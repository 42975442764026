import React, { ReactElement, useCallback, useMemo, useRef } from "react";
import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { DonutChartRef } from "@components/Chart/DonutChart";
import { LoadingPlaceHolder } from "@components";
import { NoAccountsStub } from "@components/NoAccountsStub";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { ChartBreakdown } from "@features/analysis/components/types";

const findAllChildren = (
  chartData: ChartBreakdown[],
  parentId: string | number
): ChartBreakdown[] => {
  const children = chartData.filter((item) => item.parentId === parentId);
  return children.reduce(
    (acc, item) => [...acc, item, ...findAllChildren(chartData, item.id)],
    [] as ChartBreakdown[]
  );
};

type Props = {
  chart: ReactElement;
  legend: ReactElement;
  isLoading?: boolean;
  actionButton?: ReactElement;
  noData?: boolean;
  chartData: ChartBreakdown[];
  chartTitle?: string;
  noDataComponent?: React.FC<{ mt: number }>;
  subtitleFirstLineStyle?: React.CSSProperties;
  subtitleSecondLineStyle?: React.CSSProperties;
};

export const CardLayout = ({
  chart,
  chartTitle,
  legend,
  isLoading,
  noData,
  noDataComponent = NoAccountsStub,
  actionButton,
  chartData,
  subtitleFirstLineStyle,
  subtitleSecondLineStyle,
}: Props) => {
  const ref = useRef<DonutChartRef>(null);
  const NoDataComponent = noDataComponent;
  const [mainItem, setMainItem] = React.useState<ChartBreakdown | null>(null);
  const [hoveredItem, setHoveredItem] = React.useState<ChartBreakdown | null>(
    null
  );
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { isAnonymized } = useSharedPortfolioContext();

  const onSectionHover = useCallback((item: ChartBreakdown | null) => {
    setHoveredItem(item);
    ref.current?.setHoveredItem(item);
  }, []);

  const onSectionSelect = useCallback((item: ChartBreakdown | null) => {
    if (item?.hasChildren || !item) {
      setMainItem(item);
    }
  }, []);

  const onDonutChartSectionSelect = useCallback(
    (item: ChartBreakdown | null) => {
      if (item?.hasChildren) {
        setMainItem(item);
      }
    },
    []
  );

  const secondLineStyles = useMemo(
    () => ({
      marginTop: isLessSm ? "4px" : "6px",
      fontSize: isLessSm ? "12px" : "16px",
      lineHeight: isLessSm ? "18px" : "22px",
      ...subtitleSecondLineStyle,
    }),
    [isLessSm, subtitleSecondLineStyle]
  );

  const firstLineStyles = useMemo(
    () => ({
      fontSize: isLessSm ? "24px" : "36px",
      lineHeight: "34px",
      ...subtitleFirstLineStyle,
    }),
    [isLessSm, subtitleFirstLineStyle]
  );

  const firstLineStylesForAbsoluteView = useMemo(
    () => ({
      fontSize: isLessSm ? "14px" : "24px",
      lineHeight: "34px",
      ...subtitleFirstLineStyle,
    }),
    [isLessSm, subtitleFirstLineStyle]
  );

  const mainParentItem = useMemo(() => {
    return mainItem
      ? chartData?.find((item) => item.id && item.id === mainItem?.parentId)
      : null;
  }, [chartData, mainItem]);

  const donutChartData = useMemo(() => {
    return (
      chartData
        ?.filter((item) => {
          if (mainItem) {
            return item.parentId === mainItem.id;
          }
          return item.parentId === undefined;
        })
        .map((item) => ({
          ...item,
          id: item.id ?? item.uid,
          amountOfInvestments: item.hasChildren
            ? findAllChildren(chartData, item.id).length
            : 0,
        })) || []
    );
  }, [chartData, mainItem]);

  const totalValue = useMemo(
    () =>
      chartData
        ?.filter((item) => {
          return item.parentId === undefined;
        })
        .reduce((acc, item) => acc + item.y, 0),
    [chartData]
  );
  const currentTotalValue = useMemo(
    () => donutChartData.reduce((acc, item) => acc + item.y, 0),
    [donutChartData]
  );

  const breadcrumbs = useMemo(() => {
    return mainItem
      ? [
          {
            name: chartTitle,
            onClick: () => {
              setMainItem(null);
              ref?.current?.setSelectedItem(null);
            },
          },
          ...(mainParentItem
            ? [
                {
                  name: mainParentItem.name,
                  onClick: () => {
                    setMainItem(mainParentItem);
                    ref?.current?.setSelectedItem(null);
                  },
                },
              ]
            : []),
        ]
      : [];
  }, [mainParentItem, mainItem, chartTitle]);

  if (noData) {
    return <NoDataComponent mt={11} />;
  }

  return (
    <Box maxWidth={1200} pt={isLessSm ? 2 : 12} width="100%">
      <Grid container columnSpacing={17} rowSpacing={isLessSm ? 5 : 9}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width="100%" height={isLessSm ? 280 : isLessMd ? 450 : "100%"}>
            <AutoSizer>
              {({ width }: { width: number }) => (
                <Box
                  width={width}
                  height={isLessSm ? 280 : 450}
                  display="flex"
                  justifyContent="center"
                >
                  {React.cloneElement(chart, {
                    height: isLessSm ? 280 : 450,
                    width: width,
                    ref,
                    onSectionSelect: onDonutChartSectionSelect,
                    onSectionHover: setHoveredItem,
                    subtitleSecondLineStyle: secondLineStyles,
                    subtitleFirstLineStyle: isAnonymized
                      ? firstLineStyles
                      : firstLineStylesForAbsoluteView,
                    totalValueText: mainItem?.name,
                    isLoading,
                    chartData: donutChartData,
                    totalValue: currentTotalValue,
                    view: isAnonymized ? "percentage" : "absolute",
                    selectedProperty: "uid",
                  })}
                </Box>
              )}
            </AutoSizer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Box display="flex" gap={5} flexDirection="column">
            <Box display="flex" justifyContent="flex-end">
              {actionButton}
            </Box>
            {isLoading ? (
              <Box mt={11}>
                <LoadingPlaceHolder />
              </Box>
            ) : (
              React.cloneElement(legend, {
                chartTitle: mainItem?.name || chartTitle,
                breadcrumbs,
                selectedItem: hoveredItem,
                chartData: donutChartData,
                totalValue,
                currentTotalValue,
                onMouseOver: onSectionHover,
                onClick: onSectionSelect,
              })
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
