import { UseQueryOptions } from "react-query";
import {
  DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest,
  DividendMasterDataRecordList,
} from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllDividendMasterDataRecords = async (
  token: string | undefined,
  params?: DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.listAndSearchAllDividendMasterDataRecords(
    params
  );
  return data;
};

export const useListAndSearchAllDividendMasterDataRecords = (
  params?: DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest,
  options?: UseQueryOptions<
    DividendMasterDataRecordList,
    AxiosApiError,
    DividendMasterDataRecordList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.DIVIDENDMASTERDATARECORDS_LIST_AND_SEARCH_ALL_DIVIDEND_MASTER_DATA_RECORDS,
      params,
    ],
    ({ token }) => listAndSearchAllDividendMasterDataRecords(token, params),
    options
  );
};
