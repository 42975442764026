import { UseMutationOptions } from "react-query";
import {
  UserProfilesApiUpdateUserProfileRequest,
  Profile,
} from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateUserProfile = async (
  token: string | undefined,
  params: UserProfilesApiUpdateUserProfileRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.updateUserProfile(params);
  return data;
};

export const useUpdateUserProfile = (
  options?: UseMutationOptions<
    Profile,
    AxiosApiError,
    UserProfilesApiUpdateUserProfileRequest
  >
) => {
  return useAuthMutation(updateUserProfile, options, false);
};
