import { UseQueryOptions } from "react-query";
import {
  DividendsApiGetDividendsPerYearRequest,
  DividendPerYearList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getDividendsPerYear = async (
  token: string | undefined,
  params?: DividendsApiGetDividendsPerYearRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.getDividendsPerYear(params);
  return data;
};

export const useGetDividendsPerYear = (
  params?: DividendsApiGetDividendsPerYearRequest,
  options?: UseQueryOptions<
    DividendPerYearList,
    AxiosApiError,
    DividendPerYearList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_GET_DIVIDENDS_PER_YEAR, params],
    ({ token }) => getDividendsPerYear(token, params),
    options
  );
};
