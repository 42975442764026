import { UseQueryOptions } from "react-query";
import { CryptoCurrenciesApiAddCryptoCurrencyRequest } from "@generated/apiv1";

import { buildCryptoCurrenciesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const addCryptoCurrency = async (
  token: string | undefined,
  params: CryptoCurrenciesApiAddCryptoCurrencyRequest
) => {
  const apiInstance = buildCryptoCurrenciesApi(token);
  const { data } = await apiInstance.addCryptoCurrency(params);
  return data;
};

export const useAddCryptoCurrency = (
  params: CryptoCurrenciesApiAddCryptoCurrencyRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CRYPTOCURRENCIES_ADD_CRYPTO_CURRENCY, params],
    ({ token }) => addCryptoCurrency(token, params),
    options
  );
};
