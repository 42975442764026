import React from "react";
import { useTranslation } from "react-i18next";
import { NoTokenError, useToken } from "@api";
import { SimpleLayout, LoginFormWithLinks } from "@components";
import { LoginParams } from "@generated/apiv1";

export interface Props {
  error?: AxiosApiError | NoTokenError;
  onLogin?: () => void;
}

export const LoginOrConfirmation = () => {
  const { t } = useTranslation();

  const { getToken: login, isLoading } = useToken();

  const handleLogin = (params: LoginParams & { rememberMe?: boolean }) => {
    login(params, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  };

  return (
    <>
      <SimpleLayout
        testId="login-form-with-redirect"
        backUrl="/"
        title={t("authContext.loginTitle")}
        variant="light"
      >
        <LoginFormWithLinks login={handleLogin} isLoading={isLoading} />
      </SimpleLayout>
    </>
  );
};
