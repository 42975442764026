import {
  defaultContext,
  TransactionWizardContext,
} from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";

export const clearSplitValuesAction = (context: TransactionWizardContext) => {
  return {
    splitValues: defaultContext.splitValues,
  };
};
