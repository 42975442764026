import React from "react";
import { useTranslation } from "react-i18next";
import { SelectStepProps, SelectStep } from "@components/Wizard/SelectStep";

export const FullLotsStep = (
  props: SelectStepProps<"onlyFullLots" | "fullLotsWithPartial">
) => {
  const { t } = useTranslation();
  return (
    <SelectStep
      {...props}
      testId="select-full-lots-transfer-step"
      options={[
        {
          label: t("depotTransfer.fullLotsStep.onlyFullLots"),
          value: "onlyFullLots",
          testId: "only-full-lots",
        },
        {
          label: t("depotTransfer.fullLotsStep.fullLotsAndPartials"),
          value: "fullLotsWithPartial",
          testId: "full-lots-with-partial",
        },
      ]}
      question={t("depotTransfer.fullLotsStep.question")}
    />
  );
};
