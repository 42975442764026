/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateCashbackIncidentParams
 */
export interface CreateOrUpdateCashbackIncidentParams {
    /**
     * The subscription the incident belongs to; required for creation
     * @type {number}
     * @memberof CreateOrUpdateCashbackIncidentParams
     */
    'subscriptionId'?: number;
    /**
     * The incident type. This is a freetext field for user reference only, required for creation
     * @type {string}
     * @memberof CreateOrUpdateCashbackIncidentParams
     */
    'type'?: string;
    /**
     * The incident status, required for creation
     * @type {string}
     * @memberof CreateOrUpdateCashbackIncidentParams
     */
    'status'?: CreateOrUpdateCashbackIncidentParamsStatusEnum;
    /**
     * The incident comment (a longtext)
     * @type {string}
     * @memberof CreateOrUpdateCashbackIncidentParams
     */
    'comment'?: string;
    /**
     * Any user-defined external reference; e.g. a ticket link
     * @type {string}
     * @memberof CreateOrUpdateCashbackIncidentParams
     */
    'externalReference'?: string;
}

export const CreateOrUpdateCashbackIncidentParamsStatusEnum = {
    ACTIVE: 'active',
    RESOLVED: 'resolved'
} as const;

export type CreateOrUpdateCashbackIncidentParamsStatusEnum = typeof CreateOrUpdateCashbackIncidentParamsStatusEnum[keyof typeof CreateOrUpdateCashbackIncidentParamsStatusEnum];


