import { useNavigate } from "react-router-dom";
import {
  CreateMultipleBookingParams,
  CreateOrUpdateBookingParams,
  CreateOrUpdateInvestmentParams,
  TransactionValidationError,
} from "@api";
import { useCreateOrUpdateBookings } from "@api/v1/hooks/useCreateOrUpdateBooking";

const filterInvalidBookings = (
  createOrUpdateBookingParamsList: CreateOrUpdateBookingParams[],
  transactionValidationErrors: TransactionValidationError[]
) => {
  return createOrUpdateBookingParamsList.filter(
    (params) =>
      !transactionValidationErrors.find((err) => err.hash === params.hash)
  );
};

const filterInvalidInvestmentParams = (
  createOrUpdateInvestmentParamsList: CreateOrUpdateInvestmentParams[],
  transactionValidationErrors: TransactionValidationError[]
) => {
  const initialValue: CreateOrUpdateInvestmentParams[] = [];
  return createOrUpdateInvestmentParamsList.reduce((list, params) => {
    const filteredBookings = filterInvalidBookings(
      params.createOrUpdateBookingParamsList || [],
      transactionValidationErrors
    );
    if (!filteredBookings.length) return list;
    return [
      ...list,
      { ...params, createOrUpdateBookingParamsList: filteredBookings },
    ];
  }, initialValue);
};

export const usePersistValidBookings = (successUrl: string) => {
  const navigate = useNavigate();
  const { mutate: createOrUpdateBookings, isLoading } =
    useCreateOrUpdateBookings();

  const persistValidBookings = (
    accountId: number,
    createOrUpdateInvestmentParamsList: CreateOrUpdateInvestmentParams[],
    transactionValidationErrors: TransactionValidationError[]
  ) => {
    const createMultipleBookingParams: CreateMultipleBookingParams = {
      accountId: accountId,
      performBackgroundTransactionSync: false,
      autoAssignQuotes: true,
      createDepotSynchronizationLog: true,
      createOrUpdateInvestmentParamsList: [],
    };

    const filteredList = filterInvalidInvestmentParams(
      createOrUpdateInvestmentParamsList,
      transactionValidationErrors
    );

    if (!filteredList.length) return;

    createOrUpdateBookings(
      {
        ...createMultipleBookingParams,
        createOrUpdateInvestmentParamsList: filteredList,
      },
      {
        onSuccess: (data) => {
          navigate(
            `${successUrl}/file-drop-success/${data.depotSynchronizationLogId}`
          );
        },
      }
    );
  };

  return { persistValidBookings, isLoading };
};
