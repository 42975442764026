import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { LOGIN_TOKEN_QUERY_CACHE_KEY } from "@api/auth/cacheKeys";
import {
  readFromLocalStorage,
  readFromSessionStorage,
} from "@api/auth/readFromStorage";
import { saveUserTokenToStorage } from "@api/auth/saveToStorage";
import { useRefreshToken as useRefresh } from "@generated/user/hooks";

export const useRefreshToken = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: refreshToken, ...rest } = useRefresh({
    onSuccess: (loginData) => {
      let token = queryClient.getQueryData<Token>(LOGIN_TOKEN_QUERY_CACHE_KEY);
      if (!token) {
        token = readFromSessionStorage();
      }

      if (!token) {
        token = readFromLocalStorage();
      }

      if (token) saveUserTokenToStorage(token, loginData, token.rememberMe);
      //refresh the page so that hooks that rely on tokens get fresh data
      navigate(0);
    },
  });

  return {
    mutate: refreshToken,
    ...rest,
  };
};
