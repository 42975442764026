import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setValidatedParams = (
  _: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "validatedMultipleBookingParams"> => {
  if (event.type !== "DIVIDENDS_VALIDATED") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    validatedMultipleBookingParams: event.validatedMultipleBookingParams,
  };
};
