import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@components";
import { BookingDataConsistencyStatusEnum } from "@generated/apiv1";

export const VirtualBookingTooltip = ({
  dataConsistencyStatus,
}: {
  dataConsistencyStatus: BookingDataConsistencyStatusEnum;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip
      text={t(
        `bookingsTable.virtualBookingConditions.${dataConsistencyStatus}`
      )}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.warning.lighter,
          border: "1px solid #FCECDB",
          borderRadius: "8px",
        }}
        py={1}
        px={2}
      >
        <Typography
          variant="body1"
          color={theme.palette.warning.dark}
          align="right"
        >
          !
        </Typography>
      </Box>
    </Tooltip>
  );
};
