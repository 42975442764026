/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Properties to import a new or update an existing bank connection via the TPP web form flow.
 * @export
 * @interface CreateOrUpdateTppWebFormParams
 */
export interface CreateOrUpdateTppWebFormParams {
    /**
     * ID of the bank in case we want to import a new bank connection; optional if bankConnectionId                        is given.
     * @type {number}
     * @memberof CreateOrUpdateTppWebFormParams
     */
    'bankId'?: number;
    /**
     * ID of the bank connection in case we want to update an existing bank connection. The parameter                        bankId 
     * @type {number}
     * @memberof CreateOrUpdateTppWebFormParams
     */
    'bankConnectionId'?: number;
    /**
     * The bank connection interface to update
     * @type {string}
     * @memberof CreateOrUpdateTppWebFormParams
     */
    'bankConnectionInterfaceType'?: CreateOrUpdateTppWebFormParamsBankConnectionInterfaceTypeEnum;
    /**
     * A custom redirect URL, if not given the mandators default redirect URL will be used
     * @type {string}
     * @memberof CreateOrUpdateTppWebFormParams
     */
    'redirectUrl'?: string;
}

export const CreateOrUpdateTppWebFormParamsBankConnectionInterfaceTypeEnum = {
    XS2_A: 'XS2A',
    FINTS_SERVER: 'FINTS_SERVER',
    WEB_SCRAPER: 'WEB_SCRAPER'
} as const;

export type CreateOrUpdateTppWebFormParamsBankConnectionInterfaceTypeEnum = typeof CreateOrUpdateTppWebFormParamsBankConnectionInterfaceTypeEnum[keyof typeof CreateOrUpdateTppWebFormParamsBankConnectionInterfaceTypeEnum];


