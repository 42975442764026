import { useTheme } from "@emotion/react";
import { format, parseJSON } from "date-fns";
import { isDate } from "lodash";
import React from "react";

interface Props {
  x: number;
  y: number;
  textAnchor: string;
  payload: { value?: Date | string };
  dateFormat?: string;
}

export const XAxisTick = ({
  x,
  y,
  textAnchor,
  payload,
  dateFormat = "MM''yy",
}: Props) => {
  const theme = useTheme();

  const isValid = !!payload.value && isDate(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={textAnchor}
        fill={theme.palette.grey[500]}
      >
        {isValid ? format(parseJSON(payload.value!), dateFormat) : undefined}
      </text>
    </g>
  );
};
