import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { ReactComponent as StarIcon } from "@icons/yellow_star.svg";
import { Tooltip } from "@components";
import { BUY } from "@helpers/urlConstants";
import { useMyself } from "@api";

export const TooltipContent = () => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={2.5}
    >
      <Typography variant="body2" noWrap={!isLessSm} fontWeight={400}>
        {t("availableWithPlus")}
      </Typography>
      <Box alignSelf="center" display="flex" alignItems="center">
        <Button
          variant="contained"
          startIcon={<StarIcon />}
          color="tertiary"
          size="small"
          href={BUY}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("upgradeNow")}
        </Button>
      </Box>
    </Box>
  );
};

type Props = {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  iconColor?: string;
  isLockIconShown?: boolean;
} & BoxProps;

export const BlurForNonPro = ({
  children,
  tooltip = <TooltipContent />,
  iconColor,
  isLockIconShown,
  ...rest
}: Props) => {
  const { data: myself } = useMyself();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  if (myself?.isUserPro) return <Box {...rest}>{children}</Box>;

  return (
    <Box {...rest}>
      <Tooltip
        text={tooltip}
        placement="top"
        maxWidth={isLessSm ? "200px" : "none"}
        openOnClick={isLessMd}
        leaveDelay={200}
      >
        <Box id="tooltip-block">
          {isLockIconShown ? (
            <Box display="flex" alignItems="center" gap={1}>
              <LockIcon color={iconColor} />
              {children}
            </Box>
          ) : (
            children
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
