import { UseMutationOptions } from "react-query";
import {
  PerformanceApiEstimateTradingGainForNewTransactionRequest,
  RealizedGain,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const estimateTradingGainForNewTransaction = async (
  token: string | undefined,
  params: PerformanceApiEstimateTradingGainForNewTransactionRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.estimateTradingGainForNewTransaction(
    params
  );
  return data;
};

export const useEstimateTradingGainForNewTransaction = (
  options?: UseMutationOptions<
    RealizedGain,
    AxiosApiError,
    PerformanceApiEstimateTradingGainForNewTransactionRequest
  >
) => {
  return useAuthMutation(estimateTradingGainForNewTransaction, options, false);
};
