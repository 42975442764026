import { UseMutationOptions } from "react-query";
import { TppCredentialsApiDeleteTppCredentialRequest } from "@generated/apiv1";

import { buildTppCredentialsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteTppCredential = async (
  token: string | undefined,
  params: TppCredentialsApiDeleteTppCredentialRequest
) => {
  const apiInstance = buildTppCredentialsApi(token);
  const { data } = await apiInstance.deleteTppCredential(params);
  return data;
};

export const useDeleteTppCredential = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TppCredentialsApiDeleteTppCredentialRequest
  >
) => {
  return useAuthMutation(deleteTppCredential, options, false);
};
