import { UseQueryOptions } from "react-query";
import {
  HistoricQuotesApiHistoricQuotesRequest,
  HistoricQuoteList,
} from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const historicQuotes = async (
  token: string | undefined,
  params?: HistoricQuotesApiHistoricQuotesRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.historicQuotes(params);
  return data;
};

export const useHistoricQuotes = (
  params?: HistoricQuotesApiHistoricQuotesRequest,
  options?: UseQueryOptions<HistoricQuoteList, AxiosApiError, HistoricQuoteList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.HISTORICQUOTES_HISTORIC_QUOTES, params],
    ({ token }) => historicQuotes(token, params),
    options
  );
};
