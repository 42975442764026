import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { Tooltip } from "@components";
import { ReactComponent as WarningIcon } from "@icons/warning-circle.svg";

type Props = {
  name: string;
  assetType: InvestmentTypeEnum;
  error?: string;
};

export const FieldLabel = ({ name, assetType, error }: Props) => {
  const { t } = useTranslation();
  const fieldLabel =
    name === "number_of_lots" && assetType === InvestmentTypeEnum._31_BOND
      ? `${name}_bond`
      : name;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {t(`csvImport.parsingResults.${fieldLabel}`)}
      {error && (
        <Tooltip
          text={t("csvImport.parsingResults.error", {
            name: t(`csvImport.parsingResults.${name}`),
          })}
        >
          <WarningIcon />
        </Tooltip>
      )}
    </Box>
  );
};
