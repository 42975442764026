import { UseQueryOptions } from "react-query";
import {
  FundsApiListAndSearchAllFundsRequest,
  PageableFundList,
} from "@generated/apiv1";

import { buildFundsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllFunds = async (
  token: string | undefined,
  params?: FundsApiListAndSearchAllFundsRequest
) => {
  const apiInstance = buildFundsApi(token);
  const { data } = await apiInstance.listAndSearchAllFunds(params);
  return data;
};

export const useListAndSearchAllFunds = (
  params?: FundsApiListAndSearchAllFundsRequest,
  options?: UseQueryOptions<PageableFundList, AxiosApiError, PageableFundList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDS_LIST_AND_SEARCH_ALL_FUNDS, params],
    ({ token }) => listAndSearchAllFunds(token, params),
    options
  );
};
