import { parseISO, parseJSON } from "date-fns";
import { HistoricQuote } from "@api";
import { addAreaValues, getInterval } from "@helpers";
import { useGetBookings } from "@api/v1/hooks/useBookings";
import { useDividends } from "@api/v1/hooks/useDividends";
import { useGetHistoricQuotesIntraday } from "@api/v1/hooks/useGetHistoricQuotesIntraday";
import { mapStockIndexToQuoteProvider } from "@constants";
import { useInvestmentHistoricQuotes } from "@features/investments/investment/hooks/useInvestmentHistoricQuotes";
import { mergeComparisonQuotesByDate } from "@features/investments/investment/utils/mergeComparisonQuotes";
import { mergeCloseByDate } from "@features/investments/investment/utils/mergeCloseByDate";

interface Attrs {
  investmentId?: number;
  startDate?: Date;
  endDate?: Date;
  tickerSymbol?: string;
  tickerSymbolWithCurrency?: string;
  comparedTickerSymbol?: string;
  quoteProvider?: string;
  currentInterval?: IntervalType;
}

interface Options {
  showEvents: boolean;
}

export const useInvestmentChartData = (
  {
    investmentId,
    currentInterval,
    startDate,
    endDate,
    tickerSymbol,
    tickerSymbolWithCurrency,
    comparedTickerSymbol,
    quoteProvider,
  }: Attrs,
  options: Options = { showEvents: false }
) => {
  const interval = getInterval(startDate, endDate);

  const dividendsQuery = useDividends(
    {
      investmentId: investmentId ? [investmentId] : undefined,
      excludeImported: false,
      taxRate: 0,
    },
    { staleTime: Infinity, enabled: options.showEvents && !!investmentId }
  );

  const dividends =
    dividendsQuery.data?.dividends?.map(({ date, dividendPerShare }) => ({
      date: parseJSON(date!),
      dividendPerShare: dividendPerShare!,
    })) || [];

  const bookingsQuery = useGetBookings(
    {
      investmentId: investmentId ? [investmentId] : undefined,
    },
    { staleTime: Infinity, enabled: options.showEvents && !!investmentId }
  );

  const bookings =
    bookingsQuery.data?.bookings?.map(({ valueDate, amount, type }) => ({
      valueDate: parseJSON(valueDate!),
      amount,
      type,
    })) || [];

  const {
    data: chartHistoricQuotesData = [],
    isLoading: chartHistoricQuotesQueryLoadingState,
    isIdle: chartHistoricQuotesIdleState,
  } = useInvestmentHistoricQuotes(
    {
      investmentId,
      tickerSymbol,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: !!investmentId,
      keepPreviousData: true,
    }
  );

  const { data: comparisonHistoricQuotes = [] } = useInvestmentHistoricQuotes(
    {
      tickerSymbol: comparedTickerSymbol,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: Boolean(comparedTickerSymbol),
      keepPreviousData: true,
    }
  );

  const shouldRequestIntradayQuotes = currentInterval === "oneDay";

  const { data: intraday } = useGetHistoricQuotesIntraday(
    {
      tickerSymbol: tickerSymbol!,
      quoteProvider: quoteProvider!,
      period: "intraday10",
      from: startDate?.toISOString()!,
      to: endDate?.toISOString()!,
    },
    {
      enabled: !!quoteProvider && !!tickerSymbol && shouldRequestIntradayQuotes,
    }
  );

  const { data: comparisonIntraday } = useGetHistoricQuotesIntraday(
    {
      tickerSymbol: comparedTickerSymbol!,
      quoteProvider: mapStockIndexToQuoteProvider[comparedTickerSymbol!],
      period: "intraday10",
      from: startDate?.toISOString()!,
      to: endDate?.toISOString()!,
    },
    {
      enabled: Boolean(comparedTickerSymbol) && shouldRequestIntradayQuotes,
    }
  );

  const preparedIntradayData: HistoricQuote[] =
    intraday?.quoteBars?.map(({ dateTime, close }) => ({
      date: dateTime!,
      value: close!,
    })) ?? [];

  const preparedComparisonIntraday: HistoricQuote[] =
    comparisonIntraday?.quoteBars?.map(({ dateTime, close }) => ({
      date: dateTime!,
      value: close!,
    })) ?? [];

  const mainQuotes = shouldRequestIntradayQuotes
    ? preparedIntradayData
    : chartHistoricQuotesData;
  const comparisonQuotes = shouldRequestIntradayQuotes
    ? preparedComparisonIntraday
    : comparisonHistoricQuotes;

  const chartData = comparedTickerSymbol
    ? mergeComparisonQuotesByDate(mainQuotes, comparisonQuotes)
    : mainQuotes;

  const mainChartData = addAreaValues(
    chartData.map(({ date, ...rest }) => ({
      ...rest,
      date: parseISO(date.slice(0, -5)),
    })),
    "value"
  );

  const mergedData = mergeCloseByDate(
    mainChartData,
    dividends || [],
    bookings || []
  );
  const data = mergedData.map((item) => [item.date!.getTime(), item.value]);

  return {
    data,
    isLoading: chartHistoricQuotesQueryLoadingState,
    isIdle: chartHistoricQuotesIdleState,
  };
};
