import {
  Box,
  BoxProps,
  IconButton,
  Theme,
  Typography,
  TypographyVariant,
  useMediaQuery,
} from "@mui/material";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { ReactComponent as Cog } from "@icons/cog.svg";
import { ActionsMenu } from "@components";

interface MenuButtonProps {
  hasMenuItems?: boolean;
}

const MenuButton = styled(IconButton, {
  shouldForwardProp: (prop: string) =>
    isPropValid(prop) || prop !== "hasMenuItems",
})<MenuButtonProps>`
  visibility: ${({ hasMenuItems }) => (hasMenuItems ? "visible" : "hidden")};
`;

interface Props extends Omit<BoxProps, "title"> {
  title?: ReactNode;
  subtitle?: ReactNode;
  actionButton?: ReactNode;
  mobileMenu?: ReactNode;
  isMainPage?: boolean;
  variant?: TypographyVariant;
}

export const Header = ({
  title,
  variant = "h4",
  mobileMenu,
  subtitle,
  actionButton,
  isMainPage,
  ...rest
}: Props) => {
  const menuRef = React.createRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="main-header"
      mb={isXs && isMainPage ? 4 : 6}
      {...rest}
    >
      <Box>
        {typeof title === "string" ? (
          <Typography
            variant={variant}
            component="h1"
            mr={2}
            mb={subtitle ? 1 : 0}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
        {typeof subtitle === "string" ? (
          <Typography variant="body1" color="textSecondary" mt={0}>
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
      </Box>
      <Box flexShrink={0}>
        {isXs && mobileMenu && (
          <>
            <MenuButton
              data-testid="open-menu-button"
              aria-controls="simple-menu"
              aria-haspopup="true"
              hasMenuItems={React.Children.count(mobileMenu) > 0}
              onClick={handleOpenMenu}
            >
              <Cog />
            </MenuButton>
            <ActionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
              <div ref={menuRef}>{mobileMenu}</div>
            </ActionsMenu>
          </>
        )}
        {(!isXs || !mobileMenu) && actionButton}
      </Box>
    </Box>
  );
};
