import { useEffect, useMemo } from "react";
import { groupBy, isEqual, uniq } from "lodash";
import { usePrevious } from "react-use";
import {
  Image,
  useSecurityImagesContext,
} from "@providers/SecurityImageProvider";
import { getCompanyLogos } from "@generated/apiv3/quotes/hooks";
import { InvestmentTypeEnum } from "@generated/apiv1";

const allowedTypes: InvestmentTypeEnum[] = [
  InvestmentTypeEnum._11_STOCK,
  InvestmentTypeEnum._22_ETF,
  InvestmentTypeEnum._61_PMETALS,
];

const getIsinsForRequest = (
  data: Security[],
  images: Record<string, Image>
) => {
  return uniq(
    data
      .filter(
        (security) =>
          security.hasValidIsin &&
          security.isin &&
          allowedTypes.includes(security.type as InvestmentTypeEnum) &&
          !((security.isin as string) in images) &&
          !images[security.isin]?.isLoading
      )
      .map((security) => security.isin)
  );
};

const getSymbolsForRequest = (
  data: Security[],
  images: Record<string, Image>
) => {
  return uniq(
    data.map((security) => security.ticker_symbol || security.tickerSymbol)
  ).filter(
    (symbol) =>
      Boolean(symbol) &&
      !((symbol as string) in images) &&
      !images[symbol as string]?.isLoading
  );
};

const chunk = (arr: any[], size: number = 10) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

type Security = {
  isin?: string | undefined;
  ticker_symbol?: string | undefined;
  tickerSymbol?: string | undefined;
  quoteProvider?: string | undefined;
  quote?: {
    quoteProvider?: string;
  };
  hasValidIsin?: boolean;
  type?: string;
};

export const useSecurityImages = <T extends Security>(
  data?: T[]
): Record<string, string | null | undefined> => {
  const groupByQuote = useMemo(
    () =>
      groupBy(data, (item) => item.quote?.quoteProvider || item.quoteProvider),
    [data]
  );
  const prevGroupByQuote = usePrevious(groupByQuote);
  const { images, setImages } = useSecurityImagesContext();

  useEffect(() => {
    if (isEqual(groupByQuote, prevGroupByQuote)) return;

    Object.keys(groupByQuote).forEach((quoteProvider) => {
      let dataForRequest;
      const isCrypto = quoteProvider === "crypto";
      if (isCrypto) {
        dataForRequest = getSymbolsForRequest(
          groupByQuote[quoteProvider],
          images
        );
      } else {
        dataForRequest = getIsinsForRequest(
          groupByQuote[quoteProvider],
          images
        );
      }

      if (quoteProvider === "none" || !dataForRequest?.length) return;

      chunk(dataForRequest).forEach((dataChunk) => {
        const requested = dataChunk.reduce((acc, isinOrSymbol) => {
          return {
            ...acc,
            [isinOrSymbol]: {
              isLoading: true,
              value: images[isinOrSymbol]?.value,
            },
          };
        }, images);

        setImages(requested);

        getCompanyLogos(undefined, {
          quoteProvider,
          filterIsin: isCrypto ? undefined : dataChunk.join(","),
          filterSymbol: isCrypto ? dataChunk.join(",") : undefined,
        })
          .then((response) => {
            const responseLogos = response.data;
            setImages((logos) => ({
              ...logos,
              ...responseLogos?.reduce(
                (acc, logo) => ({
                  ...acc,
                  [logo.id]: {
                    isLoading: false,
                    value:
                      logo.attributes.url_svg ||
                      logo.attributes.url_medium ||
                      logo.attributes.url_small,
                  },
                }),
                {} as Record<string, Image>
              ),
            }));
          })
          .catch(() => {
            setImages((logos) =>
              dataChunk.reduce(
                (acc, isinOrSymbol) => ({
                  ...acc,
                  [isinOrSymbol]: {
                    isLoading: false,
                    value: null,
                  },
                }),
                logos
              )
            );
          });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupByQuote, prevGroupByQuote]);

  return useMemo(
    () =>
      Object.entries(images).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value?.value,
        }),
        {} as Record<string, string | null | undefined>
      ),
    [images]
  );
};
