import { UseQueryOptions } from "react-query";
import { MandatorsApiMandatorsIdRequest, Mandator } from "@generated/apiv1";

import { buildMandatorsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const mandatorsId = async (
  token: string | undefined,
  params: MandatorsApiMandatorsIdRequest
) => {
  const apiInstance = buildMandatorsApi(token);
  const { data } = await apiInstance.mandatorsId(params);
  return data;
};

export const useMandatorsId = (
  params: MandatorsApiMandatorsIdRequest,
  options?: UseQueryOptions<Mandator, AxiosApiError, Mandator>
) => {
  return useAuthQuery(
    [CACHE_KEYS.MANDATORS_MANDATORS_ID, params],
    ({ token }) => mandatorsId(token, params),
    options
  );
};
