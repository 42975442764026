import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

interface Props<T>
  extends Omit<
      AutocompleteProps<string | number, boolean, boolean, boolean>,
      "renderInput" | "label" | "defaultValue"
    >,
    UseControllerProps<T> {
  label?: string;
  options: (string | number)[];
  testId?: string;
  optional?: boolean;
}

export const RHFAutocomplete = <T extends FieldValues>({
  name,
  label,
  testId,
  options,
  control,
  optional = false,
  ...props
}: Props<T>) => {
  const Label = (
    <>
      {label}
      {optional ? (
        <Typography color="textSecondary" component="span" variant="body2">
          {" "}
          (optional)
        </Typography>
      ) : (
        ""
      )}
    </>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            options={options}
            autoHighlight
            value={field.value ?? null}
            onChange={(_, value) => field.onChange(value)}
            disablePortal
            renderInput={({ InputLabelProps, ...rest }) => {
              return (
                <TextField
                  {...rest}
                  label={Label}
                  variant="outlined"
                  placeholder={label}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{
                    ...rest.inputProps,
                    "data-testid": testId || "autocomplete",
                  }}
                />
              );
            }}
            {...props}
          />
        );
      }}
    />
  );
};
