import { UseQueryOptions } from "react-query";
import {
  PerformanceApiAbsoluteReturnByPositionRequest,
  AbsoluteReturnList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const absoluteReturnByPosition = async (
  token: string | undefined,
  params?: PerformanceApiAbsoluteReturnByPositionRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.absoluteReturnByPosition(params);
  return data;
};

export const useAbsoluteReturnByPosition = (
  params?: PerformanceApiAbsoluteReturnByPositionRequest,
  options?: UseQueryOptions<
    AbsoluteReturnList,
    AxiosApiError,
    AbsoluteReturnList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_ABSOLUTE_RETURN_BY_POSITION, params],
    ({ token }) => absoluteReturnByPosition(token, params),
    options
  );
};
