import { UseMutationOptions } from "react-query";
import {
  RemoteDepotsApiUpdateRemoteDepotRequest,
  DepotSynchronizationLog,
} from "@generated/apiv1";

import { buildRemoteDepotsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateRemoteDepot = async (
  token: string | undefined,
  params: RemoteDepotsApiUpdateRemoteDepotRequest
) => {
  const apiInstance = buildRemoteDepotsApi(token);
  const { data } = await apiInstance.updateRemoteDepot(params);
  return data;
};

export const useUpdateRemoteDepot = (
  options?: UseMutationOptions<
    DepotSynchronizationLog,
    AxiosApiError,
    RemoteDepotsApiUpdateRemoteDepotRequest
  >
) => {
  return useAuthMutation(updateRemoteDepot, options, false);
};
