import { UseQueryOptions } from "react-query";
import {
  BookingsApiRemoteChangesRequest,
  AccountImportOrSynchronizationResult,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const remoteChanges = async (
  token: string | undefined,
  params: BookingsApiRemoteChangesRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.remoteChanges(params);
  return data;
};

export const useRemoteChanges = (
  params: BookingsApiRemoteChangesRequest,
  options?: UseQueryOptions<
    AccountImportOrSynchronizationResult,
    AxiosApiError,
    AccountImportOrSynchronizationResult
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BOOKINGS_REMOTE_CHANGES, params],
    ({ token }) => remoteChanges(token, params),
    options
  );
};
