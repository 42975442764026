import { UseQueryOptions } from "react-query";
import {
  AccountsApiListAndSearchAllAccountsRequest,
  AccountList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllAccounts = async (
  token: string | undefined,
  params?: AccountsApiListAndSearchAllAccountsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.listAndSearchAllAccounts(params);
  return data;
};

export const useListAndSearchAllAccounts = (
  params?: AccountsApiListAndSearchAllAccountsRequest,
  options?: UseQueryOptions<AccountList, AxiosApiError, AccountList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS, params],
    ({ token }) => listAndSearchAllAccounts(token, params),
    options
  );
};
