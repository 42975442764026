import { MutationOptions } from "react-query";
import { useAuthMutation } from "@api";
import {
  CategoriesApiCreateTransactionCategoryRequest,
  CategoriesApiDeleteCategoryRequest,
  Category,
  IdentifierList,
  TransactionsApiAssignCategoryRequest,
} from "@generated/apiv1";
import {
  buildCategoriesApiWithToken,
  buildTransactionsApiWithToken,
} from "@generated/apiv1/apiBuilder";

const addCategory = async (
  token: string | undefined,
  params: CategoriesApiCreateTransactionCategoryRequest
) => {
  const api = await buildCategoriesApiWithToken();
  const { data } = await api.createTransactionCategory(params);

  return data;
};

const deleteCategory = async (
  token: string | undefined,
  params: CategoriesApiDeleteCategoryRequest
) => {
  const api = await buildCategoriesApiWithToken();
  const { data } = await api.deleteCategory(params);

  return data;
};

const assignCategory = async (
  token: string | undefined,
  params: TransactionsApiAssignCategoryRequest
) => {
  const api = await buildTransactionsApiWithToken();
  const { data } = await api.assignCategory(params);

  return data;
};

export const useCreateCategory = (
  options?: MutationOptions<
    Category,
    AxiosApiError,
    CategoriesApiCreateTransactionCategoryRequest
  >
) => useAuthMutation(addCategory, options);

export const useDeleteCategory = (
  options?: MutationOptions<
    unknown,
    AxiosApiError,
    CategoriesApiDeleteCategoryRequest
  >
) => useAuthMutation(deleteCategory, options);

export const useAssignCategory = (
  options?: MutationOptions<
    IdentifierList,
    AxiosApiError,
    TransactionsApiAssignCategoryRequest
  >
) => useAuthMutation(assignCategory, options);
