/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AssetMarketValuePerIsin } from './asset-market-value-per-isin';

/**
 * 
 * @export
 * @interface AssetsTotalMarketValue
 */
export interface AssetsTotalMarketValue {
    /**
     * Identifies the asset type
     * @type {string}
     * @memberof AssetsTotalMarketValue
     */
    'asset_type'?: AssetsTotalMarketValueAssetTypeEnum;
    /**
     * Aggregate sum of all individual asset market values
     * @type {string}
     * @memberof AssetsTotalMarketValue
     */
    'total_market_value'?: string;
    /**
     * 
     * @type {Array<AssetMarketValuePerIsin>}
     * @memberof AssetsTotalMarketValue
     */
    'assets'?: Array<AssetMarketValuePerIsin>;
}

export const AssetsTotalMarketValueAssetTypeEnum = {
    STOCK: 'STOCK',
    FUND: 'FUND',
    ETF: 'ETF',
    BOND: 'BOND',
    CASH: 'CASH',
    CERTIFICATE_OS: 'CERTIFICATE_OS',
    PRECIOUS_METAL: 'PRECIOUS_METAL',
    MATERIAL_ASSET: 'MATERIAL_ASSET',
    FOREIGN_CURRENCY: 'FOREIGN_CURRENCY',
    MANAGED: 'MANAGED'
} as const;

export type AssetsTotalMarketValueAssetTypeEnum = typeof AssetsTotalMarketValueAssetTypeEnum[keyof typeof AssetsTotalMarketValueAssetTypeEnum];


