import {
  BarInvestmentDataProps,
  ChartBarData,
  ChartDataProps,
} from "@components/Chart/DashboardDividendsChart/types";

export const calculateDataForCurrentYearChart = ({
  historyData,
  predictedData,
  dividendYieldData,
}: ChartDataProps) => {
  const currentYearIndex = new Date().getFullYear();
  const previousYearIndex = currentYearIndex - 1;
  const currentMonthIndex = new Date().getMonth();

  let currentYearOverallDividends;
  let previousYearOverallDividends;
  let currentYearDividendsPredicted;
  let grossYield;
  let chartBarsData: Array<ChartBarData> = Array(12)
    .fill(0)
    .map((item, idx) => ({
      month: idx,
      last: 0,
      current: 0,
      isPredicted: false,
      lastBarInvestmentsData: [],
      currentBarInvestmentsData: [],
    }));

  if (
    historyData?.nodesByYear &&
    predictedData?.nodesByYear &&
    dividendYieldData?.length
  ) {
    currentYearOverallDividends =
      historyData?.nodesByYear?.[currentYearIndex]?.grossAmount;
    previousYearOverallDividends =
      historyData?.nodesByYear?.[previousYearIndex]?.grossAmount;
    currentYearDividendsPredicted =
      predictedData?.nodesByYear?.[currentYearIndex]?.grossAmount;
    grossYield =
      dividendYieldData?.find((item) => item.year === currentYearIndex)
        ?.grossYield || 0;

    chartBarsData = chartBarsData.map((chartBar) => {
      const { month } = chartBar;
      const last =
        historyData?.nodesByYear?.[previousYearIndex]?.children?.[month]
          ?.grossAmount || 0;
      const historyDataForCurrent =
        historyData?.nodesByYear?.[currentYearIndex]?.children?.[month]
          ?.grossAmount || 0;
      const predictedDataForCurrent =
        predictedData?.nodesByYear?.[currentYearIndex]?.children?.[month]
          ?.grossAmount || 0;

      const currentMonthCurrentValue =
        historyDataForCurrent > predictedDataForCurrent
          ? historyDataForCurrent
          : predictedDataForCurrent;
      const isPredicted = month > currentMonthIndex;
      const current =
        (month === currentMonthIndex
          ? currentMonthCurrentValue
          : isPredicted
          ? predictedDataForCurrent
          : historyDataForCurrent) || 0;

      let lastBarInvestmentsData: BarInvestmentDataProps[] = [];
      let currentBarInvestmentsData: BarInvestmentDataProps[] | undefined = [];

      const tooltipDataForLast =
        historyData?.nodesByYear?.[previousYearIndex]?.children?.[month]
          ?.children;
      if (tooltipDataForLast) {
        lastBarInvestmentsData = Object.values(tooltipDataForLast).map(
          (item) => {
            const [investmentId] = item.investmentIds;
            return {
              amount: item.grossAmount,
              invRefName:
                historyData.investmentReferenceById?.[investmentId]
                  .standardisedName,
              percent: ((item.grossAmount / last) * 100).toFixed(2),
              year: item.year,
            };
          }
        );
      }

      let currentMonthBarInvestmentsData;
      let currentMonthCurrentInvestments;
      if (historyDataForCurrent > predictedDataForCurrent) {
        currentMonthCurrentInvestments =
          historyData?.nodesByYear?.[currentYearIndex]?.children?.[
            currentMonthIndex
          ]?.children;
      } else {
        currentMonthCurrentInvestments =
          predictedData?.nodesByYear?.[currentYearIndex]?.children?.[
            currentMonthIndex
          ]?.children;
      }

      if (currentMonthCurrentInvestments) {
        currentMonthBarInvestmentsData = Object.values(
          currentMonthCurrentInvestments
        ).map((item) => {
          const [investmentId] = item.investmentIds;
          const invRefName =
            historyDataForCurrent > predictedDataForCurrent
              ? historyData?.investmentReferenceById?.[investmentId]
                  .standardisedName
              : predictedData?.investmentReferenceById?.[investmentId]
                  .standardisedName;
          return {
            amount: item.grossAmount,
            invRefName,
            percent: ((item.grossAmount / current) * 100).toFixed(2),
            year: item.year,
          };
        });
      }

      let currentBarInvestmentsHistoryData;
      const tooltipHistoryDataForCurrent =
        historyData?.nodesByYear?.[currentYearIndex]?.children?.[month]
          ?.children;
      if (tooltipHistoryDataForCurrent) {
        currentBarInvestmentsHistoryData = Object.values(
          tooltipHistoryDataForCurrent
        )
          .filter((inv) => inv.month < currentMonthIndex)
          .map((item) => {
            const [investmentId] = item.investmentIds;
            return {
              amount: item.grossAmount,
              invRefName:
                historyData?.investmentReferenceById?.[investmentId]
                  .standardisedName,
              percent: ((item.grossAmount / current) * 100).toFixed(2),
              year: item.year,
            };
          });
      }

      let currentBarInvestmentsPredictedData;
      const tooltipPredictedDataForCurrent =
        predictedData?.nodesByYear?.[currentYearIndex]?.children?.[month]
          ?.children;
      if (tooltipPredictedDataForCurrent) {
        currentBarInvestmentsPredictedData = Object.values(
          tooltipPredictedDataForCurrent
        )
          .filter((inv) => inv.month > currentMonthIndex)
          .map((item) => {
            const [investmentId] = item.investmentIds;
            return {
              amount: item.grossAmount,
              invRefName:
                historyData?.investmentReferenceById?.[investmentId]
                  .standardisedName,
              percent: ((item.grossAmount / current) * 100).toFixed(2),
              year: item.year,
            };
          });
      }

      if (month < currentMonthIndex) {
        currentBarInvestmentsData = currentBarInvestmentsHistoryData;
      }

      if (month > currentMonthIndex) {
        currentBarInvestmentsData = currentBarInvestmentsPredictedData;
      }

      if (month === currentMonthIndex)
        currentBarInvestmentsData = currentMonthBarInvestmentsData;

      return {
        last,
        current,
        month,
        year: currentYearIndex,
        isPredicted,
        lastBarInvestmentsData,
        currentBarInvestmentsData,
      };
    });
  }

  return {
    currentYearOverallDividends,
    previousYearOverallDividends,
    currentYearDividendsPredicted,
    grossYield,
    previousYearIndex,
    currentYearIndex,
    currentMonthIndex,
    chartBarsData,
  };
};
