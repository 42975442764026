import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead } from "@mui/material";
import { TableRow } from "@components";
import { SortableTableHeader } from "@components/Table";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

interface BookingsTableHeadProps {
  sortField: string;
  sortDirection: TableSortDirection;
  onSort: (sortByName: string) => void;
}

export const BookingsTableHead = (props: BookingsTableHeadProps) => {
  const { sortField, sortDirection, onSort } = props;
  const { isSharedPortfolio } = useSharedPortfolioContext();

  const { t } = useTranslation();

  const handleClick = (sortByName: string) => {
    onSort(sortByName);
  };

  return (
    <TableHead>
      <TableRow>
        <SortableTableHeader
          text={t("investments.bookingsTable.headers.investment")}
          sortByName="valueDate"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          isSortable
          width="10%"
        />
        <SortableTableHeader
          text={t("investments.bookingsTable.headers.name")}
          sortByName="investment.standardisedName"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          isSortable
          width={isSharedPortfolio ? "45%" : "30%"}
        />

        <SortableTableHeader
          align="right"
          isSortable
          text={t("investments.bookingsTable.headers.course")}
          sortByName="securityPriceForSorting"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          width="30%"
        />

        <SortableTableHeader
          align="right"
          isSortable
          text={t("investments.bookingsTable.headers.numberOfLots")}
          sortByName="numberOfLots"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          width="15%"
        />
        <SortableTableHeader
          align="right"
          isSortable
          text={t("investments.bookingsTable.headers.totalValue")}
          sortByName="amount"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          width="15%"
        />
        {!isSharedPortfolio && (
          <SortableTableHeader
            text=""
            sortByName=""
            align="right"
            width="15%"
          />
        )}
      </TableRow>
    </TableHead>
  );
};
