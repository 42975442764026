import React from "react";
import {
  Box,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HOME, IMPRESSUM, PRIVACY } from "@helpers/urlConstants";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { ReactComponent as FeedbackIcon } from "@icons/comment-dots-solid.svg";
import { ReactComponent as SettingsIcon } from "@icons/gear-solid.svg";
import { useToken } from "@api";
import { ReactComponent as ArrowUpIcon } from "../../../../../icons/arrow-up-right-from-square-solid.svg";

const StyledLink = styled(Typography)`
  font-size: 14px;
  cursor: pointer;
`;

const LinksContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Dot = styled(Box)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.appSidebar?.color};
  margin: ${({ theme }) => theme.spacing(0, 2)};
`;

export const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: token, isFetched } = useToken();
  const { sidebarCollapsed: collapsed, toggleSidebarCollapsed } =
    useAppLayout();

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const isAuthorized = isFetched && Boolean(token);

  const redirectAndToggleSidebar = (url: string) => {
    navigate(url);
    if (isXs) {
      toggleSidebarCollapsed();
    }
  };

  if (collapsed) return null;

  return (
    <Box px={isXs ? 3 : 4} pt={3}>
      {isAuthorized && (
        <Box mb={3}>
          <StyledLink onClick={() => redirectAndToggleSidebar("/settings")}>
            <Box display="flex" gap={2} alignItems="center">
              <SettingsIcon color={theme.palette.common.white} />
              <Typography
                variant="body2"
                color={theme.appSidebar?.color}
                fontWeight="bold"
              >
                {t("sidebar.footer.settings")}
              </Typography>
            </Box>
          </StyledLink>
        </Box>
      )}

      <Box mb={3}>
        <StyledLink onClick={() => redirectAndToggleSidebar("/feedback")}>
          <Box display="flex" alignItems="center">
            <FeedbackIcon color={theme.palette.common.white} />
            <Typography
              ml={2}
              variant="body2"
              color={theme.appSidebar?.color}
              fontWeight="600"
            >
              {t("sidebar.footer.feedback")}
            </Typography>
          </Box>
        </StyledLink>
      </Box>
      <Box display="flex" alignItems="center" mb={3}>
        {isAuthorized && (
          <>
            <StyledLink onClick={() => redirectAndToggleSidebar("/logout")}>
              <Typography
                variant="body2"
                color={theme.appSidebar?.contrastText}
                fontWeight="bold"
              >
                {t("sidebar.menu.logout")}
              </Typography>
            </StyledLink>
            <Dot />
          </>
        )}
        <MuiLink href={HOME} target="_blank" rel="noopener noreferrer">
          <Box display="flex" alignItems="center">
            <ArrowUpIcon />
            <Typography
              ml={2}
              variant="body2"
              color={theme.appSidebar?.color}
              fontWeight="600"
            >
              {t("sidebar.footer.homeLink")}
            </Typography>
          </Box>
        </MuiLink>
      </Box>
      <Box pt={3} borderTop={`1px solid ${theme.appSidebar?.divider}`}>
        <LinksContainer>
          <MuiLink href={IMPRESSUM} target="_blank">
            <Typography
              variant="body2"
              color={theme.appSidebar?.color}
              fontWeight="bold"
            >
              {t("sidebar.footer.imprint")}
            </Typography>
          </MuiLink>
          <Dot />
          <MuiLink href={PRIVACY} target="_blank">
            <Typography
              variant="body2"
              color={theme.appSidebar?.color}
              fontWeight="bold"
            >
              {t("sidebar.footer.privacy")}
            </Typography>
          </MuiLink>
        </LinksContainer>
      </Box>
    </Box>
  );
};
