import React, { useMemo } from "react";
import { Box, Button, Grid, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { LoadingButton, Modal, HideableField } from "@components";
import { CategoryWithChildren } from "@features/cashflowAnalyzer/types";
import { renderFormElement } from "@helpers/renderFormElement";
import { useCreateCategory } from "@features/cashflowAnalyzer/hooks/useCategories";
import { CATEGORIES_V1 } from "@api/cacheKeys";
import { FormElement } from "@helpers";

export type FormField = FormElement & {
  withDivider?: boolean;
  hidden?: (theme: Theme) => string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  categories: Record<string, CategoryWithChildren>;
};

export const AddCategoryModal = ({ isOpen, onClose, categories }: Props) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: createCategory, isLoading } = useCreateCategory({
    onSuccess: () => {
      enqueueSnackbar(`${t("cashflowAnalyzer.addCategoryModal.created")}`, {
        autoHideDuration: 3000,
        variant: "success",
      });
      queryClient.invalidateQueries(CATEGORIES_V1);
      onClose();
    },
  });

  const schema = Yup.object().shape({
    mainCategory: Yup.string().when("type", {
      is: "sub",
      then: Yup.string().required(t("form.errors.required")),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    mainCategoryName: Yup.string().when("type", {
      is: "main",
      then: Yup.string().required(t("form.errors.required")),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    subCategoryName: Yup.string().when("type", {
      is: "sub",
      then: Yup.string().required(t("form.errors.required")),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  });

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "main",
      mainCategoryName: "",
      mainCategory: undefined,
      subCategoryName: "",
    },
  });

  const type = watch("type");

  const categoryOptions = useMemo(() => {
    return Object.values(categories).reduce((acc, category) => {
      if (!category.id) return acc;
      return {
        ...acc,
        [category.id]: category.name,
      };
    }, {} as Record<string, string>);
  }, [categories]);

  const formFields: Record<string, FormField> = {
    type: {
      name: "type",
      type: "radio-group",
      size: "small",
      radioPosition: "left",
      options: [
        {
          value: "main",
          label: t("cashflowAnalyzer.addCategoryModal.main"),
          testId: "main-category",
        },
        {
          value: "sub",
          label: t("cashflowAnalyzer.addCategoryModal.sub"),
          testId: "sub-category",
        },
      ],
    },
    mainCategoryName: {
      name: "mainCategoryName",
      type: "text",
    },
    mainCategory: {
      name: "mainCategory",
      type: "select",
      options: categoryOptions,
    },
    subCategoryName: {
      name: "subCategoryName",
      type: "text",
    },
  };

  const onSubmit = (data: Record<string, any>) => {
    if (type === "main") {
      createCategory({
        body: {
          name: data.mainCategoryName,
        },
      });
    } else {
      createCategory({
        body: {
          name: data.subCategoryName,
          parentId: data.mainCategory,
        },
      });
    }
  };

  return (
    <Modal
      width={640}
      height={isLessSm ? "100%" : 535}
      title={t("cashflowAnalyzer.addCategoryModal.title")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="calc(100% - 64px)"
      >
        <Box>
          <Box mb={6}>
            {renderFormElement(
              "cashflowAnalyzer.addCategoryModal",
              formFields.type,
              control
            )}
          </Box>
          <HideableField hide={type === "sub"}>
            {renderFormElement(
              "cashflowAnalyzer.addCategoryModal",
              formFields.mainCategoryName,
              control
            )}
          </HideableField>
          <Grid container spacing={4}>
            <HideableField hide={type === "main"}>
              {renderFormElement(
                "cashflowAnalyzer.addCategoryModal",
                formFields.mainCategory,
                control,
                {
                  MenuProps: {
                    disablePortal: true,
                  },
                }
              )}
            </HideableField>
            <HideableField hide={type === "main"}>
              {renderFormElement(
                "cashflowAnalyzer.addCategoryModal",
                formFields.subCategoryName,
                control
              )}
            </HideableField>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" gap={4}>
          <Box width="50%">
            <Button variant="outlined" fullWidth onClick={onClose}>
              {t("cashflowAnalyzer.categoryModal.back")}
            </Button>
          </Box>
          <Box width="50%">
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t("cashflowAnalyzer.categoryModal.save")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
