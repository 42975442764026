import { UseQueryOptions } from "react-query";
import {
  AccountHoldersApiAccountHolderPayoutStatisticsRequest,
  AccountHolderPayoutStatisticList,
} from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const accountHolderPayoutStatistics = async (
  token: string | undefined,
  params?: AccountHoldersApiAccountHolderPayoutStatisticsRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.accountHolderPayoutStatistics(params);
  return data;
};

export const useAccountHolderPayoutStatistics = (
  params?: AccountHoldersApiAccountHolderPayoutStatisticsRequest,
  options?: UseQueryOptions<
    AccountHolderPayoutStatisticList,
    AxiosApiError,
    AccountHolderPayoutStatisticList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTHOLDERS_ACCOUNT_HOLDER_PAYOUT_STATISTICS, params],
    ({ token }) => accountHolderPayoutStatistics(token, params),
    options
  );
};
