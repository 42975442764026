import React from "react";
import { Box, Typography } from "@mui/material";
import { Investment } from "@generated/apiv1";
import { TableRow, TableBodyCell } from "@components/Table";
import { toCurrency, toPercent, toRoundedNumber } from "@helpers";
import { AccountInfo, GainLossPercents, GainLossTypography } from "@components";
import { AllocationChart } from "@components/Chart/AllocationChart";
import { BlurredTypography } from "@components/BlurredTypography";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { InvestmentInfo } from "@components/InvestmentInfo";

type Props = {
  row: Investment & {
    valuationGainAbs?: number;
    twror?: number;
    allocation?: number;
  };
  image?: string | null;
  expandableElement?: React.ReactNode;
  isChildRow?: boolean;
  showIndent?: boolean;
};

export const DashboardInvestmentRow = ({
  image,
  row,
  expandableElement,
  isChildRow = false,
  showIndent = false,
}: Props) => {
  const { isAnonymized } = useSharedPortfolioContext();
  const allocationPercent = row.allocation ?? 0;
  const hasChildren = row.aggregation?.isAggregated;

  return (
    <TableRow
      data-testid="investment-table-row"
      height={80}
      expandableElement={expandableElement}
      clickable={hasChildren}
      isDepots={true}
      isWrapped={false}
    >
      {showIndent && (
        // Rows that have aggretation will have one extra column/cell so we need the same for non-aggregated rows
        <TableBodyCell>
          <></>
        </TableBodyCell>
      )}
      <TableBodyCell>
        {isChildRow ? (
          <AccountInfo item={row} />
        ) : (
          <InvestmentInfo item={row} image={image} />
        )}
      </TableBodyCell>
      <TableBodyCell>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="body2" lineHeight="20px">
            {toRoundedNumber(row.numberOfLots)}
          </Typography>
        </Box>
      </TableBodyCell>
      <TableBodyCell align="right">
        <BlurredTypography
          variant="body2"
          sx={{
            lineHeight: "20px",
            textAlign: "right",
          }}
        >
          {toCurrency(row.currentMarketValue, row.currency)}
        </BlurredTypography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Box
          flexDirection="column"
          display="flex"
          alignItems="flex-end"
          gap={0.5}
        >
          {!isAnonymized && (
            <GainLossTypography value={row.valuationGainAbs || 0}>
              <Typography variant="body2" component="span" lineHeight="20px">
                {toCurrency(row.valuationGainAbs, row.currency)}
              </Typography>
            </GainLossTypography>
          )}
          <Box display="flex">
            <GainLossPercents value={(row.twror || 1) - 1} showZero />
          </Box>
        </Box>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <AllocationChart percent={allocationPercent} />
          <Box width={40}>
            <Typography variant="body2" lineHeight="20px">
              {toPercent(allocationPercent)}
            </Typography>
          </Box>
        </Box>
      </TableBodyCell>
    </TableRow>
  );
};
