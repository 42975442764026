import { InvestmentTypeEnum } from "@generated/apiv1";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setSymbolAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "INIT_FROM_SYMBOL" &&
    event.type !== "NAME_AND_DEPOT_SELECTED" &&
    event.type !== "INVESTMENT_INITIALIZED"
  )
    return {};

  const quoteSymbol = event.symbol;

  if (!quoteSymbol) {
    return {};
  }

  return {
    symbol: quoteSymbol,
    bookingValues: {
      ...context.bookingValues,
      entryQuote: quoteSymbol.quote?.last,
    },
    investmentValues: {
      ...context.investmentValues,
      name: quoteSymbol.name,
      isin: quoteSymbol.isin,
      wkn: quoteSymbol.wkn,
      type: quoteSymbol.type as InvestmentTypeEnum,
      tickerSymbol: quoteSymbol.quote?.tickerSymbol,
      quoteProvider: quoteSymbol.quoteProvider,
      investmentCurrency: "EUR",
      quoteCurrency: quoteSymbol.quote?.currency,
    },
  };
};
