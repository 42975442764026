import React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import {
  Box,
  FormLabel,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  padding-top: ${({ theme }) => theme.spacing(1)};
`;

interface Props {
  name: string;
  testId?: string;
  optional?: boolean;
}

type FormInputProps<T extends FieldValues> = TextFieldProps &
  Props &
  UseControllerProps<T>;

export const RHFTextField = <T extends FieldValues>(
  props: FormInputProps<T>
): JSX.Element => {
  const {
    control,
    name,
    defaultValue,
    label = "",
    variant = "outlined",
    type = "text",
    testId = "text-input",
    optional = false,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController<T>({ control, name, defaultValue });

  return (
    <Box>
      {label && (
        <FormLabel htmlFor={name}>
          {typeof label === "string" ? (
            <Typography variant="body2" component="span">
              {label}
            </Typography>
          ) : (
            label
          )}
          {optional ? (
            <Typography color="textSecondary" component="span" variant="body2">
              {" "}
              (optional)
            </Typography>
          ) : (
            ""
          )}
        </FormLabel>
      )}
      <TextField
        {...field}
        error={!!error}
        value={field.value ?? ""}
        type={type}
        variant={variant}
        inputProps={{
          "data-testid": testId,
          sx: {
            "&::placeholder": {
              color: "text.secondary",
              opacity: 1,
            },
          },
        }}
        {...rest}
      />

      {error?.message && (
        <Error>
          <Typography
            variant="caption"
            color="inherit"
            data-testid="rhf-textfield-error"
          >
            {error.message}
          </Typography>
        </Error>
      )}
    </Box>
  );
};
