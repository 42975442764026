import { UseQueryOptions } from "react-query";
import {
  IndustriesApiListAndSearchAllIndustriesRequest,
  PageableIndustryList,
} from "@generated/apiv1";

import { buildIndustriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllIndustries = async (
  token: string | undefined,
  params: IndustriesApiListAndSearchAllIndustriesRequest
) => {
  const apiInstance = buildIndustriesApi(token);
  const { data } = await apiInstance.listAndSearchAllIndustries(params);
  return data;
};

export const useListAndSearchAllIndustries = (
  params: IndustriesApiListAndSearchAllIndustriesRequest,
  options?: UseQueryOptions<
    PageableIndustryList,
    AxiosApiError,
    PageableIndustryList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INDUSTRIES_LIST_AND_SEARCH_ALL_INDUSTRIES, params],
    ({ token }) => listAndSearchAllIndustries(token, params),
    options
  );
};
