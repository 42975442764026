import React, { createContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SharingPreference } from "@generated/apiv1";
import { useMyself, UserWithType, useToken } from "@api";
import { useShowSharingPreferencesByPublicId } from "@generated/apiv1/hooks";
import { PrivatePortfolioPage } from "@features/sharedPortfolio/PrivatePortfolioPage";

export interface SharedPortfolioContextProps {
  isSharedPortfolio: boolean;
  settings: SharingPreference;
  publicId?: string;
  isAnonymized?: boolean;
  isForbidden?: boolean;
  user?: UserWithType;
  isOpenByAnon: boolean;
}

export const SharedPortfolioContext =
  createContext<SharedPortfolioContextProps>({
    isSharedPortfolio: false,
    settings: {},
    isAnonymized: false,
    isForbidden: false,
    publicId: undefined,
    user: undefined,
    isOpenByAnon: false,
  });

export const SharedPortfolioProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();
  const { data: token } = useToken();
  const sharedPortfolioId = useMemo(
    () => location.pathname.match(/\/portfolios\/([^/]+)\/?/)?.[1],
    [location.pathname]
  );
  const [isForbidden, setIsForbidden] = React.useState(false);

  const { data, isFetched } = useShowSharingPreferencesByPublicId(
    {
      publicId: sharedPortfolioId!,
    },
    {
      enabled: Boolean(sharedPortfolioId),
      onError: (err) => {
        if (err.response?.status === 404) {
          setIsForbidden(true);
        }
      },
    }
  );
  const { data: user } = useMyself({
    enabled: Boolean(sharedPortfolioId),
  });

  if (sharedPortfolioId && !isFetched) {
    return null;
  }

  return (
    <SharedPortfolioContext.Provider
      value={{
        isSharedPortfolio: Boolean(sharedPortfolioId),
        publicId: sharedPortfolioId,
        isAnonymized: data?.isAnonymized,
        isForbidden: isForbidden,
        settings: data || {},
        user,
        isOpenByAnon: Boolean(sharedPortfolioId && !token),
      }}
    >
      {sharedPortfolioId && isForbidden ? <PrivatePortfolioPage /> : children}
    </SharedPortfolioContext.Provider>
  );
};

export const useSharedPortfolioContext = () => {
  const context = React.useContext(SharedPortfolioContext);

  if (!context) {
    throw new Error(
      "useSharedPortfolioContext must be used within a SharedPortfolioProvider"
    );
  }

  return context;
};
