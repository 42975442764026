import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead } from "@mui/material";
import { TableRow, SortableTableHeader } from "@components/Table";

interface WatchlistItemsTableHeadProps {
  sortField: string;
  sortDirection: TableSortDirection;
  onSort: (sortByName: string) => void;
}

export const WatchlistItemsTableHead = (
  props: WatchlistItemsTableHeadProps
) => {
  const { sortField, sortDirection, onSort } = props;

  const { t } = useTranslation();

  const handleClick = (sortByName: string) => {
    onSort(sortByName);
  };

  return (
    <TableHead>
      <TableRow>
        <SortableTableHeader
          isSortable
          text={t("watchlistSinglePage.table.headers.name")}
          sortByName="name"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          align="right"
          isSortable
          text={t("watchlistSinglePage.table.headers.watchPrice")}
          sortByName="returnOnInvestmentInPercent"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          align="right"
          isSortable
          text={t("watchlistSinglePage.table.headers.currentPrice")}
          sortByName="quote.last"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          isSortable
          align="right"
          text={t("watchlistSinglePage.table.headers.changeToday")}
          sortByName="changePercentAbs"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          isSortable
          align="right"
          text={t("watchlistSinglePage.table.headers.oneYear")}
          sortByName="oneYearAgoInPercent"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          isSortable
          align="right"
          text={t("watchlistSinglePage.table.headers.threeYear")}
          sortByName="threeYearsAgoInPercent"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
        <SortableTableHeader
          isSortable
          align="right"
          text={t("watchlistSinglePage.table.headers.fiveYear")}
          sortByName="fiveYearsAgoInPercent"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
        />
      </TableRow>
    </TableHead>
  );
};
