/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateReservationParams
 */
export interface CreateOrUpdateReservationParams {
    /**
     * A captcha challenge token
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'challengeToken': string;
    /**
     * Name of the customer doing the reservation
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'customerName': string;
    /**
     * Email of the customer doing the reservation
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'customerEmail': string;
    /**
     * Phone number of the customer doing the reservation
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'customerPhone': string;
    /**
     * The category; determining the emailing setup
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'reservationCategory': CreateOrUpdateReservationParamsReservationCategoryEnum;
    /**
     * Type of the reservation; must match the category
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'reservationType': string;
    /**
     * Date (with time) of the reservation
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'reservationDate': string;
    /**
     * A user comment
     * @type {string}
     * @memberof CreateOrUpdateReservationParams
     */
    'comment'?: string;
}

export const CreateOrUpdateReservationParamsReservationCategoryEnum = {
    ADVISING: 'advising'
} as const;

export type CreateOrUpdateReservationParamsReservationCategoryEnum = typeof CreateOrUpdateReservationParamsReservationCategoryEnum[keyof typeof CreateOrUpdateReservationParamsReservationCategoryEnum];


