import { useMemo } from "react";
import i18next from "i18next";
import { Investment } from "@generated/apiv1";
import { usePublicOrPrivateInvestments } from "@features/sharedPortfolio/hooks/usePublicOrPrivateInvestments";
import { ChartBreakdown } from "@features/analysis/components/types";
import { useSecurityImages } from "@hooks";

export const convertToChartBreakdown = (
  investments?: Investment[],
  logos?: Record<string, string | undefined | null>
): ChartBreakdown[] => {
  if (!investments) return [];

  const uniqueTagClasses = investments.reduce(
    (acc, investment) => {
      const key = investment.tag?.value || "unclassified";

      return {
        ...acc,
        [key]: {
          amount: (acc[key]?.amount || 0) + 1,
          marketValue:
            (acc[key]?.marketValue || 0) +
            (investment.currentMarketValueAC || 0),
          investments: [...(acc[key]?.investments || []), investment],
        },
      };
    },
    {} as Record<
      number | string,
      {
        amount: number;
        marketValue: number;
        investments: Investment[];
      }
    >
  );

  const tagClasses = Object.keys(uniqueTagClasses).map(
    (tagClass) =>
      ({
        id: tagClass,
        uid: tagClass,
        value: uniqueTagClasses[tagClass].marketValue,
        name:
          tagClass !== "unclassified"
            ? tagClass
            : i18next.t("tagClassification.unclassified"),
        currency: "EUR",
        hasChildren: true,
        y: uniqueTagClasses[tagClass].marketValue,
      } as ChartBreakdown)
  );

  const investmentsChartData = Object.keys(uniqueTagClasses).reduce(
    (acc, tagClass) => {
      const investments = uniqueTagClasses[tagClass].investments;

      return [
        ...acc,
        ...investments.map(
          (investment) =>
            ({
              id: investment.id,
              uid: investment.id,
              parentId: tagClass,
              hasChildren: false,
              value: investment.currentMarketValueAC || 0,
              name: investment.standardisedName || investment.name,
              currency: "EUR",
              y: investment.currentMarketValueAC || 0,
              icon:
                logos?.[investment.isin] || logos?.[investment.tickerSymbol],
            } as ChartBreakdown)
        ),
      ];
    },
    [] as ChartBreakdown[]
  );

  return [...tagClasses, ...investmentsChartData].sort(
    (a, b) => b.value - a.value
  );
};

export const useTagBreakdowns = (accountIds?: number[]) => {
  const {
    investments,
    isFetching: isLoading,
    isFetched,
  } = usePublicOrPrivateInvestments(
    {
      accountId: accountIds,
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const logos = useSecurityImages(investments);
  const breakdowns = useMemo(
    () => convertToChartBreakdown(investments, logos),
    [investments, logos]
  );

  return {
    data: breakdowns,
    isLoading,
    isFetched,
  };
};
