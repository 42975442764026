/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateAccountParams
 */
export interface CreateOrUpdateAccountParams {
    /**
     * The bank account type ID; as provided by the multibanking provider
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'bankAccountTypeId'?: number;
    /**
     * The technical bank account type
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'type': CreateOrUpdateAccountParamsTypeEnum;
    /**
     * An external ID; only available if bankConnectionType is WEALTH_API;              may only be set when creating the account. Must be unique for this user.
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'externalId'?: string;
    /**
     * Identifier of the bank connection; if any. This attribute should typically be used         during programmatic account creation when interacting with a multibanking API such as finAPI wor wealthAPI.         If you find yourself setting this parameter in the frontend, this is most likely a mistake
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'bankConnectionId'?: number;
    /**
     * The type of bank connection; if any; may only be set when creating the account
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'bankConnectionType'?: CreateOrUpdateAccountParamsBankConnectionTypeEnum;
    /**
     * Mark this account as hidden, default is false
     * @type {boolean}
     * @memberof CreateOrUpdateAccountParams
     */
    'isHidden'?: boolean;
    /**
     * The bank account name
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'name': string;
    /**
     * The IBAN; may only be set when creating the account
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'iban'?: string;
    /**
     * The account number; a German legacy standard for identifying accounts.         May only be set when creating the account
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'accountNumber'?: string;
    /**
     * The BIC
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'bic'?: string;
    /**
     * Name of the bank the account belongs to
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'bankName'?: string;
    /**
     * The account currency as 3-letter ISO code, e.g. EUR; default is EUR
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'currency'?: string;
    /**
     * Pre-set default commission
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'defaultCommission'?: number;
    /**
     * Enable visibility for public, default is private
     * @type {string}
     * @memberof CreateOrUpdateAccountParams
     */
    'visibility'?: CreateOrUpdateAccountParamsVisibilityEnum;
    /**
     * Enable implicit transactions; default is false during account creation.         When implicit transactions are enabled, the finance manager will automatically create         a correction deposit whenever the account balance drops below zero. If there is a positive         balance on the account, it will be used. This mimics the behavior of a real investor who         would only transfer money when needed.
     * @type {boolean}
     * @memberof CreateOrUpdateAccountParams
     */
    'enableImplicitTransactions'?: boolean;
    /**
     * A default tax rate
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'taxRate'?: number;
    /**
     * Assign the given contra account; or create a new one (the default). Ignored during updates
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'contraAccountId'?: number;
    /**
     * ID of this accounts sub-category (if any)
     * @type {number}
     * @memberof CreateOrUpdateAccountParams
     */
    'subCategoryId'?: number;
}

export const CreateOrUpdateAccountParamsTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _03_USER_DEFINED: '03_user_defined',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;

export type CreateOrUpdateAccountParamsTypeEnum = typeof CreateOrUpdateAccountParamsTypeEnum[keyof typeof CreateOrUpdateAccountParamsTypeEnum];
export const CreateOrUpdateAccountParamsBankConnectionTypeEnum = {
    WEALTH_API: 'WEALTH_API'
} as const;

export type CreateOrUpdateAccountParamsBankConnectionTypeEnum = typeof CreateOrUpdateAccountParamsBankConnectionTypeEnum[keyof typeof CreateOrUpdateAccountParamsBankConnectionTypeEnum];
export const CreateOrUpdateAccountParamsVisibilityEnum = {
    PUBLIC: 'public',
    PRIVATE: 'private'
} as const;

export type CreateOrUpdateAccountParamsVisibilityEnum = typeof CreateOrUpdateAccountParamsVisibilityEnum[keyof typeof CreateOrUpdateAccountParamsVisibilityEnum];


