import { LinearProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const getRandomInt = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));

const useMessage = () => {
  const { t } = useTranslation();
  const messages: string[] = t("bankConnections.inProgress.messages", {
    returnObjects: true,
  });
  const [message, setMessage] = useState(messages[0]);

  useEffect(() => {
    const interval = setInterval(
      () => setMessage(messages[getRandomInt(messages.length)]),
      5000
    );
    return () => {
      clearInterval(interval);
    };
  }, [messages]);

  return message;
};

export const InProgress = () => {
  const message = useMessage();
  return (
    <div data-testid="in-progress">
      <LinearProgress />
      <Typography>{message}</Typography>
    </div>
  );
};
