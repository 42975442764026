import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { GroupedBank } from "@components/BankSelect";
import { Logo } from "@components";
import { SearchHighlight } from "../../SearchHighlight";

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  max-width: 450px;
  display: grid;
  grid-template-columns: 45px auto;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  & > *:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.neutral};
  }
  cursor: pointer;
`;

const BankOptionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(1, 2),

  "&:hover": {
    backgroundColor: theme.palette.background.subtle,
    cursor: "pointer",
  },
}));

const BankImage = styled(Box)(({ theme }) => ({
  borderRadius: "6px",
  overflow: "hidden",
  width: "44px",
  height: "44px",
  flexShrink: 0,
  backgroundColor: theme.palette.background.paper,

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export const Bics = styled(Typography)`
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  searchValue?: string;
  bank: GroupedBank;
  onClick: (bank: GroupedBank) => void;
}

export const BankOption = ({ bank, searchValue, onClick }: Props) => {
  return (
    <Grid
      item
      md={6}
      sm={6}
      xs={12}
      key={bank.ids.join("-")}
      onClick={() => onClick(bank)}
      data-testid="bank-option"
    >
      <BankOptionContainer>
        <BankImage>
          {bank.iconUrl && !bank.iconUrl.includes("BANK_square") ? (
            <img src={bank.iconUrl} alt={bank.name} />
          ) : (
            <Logo variant="asset" width="100%" height="100%" />
          )}
        </BankImage>
        <Box
          minWidth={0}
          width="100%"
          display="flex"
          flexDirection="column"
          sx={{
            "& span": {
              width: "100%",
              minWidth: 0,
            },
          }}
        >
          <SearchHighlight
            value={bank.name}
            searchValue={searchValue}
            variant="body1"
            noWrap
          />
          <SearchHighlight
            value={bank.banks.map((b) => b.bic).join(", ")}
            searchValue={searchValue}
            variant="body2"
            color="textSecondary"
            noWrap
          />
        </Box>
      </BankOptionContainer>
    </Grid>
  );
};
