import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Banner, DepotSelect } from "@components";
import {
  CsvImportParseAttributesBankSlugEnum,
  CsvParseResult,
  ParseCsvBankSlugEnum,
} from "@generated/apiv3";
import { FileDropField } from "@components/common/forms/FileDropField";
import { ReactComponent as SuccessIcon } from "@icons/success.svg";
import { ReactComponent as ErrorIcon } from "@icons/error.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { useCsvUpload } from "@api/v3/hooks/useCsvUpload";

type FormatSelectItem = {
  value: ParseCsvBankSlugEnum;
  name: string;
};

const formats: FormatSelectItem[] = [
  {
    value: ParseCsvBankSlugEnum.GENERIC,
    name: "Generisches Format",
  },
  {
    value: ParseCsvBankSlugEnum.PARQET,
    name: "Parqet",
  },
  {
    value: ParseCsvBankSlugEnum.SCALABLE_CAPITAL,
    name: "Scalable Capital",
  },
  {
    value: ParseCsvBankSlugEnum.EXTRA_ETF,
    name: "Extra ETF",
  },
  {
    // @ts-ignore TODO: fix this
    value:
      CsvImportParseAttributesBankSlugEnum.PORTFOLIO_PERFORMANCE_SECURITIES,
    name: "Portfolio Performance (Depot)",
  },
  {
    // @ts-ignore TODO: fix this
    value: CsvImportParseAttributesBankSlugEnum.PORTFOLIO_PERFORMANCE_CASH,
    name: "Portfolio Performance (Konto)",
  },
];

const UploadStatus = ({
  status,
  fileName,
  onFileDelete,
}: {
  status: "success" | "fail";
  fileName: string;
  onFileDelete: (event: React.MouseEvent) => void;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={3}
      width="100%"
      justifyContent="center"
    >
      <Box display="flex" alignItems="center" flexShrink={0}>
        {status === "success" ? <SuccessIcon /> : <ErrorIcon />}
      </Box>
      <Typography variant="body2" fontWeight={400} noWrap maxWidth="300px">
        {fileName}
      </Typography>
      <IconButton
        onClick={onFileDelete}
        size="small"
        sx={{
          cursor: "pointer",
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

type Props = {
  onCsvUpload: (parsingResult: CsvParseResult) => void;
  changeStep: (depotId: number, selectedFormat: ParseCsvBankSlugEnum) => void;
};

export const SelectFileAndFormat = ({ onCsvUpload, changeStep }: Props) => {
  const { t } = useTranslation();
  const { depotId } = useParams();
  const isSingleDepotPage = Boolean(depotId);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const [selectedDepotId, setSelectedDepotId] = React.useState<
    number | undefined
  >(depotId ? Number(depotId) : undefined);
  const [selectedFormat, setSelectedFormat] =
    React.useState<ParseCsvBankSlugEnum>(formats[0].value);
  const [fileStatus, setFileStatus] = React.useState<{
    fileName: string;
    status: "success" | "fail";
    error?: string;
  } | null>(null);

  const isNextDisabled =
    !selectedDepotId || !selectedFormat || fileStatus?.status !== "success";

  const { mutate: uploadCSV, isLoading: isParsing } = useCsvUpload();

  const handleUploadCsv = (file: File[]) => {
    const formData = new FormData();
    formData.append("file", file[0], file[0].name);

    uploadCSV(
      {
        file: formData.get("file"),
        bankSlug: selectedFormat,
      },
      {
        onSuccess: (data) => {
          const isFailed = data.status !== "ok" || !data.result?.length;
          setFileStatus({
            fileName: file[0].name,
            status: isFailed ? "fail" : "success",
            error: isFailed ? t("csvImport.csvUpload.error") : undefined,
          });
          onCsvUpload(data);
        },
        onError: (data) => {
          setFileStatus({
            fileName: file[0].name,
            status: "fail",
            // @ts-ignore
            error: data.response?.data?.[0].detail,
          });
        },
      }
    );
  };

  const goBack = () => {
    window.history.back();
  };

  const handleFileDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    setFileStatus(null);
  };

  const handleDepotChange = (depotId: number | null) => {
    if (isSingleDepotPage) {
      navigate(`/depots/${depotId}/csv-drop`, {
        replace: true,
      });
    } else {
      setSelectedDepotId(depotId ?? undefined);
    }
  };

  return (
    <Box maxWidth={716} display="flex" flexDirection="column" gap={6}>
      <DepotSelect
        onDepotChange={handleDepotChange}
        allowNewDepot={false}
        selectedDepotId={selectedDepotId}
      />
      <FormControl fullWidth={true}>
        <InputLabel>{t("csvImport.formatSelector.label")}</InputLabel>
        <Select
          data-testid="select-format"
          displayEmpty={true}
          value={selectedFormat}
          MenuProps={{
            sx: { maxHeight: 300 },
          }}
          onChange={(event) => {
            setSelectedFormat(event.target.value as ParseCsvBankSlugEnum);
          }}
          inputProps={{ "data-testid": "select-format-input" }}
        >
          {formats.map((format, ix) => {
            return (
              <MenuItem
                key={format.value}
                data-testid={`select-format-${ix}`}
                value={format.value}
              >
                {format.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {selectedFormat === ParseCsvBankSlugEnum.GENERIC && (
        <Typography color="textSecondary" variant="body2">
          <Trans
            components={{
              linkText: (
                <Link
                  href={`${process.env.REACT_APP_V2_URL}/generic-beispiel.csv`}
                />
              ),
            }}
            i18nKey="csvImport.csvUpload.genericHint"
          />
        </Typography>
      )}
      <FileDropField
        isParsing={isParsing}
        onDrop={handleUploadCsv}
        text={
          fileStatus ? (
            <UploadStatus
              status={fileStatus.status}
              fileName={fileStatus.fileName}
              onFileDelete={handleFileDelete}
            />
          ) : (
            t("csvImport.csvUpload.text")
          )
        }
        accept={{ "application/csv": [".csv"] }}
        maxFiles={1}
      />
      {fileStatus?.status === "fail" && (
        <Banner
          type="error"
          icon={<ErrorIcon />}
          text={
            <Typography color="error.dark" variant="body1" fontWeight={400}>
              {fileStatus?.error}
            </Typography>
          }
        />
      )}
      <Box
        display="flex"
        flexDirection={isLessSm ? "column" : "row"}
        justifyContent="flex-start"
        gap={3}
      >
        <Button
          variant="contained"
          color="grey"
          onClick={goBack}
          fullWidth={isLessSm}
        >
          {t("navigation.previous")}
        </Button>
        <Button
          fullWidth={isLessSm}
          disabled={isNextDisabled}
          variant="contained"
          color="primary"
          onClick={() => changeStep(selectedDepotId!, selectedFormat)}
        >
          {t("navigation.next")}
        </Button>
      </Box>
    </Box>
  );
};
