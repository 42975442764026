import { ChartData } from "@features/dividendPlanner/OverViewTab/HistoryDataCard";
import { DividendHistoryTree, DividendYieldList } from "@generated/apiv1";
import { getDividends } from "@components/Chart/DashboardDividendsChart/helpers";

export const calculateHistoryData = (
  historyData?: DividendHistoryTree,
  dividendYieldData?: DividendYieldList
) => {
  let chartBarsData: ChartData[] = Array(12)
    .fill(0)
    .map((item, idx) => ({
      month: idx,
      value: 0,
    }));

  let data;

  if (historyData?.nodesByYear && dividendYieldData?.dividendYields) {
    const nodesByYear = Object.values(historyData?.nodesByYear).reverse();
    const investmentReferences = historyData.investmentReferenceById;

    data = nodesByYear.map((yearItem) => {
      return {
        year: yearItem.year,
        grossYield:
          dividendYieldData?.dividendYields?.find(
            (dividendItem) => dividendItem.year === yearItem.year
          )?.grossYield || 0,
        currentYearOverallDividends: yearItem.grossAmount || 0,
        previousYearOverallDividends:
          historyData?.nodesByYear?.[yearItem.year - 1]?.grossAmount || 0,
        chartBarsData: chartBarsData.map((monthItem, idx) => {
          const { month } = monthItem;
          return {
            month: yearItem.children?.[month]?.month || idx,
            value: yearItem.children?.[month]?.grossAmount || 0,
          };
        }),
        dividends: getDividends(yearItem, investmentReferences),
      };
    });
  }

  return data || [];
};
