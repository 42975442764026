import { UseMutationOptions } from "react-query";
import { BookingsApiDeleteBookingRequest } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBooking = async (
  token: string | undefined,
  params: BookingsApiDeleteBookingRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.deleteBooking(params);
  return data;
};

export const useDeleteBooking = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BookingsApiDeleteBookingRequest
  >
) => {
  return useAuthMutation(deleteBooking, options, false);
};
