import React from "react";
import { useTranslation } from "react-i18next";
import { useMachine } from "@xstate/react";
import { Box } from "@mui/material";
import truncate from "lodash/truncate";
import { InvestmentTypeEnum } from "@api";
import { LoadingProgressBar, PageLayout } from "@components";
import { InvestmentTypeStep } from "@features/transactionWizard/steps/InvestmentTypeStep";
import { QuoteV2, SymbolV2, Symbol } from "@generated/apiv1";
import { newTransactionWizardSM } from "./stateMachine/newTransactionWizardSM";
import { SearchSymbolStep } from "./steps/SearchSymbolStep";
import { useStateHelpers } from "./hooks/useStateHelpers";
import { NewTransactionSteps } from "./NewTransactionSteps";

export interface Props {
  tickerSymbol?: string;
  quoteProvider?: string;
  depotId?: number;
}

export const NewTransactionWizard = ({
  tickerSymbol,
  quoteProvider,
  depotId,
}: Props) => {
  const [currentState, sendEvent] = useMachine(newTransactionWizardSM);
  const { isLoadingState } = useStateHelpers(currentState);
  const initFromSearch = !tickerSymbol || !quoteProvider;
  const initFromTickerSymbol = Boolean(tickerSymbol && quoteProvider);
  const { t } = useTranslation();
  const investmentValues =
    currentState.context?.sourceInvestmentValues ||
    currentState.context?.investmentValues;

  const backOneStep = () => sendEvent({ type: "BACK" });

  let breadcrumbs = [
    { to: "/", text: t("breadcrumbs.home") },
    { to: "/investments", text: t("breadcrumbs.investments") },
    { text: t("transactionWizard.new.title") },
  ];
  let securityPageBreadCrumb = {
    to: `/securities?id=${investmentValues?.isin}&quoteProvider=${investmentValues?.quoteProvider}`,
    text: truncate(investmentValues?.name),
  };

  const shouldShowBreadcrumb =
    investmentValues?.isin && investmentValues?.quoteProvider;

  if (shouldShowBreadcrumb) breadcrumbs.splice(2, 0, securityPageBreadCrumb);

  return (
    <PageLayout breadcrumbs={breadcrumbs} isLoading={isLoadingState()}>
      <Box>
        {currentState.matches("idle") &&
          initFromSearch &&
          sendEvent({
            type: "INIT_FROM_SYMBOL_SEARCH",
            depotId: depotId,
          }) && <LoadingProgressBar />}

        {currentState.matches("idle") &&
          !initFromSearch &&
          sendEvent({
            type: "INIT_FROM_TICKER",
            quoteProvider: quoteProvider!,
            tickerSymbol: tickerSymbol!,
            depotId: depotId,
          }) && <LoadingProgressBar />}

        {currentState.matches("selectInvestmentType") && (
          <InvestmentTypeStep
            handleNext={(investmentType: InvestmentTypeEnum) => {
              sendEvent({ type: "INVESTMENT_TYPE_SELECTED", investmentType });
            }}
            handlePrevious={() => window.history.back()}
          />
        )}

        {currentState.matches("searchSymbol") && (
          <SearchSymbolStep
            handleNext={(symbol: Symbol | (SymbolV2 & { quote: QuoteV2 })) => {
              sendEvent({ type: "INIT_FROM_SYMBOL", symbol: symbol });
            }}
            handleManualInvestmentClick={() =>
              sendEvent({ type: "MANUAL_SELECT" })
            }
            isExchangeOrCarveOut={Boolean(
              currentState.context.bookingValues.type === "switch" ||
                currentState.context.bookingValues.type === "carve_out"
            )}
            handlePrevious={backOneStep}
            type={currentState.context.investmentValues.type}
            depotId={depotId}
          />
        )}

        <NewTransactionSteps
          currentState={currentState}
          sendEvent={sendEvent}
          initFromTickerSymbol={initFromTickerSymbol}
          depotId={depotId}
        />
      </Box>
    </PageLayout>
  );
};
