import { UseMutationOptions } from "react-query";
import {
  InternalTransactionsApiMarkBatchTransferAsExecutedRequest,
  InternalTransactionList,
} from "@generated/apiv1";

import { buildInternalTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const markBatchTransferAsExecuted = async (
  token: string | undefined,
  params: InternalTransactionsApiMarkBatchTransferAsExecutedRequest
) => {
  const apiInstance = buildInternalTransactionsApi(token);
  const { data } = await apiInstance.markBatchTransferAsExecuted(params);
  return data;
};

export const useMarkBatchTransferAsExecuted = (
  options?: UseMutationOptions<
    InternalTransactionList,
    AxiosApiError,
    InternalTransactionsApiMarkBatchTransferAsExecutedRequest
  >
) => {
  return useAuthMutation(markBatchTransferAsExecuted, options, false);
};
