import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { format, parseJSON } from "date-fns";
import {
  Box,
  IconButton,
  TableBody,
  TableHead,
  Typography,
  TableRow,
} from "@mui/material";
import { useQueryClient } from "react-query";
import {
  toCurrency,
  toPercent,
  getValueByInvestmentAndBookingType,
  getNumberOfLotsByInvestmentAndBookingType,
} from "@helpers";
import { TableBodyCell, Tooltip, GainLossTypography } from "@components";
import { SortableTableHeader } from "@components/Table";
import { ReactComponent as ChatIcon } from "@icons/myicons/chat.svg";
import {
  BOOKING_WITH_NOL_ESTIMATED,
  DELTA_RESOLUTION,
  NEGATIVE_NOL_DELTA_RESOLUTION,
} from "@constants";
import { EditBookingModal } from "@components/Accounts/TransactionTable/EditBookingModal";
import { EditDividendModal } from "@components/Accounts/TransactionTable/EditDividendModal";
import { BookingTypeEnum } from "@generated/apiv1";
import { useDeleteBooking } from "@api";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { GET_REALIZED_GAINS } from "@api/cacheKeys";
import { ActionsButton } from "@components/ActionsButton";
import { BookingType } from "@components/BookingType";
import { useTableSortWithData } from "@hooks";
import { VirtualBookingTooltip } from "@features/investments/components/VirtualBookingTooltip";
import {
  BookingSingleInvestmentTableProps,
  TypographyColored,
  ValueDateWrapper,
} from "./BookingsSingleInvestmentTable";

export const BookingsTableDesktopView = ({
  bookings,
  currencySymbol,
  currentMarketValue,
}: Omit<BookingSingleInvestmentTableProps, "rangeData">) => {
  const { t } = useTranslation();

  const [currentBookingIdEditing, setCurrentBookingIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentInvestmentIdEditing, setCurrentInvestmentIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentBookingIdDeleting, setCurrentBookingIdDeleting] = useState<
    number | undefined
  >(undefined);
  const [isEditTransactionModalOpened, setIsEditTransactionModalOpened] =
    useState(false);
  const [isEditDividendModalOpened, setIsEditDividendModalOpened] =
    useState(false);
  const [isDeleteBookingModalOpened, setIsDeleteBookingModalOpened] =
    useState(false);
  const [tickerSymbol, setTickerSymbol] = useState<string | undefined>(
    undefined
  );

  const {
    data: sortedItems = [],
    sortField,
    sortDirection,
    sort,
  } = useTableSortWithData(bookings ?? [], {
    field: "valueDate",
    direction: "DESC",
  });

  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isDeleteRequestLoading,
    isError: isDeleteRequestError,
    reset: mutationReset,
  } = useDeleteBooking({
    onSuccess: () => {
      setIsDeleteBookingModalOpened(false);
      queryClient.refetchQueries(GET_REALIZED_GAINS);
    },
    retry: false,
  });

  const handleBookingTypeEditing = (
    bookingType?: BookingTypeEnum,
    bookingId?: number,
    investmentId?: number,
    tickerSymbol?: string
  ) => {
    setCurrentInvestmentIdEditing(investmentId);
    setCurrentBookingIdEditing(bookingId);
    setTickerSymbol(tickerSymbol);
    if (bookingType === "dividend") {
      setIsEditDividendModalOpened(true);
      return;
    }
    setIsEditTransactionModalOpened(true);
  };

  const handleDeleteBooking = (bookingId?: number) => {
    setIsDeleteBookingModalOpened(true);
    setCurrentBookingIdDeleting(bookingId);
  };

  const onSort = (sortedField: string) => {
    sort(sortedField);
  };

  const handleClick = (sortByName: string) => {
    onSort(sortByName);
  };

  const bookingList = sortField ? sortedItems : bookings;
  return (
    <>
      <TableHead>
        <TableRow>
          <SortableTableHeader
            text={t("investment.protocolTable.bookingType")}
            sortByName="valueDate"
            sortField={sortField}
            sortDirection={sortDirection}
            onClick={handleClick}
            isSortable
          />
          <SortableTableHeader
            text={t("investment.protocolTable.amount")}
            isSortable
            sortByName="securityPrice"
            sortField={sortField}
            sortDirection={sortDirection}
            onClick={handleClick}
          />

          <SortableTableHeader
            text={t("investment.protocolTable.expenses")}
            isSortable
            sortField={sortField}
            sortDirection={sortDirection}
            onClick={handleClick}
            sortByName="expenses"
          />

          <SortableTableHeader
            align="right"
            text={t("investment.protocolTable.totalSum")}
            isSortable
            sortField={sortField}
            sortDirection={sortDirection}
            onClick={handleClick}
            sortByName="amount"
          />

          <SortableTableHeader
            text={""}
            isSortable={false}
            sortField={sortField}
            sortDirection={sortDirection}
            sortByName="bookingType"
            align="right"
          />
        </TableRow>
      </TableHead>
      <TableBody>
        {bookings.length === 0 && (
          <TableRow>
            <td colSpan={5}>
              <Typography variant="body1" align="center">
                {t("investment.protocolTable.noBookings")}
              </Typography>
            </td>
          </TableRow>
        )}
        <ConfirmDeleteDialog
          isOpen={isDeleteBookingModalOpened}
          title={t("singleAccount.deleteModal.title")}
          text={t("singleAccount.deleteModal.text")}
          cancelButtonText={t("singleAccount.deleteModal.cancel")}
          confirmButtonText={t("singleAccount.deleteModal.confirm")}
          onClose={() => {
            setIsDeleteBookingModalOpened(false);
            mutationReset();
          }}
          onDelete={() => mutate(currentBookingIdDeleting!)}
          errorMessage={
            isDeleteRequestError
              ? t("singleAccount.deleteModal.errorOccurred")
              : ""
          }
          isDeleteRequestLoading={isDeleteRequestLoading}
        />
        {isEditTransactionModalOpened && currentBookingIdEditing && (
          <EditBookingModal
            isOpen={isEditTransactionModalOpened}
            onClose={() => setIsEditTransactionModalOpened(false)}
            investmentId={currentInvestmentIdEditing}
            bookingId={currentBookingIdEditing}
            tickerSymbol={tickerSymbol}
            invalidateBookings
          />
        )}

        {isEditDividendModalOpened &&
          currentBookingIdEditing &&
          currentInvestmentIdEditing && (
            <EditDividendModal
              isOpen={isEditDividendModalOpened}
              onClose={() => setIsEditDividendModalOpened(false)}
              investmentId={currentInvestmentIdEditing}
              bookingId={currentBookingIdEditing}
              tickerSymbol={tickerSymbol}
              invalidateBookings
            />
          )}
        {bookingList.map(
          ({
            id,
            valueDate,
            bookingType,
            numberOfLots,
            securityPrice,
            priceChange,
            expenses,
            amount,
            comment,
            grossGain,
            investmentId,
            investmentType,
            investmentTickerSymbol,
            currency,
            dataConsistencyStatus,
          }) => {
            const numberOfLotsText = getNumberOfLotsByInvestmentAndBookingType({
              value: numberOfLots,
              bookingType,
            });
            const valueText = getValueByInvestmentAndBookingType({
              value: securityPrice,
              investmentType,
              bookingType,
              percentageRange: "0-100",
              currency,
            });
            const amountText = Boolean(numberOfLotsText)
              ? numberOfLotsText + " " + valueText
              : valueText;

            return (
              <TableRow key={`booking-${id}`}>
                <TableBodyCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <BookingType type={bookingType} />
                    {(dataConsistencyStatus === BOOKING_WITH_NOL_ESTIMATED ||
                      dataConsistencyStatus === DELTA_RESOLUTION ||
                      dataConsistencyStatus ===
                        NEGATIVE_NOL_DELTA_RESOLUTION) && (
                      <VirtualBookingTooltip
                        dataConsistencyStatus={dataConsistencyStatus}
                      />
                    )}
                    {comment && (
                      <Tooltip
                        title={t("investment.protocolTable.comment")}
                        text={comment}
                      >
                        <IconButton
                          size="small"
                          sx={{
                            transform: "scaleX(-1)",
                            width: "26px",
                            height: "26px",
                          }}
                        >
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <ValueDateWrapper>
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      color="textSecondary"
                    >
                      {format(parseJSON(valueDate), "dd.MM.yyyy")}
                    </Typography>
                  </ValueDateWrapper>
                </TableBodyCell>
                <TableBodyCell align="left">
                  <Typography fontSize={14} noWrap>
                    {amountText}
                  </Typography>
                  {priceChange ? (
                    <div>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        color="textsSecondary"
                        display="inline"
                      >
                        {t("investment.protocolTable.gainersAndLosersLabel")}:{" "}
                      </Typography>
                      <TypographyColored
                        fontSize={14}
                        fontWeight={400}
                        lots={priceChange}
                        display="inline"
                      >
                        {toPercent(priceChange)}
                      </TypographyColored>
                    </div>
                  ) : null}
                </TableBodyCell>
                <TableBodyCell align="left">
                  <Typography fontSize={14}>
                    {toCurrency(expenses, currencySymbol)}
                  </Typography>
                </TableBodyCell>
                <TableBodyCell align="right">
                  <Typography fontSize={14}>
                    {toCurrency(amount, currencySymbol)}
                  </Typography>
                  {bookingType === "sell" && grossGain ? (
                    <GainLossTypography
                      variant="body2"
                      value={grossGain}
                      showBackground
                      textAlign="right"
                      sx={{
                        display: "inline-flex",
                      }}
                    >
                      {toCurrency(grossGain, currencySymbol)}{" "}
                    </GainLossTypography>
                  ) : null}
                </TableBodyCell>
                <TableBodyCell align="right">
                  <ActionsButton
                    onEditItemClick={
                      bookingType === "split" || bookingType === "reverse_split"
                        ? undefined
                        : () =>
                            handleBookingTypeEditing(
                              bookingType,
                              id,
                              investmentId,
                              investmentTickerSymbol
                            )
                    }
                    onDeleteItemClick={() => handleDeleteBooking(id)}
                    editItemText={t("singleAccount.editTransaction")}
                    deleteItemText={t("singleAccount.deleteTransaction")}
                  />
                </TableBodyCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </>
  );
};
