import React, { useEffect, useMemo } from "react";
import { Box, TableContainer } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTableSortWithData, useRangeSelectControl } from "@hooks";
import { LoadingPlaceHolder, Table } from "@components";
import {
  getStoredSortParams,
  setStoredSortParams,
} from "@helpers/localStorage";
import { BookingsTableHead } from "@features/investments/components/BookingsTable/BookingsTableHead";
import { BookingsTableBody } from "@features/investments/components/BookingsTable/BookingsTableBody";
import { BookingsFilters } from "@features/investments/components/BookingsTable/BookingsFilters";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { AssetCard } from "@features/investments/components/Assets/AssetTypeCard";
import { usePublicOrPrivateBookings } from "@features/sharedPortfolio/hooks/usePublicOrPrivateBookings";
import { BookingTypeEnum, InvestmentTypeEnum } from "@generated/apiv1";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import { assetCardsToInvestmentType } from "@features/investments/components/helpers/assetCards";

interface BookingsTableProps {
  accountIds: number[];
  selectedCards: AssetCard[];
  onInvestmentTypeChange: (assetType: InvestmentTypeEnum[]) => void;
}

const INVESTMENTS_BOOKINGS_TABLE_KEY = "investmentsBookingsTable";

export const BookingsTableContainer = ({
  accountIds = [],
  selectedCards,
  onInvestmentTypeChange,
}: BookingsTableProps) => {
  const [selectedInvestment, setSelectedInvestment] = React.useState<
    string | null
  >(null);
  const [selectedBookingType, setSelectedBookingType] = React.useState<
    BookingTypeEnum[]
  >([]);
  const { range, intervalType, customIntervalSelect, selectInterval } =
    useRangeSelectControl({
      firstDate: new Date(),
      defaultIntervalType: "sinceBeginning",
    });
  const selectedInvestmentType = assetCardsToInvestmentType(selectedCards);
  const {
    bookings,
    hasNextPage,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    isLoading: isTableDataLoading,
  } = usePublicOrPrivateBookings(
    {
      accountId: accountIds,
      perPage: 100,
      // @ts-ignore TODO: backend fix
      type: selectedBookingType,
      isin: selectedInvestment ? [selectedInvestment] : undefined,
      investmentType: selectedInvestmentType,
      minDate: range?.startDate?.toISOString(),
      maxDate: range?.endDate.toISOString(),
      order: "valueDate DESC",
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const formattedBookings = useMemo(() => {
    return bookings?.map((booking) => ({
      ...booking,
      amount: Math.abs(booking.amount),
      numberOfLots:
        booking.type === "dividend" || booking.type === "purchase_right"
          ? 0
          : booking.numberOfLots,
      securityPriceForSorting: Math.abs(booking.securityPrice),
    }));
  }, [bookings]);

  const {
    data: sortedItems = [],
    sortField,
    sortDirection,
    sort,
  } = useTableSortWithData(formattedBookings ?? [], {
    field: "valueDate",
    direction: "DESC",
  });

  const handleSort = (name: string) => {
    sort(name);
  };

  const handleInvestmentTypeChange = (value: InvestmentTypeEnum[]) => {
    onInvestmentTypeChange(value);
  };

  useEffect(() => {
    if (!sortDirection || !sortField) return;
    setStoredSortParams(
      sortField,
      sortDirection,
      INVESTMENTS_BOOKINGS_TABLE_KEY
    );
  }, [sortDirection, sortField]);

  useEffect(() => {
    const item = getStoredSortParams(INVESTMENTS_BOOKINGS_TABLE_KEY);
    if (!item) return;
    const { sortDirection, sortField } = item;
    sort(sortField, sortDirection);
    // eslint-disable-next-line
  }, []);

  if (!isFetched && isTableDataLoading) {
    return <LoadingPlaceHolder />;
  }

  return (
    <Box>
      <BookingsFilters
        accountIds={accountIds}
        onInvestmentTypeChange={handleInvestmentTypeChange}
        selectedInvestmentType={selectedInvestmentType}
        selectedInvestment={selectedInvestment}
        selectedBookingType={selectedBookingType}
        onBookingTypeChange={setSelectedBookingType}
        onInvestmentChange={setSelectedInvestment}
        range={range}
        intervalType={intervalType}
        onSelectInterval={selectInterval}
        onSelectCustomInterval={customIntervalSelect}
        disabled={isTableDataLoading}
      />
      <WithLoadingSpinner isLoading={isFetching && !isFetchingNextPage}>
        <Box>
          <TableContainer>
            <Box sx={{ overflowX: "auto" }}>
              <InfiniteScroll
                next={fetchNextPage}
                dataLength={sortedItems?.length || 0}
                hasMore={hasNextPage || false}
                loader={isFetchingNextPage && hasNextPage ? <div></div> : null}
              >
                <Table>
                  <BookingsTableHead
                    onSort={handleSort}
                    sortField={sortField || ""}
                    sortDirection={sortDirection || "NONE"}
                  />
                  <BookingsTableBody data={sortedItems} />
                </Table>
              </InfiniteScroll>
            </Box>

            {!isTableDataLoading && !sortedItems.length && <NoDataStub />}
          </TableContainer>
        </Box>
      </WithLoadingSpinner>
    </Box>
  );
};
