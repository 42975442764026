import React, { ReactElement, ReactNode, useState } from "react";
import styled from "@emotion/styled";
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Box,
  TabsProps as MuiTabsProps,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.div<{ hasBottomBorder: boolean }>`
  position: relative;
  ${({ hasBottomBorder, theme }) =>
    hasBottomBorder &&
    `border-bottom: 1px solid ${theme.palette.border.primary};`}
`;

const Tab = styled(MuiTab)`
  padding: ${({ theme }) => theme.spacing(4, 3)};
  font-size: 16px;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => theme.spacing(3.5, 3)};
    font-size: 14px;
    line-height: 22px;
  }
`;

const Body = styled.div`
  background-color: white;
`;

export interface TabsProps<T> extends Omit<MuiTabsProps, "onChange"> {
  defaultKey?: string | null;
  children: ReactNode[] | ReactNode;
  onChange?: (tabKey: T) => void;
  buttonElement?: ReactElement;
  fakeDisabled?: string[];
  height?: string | number;
  hasBottomBorder?: boolean;
}

export const Tabs = <T extends string>({
  children,
  onChange,
  defaultKey,
  buttonElement,
  fakeDisabled,
  height,
  hasBottomBorder = true,
  ...rest
}: TabsProps<T>) => {
  const chidrenArray = Array.isArray(children) ? children : [children];
  const validChildren = chidrenArray.filter((child) =>
    React.isValidElement(child)
  ) as React.ReactElement<{
    label: string;
    defaultActive?: boolean;
    testId?: string;
    disabled?: boolean;
  }>[];

  const defaultActiveChild = validChildren.find(
    ({ props }) => props.defaultActive
  );

  const [activeTab, setActiveTab] = useState<string>(
    defaultKey || ((defaultActiveChild?.key || validChildren[0].key) as string)
  );

  const activeChild = validChildren.find((el) => el.key === activeTab);

  const handleChange = (event: React.SyntheticEvent, value: T) => {
    if (
      // @ts-ignore
      validChildren.find((el) => el.key === value)?.disabled ||
      fakeDisabled?.includes(value)
    )
      return;

    setActiveTab(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Container>
      <Header hasBottomBorder={hasBottomBorder}>
        <MuiTabs
          value={activeTab}
          onChange={handleChange}
          {...rest}
          sx={{
            ...rest.sx,
            ...(height && {
              "& .MuiTabs-scroller": {
                height: height,
              },
              minHeight: "auto",
            }),
          }}
        >
          {validChildren.map((el, index) => (
            <Tab
              disableRipple
              value={el.key}
              key={`label-${index}`}
              data-testid={el.props.testId}
              label={el.props.label}
              disabled={el.props.disabled}
              className={
                fakeDisabled?.includes(el.key as string) ? "Mui-disabled" : ""
              }
            />
          ))}
        </MuiTabs>
        {buttonElement && (
          <Box
            position="absolute"
            right={0}
            top="50%"
            zIndex="1"
            sx={{
              transform: "translateY(-50%)",
            }}
          >
            {React.cloneElement(buttonElement, { activeTab })}
          </Box>
        )}
      </Header>
      <Body>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            key={activeTab}
          >
            {activeChild}
          </motion.div>
        </AnimatePresence>
      </Body>
    </Container>
  );
};
