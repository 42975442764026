import { UseMutationOptions } from "react-query";
import {
  RemoteDepotsApiImportRemoteDepotRequest,
  DepotSynchronizationLog,
} from "@generated/apiv1";

import { buildRemoteDepotsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const importRemoteDepot = async (
  token: string | undefined,
  params: RemoteDepotsApiImportRemoteDepotRequest
) => {
  const apiInstance = buildRemoteDepotsApi(token);
  const { data } = await apiInstance.importRemoteDepot(params);
  return data;
};

export const useImportRemoteDepot = (
  options?: UseMutationOptions<
    DepotSynchronizationLog,
    AxiosApiError,
    RemoteDepotsApiImportRemoteDepotRequest
  >
) => {
  return useAuthMutation(importRemoteDepot, options, false);
};
