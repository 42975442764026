import { format } from "date-fns";
import { useMemo } from "react";
import { useHistoricQuotes } from "@generated/apiv1/hooks";
import { getInterval } from "@helpers";
import { SecurityChartData } from "@components/Chart/SecuritiesChart/types";
import { convertToChartData } from "@components/Chart/SecuritiesChart/utils";
import { WatchlistEntry } from "@generated/apiv1";
import { useChartColors } from "@hooks";

export const useChartData = (
  watchlistEntries: WatchlistEntry[],
  startDate: Date,
  endDate: Date
) => {
  const colors = useChartColors("watchlistsChart");

  const watchlistEntriesWithColor = useMemo(
    () =>
      watchlistEntries?.map((entry, index) => {
        return {
          ...entry,
          color: colors[index % colors.length],
        };
      }) || [],
    [watchlistEntries, colors]
  );

  const {
    data: historicQuotes,
    isLoading,
    isFetched,
  } = useHistoricQuotes(
    {
      watchlistEntryId: watchlistEntries.map((item) => item.id!),
      from: format(startDate!, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      // @ts-ignore
      interval: getInterval(startDate, endDate),
    },
    {
      enabled: Boolean(watchlistEntries.length),
    }
  );

  const chartData: SecurityChartData[] = useMemo(
    () =>
      watchlistEntriesWithColor.map((watchlistEntry) => {
        const quotes = watchlistEntry.tickerSymbol
          ? historicQuotes?.historicQuotes?.[
              watchlistEntry.tickerSymbolWithCurrency!
            ]
          : undefined;

        return {
          name: watchlistEntry.name!,
          securityName: watchlistEntry.name!,
          data:
            watchlistEntriesWithColor.length > 1 && quotes
              ? convertToChartData(quotes, undefined, "performance").map(
                  (item) => item.mainValue
                )
              : quotes?.map((item) => [
                  new Date(item.date!).getTime(),
                  item.value!,
                ]) || [],
          color: watchlistEntry.color,
        };
      }),
    [watchlistEntriesWithColor, historicQuotes]
  );

  return {
    chartData,
    watchlistEntriesWithColor,
    isLoading,
    isFetched,
  };
};
