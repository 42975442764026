import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { WatchlistEntry } from "@api";
import { toCurrency, toPercent } from "@helpers";
import { DataBlockLayout } from "@components/common/dataBlock";

interface Props {
  isLoading?: boolean;
  watchlistEntry?: WatchlistEntry;
}

const Block = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const Content = styled("div")(({ theme }) => ({
  "& > *": {
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    paddingBottom: theme.spacing(2),
  },

  "& > *:last-child": {
    borderBottom: "none",
  },

  "& > *:first-child": {
    paddingTop: theme.spacing(0),
  },
}));

export const GainLossStatistics = ({ isLoading, watchlistEntry }: Props) => {
  const { t } = useTranslation();

  return (
    <DataBlockLayout
      title={t("watchlistEntry.details.gainLossStatistics.title")}
      isLoading={isLoading}
    >
      <Content>
        <Block>
          <Typography color="textSecondary" variant="body2">
            {t("watchlistEntry.details.gainLossStatistics.gainAndLoss")}
          </Typography>
          <Typography variant="h3" data-testid="gainLossStatistics.gainAndLoss">
            {!watchlistEntry && "N/A"}
            {watchlistEntry &&
              toPercent(watchlistEntry.returnOnInvestmentInPercent)}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {toCurrency(
              watchlistEntry?.returnOnInvestment,
              watchlistEntry?.currency
            )}
          </Typography>
        </Block>

        <Block data-testid="row-entryQuote">
          <Typography color="textSecondary" variant="body2">
            {t("watchlistEntry.details.gainLossStatistics.entryQuote")}
          </Typography>
          <Typography variant="h3" data-testid="gainLossStatistics.entryQuote">
            {watchlistEntry
              ? toCurrency(
                  watchlistEntry?.purchasePrice,
                  watchlistEntry?.currency
                )
              : "N/A"}
          </Typography>
        </Block>

        <Block data-testid="row-entryQuote">
          <Typography color="textSecondary" variant="body2">
            {t("watchlistEntry.details.gainLossStatistics.currentQuote")}
          </Typography>
          <Typography variant="h3" data-testid="gainLossStatistics.entryQuote">
            {watchlistEntry
              ? toCurrency(watchlistEntry.quote?.last, watchlistEntry.currency)
              : "N/A"}
          </Typography>
        </Block>

        {watchlistEntry && watchlistEntry?.oneYearAgoInPercent != null && (
          <Block data-testid="row-historic-returns">
            <Typography color="textSecondary" variant="body2">
              {t("watchlistEntry.details.gainLossStatistics.historicReturns")}
            </Typography>
            <Typography variant="body1">
              {t("ranges.years.oneYear")}:{" "}
              {toPercent(watchlistEntry.oneYearAgoInPercent)}
            </Typography>
            {watchlistEntry.threeYearsAgo != null && (
              <Typography variant="body1">
                {t("ranges.years.threeYears")}:{" "}
                {toPercent(watchlistEntry.threeYearsAgoInPercent)}
              </Typography>
            )}
            {watchlistEntry.fiveYearsAgo != null && (
              <Typography variant="body1">
                {t("ranges.years.fiveYears")}:{" "}
                {toPercent(watchlistEntry.fiveYearsAgoInPercent)}
              </Typography>
            )}
          </Block>
        )}
      </Content>
    </DataBlockLayout>
  );
};
