import { UseQueryOptions } from "react-query";
import { CategoriesApiCategoriesIdRequest, Category } from "@generated/apiv1";

import { buildCategoriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const categoriesId = async (
  token: string | undefined,
  params: CategoriesApiCategoriesIdRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.categoriesId(params);
  return data;
};

export const useCategoriesId = (
  params: CategoriesApiCategoriesIdRequest,
  options?: UseQueryOptions<Category, AxiosApiError, Category>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIES_CATEGORIES_ID, params],
    ({ token }) => categoriesId(token, params),
    options
  );
};
