import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiBatchRiskYieldMetricsRequest,
  BatchRiskYieldMetrics,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const batchRiskYieldMetrics = async (
  token: string | undefined,
  params?: RiskYieldMetricsApiBatchRiskYieldMetricsRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.batchRiskYieldMetrics(params);
  return data;
};

export const useBatchRiskYieldMetrics = (
  params?: RiskYieldMetricsApiBatchRiskYieldMetricsRequest,
  options?: UseQueryOptions<
    BatchRiskYieldMetrics,
    AxiosApiError,
    BatchRiskYieldMetrics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_BATCH_RISK_YIELD_METRICS, params],
    ({ token }) => batchRiskYieldMetrics(token, params),
    options
  );
};
