import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  Switch,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { getHours } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { keyBy } from "lodash";
import { getWelcomePhrase } from "@features/dashboard/getWelcomePhrase";
import { ComparisonValue, PageLayout, SelectComparison } from "@components";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { useMyself } from "@api";
import { MainDataSection } from "@features/dashboard/components/MainDataSection";
import { DashboardInvestmentsTable } from "@features/dashboard/components/DashboardInvestmentsTable";
import { useInvestmentsChartDataTotal } from "@api/v1/hooks/performance/useInvestmentsChartDataTotal";
import { DashboardPerformanceChart } from "@components/Chart/DashboardPerformanceChart";
import { DashboardValuationChart } from "@components/Chart/DashboardValuationChart";
import { DashboardChartType } from "@features/dashboard/types";
import { DashboardDividendsChart } from "@components/Chart/DashboardDividendsChart/DashboardDividendsChart";
import { useInvestments } from "@features/dashboard/useInvestments";
import { AccountAddSelection } from "@features/accounts/add/AccountAddChoicePage";
import { PortfolioShareModal } from "@components/Modal/PortfolioShareModal/PortfolioShareModal";
import { useDividendsChartData } from "@components/Chart/DashboardDividendsChart/useDividendsChartData";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { DepotsSelector } from "@features/selectDepots/components/DepotsSelector";
import { SharedBanner } from "@features/sharedPortfolio/SharedBanner";
import { ReactComponent as ShareIcon } from "@icons/share.svg";
import { CashTable } from "@features/dashboard/components/CashTable";
import { useRangeSelectControl } from "@hooks";
import { STOCK_INDEXES, StockIndex } from "@constants";
import { useAccountsContext } from "@providers/AccountsProvider/AccountsProvider";
import { RangeSelector } from "../../components/common/ranges/RangeSelector";

const BlurContainer = styled(Box)(({ theme }) => ({
  backdropFilter: "blur(6px)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const indexesByKey = keyBy(STOCK_INDEXES, "label");

const mapToChange: Record<string, StockIndex> = {
  DAX: indexesByKey["DAX Kurs"],
  "DAX Kurs": indexesByKey["DAX"],
  MDAX: indexesByKey["MDAX Kurs"],
  "MDAX Kurs": indexesByKey["MDAX"],
};

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { isSharedPortfolio, isAnonymized, settings } =
    useSharedPortfolioContext();

  const {
    accounts,
    areAccountsFetched,
    areAccountsLoading,
    selectedAccounts,
    setSelectedAccounts,
  } = useAccountsContext();

  const theme = useTheme();
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { data: myselfData } = useMyself();
  const isLess1200 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1200)
  );
  const navigate = useNavigate();
  const name = myselfData?.profileReference?.firstName;
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isSelectSmall = !isSm && isLg;

  const welcomePhrase = getWelcomePhrase(getHours(new Date()));
  const welcomeText = name ? `${welcomePhrase}, ${name}` : welcomePhrase;
  const savedWithDividends = localStorage.getItem("dashboard-withDividends");

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [withDividends, setWithDividends] = useState(
    savedWithDividends === "true"
  );
  const [showAggregated, setShowAggregated] = useState(true);
  const [comparedTicker, setComparedTicker] = useState<
    ComparisonValue | undefined
  >();
  const [displayedChart, setDisplayedChart] = useState<DashboardChartType>(
    isAnonymized ? "performance" : "valuation"
  );

  const { range, intervalType, selectInterval, customIntervalChange } =
    useRangeSelectControl({
      firstDate: new Date(),
      id: "dashboard",
    });

  const accountIds = useMemo(
    () =>
      (isSharedPortfolio
        ? selectedAccounts
        : accounts?.map((account) => account.id)) ?? [],
    [accounts, selectedAccounts, isSharedPortfolio]
  );

  const {
    investments,
    investmentsPerformance,
    investmentLogos,
    cash,
    isFetched,
    isLoading,
  } = useInvestments({
    accountIds,
    startDate: range.startDate,
    endDate: range.endDate,
    showAggregated,
  });

  const {
    statistic,
    data: chartData,
    isLoading: isLoadingChartData,
    isFetched: isChartDataFetched,
  } = useInvestmentsChartDataTotal(
    accountIds,
    range.startDate,
    range.endDate,
    displayedChart,
    displayedChart === "performance" ? comparedTicker?.value : undefined
  );

  const {
    chartData: dividendsChartData,
    isLoading: areDividendsLoading,
    isFetched: areDividendsFetched,
  } = useDividendsChartData({
    accountIds,
    startDate: range?.startDate!,
    interval: intervalType,
    enabled: displayedChart === "dividends",
  });

  const availableIndexesWithDividends = STOCK_INDEXES.filter(
    (index) => index.label !== "DAX Kurs" && index.label !== "MDAX Kurs"
  );
  const availableIndexesWithoutDividends = STOCK_INDEXES.filter(
    (index) => index.label !== "DAX" && index.label !== "MDAX"
  );
  const availableIndexes = withDividends
    ? availableIndexesWithDividends
    : availableIndexesWithoutDividends;

  const shouldShowAddAccountModal =
    !isSharedPortfolio &&
    ((!accounts?.length && areAccountsFetched) ||
      (!investments?.length && isFetched));

  const investmentsByTypeCount =
    investments?.reduce((acc, investment) => {
      const type = investment.type;
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type] += 1;
      return acc;
    }, {} as Record<string, number>) || {};

  const statisticsWithCash = {
    ...statistic,
    ...(isAnonymized ? { cashBalance: cash } : {}),
  };

  useEffect(() => {
    if (shouldShowAddAccountModal) {
      const listener = () => {
        const body = document.querySelector("body");
        if (body) body.scrollTop = 0;
      };

      window.addEventListener("resize", listener);

      return () => {
        window.removeEventListener("resize", listener);
      };
    }
  }, [shouldShowAddAccountModal]);

  const onDividendsToggleChange = () => {
    setWithDividends(!withDividends);

    localStorage.setItem(
      "dashboard-withDividends",
      JSON.stringify(!withDividends)
    );

    const isDax = comparedTicker?.label === "DAX";
    const isDaxKurs = comparedTicker?.label === "DAX Kurs";
    const isMdax = comparedTicker?.label === "MDAX";
    const isMdaxKurs = comparedTicker?.label === "MDAX Kurs";

    const shouldChangeSelection = isDax || isMdax || isDaxKurs || isMdaxKurs;

    if (shouldChangeSelection) {
      setComparedTicker(mapToChange[comparedTicker?.label] ?? undefined);
    }
  };

  return (
    <PageLayout
      isMainPage
      position="relative"
      title={
        <Box>
          {!isSharedPortfolio && (
            <Typography variant="body2" color="textSecondary" mb={isXs ? 0 : 1}>
              {welcomeText}
            </Typography>
          )}
          <Typography variant="h4" component="h1">
            {isSharedPortfolio
              ? settings.name
              : t("dashboardPage.header.title")}
          </Typography>
          {isSharedPortfolio && (
            <DepotsSelector
              selectedGroupParams={{
                id: "dashboard",
              }}
              variant="body2"
              fontWeight={600}
              color="text.secondary"
              textKeys={{
                modalTitle: "dashboardPage.depotSelectorTitle",
                allDepots: "depotsSelector.allDepots",
                amountDepots: "depotsSelector.amountDepots",
                noDepots: "depotsSelector.noDepots",
              }}
              onSave={setSelectedAccounts}
            />
          )}
        </Box>
      }
      actionButton={
        <Box display="flex" gap={5} mt={4}>
          {!isSharedPortfolio && (
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsShareModalOpen(true)}
              startIcon={<ShareIcon />}
            >
              {t("portfolioShare.share")}
            </Button>
          )}
          {!isSharedPortfolio &&
            (isLessMd ? (
              <IconButton
                color="secondary"
                onClick={() => navigate("/depots/add")}
              >
                <PlusIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlusIcon />}
                onClick={() => navigate("/depots/add")}
              >
                {t("dashboardPage.dashboard.addPortfolio")}
              </Button>
            ))}
          {!isLess1200 && (
            <RangeSelector
              range={intervalType === "custom" ? range : undefined}
              onSelect={selectInterval}
              onCustomSelect={customIntervalChange}
              intervalType={intervalType}
              disabledIntervalTypes={
                displayedChart === "dividends"
                  ? ["oneWeek", "oneMonth", "custom"]
                  : []
              }
            />
          )}
        </Box>
      }
    >
      <SharedBanner mb={isLess1200 ? 3 : 0} />
      {isLess1200 && (
        <RangeSelector
          range={intervalType === "custom" ? range : undefined}
          onSelect={selectInterval}
          onCustomSelect={customIntervalChange}
          intervalType={intervalType}
          disabledIntervalTypes={
            displayedChart === "dividends"
              ? ["oneWeek", "oneMonth", "custom"]
              : []
          }
        />
      )}
      {!isSharedPortfolio && (
        <Box position="absolute" top={0} left={0} width="100%">
          {!accounts?.length && areAccountsFetched && (
            <>
              <BlurContainer />
              <Box
                height="calc(100vh - 48px)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                zIndex={1001}
              >
                <Box
                  width={isLessMd ? 400 : 700}
                  sx={{ backgroundColor: theme.palette.background.paper }}
                  py={4}
                  px={5}
                  borderRadius={2}
                  border={`1px solid ${theme.palette.border.secondary}`}
                  mx="auto"
                >
                  <Typography variant="h6">
                    {t("dashboard.addTitle")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={4}>
                    {t("dashboard.addSubtitle")}
                  </Typography>
                  <AccountAddSelection isDepots={false} />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
      <Grid container mb={19} mt={1} rowSpacing={8}>
        <Grid item xs={12} sm={12} md={5}>
          <MainDataSection
            investments={investments}
            statistics={statisticsWithCash}
            isLoading={isLoading || areAccountsLoading || isLoadingChartData}
            isAggregated={showAggregated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Box p={isSm ? 0 : 6}>
            <Box
              sx={{
                "& > div > div": {
                  borderBottom: "none",
                  marginBottom: (theme) =>
                    theme.spacing(theme.breakpoints.down("md") ? 4 : 8),
                },
              }}
            >
              <ToggleButtonGroup
                value={displayedChart}
                color="secondary"
                sx={{ marginBottom: theme.spacing(4) }}
              >
                {!isAnonymized && (
                  <ToggleButton
                    value="valuation"
                    sx={{ width: "30px" }}
                    onClick={() => setDisplayedChart("valuation")}
                    selected={displayedChart === "valuation"}
                  >
                    {t("dashboard.chartSwitcher.value")}
                  </ToggleButton>
                )}
                <ToggleButton
                  value="performance"
                  sx={{ width: "30px" }}
                  onClick={() => setDisplayedChart("performance")}
                  selected={displayedChart === "performance"}
                >
                  {t("dashboard.chartSwitcher.performance")}
                </ToggleButton>
                {!isAnonymized && (
                  <ToggleButton
                    value="dividends"
                    onClick={() => {
                      setDisplayedChart("dividends");
                      if (
                        intervalType === "oneWeek" ||
                        intervalType === "oneMonth" ||
                        intervalType === "custom"
                      )
                        selectInterval("oneYear");
                    }}
                    selected={displayedChart === "dividends"}
                  >
                    {t("dashboard.chartSwitcher.dividends")}
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
              <Box>
                {displayedChart === "valuation" && !isAnonymized && (
                  <DashboardValuationChart
                    chartData={chartData}
                    intervalType={intervalType}
                    isLoading={isLoadingChartData || areAccountsLoading}
                    isFetched={isChartDataFetched && isFetched}
                  />
                )}
                {displayedChart === "performance" && (
                  <DashboardPerformanceChart
                    chartData={chartData}
                    comparedTicker={comparedTicker}
                    intervalType={intervalType}
                    setComparedTicker={setComparedTicker}
                    isLoading={isLoadingChartData || areAccountsLoading}
                    isFetched={isChartDataFetched && isFetched}
                    withDividends={withDividends}
                    onDividendsChange={onDividendsToggleChange}
                    indexes={availableIndexes}
                  />
                )}
                {displayedChart === "dividends" && !isAnonymized && (
                  <DashboardDividendsChart
                    chartData={dividendsChartData}
                    isLoading={areDividendsLoading}
                    isFetched={areDividendsFetched}
                    interval={intervalType}
                  />
                )}
              </Box>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  {displayedChart === "performance" && !isAnonymized && (
                    <FormControlLabel
                      control={
                        <Switch
                          size="medium"
                          checked={withDividends}
                          onChange={onDividendsToggleChange}
                        />
                      }
                      label={t("dashboardPage.dashboard.includeDividends")}
                      sx={{
                        marginLeft: 0,
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 500,
                        },
                      }}
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        checked={showAggregated}
                        onChange={(e) => setShowAggregated(e.target.checked)}
                      />
                    }
                    label={t("dashboardPage.dashboard.showAggregated")}
                    sx={{
                      marginLeft: 0,
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 500,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  maxWidth={isSelectSmall ? "fit-content !important" : "auto"}
                >
                  <SelectComparison
                    value={comparedTicker}
                    onChange={(value?: ComparisonValue) => {
                      setComparedTicker(value);
                      if (
                        value &&
                        (displayedChart === "dividends" ||
                          displayedChart === "valuation")
                      ) {
                        setDisplayedChart("performance");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mb={6}>
        <Typography variant="h4" mb={1.5}>
          {t("dashboardPage.dashboard.investmentsTable.header")}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          mb={4}
          fontWeight={isXs ? 600 : 400}
        >
          {Object.entries(investmentsByTypeCount)
            .map(
              ([type, amount]) =>
                `${amount} ${t(
                  `dashboardPage.dashboard.investmentsTable.types.${type}`,
                  {
                    count: amount,
                  }
                )}`
            )
            .join(", ")}
        </Typography>
        <DashboardInvestmentsTable
          isLoading={isLoading}
          isFetched={isFetched}
          investments={investments}
          cashValue={statisticsWithCash.cashBalance ?? 0}
          performance={investmentsPerformance?.singleInvestmentTwrorRecords}
          logos={investmentLogos}
        />
      </Box>
      <Box mb={6}>
        <Typography variant="h4" mb={1.5}>
          {t("dashboardPage.dashboard.cashTable.header")}
        </Typography>
        <CashTable
          isLoading={isLoading}
          isFetched={isFetched}
          statistics={statisticsWithCash}
          investments={investments}
        />
      </Box>
      {!isSharedPortfolio && isShareModalOpen && (
        <PortfolioShareModal
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </PageLayout>
  );
};
