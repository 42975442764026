import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiAddBankConnectionV2Request,
  BankSynchronizationProcess,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const addBankConnectionV2 = async (
  token: string | undefined,
  params: BankConnectionsApiAddBankConnectionV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.addBankConnectionV2(params);
  return data;
};

export const useAddBankConnectionV2 = (
  options?: UseMutationOptions<
    BankSynchronizationProcess,
    AxiosApiError,
    BankConnectionsApiAddBankConnectionV2Request
  >
) => {
  return useAuthMutation(addBankConnectionV2, options, false);
};
