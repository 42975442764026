import {
  MutationOptions,
  UseMutationOptions,
  UseQueryOptions,
} from "react-query";
import {
  deleteBooking,
  downloadBookings,
  getBookings,
  GetBookingParams,
} from "@api/v1/methods";
import { PageableBookingList } from "@api/v1/model";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import { BOOKINGS } from "../../cacheKeys";

export const useGetBookings = (
  params: GetBookingParams,
  options: UseQueryOptions<
    PageableBookingList,
    AxiosApiError,
    PageableBookingList
  > = {}
) =>
  useAuthQuery(
    [BOOKINGS, params],
    ({ token }) => getBookings(token, params),
    options
  );

export const useDownloadBookings = (
  params: GetBookingParams,
  options?: UseMutationOptions<Blob, AxiosApiError, unknown>
) => useAuthMutation((token) => downloadBookings(token, params), options);

export const useDeleteBooking = (
  options: MutationOptions<unknown, AxiosApiError, unknown> = {}
) => useAuthMutation(deleteBooking, options);
