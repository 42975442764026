import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { LoadingButton, Modal, ModalProps } from "@components";
import { AccountsTable } from "@components/AccountsTable";
import { Account } from "@generated/apiv1";

type Props = {
  accounts?: Account[];
  onConfirm: (ids: number[]) => void;
  onSelect: (ids: number[]) => void;
  isLoading?: boolean;
  checkedPortfolios: number[];
} & Omit<ModalProps, "children">;

export const DepotsSelectorModal = ({
  accounts,
  checkedPortfolios = [],
  onSelect,
  onConfirm,
  isLoading,
  onClose,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm(checkedPortfolios);
  };

  return (
    <Modal onClose={onClose} height="auto" {...rest}>
      <Box display="flex" flexDirection="column" height="calc(100% - 70px)">
        <AccountsTable
          accounts={accounts}
          checkedPortfolios={checkedPortfolios}
          onSelect={onSelect}
        />
        <Box display="flex" justifyContent="space-between" gap={3}>
          <Button variant="contained" onClick={onClose} color="secondary">
            {t("navigation.previous")}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            isLoading={isLoading}
            disabled={!checkedPortfolios.length}
          >
            {t("depotsSelector.modalButton")}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
