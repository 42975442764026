import { UseMutationOptions } from "react-query";
import {
  PortfolioOperationsApiMergePortfoliosRequest,
  Account,
} from "@generated/apiv1";

import { buildPortfolioOperationsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const mergePortfolios = async (
  token: string | undefined,
  params: PortfolioOperationsApiMergePortfoliosRequest
) => {
  const apiInstance = buildPortfolioOperationsApi(token);
  const { data } = await apiInstance.mergePortfolios(params);
  return data;
};

export const useMergePortfolios = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    PortfolioOperationsApiMergePortfoliosRequest
  >
) => {
  return useAuthMutation(mergePortfolios, options, false);
};
