/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { MasterDataList } from '../model';
// @ts-ignore
import { StringList } from '../model';
/**
 * MasterDataApi - axios parameter creator
 * @export
 */
export const MasterDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auto curate fund classifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCurateFundClassification: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/masterData/fundClassification/autoCurate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {string} id The type of master data, see catalogue for supported types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterData: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMasterData', 'id', id)
            const localVarPath = `/v1/masterData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a catalogue of supported master data types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterDataCatalogue: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/masterData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {string} id The type of master data, see catalogue for supported types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetMasterData: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2GetMasterData', 'id', id)
            const localVarPath = `/v2/masterData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterDataApi - functional programming interface
 * @export
 */
export const MasterDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auto curate fund classifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoCurateFundClassification(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoCurateFundClassification(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MasterDataApi.autoCurateFundClassification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {string} id The type of master data, see catalogue for supported types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterData(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MasterDataApi.getMasterData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a catalogue of supported master data types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterDataCatalogue(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterDataCatalogue(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MasterDataApi.masterDataCatalogue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {string} id The type of master data, see catalogue for supported types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetMasterData(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MasterDataList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetMasterData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MasterDataApi.v2GetMasterData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MasterDataApi - factory interface
 * @export
 */
export const MasterDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Auto curate fund classifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCurateFundClassification(options?: RawAxiosRequestConfig): AxiosPromise<StringList> {
            return localVarFp.autoCurateFundClassification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {MasterDataApiGetMasterDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterData(requestParameters: MasterDataApiGetMasterDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<StringList> {
            return localVarFp.getMasterData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a catalogue of supported master data types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterDataCatalogue(options?: RawAxiosRequestConfig): AxiosPromise<StringList> {
            return localVarFp.masterDataCatalogue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all master data records of the given type.
         * @param {MasterDataApiV2GetMasterDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetMasterData(requestParameters: MasterDataApiV2GetMasterDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<MasterDataList> {
            return localVarFp.v2GetMasterData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMasterData operation in MasterDataApi.
 * @export
 * @interface MasterDataApiGetMasterDataRequest
 */
export interface MasterDataApiGetMasterDataRequest {
    /**
     * The type of master data, see catalogue for supported types.
     * @type {string}
     * @memberof MasterDataApiGetMasterData
     */
    readonly id: string
}

/**
 * Request parameters for v2GetMasterData operation in MasterDataApi.
 * @export
 * @interface MasterDataApiV2GetMasterDataRequest
 */
export interface MasterDataApiV2GetMasterDataRequest {
    /**
     * The type of master data, see catalogue for supported types.
     * @type {string}
     * @memberof MasterDataApiV2GetMasterData
     */
    readonly id: string
}

/**
 * MasterDataApi - object-oriented interface
 * @export
 * @class MasterDataApi
 * @extends {BaseAPI}
 */
export class MasterDataApi extends BaseAPI {
    /**
     * 
     * @summary Auto curate fund classifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public autoCurateFundClassification(options?: RawAxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).autoCurateFundClassification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all master data records of the given type.
     * @param {MasterDataApiGetMasterDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public getMasterData(requestParameters: MasterDataApiGetMasterDataRequest, options?: RawAxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).getMasterData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a catalogue of supported master data types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public masterDataCatalogue(options?: RawAxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).masterDataCatalogue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all master data records of the given type.
     * @param {MasterDataApiV2GetMasterDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public v2GetMasterData(requestParameters: MasterDataApiV2GetMasterDataRequest, options?: RawAxiosRequestConfig) {
        return MasterDataApiFp(this.configuration).v2GetMasterData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

