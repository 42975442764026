import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { alpha, darken, useTheme } from "@mui/material";
import { renderToString } from "react-dom/server";
import { DividendsTooltip } from "@components/Chart/Tooltips/DividendsTooltip";
import { toAmericanShort } from "@helpers";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import commonHighchartsOptions from "@components/Chart/commonHighchartsOptions";
import { NoAccountsStub } from "@components/NoAccountsStub";
import { DividendChartData } from "@components/Chart/DashboardDividendsChart/useDividendsChartData";

type Props = {
  chartData: DividendChartData[];
  isLoading: boolean;
  isFetched: boolean;
  interval: IntervalType;
};

export const DashboardDividendsChart = ({
  chartData,
  isLoading,
  isFetched,
  interval,
}: Props) => {
  const theme = useTheme();

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      ...commonHighchartsOptions.chart,
    },
    title: {
      text: "",
    },
    colors: [alpha(theme.palette.primary.main, 0.3)],
    tooltip: {
      ...commonHighchartsOptions.tooltip,
    },
    xAxis: {
      type: "category",
      lineColor: darken(theme.palette.common.white, 0.05),
      labels: {
        style: {
          color: theme.palette.text.secondary,
          fontFamily: "Averta,Arial",
          fontWeight: "400",
          fontSize: "10px",
        },
      },
      title: {
        text: "",
      },
    },
    yAxis: {
      margin: 0,
      tickAmount: 6,
      title: {
        text: "",
      },
      gridLineColor: darken(theme.palette.common.white, 0.05),
      labels: {
        useHTML: true,
        formatter: (data) => {
          return renderToString(
            <div
              style={{
                fontFamily: "Averta,Arial",
                fontSize: "11px",
                fontWeight: "400",
                color: theme.palette.text.secondary,
              }}
            >
              {toAmericanShort(data.value as number, 0, 2)}
            </div>
          );
        },
      },
    },
    legend: {
      enabled: false,
      width: "80%",
      verticalAlign: "top",
      align: "left",
      itemStyle: {
        fontSize: "10px",
        fontFamily: "var(--ff-font-family-base)",
        fontWeight: "600",
      },
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
          style: {
            fontFamily: "var(--ff-font-family-base)",
            fontWeight: "400",
            textOutline: "0",
            fontSize: "16px",
          },
        },
        pointStart: 1,
        pointWidth: 10,
        minPointLength: 3,
      },
    },
    navigation: {
      buttonOptions: {
        y: 12,
      },
    },
  };

  return (
    <WithLoadingSpinner isLoading={isLoading}>
      {isFetched && !chartData.length ? (
        <NoAccountsStub height="300px" />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            tooltip: {
              ...options.tooltip,
              formatter: function () {
                return renderToString(
                  <DividendsTooltip
                    // @ts-ignore
                    point={this.point}
                    mode={
                      interval === "oneYear" || interval === "YTD"
                        ? "long"
                        : "short"
                    }
                  />
                );
              },
            },
            series: [
              {
                name: "Dividende",
                data: chartData,
                states: {
                  hover: {
                    color: theme.palette.primary.main,
                  },
                },
              },
            ],
          }}
        />
      )}
    </WithLoadingSpinner>
  );
};
