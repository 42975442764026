import { flatMap } from "lodash";
import { UseQueryOptions } from "react-query";
import { getBookings, validateBookings } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import {
  extractIsinsAndWkns,
  extractTickerSymbols,
} from "@features/fileDrop/helpers/extractIsinsOrWkns";
import { buildCreateOrUpdateInvestmentParamsList } from "@features/fileDrop/hooks/buildCreateOrUpdateInvestmentParams";
import { UniqueParsingResult } from "@features/fileDrop/components/ParsingResult";
import { GET_AND_VALIDATE_FINANZFLUSS_BOOKINGS_KEY } from "@api/cacheKeys";
import {
  locateBookings,
  ActivityWithBooking,
} from "@features/fileDrop/helpers/bookingLocator";
import {
  CreateOrUpdateInvestmentParams,
  TransactionValidationErrorList,
} from "@api";

type UseGetAndValidateBookingsResult = {
  activitiesWithBookings: ActivityWithBooking[];
  createOrUpdateInvestmentParamsList: CreateOrUpdateInvestmentParams[];
  transactionValidationErrorList: TransactionValidationErrorList;
};

const getAndValidateBookings = async (
  token: string,
  accountId: number,
  parsingResults: UniqueParsingResult[]
) => {
  const bookings = await getBookings(token, {
    accountId: [accountId],
    isinOrWkn: extractIsinsAndWkns(parsingResults),
    tickerSymbol: extractTickerSymbols(parsingResults),
  });

  const parsingActivities = flatMap(
    parsingResults,
    (parsingResult) => parsingResult.activities || []
  ).filter((pa) => (pa.cryptoSymbol ? true : pa.isin || pa.wkn));

  const activitiesWithBookings = locateBookings(
    bookings?.bookings || [],
    parsingActivities
  );

  const createOrUpdateInvestmentParamsList =
    buildCreateOrUpdateInvestmentParamsList(accountId, activitiesWithBookings);

  const transactionValidationErrorList = await validateBookings(token, {
    createOrUpdateInvestmentParamsList,
  });

  return {
    activitiesWithBookings,
    createOrUpdateInvestmentParamsList,
    transactionValidationErrorList,
  };
};

export const useGetAndValidateBookings = (
  accountId: number,
  parsingResults: UniqueParsingResult[],
  options: UseQueryOptions<
    UseGetAndValidateBookingsResult,
    AxiosApiError,
    UseGetAndValidateBookingsResult
  > = {}
) =>
  useAuthQuery(
    [GET_AND_VALIDATE_FINANZFLUSS_BOOKINGS_KEY, parsingResults, accountId],
    ({ token }) => getAndValidateBookings(token, accountId, parsingResults),
    options
  );
