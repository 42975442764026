import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiEditBankConnectionFromTppWebFormV2Request,
  BankConnectionWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const editBankConnectionFromTppWebFormV2 = async (
  token: string | undefined,
  params: BankConnectionsApiEditBankConnectionFromTppWebFormV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.editBankConnectionFromTppWebFormV2(params);
  return data;
};

export const useEditBankConnectionFromTppWebFormV2 = (
  options?: UseMutationOptions<
    BankConnectionWrapper,
    AxiosApiError,
    BankConnectionsApiEditBankConnectionFromTppWebFormV2Request
  >
) => {
  return useAuthMutation(editBankConnectionFromTppWebFormV2, options, false);
};
