/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Courtage } from './courtage';
// May contain unused imports in some cases
// @ts-ignore
import { Region } from './region';

/**
 * 
 * @export
 * @interface Fund
 */
export interface Fund {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Fund
     */
    'id'?: number;
    /**
     * Type of the fund
     * @type {string}
     * @memberof Fund
     */
    'type'?: string;
    /**
     * The funds ISIN
     * @type {string}
     * @memberof Fund
     */
    'isin'?: string;
    /**
     * The funds WKN
     * @type {string}
     * @memberof Fund
     */
    'wkn'?: string;
    /**
     * The funds literal name
     * @type {string}
     * @memberof Fund
     */
    'name'?: string;
    /**
     * The funds emitter (KAG)
     * @type {string}
     * @memberof Fund
     */
    'emitter'?: string;
    /**
     * The literal fund category
     * @type {string}
     * @memberof Fund
     */
    'category'?: string;
    /**
     * The literal fund sub-category
     * @type {string}
     * @memberof Fund
     */
    'subCategory'?: string;
    /**
     * The fund currency
     * @type {string}
     * @memberof Fund
     */
    'fundCurrency'?: string;
    /**
     * The funds risk classification
     * @type {number}
     * @memberof Fund
     */
    'riskClass'?: number;
    /**
     * The funds popularity; this is the fund count divided by the number of users. This field                                is only available when using the \'mostPopular\' enpdoint. Popularity rage is from                                3 (highest) to 1 (lowest).
     * @type {number}
     * @memberof Fund
     */
    'popularity'?: FundPopularityEnum;
    /**
     * A list of bank-specific courtages (commissions)
     * @type {Array<Courtage>}
     * @memberof Fund
     */
    'courtages'?: Array<Courtage>;
    /**
     * FWW risk level
     * @type {number}
     * @memberof Fund
     */
    'riskLevelFww'?: number;
    /**
     * SRRI risk level
     * @type {number}
     * @memberof Fund
     */
    'riskLevelSrri'?: number;
    /**
     * last updated fundamental data
     * @type {string}
     * @memberof Fund
     */
    'lastUpdatedAt'?: string;
    /**
     * fund volume
     * @type {number}
     * @memberof Fund
     */
    'volume'?: number;
    /**
     * last updated volume data
     * @type {string}
     * @memberof Fund
     */
    'volumeDate'?: string;
    /**
     * Launch Date of a fund
     * @type {string}
     * @memberof Fund
     */
    'launchDate'?: string;
    /**
     * 
     * @type {Region}
     * @memberof Fund
     */
    'focusRegion'?: Region;
    /**
     * FwwFundstars Rating
     * @type {number}
     * @memberof Fund
     */
    'ratingFwwFundstars'?: number;
}

export const FundPopularityEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FundPopularityEnum = typeof FundPopularityEnum[keyof typeof FundPopularityEnum];


