import { UseMutationOptions } from "react-query";
import {
  PerformanceApiPerformanceIntervalsRequest,
  PerformanceIntervalList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const performanceIntervals = async (
  token: string | undefined,
  params: PerformanceApiPerformanceIntervalsRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.performanceIntervals(params);
  return data;
};

export const usePerformanceIntervals = (
  options?: UseMutationOptions<
    PerformanceIntervalList,
    AxiosApiError,
    PerformanceApiPerformanceIntervalsRequest
  >
) => {
  return useAuthMutation(performanceIntervals, options, false);
};
