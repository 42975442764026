import { Popover as MuiPopover, PopoverOrigin } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { DateRangePickerDialogContainer } from "./DateRangePickerDialogContainer";

const Popover = styled(MuiPopover)`
  & > .MuiPopover-paper {
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
`;

export interface DateRangePickerDialogProps {
  onSubmit: (from: Date, to: Date) => void;
  onCancel: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  defaultFrom?: Date;
  defaultTo?: Date;
  defaultMonthFrom?: Date;
  children: React.FC<{
    onTrigger: (event: EventTarget & HTMLElement) => void;
  }>;
}

export const DateRangePickerDialog = ({
  children,
  anchorOrigin,
  transformOrigin,
  onCancel,
  onSubmit,
  defaultFrom,
  defaultTo,
  defaultMonthFrom,
}: DateRangePickerDialogProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (currentTarget: EventTarget & HTMLElement) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
    onCancel();
  };

  const handleSubmit = (from: Date, to: Date) => {
    handleClose();
    onSubmit(from, to);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {children({ onTrigger: handleClick })}
      <Popover
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
      >
        <DateRangePickerDialogContainer
          defaultFrom={defaultFrom}
          defaultTo={defaultTo}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          defaultMonthFrom={defaultMonthFrom}
        />
      </Popover>
    </>
  );
};
