import React, { ReactNode } from "react";
import styled from "@emotion/styled";

interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  className?: string;
}

const Wrapper = styled.div`
  align-items: center;
`;

export const Figure = ({ title, subtitle, className, children }: Props) => {
  return (
    <Wrapper className={className}>
      {children}
      {title}
      {subtitle}
    </Wrapper>
  );
};
