/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SplitMeta
 */
export interface SplitMeta {
    /**
     * Status of the split if it is active or deleted
     * @type {string}
     * @memberof SplitMeta
     */
    'status': SplitMetaStatusEnum;
    /**
     * Record creation timestamp
     * @type {string}
     * @memberof SplitMeta
     */
    'created_at': string;
    /**
     * Record update timestamp
     * @type {string}
     * @memberof SplitMeta
     */
    'updated_at': string;
}

export const SplitMetaStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted'
} as const;

export type SplitMetaStatusEnum = typeof SplitMetaStatusEnum[keyof typeof SplitMetaStatusEnum];


