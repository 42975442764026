import { UseMutationOptions } from "react-query";
import { StripeApiStripeWebhooksRequest } from "@generated/apiv1";

import { buildStripeApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const stripeWebhooks = async (
  token: string | undefined,
  params: StripeApiStripeWebhooksRequest
) => {
  const apiInstance = buildStripeApi(token);
  const { data } = await apiInstance.stripeWebhooks(params);
  return data;
};

export const useStripeWebhooks = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    StripeApiStripeWebhooksRequest
  >
) => {
  return useAuthMutation(stripeWebhooks, options, false);
};
