import { UseQueryOptions } from "react-query";
import { TagsApiGetTagRequest, Tag } from "@generated/apiv1";

import { buildTagsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getTag = async (
  token: string | undefined,
  params: TagsApiGetTagRequest
) => {
  const apiInstance = buildTagsApi(token);
  const { data } = await apiInstance.getTag(params);
  return data;
};

export const useGetTag = (
  params: TagsApiGetTagRequest,
  options?: UseQueryOptions<Tag, AxiosApiError, Tag>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TAGS_GET_TAG, params],
    ({ token }) => getTag(token, params),
    options
  );
};
