import React from "react";
import styled from "@emotion/styled";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { Investment } from "@generated/apiv1";
import { ReactComponent as ReferenceAccountIcon } from "@icons/reference-account.svg";
import { accountTypes } from "@features/accounts/constants";
import { useAccountsContext } from "@providers/AccountsProvider";
import { Account } from "@api";

const ICON_SIZE = {
  width: "24px",
  height: "24px",
};

const BankAvatar = styled.img`
  max-height: 40px;
  max-width: 40px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
`;

const Image = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: 24,
  height: 24,
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.common.white,
  display: "flex",
  color: theme.palette.common.black,
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,

  "& svg": {
    fill: theme.palette.common.black,
    width: 10,
    height: 10,
  },
}));

type Props = {
  item: Investment;
};

export const AccountInfo = ({ item }: Props) => {
  const theme = useTheme();
  const { accounts } = useAccountsContext();

  const account =
    accounts?.find((x) => x.id === item.account?.id) ?? ({} as Account);
  const image = account?.iconUrl;
  const isReference = Boolean(account?.depotIds?.length);

  const linkToGo = item.id
    ? `${process.env.REACT_APP_V2_URL}/investments/${item.id}`
    : undefined;

  const noIcon =
    image?.includes("DEPOT_square") ||
    image?.includes("CHECKING_square") ||
    image?.includes("MATERIAL_ASSETS_square");
  const type = accountTypes.find(
    (type) => type.accountType === item.account?.type
  );

  return (
    <Box display="flex" alignItems="center" gap={2} position="relative" ml={12}>
      {isReference ? (
        <Box display="flex" color={theme.palette.icon.secondary} mr={4}>
          <ReferenceAccountIcon {...ICON_SIZE} />
        </Box>
      ) : !noIcon && image ? (
        <BankAvatar src={image} {...ICON_SIZE} />
      ) : (
        <Image>{type?.icon}</Image>
      )}
      <Box display="flex" alignItems="center" gap={2}>
        {linkToGo ? (
          <Link
            href={linkToGo}
            sx={{
              lineHeight: "24px",
              fontWeight: 400,
            }}
          >
            {item.account?.name}
          </Link>
        ) : (
          <Typography variant="body2" lineHeight="24px" fontWeight={400}>
            {item.account?.name}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
