import { UseQueryOptions } from "react-query";
import { getSymbol } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { Symbol } from "@generated/apiv1";
import { GET_SYMBOL } from "../../cacheKeys";

interface GetSymbolParams {
  tickerSymbol: string;
  quoteProvider: string;
}

export const useGetSymbol = (
  params: GetSymbolParams,
  options?: UseQueryOptions<Symbol, AxiosApiError>
) =>
  useAuthQuery(
    [GET_SYMBOL, params],
    ({ token }) => getSymbol(token, params.quoteProvider, params.tickerSymbol),
    options
  );
