import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiListAndSearchAllPublicInvestmentsRequest,
  PageableInvestmentList,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllPublicInvestments = async (
  token: string | undefined,
  params: InvestmentsApiListAndSearchAllPublicInvestmentsRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.listAndSearchAllPublicInvestments(params);
  return data;
};

export const useListAndSearchAllPublicInvestments = (
  params: InvestmentsApiListAndSearchAllPublicInvestmentsRequest,
  options?: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_LIST_AND_SEARCH_ALL_PUBLIC_INVESTMENTS, params],
    ({ token }) => listAndSearchAllPublicInvestments(token, params),
    options
  );
};
