import { isEqual, parseJSON } from "date-fns";
import { SecuritiesChartDataWithDate } from "@components/Chart/SecuritiesChart/types";
import { HistoricQuote } from "@api";

type MergedHistoricQuote = HistoricQuote & { comparedValue?: number };

export const mergeComparisonQuotesByDate = (
  investmentQuotes: HistoricQuote[],
  compareQuotes: HistoricQuote[]
): MergedHistoricQuote[] => {
  if (!compareQuotes.length) return investmentQuotes;
  return investmentQuotes.map((investmentQuote) => {
    const compareQuote = compareQuotes.find(
      (quote) => quote.date === investmentQuote.date
    );

    return {
      ...investmentQuote,
      comparedValue: compareQuote?.value ?? 0,
    };
  });
};

export const mergeComparisonInvestmentByDate = (
  investmentQuotes: Omit<SecuritiesChartDataWithDate, "areaValue">[],
  compareQuotes: HistoricQuote[]
) => {
  if (!compareQuotes.length) return investmentQuotes;
  return investmentQuotes.map((investmentQuote) => {
    const compareQuote = compareQuotes.find((quote) =>
      isEqual(parseJSON(quote.date), investmentQuote.date)
    );

    return {
      ...investmentQuote,
      comparedValue: compareQuote?.value ?? 0,
    };
  });
};
