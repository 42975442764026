import {
  Box,
  Button,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "@icons/warning.svg";
import { Banner, LoadingButton, Modal } from "@components";
import { ReactComponent as SuccessIcon } from "@icons/myicons/success.svg";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { ReactComponent as ArrowIcon } from "@icons/arrow-left.svg";

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export interface SettingsModalWrapperProps {
  isOpen: boolean;
  showSuccess?: boolean;
  showCloseIcon?: boolean;
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
  errorMessage?: string;
  children?: ReactNode;
  mobileHeight?: string;
  successTitle?: string;
  successSubtitle?: string;
  successIcon?: ReactNode;
  successButton?: string;
  confirmButton?: string;
  confirmButtonColor?: "primary" | "error";
  isConfirmButtonLoading?: boolean;
  isConfirmBtnDisabled?: boolean;
  cancelButton?: string;
  secondButton?: string;
  secondButtonColor?: "secondary" | "secondaryError";
  isSecondBtnDisabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSecondButtonConfirm?: () => void;
  onBack?: () => void;
}

export const SettingsModalWrapper = (props: SettingsModalWrapperProps) => {
  const {
    isOpen,
    showSuccess = false,
    showCloseIcon = false,
    title,
    subtitle,
    icon,
    errorMessage,
    children,
    mobileHeight = "calc(100% - 70px)",
    successTitle,
    successSubtitle,
    successIcon = <SuccessIcon />,
    successButton = "settingsPage.profile.modal.success.confirm",
    confirmButton,
    confirmButtonColor = "primary",
    isConfirmButtonLoading = false,
    isConfirmBtnDisabled = false,
    cancelButton,
    secondButton,
    secondButtonColor = "secondary",
    isSecondBtnDisabled = false,
    onBack,
    onClose,
    onConfirm,
    onSecondButtonConfirm,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal
      height={isMobile && !showSuccess ? mobileHeight : "auto"}
      width={830}
      disablePortal={false}
      isOpen={isOpen}
      onClose={onClose}
      position={isMobile ? "bottom" : "centered"}
      zIndex={4001}
      title={
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          gap={theme.spacing(6)}
        >
          <Box mb={theme.spacing(6)}>
            {showSuccess && (
              <Box style={{ marginBottom: theme.spacing(5) }}>
                {successIcon}
              </Box>
            )}
            {icon && !showSuccess && <Box>{icon}</Box>}
            <Box position="relative">
              {onBack && (
                <IconBox sx={{ left: 0 }} onClick={onBack}>
                  <ArrowIcon
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconBox>
              )}
              <Typography
                variant="body1"
                fontSize="26px"
                lineHeight="34px"
                fontWeight={600}
                letterSpacing={-0.6}
                color="textPrimary"
              >
                {showSuccess && successTitle ? successTitle : t(title ?? "")}
              </Typography>
              {showCloseIcon && (
                <IconBox sx={{ right: 0 }} onClick={onClose}>
                  <CloseIcon
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconBox>
              )}
            </Box>
            {(subtitle || (showSuccess && successSubtitle)) && (
              <Typography
                variant="body1"
                fontSize="18px"
                lineHeight="28px"
                fontWeight={400}
                textAlign="center"
                letterSpacing={-0.4}
                mt={theme.spacing(6)}
              >
                {t(!showSuccess ? subtitle ?? "" : successSubtitle ?? "")}
              </Typography>
            )}
          </Box>
        </Box>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {(errorMessage || children) && !showSuccess && (
          <Box mb={8}>
            {errorMessage && (
              <Banner
                type="error"
                icon={<InfoIcon color={theme.palette.error.dark} />}
                data-testid="error-alert"
                text={errorMessage}
                mb={4}
              />
            )}
            {children && (
              <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
            )}
          </Box>
        )}
        {!showSuccess ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection={isMobile ? "column" : "row"}
            gap={isMobile ? 4 : 6}
            width="100%"
          >
            <LoadingButton
              data-testid="settings-modal-action-button"
              variant="contained"
              color={confirmButtonColor}
              sx={{ width: "100%" }}
              autoFocus
              onClick={onConfirm}
              disabled={isConfirmBtnDisabled || isConfirmButtonLoading}
              isLoading={isConfirmButtonLoading}
            >
              {t(confirmButton ?? "")}
            </LoadingButton>
            {secondButton && (
              <Button
                variant="contained"
                color={secondButtonColor}
                onClick={onSecondButtonConfirm}
                disabled={isSecondBtnDisabled}
                sx={{ width: "100%" }}
              >
                {t(secondButton)}
              </Button>
            )}
            {cancelButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose}
                sx={{ width: "100%" }}
              >
                {t(cancelButton)}
              </Button>
            )}
          </Box>
        ) : (
          <Box width="100%">
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{ width: "100%", marginTop: theme.spacing(2) }}
            >
              {t(successButton)}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
