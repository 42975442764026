import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendYieldRequest,
  DividendYieldList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendYield = async (
  token: string | undefined,
  params: DividendsApiDividendYieldRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendYield(params);
  return data;
};

export const useDividendYield = (
  params: DividendsApiDividendYieldRequest,
  options?: UseQueryOptions<DividendYieldList, AxiosApiError, DividendYieldList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_YIELD, params],
    ({ token }) => dividendYield(token, params),
    options
  );
};
