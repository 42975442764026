import {
  SymbolV2,
  Symbol,
  QuoteV2,
  InvestmentTypeEnum,
} from "@generated/apiv1";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

const symbolIsOld = (
  symbol: Symbol | (SymbolV2 & { quote: QuoteV2 })
): symbol is Symbol => {
  return (symbol as Symbol).mappedType !== undefined;
};

export const setSymbolAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "INIT_FROM_SYMBOL") return {};

  const quoteSymbol = event.symbol;

  const symbol: Symbol = symbolIsOld(quoteSymbol)
    ? quoteSymbol
    : {
        ...quoteSymbol,
        currency: quoteSymbol.quote?.currency,
        tickerSymbol: quoteSymbol.quote?.tickerSymbol,
      };

  return {
    symbol,
    bookingValues: {
      ...context.bookingValues,
      entryQuote: quoteSymbol.quote?.last,
    },
    investmentValues: {
      ...context.investmentValues,
      name: quoteSymbol.name,
      isin: quoteSymbol.isin,
      wkn: quoteSymbol.wkn,
      type: (symbolIsOld(quoteSymbol)
        ? quoteSymbol.mappedType
        : quoteSymbol.type) as InvestmentTypeEnum,
      tickerSymbol: symbolIsOld(quoteSymbol)
        ? quoteSymbol.id
        : quoteSymbol.quote?.tickerSymbol,
      quoteProvider: quoteSymbol.quoteProvider,
      investmentCurrency: symbolIsOld(quoteSymbol)
        ? quoteSymbol.currency
        : quoteSymbol.quote?.currency,
      quoteCurrency: symbolIsOld(quoteSymbol)
        ? quoteSymbol.currency
        : quoteSymbol.quote?.currency,
    },
  };
};
