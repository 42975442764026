import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { accountTypes } from "@features/accounts/constants";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";

const BankAvatarImage = styled.img<{ width?: number; height?: number }>(
  ({ width, height, theme }) => `
  max-height: ${height || "40px"};
  max-width: ${width || "40px"};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.border.secondary};
`
);

const Image = styled(Box)<{ width?: number; height?: number }>(
  ({ theme, width, height }) => ({
    borderRadius: theme.shape.borderRadius,
    width: width || 40,
    height: height || 40,
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.tertiary.light,
    display: "flex",
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,

    "& svg": {
      fill: theme.palette.common.white,
    },
  })
);

type Props = {
  bankLogoURL?: string;
  bankName?: string;
  width?: number;
  height?: number;
  accountType?: AccountSubcategoryEnum;
};

export const BankAvatar = ({
  bankLogoURL,
  accountType,
  bankName,
  width,
  height,
}: Props) => {
  const type = accountTypes.find((type) => type.value === accountType);

  const noIcon =
    !bankLogoURL ||
    bankLogoURL.includes("DEPOT_square") ||
    bankLogoURL.includes("CHECKING_square") ||
    bankLogoURL.includes("MATERIAL_ASSETS_square");

  return !noIcon ? (
    <BankAvatarImage
      src={bankLogoURL}
      width={width}
      height={height}
      alt={bankName}
    />
  ) : (
    <Image width={width} height={height}>
      {type?.icon || accountTypes[0].icon}
    </Image>
  );
};
