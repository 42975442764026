/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CreateSepaTransactionParams } from './create-sepa-transaction-params';
// May contain unused imports in some cases
// @ts-ignore
import { SaasSecurity } from './saas-security';

/**
 * 
 * @export
 * @interface BatchSynchronizationParams
 */
export interface BatchSynchronizationParams {
    /**
     * The securities in the current portfolio, as delivered by the bank
     * @type {Array<SaasSecurity>}
     * @memberof BatchSynchronizationParams
     */
    'securities': Array<SaasSecurity>;
    /**
     * The total or recent batch of SEPA transactions as delivered by the bank
     * @type {Array<CreateSepaTransactionParams>}
     * @memberof BatchSynchronizationParams
     */
    'sepaTransactions': Array<CreateSepaTransactionParams>;
    /**
     * The synchronization type
     * @type {string}
     * @memberof BatchSynchronizationParams
     */
    'synchronizationType': BatchSynchronizationParamsSynchronizationTypeEnum;
    /**
     * A batch ID to tie individual synchronization runs together; default is to         create a unique ID for each run
     * @type {string}
     * @memberof BatchSynchronizationParams
     */
    'synchronizationBatchId'?: string;
}

export const BatchSynchronizationParamsSynchronizationTypeEnum = {
    MANUAL: 'MANUAL',
    BATCH_UPDATE: 'BATCH_UPDATE'
} as const;

export type BatchSynchronizationParamsSynchronizationTypeEnum = typeof BatchSynchronizationParamsSynchronizationTypeEnum[keyof typeof BatchSynchronizationParamsSynchronizationTypeEnum];


