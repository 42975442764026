import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendCalendarRequest,
  DividendCalendarRecordList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendCalendar = async (
  token: string | undefined,
  params?: DividendsApiDividendCalendarRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendCalendar(params);
  return data;
};

export const useDividendCalendar = (
  params?: DividendsApiDividendCalendarRequest,
  options?: UseQueryOptions<
    DividendCalendarRecordList,
    AxiosApiError,
    DividendCalendarRecordList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_CALENDAR, params],
    ({ token }) => dividendCalendar(token, params),
    options
  );
};
