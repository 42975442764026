import { InvokeCallback, Sender } from "xstate";

import { getBookingsWithToken } from "@api/v1/methods/bookings";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const loadBookings =
  (
    context: TransactionWizardContext
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (!context.investmentValues.id)
      throw new Error("Investment ID must be provided in investment values");
    load(context.investmentValues.id, callback);
  };

const load = async (
  investmentId: number,
  callback: Sender<TransactionWizardEvent>
) => {
  try {
    const bookingList = await getBookingsWithToken({
      investmentId: [investmentId],
      type: ["buy", "sell", "book_in", "book_out", "retained_dividend"],
    });
    callback({
      type: "BOOKINGS_LOADED",
      bookings: bookingList.bookings || [],
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
