import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckCircleIcon } from "@icons/myicons/check-circle-filled.svg";

const SuccessCircleIcon = styled(CheckCircleIcon)`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NoUpdateRequired = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <SuccessCircleIcon width="30px" height="30px" />
        <Typography variant="body1">
          {t("synchronizationLog.noUpdateRequired")}
        </Typography>
      </Container>
    </>
  );
};
