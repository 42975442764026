import { UseQueryOptions } from "react-query";
import { TransactionsApiMt940Request } from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const mt940 = async (
  token: string | undefined,
  params: TransactionsApiMt940Request
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.mt940(params);
  return data;
};

export const useMt940 = (
  params: TransactionsApiMt940Request,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TRANSACTIONS_MT940, params],
    ({ token }) => mt940(token, params),
    options
  );
};
