import { UseQueryOptions } from "react-query";
import {
  AccountsApiCategorizeRequest,
  AssetCategorization,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const categorize = async (
  token: string | undefined,
  params?: AccountsApiCategorizeRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.categorize(params);
  return data;
};

export const useCategorize = (
  params?: AccountsApiCategorizeRequest,
  options?: UseQueryOptions<
    AssetCategorization,
    AxiosApiError,
    AssetCategorization
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_CATEGORIZE, params],
    ({ token }) => categorize(token, params),
    options
  );
};
