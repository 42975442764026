import React from "react";
import { InvestmentValues } from "@features/transactionWizard/stateMachine/types/investmentValues";
import { SplitPreviewStep } from "@features/transactionWizard/steps/SplitPreviewStep";
import { RatioAndDateStep } from "@features/transactionWizard/steps/RatioAndDateStep";
import { InvestmentManualStep } from "@features/transactionWizard/steps/InvestmentManualStep";
import { BookingValues } from "./stateMachine/types/bookingValues";
import { BookingTypeStep } from "./steps/BookingTypeStep";
import { NameAndDepotStepWithLookup } from "./steps/NameAndDepotStepWithLookup";
import { PersistedStep } from "./steps/PersistedStep";
import { PriceAndDateStep } from "./steps/PriceAndDateStep";
import { SomethingWrong } from "./steps/SomethingWrong";
import { SummaryStep } from "./steps/SummaryStep";
import { SendEventType } from "./stateMachine/types/SendEventType";
import { StateType } from "./stateMachine/types/StateType";
import {
  BookingTypeWithSplit,
  SplitValues,
} from "./stateMachine/types/splitValues";

const getRedirectUrl = (depotId?: number) => {
  return depotId ? `/depots/${depotId}` : "/investments";
};

export interface Props {
  currentState: StateType;
  sendEvent: SendEventType;
  isManualTransaction?: boolean;
  initFromNewTransaction?: boolean;
  initFromTickerSymbol?: boolean;
  depotId?: number;
}

export const NewTransactionSteps = ({
  currentState,
  sendEvent,
  isManualTransaction,
  initFromTickerSymbol = false,
  initFromNewTransaction,
  depotId,
}: Props) => {
  const backOneStep = () => {
    initFromTickerSymbol ? window.history.back() : sendEvent({ type: "BACK" });
  };
  const backFromBookingTypeStep = () => {
    isManualTransaction || initFromNewTransaction
      ? window.history.back()
      : sendEvent({ type: "BACK" });
  };

  return (
    <>
      {currentState.matches("selectBookingType") && (
        <BookingTypeStep
          context={currentState.context}
          handleNext={(
            bookingType: BookingTypeWithSplit,
            investmentId?: number
          ) => {
            sendEvent({
              type: "BOOKING_TYPE_SELECTED",
              bookingType: bookingType,
              investmentId,
            });
          }}
          isManualTransaction={isManualTransaction}
          handlePrevious={backFromBookingTypeStep}
        />
      )}

      {currentState.matches("depots") && (
        <NameAndDepotStepWithLookup
          handleNext={(
            depotId: number,
            investmentValues: InvestmentValues,
            comment?: string
          ) =>
            sendEvent({
              type: "NAME_AND_DEPOT_SELECTED",
              depotId: depotId,
              investmentValues: investmentValues,
              comment: comment,
            })
          }
          isManualTransaction={isManualTransaction}
          handlePrevious={backOneStep}
          context={currentState.context}
        />
      )}

      {currentState.matches("manualInvestment") && (
        <InvestmentManualStep
          context={currentState.context}
          handleNext={(investment) => {
            sendEvent({
              type: "INVESTMENT_SELECTED",
              investmentValues: investment,
            });
          }}
          handlePrevious={() => sendEvent({ type: "BACK" })}
        />
      )}

      {currentState.matches("priceAndDate") && (
        <PriceAndDateStep
          handleNext={(bookingValues: BookingValues, currency?: string) =>
            sendEvent({
              type: "VALIDATE_BOOKING",
              bookingValues,
              currency,
            })
          }
          symbol={currentState.context.symbol}
          handlePrevious={() => sendEvent({ type: "BACK" })}
          bookingValues={currentState.context.bookingValues}
          investmentValues={currentState.context.investmentValues}
          sourceInvestmentValues={currentState.context.sourceInvestmentValues}
          validationError={currentState.context.validationError}
          isNewTransaction
        />
      )}

      {currentState.matches("split.preview") && (
        <SplitPreviewStep
          handleNext={() =>
            sendEvent({
              type: "PERSIST",
            })
          }
          handlePrevious={() => sendEvent({ type: "BACK" })}
          splitValues={currentState.context.splitValues || {}}
        />
      )}

      {currentState.matches("split.ratioAndDate") && (
        <RatioAndDateStep
          splitValues={currentState.context.splitValues || {}}
          investmentName={currentState.context.investmentValues.name || ""}
          handleNext={(splitValues: SplitValues) =>
            sendEvent({
              type: "PREVIEW",
              splitValues,
            })
          }
          handlePrevious={() => sendEvent({ type: "BACK" })}
          validationError={currentState.context.validationError}
        />
      )}

      {currentState.matches("summary") && (
        <SummaryStep
          handleNext={() =>
            sendEvent({
              type: "PERSIST",
            })
          }
          error={currentState.context.error}
          handlePrevious={backOneStep}
          bookingValues={currentState.context.bookingValues}
          investmentValues={currentState.context.investmentValues}
        />
      )}

      {currentState.matches("persisted") && (
        <PersistedStep
          redirectUrl={getRedirectUrl(
            currentState.context.investmentValues.depotId
          )}
          investmentName={
            currentState.context.sourceInvestmentValues?.name ||
            currentState.context.investmentValues.name ||
            ""
          }
        />
      )}

      {currentState.matches("somethingWrong") && (
        <SomethingWrong message={currentState.context.error?.message} />
      )}
    </>
  );
};
