import { UseMutationOptions } from "react-query";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const autoAssignMissingProperties = async (
  token: string | undefined
) => {
  const apiInstance = buildSecurityCommissionsApi(token);
  const { data } = await apiInstance.autoAssignMissingProperties();
  return data;
};

export const useAutoAssignMissingProperties = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(autoAssignMissingProperties, options, false);
};
