import { UseMutationOptions } from "react-query";
import {
  TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest,
  TimeDependentProperty,
} from "@generated/apiv1";

import { buildTimeDependentPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateTimeDependentProperty = async (
  token: string | undefined,
  params: TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest
) => {
  const apiInstance = buildTimeDependentPropertiesApi(token);
  const { data } = await apiInstance.updateTimeDependentProperty(params);
  return data;
};

export const useUpdateTimeDependentProperty = (
  options?: UseMutationOptions<
    TimeDependentProperty,
    AxiosApiError,
    TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest
  >
) => {
  return useAuthMutation(updateTimeDependentProperty, options, false);
};
