import { deleteInvestment, getInvestment } from "@api/v1/methods";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import { GET_INVESTMENT_KEY } from "../../cacheKeys";

export const useInvestment = (
  investmentId?: number,
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) =>
  useAuthQuery(
    [GET_INVESTMENT_KEY, investmentId!],
    ({ token }) => getInvestment(token, investmentId!),
    {
      enabled: !!investmentId && enabled,
    }
  );

export const useDeleteInvestment = () =>
  useAuthMutation(deleteInvestment, {
    useErrorBoundary: true,
  });
