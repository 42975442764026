import { useTheme } from "@emotion/react";
import {
  Box,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from "@icons/arrow-left.svg";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { ReactComponent as DotsIcon } from "@icons/dots.svg";
import { SecurityImage } from "@components/SecurityImage";
import { Account } from "@generated/apiv1";
import { toCurrency } from "@helpers";
import { ModalState } from "../SettingsConnectionsPage";

const ActiveBox = styled(Box)(({ theme }) => ({
  color: theme.palette.success.dark,
  backgroundColor: theme.palette.background.success,
  borderRadius: "24px",
  padding: theme.spacing(0.75, 2),
  width: "fit-content",
}));

interface Props {
  bankName: string;
  bankId?: string | number;
  id: number;
  provider: "FIN_API" | "WEALTH_API";
  accounts: Account[];
  setShowModal: (value: ModalState) => void;
}

export const SettingsBankConnection = ({
  bankName,
  id,
  bankId,
  provider,
  accounts,
  setShowModal,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const icon = accounts?.[0]?.iconUrl;

  const handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(
      `/import-bank-connection/redirect?bankId=${bankId}&multibankingApiProvider=${provider}`
    );
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowModal({ id, title: bankName, state: "open", provider });
  };

  return (
    <>
      <Box width="100%" onClick={() => setIsExpanded(!isExpanded)}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={theme.spacing(2)}
          sx={{
            cursor: "pointer",
          }}
        >
          <Box display="flex" alignItems="center" gap={theme.spacing(4)}>
            <Icon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& svg": {
                  transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
                  transition: "transform 0.2s ease-in-out",
                  "& path": {
                    stroke: theme.palette.icon.primary,
                  },
                },
              }}
            >
              <ArrowIcon width="28px" height="28px" />
            </Icon>
            <SecurityImage src={icon} height={48} width={48} />
            <Typography variant="subtitle1" lineHeight="28px">
              {bankName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={theme.spacing(4)}>
            <IconButton
              color="secondary"
              onClick={handlePlusClick}
              sx={{ height: "36px", width: "36px" }}
            >
              <PlusIcon />
            </IconButton>
            <IconButton
              color="info"
              onClick={handleMenuClick}
              sx={{
                backgroundColor: theme.palette.background.neutral,
                height: "36px",
                width: "36px",
              }}
            >
              <DotsIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {isExpanded && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
            m={theme.spacing(4, 0, 6, 11)}
          >
            {accounts.map((acc) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="subtitle1" lineHeight="28px">
                    {t(
                      `settings.bankConnection.accountTypes.${
                        provider === "FIN_API" ? acc.type : acc.type
                      }`
                    )}
                  </Typography>
                  {(acc.depotIds?.length ?? 0) <= 0 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={theme.spacing(2)}
                      mb={theme.spacing(2)}
                    >
                      <Typography variant="body1" color="textSecondary">
                        {acc.name}
                      </Typography>
                      {!isMobile && acc.iban && (
                        <Typography variant="body1" color="textSecondary">
                          ·
                        </Typography>
                      )}
                      {acc.iban && (
                        <Typography variant="body1" color="textSecondary">
                          {acc.iban}
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={theme.spacing(2)}
                      mb={theme.spacing(2)}
                    >
                      {acc.iban && (
                        <Typography variant="body1" color="textSecondary">
                          {acc.iban}
                        </Typography>
                      )}
                      {!isMobile && acc.iban && (
                        <Typography variant="body1" color="textSecondary">
                          ·
                        </Typography>
                      )}
                      <Typography variant="body1" color="textSecondary">
                        ID: {acc.id}
                      </Typography>
                    </Box>
                  )}
                  <ActiveBox color="success.main">
                    {t("domains.cashback.dashboard.status.processed")}
                  </ActiveBox>
                </Box>
                <Typography variant="subtitle1" lineHeight="28px">
                  {toCurrency(acc.balance, acc.currency)}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};
