import { BookingTypeEnum } from "@api";

interface ExpectedDividend {
  date: Date;
  dividendPerShare: number;
}

export interface Booking {
  valueDate: Date;
  amount: number;
  type: BookingTypeEnum;
}

export interface ChartHistoricQuote {
  date: Date;
  value: number;
  areaValue: [number, number];
}

export interface Event {
  amount: number;
  type: BookingTypeEnum | "expected_dividend";
}
export interface InvestmentChartData {
  date: Date;
  value: number;
  originalValue?: number;
  areaValue: [number, number];
  eventsValue?: number;
  events?: Event[];
  comparedValue?: number;
  comparedOriginalValue?: number;
}

export const mergeCloseByDate = (
  historicQuotes: ChartHistoricQuote[],
  expectedDividends: ExpectedDividend[],
  bookings: Booking[]
): InvestmentChartData[] => {
  const newValue = historicQuotes.map((item, index) => {
    if (index === 0) {
      return item;
    }
    const matchedExpectedDividend = expectedDividends.find(
      ({ date }) =>
        date <= item.date &&
        (!historicQuotes[index - 1] || date > historicQuotes[index - 1].date)
    );

    const matchedBookings = bookings.filter(
      ({ valueDate }) =>
        valueDate <= item.date &&
        (!historicQuotes[index - 1] ||
          valueDate > historicQuotes[index - 1].date)
    );

    let newItem: InvestmentChartData = { ...item };

    if (matchedExpectedDividend) {
      newItem = {
        ...newItem,
        events: [
          {
            amount: matchedExpectedDividend.dividendPerShare,
            type: "expected_dividend",
          },
        ],
        eventsValue: newItem.value,
      };
    }

    if (matchedBookings && matchedBookings.length > 0) {
      newItem = {
        ...newItem,
        events: [...(newItem.events || []), ...matchedBookings],
        eventsValue: newItem.value,
      };
    }

    return newItem;
  });

  return newValue;
};
