import { Link, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { TppWebFormTermsOfUse } from "@components/common/layouts/TppWebForm/TppWebFormTermsOfUse";
import { ImpressumTppWebForm } from "@components/common/layouts/TppWebForm/ImpressumTppWebForm";
import { TermsDialog } from "../../../TermsDialog";
import { ReactComponent as WealthApiLogo } from "./wealthapi-logo.svg";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const TextContainer = styled.div`
  padding-top: 7px;
  margin-right: 10px;
`;

const LinkWithMargin = styled(Link)`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const TppWebFormFooter = () => {
  const { t } = useTranslation();
  const [openTerms, setOpenTerms] = useState(false);
  const [openImpressum, setOpenImpressum] = useState(false);

  return (
    <div>
      <Container>
        <TextContainer>
          <Typography variant="body1">Powered by:</Typography>
        </TextContainer>
        <WealthApiLogo width="119px" height="57px" />
      </Container>
      <Container>
        <LinkWithMargin onClick={() => setOpenTerms(true)}>
          {t("terms.agb")}
        </LinkWithMargin>
        <Link onClick={() => setOpenImpressum(true)} underline="hover">
          {t("terms.impressum")}
        </Link>
      </Container>

      <TermsDialog
        open={openTerms}
        title={t("terms.agb")}
        onClose={() => setOpenTerms(false)}
      >
        <TppWebFormTermsOfUse showTitle={false} />
      </TermsDialog>
      <TermsDialog
        open={openImpressum}
        title={t("terms.impressum")}
        onClose={() => setOpenImpressum(false)}
      >
        <ImpressumTppWebForm showTitle={false} />
      </TermsDialog>
    </div>
  );
};
