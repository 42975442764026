/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams } from '../model';
// @ts-ignore
import { InsuranceProviderSpecificContractIdPattern } from '../model';
/**
 * InsuranceProviderSpecificContractIdPatternsApi - axios parameter creator
 * @export
 */
export const InsuranceProviderSpecificContractIdPatternsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an Insurance Provider specific pattern
         * @param {CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsuranceProviderSpecificContractIdPattern: async (body: CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createInsuranceProviderSpecificContractIdPattern', 'body', body)
            const localVarPath = `/v1/insuranceProviderSpecificContractIdPatterns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an Insurance Provider specific pattern
         * @param {number} id ID of the pattern to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsuranceProviderSpecificContractIdPattern: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInsuranceProviderSpecificContractIdPattern', 'id', id)
            const localVarPath = `/v1/insuranceProviderSpecificContractIdPatterns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceProviderSpecificContractIdPatternsApi - functional programming interface
 * @export
 */
export const InsuranceProviderSpecificContractIdPatternsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceProviderSpecificContractIdPatternsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an Insurance Provider specific pattern
         * @param {CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsuranceProviderSpecificContractIdPattern(body: CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProviderSpecificContractIdPattern>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsuranceProviderSpecificContractIdPattern(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceProviderSpecificContractIdPatternsApi.createInsuranceProviderSpecificContractIdPattern']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an Insurance Provider specific pattern
         * @param {number} id ID of the pattern to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInsuranceProviderSpecificContractIdPattern(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInsuranceProviderSpecificContractIdPattern(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceProviderSpecificContractIdPatternsApi.deleteInsuranceProviderSpecificContractIdPattern']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsuranceProviderSpecificContractIdPatternsApi - factory interface
 * @export
 */
export const InsuranceProviderSpecificContractIdPatternsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceProviderSpecificContractIdPatternsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an Insurance Provider specific pattern
         * @param {InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsuranceProviderSpecificContractIdPattern(requestParameters: InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsuranceProviderSpecificContractIdPattern> {
            return localVarFp.createInsuranceProviderSpecificContractIdPattern(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an Insurance Provider specific pattern
         * @param {InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsuranceProviderSpecificContractIdPattern(requestParameters: InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteInsuranceProviderSpecificContractIdPattern(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInsuranceProviderSpecificContractIdPattern operation in InsuranceProviderSpecificContractIdPatternsApi.
 * @export
 * @interface InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest
 */
export interface InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams}
     * @memberof InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPattern
     */
    readonly body: CreateOrUpdateInsuranceProviderSpecificContractIdPatternParams
}

/**
 * Request parameters for deleteInsuranceProviderSpecificContractIdPattern operation in InsuranceProviderSpecificContractIdPatternsApi.
 * @export
 * @interface InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest
 */
export interface InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest {
    /**
     * ID of the pattern to delete
     * @type {number}
     * @memberof InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPattern
     */
    readonly id: number
}

/**
 * InsuranceProviderSpecificContractIdPatternsApi - object-oriented interface
 * @export
 * @class InsuranceProviderSpecificContractIdPatternsApi
 * @extends {BaseAPI}
 */
export class InsuranceProviderSpecificContractIdPatternsApi extends BaseAPI {
    /**
     * 
     * @summary Create an Insurance Provider specific pattern
     * @param {InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProviderSpecificContractIdPatternsApi
     */
    public createInsuranceProviderSpecificContractIdPattern(requestParameters: InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest, options?: RawAxiosRequestConfig) {
        return InsuranceProviderSpecificContractIdPatternsApiFp(this.configuration).createInsuranceProviderSpecificContractIdPattern(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an Insurance Provider specific pattern
     * @param {InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProviderSpecificContractIdPatternsApi
     */
    public deleteInsuranceProviderSpecificContractIdPattern(requestParameters: InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest, options?: RawAxiosRequestConfig) {
        return InsuranceProviderSpecificContractIdPatternsApiFp(this.configuration).deleteInsuranceProviderSpecificContractIdPattern(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

