import { useMemo } from "react";
import {
  useDividendsContext,
  useDividendsForPeriod,
} from "@features/dividendPlanner/DividendsProvider";
import { DividendHistoryTreeNode } from "@generated/apiv1";
import { useTableSort } from "@hooks";
import { generateRandomNumber } from "@helpers";

const getMonthChildren = (month: DividendHistoryTreeNode) => {
  return Object.values(month.children || {}) as unknown as Dividend[];
};

export type Dividend = Omit<DividendHistoryTreeNode, "children"> & {
  children: Dividend[];
};

type Props = {
  date: Date;
  period: "year" | "month" | "day" | undefined;
  displayedSlice: number;
};

export const useDividendsForTable = ({
  date,
  period,
  displayedSlice,
}: Props) => {
  const isDailyView = period === "day";
  const isMonthlyView = period === "month";
  const isYearlyView = !period;
  const { investments, isUserPro, isUserDataFetched, filter } =
    useDividendsContext();
  const dividends = useDividendsForPeriod({
    year: Boolean(period) ? date.getFullYear() : undefined,
    month: period === "day" || period === "month" ? date.getMonth() : undefined,
    day: period === "day" ? date.getDate() : undefined,
  });
  const { sort, sortField, sortDirection } = useTableSort({
    field: filter.sortByPaymentDate ? "paymentDate" : "exDate",
    direction: "ASC",
  });

  const dividendsToDisplay = useMemo(() => {
    if (isYearlyView) {
      return Object.values(dividends || {}).reduce(
        (acc, year) => [
          ...acc,
          {
            ...year,
            children: Object.values(year.children || {}).reduce(
              (acc, month) => [...acc, ...getMonthChildren(month)],
              [] as Dividend[]
            ),
          },
        ],
        [] as Dividend[]
      );
    } else if (isMonthlyView || isDailyView) {
      return [
        {
          ...dividends?.[date.getMonth()],
          children: Object.values(
            dividends?.[date.getMonth()]?.children || {}
          ) as unknown as Dividend[],
        },
      ];
    } else {
      return Object.values(
        dividends?.[date.getFullYear() || new Date().getFullYear()]?.children ||
          {}
      ).map((month) => ({
        ...month,
        children: getMonthChildren(month),
      }));
    }
  }, [dividends, date, isMonthlyView, isYearlyView, isDailyView]);

  const sortedDividends = useMemo(() => {
    return dividendsToDisplay
      .sort((a, b) => ((a.year || 0) >= (b.year || 0) ? -1 : 1))
      .map((parent) => {
        return {
          ...parent,
          children: parent.children?.sort((a, b) => {
            const valueA =
              (sortField === "name"
                ? investments?.[a.investmentIds[0]]?.standardisedName
                : a[sortField as keyof DividendHistoryTreeNode]) || "";
            const valueB =
              (sortField === "name"
                ? investments?.[b.investmentIds[0]]?.standardisedName
                : b[sortField as keyof DividendHistoryTreeNode]) || "";
            return sortDirection === "ASC"
              ? valueA > valueB
                ? 1
                : -1
              : valueA < valueB
              ? 1
              : -1;
          }),
        };
      });
  }, [dividendsToDisplay, sortDirection, sortField, investments]);

  const finalDividends = useMemo(() => {
    if (!isUserDataFetched) return [];

    if (isUserDataFetched && isUserPro) {
      return sortedDividends;
    }

    return sortedDividends.map((year) => {
      const newChildren = year.children.map((dividend) => {
        const amount = generateRandomNumber(0, dividend.grossAmount);
        if (!dividend.isPrediction) return dividend;

        return {
          ...dividend,
          grossAmount: amount,
          grossAmountPerShare: amount / (dividend.numberOfLots || 1),
        };
      });
      return {
        ...year,
        children: newChildren,
        grossAmount: newChildren.reduce(
          (acc, dividend) => acc + dividend.grossAmount,
          0
        ),
      };
    });
  }, [isUserDataFetched, isUserPro, sortedDividends]);

  const dividendsToDisplaySliced = useMemo(
    () =>
      finalDividends.slice(
        0,
        displayedSlice * (period === "year" || !period ? 5 : 10)
      ),
    [finalDividends, displayedSlice, period]
  );

  const hasPredictions = useMemo(
    () => dividendsToDisplay.some((dividend) => dividend.isPrediction),
    [dividendsToDisplay]
  );

  return {
    dividends: dividendsToDisplaySliced,
    allDividends: dividendsToDisplay,
    hasPredictions,
    sort,
    sortField,
    sortDirection,
  };
};
