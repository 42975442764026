import { AxiosError } from "axios";

export const isEmailConfirmationRequiredError = (
  error: AxiosError<ApiException>
) => {
  return (
    error.response?.status === 401 &&
    !!error.response?.data.errors?.find(
      ({ code }) => code === "EMAIL_CONFIRMATION_REQUIRED"
    )
  );
};
