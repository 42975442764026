import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  WatchlistList,
  ListAndSearchAllWatchlists,
  CreateOrUpdateWatchlistParams,
  Watchlist,
} from "../model";

export const getSingleWatchlist = async (
  token: string | undefined,
  id: string
) => {
  const response = await axiosInstanceV1.get<Watchlist>(`/watchlists/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getWatchlists = async (
  token: string | undefined,
  params?: ListAndSearchAllWatchlists
) => {
  const response = await axiosInstanceV1.get<WatchlistList>(`/watchlists`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  return response.data;
};

export const createWatchlist = async (
  token: string | undefined,
  body: CreateOrUpdateWatchlistParams
) => {
  const { data } = await axiosInstanceV1.post<Watchlist>(`/watchlists`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const updateWatchlist = async (
  token: string | undefined,
  { id, ...body }: CreateOrUpdateWatchlistParams & { id: number }
) => {
  const { data } = await axiosInstanceV1.put<Watchlist>(
    `/watchlists/${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const deleteWatchlist = async (
  token: string | undefined,
  { id }: { id: string }
) => {
  const { data } = await axiosInstanceV1.delete(`/watchlists/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
