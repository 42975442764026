import { UseQueryOptions } from "react-query";
import {
  MasterDataApiV2GetMasterDataRequest,
  MasterDataList,
} from "@generated/apiv1";

import { buildMasterDataApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const v2GetMasterData = async (
  token: string | undefined,
  params: MasterDataApiV2GetMasterDataRequest
) => {
  const apiInstance = buildMasterDataApi(token);
  const { data } = await apiInstance.v2GetMasterData(params);
  return data;
};

export const useV2GetMasterData = (
  params: MasterDataApiV2GetMasterDataRequest,
  options?: UseQueryOptions<MasterDataList, AxiosApiError, MasterDataList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.MASTERDATA_V2_GET_MASTER_DATA, params],
    ({ token }) => v2GetMasterData(token, params),
    options
  );
};
