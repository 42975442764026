import { useAuthQuery, loadFinancials } from "@api";

interface Props {
  isin?: string;
  from?: number;
  to?: number;
}

export const useFinancials = (
  { isin, from, to }: Props,
  { enabled = true }: { enabled?: boolean } = {}
) =>
  useAuthQuery(
    ["fundamental-financials", isin!, from, to],
    ({ token }) => loadFinancials(token, isin!, from, to),
    {
      enabled: !!isin && enabled,
      refetchOnWindowFocus: false,
    }
  );
