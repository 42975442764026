import { UseQueryOptions } from "react-query";
import {
  ClassificationsApiListRegionClassificationsForAllInvestmentsRequest,
  RegionBreakdownList,
} from "@generated/apiv1";

import { buildClassificationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listRegionClassificationsForAllInvestments = async (
  token: string | undefined,
  params?: ClassificationsApiListRegionClassificationsForAllInvestmentsRequest
) => {
  const apiInstance = buildClassificationsApi(token);
  const { data } = await apiInstance.listRegionClassificationsForAllInvestments(
    params
  );
  return data;
};

export const useListRegionClassificationsForAllInvestments = (
  params?: ClassificationsApiListRegionClassificationsForAllInvestmentsRequest,
  options?: UseQueryOptions<
    RegionBreakdownList,
    AxiosApiError,
    RegionBreakdownList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CLASSIFICATIONS_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
      params,
    ],
    ({ token }) => listRegionClassificationsForAllInvestments(token, params),
    options
  );
};
