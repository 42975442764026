import { axiosInstanceV1 } from "@api/axiosInstance";
import { ExchangeRate, GetExchangeRateParams } from "@api/v1/model";

export const getExchangeRate = async (params: GetExchangeRateParams) => {
  const response = await axiosInstanceV1.get<ExchangeRate>(`/exchangeRates`, {
    params: {
      ...params,
    },
  });
  return response.data;
};
