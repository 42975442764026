import { Box, BoxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { YTDPercents } from "@components/common";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const PercentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const FirstLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(4.75)};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface CardLabelProps extends BoxProps {
  icon?: ReactNode;
  title: string;
  headline?: string;
  subtitle?: string;
  percent?: number;
  caption?: ReactNode;
  className?: string;
}

export const CardLabel = ({
  icon,
  title,
  subtitle,
  headline,
  percent,
  className,
  caption,
  ...props
}: CardLabelProps) => {
  return (
    <Container className={className} {...props}>
      {caption}
      <FirstLine>
        <TitleContainer>
          {icon}
          <Typography variant="body2" noWrap sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </TitleContainer>
        {percent != null && (
          <PercentWrapper>
            <YTDPercents value={percent} />
          </PercentWrapper>
        )}
      </FirstLine>
      {headline && (
        <Typography variant="h4" noWrap>
          {headline}
        </Typography>
      )}

      {subtitle && (
        <Typography variant="caption" color="text.tertiary">
          {subtitle}
        </Typography>
      )}
    </Container>
  );
};
