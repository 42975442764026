import {
  Box,
  IconButton,
  Popover,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as Calendar } from "@icons/calendar-schedule.svg";
import { DateRangePicker } from "@components";
import { BarChartMode, Range } from "../types";

type ButtonsProps = {
  handleYearChange: (direction: "back" | "forward") => void;
  isBackDisabled: boolean;
  isForwardDisabled: boolean;
  year: string | number;
};

const Buttons = ({
  handleYearChange,
  isBackDisabled,
  isForwardDisabled,
  year,
}: ButtonsProps) => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isLessSm ? "space-between" : "flex-start"}
      gap={3}
    >
      <IconButton
        disabled={isBackDisabled}
        onClick={() => handleYearChange("back")}
        size="small"
        color="grey"
      >
        <KeyboardArrowLeftIcon fontSize="small" />
      </IconButton>
      <IconButton
        disabled={isForwardDisabled}
        onClick={() => handleYearChange("forward")}
        size="small"
        color="grey"
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

type Props = {
  mode: BarChartMode;
  onModeChange: (mode: BarChartMode) => void;
  onMainPeriodChange: (period: Required<RangeType>) => void;
  onCustomPeriodSelect: (from: Date, to: Date) => void;
  onCustomPeriodReset: () => void;
  mainPeriod: Range;
};

export const BarChartToolbar = ({
  mode,
  onModeChange,
  onMainPeriodChange,
  onCustomPeriodSelect,
  onCustomPeriodReset,
  mainPeriod,
}: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handleDateChange = (from?: Date, to?: Date) => {
    if (!from || !to) return;
    onCustomPeriodSelect(from, to);
    if (to) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { t } = useTranslation();
  const isMoreSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const startYear = mainPeriod.startDate.getFullYear();
  const endYear = mainPeriod.endDate.getFullYear();
  const displayedYear =
    startYear === endYear ? startYear : [startYear, endYear].join(" - ");
  const isBackYearDisabled = mode === "custom";
  const isForwardYearDisabled =
    mode === "custom" || endYear === new Date().getFullYear();

  const handleYearChange = (direction: "back" | "forward") => {
    if (mode === "custom") return;
    if (direction === "forward") {
      onMainPeriodChange({
        startDate: new Date(startYear + 1, 0, 1),
        endDate: new Date(startYear + 1, 11, 31),
      });
    } else {
      onMainPeriodChange({
        startDate: new Date(startYear - 1, 0, 1),
        endDate: new Date(startYear - 1, 11, 31),
      });
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={isMoreSm ? "row" : "column"}
      justifyContent="space-between"
      mb={10}
    >
      <Box>
        <Typography variant="caption" color="textSecondary">
          {t("cashflowAnalyzer.timePeriod")}
        </Typography>
        <Typography variant="subtitle1" mb={2}>
          {displayedYear}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent={isMoreSm ? "flex-end" : "space-between"}
        gap={5}
      >
        <Box display="flex" gap={2} alignItems="center">
          <ToggleButtonGroup color="secondary" value={mode}>
            <ToggleButton
              value="monthly"
              onClick={() => onModeChange("monthly")}
            >
              {t("cashflowAnalyzer.monthly")}
            </ToggleButton>
            <ToggleButton
              value="quarterly"
              onClick={() => onModeChange("quarterly")}
            >
              {t("cashflowAnalyzer.quarterly")}
            </ToggleButton>
            <ToggleButton value="custom" onClick={handleOpen} ref={ref}>
              <Calendar width="20px" height="20px" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Buttons
          handleYearChange={handleYearChange}
          isBackDisabled={isBackYearDisabled}
          isForwardDisabled={isForwardYearDisabled}
          year={displayedYear}
        />
        <Popover
          open={open}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateRangePicker onChange={handleDateChange} />
        </Popover>
      </Box>
    </Box>
  );
};
