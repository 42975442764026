/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ContractId
 */
export interface ContractId {
    /**
     * The (DB-level) ID
     * @type {number}
     * @memberof ContractId
     */
    'id'?: number;
    /**
     * The contract ID
     * @type {string}
     * @memberof ContractId
     */
    'contractId'?: string;
    /**
     * Is the contract ID curated (i.e. modified or confirmed by the user)?
     * @type {boolean}
     * @memberof ContractId
     */
    'isContractIdCurated'?: boolean;
    /**
     * The type of ID; this may be a contract ID, a customer ID or something else
     * @type {string}
     * @memberof ContractId
     */
    'type'?: ContractIdTypeEnum;
    /**
     * Is the type curated (i.e. modified or confirmed by the user)?
     * @type {boolean}
     * @memberof ContractId
     */
    'isTypeCurated'?: boolean;
    /**
     * A probabilistic score giving the quality of the automatically detected ID; the higher                                the better. Scores above 1 are highly reliable, scores below 0.5 should make you                                suspicious. A scoe is only available if the entity was automatically detected and not                                manually created.
     * @type {number}
     * @memberof ContractId
     */
    'score'?: number;
    /**
     * Has the ID been automatically detected (true) or was it manually assigned (false)?
     * @type {boolean}
     * @memberof ContractId
     */
    'wasDetected'?: boolean;
}

export const ContractIdTypeEnum = {
    CONTRACT_ID: 'contract_id',
    CUSTOMER_ID: 'customer_id',
    OTHER_ID: 'other_id'
} as const;

export type ContractIdTypeEnum = typeof ContractIdTypeEnum[keyof typeof ContractIdTypeEnum];


