import { useEffect } from "react";
export const useGoogleTagManagerEngagement = (
  userFlowId: string,
  data: Record<string, string | undefined | null> = {},
  enabled: boolean = true
) => {
  useEffect(() => {
    let inViewTimer: number;
    let highlyEngagedTimer: number;

    if (!enabled) return;

    inViewTimer = (setTimeout as Window["setTimeout"])(() => {
      window.dataLayer?.push({
        userFlowId,
        event: "in-view",
        ...data,
      });
    }, 2000);

    highlyEngagedTimer = (setTimeout as Window["setTimeout"])(() => {
      window.dataLayer?.push({
        userFlowId,
        event: "high-engagement",
        ...data,
      });
    }, 15000);

    return () => {
      clearTimeout(inViewTimer);
      clearTimeout(highlyEngagedTimer);
    };
  }, [userFlowId, data, enabled]);
};
