/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateInsuranceProviderParams
 */
export interface CreateOrUpdateInsuranceProviderParams {
    /**
     * The provider name; a search will try to match the name; required for creation
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'name'?: string;
    /**
     * An optional short pre-filter tag for the search algorithm; the tag must be matched in order to                                count as a valid search result. Send an empty or blank string to clear.
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'nameTag'?: string;
    /**
     * An optional description (informative only); send an empty or blank string to clear.
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'description'?: string;
    /**
     * A unique ID of the insurance company; send an empty or blank string to clear.
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'providerId'?: string;
    /**
     * A unique external reference, e.g. to link to third party master data;                                send an empty or blank string to clear.
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'externalReference'?: string;
    /**
     * Is this an alias entry?
     * @type {boolean}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'isAlias'?: boolean;
    /**
     * ID of the insurance provider for which this entry is an alias; will be cleared if                                isAlias is set to false.
     * @type {number}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'aliasFor'?: number;
    /**
     * Is automatic detection enabled? Required for creation
     * @type {boolean}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'automaticDetection'?: boolean;
    /**
     * The status; will default to active if not given
     * @type {string}
     * @memberof CreateOrUpdateInsuranceProviderParams
     */
    'status'?: CreateOrUpdateInsuranceProviderParamsStatusEnum;
}

export const CreateOrUpdateInsuranceProviderParamsStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted',
    UNDER_REVIEW: 'under_review'
} as const;

export type CreateOrUpdateInsuranceProviderParamsStatusEnum = typeof CreateOrUpdateInsuranceProviderParamsStatusEnum[keyof typeof CreateOrUpdateInsuranceProviderParamsStatusEnum];


