/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateSubscriptionParams } from '../model';
// @ts-ignore
import { DiscountCodeValidationResult } from '../model';
// @ts-ignore
import { PageableSubscriptionList } from '../model';
// @ts-ignore
import { Subscription } from '../model';
// @ts-ignore
import { ValidationResult } from '../model';
/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel affiliate tracking for the given subscription. This can only be done for subscriptions                      belonging to the logged in users mandator domain.
         * @param {number} id ID of the subscription to cancel affiliate tracking for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAffiliateTracking: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelAffiliateTracking', 'id', id)
            const localVarPath = `/v1/subscriptions/{id}/affiliateTracking`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new subscription. Add affiliate tracking if a tracking code is present and the mandator                      supports it. Also send out a notification email in case the subscription is in status \'IN_PROGRESS\'.<br />                      <b>A note on creating cashback subscriptions:</b><br />                      when creating a cashback subscription, at least the following parameters should be given to allow                      for correct initialization and validation:<br>                      - type (to indicate this is cashback)<br>                      - bic (may trigger generation of account identifier for DAB)<br>                      - customData.isNewDepot<br>                       
         * @param {CreateOrUpdateSubscriptionParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (body: CreateOrUpdateSubscriptionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createSubscription', 'body', body)
            const localVarPath = `/v1/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given subscription. A two factor authentication is NOT required for a short time after                      user registration in case the user did not confirm his email address yet. This is a small                      concession to usability, allowing a smooth transition to the registration process.
         * @param {number} id ID of the subscription to retrieve.
         * @param {string} [format] Retrieve the subscription in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscription: async (id: number, format?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubscription', 'id', id)
            const localVarPath = `/v1/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search subscriptions.
         * @param {Array<number>} [id] IDs of the subscriptions to retrieve.
         * @param {Array<number>} [userId] Retrieve subscriptions for the given users; this requires \&#39;ROLE_SUBSCRIPTION_MANAGEMENT\&#39;                              privileges.
         * @param {Array<string>} [type] Filter subscriptions by the given types; default is not filter. Allowed values are                              02_basic_flatrate, 03_fund_flatrate.
         * @param {Array<ListAndSearchAllSubscriptionsStatusEnum>} [status] Filter subscriptions by the given status; default is no filter.
         * @param {boolean} [isActive] Only retrieve active / in-active subscriptions; default is no filter. Note that an in-active                              subscription does not equal a cancelled subscription. A subscription may become in-active                              for various reasons; typically the subscription period ended.
         * @param {boolean} [isCancelled] Only retrieve cancelled / not cancelled subscriptions; default is no filter. Not that an                              in-active subscription does not equal a cancelled subscription. A subscription may become                              in-active for various reasons; typically the subscription period ended. Cancellation                              requires an active cancellation by the user or an administrator.
         * @param {boolean} [isCurrent] Only retrieve the current / non-current subscriptions; default is no filter. A current                              subscription is defined as the latest subscription in a row of extended subscriptions.
         * @param {boolean} [isTrial] Only retrieve trial / non-trial subscriptions.
         * @param {string} [maxStart] Retrieve subscriptions with a start date &gt;&#x3D; maxStart; format is yyyy-MM-dd (US Format)
         * @param {string} [minStart] Retrieve subscriptions with a start date &lt;&#x3D; minStart; format is yyyy-MM-dd (US Format)
         * @param {string} [maxEnd] Retrieve subscriptions with an end date &gt;&#x3D; maxEnd; format is yyyy-MM-dd (US Format)
         * @param {string} [minEnd] Retrieve subscriptions with an end date &lt;&#x3D; minEnd; format is yyyy-MM-dd (US Format)
         * @param {string} [maxCreationTimestamp] Retrieve subscriptions with a creation timestamp &gt;&#x3D; maxCreationTimestamp; format is yyyy-MM-dd (US Format)
         * @param {string} [minCreationTimestamp] Retrieve subscriptions with a creation timestamp &lt;&#x3D; minCreationTimestamp; format is yyyy-MM-dd (US Format)
         * @param {boolean} [hasAffiliateTrackingCode] Only retrieve subscriptions having a tracking ID; default is any. Setting this value to                              false is considered equal to \&#39;any\&#39;. This option is only available to subscription                              management administrators.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllSubscriptions: async (id?: Array<number>, userId?: Array<number>, type?: Array<string>, status?: Array<ListAndSearchAllSubscriptionsStatusEnum>, isActive?: boolean, isCancelled?: boolean, isCurrent?: boolean, isTrial?: boolean, maxStart?: string, minStart?: string, maxEnd?: string, minEnd?: string, maxCreationTimestamp?: string, minCreationTimestamp?: string, hasAffiliateTrackingCode?: boolean, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (isCancelled !== undefined) {
                localVarQueryParameter['isCancelled'] = isCancelled;
            }

            if (isCurrent !== undefined) {
                localVarQueryParameter['isCurrent'] = isCurrent;
            }

            if (isTrial !== undefined) {
                localVarQueryParameter['isTrial'] = isTrial;
            }

            if (maxStart !== undefined) {
                localVarQueryParameter['maxStart'] = maxStart;
            }

            if (minStart !== undefined) {
                localVarQueryParameter['minStart'] = minStart;
            }

            if (maxEnd !== undefined) {
                localVarQueryParameter['maxEnd'] = maxEnd;
            }

            if (minEnd !== undefined) {
                localVarQueryParameter['minEnd'] = minEnd;
            }

            if (maxCreationTimestamp !== undefined) {
                localVarQueryParameter['maxCreationTimestamp'] = maxCreationTimestamp;
            }

            if (minCreationTimestamp !== undefined) {
                localVarQueryParameter['minCreationTimestamp'] = minCreationTimestamp;
            }

            if (hasAffiliateTrackingCode !== undefined) {
                localVarQueryParameter['hasAffiliateTrackingCode'] = hasAffiliateTrackingCode;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a subscription. Send out a notification email in case the subscription does transition from                      a lower status into status \'IN_PROGRESS\'. This endpoint does not require email validation to                      support an interactive booking process also for non-logged in users. As a result of this reduced                      security requirements, account identifiers in the return object will be masked with an \'XXX\' for                      normal users. This limitation does not apply to subscription managers.
         * @param {number} id ID of the subscription to update.
         * @param {CreateOrUpdateSubscriptionParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (id: number, body: CreateOrUpdateSubscriptionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscription', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateSubscription', 'body', body)
            const localVarPath = `/v1/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate the given discount code. Return OK if valid; or Status 406 (NOT_ACCEPTABLE) if invalid.                      In the latter case the operation returns an ApiException containing the respective validation                      error message.
         * @param {string} discountCode The discount code to validate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDiscountCode: async (discountCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discountCode' is not null or undefined
            assertParamExists('validateDiscountCode', 'discountCode', discountCode)
            const localVarPath = `/v1/subscriptions/validateDiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (discountCode !== undefined) {
                localVarQueryParameter['discountCode'] = discountCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates the given primary account identifier (or customer ID for banks such as comdirect).                 The primary account identifier must not have been assigned to a subscription in the scope of the given bank (BIC).                 The match includes partials, so \'IDENTIFIER123\' and \'IDENTIFIER12\' would also be considered as already assigned.                 This covers many cases in which banks use base numbers.
         * @param {number} id The subscription the account identifier (or customer ID) should be assigned to.
         * @param {string} bic The bic we want to validate for. In case the subscription already has a bic, this value                         should match the subscriptions bic; however, the API will not enforce this.
         * @param {string} accountIdentifier The account identifier we would like to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePrimaryAccountIdentifier: async (id: number, bic: string, accountIdentifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validatePrimaryAccountIdentifier', 'id', id)
            // verify required parameter 'bic' is not null or undefined
            assertParamExists('validatePrimaryAccountIdentifier', 'bic', bic)
            // verify required parameter 'accountIdentifier' is not null or undefined
            assertParamExists('validatePrimaryAccountIdentifier', 'accountIdentifier', accountIdentifier)
            const localVarPath = `/v1/subscriptions/{id}/validatePrimaryAccountIdentifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bic !== undefined) {
                localVarQueryParameter['bic'] = bic;
            }

            if (accountIdentifier !== undefined) {
                localVarQueryParameter['accountIdentifier'] = accountIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel affiliate tracking for the given subscription. This can only be done for subscriptions                      belonging to the logged in users mandator domain.
         * @param {number} id ID of the subscription to cancel affiliate tracking for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAffiliateTracking(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAffiliateTracking(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.cancelAffiliateTracking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new subscription. Add affiliate tracking if a tracking code is present and the mandator                      supports it. Also send out a notification email in case the subscription is in status \'IN_PROGRESS\'.<br />                      <b>A note on creating cashback subscriptions:</b><br />                      when creating a cashback subscription, at least the following parameters should be given to allow                      for correct initialization and validation:<br>                      - type (to indicate this is cashback)<br>                      - bic (may trigger generation of account identifier for DAB)<br>                      - customData.isNewDepot<br>                       
         * @param {CreateOrUpdateSubscriptionParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(body: CreateOrUpdateSubscriptionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.createSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given subscription. A two factor authentication is NOT required for a short time after                      user registration in case the user did not confirm his email address yet. This is a small                      concession to usability, allowing a smooth transition to the registration process.
         * @param {number} id ID of the subscription to retrieve.
         * @param {string} [format] Retrieve the subscription in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscription(id: number, format?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscription(id, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.getSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search subscriptions.
         * @param {Array<number>} [id] IDs of the subscriptions to retrieve.
         * @param {Array<number>} [userId] Retrieve subscriptions for the given users; this requires \&#39;ROLE_SUBSCRIPTION_MANAGEMENT\&#39;                              privileges.
         * @param {Array<string>} [type] Filter subscriptions by the given types; default is not filter. Allowed values are                              02_basic_flatrate, 03_fund_flatrate.
         * @param {Array<ListAndSearchAllSubscriptionsStatusEnum>} [status] Filter subscriptions by the given status; default is no filter.
         * @param {boolean} [isActive] Only retrieve active / in-active subscriptions; default is no filter. Note that an in-active                              subscription does not equal a cancelled subscription. A subscription may become in-active                              for various reasons; typically the subscription period ended.
         * @param {boolean} [isCancelled] Only retrieve cancelled / not cancelled subscriptions; default is no filter. Not that an                              in-active subscription does not equal a cancelled subscription. A subscription may become                              in-active for various reasons; typically the subscription period ended. Cancellation                              requires an active cancellation by the user or an administrator.
         * @param {boolean} [isCurrent] Only retrieve the current / non-current subscriptions; default is no filter. A current                              subscription is defined as the latest subscription in a row of extended subscriptions.
         * @param {boolean} [isTrial] Only retrieve trial / non-trial subscriptions.
         * @param {string} [maxStart] Retrieve subscriptions with a start date &gt;&#x3D; maxStart; format is yyyy-MM-dd (US Format)
         * @param {string} [minStart] Retrieve subscriptions with a start date &lt;&#x3D; minStart; format is yyyy-MM-dd (US Format)
         * @param {string} [maxEnd] Retrieve subscriptions with an end date &gt;&#x3D; maxEnd; format is yyyy-MM-dd (US Format)
         * @param {string} [minEnd] Retrieve subscriptions with an end date &lt;&#x3D; minEnd; format is yyyy-MM-dd (US Format)
         * @param {string} [maxCreationTimestamp] Retrieve subscriptions with a creation timestamp &gt;&#x3D; maxCreationTimestamp; format is yyyy-MM-dd (US Format)
         * @param {string} [minCreationTimestamp] Retrieve subscriptions with a creation timestamp &lt;&#x3D; minCreationTimestamp; format is yyyy-MM-dd (US Format)
         * @param {boolean} [hasAffiliateTrackingCode] Only retrieve subscriptions having a tracking ID; default is any. Setting this value to                              false is considered equal to \&#39;any\&#39;. This option is only available to subscription                              management administrators.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllSubscriptions(id?: Array<number>, userId?: Array<number>, type?: Array<string>, status?: Array<ListAndSearchAllSubscriptionsStatusEnum>, isActive?: boolean, isCancelled?: boolean, isCurrent?: boolean, isTrial?: boolean, maxStart?: string, minStart?: string, maxEnd?: string, minEnd?: string, maxCreationTimestamp?: string, minCreationTimestamp?: string, hasAffiliateTrackingCode?: boolean, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableSubscriptionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllSubscriptions(id, userId, type, status, isActive, isCancelled, isCurrent, isTrial, maxStart, minStart, maxEnd, minEnd, maxCreationTimestamp, minCreationTimestamp, hasAffiliateTrackingCode, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.listAndSearchAllSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a subscription. Send out a notification email in case the subscription does transition from                      a lower status into status \'IN_PROGRESS\'. This endpoint does not require email validation to                      support an interactive booking process also for non-logged in users. As a result of this reduced                      security requirements, account identifiers in the return object will be masked with an \'XXX\' for                      normal users. This limitation does not apply to subscription managers.
         * @param {number} id ID of the subscription to update.
         * @param {CreateOrUpdateSubscriptionParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(id: number, body: CreateOrUpdateSubscriptionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.updateSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate the given discount code. Return OK if valid; or Status 406 (NOT_ACCEPTABLE) if invalid.                      In the latter case the operation returns an ApiException containing the respective validation                      error message.
         * @param {string} discountCode The discount code to validate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateDiscountCode(discountCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateDiscountCode(discountCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.validateDiscountCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validates the given primary account identifier (or customer ID for banks such as comdirect).                 The primary account identifier must not have been assigned to a subscription in the scope of the given bank (BIC).                 The match includes partials, so \'IDENTIFIER123\' and \'IDENTIFIER12\' would also be considered as already assigned.                 This covers many cases in which banks use base numbers.
         * @param {number} id The subscription the account identifier (or customer ID) should be assigned to.
         * @param {string} bic The bic we want to validate for. In case the subscription already has a bic, this value                         should match the subscriptions bic; however, the API will not enforce this.
         * @param {string} accountIdentifier The account identifier we would like to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePrimaryAccountIdentifier(id: number, bic: string, accountIdentifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePrimaryAccountIdentifier(id, bic, accountIdentifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.validatePrimaryAccountIdentifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel affiliate tracking for the given subscription. This can only be done for subscriptions                      belonging to the logged in users mandator domain.
         * @param {SubscriptionsApiCancelAffiliateTrackingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAffiliateTracking(requestParameters: SubscriptionsApiCancelAffiliateTrackingRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelAffiliateTracking(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new subscription. Add affiliate tracking if a tracking code is present and the mandator                      supports it. Also send out a notification email in case the subscription is in status \'IN_PROGRESS\'.<br />                      <b>A note on creating cashback subscriptions:</b><br />                      when creating a cashback subscription, at least the following parameters should be given to allow                      for correct initialization and validation:<br>                      - type (to indicate this is cashback)<br>                      - bic (may trigger generation of account identifier for DAB)<br>                      - customData.isNewDepot<br>                       
         * @param {SubscriptionsApiCreateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(requestParameters: SubscriptionsApiCreateSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subscription> {
            return localVarFp.createSubscription(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given subscription. A two factor authentication is NOT required for a short time after                      user registration in case the user did not confirm his email address yet. This is a small                      concession to usability, allowing a smooth transition to the registration process.
         * @param {SubscriptionsApiGetSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscription(requestParameters: SubscriptionsApiGetSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subscription> {
            return localVarFp.getSubscription(requestParameters.id, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search subscriptions.
         * @param {SubscriptionsApiListAndSearchAllSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllSubscriptions(requestParameters: SubscriptionsApiListAndSearchAllSubscriptionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableSubscriptionList> {
            return localVarFp.listAndSearchAllSubscriptions(requestParameters.id, requestParameters.userId, requestParameters.type, requestParameters.status, requestParameters.isActive, requestParameters.isCancelled, requestParameters.isCurrent, requestParameters.isTrial, requestParameters.maxStart, requestParameters.minStart, requestParameters.maxEnd, requestParameters.minEnd, requestParameters.maxCreationTimestamp, requestParameters.minCreationTimestamp, requestParameters.hasAffiliateTrackingCode, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a subscription. Send out a notification email in case the subscription does transition from                      a lower status into status \'IN_PROGRESS\'. This endpoint does not require email validation to                      support an interactive booking process also for non-logged in users. As a result of this reduced                      security requirements, account identifiers in the return object will be masked with an \'XXX\' for                      normal users. This limitation does not apply to subscription managers.
         * @param {SubscriptionsApiUpdateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(requestParameters: SubscriptionsApiUpdateSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subscription> {
            return localVarFp.updateSubscription(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate the given discount code. Return OK if valid; or Status 406 (NOT_ACCEPTABLE) if invalid.                      In the latter case the operation returns an ApiException containing the respective validation                      error message.
         * @param {SubscriptionsApiValidateDiscountCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDiscountCode(requestParameters: SubscriptionsApiValidateDiscountCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<DiscountCodeValidationResult> {
            return localVarFp.validateDiscountCode(requestParameters.discountCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates the given primary account identifier (or customer ID for banks such as comdirect).                 The primary account identifier must not have been assigned to a subscription in the scope of the given bank (BIC).                 The match includes partials, so \'IDENTIFIER123\' and \'IDENTIFIER12\' would also be considered as already assigned.                 This covers many cases in which banks use base numbers.
         * @param {SubscriptionsApiValidatePrimaryAccountIdentifierRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePrimaryAccountIdentifier(requestParameters: SubscriptionsApiValidatePrimaryAccountIdentifierRequest, options?: RawAxiosRequestConfig): AxiosPromise<ValidationResult> {
            return localVarFp.validatePrimaryAccountIdentifier(requestParameters.id, requestParameters.bic, requestParameters.accountIdentifier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelAffiliateTracking operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiCancelAffiliateTrackingRequest
 */
export interface SubscriptionsApiCancelAffiliateTrackingRequest {
    /**
     * ID of the subscription to cancel affiliate tracking for.
     * @type {number}
     * @memberof SubscriptionsApiCancelAffiliateTracking
     */
    readonly id: number
}

/**
 * Request parameters for createSubscription operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiCreateSubscriptionRequest
 */
export interface SubscriptionsApiCreateSubscriptionRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateSubscriptionParams}
     * @memberof SubscriptionsApiCreateSubscription
     */
    readonly body: CreateOrUpdateSubscriptionParams
}

/**
 * Request parameters for getSubscription operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiGetSubscriptionRequest
 */
export interface SubscriptionsApiGetSubscriptionRequest {
    /**
     * ID of the subscription to retrieve.
     * @type {number}
     * @memberof SubscriptionsApiGetSubscription
     */
    readonly id: number

    /**
     * Retrieve the subscription in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
     * @type {string}
     * @memberof SubscriptionsApiGetSubscription
     */
    readonly format?: string
}

/**
 * Request parameters for listAndSearchAllSubscriptions operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiListAndSearchAllSubscriptionsRequest
 */
export interface SubscriptionsApiListAndSearchAllSubscriptionsRequest {
    /**
     * IDs of the subscriptions to retrieve.
     * @type {Array<number>}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly id?: Array<number>

    /**
     * Retrieve subscriptions for the given users; this requires \&#39;ROLE_SUBSCRIPTION_MANAGEMENT\&#39;                              privileges.
     * @type {Array<number>}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly userId?: Array<number>

    /**
     * Filter subscriptions by the given types; default is not filter. Allowed values are                              02_basic_flatrate, 03_fund_flatrate.
     * @type {Array<string>}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly type?: Array<string>

    /**
     * Filter subscriptions by the given status; default is no filter.
     * @type {Array<'incomplete' | 'in_progress' | 'internal_processing' | 'processing_at_bank' | 'processed' | 'contract_terminated' | 'cancelled'>}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly status?: Array<ListAndSearchAllSubscriptionsStatusEnum>

    /**
     * Only retrieve active / in-active subscriptions; default is no filter. Note that an in-active                              subscription does not equal a cancelled subscription. A subscription may become in-active                              for various reasons; typically the subscription period ended.
     * @type {boolean}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly isActive?: boolean

    /**
     * Only retrieve cancelled / not cancelled subscriptions; default is no filter. Not that an                              in-active subscription does not equal a cancelled subscription. A subscription may become                              in-active for various reasons; typically the subscription period ended. Cancellation                              requires an active cancellation by the user or an administrator.
     * @type {boolean}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly isCancelled?: boolean

    /**
     * Only retrieve the current / non-current subscriptions; default is no filter. A current                              subscription is defined as the latest subscription in a row of extended subscriptions.
     * @type {boolean}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly isCurrent?: boolean

    /**
     * Only retrieve trial / non-trial subscriptions.
     * @type {boolean}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly isTrial?: boolean

    /**
     * Retrieve subscriptions with a start date &gt;&#x3D; maxStart; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly maxStart?: string

    /**
     * Retrieve subscriptions with a start date &lt;&#x3D; minStart; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly minStart?: string

    /**
     * Retrieve subscriptions with an end date &gt;&#x3D; maxEnd; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly maxEnd?: string

    /**
     * Retrieve subscriptions with an end date &lt;&#x3D; minEnd; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly minEnd?: string

    /**
     * Retrieve subscriptions with a creation timestamp &gt;&#x3D; maxCreationTimestamp; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly maxCreationTimestamp?: string

    /**
     * Retrieve subscriptions with a creation timestamp &lt;&#x3D; minCreationTimestamp; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly minCreationTimestamp?: string

    /**
     * Only retrieve subscriptions having a tracking ID; default is any. Setting this value to                              false is considered equal to \&#39;any\&#39;. This option is only available to subscription                              management administrators.
     * @type {boolean}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly hasAffiliateTrackingCode?: boolean

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof SubscriptionsApiListAndSearchAllSubscriptions
     */
    readonly order?: string
}

/**
 * Request parameters for updateSubscription operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiUpdateSubscriptionRequest
 */
export interface SubscriptionsApiUpdateSubscriptionRequest {
    /**
     * ID of the subscription to update.
     * @type {number}
     * @memberof SubscriptionsApiUpdateSubscription
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateSubscriptionParams}
     * @memberof SubscriptionsApiUpdateSubscription
     */
    readonly body: CreateOrUpdateSubscriptionParams
}

/**
 * Request parameters for validateDiscountCode operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiValidateDiscountCodeRequest
 */
export interface SubscriptionsApiValidateDiscountCodeRequest {
    /**
     * The discount code to validate.
     * @type {string}
     * @memberof SubscriptionsApiValidateDiscountCode
     */
    readonly discountCode: string
}

/**
 * Request parameters for validatePrimaryAccountIdentifier operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiValidatePrimaryAccountIdentifierRequest
 */
export interface SubscriptionsApiValidatePrimaryAccountIdentifierRequest {
    /**
     * The subscription the account identifier (or customer ID) should be assigned to.
     * @type {number}
     * @memberof SubscriptionsApiValidatePrimaryAccountIdentifier
     */
    readonly id: number

    /**
     * The bic we want to validate for. In case the subscription already has a bic, this value                         should match the subscriptions bic; however, the API will not enforce this.
     * @type {string}
     * @memberof SubscriptionsApiValidatePrimaryAccountIdentifier
     */
    readonly bic: string

    /**
     * The account identifier we would like to check
     * @type {string}
     * @memberof SubscriptionsApiValidatePrimaryAccountIdentifier
     */
    readonly accountIdentifier: string
}

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel affiliate tracking for the given subscription. This can only be done for subscriptions                      belonging to the logged in users mandator domain.
     * @param {SubscriptionsApiCancelAffiliateTrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public cancelAffiliateTracking(requestParameters: SubscriptionsApiCancelAffiliateTrackingRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).cancelAffiliateTracking(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new subscription. Add affiliate tracking if a tracking code is present and the mandator                      supports it. Also send out a notification email in case the subscription is in status \'IN_PROGRESS\'.<br />                      <b>A note on creating cashback subscriptions:</b><br />                      when creating a cashback subscription, at least the following parameters should be given to allow                      for correct initialization and validation:<br>                      - type (to indicate this is cashback)<br>                      - bic (may trigger generation of account identifier for DAB)<br>                      - customData.isNewDepot<br>                       
     * @param {SubscriptionsApiCreateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public createSubscription(requestParameters: SubscriptionsApiCreateSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).createSubscription(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given subscription. A two factor authentication is NOT required for a short time after                      user registration in case the user did not confirm his email address yet. This is a small                      concession to usability, allowing a smooth transition to the registration process.
     * @param {SubscriptionsApiGetSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getSubscription(requestParameters: SubscriptionsApiGetSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getSubscription(requestParameters.id, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search subscriptions.
     * @param {SubscriptionsApiListAndSearchAllSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public listAndSearchAllSubscriptions(requestParameters: SubscriptionsApiListAndSearchAllSubscriptionsRequest = {}, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).listAndSearchAllSubscriptions(requestParameters.id, requestParameters.userId, requestParameters.type, requestParameters.status, requestParameters.isActive, requestParameters.isCancelled, requestParameters.isCurrent, requestParameters.isTrial, requestParameters.maxStart, requestParameters.minStart, requestParameters.maxEnd, requestParameters.minEnd, requestParameters.maxCreationTimestamp, requestParameters.minCreationTimestamp, requestParameters.hasAffiliateTrackingCode, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a subscription. Send out a notification email in case the subscription does transition from                      a lower status into status \'IN_PROGRESS\'. This endpoint does not require email validation to                      support an interactive booking process also for non-logged in users. As a result of this reduced                      security requirements, account identifiers in the return object will be masked with an \'XXX\' for                      normal users. This limitation does not apply to subscription managers.
     * @param {SubscriptionsApiUpdateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public updateSubscription(requestParameters: SubscriptionsApiUpdateSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).updateSubscription(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate the given discount code. Return OK if valid; or Status 406 (NOT_ACCEPTABLE) if invalid.                      In the latter case the operation returns an ApiException containing the respective validation                      error message.
     * @param {SubscriptionsApiValidateDiscountCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public validateDiscountCode(requestParameters: SubscriptionsApiValidateDiscountCodeRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).validateDiscountCode(requestParameters.discountCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates the given primary account identifier (or customer ID for banks such as comdirect).                 The primary account identifier must not have been assigned to a subscription in the scope of the given bank (BIC).                 The match includes partials, so \'IDENTIFIER123\' and \'IDENTIFIER12\' would also be considered as already assigned.                 This covers many cases in which banks use base numbers.
     * @param {SubscriptionsApiValidatePrimaryAccountIdentifierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public validatePrimaryAccountIdentifier(requestParameters: SubscriptionsApiValidatePrimaryAccountIdentifierRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).validatePrimaryAccountIdentifier(requestParameters.id, requestParameters.bic, requestParameters.accountIdentifier, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllSubscriptionsStatusEnum = {
    INCOMPLETE: 'incomplete',
    IN_PROGRESS: 'in_progress',
    INTERNAL_PROCESSING: 'internal_processing',
    PROCESSING_AT_BANK: 'processing_at_bank',
    PROCESSED: 'processed',
    CONTRACT_TERMINATED: 'contract_terminated',
    CANCELLED: 'cancelled'
} as const;
export type ListAndSearchAllSubscriptionsStatusEnum = typeof ListAndSearchAllSubscriptionsStatusEnum[keyof typeof ListAndSearchAllSubscriptionsStatusEnum];
