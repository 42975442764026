import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, MenuItem } from "@mui/material";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

const MenuItemContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

interface InvestmentMenuProps {
  investmentId: number;
  handleOpenDeleteModal: () => void;
}

export const InvestmentMenu = React.forwardRef<
  HTMLDivElement,
  InvestmentMenuProps
>(({ investmentId, handleOpenDeleteModal }, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref}>
      <MenuItem
        data-testid="investment-edit-menu-item"
        component={RouterLink}
        to={`/investments/${investmentId}/edit`}
      >
        <MenuItemContent>
          <EditIcon />
          {t("investment.header.actions.editInvestmentButton")}
        </MenuItemContent>
      </MenuItem>
      <MenuItem onClick={handleOpenDeleteModal}>
        <MenuItemContent color="error.main">
          <DeleteIcon />
          {t("investment.header.actions.deleteInvestmentButton")}
        </MenuItemContent>
      </MenuItem>
    </div>
  );
});
