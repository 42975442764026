import { UseQueryOptions } from "react-query";

import { buildDiscountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const recommendationCode = async (token: string | undefined) => {
  const apiInstance = buildDiscountsApi(token);
  const { data } = await apiInstance.recommendationCode();
  return data;
};

export const useRecommendationCode = (
  options?: UseQueryOptions<string, AxiosApiError, string>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DISCOUNTS_RECOMMENDATION_CODE],
    ({ token }) => recommendationCode(token),
    options
  );
};
