import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { ReactComponent as TagPlus } from "@icons/tag_plus.svg";
import { Stripe } from "@generated/user";
import { toCurrency } from "@helpers";
import { Switcher } from "@components";
import { PLUS_PRICE, PLUS_PRICE_ANNUAL } from "@constants";
import { freeBenefits, plusBenefits } from "../constants";
import { SubscriptionBenefit } from "./SubscriptionBenefit";

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  padding: ${({ theme }) => theme.spacing(8)};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  height: 100%;
  max-width: ${({ isMobile }) => (isMobile ? "unset" : "384px")};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  flex: 1;
`;

const HorizontalLine = styled(Box)`
  margin: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[500]};
`;

export const SubscriptionComparison = ({
  stripeData,
  showInOneColumn,
}: {
  stripeData?: Stripe;
  showInOneColumn: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isAnnual, setIsAnnual] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const plusPriceNumber = isAnnual ? PLUS_PRICE_ANNUAL : PLUS_PRICE;

  return (
    <Box
      display="flex"
      flexDirection={showInOneColumn ? "column" : "row"}
      gap={theme.spacing(showInOneColumn ? 6 : 8)}
      marginTop={theme.spacing(4)}
      marginBottom={theme.spacing(8)}
    >
      <StyledBox isMobile={isMobile}>
        <Typography
          variant="h6"
          fontSize="22px"
          lineHeight="30px"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {t("settingsPage.subscription.freeOption.title")}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          mb={6.5}
          letterSpacing={-0.3}
        >
          {t("settingsPage.subscription.freeOption.subtitle")}
        </Typography>
        <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
          {freeBenefits.map((benefit, index) => (
            <SubscriptionBenefit
              key={`freeBenefits-${benefit}`}
              text={t(benefit)}
              showBankIcons={index === 0}
            />
          ))}
        </Box>
        <HorizontalLine />
        <Box display="flex" alignItems="flex-end">
          <Typography variant="h3" fontWeight={600} pr={theme.spacing(2)}>
            {toCurrency(0, "EUR", { minimumFractionDigits: 0 })}
          </Typography>
          <Typography variant="body1" fontWeight={400} color="textSecondary">
            {"/ "} {t("settingsPage.subscription.monthly")}
          </Typography>
        </Box>
      </StyledBox>
      <StyledBox isMobile={isMobile}>
        <TagPlus style={{ marginBottom: theme.spacing(0.5) }} />
        <Typography
          variant="body1"
          fontWeight={400}
          mb={8}
          letterSpacing={-0.3}
        >
          {t("settingsPage.subscription.plusOption.subtitle")}
        </Typography>
        <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
          {plusBenefits.map((benefit, index) => (
            <SubscriptionBenefit
              key={`plusBenefits-${benefit}`}
              text={t(benefit)}
              showBankIcons={index === 0}
            />
          ))}
        </Box>
        <HorizontalLine />
        <Box display="flex" alignItems="flex-end">
          <Typography variant="h3" fontWeight={600} pr={theme.spacing(2)}>
            {toCurrency(plusPriceNumber, "EUR")}
          </Typography>
          <Typography variant="body1" fontWeight={400} color="textSecondary">
            {"/ "} {t("settingsPage.subscription.monthly")}
          </Typography>
        </Box>
        {isAnnual && (
          <Typography
            variant="body1"
            fontWeight={400}
            mt={theme.spacing(2)}
            color="textSecondary"
          >
            <Trans i18nKey="settingsPage.subscription.annualSave" />
          </Typography>
        )}
        <Box ml={theme.spacing(2)} mt={theme.spacing(6)}>
          <Switcher
            size="medium"
            checked={isAnnual}
            onChange={(e) => setIsAnnual(e.target.checked)}
            label="settingsPage.subscription.annualPayment"
            switchName="is-annual-billing-switch"
          />
        </Box>
        {stripeData && (
          <Button
            sx={{ width: "100%", margin: "24px 0 16px" }}
            variant="contained"
            color="primary"
            href={
              isAnnual
                ? stripeData.copilotPlusYearlySubscriptionBuyLink
                : stripeData.copilotPlusMonthlySubscriptionBuyLink
            }
          >
            {t("settingsPage.subscription.agree")}
          </Button>
        )}
        <Typography
          variant="caption"
          lineHeight="16px"
          fontWeight={400}
          mt={theme.spacing(2)}
          letterSpacing={-0.2}
          color="textSecondary"
        >
          <Trans i18nKey="settingsPage.subscription.cancelation" />
        </Typography>
      </StyledBox>
    </Box>
  );
};
