/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
    /**
     * The users email address / username. For server-to-server use cases, this may also             be a random string / hash key.
     * @type {string}
     * @memberof CreateUserParams
     */
    'email': string;
    /**
     * The users password (in plain text)
     * @type {string}
     * @memberof CreateUserParams
     */
    'password': string;
    /**
     * Did the user accept your terms and privacy agreement?
     * @type {boolean}
     * @memberof CreateUserParams
     */
    'acceptedAgb': boolean;
    /**
     * Does the user want to receive a newsletter; defaults to false
     * @type {boolean}
     * @memberof CreateUserParams
     */
    'receiveNewsletter'?: boolean;
    /**
     * The users gender (1 = male, 2 = female); will default to male if not given
     * @type {number}
     * @memberof CreateUserParams
     */
    'gender'?: CreateUserParamsGenderEnum;
    /**
     * The users first name; will default to \'undefined\' if not given
     * @type {string}
     * @memberof CreateUserParams
     */
    'firstName'?: string;
    /**
     * The users last name; will default to \'undefined\' if not given
     * @type {string}
     * @memberof CreateUserParams
     */
    'lastName'?: string;
    /**
     * The users phone number
     * @type {string}
     * @memberof CreateUserParams
     */
    'phoneNumber'?: string;
    /**
     * An optional recovery phone number; currently not implemented.
     * @type {string}
     * @memberof CreateUserParams
     */
    'recoveryPhoneNumber'?: string;
    /**
     * The mandator under which the user is registering. Note that we require the              mandator public ID here (something like yourCompanyName) and not the mandator client ID. The reason for              this is that we also want to support use cases where signups are done directly via our API. In this              case we do not want to expose the mandator client ID via the frontend request.
     * @type {string}
     * @memberof CreateUserParams
     */
    'mandatorPublicId': string;
    /**
     * The mandators client secret, required for user signup for all server-to-server             use cases. The signup operation will ensure that mandatorPublicId and mandator secret match
     * @type {string}
     * @memberof CreateUserParams
     */
    'mandatorClientSecret'?: string;
}

export const CreateUserParamsGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CreateUserParamsGenderEnum = typeof CreateUserParamsGenderEnum[keyof typeof CreateUserParamsGenderEnum];


