import { assign, createMachine } from "xstate";
import { initFromInvestmentTypeAction } from "@features/transactionWizard/modal/stateMachine/actions/initFromInvestmentTypeAction";
import { updateSplitValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/updateSplitValuesAction";
import { setBookingsAction } from "@features/transactionWizard/modal/stateMachine/actions/setBookingsAction";
import { clearInvestmentValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/clearInvestmentValuesAction";
import { setSourceInvestmentValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/setSourceInvestmentValuesAction";
import { clearBookingTypeAction } from "@features/transactionWizard/modal/stateMachine/actions/clearBookingTypeAction";
import { setInvestmentValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/setInvestmentValuesAction";
import { setManualInvestmentAction } from "@features/transactionWizard/modal/stateMachine/actions/setManualInvestmentAction";
import { clearManualInvestmentAction } from "@features/transactionWizard/modal/stateMachine/actions/clearManualInvestmentAction";
import { clearSourceInvestmentValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/clearSourceInvestmentValuesAction";
import { clearNameAndDepotAction } from "@features/transactionWizard/modal/stateMachine/actions/clearNameAndDepotAction";
import { validateBooking } from "@features/transactionWizard/modal/stateMachine/services/validateBooking";
import { persistBooking } from "@features/transactionWizard/modal/stateMachine/services/persistBooking";
import { clearBookingValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/clearBookingValuesAction";
import { clearSymbolAction } from "@features/transactionWizard/modal/stateMachine/actions/clearSymbolAction";
import { setManualFlowAction } from "@features/transactionWizard/modal/stateMachine/actions/setManualFlowAction";
import { clearManualFlowAction } from "@features/transactionWizard/modal/stateMachine/actions/clearManualFlowAction";
import { clearSplitValuesAction } from "@features/transactionWizard/modal/stateMachine/actions/clearSplitValuesAction";
import { loadInvestment } from "@features/transactionWizard/modal/stateMachine/services/loadInvestment";
import { addAxiosErrorToContextAction } from "../stateMachine/actions/addAxiosErrorToContextAction";
import { addBookingValuesAction } from "../stateMachine/actions/addBookingValuesAction";
import { addValidationErrorAction } from "../stateMachine/actions/addValidationErrorAction";
import { clearValidationErrorAction } from "../stateMachine/actions/clearValidationErrorAction";
import { setBookingTypeAction } from "../stateMachine/actions/setBookingTypeAction";
import { setNameAndDepotAction } from "../stateMachine/actions/setNameAndDepotAction";
import { setSymbolAction } from "../stateMachine/actions/setSymbolAction";
import { TransactionWizardState } from "../stateMachine/types/transactionWizardState";
import {
  defaultContext,
  TransactionWizardContext,
} from "../stateMachine/types/transactionWizardContext";
import { TransactionWizardEvent } from "../stateMachine/types/TransactionWizardEvent";
import { loadBookings } from "../stateMachine/services/loadBookings";
import { persistSplit } from "../stateMachine/services/persistSplit";

export const newTransactionWizardModalSM = createMachine<
  TransactionWizardContext,
  TransactionWizardEvent,
  TransactionWizardState
>(
  {
    id: "newTransactionWizardSM",

    // Initial state
    initial: "idle",

    // Local context for entire machine
    context: defaultContext,

    // State definitions
    states: {
      idle: {
        on: {
          INIT: {
            target: "searchSymbol",
          },
          INIT_FROM_INVESTMENT: { target: "loadInvestment" },
          INIT_FROM_MANUAL: {
            target: "selectBookingType",
            actions: ["setManualFlowAction", "setInvestmentValuesAction"],
          },
          INIT_FROM_NON_LISTED: {
            target: "selectBookingType",
            actions: ["setInvestmentValuesAction"],
          },
          INIT_FROM_SYMBOL: {
            target: "depots",
            actions: ["setSymbolAction"],
          },
        },
      },
      loadInvestment: {
        id: "loadInvestment",
        invoke: {
          id: "loadInvestment",
          src: loadInvestment,
        },
        on: {
          INVESTMENT_INITIALIZED: {
            target: "selectBookingType",
            actions: ["setSymbolAction", "setInvestmentValuesAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      searchSymbol: {
        on: {
          INIT_FROM_SYMBOL: { target: "depots" },
          NON_LISTED_SELECTED: {
            target: "selectBookingType",
            actions: ["setInvestmentValuesAction"],
          },
          MANUAL_SELECTED: {
            target: "selectBookingType",
            actions: ["setManualFlowAction", "setInvestmentValuesAction"],
          },
        },
        exit: ["setSymbolAction"],
      },
      depots: {
        id: "depots",
        on: {
          NAME_AND_DEPOT_SELECTED: [
            {
              target: "split",
              cond: "isManualOrNonListedAndIsSplit",
              actions: ["setSymbolAction", "setNameAndDepotAction"],
            },
            {
              target: "targetInvestment",
              cond: "isManualOrNonListedAndIsExchange",
              actions: [
                "setSymbolAction",
                "setNameAndDepotAction",
                "setSourceInvestmentValuesAction",
              ],
            },
            {
              target: "priceAndDate",
              cond: "isManualOrNonListed",
              actions: ["setSymbolAction", "setNameAndDepotAction"],
            },
            {
              target: "selectBookingType",
              actions: ["setNameAndDepotAction"],
            },
          ],
          BACK: [
            {
              target: "selectBookingType",
              cond: "isManualOrNonListed",
              actions: [
                "clearNameAndDepotAction",
                "clearSymbolAction",
                "clearInvestmentValuesAction",
              ],
            },
            {
              target: "searchSymbol",
              actions: [
                "clearNameAndDepotAction",
                "clearSymbolAction",
                "clearInvestmentValuesAction",
              ],
            },
          ],
        },
      },
      selectBookingType: {
        id: "selectBookingType",
        on: {
          BOOKING_TYPE_SELECTED: [
            {
              target: "depots",
              cond: "isManualOrNonListed",
              actions: ["setBookingTypeAction"],
            },
            {
              target: "split",
              cond: "isSplitSelected",
              actions: ["setBookingTypeAction"],
            },
            {
              target: "targetInvestment",
              cond: "isExchangeSelected",
              actions: [
                "setBookingTypeAction",
                "setSourceInvestmentValuesAction",
              ],
            },
            {
              target: "priceAndDate",
              actions: ["setBookingTypeAction"],
            },
          ],
          BACK: [
            {
              target: "searchSymbol",
              cond: "isManualOrNonListed",
              actions: ["clearManualFlowAction", "clearBookingTypeAction"],
            },
            {
              target: "depots",
              actions: ["clearBookingTypeAction"],
            },
          ],
        },
      },
      targetInvestment: {
        initial: "search",
        states: {
          search: {
            on: {
              INIT_FROM_SYMBOL: {
                target: "#priceAndDate",
                actions: ["setSymbolAction"],
              },
              NON_LISTED_SELECTED: {
                target: "manual",
              },
              MANUAL_SELECTED: {
                target: "manual",
                actions: ["setManualInvestmentAction"],
              },
              BACK: [
                {
                  target: "#depots",
                  cond: "isManualOrNonListed",
                  actions: [
                    "clearInvestmentValuesAction",
                    "clearSourceInvestmentValuesAction",
                  ],
                },
                {
                  target: "#selectBookingType",
                  actions: [
                    "clearInvestmentValuesAction",
                    "clearSourceInvestmentValuesAction",
                  ],
                },
              ],
            },
          },
          manual: {
            on: {
              INVESTMENT_SELECTED: [
                {
                  target: "#priceAndDate",
                  actions: ["setInvestmentValuesAction"],
                },
              ],
              BACK: {
                target: "search",
                actions: [
                  "clearManualInvestmentAction",
                  "clearInvestmentValuesAction",
                ],
              },
            },
          },
        },
      },
      split: {
        initial: "loadBookings",
        states: {
          loadBookings: {
            invoke: { id: "loadBookings", src: loadBookings },
            on: {
              BOOKINGS_LOADED: {
                target: "ratioAndDate",
                actions: ["setBookingsAction"],
              },
              SOMETHING_WRONG: { target: "#somethingWrong" },
            },
          },
          ratioAndDate: {
            on: {
              PREVIEW: {
                target: "preview",
                actions: ["updateSplitValuesAction"],
              },
              BACK: [
                {
                  target: "#depots",
                  cond: "isManualOrNonListed",
                  actions: ["clearSplitValuesAction"],
                },
                {
                  target: "#selectBookingType",
                  actions: ["clearSplitValuesAction"],
                },
              ],
            },
          },
          preview: {
            on: {
              PERSIST: { target: "persist" },
              BACK: { target: "ratioAndDate" },
            },
          },
          persist: {
            invoke: {
              id: "persistSplit",
              src: persistSplit,
            },
            on: {
              BOOKING_VALID: {
                target: "#persisted",
              },
              BOOKING_INVALID: {
                target: "ratioAndDate",
                actions: ["addValidationErrorAction"],
              },
              SOMETHING_WRONG: { target: "#somethingWrong" },
            },
          },
        },
      },
      priceAndDate: {
        id: "priceAndDate",
        on: {
          VALIDATE_BOOKING: {
            target: "validateBooking",
            actions: ["addBookingValuesAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
          BACK: [
            {
              target: "targetInvestment.manual",
              actions: ["clearBookingValuesAction"],
              cond: (context) => Boolean(context.manualInvestmentSelected),
            },
            {
              target: "targetInvestment.search",
              actions: ["clearBookingValuesAction"],
              cond: "isExchange",
            },
            {
              target: "depots",
              actions: ["clearBookingValuesAction"],
              cond: "isManualOrNonListed",
            },
            {
              target: "selectBookingType",
              actions: [
                "clearValidationErrorAction",
                "clearBookingValuesAction",
              ],
            },
          ],
        },
      },
      validateBooking: {
        invoke: {
          id: "validateBooking",
          src: validateBooking,
        },
        on: {
          BOOKING_VALID: [
            {
              target: "summary",
              actions: ["clearValidationErrorAction"],
            },
          ],
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      summary: {
        on: {
          PERSIST: { target: "persist" },
          BACK: { target: "priceAndDate" },
        },
      },
      persist: {
        invoke: {
          id: "persistBooking",
          src: persistBooking,
        },
        on: {
          BOOKING_VALID: {
            target: "persisted",
          },
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      persisted: {
        id: "persisted",
        type: "final",
      },
      somethingWrong: {
        type: "final",
        id: "somethingWrong",
        entry: ["addAxiosErrorToContextAction"],
      },
    },
  },
  {
    actions: {
      addAxiosErrorToContextAction: assign(addAxiosErrorToContextAction),
      addBookingValuesAction: assign(addBookingValuesAction),
      addValidationErrorAction: assign(addValidationErrorAction),
      clearBookingTypeAction: assign(clearBookingTypeAction),
      clearBookingValuesAction: assign(clearBookingValuesAction),
      clearInvestmentValuesAction: assign(clearInvestmentValuesAction),
      clearManualFlowAction: assign(clearManualFlowAction),
      clearManualInvestmentAction: assign(clearManualInvestmentAction),
      clearNameAndDepotAction: assign(clearNameAndDepotAction),
      clearSourceInvestmentValuesAction: assign(
        clearSourceInvestmentValuesAction
      ),
      clearSplitValuesAction: assign(clearSplitValuesAction),
      clearSymbolAction: assign(clearSymbolAction),
      clearValidationErrorAction: assign(clearValidationErrorAction),
      initFromInvestmentTypeAction: assign(initFromInvestmentTypeAction),
      setBookingsAction: assign(setBookingsAction),
      setBookingTypeAction: assign(setBookingTypeAction),
      setInvestmentValuesAction: assign(setInvestmentValuesAction),
      setManualFlowAction: assign(setManualFlowAction),
      setManualInvestmentAction: assign(setManualInvestmentAction),
      setNameAndDepotAction: assign(setNameAndDepotAction),
      setSourceInvestmentValuesAction: assign(setSourceInvestmentValuesAction),
      setSymbolAction: assign(setSymbolAction),
      updateSplitValuesAction: assign(updateSplitValuesAction),
    },
    guards: {
      initWithDepotId: (context, event) => {
        return Boolean(context.defaultDepotId);
      },
      isSplitSelected: (context, event) => {
        return (
          event.type === "BOOKING_TYPE_SELECTED" &&
          event.bookingType === "split"
        );
      },
      isExchangeSelected: (context, event) => {
        return (
          event.type === "BOOKING_TYPE_SELECTED" &&
          (event.bookingType === "carve_out" ||
            event.bookingType === "switch" ||
            event.bookingType === "fusion")
        );
      },
      isExchange: (context, event) => {
        return (
          context.bookingValues?.type === "carve_out" ||
          context.bookingValues?.type === "switch" ||
          context.bookingValues?.type === "fusion"
        );
      },
      isManual: (context, event) => {
        return Boolean(context.manualFlow);
      },
      isManualOrNonListed: (context, event) => {
        return Boolean(
          context.manualFlow || context.investmentValues?.type === "71_massets"
        );
      },
      isManualOrNonListedAndIsExchange: (context, event) => {
        return (
          (context.manualFlow ||
            context.investmentValues?.type === "71_massets") &&
          (context.bookingValues?.type === "carve_out" ||
            context.bookingValues?.type === "switch" ||
            context.bookingValues?.type === "fusion")
        );
      },
      isManualOrNonListedAndIsSplit: (context, event) => {
        return (
          (context.manualFlow ||
            context.investmentValues?.type === "71_massets") &&
          context.bookingValues?.type === "split"
        );
      },
    },
  }
);
