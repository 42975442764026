import React, { useEffect, useState } from "react";
import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledCard } from "@features/dividendPlanner/components/StyledCard";
import { SharedBanner } from "@features/sharedPortfolio/SharedBanner";
import { FiltersPanel } from "@features/dividendPlanner/CalendarTab/FiltersPanel";
import { DividendsTable } from "@features/dividendPlanner/components/DividendsTable/DividendsTable";
import { DividendsCalendar } from "@features/dividendPlanner/CalendarTab/DividendsCalendar";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { LoadingPlaceHolder } from "@components";

const today = {
  month: new Date().getUTCMonth(),
  year: new Date().getFullYear(),
};

export const CalendarTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { filter, isLoading } = useDividendsContext();
  const [view, setView] = useState<"calendar" | "table">("calendar");
  const [period, setPeriod] = useState<"month" | "year">("month");
  const [date, setDate] = useState<{
    month: number;
    year: number;
  }>(today);

  useEffect(() => {
    if (!filter.includePrediction) {
      setDate(today);
    }
  }, [filter]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={isMobile ? 4 : 6}
      gap={6}
      maxWidth={1800}
      width="100%"
      flex={1}
    >
      <SharedBanner />
      <StyledCard>
        <Typography variant="h5" fontWeight={700} letterSpacing={-0.5} mb={4}>
          {t("dividends.calendarPage.title")}
        </Typography>
        <FiltersPanel
          view={view}
          period={period}
          date={date}
          onViewChange={setView}
          onPeriodChange={setPeriod}
          onDateChange={setDate}
        />
        {isLoading ? (
          <Box width="100%" mt={6}>
            <LoadingPlaceHolder height="300px" />
          </Box>
        ) : (
          <Box
            mt={6}
            width={`calc(100% + ${isMobile ? "32px" : "48px"})`}
            margin={(theme) =>
              theme.spacing(6, isMobile ? -4 : -6, isMobile ? -4 : -6)
            }
            height="100%"
          >
            {view === "table" ? (
              <DividendsTable
                date={new Date(date.year, date.month)}
                displaySum
                view="calendar"
                period={period}
                fullViewPadding={4}
                sx={{
                  "& .MuiTableCell-root:first-of-type": {
                    padding: theme.spacing(3, 3, 3, 6),
                  },
                  "& .MuiTableCell-root:last-of-type": {
                    padding: theme.spacing(3, 6, 3, 3),
                  },
                }}
              />
            ) : (
              <DividendsCalendar date={date} period={period} />
            )}
          </Box>
        )}
      </StyledCard>
    </Box>
  );
};
