import { PaletteOptions } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

export const baseLightColors = {
  N1000: "#000000",
  N900: "#1B1F22",
  N800: "#263240",
  N700: "#3C4653",
  N600: "#6C7889",
  N500: "#8593A3",
  N400: "#A8B0BD",
  N300: "#C9D0DA",
  N200: "#E4E9F1",
  N100: "#EBEDF5",
  N050: "#F8F8FD",
  N000: "#FFFFFF",

  M050: "#DBFDFE",
  M100: "#C3F1FE",
  M200: "#A7E1FC",
  M300: "#75D2FA",
  M400: "#18C2F9",
  M500: "#13ADDF",
  M600: "#1598C4",
  M700: "#0783AA",
  M800: "#106887",
  M900: "#145067",

  G050: "#E9FFE1",
  G100: "#B7F9C7",
  G200: "#87EEB0",
  G300: "#5FDDA3",
  G400: "#1CCC93",
  G500: "#0FB783",
  G600: "#2A9F7C",
  G700: "#1D8869",
  G800: "#206E56",
  G900: "#015540",

  O050: "#FEEED7",
  O100: "#FFE6C1",
  O200: "#FED097",
  O300: "#FEB971",
  O400: "#FD9C5E",
  O500: "#FF794A",
  O600: "#E66638",
  O700: "#C95021",
  O800: "#A63F16",
  O900: "#842B03",

  R050: "#FDECE8",
  R100: "#FED7CF",
  R200: "#FFC4B8",
  R300: "#FEAC9E",
  R400: "#FD8E73",
  R500: "#FF5F43",
  R600: "#FC4928",
  R700: "#DA3616",
  R800: "#B52202",
  R900: "#8D1700",
  Y050: "#FEF6D4",

  Y100: "#FEE8A8",
  Y200: "#FCDD7E",
  Y300: "#FDCB4D",
  Y400: "#F2BB13",
  Y500: "#E8A418",
  Y600: "#D18A0C",
  Y700: "#B27203",
  Y800: "#8A560C",
  Y900: "#6B4102",

  P050: "#ECEFFE",
  P100: "#E5E6FC",
  P200: "#D5D5FD",
  P300: "#BFBEFE",
  P400: "#A4AAFB",
  P500: "#9499FD",
  P600: "#7C7DF2",
  P700: "#6D67D6",
  P800: "#5950BD",
  P900: "#463D9C",

  B050: "#E1F8FD",
  B100: "#CEEBFE",
  B200: "#ACDBFA",
  B300: "#91CAFF",
  B400: "#75AEFD",
  B500: "#4393FC",
  B600: "#3E76F5",
  B700: "#3269E3",
  B800: "#1B4FC7",
  B900: "#113CA1",

  F050: "#fce7f6",
  F100: "#fcceee",
  F200: "#faa7e0",
  F300: "#f670c7",
  F400: "#ee46bc",
  F500: "#dd2590",
  F600: "#c11574",
  F700: "#9e165f",
  F800: "#851651",
  F900: "#4e0d30",

  T050: "#f0fdfa",
  T100: "#ccfbf1",
  T200: "#99f6e4",
  T300: "#5eead4",
  T400: "#2dd4bf",
  T500: "#14b8a6",
  T600: "#0d9488",
  T700: "#0f766e",
  T800: "#115e59",
  T900: "#134e4a",

  A050: "#eefbde",
  A100: "#def8bc",
  A200: "#cdf49b",
  A300: "#bdf07a",
  A400: "#acec58",
  A500: "#9bd44f",
  A600: "#8abd46",
  A700: "#78a53e",
  A800: "#678e35",
  A900: "#56762c",

  L050: "#def0e1",
  L100: "#bce1c3",
  L200: "#9bd1a4",
  L300: "#79c286",
  L400: "#58b368",
  L500: "#4fa15e",
  L600: "#468f53",
  L700: "#3e7d49",
  L800: "#356b3e",
  L900: "#2c5a34",

  V050: "#eddaff",
  V100: "#dab5ff",
  V200: "#c891ff",
  V300: "#b56cff",
  V400: "#a347ff",
  V500: "#9340e5",
  V600: "#8239cc",
  V700: "#7232b2",
  V800: "#622b99",
  V900: "#522480",

  C050: "#e0eefa",
  C100: "#c1def5",
  C200: "#a1cdef",
  C300: "#82bdea",
  C400: "#63ace5",
  C500: "#599bce",
  C600: "#4f8ab7",
  C700: "#4578a0",
  C800: "#3b6789",
  C900: "#325673",

  // @deprecated all colors below are deprecated
  black: "#000000",
  white: "#ffffff",
};

export const baseLightPalette: Partial<PaletteOptions> = {
  common: { ...baseLightColors },
  grey: {
    main: baseLightColors.N300,
    dark: baseLightColors.N400,
  },
  primary: {
    main: baseLightColors.M400,
  },
  secondary: {
    main: baseLightColors.M200,
  },
  error: {
    main: baseLightColors.R400,
  },
  warning: {
    main: baseLightColors.Y400,
  },
  info: {
    main: baseLightColors.B400,
  },
  success: {
    main: baseLightColors.G400,
  },
  text: {
    primary: baseLightColors.N800,
    secondary: baseLightColors.N600,
    disabled: baseLightColors.N300,
    positive: baseLightColors.G500,
    negative: baseLightColors.R500,
  },
};

export const baseLightShape = {
  borderRadius: 6,
};

export const baseLightShadows = [
  "none",
  "0px 1px 2px rgba(30, 39, 50, 0.16)",
  "0px 2px 4px -1px rgba(30, 39, 50, 0.16)",
  "0px 4px 6px -1px rgba(30, 39, 50, 0.16)",
  "0px 4px 12px -1px rgba(30, 39, 50, 0.12), 0px 4px 16px -2px rgba(30, 39, 50, 0.16)",
  "0px 8px 16px -2px rgba(30, 39, 50, 0.12), 0px 6px 24px -2px rgba(30, 39, 50, 0.16)",
  ...Array(19).fill("none"),
] as Shadows;
