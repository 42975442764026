import { UseQueryOptions } from "react-query";

import { buildFundsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const runFwwImport1 = async (token: string | undefined) => {
  const apiInstance = buildFundsApi(token);
  const { data } = await apiInstance.runFwwImport1();
  return data;
};

export const useRunFwwImport1 = (
  options?: UseQueryOptions<object, AxiosApiError, object>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDS_RUN_FWW_IMPORT1],
    ({ token }) => runFwwImport1(token),
    options
  );
};
