import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
  header: ReactNode;
  children: ReactNode;
}

export const FundamentalsBlock = ({ header, children }: Props) => {
  return (
    <Container data-testid="fundamentals-block">
      {header}
      <Grid container spacing={4}>
        {Array.isArray(children) ? (
          children.filter(Boolean).map((child, i) => {
            if (Array.isArray(child)) {
              return child.map((c, idx) => (
                <Grid item xs={12} sm={12} md={6} key={idx}>
                  {c}
                </Grid>
              ));
            }

            return (
              <Grid item xs={12} sm={12} md={6} key={i}>
                {child}
              </Grid>
            );
          })
        ) : (
          <Grid item sm={12} md={6}>
            {children}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
