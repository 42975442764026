import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as ArrowsRotate } from "@icons/clock-rotate-left-solid.svg";
import { toCurrency } from "@helpers";
import { ReactComponent as WarningIcon } from "@icons/medium-warn.svg";
import { TableBodyCell, TableRow } from "@components/Table";
import { accountTypes } from "@features/accounts/constants";
import { ReactComponent as ReferenceAccountIcon } from "@icons/reference-account.svg";
import {
  AccountPreviewChart,
  AccountPreviewChartData,
  Tooltip,
} from "@components";
import { BankAvatar } from "@components/Accounts/BankAvatar";

const AccountMetaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  height: 100%;
  flex-grow: 1;
  max-width: 400px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 300px;
  }

  ${({ theme }) => theme.breakpoints.down(700)} {
    max-width: 250px;
  }
`;

const AccountNameContainer = styled.div`
  // Note: these calculations depend on bank logo size and margins
  max-width: calc(100% - 24px - ${({ theme }) => theme.spacing(2)});

  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: calc(100% - 40px - ${({ theme }) => theme.spacing(4)});
  }
`;

const AccountName = styled(Typography)`
  width: 100%;
`;

const AccountSubtitle = styled(Typography)`
  width: 100%;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const ChartContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "showBorder" && prop !== "height",
})<{ showBorder: boolean; height: number }>`
  height: ${({ height }) => height || 72}px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.border.secondary};
  border-width: ${({ showBorder }) => (showBorder ? 1 : 0)}px;
`;

const FinanceValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  height: 100%;
`;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    justify-content: normal;
  }
`;

const PercentTypography = styled(Typography)<{ positive: number }>`
  color: ${({ theme, positive }) =>
    positive > 0 ? theme.palette.text.positive : theme.palette.text.negative};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

const NotificationContainer = styled.div<
  Pick<AccountPreviewCardProps, "showNotification">
>`
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.text.secondary};
  display: ${({ showNotification }) => (showNotification ? "flex" : "none")};
`;

export interface AccountPreviewCardProps {
  accountName: string;
  bankName: string;
  bankLogoURL: string;
  bankCode?: string;
  amount: number;
  currency: string;
  increasePercent: number;
  chartData: AccountPreviewChartData[];
  showNotification?: boolean;
  showChartBorder?: boolean;
  onClick?: () => void;
  onClickUpdate?: () => void;
  showYtdValue?: boolean;
  sinceYear?: number;
  accountType?: string;
  changeAbsCashFlowAdjusted?: number;
  isReferenceAccount?: boolean;
  showUpdateButton?: boolean;
  hasAccountsWithUpdateButton?: boolean;
}

export const AccountPreviewCard = ({
  accountName,
  bankName,
  bankLogoURL,
  bankCode,
  amount,
  currency,
  increasePercent,
  showNotification = false,
  showChartBorder = true,
  chartData,
  onClick,
  onClickUpdate = () => {},
  showYtdValue = true,
  showUpdateButton,
  sinceYear,
  changeAbsCashFlowAdjusted,
  accountType,
  isReferenceAccount,
  hasAccountsWithUpdateButton,
}: AccountPreviewCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClick = () => {
    onClick && onClick();
  };
  const handleUpdateClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClickUpdate();
  };
  const type = accountTypes.find((type) => type.value === accountType);

  return (
    <StyledTableRow data-testid="account-row">
      <TableBodyCell onClick={onClick && handleClick}>
        <AccountMetaContainer>
          {isReferenceAccount ? (
            <Box display="flex" color={theme.palette.icon.secondary} mr={4}>
              <ReferenceAccountIcon width="24px" height="24px" />
            </Box>
          ) : (
            <BankAvatar bankLogoURL={bankLogoURL} accountType={type?.value} />
          )}
          <AccountNameContainer>
            <AccountName
              variant="subtitle1"
              fontWeight="600"
              noWrap
              data-testid="account-name"
            >
              {accountName}
            </AccountName>
            <AccountSubtitle
              variant="body1"
              color="textSecondary"
              noWrap
              fontWeight={400}
            >
              {bankName}
              {bankCode && ` • ${bankCode}`}
            </AccountSubtitle>
          </AccountNameContainer>
        </AccountMetaContainer>
      </TableBodyCell>
      {smAndUp && (
        <TableBodyCell onClick={onClick && handleClick} width="30%">
          <ChartContainer showBorder={showChartBorder} height={45}>
            <AccountPreviewChart
              chartData={chartData}
              height={45}
              strokeColor={theme.palette.graphVisual["02"].mid}
            />
          </ChartContainer>
        </TableBodyCell>
      )}
      <TableBodyCell onClick={onClick && handleClick} width="20%">
        <FinanceValuesContainer>
          <ValuesContainer>
            <Typography variant="subtitle1" fontWeight="600">
              {toCurrency(amount, currency)}
            </Typography>

            {showYtdValue && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                {sinceYear && (
                  <Typography variant="body2" color="textSecondary">
                    {t("accounts.sinceYear", { year: sinceYear })}
                  </Typography>
                )}
                <PercentTypography variant="body2" positive={increasePercent}>
                  {toCurrency(changeAbsCashFlowAdjusted, currency)}
                </PercentTypography>
              </Box>
            )}

            <NotificationContainer
              showNotification={showNotification}
              onClick={handleUpdateClick}
            >
              <WarningIcon
                width="12px"
                height="12px"
                color={theme.palette.warning.main}
              />
              <Typography
                variant="caption"
                ml={1.5}
                color={theme.palette.warning.dark}
              >
                {t("accounts.updateRecommended")}
              </Typography>
            </NotificationContainer>
          </ValuesContainer>
        </FinanceValuesContainer>
      </TableBodyCell>

      {hasAccountsWithUpdateButton && (
        <TableBodyCell align="center">
          {showUpdateButton ? (
            <Tooltip text={t("depotsAccounts.updateAccount")}>
              <IconButton
                size="small"
                color="secondary"
                onClick={handleUpdateClick}
                sx={{
                  background: "transparent",
                  "& svg": {
                    color: theme.palette.icon.active,
                  },
                }}
              >
                <ArrowsRotate width={16} height={16} />
              </IconButton>
            </Tooltip>
          ) : (
            <Box width="36px" height="36px" />
          )}
        </TableBodyCell>
      )}
    </StyledTableRow>
  );
};
