import { UseMutationOptions } from "react-query";
import {
  SymbolsApiAssignPreferredQuoteRequest,
  CreateOrUpdateInvestmentParamsList,
} from "@generated/apiv1";

import { buildSymbolsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const assignPreferredQuote = async (
  token: string | undefined,
  params: SymbolsApiAssignPreferredQuoteRequest
) => {
  const apiInstance = buildSymbolsApi(token);
  const { data } = await apiInstance.assignPreferredQuote(params);
  return data;
};

export const useAssignPreferredQuote = (
  options?: UseMutationOptions<
    CreateOrUpdateInvestmentParamsList,
    AxiosApiError,
    SymbolsApiAssignPreferredQuoteRequest
  >
) => {
  return useAuthMutation(assignPreferredQuote, options, false);
};
