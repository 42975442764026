import { UseMutationOptions } from "react-query";
import { updateProfile, UpdateProfileAttrs } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

export const useUpdateProfile = (
  options: UseMutationOptions<
    UpdateProfileAttrs,
    AxiosApiError,
    UpdateProfileAttrs
  > = {}
) => useAuthMutation(updateProfile, { useErrorBoundary: true, ...options });
