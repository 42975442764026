import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetDepotSynchronizationLogs } from "@api/v1/hooks/useDepotSynchronizationLog";
import { WizardsHeader } from "@components/common/headers/WizardsHeader";
import { WizardLayout } from "@components/common/layouts/WizardLayout";
import { LoadingProgressBar } from "@components";
import { SynchronizationLogList } from "./SynchronizationLogList";

export const SynchronizationLogListWithRouting = () => {
  const { accountId } = useParams<"accountId">();
  const parsedAccountId = Number.parseInt(accountId!, 10);

  const { data: depotSynchronizationLogList, isLoading } =
    useGetDepotSynchronizationLogs({
      accountId: [parsedAccountId],
      perPage: 10,
      order: "id desc",
    });
  const { t } = useTranslation();

  const goBack = () => {
    window.history.back();
  };

  if (isLoading) {
    return <LoadingProgressBar />;
  }

  return (
    <WizardLayout
      header={
        <>
          <WizardsHeader
            title={t("synchronizationLog.title")}
            onBackClick={goBack}
            variant="dark"
          />
        </>
      }
    >
      <Helmet>
        <title>{t("depotTransfer.title")}</title>
      </Helmet>

      <SynchronizationLogList
        depotSynchronizationLogs={
          depotSynchronizationLogList?.depotSynchronizationLogs || []
        }
      />
    </WizardLayout>
  );
};
