import { UseQueryOptions } from "react-query";
import {
  InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest,
  PageableInsuranceCategoryList,
} from "@generated/apiv1";

import { buildInsuranceCategoriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllInsuranceCategories = async (
  token: string | undefined,
  params?: InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest
) => {
  const apiInstance = buildInsuranceCategoriesApi(token);
  const { data } = await apiInstance.listAndSearchAllInsuranceCategories(
    params
  );
  return data;
};

export const useListAndSearchAllInsuranceCategories = (
  params?: InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest,
  options?: UseQueryOptions<
    PageableInsuranceCategoryList,
    AxiosApiError,
    PageableInsuranceCategoryList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.INSURANCECATEGORIES_LIST_AND_SEARCH_ALL_INSURANCE_CATEGORIES,
      params,
    ],
    ({ token }) => listAndSearchAllInsuranceCategories(token, params),
    options
  );
};
