import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  Box,
  BoxProps,
  ToggleButton as MuiToggleButton,
  useTheme,
} from "@mui/material";
import { DatePicker } from "./DatePicker";

const ToggleButton = styled(MuiToggleButton)`
  font-size: 14px;
  line-height: 20px;
  border: none;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: ${({ theme }) => theme.spacing(1.25, 3)};
  text-transform: unset;
  border: 1px solid transparent;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.background.default};
    border: 1px solid ${({ theme }) => theme.palette.border.secondary};
    font-weight: 600;
  }

  &.Mui-disabled {
    border: 1px solid transparent;
  }
`;

const fullDefaultIntervalTypes: IntervalType[] = [
  "oneWeek",
  "oneMonth",
  "YTD",
  "oneYear",
  "threeYears",
  "fiveYears",
  "sinceBeginning",
  "custom",
];

const compactDefaultIntervalTypes: IntervalType[] = [
  "oneWeek",
  "oneMonth",
  "oneYear",
  "fiveYears",
  "custom",
];

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: theme.shape.borderRadius,
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.neutral,

  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },

  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(1),
  },

  [theme.breakpoints.down("sm")]: {
    flexGrow: 1,
  },
}));

export interface RangeSelectorProps extends Omit<BoxProps, "onSelect"> {
  className?: string;
  intervalType?: IntervalType;
  range?: RangeType;
  compact?: boolean;
  intervalTypes?: IntervalType[];
  compactIntervalTypes?: IntervalType[];
  onSelect: (type: IntervalType) => void;
  onCustomSelect?: (from: Date, to: Date) => void;
  disabledIntervalTypes?: IntervalType[];
  disablePortal?: boolean;
}

export const RangeSelector = ({
  onSelect,
  intervalType,
  range,
  className,
  compact,
  intervalTypes = fullDefaultIntervalTypes,
  compactIntervalTypes = compactDefaultIntervalTypes,
  onCustomSelect,
  disabledIntervalTypes,
  disablePortal,
  ...rest
}: RangeSelectorProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClick = (type: IntervalType) => {
    return onSelect(type);
  };

  const handleCustomSubmit = (from: Date, to?: Date) => {
    return onCustomSelect && onCustomSelect(from, to!);
  };

  const intervals = compact ? compactIntervalTypes : intervalTypes;

  return (
    <Container className={className} {...rest}>
      {intervals.map((type) =>
        type === "custom" ? (
          <DatePicker
            key="custom"
            onChange={handleCustomSubmit}
            sx={{
              color:
                intervalType === "custom" ? "primary.main" : "icon.tertiary",
              backgroundColor:
                intervalType === "custom"
                  ? "background.default"
                  : "transparent",
              border:
                intervalType === "custom"
                  ? `1px solid ${theme.palette.border.secondary}`
                  : "none",
              height: "32px",
            }}
            from={range?.startDate}
            to={range?.endDate}
            disabled={disabledIntervalTypes?.includes(type)}
            disablePortal={disablePortal}
          />
        ) : (
          <ToggleButton
            data-testid={`button-${type}`}
            size="small"
            key={type}
            value={type}
            selected={type === intervalType}
            onClick={() => {
              handleClick(type);
            }}
            disabled={disabledIntervalTypes?.includes(type)}
          >
            {t(
              `general.ranges.rangeSelector.intervalSelector.compact.${type}`
            ).toUpperCase()}
          </ToggleButton>
        )
      )}
    </Container>
  );
};
