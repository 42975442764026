/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateMandatorParams } from '../model';
// @ts-ignore
import { Mandator } from '../model';
// @ts-ignore
import { MandatorList } from '../model';
/**
 * MandatorsApi - axios parameter creator
 * @export
 */
export const MandatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a mandator.
         * @param {CreateOrUpdateMandatorParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMandator: async (body: CreateOrUpdateMandatorParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMandator', 'body', body)
            const localVarPath = `/v1/mandators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all configured mandators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMandators: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/mandators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a mandator
         * @param {number} id ID of the mandator to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mandatorsId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mandatorsId', 'id', id)
            const localVarPath = `/v1/mandators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a mandator.
         * @param {number} id ID of the mandator.
         * @param {CreateOrUpdateMandatorParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandator: async (id: number, body: CreateOrUpdateMandatorParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMandator', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMandator', 'body', body)
            const localVarPath = `/v1/mandators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MandatorsApi - functional programming interface
 * @export
 */
export const MandatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MandatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a mandator.
         * @param {CreateOrUpdateMandatorParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMandator(body: CreateOrUpdateMandatorParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mandator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMandator(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.createMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all configured mandators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMandators(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MandatorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMandators(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.listMandators']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a mandator
         * @param {number} id ID of the mandator to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mandatorsId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mandator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mandatorsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.mandatorsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a mandator.
         * @param {number} id ID of the mandator.
         * @param {CreateOrUpdateMandatorParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMandator(id: number, body: CreateOrUpdateMandatorParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mandator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMandator(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorsApi.updateMandator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MandatorsApi - factory interface
 * @export
 */
export const MandatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MandatorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a mandator.
         * @param {MandatorsApiCreateMandatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMandator(requestParameters: MandatorsApiCreateMandatorRequest, options?: RawAxiosRequestConfig): AxiosPromise<Mandator> {
            return localVarFp.createMandator(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all configured mandators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMandators(options?: RawAxiosRequestConfig): AxiosPromise<MandatorList> {
            return localVarFp.listMandators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a mandator
         * @param {MandatorsApiMandatorsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mandatorsId(requestParameters: MandatorsApiMandatorsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Mandator> {
            return localVarFp.mandatorsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a mandator.
         * @param {MandatorsApiUpdateMandatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandator(requestParameters: MandatorsApiUpdateMandatorRequest, options?: RawAxiosRequestConfig): AxiosPromise<Mandator> {
            return localVarFp.updateMandator(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMandator operation in MandatorsApi.
 * @export
 * @interface MandatorsApiCreateMandatorRequest
 */
export interface MandatorsApiCreateMandatorRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateMandatorParams}
     * @memberof MandatorsApiCreateMandator
     */
    readonly body: CreateOrUpdateMandatorParams
}

/**
 * Request parameters for mandatorsId operation in MandatorsApi.
 * @export
 * @interface MandatorsApiMandatorsIdRequest
 */
export interface MandatorsApiMandatorsIdRequest {
    /**
     * ID of the mandator to retrieve
     * @type {number}
     * @memberof MandatorsApiMandatorsId
     */
    readonly id: number
}

/**
 * Request parameters for updateMandator operation in MandatorsApi.
 * @export
 * @interface MandatorsApiUpdateMandatorRequest
 */
export interface MandatorsApiUpdateMandatorRequest {
    /**
     * ID of the mandator.
     * @type {number}
     * @memberof MandatorsApiUpdateMandator
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateMandatorParams}
     * @memberof MandatorsApiUpdateMandator
     */
    readonly body: CreateOrUpdateMandatorParams
}

/**
 * MandatorsApi - object-oriented interface
 * @export
 * @class MandatorsApi
 * @extends {BaseAPI}
 */
export class MandatorsApi extends BaseAPI {
    /**
     * 
     * @summary Create a mandator.
     * @param {MandatorsApiCreateMandatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public createMandator(requestParameters: MandatorsApiCreateMandatorRequest, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).createMandator(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all configured mandators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public listMandators(options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).listMandators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a mandator
     * @param {MandatorsApiMandatorsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public mandatorsId(requestParameters: MandatorsApiMandatorsIdRequest, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).mandatorsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a mandator.
     * @param {MandatorsApiUpdateMandatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorsApi
     */
    public updateMandator(requestParameters: MandatorsApiUpdateMandatorRequest, options?: RawAxiosRequestConfig) {
        return MandatorsApiFp(this.configuration).updateMandator(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

