import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export interface Props {
  investmentName: string;
  redirectUrl: string;
}

export const PersistedStep = ({ investmentName, redirectUrl }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar(
    `${t("transactionWizard.persistedStep.title")}: ${investmentName}`,
    { autoHideDuration: 3000, variant: "success" }
  );
  queryClient.invalidateQueries({
    predicate: (query) => {
      return [
        CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS,
        CACHE_KEYS.INVESTMENTS_LIST_AND_SEARCH_ALL_INVESTMENTS,
        CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS,
      ].includes(query.queryKey[0] as string);
    },
  });

  return <Navigate replace to={redirectUrl} />;
};
