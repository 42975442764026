/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { FundamentalList } from '../model';
// @ts-ignore
import { MarketCapitalization } from '../model';
// @ts-ignore
import { Security } from '../model';
// @ts-ignore
import { StatisticList } from '../model';
/**
 * FundamentalsApi - axios parameter creator
 * @export
 */
export const FundamentalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Refresh all master data via the data providers\'s API. A refresh is executed as a background                 operation, thus this endpoint will return immediately.
         * @param {boolean} [forceFullRefresh] Perform a full refresh of daily data instead of the default refresh of the last 365 days only.                      Default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsFullRefresh: async (forceFullRefresh?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/fundamentals/fullRefresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (forceFullRefresh !== undefined) {
                localVarQueryParameter['forceFullRefresh'] = forceFullRefresh;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve company information for the given ISIN.
         * @param {string} id The companies ISIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundamentalsId', 'id', id)
            const localVarPath = `/v1/fundamentals/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve yearly company financials for the given ISIN. . This endpoint requires a PRO                     subscription for mandators supporting subscriptions.
         * @param {string} id The companies ISIN
         * @param {number} from Retrieve financials starting from the given year
         * @param {number} to Retrieve financials up to the given year. Note that due to delayed reporting, there might                         be no available date for the current year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdFinancials: async (id: string, from: number, to: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundamentalsIdFinancials', 'id', id)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('fundamentalsIdFinancials', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('fundamentalsIdFinancials', 'to', to)
            const localVarPath = `/v1/fundamentals/fundamentals/{id}/financials`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the market capitalization based on the current quote. Optionally, convert to a given                 currency.
         * @param {string} id The companies ISIN
         * @param {string} tickerSymbol Use the given ticker symbol to derive todays quote.
         * @param {string} quoteProvider Use the given quote provider to derive todays quote
         * @param {string} [convertToCurrency] Convert the market capitalization to the given currency; use the quote currency as default.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdMarketCapitalization: async (id: string, tickerSymbol: string, quoteProvider: string, convertToCurrency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundamentalsIdMarketCapitalization', 'id', id)
            // verify required parameter 'tickerSymbol' is not null or undefined
            assertParamExists('fundamentalsIdMarketCapitalization', 'tickerSymbol', tickerSymbol)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('fundamentalsIdMarketCapitalization', 'quoteProvider', quoteProvider)
            const localVarPath = `/v1/fundamentals/fundamentals/{id}/marketCapitalization`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tickerSymbol !== undefined) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (quoteProvider !== undefined) {
                localVarQueryParameter['quoteProvider'] = quoteProvider;
            }

            if (convertToCurrency !== undefined) {
                localVarQueryParameter['convertToCurrency'] = convertToCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh master for the given ISIN.
         * @param {string} id Perform a refresh of fundamental data for the given ISIN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdRefresh: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundamentalsIdRefresh', 'id', id)
            const localVarPath = `/v1/fundamentals/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve daily price to XXX statistics for the given ISIN. This endpoint requires a PRO                 subscription for mandators supporting subscriptions.
         * @param {string} id The companies ISIN
         * @param {Array<string>} [interval] Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year.
         * @param {string} [from] Retrieve data starting from the given date; default value is 5 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve data up to the given date; default value is now.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdStatistics: async (id: string, interval?: Array<string>, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundamentalsIdStatistics', 'id', id)
            const localVarPath = `/v1/fundamentals/fundamentals/{id}/statistics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (interval) {
                localVarQueryParameter['interval'] = interval;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundamentalsApi - functional programming interface
 * @export
 */
export const FundamentalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundamentalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Refresh all master data via the data providers\'s API. A refresh is executed as a background                 operation, thus this endpoint will return immediately.
         * @param {boolean} [forceFullRefresh] Perform a full refresh of daily data instead of the default refresh of the last 365 days only.                      Default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsFullRefresh(forceFullRefresh?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsFullRefresh(forceFullRefresh, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsFullRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve company information for the given ISIN.
         * @param {string} id The companies ISIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve yearly company financials for the given ISIN. . This endpoint requires a PRO                     subscription for mandators supporting subscriptions.
         * @param {string} id The companies ISIN
         * @param {number} from Retrieve financials starting from the given year
         * @param {number} to Retrieve financials up to the given year. Note that due to delayed reporting, there might                         be no available date for the current year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsIdFinancials(id: string, from: number, to: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundamentalList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsIdFinancials(id, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsIdFinancials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the market capitalization based on the current quote. Optionally, convert to a given                 currency.
         * @param {string} id The companies ISIN
         * @param {string} tickerSymbol Use the given ticker symbol to derive todays quote.
         * @param {string} quoteProvider Use the given quote provider to derive todays quote
         * @param {string} [convertToCurrency] Convert the market capitalization to the given currency; use the quote currency as default.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsIdMarketCapitalization(id: string, tickerSymbol: string, quoteProvider: string, convertToCurrency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketCapitalization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsIdMarketCapitalization(id, tickerSymbol, quoteProvider, convertToCurrency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsIdMarketCapitalization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh master for the given ISIN.
         * @param {string} id Perform a refresh of fundamental data for the given ISIN.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsIdRefresh(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsIdRefresh(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsIdRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve daily price to XXX statistics for the given ISIN. This endpoint requires a PRO                 subscription for mandators supporting subscriptions.
         * @param {string} id The companies ISIN
         * @param {Array<string>} [interval] Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year.
         * @param {string} [from] Retrieve data starting from the given date; default value is 5 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve data up to the given date; default value is now.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundamentalsIdStatistics(id: string, interval?: Array<string>, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundamentalsIdStatistics(id, interval, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalsApi.fundamentalsIdStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FundamentalsApi - factory interface
 * @export
 */
export const FundamentalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundamentalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Refresh all master data via the data providers\'s API. A refresh is executed as a background                 operation, thus this endpoint will return immediately.
         * @param {FundamentalsApiFundamentalsFullRefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsFullRefresh(requestParameters: FundamentalsApiFundamentalsFullRefreshRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fundamentalsFullRefresh(requestParameters.forceFullRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve company information for the given ISIN.
         * @param {FundamentalsApiFundamentalsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsId(requestParameters: FundamentalsApiFundamentalsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Security> {
            return localVarFp.fundamentalsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve yearly company financials for the given ISIN. . This endpoint requires a PRO                     subscription for mandators supporting subscriptions.
         * @param {FundamentalsApiFundamentalsIdFinancialsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdFinancials(requestParameters: FundamentalsApiFundamentalsIdFinancialsRequest, options?: RawAxiosRequestConfig): AxiosPromise<FundamentalList> {
            return localVarFp.fundamentalsIdFinancials(requestParameters.id, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the market capitalization based on the current quote. Optionally, convert to a given                 currency.
         * @param {FundamentalsApiFundamentalsIdMarketCapitalizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdMarketCapitalization(requestParameters: FundamentalsApiFundamentalsIdMarketCapitalizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<MarketCapitalization> {
            return localVarFp.fundamentalsIdMarketCapitalization(requestParameters.id, requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.convertToCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh master for the given ISIN.
         * @param {FundamentalsApiFundamentalsIdRefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdRefresh(requestParameters: FundamentalsApiFundamentalsIdRefreshRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fundamentalsIdRefresh(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve daily price to XXX statistics for the given ISIN. This endpoint requires a PRO                 subscription for mandators supporting subscriptions.
         * @param {FundamentalsApiFundamentalsIdStatisticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundamentalsIdStatistics(requestParameters: FundamentalsApiFundamentalsIdStatisticsRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatisticList> {
            return localVarFp.fundamentalsIdStatistics(requestParameters.id, requestParameters.interval, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fundamentalsFullRefresh operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsFullRefreshRequest
 */
export interface FundamentalsApiFundamentalsFullRefreshRequest {
    /**
     * Perform a full refresh of daily data instead of the default refresh of the last 365 days only.                      Default is false.
     * @type {boolean}
     * @memberof FundamentalsApiFundamentalsFullRefresh
     */
    readonly forceFullRefresh?: boolean
}

/**
 * Request parameters for fundamentalsId operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsIdRequest
 */
export interface FundamentalsApiFundamentalsIdRequest {
    /**
     * The companies ISIN
     * @type {string}
     * @memberof FundamentalsApiFundamentalsId
     */
    readonly id: string
}

/**
 * Request parameters for fundamentalsIdFinancials operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsIdFinancialsRequest
 */
export interface FundamentalsApiFundamentalsIdFinancialsRequest {
    /**
     * The companies ISIN
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdFinancials
     */
    readonly id: string

    /**
     * Retrieve financials starting from the given year
     * @type {number}
     * @memberof FundamentalsApiFundamentalsIdFinancials
     */
    readonly from: number

    /**
     * Retrieve financials up to the given year. Note that due to delayed reporting, there might                         be no available date for the current year.
     * @type {number}
     * @memberof FundamentalsApiFundamentalsIdFinancials
     */
    readonly to: number
}

/**
 * Request parameters for fundamentalsIdMarketCapitalization operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsIdMarketCapitalizationRequest
 */
export interface FundamentalsApiFundamentalsIdMarketCapitalizationRequest {
    /**
     * The companies ISIN
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdMarketCapitalization
     */
    readonly id: string

    /**
     * Use the given ticker symbol to derive todays quote.
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdMarketCapitalization
     */
    readonly tickerSymbol: string

    /**
     * Use the given quote provider to derive todays quote
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdMarketCapitalization
     */
    readonly quoteProvider: string

    /**
     * Convert the market capitalization to the given currency; use the quote currency as default.
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdMarketCapitalization
     */
    readonly convertToCurrency?: string
}

/**
 * Request parameters for fundamentalsIdRefresh operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsIdRefreshRequest
 */
export interface FundamentalsApiFundamentalsIdRefreshRequest {
    /**
     * Perform a refresh of fundamental data for the given ISIN.
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdRefresh
     */
    readonly id: string
}

/**
 * Request parameters for fundamentalsIdStatistics operation in FundamentalsApi.
 * @export
 * @interface FundamentalsApiFundamentalsIdStatisticsRequest
 */
export interface FundamentalsApiFundamentalsIdStatisticsRequest {
    /**
     * The companies ISIN
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdStatistics
     */
    readonly id: string

    /**
     * Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year.
     * @type {Array<string>}
     * @memberof FundamentalsApiFundamentalsIdStatistics
     */
    readonly interval?: Array<string>

    /**
     * Retrieve data starting from the given date; default value is 5 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdStatistics
     */
    readonly from?: string

    /**
     * Retrieve data up to the given date; default value is now.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof FundamentalsApiFundamentalsIdStatistics
     */
    readonly to?: string
}

/**
 * FundamentalsApi - object-oriented interface
 * @export
 * @class FundamentalsApi
 * @extends {BaseAPI}
 */
export class FundamentalsApi extends BaseAPI {
    /**
     * 
     * @summary Refresh all master data via the data providers\'s API. A refresh is executed as a background                 operation, thus this endpoint will return immediately.
     * @param {FundamentalsApiFundamentalsFullRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsFullRefresh(requestParameters: FundamentalsApiFundamentalsFullRefreshRequest = {}, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsFullRefresh(requestParameters.forceFullRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve company information for the given ISIN.
     * @param {FundamentalsApiFundamentalsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsId(requestParameters: FundamentalsApiFundamentalsIdRequest, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve yearly company financials for the given ISIN. . This endpoint requires a PRO                     subscription for mandators supporting subscriptions.
     * @param {FundamentalsApiFundamentalsIdFinancialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsIdFinancials(requestParameters: FundamentalsApiFundamentalsIdFinancialsRequest, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsIdFinancials(requestParameters.id, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the market capitalization based on the current quote. Optionally, convert to a given                 currency.
     * @param {FundamentalsApiFundamentalsIdMarketCapitalizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsIdMarketCapitalization(requestParameters: FundamentalsApiFundamentalsIdMarketCapitalizationRequest, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsIdMarketCapitalization(requestParameters.id, requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.convertToCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh master for the given ISIN.
     * @param {FundamentalsApiFundamentalsIdRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsIdRefresh(requestParameters: FundamentalsApiFundamentalsIdRefreshRequest, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsIdRefresh(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve daily price to XXX statistics for the given ISIN. This endpoint requires a PRO                 subscription for mandators supporting subscriptions.
     * @param {FundamentalsApiFundamentalsIdStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalsApi
     */
    public fundamentalsIdStatistics(requestParameters: FundamentalsApiFundamentalsIdStatisticsRequest, options?: RawAxiosRequestConfig) {
        return FundamentalsApiFp(this.configuration).fundamentalsIdStatistics(requestParameters.id, requestParameters.interval, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}

