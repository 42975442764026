/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CommissionPayedInFollowingYearList } from '../model';
// @ts-ignore
import { PageableSecurityCommissionList } from '../model';
// @ts-ignore
import { SecurityCommission } from '../model';
// @ts-ignore
import { SecurityCommissionTaxationStatisticList } from '../model';
/**
 * SecurityCommissionsApi - axios parameter creator
 * @export
 */
export const SecurityCommissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Attempt to auto-assign all missing security commission properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoAssignMissingProperties: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/securityCommissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the given security commission as deleted.
         * @param {number} id Delete the security commissions with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommissions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCommissions', 'id', id)
            const localVarPath = `/v1/securityCommissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark all security commissions for the given uploadFilename as deleted.
         * @param {string} uploadFilename Delete the security commissions for the given filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommissionsForFilename: async (uploadFilename: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFilename' is not null or undefined
            assertParamExists('deleteCommissionsForFilename', 'uploadFilename', uploadFilename)
            const localVarPath = `/v1/securityCommissions/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (uploadFilename !== undefined) {
                localVarQueryParameter['uploadFilename'] = uploadFilename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a security commission. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {number} id Retrieve the security commissions with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurityCommissions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSecurityCommissions', 'id', id)
            const localVarPath = `/v1/securityCommissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of security commissions. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {Array<number>} [id] Retrieve the security commissions with the given ID
         * @param {Array<string>} [subscriptionId] Retrieve security commissions belonging to the given subscriptions
         * @param {Array<number>} [internalTransactionId] Retrieve security commissions belonging to the given internal transaction
         * @param {string} [startDate] Only retrieve security commissions with billing date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [endDate] Only retrieve security commissions with billing date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
         * @param {boolean} [withoutSubscription] Only retrieve security commissions without an assigned subscription; default is false.                              Such security commissions are inconsistent and require fixing before closing a billing cycle.
         * @param {boolean} [withoutSecurity] Only retrieve security commissions without an assigned security; default is false.                              This is a minor issue but should be fixed before closing a billing cycle.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllSecurityCommissions: async (id?: Array<number>, subscriptionId?: Array<string>, internalTransactionId?: Array<number>, startDate?: string, endDate?: string, withoutSubscription?: boolean, withoutSecurity?: boolean, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/securityCommissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (subscriptionId) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (internalTransactionId) {
                localVarQueryParameter['internalTransactionId'] = internalTransactionId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (withoutSubscription !== undefined) {
                localVarQueryParameter['withoutSubscription'] = withoutSubscription;
            }

            if (withoutSecurity !== undefined) {
                localVarQueryParameter['withoutSecurity'] = withoutSecurity;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sum of all security commission for year X payed in year X+1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCommissionsSecurityCommissionsPayedInFollowingYear: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/securityCommissions/securityCommissionsPayedInFollowingYear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get taxation statistics based on the securityCommissionsForTaxation table. This table holds                      raw accounting data that supports taxation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCommissionsTaxationStatistics: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/securityCommissions/taxationStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check for problems preventing correct security commission assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSecurityCommissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/securityCommissions/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityCommissionsApi - functional programming interface
 * @export
 */
export const SecurityCommissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityCommissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Attempt to auto-assign all missing security commission properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoAssignMissingProperties(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoAssignMissingProperties(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.autoAssignMissingProperties']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Mark the given security commission as deleted.
         * @param {number} id Delete the security commissions with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommissions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommissions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.deleteCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Mark all security commissions for the given uploadFilename as deleted.
         * @param {string} uploadFilename Delete the security commissions for the given filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommissionsForFilename(uploadFilename: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommissionsForFilename(uploadFilename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.deleteCommissionsForFilename']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a security commission. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {number} id Retrieve the security commissions with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecurityCommissions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityCommission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecurityCommissions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.getSecurityCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of security commissions. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {Array<number>} [id] Retrieve the security commissions with the given ID
         * @param {Array<string>} [subscriptionId] Retrieve security commissions belonging to the given subscriptions
         * @param {Array<number>} [internalTransactionId] Retrieve security commissions belonging to the given internal transaction
         * @param {string} [startDate] Only retrieve security commissions with billing date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [endDate] Only retrieve security commissions with billing date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
         * @param {boolean} [withoutSubscription] Only retrieve security commissions without an assigned subscription; default is false.                              Such security commissions are inconsistent and require fixing before closing a billing cycle.
         * @param {boolean} [withoutSecurity] Only retrieve security commissions without an assigned security; default is false.                              This is a minor issue but should be fixed before closing a billing cycle.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllSecurityCommissions(id?: Array<number>, subscriptionId?: Array<string>, internalTransactionId?: Array<number>, startDate?: string, endDate?: string, withoutSubscription?: boolean, withoutSecurity?: boolean, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableSecurityCommissionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllSecurityCommissions(id, subscriptionId, internalTransactionId, startDate, endDate, withoutSubscription, withoutSecurity, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.listAndSearchAllSecurityCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the sum of all security commission for year X payed in year X+1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityCommissionsSecurityCommissionsPayedInFollowingYear(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPayedInFollowingYearList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityCommissionsSecurityCommissionsPayedInFollowingYear(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.securityCommissionsSecurityCommissionsPayedInFollowingYear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get taxation statistics based on the securityCommissionsForTaxation table. This table holds                      raw accounting data that supports taxation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async securityCommissionsTaxationStatistics(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityCommissionTaxationStatisticList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.securityCommissionsTaxationStatistics(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.securityCommissionsTaxationStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check for problems preventing correct security commission assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSecurityCommissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSecurityCommissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityCommissionsApi.validateSecurityCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SecurityCommissionsApi - factory interface
 * @export
 */
export const SecurityCommissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityCommissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Attempt to auto-assign all missing security commission properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoAssignMissingProperties(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.autoAssignMissingProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark the given security commission as deleted.
         * @param {SecurityCommissionsApiDeleteCommissionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommissions(requestParameters: SecurityCommissionsApiDeleteCommissionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCommissions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark all security commissions for the given uploadFilename as deleted.
         * @param {SecurityCommissionsApiDeleteCommissionsForFilenameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommissionsForFilename(requestParameters: SecurityCommissionsApiDeleteCommissionsForFilenameRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCommissionsForFilename(requestParameters.uploadFilename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a security commission. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {SecurityCommissionsApiGetSecurityCommissionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurityCommissions(requestParameters: SecurityCommissionsApiGetSecurityCommissionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SecurityCommission> {
            return localVarFp.getSecurityCommissions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of security commissions. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
         * @param {SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllSecurityCommissions(requestParameters: SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableSecurityCommissionList> {
            return localVarFp.listAndSearchAllSecurityCommissions(requestParameters.id, requestParameters.subscriptionId, requestParameters.internalTransactionId, requestParameters.startDate, requestParameters.endDate, requestParameters.withoutSubscription, requestParameters.withoutSecurity, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sum of all security commission for year X payed in year X+1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCommissionsSecurityCommissionsPayedInFollowingYear(options?: RawAxiosRequestConfig): AxiosPromise<CommissionPayedInFollowingYearList> {
            return localVarFp.securityCommissionsSecurityCommissionsPayedInFollowingYear(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get taxation statistics based on the securityCommissionsForTaxation table. This table holds                      raw accounting data that supports taxation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        securityCommissionsTaxationStatistics(options?: RawAxiosRequestConfig): AxiosPromise<SecurityCommissionTaxationStatisticList> {
            return localVarFp.securityCommissionsTaxationStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check for problems preventing correct security commission assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSecurityCommissions(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.validateSecurityCommissions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteCommissions operation in SecurityCommissionsApi.
 * @export
 * @interface SecurityCommissionsApiDeleteCommissionsRequest
 */
export interface SecurityCommissionsApiDeleteCommissionsRequest {
    /**
     * Delete the security commissions with the given ID
     * @type {number}
     * @memberof SecurityCommissionsApiDeleteCommissions
     */
    readonly id: number
}

/**
 * Request parameters for deleteCommissionsForFilename operation in SecurityCommissionsApi.
 * @export
 * @interface SecurityCommissionsApiDeleteCommissionsForFilenameRequest
 */
export interface SecurityCommissionsApiDeleteCommissionsForFilenameRequest {
    /**
     * Delete the security commissions for the given filename
     * @type {string}
     * @memberof SecurityCommissionsApiDeleteCommissionsForFilename
     */
    readonly uploadFilename: string
}

/**
 * Request parameters for getSecurityCommissions operation in SecurityCommissionsApi.
 * @export
 * @interface SecurityCommissionsApiGetSecurityCommissionsRequest
 */
export interface SecurityCommissionsApiGetSecurityCommissionsRequest {
    /**
     * Retrieve the security commissions with the given ID
     * @type {number}
     * @memberof SecurityCommissionsApiGetSecurityCommissions
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllSecurityCommissions operation in SecurityCommissionsApi.
 * @export
 * @interface SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest
 */
export interface SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest {
    /**
     * Retrieve the security commissions with the given ID
     * @type {Array<number>}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly id?: Array<number>

    /**
     * Retrieve security commissions belonging to the given subscriptions
     * @type {Array<string>}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly subscriptionId?: Array<string>

    /**
     * Retrieve security commissions belonging to the given internal transaction
     * @type {Array<number>}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly internalTransactionId?: Array<number>

    /**
     * Only retrieve security commissions with billing date equal or after the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly startDate?: string

    /**
     * Only retrieve security commissions with billing date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly endDate?: string

    /**
     * Only retrieve security commissions without an assigned subscription; default is false.                              Such security commissions are inconsistent and require fixing before closing a billing cycle.
     * @type {boolean}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly withoutSubscription?: boolean

    /**
     * Only retrieve security commissions without an assigned security; default is false.                              This is a minor issue but should be fixed before closing a billing cycle.
     * @type {boolean}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly withoutSecurity?: boolean

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof SecurityCommissionsApiListAndSearchAllSecurityCommissions
     */
    readonly order?: string
}

/**
 * SecurityCommissionsApi - object-oriented interface
 * @export
 * @class SecurityCommissionsApi
 * @extends {BaseAPI}
 */
export class SecurityCommissionsApi extends BaseAPI {
    /**
     * 
     * @summary Attempt to auto-assign all missing security commission properties.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public autoAssignMissingProperties(options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).autoAssignMissingProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark the given security commission as deleted.
     * @param {SecurityCommissionsApiDeleteCommissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public deleteCommissions(requestParameters: SecurityCommissionsApiDeleteCommissionsRequest, options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).deleteCommissions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark all security commissions for the given uploadFilename as deleted.
     * @param {SecurityCommissionsApiDeleteCommissionsForFilenameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public deleteCommissionsForFilename(requestParameters: SecurityCommissionsApiDeleteCommissionsForFilenameRequest, options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).deleteCommissionsForFilename(requestParameters.uploadFilename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a security commission. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
     * @param {SecurityCommissionsApiGetSecurityCommissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public getSecurityCommissions(requestParameters: SecurityCommissionsApiGetSecurityCommissionsRequest, options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).getSecurityCommissions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of security commissions. Accountants (ROLE_ACCOUNTING) have access to all                      all security commissions; normal users only have access to security                      commissions attached to their respective subscriptions.
     * @param {SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public listAndSearchAllSecurityCommissions(requestParameters: SecurityCommissionsApiListAndSearchAllSecurityCommissionsRequest = {}, options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).listAndSearchAllSecurityCommissions(requestParameters.id, requestParameters.subscriptionId, requestParameters.internalTransactionId, requestParameters.startDate, requestParameters.endDate, requestParameters.withoutSubscription, requestParameters.withoutSecurity, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sum of all security commission for year X payed in year X+1.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public securityCommissionsSecurityCommissionsPayedInFollowingYear(options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).securityCommissionsSecurityCommissionsPayedInFollowingYear(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get taxation statistics based on the securityCommissionsForTaxation table. This table holds                      raw accounting data that supports taxation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public securityCommissionsTaxationStatistics(options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).securityCommissionsTaxationStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check for problems preventing correct security commission assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityCommissionsApi
     */
    public validateSecurityCommissions(options?: RawAxiosRequestConfig) {
        return SecurityCommissionsApiFp(this.configuration).validateSecurityCommissions(options).then((request) => request(this.axios, this.basePath));
    }
}

