import { UseQueryOptions } from "react-query";
import { RolesApiRolesIdRequest, Role } from "@generated/apiv1";

import { buildRolesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const rolesId = async (
  token: string | undefined,
  params: RolesApiRolesIdRequest
) => {
  const apiInstance = buildRolesApi(token);
  const { data } = await apiInstance.rolesId(params);
  return data;
};

export const useRolesId = (
  params: RolesApiRolesIdRequest,
  options?: UseQueryOptions<Role, AxiosApiError, Role>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ROLES_ROLES_ID, params],
    ({ token }) => rolesId(token, params),
    options
  );
};
