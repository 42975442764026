import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "@icons/warning.svg";
import { Banner, LoadingButton, Modal } from "@components";

export interface ConfirmDeleteDialogProps {
  isOpen: boolean;
  title: string;
  errorMessage?: string;
  onClose: () => void;
  onDelete: () => void;
  isDeleteRequestLoading?: boolean;
  text?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  children?: ReactNode;
}

export const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const {
    isOpen,
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    errorMessage,
    onClose,
    onDelete,
    isDeleteRequestLoading = false,
    children,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const noButtonText = cancelButtonText || t("navigation.deleteModal.no");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      height="auto"
      width={536}
      disablePortal={false}
    >
      <Box mb={11}>
        {errorMessage && (
          <Banner
            type="error"
            icon={<InfoIcon color={theme.palette.error.dark} />}
            data-testid="delete-error-alert"
            text={errorMessage}
            mb={4}
          />
        )}
        {typeof text === "string" || !text ? (
          <DialogContentText>
            {text || t("navigation.deleteModal.cannotBeUndone")}
          </DialogContentText>
        ) : (
          text
        )}
        {children && (
          <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="contained" onClick={onClose}>
          {noButtonText}
        </Button>
        <LoadingButton
          data-testid="delete-modal-delete-action-button"
          variant="contained"
          color="error"
          autoFocus
          onClick={onDelete}
          disabled={isDeleteRequestLoading}
          isLoading={isDeleteRequestLoading}
        >
          {confirmButtonText || t("navigation.deleteModal.yes")}
        </LoadingButton>
      </Box>
    </Modal>
  );
};
