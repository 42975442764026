import { UseMutationOptions } from "react-query";
import {
  QualityAssuranceApiCreateRandomVoucherRequest,
  TimeDependentProperty,
} from "@generated/apiv1";

import { buildQualityAssuranceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createRandomVoucher = async (
  token: string | undefined,
  params: QualityAssuranceApiCreateRandomVoucherRequest
) => {
  const apiInstance = buildQualityAssuranceApi(token);
  const { data } = await apiInstance.createRandomVoucher(params);
  return data;
};

export const useCreateRandomVoucher = (
  options?: UseMutationOptions<
    TimeDependentProperty,
    AxiosApiError,
    QualityAssuranceApiCreateRandomVoucherRequest
  >
) => {
  return useAuthMutation(createRandomVoucher, options, false);
};
