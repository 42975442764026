import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SidebarMenu } from "@components/common/layouts/AppSidebar/SidebarMenu/SidebarMenu";
import { MenuItem } from "@components/common/layouts/AppSidebar";
import { SharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as LineChartIcon } from "@icons/line-chart.svg";
import { ReactComponent as AnalyticsPie } from "@icons/analytics-pie-2.svg";
import { ReactComponent as HandEuro } from "@icons/hand-euro.svg";

type Props = {
  className?: string;
  collapsed?: boolean;
};

export const SharedAppSidebarMenu = ({ className, collapsed }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { publicId, isAnonymized, isForbidden } = useContext(
    SharedPortfolioContext
  );

  if (!publicId || isForbidden) {
    return null;
  }

  return (
    <SidebarMenu className={className} collapsed={collapsed}>
      <MenuItem
        to={`/portfolios/${publicId}`}
        name={t("sidebar.dashboard")}
        selected={pathname === `/portfolios/${publicId}`}
        before={<HomeIcon />}
      />
      <MenuItem
        to={`/portfolios/${publicId}/investments`}
        name={t("sidebar.investments")}
        selected={pathname === `/portfolios/${publicId}/investments`}
        before={<LineChartIcon />}
      />
      <MenuItem
        to={`/portfolios/${publicId}/classifications`}
        name={t("sidebar.analyse")}
        selected={pathname === `/portfolios/${publicId}/classifications`}
        before={<AnalyticsPie />}
      />
      {!isAnonymized && (
        <MenuItem
          to={`/portfolios/${publicId}/dividend-planner`}
          name={t("sidebar.dividends")}
          selected={pathname === `/portfolios/${publicId}/dividend-planner`}
          before={<HandEuro />}
        />
      )}
    </SidebarMenu>
  );
};
