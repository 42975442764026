/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateSharingPreferenceParams
 */
export interface CreateSharingPreferenceParams {
    /**
     * Name for the public portfolios
     * @type {string}
     * @memberof CreateSharingPreferenceParams
     */
    'name': string;
    /**
     * Visibility of the public portfolios. Can be one of \'public\' or \'private\'
     * @type {string}
     * @memberof CreateSharingPreferenceParams
     */
    'visibility': CreateSharingPreferenceParamsVisibilityEnum;
    /**
     * Description of the public portfolios
     * @type {string}
     * @memberof CreateSharingPreferenceParams
     */
    'description'?: string;
    /**
     * Anonymize data if true; Default is true
     * @type {boolean}
     * @memberof CreateSharingPreferenceParams
     */
    'isAnonymized'?: boolean;
}

export const CreateSharingPreferenceParamsVisibilityEnum = {
    PUBLIC: 'public',
    PRIVATE: 'private'
} as const;

export type CreateSharingPreferenceParamsVisibilityEnum = typeof CreateSharingPreferenceParamsVisibilityEnum[keyof typeof CreateSharingPreferenceParamsVisibilityEnum];


