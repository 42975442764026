import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiListAndSearchAllBankConnectionsV2Request,
  BankConnectionListWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllBankConnectionsV2 = async (
  token: string | undefined,
  params?: BankConnectionsApiListAndSearchAllBankConnectionsV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.listAndSearchAllBankConnectionsV2(params);
  return data;
};

export const useListAndSearchAllBankConnectionsV2 = (
  params?: BankConnectionsApiListAndSearchAllBankConnectionsV2Request,
  options?: UseQueryOptions<
    BankConnectionListWrapper,
    AxiosApiError,
    BankConnectionListWrapper
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS_V2,
      params,
    ],
    ({ token }) => listAndSearchAllBankConnectionsV2(token, params),
    options
  );
};
