import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { CreateOrUpdateTransactionParams } from "@generated/apiv1";
import { PageLayout } from "@components";
import { useAccounts } from "@api/v1/hooks/useAccounts";
import { useCreateTransaction } from "@api/v1/hooks/useTransactions";
import { convertDateToOnlyDateString } from "@helpers";
import { AddOrEditManuallyPageForm } from "./components/AddOrEditManuallyPageForm";

export const AddManuallyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useParams<"accountId">();
  const parsedAccountId = Number.parseInt(accountId!, 10);
  const { data: accountsResponse, isLoading: isAccountsLoading } = useAccounts({
    id: [parsedAccountId],
  });
  const {
    mutateAsync: saveCashTransaction,
    isLoading: isSavingLoading,
    error,
    reset,
  } = useCreateTransaction();

  const [account] = accountsResponse?.accounts ?? [];

  const handleSubmit = (values: CreateOrUpdateTransactionParams) => {
    saveCashTransaction(
      {
        body: {
          ...values,
          valueDate: convertDateToOnlyDateString(values.valueDate),
          accountId: parsedAccountId,
        },
      },
      {
        onSuccess: () => navigate(`/accounts/${accountId}`),
      }
    );
  };

  return (
    <PageLayout
      title={t("addManuallyPage.header.title")}
      subtitle={t("addManuallyPage.header.subtitle")}
      isLoading={isAccountsLoading}
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        {
          to: "/depots-accounts",
          text: t("depotsAccounts.title"),
        },
        {
          to: `/accounts/${accountId}`,
          text: truncate(account?.name),
        },
        {
          text: t("addManuallyPage.breadcrumbs.addManually"),
        },
      ]}
    >
      <AddOrEditManuallyPageForm
        isAccountsLoading={isAccountsLoading}
        isSavingLoading={isSavingLoading}
        errorMessage={error?.response?.data?.message}
        onReset={() => reset()}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  );
};
