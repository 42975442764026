import { useAuthQuery, loadDividendsPerYerForInvestment } from "@api";

export const useDividendsPerYearForInvestment = (investmentId?: number) => {
  return useAuthQuery(
    ["dividends-per-year", investmentId!],
    ({ token }) => loadDividendsPerYerForInvestment(token, investmentId!),
    {
      enabled: !!investmentId,
      refetchOnWindowFocus: false,
    }
  );
};
