import React from "react";
import { Box, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CreateIcon from "@mui/icons-material/Create";
import styled from "@emotion/styled";
import { TableSummary } from "@components/TableSummary";
import {
  BookingDataConsistencyStatusEnum,
  BookingTypeEnum,
  Investment,
  InvestmentTypeEnum,
} from "@generated/apiv1";
import { useRangeSelectControl } from "@hooks";
import { Table } from "@components/Table";
import { BookingsTableDesktopView } from "./BookingsTableDesktopView";

export interface TableBookingData {
  id: number;
  valueDate: string;
  bookingType: BookingTypeEnum;
  numberOfLots: number;
  securityPrice: number;
  priceChange: number | null;
  expenses: number;
  amount: number;
  grossGain: number | null;
  isExternalID: boolean;
  dataConsistencyStatus: BookingDataConsistencyStatusEnum;
  comment?: string;
  currency?: string;
  investmentId: number;
  investmentType: InvestmentTypeEnum;
  investmentTickerSymbol: string;
}

export interface BookingSingleInvestmentTableProps {
  bookings: TableBookingData[];
  className?: string;
  currencySymbol?: string;
  currentMarketValue: number;
  investment: Investment;
  rangeData: ReturnType<typeof useRangeSelectControl>;
}

interface TypographyColoredProps {
  lots: number;
}

export const TypographyColored = styled(Typography)<TypographyColoredProps>`
  color: ${({ theme, lots }) => {
    if (lots < 0) return theme.palette.text.negative;
    return theme.palette.text.positive;
  }};
`;

export const ValueDateWrapper = styled(Box)`
  display: inline-flex;
`;

export const LinkIconStyled = styled(LinkIcon)`
  display: inline-block;
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing()};
  font-size: 16px;
`;

export const CreateIconStyled = styled(CreateIcon)`
  display: inline-block;
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing()};
  font-size: 16px;
`;

export const BookingSingleInvestmentTable = ({
  className,
  rangeData,
  currencySymbol,
  currentMarketValue,
  investment,
  ...rest
}: BookingSingleInvestmentTableProps) => {
  const { intervalType, customIntervalSelect, selectInterval } = rangeData;

  return (
    <Box pt={6} data-testid="booking-table-container">
      <TableSummary
        summaryValue={currentMarketValue}
        currencySymbol={currencySymbol || "EUR"}
        intervalType={intervalType}
        onSelectInterval={selectInterval}
        investment={investment}
        onSelectCustomInterval={customIntervalSelect}
      />
      <Table className={className} data-testid="booking-table">
        <BookingsTableDesktopView
          investment={investment}
          currentMarketValue={currentMarketValue}
          currencySymbol={currencySymbol}
          {...rest}
        />
      </Table>
    </Box>
  );
};
