import { UseQueryOptions } from "react-query";
import {
  AccountsApiRemoteStatusRequest,
  RemoteAccountStatus,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const remoteStatus = async (
  token: string | undefined,
  params: AccountsApiRemoteStatusRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.remoteStatus(params);
  return data;
};

export const useRemoteStatus = (
  params: AccountsApiRemoteStatusRequest,
  options?: UseQueryOptions<
    RemoteAccountStatus,
    AxiosApiError,
    RemoteAccountStatus
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_REMOTE_STATUS, params],
    ({ token }) => remoteStatus(token, params),
    options
  );
};
