import { UseMutationOptions } from "react-query";
import {
  BookingsApiValidateManagedDepotBookingRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const validateManagedDepotBooking = async (
  token: string | undefined,
  params: BookingsApiValidateManagedDepotBookingRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.validateManagedDepotBooking(params);
  return data;
};

export const useValidateManagedDepotBooking = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiValidateManagedDepotBookingRequest
  >
) => {
  return useAuthMutation(validateManagedDepotBooking, options, false);
};
