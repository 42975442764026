import { UseMutationOptions } from "react-query";
import { TppWebFormsApiV2UpdateTppWebFormRequest } from "@generated/apiv1";

import { buildTppWebFormsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const v2UpdateTppWebForm = async (
  token: string | undefined,
  params: TppWebFormsApiV2UpdateTppWebFormRequest
) => {
  const apiInstance = buildTppWebFormsApi(token);
  const { data } = await apiInstance.v2UpdateTppWebForm(params);
  return data;
};

export const useV2UpdateTppWebForm = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TppWebFormsApiV2UpdateTppWebFormRequest
  >
) => {
  return useAuthMutation(v2UpdateTppWebForm, options, false);
};
