/* tslint:disable */
/* eslint-disable */
/**
 * Finanzfluss API
 * An API for the <a href=\"https://www.finanzfluss.de/\">Finanzfluss</a> backend. Work in progress.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@finanzfluss.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { RevenueCatUser } from '../model';
// @ts-ignore
import type { Stripe } from '../model';
/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of all active revenue cat subscriptions of the currently signed in user
         * @summary List all active revenue cat subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueCat: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/api/v1/subscriptions/revenueCat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all active stripe subscriptions of the currently signed in user
         * @summary List all active stripe subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/api/v1/subscriptions/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a list of all active revenue cat subscriptions of the currently signed in user
         * @summary List all active revenue cat subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revenueCat(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueCatUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revenueCat(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.revenueCat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a list of all active stripe subscriptions of the currently signed in user
         * @summary List all active stripe subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stripe>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.stripe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * Get a list of all active revenue cat subscriptions of the currently signed in user
         * @summary List all active revenue cat subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenueCat(options?: RawAxiosRequestConfig): AxiosPromise<RevenueCatUser> {
            return localVarFp.revenueCat(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of all active stripe subscriptions of the currently signed in user
         * @summary List all active stripe subscriptions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripe(options?: RawAxiosRequestConfig): AxiosPromise<Stripe> {
            return localVarFp.stripe(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * Get a list of all active revenue cat subscriptions of the currently signed in user
     * @summary List all active revenue cat subscriptions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public revenueCat(options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).revenueCat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of all active stripe subscriptions of the currently signed in user
     * @summary List all active stripe subscriptions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public stripe(options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).stripe(options).then((request) => request(this.axios, this.basePath));
    }
}

