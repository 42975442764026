/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Event } from '../model';
// @ts-ignore
import { StripeCheckoutSession } from '../model';
// @ts-ignore
import { StripeSetup } from '../model';
/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a checkout stripe session
         * @param {string} sessionId Stripe Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCheckoutSession: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('stripeCheckoutSession', 'sessionId', sessionId)
            const localVarPath = `/v1/stripe/checkoutSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a stripe checkout session
         * @param {StripeCheckoutSession} body Params for creating Stripe Checkout Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCreateCheckoutSession: async (body: StripeCheckoutSession, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stripeCreateCheckoutSession', 'body', body)
            const localVarPath = `/v1/stripe/createCheckoutSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a stripe customer portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCreateCustomerPortalSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stripe/createCustomerPortalSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stripe configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeSetup: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stripe/setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {Event} body The webhook event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhooks: async (body: Event, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stripeWebhooks', 'body', body)
            const localVarPath = `/v1/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a checkout stripe session
         * @param {string} sessionId Stripe Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeCheckoutSession(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeCheckoutSession(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeCheckoutSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a stripe checkout session
         * @param {StripeCheckoutSession} body Params for creating Stripe Checkout Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeCreateCheckoutSession(body: StripeCheckoutSession, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeCheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeCreateCheckoutSession(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeCreateCheckoutSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a stripe customer portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeCreateCustomerPortalSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeCheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeCreateCustomerPortalSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeCreateCustomerPortalSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Stripe configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeSetup(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSetup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeSetup(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {Event} body The webhook event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhooks(body: Event, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeWebhooks(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.stripeWebhooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a checkout stripe session
         * @param {StripeApiStripeCheckoutSessionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCheckoutSession(requestParameters: StripeApiStripeCheckoutSessionRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.stripeCheckoutSession(requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a stripe checkout session
         * @param {StripeApiStripeCreateCheckoutSessionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCreateCheckoutSession(requestParameters: StripeApiStripeCreateCheckoutSessionRequest, options?: RawAxiosRequestConfig): AxiosPromise<StripeCheckoutSession> {
            return localVarFp.stripeCreateCheckoutSession(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a stripe customer portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCreateCustomerPortalSession(options?: RawAxiosRequestConfig): AxiosPromise<StripeCheckoutSession> {
            return localVarFp.stripeCreateCustomerPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stripe configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeSetup(options?: RawAxiosRequestConfig): AxiosPromise<StripeSetup> {
            return localVarFp.stripeSetup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {StripeApiStripeWebhooksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhooks(requestParameters: StripeApiStripeWebhooksRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.stripeWebhooks(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stripeCheckoutSession operation in StripeApi.
 * @export
 * @interface StripeApiStripeCheckoutSessionRequest
 */
export interface StripeApiStripeCheckoutSessionRequest {
    /**
     * Stripe Session ID
     * @type {string}
     * @memberof StripeApiStripeCheckoutSession
     */
    readonly sessionId: string
}

/**
 * Request parameters for stripeCreateCheckoutSession operation in StripeApi.
 * @export
 * @interface StripeApiStripeCreateCheckoutSessionRequest
 */
export interface StripeApiStripeCreateCheckoutSessionRequest {
    /**
     * Params for creating Stripe Checkout Session
     * @type {StripeCheckoutSession}
     * @memberof StripeApiStripeCreateCheckoutSession
     */
    readonly body: StripeCheckoutSession
}

/**
 * Request parameters for stripeWebhooks operation in StripeApi.
 * @export
 * @interface StripeApiStripeWebhooksRequest
 */
export interface StripeApiStripeWebhooksRequest {
    /**
     * The webhook event
     * @type {Event}
     * @memberof StripeApiStripeWebhooks
     */
    readonly body: Event
}

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @summary Get a checkout stripe session
     * @param {StripeApiStripeCheckoutSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeCheckoutSession(requestParameters: StripeApiStripeCheckoutSessionRequest, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeCheckoutSession(requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a stripe checkout session
     * @param {StripeApiStripeCreateCheckoutSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeCreateCheckoutSession(requestParameters: StripeApiStripeCreateCheckoutSessionRequest, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeCreateCheckoutSession(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a stripe customer portal session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeCreateCustomerPortalSession(options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeCreateCustomerPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stripe configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeSetup(options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeSetup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stripe webhooks
     * @param {StripeApiStripeWebhooksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeWebhooks(requestParameters: StripeApiStripeWebhooksRequest, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeWebhooks(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

