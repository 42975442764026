import React from "react";
import { Box } from "@mui/material";
import { LoadingPlaceHolder } from "@components";
import { useInvestment } from "@api/v1/hooks/useInvestment";
import { useDividendsPerYearForInvestment } from "./hooks/useDividendsPerYearForInvestment";
import { SecurityFundamentalsByInvestmentId } from "./components/SecurityFundamentals/SecurityFundamentalsByInvestmentId";
import { FundFundamentalsByInvestmentId } from "./components/FundFundamentals/FundFundamentalsByInvestmentId";

export const FundamentalsPageByInvestmentId = ({
  investmentId,
}: {
  investmentId: number;
}) => {
  const { data: investment, isLoading: isInvestmentLoading } =
    useInvestment(investmentId);

  const isInvestmentFund =
    investment?.type === "21_fund" || investment?.type === "22_etf";
  const isInvestmentStock = investment?.type === "11_stock";

  const { data: dividends, isLoading: isDividendsLoading } =
    useDividendsPerYearForInvestment(investmentId);

  if (isDividendsLoading || isInvestmentLoading) {
    return (
      <Box pt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  const areDividendsPresent = dividends && dividends?.length > 0;

  if (!investment) {
    return <></>;
  }

  return isInvestmentFund ? (
    <FundFundamentalsByInvestmentId
      investmentId={investmentId}
      areDividendsPresent={areDividendsPresent}
      isin={investment.isin}
    />
  ) : (
    <SecurityFundamentalsByInvestmentId
      investmentId={investmentId}
      isInvestmentStock={isInvestmentStock}
      areDividendsPresent={areDividendsPresent}
    />
  );
};
