import React from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { SummaryStepMobileView } from "@features/transactionWizard/modal/steps/SummaryStep/SummaryStepMobileView";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { SummaryStepDesktop } from "@features/transactionWizard/modal/steps/SummaryStep/SummaryStepDesktop";

type Props = {
  handleNext: () => void;
  isLoading: boolean;
  context: TransactionWizardContext;
};

export const SummaryStep = (props: Props) => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return isLessSm ? (
    <SummaryStepMobileView {...props} />
  ) : (
    <SummaryStepDesktop {...props} />
  );
};
