import { withToken } from "@api/auth/withToken";
import { axiosInstanceV1 } from "@api/axiosInstance";
import { DividendList } from "../model";

export interface DividendsParams {
  /** Retrieve Dividends for the given account(s). */
  accountId?: number[];
  /** Retrieve Dividends for the given Investment(s) */
  investmentId?: number[];
  /** Retrieve Dividends for the given ISINS */
  isin?: string[];
  /** Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1. */
  taxRate?: number;
  /** Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format). */
  cutoffDate?: string;
  /** Only include investments with number of lots > 0; default is true */
  activeInvestmentsOnly?: boolean;
  /** Exclude dividends for investments that have been imported via banking API and for which                              transaction data is available; default is true. */
  excludeImported?: boolean;
}

export const loadDividends = async (
  token: string | undefined,
  params: DividendsParams
) => {
  const response = await axiosInstanceV1.get<DividendList>(`/dividends`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  return response.data;
};

export const getDividends = withToken(loadDividends);
