import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiPortfolioCorrelationRequest,
  PortfolioCorrelation,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const portfolioCorrelation = async (
  token: string | undefined,
  params?: RiskYieldMetricsApiPortfolioCorrelationRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.portfolioCorrelation(params);
  return data;
};

export const usePortfolioCorrelation = (
  params?: RiskYieldMetricsApiPortfolioCorrelationRequest,
  options?: UseQueryOptions<
    PortfolioCorrelation,
    AxiosApiError,
    PortfolioCorrelation
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_PORTFOLIO_CORRELATION, params],
    ({ token }) => portfolioCorrelation(token, params),
    options
  );
};
