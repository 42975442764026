import React from "react";
import { Typography } from "@mui/material";
import { useGetAccount } from "@api/v1/hooks/useAccounts";
import { goToAccount } from "@helpers/mainAppUrls";
import { SelectContraAccount, InProgressFullWidth } from "@components";
import { useCashAccounts } from "@api/v1/hooks/useCashAccounts";
import { useAssignContraAccount } from "../hooks/useAssignContraAccount";

interface Props {
  depotId: number;
}

export const AssignContraAccount = ({ depotId }: Props) => {
  const { data: depot, isLoading: isDepotLoading } = useGetAccount(depotId);
  const { mutateAsync: assignContraAccount, isLoading: assignmentInProgress } =
    useAssignContraAccount();

  const { data: accounts = [], isLoading: isAccountsLoading } = useCashAccounts(
    depot?.bankConnectionType || "FIN_API",
    {
      enabled: !!depot?.bankConnectionType,
    }
  );

  const handleFinish = async (contraAccountId?: number) => {
    if (!depot?.id || !contraAccountId) return;
    await assignContraAccount({
      id: depot.id,
      contraAccountId: contraAccountId,
    });
    goToAccount(depot.id);
  };

  if (!depot) return <InProgressFullWidth />;
  if (!depot.bankConnectionType)
    return (
      <Typography data-testid="no-bank-connection">
        Account must have bank connection
      </Typography>
    );

  return (
    <SelectContraAccount
      accounts={accounts}
      currentDepot={{
        iban: depot.iban,
        bankConnectionId: depot.bankConnectionId,
        bankConnectionType: depot.bankConnectionType,
        name: depot.name,
      }}
      idType="LOCAL"
      showNoAccountOption={false}
      onSubmit={handleFinish}
      isLoading={assignmentInProgress || isDepotLoading || isAccountsLoading}
      defaultId={depot.contraAccountId}
    />
  );
};
