/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddToProductWatchlistRequestBody } from '../model';
// @ts-ignore
import { CreateAnswerRequestBody } from '../model';
// @ts-ignore
import { CreateAnswerResponse } from '../model';
// @ts-ignore
import { ListProductsResponse } from '../model';
// @ts-ignore
import { QuestionsResponse } from '../model';
// @ts-ignore
import { RecommendProductsRequestBody } from '../model';
// @ts-ignore
import { RecommendProductsResponse } from '../model';
// @ts-ignore
import { ShowProductResponse } from '../model';
// @ts-ignore
import { ShowProductWatchlistResponse } from '../model';
/**
 * PlugAndPlayApi - axios parameter creator
 * @export
 */
export const PlugAndPlayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a product to the current user product watchlist
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {AddToProductWatchlistRequestBody} [addToProductWatchlistRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToProductWatchlist: async (sessionId?: string, addToProductWatchlistRequestBody?: AddToProductWatchlistRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/plug_and_play/product_watchlist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToProductWatchlistRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns questions
         * @param {string} [filterCategory] Filter the questions by their category
         * @param {string} [filterKey] Filter the questions by their key. Comma separated values. Example: &#x60;filter[key]&#x3D;KEY_1,KEY_2&#x60;
         * @param {GetQuestionsFilterAnswerStatusEnum} [filterAnswerStatus] Filter the questions by the status of the answer of the current user. Default: OPEN
         * @param {string} [include] Option to also include in the response the answer from the current user. Example: &#x60;include&#x3D;answer&#x60;
         * @param {string} [limit] Option to limit the amount of questions returned. Default: No Limit
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: async (filterCategory?: string, filterKey?: string, filterAnswerStatus?: GetQuestionsFilterAnswerStatusEnum, include?: string, limit?: string, sessionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/plug_and_play/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterCategory !== undefined) {
                localVarQueryParameter['filter[category]'] = filterCategory;
            }

            if (filterKey !== undefined) {
                localVarQueryParameter['filter[key]'] = filterKey;
            }

            if (filterAnswerStatus !== undefined) {
                localVarQueryParameter['filter[answer.status]'] = filterAnswerStatus;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of products. The list can be filtered by using the request parameters described below.
         * @param {string} [include] Option to include the full entity of a relationship.                              &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;, &#x60;quote&#x60;                              &lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing&#x60;
         * @param {string} [filterRiskClassComplex] Filter the list by product risk *complex* class
         * @param {string} [filterRiskClassLte] Filter the list by products with risk class *less than or equal* to the value
         * @param {string} [filterPerformanceClassComplex] Filter the list by the product performance *complex* class
         * @param {string} [filterPerformanceGte] Filter the list with products with a performance *greater than or equal* to the value                               over the given years in &#x60;filter[performance.years]&#x60;. This value should be given                               as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
         * @param {string} [filterRiskLte] Filter the list with products with a risk *less than or equal* to the value                                 over the given years in &#x60;filter[risk_years]&#x60;. This value should be given                                 as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
         * @param {string} [filterRiskYears] Number of years over which the risk is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a risk of 5% or less over 5 years,                               you would use: *filter[risk.years]&#x3D;5&amp;filter[risk.lte]&#x3D;0.05*
         * @param {string} [filterPerformanceYears] Number of years over which the performance is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a performance of 5%                               or less over 5 years, you would use: *filter[performance.years]&#x3D;5                               &amp;filter[performance.lte]&#x3D;0.05*
         * @param {string} [filterCategoryProductClass] Filter the list by the product class category
         * @param {string} [sort] Sort by a specific field. The field should be prefixed with a &#x60;-&#x60;                               to sort in descending order. &lt;br&gt; Supported: &#x60;risk&#x60;, &#x60;performance&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts: async (include?: string, filterRiskClassComplex?: string, filterRiskClassLte?: string, filterPerformanceClassComplex?: string, filterPerformanceGte?: string, filterRiskLte?: string, filterRiskYears?: string, filterPerformanceYears?: string, filterCategoryProductClass?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/plug_and_play/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (filterRiskClassComplex !== undefined) {
                localVarQueryParameter['filter[risk_class_complex]'] = filterRiskClassComplex;
            }

            if (filterRiskClassLte !== undefined) {
                localVarQueryParameter['filter[risk_class.lte]'] = filterRiskClassLte;
            }

            if (filterPerformanceClassComplex !== undefined) {
                localVarQueryParameter['filter[performance_class_complex]'] = filterPerformanceClassComplex;
            }

            if (filterPerformanceGte !== undefined) {
                localVarQueryParameter['filter[performance.gte]'] = filterPerformanceGte;
            }

            if (filterRiskLte !== undefined) {
                localVarQueryParameter['filter[risk.lte]'] = filterRiskLte;
            }

            if (filterRiskYears !== undefined) {
                localVarQueryParameter['filter[risk.years]'] = filterRiskYears;
            }

            if (filterPerformanceYears !== undefined) {
                localVarQueryParameter['filter[performance.years]'] = filterPerformanceYears;
            }

            if (filterCategoryProductClass !== undefined) {
                localVarQueryParameter['filter[category.product_class]'] = filterCategoryProductClass;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates an answer
         * @param {CreateAnswerRequestBody} createAnswerRequestBody 
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnswers: async (createAnswerRequestBody: CreateAnswerRequestBody, sessionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnswerRequestBody' is not null or undefined
            assertParamExists('postAnswers', 'createAnswerRequestBody', createAnswerRequestBody)
            const localVarPath = `/api/v3/plug_and_play/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnswerRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of recommended products. The recommendation is based on the user saved answers to the PlugAndPlay questionnaire. When the user is NOT authenticated, the answers should be submitted in the request body. When user IS authenticated, the request body is ignored.
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;product&#x60;, &#x60;product.risk_yield&#x60;,&#x60;product.pricing&#x60;,&#x60;product.pricing.bank_fee&#x60;, &#x60;product.quote&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;product, product.pricing&#x60;
         * @param {string} [filterProductCategoryProductClass] Filter the recommendations by the product class category
         * @param {string} [filterProductRiskClass] Filter the recommendations by products with that exact risk class value
         * @param {string} [filterProductRiskClassGte] Filter recommendations by products with risk class *greater than or equal* to the value
         * @param {string} [filterProductRiskClassLte] Filter recommendations by products with risk class *less than or equal* to the value
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {RecommendProductsRequestBody} [recommendProductsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendProducts: async (include?: string, filterProductCategoryProductClass?: string, filterProductRiskClass?: string, filterProductRiskClassGte?: string, filterProductRiskClassLte?: string, sessionId?: string, recommendProductsRequestBody?: RecommendProductsRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/plug_and_play/products/recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (filterProductCategoryProductClass !== undefined) {
                localVarQueryParameter['filter[product.category.product_class]'] = filterProductCategoryProductClass;
            }

            if (filterProductRiskClass !== undefined) {
                localVarQueryParameter['filter[product.risk_class]'] = filterProductRiskClass;
            }

            if (filterProductRiskClassGte !== undefined) {
                localVarQueryParameter['filter[product.risk_class.gte]'] = filterProductRiskClassGte;
            }

            if (filterProductRiskClassLte !== undefined) {
                localVarQueryParameter['filter[product.risk_class.lte]'] = filterProductRiskClassLte;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recommendProductsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a product from the current user product watchlist
         * @param {string} productId 
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromWatchlist: async (productId: string, sessionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('removeProductFromWatchlist', 'productId', productId)
            const localVarPath = `/api/v3/plug_and_play/product_watchlist/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the product given by id
         * @param {string} id Product Id
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing,pricing.bank_fee&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProduct: async (id: string, include?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showProduct', 'id', id)
            const localVarPath = `/api/v3/plug_and_play/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shows the current user product watchlist
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;products&#x60;
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProductWatchlist: async (include?: string, sessionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/plug_and_play/product_watchlist/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlugAndPlayApi - functional programming interface
 * @export
 */
export const PlugAndPlayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlugAndPlayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a product to the current user product watchlist
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {AddToProductWatchlistRequestBody} [addToProductWatchlistRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToProductWatchlist(sessionId?: string, addToProductWatchlistRequestBody?: AddToProductWatchlistRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToProductWatchlist(sessionId, addToProductWatchlistRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.addToProductWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns questions
         * @param {string} [filterCategory] Filter the questions by their category
         * @param {string} [filterKey] Filter the questions by their key. Comma separated values. Example: &#x60;filter[key]&#x3D;KEY_1,KEY_2&#x60;
         * @param {GetQuestionsFilterAnswerStatusEnum} [filterAnswerStatus] Filter the questions by the status of the answer of the current user. Default: OPEN
         * @param {string} [include] Option to also include in the response the answer from the current user. Example: &#x60;include&#x3D;answer&#x60;
         * @param {string} [limit] Option to limit the amount of questions returned. Default: No Limit
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestions(filterCategory?: string, filterKey?: string, filterAnswerStatus?: GetQuestionsFilterAnswerStatusEnum, include?: string, limit?: string, sessionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestions(filterCategory, filterKey, filterAnswerStatus, include, limit, sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.getQuestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of products. The list can be filtered by using the request parameters described below.
         * @param {string} [include] Option to include the full entity of a relationship.                              &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;, &#x60;quote&#x60;                              &lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing&#x60;
         * @param {string} [filterRiskClassComplex] Filter the list by product risk *complex* class
         * @param {string} [filterRiskClassLte] Filter the list by products with risk class *less than or equal* to the value
         * @param {string} [filterPerformanceClassComplex] Filter the list by the product performance *complex* class
         * @param {string} [filterPerformanceGte] Filter the list with products with a performance *greater than or equal* to the value                               over the given years in &#x60;filter[performance.years]&#x60;. This value should be given                               as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
         * @param {string} [filterRiskLte] Filter the list with products with a risk *less than or equal* to the value                                 over the given years in &#x60;filter[risk_years]&#x60;. This value should be given                                 as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
         * @param {string} [filterRiskYears] Number of years over which the risk is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a risk of 5% or less over 5 years,                               you would use: *filter[risk.years]&#x3D;5&amp;filter[risk.lte]&#x3D;0.05*
         * @param {string} [filterPerformanceYears] Number of years over which the performance is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a performance of 5%                               or less over 5 years, you would use: *filter[performance.years]&#x3D;5                               &amp;filter[performance.lte]&#x3D;0.05*
         * @param {string} [filterCategoryProductClass] Filter the list by the product class category
         * @param {string} [sort] Sort by a specific field. The field should be prefixed with a &#x60;-&#x60;                               to sort in descending order. &lt;br&gt; Supported: &#x60;risk&#x60;, &#x60;performance&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProducts(include?: string, filterRiskClassComplex?: string, filterRiskClassLte?: string, filterPerformanceClassComplex?: string, filterPerformanceGte?: string, filterRiskLte?: string, filterRiskYears?: string, filterPerformanceYears?: string, filterCategoryProductClass?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProducts(include, filterRiskClassComplex, filterRiskClassLte, filterPerformanceClassComplex, filterPerformanceGte, filterRiskLte, filterRiskYears, filterPerformanceYears, filterCategoryProductClass, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.listProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary creates an answer
         * @param {CreateAnswerRequestBody} createAnswerRequestBody 
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAnswers(createAnswerRequestBody: CreateAnswerRequestBody, sessionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAnswerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAnswers(createAnswerRequestBody, sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.postAnswers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of recommended products. The recommendation is based on the user saved answers to the PlugAndPlay questionnaire. When the user is NOT authenticated, the answers should be submitted in the request body. When user IS authenticated, the request body is ignored.
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;product&#x60;, &#x60;product.risk_yield&#x60;,&#x60;product.pricing&#x60;,&#x60;product.pricing.bank_fee&#x60;, &#x60;product.quote&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;product, product.pricing&#x60;
         * @param {string} [filterProductCategoryProductClass] Filter the recommendations by the product class category
         * @param {string} [filterProductRiskClass] Filter the recommendations by products with that exact risk class value
         * @param {string} [filterProductRiskClassGte] Filter recommendations by products with risk class *greater than or equal* to the value
         * @param {string} [filterProductRiskClassLte] Filter recommendations by products with risk class *less than or equal* to the value
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {RecommendProductsRequestBody} [recommendProductsRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recommendProducts(include?: string, filterProductCategoryProductClass?: string, filterProductRiskClass?: string, filterProductRiskClassGte?: string, filterProductRiskClassLte?: string, sessionId?: string, recommendProductsRequestBody?: RecommendProductsRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recommendProducts(include, filterProductCategoryProductClass, filterProductRiskClass, filterProductRiskClassGte, filterProductRiskClassLte, sessionId, recommendProductsRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.recommendProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes a product from the current user product watchlist
         * @param {string} productId 
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProductFromWatchlist(productId: string, sessionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProductFromWatchlist(productId, sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.removeProductFromWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the product given by id
         * @param {string} id Product Id
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing,pricing.bank_fee&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showProduct(id: string, include?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showProduct(id, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.showProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Shows the current user product watchlist
         * @param {string} [include] Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;products&#x60;
         * @param {string} [sessionId] Session ID representing an anonymous user. Required when user is not authenticated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showProductWatchlist(include?: string, sessionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowProductWatchlistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showProductWatchlist(include, sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlugAndPlayApi.showProductWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlugAndPlayApi - factory interface
 * @export
 */
export const PlugAndPlayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlugAndPlayApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a product to the current user product watchlist
         * @param {PlugAndPlayApiAddToProductWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToProductWatchlist(requestParameters: PlugAndPlayApiAddToProductWatchlistRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addToProductWatchlist(requestParameters.sessionId, requestParameters.addToProductWatchlistRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns questions
         * @param {PlugAndPlayApiGetQuestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions(requestParameters: PlugAndPlayApiGetQuestionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<QuestionsResponse> {
            return localVarFp.getQuestions(requestParameters.filterCategory, requestParameters.filterKey, requestParameters.filterAnswerStatus, requestParameters.include, requestParameters.limit, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of products. The list can be filtered by using the request parameters described below.
         * @param {PlugAndPlayApiListProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(requestParameters: PlugAndPlayApiListProductsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListProductsResponse> {
            return localVarFp.listProducts(requestParameters.include, requestParameters.filterRiskClassComplex, requestParameters.filterRiskClassLte, requestParameters.filterPerformanceClassComplex, requestParameters.filterPerformanceGte, requestParameters.filterRiskLte, requestParameters.filterRiskYears, requestParameters.filterPerformanceYears, requestParameters.filterCategoryProductClass, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates an answer
         * @param {PlugAndPlayApiPostAnswersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnswers(requestParameters: PlugAndPlayApiPostAnswersRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAnswerResponse> {
            return localVarFp.postAnswers(requestParameters.createAnswerRequestBody, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of recommended products. The recommendation is based on the user saved answers to the PlugAndPlay questionnaire. When the user is NOT authenticated, the answers should be submitted in the request body. When user IS authenticated, the request body is ignored.
         * @param {PlugAndPlayApiRecommendProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendProducts(requestParameters: PlugAndPlayApiRecommendProductsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RecommendProductsResponse> {
            return localVarFp.recommendProducts(requestParameters.include, requestParameters.filterProductCategoryProductClass, requestParameters.filterProductRiskClass, requestParameters.filterProductRiskClassGte, requestParameters.filterProductRiskClassLte, requestParameters.sessionId, requestParameters.recommendProductsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a product from the current user product watchlist
         * @param {PlugAndPlayApiRemoveProductFromWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromWatchlist(requestParameters: PlugAndPlayApiRemoveProductFromWatchlistRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeProductFromWatchlist(requestParameters.productId, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the product given by id
         * @param {PlugAndPlayApiShowProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProduct(requestParameters: PlugAndPlayApiShowProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<ShowProductResponse> {
            return localVarFp.showProduct(requestParameters.id, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shows the current user product watchlist
         * @param {PlugAndPlayApiShowProductWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProductWatchlist(requestParameters: PlugAndPlayApiShowProductWatchlistRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ShowProductWatchlistResponse> {
            return localVarFp.showProductWatchlist(requestParameters.include, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addToProductWatchlist operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiAddToProductWatchlistRequest
 */
export interface PlugAndPlayApiAddToProductWatchlistRequest {
    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiAddToProductWatchlist
     */
    readonly sessionId?: string

    /**
     * 
     * @type {AddToProductWatchlistRequestBody}
     * @memberof PlugAndPlayApiAddToProductWatchlist
     */
    readonly addToProductWatchlistRequestBody?: AddToProductWatchlistRequestBody
}

/**
 * Request parameters for getQuestions operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiGetQuestionsRequest
 */
export interface PlugAndPlayApiGetQuestionsRequest {
    /**
     * Filter the questions by their category
     * @type {string}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly filterCategory?: string

    /**
     * Filter the questions by their key. Comma separated values. Example: &#x60;filter[key]&#x3D;KEY_1,KEY_2&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly filterKey?: string

    /**
     * Filter the questions by the status of the answer of the current user. Default: OPEN
     * @type {'OPEN' | 'ANSWERED'}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly filterAnswerStatus?: GetQuestionsFilterAnswerStatusEnum

    /**
     * Option to also include in the response the answer from the current user. Example: &#x60;include&#x3D;answer&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly include?: string

    /**
     * Option to limit the amount of questions returned. Default: No Limit
     * @type {string}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly limit?: string

    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiGetQuestions
     */
    readonly sessionId?: string
}

/**
 * Request parameters for listProducts operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiListProductsRequest
 */
export interface PlugAndPlayApiListProductsRequest {
    /**
     * Option to include the full entity of a relationship.                              &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;, &#x60;quote&#x60;                              &lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly include?: string

    /**
     * Filter the list by product risk *complex* class
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterRiskClassComplex?: string

    /**
     * Filter the list by products with risk class *less than or equal* to the value
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterRiskClassLte?: string

    /**
     * Filter the list by the product performance *complex* class
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterPerformanceClassComplex?: string

    /**
     * Filter the list with products with a performance *greater than or equal* to the value                               over the given years in &#x60;filter[performance.years]&#x60;. This value should be given                               as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterPerformanceGte?: string

    /**
     * Filter the list with products with a risk *less than or equal* to the value                                 over the given years in &#x60;filter[risk_years]&#x60;. This value should be given                                 as a decimal number, not as a percentage [0.1 &#x3D; 10%] and should be annualized.
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterRiskLte?: string

    /**
     * Number of years over which the risk is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a risk of 5% or less over 5 years,                               you would use: *filter[risk.years]&#x3D;5&amp;filter[risk.lte]&#x3D;0.05*
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterRiskYears?: string

    /**
     * Number of years over which the performance is calculated. Default is 3 years.                               Supported values: 1, 2, 3, 5, 10 years.                               &lt;br&gt; For example: If you want to filter products with a performance of 5%                               or less over 5 years, you would use: *filter[performance.years]&#x3D;5                               &amp;filter[performance.lte]&#x3D;0.05*
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterPerformanceYears?: string

    /**
     * Filter the list by the product class category
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly filterCategoryProductClass?: string

    /**
     * Sort by a specific field. The field should be prefixed with a &#x60;-&#x60;                               to sort in descending order. &lt;br&gt; Supported: &#x60;risk&#x60;, &#x60;performance&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiListProducts
     */
    readonly sort?: string
}

/**
 * Request parameters for postAnswers operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiPostAnswersRequest
 */
export interface PlugAndPlayApiPostAnswersRequest {
    /**
     * 
     * @type {CreateAnswerRequestBody}
     * @memberof PlugAndPlayApiPostAnswers
     */
    readonly createAnswerRequestBody: CreateAnswerRequestBody

    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiPostAnswers
     */
    readonly sessionId?: string
}

/**
 * Request parameters for recommendProducts operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiRecommendProductsRequest
 */
export interface PlugAndPlayApiRecommendProductsRequest {
    /**
     * Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;product&#x60;, &#x60;product.risk_yield&#x60;,&#x60;product.pricing&#x60;,&#x60;product.pricing.bank_fee&#x60;, &#x60;product.quote&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;product, product.pricing&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly include?: string

    /**
     * Filter the recommendations by the product class category
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly filterProductCategoryProductClass?: string

    /**
     * Filter the recommendations by products with that exact risk class value
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly filterProductRiskClass?: string

    /**
     * Filter recommendations by products with risk class *greater than or equal* to the value
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly filterProductRiskClassGte?: string

    /**
     * Filter recommendations by products with risk class *less than or equal* to the value
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly filterProductRiskClassLte?: string

    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly sessionId?: string

    /**
     * 
     * @type {RecommendProductsRequestBody}
     * @memberof PlugAndPlayApiRecommendProducts
     */
    readonly recommendProductsRequestBody?: RecommendProductsRequestBody
}

/**
 * Request parameters for removeProductFromWatchlist operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiRemoveProductFromWatchlistRequest
 */
export interface PlugAndPlayApiRemoveProductFromWatchlistRequest {
    /**
     * 
     * @type {string}
     * @memberof PlugAndPlayApiRemoveProductFromWatchlist
     */
    readonly productId: string

    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiRemoveProductFromWatchlist
     */
    readonly sessionId?: string
}

/**
 * Request parameters for showProduct operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiShowProductRequest
 */
export interface PlugAndPlayApiShowProductRequest {
    /**
     * Product Id
     * @type {string}
     * @memberof PlugAndPlayApiShowProduct
     */
    readonly id: string

    /**
     * Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;risk_yield&#x60;, &#x60;pricing&#x60;,&#x60;pricing.bank_fee&#x60;&lt;br&gt;&lt;br&gt; Example: &#x60;include&#x3D;pricing,pricing.bank_fee&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiShowProduct
     */
    readonly include?: string
}

/**
 * Request parameters for showProductWatchlist operation in PlugAndPlayApi.
 * @export
 * @interface PlugAndPlayApiShowProductWatchlistRequest
 */
export interface PlugAndPlayApiShowProductWatchlistRequest {
    /**
     * Option to also include the full entity of a relationship. &lt;br&gt; Supported: &#x60;products&#x60;
     * @type {string}
     * @memberof PlugAndPlayApiShowProductWatchlist
     */
    readonly include?: string

    /**
     * Session ID representing an anonymous user. Required when user is not authenticated.
     * @type {string}
     * @memberof PlugAndPlayApiShowProductWatchlist
     */
    readonly sessionId?: string
}

/**
 * PlugAndPlayApi - object-oriented interface
 * @export
 * @class PlugAndPlayApi
 * @extends {BaseAPI}
 */
export class PlugAndPlayApi extends BaseAPI {
    /**
     * 
     * @summary Adds a product to the current user product watchlist
     * @param {PlugAndPlayApiAddToProductWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public addToProductWatchlist(requestParameters: PlugAndPlayApiAddToProductWatchlistRequest = {}, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).addToProductWatchlist(requestParameters.sessionId, requestParameters.addToProductWatchlistRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns questions
     * @param {PlugAndPlayApiGetQuestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public getQuestions(requestParameters: PlugAndPlayApiGetQuestionsRequest = {}, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).getQuestions(requestParameters.filterCategory, requestParameters.filterKey, requestParameters.filterAnswerStatus, requestParameters.include, requestParameters.limit, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of products. The list can be filtered by using the request parameters described below.
     * @param {PlugAndPlayApiListProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public listProducts(requestParameters: PlugAndPlayApiListProductsRequest = {}, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).listProducts(requestParameters.include, requestParameters.filterRiskClassComplex, requestParameters.filterRiskClassLte, requestParameters.filterPerformanceClassComplex, requestParameters.filterPerformanceGte, requestParameters.filterRiskLte, requestParameters.filterRiskYears, requestParameters.filterPerformanceYears, requestParameters.filterCategoryProductClass, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates an answer
     * @param {PlugAndPlayApiPostAnswersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public postAnswers(requestParameters: PlugAndPlayApiPostAnswersRequest, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).postAnswers(requestParameters.createAnswerRequestBody, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of recommended products. The recommendation is based on the user saved answers to the PlugAndPlay questionnaire. When the user is NOT authenticated, the answers should be submitted in the request body. When user IS authenticated, the request body is ignored.
     * @param {PlugAndPlayApiRecommendProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public recommendProducts(requestParameters: PlugAndPlayApiRecommendProductsRequest = {}, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).recommendProducts(requestParameters.include, requestParameters.filterProductCategoryProductClass, requestParameters.filterProductRiskClass, requestParameters.filterProductRiskClassGte, requestParameters.filterProductRiskClassLte, requestParameters.sessionId, requestParameters.recommendProductsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a product from the current user product watchlist
     * @param {PlugAndPlayApiRemoveProductFromWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public removeProductFromWatchlist(requestParameters: PlugAndPlayApiRemoveProductFromWatchlistRequest, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).removeProductFromWatchlist(requestParameters.productId, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the product given by id
     * @param {PlugAndPlayApiShowProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public showProduct(requestParameters: PlugAndPlayApiShowProductRequest, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).showProduct(requestParameters.id, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shows the current user product watchlist
     * @param {PlugAndPlayApiShowProductWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlugAndPlayApi
     */
    public showProductWatchlist(requestParameters: PlugAndPlayApiShowProductWatchlistRequest = {}, options?: RawAxiosRequestConfig) {
        return PlugAndPlayApiFp(this.configuration).showProductWatchlist(requestParameters.include, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetQuestionsFilterAnswerStatusEnum = {
    OPEN: 'OPEN',
    ANSWERED: 'ANSWERED'
} as const;
export type GetQuestionsFilterAnswerStatusEnum = typeof GetQuestionsFilterAnswerStatusEnum[keyof typeof GetQuestionsFilterAnswerStatusEnum];
