import React, { useMemo, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import {
  useCreateAccount,
  useListAndSearchAllAccounts,
} from "@generated/apiv1/hooks";
import { Modal } from "@components/Modal";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { SecurityImage } from "@components/SecurityImage";
import { CreateAndEditAccountForm } from "@features/accounts/edit/components/CreateAndEditAccountForm";
import {
  CreateOrUpdateAccountParams,
  CreateOrUpdateAccountParamsTypeEnum,
} from "@generated/apiv1";
import { LoadingButton } from "@components/LoadingButton";
import { Banner } from "@components/Banner";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";

const renderValue = (
  value: number,
  options: DepotSelectItem[],
  placeholder: string
) => {
  if (!value) {
    return (
      <Typography variant="body1" color="textSecondary" fontWeight={400}>
        {placeholder}
      </Typography>
    );
  }
  const foundOption = options.find((option) => option.value === value);
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {foundOption && (
        <SecurityImage
          src={foundOption.image}
          alt={foundOption.name}
          width={20}
          height={20}
        />
      )}
      {foundOption?.name || placeholder}
    </Box>
  );
};

type DepotSelectItem = {
  name: string;
  value: number;
  image?: string;
};

type Props = {
  allowNewDepot?: boolean;
  selectedDepotId?: number;
  selectableDepots?: DepotSelectItem[];
  isLoading?: boolean;
  type?: CreateOrUpdateAccountParamsTypeEnum;
  onDepotChange: (depotId: number | null) => void;
  defaultSubcategory?: AccountSubcategoryEnum;
} & SelectProps;

export const DepotSelect = ({
  selectedDepotId,
  selectableDepots,
  isLoading,
  allowNewDepot = true,
  onDepotChange,
  type,
  defaultSubcategory,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [depotId, setDepotId] = useState<number | string>(
    selectedDepotId ?? ""
  );
  const [accountValues, setAccountValues] =
    useState<CreateOrUpdateAccountParams>({
      name: "",
      type: type || "01_depot",
      subCategoryId: 1,
      enableImplicitTransactions: Boolean(type === "01_depot"),
    });
  const queryClient = useQueryClient();

  const { mutate: createNewDepot, isLoading: isSaving } = useCreateAccount();

  const { data: allDepots, isLoading: areAccountsLoading } =
    useListAndSearchAllAccounts(
      {
        type: ["01_depot"],
      },
      {
        enabled: !selectableDepots || selectableDepots.length === 0,
      }
    );

  const depots = useMemo(
    () =>
      selectableDepots ||
      allDepots?.accounts?.map((account) => ({
        name: account.name,
        value: account.id,
        image: account.iconUrl,
      })) ||
      [],
    [allDepots, selectableDepots]
  );

  const handleDepotIdChange = (id: number | null) => {
    setDepotId(id === null ? "" : id);
    onDepotChange(id);
  };

  const handleSaveNewDepot = () => {
    setError(undefined);
    createNewDepot(
      {
        body: accountValues,
      },
      {
        onSuccess: async (data) => {
          await queryClient.refetchQueries({
            queryKey: [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS],
          });
          setIsModalOpen(false);
          handleDepotIdChange(data.id);

          setAccountValues({
            name: "",
            type: type || "01_depot",
            enableImplicitTransactions: Boolean(type === "01_depot"),
          });
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const depotsWithCorrectIcons = useMemo(() => {
    return depots.map((depot) => {
      const noIcon =
        depot.image?.includes("DEPOT_square") ||
        depot.image?.includes("CHECKING_square") ||
        depot.image?.includes("MATERIAL_ASSETS_square");

      return {
        ...depot,
        image: noIcon ? undefined : depot.image,
      };
    });
  }, [depots]);

  const handleUpdateAccountValues = (
    params: Partial<CreateOrUpdateAccountParams>
  ) => {
    setAccountValues((values) => {
      return { ...values, ...params };
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      alignItems="flex-end"
      gap={3}
    >
      <FormControl fullWidth={true}>
        <InputLabel>{t("components.depotSelect.label")}</InputLabel>
        <Select
          data-testid="select-depot"
          displayEmpty
          value={depotId}
          MenuProps={{
            sx: {
              maxHeight: 300,

              "& .MuiMenuItem-root": {
                color: (theme) => theme.palette.text.secondary,
              },
            },
          }}
          inputProps={{ "data-testid": "select-depot-input" }}
          renderValue={(value) =>
            renderValue(
              value as number,
              depotsWithCorrectIcons,
              t("components.depotSelect.placeholder")
            )
          }
          {...rest}
          onChange={(event) => {
            if (event.target.value === "new") return;
            handleDepotIdChange(parseInt(event.target.value as string));
          }}
          sx={{
            "& .MuiPaper-root": {
              maxHeight: 300,
            },
          }}
        >
          {allowNewDepot && (
            <MenuItem
              value="new"
              onClick={() => setIsModalOpen(true)}
              sx={{
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.border.secondary} !important`,
              }}
            >
              <PlusIcon width={20} height={20} />
              {t("components.depotSelect.new")}
            </MenuItem>
          )}
          {!depots.length &&
            !isLoading &&
            !areAccountsLoading &&
            !allowNewDepot && (
              <MenuItem aria-label="None" value="" disabled>
                {t("form.noFound")}
              </MenuItem>
            )}
          {(isLoading || areAccountsLoading) && !depots.length && (
            <MenuItem aria-label="Loading" value="" disabled>
              {t("form.loading")}
            </MenuItem>
          )}
          {depotsWithCorrectIcons.map((depot, ix) => {
            return (
              <MenuItem
                key={depot.value}
                data-testid={`select-depot-${ix}`}
                value={depot.value}
              >
                <SecurityImage
                  src={depot.image}
                  alt={depot.name}
                  width={20}
                  height={20}
                />
                {depot.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Modal
        height="auto"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={t("components.depotSelect.modal.title")}
      >
        <CreateAndEditAccountForm
          accountValues={accountValues}
          onUpdateAccountValues={handleUpdateAccountValues}
          type={type}
          bankSearchHeight={200}
          defaultSubcategory={defaultSubcategory}
        />
        {error && <Banner type="error" text={error} />}
        <Box display="flex" justifyContent="flex-end" mt={6}>
          <LoadingButton
            isLoading={isSaving}
            onClick={handleSaveNewDepot}
            disabled={!accountValues.name}
          >
            {t("components.depotSelect.modal.save")}
          </LoadingButton>
        </Box>
      </Modal>
    </Box>
  );
};
