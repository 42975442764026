import { UseMutationOptions } from "react-query";
import {
  RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest,
  DepotSynchronizationLog,
} from "@generated/apiv1";

import { buildRemoteDepotsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateRemoteDepotViaTppWebForm = async (
  token: string | undefined,
  params: RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest
) => {
  const apiInstance = buildRemoteDepotsApi(token);
  const { data } = await apiInstance.updateRemoteDepotViaTppWebForm(params);
  return data;
};

export const useUpdateRemoteDepotViaTppWebForm = (
  options?: UseMutationOptions<
    DepotSynchronizationLog,
    AxiosApiError,
    RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest
  >
) => {
  return useAuthMutation(updateRemoteDepotViaTppWebForm, options, false);
};
