import React from "react";
import { MenuItem, Select, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const durations = [
  "15mins",
  "30mins",
  "1h",
  "3h",
  "6h",
  "12h",
  "24h",
  "1W",
  "1M",
] as const;

export type LogoutDuration = (typeof durations)[number];

export const LogoutDurationSelector = ({
  selected,
  setSelected,
}: {
  selected: LogoutDuration;
  setSelected: (duration: LogoutDuration) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Select
      label={t("settingsPage.security.logout.logoutPeriod")}
      data-testid="duration-selector"
      value={selected}
      onChange={(event) => setSelected(event.target.value as LogoutDuration)}
      fullWidth
      sx={{ marginBottom: theme.spacing(4) }}
    >
      {durations.map((x) => (
        <MenuItem
          value={x}
          key={x}
          data-testid={`duration-option-${x}`}
          onClick={() => setSelected(x as LogoutDuration)}
        >
          {t(`settingsPage.security.logout.durations.${x}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
