import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetInvestments } from "@api/v1/hooks/useInvestments";
import { Modal } from "@components";
import { RegionSelectorWithInvestmentUpdate } from "./RegionSelectorWithInvestmentUpdate";

const FieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  paddingRight: theme.spacing(2),
  gap: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

type Props = {
  accountIds?: number[];
  isOpen: boolean;
  onClose: () => void;
};

export const AssignRegionToInvestment = ({
  accountIds,
  isOpen,
  onClose,
}: Props) => {
  const { data, isLoading } = useGetInvestments({ accountId: accountIds });
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={t("classification.assignRegionToInvestment")}
      testId="assign-region-to-investment-modal"
    >
      <Box
        sx={{
          overflowY: "auto",
        }}
      >
        {(data?.investments || []).map((investment) => (
          <FieldContainer key={investment.id}>
            <Typography variant="body1" fontWeight="bold">
              {investment.name}
            </Typography>
            <RegionSelectorWithInvestmentUpdate
              investmentId={investment.id}
              region={investment.region}
            />
          </FieldContainer>
        ))}
      </Box>
    </Modal>
  );
};
