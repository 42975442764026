/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CsvRow } from './csv-row';

/**
 * 
 * @export
 * @interface CsvImportPerformAttributes
 */
export interface CsvImportPerformAttributes {
    /**
     * Array of CSV rows
     * @type {Array<CsvRow>}
     * @memberof CsvImportPerformAttributes
     */
    'records'?: Array<CsvRow>;
    /**
     * Depot ID
     * @type {string}
     * @memberof CsvImportPerformAttributes
     */
    'depot_id'?: string;
    /**
     * Name of the file
     * @type {string}
     * @memberof CsvImportPerformAttributes
     */
    'bank_slug'?: CsvImportPerformAttributesBankSlugEnum;
}

export const CsvImportPerformAttributesBankSlugEnum = {
    PARQET: 'Parqet',
    SCALABLE_CAPITAL: 'ScalableCapital',
    EXTRA_ETF: 'extra_etf',
    PORTFOLIO_PERFORMANCE_SECURITIES: 'portfolio_performance_securities',
    PORTFOLIO_PERFORMANCE_CASH: 'portfolio_performance_cash',
    GENERIC: 'generic'
} as const;

export type CsvImportPerformAttributesBankSlugEnum = typeof CsvImportPerformAttributesBankSlugEnum[keyof typeof CsvImportPerformAttributesBankSlugEnum];


