import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { Modal } from "@components";
import { useListAndSearchAllInvestments } from "@generated/apiv1/hooks";
import { TagSelectorWithInvestmentUpdate } from "./TagSelectorWithInvestmentUpdate";

const FieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  paddingRight: theme.spacing(2),
  gap: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

type Props = {
  accountIds?: number[];
  isOpen: boolean;
  onClose: () => void;
};

export const AssignTagToInvestment = ({
  accountIds,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useListAndSearchAllInvestments(
    { accountId: accountIds },
    {
      enabled: isOpen,
    }
  );

  const tags = uniq(
    data?.investments?.map(({ tag }) => tag?.value).filter((t) => !!t)
  ) as string[];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={t("analysis.classifications.assignTagToInvestment")}
      testId="assign-tag-to-investment-modal"
    >
      <Box
        sx={{
          overflowY: "auto",
        }}
      >
        {(data?.investments || []).map((investment) => (
          <FieldContainer key={investment.id}>
            <Typography variant="body1">
              {investment.standardisedName}
            </Typography>
            <TagSelectorWithInvestmentUpdate
              investmentId={investment.id}
              tag={investment.tag?.value}
              tags={tags}
            />
          </FieldContainer>
        ))}
      </Box>
    </Modal>
  );
};
