/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddOrUpdateBankConnectionFromTppWebFormParams } from '../model';
// @ts-ignore
import { AddOrUpdateBankConnectionParams } from '../model';
// @ts-ignore
import { AddOrUpdateBankConnectionResult } from '../model';
// @ts-ignore
import { BankConnectionLEventWrapper } from '../model';
// @ts-ignore
import { BankConnectionListWrapper } from '../model';
// @ts-ignore
import { BankConnectionWrapper } from '../model';
// @ts-ignore
import { BankSynchronizationProcess } from '../model';
// @ts-ignore
import { EditBankConnectionParams } from '../model';
// @ts-ignore
import { SynchronizationBatchJob } from '../model';
/**
 * BankConnectionsApi - axios parameter creator
 * @export
 */
export const BankConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankConnection: async (body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addBankConnection', 'body', body)
            const localVarPath = `/v1/bankConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankConnectionV2: async (body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addBankConnectionV2', 'body', body)
            const localVarPath = `/v2/bankConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {string} processId The bank connection import or update process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionUpdateProcessV2: async (processId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('bankConnectionUpdateProcessV2', 'processId', processId)
            const localVarPath = `/v2/bankConnections/updateProcess/{processId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionsCashback: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankConnections/cashback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionsCashbackV2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/bankConnections/cashback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectInterface: async (id: number, body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectInterface', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('connectInterface', 'body', body)
            const localVarPath = `/v1/bankConnections/{id}/connectInterface`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectInterfaceV2: async (id: number, body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectInterfaceV2', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('connectInterfaceV2', 'body', body)
            const localVarPath = `/v2/bankConnections/{id}/connectInterface`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankConnection', 'id', id)
            const localVarPath = `/v1/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionV2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankConnectionV2', 'id', id)
            const localVarPath = `/v2/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionsCashback: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankConnections/cashback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionsCashbackV2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/bankConnections/cashback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnection: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankConnections/bankingApiConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id Delete the banking API connection for the given user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankingApiConnectionId', 'id', id)
            const localVarPath = `/v1/bankConnections/bankingApiConnection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id Delete the banking API connection for the given user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionIdV2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankingApiConnectionIdV2', 'id', id)
            const localVarPath = `/v2/bankConnections/bankingApiConnection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionV2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/bankConnections/bankingApiConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {number} id The bank connection ID
         * @param {string} [bankingInterface] Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoredSecrets: async (id: number, bankingInterface?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStoredSecrets', 'id', id)
            const localVarPath = `/v1/bankConnections/secrets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bankingInterface !== undefined) {
                localVarQueryParameter['bankingInterface'] = bankingInterface;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {number} id The bank connection ID
         * @param {string} [bankingInterface] Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoredSecretsV2: async (id: number, bankingInterface?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStoredSecretsV2', 'id', id)
            const localVarPath = `/v2/bankConnections/secrets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bankingInterface !== undefined) {
                localVarQueryParameter['bankingInterface'] = bankingInterface;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {number} id The bank connection ID
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnection: async (id: number, body: EditBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editBankConnection', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editBankConnection', 'body', body)
            const localVarPath = `/v1/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionFromTppWebForm: async (id: string, body: EditBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editBankConnectionFromTppWebForm', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editBankConnectionFromTppWebForm', 'body', body)
            const localVarPath = `/v1/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionFromTppWebFormV2: async (id: string, body: EditBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editBankConnectionFromTppWebFormV2', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editBankConnectionFromTppWebFormV2', 'body', body)
            const localVarPath = `/v2/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {number} id The bank connection ID
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionV2: async (id: number, body: EditBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editBankConnectionV2', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editBankConnectionV2', 'body', body)
            const localVarPath = `/v2/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {number} id ID of the bank connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankConnection', 'id', id)
            const localVarPath = `/v1/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {number} id ID of the bank connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionV2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankConnectionV2', 'id', id)
            const localVarPath = `/v2/bankConnections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a bank connection saved logs
         * @param {number} userId ID of the user; only users for your mandator may be accessed.
         * @param {Array<GetUserBankConnectionEventsMultibankingProviderEnum>} [multibankingProvider] Filter results by the given multi-banking providers; default: no filter
         * @param {string} [startDate] A date in format yyyy-MM-dd (US Format).                              Only retrieve logs from the given date onwards.                              Default is 14 days from today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBankConnectionEvents: async (userId: number, multibankingProvider?: Array<GetUserBankConnectionEventsMultibankingProviderEnum>, startDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserBankConnectionEvents', 'userId', userId)
            const localVarPath = `/v1/bankConnections/logging/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (multibankingProvider) {
                localVarQueryParameter['multibankingProvider'] = multibankingProvider;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idAddOrUpdateBankConnectionResult: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idAddOrUpdateBankConnectionResult', 'id', id)
            const localVarPath = `/v1/bankConnections/{id}/addOrUpdateBankConnectionResult`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idAddOrUpdateBankConnectionResultV2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idAddOrUpdateBankConnectionResultV2', 'id', id)
            const localVarPath = `/v2/bankConnections/{id}/addOrUpdateBankConnectionResult`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {Array<number>} [id] ID of the bank connection
         * @param {Array<number>} [accountId] Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
         * @param {Array<boolean>} [includeRelatedAccounts] Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBankConnections: async (id?: Array<number>, accountId?: Array<number>, includeRelatedAccounts?: Array<boolean>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (includeRelatedAccounts) {
                localVarQueryParameter['includeRelatedAccounts'] = includeRelatedAccounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {Array<number>} [id] ID of the bank connection
         * @param {Array<number>} [accountId] Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
         * @param {Array<boolean>} [includeRelatedAccounts] Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBankConnectionsV2: async (id?: Array<number>, accountId?: Array<number>, includeRelatedAccounts?: Array<boolean>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/bankConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (includeRelatedAccounts) {
                localVarQueryParameter['includeRelatedAccounts'] = includeRelatedAccounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankConnection: async (id: number, body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBankConnection', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateBankConnection', 'body', body)
            const localVarPath = `/v1/bankConnections/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankConnectionV2: async (id: number, body: AddOrUpdateBankConnectionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBankConnectionV2', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateBankConnectionV2', 'body', body)
            const localVarPath = `/v2/bankConnections/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an <b>EXISTING</b>                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {AddOrUpdateBankConnectionFromTppWebFormParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowAddOrUpdateBankConnection: async (id: string, body: AddOrUpdateBankConnectionFromTppWebFormParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webFormFlowAddOrUpdateBankConnection', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webFormFlowAddOrUpdateBankConnection', 'body', body)
            const localVarPath = `/v1/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an EXISTING                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on. Note that the endpoint will not wait for the                      bank connection update and account updates to finish. Rather, it will respond with a 510 error                      indicating that update is in progress. Please use the \'updateProcess\' endpoint to check for the                      update to finish
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {AddOrUpdateBankConnectionFromTppWebFormParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowAddOrUpdateBankConnectionV2: async (id: string, body: AddOrUpdateBankConnectionFromTppWebFormParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webFormFlowAddOrUpdateBankConnectionV2', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webFormFlowAddOrUpdateBankConnectionV2', 'body', body)
            const localVarPath = `/v2/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {number} processId The bank connection import or update process
         * @param {number} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowBankConnectionUpdateProcessV2: async (processId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('webFormFlowBankConnectionUpdateProcessV2', 'processId', processId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webFormFlowBankConnectionUpdateProcessV2', 'id', id)
            const localVarPath = `/v2/bankConnections/webFormFlow/{id}/updateProcess/{processId}`
                .replace(`{${"processId"}}`, encodeURIComponent(String(processId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {string} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowGetImportOrUpdateBankConnectionResult: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webFormFlowGetImportOrUpdateBankConnectionResult', 'id', id)
            const localVarPath = `/v1/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {string} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowGetImportOrUpdateBankConnectionResultV2: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webFormFlowGetImportOrUpdateBankConnectionResultV2', 'id', id)
            const localVarPath = `/v2/bankConnections/webFormFlow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankConnectionsApi - functional programming interface
 * @export
 */
export const BankConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankConnection(body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBankConnection(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.addBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankConnectionV2(body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankSynchronizationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBankConnectionV2(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.addBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {string} processId The bank connection import or update process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankConnectionUpdateProcessV2(processId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankConnectionUpdateProcessV2(processId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.bankConnectionUpdateProcessV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankConnectionsCashback(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationBatchJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankConnectionsCashback(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.bankConnectionsCashback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankConnectionsCashbackV2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationBatchJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankConnectionsCashbackV2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.bankConnectionsCashbackV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectInterface(id: number, body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectInterface(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.connectInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectInterfaceV2(id: number, body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankSynchronizationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectInterfaceV2(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.connectInterfaceV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankConnection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankConnectionV2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankConnectionV2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankConnectionsCashback(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankConnectionsCashback(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankConnectionsCashback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankConnectionsCashbackV2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankConnectionsCashbackV2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankConnectionsCashbackV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankingApiConnection(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankingApiConnection(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankingApiConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id Delete the banking API connection for the given user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankingApiConnectionId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankingApiConnectionId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankingApiConnectionId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {number} id Delete the banking API connection for the given user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankingApiConnectionIdV2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankingApiConnectionIdV2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankingApiConnectionIdV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankingApiConnectionV2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankingApiConnectionV2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankingApiConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {number} id The bank connection ID
         * @param {string} [bankingInterface] Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoredSecrets(id: number, bankingInterface?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoredSecrets(id, bankingInterface, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteStoredSecrets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {number} id The bank connection ID
         * @param {string} [bankingInterface] Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoredSecretsV2(id: number, bankingInterface?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoredSecretsV2(id, bankingInterface, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteStoredSecretsV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {number} id The bank connection ID
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBankConnection(id: number, body: EditBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBankConnection(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.editBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBankConnectionFromTppWebForm(id: string, body: EditBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBankConnectionFromTppWebForm(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.editBankConnectionFromTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBankConnectionFromTppWebFormV2(id: string, body: EditBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBankConnectionFromTppWebFormV2(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.editBankConnectionFromTppWebFormV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {number} id The bank connection ID
         * @param {EditBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBankConnectionV2(id: number, body: EditBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBankConnectionV2(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.editBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {number} id ID of the bank connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankConnection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {number} id ID of the bank connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankConnectionV2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankConnectionV2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a bank connection saved logs
         * @param {number} userId ID of the user; only users for your mandator may be accessed.
         * @param {Array<GetUserBankConnectionEventsMultibankingProviderEnum>} [multibankingProvider] Filter results by the given multi-banking providers; default: no filter
         * @param {string} [startDate] A date in format yyyy-MM-dd (US Format).                              Only retrieve logs from the given date onwards.                              Default is 14 days from today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBankConnectionEvents(userId: number, multibankingProvider?: Array<GetUserBankConnectionEventsMultibankingProviderEnum>, startDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionLEventWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBankConnectionEvents(userId, multibankingProvider, startDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getUserBankConnectionEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idAddOrUpdateBankConnectionResult(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idAddOrUpdateBankConnectionResult(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.idAddOrUpdateBankConnectionResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {number} id The bank connection ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idAddOrUpdateBankConnectionResultV2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idAddOrUpdateBankConnectionResultV2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.idAddOrUpdateBankConnectionResultV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {Array<number>} [id] ID of the bank connection
         * @param {Array<number>} [accountId] Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
         * @param {Array<boolean>} [includeRelatedAccounts] Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllBankConnections(id?: Array<number>, accountId?: Array<number>, includeRelatedAccounts?: Array<boolean>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionListWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllBankConnections(id, accountId, includeRelatedAccounts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.listAndSearchAllBankConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {Array<number>} [id] ID of the bank connection
         * @param {Array<number>} [accountId] Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
         * @param {Array<boolean>} [includeRelatedAccounts] Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllBankConnectionsV2(id?: Array<number>, accountId?: Array<number>, includeRelatedAccounts?: Array<boolean>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionListWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllBankConnectionsV2(id, accountId, includeRelatedAccounts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.listAndSearchAllBankConnectionsV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankConnection(id: number, body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankConnection(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.updateBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {number} id The bank connection ID
         * @param {AddOrUpdateBankConnectionParams} body A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankConnectionV2(id: number, body: AddOrUpdateBankConnectionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankSynchronizationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankConnectionV2(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.updateBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an <b>EXISTING</b>                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on.
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {AddOrUpdateBankConnectionFromTppWebFormParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webFormFlowAddOrUpdateBankConnection(id: string, body: AddOrUpdateBankConnectionFromTppWebFormParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webFormFlowAddOrUpdateBankConnection(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.webFormFlowAddOrUpdateBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an EXISTING                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on. Note that the endpoint will not wait for the                      bank connection update and account updates to finish. Rather, it will respond with a 510 error                      indicating that update is in progress. Please use the \'updateProcess\' endpoint to check for the                      update to finish
         * @param {string} id ID of the web form this import or update request relates to (this is a random ID token)
         * @param {AddOrUpdateBankConnectionFromTppWebFormParams} body A create or update bank connection object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webFormFlowAddOrUpdateBankConnectionV2(id: string, body: AddOrUpdateBankConnectionFromTppWebFormParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankSynchronizationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webFormFlowAddOrUpdateBankConnectionV2(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.webFormFlowAddOrUpdateBankConnectionV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {number} processId The bank connection import or update process
         * @param {number} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webFormFlowBankConnectionUpdateProcessV2(processId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webFormFlowBankConnectionUpdateProcessV2(processId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.webFormFlowBankConnectionUpdateProcessV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {string} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webFormFlowGetImportOrUpdateBankConnectionResult(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webFormFlowGetImportOrUpdateBankConnectionResult(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.webFormFlowGetImportOrUpdateBankConnectionResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {string} id The TPP web-form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webFormFlowGetImportOrUpdateBankConnectionResultV2(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateBankConnectionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webFormFlowGetImportOrUpdateBankConnectionResultV2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.webFormFlowGetImportOrUpdateBankConnectionResultV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankConnectionsApi - factory interface
 * @export
 */
export const BankConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {BankConnectionsApiAddBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankConnection(requestParameters: BankConnectionsApiAddBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.addBankConnection(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {BankConnectionsApiAddBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankConnectionV2(requestParameters: BankConnectionsApiAddBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankSynchronizationProcess> {
            return localVarFp.addBankConnectionV2(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {BankConnectionsApiBankConnectionUpdateProcessV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionUpdateProcessV2(requestParameters: BankConnectionsApiBankConnectionUpdateProcessV2Request, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.bankConnectionUpdateProcessV2(requestParameters.processId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionsCashback(options?: RawAxiosRequestConfig): AxiosPromise<SynchronizationBatchJob> {
            return localVarFp.bankConnectionsCashback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankConnectionsCashbackV2(options?: RawAxiosRequestConfig): AxiosPromise<SynchronizationBatchJob> {
            return localVarFp.bankConnectionsCashbackV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {BankConnectionsApiConnectInterfaceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectInterface(requestParameters: BankConnectionsApiConnectInterfaceRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.connectInterface(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
         * @param {BankConnectionsApiConnectInterfaceV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectInterfaceV2(requestParameters: BankConnectionsApiConnectInterfaceV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankSynchronizationProcess> {
            return localVarFp.connectInterfaceV2(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {BankConnectionsApiDeleteBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnection(requestParameters: BankConnectionsApiDeleteBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {BankConnectionsApiDeleteBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionV2(requestParameters: BankConnectionsApiDeleteBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankConnectionV2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionsCashback(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankConnectionsCashback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all accounts imported via the cashback (Netfonds) import.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnectionsCashbackV2(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankConnectionsCashbackV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnection(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankingApiConnection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {BankConnectionsApiDeleteBankingApiConnectionIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionId(requestParameters: BankConnectionsApiDeleteBankingApiConnectionIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankingApiConnectionId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {BankConnectionsApiDeleteBankingApiConnectionIdV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionIdV2(requestParameters: BankConnectionsApiDeleteBankingApiConnectionIdV2Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankingApiConnectionIdV2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankingApiConnectionV2(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankingApiConnectionV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {BankConnectionsApiDeleteStoredSecretsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoredSecrets(requestParameters: BankConnectionsApiDeleteStoredSecretsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteStoredSecrets(requestParameters.id, requestParameters.bankingInterface, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
         * @param {BankConnectionsApiDeleteStoredSecretsV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoredSecretsV2(requestParameters: BankConnectionsApiDeleteStoredSecretsV2Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteStoredSecretsV2(requestParameters.id, requestParameters.bankingInterface, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {BankConnectionsApiEditBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnection(requestParameters: BankConnectionsApiEditBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.editBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {BankConnectionsApiEditBankConnectionFromTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionFromTppWebForm(requestParameters: BankConnectionsApiEditBankConnectionFromTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.editBankConnectionFromTppWebForm(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
         * @param {BankConnectionsApiEditBankConnectionFromTppWebFormV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionFromTppWebFormV2(requestParameters: BankConnectionsApiEditBankConnectionFromTppWebFormV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.editBankConnectionFromTppWebFormV2(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
         * @param {BankConnectionsApiEditBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankConnectionV2(requestParameters: BankConnectionsApiEditBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.editBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {BankConnectionsApiGetBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnection(requestParameters: BankConnectionsApiGetBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.getBankConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a bank connection
         * @param {BankConnectionsApiGetBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionV2(requestParameters: BankConnectionsApiGetBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionWrapper> {
            return localVarFp.getBankConnectionV2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a bank connection saved logs
         * @param {BankConnectionsApiGetUserBankConnectionEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBankConnectionEvents(requestParameters: BankConnectionsApiGetUserBankConnectionEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionLEventWrapper> {
            return localVarFp.getUserBankConnectionEvents(requestParameters.userId, requestParameters.multibankingProvider, requestParameters.startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idAddOrUpdateBankConnectionResult(requestParameters: BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.idAddOrUpdateBankConnectionResult(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
         * @param {BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idAddOrUpdateBankConnectionResultV2(requestParameters: BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.idAddOrUpdateBankConnectionResultV2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {BankConnectionsApiListAndSearchAllBankConnectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBankConnections(requestParameters: BankConnectionsApiListAndSearchAllBankConnectionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionListWrapper> {
            return localVarFp.listAndSearchAllBankConnections(requestParameters.id, requestParameters.accountId, requestParameters.includeRelatedAccounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all bank connections
         * @param {BankConnectionsApiListAndSearchAllBankConnectionsV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBankConnectionsV2(requestParameters: BankConnectionsApiListAndSearchAllBankConnectionsV2Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionListWrapper> {
            return localVarFp.listAndSearchAllBankConnectionsV2(requestParameters.id, requestParameters.accountId, requestParameters.includeRelatedAccounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {BankConnectionsApiUpdateBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankConnection(requestParameters: BankConnectionsApiUpdateBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.updateBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
         * @param {BankConnectionsApiUpdateBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankConnectionV2(requestParameters: BankConnectionsApiUpdateBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankSynchronizationProcess> {
            return localVarFp.updateBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an <b>EXISTING</b>                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on.
         * @param {BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowAddOrUpdateBankConnection(requestParameters: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.webFormFlowAddOrUpdateBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an EXISTING                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on. Note that the endpoint will not wait for the                      bank connection update and account updates to finish. Rather, it will respond with a 510 error                      indicating that update is in progress. Please use the \'updateProcess\' endpoint to check for the                      update to finish
         * @param {BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowAddOrUpdateBankConnectionV2(requestParameters: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request, options?: RawAxiosRequestConfig): AxiosPromise<BankSynchronizationProcess> {
            return localVarFp.webFormFlowAddOrUpdateBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
         * @param {BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowBankConnectionUpdateProcessV2(requestParameters: BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.webFormFlowBankConnectionUpdateProcessV2(requestParameters.processId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowGetImportOrUpdateBankConnectionResult(requestParameters: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.webFormFlowGetImportOrUpdateBankConnectionResult(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
         * @param {BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webFormFlowGetImportOrUpdateBankConnectionResultV2(requestParameters: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request, options?: RawAxiosRequestConfig): AxiosPromise<AddOrUpdateBankConnectionResult> {
            return localVarFp.webFormFlowGetImportOrUpdateBankConnectionResultV2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiAddBankConnectionRequest
 */
export interface BankConnectionsApiAddBankConnectionRequest {
    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiAddBankConnection
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for addBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiAddBankConnectionV2Request
 */
export interface BankConnectionsApiAddBankConnectionV2Request {
    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiAddBankConnectionV2
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for bankConnectionUpdateProcessV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiBankConnectionUpdateProcessV2Request
 */
export interface BankConnectionsApiBankConnectionUpdateProcessV2Request {
    /**
     * The bank connection import or update process
     * @type {string}
     * @memberof BankConnectionsApiBankConnectionUpdateProcessV2
     */
    readonly processId: string
}

/**
 * Request parameters for connectInterface operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiConnectInterfaceRequest
 */
export interface BankConnectionsApiConnectInterfaceRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiConnectInterface
     */
    readonly id: number

    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiConnectInterface
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for connectInterfaceV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiConnectInterfaceV2Request
 */
export interface BankConnectionsApiConnectInterfaceV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiConnectInterfaceV2
     */
    readonly id: number

    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiConnectInterfaceV2
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for deleteBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteBankConnectionRequest
 */
export interface BankConnectionsApiDeleteBankConnectionRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiDeleteBankConnection
     */
    readonly id: number
}

/**
 * Request parameters for deleteBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteBankConnectionV2Request
 */
export interface BankConnectionsApiDeleteBankConnectionV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiDeleteBankConnectionV2
     */
    readonly id: number
}

/**
 * Request parameters for deleteBankingApiConnectionId operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteBankingApiConnectionIdRequest
 */
export interface BankConnectionsApiDeleteBankingApiConnectionIdRequest {
    /**
     * Delete the banking API connection for the given user
     * @type {number}
     * @memberof BankConnectionsApiDeleteBankingApiConnectionId
     */
    readonly id: number
}

/**
 * Request parameters for deleteBankingApiConnectionIdV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteBankingApiConnectionIdV2Request
 */
export interface BankConnectionsApiDeleteBankingApiConnectionIdV2Request {
    /**
     * Delete the banking API connection for the given user
     * @type {number}
     * @memberof BankConnectionsApiDeleteBankingApiConnectionIdV2
     */
    readonly id: number
}

/**
 * Request parameters for deleteStoredSecrets operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteStoredSecretsRequest
 */
export interface BankConnectionsApiDeleteStoredSecretsRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiDeleteStoredSecrets
     */
    readonly id: number

    /**
     * Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
     * @type {string}
     * @memberof BankConnectionsApiDeleteStoredSecrets
     */
    readonly bankingInterface?: string
}

/**
 * Request parameters for deleteStoredSecretsV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteStoredSecretsV2Request
 */
export interface BankConnectionsApiDeleteStoredSecretsV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiDeleteStoredSecretsV2
     */
    readonly id: number

    /**
     * Only delete credentials for the given banking interface, allowed values are                              XS2A, FINTS_SERVER, WEB_SCRAPER.
     * @type {string}
     * @memberof BankConnectionsApiDeleteStoredSecretsV2
     */
    readonly bankingInterface?: string
}

/**
 * Request parameters for editBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiEditBankConnectionRequest
 */
export interface BankConnectionsApiEditBankConnectionRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiEditBankConnection
     */
    readonly id: number

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {EditBankConnectionParams}
     * @memberof BankConnectionsApiEditBankConnection
     */
    readonly body: EditBankConnectionParams
}

/**
 * Request parameters for editBankConnectionFromTppWebForm operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiEditBankConnectionFromTppWebFormRequest
 */
export interface BankConnectionsApiEditBankConnectionFromTppWebFormRequest {
    /**
     * ID of the web form this import or update request relates to (this is a random ID token)
     * @type {string}
     * @memberof BankConnectionsApiEditBankConnectionFromTppWebForm
     */
    readonly id: string

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {EditBankConnectionParams}
     * @memberof BankConnectionsApiEditBankConnectionFromTppWebForm
     */
    readonly body: EditBankConnectionParams
}

/**
 * Request parameters for editBankConnectionFromTppWebFormV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiEditBankConnectionFromTppWebFormV2Request
 */
export interface BankConnectionsApiEditBankConnectionFromTppWebFormV2Request {
    /**
     * ID of the web form this import or update request relates to (this is a random ID token)
     * @type {string}
     * @memberof BankConnectionsApiEditBankConnectionFromTppWebFormV2
     */
    readonly id: string

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {EditBankConnectionParams}
     * @memberof BankConnectionsApiEditBankConnectionFromTppWebFormV2
     */
    readonly body: EditBankConnectionParams
}

/**
 * Request parameters for editBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiEditBankConnectionV2Request
 */
export interface BankConnectionsApiEditBankConnectionV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiEditBankConnectionV2
     */
    readonly id: number

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {EditBankConnectionParams}
     * @memberof BankConnectionsApiEditBankConnectionV2
     */
    readonly body: EditBankConnectionParams
}

/**
 * Request parameters for getBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetBankConnectionRequest
 */
export interface BankConnectionsApiGetBankConnectionRequest {
    /**
     * ID of the bank connection
     * @type {number}
     * @memberof BankConnectionsApiGetBankConnection
     */
    readonly id: number
}

/**
 * Request parameters for getBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetBankConnectionV2Request
 */
export interface BankConnectionsApiGetBankConnectionV2Request {
    /**
     * ID of the bank connection
     * @type {number}
     * @memberof BankConnectionsApiGetBankConnectionV2
     */
    readonly id: number
}

/**
 * Request parameters for getUserBankConnectionEvents operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetUserBankConnectionEventsRequest
 */
export interface BankConnectionsApiGetUserBankConnectionEventsRequest {
    /**
     * ID of the user; only users for your mandator may be accessed.
     * @type {number}
     * @memberof BankConnectionsApiGetUserBankConnectionEvents
     */
    readonly userId: number

    /**
     * Filter results by the given multi-banking providers; default: no filter
     * @type {Array<'WEALTH_API' | 'FIN_API' | 'NETFONDS'>}
     * @memberof BankConnectionsApiGetUserBankConnectionEvents
     */
    readonly multibankingProvider?: Array<GetUserBankConnectionEventsMultibankingProviderEnum>

    /**
     * A date in format yyyy-MM-dd (US Format).                              Only retrieve logs from the given date onwards.                              Default is 14 days from today
     * @type {string}
     * @memberof BankConnectionsApiGetUserBankConnectionEvents
     */
    readonly startDate?: string
}

/**
 * Request parameters for idAddOrUpdateBankConnectionResult operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest
 */
export interface BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiIdAddOrUpdateBankConnectionResult
     */
    readonly id: number
}

/**
 * Request parameters for idAddOrUpdateBankConnectionResultV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request
 */
export interface BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiIdAddOrUpdateBankConnectionResultV2
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllBankConnections operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiListAndSearchAllBankConnectionsRequest
 */
export interface BankConnectionsApiListAndSearchAllBankConnectionsRequest {
    /**
     * ID of the bank connection
     * @type {Array<number>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnections
     */
    readonly id?: Array<number>

    /**
     * Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
     * @type {Array<number>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnections
     */
    readonly accountId?: Array<number>

    /**
     * Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
     * @type {Array<boolean>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnections
     */
    readonly includeRelatedAccounts?: Array<boolean>
}

/**
 * Request parameters for listAndSearchAllBankConnectionsV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiListAndSearchAllBankConnectionsV2Request
 */
export interface BankConnectionsApiListAndSearchAllBankConnectionsV2Request {
    /**
     * ID of the bank connection
     * @type {Array<number>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnectionsV2
     */
    readonly id?: Array<number>

    /**
     * Search bankConnections by local (Rentablo) accounts. This will include all bank connections                               matching the given account IDs.
     * @type {Array<number>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnectionsV2
     */
    readonly accountId?: Array<number>

    /**
     * Also include bank connections of related accounts (if any). This will include additional                              bank connections in the following cases:&lt;br /&gt;                              a) An account is a depot and its contra account(s) reside at a different bank&lt;br /&gt;                              b) An account is a contra account and its depot(s) reside at a different bank&lt;br /&gt;
     * @type {Array<boolean>}
     * @memberof BankConnectionsApiListAndSearchAllBankConnectionsV2
     */
    readonly includeRelatedAccounts?: Array<boolean>
}

/**
 * Request parameters for updateBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiUpdateBankConnectionRequest
 */
export interface BankConnectionsApiUpdateBankConnectionRequest {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiUpdateBankConnection
     */
    readonly id: number

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiUpdateBankConnection
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for updateBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiUpdateBankConnectionV2Request
 */
export interface BankConnectionsApiUpdateBankConnectionV2Request {
    /**
     * The bank connection ID
     * @type {number}
     * @memberof BankConnectionsApiUpdateBankConnectionV2
     */
    readonly id: number

    /**
     * A create or update bank connection object. Only the flags bankingPIN and storePIN are                              relevant when updating bank connections
     * @type {AddOrUpdateBankConnectionParams}
     * @memberof BankConnectionsApiUpdateBankConnectionV2
     */
    readonly body: AddOrUpdateBankConnectionParams
}

/**
 * Request parameters for webFormFlowAddOrUpdateBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest
 */
export interface BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest {
    /**
     * ID of the web form this import or update request relates to (this is a random ID token)
     * @type {string}
     * @memberof BankConnectionsApiWebFormFlowAddOrUpdateBankConnection
     */
    readonly id: string

    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionFromTppWebFormParams}
     * @memberof BankConnectionsApiWebFormFlowAddOrUpdateBankConnection
     */
    readonly body: AddOrUpdateBankConnectionFromTppWebFormParams
}

/**
 * Request parameters for webFormFlowAddOrUpdateBankConnectionV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request
 */
export interface BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request {
    /**
     * ID of the web form this import or update request relates to (this is a random ID token)
     * @type {string}
     * @memberof BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2
     */
    readonly id: string

    /**
     * A create or update bank connection object
     * @type {AddOrUpdateBankConnectionFromTppWebFormParams}
     * @memberof BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2
     */
    readonly body: AddOrUpdateBankConnectionFromTppWebFormParams
}

/**
 * Request parameters for webFormFlowBankConnectionUpdateProcessV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request
 */
export interface BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request {
    /**
     * The bank connection import or update process
     * @type {number}
     * @memberof BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2
     */
    readonly processId: number

    /**
     * The TPP web-form ID
     * @type {number}
     * @memberof BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2
     */
    readonly id: number
}

/**
 * Request parameters for webFormFlowGetImportOrUpdateBankConnectionResult operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest
 */
export interface BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest {
    /**
     * The TPP web-form ID
     * @type {string}
     * @memberof BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResult
     */
    readonly id: string
}

/**
 * Request parameters for webFormFlowGetImportOrUpdateBankConnectionResultV2 operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request
 */
export interface BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request {
    /**
     * The TPP web-form ID
     * @type {string}
     * @memberof BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2
     */
    readonly id: string
}

/**
 * BankConnectionsApi - object-oriented interface
 * @export
 * @class BankConnectionsApi
 * @extends {BaseAPI}
 */
export class BankConnectionsApi extends BaseAPI {
    /**
     * 
     * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
     * @param {BankConnectionsApiAddBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public addBankConnection(requestParameters: BankConnectionsApiAddBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).addBankConnection(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a bank connection. All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
     * @param {BankConnectionsApiAddBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public addBankConnectionV2(requestParameters: BankConnectionsApiAddBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).addBankConnectionV2(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
     * @param {BankConnectionsApiBankConnectionUpdateProcessV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public bankConnectionUpdateProcessV2(requestParameters: BankConnectionsApiBankConnectionUpdateProcessV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).bankConnectionUpdateProcessV2(requestParameters.processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public bankConnectionsCashback(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).bankConnectionsCashback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger the import of bank connections managed via Rentablo Cashback. This import is fundamentally                      different from the \'usual\' import as it does not require any banking credentials. The data                      is imported via dedicated B2B interfaces only available to Rentablo Cashback customers.                      The import is done as as background job, so this operation returns immediately.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public bankConnectionsCashbackV2(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).bankConnectionsCashbackV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
     * @param {BankConnectionsApiConnectInterfaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public connectInterface(requestParameters: BankConnectionsApiConnectInterfaceRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).connectInterface(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Connect an new bank connection interface to an existing bank connection. \" +                      All cash accounts (everything not a depot) will be automatically imported by                      this routine. In case an <b>EXISTING</b> cash account is used as depot contra account,                      transactions update for this account is skipped to allow correct dividend assignment later on.
     * @param {BankConnectionsApiConnectInterfaceV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public connectInterfaceV2(requestParameters: BankConnectionsApiConnectInterfaceV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).connectInterfaceV2(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {BankConnectionsApiDeleteBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankConnection(requestParameters: BankConnectionsApiDeleteBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a (remote) bank connection identified by the given ID.                      This will also clear bank connection data stored in locally persisted accounts.<br>                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {BankConnectionsApiDeleteBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankConnectionV2(requestParameters: BankConnectionsApiDeleteBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankConnectionV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all accounts imported via the cashback (Netfonds) import.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankConnectionsCashback(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankConnectionsCashback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all accounts imported via the cashback (Netfonds) import.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankConnectionsCashbackV2(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankConnectionsCashbackV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankingApiConnection(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankingApiConnection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {BankConnectionsApiDeleteBankingApiConnectionIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankingApiConnectionId(requestParameters: BankConnectionsApiDeleteBankingApiConnectionIdRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankingApiConnectionId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the remote banking API account (i.e. the finAPI account) of the given  user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {BankConnectionsApiDeleteBankingApiConnectionIdV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankingApiConnectionIdV2(requestParameters: BankConnectionsApiDeleteBankingApiConnectionIdV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankingApiConnectionIdV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the remote banking API account (i.e. the finAPI account) of the currently logged in user.                      This will delete the remote user account and also the local copy of the remote user.                      Remote IDs of all accounts will be reset and all accounts are manually maintained after executing                      this operation - so handle with care!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankingApiConnectionV2(options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankingApiConnectionV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
     * @param {BankConnectionsApiDeleteStoredSecretsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteStoredSecrets(requestParameters: BankConnectionsApiDeleteStoredSecretsRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteStoredSecrets(requestParameters.id, requestParameters.bankingInterface, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete stored secrets for a (remote) bank connection identified by the given ID.
     * @param {BankConnectionsApiDeleteStoredSecretsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteStoredSecretsV2(requestParameters: BankConnectionsApiDeleteStoredSecretsV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteStoredSecretsV2(requestParameters.id, requestParameters.bankingInterface, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
     * @param {BankConnectionsApiEditBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public editBankConnection(requestParameters: BankConnectionsApiEditBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).editBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
     * @param {BankConnectionsApiEditBankConnectionFromTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public editBankConnectionFromTppWebForm(requestParameters: BankConnectionsApiEditBankConnectionFromTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).editBankConnectionFromTppWebForm(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      This endpoint allows to edit a bank connection starting from a TPP web-form. The request body                      must thus contain the web-form secret.
     * @param {BankConnectionsApiEditBankConnectionFromTppWebFormV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public editBankConnectionFromTppWebFormV2(requestParameters: BankConnectionsApiEditBankConnectionFromTppWebFormV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).editBankConnectionFromTppWebFormV2(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the stored authentication credentials (banking user ID, banking customer ID, and banking                      PIN), or other fields of the bank connection. Must pass the connection\'s identifier.<br>                      Note that a bank connection\'s credentials cannot be changed while it is in the process of import,                      update, or transactions categorization.<br>                      NOTE: Depending on your license, this service may respond with HTTP code 451, containing an error                      message with a identifier of web form in it. In addition to that the response will also have                      included a \'Location\' attribute, which contains the URL to the web form. In this case, you must                      forward your user to the web form. For a detailed explanation of the Web Form Flow, please refer                      to this article: Rentablo Web Form Flow.
     * @param {BankConnectionsApiEditBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public editBankConnectionV2(requestParameters: BankConnectionsApiEditBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).editBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a bank connection
     * @param {BankConnectionsApiGetBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getBankConnection(requestParameters: BankConnectionsApiGetBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getBankConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a bank connection
     * @param {BankConnectionsApiGetBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getBankConnectionV2(requestParameters: BankConnectionsApiGetBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getBankConnectionV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a bank connection saved logs
     * @param {BankConnectionsApiGetUserBankConnectionEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getUserBankConnectionEvents(requestParameters: BankConnectionsApiGetUserBankConnectionEventsRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getUserBankConnectionEvents(requestParameters.userId, requestParameters.multibankingProvider, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
     * @param {BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public idAddOrUpdateBankConnectionResult(requestParameters: BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).idAddOrUpdateBankConnectionResult(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a bank connection import or update result, without attempting to update the connection.
     * @param {BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public idAddOrUpdateBankConnectionResultV2(requestParameters: BankConnectionsApiIdAddOrUpdateBankConnectionResultV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).idAddOrUpdateBankConnectionResultV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all bank connections
     * @param {BankConnectionsApiListAndSearchAllBankConnectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public listAndSearchAllBankConnections(requestParameters: BankConnectionsApiListAndSearchAllBankConnectionsRequest = {}, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).listAndSearchAllBankConnections(requestParameters.id, requestParameters.accountId, requestParameters.includeRelatedAccounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all bank connections
     * @param {BankConnectionsApiListAndSearchAllBankConnectionsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public listAndSearchAllBankConnectionsV2(requestParameters: BankConnectionsApiListAndSearchAllBankConnectionsV2Request = {}, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).listAndSearchAllBankConnectionsV2(requestParameters.id, requestParameters.accountId, requestParameters.includeRelatedAccounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
     * @param {BankConnectionsApiUpdateBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public updateBankConnection(requestParameters: BankConnectionsApiUpdateBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).updateBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a bank connection. This will also perform bank synchronization, meaning updating all of                      its accounts (transactions and securities) on finAPI side. Transaction data for local cash accounts                      will be updated automatically. Securities are not persisted as we usually require user review                      or advanced logic before doing so.
     * @param {BankConnectionsApiUpdateBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public updateBankConnectionV2(requestParameters: BankConnectionsApiUpdateBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).updateBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an <b>EXISTING</b>                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on.
     * @param {BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public webFormFlowAddOrUpdateBankConnection(requestParameters: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).webFormFlowAddOrUpdateBankConnection(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update a bank connection using the TPP web-form flow. All cash (everything not a depot)                      accounts will be automatically imported or updated by this endpoint. In case an EXISTING                      cash account is used as depot contra account, transactions update for this account is skipped to                      allow correct dividend assignment later on. Note that the endpoint will not wait for the                      bank connection update and account updates to finish. Rather, it will respond with a 510 error                      indicating that update is in progress. Please use the \'updateProcess\' endpoint to check for the                      update to finish
     * @param {BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public webFormFlowAddOrUpdateBankConnectionV2(requestParameters: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).webFormFlowAddOrUpdateBankConnectionV2(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the update status for a bank connection import or update process. Will include the update                     result if the update finished and cash accounts were written.
     * @param {BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public webFormFlowBankConnectionUpdateProcessV2(requestParameters: BankConnectionsApiWebFormFlowBankConnectionUpdateProcessV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).webFormFlowBankConnectionUpdateProcessV2(requestParameters.processId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
     * @param {BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public webFormFlowGetImportOrUpdateBankConnectionResult(requestParameters: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).webFormFlowGetImportOrUpdateBankConnectionResult(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a bank connection import or update result, after successfully completing the web-form flow.
     * @param {BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public webFormFlowGetImportOrUpdateBankConnectionResultV2(requestParameters: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).webFormFlowGetImportOrUpdateBankConnectionResultV2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetUserBankConnectionEventsMultibankingProviderEnum = {
    WEALTH_API: 'WEALTH_API',
    FIN_API: 'FIN_API',
    NETFONDS: 'NETFONDS'
} as const;
export type GetUserBankConnectionEventsMultibankingProviderEnum = typeof GetUserBankConnectionEventsMultibankingProviderEnum[keyof typeof GetUserBankConnectionEventsMultibankingProviderEnum];
