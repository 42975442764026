import { UseQueryOptions } from "react-query";
import {
  QuotesApiGetHistoricQuotesRequest,
  HistoricQuotesResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getHistoricQuotes = async (
  token: string | undefined,
  params: QuotesApiGetHistoricQuotesRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getHistoricQuotes(params);
  return data;
};

export const useGetHistoricQuotes = (
  params: QuotesApiGetHistoricQuotesRequest,
  options?: UseQueryOptions<
    HistoricQuotesResponse,
    AxiosApiError,
    HistoricQuotesResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_HISTORIC_QUOTES, params],
    ({ token }) => getHistoricQuotes(token, params),
    options
  );
};
