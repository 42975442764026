/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccountImportOrSynchronizationResult } from '../model';
// @ts-ignore
import { Booking } from '../model';
// @ts-ignore
import { CarveOutParams } from '../model';
// @ts-ignore
import { ConsolidateNumberOfLotsParams } from '../model';
// @ts-ignore
import { CreateMultipleBookingParams } from '../model';
// @ts-ignore
import { CreateOrUpdateBookingParams } from '../model';
// @ts-ignore
import { CreateOrUpdateInvestmentParamsList } from '../model';
// @ts-ignore
import { CreateOrUpdateManagedDepotParams } from '../model';
// @ts-ignore
import { PageableBookingList } from '../model';
// @ts-ignore
import { SplitParams } from '../model';
// @ts-ignore
import { SwitchParams } from '../model';
// @ts-ignore
import { TransactionValidationErrorList } from '../model';
/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Carve out an investment.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carveOut: async (body: CarveOutParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('carveOut', 'body', body)
            const localVarPath = `/v1/bookings/carveOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if persisting all of the given bookings would lead to consistency errors.
         * @param {CreateOrUpdateInvestmentParamsList} body CreateOrUpdateInvestment params including a list of CreateOrUpdateTransaction params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConsistencyStatus: async (body: CreateOrUpdateInvestmentParamsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkConsistencyStatus', 'body', body)
            const localVarPath = `/v1/bookings/checkConsistencyStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if updating the given bookings will lead to consistency problems.
         * @param {number} id ID of the booking to check the consistency status for
         * @param {CreateOrUpdateBookingParams} body The transaction params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConsistencyStatusId: async (id: number, body: CreateOrUpdateBookingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConsistencyStatusId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkConsistencyStatusId', 'body', body)
            const localVarPath = `/v1/bookings/checkConsistencyStatus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consolidate bookings with a negative number of lots for the investments with the given ISINs.                 This will create an estimated booking whenever the number of lots at any day will                 drop below zero.                 This endpoint is designed for server-to-server use cases, but might be user by clients as well to                 re-consolidate erroneous user input.
         * @param {ConsolidateNumberOfLotsParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consolidateNegativeNol: async (body: ConsolidateNumberOfLotsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('consolidateNegativeNol', 'body', body)
            const localVarPath = `/v1/bookings/consolidateNegativeNol`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create (or batch update) a set of bookings und perform optional background synchronization of dividends and                      kupons if attributes \'performBackgroundTransactionSync\' is set true. In case no bookings are                      submitted (i.e. the \'createOrUpdateInvestmentParamsList\' is empty), a background sync may still                      be performed. This allows importing dividends in the absence of depot composition changes.<br>                     In case some of the transactions are invalid, the according validation errors are returned and                      only valid transactions are persisted.
         * @param {CreateMultipleBookingParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookings: async (body: CreateMultipleBookingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createBookings', 'body', body)
            const localVarPath = `/v1/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a booking for a managed depot.
         * @param {CreateOrUpdateManagedDepotParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedDepotBooking: async (body: CreateOrUpdateManagedDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createManagedDepotBooking', 'body', body)
            const localVarPath = `/v1/bookings/managedDepot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a booking
         * @param {number} id ID of the booking to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking: async (id: number, keepData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBooking', 'id', id)
            const localVarPath = `/v1/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keepData !== undefined) {
                localVarQueryParameter['keepData'] = keepData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a booking by external ID. This is a convenience routine typically used by SaaS processes                        working on booking data.
         * @param {string} externalId External ID of the booking to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingByExternalId: async (externalId: string, keepData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('deleteBookingByExternalId', 'externalId', externalId)
            const localVarPath = `/v1/bookings/{externalId}/byExternalId`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keepData !== undefined) {
                localVarQueryParameter['keepData'] = keepData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single split execution. In most cases rolling back (deleting) the split is the                 preferred option; however there may be bank-import related edge cases in which a user might want to                 delete a single split execution instead of the entire split.                 Deleting a single split execution will only roll back this execution but leave executions for all                 other bookings in place.                 Note: should rolling back this execution lead to negative number of lots at any case, then the                 rollback will automatically create a new booking to compensate for the negative number of lots.
         * @param {number} id ID of the split execution to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSplitExecution: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSplitExecution', 'id', id)
            const localVarPath = `/v1/bookings/splitExecutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED: Estimate the number of lots moved in a transaction for managed depots. The special case here is, that we do                      NOT know the depot composition. The managing entity (usually a robo advisor) does not deliver any information                      on the number of lots. This means we need to work with \'virtual\' lots. The number of the virtual lots is not                      important; we rather care about the correct relation of the lots to each other. The respective ratio                      is calculated using the depot valuation provided; in respect to transactions executed previously.
         * @param {string} type The transaction type; allowed values are buy and sell.
         * @param {number} amount The unsigned amount of cash moved in the transaction.
         * @param {number} depotValuation The depot valuation AFTER executing the transaction. This value is only required if we are                              adding transactions to an existing investment. Otherwise it equals the amount.
         * @param {string} date The transaction date; format is yyyy-MM-dd (US Format).
         * @param {number} [investmentId] Include transactions for the given investments into the estimation; if present. If not                              given, a new investment is assumed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimateImplicitNumberOfLots: async (type: string, amount: number, depotValuation: number, date: string, investmentId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('estimateImplicitNumberOfLots', 'type', type)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('estimateImplicitNumberOfLots', 'amount', amount)
            // verify required parameter 'depotValuation' is not null or undefined
            assertParamExists('estimateImplicitNumberOfLots', 'depotValuation', depotValuation)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('estimateImplicitNumberOfLots', 'date', date)
            const localVarPath = `/v1/bookings/estimateImplicitNumberOfLots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (investmentId !== undefined) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (depotValuation !== undefined) {
                localVarQueryParameter['depotValuation'] = depotValuation;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {Array<number>} [id] Retrieve Bookings with the given IDs
         * @param {Array<number>} [accountId] Retrieve Bookings for the given Accounts
         * @param {Array<number>} [investmentId] Retrieve Bookings for the given investments
         * @param {Array<ListAndSearchAllBookingsInvestmentTypeEnum>} [investmentType] Retrieve Bookings for the given investment types (e.g. bookings for funds)
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Bookings with the given external IDs
         * @param {Array<ListAndSearchAllBookingsTypeEnum>} [type] Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
         * @param {Array<string>} [wkn] Retrieve Bookings belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Bookings belonging to Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Bookings belonging to Investments with the given ISINs or WKNs
         * @param {Array<string>} [tickerSymbol] Retrieve Bookings belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Bookings in the given currencies (3-letter ISO code)
         * @param {Array<ListAndSearchAllBookingsDataConsistencyStatusEnum>} [dataConsistencyStatus] Filter bookings by data consistency status; default is no filter.                         Possible values are:                         EXACT_NOL - the bank or broker provided a cash amount and the exact number of lots moved by this order. This \\                             is the best case and yields precise data.                         BOOKING_WITH_NOL_ESTIMATED - the bank or broker provided the cash amount, but NOT the number of lots \\                             moved by this order. Thus the number of lots was estimated based on historic quote data. This generally \\                             works well if there are not too many orders.                         NEGATIVE_NOL_DELTA_RESOLUTION - this booking was created to avoid a drop of the the investments number of lots \\                             below zero at any point in time. Such a case may happen if data is missing or an estimation is incorrect.                         DELTA_RESOLUTION - there was a delta (also called tracking error) between the number of lots calculated by \\                             the order history and between the number of lots reported by the bank. Such tracking errors may occur \\                             due to data inconsistencies or due to capital measures such as carve outs, depot transfers or splits.
         * @param {number} [minAmount] Retrieve Bookings with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Bookings with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {Array<ListAndSearchAllBookingsIncludeEnum>} [include] Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
         * @param {ListAndSearchAllBookingsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBookings: async (id?: Array<number>, accountId?: Array<number>, investmentId?: Array<number>, investmentType?: Array<ListAndSearchAllBookingsInvestmentTypeEnum>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<ListAndSearchAllBookingsTypeEnum>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, dataConsistencyStatus?: Array<ListAndSearchAllBookingsDataConsistencyStatusEnum>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, include?: Array<ListAndSearchAllBookingsIncludeEnum>, format?: ListAndSearchAllBookingsFormatEnum, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (investmentType) {
                localVarQueryParameter['investmentType'] = investmentType;
            }

            if (finApiId) {
                localVarQueryParameter['finApiId'] = finApiId;
            }

            if (externalId) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (isinOrWkn) {
                localVarQueryParameter['isinOrWkn'] = isinOrWkn;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (dataConsistencyStatus) {
                localVarQueryParameter['dataConsistencyStatus'] = dataConsistencyStatus;
            }

            if (minAmount !== undefined) {
                localVarQueryParameter['minAmount'] = minAmount;
            }

            if (maxAmount !== undefined) {
                localVarQueryParameter['maxAmount'] = maxAmount;
            }

            if (minDate !== undefined) {
                localVarQueryParameter['minDate'] = minDate;
            }

            if (maxDate !== undefined) {
                localVarQueryParameter['maxDate'] = maxDate;
            }

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a booking change
         * @param {number} id ID of the transaction to retrieve
         * @param {Array<PortfolioPositionsIdIncludeEnum>} [include] Include the given entities in the response.
         * @param {PortfolioPositionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPositionsId: async (id: number, include?: Array<PortfolioPositionsIdIncludeEnum>, format?: PortfolioPositionsIdFormatEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portfolioPositionsId', 'id', id)
            const localVarPath = `/v1/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [id] Retrieve Bookings with the given IDs
         * @param {Array<number>} [accountId] Retrieve Bookings for the given Accounts
         * @param {Array<number>} [investmentId] Retrieve Bookings for the given investments
         * @param {Array<PublicListAndSearchAllBookingsInvestmentTypeEnum>} [investmentType] Retrieve Bookings for the given investment types (e.g. bookings for funds)
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Bookings with the given external IDs
         * @param {Array<PublicListAndSearchAllBookingsTypeEnum>} [type] Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
         * @param {Array<string>} [wkn] Retrieve Bookings belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Bookings belonging to Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Bookings belonging to Investments with the given ISINs or WKNs
         * @param {Array<string>} [tickerSymbol] Retrieve Bookings belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Bookings in the given currencies (3-letter ISO code)
         * @param {number} [minAmount] Retrieve Bookings with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Bookings with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {Array<PublicListAndSearchAllBookingsIncludeEnum>} [include] Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
         * @param {PublicListAndSearchAllBookingsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListAndSearchAllBookings: async (publicId: string, id?: Array<number>, accountId?: Array<number>, investmentId?: Array<number>, investmentType?: Array<PublicListAndSearchAllBookingsInvestmentTypeEnum>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<PublicListAndSearchAllBookingsTypeEnum>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, include?: Array<PublicListAndSearchAllBookingsIncludeEnum>, format?: PublicListAndSearchAllBookingsFormatEnum, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicListAndSearchAllBookings', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/bookings`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (investmentType) {
                localVarQueryParameter['investmentType'] = investmentType;
            }

            if (finApiId) {
                localVarQueryParameter['finApiId'] = finApiId;
            }

            if (externalId) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (isinOrWkn) {
                localVarQueryParameter['isinOrWkn'] = isinOrWkn;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (minAmount !== undefined) {
                localVarQueryParameter['minAmount'] = minAmount;
            }

            if (maxAmount !== undefined) {
                localVarQueryParameter['maxAmount'] = maxAmount;
            }

            if (minDate !== undefined) {
                localVarQueryParameter['minDate'] = minDate;
            }

            if (maxDate !== undefined) {
                localVarQueryParameter['maxDate'] = maxDate;
            }

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a booking change
         * @param {number} id ID of the transaction to retrieve
         * @param {string} publicId Public ID of the user
         * @param {Array<PublicPortfolioPositionsIdIncludeEnum>} [include] Include the given entities in the response
         * @param {PublicPortfolioPositionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPortfolioPositionsId: async (id: number, publicId: string, include?: Array<PublicPortfolioPositionsIdIncludeEnum>, format?: PublicPortfolioPositionsIdFormatEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicPortfolioPositionsId', 'id', id)
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicPortfolioPositionsId', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED: Detect changes to an accounts investments and return them as a list of proposed booking changes.
         * @param {number} accountId ID of the account to synchronize
         * @param {boolean} [doNotApplyTransactionCutoff] Usually all transactions with an import date greater then equal the last manual or automatic                              synchronization date will be used. Setting this parameter to true will force the transaction                              classification logic to skip this cutoff and instead use the latest investment transaction                              date as cutoff if an investment was found. Otherwise all historic transactions will be                              reported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteChanges: async (accountId: number, doNotApplyTransactionCutoff?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('remoteChanges', 'accountId', accountId)
            const localVarPath = `/v1/bookings/remoteChanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (doNotApplyTransactionCutoff !== undefined) {
                localVarQueryParameter['doNotApplyTransactionCutoff'] = doNotApplyTransactionCutoff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Split an investment.
         * @param {SplitParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        split: async (body: SplitParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('split', 'body', body)
            const localVarPath = `/v1/bookings/split`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Switch an investment or fund. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {SwitchParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchInvestment: async (body: SwitchParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('switchInvestment', 'body', body)
            const localVarPath = `/v1/bookings/switch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a booking for a managed depot.
         * @param {CreateOrUpdateManagedDepotParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedDepotBooking: async (body: CreateOrUpdateManagedDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateManagedDepotBooking', 'body', body)
            const localVarPath = `/v1/bookings/managedDepot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a booking. Note that update of single fields is typically not possible (see the required                        attribute in the input model). This is because many fields are inter dependent; e.g. a commissions                        may be entered in EUR for and investment denote in USD. In such cases updating data without                        providing the exchange rate would lead to inconsistent data. We thus require a certain set of                        input data also for updates.
         * @param {number} id ID of the transaction to update
         * @param {CreateOrUpdateBookingParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction: async (id: number, body: CreateOrUpdateBookingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTransaction', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTransaction', 'body', body)
            const localVarPath = `/v1/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a carve out.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valiateCarveOut: async (body: CarveOutParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('valiateCarveOut', 'body', body)
            const localVarPath = `/v1/bookings/carveOut/checkConsistencyStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a managed depot booking.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateManagedDepotBooking: async (body: CarveOutParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateManagedDepotBooking', 'body', body)
            const localVarPath = `/v1/bookings/managedDepot/checkConsistencyStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a switch. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {SwitchParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSwitchInvestment: async (body: SwitchParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateSwitchInvestment', 'body', body)
            const localVarPath = `/v1/bookings/switch/checkConsistencyStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Carve out an investment.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carveOut(body: CarveOutParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carveOut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.carveOut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if persisting all of the given bookings would lead to consistency errors.
         * @param {CreateOrUpdateInvestmentParamsList} body CreateOrUpdateInvestment params including a list of CreateOrUpdateTransaction params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConsistencyStatus(body: CreateOrUpdateInvestmentParamsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConsistencyStatus(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.checkConsistencyStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if updating the given bookings will lead to consistency problems.
         * @param {number} id ID of the booking to check the consistency status for
         * @param {CreateOrUpdateBookingParams} body The transaction params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConsistencyStatusId(id: number, body: CreateOrUpdateBookingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConsistencyStatusId(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.checkConsistencyStatusId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Consolidate bookings with a negative number of lots for the investments with the given ISINs.                 This will create an estimated booking whenever the number of lots at any day will                 drop below zero.                 This endpoint is designed for server-to-server use cases, but might be user by clients as well to                 re-consolidate erroneous user input.
         * @param {ConsolidateNumberOfLotsParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consolidateNegativeNol(body: ConsolidateNumberOfLotsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consolidateNegativeNol(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.consolidateNegativeNol']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create (or batch update) a set of bookings und perform optional background synchronization of dividends and                      kupons if attributes \'performBackgroundTransactionSync\' is set true. In case no bookings are                      submitted (i.e. the \'createOrUpdateInvestmentParamsList\' is empty), a background sync may still                      be performed. This allows importing dividends in the absence of depot composition changes.<br>                     In case some of the transactions are invalid, the according validation errors are returned and                      only valid transactions are persisted.
         * @param {CreateMultipleBookingParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBookings(body: CreateMultipleBookingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBookings(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.createBookings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a booking for a managed depot.
         * @param {CreateOrUpdateManagedDepotParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManagedDepotBooking(body: CreateOrUpdateManagedDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManagedDepotBooking(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.createManagedDepotBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a booking
         * @param {number} id ID of the booking to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBooking(id: number, keepData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBooking(id, keepData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.deleteBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a booking by external ID. This is a convenience routine typically used by SaaS processes                        working on booking data.
         * @param {string} externalId External ID of the booking to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookingByExternalId(externalId: string, keepData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBookingByExternalId(externalId, keepData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.deleteBookingByExternalId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a single split execution. In most cases rolling back (deleting) the split is the                 preferred option; however there may be bank-import related edge cases in which a user might want to                 delete a single split execution instead of the entire split.                 Deleting a single split execution will only roll back this execution but leave executions for all                 other bookings in place.                 Note: should rolling back this execution lead to negative number of lots at any case, then the                 rollback will automatically create a new booking to compensate for the negative number of lots.
         * @param {number} id ID of the split execution to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSplitExecution(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSplitExecution(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.deleteSplitExecution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED: Estimate the number of lots moved in a transaction for managed depots. The special case here is, that we do                      NOT know the depot composition. The managing entity (usually a robo advisor) does not deliver any information                      on the number of lots. This means we need to work with \'virtual\' lots. The number of the virtual lots is not                      important; we rather care about the correct relation of the lots to each other. The respective ratio                      is calculated using the depot valuation provided; in respect to transactions executed previously.
         * @param {string} type The transaction type; allowed values are buy and sell.
         * @param {number} amount The unsigned amount of cash moved in the transaction.
         * @param {number} depotValuation The depot valuation AFTER executing the transaction. This value is only required if we are                              adding transactions to an existing investment. Otherwise it equals the amount.
         * @param {string} date The transaction date; format is yyyy-MM-dd (US Format).
         * @param {number} [investmentId] Include transactions for the given investments into the estimation; if present. If not                              given, a new investment is assumed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async estimateImplicitNumberOfLots(type: string, amount: number, depotValuation: number, date: string, investmentId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.estimateImplicitNumberOfLots(type, amount, depotValuation, date, investmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.estimateImplicitNumberOfLots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {Array<number>} [id] Retrieve Bookings with the given IDs
         * @param {Array<number>} [accountId] Retrieve Bookings for the given Accounts
         * @param {Array<number>} [investmentId] Retrieve Bookings for the given investments
         * @param {Array<ListAndSearchAllBookingsInvestmentTypeEnum>} [investmentType] Retrieve Bookings for the given investment types (e.g. bookings for funds)
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Bookings with the given external IDs
         * @param {Array<ListAndSearchAllBookingsTypeEnum>} [type] Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
         * @param {Array<string>} [wkn] Retrieve Bookings belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Bookings belonging to Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Bookings belonging to Investments with the given ISINs or WKNs
         * @param {Array<string>} [tickerSymbol] Retrieve Bookings belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Bookings in the given currencies (3-letter ISO code)
         * @param {Array<ListAndSearchAllBookingsDataConsistencyStatusEnum>} [dataConsistencyStatus] Filter bookings by data consistency status; default is no filter.                         Possible values are:                         EXACT_NOL - the bank or broker provided a cash amount and the exact number of lots moved by this order. This \\                             is the best case and yields precise data.                         BOOKING_WITH_NOL_ESTIMATED - the bank or broker provided the cash amount, but NOT the number of lots \\                             moved by this order. Thus the number of lots was estimated based on historic quote data. This generally \\                             works well if there are not too many orders.                         NEGATIVE_NOL_DELTA_RESOLUTION - this booking was created to avoid a drop of the the investments number of lots \\                             below zero at any point in time. Such a case may happen if data is missing or an estimation is incorrect.                         DELTA_RESOLUTION - there was a delta (also called tracking error) between the number of lots calculated by \\                             the order history and between the number of lots reported by the bank. Such tracking errors may occur \\                             due to data inconsistencies or due to capital measures such as carve outs, depot transfers or splits.
         * @param {number} [minAmount] Retrieve Bookings with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Bookings with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {Array<ListAndSearchAllBookingsIncludeEnum>} [include] Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
         * @param {ListAndSearchAllBookingsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllBookings(id?: Array<number>, accountId?: Array<number>, investmentId?: Array<number>, investmentType?: Array<ListAndSearchAllBookingsInvestmentTypeEnum>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<ListAndSearchAllBookingsTypeEnum>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, dataConsistencyStatus?: Array<ListAndSearchAllBookingsDataConsistencyStatusEnum>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, include?: Array<ListAndSearchAllBookingsIncludeEnum>, format?: ListAndSearchAllBookingsFormatEnum, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableBookingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllBookings(id, accountId, investmentId, investmentType, finApiId, externalId, type, wkn, isin, isinOrWkn, tickerSymbol, currency, dataConsistencyStatus, minAmount, maxAmount, minDate, maxDate, include, format, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.listAndSearchAllBookings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a booking change
         * @param {number} id ID of the transaction to retrieve
         * @param {Array<PortfolioPositionsIdIncludeEnum>} [include] Include the given entities in the response.
         * @param {PortfolioPositionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioPositionsId(id: number, include?: Array<PortfolioPositionsIdIncludeEnum>, format?: PortfolioPositionsIdFormatEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioPositionsId(id, include, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.portfolioPositionsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [id] Retrieve Bookings with the given IDs
         * @param {Array<number>} [accountId] Retrieve Bookings for the given Accounts
         * @param {Array<number>} [investmentId] Retrieve Bookings for the given investments
         * @param {Array<PublicListAndSearchAllBookingsInvestmentTypeEnum>} [investmentType] Retrieve Bookings for the given investment types (e.g. bookings for funds)
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Bookings with the given external IDs
         * @param {Array<PublicListAndSearchAllBookingsTypeEnum>} [type] Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
         * @param {Array<string>} [wkn] Retrieve Bookings belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Bookings belonging to Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Bookings belonging to Investments with the given ISINs or WKNs
         * @param {Array<string>} [tickerSymbol] Retrieve Bookings belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Bookings in the given currencies (3-letter ISO code)
         * @param {number} [minAmount] Retrieve Bookings with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Bookings with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {Array<PublicListAndSearchAllBookingsIncludeEnum>} [include] Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
         * @param {PublicListAndSearchAllBookingsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListAndSearchAllBookings(publicId: string, id?: Array<number>, accountId?: Array<number>, investmentId?: Array<number>, investmentType?: Array<PublicListAndSearchAllBookingsInvestmentTypeEnum>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<PublicListAndSearchAllBookingsTypeEnum>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, include?: Array<PublicListAndSearchAllBookingsIncludeEnum>, format?: PublicListAndSearchAllBookingsFormatEnum, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableBookingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListAndSearchAllBookings(publicId, id, accountId, investmentId, investmentType, finApiId, externalId, type, wkn, isin, isinOrWkn, tickerSymbol, currency, minAmount, maxAmount, minDate, maxDate, include, format, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.publicListAndSearchAllBookings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a booking change
         * @param {number} id ID of the transaction to retrieve
         * @param {string} publicId Public ID of the user
         * @param {Array<PublicPortfolioPositionsIdIncludeEnum>} [include] Include the given entities in the response
         * @param {PublicPortfolioPositionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicPortfolioPositionsId(id: number, publicId: string, include?: Array<PublicPortfolioPositionsIdIncludeEnum>, format?: PublicPortfolioPositionsIdFormatEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicPortfolioPositionsId(id, publicId, include, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.publicPortfolioPositionsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED: Detect changes to an accounts investments and return them as a list of proposed booking changes.
         * @param {number} accountId ID of the account to synchronize
         * @param {boolean} [doNotApplyTransactionCutoff] Usually all transactions with an import date greater then equal the last manual or automatic                              synchronization date will be used. Setting this parameter to true will force the transaction                              classification logic to skip this cutoff and instead use the latest investment transaction                              date as cutoff if an investment was found. Otherwise all historic transactions will be                              reported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteChanges(accountId: number, doNotApplyTransactionCutoff?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountImportOrSynchronizationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteChanges(accountId, doNotApplyTransactionCutoff, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.remoteChanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Split an investment.
         * @param {SplitParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async split(body: SplitParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.split(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.split']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Switch an investment or fund. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {SwitchParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchInvestment(body: SwitchParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchInvestment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.switchInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a booking for a managed depot.
         * @param {CreateOrUpdateManagedDepotParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManagedDepotBooking(body: CreateOrUpdateManagedDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManagedDepotBooking(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.updateManagedDepotBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a booking. Note that update of single fields is typically not possible (see the required                        attribute in the input model). This is because many fields are inter dependent; e.g. a commissions                        may be entered in EUR for and investment denote in USD. In such cases updating data without                        providing the exchange rate would lead to inconsistent data. We thus require a certain set of                        input data also for updates.
         * @param {number} id ID of the transaction to update
         * @param {CreateOrUpdateBookingParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransaction(id: number, body: CreateOrUpdateBookingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransaction(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.updateTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate a carve out.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valiateCarveOut(body: CarveOutParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valiateCarveOut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.valiateCarveOut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate a managed depot booking.
         * @param {CarveOutParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateManagedDepotBooking(body: CarveOutParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateManagedDepotBooking(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.validateManagedDepotBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate a switch. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {SwitchParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSwitchInvestment(body: SwitchParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionValidationErrorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSwitchInvestment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.validateSwitchInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Carve out an investment.
         * @param {BookingsApiCarveOutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carveOut(requestParameters: BookingsApiCarveOutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.carveOut(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if persisting all of the given bookings would lead to consistency errors.
         * @param {BookingsApiCheckConsistencyStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConsistencyStatus(requestParameters: BookingsApiCheckConsistencyStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.checkConsistencyStatus(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if updating the given bookings will lead to consistency problems.
         * @param {BookingsApiCheckConsistencyStatusIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConsistencyStatusId(requestParameters: BookingsApiCheckConsistencyStatusIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.checkConsistencyStatusId(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Consolidate bookings with a negative number of lots for the investments with the given ISINs.                 This will create an estimated booking whenever the number of lots at any day will                 drop below zero.                 This endpoint is designed for server-to-server use cases, but might be user by clients as well to                 re-consolidate erroneous user input.
         * @param {BookingsApiConsolidateNegativeNolRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consolidateNegativeNol(requestParameters: BookingsApiConsolidateNegativeNolRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.consolidateNegativeNol(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create (or batch update) a set of bookings und perform optional background synchronization of dividends and                      kupons if attributes \'performBackgroundTransactionSync\' is set true. In case no bookings are                      submitted (i.e. the \'createOrUpdateInvestmentParamsList\' is empty), a background sync may still                      be performed. This allows importing dividends in the absence of depot composition changes.<br>                     In case some of the transactions are invalid, the according validation errors are returned and                      only valid transactions are persisted.
         * @param {BookingsApiCreateBookingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookings(requestParameters: BookingsApiCreateBookingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.createBookings(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a booking for a managed depot.
         * @param {BookingsApiCreateManagedDepotBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedDepotBooking(requestParameters: BookingsApiCreateManagedDepotBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.createManagedDepotBooking(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a booking
         * @param {BookingsApiDeleteBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking(requestParameters: BookingsApiDeleteBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBooking(requestParameters.id, requestParameters.keepData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a booking by external ID. This is a convenience routine typically used by SaaS processes                        working on booking data.
         * @param {BookingsApiDeleteBookingByExternalIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingByExternalId(requestParameters: BookingsApiDeleteBookingByExternalIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBookingByExternalId(requestParameters.externalId, requestParameters.keepData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single split execution. In most cases rolling back (deleting) the split is the                 preferred option; however there may be bank-import related edge cases in which a user might want to                 delete a single split execution instead of the entire split.                 Deleting a single split execution will only roll back this execution but leave executions for all                 other bookings in place.                 Note: should rolling back this execution lead to negative number of lots at any case, then the                 rollback will automatically create a new booking to compensate for the negative number of lots.
         * @param {BookingsApiDeleteSplitExecutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSplitExecution(requestParameters: BookingsApiDeleteSplitExecutionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSplitExecution(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED: Estimate the number of lots moved in a transaction for managed depots. The special case here is, that we do                      NOT know the depot composition. The managing entity (usually a robo advisor) does not deliver any information                      on the number of lots. This means we need to work with \'virtual\' lots. The number of the virtual lots is not                      important; we rather care about the correct relation of the lots to each other. The respective ratio                      is calculated using the depot valuation provided; in respect to transactions executed previously.
         * @param {BookingsApiEstimateImplicitNumberOfLotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimateImplicitNumberOfLots(requestParameters: BookingsApiEstimateImplicitNumberOfLotsRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.estimateImplicitNumberOfLots(requestParameters.type, requestParameters.amount, requestParameters.depotValuation, requestParameters.date, requestParameters.investmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {BookingsApiListAndSearchAllBookingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBookings(requestParameters: BookingsApiListAndSearchAllBookingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableBookingList> {
            return localVarFp.listAndSearchAllBookings(requestParameters.id, requestParameters.accountId, requestParameters.investmentId, requestParameters.investmentType, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.dataConsistencyStatus, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.include, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a booking change
         * @param {BookingsApiPortfolioPositionsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPositionsId(requestParameters: BookingsApiPortfolioPositionsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Booking> {
            return localVarFp.portfolioPositionsId(requestParameters.id, requestParameters.include, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of bookings
         * @param {BookingsApiPublicListAndSearchAllBookingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListAndSearchAllBookings(requestParameters: BookingsApiPublicListAndSearchAllBookingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableBookingList> {
            return localVarFp.publicListAndSearchAllBookings(requestParameters.publicId, requestParameters.id, requestParameters.accountId, requestParameters.investmentId, requestParameters.investmentType, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.include, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a booking change
         * @param {BookingsApiPublicPortfolioPositionsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPortfolioPositionsId(requestParameters: BookingsApiPublicPortfolioPositionsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Booking> {
            return localVarFp.publicPortfolioPositionsId(requestParameters.id, requestParameters.publicId, requestParameters.include, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED: Detect changes to an accounts investments and return them as a list of proposed booking changes.
         * @param {BookingsApiRemoteChangesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteChanges(requestParameters: BookingsApiRemoteChangesRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountImportOrSynchronizationResult> {
            return localVarFp.remoteChanges(requestParameters.accountId, requestParameters.doNotApplyTransactionCutoff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Split an investment.
         * @param {BookingsApiSplitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        split(requestParameters: BookingsApiSplitRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.split(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Switch an investment or fund. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {BookingsApiSwitchInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchInvestment(requestParameters: BookingsApiSwitchInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.switchInvestment(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a booking for a managed depot.
         * @param {BookingsApiUpdateManagedDepotBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedDepotBooking(requestParameters: BookingsApiUpdateManagedDepotBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.updateManagedDepotBooking(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a booking. Note that update of single fields is typically not possible (see the required                        attribute in the input model). This is because many fields are inter dependent; e.g. a commissions                        may be entered in EUR for and investment denote in USD. In such cases updating data without                        providing the exchange rate would lead to inconsistent data. We thus require a certain set of                        input data also for updates.
         * @param {BookingsApiUpdateTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction(requestParameters: BookingsApiUpdateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Booking> {
            return localVarFp.updateTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a carve out.
         * @param {BookingsApiValiateCarveOutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valiateCarveOut(requestParameters: BookingsApiValiateCarveOutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.valiateCarveOut(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a managed depot booking.
         * @param {BookingsApiValidateManagedDepotBookingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateManagedDepotBooking(requestParameters: BookingsApiValidateManagedDepotBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.validateManagedDepotBooking(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a switch. A switch typically occurs if a company or fund was renamed or fused with another
         * @param {BookingsApiValidateSwitchInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSwitchInvestment(requestParameters: BookingsApiValidateSwitchInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransactionValidationErrorList> {
            return localVarFp.validateSwitchInvestment(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for carveOut operation in BookingsApi.
 * @export
 * @interface BookingsApiCarveOutRequest
 */
export interface BookingsApiCarveOutRequest {
    /**
     * The create or update parameters
     * @type {CarveOutParams}
     * @memberof BookingsApiCarveOut
     */
    readonly body: CarveOutParams
}

/**
 * Request parameters for checkConsistencyStatus operation in BookingsApi.
 * @export
 * @interface BookingsApiCheckConsistencyStatusRequest
 */
export interface BookingsApiCheckConsistencyStatusRequest {
    /**
     * CreateOrUpdateInvestment params including a list of CreateOrUpdateTransaction params
     * @type {CreateOrUpdateInvestmentParamsList}
     * @memberof BookingsApiCheckConsistencyStatus
     */
    readonly body: CreateOrUpdateInvestmentParamsList
}

/**
 * Request parameters for checkConsistencyStatusId operation in BookingsApi.
 * @export
 * @interface BookingsApiCheckConsistencyStatusIdRequest
 */
export interface BookingsApiCheckConsistencyStatusIdRequest {
    /**
     * ID of the booking to check the consistency status for
     * @type {number}
     * @memberof BookingsApiCheckConsistencyStatusId
     */
    readonly id: number

    /**
     * The transaction params
     * @type {CreateOrUpdateBookingParams}
     * @memberof BookingsApiCheckConsistencyStatusId
     */
    readonly body: CreateOrUpdateBookingParams
}

/**
 * Request parameters for consolidateNegativeNol operation in BookingsApi.
 * @export
 * @interface BookingsApiConsolidateNegativeNolRequest
 */
export interface BookingsApiConsolidateNegativeNolRequest {
    /**
     * The create or update parameters
     * @type {ConsolidateNumberOfLotsParams}
     * @memberof BookingsApiConsolidateNegativeNol
     */
    readonly body: ConsolidateNumberOfLotsParams
}

/**
 * Request parameters for createBookings operation in BookingsApi.
 * @export
 * @interface BookingsApiCreateBookingsRequest
 */
export interface BookingsApiCreateBookingsRequest {
    /**
     * The create or update parameters
     * @type {CreateMultipleBookingParams}
     * @memberof BookingsApiCreateBookings
     */
    readonly body: CreateMultipleBookingParams
}

/**
 * Request parameters for createManagedDepotBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiCreateManagedDepotBookingRequest
 */
export interface BookingsApiCreateManagedDepotBookingRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateManagedDepotParams}
     * @memberof BookingsApiCreateManagedDepotBooking
     */
    readonly body: CreateOrUpdateManagedDepotParams
}

/**
 * Request parameters for deleteBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiDeleteBookingRequest
 */
export interface BookingsApiDeleteBookingRequest {
    /**
     * ID of the booking to delete
     * @type {number}
     * @memberof BookingsApiDeleteBooking
     */
    readonly id: number

    /**
     * Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
     * @type {boolean}
     * @memberof BookingsApiDeleteBooking
     */
    readonly keepData?: boolean
}

/**
 * Request parameters for deleteBookingByExternalId operation in BookingsApi.
 * @export
 * @interface BookingsApiDeleteBookingByExternalIdRequest
 */
export interface BookingsApiDeleteBookingByExternalIdRequest {
    /**
     * External ID of the booking to delete
     * @type {string}
     * @memberof BookingsApiDeleteBookingByExternalId
     */
    readonly externalId: string

    /**
     * Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
     * @type {boolean}
     * @memberof BookingsApiDeleteBookingByExternalId
     */
    readonly keepData?: boolean
}

/**
 * Request parameters for deleteSplitExecution operation in BookingsApi.
 * @export
 * @interface BookingsApiDeleteSplitExecutionRequest
 */
export interface BookingsApiDeleteSplitExecutionRequest {
    /**
     * ID of the split execution to delete
     * @type {number}
     * @memberof BookingsApiDeleteSplitExecution
     */
    readonly id: number
}

/**
 * Request parameters for estimateImplicitNumberOfLots operation in BookingsApi.
 * @export
 * @interface BookingsApiEstimateImplicitNumberOfLotsRequest
 */
export interface BookingsApiEstimateImplicitNumberOfLotsRequest {
    /**
     * The transaction type; allowed values are buy and sell.
     * @type {string}
     * @memberof BookingsApiEstimateImplicitNumberOfLots
     */
    readonly type: string

    /**
     * The unsigned amount of cash moved in the transaction.
     * @type {number}
     * @memberof BookingsApiEstimateImplicitNumberOfLots
     */
    readonly amount: number

    /**
     * The depot valuation AFTER executing the transaction. This value is only required if we are                              adding transactions to an existing investment. Otherwise it equals the amount.
     * @type {number}
     * @memberof BookingsApiEstimateImplicitNumberOfLots
     */
    readonly depotValuation: number

    /**
     * The transaction date; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof BookingsApiEstimateImplicitNumberOfLots
     */
    readonly date: string

    /**
     * Include transactions for the given investments into the estimation; if present. If not                              given, a new investment is assumed.
     * @type {number}
     * @memberof BookingsApiEstimateImplicitNumberOfLots
     */
    readonly investmentId?: number
}

/**
 * Request parameters for listAndSearchAllBookings operation in BookingsApi.
 * @export
 * @interface BookingsApiListAndSearchAllBookingsRequest
 */
export interface BookingsApiListAndSearchAllBookingsRequest {
    /**
     * Retrieve Bookings with the given IDs
     * @type {Array<number>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly id?: Array<number>

    /**
     * Retrieve Bookings for the given Accounts
     * @type {Array<number>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Bookings for the given investments
     * @type {Array<number>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve Bookings for the given investment types (e.g. bookings for funds)
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly investmentType?: Array<ListAndSearchAllBookingsInvestmentTypeEnum>

    /**
     * (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
     * @type {Array<number>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly finApiId?: Array<number>

    /**
     * Retrieve Bookings with the given external IDs
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly externalId?: Array<string>

    /**
     * Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
     * @type {Array<'buy' | 'sell' | 'book_in' | 'book_out' | 'dividend' | 'retained_dividend' | 'purchase_right'>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly type?: Array<ListAndSearchAllBookingsTypeEnum>

    /**
     * Retrieve Bookings belonging to Investments with the given WKNs
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ISINs
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly isin?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ISINs or WKNs
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly isinOrWkn?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ticker symbols
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Retrieve Bookings in the given currencies (3-letter ISO code)
     * @type {Array<string>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly currency?: Array<string>

    /**
     * Filter bookings by data consistency status; default is no filter.                         Possible values are:                         EXACT_NOL - the bank or broker provided a cash amount and the exact number of lots moved by this order. This \\                             is the best case and yields precise data.                         BOOKING_WITH_NOL_ESTIMATED - the bank or broker provided the cash amount, but NOT the number of lots \\                             moved by this order. Thus the number of lots was estimated based on historic quote data. This generally \\                             works well if there are not too many orders.                         NEGATIVE_NOL_DELTA_RESOLUTION - this booking was created to avoid a drop of the the investments number of lots \\                             below zero at any point in time. Such a case may happen if data is missing or an estimation is incorrect.                         DELTA_RESOLUTION - there was a delta (also called tracking error) between the number of lots calculated by \\                             the order history and between the number of lots reported by the bank. Such tracking errors may occur \\                             due to data inconsistencies or due to capital measures such as carve outs, depot transfers or splits.
     * @type {Array<'EXACT_NOL' | 'BOOKING_WITH_NOL_ESTIMATED' | 'NEGATIVE_NOL_DELTA_RESOLUTION' | 'DELTA_RESOLUTION'>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly dataConsistencyStatus?: Array<ListAndSearchAllBookingsDataConsistencyStatusEnum>

    /**
     * Retrieve Bookings with amount greater or equal than the given minAmount
     * @type {number}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly minAmount?: number

    /**
     * Retrieve Bookings with amount less or equal than the given maxAmount
     * @type {number}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly maxAmount?: number

    /**
     * Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly minDate?: string

    /**
     * Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly maxDate?: string

    /**
     * Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
     * @type {Array<'split'>}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly include?: Array<ListAndSearchAllBookingsIncludeEnum>

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly format?: ListAndSearchAllBookingsFormatEnum

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof BookingsApiListAndSearchAllBookings
     */
    readonly order?: string
}

/**
 * Request parameters for portfolioPositionsId operation in BookingsApi.
 * @export
 * @interface BookingsApiPortfolioPositionsIdRequest
 */
export interface BookingsApiPortfolioPositionsIdRequest {
    /**
     * ID of the transaction to retrieve
     * @type {number}
     * @memberof BookingsApiPortfolioPositionsId
     */
    readonly id: number

    /**
     * Include the given entities in the response.
     * @type {Array<'split'>}
     * @memberof BookingsApiPortfolioPositionsId
     */
    readonly include?: Array<PortfolioPositionsIdIncludeEnum>

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof BookingsApiPortfolioPositionsId
     */
    readonly format?: PortfolioPositionsIdFormatEnum
}

/**
 * Request parameters for publicListAndSearchAllBookings operation in BookingsApi.
 * @export
 * @interface BookingsApiPublicListAndSearchAllBookingsRequest
 */
export interface BookingsApiPublicListAndSearchAllBookingsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly publicId: string

    /**
     * Retrieve Bookings with the given IDs
     * @type {Array<number>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly id?: Array<number>

    /**
     * Retrieve Bookings for the given Accounts
     * @type {Array<number>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Bookings for the given investments
     * @type {Array<number>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve Bookings for the given investment types (e.g. bookings for funds)
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly investmentType?: Array<PublicListAndSearchAllBookingsInvestmentTypeEnum>

    /**
     * (Deprecated - use externalId) Retrieve Bookings with the given finAPI IDs
     * @type {Array<number>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly finApiId?: Array<number>

    /**
     * Retrieve Bookings with the given external IDs
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly externalId?: Array<string>

    /**
     * Retrieve Bookings having the given type, allowed values are buy, sell, dividend. Note that                              kupons also have types dividend; and retained dividends have type buy (check subtype here)
     * @type {Array<'buy' | 'sell' | 'book_in' | 'book_out' | 'dividend' | 'retained_dividend' | 'purchase_right'>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly type?: Array<PublicListAndSearchAllBookingsTypeEnum>

    /**
     * Retrieve Bookings belonging to Investments with the given WKNs
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ISINs
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly isin?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ISINs or WKNs
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly isinOrWkn?: Array<string>

    /**
     * Retrieve Bookings belonging to Investments with the given ticker symbols
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Retrieve Bookings in the given currencies (3-letter ISO code)
     * @type {Array<string>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly currency?: Array<string>

    /**
     * Retrieve Bookings with amount greater or equal than the given minAmount
     * @type {number}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly minAmount?: number

    /**
     * Retrieve Bookings with amount less or equal than the given maxAmount
     * @type {number}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly maxAmount?: number

    /**
     * Retrieve Bookings with date after or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly minDate?: string

    /**
     * Retrieve Bookings with date before or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly maxDate?: string

    /**
     * Include the given entities in the response. Note that including entities in large                         responses (e.g. booking lists for large portfolios) may come with a response time penalty.
     * @type {Array<'split'>}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly include?: Array<PublicListAndSearchAllBookingsIncludeEnum>

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly format?: PublicListAndSearchAllBookingsFormatEnum

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof BookingsApiPublicListAndSearchAllBookings
     */
    readonly order?: string
}

/**
 * Request parameters for publicPortfolioPositionsId operation in BookingsApi.
 * @export
 * @interface BookingsApiPublicPortfolioPositionsIdRequest
 */
export interface BookingsApiPublicPortfolioPositionsIdRequest {
    /**
     * ID of the transaction to retrieve
     * @type {number}
     * @memberof BookingsApiPublicPortfolioPositionsId
     */
    readonly id: number

    /**
     * Public ID of the user
     * @type {string}
     * @memberof BookingsApiPublicPortfolioPositionsId
     */
    readonly publicId: string

    /**
     * Include the given entities in the response
     * @type {Array<'split'>}
     * @memberof BookingsApiPublicPortfolioPositionsId
     */
    readonly include?: Array<PublicPortfolioPositionsIdIncludeEnum>

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof BookingsApiPublicPortfolioPositionsId
     */
    readonly format?: PublicPortfolioPositionsIdFormatEnum
}

/**
 * Request parameters for remoteChanges operation in BookingsApi.
 * @export
 * @interface BookingsApiRemoteChangesRequest
 */
export interface BookingsApiRemoteChangesRequest {
    /**
     * ID of the account to synchronize
     * @type {number}
     * @memberof BookingsApiRemoteChanges
     */
    readonly accountId: number

    /**
     * Usually all transactions with an import date greater then equal the last manual or automatic                              synchronization date will be used. Setting this parameter to true will force the transaction                              classification logic to skip this cutoff and instead use the latest investment transaction                              date as cutoff if an investment was found. Otherwise all historic transactions will be                              reported.
     * @type {boolean}
     * @memberof BookingsApiRemoteChanges
     */
    readonly doNotApplyTransactionCutoff?: boolean
}

/**
 * Request parameters for split operation in BookingsApi.
 * @export
 * @interface BookingsApiSplitRequest
 */
export interface BookingsApiSplitRequest {
    /**
     * The create or update parameters
     * @type {SplitParams}
     * @memberof BookingsApiSplit
     */
    readonly body: SplitParams
}

/**
 * Request parameters for switchInvestment operation in BookingsApi.
 * @export
 * @interface BookingsApiSwitchInvestmentRequest
 */
export interface BookingsApiSwitchInvestmentRequest {
    /**
     * The create or update parameters
     * @type {SwitchParams}
     * @memberof BookingsApiSwitchInvestment
     */
    readonly body: SwitchParams
}

/**
 * Request parameters for updateManagedDepotBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiUpdateManagedDepotBookingRequest
 */
export interface BookingsApiUpdateManagedDepotBookingRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateManagedDepotParams}
     * @memberof BookingsApiUpdateManagedDepotBooking
     */
    readonly body: CreateOrUpdateManagedDepotParams
}

/**
 * Request parameters for updateTransaction operation in BookingsApi.
 * @export
 * @interface BookingsApiUpdateTransactionRequest
 */
export interface BookingsApiUpdateTransactionRequest {
    /**
     * ID of the transaction to update
     * @type {number}
     * @memberof BookingsApiUpdateTransaction
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateBookingParams}
     * @memberof BookingsApiUpdateTransaction
     */
    readonly body: CreateOrUpdateBookingParams
}

/**
 * Request parameters for valiateCarveOut operation in BookingsApi.
 * @export
 * @interface BookingsApiValiateCarveOutRequest
 */
export interface BookingsApiValiateCarveOutRequest {
    /**
     * The create or update parameters
     * @type {CarveOutParams}
     * @memberof BookingsApiValiateCarveOut
     */
    readonly body: CarveOutParams
}

/**
 * Request parameters for validateManagedDepotBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiValidateManagedDepotBookingRequest
 */
export interface BookingsApiValidateManagedDepotBookingRequest {
    /**
     * The create or update parameters
     * @type {CarveOutParams}
     * @memberof BookingsApiValidateManagedDepotBooking
     */
    readonly body: CarveOutParams
}

/**
 * Request parameters for validateSwitchInvestment operation in BookingsApi.
 * @export
 * @interface BookingsApiValidateSwitchInvestmentRequest
 */
export interface BookingsApiValidateSwitchInvestmentRequest {
    /**
     * The create or update parameters
     * @type {SwitchParams}
     * @memberof BookingsApiValidateSwitchInvestment
     */
    readonly body: SwitchParams
}

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * 
     * @summary Carve out an investment.
     * @param {BookingsApiCarveOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public carveOut(requestParameters: BookingsApiCarveOutRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).carveOut(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if persisting all of the given bookings would lead to consistency errors.
     * @param {BookingsApiCheckConsistencyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public checkConsistencyStatus(requestParameters: BookingsApiCheckConsistencyStatusRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).checkConsistencyStatus(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if updating the given bookings will lead to consistency problems.
     * @param {BookingsApiCheckConsistencyStatusIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public checkConsistencyStatusId(requestParameters: BookingsApiCheckConsistencyStatusIdRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).checkConsistencyStatusId(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Consolidate bookings with a negative number of lots for the investments with the given ISINs.                 This will create an estimated booking whenever the number of lots at any day will                 drop below zero.                 This endpoint is designed for server-to-server use cases, but might be user by clients as well to                 re-consolidate erroneous user input.
     * @param {BookingsApiConsolidateNegativeNolRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public consolidateNegativeNol(requestParameters: BookingsApiConsolidateNegativeNolRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).consolidateNegativeNol(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create (or batch update) a set of bookings und perform optional background synchronization of dividends and                      kupons if attributes \'performBackgroundTransactionSync\' is set true. In case no bookings are                      submitted (i.e. the \'createOrUpdateInvestmentParamsList\' is empty), a background sync may still                      be performed. This allows importing dividends in the absence of depot composition changes.<br>                     In case some of the transactions are invalid, the according validation errors are returned and                      only valid transactions are persisted.
     * @param {BookingsApiCreateBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public createBookings(requestParameters: BookingsApiCreateBookingsRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).createBookings(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a booking for a managed depot.
     * @param {BookingsApiCreateManagedDepotBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public createManagedDepotBooking(requestParameters: BookingsApiCreateManagedDepotBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).createManagedDepotBooking(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a booking
     * @param {BookingsApiDeleteBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public deleteBooking(requestParameters: BookingsApiDeleteBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).deleteBooking(requestParameters.id, requestParameters.keepData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a booking by external ID. This is a convenience routine typically used by SaaS processes                        working on booking data.
     * @param {BookingsApiDeleteBookingByExternalIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public deleteBookingByExternalId(requestParameters: BookingsApiDeleteBookingByExternalIdRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).deleteBookingByExternalId(requestParameters.externalId, requestParameters.keepData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single split execution. In most cases rolling back (deleting) the split is the                 preferred option; however there may be bank-import related edge cases in which a user might want to                 delete a single split execution instead of the entire split.                 Deleting a single split execution will only roll back this execution but leave executions for all                 other bookings in place.                 Note: should rolling back this execution lead to negative number of lots at any case, then the                 rollback will automatically create a new booking to compensate for the negative number of lots.
     * @param {BookingsApiDeleteSplitExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public deleteSplitExecution(requestParameters: BookingsApiDeleteSplitExecutionRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).deleteSplitExecution(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED: Estimate the number of lots moved in a transaction for managed depots. The special case here is, that we do                      NOT know the depot composition. The managing entity (usually a robo advisor) does not deliver any information                      on the number of lots. This means we need to work with \'virtual\' lots. The number of the virtual lots is not                      important; we rather care about the correct relation of the lots to each other. The respective ratio                      is calculated using the depot valuation provided; in respect to transactions executed previously.
     * @param {BookingsApiEstimateImplicitNumberOfLotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public estimateImplicitNumberOfLots(requestParameters: BookingsApiEstimateImplicitNumberOfLotsRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).estimateImplicitNumberOfLots(requestParameters.type, requestParameters.amount, requestParameters.depotValuation, requestParameters.date, requestParameters.investmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of bookings
     * @param {BookingsApiListAndSearchAllBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public listAndSearchAllBookings(requestParameters: BookingsApiListAndSearchAllBookingsRequest = {}, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).listAndSearchAllBookings(requestParameters.id, requestParameters.accountId, requestParameters.investmentId, requestParameters.investmentType, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.dataConsistencyStatus, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.include, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a booking change
     * @param {BookingsApiPortfolioPositionsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public portfolioPositionsId(requestParameters: BookingsApiPortfolioPositionsIdRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).portfolioPositionsId(requestParameters.id, requestParameters.include, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of bookings
     * @param {BookingsApiPublicListAndSearchAllBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public publicListAndSearchAllBookings(requestParameters: BookingsApiPublicListAndSearchAllBookingsRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).publicListAndSearchAllBookings(requestParameters.publicId, requestParameters.id, requestParameters.accountId, requestParameters.investmentId, requestParameters.investmentType, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.include, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a booking change
     * @param {BookingsApiPublicPortfolioPositionsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public publicPortfolioPositionsId(requestParameters: BookingsApiPublicPortfolioPositionsIdRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).publicPortfolioPositionsId(requestParameters.id, requestParameters.publicId, requestParameters.include, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED: Detect changes to an accounts investments and return them as a list of proposed booking changes.
     * @param {BookingsApiRemoteChangesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public remoteChanges(requestParameters: BookingsApiRemoteChangesRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).remoteChanges(requestParameters.accountId, requestParameters.doNotApplyTransactionCutoff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Split an investment.
     * @param {BookingsApiSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public split(requestParameters: BookingsApiSplitRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).split(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Switch an investment or fund. A switch typically occurs if a company or fund was renamed or fused with another
     * @param {BookingsApiSwitchInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public switchInvestment(requestParameters: BookingsApiSwitchInvestmentRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).switchInvestment(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a booking for a managed depot.
     * @param {BookingsApiUpdateManagedDepotBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public updateManagedDepotBooking(requestParameters: BookingsApiUpdateManagedDepotBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).updateManagedDepotBooking(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a booking. Note that update of single fields is typically not possible (see the required                        attribute in the input model). This is because many fields are inter dependent; e.g. a commissions                        may be entered in EUR for and investment denote in USD. In such cases updating data without                        providing the exchange rate would lead to inconsistent data. We thus require a certain set of                        input data also for updates.
     * @param {BookingsApiUpdateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public updateTransaction(requestParameters: BookingsApiUpdateTransactionRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).updateTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate a carve out.
     * @param {BookingsApiValiateCarveOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public valiateCarveOut(requestParameters: BookingsApiValiateCarveOutRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).valiateCarveOut(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate a managed depot booking.
     * @param {BookingsApiValidateManagedDepotBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public validateManagedDepotBooking(requestParameters: BookingsApiValidateManagedDepotBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).validateManagedDepotBooking(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate a switch. A switch typically occurs if a company or fund was renamed or fused with another
     * @param {BookingsApiValidateSwitchInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public validateSwitchInvestment(requestParameters: BookingsApiValidateSwitchInvestmentRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).validateSwitchInvestment(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllBookingsInvestmentTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type ListAndSearchAllBookingsInvestmentTypeEnum = typeof ListAndSearchAllBookingsInvestmentTypeEnum[keyof typeof ListAndSearchAllBookingsInvestmentTypeEnum];
/**
 * @export
 */
export const ListAndSearchAllBookingsTypeEnum = {
    BUY: 'buy',
    SELL: 'sell',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out',
    DIVIDEND: 'dividend',
    RETAINED_DIVIDEND: 'retained_dividend',
    PURCHASE_RIGHT: 'purchase_right'
} as const;
export type ListAndSearchAllBookingsTypeEnum = typeof ListAndSearchAllBookingsTypeEnum[keyof typeof ListAndSearchAllBookingsTypeEnum];
/**
 * @export
 */
export const ListAndSearchAllBookingsDataConsistencyStatusEnum = {
    EXACT_NOL: 'EXACT_NOL',
    BOOKING_WITH_NOL_ESTIMATED: 'BOOKING_WITH_NOL_ESTIMATED',
    NEGATIVE_NOL_DELTA_RESOLUTION: 'NEGATIVE_NOL_DELTA_RESOLUTION',
    DELTA_RESOLUTION: 'DELTA_RESOLUTION'
} as const;
export type ListAndSearchAllBookingsDataConsistencyStatusEnum = typeof ListAndSearchAllBookingsDataConsistencyStatusEnum[keyof typeof ListAndSearchAllBookingsDataConsistencyStatusEnum];
/**
 * @export
 */
export const ListAndSearchAllBookingsIncludeEnum = {
    SPLIT: 'split'
} as const;
export type ListAndSearchAllBookingsIncludeEnum = typeof ListAndSearchAllBookingsIncludeEnum[keyof typeof ListAndSearchAllBookingsIncludeEnum];
/**
 * @export
 */
export const ListAndSearchAllBookingsFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type ListAndSearchAllBookingsFormatEnum = typeof ListAndSearchAllBookingsFormatEnum[keyof typeof ListAndSearchAllBookingsFormatEnum];
/**
 * @export
 */
export const PortfolioPositionsIdIncludeEnum = {
    SPLIT: 'split'
} as const;
export type PortfolioPositionsIdIncludeEnum = typeof PortfolioPositionsIdIncludeEnum[keyof typeof PortfolioPositionsIdIncludeEnum];
/**
 * @export
 */
export const PortfolioPositionsIdFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type PortfolioPositionsIdFormatEnum = typeof PortfolioPositionsIdFormatEnum[keyof typeof PortfolioPositionsIdFormatEnum];
/**
 * @export
 */
export const PublicListAndSearchAllBookingsInvestmentTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type PublicListAndSearchAllBookingsInvestmentTypeEnum = typeof PublicListAndSearchAllBookingsInvestmentTypeEnum[keyof typeof PublicListAndSearchAllBookingsInvestmentTypeEnum];
/**
 * @export
 */
export const PublicListAndSearchAllBookingsTypeEnum = {
    BUY: 'buy',
    SELL: 'sell',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out',
    DIVIDEND: 'dividend',
    RETAINED_DIVIDEND: 'retained_dividend',
    PURCHASE_RIGHT: 'purchase_right'
} as const;
export type PublicListAndSearchAllBookingsTypeEnum = typeof PublicListAndSearchAllBookingsTypeEnum[keyof typeof PublicListAndSearchAllBookingsTypeEnum];
/**
 * @export
 */
export const PublicListAndSearchAllBookingsIncludeEnum = {
    SPLIT: 'split'
} as const;
export type PublicListAndSearchAllBookingsIncludeEnum = typeof PublicListAndSearchAllBookingsIncludeEnum[keyof typeof PublicListAndSearchAllBookingsIncludeEnum];
/**
 * @export
 */
export const PublicListAndSearchAllBookingsFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type PublicListAndSearchAllBookingsFormatEnum = typeof PublicListAndSearchAllBookingsFormatEnum[keyof typeof PublicListAndSearchAllBookingsFormatEnum];
/**
 * @export
 */
export const PublicPortfolioPositionsIdIncludeEnum = {
    SPLIT: 'split'
} as const;
export type PublicPortfolioPositionsIdIncludeEnum = typeof PublicPortfolioPositionsIdIncludeEnum[keyof typeof PublicPortfolioPositionsIdIncludeEnum];
/**
 * @export
 */
export const PublicPortfolioPositionsIdFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type PublicPortfolioPositionsIdFormatEnum = typeof PublicPortfolioPositionsIdFormatEnum[keyof typeof PublicPortfolioPositionsIdFormatEnum];
