import { UseQueryOptions } from "react-query";
import { BanksApiGetBankRequest, Bank } from "@generated/apiv3";

import { buildBanksApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBank = async (
  token: string | undefined,
  params: BanksApiGetBankRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.getBank(params);
  return data;
};

export const useGetBank = (
  params: BanksApiGetBankRequest,
  options?: UseQueryOptions<Bank, AxiosApiError, Bank>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKS_GET_BANK, params],
    ({ token }) => getBank(token, params),
    options
  );
};
