import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, styled, Theme, useMediaQuery } from "@mui/material";
import {
  Link,
  matchPath,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useAccountTypeAddText } from "@features/accounts/hooks/useAccountTypeAddText";
import { PageLayout } from "@components";
import { ReactComponent as BankVerbindungImage } from "./Bankverbindung.svg";
import { ReactComponent as ManuellImage } from "./Manuell.svg";

export const AccountAddSelection = ({
  isDepots,
  type,
}: {
  isDepots: boolean;
  type?: string | null;
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} width="100%" columnSpacing={4}>
      <Grid item xs={12} sm={12} md={6}>
        <StyledBox>
          <Box mb={4} width="100%">
            <BankVerbindungImage />
          </Box>
          <Button
            variant="contained"
            color="primary"
            data-testid="import-bank"
            component={Link}
            to={`/import-bank-connection/select-bank${
              type ? `?type=${type}` : ""
            }`}
            fullWidth
          >
            {t("accounts.add.importBank")}
          </Button>
        </StyledBox>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <StyledBox>
          <Box mb={4} width="100%">
            <ManuellImage />
          </Box>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            data-testid="add-manually"
            to={`/${isDepots ? "depots" : "accounts"}/add-manually${
              type ? `?type=${type}` : ""
            }`}
            fullWidth
          >
            {t("accounts.add.importManualDepot")}
          </Button>
        </StyledBox>
      </Grid>
    </Grid>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  border: `1px solid ${theme.palette.border.secondary}`,
  padding: theme.spacing(4, 5),
}));

export const AccountAddChoicePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isDepots = matchPath("/depots/add", location.pathname);
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const text = useAccountTypeAddText();

  const breadcrumbs = [
    { to: "/", text: t("breadcrumbs.home") },
    {
      to: "/depots-accounts",
      text: t("depotsAccounts.title"),
    },
    {
      text,
    },
  ];

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title={t("depotsAccounts.addTitle")}
      subtitle={t("depotsAccounts.addSubtitle")}
      layout="centered"
      pt={isSm ? 0 : 20}
    >
      <AccountAddSelection isDepots={Boolean(isDepots)} type={type} />
    </PageLayout>
  );
};
