import React from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowIcon } from "@icons/myicons/arrow-left.svg";
import { BookingTypeEnum } from "@api";
import { useHelpers } from "../hooks/useHelpers";

const Container = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  min-height: 20px;
`;

const Column = styled(Typography)`
  align-self: center;
  display: flex;
  align-items: center;
`;

export interface Props {
  testId: string;
  bookingDate: Date;
  type: BookingTypeEnum;
  numberOfLots: number;
  numberOfLotsAfterSplit?: number;
}

export const SplitBooking = ({
  testId,
  bookingDate,
  type,
  numberOfLots,
  numberOfLotsAfterSplit,
}: Props) => {
  const { t } = useTranslation();
  const { toString } = useHelpers();
  const isSplit = !!numberOfLotsAfterSplit;

  return (
    <Container data-testid={testId}>
      <Column variant="body1" color={isSplit ? "textPrimary" : "textSecondary"}>
        {format(bookingDate, "dd.MM.yyyy")}
        {` (${t(`bookingTypes.${type}`)})`}
      </Column>
      <Column
        variant="body1"
        color={isSplit ? "textPrimary" : "textSecondary"}
        fontWeight={400}
      >
        {toString(numberOfLots)}
        {isSplit && (
          <>
            <Box display="flex" sx={{ transform: "rotate(180deg)" }}>
              <ArrowIcon />
            </Box>
            {toString(numberOfLotsAfterSplit)}
          </>
        )}
      </Column>
    </Container>
  );
};
