import { UseMutationOptions } from "react-query";
import {
  UsersApiChangeAutoSignOutDurationRequest,
  User,
} from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const changeAutoSignOutDuration = async (
  token: string | undefined,
  params: UsersApiChangeAutoSignOutDurationRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.changeAutoSignOutDuration(params);
  return data;
};

export const useChangeAutoSignOutDuration = (
  options?: UseMutationOptions<
    User,
    AxiosApiError,
    UsersApiChangeAutoSignOutDurationRequest
  >
) => {
  return useAuthMutation(changeAutoSignOutDuration, options, true);
};
