import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiImportBankConnectionRequest,
  BankConnection,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const importBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiImportBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.importBankConnection(params);
  return data;
};

export const useImportBankConnection = (
  options?: UseMutationOptions<
    BankConnection,
    AxiosApiError,
    BankConnectionsApiImportBankConnectionRequest
  >
) => {
  return useAuthMutation(importBankConnection, options, false);
};
