import { useQueryClient } from "react-query";
import {
  getDepotSynchronizationLog,
  getDepotSynchronizationLogs,
  rollbackDepotSynchronizationLog,
} from "@api/v1/methods";
import { useAuthQuery, useAuthMutation } from "@api/auth";
import { GetDepotSynchronizationLogParams } from "@api/v1/model";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const useGetDepotSynchronizationLog = (id?: number) =>
  useAuthQuery(
    ["get-synchronization-log", id!],
    ({ token }) => getDepotSynchronizationLog(token, id!),
    {
      enabled: !!id,
    }
  );

export const useGetDepotSynchronizationLogs = (
  params?: GetDepotSynchronizationLogParams
) =>
  useAuthQuery(
    ["get-synchronization-logs", params!],
    ({ token }) => getDepotSynchronizationLogs(token, params!),
    {
      enabled: !!params,
    }
  );

export const useRollbackDepotSynchronizationLog = () => {
  const queryClient = useQueryClient();
  return useAuthMutation(rollbackDepotSynchronizationLog, {
    useErrorBoundary: false,
    onSuccess: () => {
      queryClient.invalidateQueries("get-synchronization-logs");
      queryClient.invalidateQueries(
        CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS
      );
    },
  });
};
