import { UseMutationOptions } from "react-query";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const refreshDividendPredictions = async (token: string | undefined) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.refreshDividendPredictions();
  return data;
};

export const useRefreshDividendPredictions = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(refreshDividendPredictions, options, false);
};
