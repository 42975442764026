import { Box } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import React from "react";
import i18next from "i18next";
import {
  RHFCountryAutocomplete,
  RHFSelect,
  RHFTextField,
  RHFCheckbox,
  RHFAutocomplete,
  RHFRadio,
  RHFCardGroup,
  RHFDatePicker,
  RHFPassword,
} from "@components/common/forms";
import { RHFSwitch } from "@components/common/forms/RHFSwitch";
import { FormElement } from "./types";

export const renderSelectValue = (
  value: string | string[],
  options: Record<string, string> | string[],
  label?: string
) => {
  let valueToRender = label ? (
    <Box color="text.secondary">{label}</Box>
  ) : undefined;

  if (Array.isArray(value) && value.length > 0) {
    valueToRender = (
      <>
        {(Array.isArray(options) ? value : value.map((v) => options[v])).join(
          ", "
        )}
      </>
    );
  }
  if (typeof value === "string" && value.length > 0) {
    valueToRender = <>{Array.isArray(options) ? value : options[value]}</>;
  }

  return valueToRender;
};

interface CustomProps {
  [key: string]: any;
  label?: string | React.ReactNode;
}

export const renderFormElement = (
  translationNamespace: string,
  formElement: FormElement,
  control: Control<any>,
  props: CustomProps = {}
) => {
  const labelKey = `${translationNamespace}.${formElement.name}`;
  const formElementLabel = i18next.exists(labelKey)
    ? i18next.t<string>(labelKey)
    : undefined;

  const placeholderKey = `${translationNamespace}.${formElement.name}_placeholder`;
  const formElementPlaceholder = i18next.exists(placeholderKey)
    ? i18next.t<string>(placeholderKey)
    : formElementLabel;

  const tooltipTitleKey = `${translationNamespace}.${formElement.name}_tooltipTitle`;
  const formElementTooltipTitle = i18next.exists(tooltipTitleKey)
    ? i18next.t<string>(tooltipTitleKey)
    : undefined;

  const tooltipTextKey = `${translationNamespace}.${formElement.name}_tooltipText`;
  const formElementTooltipText = i18next.exists(tooltipTextKey)
    ? i18next.t<string>(tooltipTextKey)
    : undefined;

  switch (formElement.type) {
    case "text":
    case "number":
    case "email":
      return (
        <RHFTextField
          label={formElementLabel}
          variant="outlined"
          type={formElement.type}
          name={formElement.name}
          placeholder={formElementPlaceholder}
          control={control}
          fullWidth
          testId={formElement.testId}
          optional={formElement.optional}
          {...props}
        />
      );
    case "password":
      return (
        <RHFPassword
          label={formElementLabel}
          variant="outlined"
          type={formElement.type}
          name={formElement.name}
          placeholder={formElementPlaceholder}
          control={control}
          fullWidth
          testId={formElement.testId}
          optional={formElement.optional}
          {...props}
        />
      );
    case "checkbox":
      return (
        <RHFCheckbox
          label={props.label || formElementLabel}
          name={formElement.name}
          control={control}
          data-testid={formElement.testId}
          {...props}
        />
      );
    case "radio":
      return (
        <RHFRadio
          label={formElementLabel}
          name={formElement.name}
          control={control}
          data-testid={formElement.testId}
          value={formElement.value}
          {...props}
        />
      );
    case "radio-group":
      return (
        <RHFCardGroup
          {...formElement}
          control={control}
          label={formElementLabel}
          translationNamespace={translationNamespace}
          {...props}
        />
      );
    case "select":
      return (
        <RHFSelect
          name={formElement.name}
          control={control}
          label={formElementLabel}
          data-testid={formElement.testId || "select"}
          displayEmpty
          renderValue={(value) =>
            renderSelectValue(
              value as string | string[],
              formElement.options,
              formElementPlaceholder || formElementLabel
            )
          }
          optional={formElement.optional}
          options={formElement.options}
          {...props}
        />
      );
    case "autocomplete":
      return (
        <RHFAutocomplete
          options={formElement.options}
          // @ts-ignore
          label={formElementLabel}
          name={formElement.name}
          testId={formElement.testId}
          control={control}
          optional={formElement.optional}
          {...props}
        />
      );
    case "date":
      return (
        <RHFDatePicker
          name={formElement.name}
          label={formElementLabel}
          placeholder={formElementPlaceholder}
          control={control}
          optional={formElement.optional}
          testId={formElement.testId}
          {...props}
        />
      );
    case "region":
      return (
        <Controller
          name={formElement.name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <RHFCountryAutocomplete
                testId={formElement.testId}
                placeholder={formElementLabel}
                onChange={(_, region) => field.onChange(region)}
                validationError={error}
                value={field.value?.id}
                optional={formElement.optional}
              />
            );
          }}
        />
      );
    case "switch":
      return (
        <RHFSwitch
          label={formElementLabel || formElement.label}
          name={formElement.name}
          control={control}
          testId={formElement.testId}
          tooltip={{
            title: formElementTooltipTitle,
            text: formElementTooltipText,
          }}
          {...props}
        />
      );
    default:
      return null;
  }
};
