import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import { useMyself } from "@api";
import { Transaction } from "@generated/apiv1";
import { useTableSortWithData } from "@hooks";
import { LoadingPlaceHolder, AccountRowLoading } from "@components";
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import {
  useDownloadTransactions,
  useInfiniteTransactions,
} from "@api/v1/hooks/useTransactions";
import { filterTransactionsByPurposeAndInvestmentName } from "@helpers/filters";
import { createObjectUrl, downloadFile } from "@helpers/files";
import { AccountProtocolTableHead } from "@components/Accounts/TransactionTable/AccountProtocolTableHead";
import { AccountProtocolTableBody } from "@components/Accounts/TransactionTable/AccountProtocolTableBody";
import { Table } from "@components/Table";
import { BlurForNonPro } from "@components/BlurForNonPro";

const NoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const TableContainerStyled = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(6),

  [theme.breakpoints.down(750)]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

interface AccountProtocolTableProps {
  accountId: number;
  range?: RangeType;
}

export const AccountProtocolTable = (props: AccountProtocolTableProps) => {
  const { accountId, range } = props;
  const theme = useTheme();
  const isDownSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { data: myself } = useMyself();
  const isUserFree = myself?.isUserFree;

  const [filter, setFilter] = useState("");
  const [debouncedFilter] = useDebounce(filter, 500);

  const { t } = useTranslation();

  const {
    data: transactionsResponse,
    isLoading: isTableDataLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteTransactions(
    {
      accountId: [accountId],
      minDate: range?.startDate?.toISOString(),
      maxDate: range?.endDate.toISOString(),
    },
    {
      enabled: Boolean(accountId),
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) =>
        (lastPage.paging?.page || 0) + 1,
      refetchOnWindowFocus: false,
    }
  );

  const transactions = (transactionsResponse?.pages || []).reduce(
    (acc, page) => {
      return [...acc, ...(page?.transactions || [])];
    },
    [] as Transaction[]
  );

  const {
    data: sortedTransactions,
    sortField,
    sortDirection,
    sort,
  } = useTableSortWithData(transactions, {
    field: "valueDate",
    direction: "DESC",
  });

  const { mutate: downloadTransactions } = useDownloadTransactions(
    {
      format: "csv",
      accountId: [accountId],
      minDate: range?.startDate?.toISOString(),
      maxDate: range?.endDate.toISOString(),
      order: `${sortField} ${sortDirection?.toLowerCase()}`,
    },
    {
      onSuccess: (data) => {
        const url = createObjectUrl(data, "text/csv");
        downloadFile(url, "transactions.csv");
      },
    }
  );

  const handleSort = (name: string) => {
    sort(name);
  };

  if (isTableDataLoading) {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <AccountRowLoading />
        <AccountRowLoading />
        <AccountRowLoading />
      </Box>
    );
  }

  const filteredTransactions = filterTransactionsByPurposeAndInvestmentName(
    sortedTransactions,
    debouncedFilter
  );

  return (
    <TableContainerStyled>
      <Header>
        <Typography variant="h5">
          {t("singleAccountPage.protocolTable.title")}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={4}
          width="100%"
          flexDirection={isDownSm ? "column" : "row"}
        >
          <TextField
            placeholder={t(
              "singleAccountPage.protocolTable.search.placeholder"
            )}
            InputProps={{
              endAdornment: (
                <Box color="icon.primary" height="20px">
                  <SearchIcon
                    width="20px"
                    height="20px"
                    color={theme.palette.text.tertiary}
                  />
                </Box>
              ),
            }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{
              height: "48ox",
              width: "300px",
              [theme.breakpoints.down(750)]: { width: "100%" },
            }}
          />
          <BlurForNonPro sx={{ flexShrink: 0 }}>
            <Button
              color="grey"
              variant="contained"
              sx={{ cursor: isUserFree ? "initial" : "pointer" }}
              fullWidth={isDownSm}
              startIcon={isUserFree ? <LockIcon /> : undefined}
              onClick={isUserFree ? undefined : downloadTransactions}
            >
              {t("singleAccountPage.protocolTable.export")}
            </Button>
          </BlurForNonPro>
        </Box>
      </Header>

      <InfiniteScroll
        next={fetchNextPage}
        dataLength={filteredTransactions.length}
        hasMore={hasNextPage || false}
        loader={isFetchingNextPage ? <LoadingPlaceHolder /> : null}
      >
        <Table>
          <AccountProtocolTableHead
            onSort={handleSort}
            sortField={sortField || ""}
            sortDirection={sortDirection || "NONE"}
          />
          <AccountProtocolTableBody data={filteredTransactions} />
        </Table>

        {!isTableDataLoading && !filteredTransactions.length && (
          <NoItemsContainer>
            <Typography variant="body2" color="textSecondary">
              {t("singleAccountPage.protocolTable.noItems")}
            </Typography>
          </NoItemsContainer>
        )}
      </InfiniteScroll>
    </TableContainerStyled>
  );
};
