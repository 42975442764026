import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiListAndSearchAllBankConnectionsRequest,
  BankConnectionListWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllBankConnections = async (
  token: string | undefined,
  params?: BankConnectionsApiListAndSearchAllBankConnectionsRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.listAndSearchAllBankConnections(params);
  return data;
};

export const useListAndSearchAllBankConnections = (
  params?: BankConnectionsApiListAndSearchAllBankConnectionsRequest,
  options?: UseQueryOptions<
    BankConnectionListWrapper,
    AxiosApiError,
    BankConnectionListWrapper
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_LIST_AND_SEARCH_ALL_BANK_CONNECTIONS, params],
    ({ token }) => listAndSearchAllBankConnections(token, params),
    options
  );
};
