import { UseMutationOptions } from "react-query";
import {
  MandatorConfigurationsApiUpdateMandatorConfigurationRequest,
  MandatorConfiguration,
} from "@generated/apiv1";

import { buildMandatorConfigurationsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateMandatorConfiguration = async (
  token: string | undefined,
  params: MandatorConfigurationsApiUpdateMandatorConfigurationRequest
) => {
  const apiInstance = buildMandatorConfigurationsApi(token);
  const { data } = await apiInstance.updateMandatorConfiguration(params);
  return data;
};

export const useUpdateMandatorConfiguration = (
  options?: UseMutationOptions<
    MandatorConfiguration,
    AxiosApiError,
    MandatorConfigurationsApiUpdateMandatorConfigurationRequest
  >
) => {
  return useAuthMutation(updateMandatorConfiguration, options, false);
};
