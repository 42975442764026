import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SubscriptionType } from "@features/settingsPage/types";
import { formatDateDDMMMMYYYY } from "@helpers";
import { ReactComponent as SuccessIcon } from "@icons/myicons/success.svg";
import { ReactComponent as MobileAppScreenIcon } from "@icons/myicons/mobile-app-screen.svg";
import { ReactComponent as TagPlus } from "@icons/tag_plus.svg";

export const SubscriptionDetails = ({
  subscriptionType,
  endDate,
  canceledDate,
}: {
  subscriptionType: SubscriptionType;
  endDate?: string;
  canceledDate?: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isFreeUser = subscriptionType === "free";
  const isRCUser = subscriptionType === "revenueCat";
  const isStripeUser = subscriptionType === "stripe";

  return (
    <>
      {isStripeUser && canceledDate && (
        <Typography
          variant="body1"
          fontSize="18px"
          lineHeight="28px"
          fontWeight={500}
          mb={theme.spacing(8)}
        >
          {t("settingsPage.subscription.activeSubscription.canceled", {
            date: formatDateDDMMMMYYYY(canceledDate),
          })}
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p="24px 16px"
        mb={theme.spacing(8)}
        borderRadius={theme.spacing(2)}
        sx={{ backgroundColor: theme.palette.background.neutral }}
      >
        {isFreeUser && (
          <SuccessIcon
            height={100}
            width={112}
            style={{ marginBottom: theme.spacing(4) }}
          />
        )}
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(2)}
          mb={theme.spacing(4)}
        >
          <Typography variant="h3" fontSize="26px" lineHeight="34px">
            Copilot
          </Typography>
          <TagPlus />
        </Box>
        {!isFreeUser && endDate && (
          <Typography variant="subtitle1" lineHeight="28px">
            {t("settingsPage.subscription.activeSubscription.activeUntil", {
              date: formatDateDDMMMMYYYY(endDate),
            })}
          </Typography>
        )}
        {(isRCUser || isFreeUser) && (
          <Typography
            variant="subtitle1"
            lineHeight="28px"
            letterSpacing={-0.65}
            paddingTop={!isFreeUser && endDate ? theme.spacing(2) : 0}
          >
            {isRCUser &&
              t("settingsPage.subscription.activeSubscription.automaticRenew")}
            {isFreeUser &&
              t(
                "settingsPage.subscription.activeSubscription.freeSubscription"
              )}
          </Typography>
        )}
      </Box>
      {(isStripeUser || isRCUser) && (
        <Typography
          variant="body1"
          fontSize="18px"
          lineHeight="28px"
          fontWeight={400}
          letterSpacing={-0.45}
          mb={theme.spacing(isRCUser ? 2 : 8)}
        >
          {isStripeUser &&
            t("settingsPage.subscription.activeSubscription.description")}
          {isRCUser &&
            t("settingsPage.subscription.activeSubscription.rcDescription")}
        </Typography>
      )}
      {isRCUser && (
        <Box width="100%" display="flex" justifyContent="center">
          <MobileAppScreenIcon />
        </Box>
      )}
    </>
  );
};
