import React from "react";
import { useTranslation } from "react-i18next";
import { SelectStepProps, SelectStep } from "@components/Wizard/SelectStep";

export const SelectTransferTypeStep = (
  props: SelectStepProps<"full" | "partial">
) => {
  const { t } = useTranslation();
  return (
    <SelectStep
      {...props}
      testId="select-transfer-type-step"
      options={[
        {
          label: t("depotTransfer.selectTransferTypeStep.full"),
          value: "full",
          testId: "full",
        },
        {
          label: t("depotTransfer.selectTransferTypeStep.partial"),
          value: "partial",
          testId: "partial",
        },
      ]}
      question={t("depotTransfer.selectTransferTypeStep.question")}
    />
  );
};
