/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateInsuranceProviderParams } from '../model';
// @ts-ignore
import { InsuranceProvider } from '../model';
/**
 * InsuranceProvidersApi - axios parameter creator
 * @export
 */
export const InsuranceProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an Insurance Provider
         * @param {CreateOrUpdateInsuranceProviderParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsuranceProvider: async (body: CreateOrUpdateInsuranceProviderParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createInsuranceProvider', 'body', body)
            const localVarPath = `/v1/insuranceProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark an Insurance Provider as delted
         * @param {number} id ID of the insurance provider to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsuranceProvider: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInsuranceProvider', 'id', id)
            const localVarPath = `/v1/insuranceProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceProvidersApi - functional programming interface
 * @export
 */
export const InsuranceProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an Insurance Provider
         * @param {CreateOrUpdateInsuranceProviderParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsuranceProvider(body: CreateOrUpdateInsuranceProviderParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsuranceProvider(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceProvidersApi.createInsuranceProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Mark an Insurance Provider as delted
         * @param {number} id ID of the insurance provider to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInsuranceProvider(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInsuranceProvider(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceProvidersApi.deleteInsuranceProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsuranceProvidersApi - factory interface
 * @export
 */
export const InsuranceProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceProvidersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an Insurance Provider
         * @param {InsuranceProvidersApiCreateInsuranceProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsuranceProvider(requestParameters: InsuranceProvidersApiCreateInsuranceProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsuranceProvider> {
            return localVarFp.createInsuranceProvider(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark an Insurance Provider as delted
         * @param {InsuranceProvidersApiDeleteInsuranceProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsuranceProvider(requestParameters: InsuranceProvidersApiDeleteInsuranceProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteInsuranceProvider(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInsuranceProvider operation in InsuranceProvidersApi.
 * @export
 * @interface InsuranceProvidersApiCreateInsuranceProviderRequest
 */
export interface InsuranceProvidersApiCreateInsuranceProviderRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateInsuranceProviderParams}
     * @memberof InsuranceProvidersApiCreateInsuranceProvider
     */
    readonly body: CreateOrUpdateInsuranceProviderParams
}

/**
 * Request parameters for deleteInsuranceProvider operation in InsuranceProvidersApi.
 * @export
 * @interface InsuranceProvidersApiDeleteInsuranceProviderRequest
 */
export interface InsuranceProvidersApiDeleteInsuranceProviderRequest {
    /**
     * ID of the insurance provider to delete
     * @type {number}
     * @memberof InsuranceProvidersApiDeleteInsuranceProvider
     */
    readonly id: number
}

/**
 * InsuranceProvidersApi - object-oriented interface
 * @export
 * @class InsuranceProvidersApi
 * @extends {BaseAPI}
 */
export class InsuranceProvidersApi extends BaseAPI {
    /**
     * 
     * @summary Create an Insurance Provider
     * @param {InsuranceProvidersApiCreateInsuranceProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProvidersApi
     */
    public createInsuranceProvider(requestParameters: InsuranceProvidersApiCreateInsuranceProviderRequest, options?: RawAxiosRequestConfig) {
        return InsuranceProvidersApiFp(this.configuration).createInsuranceProvider(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark an Insurance Provider as delted
     * @param {InsuranceProvidersApiDeleteInsuranceProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProvidersApi
     */
    public deleteInsuranceProvider(requestParameters: InsuranceProvidersApiDeleteInsuranceProviderRequest, options?: RawAxiosRequestConfig) {
        return InsuranceProvidersApiFp(this.configuration).deleteInsuranceProvider(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

