import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiRegionAndIndustryClassificationRequest,
  RegionAndIndustryClassification,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const regionAndIndustryClassification = async (
  token: string | undefined,
  params: InvestmentsApiRegionAndIndustryClassificationRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.regionAndIndustryClassification(params);
  return data;
};

export const useRegionAndIndustryClassification = (
  params: InvestmentsApiRegionAndIndustryClassificationRequest,
  options?: UseQueryOptions<
    RegionAndIndustryClassification,
    AxiosApiError,
    RegionAndIndustryClassification
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_REGION_AND_INDUSTRY_CLASSIFICATION, params],
    ({ token }) => regionAndIndustryClassification(token, params),
    options
  );
};
