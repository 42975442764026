import React from "react";
import { useTranslation } from "react-i18next";
import { format, isBefore, parseISO, startOfDay } from "date-fns";
import { Box, Divider, Grid, Typography, Table } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {
  BookingType,
  SortableTableHeader,
  TableRow,
  TypographyWithInfoIcon,
} from "@components";
import { sellTypes } from "@features/transactionWizard/modal/steps/constants";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { getCurrencySymbol } from "@helpers";
import { useLabelHelpers } from "@features/transactionWizard/modal/hooks/useLabelHelpers";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { SplitValues } from "../stateMachine/types/splitValues";

export interface Props {
  splitValues: SplitValues;
  investmentType?: InvestmentTypeEnum;
  handleNext: () => void;
  isLoading: boolean;
}

const nolAfterSplit = (nol: number, splitRatio: number, isReverse: boolean) =>
  isReverse ? nol / splitRatio : nol * splitRatio;

export const SplitPreviewStep = ({
  splitValues,
  investmentType,
  handleNext,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { formatPrice, nolLabel } = useLabelHelpers();
  const investmentCurrency = "EUR";
  const currencySymbol = getCurrencySymbol(investmentCurrency);

  const beforeSplit =
    splitValues.bookings?.reduce((acc, booking) => {
      return (
        acc +
        (sellTypes.includes(booking.type)
          ? -booking.numberOfLots
          : booking.numberOfLots)
      );
    }, 0) || 0;

  const afterSplit =
    splitValues.bookings?.reduce((acc, booking) => {
      const isSplit = isBefore(
        parseISO(booking.valueDate),
        startOfDay(splitValues.date!)
      );

      const numberOfLots = sellTypes.includes(booking.type)
        ? -booking.numberOfLots
        : booking.numberOfLots;

      if (!isSplit) return acc + numberOfLots;

      return (
        acc +
        (splitValues.isReverse
          ? numberOfLots / splitValues.ratio!
          : numberOfLots * splitValues.ratio!)
      );
    }, 0) || 0;

  const getNumberOfLots = (numberOfLots: number) => {
    return `${formatPrice(undefined, numberOfLots)} ${
      investmentType === "31_bond"
        ? ""
        : nolLabel(investmentType, currencySymbol)
    }`;
  };

  return (
    <StepContainer
      handleNext={handleNext}
      isLoading={isLoading}
      buttonTitle={t("transactionWizardModal.split.preview.save")}
    >
      <Box display="flex" flexDirection="column" gap={5} height="100%">
        <Divider />
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <Typography variant="subtitle1" fontWeight={600}>
              {t("transactionWizardModal.split.preview.date")}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" fontWeight={400}>
              {format(splitValues?.date || new Date(), "dd.MM.yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1" fontWeight={600}>
              {t("transactionWizardModal.split.preview.ratio")}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" fontWeight={400}>
              {splitValues.isReverse
                ? `${splitValues.ratio}:1`
                : `1:${splitValues.ratio}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Typography variant="subtitle1" fontWeight={600}>
          {t("transactionWizardModal.split.preview.info")}
        </Typography>
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Box height="100%" minHeight={0} minWidth={600}>
            <Table
              stickyHeader
              sx={{
                "& .MuiTableCell-head .MuiTypography-root": {
                  fontWeight: 600,
                  fontSize: "18px",
                  color: (theme) => theme.palette.text.primary,
                },
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                  padding: "12px 12px",
                },
                marginLeft: "-12px",
                marginRight: "-12px",
              }}
            >
              <TableHead>
                <TableRow>
                  <SortableTableHeader
                    text={t("transactionWizardModal.split.preview.date")}
                    sortByName=""
                  />
                  <SortableTableHeader
                    text={t("transactionWizardModal.split.preview.bookingType")}
                    sortByName=""
                  />
                  <SortableTableHeader
                    text={t("transactionWizardModal.split.preview.beforeSplit")}
                    sortByName=""
                  />
                  <SortableTableHeader
                    text={t("transactionWizardModal.split.preview.afterSplit")}
                    sortByName=""
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {(splitValues.bookings || []).map((booking) => {
                  const isSplit = isBefore(
                    parseISO(booking.valueDate),
                    startOfDay(splitValues.date!)
                  );

                  return (
                    <TableRow key={booking.id}>
                      <TableCell>
                        {isSplit ? (
                          <Typography variant="subtitle1" fontWeight={600}>
                            {format(parseISO(booking.valueDate), "dd.MM.yyyy")}
                          </Typography>
                        ) : (
                          <TypographyWithInfoIcon
                            variant="subtitle1"
                            fontWeight={600}
                            tooltipText={t(
                              "transactionWizardModal.split.preview.tooltipInfo"
                            )}
                          >
                            {format(parseISO(booking.valueDate), "dd.MM.yyyy")}
                          </TypographyWithInfoIcon>
                        )}
                      </TableCell>
                      <TableCell>
                        <BookingType type={booking.type} />
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {getNumberOfLots(booking.numberOfLots)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {getNumberOfLots(
                            isSplit
                              ? nolAfterSplit(
                                  booking.numberOfLots,
                                  splitValues.ratio ?? 1,
                                  splitValues.isReverse ?? false
                                )
                              : booking.numberOfLots
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {t("transactionWizardModal.split.preview.total")}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight={400}>
                      {getNumberOfLots(beforeSplit)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight={400}>
                      {getNumberOfLots(afterSplit)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </StepContainer>
  );
};
