import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "@emotion/styled";
import { Investment, InvestmentPerformance, RealizedGainByBooking } from "@api";
import {
  toRoundedNumber,
  toRoundedNumberOfLots,
  toCurrency,
  toCurrencyCustomShort,
  toPercent,
} from "@helpers";

import { GainLossPercents } from "@components";
import { DataBlockLayout } from "@components/common/dataBlock";

interface Props {
  investment: Investment;
  realizedGainByBooking: RealizedGainByBooking;
  investmentPerformance: InvestmentPerformance;
}

const Block = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const Content = styled("div")(({ theme }) => ({
  "& > *": {
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    paddingBottom: theme.spacing(2),
  },

  "& > *:last-child": {
    borderBottom: "none",
  },

  "& > *:first-of-type": {
    paddingTop: theme.spacing(0),
  },
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: "none",
  boxShadow: "none",

  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },

  "&:before": {
    display: "none",
  },

  "&.Mui-expanded": {
    margin: `0 0 ${theme.spacing(1)}`,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0),

  "& .MuiAccordionSummary-content": {
    margin: `${theme.spacing(0)} !important`,
  },
}));

const extractRequiredValues = (
  currency: string,
  investment: Investment,
  realizedGainByBooking: RealizedGainByBooking,
  investmentPerformance: InvestmentPerformance
) => {
  const isForeignCurrency = currency !== investment.account.currency;

  if (isForeignCurrency) {
    return {
      purchasePrice: investment.purchasePrice || 0,
      purchaseValue: investment.purchaseValue || 0,
      currentMarketValue: investment.currentMarketValue || 0,
      returnOnInvestment: investment.returnOnInvestment || 0,
      returnOnInvestmentInPercent: investment.returnOnInvestmentInPercent || 0,
      netTotalGain: realizedGainByBooking.accumulatedGain?.netGain || 0,
      grossTradingGain:
        realizedGainByBooking.accumulatedTradingGain?.grossGain || 0,
      grossDividendGain:
        realizedGainByBooking.accumulatedDividendGain?.grossGain || 0,
      commission: -1 * (realizedGainByBooking.accumulatedGain?.commission || 0),
      taxAmount: -1 * (realizedGainByBooking.accumulatedGain?.taxAmount || 0),
      performance: investmentPerformance.inInvestmentCurrency,
      showIrr:
        (investmentPerformance.holdingTimeInYears || 0) >= 1 &&
        investmentPerformance.inInvestmentCurrency?.irr,
    };
  }
  return {
    purchasePrice: investment.purchasePriceAC || 0,
    purchaseValue: investment.purchaseValueAC || 0,
    currentMarketValue: investment.currentMarketValueAC || 0,
    returnOnInvestment: investment.returnOnInvestmentAC || 0,
    returnOnInvestmentInPercent: investment.returnOnInvestmentInPercentAC || 0,
    netTotalGain: realizedGainByBooking.accumulatedGain?.netGainAC || 0,
    grossTradingGain:
      realizedGainByBooking.accumulatedTradingGain?.grossGainAC || 0,
    grossDividendGain:
      realizedGainByBooking.accumulatedDividendGain?.grossGainAC || 0,
    commission: -1 * (realizedGainByBooking.accumulatedGain?.commissionAC || 0),
    taxAmount: -1 * (realizedGainByBooking.accumulatedGain?.taxAmountAC || 0),
    performance: investmentPerformance.inAccountCurrency,
    showIrr:
      (investmentPerformance.holdingTimeInYears || 0) >= 1 &&
      investmentPerformance.inAccountCurrency?.irr,
  };
};

export const GainLossStatistics = ({
  isLoading,
  investment,
  realizedGainByBooking,
  investmentPerformance,
}: Partial<Props> & { isLoading: boolean }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<string>();
  const defaultCurrency = investment?.account?.currency;
  useEffect(() => {
    if (defaultCurrency && !currency) {
      setCurrency(defaultCurrency);
    }
  }, [defaultCurrency, currency]);

  return (
    <DataBlockLayout
      title={t("investment.details.gainLossStatistics.title")}
      isLoading={isLoading}
    >
      {investment &&
      realizedGainByBooking &&
      investmentPerformance &&
      currency ? (
        <GainLossStatisticsBlock
          investment={investment}
          realizedGainByBooking={realizedGainByBooking}
          investmentPerformance={investmentPerformance}
          currency={currency}
        />
      ) : (
        <EmptyGainLossStatisticsBlock />
      )}
    </DataBlockLayout>
  );
};

const GainLossStatisticsBlock = ({
  investment,
  realizedGainByBooking,
  investmentPerformance,
  currency,
}: Props & { currency: string }) => {
  const { t } = useTranslation();

  const isForeignCurrencyInvestment =
    investment?.currency !== investment?.account?.currency;

  const values = extractRequiredValues(
    currency,
    investment,
    realizedGainByBooking,
    investmentPerformance
  );

  return (
    <Content>
      <Block>
        <Typography color="textSecondary" variant="body2">
          {t("investment.details.gainLossStatistics.gainAndLoss")}
        </Typography>
        <Typography variant="h6" data-testid="gain-and-loss" fontWeight={700}>
          {toCurrencyCustomShort(
            values.netTotalGain + values.returnOnInvestment,
            currency
          )}
        </Typography>
      </Block>

      <Accordion elevation={0} square={true}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon data-testid="expand-return-on-investment" />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <Typography color="textSecondary" variant="body2">
              {t("investment.details.gainLossStatistics.returnOnInvestment")}
            </Typography>
            <Box display="flex" alignItems="center" gap={6}>
              <Typography
                variant="h6"
                fontWeight={700}
                data-testid="gainLossStatistics.returnOnInvestment"
                sx={{ lineHeight: "38px" }}
              >
                {toCurrencyCustomShort(values.returnOnInvestment, currency)}
              </Typography>
              <GainLossPercents value={values.returnOnInvestmentInPercent} />
            </Box>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.purchasePrice"
            >
              {toRoundedNumberOfLots(investment.numberOfLots)} x&nbsp;
              {toCurrency(values.purchasePrice, currency)}{" "}
              {t("investment.details.gainLossStatistics.purchasePrice")}{" "}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.purchaseValue"
            >
              {toCurrency(values.purchaseValue, currency)}{" "}
              {t("investment.details.gainLossStatistics.investedAmount")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.currentMarketValue"
            >
              {toCurrency(values.currentMarketValue, currency)}{" "}
              {t("investment.details.gainLossStatistics.marketValue")}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} square={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-testid="expand-realized-gains" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <Typography color="textSecondary" variant="body2">
              {t("investment.details.gainLossStatistics.realizedGains")}
            </Typography>
            <Typography
              fontWeight={700}
              variant="h6"
              data-testid="gainLossStatistics.netTotalGain"
            >
              {toCurrencyCustomShort(values.netTotalGain, currency)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.grossTradingGain"
            >
              {toCurrency(values.grossTradingGain, currency)}{" "}
              {t("investment.details.gainLossStatistics.tradingGains")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.dividends"
            >
              {toCurrency(values.grossDividendGain, currency)}{" "}
              {t("investment.details.gainLossStatistics.dividends")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.commission"
            >
              {toCurrency(values.commission, currency)}{" "}
              {t("investment.details.gainLossStatistics.commission")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.taxAmount"
            >
              {toCurrency(values.taxAmount, currency)}{" "}
              {t("investment.details.gainLossStatistics.tax")}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} square={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-testid="expand-performance" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>
            <Typography color="textSecondary" variant="body2">
              {values.showIrr
                ? t("investment.details.gainLossStatistics.irr", {
                    years: toRoundedNumber(
                      investmentPerformance.holdingTimeInYears || 0,
                      1
                    ),
                  })
                : t("investment.details.gainLossStatistics.twror")}
            </Typography>
            <Box
              maxWidth="80px"
              display="flex"
              alignItems="center"
              height="38px"
            >
              <GainLossPercents
                value={
                  values.showIrr
                    ? values.performance?.irr
                    : values.performance?.twror
                }
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.performanceOrIrr"
            >
              {toPercent(
                values.showIrr
                  ? values.performance?.irr
                  : values.performance?.twror
              )}{" "}
              {isForeignCurrencyInvestment
                ? t(
                    "investment.details.gainLossStatistics.fromMarketAndCurrencyReturns"
                  )
                : t("investment.details.gainLossStatistics.fromMarketReturns")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="gainLossStatistics.performanceOrIrrFromDividends"
            >
              {toPercent(
                values.showIrr
                  ? (values.performance?.irrWithDividends || 0) -
                      (values.performance?.irr || 0)
                  : (values.performance?.twrorWithDividends || 0) -
                      (values.performance?.twror || 0)
              )}{" "}
              {t(
                "investment.details.gainLossStatistics.fromDividendsAndKupons"
              )}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </Content>
  );
};

const EmptyGainLossStatisticsBlock = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Block>
        <Typography color="textSecondary" fontSize={12} fontWeight={500}>
          {t("investment.details.gainLossStatistics.gainAndLoss")}
        </Typography>
        <Typography variant="h6">N/A</Typography>
      </Block>

      <Block>
        <Typography color="textSecondary" variant="body2">
          {t("investment.details.gainLossStatistics.returnOnInvestment")}
        </Typography>
        <Typography variant="h6">N/A</Typography>
      </Block>
      <Block>
        <Typography color="textSecondary" variant="body2">
          {t("investment.details.gainLossStatistics.realizedGains")}
        </Typography>
        <Typography variant="h6">N/A</Typography>
      </Block>

      <Block>
        <Typography color="textSecondary" variant="body2">
          {t("investment.details.gainLossStatistics.twror")}
        </Typography>
        <Typography variant="h6">N/A</Typography>
      </Block>
    </Content>
  );
};
