/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateSharingPreferenceParams } from '../model';
// @ts-ignore
import { SharingPreference } from '../model';
/**
 * SharingPreferencesApi - axios parameter creator
 * @export
 */
export const SharingPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the sharing preferences of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSharingPreferences: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/sharingPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sharing preferences of a user using their publicId.
         * @param {string} publicId Public ID of the user you want to see the sharing preferences for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSharingPreferencesByPublicId: async (publicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('showSharingPreferencesByPublicId', 'publicId', publicId)
            const localVarPath = `/v1/users/sharingPreferences/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update the sharing preferences of a user.
         * @param {CreateSharingPreferenceParams} body The sharing preferences details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertSharingPreferences: async (body: CreateSharingPreferenceParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('upsertSharingPreferences', 'body', body)
            const localVarPath = `/v1/users/sharingPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharingPreferencesApi - functional programming interface
 * @export
 */
export const SharingPreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharingPreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the sharing preferences of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showSharingPreferences(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharingPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showSharingPreferences(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharingPreferencesApi.showSharingPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the sharing preferences of a user using their publicId.
         * @param {string} publicId Public ID of the user you want to see the sharing preferences for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showSharingPreferencesByPublicId(publicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharingPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showSharingPreferencesByPublicId(publicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharingPreferencesApi.showSharingPreferencesByPublicId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create or update the sharing preferences of a user.
         * @param {CreateSharingPreferenceParams} body The sharing preferences details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertSharingPreferences(body: CreateSharingPreferenceParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharingPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertSharingPreferences(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharingPreferencesApi.upsertSharingPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SharingPreferencesApi - factory interface
 * @export
 */
export const SharingPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharingPreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the sharing preferences of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSharingPreferences(options?: RawAxiosRequestConfig): AxiosPromise<SharingPreference> {
            return localVarFp.showSharingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sharing preferences of a user using their publicId.
         * @param {SharingPreferencesApiShowSharingPreferencesByPublicIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSharingPreferencesByPublicId(requestParameters: SharingPreferencesApiShowSharingPreferencesByPublicIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<SharingPreference> {
            return localVarFp.showSharingPreferencesByPublicId(requestParameters.publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update the sharing preferences of a user.
         * @param {SharingPreferencesApiUpsertSharingPreferencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertSharingPreferences(requestParameters: SharingPreferencesApiUpsertSharingPreferencesRequest, options?: RawAxiosRequestConfig): AxiosPromise<SharingPreference> {
            return localVarFp.upsertSharingPreferences(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for showSharingPreferencesByPublicId operation in SharingPreferencesApi.
 * @export
 * @interface SharingPreferencesApiShowSharingPreferencesByPublicIdRequest
 */
export interface SharingPreferencesApiShowSharingPreferencesByPublicIdRequest {
    /**
     * Public ID of the user you want to see the sharing preferences for.
     * @type {string}
     * @memberof SharingPreferencesApiShowSharingPreferencesByPublicId
     */
    readonly publicId: string
}

/**
 * Request parameters for upsertSharingPreferences operation in SharingPreferencesApi.
 * @export
 * @interface SharingPreferencesApiUpsertSharingPreferencesRequest
 */
export interface SharingPreferencesApiUpsertSharingPreferencesRequest {
    /**
     * The sharing preferences details
     * @type {CreateSharingPreferenceParams}
     * @memberof SharingPreferencesApiUpsertSharingPreferences
     */
    readonly body: CreateSharingPreferenceParams
}

/**
 * SharingPreferencesApi - object-oriented interface
 * @export
 * @class SharingPreferencesApi
 * @extends {BaseAPI}
 */
export class SharingPreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Get the sharing preferences of a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingPreferencesApi
     */
    public showSharingPreferences(options?: RawAxiosRequestConfig) {
        return SharingPreferencesApiFp(this.configuration).showSharingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sharing preferences of a user using their publicId.
     * @param {SharingPreferencesApiShowSharingPreferencesByPublicIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingPreferencesApi
     */
    public showSharingPreferencesByPublicId(requestParameters: SharingPreferencesApiShowSharingPreferencesByPublicIdRequest, options?: RawAxiosRequestConfig) {
        return SharingPreferencesApiFp(this.configuration).showSharingPreferencesByPublicId(requestParameters.publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update the sharing preferences of a user.
     * @param {SharingPreferencesApiUpsertSharingPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingPreferencesApi
     */
    public upsertSharingPreferences(requestParameters: SharingPreferencesApiUpsertSharingPreferencesRequest, options?: RawAxiosRequestConfig) {
        return SharingPreferencesApiFp(this.configuration).upsertSharingPreferences(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

