/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { PublicAccountList } from '../model';
/**
 * PublicAccountsApi - axios parameter creator
 * @export
 */
export const PublicAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the public accounts of a user.
         * @param {string} publicId Public ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAccounts: async (publicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('listPublicAccounts', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/accounts`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicAccountsApi - functional programming interface
 * @export
 */
export const PublicAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the public accounts of a user.
         * @param {string} publicId Public ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAccounts(publicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicAccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAccounts(publicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicAccountsApi.listPublicAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicAccountsApi - factory interface
 * @export
 */
export const PublicAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the public accounts of a user.
         * @param {PublicAccountsApiListPublicAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAccounts(requestParameters: PublicAccountsApiListPublicAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicAccountList> {
            return localVarFp.listPublicAccounts(requestParameters.publicId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listPublicAccounts operation in PublicAccountsApi.
 * @export
 * @interface PublicAccountsApiListPublicAccountsRequest
 */
export interface PublicAccountsApiListPublicAccountsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof PublicAccountsApiListPublicAccounts
     */
    readonly publicId: string
}

/**
 * PublicAccountsApi - object-oriented interface
 * @export
 * @class PublicAccountsApi
 * @extends {BaseAPI}
 */
export class PublicAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Get the public accounts of a user.
     * @param {PublicAccountsApiListPublicAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicAccountsApi
     */
    public listPublicAccounts(requestParameters: PublicAccountsApiListPublicAccountsRequest, options?: RawAxiosRequestConfig) {
        return PublicAccountsApiFp(this.configuration).listPublicAccounts(requestParameters.publicId, options).then((request) => request(this.axios, this.basePath));
    }
}

