import { Box, Button, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { useMyself } from "@api/auth";
import { Logo } from "@components/Logo";
import { BUY } from "@helpers/urlConstants";
import { Profile } from "../Profile";
import { ReactComponent as TagPlus } from "../../../../../icons/tag_plus.svg";
import { ReactComponent as StarsLight } from "../../../../../icons/stars-light-sparkle.svg";

const Container = styled(Box)<{ collapsed: number }>`
  align-items: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface AppSidebarHeaderProps {
  showPremium?: boolean;
  onLogoClick?: () => void;
}

export const AppSidebarHeader = ({
  onLogoClick,
  showPremium = true,
}: AppSidebarHeaderProps) => {
  const { t } = useTranslation();
  const { data: myself } = useMyself();
  const { sidebarCollapsed: collapsed } = useAppLayout();

  return (
    <Container
      display="flex"
      flexDirection="column"
      collapsed={collapsed ? 1 : 0}
      data-testid="header-container"
    >
      {collapsed && <Logo variant="compact" onClick={onLogoClick} />}
      <Box
        px={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {!collapsed && <Logo variant="sidebar" onClick={onLogoClick} />}
        {myself?.isUserPro && showPremium && !collapsed && (
          <Link href={BUY} target="_blank" rel="noopener noreferrer">
            <TagPlus />
          </Link>
        )}
        {myself?.isUserFree && showPremium && !collapsed && (
          <motion.div
            animate={{
              opacity: collapsed ? 0 : 1,
              transition: { delay: 0.15 },
            }}
          >
            {!collapsed && (
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<StarsLight />}
                href={BUY}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("sidebar.upgrade")}
              </Button>
            )}
          </motion.div>
        )}
      </Box>
      {!collapsed && (
        <Box width="100%">
          <Profile />
        </Box>
      )}
    </Container>
  );
};
