import { UseMutationOptions } from "react-query";
import { UsersApiInitiatePasswordResetRequest } from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const initiatePasswordReset = async (
  token: string | undefined,
  params: UsersApiInitiatePasswordResetRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.initiatePasswordReset(params);
  return data;
};

export const useInitiatePasswordReset = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UsersApiInitiatePasswordResetRequest
  >
) => {
  return useAuthMutation(initiatePasswordReset, options, true);
};
