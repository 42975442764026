/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BatchRiskYieldMetrics } from '../model';
// @ts-ignore
import { CorrelationToReference } from '../model';
// @ts-ignore
import { DateValuePairList } from '../model';
// @ts-ignore
import { PortfolioCorrelation } from '../model';
// @ts-ignore
import { RiskYield } from '../model';
/**
 * RiskYieldMetricsApi - axios parameter creator
 * @export
 */
export const RiskYieldMetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {Array<number>} [depotId] Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {BatchRiskYieldMetricsIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {BatchRiskYieldMetricsIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRiskYieldMetrics: async (depotId?: Array<number>, investmentId?: Array<number>, from?: string, interval?: BatchRiskYieldMetricsIntervalEnum, intervalPosition?: BatchRiskYieldMetricsIntervalPositionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/riskYieldMetrics/investments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the correlation of the given investments to the given reference ticker or to the entire portfolio.
         * @param {Array<number>} [depotId] Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Calculate the correlation to the reference for the given investments. Limited to 250 investments.
         * @param {string} [referenceTicker] This is the reference for calculation; default is PORTFOLIO meaning to correlate each                             investment to the entire portfolio
         * @param {string} [referenceQuoteProvider] The quote provider of the reference quote; default is none (for PORTFOLIO).
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correlationToReference: async (depotId?: Array<number>, investmentId?: Array<number>, referenceTicker?: string, referenceQuoteProvider?: string, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/riskYieldMetrics/correlationToReference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (referenceTicker !== undefined) {
                localVarQueryParameter['referenceTicker'] = referenceTicker;
            }

            if (referenceQuoteProvider !== undefined) {
                localVarQueryParameter['referenceQuoteProvider'] = referenceQuoteProvider;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Backtrack the historic performance using the current portfolio composition. This endpoint allows                     anonymous access when backtracking against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {Array<number>} [depotId] Get backtracking data for the given depots; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get backtracking data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {PortfolioBacktrackingIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {PortfolioBacktrackingIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {boolean} [inPercent] Convert all the quotes to a performance; default is false.
         * @param {string} [virtualInvestmentTickerSymbol] Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
         * @param {number} [virtualInvestmentAmount] Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
         * @param {string} [virtualInvestmentQuoteProvider] Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
         * @param {string} [simulatedTickerSymbol] Get simulated backtracking data for an investment scenario.                                E.g. Simulate my portfolio backtracking if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
         * @param {number} [simulatedAmount] Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
         * @param {string} [simulatedQuoteProvider] Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioBacktracking: async (depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, interval?: PortfolioBacktrackingIntervalEnum, intervalPosition?: PortfolioBacktrackingIntervalPositionEnum, inPercent?: boolean, virtualInvestmentTickerSymbol?: string, virtualInvestmentAmount?: number, virtualInvestmentQuoteProvider?: string, simulatedTickerSymbol?: string, simulatedAmount?: number, simulatedQuoteProvider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/riskYieldMetrics/portfolioBacktracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }

            if (inPercent !== undefined) {
                localVarQueryParameter['inPercent'] = inPercent;
            }

            if (virtualInvestmentTickerSymbol !== undefined) {
                localVarQueryParameter['virtualInvestmentTickerSymbol'] = virtualInvestmentTickerSymbol;
            }

            if (virtualInvestmentAmount !== undefined) {
                localVarQueryParameter['virtualInvestmentAmount'] = virtualInvestmentAmount;
            }

            if (virtualInvestmentQuoteProvider !== undefined) {
                localVarQueryParameter['virtualInvestmentQuoteProvider'] = virtualInvestmentQuoteProvider;
            }

            if (simulatedTickerSymbol !== undefined) {
                localVarQueryParameter['simulatedTickerSymbol'] = simulatedTickerSymbol;
            }

            if (simulatedAmount !== undefined) {
                localVarQueryParameter['simulatedAmount'] = simulatedAmount;
            }

            if (simulatedQuoteProvider !== undefined) {
                localVarQueryParameter['simulatedQuoteProvider'] = simulatedQuoteProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the inner correlation in the portfolio, a good measure for how similar individual positions behave.
         * @param {Array<number>} [depotId] Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Calculate the correlation to the reference for the given investments. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioCorrelation: async (depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/riskYieldMetrics/portfolioCorrelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get historic risk (standard deviation) and yield (annualized return) for the given depots.                     This endpoint allows anonymous access when calculating for against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {Array<number>} [depotId] Get risk / yield data for the given depots; authenticated user required. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk / yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {PortfolioRiskYieldIntervalEnum} [interval] Use the given quote interval for risk / yield calculations. Default value is month.
         * @param {PortfolioRiskYieldIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                Ignored if interval is day.                                The position is defined as current: current day of week / month / year;                                 first: first day of week / month / year; last: last day of week / month / year or                                the current day if the last day would fall after today.
         * @param {string} [virtualInvestmentTickerSymbol] Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
         * @param {number} [virtualInvestmentAmount] Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol                                
         * @param {string} [virtualInvestmentQuoteProvider] Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol                                
         * @param {string} [simulatedTickerSymbol] Get simulated risk yield metrics for an investment scenario.                                E.g. Simulate my risk &amp; yield if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
         * @param {number} [simulatedAmount] Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
         * @param {string} [simulatedQuoteProvider] Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioRiskYield: async (depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, interval?: PortfolioRiskYieldIntervalEnum, intervalPosition?: PortfolioRiskYieldIntervalPositionEnum, virtualInvestmentTickerSymbol?: string, virtualInvestmentAmount?: number, virtualInvestmentQuoteProvider?: string, simulatedTickerSymbol?: string, simulatedAmount?: number, simulatedQuoteProvider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/riskYieldMetrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }

            if (virtualInvestmentTickerSymbol !== undefined) {
                localVarQueryParameter['virtualInvestmentTickerSymbol'] = virtualInvestmentTickerSymbol;
            }

            if (virtualInvestmentAmount !== undefined) {
                localVarQueryParameter['virtualInvestmentAmount'] = virtualInvestmentAmount;
            }

            if (virtualInvestmentQuoteProvider !== undefined) {
                localVarQueryParameter['virtualInvestmentQuoteProvider'] = virtualInvestmentQuoteProvider;
            }

            if (simulatedTickerSymbol !== undefined) {
                localVarQueryParameter['simulatedTickerSymbol'] = simulatedTickerSymbol;
            }

            if (simulatedAmount !== undefined) {
                localVarQueryParameter['simulatedAmount'] = simulatedAmount;
            }

            if (simulatedQuoteProvider !== undefined) {
                localVarQueryParameter['simulatedQuoteProvider'] = simulatedQuoteProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [depotId] Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {PublicBatchRiskYieldMetricsIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {PublicBatchRiskYieldMetricsIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBatchRiskYieldMetrics: async (publicId: string, depotId?: Array<number>, investmentId?: Array<number>, from?: string, interval?: PublicBatchRiskYieldMetricsIntervalEnum, intervalPosition?: PublicBatchRiskYieldMetricsIntervalPositionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicBatchRiskYieldMetrics', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/riskYieldMetrics/investments`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RiskYieldMetricsApi - functional programming interface
 * @export
 */
export const RiskYieldMetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RiskYieldMetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {Array<number>} [depotId] Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {BatchRiskYieldMetricsIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {BatchRiskYieldMetricsIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchRiskYieldMetrics(depotId?: Array<number>, investmentId?: Array<number>, from?: string, interval?: BatchRiskYieldMetricsIntervalEnum, intervalPosition?: BatchRiskYieldMetricsIntervalPositionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchRiskYieldMetrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchRiskYieldMetrics(depotId, investmentId, from, interval, intervalPosition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.batchRiskYieldMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the correlation of the given investments to the given reference ticker or to the entire portfolio.
         * @param {Array<number>} [depotId] Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Calculate the correlation to the reference for the given investments. Limited to 250 investments.
         * @param {string} [referenceTicker] This is the reference for calculation; default is PORTFOLIO meaning to correlate each                             investment to the entire portfolio
         * @param {string} [referenceQuoteProvider] The quote provider of the reference quote; default is none (for PORTFOLIO).
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correlationToReference(depotId?: Array<number>, investmentId?: Array<number>, referenceTicker?: string, referenceQuoteProvider?: string, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrelationToReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correlationToReference(depotId, investmentId, referenceTicker, referenceQuoteProvider, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.correlationToReference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Backtrack the historic performance using the current portfolio composition. This endpoint allows                     anonymous access when backtracking against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {Array<number>} [depotId] Get backtracking data for the given depots; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get backtracking data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {PortfolioBacktrackingIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {PortfolioBacktrackingIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {boolean} [inPercent] Convert all the quotes to a performance; default is false.
         * @param {string} [virtualInvestmentTickerSymbol] Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
         * @param {number} [virtualInvestmentAmount] Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
         * @param {string} [virtualInvestmentQuoteProvider] Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
         * @param {string} [simulatedTickerSymbol] Get simulated backtracking data for an investment scenario.                                E.g. Simulate my portfolio backtracking if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
         * @param {number} [simulatedAmount] Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
         * @param {string} [simulatedQuoteProvider] Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioBacktracking(depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, interval?: PortfolioBacktrackingIntervalEnum, intervalPosition?: PortfolioBacktrackingIntervalPositionEnum, inPercent?: boolean, virtualInvestmentTickerSymbol?: string, virtualInvestmentAmount?: number, virtualInvestmentQuoteProvider?: string, simulatedTickerSymbol?: string, simulatedAmount?: number, simulatedQuoteProvider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateValuePairList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioBacktracking(depotId, investmentId, from, to, interval, intervalPosition, inPercent, virtualInvestmentTickerSymbol, virtualInvestmentAmount, virtualInvestmentQuoteProvider, simulatedTickerSymbol, simulatedAmount, simulatedQuoteProvider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.portfolioBacktracking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the inner correlation in the portfolio, a good measure for how similar individual positions behave.
         * @param {Array<number>} [depotId] Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Calculate the correlation to the reference for the given investments. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioCorrelation(depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioCorrelation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioCorrelation(depotId, investmentId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.portfolioCorrelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get historic risk (standard deviation) and yield (annualized return) for the given depots.                     This endpoint allows anonymous access when calculating for against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {Array<number>} [depotId] Get risk / yield data for the given depots; authenticated user required. Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk / yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {PortfolioRiskYieldIntervalEnum} [interval] Use the given quote interval for risk / yield calculations. Default value is month.
         * @param {PortfolioRiskYieldIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                Ignored if interval is day.                                The position is defined as current: current day of week / month / year;                                 first: first day of week / month / year; last: last day of week / month / year or                                the current day if the last day would fall after today.
         * @param {string} [virtualInvestmentTickerSymbol] Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
         * @param {number} [virtualInvestmentAmount] Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol                                
         * @param {string} [virtualInvestmentQuoteProvider] Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol                                
         * @param {string} [simulatedTickerSymbol] Get simulated risk yield metrics for an investment scenario.                                E.g. Simulate my risk &amp; yield if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
         * @param {number} [simulatedAmount] Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
         * @param {string} [simulatedQuoteProvider] Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioRiskYield(depotId?: Array<number>, investmentId?: Array<number>, from?: string, to?: string, interval?: PortfolioRiskYieldIntervalEnum, intervalPosition?: PortfolioRiskYieldIntervalPositionEnum, virtualInvestmentTickerSymbol?: string, virtualInvestmentAmount?: number, virtualInvestmentQuoteProvider?: string, simulatedTickerSymbol?: string, simulatedAmount?: number, simulatedQuoteProvider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskYield>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioRiskYield(depotId, investmentId, from, to, interval, intervalPosition, virtualInvestmentTickerSymbol, virtualInvestmentAmount, virtualInvestmentQuoteProvider, simulatedTickerSymbol, simulatedAmount, simulatedQuoteProvider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.portfolioRiskYield']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [depotId] Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
         * @param {Array<number>} [investmentId] Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
         * @param {string} [from] Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {PublicBatchRiskYieldMetricsIntervalEnum} [interval] Restrict data retrieval to the given interval. Default is month.
         * @param {PublicBatchRiskYieldMetricsIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBatchRiskYieldMetrics(publicId: string, depotId?: Array<number>, investmentId?: Array<number>, from?: string, interval?: PublicBatchRiskYieldMetricsIntervalEnum, intervalPosition?: PublicBatchRiskYieldMetricsIntervalPositionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchRiskYieldMetrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBatchRiskYieldMetrics(publicId, depotId, investmentId, from, interval, intervalPosition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskYieldMetricsApi.publicBatchRiskYieldMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RiskYieldMetricsApi - factory interface
 * @export
 */
export const RiskYieldMetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RiskYieldMetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {RiskYieldMetricsApiBatchRiskYieldMetricsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRiskYieldMetrics(requestParameters: RiskYieldMetricsApiBatchRiskYieldMetricsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BatchRiskYieldMetrics> {
            return localVarFp.batchRiskYieldMetrics(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.interval, requestParameters.intervalPosition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the correlation of the given investments to the given reference ticker or to the entire portfolio.
         * @param {RiskYieldMetricsApiCorrelationToReferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correlationToReference(requestParameters: RiskYieldMetricsApiCorrelationToReferenceRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CorrelationToReference> {
            return localVarFp.correlationToReference(requestParameters.depotId, requestParameters.investmentId, requestParameters.referenceTicker, requestParameters.referenceQuoteProvider, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Backtrack the historic performance using the current portfolio composition. This endpoint allows                     anonymous access when backtracking against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {RiskYieldMetricsApiPortfolioBacktrackingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioBacktracking(requestParameters: RiskYieldMetricsApiPortfolioBacktrackingRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DateValuePairList> {
            return localVarFp.portfolioBacktracking(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercent, requestParameters.virtualInvestmentTickerSymbol, requestParameters.virtualInvestmentAmount, requestParameters.virtualInvestmentQuoteProvider, requestParameters.simulatedTickerSymbol, requestParameters.simulatedAmount, requestParameters.simulatedQuoteProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the inner correlation in the portfolio, a good measure for how similar individual positions behave.
         * @param {RiskYieldMetricsApiPortfolioCorrelationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioCorrelation(requestParameters: RiskYieldMetricsApiPortfolioCorrelationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PortfolioCorrelation> {
            return localVarFp.portfolioCorrelation(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get historic risk (standard deviation) and yield (annualized return) for the given depots.                     This endpoint allows anonymous access when calculating for against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
         * @param {RiskYieldMetricsApiPortfolioRiskYieldRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioRiskYield(requestParameters: RiskYieldMetricsApiPortfolioRiskYieldRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RiskYield> {
            return localVarFp.portfolioRiskYield(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.virtualInvestmentTickerSymbol, requestParameters.virtualInvestmentAmount, requestParameters.virtualInvestmentQuoteProvider, requestParameters.simulatedTickerSymbol, requestParameters.simulatedAmount, requestParameters.simulatedQuoteProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
         * @param {RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBatchRiskYieldMetrics(requestParameters: RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchRiskYieldMetrics> {
            return localVarFp.publicBatchRiskYieldMetrics(requestParameters.publicId, requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.interval, requestParameters.intervalPosition, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for batchRiskYieldMetrics operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiBatchRiskYieldMetricsRequest
 */
export interface RiskYieldMetricsApiBatchRiskYieldMetricsRequest {
    /**
     * Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiBatchRiskYieldMetrics
     */
    readonly depotId?: Array<number>

    /**
     * Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiBatchRiskYieldMetrics
     */
    readonly investmentId?: Array<number>

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiBatchRiskYieldMetrics
     */
    readonly from?: string

    /**
     * Restrict data retrieval to the given interval. Default is month.
     * @type {'day' | 'week' | 'month' | 'year'}
     * @memberof RiskYieldMetricsApiBatchRiskYieldMetrics
     */
    readonly interval?: BatchRiskYieldMetricsIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof RiskYieldMetricsApiBatchRiskYieldMetrics
     */
    readonly intervalPosition?: BatchRiskYieldMetricsIntervalPositionEnum
}

/**
 * Request parameters for correlationToReference operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiCorrelationToReferenceRequest
 */
export interface RiskYieldMetricsApiCorrelationToReferenceRequest {
    /**
     * Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly depotId?: Array<number>

    /**
     * Calculate the correlation to the reference for the given investments. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly investmentId?: Array<number>

    /**
     * This is the reference for calculation; default is PORTFOLIO meaning to correlate each                             investment to the entire portfolio
     * @type {string}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly referenceTicker?: string

    /**
     * The quote provider of the reference quote; default is none (for PORTFOLIO).
     * @type {string}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly referenceQuoteProvider?: string

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly from?: string

    /**
     * Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiCorrelationToReference
     */
    readonly to?: string
}

/**
 * Request parameters for portfolioBacktracking operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiPortfolioBacktrackingRequest
 */
export interface RiskYieldMetricsApiPortfolioBacktrackingRequest {
    /**
     * Get backtracking data for the given depots; authenticated user required.                                Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly depotId?: Array<number>

    /**
     * Get backtracking data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly investmentId?: Array<number>

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly from?: string

    /**
     * Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly to?: string

    /**
     * Restrict data retrieval to the given interval. Default is month.
     * @type {'day' | 'week' | 'month' | 'year'}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly interval?: PortfolioBacktrackingIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly intervalPosition?: PortfolioBacktrackingIntervalPositionEnum

    /**
     * Convert all the quotes to a performance; default is false.
     * @type {boolean}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly inPercent?: boolean

    /**
     * Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly virtualInvestmentTickerSymbol?: string

    /**
     * Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
     * @type {number}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly virtualInvestmentAmount?: number

    /**
     * Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol.                                
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly virtualInvestmentQuoteProvider?: string

    /**
     * Get simulated backtracking data for an investment scenario.                                E.g. Simulate my portfolio backtracking if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly simulatedTickerSymbol?: string

    /**
     * Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
     * @type {number}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly simulatedAmount?: number

    /**
     * Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol.
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioBacktracking
     */
    readonly simulatedQuoteProvider?: string
}

/**
 * Request parameters for portfolioCorrelation operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiPortfolioCorrelationRequest
 */
export interface RiskYieldMetricsApiPortfolioCorrelationRequest {
    /**
     * Calculate the correlation to the reference for all investments inside the given                                portfolio. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioCorrelation
     */
    readonly depotId?: Array<number>

    /**
     * Calculate the correlation to the reference for the given investments. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioCorrelation
     */
    readonly investmentId?: Array<number>

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioCorrelation
     */
    readonly from?: string

    /**
     * Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioCorrelation
     */
    readonly to?: string
}

/**
 * Request parameters for portfolioRiskYield operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiPortfolioRiskYieldRequest
 */
export interface RiskYieldMetricsApiPortfolioRiskYieldRequest {
    /**
     * Get risk / yield data for the given depots; authenticated user required. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly depotId?: Array<number>

    /**
     * Get risk / yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly investmentId?: Array<number>

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly from?: string

    /**
     * Backtrack to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly to?: string

    /**
     * Use the given quote interval for risk / yield calculations. Default value is month.
     * @type {'day' | 'week' | 'month' | 'year'}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly interval?: PortfolioRiskYieldIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                Ignored if interval is day.                                The position is defined as current: current day of week / month / year;                                 first: first day of week / month / year; last: last day of week / month / year or                                the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly intervalPosition?: PortfolioRiskYieldIntervalPositionEnum

    /**
     * Use the given ticker symbol as your existing portfolio.                                  Only used when depotId is not given.
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly virtualInvestmentTickerSymbol?: string

    /**
     * Investment amount for your virtual investment.                                 Only used together with query param virtualInvestmentTickerSymbol                                
     * @type {number}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly virtualInvestmentAmount?: number

    /**
     * Quote provider to be used for the virtualInvestment quotes.                                 Only used together with query param virtualInvestmentTickerSymbol                                
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly virtualInvestmentQuoteProvider?: string

    /**
     * Get simulated risk yield metrics for an investment scenario.                                E.g. Simulate my risk &amp; yield if I had invested 10,000 on TSLA.                                 simulatedTickerSymbol &#x3D; \&quot;TSLA\&quot;                                 simulatedAmount &#x3D; 10000                                simulatedQuoteProvider &#x3D; TTE
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly simulatedTickerSymbol?: string

    /**
     * Amount to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
     * @type {number}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly simulatedAmount?: number

    /**
     * Quote provider to be used for the simulated backtracking data.                                Only used together with query param simulatedTickerSymbol
     * @type {string}
     * @memberof RiskYieldMetricsApiPortfolioRiskYield
     */
    readonly simulatedQuoteProvider?: string
}

/**
 * Request parameters for publicBatchRiskYieldMetrics operation in RiskYieldMetricsApi.
 * @export
 * @interface RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest
 */
export interface RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly publicId: string

    /**
     * Get risk and yield data for the given accounts; authenticated user required.                                Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly depotId?: Array<number>

    /**
     * Get risk yield data for the given set of investments. Takes precedence over the                                accountIds parameter. Authenticated user required. Limited to 250 investments.
     * @type {Array<number>}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly investmentId?: Array<number>

    /**
     * Backtrack from the given date; max is 10 years. Default value is 3 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly from?: string

    /**
     * Restrict data retrieval to the given interval. Default is month.
     * @type {'day' | 'week' | 'month' | 'year'}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly interval?: PublicBatchRiskYieldMetricsIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if interval is day.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof RiskYieldMetricsApiPublicBatchRiskYieldMetrics
     */
    readonly intervalPosition?: PublicBatchRiskYieldMetricsIntervalPositionEnum
}

/**
 * RiskYieldMetricsApi - object-oriented interface
 * @export
 * @class RiskYieldMetricsApi
 * @extends {BaseAPI}
 */
export class RiskYieldMetricsApi extends BaseAPI {
    /**
     * 
     * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
     * @param {RiskYieldMetricsApiBatchRiskYieldMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public batchRiskYieldMetrics(requestParameters: RiskYieldMetricsApiBatchRiskYieldMetricsRequest = {}, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).batchRiskYieldMetrics(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.interval, requestParameters.intervalPosition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the correlation of the given investments to the given reference ticker or to the entire portfolio.
     * @param {RiskYieldMetricsApiCorrelationToReferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public correlationToReference(requestParameters: RiskYieldMetricsApiCorrelationToReferenceRequest = {}, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).correlationToReference(requestParameters.depotId, requestParameters.investmentId, requestParameters.referenceTicker, requestParameters.referenceQuoteProvider, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Backtrack the historic performance using the current portfolio composition. This endpoint allows                     anonymous access when backtracking against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
     * @param {RiskYieldMetricsApiPortfolioBacktrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public portfolioBacktracking(requestParameters: RiskYieldMetricsApiPortfolioBacktrackingRequest = {}, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).portfolioBacktracking(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercent, requestParameters.virtualInvestmentTickerSymbol, requestParameters.virtualInvestmentAmount, requestParameters.virtualInvestmentQuoteProvider, requestParameters.simulatedTickerSymbol, requestParameters.simulatedAmount, requestParameters.simulatedQuoteProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the inner correlation in the portfolio, a good measure for how similar individual positions behave.
     * @param {RiskYieldMetricsApiPortfolioCorrelationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public portfolioCorrelation(requestParameters: RiskYieldMetricsApiPortfolioCorrelationRequest = {}, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).portfolioCorrelation(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get historic risk (standard deviation) and yield (annualized return) for the given depots.                     This endpoint allows anonymous access when calculating for against a virtual portfolio, provided by the                     virtualInvestmentTickerSymbol. An authenticated user is required if we want to backtrack against                     an existing portfolio or an existing set of investments.
     * @param {RiskYieldMetricsApiPortfolioRiskYieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public portfolioRiskYield(requestParameters: RiskYieldMetricsApiPortfolioRiskYieldRequest = {}, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).portfolioRiskYield(requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.virtualInvestmentTickerSymbol, requestParameters.virtualInvestmentAmount, requestParameters.virtualInvestmentQuoteProvider, requestParameters.simulatedTickerSymbol, requestParameters.simulatedAmount, requestParameters.simulatedQuoteProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delivers risk and yield for each of the selected investments / the investments contained in                 the given depots
     * @param {RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskYieldMetricsApi
     */
    public publicBatchRiskYieldMetrics(requestParameters: RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest, options?: RawAxiosRequestConfig) {
        return RiskYieldMetricsApiFp(this.configuration).publicBatchRiskYieldMetrics(requestParameters.publicId, requestParameters.depotId, requestParameters.investmentId, requestParameters.from, requestParameters.interval, requestParameters.intervalPosition, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const BatchRiskYieldMetricsIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type BatchRiskYieldMetricsIntervalEnum = typeof BatchRiskYieldMetricsIntervalEnum[keyof typeof BatchRiskYieldMetricsIntervalEnum];
/**
 * @export
 */
export const BatchRiskYieldMetricsIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type BatchRiskYieldMetricsIntervalPositionEnum = typeof BatchRiskYieldMetricsIntervalPositionEnum[keyof typeof BatchRiskYieldMetricsIntervalPositionEnum];
/**
 * @export
 */
export const PortfolioBacktrackingIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type PortfolioBacktrackingIntervalEnum = typeof PortfolioBacktrackingIntervalEnum[keyof typeof PortfolioBacktrackingIntervalEnum];
/**
 * @export
 */
export const PortfolioBacktrackingIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type PortfolioBacktrackingIntervalPositionEnum = typeof PortfolioBacktrackingIntervalPositionEnum[keyof typeof PortfolioBacktrackingIntervalPositionEnum];
/**
 * @export
 */
export const PortfolioRiskYieldIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type PortfolioRiskYieldIntervalEnum = typeof PortfolioRiskYieldIntervalEnum[keyof typeof PortfolioRiskYieldIntervalEnum];
/**
 * @export
 */
export const PortfolioRiskYieldIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type PortfolioRiskYieldIntervalPositionEnum = typeof PortfolioRiskYieldIntervalPositionEnum[keyof typeof PortfolioRiskYieldIntervalPositionEnum];
/**
 * @export
 */
export const PublicBatchRiskYieldMetricsIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type PublicBatchRiskYieldMetricsIntervalEnum = typeof PublicBatchRiskYieldMetricsIntervalEnum[keyof typeof PublicBatchRiskYieldMetricsIntervalEnum];
/**
 * @export
 */
export const PublicBatchRiskYieldMetricsIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type PublicBatchRiskYieldMetricsIntervalPositionEnum = typeof PublicBatchRiskYieldMetricsIntervalPositionEnum[keyof typeof PublicBatchRiskYieldMetricsIntervalPositionEnum];
