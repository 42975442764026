import { useEffect, useState } from "react";
import { UseMutationOptions } from "react-query";
import { useThrottledCallback } from "use-debounce";
import { isEqual } from "lodash";
import { usePrevious } from "react-use";
import {
  usePortfolioPerformance,
  usePublicPortfolioPerformance,
} from "@generated/apiv1/hooks";
import {
  CreateCashFlowAndPerformanceStatisticsParams,
  PerformanceApiPortfolioPerformanceRequest,
  PortfolioPerformanceResult,
  PortfolioPerformanceResultList,
} from "@generated/apiv1";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const usePublicOrPrivatePerformance = (
  params?: CreateCashFlowAndPerformanceStatisticsParams,
  options?: UseMutationOptions<
    PortfolioPerformanceResultList,
    AxiosApiError,
    PerformanceApiPortfolioPerformanceRequest
  > & { enabled?: boolean }
) => {
  const { mutate: getSharedPerformanceByDepots, ...publicRest } =
    usePublicPortfolioPerformance();
  const { mutate: getPerformanceByDepots, ...rest } = usePortfolioPerformance({
    mutationKey: [CACHE_KEYS.PERFORMANCE_PORTFOLIO_PERFORMANCE],
  });
  const [dataIsRequested, setDataIsRequested] = useState(false);
  const { publicId, isSharedPortfolio } = useSharedPortfolioContext();
  const [performanceData, setPerformanceData] = useState<
    PortfolioPerformanceResult[]
  >([]);
  const prev = usePrevious(params);

  const requestData = useThrottledCallback(() => {
    const isEnabled = options?.enabled === undefined ? true : options?.enabled;
    if (!params?.accountIds?.length || !isEnabled) return;

    setDataIsRequested(true);

    (isSharedPortfolio ? getSharedPerformanceByDepots : getPerformanceByDepots)(
      {
        publicId: publicId!,
        body: params,
      },
      // @ts-ignore
      {
        ...options,
        onSuccess: (data: PortfolioPerformanceResultList) => {
          // @ts-ignore
          options?.onSuccess?.(data);
          if (data.portfolioPerformanceResults) {
            setPerformanceData(data.portfolioPerformanceResults);
          }
        },
      }
    );
  }, 500);

  useEffect(() => {
    if (!isEqual(params, prev)) {
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, prev]);

  return {
    ...(isSharedPortfolio ? publicRest : rest),
    data: performanceData,
    isLoading: dataIsRequested && (rest.isLoading || publicRest.isLoading),
    isFetched: dataIsRequested,
  };
};
