import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

export type Props = ButtonProps & {
  isLoading?: boolean;
};

export const LoadingButton = ({
  isLoading = false,
  disabled = false,
  children,
  ...rest
}: Props) => {
  return (
    <Button
      disabled={disabled || isLoading}
      type="submit"
      color="primary"
      variant="contained"
      disableElevation
      startIcon={
        isLoading && (
          <CircularProgress
            color="inherit"
            size={20}
            data-testid="button-loading"
          />
        )
      }
      {...rest}
    >
      <span>{children}</span>
    </Button>
  );
};
