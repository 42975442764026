import React, { ReactNode } from "react";

interface Props {
  testId?: string;
  label: ReactNode;
  defaultActive?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  key: string;
}

export const Tab = ({ children, className, testId }: Props) => (
  <div className={className} data-testid={testId}>
    {children}
  </div>
);
