import React, { ReactElement } from "react";
import {
  Box,
  Breakpoint,
  Button,
  ButtonProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "@icons/myicons/plus.svg";
import { ButtonWithActionMenu } from "@components/ButtonWithActionMenu";

export interface PrimaryActionWithMenuProps extends ButtonProps {
  primaryActionText?: string;
  primaryAction?: string | (() => void);
  primaryActionComponent?: ReactElement;
  primaryActionMobileComponent?: ReactElement;
  startIcon?: ReactElement;
  size?: "small" | "medium";
  children: ReactElement;
  breakpoint?: Breakpoint | number;
}

export const PrimaryActionWithMenu = ({
  primaryActionText,
  primaryAction,
  primaryActionComponent,
  primaryActionMobileComponent,
  size = "medium",
  breakpoint = "md",
  startIcon = <AddIcon width="18px" height="12px" />,
  children,
  ...rest
}: PrimaryActionWithMenuProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMoreMd = useMediaQuery(theme.breakpoints.up(breakpoint));

  const handlePrimaryButtonClick = () => {
    if (!primaryAction) return;
    if (typeof primaryAction === "function") return primaryAction();
    navigate(primaryAction);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexShrink={0}
      gap={3}
    >
      {!primaryActionComponent &&
        !(primaryActionMobileComponent && !isMoreMd) && (
          <Button
            variant="contained"
            startIcon={startIcon}
            color="secondary"
            onClick={handlePrimaryButtonClick}
            data-testid="primary-action-button"
            size={size}
            {...rest}
          >
            {primaryActionText}
          </Button>
        )}
      {primaryActionMobileComponent && !isMoreMd
        ? primaryActionMobileComponent
        : primaryActionComponent}
      <ButtonWithActionMenu size={size}>{children}</ButtonWithActionMenu>
    </Box>
  );
};
