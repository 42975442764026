import React from "react";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Region } from "@generated/apiv1";
import { useListAndSearchAllRegions } from "@generated/apiv1/hooks";

export type RegionWithLabel = Region & {
  label?: string;
};

const getPosition = (isoCode?: string) => {
  switch (isoCode) {
    case "276":
      return 1;
    case "40":
      return 2;
    default:
      return undefined;
  }
};

interface Props {
  onChange: (id?: number | null, region?: Region) => void;
  value?: number | null;
  validationError?: ValidationError;
  id?: string;
  inputRef?: React.Ref<any>;
  placeholder?: string;
  testId?: string;
  optional?: boolean;
  disabled?: boolean;
}

export const RHFCountryAutocomplete = ({
  id,
  value,
  validationError,
  inputRef,
  onChange,
  placeholder,
  testId,
  disabled,
  optional = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { data, isFetching } = useListAndSearchAllRegions({
    type: "country",
  });

  const regionToOption = (r: Region) => ({
    ...r,
    label: i18n.language === "de" ? r.germanName : r.name,
    position: getPosition(r.isoCode),
  });

  const getGroup = (isoCode?: string) => {
    switch (isoCode) {
      case "276":
        return t("countryAutocomplete.mostUsed");
      case "40":
        return t("countryAutocomplete.mostUsed");
      default:
        return t("countryAutocomplete.others");
    }
  };

  const regionsToOptions = (regions: Region[] = []) => {
    const options = regions.map(regionToOption);

    return sortBy(options, [
      "position",
      i18n.language === "de" ? "germanName" : "name",
    ]);
  };

  const options = regionsToOptions(data?.regions);

  const handleOnChange = (value: any) => {
    onChange(value?.id, value);
  };

  const Label = (
    <>
      {placeholder}
      {optional ? (
        <Typography color="textSecondary" component="span" variant="body2">
          {" "}
          (optional)
        </Typography>
      ) : (
        ""
      )}
    </>
  );

  return (
    <Autocomplete<RegionWithLabel>
      id={id}
      loading={isFetching}
      value={options.find((o) => o.id === value) || null}
      fullWidth
      options={options}
      autoHighlight
      disabled={disabled}
      getOptionLabel={(option) => option.label!}
      renderOption={(props, option) => (
        <li {...props} data-testid={`country-${option.isoCode}`}>
          {option.label}
        </li>
      )}
      disablePortal
      groupBy={(option) => getGroup(option.isoCode)}
      onChange={(_: any, value: any) => handleOnChange(value)}
      renderInput={({ InputLabelProps, ...rest }) => (
        <TextField
          {...rest}
          label={Label}
          variant="outlined"
          error={!!validationError}
          placeholder={placeholder}
          helperText={validationError?.message}
          inputRef={inputRef}
          inputProps={{ ...rest.inputProps, "data-testid": testId || "input" }}
          InputProps={{
            ...rest.InputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
