import { Link, styled, Typography } from "@mui/material";

export const TermsMainTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(30)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: ${({ theme }) => theme.spacing(14)};
  }
`;

export const TermsSubheader = styled(Typography)`
  text-align: center;
  font-weight: bold;
`;

export const TermsSecondSubheader = styled(TermsSubheader)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const TermsTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(11)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: ${({ theme }) => theme.spacing(7)};
  }
`;

export const TermsLinkStyled = styled(Link)(({ theme }) => ({
  width: "fit-content",
  color: theme.palette.primary.main,
}));

export const TermsText = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;
