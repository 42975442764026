import { useQuery, UseQueryOptions } from "react-query";
import { searchBank } from "@api/v1/methods";
import { BankWrapper } from "@api/v1/model";

export const useFindBanks = (
  search?: string,
  options: UseQueryOptions<
    Promise<{ banks: BankWrapper[] }>,
    AxiosApiError,
    { banks: BankWrapper[] },
    (string | undefined)[]
  > = {}
) =>
  useQuery(
    ["search-bank", search],
    () => searchBank(search!) as Promise<{ banks: BankWrapper[] }>,
    {
      enabled: !!search,
      ...options,
    }
  );
