/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CashFlowAndPerformanceStatisticsParams } from './cash-flow-and-performance-statistics-params';

/**
 * 
 * @export
 * @interface CreateCashFlowAndPerformanceStatisticsParams
 */
export interface CreateCashFlowAndPerformanceStatisticsParams {
    /**
     * Start of the performance calculation. The start date is limited to the maximum         quote history, which is currently 10 years. Default is 6 years ago.
     * @type {string}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'startDate'?: string;
    /**
     * End of the performance calculation; default is today
     * @type {string}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'endDate'?: string;
    /**
     * Calculate the performance on the given accounts or depots.
     * @type {Array<number>}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'accountIds'?: Array<number>;
    /**
     * For faster execution, do not attempt to look up current quotes via web-service.
     * @type {boolean}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'cachedQuotesOnly'?: boolean;
    /**
     * Restrict data points to the given interval. Default: day.
     * @type {string}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'interval'?: CreateCashFlowAndPerformanceStatisticsParamsIntervalEnum;
    /**
     * Defines which value within the specified interval is used. Default value is current.             Ignored if no interval is given or interval is ```day```.             The interval position is defined as:             **current**: current day of week / month / year              **first**: first day of the week / month / year             **last:** last day of the week / month / year. Note that the last day for an incomplete year, month or             week may be today.
     * @type {string}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'intervalPosition'?: CreateCashFlowAndPerformanceStatisticsParamsIntervalPositionEnum;
    /**
     * **DEPRECATED:** Use interval and interval position             Return aggregated quotes for values coming before the given date;             significantly reduces the size of the result data set.
     * @type {string}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'switchToIntervalBefore'?: string;
    /**
     * Request several performance calculations with the respective settings in on go.
     * @type {Array<CashFlowAndPerformanceStatisticsParams>}
     * @memberof CreateCashFlowAndPerformanceStatisticsParams
     */
    'cashFlowAndPerformanceStatisticsParamsList': Array<CashFlowAndPerformanceStatisticsParams>;
}

export const CreateCashFlowAndPerformanceStatisticsParamsIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;

export type CreateCashFlowAndPerformanceStatisticsParamsIntervalEnum = typeof CreateCashFlowAndPerformanceStatisticsParamsIntervalEnum[keyof typeof CreateCashFlowAndPerformanceStatisticsParamsIntervalEnum];
export const CreateCashFlowAndPerformanceStatisticsParamsIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;

export type CreateCashFlowAndPerformanceStatisticsParamsIntervalPositionEnum = typeof CreateCashFlowAndPerformanceStatisticsParamsIntervalPositionEnum[keyof typeof CreateCashFlowAndPerformanceStatisticsParamsIntervalPositionEnum];


