import { UseQueryOptions } from "react-query";
import {
  PerformanceApiPerformanceHistogramRequest,
  HistogramBinList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const performanceHistogram = async (
  token: string | undefined,
  params?: PerformanceApiPerformanceHistogramRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.performanceHistogram(params);
  return data;
};

export const usePerformanceHistogram = (
  params?: PerformanceApiPerformanceHistogramRequest,
  options?: UseQueryOptions<HistogramBinList, AxiosApiError, HistogramBinList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_PERFORMANCE_HISTOGRAM, params],
    ({ token }) => performanceHistogram(token, params),
    options
  );
};
