import { useTranslation } from "react-i18next";
import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";

export const NoDataStub = (props: BoxProps) => {
  const { t } = useTranslation();
  return (
    <Box
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Typography variant="body2" color="textSecondary" textAlign="center">
        {t("singleAccount.noData")}
      </Typography>
    </Box>
  );
};
