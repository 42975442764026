import { UseQueryOptions } from "react-query";
import {
  RolesApiListAndSearchAllRolesRequest,
  RoleList,
} from "@generated/apiv1";

import { buildRolesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllRoles = async (
  token: string | undefined,
  params?: RolesApiListAndSearchAllRolesRequest
) => {
  const apiInstance = buildRolesApi(token);
  const { data } = await apiInstance.listAndSearchAllRoles(params);
  return data;
};

export const useListAndSearchAllRoles = (
  params?: RolesApiListAndSearchAllRolesRequest,
  options?: UseQueryOptions<RoleList, AxiosApiError, RoleList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ROLES_LIST_AND_SEARCH_ALL_ROLES, params],
    ({ token }) => listAndSearchAllRoles(token, params),
    options
  );
};
