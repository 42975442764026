/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ApiException } from '../model';
// @ts-ignore
import { Bank } from '../model';
// @ts-ignore
import { BankList } from '../model';
/**
 * BankControllerApi - axios parameter creator
 * @export
 */
export const BankControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pdfParser/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show1: async (group: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('show1', 'group', group)
            const localVarPath = `/api/v1/pdfParser/banks/{group}`
                .replace(`{${"group"}}`, encodeURIComponent(String(group)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankControllerApi - functional programming interface
 * @export
 */
export const BankControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async show(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.show(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankControllerApi.show']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async show1(group: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bank>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.show1(group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankControllerApi.show1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankControllerApi - factory interface
 * @export
 */
export const BankControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show(options?: RawAxiosRequestConfig): AxiosPromise<BankList> {
            return localVarFp.show(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BankControllerApiShow1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show1(requestParameters: BankControllerApiShow1Request, options?: RawAxiosRequestConfig): AxiosPromise<Bank> {
            return localVarFp.show1(requestParameters.group, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for show1 operation in BankControllerApi.
 * @export
 * @interface BankControllerApiShow1Request
 */
export interface BankControllerApiShow1Request {
    /**
     * 
     * @type {string}
     * @memberof BankControllerApiShow1
     */
    readonly group: string
}

/**
 * BankControllerApi - object-oriented interface
 * @export
 * @class BankControllerApi
 * @extends {BaseAPI}
 */
export class BankControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public show(options?: RawAxiosRequestConfig) {
        return BankControllerApiFp(this.configuration).show(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankControllerApiShow1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public show1(requestParameters: BankControllerApiShow1Request, options?: RawAxiosRequestConfig) {
        return BankControllerApiFp(this.configuration).show1(requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }
}

