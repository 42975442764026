import { UseMutationOptions } from "react-query";
import { TppCertificatesApiDeleteTppCertificateRequest } from "@generated/apiv1";

import { buildTppCertificatesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteTppCertificate = async (
  token: string | undefined,
  params: TppCertificatesApiDeleteTppCertificateRequest
) => {
  const apiInstance = buildTppCertificatesApi(token);
  const { data } = await apiInstance.deleteTppCertificate(params);
  return data;
};

export const useDeleteTppCertificate = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TppCertificatesApiDeleteTppCertificateRequest
  >
) => {
  return useAuthMutation(deleteTppCertificate, options, false);
};
