import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { subWeeks, parseJSON, isBefore } from "date-fns";
import { AccountInfo, GainLossPercents } from "@components";
import { TableBodyCell, TableRow } from "@components/Table";
import { ReactComponent as PlusIcon } from "@icons/plus_filled_circle.svg";
import { Investment } from "@generated/apiv1";
import { toRoundedNumber, toCurrency, toPercent } from "@helpers";
import { getValueByInvestmentAndBookingType } from "@helpers/getValueByInvestmentAndBookingType";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { BlurredTypography } from "@components/BlurredTypography";
import { AllocationChart } from "@components/Chart/AllocationChart";
import { InvestmentInfo } from "@components/InvestmentInfo";

const QUOTE_PROVIDER_NONE = "none";

const GainLossPercentsStyled = styled(GainLossPercents)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const checkIsShowModal = (investment: Investment) => {
  const { quote, quoteProvider } = investment;
  const showManualQuoteOptionAfter = subWeeks(new Date(), 2);
  if (quoteProvider === QUOTE_PROVIDER_NONE) return true;
  return !!(
    quote?.lastDateTime &&
    isBefore(parseJSON(quote.lastDateTime), showManualQuoteOptionAfter)
  );
};

interface RowProps {
  row: Investment & { allocation?: number };
  image?: string | null;
  isChildRow?: boolean;
  showIndent?: boolean;
  onClickAddQuoteModal?: (investment: Investment) => void;
  expandableElement?: React.ReactNode;
}

export const InvestmentRow = ({
  row,
  image,
  isChildRow = false,
  showIndent = false,
  onClickAddQuoteModal,
  expandableElement,
}: RowProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { isSharedPortfolio, isAnonymized } = useSharedPortfolioContext();
  const allocation = row.allocation ?? 0;
  const hasChildren = row.aggregation?.isAggregated;
  const isBond = row.type === "31_bond";

  return (
    <TableRow
      data-testid="investment-table-row"
      height={isDownMd ? 94 : 72}
      expandableElement={expandableElement}
      isDepots={true}
      isWrapped={false}
      clickable={hasChildren}
    >
      {showIndent && (
        // Rows that have aggretation will have one extra column/cell so we need the same for non-aggregated rows
        <TableBodyCell>
          <></>
        </TableBodyCell>
      )}
      <TableBodyCell>
        {isChildRow ? (
          <AccountInfo item={row} />
        ) : (
          <InvestmentInfo item={row} image={image} />
        )}
      </TableBodyCell>
      <TableBodyCell align="right">
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          gap={1}
          flexDirection="column"
        >
          <BlurredTypography variant="body2" noWrap>
            {getValueByInvestmentAndBookingType({
              value: row.quote?.last ?? 0,
              investmentType: row.type,
              currency: row.currency,
              percentageRange: "0-100",
            })}{" "}
          </BlurredTypography>
          {checkIsShowModal(row) && !isSharedPortfolio && !isChildRow && (
            <Button
              variant="contained"
              type="submit"
              size="small"
              startIcon={<PlusIcon color="primary" />}
              onClick={() => onClickAddQuoteModal?.(row)}
              sx={{ marginLeft: 2 }}
            >
              {t("domains.investments.investmentTable.addQuote.quote")}
            </Button>
          )}
          {Boolean(row.quote!.changePercentAbs) && (
            <GainLossPercentsStyled
              value={row.quote!.changePercentAbs!}
              showZero
            />
          )}
        </Box>
      </TableBodyCell>
      <TableBodyCell align="right">
        <BlurredTypography variant="body2">
          {toCurrency(row.purchaseValue, row.currency)}
        </BlurredTypography>
        <BlurredTypography
          variant="caption"
          color="textSecondary"
          fontWeight={600}
        >
          {isBond
            ? toCurrency(row.numberOfLots, row.currency)
            : toRoundedNumber(row.numberOfLots)}{" "}
          {t(`domains.investments.investmentTable.${isBond ? "at" : "pieces"}`)}{" "}
          {getValueByInvestmentAndBookingType({
            value: row.purchasePrice ?? 0,
            investmentType: row.type,
            currency: row.currency,
          })}
        </BlurredTypography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          flexDirection="column"
          gap={1}
        >
          {isAnonymized ? (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <AllocationChart percent={allocation} />
              <Box>
                <Typography variant="body2">{toPercent(allocation)}</Typography>
              </Box>
            </Box>
          ) : (
            <Typography variant="body2">
              {toCurrency(row.currentMarketValue, row.currency)}
            </Typography>
          )}
          <GainLossPercentsStyled
            value={row.returnOnInvestmentInPercent!}
            showZero
          />
        </Box>
      </TableBodyCell>
      {!isAnonymized && (
        <>
          <TableBodyCell align="right">
            {Boolean(row.receivedTotalDividend) && (
              <Typography
                variant="caption"
                color="textSecondary"
                fontWeight={600}
              >
                {toCurrency(row.receivedTotalDividend)}
              </Typography>
            )}
          </TableBodyCell>
          <TableBodyCell align="right">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <AllocationChart percent={allocation} />
              <Box width={60}>
                <Typography variant="body2" lineHeight="20px" fontWeight={600}>
                  {toPercent(allocation)}
                </Typography>
              </Box>
            </Box>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};
