import { BookingTypeEnum, InvestmentTypeEnum } from "@api";
import { Investment } from "@generated/apiv1";
import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";

export const investmentTypeToAccountType = (type: InvestmentTypeEnum) =>
  ["61_pmetals", "71_massets"].includes(type)
    ? "05_material_assets"
    : "01_depot";

export const isCapitalMeasure = (type: BookingTypeEnum) => {
  return ["carve_out", "switch"].includes(type);
};

export const investmentToInvestmentValues = (
  investment?: Investment | null
) => {
  if (!investment) return null;

  return {
    id: investment.id,
    name: investment.standardisedName,
    isin: investment.isin,
    wkn: investment.wkn,
    type: investment.type as InvestmentTypeEnum,
    tickerSymbol: investment.tickerSymbol,
    quoteProvider: investment.quoteProvider,
    quoteCurrency: investment.quote.currency,
    quoteLast: investment.quote.last,
    investmentCurrency: investment.currency,
    numberOfLots: investment.numberOfLots,
  };
};

export const areNonListedAssetsSelected = (
  context: TransactionWizardContext
) => {
  return (
    context.investmentValues.type === "71_massets" ||
    context.investmentValues.type === "91_managed"
  );
};
