import React from "react";
import { Bar, XAxis, YAxis, ReferenceLine, BarChart, Cell } from "recharts";
import { every } from "lodash";
import { useTheme } from "@emotion/react";
import { Palette } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { useChartColors } from "@hooks";

export interface HorizontalBarChartData {
  category: string;
  value: number;
  isFake?: boolean;
  isEstimate?: boolean;
  valueFormatted?: string;
}

export type ChartType = "colored" | "monochrome";

interface Props {
  name: string;
  type?: ChartType;
  data: HorizontalBarChartData[];
  className?: string;
  height?: string;
  barLabelWidth?: number;
}

const textColor = (colors: Palette, value: number = 0) => {
  if (value > 0) {
    return colors.text.positive;
  }
  if (value < 0) {
    return colors.text.negative;
  }
  return colors.text.primary;
};

const barColor = (
  // eslint-disable-next-line prettier/prettier
  colors: ReturnType<typeof useChartColors<"horizontalBarChart">>,
  type: ChartType,
  data: HorizontalBarChartData
) => {
  if (type === "monochrome") {
    if (data.isEstimate) {
      return colors.estimated;
    }
    return colors.current;
  }
  if (data.value > 0) {
    return colors.positive;
  }
  if (data.value < 0) {
    return colors.negative;
  }
  return colors.neutral;
};

const renderTick =
  (data: HorizontalBarChartData[], type: ChartType, colors: Palette) =>
  (tickProps: any) => {
    const { index, y, payload } = tickProps;
    const { value: category, offset } = payload;
    const entry = data.find((item) => item.category === category);

    const isFirst = index === 0;

    const valueColor =
      type === "colored"
        ? textColor(colors, entry?.value)
        : colors.text.primary;
    return (
      <g filter={entry?.isFake ? "url(#blurMe)" : undefined}>
        {!isFirst && (
          <path
            d={`M${0} ${y - offset} H${500}`}
            strokeWidth="1"
            stroke={colors.border.primary}
          />
        )}
        <text
          alignmentBaseline="central"
          x={0}
          y={y}
          textAnchor="left"
          fontSize="14px"
          fill={colors.text.secondary}
        >
          {category}
        </text>
        <text
          alignmentBaseline="central"
          x={70}
          y={y}
          textAnchor="left"
          fontSize="14px"
          fill={valueColor}
        >
          {entry?.valueFormatted || entry?.value}
        </text>
      </g>
    );
  };

export const HorizontalBarChart = ({
  name,
  data,
  type = "colored",
  className,
  height,
  barLabelWidth = 100,
}: Props) => {
  const theme = useTheme();
  const chartColors = useChartColors("horizontalBarChart");

  const areValuesSameSign =
    every(data, ({ value }) => value >= 0) ||
    every(data, ({ value }) => value <= 0);

  return (
    <AutoSizer className={className}>
      {({ width, height }: { width: number; height: number }) => (
        <BarChart
          data={data}
          width={width}
          height={height}
          layout="vertical"
          margin={{ left: barLabelWidth, top: 1, bottom: 1 }}
        >
          <filter id="blurMe">
            <feGaussianBlur stdDeviation="5" />
          </filter>
          {!areValuesSameSign && (
            <ReferenceLine x={0} stroke={theme.palette.border.primary} />
          )}
          <XAxis type="number" hide={true} />
          <YAxis
            stroke={theme.palette.border.primary}
            type="category"
            axisLine={false}
            dataKey="category"
            tickLine={false}
            tick={renderTick(data, type, theme.palette)}
            interval={0}
          />
          <Bar
            barSize={20}
            name={name}
            dataKey="value"
            fill={theme.palette.primary.main}
            radius={[0, theme.shape.borderRadius, theme.shape.borderRadius, 0]}
          >
            {data.map((entry, index) => (
              <Cell
                filter={entry.isFake ? "url(#blurMe)" : undefined}
                key={`cell-${index}`}
                fill={barColor(chartColors, type, entry)}
              />
            ))}
          </Bar>
        </BarChart>
      )}
    </AutoSizer>
  );
};
