import {
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  Grid,
  styled,
  Box,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { useV1ListCurrencies } from "@generated/apiv1/hooks";
import { getExchangeRate } from "@api";
import { Symbol } from "@generated/apiv1";
import { ReactComponent as ErrorIcon } from "@icons/error.svg";
import { Banner } from "@components";
import { sellTypes } from "@features/transactionWizard/stateMachine/types/sellTypes";
import { useHelpers } from "../hooks/useHelpers";
import { useLabelHelpers } from "../hooks/useLabelHelpers";
import { useGetBookingValues } from "../hooks/useUpdateBookingValues";
import { BookingValues } from "../stateMachine/types/bookingValues";
import { InvestmentValues } from "../stateMachine/types/investmentValues";
import { InputFieldWithRevert } from "../components/NumberInputFieldWithRevert";
import { DatePickerWithQuoteLookup } from "../components/DatePickerWithQuoteLookup";
import { StepContainer } from "../StepContainer";
import { useProposePrice } from "../hooks/useProposePrice";

const GridContainer = styled(Grid)(() => ({
  "> div:nth-last-of-type(odd):nth-of-type(odd):last-of-type": {
    flexBasis: "100%",
    maxWidth: "100%",
  },
}));

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;

export interface Props {
  handleNext: (bookingValues: BookingValues, currency?: string) => void;
  handlePrevious?: () => void;
  symbol?: Symbol;
  bookingValues: BookingValues;
  bookingValuesToRestore?: BookingValues;
  investmentValues: InvestmentValues;
  sourceInvestmentValues?: InvestmentValues;
  validationError?: string;
  chart?: ReactNode;
  disableOpenPicker?: boolean;
  datePickerDesktopVariant?: boolean;
  isNewTransaction?: boolean;
  isEditTransaction?: boolean;
  isLoading?: boolean;
}

export const PriceAndDateStep = ({
  handleNext,
  handlePrevious,
  bookingValues,
  bookingValuesToRestore,
  symbol,
  investmentValues,
  sourceInvestmentValues,
  validationError,
  chart,
  disableOpenPicker,
  datePickerDesktopVariant,
  isNewTransaction,
  isEditTransaction,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const isSelling = sellTypes.includes(bookingValues.type || "buy");
  const {
    getAmounts,
    getPriceAndTotalAmountAC,
    getPriceAndTotalAmount,
    ouncesToGram,
    gramToOunces,
    validate,
  } = useGetBookingValues();

  const { data: currencies, isLoading: areCurrenciesLoading } =
    useV1ListCurrencies();

  const { nolLabel, nolDescription, priceLabel, entryQuoteDescription } =
    useLabelHelpers();
  const {
    toString,
    parseOrUndefined,
    hasTax,
    hasSecurityPrice,
    isCapitalMeasure,
  } = useHelpers();

  const [values, setValues] = useState<BookingValues>(bookingValues);

  const [date, setDate] = useState<Date | undefined>(bookingValues.date);

  const [numberOfLots, setNumberOfLots] = useState<string>(
    investmentValues.type === "61_pmetals"
      ? toString(ouncesToGram(bookingValues.numberOfLots))
      : toString(bookingValues.numberOfLots)
  );
  const [ounces, setOunces] = useState<string>(
    toString(bookingValues.numberOfLots)
  );
  const [currency, setCurrency] = useState<string | undefined>(
    investmentValues.investmentCurrency || "EUR"
  );
  const [entryQuote, setEntryQuote] = useState<string>(
    toString(bookingValues.entryQuote)
  );
  const [securityPrice, setSecurityPrice] = useState<string>(
    toString(bookingValues.securityPrice)
  );
  const [entryQuoteTakeoverValue, setEntryQuoteTakeoverValue] = useState<
    number | undefined
  >(undefined);
  const [taxAmount, setTaxAmount] = useState<string>(
    toString(bookingValues.taxAmount)
  );
  const [commission, setCommission] = useState<string>(
    toString(bookingValues.commission)
  );
  const [exchangeRate, setExchangeRate] = useState<string>(
    toString(bookingValues.exchangeRate)
  );
  const [exchangeRateTakeoverValue, setExchangeRateTakeoverValue] = useState<
    number | undefined
  >(undefined);
  const [taxAndCommissionInEur, setTaxAndCommissionInEur] = useState<boolean>(
    bookingValues.taxAndCommissionInEur != null
      ? bookingValues.taxAndCommissionInEur
      : true
  );
  const [totalAmount, setTotalAmount] = useState<string>(
    toString(bookingValues.amount)
  );
  const [totalAmountAC, setTotalAmountAC] = useState<string>(
    toString(bookingValues.amountAC)
  );

  const [depotValuationAfterBooking, setDepotValuationAfterBooking] =
    useState<string>(toString(bookingValues.depotValuationAfterBooking));

  const priceInPercent = investmentValues.type === "31_bond";

  const { proposeSecurityPriceChange, proposeExchangeRateChange } =
    useProposePrice(
      investmentValues.tickerSymbol || "",
      investmentValues.quoteProvider || "none",
      investmentValues.quoteCurrency || "EUR",
      currency || "EUR",
      setEntryQuoteTakeoverValue,
      setExchangeRateTakeoverValue
    );

  const handleDateChange = (date: Date) => {
    setDate(date);
    setValues({ ...values, date: date });
    proposeSecurityPriceChange(date);
    proposeExchangeRateChange(date);
  };

  const updateAmounts = useCallback(
    (partial: BookingValues) => {
      setValues((current: BookingValues) => {
        const newValues = { ...current, ...partial };

        if (!numberOfLots && !partial.numberOfLots) return newValues;
        if (entryQuote == null && partial.entryQuote == null) return newValues;
        if (!newValues.type) return newValues;

        const amounts = getAmounts(
          newValues.type,
          newValues.numberOfLots || 0,
          newValues.entryQuote || 0,
          newValues.exchangeRate || 1,
          newValues.commission || 0,
          newValues.taxAmount || 0,
          priceInPercent,
          newValues.taxAndCommissionInEur || false
        );

        newValues.amount = amounts.amount;
        newValues.amountAC = amounts.amountAC;

        setTotalAmount(toString(amounts.amount, "", 6));
        setTotalAmountAC(toString(amounts.amountAC, "", 6));
        return newValues;
      });
    },
    [numberOfLots, entryQuote, priceInPercent, getAmounts, toString]
  );

  const convertEntryQuoteToCurrency = useCallback(
    async (
      symbol: Symbol | undefined,
      entryQuote?: number,
      currency: string = "EUR"
    ) => {
      let convertedEntryQuote = entryQuote;

      if (!symbol?.quote?.last || !symbol?.currency) {
        convertedEntryQuote = entryQuote;
      } else if (currency === symbol.currency) {
        convertedEntryQuote = symbol.quote?.last;
      } else {
        try {
          const rate = await getExchangeRate({
            from: symbol.currency,
            to: currency || "EUR",
            date: format(new Date(), "yyyy-MM-dd"),
          });

          convertedEntryQuote = symbol?.quote?.last * rate.rate;
        } catch (e) {
          convertedEntryQuote = symbol?.quote?.last;
        }
      }

      updateAmounts({
        entryQuote: convertedEntryQuote,
      });
      setEntryQuote(toString(convertedEntryQuote));
    },
    [toString, updateAmounts]
  );

  const handleNolChange = (value: string) => {
    const numberOfLots = parseOrUndefined(value);
    setNumberOfLots(toString(numberOfLots, value, 10));
    if (!numberOfLots || numberOfLots < 0) return;
    setOunces(toString(gramToOunces(numberOfLots)));
    updateAmounts({
      numberOfLots:
        investmentValues.type === "61_pmetals"
          ? gramToOunces(numberOfLots)
          : numberOfLots,
    });
  };

  const handleEntryQuoteChange = (value: string) => {
    const entryQuote = parseOrUndefined(value);
    setEntryQuote(toString(entryQuote, value));
    if (entryQuote == null || entryQuote < 0) return;
    updateAmounts({ entryQuote });
  };

  const handleOuncesChange = (value: string) => {
    const ounces = parseOrUndefined(value);
    setOunces(toString(ounces, value));
    if (!ounces || ounces < 0) return;
    setNumberOfLots(toString(ouncesToGram(ounces)));
    updateAmounts({ numberOfLots: ounces });
  };

  const handleSecurityPriceChange = (value: string) => {
    const securityPrice = parseOrUndefined(value);
    setSecurityPrice(toString(securityPrice, value));
    if (securityPrice == null || securityPrice < 0) return;
    updateAmounts({ securityPrice });
  };

  const handleEntryQuoteTakeover = (value: string) => {
    setEntryQuoteTakeoverValue(undefined);
    handleEntryQuoteChange(value);
  };

  const handleExchangeRateTakeover = (value: string) => {
    setExchangeRateTakeoverValue(undefined);
    handleExchangeRateChange(value);
  };

  const handleExchangeRateChange = (value: string) => {
    const exchangeRate = parseOrUndefined(value);
    setExchangeRate(toString(exchangeRate, value));
    if (exchangeRate == null || exchangeRate < 0) return;
    updateAmounts({ exchangeRate });
  };

  const handleCommissionChange = (value: string) => {
    const commission = parseOrUndefined(value);
    setCommission(toString(commission, value, 6));
    if (commission == null) return;
    updateAmounts({ commission });
  };

  const handleTaxAmountChange = (value: string) => {
    const taxAmount = parseOrUndefined(value);
    setTaxAmount(toString(taxAmount, value, 6));
    if (taxAmount == null) return;
    updateAmounts({ taxAmount });
  };

  const handleTaxAndCommissionInEurChange = () => {
    setTaxAndCommissionInEur((current) => {
      updateAmounts({ taxAndCommissionInEur: !current });
      return !current;
    });
  };

  const handleCurrencyChange = (
    event: ChangeEvent<{ value?: string }> | SelectChangeEvent<string>
  ) => {
    setCurrency(event.target.value);
  };

  const handleTotalAmountChange = (value: string) => {
    const amount = parseOrUndefined(value);
    setTotalAmount(toString(amount, value));

    if (amount == null) return;
    if (!numberOfLots) return;
    if (entryQuote == null) return;
    const priceAndAmountAC = getPriceAndTotalAmountAC(
      values.type || "buy",
      values.numberOfLots || 0,
      amount || 0,
      values.exchangeRate || 1,
      values.commission || 0,
      values.taxAmount || 0,
      priceInPercent,
      values.taxAndCommissionInEur || false
    );
    setValues({
      ...values,
      entryQuote: priceAndAmountAC.price,
      amount: amount,
      amountAC: priceAndAmountAC.amount,
    });
    setEntryQuote(toString(priceAndAmountAC.price, "", 6));
    setTotalAmountAC(toString(priceAndAmountAC.amount, "", 6));
  };

  const handleTotalAmountACChange = (value: string) => {
    const amountAC = parseOrUndefined(value);
    setTotalAmountAC(toString(amountAC, value));

    if (amountAC == null) return;
    if (!numberOfLots) return;
    if (entryQuote == null) return;
    const priceAndAmount = getPriceAndTotalAmount(
      values.type || "buy",
      values.numberOfLots || 0,
      amountAC || 0,
      values.exchangeRate || 1,
      values.commission || 0,
      values.taxAmount || 0,
      priceInPercent,
      values.taxAndCommissionInEur || false
    );

    setValues({
      ...values,
      entryQuote: priceAndAmount.price,
      amount: priceAndAmount.amount,
      amountAC: amountAC,
    });
    setEntryQuote(toString(priceAndAmount.price, "", 6));
    setTotalAmount(toString(priceAndAmount.amount, "", 6));
  };

  const handleDepotValuationAfterBookingChange = (value: string) => {
    const depotValuationAfterBooking = parseOrUndefined(value);
    updateAmounts({ depotValuationAfterBooking });
    setDepotValuationAfterBooking(
      toString(depotValuationAfterBooking, value, 6)
    );
  };

  const currencySymbol = getCurrencySymbol(currency);
  const isFx = currency !== "EUR";
  const onNextDisabled = !validate(values, investmentValues);
  const isManagedDepot = investmentValues.type === "91_managed";

  useEffect(() => {
    if (isEditTransaction) return;

    convertEntryQuoteToCurrency(symbol, parseOrUndefined(entryQuote), currency);
    const foundCurrency = currencies?.currencies?.find(
      (c) => c.code === currency
    );
    if (foundCurrency) {
      setExchangeRate(toString(foundCurrency.toEUR));
      updateAmounts({ exchangeRate: foundCurrency.toEUR });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, currencies, symbol, parseOrUndefined]);

  return (
    <StepContainer
      testid="price-and-date-step"
      title={t("transactionWizard.priceAndDateStep.title")}
      subtitle={
        <>
          <b>
            {t(`transactionWizard.bookingTypeStep.${bookingValues.type}`)}
            {": "}
          </b>
          {investmentValues.name}
        </>
      }
      onNext={() => {
        if (onNextDisabled) return;
        handleNext(values, currency || "EUR");
      }}
      onNextDisabled={onNextDisabled}
      onPrevious={handlePrevious}
      chart={chart}
      isLoading={isLoading || areCurrenciesLoading}
    >
      <Container>
        {!!validationError && (
          <Banner
            icon={<ErrorIcon />}
            type="error"
            data-testid="booking-validation-error"
            text={validationError}
          />
        )}

        <GridContainer container rowSpacing={6} columnSpacing={4}>
          <Grid item xs={12} sm={6}>
            <DatePickerWithQuoteLookup
              date={date ? date : new Date()}
              handleChange={handleDateChange}
              label={t("transactionWizard.priceAndDateStep.date")}
              disableOpenPicker={disableOpenPicker}
              desktopVariant={datePickerDesktopVariant}
            />
          </Grid>

          {!isManagedDepot && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" color="textSecondary">
                      {nolDescription(investmentValues.type)}
                    </Typography>
                    {Boolean(
                      (sourceInvestmentValues?.availableLots ??
                        investmentValues.availableLots) &&
                        isSelling
                    ) && (
                      <Typography variant="body1" color="textSecondary">
                        {toString(
                          sourceInvestmentValues?.availableLots ??
                            investmentValues.availableLots
                        )}{" "}
                        verfügbar
                      </Typography>
                    )}
                  </Box>
                }
                id="number-of-lots"
                value={numberOfLots}
                handleChange={handleNolChange}
                rollbackValue={
                  investmentValues.type === "61_pmetals"
                    ? ouncesToGram(bookingValuesToRestore?.numberOfLots)
                    : bookingValuesToRestore?.numberOfLots
                }
                endAdornment={nolLabel(investmentValues.type, currencySymbol)}
                maximumFractionDigits={10}
              />
            </Grid>
          )}

          {!isManagedDepot && investmentValues.type === "61_pmetals" && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizard.priceAndDateStep.numberOfLotsOunces"
                )}
                id="ounces"
                value={ounces}
                handleChange={handleOuncesChange}
                endAdornment="oz"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <InputFieldWithRevert
              fieldLabel={entryQuoteDescription(bookingValues.type || "buy")}
              id="entry-quote"
              value={entryQuote}
              rollbackValue={bookingValuesToRestore?.entryQuote}
              takeoverDate={
                values.date && isNaN(values.date.getTime())
                  ? undefined
                  : values.date
              }
              takeoverValue={entryQuoteTakeoverValue}
              handleTakeover={handleEntryQuoteTakeover}
              handleChange={handleEntryQuoteChange}
              endAdornment={priceLabel(investmentValues.type, currencySymbol)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>
                {t("transactionWizard.priceAndDateStep.currency")}
              </InputLabel>
              <Select
                value={currency}
                id="currency"
                data-testid="currency-selector"
                onChange={handleCurrencyChange}
                disabled={
                  !isNewTransaction && !isCapitalMeasure(bookingValues.type)
                }
                inputProps={{
                  "data-testid": "currency-selector-input",
                }}
              >
                {currencies?.currencies?.map((currency) => (
                  <MenuItem
                    key={currency.code}
                    data-testid={currency.code}
                    value={currency.code}
                  >
                    {`${currency.code} - ${currency.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {hasSecurityPrice(bookingValues.type) && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizard.priceAndDateStep.securityPrice"
                )}
                id="security-price"
                value={securityPrice}
                rollbackValue={bookingValuesToRestore?.securityPrice}
                handleChange={handleSecurityPriceChange}
                endAdornment={priceLabel(investmentValues.type, currencySymbol)}
              />
            </Grid>
          )}

          {isFx && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizard.priceAndDateStep.exchangeRate"
                )}
                id="exchange-rate"
                value={exchangeRate}
                rollbackValue={bookingValuesToRestore?.exchangeRate}
                handleChange={handleExchangeRateChange}
                takeoverDate={
                  values.date && isNaN(values.date.getTime())
                    ? undefined
                    : values.date
                }
                takeoverValue={exchangeRateTakeoverValue}
                handleTakeover={handleExchangeRateTakeover}
                endAdornment=""
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <InputFieldWithRevert
              fieldLabel={t("transactionWizard.priceAndDateStep.commission")}
              id="commission"
              value={commission}
              rollbackValue={bookingValuesToRestore?.commission}
              handleChange={handleCommissionChange}
              endAdornment={taxAndCommissionInEur ? "€" : currencySymbol}
            />
          </Grid>

          {bookingValues.type && hasTax(bookingValues.type) && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t("transactionWizard.priceAndDateStep.taxAmount")}
                id="tax-amount"
                value={taxAmount != null ? taxAmount : ""}
                rollbackValue={bookingValuesToRestore?.taxAmount}
                handleChange={handleTaxAmountChange}
                endAdornment={taxAndCommissionInEur ? "€" : currencySymbol}
              />
            </Grid>
          )}
        </GridContainer>

        {isFx && (
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={taxAndCommissionInEur}
                    onChange={handleTaxAndCommissionInEurChange}
                    color="primary"
                    data-testid="tax-and-commission-in-eur-input"
                  />
                }
                label={t(
                  "transactionWizard.priceAndDateStep.taxAndCommissionInEur"
                )}
                data-testid="tax-and-commission-in-eur-switch"
                sx={{ marginLeft: 0 }}
              />
            </Grid>
          </Grid>
        )}

        <GridContainer container rowSpacing={6} columnSpacing={4}>
          {!isManagedDepot && (
            <>
              <Grid item xs={12} sm={6}>
                <InputFieldWithRevert
                  fieldLabel={t(
                    "transactionWizard.priceAndDateStep.totalAmount"
                  )}
                  id="total-amount"
                  value={totalAmount}
                  rollbackValue={bookingValuesToRestore?.amount}
                  handleChange={handleTotalAmountChange}
                  endAdornment={currencySymbol}
                />
              </Grid>

              {isFx && (
                <Grid item xs={12} sm={6}>
                  <InputFieldWithRevert
                    fieldLabel={t(
                      "transactionWizard.priceAndDateStep.totalAmountAC"
                    )}
                    id="total-amount-ac"
                    value={totalAmountAC}
                    rollbackValue={bookingValuesToRestore?.amountAC}
                    handleChange={handleTotalAmountACChange}
                    endAdornment="€"
                  />
                </Grid>
              )}
            </>
          )}

          {isManagedDepot && !!investmentValues?.id && (
            <Grid item xs={12} sm={6}>
              <InputFieldWithRevert
                fieldLabel={t(
                  "transactionWizard.priceAndDateStep.depotValuationAfterBooking"
                )}
                id="depot-valuation-after-booking"
                value={depotValuationAfterBooking}
                handleChange={handleDepotValuationAfterBookingChange}
                endAdornment={currencySymbol}
              />
            </Grid>
          )}
        </GridContainer>
      </Container>
    </StepContainer>
  );
};
