import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const initFromInvestmentTypeAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "INVESTMENT_TYPE_SELECTED" &&
    event.type !== "INIT_FROM_BOOKING_TYPE"
  )
    return {};

  return {
    investmentValues: {
      ...context.investmentValues,
      type: event.investmentType,
      tickerSymbol: undefined,
      quoteProvider: "none",
    },
  };
};
