import { UseQueryOptions } from "react-query";
import {
  CategoriesV2ApiUpdateCategoryV2Request,
  CategoryV2,
} from "@generated/apiv1";

import { buildCategoriesV2Api } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const updateCategoryV2 = async (
  token: string | undefined,
  params: CategoriesV2ApiUpdateCategoryV2Request
) => {
  const apiInstance = buildCategoriesV2Api(token);
  const { data } = await apiInstance.updateCategoryV2(params);
  return data;
};

export const useUpdateCategoryV2 = (
  params: CategoriesV2ApiUpdateCategoryV2Request,
  options?: UseQueryOptions<CategoryV2, AxiosApiError, CategoryV2>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIESV2_UPDATE_CATEGORY_V2, params],
    ({ token }) => updateCategoryV2(token, params),
    options
  );
};
