/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * CryptoCurrenciesApi - axios parameter creator
 * @export
 */
export const CryptoCurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a crypto currency.
         * @param {string} id Add the given crypto currency; the coin-gecko ID must be provided (example: bitcoin).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCryptoCurrency: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addCryptoCurrency', 'id', id)
            const localVarPath = `/v1/cryptoCurrencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (Re)load data for the entire crypto currency catalogue.
         * @param {boolean} [refreshHistoricQuotes] Refresh historic quotes as well if true; default is to refresh todays quote only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoCurrenciesRefresh: async (refreshHistoricQuotes?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cryptoCurrencies/catalogue/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (refreshHistoricQuotes !== undefined) {
                localVarQueryParameter['refreshHistoricQuotes'] = refreshHistoricQuotes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CryptoCurrenciesApi - functional programming interface
 * @export
 */
export const CryptoCurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CryptoCurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a crypto currency.
         * @param {string} id Add the given crypto currency; the coin-gecko ID must be provided (example: bitcoin).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCryptoCurrency(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCryptoCurrency(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CryptoCurrenciesApi.addCryptoCurrency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary (Re)load data for the entire crypto currency catalogue.
         * @param {boolean} [refreshHistoricQuotes] Refresh historic quotes as well if true; default is to refresh todays quote only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoCurrenciesRefresh(refreshHistoricQuotes?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoCurrenciesRefresh(refreshHistoricQuotes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CryptoCurrenciesApi.cryptoCurrenciesRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CryptoCurrenciesApi - factory interface
 * @export
 */
export const CryptoCurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CryptoCurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a crypto currency.
         * @param {CryptoCurrenciesApiAddCryptoCurrencyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCryptoCurrency(requestParameters: CryptoCurrenciesApiAddCryptoCurrencyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addCryptoCurrency(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (Re)load data for the entire crypto currency catalogue.
         * @param {CryptoCurrenciesApiCryptoCurrenciesRefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoCurrenciesRefresh(requestParameters: CryptoCurrenciesApiCryptoCurrenciesRefreshRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cryptoCurrenciesRefresh(requestParameters.refreshHistoricQuotes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addCryptoCurrency operation in CryptoCurrenciesApi.
 * @export
 * @interface CryptoCurrenciesApiAddCryptoCurrencyRequest
 */
export interface CryptoCurrenciesApiAddCryptoCurrencyRequest {
    /**
     * Add the given crypto currency; the coin-gecko ID must be provided (example: bitcoin).
     * @type {string}
     * @memberof CryptoCurrenciesApiAddCryptoCurrency
     */
    readonly id: string
}

/**
 * Request parameters for cryptoCurrenciesRefresh operation in CryptoCurrenciesApi.
 * @export
 * @interface CryptoCurrenciesApiCryptoCurrenciesRefreshRequest
 */
export interface CryptoCurrenciesApiCryptoCurrenciesRefreshRequest {
    /**
     * Refresh historic quotes as well if true; default is to refresh todays quote only.
     * @type {boolean}
     * @memberof CryptoCurrenciesApiCryptoCurrenciesRefresh
     */
    readonly refreshHistoricQuotes?: boolean
}

/**
 * CryptoCurrenciesApi - object-oriented interface
 * @export
 * @class CryptoCurrenciesApi
 * @extends {BaseAPI}
 */
export class CryptoCurrenciesApi extends BaseAPI {
    /**
     * 
     * @summary Add a crypto currency.
     * @param {CryptoCurrenciesApiAddCryptoCurrencyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoCurrenciesApi
     */
    public addCryptoCurrency(requestParameters: CryptoCurrenciesApiAddCryptoCurrencyRequest, options?: RawAxiosRequestConfig) {
        return CryptoCurrenciesApiFp(this.configuration).addCryptoCurrency(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (Re)load data for the entire crypto currency catalogue.
     * @param {CryptoCurrenciesApiCryptoCurrenciesRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoCurrenciesApi
     */
    public cryptoCurrenciesRefresh(requestParameters: CryptoCurrenciesApiCryptoCurrenciesRefreshRequest = {}, options?: RawAxiosRequestConfig) {
        return CryptoCurrenciesApiFp(this.configuration).cryptoCurrenciesRefresh(requestParameters.refreshHistoricQuotes, options).then((request) => request(this.axios, this.basePath));
    }
}

