import { UseMutationOptions } from "react-query";
import {
  TppWebFormsApiCreateTppWebFormRequest,
  TppWebForm,
} from "@generated/apiv1";

import { buildTppWebFormsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createTppWebForm = async (
  token: string | undefined,
  params: TppWebFormsApiCreateTppWebFormRequest
) => {
  const apiInstance = buildTppWebFormsApi(token);
  const { data } = await apiInstance.createTppWebForm(params);
  return data;
};

export const useCreateTppWebForm = (
  options?: UseMutationOptions<
    TppWebForm,
    AxiosApiError,
    TppWebFormsApiCreateTppWebFormRequest
  >
) => {
  return useAuthMutation(createTppWebForm, options, false);
};
