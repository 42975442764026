import React, { FunctionComponent, useContext } from "react";
import { WithReferenceComponent } from "./withReference";
import { DataContext } from "./Wizard";
import { WizardManagerProps, WizardManager } from "./WizardManager";

export type Child<C> = WithReferenceComponent<C> | WithIfComponent<C>;

type WithIfComponent<C> = FunctionComponent<
  WizardManagerProps<C> & { innerStepNumber: number }
>;

export const withIf = <C extends {}>(
  conditionFn: (context: Partial<C>) => boolean | undefined,
  children: Child<C>[]
) => {
  const WithIf: WithIfComponent<C> = ({
    innerStepNumber,
    onPrevious,
    animation,
    validation,
    globalStepNumber,
    children: parentChildren,
    initialStepNumber,
  }) => {
    const context = useContext(DataContext);
    const isTrue = conditionFn(context.data);

    const childrenAfterWithIf = parentChildren.slice(innerStepNumber + 1);

    const newChildren = isTrue
      ? [...children, ...childrenAfterWithIf]
      : childrenAfterWithIf;

    return (
      <WizardManager
        globalStepNumber={globalStepNumber}
        onPrevious={onPrevious}
        children={newChildren}
        animation={animation}
        validation={validation}
        initialStepNumber={initialStepNumber}
      />
    );
  };
  WithIf.displayName = "WithIf";
  return WithIf;
};
