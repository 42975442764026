import { UseQueryOptions } from "react-query";
import {
  ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest,
  IndustryBreakdownList,
} from "@generated/apiv1";

import { buildClassificationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicListIndustryClassificationsForAllInvestments = async (
  token: string | undefined,
  params: ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest
) => {
  const apiInstance = buildClassificationsApi(token);
  const { data } =
    await apiInstance.publicListIndustryClassificationsForAllInvestments(
      params
    );
  return data;
};

export const usePublicListIndustryClassificationsForAllInvestments = (
  params: ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest,
  options?: UseQueryOptions<
    IndustryBreakdownList,
    AxiosApiError,
    IndustryBreakdownList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CLASSIFICATIONS_PUBLIC_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
      params,
    ],
    ({ token }) =>
      publicListIndustryClassificationsForAllInvestments(token, params),
    options
  );
};
