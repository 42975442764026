import { useMemo } from "react";
import { DividendHistoryTreeNode, InvestmentReference } from "@generated/apiv1";
import {
  useDividendsContext,
  useDividendsForPeriod,
} from "@features/dividendPlanner/DividendsProvider";

export type DividendChartData = {
  dividends: (DividendHistoryTreeNode & {
    investmentReference?: InvestmentReference;
  })[];
  y: number;
  name: [number, number] | number;
  year: number;
  month?: number;
  isPredicted: boolean;
  color?: Pattern | string;
};

type Pattern = {
  pattern: {
    path: {
      d: string;
      stroke: string;
      strokeWidth: number;
    };
    width: number;
    height: number;
  };
};

const getEmptyMonth = (year: number, month: number): DividendChartData => ({
  dividends: [],
  y: 0,
  name: [month, year],
  year,
  month,
  isPredicted: false,
});

const pattern: Pattern = {
  pattern: {
    path: {
      d: "M 3 -3 L -3 3 M 6 0 L 0 6 M 9 3 L 3 9",
      stroke: "#CE7D34",
      strokeWidth: 1.75,
    },
    width: 6,
    height: 6,
  },
};

const getAllMonthsFromYears = (
  dividends: Record<string, DividendHistoryTreeNode> | null
): DividendHistoryTreeNode[] => {
  if (!dividends) return [];

  return Object.values(dividends)
    .slice(-3, -1)
    .reduce((acc, year) => {
      return [...acc, ...Object.values(year.children || {})];
    }, [] as DividendHistoryTreeNode[]);
};

const getDisplayedDividends = ({
  dividends,
  view,
  currentYear,
}: {
  dividends: Record<string, DividendHistoryTreeNode> | null;
  view: "month" | "year";
  currentYear?: number;
}): DividendHistoryTreeNode[] => {
  if (!dividends) return [];

  if (view === "month") {
    if (!currentYear) {
      return getAllMonthsFromYears(dividends);
    }

    return Object.values(dividends[currentYear]?.children || {});
  }

  return currentYear ? [dividends[currentYear]] : Object.values(dividends);
};

export const useDividendsChartData = ({
  currentYear,
}: {
  currentYear?: number;
}): DividendChartData[] => {
  const { investments, isUserDataFetched, areImagesFetched } =
    useDividendsContext();
  const dividends = useDividendsForPeriod({
    year: currentYear,
  });
  const displayedDividends = useMemo(
    () =>
      getDisplayedDividends({
        dividends,
        view: currentYear ? "month" : "year",
        currentYear,
      }),
    [dividends, currentYear]
  );

  return useMemo(() => {
    if (!dividends || !isUserDataFetched || !areImagesFetched) {
      return [];
    }

    let formattedDividends: DividendChartData[] = [];

    if (currentYear) {
      const dividendsFromBackend: DividendChartData[] =
        displayedDividends.map((month) => {
          const isPredicted =
            Object.values(month.children || {}).every(
              (dividend) => dividend.isPrediction
            ) || false;

          return {
            dividends: Object.values(month.children || {}).map((item) => {
              const investmentRef = investments?.[item.investmentIds[0]];
              return {
                ...item,
                investmentReference: investmentRef,
              };
            }),
            y: month.grossAmount,
            name: [month.month, month.year],
            year: month.year,
            month: month.month,
            isPredicted,
            color: isPredicted ? pattern : "#11B87B",
          };
        }) || [];

      formattedDividends = Array(12)
        .fill(undefined)
        .map((_, index) => {
          const month = dividendsFromBackend.find(
            (dividend) => dividend.month === index
          );

          return month || getEmptyMonth(currentYear, index);
        });
    } else {
      formattedDividends = displayedDividends.map((year) => {
        const isPredicted =
          Object.values(year.children || {}).every(
            (month) => month.isPrediction
          ) || false;

        return {
          dividends: Object.values(year.children || {}).reduce(
            (acc, month) => [
              ...acc,
              ...Object.values(month.children || {}).map((item) => {
                return {
                  ...item,
                  investmentReference: investments?.[item.investmentIds[0]],
                };
              }),
            ],
            [] as DividendChartData["dividends"]
          ),
          y: year.grossAmount,
          name: year.year,
          year: year.year,
          month: undefined,
          isPredicted: isPredicted,
          color: isPredicted ? pattern : "#11B87B",
        };
      });
    }
    return formattedDividends;
  }, [
    displayedDividends,
    dividends,
    investments,
    currentYear,
    isUserDataFetched,
    areImagesFetched,
  ]);
};
