import React, { useEffect, useState } from "react";
import { Box, Button, InputLabel, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { User } from "@generated/user";
import { LoadingButton, LoadingPlaceHolder } from "@components";
import { useChangeAutoSignOutDuration } from "@generated/user/hooks";
import { useToken } from "@api";
import { useRefreshToken } from "@api/user/useRefreshToken";
import { ReactComponent as CheckIcon } from "@icons/myicons/green-check.svg";
import { ReactComponent as XIcon } from "@icons/myicons/circle-red-x.svg";
import { ReactComponent as EditIcon } from "@icons/myicons/edit_alt.svg";
import { ReactComponent as DeleteIcon } from "@icons/myicons/trash-bin.svg";
import {
  LogoutDuration,
  LogoutDurationSelector,
} from "./components/LogoutDurationSelector";
import { ChangeMFALabelModal } from "./components/ChangeMFALabelModal";
import { DeleteMFAModal } from "./components/DeleteMFAModal";

function SettingsSubscriptionsPage({
  userData,
  isLoadingUserData,
}: {
  userData?: User;
  isLoadingUserData: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { data: tokenData } = useToken();
  const { mutate: refreshToken } = useRefreshToken();
  const { mutate: changeDuration, isLoading } = useChangeAutoSignOutDuration({
    onSuccess: async () => {
      enqueueSnackbar(t("settingsPage.security.logout.success"), {
        autoHideDuration: 3000,
        variant: "success",
      });
      refreshToken({
        refreshToken: tokenData?.refresh_token ?? "",
      });
    },
    onError: () => {
      enqueueSnackbar(t("settingsPage.commonErrorMessage"), {
        autoHideDuration: 3000,
        variant: "error",
      });
    },
  });
  const [selectedDuration, setSelectedDuration] = useState<LogoutDuration>(
    userData?.autoSignOutDuration ?? "1W"
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const hasMFA = userData?.otpRequiredForLogin ?? false;

  const handleDurationSave = () => {
    changeDuration({
      newAutoSignOutDuration: selectedDuration,
    });
  };

  useEffect(() => {
    setSelectedDuration(userData?.autoSignOutDuration ?? "1W");
  }, [userData]);

  if (isLoadingUserData) return <LoadingPlaceHolder />;

  return (
    <>
      {hasMFA ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            gap={theme.spacing(2)}
            mb={theme.spacing(4)}
          >
            <CheckIcon />
            <Typography letterSpacing={-0.5}>
              {t("settingsPage.security.active")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" fontSize="18px" lineHeight="28px">
              {t("settingsPage.security.method")}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" gap={theme.spacing(3)}>
                <Typography
                  variant="body1"
                  fontSize="18px"
                  lineHeight="28px"
                  fontWeight={400}
                >
                  {userData?.otpLabel}
                </Typography>
                <EditIcon
                  cursor="pointer"
                  onClick={() => setShowEditModal(true)}
                />
              </Box>
              <DeleteIcon
                cursor="pointer"
                onClick={() => setShowDeleteModal(true)}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            gap={theme.spacing(2)}
            mb={theme.spacing(5)}
          >
            <XIcon />
            <Typography letterSpacing={-0.5} fontWeight={400}>
              {t("settingsPage.security.inactive")}
            </Typography>
          </Box>
          <Button
            sx={{ width: "100%", border: "none!important" }}
            variant="outlined"
            color="primary"
            onClick={() => navigate("/settings/security/create")}
          >
            {t("settingsPage.security.setupNow")}
          </Button>
        </>
      )}
      <Box mt={theme.spacing(12)}>
        <Typography
          variant="h5"
          fontSize="26px"
          fontWeight={600}
          color={theme.palette.text.primary}
        >
          {t("settingsPage.security.logout.autoLogout")}
        </Typography>
        <InputLabel sx={{ marginTop: theme.spacing(4) }}>
          {t("settingsPage.security.logout.logoutPeriod")}
        </InputLabel>
        <LogoutDurationSelector
          selected={selectedDuration}
          setSelected={setSelectedDuration}
        />
        <Typography variant="body1" fontWeight={400} letterSpacing={-0.4}>
          {t("settingsPage.security.logout.logoutDescription")}
        </Typography>
        <LoadingButton
          sx={{ width: "100%", marginTop: theme.spacing(12) }}
          variant="contained"
          color="primary"
          onClick={handleDurationSave}
          isLoading={isLoading}
        >
          {t("settingsPage.security.logout.logoutButton")}
        </LoadingButton>
      </Box>
      <ChangeMFALabelModal
        name={userData?.otpLabel ?? ""}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
      />
      <DeleteMFAModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        methodName={userData?.otpLabel ?? "Auth"}
      />
    </>
  );
}

export default SettingsSubscriptionsPage;
