/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * The three-letter ISO code
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * The literal name of the currency
     * @type {string}
     * @memberof Currency
     */
    'name'?: string;
    /**
     * Exchange rate to EUR
     * @type {number}
     * @memberof Currency
     */
    'toEUR'?: number;
    /**
     * The currency type
     * @type {string}
     * @memberof Currency
     */
    'type'?: CurrencyTypeEnum;
}

export const CurrencyTypeEnum = {
    STANDARD: 'standard',
    CRYPTO: 'crypto'
} as const;

export type CurrencyTypeEnum = typeof CurrencyTypeEnum[keyof typeof CurrencyTypeEnum];


