import { QueryClient } from "react-query";
import { NoTokenError } from "./NoTokenError";
import { getAccessTokenQueryFn } from "./getAccessTokenQueryFn";

/**
 * Allows to retrieve a token from local storage or cache and pass the token to the wrapped function.
 * Used for wrapping API call functions which used directly without `useAuthQuery` or `useAuthMutation`.
 * Please avoid using this function unless there is no way to use `useAuthQuery` or `useAuthMutation`.
 */

export const withTokenFactory =
  (queryClient: QueryClient) =>
  <TArgs extends unknown[], TReturn>(
    fn: (token: string, ...args: TArgs) => Promise<TReturn>
  ): ((...args: TArgs) => Promise<TReturn>) => {
    const tokenFn = getAccessTokenQueryFn(queryClient);
    return async (...args: TArgs) => {
      const token = await tokenFn();
      if (!token) {
        throw new NoTokenError("Token is empty");
      }
      return fn(token.access_token, ...args);
    };
  };
