import { UseQueryOptions } from "react-query";
import {
  ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest,
  RegionBreakdownList,
} from "@generated/apiv1";

import { buildClassificationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicListRegionClassificationsForAllInvestments = async (
  token: string | undefined,
  params: ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest
) => {
  const apiInstance = buildClassificationsApi(token);
  const { data } =
    await apiInstance.publicListRegionClassificationsForAllInvestments(params);
  return data;
};

export const usePublicListRegionClassificationsForAllInvestments = (
  params: ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest,
  options?: UseQueryOptions<
    RegionBreakdownList,
    AxiosApiError,
    RegionBreakdownList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CLASSIFICATIONS_PUBLIC_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
      params,
    ],
    ({ token }) =>
      publicListRegionClassificationsForAllInvestments(token, params),
    options
  );
};
