/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateWatchlistParams } from '../model';
// @ts-ignore
import { Watchlist } from '../model';
// @ts-ignore
import { WatchlistList } from '../model';
/**
 * WatchlistsApi - axios parameter creator
 * @export
 */
export const WatchlistsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a watchlist.
         * @param {CreateOrUpdateWatchlistParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWatchlist: async (body: CreateOrUpdateWatchlistParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createWatchlist', 'body', body)
            const localVarPath = `/v1/watchlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Watchlist, including all of its entries.
         * @param {number} id ID of the watchlist to delete. This will also delete all watchlist entries assigned to the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchlist: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWatchlist', 'id', id)
            const localVarPath = `/v1/watchlists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the changes to a watchlist.
         * @param {number} id ID of the watchlist to update
         * @param {CreateOrUpdateWatchlistParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWatchlist: async (id: number, body: CreateOrUpdateWatchlistParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editWatchlist', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editWatchlist', 'body', body)
            const localVarPath = `/v1/watchlists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a watchlist.
         * @param {number} id Retrieve the given watchlist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchlist: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWatchlist', 'id', id)
            const localVarPath = `/v1/watchlists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the selected watchlists, or all watchlists for the currently logged in user.
         * @param {Array<number>} [id] Retrieve the given watchlists.
         * @param {number} [userId] Retrieve all accounts for the given user in anonymized watchlist format. The current                              user must be a friend of the logged in user in order for this endpoint to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllWatchlists: async (id?: Array<number>, userId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/watchlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WatchlistsApi - functional programming interface
 * @export
 */
export const WatchlistsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WatchlistsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a watchlist.
         * @param {CreateOrUpdateWatchlistParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWatchlist(body: CreateOrUpdateWatchlistParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Watchlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWatchlist(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistsApi.createWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Watchlist, including all of its entries.
         * @param {number} id ID of the watchlist to delete. This will also delete all watchlist entries assigned to the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWatchlist(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWatchlist(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistsApi.deleteWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save the changes to a watchlist.
         * @param {number} id ID of the watchlist to update
         * @param {CreateOrUpdateWatchlistParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editWatchlist(id: number, body: CreateOrUpdateWatchlistParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Watchlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editWatchlist(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistsApi.editWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a watchlist.
         * @param {number} id Retrieve the given watchlist.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWatchlist(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Watchlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWatchlist(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistsApi.getWatchlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the selected watchlists, or all watchlists for the currently logged in user.
         * @param {Array<number>} [id] Retrieve the given watchlists.
         * @param {number} [userId] Retrieve all accounts for the given user in anonymized watchlist format. The current                              user must be a friend of the logged in user in order for this endpoint to work.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllWatchlists(id?: Array<number>, userId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllWatchlists(id, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistsApi.listAndSearchAllWatchlists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WatchlistsApi - factory interface
 * @export
 */
export const WatchlistsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WatchlistsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a watchlist.
         * @param {WatchlistsApiCreateWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWatchlist(requestParameters: WatchlistsApiCreateWatchlistRequest, options?: RawAxiosRequestConfig): AxiosPromise<Watchlist> {
            return localVarFp.createWatchlist(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Watchlist, including all of its entries.
         * @param {WatchlistsApiDeleteWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchlist(requestParameters: WatchlistsApiDeleteWatchlistRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWatchlist(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the changes to a watchlist.
         * @param {WatchlistsApiEditWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWatchlist(requestParameters: WatchlistsApiEditWatchlistRequest, options?: RawAxiosRequestConfig): AxiosPromise<Watchlist> {
            return localVarFp.editWatchlist(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a watchlist.
         * @param {WatchlistsApiGetWatchlistRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchlist(requestParameters: WatchlistsApiGetWatchlistRequest, options?: RawAxiosRequestConfig): AxiosPromise<Watchlist> {
            return localVarFp.getWatchlist(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the selected watchlists, or all watchlists for the currently logged in user.
         * @param {WatchlistsApiListAndSearchAllWatchlistsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllWatchlists(requestParameters: WatchlistsApiListAndSearchAllWatchlistsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistList> {
            return localVarFp.listAndSearchAllWatchlists(requestParameters.id, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWatchlist operation in WatchlistsApi.
 * @export
 * @interface WatchlistsApiCreateWatchlistRequest
 */
export interface WatchlistsApiCreateWatchlistRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateWatchlistParams}
     * @memberof WatchlistsApiCreateWatchlist
     */
    readonly body: CreateOrUpdateWatchlistParams
}

/**
 * Request parameters for deleteWatchlist operation in WatchlistsApi.
 * @export
 * @interface WatchlistsApiDeleteWatchlistRequest
 */
export interface WatchlistsApiDeleteWatchlistRequest {
    /**
     * ID of the watchlist to delete. This will also delete all watchlist entries assigned to the list.
     * @type {number}
     * @memberof WatchlistsApiDeleteWatchlist
     */
    readonly id: number
}

/**
 * Request parameters for editWatchlist operation in WatchlistsApi.
 * @export
 * @interface WatchlistsApiEditWatchlistRequest
 */
export interface WatchlistsApiEditWatchlistRequest {
    /**
     * ID of the watchlist to update
     * @type {number}
     * @memberof WatchlistsApiEditWatchlist
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateWatchlistParams}
     * @memberof WatchlistsApiEditWatchlist
     */
    readonly body: CreateOrUpdateWatchlistParams
}

/**
 * Request parameters for getWatchlist operation in WatchlistsApi.
 * @export
 * @interface WatchlistsApiGetWatchlistRequest
 */
export interface WatchlistsApiGetWatchlistRequest {
    /**
     * Retrieve the given watchlist.
     * @type {number}
     * @memberof WatchlistsApiGetWatchlist
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllWatchlists operation in WatchlistsApi.
 * @export
 * @interface WatchlistsApiListAndSearchAllWatchlistsRequest
 */
export interface WatchlistsApiListAndSearchAllWatchlistsRequest {
    /**
     * Retrieve the given watchlists.
     * @type {Array<number>}
     * @memberof WatchlistsApiListAndSearchAllWatchlists
     */
    readonly id?: Array<number>

    /**
     * Retrieve all accounts for the given user in anonymized watchlist format. The current                              user must be a friend of the logged in user in order for this endpoint to work.
     * @type {number}
     * @memberof WatchlistsApiListAndSearchAllWatchlists
     */
    readonly userId?: number
}

/**
 * WatchlistsApi - object-oriented interface
 * @export
 * @class WatchlistsApi
 * @extends {BaseAPI}
 */
export class WatchlistsApi extends BaseAPI {
    /**
     * 
     * @summary Create a watchlist.
     * @param {WatchlistsApiCreateWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistsApi
     */
    public createWatchlist(requestParameters: WatchlistsApiCreateWatchlistRequest, options?: RawAxiosRequestConfig) {
        return WatchlistsApiFp(this.configuration).createWatchlist(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Watchlist, including all of its entries.
     * @param {WatchlistsApiDeleteWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistsApi
     */
    public deleteWatchlist(requestParameters: WatchlistsApiDeleteWatchlistRequest, options?: RawAxiosRequestConfig) {
        return WatchlistsApiFp(this.configuration).deleteWatchlist(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the changes to a watchlist.
     * @param {WatchlistsApiEditWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistsApi
     */
    public editWatchlist(requestParameters: WatchlistsApiEditWatchlistRequest, options?: RawAxiosRequestConfig) {
        return WatchlistsApiFp(this.configuration).editWatchlist(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a watchlist.
     * @param {WatchlistsApiGetWatchlistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistsApi
     */
    public getWatchlist(requestParameters: WatchlistsApiGetWatchlistRequest, options?: RawAxiosRequestConfig) {
        return WatchlistsApiFp(this.configuration).getWatchlist(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the selected watchlists, or all watchlists for the currently logged in user.
     * @param {WatchlistsApiListAndSearchAllWatchlistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistsApi
     */
    public listAndSearchAllWatchlists(requestParameters: WatchlistsApiListAndSearchAllWatchlistsRequest = {}, options?: RawAxiosRequestConfig) {
        return WatchlistsApiFp(this.configuration).listAndSearchAllWatchlists(requestParameters.id, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}

