import styled from "@emotion/styled";

export const LegendCircle = styled.div<{
  color: string;
  isSelected?: boolean;
  size?: string;
}>`
  display: inline-block;
  box-sizing: unset;
  width: ${({ size = "10px" }) => size};
  height: ${({ size = "10px" }) => size};
  border-radius: 50%;
  background-color: ${({ color }) => color};
  ${({ isSelected, color }) =>
    isSelected ? `background-color: ${color};` : ""}
`;
