import { convertDateToOnlyDateString } from "@helpers";
import { Values } from "../DepotTransferWizard";
import { useTransferBatchInvestments } from "./useTransferBatchInvestments";
import { useTransferDepot } from "./useTransferDepot";

export const useTransfer = () => {
  const { mutateAsync: transferDepot } = useTransferDepot();
  const { mutateAsync: transferInvestments } = useTransferBatchInvestments();

  const transfer = async (values: Values) => {
    if (
      !values.toAccountId ||
      !values.dateOfTransfer ||
      !values.fromAccountId
    ) {
      throw new Error(
        "toAccountId, dateOfTransfer, fromAccountId are required attributes"
      );
    }

    if (values.transferType === "full") {
      return await transferDepot({
        transferFromAccountId: values.fromAccountId,
        transferToAccountId: values.toAccountId,
        dateOfTransfer: convertDateToOnlyDateString(values.dateOfTransfer)!,
        transferFullLotsOnly: values.transferFullLots === "onlyFullLots",
      });
    }

    const investmentsToTransfer = values.investmentsToTransfer;

    if (!investmentsToTransfer || investmentsToTransfer.length < 0) {
      throw new Error("investmentsToTransfer can not be empty");
    }

    return transferInvestments({
      dateOfTransfer: convertDateToOnlyDateString(values.dateOfTransfer)!,
      transferToAccountId: values.toAccountId!,
      investmentBatchTransferParamsList: investmentsToTransfer
        .filter(({ numberOfLots }) => numberOfLots && numberOfLots > 0)
        .map((investment) => ({
          numberOfLots: investment.numberOfLots!,
          id: investment.id,
        })),
    });
  };

  return transfer;
};
