import { UseQueryOptions } from "react-query";
import { SecurityCommissionTaxationStatisticList } from "@generated/apiv1";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const securityCommissionsTaxationStatistics = async (
  token: string | undefined
) => {
  const apiInstance = buildSecurityCommissionsApi(token);
  const { data } = await apiInstance.securityCommissionsTaxationStatistics();
  return data;
};

export const useSecurityCommissionsTaxationStatistics = (
  options?: UseQueryOptions<
    SecurityCommissionTaxationStatisticList,
    AxiosApiError,
    SecurityCommissionTaxationStatisticList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_TAXATION_STATISTICS],
    ({ token }) => securityCommissionsTaxationStatistics(token),
    options
  );
};
