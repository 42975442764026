import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiListAndSearchAllInvestmentsRequest,
  PageableInvestmentList,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllInvestments = async (
  token: string | undefined,
  params?: InvestmentsApiListAndSearchAllInvestmentsRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.listAndSearchAllInvestments(params);
  return data;
};

export const useListAndSearchAllInvestments = (
  params?: InvestmentsApiListAndSearchAllInvestmentsRequest,
  options?: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_LIST_AND_SEARCH_ALL_INVESTMENTS, params],
    ({ token }) => listAndSearchAllInvestments(token, params),
    options
  );
};
