/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DepotSynchronizationLogItem
 */
export interface DepotSynchronizationLogItem {
    /**
     * Technical identifier
     * @type {number}
     * @memberof DepotSynchronizationLogItem
     */
    'id': number;
    /**
     * The synchronization log this item belongs to; typically more then one positions are         updated during a synchronization run
     * @type {number}
     * @memberof DepotSynchronizationLogItem
     */
    'depotSynchronizationLogId': number;
    /**
     * ID of the booking that was created
     * @type {number}
     * @memberof DepotSynchronizationLogItem
     */
    'bookingId'?: number;
    /**
     * ISIN of the investment that was updated
     * @type {string}
     * @memberof DepotSynchronizationLogItem
     */
    'isin'?: string;
    /**
     * WKN of the investment that was updated
     * @type {string}
     * @memberof DepotSynchronizationLogItem
     */
    'wkn'?: string;
    /**
     * Name of the investment that was updated
     * @type {string}
     * @memberof DepotSynchronizationLogItem
     */
    'name'?: string;
    /**
     * State of the synchronization for this specific item
     * @type {string}
     * @memberof DepotSynchronizationLogItem
     */
    'synchronizationStatus': DepotSynchronizationLogItemSynchronizationStatusEnum;
    /**
     * Was the date estimated; e.g. because we could not find a transaction?
     * @type {boolean}
     * @memberof DepotSynchronizationLogItem
     */
    'wasDateEstimated'?: boolean;
    /**
     * Was the price estimated; e.g. becaue we could not find a transaction?
     * @type {boolean}
     * @memberof DepotSynchronizationLogItem
     */
    'wasPriceEstimated'?: boolean;
}

export const DepotSynchronizationLogItemSynchronizationStatusEnum = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
} as const;

export type DepotSynchronizationLogItemSynchronizationStatusEnum = typeof DepotSynchronizationLogItemSynchronizationStatusEnum[keyof typeof DepotSynchronizationLogItemSynchronizationStatusEnum];


