import ReactCountryFlag from "react-country-flag";
import React from "react";
import { InvestmentTypeBreakdown, RegionBreakdown } from "@generated/apiv1";
import { ChartBreakdown } from "@features/analysis/components/types";

const getName = (
  breakdown: InvestmentTypeBreakdown | RegionBreakdown,
  key: string,
  translations: { [key: string]: string }
) => {
  const name =
    breakdown.investment?.standardisedName ||
    breakdown.investment?.name ||
    // @ts-ignore
    translations[breakdown[key]?.germanName || breakdown[key]?.name] ||
    // @ts-ignore
    breakdown[key]?.germanName ||
    // @ts-ignore
    breakdown[key]?.name;

  if (breakdown.type === "cash") {
    return `[Cash] ${name}`;
  }
  return name;
};

const breakdownIsRegion = (
  breakdown: InvestmentTypeBreakdown | RegionBreakdown
): breakdown is RegionBreakdown => {
  return breakdown.type === "region";
};

const breakdownIsInvestment = (
  breakdown: InvestmentTypeBreakdown | RegionBreakdown
): breakdown is InvestmentTypeBreakdown => {
  return breakdown.type === "investment";
};

export const convertToChartBreakdown = (
  breakdowns: (InvestmentTypeBreakdown | RegionBreakdown)[],
  key: string,
  translations: { [key: string]: string },
  logos: Record<string, string | undefined | null>
): ChartBreakdown[] =>
  breakdowns
    .map((breakdown) => ({
      id:
        breakdown.type === "investment" && breakdown.investment?.id
          ? breakdown.investment?.id
          : breakdown.id!,
      uid: breakdown.uid,
      value: breakdown.marketValue || 0,
      name: getName(breakdown, key, translations) || "",
      parentId: breakdown.parentId,
      hasChildren: breakdown.type !== "investment" && breakdown.type !== "cash",
      currency: "EUR",
      icon: breakdownIsInvestment(breakdown) ? (
        logos[breakdown.investment?.isin || ""] ||
        logos[breakdown.investment?.tickerSymbol || ""]
      ) : breakdownIsRegion(breakdown) && breakdown.region?.twoLetterCode ? (
        <ReactCountryFlag
          countryCode={breakdown.region.twoLetterCode}
          svg
          title={breakdown.region.twoLetterCode}
        />
      ) : undefined,
      y: breakdown.marketValue || 0,
    }))
    .sort((a, b) => b.value - a.value);
