import {
  format,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  parseISO,
  isValid,
} from "date-fns";
import { enUS } from "date-fns/locale";

export const formatDateDDMMMYY = (value?: string | number) => {
  if (!value) return "";

  return `${format(new Date(value), "dd.MMM").replace(/\.$/, "")}'${format(
    new Date(value),
    "yy"
  )}`;
};

export const formatDateDDMMYY = (value?: string | number) => {
  if (!value) return "";
  return `${format(new Date(value), "dd.MM")}'${format(new Date(value), "yy")}`;
};

export const formatDateMMMYY = (value?: string | number) => {
  if (!value) return "";
  return `${format(new Date(value), "MMM", {
    locale: enUS,
  }).replace(/\.$/, "")} '${format(new Date(value), "yy")}`;
};

export const formatDateDDMMM = (value?: string | number) => {
  if (!value) return "";
  return `${format(new Date(value), "dd")}. ${format(new Date(value), "MMM", {
    locale: enUS,
  }).replace(/\.$/, "")}`;
};

export const formatYYYY = (value?: string | number) => {
  if (!value) return "";
  return format(new Date(value), "yyyy");
};

export const midday = (date: Date) => addHours(startOfDay(date), 12);

export const intervalToTimeRange = (
  interval: IntervalType,
  startDate?: Date,
  endDate?: Date
) => {
  if (interval === "oneDay") {
    return {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    };
  }

  if (interval === "oneWeek") {
    return {
      startDate: midday(addWeeks(new Date(), -1)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "oneMonth") {
    return {
      startDate: midday(addMonths(new Date(), -1)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "sixMonths") {
    return {
      startDate: midday(addMonths(new Date(), -6)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "oneYear") {
    return {
      startDate: midday(addYears(new Date(), -1)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "twoYears") {
    return {
      startDate: midday(addYears(new Date(), -2)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "threeYears") {
    return {
      startDate: midday(addYears(new Date(), -3)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "fiveYears") {
    return {
      startDate: midday(addYears(new Date(), -5)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "YTD") {
    return {
      startDate: midday(startOfYear(new Date())),
      endDate: midday(new Date()),
    };
  }
  if (interval === "14days") {
    return {
      startDate: midday(addDays(new Date(), -14)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "30days") {
    return {
      startDate: midday(addDays(new Date(), -30)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "60days") {
    return {
      startDate: midday(addDays(new Date(), -60)),
      endDate: midday(new Date()),
    };
  }
  if (interval === "sinceBeginning") {
    return {
      startDate: midday(addYears(new Date(), -100)),
      endDate: midday(new Date()),
    };
  }

  if (interval === "all") {
    return {
      startDate: undefined,
      endDate: midday(new Date()),
    };
  }

  if (interval === "custom" && startDate && endDate) {
    return {
      startDate,
      endDate,
    };
  }

  throw new Error(`Unknown interval: ${interval}`);
};

export const startDate = (currentDate = new Date()) =>
  startOfMonth(addMonths(addDays(currentDate, 25), 1));

export const sameDateInUTC = (date: Date) =>
  addMinutes(
    date,
    -date.getTimezoneOffset() // it fixes timezone for saving to backend as date-fns calculates always in local timezone
  );

export const startDateUTC = (currentDate = new Date()) =>
  sameDateInUTC(startDate(currentDate));

export const isBeforeOrEqual = (a: Date, b: Date) =>
  startOfDay(sameDateInUTC(a)) <= startOfDay(sameDateInUTC(b));

export const isAfterOrEqual = (a: Date, b: Date) =>
  startOfDay(sameDateInUTC(a)) >= startOfDay(sameDateInUTC(b));

export const formatDateOrString = (dateOrString: Date | string) => {
  return dateOrString instanceof Date
    ? format(dateOrString, "dd.MM.yyyy")
    : format(sameDateInUTC(parseISO(dateOrString)), "dd.MM.yyyy");
};

export const convertDateToOnlyDateString = (
  value: Date | string | null | undefined
): string | undefined => {
  if (!value) return;
  const date = typeof value === "string" ? new Date(value) : value;
  if (!(date instanceof Date)) return date;

  return format(date, "yyyy-MM-dd");
};

export const isDateValid = (date?: Date | null): date is Date => {
  if (!(date instanceof Date)) return false;
  return isValid(date) && date.getFullYear() > 1900;
};

export const isStringDateValid = (value?: string | null): value is string => {
  if (typeof value !== "string") return false;

  const date = new Date(value);
  return isValid(date) && new Date(date).getFullYear() >= 1900;
};

export const formatDateDDMMMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, "dd. MMMM yyyy");
};
