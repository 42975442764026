import { axiosErrorToApiException } from "@api";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const addAxiosErrorToContextAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "SOMETHING_WRONG") return {};

  return {
    error: axiosErrorToApiException(event.error),
  };
};
