/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The risk and yield statistics of a portfolio or a set of investments
 * @export
 * @interface RiskYield
 */
export interface RiskYield {
    /**
     * The annualized standard deviation; in percent
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedStandardDeviation'?: number;
    /**
     * The annualized risk class, ranging from 0 (lowest) to 2 (highest)
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedRiskClass'?: RiskYieldAnnualizedRiskClassEnum;
    /**
     * The annualized risk class in a more complex classification, ranging from 0 (lowest)                               to 6 (highest) in 0.01 interval steps
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedRiskClassComplex'?: number;
    /**
     * The annualized yield; in percent
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedReturn'?: number;
    /**
     * The annualized return class, ranging from 0 (lowest) to 2 (highest)
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedReturnClass'?: RiskYieldAnnualizedReturnClassEnum;
    /**
     * The annualized return class in a more complex classification, ranging from 0 (lowest)                               to 6 (highest) in 0.01 interval steps
     * @type {number}
     * @memberof RiskYield
     */
    'annualizedReturnClassComplex'?: number;
}

export const RiskYieldAnnualizedRiskClassEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type RiskYieldAnnualizedRiskClassEnum = typeof RiskYieldAnnualizedRiskClassEnum[keyof typeof RiskYieldAnnualizedRiskClassEnum];
export const RiskYieldAnnualizedReturnClassEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type RiskYieldAnnualizedReturnClassEnum = typeof RiskYieldAnnualizedReturnClassEnum[keyof typeof RiskYieldAnnualizedReturnClassEnum];


