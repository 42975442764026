import { useMemo, useState } from "react";
import { useSelectInterval } from "./useSelectInterval";

interface Attrs {
  onSelect?: (type: IntervalType, value: RangeType) => void;
  firstDate: Date;
  defaultIntervalType?: IntervalType;
  id?: string;
}
const LOCAL_STORAGE_PREFIX = "range-selector.";

export const useRangeSelectControl = ({
  onSelect,
  firstDate,
  defaultIntervalType = "oneYear",
  id = "",
}: Attrs) => {
  const defaultInterval = useMemo(() => {
    try {
      const storedRawRange = localStorage.getItem(
        `${LOCAL_STORAGE_PREFIX}${id}`
      );
      const storedRange = storedRawRange ? JSON.parse(storedRawRange) : null;
      return storedRange
        ? {
            intervalType: storedRange.intervalType,
            startDate: new Date(storedRange.startDate),
            endDate: new Date(storedRange.endDate),
          }
        : {
            intervalType: defaultIntervalType,
            startDate: firstDate,
            endDate: firstDate,
          };
    } catch (err) {
      return {
        intervalType: defaultIntervalType,
        startDate: firstDate,
        endDate: firstDate,
      };
    }
    // eslint-disable-next-line
  }, [`${LOCAL_STORAGE_PREFIX}${id}`]);

  const { range, selectInterval, selectCustomInterval, intervalType } =
    useSelectInterval(
      defaultInterval.intervalType,
      defaultInterval.startDate,
      defaultInterval.endDate
    );

  const [isCustomRangeSelectOpened, setIsCustomRangeSelectOpened] =
    useState<boolean>();
  const [isTimeBrushOpen, setIsTimeBrushOpen] = useState<boolean>(false);

  const handleCustomIntervalChange = (start: Date, end: Date) => {
    selectCustomInterval(start, end);

    if (id) {
      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}${id}`,
        JSON.stringify({
          intervalType: "custom",
          startDate: start,
          endDate: end,
        })
      );
    }
  };

  const handleTimeBrushSelect = (start: Date, end: Date) => {
    const newRange = selectCustomInterval(start, end);
    onSelect && onSelect("custom", newRange);

    if (id) {
      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}${id}`,
        JSON.stringify({
          intervalType: "custom",
          startDate: newRange.startDate,
          endDate: newRange.endDate,
        })
      );
    }
  };

  const handleRangeSelect = (value: IntervalType) => {
    const newRange = selectInterval(value);
    onSelect && onSelect(value, newRange);

    if (id) {
      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}${id}`,
        JSON.stringify({
          intervalType: value,
          startDate: newRange.startDate,
          endDate: newRange.endDate,
        })
      );
    }
  };

  const rangeStartDate = range.startDate || firstDate;

  return {
    range: { startDate: rangeStartDate, endDate: range.endDate },
    intervalType,
    isTimeBrushOpen,
    setIsTimeBrushOpen,
    customIntervalSelect: handleTimeBrushSelect,
    selectInterval: handleRangeSelect,
    isCustomRangeSelectOpened,
    setIsCustomRangeSelectOpened,
    customIntervalChange: handleCustomIntervalChange,
  };
};
