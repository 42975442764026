import { UseMutationOptions } from "react-query";
import { SecurityCommissionsApiDeleteCommissionsForFilenameRequest } from "@generated/apiv1";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteCommissionsForFilename = async (
  token: string | undefined,
  params: SecurityCommissionsApiDeleteCommissionsForFilenameRequest
) => {
  const apiInstance = buildSecurityCommissionsApi(token);
  const { data } = await apiInstance.deleteCommissionsForFilename(params);
  return data;
};

export const useDeleteCommissionsForFilename = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    SecurityCommissionsApiDeleteCommissionsForFilenameRequest
  >
) => {
  return useAuthMutation(deleteCommissionsForFilename, options, false);
};
