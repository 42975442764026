/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DividendRecordAttributes
 */
export interface DividendRecordAttributes {
    /**
     * 
     * @type {number}
     * @memberof DividendRecordAttributes
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DividendRecordAttributes
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DividendRecordAttributes
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DividendRecordAttributes
     */
    'ex_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DividendRecordAttributes
     */
    'literal_type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DividendRecordAttributes
     */
    'is_special'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DividendRecordAttributes
     */
    'payment_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DividendRecordAttributes
     */
    'period'?: DividendRecordAttributesPeriodEnum | null;
}

export const DividendRecordAttributesPeriodEnum = {
    YEARLY: 'YEARLY',
    BIANNUAL: 'BIANNUAL',
    THREE_TIMES_PER_YEAR: 'THREE_TIMES_PER_YEAR',
    QUARTERLY: 'QUARTERLY',
    MONTHLY: 'MONTHLY',
    SPECIAL: 'SPECIAL'
} as const;

export type DividendRecordAttributesPeriodEnum = typeof DividendRecordAttributesPeriodEnum[keyof typeof DividendRecordAttributesPeriodEnum];


