import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setDateAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "dividendValues"> => {
  if (event.type !== "INIT_FROM_DIVIDEND")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    dividendValues: {
      ...context.dividendValues,
      date: event.date,
    },
  };
};
