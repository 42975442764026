import { parseISO, startOfYear } from "date-fns";
import { DateValuePair } from "@api";
import { useAccountBalances } from "@api/v1/hooks/useAccountBalances";

const calculateIncreaseDecrease = (startValue: number, endValue: number) => {
  if (!endValue || !startValue) return 0;
  if (startValue < 0 && endValue > 0) return 0;
  return endValue / startValue - 1;
};

const balanceAtStartOfYear = (dateValuePairs?: DateValuePair[]) => {
  try {
    if (!dateValuePairs) return 0;

    const start = startOfYear(new Date());
    const match = dateValuePairs
      .slice()
      .reverse()
      .find((dvp) => {
        return parseISO(dvp.date) < start;
      });
    return match ? match.value : 0;
  } catch (err) {
    return 0;
  }
};

export const useCashBalance = (accountIds: number[], startDate?: Date) => {
  const { data, ...rest } = useAccountBalances(
    { accountIds, from: startDate },
    {
      enabled: accountIds && accountIds.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  const endBalance =
    data?.dateValuePairs[data?.dateValuePairs.length - 1]?.value;
  const startBalance = balanceAtStartOfYear(data?.dateValuePairs);

  const changeYTD =
    endBalance && startBalance
      ? calculateIncreaseDecrease(startBalance, endBalance)
      : 0;

  return {
    changeYTD,
    balance: endBalance,
    chartData: data?.dateValuePairs,
    ...rest,
  };
};
