export type BankOption = {
  name: string;
  bic?: string;
  iconUrl?: string;
};

export type WhitelistBank = {
  search: string;
  filter?: (bank: any) => boolean;
  update: "automatic" | "manual";
  img?: string;
  bic?: string;
  title: string;
};

export type BlackListBank = string;

export const banksForSearchField: BankOption[] = [
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/BDWBDEMM001_square.png",
    name: "Scalable Capital",
    bic: "BDWBDEMM001",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/TREBDED1XXX_square.png",
    name: "Trade Republic",
    bic: "TREBDED1XXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/COMDIRECT_square.png",
    name: "Comdirect",
    bic: "COBADEHDXXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/BYLADEM1001_square.png",
    name: "DKB",
    bic: "BYLADEM1001",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/INGDDEFFXXX_square.png",
    name: "ING",
    bic: "INGDDEFFXXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/CSDBDE71XXX_square.png",
    name: "Consorsbank",
    bic: "CSDBDE71XXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/FLATEX_square.png",
    name: "Flatex",
    bic: "BIWBDE33XXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/BDWBDEMM003_square.png",
    name: "Smartbroker Plus",
    bic: "BDWBDEMM003",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/BDWBDEMM002_square.png",
    name: "Finanzen.net ZERO",
    bic: "BDWBDEMM002",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/DEFFDEFFXXX_square.png",
    name: "C24 Bank",
    bic: "DEFFDEFFXXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/PARQDEB1XXX_square.png",
    name: "Parqet",
    bic: "PARQDEB1XXX",
  },
  {
    iconUrl: "https://rentablo.de/assets/bankLogos/SOBKDEB2XXX_square.png",
    name: "Bison",
    bic: "SOBKDEB2XXX",
  },
];

export const favouriteBanks: WhitelistBank[] = [
  {
    search: "BDWBDEMM001",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM001_square.png",
    title: "Scalable Capital",
    bic: "BDWBDEMM001",
    update: "manual",
  },
  {
    search: "TREBDED1XXX",
    img: "https://rentablo.de/assets/bankLogos/TREBDED1XXX_square.png",
    title: "Trade Republic",
    bic: "TREBDED1XXX",
    update: "manual",
  },
  {
    search: "comdirect",
    img: "https://rentablo.de/assets/bankLogos/COMDIRECT_square.png",
    title: "Comdirect",
    bic: "COBADEHDXXX",
    update: "automatic",
  },
  {
    search: "BYLADEM1001",
    img: "https://rentablo.de/assets/bankLogos/BYLADEM1001_square.png",
    title: "DKB",
    bic: "BYLADEM1001",
    update: "automatic",
  },
  {
    search: "INGDDEFFXXX",
    img: "https://rentablo.de/assets/bankLogos/INGDDEFFXXX_square.png",
    title: "ING",
    bic: "INGDDEFFXXX",
    update: "automatic",
  },
  {
    search: "CSDBDE71XXX",
    img: "https://rentablo.de/assets/bankLogos/CSDBDE71XXX_square.png",
    title: "Consorsbank",
    bic: "CSDBDE71XXX",
    update: "automatic",
  },
  {
    search: "FlatexDEGIRO",
    filter: (bank) => bank.name === "flatex",
    img: "https://rentablo.de/assets/bankLogos/FLATEX_square.png",
    title: "Flatex",
    bic: "BIWBDE33XXX",
    update: "automatic",
  },
  {
    search: "BDWBDEMM003",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM003_square.png",
    title: "Smartbroker Plus",
    bic: "BDWBDEMM003",
    update: "manual",
  },
  {
    search: "BDWBDEMM002",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM002_square.png",
    title: "Finanzen.net ZERO",
    bic: "BDWBDEMM002",
    update: "manual",
  },
  {
    search: "DEFFDEFFXXX",
    img: "https://rentablo.de/assets/bankLogos/DEFFDEFFXXX_square.png",
    title: "C24 Bank",
    bic: "DEFFDEFFXXX",
    update: "automatic",
  },
  {
    search: "PARQDEB1XXX",
    img: "https://rentablo.de/assets/bankLogos/PARQDEB1XXX_square.png",
    title: "Parqet",
    bic: "PARQDEB1XXX",
    update: "manual",
  },
  {
    search: "SOBKDEB2XXX",
    img: "https://rentablo.de/assets/bankLogos/SOBKDEB2XXX_square.png",
    title: "Bison",
    bic: "SOBKDEB2XXX",
    update: "manual",
  },
];

export const notSoFavouriteBanks: WhitelistBank[] = [
  {
    search: "HELADEF1822",
    img: "https://rentablo.de/assets/bankLogos/SPARKASSE_square.png",
    title: "1822direkt",
    update: "automatic",
  },
  {
    search: "ADVZLULLXXX",
    img: "https://rentablo.de/assets/bankLogos/ADVZLULLXXX_square.png",
    title: "Advanzia Bank",
    update: "manual",
  },
  {
    search: "BDWBDEMMXXX",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMMXXX_square.png",
    title: "Baader Bank",
    update: "automatic",
  },
  {
    search: "BARCDEHAXXX",
    img: "https://rentablo.de/assets/bankLogos/BARCDEHAXXX_square.png",
    title: "Barclays Bank",
    update: "manual",
  },
  {
    search: "BSHHDE61XXX",
    img: "https://rentablo.de/assets/bankLogos/BSHHDE61XXX.png",
    title: "Bausparkasse Schwäbisch Hall",
    update: "manual",
  },
  {
    search: "GENODE61BBB",
    img: "https://rentablo.de/assets/bankLogos/GENODE61BBB_square.png",
    title: "BBBank",
    update: "automatic",
  },
  {
    search: "LHVBEE22XXX",
    img: "https://rentablo.de/assets/bankLogos/LHVBEE22XXX_square.png",
    title: "Bitpanda",
    update: "manual",
  },
  {
    search: "DEFFDEFFXXX",
    img: "https://rentablo.de/assets/bankLogos/DEFFDEFFXXX_square.png",
    title: "C24 Bank",
    update: "automatic",
  },
  {
    search: "comdirect",
    img: "https://rentablo.de/assets/bankLogos/COMDIRECT_square.png",
    title: "Comdirect",
    update: "automatic",
  },
  {
    search: "COBADEFFXXX",
    img: "https://rentablo.de/assets/bankLogos/COMMERZBANK_square.png",
    title: "Commerzbank",
    update: "manual",
  },
  {
    search: "CSDBDE71XXX",
    img: "https://rentablo.de/assets/bankLogos/CSDBDE71XXX_square.png",
    title: "Consorsbank",
    update: "automatic",
  },
  {
    search: "DGZFDEFFBER",
    img: "https://rentablo.de/assets/bankLogos/DGZFDEFFBER_square.png",
    title: "Deka Bank",
    update: "manual",
  },
  {
    search: "BYLADEM1001",
    img: "https://rentablo.de/assets/bankLogos/BYLADEM1001_square.png",
    title: "DKB",
    update: "automatic",
  },
  {
    search: "DWS",
    img: "https://rentablo.de/assets/bankLogos/DEUTDE5DCON_square.png",
    title: "DWS Investments",
    update: "manual",
  },
  {
    search: "GENODEF1ETK",
    img: "https://rentablo.de/assets/bankLogos/GENODEF1ETK_square.png",
    title: "EthikBank",
    update: "automatic",
  },
  {
    search: "BDWBDEMM002",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM002_square.png",
    title: "Finanzen.net ZERO",
    update: "manual",
  },
  {
    search: "BIIWATWWXXX",
    img: "https://rentablo.de/assets/bankLogos/FLATEX_square.png",
    title: "Flatex AT",
    update: "manual",
  },
  {
    search: "FlatexDEGIRO",
    filter: (bank) => bank.name === "flatex",
    img: "https://rentablo.de/assets/bankLogos/FLATEX_square.png",
    title: "Flatex",
    update: "automatic",
  },
  {
    search: "GENODEM1GLS",
    img: "https://rentablo.de/assets/bankLogos/GENODEM1GLS_square.png",
    title: "GLS Bank",
    update: "automatic",
  },
  {
    search: "INGDDEFFXXX",
    img: "https://rentablo.de/assets/bankLogos/INGDDEFFXXX_square.png",
    title: "ING",
    update: "automatic",
  },
  {
    search: "NTSBDEB1XXX",
    img: "https://rentablo.de/assets/bankLogos/NTSBDEB1XXX_square.png",
    title: "N26 Bank",
    update: "manual",
  },
  {
    search: "norisbank",
    img: "https://rentablo.de/assets/bankLogos/NORSDE51XXX_square.png",
    title: "norisbank",
    update: "automatic",
  },
  {
    search: "Postbank",
    img: "https://rentablo.de/assets/bankLogos/POSTBANK_square.png",
    title: "Postbank",
    update: "automatic",
  },
  {
    search: "S Broker Wiesbaden",
    img: "https://rentablo.de/assets/bankLogos/SBROKER_square.png",
    title: "S Broker",
    update: "manual",
  },
  {
    search: "BDWBDEMM001",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM001_square.png",
    title: "Scalable Capital",
    update: "manual",
  },
  {
    search: "BDWBDEMM003",
    img: "https://rentablo.de/assets/bankLogos/BDWBDEMM003_square.png",
    title: "Smartbroker Plus",
    update: "manual",
  },
  {
    search: "CMCIDEDDXXX",
    img: "https://rentablo.de/assets/bankLogos/CMCIDEDDXXX_square.png",
    title: "Targobank",
    update: "automatic",
  },
  {
    search: "TREBDED1XXX",
    img: "https://rentablo.de/assets/bankLogos/TREBDED1XXX_square.png",
    title: "Trade Republic",
    update: "manual",
  },
  {
    search: "HYPOVEREINSBANK",
    img: "https://rentablo.de/assets/bankLogos/HYPOVEREINSBANK_square.png",
    title: "HypoVereinsbank",
    update: "automatic",
  },
];

export const C24Bank: WhitelistBank = {
  search: "DEFFDEFFXXX",
  img: "https://rentablo.de/assets/bankLogos/DEFFDEFFXXX_square.png",
  title: "C24 Bank",
  update: "automatic",
};

export const FinanzenNetZero: WhitelistBank = {
  search: "BDWBDEMM002",
  img: "https://rentablo.de/assets/bankLogos/BDWBDEMM002_square.png",
  title: "Finanzen.net ZERO",
  update: "manual",
};

export const blacklistBanks: BlackListBank[] = [
  "SCBLDEFXXXX", // American Express
  "BIWBDE33XXX (WBX)", // Whitebox
];
