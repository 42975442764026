import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { InvestmentTypeEnum } from "@api";
import { StepContainer } from "../StepContainer";

const Container = styled.div`
  width: 100%;
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface Props {
  handleNext: (investmentType: InvestmentTypeEnum) => void;
  handlePrevious?: () => void;
  defaultValue?: InvestmentTypeEnum;
}

export const InvestmentTypeStep = ({
  handleNext,
  handlePrevious,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<InvestmentTypeEnum | undefined>(
    defaultValue
  );
  const handleClick = (investmentType: InvestmentTypeEnum | "92_other") => {
    setValue(investmentType === "92_other" ? "71_massets" : investmentType);
    handleNext(investmentType === "92_other" ? "71_massets" : investmentType);
  };

  const types: (InvestmentTypeEnum | "92_other")[] = [
    "11_stock",
    "22_etf",
    "21_fund",
    "31_bond",
    "51_certos",
    "61_pmetals",
    "81_fcurr",
    "71_massets",
    "91_managed",
    "92_other",
  ];

  return (
    <StepContainer
      testid="investment-type-step"
      title={t("transactionWizard.investmentTypeStep.title")}
      onPrevious={handlePrevious}
    >
      <Container>
        {types.map((investmentType) => {
          return (
            <div key={investmentType}>
              <Button
                variant="contained"
                data-testid={`${investmentType}-button`}
                size="small"
                color={value === investmentType ? "primary" : "grey"}
                onClick={() => handleClick(investmentType)}
              >
                {t(`investmentTypes.${investmentType}`)}
              </Button>
            </div>
          );
        })}
      </Container>
    </StepContainer>
  );
};
