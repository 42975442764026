import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { toCurrency } from "@helpers";

export const Container = styled.div`
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(2)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.subtle};
  }
`;

export interface Props {
  name: string;
  isin?: string;
  typeName: string;
  stockExchangeName: string;
  quote?: number;
  currency?: string;
  handleClick: () => void;
  testid?: string;
}

export const Symbol = ({
  name,
  isin,
  typeName,
  stockExchangeName,
  quote,
  currency,
  handleClick,
  testid,
}: Props) => {
  return (
    <Container
      onClick={() => handleClick()}
      data-testid={testid ? testid : "symbol"}
    >
      <Typography variant="body2">
        {name.toUpperCase()}
        {isin ? ` (${isin})` : ""}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <b>{typeName}</b> - {stockExchangeName}
        {quote ? ` ${toCurrency(quote, currency || "EUR")}` : ""}
      </Typography>
    </Container>
  );
};
