import React from "react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as ChevronDown } from "@icons/chevron-down.svg";
import { NoDataStub } from "@components/Chart/NoDataStub";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  backgroundColor: theme.palette.tertiary.light,
}));

export const DividendChartBar = (props: {
  valueFormatted: string;
  category: string;
  value: number;
  reversed?: boolean;
  shouldBeRounded?: boolean;
}) => {
  const borderRadius = props.shouldBeRounded ? "5px" : "0px 5px 5px 0px";

  return (
    <Box
      sx={{
        transform: props.reversed ? "rotate(180deg)" : "rotate(0deg)",
        transformOrigin: "center",
        width: props.shouldBeRounded ? "100%" : "50%",
      }}
    >
      <BorderLinearProgress
        variant="determinate"
        color={props.reversed ? "error" : "success"}
        value={props.value < 100 ? Math.abs(props.value) : 100}
        sx={{
          borderRadius,
          "& .MuiLinearProgress-bar": {
            borderRadius,
          },
        }}
      />
    </Box>
  );
};

interface Props {
  chartData: {
    category: string;
    valueFormatted: string;
    value: number;
    percentage: number;
  }[];
  component: React.FC<{
    valueFormatted: string;
    category: string;
    value: number;
    reversed?: boolean;
    shouldBeRounded?: boolean;
  }>;
  type: "dividends" | "historicQuotes";
}

export const LinearProgressChart = ({ chartData, type, component }: Props) => {
  const [showMore, setShowMore] = React.useState(false);
  const shouldShowMore = chartData.length > 6;
  const Component = component;
  const shouldDisplayNegative = chartData.some((item) => item.value < 0);
  const shouldDisplayPositive = chartData.some((item) => item.value > 0);

  if (!chartData.length) {
    return <NoDataStub />;
  }

  return (
    <Box
      sx={{
        "& > div:not(:last-child)": {
          borderBottom: (theme) =>
            `1px solid ${theme.palette.border.secondary}`,
        },
      }}
    >
      {chartData.slice(0, !showMore ? 5 : chartData.length).map((item) => {
        const isNegative = item.value < 0;

        return (
          <Box px={2.5} pt={2.5} pb={1.5} key={item.category}>
            <Typography variant="body2" color="text.primary">
              {item.category}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                display="flex"
                alignItems="center"
                key={item.category}
                width="100%"
                mr={1}
                pr={2}
                gap={0.25}
              >
                {shouldDisplayNegative && (
                  <Component
                    valueFormatted={item.valueFormatted}
                    category={item.category}
                    value={isNegative ? item.percentage : 0}
                    reversed
                    shouldBeRounded={!shouldDisplayPositive}
                  />
                )}
                {shouldDisplayPositive && (
                  <Component
                    valueFormatted={item.valueFormatted}
                    category={item.category}
                    value={isNegative ? 0 : item.percentage}
                    shouldBeRounded={!shouldDisplayNegative}
                  />
                )}
              </Box>
              <Box minWidth="60px">
                <Typography
                  variant="body2"
                  sx={{ lineHeight: "20px" }}
                  color={
                    type === "dividends"
                      ? "textPrimary"
                      : isNegative
                      ? "text.negative"
                      : "text.positive"
                  }
                >
                  {item.valueFormatted}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
      {shouldShowMore && !showMore && (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          endIcon={<ChevronDown />}
          onClick={() => setShowMore(true)}
          size="small"
          sx={{
            marginTop: (theme) => theme.spacing(5),
          }}
        >
          Mehr anzeigen
        </Button>
      )}
    </Box>
  );
};
