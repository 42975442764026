import { UseQueryOptions } from "react-query";
import { StripeApiStripeCheckoutSessionRequest } from "@generated/apiv1";

import { buildStripeApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const stripeCheckoutSession = async (
  token: string | undefined,
  params: StripeApiStripeCheckoutSessionRequest
) => {
  const apiInstance = buildStripeApi(token);
  const { data } = await apiInstance.stripeCheckoutSession(params);
  return data;
};

export const useStripeCheckoutSession = (
  params: StripeApiStripeCheckoutSessionRequest,
  options?: UseQueryOptions<object, AxiosApiError, object>
) => {
  return useAuthQuery(
    [CACHE_KEYS.STRIPE_STRIPE_CHECKOUT_SESSION, params],
    ({ token }) => stripeCheckoutSession(token, params),
    options
  );
};
