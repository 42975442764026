import { UseQueryOptions } from "react-query";
import { getApplicationProperties } from "@api/v1/methods";
import {
  ListAndSearchAllApplicationProperties,
  ApplicationPropertyList,
} from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_APPLICATION_PROPERTIES } from "../../cacheKeys";

export const useGetApplicationProperties = (
  params: ListAndSearchAllApplicationProperties,
  options?: UseQueryOptions<
    ApplicationPropertyList,
    AxiosApiError,
    ApplicationPropertyList
  >
) =>
  useAuthQuery(
    [GET_APPLICATION_PROPERTIES, params],
    ({ token }) => getApplicationProperties(token, params),
    options
  );
