import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setCustomTaxAmountAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "customTaxAmount"> => {
  if (event.type !== "TAX_ENTERED") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    customTaxAmount: event.customTaxAmount,
  };
};
