import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import truncate from "lodash/truncate";
import { DepotSynchronizationLog, Account } from "@api";
import { PageLayout } from "@components";
import { SynchronizationLogList } from "@features/fileDrop/components/SynchronisationLog/SynchronizationLogList";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 800px;
`;

export interface Props {
  account?: Account;
  depotSynchronizationLog: DepotSynchronizationLog;
  onGoToDepot: () => void;
}

export const ImportSuccess = ({
  account,
  depotSynchronizationLog,
  onGoToDepot,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const breadcrumbs = [
    { to: "/", text: t("breadcrumbs.home") },
    ...(account?.id
      ? [
          { to: "/depots", text: t("breadcrumbs.depots") },
          {
            to: `/depots/${account?.id}`,
            text: truncate(account?.name),
          },
        ]
      : [{ to: "/investments", text: t("breadcrumbs.investments") }]),
    { text: t("transactionUpload.title") },
  ];

  return (
    <>
      <PageLayout
        data-testid="file-drop-handler-layout"
        title={t("transactionUpload.success.importSuccess")}
        breadcrumbs={breadcrumbs}
      >
        <Container data-testid="file-import-success">
          <Box width="100%" maxWidth={700} textAlign="left">
            <Typography variant="h6">
              {t("transactionUpload.success.importDetails")}:
            </Typography>

            <SynchronizationLogList
              depotSynchronizationLogs={[depotSynchronizationLog]}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant={theme.bankConnection?.button?.variant || "contained"}
              onClick={() => onGoToDepot()}
            >
              {account?.id
                ? t("transactionUpload.success.toDepot")
                : t("transactionUpload.success.toInvestments")}
            </Button>
          </Box>
        </Container>
      </PageLayout>
    </>
  );
};
