import { UseQueryOptions } from "react-query";
import {
  DividendMasterDataRecordsApiGetDividendIdRequest,
  DividendMasterDataRecord,
} from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getDividendId = async (
  token: string | undefined,
  params: DividendMasterDataRecordsApiGetDividendIdRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.getDividendId(params);
  return data;
};

export const useGetDividendId = (
  params: DividendMasterDataRecordsApiGetDividendIdRequest,
  options?: UseQueryOptions<
    DividendMasterDataRecord,
    AxiosApiError,
    DividendMasterDataRecord
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDMASTERDATARECORDS_GET_DIVIDEND_ID, params],
    ({ token }) => getDividendId(token, params),
    options
  );
};
