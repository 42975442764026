import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import truncate from "lodash/truncate";
import { Account, AccountList } from "@api";
import { useAccounts } from "@api/v1/hooks/useAccounts";
import { PageLayout } from "@components";
import { FileDropHandler } from "@features/fileDrop/components/FileDropHandler";
import { AccountSelector } from "./components/AccountSelector";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

const FileDropContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const FileDropHandlerWithRoutingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useParams<{ accountId: string }>();
  const [account, setAccount] = useState<Account | null>(null);

  const accountIdNumber = accountId ? parseInt(accountId) : undefined;
  const { data: accountList, isLoading } = useAccounts(
    { type: "01_depot" },
    {
      enabled: true,
      onSuccess: (accountList: AccountList) => {
        const selected = accountList.accounts.find(
          (acc) => acc.id === accountIdNumber
        );
        if (selected) handleSelect(selected);
      },
    }
  );

  const handleSelect = (account: Account) => {
    setAccount(account);
    if (accountIdNumber) {
      navigate(`/depots/${account.id}/file-drop`, { replace: true });
    }
  };

  const breadcrumbs = [
    { to: "/", text: t("breadcrumbs.home") },
    ...(accountIdNumber
      ? [
          { to: "/depots", text: t("breadcrumbs.depots") },
          {
            to: `/depots/${accountIdNumber}`,
            text: truncate(account?.name),
          },
        ]
      : [{ to: "/investments", text: t("breadcrumbs.investments") }]),
    { text: t("transactionUpload.title") },
  ];

  return (
    <PageLayout
      data-testid="file-drop-handler-layout"
      title={t("transactionUpload.title")}
      breadcrumbs={breadcrumbs}
    >
      <Container>
        <Helmet>
          <title>{t("transactionUpload.title")}</title>
        </Helmet>
        <FileDropContainer>
          <AccountSelector
            loading={isLoading}
            accounts={accountList?.accounts || []}
            selectedAccount={account}
            placeholder={t("transactionUpload.placeholder")}
            onSelect={handleSelect}
          />
        </FileDropContainer>
        {account && (
          <FileDropContainer>
            <FileDropHandler accountId={account.id} />
          </FileDropContainer>
        )}
      </Container>
    </PageLayout>
  );
};
