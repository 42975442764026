import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendHistoryRequest,
  DividendHistoryTree,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendHistory = async (
  token: string | undefined,
  params?: DividendsApiDividendHistoryRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendHistory(params);
  return data;
};

export const useDividendHistory = (
  params?: DividendsApiDividendHistoryRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_HISTORY, params],
    ({ token }) => dividendHistory(token, params),
    options
  );
};
