import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Grid,
  Box,
  Typography,
  Switch,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as Calendar } from "@icons/calendar.svg";
import { isDateValid } from "@helpers";
import { Banner } from "@components";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { useHelpers } from "../hooks/useHelpers";
import { SplitValues } from "../stateMachine/types/splitValues";

import { InputFieldWithRevert } from "../components/NumberInputFieldWithRevert";

export interface Props {
  handleNext: (splitValues: SplitValues) => void;
  context: TransactionWizardContext;
}

export const RatioAndDateStep = ({ handleNext, context }: Props) => {
  const { t } = useTranslation();
  const { splitValues = {}, validationError } = context;
  const { toString, parseOrUndefined } = useHelpers();

  const [values, setValues] = useState<SplitValues>(splitValues);
  const [ratio, setRatio] = useState<string>(toString(splitValues.ratio));
  const [date, setDate] = useState<Date>(splitValues.date || new Date());
  const [isReverse, setIsReverse] = useState<boolean>(
    splitValues.isReverse ? splitValues.isReverse : false
  );

  const onNextDisabled =
    !isDateValid(values.date) ||
    !values.ratio ||
    values.ratio < 1 ||
    values.isReverse == null;

  const handleRatioChange = (value: string) => {
    const ratio = parseOrUndefined(value);
    setRatio(toString(ratio, value));
    setValues((current: SplitValues) => {
      return { ...current, ratio };
    });
  };

  const handleDateChange = (date?: Date | null) => {
    if (!date) return;
    setDate(date);
    setValues((current: SplitValues) => {
      return { ...current, date };
    });
  };

  const handleIsReverseChange = () => {
    setIsReverse((current) => {
      setValues((values: SplitValues) => {
        return { ...values, isReverse: !current };
      });
      return !current;
    });
  };

  return (
    <StepContainer
      handleNext={() => handleNext(values)}
      onNextDisabled={onNextDisabled}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        {!!validationError && (
          <Banner
            type="error"
            data-testid="booking-validation-error"
            text={validationError}
          />
        )}

        <Grid container rowSpacing={6} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              data-testid="date-picker"
              disableFuture
              openTo="year"
              mask="__.__.____"
              inputFormat="dd.MM.yyyy"
              label={t("transactionWizardModal.split.ratioAndDate.date")}
              views={["year", "month", "day"]}
              PopperProps={{
                disablePortal: true,
              }}
              value={date}
              components={{
                OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "date-picker-input",
                  }}
                />
              )}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputFieldWithRevert
              fieldLabel={t("transactionWizardModal.split.ratioAndDate.ratio")}
              id="ratio"
              value={ratio || ""}
              handleChange={handleRatioChange}
              // @ts-ignore
              startAdornment={
                isReverse ? undefined : (
                  <Typography variant="body1" color="textSecondary">
                    1:
                  </Typography>
                )
              }
              // @ts-ignore
              endAdornment={
                isReverse ? (
                  <Typography variant="body1" color="textSecondary">
                    :1
                  </Typography>
                ) : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel sx={{ width: "200px" }}>
                {t("transactionWizardModal.split.ratioAndDate.isReverse")}
              </InputLabel>
              <Switch
                checked={isReverse}
                size="medium"
                onChange={handleIsReverseChange}
                color="primary"
                data-testid="is-reverse-input"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </StepContainer>
  );
};
