import { useCallback } from "react";
import { useToken } from "@api/auth";

export const useLogout = () => {
  const { logout: tokenLogout } = useToken({ enabled: false });
  const logout = useCallback(() => {
    tokenLogout();
    window.location.href = process.env.REACT_APP_ROOT_URL!;
  }, [tokenLogout]);

  return {
    logout,
  };
};
