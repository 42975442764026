import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { useDeleteOtp } from "@generated/user/hooks";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { RHFTextField } from "@components";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  confirmButton: "settingsPage.security.deleteMFA.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
  confirmButtonColor: "error" as const,
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  methodName: string;
}

export const DeleteMFAModal = ({ isOpen, onClose, methodName }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    mutate: deleteUser,
    isLoading,
    isError,
  } = useDeleteOtp({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.USERS_MYSELF],
      });
      enqueueSnackbar(t("settingsPage.security.deleteMFA.success"), {
        autoHideDuration: 3000,
        variant: "success",
      });
      onClose();
    },
  });

  const schema = Yup.object().shape({
    otpCode: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, watch } = useForm<{
    otpCode: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      otpCode: "",
    },
  });

  const handleConfirm = () => {
    const { otpCode } = getValues();
    deleteUser({ otpCode });
  };

  const isConfirmBtnDisabled = !watch("otpCode");

  return (
    <SettingsModalWrapper
      {...modalData}
      title={t("settingsPage.security.deleteMFA.title", {
        name: methodName,
      })}
      mobileHeight="auto"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(handleConfirm)}
      isConfirmButtonLoading={isLoading}
      isConfirmBtnDisabled={isConfirmBtnDisabled}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
        />
      )}
      <RHFTextField
        name="otpCode"
        label={t("settingsPage.security.deleteMFA.otpCode")}
        control={control}
        fullWidth
        type="text"
      />
    </SettingsModalWrapper>
  );
};
