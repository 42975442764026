import { UseMutationOptions } from "react-query";
import { SubscriptionsApiCancelAffiliateTrackingRequest } from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const cancelAffiliateTracking = async (
  token: string | undefined,
  params: SubscriptionsApiCancelAffiliateTrackingRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.cancelAffiliateTracking(params);
  return data;
};

export const useCancelAffiliateTracking = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    SubscriptionsApiCancelAffiliateTrackingRequest
  >
) => {
  return useAuthMutation(cancelAffiliateTracking, options, false);
};
