import {
  FormControlLabel,
  Link,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@components/LoadingButton";
import { CurrentDepot } from "./SelectContraAccount";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const DepotContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const RadioGroup = styled(MuiRadioGroup)`
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export interface Option {
  id: number;
  name?: string;
  iban?: string;
  bankName?: string;
}

export interface SelectContraAccountContainerProps {
  currentDepot: CurrentDepot;
  options: Option[];
  defaultId?: number;
  showNoAccountOption?: boolean;
  onSubmit: (id?: number) => void;
  isLoading?: boolean;
  showAccountsFromAllBanks?: boolean;
  onShowAccountsClicked?: () => void;
}

export const SelectContraAccountContainer = ({
  currentDepot,
  options,
  defaultId,
  showNoAccountOption = false,
  onSubmit,
  showAccountsFromAllBanks = false,
  isLoading = false,
  onShowAccountsClicked,
}: SelectContraAccountContainerProps) => {
  const theme = useTheme();
  const [value, setValue] = useState(defaultId?.toString() || null);
  const { t } = useTranslation();

  const handleClick = () => {
    if (value === "no-value") {
      return onSubmit(undefined);
    }
    if (value) {
      return onSubmit(parseInt(value, 10));
    }
  };

  return (
    <Container data-testid="select-contra-account">
      <Typography variant="h3">{t("selectContraAccount.title")}</Typography>

      <DepotContainer>
        <Typography color="textSecondary">{currentDepot.name}</Typography>
        <Typography>
          <strong>{currentDepot.iban}</strong>
        </Typography>
      </DepotContainer>
      <RadioGroup
        aria-label="quiz"
        name="quiz"
        value={value}
        onChange={(_, val) => {
          setValue(val);
        }}
      >
        {options.map(({ id, name, bankName, iban }) => (
          <FormControlLabel
            key={id}
            value={id.toString()}
            control={<Radio data-testid={`radio-button-${id}`} />}
            label={
              <>
                {(showAccountsFromAllBanks ||
                  currentDepot?.bankConnectionType !== "FIN_API") && (
                  <Typography color="textSecondary" variant="body2">
                    {bankName}
                  </Typography>
                )}
                <Typography variant="body2">
                  {id} {name}
                </Typography>
                <Typography variant="body2">{iban}</Typography>
              </>
            }
          />
        ))}
        {showNoAccountOption && (
          <FormControlLabel
            key="no-account"
            value={"no-value"}
            control={<Radio data-testid={`radio-button-no-account`} />}
            label={
              <>
                <Typography variant="body2">
                  {t("selectContraAccount.noAccount")}
                </Typography>
              </>
            }
          />
        )}
      </RadioGroup>
      {value === "no-value" && (
        <Alert data-testid="no-account-warning" severity="warning">
          {t("selectContraAccount.noAccountWarning")}
        </Alert>
      )}
      {onShowAccountsClicked && (
        <Link
          data-testid="show-accounts"
          underline="always"
          color="textPrimary"
          variant="subtitle2"
          onClick={() => onShowAccountsClicked()}
        >
          {showAccountsFromAllBanks
            ? t("selectContraAccount.showAccountsForSameBank")
            : t("selectContraAccount.showAllAccounts")}
        </Link>
      )}

      <LoadingButton
        variant={theme.bankConnection?.button?.variant || "contained"}
        disabled={!value}
        isLoading={isLoading}
        onClick={handleClick}
        endIcon={<ArrowForwardIosIcon />}
        data-testid="submit-button"
      >
        {t("selectContraAccount.nextButton")}
      </LoadingButton>
    </Container>
  );
};
