import { every } from "lodash";
import { ComparisonChartData } from "../ComparisonBaseChart";

export const OFFSET = 1.05;

const normalizeValues = (values: number[]) => {
  try {
    const sum = values.reduce((acc, v) => acc + v);
    return values.map((v) => v / sum);
  } catch (e) {
    throw e;
  }
};

const calculateDomain = (values: number[], ratio: number) => {
  const min1 = Math.min(...values);
  const max1 = Math.max(...values);

  if (Math.abs(min1) > max1) {
    return [min1 * OFFSET, Math.abs(min1) * ratio * OFFSET];
  }
  return [(-max1 * OFFSET) / ratio, max1 * OFFSET];
};

export const calculateDomains = (
  data: ComparisonChartData[],
  key1: string,
  key2?: string
) => {
  if (!key2) {
    return { [key1]: [0, "auto"] };
  }
  const valuesSet1: number[] = data
    .map((values) => (values as any)[key1]!)
    .filter((v) => v != null);

  const valuesSet2: number[] = data
    .map((values) => (values as any)[key2]!)
    .filter((v) => v != null);

  if (valuesSet1.length === 0 && valuesSet2.length === 0) {
    return { [key2]: [0, "auto"], [key1]: [0, "auto"] };
  }

  if (valuesSet1.length === 0) {
    return { [key2]: [0, "auto"] };
  }
  if (valuesSet2.length === 0) {
    return { [key1]: [0, "auto"] };
  }

  if (every([...valuesSet1, ...valuesSet2], (v) => v >= 0)) {
    return { [key1]: [0, "auto"], [key2]: [0, "auto"] };
  }

  if (every([...valuesSet1, ...valuesSet2], (v) => v <= 0)) {
    return { [key1]: [0, "auto"], [key2]: [0, "auto"] };
  }

  const normalizedValues1 = normalizeValues(valuesSet1);
  const normalizedValues2 = normalizeValues(valuesSet2);

  const min = Math.min(...normalizedValues1, ...normalizedValues2);
  const max = Math.max(...normalizedValues1, ...normalizedValues2);

  const ratio = Math.abs(max / min);

  return {
    [key1]: calculateDomain(valuesSet1, ratio),
    [key2]: calculateDomain(valuesSet2, ratio),
  };
};
