import React from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { DividendCards } from "@features/dividendPlanner/OverViewTab/DividendCards";
import { SharedBanner } from "@features/sharedPortfolio/SharedBanner";
import { MainSection } from "@features/dividendPlanner/OverViewTab/MainSection/MainSection";

type Props = {
  selectedAccounts: number[];
};

export const OverviewTab = ({ selectedAccounts }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={isMobile ? 4 : 6}
      gap={6}
      maxWidth={1400}
      flex={1}
      width="100%"
    >
      <SharedBanner />
      <DividendCards accountIds={selectedAccounts} />
      <MainSection />
    </Box>
  );
};
