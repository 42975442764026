import { useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { Account } from "@generated/apiv1";
import { Account as OldAccount } from "@api/v1";
import { SortProperties, useTableSort } from "@hooks";
import {
  getStoredSortParams,
  setStoredSortParams,
} from "@helpers/localStorage";
import { AccountWithSyncRequired } from "../../hooks/useAccountsWithSyncRequired";

export const useAccountsSortAndFilter = <T extends Account | OldAccount>(
  accounts: AccountWithSyncRequired<T>[],
  filter: string,
  defaultSort?: SortProperties
) => {
  const { sortField, sortDirection, sort } = useTableSort(defaultSort);
  const [performanceValues, setPerformanceValues] = useState<
    Record<number, number>
  >({});

  useEffect(() => {
    if (!sortDirection || !sortField) return;
    setStoredSortParams(sortField, sortDirection, "depotsList");
  }, [sortDirection, sortField]);

  useEffect(() => {
    const item = getStoredSortParams("depotsList");
    if (!item) return;
    const { sortDirection, sortField } = item;
    sort(sortField, sortDirection);
    // eslint-disable-next-line
  }, []);

  const sortedAccounts = useMemo(() => {
    const filteredAccounts = accounts.filter((account) => {
      return [account.name, account.bankName, account.bankCode].some((input) =>
        input?.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return orderBy(
      filteredAccounts,
      (account) => {
        const accountSortField =
          account[sortField as keyof (Account | OldAccount)];

        return sortField === "performance"
          ? performanceValues[account.id] || 0
          : typeof accountSortField === "string"
          ? accountSortField.toLowerCase()
          : accountSortField;
      },
      [sortDirection === "ASC" ? "asc" : "desc"]
    );
  }, [accounts, filter, performanceValues, sortDirection, sortField]);

  return {
    sort,
    sortField,
    sortDirection,
    sortedAccounts,
    setPerformanceValues,
  };
};
