/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AccountHolderReference } from './account-holder-reference';
// May contain unused imports in some cases
// @ts-ignore
import { Bank } from './bank';
// May contain unused imports in some cases
// @ts-ignore
import { CustomData } from './custom-data';
// May contain unused imports in some cases
// @ts-ignore
import { TimeDependentProperty } from './time-dependent-property';
// May contain unused imports in some cases
// @ts-ignore
import { UserReference } from './user-reference';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    'id'?: number;
    /**
     * 
     * @type {AccountHolderReference}
     * @memberof Subscription
     */
    'accountHolderReference'?: AccountHolderReference;
    /**
     * 
     * @type {UserReference}
     * @memberof Subscription
     */
    'userReference'?: UserReference;
    /**
     * The subscription type.
     * @type {string}
     * @memberof Subscription
     */
    'type'?: SubscriptionTypeEnum;
    /**
     * The type of onboarding being used; default is INTERNAL.
     * @type {string}
     * @memberof Subscription
     */
    'onboardingType': SubscriptionOnboardingTypeEnum;
    /**
     * The subscription start date; required for creation
     * @type {string}
     * @memberof Subscription
     */
    'start'?: string;
    /**
     * The subscription creation timestamp (non editable field)
     * @type {string}
     * @memberof Subscription
     */
    'creationTimestamp'?: string;
    /**
     * The subscription end date; not relevant for cashback subscriptions
     * @type {string}
     * @memberof Subscription
     */
    'end'?: string;
    /**
     * Is the subscription active? This is relevant for subscriptions the get extended;                                not for cashback.
     * @type {boolean}
     * @memberof Subscription
     */
    'isActive'?: boolean;
    /**
     * Is this the current subscription? (auto determined; non editable field)
     * @type {boolean}
     * @memberof Subscription
     */
    'isCurrent'?: boolean;
    /**
     * Has the subscription been cancelled?
     * @type {boolean}
     * @memberof Subscription
     */
    'isCancelled'?: boolean;
    /**
     * Is this a trial subscription?
     * @type {boolean}
     * @memberof Subscription
     */
    'isTrial'?: boolean;
    /**
     * The subscription status; will default to \'incomplete\' if not given at creation time.
     * @type {string}
     * @memberof Subscription
     */
    'status'?: SubscriptionStatusEnum;
    /**
     * The last update of the subscription status; may be null if the status was never changed
     * @type {string}
     * @memberof Subscription
     */
    'statusUpdatedAt'?: string;
    /**
     * An optional, custom tag useful for custom subscription processing
     * @type {string}
     * @memberof Subscription
     */
    'tag'?: string;
    /**
     * The last update of the subscription flag; may be null if the flag was never updated
     * @type {string}
     * @memberof Subscription
     */
    'tagUpdatedAt'?: string;
    /**
     * The last update of either the status or the flag; this is a derived property for                                convenient UX handling.
     * @type {string}
     * @memberof Subscription
     */
    'lastUpdate'?: string;
    /**
     * Date of the latest fee payment date (non editable field).
     * @type {string}
     * @memberof Subscription
     */
    'feePayedAt'?: string;
    /**
     * The subscription currency; currently statically set to \'EUR\'.
     * @type {string}
     * @memberof Subscription
     */
    'currency'?: string;
    /**
     * A reason, e.g. for cancellation. May be given by business or the customer.
     * @type {string}
     * @memberof Subscription
     */
    'reason'?: string;
    /**
     * A user comment (longtext).
     * @type {string}
     * @memberof Subscription
     */
    'comment'?: string;
    /**
     * The primary account or depot identifier. This value will be masked during the less                                secure update operation. Relevant for correct billing.
     * @type {string}
     * @memberof Subscription
     */
    'primaryAccountIdentifier'?: string;
    /**
     * The secondary account or depot identifier. This value will be masked during the less                                secure update operation. May be relevant for correct billing.
     * @type {string}
     * @memberof Subscription
     */
    'additionalAccountIdentifier'?: string;
    /**
     * 
     * @type {TimeDependentProperty}
     * @memberof Subscription
     */
    'regularFee'?: TimeDependentProperty;
    /**
     * 
     * @type {TimeDependentProperty}
     * @memberof Subscription
     */
    'oneTimeFee'?: TimeDependentProperty;
    /**
     * 
     * @type {TimeDependentProperty}
     * @memberof Subscription
     */
    'discount'?: TimeDependentProperty;
    /**
     * 
     * @type {Bank}
     * @memberof Subscription
     */
    'bank'?: Bank;
    /**
     * 
     * @type {CustomData}
     * @memberof Subscription
     */
    'customData'?: CustomData;
    /**
     * Json data required to restore the cashback wizard state.
     * @type {{ [key: string]: object; }}
     * @memberof Subscription
     */
    'wizardData'?: { [key: string]: object; };
}

export const SubscriptionTypeEnum = {
    _02_BASIC_FLATRATE: '02_basic_flatrate',
    _03_FUND_FLATRATE: '03_fund_flatrate'
} as const;

export type SubscriptionTypeEnum = typeof SubscriptionTypeEnum[keyof typeof SubscriptionTypeEnum];
export const SubscriptionOnboardingTypeEnum = {
    INTERNAL: 'internal',
    EXTERNAL: 'external'
} as const;

export type SubscriptionOnboardingTypeEnum = typeof SubscriptionOnboardingTypeEnum[keyof typeof SubscriptionOnboardingTypeEnum];
export const SubscriptionStatusEnum = {
    INCOMPLETE: 'incomplete',
    IN_PROGRESS: 'in_progress',
    INTERNAL_PROCESSING: 'internal_processing',
    PROCESSING_AT_BANK: 'processing_at_bank',
    PROCESSED: 'processed',
    CONTRACT_TERMINATED: 'contract_terminated',
    CANCELLED: 'cancelled'
} as const;

export type SubscriptionStatusEnum = typeof SubscriptionStatusEnum[keyof typeof SubscriptionStatusEnum];


