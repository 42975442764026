import {
  CompanyLogosApi,
  DividendsApi,
  QuotesApi,
} from "@generated/apiv3/quotes";

import { withToken } from "@api/auth/withToken";
import {
  buildAnonymousConfiguration,
  buildConfiguration,
} from "@api/v3/quotes/buildConfiguration";

export const buildCompanyLogosApiWithToken = withToken(
  async (token: string) => new CompanyLogosApi(buildConfiguration(token))
);
export const buildAnonymousCompanyLogosApi = () =>
  new CompanyLogosApi(buildAnonymousConfiguration());
export const buildCompanyLogosApi = (token?: string) =>
  new CompanyLogosApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildDividendsApiWithToken = withToken(
  async (token: string) => new DividendsApi(buildConfiguration(token))
);
export const buildAnonymousDividendsApi = () =>
  new DividendsApi(buildAnonymousConfiguration());
export const buildDividendsApi = (token?: string) =>
  new DividendsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildQuotesApiWithToken = withToken(
  async (token: string) => new QuotesApi(buildConfiguration(token))
);
export const buildAnonymousQuotesApi = () =>
  new QuotesApi(buildAnonymousConfiguration());
export const buildQuotesApi = (token?: string) =>
  new QuotesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );
