import React from "react";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QuoteV2, SearchSymbolV2TypeEnum, SymbolV2 } from "@generated/apiv1";
import { useSecurityImages } from "@hooks";
import { SecurityImage } from "@components/SecurityImage";
import {
  ExtendedBookingType,
  filterGroups,
} from "@features/transactionWizard/modal/steps/constants";
import { CopyTypography } from "@components";

const getSymbolGroup = (symbol: SymbolV2) => {
  return symbol.quoteProvider === "crypto"
    ? "crypto"
    : Object.entries(filterGroups).filter(([key, value]) =>
        value.types.includes(symbol.type as SearchSymbolV2TypeEnum)
      )?.[0]?.[0];
};

const Divider = () => (
  <Box
    width={2}
    height={2}
    sx={{
      borderRadius: "50%",
      backgroundColor: (theme) => theme.palette.text.secondary,
    }}
  />
);

type Props = {
  symbol: SymbolV2 & { quote?: QuoteV2 };
  bookingType?: ExtendedBookingType;
};

export const SymbolInfo = ({ symbol, bookingType }: Props) => {
  const { t } = useTranslation();
  const logos = useSecurityImages([
    {
      isin: symbol.isin,
      ticker_symbol: symbol.quote?.tickerSymbol,
      quoteProvider: symbol.quoteProvider,
      hasValidIsin: true,
      type: symbol.type,
    },
  ]);
  const logo = logos[symbol.isin || symbol.quote?.tickerSymbol || ""];

  const group = getSymbolGroup(symbol);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      alignItems={isLessSm ? "flex-start" : "center"}
      gap={4}
      mb={6}
      flexDirection={isLessSm ? "row-reverse" : "row"}
      justifyContent={isLessSm ? "space-between" : "flex-start"}
    >
      <SecurityImage src={logo} alt={symbol.name} />
      <Box>
        <Typography variant="subtitle1">{symbol.name}</Typography>
        <Box
          display="flex"
          alignItems="center"
          rowGap={0}
          columnGap={2}
          flexWrap={isLessSm ? "wrap" : "nowrap"}
        >
          {bookingType && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                fontWeight={isLessSm ? "400" : "500"}
              >
                {t(
                  `transactionWizardModal.selectBookingType.${bookingType}.title`
                )}
              </Typography>
              <Divider />
            </>
          )}
          {group && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                fontWeight={isLessSm ? "400" : "500"}
              >
                {t(`transactionWizardModal.searchSymbol.types.${group}.title`)}
              </Typography>
              <Divider />
            </>
          )}

          <Typography
            variant="body1"
            color="textSecondary"
            fontWeight={isLessSm ? "400" : "500"}
          >
            {t(`investmentTypes.${symbol.type}`)}
          </Typography>
          {symbol.isin && (
            <>
              {!isLessSm && <Divider />}
              <Box width={isLessSm ? "100%" : "auto"}>
                <CopyTypography
                  fontWeight={isLessSm ? "400" : "500"}
                  variant="body1"
                >
                  {symbol.isin}
                </CopyTypography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
