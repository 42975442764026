import { UseMutationOptions } from "react-query";
import { MailingApiContactUsRequest } from "@generated/apiv1";

import { buildMailingApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const contactUs = async (
  token: string | undefined,
  params: MailingApiContactUsRequest
) => {
  const apiInstance = buildMailingApi(token);
  const { data } = await apiInstance.contactUs(params);
  return data;
};

export const useContactUs = (
  options?: UseMutationOptions<void, AxiosApiError, MailingApiContactUsRequest>
) => {
  return useAuthMutation(contactUs, options, false);
};
