import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Box, MenuItem, Typography } from "@mui/material";
import { useMyself } from "@api";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import {
  MultipleSelect,
  MultipleSelectProps,
} from "@components/common/forms/MultipleSelect";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { useCreateOrUpdateSelectGroup } from "@generated/apiv1/hooks";
import { ReactComponent as CheckIcon } from "@icons/check.svg";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { ReactComponent as StackIcon } from "@icons/stack.svg";
import { PlusGradient } from "@components/Plus/PlusGradient";
import { useSelectedAccounts } from "@hooks";
import { SelectGroupParams } from "@features/selectDepots/types";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

type Props = {
  selectedGroupParams: SelectGroupParams;
  accountParams: AccountsApiListAndSearchAllAccountsRequest;
  onSave: (ids: number[]) => void;
} & Omit<
  MultipleSelectProps<string>,
  | "options"
  | "selectedOptions"
  | "onChange"
  | "noOptionsText"
  | "getOptionsText"
>;

export const DepotsSelectorDropdown = ({
  selectedGroupParams,
  accountParams,
  onSave,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { data } = useMyself();
  const queryClient = useQueryClient();
  const [checkedPortfolios, setCheckedPortfolios] = useState<number[]>([]);

  const { selectedAccounts: accounts } = useSelectedAccounts({
    selectedGroupParams,
    accountParams,
  });
  const { isSharedPortfolio, settings } = useSharedPortfolioContext();
  const amountSelected = checkedPortfolios.length;
  const allAccountsIds = accounts.map((account) => account.id);

  const { mutate: saveSelectedGroup } = useCreateOrUpdateSelectGroup();

  const options = useMemo(() => {
    return accounts.map((account) => ({
      value: String(account.id),
      label: account.name,
    }));
  }, [accounts]);
  const selectedOptions = useMemo(() => {
    if (checkedPortfolios.length === accounts.length || !accounts?.length) {
      return ["all"];
    }
    return checkedPortfolios.map((id) => String(id));
  }, [checkedPortfolios, accounts]);

  const saveSelectedAccounts = useCallback(
    (newSelectedAccounts: number[]) => {
      if (isSharedPortfolio) {
        localStorage.setItem(
          `selectedGroup-${settings.publicId}-${selectedGroupParams.id}`,
          JSON.stringify(newSelectedAccounts)
        );
        onSave?.(newSelectedAccounts);
      } else {
        saveSelectedGroup(
          {
            body: {
              name: selectedGroupParams.id,
              accountIds: newSelectedAccounts,
            },
          },
          {
            onSuccess: () => {
              queryClient.refetchQueries({
                queryKey: [
                  CACHE_KEYS.ACCOUNTS_SELECT_GROUPS_ID,
                  selectedGroupParams,
                ],
              });
            },
          }
        );
      }
      onSave?.(checkedPortfolios);
    },
    [
      isSharedPortfolio,
      saveSelectedGroup,
      checkedPortfolios,
      settings.publicId,
      onSave,
      queryClient,
      selectedGroupParams,
    ]
  );

  const onChange = (selectedAccounts: string[]) => {
    if (!selectedAccounts?.length) {
      setCheckedPortfolios(allAccountsIds);
      saveSelectedAccounts(allAccountsIds);
      return;
    }

    const newSelectedAccounts = selectedAccounts.map((id) => Number(id));
    setCheckedPortfolios(newSelectedAccounts);
    saveSelectedAccounts(newSelectedAccounts);
  };

  useEffect(() => {
    setCheckedPortfolios(
      accounts.reduce(
        (acc, account) => (account.selected ? [...acc, account.id] : acc),
        [] as number[]
      )
    );
  }, [accounts]);

  return (
    <MultipleSelect
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      noOptionsText={t("depotsSelector.noDepots")}
      allOptionsText={t("depotsSelector.allDepots")}
      getOptionsText={() =>
        t("depotsSelector.amountDepots", {
          selected: amountSelected,
          total: accounts.length,
        })
      }
      beforeElement={
        !data?.isUserPro ? (
          <Box pt={3} px={3} pb={1}>
            <PlusGradient variant="large" text={t("plus.shortText")} />
          </Box>
        ) : undefined
      }
      sx={{
        width: 246,
      }}
      startAdornment={
        <Box display="flex" color="icon.active">
          <StackIcon />
        </Box>
      }
      renderOption={(item) => {
        const isDisabled = !data?.isUserPro && item.value !== "all";
        const isSelected =
          selectedOptions.includes(item.value) ||
          (item.value === "all" && !selectedOptions.length);

        return (
          <MenuItem
            key={item.value as string}
            value={item.value as string}
            selected={isSelected}
            disabled={isDisabled}
            sx={{
              borderBottom: "none !important",
              padding: (theme) => theme.spacing(1.5, 3),

              "&.Mui-selected": {
                background: "transparent !important",
              },
              "&.Mui-selected:hover": {
                background: (theme) =>
                  `${theme.palette.background.neutral} !important`,
              },
            }}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              width="100%"
              color={isSelected ? "primary.main" : "textPrimary"}
            >
              <Box width="16px" display="flex">
                {isDisabled ? (
                  <LockIcon />
                ) : isSelected ? (
                  <CheckIcon />
                ) : undefined}
              </Box>
              <Typography noWrap variant="body1" fontWeight={400}>
                {item.label}
              </Typography>
            </Box>
          </MenuItem>
        );
      }}
      MenuProps={{
        sx: {
          "& .MuiMenu-paper": {
            maxHeight: 300,
            maxWidth: 246,
          },
        },
      }}
      {...rest}
    />
  );
};
