import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiGetUserBankConnectionEventsRequest,
  BankConnectionLEventWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getUserBankConnectionEvents = async (
  token: string | undefined,
  params: BankConnectionsApiGetUserBankConnectionEventsRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.getUserBankConnectionEvents(params);
  return data;
};

export const useGetUserBankConnectionEvents = (
  params: BankConnectionsApiGetUserBankConnectionEventsRequest,
  options?: UseQueryOptions<
    BankConnectionLEventWrapper,
    AxiosApiError,
    BankConnectionLEventWrapper
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_GET_USER_BANK_CONNECTION_EVENTS, params],
    ({ token }) => getUserBankConnectionEvents(token, params),
    options
  );
};
