import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, useTheme } from "@mui/material";
import { toPercent } from "@helpers";

export const YTDPercentContainer = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "value",
})<{
  value?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.75, 2)};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  border-radius: 4px;
  color: ${({
    theme: {
      palette: { text },
    },
    value = 0,
  }) => {
    if (value > 0) {
      return text.positive;
    } else if (value < 0) {
      return text.negative;
    }

    return text.secondary;
  }};
  background: ${({
    theme: {
      palette: { background },
    },
    value = 0,
  }) => {
    if (value > 0) {
      return background.success;
    } else if (value < 0) {
      return background.error;
    }

    return background.neutral;
  }};
`;

export type YTDPercentProps = {
  value: number;
};

export const YTDPercents = ({ value }: YTDPercentProps) => {
  const theme = useTheme();
  const digits =
    Math.abs(value) > 0.1 || Math.floor(value * 1000) === value * 1000 ? 0 : 1;
  const percent = toPercent(value || 0, "auto", digits, digits).replace(
    /\s/,
    ""
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "12px", marginRight: theme.spacing(1.5) }}
      >
        YTD
      </Typography>
      <YTDPercentContainer value={value}>{percent}</YTDPercentContainer>
    </Box>
  );
};
