interface Values {
  securityPrice: number;
  exchangeRate: number;
  commission: number;
  taxAmount: number;
  taxAndCommissionInEur: boolean;
}

export const getAmount = (props: Values) => {
  const {
    securityPrice,
    taxAmount,
    commission,
    taxAndCommissionInEur,
    exchangeRate,
  } = props;

  if (taxAndCommissionInEur) {
    return securityPrice - (taxAmount + commission) * exchangeRate;
  }

  return securityPrice - taxAmount - commission;
};

export const getAmountAC = (props: Values) => {
  const {
    securityPrice,
    taxAmount,
    commission,
    taxAndCommissionInEur,
    exchangeRate,
  } = props;

  if (taxAndCommissionInEur) {
    return securityPrice / exchangeRate - taxAmount - commission;
  }

  return (securityPrice - taxAmount - commission) / exchangeRate;
};

export const useAmounts = () => ({
  getAmount,
  getAmountAC,
});
