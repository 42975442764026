import { UseMutationOptions } from "react-query";
import { HistoricQuotesApiRefreshHistoricQuotesByIsinRequest } from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const refreshHistoricQuotesByIsin = async (
  token: string | undefined,
  params: HistoricQuotesApiRefreshHistoricQuotesByIsinRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.refreshHistoricQuotesByIsin(params);
  return data;
};

export const useRefreshHistoricQuotesByIsin = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    HistoricQuotesApiRefreshHistoricQuotesByIsinRequest
  >
) => {
  return useAuthMutation(refreshHistoricQuotesByIsin, options, false);
};
