import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiGetBankConnectionRequest,
  BankConnectionWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiGetBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.getBankConnection(params);
  return data;
};

export const useGetBankConnection = (
  params: BankConnectionsApiGetBankConnectionRequest,
  options?: UseQueryOptions<
    BankConnectionWrapper,
    AxiosApiError,
    BankConnectionWrapper
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_GET_BANK_CONNECTION, params],
    ({ token }) => getBankConnection(token, params),
    options
  );
};
