import { UseMutationOptions } from "react-query";
import {
  SharingPreferencesApiUpsertSharingPreferencesRequest,
  SharingPreference,
} from "@generated/apiv1";

import { buildSharingPreferencesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const upsertSharingPreferences = async (
  token: string | undefined,
  params: SharingPreferencesApiUpsertSharingPreferencesRequest
) => {
  const apiInstance = buildSharingPreferencesApi(token);
  const { data } = await apiInstance.upsertSharingPreferences(params);
  return data;
};

export const useUpsertSharingPreferences = (
  options?: UseMutationOptions<
    SharingPreference,
    AxiosApiError,
    SharingPreferencesApiUpsertSharingPreferencesRequest
  >
) => {
  return useAuthMutation(upsertSharingPreferences, options, false);
};
