import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { BackButton } from "@components/BackButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 736px;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

const BankContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface NotSupportedProps {
  bankName: string;
  iconUrl?: string;
  onBack: () => void;
  className?: string;
}

export const NotSupported = ({
  iconUrl,
  bankName,
  onBack,
  className,
}: NotSupportedProps) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="not-supported" className={className}>
      <BackButton onClick={onBack} className="notSupportedBackButton" />
      <BankContainer>
        {iconUrl && <img width="45" height="45" src={iconUrl} alt="name" />}
        <Typography variant="h6">{bankName}</Typography>
      </BankContainer>

      <Typography>{t("domains.bankSelect.notSupported")}</Typography>
    </Container>
  );
};
