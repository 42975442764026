import { UseMutationOptions } from "react-query";
import {
  BanksApiUpdateInterfaceSelectionOptionRequest,
  InterfaceSelectionOption,
} from "@generated/apiv1";

import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateInterfaceSelectionOption = async (
  token: string | undefined,
  params: BanksApiUpdateInterfaceSelectionOptionRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.updateInterfaceSelectionOption(params);
  return data;
};

export const useUpdateInterfaceSelectionOption = (
  options?: UseMutationOptions<
    InterfaceSelectionOption,
    AxiosApiError,
    BanksApiUpdateInterfaceSelectionOptionRequest
  >
) => {
  return useAuthMutation(updateInterfaceSelectionOption, options, false);
};
