/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateBankMessage
 */
export interface UpdateBankMessage {
    /**
     * the message
     * @type {string}
     * @memberof UpdateBankMessage
     */
    'message_text'?: string;
    /**
     * the severity status assigned to the message
     * @type {string}
     * @memberof UpdateBankMessage
     */
    'severity'?: UpdateBankMessageSeverityEnum;
    /**
     * the bic or bic group for the bank that\'s associated with this message
     * @type {string}
     * @memberof UpdateBankMessage
     */
    'bic'?: string;
    /**
     * the date (or date-time) when the message becomes active
     * @type {string}
     * @memberof UpdateBankMessage
     */
    'start_date'?: string;
    /**
     * the date (or date-time) when the message stops being active
     * @type {string}
     * @memberof UpdateBankMessage
     */
    'end_date'?: string | null;
}

export const UpdateBankMessageSeverityEnum = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    ERROR: 'ERROR'
} as const;

export type UpdateBankMessageSeverityEnum = typeof UpdateBankMessageSeverityEnum[keyof typeof UpdateBankMessageSeverityEnum];


