/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateTimeDependentPropertyParams } from '../model';
// @ts-ignore
import { TimeDependentProperty } from '../model';
// @ts-ignore
import { TimeDependentPropertyList } from '../model';
/**
 * TimeDependentPropertiesApi - axios parameter creator
 * @export
 */
export const TimeDependentPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a time dependent property.
         * @param {CreateOrUpdateTimeDependentPropertyParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeDependentProperty: async (body: CreateOrUpdateTimeDependentPropertyParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTimeDependentProperty', 'body', body)
            const localVarPath = `/v1/timeDependentProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of time dependent properties. Time dependent properties can be used for dynamic                      billing, taxation or the generation of vouchers depending on the type. Currently, the only                      supported type is \'voucher\'.
         * @param {Array<number>} [id] Retrieve time dependent properties with the given IDs
         * @param {Array<string>} [property] Retrieve all properties matching the given string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTimeDependentProperties: async (id?: Array<number>, property?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/timeDependentProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (property) {
                localVarQueryParameter['property'] = property;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given time dependent property.
         * @param {number} id Retrieve the given time dependent property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeDependentPropertiesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timeDependentPropertiesId', 'id', id)
            const localVarPath = `/v1/timeDependentProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a time dependent property.
         * @param {number} id Update the given time dependent property
         * @param {CreateOrUpdateTimeDependentPropertyParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeDependentProperty: async (id: number, body: CreateOrUpdateTimeDependentPropertyParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeDependentProperty', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTimeDependentProperty', 'body', body)
            const localVarPath = `/v1/timeDependentProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeDependentPropertiesApi - functional programming interface
 * @export
 */
export const TimeDependentPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeDependentPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a time dependent property.
         * @param {CreateOrUpdateTimeDependentPropertyParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimeDependentProperty(body: CreateOrUpdateTimeDependentPropertyParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeDependentProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimeDependentProperty(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeDependentPropertiesApi.createTimeDependentProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of time dependent properties. Time dependent properties can be used for dynamic                      billing, taxation or the generation of vouchers depending on the type. Currently, the only                      supported type is \'voucher\'.
         * @param {Array<number>} [id] Retrieve time dependent properties with the given IDs
         * @param {Array<string>} [property] Retrieve all properties matching the given string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTimeDependentProperties(id?: Array<number>, property?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeDependentPropertyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTimeDependentProperties(id, property, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeDependentPropertiesApi.listAndSearchAllTimeDependentProperties']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given time dependent property.
         * @param {number} id Retrieve the given time dependent property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timeDependentPropertiesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeDependentProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timeDependentPropertiesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeDependentPropertiesApi.timeDependentPropertiesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a time dependent property.
         * @param {number} id Update the given time dependent property
         * @param {CreateOrUpdateTimeDependentPropertyParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeDependentProperty(id: number, body: CreateOrUpdateTimeDependentPropertyParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeDependentProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeDependentProperty(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeDependentPropertiesApi.updateTimeDependentProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TimeDependentPropertiesApi - factory interface
 * @export
 */
export const TimeDependentPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeDependentPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a time dependent property.
         * @param {TimeDependentPropertiesApiCreateTimeDependentPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeDependentProperty(requestParameters: TimeDependentPropertiesApiCreateTimeDependentPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<TimeDependentProperty> {
            return localVarFp.createTimeDependentProperty(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of time dependent properties. Time dependent properties can be used for dynamic                      billing, taxation or the generation of vouchers depending on the type. Currently, the only                      supported type is \'voucher\'.
         * @param {TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTimeDependentProperties(requestParameters: TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TimeDependentPropertyList> {
            return localVarFp.listAndSearchAllTimeDependentProperties(requestParameters.id, requestParameters.property, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given time dependent property.
         * @param {TimeDependentPropertiesApiTimeDependentPropertiesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeDependentPropertiesId(requestParameters: TimeDependentPropertiesApiTimeDependentPropertiesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TimeDependentProperty> {
            return localVarFp.timeDependentPropertiesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a time dependent property.
         * @param {TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeDependentProperty(requestParameters: TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<TimeDependentProperty> {
            return localVarFp.updateTimeDependentProperty(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTimeDependentProperty operation in TimeDependentPropertiesApi.
 * @export
 * @interface TimeDependentPropertiesApiCreateTimeDependentPropertyRequest
 */
export interface TimeDependentPropertiesApiCreateTimeDependentPropertyRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateTimeDependentPropertyParams}
     * @memberof TimeDependentPropertiesApiCreateTimeDependentProperty
     */
    readonly body: CreateOrUpdateTimeDependentPropertyParams
}

/**
 * Request parameters for listAndSearchAllTimeDependentProperties operation in TimeDependentPropertiesApi.
 * @export
 * @interface TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest
 */
export interface TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest {
    /**
     * Retrieve time dependent properties with the given IDs
     * @type {Array<number>}
     * @memberof TimeDependentPropertiesApiListAndSearchAllTimeDependentProperties
     */
    readonly id?: Array<number>

    /**
     * Retrieve all properties matching the given string
     * @type {Array<string>}
     * @memberof TimeDependentPropertiesApiListAndSearchAllTimeDependentProperties
     */
    readonly property?: Array<string>
}

/**
 * Request parameters for timeDependentPropertiesId operation in TimeDependentPropertiesApi.
 * @export
 * @interface TimeDependentPropertiesApiTimeDependentPropertiesIdRequest
 */
export interface TimeDependentPropertiesApiTimeDependentPropertiesIdRequest {
    /**
     * Retrieve the given time dependent property
     * @type {number}
     * @memberof TimeDependentPropertiesApiTimeDependentPropertiesId
     */
    readonly id: number
}

/**
 * Request parameters for updateTimeDependentProperty operation in TimeDependentPropertiesApi.
 * @export
 * @interface TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest
 */
export interface TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest {
    /**
     * Update the given time dependent property
     * @type {number}
     * @memberof TimeDependentPropertiesApiUpdateTimeDependentProperty
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateTimeDependentPropertyParams}
     * @memberof TimeDependentPropertiesApiUpdateTimeDependentProperty
     */
    readonly body: CreateOrUpdateTimeDependentPropertyParams
}

/**
 * TimeDependentPropertiesApi - object-oriented interface
 * @export
 * @class TimeDependentPropertiesApi
 * @extends {BaseAPI}
 */
export class TimeDependentPropertiesApi extends BaseAPI {
    /**
     * 
     * @summary Add a time dependent property.
     * @param {TimeDependentPropertiesApiCreateTimeDependentPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeDependentPropertiesApi
     */
    public createTimeDependentProperty(requestParameters: TimeDependentPropertiesApiCreateTimeDependentPropertyRequest, options?: RawAxiosRequestConfig) {
        return TimeDependentPropertiesApiFp(this.configuration).createTimeDependentProperty(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of time dependent properties. Time dependent properties can be used for dynamic                      billing, taxation or the generation of vouchers depending on the type. Currently, the only                      supported type is \'voucher\'.
     * @param {TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeDependentPropertiesApi
     */
    public listAndSearchAllTimeDependentProperties(requestParameters: TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest = {}, options?: RawAxiosRequestConfig) {
        return TimeDependentPropertiesApiFp(this.configuration).listAndSearchAllTimeDependentProperties(requestParameters.id, requestParameters.property, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given time dependent property.
     * @param {TimeDependentPropertiesApiTimeDependentPropertiesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeDependentPropertiesApi
     */
    public timeDependentPropertiesId(requestParameters: TimeDependentPropertiesApiTimeDependentPropertiesIdRequest, options?: RawAxiosRequestConfig) {
        return TimeDependentPropertiesApiFp(this.configuration).timeDependentPropertiesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a time dependent property.
     * @param {TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeDependentPropertiesApi
     */
    public updateTimeDependentProperty(requestParameters: TimeDependentPropertiesApiUpdateTimeDependentPropertyRequest, options?: RawAxiosRequestConfig) {
        return TimeDependentPropertiesApiFp(this.configuration).updateTimeDependentProperty(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

