import React, { Fragment, useState } from "react";
import { Typography } from "@mui/material";
import { format, parseJSON } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useQueryClient } from "react-query";
import { IntervalInfo } from "@components/IntervalInfo";
import { BookingTypeEnum, useDeleteBooking } from "@api";
import { Transaction } from "@generated/apiv1";
import { toCurrency } from "@helpers";
import { GainLossTypography } from "@components";
import { TableBodyCell, TableRow, TableBody } from "@components/Table";
import { BookingInfo } from "@components/Accounts/TransactionTable/BookingInfo";
import { EditBookingModal } from "@components/Accounts/TransactionTable/EditBookingModal";
import { EditDividendModal } from "@components/Accounts/TransactionTable/EditDividendModal";
import { ActionsButton } from "@components/ActionsButton";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { ACCOUNT_BALANCES, TRANSACTIONS } from "@api/cacheKeys";
import { useDeleteTransaction } from "@generated/apiv1/hooks";
import { EditTransactionModal } from "@components/Accounts/TransactionTable/EditTransactionModal";

const PurposeText = styled(Typography)`
  word-break: break-all;
`;

interface AccountProtocolTableBodyProps {
  data: Transaction[];
}

export const AccountProtocolTableBody = (
  props: AccountProtocolTableBodyProps
) => {
  const { data = [] } = props;

  const { t } = useTranslation();

  const [currentTransactionEditing, setCurrentTransactionEditing] = useState<
    Transaction | undefined
  >(undefined);
  const [currentBookingIdEditing, setCurrentBookingIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentInvestmentIdEditing, setCurrentInvestmentIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentBookingIdDeleting, setCurrentBookingIdDeleting] = useState<
    number | undefined
  >(undefined);
  const [currentTransactionIdDeleting, setCurrentTransactionIdDeleting] =
    useState<number | undefined>(undefined);
  const [tickerSymbol, setTickerSymbol] = useState<string | undefined>(
    undefined
  );
  const [isEditBookingModalOpened, setIsEditBookingModalOpened] =
    useState(false);
  const [isEditDividendModalOpened, setIsEditDividendModalOpened] =
    useState(false);

  const handleBookingTypeEditing = (
    bookingType?: BookingTypeEnum,
    bookingId?: number,
    investmentId?: number,
    tickerSymbol?: string
  ) => {
    setCurrentInvestmentIdEditing(investmentId);
    setCurrentBookingIdEditing(bookingId);
    setTickerSymbol(tickerSymbol);
    if (bookingType === "dividend") {
      setIsEditDividendModalOpened(true);
      return;
    }
    setIsEditBookingModalOpened(true);
  };

  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isDeleteRequestLoading,
    isError: isDeleteRequestError,
    reset: mutationReset,
  } = useDeleteBooking({
    onSuccess: () => {
      setCurrentBookingIdDeleting(undefined);
      queryClient.refetchQueries(TRANSACTIONS);
      queryClient.refetchQueries(ACCOUNT_BALANCES);
    },
    retry: false,
  });

  const {
    mutate: deleteTransaction,
    isLoading: isDeleteTransactionLoading,
    isError: isDeleteTransactionError,
    reset: transactionMutationReset,
  } = useDeleteTransaction({
    onSuccess: () => {
      setCurrentTransactionIdDeleting(undefined);
      queryClient.refetchQueries(TRANSACTIONS);
      queryClient.refetchQueries(ACCOUNT_BALANCES);
    },
    retry: false,
  });

  const onEditItemClick = (item: Transaction) => {
    if (item.booking) {
      handleBookingTypeEditing(
        item.booking?.type,
        item.booking?.id,
        item.booking?.investment?.id,
        item.booking?.investment?.tickerSymbol
      );
    } else {
      setCurrentTransactionEditing(item);
    }
  };

  const onDeleteItemClick = (item: Transaction) => {
    if (item.booking?.id) {
      setCurrentBookingIdDeleting(item.booking?.id);
    } else {
      setCurrentTransactionIdDeleting(item.id);
    }
  };

  return (
    <>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.id}>
            <TableBodyCell width="10%">
              <Typography variant="body2" fontWeight="400">
                {format(parseJSON(item.valueDate!), "dd.MM.yyyy")}
              </Typography>
              <IntervalInfo
                isRegular={item.isRegular}
                paymentInterval={item.paymentInterval}
                isInterAccountTransfer={item.isInterAccountTransfer}
              />
            </TableBodyCell>

            <TableBodyCell align="left">
              {item.booking && <BookingInfo bookingData={item.booking} />}
              <PurposeText
                variant="body2"
                color="textSecondary"
                fontWeight="400"
              >
                {item.purpose || t(`singleAccountPage.protocolTable.noPurpose`)}
              </PurposeText>
            </TableBodyCell>

            <TableBodyCell align="right">
              {item.amount ? (
                <GainLossTypography
                  justifyContent="end"
                  variant="body2"
                  fontWeight="600"
                  value={item.amount}
                >
                  {item.amount > 0 ? "+" : ""}
                  {toCurrency(item.amount, "EUR")}
                </GainLossTypography>
              ) : (
                "N/A"
              )}
            </TableBodyCell>

            <TableBodyCell align="right">
              <ActionsButton
                onEditItemClick={() => onEditItemClick(item)}
                onDeleteItemClick={() => onDeleteItemClick(item)}
                editItemText={t("singleAccount.editTransaction")}
                deleteItemText={t("singleAccount.deleteTransaction")}
              />
            </TableBodyCell>
          </TableRow>
        ))}
      </TableBody>

      {isEditBookingModalOpened && currentBookingIdEditing && (
        <EditBookingModal
          isOpen={isEditBookingModalOpened}
          onClose={() => setIsEditBookingModalOpened(false)}
          investmentId={currentInvestmentIdEditing}
          bookingId={currentBookingIdEditing}
          tickerSymbol={tickerSymbol}
        />
      )}

      {isEditDividendModalOpened &&
        currentBookingIdEditing &&
        currentInvestmentIdEditing && (
          <EditDividendModal
            isOpen={isEditDividendModalOpened}
            onClose={() => setIsEditDividendModalOpened(false)}
            investmentId={currentInvestmentIdEditing}
            bookingId={currentBookingIdEditing}
            tickerSymbol={tickerSymbol}
          />
        )}

      {currentTransactionEditing && (
        <EditTransactionModal
          isOpen
          onClose={() => setCurrentTransactionEditing(undefined)}
          transaction={currentTransactionEditing}
          onSaved={() => {
            setCurrentTransactionEditing(undefined);
            queryClient.refetchQueries(TRANSACTIONS);
            queryClient.refetchQueries(ACCOUNT_BALANCES);
          }}
        />
      )}

      {currentBookingIdDeleting && (
        <ConfirmDeleteDialog
          isOpen
          title={t("singleAccount.deleteModal.title")}
          text={t("singleAccount.deleteModal.text")}
          cancelButtonText={t("singleAccount.deleteModal.cancel")}
          confirmButtonText={t("singleAccount.deleteModal.confirm")}
          onClose={() => {
            setCurrentBookingIdDeleting(undefined);
            mutationReset();
          }}
          onDelete={() => mutate(currentBookingIdDeleting!)}
          errorMessage={
            isDeleteRequestError
              ? t("singleAccount.deleteModal.errorOccurred")
              : ""
          }
          isDeleteRequestLoading={isDeleteRequestLoading}
        />
      )}

      {currentTransactionIdDeleting && (
        <ConfirmDeleteDialog
          isOpen
          title={t("singleAccount.deleteModal.title")}
          text={t("singleAccount.deleteModal.text")}
          cancelButtonText={t("singleAccount.deleteModal.cancel")}
          confirmButtonText={t("singleAccount.deleteModal.confirm")}
          onClose={() => {
            setCurrentTransactionIdDeleting(undefined);
            transactionMutationReset();
          }}
          onDelete={() =>
            deleteTransaction({ id: currentTransactionIdDeleting })
          }
          errorMessage={
            isDeleteTransactionError
              ? t("singleAccount.deleteModal.errorOccurred")
              : ""
          }
          isDeleteRequestLoading={isDeleteTransactionLoading}
        />
      )}
    </>
  );
};
