import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Link,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toPercent } from "@helpers";
import { ChartBreakdown } from "@features/analysis/components/types";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { Logo } from "@components";
import { ReactComponent as LockIcon } from "@icons/lock.svg";

const Circle = styled(Box)<{ color: string }>(({ theme, color }) => ({
  backgroundColor: color,
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  flexShrink: 0,
  marginTop: "6px",
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.tertiary.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
  },
}));

type LegendItemProps = {
  item: ChartBreakdown;
  isSelected: boolean;
  totalValue: number;
  color: string;
  onMouseOver?: (item: ChartBreakdown | null) => void;
  onClick?: (item: ChartBreakdown | null) => void;
  showInvestmentAmount?: boolean;
  renderAmount?: (item: ChartBreakdown | null) => React.ReactNode;
  blurData: boolean;
  isLast?: boolean;
};

export const LegendItem = ({
  item,
  totalValue,
  isSelected,
  color,
  onMouseOver,
  onClick,
  showInvestmentAmount,
  renderAmount,
  blurData,
  isLast,
}: LegendItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const [percentage, setPercentage] = React.useState(0);
  const shouldDisplayAmount = Boolean(
    showInvestmentAmount &&
      item.amountOfInvestments &&
      item.amountOfInvestments > 0
  );

  useEffect(() => {
    setPercentage((item.y / totalValue) * 100);
  }, [item, totalValue]);

  return (
    <Box
      py={4}
      px={3}
      sx={{
        borderBottom: (theme) =>
          isLast ? "none" : `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: (theme) =>
          isSelected ? theme.palette.background.neutral : "transparent",
        cursor: onClick ? "pointer" : "default",
      }}
      onMouseEnter={() => onMouseOver?.(item)}
      onMouseLeave={() => onMouseOver?.(null)}
      onClick={() => onClick?.(item)}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Box display="flex" alignItems="flex-start" gap={2}>
          <Circle color={color} />
          {!item.hasChildren ? (
            <Box
              width="24px"
              height="24px"
              display="flex"
              alignItems="center"
              sx={{
                overflow: "hidden",
                borderRadius: "4px",
                flexShrink: 0,
                border: (theme) =>
                  `1px solid ${theme.palette.border.secondary}`,
                "& img": {
                  width: "100% !important",
                  height: "100% !important",
                  objectFit: "contain",
                },
              }}
            >
              {item.icon && typeof item.icon === "string" ? (
                <img src={item.icon} alt={item.name} />
              ) : (
                <Logo variant="asset" />
              )}
            </Box>
          ) : item.icon ? (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                width: "22px",
                height: "16px",
                overflow: "hidden",

                "& img": {
                  width: "100% !important",
                  height: "100% !important",
                  objectFit: "cover",
                },
              }}
            >
              {item.icon}
            </Box>
          ) : null}
          {item.hasChildren || isSharedPortfolio ? (
            <Typography
              variant="body2"
              color={isSelected ? "primary.main" : "text.primary"}
              fontWeight={isSelected ? 500 : 400}
            >
              {item.name}
            </Typography>
          ) : blurData ? (
            <Box
              display="flex"
              alignItems="flex-start"
              gap={2}
              color="icon.secondary"
              sx={{
                "& svg": {
                  flexShrink: 0,
                },
              }}
            >
              <LockIcon width={18} />
              <Link
                variant="body2"
                onClick={() => navigate(item.url || `/investments/${item.id}`)}
              >
                {item.name}
              </Link>
            </Box>
          ) : (
            <Link
              variant="body2"
              onClick={() => navigate(item.url || `/investments/${item.id}`)}
            >
              {item.name}
            </Link>
          )}
        </Box>
        {renderAmount ? (
          renderAmount(item)
        ) : shouldDisplayAmount ? (
          <Typography variant="body2" fontWeight={400} color="textPrimary">
            {t("analysis.classifications.investments", {
              count: item.amountOfInvestments,
            })}
          </Typography>
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" gap={1.5}>
        <StyledLinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="body2" fontWeight={400} color="textSecondary">
          {toPercent(percentage / 100, "auto", 2, percentage < 0.01 ? 3 : 2)}
        </Typography>
      </Box>
    </Box>
  );
};
