import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { TransactionWizardEvent } from "@features/transactionWizard/modal/stateMachine/types/TransactionWizardEvent";

export const clearBookingValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BACK") {
    return {};
  }

  return {
    bookingValues: {
      ...context.bookingValues,
      numberOfLots: undefined,
      price: undefined,
      date: new Date(),
      securityPrice: undefined,
      commission: 0,
      taxAmount: 0,
      totalAmount: undefined,
      exchangeRate: 1,
    },
  };
};
