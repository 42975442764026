import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMachine } from "@xstate/react";
import truncate from "lodash/truncate";
import { Dividend } from "@api";
import { PageLayout } from "@components";
import { SomethingWrong } from "../transactionWizard/steps/SomethingWrong";
import { PersistedStep as SuccessStep } from "./steps/PersistedStep";
import { useStateHelpers } from "./hooks";
import { batchDividendWizardSM } from "./stateMachine/batchDividendWizardSM";
import { ListDividendsStep } from "./steps/ListDividendsStep";
import { TaxRequestStep } from "./steps/TaxRequestStep";
import { TaxInputStep } from "./steps/TaxInputStep";
import { SummaryStep } from "./steps/SummaryStep";

interface Props {
  investmentId: number;
}

export const CreateBatchDividendWizard = ({ investmentId }: Props) => {
  const { t } = useTranslation();
  const [currentState, sendEvent] = useMachine(batchDividendWizardSM);
  const { isLoadingState } = useStateHelpers(currentState);

  useEffect(() => {
    sendEvent({ type: "INIT_FROM_INVESTMENT", investmentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentId]);

  const investmentName = currentState.context.investment?.name ?? "";

  return (
    <PageLayout
      isLoading={isLoadingState()}
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { to: "/investments", text: t("breadcrumbs.investments") },
        { to: `/investments/${investmentId}`, text: truncate(investmentName) },
        { text: t("batchDividendsWizard.title") },
      ]}
    >
      {currentState.matches("selectDividends") && (
        <ListDividendsStep
          dividends={currentState.context.dividends}
          previouslySelectedDividends={
            currentState.context.selectedDividends ?? []
          }
          validationErrors={currentState.context.validationErrors ?? []}
          onNext={(selectedDividends: Dividend[]) =>
            sendEvent({ type: "DIVIDENDS_SELECTED", selectedDividends })
          }
        />
      )}

      {currentState.matches("taxRequest") && (
        <TaxRequestStep
          onPrevious={() => sendEvent({ type: "BACK" })}
          onNext={(agree) => {
            if (agree) {
              sendEvent({ type: "ENTER_TAX" });
              return;
            }

            sendEvent({
              type: "SKIP_TAX",
            });
          }}
        />
      )}

      {currentState.matches("taxInput") && (
        <TaxInputStep
          onNext={(customTaxAmount: number) => {
            sendEvent({
              type: "TAX_ENTERED",
              customTaxAmount,
            });
          }}
          onPrevious={() => sendEvent({ type: "BACK" })}
        />
      )}

      {currentState.matches("summary") && (
        <SummaryStep
          selectedDividends={currentState.context.selectedDividendsWithTaxRate}
          onNext={() => sendEvent({ type: "PERSIST" })}
          onPrevious={() => sendEvent({ type: "BACK" })}
        />
      )}
      {currentState.matches("success") && (
        <SuccessStep
          investmentName={currentState.context.investment?.name || ""}
          redirectUrl={`/investments/${investmentId}?tab=dividends`}
        />
      )}

      {currentState.matches("somethingWrong") && (
        <SomethingWrong message={currentState.context.error?.message} />
      )}
    </PageLayout>
  );
};
