import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

const investmentRequiredTypes = [
  "NON_LISTED_SELECTED",
  "MANUAL_SELECTED",
  "INIT_FROM_NON_LISTED",
];

export const setInvestmentValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "INVESTMENT_INITIALIZED" &&
    event.type !== "INVESTMENT_SELECTED" &&
    event.type !== "NON_LISTED_SELECTED" &&
    event.type !== "MANUAL_SELECTED" &&
    event.type !== "INIT_FROM_MANUAL" &&
    event.type !== "INIT_FROM_NON_LISTED"
  )
    throw new Error(`Invalid event type ${event.type}`);

  const quoteLast = event.investmentValues?.quoteLast;

  return {
    ...(event.type === "INVESTMENT_SELECTED" &&
      quoteLast && {
        bookingValues: {
          ...context.bookingValues,
          entryQuote: quoteLast,
        },
      }),
    isInvestmentRequired: investmentRequiredTypes.includes(event.type),
    investmentValues: event.investmentValues,
  };
};
