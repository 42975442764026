import { UseQueryOptions } from "react-query";
import {
  TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest,
  PageableTppAuthenticationGroupResources,
} from "@generated/apiv1";

import { buildTppCredentialsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllTppAuthenticationGroups = async (
  token: string | undefined,
  params?: TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest
) => {
  const apiInstance = buildTppCredentialsApi(token);
  const { data } = await apiInstance.listAndSearchAllTppAuthenticationGroups(
    params
  );
  return data;
};

export const useListAndSearchAllTppAuthenticationGroups = (
  params?: TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest,
  options?: UseQueryOptions<
    PageableTppAuthenticationGroupResources,
    AxiosApiError,
    PageableTppAuthenticationGroupResources
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_AUTHENTICATION_GROUPS,
      params,
    ],
    ({ token }) => listAndSearchAllTppAuthenticationGroups(token, params),
    options
  );
};
