import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from "@mui/material";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { useV1ListCurrencies } from "@generated/apiv1/hooks";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { useHelpers } from "@features/transactionWizard/modal/hooks/useHelpers";
import { useGetBookingValues } from "@features/transactionWizard/modal/hooks/useUpdateBookingValues";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { useLabelHelpers } from "../../hooks/useLabelHelpers";

const SummaryItem = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <Box width="100%" display="flex" justifyContent="space-between" gap={3}>
      <Typography variant="subtitle1" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight={400}>
        {value}
      </Typography>
    </Box>
  );
};

export interface Props {
  handleNext: () => void;
  isLoading: boolean;
  context: TransactionWizardContext;
}

export const SummaryStepMobileView = ({
  handleNext,
  context,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { investmentValues, bookingValues } = context;

  const { hasSecurityPrice, hasTax } = useHelpers();
  const { ouncesToGram } = useGetBookingValues();
  const { nolLabel, nolSummaryDescription, formatPrice } = useLabelHelpers();
  const investmentType = investmentValues.type || "11_stock";
  const investmentCurrency = "EUR";
  const currencySymbol = getCurrencySymbol(investmentCurrency);
  const { data } = useV1ListCurrencies();
  const currency = data?.currencies?.find(
    (currency) => currency.code === investmentCurrency
  );

  return (
    <StepContainer
      handleNext={handleNext}
      isLoading={isLoading}
      buttonTitle={t("transactionWizardModal.summary.persist")}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={5}
        sx={{
          "& svg": {
            flexShrink: 0,
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap={5}>
          <SummaryItem
            title={t("transactionWizardModal.summary.date")}
            value={format(bookingValues?.date || new Date(), "dd.MM.yyyy")}
          />
          {investmentValues.type === "61_pmetals" && (
            <SummaryItem
              title={t("transactionWizardModal.summary.gramms")}
              value={`${ouncesToGram(bookingValues?.numberOfLots || 0)} g`}
            />
          )}
          <SummaryItem
            title={t("transactionWizardModal.summary.currency")}
            value={`${context.investmentValues.investmentCurrency || "EUR"} - ${
              currency?.name
            }`}
          />
          {hasSecurityPrice(bookingValues.type) && (
            <SummaryItem
              title={t("transactionWizardModal.summary.securityPrice")}
              value={formatPrice(
                investmentType === "31_bond" ? "%" : investmentCurrency,
                bookingValues.securityPrice
              )}
            />
          )}

          <SummaryItem
            title={t("transactionWizardModal.summary.commission")}
            value={formatPrice(
              investmentCurrency,
              bookingValues.commission || 0
            )}
          />
          {bookingValues.type && hasTax(bookingValues.type) && (
            <SummaryItem
              title={t("transactionWizardModal.summary.tax")}
              value={formatPrice(
                investmentCurrency,
                bookingValues.taxAmount || 0
              )}
            />
          )}
        </Box>

        <Divider />
        <Box display="flex" flexDirection="column" gap={5}>
          <SummaryItem
            title={nolSummaryDescription(investmentType)}
            value={
              investmentType === "31_bond"
                ? formatPrice(investmentCurrency, bookingValues.numberOfLots)
                : `${formatPrice(
                    undefined,
                    bookingValues.numberOfLots
                  )} ${nolLabel(investmentType, currencySymbol)}`
            }
          />
          <SummaryItem
            title={t("transactionWizardModal.summary.entryQuote")}
            value={formatPrice(
              investmentType === "31_bond" ? "%" : investmentCurrency,
              bookingValues.entryQuote
            )}
          />
        </Box>

        <Divider />

        <SummaryItem
          title={t("transactionWizardModal.summary.totalAmountWithTaxes")}
          value={formatPrice(investmentCurrency, bookingValues.totalAmount)}
        />
      </Box>
    </StepContainer>
  );
};
