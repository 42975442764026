import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { LoadingButton, Modal, SearchField } from "@components";
import { ReactComponent as TrashBin } from "@icons/myicons/trash-bin-delete.svg";
import {
  CategoryWithChildren,
  TransactionWithCategory,
} from "@features/cashflowAnalyzer/types";
import { AddCategoryModal } from "@features/cashflowAnalyzer/components/AddCategoryModal";
import {
  useAssignCategory,
  useDeleteCategory,
} from "@features/cashflowAnalyzer/hooks/useCategories";
import { CATEGORIES_V1, TRANSACTIONS } from "@api/cacheKeys";

const CategoryWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  padding: theme.spacing(0, 4),
  backgroundColor: isSelected ? theme.palette.background.subtle : "inherit",
  cursor: "pointer",
}));

const CategoriesWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  border: `1px solid ${theme.palette.border.primary}`,
  overflowY: "auto",
}));

const CategoryRow = ({
  category,
  onCategoryClick,
  onCategoryDelete,
  selectedCategory,
  isLast,
  filter,
  isDeleting,
  transactionCategory,
}: {
  category: CategoryWithChildren;
  onCategoryClick: (category: number) => void;
  onCategoryDelete: (category: number) => void;
  selectedCategory?: number;
  isLast: boolean;
  filter?: string;
  isDeleting: boolean;
  transactionCategory?: number;
}) => {
  const isCategorySelected =
    category.id === selectedCategory ||
    category.children?.some((child) => child.id === selectedCategory);
  const isCategoryOpen = Boolean(isCategorySelected || filter);

  const filteredChildren = useMemo(() => {
    if (!filter) return category.children;
    return category.children?.filter((child) =>
      child.name?.toLowerCase().includes(filter.toLowerCase())
    );
  }, [category.children, filter]);

  const onChildCategoryClick = (id: number) => {
    onCategoryClick(selectedCategory === id ? category.id : id);
  };

  const handleCategoryDelete = (id: number) => {
    onCategoryDelete(id);
  };

  const canRemoveParentCategory =
    category.isCustom &&
    transactionCategory !== category.id &&
    (category.children
      ? !category.children?.some((child) => child.id === transactionCategory)
      : true);

  return (
    <CategoryWrapper isSelected={isCategorySelected}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          gap={4}
          height="50px"
          width="100%"
          onClick={() => onCategoryClick(category.id)}
        >
          <Checkbox checked={isCategorySelected} />
          <Typography variant="body2">{category.name}</Typography>
        </Box>
        {canRemoveParentCategory && (
          <IconButton
            onClick={() => handleCategoryDelete(category.id)}
            disabled={isDeleting}
          >
            <TrashBin />
          </IconButton>
        )}
      </Box>
      {(!isLast || isCategoryOpen) && <Divider />}
      {isCategoryOpen && (
        <Box pl={4}>
          {filteredChildren?.map((child) => (
            <Box key={child.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap={4}
                  height="50px"
                  onClick={() => onChildCategoryClick(child.id)}
                >
                  <Checkbox checked={selectedCategory === child.id} />
                  <Typography variant="body2">{child.name}</Typography>
                </Box>
                {category.isCustom && transactionCategory !== child.id && (
                  <IconButton
                    onClick={() => handleCategoryDelete(child.id)}
                    disabled={isDeleting}
                  >
                    <TrashBin />
                  </IconButton>
                )}
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      )}
    </CategoryWrapper>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  transaction: TransactionWithCategory;
  categoryTree: Record<string, CategoryWithChildren>;
};

export const CategorySelectModal = ({
  isOpen,
  onClose,
  transaction,
  categoryTree,
}: Props) => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState("");
  const { t } = useTranslation();
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    transaction.categoryId
  );
  const { mutate: assignCategory, isLoading: isAssigning } = useAssignCategory({
    onSuccess: () => {
      enqueueSnackbar(t("cashflowAnalyzer.addCategoryModal.assigned"), {
        autoHideDuration: 3000,
        variant: "success",
      });
      queryClient.invalidateQueries(TRANSACTIONS);
      onClose();
    },
  });
  const { mutate: deleteCategory, isLoading } = useDeleteCategory({
    onSuccess: () => {
      enqueueSnackbar(t("cashflowAnalyzer.addCategoryModal.deleted"), {
        autoHideDuration: 3000,
        variant: "success",
      });
      queryClient.invalidateQueries(CATEGORIES_V1);
    },
  });
  const displayedCategories = useMemo(() => {
    return Object.values(categoryTree).filter(
      (category) =>
        category.name?.toLowerCase().includes(filter.toLowerCase()) ||
        category.children?.some((child) =>
          child.name?.toLowerCase().includes(filter.toLowerCase())
        )
    );
  }, [categoryTree, filter]);

  const handleCategoryDelete = (id: number) => {
    deleteCategory({ id });
    if (id === selectedCategory) {
      setSelectedCategory(transaction.categoryId);
    }
  };

  const handleCategoryAssign = () => {
    if (!transaction.id || !selectedCategory) return;
    assignCategory({
      id: [transaction.id],
      categoryId: selectedCategory,
    });
  };

  return (
    <Modal
      width={640}
      height={isLessSm ? "100%" : 535}
      isOpen={isOpen}
      onClose={onClose}
      title={t("cashflowAnalyzer.categoryModal.title")}
    >
      <Box
        height="calc(100% - 64px)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight={0}
          mb={isLessSm ? 6 : 3}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={3}
            mb={isLessSm ? 6 : 3}
            flexDirection={isLessSm ? "column" : "row"}
          >
            <SearchField
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={t(
                "cashflowAnalyzer.categoryModal.searchPlaceholder"
              )}
              sx={{
                width: "100%",
                "& input": {
                  height: "24px",
                },
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              fullWidth={isLessSm}
              sx={{ height: "40px", flexShrink: 0 }}
              onClick={() => setIsAddCategoryModalOpen(true)}
            >
              {t("cashflowAnalyzer.categoryModal.addCategory")}
            </Button>
          </Box>
          <CategoriesWrapper>
            {displayedCategories.map((category, index) => {
              return (
                <CategoryRow
                  key={category.id}
                  filter={filter}
                  category={category}
                  selectedCategory={selectedCategory}
                  isDeleting={isLoading}
                  onCategoryClick={setSelectedCategory}
                  onCategoryDelete={handleCategoryDelete}
                  isLast={index === Object.entries(categoryTree).length - 1}
                  transactionCategory={transaction.categoryId}
                />
              );
            })}
          </CategoriesWrapper>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={isLessSm ? 3 : 4}
          flexDirection={isLessSm ? "column" : "row"}
        >
          <Box width={isLessSm ? "100%" : "50%"}>
            <Button variant="outlined" fullWidth onClick={onClose}>
              {t("cashflowAnalyzer.categoryModal.back")}
            </Button>
          </Box>
          <Box width={isLessSm ? "100%" : "50%"}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCategoryAssign}
              isLoading={isAssigning}
            >
              {t("cashflowAnalyzer.categoryModal.save")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <AddCategoryModal
        isOpen={isAddCategoryModalOpen}
        onClose={() => setIsAddCategoryModalOpen(false)}
        categories={categoryTree}
      />
    </Modal>
  );
};
