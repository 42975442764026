import { UseQueryOptions } from "react-query";
import {
  AssetReportsApiAssetsTotalMarketValueRequest,
  AssetsTotalMarketValue,
} from "@generated/apiv3";

import { buildAssetReportsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const assetsTotalMarketValue = async (
  token: string | undefined,
  params: AssetReportsApiAssetsTotalMarketValueRequest
) => {
  const apiInstance = buildAssetReportsApi(token);
  const { data } = await apiInstance.assetsTotalMarketValue(params);
  return data;
};

export const useAssetsTotalMarketValue = (
  params: AssetReportsApiAssetsTotalMarketValueRequest,
  options?: UseQueryOptions<
    AssetsTotalMarketValue,
    AxiosApiError,
    AssetsTotalMarketValue
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ASSETREPORTS_ASSETS_TOTAL_MARKET_VALUE, params],
    ({ token }) => assetsTotalMarketValue(token, params),
    options
  );
};
