/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A wrapper around finAPIs bank connection event
 * @export
 * @interface BankConnectionEventWrapper
 */
export interface BankConnectionEventWrapper {
    /**
     * User identifier
     * @type {number}
     * @memberof BankConnectionEventWrapper
     */
    'userId': number;
    /**
     * User mandator identifier
     * @type {number}
     * @memberof BankConnectionEventWrapper
     */
    'mandatorId': number;
    /**
     * Log severity level
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'severity'?: BankConnectionEventWrapperSeverityEnum;
    /**
     * Bank name
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'bankName'?: string;
    /**
     * Bank slug
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'bankSlug'?: string;
    /**
     * Bank Identifier Code
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'bic'?: string;
    /**
     * Multibanking provider
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'multiBankingProvider'?: BankConnectionEventWrapperMultiBankingProviderEnum;
    /**
     * Logged error response body
     * @type {{ [key: string]: object; }}
     * @memberof BankConnectionEventWrapper
     */
    'logResponseBody'?: { [key: string]: object; };
    /**
     * Created at
     * @type {string}
     * @memberof BankConnectionEventWrapper
     */
    'createdAt'?: string;
}

export const BankConnectionEventWrapperSeverityEnum = {
    INFO: 'info',
    DEBUG: 'debug',
    ERROR: 'error'
} as const;

export type BankConnectionEventWrapperSeverityEnum = typeof BankConnectionEventWrapperSeverityEnum[keyof typeof BankConnectionEventWrapperSeverityEnum];
export const BankConnectionEventWrapperMultiBankingProviderEnum = {
    FIN_API: 'FIN_API',
    NETFONDS: 'NETFONDS',
    WEALTH_API: 'WEALTH_API'
} as const;

export type BankConnectionEventWrapperMultiBankingProviderEnum = typeof BankConnectionEventWrapperMultiBankingProviderEnum[keyof typeof BankConnectionEventWrapperMultiBankingProviderEnum];


