/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { IncomeAndSpendingHistory } from '../model';
// @ts-ignore
import { IncomeAndSpendingStatistics } from '../model';
/**
 * CashFlowAnalyticsApi - axios parameter creator
 * @export
 */
export const CashFlowAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cash flow analytics for the given accounts
         * @param {string} from Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).
         * @param {Array<number>} [id] Perform analytics on the given accounts
         * @param {string} [to] Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).                                Default is today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashFlowAnalytics: async (from: string, id?: Array<number>, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('cashFlowAnalytics', 'from', from)
            const localVarPath = `/v1/cashFlowAnalytics/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Predict this and next months transactions for the given accounts
         * @param {Array<number>} [id] Perform analytics on the given accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictCashFlows: async (id?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cashFlowAnalytics/predict`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashFlowAnalyticsApi - functional programming interface
 * @export
 */
export const CashFlowAnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashFlowAnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cash flow analytics for the given accounts
         * @param {string} from Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).
         * @param {Array<number>} [id] Perform analytics on the given accounts
         * @param {string} [to] Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).                                Default is today.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashFlowAnalytics(from: string, id?: Array<number>, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeAndSpendingHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashFlowAnalytics(from, id, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashFlowAnalyticsApi.cashFlowAnalytics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Predict this and next months transactions for the given accounts
         * @param {Array<number>} [id] Perform analytics on the given accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predictCashFlows(id?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeAndSpendingStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predictCashFlows(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashFlowAnalyticsApi.predictCashFlows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashFlowAnalyticsApi - factory interface
 * @export
 */
export const CashFlowAnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashFlowAnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cash flow analytics for the given accounts
         * @param {CashFlowAnalyticsApiCashFlowAnalyticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashFlowAnalytics(requestParameters: CashFlowAnalyticsApiCashFlowAnalyticsRequest, options?: RawAxiosRequestConfig): AxiosPromise<IncomeAndSpendingHistory> {
            return localVarFp.cashFlowAnalytics(requestParameters.from, requestParameters.id, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Predict this and next months transactions for the given accounts
         * @param {CashFlowAnalyticsApiPredictCashFlowsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictCashFlows(requestParameters: CashFlowAnalyticsApiPredictCashFlowsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<IncomeAndSpendingStatistics> {
            return localVarFp.predictCashFlows(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cashFlowAnalytics operation in CashFlowAnalyticsApi.
 * @export
 * @interface CashFlowAnalyticsApiCashFlowAnalyticsRequest
 */
export interface CashFlowAnalyticsApiCashFlowAnalyticsRequest {
    /**
     * Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof CashFlowAnalyticsApiCashFlowAnalytics
     */
    readonly from: string

    /**
     * Perform analytics on the given accounts
     * @type {Array<number>}
     * @memberof CashFlowAnalyticsApiCashFlowAnalytics
     */
    readonly id?: Array<number>

    /**
     * Get income and expenses starting from the given date; format is yyyy-MM-dd (US Format).                                Default is today.
     * @type {string}
     * @memberof CashFlowAnalyticsApiCashFlowAnalytics
     */
    readonly to?: string
}

/**
 * Request parameters for predictCashFlows operation in CashFlowAnalyticsApi.
 * @export
 * @interface CashFlowAnalyticsApiPredictCashFlowsRequest
 */
export interface CashFlowAnalyticsApiPredictCashFlowsRequest {
    /**
     * Perform analytics on the given accounts
     * @type {Array<number>}
     * @memberof CashFlowAnalyticsApiPredictCashFlows
     */
    readonly id?: Array<number>
}

/**
 * CashFlowAnalyticsApi - object-oriented interface
 * @export
 * @class CashFlowAnalyticsApi
 * @extends {BaseAPI}
 */
export class CashFlowAnalyticsApi extends BaseAPI {
    /**
     * 
     * @summary Get cash flow analytics for the given accounts
     * @param {CashFlowAnalyticsApiCashFlowAnalyticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashFlowAnalyticsApi
     */
    public cashFlowAnalytics(requestParameters: CashFlowAnalyticsApiCashFlowAnalyticsRequest, options?: RawAxiosRequestConfig) {
        return CashFlowAnalyticsApiFp(this.configuration).cashFlowAnalytics(requestParameters.from, requestParameters.id, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Predict this and next months transactions for the given accounts
     * @param {CashFlowAnalyticsApiPredictCashFlowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashFlowAnalyticsApi
     */
    public predictCashFlows(requestParameters: CashFlowAnalyticsApiPredictCashFlowsRequest = {}, options?: RawAxiosRequestConfig) {
        return CashFlowAnalyticsApiFp(this.configuration).predictCashFlows(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

