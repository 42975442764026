/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddOrUpdateHistoricQuoteParams } from '../model';
// @ts-ignore
import { AddOrUpdateProductQuoteParams } from '../model';
// @ts-ignore
import { HistoricQuoteList } from '../model';
// @ts-ignore
import { HistoricQuoteListWithRiskYieldStatistics } from '../model';
// @ts-ignore
import { HistoricQuoteWithRiskYieldStatistics } from '../model';
// @ts-ignore
import { QuoteBarList } from '../model';
// @ts-ignore
import { TickerSymbolWithQuoteProvider } from '../model';
/**
 * HistoricQuotesApi - axios parameter creator
 * @export
 */
export const HistoricQuotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add or overwrite historic quotes manually. This operation should only be used if no automatic                      quote assignment can be done; i.e. no quotes data is available for the given investment.
         * @param {AddOrUpdateHistoricQuoteParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateHistoricQuotes: async (body: AddOrUpdateHistoricQuoteParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addOrUpdateHistoricQuotes', 'body', body)
            const localVarPath = `/v1/historicQuotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Note: this is a Server-to-Server operation only! Add or overwrite historic quotes for an                     investment product manually. This operation should be used by other backends to maintain quote                     master data.
         * @param {AddOrUpdateProductQuoteParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateProductQuotes: async (body: AddOrUpdateProductQuoteParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addOrUpdateProductQuotes', 'body', body)
            const localVarPath = `/v1/historicQuotes/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                 Get or search historic quotes
         * @param {Array<number>} [accountId] Retrieve historic quotes for all investments within the given accounts.                         Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<number>} [investmentId] Retrieve historic quotes for the given investments. Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<number>} [watchlistEntryId] Retrieve historic quotes for the given watchlist entries. Limited to 50 entries.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<string>} [tickerSymbol] Retrieve historic quotes for the given ticker symbols. Limited to 50 tickers.
         * @param {boolean} [includeIndexes] Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
         * @param {boolean} [includeHistoricPositions] Include quotes for historic depot positions. This parameter is only used if accountId(s)                              are specified as well. In this context, historic positions are all investments having a                              number of lots of zero. Default is false.
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes upt to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {HistoricQuotesIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {HistoricQuotesIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {HistoricQuotesInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicQuotes: async (accountId?: Array<number>, investmentId?: Array<number>, watchlistEntryId?: Array<number>, tickerSymbol?: Array<string>, includeIndexes?: boolean, includeHistoricPositions?: boolean, convertToCurrency?: string, from?: string, to?: string, interval?: HistoricQuotesIntervalEnum, intervalPosition?: HistoricQuotesIntervalPositionEnum, inPercentFrom?: HistoricQuotesInPercentFromEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/historicQuotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (watchlistEntryId) {
                localVarQueryParameter['watchlistEntryId'] = watchlistEntryId;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (includeIndexes !== undefined) {
                localVarQueryParameter['includeIndexes'] = includeIndexes;
            }

            if (includeHistoricPositions !== undefined) {
                localVarQueryParameter['includeHistoricPositions'] = includeHistoricPositions;
            }

            if (convertToCurrency !== undefined) {
                localVarQueryParameter['convertToCurrency'] = convertToCurrency;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }

            if (inPercentFrom !== undefined) {
                localVarQueryParameter['inPercentFrom'] = inPercentFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve intraday quotes for the given ticker symbol by querying an external web-service.
         * @param {string} tickerSymbol Retrieve historic quotes for this ticker symbol
         * @param {string} quoteProvider The according quote provider; currently \&#39;crypto\&#39; and \&#39;teletrader\&#39; support intraday quotes.
         * @param {string} period The period for quote retrieval, supported values are:&lt;br /&gt;                     intraday1: minutely quotes&lt;br /&gt;                     intraday5: 5-minute quotes&lt;br /&gt;                     intraday10: 10-minute quotes&lt;br /&gt;                     intraday15: 15-minute quotes&lt;br /&gt;                     intraday60: 60-minute quotes&lt;br /&gt;                     The default value is intraday1 (minutely) quotes. Not that the interval is automatically determined                     for crypto currencies due to third party API restrictions.
         * @param {string} from Retrieve intraday quotes starting from the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
         * @param {string} to Retrieve intraday quotes until the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
         * @param {string} [quoteCurrency] The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intraday: async (tickerSymbol: string, quoteProvider: string, period: string, from: string, to: string, quoteCurrency?: string, convertToCurrency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tickerSymbol' is not null or undefined
            assertParamExists('intraday', 'tickerSymbol', tickerSymbol)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('intraday', 'quoteProvider', quoteProvider)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('intraday', 'period', period)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('intraday', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('intraday', 'to', to)
            const localVarPath = `/v1/historicQuotes/intraday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tickerSymbol !== undefined) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (quoteProvider !== undefined) {
                localVarQueryParameter['quoteProvider'] = quoteProvider;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (quoteCurrency !== undefined) {
                localVarQueryParameter['quoteCurrency'] = quoteCurrency;
            }

            if (convertToCurrency !== undefined) {
                localVarQueryParameter['convertToCurrency'] = convertToCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                        Lookup historic quotes for the given ticker symbol by querying an external web-service.
         * @param {string} tickerSymbol Retrieve historic quotes for this ticker symbol
         * @param {string} quoteProvider The according quote provider
         * @param {boolean} [includeIndexes] Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
         * @param {string} [quoteCurrency] The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
         * @param {LookupIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {LookupIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {LookupInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookup: async (tickerSymbol: string, quoteProvider: string, includeIndexes?: boolean, quoteCurrency?: string, convertToCurrency?: string, from?: string, to?: string, interval?: LookupIntervalEnum, intervalPosition?: LookupIntervalPositionEnum, inPercentFrom?: LookupInPercentFromEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tickerSymbol' is not null or undefined
            assertParamExists('lookup', 'tickerSymbol', tickerSymbol)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('lookup', 'quoteProvider', quoteProvider)
            const localVarPath = `/v1/historicQuotes/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tickerSymbol !== undefined) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (quoteProvider !== undefined) {
                localVarQueryParameter['quoteProvider'] = quoteProvider;
            }

            if (includeIndexes !== undefined) {
                localVarQueryParameter['includeIndexes'] = includeIndexes;
            }

            if (quoteCurrency !== undefined) {
                localVarQueryParameter['quoteCurrency'] = quoteCurrency;
            }

            if (convertToCurrency !== undefined) {
                localVarQueryParameter['convertToCurrency'] = convertToCurrency;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }

            if (inPercentFrom !== undefined) {
                localVarQueryParameter['inPercentFrom'] = inPercentFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the given historic quote.
         * @param {TickerSymbolWithQuoteProvider} body A tickerSymbol / quoteProvider pair uniquely identifying the quote to refresh.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshHistoricQuote: async (body: TickerSymbolWithQuoteProvider, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('refreshHistoricQuote', 'body', body)
            const localVarPath = `/v1/historicQuotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the given historic quote(s) by ISIN.
         * @param {string} isin Refresh all historic quotes matching the given ticker symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshHistoricQuotesByIsin: async (isin: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('refreshHistoricQuotesByIsin', 'isin', isin)
            const localVarPath = `/v1/historicQuotes/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a historic quote with risk and yield statistics for a given ticker symbol
         * @param {string} quoteProvider The according quote provider. The quote provider will be delivered when searching a                         security.
         * @param {string} tickerSymbol The ticker symbol of the investment to retrieve the risk and yield statistics for.
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {boolean} [userDefinedOnly] Only return user defined quotes if true; thereby skipping interpolated quotes.                         Returns all quotes including the interpolated ones if false. This flag obviously                         only has an effect if these quotes are user defined, i.e. not provided by a quote provider.                         Default is false.
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
         * @param {ShowHistoricQuoteIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {ShowHistoricQuoteIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {ShowHistoricQuoteInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                         \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showHistoricQuote: async (quoteProvider: string, tickerSymbol: string, convertToCurrency?: string, userDefinedOnly?: boolean, from?: string, to?: string, interval?: ShowHistoricQuoteIntervalEnum, intervalPosition?: ShowHistoricQuoteIntervalPositionEnum, inPercentFrom?: ShowHistoricQuoteInPercentFromEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('showHistoricQuote', 'quoteProvider', quoteProvider)
            // verify required parameter 'tickerSymbol' is not null or undefined
            assertParamExists('showHistoricQuote', 'tickerSymbol', tickerSymbol)
            const localVarPath = `/v1/historicQuotes/{quoteProvider}/{tickerSymbol}`
                .replace(`{${"quoteProvider"}}`, encodeURIComponent(String(quoteProvider)))
                .replace(`{${"tickerSymbol"}}`, encodeURIComponent(String(tickerSymbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (convertToCurrency !== undefined) {
                localVarQueryParameter['convertToCurrency'] = convertToCurrency;
            }

            if (userDefinedOnly !== undefined) {
                localVarQueryParameter['userDefinedOnly'] = userDefinedOnly;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (intervalPosition !== undefined) {
                localVarQueryParameter['intervalPosition'] = intervalPosition;
            }

            if (inPercentFrom !== undefined) {
                localVarQueryParameter['inPercentFrom'] = inPercentFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoricQuotesApi - functional programming interface
 * @export
 */
export const HistoricQuotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoricQuotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add or overwrite historic quotes manually. This operation should only be used if no automatic                      quote assignment can be done; i.e. no quotes data is available for the given investment.
         * @param {AddOrUpdateHistoricQuoteParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateHistoricQuotes(body: AddOrUpdateHistoricQuoteParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateHistoricQuotes(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.addOrUpdateHistoricQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Note: this is a Server-to-Server operation only! Add or overwrite historic quotes for an                     investment product manually. This operation should be used by other backends to maintain quote                     master data.
         * @param {AddOrUpdateProductQuoteParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateProductQuotes(body: AddOrUpdateProductQuoteParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateProductQuotes(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.addOrUpdateProductQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                 Get or search historic quotes
         * @param {Array<number>} [accountId] Retrieve historic quotes for all investments within the given accounts.                         Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<number>} [investmentId] Retrieve historic quotes for the given investments. Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<number>} [watchlistEntryId] Retrieve historic quotes for the given watchlist entries. Limited to 50 entries.                         Note that unauthorized access is not supported for this query parameter.
         * @param {Array<string>} [tickerSymbol] Retrieve historic quotes for the given ticker symbols. Limited to 50 tickers.
         * @param {boolean} [includeIndexes] Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
         * @param {boolean} [includeHistoricPositions] Include quotes for historic depot positions. This parameter is only used if accountId(s)                              are specified as well. In this context, historic positions are all investments having a                              number of lots of zero. Default is false.
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes upt to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
         * @param {HistoricQuotesIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {HistoricQuotesIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {HistoricQuotesInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicQuotes(accountId?: Array<number>, investmentId?: Array<number>, watchlistEntryId?: Array<number>, tickerSymbol?: Array<string>, includeIndexes?: boolean, includeHistoricPositions?: boolean, convertToCurrency?: string, from?: string, to?: string, interval?: HistoricQuotesIntervalEnum, intervalPosition?: HistoricQuotesIntervalPositionEnum, inPercentFrom?: HistoricQuotesInPercentFromEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricQuoteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicQuotes(accountId, investmentId, watchlistEntryId, tickerSymbol, includeIndexes, includeHistoricPositions, convertToCurrency, from, to, interval, intervalPosition, inPercentFrom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.historicQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve intraday quotes for the given ticker symbol by querying an external web-service.
         * @param {string} tickerSymbol Retrieve historic quotes for this ticker symbol
         * @param {string} quoteProvider The according quote provider; currently \&#39;crypto\&#39; and \&#39;teletrader\&#39; support intraday quotes.
         * @param {string} period The period for quote retrieval, supported values are:&lt;br /&gt;                     intraday1: minutely quotes&lt;br /&gt;                     intraday5: 5-minute quotes&lt;br /&gt;                     intraday10: 10-minute quotes&lt;br /&gt;                     intraday15: 15-minute quotes&lt;br /&gt;                     intraday60: 60-minute quotes&lt;br /&gt;                     The default value is intraday1 (minutely) quotes. Not that the interval is automatically determined                     for crypto currencies due to third party API restrictions.
         * @param {string} from Retrieve intraday quotes starting from the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
         * @param {string} to Retrieve intraday quotes until the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
         * @param {string} [quoteCurrency] The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intraday(tickerSymbol: string, quoteProvider: string, period: string, from: string, to: string, quoteCurrency?: string, convertToCurrency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteBarList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.intraday(tickerSymbol, quoteProvider, period, from, to, quoteCurrency, convertToCurrency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.intraday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                        Lookup historic quotes for the given ticker symbol by querying an external web-service.
         * @param {string} tickerSymbol Retrieve historic quotes for this ticker symbol
         * @param {string} quoteProvider The according quote provider
         * @param {boolean} [includeIndexes] Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
         * @param {string} [quoteCurrency] The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
         * @param {LookupIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {LookupIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {LookupInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookup(tickerSymbol: string, quoteProvider: string, includeIndexes?: boolean, quoteCurrency?: string, convertToCurrency?: string, from?: string, to?: string, interval?: LookupIntervalEnum, intervalPosition?: LookupIntervalPositionEnum, inPercentFrom?: LookupInPercentFromEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricQuoteListWithRiskYieldStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookup(tickerSymbol, quoteProvider, includeIndexes, quoteCurrency, convertToCurrency, from, to, interval, intervalPosition, inPercentFrom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.lookup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the given historic quote.
         * @param {TickerSymbolWithQuoteProvider} body A tickerSymbol / quoteProvider pair uniquely identifying the quote to refresh.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshHistoricQuote(body: TickerSymbolWithQuoteProvider, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshHistoricQuote(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.refreshHistoricQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the given historic quote(s) by ISIN.
         * @param {string} isin Refresh all historic quotes matching the given ticker symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshHistoricQuotesByIsin(isin: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshHistoricQuotesByIsin(isin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.refreshHistoricQuotesByIsin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve a historic quote with risk and yield statistics for a given ticker symbol
         * @param {string} quoteProvider The according quote provider. The quote provider will be delivered when searching a                         security.
         * @param {string} tickerSymbol The ticker symbol of the investment to retrieve the risk and yield statistics for.
         * @param {string} [convertToCurrency] Convert all quotes to the given currency; currency must be given in 3-letter ISO code
         * @param {boolean} [userDefinedOnly] Only return user defined quotes if true; thereby skipping interpolated quotes.                         Returns all quotes including the interpolated ones if false. This flag obviously                         only has an effect if these quotes are user defined, i.e. not provided by a quote provider.                         Default is false.
         * @param {string} [from] Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
         * @param {string} [to] Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
         * @param {ShowHistoricQuoteIntervalEnum} [interval] Restrict quote retrieval to the given interval. Default is to ignore this parameter.
         * @param {ShowHistoricQuoteIntervalPositionEnum} [intervalPosition] Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
         * @param {ShowHistoricQuoteInPercentFromEnum} [inPercentFrom] Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                         \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showHistoricQuote(quoteProvider: string, tickerSymbol: string, convertToCurrency?: string, userDefinedOnly?: boolean, from?: string, to?: string, interval?: ShowHistoricQuoteIntervalEnum, intervalPosition?: ShowHistoricQuoteIntervalPositionEnum, inPercentFrom?: ShowHistoricQuoteInPercentFromEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricQuoteWithRiskYieldStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showHistoricQuote(quoteProvider, tickerSymbol, convertToCurrency, userDefinedOnly, from, to, interval, intervalPosition, inPercentFrom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HistoricQuotesApi.showHistoricQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HistoricQuotesApi - factory interface
 * @export
 */
export const HistoricQuotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoricQuotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add or overwrite historic quotes manually. This operation should only be used if no automatic                      quote assignment can be done; i.e. no quotes data is available for the given investment.
         * @param {HistoricQuotesApiAddOrUpdateHistoricQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateHistoricQuotes(requestParameters: HistoricQuotesApiAddOrUpdateHistoricQuotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addOrUpdateHistoricQuotes(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Note: this is a Server-to-Server operation only! Add or overwrite historic quotes for an                     investment product manually. This operation should be used by other backends to maintain quote                     master data.
         * @param {HistoricQuotesApiAddOrUpdateProductQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateProductQuotes(requestParameters: HistoricQuotesApiAddOrUpdateProductQuotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addOrUpdateProductQuotes(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                 Get or search historic quotes
         * @param {HistoricQuotesApiHistoricQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicQuotes(requestParameters: HistoricQuotesApiHistoricQuotesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<HistoricQuoteList> {
            return localVarFp.historicQuotes(requestParameters.accountId, requestParameters.investmentId, requestParameters.watchlistEntryId, requestParameters.tickerSymbol, requestParameters.includeIndexes, requestParameters.includeHistoricPositions, requestParameters.convertToCurrency, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve intraday quotes for the given ticker symbol by querying an external web-service.
         * @param {HistoricQuotesApiIntradayRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intraday(requestParameters: HistoricQuotesApiIntradayRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteBarList> {
            return localVarFp.intraday(requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.period, requestParameters.from, requestParameters.to, requestParameters.quoteCurrency, requestParameters.convertToCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                        Lookup historic quotes for the given ticker symbol by querying an external web-service.
         * @param {HistoricQuotesApiLookupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookup(requestParameters: HistoricQuotesApiLookupRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricQuoteListWithRiskYieldStatistics> {
            return localVarFp.lookup(requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.includeIndexes, requestParameters.quoteCurrency, requestParameters.convertToCurrency, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the given historic quote.
         * @param {HistoricQuotesApiRefreshHistoricQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshHistoricQuote(requestParameters: HistoricQuotesApiRefreshHistoricQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.refreshHistoricQuote(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the given historic quote(s) by ISIN.
         * @param {HistoricQuotesApiRefreshHistoricQuotesByIsinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshHistoricQuotesByIsin(requestParameters: HistoricQuotesApiRefreshHistoricQuotesByIsinRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.refreshHistoricQuotesByIsin(requestParameters.isin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a historic quote with risk and yield statistics for a given ticker symbol
         * @param {HistoricQuotesApiShowHistoricQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showHistoricQuote(requestParameters: HistoricQuotesApiShowHistoricQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricQuoteWithRiskYieldStatistics> {
            return localVarFp.showHistoricQuote(requestParameters.quoteProvider, requestParameters.tickerSymbol, requestParameters.convertToCurrency, requestParameters.userDefinedOnly, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addOrUpdateHistoricQuotes operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiAddOrUpdateHistoricQuotesRequest
 */
export interface HistoricQuotesApiAddOrUpdateHistoricQuotesRequest {
    /**
     * The comment details
     * @type {AddOrUpdateHistoricQuoteParams}
     * @memberof HistoricQuotesApiAddOrUpdateHistoricQuotes
     */
    readonly body: AddOrUpdateHistoricQuoteParams
}

/**
 * Request parameters for addOrUpdateProductQuotes operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiAddOrUpdateProductQuotesRequest
 */
export interface HistoricQuotesApiAddOrUpdateProductQuotesRequest {
    /**
     * The comment details
     * @type {AddOrUpdateProductQuoteParams}
     * @memberof HistoricQuotesApiAddOrUpdateProductQuotes
     */
    readonly body: AddOrUpdateProductQuoteParams
}

/**
 * Request parameters for historicQuotes operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiHistoricQuotesRequest
 */
export interface HistoricQuotesApiHistoricQuotesRequest {
    /**
     * Retrieve historic quotes for all investments within the given accounts.                         Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
     * @type {Array<number>}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve historic quotes for the given investments. Limited to 50 investments.                         Note that unauthorized access is not supported for this query parameter.
     * @type {Array<number>}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve historic quotes for the given watchlist entries. Limited to 50 entries.                         Note that unauthorized access is not supported for this query parameter.
     * @type {Array<number>}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly watchlistEntryId?: Array<number>

    /**
     * Retrieve historic quotes for the given ticker symbols. Limited to 50 tickers.
     * @type {Array<string>}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
     * @type {boolean}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly includeIndexes?: boolean

    /**
     * Include quotes for historic depot positions. This parameter is only used if accountId(s)                              are specified as well. In this context, historic positions are all investments having a                              number of lots of zero. Default is false.
     * @type {boolean}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly includeHistoricPositions?: boolean

    /**
     * Convert all quotes to the given currency; currency must be given in 3-letter ISO code
     * @type {string}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly convertToCurrency?: string

    /**
     * Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly from?: string

    /**
     * Retrieve quotes upt to the given date. Default value is today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly to?: string

    /**
     * Restrict quote retrieval to the given interval. Default is to ignore this parameter.
     * @type {'day' | 'week' | 'month' | 'year'}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly interval?: HistoricQuotesIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly intervalPosition?: HistoricQuotesIntervalPositionEnum

    /**
     * Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
     * @type {'first' | 'previous' | 'currentToNow'}
     * @memberof HistoricQuotesApiHistoricQuotes
     */
    readonly inPercentFrom?: HistoricQuotesInPercentFromEnum
}

/**
 * Request parameters for intraday operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiIntradayRequest
 */
export interface HistoricQuotesApiIntradayRequest {
    /**
     * Retrieve historic quotes for this ticker symbol
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly tickerSymbol: string

    /**
     * The according quote provider; currently \&#39;crypto\&#39; and \&#39;teletrader\&#39; support intraday quotes.
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly quoteProvider: string

    /**
     * The period for quote retrieval, supported values are:&lt;br /&gt;                     intraday1: minutely quotes&lt;br /&gt;                     intraday5: 5-minute quotes&lt;br /&gt;                     intraday10: 10-minute quotes&lt;br /&gt;                     intraday15: 15-minute quotes&lt;br /&gt;                     intraday60: 60-minute quotes&lt;br /&gt;                     The default value is intraday1 (minutely) quotes. Not that the interval is automatically determined                     for crypto currencies due to third party API restrictions.
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly period: string

    /**
     * Retrieve intraday quotes starting from the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly from: string

    /**
     * Retrieve intraday quotes until the given date / time. This must be a valid                         ISO-8601 date string. Examples are 2019-01-01 or 2009-01-01T12:00:00+01:0
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly to: string

    /**
     * The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly quoteCurrency?: string

    /**
     * Convert all quotes to the given currency; currency must be given in 3-letter ISO code
     * @type {string}
     * @memberof HistoricQuotesApiIntraday
     */
    readonly convertToCurrency?: string
}

/**
 * Request parameters for lookup operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiLookupRequest
 */
export interface HistoricQuotesApiLookupRequest {
    /**
     * Retrieve historic quotes for this ticker symbol
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly tickerSymbol: string

    /**
     * The according quote provider
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly quoteProvider: string

    /**
     * Include quotes for a pre-defined set of stock indexes; this is nice for comparisons
     * @type {boolean}
     * @memberof HistoricQuotesApiLookup
     */
    readonly includeIndexes?: boolean

    /**
     * The historic quote currency - the used web-service for historic quote lookup does not                              provide currency information
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly quoteCurrency?: string

    /**
     * Convert all quotes to the given currency; currency must be given in 3-letter ISO code
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly convertToCurrency?: string

    /**
     * Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly from?: string

    /**
     * Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiLookup
     */
    readonly to?: string

    /**
     * Restrict quote retrieval to the given interval. Default is to ignore this parameter.
     * @type {'week' | 'month' | 'year'}
     * @memberof HistoricQuotesApiLookup
     */
    readonly interval?: LookupIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof HistoricQuotesApiLookup
     */
    readonly intervalPosition?: LookupIntervalPositionEnum

    /**
     * Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                          \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
     * @type {'first' | 'previous' | 'currentToNow'}
     * @memberof HistoricQuotesApiLookup
     */
    readonly inPercentFrom?: LookupInPercentFromEnum
}

/**
 * Request parameters for refreshHistoricQuote operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiRefreshHistoricQuoteRequest
 */
export interface HistoricQuotesApiRefreshHistoricQuoteRequest {
    /**
     * A tickerSymbol / quoteProvider pair uniquely identifying the quote to refresh.
     * @type {TickerSymbolWithQuoteProvider}
     * @memberof HistoricQuotesApiRefreshHistoricQuote
     */
    readonly body: TickerSymbolWithQuoteProvider
}

/**
 * Request parameters for refreshHistoricQuotesByIsin operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiRefreshHistoricQuotesByIsinRequest
 */
export interface HistoricQuotesApiRefreshHistoricQuotesByIsinRequest {
    /**
     * Refresh all historic quotes matching the given ticker symbol
     * @type {string}
     * @memberof HistoricQuotesApiRefreshHistoricQuotesByIsin
     */
    readonly isin: string
}

/**
 * Request parameters for showHistoricQuote operation in HistoricQuotesApi.
 * @export
 * @interface HistoricQuotesApiShowHistoricQuoteRequest
 */
export interface HistoricQuotesApiShowHistoricQuoteRequest {
    /**
     * The according quote provider. The quote provider will be delivered when searching a                         security.
     * @type {string}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly quoteProvider: string

    /**
     * The ticker symbol of the investment to retrieve the risk and yield statistics for.
     * @type {string}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly tickerSymbol: string

    /**
     * Convert all quotes to the given currency; currency must be given in 3-letter ISO code
     * @type {string}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly convertToCurrency?: string

    /**
     * Only return user defined quotes if true; thereby skipping interpolated quotes.                         Returns all quotes including the interpolated ones if false. This flag obviously                         only has an effect if these quotes are user defined, i.e. not provided by a quote provider.                         Default is false.
     * @type {boolean}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly userDefinedOnly?: boolean

    /**
     * Retrieve quotes starting from the given date; max is 10 years. Default value is 6 years ago.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly from?: string

    /**
     * Retrieve quotes until the the given date; default: today.                              Format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly to?: string

    /**
     * Restrict quote retrieval to the given interval. Default is to ignore this parameter.
     * @type {'week' | 'month' | 'year'}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly interval?: ShowHistoricQuoteIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default value is current.                                 Ignored if no interval is given.                                 The position is defined as current: current day of week / month / year;                                  first: first day of week / month / year; last: last day of week / month / year or                                 the current day if the last day would fall after today.
     * @type {'current' | 'first' | 'last'}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly intervalPosition?: ShowHistoricQuoteIntervalPositionEnum

    /**
     * Do not return quotes, but instead return the percent change relative to the first quote                         / the previous quote. The following calculation methods are supported.                         \&#39;first\&#39; uses the earliest quote as reference and thus calculates first-to-interval changes.                         \&#39;previous\&#39; uses the previous quote as reference and thus calculates interval-to-interval changes.                         \&#39;currentToNow\&#39; uses the latest quote as reference and thus calculates interval-to-now changes.
     * @type {'first' | 'previous' | 'currentToNow'}
     * @memberof HistoricQuotesApiShowHistoricQuote
     */
    readonly inPercentFrom?: ShowHistoricQuoteInPercentFromEnum
}

/**
 * HistoricQuotesApi - object-oriented interface
 * @export
 * @class HistoricQuotesApi
 * @extends {BaseAPI}
 */
export class HistoricQuotesApi extends BaseAPI {
    /**
     * 
     * @summary Add or overwrite historic quotes manually. This operation should only be used if no automatic                      quote assignment can be done; i.e. no quotes data is available for the given investment.
     * @param {HistoricQuotesApiAddOrUpdateHistoricQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public addOrUpdateHistoricQuotes(requestParameters: HistoricQuotesApiAddOrUpdateHistoricQuotesRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).addOrUpdateHistoricQuotes(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Note: this is a Server-to-Server operation only! Add or overwrite historic quotes for an                     investment product manually. This operation should be used by other backends to maintain quote                     master data.
     * @param {HistoricQuotesApiAddOrUpdateProductQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public addOrUpdateProductQuotes(requestParameters: HistoricQuotesApiAddOrUpdateProductQuotesRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).addOrUpdateProductQuotes(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                 Get or search historic quotes
     * @param {HistoricQuotesApiHistoricQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public historicQuotes(requestParameters: HistoricQuotesApiHistoricQuotesRequest = {}, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).historicQuotes(requestParameters.accountId, requestParameters.investmentId, requestParameters.watchlistEntryId, requestParameters.tickerSymbol, requestParameters.includeIndexes, requestParameters.includeHistoricPositions, requestParameters.convertToCurrency, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve intraday quotes for the given ticker symbol by querying an external web-service.
     * @param {HistoricQuotesApiIntradayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public intraday(requestParameters: HistoricQuotesApiIntradayRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).intraday(requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.period, requestParameters.from, requestParameters.to, requestParameters.quoteCurrency, requestParameters.convertToCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED. Use /v1/historicQuotes/{quoteProvider}/{tickerSymbol} instead.                        Lookup historic quotes for the given ticker symbol by querying an external web-service.
     * @param {HistoricQuotesApiLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public lookup(requestParameters: HistoricQuotesApiLookupRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).lookup(requestParameters.tickerSymbol, requestParameters.quoteProvider, requestParameters.includeIndexes, requestParameters.quoteCurrency, requestParameters.convertToCurrency, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the given historic quote.
     * @param {HistoricQuotesApiRefreshHistoricQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public refreshHistoricQuote(requestParameters: HistoricQuotesApiRefreshHistoricQuoteRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).refreshHistoricQuote(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the given historic quote(s) by ISIN.
     * @param {HistoricQuotesApiRefreshHistoricQuotesByIsinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public refreshHistoricQuotesByIsin(requestParameters: HistoricQuotesApiRefreshHistoricQuotesByIsinRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).refreshHistoricQuotesByIsin(requestParameters.isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a historic quote with risk and yield statistics for a given ticker symbol
     * @param {HistoricQuotesApiShowHistoricQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricQuotesApi
     */
    public showHistoricQuote(requestParameters: HistoricQuotesApiShowHistoricQuoteRequest, options?: RawAxiosRequestConfig) {
        return HistoricQuotesApiFp(this.configuration).showHistoricQuote(requestParameters.quoteProvider, requestParameters.tickerSymbol, requestParameters.convertToCurrency, requestParameters.userDefinedOnly, requestParameters.from, requestParameters.to, requestParameters.interval, requestParameters.intervalPosition, requestParameters.inPercentFrom, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const HistoricQuotesIntervalEnum = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type HistoricQuotesIntervalEnum = typeof HistoricQuotesIntervalEnum[keyof typeof HistoricQuotesIntervalEnum];
/**
 * @export
 */
export const HistoricQuotesIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type HistoricQuotesIntervalPositionEnum = typeof HistoricQuotesIntervalPositionEnum[keyof typeof HistoricQuotesIntervalPositionEnum];
/**
 * @export
 */
export const HistoricQuotesInPercentFromEnum = {
    FIRST: 'first',
    PREVIOUS: 'previous',
    CURRENT_TO_NOW: 'currentToNow'
} as const;
export type HistoricQuotesInPercentFromEnum = typeof HistoricQuotesInPercentFromEnum[keyof typeof HistoricQuotesInPercentFromEnum];
/**
 * @export
 */
export const LookupIntervalEnum = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type LookupIntervalEnum = typeof LookupIntervalEnum[keyof typeof LookupIntervalEnum];
/**
 * @export
 */
export const LookupIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type LookupIntervalPositionEnum = typeof LookupIntervalPositionEnum[keyof typeof LookupIntervalPositionEnum];
/**
 * @export
 */
export const LookupInPercentFromEnum = {
    FIRST: 'first',
    PREVIOUS: 'previous',
    CURRENT_TO_NOW: 'currentToNow'
} as const;
export type LookupInPercentFromEnum = typeof LookupInPercentFromEnum[keyof typeof LookupInPercentFromEnum];
/**
 * @export
 */
export const ShowHistoricQuoteIntervalEnum = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type ShowHistoricQuoteIntervalEnum = typeof ShowHistoricQuoteIntervalEnum[keyof typeof ShowHistoricQuoteIntervalEnum];
/**
 * @export
 */
export const ShowHistoricQuoteIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type ShowHistoricQuoteIntervalPositionEnum = typeof ShowHistoricQuoteIntervalPositionEnum[keyof typeof ShowHistoricQuoteIntervalPositionEnum];
/**
 * @export
 */
export const ShowHistoricQuoteInPercentFromEnum = {
    FIRST: 'first',
    PREVIOUS: 'previous',
    CURRENT_TO_NOW: 'currentToNow'
} as const;
export type ShowHistoricQuoteInPercentFromEnum = typeof ShowHistoricQuoteInPercentFromEnum[keyof typeof ShowHistoricQuoteInPercentFromEnum];
