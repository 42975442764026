import { differenceInMonths, format, isAfter } from "date-fns";
import { useMemo } from "react";
import { alpha } from "@mui/material";
import {
  useDividendsHistory,
  useDividendsPortfolioYield,
  useDividendsPrediction,
} from "@features/dividendPlanner/hooks/useDividends";
import { calculateDataForCurrentYearChart } from "@components/Chart/DashboardDividendsChart/helpers/calculateDataForCurrentYearChart";
import { calculateHistoryData } from "@components/Chart/DashboardDividendsChart/helpers/calculateHistoryData";
import { BarInvestmentDataProps } from "@components/Chart/DashboardDividendsChart/types";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import {
  usePublicDividendHistory,
  usePublicDividendPrediction,
  usePublicDividendYield,
} from "@generated/apiv1/hooks";

export type DividendChartData = {
  dividends: BarInvestmentDataProps[];
  y: number;
  name: string;
  year: number;
  color: string;
};

export const useDividendsChartData = ({
  accountIds,
  startDate,
  interval,
  enabled = true,
}: {
  accountIds: number[];
  startDate: Date;
  interval: IntervalType;
  enabled?: boolean;
}): {
  chartData: DividendChartData[];
  isLoading: boolean;
  isFetched: boolean;
} => {
  const {
    data: historyData,
    isFetching: isLoadingDividendsHistory,
    isFetched: isDividendsHistoryFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsHistory,
    usePublicMethod: usePublicDividendHistory,
    params: {
      accountId: accountIds,
      cutoffDate: format(startDate, "yyyy-MM-dd"),
    },
    options: {
      enabled: Boolean(accountIds.length) && enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const {
    data: dividendYieldActualData,
    isFetching: isLoadingYieldActualData,
    isFetched: isYieldActualDataFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsPortfolioYield,
    usePublicMethod: usePublicDividendYield,
    params: {
      calculationType: "actual" as "actual" | "predicted",
      accountId: accountIds,
      from: format(startDate, "yyyy-MM-dd"),
    },
    options: {
      enabled: Boolean(accountIds.length) && enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const {
    data: dividendYieldPredictedData,
    isFetching: isLoadingYieldPredictedData,
    isFetched: isYieldPredictedDataFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsPortfolioYield,
    usePublicMethod: usePublicDividendYield,
    params: {
      calculationType: "predicted" as "actual" | "predicted",
      accountId: accountIds,
      from: format(startDate, "yyyy-MM-dd"),
    },
    options: {
      enabled: Boolean(accountIds.length) && enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const {
    data: predictedData,
    isFetching: isLoadingPrediction,
    isFetched: isPredictionFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsPrediction,
    usePublicMethod: usePublicDividendPrediction,
    params: {
      predictYears: 1,
      accountId: accountIds,
    },
    options: {
      enabled: Boolean(accountIds.length) && enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const { chartBarsData } = useMemo(
    () =>
      calculateDataForCurrentYearChart({
        historyData,
        predictedData,
        dividendYieldData: dividendYieldPredictedData?.dividendYields,
      }),
    [historyData, predictedData, dividendYieldPredictedData?.dividendYields]
  );

  const monthsAmount = differenceInMonths(new Date(), startDate);
  const yearsAmount = monthsAmount / 12;
  const isLoading =
    isLoadingPrediction ||
    isLoadingDividendsHistory ||
    isLoadingYieldActualData ||
    isLoadingYieldPredictedData;

  const isFetched =
    isDividendsHistoryFetched &&
    isYieldActualDataFetched &&
    isYieldPredictedDataFetched &&
    isPredictionFetched;

  if (yearsAmount > 1) {
    const historyDataPrepared = calculateHistoryData(
      historyData,
      dividendYieldActualData
    );

    return {
      chartData: historyDataPrepared
        .map((item) => ({
          dividends: item.dividends,
          y: item.currentYearOverallDividends,
          name: format(new Date(item.year, 0), "yyyy"),
          year: item.year,
          color:
            item.currentYearOverallDividends === 0
              ? "#F8F8FD"
              : alpha("#4D6BDD", 0.3),
        }))
        .sort((a, b) => {
          return a.year! - b.year!;
        }),
      isLoading,
      isFetched,
    };
  }

  return {
    chartData: chartBarsData
      .filter((item) =>
        interval === "YTD"
          ? !isAfter(new Date(item.year!, item.month), new Date())
          : true
      )
      .map((item, index) => ({
        dividends:
          item.currentBarInvestmentsData?.map((inv) => ({
            ...inv,
            name: inv.invRefName,
          })) || [],
        y: item.current,
        name: format(new Date(2021, index), "MMM").replace(".", ""),
        year: item.year!,
        color: item.current === 0 ? "#F8F8FD" : alpha("#4D6BDD", 0.3),
      }))
      .sort((a, b) => {
        return a.year! - b.year!;
      }),
    isLoading,
    isFetched,
  };
};
