import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiGainersAndLosersRequest,
  PageableInvestmentList,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const gainersAndLosers = async (
  token: string | undefined,
  params: InvestmentsApiGainersAndLosersRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.gainersAndLosers(params);
  return data;
};

export const useGainersAndLosers = (
  params: InvestmentsApiGainersAndLosersRequest,
  options?: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_GAINERS_AND_LOSERS, params],
    ({ token }) => gainersAndLosers(token, params),
    options
  );
};
