import React from "react";
import styled from "@emotion/styled";
import { TableCell, TableCellProps, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ReactComponent as SortArrow } from "@icons/sort-down.svg";
import { TypographyWithInfoIcon } from "@components/TypographyWithInfoIcon";

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.icon.disabled};
`;

interface SortableIconProps {
  sortByName?: string;
  sortDirection?: TableSortDirection;
  sortField?: string;
}

const ArrowUp = styled(SortArrow)`
  color: ${({ theme }) => theme.palette.icon.secondary};
  transform: rotate(180deg);
`;

const ArrowDown = styled(ArrowUp)`
  transform: rotate(360deg);
  color: ${({ theme }) => theme.palette.icon.secondary};
`;

const SortableIcon = ({
  sortDirection = "NONE",
  sortByName,
  sortField,
}: SortableIconProps) => {
  const active = sortField === sortByName;
  const theme = useTheme();

  return (
    <IconsWrapper>
      {!active && <></>}
      {active && sortDirection === "ASC" && (
        <ArrowUp color={theme.palette.icon.secondary} />
      )}
      {active && sortDirection === "DESC" && (
        <ArrowDown color={theme.palette.icon.secondary} />
      )}
    </IconsWrapper>
  );
};

const SortableIconContainer = styled.div<{
  align: SortableTableHeaderProps["align"];
  isClickable?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "left"
      ? "flex-start"
      : "center"};

  & .typography-info {
    white-space: nowrap;
  }
  & .info-icon {
    display: inline-flex;
    margin-top: -2px;
  }
`;

const StyledTableCell = styled(TableCell)<
  Pick<SortableTableHeaderProps, "position">
>`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: ${({ theme }) => theme.spacing(4, 3)};

  ${({ position, theme }) => {
    if (position === "first") return `padding-left: ${theme.spacing(0)}`;
    if (position === "last") return `padding-right: ${theme.spacing(0)}`;
  }}
`;

export interface SortableTableHeaderProps
  extends SortableIconProps,
    Omit<
      TableCellProps,
      "sortDirection" | "onClick" | "sortByName" | "sortField"
    > {
  text: string;
  position?: "first" | "last" | "center";
  isSortable?: boolean;
  onClick?: (sortByName: string) => void;
  tooltip?: string;
}

export const SortableTableHeader = (props: SortableTableHeaderProps) => {
  const {
    text,
    align,
    sortByName,
    sortField,
    sortDirection,
    isSortable = false,
    position = "center",
    tooltip,
    onClick = () => {},
    ...rest
  } = props;

  const handleClick = () => {
    onClick(sortByName!);
  };

  const isActive = sortField === sortByName;
  const cellAlign: TableCellProps["align"] =
    align || position === "last" ? "right" : "left";

  return (
    <StyledTableCell
      position={position}
      variant="head"
      align={cellAlign}
      onClick={sortByName ? handleClick : undefined}
      {...rest}
    >
      <SortableIconContainer align={cellAlign} isClickable={isSortable}>
        {tooltip ? (
          <TypographyWithInfoIcon
            variant="caption"
            color="textPrimary"
            fontWeight={600}
            fontSize={12}
            noWrap
            tooltipText={tooltip}
            iconSize={16}
          >
            {text}
          </TypographyWithInfoIcon>
        ) : (
          <Typography
            variant="caption"
            color="textPrimary"
            fontWeight={600}
            fontSize={12}
            noWrap
          >
            {text}
          </Typography>
        )}

        {isSortable && isActive && (
          <SortableIcon
            sortField={sortField}
            sortByName={sortByName}
            sortDirection={sortDirection}
          />
        )}
      </SortableIconContainer>
    </StyledTableCell>
  );
};
