import React, { ReactNode, useState } from "react";
import styled from "@emotion/styled";
import {
  motion,
  useAnimation,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useMeasure } from "react-use";
import { ReactComponent as LeftIcon } from "@icons/controls-chevron-left.svg";
import { ReactComponent as LeftRight } from "@icons/controls-chevron-right.svg";

const ChevronLeftIcon = styled(LeftIcon)`
  cursor: pointer;
  &:hover path {
    stroke: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const ChevronRightIcon = styled(LeftRight)`
  cursor: pointer;
  &:hover path {
    stroke: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const OuterContainer = styled.div`
  position: relative;
  height: 170px;
  overflow: hidden;
  max-width: 100%;
`;

const Container = styled(motion.div)`
  height: 170px;
  display: flex;
  width: min-content;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const Left = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 4%,
    ${({ theme }) => theme.palette.common.white} 99%
  );
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 194px;
  height: 170px;
  padding-left: 55px;
`;

const Right = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 4%,
    ${({ theme }) => theme.palette.common.white} 99%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 194px;
  height: 170px;
  padding-right: 55px;
`;

export interface CarouselProps {
  children: ReactNode;
  hideArrows?: boolean;
}

const STEP = 300;

export const Carousel = ({ children, hideArrows = false }: CarouselProps) => {
  const [showRight, setShowRight] = useState(true);
  const [showLeft, setShowLeft] = useState(false);
  const [refContainer, { width: containerWidth }] = useMeasure();
  const [refOuterContainer, { width: outerContainerWidth }] = useMeasure();
  const currentX = useMotionValue(0);
  const controls = useAnimation();
  const rightOpacity = useTransform(
    currentX,
    [
      outerContainerWidth - containerWidth + STEP / 10,
      outerContainerWidth - containerWidth,
    ],
    [1, 0]
  );
  const leftOpacity = useTransform(currentX, [-STEP / 10, 0], [1, 0]);

  currentX.onChange((x) => {
    if (x === 0) {
      setShowLeft(false);
    } else {
      setShowLeft(true);
    }
    if (x === outerContainerWidth - containerWidth) {
      setShowRight(false);
    } else {
      setShowRight(true);
    }
  });

  const handleRightClick = () => {
    const offset = currentX.get() - STEP;

    if (Math.abs(offset) > Math.abs(outerContainerWidth - containerWidth)) {
      controls.start({
        x: outerContainerWidth - containerWidth,
      });
      return;
    }
    controls.start({
      x: currentX.get() - STEP,
      transition: { duration: 0.5, type: "spring" },
    });
  };

  const handleLeftClick = () => {
    const offset = currentX.get() + STEP;

    if (offset > 0) {
      controls.start({
        x: 0,
      });
      return;
    }
    controls.start({
      x: currentX.get() + STEP,
      transition: { duration: 0.5, type: "spring" },
    });
  };

  return (
    <OuterContainer ref={refOuterContainer as any}>
      <Container
        animate={controls}
        style={{ x: currentX }}
        initial={false}
        custom={currentX}
        ref={refContainer as any}
        drag="x"
        dragConstraints={{
          right: 0,
          left: outerContainerWidth - containerWidth,
        }}
      >
        {children}
      </Container>
      {!hideArrows && showLeft && (
        <Left onClick={handleLeftClick} style={{ opacity: leftOpacity }}>
          <ChevronLeftIcon />
        </Left>
      )}
      {!hideArrows && showRight && (
        <Right onClick={handleRightClick} style={{ opacity: rightOpacity }}>
          <ChevronRightIcon />
        </Right>
      )}
    </OuterContainer>
  );
};
