import { UseMutationOptions } from "react-query";
import { DividendMasterDataRecordsApiCloneDividendsRequest } from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const cloneDividends = async (
  token: string | undefined,
  params: DividendMasterDataRecordsApiCloneDividendsRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.cloneDividends(params);
  return data;
};

export const useCloneDividends = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    DividendMasterDataRecordsApiCloneDividendsRequest
  >
) => {
  return useAuthMutation(cloneDividends, options, false);
};
