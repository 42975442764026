import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useChartColors } from "@hooks";
import { ChartBreakdown } from "@features/analysis/components/types";
import { ReactComponent as ArrowRight } from "@icons/chevron-crumb.svg";
import { toPercent } from "@helpers";
import { useMyself } from "@api";
import { LegendItem } from "./LegendItem";

type Props = {
  chartData?: ChartBreakdown[];
  selectedItem?: ChartBreakdown | null;
  breadcrumbs?: {
    onClick: () => void;
    name: string;
  }[];
  totalValue?: number;
  colors?: string[];
  currentTotalValue?: number;
  chartTitle?: string;
  onMouseOver?: (item: ChartBreakdown | null) => void;
  onClick?: (item: ChartBreakdown | null) => void;
  showInvestmentAmount?: boolean;
  renderAmount?: (item: ChartBreakdown | null) => React.ReactNode;
  hideForNonPro?: boolean;
};

export const LegendLayout = ({
  selectedItem,
  breadcrumbs,
  chartTitle,
  colors: actualColors,
  chartData,
  totalValue,
  currentTotalValue,
  onMouseOver,
  onClick,
  showInvestmentAmount,
  hideForNonPro = false,
  renderAmount,
}: Props) => {
  const classificationsColors = useChartColors("classificationChart");
  const colors = actualColors || classificationsColors;
  const theme = useTheme();
  const isInDeepDiveMode = Boolean(breadcrumbs?.length);
  const { data, isFetched } = useMyself({
    enabled: hideForNonPro,
  });
  const isUserPro = data?.isUserPro;

  if (hideForNonPro && !isFetched) {
    return null;
  }

  return (
    <Box>
      {isInDeepDiveMode && (
        <Box
          display="flex"
          alignItems="center"
          color="textSecondary"
          gap={1}
          mb={2.5}
        >
          {breadcrumbs?.map((breadcrumb) => (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              key={`breadcrumbs-${breadcrumb.name}`}
              onClick={breadcrumb.onClick}
              sx={{
                cursor: "pointer",
                color: theme.palette.text.secondary + " !important",
                "&:hover": {
                  color: theme.palette.primary.main + " !important",
                },
              }}
              zIndex={1}
            >
              <Box display="flex" sx={{ transform: "rotate(180deg)" }}>
                <ArrowRight />
              </Box>
              <Typography variant="body1" fontSize="18px">
                {breadcrumb.name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {chartTitle && (
          <Typography variant="subtitle1" mb={2.5}>
            {chartTitle}
          </Typography>
        )}
        {isInDeepDiveMode && (
          <Typography variant="subtitle1" mb={2.5}>
            {toPercent((currentTotalValue ?? 0) / (totalValue ?? 1))}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          borderTop: (theme) =>
            chartTitle ? `1px solid ${theme.palette.border.secondary}` : "none",
        }}
      >
        {chartData?.map((item, index) => (
          <LegendItem
            key={item.id || item.uid}
            isSelected={selectedItem?.uid === item.uid}
            item={item}
            isLast={index === chartData.length - 1}
            totalValue={totalValue ?? 0}
            color={colors[index % colors.length]}
            onMouseOver={onMouseOver}
            onClick={item.hasChildren ? onClick : undefined}
            showInvestmentAmount={showInvestmentAmount}
            blurData={hideForNonPro && !isUserPro}
            renderAmount={renderAmount}
          />
        ))}
      </Box>
    </Box>
  );
};
