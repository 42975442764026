import { UseMutationOptions } from "react-query";
import { MailingApiContactUserIdRequest } from "@generated/apiv1";

import { buildMailingApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const contactUserId = async (
  token: string | undefined,
  params: MailingApiContactUserIdRequest
) => {
  const apiInstance = buildMailingApi(token);
  const { data } = await apiInstance.contactUserId(params);
  return data;
};

export const useContactUserId = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    MailingApiContactUserIdRequest
  >
) => {
  return useAuthMutation(contactUserId, options, false);
};
