import { UseMutationOptions } from "react-query";
import { UsersApiUpdateUserRequest, User } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateUser = async (
  token: string | undefined,
  params: UsersApiUpdateUserRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.updateUser(params);
  return data;
};

export const useUpdateUser = (
  options?: UseMutationOptions<User, AxiosApiError, UsersApiUpdateUserRequest>
) => {
  return useAuthMutation(updateUser, options, false);
};
