/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { InvestmentReference } from './investment-reference';
// May contain unused imports in some cases
// @ts-ignore
import { LinkedTransactionConsistencyWarning } from './linked-transaction-consistency-warning';
// May contain unused imports in some cases
// @ts-ignore
import { RealizedGain } from './realized-gain';
// May contain unused imports in some cases
// @ts-ignore
import { SplitExecution } from './split-execution';

/**
 * 
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Booking
     */
    'id': number;
    /**
     * ID assigned by the bank or the multi-banking provider
     * @type {string}
     * @memberof Booking
     */
    'externalId'?: string;
    /**
     * DEPRECATED: use externalId instead. ID assigned by the banking provider in case          the booking was created from a bank transaction
     * @type {number}
     * @memberof Booking
     */
    'finApiId'?: number;
    /**
     * The data source that created this booking. This value will be auto-assigned by             automated import routines.
     * @type {string}
     * @memberof Booking
     */
    'dataSource'?: BookingDataSourceEnum;
    /**
     * If this is a cancellation / was cancelled: ID of the cancelling counterpart
     * @type {string}
     * @memberof Booking
     */
    'cancellationId'?: string;
    /**
     * ID of the depot (an account entity) the booking belongs to
     * @type {number}
     * @memberof Booking
     */
    'depotId': number;
    /**
     * Type of the booking
     * @type {string}
     * @memberof Booking
     */
    'type': BookingTypeEnum;
    /**
     * The value date (Wertstellungsdatum)
     * @type {string}
     * @memberof Booking
     */
    'valueDate': string;
    /**
     * The banks booking date (Buchungsdatum)
     * @type {string}
     * @memberof Booking
     */
    'bankBookingDate': string;
    /**
     * The data consistency status of this booking, allowed values are         EXACT_NOL - the bank or broker provided a cash amount and the exact number of lots moved by this order. This             is the best case and yields precise data.         BOOKING_WITH_NOL_ESTIMATED - the bank or broker provided the cash amount, but NOT the number of lots             moved by this order. Thus the number of lots was estimated based on historic quote data. This generally             works well if there are not too many orders.         NEGATIVE_NOL_DELTA_RESOLUTION - this booking was created to avoid a drop of the the investments number of lots             below zero at any point in time. Such a case may happen if data is missing or an estimation is incorrect.         DELTA_RESOLUTION - there was a delta (also called tracking error) between the number of lots calculated by             the order history and between the number of lots reported by the bank. Such tracking errors may occur             due to data inconsistencies or due to capital measures such as carve outs, depot transfers or splits.
     * @type {string}
     * @memberof Booking
     */
    'dataConsistencyStatus': BookingDataConsistencyStatusEnum;
    /**
     * DEPRECATED - use dataConsistencyStatus.         The booking date was estimated; in this case valueDate equals bankBookingDate and the         according values were estimated based on the average entry quote delivered by the bank.
     * @type {boolean}
     * @memberof Booking
     */
    'dateIsEstimate': boolean;
    /**
     * 
     * @type {InvestmentReference}
     * @memberof Booking
     */
    'investment': InvestmentReference;
    /**
     * The number of lots moved in the booking
     * @type {number}
     * @memberof Booking
     */
    'numberOfLots': number;
    /**
     * The security price / amount received; in investment currency
     * @type {number}
     * @memberof Booking
     */
    'securityPrice': number;
    /**
     * The entry quote or booking quote; in investment currency.                                This quote may be different than the security price                                if we received stocks for free; e.g. for retained dividends
     * @type {number}
     * @memberof Booking
     */
    'entryQuote'?: number;
    /**
     * The bookings exchange rate (to EUR)
     * @type {number}
     * @memberof Booking
     */
    'exchangeRate': number;
    /**
     * The commission payed; in investment currency
     * @type {number}
     * @memberof Booking
     */
    'commission'?: number;
    /**
     * The amount of tax payed; in investment currency
     * @type {number}
     * @memberof Booking
     */
    'taxAmount'?: number;
    /**
     * The total amount moved in this booking; in investment currency
     * @type {number}
     * @memberof Booking
     */
    'amount': number;
    /**
     * A comment or reason entered by the user
     * @type {string}
     * @memberof Booking
     */
    'comment'?: string;
    /**
     * 
     * @type {RealizedGain}
     * @memberof Booking
     */
    'realizedGain'?: RealizedGain;
    /**
     * A list of executed splits; each referencing the original split transaction. Note that             including split data may have negative performance impact for large batch requests. We thus recommend including             split data when filtering bookings for a single investment. Use the include: split request parameter.
     * @type {Array<SplitExecution>}
     * @memberof Booking
     */
    'splits': Array<SplitExecution>;
    /**
     * 
     * @type {LinkedTransactionConsistencyWarning}
     * @memberof Booking
     */
    'linkedTransactionConsistencyWarning'?: LinkedTransactionConsistencyWarning;
}

export const BookingDataSourceEnum = {
    BANKING_API: 'BANKING_API',
    PDF: 'PDF',
    CSV: 'CSV',
    USER_INPUT: 'USER_INPUT'
} as const;

export type BookingDataSourceEnum = typeof BookingDataSourceEnum[keyof typeof BookingDataSourceEnum];
export const BookingTypeEnum = {
    BUY: 'buy',
    SELL: 'sell',
    DIVIDEND: 'dividend',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out',
    SPLIT: 'split',
    REVERSE_SPLIT: 'reverse_split',
    SWITCH: 'switch',
    RETAINED_DIVIDEND: 'retained_dividend',
    PURCHASE_RIGHT: 'purchase_right',
    CARVE_OUT: 'carve_out'
} as const;

export type BookingTypeEnum = typeof BookingTypeEnum[keyof typeof BookingTypeEnum];
export const BookingDataConsistencyStatusEnum = {
    EXACT_NOL: 'EXACT_NOL',
    BOOKING_WITH_NOL_ESTIMATED: 'BOOKING_WITH_NOL_ESTIMATED',
    NEGATIVE_NOL_DELTA_RESOLUTION: 'NEGATIVE_NOL_DELTA_RESOLUTION',
    DELTA_RESOLUTION: 'DELTA_RESOLUTION'
} as const;

export type BookingDataConsistencyStatusEnum = typeof BookingDataConsistencyStatusEnum[keyof typeof BookingDataConsistencyStatusEnum];


