/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Address } from './address';
// May contain unused imports in some cases
// @ts-ignore
import { BankAccount } from './bank-account';
// May contain unused imports in some cases
// @ts-ignore
import { CustomFeeAgreement } from './custom-fee-agreement';
// May contain unused imports in some cases
// @ts-ignore
import { InvestmentExperience } from './investment-experience';
// May contain unused imports in some cases
// @ts-ignore
import { NaturalPerson } from './natural-person';
// May contain unused imports in some cases
// @ts-ignore
import { UserReference } from './user-reference';

/**
 * 
 * @export
 * @interface AccountHolder
 */
export interface AccountHolder {
    /**
     * Technical ID
     * @type {number}
     * @memberof AccountHolder
     */
    'id': number;
    /**
     * 
     * @type {UserReference}
     * @memberof AccountHolder
     */
    'userReference': UserReference;
    /**
     * An external reference; e.g. a pool ID.
     * @type {string}
     * @memberof AccountHolder
     */
    'externalId'?: string;
    /**
     * The account holder type; default is PRIVATE
     * @type {string}
     * @memberof AccountHolder
     */
    'type': AccountHolderTypeEnum;
    /**
     * An affiliate partner id; e.g. com.rentablo.partnerX.
     * @type {string}
     * @memberof AccountHolder
     */
    'affiliatePartnerId'?: string;
    /**
     * Is account synchronization via Netfonds API enabled for this account holder?
     * @type {boolean}
     * @memberof AccountHolder
     */
    'isSyncEnabled': boolean;
    /**
     * Is the users consent required to enable synchronization for this account holder?
     * @type {boolean}
     * @memberof AccountHolder
     */
    'isSyncConsentRequired': boolean;
    /**
     * Timestamp of the last sync attempt
     * @type {string}
     * @memberof AccountHolder
     */
    'lastSyncAttempt'?: string;
    /**
     * The number of subscription contracts that were received by us; the name fields of                                account holders with active or terminated subscriptions may not be changed.
     * @type {number}
     * @memberof AccountHolder
     */
    'numberOfActiveOrTerminatedSubscriptions': number;
    /**
     * A list of all natural persons this account holder is made of.
     * @type {Array<NaturalPerson>}
     * @memberof AccountHolder
     */
    'naturalPersons'?: Array<NaturalPerson>;
    /**
     * 
     * @type {BankAccount}
     * @memberof AccountHolder
     */
    'referenceAccount'?: BankAccount;
    /**
     * 
     * @type {Address}
     * @memberof AccountHolder
     */
    'additionalMailingAddress'?: Address;
    /**
     * 
     * @type {CustomFeeAgreement}
     * @memberof AccountHolder
     */
    'customFeeAgreement'?: CustomFeeAgreement;
    /**
     * 
     * @type {InvestmentExperience}
     * @memberof AccountHolder
     */
    'investmentExperience'?: InvestmentExperience;
}

export const AccountHolderTypeEnum = {
    PRIVATE: 'PRIVATE',
    COMPANY: 'COMPANY'
} as const;

export type AccountHolderTypeEnum = typeof AccountHolderTypeEnum[keyof typeof AccountHolderTypeEnum];


