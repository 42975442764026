import { useState } from "react";
import { intervalToTimeRange } from "@helpers";

export const useSelectInterval = (
  defaultIntervalType?: IntervalType,
  startDate?: Date,
  endDate?: Date
) => {
  const [intervalType, setIntervalType] = useState<IntervalType>(
    defaultIntervalType || "oneYear"
  );
  const [range, setRange] = useState<RangeType>(
    intervalToTimeRange(defaultIntervalType || "oneYear", startDate, endDate)
  );

  const handleSelectInterval = (type: IntervalType) => {
    setIntervalType(type);
    const newRange = intervalToTimeRange(type);
    setRange(newRange);
    return newRange;
  };

  const handleSelectCustomInterval = (from: Date, to: Date) => {
    setIntervalType("custom");
    const newRange = { startDate: from, endDate: to };
    setRange(newRange);
    return newRange;
  };

  return {
    intervalType,
    range,
    selectInterval: handleSelectInterval,
    selectCustomInterval: handleSelectCustomInterval,
  };
};
