import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Link,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { SymbolV2 } from "@generated/apiv1";
import { ReactComponent as ArrowDownIcon } from "@icons/chevron-down.svg";
import { SecurityImage } from "@components/SecurityImage";

const StyledTable = styled(Table)(({ theme }) => ({
  tableLayout: "fixed",
  "& p": {
    lineHeight: "16px",
  },

  "& .MuiTableCell-root": {
    padding: theme.spacing(3),
    borderColor: theme.palette.border.secondary,
  },
  "& .MuiTableRow-root .MuiTableCell-root:first-of-type": {
    paddingLeft: theme.spacing(0),
  },
  "& .MuiTableRow-root .MuiTableCell-root:last-of-type": {
    paddingRight: theme.spacing(0),
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiTableHead-root:first-of-type .MuiTableCell-root": {
      padding: theme.spacing(0),
    },

    "& .MuiTableRow-head .MuiTableCell-root": {
      borderBottom: "none",
      paddingBottom: theme.spacing(0),
    },
  },
}));

const TypographyLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,

  "&:hover": {
    textDecoration: "underline",
  },
}));

type Props = {
  symbols?: SymbolV2[];
  isLoading: boolean;
  onSymbolSelect: (symbol: SymbolV2) => void;
  logos?: Record<string, string | null | undefined>;
  searchHeight?: number;
  shouldShowExpand?: boolean;
};

export const SecuritiesSearchResults = ({
  symbols,
  onSymbolSelect,
  isLoading,
  shouldShowExpand,
  logos = {},
  searchHeight,
}: Props) => {
  const { t } = useTranslation();
  const searchResultsRef = useRef() as React.RefObject<HTMLDivElement>;
  const [showMore, setShowMore] = useState<string | null>(null);
  const [rightPadding, setRightPadding] = useState(0);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const groupedSymbols = useMemo(() => {
    const groups = groupBy(
      symbols?.filter((symbol) => symbol.type !== "index") || [],
      "type"
    );
    return showMore && shouldShowExpand
      ? { [showMore]: groups[showMore] }
      : groups;
  }, [symbols, showMore, shouldShowExpand]);

  useEffect(() => {
    if (!searchResultsRef?.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!searchResultsRef?.current) return;

      if (
        searchResultsRef.current.scrollHeight >
        searchResultsRef.current.offsetHeight
      ) {
        setRightPadding(3);
      }
    });
    resizeObserver.observe(searchResultsRef.current);

    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsRef.current]);

  if (isLoading || !symbols?.length) {
    return (
      <Paper
        sx={{
          width: "100%",
          marginTop: (theme) => theme.spacing(2.5),
          padding: (theme) => theme.spacing(4),
        }}
      >
        <Typography variant="body1">
          {isLoading
            ? t("securitiesSearch.loading")
            : t("securitiesSearch.noResults")}
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        width: "100%",
        maxHeight: searchHeight || "calc(100vh - 400px)",
        marginTop: (theme) => theme.spacing(2.5),
        display: "flex",
      }}
    >
      <Box p={4} display="flex" flexDirection="column" gap={3} width="100%">
        {showMore && shouldShowExpand && (
          <Box
            display="flex"
            alignItems="center"
            color="primary.main"
            onClick={() => setShowMore(null)}
            gap={1}
            sx={{
              cursor: "pointer",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                transform: "rotate(90deg)",
              }}
            >
              <ArrowDownIcon width="16px" height="16px" />
            </Box>
            <TypographyLink variant="caption">
              {t("securitiesSearch.back")}
            </TypographyLink>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          ref={searchResultsRef}
          gap={3}
          height="100%"
          pr={rightPadding}
          sx={{
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Box>
            <StyledTable stickyHeader={Boolean(showMore && shouldShowExpand)}>
              {Object.keys(groupedSymbols).map((group) => {
                const children =
                  showMore || !shouldShowExpand
                    ? groupedSymbols[group]
                    : groupedSymbols[group]?.slice(0, 3);

                return (
                  <React.Fragment key={group}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: isLessMd ? "50%" : "60%",
                          }}
                          colSpan={isLessSm ? 3 : 1}
                        >
                          <Typography variant="body1">
                            {t(`investmentTypeClassification.types.${group}`)}
                          </Typography>
                        </TableCell>
                        {!isLessSm && (
                          <>
                            <TableCell
                              align="right"
                              style={{ maxWidth: "20%" }}
                            >
                              <Typography
                                variant="overline"
                                color="textSecondary"
                                fontWeight={400}
                                textAlign="right"
                              >
                                {t("securitiesSearch.isin")}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ maxWidth: "20%" }}
                            >
                              <Typography
                                variant="overline"
                                color="textSecondary"
                                fontWeight={400}
                                textAlign="right"
                                textTransform="none"
                              >
                                {t("securitiesSearch.type")}
                              </Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {children.map((symbol, index) => {
                        const isLastChild = index === children.length - 1;
                        const style = isLastChild
                          ? {
                              borderBottom: isLastChild
                                ? "none"
                                : (theme: Theme) =>
                                    `1px solid ${theme.palette.border.secondary}`,
                            }
                          : {};
                        const image = logos[symbol.isin || symbol.id];

                        return (
                          <TableRow key={symbol.id}>
                            <TableCell
                              sx={style}
                              style={{
                                maxWidth: isLessMd ? "50%" : "60%",
                                width: "100%",
                              }}
                              colSpan={isLessSm ? 3 : 1}
                            >
                              <Box display="flex" alignItems="center" gap={2}>
                                <SecurityImage
                                  width={20}
                                  height={20}
                                  src={image}
                                  alt={symbol.isin || symbol.id}
                                />

                                <Link onClick={() => onSymbolSelect(symbol)}>
                                  <Typography
                                    variant="caption"
                                    fontWeight={400}
                                    noWrap
                                  >
                                    {truncate(symbol.name, {
                                      length: isLessMd ? 30 : 50,
                                    })}
                                  </Typography>
                                </Link>
                              </Box>
                            </TableCell>
                            {!isLessSm && (
                              <>
                                <TableCell
                                  align="right"
                                  sx={style}
                                  style={{ maxWidth: "20%" }}
                                >
                                  <Typography
                                    variant="caption"
                                    fontWeight={400}
                                    textAlign="right"
                                  >
                                    {symbol.isin ||
                                      (symbol.id
                                        ? String(symbol.id).split("_")[0]
                                        : undefined)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={style}
                                  style={{ maxWidth: "20%" }}
                                >
                                  <Typography
                                    variant="caption"
                                    fontWeight={400}
                                    textAlign="right"
                                    sx={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {t(
                                      `investmentTypeClassification.types.${symbol.type}`
                                    )}
                                  </Typography>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}

                      {groupedSymbols[group]?.length > 3 &&
                        !showMore &&
                        shouldShowExpand && (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              sx={{
                                borderBottom: "none",
                                borderTop: (theme) =>
                                  `1px solid ${theme.palette.border.secondary}`,
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                color="primary.main"
                                onClick={() => setShowMore(group)}
                                gap={1}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <TypographyLink variant="caption">
                                  {t("securitiesSearch.showMore")}
                                </TypographyLink>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{
                                    transform: "rotate(-90deg)",
                                  }}
                                >
                                  <ArrowDownIcon width="16px" height="16px" />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </React.Fragment>
                );
              })}
            </StyledTable>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
