import { UseQueryOptions } from "react-query";
import {
  DividendsApiListAndSearchAllDividendsRequest,
  DividendList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllDividends = async (
  token: string | undefined,
  params?: DividendsApiListAndSearchAllDividendsRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.listAndSearchAllDividends(params);
  return data;
};

export const useListAndSearchAllDividends = (
  params?: DividendsApiListAndSearchAllDividendsRequest,
  options?: UseQueryOptions<DividendList, AxiosApiError, DividendList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_LIST_AND_SEARCH_ALL_DIVIDENDS, params],
    ({ token }) => listAndSearchAllDividends(token, params),
    options
  );
};
