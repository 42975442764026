import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Typography, useMediaQuery, Theme, Box, Button } from "@mui/material";
import truncate from "lodash/truncate";
import { format } from "date-fns";
import { getValueByInvestmentAndBookingType } from "@helpers/getValueByInvestmentAndBookingType";
import {
  PageLayout,
  Tabs,
  Tab,
  ConfirmDeleteDialog,
  ComparisonValue,
  GainLossPercents,
} from "@components";
import { PrimaryActionWithMenu } from "@components/common/navigation";
import { ReactComponent as ChevronIcon } from "@icons/chevron.svg";
import { useDeleteInvestment } from "@api/v1/hooks/useInvestment";
import { useAccount } from "@api/v1/hooks/useAccount";
import { SelectComparison } from "@components/Chart/BaseChartTools";
import { useMyself } from "@api";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { useRangeSelectControl } from "@hooks";
import { SecuritiesChart } from "@components/Chart/SecuritiesChart/SecuritiesChart";
import {
  useBatchInvestmentPerformance,
  useInvestmentsId,
  useTradingGainForInvestment,
} from "@generated/apiv1/hooks";
import { intervalTitle } from "@features/investments/components/Assets/YTDPercents";
import { SecurityImage } from "@components/SecurityImage";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { InvestmentsActionButton } from "@features/investments/components/InvestmentsActionButton";
import { useInvestmentPerformance } from "../../investment/hooks/useInvestmentPerformance";
import { FundamentalsPageByInvestmentId } from "../../fundamentals/FundamentalsPageByInvestmentId";
import { InvestmentProtocolContainer } from "./components/InvestmentProtocolContainer";
import { DividendsTableContainer } from "./components/DividendsTableContainer";
import { InvestmentMenu } from "./components/InvestmentMenu";
import { PerformanceOverview } from "./components/PerformanceOverview";
import { useChartData } from "./hooks/useChartData";

const RedirectToETFButton = ({ isin }: { isin: string }) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <Button
      variant="text"
      color="primary"
      fullWidth={isSm}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.finanzfluss.de/informer/etf/${isin.toLowerCase()}`}
      endIcon={
        <Box
          display="flex"
          sx={{
            transform: "rotate(180deg)",
            transformOrigin: "center",
          }}
        >
          <ChevronIcon />
        </Box>
      }
    >
      {t("investments.goBtn")}
    </Button>
  );
};

export const tabKeys = {
  fundamentals: "fundamentals",
  news: "news",
  protocol: "protocol",
  dividends: "dividends",
};

const PerformanceOverviewContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  & > :not(style) {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface Props {
  investmentId: number;
  depotId?: number;
}

export const InvestmentPage = ({ investmentId, depotId }: Props) => {
  const { mutateAsync } = useDeleteInvestment();
  const { data: account } = useAccount(depotId);
  const [comparedTicker, setComparedTicker] = useState<
    ComparisonValue | undefined
  >();
  const [el, setEl] = useState<HTMLElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const rangeData = useRangeSelectControl({
    firstDate: new Date(),
    id: "investment",
  });

  const handleDeleteInvestment = async () => {
    await mutateAsync(investmentId);
    navigate("/investments");
  };
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleOpenDeleteModal = () => {
    setDeleteModalShow(true);
  };
  const handleCloseDeleteModal = () => setDeleteModalShow(false);

  const { t } = useTranslation();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const {
    data: investment,
    isLoading: isInvestmentLoading,
    isIdle: isInvestmentIdle,
  } = useInvestmentsId({ id: investmentId }, { enabled: true });
  const realizedGainsQuery = useTradingGainForInvestment({ id: investmentId });
  const investmentPerformanceQuery = useInvestmentPerformance(investmentId);

  useEffect(() => {
    const el = document.getElementById("content");
    setEl(el);
    const handleScroll = () => {
      if (el) {
        el.scrollTo(0, 0);
      }
    };

    if (el) {
      window.addEventListener("popstate", handleScroll, true);
      return () => {
        window.removeEventListener("popstate", handleScroll);
      };
    }
  }, [el]);

  const isETF = investment?.type === "22_etf";
  const logo = useSecurityImages(investment ? [investment] : []);
  const image =
    logo[investment?.isin || ""] || logo[investment?.tickerSymbol || ""];
  const {
    data: chartData = [],
    markers,
    isLoading,
  } = useChartData(
    {
      investmentId,
      currentInterval: rangeData.intervalType,
      tickerSymbolWithCurrency: investment?.tickerSymbolWithCurrency,
      quoteProvider: investment?.quoteProvider,
      startDate: rangeData.range.startDate,
      endDate: rangeData.range.endDate,
      comparedTickerSymbol: comparedTicker?.value,
      mainName: investment?.standardisedName!,
      comparedName: comparedTicker?.label,
      currency: investment?.currency || "EUR",
    },
    { showEvents: true }
  );

  const { data: singleInvestmentTwrorData } = useBatchInvestmentPerformance(
    {
      id: [investmentId],
      from: format(rangeData.range.startDate, "yyyy-MM-dd"),
      to: format(rangeData.range.endDate, "yyyy-MM-dd"),
      inAccountCurrency: false,
    },
    {
      keepPreviousData: true,
      enabled: Boolean(investmentId),
    }
  );

  const investmentTwrorData =
    singleInvestmentTwrorData?.singleInvestmentTwrorRecords;

  const investmentTwror = investmentTwrorData
    ? investmentTwrorData[0].twror! - 1
    : 0;

  const handleTabChange = (key: string) => {
    setSearchParams({ tab: key });
  };

  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { data: myself } = useMyself();
  const isComparisonDisabled = !myself?.isUserPro;

  const defaultTabKey = searchParams.get("tab");
  const showPerformanceReview = !isInvestmentIdle || !isInvestmentLoading;

  const chartHeaderValue =
    getValueByInvestmentAndBookingType({
      value: investment?.quote?.last ?? 0,
      investmentType: investment?.type ?? "11_stock",
      currency: investment?.currency ?? "EUR",
      percentageRange: "0-100",
    }) ?? 0;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: "/",
      text: t("breadcrumbs.home"),
    },
    depotId
      ? {
          to: "/depots-accounts",
          text: t("depotsAccounts.title"),
        }
      : {
          to: "/investments",
          text: t("breadcrumbs.investments"),
        },
    ...(account
      ? [
          {
            to: `/depots/${depotId}`,
            text: truncate(account?.name),
          },
        ]
      : []),
    {
      text:
        (investment?.hasValidIsin
          ? investment?.isin
          : truncate(investment?.standardisedName)) ?? "",
    },
  ];

  return (
    <PageLayout
      id="content"
      data-testid="investment-page"
      isMainPage
      breadcrumbs={breadcrumbs}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        rowGap={6.5}
        columnGap={3}
        mb={isSm ? 3 : 6}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <SecurityImage
            src={image}
            alt={investment?.name || investment?.isin}
          />
          <Typography variant="h4" component="h1">
            {investment?.standardisedName || investment?.isin}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={3}
          flexDirection={isSm ? "column" : "row"}
          flexShrink={0}
        >
          {isETF && investment?.hasValidIsin && !isSm && (
            <RedirectToETFButton isin={investment?.isin!} />
          )}

          <PrimaryActionWithMenu
            primaryActionComponent={
              <InvestmentsActionButton investmentId={investment?.id} />
            }
          >
            <InvestmentMenu
              investmentId={investmentId}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </PrimaryActionWithMenu>
        </Box>
      </Box>
      {isSm && isETF && investment?.hasValidIsin && (
        <Box mb={3}>
          <RedirectToETFButton isin={investment?.isin!} />
        </Box>
      )}
      <ConfirmDeleteDialog
        isOpen={deleteModalShow}
        title={t("investment.header.deleteModal.question")}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteInvestment}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        mb={6}
        gap={isDownMd ? 6 : 3}
        flexDirection={isDownMd ? "column" : "row"}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" mb={3}>
            {t("investment.header.chart.subheader")}
          </Typography>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography fontSize="36px">{chartHeaderValue}</Typography>
            <Typography variant="body2" color="textSecondary" fontWeight={400}>
              {intervalTitle[rangeData.intervalType]}
            </Typography>
            <GainLossPercents value={investmentTwror} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <RangeSelector
            range={rangeData.range}
            onSelect={rangeData.selectInterval}
            intervalType={rangeData.intervalType}
            intervalTypes={[
              "oneWeek",
              "oneMonth",
              "YTD",
              "oneYear",
              "threeYears",
              "fiveYears",
              "sinceBeginning",
            ]}
          />
        </Box>
      </Box>

      <SecuritiesChart
        chartData={chartData}
        markers={markers}
        chartType={comparedTicker ? "performance" : "valuation"}
        intervalType={rangeData.intervalType}
        isLoading={isLoading}
        displayArea
        disableNavigator={isDownMd}
        currency={investment?.currency ?? "EUR"}
        isComparison={Boolean(comparedTicker)}
      />

      {showPerformanceReview && (
        <PerformanceOverviewContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={isDownMd ? "column" : "row"}
            columnGap={3}
            rowGap={6}
          >
            <Box display="flex" flexDirection="column" flex={1} pr={4}>
              <Typography variant="body1" color="textSecondary" mb={3}>
                {t("investment.performance")}
              </Typography>
              <PerformanceOverview
                currency={investment?.currency ?? "EUR"}
                gainAndLoss={
                  (realizedGainsQuery.data?.accumulatedGain?.netGain ?? 0) +
                  (investment?.returnOnInvestment ?? 0)
                }
                realizedGains={
                  realizedGainsQuery.data?.accumulatedGain?.netGain ?? 0
                }
                returnOnInvestment={investment?.returnOnInvestment ?? 0}
                returnOnInvestmentInPercent={
                  investment?.returnOnInvestmentInPercent ?? 0
                }
                performance={
                  investmentPerformanceQuery.data?.inInvestmentCurrency
                    ?.twrorWithDividends ?? 0
                }
              />
            </Box>
            <Box width={isDownMd ? "100%" : 334}>
              <SelectComparison
                value={comparedTicker}
                onChange={setComparedTicker}
                disabled={isComparisonDisabled}
              />
            </Box>
          </Box>
        </PerformanceOverviewContainer>
      )}

      <Tabs
        onChange={handleTabChange}
        defaultKey={defaultTabKey}
        variant="scrollable"
      >
        <Tab
          label={t("investment.tabs.fundamentals")}
          key={tabKeys.fundamentals}
        >
          <FundamentalsPageByInvestmentId investmentId={investmentId} />
        </Tab>
        {realizedGainsQuery?.data?.gainsByBooking &&
          investment?.quote?.last && (
            <Tab
              label={t("investment.tabs.finanzfluss.bookings")}
              key={tabKeys.protocol}
            >
              <InvestmentProtocolContainer
                bookingData={realizedGainsQuery.data.gainsByBooking}
                isLoading={realizedGainsQuery.isLoading}
                investmentQuote={investment.quote.last}
                currencySymbol={investment.currency}
                investment={investment}
                currentMarketValue={investment.currentMarketValue ?? 0}
              />
            </Tab>
          )}
        <Tab label={t("investment.tabs.dividends")} key={tabKeys.dividends}>
          <DividendsTableContainer tickerSymbol={investment?.tickerSymbol} />
        </Tab>
      </Tabs>
    </PageLayout>
  );
};
