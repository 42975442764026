import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Skeleton, Button, TextField, Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { Watchlist } from "@generated/apiv1";
import { useCreateWatchlist } from "@api/v1/hooks/useCreateWatchlist";
import { useUpdateWatchlist } from "@api/v1/hooks/useUpdateWatchlist";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  padding-top: ${({ theme }) => theme.spacing(1)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

const SaveButton = styled(Button)`
  align-self: baseline;
`;

interface CreateAndEditFormProps {
  watchlist?: Watchlist;
  isWatchlistLoading?: boolean;
  onCancel: () => void;
}

export const CreateAndEditForm = (props: CreateAndEditFormProps) => {
  const { watchlist, isWatchlistLoading, onCancel } = props;
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [touched, setTouched] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    watchlist && setName(watchlist.name ?? "");
  }, [watchlist]);

  const { mutate: createWatchlist } = useCreateWatchlist({
    onSuccess: () => {
      onCancel();
      enqueueSnackbar({
        message: "Watchlist erfolgreich erstellt",
        variant: "success",
        autoHideDuration: 3000,
      });
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS],
      });
    },
    onError: (err) => {
      setError(err.response?.data.message);
    },
  });

  const { mutate: updateWatchlist } = useUpdateWatchlist({
    onSuccess: () => {
      onCancel();
      enqueueSnackbar({
        message: "Watchlist erfolgreich bearbeitet",
        variant: "success",
        autoHideDuration: 3000,
      });
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS],
      });
    },
    onError: (err) => {
      setError(err.response?.data.message);
    },
  });

  const handleInputChange = (input: string) => {
    setName(input);
    // Note: we need to handle it for showing error after first field changing
    !touched && setTouched(true);
  };

  const handleSaveClick = () => {
    const data = {
      name,
    };

    if (name && name.length > 0) {
      watchlist
        ? updateWatchlist({ id: watchlist.id!, ...data })
        : createWatchlist(data);
    }
  };

  if (isWatchlistLoading) {
    return (
      <Container>
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" width={120} height={40} />
      </Container>
    );
  }

  return (
    <Container>
      <Box mb={6}>
        <TextField
          label={t("createAndEditForm.label")}
          placeholder={t("createAndEditForm.placeholder")}
          value={name}
          fullWidth
          error={(touched && name.length === 0) || Boolean(error)}
          inputProps={{
            "data-testid": `watchlist-name-input`,
            id: "watchlist-name-input",
          }}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {error && (
          <Error>
            <Typography variant="caption" color="inherit">
              {error}
            </Typography>
          </Error>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="contained" color="grey" onClick={onCancel}>
          {t("navigation.previous")}
        </Button>
        <SaveButton
          data-testid="watchlist-save-button"
          variant="contained"
          color="primary"
          disabled={name.length === 0}
          onClick={handleSaveClick}
        >
          {t("components.watchlistsCreateAndEditForm.form.saveButton")}
        </SaveButton>
      </Box>
    </Container>
  );
};
