import { UseQueryOptions } from "react-query";
import {
  TaxationApiEstimateTaxForInvestmentRequest,
  RealizedGain,
} from "@generated/apiv1";

import { buildTaxationApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const estimateTaxForInvestment = async (
  token: string | undefined,
  params: TaxationApiEstimateTaxForInvestmentRequest
) => {
  const apiInstance = buildTaxationApi(token);
  const { data } = await apiInstance.estimateTaxForInvestment(params);
  return data;
};

export const useEstimateTaxForInvestment = (
  params: TaxationApiEstimateTaxForInvestmentRequest,
  options?: UseQueryOptions<RealizedGain, AxiosApiError, RealizedGain>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TAXATION_ESTIMATE_TAX_FOR_INVESTMENT, params],
    ({ token }) => estimateTaxForInvestment(token, params),
    options
  );
};
