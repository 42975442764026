import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { useGetWatchlists } from "@api/v1/hooks/useGetWatchlists";
import { ReactComponent as ChevronIcon } from "@icons/chevron.svg";
import { SecurityImage } from "@components/SecurityImage";
import { NewTransactionModal } from "@features/transactionWizard/modal/NewTransactionModal";
import { QuoteV2, SymbolV2 } from "@generated/apiv1";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: baseline;
    gap: ${({ theme }) => theme.spacing(6)};
    margin-top: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

export interface Props {
  hasWatchlists: boolean;
  quote?: QuoteV2;
  symbol?: SymbolV2;
  isETF: boolean;
  id: number;
}

const AddButtonsWrapper = (props: Props) => {
  const { hasWatchlists, isETF, quote, symbol, id } = props;

  const tickerSymbol = quote?.tickerSymbol ?? "";
  const quoteIsin = symbol?.isin ?? "";
  const quoteProvider = symbol?.quoteProvider ?? "";

  const [isOpen, setIsOpen] = React.useState(false);
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      gap={3}
      flexDirection={isLessMd ? "column" : "row"}
      width={isLessMd ? "100%" : "auto"}
    >
      {isETF && (
        <Button
          variant="text"
          color="primary"
          fullWidth={isLessMd}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.finanzfluss.de/informer/etf/${quoteIsin.toLowerCase()}`}
          endIcon={
            <Box
              display="flex"
              sx={{
                transform: "rotate(180deg)",
                transformOrigin: "center",
              }}
            >
              <ChevronIcon />
            </Box>
          }
        >
          {t("investments.goBtn")}
        </Button>
      )}
      {symbol && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth={isLessMd}
          onClick={() => setIsOpen(true)}
          startIcon={<PlusIcon />}
        >
          {t("toPortfolio")}
        </Button>
      )}
      {hasWatchlists && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth={isLessMd}
          onClick={() =>
            navigate(
              `/watchlists/${id}/securities/add?quoteProvider=${quoteProvider}&tickerSymbol=${tickerSymbol}`
            )
          }
          startIcon={<PlusIcon />}
        >
          {t("toWatchlist")}
        </Button>
      )}
      {symbol && (
        <NewTransactionModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          accountCategory={
            symbol.type === "61_pmetals"
              ? AccountSubcategoryEnum.PRECIOUS_METALS
              : undefined
          }
          symbol={{
            ...symbol,
            quote,
          }}
        />
      )}
    </Box>
  );
};

interface SingleSecurityPageProps {
  image?: string | null;
  isETF: boolean;
  quote?: QuoteV2;
  symbol?: SymbolV2;
}

export const SingleSecurityPageHeader = (props: SingleSecurityPageProps) => {
  const { symbol, quote, image, isETF } = props;

  const { data: watchlistsResponse } = useGetWatchlists();
  const watchlists = watchlistsResponse?.watchlists;
  const hasWatchlists = Boolean(watchlists?.length);
  const [firstWatchlist] = watchlistsResponse?.watchlists ?? [];

  const quoteName = symbol?.name ?? "";

  return (
    <Container>
      <Box display="flex" alignItems="center" gap={3}>
        <SecurityImage src={image} alt={quoteName} />
        <Typography variant="h4" component="h1">
          {quoteName}
        </Typography>
      </Box>
      <AddButtonsWrapper
        hasWatchlists={hasWatchlists}
        id={firstWatchlist?.id}
        symbol={symbol}
        quote={quote}
        isETF={isETF}
      />
    </Container>
  );
};
