import { StateType } from "../types/StateType";

export const useStateHelpers = (currentState: StateType) => {
  const isLoadingState = () =>
    currentState.matches("idle") ||
    currentState.matches("loadDividends") ||
    currentState.matches("loadBooking") ||
    currentState.matches("loadInvestment") ||
    currentState.matches("loadExchangeRate") ||
    currentState.matches("validateDividends") ||
    currentState.matches("persist");

  const isLoadingStateForModals = () =>
    currentState.matches("idle") ||
    currentState.matches("loadDividends") ||
    currentState.matches("loadBooking") ||
    currentState.matches("loadInvestment") ||
    currentState.matches("loadExchangeRate");

  return {
    isLoadingState,
    isLoadingStateForModals,
  };
};
