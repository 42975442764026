/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Quote } from '../model';
// @ts-ignore
import { QuoteList } from '../model';
// @ts-ignore
import { QuoteMap } from '../model';
/**
 * QuotesApi - axios parameter creator
 * @export
 */
export const QuotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a quote for the given ticker symbol
         * @param {string} id Get the quote with the given ticker symbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuote', 'id', id)
            const localVarPath = `/v1/quotes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get quotes for the given ticker symbols
         * @param {Array<string>} id Get quotes with the given ticker symbols. Limit: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes: async (id: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuotes', 'id', id)
            const localVarPath = `/v1/quotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get quotes for the given security ids
         * @param {Array<string>} securityId Get quotes with the given security ids. Limit: 1000.
         * @param {string} quoteProvider Get the quotes from the given quote provider.
         * @param {boolean} [optimal] Get the optimal quotes for the given security ids.Default is true.
         * @param {string} [preferredCurrency] Get the quotes in the given currency. Default is EUR.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotesV2: async (securityId: Array<string>, quoteProvider: string, optimal?: boolean, preferredCurrency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('getQuotesV2', 'securityId', securityId)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getQuotesV2', 'quoteProvider', quoteProvider)
            const localVarPath = `/v2/quotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (securityId) {
                localVarQueryParameter['securityId'] = securityId;
            }

            if (optimal !== undefined) {
                localVarQueryParameter['optimal'] = optimal;
            }

            if (preferredCurrency !== undefined) {
                localVarQueryParameter['preferredCurrency'] = preferredCurrency;
            }

            if (quoteProvider !== undefined) {
                localVarQueryParameter['quoteProvider'] = quoteProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuotesApi - functional programming interface
 * @export
 */
export const QuotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a quote for the given ticker symbol
         * @param {string} id Get the quote with the given ticker symbol.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuote(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuote(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get quotes for the given ticker symbols
         * @param {Array<string>} id Get quotes with the given ticker symbols. Limit: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuotes(id: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuotes(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get quotes for the given security ids
         * @param {Array<string>} securityId Get quotes with the given security ids. Limit: 1000.
         * @param {string} quoteProvider Get the quotes from the given quote provider.
         * @param {boolean} [optimal] Get the optimal quotes for the given security ids.Default is true.
         * @param {string} [preferredCurrency] Get the quotes in the given currency. Default is EUR.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuotesV2(securityId: Array<string>, quoteProvider: string, optimal?: boolean, preferredCurrency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuotesV2(securityId, quoteProvider, optimal, preferredCurrency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuotesV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuotesApi - factory interface
 * @export
 */
export const QuotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a quote for the given ticker symbol
         * @param {QuotesApiGetQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote(requestParameters: QuotesApiGetQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<Quote> {
            return localVarFp.getQuote(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get quotes for the given ticker symbols
         * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes(requestParameters: QuotesApiGetQuotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteMap> {
            return localVarFp.getQuotes(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get quotes for the given security ids
         * @param {QuotesApiGetQuotesV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotesV2(requestParameters: QuotesApiGetQuotesV2Request, options?: RawAxiosRequestConfig): AxiosPromise<QuoteList> {
            return localVarFp.getQuotesV2(requestParameters.securityId, requestParameters.quoteProvider, requestParameters.optimal, requestParameters.preferredCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getQuote operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuoteRequest
 */
export interface QuotesApiGetQuoteRequest {
    /**
     * Get the quote with the given ticker symbol.
     * @type {string}
     * @memberof QuotesApiGetQuote
     */
    readonly id: string
}

/**
 * Request parameters for getQuotes operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuotesRequest
 */
export interface QuotesApiGetQuotesRequest {
    /**
     * Get quotes with the given ticker symbols. Limit: 1000.
     * @type {Array<string>}
     * @memberof QuotesApiGetQuotes
     */
    readonly id: Array<string>
}

/**
 * Request parameters for getQuotesV2 operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuotesV2Request
 */
export interface QuotesApiGetQuotesV2Request {
    /**
     * Get quotes with the given security ids. Limit: 1000.
     * @type {Array<string>}
     * @memberof QuotesApiGetQuotesV2
     */
    readonly securityId: Array<string>

    /**
     * Get the quotes from the given quote provider.
     * @type {string}
     * @memberof QuotesApiGetQuotesV2
     */
    readonly quoteProvider: string

    /**
     * Get the optimal quotes for the given security ids.Default is true.
     * @type {boolean}
     * @memberof QuotesApiGetQuotesV2
     */
    readonly optimal?: boolean

    /**
     * Get the quotes in the given currency. Default is EUR.
     * @type {string}
     * @memberof QuotesApiGetQuotesV2
     */
    readonly preferredCurrency?: string
}

/**
 * QuotesApi - object-oriented interface
 * @export
 * @class QuotesApi
 * @extends {BaseAPI}
 */
export class QuotesApi extends BaseAPI {
    /**
     * 
     * @summary Get a quote for the given ticker symbol
     * @param {QuotesApiGetQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuote(requestParameters: QuotesApiGetQuoteRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuote(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get quotes for the given ticker symbols
     * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuotes(requestParameters: QuotesApiGetQuotesRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuotes(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get quotes for the given security ids
     * @param {QuotesApiGetQuotesV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuotesV2(requestParameters: QuotesApiGetQuotesV2Request, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuotesV2(requestParameters.securityId, requestParameters.quoteProvider, requestParameters.optimal, requestParameters.preferredCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}

