import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { DividendHistoryTreeNode } from "@generated/apiv1";
import { SecurityImage } from "@components/SecurityImage";
import { Modal, Tooltip } from "@components";
import { toCurrency } from "@helpers";
import { BlurredTypography } from "@components/BlurredTypography";
import { DividendsTable } from "@features/dividendPlanner/components/DividendsTable/DividendsTable";
import { DividendTooltip } from "@features/dividendPlanner/CalendarTab/DividendTooltip";
import { DividendStatus } from "@features/dividendPlanner/components/DividendStatus";

type Props = {
  dividends: DividendHistoryTreeNode[];
  date: Date;
  period: "month" | "year";
  isEmpty?: boolean;
};

export const DividendsCalendarCell = ({ dividends, date, period }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLessSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLessLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [isOpen, setModalOpen] = useState(false);
  const { investments, images, isUserPro, isUserDataFetched } =
    useDividendsContext();
  const displayedSlice = dividends.slice(0, 5);
  const shouldShowButton = Boolean(dividends.length && dividends.length > 5);
  const displayWeekday = period === "month" && isLessLg && !isLessSm;

  if (!isUserDataFetched) return null;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12 / 2}
        md={12 / 3}
        lg={12 / (period === "month" ? 7 : 4)}
        sx={{
          height: isLessLg ? "auto" : "350px",
          minHeight: displayWeekday ? "398px" : "350px",
          borderTop: `1px solid ${theme.palette.border.secondary}`,
          borderLeft: isLessSm
            ? "none"
            : `1px solid ${theme.palette.border.secondary}`,
        }}
      >
        {displayWeekday && (
          <Box
            textAlign="center"
            p={4}
            sx={{
              backgroundColor: theme.palette.background.subtle,
              borderBottom: `1px solid ${theme.palette.border.secondary}`,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {format(date, "EE").replace(".", "").toUpperCase()}
            </Typography>
          </Box>
        )}
        <Box
          textAlign="center"
          p={4}
          sx={{
            borderBottom: `1px solid ${theme.palette.border.secondary}`,
            backgroundColor: theme.palette.background.subtle,
          }}
        >
          <Typography variant="body2">
            {format(date, period === "month" ? "dd. MMMM" : "MMMM")}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height={`calc(100% - ${displayWeekday ? "111px" : "55px"})`}
        >
          <Box>
            {displayedSlice.map((dividend, index) => {
              const investment = investments?.[dividend.investmentIds[0]];
              const isLast = index === displayedSlice.length - 1;

              return (
                <Tooltip
                  text={
                    <DividendTooltip
                      dividend={dividend}
                      investment={investment}
                      image={images[investment?.isin || ""]}
                    />
                  }
                  tooltipWidth="320px"
                  openOnClick
                  key={`${date.getDate()}-${dividend.month}-${dividend.year}-${
                    dividend.investmentIds[0]
                  }-${dividend.grossAmount}`}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    px={4}
                    width="100%"
                    py={3.25}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: theme.palette.background.neutralLight,
                      },
                      borderBottom:
                        isLast && !shouldShowButton
                          ? "none"
                          : `1px solid ${theme.palette.border.secondary}`,
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={2}
                      minWidth={0}
                    >
                      <SecurityImage
                        width={20}
                        height={20}
                        src={images[investment?.isin || ""]}
                        alt={investment?.standardisedName}
                        sx={{
                          borderRadius: "4px",
                        }}
                      />
                      <Typography variant="body2" noWrap color="textSecondary">
                        {investment?.standardisedName}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      {dividend.isPrediction && (
                        <DividendStatus dividend={dividend} small />
                      )}
                      <BlurredTypography
                        variant="body2"
                        blur={6}
                        minWidth={47}
                        textAlign="right"
                        shouldBlur={!isUserPro && dividend.isPrediction}
                      >
                        {toCurrency(dividend.grossAmount)}
                      </BlurredTypography>
                    </Box>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
          {shouldShowButton && (
            <Button
              variant="text"
              onClick={() => setModalOpen(true)}
              color="primary"
              fullWidth
              sx={{
                fontSize: "16px",
              }}
            >
              {t("dividends.calendarPage.showAll")}
            </Button>
          )}
        </Box>
      </Grid>
      {isOpen && (
        <Modal
          isOpen
          width={1000}
          height={isLessSm ? "calc(100% - 86px)" : 700}
          position={isLessSm ? "bottom" : "centered"}
          onClose={() => setModalOpen(false)}
          title={format(
            date,
            period === "month" ? "dd. MMMM yyyy" : "MMMM yyyy"
          )}
          titleAlign="center"
        >
          <DividendsTable
            date={date}
            period={period === "month" ? "day" : "month"}
            height="100%"
            scrollContainerHeight="100%"
            view="calendar"
            displayProBanner
            sx={{
              "& .infinite-scroll-component__outerdiv": {
                height: "100%",
              },
              "& .infinite-scroll-component": {
                height: "100% !important",
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};
