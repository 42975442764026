import { UseMutationOptions } from "react-query";
import {
  AccountsApiChangeContraAccountRequest,
  Account,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const changeContraAccount = async (
  token: string | undefined,
  params: AccountsApiChangeContraAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.changeContraAccount(params);
  return data;
};

export const useChangeContraAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiChangeContraAccountRequest
  >
) => {
  return useAuthMutation(changeContraAccount, options, false);
};
