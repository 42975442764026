import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Theme, useMediaQuery } from "@mui/material";
import { CardLayout } from "@features/analysis/components/CardLayout";
import { DonutChart } from "@components/Chart/DonutChart";
import { useChartColors, useSecurityImages } from "@hooks";
import {
  useListIndustryClassificationsForAllInvestments,
  usePublicListIndustryClassificationsForAllInvestments,
} from "@generated/apiv1/hooks";
import { ReactComponent as EditIcon } from "@icons/edit-box.svg";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { convertToChartBreakdown } from "@features/analysis/ClassificationsTab/utils/convertToChartBreakdown";
import { LegendLayout } from "../../components/Legend";
import { AssignIndustryToInvestment } from "./AssignIndustryToInvestment";

export interface Props {
  accountIds?: number[];
}

export const IndustryClassification = ({ accountIds }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const {
    data,
    isFetching: isLoading,
    isFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useListIndustryClassificationsForAllInvestments,
    usePublicMethod: usePublicListIndustryClassificationsForAllInvestments,
    params: {
      accountId: accountIds,
    },
    options: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });

  const logos = useSecurityImages(
    data?.industries
      ?.filter((i) => i.type === "investment")
      .map((i) => ({
        ...i.investment,
        quote: { quoteProvider: i.investment?.quoteProvider },
      }))
  );

  const colors = useChartColors("classificationChart");
  const chartData = useMemo(
    () =>
      convertToChartBreakdown(
        data?.industries || [],
        "industry",
        t("investmentTypeClassification.types", { returnObjects: true }),
        logos
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.industries, logos]
  );

  return (
    <Box>
      <CardLayout
        isLoading={Boolean(accountIds?.length && isLoading)}
        noData={!accountIds?.length || (isFetched && !chartData.length)}
        chartTitle={t("analysis.classifications.overviewTabs.industry")}
        chartData={chartData}
        chart={<DonutChart chartColors={colors} />}
        legend={<LegendLayout />}
        actionButton={
          isSharedPortfolio ? undefined : (
            <Button
              data-testid="assign-regions-item"
              onClick={() => setIsOpen(true)}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
              fullWidth={isLessMd}
            >
              {t("industryClassification.assignTitle")}
            </Button>
          )
        }
      />
      {isOpen && (
        <AssignIndustryToInvestment
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          accountIds={accountIds}
        />
      )}
    </Box>
  );
};
