import { UseQueryOptions } from "react-query";
import {
  CompanyLogosApiGetCompanyLogosRequest,
  CompanyLogosResponse,
} from "@generated/apiv3/quotes";

import { buildCompanyLogosApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getCompanyLogos = async (
  token: string | undefined,
  params: CompanyLogosApiGetCompanyLogosRequest
) => {
  const apiInstance = buildCompanyLogosApi(token);
  const { data } = await apiInstance.getCompanyLogos(params);
  return data;
};

export const useGetCompanyLogos = (
  params: CompanyLogosApiGetCompanyLogosRequest,
  options?: UseQueryOptions<
    CompanyLogosResponse,
    AxiosApiError,
    CompanyLogosResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.COMPANYLOGOS_GET_COMPANY_LOGOS, params],
    ({ token }) => getCompanyLogos(token, params),
    options
  );
};
