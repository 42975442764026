/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { FinApiAccount } from './fin-api-account';
// May contain unused imports in some cases
// @ts-ignore
import { IdentifierValuePair } from './identifier-value-pair';

/**
 * 
 * @export
 * @interface RemoteAccountWithProperties
 */
export interface RemoteAccountWithProperties {
    /**
     * 
     * @type {FinApiAccount}
     * @memberof RemoteAccountWithProperties
     */
    'remoteAccount'?: FinApiAccount;
    /**
     * The account information delivered by the banking API is persisted in the Rentablo data                                base for performance reasons.. This is the ID of the \'internal\' representation of the                                remote account.
     * @type {number}
     * @memberof RemoteAccountWithProperties
     */
    'persistedInternalAccountId'?: number;
    /**
     * Rentablo performs a mapping of the more complex account types to simplified internal                                categories.
     * @type {string}
     * @memberof RemoteAccountWithProperties
     */
    'internalType'?: RemoteAccountWithPropertiesInternalTypeEnum;
    /**
     * True if this account has investment related transactions (like buys, sells, dividends, ...
     * @type {boolean}
     * @memberof RemoteAccountWithProperties
     */
    'investmentRelatedTransactionsDetected'?: boolean;
    /**
     * If this is a depot: a list of a contra accounts with a probability that this is the correct one
     * @type {Array<IdentifierValuePair>}
     * @memberof RemoteAccountWithProperties
     */
    'estimatedContraAccounts'?: Array<IdentifierValuePair>;
}

export const RemoteAccountWithPropertiesInternalTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;

export type RemoteAccountWithPropertiesInternalTypeEnum = typeof RemoteAccountWithPropertiesInternalTypeEnum[keyof typeof RemoteAccountWithPropertiesInternalTypeEnum];


