import { UseQueryOptions } from "react-query";
import {
  RemoteDepotsApiImportDepotRequest,
  AccountImportOrSynchronizationResult,
} from "@generated/apiv1";

import { buildRemoteDepotsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const importDepot = async (
  token: string | undefined,
  params: RemoteDepotsApiImportDepotRequest
) => {
  const apiInstance = buildRemoteDepotsApi(token);
  const { data } = await apiInstance.importDepot(params);
  return data;
};

export const useImportDepot = (
  params: RemoteDepotsApiImportDepotRequest,
  options?: UseQueryOptions<
    AccountImportOrSynchronizationResult,
    AxiosApiError,
    AccountImportOrSynchronizationResult
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.REMOTEDEPOTS_IMPORT_DEPOT, params],
    ({ token }) => importDepot(token, params),
    options
  );
};
