import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import { Tab, Tabs } from "@components";
import { SecuritiesChartType } from "@components/Chart/SecuritiesChart/types";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

export const ChartTypeToggler = ({
  chartType,
  handleChartType,
  hasSelectedComparedValue,
  children,
}: {
  chartType: SecuritiesChartType;
  handleChartType: (value: SecuritiesChartType) => void;
  hasSelectedComparedValue: boolean;
  children?: ReactNode;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("");
  const { isAnonymized } = useSharedPortfolioContext();

  return (
    <Box
      my={theme.spacing(6)}
      sx={{
        "& > div > div": {
          borderBottom: "none",
        },
      }}
    >
      <Tabs value={chartType} onChange={handleChartType}>
        {!isAnonymized && (
          <Tab
            disabled={hasSelectedComparedValue}
            key="valuation"
            label={t("securitiesChart.typeSwitcher.valuation")}
          >
            {children}
          </Tab>
        )}
        <Tab
          disabled={hasSelectedComparedValue}
          key="performance"
          label={t("securitiesChart.typeSwitcher.performance")}
          defaultActive={isAnonymized}
        >
          {children}
        </Tab>
      </Tabs>
    </Box>
  );
};
