import { UseQueryOptions } from "react-query";
import {
  CommentsApiListAndSearchAllCommentsRequest,
  PageableCommentList,
} from "@generated/apiv1";

import { buildCommentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllComments = async (
  token: string | undefined,
  params?: CommentsApiListAndSearchAllCommentsRequest
) => {
  const apiInstance = buildCommentsApi(token);
  const { data } = await apiInstance.listAndSearchAllComments(params);
  return data;
};

export const useListAndSearchAllComments = (
  params?: CommentsApiListAndSearchAllCommentsRequest,
  options?: UseQueryOptions<
    PageableCommentList,
    AxiosApiError,
    PageableCommentList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.COMMENTS_LIST_AND_SEARCH_ALL_COMMENTS, params],
    ({ token }) => listAndSearchAllComments(token, params),
    options
  );
};
