import styled from "@emotion/styled";

export const TableHeader = styled.tr<{ roundTop?: number }>`
  & > th {
    background-color: ${({ theme }) => theme.palette.background.neutral};
    padding: ${({ theme }) => theme.spacing(4)};
  }
  & > th:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(6)};
    ${({ theme, roundTop }) =>
      roundTop ? `border-top-left-radius: ${theme.shape.borderRadius}px;` : ``}
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
  & > th:last-of-type {
    padding-right: ${({ theme }) => theme.spacing(6)};
    ${({ theme, roundTop }) =>
      roundTop ? `border-top-right-radius: ${theme.shape.borderRadius}px;` : ``}

    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`;
