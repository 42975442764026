/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateAuthenticationProcess
 */
export interface UpdateAuthenticationProcess {
    /**
     * ID of the current authentication process
     * @type {string}
     * @memberof UpdateAuthenticationProcess
     */
    'process_id': string;
    /**
     * The authentication process status
     * @type {string}
     * @memberof UpdateAuthenticationProcess
     */
    'process_status': string;
    /**
     * 
     * @type {object}
     * @memberof UpdateAuthenticationProcess
     */
    'custom_data'?: object;
    /**
     * Error code if any
     * @type {string}
     * @memberof UpdateAuthenticationProcess
     */
    'error_code'?: UpdateAuthenticationProcessErrorCodeEnum | null;
    /**
     * Error message if any
     * @type {string}
     * @memberof UpdateAuthenticationProcess
     */
    'error_message'?: string | null;
}

export const UpdateAuthenticationProcessErrorCodeEnum = {
    INITIAL_CONNECTION: 'INITIAL_CONNECTION',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    INVALID_2_FA_CREDENTIALS: 'INVALID_2FA_CREDENTIALS',
    AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
    LOGIN_FAILED: 'LOGIN_FAILED',
    NO_2_FA_RECEIVED: 'NO_2FA_RECEIVED',
    SCRAPING_FAILED: 'SCRAPING_FAILED',
    UNSUPPORTED_BANK: 'UNSUPPORTED_BANK',
    UNEXPECTED: 'UNEXPECTED'
} as const;

export type UpdateAuthenticationProcessErrorCodeEnum = typeof UpdateAuthenticationProcessErrorCodeEnum[keyof typeof UpdateAuthenticationProcessErrorCodeEnum];


