import { UseQueryOptions } from "react-query";
import { QualityAssuranceApiConfigureMockBankingApiRequest } from "@generated/apiv1";

import { buildQualityAssuranceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const configureMockBankingApi = async (
  token: string | undefined,
  params: QualityAssuranceApiConfigureMockBankingApiRequest
) => {
  const apiInstance = buildQualityAssuranceApi(token);
  const { data } = await apiInstance.configureMockBankingApi(params);
  return data;
};

export const useConfigureMockBankingApi = (
  params: QualityAssuranceApiConfigureMockBankingApiRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUALITYASSURANCE_CONFIGURE_MOCK_BANKING_API, params],
    ({ token }) => configureMockBankingApi(token, params),
    options
  );
};
