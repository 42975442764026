/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Transaction type
 * @export
 * @interface CsvRowTransactionType
 */
export interface CsvRowTransactionType {
    /**
     * Transaction type
     * @type {string}
     * @memberof CsvRowTransactionType
     */
    'value'?: CsvRowTransactionTypeValueEnum;
    /**
     * Name of the cell
     * @type {string}
     * @memberof CsvRowTransactionType
     */
    'name'?: string;
    /**
     * Error message if cell is invalid
     * @type {string}
     * @memberof CsvRowTransactionType
     */
    'error'?: string;
}

export const CsvRowTransactionTypeValueEnum = {
    BUY: 'buy',
    SELL: 'sell',
    DIVIDEND: 'dividend',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out'
} as const;

export type CsvRowTransactionTypeValueEnum = typeof CsvRowTransactionTypeValueEnum[keyof typeof CsvRowTransactionTypeValueEnum];


