import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Industry } from "@api";
import { SearchHighlight } from "@components/SearchHighlight";
import { useIndustries } from "@api/v1/hooks/useIndustries";

const OptionContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};

  & span {
    font-weight: 600 !important;
  }

  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

interface Props extends Omit<BoxProps, "onChange"> {
  defaultIndustry?: Industry;
  className?: string;
  onChange?: (industry: Industry | null) => void;
  isError?: boolean;
  errorText?: string;
  disabled?: boolean;
  noLabel?: boolean;
}

export const IndustrySelector = ({
  className,
  onChange,
  defaultIndustry,
  isError = false,
  errorText,
  disabled = false,
  noLabel,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useIndustries();
  const [industry, setIndustry] = useState(defaultIndustry ?? null);

  const handleChange = (value: Industry | null) => {
    onChange && onChange(value);
    setIndustry(value);
  };

  return (
    <Box {...props}>
      <Autocomplete
        disabled={disabled}
        defaultValue={defaultIndustry || null}
        className={className}
        value={industry}
        onChange={(e, value) => handleChange(value)}
        loading={isLoading}
        options={data?.industries || []}
        getOptionLabel={(option) =>
          option.germanName || option.name || "Undefined"
        }
        disablePortal
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={noLabel ? null : t("industrySelector.label")}
            error={isError}
            placeholder={t("industrySelector.label")}
            helperText={errorText}
            inputProps={{
              ...params.inputProps,
              "data-testid": "industry-selector-input",
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => (
          <OptionContainer
            {...props}
            data-testid="industry-selector-option"
            key={`${option.germanName}-${option.gicsId}}`}
          >
            <SearchHighlight
              value={option.germanName}
              searchValue={inputValue}
            />
          </OptionContainer>
        )}
      />
    </Box>
  );
};
