import { loadDividendsPerYear, useAuthQuery } from "@api";

export const useDividendsPerYear = (isin?: string, currency?: string) => {
  return useAuthQuery(
    ["dividends-per-year", isin!, currency],
    ({ token }) => loadDividendsPerYear(token, isin!, currency),
    {
      enabled: !!isin,
    }
  );
};
