import { UseQueryOptions } from "react-query";
import {
  ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest,
  ApplicationPropertyList,
} from "@generated/apiv1";

import { buildApplicationPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllApplicationProperties = async (
  token: string | undefined,
  params?: ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest
) => {
  const apiInstance = buildApplicationPropertiesApi(token);
  const { data } = await apiInstance.listAndSearchAllApplicationProperties(
    params
  );
  return data;
};

export const useListAndSearchAllApplicationProperties = (
  params?: ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest,
  options?: UseQueryOptions<
    ApplicationPropertyList,
    AxiosApiError,
    ApplicationPropertyList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.APPLICATIONPROPERTIES_LIST_AND_SEARCH_ALL_APPLICATION_PROPERTIES,
      params,
    ],
    ({ token }) => listAndSearchAllApplicationProperties(token, params),
    options
  );
};
