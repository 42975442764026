import { isAfter } from "date-fns";
import {
  Account,
  BankConnectionWrapper,
  FinApiAccount,
  UpdateResult,
} from "@generated/apiv1";
import {
  SettingsBankConnection,
  SettingsBankConnectionAccount,
} from "@features/settingsPage/SettingsConnectionsPage/types";
import { BankConnectionData } from "@generated/apiv3";
import { DataTransformer } from "@helpers/DataTransformer";

const isFinApiAccount = (
  account: Account | FinApiAccount
): account is FinApiAccount => {
  return "accountType" in account;
};

const isWealthApiBankConnection = (
  bankConnection: BankConnectionWrapper | BankConnectionData
): bankConnection is BankConnectionData => {
  return "meta" in bankConnection;
};

export const transformBankConnectionAccount = (
  account: Account | FinApiAccount
): SettingsBankConnectionAccount => {
  if (isFinApiAccount(account)) {
    return {
      id: account.id,
      name: account.accountName,
      iban: account.iban,
      type: String(account.accountTypeId),
      balance: account.balance,
      currency: account.accountCurrency,
    };
  } else {
    return {
      id: account.id,
      name: account.name,
      iban: account.iban,
      type: account.type,
      balance: account.balance,
      currency: account.currency,
    };
  }
};

export const getLastUpdate = (
  autoUpdate?: UpdateResult,
  manualUpdate?: UpdateResult
) => {
  if (!autoUpdate && manualUpdate) {
    return manualUpdate;
  } else if (autoUpdate && !manualUpdate) {
    return autoUpdate;
  } else if (!autoUpdate && !manualUpdate) {
    return undefined;
  }

  return isAfter(
    new Date(autoUpdate!.timestamp),
    new Date(manualUpdate!.timestamp)
  )
    ? autoUpdate
    : manualUpdate;
};

export const getLastUpdateStatus = (result?: UpdateResult) => {
  if (!result) {
    return undefined;
  }
  if (result.result === "OK") {
    return "SUCCESS";
  } else if (
    result.result === "BANK_SERVER_REJECTION" ||
    result.result === "INTERNAL_SERVER_ERROR"
  ) {
    return "FAILED";
  }
};

export const transformBankConnection = (
  bankConnection: BankConnectionWrapper | BankConnectionData
): SettingsBankConnection => {
  if (isWealthApiBankConnection(bankConnection)) {
    const dataTransformer = new DataTransformer({
      data: bankConnection,
    });
    const connection = dataTransformer.transformData<
      Omit<SettingsBankConnection, "provider">
    >({
      id: "id",
      bankId: "attributes.bank_name",
      name: "attributes.bank_name",
      iconUrl: "attributes.bank_icon_url",
      interfaces: [
        {
          error: "meta.connection_error",
          status: "meta.connection_status",
          lastUpdate: "meta.last_successful_connection_attempt",
        },
      ],
    });

    return {
      ...connection,
      interfaces: connection.interfaces.map((interfaceItem) => ({
        ...interfaceItem,
        name: "WealthAPI",
      })),
      provider: "WEALTH_API",
    };
  } else {
    return {
      id: bankConnection.id,
      bankId: bankConnection.bank.id,
      name: bankConnection.bank.name,
      provider: "FIN_API",
      iconUrl: bankConnection.bank.iconUrl,
      interfaces:
        bankConnection.interfaces?.map((interfaceItem) => {
          const lastUpdate = getLastUpdate(
            interfaceItem.lastManualUpdate,
            interfaceItem.lastAutoUpdate
          );
          return {
            name: interfaceItem.interface,
            status: getLastUpdateStatus(lastUpdate),
            lastUpdate: lastUpdate?.timestamp,
            error: lastUpdate?.errorMessage,
          };
        }) || [],
    };
  }
};
