import React, { ReactNode } from "react";
import { ReactComponent as QRIcon } from "@icons/myicons/qr_code.svg";
import { ReactComponent as SubscriptionsIcon } from "@icons/myicons/subscriptions.svg";
import { ReactComponent as ProfileIcon } from "@icons/myicons/text_format.svg";
import { ReactComponent as ConnectionsIcon } from "@icons/myicons/switch_account.svg";

export type SettingTabs =
  | "profile"
  | "subscription"
  | "security"
  | "connections"
  | "create"; // create MFA in security tab

type SettingIcons = {
  [key in SettingTabs]: ReactNode;
};

export const settingIcons: SettingIcons = {
  profile: <ProfileIcon />,
  subscription: <SubscriptionsIcon />,
  security: <QRIcon />,
  connections: <ConnectionsIcon />,
  create: <QRIcon />,
};
