/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AuthenticationProcessId } from '../model';
// @ts-ignore
import { BankAuthenticationProcess } from '../model';
// @ts-ignore
import { BankConnection } from '../model';
// @ts-ignore
import { BankConnectionList } from '../model';
// @ts-ignore
import { BankingCredentials } from '../model';
// @ts-ignore
import { TppWebFormCredentials } from '../model';
// @ts-ignore
import { TwoFactorChallengeResponse } from '../model';
// @ts-ignore
import { UpdateAuthenticationProcess } from '../model';
// @ts-ignore
import { UpdateBankConnectionCredentials } from '../model';
/**
 * BankConnectionsApi - axios parameter creator
 * @export
 */
export const BankConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authenticates against the bank
         * @param {BankingCredentials} bankingCredentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateAtBank: async (bankingCredentials: BankingCredentials, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankingCredentials' is not null or undefined
            assertParamExists('authenticateAtBank', 'bankingCredentials', bankingCredentials)
            const localVarPath = `/api/v3/wealth_api/bank_connections/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankingCredentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (re)authenticates an existing connection against the bank. No credentials are required if credentials are stored
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {UpdateBankConnectionCredentials} [updateBankConnectionCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateViaBankConnection: async (id: number, updateBankConnectionCredentials?: UpdateBankConnectionCredentials, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authenticateViaBankConnection', 'id', id)
            const localVarPath = `/api/v3/wealth_api/bank_connections/{id}/authenticate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankConnectionCredentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (re)authenticates an existing connection using a TPP web-form. No credentials are required if credentials are stored
         * @param {string} token The TPP web-form token; token&#x3D;the-web-form-token
         * @param {TppWebFormCredentials} [tppWebFormCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateViaTppWebForm: async (token: string, tppWebFormCredentials?: TppWebFormCredentials, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authenticateViaTppWebForm', 'token', token)
            const localVarPath = `/api/v3/wealth_api/bank_connections/authenticate/tpp_web_form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tppWebFormCredentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes stored credentials for a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCredentials: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearCredentials', 'id', id)
            const localVarPath = `/api/v3/wealth_api/bank_connections/{id}/credentials`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirms 2-Factor Authentication (or: challenge) against the bank
         * @param {TwoFactorChallengeResponse} twoFactorChallengeResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChallengeResponse: async (twoFactorChallengeResponse: TwoFactorChallengeResponse, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorChallengeResponse' is not null or undefined
            assertParamExists('confirmChallengeResponse', 'twoFactorChallengeResponse', twoFactorChallengeResponse)
            const localVarPath = `/api/v3/wealth_api/bank_connections/confirm_challenge_response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorChallengeResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankConnection', 'id', id)
            const localVarPath = `/api/v3/wealth_api/bank_connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankConnection', 'id', id)
            const localVarPath = `/api/v3/wealth_api/bank_connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a bank connection
         * @param {string} token The TPP-web-form token; token&#x3D;some-web-form-token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionViaTppWebForm: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getBankConnectionViaTppWebForm', 'token', token)
            const localVarPath = `/api/v3/wealth_api/bank_connections/tpp_web_form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a bank connections with given ids
         * @param {string} filterId Bank connection IDs (comma seperated); filter[id]&#x3D;42,43
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionsInBatch: async (filterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('getBankConnectionsInBatch', 'filterId', filterId)
            const localVarPath = `/api/v3/wealth_api/bank_connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initiates connection import or update after successful authentication
         * @param {AuthenticationProcessId} authenticationProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBankConnection: async (authenticationProcessId: AuthenticationProcessId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationProcessId' is not null or undefined
            assertParamExists('importBankConnection', 'authenticationProcessId', authenticationProcessId)
            const localVarPath = `/api/v3/wealth_api/bank_connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationProcessId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the bank authentication process
         * @param {string} processId The bank authentication process id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAuthenticationProcess: async (processId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('showAuthenticationProcess', 'processId', processId)
            const localVarPath = `/api/v3/wealth_api/bank_connections/authentication_processes/{process_id}`
                .replace(`{${"process_id"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the bank authentication process status
         * @param {string} processId The bank authentication process id
         * @param {UpdateAuthenticationProcess} updateAuthenticationProcess 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthenticationProcess: async (processId: string, updateAuthenticationProcess: UpdateAuthenticationProcess, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('updateAuthenticationProcess', 'processId', processId)
            // verify required parameter 'updateAuthenticationProcess' is not null or undefined
            assertParamExists('updateAuthenticationProcess', 'updateAuthenticationProcess', updateAuthenticationProcess)
            const localVarPath = `/api/v3/wealth_api/bank_connections/authentication_processes/{process_id}`
                .replace(`{${"process_id"}}`, encodeURIComponent(String(processId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuthenticationProcess, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankConnectionsApi - functional programming interface
 * @export
 */
export const BankConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary authenticates against the bank
         * @param {BankingCredentials} bankingCredentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateAtBank(bankingCredentials: BankingCredentials, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateAtBank(bankingCredentials, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.authenticateAtBank']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary (re)authenticates an existing connection against the bank. No credentials are required if credentials are stored
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {UpdateBankConnectionCredentials} [updateBankConnectionCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateViaBankConnection(id: number, updateBankConnectionCredentials?: UpdateBankConnectionCredentials, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateViaBankConnection(id, updateBankConnectionCredentials, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.authenticateViaBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary (re)authenticates an existing connection using a TPP web-form. No credentials are required if credentials are stored
         * @param {string} token The TPP web-form token; token&#x3D;the-web-form-token
         * @param {TppWebFormCredentials} [tppWebFormCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateViaTppWebForm(token: string, tppWebFormCredentials?: TppWebFormCredentials, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateViaTppWebForm(token, tppWebFormCredentials, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.authenticateViaTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deletes stored credentials for a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCredentials(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCredentials(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.clearCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary confirms 2-Factor Authentication (or: challenge) against the bank
         * @param {TwoFactorChallengeResponse} twoFactorChallengeResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmChallengeResponse(twoFactorChallengeResponse: TwoFactorChallengeResponse, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmChallengeResponse(twoFactorChallengeResponse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.confirmChallengeResponse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deletes a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankConnection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.deleteBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a bank connection
         * @param {number} id The bank connection ID; id&#x3D;42
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankConnection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a bank connection
         * @param {string} token The TPP-web-form token; token&#x3D;some-web-form-token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankConnectionViaTppWebForm(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankConnectionViaTppWebForm(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getBankConnectionViaTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a bank connections with given ids
         * @param {string} filterId Bank connection IDs (comma seperated); filter[id]&#x3D;42,43
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankConnectionsInBatch(filterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankConnectionsInBatch(filterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.getBankConnectionsInBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary initiates connection import or update after successful authentication
         * @param {AuthenticationProcessId} authenticationProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBankConnection(authenticationProcessId: AuthenticationProcessId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importBankConnection(authenticationProcessId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.importBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary retrieves the bank authentication process
         * @param {string} processId The bank authentication process id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showAuthenticationProcess(processId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showAuthenticationProcess(processId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.showAuthenticationProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updates the bank authentication process status
         * @param {string} processId The bank authentication process id
         * @param {UpdateAuthenticationProcess} updateAuthenticationProcess 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthenticationProcess(processId: string, updateAuthenticationProcess: UpdateAuthenticationProcess, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAuthenticationProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthenticationProcess(processId, updateAuthenticationProcess, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankConnectionsApi.updateAuthenticationProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankConnectionsApi - factory interface
 * @export
 */
export const BankConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @summary authenticates against the bank
         * @param {BankConnectionsApiAuthenticateAtBankRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateAtBank(requestParameters: BankConnectionsApiAuthenticateAtBankRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.authenticateAtBank(requestParameters.bankingCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (re)authenticates an existing connection against the bank. No credentials are required if credentials are stored
         * @param {BankConnectionsApiAuthenticateViaBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateViaBankConnection(requestParameters: BankConnectionsApiAuthenticateViaBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.authenticateViaBankConnection(requestParameters.id, requestParameters.updateBankConnectionCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (re)authenticates an existing connection using a TPP web-form. No credentials are required if credentials are stored
         * @param {BankConnectionsApiAuthenticateViaTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateViaTppWebForm(requestParameters: BankConnectionsApiAuthenticateViaTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.authenticateViaTppWebForm(requestParameters.token, requestParameters.tppWebFormCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes stored credentials for a bank connection
         * @param {BankConnectionsApiClearCredentialsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCredentials(requestParameters: BankConnectionsApiClearCredentialsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.clearCredentials(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary confirms 2-Factor Authentication (or: challenge) against the bank
         * @param {BankConnectionsApiConfirmChallengeResponseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChallengeResponse(requestParameters: BankConnectionsApiConfirmChallengeResponseRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.confirmChallengeResponse(requestParameters.twoFactorChallengeResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a bank connection
         * @param {BankConnectionsApiDeleteBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankConnection(requestParameters: BankConnectionsApiDeleteBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a bank connection
         * @param {BankConnectionsApiGetBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnection(requestParameters: BankConnectionsApiGetBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnection> {
            return localVarFp.getBankConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a bank connection
         * @param {BankConnectionsApiGetBankConnectionViaTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionViaTppWebForm(requestParameters: BankConnectionsApiGetBankConnectionViaTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnection> {
            return localVarFp.getBankConnectionViaTppWebForm(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a bank connections with given ids
         * @param {BankConnectionsApiGetBankConnectionsInBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankConnectionsInBatch(requestParameters: BankConnectionsApiGetBankConnectionsInBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnectionList> {
            return localVarFp.getBankConnectionsInBatch(requestParameters.filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initiates connection import or update after successful authentication
         * @param {BankConnectionsApiImportBankConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBankConnection(requestParameters: BankConnectionsApiImportBankConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankConnection> {
            return localVarFp.importBankConnection(requestParameters.authenticationProcessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the bank authentication process
         * @param {BankConnectionsApiShowAuthenticationProcessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAuthenticationProcess(requestParameters: BankConnectionsApiShowAuthenticationProcessRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.showAuthenticationProcess(requestParameters.processId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the bank authentication process status
         * @param {BankConnectionsApiUpdateAuthenticationProcessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthenticationProcess(requestParameters: BankConnectionsApiUpdateAuthenticationProcessRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAuthenticationProcess> {
            return localVarFp.updateAuthenticationProcess(requestParameters.processId, requestParameters.updateAuthenticationProcess, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticateAtBank operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiAuthenticateAtBankRequest
 */
export interface BankConnectionsApiAuthenticateAtBankRequest {
    /**
     * 
     * @type {BankingCredentials}
     * @memberof BankConnectionsApiAuthenticateAtBank
     */
    readonly bankingCredentials: BankingCredentials
}

/**
 * Request parameters for authenticateViaBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiAuthenticateViaBankConnectionRequest
 */
export interface BankConnectionsApiAuthenticateViaBankConnectionRequest {
    /**
     * The bank connection ID; id&#x3D;42
     * @type {number}
     * @memberof BankConnectionsApiAuthenticateViaBankConnection
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBankConnectionCredentials}
     * @memberof BankConnectionsApiAuthenticateViaBankConnection
     */
    readonly updateBankConnectionCredentials?: UpdateBankConnectionCredentials
}

/**
 * Request parameters for authenticateViaTppWebForm operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiAuthenticateViaTppWebFormRequest
 */
export interface BankConnectionsApiAuthenticateViaTppWebFormRequest {
    /**
     * The TPP web-form token; token&#x3D;the-web-form-token
     * @type {string}
     * @memberof BankConnectionsApiAuthenticateViaTppWebForm
     */
    readonly token: string

    /**
     * 
     * @type {TppWebFormCredentials}
     * @memberof BankConnectionsApiAuthenticateViaTppWebForm
     */
    readonly tppWebFormCredentials?: TppWebFormCredentials
}

/**
 * Request parameters for clearCredentials operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiClearCredentialsRequest
 */
export interface BankConnectionsApiClearCredentialsRequest {
    /**
     * The bank connection ID; id&#x3D;42
     * @type {number}
     * @memberof BankConnectionsApiClearCredentials
     */
    readonly id: number
}

/**
 * Request parameters for confirmChallengeResponse operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiConfirmChallengeResponseRequest
 */
export interface BankConnectionsApiConfirmChallengeResponseRequest {
    /**
     * 
     * @type {TwoFactorChallengeResponse}
     * @memberof BankConnectionsApiConfirmChallengeResponse
     */
    readonly twoFactorChallengeResponse: TwoFactorChallengeResponse
}

/**
 * Request parameters for deleteBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiDeleteBankConnectionRequest
 */
export interface BankConnectionsApiDeleteBankConnectionRequest {
    /**
     * The bank connection ID; id&#x3D;42
     * @type {number}
     * @memberof BankConnectionsApiDeleteBankConnection
     */
    readonly id: number
}

/**
 * Request parameters for getBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetBankConnectionRequest
 */
export interface BankConnectionsApiGetBankConnectionRequest {
    /**
     * The bank connection ID; id&#x3D;42
     * @type {number}
     * @memberof BankConnectionsApiGetBankConnection
     */
    readonly id: number
}

/**
 * Request parameters for getBankConnectionViaTppWebForm operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetBankConnectionViaTppWebFormRequest
 */
export interface BankConnectionsApiGetBankConnectionViaTppWebFormRequest {
    /**
     * The TPP-web-form token; token&#x3D;some-web-form-token
     * @type {string}
     * @memberof BankConnectionsApiGetBankConnectionViaTppWebForm
     */
    readonly token: string
}

/**
 * Request parameters for getBankConnectionsInBatch operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiGetBankConnectionsInBatchRequest
 */
export interface BankConnectionsApiGetBankConnectionsInBatchRequest {
    /**
     * Bank connection IDs (comma seperated); filter[id]&#x3D;42,43
     * @type {string}
     * @memberof BankConnectionsApiGetBankConnectionsInBatch
     */
    readonly filterId: string
}

/**
 * Request parameters for importBankConnection operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiImportBankConnectionRequest
 */
export interface BankConnectionsApiImportBankConnectionRequest {
    /**
     * 
     * @type {AuthenticationProcessId}
     * @memberof BankConnectionsApiImportBankConnection
     */
    readonly authenticationProcessId: AuthenticationProcessId
}

/**
 * Request parameters for showAuthenticationProcess operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiShowAuthenticationProcessRequest
 */
export interface BankConnectionsApiShowAuthenticationProcessRequest {
    /**
     * The bank authentication process id
     * @type {string}
     * @memberof BankConnectionsApiShowAuthenticationProcess
     */
    readonly processId: string
}

/**
 * Request parameters for updateAuthenticationProcess operation in BankConnectionsApi.
 * @export
 * @interface BankConnectionsApiUpdateAuthenticationProcessRequest
 */
export interface BankConnectionsApiUpdateAuthenticationProcessRequest {
    /**
     * The bank authentication process id
     * @type {string}
     * @memberof BankConnectionsApiUpdateAuthenticationProcess
     */
    readonly processId: string

    /**
     * 
     * @type {UpdateAuthenticationProcess}
     * @memberof BankConnectionsApiUpdateAuthenticationProcess
     */
    readonly updateAuthenticationProcess: UpdateAuthenticationProcess
}

/**
 * BankConnectionsApi - object-oriented interface
 * @export
 * @class BankConnectionsApi
 * @extends {BaseAPI}
 */
export class BankConnectionsApi extends BaseAPI {
    /**
     * 
     * @summary authenticates against the bank
     * @param {BankConnectionsApiAuthenticateAtBankRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public authenticateAtBank(requestParameters: BankConnectionsApiAuthenticateAtBankRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).authenticateAtBank(requestParameters.bankingCredentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (re)authenticates an existing connection against the bank. No credentials are required if credentials are stored
     * @param {BankConnectionsApiAuthenticateViaBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public authenticateViaBankConnection(requestParameters: BankConnectionsApiAuthenticateViaBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).authenticateViaBankConnection(requestParameters.id, requestParameters.updateBankConnectionCredentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (re)authenticates an existing connection using a TPP web-form. No credentials are required if credentials are stored
     * @param {BankConnectionsApiAuthenticateViaTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public authenticateViaTppWebForm(requestParameters: BankConnectionsApiAuthenticateViaTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).authenticateViaTppWebForm(requestParameters.token, requestParameters.tppWebFormCredentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes stored credentials for a bank connection
     * @param {BankConnectionsApiClearCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public clearCredentials(requestParameters: BankConnectionsApiClearCredentialsRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).clearCredentials(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary confirms 2-Factor Authentication (or: challenge) against the bank
     * @param {BankConnectionsApiConfirmChallengeResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public confirmChallengeResponse(requestParameters: BankConnectionsApiConfirmChallengeResponseRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).confirmChallengeResponse(requestParameters.twoFactorChallengeResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a bank connection
     * @param {BankConnectionsApiDeleteBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public deleteBankConnection(requestParameters: BankConnectionsApiDeleteBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).deleteBankConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a bank connection
     * @param {BankConnectionsApiGetBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getBankConnection(requestParameters: BankConnectionsApiGetBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getBankConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a bank connection
     * @param {BankConnectionsApiGetBankConnectionViaTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getBankConnectionViaTppWebForm(requestParameters: BankConnectionsApiGetBankConnectionViaTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getBankConnectionViaTppWebForm(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a bank connections with given ids
     * @param {BankConnectionsApiGetBankConnectionsInBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public getBankConnectionsInBatch(requestParameters: BankConnectionsApiGetBankConnectionsInBatchRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).getBankConnectionsInBatch(requestParameters.filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initiates connection import or update after successful authentication
     * @param {BankConnectionsApiImportBankConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public importBankConnection(requestParameters: BankConnectionsApiImportBankConnectionRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).importBankConnection(requestParameters.authenticationProcessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the bank authentication process
     * @param {BankConnectionsApiShowAuthenticationProcessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public showAuthenticationProcess(requestParameters: BankConnectionsApiShowAuthenticationProcessRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).showAuthenticationProcess(requestParameters.processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the bank authentication process status
     * @param {BankConnectionsApiUpdateAuthenticationProcessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankConnectionsApi
     */
    public updateAuthenticationProcess(requestParameters: BankConnectionsApiUpdateAuthenticationProcessRequest, options?: RawAxiosRequestConfig) {
        return BankConnectionsApiFp(this.configuration).updateAuthenticationProcess(requestParameters.processId, requestParameters.updateAuthenticationProcess, options).then((request) => request(this.axios, this.basePath));
    }
}

