import React from "react";
import styled from "@emotion/styled";
import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ManualContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface Props {
  handleManualInvestmentClick: () => void;
  noManual?: boolean;
}

export const SearchExamples = ({
  handleManualInvestmentClick,
  noManual,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="body2" mb={2} color="textSecondary">
        {t("symbolSearch.examples")}:
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("symbolSearch.example1")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("symbolSearch.example2")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("symbolSearch.example3")}
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={2}>
        {t("symbolSearch.example4")}
      </Typography>

      {!noManual && (
        <ManualContainer>
          <Typography variant="body2">
            {t("symbolSearch.manualInvestmentLabel")}
          </Typography>
          <Link
            data-testid="create-manual-investment"
            variant="body2"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={handleManualInvestmentClick}
          >
            {t("symbolSearch.manualInvestment")}
          </Link>
        </ManualContainer>
      )}
    </div>
  );
};
