import { UseQueryOptions } from "react-query";
import {
  AccountsApiIdCategorizeRequest,
  AssetCategorization,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const idCategorize = async (
  token: string | undefined,
  params: AccountsApiIdCategorizeRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.idCategorize(params);
  return data;
};

export const useIdCategorize = (
  params: AccountsApiIdCategorizeRequest,
  options?: UseQueryOptions<
    AssetCategorization,
    AxiosApiError,
    AssetCategorization
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_ID_CATEGORIZE, params],
    ({ token }) => idCategorize(token, params),
    options
  );
};
