import { UseMutationOptions } from "react-query";
import { OauthApiLoginRequest, LoginToken } from "@generated/apiv1";

import { buildOauthApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const login = async (
  token: string | undefined,
  params: OauthApiLoginRequest
) => {
  const apiInstance = buildOauthApi(token);
  const { data } = await apiInstance.login(params);
  return data;
};

export const useLogin = (
  options?: UseMutationOptions<LoginToken, AxiosApiError, OauthApiLoginRequest>
) => {
  return useAuthMutation(login, options, false);
};
