import { axiosInstanceV1 } from "@api/axiosInstance";

interface ProfileBase {
  gender?: 1 | 2;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export type Profile = ProfileBase & {
  id: number;
  userId: number;
};

export type UpdateProfileAttrs = ProfileBase & {
  id: number;
};

export const updateProfile = async (
  token: string | undefined,
  { id, ...attrs }: UpdateProfileAttrs
) => {
  const { data } = await axiosInstanceV1.put<Profile>(
    `/userProfiles/${id}`,
    attrs,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
