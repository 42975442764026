export const HOME = "https://www.finanzfluss.de/";
export const BUY_PROD = "https://link.finanzfluss.de/copilot/plus";
export const BUY_SANDBOX =
  "https://copilot-sandbox.finanzfluss.de/user/subscribe";
export const BUY =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ? BUY_SANDBOX : BUY_PROD;
export const FEEDBACK_EMAIL = "copilot@finanzfluss.de";
export const LOGIN = `${process.env.REACT_APP_V2_URL}/sign_in`;
export const LOGOUT = `${process.env.REACT_APP_V2_URL}/signed_out`;
export const SIGNUP = `${process.env.REACT_APP_V2_URL}/sign_up`;
export const EMAIL_CONFIRMATION = `${process.env.REACT_APP_V2_URL}/signed_up`;
export const SETTINGS = `${process.env.REACT_APP_V2_URL}/settings`;
export const MFA_SETTINGS = `${process.env.REACT_APP_V2_URL}/mfa/authenticators/edit`;
export const AGB = "https://www.finanzfluss.de/agb";
export const IMPRESSUM = "https://www.finanzfluss.de/impressum";
export const PRIVACY = "https://www.finanzfluss.de/datenschutz";
export const ACCOUNTS = `${process.env.REACT_APP_V2_URL}/depots-accounts`;
export const DISCORD_URL = "https://finanzfluss.de/discord";
