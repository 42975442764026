import { UseQueryOptions } from "react-query";
import {
  RetirementCalculatorApiPensionGapRequest,
  PensionGap,
} from "@generated/apiv1";

import { buildRetirementCalculatorApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const pensionGap = async (
  token: string | undefined,
  params: RetirementCalculatorApiPensionGapRequest
) => {
  const apiInstance = buildRetirementCalculatorApi(token);
  const { data } = await apiInstance.pensionGap(params);
  return data;
};

export const usePensionGap = (
  params: RetirementCalculatorApiPensionGapRequest,
  options?: UseQueryOptions<PensionGap, AxiosApiError, PensionGap>
) => {
  return useAuthQuery(
    [CACHE_KEYS.RETIREMENTCALCULATOR_PENSION_GAP, params],
    ({ token }) => pensionGap(token, params),
    options
  );
};
