import { UseQueryOptions } from "react-query";
import {
  DividendsApiPublicDividendAllocationRequest,
  DividendAllocationList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicDividendAllocation = async (
  token: string | undefined,
  params: DividendsApiPublicDividendAllocationRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.publicDividendAllocation(params);
  return data;
};

export const usePublicDividendAllocation = (
  params: DividendsApiPublicDividendAllocationRequest,
  options?: UseQueryOptions<
    DividendAllocationList,
    AxiosApiError,
    DividendAllocationList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_PUBLIC_DIVIDEND_ALLOCATION, params],
    ({ token }) => publicDividendAllocation(token, params),
    options
  );
};
