import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Box, IconButton, Theme, useTheme } from "@mui/material";
import { ReactComponent as MenuIcon } from "@icons/menu.svg";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { Footer } from "../Footer";
import { Profile } from "../Profile";

const Container = styled(motion.div)(
  ({ theme, collapsed }: { theme?: Theme; collapsed: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 4000,
    backgroundColor: theme?.appSidebar?.background,
    alignItems: "stretch",
    overflow: "hidden",
    width: collapsed ? "calc(100% - 32px)" : "unset",
    borderRadius: collapsed ? theme?.spacing(2) : "none",
    margin: collapsed ? theme?.spacing(4, 0, 4, 4) : "unset",
    paddingBottom: theme?.spacing(6),
    "&::-webkit-scrollbar": {
      display: "none",
    },
  })
);

const HeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.appSidebar?.background};
  height: 54px;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2, 2, 0)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export interface SidebarResponsiveProps {
  header: ReactNode;
  children: ReactNode;
  collapsed: boolean;
  onMenuOpen: () => void;
  iconColor?: "primary" | "white";
}

export const SidebarResponsive = ({
  header,
  children,
  collapsed,
  onMenuOpen,
  iconColor = "primary",
}: SidebarResponsiveProps) => {
  const height = window.innerHeight;
  const theme = useTheme();

  return (
    <Container
      animate={{ height: collapsed ? "54px" : height }}
      collapsed={collapsed}
    >
      <Box
        width="100%"
        height="calc(100% - 130px)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box height="100%" display="flex" flexDirection="column">
          <HeaderContainer>
            {header}
            <IconButton onClick={onMenuOpen} size="small">
              {collapsed ? (
                <MenuIcon
                  color={
                    iconColor === "primary"
                      ? theme.palette.icon.primary
                      : theme.palette.common.white
                  }
                />
              ) : (
                <CloseIcon
                  color={
                    iconColor === "primary"
                      ? theme.palette.icon.primary
                      : theme.palette.common.white
                  }
                />
              )}
            </IconButton>
          </HeaderContainer>
          <Box
            height="100%"
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Profile />
            <BodyContainer>{children}</BodyContainer>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};
