import { PortfolioPerformanceResult } from "@generated/apiv1";

export interface PortfolioStatistics {
  currentMarketValue: number;
  twrorPercent: number;
  purchaseValue: number;
  changeTotalAbsolute: number;
}

export const portfolioStatistics = (
  data?: PortfolioPerformanceResult
): PortfolioStatistics => {
  const twror = data?.twrorResults;
  const currentMarketValue = twror
    ? twror[twror?.length - 1]?.currentMarketValue
    : 0;
  const twrorPercent = twror ? twror[twror?.length - 1]?.twror : 0;
  const purchaseValue = twror ? twror[twror?.length - 1]?.purchaseValue : 0;
  const changeTotalAbsolute = twror
    ? twror[twror?.length - 1]?.valuationGainAbs
    : 0;

  return {
    currentMarketValue: currentMarketValue ?? 0,
    twrorPercent: twrorPercent ?? 0,
    purchaseValue: purchaseValue ?? 0,
    changeTotalAbsolute: changeTotalAbsolute ?? 0,
  };
};
