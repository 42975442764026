import { useQuery } from "react-query";
import { loadFunds } from "@api/v1/methods";
import { GET_FUNDS_KEY } from "@api/cacheKeys";

export const useFunds = (search: string) =>
  useQuery(
    GET_FUNDS_KEY,
    () => loadFunds({ perPage: 10, includeCommissions: true, search }),
    {
      enabled: search.length > 2,
    }
  );
