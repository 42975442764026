import { TextField, Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as InfoIcon } from "@icons/info.svg";
import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";
import {
  AdditionalOption,
  InvestmentSelect,
} from "@components/common/forms/InvestmentSelect";
import { Banner, DepotSelect } from "@components";
import {
  areNonListedAssetsSelected,
  investmentToInvestmentValues,
  investmentTypeToAccountType,
} from "@features/transactionWizard/stateMachine/utils/utils";
import {
  useListAndSearchAllAccounts,
  useListAndSearchAllInvestments,
} from "@generated/apiv1/hooks";
import { Investment } from "@generated/apiv1";
import { InvestmentValues } from "@features/transactionWizard/stateMachine/types/investmentValues";
import { sellTypes } from "@features/transactionWizard/stateMachine/types/sellTypes";
import { toGermanNumberFormat } from "@helpers";
import { StepContainer } from "../StepContainer";

const isNextDisabled = (
  isInvestmentNonListed: boolean,
  {
    depotId,
    investment,
    name,
  }: {
    depotId?: number;
    investment?: InvestmentValues | AdditionalOption | null;
    name?: string;
  }
) => {
  if (isInvestmentNonListed) {
    if (!depotId) return true;
    if (investment?.id === "new") return !name?.trim();
    return !investment?.id;
  }

  return !name?.trim() || !depotId;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;

export interface Props {
  handleNext: (investmentValues: InvestmentValues, comment?: string) => void;
  handlePrevious: () => void;
  handleDepotIdChange: (id: number | null) => void;
  context: TransactionWizardContext;
  selectedDepotId?: number;
  isManualTransaction?: boolean;
  existingLots?: number;
}

export const NameAndDepotStep = ({
  handleNext,
  handlePrevious,
  handleDepotIdChange,
  context,
  selectedDepotId,
  isManualTransaction,
  existingLots,
}: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [investment, setInvestment] = useState<
    InvestmentValues | AdditionalOption | null
  >(context.sourceInvestmentValues || context.investmentValues);
  const [name, setName] = useState<string>(
    context.sourceInvestmentValues?.name || context.investmentValues.name || ""
  );
  const [comment, setComment] = useState<string>(
    context.bookingValues.comment || ""
  );

  const type = investmentTypeToAccountType(context.investmentValues.type!);
  const depotId = searchParams.get("depotId");
  const shouldDisableDepotSelection = Boolean(depotId && selectedDepotId);
  const isNameInputDisabled = !isManualTransaction && investment?.id !== "new";
  const isInvestmentNonListed = areNonListedAssetsSelected(context);
  const isSelling = sellTypes.includes(context.bookingValues.type || "buy");
  const numberOfLots =
    investment?.id === "new"
      ? 0
      : existingLots || (investment as InvestmentValues)?.numberOfLots || 0;

  const onNextDisabled = isNextDisabled(isInvestmentNonListed, {
    depotId: selectedDepotId,
    investment,
    name,
  });

  const { data: investmentList, isLoading: areInvestmentsLoading } =
    useListAndSearchAllInvestments({
      includeHistoric: false,
    });
  const { data, isLoading } = useListAndSearchAllAccounts({
    type: shouldDisableDepotSelection ? undefined : [type],
    id: shouldDisableDepotSelection ? [Number(depotId)] : undefined,
  });

  const selectableDepots = useMemo(
    () =>
      data?.accounts
        ?.filter((depot) =>
          isSelling && !shouldDisableDepotSelection
            ? investmentList?.investments?.some(
                (investment) =>
                  investment.account.id === depot.id &&
                  investment.type === context.investmentValues.type
              )
            : true
        )
        .map((depot) => ({
          name: depot.name,
          value: depot.id,
        })),
    [
      data,
      investmentList,
      context.investmentValues.type,
      isSelling,
      shouldDisableDepotSelection,
    ]
  );

  const onDepotSelect = (depotId: number | null) => {
    handleDepotIdChange(depotId);
    setInvestment(null);
    if (!isNameInputDisabled) setName("");
  };

  const onInvestmentSelect = (
    investment: Investment | AdditionalOption | null
  ) => {
    setInvestment(
      investment?.id === "new"
        ? investment
        : investmentToInvestmentValues(investment as Investment)
    );
    setName(investment?.standardisedName || "");
  };

  const onNext = () => {
    if (onNextDisabled) return;

    if (investment && investment?.id !== "new") {
      handleNext(
        { ...(investment as InvestmentValues), availableLots: numberOfLots },
        comment
      );
      return;
    }
    return handleNext(
      {
        name,
        availableLots: numberOfLots,
      },
      comment
    );
  };

  return (
    <StepContainer
      testid="name-and-depot-step"
      title={t("transactionWizard.nameAndDepotStep.title")}
      onNext={onNext}
      onNextDisabled={onNextDisabled}
      onPrevious={handlePrevious}
    >
      <Container>
        <Box>
          <DepotSelect
            onDepotChange={onDepotSelect}
            selectableDepots={selectableDepots}
            isLoading={
              isSelling && !shouldDisableDepotSelection
                ? areInvestmentsLoading || isLoading
                : isLoading
            }
            selectedDepotId={selectedDepotId}
            disabled={shouldDisableDepotSelection}
            allowNewDepot={false}
            type={type}
          />
          {Boolean(numberOfLots) && numberOfLots > 0 && (
            <Banner
              icon={<InfoIcon />}
              type="grey-info"
              data-testid="investment-exists-info"
              mt={2}
              text={t("transactionWizard.nameAndDepotStep.investmentExists", {
                lots: toGermanNumberFormat(numberOfLots, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 6,
                }),
              })}
            />
          )}
        </Box>

        {(isInvestmentNonListed || isManualTransaction) && (
          <>
            <InvestmentSelect
              accountId={selectedDepotId}
              value={investment}
              label={
                isSelling && context.bookingValues.type
                  ? t(
                      `transactionWizard.nameAndDepotStep.investmentSell_${context.bookingValues.type}`
                    )
                  : t("transactionWizard.nameAndDepotStep.investmentSelect")
              }
              allowAddingNew={!isSelling}
              onChange={onInvestmentSelect}
              disabled={!selectedDepotId}
              type={context.investmentValues.type}
            />
          </>
        )}
        {!isInvestmentNonListed && !isManualTransaction && (
          <TextField
            autoComplete="off"
            label={t("transactionWizard.nameAndDepotStep.name")}
            placeholder={t("transactionWizard.nameAndDepotStep.name")}
            data-testid="name"
            fullWidth={true}
            onChange={(event) => {
              setName(event.target.value);
            }}
            disabled={isNameInputDisabled}
            type="text"
            value={name}
            inputProps={{
              style: { textAlign: "left" },
              "data-testid": "name-input",
            }}
          />
        )}
        <TextField
          autoComplete="off"
          label={t("transactionWizard.nameAndDepotStep.comment")}
          data-testid="comment"
          fullWidth={true}
          rows={5}
          multiline
          onChange={(event) => {
            setComment(event.target.value);
          }}
          type="text"
          value={comment}
          inputProps={{
            style: { textAlign: "left" },
            "data-testid": "comment-input",
          }}
        />
      </Container>
    </StepContainer>
  );
};
