/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
    /**
     * The users email address
     * @type {string}
     * @memberof UpdateUserParams
     */
    'email'?: string;
    /**
     * Is the users email address confirmed?  Usually, this property should be                                automatically set be the user confirmation step
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'isEmailConfirmed'?: boolean;
    /**
     * Reset the users email status defining if emails may be delivered. Delivery is         skipped for any status except \'active\'. This flag is only relevant if your mandator uses         Rentablo as email delivery service.
     * @type {string}
     * @memberof UpdateUserParams
     */
    'emailDeliveryStatus'?: UpdateUserParamsEmailDeliveryStatusEnum;
    /**
     * The users password (in plain text)
     * @type {string}
     * @memberof UpdateUserParams
     */
    'password'?: string;
    /**
     * Did the user accept your terms and privacy agreement
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'acceptedAgb'?: boolean;
    /**
     * Manually set a user to enabled. Users are enabled by default, but still require                                email confirmation.
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'enabled'?: boolean;
    /**
     * Manually set the expiry status of a users account
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'accountExpired'?: boolean;
    /**
     * Manually set the account lock of a user
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'accountLocked'?: boolean;
    /**
     * Manually set the password expired status of a users account
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'passwordExpired'?: boolean;
    /**
     * Enable or disable client credentials for a user. If disabled, the user may         obtain an access token using credential login without providing client credentials. You will require         ROLE_USER_MANAGEMENT to change this setting. Client credentials should only be disabled for certain         management roles / reporting use cases; but never for normal end users.
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'clientCredentialsDisabled'?: boolean;
    /**
     * Manually disable or enable the second authentication factor (security-PIN)
     * @type {boolean}
     * @memberof UpdateUserParams
     */
    'secondAuthenticationFactorDisabled'?: boolean;
    /**
     * An optional recovery phone number; currently not implemented.
     * @type {string}
     * @memberof UpdateUserParams
     */
    'recoveryPhoneNumber'?: string;
    /**
     * Change the users mandator. Note that only a user in role                                ROLE_MANDATOR_ADMIN may change a users mandator.
     * @type {string}
     * @memberof UpdateUserParams
     */
    'mandatorPublicId'?: string;
}

export const UpdateUserParamsEmailDeliveryStatusEnum = {
    HARD_BOUNCE: 'hard_bounce',
    SPAM: 'spam',
    ACTIVE: 'active'
} as const;

export type UpdateUserParamsEmailDeliveryStatusEnum = typeof UpdateUserParamsEmailDeliveryStatusEnum[keyof typeof UpdateUserParamsEmailDeliveryStatusEnum];


