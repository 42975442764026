import { format } from "date-fns";

import { usePublicOrPrivatePerformance } from "@features/sharedPortfolio/usePublicOrPrivatePerformance";
interface Attrs {
  accountIds: number[];
  startDate: Date;
  endDate: Date;
  interval?: "day" | "week" | "month" | "year";
}

export const usePerformanceByAssetClass = ({
  accountIds,
  startDate,
  endDate,
  interval,
}: Attrs) => {
  const {
    data: performanceData,
    isFetched,
    isLoading,
    isIdle,
  } = usePublicOrPrivatePerformance({
    accountIds,
    startDate: format(startDate, "yyyy-MM-dd"),
    endDate: format(endDate, "yyyy-MM-dd"),
    interval,
    cashFlowAndPerformanceStatisticsParamsList: [
      { assetClasses: [], includeCash: false, includeDividends: true },
      {
        // Einzeltitel
        assetClasses: ["11_stock"],
        includeCash: false,
        includeDividends: false,
      },
      {
        // Fonds
        assetClasses: ["21_fund"],
        includeCash: false,
        includeDividends: false,
      },
      {
        // ETF
        assetClasses: ["22_etf"],
        includeCash: false,
        includeDividends: false,
      },
      {
        //   Anleihen
        assetClasses: ["31_bond"],
        includeCash: false,
        includeDividends: false,
      },
      {
        //   Währungen / Krypto
        assetClasses: ["81_fcurr"],
        includeCash: false,
        includeDividends: false,
      },
      {
        // Sachwerte
        assetClasses: ["71_massets"],
        includeCash: false,
        includeDividends: false,
      },
      //   Rohstoffe / Edelmetalle
      {
        assetClasses: ["61_pmetals"],
        includeCash: false,
        includeDividends: false,
      },
    ],
  });

  const [all, shares, funds, etfs, bonds, currency, materialAssets, metals] =
    performanceData ?? [];

  return {
    all,
    shares,
    funds,
    etfs,
    bonds,
    currency,
    materialAssets,
    metals,
    isLoading: isLoading,
    isFetched: isFetched,
    isIdle: isIdle,
  };
};
