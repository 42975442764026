import { UseQueryOptions } from "react-query";
import {
  InternalTransactionsApiListAndSearchAllInternalTransactionsRequest,
  InternalTransactionList,
} from "@generated/apiv1";

import { buildInternalTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllInternalTransactions = async (
  token: string | undefined,
  params?: InternalTransactionsApiListAndSearchAllInternalTransactionsRequest
) => {
  const apiInstance = buildInternalTransactionsApi(token);
  const { data } = await apiInstance.listAndSearchAllInternalTransactions(
    params
  );
  return data;
};

export const useListAndSearchAllInternalTransactions = (
  params?: InternalTransactionsApiListAndSearchAllInternalTransactionsRequest,
  options?: UseQueryOptions<
    InternalTransactionList,
    AxiosApiError,
    InternalTransactionList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.INTERNALTRANSACTIONS_LIST_AND_SEARCH_ALL_INTERNAL_TRANSACTIONS,
      params,
    ],
    ({ token }) => listAndSearchAllInternalTransactions(token, params),
    options
  );
};
