/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A background bank connection and account synchronization
 * @export
 * @interface BankSynchronizationProcess
 */
export interface BankSynchronizationProcess {
    /**
     * The id of the process; use to check the update status
     * @type {string}
     * @memberof BankSynchronizationProcess
     */
    'processId': string;
    /**
     * The process status
     * @type {string}
     * @memberof BankSynchronizationProcess
     */
    'status': BankSynchronizationProcessStatusEnum;
}

export const BankSynchronizationProcessStatusEnum = {
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS'
} as const;

export type BankSynchronizationProcessStatusEnum = typeof BankSynchronizationProcessStatusEnum[keyof typeof BankSynchronizationProcessStatusEnum];


