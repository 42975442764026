import React from "react";
import LazyLoad from "react-lazyload";
import styled from "@emotion/styled";
import { HistoricalQuotesByInvestmentChart } from "@components/Chart/HistoricalQuotesChart";
import { FundamentalsBlock, Header } from "../FundamentalsBlock";
import { DividendsByInvestmentChart } from "../DividendsChart/DividendsByInvestmentChart";
import { GainLossStatisticsLoader } from "../../../investment/details/GainLossStatisticsLoader";

export interface Props {
  investmentId: number;
  isin: string;
  areDividendsPresent?: boolean;
}

const LazyLoadWrapper = styled.div`
  width: 100%;
  height: 100%;

  & .lazyload-wrapper {
    height: 100%;
    width: 100%;
  }
`;

export const FundFundamentalsByInvestmentId = ({
  isin,
  investmentId,
  areDividendsPresent = false,
}: Props) => {
  return (
    <FundamentalsBlock header={<Header />}>
      <LazyLoadWrapper>
        <LazyLoad>
          <GainLossStatisticsLoader investmentId={investmentId} />
        </LazyLoad>
      </LazyLoadWrapper>

      {areDividendsPresent && (
        <LazyLoadWrapper>
          <LazyLoad>
            <DividendsByInvestmentChart investmentId={investmentId} />
          </LazyLoad>
        </LazyLoadWrapper>
      )}

      <LazyLoadWrapper>
        <LazyLoad>
          <HistoricalQuotesByInvestmentChart investmentId={investmentId} />
        </LazyLoad>
      </LazyLoadWrapper>
    </FundamentalsBlock>
  );
};
