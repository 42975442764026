import { UseQueryOptions } from "react-query";
import {
  BankErrorsReportsApiBankErrorsRequest,
  BankErrors,
} from "@generated/apiv3";

import { buildBankErrorsReportsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const bankErrors = async (
  token: string | undefined,
  params: BankErrorsReportsApiBankErrorsRequest
) => {
  const apiInstance = buildBankErrorsReportsApi(token);
  const { data } = await apiInstance.bankErrors(params);
  return data;
};

export const useBankErrors = (
  params: BankErrorsReportsApiBankErrorsRequest,
  options?: UseQueryOptions<BankErrors, AxiosApiError, BankErrors>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKERRORSREPORTS_BANK_ERRORS, params],
    ({ token }) => bankErrors(token, params),
    options
  );
};
