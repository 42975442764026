import { UseMutationOptions } from "react-query";
import { SplitsApiUpdateSplitRequest, Split } from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateSplit = async (
  token: string | undefined,
  params: SplitsApiUpdateSplitRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.updateSplit(params);
  return data;
};

export const useUpdateSplit = (
  options?: UseMutationOptions<
    Split,
    AxiosApiError,
    SplitsApiUpdateSplitRequest
  >
) => {
  return useAuthMutation(updateSplit, options, false);
};
