import { UseMutationOptions } from "react-query";
import { TransactionsApiBatchCreateCashTransactionsRequest } from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const batchCreateCashTransactions = async (
  token: string | undefined,
  params: TransactionsApiBatchCreateCashTransactionsRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.batchCreateCashTransactions(params);
  return data;
};

export const useBatchCreateCashTransactions = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TransactionsApiBatchCreateCashTransactionsRequest
  >
) => {
  return useAuthMutation(batchCreateCashTransactions, options, false);
};
