import { AuthApi, OtpApi, SubscriptionsApi, UsersApi } from "@generated/user";

import { withToken } from "@api/auth/withToken";
import {
  buildAnonymousConfiguration,
  buildConfiguration,
} from "@api/user/buildConfiguration";

export const buildAuthApiWithToken = withToken(
  async (token: string) => new AuthApi(buildConfiguration(token))
);
export const buildAnonymousAuthApi = () =>
  new AuthApi(buildAnonymousConfiguration());
export const buildAuthApi = (token?: string) =>
  new AuthApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildOtpApiWithToken = withToken(
  async (token: string) => new OtpApi(buildConfiguration(token))
);
export const buildAnonymousOtpApi = () =>
  new OtpApi(buildAnonymousConfiguration());
export const buildOtpApi = (token?: string) =>
  new OtpApi(token ? buildConfiguration(token) : buildAnonymousConfiguration());

export const buildSubscriptionsApiWithToken = withToken(
  async (token: string) => new SubscriptionsApi(buildConfiguration(token))
);
export const buildAnonymousSubscriptionsApi = () =>
  new SubscriptionsApi(buildAnonymousConfiguration());
export const buildSubscriptionsApi = (token?: string) =>
  new SubscriptionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildUsersApiWithToken = withToken(
  async (token: string) => new UsersApi(buildConfiguration(token))
);
export const buildAnonymousUsersApi = () =>
  new UsersApi(buildAnonymousConfiguration());
export const buildUsersApi = (token?: string) =>
  new UsersApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );
