import React from "react";
import { useTranslation } from "react-i18next";
import { useYieldBreakdowns } from "@features/analysis/ClassificationsTab/YieldClassification/useYieldBreakdowns";
import { CardLayout } from "@features/analysis/components/CardLayout";
import { DonutChart } from "@components/Chart/DonutChart";
import { useChartColors } from "@hooks";

import { LegendLayout } from "../../components/Legend";

export interface Props {
  accountIds?: number[];
}

export const YieldClassification = ({ accountIds }: Props) => {
  const { t } = useTranslation();
  const {
    data: chartData,
    isLoading,
    isFetched,
  } = useYieldBreakdowns(accountIds);
  const colors = useChartColors("classificationChart");

  return (
    <CardLayout
      isLoading={Boolean(accountIds?.length && isLoading)}
      noData={!accountIds?.length || (isFetched && !chartData.length)}
      chartData={chartData}
      chartTitle={t("analysis.classifications.overviewTabs.yield")}
      chart={<DonutChart chartColors={colors} />}
      legend={<LegendLayout showInvestmentAmount />}
    />
  );
};
