/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AccountHolderReference } from './account-holder-reference';
// May contain unused imports in some cases
// @ts-ignore
import { DiscountPosition } from './discount-position';
// May contain unused imports in some cases
// @ts-ignore
import { UserReference } from './user-reference';

/**
 * 
 * @export
 * @interface InternalTransaction
 */
export interface InternalTransaction {
    /**
     * Technical identifier
     * @type {number}
     * @memberof InternalTransaction
     */
    'id'?: number;
    /**
     * The according billing cycle
     * @type {number}
     * @memberof InternalTransaction
     */
    'billingCycleId'?: number;
    /**
     * 
     * @type {UserReference}
     * @memberof InternalTransaction
     */
    'userReference'?: UserReference;
    /**
     * 
     * @type {AccountHolderReference}
     * @memberof InternalTransaction
     */
    'accountHolderReference'?: AccountHolderReference;
    /**
     * IBAN of the sender account
     * @type {string}
     * @memberof InternalTransaction
     */
    'senderAccountId'?: string;
    /**
     * A unique transaction identifier; this identifier is also written into the purpose
     * @type {string}
     * @memberof InternalTransaction
     */
    'transactionUid'?: string;
    /**
     * Name of the receiving accounts account holder
     * @type {string}
     * @memberof InternalTransaction
     */
    'receiverName'?: string;
    /**
     * IBAN of the receiving account
     * @type {string}
     * @memberof InternalTransaction
     */
    'receiverIban'?: string;
    /**
     * BIC of the receiving account
     * @type {string}
     * @memberof InternalTransaction
     */
    'receiverBic'?: string;
    /**
     * The sent amount (in cents)
     * @type {number}
     * @memberof InternalTransaction
     */
    'amount'?: number;
    /**
     * The sent curreny
     * @type {string}
     * @memberof InternalTransaction
     */
    'currency'?: string;
    /**
     * The transaction purpose
     * @type {string}
     * @memberof InternalTransaction
     */
    'purpose'?: string;
    /**
     * The transaction status
     * @type {string}
     * @memberof InternalTransaction
     */
    'status'?: InternalTransactionStatusEnum;
    /**
     * Timestamp when the transaction was submitted
     * @type {string}
     * @memberof InternalTransaction
     */
    'executedAt'?: string;
    /**
     * An ID given by the bank on execution time
     * @type {string}
     * @memberof InternalTransaction
     */
    'executedTransactionId'?: string;
    /**
     * Creation timestamp
     * @type {string}
     * @memberof InternalTransaction
     */
    'timestamp'?: string;
    /**
     * The discount (the sum of all discount positions)
     * @type {number}
     * @memberof InternalTransaction
     */
    'discount'?: number;
    /**
     * A list of discount positions that have been included into the transaction amount
     * @type {Array<DiscountPosition>}
     * @memberof InternalTransaction
     */
    'discountPositions'?: Array<DiscountPosition>;
}

export const InternalTransactionStatusEnum = {
    PLANNED: 'planned',
    EXECUTED: 'executed',
    TRANSFER_FAILED: 'transferFailed'
} as const;

export type InternalTransactionStatusEnum = typeof InternalTransactionStatusEnum[keyof typeof InternalTransactionStatusEnum];


