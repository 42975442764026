import { UseMutationOptions } from "react-query";
import { AccountsApiUpdateAccountRequest, Account } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateAccount = async (
  token: string | undefined,
  params: AccountsApiUpdateAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.updateAccount(params);
  return data;
};

export const useUpdateAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiUpdateAccountRequest
  >
) => {
  return useAuthMutation(updateAccount, options, false);
};
