import { UseMutationOptions } from "react-query";
import {
  TppCredentialsApiCreateTppCredentialRequest,
  TppCredentials,
} from "@generated/apiv1";

import { buildTppCredentialsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createTppCredential = async (
  token: string | undefined,
  params: TppCredentialsApiCreateTppCredentialRequest
) => {
  const apiInstance = buildTppCredentialsApi(token);
  const { data } = await apiInstance.createTppCredential(params);
  return data;
};

export const useCreateTppCredential = (
  options?: UseMutationOptions<
    TppCredentials,
    AxiosApiError,
    TppCredentialsApiCreateTppCredentialRequest
  >
) => {
  return useAuthMutation(createTppCredential, options, false);
};
