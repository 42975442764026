import { AccountGroupName } from "@features/selectDepots/types";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";

type AccountProviderProps = {
  selectedGroupsKey: AccountGroupName;
  accountsParams?: AccountsApiListAndSearchAllAccountsRequest;
};

export const DASHBOARD_PAGE_PROPS: AccountProviderProps = {
  selectedGroupsKey: "dashboard",
  accountsParams: {
    isHidden: false,
  },
};

export const INVESTMENTS_PAGE_PROPS: AccountProviderProps = {
  selectedGroupsKey: "investmentList",
};
