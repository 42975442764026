import { UseMutationOptions } from "react-query";
import {
  WatchlistEntriesApiEditWatchlistEntryRequest,
  WatchlistEntry,
} from "@generated/apiv1";

import { buildWatchlistEntriesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const editWatchlistEntry = async (
  token: string | undefined,
  params: WatchlistEntriesApiEditWatchlistEntryRequest
) => {
  const apiInstance = buildWatchlistEntriesApi(token);
  const { data } = await apiInstance.editWatchlistEntry(params);
  return data;
};

export const useEditWatchlistEntry = (
  options?: UseMutationOptions<
    WatchlistEntry,
    AxiosApiError,
    WatchlistEntriesApiEditWatchlistEntryRequest
  >
) => {
  return useAuthMutation(editWatchlistEntry, options, false);
};
