import { UseMutationOptions } from "react-query";
import { CategoriesV2ApiDeleteCategoryV2Request } from "@generated/apiv1";

import { buildCategoriesV2Api } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteCategoryV2 = async (
  token: string | undefined,
  params: CategoriesV2ApiDeleteCategoryV2Request
) => {
  const apiInstance = buildCategoriesV2Api(token);
  const { data } = await apiInstance.deleteCategoryV2(params);
  return data;
};

export const useDeleteCategoryV2 = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    CategoriesV2ApiDeleteCategoryV2Request
  >
) => {
  return useAuthMutation(deleteCategoryV2, options, false);
};
