import React, { forwardRef } from "react";
import { TooltipProps } from "recharts";
import { DonutChartTag } from "./DonutChartTag";

interface DonutChartTooltipProps extends TooltipProps<number, string> {
  totalValue: number;
}

export const DonutChartTooltip = forwardRef<
  HTMLDivElement,
  DonutChartTooltipProps
>((props, ref) => {
  const { totalValue, active, payload } = props;

  if (!active || !payload?.length) return null;

  // @ts-ignore
  const [
    {
      value = 0,
      name,
      payload: { icon, id, children },
    },
  ] = payload;

  const percent = Math.abs(value) / totalValue;

  if (!name || id === "noData") return null;

  if (id === "rest") {
    return (
      <div ref={ref}>
        <DonutChartTag
          // @ts-ignore
          children={children.map((child) => ({
            ...child,
            value: Math.abs(child.value) / totalValue,
          }))}
        />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <DonutChartTag icon={icon} percent={percent} name={name} />
    </div>
  );
});
