import { useCallback, useMemo, useState } from "react";
import { uniqueId } from "lodash";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { useSelectedDepotsFromLocalStorage } from "@features/selectDepots/components/useSelectedDepotsFromLocalStorage";
import { usePublicOrPrivateAccounts } from "@features/sharedPortfolio/hooks/usePublicOrPrivateAccounts";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import { useSelectGroupsId } from "@generated/apiv1/hooks";
import { SelectGroupParams } from "@features/selectDepots/types";

export type SelectedAccount = {
  id: number;
  name: string;
  selected: boolean;
};

type Props = {
  selectedGroupParams: SelectGroupParams;
  accountParams: AccountsApiListAndSearchAllAccountsRequest;
};

export const useSelectedAccounts = ({
  selectedGroupParams,
  accountParams,
}: Props): {
  selectedAccounts: SelectedAccount[];
  refresh: () => void;
} => {
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const [key, setKey] = useState<string>(uniqueId());
  const { data: accounts } = usePublicOrPrivateAccounts(accountParams, {
    refetchOnWindowFocus: false,
  });
  const { data: selectedGroup } = useSelectGroupsId(selectedGroupParams, {
    enabled: !isSharedPortfolio,
    refetchOnWindowFocus: false,
  });

  const { selectedAccounts: selectedAccountsFromLocalStorage } =
    useSelectedDepotsFromLocalStorage(selectedGroupParams.id, accounts, key);

  const refresh = useCallback(() => {
    setKey(uniqueId());
  }, []);

  const formattedAccounts = useMemo(() => {
    const ids = isSharedPortfolio
      ? selectedAccountsFromLocalStorage
      : selectedGroup?.accountIds;

    return (accounts || [])?.map((account) => ({
      id: account.id,
      name: account.name,
      selected: ids?.length ? ids.includes(account.id) : true,
    }));
  }, [
    accounts,
    selectedGroup,
    selectedAccountsFromLocalStorage,
    isSharedPortfolio,
  ]);

  return {
    selectedAccounts: formattedAccounts,
    refresh,
  };
};
