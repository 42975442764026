import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { InvestmentsActionButton } from "@features/investments/components/InvestmentsActionButton";
import { ReactComponent as LineChartIcon } from "@icons/line-chart.svg";
import { PortfolioShareModal } from "@components/Modal/PortfolioShareModal/PortfolioShareModal";
import { ReactComponent as ShareIcon } from "@icons/share.svg";

export const HeaderButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);

  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  if (isDownMd)
    return (
      <Box>
        <Box display="flex" gap={4}>
          <IconButton color="default" onClick={() => setIsShareModalOpen(true)}>
            <ShareIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => navigate("/depots/add")}>
            <LineChartIcon width="16px" height="16px" />
          </IconButton>
          <InvestmentsActionButton />
        </Box>
        <PortfolioShareModal
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
        />
      </Box>
    );

  return (
    <Box>
      <Box display="flex" gap={4}>
        <Button
          variant="text"
          color="primary"
          onClick={() => setIsShareModalOpen(true)}
          startIcon={<ShareIcon />}
        >
          {t("portfolioShare.share")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          startIcon={<LineChartIcon width="16px" height="16px" />}
          onClick={() => navigate("/depots/add")}
        >
          {t("investments.newPortfolio")}
        </Button>
        <InvestmentsActionButton />
      </Box>
      <PortfolioShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
    </Box>
  );
};
