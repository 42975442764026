import React, { createContext, useContext, useMemo } from "react";
import { useGetCategories, useGetSubcategories } from "@generated/apiv3/hooks";

type SubcategoryName<T> = T extends CategoryType.INVESTMENT
  ? InvestmentSubcategoryEnum
  : T extends CategoryType.ACCOUNT
  ? AccountSubcategoryEnum
  : string;

export enum AccountSubcategoryEnum {
  "CASH_ACCOUNT" = "CASH_ACCOUNT",
  "DEPOT" = "DEPOT",
  "REAL_ESTATE" = "REAL_ESTATE",
  "LOAN" = "LOAN",
  "CREDIT_CARD" = "CREDIT_CARD",
  "CRYPTO" = "CRYPTO",
  "PRECIOUS_METALS" = "PRECIOUS_METALS",
  "OTHER_MATERIAL_ASSETS" = "OTHER_MATERIAL_ASSETS",
}

export enum InvestmentSubcategoryEnum {
  "REAL_ESTATE" = "REAL_ESTATE",
}

export enum CategoryType {
  INVESTMENT = "INVESTMENT_CATEGORIZATION",
  ACCOUNT = "ACCOUNT_CATEGORIZATION",
}

export type Subcategory<T extends CategoryType> = {
  id: number;
  name: SubcategoryName<T>;
  categoryType: T;
};

interface SubcategoriesContextProps {
  subcategories?: Subcategory<CategoryType>[];
  isLoading: boolean;
}

const SubcategoriesContext = createContext<
  SubcategoriesContextProps | undefined
>(undefined);

export const useSubcategoriesContext = (): SubcategoriesContextProps => {
  const context = useContext(SubcategoriesContext);
  if (!context) {
    throw new Error(
      "useSubcategories must be used within a SubcategoriesProvider"
    );
  }
  return context;
};

export const useSubcategory = <T extends CategoryType>({
  id,
  name,
  categoryType,
}: {
  id?: number;
  name?: SubcategoryName<T>;
  categoryType?: T;
} = {}): Subcategory<T> | undefined => {
  const { subcategories } = useSubcategoriesContext();

  return useMemo(
    () =>
      subcategories?.find((subcategory) => {
        if (id) return subcategory.id === id;

        return (
          (categoryType ? subcategory.categoryType === categoryType : true) &&
          (name ? subcategory.name === name : true)
        );
      }) as Subcategory<T>,
    [subcategories, id, name, categoryType]
  );
};

export const useSubcategories = <T extends CategoryType>({
  id,
  name,
  categoryType,
}: {
  id?: number;
  name?: SubcategoryName<T>;
  categoryType?: T;
} = {}): {
  subcategories?: Subcategory<T>[];
  isLoading: boolean;
} => {
  const { subcategories, isLoading } = useSubcategoriesContext();
  const filteredSubcategories = useMemo(
    () =>
      subcategories?.filter((subcategory) => {
        if (id) return subcategory.id === id;

        return (
          (categoryType ? subcategory.categoryType === categoryType : true) &&
          (name ? subcategory.name === name : true)
        );
      }) as Subcategory<T>[],
    [subcategories, id, name, categoryType]
  );

  return {
    subcategories: filteredSubcategories,
    isLoading,
  };
};

export const SubcategoriesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { data: categories, isLoading: isCategoriesLoading } = useGetCategories(
    {},
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: subcategories, isLoading: isSubCategoriesLoading } =
    useGetSubcategories(
      {},
      {
        refetchOnWindowFocus: false,
      }
    );

  const mappedSubcategories: Subcategory<CategoryType>[] | undefined = useMemo(
    () =>
      subcategories?.data?.map((subcategory) => ({
        id: Number(subcategory.id),
        name: subcategory.attributes.name as SubcategoryName<CategoryType>,
        categoryType: categories?.data?.find(
          (category) =>
            Number(category.id) === subcategory.attributes.category_id
        )?.attributes.category_type as CategoryType,
      })),
    [categories, subcategories]
  );

  return (
    <SubcategoriesContext.Provider
      value={{
        subcategories: mappedSubcategories,
        isLoading: isCategoriesLoading || isSubCategoriesLoading,
      }}
    >
      {children}
    </SubcategoriesContext.Provider>
  );
};
