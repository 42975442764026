import { MutateOptions, UseQueryOptions } from "react-query";
import {
  Account,
  AccountList,
  AssetCategorization,
  CreateOrUpdateAccountParams,
  GetAccountParams,
  GetAccountsCategorizeParams,
} from "@api/v1/model";
import {
  purgeAccount,
  getAccountsCategorize,
  getAccounts,
  getAccount,
  createAccount,
  updateAccount,
  deleteAccount,
} from "@api/v1/methods";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_CATEGORIZE,
  MY_DEPOTS,
} from "../../cacheKeys";

export const useMyDepots = (
  options: UseQueryOptions<AccountList, AxiosApiError, AccountList> = {
    enabled: true,
  }
) =>
  useAuthQuery(
    [MY_DEPOTS],
    ({ token }) => getAccounts(token, { type: "01_depot" }),
    options
  );

export const useGetAccount = (
  id?: number,
  options?: UseQueryOptions<Account, AxiosApiError, Account>
) =>
  useAuthQuery(["get-account", id], ({ token }) => getAccount(token, id!), {
    enabled: !!id,
    ...(options || {}),
  });

export const useAccounts = (
  params: GetAccountParams = {},
  options: UseQueryOptions<AccountList, AxiosApiError, AccountList> = {
    enabled: true,
  }
) =>
  useAuthQuery(
    [GET_ACCOUNTS, params],
    ({ token }) => getAccounts(token, params),
    options
  );

export const useCreateAccount = () => useAuthMutation(createAccount);

export const useDeleteAccount = (
  options?: MutateOptions<Account, AxiosApiError, number>
) => useAuthMutation(deleteAccount, options);

export const useUpdateAccount = (
  options?: MutateOptions<
    Account,
    AxiosApiError,
    CreateOrUpdateAccountParams & { id: number }
  >
) => useAuthMutation(updateAccount, options);

export const usePurgeAccount = (
  options?: MutateOptions<Account, AxiosApiError, string>
) => useAuthMutation(purgeAccount, options);

export const useGetAccountsCategorize = (
  params?: GetAccountsCategorizeParams,
  options: UseQueryOptions<
    AssetCategorization,
    AxiosApiError,
    AssetCategorization
  > = {}
) =>
  useAuthQuery(
    [GET_ACCOUNTS_CATEGORIZE, params],
    ({ token }) => getAccountsCategorize(token, params),
    options
  );
