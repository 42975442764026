import { UseMutationOptions } from "react-query";
import { BookingsApiConsolidateNegativeNolRequest } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const consolidateNegativeNol = async (
  token: string | undefined,
  params: BookingsApiConsolidateNegativeNolRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.consolidateNegativeNol(params);
  return data;
};

export const useConsolidateNegativeNol = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BookingsApiConsolidateNegativeNolRequest
  >
) => {
  return useAuthMutation(consolidateNegativeNol, options, false);
};
