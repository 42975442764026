import React from "react";
import { Box, BoxProps, styled } from "@mui/material";
import { LoadingSpinner } from "./LoadingSpinner";

const LoadingSpinnerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 500,
}));

const LoadingBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  backgroundColor: theme.palette.background.default,
  opacity: 0.7,
}));

type Props = {
  children: React.ReactNode;
  isLoading: boolean;
  spinnerWidth?: number;
} & BoxProps;

export const WithLoadingSpinner = ({
  children,
  spinnerWidth = 80,
  isLoading,
  ...rest
}: Props) => {
  return (
    <Box
      position="relative"
      sx={{
        pointerEvents: isLoading ? "none" : "auto",
      }}
      width="100%"
      {...rest}
    >
      {isLoading && (
        <LoadingSpinnerContainer>
          <LoadingBackground />
          <LoadingSpinner width={spinnerWidth} />
        </LoadingSpinnerContainer>
      )}
      {children}
    </Box>
  );
};
