import { UseQueryOptions } from "react-query";
import { CurrenciesApiV1GetCurrencyRequest, Currency } from "@generated/apiv1";

import { buildCurrenciesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const v1GetCurrency = async (
  token: string | undefined,
  params: CurrenciesApiV1GetCurrencyRequest
) => {
  const apiInstance = buildCurrenciesApi(token);
  const { data } = await apiInstance.v1GetCurrency(params);
  return data;
};

export const useV1GetCurrency = (
  params: CurrenciesApiV1GetCurrencyRequest,
  options?: UseQueryOptions<Currency, AxiosApiError, Currency>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CURRENCIES_V1_GET_CURRENCY, params],
    ({ token }) => v1GetCurrency(token, params),
    options
  );
};
