/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankConsent } from './bank-consent';
// May contain unused imports in some cases
// @ts-ignore
import { LoginCredentialResource } from './login-credential-resource';
// May contain unused imports in some cases
// @ts-ignore
import { TwoStepProcedure } from './two-step-procedure';
// May contain unused imports in some cases
// @ts-ignore
import { UpdateResult } from './update-result';

/**
 * Resource representing a bank connection interface
 * @export
 * @interface BankConnectionInterface
 */
export interface BankConnectionInterface {
    /**
     * Login fields for this interface, in the order that we suggest to show them to the user.
     * @type {Array<LoginCredentialResource>}
     * @memberof BankConnectionInterface
     */
    'loginCredentials'?: Array<LoginCredentialResource>;
    /**
     * The default two-step-procedure for this interface. Must match one of the available \'procedureId\'s from the \'twoStepProcedures\' list. When this field is set, then finAPI will automatically try to select the procedure wherever applicable. Note that the list of available procedures of a bank connection may change as a result of an update of the connection, and if this field references a procedure that is no longer available after an update, finAPI will automatically clear the default procedure (set it to null).
     * @type {string}
     * @memberof BankConnectionInterface
     */
    'defaultTwoStepProcedureId'?: string;
    /**
     * Available two-step-procedures in this interface, used for submitting a money transfer or direct debit request (see /accounts/requestSepaMoneyTransfer or /requestSepaDirectDebit),or for multi-step-authentication during bank connection import or update. The available two-step-procedures mya be re-evaluated each time this bank connection is updated (/bankConnections/update). This means that this list may change as a result of an update.
     * @type {Array<TwoStepProcedure>}
     * @memberof BankConnectionInterface
     */
    'twoStepProcedures'?: Array<TwoStepProcedure>;
    /**
     * 
     * @type {BankConsent}
     * @memberof BankConnectionInterface
     */
    'aisConsent'?: BankConsent;
    /**
     * 
     * @type {UpdateResult}
     * @memberof BankConnectionInterface
     */
    'lastManualUpdate'?: UpdateResult;
    /**
     * 
     * @type {UpdateResult}
     * @memberof BankConnectionInterface
     */
    'lastAutoUpdate'?: UpdateResult;
    /**
     * This field indicates whether the user\'s attention is required for the next update of the given bank connection interface.<br/>If the field is true, finAPI stops auto-updates of this bank connection interface to mitigate the risk of locking the user\'s bank account and also of triggering a multi-step authentication that might lead to a notification being sent to the end-user.<br/>Every communication with the bank (e.g. updating a bank connection, submitting a money transfer or a direct debit, etc.) can change the value of this flag. If the field is true, we recommend to ask the end-user to trigger a manual update of the bank connection interface (using the \'Update a bank connection\' service). If the update completes successfully without triggering a strong customer authentication or results in storing a valid XS2A consent, this flag will switch to false. The logic about determination of the user\'s attention being required might change in time. Please use this as a convenience function to know, when you have to involve the user in the next communication with the bank. Once the flag switches to false, the bank connection interface will be enabled again for the auto-update (if it is configured).
     * @type {boolean}
     * @memberof BankConnectionInterface
     */
    'userActionRequired': boolean;
    /**
     * 
     * @type {number}
     * @memberof BankConnectionInterface
     */
    'maxDaysForDownload'?: number;
    /**
     * Bank interface. Possible values:<br><br>&bull; <code>FINTS_SERVER</code> - means that finAPI will download data via the bank\'s FinTS interface.<br>&bull; <code>WEB_SCRAPER</code> - means that finAPI will parse data from the bank\'s online banking website.<br>&bull; <code>XS2A</code> - means that finAPI will download data via the bank\'s XS2A interface.<br>
     * @type {string}
     * @memberof BankConnectionInterface
     */
    'interface': BankConnectionInterfaceInterfaceEnum;
}

export const BankConnectionInterfaceInterfaceEnum = {
    WEB_SCRAPER: 'WEB_SCRAPER',
    FINTS_SERVER: 'FINTS_SERVER',
    XS2_A: 'XS2A'
} as const;

export type BankConnectionInterfaceInterfaceEnum = typeof BankConnectionInterfaceInterfaceEnum[keyof typeof BankConnectionInterfaceInterfaceEnum];


