import { UseMutationOptions } from "react-query";
import { AuthApiLoginRequest, LoginData } from "@generated/user";

import { buildAuthApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const login = async (
  token: string | undefined,
  params: AuthApiLoginRequest
) => {
  const apiInstance = buildAuthApi(token);
  const { data } = await apiInstance.login(params);
  return data;
};

export const useLogin = (
  options?: UseMutationOptions<LoginData, AxiosApiError, AuthApiLoginRequest>
) => {
  return useAuthMutation(login, options, true);
};
