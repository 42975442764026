import { UseQueryOptions } from "react-query";
import {
  AccountsApiListAndSearchAllRemoteAccountsRequest,
  FinApiAccountList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllRemoteAccounts = async (
  token: string | undefined,
  params?: AccountsApiListAndSearchAllRemoteAccountsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.listAndSearchAllRemoteAccounts(params);
  return data;
};

export const useListAndSearchAllRemoteAccounts = (
  params?: AccountsApiListAndSearchAllRemoteAccountsRequest,
  options?: UseQueryOptions<FinApiAccountList, AxiosApiError, FinApiAccountList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_REMOTE_ACCOUNTS, params],
    ({ token }) => listAndSearchAllRemoteAccounts(token, params),
    options
  );
};
