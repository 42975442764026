import { UseQueryOptions } from "react-query";
import { QuotesApiGetQuoteRequest, Quote } from "@generated/apiv1";

import { buildQuotesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getQuote = async (
  token: string | undefined,
  params: QuotesApiGetQuoteRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getQuote(params);
  return data;
};

export const useGetQuote = (
  params: QuotesApiGetQuoteRequest,
  options?: UseQueryOptions<Quote, AxiosApiError, Quote>
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_QUOTE, params],
    ({ token }) => getQuote(token, params),
    options
  );
};
