import { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useCallback } from "react";
import { LoginToken, LoginParams } from "@generated/apiv1";
import { RefreshTokenError } from "@api/auth/RefreshTokenError";
import { TokenExpiredError } from "@api/auth/TokenExpiredError";
import { LOGIN_TOKEN_QUERY_CACHE_KEY } from "./cacheKeys";
import { isAuthError } from "./isAuthError";
import { NoTokenError } from "./NoTokenError";
import { LOGIN_TOKEN_LOCAL_STORAGE_KEY, saveToStorage } from "./saveToStorage";
import { getAccessToken, getAccessTokenQueryFn } from "./getAccessTokenQueryFn";
import { isExpired } from "./utils";

interface LoginParamsWithRememberMe extends LoginParams {
  rememberMe?: boolean;
}

const retry = (
  failureCount: number,
  error: NoTokenError | RefreshTokenError | AxiosError | TokenExpiredError
) => {
  if (isAuthError(error)) {
    return false;
  }
  if ((error as AxiosError).response?.status === 403) {
    return false;
  }
  if (failureCount > 2) {
    return false;
  }

  return true;
};

interface Options
  extends UseQueryOptions<Token | undefined, AxiosError | NoTokenError, Token> {
  shouldUpdateQuery?: boolean;
  key?: string;
}

export const useToken = ({
  shouldUpdateQuery = true,
  key = LOGIN_TOKEN_QUERY_CACHE_KEY,
  ...options
}: Options = {}) => {
  const queryClient = useQueryClient();
  const isEnabled = options?.enabled === undefined ? true : options.enabled;

  const {
    mutate: requestToken,
    mutateAsync: requestTokenAsync,
    ...getTokenQuery
  } = useMutation<LoginToken, AxiosError, LoginParamsWithRememberMe, unknown>(
    getAccessToken,
    {
      onSuccess: (newToken, params) => {
        const token = saveToStorage(newToken, params.rememberMe);
        // this is to stop wizard from reloading when setting the query data. if you have other ideas how to fix - welcome
        if (shouldUpdateQuery) {
          queryClient.setQueryData<Token>(LOGIN_TOKEN_QUERY_CACHE_KEY, token);
        }
      },
    }
  );

  const logout = useCallback(() => {
    queryClient.setQueryData<undefined>(LOGIN_TOKEN_QUERY_CACHE_KEY, undefined);
    localStorage.removeItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY);
    sessionStorage.removeItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY);
  }, [queryClient]);

  const tokenQuery = useQuery<
    Token | undefined,
    AxiosError | NoTokenError,
    Token
  >([key], getAccessTokenQueryFn(queryClient), {
    ...options,
    retry,
    onError: (error) => {
      if (
        error instanceof RefreshTokenError ||
        error instanceof TokenExpiredError
      ) {
        logout();
      }
      options?.onError?.(error);
    },
    // this is to stop wizard from reloading when setting the query data. if you have other ideas how to fix - welcome
    enabled: shouldUpdateQuery && isEnabled,
  });

  return {
    ...tokenQuery,
    isExpired: tokenQuery.data ? isExpired(tokenQuery.data) : undefined,
    getToken: requestToken,
    getTokenAsync: requestTokenAsync,
    logout,
    getTokenQuery,
  };
};
