import { UseMutationOptions } from "react-query";
import {
  AccountsApiLinkToRemoteAccountRequest,
  Account,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const linkToRemoteAccount = async (
  token: string | undefined,
  params: AccountsApiLinkToRemoteAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.linkToRemoteAccount(params);
  return data;
};

export const useLinkToRemoteAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiLinkToRemoteAccountRequest
  >
) => {
  return useAuthMutation(linkToRemoteAccount, options, false);
};
