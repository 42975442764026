import { useEffect, useState } from "react";
import { buildMasterDataApiWithToken } from "@generated/apiv1/apiBuilder";
import { StringList } from "@generated/apiv1";

export const useMasterData = (name: string) => {
  const [values, setValues] = useState<StringList>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryFn = async () => {
      const masterDataApi = await buildMasterDataApiWithToken();
      const { data } = await masterDataApi.getMasterData({ id: name });
      return data;
    };

    setIsLoading(true);

    queryFn()
      .then((result) => {
        setValues(result);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  }, [name]);

  return { data: values, isLoading };
};
