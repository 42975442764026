import { UseMutationOptions } from "react-query";
import { MandatorsApiUpdateMandatorRequest, Mandator } from "@generated/apiv1";

import { buildMandatorsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateMandator = async (
  token: string | undefined,
  params: MandatorsApiUpdateMandatorRequest
) => {
  const apiInstance = buildMandatorsApi(token);
  const { data } = await apiInstance.updateMandator(params);
  return data;
};

export const useUpdateMandator = (
  options?: UseMutationOptions<
    Mandator,
    AxiosApiError,
    MandatorsApiUpdateMandatorRequest
  >
) => {
  return useAuthMutation(updateMandator, options, false);
};
