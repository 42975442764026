/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { SaasQuote } from './saas-quote';

/**
 * 
 * @export
 * @interface SaasSecurity
 */
export interface SaasSecurity {
    /**
     * Technical identifier
     * @type {number}
     * @memberof SaasSecurity
     */
    'id'?: number;
    /**
     * Account Id; not required for batch operations
     * @type {number}
     * @memberof SaasSecurity
     */
    'accountId'?: number;
    /**
     * Name of the security
     * @type {string}
     * @memberof SaasSecurity
     */
    'name': string;
    /**
     * ISIN of the security if applicable
     * @type {string}
     * @memberof SaasSecurity
     */
    'isin'?: string;
    /**
     * The security type; if not provided the type will be resolved via the quote API              based on the ISIN. Note that type resolution will fail for securities that do not have an ISIN or for              which no proper ISIN standard exists (e.g. cryptos). In this case, the type must be provided manually.
     * @type {string}
     * @memberof SaasSecurity
     */
    'type'?: SaasSecurityTypeEnum;
    /**
     * Ticker symbol of the security; will be resolved automatically if not provided.         To speed up crypto-import we highly recommend to provide the ticker symbol for cryptos
     * @type {string}
     * @memberof SaasSecurity
     */
    'tickerSymbol'?: string;
    /**
     * The quote provider of the security; will be resolved automatically if not provided.         To speed up crypto-import we highly recommend to provide the quote provider for cryptos
     * @type {string}
     * @memberof SaasSecurity
     */
    'quoteProvider'?: string;
    /**
     * WKN of the security if applicable
     * @type {string}
     * @memberof SaasSecurity
     */
    'wkn'?: string;
    /**
     * 
     * @type {SaasQuote}
     * @memberof SaasSecurity
     */
    'quote'?: SaasQuote;
    /**
     * 
     * @type {SaasQuote}
     * @memberof SaasSecurity
     */
    'entryQuote'?: SaasQuote;
    /**
     * The number of lots currently held by the bank or broker
     * @type {number}
     * @memberof SaasSecurity
     */
    'quantityNominal': number;
    /**
     * The type of the quantity nominal. The typical case is LOTS for stocks, funds, etc.         A quantity of AMOUNT typically indicates a bond.
     * @type {string}
     * @memberof SaasSecurity
     */
    'quantityNominalType'?: SaasSecurityQuantityNominalTypeEnum;
    /**
     * The market value of the security (typically quote * quantityNominal)
     * @type {number}
     * @memberof SaasSecurity
     */
    'marketValue'?: number;
    /**
     * The currency of the market value
     * @type {string}
     * @memberof SaasSecurity
     */
    'marketValueCurrency'?: string;
    /**
     * A profit or loss, if delivered by the bank
     * @type {number}
     * @memberof SaasSecurity
     */
    'profitOrLoss'?: number;
}

export const SaasSecurityTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;

export type SaasSecurityTypeEnum = typeof SaasSecurityTypeEnum[keyof typeof SaasSecurityTypeEnum];
export const SaasSecurityQuantityNominalTypeEnum = {
    LOTS: 'LOTS',
    AMOUNT: 'AMOUNT'
} as const;

export type SaasSecurityQuantityNominalTypeEnum = typeof SaasSecurityQuantityNominalTypeEnum[keyof typeof SaasSecurityQuantityNominalTypeEnum];


