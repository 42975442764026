import { UseQueryOptions, UseQueryResult } from "react-query";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/browser";
import { useAuthQuery, useToken } from "@api/auth";
import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { User } from "@generated/apiv1";

const ROLE_FINANZFLUSS_PLUS_SUBSCRIPTION = "ROLE_FINANZFLUSS_PLUS_SUBSCRIPTION";
const ROLE_PRO_SUBSCRIPTION = "ROLE_PRO_SUBSCRIPTION";

export type UserWithType = User & {
  isUserPro: boolean;
  isUserFree: boolean;
};

export const loadMyself = async (
  token: string | undefined
): Promise<UserWithType> => {
  const api = buildUsersApi(token);
  const response = await api.usersMyself();
  let isUserPro = false;
  try {
    const parsedToken = token ? jwtDecode<{ roles: string[] }>(token) : null;
    isUserPro =
      parsedToken?.roles?.includes(ROLE_FINANZFLUSS_PLUS_SUBSCRIPTION) ||
      parsedToken?.roles?.includes(ROLE_PRO_SUBSCRIPTION) ||
      false;
  } catch (e) {
    Sentry.captureException(e);
  }

  const isUserFree = !isUserPro;

  return {
    ...response.data,
    isUserPro: Boolean(isUserPro),
    isUserFree,
  };
};

export const useMyself = (
  options?: UseQueryOptions<UserWithType, AxiosApiError, UserWithType>
): UseQueryResult<UserWithType> => {
  const { data: token, isFetched } = useToken();
  const isEnabled = options?.enabled === undefined ? true : !!options?.enabled;
  const requestData = useAuthQuery("myself", ({ token }) => loadMyself(token), {
    ...options,
    enabled: !!token && isEnabled,
  });

  return {
    ...requestData,
    isFetched: isFetched ? (token ? requestData.isFetched : true) : false,
    data: {
      isUserPro: false,
      isUserFree: true,
      ...requestData.data,
    },
  } as UseQueryResult<UserWithType>;
};
