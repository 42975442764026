import { useQuery } from "react-query";
import { loadHistoricQuotesLookup } from "@api";

const loadHistoricQuotesPerYear = ({
  quoteProvider,
  tickerSymbol,
  from,
}: {
  quoteProvider: string;
  tickerSymbol: string;
  from?: string;
}) =>
  loadHistoricQuotesLookup({
    tickerSymbol,
    quoteProvider,
    from,
    intervalPosition: "last",
    interval: "year",
  });

export const useHistoricQuoteLookupPerYear = (
  quoteProvider?: string,
  tickerSymbol?: string,
  from?: string,
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) =>
  useQuery(
    [
      "historic-quotes-lookup-per-year",
      { quoteProvider: quoteProvider!, tickerSymbol: tickerSymbol!, from },
    ],
    () =>
      loadHistoricQuotesPerYear({
        quoteProvider: quoteProvider!,
        tickerSymbol: tickerSymbol!,
        from,
      }),
    {
      enabled: !!quoteProvider && !!tickerSymbol && enabled,
    }
  );

const loadHistoricQuotesPercent = ({
  quoteProvider,
  tickerSymbol,
  from,
}: {
  quoteProvider: string;
  tickerSymbol: string;
  from?: string;
}) =>
  loadHistoricQuotesLookup({
    tickerSymbol,
    quoteProvider,
    from,
    inPercentFrom: "currentToNow",
    intervalPosition: "current",
    interval: "year",
  });

export const useHistoricQuotesLookupPercent = (
  quoteProvider?: string,
  tickerSymbol?: string,
  from?: string
) =>
  useQuery(
    [
      "historic-quotes-lookup-percent",
      { quoteProvider: quoteProvider!, tickerSymbol: tickerSymbol!, from },
    ],
    () =>
      loadHistoricQuotesPercent({
        quoteProvider: quoteProvider!,
        tickerSymbol: tickerSymbol!,
        from,
      }),
    {
      enabled: !!quoteProvider && !!tickerSymbol,
    }
  );
