import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { uniq, uniqBy } from "lodash";
import {
  DividendTimelineTypeEnum,
  InvestmentReference,
  InvestmentTypeEnum,
} from "@generated/apiv1";
import { SelectOption } from "@components/common/forms/MultipleSelect";
import {
  Filter,
  useDividendsContext,
} from "@features/dividendPlanner/DividendsProvider";

export const filterDividends = (
  investments: InvestmentReference[] | undefined,
  filterType: "investmentType" | "investmentIsins",
  filters: Filter
) => {
  if (!investments) return [];

  return investments.filter((investment) => {
    switch (filterType) {
      case "investmentType":
        return filters.investmentIsins?.length
          ? filters.investmentIsins.includes(investment.isin)
          : true;
      case "investmentIsins":
        return (
          (filters.investmentTypes?.length
            ? filters.investmentTypes.includes(
                investment.type as DividendTimelineTypeEnum
              )
            : true) &&
          (filters.investmentIsins?.length
            ? !filters.investmentIsins.includes(investment.isin)
            : true)
        );

      default:
        return true;
    }
  });
};

export const useDividendFilters = (
  filters: Filter
): {
  investmentIsins: number[];
  investmentTypes: SelectOption<InvestmentTypeEnum>[];
} => {
  const { t } = useTranslation();
  const { investmentsForFilters } = useDividendsContext();
  const investments = Object.values(investmentsForFilters || {});

  const investmentIsins = useMemo(() => {
    return uniq(
      filterDividends(investments, "investmentIsins", filters)?.map(
        (investment) => investment.id
      )
    );
  }, [investments, filters]);

  const investmentTypes = useMemo(() => {
    return uniqBy(
      filterDividends(investments, "investmentType", filters)?.map(
        (investment) => ({
          value: investment.type as InvestmentTypeEnum,
          label: t(`investmentTypes.${investment.type}_plural`),
        })
      ),
      (item) => item.value
    );
  }, [investments, filters, t]);

  return {
    investmentIsins,
    investmentTypes,
  };
};
