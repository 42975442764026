import {
  defaultContext,
  TransactionWizardContext,
} from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";

export const clearSymbolAction = (context: TransactionWizardContext) => {
  return {
    symbol: defaultContext.symbol,
  };
};
