import React, { FunctionComponent, useContext } from "react";
import { get } from "lodash";
import styled from "@emotion/styled";
import { SharedStepProps, StepComponent } from "./StepComponent";
import { DataContext, LoadingContext, ChangeDataContext } from "./Wizard";

type WithReferenceProps<
  C,
  K extends keyof C,
  NK1 extends keyof C[K]
> = SharedStepProps & {
  onNext: (
    stepNumber: number,
    name: K | [K, NK1],
    value?: C[K][NK1] | C[K]
  ) => void;
};

export type WithReferenceComponent<C> = FunctionComponent<
  WithReferenceProps<C, keyof C, any>
>;

const Container = styled.div`
  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    `pointer-events: none;
  opacity: 0.4;`}
`;

export function withReference<C extends {}, K extends keyof C>(
  name: K,
  component: StepComponent<C[K], C>
): WithReferenceComponent<C>;

export function withReference<
  C extends {},
  K extends keyof C,
  NK1 extends keyof C[K]
>(
  name: [K, NK1],
  component: StepComponent<C[K][NK1], C>
): WithReferenceComponent<C>;

export function withReference<
  C extends {},
  K extends keyof C,
  NK1 extends keyof C[K]
>(
  name: [K, NK1] | K,
  component: StepComponent<C[K][NK1] | C[K], C>
): WithReferenceComponent<C> {
  const WithReference: WithReferenceComponent<C> = ({
    onNext,
    onPrevious,
    focus,
    stepNumber,
    isFirst,
    isLast,
    validationError,
  }) => {
    const loading = useContext(LoadingContext);
    const context = useContext(DataContext);
    const dispatch = useContext(ChangeDataContext);

    const handleNext = (value?: C[K] | C[K][NK1]) => {
      onNext(stepNumber, name, value);
    };

    const defaultValue = Array.isArray(name)
      ? get(context.data, name.join("."))
      : context.data[name];

    return (
      <Container
        isLoading={loading}
        data-testid="step-container"
        data-is-loading={loading}
      >
        {React.createElement(component, {
          onPrevious,
          focus,
          stepNumber,
          isFirst,
          isLast,
          validationError,
          onNext: handleNext,
          defaultValue,
          context: context.data,
          loading,
          dispatch,
        })}
      </Container>
    );
  };
  WithReference.displayName = `WithReference(${String(name)})`;
  return WithReference;
}
