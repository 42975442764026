import { UseQueryOptions } from "react-query";
import { WatchlistsApiGetWatchlistRequest, Watchlist } from "@generated/apiv1";

import { buildWatchlistsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getWatchlist = async (
  token: string | undefined,
  params: WatchlistsApiGetWatchlistRequest
) => {
  const apiInstance = buildWatchlistsApi(token);
  const { data } = await apiInstance.getWatchlist(params);
  return data;
};

export const useGetWatchlist = (
  params: WatchlistsApiGetWatchlistRequest,
  options?: UseQueryOptions<Watchlist, AxiosApiError, Watchlist>
) => {
  return useAuthQuery(
    [CACHE_KEYS.WATCHLISTS_GET_WATCHLIST, params],
    ({ token }) => getWatchlist(token, params),
    options
  );
};
