import { UseMutationOptions } from "react-query";
import { BanksApiDeleteBankInterfaceSelectionOptionRequest } from "@generated/apiv1";

import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankInterfaceSelectionOption = async (
  token: string | undefined,
  params: BanksApiDeleteBankInterfaceSelectionOptionRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.deleteBankInterfaceSelectionOption(params);
  return data;
};

export const useDeleteBankInterfaceSelectionOption = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BanksApiDeleteBankInterfaceSelectionOptionRequest
  >
) => {
  return useAuthMutation(deleteBankInterfaceSelectionOption, options, false);
};
