import { useQuery, UseQueryOptions } from "react-query";

import { buildDividendsApiWithToken } from "@generated/apiv1/apiBuilder";
import {
  GET_DIVIDENDS_CALENDAR,
  GET_DIVIDENDS_HISTORY,
  GET_DIVIDENDS_PORTFOLIO_YIELD,
  GET_DIVIDENDS_PREDICTION,
} from "@api/cacheKeys";
import {
  DividendCalendarRecord,
  DividendHistoryTree,
  DividendsApiDividendCalendarRequest,
  DividendsApiDividendHistoryRequest,
  DividendsApiDividendPredictionRequest,
  DividendsApiDividendYieldRequest,
  DividendYieldList,
} from "@generated/apiv1";

export const getDividendCalendar = async (
  params: DividendsApiDividendCalendarRequest = {}
): Promise<DividendCalendarRecord[]> => {
  const dividendsApi = await buildDividendsApiWithToken();
  const { data } = await dividendsApi.dividendCalendar({
    ...params,
  });

  return data.dividendCalendarRecords || [];
};

export const useDividendCalendar = (
  params: DividendsApiDividendCalendarRequest,
  options?: UseQueryOptions<
    DividendCalendarRecord[],
    AxiosApiError,
    DividendCalendarRecord[],
    [string, DividendsApiDividendCalendarRequest]
  >
) => {
  return useQuery(
    [GET_DIVIDENDS_CALENDAR, params],
    () => getDividendCalendar(params),
    options
  );
};

export const getDividendsPortfolioYield = async (
  params: DividendsApiDividendYieldRequest
): Promise<DividendYieldList> => {
  const dividendsApi = await buildDividendsApiWithToken();
  const { data } = await dividendsApi.dividendYield({
    ...params,
  });
  return data;
};

export const useDividendsPortfolioYield = (
  params: DividendsApiDividendYieldRequest,
  options?: UseQueryOptions<
    DividendYieldList,
    AxiosApiError,
    DividendYieldList,
    [string, DividendsApiDividendYieldRequest]
  >
) => {
  return useQuery(
    [GET_DIVIDENDS_PORTFOLIO_YIELD, params],
    () => getDividendsPortfolioYield(params),
    options
  );
};

export const getDividendsHistory = async (
  params: DividendsApiDividendHistoryRequest
): Promise<DividendHistoryTree> => {
  const dividendsApi = await buildDividendsApiWithToken();
  const { data } = await dividendsApi.dividendHistory(params);

  return data;
};

export const useDividendsHistory = (
  params: DividendsApiDividendHistoryRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree,
    [string, DividendsApiDividendHistoryRequest]
  >
) => {
  return useQuery(
    [GET_DIVIDENDS_HISTORY, params],
    () => getDividendsHistory(params),
    options
  );
};

export const getDividendsPrediction = async (
  params: DividendsApiDividendPredictionRequest
): Promise<DividendHistoryTree> => {
  const dividendsApi = await buildDividendsApiWithToken();
  const { data } = await dividendsApi.dividendPrediction(params);
  return data;
};

export const useDividendsPrediction = (
  params: DividendsApiDividendPredictionRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree,
    [string, DividendsApiDividendPredictionRequest]
  >
) => {
  return useQuery(
    [GET_DIVIDENDS_PREDICTION, params],
    () => getDividendsPrediction(params),
    options
  );
};
