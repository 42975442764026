export const CACHE_KEYS = {
  ASSETREPORTS_ASSETS_TOTAL_MARKET_VALUE:
    "ASSETREPORTS_ASSETS_TOTAL_MARKET_VALUE",
  BANKCONNECTIONS_AUTHENTICATE_AT_BANK: "BANKCONNECTIONS_AUTHENTICATE_AT_BANK",
  BANKCONNECTIONS_AUTHENTICATE_VIA_BANK_CONNECTION:
    "BANKCONNECTIONS_AUTHENTICATE_VIA_BANK_CONNECTION",
  BANKCONNECTIONS_AUTHENTICATE_VIA_TPP_WEB_FORM:
    "BANKCONNECTIONS_AUTHENTICATE_VIA_TPP_WEB_FORM",
  BANKCONNECTIONS_CLEAR_CREDENTIALS: "BANKCONNECTIONS_CLEAR_CREDENTIALS",
  BANKCONNECTIONS_CONFIRM_CHALLENGE_RESPONSE:
    "BANKCONNECTIONS_CONFIRM_CHALLENGE_RESPONSE",
  BANKCONNECTIONS_DELETE_BANK_CONNECTION:
    "BANKCONNECTIONS_DELETE_BANK_CONNECTION",
  BANKCONNECTIONS_GET_BANK_CONNECTION: "BANKCONNECTIONS_GET_BANK_CONNECTION",
  BANKCONNECTIONS_GET_BANK_CONNECTION_VIA_TPP_WEB_FORM:
    "BANKCONNECTIONS_GET_BANK_CONNECTION_VIA_TPP_WEB_FORM",
  BANKCONNECTIONS_GET_BANK_CONNECTIONS_IN_BATCH:
    "BANKCONNECTIONS_GET_BANK_CONNECTIONS_IN_BATCH",
  BANKCONNECTIONS_IMPORT_BANK_CONNECTION:
    "BANKCONNECTIONS_IMPORT_BANK_CONNECTION",
  BANKCONNECTIONS_SHOW_AUTHENTICATION_PROCESS:
    "BANKCONNECTIONS_SHOW_AUTHENTICATION_PROCESS",
  BANKCONNECTIONS_UPDATE_AUTHENTICATION_PROCESS:
    "BANKCONNECTIONS_UPDATE_AUTHENTICATION_PROCESS",
  BANKERRORSREPORTS_BANK_ERRORS: "BANKERRORSREPORTS_BANK_ERRORS",
  BANKHEALTHREPORTS_BANK_HEALTH: "BANKHEALTHREPORTS_BANK_HEALTH",
  BANKMESSAGES_CREATE_BANK_MESSAGE: "BANKMESSAGES_CREATE_BANK_MESSAGE",
  BANKMESSAGES_DELETE_BANK_MESSAGE: "BANKMESSAGES_DELETE_BANK_MESSAGE",
  BANKMESSAGES_GET_BANK_MESSAGES: "BANKMESSAGES_GET_BANK_MESSAGES",
  BANKMESSAGES_UPDATE_BANK_MESSAGE: "BANKMESSAGES_UPDATE_BANK_MESSAGE",
  BANKSYNCS_GET_BANK_SYNC: "BANKSYNCS_GET_BANK_SYNC",
  BANKSYNCS_SHOW_BANK_SYNCS: "BANKSYNCS_SHOW_BANK_SYNCS",
  BANKS_GET_BANK: "BANKS_GET_BANK",
  BANKS_SHOW_BANKS: "BANKS_SHOW_BANKS",
  CATEGORIES_CRATE_CATEGORY: "CATEGORIES_CRATE_CATEGORY",
  CATEGORIES_DELETE_CATEGORY: "CATEGORIES_DELETE_CATEGORY",
  CATEGORIES_GET_CATEGORIES: "CATEGORIES_GET_CATEGORIES",
  CATEGORIES_GET_CATEGORY: "CATEGORIES_GET_CATEGORY",
  CATEGORIES_UPDATE_CATEGORY: "CATEGORIES_UPDATE_CATEGORY",
  CSV_IMPORT_CSV: "CSV_IMPORT_CSV",
  CSV_PARSE_CSV: "CSV_PARSE_CSV",
  ETFTYPECLASSIFICATION_LIST_ETF_TYPE_CLASSIFICATIONS:
    "ETFTYPECLASSIFICATION_LIST_ETF_TYPE_CLASSIFICATIONS",
  ETFTYPECLASSIFICATION_TRIGGER_ETF_TYPE_CLASSIFICATION_UPDATE:
    "ETFTYPECLASSIFICATION_TRIGGER_ETF_TYPE_CLASSIFICATION_UPDATE",
  ISINNAMES_GET_STANDARD_ISIN_NAMES: "ISINNAMES_GET_STANDARD_ISIN_NAMES",
  PLUGANDPLAY_ADD_TO_PRODUCT_WATCHLIST: "PLUGANDPLAY_ADD_TO_PRODUCT_WATCHLIST",
  PLUGANDPLAY_GET_QUESTIONS: "PLUGANDPLAY_GET_QUESTIONS",
  PLUGANDPLAY_LIST_PRODUCTS: "PLUGANDPLAY_LIST_PRODUCTS",
  PLUGANDPLAY_POST_ANSWERS: "PLUGANDPLAY_POST_ANSWERS",
  PLUGANDPLAY_RECOMMEND_PRODUCTS: "PLUGANDPLAY_RECOMMEND_PRODUCTS",
  PLUGANDPLAY_REMOVE_PRODUCT_FROM_WATCHLIST:
    "PLUGANDPLAY_REMOVE_PRODUCT_FROM_WATCHLIST",
  PLUGANDPLAY_SHOW_PRODUCT: "PLUGANDPLAY_SHOW_PRODUCT",
  PLUGANDPLAY_SHOW_PRODUCT_WATCHLIST: "PLUGANDPLAY_SHOW_PRODUCT_WATCHLIST",
  RATELIMIT_GET_BANK_TPP_WEB_FORM_RATE_LIMIT_STATUS:
    "RATELIMIT_GET_BANK_TPP_WEB_FORM_RATE_LIMIT_STATUS",
  SOCIALSTATISTICS_GET_TOP_HOLDINGS: "SOCIALSTATISTICS_GET_TOP_HOLDINGS",
  SOCIALSTATISTICS_GET_TOP_MOVERS: "SOCIALSTATISTICS_GET_TOP_MOVERS",
  SPLITS_APPLY_SPLITS_FOR_DEPOT: "SPLITS_APPLY_SPLITS_FOR_DEPOT",
  SPLITS_APPLY_SPLITS_FOR_DEPOTS: "SPLITS_APPLY_SPLITS_FOR_DEPOTS",
  SPLITS_BATCH_IMPORT_SPLITS: "SPLITS_BATCH_IMPORT_SPLITS",
  SPLITS_CRATE_SPLIT: "SPLITS_CRATE_SPLIT",
  SPLITS_DELETE_SPLIT: "SPLITS_DELETE_SPLIT",
  SPLITS_GET_SPLIT: "SPLITS_GET_SPLIT",
  SPLITS_GET_SPLITS_FOR_DEPOT: "SPLITS_GET_SPLITS_FOR_DEPOT",
  SPLITS_GET_SPLITS_FOR_DEPOTS: "SPLITS_GET_SPLITS_FOR_DEPOTS",
  SPLITS_IMPORT_SPLIT_BY_ISIN: "SPLITS_IMPORT_SPLIT_BY_ISIN",
  SPLITS_SHOW_SPLITS: "SPLITS_SHOW_SPLITS",
  SPLITS_UPDATE_SPLIT: "SPLITS_UPDATE_SPLIT",
  SUBCATEGORIES_CREATE_SUBCATEGORY: "SUBCATEGORIES_CREATE_SUBCATEGORY",
  SUBCATEGORIES_DELETE_SUBCATEGORY: "SUBCATEGORIES_DELETE_SUBCATEGORY",
  SUBCATEGORIES_GET_SUBCATEGORIES: "SUBCATEGORIES_GET_SUBCATEGORIES",
  SUBCATEGORIES_GET_SUBCATEGORY: "SUBCATEGORIES_GET_SUBCATEGORY",
  SUBCATEGORIES_UPDATE_SUBCATEGORY: "SUBCATEGORIES_UPDATE_SUBCATEGORY",
  TRACKINGEVENTS_TRACKING_EVENTS: "TRACKINGEVENTS_TRACKING_EVENTS",
  USERREPORTS_ACTIVE_USERS_BY_BANK: "USERREPORTS_ACTIVE_USERS_BY_BANK",
};
