import { UseMutationOptions } from "react-query";
import { MailingApiRecommendCashbackRequest } from "@generated/apiv1";

import { buildMailingApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const recommendCashback = async (
  token: string | undefined,
  params: MailingApiRecommendCashbackRequest
) => {
  const apiInstance = buildMailingApi(token);
  const { data } = await apiInstance.recommendCashback(params);
  return data;
};

export const useRecommendCashback = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    MailingApiRecommendCashbackRequest
  >
) => {
  return useAuthMutation(recommendCashback, options, false);
};
