import React from "react";
import * as Yup from "yup";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { format, isValid } from "date-fns";
import {
  Box,
  Button,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FormElement, renderFormElement, toString } from "@helpers";
import { InvestmentTypeEnum, BookingTypeEnum } from "@generated/apiv1";
import { Modal } from "@components";
import { useExchangeRates, useV1ListCurrencies } from "@generated/apiv1/hooks";
import { CsvRow } from "@generated/apiv3";
import { getInputAdornment } from "@features/csvDrop/helpers";
import { FieldLabel } from "@features/csvDrop/FieldLabel";
import { InputFieldWithRevert } from "@features/transactionWizard/components/NumberInputFieldWithRevert";

const stringToNumber = (value: string | number) =>
  Number.isNaN(value) ? null : value;

type CsvRowWithoutId = Omit<CsvRow, "id">;

interface FormFields {
  transaction_type: BookingTypeEnum;
  date: string;
  name: string;
  asset_type?: InvestmentTypeEnum;
  isin: string;
  number_of_lots: number;
  security_price: number;
  commission: number;
  tax_amount: number;
  currency: string;
  exchange_rate: number;
  // tax_and_commission_in_eur?: boolean;
}

const formFields: FormElement[] = [
  {
    name: "transaction_type",
    type: "select",
    options: Object.values(BookingTypeEnum).reduce(
      (acc, curr) => ({ ...acc, [curr]: i18next.t(`bookingTypes.${curr}`) }),
      {}
    ),
  },
  {
    name: "date",
    type: "date",
  },
  {
    name: "name",
    type: "text",
  },
  {
    name: "asset_type",
    type: "select",
    options: Object.values(InvestmentTypeEnum).reduce(
      (acc, curr) => ({ ...acc, [curr]: i18next.t(`investmentTypes.${curr}`) }),
      {}
    ),
  },
  {
    name: "isin",
    type: "text",
  },
  {
    name: "number_of_lots",
    type: "number",
  },
  {
    name: "security_price",
    type: "number",
  },
  {
    name: "commission",
    type: "number",
  },
  {
    name: "tax_amount",
    type: "number",
  },
  {
    name: "currency",
    type: "text",
  },
  // TODO: not implemented yet on the backend
  // {
  //   name: "tax_and_commission_in_eur",
  //   type: "switch",
  // },
  {
    name: "exchange_rate",
    type: "number",
  },
];

type Props = {
  parsingResult: CsvRow;
  onClose: () => void;
  isOpen: boolean;
  onSave: (result: CsvRow) => void;
};

export const ParsingResultEditModal = ({
  parsingResult,
  onClose,
  isOpen,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { data: currencies } = useV1ListCurrencies();
  const [exchangeRateTakeoverValue, setExchangeRateTakeoverValue] =
    React.useState<number>();

  const schema = Yup.object().shape({
    number_of_lots: Yup.number()
      .nullable()
      .required(t("form.errors.required"))
      .transform(stringToNumber),
    security_price: Yup.number()
      .nullable()
      .required(t("form.errors.required"))
      .transform(stringToNumber),
    name: Yup.string().required(t("form.errors.required")).nullable(),
    date: Yup.string()
      .required(t("form.errors.required"))
      .nullable()
      .test(
        "test-date",
        t("form.errors.wrongDateFormat"),
        (value: string | null | undefined) =>
          Boolean(value) && isValid(new Date(value as string))
      ),
    isin: Yup.string().required(t("form.errors.required")).nullable(),
    currency: Yup.string().required(t("form.errors.required")).nullable(),
    commission: Yup.number()
      .nullable()
      .required(t("form.errors.required"))
      .transform(stringToNumber),
    tax_amount: Yup.number()
      .nullable()
      .required(t("form.errors.required"))
      .transform(stringToNumber),
    asset_type: Yup.string().nullable(),
    transaction_type: Yup.string()
      .required(t("form.errors.required"))
      .nullable(),
    exchange_rate: Yup.number().nullable().transform(stringToNumber),
    // tax_and_commission_in_eur: Yup.boolean().nullable(),
  });

  const { control, handleSubmit, watch, setValue } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      number_of_lots: parsingResult.number_of_lots?.value,
      security_price: parsingResult.security_price?.value,
      name: parsingResult.name?.value,
      date: parsingResult.date?.value,
      isin: parsingResult.isin?.value,
      currency: parsingResult.currency?.value,
      commission: parsingResult.commission?.value ?? 0,
      tax_amount: parsingResult.tax_amount?.value ?? 0,
      asset_type: parsingResult.asset_type?.value,
      transaction_type: parsingResult.transaction_type?.value,
      exchange_rate: parsingResult.exchange_rate?.value,
      // tax_and_commission_in_eur: parsingResult.tax_and_commission_in_eur?.value,
    },
  });
  const currentCurrency = watch("currency");
  const currentAssetType = watch("asset_type");
  const currentDate = watch("date");
  // const taxAndCommissionInEur = watch("tax_and_commission_in_eur");
  const isFx = Boolean(currentCurrency) && currentCurrency !== "EUR";

  useExchangeRates(
    {
      from: "EUR",
      to: currentCurrency,
      date: isValid(currentDate)
        ? format(new Date(currentDate), "yyyy-MM-dd")
        : "",
    },
    {
      enabled: isFx && isValid(currentDate),
      onSuccess: (data) => {
        setExchangeRateTakeoverValue(data.rate);
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const { field: exchangeRateField } = useController({
    control,
    name: "exchange_rate",
    defaultValue: parsingResult.exchange_rate?.value,
  });

  const onSubmit = (data: FormFields) => {
    const keys = Object.keys(data);
    const newParsingResult = keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...parsingResult[key as keyof CsvRowWithoutId],
          error: undefined,
          value:
            key === "date"
              ? format(new Date(data[key]), "yyyy-MM-dd")
              : data[key as keyof CsvRowWithoutId],
        },
      }),
      { ...parsingResult } as CsvRow
    );

    onSave(newParsingResult);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("csvImport.parsingResults.edit")}
      height={isLessSm ? "100%" : "auto"}
      zIndex={4001}
    >
      <Grid
        container
        spacing={6}
        sx={{
          overflowY: "auto",
        }}
      >
        {formFields.map((field) => {
          if (
            (field.name === "exchange_rate" ||
              field.name === "tax_and_commission_in_eur") &&
            !isFx
          )
            return null;

          if (field.name === "currency") {
            return (
              <Grid item xs={isLessSm ? 12 : 6} key={field.name}>
                {renderFormElement(
                  "csvImport.parsingResults",
                  {
                    ...field,
                    type: "select",
                    options:
                      currencies?.currencies?.reduce(
                        (acc, currency) => ({
                          ...acc,
                          [currency.code!]: `${currency.code} - ${currency.name}`,
                        }),
                        {} as Record<string, string>
                      ) ?? [],
                  },
                  control,
                  {
                    label: (
                      <FieldLabel
                        name={field.name}
                        assetType={currentAssetType as InvestmentTypeEnum}
                        error={
                          parsingResult[field.name as keyof CsvRowWithoutId]
                            ?.error
                        }
                      />
                    ),
                  }
                )}
              </Grid>
            );
          }

          if (field.name === "exchange_rate") {
            return (
              <Grid item xs={12} key={field.name}>
                <InputFieldWithRevert
                  fieldLabel={
                    <FieldLabel
                      name={field.name}
                      assetType={currentAssetType as InvestmentTypeEnum}
                      error={
                        parsingResult[field.name as keyof CsvRowWithoutId]
                          ?.error
                      }
                    />
                  }
                  handleTakeover={(value: string) => {
                    setValue("exchange_rate", Number(value));
                    setExchangeRateTakeoverValue(undefined);
                  }}
                  takeoverDate={new Date(currentDate)}
                  takeoverValue={exchangeRateTakeoverValue}
                  id="exchange_rate"
                  value={toString(exchangeRateField.value)}
                  handleChange={exchangeRateField.onChange}
                />
              </Grid>
            );
          }

          return (
            <Grid item xs={isLessSm ? 12 : 6} key={field.name}>
              {renderFormElement("csvImport.parsingResults", field, control, {
                InputProps: {
                  endAdornment: (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      fontWeight={400}
                    >
                      {getInputAdornment(
                        field.name,
                        currentCurrency,
                        // taxAndCommissionInEur ?? false,
                        false,
                        currentAssetType as InvestmentTypeEnum
                      )}
                    </Typography>
                  ),
                },
                DialogProps: {
                  disablePortal: true,
                  sx: { zIndex: 5000 },
                },
                label: (
                  <FieldLabel
                    name={field.name}
                    assetType={currentAssetType as InvestmentTypeEnum}
                    error={
                      parsingResult[field.name as keyof CsvRowWithoutId]?.error
                    }
                  />
                ),
              })}
            </Grid>
          );
        })}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={isLessSm ? "column" : "row"}
        justifyContent="space-between"
        width="100%"
        gap={4}
        mt={8}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          fullWidth={isLessSm}
        >
          {t("navigation.previous")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          fullWidth={isLessSm}
        >
          {t("cashflowAnalyzer.categoryModal.save")}
        </Button>
      </Box>
    </Modal>
  );
};
