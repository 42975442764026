import { useRef } from "react";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { useToken } from "./useToken";

export type SecureMutationFunction<TData = unknown, TVariables = unknown> = (
  token: string | undefined,
  variables: TVariables
) => Promise<TData>;

export function useAuthMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: SecureMutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
  isFF: boolean = false
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { data: token } = useToken();
  const accessTokenRef = useRef<string>();
  const accessFFTokenRef = useRef<string>();
  accessTokenRef.current = token?.access_token;
  accessFFTokenRef.current = token?.ff_access_token;

  return useMutation(async (args) => {
    return mutationFn(
      isFF ? accessFFTokenRef.current : accessTokenRef.current || undefined,
      args
    );
  }, options);
}
