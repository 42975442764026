import { UseMutationOptions } from "react-query";
import { BookingsApiUpdateTransactionRequest, Booking } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateTransaction = async (
  token: string | undefined,
  params: BookingsApiUpdateTransactionRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.updateTransaction(params);
  return data;
};

export const useUpdateTransaction = (
  options?: UseMutationOptions<
    Booking,
    AxiosApiError,
    BookingsApiUpdateTransactionRequest
  >
) => {
  return useAuthMutation(updateTransaction, options, false);
};
