import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { ShortBank } from "@components/BankSelect";
import { BackButton } from "@components/BackButton";
import { blacklistBanks } from "@constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 736px;
  width: 100%;
`;

export interface BicSelectProps {
  banks: ShortBank[];
  onSelect?: (bank: ShortBank) => void;
  onCancel?: () => void;
  className?: string;
}

export const BicSelect = ({
  banks,
  onSelect,
  onCancel,
  className,
}: BicSelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-testid="bic-select" className={className}>
      <Box mb={6}>
        {onCancel && <BackButton onClick={onCancel} />}
        <Typography variant="h4" mb={1} mt={2}>
          {t("bankSelect.pageTitle")}
        </Typography>
        <Typography variant="body1" color="textSecondary" fontWeight={400}>
          <Trans
            i18nKey="bankSelect.selectBic.title"
            components={{ bold: <strong /> }}
          ></Trans>
        </Typography>
      </Box>
      <Grid container rowSpacing={2.5} columnSpacing={4} mb={6} width="100%">
        {banks.map((bank) => {
          if (blacklistBanks.includes(bank.bic!)) return null;
          return (
            <Grid item sm={6} md={4} xs={12} key={bank.bic}>
              <Box
                sx={{
                  height: "48px",
                  cursor: "pointer",
                  border: `1px solid ${theme.palette.border.secondary}`,
                  borderRadius: `${theme.shape.borderRadius}px`,

                  "&:hover": {
                    backgroundColor: theme.palette.background.subtle,
                  },
                }}
                data-testid="bic-button"
                onClick={() => onSelect?.(bank)}
              >
                <Box p={3} display="flex" alignItems="center">
                  <Typography>{bank.bic}</Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};
