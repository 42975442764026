import { UseMutationOptions } from "react-query";
import { UsersApiInitiateEmailChangeRequest } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const initiateEmailChange = async (
  token: string | undefined,
  params: UsersApiInitiateEmailChangeRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.initiateEmailChange(params);
  return data;
};

export const useInitiateEmailChange = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UsersApiInitiateEmailChangeRequest
  >
) => {
  return useAuthMutation(initiateEmailChange, options, false);
};
