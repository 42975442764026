import { UseMutationOptions } from "react-query";
import { ApplicationPropertiesApiDeleteApplicationPropertyRequest } from "@generated/apiv1";

import { buildApplicationPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteApplicationProperty = async (
  token: string | undefined,
  params: ApplicationPropertiesApiDeleteApplicationPropertyRequest
) => {
  const apiInstance = buildApplicationPropertiesApi(token);
  const { data } = await apiInstance.deleteApplicationProperty(params);
  return data;
};

export const useDeleteApplicationProperty = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    ApplicationPropertiesApiDeleteApplicationPropertyRequest
  >
) => {
  return useAuthMutation(deleteApplicationProperty, options, false);
};
