import React from "react";
import { TextField, Typography } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Symbol } from "@api";
import { SymbolSkeleton } from "./SymbolSkeleton";
import { SymbolList } from "./SymbolList";

const Container = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const NoResultsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export interface Props {
  search: string;
  handleChange: (search: string) => void;
  showLoadingSkeleton: boolean;
  showNoResults: boolean;
  symbols: Symbol[];
  onSelectSymbol: (symbol: Symbol) => void;
}

export const SymbolSearchInput = ({
  search,
  handleChange,
  symbols,
  showLoadingSkeleton,
  showNoResults,
  onSelectSymbol,
}: Props) => {
  const { t } = useTranslation();

  const debounced = useDebouncedCallback((value: string) => {
    handleChange(value);
  }, 500);

  return (
    <Container>
      <TextField
        fullWidth={true}
        defaultValue={search}
        inputProps={{ "data-testid": "symbol-search-input" }}
        label={t("symbolSearch.searchLabel")}
        onChange={(event) => debounced(event.target.value)}
        autoFocus
        id="symbol-search-input"
      />

      {showLoadingSkeleton &&
        [...Array.from(Array(3).keys())].map((ix) => {
          return <SymbolSkeleton key={ix} />;
        })}
      {!showNoResults && symbols.length > 0 && (
        <SymbolList symbols={symbols} handleClick={onSelectSymbol} />
      )}
      {showNoResults && (
        <NoResultsContainer>
          <Typography variant="body1">{t("symbolSearch.noData")}</Typography>
        </NoResultsContainer>
      )}
    </Container>
  );
};
