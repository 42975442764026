import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";

export const clearSourceInvestmentValuesAction = (
  context: TransactionWizardContext
) => {
  return {
    investmentValues: {
      ...(context.sourceInvestmentValues || context.investmentValues),
    },
    sourceInvestmentValues: undefined,
  };
};
