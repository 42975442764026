import { UseMutationOptions } from "react-query";
import {
  UsersApiUsersIdImpersonateRequest,
  AccessToken,
} from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const usersIdImpersonate = async (
  token: string | undefined,
  params: UsersApiUsersIdImpersonateRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.usersIdImpersonate(params);
  return data;
};

export const useUsersIdImpersonate = (
  options?: UseMutationOptions<
    AccessToken,
    AxiosApiError,
    UsersApiUsersIdImpersonateRequest
  >
) => {
  return useAuthMutation(usersIdImpersonate, options, false);
};
