import { UseQueryOptions } from "react-query";
import { MandatorList } from "@generated/apiv1";

import { buildMandatorsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listMandators = async (token: string | undefined) => {
  const apiInstance = buildMandatorsApi(token);
  const { data } = await apiInstance.listMandators();
  return data;
};

export const useListMandators = (
  options?: UseQueryOptions<MandatorList, AxiosApiError, MandatorList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.MANDATORS_LIST_MANDATORS],
    ({ token }) => listMandators(token),
    options
  );
};
