import React, { forwardRef, MutableRefObject } from "react";
import { Box, ClickAwayListener, Fade, Popper } from "@mui/material";
import { SymbolV2 } from "@generated/apiv1";

import { SecuritiesSearchResults } from "@features/securities/SecuritiesSearchResults";

type Props = {
  symbols?: SymbolV2[];
  isLoading: boolean;
  onSymbolSelect: (symbol: SymbolV2) => void;
  logos?: Record<string, string | null | undefined>;
  searchHeight?: number;
  isMobile?: boolean;
  anchorEl?: HTMLElement;
  setSearchResultsHeight: (height: number) => void;
  onOuterClick?: (event: MouseEvent | TouchEvent) => void;
  shouldShowExpand?: boolean;
  isOpen?: boolean;
};

export const SecuritiesSearchResultsDesktop = forwardRef(
  // @ts-ignore
  (
    {
      isMobile,
      searchHeight,
      onOuterClick,
      setSearchResultsHeight,
      isOpen = false,
      anchorEl,
      ...rest
    }: Props,
    searchResultsRef: MutableRefObject<HTMLDivElement> | null
  ) => {
    return (
      <ClickAwayListener onClickAway={onOuterClick ? onOuterClick : () => {}}>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom"
          disablePortal
          transition
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              addEndListener={() => {
                setSearchResultsHeight(
                  searchResultsRef?.current?.offsetHeight || 0
                );
              }}
            >
              <Box maxHeight="100%" width="100%" ref={searchResultsRef}>
                <SecuritiesSearchResults
                  {...rest}
                  searchHeight={searchHeight}
                />
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    );
  }
);
