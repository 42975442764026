import { addMilliseconds } from "date-fns";

// request new token 5 seconds earlier expire time
export const REFRESH_DELTA = 5000;

export const isExpired = (token: Token) =>
  addMilliseconds(token.expires_at, -REFRESH_DELTA) < new Date();

export const isAutoSignOutExpired = (token: Token) =>
  token.auto_sign_out_at && token.auto_sign_out_at < new Date();
