import { UseQueryOptions } from "react-query";
import {
  QuotesApiClassifySecuritiesRequest,
  SecuritiesResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const classifySecurities = async (
  token: string | undefined,
  params?: QuotesApiClassifySecuritiesRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.classifySecurities(params);
  return data;
};

export const useClassifySecurities = (
  params?: QuotesApiClassifySecuritiesRequest,
  options?: UseQueryOptions<
    SecuritiesResponse,
    AxiosApiError,
    SecuritiesResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_CLASSIFY_SECURITIES, params],
    ({ token }) => classifySecurities(token, params),
    options
  );
};
