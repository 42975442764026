/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Amount } from './amount';

/**
 * Detailed tax and commission statements, i.e. not-aggregated
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * The type of the position
     * @type {string}
     * @memberof Position
     */
    'type'?: PositionTypeEnum;
    /**
     * 
     * @type {Amount}
     * @memberof Position
     */
    'amount'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Position
     */
    'amountFx'?: Amount;
    /**
     * The exchange rate of the position
     * @type {number}
     * @memberof Position
     */
    'exchangeRate'?: number;
}

export const PositionTypeEnum = {
    GROSS_AMOUNT: 'grossAmount',
    TAX: 'tax',
    COMMISSION: 'commission',
    UNKNOWN: 'unknown'
} as const;

export type PositionTypeEnum = typeof PositionTypeEnum[keyof typeof PositionTypeEnum];


