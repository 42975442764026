import { UseMutationOptions } from "react-query";
import {
  PerformanceApiPortfolioPerformanceRequest,
  PortfolioPerformanceResultList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const portfolioPerformance = async (
  token: string | undefined,
  params: PerformanceApiPortfolioPerformanceRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.portfolioPerformance(params);
  return data;
};

export const usePortfolioPerformance = (
  options?: UseMutationOptions<
    PortfolioPerformanceResultList,
    AxiosApiError,
    PerformanceApiPortfolioPerformanceRequest
  >
) => {
  return useAuthMutation(portfolioPerformance, options, false);
};
