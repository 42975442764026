import { UseMutationOptions } from "react-query";
import {
  DiscountsApiCreateDiscountPositionRequest,
  DiscountPosition,
} from "@generated/apiv1";

import { buildDiscountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createDiscountPosition = async (
  token: string | undefined,
  params: DiscountsApiCreateDiscountPositionRequest
) => {
  const apiInstance = buildDiscountsApi(token);
  const { data } = await apiInstance.createDiscountPosition(params);
  return data;
};

export const useCreateDiscountPosition = (
  options?: UseMutationOptions<
    DiscountPosition,
    AxiosApiError,
    DiscountsApiCreateDiscountPositionRequest
  >
) => {
  return useAuthMutation(createDiscountPosition, options, false);
};
