/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { PageableIndustryList } from '../model';
// @ts-ignore
import { Region } from '../model';
/**
 * IndustriesApi - axios parameter creator
 * @export
 */
export const IndustriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get an industry by ID
         * @param {number} id ID of the industry to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('industriesId', 'id', id)
            const localVarPath = `/v1/industries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List or search all industries
         * @param {string} type Only return regions with the given type; supported are sector, industryGroup, industry, subIndustry
         * @param {string} [germanName] German name; will perform a like search
         * @param {string} [name] Default (English) name; will perform a like search
         * @param {string} [gicsId] GICS (Global Industry Classification Standard) ID
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllIndustries: async (type: string, germanName?: string, name?: string, gicsId?: string, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listAndSearchAllIndustries', 'type', type)
            const localVarPath = `/v1/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (germanName !== undefined) {
                localVarQueryParameter['germanName'] = germanName;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (gicsId !== undefined) {
                localVarQueryParameter['gicsId'] = gicsId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustriesApi - functional programming interface
 * @export
 */
export const IndustriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get an industry by ID
         * @param {number} id ID of the industry to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async industriesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.industriesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndustriesApi.industriesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List or search all industries
         * @param {string} type Only return regions with the given type; supported are sector, industryGroup, industry, subIndustry
         * @param {string} [germanName] German name; will perform a like search
         * @param {string} [name] Default (English) name; will perform a like search
         * @param {string} [gicsId] GICS (Global Industry Classification Standard) ID
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllIndustries(type: string, germanName?: string, name?: string, gicsId?: string, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableIndustryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllIndustries(type, germanName, name, gicsId, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndustriesApi.listAndSearchAllIndustries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IndustriesApi - factory interface
 * @export
 */
export const IndustriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get an industry by ID
         * @param {IndustriesApiIndustriesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesId(requestParameters: IndustriesApiIndustriesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Region> {
            return localVarFp.industriesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List or search all industries
         * @param {IndustriesApiListAndSearchAllIndustriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllIndustries(requestParameters: IndustriesApiListAndSearchAllIndustriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableIndustryList> {
            return localVarFp.listAndSearchAllIndustries(requestParameters.type, requestParameters.germanName, requestParameters.name, requestParameters.gicsId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for industriesId operation in IndustriesApi.
 * @export
 * @interface IndustriesApiIndustriesIdRequest
 */
export interface IndustriesApiIndustriesIdRequest {
    /**
     * ID of the industry to retrieve
     * @type {number}
     * @memberof IndustriesApiIndustriesId
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllIndustries operation in IndustriesApi.
 * @export
 * @interface IndustriesApiListAndSearchAllIndustriesRequest
 */
export interface IndustriesApiListAndSearchAllIndustriesRequest {
    /**
     * Only return regions with the given type; supported are sector, industryGroup, industry, subIndustry
     * @type {string}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly type: string

    /**
     * German name; will perform a like search
     * @type {string}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly germanName?: string

    /**
     * Default (English) name; will perform a like search
     * @type {string}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly name?: string

    /**
     * GICS (Global Industry Classification Standard) ID
     * @type {string}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly gicsId?: string

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof IndustriesApiListAndSearchAllIndustries
     */
    readonly order?: string
}

/**
 * IndustriesApi - object-oriented interface
 * @export
 * @class IndustriesApi
 * @extends {BaseAPI}
 */
export class IndustriesApi extends BaseAPI {
    /**
     * 
     * @summary Get an industry by ID
     * @param {IndustriesApiIndustriesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public industriesId(requestParameters: IndustriesApiIndustriesIdRequest, options?: RawAxiosRequestConfig) {
        return IndustriesApiFp(this.configuration).industriesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List or search all industries
     * @param {IndustriesApiListAndSearchAllIndustriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public listAndSearchAllIndustries(requestParameters: IndustriesApiListAndSearchAllIndustriesRequest, options?: RawAxiosRequestConfig) {
        return IndustriesApiFp(this.configuration).listAndSearchAllIndustries(requestParameters.type, requestParameters.germanName, requestParameters.name, requestParameters.gicsId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }
}

