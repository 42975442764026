import { UseQueryOptions } from "react-query";
import { FundsApiShowFundByIsinRequest, Fund } from "@generated/apiv1";

import { buildFundsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showFundByIsin = async (
  token: string | undefined,
  params: FundsApiShowFundByIsinRequest
) => {
  const apiInstance = buildFundsApi(token);
  const { data } = await apiInstance.showFundByIsin(params);
  return data;
};

export const useShowFundByIsin = (
  params: FundsApiShowFundByIsinRequest,
  options?: UseQueryOptions<Fund, AxiosApiError, Fund>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDS_SHOW_FUND_BY_ISIN, params],
    ({ token }) => showFundByIsin(token, params),
    options
  );
};
