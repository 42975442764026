import React from "react";
import styled from "@emotion/styled";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { toCurrency } from "@helpers";
import { GainLossPercents } from "@components";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { Investment } from "@generated/apiv1";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const intervalTypes: IntervalType[] = [
  "oneMonth",
  "sixMonths",
  "oneYear",
  "sinceBeginning",
  "custom",
];

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  border: none;
  width: 100%;
`;

interface TableSummaryProps {
  currencySymbol: string;
  summaryValue: number;
  intervalType: IntervalType;
  investment: Investment;
  onSelectInterval: (interval: IntervalType) => void;
  onSelectCustomInterval: (from: Date, to: Date) => void;
}

export const TableSummary = (props: TableSummaryProps) => {
  const {
    summaryValue = 0,
    currencySymbol = "EUR",
    intervalType,
    onSelectCustomInterval,
    onSelectInterval,
    investment,
  } = props;
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Container id="container">
      <Wrapper id="wraper">
        <Box display="flex">
          <Typography fontSize={26} fontWeight={500}>
            {toCurrency(summaryValue, currencySymbol)}
          </Typography>
          <Box px={4}>
            <GainLossPercents
              value={investment?.quote?.changePercentAbs ?? 0}
            />
          </Box>
        </Box>

        {!isLessSm && (
          <Box display="flex">
            <RangeSelector
              intervalType={intervalType}
              intervalTypes={intervalTypes}
              onSelect={onSelectInterval}
              onCustomSelect={onSelectCustomInterval}
            />
          </Box>
        )}
      </Wrapper>
      {isLessSm && (
        <RangeSelector
          intervalType={intervalType}
          intervalTypes={intervalTypes}
          onSelect={onSelectInterval}
          onCustomSelect={onSelectCustomInterval}
        />
      )}
    </Container>
  );
};
