import { UseQueryOptions } from "react-query";
import {
  UsersApiUsersPremiumSubscriptionRequest,
  StripeSubscription,
} from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const usersPremiumSubscription = async (
  token: string | undefined,
  params: UsersApiUsersPremiumSubscriptionRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.usersPremiumSubscription(params);
  return data;
};

export const useUsersPremiumSubscription = (
  params: UsersApiUsersPremiumSubscriptionRequest,
  options?: UseQueryOptions<
    StripeSubscription,
    AxiosApiError,
    StripeSubscription
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERS_USERS_PREMIUM_SUBSCRIPTION, params],
    ({ token }) => usersPremiumSubscription(token, params),
    options
  );
};
