/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { differenceInMonths, parseJSON } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertToChartData } from "@components/Chart/SecuritiesChart/utils";
import { SecurityChartMarker } from "@components/Chart/SecuritiesChart/types";
import { toCurrency } from "@helpers";
import {
  useListAndSearchAllBookings,
  useListAndSearchAllDividends,
} from "@generated/apiv1/hooks";
import { useInvestmentHistoricQuotes } from "./useInvestmentHistoricQuotes";

const getInterval = (startDate?: Date, endDate?: Date) => {
  if (!startDate && !!endDate) return "week";
  if (!startDate) return "day";
  if (!endDate) return "day";
  const monthDiff = differenceInMonths(endDate, startDate);
  if (monthDiff > 12) return "week";
  return "day";
};

interface Options {
  showEvents: boolean;
}

interface Attrs {
  investmentId?: number;
  startDate?: Date;
  endDate?: Date;
  tickerSymbol?: string;
  tickerSymbolWithCurrency?: string;
  comparedTickerSymbol?: string;
  quoteProvider?: string;
  mainName: string;
  comparedName?: string;
  currentInterval?: IntervalType;
  currency: string;
}

export const useChartData = (
  {
    investmentId,
    startDate,
    endDate,
    mainName,
    comparedName,
    tickerSymbolWithCurrency,
    comparedTickerSymbol,
  }: Attrs,
  options: Options = { showEvents: false }
) => {
  const { t } = useTranslation();
  const interval = getInterval(startDate, endDate);

  const {
    data: chartHistoricQuotesData = [],
    isLoading: chartHistoricQuotesQueryLoadingState,
    isFetched: chartHistoricQuotesFetchedState,
    isIdle: chartHistoricQuotesIdleState,
  } = useInvestmentHistoricQuotes(
    {
      investmentId,
      tickerSymbol: tickerSymbolWithCurrency,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: !!investmentId && !!tickerSymbolWithCurrency,
      keepPreviousData: true,
    }
  );

  const { data: comparisonHistoricQuotes = [] } = useInvestmentHistoricQuotes(
    {
      tickerSymbol: comparedTickerSymbol,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: Boolean(comparedTickerSymbol),
      keepPreviousData: true,
    }
  );

  const firstHistoricQuote = chartHistoricQuotesData.at(0);
  const lastHistoricQuote = chartHistoricQuotesData.at(-1);

  const dividendsQuery = useListAndSearchAllDividends(
    {
      investmentId: investmentId ? [investmentId] : undefined,
      excludeImported: [false],
      taxRate: 0,
    },
    { staleTime: Infinity, enabled: options.showEvents && !!investmentId }
  );

  const bookingsQuery = useListAndSearchAllBookings(
    {
      investmentId: investmentId ? [investmentId] : undefined,
    },
    { staleTime: Infinity, enabled: options.showEvents && !!investmentId }
  );

  const quotes = useMemo(
    () =>
      convertToChartData(
        chartHistoricQuotesData,
        comparisonHistoricQuotes,
        comparedTickerSymbol ? "performance" : "valuation"
      ),
    [chartHistoricQuotesData, comparisonHistoricQuotes, comparedTickerSymbol]
  );

  const chartData = useMemo(() => {
    return [
      {
        name: mainName,
        securityName: mainName,
        data: quotes.map(({ mainValue }) => mainValue),
      },
      ...(comparedName
        ? [
            {
              name: comparedName,
              securityName: comparedName,
              data: quotes.map(({ comparedValue }) => comparedValue),
            },
          ]
        : []),
    ];
  }, [comparedName, mainName, quotes]);

  const sortedMarkers = useMemo(() => {
    if (!firstHistoricQuote || !lastHistoricQuote) {
      return {
        data: chartData,
        markers: [],
        isLoading:
          !chartHistoricQuotesFetchedState ||
          chartHistoricQuotesQueryLoadingState,
        isIdle: chartHistoricQuotesIdleState,
      };
    }
    const firstHistoricQuoteDate = parseJSON(firstHistoricQuote.date).getTime();
    const lastHistoricQuoteDate = parseJSON(lastHistoricQuote.date).getTime();

    const dividends = dividendsQuery.data?.dividends ?? [];

    const dividendMarkers = dividends
      .map<SecurityChartMarker | null>((dividend) => {
        if (!dividend.date) return null;
        return {
          x: parseJSON(dividend.date).getTime(),
          title: "D",
          text: `${t("investmentChartTooltip.expected_dividend")}: ${toCurrency(
            dividend.dividendPerShare
          )}`,
        };
      })
      .filter((marker): marker is SecurityChartMarker => {
        if (!marker) return false;
        return (
          marker.x >= firstHistoricQuoteDate &&
          marker.x <= lastHistoricQuoteDate
        );
      });

    const bookings = bookingsQuery.data?.bookings ?? [];

    const bookingsMarkers = bookings
      .map<SecurityChartMarker>((booking) => {
        return {
          x: parseJSON(booking.valueDate).getTime(),
          title: `${t(`investmentChartTooltip.${booking.type}`)[0]}`,
          text: `${t(`investmentChartTooltip.${booking.type}`)}: ${toCurrency(
            booking.amount
          )}`,
        };
      })
      .filter((marker): marker is SecurityChartMarker => {
        return (
          marker.x >= firstHistoricQuoteDate &&
          marker.x <= lastHistoricQuoteDate
        );
      });

    return [...dividendMarkers, ...bookingsMarkers].sort((a, b) => a.x - b.x);
  }, [
    bookingsQuery.data?.bookings,
    chartData,
    chartHistoricQuotesFetchedState,
    chartHistoricQuotesIdleState,
    chartHistoricQuotesQueryLoadingState,
    dividendsQuery.data?.dividends,
    firstHistoricQuote,
    lastHistoricQuote,
    t,
  ]);

  return {
    data: chartData,
    markers: sortedMarkers as SecurityChartMarker[] | undefined,
    isLoading:
      !chartHistoricQuotesFetchedState || chartHistoricQuotesQueryLoadingState,
    isIdle: chartHistoricQuotesIdleState,
  };
};
