import { UseMutationOptions } from "react-query";
import { WatchlistEntriesApiDeleteWatchlistEntryRequest } from "@generated/apiv1";

import { buildWatchlistEntriesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteWatchlistEntry = async (
  token: string | undefined,
  params: WatchlistEntriesApiDeleteWatchlistEntryRequest
) => {
  const apiInstance = buildWatchlistEntriesApi(token);
  const { data } = await apiInstance.deleteWatchlistEntry(params);
  return data;
};

export const useDeleteWatchlistEntry = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    WatchlistEntriesApiDeleteWatchlistEntryRequest
  >
) => {
  return useAuthMutation(deleteWatchlistEntry, options, false);
};
