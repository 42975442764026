import React, { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface TermsDialogProps {
  open: boolean;
  children: ReactNode;
  title?: string;
  onClose: () => void;
}

export const TermsDialog = (props: TermsDialogProps) => {
  const { open, children, title, onClose } = props;

  return (
    <Dialog open={open} scroll="paper" onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent dividers>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
