import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const clearValidationErrorAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "VALIDATE_MANUAL_DIVIDEND") return {};

  return {
    validationErrors: [],
  };
};
