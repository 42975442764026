/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateInsuranceParams } from '../model';
// @ts-ignore
import { CreateOrUpdateInsuranceParamsList } from '../model';
// @ts-ignore
import { Insurance } from '../model';
// @ts-ignore
import { MergeInsuranceParams } from '../model';
// @ts-ignore
import { PageableInsuranceList } from '../model';
/**
 * InsurancesApi - axios parameter creator
 * @export
 */
export const InsurancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Batch update the given insurances.
         * @param {CreateOrUpdateInsuranceParamsList} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateInsurances: async (body: CreateOrUpdateInsuranceParamsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('batchUpdateInsurances', 'body', body)
            const localVarPath = `/v1/insurances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new insurance record.
         * @param {CreateOrUpdateInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurance: async (body: CreateOrUpdateInsuranceParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createInsurance', 'body', body)
            const localVarPath = `/v1/insurances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an Insurance, including all of its child entities.
         * @param {number} id ID of the insurance to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurance: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInsurance', 'id', id)
            const localVarPath = `/v1/insurances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Insurance
         * @param {number} id ID of the insurance to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insurancesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insurancesId', 'id', id)
            const localVarPath = `/v1/insurances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Insurances
         * @param {Array<number>} [id] Retrieve Insurances with the given IDs
         * @param {Array<number>} [accountId] Retrieve Insurances for the given Accounts (insurance folders)
         * @param {Array<string>} [categoryId] Retrieve Insurances for the given categories
         * @param {Array<string>} [subCategoryId] Retrieve Insurances for the given sub-categories
         * @param {Array<string>} [providerId] Retrieve Insurances for the given providers
         * @param {Array<string>} [status] Retrieve Insurances having the given status, one of detected, active, expired, no_contribution,                              cancelled, ignored.
         * @param {string} [contractHolder] Retrieve Insurances matching the contract holders name (like search)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInsurances: async (id?: Array<number>, accountId?: Array<number>, categoryId?: Array<string>, subCategoryId?: Array<string>, providerId?: Array<string>, status?: Array<string>, contractHolder?: string, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/insurances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (categoryId) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (subCategoryId) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            if (providerId) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (contractHolder !== undefined) {
                localVarQueryParameter['contractHolder'] = contractHolder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merge two insurances.
         * @param {MergeInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeInsurances: async (body: MergeInsuranceParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('mergeInsurances', 'body', body)
            const localVarPath = `/v1/insurances/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the insurance with the given ID.
         * @param {number} id ID of the account to update
         * @param {CreateOrUpdateInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurance: async (id: number, body: CreateOrUpdateInsuranceParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInsurance', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateInsurance', 'body', body)
            const localVarPath = `/v1/insurances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsurancesApi - functional programming interface
 * @export
 */
export const InsurancesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsurancesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Batch update the given insurances.
         * @param {CreateOrUpdateInsuranceParamsList} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateInsurances(body: CreateOrUpdateInsuranceParamsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInsuranceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateInsurances(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.batchUpdateInsurances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new insurance record.
         * @param {CreateOrUpdateInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInsurance(body: CreateOrUpdateInsuranceParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInsurance(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.createInsurance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an Insurance, including all of its child entities.
         * @param {number} id ID of the insurance to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInsurance(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInsurance(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.deleteInsurance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an Insurance
         * @param {number} id ID of the insurance to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insurancesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insurancesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.insurancesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Insurances
         * @param {Array<number>} [id] Retrieve Insurances with the given IDs
         * @param {Array<number>} [accountId] Retrieve Insurances for the given Accounts (insurance folders)
         * @param {Array<string>} [categoryId] Retrieve Insurances for the given categories
         * @param {Array<string>} [subCategoryId] Retrieve Insurances for the given sub-categories
         * @param {Array<string>} [providerId] Retrieve Insurances for the given providers
         * @param {Array<string>} [status] Retrieve Insurances having the given status, one of detected, active, expired, no_contribution,                              cancelled, ignored.
         * @param {string} [contractHolder] Retrieve Insurances matching the contract holders name (like search)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllInsurances(id?: Array<number>, accountId?: Array<number>, categoryId?: Array<string>, subCategoryId?: Array<string>, providerId?: Array<string>, status?: Array<string>, contractHolder?: string, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInsuranceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllInsurances(id, accountId, categoryId, subCategoryId, providerId, status, contractHolder, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.listAndSearchAllInsurances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Merge two insurances.
         * @param {MergeInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeInsurances(body: MergeInsuranceParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeInsurances(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.mergeInsurances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the insurance with the given ID.
         * @param {number} id ID of the account to update
         * @param {CreateOrUpdateInsuranceParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsurance(id: number, body: CreateOrUpdateInsuranceParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insurance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsurance(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsurancesApi.updateInsurance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsurancesApi - factory interface
 * @export
 */
export const InsurancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsurancesApiFp(configuration)
    return {
        /**
         * 
         * @summary Batch update the given insurances.
         * @param {InsurancesApiBatchUpdateInsurancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateInsurances(requestParameters: InsurancesApiBatchUpdateInsurancesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableInsuranceList> {
            return localVarFp.batchUpdateInsurances(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new insurance record.
         * @param {InsurancesApiCreateInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInsurance(requestParameters: InsurancesApiCreateInsuranceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Insurance> {
            return localVarFp.createInsurance(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an Insurance, including all of its child entities.
         * @param {InsurancesApiDeleteInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInsurance(requestParameters: InsurancesApiDeleteInsuranceRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteInsurance(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Insurance
         * @param {InsurancesApiInsurancesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insurancesId(requestParameters: InsurancesApiInsurancesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Insurance> {
            return localVarFp.insurancesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Insurances
         * @param {InsurancesApiListAndSearchAllInsurancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInsurances(requestParameters: InsurancesApiListAndSearchAllInsurancesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableInsuranceList> {
            return localVarFp.listAndSearchAllInsurances(requestParameters.id, requestParameters.accountId, requestParameters.categoryId, requestParameters.subCategoryId, requestParameters.providerId, requestParameters.status, requestParameters.contractHolder, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merge two insurances.
         * @param {InsurancesApiMergeInsurancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeInsurances(requestParameters: InsurancesApiMergeInsurancesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Insurance> {
            return localVarFp.mergeInsurances(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the insurance with the given ID.
         * @param {InsurancesApiUpdateInsuranceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsurance(requestParameters: InsurancesApiUpdateInsuranceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Insurance> {
            return localVarFp.updateInsurance(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for batchUpdateInsurances operation in InsurancesApi.
 * @export
 * @interface InsurancesApiBatchUpdateInsurancesRequest
 */
export interface InsurancesApiBatchUpdateInsurancesRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateInsuranceParamsList}
     * @memberof InsurancesApiBatchUpdateInsurances
     */
    readonly body: CreateOrUpdateInsuranceParamsList
}

/**
 * Request parameters for createInsurance operation in InsurancesApi.
 * @export
 * @interface InsurancesApiCreateInsuranceRequest
 */
export interface InsurancesApiCreateInsuranceRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateInsuranceParams}
     * @memberof InsurancesApiCreateInsurance
     */
    readonly body: CreateOrUpdateInsuranceParams
}

/**
 * Request parameters for deleteInsurance operation in InsurancesApi.
 * @export
 * @interface InsurancesApiDeleteInsuranceRequest
 */
export interface InsurancesApiDeleteInsuranceRequest {
    /**
     * ID of the insurance to retrieve
     * @type {number}
     * @memberof InsurancesApiDeleteInsurance
     */
    readonly id: number
}

/**
 * Request parameters for insurancesId operation in InsurancesApi.
 * @export
 * @interface InsurancesApiInsurancesIdRequest
 */
export interface InsurancesApiInsurancesIdRequest {
    /**
     * ID of the insurance to retrieve
     * @type {number}
     * @memberof InsurancesApiInsurancesId
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllInsurances operation in InsurancesApi.
 * @export
 * @interface InsurancesApiListAndSearchAllInsurancesRequest
 */
export interface InsurancesApiListAndSearchAllInsurancesRequest {
    /**
     * Retrieve Insurances with the given IDs
     * @type {Array<number>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly id?: Array<number>

    /**
     * Retrieve Insurances for the given Accounts (insurance folders)
     * @type {Array<number>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Insurances for the given categories
     * @type {Array<string>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly categoryId?: Array<string>

    /**
     * Retrieve Insurances for the given sub-categories
     * @type {Array<string>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly subCategoryId?: Array<string>

    /**
     * Retrieve Insurances for the given providers
     * @type {Array<string>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly providerId?: Array<string>

    /**
     * Retrieve Insurances having the given status, one of detected, active, expired, no_contribution,                              cancelled, ignored.
     * @type {Array<string>}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly status?: Array<string>

    /**
     * Retrieve Insurances matching the contract holders name (like search)
     * @type {string}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly contractHolder?: string

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof InsurancesApiListAndSearchAllInsurances
     */
    readonly order?: string
}

/**
 * Request parameters for mergeInsurances operation in InsurancesApi.
 * @export
 * @interface InsurancesApiMergeInsurancesRequest
 */
export interface InsurancesApiMergeInsurancesRequest {
    /**
     * The comment details
     * @type {MergeInsuranceParams}
     * @memberof InsurancesApiMergeInsurances
     */
    readonly body: MergeInsuranceParams
}

/**
 * Request parameters for updateInsurance operation in InsurancesApi.
 * @export
 * @interface InsurancesApiUpdateInsuranceRequest
 */
export interface InsurancesApiUpdateInsuranceRequest {
    /**
     * ID of the account to update
     * @type {number}
     * @memberof InsurancesApiUpdateInsurance
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateInsuranceParams}
     * @memberof InsurancesApiUpdateInsurance
     */
    readonly body: CreateOrUpdateInsuranceParams
}

/**
 * InsurancesApi - object-oriented interface
 * @export
 * @class InsurancesApi
 * @extends {BaseAPI}
 */
export class InsurancesApi extends BaseAPI {
    /**
     * 
     * @summary Batch update the given insurances.
     * @param {InsurancesApiBatchUpdateInsurancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public batchUpdateInsurances(requestParameters: InsurancesApiBatchUpdateInsurancesRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).batchUpdateInsurances(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new insurance record.
     * @param {InsurancesApiCreateInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public createInsurance(requestParameters: InsurancesApiCreateInsuranceRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).createInsurance(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an Insurance, including all of its child entities.
     * @param {InsurancesApiDeleteInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public deleteInsurance(requestParameters: InsurancesApiDeleteInsuranceRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).deleteInsurance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Insurance
     * @param {InsurancesApiInsurancesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public insurancesId(requestParameters: InsurancesApiInsurancesIdRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).insurancesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Insurances
     * @param {InsurancesApiListAndSearchAllInsurancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public listAndSearchAllInsurances(requestParameters: InsurancesApiListAndSearchAllInsurancesRequest = {}, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).listAndSearchAllInsurances(requestParameters.id, requestParameters.accountId, requestParameters.categoryId, requestParameters.subCategoryId, requestParameters.providerId, requestParameters.status, requestParameters.contractHolder, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merge two insurances.
     * @param {InsurancesApiMergeInsurancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public mergeInsurances(requestParameters: InsurancesApiMergeInsurancesRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).mergeInsurances(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the insurance with the given ID.
     * @param {InsurancesApiUpdateInsuranceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsurancesApi
     */
    public updateInsurance(requestParameters: InsurancesApiUpdateInsuranceRequest, options?: RawAxiosRequestConfig) {
        return InsurancesApiFp(this.configuration).updateInsurance(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

