import { UseQueryOptions } from "react-query";
import {
  SecurityCommissionsApiGetSecurityCommissionsRequest,
  SecurityCommission,
} from "@generated/apiv1";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSecurityCommissions = async (
  token: string | undefined,
  params: SecurityCommissionsApiGetSecurityCommissionsRequest
) => {
  const apiInstance = buildSecurityCommissionsApi(token);
  const { data } = await apiInstance.getSecurityCommissions(params);
  return data;
};

export const useGetSecurityCommissions = (
  params: SecurityCommissionsApiGetSecurityCommissionsRequest,
  options?: UseQueryOptions<
    SecurityCommission,
    AxiosApiError,
    SecurityCommission
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SECURITYCOMMISSIONS_GET_SECURITY_COMMISSIONS, params],
    ({ token }) => getSecurityCommissions(token, params),
    options
  );
};
