import React, { useEffect, useReducer, createContext } from "react";
import { isEmpty, set, merge, cloneDeep } from "lodash";
import { useGoogleTagManagerHandlers } from "@hooks";
import { ErrorBoundary } from "../common";
import { getStepGroup } from "./helpers/getStepGroup";
import { Child } from "./withIf";
import { WizardManager } from "./WizardManager";

export interface ValidationError {
  message?: string;
  expectedValues?: any;
  errors?: any[];
}

export type ValidationFunction<
  C,
  K extends keyof C,
  NK1 extends keyof C[K]
> = ({
  name,
  value,
  context,
}: {
  name: K | [K, NK1];
  value: unknown;
  context: C;
}) => ValidationError | void | undefined;

export type NestedName<C> = keyof C[keyof C];
export type NamePath<C> = [keyof C, NestedName<C>];
export type NameOrNamePath<C> = keyof C | NamePath<C>;

interface WizardProps<C> {
  initialStepNumber?: number;
  animation?: boolean;
  onChange?: (value: C, stepNumber: number) => void;
  onFinish: (value: C) => void;
  children: Child<C>[];
  validation?: ValidationFunction<C, keyof C, NestedName<C>>;
  loading?: boolean;
  initialData?: C;
}
interface DataState<C> {
  data: C;
  isLast: boolean;
  stepNumber: number;
  step: string;
}

interface Payload<C> {
  name: NameOrNamePath<C>;
  value: any;
  stepNumber: number;
}

interface ActionAdd<C> {
  type: "add";
  payload: Payload<C>;
}

interface ActionLast<C> {
  type: "last";
  payload: Payload<C>;
}

interface ActionPrevious {
  type: "previous";
  payload: { stepNumber: number };
}

interface ActionMerge<C> {
  type: "merge";
  payload: C;
}

interface ActionReplace<C> {
  type: "replace";
  payload: C;
}

interface ActionStep {
  type: "step";
  payload: { step: string };
}

export type Action<C> =
  | ActionAdd<C>
  | ActionLast<C>
  | ActionPrevious
  | ActionMerge<C>
  | ActionReplace<C>
  | ActionStep;

export const ChangeDataContext = createContext<React.Dispatch<Action<any>>>(
  null as any
);
export const DataContext = createContext<any>({});
export const LoadingContext = createContext<boolean>(false);

export const Wizard = <C extends {}>({
  children,
  onChange,
  onFinish,
  validation = () => undefined,
  loading = false,
  animation = true,
  initialStepNumber = 0,
  initialData = {} as C,
}: WizardProps<C>) => {
  const payloadToData = (data: C, payload: Payload<C>) => {
    return Array.isArray(payload.name)
      ? merge(cloneDeep(data), set({}, payload.name, payload.value))
      : { ...data, [payload.name]: payload.value };
  };

  const reducer = (state: DataState<C>, action: Action<C>): DataState<C> => {
    switch (action.type) {
      case "add":
        return {
          data: payloadToData(state.data, action.payload),
          isLast: false,
          stepNumber: action.payload.stepNumber,
          step: state.step,
        };
      case "last":
        return {
          data: payloadToData(state.data, action.payload),
          stepNumber: action.payload.stepNumber,
          isLast: true,
          step: state.step,
        };
      case "previous":
        return { ...state, stepNumber: action.payload.stepNumber };
      case "merge":
        return { ...state, data: merge(state.data, action.payload) };
      case "replace":
        return { ...state, data: action.payload };
      case "step":
        return { ...state, step: action.payload.step };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, {
    data: initialData,
    isLast: false,
    stepNumber: initialStepNumber,
    step: "",
  });

  const { data, isLast, step } = formState;
  const { workflowType } = data as Record<string, string>;

  const { onClick } = useGoogleTagManagerHandlers({
    userFlowId: "cashback-wizard",
    ignoredSelectors: ["#wizard-submit"],
    trackedElements: ["button"],
    data: {
      contextId: step,
      contextGroup: getStepGroup(step),
      workflow: workflowType,
    },
  });

  useEffect(() => {
    if (isEmpty(data)) return;
    if (
      JSON.stringify(initialData) === JSON.stringify(data) &&
      formState.stepNumber === initialStepNumber
    ) {
      return;
    }
    onChange && onChange(data, formState.stepNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formState.data), formState.stepNumber, formState.isLast]);

  useEffect(() => {
    if (isLast) {
      onFinish(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLast, JSON.stringify(data)]);

  // TODO: do proper ErrorBoundary for the wizard
  return (
    <ErrorBoundary>
      <ChangeDataContext.Provider value={dispatch}>
        <DataContext.Provider value={formState}>
          <LoadingContext.Provider value={loading}>
            <div onClick={onClick}>
              <WizardManager
                validation={validation}
                animation={animation}
                children={children}
                initialStepNumber={formState.stepNumber}
                step={step}
              />
            </div>
          </LoadingContext.Provider>
        </DataContext.Provider>
      </ChangeDataContext.Provider>
    </ErrorBoundary>
  );
};
