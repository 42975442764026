import { get } from "lodash";
import {
  Relation,
  RelationArray,
  RelationDataAnyOf1Inner,
} from "@generated/apiv3";

type DataObject<T> = {
  id?: string;
  type?: string;
  attributes?: Record<string, any>;
  relationships?: T;
};

type IncludedDataObject = {
  id?: string;
  type?: string;
  attributes?: Record<string, any>;
  relationships?: Partial<Record<string, Relation | RelationArray>>;
};

interface DataTransferObjectWithRelationships<T> {
  data: DataObject<T>[];
  included?: IncludedDataObject[];
}

interface DataTransferSingleObjectWithRelationships<T> {
  data: DataObject<T>;
  included?: IncludedDataObject[];
}

type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K : never;
}[keyof T];

type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? never : K;
}[keyof T];

export type IncludeType<T> = {
  [K in RequiredKeys<T>]: IncludeTypeValue;
} & {
  [K in OptionalKeys<T>]?: IncludeTypeValue;
};

type FunctionIncludeTypeValue = [string, (value: any) => any];
type ArrayIncludeTypeValue = [Record<string, string | Record<string, string>>];

type IncludeTypeValue =
  | string
  | Record<string, string | FunctionIncludeTypeValue>
  | ArrayIncludeTypeValue
  | FunctionIncludeTypeValue;

type RelationshipType<T> =
  T extends DataTransferSingleObjectWithRelationships<any>
    ? T["data"]["relationships"]
    : T extends DataTransferObjectWithRelationships<any>
    ? T["data"][0]["relationships"]
    : never;

type ReturnType<T, R, S> =
  T extends DataTransferSingleObjectWithRelationships<R> ? S : S[];

export class DataTransformer<
  T extends
    | DataTransferSingleObjectWithRelationships<R>
    | DataTransferObjectWithRelationships<R>,
  R = RelationshipType<T>
> {
  constructor(private dataObject: T) {
    this.dataObject = dataObject;
  }

  // include should be written this way:
  // { id: "product.id", short_name: "attributes.name", name: "product.attributes.name", holdings: [{ id: "holding.id" }] }
  // if value is present on the main DataObject then it would be taken from the main DataObject, otherwise from the included
  transformData<S>(include: IncludeType<S>): ReturnType<T, R, S> {
    const { data, included } = this.dataObject;

    if (Array.isArray(data)) {
      return data.map((dataItem) =>
        this.transformSingleDataItem<S>(
          dataItem,
          include,
          included as IncludedDataObject[]
        )
      ) as ReturnType<T, R, S>;
    }

    return this.transformSingleDataItem<S>(
      data,
      include,
      included as IncludedDataObject[]
    ) as ReturnType<T, R, S>;
  }

  private transformSingleDataItem<S>(
    dataItem: DataObject<R>,
    include: IncludeType<S>,
    included?: IncludedDataObject[]
  ): S {
    return Object.entries<IncludeTypeValue>(include).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value) && typeof value[1] === "function") {
          return {
            ...acc,
            [key]: value[1](
              // @ts-ignore
              this.getIncludedItemValue(value[0], dataItem, included)
            ),
          };
        }

        return {
          ...acc,
          [key]:
            Array.isArray(value) && typeof value[0] !== "string"
              ? this.getIncludedItemsValues(value[0], dataItem, included)
              : this.getIncludedItemValue(value, dataItem, included),
        };
      },
      {} as S
    );
  }

  private getIncludedItemsValues(
    dataStructure: Record<string, string | Record<string, string>>,
    dataItem: DataObject<R>,
    included?: IncludedDataObject[]
  ): Record<string, any>[] {
    if (!dataItem) return [];
    const { relationships } = dataItem;

    const data: Record<string, any[]> = Object.entries(dataStructure).reduce(
      (acc, [key, value]) => {
        if (typeof value !== "string") {
          return {
            ...acc,
            [key]: this.getIncludedItemsValues(value, dataItem, included),
          };
        }
        const simpleValue = get(dataItem, value);
        if (simpleValue !== null && simpleValue !== undefined) {
          return {
            ...acc,
            [key]: [simpleValue],
          };
        }

        if (!relationships || !included) return acc;

        const match = /(?<includedType>\w+).(?<valueString>.+)/.exec(value);
        if (!match) return acc;

        const { includedType, valueString } = match.groups as {
          includedType: keyof R;
          valueString: string;
        };

        const directlyRelatedIncludedItems =
          this.getDirectlyRelatedIncludedItems(relationships, included);
        let matchingIncluded = directlyRelatedIncludedItems.filter(
          (item) => item.type === includedType
        );

        if (!matchingIncluded.length) {
          const indirectlyRelatedIncludedItems =
            this.getIndirectlyRelatedIncludedItems(relationships, included);
          matchingIncluded = indirectlyRelatedIncludedItems.filter(
            (item) => item.type === includedType
          );
        }

        if (!matchingIncluded) return acc;

        return {
          ...acc,
          [key]: matchingIncluded.map((item) => get(item, valueString)),
        };
      },
      {}
    );

    const itemsLength = Object.values(data)[0]?.length || 0;
    return Array(itemsLength)
      .fill(undefined)
      .map((_, index) => ({
        ...Object.entries(data).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value[index],
          }),
          {}
        ),
      }));
  }

  private getIncludedItem(
    included: IncludedDataObject[],
    type?: string,
    id?: string
  ) {
    if (!type || !id) return undefined;

    return included.find(
      (includedItem) => includedItem.id === id && includedItem.type === type
    );
  }

  private getDirectlyRelatedIncludedItems(
    relationships: R,
    included: IncludedDataObject[]
  ): IncludedDataObject[] {
    return Object.values(relationships)
      .reduce(
        (acc, relItem) =>
          Array.isArray(relItem?.data)
            ? [
                ...acc,
                ...relItem?.data?.map((relSubItem: RelationDataAnyOf1Inner) =>
                  this.getIncludedItem(included, relSubItem.type, relSubItem.id)
                ),
              ]
            : [
                ...acc,
                this.getIncludedItem(
                  included,
                  relItem.data?.type,
                  relItem.data?.id
                ),
              ],
        [] as string[]
      )
      .filter(Boolean);
  }

  private getIndirectlyRelatedIncludedItems(
    relationships: R,
    included: IncludedDataObject[]
  ): IncludedDataObject[] {
    const directlyRelatedItems = this.getDirectlyRelatedIncludedItems(
      relationships,
      included
    );

    return directlyRelatedItems
      .reduce((acc, item) => {
        if (!item.relationships) return acc;
        const relationships = Object.values(item.relationships);

        return [
          ...acc,
          ...relationships.reduce(
            (acc, relItem) =>
              Array.isArray(relItem?.data)
                ? [
                    ...acc,
                    ...(relItem?.data?.map((relSubItem) =>
                      this.getIncludedItem(
                        included,
                        relSubItem.type,
                        relSubItem.id
                      )
                    ) || []),
                  ]
                : [
                    ...acc,
                    this.getIncludedItem(
                      included,
                      relItem?.data?.type,
                      relItem?.data?.id
                    ),
                  ],
            [] as (IncludedDataObject | undefined)[]
          ),
        ];
      }, [] as (IncludedDataObject | undefined)[])
      .filter(Boolean) as IncludedDataObject[];
  }

  private getIncludedItemValue(
    dataKey: IncludeTypeValue,
    dataObject: DataObject<R>,
    included?: IncludedDataObject[]
  ): any {
    const { relationships } = dataObject;

    if (Array.isArray(dataKey) && typeof dataKey[1] === "function") {
      return dataKey[1](
        this.getIncludedItemValue(dataKey[0] as string, dataObject, included)
      );
    }

    if (typeof dataKey !== "string")
      return Object.entries(dataKey).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: this.getIncludedItemValue(value, dataObject, included),
        }),
        {}
      );

    const simpleValue = get(dataObject, dataKey);
    if (simpleValue !== null && simpleValue !== undefined) {
      return simpleValue;
    }

    if (!included || !relationships) return undefined;

    const match = /(?<includedType>\w+).(?<valueString>.+)/.exec(dataKey);
    if (!match) return undefined;
    const { includedType, valueString } = match.groups as {
      includedType: keyof R;
      valueString: string;
    };

    const directlyRelatedItems = this.getDirectlyRelatedIncludedItems(
      relationships,
      included
    );
    let includedItem = directlyRelatedItems.find(
      (includedItem) => includedItem.type === includedType
    );

    if (!includedItem) {
      const indirectlyRelatedItems = this.getIndirectlyRelatedIncludedItems(
        relationships,
        included
      );
      includedItem = indirectlyRelatedItems.find(
        (includedItem) => includedItem.type === includedType
      );
    }

    if (!includedItem) {
      return undefined;
    }

    return get(includedItem, valueString);
  }
}
