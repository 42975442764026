import { UseQueryOptions } from "react-query";
import {
  useListAndSearchAllInvestments,
  useListAndSearchAllPublicInvestments,
} from "@generated/apiv1/hooks";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import {
  InvestmentsApiListAndSearchAllInvestmentsRequest,
  PageableInvestmentList,
} from "@generated/apiv1";

export const usePublicOrPrivateInvestments = (
  params?: InvestmentsApiListAndSearchAllInvestmentsRequest,
  options?: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  >
) => {
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const isEnabled = options?.enabled === undefined ? true : !!options?.enabled;

  const { data: publicInvestments, ...publicRest } =
    useListAndSearchAllPublicInvestments(
      // @ts-ignore TODO: fix this when API is fixed
      {
        ...params,
        publicId: publicId!,
      },
      {
        ...options,
        enabled: Boolean(publicId) && isEnabled,
      }
    );

  const { data: investmentsResult, ...rest } = useListAndSearchAllInvestments(
    params,
    {
      ...options,
      enabled: !isSharedPortfolio && isEnabled,
    }
  );

  return {
    investments:
      (isSharedPortfolio
        ? publicInvestments?.investments
        : investmentsResult?.investments) ?? [],
    ...(isSharedPortfolio ? publicRest : rest),
    cash:
      (isSharedPortfolio
        ? publicInvestments?.cashBalance
        : investmentsResult?.cashBalance) ?? 0,
  };
};
