import { differenceInMonths, format } from "date-fns";
import React, { useMemo } from "react";
import { useHistoricQuotes, useLookup } from "@generated/apiv1/hooks";
import { mapStockIndexToQuoteProvider } from "@constants";
import { SecuritiesChart } from "@components/Chart/SecuritiesChart/SecuritiesChart";
import { convertToChartData } from "@components/Chart/SecuritiesChart/utils";
import { useChartColors } from "@hooks";

const getInterval = (startDate?: Date, endDate?: Date) => {
  if (!startDate && !!endDate) return "week";
  if (!startDate) return "day";
  if (!endDate) return "day";
  const monthDiff = differenceInMonths(endDate, startDate);
  if (monthDiff > 12) return "week";
  return "day";
};

type QuoteChartContainerProps = {
  range: RangeType;
  selectedInterval: IntervalType;
  mainTickerSymbol: string;
  mainName: string;
  mainQuoteProvider: string;
  comparedTickerSymbol?: string;
  comparedQuoteProvider?: string;
  comparedName?: string;
  isBond?: boolean;
};

export const QuoteChartContainer = ({
  range,
  selectedInterval,
  mainQuoteProvider,
  mainName,
  mainTickerSymbol,
  comparedName,
  comparedQuoteProvider,
  comparedTickerSymbol,
  isBond = false,
}: QuoteChartContainerProps) => {
  const interval = getInterval(range.startDate, range.endDate);
  const chartColors = useChartColors("securitiesChart");

  const {
    data: historicQuotesData,
    isLoading: isChartLoading,
    isFetched,
  } = useHistoricQuotes(
    {
      // @ts-ignore TODO: fix this when API is fixed
      tickerSymbol: mainTickerSymbol,
      quoteProvider: mainQuoteProvider,
      interval,
      from: format(range.startDate!, "yyyy-MM-dd"),
      to: format(range.endDate, "yyyy-MM-dd"),
    },
    {
      enabled: Boolean(mainTickerSymbol),
    }
  );

  const { data: historicQuotesComparedData, isLoading: isComparedDataLoading } =
    useLookup(
      {
        tickerSymbol: comparedTickerSymbol!,
        quoteProvider:
          comparedQuoteProvider ||
          mapStockIndexToQuoteProvider[comparedTickerSymbol!],
        from: format(range.startDate!, "yyyy-MM-dd"),
        to: format(range.endDate, "yyyy-MM-dd"),
      },
      {
        enabled: Boolean(comparedTickerSymbol),
      }
    );

  const historicQuotes = useMemo(
    () => historicQuotesData?.historicQuotes?.[mainTickerSymbol] ?? [],
    [historicQuotesData, mainTickerSymbol]
  );
  const comparedHistoricQuotes = useMemo(
    () =>
      historicQuotesComparedData?.historicQuotes?.[
        comparedTickerSymbol || ""
      ] ?? [],
    [historicQuotesComparedData, comparedTickerSymbol]
  );

  const quotes = useMemo(
    () =>
      convertToChartData(
        historicQuotes,
        comparedHistoricQuotes,
        comparedTickerSymbol ? "performance" : "valuation"
      ),
    [historicQuotes, comparedHistoricQuotes, comparedTickerSymbol]
  );

  const chartData = [
    {
      name: mainName,
      securityName: mainName,
      data: quotes.map(({ mainValue }) => mainValue),
      color: chartColors.mainLine,
    },
    ...(comparedName
      ? [
          {
            name: comparedName,
            securityName: comparedName,
            data: quotes.map(({ comparedValue }) => comparedValue),
            color: chartColors.secondaryLine,
          },
        ]
      : []),
  ];

  return (
    <SecuritiesChart
      chartData={chartData}
      chartType={comparedName ? "performance" : "valuation"}
      intervalType={selectedInterval}
      isLoading={!isFetched || isChartLoading || isComparedDataLoading}
      displayArea
      showPercentage={isBond}
    />
  );
};
