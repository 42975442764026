import React, { useState } from "react";
import { Account, RentabloBankConnectionTypeEnum } from "@api";
import { buildOptions } from "./buildOptions";
import { SelectContraAccountContainer } from "./SelectContraAccountContainer";

export type IdType = "REMOTE" | "LOCAL";

export interface CurrentDepot {
  iban?: string;
  name?: string;
  bankConnectionId?: number;
  bankConnectionType: RentabloBankConnectionTypeEnum;
}

interface Props {
  currentDepot: CurrentDepot;
  idType: IdType;
  onSubmit: (id?: number) => void;
  isLoading?: boolean;
  showNoAccountOption?: boolean;
  defaultId?: number;
  accounts: Account[];
}

export const SelectContraAccount = ({
  currentDepot,
  idType,
  onSubmit,
  showNoAccountOption = true,
  isLoading = false,
  defaultId,
  accounts,
}: Props) => {
  const [showAllAccounts, setShowAllAccounts] = useState(false);

  const accountsToShow = showAllAccounts
    ? accounts
    : accounts.filter(
        ({ bankConnectionId }) =>
          bankConnectionId === currentDepot.bankConnectionId
      );
  const options = buildOptions(accountsToShow, idType);

  const handleShowAccountsClicked = () => {
    setShowAllAccounts((current) => !current);
  };

  return (
    <SelectContraAccountContainer
      currentDepot={currentDepot}
      options={options}
      showAccountsFromAllBanks={showAllAccounts}
      onShowAccountsClicked={handleShowAccountsClicked}
      onSubmit={onSubmit}
      showNoAccountOption={showNoAccountOption}
      isLoading={isLoading}
      defaultId={defaultId}
    />
  );
};
