import React from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  AccountTypeEnum,
  CreateOrUpdateInvestmentParams,
  Industry,
  InvestmentTypeEnum,
  Region,
} from "@api";
import { useAccounts } from "@api/v1/hooks/useAccounts";
import { useUpdateInvestment } from "@api/v1/hooks/useUpdateInvestment";
import { GET_INVESTMENT_KEY, GET_TAGS_KEY } from "@api/cacheKeys";
import { useTags } from "@api/v1/hooks/useTags";
import { InvestmentEditBasicTabContainer } from "./InvestmentEditBasicTabContainer";

interface Props {
  investmentId: number;
  investmentName: string;
  investmentType: InvestmentTypeEnum;
  depotId: number;
  industry?: Industry;
  region?: Region;
  tagValue?: string;
}

export const InvestmentEditBasicTab = ({
  depotId,
  investmentName,
  investmentId,
  investmentType,
  industry,
  region,
  tagValue,
}: Props) => {
  const { t } = useTranslation();
  const depotType: AccountTypeEnum =
    investmentType === "61_pmetals" || investmentType === "71_massets"
      ? "05_material_assets"
      : "01_depot";

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: { accounts = [] } = { accounts: [] } } = useAccounts({
    type: depotType,
  });
  const { data: tagsResponse } = useTags();
  const { mutateAsync: update, isLoading } = useUpdateInvestment();

  const handleSave = (data: CreateOrUpdateInvestmentParams) => {
    update(
      { id: investmentId, ...data },
      {
        onSuccess: async () => {
          enqueueSnackbar(
            `${t("investmentEditPage.basicTab.saved")}: ${data.name}`,
            { autoHideDuration: 3000, variant: "success" }
          );
          await queryClient.invalidateQueries([
            GET_INVESTMENT_KEY,
            GET_TAGS_KEY,
          ]);
        },
      }
    );
  };

  return (
    <InvestmentEditBasicTabContainer
      currentDepotId={depotId}
      currentIndustry={industry}
      currentRegion={region}
      investmentName={investmentName}
      tagValue={tagValue}
      depots={accounts}
      tags={tagsResponse?.tags ?? []}
      isSaving={isLoading}
      onSave={handleSave}
    />
  );
};
