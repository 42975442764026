import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useListAndSearchAllWatchlists } from "@generated/apiv1/hooks";
import { Modal, PageLayout } from "@components";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { CreateAndEditForm } from "@features/watchlists/components/CreateAndEditForm";
import { BlurForNonPro } from "@components/BlurForNonPro";
import { useMyself } from "@api";
import { WatchlistsPageWatchlists } from "./components/WatchlistsPageWatchlists";

const ActionButton = ({
  onClick,
  hasAtLeastOneWatchlist,
}: {
  onClick: () => void;
  hasAtLeastOneWatchlist: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const { data: myself } = useMyself();
  const isUserFree = myself?.isUserFree;
  const buttonIsDisabled = isUserFree && hasAtLeastOneWatchlist;

  return buttonIsDisabled ? (
    <BlurForNonPro>
      <Button
        data-testid="button-header-menu"
        variant="contained"
        color="secondary"
        size="small"
        sx={{ cursor: "initial" }}
        startIcon={<LockIcon />}
      >
        {t("watchlists.addWatchlist")}
      </Button>
    </BlurForNonPro>
  ) : (
    <Button
      data-testid="button-header-menu"
      variant="contained"
      color="secondary"
      size="small"
      startIcon={<PlusIcon />}
      onClick={onClick}
    >
      {t("watchlists.addWatchlist")}
    </Button>
  );
};

export const WatchlistsPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const match = matchPath("/watchlists/:watchlistId", pathname);
  const navigate = useNavigate();

  const { data: watchlistsResponse, isLoading: isWatchlistsLoading } =
    useListAndSearchAllWatchlists(
      {},
      {
        onSuccess: (data) => {
          const [firstWatchlist] = data?.watchlists ?? [];
          if (!match?.params?.watchlistId && firstWatchlist?.id) {
            navigate(`/watchlists/${firstWatchlist.id}`);
          }
        },
      }
    );
  const hasAtLeastOneWatchlist = Boolean(
    watchlistsResponse?.watchlists?.length
  );

  if (isWatchlistsLoading) return null;

  return (
    <PageLayout
      title={t("watchlistsPage.header.title")}
      isMainPage
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { text: t("watchlistsCreatePage.breadcrumbs.watchlists") },
      ]}
      actionButton={
        <ActionButton
          onClick={() => setIsOpen(true)}
          hasAtLeastOneWatchlist={hasAtLeastOneWatchlist}
        />
      }
    >
      <WatchlistsPageWatchlists
        isWatchlistsLoading={isWatchlistsLoading}
        watchlists={watchlistsResponse?.watchlists ?? []}
      />

      <Modal
        title={t("watchlists.newWatchlist")}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        height="auto"
      >
        <CreateAndEditForm onCancel={() => setIsOpen(false)} />
      </Modal>
    </PageLayout>
  );
};
