import { UseQueryOptions } from "react-query";
import { StringList } from "@generated/apiv1";

import { buildMasterDataApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const masterDataCatalogue = async (token: string | undefined) => {
  const apiInstance = buildMasterDataApi(token);
  const { data } = await apiInstance.masterDataCatalogue();
  return data;
};

export const useMasterDataCatalogue = (
  options?: UseQueryOptions<StringList, AxiosApiError, StringList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.MASTERDATA_MASTER_DATA_CATALOGUE],
    ({ token }) => masterDataCatalogue(token),
    options
  );
};
