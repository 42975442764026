import { UseQueryOptions } from "react-query";
import {
  WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest,
  WatchlistEntryList,
} from "@generated/apiv1";

import { buildWatchlistEntriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllWatchlistEntries = async (
  token: string | undefined,
  params?: WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest
) => {
  const apiInstance = buildWatchlistEntriesApi(token);
  const { data } = await apiInstance.listAndSearchAllWatchlistEntries(params);
  return data;
};

export const useListAndSearchAllWatchlistEntries = (
  params?: WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest,
  options?: UseQueryOptions<
    WatchlistEntryList,
    AxiosApiError,
    WatchlistEntryList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.WATCHLISTENTRIES_LIST_AND_SEARCH_ALL_WATCHLIST_ENTRIES, params],
    ({ token }) => listAndSearchAllWatchlistEntries(token, params),
    options
  );
};
