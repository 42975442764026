import { QueryClient } from "react-query";
import { LoginParams, LoginToken } from "@generated/apiv1";
import { RefreshTokenError } from "@api/auth/RefreshTokenError";
import { getAccessTokenByRefreshToken } from "@api/auth/getAccessTokenByRefreshToken";
import { TokenExpiredError } from "@api/auth/TokenExpiredError";
import { axiosInstanceV1 } from "../axiosInstance";
import { NoTokenError } from "./NoTokenError";
import { saveToStorage } from "./saveToStorage";
import {
  readFromSessionStorage,
  readFromLocalStorage,
} from "./readFromStorage";

import { LOGIN_TOKEN_QUERY_CACHE_KEY } from "./cacheKeys";
import { isAutoSignOutExpired, isExpired } from "./utils";

export const getAccessToken = async ({ username, password }: LoginParams) => {
  const { data } = await axiosInstanceV1.post<LoginToken>(`/login`, {
    username,
    password,
  });
  return data;
};

export const getAccessTokenQueryFn = (queryClient: QueryClient) => async () => {
  let token = queryClient.getQueryData<Token>(LOGIN_TOKEN_QUERY_CACHE_KEY);
  if (!token) {
    token = readFromSessionStorage();
  }

  if (!token) {
    token = readFromLocalStorage();
  }

  if (token && isAutoSignOutExpired(token)) {
    throw new TokenExpiredError("Token was automatically expired");
  }

  if (token && (!token.expires_at || isExpired(token))) {
    try {
      const accessToken = await getAccessTokenByRefreshToken({
        refreshToken: token.refresh_token,
      });

      token = saveToStorage(accessToken, token.rememberMe);
    } catch (err) {
      throw new RefreshTokenError("Could not refresh the token");
    }
  }

  if (!token) {
    throw new NoTokenError("No Token. Login Required.");
  }
  return token;
};
