import axios, { AxiosInstance } from "axios";

export let axiosInstanceV1: AxiosInstance;
export let axiosInstanceV2: AxiosInstance;
export let axiosInstanceV3: AxiosInstance;
export let axiosInstanceOAuth: AxiosInstance;
export let axiosInstanceLegacy: AxiosInstance;
export let axiosInstanceRoot: AxiosInstance;
export let axiosInstanceRentablo: AxiosInstance;
export let axiosInstanceRentabloV1: AxiosInstance;
export let axiosInstanceFF: AxiosInstance;

export const initAxios = ({
  baseURL,
  legacyURL,
  rentabloURL,
  ffURL,
}: {
  baseURL: string;
  legacyURL: string;
  rentabloURL: string;
  ffURL: string;
}) => {
  axiosInstanceV1 = axios.create({
    baseURL: `${baseURL}/api/v1`,
  });
  axiosInstanceV2 = axios.create({
    baseURL: `${baseURL}/api/v2`,
  });
  axiosInstanceOAuth = axios.create({
    baseURL: `${baseURL}/api/oauth`,
  });
  axiosInstanceLegacy = axios.create({
    baseURL: `${legacyURL}/webapp/v1`,
  });
  axiosInstanceV3 = axios.create({
    baseURL: `${baseURL}/api/v3`,
  });
  axiosInstanceRoot = axios.create({
    baseURL: `${baseURL}`,
  });
  axiosInstanceRentablo = axios.create({
    baseURL: `${rentabloURL}`,
  });
  axiosInstanceRentabloV1 = axios.create({
    baseURL: `${rentabloURL}/api/v1`,
  });
  axiosInstanceFF = axios.create({
    baseURL: `${ffURL}/api/v1`,
  });
};
