import { styled } from "@mui/material";
import { Card } from "@components";

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2.5,
  padding: theme.spacing(6),
  border: `none`,
  boxShadow: "0px 0px 2px 0px #00000029, 0px 4px 24px 0px #00000014",
  width: "100%",
  height: "auto",
  flex: 1,

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
  },
}));
