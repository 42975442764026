import { UseMutationOptions } from "react-query";
import { CSVApiParseCsvRequest, CsvParseResult } from "@generated/apiv3";

import { buildCSVApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const parseCsv = async (
  token: string | undefined,
  params?: CSVApiParseCsvRequest
) => {
  const apiInstance = buildCSVApi(token);
  const { data } = await apiInstance.parseCsv(params);
  return data;
};

export const useParseCsv = (
  options?: UseMutationOptions<
    CsvParseResult,
    AxiosApiError,
    CSVApiParseCsvRequest
  >
) => {
  return useAuthMutation(parseCsv, options, false);
};
