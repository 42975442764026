/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Container for a security position\'s data
 * @export
 * @interface FinApiSecurity
 */
export interface FinApiSecurity {
    /**
     * Identifier. Note: Whenever a security account is being updated, its security positions will be internally re-created, meaning that the identifier of a security position might change over time.
     * @type {number}
     * @memberof FinApiSecurity
     */
    'id': number;
    /**
     * Security account identifier
     * @type {number}
     * @memberof FinApiSecurity
     */
    'accountId': number;
    /**
     * Name
     * @type {string}
     * @memberof FinApiSecurity
     */
    'name'?: string;
    /**
     * ISIN
     * @type {string}
     * @memberof FinApiSecurity
     */
    'isin'?: string;
    /**
     * WKN
     * @type {string}
     * @memberof FinApiSecurity
     */
    'wkn'?: string;
    /**
     * Quote
     * @type {number}
     * @memberof FinApiSecurity
     */
    'quote'?: number;
    /**
     * Currency of quote
     * @type {string}
     * @memberof FinApiSecurity
     */
    'quoteCurrency'?: string;
    /**
     * Type of quote. \'PERC\' if quote is a percentage value, \'ACTU\' if quote is the actual amount
     * @type {string}
     * @memberof FinApiSecurity
     */
    'quoteType'?: FinApiSecurityQuoteTypeEnum;
    /**
     * Quote date in the format \'YYYY-MM-DD HH:MM:SS.SSS\' (german time).
     * @type {string}
     * @memberof FinApiSecurity
     */
    'quoteDate'?: string;
    /**
     * Value of quantity or nominal
     * @type {number}
     * @memberof FinApiSecurity
     */
    'quantityNominal'?: number;
    /**
     * Type of quantity or nominal value. \'UNIT\' if value is a quantity, \'FAMT\' if value is the nominal amount
     * @type {string}
     * @memberof FinApiSecurity
     */
    'quantityNominalType'?: FinApiSecurityQuantityNominalTypeEnum;
    /**
     * Market value
     * @type {number}
     * @memberof FinApiSecurity
     */
    'marketValue'?: number;
    /**
     * Currency of market value
     * @type {string}
     * @memberof FinApiSecurity
     */
    'marketValueCurrency'?: string;
    /**
     * Entry quote
     * @type {number}
     * @memberof FinApiSecurity
     */
    'entryQuote'?: number;
    /**
     * Currency of entry quote
     * @type {string}
     * @memberof FinApiSecurity
     */
    'entryQuoteCurrency'?: string;
    /**
     * Current profit or loss
     * @type {number}
     * @memberof FinApiSecurity
     */
    'profitOrLoss'?: number;
}

export const FinApiSecurityQuoteTypeEnum = {
    ACTU: 'ACTU',
    PERC: 'PERC'
} as const;

export type FinApiSecurityQuoteTypeEnum = typeof FinApiSecurityQuoteTypeEnum[keyof typeof FinApiSecurityQuoteTypeEnum];
export const FinApiSecurityQuantityNominalTypeEnum = {
    UNIT: 'UNIT',
    FAMT: 'FAMT'
} as const;

export type FinApiSecurityQuantityNominalTypeEnum = typeof FinApiSecurityQuantityNominalTypeEnum[keyof typeof FinApiSecurityQuantityNominalTypeEnum];


