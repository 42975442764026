import styled from "@emotion/styled";
import React from "react";

const Line = styled.div`
  width: 100%;
  height: 2px;
  border: solid 1px ${({ theme }) => theme.palette.border.primary};
`;

export const LogItemLine = () => {
  return <Line />;
};
