import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMyself } from "@generated/user/hooks";
import { useMyself as useMyselfWapi } from "@api";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { useScrollbarWithoutJump } from "@hooks";
import { SettingsMobileNavigation } from "@features/settingsPage/components/SettingsMobileNavigation";
import { SettingsNavigation } from "./components/SettingsNavigation";
import SettingsProfilePage from "./SettingsProfilePage/SettingsProfilePage";
import { SettingTabs } from "./constants";
import SettingsSubscriptionsPage from "./SettingsSubscriptionsPage/SettingsSubscriptionsPage";
import SettingsSecurityPage from "./SettingsSecurityPage/SettingsSecurityPage";
import CreateMFA from "./SettingsSecurityPage/components/CreateMFA";
import { SettingsConnectionsPage } from "./SettingsConnectionsPage/SettingsConnectionsPage";
import { SettingsErrorBlock } from "./components/SettingsErrorBlock";

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const HeadingContainer = styled(Container)`
  max-width: 648px;
`;

const ContentWrapper = styled(Container)<{ maxWidth: string }>`
  padding: 0 24px;
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const SettingsPage = ({ defaultTab }: { defaultTab?: SettingTabs }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef();
  const {
    data: userData,
    isLoading: isLoadingUser,
    isError,
  } = useMyself({
    refetchOnWindowFocus: false,
  });
  const { data, isLoading: isLoadingUserWapi } = useMyselfWapi({
    refetchOnWindowFocus: false,
  });

  const isLoading = isLoadingUser || isLoadingUserWapi;

  const { sidebarCollapsed } = useAppLayout();
  useScrollbarWithoutJump({
    elementToAddPadding: ref.current,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const activeTab = isMobile ? defaultTab : defaultTab ?? "profile";
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));
  const showInOneColumn = isMD || (!sidebarCollapsed && isLG);
  const [customTitle, setCustomTitle] = useState<string>(
    activeTab === "create" ? t("settingsPage.security.newTFA") : ""
  );
  const isPlusUser = data?.isUserPro ?? false;

  const contentWidth =
    activeTab === "subscription" && !isPlusUser && !showInOneColumn
      ? "848px"
      : "648px";

  return (
    <Container ref={ref}>
      <HeadingContainer>
        {isMobile && !activeTab ? (
          <SettingsMobileNavigation />
        ) : (
          <SettingsNavigation activeTab={activeTab} />
        )}

        {Boolean(activeTab) && (
          <Box mb={theme.spacing(4)} p={theme.spacing(0, 6)}>
            <Box
              display="flex"
              alignItems="center"
              marginTop={theme.spacing(7)}
            >
              <Typography
                variant="body1"
                fontSize="26px"
                lineHeight="34px"
                fontWeight={600}
                letterSpacing={-0.7}
              >
                {customTitle
                  ? customTitle
                  : t(`settingsPage.${activeTab}.title`)}
              </Typography>
            </Box>

            {activeTab === "subscription" && !isPlusUser && (
              <Typography
                variant="body1"
                fontWeight={400}
                sx={{ marginTop: theme.spacing(4) }}
                letterSpacing={-0.26}
              >
                {t(`settingsPage.${activeTab}.subtitle`)}
              </Typography>
            )}
          </Box>
        )}
      </HeadingContainer>

      <ContentWrapper maxWidth={contentWidth}>
        {isError ? (
          <SettingsErrorBlock />
        ) : (
          <>
            {activeTab === "profile" && (
              <SettingsProfilePage
                isPlusUser={isPlusUser}
                userData={userData}
                isLoadingUserData={isLoading}
              />
            )}
            {activeTab === "subscription" && (
              <SettingsSubscriptionsPage
                isPlusUser={isPlusUser}
                isLoadingUserData={isLoading}
                showInOneColumn={showInOneColumn}
              />
            )}
            {activeTab === "security" && (
              <SettingsSecurityPage
                userData={userData}
                isLoadingUserData={isLoading}
              />
            )}
            {activeTab === "create" && (
              <CreateMFA
                setCustomTitle={setCustomTitle}
                isLoadingUserData={isLoading}
              />
            )}
            {activeTab === "connections" && (
              <SettingsConnectionsPage isLoadingUserData={isLoading} />
            )}
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};

export const SettingsPageWithTab = () => {
  const { tab } = useParams<{ tab: SettingTabs | undefined }>();
  return <SettingsPage defaultTab={tab} />;
};
