import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setEntryQuoteAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "ENTRY_QUOTE_CONVERTED")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    bookingValues: { ...context.bookingValues, entryQuote: event.entryQuote },
  };
};
