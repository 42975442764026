import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { SecuritiesAddAndEditForm } from "@features/securities/components/SecuritiesAddAndEditForm";
import { LoadingPlaceHolder, Modal, Tab, Tabs } from "@components";
import { WatchlistsSinglePageHeader } from "@features/watchlists/watchlist/components/WatchlistsSinglePageHeader";
import { WatchlistItemsTable } from "@features/watchlists/watchlist/components/WatchlistItemsTable/WatchlistItemsTable";
import { SecuritiesChart } from "@components/Chart/SecuritiesChart/SecuritiesChart";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { intervalToTimeRange } from "@helpers";
import {
  useDeleteWatchlist,
  useGetQuotesV2,
  useGetWatchlist,
  useListAndSearchAllWatchlistEntries,
} from "@generated/apiv1/hooks";
import {
  SymbolV2,
  Watchlist as WatchlistV2,
  Watchlist,
} from "@generated/apiv1";
import { WatchlistsChartTooltip } from "@features/watchlists/components/WatchlistsChartTooltip";
import { CreateAndEditForm } from "@features/watchlists/components/CreateAndEditForm";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { ReactComponent as AddIcon } from "@icons/myicons/plus.svg";
import { SearchModal } from "@components/common/layouts/AppSidebar/SearchModal";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useChartData } from "@features/watchlists/hooks/useChartData";
import { NoDataStub } from "@components/Chart/NoDataStub";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

interface WatchlistsPageWatchlistsProps {
  watchlists: Watchlist[];
  isWatchlistsLoading: boolean;
}

export const WatchlistsPageWatchlists = (
  props: WatchlistsPageWatchlistsProps
) => {
  const { t } = useTranslation();
  const { watchlists, isWatchlistsLoading } = props;
  const navigate = useNavigate();
  const { watchlistId } = useParams<"watchlistId">();
  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );
  const [watchlistToEdit, setWatchlistToEdit] = React.useState<Watchlist>();
  const [watchlistToDelete, setWatchlistToDelete] = React.useState<Watchlist>();
  const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
  const [securityToAdd, setSecurityToAdd] = React.useState<SymbolV2>();
  const parsedWatchlistId = Number.parseInt(watchlistId!, 10);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  type WatchlistV2Required = Omit<WatchlistV2, "id"> & { id: number };

  const handleWhitelistClick = (id: number) => {
    navigate(`/watchlists/${id}`);
  };

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  const { mutateAsync: deleteWatchlist } = useDeleteWatchlist();
  const { data, isLoading } = useGetWatchlist(
    { id: parsedWatchlistId },
    {
      enabled: Boolean(parsedWatchlistId),
    }
  );
  const { data: watchlistEntriesResponse, isLoading: isEntriesLoading } =
    useListAndSearchAllWatchlistEntries(
      {
        watchlistId: [parsedWatchlistId],
      },
      {
        enabled: Boolean(parsedWatchlistId),
      }
    );

  const {
    chartData,
    watchlistEntriesWithColor,
    isLoading: isChartLoading,
    isFetched,
  } = useChartData(
    watchlistEntriesResponse?.watchlistEntries ?? [],
    range.startDate!,
    range.endDate
  );

  const { data: quoteData } = useGetQuotesV2(
    {
      securityId: [securityToAdd?.id!],
      quoteProvider: securityToAdd?.quoteProvider!,
    },
    {
      enabled: Boolean(securityToAdd),
    }
  );
  const quote = quoteData?.quotes?.[0];

  const handleDeleteWatchlist = () => {
    if (watchlistToDelete) {
      deleteWatchlist(
        { id: watchlistToDelete.id! },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [CACHE_KEYS.WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS],
            });
            setWatchlistToDelete(undefined);
            navigate("/watchlists");
            enqueueSnackbar({
              message: "Watchlist erfolgreich gelöscht",
              variant: "success",
              autoHideDuration: 3000,
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  if (!watchlists?.length && !isWatchlistsLoading) return <NoDataStub />;
  if (!data || isWatchlistsLoading) return <LoadingPlaceHolder />;

  return (
    <Container>
      <Tabs
        defaultKey={watchlistId}
        onChange={(id: string) => handleWhitelistClick(Number(id))}
        variant="scrollable"
      >
        {watchlists.map((watchlist) => {
          return (
            <Tab
              data-testid={`watchlist-chip-${watchlist.name}`}
              key={String(watchlist.id)}
              label={watchlist.name}
            >
              <Box pt={6} display="flex" flexDirection="column">
                <WatchlistsSinglePageHeader
                  isWatchlistLoading={isLoading}
                  onPrimaryActionClick={() => setIsSearchModalOpen(true)}
                  totalObservedItems={data?.numberOfEntries ?? 0}
                  onWatchlistEdit={() => setWatchlistToEdit(watchlist)}
                  onWatchlistDelete={() => setWatchlistToDelete(watchlist)}
                />
                {isFetched && !isEntriesLoading && !chartData.length ? (
                  <Box
                    height="calc(100vh - 350px)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box textAlign="center">
                      <Typography variant="h5" mb={2.5}>
                        {t("watchlists.nothingAdded")}
                      </Typography>
                      <Typography variant="body1" mb={2.5}>
                        {t("watchlists.addItems")}
                      </Typography>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setIsSearchModalOpen(true)}
                      >
                        {t("watchlists.addFirstSecurity")}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box display="flex" justifyContent="flex-end" mb={2}>
                      <RangeSelector
                        range={selectedRange === "custom" ? range : undefined}
                        onSelect={setSelectedRange}
                        onCustomSelect={onCustomRangeSelect}
                        intervalType={selectedRange}
                      />
                    </Box>
                    <Box mb={6}>
                      <SecuritiesChart
                        chartData={chartData}
                        chartType={
                          chartData.length > 1 ? "performance" : "valuation"
                        }
                        intervalType={selectedRange}
                        isLoading={isChartLoading || isEntriesLoading}
                        disableNavigator
                        displayArea
                        tooltipOptions={{
                          split: false,
                          formatter: function () {
                            const series = this.point.series.chart.series;
                            const isLimitReached = series.length >= 15;

                            return renderToString(
                              <WatchlistsChartTooltip
                                chartType={
                                  chartData.length > 1
                                    ? "performance"
                                    : "valuation"
                                }
                                isLimitReached={isLimitReached}
                                series={series}
                                x={this.x}
                              />
                            );
                          },
                        }}
                      />
                    </Box>
                    <WatchlistItemsTable
                      watchlistId={watchlistId!}
                      entries={watchlistEntriesWithColor ?? []}
                      isTableDataLoading={isEntriesLoading}
                    />
                  </>
                )}
                <SearchModal
                  isOpen={isSearchModalOpen}
                  onClose={() => setIsSearchModalOpen(false)}
                  onSymbolSelect={(symbol: SymbolV2) => {
                    setSecurityToAdd(symbol);
                    setIsSearchModalOpen(false);
                  }}
                />
                <Modal
                  isOpen={Boolean(securityToAdd)}
                  onClose={() => setSecurityToAdd(undefined)}
                  title={t("watchlistsCreatePage.breadcrumbs.create")}
                >
                  <SecuritiesAddAndEditForm
                    currentWatchlist={watchlist as WatchlistV2Required}
                    watchlists={watchlists as WatchlistV2Required[]}
                    securityMeta={{
                      ...securityToAdd,
                      tickerSymbol: quote?.tickerSymbol,
                      currency: quote?.currency,
                    }}
                    onSave={() => {
                      setSecurityToAdd(undefined);
                      queryClient.invalidateQueries({
                        queryKey: [
                          CACHE_KEYS.WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS,
                        ],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          CACHE_KEYS.WATCHLISTS_GET_WATCHLIST,
                          { id: parsedWatchlistId },
                        ],
                      });
                    }}
                  />
                </Modal>
              </Box>
            </Tab>
          );
        })}
      </Tabs>
      <Modal
        title={t("watchlistSinglePage.header.actions.editWatchlist")}
        isOpen={Boolean(watchlistToEdit)}
        onClose={() => setWatchlistToEdit(undefined)}
        height="auto"
      >
        <CreateAndEditForm
          onCancel={() => setWatchlistToEdit(undefined)}
          watchlist={watchlistToEdit}
        />
      </Modal>
      <ConfirmDeleteDialog
        isOpen={Boolean(watchlistToDelete)}
        title={t("watchlistSinglePage.header.deleteDialog.title")}
        onClose={() => setWatchlistToDelete(undefined)}
        onDelete={handleDeleteWatchlist}
      />
    </Container>
  );
};
