import { buildTppWebFormsApiWithToken } from "@generated/apiv1/apiBuilder";
import { MultibankingApiProvider } from "@api";
import { ACCOUNTS } from "@helpers/urlConstants";

export const createAndRedirectToTppWebForm = async (
  provider: MultibankingApiProvider,
  bankId?: number | string,
  bankConnectionId?: number
) => {
  const api = await buildTppWebFormsApiWithToken();

  if (provider === "WEALTH_API") {
    const { data } = await api.v2CreateTppWebForm({
      body: {
        bankSlug: bankId as string,
        bankConnectionId,
        multibankingApiProvider: "WEALTH_API",
        redirectUrl: ACCOUNTS,
      },
    });

    window.location.replace(`${data.startWebFormFlowUrl}?mandator=finanzfluss`);
  } else {
    const { data } = await api.createTppWebForm({
      body: {
        bankId: bankId as number,
        bankConnectionId,
        redirectUrl: ACCOUNTS,
      },
    });
    window.location.replace(`${data.startWebFormFlowUrl}?mandator=finanzfluss`);
  }
};
