import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiPortfolioRiskYieldRequest,
  RiskYield,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const portfolioRiskYield = async (
  token: string | undefined,
  params?: RiskYieldMetricsApiPortfolioRiskYieldRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.portfolioRiskYield(params);
  return data;
};

export const usePortfolioRiskYield = (
  params?: RiskYieldMetricsApiPortfolioRiskYieldRequest,
  options?: UseQueryOptions<RiskYield, AxiosApiError, RiskYield>
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_PORTFOLIO_RISK_YIELD, params],
    ({ token }) => portfolioRiskYield(token, params),
    options
  );
};
