import { DepotSynchronizationLogItem } from "@api";
import { Booking } from "@generated/apiv1";
import { LogItem } from "./SynchronizationLogItem";

export const toDisplayFormat = (
  logItems?: DepotSynchronizationLogItem[],
  bookings?: Booking[]
) => {
  if (!logItems) return [];

  let bookingMap = new Map<number, Booking>();
  bookings?.forEach((it) => bookingMap.set(it.id, it));

  return logItems.reduce<LogItem[]>((acc, item) => {
    const booking = item.bookingId ? bookingMap.get(item.bookingId) : undefined;
    return [
      ...acc,
      {
        id: item.id,
        synchronizationStatus: item.synchronizationStatus,
        bookingId: item.bookingId,
        investmentId: booking?.investment?.id,
        isin: item.isin,
        name: item.name,
        numberOfLots: booking?.numberOfLots,
        amount: booking?.amount,
        currency: booking?.investment?.currency,
        type: booking?.type,
      },
    ];
  }, []);
};
