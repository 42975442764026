import { UseMutationOptions } from "react-query";
import { InvestmentsApiDeleteInvestmentRequest } from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteInvestment = async (
  token: string | undefined,
  params: InvestmentsApiDeleteInvestmentRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.deleteInvestment(params);
  return data;
};

export const useDeleteInvestment = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    InvestmentsApiDeleteInvestmentRequest
  >
) => {
  return useAuthMutation(deleteInvestment, options, false);
};
