/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ApiException } from './api-exception';
// May contain unused imports in some cases
// @ts-ignore
import { BankConnectionWrapper } from './bank-connection-wrapper';
// May contain unused imports in some cases
// @ts-ignore
import { RemoteAccountWithProperties } from './remote-account-with-properties';

/**
 * 
 * @export
 * @interface AddOrUpdateBankConnectionResult
 */
export interface AddOrUpdateBankConnectionResult {
    /**
     * Identifier (hash) of the import or update process. Used to poll the status until import is finished
     * @type {string}
     * @memberof AddOrUpdateBankConnectionResult
     */
    'processId': string;
    /**
     * Status of the import or update run
     * @type {string}
     * @memberof AddOrUpdateBankConnectionResult
     */
    'status': AddOrUpdateBankConnectionResultStatusEnum;
    /**
     * 
     * @type {BankConnectionWrapper}
     * @memberof AddOrUpdateBankConnectionResult
     */
    'bankConnection'?: BankConnectionWrapper;
    /**
     * The accounts associated with the bank connection; if update was finished, empty if          update is pending. As depots are not necessarily imported (yet) we also return the data structure delivered by          the remote banking API for status handling and contra account assignment.
     * @type {Array<RemoteAccountWithProperties>}
     * @memberof AddOrUpdateBankConnectionResult
     */
    'remoteAccountsWithProperties'?: Array<RemoteAccountWithProperties>;
    /**
     * 
     * @type {ApiException}
     * @memberof AddOrUpdateBankConnectionResult
     */
    'synchronizationError'?: ApiException;
}

export const AddOrUpdateBankConnectionResultStatusEnum = {
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS'
} as const;

export type AddOrUpdateBankConnectionResultStatusEnum = typeof AddOrUpdateBankConnectionResultStatusEnum[keyof typeof AddOrUpdateBankConnectionResultStatusEnum];


