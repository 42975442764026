import { UseQueryOptions } from "react-query";
import {
  CategoriesApiListAndSearchAllCategoriesRequest,
  CategoryList,
} from "@generated/apiv1";

import { buildCategoriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllCategories = async (
  token: string | undefined,
  params?: CategoriesApiListAndSearchAllCategoriesRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.listAndSearchAllCategories(params);
  return data;
};

export const useListAndSearchAllCategories = (
  params?: CategoriesApiListAndSearchAllCategoriesRequest,
  options?: UseQueryOptions<CategoryList, AxiosApiError, CategoryList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIES_LIST_AND_SEARCH_ALL_CATEGORIES, params],
    ({ token }) => listAndSearchAllCategories(token, params),
    options
  );
};
