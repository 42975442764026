import React from "react";
import styled from "@emotion/styled";
import { parseISO, format } from "date-fns";
import { DepotSynchronizationLog } from "@api";
import { SynchronizationLog } from "./SynchronizationLog";

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  width: 100%;
`;

interface Props {
  depotSynchronizationLogs: DepotSynchronizationLog[];
}

export const SynchronizationLogList = ({ depotSynchronizationLogs }: Props) => {
  return (
    <>
      {depotSynchronizationLogs.map((depotSynchronizationLog, index) => {
        return (
          <Container
            key={`synchronizationLog-${depotSynchronizationLog.id}`}
            data-testid={`synchronizationLog-${index}`}
          >
            <SynchronizationLog
              depotSynchronizationLog={depotSynchronizationLog}
            />
            {format(
              parseISO(depotSynchronizationLog.executedAt),
              "dd.MM.yyyy HH:mm"
            )}
          </Container>
        );
      })}
    </>
  );
};
