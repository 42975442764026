import { UseQueryOptions } from "react-query";
import {
  FinancialGoalsApiFinancialGoalsIdRequest,
  FinancialGoal,
} from "@generated/apiv1";

import { buildFinancialGoalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const financialGoalsId = async (
  token: string | undefined,
  params: FinancialGoalsApiFinancialGoalsIdRequest
) => {
  const apiInstance = buildFinancialGoalsApi(token);
  const { data } = await apiInstance.financialGoalsId(params);
  return data;
};

export const useFinancialGoalsId = (
  params: FinancialGoalsApiFinancialGoalsIdRequest,
  options?: UseQueryOptions<FinancialGoal, AxiosApiError, FinancialGoal>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FINANCIALGOALS_FINANCIAL_GOALS_ID, params],
    ({ token }) => financialGoalsId(token, params),
    options
  );
};
