import { UseMutationOptions } from "react-query";
import { RolesApiCreateRoleRequest, Role } from "@generated/apiv1";

import { buildRolesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createRole = async (
  token: string | undefined,
  params: RolesApiCreateRoleRequest
) => {
  const apiInstance = buildRolesApi(token);
  const { data } = await apiInstance.createRole(params);
  return data;
};

export const useCreateRole = (
  options?: UseMutationOptions<Role, AxiosApiError, RolesApiCreateRoleRequest>
) => {
  return useAuthMutation(createRole, options, false);
};
