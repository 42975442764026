/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ClientConfiguration } from '../model';
// @ts-ignore
import { UpdateClientConfigurationParams } from '../model';
/**
 * FinApiClientConfigurationApi - axios parameter creator
 * @export
 */
export const FinApiClientConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the finAPI client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinApiClientConfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/finApi/clientConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the finAPI client configuration
         * @param {UpdateClientConfigurationParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinApiClientConfiguration: async (body: UpdateClientConfigurationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateFinApiClientConfiguration', 'body', body)
            const localVarPath = `/v1/finApi/clientConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinApiClientConfigurationApi - functional programming interface
 * @export
 */
export const FinApiClientConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinApiClientConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the finAPI client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinApiClientConfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinApiClientConfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinApiClientConfigurationApi.getFinApiClientConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the finAPI client configuration
         * @param {UpdateClientConfigurationParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFinApiClientConfiguration(body: UpdateClientConfigurationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinApiClientConfiguration(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinApiClientConfigurationApi.updateFinApiClientConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FinApiClientConfigurationApi - factory interface
 * @export
 */
export const FinApiClientConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinApiClientConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the finAPI client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinApiClientConfiguration(options?: RawAxiosRequestConfig): AxiosPromise<ClientConfiguration> {
            return localVarFp.getFinApiClientConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the finAPI client configuration
         * @param {FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinApiClientConfiguration(requestParameters: FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientConfiguration> {
            return localVarFp.updateFinApiClientConfiguration(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateFinApiClientConfiguration operation in FinApiClientConfigurationApi.
 * @export
 * @interface FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest
 */
export interface FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest {
    /**
     * The account details
     * @type {UpdateClientConfigurationParams}
     * @memberof FinApiClientConfigurationApiUpdateFinApiClientConfiguration
     */
    readonly body: UpdateClientConfigurationParams
}

/**
 * FinApiClientConfigurationApi - object-oriented interface
 * @export
 * @class FinApiClientConfigurationApi
 * @extends {BaseAPI}
 */
export class FinApiClientConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Get the finAPI client configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinApiClientConfigurationApi
     */
    public getFinApiClientConfiguration(options?: RawAxiosRequestConfig) {
        return FinApiClientConfigurationApiFp(this.configuration).getFinApiClientConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the finAPI client configuration
     * @param {FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinApiClientConfigurationApi
     */
    public updateFinApiClientConfiguration(requestParameters: FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest, options?: RawAxiosRequestConfig) {
        return FinApiClientConfigurationApiFp(this.configuration).updateFinApiClientConfiguration(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

