import { MutateOptions, UseQueryOptions, UseQueryResult } from "react-query";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import {
  buildSubscriptionsApi,
  buildSubscriptionsApiWithToken,
} from "@generated/apiv1/apiBuilder";
import {
  PageableSubscriptionList,
  Subscription,
  SubscriptionsApiCreateSubscriptionRequest,
  SubscriptionsApiListAndSearchAllSubscriptionsRequest,
  SubscriptionsApiUpdateSubscriptionRequest,
} from "@generated/apiv1";

export const useV1Subscription = (
  id?: number,
  options: UseQueryOptions<Subscription, AxiosApiError, Subscription> = {}
) =>
  useAuthQuery<Subscription, AxiosApiError, Subscription>(
    ["subscriptions", id],
    ({ token }) =>
      buildSubscriptionsApi(token)
        .getSubscription({ id: id! })
        .then((r) => r.data),
    { ...options, enabled: Boolean(id) }
  );

export const useV1SubscriptionsList = (
  params: SubscriptionsApiListAndSearchAllSubscriptionsRequest,
  options: UseQueryOptions<
    PageableSubscriptionList,
    AxiosApiError,
    PageableSubscriptionList
  > = {}
): UseQueryResult<PageableSubscriptionList> => {
  const queryFn = async (
    params: SubscriptionsApiListAndSearchAllSubscriptionsRequest
  ): Promise<PageableSubscriptionList> => {
    const api = await buildSubscriptionsApiWithToken();
    const { data } = await api.listAndSearchAllSubscriptions(params);
    return data;
  };
  return useAuthQuery<
    PageableSubscriptionList,
    AxiosApiError,
    PageableSubscriptionList
  >(["subscriptions", params], () => queryFn(params), {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useV1UpdateSubscription = (
  options: MutateOptions<
    Subscription,
    AxiosApiError,
    SubscriptionsApiUpdateSubscriptionRequest
  > = {}
) =>
  useAuthMutation<
    Subscription,
    AxiosApiError,
    SubscriptionsApiUpdateSubscriptionRequest
  >(
    (token, params) =>
      buildSubscriptionsApi(token)
        .updateSubscription(params)
        .then((r) => r.data),
    options
  );

const getSubscription = (data: PageableSubscriptionList) => {
  if (!data) return undefined;
  const sub = data.subscriptions?.[0];
  if (!sub) return undefined;
  return sub;
};
export const useV1FindIncompleteSubscription = (
  options: UseQueryOptions<
    Subscription | undefined,
    AxiosApiError,
    Subscription | undefined
  > = {}
) =>
  useAuthQuery<
    Subscription | undefined,
    AxiosApiError,
    Subscription | undefined
  >(
    ["subscriptions-my-last-incomplete"],
    ({ token }) =>
      buildSubscriptionsApi(token)
        .listAndSearchAllSubscriptions({
          status: ["incomplete"],
          isCancelled: false,
          order: "creationTimestamp DESC",
        })
        .then((r) => getSubscription(r.data)),
    options
  );

export const useV1CreateInitialSubscription = (
  options: MutateOptions<
    Subscription,
    AxiosApiError,
    SubscriptionsApiCreateSubscriptionRequest
  > = {}
) =>
  useAuthMutation<
    Subscription,
    AxiosApiError,
    SubscriptionsApiCreateSubscriptionRequest
  >(
    (token, params) =>
      buildSubscriptionsApi(token)
        .createSubscription(params)
        .then((r) => r.data),
    options
  );
