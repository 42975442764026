import React, { forwardRef, ReactNode } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ComposedChart as RComposedChart } from "recharts";
import styled from "@emotion/styled";

const ComposedChart = styled(RComposedChart)`
  & svg {
    overflow: visible;
  }
`;

export interface ChartProps<DataType> {
  data: DataType[];
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Chart = forwardRef<any, any>((props, ref) => {
  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <ComposedChart
          margin={{
            top: 20,
            right: 20,
            left: props.hideYAxis ? 20 : 0,
            bottom: 0,
          }}
          width={width}
          height={height}
          ref={ref}
          {...props}
        />
      )}
    </AutoSizer>
  );
});
