import { UseMutationOptions } from "react-query";
import {
  SubscriptionsApiUpdateSubscriptionRequest,
  Subscription,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateSubscription = async (
  token: string | undefined,
  params: SubscriptionsApiUpdateSubscriptionRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.updateSubscription(params);
  return data;
};

export const useUpdateSubscription = (
  options?: UseMutationOptions<
    Subscription,
    AxiosApiError,
    SubscriptionsApiUpdateSubscriptionRequest
  >
) => {
  return useAuthMutation(updateSubscription, options, false);
};
