import React, { CSSProperties, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { METRICS } from "@features/fundamentals/components/SecurityMetrics";

interface Props {
  defaultValue?: string;
  excludeKey?: string;
  onChange: (key?: string) => void;
  style?: CSSProperties;
}

export const FundamentalsAutocomplete = ({
  defaultValue,
  excludeKey,
  onChange,
  style,
}: Props) => {
  const { t } = useTranslation();
  const [defaultOption] = useState(
    defaultValue
      ? {
          key: defaultValue,
          label: t(`fundamentals.metrics.${defaultValue}.normal`),
        }
      : undefined
  );

  const options = METRICS.filter((key) => key !== excludeKey).map((key) => ({
    key,
    label: t(`fundamentals.metrics.${key}.normal`),
  }));

  return (
    <Autocomplete<{ key: string; label: string }>
      isOptionEqualToValue={(option, value) => option.key === value.key}
      defaultValue={defaultOption}
      data-testid="fundamentals-autocomplete"
      onChange={(event, option: { key: string; label: string } | null) => {
        onChange(option?.key);
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            "data-testid": "fundamentals-autocomplete-input",
          }}
          placeholder={t(`fundamentals.metricsAutocomplete.placeholder`)}
        />
      )}
    />
  );
};
