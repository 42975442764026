import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { TypographyWithInfoIcon } from "@components/TypographyWithInfoIcon";

const centredCss = css`
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ centred }: { centred: boolean }) => (centred ? centredCss : "")}
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

interface Props {
  title?: string;
  toolTipDesc?: string;
  centred?: boolean;
  children: ReactNode;
}

export const Block = ({
  title,
  children,
  centred = false,
  toolTipDesc,
}: Props) => (
  <Container centred={centred}>
    {title && (
      <Title>
        {toolTipDesc ? (
          <TypographyWithInfoIcon
            color="textSecondary"
            variant="body2"
            tooltipText={
              <Typography color="textPrimary" variant="body2">
                {toolTipDesc}
              </Typography>
            }
          >
            {title}
          </TypographyWithInfoIcon>
        ) : (
          <Typography variant="body2">{title}</Typography>
        )}
      </Title>
    )}
    {children}
  </Container>
);
