import { UseQueryOptions } from "react-query";
import {
  AccountsApiCashFlowsRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const cashFlows = async (
  token: string | undefined,
  params: AccountsApiCashFlowsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.cashFlows(params);
  return data;
};

export const useCashFlows = (
  params: AccountsApiCashFlowsRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_CASH_FLOWS, params],
    ({ token }) => cashFlows(token, params),
    options
  );
};
