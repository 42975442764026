import React from "react";
import { useSearchParams } from "react-router-dom";
import { NewTransactionWizard } from "./NewTransactionWizard";

export const NewTransactionWizardWithRouting = () => {
  const [searchParams] = useSearchParams();

  const tickerSymbol = searchParams.get("tickerSymbol");
  const quoteProvider = searchParams.get("quoteProvider");
  const depotId = searchParams.get("depotId");

  return (
    <NewTransactionWizard
      tickerSymbol={tickerSymbol!}
      quoteProvider={quoteProvider!}
      depotId={depotId ? Number.parseInt(depotId, 10) : undefined}
    />
  );
};
