import React, { ReactElement, useMemo } from "react";
import { orderBy } from "lodash";
import { DonutChartData } from "@components";
import { TransactionCategoryTreeNode } from "@features/cashflowAnalyzer/services/TransactionTreeBuilder";
import { useChartColors } from "@hooks";
import { ComponentsTheme } from "@theming/types";
import { ReactComponent as BankKredit } from "../icons/bank-credit.svg";
import { ReactComponent as Einnahmen } from "../icons/income.svg";
import { ReactComponent as Freizeit } from "../icons/free-time-hobby.svg";
import { ReactComponent as Kinder } from "../icons/baby.svg";
import { ReactComponent as Lebenshaltung } from "../icons/living.svg";
import { ReactComponent as Mobility } from "../icons/mobility.svg";
import { ReactComponent as NichtKlassifiziert } from "../icons/no-classification.svg";
import { ReactComponent as Shopping } from "../icons/shopping.svg";
import { ReactComponent as Versicherung } from "../icons/insurance.svg";
import { ReactComponent as Wohnen } from "../icons/rent.svg";
import { ReactComponent as SparenAnlegen } from "../icons/finance.svg";
import { ReactComponent as Custom } from "../icons/custom.svg";
import { ReactComponent as Gesundheit } from "../icons/health.svg";

const icons: Record<string, ReactElement> = {
  "Nicht klassifiziert": <NichtKlassifiziert />,
  "Bank und Kredit": <BankKredit />,
  Custom: <Custom />,
  Einnahmen: <Einnahmen />,
  "Freizeit, Hobbies und Soziales": <Freizeit />,
  "Gesundheit und Wellness": <Gesundheit />,
  Kinder: <Kinder />,
  Lebenshaltung: <Lebenshaltung />,
  Mobilität: <Mobility />,
  "Shopping und Unterhaltung": <Shopping />,
  "Sparen und Anlegen": <SparenAnlegen />,
  Versicherung: <Versicherung />,
  Wohnen: <Wohnen />,
};

const mapCategoryToChartData = (
  categories: Record<string, TransactionCategoryTreeNode>,
  colors: ComponentsTheme["charts"]["cashflowChart"]["categories"],
  parentName?: string | null
): DonutChartData[] => {
  return orderBy(Object.values(categories), (item) => Math.abs(item.total), [
    "desc",
  ]).map((category) => {
    const displayName = category.categoryName?.replace(/&/g, "und");

    return {
      id: String(category.categoryId),
      name: displayName || "Nicht klassifiziert",
      value:
        category.categoryName === "Einnahmen" || parentName === "Einnahmen"
          ? category.totalIncome
          : category.totalSpending,
      icon:
        icons[displayName || "Nicht klassifiziert"] ||
        icons["Nicht klassifiziert"],
      children: category.children
        ? mapCategoryToChartData(
            category.children,
            colors,
            category.categoryName
          )
        : [],
      color:
        colors?.[displayName || ""]?.light ||
        colors?.["Nicht klassifiziert"]?.light,
      onHoverColor:
        colors?.[displayName || ""]?.dark ||
        colors?.["Nicht klassifiziert"]?.dark,
      hiddenInChart: category.categoryName === "Einnahmen",
      isCustom: !colors?.[displayName || ""],
    };
  });
};

export const useCategoriesChartData = (
  transactionCategoryTreeNode: TransactionCategoryTreeNode
): DonutChartData[] => {
  const categories = transactionCategoryTreeNode.children;
  const colors = useChartColors("cashflowChart").categories;

  const chartData = useMemo(() => {
    if (categories && Object.keys(categories).length > 0) {
      return mapCategoryToChartData(categories, colors!);
    } else {
      return [
        {
          value: 0,
          id: "no-spending",
          name: "Keine Ausgaben",
          color: colors?.["Nicht klassifiziert"]?.light,
          onHoverColor: colors?.["Nicht klassifiziert"]?.text,
          icon: <NichtKlassifiziert />,
        },
      ];
    }
  }, [categories, colors]);

  return chartData;
};
