import { UseMutationOptions } from "react-query";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankingApiConnectionV2 = async (
  token: string | undefined
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankingApiConnectionV2();
  return data;
};

export const useDeleteBankingApiConnectionV2 = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(deleteBankingApiConnectionV2, options, false);
};
