import { axiosInstanceV1 } from "@api/axiosInstance";
import { RealizedGainByBooking } from "../model";

export const loadRealizedGains = async (
  token: string | undefined,
  investmentId: number
) => {
  const response = await axiosInstanceV1.get<RealizedGainByBooking>(
    `/performance/realizedGains/${investmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
