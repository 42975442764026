import { Transaction } from "@generated/apiv1";

export const filterTransactionsByPurposeAndInvestmentName = (
  transactions: Transaction[],
  text: string = ""
): Transaction[] => {
  if (!text) return transactions;
  if (!Boolean(transactions.length)) return [];

  const loweredText = text.toLowerCase();

  return transactions.filter(({ purpose, booking }) => {
    const investmentName = booking?.investment.name;
    if (!purpose && !investmentName) return false;
    return (
      purpose?.toLowerCase().includes(loweredText) ||
      investmentName?.toLowerCase().includes(loweredText)
    );
  });
};
