import { InvokeCallback, Sender } from "xstate";

import i18next from "i18next";
import {
  createCarveOutWithToken,
  createOrUpdateBookingsWithToken,
  createSwitchWithToken,
} from "@api/v1/methods/bookings";
import { queryClient } from "@api/queryClient";
import { BookingValues } from "../types/bookingValues";
import { InvestmentValues } from "../types/investmentValues";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import {
  buildBookingParams,
  buildCarveOutParams,
  buildInvestmentParams,
  buildInvestmentParamsList,
  buildSwitchParams,
} from "../utils/buildRequestParams";
import { isCapitalMeasure } from "../utils/utils";

export const persistBooking =
  (
    context: TransactionWizardContext
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (!context.investmentValues.depotId)
      throw new Error("no depot ID provided");
    if (!context.bookingValues.type) throw new Error("No booking type defined");
    if (
      isCapitalMeasure(context.bookingValues.type) &&
      !context.sourceInvestmentValues?.id
    )
      throw new Error("No source investment ID defined");

    isCapitalMeasure(context.bookingValues.type)
      ? persistCapitalMeasure(
          callback,
          context.sourceInvestmentValues?.id!,
          context.investmentValues,
          context.bookingValues
        )
      : persistStandard(
          callback,
          context.investmentValues,
          context.bookingValues
        );
  };

const persistStandard = async (
  callback: Sender<TransactionWizardEvent>,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    if (!investmentValues.depotId) throw new Error(`No depot ID provided`);

    const paramsList = buildInvestmentParamsList([
      buildInvestmentParams(investmentValues, [
        buildBookingParams(bookingValues),
      ]),
    ]);

    const result = await createOrUpdateBookingsWithToken({
      accountId: investmentValues.depotId,
      performBackgroundTransactionSync: false,
      autoAssignQuotes: false,
      createDepotSynchronizationLog: false,
      createOrUpdateInvestmentParamsList:
        paramsList.createOrUpdateInvestmentParamsList,
    });

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      queryClient.resetQueries();
      return;
    }

    const invalidError = i18next.t(
      "transactionWizardModal.persist.defaultError"
    );

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage || invalidError
          : i18next.t("transactionWizardModal.persist.defaultError"),
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};

const persistCapitalMeasure = async (
  callback: Sender<TransactionWizardEvent>,
  sourceInvestmentId: number,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    const investmentParams = buildInvestmentParams(investmentValues, [
      buildBookingParams(bookingValues),
    ]);

    const result =
      bookingValues.type === "carve_out"
        ? await createCarveOutWithToken(
            buildCarveOutParams(sourceInvestmentId, investmentParams)
          )
        : await createSwitchWithToken(
            buildSwitchParams(sourceInvestmentId, investmentParams)
          );

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      queryClient.invalidateQueries();
      return;
    }

    const invalidError = i18next.t(
      "transactionWizardModal.persist.defaultError"
    );

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage || invalidError
          : i18next.t("transactionWizardModal.persist.defaultError"),
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
