import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiShowAuthenticationProcessRequest,
  BankAuthenticationProcess,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showAuthenticationProcess = async (
  token: string | undefined,
  params: BankConnectionsApiShowAuthenticationProcessRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.showAuthenticationProcess(params);
  return data;
};

export const useShowAuthenticationProcess = (
  params: BankConnectionsApiShowAuthenticationProcessRequest,
  options?: UseQueryOptions<
    BankAuthenticationProcess,
    AxiosApiError,
    BankAuthenticationProcess
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_SHOW_AUTHENTICATION_PROCESS, params],
    ({ token }) => showAuthenticationProcess(token, params),
    options
  );
};
