import { Link, Typography, Box } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { FEEDBACK_EMAIL } from "@helpers";
import { PageLayout } from "@components";
import { readFromLocalStorage } from "@api/auth/readFromStorage";

// calc based on layout margin and header height
const StyledPageLayout = styled(PageLayout)`
  height: calc(100vh - 120px);

  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: calc(100vh - 50px);
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const FeedbackPage = () => {
  const { t } = useTranslation();
  const ssoToken = readFromLocalStorage()?.frill_sso_token;

  return (
    <StyledPageLayout title={t("feedbackPage.title")}>
      <Box mb={5}>
        <Typography variant="body2" color="textSecondary">
          <Trans
            i18nKey="feedbackPage.intro"
            components={{
              linkText: (
                <Link
                  href={`mailto:${FEEDBACK_EMAIL}`}
                  rel="noopener noreferrer"
                >
                  {t("feedbackPage.link")}
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
      <Box
        minHeight="800px"
        height="100%"
        mb={10}
        sx={{
          borderRadius: "6px",
          border: "1px solid #e0e0e0",
        }}
      >
        <iframe
          title="frill"
          src={`https://finanzfluss.frill.co/embed/widget/b6437cd8-f7d6-48f1-a6ea-9017c60cd730?ssoToken=${ssoToken}`}
          sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups allow-forms allow-popups-to-escape-sandbox"
          style={{
            border: "0px",
            borderRadius: "6px",
            outline: "0px",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    </StyledPageLayout>
  );
};
