/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InterfaceSelectionOption
 */
export interface InterfaceSelectionOption {
    /**
     * Technical identifier
     * @type {number}
     * @memberof InterfaceSelectionOption
     */
    'id': number;
    /**
     * The BIC / BIC Group this import option belongs to
     * @type {string}
     * @memberof InterfaceSelectionOption
     */
    'bicGroup': string;
    /**
     * The key of the import option, for internationalization
     * @type {string}
     * @memberof InterfaceSelectionOption
     */
    'optionKey': InterfaceSelectionOptionOptionKeyEnum;
    /**
     * The interfaces this import option applies to. If the option is selected,         these interfaces should be imported
     * @type {Array<string>}
     * @memberof InterfaceSelectionOption
     */
    'interfaces': Array<string>;
}

export const InterfaceSelectionOptionOptionKeyEnum = {
    CHECKING_ACCOUNTS: 'checking.accounts',
    DEPOTS_AND_REFERENCE_ACCOUNTS: 'depots.and.reference.accounts',
    DEPOTS_AND_OTHER_ACCOUNTS: 'depots.and.other.accounts',
    OTHER_ACCOUNTS: 'other.accounts',
    CREDIT_CARDS: 'credit.cards'
} as const;

export type InterfaceSelectionOptionOptionKeyEnum = typeof InterfaceSelectionOptionOptionKeyEnum[keyof typeof InterfaceSelectionOptionOptionKeyEnum];


