import { UseQueryOptions } from "react-query";
import { getInvestmentsGainersAndLosers } from "@api/v1/methods";
import { GainersAndLosers, PageableInvestmentList } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_INVESTMENTS_GAINERS_AND_LOSERS } from "../../cacheKeys";

export const useGetInvestmentsGainersAndLosers = (
  params: GainersAndLosers,
  options: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  > = { enabled: true }
) =>
  useAuthQuery(
    [GET_INVESTMENTS_GAINERS_AND_LOSERS, params],
    ({ token }) => getInvestmentsGainersAndLosers(token, params),
    options
  );
