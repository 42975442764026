/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateCustomPropertyParams } from '../model';
// @ts-ignore
import { CustomProperty } from '../model';
// @ts-ignore
import { CustomPropertyList } from '../model';
/**
 * UserPropertiesApi - axios parameter creator
 * @export
 */
export const UserPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create or update a user property.
         * @param {CreateOrUpdateCustomPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUserProperty: async (body: CreateOrUpdateCustomPropertyParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createOrUpdateUserProperty', 'body', body)
            const localVarPath = `/v1/users/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user property with the given key.
         * @param {string} id The property key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProperty: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserProperty', 'id', id)
            const localVarPath = `/v1/users/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all user properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllUserProperties: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user property with the given key.
         * @param {string} id The property key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserProperty: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showUserProperty', 'id', id)
            const localVarPath = `/v1/users/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPropertiesApi - functional programming interface
 * @export
 */
export const UserPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create or update a user property.
         * @param {CreateOrUpdateCustomPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateUserProperty(body: CreateOrUpdateCustomPropertyParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateUserProperty(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPropertiesApi.createOrUpdateUserProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the user property with the given key.
         * @param {string} id The property key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProperty(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProperty(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPropertiesApi.deleteUserProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of all user properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllUserProperties(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomPropertyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllUserProperties(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPropertiesApi.listAndSearchAllUserProperties']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the user property with the given key.
         * @param {string} id The property key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUserProperty(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUserProperty(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPropertiesApi.showUserProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPropertiesApi - factory interface
 * @export
 */
export const UserPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create or update a user property.
         * @param {UserPropertiesApiCreateOrUpdateUserPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUserProperty(requestParameters: UserPropertiesApiCreateOrUpdateUserPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomProperty> {
            return localVarFp.createOrUpdateUserProperty(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user property with the given key.
         * @param {UserPropertiesApiDeleteUserPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProperty(requestParameters: UserPropertiesApiDeleteUserPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserProperty(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all user properties.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllUserProperties(options?: RawAxiosRequestConfig): AxiosPromise<CustomPropertyList> {
            return localVarFp.listAndSearchAllUserProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user property with the given key.
         * @param {UserPropertiesApiShowUserPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserProperty(requestParameters: UserPropertiesApiShowUserPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomProperty> {
            return localVarFp.showUserProperty(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrUpdateUserProperty operation in UserPropertiesApi.
 * @export
 * @interface UserPropertiesApiCreateOrUpdateUserPropertyRequest
 */
export interface UserPropertiesApiCreateOrUpdateUserPropertyRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateCustomPropertyParams}
     * @memberof UserPropertiesApiCreateOrUpdateUserProperty
     */
    readonly body: CreateOrUpdateCustomPropertyParams
}

/**
 * Request parameters for deleteUserProperty operation in UserPropertiesApi.
 * @export
 * @interface UserPropertiesApiDeleteUserPropertyRequest
 */
export interface UserPropertiesApiDeleteUserPropertyRequest {
    /**
     * The property key
     * @type {string}
     * @memberof UserPropertiesApiDeleteUserProperty
     */
    readonly id: string
}

/**
 * Request parameters for showUserProperty operation in UserPropertiesApi.
 * @export
 * @interface UserPropertiesApiShowUserPropertyRequest
 */
export interface UserPropertiesApiShowUserPropertyRequest {
    /**
     * The property key
     * @type {string}
     * @memberof UserPropertiesApiShowUserProperty
     */
    readonly id: string
}

/**
 * UserPropertiesApi - object-oriented interface
 * @export
 * @class UserPropertiesApi
 * @extends {BaseAPI}
 */
export class UserPropertiesApi extends BaseAPI {
    /**
     * 
     * @summary Create or update a user property.
     * @param {UserPropertiesApiCreateOrUpdateUserPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPropertiesApi
     */
    public createOrUpdateUserProperty(requestParameters: UserPropertiesApiCreateOrUpdateUserPropertyRequest, options?: RawAxiosRequestConfig) {
        return UserPropertiesApiFp(this.configuration).createOrUpdateUserProperty(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user property with the given key.
     * @param {UserPropertiesApiDeleteUserPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPropertiesApi
     */
    public deleteUserProperty(requestParameters: UserPropertiesApiDeleteUserPropertyRequest, options?: RawAxiosRequestConfig) {
        return UserPropertiesApiFp(this.configuration).deleteUserProperty(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all user properties.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPropertiesApi
     */
    public listAndSearchAllUserProperties(options?: RawAxiosRequestConfig) {
        return UserPropertiesApiFp(this.configuration).listAndSearchAllUserProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user property with the given key.
     * @param {UserPropertiesApiShowUserPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPropertiesApi
     */
    public showUserProperty(requestParameters: UserPropertiesApiShowUserPropertyRequest, options?: RawAxiosRequestConfig) {
        return UserPropertiesApiFp(this.configuration).showUserProperty(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

