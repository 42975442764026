import { UseQueryOptions } from "react-query";
import {
  AssetsUnderManagementApiAssetsUnderManagementRequest,
  AssetsUnderManagementList,
} from "@generated/apiv1";

import { buildAssetsUnderManagementApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const assetsUnderManagement = async (
  token: string | undefined,
  params?: AssetsUnderManagementApiAssetsUnderManagementRequest
) => {
  const apiInstance = buildAssetsUnderManagementApi(token);
  const { data } = await apiInstance.assetsUnderManagement(params);
  return data;
};

export const useAssetsUnderManagement = (
  params?: AssetsUnderManagementApiAssetsUnderManagementRequest,
  options?: UseQueryOptions<
    AssetsUnderManagementList,
    AxiosApiError,
    AssetsUnderManagementList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ASSETSUNDERMANAGEMENT_ASSETS_UNDER_MANAGEMENT, params],
    ({ token }) => assetsUnderManagement(token, params),
    options
  );
};
