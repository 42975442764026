import { UseQueryOptions } from "react-query";
import { UsersApiUsersIdRequest, User } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const usersId = async (
  token: string | undefined,
  params: UsersApiUsersIdRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.usersId(params);
  return data;
};

export const useUsersId = (
  params: UsersApiUsersIdRequest,
  options?: UseQueryOptions<User, AxiosApiError, User>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERS_USERS_ID, params],
    ({ token }) => usersId(token, params),
    options
  );
};
