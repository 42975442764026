/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateSubcategory
 */
export interface UpdateSubcategory {
    /**
     * The name of the subcategory
     * @type {string}
     * @memberof UpdateSubcategory
     */
    'name': string;
    /**
     * A mapping or slug for this category
     * @type {string}
     * @memberof UpdateSubcategory
     */
    'mapping'?: string;
    /**
     * ID of the category this subcategory belongs to
     * @type {string}
     * @memberof UpdateSubcategory
     */
    'category_id': string;
    /**
     * Status of the subcategory if it is active or deleted
     * @type {string}
     * @memberof UpdateSubcategory
     */
    'status'?: UpdateSubcategoryStatusEnum;
}

export const UpdateSubcategoryStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted'
} as const;

export type UpdateSubcategoryStatusEnum = typeof UpdateSubcategoryStatusEnum[keyof typeof UpdateSubcategoryStatusEnum];


