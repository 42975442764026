/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NewsletterSubscriptionStatus
 */
export interface NewsletterSubscriptionStatus {
    /**
     * The newsletter subscription status,             **subscribed**: user is subscribed             **unsubscribed**: user is unsubscribed             **not_in_list**: user is not in the list, i.e. was never subscribed.
     * @type {string}
     * @memberof NewsletterSubscriptionStatus
     */
    'status'?: NewsletterSubscriptionStatusStatusEnum;
}

export const NewsletterSubscriptionStatusStatusEnum = {
    SUBSCRIBED: 'subscribed',
    UNSUBSCRIBED: 'unsubscribed',
    NOT_IN_LIST: 'not_in_list'
} as const;

export type NewsletterSubscriptionStatusStatusEnum = typeof NewsletterSubscriptionStatusStatusEnum[keyof typeof NewsletterSubscriptionStatusStatusEnum];


