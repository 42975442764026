import React from "react";
import { useTranslation } from "react-i18next";
import { Dividend } from "@api";
import { StepContainer } from "../../transactionWizard/StepContainer";
import { SummaryTable } from "../components/SummaryTable";

export interface Props {
  selectedDividends: Dividend[];
  onNext: () => void;
  onPrevious: () => void;
}

export const SummaryStep = (props: Props) => {
  const { t } = useTranslation();
  const { selectedDividends, onPrevious, onNext } = props;
  return (
    <StepContainer
      testid="summary-step"
      title={t("batchDividendsWizard.summaryStep.title")}
      onPrevious={onPrevious}
      onNext={onNext}
      nextLabel={t("batchDividendsWizard.summaryStep.saveButtonLabel")}
    >
      <SummaryTable selectedDividends={selectedDividends} />
    </StepContainer>
  );
};
