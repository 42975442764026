/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Container for a status of bank connection update
 * @export
 * @interface UpdateResult
 */
export interface UpdateResult {
    /**
     * Note that \'OK\' just means that finAPI could successfully connect and log in to the bank server. However, it does not necessarily mean that all accounts could be updated successfully. For the latter, please refer to the \'status\' field of the Account resource.
     * @type {string}
     * @memberof UpdateResult
     */
    'result': UpdateResultResultEnum;
    /**
     * In case the update result is not <code>OK</code>, this field may contain an error message with details about why the update failed (it is not guaranteed that a message is available though). In case the update result is <code>OK</code>, the field will always be null.
     * @type {string}
     * @memberof UpdateResult
     */
    'errorMessage'?: string;
    /**
     * In case the update result is not <code>OK</code>, this field contains the type of the error that occurred. BUSINESS means that the bank server responded with a non-technical error message for the user. TECHNICAL means that some internal error has occurred in finAPI or at the bank server.
     * @type {string}
     * @memberof UpdateResult
     */
    'errorType'?: UpdateResultErrorTypeEnum;
    /**
     * Time of the update. The value is returned in the format \'YYYY-MM-DD HH:MM:SS.SSS\' (german time).
     * @type {string}
     * @memberof UpdateResult
     */
    'timestamp': string;
}

export const UpdateResultResultEnum = {
    OK: 'OK',
    BANK_SERVER_REJECTION: 'BANK_SERVER_REJECTION',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR'
} as const;

export type UpdateResultResultEnum = typeof UpdateResultResultEnum[keyof typeof UpdateResultResultEnum];
export const UpdateResultErrorTypeEnum = {
    BUSINESS: 'BUSINESS',
    TECHNICAL: 'TECHNICAL'
} as const;

export type UpdateResultErrorTypeEnum = typeof UpdateResultErrorTypeEnum[keyof typeof UpdateResultErrorTypeEnum];


