import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setZeroTaxAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "customTaxAmount"> => {
  if (event.type !== "SKIP_TAX") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    customTaxAmount: 0,
  };
};
