/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { MandatorInvoice } from '../model';
/**
 * MandatorInvoicesApi - axios parameter creator
 * @export
 */
export const MandatorInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get an invoice / invoicing data for the given month
         * @param {number} id ID of the mandator to retrieve invoicing data for
         * @param {string} [month] The month to invoice in format \&#39;YYYY-MM\&#39;; if not given the current month is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mandatorsIdInvoicing: async (id: number, month?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mandatorsIdInvoicing', 'id', id)
            const localVarPath = `/v1/mandators/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MandatorInvoicesApi - functional programming interface
 * @export
 */
export const MandatorInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MandatorInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get an invoice / invoicing data for the given month
         * @param {number} id ID of the mandator to retrieve invoicing data for
         * @param {string} [month] The month to invoice in format \&#39;YYYY-MM\&#39;; if not given the current month is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mandatorsIdInvoicing(id: number, month?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MandatorInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mandatorsIdInvoicing(id, month, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorInvoicesApi.mandatorsIdInvoicing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MandatorInvoicesApi - factory interface
 * @export
 */
export const MandatorInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MandatorInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get an invoice / invoicing data for the given month
         * @param {MandatorInvoicesApiMandatorsIdInvoicingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mandatorsIdInvoicing(requestParameters: MandatorInvoicesApiMandatorsIdInvoicingRequest, options?: RawAxiosRequestConfig): AxiosPromise<MandatorInvoice> {
            return localVarFp.mandatorsIdInvoicing(requestParameters.id, requestParameters.month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mandatorsIdInvoicing operation in MandatorInvoicesApi.
 * @export
 * @interface MandatorInvoicesApiMandatorsIdInvoicingRequest
 */
export interface MandatorInvoicesApiMandatorsIdInvoicingRequest {
    /**
     * ID of the mandator to retrieve invoicing data for
     * @type {number}
     * @memberof MandatorInvoicesApiMandatorsIdInvoicing
     */
    readonly id: number

    /**
     * The month to invoice in format \&#39;YYYY-MM\&#39;; if not given the current month is used
     * @type {string}
     * @memberof MandatorInvoicesApiMandatorsIdInvoicing
     */
    readonly month?: string
}

/**
 * MandatorInvoicesApi - object-oriented interface
 * @export
 * @class MandatorInvoicesApi
 * @extends {BaseAPI}
 */
export class MandatorInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Get an invoice / invoicing data for the given month
     * @param {MandatorInvoicesApiMandatorsIdInvoicingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorInvoicesApi
     */
    public mandatorsIdInvoicing(requestParameters: MandatorInvoicesApiMandatorsIdInvoicingRequest, options?: RawAxiosRequestConfig) {
        return MandatorInvoicesApiFp(this.configuration).mandatorsIdInvoicing(requestParameters.id, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }
}

