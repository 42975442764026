import React from "react";
import { styled, Typography, Box } from "@mui/material";
import { WatchlistEntry } from "@generated/apiv1";
import { toCurrency } from "@helpers";
import { TableBody, TableRow, TableBodyCell } from "@components/Table";
import {
  GainLossTypography,
  GainLossPercents,
  GainLossPercentsTypography,
} from "@components";
import { InvestmentInfo } from "@components/InvestmentInfo";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";

const StyledGainLossPercents = styled(GainLossPercents)`
  width: fit-content;
`;

const StyledGainLossTypography = ({
  value,
  currency = "EUR",
}: {
  value: number;
  currency?: string;
}) => {
  if (value === 0)
    return (
      <GainLossTypography
        variant="body2"
        value={value ?? 0}
        width="fit-content"
      >
        {toCurrency(value ?? 0, currency)}
      </GainLossTypography>
    );
  return (
    <GainLossPercentsTypography value={value} style={{ width: "fit-content" }}>
      {(value ?? 0) > 0 && "+"}
      <Box component="span" marginRight={1}>
        {toCurrency(value ?? 0, currency)}
      </Box>
      {(value ?? 0) < 0 ? (
        <DownIcon width="10px" height="8px" />
      ) : (
        <UpIcon width="10px" height="8px" />
      )}
    </GainLossPercentsTypography>
  );
};

const DoubleValuesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const LastColumnValuesContainer = styled(DoubleValuesContainer)`
  align-items: flex-end;
`;

interface WatchlistItemsTableBodyProps {
  watchlistId: string;
  data: (WatchlistEntry & { color?: string })[];
}

export const WatchlistItemsTableBody = (
  props: WatchlistItemsTableBodyProps
) => {
  const { data = [], watchlistId } = props;
  const logos = useSecurityImages(data);

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.id} data-testid="security-table-row">
          <TableBodyCell>
            <InvestmentInfo
              item={item}
              image={logos[item.isin!] || logos[item.tickerSymbol!]}
              circleColor={item.color}
              link={`${process.env.REACT_APP_V2_URL}/watchlists/${watchlistId}/securities/${item.id}`}
            />
          </TableBodyCell>

          <TableBodyCell>
            <DoubleValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(
                  item.tickerSymbol === "SILVER_EUR_IN_GRAMS"
                    ? (item.purchasePrice ?? 0) * 100
                    : item.purchasePrice,
                  item?.currency || "EUR"
                )}
              </Typography>
              <StyledGainLossPercents
                value={item.returnOnInvestmentInPercent ?? 0}
              />
            </DoubleValuesContainer>
          </TableBodyCell>

          <TableBodyCell>
            <DoubleValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(item?.quote?.last ?? 0, item?.currency || "EUR")}
              </Typography>
              <StyledGainLossTypography
                value={item.returnOnInvestment ?? 0}
                currency={item.currency}
              />
            </DoubleValuesContainer>
          </TableBodyCell>

          <TableBodyCell align="right">
            <DoubleValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(
                  item?.quote?.changeAbs ?? 0,
                  item?.currency || "EUR"
                )}
              </Typography>
              <StyledGainLossPercents
                value={item?.quote?.changePercentAbs ?? 0}
              />
            </DoubleValuesContainer>
          </TableBodyCell>

          <TableBodyCell align="right">
            <DoubleValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(item.oneYearAgo ?? 0, item?.currency || "EUR")}
              </Typography>
              <StyledGainLossPercents value={item.oneYearAgoInPercent ?? 0} />
            </DoubleValuesContainer>
          </TableBodyCell>

          <TableBodyCell align="right">
            <DoubleValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(item.threeYearsAgo ?? 0, item?.currency || "EUR")}
              </Typography>
              <StyledGainLossPercents
                value={item.threeYearsAgoInPercent ?? 0}
              />
            </DoubleValuesContainer>
          </TableBodyCell>

          <TableBodyCell align="right">
            <LastColumnValuesContainer>
              <Typography variant="body2" color="textPrimary">
                {toCurrency(item.fiveYearsAgo ?? 0, item?.currency || "EUR")}
              </Typography>
              <StyledGainLossPercents value={item.fiveYearsAgoInPercent ?? 0} />
            </LastColumnValuesContainer>
          </TableBodyCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
