import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, useTheme } from "@mui/material";
import { getPercent, toPercent } from "@helpers";

export const YTDPercentContainer = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "value",
})<{
  value?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5, 1)};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-radius: 12px;
  color: ${({
    theme: {
      palette: { text },
    },
    value = 0,
  }) => {
    if (value > 0) {
      return text.positive;
    } else if (value < 0) {
      return text.negative;
    }

    return text.secondary;
  }};
  background: ${({
    theme: {
      palette: { background },
    },
    value = 0,
  }) => {
    if (value > 0) {
      return background.success;
    } else if (value < 0) {
      return background.error;
    }

    return background.neutral;
  }};
`;

export const intervalTitle: Record<string, string> = {
  oneWeek: "1W",
  oneMonth: "1M",
  YTD: "YTD",
  oneYear: "1J",
  threeYears: "3J",
  fiveYears: "5J",
  sinceBeginning: "MAX",
};

export type YTDPercentProps = {
  value: number;
  interval: string;
};

export const YTDPercents = ({ value, interval }: YTDPercentProps) => {
  const theme = useTheme();
  const percent = getPercent(value);
  const formattedPercent = toPercent(value - 1);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="body2"
        color="textSecondary"
        mr={theme.spacing(1.5)}
        fontWeight={400}
      >
        {intervalTitle[interval]}
      </Typography>
      <YTDPercentContainer value={percent}>
        {formattedPercent}
      </YTDPercentContainer>
    </Box>
  );
};
