/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CompanyLogosResponse } from '../model';
/**
 * CompanyLogosApi - axios parameter creator
 * @export
 */
export const CompanyLogosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary filter logos by multiple isin
         * @param {string} quoteProvider 
         * @param {string} [filterIsin] Comma separated values.Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {string} [filterSymbol] Comma separated values of cypto-currency ticker symbols.Example: \&#39;filter[symbol]&#x3D;BTC_to_EUR,ETH_to_EUR,USDT_to_EUR\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogos: async (quoteProvider: string, filterIsin?: string, filterSymbol?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getCompanyLogos', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/company_logos`
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIsin !== undefined) {
                localVarQueryParameter['filter[isin]'] = filterIsin;
            }

            if (filterSymbol !== undefined) {
                localVarQueryParameter['filter[symbol]'] = filterSymbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyLogosApi - functional programming interface
 * @export
 */
export const CompanyLogosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyLogosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary filter logos by multiple isin
         * @param {string} quoteProvider 
         * @param {string} [filterIsin] Comma separated values.Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {string} [filterSymbol] Comma separated values of cypto-currency ticker symbols.Example: \&#39;filter[symbol]&#x3D;BTC_to_EUR,ETH_to_EUR,USDT_to_EUR\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLogos(quoteProvider: string, filterIsin?: string, filterSymbol?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLogosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLogos(quoteProvider, filterIsin, filterSymbol, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyLogosApi.getCompanyLogos']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyLogosApi - factory interface
 * @export
 */
export const CompanyLogosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyLogosApiFp(configuration)
    return {
        /**
         * 
         * @summary filter logos by multiple isin
         * @param {CompanyLogosApiGetCompanyLogosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogos(requestParameters: CompanyLogosApiGetCompanyLogosRequest, options?: RawAxiosRequestConfig): AxiosPromise<CompanyLogosResponse> {
            return localVarFp.getCompanyLogos(requestParameters.quoteProvider, requestParameters.filterIsin, requestParameters.filterSymbol, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCompanyLogos operation in CompanyLogosApi.
 * @export
 * @interface CompanyLogosApiGetCompanyLogosRequest
 */
export interface CompanyLogosApiGetCompanyLogosRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyLogosApiGetCompanyLogos
     */
    readonly quoteProvider: string

    /**
     * Comma separated values.Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
     * @type {string}
     * @memberof CompanyLogosApiGetCompanyLogos
     */
    readonly filterIsin?: string

    /**
     * Comma separated values of cypto-currency ticker symbols.Example: \&#39;filter[symbol]&#x3D;BTC_to_EUR,ETH_to_EUR,USDT_to_EUR\&#39;
     * @type {string}
     * @memberof CompanyLogosApiGetCompanyLogos
     */
    readonly filterSymbol?: string
}

/**
 * CompanyLogosApi - object-oriented interface
 * @export
 * @class CompanyLogosApi
 * @extends {BaseAPI}
 */
export class CompanyLogosApi extends BaseAPI {
    /**
     * 
     * @summary filter logos by multiple isin
     * @param {CompanyLogosApiGetCompanyLogosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyLogosApi
     */
    public getCompanyLogos(requestParameters: CompanyLogosApiGetCompanyLogosRequest, options?: RawAxiosRequestConfig) {
        return CompanyLogosApiFp(this.configuration).getCompanyLogos(requestParameters.quoteProvider, requestParameters.filterIsin, requestParameters.filterSymbol, options).then((request) => request(this.axios, this.basePath));
    }
}

