import { UseQueryOptions } from "react-query";
import {
  CashbackIncidentsApiGetCashbackIncidentRequest,
  CashbackIncident,
} from "@generated/apiv1";

import { buildCashbackIncidentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getCashbackIncident = async (
  token: string | undefined,
  params: CashbackIncidentsApiGetCashbackIncidentRequest
) => {
  const apiInstance = buildCashbackIncidentsApi(token);
  const { data } = await apiInstance.getCashbackIncident(params);
  return data;
};

export const useGetCashbackIncident = (
  params: CashbackIncidentsApiGetCashbackIncidentRequest,
  options?: UseQueryOptions<CashbackIncident, AxiosApiError, CashbackIncident>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CASHBACKINCIDENTS_GET_CASHBACK_INCIDENT, params],
    ({ token }) => getCashbackIncident(token, params),
    options
  );
};
