import React from "react";
import { HistoricalQuotesBaseChart } from "@components/Chart/HistoricalQuotesChart";
import { useInvestment } from "@api/v1/hooks/useInvestment";
import { useHistoricQuotesPercent } from "@features/fundamentals/hooks/useHistoricQuotesForInvestment";
import { getFromDate } from "@features/fundamentals/hooks/chartData/getFromDate";

interface Props {
  investmentId: number;
}

export const HistoricalQuotesByInvestmentChart = ({ investmentId }: Props) => {
  const { data: investment, isLoading: isInvestmentLoading } =
    useInvestment(investmentId);

  const { data: historicQuotes, isLoading: isHistoricQuotesLoading } =
    useHistoricQuotesPercent(
      investmentId,
      investment?.tickerSymbolWithCurrency,
      getFromDate()
    );

  return (
    <HistoricalQuotesBaseChart
      historicQuotes={historicQuotes}
      isLoading={isInvestmentLoading || isHistoricQuotesLoading}
    />
  );
};
