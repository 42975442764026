import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiBatchInvestmentPerformanceRequest,
  BatchSingleInvestmentTwror,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const batchInvestmentPerformance = async (
  token: string | undefined,
  params?: InvestmentsApiBatchInvestmentPerformanceRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.batchInvestmentPerformance(params);
  return data;
};

export const useBatchInvestmentPerformance = (
  params?: InvestmentsApiBatchInvestmentPerformanceRequest,
  options?: UseQueryOptions<
    BatchSingleInvestmentTwror,
    AxiosApiError,
    BatchSingleInvestmentTwror
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_BATCH_INVESTMENT_PERFORMANCE, params],
    ({ token }) => batchInvestmentPerformance(token, params),
    options
  );
};
