import { AccountGroupName } from "@features/selectDepots/types";

export const getSelectedGroupFromStorage = (
  groupName: AccountGroupName,
  publicId?: string
) => {
  const group = localStorage.getItem(
    `selectedGroup${publicId ? `-${publicId}-` : ""}${groupName}`
  );
  if (!group) return [];

  return JSON.parse(group);
};
