import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Container } from "./Symbol";

const ContainerWithBorder = styled(Container)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: solid 1px ${({ theme }) => theme.palette.border.primary};
`;

export const SymbolSkeleton = () => {
  return (
    <ContainerWithBorder data-testid="symbol-skeleton">
      <Skeleton variant="text" width="100%" height={22}>
        <Typography variant="body1">The symbol name and ISIN</Typography>
      </Skeleton>
      <Skeleton variant="text" width="100%" height={18}>
        <Typography variant="body2">The exchange and price</Typography>
      </Skeleton>
    </ContainerWithBorder>
  );
};
