import { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
  useAuthQuery,
  loadProperties,
  updateProperties,
  useAuthMutation,
} from "@api";

export const SECURITY_KEY = "securityDashboardSettings";
export const FUNDS_KEY = "fundDashboardSettings";

export const useSettings = <V>(propKey: string) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useAuthQuery(
    ["my-security-dashboard-settings", propKey],
    ({ token }) => loadProperties(token, propKey),
    { staleTime: 1000 * 60 * 5 }
  );

  const { mutate: update } = useAuthMutation<
    any,
    unknown,
    {
      values: V[];
      propKey: string;
    },
    unknown
  >(updateProperties, {
    onSettled: () => {
      queryClient.invalidateQueries("my-security-dashboard-settings");
    },
  });

  const handleSave = useCallback(
    (values: V[]) => {
      update({ values, propKey });
    },
    [update, propKey]
  );

  return {
    settings: data?.value?.values as V[] | undefined,
    save: handleSave,
    isLoading,
  };
};
