import { UseMutationOptions } from "react-query";
import {
  TransactionsApiUpdateCashTransactionRequest,
  Transaction,
} from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateCashTransaction = async (
  token: string | undefined,
  params: TransactionsApiUpdateCashTransactionRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.updateCashTransaction(params);
  return data;
};

export const useUpdateCashTransaction = (
  options?: UseMutationOptions<
    Transaction,
    AxiosApiError,
    TransactionsApiUpdateCashTransactionRequest
  >
) => {
  return useAuthMutation(updateCashTransaction, options, false);
};
