import { UseQueryOptions } from "react-query";
import {
  BankControllerApiShow1Request,
  Bank,
} from "@generated/apiv1/pdfParser";

import { buildBankControllerApi } from "@generated/apiv1/pdfParser/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/pdfParser/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const show1 = async (
  token: string | undefined,
  params: BankControllerApiShow1Request
) => {
  const apiInstance = buildBankControllerApi(token);
  const { data } = await apiInstance.show1(params);
  return data;
};

export const useShow1 = (
  params: BankControllerApiShow1Request,
  options?: UseQueryOptions<Bank, AxiosApiError, Bank>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONTROLLER_SHOW1, params],
    ({ token }) => show1(token, params),
    options
  );
};
