import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CopyTypography } from "@components";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { SecurityImage } from "@components/SecurityImage";
import { AccountReference } from "@generated/apiv1";

type Props = {
  item: {
    account?: AccountReference;
    id?: number | string;
    wkn?: string;
    type?: string;
    isin?: string;
    hasValidIsin?: boolean;
    standardisedName?: string;
    name?: string;
    tickerSymbol?: string;
    quote?: {
      quoteProvider: string;
    };
    quoteProvider?: string;
  };
  circleColor?: string;
  link?: string | null;
  image?: string | null;
};

export const InvestmentInfo = ({ item, link, image, circleColor }: Props) => {
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const { t } = useTranslation();
  const name = item.standardisedName || item.name;
  const linkToGo =
    link === null || !item.id
      ? undefined
      : link || `${process.env.REACT_APP_V2_URL}/investments/${item.id}`;
  const isinValue =
    item.quote?.quoteProvider === "crypto" || item.quoteProvider === "crypto"
      ? item.tickerSymbol?.split("_")[0]
      : item.hasValidIsin
      ? item.isin
      : undefined;

  return (
    <Box display="flex" alignItems="center" gap={2} position="relative">
      <SecurityImage src={image} alt={name} circleColor={circleColor} />
      <Box>
        {isSharedPortfolio || !linkToGo ? (
          <Typography fontWeight={400} variant="body2">
            {name}
          </Typography>
        ) : (
          <Link
            href={linkToGo}
            sx={{
              lineHeight: "22px",
              fontWeight: 400,
            }}
          >
            {name}
          </Link>
        )}
        <Box display="flex" alignItems="center" gap={2}>
          {item.type && (
            <Typography
              color="textSecondary"
              variant="body2"
              fontWeight={400}
              lineHeight="24px"
              noWrap
            >
              {t(
                `dashboardPage.dashboard.investmentsTable.types.${item.type}`,
                {
                  count: 1,
                }
              )}
            </Typography>
          )}
          {item.wkn && (
            <CopyTypography lineHeight="24px">{item.wkn}</CopyTypography>
          )}
          {isinValue && (
            <CopyTypography lineHeight="24px">{isinValue}</CopyTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
