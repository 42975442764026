import React, { ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import i18next from "i18next";
import { DividendHistoryTreeNode, InvestmentReference } from "@generated/apiv1";
import { SecurityImage } from "@components/SecurityImage";
import { TypographyWithInfoIcon } from "@components";
import { toCurrency } from "@helpers";
import { DividendFrequency } from "@features/dividendPlanner/components/DividendFrequency";
import { BlurredTypography } from "@components/BlurredTypography";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";

const Row = ({
  i18nkey,
  value,
  hideForNonPro,
}: {
  i18nkey: string;
  value: ReactNode;
  hideForNonPro?: boolean;
}) => {
  const { isUserPro, isUserDataFetched } = useDividendsContext();
  const label = i18next.t(`dividends.calendarPage.tooltip.${i18nkey}`);
  const tooltip = i18next.exists(
    `dividends.calendarPage.tooltip.${i18nkey}Tooltip`
  )
    ? i18next.t(`dividends.calendarPage.tooltip.${i18nkey}Tooltip`)
    : null;

  if (!isUserDataFetched) return null;

  return (
    <>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center" height="100%">
          {tooltip ? (
            <TypographyWithInfoIcon
              tooltipText={tooltip}
              variant="body2"
              color="textSecondary"
              iconSize={16}
              noWrap
            >
              {label}
            </TypographyWithInfoIcon>
          ) : (
            <Typography variant="body2" color="textSecondary" noWrap>
              {label}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          height="100%"
        >
          {typeof value === "string" ? (
            <BlurredTypography
              variant="body2"
              shouldBlur={!isUserPro && hideForNonPro}
              blur={6}
            >
              {value}
            </BlurredTypography>
          ) : (
            value
          )}
        </Box>
      </Grid>
    </>
  );
};

type Props = {
  dividend: DividendHistoryTreeNode;
  investment?: InvestmentReference;
  image?: string | null;
};

export const DividendTooltip = ({ dividend, investment, image }: Props) => {
  return (
    <Box px={2} py={1}>
      <Box display="flex" alignItems="center" gap={2} mb={3.5}>
        <SecurityImage
          src={image}
          alt={investment?.standardisedName}
          width={20}
          height={20}
        />
        <Typography variant="body1" noWrap>
          {investment?.standardisedName}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Row
          i18nkey="paymentDate"
          value={
            dividend.paymentDate &&
            format(new Date(dividend.paymentDate), "dd.MM.yyyy")
          }
          hideForNonPro
        />
        {dividend.exDate && (
          <Row
            i18nkey="exDate"
            value={format(new Date(dividend.exDate), "dd.MM.yyyy")}
            hideForNonPro
          />
        )}
        <Row
          i18nkey="frequency"
          value={<DividendFrequency dividend={dividend} />}
        />
        <Row
          i18nkey="grossAmount"
          value={toCurrency(dividend.grossAmount)}
          hideForNonPro
        />
      </Grid>
    </Box>
  );
};
