import { UseMutationOptions } from "react-query";
import {
  AccountsApiCreateOrUpdateSelectGroupRequest,
  AccountSelectGroup,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createOrUpdateSelectGroup = async (
  token: string | undefined,
  params: AccountsApiCreateOrUpdateSelectGroupRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.createOrUpdateSelectGroup(params);
  return data;
};

export const useCreateOrUpdateSelectGroup = (
  options?: UseMutationOptions<
    AccountSelectGroup,
    AxiosApiError,
    AccountsApiCreateOrUpdateSelectGroupRequest
  >
) => {
  return useAuthMutation(createOrUpdateSelectGroup, options, false);
};
