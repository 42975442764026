import { UseQueryOptions } from "react-query";
import { getSingleWatchlist } from "@api/v1/methods";
import { Watchlist } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_SINGLE_WATCHLIST } from "../../cacheKeys";

export const useGetSingleWatchlist = (
  watchlistId: string,
  options?: UseQueryOptions<Watchlist, AxiosApiError, Watchlist>
) =>
  useAuthQuery(
    [GET_SINGLE_WATCHLIST, watchlistId],
    ({ token }) => getSingleWatchlist(token, watchlistId),
    options
  );
