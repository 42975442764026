import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  TextField,
  TextFieldProps,
  Box,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "@icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "@icons/eye-cross.svg";

export const PasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="toggle-password-visibility"
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((show) => !show)}
              size="small"
              sx={{ marginRight: (theme) => theme.spacing(-2) }}
            >
              {showPassword ? (
                <Box color="icon.primary" display="flex">
                  <EyeIcon width="24px" height="24px" />
                </Box>
              ) : (
                <Box color="icon.primary" display="flex">
                  <EyeOffIcon width="24px" height="24px" />
                </Box>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
