import { Theme } from "@emotion/react";
import React from "react";
import { ReferenceArea } from "recharts";

interface Props {
  refAreaLeft?: Date;
  refAreaRight?: Date;
  theme: Theme;
}

export const DateReferenceArea = ({
  refAreaLeft,
  refAreaRight,
  theme,
}: Props) =>
  refAreaLeft && refAreaRight ? (
    <ReferenceArea
      yAxisId="1"
      x1={refAreaLeft.toString()}
      x2={refAreaRight.toString()}
      strokeOpacity={0.1}
      stroke={theme.palette.grey[400]}
      fill={theme.palette.grey[400]}
      fillOpacity={0.1}
    />
  ) : null;
