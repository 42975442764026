import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { ReactComponent as UploadIcon } from "@icons/cloud.svg";
import { NewTransactionModal } from "@features/transactionWizard/modal/NewTransactionModal";
import { AccountTypeEnum } from "@generated/apiv1";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";

export const InvestmentsActionButton = ({
  investmentId,
  accountId,
  accountType,
  accountCategory,
}: {
  investmentId?: number;
  accountId?: number;
  accountType?: AccountTypeEnum;
  accountCategory?: AccountSubcategoryEnum;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => setMenuEl(null);

  const handleCreateClick = () => {
    setIsOpen(true);
    handleCloseMenu();
  };

  const isFromInvestments = new URL(window.location.href).pathname?.match(
    "/investments/(.*)"
  )?.[1];
  const isFromDepots = new URL(window.location.href).pathname?.match(
    "/depots/(.*)"
  )?.[1];
  const isAddButtonDisabled = Boolean(
    (isFromInvestments && !investmentId) || (isFromDepots && !accountId)
  );

  return (
    <>
      {isDownMd ? (
        <IconButton
          data-testid="button-header-menu"
          color="secondary"
          onClick={handleOpenMenuClick}
        >
          <PlusIcon />
        </IconButton>
      ) : (
        <Button
          data-testid="button-header-menu"
          variant="contained"
          color="secondary"
          startIcon={<PlusIcon />}
          onClick={handleOpenMenuClick}
        >
          {t("investments.newTransaction")}
        </Button>
      )}
      <Menu
        open={Boolean(menuEl)}
        anchorEl={menuEl}
        keepMounted
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          data-testid="investment-create"
          onClick={handleCreateClick}
          disabled={isAddButtonDisabled}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <PlusIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />
            {t("investmentsPage.header.actions.create")}
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          data-testid="upload-pdf"
          to={
            accountId
              ? `/depots/${accountId}/file-drop`
              : "/investments/file-drop"
          }
        >
          <Box display="flex" alignItems="center" gap={3}>
            <UploadIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />

            {t("investmentsPage.header.actions.uploadPdf")}
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          data-testid="upload-csv"
          to={
            accountId
              ? `/depots/${accountId}/csv-drop`
              : "/investments/csv-drop"
          }
        >
          <Box display="flex" alignItems="center" gap={3}>
            <UploadIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />

            {t("investmentsPage.header.actions.uploadCsv")}
          </Box>
        </MenuItem>
      </Menu>
      <NewTransactionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        accountId={accountId}
        accountType={accountType}
        accountCategory={accountCategory}
        investmentId={investmentId}
      />
    </>
  );
};
