import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const addValidationErrorAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "BACK") return {};

  return {
    validationErrors: event.validationErrors,
  };
};
