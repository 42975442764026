/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BankAuthenticationProcessAttributes
 */
export interface BankAuthenticationProcessAttributes {
    /**
     * The status of this current authentication run
     * @type {string}
     * @memberof BankAuthenticationProcessAttributes
     */
    'status': BankAuthenticationProcessAttributesStatusEnum;
    /**
     * The user executing this authentication run
     * @type {number}
     * @memberof BankAuthenticationProcessAttributes
     */
    'user_id': number;
    /**
     * identifies the bank being imported
     * @type {string}
     * @memberof BankAuthenticationProcessAttributes
     */
    'bank_slug': BankAuthenticationProcessAttributesBankSlugEnum;
    /**
     * Any error we received from the bank
     * @type {string}
     * @memberof BankAuthenticationProcessAttributes
     */
    'authentication_error'?: string;
    /**
     * Redirect here in case a redirect is required for oauth or similar
     * @type {string}
     * @memberof BankAuthenticationProcessAttributes
     */
    'redirect_url'?: string;
}

export const BankAuthenticationProcessAttributesStatusEnum = {
    AUTHENTICATED: 'AUTHENTICATED',
    AUTHENTICATION_IN_PROGRESS: 'AUTHENTICATION_IN_PROGRESS',
    CHALLENGE_RESPONSE_REQUIRED: 'CHALLENGE_RESPONSE_REQUIRED',
    VERIFYING_CHALLENGE_RESPONSE: 'VERIFYING_CHALLENGE_RESPONSE',
    DECOUPLED_AUTH_REQUIRED: 'DECOUPLED_AUTH_REQUIRED',
    AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
    REDIRECT_REQUIRED: 'REDIRECT_REQUIRED'
} as const;

export type BankAuthenticationProcessAttributesStatusEnum = typeof BankAuthenticationProcessAttributesStatusEnum[keyof typeof BankAuthenticationProcessAttributesStatusEnum];
export const BankAuthenticationProcessAttributesBankSlugEnum = {
    TRADE_REPUBLIC: 'TradeRepublic',
    FINANZEN_NET: 'finanzen_net',
    BISON_APP: 'bison_app',
    BINANCE: 'binance',
    QUIRION: 'Quirion',
    DEGIRO: 'Degiro',
    BITPANDA: 'Bitpanda',
    WHITEBOX: 'Whitebox',
    SCALABLE_CAPITAL: 'ScalableCapital',
    MOCK_BANK: 'MockBank',
    SMARTBROKER_PLUS: 'SmartbrokerPlus',
    JUST_TRADE: 'JustTrade',
    PARQET: 'Parqet'
} as const;

export type BankAuthenticationProcessAttributesBankSlugEnum = typeof BankAuthenticationProcessAttributesBankSlugEnum[keyof typeof BankAuthenticationProcessAttributesBankSlugEnum];


