import { BookingTypeEnum } from "@api";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setBookingTypeAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "BOOKING_TYPE_SELECTED" &&
    event.type !== "CAPITAL_MEASURE_SELECTED" &&
    event.type !== "SPLIT_SELECTED"
  )
    throw new Error(`Invalid event type ${event.type}`);

  return {
    bookingValues: {
      ...context.bookingValues,
      type: event.bookingType,
      numberOfLots: shouldTakeoverNol(event.bookingType)
        ? context.investmentValues.numberOfLots
        : undefined,
    },
  };
};

const shouldTakeoverNol = (bookingType: BookingTypeEnum) =>
  ["sell", "book_out", "switch"].includes(bookingType);
