/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateCategory
 */
export interface UpdateCategory {
    /**
     * The name of the category
     * @type {string}
     * @memberof UpdateCategory
     */
    'name': string;
    /**
     * Type of the category
     * @type {string}
     * @memberof UpdateCategory
     */
    'category_type': string;
    /**
     * Status of the category if it is active or deleted
     * @type {string}
     * @memberof UpdateCategory
     */
    'status'?: UpdateCategoryStatusEnum;
}

export const UpdateCategoryStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted'
} as const;

export type UpdateCategoryStatusEnum = typeof UpdateCategoryStatusEnum[keyof typeof UpdateCategoryStatusEnum];


