import { UseQueryOptions } from "react-query";
import { loadDividends, DividendsParams } from "@api/v1/methods";
import { DividendList } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { DIVIDENDS } from "../../cacheKeys";

export const useDividends = (
  params: DividendsParams,
  options?: UseQueryOptions<DividendList, AxiosApiError, DividendList>
) => {
  return useAuthQuery<DividendList, AxiosApiError, DividendList>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [DIVIDENDS, params],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ({ token }) => loadDividends(token, params),
    options
  );
};
