import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { orderBy } from "lodash";
import { BankWrapper } from "@api";
import { CreateOrUpdateAccountParams } from "@generated/apiv1";
import { AccountType, accountTypes } from "@features/accounts/constants";
import {
  CategoryType,
  useSubcategories,
  AccountSubcategoryEnum,
} from "@providers/SubcategoriesProvider";
import { BankSearchField } from "@components/common/forms/BankSearchField";
import { BankOption } from "@constants";

const bankWrapperToBankOption = (
  bank: BankWrapper | null
): BankOption | null => {
  if (!bank) return null;

  return {
    name: bank.name,
    bic: bank.bic,
    iconUrl: bank.iconUrl,
  };
};

interface FieldsHideOptions {
  type: boolean;
  name: boolean;
  bank: boolean;
}

export interface CreateAndEditAccountFormProps {
  accountValues: CreateOrUpdateAccountParams;
  defaultBank?: BankWrapper;
  hideFields?: FieldsHideOptions;
  onUpdateAccountValues: (params: Partial<CreateOrUpdateAccountParams>) => void;
  type?: string | null;
  bankSearchHeight?: number;
  defaultSubcategory?: AccountSubcategoryEnum;
}

export const CreateAndEditAccountForm = (
  props: CreateAndEditAccountFormProps
) => {
  const {
    defaultBank = null,
    accountValues,
    hideFields = { type: false, name: false, bank: false },
    onUpdateAccountValues,
    bankSearchHeight,
    type,
    defaultSubcategory = null,
  } = props;
  const { t } = useTranslation();
  const [selectedBank, setSelectedBank] = useState<BankOption | null>(
    bankWrapperToBankOption(defaultBank)
  );
  const { subcategories } = useSubcategories({
    categoryType: CategoryType.ACCOUNT,
  });

  let availableTypes = orderBy(
    subcategories
      ?.map((s) => {
        return accountTypes.find((t) => t.value === s.name);
      })
      .filter(Boolean) as AccountType[],
    (availableType) =>
      accountTypes.findIndex((t) => t.value === availableType.value)
  );

  const isCryptoAccount =
    subcategories?.find((s) => s.id === accountValues?.subCategoryId)?.name ===
    AccountSubcategoryEnum.CRYPTO;

  if (!availableTypes?.length) {
    availableTypes = [accountTypes[0], accountTypes[1], accountTypes[6]];
  }

  const defaultSelectValue = defaultSubcategory
    ? accountTypes.find((t) => t.value === defaultSubcategory)
    : type
    ? accountTypes.find((t) =>
        type ? t.value === type : t.accountType === accountValues.type
      )
    : null;

  useEffect(() => {
    defaultBank && setSelectedBank(defaultBank);
  }, [defaultBank]);

  useEffect(() => {
    let accountType = accountTypes.find((t) => t.value === type);
    if (!accountType) {
      accountType = defaultSelectValue || accountTypes[0];
    }
    const subCategory = subcategories?.find(
      (s) => s.name === accountType?.value
    );

    onUpdateAccountValues({
      type: accountType?.accountType,
      subCategoryId: !hideFields.type ? Number(subCategory?.id) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, subcategories]);

  const handleSelectChange = (value: SelectChangeEvent<string>) => {
    const type = accountTypes.find((t) => t.value === value.target.value);
    const enableImplicitTransactions = type?.accountType !== "02_cash";
    const subCategory = subcategories?.find((s) => s.name === type?.value);

    onUpdateAccountValues({
      type: type?.accountType,
      subCategoryId: Number(subCategory?.id),
      enableImplicitTransactions,
    });
  };

  const handleBankClick = (value: BankOption | null) => {
    setSelectedBank(value);

    onUpdateAccountValues({
      bankName: value ? value.name : undefined,
      bic: value ? value.bic : undefined,
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateAccountValues({ name: event.target.value });
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      {!hideFields.type && (
        <FormControl fullWidth>
          <InputLabel>{t("accounts.addManually.type")}</InputLabel>
          <Select
            id="type"
            data-testid="account-type"
            defaultValue={defaultSelectValue?.value ?? accountTypes[0].value}
            onChange={handleSelectChange}
            MenuProps={{
              disablePortal: true,
            }}
          >
            {availableTypes.map(({ value, icon }) => (
              <MenuItem key={value} value={value} id={value}>
                <Box display="flex" alignItems="center" gap={3}>
                  {icon}
                  {t(`depotsAccounts.subcategories.${value}`)}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!hideFields.name && (
        <TextField
          id="account-name"
          data-testid="account-name"
          fullWidth
          label={t("accounts.addManually.name")}
          placeholder={
            isCryptoAccount
              ? t("accounts.addManually.crypto_name_placeholder")
              : t("accounts.addManually.name_placeholder")
          }
          value={accountValues?.name ?? ""}
          onChange={handleNameChange}
          inputProps={{
            "data-testid": "account-name-input",
          }}
        />
      )}
      {!hideFields.bank && (
        <>
          <Divider />
          <BankSearchField
            defaultBank={selectedBank}
            label={
              isCryptoAccount
                ? t("accounts.addManually.crypto")
                : t("accounts.addManually.bank")
            }
            placeholder={
              isCryptoAccount
                ? t("accounts.addManually.crypto_placeholder")
                : t("accounts.addManually.bank_placeholder")
            }
            onBankClick={handleBankClick}
            listHeight={bankSearchHeight}
          />
        </>
      )}
    </Box>
  );
};
