import { UseQueryOptions } from "react-query";
import { HistoricQuote, HistoricQuoteParams } from "@api";
import { useHistoricQuotes } from "@api/v1/hooks/useHistoricQuotes";

export const useInvestmentHistoricQuotes = (
  params: Partial<HistoricQuoteParams>,
  options?: UseQueryOptions<HistoricQuote[], AxiosApiError, HistoricQuote[]>
) => {
  return useHistoricQuotes(
    {
      ...params,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      tickerSymbol: params.tickerSymbol!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      investmentId: params.investmentId!,
    },
    options
  );
};
