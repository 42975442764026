import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { toPercent } from "@helpers";
import { DonutChartData } from "./types";

const LegendIcon = styled(Box)(() => ({
  width: "20px",
  height: "20px",

  "& svg": {
    width: "100%",
    height: "100%",
  },
}));

export const TagContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMultiple",
})<{ isMultiple?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  display: ${({ isMultiple }) => (isMultiple ? "flex" : "inline-flex")};
  flex-direction: ${({ isMultiple }) => (isMultiple ? "column" : "row")};
  align-items: ${({ isMultiple }) => (isMultiple ? "flex-start" : "center")};
  gap: ${({ isMultiple }) => (isMultiple ? "2px" : "8px")};
  background: ${({ theme }) => theme.palette.background.tooltipChart};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  outline: none !important;
  color: ${({ theme }) => theme.palette.text.tooltipSecondary};
  border-radius: 4px;
`;

interface DonutChartTagProps {
  percent?: number;
  children?: DonutChartData[];
  icon?: ReactElement;
  name?: string;
}

export const DonutChartTag = (props: DonutChartTagProps) => {
  const { percent, children, icon, name } = props;

  if (children) {
    return (
      <TagContainer isMultiple>
        {children?.map(
          // @ts-ignore
          (child) => (
            <Box display="flex" alignItems="center" gap={2} key={child.id}>
              <LegendIcon>{child.icon}</LegendIcon>
              <Typography variant="body2">{child.name}</Typography>
              <Typography variant="body2">
                {toPercent(child.value, "auto", 2, 2)}
              </Typography>
            </Box>
          )
        )}
      </TagContainer>
    );
  }

  return (
    <TagContainer>
      <LegendIcon>{icon}</LegendIcon>
      {name && <Typography variant="body2">{name}</Typography>}
      <Typography variant="body2">
        {toPercent(percent, "auto", 2, 2)}
      </Typography>
    </TagContainer>
  );
};
