import { UseMutationOptions } from "react-query";
import {
  FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest,
  ClientConfiguration,
} from "@generated/apiv1";

import { buildFinApiClientConfigurationApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateFinApiClientConfiguration = async (
  token: string | undefined,
  params: FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest
) => {
  const apiInstance = buildFinApiClientConfigurationApi(token);
  const { data } = await apiInstance.updateFinApiClientConfiguration(params);
  return data;
};

export const useUpdateFinApiClientConfiguration = (
  options?: UseMutationOptions<
    ClientConfiguration,
    AxiosApiError,
    FinApiClientConfigurationApiUpdateFinApiClientConfigurationRequest
  >
) => {
  return useAuthMutation(updateFinApiClientConfiguration, options, false);
};
