import { Box, IconButton } from "@mui/material";
import React, { ReactNode } from "react";
import { ActionsMenu } from "@components";
import { ReactComponent as DotsIcon } from "@icons/dots.svg";

export const MenuButton = ({
  children,
  size = undefined,
  ...rest
}: {
  children: ReactNode;
  size?: "small";
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ml={1}
    >
      <IconButton
        data-testid="open-menu-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="grey"
        onClick={handleOpenMenu}
        size={size}
      >
        <DotsIcon />
      </IconButton>
      <ActionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {children}
      </ActionsMenu>
    </Box>
  );
};
