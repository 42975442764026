import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import {
  Account,
  CreateOrUpdateInvestmentParams,
  Industry,
  Region,
  Tag,
} from "@api";
import { IndustrySelector, RegionSelector, TagSelector } from "@components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 50%;
  }
`;

interface Props {
  investmentName: string;
  currentDepotId: number;
  currentIndustry?: Industry;
  currentRegion?: Region;
  tagValue?: string;
  depots: Account[];
  tags?: Tag[];
  isSaving: boolean;
  onSave: (data: CreateOrUpdateInvestmentParams) => void;
}

export const InvestmentEditBasicTabContainer = ({
  investmentName,
  currentDepotId,
  currentIndustry,
  currentRegion,
  tagValue,
  depots = [],
  tags = [],
  isSaving = false,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState(investmentName);
  const [depotId, setDepot] = useState(currentDepotId);
  const [industry, setIndustry] = useState(currentIndustry ?? null);
  const [region, setRegion] = useState(currentRegion ?? null);
  const [customGroup, setCustomGroup] = useState(tagValue ?? null);

  const handleSave = () => {
    const data = {
      name,
      accountId: depotId,
      industryId: industry?.id ?? null,
      regionId: region?.id ?? null,
      tag: customGroup ?? "",
    };

    onSave(data);
  };

  const hasDepots = Boolean(depots.length);
  const convertedTags: string[] = tags
    .filter(({ value }) => Boolean(value))
    .map(({ value }) => value as string);

  return (
    <Container>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={t("investmentEditPage.basicTab.name")}
        inputProps={{ "data-testid": `investment-name-input` }}
      />
      {hasDepots && (
        <FormControl>
          <InputLabel id="depot-select-label">
            {t("investmentEditPage.basicTab.depot")}
          </InputLabel>
          <Select
            labelId="depot-select-label"
            fullWidth
            value={depotId}
            onChange={(event) => setDepot(event.target.value as number)}
          >
            {depots.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <IndustrySelector
        defaultIndustry={currentIndustry}
        onChange={(value) => setIndustry(value)}
      />

      <RegionSelector
        defaultRegion={currentRegion}
        onChange={(value) => setRegion(value)}
      />

      <TagSelector
        defaultTag={tagValue}
        tags={convertedTags}
        onChange={(value) => setCustomGroup(value)}
      />

      <div>
        <Button
          data-testid="investment-edit-save-button"
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          {!isSaving && t("investmentEditPage.basicTab.saveButton")}
          {isSaving && <CircularProgress size={16} />}
        </Button>
      </div>
    </Container>
  );
};
