import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Theme,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useCashBalance } from "@features/cashflowAnalyzer/hooks/useCashBalance";
import { toCurrency } from "@helpers";
import { PageLayout, DonutChart } from "@components";
import { BarChart, BarChartMode } from "@components/Chart/BarChart";
import { DepotsSelector } from "@features/selectDepots/components/DepotsSelector";
import {
  useListAndSearchAllAccounts,
  useSelectGroupsId,
} from "@generated/apiv1/hooks";
import { SankeyChart } from "@components/Chart/CashflowCharts/SankeyChart";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import { SelectGroupParams } from "@features/selectDepots/types";
import { useCategoriesChartData } from "./hooks/useTransactionsChartData";
import { useCashflowChartData } from "./hooks/useCashflowChartData";
import { ExpensesBanner } from "./components/ExpensesBanner";
import { TransactionsTable } from "./components/TransactionsTable";
import { useIncomeExpensesStatistics } from "./hooks/useIncomeExpensesStatistics";
import { useCategorizedTransactions } from "./hooks/useCategorizedTransactions";
import { IncomeExpensesCard } from "./components/IncomeExpensesCard";

const accountParams: AccountsApiListAndSearchAllAccountsRequest = {
  type: ["02_cash"],
  bankConnectionType: ["FIN_API"],
};

const selectedGroupParams: SelectGroupParams = {
  id: "householdBook",
  fallbackAccountType: ["02_cash"],
  excludeManuallyMaintainedFallbackAccounts: true,
};

export const CashflowAnalyzerPage = () => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const donutChartRef = React.useRef<{
    resetActiveItem: () => void;
  }>(null);
  const [displayedChart, setDisplayedChart] = React.useState<
    "donut" | "sankey"
  >("sankey");
  const { data: accountList } = useListAndSearchAllAccounts(accountParams, {
    refetchOnWindowFocus: false,
  });
  const { data: selectedGroup, isLoading: isSelectedGroupLoading } =
    useSelectGroupsId(selectedGroupParams, {
      refetchOnWindowFocus: false,
    });
  const validAccounts = useMemo(
    () =>
      accountList?.accounts?.filter((x) => x.bankConnectionType === "FIN_API"),
    [accountList]
  );

  const validSelectedAccounts = useMemo(
    () =>
      selectedGroup?.accountIds?.filter((id) =>
        validAccounts?.some((x) => x.id === id)
      ),
    [validAccounts, selectedGroup]
  );

  const [mainPeriod, setMainPeriod] = React.useState<Required<RangeType>>({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  });
  const [subPeriod, setSubPeriod] = React.useState<Required<RangeType>>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [mode, setMode] = React.useState<BarChartMode>("monthly");
  const [selectedCategory, setSelectedCategory] = React.useState<
    string[] | null
  >(null);
  const {
    chartData: barChartData,
    previousYearChartData: previousYearBarChartData,
    nextYearChartData: nextYearBarChartData,
    isLoading: isCashflowLoading,
  } = useCashflowChartData(
    validSelectedAccounts || [],
    mainPeriod.startDate,
    mainPeriod.endDate
  );
  const { income, expenses } = useIncomeExpensesStatistics(
    validSelectedAccounts || []
  );

  const { transactions, categorizedTransactions, categories, isLoading } =
    useCategorizedTransactions(
      validSelectedAccounts || [],
      subPeriod.startDate,
      subPeriod.endDate
    );

  const chartData = useCategoriesChartData(categorizedTransactions);
  const accountsBalance =
    validAccounts?.reduce(
      (acc, account) =>
        validSelectedAccounts?.includes(account.id)
          ? acc + (account.balance || 0)
          : acc,
      0
    ) || 0;

  const cashBalances = useCashBalance(validSelectedAccounts || []);
  const areCardsLoading =
    isSelectedGroupLoading || cashBalances?.isLoading || isCashflowLoading;

  const resetActiveCategory = () => {
    setSelectedCategory(null);
    donutChartRef.current?.resetActiveItem();
  };

  return (
    <PageLayout
      title={
        <Box>
          <Typography variant="h4">{t("cashflowAnalyzer.title")}</Typography>
          <DepotsSelector
            accountParams={accountParams}
            selectedGroupParams={selectedGroupParams}
            variant="body2"
            color="text.secondary"
            textKeys={{
              modalTitle: "cashflowAnalyzer.depotsSelectorTitle",
              allDepots: "depotsSelector.allAccounts",
              amountDepots: "depotsSelector.amountAccounts",
              noDepots: "depotsSelector.noAccounts",
            }}
          />
        </Box>
      }
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { text: t("cashflowAnalyzer.title") },
      ]}
      isMainPage
    >
      <Box maxWidth={1200}>
        <Box mb={5}>
          <Typography
            variant="body2"
            fontWeight={400}
            color="textSecondary"
            mb={1}
          >
            {t("cashflowAnalyzer.accountsBalance")}
          </Typography>
          <Typography variant="h3" component="p">
            {toCurrency(accountsBalance, "EUR")}
          </Typography>
        </Box>
        <Grid container spacing={3} mb={7}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <IncomeExpensesCard
              isLoading={areCardsLoading}
              type="income"
              actual={income.thisMonth}
              average={income.curatedMean}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <IncomeExpensesCard
              type="expenses"
              isLoading={areCardsLoading}
              actual={expenses.thisMonth}
              average={expenses.curatedMean}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <IncomeExpensesCard
              type="balance"
              isLoading={areCardsLoading}
              actual={cashBalances?.balance || 0}
            />
          </Grid>
        </Grid>
        <BarChart
          data={barChartData}
          previousYearData={previousYearBarChartData}
          nextYearData={nextYearBarChartData}
          onMainPeriodChange={setMainPeriod}
          mainPeriod={mainPeriod}
          onSubPeriodChange={setSubPeriod}
          isLoading={isCashflowLoading}
          mode={mode}
          onModeChange={setMode}
        />
        <ExpensesBanner expenses={expenses} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
          mb={6}
        >
          <Typography variant="subtitle1">
            {t("cashflowAnalyzer.chartTitle")}
          </Typography>
          <ToggleButtonGroup value={displayedChart} color="secondary">
            <ToggleButton
              value="sankey"
              onClick={() => setDisplayedChart("sankey")}
            >
              {t("cashflowAnalyzer.chartSwitcher.flow")}
            </ToggleButton>
            <ToggleButton
              value="donut"
              onClick={() => setDisplayedChart("donut")}
            >
              {t("cashflowAnalyzer.chartSwitcher.distribution")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box
          mb={isLessSm ? 5 : 11}
          sx={{
            display: displayedChart === "donut" ? "block" : "none",
          }}
        >
          <DonutChart
            ref={donutChartRef}
            data={chartData}
            onLegendClick={(id: string | null) =>
              setSelectedCategory(id ? [id] : null)
            }
            onSectionClick={(id: string | string[]) =>
              setSelectedCategory(Array.isArray(id) ? id : [id])
            }
            displayLegend
            displayTotal
          />
        </Box>

        <Box
          mb={isLessSm ? 5 : 11}
          sx={{
            display: displayedChart === "sankey" ? "block" : "none",
          }}
        >
          <SankeyChart
            chartData={chartData}
            uniqueKey={`${format(subPeriod.startDate, "MM-dd")}${format(
              subPeriod.endDate,
              "MM-dd"
            )}`}
            onLegendClick={(id: string | null) =>
              setSelectedCategory(id ? [id] : null)
            }
            isLoading={isLoading}
          />
        </Box>

        <TransactionsTable
          transactions={transactions}
          categoryTree={categories}
          selectedCategory={selectedCategory}
          resetCategorySelection={resetActiveCategory}
          subPeriod={subPeriod}
          mode={mode}
          accounts={validAccounts || []}
        />
      </Box>
    </PageLayout>
  );
};
