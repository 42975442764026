import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setSelectedDividendsWithTaxRateAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "selectedDividendsWithTaxRate"> => {
  if (event.type !== "TAX_RATE_LOADED") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    selectedDividendsWithTaxRate: event.selectedDividendsWithTaxRate,
  };
};
