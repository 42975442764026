import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  PageableTransactionList,
  CreateOrUpdateTransactionParams,
  Transaction,
} from "../model";

export interface ListAndSearchAllTransactionParams {
  /** Retrieve Transactions with the given IDs */
  id?: number[];
  /** Retrieve Transactions for the given Accounts */
  accountId?: number[];
  /** Retrieve Transactions related to investments residing inside the given depots.                              Note that this parameter will be ignored if you also provide investmentIds. */
  depotId?: number[];
  /** Retrieve Transactions for the given insurance; setting this parameter will ignore the                              'accountId' or 'depotId' parameter. */
  insuranceId?: number[];
  /** Retrieve Transactions for the given investments */
  investmentId?: number[];
  /** Retrieve Transactions with the given finAPI IDs */
  finApiId?: number[];
  /** Retrieve transactions having the given type, allowed values are withdrawal, deposit,                              interest, tax_refund, other_gains. */
  type?: string[];
  /** Retrieve Transactions belonging to Investments with the given WKNs */
  wkn?: string[];
  /** Retrieve Transactions belonging to Investments with the given ISINs */
  isin?: string[];
  /** Retrieve Transactions belonging to Investments with the given ticker symbols */
  tickerSymbol?: string[];
  /** Retrieve Transactions in the given currencies (3-letter ISO code) */
  currency?: string[];
  /** Retrieve Transactions with amount greater or equal than the given minAmount */
  minAmount?: number;
  /** Retrieve Transactions with amount less or equal than the given maxAmount */
  maxAmount?: number;
  /** Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format) */
  minDate?: string;
  /** Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format) */
  maxDate?: string;
  /** Include SEPA transaction data stored on the finApi cloud. Note that this will slow                         down the request somewhat, as remote data must be polled in. */
  includeRemoteSepaFields?: boolean;
  /** Skip transactions that were manually created (i.e. not imported from a bank). */
  skipManuallyCreatedTransactions?: boolean;
  /** Include adjusting entries. Adjusting entries are cash transactions created whenever the                              account balance will fall below zero. This is a convenience feature for manually                              maintained depots. */
  includeAdjustingEntries?: boolean;
  /** Result page that you want to retrieve. */
  page?: number;
  /** Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes. */
  perPage?: number;
  /** Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is 'id asc'. */
  order?: string;
  /** Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON. */
  format?: "csv" | "json";
}

export const listAndSearchAllTransaction = async (
  token: string | undefined,
  params: ListAndSearchAllTransactionParams
) => {
  const { data } = await axiosInstanceV1.get<PageableTransactionList>(
    `/transactions`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );
  return data;
};

export const downloadTransactions = async (
  token: string | undefined,
  params: ListAndSearchAllTransactionParams
) => {
  const { data } = await axiosInstanceV1.get<File>(`/transactions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
    params,
  });
  return data;
};

export const createCashTransaction = async (
  token: string | undefined,
  params: CreateOrUpdateTransactionParams
) => {
  const { data } = await axiosInstanceV1.post<Transaction>(
    `/transactions`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
