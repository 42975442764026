/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CsvImportPerformAttributes } from '../model';
// @ts-ignore
import { CsvImportPerformResponse } from '../model';
// @ts-ignore
import { CsvParseResult } from '../model';
/**
 * CSVApi - axios parameter creator
 * @export
 */
export const CSVApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import a parsed CSV
         * @param {CsvImportPerformAttributes} [csvImportPerformAttributes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv: async (csvImportPerformAttributes?: CsvImportPerformAttributes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/csv/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvImportPerformAttributes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary parse a CSV
         * @param {any} [file] 
         * @param {ParseCsvBankSlugEnum} [bankSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCsv: async (file?: any, bankSlug?: ParseCsvBankSlugEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/csv/parse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (bankSlug !== undefined) { 
                localVarFormParams.append('bank_slug', bankSlug as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CSVApi - functional programming interface
 * @export
 */
export const CSVApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CSVApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary import a parsed CSV
         * @param {CsvImportPerformAttributes} [csvImportPerformAttributes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCsv(csvImportPerformAttributes?: CsvImportPerformAttributes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportPerformResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCsv(csvImportPerformAttributes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVApi.importCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary parse a CSV
         * @param {any} [file] 
         * @param {ParseCsvBankSlugEnum} [bankSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseCsv(file?: any, bankSlug?: ParseCsvBankSlugEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvParseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseCsv(file, bankSlug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CSVApi.parseCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CSVApi - factory interface
 * @export
 */
export const CSVApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CSVApiFp(configuration)
    return {
        /**
         * 
         * @summary import a parsed CSV
         * @param {CSVApiImportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCsv(requestParameters: CSVApiImportCsvRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CsvImportPerformResponse> {
            return localVarFp.importCsv(requestParameters.csvImportPerformAttributes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary parse a CSV
         * @param {CSVApiParseCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCsv(requestParameters: CSVApiParseCsvRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CsvParseResult> {
            return localVarFp.parseCsv(requestParameters.file, requestParameters.bankSlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for importCsv operation in CSVApi.
 * @export
 * @interface CSVApiImportCsvRequest
 */
export interface CSVApiImportCsvRequest {
    /**
     * 
     * @type {CsvImportPerformAttributes}
     * @memberof CSVApiImportCsv
     */
    readonly csvImportPerformAttributes?: CsvImportPerformAttributes
}

/**
 * Request parameters for parseCsv operation in CSVApi.
 * @export
 * @interface CSVApiParseCsvRequest
 */
export interface CSVApiParseCsvRequest {
    /**
     * 
     * @type {any}
     * @memberof CSVApiParseCsv
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof CSVApiParseCsv
     */
    readonly bankSlug?: ParseCsvBankSlugEnum
}

/**
 * CSVApi - object-oriented interface
 * @export
 * @class CSVApi
 * @extends {BaseAPI}
 */
export class CSVApi extends BaseAPI {
    /**
     * 
     * @summary import a parsed CSV
     * @param {CSVApiImportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVApi
     */
    public importCsv(requestParameters: CSVApiImportCsvRequest = {}, options?: RawAxiosRequestConfig) {
        return CSVApiFp(this.configuration).importCsv(requestParameters.csvImportPerformAttributes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary parse a CSV
     * @param {CSVApiParseCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CSVApi
     */
    public parseCsv(requestParameters: CSVApiParseCsvRequest = {}, options?: RawAxiosRequestConfig) {
        return CSVApiFp(this.configuration).parseCsv(requestParameters.file, requestParameters.bankSlug, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ParseCsvBankSlugEnum = {
    PARQET: 'Parqet',
    SCALABLE_CAPITAL: 'ScalableCapital',
    EXTRA_ETF: 'extra_etf',
    GENERIC: 'generic'
} as const;
export type ParseCsvBankSlugEnum = typeof ParseCsvBankSlugEnum[keyof typeof ParseCsvBankSlugEnum];
