/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateDiscountPositionParams } from '../model';
// @ts-ignore
import { DiscountPosition } from '../model';
// @ts-ignore
import { DiscountPositionList } from '../model';
/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a discount position to the given user.
         * @param {CreateDiscountPositionParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscountPosition: async (body: CreateDiscountPositionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDiscountPosition', 'body', body)
            const localVarPath = `/v1/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get discount positions for the currently logged in user or (if in role ROLE_ACCOUNTING or                      ROLE_SUBSCRIPTION_MANAGEMENT) for the given user.
         * @param {number} [userId] Retrieve discount position for the given user; requires administrative privileges.                             Retrieve positions for the currently logged in user if not specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDiscounts: async (userId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the currently logged in users recommendation code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendationCode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/discounts/recommendationCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a discount position to the given user.
         * @param {CreateDiscountPositionParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDiscountPosition(body: CreateDiscountPositionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDiscountPosition(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.createDiscountPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get discount positions for the currently logged in user or (if in role ROLE_ACCOUNTING or                      ROLE_SUBSCRIPTION_MANAGEMENT) for the given user.
         * @param {number} [userId] Retrieve discount position for the given user; requires administrative privileges.                             Retrieve positions for the currently logged in user if not specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllDiscounts(userId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountPositionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllDiscounts(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.listAndSearchAllDiscounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the currently logged in users recommendation code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recommendationCode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recommendationCode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.recommendationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a discount position to the given user.
         * @param {DiscountsApiCreateDiscountPositionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscountPosition(requestParameters: DiscountsApiCreateDiscountPositionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DiscountPosition> {
            return localVarFp.createDiscountPosition(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get discount positions for the currently logged in user or (if in role ROLE_ACCOUNTING or                      ROLE_SUBSCRIPTION_MANAGEMENT) for the given user.
         * @param {DiscountsApiListAndSearchAllDiscountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDiscounts(requestParameters: DiscountsApiListAndSearchAllDiscountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DiscountPositionList> {
            return localVarFp.listAndSearchAllDiscounts(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the currently logged in users recommendation code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendationCode(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.recommendationCode(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDiscountPosition operation in DiscountsApi.
 * @export
 * @interface DiscountsApiCreateDiscountPositionRequest
 */
export interface DiscountsApiCreateDiscountPositionRequest {
    /**
     * The create or update parameters
     * @type {CreateDiscountPositionParams}
     * @memberof DiscountsApiCreateDiscountPosition
     */
    readonly body: CreateDiscountPositionParams
}

/**
 * Request parameters for listAndSearchAllDiscounts operation in DiscountsApi.
 * @export
 * @interface DiscountsApiListAndSearchAllDiscountsRequest
 */
export interface DiscountsApiListAndSearchAllDiscountsRequest {
    /**
     * Retrieve discount position for the given user; requires administrative privileges.                             Retrieve positions for the currently logged in user if not specified.
     * @type {number}
     * @memberof DiscountsApiListAndSearchAllDiscounts
     */
    readonly userId?: number
}

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI {
    /**
     * 
     * @summary Add a discount position to the given user.
     * @param {DiscountsApiCreateDiscountPositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public createDiscountPosition(requestParameters: DiscountsApiCreateDiscountPositionRequest, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).createDiscountPosition(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get discount positions for the currently logged in user or (if in role ROLE_ACCOUNTING or                      ROLE_SUBSCRIPTION_MANAGEMENT) for the given user.
     * @param {DiscountsApiListAndSearchAllDiscountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public listAndSearchAllDiscounts(requestParameters: DiscountsApiListAndSearchAllDiscountsRequest = {}, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).listAndSearchAllDiscounts(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the currently logged in users recommendation code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public recommendationCode(options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).recommendationCode(options).then((request) => request(this.axios, this.basePath));
    }
}

