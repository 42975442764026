import { useAuthQuery, loadMarketCap } from "@api";

export const useMarketCap = (
  isin?: string,
  quoteProvider?: string,
  tickerSymbol?: string,
  currency?: string
) =>
  useAuthQuery(
    ["market-cap", isin!, quoteProvider!, tickerSymbol!, currency!],
    ({ token }) =>
      loadMarketCap(token, isin!, quoteProvider!, tickerSymbol!, currency!),
    {
      enabled: !!isin && !!quoteProvider && !!tickerSymbol && !!currency,
      refetchOnWindowFocus: false,
    }
  );
