import { withToken } from "@api/auth/withToken";
import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  GainersAndLosers,
  PageableInvestmentList,
  Investment,
  CreateOrUpdateInvestmentParams,
} from "../model";

export const getInvestment = async (
  token: string | undefined,
  investmentId: number
) => {
  const response = await axiosInstanceV1.get<Investment>(
    `/investments/${investmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export interface GetInvestmentParams {
  id?: number[];
  accountId?: number[];
  wkn?: string[];
  isin?: string[];
  isinOrWkn?: string[];
  tickerSymbol?: string[];
  name?: string;
  currency?: string[];
  industryId?: number[];
  regionId?: number[];
  includeHistoric?: boolean;
  positiveAmountOrLastTransactionAfter?: string;
  page?: number;
  perPage?: number;
  order?: string;
  format?: "csv" | "json";
}

export const getInvestments = async (
  token: string | undefined,
  params: GetInvestmentParams
) => {
  const response = await axiosInstanceV1.get<PageableInvestmentList>(
    `/investments`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    }
  );

  return response.data;
};

export const downloadInvestments = async (
  token: string | undefined,
  params: GetInvestmentParams
) => {
  const { data } = await axiosInstanceV1.get<File>(`/investments`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
    params,
  });
  return data;
};

export const updateInvestment = async (
  token: string | undefined | undefined,
  params: CreateOrUpdateInvestmentParams & { id: number }
) => {
  const response = await axiosInstanceV1.put<Investment>(
    `/investments/${params.id}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const deleteInvestment = async (
  token: string | undefined,
  investmentId: number
) => {
  const response = await axiosInstanceV1.delete(
    `/investments/${investmentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const transferBatchInvestments = async (
  token: string | undefined,
  attrs: {
    transferToAccountId: number;
    dateOfTransfer: string;
    investmentBatchTransferParamsList: {
      numberOfLots: number;
      id: number;
    }[];
  }
) => {
  const { data } = await axiosInstanceV1.put(`/investments/transfer`, attrs, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getInvestmentsGainersAndLosers = async (
  token: string | undefined,
  params: GainersAndLosers
) => {
  const { data } = await axiosInstanceV1.get<PageableInvestmentList>(
    `/investments/gainersAndLosers`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );

  return data;
};

export const getInvestmentWithToken = withToken(getInvestment);
