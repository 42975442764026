import { UseQueryOptions } from "react-query";
import {
  NetfondsCustomersApiNetfondsCustomersRequest,
  StringList,
} from "@generated/apiv1";

import { buildNetfondsCustomersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const netfondsCustomers = async (
  token: string | undefined,
  params?: NetfondsCustomersApiNetfondsCustomersRequest
) => {
  const apiInstance = buildNetfondsCustomersApi(token);
  const { data } = await apiInstance.netfondsCustomers(params);
  return data;
};

export const useNetfondsCustomers = (
  params?: NetfondsCustomersApiNetfondsCustomersRequest,
  options?: UseQueryOptions<StringList, AxiosApiError, StringList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.NETFONDSCUSTOMERS_NETFONDS_CUSTOMERS, params],
    ({ token }) => netfondsCustomers(token, params),
    options
  );
};
