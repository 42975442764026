import { Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import UndoIcon from "@mui/icons-material/Undo";
import { useTranslation } from "react-i18next";
import { LogItemLine } from "./LogItemLine";
import { ReactComponent as DepotIconSvg } from "./depot-icon.svg";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(1)};
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const RollbackLink = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
  text-decoration: underline;
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface AccountHeaderWithRollbackProps {
  onRollback: () => void;
  name: string;
  iban?: string;
  showRollbackLink?: boolean;
  rollbackErrorMessage?: string;
}

export const AccountHeaderWithRollback = ({
  onRollback,
  name,
  iban,
  showRollbackLink = true,
  rollbackErrorMessage,
}: AccountHeaderWithRollbackProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {showRollbackLink && (
        <RollbackLink
          variant="body1"
          onClick={() => onRollback()}
          data-testid="rollback-synchronization-log-button"
        >
          <UndoIcon />
          {`${t("synchronizationLog.rollback")}`}
        </RollbackLink>
      )}

      {rollbackErrorMessage && (
        <Typography color="textPrimary">{rollbackErrorMessage}</Typography>
      )}

      <LogItemLine />

      <Heading>
        <DepotIconSvg />
        <div>
          <Typography color="textSecondary">{name}:</Typography>
          {iban && (
            <Typography>
              <strong>{iban}</strong>
            </Typography>
          )}
        </div>
      </Heading>
    </Container>
  );
};
