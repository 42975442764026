import { UseMutationOptions } from "react-query";
import { PlugAndPlayApiRemoveProductFromWatchlistRequest } from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const removeProductFromWatchlist = async (
  token: string | undefined,
  params: PlugAndPlayApiRemoveProductFromWatchlistRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.removeProductFromWatchlist(params);
  return data;
};

export const useRemoveProductFromWatchlist = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    PlugAndPlayApiRemoveProductFromWatchlistRequest
  >
) => {
  return useAuthMutation(removeProductFromWatchlist, options, false);
};
