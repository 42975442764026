import { UseMutationOptions } from "react-query";
import {
  DividendMasterDataRecordsApiCreateDividendRequest,
  DividendMasterDataRecord,
} from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createDividend = async (
  token: string | undefined,
  params: DividendMasterDataRecordsApiCreateDividendRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.createDividend(params);
  return data;
};

export const useCreateDividend = (
  options?: UseMutationOptions<
    DividendMasterDataRecord,
    AxiosApiError,
    DividendMasterDataRecordsApiCreateDividendRequest
  >
) => {
  return useAuthMutation(createDividend, options, false);
};
