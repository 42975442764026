import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const SwitcherContainer = styled.div`
  display: flex;

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

type Props = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: ReactNode;
  switchName: string;
  size?: "small" | "medium";
} & Omit<FormControlLabelProps, "control" | "onChange">;

export const Switcher = ({
  checked,
  label,
  switchName,
  onChange,
  size = "small",
  ...rest
}: Props) => {
  const { t } = useTranslation();
  return (
    <SwitcherContainer>
      <FormControlLabel
        control={<Switch size={size} checked={checked} name={switchName} />}
        label={
          typeof label === "string" ? (
            <Typography variant="body2" color="textSecondary">
              {t(label)}
            </Typography>
          ) : (
            label
          )
        }
        // @ts-ignore
        onChange={onChange}
        {...rest}
      />
    </SwitcherContainer>
  );
};
