/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { HistoricQuotesBatchResponse } from '../model';
// @ts-ignore
import { HistoricQuotesResponse } from '../model';
// @ts-ignore
import { LatestByIsin } from '../model';
// @ts-ignore
import { QuoteResponse } from '../model';
// @ts-ignore
import { QuotesResponse } from '../model';
// @ts-ignore
import { SecuritiesResponse } from '../model';
// @ts-ignore
import { SecurityResponse } from '../model';
/**
 * QuotesApi - axios parameter creator
 * @export
 */
export const QuotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary perform region and industry classification by ISIN or WKN
         * @param {string} [filterWkn] Comma separated values. Can not be combined with filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated values. Can not be combined with filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifySecurities: async (filterWkn?: string, filterIsin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/securities/classify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterWkn !== undefined) {
                localVarQueryParameter['filter[wkn]'] = filterWkn;
            }

            if (filterIsin !== undefined) {
                localVarQueryParameter['filter[isin]'] = filterIsin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {string} filterQuoteId 
         * @param {string} quoteProvider 
         * @param {string} [filterFrom] Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
         * @param {string} [filterTo] Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
         * @param {string} [toCurrency] Format: 3-letter ISO code such as USD
         * @param {number} [average] if exist, converts the response to the running average over the given amount of days 
         * @param {boolean} [padDates] Pad dates if true; default: false. Padding will fill gaps on weekends / public holidays.
         * @param {GetHistoricQuotesInPercentFromEnum} [inPercentFrom] Convert quotes to percent, using&lt;br&gt; \\                 first: the first quote as reference&lt;br&gt; \\                 previous: the previous quote as reference&lt;br&gt; \\                 current_to_now: the current quote as reference
         * @param {GetHistoricQuotesFilterIntervalEnum} [filterInterval] Restrict quote retrieval to the given interval. Auto will switch between daily and \\                 intraday quotes according to the selected interval. Default: auto.
         * @param {GetHistoricQuotesFilterIntervalPositionEnum} [filterIntervalPosition] Defines which value within the specified interval is used. Default: current.&lt;br&gt; \\                 current: current day of week / month / year;&lt;br&gt; \\                 first: first day of week / month / year;&lt;br&gt; \\                 last: last day of week / month / year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricQuotes: async (filterQuoteId: string, quoteProvider: string, filterFrom?: string, filterTo?: string, toCurrency?: string, average?: number, padDates?: boolean, inPercentFrom?: GetHistoricQuotesInPercentFromEnum, filterInterval?: GetHistoricQuotesFilterIntervalEnum, filterIntervalPosition?: GetHistoricQuotesFilterIntervalPositionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterQuoteId' is not null or undefined
            assertParamExists('getHistoricQuotes', 'filterQuoteId', filterQuoteId)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getHistoricQuotes', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/historic_quotes`
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterQuoteId !== undefined) {
                localVarQueryParameter['filter[quote_id]'] = filterQuoteId;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }

            if (toCurrency !== undefined) {
                localVarQueryParameter['to_currency'] = toCurrency;
            }

            if (average !== undefined) {
                localVarQueryParameter['average'] = average;
            }

            if (padDates !== undefined) {
                localVarQueryParameter['pad_dates'] = padDates;
            }

            if (inPercentFrom !== undefined) {
                localVarQueryParameter['in_percent_from'] = inPercentFrom;
            }

            if (filterInterval !== undefined) {
                localVarQueryParameter['filter[interval]'] = filterInterval;
            }

            if (filterIntervalPosition !== undefined) {
                localVarQueryParameter['filter[interval_position]'] = filterIntervalPosition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {string} filterQuoteIds 
         * @param {string} quoteProvider 
         * @param {string} [filterFrom] Format: 2017-07-21
         * @param {string} [filterTo] Format: 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricQuotesInBatch: async (filterQuoteIds: string, quoteProvider: string, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterQuoteIds' is not null or undefined
            assertParamExists('getHistoricQuotesInBatch', 'filterQuoteIds', filterQuoteIds)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getHistoricQuotesInBatch', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/historic_quotes/batch`
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterQuoteIds !== undefined) {
                localVarQueryParameter['filter[quote_ids]'] = filterQuoteIds;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns latest price for given isin
         * @param {string} isin Comma separated ISINs. Example: \&#39;isin&#x3D;US2338252073,DE0007100000\&#39;
         * @param {string} quoteProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestByIsin: async (isin: string, quoteProvider: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('getLatestByIsin', 'isin', isin)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getLatestByIsin', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/quotes/latest_by_isin`
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns quotes
         * @param {string} id The quote ID; id&#x3D;42
         * @param {string} quoteProvider 
         * @param {GetQuoteIncludeEnum} [include] Include the given entities in the request; input as comma separated list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote: async (id: string, quoteProvider: string, include?: GetQuoteIncludeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuote', 'id', id)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getQuote', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/quotes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns quotes
         * @param {string} quoteProvider 
         * @param {string} [filterId] Comma separated quote IDs. filter[id]&#x3D;42,43
         * @param {string} [filterSecurityId] Comma separated security IDs. filter[security_id]&#x3D;42,43
         * @param {string} [filterWkn] Comma separated WKNs. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated ISINs. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {boolean} [filterOptimal] Returns the optimal quote for each of the given filters. Default: false. Example: \&#39;filter[optimal]&#x3D;true\&#39;
         * @param {string} [filterPreferredCurrency] Prefer quotes in this currency when sorting or determining the optimal quote. Example: \&#39;filter[preferred_currency]&#x3D;USD\&#39;
         * @param {string} [mandator] Server-to-server only. Apply the given mandators permission if searching quotes. Example: \&#39;mandator&#x3D;somePublicId\&#39;
         * @param {GetQuotesIncludeEnum} [include] Include the given entities in the request; input as comma separated list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes: async (quoteProvider: string, filterId?: string, filterSecurityId?: string, filterWkn?: string, filterIsin?: string, filterOptimal?: boolean, filterPreferredCurrency?: string, mandator?: string, include?: GetQuotesIncludeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getQuotes', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/quotes`
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }

            if (filterSecurityId !== undefined) {
                localVarQueryParameter['filter[security_id]'] = filterSecurityId;
            }

            if (filterWkn !== undefined) {
                localVarQueryParameter['filter[wkn]'] = filterWkn;
            }

            if (filterIsin !== undefined) {
                localVarQueryParameter['filter[isin]'] = filterIsin;
            }

            if (filterOptimal !== undefined) {
                localVarQueryParameter['filter[optimal]'] = filterOptimal;
            }

            if (filterPreferredCurrency !== undefined) {
                localVarQueryParameter['filter[preferred_currency]'] = filterPreferredCurrency;
            }

            if (mandator !== undefined) {
                localVarQueryParameter['mandator'] = mandator;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary find a security by string
         * @param {string} [filterSearch] 
         * @param {string} [filterWkn] Comma separated values. Can not be combined with filter[search] or filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated values. Can not be combined with filter[search] or filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurities: async (filterSearch?: string, filterWkn?: string, filterIsin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/securities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter[search]'] = filterSearch;
            }

            if (filterWkn !== undefined) {
                localVarQueryParameter['filter[wkn]'] = filterWkn;
            }

            if (filterIsin !== undefined) {
                localVarQueryParameter['filter[isin]'] = filterIsin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve a security by ID
         * @param {string} id 
         * @param {string} quoteProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurity: async (id: string, quoteProvider: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSecurity', 'id', id)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('getSecurity', 'quoteProvider', quoteProvider)
            const localVarPath = `/api/v3/{quote_provider}/securities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"quote_provider"}}`, encodeURIComponent(String(quoteProvider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuotesApi - functional programming interface
 * @export
 */
export const QuotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary perform region and industry classification by ISIN or WKN
         * @param {string} [filterWkn] Comma separated values. Can not be combined with filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated values. Can not be combined with filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifySecurities(filterWkn?: string, filterIsin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecuritiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifySecurities(filterWkn, filterIsin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.classifySecurities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {string} filterQuoteId 
         * @param {string} quoteProvider 
         * @param {string} [filterFrom] Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
         * @param {string} [filterTo] Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
         * @param {string} [toCurrency] Format: 3-letter ISO code such as USD
         * @param {number} [average] if exist, converts the response to the running average over the given amount of days 
         * @param {boolean} [padDates] Pad dates if true; default: false. Padding will fill gaps on weekends / public holidays.
         * @param {GetHistoricQuotesInPercentFromEnum} [inPercentFrom] Convert quotes to percent, using&lt;br&gt; \\                 first: the first quote as reference&lt;br&gt; \\                 previous: the previous quote as reference&lt;br&gt; \\                 current_to_now: the current quote as reference
         * @param {GetHistoricQuotesFilterIntervalEnum} [filterInterval] Restrict quote retrieval to the given interval. Auto will switch between daily and \\                 intraday quotes according to the selected interval. Default: auto.
         * @param {GetHistoricQuotesFilterIntervalPositionEnum} [filterIntervalPosition] Defines which value within the specified interval is used. Default: current.&lt;br&gt; \\                 current: current day of week / month / year;&lt;br&gt; \\                 first: first day of week / month / year;&lt;br&gt; \\                 last: last day of week / month / year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricQuotes(filterQuoteId: string, quoteProvider: string, filterFrom?: string, filterTo?: string, toCurrency?: string, average?: number, padDates?: boolean, inPercentFrom?: GetHistoricQuotesInPercentFromEnum, filterInterval?: GetHistoricQuotesFilterIntervalEnum, filterIntervalPosition?: GetHistoricQuotesFilterIntervalPositionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricQuotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricQuotes(filterQuoteId, quoteProvider, filterFrom, filterTo, toCurrency, average, padDates, inPercentFrom, filterInterval, filterIntervalPosition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getHistoricQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {string} filterQuoteIds 
         * @param {string} quoteProvider 
         * @param {string} [filterFrom] Format: 2017-07-21
         * @param {string} [filterTo] Format: 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricQuotesInBatch(filterQuoteIds: string, quoteProvider: string, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricQuotesBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricQuotesInBatch(filterQuoteIds, quoteProvider, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getHistoricQuotesInBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns latest price for given isin
         * @param {string} isin Comma separated ISINs. Example: \&#39;isin&#x3D;US2338252073,DE0007100000\&#39;
         * @param {string} quoteProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestByIsin(isin: string, quoteProvider: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestByIsin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestByIsin(isin, quoteProvider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getLatestByIsin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns quotes
         * @param {string} id The quote ID; id&#x3D;42
         * @param {string} quoteProvider 
         * @param {GetQuoteIncludeEnum} [include] Include the given entities in the request; input as comma separated list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuote(id: string, quoteProvider: string, include?: GetQuoteIncludeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuote(id, quoteProvider, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns quotes
         * @param {string} quoteProvider 
         * @param {string} [filterId] Comma separated quote IDs. filter[id]&#x3D;42,43
         * @param {string} [filterSecurityId] Comma separated security IDs. filter[security_id]&#x3D;42,43
         * @param {string} [filterWkn] Comma separated WKNs. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated ISINs. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {boolean} [filterOptimal] Returns the optimal quote for each of the given filters. Default: false. Example: \&#39;filter[optimal]&#x3D;true\&#39;
         * @param {string} [filterPreferredCurrency] Prefer quotes in this currency when sorting or determining the optimal quote. Example: \&#39;filter[preferred_currency]&#x3D;USD\&#39;
         * @param {string} [mandator] Server-to-server only. Apply the given mandators permission if searching quotes. Example: \&#39;mandator&#x3D;somePublicId\&#39;
         * @param {GetQuotesIncludeEnum} [include] Include the given entities in the request; input as comma separated list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuotes(quoteProvider: string, filterId?: string, filterSecurityId?: string, filterWkn?: string, filterIsin?: string, filterOptimal?: boolean, filterPreferredCurrency?: string, mandator?: string, include?: GetQuotesIncludeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuotes(quoteProvider, filterId, filterSecurityId, filterWkn, filterIsin, filterOptimal, filterPreferredCurrency, mandator, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getQuotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary find a security by string
         * @param {string} [filterSearch] 
         * @param {string} [filterWkn] Comma separated values. Can not be combined with filter[search] or filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
         * @param {string} [filterIsin] Comma separated values. Can not be combined with filter[search] or filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecurities(filterSearch?: string, filterWkn?: string, filterIsin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecuritiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecurities(filterSearch, filterWkn, filterIsin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getSecurities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary retrieve a security by ID
         * @param {string} id 
         * @param {string} quoteProvider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecurity(id: string, quoteProvider: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecurity(id, quoteProvider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuotesApi.getSecurity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuotesApi - factory interface
 * @export
 */
export const QuotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuotesApiFp(configuration)
    return {
        /**
         * 
         * @summary perform region and industry classification by ISIN or WKN
         * @param {QuotesApiClassifySecuritiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifySecurities(requestParameters: QuotesApiClassifySecuritiesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SecuritiesResponse> {
            return localVarFp.classifySecurities(requestParameters.filterWkn, requestParameters.filterIsin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {QuotesApiGetHistoricQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricQuotes(requestParameters: QuotesApiGetHistoricQuotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricQuotesResponse> {
            return localVarFp.getHistoricQuotes(requestParameters.filterQuoteId, requestParameters.quoteProvider, requestParameters.filterFrom, requestParameters.filterTo, requestParameters.toCurrency, requestParameters.average, requestParameters.padDates, requestParameters.inPercentFrom, requestParameters.filterInterval, requestParameters.filterIntervalPosition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns historic quotes
         * @param {QuotesApiGetHistoricQuotesInBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricQuotesInBatch(requestParameters: QuotesApiGetHistoricQuotesInBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricQuotesBatchResponse> {
            return localVarFp.getHistoricQuotesInBatch(requestParameters.filterQuoteIds, requestParameters.quoteProvider, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns latest price for given isin
         * @param {QuotesApiGetLatestByIsinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestByIsin(requestParameters: QuotesApiGetLatestByIsinRequest, options?: RawAxiosRequestConfig): AxiosPromise<LatestByIsin> {
            return localVarFp.getLatestByIsin(requestParameters.isin, requestParameters.quoteProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns quotes
         * @param {QuotesApiGetQuoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote(requestParameters: QuotesApiGetQuoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteResponse> {
            return localVarFp.getQuote(requestParameters.id, requestParameters.quoteProvider, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns quotes
         * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuotes(requestParameters: QuotesApiGetQuotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuotesResponse> {
            return localVarFp.getQuotes(requestParameters.quoteProvider, requestParameters.filterId, requestParameters.filterSecurityId, requestParameters.filterWkn, requestParameters.filterIsin, requestParameters.filterOptimal, requestParameters.filterPreferredCurrency, requestParameters.mandator, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary find a security by string
         * @param {QuotesApiGetSecuritiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurities(requestParameters: QuotesApiGetSecuritiesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SecuritiesResponse> {
            return localVarFp.getSecurities(requestParameters.filterSearch, requestParameters.filterWkn, requestParameters.filterIsin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve a security by ID
         * @param {QuotesApiGetSecurityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecurity(requestParameters: QuotesApiGetSecurityRequest, options?: RawAxiosRequestConfig): AxiosPromise<SecurityResponse> {
            return localVarFp.getSecurity(requestParameters.id, requestParameters.quoteProvider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for classifySecurities operation in QuotesApi.
 * @export
 * @interface QuotesApiClassifySecuritiesRequest
 */
export interface QuotesApiClassifySecuritiesRequest {
    /**
     * Comma separated values. Can not be combined with filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
     * @type {string}
     * @memberof QuotesApiClassifySecurities
     */
    readonly filterWkn?: string

    /**
     * Comma separated values. Can not be combined with filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
     * @type {string}
     * @memberof QuotesApiClassifySecurities
     */
    readonly filterIsin?: string
}

/**
 * Request parameters for getHistoricQuotes operation in QuotesApi.
 * @export
 * @interface QuotesApiGetHistoricQuotesRequest
 */
export interface QuotesApiGetHistoricQuotesRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly filterQuoteId: string

    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly quoteProvider: string

    /**
     * Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly filterFrom?: string

    /**
     * Format: ISO date such as 2017-07-21 or ISO time such as 2017-07-21T12:11:10Z
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly filterTo?: string

    /**
     * Format: 3-letter ISO code such as USD
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly toCurrency?: string

    /**
     * if exist, converts the response to the running average over the given amount of days 
     * @type {number}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly average?: number

    /**
     * Pad dates if true; default: false. Padding will fill gaps on weekends / public holidays.
     * @type {boolean}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly padDates?: boolean

    /**
     * Convert quotes to percent, using&lt;br&gt; \\                 first: the first quote as reference&lt;br&gt; \\                 previous: the previous quote as reference&lt;br&gt; \\                 current_to_now: the current quote as reference
     * @type {'first' | 'previous' | 'current_to_now'}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly inPercentFrom?: GetHistoricQuotesInPercentFromEnum

    /**
     * Restrict quote retrieval to the given interval. Auto will switch between daily and \\                 intraday quotes according to the selected interval. Default: auto.
     * @type {'auto' | 'day' | 'week' | 'month' | 'year'}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly filterInterval?: GetHistoricQuotesFilterIntervalEnum

    /**
     * Defines which value within the specified interval is used. Default: current.&lt;br&gt; \\                 current: current day of week / month / year;&lt;br&gt; \\                 first: first day of week / month / year;&lt;br&gt; \\                 last: last day of week / month / year
     * @type {'current' | 'first' | 'last'}
     * @memberof QuotesApiGetHistoricQuotes
     */
    readonly filterIntervalPosition?: GetHistoricQuotesFilterIntervalPositionEnum
}

/**
 * Request parameters for getHistoricQuotesInBatch operation in QuotesApi.
 * @export
 * @interface QuotesApiGetHistoricQuotesInBatchRequest
 */
export interface QuotesApiGetHistoricQuotesInBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotesInBatch
     */
    readonly filterQuoteIds: string

    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotesInBatch
     */
    readonly quoteProvider: string

    /**
     * Format: 2017-07-21
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotesInBatch
     */
    readonly filterFrom?: string

    /**
     * Format: 2017-07-21
     * @type {string}
     * @memberof QuotesApiGetHistoricQuotesInBatch
     */
    readonly filterTo?: string
}

/**
 * Request parameters for getLatestByIsin operation in QuotesApi.
 * @export
 * @interface QuotesApiGetLatestByIsinRequest
 */
export interface QuotesApiGetLatestByIsinRequest {
    /**
     * Comma separated ISINs. Example: \&#39;isin&#x3D;US2338252073,DE0007100000\&#39;
     * @type {string}
     * @memberof QuotesApiGetLatestByIsin
     */
    readonly isin: string

    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetLatestByIsin
     */
    readonly quoteProvider: string
}

/**
 * Request parameters for getQuote operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuoteRequest
 */
export interface QuotesApiGetQuoteRequest {
    /**
     * The quote ID; id&#x3D;42
     * @type {string}
     * @memberof QuotesApiGetQuote
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetQuote
     */
    readonly quoteProvider: string

    /**
     * Include the given entities in the request; input as comma separated list
     * @type {'security'}
     * @memberof QuotesApiGetQuote
     */
    readonly include?: GetQuoteIncludeEnum
}

/**
 * Request parameters for getQuotes operation in QuotesApi.
 * @export
 * @interface QuotesApiGetQuotesRequest
 */
export interface QuotesApiGetQuotesRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly quoteProvider: string

    /**
     * Comma separated quote IDs. filter[id]&#x3D;42,43
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterId?: string

    /**
     * Comma separated security IDs. filter[security_id]&#x3D;42,43
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterSecurityId?: string

    /**
     * Comma separated WKNs. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterWkn?: string

    /**
     * Comma separated ISINs. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterIsin?: string

    /**
     * Returns the optimal quote for each of the given filters. Default: false. Example: \&#39;filter[optimal]&#x3D;true\&#39;
     * @type {boolean}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterOptimal?: boolean

    /**
     * Prefer quotes in this currency when sorting or determining the optimal quote. Example: \&#39;filter[preferred_currency]&#x3D;USD\&#39;
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly filterPreferredCurrency?: string

    /**
     * Server-to-server only. Apply the given mandators permission if searching quotes. Example: \&#39;mandator&#x3D;somePublicId\&#39;
     * @type {string}
     * @memberof QuotesApiGetQuotes
     */
    readonly mandator?: string

    /**
     * Include the given entities in the request; input as comma separated list
     * @type {'security'}
     * @memberof QuotesApiGetQuotes
     */
    readonly include?: GetQuotesIncludeEnum
}

/**
 * Request parameters for getSecurities operation in QuotesApi.
 * @export
 * @interface QuotesApiGetSecuritiesRequest
 */
export interface QuotesApiGetSecuritiesRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetSecurities
     */
    readonly filterSearch?: string

    /**
     * Comma separated values. Can not be combined with filter[search] or filter[isin]. Example: \&#39;filter[wkn]&#x3D;710000,A2DKLU\&#39;
     * @type {string}
     * @memberof QuotesApiGetSecurities
     */
    readonly filterWkn?: string

    /**
     * Comma separated values. Can not be combined with filter[search] or filter[wkn]. Example: \&#39;filter[isin]&#x3D;US2338252073,DE0007100000\&#39;
     * @type {string}
     * @memberof QuotesApiGetSecurities
     */
    readonly filterIsin?: string
}

/**
 * Request parameters for getSecurity operation in QuotesApi.
 * @export
 * @interface QuotesApiGetSecurityRequest
 */
export interface QuotesApiGetSecurityRequest {
    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetSecurity
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof QuotesApiGetSecurity
     */
    readonly quoteProvider: string
}

/**
 * QuotesApi - object-oriented interface
 * @export
 * @class QuotesApi
 * @extends {BaseAPI}
 */
export class QuotesApi extends BaseAPI {
    /**
     * 
     * @summary perform region and industry classification by ISIN or WKN
     * @param {QuotesApiClassifySecuritiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public classifySecurities(requestParameters: QuotesApiClassifySecuritiesRequest = {}, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).classifySecurities(requestParameters.filterWkn, requestParameters.filterIsin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns historic quotes
     * @param {QuotesApiGetHistoricQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getHistoricQuotes(requestParameters: QuotesApiGetHistoricQuotesRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getHistoricQuotes(requestParameters.filterQuoteId, requestParameters.quoteProvider, requestParameters.filterFrom, requestParameters.filterTo, requestParameters.toCurrency, requestParameters.average, requestParameters.padDates, requestParameters.inPercentFrom, requestParameters.filterInterval, requestParameters.filterIntervalPosition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns historic quotes
     * @param {QuotesApiGetHistoricQuotesInBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getHistoricQuotesInBatch(requestParameters: QuotesApiGetHistoricQuotesInBatchRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getHistoricQuotesInBatch(requestParameters.filterQuoteIds, requestParameters.quoteProvider, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns latest price for given isin
     * @param {QuotesApiGetLatestByIsinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getLatestByIsin(requestParameters: QuotesApiGetLatestByIsinRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getLatestByIsin(requestParameters.isin, requestParameters.quoteProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns quotes
     * @param {QuotesApiGetQuoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuote(requestParameters: QuotesApiGetQuoteRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuote(requestParameters.id, requestParameters.quoteProvider, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns quotes
     * @param {QuotesApiGetQuotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getQuotes(requestParameters: QuotesApiGetQuotesRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getQuotes(requestParameters.quoteProvider, requestParameters.filterId, requestParameters.filterSecurityId, requestParameters.filterWkn, requestParameters.filterIsin, requestParameters.filterOptimal, requestParameters.filterPreferredCurrency, requestParameters.mandator, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary find a security by string
     * @param {QuotesApiGetSecuritiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getSecurities(requestParameters: QuotesApiGetSecuritiesRequest = {}, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getSecurities(requestParameters.filterSearch, requestParameters.filterWkn, requestParameters.filterIsin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve a security by ID
     * @param {QuotesApiGetSecurityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuotesApi
     */
    public getSecurity(requestParameters: QuotesApiGetSecurityRequest, options?: RawAxiosRequestConfig) {
        return QuotesApiFp(this.configuration).getSecurity(requestParameters.id, requestParameters.quoteProvider, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetHistoricQuotesInPercentFromEnum = {
    FIRST: 'first',
    PREVIOUS: 'previous',
    CURRENT_TO_NOW: 'current_to_now'
} as const;
export type GetHistoricQuotesInPercentFromEnum = typeof GetHistoricQuotesInPercentFromEnum[keyof typeof GetHistoricQuotesInPercentFromEnum];
/**
 * @export
 */
export const GetHistoricQuotesFilterIntervalEnum = {
    AUTO: 'auto',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;
export type GetHistoricQuotesFilterIntervalEnum = typeof GetHistoricQuotesFilterIntervalEnum[keyof typeof GetHistoricQuotesFilterIntervalEnum];
/**
 * @export
 */
export const GetHistoricQuotesFilterIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;
export type GetHistoricQuotesFilterIntervalPositionEnum = typeof GetHistoricQuotesFilterIntervalPositionEnum[keyof typeof GetHistoricQuotesFilterIntervalPositionEnum];
/**
 * @export
 */
export const GetQuoteIncludeEnum = {
    SECURITY: 'security'
} as const;
export type GetQuoteIncludeEnum = typeof GetQuoteIncludeEnum[keyof typeof GetQuoteIncludeEnum];
/**
 * @export
 */
export const GetQuotesIncludeEnum = {
    SECURITY: 'security'
} as const;
export type GetQuotesIncludeEnum = typeof GetQuotesIncludeEnum[keyof typeof GetQuotesIncludeEnum];
