/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { UserAuthorization } from '../model';
/**
 * AuthorizationsApi - axios parameter creator
 * @export
 */
export const AuthorizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the currently logged in users authorizations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorization: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/authorizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationsApi - functional programming interface
 * @export
 */
export const AuthorizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the currently logged in users authorizations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorization(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorization(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorizationsApi.getAuthorization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthorizationsApi - factory interface
 * @export
 */
export const AuthorizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the currently logged in users authorizations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorization(options?: RawAxiosRequestConfig): AxiosPromise<UserAuthorization> {
            return localVarFp.getAuthorization(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationsApi - object-oriented interface
 * @export
 * @class AuthorizationsApi
 * @extends {BaseAPI}
 */
export class AuthorizationsApi extends BaseAPI {
    /**
     * 
     * @summary Get the currently logged in users authorizations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationsApi
     */
    public getAuthorization(options?: RawAxiosRequestConfig) {
        return AuthorizationsApiFp(this.configuration).getAuthorization(options).then((request) => request(this.axios, this.basePath));
    }
}

