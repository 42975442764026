/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateAddressParams } from './create-or-update-address-params';
// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateNaturalPersonLegitimationParams } from './create-or-update-natural-person-legitimation-params';
// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateNaturalPersonMifidParams } from './create-or-update-natural-person-mifid-params';
// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateNaturalPersonTaxationParams } from './create-or-update-natural-person-taxation-params';

/**
 * 
 * @export
 * @interface CreateOrUpdateNaturalPersonParams
 */
export interface CreateOrUpdateNaturalPersonParams {
    /**
     * Technical identifier; required when updated in the context of an account holder
     * @type {number}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'id'?: number;
    /**
     * ID of the account holder this natural person belongs to
     * @type {number}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'accountHolderId'?: number;
    /**
     * Is this natural person the primary account holder?
     * @type {boolean}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'isPrimaryAccountHolder'?: boolean;
    /**
     * The gender, 1 = male, 2 = female
     * @type {number}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'gender'?: CreateOrUpdateNaturalPersonParamsGenderEnum;
    /**
     * The natural persons first name
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'firstName'?: string;
    /**
     * The natural persons last name
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'lastName'?: string;
    /**
     * The natural persons academic title
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'academicTitle'?: string;
    /**
     * The natural persons date of birth
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'dateOfBirth'?: string;
    /**
     * Is the natural person a minor (younger than 18 years)
     * @type {boolean}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'isMinor'?: boolean;
    /**
     * The natural persons name at birth
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'nameAtBirth'?: string;
    /**
     * The natural persons place of birth
     * @type {string}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'placeOfBirth'?: string;
    /**
     * ID of the country the natural person was born in
     * @type {number}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'countryOfBirthRegionId'?: number;
    /**
     * 
     * @type {CreateOrUpdateAddressParams}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'createOrUpdateAddressParams'?: CreateOrUpdateAddressParams;
    /**
     * 
     * @type {CreateOrUpdateNaturalPersonMifidParams}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'createOrUpdateNaturalPersonMifidParams'?: CreateOrUpdateNaturalPersonMifidParams;
    /**
     * 
     * @type {CreateOrUpdateNaturalPersonTaxationParams}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'createOrUpdateNaturalPersonTaxationParams'?: CreateOrUpdateNaturalPersonTaxationParams;
    /**
     * 
     * @type {CreateOrUpdateNaturalPersonLegitimationParams}
     * @memberof CreateOrUpdateNaturalPersonParams
     */
    'createOrUpdateNaturalPersonLegitimationParams'?: CreateOrUpdateNaturalPersonLegitimationParams;
}

export const CreateOrUpdateNaturalPersonParamsGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CreateOrUpdateNaturalPersonParamsGenderEnum = typeof CreateOrUpdateNaturalPersonParamsGenderEnum[keyof typeof CreateOrUpdateNaturalPersonParamsGenderEnum];


