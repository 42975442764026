/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BankConnectionMeta
 */
export interface BankConnectionMeta {
    /**
     * The overall status of the bank connection
     * @type {string}
     * @memberof BankConnectionMeta
     */
    'connection_status': BankConnectionMetaConnectionStatusEnum;
    /**
     * Progress of the current import or update run; useful to display the progress to end users
     * @type {string}
     * @memberof BankConnectionMeta
     */
    'import_progress'?: BankConnectionMetaImportProgressEnum | null;
    /**
     * DateTime of the last connection attempt
     * @type {string}
     * @memberof BankConnectionMeta
     */
    'last_connection_attempt': string;
    /**
     * DateTime of the last SUCCESSFUL connection attempt
     * @type {string}
     * @memberof BankConnectionMeta
     */
    'last_successful_connection_attempt'?: string | null;
    /**
     * Any error message that we received from the bank
     * @type {string}
     * @memberof BankConnectionMeta
     */
    'connection_error'?: string | null;
}

export const BankConnectionMetaConnectionStatusEnum = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    IN_PROGRESS: 'IN_PROGRESS'
} as const;

export type BankConnectionMetaConnectionStatusEnum = typeof BankConnectionMetaConnectionStatusEnum[keyof typeof BankConnectionMetaConnectionStatusEnum];
export const BankConnectionMetaImportProgressEnum = {
    CONNECTING: 'CONNECTING',
    ACCOUNTS: 'ACCOUNTS',
    TRANSACTIONS: 'TRANSACTIONS',
    SECURITIES: 'SECURITIES',
    PERSISTING_TRANSACTIONS: 'PERSISTING_TRANSACTIONS',
    PERSISTING_BOOKINGS: 'PERSISTING_BOOKINGS',
    SUCCESS: 'SUCCESS',
    FAILED_WITH_ERROR: 'FAILED_WITH_ERROR'
} as const;

export type BankConnectionMetaImportProgressEnum = typeof BankConnectionMetaImportProgressEnum[keyof typeof BankConnectionMetaImportProgressEnum];


