import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Grid,
  Popper,
  Typography,
  styled,
  useMediaQuery,
  Theme,
  Link,
  TableCell,
  TableHead,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import { SearchField } from "@components/SearchField";
import { groupBankByName, GroupedBank } from "@components/BankSelect";
// import { ReactComponent as InfoIcon } from "@icons/myicons/info.svg";

import { SortableTableHeader, Table, TableRow } from "@components/Table";
import { BankImage } from "@components/BankSelect/components/BankImage";
import { UpdateTag } from "@components/BankSelect/components/UpdateTag";
import { Logo } from "@components";
import {
  blacklistBanks,
  favouriteBanks,
  notSoFavouriteBanks,
  WhitelistBank,
} from "@constants";
import { BankOption, BankOptionSmall } from "../BankOption";
import { useBanks } from "../hooks";

const filterBanks = (banks: GroupedBank[]) =>
  banks.reduce((acc, bank) => {
    if (blacklistBanks.includes(bank.bicGroup!)) {
      return acc;
    }
    const whitelistedBanks = bank.banks.filter(
      (bank) => !blacklistBanks.includes(bank.bic!)
    );
    if (!whitelistedBanks.length) {
      return acc;
    }

    return [
      ...acc,
      {
        ...bank,
        banks: whitelistedBanks,
      },
    ];
  }, [] as GroupedBank[]);

const Container = styled(Box)`
  max-width: 736px;
  width: 100%;
  position: relative;
`;

const PopperInnerWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  overflowY: "auto",
  marginTop: theme.spacing(2),
}));

export interface BankSelectProps {
  onChange?: (value: string) => void;
  onSelect?: (groupedBank: GroupedBank) => void;
  banks: GroupedBank[];
  searchValue?: string;
  isLoading?: boolean;
  isIdle?: boolean;
  className?: string;
  shouldDisplayTitle?: boolean;
  controlled?: boolean;
}

export const BankSelectView = ({
  onChange,
  onSelect,
  searchValue,
  banks = [],
  isIdle = true,
  isLoading = false,
  shouldDisplayTitle = true,
  className,
  controlled,
}: BankSelectProps) => {
  const ref = useRef() as React.RefObject<HTMLInputElement>;
  const theme = useTheme();
  const { t } = useTranslation();
  const { mutate: getBanks } = useBanks();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isBankSelectOpen, setIsBankSelectOpen] = useState(false);
  const [inputValue, setInputValue] = useState(searchValue || "");
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [searchParams] = useSearchParams();

  const handleChange = useDebouncedCallback((value: string) => {
    onChange && onChange(value);
  }, 500);

  const banksWithoutBlacklisted = filterBanks(banks);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (controlled) {
        onChange?.(event.target.value);
        setIsBankSelectOpen(true);
      } else {
        setInputValue(event.target.value);
        handleChange(event.target.value);
      }
    },
    [handleChange, controlled, onChange]
  );

  const handleBankSelect = useCallback(
    (bank: WhitelistBank) => {
      getBanks(
        {
          search: bank.search,
        },
        {
          onSuccess: (result) => {
            let banks = result.banks;

            if (bank.filter) {
              banks = banks?.filter(bank.filter);
            }
            onSelect?.(groupBankByName(banks)[0]);
          },
        }
      );
    },
    [onSelect, getBanks]
  );

  useEffect(() => {
    if (controlled) return;
    if (searchValue) {
      // this is to prevent popper appearing in the wrong place when animating
      setTimeout(() => {
        setIsBankSelectOpen(true);
      }, 300);
    } else {
      setIsBankSelectOpen(false);
    }
  }, [searchValue, controlled]);

  useEffect(() => {
    setAnchorEl(ref?.current);
  }, [ref]);

  return (
    <Container data-testid="bank-select-period" className={className} pt={10}>
      {/* <Banner
        type="grey-info"
        icon={<InfoIcon width="28px" height="28px" />}
        text={
          <Typography variant="body1" fontWeight={400}>
            <Trans
              i18nKey="bankSelect.bannerText"
              components={{
                boldText: <b />,
                // TODO it will be returned after Trade Republic fix
                // linkC24: <Link onClick={() => handleBankSelect(C24Bank)} />,
                // linkFinanzen: (
                //   <Link onClick={() => handleBankSelect(FinanzenNetZero)} />
                // ),
              }}
            >
              {t("bankSelect.bannerText")}
            </Trans>
          </Typography>
        }
        mb={10}
        sx={{
          padding: `${theme.spacing(1.5, 5)} !important`,
          borderRadius: "8px !important",
        }}
      /> */}

      {shouldDisplayTitle && (
        <Box mb={6}>
          <Typography variant="h4" mb={1}>
            {t("bankSelect.pageTitle")}
          </Typography>
          <Typography variant="body1" color="textSecondary" fontWeight={400}>
            {t("bankSelect.title")}
          </Typography>
        </Box>
      )}

      <SearchField
        ref={ref}
        value={controlled ? searchValue : inputValue}
        inputProps={{ "data-testid": "bank-search-input" }}
        variant="outlined"
        onChange={handleInputChange}
        InputProps={{
          endAdornment: isLoading && (
            <CircularProgress color="inherit" size={20} />
          ),
        }}
        placeholder={t("bankSelect.searchLabel")}
        sx={{
          height: "42px",
          width: "100%",
          marginBottom: theme.spacing(20),
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: (theme) => theme.palette.border.primary,
          },
        }}
        autoFocus
      />

      {anchorEl && (
        <Popper
          id="bank-select"
          data-testid="bank-select"
          open={isBankSelectOpen}
          anchorEl={anchorEl}
          placement="bottom"
          transition
          disablePortal
          sx={{
            display: "flex",
            width: "100%",
            maxHeight: "100%",
            zIndex: 2000,
          }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener
              onClickAway={(event) => {
                if (
                  !(event.target as HTMLElement).closest(
                    "[data-testid='bank-search-input']"
                  )
                ) {
                  setIsBankSelectOpen(false);
                }
              }}
            >
              <Fade {...TransitionProps}>
                <PopperInnerWrapper p={3} maxHeight="100%" width="100%">
                  <Grid container rowSpacing={4} columnSpacing={2}>
                    {banksWithoutBlacklisted.map((bank) => (
                      <BankOption
                        key={bank.name}
                        bank={bank}
                        onClick={() => {
                          setIsBankSelectOpen(false);
                          onSelect?.(bank);
                        }}
                        searchValue={searchValue}
                      />
                    ))}
                  </Grid>

                  {!banksWithoutBlacklisted.length && !isLoading && (
                    <Typography variant="body1" mt={4} ml={2} fontWeight={400}>
                      <Trans
                        i18nKey="bankSelect.notFound"
                        components={{
                          linkText: (
                            <Link
                              href={`${
                                process.env.REACT_APP_V2_URL
                              }/accounts/add-manually?type=${searchParams.get(
                                "type"
                              )}`}
                            />
                          ),
                        }}
                      />
                    </Typography>
                  )}
                </PopperInnerWrapper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}

      <Typography variant="h5" fontWeight={500} mb={5}>
        {t("bankSelect.favouriteBanks")}
      </Typography>
      <Grid container rowSpacing={2.5} columnSpacing={4} mb={20}>
        {favouriteBanks.map((bank) => (
          <BankOptionSmall
            bank={bank}
            onClick={handleBankSelect}
            key={`favourite-banks-${bank.title}`}
          />
        ))}
      </Grid>

      <Typography variant="h5" fontWeight={500} mb={5}>
        {t("bankSelect.notSoFavouriteBanks")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeader
              text={t("bankSelect.provider")}
              sortByName=""
              width={isLessSm ? "40%" : "70%"}
            />
            <SortableTableHeader
              align="right"
              text={t("bankSelect.actualisation")}
              sortByName=""
              width={isLessSm ? "60%" : "30%"}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {notSoFavouriteBanks.map((bank) => (
            <TableRow
              key={`other-banks-${bank.title}`}
              onClick={() => handleBankSelect(bank)}
              clickable
            >
              <TableCell>
                <Box display="flex" alignItems="center" gap={2}>
                  <BankImage>
                    {bank.img ? (
                      <img src={bank.img} alt={bank.img} />
                    ) : (
                      <Logo variant="asset" width="100%" height="100%" />
                    )}
                  </BankImage>
                  <Box>
                    <Typography variant="body2" mb={0}>
                      {bank.title}
                    </Typography>
                    {/*<Typography*/}
                    {/*  variant="body2"*/}
                    {/*  color="textSecondary"*/}
                    {/*  fontWeight={400}*/}
                    {/*  lineHeight="18px"*/}
                    {/*>*/}
                    {/*  Some sample text*/}
                    {/*</Typography>*/}
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box display="flex" justifyContent="flex-end">
                  <UpdateTag update={bank.update} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
