import { UseQueryOptions } from "react-query";
import {
  SocialStatisticsApiBuySellRatioRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildSocialStatisticsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const buySellRatio = async (
  token: string | undefined,
  params: SocialStatisticsApiBuySellRatioRequest
) => {
  const apiInstance = buildSocialStatisticsApi(token);
  const { data } = await apiInstance.buySellRatio(params);
  return data;
};

export const useBuySellRatio = (
  params: SocialStatisticsApiBuySellRatioRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SOCIALSTATISTICS_BUY_SELL_RATIO, params],
    ({ token }) => buySellRatio(token, params),
    options
  );
};
