import { getYear } from "date-fns";
import { RangeValue } from "@components";

const getFromDateByRange = (range: RangeValue, toYear: number) => {
  switch (range) {
    case "3y":
      return toYear - 3;
    case "5y":
      return toYear - 5;
    case "max":
      return undefined;

    default:
      throw new Error("undefined interval");
  }
};

export const getDates = (range: RangeValue | undefined) => {
  const toYear = getYear(new Date());
  const fromYear = range && getFromDateByRange(range, toYear);
  const fromDate = fromYear ? `${fromYear}-01-01` : `${toYear - 11}-01-01`;
  const toDate = `${toYear}-12-31`;
  return { fromDate, fromYear, toYear, toDate };
};

export const getFromDate = () => `${getYear(new Date()) - 10}-01-01`;
