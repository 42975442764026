import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Account } from "@generated/apiv1";
import { useAccountPerformance } from "@components/Accounts/AccountView/hooks/useAccountPerformance";
import { AccountPreviewCard } from "@components/Accounts/AccountPreviewCard";

interface Props {
  account: Account & { syncRequired: boolean; isReferenceAccount?: boolean };
  onClick: () => void;
  onPerformanceCalculated?: Dispatch<SetStateAction<Record<number, number>>>;
  showYtdValue?: boolean;
  hasAccountsWithUpdateButton?: boolean;
  type?: string;
}

export const AccountView = ({
  account,
  onClick,
  onPerformanceCalculated,
  hasAccountsWithUpdateButton,
  showYtdValue,
  type,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isAccount =
    account.typeFromBank === "CREDIT_CARD" || account.type === "02_cash";
  const {
    portfolioValue,
    changePercent,
    chartData = [],
    sinceYear,
    changeAbsCashFlowAdjusted,
  } = useAccountPerformance(account.id, isAccount);

  useEffect(() => {
    onPerformanceCalculated?.((prevState) => ({
      ...prevState,
      [account.id]: portfolioValue,
    }));
  }, [onPerformanceCalculated, portfolioValue, account.id]);

  const {
    name,
    bankName,
    bankConnectionId,
    accountNumber,
    iconUrl = "",
    syncRequired,
    isReferenceAccount,
    bankConnectionType,
  } = account;

  const needSyncAccount = Boolean(bankConnectionId) && syncRequired;

  const handleClickUpdate = () => {
    navigate(
      `/import-bank-connection/redirect?bankConnectionId=${bankConnectionId}&multibankingApiProvider=${bankConnectionType}`
    );
  };

  return (
    <AccountPreviewCard
      currency="EUR"
      accountName={name}
      isReferenceAccount={isReferenceAccount}
      accountType={type}
      bankName={bankName ?? t("accountView.noBank")}
      bankCode={accountNumber}
      chartData={chartData}
      amount={portfolioValue}
      increasePercent={changePercent ?? 0}
      bankLogoURL={iconUrl}
      showNotification={needSyncAccount}
      showUpdateButton={Boolean(
        bankConnectionId && bankConnectionType !== "NETFONDS"
      )}
      hasAccountsWithUpdateButton={hasAccountsWithUpdateButton}
      onClickUpdate={handleClickUpdate}
      onClick={onClick}
      showChartBorder={false}
      showYtdValue={showYtdValue}
      sinceYear={sinceYear}
      changeAbsCashFlowAdjusted={changeAbsCashFlowAdjusted}
    />
  );
};
