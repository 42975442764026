import { UseMutationOptions } from "react-query";
import {
  SubscriptionsApiCreateSubscriptionRequest,
  Subscription,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createSubscription = async (
  token: string | undefined,
  params: SubscriptionsApiCreateSubscriptionRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.createSubscription(params);
  return data;
};

export const useCreateSubscription = (
  options?: UseMutationOptions<
    Subscription,
    AxiosApiError,
    SubscriptionsApiCreateSubscriptionRequest
  >
) => {
  return useAuthMutation(createSubscription, options, false);
};
