import { useMediaQuery, Theme, Box } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "@helpers";
import { Carousel } from "@components";
import { useChartColors } from "@hooks";
import {
  AssetCard,
  AssetTypeCard,
} from "@features/investments/components/Assets/AssetTypeCard";
import { usePerformanceByAssetClass } from "@features/investments/hooks/usePerformanceByAssetClass";
import { portfolioStatistics } from "@features/investments/components/helpers/portfolioStatistics";

const calculatePercentOfTotal = ({
  shares = 0,
  funds = 0,
  etfs = 0,
  bonds = 0,
  currency = 0,
  materialAssets = 0,
  metals = 0,
}: {
  shares?: number;
  funds?: number;
  etfs?: number;
  bonds?: number;
  currency?: number;
  materialAssets?: number;
  metals?: number;
}) => {
  const totalValue =
    shares + funds + etfs + bonds + currency + materialAssets + metals || 1;
  return {
    shares: Math.round((shares * 100) / totalValue),
    funds: Math.round((funds * 100) / totalValue),
    etfs: Math.round((etfs * 100) / totalValue),
    bonds: Math.round((bonds * 100) / totalValue),
    currency: Math.round((currency * 100) / totalValue),
    materialAssets: Math.round((materialAssets * 100) / totalValue),
    metals: Math.round((metals * 100) / totalValue),
  };
};

export const investmentTypes: Record<string, AssetCard> = {
  "11_stock": "shares",
  "21_fund": "funds",
  "22_etf": "etfs",
  "31_bond": "bonds",
  "81_fcurr": "currency",
  "71_massets": "materialAssets",
  "61_pmetals": "metals",
};

export interface Props {
  onCardClick: (val: AssetCard) => void;
  selectedCards: AssetCard[];
  performanceByAssetClass: ReturnType<typeof usePerformanceByAssetClass>;
  assetsWithData: string[];
  interval: IntervalType;
}

const AssetTypeCards = ({
  performanceByAssetClass,
  onCardClick,
  selectedCards,
  assetsWithData,
  interval,
}: Props) => {
  const { t } = useTranslation();
  const chartColors = useChartColors("securitiesChart");
  const isSelected = (val: AssetCard) => selectedCards.includes(val);
  const hasData = (val: AssetCard) => assetsWithData.includes(val);

  const allAssets = useMemo(
    () => ({
      shares: performanceByAssetClass.shares,
      funds: performanceByAssetClass.funds,
      etfs: performanceByAssetClass.etfs,
      bonds: performanceByAssetClass.bonds,
      currency: performanceByAssetClass.currency,
      materialAssets: performanceByAssetClass.materialAssets,
      metals: performanceByAssetClass.metals,
    }),
    [performanceByAssetClass]
  );

  const { shares, funds, etfs, bonds, currency, materialAssets, metals } =
    useMemo(
      () =>
        Object.keys(performanceByAssetClass).reduce((acc, key) => {
          return {
            ...acc,
            [key]: portfolioStatistics(
              allAssets[key as keyof typeof allAssets]
            ),
          };
        }, {} as Record<AssetCard, ReturnType<typeof portfolioStatistics>>),
      [performanceByAssetClass, allAssets]
    );

  const percents = calculatePercentOfTotal({
    shares: shares?.currentMarketValue,
    funds: funds?.currentMarketValue,
    etfs: etfs?.currentMarketValue,
    bonds: bonds?.currentMarketValue,
    currency: currency?.currentMarketValue,
    materialAssets: materialAssets?.currentMarketValue,
    metals: metals?.currentMarketValue,
  });

  const assetKeys: AssetCard[] = [
    "shares",
    "funds",
    "etfs",
    "bonds",
    "currency",
    "materialAssets",
    "metals",
  ];

  return (
    <>
      {[shares, funds, etfs, bonds, currency, materialAssets, metals].map(
        (asset, i) => {
          return (
            <AssetTypeCard
              key={assetKeys[i]}
              isLoading={
                !performanceByAssetClass.isFetched ||
                performanceByAssetClass.isLoading
              }
              title={t(`investments.securitiesChart.${assetKeys[i]}`)}
              amount={toCurrency(asset.currentMarketValue, "EUR")}
              increase={asset.twrorPercent}
              percent={percents[assetKeys[i]]}
              changeAbs={asset.changeTotalAbsolute}
              color={chartColors[assetKeys[i]]}
              // TODO refactor: change when backend implement hasData flag in v2/performance
              hasData={hasData(assetKeys[i])}
              onClick={() => onCardClick(assetKeys[i])}
              isSelected={isSelected(assetKeys[i])}
              interval={interval}
            />
          );
        }
      )}
    </>
  );
};

export const AssetTypeBreakdown = ({
  onCardClick,
  selectedCards,
  performanceByAssetClass,
  interval,
  assetsWithData,
}: Props) => {
  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return isDownMd ? (
    <Carousel hideArrows={isDownMd}>
      <AssetTypeCards
        performanceByAssetClass={performanceByAssetClass}
        onCardClick={onCardClick}
        selectedCards={selectedCards}
        interval={interval}
        assetsWithData={assetsWithData}
      />
    </Carousel>
  ) : (
    <Box display="flex" flexWrap="wrap" gap={3}>
      <AssetTypeCards
        performanceByAssetClass={performanceByAssetClass}
        onCardClick={onCardClick}
        selectedCards={selectedCards}
        interval={interval}
        assetsWithData={assetsWithData}
      />
    </Box>
  );
};
