import { UseMutationOptions } from "react-query";
import {
  CSVApiImportCsvRequest,
  CsvImportPerformResponse,
} from "@generated/apiv3";

import { buildCSVApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const importCsv = async (
  token: string | undefined,
  params?: CSVApiImportCsvRequest
) => {
  const apiInstance = buildCSVApi(token);
  const { data } = await apiInstance.importCsv(params);
  return data;
};

export const useImportCsv = (
  options?: UseMutationOptions<
    CsvImportPerformResponse,
    AxiosApiError,
    CSVApiImportCsvRequest
  >
) => {
  return useAuthMutation(importCsv, options, false);
};
