import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setInvestmentValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "INVESTMENT_INITIALIZED" &&
    event.type !== "INVESTMENT_SELECTED"
  )
    throw new Error(`Invalid event type ${event.type}`);

  const { quoteLast, ...investmentValues } = event.investmentValues;

  return {
    ...(event.type === "INVESTMENT_SELECTED" &&
      quoteLast && {
        bookingValues: {
          ...context.bookingValues,
          entryQuote: quoteLast,
        },
      }),
    investmentValues,
    ...(event.resetSource && { sourceInvestmentValues: undefined }),
  };
};
