import { merge, mergeWith } from "lodash";
import { darken, lighten, ThemeOptions } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles";

const handler =
  (func: Function, arg2: object | Function) =>
  (...args: any[]) => {
    const result = func(...args);
    const result2 = typeof arg2 === "function" ? arg2(...args) : arg2;
    return merge(result, result2);
  };

export const mergeThemes = (theme: ThemeOptions, theme2: ThemeOptions) => {
  return mergeWith({}, theme, theme2, function (value1, value2) {
    if (
      Array.isArray(value1) &&
      Array.isArray(value2) &&
      typeof value1[0] === "object"
    ) {
      return value1.concat(value2);
    }
    if (typeof value1 === "function") {
      return handler(value1, value2);
    }
  });
};

export const generateClassificationChartColors = (colors: string[]) => {
  return colors.reduce((acc, color) => {
    for (let i = 0.25; i >= 0.05; i -= 0.1) {
      acc.push(darken(color, i));
      acc.push(lighten(color, i));
    }
    return acc;
  }, [] as string[]);
};

export const generateStandardChartColors = (colors: PaletteOptions) => {
  return {
    assetsChart: {
      insurances: colors.graphVisual["02"].mid,
      cash: colors.graphVisual["01"].mid,
      investments: colors.graphVisual["07"].mid,
    },
    donutChart: [
      colors.graphVisual["02"].mid,
      colors.graphVisual["03"].mid,
      colors.graphVisual["06"].mid,
      colors.graphVisual["05"].mid,
      colors.graphVisual["07"].mid,
      colors.graphVisual["04"].mid,
      colors.graphVisual["01"].mid,
      colors.graphVisual["08"].dark,
      colors.graphVisual["08"].mid,
      colors.graphVisual["08"].light,
    ],
    speedometerChart: [
      colors.graphVisual["02"].dark,
      colors.graphVisual["02"].mid,
      colors.graphVisual["02"].light,
      colors.graphVisual["05"].mid,
      colors.graphVisual["03"].mid,
      colors.graphVisual["04"].mid,
      colors.graphVisual["04"].dark,
    ],
    predictionChart: [
      colors.graphVisual["07"].dark,
      colors.graphVisual["07"].mid,
      colors.graphVisual["05"].dark,
      colors.graphVisual["05"].mid,
      colors.graphVisual["02"].dark,
      colors.graphVisual["02"].mid,
      colors.graphVisual["06"].dark,
      colors.graphVisual["06"].light,
      colors.graphVisual["01"].mid,
      colors.graphVisual["01"].dark,
    ],
    cashflowChart: {
      income: colors.graphVisual["02"].mid,
      expenses: colors.graphVisual["01"].dark,
      disabled: colors.graphVisual["08"].light,
      others: colors.graphVisual["08"].mid,
      categories: {
        Mobilität: {
          dark: colors.graphVisual["03"].darker,
          light: colors.graphVisual["03"].lighter,
        },
        Einnahmen: {
          dark: colors.graphVisual["02"].mid,
          light: colors.graphVisual["02"].lighter,
        },
        "Bank und Kredit": {
          dark: colors.graphVisual["04"].darker,
          light: colors.graphVisual["04"].lighter,
        },
        "Gesundheit und Wellness": {
          dark: colors.graphVisual["01"].darker,
          light: colors.graphVisual["01"].lighter,
        },
        "Freizeit, Hobbies und Soziales": {
          dark: colors.graphVisual["07"].darker,
          light: colors.graphVisual["07"].lighter,
        },
        "Shopping und Unterhaltung": {
          dark: colors.graphVisual["12"].darker,
          light: colors.graphVisual["12"].lighter,
        },
        Kinder: {
          dark: colors.graphVisual["06"].darker,
          light: colors.graphVisual["06"].lighter,
        },
        Lebenshaltung: {
          dark: colors.graphVisual["09"].darker,
          light: colors.graphVisual["09"].lighter,
        },
        Drogerie: {
          dark: colors.graphVisual["10"].darker,
          light: colors.graphVisual["10"].lighter,
        },
        Reisen: {
          dark: colors.graphVisual["13"].darker,
          light: colors.graphVisual["13"].lighter,
        },
        Versicherung: {
          dark: colors.graphVisual["05"].darker,
          light: colors.graphVisual["05"].lighter,
        },
        Wohnen: {
          dark: colors.graphVisual["11"].darker,
          light: colors.graphVisual["11"].lighter,
        },
        "Sparen und Anlegen": {
          dark: colors.graphVisual["14"].darker,
          light: colors.graphVisual["14"].lighter,
        },
        "Keine Ausgaben": {
          dark: colors.graphVisual["08"].mid,
          light: colors.graphVisual["08"].light,
        },
        "Nicht klassifiziert": {
          text: colors.graphVisual["08"].darker,
          dark: colors.graphVisual["08"].mid,
          light: colors.graphVisual["08"].light,
        },
      },
    },
    classificationChart: generateClassificationChartColors([
      colors.graphVisual["01"].mid,
      colors.graphVisual["01"].light,
      colors.graphVisual["02"].dark,
      colors.graphVisual["02"].light,
      colors.graphVisual["06"].mid,
      colors.graphVisual["06"].light,
    ]),
    comparisonChart: {
      value1: colors.graphVisual["01"].mid,
      value2: colors.graphVisual["08"].darker,
      line: colors.graphVisual["08"].mid,
    },
    horizontalBarChart: {
      positive: colors.graphVisual["02"].light,
      negative: colors.graphVisual["04"].light,
      estimated: colors.graphVisual["08"].lighter,
      current: colors.graphVisual["08"].darkest!,
      neutral: colors.graphVisual["08"].dark,
    },
    securitiesChart: {
      mainLine: colors.graphVisual["01"].dark,
      mainArea: colors.graphVisual["01"].lighter,
      secondaryLine: colors.graphVisual["05"].mid,
      secondaryArea: colors.graphVisual["05"].lighter,
      holdings: colors.graphVisual["01"].mid,
      funds: colors.graphVisual["06"].mid,
      etfs: colors.graphVisual["02"].dark,
      derivative: colors.graphVisual["04"].mid,
      bonds: colors.graphVisual["03"].mid,
      drawableLines: [
        colors.graphVisual["04"].dark,
        colors.graphVisual["08"].mid,
        colors.graphVisual["05"].dark,
        colors.graphVisual["06"].mid,
        colors.graphVisual["07"].mid,
      ],
    },
    miniChart: {
      area: colors.graphVisual["02"].mid,
      line: colors.graphVisual["02"].dark,
    },
    watchlistsChart: [
      colors.graphVisual["01"].dark,
      colors.graphVisual["02"].dark,
      colors.graphVisual["03"].dark,
      colors.graphVisual["04"].dark,
      colors.graphVisual["05"].dark,
      colors.graphVisual["06"].dark,
      colors.graphVisual["07"].dark,
      colors.graphVisual["08"].dark,
      colors.graphVisual["01"].light,
      colors.graphVisual["02"].light,
      colors.graphVisual["03"].light,
      colors.graphVisual["04"].light,
      colors.graphVisual["05"].light,
      colors.graphVisual["06"].light,
      colors.graphVisual["07"].light,
      colors.graphVisual["08"].light,
    ],
  };
};
