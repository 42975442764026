import { UseQueryOptions } from "react-query";
import {
  InsurancesApiListAndSearchAllInsurancesRequest,
  PageableInsuranceList,
} from "@generated/apiv1";

import { buildInsurancesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllInsurances = async (
  token: string | undefined,
  params?: InsurancesApiListAndSearchAllInsurancesRequest
) => {
  const apiInstance = buildInsurancesApi(token);
  const { data } = await apiInstance.listAndSearchAllInsurances(params);
  return data;
};

export const useListAndSearchAllInsurances = (
  params?: InsurancesApiListAndSearchAllInsurancesRequest,
  options?: UseQueryOptions<
    PageableInsuranceList,
    AxiosApiError,
    PageableInsuranceList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INSURANCES_LIST_AND_SEARCH_ALL_INSURANCES, params],
    ({ token }) => listAndSearchAllInsurances(token, params),
    options
  );
};
