import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendPredictionRequest,
  DividendHistoryTree,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendPrediction = async (
  token: string | undefined,
  params: DividendsApiDividendPredictionRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendPrediction(params);
  return data;
};

export const useDividendPrediction = (
  params: DividendsApiDividendPredictionRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_PREDICTION, params],
    ({ token }) => dividendPrediction(token, params),
    options
  );
};
