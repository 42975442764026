/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Position } from './position';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * Technical identifier
     * @type {string}
     * @memberof Activity
     */
    'uuid'?: string;
    /**
     * The name of the security
     * @type {string}
     * @memberof Activity
     */
    'name'?: string;
    /**
     * The ISIN of the security
     * @type {string}
     * @memberof Activity
     */
    'isin'?: string;
    /**
     * The WKN of the security
     * @type {string}
     * @memberof Activity
     */
    'wkn'?: string;
    /**
     * The crypto symbol of the security; this is only filled for cryptos
     * @type {string}
     * @memberof Activity
     */
    'cryptoSymbol'?: string;
    /**
     * The number of lots
     * @type {number}
     * @memberof Activity
     */
    'numberOfLots'?: number;
    /**
     * The price of the security
     * @type {number}
     * @memberof Activity
     */
    'securityPrice'?: number;
    /**
     * The currency of the security
     * @type {string}
     * @memberof Activity
     */
    'currency'?: string;
    /**
     * The date of the activity
     * @type {string}
     * @memberof Activity
     */
    'date'?: string;
    /**
     * The exchange rate
     * @type {number}
     * @memberof Activity
     */
    'exchangeRate'?: number;
    /**
     * The booking type
     * @type {string}
     * @memberof Activity
     */
    'type'?: ActivityTypeEnum;
    /**
     * The commission; typically these are brokerage fees. This includes all types of fees. See postions for individual components.
     * @type {number}
     * @memberof Activity
     */
    'commission'?: number;
    /**
     * The (cumulative) tax amount including all types of tax. See postions for individual components.
     * @type {number}
     * @memberof Activity
     */
    'taxAmount'?: number;
    /**
     * The gross amount of the activity; excluding tax
     * @type {number}
     * @memberof Activity
     */
    'grossAmount'?: number;
    /**
     * The net amount of the activity; including tax
     * @type {number}
     * @memberof Activity
     */
    'netAmount'?: number;
    /**
     * The SEPA transaction purpose
     * @type {string}
     * @memberof Activity
     */
    'purpose'?: string;
    /**
     * The source filename of the PDF
     * @type {string}
     * @memberof Activity
     */
    'sourceFilename'?: string;
    /**
     * Detailed tax and commission statements, i.e. not-aggregated
     * @type {Array<Position>}
     * @memberof Activity
     */
    'positions'?: Array<Position>;
}

export const ActivityTypeEnum = {
    BUY: 'buy',
    SELL: 'sell',
    DIVIDEND: 'dividend',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out'
} as const;

export type ActivityTypeEnum = typeof ActivityTypeEnum[keyof typeof ActivityTypeEnum];


