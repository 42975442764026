/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Account } from '../model';
// @ts-ignore
import { MergePortfolioParams } from '../model';
/**
 * PortfolioOperationsApi - axios parameter creator
 * @export
 */
export const PortfolioOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create clone of the given portfolio. The clone will contain the same investments, bookings                 and cash transactions as the original portfolio. The clone will not have a bank connection to                 avoid collisions during sync.
         * @param {number} id ID of the portfolio to clone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePortfolio: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clonePortfolio', 'id', id)
            const localVarPath = `/v1/portfolioOperations/clone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merge the \'source\' into the \'target\' portfolio. The \'source\' portfolio will be deleted after                 a successful merge. The bank connection related parameters (such as bank name or bank connection ID)                 will be taken over into the \'target portfolio. This means the \'target\' portfolio will be connected                 to a bank after the merge - if the \'source\' portfolio was connected to a bank before.                 The merge strategy is as follows:                 - investments, bookings and cash transactions from the \'source\' portfolio will be copied into the                   \'target\' portfolio.                 - for each investment, bookings from the \'source\' portfolio that come BEFORE any bookings in                   the \'target\' portfolio will be skipped.                 - all cash transactions that were imported from a bank will be copied; no matter when.                 
         * @param {MergePortfolioParams} body Defines the parameters for the merge operation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergePortfolios: async (body: MergePortfolioParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('mergePortfolios', 'body', body)
            const localVarPath = `/v1/portfolioOperations/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioOperationsApi - functional programming interface
 * @export
 */
export const PortfolioOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create clone of the given portfolio. The clone will contain the same investments, bookings                 and cash transactions as the original portfolio. The clone will not have a bank connection to                 avoid collisions during sync.
         * @param {number} id ID of the portfolio to clone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clonePortfolio(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clonePortfolio(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioOperationsApi.clonePortfolio']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Merge the \'source\' into the \'target\' portfolio. The \'source\' portfolio will be deleted after                 a successful merge. The bank connection related parameters (such as bank name or bank connection ID)                 will be taken over into the \'target portfolio. This means the \'target\' portfolio will be connected                 to a bank after the merge - if the \'source\' portfolio was connected to a bank before.                 The merge strategy is as follows:                 - investments, bookings and cash transactions from the \'source\' portfolio will be copied into the                   \'target\' portfolio.                 - for each investment, bookings from the \'source\' portfolio that come BEFORE any bookings in                   the \'target\' portfolio will be skipped.                 - all cash transactions that were imported from a bank will be copied; no matter when.                 
         * @param {MergePortfolioParams} body Defines the parameters for the merge operation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergePortfolios(body: MergePortfolioParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergePortfolios(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PortfolioOperationsApi.mergePortfolios']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PortfolioOperationsApi - factory interface
 * @export
 */
export const PortfolioOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioOperationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create clone of the given portfolio. The clone will contain the same investments, bookings                 and cash transactions as the original portfolio. The clone will not have a bank connection to                 avoid collisions during sync.
         * @param {PortfolioOperationsApiClonePortfolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePortfolio(requestParameters: PortfolioOperationsApiClonePortfolioRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.clonePortfolio(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merge the \'source\' into the \'target\' portfolio. The \'source\' portfolio will be deleted after                 a successful merge. The bank connection related parameters (such as bank name or bank connection ID)                 will be taken over into the \'target portfolio. This means the \'target\' portfolio will be connected                 to a bank after the merge - if the \'source\' portfolio was connected to a bank before.                 The merge strategy is as follows:                 - investments, bookings and cash transactions from the \'source\' portfolio will be copied into the                   \'target\' portfolio.                 - for each investment, bookings from the \'source\' portfolio that come BEFORE any bookings in                   the \'target\' portfolio will be skipped.                 - all cash transactions that were imported from a bank will be copied; no matter when.                 
         * @param {PortfolioOperationsApiMergePortfoliosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergePortfolios(requestParameters: PortfolioOperationsApiMergePortfoliosRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.mergePortfolios(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clonePortfolio operation in PortfolioOperationsApi.
 * @export
 * @interface PortfolioOperationsApiClonePortfolioRequest
 */
export interface PortfolioOperationsApiClonePortfolioRequest {
    /**
     * ID of the portfolio to clone
     * @type {number}
     * @memberof PortfolioOperationsApiClonePortfolio
     */
    readonly id: number
}

/**
 * Request parameters for mergePortfolios operation in PortfolioOperationsApi.
 * @export
 * @interface PortfolioOperationsApiMergePortfoliosRequest
 */
export interface PortfolioOperationsApiMergePortfoliosRequest {
    /**
     * Defines the parameters for the merge operation.
     * @type {MergePortfolioParams}
     * @memberof PortfolioOperationsApiMergePortfolios
     */
    readonly body: MergePortfolioParams
}

/**
 * PortfolioOperationsApi - object-oriented interface
 * @export
 * @class PortfolioOperationsApi
 * @extends {BaseAPI}
 */
export class PortfolioOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Create clone of the given portfolio. The clone will contain the same investments, bookings                 and cash transactions as the original portfolio. The clone will not have a bank connection to                 avoid collisions during sync.
     * @param {PortfolioOperationsApiClonePortfolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOperationsApi
     */
    public clonePortfolio(requestParameters: PortfolioOperationsApiClonePortfolioRequest, options?: RawAxiosRequestConfig) {
        return PortfolioOperationsApiFp(this.configuration).clonePortfolio(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merge the \'source\' into the \'target\' portfolio. The \'source\' portfolio will be deleted after                 a successful merge. The bank connection related parameters (such as bank name or bank connection ID)                 will be taken over into the \'target portfolio. This means the \'target\' portfolio will be connected                 to a bank after the merge - if the \'source\' portfolio was connected to a bank before.                 The merge strategy is as follows:                 - investments, bookings and cash transactions from the \'source\' portfolio will be copied into the                   \'target\' portfolio.                 - for each investment, bookings from the \'source\' portfolio that come BEFORE any bookings in                   the \'target\' portfolio will be skipped.                 - all cash transactions that were imported from a bank will be copied; no matter when.                 
     * @param {PortfolioOperationsApiMergePortfoliosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOperationsApi
     */
    public mergePortfolios(requestParameters: PortfolioOperationsApiMergePortfoliosRequest, options?: RawAxiosRequestConfig) {
        return PortfolioOperationsApiFp(this.configuration).mergePortfolios(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

