import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteBankingApiConnectionIdRequest } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankingApiConnectionId = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteBankingApiConnectionIdRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankingApiConnectionId(params);
  return data;
};

export const useDeleteBankingApiConnectionId = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteBankingApiConnectionIdRequest
  >
) => {
  return useAuthMutation(deleteBankingApiConnectionId, options, false);
};
