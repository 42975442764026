import { isValid, isFuture } from "date-fns";
import { CreateOrUpdateTransactionParams } from "@api";

export const validateTransactionValues = (
  values: CreateOrUpdateTransactionParams
) => {
  const { amount, taxAmount, valueDate } = values;

  if (!valueDate) return false;
  const dateObject = new Date(valueDate);
  if (!isValid(dateObject) || isFuture(dateObject)) return false;
  if (amount === null || amount === undefined) return false;
  if (taxAmount === null || taxAmount === undefined) return false;
  if (taxAmount < 0) return false;

  return true;
};
