import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { ValidationError, LoadingProgressBar } from "@components";
import { useGetInvestments } from "@api/v1/hooks/useInvestments";
import { Question } from "@components/Wizard/Question";
import { StepContainer } from "@components/Wizard/StepContainer";
import { InvestmentToTransfer, Values } from "../DepotTransferWizard";
import { InvestmentsTable } from "./InvestmentsTable";

export interface SelectInvestmentsStepProps {
  onPrevious: () => void;
  stepNumber: number;
  isFirst: boolean;
  onNext: (value?: InvestmentToTransfer[]) => void;
  defaultValue?: InvestmentToTransfer[];
  validationError?: ValidationError;
  focus: boolean;
  questionKey?: string;
  context?: Partial<Values>;
}

export const SelectInvestmentsStep = ({
  stepNumber,
  onNext,
  onPrevious,
  isFirst,
  defaultValue,
  context,
  validationError,
}: SelectInvestmentsStepProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<InvestmentToTransfer[] | undefined>(
    defaultValue
  );

  const { data: investmentsResponse, isLoading } = useGetInvestments({
    accountId: context?.fromAccountId ? [context?.fromAccountId] : [],
    order: "name asc",
  });
  const id = "wizard-input";

  const investments = investmentsResponse?.investments ?? [];

  return (
    <StepContainer
      testId="select-investments-to-transfer-step"
      onNext={() => onNext(value)}
      onPrevious={onPrevious}
      isFirst={isFirst}
      dependency={value}
    >
      <Question
        htmlFor={id}
        stepNumber={stepNumber}
        question={t("depotTransfer.selectInvestmentsStep.question")}
      />
      {validationError && (
        <Alert severity="error">{validationError.message}</Alert>
      )}
      {!isLoading && investmentsResponse && investments.length > 0 && (
        <InvestmentsTable
          defaultValue={defaultValue}
          investments={investments}
          onChange={setValue}
        />
      )}
      {!isLoading &&
        (!investmentsResponse || investments.length === 0) &&
        t("depotTransfer.selectInvestmentsStep.noInvestments")}
      {isLoading && <LoadingProgressBar />}
    </StepContainer>
  );
};
