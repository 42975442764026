import { UseQueryOptions } from "react-query";
import {
  DividendsApiGetdividendTypeDividendInBatchRequest,
  DividendBatchResponse,
} from "@generated/apiv3/quotes";

import { buildDividendsApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getdividendTypeDividendInBatch = async (
  token: string | undefined,
  params: DividendsApiGetdividendTypeDividendInBatchRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.getdividendTypeDividendInBatch(params);
  return data;
};

export const useGetdividendTypeDividendInBatch = (
  params: DividendsApiGetdividendTypeDividendInBatchRequest,
  options?: UseQueryOptions<
    DividendBatchResponse,
    AxiosApiError,
    DividendBatchResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND_IN_BATCH, params],
    ({ token }) => getdividendTypeDividendInBatch(token, params),
    options
  );
};
