import { useQuery } from "react-query";
import { getTppWebFromById } from "@api/v1/methods";
import { TppWebForm } from "@api/v1/model";

export const useGetTppWebFromById = (
  id?: string,
  {
    onSuccess,
    enabled = true,
  }: { onSuccess?: (data: TppWebForm) => void; enabled?: boolean } = {}
) =>
  useQuery(["tpp-web-form-by-id", id!], () => getTppWebFromById(id!), {
    staleTime: Infinity,
    enabled: !!id && enabled,
    onSuccess,
  });
