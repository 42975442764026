import React from "react";
import { Box, Checkbox, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographyWithInfoIcon } from "@components/TypographyWithInfoIcon";
import { Account } from "@generated/apiv1";

type Props = {
  accounts?: Account[];
  checkedPortfolios: number[];
  onSelect: (ids: number[]) => void;
};

export const AccountsTable = ({
  accounts = [],
  checkedPortfolios = [],
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const areAllChecked = Boolean(
    checkedPortfolios.length && checkedPortfolios.length === accounts.length
  );

  const onCheckAll = () => {
    if (areAllChecked) {
      onSelect([]);
    } else {
      onSelect(accounts.map((item) => item.id) || []);
    }
  };

  const onCheck = (id: number) => {
    if (checkedPortfolios.includes(id)) {
      onSelect(
        checkedPortfolios.filter((checkPortfolioId) => checkPortfolioId !== id)
      );
    } else {
      onSelect([...checkedPortfolios, id]);
    }
  };

  return (
    <Grid
      container
      mb={8}
      columnSpacing={3}
      sx={{
        overflowY: "auto",
        maxHeight: 500,
      }}
    >
      <Grid item xs={5} py={1}>
        <Box display="flex" gap={3} alignItems="center" pl={3}>
          <Checkbox checked={areAllChecked} onClick={onCheckAll} />
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="caption" fontWeight={600}>
              {t("portfolioShare.modal.table.name")}
            </Typography>
            <TypographyWithInfoIcon
              tooltipText={t("portfolioShare.modal.table.selectAll")}
            >
              {""}
            </TypographyWithInfoIcon>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={7} pr={3} display="flex" alignItems="center">
        <Typography variant="caption" fontWeight={600}>
          {t("portfolioShare.modal.table.bank")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {accounts.map((item, index) => (
        <React.Fragment key={item.id}>
          <Grid
            item
            xs={5}
            pl={3}
            py={1}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => onCheck(item.id)}
          >
            <Box display="flex" alignItems="center" gap={3} pl={3}>
              <Checkbox checked={checkedPortfolios.includes(item.id)} />
              <Typography variant="body2" fontWeight={600} noWrap>
                {item.name}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={7}
            pr={3}
            onClick={() => onCheck(item.id)}
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography variant="body2" fontWeight={400} noWrap>
              {item.bankName}
            </Typography>
          </Grid>
          {index !== (accounts.length || 0 - 1) && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};
