import { UseQueryOptions } from "react-query";
import {
  SymbolsApiSearchSymbolV2Request,
  SymbolListV2,
} from "@generated/apiv1";

import { buildSymbolsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const searchSymbolV2 = async (
  token: string | undefined,
  params?: SymbolsApiSearchSymbolV2Request
) => {
  const apiInstance = buildSymbolsApi(token);
  const { data } = await apiInstance.searchSymbolV2(params);
  return data;
};

export const useSearchSymbolV2 = (
  params?: SymbolsApiSearchSymbolV2Request,
  options?: UseQueryOptions<SymbolListV2, AxiosApiError, SymbolListV2>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SYMBOLS_SEARCH_SYMBOL_V2, params],
    ({ token }) => searchSymbolV2(token, params),
    options
  );
};
