/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateMandatorConfigurationParams } from '../model';
// @ts-ignore
import { MandatorConfiguration } from '../model';
/**
 * MandatorConfigurationsApi - axios parameter creator
 * @export
 */
export const MandatorConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the configuration for the given mandator
         * @param {number} id ID of the mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorConfiguration: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMandatorConfiguration', 'id', id)
            const localVarPath = `/v1/mandatorConfigurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the configuration for the given mandator.
         * @param {number} id ID of the mandator.
         * @param {CreateOrUpdateMandatorConfigurationParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandatorConfiguration: async (id: number, body: CreateOrUpdateMandatorConfigurationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMandatorConfiguration', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMandatorConfiguration', 'body', body)
            const localVarPath = `/v1/mandatorConfigurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MandatorConfigurationsApi - functional programming interface
 * @export
 */
export const MandatorConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MandatorConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the configuration for the given mandator
         * @param {number} id ID of the mandator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatorConfiguration(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MandatorConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatorConfiguration(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorConfigurationsApi.getMandatorConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the configuration for the given mandator.
         * @param {number} id ID of the mandator.
         * @param {CreateOrUpdateMandatorConfigurationParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMandatorConfiguration(id: number, body: CreateOrUpdateMandatorConfigurationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MandatorConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMandatorConfiguration(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MandatorConfigurationsApi.updateMandatorConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MandatorConfigurationsApi - factory interface
 * @export
 */
export const MandatorConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MandatorConfigurationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the configuration for the given mandator
         * @param {MandatorConfigurationsApiGetMandatorConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatorConfiguration(requestParameters: MandatorConfigurationsApiGetMandatorConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<MandatorConfiguration> {
            return localVarFp.getMandatorConfiguration(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the configuration for the given mandator.
         * @param {MandatorConfigurationsApiUpdateMandatorConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMandatorConfiguration(requestParameters: MandatorConfigurationsApiUpdateMandatorConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<MandatorConfiguration> {
            return localVarFp.updateMandatorConfiguration(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMandatorConfiguration operation in MandatorConfigurationsApi.
 * @export
 * @interface MandatorConfigurationsApiGetMandatorConfigurationRequest
 */
export interface MandatorConfigurationsApiGetMandatorConfigurationRequest {
    /**
     * ID of the mandator
     * @type {number}
     * @memberof MandatorConfigurationsApiGetMandatorConfiguration
     */
    readonly id: number
}

/**
 * Request parameters for updateMandatorConfiguration operation in MandatorConfigurationsApi.
 * @export
 * @interface MandatorConfigurationsApiUpdateMandatorConfigurationRequest
 */
export interface MandatorConfigurationsApiUpdateMandatorConfigurationRequest {
    /**
     * ID of the mandator.
     * @type {number}
     * @memberof MandatorConfigurationsApiUpdateMandatorConfiguration
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateMandatorConfigurationParams}
     * @memberof MandatorConfigurationsApiUpdateMandatorConfiguration
     */
    readonly body: CreateOrUpdateMandatorConfigurationParams
}

/**
 * MandatorConfigurationsApi - object-oriented interface
 * @export
 * @class MandatorConfigurationsApi
 * @extends {BaseAPI}
 */
export class MandatorConfigurationsApi extends BaseAPI {
    /**
     * 
     * @summary Get the configuration for the given mandator
     * @param {MandatorConfigurationsApiGetMandatorConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorConfigurationsApi
     */
    public getMandatorConfiguration(requestParameters: MandatorConfigurationsApiGetMandatorConfigurationRequest, options?: RawAxiosRequestConfig) {
        return MandatorConfigurationsApiFp(this.configuration).getMandatorConfiguration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the configuration for the given mandator.
     * @param {MandatorConfigurationsApiUpdateMandatorConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MandatorConfigurationsApi
     */
    public updateMandatorConfiguration(requestParameters: MandatorConfigurationsApiUpdateMandatorConfigurationRequest, options?: RawAxiosRequestConfig) {
        return MandatorConfigurationsApiFp(this.configuration).updateMandatorConfiguration(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

