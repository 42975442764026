import { UseQueryOptions } from "react-query";
import {
  RateLimitApiGetBankTppWebFormRateLimitStatusRequest,
  BankRateLimit,
} from "@generated/apiv3";

import { buildRateLimitApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankTppWebFormRateLimitStatus = async (
  token: string | undefined,
  params: RateLimitApiGetBankTppWebFormRateLimitStatusRequest
) => {
  const apiInstance = buildRateLimitApi(token);
  const { data } = await apiInstance.getBankTppWebFormRateLimitStatus(params);
  return data;
};

export const useGetBankTppWebFormRateLimitStatus = (
  params: RateLimitApiGetBankTppWebFormRateLimitStatusRequest,
  options?: UseQueryOptions<BankRateLimit, AxiosApiError, BankRateLimit>
) => {
  return useAuthQuery(
    [CACHE_KEYS.RATELIMIT_GET_BANK_TPP_WEB_FORM_RATE_LIMIT_STATUS, params],
    ({ token }) => getBankTppWebFormRateLimitStatus(token, params),
    options
  );
};
