import React, { useMemo, useState } from "react";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { differenceInMonths, format, getYear } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import sortBy from "lodash/sortBy";
import {
  CategoryWithChildren,
  TransactionWithCategory,
} from "@features/cashflowAnalyzer/types";
import { CategorySelectModal } from "@features/cashflowAnalyzer/components/CategorySelectModal";
import { BarChartMode } from "@components/Chart/BarChart";
import { TransactionRow } from "@features/cashflowAnalyzer/components/TransactionRow";
import { Account } from "@generated/apiv1";
import { Tag } from "@components";

const getDisplayedPeriod = (
  mode: BarChartMode,
  period: Required<RangeType>
) => {
  const { startDate, endDate } = period;
  if (mode === "custom") {
    return `${format(startDate, "dd.MM.yyyy")} - ${format(
      endDate,
      "dd.MM.yyyy"
    )}`;
  }

  if (mode === "quarterly") {
    const month = startDate.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    return `Q${quarter} ${format(startDate, "yyyy")}`;
  }

  if (differenceInMonths(endDate, startDate) > 0) {
    return format(startDate, "yyyy");
  }

  return format(
    period.startDate,
    getYear(period.startDate) !== getYear(new Date()) ? "MMMM yyyy" : "MMMM"
  );
};

const getDisplayedTransactions = (
  transactions: Record<string, TransactionWithCategory[]>,
  categoryTree: Record<number, CategoryWithChildren>,
  selectedCategory: string[] | null,
  groupsDisplayed: number,
  incomeCategoryId: number = 320
) => {
  let transactionsToDisplay = Object.entries(transactions);
  if (!selectedCategory) return transactionsToDisplay.slice(0, groupsDisplayed);

  const parentCategories = selectedCategory
    .map((category) => categoryTree[Number(category)])
    .filter(Boolean);

  const categoriesToFilterBy = [
    ...selectedCategory,
    ...parentCategories
      .map(
        (parent) =>
          parent?.children?.map((category) => String(category.id)) || []
      )
      .flat(),
  ];

  if (categoriesToFilterBy?.length) {
    transactionsToDisplay = transactionsToDisplay.reduce(
      (acc, [date, transactions]) => {
        const filteredTransactions = transactions.filter((transaction) => {
          if ((transaction.amount || 0) > 0) {
            return selectedCategory.includes(String(incomeCategoryId));
          }
          if (!transaction.categoryId)
            return categoriesToFilterBy.includes("-1");
          return categoriesToFilterBy.includes(String(transaction.categoryId));
        });
        if (filteredTransactions.length > 0) {
          acc.push([date, sortBy(filteredTransactions, (t) => t.purpose)]);
        }
        return acc;
      },
      [] as [string, TransactionWithCategory[]][]
    );
  }

  return transactionsToDisplay.slice(0, groupsDisplayed);
};

type Props = {
  transactions: Record<string, TransactionWithCategory[]>;
  categoryTree: Record<number, CategoryWithChildren>;
  selectedCategory: string[] | null;
  resetCategorySelection: () => void;
  subPeriod: Required<RangeType>;
  mode: BarChartMode;
  accounts: Account[];
};

export const TransactionsTable = ({
  transactions,
  categoryTree,
  selectedCategory,
  resetCategorySelection,
  subPeriod,
  mode,
  accounts,
}: Props) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [openedTransaction, setOpenedTransaction] = useState<number | null>(
    null
  );
  const [transactionInModal, setTransactionInModal] =
    useState<TransactionWithCategory | null>(null);
  const [groupsDisplayed, setGroupsDisplayed] = React.useState(3);
  const incomeCategoryId = useMemo(
    () =>
      Object.values(categoryTree).find(
        (category) => category.name === "Einnahmen"
      )?.id,
    [categoryTree]
  );
  const selectedCategoryName = selectedCategory
    ? selectedCategory?.length > 1
      ? selectedCategory?.map((id) => categoryTree[Number(id)]?.name).join(", ")
      : categoryTree[Number(selectedCategory?.[0])]?.name
    : null;

  const displayedPeriod = useMemo(
    () => getDisplayedPeriod(mode, subPeriod),
    [subPeriod, mode]
  );

  const displayedTransactions = useMemo(
    () =>
      getDisplayedTransactions(
        transactions,
        categoryTree,
        selectedCategory,
        groupsDisplayed,
        incomeCategoryId
      ),
    [
      groupsDisplayed,
      transactions,
      selectedCategory,
      categoryTree,
      incomeCategoryId,
    ]
  );

  const dispalyMoreTransactions = () => {
    setGroupsDisplayed((prev) => prev + 3);
  };

  const handleTransactionClick = (
    event: React.MouseEvent<HTMLDivElement>,
    transactionId: number
  ) => {
    if ((event.target as HTMLDivElement).closest(".transaction-expanded"))
      return;

    setOpenedTransaction(
      openedTransaction === transactionId ? null : transactionId!
    );
  };

  return (
    <Box pb={4}>
      <Box
        display="flex"
        alignItems={isLessSm ? "flex-start" : "center"}
        flexDirection={isLessSm ? "column" : "row"}
        gap={2}
        justifyContent={
          selectedCategory && !isLessSm ? "space-between" : "flex-start"
        }
        mb={3}
        width="100%"
      >
        <Typography variant="subtitle1" fontWeight={600} lineHeight="30px">
          {t("cashflowAnalyzer.transactionsTable.title")} {displayedPeriod}
        </Typography>
        {selectedCategory && (
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body2">
              {t("cashflowAnalyzer.transactionsTable.filter")}:{" "}
            </Typography>
            <Tag
              text={selectedCategoryName || "Nicht Klassifiziert"}
              variant="default"
              backgroundColor={
                categoryTree[Number(selectedCategory?.[0])]?.backgroundColor
              }
              color={categoryTree[Number(selectedCategory?.[0])]?.color}
              onTagRemove={resetCategorySelection}
            />
          </Box>
        )}
      </Box>

      <Divider sx={{ marginBottom: "16px" }} />
      {displayedTransactions.length === 0 && (
        <Typography variant="body1" fontWeight={400}>
          {t("cashflowAnalyzer.transactionsTable.noData")}
        </Typography>
      )}
      <InfiniteScroll
        next={dispalyMoreTransactions}
        dataLength={displayedTransactions.length}
        hasMore
        loader={
          displayedTransactions.length === Object.keys(transactions).length
        }
      >
        {displayedTransactions.map(([date, transactions]) => (
          <Box key={date} mb={3}>
            <Typography variant="caption" color="textSecondary" mb={2}>
              {format(new Date(date), "dd.MM.yyyy")}
            </Typography>
            {transactions.map((transaction) => (
              <Box
                onClick={(event) =>
                  handleTransactionClick(event, transaction.id!)
                }
                key={transaction.id}
              >
                <TransactionRow
                  transaction={transaction}
                  key={transaction.id}
                  onCategoryClick={setTransactionInModal}
                  isOpen={openedTransaction === transaction.id}
                  accountName={
                    accounts.find(
                      (account) => account.id === transaction.accountId
                    )?.name
                  }
                />
              </Box>
            ))}
            <Divider />
          </Box>
        ))}
      </InfiniteScroll>
      {transactionInModal && (
        <CategorySelectModal
          categoryTree={categoryTree}
          isOpen
          onClose={() => setTransactionInModal(null)}
          transaction={transactionInModal}
        />
      )}
    </Box>
  );
};
