/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccountHolder } from '../model';
// @ts-ignore
import { AccountHolderMayUpdateName } from '../model';
// @ts-ignore
import { AccountHolderPayoutStatisticList } from '../model';
// @ts-ignore
import { CreateOrUpdateAccountHolderParams } from '../model';
// @ts-ignore
import { CreateOrUpdateBankAccountParams } from '../model';
// @ts-ignore
import { CreateOrUpdateCustomFeeAgreementParams } from '../model';
// @ts-ignore
import { CreateOrUpdateInvestmentExperienceParams } from '../model';
// @ts-ignore
import { PageableAccountHolderList } from '../model';
/**
 * AccountHoldersApi - axios parameter creator
 * @export
 */
export const AccountHoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get payout statistics for all account holders
         * @param {Array<string>} [subscriptionStatus] \&quot;\&quot;Only return account holders having at least one subscription in the given status(es);                         default is to not apply a status filter
         * @param {string} [mandatorPublicId] Only display data for the given mandator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountHolderPayoutStatistics: async (subscriptionStatus?: Array<string>, mandatorPublicId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accountHolders/payoutStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionStatus) {
                localVarQueryParameter['subscriptionStatus'] = subscriptionStatus;
            }

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an account holder
         * @param {number} id ID of the account holder to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountHoldersId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountHoldersId', 'id', id)
            const localVarPath = `/v1/accountHolders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an accountHolder.
         * @param {CreateOrUpdateAccountHolderParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountHolder: async (body: CreateOrUpdateAccountHolderParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAccountHolder', 'body', body)
            const localVarPath = `/v1/accountHolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the given account holder.
         * @param {number} id ID of the account holder to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountHolder: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAccountHolder', 'id', id)
            const localVarPath = `/v1/accountHolders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of accounts holders
         * @param {string} [search] A custom search string; retrieves account holders matching&lt;br&gt;                         - the natural persons name(s)&lt;br&gt;                         - the external ID&lt;br&gt;                         - the bank accounts IBAN&lt;br&gt;                         - the email address of the underlying user&lt;br&gt;                         - the given depot identifier (Like search)&lt;br&gt;                      
         * @param {Array<number>} [id] Retrieve the given account holders
         * @param {Array<number>} [userId] Retrieve account holders for the given user(s)
         * @param {Array<string>} [externalId] Retrieve account holders having the given external IDs
         * @param {Array<ListAndSearchAllAccountHoldersTypeEnum>} [type] Retrieve account holders having the given types
         * @param {boolean} [externalIdAssigned] Retrieve account holders having / not having an external ID; default is to ignore the parameter
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. Allowed params: id, externalId, externalIdAssigned.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccountHolders: async (search?: string, id?: Array<number>, userId?: Array<number>, externalId?: Array<string>, type?: Array<ListAndSearchAllAccountHoldersTypeEnum>, externalIdAssigned?: boolean, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accountHolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }

            if (externalId) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (externalIdAssigned !== undefined) {
                localVarQueryParameter['externalIdAssigned'] = externalIdAssigned;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if it is still possible to update the name of the associated natural person(s).
         * @param {number} id ID of the AccountHolder to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mayUpdateName: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mayUpdateName', 'id', id)
            const localVarPath = `/v1/accountHolders/{id}/mayUpdateName`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the accountHolder with the given ID. Users can only update their own account holders.                      Subscription manager and accountants may update all account holders in their mandators scope.                      Also, they may change the user this account holder belongs to.
         * @param {number} id ID of the accountHolder to update
         * @param {CreateOrUpdateAccountHolderParams} body The account holder parameters details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountHolder: async (id: number, body: CreateOrUpdateAccountHolderParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccountHolder', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAccountHolder', 'body', body)
            const localVarPath = `/v1/accountHolders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update or create the custom fee agreement for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we create or update the custom fee agreement attributes
         * @param {CreateOrUpdateCustomFeeAgreementParams} body The custom fee agreement parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFeeAgreement: async (id: number, body: CreateOrUpdateCustomFeeAgreementParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomFeeAgreement', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCustomFeeAgreement', 'body', body)
            const localVarPath = `/v1/accountHolders/{id}/customFeeAgreement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the investment experience attributes for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we update the investment experience attributes
         * @param {CreateOrUpdateInvestmentExperienceParams} body The investment experience parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentExperience: async (id: number, body: CreateOrUpdateInvestmentExperienceParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInvestmentExperience', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateInvestmentExperience', 'body', body)
            const localVarPath = `/v1/accountHolders/{id}/investmentExperience`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the reference account for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we update the investment experience attributes
         * @param {CreateOrUpdateBankAccountParams} body The reference account parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceAccount: async (id: number, body: CreateOrUpdateBankAccountParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReferenceAccount', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateReferenceAccount', 'body', body)
            const localVarPath = `/v1/accountHolders/{id}/referenceAccount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountHoldersApi - functional programming interface
 * @export
 */
export const AccountHoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountHoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get payout statistics for all account holders
         * @param {Array<string>} [subscriptionStatus] \&quot;\&quot;Only return account holders having at least one subscription in the given status(es);                         default is to not apply a status filter
         * @param {string} [mandatorPublicId] Only display data for the given mandator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountHolderPayoutStatistics(subscriptionStatus?: Array<string>, mandatorPublicId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolderPayoutStatisticList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountHolderPayoutStatistics(subscriptionStatus, mandatorPublicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.accountHolderPayoutStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an account holder
         * @param {number} id ID of the account holder to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountHoldersId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountHoldersId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.accountHoldersId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create an accountHolder.
         * @param {CreateOrUpdateAccountHolderParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountHolder(body: CreateOrUpdateAccountHolderParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccountHolder(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.createAccountHolder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the given account holder.
         * @param {number} id ID of the account holder to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountHolder(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccountHolder(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.deleteAccountHolder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of accounts holders
         * @param {string} [search] A custom search string; retrieves account holders matching&lt;br&gt;                         - the natural persons name(s)&lt;br&gt;                         - the external ID&lt;br&gt;                         - the bank accounts IBAN&lt;br&gt;                         - the email address of the underlying user&lt;br&gt;                         - the given depot identifier (Like search)&lt;br&gt;                      
         * @param {Array<number>} [id] Retrieve the given account holders
         * @param {Array<number>} [userId] Retrieve account holders for the given user(s)
         * @param {Array<string>} [externalId] Retrieve account holders having the given external IDs
         * @param {Array<ListAndSearchAllAccountHoldersTypeEnum>} [type] Retrieve account holders having the given types
         * @param {boolean} [externalIdAssigned] Retrieve account holders having / not having an external ID; default is to ignore the parameter
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. Allowed params: id, externalId, externalIdAssigned.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllAccountHolders(search?: string, id?: Array<number>, userId?: Array<number>, externalId?: Array<string>, type?: Array<ListAndSearchAllAccountHoldersTypeEnum>, externalIdAssigned?: boolean, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableAccountHolderList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllAccountHolders(search, id, userId, externalId, type, externalIdAssigned, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.listAndSearchAllAccountHolders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if it is still possible to update the name of the associated natural person(s).
         * @param {number} id ID of the AccountHolder to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mayUpdateName(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolderMayUpdateName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mayUpdateName(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.mayUpdateName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the accountHolder with the given ID. Users can only update their own account holders.                      Subscription manager and accountants may update all account holders in their mandators scope.                      Also, they may change the user this account holder belongs to.
         * @param {number} id ID of the accountHolder to update
         * @param {CreateOrUpdateAccountHolderParams} body The account holder parameters details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountHolder(id: number, body: CreateOrUpdateAccountHolderParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountHolder(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.updateAccountHolder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update or create the custom fee agreement for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we create or update the custom fee agreement attributes
         * @param {CreateOrUpdateCustomFeeAgreementParams} body The custom fee agreement parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomFeeAgreement(id: number, body: CreateOrUpdateCustomFeeAgreementParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomFeeAgreement(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.updateCustomFeeAgreement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the investment experience attributes for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we update the investment experience attributes
         * @param {CreateOrUpdateInvestmentExperienceParams} body The investment experience parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentExperience(id: number, body: CreateOrUpdateInvestmentExperienceParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentExperience(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.updateInvestmentExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the reference account for the accountHolder with the given ID.
         * @param {number} id ID of the accountHolder for which we update the investment experience attributes
         * @param {CreateOrUpdateBankAccountParams} body The reference account parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferenceAccount(id: number, body: CreateOrUpdateBankAccountParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferenceAccount(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountHoldersApi.updateReferenceAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountHoldersApi - factory interface
 * @export
 */
export const AccountHoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountHoldersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get payout statistics for all account holders
         * @param {AccountHoldersApiAccountHolderPayoutStatisticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountHolderPayoutStatistics(requestParameters: AccountHoldersApiAccountHolderPayoutStatisticsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolderPayoutStatisticList> {
            return localVarFp.accountHolderPayoutStatistics(requestParameters.subscriptionStatus, requestParameters.mandatorPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an account holder
         * @param {AccountHoldersApiAccountHoldersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountHoldersId(requestParameters: AccountHoldersApiAccountHoldersIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.accountHoldersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an accountHolder.
         * @param {AccountHoldersApiCreateAccountHolderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountHolder(requestParameters: AccountHoldersApiCreateAccountHolderRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.createAccountHolder(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the given account holder.
         * @param {AccountHoldersApiDeleteAccountHolderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountHolder(requestParameters: AccountHoldersApiDeleteAccountHolderRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.deleteAccountHolder(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of accounts holders
         * @param {AccountHoldersApiListAndSearchAllAccountHoldersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccountHolders(requestParameters: AccountHoldersApiListAndSearchAllAccountHoldersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableAccountHolderList> {
            return localVarFp.listAndSearchAllAccountHolders(requestParameters.search, requestParameters.id, requestParameters.userId, requestParameters.externalId, requestParameters.type, requestParameters.externalIdAssigned, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if it is still possible to update the name of the associated natural person(s).
         * @param {AccountHoldersApiMayUpdateNameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mayUpdateName(requestParameters: AccountHoldersApiMayUpdateNameRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolderMayUpdateName> {
            return localVarFp.mayUpdateName(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the accountHolder with the given ID. Users can only update their own account holders.                      Subscription manager and accountants may update all account holders in their mandators scope.                      Also, they may change the user this account holder belongs to.
         * @param {AccountHoldersApiUpdateAccountHolderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountHolder(requestParameters: AccountHoldersApiUpdateAccountHolderRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.updateAccountHolder(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update or create the custom fee agreement for the accountHolder with the given ID.
         * @param {AccountHoldersApiUpdateCustomFeeAgreementRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFeeAgreement(requestParameters: AccountHoldersApiUpdateCustomFeeAgreementRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.updateCustomFeeAgreement(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the investment experience attributes for the accountHolder with the given ID.
         * @param {AccountHoldersApiUpdateInvestmentExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentExperience(requestParameters: AccountHoldersApiUpdateInvestmentExperienceRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.updateInvestmentExperience(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the reference account for the accountHolder with the given ID.
         * @param {AccountHoldersApiUpdateReferenceAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceAccount(requestParameters: AccountHoldersApiUpdateReferenceAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountHolder> {
            return localVarFp.updateReferenceAccount(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountHolderPayoutStatistics operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiAccountHolderPayoutStatisticsRequest
 */
export interface AccountHoldersApiAccountHolderPayoutStatisticsRequest {
    /**
     * \&quot;\&quot;Only return account holders having at least one subscription in the given status(es);                         default is to not apply a status filter
     * @type {Array<string>}
     * @memberof AccountHoldersApiAccountHolderPayoutStatistics
     */
    readonly subscriptionStatus?: Array<string>

    /**
     * Only display data for the given mandator.
     * @type {string}
     * @memberof AccountHoldersApiAccountHolderPayoutStatistics
     */
    readonly mandatorPublicId?: string
}

/**
 * Request parameters for accountHoldersId operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiAccountHoldersIdRequest
 */
export interface AccountHoldersApiAccountHoldersIdRequest {
    /**
     * ID of the account holder to retrieve
     * @type {number}
     * @memberof AccountHoldersApiAccountHoldersId
     */
    readonly id: number
}

/**
 * Request parameters for createAccountHolder operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiCreateAccountHolderRequest
 */
export interface AccountHoldersApiCreateAccountHolderRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateAccountHolderParams}
     * @memberof AccountHoldersApiCreateAccountHolder
     */
    readonly body: CreateOrUpdateAccountHolderParams
}

/**
 * Request parameters for deleteAccountHolder operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiDeleteAccountHolderRequest
 */
export interface AccountHoldersApiDeleteAccountHolderRequest {
    /**
     * ID of the account holder to delete
     * @type {number}
     * @memberof AccountHoldersApiDeleteAccountHolder
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllAccountHolders operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiListAndSearchAllAccountHoldersRequest
 */
export interface AccountHoldersApiListAndSearchAllAccountHoldersRequest {
    /**
     * A custom search string; retrieves account holders matching&lt;br&gt;                         - the natural persons name(s)&lt;br&gt;                         - the external ID&lt;br&gt;                         - the bank accounts IBAN&lt;br&gt;                         - the email address of the underlying user&lt;br&gt;                         - the given depot identifier (Like search)&lt;br&gt;                      
     * @type {string}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly search?: string

    /**
     * Retrieve the given account holders
     * @type {Array<number>}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly id?: Array<number>

    /**
     * Retrieve account holders for the given user(s)
     * @type {Array<number>}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly userId?: Array<number>

    /**
     * Retrieve account holders having the given external IDs
     * @type {Array<string>}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly externalId?: Array<string>

    /**
     * Retrieve account holders having the given types
     * @type {Array<'PRIVATE' | 'COMPANY'>}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly type?: Array<ListAndSearchAllAccountHoldersTypeEnum>

    /**
     * Retrieve account holders having / not having an external ID; default is to ignore the parameter
     * @type {boolean}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly externalIdAssigned?: boolean

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. Allowed params: id, externalId, externalIdAssigned.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof AccountHoldersApiListAndSearchAllAccountHolders
     */
    readonly order?: string
}

/**
 * Request parameters for mayUpdateName operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiMayUpdateNameRequest
 */
export interface AccountHoldersApiMayUpdateNameRequest {
    /**
     * ID of the AccountHolder to check
     * @type {number}
     * @memberof AccountHoldersApiMayUpdateName
     */
    readonly id: number
}

/**
 * Request parameters for updateAccountHolder operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiUpdateAccountHolderRequest
 */
export interface AccountHoldersApiUpdateAccountHolderRequest {
    /**
     * ID of the accountHolder to update
     * @type {number}
     * @memberof AccountHoldersApiUpdateAccountHolder
     */
    readonly id: number

    /**
     * The account holder parameters details
     * @type {CreateOrUpdateAccountHolderParams}
     * @memberof AccountHoldersApiUpdateAccountHolder
     */
    readonly body: CreateOrUpdateAccountHolderParams
}

/**
 * Request parameters for updateCustomFeeAgreement operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiUpdateCustomFeeAgreementRequest
 */
export interface AccountHoldersApiUpdateCustomFeeAgreementRequest {
    /**
     * ID of the accountHolder for which we create or update the custom fee agreement attributes
     * @type {number}
     * @memberof AccountHoldersApiUpdateCustomFeeAgreement
     */
    readonly id: number

    /**
     * The custom fee agreement parameters
     * @type {CreateOrUpdateCustomFeeAgreementParams}
     * @memberof AccountHoldersApiUpdateCustomFeeAgreement
     */
    readonly body: CreateOrUpdateCustomFeeAgreementParams
}

/**
 * Request parameters for updateInvestmentExperience operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiUpdateInvestmentExperienceRequest
 */
export interface AccountHoldersApiUpdateInvestmentExperienceRequest {
    /**
     * ID of the accountHolder for which we update the investment experience attributes
     * @type {number}
     * @memberof AccountHoldersApiUpdateInvestmentExperience
     */
    readonly id: number

    /**
     * The investment experience parameters
     * @type {CreateOrUpdateInvestmentExperienceParams}
     * @memberof AccountHoldersApiUpdateInvestmentExperience
     */
    readonly body: CreateOrUpdateInvestmentExperienceParams
}

/**
 * Request parameters for updateReferenceAccount operation in AccountHoldersApi.
 * @export
 * @interface AccountHoldersApiUpdateReferenceAccountRequest
 */
export interface AccountHoldersApiUpdateReferenceAccountRequest {
    /**
     * ID of the accountHolder for which we update the investment experience attributes
     * @type {number}
     * @memberof AccountHoldersApiUpdateReferenceAccount
     */
    readonly id: number

    /**
     * The reference account parameters
     * @type {CreateOrUpdateBankAccountParams}
     * @memberof AccountHoldersApiUpdateReferenceAccount
     */
    readonly body: CreateOrUpdateBankAccountParams
}

/**
 * AccountHoldersApi - object-oriented interface
 * @export
 * @class AccountHoldersApi
 * @extends {BaseAPI}
 */
export class AccountHoldersApi extends BaseAPI {
    /**
     * 
     * @summary Get payout statistics for all account holders
     * @param {AccountHoldersApiAccountHolderPayoutStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public accountHolderPayoutStatistics(requestParameters: AccountHoldersApiAccountHolderPayoutStatisticsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).accountHolderPayoutStatistics(requestParameters.subscriptionStatus, requestParameters.mandatorPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an account holder
     * @param {AccountHoldersApiAccountHoldersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public accountHoldersId(requestParameters: AccountHoldersApiAccountHoldersIdRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).accountHoldersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an accountHolder.
     * @param {AccountHoldersApiCreateAccountHolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public createAccountHolder(requestParameters: AccountHoldersApiCreateAccountHolderRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).createAccountHolder(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the given account holder.
     * @param {AccountHoldersApiDeleteAccountHolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public deleteAccountHolder(requestParameters: AccountHoldersApiDeleteAccountHolderRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).deleteAccountHolder(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of accounts holders
     * @param {AccountHoldersApiListAndSearchAllAccountHoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public listAndSearchAllAccountHolders(requestParameters: AccountHoldersApiListAndSearchAllAccountHoldersRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).listAndSearchAllAccountHolders(requestParameters.search, requestParameters.id, requestParameters.userId, requestParameters.externalId, requestParameters.type, requestParameters.externalIdAssigned, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if it is still possible to update the name of the associated natural person(s).
     * @param {AccountHoldersApiMayUpdateNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public mayUpdateName(requestParameters: AccountHoldersApiMayUpdateNameRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).mayUpdateName(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the accountHolder with the given ID. Users can only update their own account holders.                      Subscription manager and accountants may update all account holders in their mandators scope.                      Also, they may change the user this account holder belongs to.
     * @param {AccountHoldersApiUpdateAccountHolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public updateAccountHolder(requestParameters: AccountHoldersApiUpdateAccountHolderRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).updateAccountHolder(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update or create the custom fee agreement for the accountHolder with the given ID.
     * @param {AccountHoldersApiUpdateCustomFeeAgreementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public updateCustomFeeAgreement(requestParameters: AccountHoldersApiUpdateCustomFeeAgreementRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).updateCustomFeeAgreement(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the investment experience attributes for the accountHolder with the given ID.
     * @param {AccountHoldersApiUpdateInvestmentExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public updateInvestmentExperience(requestParameters: AccountHoldersApiUpdateInvestmentExperienceRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).updateInvestmentExperience(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the reference account for the accountHolder with the given ID.
     * @param {AccountHoldersApiUpdateReferenceAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountHoldersApi
     */
    public updateReferenceAccount(requestParameters: AccountHoldersApiUpdateReferenceAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountHoldersApiFp(this.configuration).updateReferenceAccount(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllAccountHoldersTypeEnum = {
    PRIVATE: 'PRIVATE',
    COMPANY: 'COMPANY'
} as const;
export type ListAndSearchAllAccountHoldersTypeEnum = typeof ListAndSearchAllAccountHoldersTypeEnum[keyof typeof ListAndSearchAllAccountHoldersTypeEnum];
