import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BookingTypeEnum, DepotSynchronizationStatusEnum } from "@api";
import { toRoundedNumberOfLots, toCurrency } from "@helpers";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TypeWithNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const TypeIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[800]};
  padding: 1px 2px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const TypeWithName = ({ logItem }: { logItem: LogItem }) => {
  const { t } = useTranslation();
  const wasRolledBack = !logItem.type && logItem.bookingId;

  if (logItem.synchronizationStatus === "FAILED") {
    return (
      <TypeWithNameContainer>
        <TypeIcon>{t("synchronizationLog.checkRequired")}</TypeIcon>
        <Typography variant="body1">{logItem.name}</Typography>
      </TypeWithNameContainer>
    );
  }

  return (
    <TypeWithNameContainer>
      <TypeIcon
        data-testid={`log-item-${wasRolledBack ? "rolled-back" : "new"}`}
      >
        {wasRolledBack
          ? t("synchronizationLog.rolledBack")
          : t("synchronizationLog.new")}
      </TypeIcon>
      <Typography variant="body1">
        {wasRolledBack ? "" : `${t(`bookingTypes.${logItem.type}`)}:`}
        {logItem.name}
      </Typography>
    </TypeWithNameContainer>
  );
};

export interface LogItem {
  id: number;
  synchronizationStatus: DepotSynchronizationStatusEnum;
  bookingId?: number;
  investmentId?: number;
  isin?: string;
  name?: string;
  numberOfLots?: number;
  amount?: number;
  currency?: string;
  type?: BookingTypeEnum;
}

export interface SynchronizationLogItemProps {
  logItem: LogItem;
}

export const SynchronizationLogItem = ({
  logItem,
}: SynchronizationLogItemProps) => {
  const wasRolledBack = !logItem.type && logItem.bookingId;

  return (
    <Container>
      <div>
        <TypeWithName logItem={logItem} />
        {!wasRolledBack && logItem.synchronizationStatus === "SUCCESS" && (
          <>
            {(logItem.type === "buy" || logItem.type === "sell") && (
              <Typography variant="body2">
                {toRoundedNumberOfLots(logItem.numberOfLots!)}
              </Typography>
            )}
            {logItem.type === "dividend" && (
              <Typography variant="body2">
                {toCurrency(logItem.amount, logItem.currency)}
              </Typography>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
