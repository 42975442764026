import { UseMutationOptions } from "react-query";
import {
  WatchlistEntriesApiCreateWatchlistEntryRequest,
  WatchlistEntry,
} from "@generated/apiv1";

import { buildWatchlistEntriesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createWatchlistEntry = async (
  token: string | undefined,
  params: WatchlistEntriesApiCreateWatchlistEntryRequest
) => {
  const apiInstance = buildWatchlistEntriesApi(token);
  const { data } = await apiInstance.createWatchlistEntry(params);
  return data;
};

export const useCreateWatchlistEntry = (
  options?: UseMutationOptions<
    WatchlistEntry,
    AxiosApiError,
    WatchlistEntriesApiCreateWatchlistEntryRequest
  >
) => {
  return useAuthMutation(createWatchlistEntry, options, false);
};
