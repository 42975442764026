import { UseMutationOptions } from "react-query";
import {
  SubcategoriesApiCreateSubcategoryRequest,
  Subcategory,
} from "@generated/apiv3";

import { buildSubcategoriesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createSubcategory = async (
  token: string | undefined,
  params: SubcategoriesApiCreateSubcategoryRequest
) => {
  const apiInstance = buildSubcategoriesApi(token);
  const { data } = await apiInstance.createSubcategory(params);
  return data;
};

export const useCreateSubcategory = (
  options?: UseMutationOptions<
    Subcategory,
    AxiosApiError,
    SubcategoriesApiCreateSubcategoryRequest
  >
) => {
  return useAuthMutation(createSubcategory, options, false);
};
