import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { InvestmentReference } from "@generated/apiv1";
import { SecurityImage } from "@components/SecurityImage";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { DividendStatus } from "@features/dividendPlanner/components/DividendStatus";
import { Badge, TypographyWithInfoIcon } from "@components";
import { BlurredTypography } from "@components/BlurredTypography";
import { toCurrency, toGermanNumberFormat } from "@helpers";
import { Dividend } from "@features/dividendPlanner/components/DividendsTable/useDividendsForTable";
import { DividendFrequency } from "@features/dividendPlanner/components/DividendFrequency";

const Header = ({ text, tooltip }: { text: string; tooltip?: string }) => (
  <Grid item xs={4} display="flex" alignItems="center">
    {tooltip ? (
      <TypographyWithInfoIcon
        tooltipText={tooltip}
        variant="body2"
        fontWeight={600}
        iconSize={16}
      >
        {text}
      </TypographyWithInfoIcon>
    ) : (
      <Typography variant="body2" fontWeight={600}>
        {text}
      </Typography>
    )}
  </Grid>
);

const Text = styled(Grid)(({ theme }) => ({
  textAlign: "right",
  minHeight: "28px",
}));

type Props = {
  dividend: Dividend;
  investment?: InvestmentReference;
  view: "modal" | "calendar" | "table";
  padding?: number;
};

export const FullViewDividendCell = ({
  dividend,
  investment,
  view,
  padding,
}: Props) => {
  const { images, isUserPro } = useDividendsContext();
  const { t } = useTranslation();
  const shouldBlur = dividend.isPrediction && !isUserPro;

  return (
    <Box pr={shouldBlur ? "8px" : 0} pt={4} px={padding || 0}>
      <Box display="flex" alignItems="center" gap={3} mb={5}>
        <SecurityImage
          src={images?.[investment?.isin!]}
          sx={{
            borderRadius: "10px",
          }}
        />
        <Typography variant="body2">{investment?.standardisedName}</Typography>
      </Box>
      <Grid container spacing={4}>
        <Header text={t("dividends.table.status")} />
        <Text item xs={8}>
          <DividendStatus dividend={dividend} />
        </Text>

        <Header
          text={t("dividends.table.exDate")}
          tooltip={t("dividends.table.exDateTooltip")}
        />
        <Text item xs={8}>
          <BlurredTypography shouldBlur={shouldBlur} variant="body2" blur={6}>
            {dividend.exDate && format(new Date(dividend.exDate), "dd.MM.yyyy")}
          </BlurredTypography>
        </Text>

        <Header
          text={t("dividends.table.paymentDate")}
          tooltip={t("dividends.table.paymentDateTooltip")}
        />
        <Text item xs={8}>
          <BlurredTypography shouldBlur={shouldBlur} variant="body2" blur={6}>
            {dividend.paymentDate &&
              format(new Date(dividend.paymentDate), "dd.MM.yyyy")}
          </BlurredTypography>
        </Text>

        {view === "calendar" ? (
          <>
            <Header text={t("dividends.table.frequency")} />
            <Text item xs={8}>
              <DividendFrequency dividend={dividend} />
            </Text>
          </>
        ) : (
          <>
            <Header text={t("dividends.table.numberOfLots")} />
            <Text item xs={8}>
              {dividend.numberOfLots && (
                <Badge>{toGermanNumberFormat(dividend.numberOfLots)}x</Badge>
              )}
            </Text>

            <Header text={t("dividends.table.price")} />
            <Text item xs={8}>
              <BlurredTypography
                shouldBlur={shouldBlur}
                variant="body2"
                blur={6}
              >
                {toCurrency(dividend.grossAmountPerShare)}
              </BlurredTypography>
            </Text>
          </>
        )}

        <Header text={t("dividends.table.sum")} />
        <Text item xs={8}>
          <BlurredTypography shouldBlur={shouldBlur} variant="body2" blur={6}>
            {toCurrency(dividend.grossAmount)}
          </BlurredTypography>
        </Text>
      </Grid>
    </Box>
  );
};
