/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Category } from '../model';
// @ts-ignore
import { CategoryList } from '../model';
// @ts-ignore
import { CreateCategory } from '../model';
// @ts-ignore
import { UpdateCategory } from '../model';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new category
         * @param {CreateCategory} createCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crateCategory: async (createCategory: CreateCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategory' is not null or undefined
            assertParamExists('crateCategory', 'createCategory', createCategory)
            const localVarPath = `/api/v3/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/api/v3/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary filter categories
         * @param {string} [filterName] Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
         * @param {string} [filterCategoryType] Comma separated values.Example: \&#39;filter[category_type]&#x3D;type1,type2\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (filterName?: string, filterCategoryType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterCategoryType !== undefined) {
                localVarQueryParameter['filter[category_type]'] = filterCategoryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategory', 'id', id)
            const localVarPath = `/api/v3/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateCategory} updateCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (id: number, updateCategory: UpdateCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCategory', 'id', id)
            // verify required parameter 'updateCategory' is not null or undefined
            assertParamExists('updateCategory', 'updateCategory', updateCategory)
            const localVarPath = `/api/v3/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new category
         * @param {CreateCategory} createCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crateCategory(createCategory: CreateCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crateCategory(createCategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.crateCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.deleteCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary filter categories
         * @param {string} [filterName] Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
         * @param {string} [filterCategoryType] Comma separated values.Example: \&#39;filter[category_type]&#x3D;type1,type2\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(filterName?: string, filterCategoryType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(filterName, filterCategoryType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.getCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.getCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a category
         * @param {number} id The category ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateCategory} updateCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(id: number, updateCategory: UpdateCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(id, updateCategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.updateCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary create new category
         * @param {CategoriesApiCrateCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crateCategory(requestParameters: CategoriesApiCrateCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.crateCategory(requestParameters.createCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a category
         * @param {CategoriesApiDeleteCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(requestParameters: CategoriesApiDeleteCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary filter categories
         * @param {CategoriesApiGetCategoriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(requestParameters: CategoriesApiGetCategoriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CategoryList> {
            return localVarFp.getCategories(requestParameters.filterName, requestParameters.filterCategoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a category
         * @param {CategoriesApiGetCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(requestParameters: CategoriesApiGetCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.getCategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a category
         * @param {CategoriesApiUpdateCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(requestParameters: CategoriesApiUpdateCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.updateCategory(requestParameters.id, requestParameters.updateCategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for crateCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCrateCategoryRequest
 */
export interface CategoriesApiCrateCategoryRequest {
    /**
     * 
     * @type {CreateCategory}
     * @memberof CategoriesApiCrateCategory
     */
    readonly createCategory: CreateCategory
}

/**
 * Request parameters for deleteCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiDeleteCategoryRequest
 */
export interface CategoriesApiDeleteCategoryRequest {
    /**
     * The category ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof CategoriesApiDeleteCategory
     */
    readonly id: number
}

/**
 * Request parameters for getCategories operation in CategoriesApi.
 * @export
 * @interface CategoriesApiGetCategoriesRequest
 */
export interface CategoriesApiGetCategoriesRequest {
    /**
     * Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
     * @type {string}
     * @memberof CategoriesApiGetCategories
     */
    readonly filterName?: string

    /**
     * Comma separated values.Example: \&#39;filter[category_type]&#x3D;type1,type2\&#39;
     * @type {string}
     * @memberof CategoriesApiGetCategories
     */
    readonly filterCategoryType?: string
}

/**
 * Request parameters for getCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiGetCategoryRequest
 */
export interface CategoriesApiGetCategoryRequest {
    /**
     * The category ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof CategoriesApiGetCategory
     */
    readonly id: number
}

/**
 * Request parameters for updateCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiUpdateCategoryRequest
 */
export interface CategoriesApiUpdateCategoryRequest {
    /**
     * The category ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof CategoriesApiUpdateCategory
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCategory}
     * @memberof CategoriesApiUpdateCategory
     */
    readonly updateCategory: UpdateCategory
}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary create new category
     * @param {CategoriesApiCrateCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public crateCategory(requestParameters: CategoriesApiCrateCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).crateCategory(requestParameters.createCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a category
     * @param {CategoriesApiDeleteCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public deleteCategory(requestParameters: CategoriesApiDeleteCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).deleteCategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary filter categories
     * @param {CategoriesApiGetCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategories(requestParameters: CategoriesApiGetCategoriesRequest = {}, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).getCategories(requestParameters.filterName, requestParameters.filterCategoryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a category
     * @param {CategoriesApiGetCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategory(requestParameters: CategoriesApiGetCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).getCategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a category
     * @param {CategoriesApiUpdateCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public updateCategory(requestParameters: CategoriesApiUpdateCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).updateCategory(requestParameters.id, requestParameters.updateCategory, options).then((request) => request(this.axios, this.basePath));
    }
}

