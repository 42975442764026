import { UseMutationOptions } from "react-query";
import { CategoriesApiDeleteCategoryRequest } from "@generated/apiv1";

import { buildCategoriesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteCategory = async (
  token: string | undefined,
  params: CategoriesApiDeleteCategoryRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.deleteCategory(params);
  return data;
};

export const useDeleteCategory = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    CategoriesApiDeleteCategoryRequest
  >
) => {
  return useAuthMutation(deleteCategory, options, false);
};
