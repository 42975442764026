/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateSepaTransactionParams
 */
export interface CreateSepaTransactionParams {
    /**
     * An external ID; usually provided by the multibanking-API
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'externalId'?: string;
    /**
     * A reference (ID), typically provided by the bank
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'externalReference'?: string;
    /**
     * The (technical) transaction type
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'type': CreateSepaTransactionParamsTypeEnum;
    /**
     * The (non-technical) transaction type as delivered by the bank. If not provided,         the given type will be use as literal type.
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'literalType'?: string;
    /**
     * The ZKA type code
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'typeCodeZka'?: string;
    /**
     * The SWIFT type code
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'typeCodeSwift'?: string;
    /**
     * The transaction amount; required for object creation
     * @type {number}
     * @memberof CreateSepaTransactionParams
     */
    'amount': number;
    /**
     * The tax amount; default is zero. Only interests and other_gains may have a tax amount
     * @type {number}
     * @memberof CreateSepaTransactionParams
     */
    'taxAmount'?: number;
    /**
     * The transactions value date
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'valueDate': string;
    /**
     * The transactions booking date
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'bankBookingDate': string;
    /**
     * The date of transaction import
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'importDate'?: string;
    /**
     * The SEPA purpose
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'purpose'?: string;
    /**
     * The SEPA purpose
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartName'?: string;
    /**
     * The SEPA counterpart account number
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartAccountNumber'?: string;
    /**
     * The SEPA counterpart IBAN
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartIban'?: string;
    /**
     * The SEPA counterpart BLZ
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartBlz'?: string;
    /**
     * The SEPA counterpart BIC
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartBic'?: string;
    /**
     * The SEPA counterpart bank name
     * @type {string}
     * @memberof CreateSepaTransactionParams
     */
    'counterpartBankName'?: string;
}

export const CreateSepaTransactionParamsTypeEnum = {
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
    INTEREST: 'interest',
    TAX_REFUND: 'tax_refund',
    OTHER_GAINS: 'other_gains'
} as const;

export type CreateSepaTransactionParamsTypeEnum = typeof CreateSepaTransactionParamsTypeEnum[keyof typeof CreateSepaTransactionParamsTypeEnum];


