import React, { ReactNode } from "react";
import { Box, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { TypographyWithInfoIcon, LoadingPlaceHolder } from "@components";
import { ChartMenu } from "./ChartMenu";

interface Props {
  className?: string;
  isLoading?: boolean;
  title: string;
  onHide?: () => void;
  children: ReactNode;
  toolTipData?: {
    desc1: string;
    desc2?: string;
  };
  customActions?: ReactNode;
}

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.common.white};
    position: relative;
    padding: ${({ theme }) => theme.spacing(3, 4)};
    align-items: flex-start;
    box-shadow: none;
  }
  height: 100%;
  width: 100%;
`;

const Head = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: theme.spacing(3),
}));

const ActionsContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

const TitleContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const DataBlockLayout = ({
  isLoading,
  title,
  onHide,
  children,
  customActions,
  toolTipData,
  className,
}: Props) => {
  if (isLoading) {
    return <LoadingPlaceHolder dataTestId="data-block-loading" />;
  }

  return (
    <StyledPaper elevation={3} square={false} className={className}>
      <Head>
        <TitleContainer>
          {toolTipData?.desc1 || toolTipData?.desc2 ? (
            <TypographyWithInfoIcon
              data-testid="title"
              variant="body1"
              color="textSecondary"
              tooltipText={
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={400}
                    mb={toolTipData?.desc2 ? 2 : 0}
                    dangerouslySetInnerHTML={{ __html: toolTipData.desc1 }}
                  />
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: toolTipData.desc2 || "",
                    }}
                  />
                </Box>
              }
            >
              {title}
            </TypographyWithInfoIcon>
          ) : (
            <Typography
              data-testid="title"
              variant="body1"
              color="textSecondary"
            >
              {title}
            </Typography>
          )}
        </TitleContainer>

        {customActions && <ActionsContainer>{customActions}</ActionsContainer>}

        {onHide && (
          <ActionsContainer>
            <ChartMenu
              onHide={() => {
                onHide();
              }}
            />
          </ActionsContainer>
        )}
      </Head>
      {children}
    </StyledPaper>
  );
};
