/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Asset type
 * @export
 * @interface CsvRowAssetType
 */
export interface CsvRowAssetType {
    /**
     * Asset type
     * @type {string}
     * @memberof CsvRowAssetType
     */
    'value'?: CsvRowAssetTypeValueEnum;
    /**
     * Name of the cell
     * @type {string}
     * @memberof CsvRowAssetType
     */
    'name'?: string;
    /**
     * Error message if cell is invalid
     * @type {string}
     * @memberof CsvRowAssetType
     */
    'error'?: string;
}

export const CsvRowAssetTypeValueEnum = {
    _11_STOCK: '11_stock',
    _31_BOND: '31_bond',
    _21_FUND: '21_fund',
    _22_ETF: '22_etf',
    _81_FCURR: '81_fcurr'
} as const;

export type CsvRowAssetTypeValueEnum = typeof CsvRowAssetTypeValueEnum[keyof typeof CsvRowAssetTypeValueEnum];


