import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiPortfolioBacktrackingRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const portfolioBacktracking = async (
  token: string | undefined,
  params?: RiskYieldMetricsApiPortfolioBacktrackingRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.portfolioBacktracking(params);
  return data;
};

export const usePortfolioBacktracking = (
  params?: RiskYieldMetricsApiPortfolioBacktrackingRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_PORTFOLIO_BACKTRACKING, params],
    ({ token }) => portfolioBacktracking(token, params),
    options
  );
};
