/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AssetsUnderManagementList } from '../model';
/**
 * AssetsUnderManagementApi - axios parameter creator
 * @export
 */
export const AssetsUnderManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get assets under management by month up to now.
         * @param {AssetsUnderManagementContractTypeEnum} [contractType] Filter by the given contract type; default is no filter
         * @param {boolean} [forceFullRefresh] Force a full data refresh - which will slow down the operation. Use if data looks odd.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUnderManagement: async (contractType?: AssetsUnderManagementContractTypeEnum, forceFullRefresh?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/assetsUnderManagement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (contractType !== undefined) {
                localVarQueryParameter['contractType'] = contractType;
            }

            if (forceFullRefresh !== undefined) {
                localVarQueryParameter['forceFullRefresh'] = forceFullRefresh;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsUnderManagementApi - functional programming interface
 * @export
 */
export const AssetsUnderManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsUnderManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get assets under management by month up to now.
         * @param {AssetsUnderManagementContractTypeEnum} [contractType] Filter by the given contract type; default is no filter
         * @param {boolean} [forceFullRefresh] Force a full data refresh - which will slow down the operation. Use if data looks odd.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsUnderManagement(contractType?: AssetsUnderManagementContractTypeEnum, forceFullRefresh?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetsUnderManagementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsUnderManagement(contractType, forceFullRefresh, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsUnderManagementApi.assetsUnderManagement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetsUnderManagementApi - factory interface
 * @export
 */
export const AssetsUnderManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsUnderManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Get assets under management by month up to now.
         * @param {AssetsUnderManagementApiAssetsUnderManagementRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUnderManagement(requestParameters: AssetsUnderManagementApiAssetsUnderManagementRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AssetsUnderManagementList> {
            return localVarFp.assetsUnderManagement(requestParameters.contractType, requestParameters.forceFullRefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assetsUnderManagement operation in AssetsUnderManagementApi.
 * @export
 * @interface AssetsUnderManagementApiAssetsUnderManagementRequest
 */
export interface AssetsUnderManagementApiAssetsUnderManagementRequest {
    /**
     * Filter by the given contract type; default is no filter
     * @type {'ACCOUNT' | 'DEPOT'}
     * @memberof AssetsUnderManagementApiAssetsUnderManagement
     */
    readonly contractType?: AssetsUnderManagementContractTypeEnum

    /**
     * Force a full data refresh - which will slow down the operation. Use if data looks odd.
     * @type {boolean}
     * @memberof AssetsUnderManagementApiAssetsUnderManagement
     */
    readonly forceFullRefresh?: boolean
}

/**
 * AssetsUnderManagementApi - object-oriented interface
 * @export
 * @class AssetsUnderManagementApi
 * @extends {BaseAPI}
 */
export class AssetsUnderManagementApi extends BaseAPI {
    /**
     * 
     * @summary Get assets under management by month up to now.
     * @param {AssetsUnderManagementApiAssetsUnderManagementRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsUnderManagementApi
     */
    public assetsUnderManagement(requestParameters: AssetsUnderManagementApiAssetsUnderManagementRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetsUnderManagementApiFp(this.configuration).assetsUnderManagement(requestParameters.contractType, requestParameters.forceFullRefresh, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AssetsUnderManagementContractTypeEnum = {
    ACCOUNT: 'ACCOUNT',
    DEPOT: 'DEPOT'
} as const;
export type AssetsUnderManagementContractTypeEnum = typeof AssetsUnderManagementContractTypeEnum[keyof typeof AssetsUnderManagementContractTypeEnum];
