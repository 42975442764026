import { Box, styled } from "@mui/material";

export const TransactionCategory = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "clickable" && prop !== "backgroundColor",
})<{ backgroundColor?: string; color?: string; clickable?: boolean }>(
  ({ theme, backgroundColor, color, clickable = false }) => ({
    backgroundColor,
    padding: theme.spacing(1, 3),
    color,
    borderRadius: "16px",
    minWidth: 0,
    maxWidth: "300px",
    cursor: clickable ? "pointer" : "default",

    "&:hover": {
      boxShadow: clickable ? theme.shadows[2] : "none",
      marginTop: clickable ? theme.spacing(-0.5) : 0,
    },
  })
);
