import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { SortProperties, useTableSort } from "./useTableSort";

export const useTableSortWithData = <T>(
  data: T[],
  defaultSort?: SortProperties
) => {
  const sort = useTableSort(defaultSort);

  const sortData = () => {
    if (!sort?.sortField) return data;

    const sortedData = sortBy(data, (dataItem) => {
      const value = get(dataItem, sort.sortField!);

      const isNaN = Number.isNaN(Number(value));
      // "" is to treat null values as empty strings to sort correctly. works with numbers and booleans too

      if (!isNaN) return Number(value);
      return (typeof value === "string" ? value.toLowerCase() : value) ?? "";
    });

    if (sort?.sortDirection === "DESC") {
      return sortedData.reverse();
    }

    return sortedData;
  };

  return {
    ...sort,
    data: sortData(),
  };
};
