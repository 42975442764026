import styled from "@emotion/styled";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BookingTypeEnum, DepotSynchronizationStatusEnum } from "@api";
import { toRoundedNumberOfLots, toCurrency } from "@helpers";
import { ReactComponent as SuccessIcon } from "@icons/check_circle.svg";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

const TypeWithNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(4)};
  min-width: 0;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const TypeIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: 1px 6px;
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
`;

const TypeWithName = ({ logItem }: { logItem: LogItem }) => {
  const { t } = useTranslation();
  const wasRolledBack = !logItem.type && logItem.bookingId;

  if (logItem.synchronizationStatus === "FAILED") {
    return (
      <TypeWithNameContainer>
        <TypeIcon>{t("investments.synchronizationLog.checkRequired")}</TypeIcon>
        <Typography variant="body1" noWrap minWidth={0}>
          {logItem.name}
        </Typography>
      </TypeWithNameContainer>
    );
  }

  return (
    <TypeWithNameContainer>
      <TypeIcon
        data-testid={`log-item-${wasRolledBack ? "rolled-back" : "new"}`}
      >
        {wasRolledBack
          ? t("investments.synchronizationLog.rolledBack")
          : t("investments.synchronizationLog.new")}
      </TypeIcon>
      <Typography variant="body1" noWrap minWidth={0}>
        {wasRolledBack ? "" : `${t(`bookingTypes.${logItem.type}`)}: `}
        {logItem.name}
      </Typography>
    </TypeWithNameContainer>
  );
};

export interface LogItem {
  id: number;
  synchronizationStatus: DepotSynchronizationStatusEnum;
  bookingId?: number;
  investmentId?: number;
  isin?: string;
  name?: string;
  numberOfLots?: number;
  amount?: number;
  currency?: string;
  type?: BookingTypeEnum;
}

export interface SynchronizationLogItemProps {
  logItem: LogItem;
}

export const SynchronizationLogItem = ({
  logItem,
}: SynchronizationLogItemProps) => {
  const theme = useTheme();
  const wasRolledBack = !logItem.type && logItem.bookingId;

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" maxWidth="100%">
        <TypeWithName logItem={logItem} />
        {!wasRolledBack && logItem.synchronizationStatus === "SUCCESS" && (
          <Box display="flex" alignItems="center" gap={3}>
            <Box display="flex" alignItems="center">
              {(logItem.type === "buy" || logItem.type === "sell") && (
                <Typography variant="body2" noWrap>
                  {toRoundedNumberOfLots(logItem.numberOfLots!, 4)}
                </Typography>
              )}
              <SuccessIcon
                width="20"
                height="20"
                color={theme.palette.icon.success}
                style={{ marginLeft: theme.spacing(3) }}
              />
            </Box>
            {logItem.type === "dividend" && (
              <Typography variant="body2" noWrap sx={{ flexShrink: 0 }}>
                {toCurrency(logItem.amount, logItem.currency)}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};
