import { UseQueryOptions } from "react-query";
import {
  CategoriesV2ApiListAndSearchAllCategoriesV2Request,
  CategoryV2List,
} from "@generated/apiv1";

import { buildCategoriesV2Api } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllCategoriesV2 = async (
  token: string | undefined,
  params?: CategoriesV2ApiListAndSearchAllCategoriesV2Request
) => {
  const apiInstance = buildCategoriesV2Api(token);
  const { data } = await apiInstance.listAndSearchAllCategoriesV2(params);
  return data;
};

export const useListAndSearchAllCategoriesV2 = (
  params?: CategoriesV2ApiListAndSearchAllCategoriesV2Request,
  options?: UseQueryOptions<CategoryV2List, AxiosApiError, CategoryV2List>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIESV2_LIST_AND_SEARCH_ALL_CATEGORIES_V2, params],
    ({ token }) => listAndSearchAllCategoriesV2(token, params),
    options
  );
};
