import { UseQueryOptions } from "react-query";
import { CustomPropertyList } from "@generated/apiv1";

import { buildUserPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllUserProperties = async (
  token: string | undefined
) => {
  const apiInstance = buildUserPropertiesApi(token);
  const { data } = await apiInstance.listAndSearchAllUserProperties();
  return data;
};

export const useListAndSearchAllUserProperties = (
  options?: UseQueryOptions<
    CustomPropertyList,
    AxiosApiError,
    CustomPropertyList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERPROPERTIES_LIST_AND_SEARCH_ALL_USER_PROPERTIES],
    ({ token }) => listAndSearchAllUserProperties(token),
    options
  );
};
