import { UseMutationOptions } from "react-query";
import { BookingsApiSplitRequest } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const split = async (
  token: string | undefined,
  params: BookingsApiSplitRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.split(params);
  return data;
};

export const useSplit = (
  options?: UseMutationOptions<void, AxiosApiError, BookingsApiSplitRequest>
) => {
  return useAuthMutation(split, options, false);
};
