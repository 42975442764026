import { UseMutationOptions } from "react-query";
import {
  DividendMasterDataRecordsApiUpdateDividendIdRequest,
  DividendMasterDataRecord,
} from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateDividendId = async (
  token: string | undefined,
  params: DividendMasterDataRecordsApiUpdateDividendIdRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.updateDividendId(params);
  return data;
};

export const useUpdateDividendId = (
  options?: UseMutationOptions<
    DividendMasterDataRecord,
    AxiosApiError,
    DividendMasterDataRecordsApiUpdateDividendIdRequest
  >
) => {
  return useAuthMutation(updateDividendId, options, false);
};
