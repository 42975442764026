import React, { useState } from "react";
import { Box, Button, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardLayout } from "@features/analysis/components/CardLayout";
import { DonutChart } from "@components/Chart/DonutChart";
import { useChartColors } from "@hooks";
import { ReactComponent as EditIcon } from "@icons/edit-box.svg";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { LegendLayout } from "../../components/Legend";
import { AssignTagToInvestment } from "./AssignTagToInvestment";
import { useTagBreakdowns } from "./hooks/useTagBreakdowns";

export interface Props {
  accountIds?: number[];
}

export const TagClassification = ({ accountIds }: Props) => {
  const { t } = useTranslation();
  const {
    data: chartData,
    isLoading,
    isFetched,
  } = useTagBreakdowns(accountIds);
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const colors = useChartColors("classificationChart");
  const [isOpen, setIsOpen] = useState(false);
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box>
      <CardLayout
        isLoading={Boolean(accountIds?.length && isLoading)}
        noData={!accountIds?.length || (isFetched && !chartData.length)}
        chartData={chartData}
        chartTitle={t("analysis.classifications.overviewTabs.tag")}
        chart={<DonutChart chartColors={colors} />}
        legend={<LegendLayout />}
        actionButton={
          isSharedPortfolio ? undefined : (
            <Button
              data-testid="assign-tags-button"
              onClick={() => setIsOpen(true)}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
              fullWidth={isLessMd}
            >
              {t("tagClassification.assignTitle")}
            </Button>
          )
        }
      />
      {isOpen && (
        <AssignTagToInvestment
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          accountIds={accountIds}
        />
      )}
    </Box>
  );
};
