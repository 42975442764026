import React, { ReactNode } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "@components";
import { queryClient } from "@api/queryClient";
import {
  finanzflussThemeLightDe,
  finanzflussThemeLightEn,
} from "@theming/finanzflussTheme";

const ThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const theme =
    i18n.language === "de" ? finanzflussThemeLightDe : finanzflussThemeLightEn;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export const AppWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProviderWrapper>{children}</ThemeProviderWrapper>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
