import React, { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

export interface AppLayoutContextProps {
  sidebarCollapsed: boolean;
  toggleSidebarCollapsed: () => void;
}

const AppLayoutContext = createContext<AppLayoutContextProps>({
  sidebarCollapsed: false,
  toggleSidebarCollapsed: () => {},
});

export const useAppLayout = (): AppLayoutContextProps =>
  useContext(AppLayoutContext);

const AppLayoutProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("lg"));

  const [sidebarCollapsed, setSidebarCollapsed] = useState(isSmallDevice);

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed);

  useEffect(() => {
    if (isSmallDevice) setSidebarCollapsed(isSmallDevice);
  }, [isSmallDevice]);

  return (
    <AppLayoutContext.Provider
      value={{ sidebarCollapsed, toggleSidebarCollapsed }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};

export { AppLayoutContext, AppLayoutProvider };
