/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CashbackIncident } from '../model';
// @ts-ignore
import { CreateOrUpdateCashbackIncidentParams } from '../model';
// @ts-ignore
import { PageableCashbackIncidentList } from '../model';
/**
 * CashbackIncidentsApi - axios parameter creator
 * @export
 */
export const CashbackIncidentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new cashback incident
         * @param {CreateOrUpdateCashbackIncidentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashbackIncident: async (body: CreateOrUpdateCashbackIncidentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCashbackIncident', 'body', body)
            const localVarPath = `/v1/cashbackIncidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cashback incident.
         * @param {number} id ID of the subscription to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashbackIncident: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCashbackIncident', 'id', id)
            const localVarPath = `/v1/cashbackIncidents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all cashback incidents.
         * @param {Array<number>} [id] IDs of the subscriptions to retrieve.
         * @param {Array<number>} [subscriptionId] Retrieve incidents for the given subscription(s).
         * @param {Array<string>} [status] Filter incidents by status.
         * @param {Array<string>} [type] Filter incidents by type.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllCashbackIncidents: async (id?: Array<number>, subscriptionId?: Array<number>, status?: Array<string>, type?: Array<string>, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cashbackIncidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (subscriptionId) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a cashback incident.
         * @param {number} id ID of the cashback incident to update.
         * @param {CreateOrUpdateCashbackIncidentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCashbackIncident: async (id: number, body: CreateOrUpdateCashbackIncidentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCashbackIncident', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCashbackIncident', 'body', body)
            const localVarPath = `/v1/cashbackIncidents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashbackIncidentsApi - functional programming interface
 * @export
 */
export const CashbackIncidentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashbackIncidentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new cashback incident
         * @param {CreateOrUpdateCashbackIncidentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCashbackIncident(body: CreateOrUpdateCashbackIncidentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashbackIncident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCashbackIncident(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashbackIncidentsApi.createCashbackIncident']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the cashback incident.
         * @param {number} id ID of the subscription to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCashbackIncident(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashbackIncident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCashbackIncident(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashbackIncidentsApi.getCashbackIncident']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all cashback incidents.
         * @param {Array<number>} [id] IDs of the subscriptions to retrieve.
         * @param {Array<number>} [subscriptionId] Retrieve incidents for the given subscription(s).
         * @param {Array<string>} [status] Filter incidents by status.
         * @param {Array<string>} [type] Filter incidents by type.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllCashbackIncidents(id?: Array<number>, subscriptionId?: Array<number>, status?: Array<string>, type?: Array<string>, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableCashbackIncidentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllCashbackIncidents(id, subscriptionId, status, type, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashbackIncidentsApi.listAndSearchAllCashbackIncidents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a cashback incident.
         * @param {number} id ID of the cashback incident to update.
         * @param {CreateOrUpdateCashbackIncidentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCashbackIncident(id: number, body: CreateOrUpdateCashbackIncidentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashbackIncident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCashbackIncident(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashbackIncidentsApi.updateCashbackIncident']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashbackIncidentsApi - factory interface
 * @export
 */
export const CashbackIncidentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashbackIncidentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new cashback incident
         * @param {CashbackIncidentsApiCreateCashbackIncidentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashbackIncident(requestParameters: CashbackIncidentsApiCreateCashbackIncidentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CashbackIncident> {
            return localVarFp.createCashbackIncident(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cashback incident.
         * @param {CashbackIncidentsApiGetCashbackIncidentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashbackIncident(requestParameters: CashbackIncidentsApiGetCashbackIncidentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CashbackIncident> {
            return localVarFp.getCashbackIncident(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all cashback incidents.
         * @param {CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllCashbackIncidents(requestParameters: CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableCashbackIncidentList> {
            return localVarFp.listAndSearchAllCashbackIncidents(requestParameters.id, requestParameters.subscriptionId, requestParameters.status, requestParameters.type, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a cashback incident.
         * @param {CashbackIncidentsApiUpdateCashbackIncidentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCashbackIncident(requestParameters: CashbackIncidentsApiUpdateCashbackIncidentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CashbackIncident> {
            return localVarFp.updateCashbackIncident(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCashbackIncident operation in CashbackIncidentsApi.
 * @export
 * @interface CashbackIncidentsApiCreateCashbackIncidentRequest
 */
export interface CashbackIncidentsApiCreateCashbackIncidentRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateCashbackIncidentParams}
     * @memberof CashbackIncidentsApiCreateCashbackIncident
     */
    readonly body: CreateOrUpdateCashbackIncidentParams
}

/**
 * Request parameters for getCashbackIncident operation in CashbackIncidentsApi.
 * @export
 * @interface CashbackIncidentsApiGetCashbackIncidentRequest
 */
export interface CashbackIncidentsApiGetCashbackIncidentRequest {
    /**
     * ID of the subscription to retrieve.
     * @type {number}
     * @memberof CashbackIncidentsApiGetCashbackIncident
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllCashbackIncidents operation in CashbackIncidentsApi.
 * @export
 * @interface CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest
 */
export interface CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest {
    /**
     * IDs of the subscriptions to retrieve.
     * @type {Array<number>}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly id?: Array<number>

    /**
     * Retrieve incidents for the given subscription(s).
     * @type {Array<number>}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly subscriptionId?: Array<number>

    /**
     * Filter incidents by status.
     * @type {Array<string>}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly status?: Array<string>

    /**
     * Filter incidents by type.
     * @type {Array<string>}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly type?: Array<string>

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof CashbackIncidentsApiListAndSearchAllCashbackIncidents
     */
    readonly order?: string
}

/**
 * Request parameters for updateCashbackIncident operation in CashbackIncidentsApi.
 * @export
 * @interface CashbackIncidentsApiUpdateCashbackIncidentRequest
 */
export interface CashbackIncidentsApiUpdateCashbackIncidentRequest {
    /**
     * ID of the cashback incident to update.
     * @type {number}
     * @memberof CashbackIncidentsApiUpdateCashbackIncident
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateCashbackIncidentParams}
     * @memberof CashbackIncidentsApiUpdateCashbackIncident
     */
    readonly body: CreateOrUpdateCashbackIncidentParams
}

/**
 * CashbackIncidentsApi - object-oriented interface
 * @export
 * @class CashbackIncidentsApi
 * @extends {BaseAPI}
 */
export class CashbackIncidentsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new cashback incident
     * @param {CashbackIncidentsApiCreateCashbackIncidentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashbackIncidentsApi
     */
    public createCashbackIncident(requestParameters: CashbackIncidentsApiCreateCashbackIncidentRequest, options?: RawAxiosRequestConfig) {
        return CashbackIncidentsApiFp(this.configuration).createCashbackIncident(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cashback incident.
     * @param {CashbackIncidentsApiGetCashbackIncidentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashbackIncidentsApi
     */
    public getCashbackIncident(requestParameters: CashbackIncidentsApiGetCashbackIncidentRequest, options?: RawAxiosRequestConfig) {
        return CashbackIncidentsApiFp(this.configuration).getCashbackIncident(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all cashback incidents.
     * @param {CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashbackIncidentsApi
     */
    public listAndSearchAllCashbackIncidents(requestParameters: CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest = {}, options?: RawAxiosRequestConfig) {
        return CashbackIncidentsApiFp(this.configuration).listAndSearchAllCashbackIncidents(requestParameters.id, requestParameters.subscriptionId, requestParameters.status, requestParameters.type, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a cashback incident.
     * @param {CashbackIncidentsApiUpdateCashbackIncidentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashbackIncidentsApi
     */
    public updateCashbackIncident(requestParameters: CashbackIncidentsApiUpdateCashbackIncidentRequest, options?: RawAxiosRequestConfig) {
        return CashbackIncidentsApiFp(this.configuration).updateCashbackIncident(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

