import { UseQueryOptions } from "react-query";
import { User } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const usersMyself = async (token: string | undefined) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.usersMyself();
  return data;
};

export const useUsersMyself = (
  options?: UseQueryOptions<User, AxiosApiError, User>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERS_USERS_MYSELF],
    ({ token }) => usersMyself(token),
    options
  );
};
