/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Booking } from './booking';
// May contain unused imports in some cases
// @ts-ignore
import { Category } from './category';
// May contain unused imports in some cases
// @ts-ignore
import { Label } from './label';
// May contain unused imports in some cases
// @ts-ignore
import { TransactionHistoryMismatchWarning } from './transaction-history-mismatch-warning';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Transaction
     */
    'id': number;
    /**
     * ID assigned by the bank or the multi-banking provider
     * @type {string}
     * @memberof Transaction
     */
    'externalId'?: string;
    /**
     * DEPRECATED: use externalId instead. ID assigned by the banking provider in case the          transaction was created from a bank transaction
     * @type {number}
     * @memberof Transaction
     */
    'finApiId'?: number;
    /**
     * ID of the account the transaction belongs to
     * @type {number}
     * @memberof Transaction
     */
    'accountId': number;
    /**
     * ID of an assigned insurance; if any
     * @type {number}
     * @memberof Transaction
     */
    'insuranceId'?: number;
    /**
     * The transaction type. Types interest, tax_refund and other_gains have impact on performance.
     * @type {string}
     * @memberof Transaction
     */
    'type': TransactionTypeEnum;
    /**
     * 
     * @type {Booking}
     * @memberof Transaction
     */
    'booking'?: Booking;
    /**
     * Tax amount payed / received; only if this transaction is of type interest of other_gains
     * @type {number}
     * @memberof Transaction
     */
    'taxAmount'?: number;
    /**
     * The transaction amount; always in account currency. Note that the transaction amount                                is still in account currency, even if the booking amount is in a different investment                                currency.
     * @type {number}
     * @memberof Transaction
     */
    'amount': number;
    /**
     * A comment or reason entered by the user
     * @type {string}
     * @memberof Transaction
     */
    'comment'?: string;
    /**
     * The value date (Wertstellungsdatum)
     * @type {string}
     * @memberof Transaction
     */
    'valueDate': string;
    /**
     * The banks booking date (Buchungsdatum)
     * @type {string}
     * @memberof Transaction
     */
    'bankBookingDate': string;
    /**
     * The data when the transaction was imported by the banking API provider.
     * @type {string}
     * @memberof Transaction
     */
    'finApiBookingDate'?: string;
    /**
     * The SEPA purpose
     * @type {string}
     * @memberof Transaction
     */
    'purpose'?: string;
    /**
     * A transaction reference, bank specific
     * @type {string}
     * @memberof Transaction
     */
    'reference'?: string;
    /**
     * The SEPA counterpart name (Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartName'?: string;
    /**
     * The SEPA counterpart account number (Kontonummer Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartAccountNumber'?: string;
    /**
     * The SEPA counterpart account iban (IBAN Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartIban'?: string;
    /**
     * The SEPA counterpart bank ID (Bankleitzahl Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartBlz'?: string;
    /**
     * The SEPA counterpart BIC (BIC Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartBic'?: string;
    /**
     * The SEPA counterpart bank name (Bankname Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartBankName'?: string;
    /**
     * The SEPA counterpart mandate reference (Mandatsreferenz Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartMandateReference'?: string;
    /**
     * The SEPA counterpart customer reference (Kundenreferenz Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartCustomerReference'?: string;
    /**
     * The SEPA counterpart creditor ID (Kreditoren-ID Gegenstelle)
     * @type {string}
     * @memberof Transaction
     */
    'counterpartCreditorId'?: string;
    /**
     * A literal transaction type; useful for display to the user. Mostly according to                                SEPA or SWIFT (but do not count on that...)
     * @type {string}
     * @memberof Transaction
     */
    'literalType'?: string;
    /**
     * A SWIFT standard field
     * @type {string}
     * @memberof Transaction
     */
    'typeCodeZka'?: string;
    /**
     * A SWIFT standard field
     * @type {string}
     * @memberof Transaction
     */
    'typeCodeSwift'?: string;
    /**
     * A SWIFT standard field
     * @type {string}
     * @memberof Transaction
     */
    'primanota'?: string;
    /**
     * true if the transaction is occurring regularly
     * @type {boolean}
     * @memberof Transaction
     */
    'isRegular'?: boolean;
    /**
     * true if the \'isRegular\' flag was defined by the user; by default this flag is set by                                our AI.
     * @type {boolean}
     * @memberof Transaction
     */
    'isRegularFlagUserDefined'?: boolean;
    /**
     * The payment interval in months, if this transaction is regular and was detected by our AI.
     * @type {number}
     * @memberof Transaction
     */
    'paymentInterval'?: number;
    /**
     * true if the payment interval was defined by the user; by default this flag is set by                                our AI.
     * @type {boolean}
     * @memberof Transaction
     */
    'isPaymentIntervalUserDefined'?: boolean;
    /**
     * If this is a regular transaction; this is the average amount moved
     * @type {number}
     * @memberof Transaction
     */
    'averageRegularAmount'?: number;
    /**
     * true if this transaction is an inter-account transfer; meaning money transferred between                                accounts that are imported into this useres rentablo account. Inter account transfers                                are excluded from cashflow analytics as no money leaves the users domain.
     * @type {boolean}
     * @memberof Transaction
     */
    'isInterAccountTransfer'?: boolean;
    /**
     * true if the inter account transfer flag was defined by the user; by default this flag                                is set by our AI.
     * @type {boolean}
     * @memberof Transaction
     */
    'isInterAccountTransferUserDefined'?: boolean;
    /**
     * If the transaction was detected as an inter account transfer, this is the ID of the                                transaction of the other account.
     * @type {number}
     * @memberof Transaction
     */
    'interAccountTransferCounterpartId'?: number;
    /**
     * A transaction category ID; result from a categorization run or set by a user
     * @type {number}
     * @memberof Transaction
     */
    'categoryId'?: number;
    /**
     * 
     * @type {Category}
     * @memberof Transaction
     */
    'category'?: Category;
    /**
     * A transaction label (currently unused)
     * @type {Array<Label>}
     * @memberof Transaction
     */
    'labels'?: Array<Label>;
    /**
     * true if this transaction was marked as a potential duplicate. We recommend ignoring                                this flag, as finAPIs duplication detection tends to fail on dividends.
     * @type {boolean}
     * @memberof Transaction
     */
    'isPotentialDuplicate'?: boolean;
    /**
     * true if this transaction is an adjusting entry. Adjusting entries are created to account                                for missing transactions.
     * @type {boolean}
     * @memberof Transaction
     */
    'isAdjustingEntry'?: boolean;
    /**
     * true if this is a newly imported transaction.
     * @type {boolean}
     * @memberof Transaction
     */
    'isNew'?: boolean;
    /**
     * The transaction import date.
     * @type {string}
     * @memberof Transaction
     */
    'importDate'?: string;
    /**
     * Children of this transaction (currently unused)
     * @type {Array<number>}
     * @memberof Transaction
     */
    'children'?: Array<number>;
    /**
     * 
     * @type {TransactionHistoryMismatchWarning}
     * @memberof Transaction
     */
    'transactionHistoryMismatchWarning'?: TransactionHistoryMismatchWarning;
}

export const TransactionTypeEnum = {
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
    ADJUSTING_ENTRY: 'adjusting_entry',
    INTEREST: 'interest',
    TAX_REFUND: 'tax_refund',
    OTHER_GAINS: 'other_gains'
} as const;

export type TransactionTypeEnum = typeof TransactionTypeEnum[keyof typeof TransactionTypeEnum];


