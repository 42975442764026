import { UseMutationOptions } from "react-query";
import { AuthApiLoginWithGoogleRequest, LoginData } from "@generated/user";

import { buildAuthApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const loginWithGoogle = async (
  token: string | undefined,
  params: AuthApiLoginWithGoogleRequest
) => {
  const apiInstance = buildAuthApi(token);
  const { data } = await apiInstance.loginWithGoogle(params);
  return data;
};

export const useLoginWithGoogle = (
  options?: UseMutationOptions<
    LoginData,
    AxiosApiError,
    AuthApiLoginWithGoogleRequest
  >
) => {
  return useAuthMutation(loginWithGoogle, options, true);
};
