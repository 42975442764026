import React from "react";
import {
  Box,
  BoxProps,
  Theme,
  Typography,
  TypographyVariant,
  useTheme,
} from "@mui/material";

const getStyle = (theme: Theme, variant: BadgeProps["variant"]) => {
  switch (variant) {
    case "primary":
      return {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.primary.main,
      };
    case "secondary":
      return {
        backgroundColor: theme.palette.secondary.lighter,
        color: theme.palette.secondary.main,
      };
    case "accent":
      return {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.light}`,
      };
    case "info":
      return {
        backgroundColor: theme.palette.background.info,
        color: theme.palette.info.main,
      };
    case "warning":
      return {
        backgroundColor: theme.palette.background.warning,
        color: theme.palette.text.warning,
        border: `1px solid ${theme.palette.warning.light}`,
      };
    case "success":
      return {
        backgroundColor: theme.palette.background.success,
        color: theme.palette.text.positive,
        border: `1px solid ${theme.palette.success.light}`,
      };
    case "error":
      return {
        backgroundColor: theme.palette.background.error,
        color: theme.palette.text.negative,
        border: `1px solid ${theme.palette.error.light}`,
      };
    case "default":
      return {
        backgroundColor: theme.palette.background.neutral,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.border.secondary}`,
      };
  }
};

export type BadgeVariant =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "warning"
  | "info"
  | "accent";

export type BadgeProps = {
  variant?: BadgeVariant;
  children: React.ReactNode;
  typographyVariant?: TypographyVariant;
} & BoxProps;

export const Badge = ({
  variant = "default",
  children,
  typographyVariant = "body2",
  ...rest
}: BadgeProps) => {
  const theme = useTheme();
  const styles = getStyle(theme, variant);

  return (
    <Box
      py={styles?.border ? 0.5 : 0.75}
      px={2}
      {...rest}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: styles?.backgroundColor || "transparent",
        borderRadius: `${theme.shape.borderRadius * 1.33}px`,
        border: styles?.border || "none",
        ...rest.sx,
      }}
    >
      {typeof children === "string" ? (
        <Typography
          variant={typographyVariant}
          color={styles?.color || "textPrimary"}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
};
