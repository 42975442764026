export * from "./banksList";

export interface StockIndex {
  label: string;
  value: string;
  type: string;
}

export const DAX_TICKER_SYMBOL = "14734849";
export const DAX_KURS_TICKER_SYMBOL = "1966970";
export const MDAX_TICKER_SYMBOL = "126161392";
export const MDAX_KURS_TICKER_SYMBOL = "1928480";
export const DOW_TICKER_SYMBOL = "126161712";
export const MSCI_WORLD_EUR_TICKER_SYMBOL = "tts-75270796";
export const SP500_TICKER_SYMBOL = "126161736";
export const NASDAQ100_TICKER_SYMBOL = "126161719";
export const MSCI_ACWI_TICKER_SYMBOL = "tts-72556594";
export const FTSE_ALL_WORLD_TICKER_SYMBOL = "tts-170935767";

export const STOCK_INDEXES: StockIndex[] = [
  { label: "DAX", value: DAX_TICKER_SYMBOL, type: "market" },
  { label: "DAX Kurs", value: DAX_KURS_TICKER_SYMBOL, type: "market" },
  { label: "MDAX", value: MDAX_TICKER_SYMBOL, type: "market" },
  { label: "MDAX Kurs", value: MDAX_KURS_TICKER_SYMBOL, type: "market" },
  {
    label: "MSCI World (EUR)",
    value: MSCI_WORLD_EUR_TICKER_SYMBOL,
    type: "market",
  },
  { label: "S&P 500", value: SP500_TICKER_SYMBOL, type: "market" },
  { label: "NASDAQ 100", value: NASDAQ100_TICKER_SYMBOL, type: "market" },
  { label: "MSCI ACWI", value: MSCI_ACWI_TICKER_SYMBOL, type: "market" },
  {
    label: "FTSE All World",
    value: FTSE_ALL_WORLD_TICKER_SYMBOL,
    type: "market",
  },
];

export const QUOTE_PROVIDERS = {
  teletrader: "tte",
  yahoo: "yf",
  ing: "ing",
  none: "none",
  quandl: "quandl",
  rentablo: "rentablo",
  crypto: "crypto",
  onvista: "ovfs",
};

export const mapStockIndexToQuoteProvider: Record<string, string> = {
  [DAX_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [DAX_KURS_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [MDAX_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [MDAX_KURS_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [DOW_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [MSCI_WORLD_EUR_TICKER_SYMBOL]: QUOTE_PROVIDERS.teletrader,
  [SP500_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
  [NASDAQ100_TICKER_SYMBOL]: QUOTE_PROVIDERS.onvista,
};

export const METRICS_WITH_CURRENCY = [
  "eps",
  "totalLiabilities",
  "operatingCashflow",
  "capEx",
  "freeCashflow",
  "totalEquity",
  "netIncome",
  "netRevenue",
  "returnOnAssets",
  "returnOnEquity",
];

export const isProduction =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "production";

export const EXACT_NOL = "EXACT_NOL";
export const BOOKING_WITH_NOL_ESTIMATED = "BOOKING_WITH_NOL_ESTIMATED";
export const NEGATIVE_NOL_DELTA_RESOLUTION = "NEGATIVE_NOL_DELTA_RESOLUTION";
export const DELTA_RESOLUTION = "DELTA_RESOLUTION";
export const PLUS_PRICE = 8.99;
export const PLUS_PRICE_ANNUAL = 5.84;
