import React, { ReactNode } from "react";
import { Link, LinkProps } from "@mui/material";
import styled from "@emotion/styled";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export type IconLinkProps = LinkProps & {
  underline?: "none" | "hover" | "always";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
};

export const IconLink = ({
  startIcon,
  endIcon,
  children,
  ...rest
}: IconLinkProps) => (
  <StyledLink {...rest}>
    {startIcon && startIcon}
    <div>{children}</div>
    {endIcon && endIcon}
  </StyledLink>
);
