import { UseQueryOptions } from "react-query";
import { UserProfilesApiProfileImageRequest } from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const profileImage = async (
  token: string | undefined,
  params: UserProfilesApiProfileImageRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.profileImage(params);
  return data;
};

export const useProfileImage = (
  params: UserProfilesApiProfileImageRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERPROFILES_PROFILE_IMAGE, params],
    ({ token }) => profileImage(token, params),
    options
  );
};
