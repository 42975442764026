import React from "react";
import { Box, styled, Typography } from "@mui/material";

const AccountsHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

interface Props {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
}

export const AccountsHeader = ({ icon, title, subtitle }: Props) => {
  return (
    <AccountsHeaderContainer>
      {icon && (
        <Box mr={4} sx={{ "& img": { display: "block" } }}>
          {icon}
        </Box>
      )}
      <Box>
        {title && (
          <Typography variant="h4" component="h1">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Box>
    </AccountsHeaderContainer>
  );
};
