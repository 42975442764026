import { UseQueryOptions } from "react-query";
import {
  SubscriptionsApiListAndSearchAllSubscriptionsRequest,
  PageableSubscriptionList,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllSubscriptions = async (
  token: string | undefined,
  params?: SubscriptionsApiListAndSearchAllSubscriptionsRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.listAndSearchAllSubscriptions(params);
  return data;
};

export const useListAndSearchAllSubscriptions = (
  params?: SubscriptionsApiListAndSearchAllSubscriptionsRequest,
  options?: UseQueryOptions<
    PageableSubscriptionList,
    AxiosApiError,
    PageableSubscriptionList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONS_LIST_AND_SEARCH_ALL_SUBSCRIPTIONS, params],
    ({ token }) => listAndSearchAllSubscriptions(token, params),
    options
  );
};
