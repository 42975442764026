import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { parseJSON } from "date-fns";
import truncate from "lodash/truncate";
import { InvestmentTypeEnum } from "@api";
import { useGetWatchlists } from "@api/v1/hooks/useGetWatchlists";
import { useGetWatchlistEntry } from "@api/v1/hooks/useGetWatchlistEntry";
import { PageLayout } from "@components";
import {
  SecuritiesAddAndEditForm,
  SecurityMeta,
} from "../components/SecuritiesAddAndEditForm";

export const SecuritiesEditPage = () => {
  const { watchlistId, securityId } = useParams<"watchlistId" | "securityId">();
  const { t } = useTranslation();

  const { data: watchlistsResponse, isLoading: isWatchlistsLoading } =
    useGetWatchlists();

  const { data: security } = useGetWatchlistEntry(
    Number.parseInt(securityId!, 10),
    { enabled: Boolean(securityId) }
  );

  const watchlists = watchlistsResponse?.watchlists ?? [];

  const currentWatchlist = watchlists.find(
    (item) => item.id === Number.parseInt(watchlistId!, 10)
  );

  const backURL = `/watchlists/${currentWatchlist?.id}`;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: "/",
      text: t("securitiesEditPage.breadcrumbs.home"),
    },
    {
      to: "/watchlists",
      text: t("securitiesEditPage.breadcrumbs.securities"),
    },
    {
      to: backURL,
      text: truncate(currentWatchlist?.name),
    },
    {
      to: `/watchlists/${currentWatchlist?.id}/securities/${securityId}`,
      text: truncate(security?.name),
    },
    {
      text: t("securitiesEditPage.breadcrumbs.edit"),
    },
  ];

  const securityMeta: SecurityMeta = {
    name: security?.name ?? "",
    id: `${security?.tickerSymbol}` ?? "",
    isin: security?.isin,
    currency: security?.currency,
    mappedType: security?.type as InvestmentTypeEnum,
    quoteProvider: security?.quote?.quoteProvider,
  };

  const purchaseDate = security?.purchaseDate
    ? parseJSON(security.purchaseDate)
    : undefined;

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      {security && !isWatchlistsLoading && (
        <SecuritiesAddAndEditForm
          currentWatchlist={currentWatchlist}
          watchlists={watchlistsResponse?.watchlists ?? []}
          securityMeta={securityMeta}
          securityId={securityId!}
          purchasePrice={security.purchasePrice}
          purchaseDate={purchaseDate}
        />
      )}
    </PageLayout>
  );
};
