import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { DateRangePicker as DateRangePickerOrigin } from "../DateRangePicker";

const DateRangePicker = styled(DateRangePickerOrigin)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 528px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 24px;
  align-items: flex-start;
`;

const Hint = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  & > button:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

interface Props {
  onSubmit: (from: Date, to: Date) => void;
  onCancel: () => void;
  defaultFrom?: Date;
  defaultTo?: Date;
  defaultMonthFrom?: Date;
}

export const DateRangePickerDialogContainer = ({
  onSubmit,
  onCancel,
  defaultFrom,
  defaultTo,
  defaultMonthFrom,
}: Props) => {
  const { t } = useTranslation();
  const [range, setRange] = useState<{ from: Date; to: Date } | undefined>();
  const handleChange = (from?: Date, to?: Date) => {
    if (from && to) {
      return setRange({ from, to });
    }
    setRange(undefined);
  };

  const handleSubmit = () => {
    if (range) {
      onSubmit(range?.from, range?.to);
    }
  };
  const rangeText = range
    ? `${format(range?.from, "d.M.yyyy")} - ${format(range?.to, "d.M.yyyy")}`
    : "";

  return (
    <Container>
      <DateRangePicker
        onChange={handleChange}
        defaultFrom={defaultFrom}
        defaultTo={defaultTo}
        defaultMonthFrom={defaultMonthFrom}
      />
      <ActionsContainer>
        <Hint>
          {range && <Typography variant="body2">{rangeText}</Typography>}
        </Hint>
        <ButtonContainer>
          <Button
            data-testid="cancel-button"
            color="primary"
            size="small"
            variant="outlined"
            onClick={onCancel}
          >
            {t("dateRangePickerDialog.cancelButton")}
          </Button>
          <Button
            data-testid="submit-button"
            color="primary"
            disabled={!range}
            variant="contained"
            size="small"
            onClick={handleSubmit}
          >
            {t("dateRangePickerDialog.submitButton")}
          </Button>
        </ButtonContainer>
      </ActionsContainer>
    </Container>
  );
};
