import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const addBookingValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "VALIDATE_BOOKING")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    bookingValues: { ...context.bookingValues, ...event.bookingValues },
  };
};
