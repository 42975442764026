import { assign, createMachine } from "xstate";
import { addAxiosErrorToContextAction } from "./actions/addAxiosErrorToContextAction";
import { addBookingValuesAction } from "./actions/addBookingValuesAction";
import { addValidationErrorAction } from "./actions/addValidationErrorAction";
import { clearValidationErrorAction } from "./actions/clearValidationErrorAction";
import { persistBooking } from "./services/persistBooking";
import { validateBooking } from "./services/validateBooking";
import { TransactionWizardState } from "./types/transactionWizardState";
import {
  defaultContext,
  TransactionWizardContext,
} from "./types/transactionWizardContext";
import { TransactionWizardEvent } from "./types/TransactionWizardEvent";
import { loadExistingBooking } from "./services/loadExistingBooking";
import { setBookingAndInvestmentValuesAction } from "./actions/setBookingAndInvestmentValuesAction";

export const editTransactionWizardSM = createMachine<
  TransactionWizardContext,
  TransactionWizardEvent,
  TransactionWizardState
>(
  {
    id: "editTransactionWizardSM",

    // Initial state
    initial: "idle",

    // Local context for entire machine
    context: defaultContext,

    // State definitions
    states: {
      idle: {
        on: {
          INIT_FROM_BOOKING: { target: "loadBooking" },
        },
      },
      loadBooking: {
        id: "loadBooking",
        invoke: {
          id: "loadExistingBooking",
          src: loadExistingBooking,
        },
        on: {
          BOOKING_INITIALIZED: {
            target: "priceAndDate",
            actions: ["setBookingAndInvestmentValues"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      priceAndDate: {
        id: "priceAndDate",
        on: {
          VALIDATE_BOOKING: {
            target: "validateBooking",
            actions: ["addBookingValuesAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      validateBooking: {
        invoke: {
          id: "validateBooking",
          src: validateBooking,
        },
        on: {
          BOOKING_VALID: [
            {
              target: "persist",
              actions: ["clearValidationErrorAction"],
              cond: (context) => Boolean(context.skipSummaryStep),
            },
            {
              target: "summary",
              actions: ["clearValidationErrorAction"],
            },
          ],
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      summary: {
        on: {
          PERSIST: { target: "persist" },
          BACK: { target: "priceAndDate" },
        },
      },
      persist: {
        invoke: {
          id: "persistBooking",
          src: persistBooking,
        },
        on: {
          BOOKING_VALID: {
            target: "persisted",
          },
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      persisted: {
        type: "final",
      },
      somethingWrong: {
        type: "final",
        id: "somethingWrong",
        entry: ["addAxiosErrorToContextAction"],
      },
    },
  },
  {
    actions: {
      setBookingAndInvestmentValues: assign(
        setBookingAndInvestmentValuesAction
      ),
      addBookingValuesAction: assign(addBookingValuesAction),
      addValidationErrorAction: assign(addValidationErrorAction),
      clearValidationErrorAction: assign(clearValidationErrorAction),
      addAxiosErrorToContextAction: assign(addAxiosErrorToContextAction),
    },
  }
);
