import { UseQueryOptions } from "react-query";
import {
  AccountsApiSelectGroupsIdRequest,
  AccountSelectGroup,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const selectGroupsId = async (
  token: string | undefined,
  params: AccountsApiSelectGroupsIdRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.selectGroupsId(params);
  return data;
};

export const useSelectGroupsId = (
  params: AccountsApiSelectGroupsIdRequest,
  options?: UseQueryOptions<
    AccountSelectGroup,
    AxiosApiError,
    AccountSelectGroup
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_SELECT_GROUPS_ID, params],
    ({ token }) => selectGroupsId(token, params),
    options
  );
};
