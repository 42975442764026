/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CategoryV2Reference } from './category-v2-reference';

/**
 *
 * @export
 * @interface CategoryV2
 */
export interface CategoryV2 {
    /**
     * Human readable unique identifier of the category
     * @type {string}
     * @memberof CategoryV2
     */
    'slug': string;
    /**
     * Textual name of the category; may be subject to change
     * @type {string}
     * @memberof CategoryV2
     */
    'name': string;
    /**
     *
     * @type {CategoryV2Reference}
     * @memberof CategoryV2
     */
    'parent'?: CategoryV2Reference;
    /**
     * A reference to the category ID used by the finApi categorization service
     * @type {number}
     * @memberof CategoryV2
     */
    'finApiId'?: number;
    /**
     * Is this a custom category defined by the user, or a base category applicable to all users.
     * @type {boolean}
     * @memberof CategoryV2
     */
    'isUserDefined': boolean;
    /**
     * User defined categories only - the ID of user the category belongs to
     * @type {number}
     * @memberof CategoryV2
     */
    'userId'?: number;
    /**
     * The status of the category
     * @type {string}
     * @memberof CategoryV2
     */
    'status': CategoryV2StatusEnum;
    /**
     * The creation timestamp
     * @type {string}
     * @memberof CategoryV2
     */
    'createdAt': string;
}

export const CategoryV2StatusEnum = {
    ACTIVE: 'ACTIVE',
    DEPRECATED: 'DEPRECATED'
} as const;

export type CategoryV2StatusEnum = typeof CategoryV2StatusEnum[keyof typeof CategoryV2StatusEnum];


