import React, { memo, useContext } from "react";
import styled from "@emotion/styled";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { useBookingsFilters } from "@features/investments/components/BookingsTable/useBookingsFilters";
import {
  BookingTypeEnum,
  InvestmentTypeEnum,
  Investment,
} from "@generated/apiv1";
import { AppLayoutContext } from "@providers/AppLayoutProvider";
import { MultipleSelect } from "@components/common/forms/MultipleSelect";
import { InvestmentSelect } from "@components/common/forms/InvestmentSelect";

const defaultIntervalTypes: IntervalType[] = [
  "oneMonth",
  "sixMonths",
  "oneYear",
  "sinceBeginning",
  "custom",
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(3)};
`;

interface Props {
  intervalType: IntervalType;
  onSelectInterval: (interval: IntervalType) => void;
  onSelectCustomInterval: (from: Date, to: Date) => void;
  shouldShowRangeSelector?: boolean;
  range: RangeType;
  onInvestmentTypeChange: (assetType: InvestmentTypeEnum[]) => void;
  onInvestmentChange: (investment: string | null) => void;
  onBookingTypeChange: (bookingType: BookingTypeEnum[]) => void;
  selectedInvestmentType: InvestmentTypeEnum[];
  selectedInvestment: string | null;
  selectedBookingType: BookingTypeEnum[];
  disabled?: boolean;
  accountIds: number[];
}

export const BookingsFilters = memo(
  ({
    range,
    intervalType,
    selectedInvestmentType,
    selectedBookingType,
    selectedInvestment,
    accountIds,
    onInvestmentChange,
    onInvestmentTypeChange,
    onBookingTypeChange,
    onSelectCustomInterval,
    onSelectInterval,
    disabled,
    shouldShowRangeSelector = true,
  }: Props) => {
    const { t } = useTranslation();
    const { sidebarCollapsed } = useContext(AppLayoutContext);
    const isLess1300 = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down(1300)
    );
    const isLess1000 = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down(1000)
    );
    const isLess700 = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("md")
    );
    const { assetTypes, investments, bookingTypes, intervalTypes } =
      useBookingsFilters(
        accountIds,
        {
          // @ts-ignore TODO: backend fix
          bookings: selectedBookingType,
          investment: selectedInvestment,
          // @ts-ignore TODO: backend fix
          investmentTypes: selectedInvestmentType,
          range,
        },
        defaultIntervalTypes
      );

    const shouldWrapRangeSelector =
      isLess1000 || (isLess1300 && !sidebarCollapsed);

    return (
      <Container>
        <Wrapper flexDirection={shouldWrapRangeSelector ? "column" : "row"}>
          <Box
            display="flex"
            alignItems="center"
            gap={3}
            width="100%"
            flexDirection={isLess700 ? "column" : "row"}
          >
            <Box
              sx={{
                width: shouldWrapRangeSelector ? "100%" : "33.3%",
                "& .MuiAutocomplete-inputRoot": {
                  paddingRight: "16px !important",
                },
              }}
            >
              <InvestmentSelect
                accountId={accountIds}
                onChange={(investment) =>
                  onInvestmentChange((investment as Investment)?.isin ?? null)
                }
                isin={investments}
                placeholder={t(`bookingsTable.filters.investment`)}
                onlyUnique
                includeHistoric
                noLabel
              />
            </Box>
            <MultipleSelect<InvestmentTypeEnum>
              value={selectedInvestmentType}
              onChange={onInvestmentTypeChange}
              fullWidth={isLess700}
              options={assetTypes}
              disabled={disabled}
              selectedOptions={selectedInvestmentType}
              noOptionsText={t("bookingsTable.filters.assets")}
              getOptionsText={(selected) => {
                return selected.length > 1
                  ? t("bookingsTable.filters.assetsSelected", {
                      count: selected.length,
                    })
                  : t(`investmentTypes.${selected[0]}`);
              }}
              sx={{
                width: isLess700 ? "100%" : "33.3%",
                maxWidth: isLess700 ? "100%" : "250px",
                flexShrink: 0,
              }}
            />
            <MultipleSelect<BookingTypeEnum>
              value={selectedBookingType}
              onChange={onBookingTypeChange}
              options={bookingTypes}
              selectedOptions={selectedBookingType}
              fullWidth={isLess700}
              disabled={disabled}
              noOptionsText={t("bookingsTable.filters.bookingType")}
              getOptionsText={(selected) => {
                return selected.length > 1
                  ? t("bookingsTable.filters.bookingTypeSelected", {
                      count: selected.length,
                    })
                  : t(`bookingTypes.${selected[0]}`);
              }}
              sx={{
                width: isLess700 ? "100%" : "33.3%",
                maxWidth: isLess700 ? "100%" : "250px",
                flexShrink: 0,
              }}
            />
          </Box>
          {shouldShowRangeSelector && (
            <RangeSelector
              range={intervalType === "custom" ? range : undefined}
              intervalType={intervalType}
              intervalTypes={defaultIntervalTypes}
              disabledIntervalTypes={defaultIntervalTypes.filter(
                (type) => !intervalTypes.includes(type)
              )}
              onSelect={onSelectInterval}
              onCustomSelect={onSelectCustomInterval}
              height={48}
              sx={{ flexShrink: 0 }}
              width={shouldWrapRangeSelector ? "100%" : "auto"}
            />
          )}
        </Wrapper>
      </Container>
    );
  }
);
