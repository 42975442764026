import { UseQueryOptions } from "react-query";
import { BankSyncsApiGetBankSyncRequest, BankSync } from "@generated/apiv3";

import { buildBankSyncsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankSync = async (
  token: string | undefined,
  params: BankSyncsApiGetBankSyncRequest
) => {
  const apiInstance = buildBankSyncsApi(token);
  const { data } = await apiInstance.getBankSync(params);
  return data;
};

export const useGetBankSync = (
  params: BankSyncsApiGetBankSyncRequest,
  options?: UseQueryOptions<BankSync, AxiosApiError, BankSync>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKSYNCS_GET_BANK_SYNC, params],
    ({ token }) => getBankSync(token, params),
    options
  );
};
