import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { StepContainer } from "@features/transactionWizard/StepContainer";
import { DepotSelect } from "@components";
import { useListAndSearchAllAccounts } from "@generated/apiv1/hooks";
import {
  investmentToInvestmentValues,
  investmentTypeToAccountType,
} from "@features/transactionWizard/stateMachine/utils/utils";
import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";
import {
  AdditionalOption,
  InvestmentSelect,
} from "@components/common/forms/InvestmentSelect";
import { Investment } from "@generated/apiv1";
import { InvestmentValues } from "@features/transactionWizard/stateMachine/types/investmentValues";

type Props = {
  context: TransactionWizardContext;
  handleNext: (investment: InvestmentValues) => void;
  handlePrevious: () => void;
};

export const InvestmentManualStep = ({
  context,
  handleNext,
  handlePrevious,
}: Props) => {
  const [investment, setInvestment] = useState<
    InvestmentValues | AdditionalOption | null
  >(context.investmentValues);
  const [name, setName] = useState<string>();
  const [selectedDepotId, setSelectedDepotId] = useState<number | undefined>(
    context.investmentValues.depotId
  );
  const { t } = useTranslation();

  const type = investmentTypeToAccountType(context.investmentValues.type!);
  const { data, isLoading } = useListAndSearchAllAccounts({
    type: [type],
  });

  const onNext = () => {
    if (!investment) return;

    if (investment && investment?.id !== "new") {
      handleNext(investment as InvestmentValues);
      return;
    }
    return handleNext({
      name,
    });
  };

  const onDepotSelect = (depotId: number | null) => {
    setSelectedDepotId(depotId ?? undefined);
  };

  const onInvestmentSelect = (
    investment: Investment | AdditionalOption | null
  ) => {
    setInvestment({
      ...investmentToInvestmentValues(investment as Investment),
      depotId: selectedDepotId,
    });
    setName(investment?.standardisedName || "");
  };

  const selectableDepots = useMemo(
    () =>
      data?.accounts?.map((depot) => ({
        name: depot.name,
        value: depot.id,
      })) || [],
    [data]
  );

  return (
    <StepContainer
      testid="manual-investment-step"
      title={t("transactionWizard.manualInvestmentStep.title")}
      onNext={onNext}
      onNextDisabled={!investment}
      onPrevious={handlePrevious}
    >
      <Box flexDirection="column" display="flex" gap={6} width="100%">
        <DepotSelect
          onDepotChange={onDepotSelect}
          selectableDepots={selectableDepots}
          isLoading={isLoading}
          selectedDepotId={selectedDepotId}
          allowNewDepot={false}
          fullWidth
        />
        <InvestmentSelect
          onChange={onInvestmentSelect}
          value={investment}
          accountId={selectedDepotId}
          disabled={!selectedDepotId}
          fullWidth
          allowAddingNew={false}
          placeholder={t(
            "transactionWizard.manualInvestmentStep.selectInvestment"
          )}
          label={t("transactionWizard.manualInvestmentStep.selectInvestment")}
        />
      </Box>
    </StepContainer>
  );
};
