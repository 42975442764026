import { UseQueryOptions } from "react-query";
import {
  BillingCyclesApiListAndSearchAllBillingCyclesRequest,
  BillingCycleList,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllBillingCycles = async (
  token: string | undefined,
  params?: BillingCyclesApiListAndSearchAllBillingCyclesRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.listAndSearchAllBillingCycles(params);
  return data;
};

export const useListAndSearchAllBillingCycles = (
  params?: BillingCyclesApiListAndSearchAllBillingCyclesRequest,
  options?: UseQueryOptions<BillingCycleList, AxiosApiError, BillingCycleList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_LIST_AND_SEARCH_ALL_BILLING_CYCLES, params],
    ({ token }) => listAndSearchAllBillingCycles(token, params),
    options
  );
};
