import { UseMutationOptions } from "react-query";
import {
  AccountsApiUnlinkRemoteAccountRequest,
  Account,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const unlinkRemoteAccount = async (
  token: string | undefined,
  params: AccountsApiUnlinkRemoteAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.unlinkRemoteAccount(params);
  return data;
};

export const useUnlinkRemoteAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiUnlinkRemoteAccountRequest
  >
) => {
  return useAuthMutation(unlinkRemoteAccount, options, false);
};
