import { UseMutationOptions } from "react-query";
import {
  StripeApiStripeCreateCheckoutSessionRequest,
  StripeCheckoutSession,
} from "@generated/apiv1";

import { buildStripeApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const stripeCreateCheckoutSession = async (
  token: string | undefined,
  params: StripeApiStripeCreateCheckoutSessionRequest
) => {
  const apiInstance = buildStripeApi(token);
  const { data } = await apiInstance.stripeCreateCheckoutSession(params);
  return data;
};

export const useStripeCreateCheckoutSession = (
  options?: UseMutationOptions<
    StripeCheckoutSession,
    AxiosApiError,
    StripeApiStripeCreateCheckoutSessionRequest
  >
) => {
  return useAuthMutation(stripeCreateCheckoutSession, options, false);
};
