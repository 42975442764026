import React from "react";
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  ToggleButtonGroupProps,
} from "@mui/material";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

interface Props {
  name: string;
  label: FormControlLabelProps["label"];
  testId?: string;
  tooltip?: {
    title?: string;
    text?: string;
  };
}

type FormInputProps<T extends FieldValues> = ToggleButtonGroupProps &
  Props &
  UseControllerProps<T>;

export const RHFSwitch = <T extends FieldValues>(
  props: FormInputProps<T>
): JSX.Element => {
  const { control, name, defaultValue, label, testId } = props;
  const { field } = useController({ control, name, defaultValue });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      gap={3}
    >
      <FormControlLabel
        control={
          <Switch
            checked={field.value}
            onChange={field.onChange}
            color="primary"
            data-testid="tax-and-commission-in-eur-input"
          />
        }
        label={label}
        data-testid={testId}
        sx={{ marginLeft: 0 }}
      />
    </Box>
  );
};
