import { UseQueryOptions } from "react-query";
import {
  BillingCyclesApiGetTaxationEstimateRequest,
  TaxationEstimateList,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getTaxationEstimate = async (
  token: string | undefined,
  params: BillingCyclesApiGetTaxationEstimateRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.getTaxationEstimate(params);
  return data;
};

export const useGetTaxationEstimate = (
  params: BillingCyclesApiGetTaxationEstimateRequest,
  options?: UseQueryOptions<
    TaxationEstimateList,
    AxiosApiError,
    TaxationEstimateList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_GET_TAXATION_ESTIMATE, params],
    ({ token }) => getTaxationEstimate(token, params),
    options
  );
};
