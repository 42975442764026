import { matchPath, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { accountTypes } from "@features/accounts/constants";

export const useAccountTypeAddText = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isDepots =
    matchPath("/depots/add", location.pathname) ||
    matchPath("/depots/add-manually", location.pathname);
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const foundAccountType = accountTypes.find(
    (accountType) => accountType.value === type
  );
  //
  // if (foundAccountType && foundAccountType.value === "DEPOT") {
  //   return t("dashboardPage.dashboard.addPortfolio");
  // }

  if (foundAccountType && foundAccountType.value === "OTHER_MATERIAL_ASSETS") {
    return `${t(
      `depotsAccounts.subcategories.${foundAccountType.value}_add`
    )} ${t("depotsAccounts.add").toLowerCase()}`;
  }

  return foundAccountType
    ? `${t(`depotsAccounts.subcategories.${foundAccountType.value}`)} ${t(
        "depotsAccounts.add"
      ).toLowerCase()}`
    : isDepots
    ? t("accounts.breadcrumbs.addDepots")
    : t("accounts.breadcrumbs.add");
};
