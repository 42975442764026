import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { chunk } from "lodash";
import { ReactComponent as WarnIcon } from "@icons/medium-warn.svg";
import { Banner } from "@components";
import { useUploadFiles } from "@generated/apiv1/pdfParser/hooks";
import {
  ParsingResultActivity,
  UniqueParsingResult,
} from "@features/fileDrop/components/ParsingResult";
import { generateRandomString } from "@features/fileDrop/helpers/bookingLocator";
import { FileDropField } from "@components/common/forms/FileDropField";

export interface Props {
  onParsingResult: (parsingResults: UniqueParsingResult[]) => void;
  setIsParsingFinished: (isParsingFinished: boolean) => void;
  isValidating: boolean;
}

export const FileDrop = ({
  onParsingResult,
  setIsParsingFinished,
  isValidating,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState<null | string>(null);
  const [isParsing, setIsParsing] = useState(false);

  const { mutateAsync } = useUploadFiles();

  const handleDrop = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    setError(null);
    setIsParsingFinished(false);
    setIsParsing(true);

    const chunks = chunk(acceptedFiles, 20);

    const promises = chunks.map(async (chunk) => {
      const formData = new FormData();
      const files: Record<string, File> = chunk.reduce(
        (acc, file) => ({
          ...acc,
          [generateRandomString()]: file,
        }),
        {}
      );

      Object.entries(files).forEach(([id, file]) => {
        formData.append("multipartFiles", file, id);
      });

      const data = await mutateAsync({
        multipartFiles: formData.getAll("multipartFiles") as File[],
      });

      const result = Object.entries(files).reduce((acc, [id, file]) => {
        const activities =
          data.activities?.reduce((acc, activity) => {
            if (activity.sourceFilename === id) {
              return [
                ...acc,
                {
                  parsingResultId: id,
                  ...activity,
                },
              ];
            }
            return acc;
          }, [] as ParsingResultActivity[]) || [];

        if (activities.length === 0) {
          setError(t("investments.errors.pdfImport"));
        }

        return [
          ...acc,
          {
            id,
            file,
            activities,
            successful: activities.length > 0,
            error:
              activities.length === 0
                ? t("transactionUpload.somethingWrong")
                : undefined,
          },
        ];
      }, [] as UniqueParsingResult[]);

      onParsingResult(result);
    });

    Promise.allSettled(promises)
      .catch(() => {
        setError(t("investments.errors.pdfImport"));
      })
      .finally(() => {
        setIsParsing(false);
        setIsParsingFinished(true);
      });
  };

  return (
    <>
      <FileDropField
        onDrop={handleDrop}
        isParsing={isParsing || isValidating}
        text={t("transactionUpload.dropOrSelectFile")}
        accept={{ "application/pdf": [".pdf"] }}
      />
      {error && (
        <Banner
          type="error"
          icon={
            <WarnIcon width="20" height="20" color={theme.palette.icon.error} />
          }
          mb={3}
          mt={3}
          text={
            <Typography color={theme.palette.text.negative}>{error}</Typography>
          }
        />
      )}
    </>
  );
};
