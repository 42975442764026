import React, { useRef, useState } from "react";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@components";
import { SecuritiesSearch } from "@features/securities/SecuritiesSearch";

import { QuoteV2, SymbolV2 } from "@generated/apiv1";
import { useGetQuotesV2 } from "@generated/apiv1/hooks";
import { filterGroups } from "@features/transactionWizard/modal/steps/constants";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { getDisabledFilters } from "@features/transactionWizard/modal/stateMachine/utils/utils";

const Filters = ({
  filter,
  onOtherClick,
  onSelect,
  disabledFilters,
}: {
  filter: keyof typeof filterGroups | undefined;
  onOtherClick: (filter: keyof typeof filterGroups) => void;
  onSelect: (filter: keyof typeof filterGroups) => void;
  disabledFilters?: string[];
}) => {
  const { t } = useTranslation();
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      height="100%"
      p={0.25}
      sx={{
        overflowY: "auto",
      }}
    >
      <Grid container rowSpacing={6} columnSpacing={6}>
        {Object.keys(filterGroups).map((group) => {
          const isActive = filter === group;
          const isDisabled = disabledFilters?.includes(group);

          const groupTitle =
            group !== "securities" && group !== "crypto"
              ? t(`transactionWizardModal.searchSymbol.types.${group}.title`)
              : group === "securities"
              ? "Depot"
              : `${t(
                  `transactionWizardModal.searchSymbol.types.${group}.title`
                )}${t(
                  `transactionWizardModal.tooltipInfoForDisabledSearchSymbols.addOnForCrypto`
                )}`;

          return (
            <Tooltip
              openOnClick={!isDisabled}
              key={group}
              text={t(
                "transactionWizardModal.tooltipInfoForDisabledSearchSymbols.text",
                {
                  searchSymbolType: groupTitle,
                }
              )}
            >
              <Grid item xs={12} sm={6}>
                <Box
                  p={4}
                  display="flex"
                  alignItems="center"
                  gap={4}
                  height={isLessMd && !isLessSm ? "102px" : "auto"}
                  sx={(theme) => ({
                    pointerEvents: isDisabled ? "none" : "auto",
                    border: `1px solid ${
                      isActive
                        ? theme.palette.border.active
                        : theme.palette.border.secondary
                    }`,
                    outline: isActive
                      ? `1px solid ${theme.palette.border.active}`
                      : "none",
                    borderRadius: `${theme.shape.borderRadius}px`,
                    cursor: "pointer",
                    "&:hover": {
                      outline: `1px solid ${
                        isActive
                          ? theme.palette.border.active
                          : theme.palette.primary.lighter
                      }`,
                      borderColor: isActive
                        ? theme.palette.border.active
                        : theme.palette.primary.lighter,
                    },
                  })}
                  onClick={(event) => {
                    // otherwise it would close again on outer click
                    event.stopPropagation();
                    // focus on the search field
                    (
                      document.querySelector(
                        "#securities-search-transaction-modal"
                      ) as HTMLElement
                    )?.focus();
                    group === "other" || group === "materialAssets"
                      ? onOtherClick(group)
                      : onSelect(group);
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="40px"
                    height="40px"
                    sx={(theme) => ({
                      borderRadius: `${theme.shape.borderRadius}px`,
                      backgroundColor: isDisabled
                        ? theme.palette.icon.disabled
                        : isActive
                        ? theme.palette.primary.main
                        : theme.palette.background.info,
                      color:
                        isDisabled || isActive
                          ? theme.palette.common.white
                          : theme.palette.icon.active,
                    })}
                  >
                    {filterGroups[group].icon}
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      color={isDisabled ? "text.disabled" : "text.primary"}
                    >
                      {t(
                        `transactionWizardModal.searchSymbol.types.${group}.title`
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={isDisabled ? "text.disabled" : "text.secondary"}
                      fontWeight={400}
                    >
                      {t(
                        `transactionWizardModal.searchSymbol.types.${group}.subtitle`
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>
    </Box>
  );
};

type Props = {
  context: TransactionWizardContext;
  handleNext: (symbol: SymbolV2 & { quote: QuoteV2 }) => void;
  onOtherClick: (filter: keyof typeof filterGroups) => void;
};

export const SearchSymbolStep = ({
  context,
  handleNext,
  onOtherClick,
}: Props) => {
  const ref = useRef<{ openSearch?: () => void }>();
  const [filter, setFilter] = useState<keyof typeof filterGroups>();
  const [securityToAdd, setSecurityToAdd] = useState<SymbolV2>();
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useGetQuotesV2(
    {
      securityId: [securityToAdd?.id!],
      quoteProvider: securityToAdd?.quoteProvider!,
    },
    {
      enabled: Boolean(securityToAdd),
      onSuccess: (data) => {
        const quote = data.quotes?.[0];
        if (quote && securityToAdd) {
          handleNext({
            ...securityToAdd,
            quote: quote,
          });
        }
      },
    }
  );

  const disabledFilters = getDisabledFilters(context);

  return (
    <Box height="100%">
      <SecuritiesSearch
        filters={
          <Filters
            filter={filter}
            onSelect={(filter) => {
              setFilter(filter);
              // open search in addition to focus above
              ref.current?.openSearch?.();
            }}
            onOtherClick={onOtherClick}
            disabledFilters={disabledFilters}
          />
        }
        ref={ref}
        id="securities-search-transaction-modal"
        prepicks={filter ? filterGroups[filter]?.prepicks : []}
        selectedFilters={filter ? filterGroups[filter]?.types : []}
        onSymbolSelect={setSecurityToAdd}
        placeholder={t("transactionWizardModal.searchSymbol.searchPlaceholder")}
        searchHeight={300}
        shouldCloseOnOuterClick
        showMobileVersion={isLessSm}
      />
    </Box>
  );
};
