import { UseQueryOptions } from "react-query";
import { loadCurrencyClassification } from "@api/v1/methods";
import { InvestmentTypeBreakdownResponse } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { CLASSIFICATION_BY_CURRENCY } from "../../cacheKeys";

export const useClassificationByCurrency = (
  accountIds?: number[],
  options?: UseQueryOptions<
    InvestmentTypeBreakdownResponse,
    AxiosApiError,
    InvestmentTypeBreakdownResponse
  >
) => {
  return useAuthQuery<
    InvestmentTypeBreakdownResponse,
    AxiosApiError,
    InvestmentTypeBreakdownResponse
  >(
    [CLASSIFICATION_BY_CURRENCY, accountIds],
    ({ token }) => loadCurrencyClassification(token, accountIds),
    options
  );
};
