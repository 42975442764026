import React, { useState } from "react";
import { useLocation } from "react-use";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Wizard, withIf, withReference, WizardsHeader } from "@components";
import { useValidation } from "./hooks/useValidation";
import { useTransfer } from "./hooks/useTransfer";
import { SelectTransferTypeStep } from "./components/SelectTransferTypeStep";
import { DepotAutocompleteStep } from "./components/DepotAutocompleteStep";
import { DateOfTransferStep } from "./components/DateOfTransferStep";
import { SelectInvestmentsStep } from "./components/SelectInvestmentsStep";
import { FullLotsStep } from "./components/FullLotsStep";
import { SuccessStep } from "./components/SuccessStep";

const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
`;

const NoScroll = styled.div`
  overflow: hidden;
`;

const isAnimationOff = process.env.REACT_APP_WIZARD_ANIMATION_OFF === "true";

type TransferType = "full" | "partial";
type TransferFullLotsType = "onlyFullLots" | "fullLotsWithPartial";

export interface InvestmentToTransfer {
  id: number;
  numberOfLots?: number;
}

export interface Values {
  transferType?: TransferType;
  fromAccountId?: number;
  toAccountId?: number;
  dateOfTransfer?: Date;
  investmentsToTransfer?: InvestmentToTransfer[];
  transferFullLots?: TransferFullLotsType;
}

interface Props {
  fromAccountId: number;
}

const isPartialTransfer = ({ transferType }: Partial<Values>) =>
  transferType === "partial";

const isFullTransfer = ({ transferType }: Partial<Values>) =>
  transferType === "full";

export const DepotTransferWizard = ({ fromAccountId }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { validate, isLoading } = useValidation(fromAccountId);
  const transfer = useTransfer();
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState<string>();

  const handleFinish = async (values: Values) => {
    try {
      await transfer(values);
      setError(undefined);
      setFinished(true);
    } catch (e) {
      // TODO: Find a solution for types
      // @ts-ignore
      setError(e.response.data.message);
    }
  };

  const redirectUrl =
    (location.search &&
      new URLSearchParams(location.search).get("redirectUrl")) ||
    "/";

  return (
    <>
      <Helmet>
        <title>{t("depotTransfer.title")}</title>
      </Helmet>

      <NoScroll data-testid="depot-transfer-wizard">
        {!finished && (
          <Wizard<Values>
            loading={isLoading}
            initialData={{ fromAccountId }}
            animation={!isAnimationOff}
            onFinish={handleFinish}
            validation={validate}
            children={[
              withReference("transferType", SelectTransferTypeStep),
              withIf(isPartialTransfer, [
                withReference<Partial<Values>, "investmentsToTransfer">(
                  "investmentsToTransfer",
                  SelectInvestmentsStep
                ),
              ]),
              withReference("toAccountId", DepotAutocompleteStep),
              withIf(isFullTransfer, [
                withReference("transferFullLots", FullLotsStep),
              ]),
              withReference("dateOfTransfer", (props) => (
                <DateOfTransferStep {...props} errorMessage={error} />
              )),
            ]}
          />
        )}
        {finished && <SuccessStep backUrl={redirectUrl} />}
        <HeaderContainer>
          <WizardsHeader
            backUrl={redirectUrl}
            title={t("depotTransfer.header.title")}
          />
        </HeaderContainer>
      </NoScroll>
    </>
  );
};
