import { UseQueryOptions } from "react-query";
import { CommissionPayedInFollowingYearList } from "@generated/apiv1";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const securityCommissionsSecurityCommissionsPayedInFollowingYear =
  async (token: string | undefined) => {
    const apiInstance = buildSecurityCommissionsApi(token);
    const { data } =
      await apiInstance.securityCommissionsSecurityCommissionsPayedInFollowingYear();
    return data;
  };

export const useSecurityCommissionsSecurityCommissionsPayedInFollowingYear = (
  options?: UseQueryOptions<
    CommissionPayedInFollowingYearList,
    AxiosApiError,
    CommissionPayedInFollowingYearList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.SECURITYCOMMISSIONS_SECURITY_COMMISSIONS_SECURITY_COMMISSIONS_PAYED_IN_FOLLOWING_YEAR,
    ],
    ({ token }) =>
      securityCommissionsSecurityCommissionsPayedInFollowingYear(token),
    options
  );
};
