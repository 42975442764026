import React, { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

const handleAutocomplete = ({
  userFlowId,
  element: option,
  data,
}: {
  userFlowId: string;
  element: HTMLLIElement;
  data?: Record<string, string | undefined | null>;
}) => {
  window.dataLayer?.push({
    userFlowId,
    event: "track-event",
    inputLabel: (option.textContent || option.innerText)?.toLowerCase(),
    ...data,
  });
};

const handleButton = ({
  userFlowId,
  element: button,
  data,
}: {
  userFlowId: string;
  element: HTMLButtonElement;
  data?: Record<string, string | undefined | null>;
}) => {
  const buttonId = button.getAttribute("data-testid");
  if (!buttonId) return; // ensure that we track only buttons that we care about

  const buttonText =
    button.textContent ||
    button.innerText ||
    button.querySelector("img")?.title;

  window.dataLayer?.push({
    userFlowId,
    event: "track-event",
    buttonId: button.getAttribute("data-testid"),
    inputLabel: buttonText?.toLowerCase(),
    ...data,
  });
};

const handleRadioGroup = ({
  userFlowId,
  element: radio,
  data,
}: {
  userFlowId: string;
  element: HTMLDivElement;
  data?: Record<string, string | undefined | null>;
}) => {
  const radioId = radio.getAttribute("data-testid");
  if (!radioId) return; // ensure that we track only buttons that we care about

  const radioText =
    radio.querySelector("img")?.title || radio.textContent || radio.innerText;

  window.dataLayer?.push({
    userFlowId,
    event: "track-event",
    buttonId: radioId,
    inputLabel: radioText?.toLowerCase(),
    ...data,
  });
};

const handleAnchor = ({
  userFlowId,
  element: anchor,
  data,
}: {
  userFlowId: string;
  element: HTMLAnchorElement;
  data?: Record<string, string | undefined | null>;
}) => {
  window.dataLayer?.push({
    userFlowId,
    event: "track-event",
    buttonId: anchor.getAttribute("data-testid"),
    inputLabel: (anchor.textContent || anchor.innerText)?.toLowerCase(),
    ...data,
  });
};

export const useGoogleTagManagerHandlers = ({
  userFlowId,
  trackedElements = [],
  ignoredSelectors = [],
  data = {},
}: {
  userFlowId: string;
  trackedElements: ("button" | "autocomplete" | "anchor" | "radio-group")[];
  ignoredSelectors?: string[];
  data?: Record<string, string | undefined | null>;
}) => {
  const onClick = useCallback(
    ({ target }: React.MouseEvent<HTMLElement>) => {
      if (!(target instanceof Element)) return;

      const autocompleteOption = target.closest("li[class*='MuiAutocomplete']");
      const isAutocomplete = Boolean(autocompleteOption);

      if (isAutocomplete && trackedElements.includes("autocomplete")) {
        if (
          ignoredSelectors.some((selector) =>
            autocompleteOption?.matches(selector)
          )
        ) {
          return;
        }
        handleAutocomplete({
          userFlowId,
          element: autocompleteOption as HTMLLIElement,
          data,
        });
      }

      const button = target.closest("button");
      const isButton = Boolean(button);

      if (isButton && trackedElements.includes("button")) {
        if (ignoredSelectors.some((selector) => button?.matches(selector))) {
          return;
        }
        handleButton({
          userFlowId,
          element: button as HTMLButtonElement,
          data,
        });
      }

      const radioElement = target.closest("[role='radio']");
      const radioGroup = target.closest("[role='radiogroup']");
      const isRadioGroup = Boolean(radioGroup && radioElement);

      if (isRadioGroup && trackedElements.includes("radio-group")) {
        if (
          ignoredSelectors.some((selector) => radioGroup?.matches(selector))
        ) {
          return;
        }
        handleRadioGroup({
          userFlowId,
          element: radioElement as HTMLDivElement,
          data,
        });
      }

      const anchor = target.closest("a");
      const isAnchor = Boolean(anchor);
      if (isAnchor && trackedElements.includes("anchor")) {
        if (ignoredSelectors.some((selector) => anchor?.matches(selector))) {
          return;
        }
        handleAnchor({
          userFlowId,
          element: anchor as HTMLAnchorElement,
          data,
        });
      }
    },
    [userFlowId, data, ignoredSelectors, trackedElements]
  );

  const onInput = useDebouncedCallback(
    ({ target }: React.KeyboardEvent<HTMLInputElement>) => {
      if (!(target instanceof Element)) return;

      window.dataLayer?.push({
        userFlowId,
        event: "track-event",
        inputLabel: (target as HTMLInputElement).placeholder?.toLowerCase(),
        ...data,
      });
    },
    500
  );

  return {
    onClick,
    onInput,
  };
};
