import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";

export const SummaryTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell variant="head">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.summaryStep.dividendsTable.headers.date")}
          </Typography>
        </TableCell>
        <TableCell variant="head">
          <Typography variant="body1" fontWeight={600}>
            {t(
              "batchDividendsWizard.summaryStep.dividendsTable.headers.amount"
            )}
          </Typography>
        </TableCell>
        <TableCell variant="head">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.summaryStep.dividendsTable.headers.tax")}
          </Typography>
        </TableCell>
        <TableCell variant="head" align="right">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.summaryStep.dividendsTable.headers.total")}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
