import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import truncate from "lodash/truncate";
import { PageLayout, Tab, Tabs, GainLossPercents } from "@components";
import { FundamentalsPageByIsin } from "@features/fundamentals/FundamentalsPageByIsin";
import { toCurrency, toPercent, intervalToTimeRange } from "@helpers";
import {
  ComparisonValue,
  SelectComparison,
} from "@components/Chart/BaseChartTools/SelectComparison";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { QuoteChartContainer } from "@components/Chart/QuoteChartContainer";
import { useGetSecurity } from "@generated/apiv3/quotes/hooks";
import { useGetQuotesV2 } from "@generated/apiv1/hooks";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { convertSecurityToSymbolV2 } from "@features/securities/security/convertSecurityToSymbolV2";
import { SingleSecurityPageHeader } from "../components/SingleSecurityPageHeader";

const tabKeys = {
  fundamentals: "fundamentals",
  news: "news",
};

export interface SingleSecurityPageQueryParams {
  tab?: string;
  tickerSymbol?: string;
  quoteProvider?: string;
}

export const SingleSecurityPage = () => {
  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );
  const [comparedTicker, setComparedTicker] = React.useState<
    ComparisonValue | undefined
  >();

  const defaultTabKey = searchParams.get("tab");
  const id = searchParams.get("id");
  const quoteProvider = searchParams.get("quoteProvider");

  const { t } = useTranslation();
  const { data } = useGetSecurity(
    {
      id: id!,
      quoteProvider: quoteProvider!,
    },
    {
      enabled: !!id && !!quoteProvider,
    }
  );
  const { data: quoteData } = useGetQuotesV2(
    {
      securityId: [id!],
      quoteProvider: quoteProvider ?? "",
    },
    {
      enabled: !!id && !!quoteProvider,
    }
  );

  const symbol = useMemo(() => {
    if (data?.data) {
      return convertSecurityToSymbolV2(data?.data?.id!, data?.data?.attributes);
    }
  }, [data]);

  const quote = quoteData?.quotes?.[0];

  const securityForLogos = useMemo(() => {
    if (!symbol || !quoteProvider || !quote?.tickerSymbol) return [];

    return [
      {
        ...symbol,
        type: quoteProvider === "currency" ? "81_fcurr" : "11_stock",
        hasValidIsin: true,
        isin: symbol.isin || symbol.id,
        ticker_symbol: quote.tickerSymbol,
        quoteProvider,
      },
    ];
  }, [symbol, quoteProvider, quote]);

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: "/",
      text: t("singleSecurityPage.breadcrumbs.home"),
    },
    {
      to: "/investments",
      text: t("sidebar.investments"),
    },
    {
      text: truncate(symbol?.name),
    },
  ];

  const logo = useSecurityImages(symbol ? securityForLogos : []);
  const isBond = symbol?.type === InvestmentTypeEnum._31_BOND;

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  const handleTabChange = (key: string) => {
    if (!id || !quoteProvider) return;

    setSearchParams({
      tab: key,
      id,
      quoteProvider: quoteProvider,
    });
  };

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <SingleSecurityPageHeader
        image={logo[symbol?.isin || ""] || logo[quote?.tickerSymbol || ""]}
        isETF={symbol?.type === "22_etf"}
        quote={quote}
        symbol={symbol}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        mb={6}
        gap={isDownMd ? 6 : 3}
        flexDirection={isDownMd ? "column" : "row"}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" mb={3}>
            {t("watchlistEntry.details.gainLossStatistics.currentQuote")}
          </Typography>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography fontSize="36px">
              {isBond
                ? toPercent((quote?.last ?? 0) / 100)
                : toCurrency(quote?.last ?? 0, quote?.currency)}
            </Typography>
            <GainLossPercents value={(quote?.changePercent ?? 0) / 100} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <RangeSelector
            range={selectedRange === "custom" ? range : undefined}
            onSelect={setSelectedRange}
            onCustomSelect={onCustomRangeSelect}
            intervalType={selectedRange}
          />
        </Box>
      </Box>

      <QuoteChartContainer
        mainQuoteProvider={quoteProvider ?? ""}
        mainName={symbol?.name ?? ""}
        mainTickerSymbol={quote?.tickerSymbol ?? ""}
        comparedName={comparedTicker?.label}
        comparedQuoteProvider={comparedTicker?.quoteProvider}
        comparedTickerSymbol={comparedTicker?.value}
        range={range}
        selectedInterval={selectedRange}
        isBond={isBond}
      />

      <Box my={6} display="flex" justifyContent="flex-end" width="100%">
        <Box width={isDownMd ? "100%" : 334}>
          <SelectComparison
            value={comparedTicker}
            onChange={setComparedTicker}
          />
        </Box>
      </Box>

      <Tabs
        onChange={handleTabChange}
        defaultKey={defaultTabKey}
        variant="scrollable"
      >
        <Tab
          label={t("singleSecurityPage.tabs.fundamentals")}
          key={tabKeys.fundamentals}
        >
          {quote?.tickerSymbol && symbol?.isin && quoteProvider && (
            <FundamentalsPageByIsin
              quoteProvider={quoteProvider}
              tickerSymbol={quote?.tickerSymbol}
              isin={symbol.isin}
            />
          )}
        </Tab>
      </Tabs>
    </PageLayout>
  );
};
