import React, { useMemo } from "react";
import {
  Box,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import i18next from "i18next";
import { DonutChart, DonutChartItem } from "@components/Chart/DonutChart";
import { InvestmentsChartDataStatistic } from "@features/dashboard/useInvestmentsChartDataTotal";
import { Investment } from "@generated/apiv1";
import { toCurrency, toPercent } from "@helpers";
import { GainLossPercents, GainLossTypography } from "@components";
import { useChartColors } from "@hooks";
import { BlurredTypography } from "@components/BlurredTypography";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

const prepareChartData = (
  theme: Theme,
  statistics?: InvestmentsChartDataStatistic,
  investments?: Investment[],
  colors?: string[],
  isAggregated?: boolean
) => {
  return [
    ...(investments || []).map(
      (investment): DonutChartItem => ({
        id: `${
          investment.id ?? investment.aggregation?.identifier
        }-${isAggregated}`,
        name: investment.standardisedName,
        y: investment.currentMarketValueAC,
        value: investment.currentMarketValue,
        currency: investment.currency,
      })
    ),

    {
      id: "cash",
      name: i18next.t("dashboardPage.dashboard.mainDataSection.cash"),
      y: Math.abs(statistics?.cashBalance ?? 0),
      value: statistics?.cashBalance ?? 0,
      currency: "EUR",
    },
  ]
    .sort((a, b) => b.y - a.y)
    .map((item, index) => {
      const color =
        item.id === "cash"
          ? (statistics?.cashBalance ?? 0) > 0
            ? theme.palette.graphVisual["02"].mid
            : theme.palette.graphVisual["04"].mid
          : colors![index % colors!.length];
      return {
        ...item,
        color,
      };
    });
};

type Props = {
  investments?: Investment[];
  statistics?: InvestmentsChartDataStatistic;
  isLoading?: boolean;
  isAggregated?: boolean;
};

export const MainDataSection = ({
  statistics,
  investments,
  isAggregated,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { isAnonymized } = useSharedPortfolioContext();
  const theme = useTheme();
  const colors = useChartColors("dashboardDonutChart");
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const data = useMemo(
    () =>
      isLoading
        ? []
        : prepareChartData(
            theme,
            statistics,
            investments,
            colors!,
            isAggregated
          ),
    [investments, statistics, isLoading, colors, theme, isAggregated]
  );

  return (
    <Box p={isSm ? 0 : 6} display="flex" flexDirection="column" height="100%">
      <Box mb={12} width="100%" height={isSm ? 340 : "auto"} minHeight={340}>
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <Box
              width={width}
              height={height}
              display="flex"
              justifyContent="center"
            >
              <DonutChart
                view={isAnonymized ? "percentage" : "absolute"}
                chartData={data || []}
                totalValue={statistics?.portfolioValue ?? 0}
                totalValueMaximumDigits={0}
                totalValueText={t(
                  "dashboardPage.dashboard.mainDataSection.assets"
                )}
                height={isSm || height < 340 ? 340 : height}
                width={width}
                isLoading={isLoading}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>
      <Box px={isSm ? 0 : 3}>
        <Typography variant="body2" color="textSecondary">
          {t("dashboardPage.dashboard.header.subheader")}
        </Typography>
        <Box display="flex" gap={4} alignItems="center" mb={1}>
          <BlurredTypography variant="h4">
            {toCurrency(statistics?.portfolioValue || 0, "EUR")}
          </BlurredTypography>
          <GainLossPercents value={statistics?.changeToday ?? 0} />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={6}>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.cash")}
            </Typography>
            <BlurredTypography
              value={statistics?.cashBalance ?? 0}
              showBlackOnPositive
            >
              {toCurrency(statistics?.cashBalance)}
            </BlurredTypography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.holdings")}
            </Typography>
            <BlurredTypography>
              {toCurrency(statistics?.holdings)}
            </BlurredTypography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.returnOnInvestment")}
            </Typography>
            <BlurredTypography value={statistics?.valuationGain ?? 0}>
              {toCurrency(statistics?.valuationGain)}
            </BlurredTypography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.rateOfReturn")}
            </Typography>
            <GainLossTypography value={statistics?.irr ?? 0}>
              {toPercent(statistics?.irr ?? 0)}
            </GainLossTypography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.dividends")}
            </Typography>
            <BlurredTypography
              value={
                isAnonymized
                  ? statistics?.dividendYield ?? 0
                  : statistics?.dividendGain ?? 0
              }
            >
              {isAnonymized
                ? toPercent(statistics?.dividendYield)
                : toCurrency(statistics?.dividendGain)}
            </BlurredTypography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboardPage.dashboard.mainDataSection.realizedGain")}
            </Typography>
            <BlurredTypography value={statistics?.realizedGain ?? 0}>
              {toCurrency(statistics?.realizedGain)}
            </BlurredTypography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
