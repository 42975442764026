export const CACHE_KEYS = {
  COMPANYLOGOS_GET_COMPANY_LOGOS: "COMPANYLOGOS_GET_COMPANY_LOGOS",
  DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND: "DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND",
  DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND_IN_BATCH:
    "DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND_IN_BATCH",
  QUOTES_CLASSIFY_SECURITIES: "QUOTES_CLASSIFY_SECURITIES",
  QUOTES_GET_HISTORIC_QUOTES: "QUOTES_GET_HISTORIC_QUOTES",
  QUOTES_GET_HISTORIC_QUOTES_IN_BATCH: "QUOTES_GET_HISTORIC_QUOTES_IN_BATCH",
  QUOTES_GET_LATEST_BY_ISIN: "QUOTES_GET_LATEST_BY_ISIN",
  QUOTES_GET_QUOTE: "QUOTES_GET_QUOTE",
  QUOTES_GET_QUOTES: "QUOTES_GET_QUOTES",
  QUOTES_GET_SECURITIES: "QUOTES_GET_SECURITIES",
  QUOTES_GET_SECURITY: "QUOTES_GET_SECURITY",
};
