/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { PensionGap } from '../model';
/**
 * RetirementCalculatorApi - axios parameter creator
 * @export
 */
export const RetirementCalculatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate required capital at the start of retirement.
         * @param {number} monthlyStatePension The monthly state pension (current value, not inflation adjusted); anything &gt;&#x3D; 0
         * @param {number} monthlyPrivatePension The monthly private pension; e.g. a live insurance (current value, not inflation adjusted);                              anything &gt;&#x3D; 0
         * @param {number} requiredPension The monthly required / desired pension (current value, not inflation adjusted); anything &gt; 0
         * @param {number} inflationRate The expected inflation rate (YOY); anything &gt;&#x3D; 0
         * @param {number} yearsToRetirement The number of years until the user wants to retire (&#x3D; number of years he is still working);                              anything &gt;&#x3D; 0
         * @param {number} pensionPaymentYears The number of years we want to receive the pension; anything &gt; 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pensionGap: async (monthlyStatePension: number, monthlyPrivatePension: number, requiredPension: number, inflationRate: number, yearsToRetirement: number, pensionPaymentYears: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monthlyStatePension' is not null or undefined
            assertParamExists('pensionGap', 'monthlyStatePension', monthlyStatePension)
            // verify required parameter 'monthlyPrivatePension' is not null or undefined
            assertParamExists('pensionGap', 'monthlyPrivatePension', monthlyPrivatePension)
            // verify required parameter 'requiredPension' is not null or undefined
            assertParamExists('pensionGap', 'requiredPension', requiredPension)
            // verify required parameter 'inflationRate' is not null or undefined
            assertParamExists('pensionGap', 'inflationRate', inflationRate)
            // verify required parameter 'yearsToRetirement' is not null or undefined
            assertParamExists('pensionGap', 'yearsToRetirement', yearsToRetirement)
            // verify required parameter 'pensionPaymentYears' is not null or undefined
            assertParamExists('pensionGap', 'pensionPaymentYears', pensionPaymentYears)
            const localVarPath = `/v1/retirementCalculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (monthlyStatePension !== undefined) {
                localVarQueryParameter['monthlyStatePension'] = monthlyStatePension;
            }

            if (monthlyPrivatePension !== undefined) {
                localVarQueryParameter['monthlyPrivatePension'] = monthlyPrivatePension;
            }

            if (requiredPension !== undefined) {
                localVarQueryParameter['requiredPension'] = requiredPension;
            }

            if (inflationRate !== undefined) {
                localVarQueryParameter['inflationRate'] = inflationRate;
            }

            if (yearsToRetirement !== undefined) {
                localVarQueryParameter['yearsToRetirement'] = yearsToRetirement;
            }

            if (pensionPaymentYears !== undefined) {
                localVarQueryParameter['pensionPaymentYears'] = pensionPaymentYears;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetirementCalculatorApi - functional programming interface
 * @export
 */
export const RetirementCalculatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RetirementCalculatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate required capital at the start of retirement.
         * @param {number} monthlyStatePension The monthly state pension (current value, not inflation adjusted); anything &gt;&#x3D; 0
         * @param {number} monthlyPrivatePension The monthly private pension; e.g. a live insurance (current value, not inflation adjusted);                              anything &gt;&#x3D; 0
         * @param {number} requiredPension The monthly required / desired pension (current value, not inflation adjusted); anything &gt; 0
         * @param {number} inflationRate The expected inflation rate (YOY); anything &gt;&#x3D; 0
         * @param {number} yearsToRetirement The number of years until the user wants to retire (&#x3D; number of years he is still working);                              anything &gt;&#x3D; 0
         * @param {number} pensionPaymentYears The number of years we want to receive the pension; anything &gt; 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pensionGap(monthlyStatePension: number, monthlyPrivatePension: number, requiredPension: number, inflationRate: number, yearsToRetirement: number, pensionPaymentYears: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PensionGap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pensionGap(monthlyStatePension, monthlyPrivatePension, requiredPension, inflationRate, yearsToRetirement, pensionPaymentYears, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RetirementCalculatorApi.pensionGap']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RetirementCalculatorApi - factory interface
 * @export
 */
export const RetirementCalculatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RetirementCalculatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate required capital at the start of retirement.
         * @param {RetirementCalculatorApiPensionGapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pensionGap(requestParameters: RetirementCalculatorApiPensionGapRequest, options?: RawAxiosRequestConfig): AxiosPromise<PensionGap> {
            return localVarFp.pensionGap(requestParameters.monthlyStatePension, requestParameters.monthlyPrivatePension, requestParameters.requiredPension, requestParameters.inflationRate, requestParameters.yearsToRetirement, requestParameters.pensionPaymentYears, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pensionGap operation in RetirementCalculatorApi.
 * @export
 * @interface RetirementCalculatorApiPensionGapRequest
 */
export interface RetirementCalculatorApiPensionGapRequest {
    /**
     * The monthly state pension (current value, not inflation adjusted); anything &gt;&#x3D; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly monthlyStatePension: number

    /**
     * The monthly private pension; e.g. a live insurance (current value, not inflation adjusted);                              anything &gt;&#x3D; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly monthlyPrivatePension: number

    /**
     * The monthly required / desired pension (current value, not inflation adjusted); anything &gt; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly requiredPension: number

    /**
     * The expected inflation rate (YOY); anything &gt;&#x3D; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly inflationRate: number

    /**
     * The number of years until the user wants to retire (&#x3D; number of years he is still working);                              anything &gt;&#x3D; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly yearsToRetirement: number

    /**
     * The number of years we want to receive the pension; anything &gt; 0
     * @type {number}
     * @memberof RetirementCalculatorApiPensionGap
     */
    readonly pensionPaymentYears: number
}

/**
 * RetirementCalculatorApi - object-oriented interface
 * @export
 * @class RetirementCalculatorApi
 * @extends {BaseAPI}
 */
export class RetirementCalculatorApi extends BaseAPI {
    /**
     * 
     * @summary Calculate required capital at the start of retirement.
     * @param {RetirementCalculatorApiPensionGapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetirementCalculatorApi
     */
    public pensionGap(requestParameters: RetirementCalculatorApiPensionGapRequest, options?: RawAxiosRequestConfig) {
        return RetirementCalculatorApiFp(this.configuration).pensionGap(requestParameters.monthlyStatePension, requestParameters.monthlyPrivatePension, requestParameters.requiredPension, requestParameters.inflationRate, requestParameters.yearsToRetirement, requestParameters.pensionPaymentYears, options).then((request) => request(this.axios, this.basePath));
    }
}

