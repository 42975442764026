import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Symbol } from "@api";
import { useSearchSymbols } from "@api/v1/hooks/useSymbols";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { SymbolSearchInput } from "./SymbolSearchInput";
import { SearchExamples } from "./SearchExamples";

const Container = styled.div`
  width: 100%;
`;

export interface Props {
  search?: string;
  noManual?: boolean;
  defaultSearchValue?: string;
  onSelectSymbol: (symbol: Symbol) => void;
  type?: InvestmentTypeEnum;
  depotId?: number;
}

export const SymbolSearch = ({
  search,
  defaultSearchValue,
  onSelectSymbol,
  noManual,
  type,
  depotId,
}: Props) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    search ? search : defaultSearchValue
  );

  const openManualWizard = () => {
    const params = [
      ["type", type],
      ["depotId", depotId !== undefined ? String(depotId) : undefined],
    ].filter(([_, value]) => Boolean(value)) as [string, string][];
    const string = new URLSearchParams(params).toString();

    navigate(`/manual-transaction-wizard${string.length ? `?${string}` : ""}`);
  };

  const { data, isLoading, isIdle } = useSearchSymbols(
    searchValue || defaultSearchValue,
    10
  );

  return (
    <Container>
      <SymbolSearchInput
        search={searchValue || ""}
        handleChange={setSearchValue}
        symbols={data?.symbols || []}
        showLoadingSkeleton={isLoading}
        showNoResults={!isLoading && !isIdle && !data?.symbols?.length}
        onSelectSymbol={onSelectSymbol}
      />

      {(isIdle || (!isLoading && !data?.symbols?.length)) && (
        <SearchExamples
          handleManualInvestmentClick={openManualWizard}
          noManual={noManual}
        />
      )}
    </Container>
  );
};
