import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as UndoIcon } from "@icons/discard.svg";
import { LoadingButton } from "@components";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(1)};
  & > :not(style) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export interface AccountHeaderWithRollbackProps {
  onRollback: () => void;
  name: string;
  iban?: string;
  showRollbackLink?: boolean;
  rollbackErrorMessage?: string;
  isRollbackLoading: boolean;
}

export const AccountHeaderWithRollback = ({
  onRollback,
  isRollbackLoading,
  name,
  iban,
  showRollbackLink = true,
  rollbackErrorMessage,
}: AccountHeaderWithRollbackProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {showRollbackLink && (
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="text"
            size="small"
            isLoading={isRollbackLoading}
            onClick={() => onRollback()}
            startIcon={<UndoIcon />}
          >
            {`${t("investments.synchronizationLog.rollback")}`}
          </LoadingButton>
        </Box>
      )}

      {rollbackErrorMessage && (
        <Typography color="textPrimary">{rollbackErrorMessage}</Typography>
      )}

      <Heading>
        <div>
          <Typography color="textSecondary">{name}:</Typography>
          {iban && (
            <Typography>
              <strong>{iban}</strong>
            </Typography>
          )}
        </div>
      </Heading>
    </Container>
  );
};
