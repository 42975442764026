import React from "react";
import { useTranslation } from "react-i18next";
import { useMachine } from "@xstate/react";
import { InvestmentTypeEnum } from "@api";
import { LoadingProgressBar, PageLayout } from "@components";
import { SearchSymbolStep } from "@features/transactionWizard/steps/SearchSymbolStep";
import { QuoteV2, Symbol, SymbolV2 } from "@generated/apiv1";
import { manualTransactionWizardSM } from "./stateMachine/manualTransactionWizardSM";
import { useStateHelpers } from "./hooks/useStateHelpers";
import { InvestmentTypeStep } from "./steps/InvestmentTypeStep";
import { NewTransactionSteps } from "./NewTransactionSteps";

export interface Props {
  depotId?: number;
  type?: InvestmentTypeEnum | null;
}

export const ManualTransactionWizard = ({ depotId, type }: Props) => {
  const [currentState, sendEvent] = useMachine(manualTransactionWizardSM);
  const { isLoadingState } = useStateHelpers(currentState);

  const { t } = useTranslation();
  return (
    <PageLayout
      isLoading={isLoadingState()}
      breadcrumbs={[
        { text: t("breadcrumbs.home"), to: "/" },
        { to: "/investments", text: t("breadcrumbs.investments") },
        { text: t("transactionWizard.manual.title") },
      ]}
    >
      {currentState.matches("idle") &&
        type &&
        sendEvent({
          type: "INIT_FROM_BOOKING_TYPE",
          depotId: depotId,
          investmentType: type,
        }) && <LoadingProgressBar />}

      {currentState.matches("idle") &&
        !type &&
        sendEvent({ type: "INIT_FROM_INVESTMENT_TYPE", depotId: depotId }) && (
          <LoadingProgressBar />
        )}

      {currentState.matches("selectInvestmentType") && (
        <InvestmentTypeStep
          handleNext={(investmentType: InvestmentTypeEnum) => {
            sendEvent({ type: "INVESTMENT_TYPE_SELECTED", investmentType });
          }}
          handlePrevious={() => window.history.back()}
        />
      )}

      {currentState.matches("searchSymbol") && (
        <SearchSymbolStep
          handleNext={(symbol: Symbol | (SymbolV2 & { quote: QuoteV2 })) => {
            sendEvent({ type: "INIT_FROM_SYMBOL", symbol: symbol });
          }}
          handleManualInvestmentClick={() => {
            sendEvent({ type: "MANUAL_SELECT" });
          }}
          handlePrevious={() => sendEvent({ type: "BACK" })}
          type={currentState.context.investmentValues.type}
          depotId={depotId}
          isExchangeOrCarveOut={Boolean(
            currentState.context.bookingValues.type === "switch" ||
              currentState.context.bookingValues.type === "carve_out"
          )}
        />
      )}

      <NewTransactionSteps
        currentState={currentState}
        sendEvent={sendEvent}
        initFromNewTransaction={Boolean(type)}
        isManualTransaction
      />
    </PageLayout>
  );
};
