import React, { ReactNode } from "react";
import styled from "@emotion/styled";

interface Props {
  children: ReactNode;
  header: ReactNode;
  width?: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing(6)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  width: ${({ width }) => width}px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 90%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const WizardLayout = ({ children, header, width }: Props) => {
  return (
    <>
      <HeaderContainer>{header}</HeaderContainer>
      <Container>
        <InnerContainer width={width ? width : 736}>{children}</InnerContainer>
      </Container>
    </>
  );
};
