import { UseMutationOptions } from "react-query";
import {
  RemoteDepotsApiSaasUpdateRemoteDepotRequest,
  DepotSynchronizationLog,
} from "@generated/apiv1";

import { buildRemoteDepotsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const saasUpdateRemoteDepot = async (
  token: string | undefined,
  params: RemoteDepotsApiSaasUpdateRemoteDepotRequest
) => {
  const apiInstance = buildRemoteDepotsApi(token);
  const { data } = await apiInstance.saasUpdateRemoteDepot(params);
  return data;
};

export const useSaasUpdateRemoteDepot = (
  options?: UseMutationOptions<
    DepotSynchronizationLog,
    AxiosApiError,
    RemoteDepotsApiSaasUpdateRemoteDepotRequest
  >
) => {
  return useAuthMutation(saasUpdateRemoteDepot, options, false);
};
