import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SomethingWrongIcon } from "@icons/something-wrong.svg";
import { FEEDBACK_EMAIL } from "@helpers";

type Props = {};

export const SomethingWrongStep = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
      <SomethingWrongIcon />
      <Typography variant="h4" fontWeight={600}>
        {t("transactionWizardModal.somethingWrong.title")}
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={400}
        sx={{ maxWidth: "90%" }}
        textAlign="center"
      >
        <Trans
          i18nKey="transactionWizardModal.somethingWrong.text"
          components={{
            linkText: <Link href={`mailto:${FEEDBACK_EMAIL}`} />,
          }}
        >
          {t("transactionWizardModal.somethingWrong.text")}
        </Trans>
      </Typography>
      <Box width="100%" display="flex" flexDirection="column" gap={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate("/investments")}
        >
          {t("transactionWizardModal.somethingWrong.back")}
        </Button>
      </Box>
    </Box>
  );
};
