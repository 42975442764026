import React, { useState, useContext, Fragment, useRef } from "react";
import { animated } from "react-spring";
import { useGoogleTagManager } from "@hooks";
import { getStepGroup } from "./helpers/getStepGroup";
import { useAnimation } from "./useAnimation";
import { Child } from "./withIf";
import {
  ValidationFunction,
  ChangeDataContext,
  DataContext,
  ValidationError,
  NameOrNamePath,
  NestedName,
} from "./Wizard";

const useInternalInitialStepNumber = (
  childrenNames: string[],
  initialStep?: number
): [number | undefined, number | undefined] => {
  const withIfIndex = childrenNames.findIndex((name) => name === "WithIf");

  const isWithIfAfterInitialStep =
    initialStep != null && withIfIndex > initialStep;
  const isWithIfInChildren = withIfIndex !== -1;

  if (initialStep == null || !isWithIfInChildren || isWithIfAfterInitialStep) {
    return [initialStep, initialStep];
  }

  const stepsLeft = initialStep - withIfIndex;

  return [withIfIndex, stepsLeft];
};

const useHelpers = <C extends {}>(
  children: Child<C>[],
  innerStepNumber: number,
  prevGlobalStepNumber: number
): [number, boolean, boolean] => {
  const globalStepNumber = prevGlobalStepNumber + innerStepNumber;
  const isFirst = globalStepNumber === 0;
  const isLast =
    innerStepNumber === children.length - 1 &&
    children[innerStepNumber].displayName !== "WithIf";
  return [globalStepNumber, isFirst, isLast];
};

export interface WizardManagerProps<C> {
  children: Child<C>[];
  globalStepNumber?: number;
  onPrevious?: () => void;
  animation: boolean;
  validation: ValidationFunction<C, keyof C, NestedName<C>>;
  initialStepNumber?: number;
  step?: string;
}

export const WizardManager = <C extends {}>({
  validation,
  children,
  onPrevious,
  animation,
  globalStepNumber: prevGlobalStepNumber = 0,
  initialStepNumber,
}: WizardManagerProps<C>) => {
  const [internalInitialStepNumber, childInitialStepNumber] =
    useInternalInitialStepNumber(
      children.map((c) => c.displayName || ""),
      initialStepNumber
    );

  const dispatch = useContext(ChangeDataContext);
  const context = useContext(DataContext);
  const { pushData } = useGoogleTagManager("cashback-wizard");
  const [innerStepNumber, setNextStep, setPreviousStep, animatedProps, focus] =
    useAnimation(animation, internalInitialStepNumber);

  const [globalStepNumber, isFirst, isLast] = useHelpers(
    children,
    innerStepNumber,
    prevGlobalStepNumber
  );

  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >();

  const lastUsedStepNumber = useRef<number | undefined>();

  const handleNext = (
    stepNumber: number,
    name: NameOrNamePath<C>,
    value: unknown
  ) => {
    const error = validation({ name, value, context: context.data });

    if (error) {
      setValidationError(error);
      return;
    }
    setValidationError(undefined);

    const type = isLast ? "last" : "add";
    dispatch({
      type,
      payload: {
        name,
        value,
        // I do not remember why we send not stepNumber on last step...Ï it might be something related with restoring
        stepNumber: isLast ? globalStepNumber : stepNumber,
      },
    });

    const nextButton = document.querySelector("#wizard-submit");
    if (nextButton instanceof HTMLButtonElement) {
      pushData({
        buttonId: nextButton.getAttribute("data-testid"),
        inputLabel:
          (nextButton.textContent || nextButton.innerText)?.toLowerCase() || "",
        contextId: context.step,
        contextGroup: getStepGroup(context.step),
        workflow: context.data.workflowType,
      });
    }

    if (isLast) return;

    if (lastUsedStepNumber.current === stepNumber) return;
    setNextStep();
    lastUsedStepNumber.current = stepNumber;
  };

  const handlePrevious = () => {
    lastUsedStepNumber.current = undefined;
    setValidationError(undefined);
    dispatch({
      type: "previous",
      payload: { stepNumber: globalStepNumber - 1 },
    });
    if (innerStepNumber !== 0) {
      setPreviousStep();
      return;
    }
    if (onPrevious) {
      onPrevious();
    }
  };

  const childProps = {
    validation,
    innerStepNumber,
    globalStepNumber,
    onPrevious: handlePrevious,
    animation,
    stepNumber: globalStepNumber + 1,
    focus,
    onNext: handleNext,
    isFirst,
    isLast,
    validationError,
    children,
    initialStepNumber: childInitialStepNumber,
  };

  return (
    <animated.div style={animatedProps}>
      {children.map((child, index) => (
        <Fragment key={index}>
          {index === innerStepNumber ? child(childProps) : <Fragment />}
        </Fragment>
      ))}
    </animated.div>
  );
};
