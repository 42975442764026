import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteBankingApiConnectionIdV2Request } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankingApiConnectionIdV2 = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteBankingApiConnectionIdV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankingApiConnectionIdV2(params);
  return data;
};

export const useDeleteBankingApiConnectionIdV2 = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteBankingApiConnectionIdV2Request
  >
) => {
  return useAuthMutation(deleteBankingApiConnectionIdV2, options, false);
};
