import { UseMutationOptions } from "react-query";
import { BankMessagesApiDeleteBankMessageRequest } from "@generated/apiv3";

import { buildBankMessagesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankMessage = async (
  token: string | undefined,
  params: BankMessagesApiDeleteBankMessageRequest
) => {
  const apiInstance = buildBankMessagesApi(token);
  const { data } = await apiInstance.deleteBankMessage(params);
  return data;
};

export const useDeleteBankMessage = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankMessagesApiDeleteBankMessageRequest
  >
) => {
  return useAuthMutation(deleteBankMessage, options, false);
};
