import { UseQueryOptions } from "react-query";
import { BankSyncsApiShowBankSyncsRequest } from "@generated/apiv3";

import { buildBankSyncsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showBankSyncs = async (
  token: string | undefined,
  params: BankSyncsApiShowBankSyncsRequest
) => {
  const apiInstance = buildBankSyncsApi(token);
  const { data } = await apiInstance.showBankSyncs(params);
  return data;
};

export const useShowBankSyncs = (
  params: BankSyncsApiShowBankSyncsRequest,
  options?: UseQueryOptions<unknown, AxiosApiError, unknown>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKSYNCS_SHOW_BANK_SYNCS, params],
    ({ token }) => showBankSyncs(token, params),
    options
  );
};
