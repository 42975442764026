import { UseMutationOptions } from "react-query";
import { UserPropertiesApiDeleteUserPropertyRequest } from "@generated/apiv1";

import { buildUserPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteUserProperty = async (
  token: string | undefined,
  params: UserPropertiesApiDeleteUserPropertyRequest
) => {
  const apiInstance = buildUserPropertiesApi(token);
  const { data } = await apiInstance.deleteUserProperty(params);
  return data;
};

export const useDeleteUserProperty = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UserPropertiesApiDeleteUserPropertyRequest
  >
) => {
  return useAuthMutation(deleteUserProperty, options, false);
};
