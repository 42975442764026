import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useChartColors } from "@hooks";
import { DonutChartData } from "./types";

export const useChartData = (
  data: DonutChartData[]
): {
  totalValue: number;
  totalAbsValue: number;
  chartData: DonutChartData[];
} => {
  const theme = useTheme();
  const colors = useChartColors("cashflowChart")?.categories;

  const chartDataWithoutHidden = useMemo(
    () =>
      data.reduce(function filterHidden(acc, item): DonutChartData[] {
        if (item.hiddenInChart) {
          return acc;
        }
        return [
          ...acc,
          {
            ...item,
            children: item.children
              ? item.children.reduce(filterHidden, [])
              : [],
          },
        ];
      }, [] as DonutChartData[]),
    [data]
  );

  const noData = useMemo(
    () =>
      chartDataWithoutHidden.every(
        (item) =>
          item.value === 0 &&
          (item.children
            ? item.children.every((child) => child.value === 0)
            : true)
      ),
    [chartDataWithoutHidden]
  );

  const totalValue = useMemo(
    () =>
      chartDataWithoutHidden.reduce(
        (prevValue = 0, currentItem) => prevValue + (currentItem?.value ?? 0),
        0
      ),
    [chartDataWithoutHidden]
  );

  const totalAbsValue = useMemo(
    () =>
      chartDataWithoutHidden.reduce(
        (prevValue = 0, currentItem) =>
          prevValue + Math.abs(currentItem?.value ?? 0),
        0
      ),
    [chartDataWithoutHidden]
  );

  const chartData = useMemo(
    () =>
      Object.values(
        chartDataWithoutHidden.reduce((acc, item) => {
          if (item.hiddenInChart) {
            return acc;
          }

          return {
            ...acc,
            [item.name]: {
              ...item,
              value: Math.abs(item.value),
            },
          };
        }, {} as Record<string, DonutChartData>)
      ),
    [chartDataWithoutHidden]
  );

  if (noData) {
    return {
      totalValue: 0,
      totalAbsValue: 0,
      chartData: [
        {
          id: "noData",
          name: "Keine Daten",
          value: 1,
          color:
            colors?.["Keine Ausgaben"]?.light ||
            theme.palette.background.neutralDark,
          onHoverColor:
            colors?.["Keine Ausgaben"]?.light ||
            theme.palette.background.neutralDark,
        },
      ],
    };
  }

  return {
    totalValue,
    totalAbsValue,
    chartData,
  };
};
