import { UseQueryOptions } from "react-query";
import { SymbolsApiFindByIsinOrWknRequest, SymbolList } from "@generated/apiv1";

import { buildSymbolsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const findByIsinOrWkn = async (
  token: string | undefined,
  params: SymbolsApiFindByIsinOrWknRequest
) => {
  const apiInstance = buildSymbolsApi(token);
  const { data } = await apiInstance.findByIsinOrWkn(params);
  return data;
};

export const useFindByIsinOrWkn = (
  params: SymbolsApiFindByIsinOrWknRequest,
  options?: UseQueryOptions<SymbolList, AxiosApiError, SymbolList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SYMBOLS_FIND_BY_ISIN_OR_WKN, params],
    ({ token }) => findByIsinOrWkn(token, params),
    options
  );
};
