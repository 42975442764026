import { UseQueryOptions } from "react-query";
import {
  BillingCyclesApiBillingCyclesIdRequest,
  BillingCycle,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const billingCyclesId = async (
  token: string | undefined,
  params: BillingCyclesApiBillingCyclesIdRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.billingCyclesId(params);
  return data;
};

export const useBillingCyclesId = (
  params: BillingCyclesApiBillingCyclesIdRequest,
  options?: UseQueryOptions<BillingCycle, AxiosApiError, BillingCycle>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_BILLING_CYCLES_ID, params],
    ({ token }) => billingCyclesId(token, params),
    options
  );
};
