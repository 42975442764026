import { UseQueryOptions } from "react-query";
import { InsurancesApiInsurancesIdRequest, Insurance } from "@generated/apiv1";

import { buildInsurancesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const insurancesId = async (
  token: string | undefined,
  params: InsurancesApiInsurancesIdRequest
) => {
  const apiInstance = buildInsurancesApi(token);
  const { data } = await apiInstance.insurancesId(params);
  return data;
};

export const useInsurancesId = (
  params: InsurancesApiInsurancesIdRequest,
  options?: UseQueryOptions<Insurance, AxiosApiError, Insurance>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INSURANCES_INSURANCES_ID, params],
    ({ token }) => insurancesId(token, params),
    options
  );
};
