import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { FakeSearchField } from "@components/common/layouts/AppSidebar";
import { SymbolV2 } from "@generated/apiv1";
import { StockIndex, STOCK_INDEXES } from "@constants";
import { useMyself } from "@api";
import { useGetQuotesV2 } from "@generated/apiv1/hooks";
import { PlusButton } from "@components/Plus/PlusButton";

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
  padding: theme.spacing(3, 0),

  "&:hover": {
    textDecoration: "underline",
    color: theme.palette.primary.dark,
  },
}));

export interface ComparisonValue {
  label: string;
  value: string;
  quoteProvider?: string;
  type?: string;
}
export interface SelectComparisonProps {
  value?: ComparisonValue;
  onChange: (ticker?: ComparisonValue) => void;
  disabled?: boolean;
  indexes?: StockIndex[];
}

export const SelectComparison = (props: SelectComparisonProps) => {
  const { value, disabled, indexes = STOCK_INDEXES, onChange } = props;
  const theme = useTheme();
  const { data: myself } = useMyself();
  const isUserPro = myself?.isUserPro;
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedSymbol, setSelectedSymbol] = React.useState<SymbolV2>();
  const { t } = useTranslation();

  const { data: quoteData } = useGetQuotesV2(
    {
      securityId: [selectedSymbol?.id!],
      quoteProvider: selectedSymbol?.quoteProvider!,
    },
    {
      enabled: Boolean(selectedSymbol),
    }
  );
  const quote = quoteData?.quotes?.[0];

  useEffect(() => {
    if (!selectedSymbol) return;
    onChange({
      label: selectedSymbol?.name!,
      value: quote?.tickerSymbol!,
      quoteProvider: selectedSymbol?.quoteProvider!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, selectedSymbol]);

  const handleComparedValueChange = (symbol?: SymbolV2) => {
    setIsOpen(false);
    setSelectedSymbol(symbol);
    if (!symbol) onChange(undefined);
  };

  const handleComparedTickerChange = (comparedTicker?: ComparisonValue) => {
    setIsOpen(false);
    onChange(comparedTicker);
  };

  const isValueInStockIndexes = React.useMemo(
    () => indexes.some((index) => index.value === value?.value),
    [value, indexes]
  );

  return (
    <Box sx={{ minWidth: "265px" }}>
      <FormControl fullWidth>
        <InputLabel
          color="primary"
          sx={{
            pointerEvents: "initial",
          }}
        >
          <Box display="flex" gap={2} alignItems="center">
            {t("dashboard.compare")}
            {!isUserPro && <PlusButton />}
          </Box>
        </InputLabel>
        <Select
          open={isOpen}
          displayEmpty
          disabled={disabled || !myself?.isUserPro}
          onClick={(event) => {
            if (
              (event.target as HTMLElement).closest(".MuiSelect-select") &&
              !(event.target as HTMLElement).closest(".clear")
            )
              setIsOpen(true);
          }}
          MenuProps={{
            disablePortal: true,
          }}
          renderValue={() =>
            value?.label ? (
              <Box position="relative" pr={15}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  fontWeight={400}
                  noWrap
                >
                  {value.label}
                </Typography>
                <IconButton
                  onClick={() => {
                    handleComparedValueChange(undefined);
                    handleComparedTickerChange(undefined);
                  }}
                  size="small"
                  className="clear"
                  sx={{
                    position: "absolute",
                    right: "34px",
                    top: "-12px",
                    width: "48px",
                    height: "48px",

                    "& svg": {
                      width: "22px",
                      height: "22px",
                      color: theme.palette.icon.primary,
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <Typography
                variant="body1"
                color={!myself?.isUserPro ? "text.disabled" : "textSecondary"}
                fontWeight={400}
              >
                {t("dashboard.compareWith")}
              </Typography>
            )
          }
        >
          <Box>
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <Box p={4}>
                <Box mb={3}>
                  <FakeSearchField
                    value={isValueInStockIndexes ? undefined : value?.label}
                    collapsed={false}
                    onSymbolSelect={handleComparedValueChange}
                  />
                </Box>
                <Typography
                  variant="overline"
                  color="textSecondary"
                  fontWeight={400}
                  sx={{
                    textTransform: "none",
                  }}
                  mb={1}
                >
                  {t("dashboard.compareWith")}
                </Typography>
                <Box>
                  {indexes.map((comparisonValue) => (
                    <MenuItemStyled
                      value={comparisonValue.value}
                      key={comparisonValue.value}
                      selected={comparisonValue.value === value?.value}
                      onClick={() =>
                        handleComparedTickerChange(comparisonValue)
                      }
                    >
                      <Typography variant="body2" fontWeight={400} px={2}>
                        {comparisonValue.label}
                      </Typography>
                    </MenuItemStyled>
                  ))}
                </Box>
              </Box>
            </ClickAwayListener>
          </Box>
        </Select>
      </FormControl>
    </Box>
  );
};
