import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiBankConnectionUpdateProcessV2Request,
  AddOrUpdateBankConnectionResult,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const bankConnectionUpdateProcessV2 = async (
  token: string | undefined,
  params: BankConnectionsApiBankConnectionUpdateProcessV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.bankConnectionUpdateProcessV2(params);
  return data;
};

export const useBankConnectionUpdateProcessV2 = (
  params: BankConnectionsApiBankConnectionUpdateProcessV2Request,
  options?: UseQueryOptions<
    AddOrUpdateBankConnectionResult,
    AxiosApiError,
    AddOrUpdateBankConnectionResult
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_BANK_CONNECTION_UPDATE_PROCESS_V2, params],
    ({ token }) => bankConnectionUpdateProcessV2(token, params),
    options
  );
};
