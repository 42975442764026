import { RangeValue } from "@components";
import { useHistoricQuoteLookupPerYear } from "../useHistoricQuotesLookup";
import { useChartDataBase } from "./useChartDataBase";
import { mergeHistoricQuotes } from "./mergeHistoricQuotes";
import { getDates } from "./getFromDate";

interface Props {
  isin?: string;
  quoteProvider?: string;
  tickerSymbol?: string;
  range?: RangeValue;
  dataKey1?: string;
  dataKey2?: string;
}
interface Options {
  enabled?: boolean;
}

export const useChartData = (
  { isin, quoteProvider, tickerSymbol, range, dataKey1, dataKey2 }: Props,
  { enabled }: Options = { enabled: true }
) => {
  const { fromDate } = getDates(range);

  const {
    data: historicQuotes,
    isLoading: historicQuotesIsLoading,
    isIdle: historicQuotesIsIdle,
  } = useHistoricQuoteLookupPerYear(quoteProvider, tickerSymbol, fromDate, {
    enabled,
  });

  const {
    data: chartData,
    securityData,
    isLoading: isChartDataLoading,
    isIdle: isChartDataIdle,
  } = useChartDataBase(
    {
      isin,
      range,
      dataKey1,
      dataKey2,
    },
    { enabled }
  );

  const isLoading = isChartDataLoading || historicQuotesIsLoading;
  const isIdle = isChartDataIdle || historicQuotesIsIdle;

  const data = mergeHistoricQuotes(chartData, historicQuotes);

  return {
    data,
    securityData,
    isLoading,
    isIdle,
  };
};
