import React from "react";
import { useTranslation } from "react-i18next";
import { SortableTableHeader, TableRow, TableHead } from "@components/Table";

interface AccountProtocolTableHeadProps {
  sortField: string;
  sortDirection: TableSortDirection;
  onSort: (sortByName: string) => void;
}

export const AccountProtocolTableHead = (
  props: AccountProtocolTableHeadProps
) => {
  const { sortField, sortDirection, onSort } = props;

  const { t } = useTranslation();

  const handleClick = (sortByName: string) => {
    onSort(sortByName);
  };

  return (
    <TableHead>
      <TableRow>
        <SortableTableHeader
          isSortable
          text={t("singleAccountPage.protocolTable.headers.valueDate")}
          sortByName="valueDate"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          width="10%"
        />
        <SortableTableHeader
          isSortable
          text={t("singleAccountPage.protocolTable.headers.bookingInformation")}
          sortByName="purpose"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          width="50%"
        />
        <SortableTableHeader
          isSortable
          text={t("singleAccountPage.protocolTable.headers.value")}
          sortByName="amount"
          sortField={sortField}
          sortDirection={sortDirection}
          onClick={handleClick}
          align="right"
          width="20%"
        />
        <SortableTableHeader text="" sortByName="" align="right" width="20%" />
      </TableRow>
    </TableHead>
  );
};
