import { UseQueryOptions } from "react-query";
import {
  FundamentalsApiFundamentalsIdRequest,
  Security,
} from "@generated/apiv1";

import { buildFundamentalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundamentalsId = async (
  token: string | undefined,
  params: FundamentalsApiFundamentalsIdRequest
) => {
  const apiInstance = buildFundamentalsApi(token);
  const { data } = await apiInstance.fundamentalsId(params);
  return data;
};

export const useFundamentalsId = (
  params: FundamentalsApiFundamentalsIdRequest,
  options?: UseQueryOptions<Security, AxiosApiError, Security>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDAMENTALS_FUNDAMENTALS_ID, params],
    ({ token }) => fundamentalsId(token, params),
    options
  );
};
