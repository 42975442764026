import { HistoricQuote } from "@api";

export const mergeComparisonQuotesByDate = (
  investmentQuotes: HistoricQuote[],
  compareQuotes: HistoricQuote[]
) => {
  if (!compareQuotes.length) return investmentQuotes;
  return investmentQuotes.map((investmentQuote) => {
    const compareQuote = compareQuotes.find(
      (quote) => quote.date === investmentQuote.date
    );

    return {
      ...investmentQuote,
      comparedValue: compareQuote?.value ?? 0,
    };
  });
};
