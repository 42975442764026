import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetDepotSynchronizationLogs } from "@api/v1/hooks/useDepotSynchronizationLog";
import { WizardsHeader, WizardLayout } from "@components/common";
import { LoadingProgressBar } from "@components";
import { SynchronizationLogList } from "./SynchronizationLogList";

export const SynchronizationLogForBatchId = () => {
  const { batchId } = useParams<"batchId">();
  const { data: depotSynchronizationLogList, isLoading } =
    useGetDepotSynchronizationLogs({ batchId: [batchId!] });
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingProgressBar />;
  }

  return (
    <WizardLayout
      header={
        <>
          <WizardsHeader
            title={t("synchronizationLog.title")}
            backUrl={"/"}
            variant="dark"
          />
        </>
      }
    >
      <SynchronizationLogList
        depotSynchronizationLogs={
          depotSynchronizationLogList?.depotSynchronizationLogs || []
        }
      />
    </WizardLayout>
  );
};
