import { UseQueryOptions } from "react-query";
import {
  CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest,
  PageableCashbackIncidentList,
} from "@generated/apiv1";

import { buildCashbackIncidentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllCashbackIncidents = async (
  token: string | undefined,
  params?: CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest
) => {
  const apiInstance = buildCashbackIncidentsApi(token);
  const { data } = await apiInstance.listAndSearchAllCashbackIncidents(params);
  return data;
};

export const useListAndSearchAllCashbackIncidents = (
  params?: CashbackIncidentsApiListAndSearchAllCashbackIncidentsRequest,
  options?: UseQueryOptions<
    PageableCashbackIncidentList,
    AxiosApiError,
    PageableCashbackIncidentList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CASHBACKINCIDENTS_LIST_AND_SEARCH_ALL_CASHBACK_INCIDENTS,
      params,
    ],
    ({ token }) => listAndSearchAllCashbackIncidents(token, params),
    options
  );
};
