import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { Region } from "@api";
import { useUpdateInvestment } from "@api/v1/hooks/useUpdateInvestment";
import { RegionSelector as OriginalRegionSelector } from "@components";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const RegionSelector = styled(OriginalRegionSelector)`
  max-width: 300px;
`;

export const RegionSelectorWithInvestmentUpdate = ({
  investmentId,
  region,
}: {
  investmentId: number;
  region?: Region;
}) => {
  const { t } = useTranslation();
  const { mutate: update, isError, error, isLoading } = useUpdateInvestment();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = (region: Region | null) => {
    update(
      { id: investmentId, regionId: region?.id || null },
      {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              CACHE_KEYS.CLASSIFICATIONS_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
            ],
          });
        },
        onSuccess: () => {
          enqueueSnackbar({
            message: t("form.successMessage"),
            variant: "success",
            autoHideDuration: 3000,
          });
        },
      }
    );
  };

  return (
    <RegionSelector
      disabled={isLoading}
      defaultRegion={region}
      onChange={handleChange}
      isError={isError}
      errorText={(error as ApiException)?.message}
      noLabel
      label={t("regionSelector.label")}
      sx={{
        maxWidth: isLessSm ? "none" : "250px",
        width: "100%",
        flexShrink: "0",
      }}
    />
  );
};
