import { axiosErrorToApiException } from "@api";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const addAxiosErrorToContextAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "SOMETHING_WRONG") return {};

  return {
    error: axiosErrorToApiException(event.error),
  };
};
