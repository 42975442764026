import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { Symbol } from "@api";
import { useGetWatchlists } from "@api/v1/hooks/useGetWatchlists";
import { useGetByQuoteProviderAndTicker } from "@api/v1/hooks/useGetByQuoteProviderAndTicker";
import { PageLayout } from "@components";
import { SymbolsApiGetByQuoteProviderAndTickerRequest } from "@generated/apiv1";
import { SecuritiesAddAndEditForm } from "../components/SecuritiesAddAndEditForm";
import { SecuritiesSearchStep } from "./components/SecuritiesSearchStep";

export const AddPage = () => {
  const { watchlistId } = useParams<"watchlistId">();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentSymbol, setCurrentSymbol] = useState<Symbol>();

  const [searchParams] = useSearchParams();
  const tickerSymbol = searchParams.get("tickerSymbol");
  const quoteProvider = searchParams.get("quoteProvider");
  const hasSearchParams = Boolean(tickerSymbol && quoteProvider);

  const { data: symbolResponse } = useGetByQuoteProviderAndTicker(
    {
      quoteProvider:
        quoteProvider as SymbolsApiGetByQuoteProviderAndTickerRequest["quoteProvider"],
      id: tickerSymbol as SymbolsApiGetByQuoteProviderAndTickerRequest["id"],
    },
    { enabled: hasSearchParams }
  );

  const { data: watchlistsResponse } = useGetWatchlists();

  const handleSelectSymbol = (symbol: Symbol) => setCurrentSymbol(symbol);
  const handleClearSymbol = () => setCurrentSymbol(undefined);
  const handleGoBackToSecurityPreview = () =>
    navigate(`/securities?${searchParams.toString()}`);

  const watchlists = watchlistsResponse?.watchlists ?? [];
  const currentWatchlist = watchlists.find(
    (item) => item.id === Number.parseInt(watchlistId!, 10)
  );
  const securityName = currentSymbol?.name || symbolResponse?.name;

  let breadcrumbs = [
    { to: "Home", text: t("breadcrumbs.home") },
    {
      to: "/watchlists",
      text: t("securitiesAddPage.breadcrumbs.securities"),
    },
    {
      to: `/watchlists/${watchlistId}`,
      text: truncate(currentWatchlist?.name),
    },
    { text: t("securitiesAddPage.breadcrumbs.add") },
  ];

  let securityPageBreadCrumb = {
    to: `/securities/?quoteProvider=${quoteProvider}&tickerSymbol=${tickerSymbol}`,
    text: truncate(securityName),
  };

  if (hasSearchParams) breadcrumbs.splice(2, 1, securityPageBreadCrumb);

  return (
    <PageLayout
      backUrl={!currentSymbol && !hasSearchParams ? "/watchlists" : undefined}
      breadcrumbs={breadcrumbs}
    >
      {!currentSymbol && !hasSearchParams && (
        <SecuritiesSearchStep onSymbolSelect={handleSelectSymbol} noManual />
      )}
      {currentSymbol && (
        <SecuritiesAddAndEditForm
          onPrevious={handleClearSymbol}
          currentWatchlist={currentWatchlist}
          watchlists={watchlistsResponse?.watchlists ?? []}
          securityMeta={currentSymbol}
        />
      )}
      {hasSearchParams && symbolResponse && (
        <SecuritiesAddAndEditForm
          onPrevious={handleGoBackToSecurityPreview}
          currentWatchlist={currentWatchlist}
          watchlists={watchlistsResponse?.watchlists ?? []}
          securityMeta={symbolResponse}
        />
      )}
    </PageLayout>
  );
};
