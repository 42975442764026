import { useMutation, UseMutationOptions } from "react-query";
import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { BanksApiBanksRequest, BankWrapperList } from "@generated/apiv1";

const getBanks = async (params: BanksApiBanksRequest) => {
  const banksApi = buildBanksApi();
  const { data } = await banksApi.banks(params);
  return data;
};

export const useBanks = (
  options?: UseMutationOptions<
    BankWrapperList,
    AxiosApiError,
    BanksApiBanksRequest
  >
) => {
  return useMutation(getBanks, options);
};
