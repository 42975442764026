import { UseQueryOptions } from "react-query";
import {
  HistoricQuotesApiLookupRequest,
  HistoricQuoteListWithRiskYieldStatistics,
} from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const lookup = async (
  token: string | undefined,
  params: HistoricQuotesApiLookupRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.lookup(params);
  return data;
};

export const useLookup = (
  params: HistoricQuotesApiLookupRequest,
  options?: UseQueryOptions<
    HistoricQuoteListWithRiskYieldStatistics,
    AxiosApiError,
    HistoricQuoteListWithRiskYieldStatistics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.HISTORICQUOTES_LOOKUP, params],
    ({ token }) => lookup(token, params),
    options
  );
};
