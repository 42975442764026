import React, { useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ReactComponent as TableIcon } from "@icons/format-list-bulleted.svg";
import { ReactComponent as ArrowDown } from "@icons/chevron-down.svg";
import { ReactComponent as CalendarIcon } from "@icons/calendar.svg";
import { FilterButton } from "@features/dividendPlanner/components/Filters/FilterButton";
import {
  Filter,
  useDividendsContext,
} from "@features/dividendPlanner/DividendsProvider";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getUTCMonth();

const defaultFilter = {
  month: new Date().getUTCMonth(),
  year: currentYear,
};

const years = Array(30 + 1)
  .fill(undefined)
  .map((key, index) => currentYear + 1 - 30 + index);
const months = Array(12)
  .fill(undefined)
  .map((key, index) => index);

const isNextDisabled = (
  period: "month" | "year",
  date: { month: number; year: number },
  filter: Filter
) => {
  if (!filter.includePrediction) {
    if (period === "year" && date.year === currentYear) return true;
    return (
      period === "month" &&
      date.month === new Date().getUTCMonth() &&
      date.year === currentYear
    );
  }

  if (period === "year" && date.year === years[years.length - 1]) return true;

  return (
    period === "month" &&
    date.year === years[years.length - 1] &&
    date.month === 11
  );
};

const isPrevDisabled = (
  period: "month" | "year",
  date: { month: number; year: number }
) => {
  if (period === "year" && date.year === years[0]) return true;
  return period === "month" && date.year === years[0] && date.month === 0;
};

type Props = {
  onViewChange: (view: "calendar" | "table") => void;
  onPeriodChange: (period: "month" | "year") => void;
  onDateChange: (date: { month: number; year: number }) => void;
  view: "calendar" | "table";
  period: "month" | "year";
  date: { month: number; year: number };
};

export const FiltersPanel = ({
  onViewChange,
  onPeriodChange,
  onDateChange,
  view,
  period,
  date,
}: Props) => {
  const { t } = useTranslation();
  const { filter } = useDividendsContext();
  const isLess1200 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1200)
  );
  const isLess720 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(720)
  );
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const Component = isLess1200 ? Box : React.Fragment;

  const nextDisabled = isNextDisabled(period, date, filter);
  const prevDisabled = isPrevDisabled(period, date);

  const getNewDate = (
    direction: "next" | "prev"
  ): {
    month: number;
    year: number;
  } => {
    if (period === "year") {
      return {
        month: date.month,
        year: date.year + (direction === "next" ? 1 : -1),
      };
    } else {
      if (date.month === 0 && direction === "prev") {
        return {
          month: 11,
          year: date.year - 1,
        };
      } else if (date.month === 11 && direction === "next") {
        return {
          month: 0,
          year: date.year + 1,
        };
      }

      return {
        month: date.month + (direction === "next" ? 1 : -1),
        year: date.year,
      };
    }
  };

  useEffect(() => {
    onViewChange(view);
  }, [view, onViewChange]);

  useEffect(() => {
    onPeriodChange(period);
  }, [period, onPeriodChange]);

  useEffect(() => {
    onDateChange(date);
  }, [date, onDateChange]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      width="100%"
      flexDirection={isLess720 ? "column" : "row"}
    >
      <Box
        display="flex"
        alignItems={isLess1200 && !isLess720 ? "flex-start" : "center"}
        gap={4}
        flexDirection={isLess1200 && !isLess720 ? "column" : "row"}
        width={isLess720 ? "100%" : "auto"}
      >
        <ToggleButtonGroup
          value={period}
          color="secondary"
          aria-label="currency-switcher-button"
          size="small"
          sx={{
            height: "48px",
            width: isLess720 ? "100%" : "auto",
          }}
        >
          <ToggleButton value="month" onClick={() => onPeriodChange("month")}>
            {t("dividends.calendarPage.view.month")}
          </ToggleButton>
          <ToggleButton value="year" onClick={() => onPeriodChange("year")}>
            {t("dividends.calendarPage.view.year")}
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="outlined"
          onClick={() => onDateChange(defaultFilter)}
          sx={{
            fontSize: "16px",
            width: isLess720 ? "100%" : "158px",
            whiteSpace: "nowrap",
          }}
        >
          {t(
            `dividends.calendarPage.${
              period === "month" ? "currentMonth" : "currentYear"
            }`
          )}
        </Button>
      </Box>
      <Component
        display="flex"
        flexDirection={isLess720 ? "column" : "column-reverse"}
        gap={4}
        alignItems="flex-end"
        width={isLess720 ? "100%" : "auto"}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={4}
          width={isLess720 ? "100%" : "auto"}
          flexWrap={isLessSm ? "wrap" : "nowrap"}
        >
          <IconButton
            color="outlined"
            onClick={() => {
              onDateChange(getNewDate("prev"));
            }}
            disabled={prevDisabled}
            sx={{
              "& svg": {
                transform: "rotate(90deg)",
              },
              order: isLessSm ? 1 : 0,
              width: isLessSm ? "calc(50% - 8px)" : "auto",
            }}
          >
            <ArrowDown width={24} height={24} />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            gap={4}
            width={isLess720 ? "100%" : "auto"}
          >
            {period === "month" && (
              <Select
                value={date.month}
                onChange={(event) => {
                  onDateChange({ ...date, month: Number(event.target.value) });
                }}
                MenuProps={{
                  sx: {
                    maxHeight: "300px",
                  },
                }}
                sx={{
                  minWidth: "143px",
                  width: isLess720 ? "60%" : "auto",
                }}
              >
                {months.map((month) => (
                  <MenuItem
                    key={month}
                    value={month}
                    disabled={!filter.includePrediction && month > currentMonth}
                  >
                    {format(new Date(0, Number(month)), "MMMM")}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Select
              value={date.year}
              onChange={(event) => {
                onDateChange({
                  month: date.month,
                  year: Number(event.target.value),
                });
              }}
              MenuProps={{
                sx: {
                  maxHeight: "300px",
                },
              }}
              sx={{
                minWidth: "100px",
                width: isLess720
                  ? period === "month"
                    ? "40%"
                    : "100%"
                  : "auto",
              }}
            >
              {years.map((year) => (
                <MenuItem
                  key={year}
                  value={year}
                  disabled={!filter.includePrediction && year > currentYear}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <IconButton
            color="outlined"
            disabled={nextDisabled}
            onClick={() => {
              onDateChange(getNewDate("next"));
            }}
            sx={{
              order: isLessSm ? 2 : 0,
              width: isLessSm ? "calc(50% - 8px)" : "auto",
              "& svg": {
                transform: "rotate(-90deg)",
              },
            }}
          >
            <ArrowDown width={24} height={24} />
          </IconButton>
        </Box>
        <Box
          width={isLess720 ? "100%" : "282px"}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={4}
        >
          <ToggleButtonGroup
            value={view}
            color="secondary"
            aria-label="currency-switcher-button"
            size="small"
            sx={{
              height: "48px",
              "& .MuiToggleButton-root": {
                minWidth: "40px",
              },
            }}
          >
            <ToggleButton
              value="calendar"
              onClick={() => onViewChange("calendar")}
            >
              <CalendarIcon />
            </ToggleButton>
            <ToggleButton value="table" onClick={() => onViewChange("table")}>
              <TableIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <FilterButton fullWidth={isLess720} />
        </Box>
      </Component>
    </Box>
  );
};
