import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { InProgress } from "./InProgress";

const Container = styled.div`
  width: 100%;
`;

const MessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
  max-width: 500px;
`;

export interface Props {
  message?: string;
}

export const InProgressFullWidth = ({ message }: Props) => (
  <Container>
    <InProgress />
    {message && (
      <MessageContainer data-testid="import-in-progress">
        <Typography variant="body1">{message}</Typography>
      </MessageContainer>
    )}
  </Container>
);
