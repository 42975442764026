/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { EtfTypeClassificationList } from '../model';
/**
 * EtfTypeClassificationApi - axios parameter creator
 * @export
 */
export const EtfTypeClassificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieve a list of etf type classifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtfTypeClassifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/master_data/etf_type_classifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerEtfTypeClassificationUpdate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/master_data/etf_type_classifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EtfTypeClassificationApi - functional programming interface
 * @export
 */
export const EtfTypeClassificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EtfTypeClassificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieve a list of etf type classifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtfTypeClassifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtfTypeClassificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtfTypeClassifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EtfTypeClassificationApi.listEtfTypeClassifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary trigger update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerEtfTypeClassificationUpdate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerEtfTypeClassificationUpdate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EtfTypeClassificationApi.triggerEtfTypeClassificationUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EtfTypeClassificationApi - factory interface
 * @export
 */
export const EtfTypeClassificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EtfTypeClassificationApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieve a list of etf type classifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtfTypeClassifications(options?: RawAxiosRequestConfig): AxiosPromise<EtfTypeClassificationList> {
            return localVarFp.listEtfTypeClassifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerEtfTypeClassificationUpdate(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.triggerEtfTypeClassificationUpdate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EtfTypeClassificationApi - object-oriented interface
 * @export
 * @class EtfTypeClassificationApi
 * @extends {BaseAPI}
 */
export class EtfTypeClassificationApi extends BaseAPI {
    /**
     * 
     * @summary retrieve a list of etf type classifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EtfTypeClassificationApi
     */
    public listEtfTypeClassifications(options?: RawAxiosRequestConfig) {
        return EtfTypeClassificationApiFp(this.configuration).listEtfTypeClassifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EtfTypeClassificationApi
     */
    public triggerEtfTypeClassificationUpdate(options?: RawAxiosRequestConfig) {
        return EtfTypeClassificationApiFp(this.configuration).triggerEtfTypeClassificationUpdate(options).then((request) => request(this.axios, this.basePath));
    }
}

