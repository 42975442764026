import { MutateOptions } from "react-query";
import { CreateOrUpdateWatchlistParams, Watchlist } from "@api/v1/model";
import { updateWatchlist } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

export const useUpdateWatchlist = (
  options?: MutateOptions<
    Watchlist,
    AxiosApiError,
    CreateOrUpdateWatchlistParams
  >
) => useAuthMutation(updateWatchlist, options);
