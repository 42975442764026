import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  HistoricQuoteParams,
  HistoricQuoteResponse,
  Lookup,
  HistoricQuoteListWithRiskYieldStatistics,
  GetHistoricQuotesIntradayParams,
  QuoteBarList,
} from "../model";

/** @deprecated */
export const loadHistoricQuotesLookup = async (params: Lookup) => {
  const response = await axiosInstanceV1.get<HistoricQuoteResponse>(
    `/historicQuotes/lookup`,
    {
      params: {
        includeIndexes: false,
        ...params,
      },
    }
  );
  return response.data.historicQuotes[
    `${params.tickerSymbol}${
      params.convertToCurrency ? `_${params.convertToCurrency}` : ""
    }`
  ];
};

export const getHistoricQuotesLookup = async (params: Lookup) => {
  const { data } =
    await axiosInstanceV1.get<HistoricQuoteListWithRiskYieldStatistics>(
      `/historicQuotes/lookup`,
      {
        params,
      }
    );
  return data;
};

export const loadHistoricQuotes = async (
  token: string | undefined,
  params: HistoricQuoteParams
) => {
  const { tickerSymbol } = params;
  const response = await axiosInstanceV1.get<HistoricQuoteResponse>(
    `/historicQuotes`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        includeIndexes: false,
        ...params,
      },
    }
  );
  return response.data.historicQuotes[tickerSymbol];
};

export const getHistoricQuotesIntraday = async (
  token: string | undefined,
  params: GetHistoricQuotesIntradayParams
) => {
  const { data } = await axiosInstanceV1.get<QuoteBarList>(
    "/historicQuotes/intraday",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );

  return data;
};
