import { UseQueryOptions } from "react-query";
import { FeedItemList } from "@generated/apiv1";

import { buildNewsFeedsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const rentabloBlogFeed = async (token: string | undefined) => {
  const apiInstance = buildNewsFeedsApi(token);
  const { data } = await apiInstance.rentabloBlogFeed();
  return data;
};

export const useRentabloBlogFeed = (
  options?: UseQueryOptions<FeedItemList, AxiosApiError, FeedItemList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.NEWSFEEDS_RENTABLO_BLOG_FEED],
    ({ token }) => rentabloBlogFeed(token),
    options
  );
};
