import { UseQueryOptions } from "react-query";
import {
  FundamentalsApiFundamentalsIdMarketCapitalizationRequest,
  MarketCapitalization,
} from "@generated/apiv1";

import { buildFundamentalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundamentalsIdMarketCapitalization = async (
  token: string | undefined,
  params: FundamentalsApiFundamentalsIdMarketCapitalizationRequest
) => {
  const apiInstance = buildFundamentalsApi(token);
  const { data } = await apiInstance.fundamentalsIdMarketCapitalization(params);
  return data;
};

export const useFundamentalsIdMarketCapitalization = (
  params: FundamentalsApiFundamentalsIdMarketCapitalizationRequest,
  options?: UseQueryOptions<
    MarketCapitalization,
    AxiosApiError,
    MarketCapitalization
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDAMENTALS_FUNDAMENTALS_ID_MARKET_CAPITALIZATION, params],
    ({ token }) => fundamentalsIdMarketCapitalization(token, params),
    options
  );
};
