import React from "react";
import { Box, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { SecuritiesHeaderMenuDeleteAction } from "./SecuritiesHeaderMenuDeleteAction";

interface SecuritiesSinglePageMobileMenuProps {
  watchlistId: string;
  securityId: string;
}

export const SecuritiesSinglePageMobileMenu = React.forwardRef<
  HTMLDivElement,
  SecuritiesSinglePageMobileMenuProps
>((props, ref) => {
  const { watchlistId, securityId } = props;

  const { t } = useTranslation();

  return (
    <div ref={ref}>
      <MenuItem
        component={RouterLink}
        to={`/watchlists/${watchlistId}/securities/${securityId}/edit`}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <EditIcon />
          {t("securitiesSinglePage.mobileHeaderMenuActions.editSecurity")}
        </Box>
      </MenuItem>

      <SecuritiesHeaderMenuDeleteAction
        securityId={securityId}
        watchlistId={watchlistId}
      />
    </div>
  );
});
