import React, { useState } from "react";
import {
  Button as MuiButton,
  Divider,
  Menu as MuiMenu,
  MenuItem,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useSelectInterval } from "@hooks";
import { DateRangePickerDialog } from "../DateRangePickerDialog";

const Menu = styled(MuiMenu)`
  & > *.MuiPaper-root {
    width: 256px;
  }
`;

const Button = styled(MuiButton)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  padding-right: 8px;
  padding-left: 8px;
`;

const intervalTypes: IntervalType[] = [
  "14days",
  "30days",
  "60days",
  "oneYear",
  "twoYears",
  "all",
];

interface Props {
  defaultIntervalType?: IntervalType;
  onSelect?: (type: IntervalType, value?: RangeType) => void;
  className?: string;
}

export const DateRangeSelectorMenu = ({
  onSelect,
  defaultIntervalType = "30days",
  className,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState<boolean>();
  const { range, selectInterval, selectCustomInterval, intervalType } =
    useSelectInterval(defaultIntervalType);

  const handleSelectInterval = (type: IntervalType) => {
    const newRange = selectInterval(type);
    setAnchorEl(null);
    if (onSelect) {
      return onSelect(type, newRange);
    }
  };

  const handleCustomSubmit = (from: Date, to: Date) => {
    setAnchorEl(null);
    setIsCustomRangeOpen(false);
    const newRange = selectCustomInterval(from, to);
    if (onSelect) {
      return onSelect("custom", newRange);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCustomRangeOpen = () => {
    setIsCustomRangeOpen(true);
  };

  const handleCustomRangeCancel = () => {
    setAnchorEl(null);
    setIsCustomRangeOpen(false);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const defaultMonthFrom = new Date();

  return (
    <div className={className}>
      <Button
        className="rangeSelectorButton"
        data-testid="select-range-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        variant="text"
        size="small"
      >
        {t(`dateRangeSelectorMenu.${intervalType}`)}
      </Button>
      <Menu
        className="rangeSelectorMenu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && !isCustomRangeOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {intervalTypes.map((type) => (
          <MenuItem
            selected={intervalType === type}
            key={type}
            onClick={() => handleSelectInterval(type)}
            data-testid={`${type}-menu-item`}
          >
            {t(`dateRangeSelectorMenu.${type}`)}
          </MenuItem>
        ))}

        <Divider />
        <DateRangePickerDialog
          defaultFrom={range?.startDate}
          defaultTo={range?.endDate}
          defaultMonthFrom={defaultMonthFrom}
          onCancel={handleCustomRangeCancel}
          onSubmit={handleCustomSubmit}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {({ onTrigger }) => (
            <MenuItem
              selected={intervalType === "custom"}
              key="custom"
              onClick={() => {
                handleCustomRangeOpen();
                if (anchorEl) {
                  onTrigger(anchorEl as any);
                }
              }}
              data-testid={`custom-menu-item`}
            >
              {t(`dateRangeSelectorMenu.custom`)}
            </MenuItem>
          )}
        </DateRangePickerDialog>
      </Menu>
    </div>
  );
};
