/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Bank } from '../model';
// @ts-ignore
import { BankList } from '../model';
/**
 * BanksApi - axios parameter creator
 * @export
 */
export const BanksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get a bank
         * @param {string} id The slug of the bank; id&#x3D;TradeRepublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBank: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBank', 'id', id)
            const localVarPath = `/api/v3/wealth_api/banks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list and find banks
         * @param {string} [filterSearch] Search banks by name, BIC or BLZ
         * @param {boolean} [filterIncludeBeta] Include beta banks; default is false (do not include beta banks)
         * @param {boolean} [filterIsMockBank] Include mock banks; default is false (do not include mock banks)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBanks: async (filterSearch?: string, filterIncludeBeta?: boolean, filterIsMockBank?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/wealth_api/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter[search]'] = filterSearch;
            }

            if (filterIncludeBeta !== undefined) {
                localVarQueryParameter['filter[include_beta]'] = filterIncludeBeta;
            }

            if (filterIsMockBank !== undefined) {
                localVarQueryParameter['filter[is_mock_bank]'] = filterIsMockBank;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BanksApi - functional programming interface
 * @export
 */
export const BanksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BanksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get a bank
         * @param {string} id The slug of the bank; id&#x3D;TradeRepublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBank(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bank>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBank(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.getBank']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list and find banks
         * @param {string} [filterSearch] Search banks by name, BIC or BLZ
         * @param {boolean} [filterIncludeBeta] Include beta banks; default is false (do not include beta banks)
         * @param {boolean} [filterIsMockBank] Include mock banks; default is false (do not include mock banks)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBanks(filterSearch?: string, filterIncludeBeta?: boolean, filterIsMockBank?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBanks(filterSearch, filterIncludeBeta, filterIsMockBank, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.showBanks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BanksApi - factory interface
 * @export
 */
export const BanksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BanksApiFp(configuration)
    return {
        /**
         * 
         * @summary get a bank
         * @param {BanksApiGetBankRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBank(requestParameters: BanksApiGetBankRequest, options?: RawAxiosRequestConfig): AxiosPromise<Bank> {
            return localVarFp.getBank(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list and find banks
         * @param {BanksApiShowBanksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBanks(requestParameters: BanksApiShowBanksRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BankList> {
            return localVarFp.showBanks(requestParameters.filterSearch, requestParameters.filterIncludeBeta, requestParameters.filterIsMockBank, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBank operation in BanksApi.
 * @export
 * @interface BanksApiGetBankRequest
 */
export interface BanksApiGetBankRequest {
    /**
     * The slug of the bank; id&#x3D;TradeRepublic
     * @type {string}
     * @memberof BanksApiGetBank
     */
    readonly id: string
}

/**
 * Request parameters for showBanks operation in BanksApi.
 * @export
 * @interface BanksApiShowBanksRequest
 */
export interface BanksApiShowBanksRequest {
    /**
     * Search banks by name, BIC or BLZ
     * @type {string}
     * @memberof BanksApiShowBanks
     */
    readonly filterSearch?: string

    /**
     * Include beta banks; default is false (do not include beta banks)
     * @type {boolean}
     * @memberof BanksApiShowBanks
     */
    readonly filterIncludeBeta?: boolean

    /**
     * Include mock banks; default is false (do not include mock banks)
     * @type {boolean}
     * @memberof BanksApiShowBanks
     */
    readonly filterIsMockBank?: boolean
}

/**
 * BanksApi - object-oriented interface
 * @export
 * @class BanksApi
 * @extends {BaseAPI}
 */
export class BanksApi extends BaseAPI {
    /**
     * 
     * @summary get a bank
     * @param {BanksApiGetBankRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public getBank(requestParameters: BanksApiGetBankRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).getBank(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list and find banks
     * @param {BanksApiShowBanksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public showBanks(requestParameters: BanksApiShowBanksRequest = {}, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).showBanks(requestParameters.filterSearch, requestParameters.filterIncludeBeta, requestParameters.filterIsMockBank, options).then((request) => request(this.axios, this.basePath));
    }
}

