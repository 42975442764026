import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Modal, ModalProps } from "@components";
import {
  useListAndSearchAllAccounts,
  useShowSharingPreferences,
  useUpdateAccount,
  useUpsertSharingPreferences,
} from "@generated/apiv1/hooks";
import { FirstStep } from "@components/Modal/PortfolioShareModal/FirstStep";
import { SecondStep } from "@components/Modal/PortfolioShareModal/SecondStep";
import { ThirdStep } from "@components/Modal/PortfolioShareModal/ThirdStep";
import { CreateOrUpdateAccountParamsVisibilityEnum } from "@generated/apiv1";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

type Props = Omit<ModalProps, "children"> & {};

export const PortfolioShareModal = (props: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);
  const [checkedPortfolios, setCheckedPortfolios] = useState<number[]>([]);
  const [name, setName] = useState<string>("");
  const [publicId, setPublicId] = useState<string | undefined>();
  const [visibility, setVisibility] = useState<"private" | "public">("private");
  const [percentageView, setPercentageView] = useState<boolean>(false);

  const title =
    step === 1
      ? t("portfolioShare.modal.title")
      : t("portfolioShare.modal.secondTitle");

  const { data } = useListAndSearchAllAccounts(
    {
      type: ["01_depot", "05_material_assets"],
      isHidden: false,
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!checkedPortfolios.length) {
          setCheckedPortfolios(
            data.accounts
              ?.filter((account) => account.visibility === "public")
              .map((account) => account.id) || []
          );
        }
      },
      enabled: props.isOpen,
    }
  );

  useShowSharingPreferences({
    onSuccess: (data) => {
      setVisibility(data.visibility as "private" | "public");
      setName(data.name!);
      // @ts-ignore
      setPercentageView(data.isAnonymized);
      setPublicId(data.publicId!);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading: isSavingGlobalPreferences } =
    useUpsertSharingPreferences();
  const { mutateAsync: updateAccount, isLoading: isUpdatingAccounts } =
    useUpdateAccount();

  const submitData = async () => {
    mutate(
      {
        body: {
          name,
          visibility,
          isAnonymized: percentageView,
        },
      },
      {
        onSuccess: (data) => setPublicId(data?.publicId),
      }
    );

    const promises = data?.accounts?.map((account) => {
      let accountVisibility: CreateOrUpdateAccountParamsVisibilityEnum;

      if (!checkedPortfolios.includes(account.id) || visibility === "private") {
        accountVisibility = "private";
      } else {
        accountVisibility = "public";
      }

      return updateAccount({
        id: account.id,
        body: {
          type: account.type,
          name: account.name,
          visibility: accountVisibility,
        },
      });
    });

    await Promise.all(promises || []);
    queryClient.invalidateQueries({
      queryKey: [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS],
    });
    queryClient.invalidateQueries({
      queryKey: [CACHE_KEYS.SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES],
    });
    if (visibility === "private") {
      handleClose();
    } else {
      setStep(3);
    }
  };

  const handleClose = () => {
    setStep(1);
    props.onClose && props.onClose();
  };

  return (
    <Modal
      title={title}
      {...props}
      onClose={handleClose}
      zIndex={4001}
      height="auto"
    >
      {step === 1 && (
        <FirstStep
          accounts={data?.accounts}
          checkedPortfolios={checkedPortfolios}
          onSelect={setCheckedPortfolios}
          onClose={props.onClose}
          onContinue={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <SecondStep
          onVisibilityChange={setVisibility}
          onPercentageViewChange={setPercentageView}
          onNameChange={setName}
          onContinue={submitData}
          onBack={() => setStep(1)}
          visibility={visibility}
          percentageView={percentageView}
          name={name}
          isLoading={isSavingGlobalPreferences || isUpdatingAccounts}
        />
      )}
      {step === 3 && (
        <ThirdStep
          onBack={() => setStep(2)}
          onContinue={handleClose}
          publicId={publicId}
        />
      )}
    </Modal>
  );
};
