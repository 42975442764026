import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export const SubscriptionActions = ({
  canceledDate,
  redirectUrl,
}: {
  redirectUrl: string;
  canceledDate?: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        sx={{ width: "100%", margin: "32px 0 16px" }}
        variant="contained"
        color="primary"
        href={redirectUrl}
      >
        {t("settingsPage.subscription.activeSubscription.manage")}
      </Button>
      {!canceledDate && (
        <Button
          sx={{ width: "100%", border: "none" }}
          variant="outlined"
          color="primary"
          href={redirectUrl}
        >
          {t("settingsPage.subscription.activeSubscription.cancel")}
        </Button>
      )}
    </>
  );
};
