import { differenceInMonths } from "date-fns";
import { formatDateDDMMM, formatDateMMMYY } from "@helpers/datetime";

export const getInterval = (startDate?: Date, endDate?: Date) => {
  if (!startDate && !!endDate) return "week";
  if (!startDate) return "day";
  if (!endDate) return "day";
  const monthDiff = differenceInMonths(endDate, startDate);
  if (monthDiff > 12) return "week";
  return "day";
};

export const getDateFormatter = (
  intervalType: IntervalType,
  value: string | number
) => {
  const dayTypes = ["oneWeek", "oneMonth"];
  if (dayTypes.includes(intervalType)) return formatDateDDMMM(value);
  return formatDateMMMYY(value);
};
