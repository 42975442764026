import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { toCurrency, toPercent } from "@helpers";
import { BlurredTypography } from "@components/BlurredTypography";

export const Title = ({
  chartType,
  statistic,
  changeToday,
}: {
  changeToday: ReactNode;
  chartType: string;
  statistic: any;
}) => {
  const { t } = useTranslation();
  const title =
    chartType === "performance"
      ? toPercent(statistic.performance)
      : toCurrency(statistic.portfolioValue, "EUR");

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={4}
      >
        <BlurredTypography
          fontSize={36}
          mr={1}
          blur={chartType === "performance" ? 0 : 10}
        >
          {title}
        </BlurredTypography>
        {changeToday}
      </Box>
      <Box display="flex" flexWrap="wrap">
        <BlurredTypography
          variant="body1"
          value={
            chartType === "performance"
              ? statistic.portfolioValue
              : statistic.investedCapital ?? 0
          }
          primary
        >
          {t(`components.depotsStatistic.chart.subheader.${chartType}`)}{" "}
          {toCurrency(
            chartType === "performance"
              ? statistic.portfolioValue
              : statistic.investedCapital ?? 0,
            "EUR"
          )}
        </BlurredTypography>
      </Box>
    </Box>
  );
};
