import React from "react";
import { Typography, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as ErrorIcon } from "@icons/exclude.svg";
import { Tooltip } from "@components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export interface Props {
  message: string;
  testId?: string;
}

export const ActivityWithBookingError = ({ message, testId }: Props) => {
  const theme = useTheme();
  return (
    <Container data-testid={testId || "activity-with-booking-error"}>
      <Tooltip
        text={
          <Typography variant="caption" fontWeight={400}>
            {message}
          </Typography>
        }
        placement="top"
      >
        <ErrorIcon color={theme.palette.error.main} width="20" height="20" />
      </Tooltip>
    </Container>
  );
};
