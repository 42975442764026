import { UseQueryOptions } from "react-query";
import { useAuthQuery } from "@api/auth";
import {
  DiscountPositionList,
  DiscountsApiListAndSearchAllDiscountsRequest,
} from "@generated/apiv1";
import { buildDiscountsApiWithToken } from "@generated/apiv1/apiBuilder";

import { GET_DISCOUNTS_KEY } from "@api/cacheKeys";

export const getDiscounts = async (
  token: string,
  params?: DiscountsApiListAndSearchAllDiscountsRequest
) => {
  const discountApi = await buildDiscountsApiWithToken();
  const { data } = await discountApi.listAndSearchAllDiscounts(params);
  return data;
};

export const useDiscounts = (
  params?: DiscountsApiListAndSearchAllDiscountsRequest,
  options?: UseQueryOptions<
    DiscountPositionList,
    AxiosApiError,
    DiscountPositionList
  >
) => {
  return useAuthQuery(
    [GET_DISCOUNTS_KEY, params],
    ({ token }) => getDiscounts(token, params),
    options
  );
};
