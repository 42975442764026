/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BankRateLimitAttributes
 */
export interface BankRateLimitAttributes {
    /**
     * Total number of connections performed within the time period
     * @type {number}
     * @memberof BankRateLimitAttributes
     */
    'connections_performed': number;
    /**
     * The overall status of the bank rate limit. BLOCKED (new connections are not allowed), BUSY (connections are over 66%), MODERATE (connections are between 33% and 66%) and RELAXED (connections are under 33%)
     * @type {string}
     * @memberof BankRateLimitAttributes
     */
    'mandator_rate_limit_status': BankRateLimitAttributesMandatorRateLimitStatusEnum;
    /**
     * Mandator of the user
     * @type {string}
     * @memberof BankRateLimitAttributes
     */
    'mandator_slug': string;
    /**
     * Maximum number of connections allowed per time period
     * @type {number}
     * @memberof BankRateLimitAttributes
     */
    'max_rate_limit_per_mandator': number;
}

export const BankRateLimitAttributesMandatorRateLimitStatusEnum = {
    BLOCKED: 'BLOCKED',
    BUSY: 'BUSY',
    MODERATE: 'MODERATE',
    RELAXED: 'RELAXED'
} as const;

export type BankRateLimitAttributesMandatorRateLimitStatusEnum = typeof BankRateLimitAttributesMandatorRateLimitStatusEnum[keyof typeof BankRateLimitAttributesMandatorRateLimitStatusEnum];


