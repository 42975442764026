import { format, parseJSON } from "date-fns";

import { useMemo } from "react";
import { getPercent, getInterval } from "@helpers";
import { useInvestmentHistoricQuotes } from "@features/investments/investment/hooks/useInvestmentHistoricQuotes";
import { usePublicOrPrivatePerformance } from "@features/sharedPortfolio/usePublicOrPrivatePerformance";
import { convertToChartData as convertComparisonToChartData } from "@components/Chart/SecuritiesChart/utils";

export type InvestmentChartDataItem = [number, number];

const convertToChartData = (
  field: string,
  data: any[],
  chartType: "valuation" | "performance" | "dividends" = "valuation"
): InvestmentChartDataItem[] => {
  if (!data) return [];

  return data.map((item) => [
    parseJSON(item.date!).getTime(),
    chartType === "performance"
      ? getPercent(item[field] || 0)
      : item[field] || 0,
  ]);
};

export interface InvestmentsChartDataStatistic {
  portfolioValue: number;
  performance: number;
  changeToday: number;
  investedCapital: number;
  changeTodayAbs: number;
  valuationGain: number;
  valuationGainPercent: number;
  cashChangeAbs: number;
  irr: number;
  holdings: number;
  cashBalance: number;
  realizedGain: number;
  dividendGain: number;
  dividendYield: number;
}

export const useInvestmentsChartDataTotal = (
  accountIds: number[],
  startDate: Date,
  endDate: Date,
  chartType: "performance" | "valuation" | "dividends" = "valuation",
  comparedTicker?: string
) => {
  const interval = getInterval(startDate, endDate);

  const {
    data: performanceData,
    isLoading,
    isIdle,
    isFetched,
  } = usePublicOrPrivatePerformance({
    accountIds,
    startDate: format(startDate, "yyyy-MM-dd"),
    endDate: format(endDate, "yyyy-MM-dd"),
    interval,
    cashFlowAndPerformanceStatisticsParamsList: [
      {
        includeCash: false,
        includeDividends: true,
      },
      {
        includeCash: false,
        includeDividends: false,
      },
      {
        includeCash: true,
        includeDividends: true,
      },
    ],
  });

  const {
    data: comparisonHistoryQuotes = [],
    isIdle: historicQuotesIdle,
    isFetching: historicQuotesLoading,
  } = useInvestmentHistoricQuotes(
    {
      tickerSymbol: comparedTicker,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: Boolean(comparedTicker),
      keepPreviousData: true,
    }
  );

  const statistics = performanceData[0];
  const cashStatistics = performanceData[2];
  const aggregatedStatistics = performanceData[0]?.aggregatedGainLossStatistics;

  const twrorResult =
    statistics?.twrorResults[statistics.twrorResults.length - 1];
  const cashTwrorResult =
    cashStatistics?.twrorResults[statistics.twrorResults.length - 1];

  const portfolioValue = cashTwrorResult?.currentMarketValue ?? 0;
  const performance = (twrorResult?.twror ?? 1) - 1;
  const cashBalance = twrorResult?.cashBalance ?? 0;
  const cashChangeAbs =
    (cashTwrorResult?.cashBalance ?? 0) -
    (cashStatistics?.twrorResults[0]?.cashBalance ?? 0);
  const holdings = twrorResult?.currentMarketValue ?? 0;
  const changeToday = statistics?.changeLast?.performance ?? 0;
  const investedCapital = twrorResult?.purchaseValue ?? 0;
  const changeTodayAbs = statistics?.changeLast?.changeAbsCashFlowAdjusted ?? 0;
  const valuationGain = twrorResult?.valuationGainAbs ?? 0;
  const valuationGainPercent = twrorResult?.valuationGainInPercent ?? 0;
  const irr = statistics?.irrTotal?.irr ?? 0;
  const dividendGain = aggregatedStatistics?.grossDividendGain ?? 0;
  const dividendYield = aggregatedStatistics?.grossDividendYield ?? 0;
  const realizedGain = aggregatedStatistics?.grossTradingGain ?? 0;

  const comparedValuesWithDividends = useMemo(
    () =>
      comparedTicker
        ? convertComparisonToChartData(
            performanceData[1]?.twrorResults.map(({ date, twror }) => ({
              date,
              value: twror,
            })),
            comparisonHistoryQuotes,
            "performance"
          ).map((item) => item.mainValue)
        : [],
    [comparedTicker, comparisonHistoryQuotes, performanceData]
  );

  const comparedValues = useMemo(
    () =>
      comparedTicker
        ? convertComparisonToChartData(
            performanceData[0]?.twrorResults.map(({ date, twror }) => ({
              date,
              value: twror,
            })),
            comparisonHistoryQuotes,
            "performance"
          )
        : [],
    [comparedTicker, comparisonHistoryQuotes, performanceData]
  );

  const totalValuesWithDividends = convertToChartData(
    chartType === "performance" ? "twror" : "currentMarketValue",
    performanceData[0]?.twrorResults,
    chartType
  );
  const totalValues = convertToChartData(
    chartType === "performance" ? "twror" : "currentMarketValue",
    performanceData[1]?.twrorResults,
    chartType
  );
  const investedCapitalValues = convertToChartData(
    "purchaseValue",
    performanceData[0]?.twrorResults,
    chartType
  );

  return {
    data: {
      totalValuesWithDividends: comparedTicker
        ? comparedValuesWithDividends
        : totalValuesWithDividends,
      totalValues: comparedTicker
        ? comparedValues.map((item) => item.mainValue)
        : totalValues,
      comparisonValues: comparedValues?.map((item) => item.comparedValue),
      investedCapitalValues,
    },
    statistic: {
      portfolioValue,
      cashBalance,
      cashChangeAbs,
      holdings,
      performance,
      changeToday,
      investedCapital,
      changeTodayAbs,
      valuationGain,
      valuationGainPercent,
      irr,
      dividendGain,
      dividendYield,
      realizedGain,
    },
    isIdle: isIdle && historicQuotesIdle,
    isLoading: isLoading || historicQuotesLoading,
    isFetched,
  };
};
