import { UseMutationOptions } from "react-query";
import {
  BankMessagesApiUpdateBankMessageRequest,
  BankMessage,
} from "@generated/apiv3";

import { buildBankMessagesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateBankMessage = async (
  token: string | undefined,
  params: BankMessagesApiUpdateBankMessageRequest
) => {
  const apiInstance = buildBankMessagesApi(token);
  const { data } = await apiInstance.updateBankMessage(params);
  return data;
};

export const useUpdateBankMessage = (
  options?: UseMutationOptions<
    BankMessage,
    AxiosApiError,
    BankMessagesApiUpdateBankMessageRequest
  >
) => {
  return useAuthMutation(updateBankMessage, options, false);
};
