import { UseQueryOptions } from "react-query";
import { FundsApiShowFundRequest, Fund } from "@generated/apiv1";

import { buildFundsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showFund = async (
  token: string | undefined,
  params: FundsApiShowFundRequest
) => {
  const apiInstance = buildFundsApi(token);
  const { data } = await apiInstance.showFund(params);
  return data;
};

export const useShowFund = (
  params: FundsApiShowFundRequest,
  options?: UseQueryOptions<Fund, AxiosApiError, Fund>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDS_SHOW_FUND, params],
    ({ token }) => showFund(token, params),
    options
  );
};
