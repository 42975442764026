import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { useV1ListCurrencies } from "@generated/apiv1/hooks";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import {
  EqualsIcon,
  MultiplyIcon,
} from "@features/transactionWizard/modal/components/Icons";
import { useHelpers } from "@features/transactionWizard/modal/hooks/useHelpers";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { useGetBookingValues } from "@features/transactionWizard/modal/hooks/useUpdateBookingValues";
import { StepContainer } from "@features/transactionWizard/modal/components/StepContainer";
import { toGermanNumberFormat } from "@helpers";
import { useLabelHelpers } from "../../hooks/useLabelHelpers";

const SummaryItem = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <Box width="100%">
      <Typography variant="subtitle1" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight={400}>
        {value}
      </Typography>
    </Box>
  );
};

export interface Props {
  handleNext: () => void;
  isLoading: boolean;
  context: TransactionWizardContext;
}

export const SummaryStepDesktop = ({
  handleNext,
  context,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { investmentValues, bookingValues } = context;

  const { hasSecurityPrice, hasTax } = useHelpers();
  const { ouncesToGram } = useGetBookingValues();
  const {
    nolLabel,
    nolSummaryDescription,
    formatPrice,
    entryQuoteDescription,
  } = useLabelHelpers();
  const investmentType = investmentValues.type || "11_stock";
  const investmentCurrency = "EUR";
  const currencySymbol = getCurrencySymbol(investmentCurrency);
  const { data } = useV1ListCurrencies();
  const currency = data?.currencies?.find(
    (currency) => currency.code === investmentCurrency
  );

  return (
    <StepContainer
      handleNext={handleNext}
      isLoading={isLoading}
      buttonTitle={t("transactionWizardModal.summary.persist")}
    >
      <Box
        sx={{
          "& svg": {
            flexShrink: 0,
          },
        }}
      >
        <Divider />
        <Grid container spacing={5} mt={0} mb={5}>
          <Grid item xs={5}>
            <Typography variant="subtitle1" fontWeight={600}>
              {t("transactionWizardModal.summary.date")}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" fontWeight={400}>
              {format(bookingValues?.date || new Date(), "dd.MM.yyyy")}
            </Typography>
          </Grid>
          {investmentValues.type === "61_pmetals" && (
            <>
              <Grid item xs={5}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {t("transactionWizardModal.summary.gramms")}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" fontWeight={400}>
                  {toGermanNumberFormat(
                    ouncesToGram(bookingValues?.numberOfLots) || 0,
                    {
                      maximumFractionDigits: 6,
                      minimumFractionDigits: 2,
                    }
                  )}{" "}
                  g
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={5}>
            <Typography variant="subtitle1" fontWeight={600}>
              {t("transactionWizardModal.summary.currency")}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" fontWeight={400}>
              {context.investmentValues.investmentCurrency || "EUR"} -{" "}
              {currency?.name}
            </Typography>
          </Grid>
          {hasSecurityPrice(bookingValues.type) && (
            <>
              <Grid item xs={5}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {t("transactionWizardModal.summary.securityPrice")}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" fontWeight={400}>
                  {formatPrice(
                    investmentType === "31_bond" ? "%" : investmentCurrency,
                    bookingValues.securityPrice
                  )}
                </Typography>
              </Grid>
            </>
          )}
          {(!bookingValues.type || !hasTax(bookingValues.type)) && (
            <>
              <Grid item xs={5}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {t("transactionWizardModal.summary.commission")}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" fontWeight={400}>
                  {formatPrice(
                    investmentCurrency,
                    bookingValues.commission || 0
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Grid container spacing={5} mt={0}>
          <Grid item xs={5} pr={10}>
            <Box display="flex" alignItems="center" gap={8}>
              <SummaryItem
                title={nolSummaryDescription(investmentType)}
                value={
                  investmentType === "31_bond"
                    ? formatPrice(
                        investmentCurrency,
                        bookingValues.numberOfLots
                      )
                    : `${formatPrice(
                        undefined,
                        bookingValues.numberOfLots
                      )} ${nolLabel(investmentType, currencySymbol)}`
                }
              />
              <MultiplyIcon />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display="flex" alignItems="center" gap={15}>
              <SummaryItem
                title={entryQuoteDescription(bookingValues.type || "buy")}
                value={formatPrice(
                  investmentType === "31_bond" ? "%" : investmentCurrency,
                  bookingValues.entryQuote
                )}
              />
              <EqualsIcon />
              <SummaryItem
                title={t("transactionWizardModal.summary.totalAmount")}
                value={formatPrice(investmentCurrency, bookingValues.amount)}
              />
            </Box>
          </Grid>
          {bookingValues.type && hasTax(bookingValues.type) && (
            <>
              <Grid item xs={5} pr={10}>
                <Box display="flex" alignItems="center" gap={8}>
                  <SummaryItem
                    title={t("transactionWizardModal.summary.commission")}
                    value={formatPrice(
                      investmentCurrency,
                      bookingValues.commission || 0
                    )}
                  />
                  <PlusIcon width={26} height={26} />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box display="flex" alignItems="center" gap={15}>
                  <SummaryItem
                    title={t("transactionWizardModal.summary.tax")}
                    value={formatPrice(
                      investmentCurrency,
                      bookingValues.taxAmount || 0
                    )}
                  />
                  <EqualsIcon />
                  <SummaryItem
                    title={t(
                      "transactionWizardModal.summary.totalAmountWithTaxes"
                    )}
                    value={formatPrice(
                      investmentCurrency,
                      (bookingValues.commission || 0) +
                        (bookingValues.taxAmount || 0)
                    )}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Box mt={5}>
          <Divider />
        </Box>
      </Box>
    </StepContainer>
  );
};
