import { axiosInstanceV1 } from "@api/axiosInstance";
import { PageableRegionList } from "../model";

interface Params {
  perPage?: number;
  germanName?: string;
}

export const getRegions = async (params?: Params) => {
  const { data } = await axiosInstanceV1.get<PageableRegionList>(`/regions`, {
    params,
  });
  return data;
};
