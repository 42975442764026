import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid } from "date-fns";
import { NumericFormat } from "react-number-format";
import i18next from "i18next";
import {
  CreateOrUpdateTransactionParams,
  CreateOrUpdateTransactionParamsTypeEnum,
  TransactionTypeEnum,
} from "@generated/apiv1";
import {
  parseOrUndefined,
  toCurrency,
  isStringDateValid,
  toString,
} from "@helpers";
import { ReactComponent as Calendar } from "@icons/calendar.svg";
import { Banner, LoadingButton, TypographyWithInfoIcon } from "@components";
import { ReactComponent as WarningIcon } from "@icons/warning.svg";
import { validateTransactionValues } from "../utils";
import { AddManuallyWarnings } from "./AddManuallyWarnings";

const descriptions = [
  {
    title: i18next.t(
      "addManuallyPage.form.additionalInformation.interestTitle"
    ),
    body: i18next.t("addManuallyPage.form.additionalInformation.interestBody"),
  },
  {
    title: i18next.t(
      "addManuallyPage.form.additionalInformation.otherGainsTitle"
    ),
    body: i18next.t(
      "addManuallyPage.form.additionalInformation.otherGainsBody"
    ),
  },
  {
    title: i18next.t(
      "addManuallyPage.form.additionalInformation.taxRefundTitle"
    ),
    body: i18next.t("addManuallyPage.form.additionalInformation.taxRefundBody"),
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  max-width: 700px;
`;

const transactionTypes: TransactionTypeEnum[] = [
  "withdrawal",
  "deposit",
  "interest",
  "tax_refund",
  "other_gains",
];

const taxAvailableTypes = ["interest", "other_gains"];

interface AddManuallyPageFormProps {
  isAccountsLoading?: boolean;
  isSavingLoading: boolean;
  errorMessage?: string;
  onSubmit: (params: CreateOrUpdateTransactionParams) => void;
  onReset: () => void;
  defaultValues?: {
    type?: CreateOrUpdateTransactionParamsTypeEnum;
    amount?: number;
    taxAmount?: number;
    valueDate?: string;
    purpose?: string;
  };
}

export const AddOrEditManuallyPageForm = ({
  isAccountsLoading = false,
  isSavingLoading,
  errorMessage,
  onSubmit,
  onReset,
  defaultValues = {},
}: AddManuallyPageFormProps) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>(
    toString(defaultValues.amount) || "0"
  );
  const [taxAmount, setTaxAmount] = useState<string>(
    toString(defaultValues.taxAmount) || "0"
  );
  const [values, setValues] = useState<CreateOrUpdateTransactionParams>({
    type: defaultValues.type || "withdrawal",
    amount: Math.abs(defaultValues.amount || 0),
    taxAmount: Math.abs(defaultValues.taxAmount || 0),
    valueDate: defaultValues.valueDate || new Date().toISOString(),
    purpose: defaultValues.purpose || "",
  });

  const handleCommentChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;

    setValues({
      ...values,
      purpose: value,
    });
    onReset();
  };

  const handleAmountChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    const withoutDots = value.replace(/\./g, "");
    const parsedAmount = parseOrUndefined(withoutDots);

    setValues({
      ...values,
      amount: parsedAmount,
    });
    setAmount(withoutDots);
    onReset();
  };

  const handleTaxAmountChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    const withoutDots = value.replace(/\./g, "");
    const parsedTaxAmount = parseOrUndefined(withoutDots);

    setValues({
      ...values,
      taxAmount: parsedTaxAmount,
    });
    setTaxAmount(withoutDots);
    onReset();
  };

  const handleSelectChange = (e: SelectChangeEvent<TransactionTypeEnum>) => {
    const { value } = e.target;
    const newValue = {
      ...values,
      type: e.target.value as TransactionTypeEnum,
    };

    if (value !== "interest" && value !== "other_gains") {
      newValue.taxAmount = 0;
    }

    // @ts-ignore TODO: backend needs to fix it
    setValues(newValue);
    onReset();
  };

  const handleDate = (value: Date | null) => {
    if (value && isValid(value)) {
      setValues({ ...values, valueDate: value.toISOString() });
      return;
    }

    setValues({ ...values, valueDate: undefined });
    onReset();
  };

  const showTaxAmount = taxAvailableTypes.find((item) => item === values.type);
  const totalAmount = (values?.taxAmount ?? 0) + (values?.amount ?? 0);
  const canSubmit =
    isStringDateValid(values?.valueDate) &&
    !isAccountsLoading &&
    !isSavingLoading &&
    validateTransactionValues(values);

  const handleSubmit = () => {
    const submitValues = { ...values };
    // For these two transaction types the amount has to be the total amount
    if (
      submitValues.type === CreateOrUpdateTransactionParamsTypeEnum.INTEREST ||
      submitValues.type === CreateOrUpdateTransactionParamsTypeEnum.OTHER_GAINS
    )
      submitValues.amount = totalAmount;

    onSubmit(submitValues);
  };

  return (
    <Container>
      {errorMessage && (
        <Banner
          data-testid="result-alert-error"
          type="error"
          icon={<WarningIcon width="30px" height="30px" />}
          text={errorMessage}
          mb={3}
        />
      )}

      <FormControl>
        <InputLabel>
          <TypographyWithInfoIcon
            tooltipText={
              <Box display="flex" flexDirection="column" gap={1.5}>
                {descriptions.map((item) => (
                  <Box key={item.title}>
                    <Typography variant="body2" fontWeight="600">
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      fontWeight={400}
                    >
                      {item.body}
                    </Typography>
                  </Box>
                ))}
              </Box>
            }
          >
            {t("addManuallyPage.form.type")}
          </TypographyWithInfoIcon>
        </InputLabel>
        <Select
          data-testid="transaction-type-field"
          displayEmpty
          MenuProps={{
            disablePortal: true,
          }}
          value={values.type}
          inputProps={{
            "data-testid": "transaction-type-select",
          }}
          onChange={handleSelectChange}
          sx={{
            "& .MuiBackdrop-root": {
              opacity: `0 !important`,
              cursor: "initial",
            },
          }}
        >
          {transactionTypes.map((item) => (
            <MenuItem
              key={item}
              value={item}
              data-testid={`type-select-option-${item}`}
            >
              {t(`addManuallyPage.form.types.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <AddManuallyWarnings transactionType={values.type} />

      <NumericFormat
        data-testid="amount-field"
        value={amount}
        name="amount"
        label={t("addManuallyPage.form.netAmount")}
        inputProps={{
          "data-testid": "amount-input",
        }}
        thousandSeparator="."
        decimalSeparator=","
        customInput={TextField}
        onChange={handleAmountChange}
      />

      {Boolean(showTaxAmount) && (
        <>
          <NumericFormat
            data-testid="tax-amount-field"
            value={taxAmount}
            name="taxAmount"
            onChange={handleTaxAmountChange}
            label={t("addManuallyPage.form.taxAmount")}
            inputProps={{
              "data-testid": "tax-amount-input",
            }}
            thousandSeparator="."
            decimalSeparator=","
            customInput={TextField}
          />
          <Typography>
            {t("addManuallyPage.form.totalAmount")} {toCurrency(totalAmount)}
          </Typography>
        </>
      )}

      <DatePicker
        data-testid="value-date-picker"
        disableFuture
        openTo="year"
        PopperProps={{
          disablePortal: true,
        }}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        label={t("addManuallyPage.form.date")}
        views={["year", "month", "day"]}
        value={new Date(values.valueDate ?? "")}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              "data-testid": "value-date-picker-input",
            }}
          />
        )}
        onChange={handleDate}
        components={{
          OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
        }}
      />

      <TextField
        name="purpose"
        label={t("addManuallyPage.form.purpose")}
        rows={4}
        multiline
        value={values.comment}
        onChange={(event) => handleCommentChange(event)}
      />

      <Box>
        <LoadingButton
          data-testid="submit-button"
          variant="contained"
          color="primary"
          disabled={!canSubmit}
          onClick={handleSubmit}
          isLoading={isSavingLoading}
        >
          {t("addManuallyPage.form.submit")}
        </LoadingButton>
      </Box>
    </Container>
  );
};
