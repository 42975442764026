import React from "react";
import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { Card as CardOrig } from "../Card/Card";

const Card = styled(CardOrig, {
  shouldForwardProp: (prop) => prop !== "borderRadius",
})<{
  height?: string;
  width?: string;
  borderRadius?: string;
}>`
  background-color: ${({ theme }) => theme.palette.background.subtle};
  box-shadow: none;
  display: flex;
  height: ${({ height }) => height || "192px"};
  box-sizing: border-box;
  width: ${({ width }) => width || "100%"};
  border: none;

  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export interface LoadingPlaceHolderProps {
  className?: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  dataTestId?: string;
}

export const LoadingPlaceHolder = ({
  className,
  height,
  width,
  borderRadius,
  dataTestId,
}: LoadingPlaceHolderProps) => {
  return (
    <Card
      className={className}
      height={height}
      width={width}
      borderRadius={borderRadius}
      data-testid={dataTestId}
    >
      <Row>
        <Skeleton width={128} height={8} />
        <Skeleton width={32} height={8} />
      </Row>
      <Skeleton width={96} height={8} />
    </Card>
  );
};
