import { UseQueryOptions } from "react-query";
import {
  PublicAccountsApiListPublicAccountsRequest,
  PublicAccountList,
} from "@generated/apiv1";

import { buildPublicAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listPublicAccounts = async (
  token: string | undefined,
  params: PublicAccountsApiListPublicAccountsRequest
) => {
  const apiInstance = buildPublicAccountsApi(token);
  const { data } = await apiInstance.listPublicAccounts(params);
  return data;
};

export const useListPublicAccounts = (
  params: PublicAccountsApiListPublicAccountsRequest,
  options?: UseQueryOptions<PublicAccountList, AxiosApiError, PublicAccountList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.PUBLICACCOUNTS_LIST_PUBLIC_ACCOUNTS, params],
    ({ token }) => listPublicAccounts(token, params),
    options
  );
};
