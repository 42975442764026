import { UseMutationOptions } from "react-query";
import { AccountsApiAccountTransferRequest } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const accountTransfer = async (
  token: string | undefined,
  params: AccountsApiAccountTransferRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.accountTransfer(params);
  return data;
};

export const useAccountTransfer = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    AccountsApiAccountTransferRequest
  >
) => {
  return useAuthMutation(accountTransfer, options, false);
};
