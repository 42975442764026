import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as EditIcon } from "@icons/myicons/edit_alt.svg";

interface Profile {
  handleChangeClick: () => void;
  label: string;
  buttonText: string;
  text: string;
}

export const ProfileRow = ({
  handleChangeClick,
  label,
  buttonText,
  text,
}: Profile) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={!isMobile ? "center" : "flex-start"}
    >
      <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
        <Typography variant="body1" color={theme.palette.text.secondary}>
          {label}
        </Typography>
        <Typography variant="h6" color={theme.palette.text.primary}>
          {text}
        </Typography>
      </Box>
      {isMobile ? (
        <EditIcon onClick={handleChangeClick} />
      ) : (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
          sx={{ height: "48px", minWidth: "118px" }}
          onClick={handleChangeClick}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
