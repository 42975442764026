import { UseQueryOptions } from "react-query";
import {
  TransactionsApiTransactionsIdRequest,
  Transaction,
} from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const transactionsId = async (
  token: string | undefined,
  params: TransactionsApiTransactionsIdRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.transactionsId(params);
  return data;
};

export const useTransactionsId = (
  params: TransactionsApiTransactionsIdRequest,
  options?: UseQueryOptions<Transaction, AxiosApiError, Transaction>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TRANSACTIONS_TRANSACTIONS_ID, params],
    ({ token }) => transactionsId(token, params),
    options
  );
};
