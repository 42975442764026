import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setDividendValuesAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (
    event.type !== "VALIDATE_MANUAL_DIVIDEND" &&
    event.type !== "DIVIDEND_FILLED"
  ) {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    dividendValues: event.dividendValues,
  };
};
