import React from "react";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { ReactComponent as CheckIcon } from "@icons/check.svg";

export type SelectOption<T> = {
  label: string;
  value: T | "all";
};

type TypeWithAll<T extends string> = (T | "all")[];

export type MultipleSelectProps<T extends string> = {
  options: SelectOption<T>[];
  selectedOptions: TypeWithAll<T>;
  onChange: (value: T[]) => void;
  noOptionsText: string;
  allOptionsText?: string;
  startAdornment?: React.ReactNode;
  getOptionsText: (selected: TypeWithAll<T>) => string;
  renderOption?: (option: SelectOption<T>) => React.ReactNode;
  beforeElement?: React.ReactNode;
} & Omit<SelectProps<TypeWithAll<T>>, "onChange">;

export const MultipleSelect = <T extends string>({
  options,
  selectedOptions,
  onChange,
  noOptionsText,
  getOptionsText,
  allOptionsText = i18next.t("components.multipleSelect.all"),
  renderOption,
  startAdornment,
  beforeElement,
  ...rest
}: MultipleSelectProps<T>) => {
  const optionsWithAll: SelectOption<T>[] = [
    { value: "all", label: allOptionsText! },
    ...options,
  ];

  const handleChange = (event: SelectChangeEvent<TypeWithAll<T>>) => {
    let value = event.target.value as TypeWithAll<T>;

    if (!selectedOptions.includes("all") && value.includes("all")) {
      value = [];
    } else {
      value = value.filter((v) => v !== "all");
    }
    onChange(value as T[]);
  };

  return (
    <Select<TypeWithAll<T>>
      displayEmpty
      multiple
      value={selectedOptions}
      onChange={handleChange}
      renderValue={(selected) => {
        let text = null;

        if (selected.length === 0) {
          text = (
            <Typography
              variant="body1"
              fontWeight={400}
              color="textSecondary"
              noWrap
              pr={4}
            >
              {noOptionsText}
            </Typography>
          );
        } else if (selected.length === 1 && selected.includes("all")) {
          text = allOptionsText;
        } else {
          text = getOptionsText(selected);
        }

        return (
          <Box display="flex" alignItems="center" gap={2}>
            {startAdornment}
            {text}
          </Box>
        );
      }}
      {...rest}
    >
      {beforeElement}
      {/* this exists because of strange behaviour of mui5 or js, see: https://github.com/mui/material-ui/issues/23747 */}
      <MenuItem key="invisible-1" sx={{ display: "none" }} />
      {optionsWithAll?.map((item) => {
        const isSelected =
          selectedOptions.includes(item.value) ||
          (item.value === "all" && !selectedOptions.length);

        return renderOption ? (
          renderOption(item)
        ) : (
          <MenuItem
            key={item.value as string}
            value={item.value as string}
            selected={isSelected}
            sx={{
              borderBottom: "none !important",
              padding: (theme) => theme.spacing(1.5, 3),

              "&.Mui-selected": {
                background: "transparent",
              },
              "&.Mui-selected:hover": {
                background: (theme) => theme.palette.background.neutral,
              },
            }}
          >
            <Box display="flex" gap={2} alignItems="center" width="100%">
              <Box width="16px" display="flex">
                {isSelected && <CheckIcon />}
              </Box>
              <Typography noWrap variant="body1" fontWeight={400}>
                {item.label}
              </Typography>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
