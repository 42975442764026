import React from "react";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RevenueCatUser, Stripe } from "@generated/user";
import { SubscriptionType } from "@features/settingsPage/types";
import { plusBenefitsShort } from "../constants";
import { SubscriptionBenefit } from "./SubscriptionBenefit";
import { SubscriptionDetails } from "./SubscriptionViewDetails";
import { SubscriptionActions } from "./SubscriptionViewActions";

const SubscriptionView = ({
  stripeData,
  revenueCatData,
  subscriptionType,
}: {
  stripeData?: Stripe;
  revenueCatData?: RevenueCatUser;
  subscriptionType: SubscriptionType;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const stripeSubscription = stripeData?.subscriptions?.[0];
  const stripeCanceledDate = stripeSubscription?.canceled_at;
  const stripeEndDate = stripeSubscription?.current_period_end;

  const rcSubscription = revenueCatData?.subscriptions?.[0];
  const rcEndDate = rcSubscription?.currentPeriodEnd;

  const endDate = subscriptionType === "revenueCat" ? rcEndDate : stripeEndDate;

  return (
    <Box mt={theme.spacing(4)}>
      <SubscriptionDetails
        subscriptionType={subscriptionType}
        endDate={endDate}
        canceledDate={stripeCanceledDate}
      />
      {subscriptionType !== "revenueCat" && (
        <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
          {plusBenefitsShort.map((benefit) => (
            <SubscriptionBenefit
              key={benefit}
              text={t(benefit)}
              variant="medium"
            />
          ))}
        </Box>
      )}
      {subscriptionType === "stripe" && stripeData && (
        <SubscriptionActions
          redirectUrl={stripeData.customerLoginUrl}
          canceledDate={stripeCanceledDate}
        />
      )}
    </Box>
  );
};

export default SubscriptionView;
