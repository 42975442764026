import React, { ReactElement } from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Container,
  Drawer,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as BurgerIcon } from "@icons/burger.svg";
import { goToDashboard } from "@helpers/mainAppUrls";
import { Logo, LogoVariant } from "@components/Logo";
import { Breadcrumbs } from "@components/Breadcrumbs";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const MobileHeader = ({
  sidebar,
  actionButton,
  context,
}: {
  sidebar: ReactElement;
  actionButton?: ReactElement;
  context?: LogoVariant;
}) => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const Sidebar = React.cloneElement(sidebar, {
    onClose: () => setShowSidebar(false),
  });

  return (
    <>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        width="100%"
        height="58px"
        mb={7}
        p={3}
        className="header-container"
      >
        <Logo variant={context} onClick={goToDashboard} />
        <Box display="flex">
          {actionButton && (
            <Box mx={2} display="flex" alignItems="center">
              {actionButton}
            </Box>
          )}
          <StyledIconButton onClick={() => setShowSidebar(true)}>
            <BurgerIcon />
          </StyledIconButton>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={showSidebar}
        onClose={() => setShowSidebar(false)}
      >
        {Sidebar}
      </Drawer>
    </>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 6)} !important;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: ${({ theme }) => theme.spacing(0, 4)} !important;
  }
`;

export const LayoutWithSidebarHeader = ({
  sidebar,
  breadcrumbs,
  actionButton,
  context,
  mobileHeader,
  maxWidth,
}: {
  sidebar: ReactElement;
  breadcrumbs?: BreadcrumbItem[];
  actionButton?: ReactElement;
  mobileHeader?: ReactElement;
  context?: LogoVariant;
  maxWidth?: number;
}) => {
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return isLessMd ? (
    mobileHeader || (
      <MobileHeader
        sidebar={sidebar}
        actionButton={actionButton}
        context={context}
      />
    )
  ) : breadcrumbs ? (
    <StyledContainer>
      <Box
        width="100%"
        maxWidth={maxWidth}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Breadcrumbs
          items={breadcrumbs}
          sx={{
            marginLeft: "0 !important",
          }}
        />
        {actionButton}
      </Box>
    </StyledContainer>
  ) : null;
};
