import { UseQueryOptions } from "react-query";
import {
  WatchlistsApiListAndSearchAllWatchlistsRequest,
  WatchlistList,
} from "@generated/apiv1";

import { buildWatchlistsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllWatchlists = async (
  token: string | undefined,
  params?: WatchlistsApiListAndSearchAllWatchlistsRequest
) => {
  const apiInstance = buildWatchlistsApi(token);
  const { data } = await apiInstance.listAndSearchAllWatchlists(params);
  return data;
};

export const useListAndSearchAllWatchlists = (
  params?: WatchlistsApiListAndSearchAllWatchlistsRequest,
  options?: UseQueryOptions<WatchlistList, AxiosApiError, WatchlistList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.WATCHLISTS_LIST_AND_SEARCH_ALL_WATCHLISTS, params],
    ({ token }) => listAndSearchAllWatchlists(token, params),
    options
  );
};
