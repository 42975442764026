import React, { ReactNode, useState } from "react";
import { Theme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import { ReactComponent as CheckCircleIcon } from "@icons/myicons/check-circle-filled.svg";
import { Question } from "./Question";
import { StepContainer } from "./StepContainer";

export const StyledSelectStepButton = styled(Button)(
  ({ theme }: { theme: Theme }) => ({
    border: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  })
);

export const CheckedCircleIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 24px;
  height: 24px;
`;

export interface SelectStepProps<T> {
  onPrevious?: () => void;
  stepNumber: number;
  isFirst: boolean;
  defaultValue?: T;
  isOptional?: boolean;
  onNext: (value?: T) => void;
  children?: ReactNode;
}

type InternalSelectStepProps<T> = SelectStepProps<T> & {
  testId?: string;
  question: string;
  options: {
    label: string;
    value: T;
    testId?: string;
  }[];
};

export const SelectStep = <T extends {}>({
  stepNumber,
  testId,
  question,
  onNext,
  options,
  onPrevious,
  isFirst,
  defaultValue,
  isOptional = false,
  children,
}: InternalSelectStepProps<T>) => {
  const [selectedValue, setSelectedValue] = useState<T | undefined>(
    defaultValue
  );

  const handleClick = (value: T) => {
    setSelectedValue(value);
    onNext(value);
  };

  return (
    <StepContainer
      testId={testId}
      onPrevious={onPrevious}
      isFirst={isFirst}
      isOptional={isOptional}
      onNext={isOptional ? onNext : undefined}
    >
      <Question stepNumber={stepNumber} question={question} />
      <Grid container spacing={2}>
        {options.map(({ label, value, testId }) => (
          <Grid item xs={12} key={label}>
            <StyledSelectStepButton
              onClick={() => handleClick(value)}
              color="primary"
              variant={value === selectedValue ? "outlined" : "contained"}
              endIcon={value === selectedValue && <CheckedCircleIcon />}
              data-testid={testId}
              data-test-selected={value === selectedValue}
            >
              {label}
            </StyledSelectStepButton>
          </Grid>
        ))}
      </Grid>

      {!!children && children}
    </StepContainer>
  );
};
