import { UseQueryOptions } from "react-query";
import { useAuthQuery } from "@api";
import {
  CASHFLOW_ANALYTICS,
  CASHFLOW_STATISTICS,
  CATEGORIES_V1,
} from "@api/cacheKeys";
import {
  buildCategoriesApiWithToken,
  buildCashFlowAnalyticsApiWithToken,
} from "@generated/apiv1/apiBuilder";
import {
  CashFlowAnalyticsApiCashFlowAnalyticsRequest,
  CategoriesApiListAndSearchAllCategoriesRequest,
  CategoryList,
  IncomeAndSpendingHistory,
  IncomeAndSpendingStatistics,
} from "@generated/apiv1";

const getCategories = async (
  params: CategoriesApiListAndSearchAllCategoriesRequest
): Promise<CategoryList> => {
  const api = await buildCategoriesApiWithToken();
  const { data } = await api.listAndSearchAllCategories(params);

  return data;
};

const getCashflowHistory = async (
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest
): Promise<IncomeAndSpendingHistory> => {
  const api = await buildCashFlowAnalyticsApiWithToken();
  const { data } = await api.cashFlowAnalytics(params);

  return data;
};

const getCashflowStatistics = async (
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest
): Promise<IncomeAndSpendingStatistics> => {
  const api = await buildCashFlowAnalyticsApiWithToken();
  const { data } = await api.predictCashFlows(params);

  return data;
};

export const useV1Categories = (
  params: CategoriesApiListAndSearchAllCategoriesRequest = {},
  options?: UseQueryOptions<CategoryList, AxiosApiError, CategoryList>
) =>
  useAuthQuery<CategoryList, AxiosApiError, CategoryList>(
    [CATEGORIES_V1, params],
    () => getCategories(params),
    options
  );

export const useCashflowAnalytics = (
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest,
  options?: UseQueryOptions<
    IncomeAndSpendingHistory,
    AxiosApiError,
    IncomeAndSpendingHistory
  >
) =>
  useAuthQuery<
    IncomeAndSpendingHistory,
    AxiosApiError,
    IncomeAndSpendingHistory
  >([CASHFLOW_ANALYTICS, params], () => getCashflowHistory(params), options);

export const useCashflowStatistics = (
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest,
  options?: UseQueryOptions<
    IncomeAndSpendingStatistics,
    AxiosApiError,
    IncomeAndSpendingStatistics
  >
) =>
  useAuthQuery<
    IncomeAndSpendingStatistics,
    AxiosApiError,
    IncomeAndSpendingStatistics
  >(
    [CASHFLOW_STATISTICS, params],
    () => getCashflowStatistics(params),
    options
  );
