import React from "react";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { toCurrency } from "@helpers";
import { Banner } from "@components";
import { ReactComponent as UpIcon } from "@icons/up.svg";
import { useIncomeExpensesStatistics } from "@features/cashflowAnalyzer/hooks/useIncomeExpensesStatistics";

type Props = {
  expenses: ReturnType<typeof useIncomeExpensesStatistics>["expenses"];
};

export const ExpensesBanner = ({ expenses }: Props) => {
  if (Math.abs(expenses.thisMonth) <= Math.abs(expenses.curatedMean))
    return null;

  return (
    <Banner
      icon={<UpIcon />}
      type="warn"
      text={
        <Typography variant="body1">
          <Trans
            i18nKey="cashflowAnalyzer.bannerTitle"
            values={{
              amount: toCurrency(
                Math.abs(expenses.thisMonth - expenses.curatedMean),
                "EUR"
              ),
            }}
            components={{
              red: (
                <Typography
                  variant="body1"
                  color="warning.dark"
                  component="span"
                />
              ),
            }}
          />
        </Typography>
      }
      mb={5}
    />
  );
};
