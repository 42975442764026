import React from "react";
import { useParams } from "react-router-dom";
import { LegalNote } from "./LegalNote";
import { useRoutes } from "./useRoutes";

export const LegalNoteWithRedirectPage = () => {
  const { pushImportConnection } = useRoutes();
  const { bankId } = useParams<"bankId">();

  const handleClick = () => {
    pushImportConnection(bankId!, "FIN_API");
  };

  return <LegalNote onSubmit={handleClick} />;
};
