import { UseMutationOptions } from "react-query";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteUser = async (token: string | undefined) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.deleteUser();
  return data;
};

export const useDeleteUser = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(deleteUser, options, true);
};
