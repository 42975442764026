import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setExchangeRateAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "LOADED_EXCHANGE_RATE")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    dividendValues: {
      ...context.dividendValues,
      exchangeRate: event.exchangeRate,
    },
  };
};
