import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setSourceInvestmentValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "CAPITAL_MEASURE_SELECTED" &&
    event.type !== "BOOKING_TYPE_SELECTED" &&
    event.type !== "NAME_AND_DEPOT_SELECTED"
  )
    throw new Error(`Invalid event type ${event.type}`);

  const investmentId =
    event.type === "BOOKING_TYPE_SELECTED"
      ? event.investmentId
      : context.sourceInvestmentValues?.id || context.investmentValues.id;

  return {
    investmentValues: {
      depotId: context.investmentValues.depotId,
      investmentCurrency: context.investmentValues.investmentCurrency,
    },
    sourceInvestmentValues: {
      ...(context.sourceInvestmentValues || context.investmentValues),
      id: investmentId,
    },
  };
};
