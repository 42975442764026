import React, { useEffect, useState } from "react";
import { isValid, parseJSON } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Watchlist, CreateOrUpdateWatchlistEntryParams } from "@api";
import { LoadingButton } from "@components";
import { Symbol, SymbolV2, Watchlist as WatchlistV2 } from "@generated/apiv1";

import { useCreateWatchlistEntry } from "@api/v1/hooks/useCreateWatchlistEntry";
import { useUpdateWatchlistEntry } from "@api/v1/hooks/useUpdateWatchlistEntry";
import { useGetHistoricQuotesLookup } from "@api/v1/hooks/useLookupHistoricQuotes";
import { SingleAccountChart } from "@components/Chart/SingleAccountChart";
import { intervalToTimeRange } from "@helpers";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { WatchlistSelector } from "../add/components/WatchlistSelector";
import { SecuritiesValuesFields } from "../add/components/SecuritiesValuesFields";

const SecuritiesAddFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  max-width: 700px;
`;

type WatchlistV2Required = Omit<WatchlistV2, "id"> & { id: number };

export type SecurityMeta = Pick<
  Symbol,
  "name" | "id" | "isin" | "currency" | "mappedType" | "quoteProvider"
>;

const isSymbolV2 = (symbol: Symbol | SymbolV2): symbol is SymbolV2 =>
  "type" in symbol;

interface SecuritiesAddFormProps {
  securityMeta:
    | Symbol
    | (SymbolV2 & { currency: string; tickerSymbol: string });
  watchlists: (Watchlist | WatchlistV2Required)[];
  currentWatchlist?: Watchlist | WatchlistV2Required;
  securityId?: string;
  purchaseDate?: Date;
  purchasePrice?: number;
  onPrevious?: () => void;
  onSave?: () => void;
}

// FIXME: delete bang operator from the component
export const SecuritiesAddAndEditForm = (props: SecuritiesAddFormProps) => {
  const {
    securityMeta,
    currentWatchlist,
    watchlists,
    securityId,
    purchasePrice,
    purchaseDate,
    onSave,
    onPrevious,
  } = props;
  const isEdit = Boolean(securityId);
  const { enqueueSnackbar } = useSnackbar();

  const [watchlist, setWatchlist] = useState<Watchlist | undefined>(
    currentWatchlist
  );
  const [date, setDate] = useState<Date | undefined>(
    purchaseDate ?? new Date()
  );
  const [price, setPrice] = useState(purchasePrice ?? 0);
  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );

  const { t } = useTranslation();

  const { mutateAsync: save, isLoading: isCreateSaving } =
    useCreateWatchlistEntry({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Wertpapier wurde erfolgreich hinzugefügt",
          variant: "success",
          autoHideDuration: 3000,
        });
      },
    });

  const { mutateAsync: update, isLoading: isUpdateSaving } =
    useUpdateWatchlistEntry();

  const { data, isLoading: areHistoricQuotesLoading } =
    useGetHistoricQuotesLookup(
      {
        tickerSymbol: isSymbolV2(securityMeta)
          ? securityMeta.tickerSymbol!
          : securityMeta.id!,
        quoteProvider: securityMeta.quoteProvider!,
        from: range?.startDate?.toISOString(),
        to: range?.endDate?.toISOString(),
      },
      {
        onSuccess: (data) => {
          if (isEdit) return;

          const historicQuotes =
            data?.historicQuotes?.[
              isSymbolV2(securityMeta)
                ? securityMeta.tickerSymbol!
                : securityMeta.id!
            ] ?? [];
          const [lastHistoricQuote] = historicQuotes.slice(-1);

          if (lastHistoricQuote) {
            setDate(parseJSON(lastHistoricQuote.date));
            setPrice(lastHistoricQuote.value);
          }
        },
      }
    );

  const navigate = useNavigate();

  const handleSelectWatchlist = (item: Watchlist) => setWatchlist(item);

  const handleDateChange = (value?: Date) => setDate(value);

  const handlePriceChange = (value: number) => setPrice(value);

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  const handleSubmit = async () => {
    const symbolIsV2 = isSymbolV2(securityMeta);

    const params: CreateOrUpdateWatchlistEntryParams = {
      name: securityMeta.name,
      tickerSymbol: symbolIsV2 ? securityMeta.tickerSymbol : securityMeta.id,
      isin: securityMeta.isin,
      currency: securityMeta.currency,
      type: symbolIsV2 ? securityMeta.type : securityMeta.mappedType,
      quoteProvider: securityMeta.quoteProvider,
      purchaseDate: date?.toISOString(),
      purchasePrice: price,
      watchlistId: watchlist?.id,
    };

    if (isEdit) {
      await update({
        ...params,
        watchlistEntryId: Number.parseInt(securityId!, 10),
      });
    } else {
      await save(params);
    }

    const backURL = isEdit
      ? `/watchlists/${watchlist?.id}/securities/${securityId}`
      : `/watchlists/${watchlist?.id}`;

    onSave ? onSave() : navigate(backURL);
  };

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  const canSubmit =
    Boolean(date) &&
    isValid(date) &&
    price > 0 &&
    !isCreateSaving &&
    !isUpdateSaving;

  const isLoading = isUpdateSaving || isCreateSaving;
  const buttonText = isEdit
    ? t("securitiesAddPage.editButton")
    : t("securitiesAddPage.saveButton");

  const historicQuotes: number[][] = (
    data?.historicQuotes?.[
      isSymbolV2(securityMeta) ? securityMeta.tickerSymbol! : securityMeta.id!
    ] ?? []
  ).map((quote) => [new Date(quote.date).getTime(), quote.value]);

  const handleChartClick = (currentValue: number, date: Date) => {
    handlePriceChange(currentValue);
    handleDateChange(date);
  };

  return (
    <>
      <SecuritiesAddFormWrapper>
        <Typography variant="h4" color="textPrimary">
          {securityMeta.name}
        </Typography>

        <WatchlistSelector
          watchlists={watchlists}
          currentWatchlist={watchlist}
          onSelect={handleSelectWatchlist}
        />

        <SecuritiesValuesFields
          date={date}
          price={price}
          onDateChange={handleDateChange}
          onPriceChange={handlePriceChange}
        />

        <Divider />

        <RangeSelector
          range={selectedRange === "custom" ? range : undefined}
          intervalType={selectedRange}
          onSelect={setSelectedRange}
          onCustomSelect={onCustomRangeSelect}
        />

        <SingleAccountChart
          chartData={historicQuotes}
          isLoading={areHistoricQuotesLoading}
          intervalType={selectedRange}
          onClick={handleChartClick}
        />

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={onPrevious ? "space-between" : "flex-end"}
          gap={2}
          width="100%"
        >
          {onPrevious && (
            <Button
              onClick={onPrevious}
              data-testid="actions-previous"
              color="grey"
              variant="contained"
            >
              {t("navigation.previous")}
            </Button>
          )}
          <LoadingButton
            data-testid="add-now-button"
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            {buttonText}
          </LoadingButton>
        </Box>
      </SecuritiesAddFormWrapper>
    </>
  );
};
