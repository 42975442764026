import { UseQueryOptions } from "react-query";
import {
  CurrenciesApiV1ListCurrenciesRequest,
  CurrencyList,
} from "@generated/apiv1";

import { buildCurrenciesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const v1ListCurrencies = async (
  token: string | undefined,
  params?: CurrenciesApiV1ListCurrenciesRequest
) => {
  const apiInstance = buildCurrenciesApi(token);
  const { data } = await apiInstance.v1ListCurrencies(params);
  return data;
};

export const useV1ListCurrencies = (
  params?: CurrenciesApiV1ListCurrenciesRequest,
  options?: UseQueryOptions<CurrencyList, AxiosApiError, CurrencyList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CURRENCIES_V1_LIST_CURRENCIES, params],
    ({ token }) => v1ListCurrencies(token, params),
    options
  );
};
