import { Button, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useMyself } from "@api/auth";
import { Logo } from "@components/Logo";
import { BUY } from "@helpers/urlConstants";
import { ReactComponent as TagPlus } from "../../../../../icons/tag_plus.svg";
import { ReactComponent as StarsLight } from "../../../../../icons/stars-light-sparkle.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  justify-content: space-between;
`;

export interface AppSidebarHeaderSmallProps {
  className?: string;
  onLogoClick?: () => void;
  showPremium?: boolean;
}

export const AppSidebarHeaderSmall = ({
  className,
  onLogoClick,
  showPremium = true,
}: AppSidebarHeaderSmallProps) => {
  const { t } = useTranslation();
  const { data: myself } = useMyself();

  return (
    <Container className={className}>
      <Logo variant="sidebar" onClick={onLogoClick} />
      {myself?.isUserPro && showPremium && (
        <Link href={BUY} target="_blank" rel="noopener noreferrer">
          <TagPlus />
        </Link>
      )}
      {myself?.isUserFree && showPremium && (
        <Button
          variant="contained"
          color="success"
          size="small"
          startIcon={<StarsLight />}
          href={BUY}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("sidebar.upgrade")}
        </Button>
      )}
    </Container>
  );
};
