import { UseMutationOptions } from "react-query";
import { WatchlistsApiDeleteWatchlistRequest } from "@generated/apiv1";

import { buildWatchlistsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteWatchlist = async (
  token: string | undefined,
  params: WatchlistsApiDeleteWatchlistRequest
) => {
  const apiInstance = buildWatchlistsApi(token);
  const { data } = await apiInstance.deleteWatchlist(params);
  return data;
};

export const useDeleteWatchlist = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    WatchlistsApiDeleteWatchlistRequest
  >
) => {
  return useAuthMutation(deleteWatchlist, options, false);
};
