import React, { useMemo, useRef, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { SymbolV2 } from "@generated/apiv1";
import { ReactComponent as ArrowDownIcon } from "@icons/chevron-down.svg";
import { SecurityImage } from "@components/SecurityImage";

const TypographyLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,

  "&:hover": {
    textDecoration: "underline",
  },
}));

type Props = {
  symbols?: SymbolV2[];
  isLoading: boolean;
  onSymbolSelect: (symbol: SymbolV2) => void;
  logos?: Record<string, string | null | undefined>;
  shouldShowExpand?: boolean;
};

export const SecuritiesSearchResultsMobile = ({
  symbols,
  onSymbolSelect,
  isLoading,
  logos = {},
}: Props) => {
  const { t } = useTranslation();
  const searchResultsRef = useRef() as React.RefObject<HTMLDivElement>;
  const [showMore, setShowMore] = useState<string | null>(null);

  const groupedSymbols = useMemo(() => {
    const groups = groupBy(
      symbols?.filter((symbol) => symbol.type !== "index") || [],
      "type"
    );
    return showMore ? { [showMore]: groups[showMore] } : groups;
  }, [symbols, showMore]);

  if (isLoading || !symbols?.length) {
    return (
      <Typography variant="body1">
        {isLoading
          ? t("securitiesSearch.loading")
          : t("securitiesSearch.noResults")}
      </Typography>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      width="100%"
      height="100%"
      sx={{
        overflowY: "auto",
      }}
    >
      {showMore && (
        <Box
          display="flex"
          alignItems="center"
          color="primary.main"
          onClick={() => setShowMore(null)}
          gap={1}
          sx={{
            cursor: "pointer",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              transform: "rotate(90deg)",
            }}
          >
            <ArrowDownIcon width="16px" height="16px" />
          </Box>
          <TypographyLink variant="body1">
            {t("securitiesSearch.back")}
          </TypographyLink>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        ref={searchResultsRef}
        gap={10}
        height="100%"
        sx={{
          overflowY: "auto",
          position: "relative",
        }}
      >
        {Object.keys(groupedSymbols).map((group) => {
          const children = showMore
            ? groupedSymbols[group]
            : groupedSymbols[group]?.slice(0, 3);

          return (
            <Box key={group}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t(`investmentTypeClassification.types.${group}`)}
              </Typography>

              {children.map((symbol, index) => {
                const image = logos[symbol.isin || symbol.id];
                const isLast = index === children.length - 1;

                return (
                  <Box
                    key={symbol.id}
                    py={4}
                    sx={{
                      borderBottom: (theme) =>
                        isLast
                          ? "none"
                          : `1px solid ${theme.palette.border.secondary}`,
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <SecurityImage
                        width={40}
                        height={40}
                        src={image}
                        alt={symbol.isin || symbol.id}
                      />

                      <Box onClick={() => onSymbolSelect(symbol)}>
                        <Typography variant="body1" fontWeight={600}>
                          {truncate(symbol.name, { length: 50 })}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          color="textSecondary"
                        >
                          {symbol.isin}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}

              {groupedSymbols[group]?.length > 3 && !showMore && (
                <Box
                  display="flex"
                  alignItems="center"
                  color="primary.main"
                  onClick={() => setShowMore(group)}
                  gap={1}
                  mt={2}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TypographyLink variant="body1">
                    {t("securitiesSearch.showMore")}
                  </TypographyLink>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      transform: "rotate(-90deg)",
                    }}
                  >
                    <ArrowDownIcon width="16px" height="16px" />
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
