import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setDividendsAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "dividends"> => {
  if (event.type !== "LOADED_DIVIDENDS")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    dividends: event.dividends,
  };
};
