import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RHFTextField } from "@components";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useChangeOtpLabel } from "@generated/user/hooks";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  name: string;
}

export const ChangeMFALabelModal = ({ isOpen, onClose, name }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    mutate: changeName,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useChangeOtpLabel({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.USERS_MYSELF],
      });
    },
  });

  const schema = Yup.object().shape({
    label: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, setValue, watch } = useForm<{
    label: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      label: name,
    },
  });

  const isConfirmBtnDisabled = !watch("label");

  const handleConfirm = () => {
    const values = getValues();
    changeName({
      otpLabel: values.label,
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, 500);
  };

  useEffect(() => {
    setValue("label", name);
  }, [isOpen, name, setValue]);

  return (
    <SettingsModalWrapper
      title="settingsPage.security.editTitle"
      confirmButton="settingsPage.security.editButton"
      successTitle={t("settingsPage.profile.modal.success.title", {
        type: t("settingsPage.security.mfaName"),
      })}
      mobileHeight="auto"
      showSuccess={isSuccess}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit(handleConfirm)}
      isConfirmBtnDisabled={isConfirmBtnDisabled}
      isConfirmButtonLoading={isLoading}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
        />
      )}
      <RHFTextField name="label" control={control} fullWidth type="text" />
    </SettingsModalWrapper>
  );
};
