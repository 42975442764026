import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { LoadingPlaceHolder } from "@components/LoadingPlaceHolder";
import { Card } from "@components/Card";

const FigureContainer = styled.div<{ isFaded?: boolean }>`
  ${({ isFaded }) => (isFaded ? "opacity: 50%;" : "")};
`;

const ChartContainer = styled.div<{ isFaded?: boolean }>`
  position: relative;
  width: 100%;
  height: 241px;
  ${({ isFaded }) => (isFaded ? "opacity: 50%;" : "")}
`;

const TopContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5, 0.5)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

const LegendContainer = styled.div<{ isFaded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isFaded }) => (isFaded ? "opacity: 50%;" : "")};
  margin-top: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
`;

const ChartCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(3)};
  border: none;
`;

const ChartContent = styled.div`
  width: 100%;
`;

const ToolbarContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

interface Props {
  intervalSelector: ReactNode;
  figure: ReactNode;
  children: ReactNode;
  legend?: ReactNode;
  toolbar?: ReactNode;
  isContentFaded?: boolean;
  className?: string;
  isLoading?: boolean;
  showTimeBrush?: boolean;
  onOpenTimeBrushClick?: () => void;
  bottomComponent?: ReactNode;
  onChartMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onChartMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const ChartLayout = ({
  children,
  intervalSelector,
  figure,
  legend,
  isContentFaded = false,
  className,
  isLoading,
  bottomComponent,
  onChartMouseEnter,
  onChartMouseLeave,
  toolbar,
}: Props) => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isLoading) {
    return <LoadingPlaceHolder className={className} />;
  }

  return (
    <ChartCard size="maxi" className={className}>
      <TopContainer>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <FigureContainer isFaded={isContentFaded}>{figure}</FigureContainer>
          {!isXs && intervalSelector}
        </Box>
      </TopContainer>
      {toolbar && <ToolbarContainer>{toolbar}</ToolbarContainer>}
      <ChartContent>
        <ChartContainer
          isFaded={isContentFaded}
          onMouseEnter={onChartMouseEnter}
          onMouseLeave={onChartMouseLeave}
        >
          {children}
        </ChartContainer>

        {legend && (
          <LegendContainer isFaded={isContentFaded}>{legend}</LegendContainer>
        )}

        {bottomComponent && (
          <BottomContainer>{bottomComponent}</BottomContainer>
        )}
      </ChartContent>
    </ChartCard>
  );
};
