import { assign, createMachine } from "xstate";
import { DividendWizardState } from "./types/dividendWizardState";
import {
  defaultContext,
  DividendWizardContext,
} from "./types/dividendWizardContext";
import { DividendWizardEvent } from "./types/dividendWizardEvent";
import {
  loadDividends,
  validateDividends,
  saveDividends,
  loadTaxAmount,
  loadInvestment,
} from "./services";
import {
  addAxiosErrorToContextAction,
  setSelectedDividendsAction,
  setZeroTaxAction,
  setCustomTaxAmountAction,
  setValidatedParams,
  clearValidationErrorAction,
  setDividendsAction,
  addValidationErrorAction,
  setSelectedDividendsWithTaxRateAction,
  setInvestmentAction,
} from "./actions";

export const batchDividendWizardSM = createMachine<
  DividendWizardContext,
  DividendWizardEvent,
  DividendWizardState
>(
  {
    id: "editDividendWizardSM",

    // Initial state
    initial: "idle",

    // Local context for entire machine
    context: defaultContext,

    // State definitions
    states: {
      idle: {
        on: {
          INIT_FROM_INVESTMENT: {
            target: "loadInvestment",
          },
        },
      },
      loadInvestment: {
        id: "loadInvestment",
        invoke: {
          id: "loadInvestment",
          src: loadInvestment,
        },
        on: {
          LOADED_INVESTMENT: {
            target: "loadDividends",
            actions: ["setInvestmentAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      loadDividends: {
        id: "loadDividends",
        invoke: {
          id: "loadDividends",
          src: loadDividends,
        },
        on: {
          LOADED_DIVIDENDS: {
            target: "selectDividends",
            actions: ["setDividendsAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      selectDividends: {
        id: "selectDividends",
        on: {
          DIVIDENDS_SELECTED: {
            target: "taxRequest",
            actions: ["setSelectedDividendsAction"],
          },
        },
      },
      taxRequest: {
        id: "taxRequest",
        on: {
          ENTER_TAX: {
            target: "taxInput",
          },
          SKIP_TAX: {
            target: "loadTaxAmount",
            actions: ["setZeroTaxAction"],
          },
          BACK: {
            target: "selectDividends",
          },
        },
      },
      taxInput: {
        id: "taxInput",
        on: {
          TAX_ENTERED: {
            target: "loadTaxAmount",
            actions: ["setCustomTaxAmountAction"],
          },
          BACK: {
            target: "taxRequest",
          },
        },
      },
      loadTaxAmount: {
        id: "loadTaxAmount",
        invoke: {
          id: "loadTaxAmount",
          src: loadTaxAmount,
        },
        on: {
          TAX_RATE_LOADED: {
            target: "validateDividends",
            actions: [
              "setSelectedDividendsWithTaxRateAction",
              "clearValidationErrorAction",
            ],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      validateDividends: {
        id: "validateDividends",
        invoke: {
          id: "validateDividends",
          src: validateDividends,
        },
        on: {
          DIVIDENDS_VALIDATED: [
            {
              target: "persist",
              actions: ["setValidatedParams"],
              cond: (context) => Boolean(context.skipSummaryStep),
            },
            {
              target: "summary",
              actions: ["setValidatedParams"],
            },
          ],
          BACK: {
            target: "selectDividends",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      summary: {
        id: "summary",
        on: {
          PERSIST: {
            target: "persist",
          },
          BACK: {
            target: "selectDividends",
          },
        },
      },
      persist: {
        id: "persist",
        invoke: {
          id: "saveDividends",
          src: saveDividends,
        },
        on: {
          SUCCESS: {
            target: "success",
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      success: {
        id: "success",
        type: "final",
      },
      somethingWrong: {
        type: "final",
        id: "somethingWrong",
        entry: ["addAxiosErrorToContextAction"],
      },
    },
  },
  {
    actions: {
      addAxiosErrorToContextAction: assign(addAxiosErrorToContextAction),
      clearValidationErrorAction: assign(clearValidationErrorAction),
      setSelectedDividendsAction: assign(setSelectedDividendsAction),
      setZeroTaxAction: assign(setZeroTaxAction),
      setCustomTaxAmountAction: assign(setCustomTaxAmountAction),
      setValidatedParams: assign(setValidatedParams),
      addValidationErrorAction: assign(addValidationErrorAction),
      setSelectedDividendsWithTaxRateAction: assign(
        setSelectedDividendsWithTaxRateAction
      ),
      setInvestmentAction: assign(setInvestmentAction),
      setDividendsAction: assign(setDividendsAction),
    },
  }
);
