import React from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { TagSelector } from "@components";
import { useUpdateTag } from "./hooks/useUpdateTag";

interface Props {
  investmentId: number;
  tag?: string;
  tags?: string[];
}

export const TagSelectorWithInvestmentUpdate = ({
  investmentId,
  tag,
  tags = [],
}: Props) => {
  const { update, isLoading, isError, error } = useUpdateTag(investmentId);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <TagSelector
      disabled={isLoading}
      defaultTag={tag}
      onChange={update}
      tags={tags}
      isError={isError}
      errorText={(error as ApiException)?.message}
      noLabel
      sx={{
        maxWidth: isLessSm ? "none" : "250px",
        width: "100%",
        flexShrink: "0",
      }}
    />
  );
};
