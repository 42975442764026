import styled from "@emotion/styled";
import { DataBlockLayout } from "@components/common/dataBlock";

export const FundamentalsBlockLayout = styled(DataBlockLayout)`
  padding: ${({ theme }) => theme.spacing(2)};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: none;
  }
`;
