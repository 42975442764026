import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Tab, Tabs } from "@components";
import { SettingTabs } from "../constants";
import { SettingsMobileNavigation } from "./SettingsMobileNavigation";

export const SettingsNavigation = ({
  activeTab,
}: {
  activeTab?: SettingTabs;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLess600 = useMediaQuery(theme.breakpoints.down(600));
  const isLess400 = useMediaQuery(theme.breakpoints.down(400));

  const currentActiveTab = activeTab === "create" ? "security" : activeTab;

  return isMobile && !activeTab ? (
    <SettingsMobileNavigation />
  ) : (
    <Box mt={isLess600 ? 5 : 10} mb={2}>
      <Tabs
        variant={isLess400 ? "scrollable" : undefined}
        defaultKey={currentActiveTab}
        onChange={(val: string) => navigate(`/settings/${val}`)}
        hasBottomBorder={false}
        height="32px"
        centered
        sx={{
          "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
            padding: "0 24px",
          },
          "& .MuiTab-root:last-of-type": {
            visibility: isLess400 ? "hidden" : undefined,
          },
        }}
      >
        <Tab
          key="profile"
          label={
            <Typography variant="body3" lineHeight="28px">
              {t("settingsPage.tabs.profile")}
            </Typography>
          }
        />
        <Tab
          key="subscription"
          label={
            <Typography variant="body3" lineHeight="28px">
              {t("settingsPage.tabs.subscription")}
            </Typography>
          }
        />
        <Tab
          key="security"
          label={
            <Typography variant="body3" lineHeight="28px">
              {t("settingsPage.tabs.security")}
            </Typography>
          }
        />
        <Tab
          key="connections"
          label={
            <Typography variant="body3" lineHeight="28px">
              {t("settingsPage.tabs.connections")}
            </Typography>
          }
        />
        {isLess400 && <Tab key="empty-tab" label={"."} />}
      </Tabs>
    </Box>
  );
};
