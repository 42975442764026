import React from "react";
import { useTranslation } from "react-i18next";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
} from "@mui/material";

interface Props {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowSelected: number;
  rowCount: number;
}

export const DividendsTableHeader = (props: Props) => {
  const { rowSelected, rowCount, onSelectAllClick } = props;

  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell variant="head">
          <Checkbox
            data-testid="select-all-checkbox"
            checked={rowCount > 0 && rowSelected === rowCount}
            indeterminate={rowSelected > 0 && rowSelected < rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell variant="head">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.dividendsTable.headers.date")}
          </Typography>
        </TableCell>
        <TableCell variant="head">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.dividendsTable.headers.amount")}
          </Typography>
        </TableCell>
        <TableCell variant="head" align="right">
          <Typography variant="body1" fontWeight={600}>
            {t("batchDividendsWizard.dividendsTable.headers.lots")}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
