import { UseMutationOptions } from "react-query";
import {
  BankMessagesApiCreateBankMessageRequest,
  BankMessage,
} from "@generated/apiv3";

import { buildBankMessagesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createBankMessage = async (
  token: string | undefined,
  params: BankMessagesApiCreateBankMessageRequest
) => {
  const apiInstance = buildBankMessagesApi(token);
  const { data } = await apiInstance.createBankMessage(params);
  return data;
};

export const useCreateBankMessage = (
  options?: UseMutationOptions<
    BankMessage,
    AxiosApiError,
    BankMessagesApiCreateBankMessageRequest
  >
) => {
  return useAuthMutation(createBankMessage, options, false);
};
