import { UseQueryOptions } from "react-query";
import { User } from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const myself = async (token: string | undefined) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.myself();
  return data;
};

export const useMyself = (
  options?: UseQueryOptions<User, AxiosApiError, User>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERS_MYSELF],
    ({ ff_token }) => myself(ff_token),
    options
  );
};
