import { UseQueryOptions } from "react-query";
import {
  BookingsApiPortfolioPositionsIdRequest,
  Booking,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const portfolioPositionsId = async (
  token: string | undefined,
  params: BookingsApiPortfolioPositionsIdRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.portfolioPositionsId(params);
  return data;
};

export const usePortfolioPositionsId = (
  params: BookingsApiPortfolioPositionsIdRequest,
  options?: UseQueryOptions<Booking, AxiosApiError, Booking>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BOOKINGS_PORTFOLIO_POSITIONS_ID, params],
    ({ token }) => portfolioPositionsId(token, params),
    options
  );
};
