/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Tag } from '../model';
// @ts-ignore
import { TagList } from '../model';
/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get an investment tag
         * @param {number} id Get the given investment tag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTag', 'id', id)
            const localVarPath = `/v1/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search investment tags
         * @param {Array<string>} [search] Search tags containing the given search string (key-insensitive like query)
         * @param {Array<number>} [investmentId] Search tags attached to the given investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTags: async (search?: Array<string>, investmentId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search) {
                localVarQueryParameter['search'] = search;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get an investment tag
         * @param {number} id Get the given investment tag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTag(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTag(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.getTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search investment tags
         * @param {Array<string>} [search] Search tags containing the given search string (key-insensitive like query)
         * @param {Array<number>} [investmentId] Search tags attached to the given investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTags(search?: Array<string>, investmentId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTags(search, investmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.listAndSearchAllTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get an investment tag
         * @param {TagsApiGetTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag(requestParameters: TagsApiGetTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.getTag(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search investment tags
         * @param {TagsApiListAndSearchAllTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTags(requestParameters: TagsApiListAndSearchAllTagsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TagList> {
            return localVarFp.listAndSearchAllTags(requestParameters.search, requestParameters.investmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTag operation in TagsApi.
 * @export
 * @interface TagsApiGetTagRequest
 */
export interface TagsApiGetTagRequest {
    /**
     * Get the given investment tag
     * @type {number}
     * @memberof TagsApiGetTag
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllTags operation in TagsApi.
 * @export
 * @interface TagsApiListAndSearchAllTagsRequest
 */
export interface TagsApiListAndSearchAllTagsRequest {
    /**
     * Search tags containing the given search string (key-insensitive like query)
     * @type {Array<string>}
     * @memberof TagsApiListAndSearchAllTags
     */
    readonly search?: Array<string>

    /**
     * Search tags attached to the given investments.
     * @type {Array<number>}
     * @memberof TagsApiListAndSearchAllTags
     */
    readonly investmentId?: Array<number>
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @summary Get an investment tag
     * @param {TagsApiGetTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getTag(requestParameters: TagsApiGetTagRequest, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).getTag(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search investment tags
     * @param {TagsApiListAndSearchAllTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public listAndSearchAllTags(requestParameters: TagsApiListAndSearchAllTagsRequest = {}, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).listAndSearchAllTags(requestParameters.search, requestParameters.investmentId, options).then((request) => request(this.axios, this.basePath));
    }
}

