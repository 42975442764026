import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardLayout } from "@features/analysis/components/CardLayout";
import { DonutChart } from "@components/Chart/DonutChart";
import { useChartColors, useSecurityImages } from "@hooks";
import {
  useListTypeClassificationsForAllInvestments,
  usePublicListTypeClassificationsForAllInvestments,
} from "@generated/apiv1/hooks";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import { convertToChartBreakdown } from "@features/analysis/ClassificationsTab/utils/convertToChartBreakdown";
import { LegendLayout } from "../../components/Legend";

export interface Props {
  accountIds?: number[];
}

export const InvestmentTypeClassification = ({ accountIds }: Props) => {
  const { t } = useTranslation();
  const {
    data,
    isFetching: isLoading,
    isFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useListTypeClassificationsForAllInvestments,
    usePublicMethod: usePublicListTypeClassificationsForAllInvestments,
    params: {
      accountId: accountIds,
    },
    options: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
  const logos = useSecurityImages(
    data?.investmentTypes
      ?.filter((i) => i.type === "investment")
      .map((i) => ({
        ...i.investment,
      }))
  );

  const colors = useChartColors("classificationChart");
  const chartData = useMemo(
    () =>
      convertToChartBreakdown(
        data?.investmentTypes || [],
        "investmentType",
        t("investmentTypeClassification.types", { returnObjects: true }),
        logos
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.investmentTypes, logos]
  );

  return (
    <CardLayout
      isLoading={Boolean(accountIds?.length && isLoading)}
      noData={!accountIds?.length || (isFetched && !chartData.length)}
      chartData={chartData}
      chartTitle={t("analysis.classifications.overviewTabs.investmentType")}
      chart={<DonutChart chartColors={colors} />}
      legend={<LegendLayout showInvestmentAmount />}
    />
  );
};
