import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import {
  CreateOrUpdateInvestmentParams,
  Currency,
  InvestmentTypeEnum,
} from "@api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 50%;
  }
`;

interface Props {
  investmentId: number;
  investmentISIN: string;
  investmentWKN?: string;
  investmentType: InvestmentTypeEnum;
  investmentCurrency: string;
  currencies: Currency[];
  isSaving: boolean;
  onSave: (data: CreateOrUpdateInvestmentParams) => void;
}

const investmentTypes: InvestmentTypeEnum[] = [
  "11_stock",
  "21_fund",
  "22_etf",
  "31_bond",
  "51_certos",
  "61_pmetals",
  "71_massets",
  "81_fcurr",
  "91_managed",
];

export const InvestmentEditAdvancedTabContainer = ({
  investmentType,
  investmentISIN,
  investmentCurrency,
  investmentWKN,
  currencies = [],
  isSaving,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const [type, setType] = useState<InvestmentTypeEnum>(investmentType);
  const [isin, setIsin] = useState(investmentISIN);
  const [wkn, setWkn] = useState(investmentWKN);
  const [currency, setCurrency] = useState(investmentCurrency);

  const handleValues = (
    event: ChangeEvent<{ value?: string }> | SelectChangeEvent<string>,
    key: "type" | "isin" | "wkn" | "currency"
  ) => {
    const value = event.target.value;

    if (key === "type") setType(value as InvestmentTypeEnum);
    if (key === "isin") setIsin(value as string);
    if (key === "wkn") setWkn(value as InvestmentTypeEnum);
    if (key === "currency") setCurrency(value as string);
  };

  const handleSave = () => {
    const data = {
      type,
      isin,
      wkn,
      currency,
    };

    onSave(data);
  };

  return (
    <Container>
      <FormControl>
        <InputLabel>
          {t("investmentEditPage.advancedTab.investmentType")}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          value={type}
          onChange={(event) => handleValues(event, "type")}
        >
          {investmentTypes.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`investmentTypes.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={isin}
        onChange={(event) => handleValues(event, "isin")}
        label={t("investmentEditPage.advancedTab.isin")}
      />

      <TextField
        value={wkn}
        onChange={(event) => handleValues(event, "wkn")}
        label={t("investmentEditPage.advancedTab.wkn")}
      />

      {Boolean(currencies.length) && (
        <FormControl>
          <InputLabel>
            {t("investmentEditPage.advancedTab.investmentCurrency")}
          </InputLabel>
          <Select
            displayEmpty
            fullWidth
            value={currency}
            onChange={(event) => handleValues(event, "currency")}
          >
            {currencies.map(({ code, name }) => (
              <MenuItem key={code} value={code}>
                {code} - {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          {!isSaving && t("investmentEditPage.advancedTab.saveButton")}
          {isSaving && <CircularProgress size={16} />}
        </Button>
      </div>
    </Container>
  );
};
