import React from "react";
import styled from "@emotion/styled";
import { Symbol as SymbolEntity } from "@api";
import { Symbol } from "./Symbol";

const Container = styled.div`
  & > :not(style):not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.palette.border.secondary};
  }
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  margin-top: 5px;
`;

export interface Props {
  symbols: SymbolEntity[];
  handleClick: (symbol: SymbolEntity) => void;
}

export const SymbolList = ({ symbols, handleClick }: Props) => {
  return (
    <Container>
      {symbols.map((symbol, index) => {
        return (
          <Symbol
            key={`symbol-${index}`}
            name={symbol.name || "N/A"}
            isin={symbol.isin}
            typeName={symbol.typeName || "N/A"}
            stockExchangeName={symbol.stockExchangeName || "N/A"}
            quote={symbol.quote?.last ? symbol.quote.last : symbol.quote?.bid}
            currency={symbol.currency}
            handleClick={() => handleClick(symbol)}
            testid={`symbol-${index}`}
          />
        );
      })}
    </Container>
  );
};
