import { useLayoutEffect } from "react";
import { useScrollbarWidth } from "react-use";

export const useScrollbarWithoutJump = ({
  elementWithScroll,
  elementToAddPadding,
  limit,
}: {
  elementWithScroll?: string | HTMLElement;
  elementToAddPadding?: string | HTMLElement;
  limit?: number;
} = {}) => {
  const scrollbarWidth = useScrollbarWidth();

  useLayoutEffect(() => {
    const elementToScroll = elementWithScroll
      ? typeof elementWithScroll === "string"
        ? document.querySelector(elementWithScroll)
        : elementWithScroll
      : document.documentElement;

    const elementToChange = elementToAddPadding
      ? typeof elementToAddPadding === "string"
        ? document.querySelector(elementToAddPadding)
        : elementToAddPadding
      : undefined;

    const checkScrollbar = () => {
      if (!elementToScroll || !elementToChange) return;
      if (limit && window.innerWidth <= limit) return;

      if (!elementToChange?.classList.contains("has-scrollbar")) {
        if (elementToScroll.scrollHeight > elementToScroll.clientHeight) {
          const paddingAlreadySet =
            window.getComputedStyle(elementToChange).paddingLeft;

          // @ts-ignore
          elementToChange?.style.setProperty(
            "padding-left",
            `${
              (paddingAlreadySet ? parseFloat(paddingAlreadySet) : 0) +
              (scrollbarWidth || 0)
            }px`
          );
          elementToChange.classList.add("has-scrollbar");
        }
      } else {
        if (elementToScroll.scrollHeight <= elementToScroll.clientHeight) {
          const paddingAlreadySet =
            window.getComputedStyle(elementToChange).paddingLeft;

          // @ts-ignore
          elementToChange?.style.setProperty(
            "padding-left",
            `${
              (paddingAlreadySet ? parseFloat(paddingAlreadySet) : 0) -
              (scrollbarWidth || 0)
            }px`
          );
          elementToChange.classList.remove("has-scrollbar");
        }
      }
    };

    checkScrollbar();

    const observer = new MutationObserver(checkScrollbar);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [scrollbarWidth, elementToAddPadding, elementWithScroll, limit]);
};
