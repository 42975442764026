import { UseQueryOptions } from "react-query";
import { InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest } from "@generated/apiv1";

import { buildInsuranceProviderSpecificContractIdPatternsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const deleteInsuranceProviderSpecificContractIdPattern = async (
  token: string | undefined,
  params: InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest
) => {
  const apiInstance =
    buildInsuranceProviderSpecificContractIdPatternsApi(token);
  const { data } =
    await apiInstance.deleteInsuranceProviderSpecificContractIdPattern(params);
  return data;
};

export const useDeleteInsuranceProviderSpecificContractIdPattern = (
  params: InsuranceProviderSpecificContractIdPatternsApiDeleteInsuranceProviderSpecificContractIdPatternRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_DELETE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN,
      params,
    ],
    ({ token }) =>
      deleteInsuranceProviderSpecificContractIdPattern(token, params),
    options
  );
};
