import React, { memo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { darken, useTheme } from "@mui/material";

type Props = {
  percent: number;
};

export const AllocationChart = memo(({ percent }: Props) => {
  const theme = useTheme();

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      plotShadow: false,
      type: "pie",
      width: 50,
      height: 50,
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: () => false,
    },
    colors: [
      theme.palette.graphVisual["01"].dark,
      darken(theme.palette.common.white, 0.05),
    ],
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        borderWidth: 0,
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        enableMouseTracking: false,
        cursor: "default",
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...options,
        series: [
          {
            type: "pie",

            data: [
              {
                y: percent * 100,
                borderRadius: "10px",
              },
              {
                y: 100 - percent * 100,
              },
            ],
          },
        ],
      }}
    />
  );
});
