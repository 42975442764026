import { UseMutationOptions } from "react-query";
import {
  SplitsApiApplySplitsForDepotsRequest,
  SplitList,
} from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const applySplitsForDepots = async (
  token: string | undefined,
  params: SplitsApiApplySplitsForDepotsRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.applySplitsForDepots(params);
  return data;
};

export const useApplySplitsForDepots = (
  options?: UseMutationOptions<
    SplitList,
    AxiosApiError,
    SplitsApiApplySplitsForDepotsRequest
  >
) => {
  return useAuthMutation(applySplitsForDepots, options, false);
};
