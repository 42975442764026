import { InvokeCallback, Sender } from "xstate";
import { format } from "date-fns";

import { getExchangeRate } from "@api/v1/methods/exchangeRates";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const loadExchangeRate =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "LOADED_INVESTMENT" && event.type !== "LOADED_DIVIDENDS")
      throw new Error(`Invalid event type ${event.type}`);

    if (!context.investment?.currency)
      throw new Error("investment currency must be defined");

    if (context.investment.currency === "EUR") {
      callback({
        type: "LOADED_EXCHANGE_RATE",
        exchangeRate: 1,
      });
      return;
    }

    getRate(
      "EUR",
      context.investment.currency,
      context.dividendValues.date,
      callback
    );
  };

const getRate = async (
  from: string,
  to: string,
  date: Date = new Date(),
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const rate = await getExchangeRate({
      from: from,
      to: to,
      date: format(date, "yyyy-MM-dd"),
    });

    callback({
      type: "LOADED_EXCHANGE_RATE",
      exchangeRate: rate.rate,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
