import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CenterScreen } from "@components";
import { ReactComponent as SuccessSvg } from "@icons/myicons/check-circle-filled.svg";
import { WizardStepContainer } from "@components/Wizard/StepContainer";

export const SuccessStep = ({ backUrl }: { backUrl: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <CenterScreen>
      <WizardStepContainer data-testid="success-step">
        <div style={{ textAlign: "center" }}>
          <SuccessSvg
            width="40px"
            height="40px"
            color={theme.palette.icon.success}
          />
          <Typography variant="h2" style={{ marginBottom: "20px" }}>
            {t("depotTransfer.successStep.message")}
          </Typography>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            data-testid="back-button"
            variant="contained"
            color="primary"
            onClick={() => navigate(backUrl)}
          >
            {t("depotTransfer.successStep.finishButton")}
          </Button>
        </div>
      </WizardStepContainer>
    </CenterScreen>
  );
};
