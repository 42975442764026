import { UseQueryOptions } from "react-query";
import {
  NewsFeedsApiInvestmentNewsRequest,
  FeedItemList,
} from "@generated/apiv1";

import { buildNewsFeedsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const investmentNews = async (
  token: string | undefined,
  params: NewsFeedsApiInvestmentNewsRequest
) => {
  const apiInstance = buildNewsFeedsApi(token);
  const { data } = await apiInstance.investmentNews(params);
  return data;
};

export const useInvestmentNews = (
  params: NewsFeedsApiInvestmentNewsRequest,
  options?: UseQueryOptions<FeedItemList, AxiosApiError, FeedItemList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.NEWSFEEDS_INVESTMENT_NEWS, params],
    ({ token }) => investmentNews(token, params),
    options
  );
};
