import { UseQueryOptions } from "react-query";
import {
  SymbolsApiGetByQuoteProviderAndTickerRequest,
  Symbol,
} from "@generated/apiv1";

import { buildSymbolsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getByQuoteProviderAndTicker = async (
  token: string | undefined,
  params: SymbolsApiGetByQuoteProviderAndTickerRequest
) => {
  const apiInstance = buildSymbolsApi(token);
  const { data } = await apiInstance.getByQuoteProviderAndTicker(params);
  return data;
};

export const useGetByQuoteProviderAndTicker = (
  params: SymbolsApiGetByQuoteProviderAndTickerRequest,
  options?: UseQueryOptions<Symbol, AxiosApiError, Symbol>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SYMBOLS_GET_BY_QUOTE_PROVIDER_AND_TICKER, params],
    ({ token }) => getByQuoteProviderAndTicker(token, params),
    options
  );
};
