import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import React from "react";
import { Account } from "@api";
import { LoadingProgressBar } from "@components";

export interface Props {
  loading: boolean;
  accounts: Account[];
  selectedAccount: Account | null;
  placeholder: string;
  testId?: string;
  onSelect: (account: Account) => void;
}

export const AccountSelector = ({
  loading,
  accounts,
  selectedAccount,
  placeholder,
  testId,
  onSelect,
}: Props) => {
  const getOptionLabel = (account: Account) =>
    `${account.name} ${account.bankName ? " - " + account.bankName : ""}`;

  const handleSelect = (account: Account | null) => {
    if (!onSelect || !account) return;
    onSelect(account);
  };

  if (loading) {
    return <LoadingProgressBar />;
  }
  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      loading={loading}
      value={selectedAccount}
      fullWidth={true}
      options={accounts}
      autoHighlight
      onChange={(_: any, value: Account | null) => {
        handleSelect(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            "data-testid": testId ? testId : "account-selector",
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
