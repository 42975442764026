import axios from "axios";
import { axiosInstanceOAuth } from "@api/axiosInstance";
import { LoginToken } from "@generated/apiv1";
import { isProduction } from "@constants";

export const getAccessTokenByRefreshToken = async ({
  refreshToken,
}: {
  refreshToken: string;
}) => {
  let token;

  if (isProduction) {
    const result = await axios.post<LoginToken>(
      `${process.env.REACT_APP_V2_URL}/refresh_token`,
      {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }
    );
    token = result?.data;
  } else {
    const result = await axiosInstanceOAuth.post<LoginToken>(`/access_token`, {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });
    token = result?.data;
  }

  return token;
};
