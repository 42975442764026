import React from "react";
import { Menu, MenuItem, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrows } from "@icons/arrows-sort.svg";

export interface TableMobileSortMenuItem {
  text: string;
  handler?: () => void;
}

export interface TableMobileSortMenuProps {
  items: TableMobileSortMenuItem[];
}

export const TableMobileSortMenu = (props: TableMobileSortMenuProps) => {
  const { items } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton color="outlined" onClick={handleOpenMenu} size="small">
        <Arrows />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="textSecondary">
            {t("general.table.tableMobileSortMenu.sortBy")}
          </Typography>
        </MenuItem>

        {items.map((item, index) => (
          <MenuItem key={index} onClick={item.handler}>
            <Typography variant="body1" color="textPrimary">
              {item.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
