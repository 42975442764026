import { withToken } from "@api/auth/withToken";
import {
  DepotSynchronizationLog,
  ImportRemoteDepotParams,
  UpdateRemoteDepotParams,
} from "@api/v1/model";
import { axiosInstanceV1 } from "@api/axiosInstance";

export const importRemoteDepot = async (
  token: string | undefined,
  id: number,
  importRemoteDepotParams: ImportRemoteDepotParams
) => {
  return axiosInstanceV1.post<DepotSynchronizationLog>(
    `/remoteDepots/${id}`,
    importRemoteDepotParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateRemoteDepot = async (
  token: string | undefined,
  id: number,
  updateRemoteDepotParams: UpdateRemoteDepotParams
) => {
  return axiosInstanceV1.put<DepotSynchronizationLog>(
    `/remoteDepots/${id}`,
    updateRemoteDepotParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const importRemoteDepotViaTppWebForm = async (
  id: number,
  webFormToken: string,
  importRemoteDepotParams: ImportRemoteDepotParams
) => {
  return axiosInstanceV1.post<DepotSynchronizationLog>(
    `/remoteDepots/${id}/tppWebForm/${webFormToken}`,
    importRemoteDepotParams
  );
};

export const updateRemoteDepotViaTppWebForm = async (
  id: number,
  webFormToken: string,
  updateRemoteDepotParams: UpdateRemoteDepotParams
) => {
  return axiosInstanceV1.put<DepotSynchronizationLog>(
    `/remoteDepots/${id}/tppWebForm/${webFormToken}`,
    updateRemoteDepotParams
  );
};

export const importRemoteDepotWithToken = withToken(importRemoteDepot);
export const updateRemoteDepotWithToken = withToken(updateRemoteDepot);
