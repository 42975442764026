import React, { useContext } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useMediaQuery, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserWithType } from "@api";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { SharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { SharedAppSidebarMenu } from "@components/common/layouts/AppSidebar/AppSidebarMenu/SharedAppSidebarMenu";
import { Sidebar, SidebarResponsive } from "./Sidebar";
import {
  AppSidebarHeader as HeaderOrig,
  AppSidebarHeaderSmall,
} from "./AppSidebarHeader";

const Header = styled(HeaderOrig)<{ collapsed?: boolean }>`
  ${({ collapsed, theme }) =>
    !collapsed &&
    css`
      margin-right: ${theme.spacing(8)};
    `}
`;

const HeaderSmallStyled = styled(AppSidebarHeaderSmall)`
  flex-grow: 1;
`;

export interface AppSidebarProps {
  user?: UserWithType;
  subscriptionType?: "plus" | "pro" | "free";
  collapseOnSelect?: boolean;
  collapsed: boolean;
  onCollapseChange: (collapsed: boolean) => void;
}

export const SharedAppSidebar = ({ user }: AppSidebarProps) => {
  const navigate = useNavigate();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { settings } = useContext(SharedPortfolioContext);
  const { sidebarCollapsed: collapsed, toggleSidebarCollapsed } =
    useAppLayout();

  const onLogoClick = () => {
    navigate(`/portfolio/${settings?.publicId}`);
  };

  if (matches) {
    return (
      <SidebarResponsive
        collapsed={collapsed}
        onMenuOpen={toggleSidebarCollapsed}
        header={
          <HeaderSmallStyled onLogoClick={onLogoClick} showPremium={false} />
        }
        iconColor="white"
      >
        <SharedAppSidebarMenu collapsed={collapsed} />
      </SidebarResponsive>
    );
  }

  return (
    <Sidebar
      collapsed={collapsed}
      onCollapseClick={toggleSidebarCollapsed}
      header={<Header onLogoClick={onLogoClick} showPremium={false} />}
    >
      <SharedAppSidebarMenu collapsed={collapsed} />
    </Sidebar>
  );
};
