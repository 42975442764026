import { useTranslation } from "react-i18next";
import { ValidationFunction } from "@components";
import { useGetInvestments } from "@api/v1/hooks/useInvestments";
import { Values, InvestmentToTransfer } from "../DepotTransferWizard";

export const useValidation = (accountId: number) => {
  const { t } = useTranslation();
  const { data: investmentsResponse, isLoading } = useGetInvestments({
    accountId: [accountId],
    order: "name asc",
  });
  const investments = investmentsResponse?.investments ?? [];

  const handleValidation: ValidationFunction<Values, keyof Values, never> = ({
    name,
    value,
  }) => {
    switch (name) {
      case "toAccountId":
        if (value == null) {
          return {
            message: t("depotTransfer.validation.destinationAccountIdIsEmpty"),
          };
        }
        return;

      case "dateOfTransfer":
        if (value == null) {
          return {
            message: t("depotTransfer.validation.dateOfTransferCanNotBeEmpty"),
          };
        }

        if ((value as Date) > new Date()) {
          return {
            message: t(
              "depotTransfer.validation.dateOfTransferCanNotBeInFuture"
            ),
          };
        }
        return;

      case "investmentsToTransfer":
        if (!value || (value as Array<any>).length === 0) {
          return {
            message: t("depotTransfer.validation.investmentsToTransferIsEmpty"),
          };
        }
        const errors = (value as Array<InvestmentToTransfer>)
          .map(({ id, numberOfLots }) => {
            if (numberOfLots && numberOfLots <= 0) {
              return {
                id,
                message: t(
                  "depotTransfer.validation.numberOfLotsCanNotBeLessThanZero"
                ),
              };
            }
            const investment = investments.find(
              (investment) => investment.id === id
            );
            if (
              investment &&
              numberOfLots &&
              numberOfLots > investment?.numberOfLots
            ) {
              return {
                id,
                message: t(
                  "depotTransfer.validation.numberOfLotsCanNotBeMoreThanOriginal"
                ),
              };
            }
            return undefined;
          })
          .filter((v) => !!v);
        if (errors && errors.length > 0) {
          return {
            message: t(
              "depotTransfer.validation.investmentsToTransferIsIncorrect"
            ),
            errors,
          };
        }
        return;
      default:
        break;
    }
  };

  return { validate: handleValidation, isLoading };
};
