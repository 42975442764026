import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Link, Typography } from "@mui/material";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { useDeleteUser } from "@generated/user/hooks";
import { useLogout } from "@hooks";
import { ReactComponent as WarningIcon } from "@icons/myicons/warning-triangle-filled.svg";
import { ReactComponent as ShrederIcon } from "@icons/myicons/shreder.svg";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  title: "settingsPage.profile.modal.delete.title",
  confirmButton: "settingsPage.profile.modal.delete.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
  confirmButtonColor: "error" as const,
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsDeleteAccountModal = ({ isOpen, onClose }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useLogout();
  const {
    mutate: deleteUser,
    isLoading,
    isError,
  } = useDeleteUser({
    onSuccess: async () => {
      onClose();
      logout();
    },
  });

  const handleConfirm = () => {
    deleteUser({});
  };

  return (
    <SettingsModalWrapper
      {...modalData}
      mobileHeight="auto"
      icon={
        <ShrederIcon
          style={{
            marginBottom: theme.spacing(6),
          }}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      isConfirmButtonLoading={isLoading}
    >
      <Typography
        variant="body1"
        fontSize="18px"
        lineHeight="28px"
        fontWeight={400}
        textAlign="center"
        letterSpacing={-0.4}
      >
        <WarningIcon
          height="20px"
          width="20px"
          color={theme.palette.icon.warning}
          style={{
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(-1),
          }}
        />
        {t("settingsPage.profile.modal.delete.subtitle")}
      </Typography>
      <Typography
        variant="body1"
        fontSize="18px"
        lineHeight="28px"
        fontWeight={400}
        textAlign="center"
        letterSpacing={-0.4}
        mt={theme.spacing(6)}
      >
        <Trans
          i18nKey="settingsPage.profile.modal.delete.text"
          components={{
            linkText: (
              <Link
                display="inline"
                color={theme.palette.text.link}
                onClick={() => navigate("/settings/connections")}
              />
            ),
          }}
        />
      </Typography>
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
          marginBottom={0}
          marginTop={8}
        />
      )}
    </SettingsModalWrapper>
  );
};
