import { buildDividendValuesFromBooking } from "../../utils/utils";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setDividendValuesFromBookingAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "dividendValues"> => {
  if (event.type !== "LOADED_BOOKING")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    dividendValues: {
      ...context.dividendValues,
      ...buildDividendValuesFromBooking(event.booking),
    },
  };
};
