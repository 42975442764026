/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccountImportOrSynchronizationResult } from '../model';
// @ts-ignore
import { BatchSynchronizationParams } from '../model';
// @ts-ignore
import { DepotSynchronizationLog } from '../model';
// @ts-ignore
import { ImportRemoteDepotParams } from '../model';
// @ts-ignore
import { PageableSecurityList } from '../model';
// @ts-ignore
import { UpdateRemoteDepotParams } from '../model';
/**
 * RemoteDepotsApi - axios parameter creator
 * @export
 */
export const RemoteDepotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get remote securities for the given banking provider (finAPI) account ID.                      Remote securities are all securities that                      are stored on finAPI side. Use this endpoint to check locally persisted securities against the                      remote set.
         * @param {number} id Banking provider (finAPI) account ID of the account/depot holding the securities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSecurities: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRemoteSecurities', 'id', id)
            const localVarPath = `/v1/remoteDepots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED: List changes required for an account import
         * @param {number} id Banking API provider ID (finApi) of the remote account (depot or others) to import.
         * @param {number} [remoteContraAccountId] Banking API provider ID (finAPI) of the according remote contra account;                              import will be done without transaction data if not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDepot: async (id: number, remoteContraAccountId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importDepot', 'id', id)
            const localVarPath = `/v1/remoteDepots/bookingsForInitialImport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (remoteContraAccountId !== undefined) {
                localVarQueryParameter['remoteContraAccountId'] = remoteContraAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import a remote depot
         * @param {number} id ID of the remote depot to import
         * @param {ImportRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteDepot: async (id: number, body: ImportRemoteDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importRemoteDepot', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('importRemoteDepot', 'body', body)
            const localVarPath = `/v1/remoteDepots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import a remote depot using the TPP web form flow
         * @param {number} id ID of the remote depot to import
         * @param {string} tppWebFormToken Token (ID) of the web form carrying the required user information
         * @param {ImportRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteDepotViaTppWebForm: async (id: number, tppWebFormToken: string, body: ImportRemoteDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importRemoteDepotViaTppWebForm', 'id', id)
            // verify required parameter 'tppWebFormToken' is not null or undefined
            assertParamExists('importRemoteDepotViaTppWebForm', 'tppWebFormToken', tppWebFormToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('importRemoteDepotViaTppWebForm', 'body', body)
            const localVarPath = `/v1/remoteDepots/{id}/tppWebForm/{tppWebFormToken}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tppWebFormToken"}}`, encodeURIComponent(String(tppWebFormToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import changes as detected based on the given SEPA transactions and securities. In case                 an empty list of SEPA transaction is provided, this endpoint will do only tracking error resolution.                 This endpoint is intended for backend communication used together with our API v3 and should not                 be called by a fronted or third-party application.
         * @param {number} id ID of the remote depot to update
         * @param {BatchSynchronizationParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saasUpdateRemoteDepot: async (id: number, body: BatchSynchronizationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saasUpdateRemoteDepot', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saasUpdateRemoteDepot', 'body', body)
            const localVarPath = `/v1/remoteDepots/{id}/saas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments and return a synchronization log.
         * @param {number} id ID of the remote depot to update
         * @param {UpdateRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteDepot: async (id: number, body: UpdateRemoteDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRemoteDepot', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRemoteDepot', 'body', body)
            const localVarPath = `/v1/remoteDepots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments using the tpp web form flow.
         * @param {number} id ID of the remote depot to update
         * @param {string} tppWebFormToken Token (ID) of the web form carrying the required user information
         * @param {UpdateRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteDepotViaTppWebForm: async (id: number, tppWebFormToken: string, body: UpdateRemoteDepotParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRemoteDepotViaTppWebForm', 'id', id)
            // verify required parameter 'tppWebFormToken' is not null or undefined
            assertParamExists('updateRemoteDepotViaTppWebForm', 'tppWebFormToken', tppWebFormToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRemoteDepotViaTppWebForm', 'body', body)
            const localVarPath = `/v1/remoteDepots/{id}/tppWebForm/{tppWebFormToken}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tppWebFormToken"}}`, encodeURIComponent(String(tppWebFormToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RemoteDepotsApi - functional programming interface
 * @export
 */
export const RemoteDepotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RemoteDepotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get remote securities for the given banking provider (finAPI) account ID.                      Remote securities are all securities that                      are stored on finAPI side. Use this endpoint to check locally persisted securities against the                      remote set.
         * @param {number} id Banking provider (finAPI) account ID of the account/depot holding the securities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteSecurities(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableSecurityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemoteSecurities(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.getRemoteSecurities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED: List changes required for an account import
         * @param {number} id Banking API provider ID (finApi) of the remote account (depot or others) to import.
         * @param {number} [remoteContraAccountId] Banking API provider ID (finAPI) of the according remote contra account;                              import will be done without transaction data if not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importDepot(id: number, remoteContraAccountId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountImportOrSynchronizationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importDepot(id, remoteContraAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.importDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import a remote depot
         * @param {number} id ID of the remote depot to import
         * @param {ImportRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importRemoteDepot(id: number, body: ImportRemoteDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importRemoteDepot(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.importRemoteDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import a remote depot using the TPP web form flow
         * @param {number} id ID of the remote depot to import
         * @param {string} tppWebFormToken Token (ID) of the web form carrying the required user information
         * @param {ImportRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importRemoteDepotViaTppWebForm(id: number, tppWebFormToken: string, body: ImportRemoteDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importRemoteDepotViaTppWebForm(id, tppWebFormToken, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.importRemoteDepotViaTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import changes as detected based on the given SEPA transactions and securities. In case                 an empty list of SEPA transaction is provided, this endpoint will do only tracking error resolution.                 This endpoint is intended for backend communication used together with our API v3 and should not                 be called by a fronted or third-party application.
         * @param {number} id ID of the remote depot to update
         * @param {BatchSynchronizationParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saasUpdateRemoteDepot(id: number, body: BatchSynchronizationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saasUpdateRemoteDepot(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.saasUpdateRemoteDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments and return a synchronization log.
         * @param {number} id ID of the remote depot to update
         * @param {UpdateRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRemoteDepot(id: number, body: UpdateRemoteDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRemoteDepot(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.updateRemoteDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments using the tpp web form flow.
         * @param {number} id ID of the remote depot to update
         * @param {string} tppWebFormToken Token (ID) of the web form carrying the required user information
         * @param {UpdateRemoteDepotParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRemoteDepotViaTppWebForm(id: number, tppWebFormToken: string, body: UpdateRemoteDepotParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRemoteDepotViaTppWebForm(id, tppWebFormToken, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RemoteDepotsApi.updateRemoteDepotViaTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RemoteDepotsApi - factory interface
 * @export
 */
export const RemoteDepotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RemoteDepotsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get remote securities for the given banking provider (finAPI) account ID.                      Remote securities are all securities that                      are stored on finAPI side. Use this endpoint to check locally persisted securities against the                      remote set.
         * @param {RemoteDepotsApiGetRemoteSecuritiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSecurities(requestParameters: RemoteDepotsApiGetRemoteSecuritiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableSecurityList> {
            return localVarFp.getRemoteSecurities(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED: List changes required for an account import
         * @param {RemoteDepotsApiImportDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDepot(requestParameters: RemoteDepotsApiImportDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountImportOrSynchronizationResult> {
            return localVarFp.importDepot(requestParameters.id, requestParameters.remoteContraAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import a remote depot
         * @param {RemoteDepotsApiImportRemoteDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteDepot(requestParameters: RemoteDepotsApiImportRemoteDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.importRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import a remote depot using the TPP web form flow
         * @param {RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteDepotViaTppWebForm(requestParameters: RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.importRemoteDepotViaTppWebForm(requestParameters.id, requestParameters.tppWebFormToken, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import changes as detected based on the given SEPA transactions and securities. In case                 an empty list of SEPA transaction is provided, this endpoint will do only tracking error resolution.                 This endpoint is intended for backend communication used together with our API v3 and should not                 be called by a fronted or third-party application.
         * @param {RemoteDepotsApiSaasUpdateRemoteDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saasUpdateRemoteDepot(requestParameters: RemoteDepotsApiSaasUpdateRemoteDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.saasUpdateRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments and return a synchronization log.
         * @param {RemoteDepotsApiUpdateRemoteDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteDepot(requestParameters: RemoteDepotsApiUpdateRemoteDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.updateRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import all detected remote changes to an accounts investments using the tpp web form flow.
         * @param {RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteDepotViaTppWebForm(requestParameters: RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.updateRemoteDepotViaTppWebForm(requestParameters.id, requestParameters.tppWebFormToken, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRemoteSecurities operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiGetRemoteSecuritiesRequest
 */
export interface RemoteDepotsApiGetRemoteSecuritiesRequest {
    /**
     * Banking provider (finAPI) account ID of the account/depot holding the securities
     * @type {number}
     * @memberof RemoteDepotsApiGetRemoteSecurities
     */
    readonly id: number
}

/**
 * Request parameters for importDepot operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiImportDepotRequest
 */
export interface RemoteDepotsApiImportDepotRequest {
    /**
     * Banking API provider ID (finApi) of the remote account (depot or others) to import.
     * @type {number}
     * @memberof RemoteDepotsApiImportDepot
     */
    readonly id: number

    /**
     * Banking API provider ID (finAPI) of the according remote contra account;                              import will be done without transaction data if not provided
     * @type {number}
     * @memberof RemoteDepotsApiImportDepot
     */
    readonly remoteContraAccountId?: number
}

/**
 * Request parameters for importRemoteDepot operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiImportRemoteDepotRequest
 */
export interface RemoteDepotsApiImportRemoteDepotRequest {
    /**
     * ID of the remote depot to import
     * @type {number}
     * @memberof RemoteDepotsApiImportRemoteDepot
     */
    readonly id: number

    /**
     * The account details
     * @type {ImportRemoteDepotParams}
     * @memberof RemoteDepotsApiImportRemoteDepot
     */
    readonly body: ImportRemoteDepotParams
}

/**
 * Request parameters for importRemoteDepotViaTppWebForm operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest
 */
export interface RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest {
    /**
     * ID of the remote depot to import
     * @type {number}
     * @memberof RemoteDepotsApiImportRemoteDepotViaTppWebForm
     */
    readonly id: number

    /**
     * Token (ID) of the web form carrying the required user information
     * @type {string}
     * @memberof RemoteDepotsApiImportRemoteDepotViaTppWebForm
     */
    readonly tppWebFormToken: string

    /**
     * The account details
     * @type {ImportRemoteDepotParams}
     * @memberof RemoteDepotsApiImportRemoteDepotViaTppWebForm
     */
    readonly body: ImportRemoteDepotParams
}

/**
 * Request parameters for saasUpdateRemoteDepot operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiSaasUpdateRemoteDepotRequest
 */
export interface RemoteDepotsApiSaasUpdateRemoteDepotRequest {
    /**
     * ID of the remote depot to update
     * @type {number}
     * @memberof RemoteDepotsApiSaasUpdateRemoteDepot
     */
    readonly id: number

    /**
     * The account details
     * @type {BatchSynchronizationParams}
     * @memberof RemoteDepotsApiSaasUpdateRemoteDepot
     */
    readonly body: BatchSynchronizationParams
}

/**
 * Request parameters for updateRemoteDepot operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiUpdateRemoteDepotRequest
 */
export interface RemoteDepotsApiUpdateRemoteDepotRequest {
    /**
     * ID of the remote depot to update
     * @type {number}
     * @memberof RemoteDepotsApiUpdateRemoteDepot
     */
    readonly id: number

    /**
     * The account details
     * @type {UpdateRemoteDepotParams}
     * @memberof RemoteDepotsApiUpdateRemoteDepot
     */
    readonly body: UpdateRemoteDepotParams
}

/**
 * Request parameters for updateRemoteDepotViaTppWebForm operation in RemoteDepotsApi.
 * @export
 * @interface RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest
 */
export interface RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest {
    /**
     * ID of the remote depot to update
     * @type {number}
     * @memberof RemoteDepotsApiUpdateRemoteDepotViaTppWebForm
     */
    readonly id: number

    /**
     * Token (ID) of the web form carrying the required user information
     * @type {string}
     * @memberof RemoteDepotsApiUpdateRemoteDepotViaTppWebForm
     */
    readonly tppWebFormToken: string

    /**
     * The account details
     * @type {UpdateRemoteDepotParams}
     * @memberof RemoteDepotsApiUpdateRemoteDepotViaTppWebForm
     */
    readonly body: UpdateRemoteDepotParams
}

/**
 * RemoteDepotsApi - object-oriented interface
 * @export
 * @class RemoteDepotsApi
 * @extends {BaseAPI}
 */
export class RemoteDepotsApi extends BaseAPI {
    /**
     * 
     * @summary Get remote securities for the given banking provider (finAPI) account ID.                      Remote securities are all securities that                      are stored on finAPI side. Use this endpoint to check locally persisted securities against the                      remote set.
     * @param {RemoteDepotsApiGetRemoteSecuritiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public getRemoteSecurities(requestParameters: RemoteDepotsApiGetRemoteSecuritiesRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).getRemoteSecurities(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED: List changes required for an account import
     * @param {RemoteDepotsApiImportDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public importDepot(requestParameters: RemoteDepotsApiImportDepotRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).importDepot(requestParameters.id, requestParameters.remoteContraAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import a remote depot
     * @param {RemoteDepotsApiImportRemoteDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public importRemoteDepot(requestParameters: RemoteDepotsApiImportRemoteDepotRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).importRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import a remote depot using the TPP web form flow
     * @param {RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public importRemoteDepotViaTppWebForm(requestParameters: RemoteDepotsApiImportRemoteDepotViaTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).importRemoteDepotViaTppWebForm(requestParameters.id, requestParameters.tppWebFormToken, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import changes as detected based on the given SEPA transactions and securities. In case                 an empty list of SEPA transaction is provided, this endpoint will do only tracking error resolution.                 This endpoint is intended for backend communication used together with our API v3 and should not                 be called by a fronted or third-party application.
     * @param {RemoteDepotsApiSaasUpdateRemoteDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public saasUpdateRemoteDepot(requestParameters: RemoteDepotsApiSaasUpdateRemoteDepotRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).saasUpdateRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import all detected remote changes to an accounts investments and return a synchronization log.
     * @param {RemoteDepotsApiUpdateRemoteDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public updateRemoteDepot(requestParameters: RemoteDepotsApiUpdateRemoteDepotRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).updateRemoteDepot(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import all detected remote changes to an accounts investments using the tpp web form flow.
     * @param {RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemoteDepotsApi
     */
    public updateRemoteDepotViaTppWebForm(requestParameters: RemoteDepotsApiUpdateRemoteDepotViaTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return RemoteDepotsApiFp(this.configuration).updateRemoteDepotViaTppWebForm(requestParameters.id, requestParameters.tppWebFormToken, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

