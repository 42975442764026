import React from "react";
import { Box, Typography, TypographyProps, styled } from "@mui/material";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { GainLossTypographyProps } from "@components/common/numbers/GainLossTypography/GainLossTypography";

type ValueType = "positive" | "negative" | "zero";

const ArrowContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "positive",
})<{ positive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme, positive }) =>
    positive ? theme.palette.text.positive : theme.palette.text.negative};
`;

type Props = TypographyProps &
  Omit<GainLossTypographyProps, "value"> & {
    value?: number | string;
    blur?: number;
    showBlackOnPositive?: boolean;
    primary?: boolean;
    shouldBlur?: boolean;
  };

export const BlurredTypography = ({
  value,
  showIndicator = false,
  shouldBlur = false,
  iconSize = "8px",
  blur = 10,
  showBlackOnPositive = false,
  primary = false,
  ...props
}: Props) => {
  const { isAnonymized } = useSharedPortfolioContext();

  let valueType: ValueType = "zero";
  if (typeof value === "number" && value !== undefined && value > 0) {
    valueType = showBlackOnPositive ? "zero" : "positive";
  }
  if (typeof value === "number" && value !== undefined && value < 0) {
    valueType = "negative";
  }

  if (value === undefined) {
    return (
      <Typography
        {...props}
        sx={{
          filter: isAnonymized || shouldBlur ? `blur(${blur}px)` : "none",
          pointerEvents: isAnonymized || shouldBlur ? "none" : "auto",
          ...props.sx,
        }}
      />
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      sx={{
        filter: isAnonymized || shouldBlur ? `blur(${blur}px)` : "none",
        pointerEvents: isAnonymized || shouldBlur ? "none" : "auto",
        ...props.sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          color: (theme) => {
            if (primary) {
              return theme.palette.text.primary;
            }
            if (valueType === "positive") {
              return theme.palette.text.positive;
            }
            if (valueType === "negative") {
              return theme.palette.text.negative;
            }
            return theme.palette.text.primary;
          },
          ...props.sx,
        }}
        {...props}
      />
      {showIndicator && value !== undefined && (
        <ArrowContainer positive={value > 0}>
          {value > 0 && <UpIcon width={iconSize} />}
          {value < 0 && <DownIcon width={iconSize} />}
        </ArrowContainer>
      )}
    </Box>
  );
};
