/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { IndustryBreakdownList } from '../model';
// @ts-ignore
import { InvestmentTypeBreakdownList } from '../model';
// @ts-ignore
import { RegionBreakdownList } from '../model';
/**
 * ClassificationsApi - axios parameter creator
 * @export
 */
export const ClassificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get investments by currency
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencyClassificationsForAllInvestments: async (accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/classifications/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIndustryClassificationsForAllInvestments: async (accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/classifications/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionClassificationsForAllInvestments: async (accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/classifications/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get investments by type
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTypeClassificationsForAllInvestments: async (accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/classifications/investmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get investments by currency
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListCurrencyClassificationsForAllInvestments: async (publicId: string, accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicListCurrencyClassificationsForAllInvestments', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/classifications/currencies`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListIndustryClassificationsForAllInvestments: async (publicId: string, accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicListIndustryClassificationsForAllInvestments', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/classifications/industries`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListRegionClassificationsForAllInvestments: async (publicId: string, accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicListRegionClassificationsForAllInvestments', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/classifications/regions`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get investments by type
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListTypeClassificationsForAllInvestments: async (publicId: string, accountId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicListTypeClassificationsForAllInvestments', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/classifications/investmentTypes`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassificationsApi - functional programming interface
 * @export
 */
export const ClassificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get investments by currency
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCurrencyClassificationsForAllInvestments(accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentTypeBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCurrencyClassificationsForAllInvestments(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.listCurrencyClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIndustryClassificationsForAllInvestments(accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndustryBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIndustryClassificationsForAllInvestments(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.listIndustryClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRegionClassificationsForAllInvestments(accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRegionClassificationsForAllInvestments(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.listRegionClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get investments by type
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTypeClassificationsForAllInvestments(accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentTypeBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTypeClassificationsForAllInvestments(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.listTypeClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get investments by currency
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListCurrencyClassificationsForAllInvestments(publicId: string, accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentTypeBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListCurrencyClassificationsForAllInvestments(publicId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.publicListCurrencyClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListIndustryClassificationsForAllInvestments(publicId: string, accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndustryBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListIndustryClassificationsForAllInvestments(publicId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.publicListIndustryClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListRegionClassificationsForAllInvestments(publicId: string, accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListRegionClassificationsForAllInvestments(publicId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.publicListRegionClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get investments by type
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListTypeClassificationsForAllInvestments(publicId: string, accountId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentTypeBreakdownList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListTypeClassificationsForAllInvestments(publicId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassificationsApi.publicListTypeClassificationsForAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClassificationsApi - factory interface
 * @export
 */
export const ClassificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get investments by currency
         * @param {ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencyClassificationsForAllInvestments(requestParameters: ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InvestmentTypeBreakdownList> {
            return localVarFp.listCurrencyClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIndustryClassificationsForAllInvestments(requestParameters: ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<IndustryBreakdownList> {
            return localVarFp.listIndustryClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {ClassificationsApiListRegionClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionClassificationsForAllInvestments(requestParameters: ClassificationsApiListRegionClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RegionBreakdownList> {
            return localVarFp.listRegionClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get investments by type
         * @param {ClassificationsApiListTypeClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTypeClassificationsForAllInvestments(requestParameters: ClassificationsApiListTypeClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InvestmentTypeBreakdownList> {
            return localVarFp.listTypeClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get investments by currency
         * @param {ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListCurrencyClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InvestmentTypeBreakdownList> {
            return localVarFp.publicListCurrencyClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get classifications by industries
         * @param {ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListIndustryClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<IndustryBreakdownList> {
            return localVarFp.publicListIndustryClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get classifications by regions
         * @param {ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListRegionClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<RegionBreakdownList> {
            return localVarFp.publicListRegionClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get investments by type
         * @param {ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListTypeClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InvestmentTypeBreakdownList> {
            return localVarFp.publicListTypeClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listCurrencyClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest {
    /**
     * Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiListCurrencyClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for listIndustryClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest {
    /**
     * Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiListIndustryClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for listRegionClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiListRegionClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiListRegionClassificationsForAllInvestmentsRequest {
    /**
     * Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiListRegionClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for listTypeClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiListTypeClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiListTypeClassificationsForAllInvestmentsRequest {
    /**
     * Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiListTypeClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for publicListCurrencyClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof ClassificationsApiPublicListCurrencyClassificationsForAllInvestments
     */
    readonly publicId: string

    /**
     * Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiPublicListCurrencyClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for publicListIndustryClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof ClassificationsApiPublicListIndustryClassificationsForAllInvestments
     */
    readonly publicId: string

    /**
     * Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiPublicListIndustryClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for publicListRegionClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof ClassificationsApiPublicListRegionClassificationsForAllInvestments
     */
    readonly publicId: string

    /**
     * Retrieves classification by regions for selected account ids. Returns classification                                for all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiPublicListRegionClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * Request parameters for publicListTypeClassificationsForAllInvestments operation in ClassificationsApi.
 * @export
 * @interface ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest
 */
export interface ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof ClassificationsApiPublicListTypeClassificationsForAllInvestments
     */
    readonly publicId: string

    /**
     * Retrieves classification by type for selected account ids. Returns classification for                                all investments if not given. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof ClassificationsApiPublicListTypeClassificationsForAllInvestments
     */
    readonly accountId?: Array<number>
}

/**
 * ClassificationsApi - object-oriented interface
 * @export
 * @class ClassificationsApi
 * @extends {BaseAPI}
 */
export class ClassificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get investments by currency
     * @param {ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public listCurrencyClassificationsForAllInvestments(requestParameters: ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).listCurrencyClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get classifications by industries
     * @param {ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public listIndustryClassificationsForAllInvestments(requestParameters: ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).listIndustryClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get classifications by regions
     * @param {ClassificationsApiListRegionClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public listRegionClassificationsForAllInvestments(requestParameters: ClassificationsApiListRegionClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).listRegionClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get investments by type
     * @param {ClassificationsApiListTypeClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public listTypeClassificationsForAllInvestments(requestParameters: ClassificationsApiListTypeClassificationsForAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).listTypeClassificationsForAllInvestments(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get investments by currency
     * @param {ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public publicListCurrencyClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListCurrencyClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).publicListCurrencyClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get classifications by industries
     * @param {ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public publicListIndustryClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListIndustryClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).publicListIndustryClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get classifications by regions
     * @param {ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public publicListRegionClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListRegionClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).publicListRegionClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get investments by type
     * @param {ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationsApi
     */
    public publicListTypeClassificationsForAllInvestments(requestParameters: ClassificationsApiPublicListTypeClassificationsForAllInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ClassificationsApiFp(this.configuration).publicListTypeClassificationsForAllInvestments(requestParameters.publicId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }
}

