import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setDepotAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (
    event.type !== "INIT_FROM_SYMBOL_SEARCH" &&
    event.type !== "INIT_FROM_TICKER" &&
    event.type !== "INIT_FROM_INVESTMENT_TYPE" &&
    event.type !== "INIT_FROM_BOOKING_TYPE"
  )
    throw new Error(`Invalid event type ${event.type}`);

  if (!event.depotId) return {};

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId: event.depotId,
    },
  };
};
