import { UseMutationOptions } from "react-query";
import {
  InsurancesApiMergeInsurancesRequest,
  Insurance,
} from "@generated/apiv1";

import { buildInsurancesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const mergeInsurances = async (
  token: string | undefined,
  params: InsurancesApiMergeInsurancesRequest
) => {
  const apiInstance = buildInsurancesApi(token);
  const { data } = await apiInstance.mergeInsurances(params);
  return data;
};

export const useMergeInsurances = (
  options?: UseMutationOptions<
    Insurance,
    AxiosApiError,
    InsurancesApiMergeInsurancesRequest
  >
) => {
  return useAuthMutation(mergeInsurances, options, false);
};
