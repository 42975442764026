import React from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { CreateOrUpdateInvestmentParams, InvestmentTypeEnum } from "@api";
import { useGetCurrencies } from "@api/v1/hooks/useCurrencies";
import { useUpdateInvestment } from "@api/v1/hooks/useUpdateInvestment";
import { GET_INVESTMENT_KEY } from "@api/cacheKeys";
import { InvestmentEditAdvancedTabContainer } from "./InvestmentEditAdvancedTabContainer";

interface Props {
  investmentId: number;
  investmentISIN: string;
  investmentWKN?: string;
  investmentType: InvestmentTypeEnum;
  investmentCurrency: string;
  investmentName: string;
}

export const InvestmentEditAdvancedTab = ({
  investmentId,
  investmentISIN,
  investmentWKN,
  investmentType,
  investmentCurrency,
  investmentName,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: currenciesResponse } = useGetCurrencies({ type: "standard" });
  const { mutateAsync: update, isLoading } = useUpdateInvestment();

  const handleSaveClick = (data: CreateOrUpdateInvestmentParams) => {
    update(
      { id: investmentId, ...data },
      {
        onSuccess: async () => {
          enqueueSnackbar(
            `${t("investmentEditPage.basicTab.saved")}: ${investmentName}`,
            { autoHideDuration: 3000, variant: "success" }
          );
          await queryClient.invalidateQueries([GET_INVESTMENT_KEY]);
        },
      }
    );
  };

  return (
    <InvestmentEditAdvancedTabContainer
      investmentId={investmentId}
      investmentISIN={investmentISIN}
      investmentWKN={investmentWKN}
      investmentType={investmentType}
      investmentCurrency={investmentCurrency}
      currencies={currenciesResponse?.currencies ?? []}
      isSaving={isLoading}
      onSave={handleSaveClick}
    />
  );
};
