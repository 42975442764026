import { UseQueryOptions } from "react-query";
import { getSecurityComissions } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { PageableSecurityCommissionList } from "@generated/apiv1";
import { GET_SECURITY_COMISSIONS } from "../../cacheKeys";

export const useGetSecuritiesComission = (
  params: number[],
  options: UseQueryOptions<
    PageableSecurityCommissionList,
    AxiosApiError,
    PageableSecurityCommissionList
  > = {
    enabled: Boolean(params.length),
    initialData: { securityCommissions: [] },
  }
) =>
  useAuthQuery(
    [GET_SECURITY_COMISSIONS, params],
    ({ token }) => getSecurityComissions(token, params),
    options
  );
