import { InvokeCallback, Sender } from "xstate";

import i18next from "i18next";
import {
  validateBookingsWithToken,
  validateCarveOutWithToken,
  validateSwitchWithToken,
} from "@api/v1/methods/bookings";
import { BookingValues } from "../types/bookingValues";
import { InvestmentValues } from "../types/investmentValues";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import {
  buildBookingParams,
  buildCarveOutParams,
  buildInvestmentParams,
  buildInvestmentParamsList,
  buildSwitchParams,
} from "../utils/buildRequestParams";
import { isCapitalMeasure } from "../utils/utils";

export const validateBooking =
  (
    context: TransactionWizardContext
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (!context.investmentValues.depotId)
      throw new Error("no depot ID provided");
    if (!context.bookingValues.type) throw new Error("No booking type defined");
    if (
      isCapitalMeasure(context.bookingValues.type) &&
      !context.sourceInvestmentValues?.id
    )
      throw new Error("No source investment ID defined");

    isCapitalMeasure(context.bookingValues.type)
      ? validateCapitalMeasure(
          callback,
          context.sourceInvestmentValues?.id!,
          context.investmentValues,
          context.bookingValues
        )
      : validateStandard(
          callback,
          context.investmentValues,
          context.bookingValues
        );
  };

const validateStandard = async (
  callback: Sender<TransactionWizardEvent>,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    const createOrUpdateInvestmentParamsList = buildInvestmentParamsList([
      buildInvestmentParams(investmentValues, [
        buildBookingParams(bookingValues),
      ]),
    ]);
    const result = await validateBookingsWithToken(
      createOrUpdateInvestmentParamsList
    );

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      return;
    }

    const invalidError = i18next.t(
      "transactionWizardModal.persist.defaultError"
    );

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage || invalidError
          : i18next.t("transactionWizardModal.persist.defaultError"),
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};

const validateCapitalMeasure = async (
  callback: Sender<TransactionWizardEvent>,
  sourceInvestmentId: number,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    const investmentParams = buildInvestmentParams(investmentValues, [
      buildBookingParams(bookingValues),
    ]);

    const result =
      bookingValues.type === "carve_out"
        ? await validateCarveOutWithToken(
            buildCarveOutParams(sourceInvestmentId, investmentParams)
          )
        : await validateSwitchWithToken(
            buildSwitchParams(sourceInvestmentId, investmentParams)
          );

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      return;
    }

    const invalidError = i18next.t(
      "transactionWizardModal.persist.defaultError"
    );

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage || invalidError
          : invalidError,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
