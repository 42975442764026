import { assign, createMachine } from "xstate";
import { initFromInvestmentTypeAction } from "@features/transactionWizard/stateMachine/actions/initFromInvestmentTypeAction";
import { loadBookings } from "@features/transactionWizard/stateMachine/services/loadBookings";
import { persistSplit } from "@features/transactionWizard/stateMachine/services/persistSplit";
import { updateSplitValuesAction } from "@features/transactionWizard/stateMachine/actions/updateSplitValuesAction";
import { setBookingsAction } from "@features/transactionWizard/stateMachine/actions/setBookingsAction";
import { clearInvestmentValuesAction } from "@features/transactionWizard/stateMachine/actions/clearInvestmentValuesAction";
import { setInvestmentIdAction } from "@features/transactionWizard/stateMachine/actions/setInvestmentIdAction";
import { setSourceInvestmentValuesAction } from "@features/transactionWizard/stateMachine/actions/setSourceInvestmentValuesAction";
import { clearBookingTypeAction } from "@features/transactionWizard/stateMachine/actions/clearBookingTypeAction";
import { setInvestmentValuesAction } from "@features/transactionWizard/stateMachine/actions/setInvestmentValuesAction";
import { setManualInvestmentAction } from "@features/transactionWizard/stateMachine/actions/setManualInvestmentAction";
import { clearManualInvestmentAction } from "@features/transactionWizard/stateMachine/actions/clearManualInvestmentAction";
import { clearSourceInvestmentValuesAction } from "@features/transactionWizard/stateMachine/actions/clearSourceInvestmentValuesAction";
import { addAxiosErrorToContextAction } from "./actions/addAxiosErrorToContextAction";
import { addBookingValuesAction } from "./actions/addBookingValuesAction";
import { addValidationErrorAction } from "./actions/addValidationErrorAction";
import { clearValidationErrorAction } from "./actions/clearValidationErrorAction";
import { setBookingTypeAction } from "./actions/setBookingTypeAction";
import { setDepotAction } from "./actions/setDepotAction";
import { setNameAndDepotAction } from "./actions/setNameAndDepotAction";
import { setSelectedCurrencyAction } from "./actions/setSelectedCurrencyAction";
import { setSymbolAction } from "./actions/setSymbolAction";
import { persistBooking } from "./services/persistBooking";
import { validateBooking } from "./services/validateBooking";
import { TransactionWizardState } from "./types/transactionWizardState";
import {
  defaultContext,
  TransactionWizardContext,
} from "./types/transactionWizardContext";
import { TransactionWizardEvent } from "./types/TransactionWizardEvent";
import { loadSymbol } from "./services/loadSymbol";
import { setEntryQuoteAction } from "./actions/setEntryQuoteAction";

export const newTransactionWizardSM = createMachine<
  TransactionWizardContext,
  TransactionWizardEvent,
  TransactionWizardState
>(
  {
    id: "newTransactionWizardSM",

    // Initial state
    initial: "idle",

    // Local context for entire machine
    context: defaultContext,

    // State definitions
    states: {
      idle: {
        on: {
          INIT_FROM_SYMBOL_SEARCH: {
            target: "selectInvestmentType",
            actions: ["setDepotAction"],
          },
          INIT_FROM_TICKER: {
            target: "loadSymbol",
            actions: ["setDepotAction"],
          },
        },
      },
      selectInvestmentType: {
        id: "selectInvestmentType",
        // this exists to remove "you have 22 lots" message
        entry: ["clearInvestmentValuesAction"],
        on: {
          INVESTMENT_TYPE_SELECTED: [
            {
              target: "selectBookingType",
              cond: (_, event) =>
                event.investmentType === "71_massets" ||
                event.investmentType === "91_managed",
            },
            {
              target: "searchSymbol",
            },
          ],
        },
        exit: ["initFromInvestmentTypeAction"],
      },
      searchSymbol: {
        id: "searchSymbol",
        on: {
          INIT_FROM_SYMBOL: [
            {
              target: "priceAndDate",
              cond: (context) =>
                context.bookingValues.type === "switch" ||
                context.bookingValues.type === "carve_out",
            },
            { target: "depots" },
          ],
          MANUAL_SELECT: {
            target: "manualInvestment",
            actions: ["setManualInvestmentAction"],
          },
          BACK: [
            {
              target: "depots",
              cond: (context) =>
                (context.investmentValues.type === "71_massets" ||
                  context.investmentValues.type === "91_managed") &&
                (context.bookingValues.type === "switch" ||
                  context.bookingValues.type === "carve_out"),
              actions: ["clearSourceInvestmentValuesAction"],
            },
            {
              target: "selectBookingType",
              cond: (context) =>
                context.bookingValues.type === "switch" ||
                context.bookingValues.type === "carve_out",
            },
            {
              target: "idle",
            },
          ],
        },
        exit: ["setSymbolAction"],
      },
      loadSymbol: {
        invoke: {
          id: "loadSymbol",
          src: loadSymbol,
        },
        on: {
          INIT_FROM_SYMBOL: {
            target: "depots",
            actions: ["setSymbolAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      manualInvestment: {
        on: {
          INVESTMENT_SELECTED: [
            {
              target: "priceAndDate",
              actions: ["setInvestmentValuesAction"],
            },
          ],
          BACK: {
            target: "searchSymbol",
            actions: ["clearManualInvestmentAction"],
          },
        },
      },
      depots: {
        id: "depots",
        on: {
          NAME_AND_DEPOT_SELECTED: [
            {
              target: "split",
              cond: (context) => context.bookingValues.type === "split",
              actions: ["setNameAndDepotAction"],
            },
            {
              target: "searchSymbol",
              cond: (context) =>
                (context.investmentValues.type === "71_massets" ||
                  context.investmentValues.type === "91_managed") &&
                (context.bookingValues.type === "switch" ||
                  context.bookingValues.type === "carve_out"),
              actions: [
                "setNameAndDepotAction",
                "setSourceInvestmentValuesAction",
              ],
            },
            {
              target: "priceAndDate",
              cond: (context) =>
                context.investmentValues.type === "71_massets" ||
                context.investmentValues.type === "91_managed",
              actions: ["setNameAndDepotAction"],
            },
            {
              target: "selectBookingType",
              actions: ["setNameAndDepotAction"],
            },
          ],
          BACK: [
            {
              target: "selectBookingType",
              cond: (context) =>
                context.investmentValues.type === "71_massets" ||
                context.investmentValues.type === "91_managed",
              actions: ["clearInvestmentValuesAction"],
            },
            {
              target: "searchSymbol",
              actions: ["clearInvestmentValuesAction"],
            },
          ],
        },
      },
      selectBookingType: {
        id: "selectBookingType",
        on: {
          BOOKING_TYPE_SELECTED: [
            {
              target: "depots",
              cond: (context) =>
                context.investmentValues.type === "71_massets" ||
                context.investmentValues.type === "91_managed",
              actions: ["setBookingTypeAction"],
            },
            {
              target: "split",
              cond: (_, event) => event.bookingType === "split",
              actions: ["setBookingTypeAction", "setInvestmentIdAction"],
            },
            {
              target: "searchSymbol",
              cond: (_, event) =>
                event.bookingType === "carve_out" ||
                event.bookingType === "switch",
              actions: [
                "setBookingTypeAction",
                "setInvestmentIdAction",
                "setSourceInvestmentValuesAction",
              ],
            },
            {
              target: "priceAndDate",
              actions: ["setBookingTypeAction"],
            },
          ],
          BACK: [
            {
              target: "selectInvestmentType",
              cond: (context) =>
                context.investmentValues.type === "71_massets" ||
                context.investmentValues.type === "91_managed",
              actions: ["clearBookingTypeAction"],
            },
            {
              target: "depots",
              actions: ["clearBookingTypeAction"],
            },
          ],
        },
      },
      priceAndDate: {
        id: "priceAndDate",
        on: {
          VALIDATE_BOOKING: {
            target: "validateBooking",
            actions: ["addBookingValuesAction", "setSelectedCurrencyAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
          BACK: [
            {
              target: "manualInvestment",
              cond: (context) => Boolean(context.manualInvestmentSelected),
            },
            {
              target: "searchSymbol",
              cond: (context) =>
                context.bookingValues.type === "switch" ||
                context.bookingValues.type === "carve_out",
            },
            {
              target: "depots",
              cond: (context) =>
                context.investmentValues.type === "71_massets" ||
                context.investmentValues.type === "91_managed",
              actions: [
                "clearPriceAndDateAction",
                "clearValidationErrorAction",
              ],
            },
            {
              target: "selectBookingType",
              actions: [
                "clearPriceAndDateAction",
                "clearValidationErrorAction",
              ],
            },
          ],
        },
      },
      split: {
        initial: "loadBookings",
        states: {
          loadBookings: {
            invoke: { id: "loadBookings", src: loadBookings },
            on: {
              BOOKINGS_LOADED: {
                target: "ratioAndDate",
                actions: ["setBookingsAction"],
              },
              SOMETHING_WRONG: { target: "#somethingWrong" },
            },
          },
          ratioAndDate: {
            on: {
              PREVIEW: {
                target: "preview",
                actions: ["updateSplitValuesAction"],
              },
              BACK: [
                {
                  target: "#depots",
                  cond: (context) =>
                    context.investmentValues.type === "71_massets" ||
                    context.investmentValues.type === "91_managed",
                },
                {
                  target: "#selectBookingType",
                },
              ],
            },
          },
          preview: {
            on: {
              PERSIST: { target: "persist" },
              BACK: { target: "ratioAndDate" },
            },
          },
          persist: {
            invoke: {
              id: "persistSplit",
              src: persistSplit,
            },
            on: {
              BOOKING_VALID: {
                target: "#persisted",
              },
              BOOKING_INVALID: {
                target: "ratioAndDate",
                actions: ["addValidationErrorAction"],
              },
              SOMETHING_WRONG: { target: "#somethingWrong" },
            },
          },
        },
      },
      validateBooking: {
        invoke: {
          id: "validateBooking",
          src: validateBooking,
        },
        on: {
          BOOKING_VALID: [
            {
              target: "persist",
              actions: ["clearValidationErrorAction"],
              cond: (context) => Boolean(context.skipSummaryStep),
            },
            {
              target: "summary",
              actions: ["clearValidationErrorAction"],
            },
          ],
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      summary: {
        on: {
          PERSIST: { target: "persist" },
          BACK: { target: "priceAndDate" },
        },
      },
      persist: {
        invoke: {
          id: "persistBooking",
          src: persistBooking,
        },
        on: {
          BOOKING_VALID: {
            target: "persisted",
          },
          BOOKING_INVALID: {
            target: "priceAndDate",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      persisted: {
        id: "persisted",
        type: "final",
      },
      somethingWrong: {
        type: "final",
        id: "somethingWrong",
        entry: ["addAxiosErrorToContextAction"],
      },
    },
  },
  {
    actions: {
      setSymbolAction: assign(setSymbolAction),
      initFromInvestmentTypeAction: assign(initFromInvestmentTypeAction),
      setSelectedCurrencyAction: assign(setSelectedCurrencyAction),
      setEntryQuoteAction: assign(setEntryQuoteAction),
      setBookingTypeAction: assign(setBookingTypeAction),
      setNameAndDepotAction: assign(setNameAndDepotAction),
      setDepotAction: assign(setDepotAction),
      addBookingValuesAction: assign(addBookingValuesAction),
      addValidationErrorAction: assign(addValidationErrorAction),
      clearValidationErrorAction: assign(clearValidationErrorAction),
      addAxiosErrorToContextAction: assign(addAxiosErrorToContextAction),
      clearInvestmentValuesAction: assign(clearInvestmentValuesAction),
      clearBookingTypeAction: assign(clearBookingTypeAction),
      updateSplitValuesAction: assign(updateSplitValuesAction),
      setBookingsAction: assign(setBookingsAction),
      setInvestmentIdAction: assign(setInvestmentIdAction),
      setSourceInvestmentValuesAction: assign(setSourceInvestmentValuesAction),
      setInvestmentValuesAction: assign(setInvestmentValuesAction),
      setManualInvestmentAction: assign(setManualInvestmentAction),
      clearManualInvestmentAction: assign(clearManualInvestmentAction),
      clearSourceInvestmentValuesAction: assign(
        clearSourceInvestmentValuesAction
      ),
    },
  }
);
