import React from "react";
import { useParams } from "react-router-dom";
import { CreateBatchDividendWizard } from "./CreateBatchDividendWizard";

export const CreateBatchDividendWizardWithRouting = () => {
  const { investmentId } = useParams<"investmentId">();

  return (
    <CreateBatchDividendWizard
      investmentId={Number.parseInt(investmentId!, 10)}
    />
  );
};
