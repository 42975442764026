import { UseMutationOptions } from "react-query";
import { MandatorsApiCreateMandatorRequest, Mandator } from "@generated/apiv1";

import { buildMandatorsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createMandator = async (
  token: string | undefined,
  params: MandatorsApiCreateMandatorRequest
) => {
  const apiInstance = buildMandatorsApi(token);
  const { data } = await apiInstance.createMandator(params);
  return data;
};

export const useCreateMandator = (
  options?: UseMutationOptions<
    Mandator,
    AxiosApiError,
    MandatorsApiCreateMandatorRequest
  >
) => {
  return useAuthMutation(createMandator, options, false);
};
