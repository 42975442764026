import { UseQueryOptions } from "react-query";
import {
  PlugAndPlayApiShowProductRequest,
  ShowProductResponse,
} from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showProduct = async (
  token: string | undefined,
  params: PlugAndPlayApiShowProductRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.showProduct(params);
  return data;
};

export const useShowProduct = (
  params: PlugAndPlayApiShowProductRequest,
  options?: UseQueryOptions<
    ShowProductResponse,
    AxiosApiError,
    ShowProductResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PLUGANDPLAY_SHOW_PRODUCT, params],
    ({ token }) => showProduct(token, params),
    options
  );
};
