import { UseMutationOptions } from "react-query";
import {
  PortfolioOperationsApiClonePortfolioRequest,
  Account,
} from "@generated/apiv1";

import { buildPortfolioOperationsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const clonePortfolio = async (
  token: string | undefined,
  params: PortfolioOperationsApiClonePortfolioRequest
) => {
  const apiInstance = buildPortfolioOperationsApi(token);
  const { data } = await apiInstance.clonePortfolio(params);
  return data;
};

export const useClonePortfolio = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    PortfolioOperationsApiClonePortfolioRequest
  >
) => {
  return useAuthMutation(clonePortfolio, options, false);
};
