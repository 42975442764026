import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { TextField, useTheme } from "@mui/material";
import { ReactComponent as Calendar } from "@icons/calendar.svg";

export interface Props {
  className?: string;
  date: Date;
  label: string;
  handleChange: (date: Date) => void;
  disableOpenPicker?: boolean;
  desktopVariant?: boolean;
}

export const DatePickerWithQuoteLookup = ({
  className,
  date,
  label,
  handleChange,
  disableOpenPicker = false,
  desktopVariant = false,
}: Props) => {
  const theme = useTheme();
  const handleDatePickerChange = (date?: Date | null) => {
    if (!date) return;
    handleChange(date);
  };

  return (
    <>
      {desktopVariant ? (
        <DesktopDatePicker
          className={className}
          data-testid="date-picker"
          disableFuture
          openTo="year"
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          label={label}
          views={["year", "month", "day"]}
          value={date}
          disableOpenPicker={disableOpenPicker}
          components={{
            OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
          }}
          PopperProps={{
            disablePortal: true,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                "data-testid": "date-picker-input",
              }}
              sx={{
                "& .MuiFormLabel-root": {
                  color: theme.palette.text.secondary,
                },
              }}
            />
          )}
          onChange={handleDatePickerChange}
        />
      ) : (
        <DatePicker
          className={className}
          data-testid="date-picker"
          disableFuture
          openTo="year"
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          label={label}
          views={["year", "month", "day"]}
          value={date}
          disableOpenPicker={disableOpenPicker}
          components={{
            OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
          }}
          PopperProps={{
            disablePortal: true,
          }}
          DialogProps={{ disablePortal: true }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                "data-testid": "date-picker-input",
              }}
              sx={{
                "& .MuiFormLabel-root": {
                  color: theme.palette.text.secondary,
                },
              }}
            />
          )}
          onChange={handleDatePickerChange}
        />
      )}
    </>
  );
};
