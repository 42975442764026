/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateAddressParams } from './create-or-update-address-params';
// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateNaturalPersonParams } from './create-or-update-natural-person-params';

/**
 * 
 * @export
 * @interface CreateOrUpdateAccountHolderParams
 */
export interface CreateOrUpdateAccountHolderParams {
    /**
     * Allows to assign the account holder to another user; including all of its                                subscriptions. This functionality is only available to subscription administrators
     * @type {number}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'userId'?: number;
    /**
     * An optional external ID; e.g. assigned by a pool
     * @type {string}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'externalId'?: string;
    /**
     * The account holder type; default is PRIVATE
     * @type {string}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'type'?: CreateOrUpdateAccountHolderParamsTypeEnum;
    /**
     * Is account synchronization via Netfonds API enabled for this account holder?
     * @type {boolean}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'isSyncEnabled'?: boolean;
    /**
     * An list of natural persons belonging to this account holder; max size is 2;                                required for creation
     * @type {Array<CreateOrUpdateNaturalPersonParams>}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'createOrUpdateNaturalPersonParamsList'?: Array<CreateOrUpdateNaturalPersonParams>;
    /**
     * 
     * @type {CreateOrUpdateAddressParams}
     * @memberof CreateOrUpdateAccountHolderParams
     */
    'createOrUpdateAdditionalMailingAddressParams'?: CreateOrUpdateAddressParams;
}

export const CreateOrUpdateAccountHolderParamsTypeEnum = {
    PRIVATE: 'PRIVATE',
    COMPANY: 'COMPANY'
} as const;

export type CreateOrUpdateAccountHolderParamsTypeEnum = typeof CreateOrUpdateAccountHolderParamsTypeEnum[keyof typeof CreateOrUpdateAccountHolderParamsTypeEnum];


