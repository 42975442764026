import React, { useState } from "react";
import { Box, Button, Link, styled, Typography, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { ReactComponent as LogoutIcon } from "@icons/myicons/logout.svg";
import { ReactComponent as TrashBinIcon } from "@icons/myicons/trash-bin-fill.svg";
import { User } from "@generated/user";
import { BUY, DISCORD_URL } from "@helpers/urlConstants";
import { useLogout } from "@hooks";
import { LoadingPlaceHolder } from "@components";
import { ProfileRow } from "./components/ProfileRow";
import { ModalType, profileEditOptions } from "./constants";
import { SettingsChangeNameModal } from "./components/SettingsChangeNameModal";
import { SettingsChangeEmailModal } from "./components/SettingsChangeEmailModal";
import { SettingsChangeDiscordIdModal } from "./components/SettingsChangeDiscordIdModal";
import { SettingsDeleteAccountModal } from "./components/SettingsDeleteAccountModal";
import { SettingsInitChangePasswordModal } from "./components/SettingsInitChangePasswordModal";

const HorizontalDivider = styled(Box)`
  content: "";
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.border.secondary};
`;

const SettingsProfilePage = ({
  isPlusUser,
  userData,
  isLoadingUserData,
}: {
  isPlusUser: boolean;
  userData?: User;
  isLoadingUserData: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<ModalType>("");
  const { logout } = useLogout();

  return (
    <>
      {isLoadingUserData ? (
        <LoadingPlaceHolder />
      ) : (
        <>
          <Box mb="83px" mt={theme.spacing(4)}>
            <Box display="flex" flexDirection="column" gap={theme.spacing(8)}>
              <ProfileRow
                handleChangeClick={() => setOpenModal("name" as ModalType)}
                buttonText={t("settingsPage.profile.change")}
                label={t(profileEditOptions.name.label)}
                text={userData?.displayName ?? ""}
              />
              <ProfileRow
                handleChangeClick={() => setOpenModal("email" as ModalType)}
                buttonText={t("settingsPage.profile.change")}
                label={t(profileEditOptions.email.label)}
                text={userData?.email ?? ""}
              />
              <ProfileRow
                handleChangeClick={() => setOpenModal("password" as ModalType)}
                buttonText={t("settingsPage.profile.change")}
                label={t(profileEditOptions.password.label)}
                text="********"
              />
              {isPlusUser && (
                <ProfileRow
                  handleChangeClick={() =>
                    setOpenModal("discordId" as ModalType)
                  }
                  buttonText={t("settingsPage.profile.change")}
                  label={t(profileEditOptions.discordId.label)}
                  text={userData?.discordId ?? "-"}
                />
              )}
            </Box>

            <>
              <Typography
                variant="body1"
                fontSize="18px"
                lineHeight="28px"
                fontWeight={400}
                mt={theme.spacing(8)}
                mb={theme.spacing(6)}
                letterSpacing={-0.35}
              >
                <Trans
                  i18nKey="settingsPage.profile.plusText"
                  components={{
                    link1: <Link target="_blank" href={DISCORD_URL} />,
                  }}
                />
              </Typography>
              <Button
                variant="contained"
                color="secondaryWarning"
                href={BUY}
                sx={{
                  width: "100%",
                }}
                startIcon={<LockIcon />}
              >
                {t("settingsPage.profile.plusButton")}
              </Button>
            </>
            <HorizontalDivider mt={theme.spacing(12)} />
            <Box pt={theme.spacing(12)}>
              <Typography
                lineHeight="30px"
                fontSize="22px"
                fontWeight={600}
                mb={theme.spacing(4)}
              >
                {t("settingsPage.profile.delete.title")}
              </Typography>
              <Typography
                variant="body1"
                display="flex"
                flexDirection="column"
                gap={theme.spacing(4)}
                fontWeight={400}
                mb={theme.spacing(6)}
                letterSpacing={-0.3}
              >
                {t("settingsPage.profile.delete.description")}
              </Typography>
              <Box display="flex" gap={theme.spacing(6)}>
                <Button
                  variant="outlined"
                  color="tertiary"
                  fullWidth
                  onClick={() => setOpenModal("delete")}
                  startIcon={<TrashBinIcon />}
                >
                  {t("settingsPage.profile.delete.button")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => logout()}
                  startIcon={<LogoutIcon height={18} width={18} />}
                >
                  {t("settingsPage.profile.delete.logoutButton")}
                </Button>
              </Box>
            </Box>
          </Box>
          <SettingsChangeNameModal
            name={userData?.displayName ?? ""}
            isOpen={openModal === "name"}
            onClose={() => setOpenModal("")}
          />
          <SettingsChangeEmailModal
            isOpen={openModal === "email"}
            onClose={() => setOpenModal("")}
            userEmail={userData?.email ?? ""}
          />
          <SettingsInitChangePasswordModal
            isOpen={openModal === "password"}
            onClose={() => setOpenModal("")}
            userEmail={userData?.email ?? ""}
          />
          <SettingsChangeDiscordIdModal
            isOpen={openModal === "discordId"}
            onClose={() => setOpenModal("")}
          />
          <SettingsDeleteAccountModal
            isOpen={openModal === "delete"}
            onClose={() => setOpenModal("")}
          />{" "}
        </>
      )}
    </>
  );
};

export default SettingsProfilePage;
