import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import { toPercent } from "@helpers";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";

export const GainLossPercentsTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "value" && prop !== "reversed",
})<{
  value?: number;
  reversed?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.75, 2)};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  border-radius: 4px;
  color: ${({
    theme: {
      palette: { text },
    },
    reversed,
    value = 0,
  }) => {
    if (value === 0) {
      return text.primary;
    }

    if (reversed) {
      return value > 0 ? text.negative : text.positive;
    }
    return value > 0 ? text.positive : text.negative;
  }};
  background: ${({
    theme: {
      palette: { background },
    },
    reversed,
    value = 0,
  }) => {
    if (value === 0) {
      return background.neutral;
    }
    if (reversed) {
      return value > 0 ? background.error : background.success;
    }
    return value > 0 ? background.success : background.error;
  }};
`;

export interface GainLossPercentsProps {
  value?: number;
  className?: string;
  reversed?: boolean;
  showZero?: boolean;
}

export const GainLossPercents = ({
  value = 0,
  className,
  reversed = false,
  showZero = false,
}: GainLossPercentsProps) => {
  if (!showZero && value === 0) {
    return null;
  }

  return (
    <GainLossPercentsTypography
      value={value}
      className={className}
      reversed={reversed}
    >
      {value > 0 && "+"}
      <Box component="span" marginRight={value === 0 ? 0 : 1}>
        {toPercent(value)}
      </Box>
      {value < 0 && <DownIcon width="10px" height="8px" />}
      {value > 0 && <UpIcon width="10px" height="8px" />}
    </GainLossPercentsTypography>
  );
};
