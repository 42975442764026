import React, { useEffect, useMemo } from "react";
import { getSelectedGroupFromStorage } from "@features/selectDepots/helpers";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { Account } from "@generated/apiv1";
import { AccountGroupName } from "@features/selectDepots/types";

export const useSelectedDepotsFromLocalStorage = (
  id: AccountGroupName,
  accounts: Account[] = [],
  key?: string
) => {
  const [selectedAccounts, setSelectedAccounts] = React.useState<number[]>([]);
  const { settings } = useSharedPortfolioContext();
  const accountIds = useMemo(
    () => accounts.map((account) => account.id),
    [accounts]
  );

  useEffect(() => {
    if (!settings.publicId) {
      return;
    }
    const selectedAccounts = getSelectedGroupFromStorage(id, settings.publicId);
    setSelectedAccounts(
      selectedAccounts?.length ? selectedAccounts : accountIds
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.publicId, accountIds, id, key]);

  return {
    selectedAccounts,
    setSelectedAccounts,
  };
};
