/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Properties to import a new or update an existing bank connection via the TPP web form flow.
 * @export
 * @interface UpdateTppWebFormV2Params
 */
export interface UpdateTppWebFormV2Params {
    /**
     * ID of the bank connection that was created during import
     * @type {number}
     * @memberof UpdateTppWebFormV2Params
     */
    'bankConnectionId'?: number;
    /**
     * The current status of this particular import or update form flow, possible values are<br />            WAITING_FOR_CREDENTIALS: no user input as of yet <br />            WAITING_FOR_MFA: waiting for a second factor or decoupled approach <br />            WAITING_FOR_REDIRECT_RESPONSE: waiting for a redirect back from the bank in case the PSD2 redirect approach is used <br />            IMPORT_SUCCESS: bank connection successfully imported or updated <br />            IMPORT_FAILED: the import failed, check the error message
     * @type {string}
     * @memberof UpdateTppWebFormV2Params
     */
    'bankConnectionInterfaceStatus'?: UpdateTppWebFormV2ParamsBankConnectionInterfaceStatusEnum;
    /**
     * The response code from the banking API (if any)
     * @type {number}
     * @memberof UpdateTppWebFormV2Params
     */
    'bankingApiResponseCode'?: number;
    /**
     * A custom message from the banking API (if any)
     * @type {string}
     * @memberof UpdateTppWebFormV2Params
     */
    'bankingApiResponseMessage'?: string;
}

export const UpdateTppWebFormV2ParamsBankConnectionInterfaceStatusEnum = {
    WAITING_FOR_CREDENTIALS: 'WAITING_FOR_CREDENTIALS',
    WAITING_FOR_MFA: 'WAITING_FOR_MFA',
    WAITING_FOR_REDIRECT_RESPONSE: 'WAITING_FOR_REDIRECT_RESPONSE',
    IMPORT_SUCCESS: 'IMPORT_SUCCESS',
    IMPORT_FAILED: 'IMPORT_FAILED'
} as const;

export type UpdateTppWebFormV2ParamsBankConnectionInterfaceStatusEnum = typeof UpdateTppWebFormV2ParamsBankConnectionInterfaceStatusEnum[keyof typeof UpdateTppWebFormV2ParamsBankConnectionInterfaceStatusEnum];


