import { UseQueryOptions } from "react-query";
import {
  SubcategoriesApiGetSubcategoriesRequest,
  SubcategoryList,
} from "@generated/apiv3";

import { buildSubcategoriesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSubcategories = async (
  token: string | undefined,
  params?: SubcategoriesApiGetSubcategoriesRequest
) => {
  const apiInstance = buildSubcategoriesApi(token);
  const { data } = await apiInstance.getSubcategories(params);
  return data;
};

export const useGetSubcategories = (
  params?: SubcategoriesApiGetSubcategoriesRequest,
  options?: UseQueryOptions<SubcategoryList, AxiosApiError, SubcategoryList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBCATEGORIES_GET_SUBCATEGORIES, params],
    ({ token }) => getSubcategories(token, params),
    options
  );
};
