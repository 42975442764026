import {
  CreateOrUpdateBookingParams,
  CreateOrUpdateInvestmentParams,
  CreateOrUpdateInvestmentParamsList,
} from "@api";
import { convertDateToOnlyDateString } from "@helpers";
import { BookingValues } from "../types/bookingValues";
import { InvestmentValues } from "../types/investmentValues";
import { SplitValues } from "../types/splitValues";

export const buildBookingParams = (
  bookingValues: BookingValues
): CreateOrUpdateBookingParams => {
  return {
    id: bookingValues.id,
    finApiId: bookingValues.finApiId,
    numberOfLots: bookingValues.numberOfLots,
    securityPrice: getSecurityPrice(bookingValues),
    entryQuote: bookingValues.entryQuote,
    date: convertDateToOnlyDateString(bookingValues.date),
    exchangeRate: bookingValues.exchangeRate,
    type: bookingValues.type,
    commission: bookingValues.commission,
    taxAmount: bookingValues.taxAmount,
    expensesInEur: bookingValues.taxAndCommissionInEur,
    comment: bookingValues.comment,
    commentVisibility: "nobody",
    notifyFriends: false,
  };
};

const getSecurityPrice = (bookingValues: BookingValues) => {
  if (bookingValues.type === "retained_dividend") return undefined;
  return bookingValues.type === "book_in"
    ? bookingValues.securityPrice
    : bookingValues.entryQuote;
};

export const buildInvestmentParams = (
  investmentValues: InvestmentValues,
  createOrUpdateBookingParams: CreateOrUpdateBookingParams[]
): CreateOrUpdateInvestmentParams => {
  if (investmentValues.id) {
    return {
      id: investmentValues.id,
      createOrUpdateBookingParamsList: createOrUpdateBookingParams,
    };
  }

  return {
    id: investmentValues.id,
    accountId: investmentValues.depotId,
    type: investmentValues.type,
    tickerSymbol: investmentValues.tickerSymbol,
    currency: investmentValues.investmentCurrency,
    quoteProvider: investmentValues.quoteProvider,
    quoteCurrency: investmentValues.quoteCurrency,
    name: investmentValues.name,
    isin: investmentValues.isin,
    wkn: investmentValues.wkn,
    createOrUpdateBookingParamsList: createOrUpdateBookingParams,
  };
};

export const buildInvestmentParamsList = (
  createOrUpdateInvestmentParams: CreateOrUpdateInvestmentParams[]
): CreateOrUpdateInvestmentParamsList => {
  return {
    createOrUpdateInvestmentParamsList: createOrUpdateInvestmentParams,
  };
};

export const buildCarveOutParams = (
  sourceInvestmentId: number,
  createOrUpdateInvestmentParams: CreateOrUpdateInvestmentParams
) => {
  return {
    carvingOutInvestmentId: sourceInvestmentId,
    createOrUpdateInvestmentParams,
  };
};

export const buildSwitchParams = (
  sourceInvestmentId: number,
  createOrUpdateInvestmentParams: CreateOrUpdateInvestmentParams
) => {
  return {
    switchedInvestmentId: sourceInvestmentId,
    createOrUpdateInvestmentParams,
  };
};

export const buildManagedDepotParams = (
  amount: number,
  depotValuationAfterBooking: number,
  createOrUpdateInvestmentParams: CreateOrUpdateInvestmentParams
) => {
  return {
    amount,
    depotValuationAfterBooking,
    createOrUpdateInvestmentParams,
  };
};

export const buildSplitParams = (
  investmentId: number,
  splitValues: SplitValues
) => {
  if (
    !splitValues.date ||
    !splitValues.ratio ||
    splitValues.ratio < 1 ||
    splitValues.isReverse == null
  )
    throw new Error("Please provide date, ratio and isReverse");

  return {
    investmentId: investmentId,
    date: convertDateToOnlyDateString(splitValues.date)!,
    ratio: splitValues.ratio,
    isReverse: splitValues.isReverse,
  };
};
