import { UseQueryOptions } from "react-query";
import {
  TimeDependentPropertiesApiTimeDependentPropertiesIdRequest,
  TimeDependentProperty,
} from "@generated/apiv1";

import { buildTimeDependentPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const timeDependentPropertiesId = async (
  token: string | undefined,
  params: TimeDependentPropertiesApiTimeDependentPropertiesIdRequest
) => {
  const apiInstance = buildTimeDependentPropertiesApi(token);
  const { data } = await apiInstance.timeDependentPropertiesId(params);
  return data;
};

export const useTimeDependentPropertiesId = (
  params: TimeDependentPropertiesApiTimeDependentPropertiesIdRequest,
  options?: UseQueryOptions<
    TimeDependentProperty,
    AxiosApiError,
    TimeDependentProperty
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.TIMEDEPENDENTPROPERTIES_TIME_DEPENDENT_PROPERTIES_ID, params],
    ({ token }) => timeDependentPropertiesId(token, params),
    options
  );
};
