import { UseQueryOptions } from "react-query";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

type GeneratedFunction<P, O, R> = (params: P, options?: O) => R;

export const usePublicOrPrivateData = <
  TParams,
  TData,
  TOptions extends UseQueryOptions<TData>,
  TFunc extends GeneratedFunction<TParams, TOptions, ReturnType<TFunc>>,
  TFunc2 extends GeneratedFunction<
    TParams & { publicId: string },
    TOptions,
    ReturnType<TFunc2>
  >
>({
  usePrivateMethod,
  usePublicMethod,
  params,
  options = {} as TOptions,
}: {
  usePrivateMethod: TFunc;
  usePublicMethod: TFunc2;
  params: TParams;
  options?: TOptions;
}): ReturnType<TFunc | TFunc2> => {
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const isEnabled = options?.enabled === undefined ? true : options?.enabled;

  const privateResult = usePrivateMethod(params, {
    ...options,
    enabled: !isSharedPortfolio && isEnabled,
  });
  const publicResult = usePublicMethod(
    {
      publicId: publicId!,
      ...params,
    },
    {
      ...options,
      enabled: isSharedPortfolio && Boolean(publicId) && isEnabled,
    }
  );

  return isSharedPortfolio ? publicResult : privateResult;
};
