import { UseMutationOptions } from "react-query";
import {
  BookingsApiValidateSwitchInvestmentRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const validateSwitchInvestment = async (
  token: string | undefined,
  params: BookingsApiValidateSwitchInvestmentRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.validateSwitchInvestment(params);
  return data;
};

export const useValidateSwitchInvestment = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiValidateSwitchInvestmentRequest
  >
) => {
  return useAuthMutation(validateSwitchInvestment, options, false);
};
