import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { merge } from "lodash";
import en from "@translations/en";
import de from "@translations/de";
import componentResources from "@translations/resources";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

const allResources = merge(componentResources, resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: allResources,
    fallbackLng: "en",
    lng: process.env.REACT_APP_LANGUAGE || "de",
    interpolation: {
      escapeValue: false, // react already safes from xss
      defaultVariables: {
        appName: "Finanzfluss",
        supportEmail: "copilot@finanzfluss.de",
      },
    },
  });

export default i18n;
