/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateInterfaceSelectionOptionParams
 */
export interface UpdateInterfaceSelectionOptionParams {
    /**
     * The key of the import option, for internationalization.
     * @type {string}
     * @memberof UpdateInterfaceSelectionOptionParams
     */
    'optionKey'?: UpdateInterfaceSelectionOptionParamsOptionKeyEnum;
    /**
     * The interfaces this import option applies to. If the option is selected,         these interfaces should be imported.
     * @type {Array<string>}
     * @memberof UpdateInterfaceSelectionOptionParams
     */
    'interfaces'?: Array<string>;
}

export const UpdateInterfaceSelectionOptionParamsOptionKeyEnum = {
    CHECKING_ACCOUNTS: 'checking.accounts',
    DEPOTS_AND_REFERENCE_ACCOUNTS: 'depots.and.reference.accounts',
    DEPOTS_AND_OTHER_ACCOUNTS: 'depots.and.other.accounts',
    OTHER_ACCOUNTS: 'other.accounts',
    CREDIT_CARDS: 'credit.cards'
} as const;

export type UpdateInterfaceSelectionOptionParamsOptionKeyEnum = typeof UpdateInterfaceSelectionOptionParamsOptionKeyEnum[keyof typeof UpdateInterfaceSelectionOptionParamsOptionKeyEnum];


