import { UseMutationOptions } from "react-query";
import {
  BookingsApiCarveOutRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const carveOut = async (
  token: string | undefined,
  params: BookingsApiCarveOutRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.carveOut(params);
  return data;
};

export const useCarveOut = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiCarveOutRequest
  >
) => {
  return useAuthMutation(carveOut, options, false);
};
