import { UseQueryOptions } from "react-query";
import { SubscriptionFormsApiGenerateEmptySubscriptionFormRequest } from "@generated/apiv1";

import { buildSubscriptionFormsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const generateEmptySubscriptionForm = async (
  token: string | undefined,
  params: SubscriptionFormsApiGenerateEmptySubscriptionFormRequest
) => {
  const apiInstance = buildSubscriptionFormsApi(token);
  const { data } = await apiInstance.generateEmptySubscriptionForm(params);
  return data;
};

export const useGenerateEmptySubscriptionForm = (
  params: SubscriptionFormsApiGenerateEmptySubscriptionFormRequest,
  options?: UseQueryOptions<object, AxiosApiError, object>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONFORMS_GENERATE_EMPTY_SUBSCRIPTION_FORM, params],
    ({ token }) => generateEmptySubscriptionForm(token, params),
    options
  );
};
