/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Address } from './address';
// May contain unused imports in some cases
// @ts-ignore
import { Link } from './link';

/**
 * A users profile
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Profile
     */
    'id'?: number;
    /**
     * ID of the underlying technical user
     * @type {number}
     * @memberof Profile
     */
    'userId'?: number;
    /**
     * The users first name
     * @type {string}
     * @memberof Profile
     */
    'firstName'?: string;
    /**
     * The users last name
     * @type {string}
     * @memberof Profile
     */
    'lastName'?: string;
    /**
     * The users academic title (Dr., Prof., ...)
     * @type {string}
     * @memberof Profile
     */
    'academicTitle'?: string;
    /**
     * The users phone number
     * @type {string}
     * @memberof Profile
     */
    'phoneNumber'?: string;
    /**
     * The users fax number
     * @type {string}
     * @memberof Profile
     */
    'fax'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    'address'?: Address;
    /**
     * 
     * @type {Link}
     * @memberof Profile
     */
    'profileImage'?: Link;
    /**
     * The users gender
     * @type {number}
     * @memberof Profile
     */
    'gender'?: number;
    /**
     * An investment motto, this is a short free text description on how the user likes to invest
     * @type {string}
     * @memberof Profile
     */
    'motto'?: string;
    /**
     * A free text self description of the user
     * @type {string}
     * @memberof Profile
     */
    'profileDescription'?: string;
    /**
     * A free text description of the users investment strategy
     * @type {string}
     * @memberof Profile
     */
    'investmentStrategy'?: string;
    /**
     * The self-chosen risk class
     * @type {number}
     * @memberof Profile
     */
    'riskAppetite'?: number;
    /**
     * May the user be contacted by others?
     * @type {boolean}
     * @memberof Profile
     */
    'mayContact'?: boolean;
    /**
     * Does the user want to discuss about investments?
     * @type {boolean}
     * @memberof Profile
     */
    'wantDiscuss'?: boolean;
    /**
     * A free text description about what the user likes to discuss about
     * @type {string}
     * @memberof Profile
     */
    'wantDiscussDescription'?: string;
    /**
     * Does the user like to help stock market noobies?
     * @type {boolean}
     * @memberof Profile
     */
    'wantToHelp'?: boolean;
    /**
     * A free text description on what the user can help with
     * @type {string}
     * @memberof Profile
     */
    'wantToHelpDescription'?: string;
    /**
     * The visibility of the users investments
     * @type {string}
     * @memberof Profile
     */
    'investmentVisibility'?: ProfileInvestmentVisibilityEnum;
    /**
     * DEPRECATED: is the users profile marked as publicly visible
     * @type {boolean}
     * @memberof Profile
     */
    'isPublic'?: boolean;
    /**
     * Does the user receive email notifications?
     * @type {boolean}
     * @memberof Profile
     */
    'emailNotification'?: boolean;
    /**
     * Does the user receive notifications on new comments?
     * @type {boolean}
     * @memberof Profile
     */
    'commentNotification'?: boolean;
    /**
     * A flag allowing to enable or disable certain tracking settings (must be implemented in the frontend)
     * @type {boolean}
     * @memberof Profile
     */
    'isTrackingEnabled'?: boolean;
    /**
     * The frequency of the users investment report (1 = weekly, 2 = monthly, 9 = off)
     * @type {number}
     * @memberof Profile
     */
    'investmentReportFrequency'?: number;
}

export const ProfileInvestmentVisibilityEnum = {
    ANYBODY: 'anybody',
    FRIENDS: 'friends',
    NOBODY: 'nobody'
} as const;

export type ProfileInvestmentVisibilityEnum = typeof ProfileInvestmentVisibilityEnum[keyof typeof ProfileInvestmentVisibilityEnum];


