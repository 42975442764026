import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";

export interface Props {
  investmentName: string;
  redirectUrl: string;
}

export const PersistedStep = ({ investmentName, redirectUrl }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  enqueueSnackbar(
    `${t("transactionWizard.persistedStep.title")}: ${investmentName}`,
    { autoHideDuration: 3000, variant: "success" }
  );

  return <Navigate replace to={redirectUrl} />;
};
