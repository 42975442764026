import { UseMutationOptions } from "react-query";
import {
  AccountHoldersApiUpdateReferenceAccountRequest,
  AccountHolder,
} from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateReferenceAccount = async (
  token: string | undefined,
  params: AccountHoldersApiUpdateReferenceAccountRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.updateReferenceAccount(params);
  return data;
};

export const useUpdateReferenceAccount = (
  options?: UseMutationOptions<
    AccountHolder,
    AxiosApiError,
    AccountHoldersApiUpdateReferenceAccountRequest
  >
) => {
  return useAuthMutation(updateReferenceAccount, options, false);
};
