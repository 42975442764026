import { UseQueryOptions } from "react-query";
import { TagsApiListAndSearchAllTagsRequest, TagList } from "@generated/apiv1";

import { buildTagsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllTags = async (
  token: string | undefined,
  params?: TagsApiListAndSearchAllTagsRequest
) => {
  const apiInstance = buildTagsApi(token);
  const { data } = await apiInstance.listAndSearchAllTags(params);
  return data;
};

export const useListAndSearchAllTags = (
  params?: TagsApiListAndSearchAllTagsRequest,
  options?: UseQueryOptions<TagList, AxiosApiError, TagList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TAGS_LIST_AND_SEARCH_ALL_TAGS, params],
    ({ token }) => listAndSearchAllTags(token, params),
    options
  );
};
