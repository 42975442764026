export const freeBenefits = [
  "settingsPage.subscription.freeOption.autoImport",
  "settingsPage.subscription.freeOption.unlimitedPortfolio",
  "settingsPage.subscription.freeOption.digitalBook",
  "settingsPage.subscription.freeOption.dividendCalendar",
  "settingsPage.subscription.freeOption.investmentAnalyse",
  "settingsPage.subscription.freeOption.watchlist",
];

export const plusBenefitsShort = [
  "settingsPage.subscription.plusOption.advancedInvestmentAnalyse",
  "settingsPage.subscription.plusOption.watchlist",
  "settingsPage.subscription.plusOption.performanceComparison",
  "settingsPage.subscription.plusOption.advancedMetrics",
  "settingsPage.subscription.plusOption.csvExport",
  "settingsPage.subscription.plusOption.plusBadge",
];

export const plusBenefits = [...freeBenefits, ...plusBenefitsShort];
