import { InvokeCallback, Sender } from "xstate";
import { CreateOrUpdateBookingParams } from "@api";
import { updateBookingWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const updateDividend =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    // if (event.type !== "PERSIST") {
    //   throw new Error(`Invalid event type ${event.type}`);
    // }

    if (!context.bookingId) {
      throw new Error(`Invalid booking id: ${context.bookingId}`);
    }

    // TODO: need refactor this types checking
    if (
      !context.validatedMultipleBookingParams
        ?.createOrUpdateInvestmentParamsList![0]
        .createOrUpdateBookingParamsList![0]
    ) {
      throw new Error("Invalid booking param list");
    }

    const [params] =
      context.validatedMultipleBookingParams
        .createOrUpdateInvestmentParamsList[0].createOrUpdateBookingParamsList;

    update(context.bookingId.toString(), params, callback);
  };

const update = async (
  bookingId: string,
  params: CreateOrUpdateBookingParams,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    await updateBookingWithToken(bookingId, params);

    callback({ type: "SUCCESS" });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
