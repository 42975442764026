import { InvokeCallback, Sender } from "xstate";
import { CreateMultipleBookingParams } from "@api";
import { createOrUpdateBookingsWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const saveDividends =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "PERSIST") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    if (!context.validatedMultipleBookingParams) {
      throw new Error(`Invalid multiply booking params`);
    }

    save(context.validatedMultipleBookingParams, callback);
  };

const save = async (
  createMultipleBookingParams: CreateMultipleBookingParams,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    await createOrUpdateBookingsWithToken(createMultipleBookingParams);

    callback({ type: "SUCCESS" });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
