import { axiosInstanceV1 } from "@api/axiosInstance";
import { PageableIndustryList } from "../model";

interface Params {
  perPage?: number;
  germanName?: string;
}

export const getIndustries = async (params?: Params) => {
  const { data } = await axiosInstanceV1.get<PageableIndustryList>(
    `/industries`,
    {
      params,
    }
  );
  return data;
};
