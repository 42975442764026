import React, { ReactNode, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Alert,
  Box,
  useTheme,
} from "@mui/material";
import { isValid } from "date-fns";
import { TransactionValidationError } from "@api";
import { toString, parseOrUndefined, toCurrency } from "@helpers";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { Banner } from "@components";
import { ReactComponent as InfoIcon } from "@icons/warning.svg";
import { InputFieldWithRevert } from "../../transactionWizard/components/NumberInputFieldWithRevert";
import { DividendValues } from "../stateMachine/types/dividendValues";
import { useAmounts, useProposeExchangeRate } from "../hooks";
import { StepContainer } from "../../transactionWizard/StepContainer";
import { valuesValidator } from "../utils/utils";
import { DatePickerWithQuoteLookup } from "../../transactionWizard/components/DatePickerWithQuoteLookup";

const InputFieldWithRevertStyled = styled(InputFieldWithRevert)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const AmountsTextContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
const AmountWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
const AmountHeader = styled(Typography)`
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

const DoubleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DividerStyled = styled(Divider)`
  margin: 0 ${({ theme }) => theme.spacing(2)};
`;

const ValidationErrorsContainer = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export interface Props {
  onNext: (dividendValues: DividendValues) => void;
  rollbackValues?: DividendValues;
  dividendValues: DividendValues;
  investmentCurrency: string;
  investmentName: string;
  validationErrors: TransactionValidationError[];
  chart?: ReactNode;
  disableOpenPicker?: boolean;
  datePickerDesktopVariant?: boolean;
  isLoading?: boolean;
}

export const AddManualDividendStep = ({
  onNext,
  rollbackValues,
  dividendValues,
  investmentCurrency,
  investmentName,
  validationErrors,
  chart,
  disableOpenPicker,
  datePickerDesktopVariant,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getAmount, getAmountAC } = useAmounts();
  const investmentCurrencySymbol = getCurrencySymbol(investmentCurrency);
  const euroCurrencySymbol = getCurrencySymbol("EUR");
  const [date, setDate] = useState<Date>(dividendValues.date ?? new Date());
  const [securityPrice, setSecurityPrice] = useState<string>(
    toString(dividendValues.securityPrice)
  );
  const [taxAmount, setTaxAmount] = useState<string>(
    toString(dividendValues.taxAmount)
  );
  const [commission, setCommission] = useState<string>(
    toString(dividendValues.commission)
  );
  const [exchangeRate, setExchangeRate] = useState<string>(
    toString(dividendValues.exchangeRate)
  );
  const [taxAndCommissionInEur, setTaxAndCommissionInEur] = useState<boolean>(
    dividendValues.taxAndCommissionInEur
  );
  const [values, setValues] = useState<DividendValues>({ ...dividendValues });
  const [exchangeRateTakeoverValue, setExchangeRateTakeoverValue] = useState<
    number | undefined
  >();

  useEffect(() => {
    updateAmounts(dividendValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividendValues]);

  const updateAmounts = (partial: Partial<DividendValues>) => {
    setValues((current: DividendValues) => {
      const newValues = { ...current, ...partial };

      const amount = getAmount({
        securityPrice: newValues.securityPrice ?? 0,
        taxAmount: newValues.taxAmount ?? 0,
        commission: newValues.commission ?? 0,
        taxAndCommissionInEur: newValues.taxAndCommissionInEur,
        exchangeRate: newValues.exchangeRate ?? 1,
      });

      const amountAC = getAmountAC({
        securityPrice: newValues.securityPrice ?? 0,
        taxAmount: newValues.taxAmount ?? 0,
        commission: newValues.commission ?? 0,
        taxAndCommissionInEur: newValues.taxAndCommissionInEur,
        exchangeRate: newValues.exchangeRate ?? 1,
      });

      newValues.amount = amount;
      newValues.amountAC = amountAC;

      return newValues;
    });
  };

  const handleSecurityPriceChange = (value: string) => {
    const parsedSecurityPrice = parseOrUndefined(value);
    setSecurityPrice(toString(parsedSecurityPrice, value, 6));

    updateAmounts({ securityPrice: parsedSecurityPrice });
  };

  const handleTaxAmountChange = (value: string) => {
    const parsedTaxAmount = parseOrUndefined(value);
    setTaxAmount(toString(parsedTaxAmount, value, 6));

    updateAmounts({
      taxAmount: parsedTaxAmount,
    });
  };

  const handleCommissionChange = (value: string) => {
    const parsedCommission = parseOrUndefined(value);
    setCommission(toString(parsedCommission, value, 6));

    updateAmounts({
      commission: parsedCommission,
    });
  };

  const handleExchangeRateChange = (value: string) => {
    const parsedExchangeRate = parseOrUndefined(value);
    setExchangeRate(toString(parsedExchangeRate, value, 6));

    updateAmounts({
      exchangeRate: parsedExchangeRate,
    });
  };

  const handleTaxAndCommissionInEur = () => {
    setTaxAndCommissionInEur((current) => {
      updateAmounts({ taxAndCommissionInEur: !current });
      return !current;
    });
  };

  const handleNextStep = () => {
    if (onNextDisabled) return null;
    onNext(values);
  };

  const handleExchangeRateTakeover = (value: string) => {
    setExchangeRateTakeoverValue(undefined);
    handleExchangeRateChange(value);
  };

  const handleDateChange = (date: Date) => {
    setDate(date);
    setValues({ ...values, date });
    proposeExchangeRateChange(date);
  };

  const isFx = investmentCurrency !== "EUR";
  const isAmountInvalid = (values.amount ?? 0) <= 0;
  const onNextDisabled = !valuesValidator({ ...values }) || isAmountInvalid;

  const { proposeExchangeRateChange } = useProposeExchangeRate(
    investmentCurrency,
    setExchangeRateTakeoverValue
  );

  return (
    <StepContainer
      testid="add-manual-dividend-step"
      title={t("addDividendWizard.addManualDividendStep.title")}
      onNext={handleNextStep}
      onNextDisabled={onNextDisabled}
      chart={chart}
      isLoading={isLoading}
    >
      <Box width="100%" maxWidth="700px">
        {Boolean(validationErrors?.length) &&
          validationErrors.map(({ errorMessage }) => (
            <ValidationErrorsContainer
              severity="error"
              data-testid="dividend-validation-error"
              key={errorMessage}
            >
              {errorMessage}
            </ValidationErrorsContainer>
          ))}
        <FormGroup>
          <Box sx={{ marginBottom: "24px" }}>
            <DatePickerWithQuoteLookup
              date={date}
              handleChange={handleDateChange}
              label={t("addDividendWizard.addManualDividendStep.date")}
              disableOpenPicker={disableOpenPicker}
              desktopVariant={datePickerDesktopVariant}
            />
          </Box>
          <InputFieldWithRevertStyled
            fieldLabel={t(
              "addDividendWizard.addManualDividendStep.securityPrice"
            )}
            id="security-price"
            value={securityPrice}
            rollbackValue={rollbackValues?.securityPrice}
            endAdornment={investmentCurrencySymbol}
            handleChange={handleSecurityPriceChange}
          />
          {isFx && (
            <InputFieldWithRevertStyled
              fieldLabel={t(
                "addDividendWizard.addManualDividendStep.exchangeRate"
              )}
              id="exchange-rate"
              value={exchangeRate}
              rollbackValue={rollbackValues?.exchangeRate}
              takeoverDate={isValid(values.date) ? values.date : undefined}
              takeoverValue={exchangeRateTakeoverValue}
              handleTakeover={handleExchangeRateTakeover}
              handleChange={handleExchangeRateChange}
            />
          )}
          <DoubleInputContainer>
            <InputFieldWithRevertStyled
              fieldLabel={t(
                "addDividendWizard.addManualDividendStep.taxAmount"
              )}
              id="tax-amount"
              value={taxAmount}
              handleChange={handleTaxAmountChange}
              rollbackValue={rollbackValues?.taxAmount}
              endAdornment={
                taxAndCommissionInEur
                  ? euroCurrencySymbol
                  : investmentCurrencySymbol
              }
            />
            <DividerStyled orientation="vertical" />
            <InputFieldWithRevertStyled
              fieldLabel={t(
                "addDividendWizard.addManualDividendStep.commission"
              )}
              id="commission"
              value={commission}
              handleChange={handleCommissionChange}
              rollbackValue={rollbackValues?.commission}
              endAdornment={
                taxAndCommissionInEur
                  ? euroCurrencySymbol
                  : investmentCurrencySymbol
              }
            />
          </DoubleInputContainer>
          {isAmountInvalid && (
            <Banner
              type="error"
              icon={<InfoIcon color={theme.palette.error.dark} />}
              text={t(
                "addDividendWizard.addManualDividendStep.amountInvalidError"
              )}
              mb={4}
            />
          )}
          {isFx && (
            <>
              <Divider sx={{ marginBottom: "20px" }} />
              <FormControlLabel
                control={
                  <Switch
                    checked={taxAndCommissionInEur}
                    onChange={handleTaxAndCommissionInEur}
                    color="primary"
                  />
                }
                sx={{ marginLeft: 0 }}
                label={t(
                  "addDividendWizard.addManualDividendStep.taxAndCommissionInEur"
                )}
                data-testid="tax-and-commission-in-eur-switch"
              />
            </>
          )}
          <AmountsTextContainer>
            <AmountWrapper>
              <AmountHeader variant="subtitle1" fontWeight="600">
                {t("addDividendWizard.addManualDividendStep.amount")}:{" "}
              </AmountHeader>
              <Typography
                variant="subtitle1"
                data-testid="amount-text"
                fontWeight="600"
              >
                {toCurrency(values.amount, investmentCurrency, {
                  maximumFractionDigits: 6,
                }) || 0}
              </Typography>
            </AmountWrapper>
            {isFx && (
              <AmountWrapper>
                <AmountHeader variant="subtitle1" fontWeight="600">
                  {t("addDividendWizard.addManualDividendStep.amountAC")}:{" "}
                </AmountHeader>
                <Typography
                  variant="subtitle1"
                  data-testid="amount-ac-text"
                  fontWeight="600"
                >
                  {toCurrency(values.amountAC, "EUR", {
                    maximumFractionDigits: 6,
                  })}
                </Typography>
              </AmountWrapper>
            )}
          </AmountsTextContainer>
        </FormGroup>
      </Box>
    </StepContainer>
  );
};
