import { useTheme } from "@emotion/react";
import React, { ReactNode, useRef, useState } from "react";
import { Line, Tooltip } from "recharts";
import { Button, Theme, Typography, useMediaQuery } from "@mui/material";
import GestureIcon from "@mui/icons-material/Gesture";
import styled from "@emotion/styled";
import { useChartColors, useRangeSelectControl, useTickInterval } from "@hooks";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { InvestmentChartData } from "@components/Chart/InvestmentChart/types";
import {
  Chart,
  ChartLayout,
  ComparisonValue,
  DateReferenceArea,
  DrawableLines,
  Figure,
  GridAndAxis,
  MainLine,
  useDateReferenceSelect,
  useDrawLines,
  useTickFormat,
} from "../BaseChartTools";
import { SecondaryLine } from "../BaseChartTools/Elements/SecondaryLine";
import { calculatePercents } from "./calculatePercents";
import { EventDot } from "./EventDot";
import { CustomTooltip } from "./CustomTooltip";

const ChartActionsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const DrawLinesButton = styled(Button)`
  padding: 3px 5px;
  width: max-content;
  height: max-content;
  border-radius: 8px;
`;

export interface InvestmentChartProps {
  title?: string | ReactNode;
  subtitle?: ReactNode;
  data: InvestmentChartData[];
  availableDateRanges?: IntervalType[];
  availableCompactDateRanges?: IntervalType[];
  className?: string;
  onSelect?: (type: IntervalType, value?: RangeType) => void;
  isLoading?: boolean;
  performanceOverview?: ReactNode;
  referenceEnabled?: boolean;
  showEvents?: boolean;
  drawLines?: boolean;
  comparedTicker?: string | null;
  comparisonValues?: ComparisonValue[];
  onComparedTickerChange?: (ticker: string | null) => void;
  customRange?: RangeType;
  mainValueLabel?: string;
}

export const InvestmentChart = ({
  data,
  className,
  onSelect,
  isLoading,
  title,
  subtitle,
  performanceOverview,
  referenceEnabled = false,
  showEvents = false,
  drawLines = false,
  comparedTicker,
  comparisonValues = [],
  availableDateRanges,
  availableCompactDateRanges,
  onComparedTickerChange,
  customRange,
  mainValueLabel,
}: InvestmentChartProps) => {
  const ref = useRef();
  const theme = useTheme();
  const chartColors = useChartColors("securitiesChart");
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { range, intervalType, customIntervalSelect, selectInterval } =
    useRangeSelectControl({
      onSelect,
      firstDate: data[0] ? data[0].date : new Date(),
    });

  const tickFormat = useTickFormat(
    customRange?.startDate || range.startDate,
    customRange?.endDate || range.endDate
  );

  const tickInterval = useTickInterval(
    // @ts-ignore
    ref.current?.container,
    data?.length,
    tickFormat === "dd.MM''yy" ? 60 : 40
  );

  const [lineDrawingEnabled, setLineDrawingEnabled] = useState(false);
  const [isChartHovering, setIsChartHovering] = useState(false);

  const { mouseDown, mouseMove, mouseUp, lines, isDrawing, lineRef, clear } =
    useDrawLines(drawLines);

  const referenceSelect = useDateReferenceSelect({
    onCustomIntervalSelect: customIntervalSelect,
  });

  const handleDrawLinesClick = () => {
    setLineDrawingEnabled((current) => {
      clear();
      return !current;
    });
  };

  const handleMouseDown = (e: any) => {
    mouseDown(e);
    if (lineDrawingEnabled || !referenceEnabled) return;
    referenceSelect.mouseDown(e);
  };

  const handleMouseUp = (e: any) => {
    mouseUp(e);
    if (lineDrawingEnabled || !referenceEnabled) return;
    referenceSelect.mouseUp();
  };

  const handleMouseMove = (e: any, e2: any) => {
    // sometimes preventDefault is not defined
    e2?.preventDefault?.();
    mouseMove(e);
    if (lineDrawingEnabled || !referenceEnabled) return;
    referenceSelect.mouseMove(e);
  };

  const isComparison = Boolean(comparedTicker);

  return (
    <ChartLayout
      isLoading={isLoading}
      className={className}
      intervalSelector={
        !customRange ? (
          <RangeSelector
            compact={isXs}
            range={range}
            intervalType={intervalType}
            intervalTypes={availableDateRanges}
            compactIntervalTypes={availableCompactDateRanges}
            onSelect={selectInterval}
            onCustomSelect={customIntervalSelect}
          />
        ) : null
      }
      figure={
        title || subtitle ? (
          <Figure>
            {<Typography variant={isXs ? "h4" : "h2"}>{title}</Typography>}
            {subtitle}
          </Figure>
        ) : null
      }
      legend={
        <>
          {isXs && !customRange && (
            <RangeSelector
              compact={isXs}
              range={range}
              intervalType={intervalType}
              intervalTypes={availableDateRanges}
              compactIntervalTypes={availableCompactDateRanges}
              onSelect={selectInterval}
              onCustomSelect={customIntervalSelect}
            />
          )}
        </>
      }
      bottomComponent={performanceOverview}
      onChartMouseEnter={() => {
        setIsChartHovering(true);
      }}
      onChartMouseLeave={() => {
        setIsChartHovering(false);
      }}
    >
      <Chart
        data={isComparison ? calculatePercents(data) : data}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        hideYAxis={isXs}
        ref={ref}
      >
        {GridAndAxis({
          theme,
          tickInterval,
          xDataKey: "date",
          tickFormat,
          hideYAxis: isXs,
          valueTickFormatter: isComparison ? (value) => `${value}%` : undefined,
        })}

        {!isDrawing && (
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                isComparison={isComparison}
                comparedValueLabel={
                  comparisonValues.find(
                    (value) => value.value === comparedTicker
                  )?.label
                }
                mainValueLabel={mainValueLabel}
              />
            }
          />
        )}

        {showEvents && (
          <Line
            dataKey="eventsValue"
            fill="transparent"
            stroke="transparent"
            dot={
              <EventDot
                stroke={theme.palette.grey[600]}
                fill={theme.palette.grey[600]}
              />
            }
            animationDuration={0}
            isAnimationActive={false}
            yAxisId="1"
          />
        )}

        {MainLine({ dataKey: "value", dataAreaKey: "areaValue", chartColors })}
        {SecondaryLine({
          dataKey: "comparedValue",
          showDot: isComparison,
          chartColors,
        })}
        {DrawableLines({
          lineRef,
          lineDrawingEnabled,
          lines: lines ?? [],
          theme,
          chartColors,
        })}

        {DateReferenceArea({
          refAreaLeft: referenceSelect.refAreaLeft,
          refAreaRight: referenceSelect.refAreaRight,
          theme,
        })}
      </Chart>
      {(isChartHovering || lineDrawingEnabled) && drawLines && (
        <ChartActionsContainer>
          <DrawLinesButton
            variant="contained"
            startIcon={<GestureIcon />}
            size="small"
            onClick={handleDrawLinesClick}
          >
            {lineDrawingEnabled ? "Clear" : "Draw"}
          </DrawLinesButton>
        </ChartActionsContainer>
      )}
    </ChartLayout>
  );
};
