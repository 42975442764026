import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BUY } from "@helpers";
import { ReactComponent as StarIcon } from "@icons/yellow_star.svg";

export const PlusButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<StarIcon width="9px" height="9px" />}
      color="tertiary"
      size="small"
      href={BUY}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        padding: (theme) => theme.spacing(0, 2),
        fontSize: "9px",
        height: "20px",
        "& .MuiButton-startIcon": {
          marginRight: (theme) => theme.spacing(1),
        },
        flexShrink: 0,
      }}
    >
      {t("upgradeNow")}
    </Button>
  );
};
