import { UseQueryOptions } from "react-query";
import {
  TransactionCounterpartPopularityApiGetTransactionCounterpartPopularityRequest,
  TransactionCounterpartValuePopularityList,
} from "@generated/apiv1";

import { buildTransactionCounterpartPopularityApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getTransactionCounterpartPopularity = async (
  token: string | undefined,
  params: TransactionCounterpartPopularityApiGetTransactionCounterpartPopularityRequest
) => {
  const apiInstance = buildTransactionCounterpartPopularityApi(token);
  const { data } = await apiInstance.getTransactionCounterpartPopularity(
    params
  );
  return data;
};

export const useGetTransactionCounterpartPopularity = (
  params: TransactionCounterpartPopularityApiGetTransactionCounterpartPopularityRequest,
  options?: UseQueryOptions<
    TransactionCounterpartValuePopularityList,
    AxiosApiError,
    TransactionCounterpartValuePopularityList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.TRANSACTIONCOUNTERPARTPOPULARITY_GET_TRANSACTION_COUNTERPART_POPULARITY,
      params,
    ],
    ({ token }) => getTransactionCounterpartPopularity(token, params),
    options
  );
};
