import React from "react";
import { useSearchParams } from "react-router-dom";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { ManualTransactionWizard } from "./ManualTransactionWizard";

export const ManualTransactionWizardWithRouting = () => {
  const [searchParams] = useSearchParams();

  const type = searchParams.get("type");
  const depotId = searchParams.get("depotId");

  return (
    <ManualTransactionWizard
      type={type as InvestmentTypeEnum}
      depotId={depotId ? Number.parseInt(depotId, 10) : undefined}
    />
  );
};
