import { UseQueryOptions } from "react-query";
import { StripeSetup } from "@generated/apiv1";

import { buildStripeApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const stripeSetup = async (token: string | undefined) => {
  const apiInstance = buildStripeApi(token);
  const { data } = await apiInstance.stripeSetup();
  return data;
};

export const useStripeSetup = (
  options?: UseQueryOptions<StripeSetup, AxiosApiError, StripeSetup>
) => {
  return useAuthQuery(
    [CACHE_KEYS.STRIPE_STRIPE_SETUP],
    ({ token }) => stripeSetup(token),
    options
  );
};
