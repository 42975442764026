import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObjectUrl, downloadFile } from "@helpers/files";
import { useDownloadBookings } from "@api/v1/hooks/useBookings";
import { useDownloadInvestments } from "@api/v1/hooks/useInvestments";
import { useMyself } from "@api";
import { BlurForNonPro } from "@components/BlurForNonPro";
import { ReactComponent as LockIcon } from "@icons/lock.svg";

export const ExportButton = ({
  activeTab,
  params,
}: {
  activeTab?: string;
  params: {
    accountId: number[];
    minDate: string;
    maxDate: string;
    includeHistoric: boolean;
  };
}) => {
  const { t } = useTranslation();
  const { data: myself } = useMyself();
  const isUserFree = myself?.isUserFree;
  const { accountId, minDate, maxDate, includeHistoric } = params;

  const { mutate: downloadBookings } = useDownloadBookings(
    { format: "csv", accountId, minDate, maxDate },
    {
      onSuccess: (data) => {
        const url = createObjectUrl(data, "text/csv");
        downloadFile(url, "buchungen.csv");
      },
    }
  );
  const { mutate: downloadInvestments } = useDownloadInvestments(
    {
      format: "csv",
      includeHistoric,
      accountId,
    },
    {
      onSuccess: (data) => {
        const url = createObjectUrl(data, "text/csv");
        downloadFile(url, "investments.csv");
      },
    }
  );

  return (
    <BlurForNonPro>
      <Button
        size="small"
        variant={"contained"}
        startIcon={isUserFree ? <LockIcon /> : undefined}
        sx={{ cursor: isUserFree ? "initial" : "pointer" }}
        onClick={
          isUserFree
            ? undefined
            : activeTab === "bookings"
            ? downloadBookings
            : downloadInvestments
        }
      >
        {t("components.depotsStatistic.tabs.export")}
      </Button>
    </BlurForNonPro>
  );
};
