import { format, isSameMonth, subYears } from "date-fns";
import { useMemo } from "react";
import { useCashflowAnalytics } from "@features/cashflowAnalyzer/hooks/useCashflow";
import {
  ExtendedDateValuePair,
  IncomeAndSpendingHistory,
} from "@generated/apiv1";

const date = new Date();

const findThisMonth = (values?: ExtendedDateValuePair[]) => {
  if (!values) return undefined;
  return values.find((item) => {
    if (!item.date) return false;
    return isSameMonth(new Date(item.date), new Date());
  });
};

const getThisMonthStatistics = (
  incomeExpensesStatistics: IncomeAndSpendingHistory
) => {
  const { averageRegularIncome, averageNonRegularIncome } =
    incomeExpensesStatistics;
  const { averageNonRegularExpenses, averageRegularExpenses } =
    incomeExpensesStatistics;

  const thisMonthRegularIncome = findThisMonth(
    averageRegularIncome?.extendedDateValuePairs
  )?.value;
  const thisMonthNonRegularIncome = findThisMonth(
    averageNonRegularIncome?.extendedDateValuePairs
  )?.value;
  const thisMonthRegularExpenses = findThisMonth(
    averageRegularExpenses?.extendedDateValuePairs
  )?.value;
  const thisMonthNonRegularExpenses = findThisMonth(
    averageNonRegularExpenses?.extendedDateValuePairs
  )?.value;

  return {
    thisMonthIncome:
      (thisMonthRegularIncome || 0) + (thisMonthNonRegularIncome || 0),
    thisMonthExpenses:
      (thisMonthRegularExpenses || 0) + (thisMonthNonRegularExpenses || 0),
  };
};

export const useIncomeExpensesStatistics = (accountIds: number[]) => {
  const { data: incomeExpensesStatistics = {} } = useCashflowAnalytics(
    {
      id: accountIds,
      from: format(subYears(date, 1), "yyyy-MM-dd"),
    },
    {
      enabled: !!accountIds && accountIds.length > 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { thisMonthIncome, thisMonthExpenses } = useMemo(
    () => getThisMonthStatistics(incomeExpensesStatistics),
    [incomeExpensesStatistics]
  );

  const {
    averageRegularIncome,
    averageNonRegularIncome,
    averageRegularExpenses,
    averageNonRegularExpenses,
  } = incomeExpensesStatistics;

  const [regularIncome, nonRegularIncome, regularExpenses, nonRegularExpenses] =
    [
      averageRegularIncome,
      averageNonRegularIncome,
      averageRegularExpenses,
      averageNonRegularExpenses,
    ].map(
      (data) =>
        data?.extendedDateValuePairs?.reduce(
          (acc, item) => acc + (item.value || 0),
          0
        ) || 0
    );
  const totalIncome = regularIncome + nonRegularIncome;
  const totalExpenses = regularExpenses + nonRegularExpenses;
  const meanIncome =
    (averageRegularIncome?.curatedMean || 0) +
    (averageNonRegularIncome?.curatedMean || 0);
  const meanExpenses =
    (averageRegularExpenses?.curatedMean || 0) +
    (averageNonRegularExpenses?.curatedMean || 0);

  return {
    income: {
      total: totalIncome,
      curatedMean: meanIncome,
      thisMonth: thisMonthIncome,
    },
    expenses: {
      total: totalExpenses,
      curatedMean: meanExpenses,
      thisMonth: thisMonthExpenses,
    },
  };
};
