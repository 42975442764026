import React from "react";
import Highcharts from "highcharts";
import { renderToString } from "react-dom/server";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";

export const splitFormatter = (
  formatter: (value?: string | number | null) => React.ReactNode,
  point?: Highcharts.TooltipFormatterContextObject
) => {
  if (!point) return "";

  return renderToString(
    <ChartTooltip>
      <div
        style={{
          display: "flex",
          gap: "6px",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "18px",
        }}
      >
        <div
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: point.color as string,
            alignItems: "center",
          }}
        />
        <div>{point.series.userOptions.name}</div>
        <div style={{ lineHeight: "22px", fontWeight: 600 }}>
          {formatter(point.y)}
        </div>
      </div>
    </ChartTooltip>
  );
};
