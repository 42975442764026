import { UseQueryOptions } from "react-query";
import {
  PlugAndPlayApiGetQuestionsRequest,
  QuestionsResponse,
} from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getQuestions = async (
  token: string | undefined,
  params?: PlugAndPlayApiGetQuestionsRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.getQuestions(params);
  return data;
};

export const useGetQuestions = (
  params?: PlugAndPlayApiGetQuestionsRequest,
  options?: UseQueryOptions<QuestionsResponse, AxiosApiError, QuestionsResponse>
) => {
  return useAuthQuery(
    [CACHE_KEYS.PLUGANDPLAY_GET_QUESTIONS, params],
    ({ token }) => getQuestions(token, params),
    options
  );
};
