import React from "react";
import { InputLabel, Typography, styled } from "@mui/material";

const InputLabelWithMargin = styled(InputLabel)(({ theme }) => ({
  paddingBottom: theme.spacing(9),
  color: theme.palette.text.primary,
  whiteSpace: "normal",
}));

interface Props {
  stepNumber?: number;
  question: string;
  htmlFor?: string;
  subtitle?: string;
}
export const Question = ({ question, subtitle, htmlFor }: Props) => (
  <InputLabelWithMargin htmlFor={htmlFor}>
    <Typography variant="h5" component="h1" mb={subtitle ? 2 : 0}>
      {question}
    </Typography>
    {subtitle && (
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    )}
  </InputLabelWithMargin>
);
