import React from "react";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { DividendPerYear } from "@api";
import { DataBlockLayout } from "@components/common/dataBlock";
import { toCurrency } from "@helpers";
import { LinearProgressChart } from "@components/Chart/LinearProgressChart";

interface Props {
  dividends?: DividendPerYear[];
  isLoading?: boolean;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  width: "100%",
  backgroundColor: theme.palette.tertiary.light,

  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {},
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

export const ChartBar = (props: {
  valueFormatted: string;
  category: string;
  value: number;
}) => {
  return (
    <BorderLinearProgress
      variant="determinate"
      color="primary"
      value={props.value < 100 ? props.value : 100}
    />
  );
};

export const DividendsBaseChart = ({ dividends, isLoading }: Props) => {
  const { t } = useTranslation();

  const title = t("fundamentals.metrics.dividendsPerYear.normal");
  // const titleShort = t("fundamentals.metrics.dividendsPerYear.short");
  const desc = t("fundamentals.metrics.dividendsPerYear.desc");

  const chartData = buildChartData(dividends);
  const formatData = () => {
    const maxValue = Math.max(...chartData.map((obj) => obj.value));

    return chartData.map((obj) => ({
      ...obj,
      percentage: (obj.value / maxValue) * 100,
    }));
  };

  const formattedData = formatData();
  return (
    <DataBlockLayout
      title={title}
      isLoading={isLoading}
      toolTipData={{ desc1: desc }}
    >
      <Box width="100%" height="100%">
        <LinearProgressChart
          chartData={formattedData}
          component={ChartBar}
          type="dividends"
        />
      </Box>
    </DataBlockLayout>
  );
};

const buildChartData = (
  dividends?: {
    year: number;
    totalDividendPerShare: number;
    isEstimate: boolean;
  }[]
) =>
  sortBy(
    dividends?.map((dividend) => {
      const value = Math.round(dividend.totalDividendPerShare * 100) / 100;
      return {
        category: `${dividend.year}${dividend.isEstimate ? "(e)" : ""}`,
        value,
        isEstimate: dividend.isEstimate,
        valueFormatted: toCurrency(value),
      };
    }) || [],
    ["category"]
  ).reverse();
