import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiUpdateBankConnectionV2Request,
  BankSynchronizationProcess,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateBankConnectionV2 = async (
  token: string | undefined,
  params: BankConnectionsApiUpdateBankConnectionV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.updateBankConnectionV2(params);
  return data;
};

export const useUpdateBankConnectionV2 = (
  options?: UseMutationOptions<
    BankSynchronizationProcess,
    AxiosApiError,
    BankConnectionsApiUpdateBankConnectionV2Request
  >
) => {
  return useAuthMutation(updateBankConnectionV2, options, false);
};
