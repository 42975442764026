import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Divider, Typography } from "@mui/material";
import { toCurrency } from "@helpers";
import { DividendValues } from "../stateMachine/types/dividendValues";
import { StepContainer } from "../../transactionWizard/StepContainer";

export interface Props {
  dividendValues: DividendValues;
  investmentCurrency: string;
  investmentName: string;
  onNext: () => void;
  onPrevious: () => void;
}

const Title = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;

export const ConfirmManualDividendStep = ({
  dividendValues,
  investmentCurrency,
  investmentName,
  onNext,
  onPrevious,
}: Props) => {
  const isFx = investmentCurrency !== "EUR";
  const { t } = useTranslation();

  return (
    <StepContainer
      testid="summary-step"
      title={t("addDividendWizard.confirmManualDividendStep.title")}
      onNext={onNext}
      onPrevious={onPrevious}
      nextLabel={t(
        "addDividendWizard.confirmManualDividendStep.saveButtonLabel"
      )}
    >
      <GridContainer>
        <Title variant="body1">
          {t("addDividendWizard.confirmManualDividendStep.date")}
        </Title>

        <Typography variant="body1">
          {format(new Date(dividendValues.date!), "dd.MM.yyyy")}
        </Typography>

        <Title variant="body1">
          {t("addDividendWizard.confirmManualDividendStep.amount")}
        </Title>

        <Typography variant="body1">
          {toCurrency(dividendValues.securityPrice, investmentCurrency, {
            maximumFractionDigits: 6,
          })}
        </Typography>

        <Title variant="body1">
          {t("addDividendWizard.confirmManualDividendStep.tax")}
        </Title>

        <Typography variant="body1">
          {toCurrency(dividendValues.taxAmount, investmentCurrency, {
            maximumFractionDigits: 6,
          })}
        </Typography>

        <Title variant="body1">
          {t("addDividendWizard.confirmManualDividendStep.commission")}
        </Title>

        <Typography variant="body1">
          {toCurrency(dividendValues.commission, investmentCurrency, {
            maximumFractionDigits: 6,
          })}
        </Typography>

        {isFx && (
          <>
            <Title variant="body1">
              {t("addDividendWizard.confirmManualDividendStep.exchangeRate")}
            </Title>

            <Typography variant="body1">
              {dividendValues.exchangeRate}
            </Typography>
          </>
        )}

        <Divider />
        <Divider />
        <Title variant="h6">
          {t("addDividendWizard.confirmManualDividendStep.total")}
        </Title>

        <Typography variant="h6">
          {toCurrency(dividendValues.amount, investmentCurrency, {
            maximumFractionDigits: 6,
          })}
        </Typography>

        {isFx && (
          <>
            <Title variant="h6">
              {t("addDividendWizard.confirmManualDividendStep.totalAC")}
            </Title>

            <Typography variant="h6">
              {toCurrency(dividendValues.amountAC, "EUR", {
                maximumFractionDigits: 6,
              })}
            </Typography>
          </>
        )}
      </GridContainer>
    </StepContainer>
  );
};
