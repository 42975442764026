import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteStoredSecretsRequest } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteStoredSecrets = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteStoredSecretsRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteStoredSecrets(params);
  return data;
};

export const useDeleteStoredSecrets = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteStoredSecretsRequest
  >
) => {
  return useAuthMutation(deleteStoredSecrets, options, false);
};
