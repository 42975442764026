/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AssetsTotalMarketValue } from '../model';
/**
 * AssetReportsApi - axios parameter creator
 * @export
 */
export const AssetReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Asset report
         * @param {AssetsTotalMarketValueFilterAssetTypeEnum} filterAssetType The asset type of the investment
         * @param {AssetsTotalMarketValueFilterMandatorSlugEnum} [filterMandatorSlug] Mandator of the user. Default is null, meaning the filter will not be applied.
         * @param {boolean} [filterImportedFromBank] Imported from bank or not. Default is FALSE
         * @param {AssetsTotalMarketValueFilterEmitterEnum} [filterEmitter]                    Name of a fund representing a group of ETFs.                   This applies only when filter[asset_type] is either FUND or ETF                 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsTotalMarketValue: async (filterAssetType: AssetsTotalMarketValueFilterAssetTypeEnum, filterMandatorSlug?: AssetsTotalMarketValueFilterMandatorSlugEnum, filterImportedFromBank?: boolean, filterEmitter?: AssetsTotalMarketValueFilterEmitterEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterAssetType' is not null or undefined
            assertParamExists('assetsTotalMarketValue', 'filterAssetType', filterAssetType)
            const localVarPath = `/api/v3/reports/assets/market_value_statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterMandatorSlug !== undefined) {
                localVarQueryParameter['filter[mandator_slug]'] = filterMandatorSlug;
            }

            if (filterImportedFromBank !== undefined) {
                localVarQueryParameter['filter[imported_from_bank]'] = filterImportedFromBank;
            }

            if (filterAssetType !== undefined) {
                localVarQueryParameter['filter[asset_type]'] = filterAssetType;
            }

            if (filterEmitter !== undefined) {
                localVarQueryParameter['filter[emitter]'] = filterEmitter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetReportsApi - functional programming interface
 * @export
 */
export const AssetReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Asset report
         * @param {AssetsTotalMarketValueFilterAssetTypeEnum} filterAssetType The asset type of the investment
         * @param {AssetsTotalMarketValueFilterMandatorSlugEnum} [filterMandatorSlug] Mandator of the user. Default is null, meaning the filter will not be applied.
         * @param {boolean} [filterImportedFromBank] Imported from bank or not. Default is FALSE
         * @param {AssetsTotalMarketValueFilterEmitterEnum} [filterEmitter]                    Name of a fund representing a group of ETFs.                   This applies only when filter[asset_type] is either FUND or ETF                 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsTotalMarketValue(filterAssetType: AssetsTotalMarketValueFilterAssetTypeEnum, filterMandatorSlug?: AssetsTotalMarketValueFilterMandatorSlugEnum, filterImportedFromBank?: boolean, filterEmitter?: AssetsTotalMarketValueFilterEmitterEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetsTotalMarketValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsTotalMarketValue(filterAssetType, filterMandatorSlug, filterImportedFromBank, filterEmitter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetReportsApi.assetsTotalMarketValue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetReportsApi - factory interface
 * @export
 */
export const AssetReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Asset report
         * @param {AssetReportsApiAssetsTotalMarketValueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsTotalMarketValue(requestParameters: AssetReportsApiAssetsTotalMarketValueRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetsTotalMarketValue> {
            return localVarFp.assetsTotalMarketValue(requestParameters.filterAssetType, requestParameters.filterMandatorSlug, requestParameters.filterImportedFromBank, requestParameters.filterEmitter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assetsTotalMarketValue operation in AssetReportsApi.
 * @export
 * @interface AssetReportsApiAssetsTotalMarketValueRequest
 */
export interface AssetReportsApiAssetsTotalMarketValueRequest {
    /**
     * The asset type of the investment
     * @type {'STOCK' | 'FUND' | 'ETF' | 'BOND' | 'CASH' | 'CERTIFICATE_OS' | 'PRECIOUS_METAL' | 'MATERIAL_ASSET' | 'FOREIGN_CURRENCY' | 'MANAGED'}
     * @memberof AssetReportsApiAssetsTotalMarketValue
     */
    readonly filterAssetType: AssetsTotalMarketValueFilterAssetTypeEnum

    /**
     * Mandator of the user. Default is null, meaning the filter will not be applied.
     * @type {'onvista' | 'rentablo'}
     * @memberof AssetReportsApiAssetsTotalMarketValue
     */
    readonly filterMandatorSlug?: AssetsTotalMarketValueFilterMandatorSlugEnum

    /**
     * Imported from bank or not. Default is FALSE
     * @type {boolean}
     * @memberof AssetReportsApiAssetsTotalMarketValue
     */
    readonly filterImportedFromBank?: boolean

    /**
     *                    Name of a fund representing a group of ETFs.                   This applies only when filter[asset_type] is either FUND or ETF                 
     * @type {'Vanguard' | 'DWS' | 'BlackRock'}
     * @memberof AssetReportsApiAssetsTotalMarketValue
     */
    readonly filterEmitter?: AssetsTotalMarketValueFilterEmitterEnum
}

/**
 * AssetReportsApi - object-oriented interface
 * @export
 * @class AssetReportsApi
 * @extends {BaseAPI}
 */
export class AssetReportsApi extends BaseAPI {
    /**
     * 
     * @summary Asset report
     * @param {AssetReportsApiAssetsTotalMarketValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetReportsApi
     */
    public assetsTotalMarketValue(requestParameters: AssetReportsApiAssetsTotalMarketValueRequest, options?: RawAxiosRequestConfig) {
        return AssetReportsApiFp(this.configuration).assetsTotalMarketValue(requestParameters.filterAssetType, requestParameters.filterMandatorSlug, requestParameters.filterImportedFromBank, requestParameters.filterEmitter, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AssetsTotalMarketValueFilterAssetTypeEnum = {
    STOCK: 'STOCK',
    FUND: 'FUND',
    ETF: 'ETF',
    BOND: 'BOND',
    CASH: 'CASH',
    CERTIFICATE_OS: 'CERTIFICATE_OS',
    PRECIOUS_METAL: 'PRECIOUS_METAL',
    MATERIAL_ASSET: 'MATERIAL_ASSET',
    FOREIGN_CURRENCY: 'FOREIGN_CURRENCY',
    MANAGED: 'MANAGED'
} as const;
export type AssetsTotalMarketValueFilterAssetTypeEnum = typeof AssetsTotalMarketValueFilterAssetTypeEnum[keyof typeof AssetsTotalMarketValueFilterAssetTypeEnum];
/**
 * @export
 */
export const AssetsTotalMarketValueFilterMandatorSlugEnum = {
    ONVISTA: 'onvista',
    RENTABLO: 'rentablo'
} as const;
export type AssetsTotalMarketValueFilterMandatorSlugEnum = typeof AssetsTotalMarketValueFilterMandatorSlugEnum[keyof typeof AssetsTotalMarketValueFilterMandatorSlugEnum];
/**
 * @export
 */
export const AssetsTotalMarketValueFilterEmitterEnum = {
    VANGUARD: 'Vanguard',
    DWS: 'DWS',
    BLACK_ROCK: 'BlackRock'
} as const;
export type AssetsTotalMarketValueFilterEmitterEnum = typeof AssetsTotalMarketValueFilterEmitterEnum[keyof typeof AssetsTotalMarketValueFilterEmitterEnum];
