import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { InvestmentTypeEnum } from "@generated/apiv1";

const options = Object.values(InvestmentTypeEnum).map((type) => ({
  value: type,
  label: i18next.t(`investmentTypes.${type}`),
}));

type Props = {
  value: InvestmentTypeEnum;
  onChange: (value: InvestmentTypeEnum) => void;
  notAvailableTypes?: InvestmentTypeEnum[];
} & Omit<SelectProps<InvestmentTypeEnum>, "value" | "onChange">;

export const InvestmentTypeSelect = ({
  value,
  onChange,
  notAvailableTypes,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<InvestmentTypeEnum>) => {
    onChange(event.target.value as InvestmentTypeEnum);
  };

  const filteredOptions = options.filter(
    (option) => !notAvailableTypes?.includes(option.value)
  );

  return (
    <FormControl fullWidth>
      <InputLabel>{t(`components.investmentTypeSelect.label`)}</InputLabel>

      <Select {...rest} value={value} onChange={handleChange} displayEmpty>
        {filteredOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
