import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { toCurrency } from "@helpers";
import { ReactComponent as ArrowLeft } from "@icons/arrow-left.svg";
import { DonutChartData } from "@components/Chart/CashflowCharts/types";

const CashflowAnalyzerChartLegendContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  width: "40%",
  flexShrink: 0,
  minWidth: "350px",
  minHeight: "400px",

  [theme.breakpoints.down("md")]: {
    width: "50%",
  },

  [theme.breakpoints.down(950)]: {
    width: "100%",
    minHeight: "0",
  },
}));

const LegendWrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const LegendChildrenWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "isOpen",
})<{ height: number; isOpen: boolean }>(({ height, isOpen }) => ({
  height: isOpen ? `${height}px` : 0,
  overflow: "hidden",
  transition: "all 0.2s ease-in-out",
  width: "100%",
}));

const LegendChild = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
  width: "100%",
  minWidth: 0,
}));

const LegendWrapperBackground = styled(Box, {
  shouldForwardProp: (prop) => prop !== "percent" && prop !== "color",
})<{ percent: number; color?: string }>(({ percent, color }) => ({
  width: `${percent}%`,
  height: "40px",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 1,
  borderRadius: "4px",
  backgroundColor: color,
}));

const LegendWrapperContent = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isClickable",
})<{ isClickable: boolean }>(({ theme, isClickable }) => ({
  position: "relative",
  zIndex: 2,
  padding: theme.spacing(1, 2),
  width: "100%",
  height: "40px",
  boxSizing: "border-box",
  cursor: isClickable ? "pointer" : "default",
  spacing: "12px",
}));

const LegendIcon = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  flexShrink: 0,

  "& svg": {
    width: "100%",
    height: "100%",
  },
}));

const ArrowRight = styled(ArrowLeft, {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "isVisible",
})<{ isOpen: boolean; isVisible: boolean }>(({ isOpen, isVisible }) => ({
  transform: isOpen ? "rotate(270deg)" : "rotate(180deg)",
  transition: isOpen ? "transform 0.2s ease-in-out" : "none",
  opacity: isVisible ? 1 : 0,
}));

const calculatePercent = (value: number, total: number) => {
  return Math.round((Math.abs(value) / Math.abs(total)) * 100);
};

type Props = {
  data: DonutChartData[];
  displayLegend?: boolean;
  onLegendClick: (id: string) => void;
  onLegendHover?: (id: string) => void;
  activeLegend: string | null;
};

export const CashflowChartLegend = ({
  data,
  displayLegend,
  onLegendClick,
  onLegendHover,
  activeLegend,
}: Props) => {
  const theme = useTheme();
  const isLessSm = useMediaQuery(theme.breakpoints.down("sm"));
  if (!displayLegend) return null;

  const handleLegendClick = (item: DonutChartData) => {
    if (!item?.children?.length) return;
    onLegendClick(item.id);
  };

  const biggestValue = data.reduce((acc, item) => {
    return Math.abs(item.value) > acc ? Math.abs(item.value) : acc;
  }, Math.abs(data[0]?.value) || 0);

  return (
    <CashflowAnalyzerChartLegendContainer>
      {data.map((item) => {
        const percent = calculatePercent(item.value, biggestValue);
        const childrenHeight =
          (item.children?.length || 0) * 32 + (item.children?.length ? 8 : 0);
        const isOpen = activeLegend === item.id;
        const shouldDisplayColorOnClick =
          isOpen && item.name !== "Nicht klassifiziert" && !item.isCustom;
        const hasChildren = Boolean(item.children?.length);

        return (
          <LegendWrapper key={item.name}>
            <LegendWrapperBackground
              percent={percent}
              color={
                item.value > 0 || isOpen
                  ? item.color
                  : theme.palette.background.neutral
              }
            />
            <LegendWrapperContent
              onClick={() => handleLegendClick(item)}
              onMouseOver={() => {
                onLegendHover?.(item.id);
              }}
              isClickable={hasChildren}
              container
            >
              <Grid
                item
                display="flex"
                alignItems="center"
                xs={6}
                md={7}
                gap={3}
              >
                <LegendIcon
                  color={
                    shouldDisplayColorOnClick
                      ? `${item.onHoverColor} !important`
                      : theme.palette.text.secondary
                  }
                >
                  {item.icon}
                </LegendIcon>
                <Typography
                  color={
                    shouldDisplayColorOnClick
                      ? `${item.onHoverColor} !important`
                      : "textPrimary"
                  }
                  variant={isLessSm ? "body2" : "body1"}
                  noWrap
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={3}
                flexShrink={0}
                xs={6}
                md={5}
              >
                <Typography variant="body1">
                  {toCurrency(item.value, "EUR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Box flexShrink={0} display="flex" alignItems="center">
                  <ArrowRight
                    width="20px"
                    height="20px"
                    isOpen={isOpen}
                    isVisible={hasChildren}
                  />
                </Box>
              </Grid>
            </LegendWrapperContent>
            <LegendChildrenWrapper isOpen={isOpen} height={childrenHeight}>
              {item.children?.map((child) => {
                const value = toCurrency(child.value, "EUR", {
                  minimumFractionDigits: 2,
                });
                if (
                  value ===
                  toCurrency(0, "EUR", {
                    minimumFractionDigits: 2,
                  })
                )
                  return null;

                return (
                  <LegendChild key={child.id}>
                    <Typography variant="body2" pl={10} noWrap>
                      {child.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      pr={10}
                      flexShrink={0}
                    >
                      {value}
                    </Typography>
                  </LegendChild>
                );
              })}
            </LegendChildrenWrapper>
          </LegendWrapper>
        );
      })}
    </CashflowAnalyzerChartLegendContainer>
  );
};
