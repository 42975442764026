import React, { ReactElement, useMemo, useState, cloneElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  Box,
  TableCell,
} from "@mui/material";
import { useTableSortWithData } from "@hooks";
import { Investment } from "@generated/apiv1";
import { Modal } from "@components";
import { SortableTableHeader } from "@components/Table";
import { InvestmentRow } from "@features/investments/components/InvestmentsTable/InvestmentRow";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { investmentTypes } from "@features/investments/components/Assets/AssetTypeBreakdown";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import { InvestmentChildRows } from "./InvestmentChildRows";

export interface Props {
  investments: Investment[];
  className?: string;
  isLoading?: boolean;
  selectedAssetCards: string[];
  AddQuoteModalContent: ReactElement;
}

export const InvestmentsTable = ({
  className,
  investments,
  isLoading = false,
  AddQuoteModalContent,
  selectedAssetCards,
}: Props) => {
  const { t } = useTranslation();
  const { isAnonymized } = useSharedPortfolioContext();
  const [currentAddQuoteInvestment, setCurrentAddQuoteInvestment] =
    useState<Investment>();
  const [isAddQuoteModalShown, setIsAddQuoteModalShown] = useState(false);

  const hasAnyAggregatedRows = investments.some(
    (investment) => investment.aggregation?.isAggregated
  );

  const filteredInvestments = useMemo(
    () =>
      investments.filter((investment) => {
        if (!selectedAssetCards.length) return true;
        return selectedAssetCards.includes(investmentTypes[investment.type]);
      }),
    [selectedAssetCards, investments]
  );

  const totalValue = useMemo(
    () =>
      investments.reduce(
        (acc, investment) => acc + investment.currentMarketValueAC,
        0
      ),
    [investments]
  );

  const investmentsWithAdditionalProps = useMemo(
    () =>
      filteredInvestments.map((investment) => ({
        ...investment,
        allocation: investment.currentMarketValueAC / totalValue ?? 0,
      })),
    [filteredInvestments, totalValue]
  );

  const {
    data: sortedInvestments,
    sortField,
    sortDirection,
    sort,
  } = useTableSortWithData(investmentsWithAdditionalProps, {
    field: "allocation",
    direction: "DESC",
    saveKey: "investmentList",
  });

  const handleClickOnHeader = (sortByName: string) => {
    sort(sortByName);
  };

  const logos = useSecurityImages(investments);

  const handleInvestmentSelected = (investment: Investment) => {
    setCurrentAddQuoteInvestment(investment);
    setIsAddQuoteModalShown(true);
  };

  const handleCloseModal = () => {
    setCurrentAddQuoteInvestment(undefined);
    setIsAddQuoteModalShown(false);
  };

  const AddQuoteModalContentWithInvestment = cloneElement(
    AddQuoteModalContent,
    { investment: currentAddQuoteInvestment, onClose: handleCloseModal }
  );

  const hasData = Boolean(investments.length);

  return (
    <WithLoadingSpinner isLoading={isLoading}>
      <Box sx={{ overflowX: "auto" }}>
        {hasData ? (
          <Table className={className}>
            <TableHead>
              <TableRow>
                {hasAnyAggregatedRows && (
                  <TableCell style={{ minWidth: "16px" }} />
                )}
                <SortableTableHeader
                  isSortable
                  sortField={sortField}
                  sortDirection={sortDirection}
                  sortByName="standardisedName"
                  text={t("domains.investments.investmentTable.name")}
                  onClick={handleClickOnHeader}
                />

                <SortableTableHeader
                  isSortable
                  sortField={sortField}
                  sortDirection={sortDirection}
                  sortByName="quote.changePercentAbs"
                  text={t(
                    "domains.investments.investmentTable.changePercentAbs"
                  )}
                  onClick={handleClickOnHeader}
                  align="right"
                />

                <SortableTableHeader
                  isSortable
                  sortField={sortField}
                  sortDirection={sortDirection}
                  sortByName="purchaseValue"
                  text={t("domains.investments.investmentTable.purchase")}
                  onClick={handleClickOnHeader}
                  align="right"
                />

                <SortableTableHeader
                  isSortable
                  sortField={sortField}
                  sortDirection={sortDirection}
                  sortByName="returnOnInvestmentInPercent"
                  text={t("domains.investments.investmentTable.change")}
                  onClick={handleClickOnHeader}
                  align="right"
                />

                {!isAnonymized && (
                  <>
                    <SortableTableHeader
                      isSortable
                      sortField={sortField}
                      sortDirection={sortDirection}
                      sortByName="receivedTotalDividend"
                      text={t("domains.investments.investmentTable.dividends")}
                      onClick={handleClickOnHeader}
                      align="right"
                    />
                    <SortableTableHeader
                      isSortable
                      sortField={sortField}
                      sortDirection={sortDirection}
                      sortByName="allocation"
                      text={t("domains.investments.investmentTable.allocation")}
                      onClick={handleClickOnHeader}
                      align="right"
                    />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody key={`${sortDirection}-${sortField}`}>
              {sortedInvestments.map((investment) => (
                <InvestmentRow
                  key={
                    investment.aggregation?.isAggregated
                      ? investment.aggregation?.identifier
                      : investment.id
                  }
                  row={investment}
                  image={
                    logos[investment.isin] || logos[investment.tickerSymbol]
                  }
                  showIndent={
                    hasAnyAggregatedRows &&
                    !investment.aggregation?.isAggregated
                  }
                  onClickAddQuoteModal={handleInvestmentSelected}
                  expandableElement={
                    investment.aggregation?.isAggregated ? (
                      <InvestmentChildRows
                        investments={investment.aggregation?.items ?? []}
                        totalValue={investment.currentMarketValueAC ?? 0}
                        renderComponent={InvestmentRow}
                        sortField={sortField}
                        sortDirection={sortDirection}
                      />
                    ) : undefined
                  }
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoDataStub minHeight={100} />
        )}
      </Box>

      <Modal
        isOpen={isAddQuoteModalShown}
        onClose={handleCloseModal}
        title={t("domains.investments.investmentTable.addQuote.modalTitle")}
        width={600}
        height={"max-content"}
      >
        {AddQuoteModalContentWithInvestment}
      </Modal>
    </WithLoadingSpinner>
  );
};
