import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  CashFlowAndPerformanceStatisticsList,
  CreateCashFlowAndPerformanceStatisticsParams,
} from "../model";

export const getPerformanceByDepots = async (
  token: string | undefined,
  body: CreateCashFlowAndPerformanceStatisticsParams
) => {
  const { data } =
    await axiosInstanceV1.post<CashFlowAndPerformanceStatisticsList>(
      `/performance/depots`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return data;
};
