import { UseQueryOptions } from "react-query";
import { RevenueCatUser } from "@generated/user";

import { buildSubscriptionsApi } from "@generated/user/apiBuilder";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const revenueCat = async (token: string | undefined) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.revenueCat();
  return data;
};

export const useRevenueCat = (
  options?: UseQueryOptions<RevenueCatUser, AxiosApiError, RevenueCatUser>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONS_REVENUE_CAT],
    ({ ff_token }) => revenueCat(ff_token),
    options
  );
};
