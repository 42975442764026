import React from "react";
import { useTranslation } from "react-i18next";
import { Account } from "@api";
import { useMyDepots } from "@api/v1/hooks/useAccounts";
import { LoadingProgressBar } from "@components";
import {
  AutocompleteStep,
  AutocompleteStepProps,
} from "@components/Wizard/AutocompleteStep";
import { Values } from "../DepotTransferWizard";

export const DepotAutocompleteStep = ({
  onNext,
  defaultValue,
  context,
  ...props
}: AutocompleteStepProps<number> & { context: Values }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useMyDepots();

  const depots =
    data?.accounts.filter((account) => account.id !== context.fromAccountId) ||
    [];

  const defaultAccount = defaultValue
    ? depots.find(({ id }) => defaultValue === id)
    : depots[0];

  const handleOnNext = (account?: Account) => {
    onNext(account?.id);
  };

  return (
    <>
      {isLoading ? (
        <LoadingProgressBar />
      ) : (
        <AutocompleteStep
          {...props}
          defaultValue={defaultAccount}
          onNext={handleOnNext}
          loading={isLoading}
          getOptionLabel={(account) => account.name || ""}
          options={depots || []}
          testId="depot-autocomplete-step"
          question={t("depotTransfer.depotAutocompleteStep.question")}
          placeholder={t("depotTransfer.depotAutocompleteStep.placeholder")}
        />
      )}
    </>
  );
};
