import { UseMutationOptions } from "react-query";
import {
  CashbackIncidentsApiCreateCashbackIncidentRequest,
  CashbackIncident,
} from "@generated/apiv1";

import { buildCashbackIncidentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createCashbackIncident = async (
  token: string | undefined,
  params: CashbackIncidentsApiCreateCashbackIncidentRequest
) => {
  const apiInstance = buildCashbackIncidentsApi(token);
  const { data } = await apiInstance.createCashbackIncident(params);
  return data;
};

export const useCreateCashbackIncident = (
  options?: UseMutationOptions<
    CashbackIncident,
    AxiosApiError,
    CashbackIncidentsApiCreateCashbackIncidentRequest
  >
) => {
  return useAuthMutation(createCashbackIncident, options, false);
};
