import { UseQueryOptions } from "react-query";
import { FundamentalsApiFundamentalsFullRefreshRequest } from "@generated/apiv1";

import { buildFundamentalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundamentalsFullRefresh = async (
  token: string | undefined,
  params?: FundamentalsApiFundamentalsFullRefreshRequest
) => {
  const apiInstance = buildFundamentalsApi(token);
  const { data } = await apiInstance.fundamentalsFullRefresh(params);
  return data;
};

export const useFundamentalsFullRefresh = (
  params?: FundamentalsApiFundamentalsFullRefreshRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDAMENTALS_FUNDAMENTALS_FULL_REFRESH, params],
    ({ token }) => fundamentalsFullRefresh(token, params),
    options
  );
};
