import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { format, parseISO } from "date-fns";
import { Block, Row } from "@components/common/dataBlock";
import { useInvestment } from "@api/v1/hooks/useInvestment";
import { toCurrencyWholeNumber, toShort } from "@helpers";
import { useFundamental } from "../../hooks/useFundamental";
import { useMarketCap } from "../../hooks/useMarketCap";
import { FundamentalsBlockLayout } from "../FundamentalsBlockLayout";

interface Props {
  isLoading?: boolean;
  marketCap?: number;
  marketCapCurrency?: string;
  stockIndexes?: string[];
  sectorName?: string;
  lastBalanceUpdate?: string;
  wkn?: string;
  countryTwoLetterCode?: string;
  countryName?: string;
}

export const DetailInformationBlock = ({
  marketCap,
  marketCapCurrency,
  isLoading,
  stockIndexes,
  sectorName,
  lastBalanceUpdate,
  countryTwoLetterCode,
  wkn,
  countryName,
}: Props) => {
  const { t } = useTranslation();
  const title = t("fundamentals.detailInformation.title");

  return (
    <FundamentalsBlockLayout title={title} isLoading={isLoading}>
      <Box display="flex">
        <Grid container columnSpacing={6} rowSpacing={3}>
          <Grid item xs={6}>
            <Row>
              <Block title={t("fundamentals.detailInformation.marketCap")}>
                <Box display="flex" gap={1.5}>
                  <Typography variant="body1">
                    {marketCap ? toShort(marketCap) : "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    {toCurrencyWholeNumber(0, marketCapCurrency).replace(
                      "0",
                      " "
                    )}
                  </Typography>
                </Box>
              </Block>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <Block title={t("fundamentals.detailInformation.indexes")}>
                <Typography variant="body1">
                  {stockIndexes?.join(", ") || "N/A"}
                </Typography>
              </Block>
            </Row>
          </Grid>
          <Grid item xs={6}>
            <Block title={t("fundamentals.detailInformation.sector")}>
              <Typography variant="body1">{sectorName || "N/A"}</Typography>
            </Block>
          </Grid>
          <Grid item xs={6}>
            <Block title={t("fundamentals.detailInformation.lastUpdate")}>
              <Typography variant="body1">
                {lastBalanceUpdate
                  ? format(parseISO(lastBalanceUpdate), "dd.MM.yyyy")
                  : "N/A"}
              </Typography>
            </Block>
          </Grid>
          <Grid item xs={6}>
            <Block title={t("detailedInformation.wkn")}>
              <Typography variant="body1">{wkn || "N/A"}</Typography>
            </Block>
          </Grid>
          <Grid item xs={6}>
            <Block title={t("detailedInformation.land")}>
              <Box display="flex" alignItems={"center"} gap={2}>
                {countryTwoLetterCode && (
                  <ReactCountryFlag
                    style={{
                      width: "18px",
                      height: "14px",
                    }}
                    svg
                    countryCode={countryTwoLetterCode}
                  />
                )}
                <Typography variant="body1">{countryName || "N/A"}</Typography>
              </Box>
            </Block>
          </Grid>
        </Grid>
      </Box>
    </FundamentalsBlockLayout>
  );
};

export const DetailInformationByInvestment = ({
  investmentId,
}: {
  investmentId: number;
}) => {
  const { data: investment, isLoading } = useInvestment(investmentId);
  return (
    <DetailInformation
      isin={investment?.isin}
      quoteProvider={investment?.quoteProvider}
      tickerSymbol={investment?.tickerSymbol}
      currency={investment?.currency}
      isLoading={isLoading}
    />
  );
};

export const DetailInformation = ({
  quoteProvider,
  tickerSymbol,
  isin,
  currency,
  isLoading,
}: {
  quoteProvider?: string;
  tickerSymbol?: string;
  isin?: string;
  currency?: string;
  isLoading?: boolean;
}) => {
  const { data: security, isLoading: isSecurityLoading } = useFundamental(isin);
  const { data: marketCap, isLoading: isMarketCapLoading } = useMarketCap(
    isin,
    quoteProvider,
    tickerSymbol,
    currency
  );
  return (
    <DetailInformationBlock
      marketCap={marketCap?.marketCapitalization}
      marketCapCurrency={marketCap?.currency}
      stockIndexes={security?.stockIndexes?.map(({ slug }) => slug).sort()}
      sectorName={security?.sector?.germanName}
      lastBalanceUpdate={security?.lastBalanceUpdate}
      wkn={security?.wkn}
      countryTwoLetterCode={security?.country?.twoLetterCode}
      countryName={security?.country?.germanName}
      isLoading={isLoading || isSecurityLoading || isMarketCapLoading}
    />
  );
};
