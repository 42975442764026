import { axiosInstanceV1 } from "@api/axiosInstance";
import { Fund, PageableFundList } from "../model";

export interface FundParams {
  includeFundamentals?: boolean;
  includeCommissions?: boolean;
}

export interface FundsParams {
  id?: string[];
  wkn?: string[];
  isin?: string[];
  search?: string;
  type?: string[];

  page?: number;
  perPage?: number;
  includeCommissions?: boolean;
  withoutCourtage?: boolean;

  strict?: boolean;
  order?: string;
}

export const loadFund = async (
  token: string | undefined,
  isin: string,
  params?: FundParams
) => {
  const response = await axiosInstanceV1.get<Fund>(`/funds/byIsin/${isin}`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const loadFunds = async (params?: FundsParams) => {
  const response = await axiosInstanceV1.get<PageableFundList>("/funds", {
    params,
  });
  return response.data;
};
