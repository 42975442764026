import { UseMutationOptions } from "react-query";
import { AccountsApiPurgeAccountRequest, Account } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const purgeAccount = async (
  token: string | undefined,
  params: AccountsApiPurgeAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.purgeAccount(params);
  return data;
};

export const usePurgeAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiPurgeAccountRequest
  >
) => {
  return useAuthMutation(purgeAccount, options, false);
};
