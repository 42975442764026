import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest,
  AddOrUpdateBankConnectionResult,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const idAddOrUpdateBankConnectionResult = async (
  token: string | undefined,
  params: BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.idAddOrUpdateBankConnectionResult(params);
  return data;
};

export const useIdAddOrUpdateBankConnectionResult = (
  params: BankConnectionsApiIdAddOrUpdateBankConnectionResultRequest,
  options?: UseQueryOptions<
    AddOrUpdateBankConnectionResult,
    AxiosApiError,
    AddOrUpdateBankConnectionResult
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.BANKCONNECTIONS_ID_ADD_OR_UPDATE_BANK_CONNECTION_RESULT,
      params,
    ],
    ({ token }) => idAddOrUpdateBankConnectionResult(token, params),
    options
  );
};
