import { UseQueryOptions } from "react-query";
import {
  InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest,
  InsuranceProviderSpecificContractIdPattern,
} from "@generated/apiv1";

import { buildInsuranceProviderSpecificContractIdPatternsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const createInsuranceProviderSpecificContractIdPattern = async (
  token: string | undefined,
  params: InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest
) => {
  const apiInstance =
    buildInsuranceProviderSpecificContractIdPatternsApi(token);
  const { data } =
    await apiInstance.createInsuranceProviderSpecificContractIdPattern(params);
  return data;
};

export const useCreateInsuranceProviderSpecificContractIdPattern = (
  params: InsuranceProviderSpecificContractIdPatternsApiCreateInsuranceProviderSpecificContractIdPatternRequest,
  options?: UseQueryOptions<
    InsuranceProviderSpecificContractIdPattern,
    AxiosApiError,
    InsuranceProviderSpecificContractIdPattern
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.INSURANCEPROVIDERSPECIFICCONTRACTIDPATTERNS_CREATE_INSURANCE_PROVIDER_SPECIFIC_CONTRACT_ID_PATTERN,
      params,
    ],
    ({ token }) =>
      createInsuranceProviderSpecificContractIdPattern(token, params),
    options
  );
};
