import React, { ChangeEvent, useEffect, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { uniqBy } from "lodash";
import sortBy from "lodash/sortBy";
import {
  Investment,
  InvestmentReference,
  InvestmentTypeEnum,
} from "@generated/apiv1";
import { SearchField, SearchHighlight } from "@components";
import { useSecurityImages } from "@hooks";
import { SecurityImage } from "@components/SecurityImage";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { usePublicOrPrivateInvestments } from "@features/sharedPortfolio/hooks/usePublicOrPrivateInvestments";

export const isNewInvestmentOption = (
  option: any
): option is AdditionalOption => option.id === "new";

export type AdditionalOption = {
  id: string;
  standardisedName: string;
};

type Props<T> = {
  accountId?: number | number[];
  isin?: string | string[];
  label?: string;
  placeholder?: string;
  type?: InvestmentTypeEnum;
  value?: { id?: number | string } | null;
  onChange: (value: T | AdditionalOption | null) => void;
  selectableInvestments?: T[];
  isLoading?: boolean;
  allowAddingNew?: boolean;
  noLabel?: boolean;
  onlyUnique?: boolean;
  includeHistoric?: boolean;
  displaySelectedInvestment?: boolean;
} & Omit<
  AutocompleteProps<any, any, any, any>,
  "onChange" | "options" | "value" | "renderInput"
>;

export const InvestmentSelect = <T extends Investment | InvestmentReference>({
  accountId,
  isin,
  label,
  placeholder,
  value,
  type,
  selectableInvestments,
  isLoading,
  allowAddingNew = false,
  onlyUnique = false,
  includeHistoric = false,
  displaySelectedInvestment = true,
  noLabel,
  ...rest
}: Props<T>) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = React.useState<
    T | AdditionalOption | null
  >(null);

  const { investments: investmentList, isLoading: areInvestmentsLoading } =
    usePublicOrPrivateInvestments(
      {
        accountId: typeof accountId === "number" ? [accountId] : accountId,
        isin: typeof isin === "string" ? [isin] : isin ?? undefined,
        includeHistoric,
      },
      {
        enabled: !selectableInvestments,
      }
    );

  const investments = useMemo(
    () => selectableInvestments || (investmentList as T[]) || [],
    [selectableInvestments, investmentList]
  );

  const filteredInvestments = useMemo(() => {
    const typed = investments.filter((investment) => {
      return type ? investment.type === type : true;
    });
    return sortBy(
      onlyUnique ? uniqBy(typed, (investment) => investment.isin) : typed,
      (investment) => investment.standardisedName
    );
  }, [investments, type, onlyUnique]);

  const logos = useSecurityImages(filteredInvestments);

  useEffect(() => {
    if (value === null) {
      setCurrentValue(null);
      return;
    }
    const found = filteredInvestments?.find(
      (investment) => investment.id === value?.id
    );
    if (found) {
      setCurrentValue(found);
    }
  }, [value, filteredInvestments]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: T | AdditionalOption | null
  ) => {
    setCurrentValue(newValue);
    if (!displaySelectedInvestment) {
      // @ts-ignore
      document.activeElement?.blur();
    }
    rest.onChange?.(newValue);
  };

  const defaultFilterOptions = useMemo(
    () =>
      createFilterOptions<T | AdditionalOption>({
        stringify: (option) => option.standardisedName,
        matchFrom: "start",
      }),
    []
  );

  return (
    <FormControl fullWidth>
      {!noLabel && (
        <InputLabel>
          {label || t(`components.investmentSelect.label`)}
        </InputLabel>
      )}

      <Autocomplete<T | AdditionalOption>
        fullWidth
        loading={isLoading || areInvestmentsLoading}
        value={displaySelectedInvestment ? currentValue : null}
        disablePortal
        loadingText={t("form.loading")}
        options={filteredInvestments || []}
        filterOptions={(options, state) => {
          const result = defaultFilterOptions(options, state);
          if (result.length > 0 || !allowAddingNew) {
            return result;
          }
          return state.inputValue.length
            ? [
                {
                  id: "new",
                  standardisedName: state.inputValue,
                },
              ]
            : [];
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value?.id;
        }}
        renderInput={(params) => (
          <SearchField
            {...params}
            inputProps={{
              ...params.inputProps,
              "data-testid": "investments-autocomplete",
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                currentValue &&
                !isNewInvestmentOption(currentValue) &&
                displaySelectedInvestment ? (
                  <InputAdornment position="start">
                    <SecurityImage
                      src={
                        logos[
                          currentValue.quoteProvider === "crypto"
                            ? currentValue.tickerSymbol
                            : currentValue.isin
                        ]
                      }
                      width={20}
                      height={20}
                    />
                  </InputAdornment>
                ) : undefined,
            }}
            placeholder={
              placeholder || t(`components.investmentSelect.placeholder`)
            }
            displayClearButton={
              displaySelectedInvestment ? Boolean(currentValue) : false
            }
            onClear={() => handleChange({} as ChangeEvent, null)}
            sx={{
              width: "100%",
            }}
          />
        )}
        getOptionLabel={(option) => option.standardisedName || ""}
        noOptionsText={t("components.investmentSelect.noOptions")}
        renderOption={(props, option, { inputValue }) => (
          <li {...props} data-testid={`country-${option.id}`} key={option.id}>
            <Box display="flex" alignItems="center" gap={2}>
              {isNewInvestmentOption(option) ? (
                <PlusIcon />
              ) : (
                <SecurityImage
                  src={
                    logos?.[
                      option.quoteProvider === "crypto"
                        ? option.tickerSymbol
                        : option.isin
                    ]
                  }
                  width={20}
                  height={20}
                />
              )}
              <SearchHighlight
                value={
                  isNewInvestmentOption(option)
                    ? t("components.investmentSelect.newInvestment", {
                        label: option.standardisedName
                          ? `"${option.standardisedName}"`
                          : "",
                      })
                    : option.standardisedName
                }
                searchValue={inputValue}
                variant="body2"
                fontWeight={400}
                color="primary.main"
                sx={{
                  textDecoration: "underline",
                }}
              />
            </Box>
          </li>
        )}
        {...rest}
        onChange={handleChange}
        sx={{
          ...rest.sx,
          "& .MuiAutocomplete-inputRoot": {
            paddingRight: "16px !important",
          },
          "& .MuiInputAdornment-positionStart": {
            paddingLeft: "12px !important",
          },
          "& .MuiInputBase-input": {
            paddingRight: "0 !important",
          },
          "& .MuiInputBase-inputAdornedStart": {
            paddingLeft: "0 !important",
          },
        }}
      />
    </FormControl>
  );
};
