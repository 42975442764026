import { UseQueryOptions } from "react-query";
import {
  CashFlowAndPerformanceStatisticsList,
  CreateCashFlowAndPerformanceStatisticsParams,
} from "@api/v1/model";
import { getPerformanceByDepots } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { GET_PERFORMANCE_BY_DEPOTS } from "../../cacheKeys";

export const useGetPerformanceByDepots = (
  body: CreateCashFlowAndPerformanceStatisticsParams,
  options: UseQueryOptions<
    CashFlowAndPerformanceStatisticsList,
    AxiosApiError,
    CashFlowAndPerformanceStatisticsList
  > = { enabled: true }
) =>
  useAuthQuery(
    [GET_PERFORMANCE_BY_DEPOTS, body],
    ({ token }) => getPerformanceByDepots(token, body),
    options
  );
