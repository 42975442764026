/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { MandatorReference } from './mandator-reference';
// May contain unused imports in some cases
// @ts-ignore
import { ProfileReference } from './profile-reference';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * The technical identifier
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * The users email address
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * Is the users email address confirmed?
     * @type {boolean}
     * @memberof User
     */
    'isEmailConfirmed'?: boolean;
    /**
     * Defines if emails may be delivered to this user; Delivery is         skipped for any status except \'active\'. This flag is only relevant if your mandator uses         Rentablo as email delivery service.
     * @type {string}
     * @memberof User
     */
    'emailDeliveryStatus'?: UserEmailDeliveryStatusEnum;
    /**
     * The users gender (1 = male, 2 = female); will default to male if not given
     * @type {number}
     * @memberof User
     */
    'gender'?: UserGenderEnum;
    /**
     * The users name (if given)
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * Date of the users last login
     * @type {string}
     * @memberof User
     */
    'lastLogin'?: string;
    /**
     * Date of user creation
     * @type {string}
     * @memberof User
     */
    'userCreatedAt'?: string;
    /**
     * A user can only log in if its account was enabled
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean;
    /**
     * Is the users account locked?
     * @type {boolean}
     * @memberof User
     */
    'accountLocked'?: boolean;
    /**
     * Is the users account expired?
     * @type {boolean}
     * @memberof User
     */
    'accountExpired'?: boolean;
    /**
     * Is the users password expired?
     * @type {boolean}
     * @memberof User
     */
    'passwordExpired'?: boolean;
    /**
     * Are client credentials disabled for this user? If so, the user may obtain an         access token using credential login without providing client credentials.
     * @type {boolean}
     * @memberof User
     */
    'clientCredentialsDisabled': boolean;
    /**
     * Is the second authentication factor (security-PIN) for this user disabled?
     * @type {boolean}
     * @memberof User
     */
    'secondAuthenticationFactorDisabled'?: boolean;
    /**
     * Did the user accept the terms and privacy agreement?
     * @type {boolean}
     * @memberof User
     */
    'acceptedAgb'?: boolean;
    /**
     * When have terms an privacy agreement been accepted?
     * @type {string}
     * @memberof User
     */
    'lastAgbAcceptanceDate'?: string;
    /**
     * 
     * @type {MandatorReference}
     * @memberof User
     */
    'mandatorReference'?: MandatorReference;
    /**
     * 
     * @type {ProfileReference}
     * @memberof User
     */
    'profileReference'?: ProfileReference;
    /**
     * Lists the type of third party accounts this user has; e.g. a finAPI account.
     * @type {Array<string>}
     * @memberof User
     */
    'thirdPartyAccounts'?: Array<string>;
    /**
     * Lists of roles user assigned
     * @type {Array<string>}
     * @memberof User
     */
    'roles'?: Array<string>;
}

export const UserEmailDeliveryStatusEnum = {
    HARD_BOUNCE: 'hard_bounce',
    SPAM: 'spam',
    ACTIVE: 'active'
} as const;

export type UserEmailDeliveryStatusEnum = typeof UserEmailDeliveryStatusEnum[keyof typeof UserEmailDeliveryStatusEnum];
export const UserGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];


