import { UseQueryOptions } from "react-query";
import {
  InsuranceCategoriesApiInsuranceCategoriesIdRequest,
  InsuranceCategory,
} from "@generated/apiv1";

import { buildInsuranceCategoriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const insuranceCategoriesId = async (
  token: string | undefined,
  params: InsuranceCategoriesApiInsuranceCategoriesIdRequest
) => {
  const apiInstance = buildInsuranceCategoriesApi(token);
  const { data } = await apiInstance.insuranceCategoriesId(params);
  return data;
};

export const useInsuranceCategoriesId = (
  params: InsuranceCategoriesApiInsuranceCategoriesIdRequest,
  options?: UseQueryOptions<InsuranceCategory, AxiosApiError, InsuranceCategory>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INSURANCECATEGORIES_INSURANCE_CATEGORIES_ID, params],
    ({ token }) => insuranceCategoriesId(token, params),
    options
  );
};
