import { InvokeCallback, Sender } from "xstate";
import { getInvestmentWithToken } from "@api/v1/methods/investments";
import { investmentToSymbol } from "@features/transactionWizard/modal/stateMachine/utils/utils";
import { Investment } from "@generated/apiv1";
import { withToken } from "@api";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import { InvestmentValues } from "../types/investmentValues";

export const getSymbolWithToken = withToken(investmentToSymbol);

export const loadInvestment =
  (
    context: TransactionWizardContext,
    event: TransactionWizardEvent
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (event.type !== "INIT_FROM_INVESTMENT")
      throw new Error(`Invalid event type ${event.type}`);

    load(event.id, callback);
  };

const load = async (id: number, callback: Sender<TransactionWizardEvent>) => {
  try {
    const investment = await getInvestmentWithToken(id);
    const symbol = await getSymbolWithToken(investment as Investment);

    const investmentValues: InvestmentValues = {
      id: investment.id,
      depotId: investment.account.id,
      name: investment.name,
      isin: investment.isin,
      wkn: investment.wkn,
      type: investment.type,
      tickerSymbol: investment.tickerSymbol,
      quoteProvider: investment.quote?.quoteProvider,
      quoteCurrency: investment.quote?.currency,
      investmentCurrency: investment.currency,
      numberOfLots: investment.numberOfLots,
      availableLots: investment.numberOfLots,
    };

    callback({
      type: "INVESTMENT_INITIALIZED",
      investmentValues: investmentValues,
      symbol: symbol ?? undefined,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
