import React from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { useV1ListCurrencies } from "@generated/apiv1/hooks";
import { InvestmentValues } from "@features/transactionWizard/stateMachine/types/investmentValues";
import { useLabelHelpers } from "../hooks/useLabelHelpers";
import { useHelpers } from "../hooks/useHelpers";
import { BookingValues } from "../stateMachine/types/bookingValues";
import { StepContainer } from "../StepContainer";

type Record = {
  label: string;
  value: string;
  bold?: boolean;
  hasBorderTop?: boolean;
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  row-gap: ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 70% 30%;
  }
`;

export interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
  bookingValues: BookingValues;
  investmentValues: InvestmentValues;
  error?: ApiException;
}

export const SummaryStep = ({
  handleNext,
  handlePrevious,
  bookingValues,
  investmentValues,
}: Props) => {
  const { hasSecurityPrice } = useHelpers();
  const { t } = useTranslation();

  const { nolSummaryLabel, nolSummaryDescription, formatPrice } =
    useLabelHelpers();
  const investmentType = investmentValues.type || "11_stock";
  const investmentCurrency = investmentValues.investmentCurrency || "EUR";
  const currencySymbol = getCurrencySymbol(investmentCurrency);
  const isFx = investmentCurrency !== "EUR";
  const isManaged = investmentType === "91_managed";
  const { data } = useV1ListCurrencies();
  const currency = data?.currencies?.find(
    (currency) => currency.code === investmentCurrency
  );

  const records: Record[] = [
    ...(investmentValues.name
      ? [
          {
            label: t("transactionWizard.summaryStep.name"),
            value: investmentValues.name,
          },
        ]
      : []),
    {
      label: t("transactionWizard.summaryStep.date"),
      value: format(bookingValues?.date || new Date(), "dd.MM.yyyy"),
    },
  ];

  if (!isManaged) {
    records.push({
      label: nolSummaryDescription(investmentType),
      value:
        investmentType === "31_bond"
          ? formatPrice(investmentCurrency, bookingValues.numberOfLots)
          : `${formatPrice(
              undefined,
              bookingValues.numberOfLots
            )} ${nolSummaryLabel(investmentType, currencySymbol)}`,
    });
  }

  records.push({
    label: t("transactionWizard.summaryStep.entryQuote"),
    value: formatPrice(
      investmentType === "31_bond" ? "%" : investmentCurrency,
      bookingValues.entryQuote
    ),
  });

  if (hasSecurityPrice(bookingValues.type)) {
    records.push({
      label: t("transactionWizard.summaryStep.securityPrice"),
      value: formatPrice(
        investmentType === "31_bond" ? "%" : investmentCurrency,
        bookingValues.securityPrice
      ),
    });
  }

  records.push({
    label: t("transactionWizard.summaryStep.currency"),
    value: `${investmentCurrency} - ${currency?.name}`,
  });

  if (isFx) {
    records.push({
      label: t("transactionWizard.summaryStep.exchangeRate"),
      value: formatPrice(undefined, bookingValues.exchangeRate),
    });
  }
  records.push({
    label: t("transactionWizard.summaryStep.expenses"),
    value: formatPrice(
      investmentCurrency,
      (bookingValues.taxAmount || 0) + (bookingValues.commission || 0)
    ),
  });
  records.push({
    label: t("transactionWizard.summaryStep.totalAmount"),
    value: formatPrice(
      investmentCurrency,
      isManaged ? bookingValues.entryQuote : bookingValues.amount
    ),
    bold: true,
    hasBorderTop: true,
  });
  if (isFx && !isManaged) {
    records.push({
      label: t("transactionWizard.summaryStep.totalAmountAC"),
      value: formatPrice("EUR", bookingValues.amountAC),
      bold: true,
    });
  }

  return (
    <StepContainer
      testid="summary-step"
      title={t("transactionWizard.summaryStep.title")}
      nextLabel={t("transactionWizard.summaryStep.persist")}
      onNext={() => {
        handleNext();
      }}
      onPrevious={handlePrevious}
    >
      <GridContainer>
        {records.map((record, ix) => {
          return (
            <React.Fragment key={ix}>
              {record.hasBorderTop && (
                <>
                  <Divider />
                  <Divider />
                </>
              )}
              <Typography variant={record.bold ? "h6" : "body1"}>
                {record.label}:
              </Typography>
              <Typography
                variant={record.bold ? "h6" : "body1"}
                fontWeight={record.bold ? "500" : "400"}
              >
                {record.value}
              </Typography>
            </React.Fragment>
          );
        })}
      </GridContainer>
    </StepContainer>
  );
};
