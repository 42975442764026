import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionType } from "@api";
import { ReactComponent as WarningIcon } from "@icons/warning.svg";
import { Banner } from "@components";

interface AddManuallyWarningsProps {
  transactionType?: TransactionType;
}

export const AddManuallyWarnings = ({
  transactionType,
}: AddManuallyWarningsProps) => {
  const { t } = useTranslation();
  if (transactionType === "other_gains") {
    return (
      <Banner
        data-testid="option-alert-warning"
        type="warn"
        text={t("addManuallyPage.form.hints.other_gains")}
        icon={<WarningIcon />}
      />
    );
  }

  return null;
};
