import React, { useMemo } from "react";
import { Investment } from "@generated/apiv1";
import { useTableSortWithData } from "@hooks";

interface Props {
  investments: (Investment & { allocation?: number; id: number })[];
  renderComponent: React.FC<{
    row: Investment & { allocation?: number };
    isChildRow: boolean;
    showIndent?: boolean;
  }>;
  totalValue: number;
  sortField?: string;
  sortDirection?: TableSortDirection;
}

export function InvestmentChildRows({
  investments,
  renderComponent,
  totalValue,
  sortField,
  sortDirection,
}: Props) {
  const Component = renderComponent;

  const fieldsWithAllocation = useMemo(
    () =>
      investments.map((investment) => ({
        ...investment,
        allocation: investment.currentMarketValueAC / totalValue,
      })),
    [investments, totalValue]
  );

  const { data } = useTableSortWithData(fieldsWithAllocation, {
    field:
      (sortField === "standardisedName" ? "account.name" : sortField) ??
      "allocation",
    direction: sortDirection ?? "DESC",
  });

  return (
    <>
      {data.map((item) => (
        <Component
          key={item.id}
          row={item}
          isChildRow={true}
          showIndent={true}
        />
      ))}
    </>
  );
}
