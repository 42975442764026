import { format } from "date-fns";
import { useGetExchangeRateOnDemand } from "@api/v1/hooks/useGetExchangeRate";

export const useProposeExchangeRate = (
  investmentCurrency: string,
  handleExchangeRateChange: (rate: number) => void
) => {
  const { mutateAsync: getExchangeRate } = useGetExchangeRateOnDemand();

  const proposeExchangeRateChange = async (date: Date) => {
    if (!date || (date && isNaN(date.getTime()))) return;
    if (!investmentCurrency || investmentCurrency === "EUR") return;
    getExchangeRate({
      from: "EUR",
      to: investmentCurrency,
      date: format(date, "yyyy-MM-dd"),
    })
      .then((result) => {
        if (!result || !result.rate) return;
        handleExchangeRateChange(result.rate);
      })
      .catch(() => {
        // ignore...
      });
  };

  return {
    proposeExchangeRateChange,
  };
};
