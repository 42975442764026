import React, { ReactNode, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  TextField,
  FormControl,
  Link,
  FormControlLabel,
  Checkbox,
  useTheme,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useKey } from "react-use";
import styled from "@emotion/styled";
import { LoadingButton, PasswordInput } from "@components";
import { LoginParams } from "@generated/apiv1";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export interface Props {
  onSubmit: (attrs: LoginParams & { rememberMe?: boolean }) => void;
  isLoading?: boolean;
  error?: (Partial<ApiException> & { status?: number }) | null;
  children?: ReactNode;
}

export const LoginFormContainer = ({
  onSubmit,
  isLoading = false,
  error,
  children,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [password, setPassword] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [rememberMe, setRememberMe] = useState(true);

  const errorStatusCode = error?.status;

  const handleClick = async () => {
    if (!email || !password) {
      return;
    }
    onSubmit({
      password,
      username: email,
      rememberMe,
    });
  };

  const handleCheckboxClick = (event: any) => {
    setRememberMe(event.target.checked);
  };

  useKey("Enter", handleClick, {}, [email, password, rememberMe]);

  const areFieldsArePresent =
    email && email?.length > 0 && password && password?.length > 0;
  return (
    <Container data-testid="login-form">
      {errorStatusCode && errorStatusCode !== 401 && (
        <StyledAlert severity="error" data-testid="something-wrong-message">
          <Trans
            i18nKey="loginForm.login.somethingWrong"
            values={{ errorStatusCode }}
          >
            Sorry, something went wrong(status: 500). Try again. Please contact
            our support using{" "}
            <Link
              href={`mailto:${t("aboutCompany.support.email")}`}
              underline="hover"
            >
              {t("aboutCompany.support.email")}
            </Link>{" "}
            if this problem persist.
          </Trans>
        </StyledAlert>
      )}

      {errorStatusCode === 401 && (
        <StyledAlert severity="error" data-testid="login-failed-message">
          {t("somethingWrong.loginFailed")}
        </StyledAlert>
      )}

      {!errorStatusCode && error && (
        <StyledAlert severity="error" data-testid="something-wrong-message">
          <div>
            <Trans i18nKey="loginForm.login.somethingWrongWithoutStatus">
              Sorry, something went wrong. Try again. Please contact our support
              using{" "}
              <Link
                href={`mailto:${t("aboutCompany.support.email")}`}
                underline="hover"
              >
                {t("aboutCompany.support.email")}
              </Link>{" "}
              if this problem persist.
            </Trans>
          </div>
          <div>{error.message}</div>
        </StyledAlert>
      )}

      <FormControl fullWidth variant="outlined">
        <StyledTextField
          id="userLogin"
          placeholder={t("domains.loginForm.login.label")}
          label={t("domains.loginForm.login.label")}
          type="email"
          required
          onChange={(event) => setEmail(event.target.value)}
          inputProps={{ "data-testid": "user-login-field" }}
        />
        <PasswordInput
          id="userPassword"
          placeholder={t("domains.loginForm.password.label")}
          label={t("domains.loginForm.password.label")}
          type="password"
          required
          onChange={(event) => setPassword(event.target.value)}
          inputProps={{ "data-testid": "user-password-field" }}
          sx={{
            marginBottom: theme.spacing(4),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid="remember-me-checkbox"
              checked={rememberMe}
              onChange={handleCheckboxClick}
              name="rememberMe"
              color="primary"
            />
          }
          label={t("domains.loginForm.rememberMe.label")}
          componentsProps={{ typography: { variant: "body2" } }}
          sx={{ marginBottom: theme.spacing(2) }}
        />

        <LoadingButton
          type="submit"
          onClick={handleClick}
          color="primary"
          variant="contained"
          disableElevation
          data-testid="submit-user-login"
          disabled={isLoading || !areFieldsArePresent}
        >
          {t("domains.loginForm.submit.label")}
        </LoadingButton>
      </FormControl>

      {children}
    </Container>
  );
};
