import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Alert, Typography, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as Calendar } from "@icons/calendar.svg";
import { Question } from "@components/Wizard/Question";
import { StepContainer } from "@components/Wizard/StepContainer";
import { useFocus } from "../../../../hooks/useFocus";

const AlertContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export interface DateOfTransferStepProps {
  onPrevious: () => void;
  stepNumber: number;
  isFirst: boolean;
  onNext: (value?: Date) => void;
  defaultValue?: Date;
  validationError?: ValidationError;
  focus: boolean;
  questionKey?: string;
  errorMessage?: string;
}

export const DateOfTransferStep = ({
  stepNumber,
  onNext,
  onPrevious,
  isFirst,
  defaultValue,
  validationError,
  focus,
  errorMessage,
}: DateOfTransferStepProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Date | undefined>(
    defaultValue || new Date()
  );
  const inputEl = useFocus(focus);
  const id = "wizard-input";

  return (
    <StepContainer
      testId="date-of-transfer-step"
      onNext={() => onNext(value)}
      onPrevious={onPrevious}
      isFirst={isFirst}
      dependency={value}
    >
      {!!errorMessage && (
        <AlertContainer>
          <Alert severity="error">
            <Typography variant="body2">{errorMessage}</Typography>
          </Alert>
        </AlertContainer>
      )}

      <Question
        htmlFor={id}
        stepNumber={stepNumber}
        question={t("depotTransfer.dateOfTransferStep.question")}
      />
      <DatePicker
        disableFuture
        openTo="year"
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        label={t("depotTransfer.dateOfTransferStep.label")}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!validationError}
            helperText={validationError?.message}
            inputProps={{
              ...params.inputProps,
              "data-testid": "date-input",
            }}
          />
        )}
        components={{
          OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
        }}
        onChange={(date) => {
          setValue(date as any);
        }}
        inputRef={inputEl}
      />
    </StepContainer>
  );
};
