import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Symbol } from "@api";
import { SymbolSearch } from "@features/searchSymbol/components/SymbolSearch";
import { useUpdateInvestment } from "@api/v1/hooks/useUpdateInvestment";
import { GET_INVESTMENT_KEY } from "@api/cacheKeys";
import { LoadingButton } from "@components";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 50%;
  }
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(3, 0)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

export interface Props {
  investmentName: string;
  investmentISIN: string;
  investmentId: number;
}

export const InvestmentEditQuoteData = ({
  investmentName,
  investmentISIN,
  investmentId,
}: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [symbol, setSymbol] = useState<Symbol>();
  const { mutateAsync: update, isLoading } = useUpdateInvestment();

  const handleSaveClick = () => {
    update(
      {
        id: investmentId,
        tickerSymbol: symbol?.id,
        quoteProvider: symbol?.quoteProvider,
      },
      {
        onSuccess: async () => {
          enqueueSnackbar(
            `${t("investmentEditPage.basicTab.saved")}: ${investmentName}`,
            { autoHideDuration: 3000, variant: "success" }
          );
          await queryClient.invalidateQueries([GET_INVESTMENT_KEY]);
        },
      }
    );
  };

  const selectedSymbolText = Boolean(symbol?.name)
    ? `${t("investmentEditPage.quoteDataTab.selectedText")} ${symbol?.name}`
    : t("investmentEditPage.quoteDataTab.nothingSelectedText");

  return (
    <Container>
      <SelectedContainer>
        <Typography variant="body1">{selectedSymbolText}</Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={isLoading || !symbol}
          onClick={handleSaveClick}
          isLoading={isLoading}
        >
          {t("investmentEditPage.quoteDataTab.saveButton")}
        </LoadingButton>
      </SelectedContainer>

      <SymbolSearch
        search={symbol?.name ?? investmentISIN ?? investmentName}
        onSelectSymbol={(value) => setSymbol(value)}
        noManual
      />
    </Container>
  );
};
