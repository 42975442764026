import { Box, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import { toAmericanShort } from "@helpers";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { usePublicOrPrivatePerformance } from "@features/sharedPortfolio/usePublicOrPrivatePerformance";
import { usePublicOrPrivateAccounts } from "@features/sharedPortfolio/hooks/usePublicOrPrivateAccounts";
import { BlurredTypography } from "@components/BlurredTypography";
import { ReactComponent as UserIcon } from "@icons/user.svg";
import { useMyself } from "@generated/user/hooks";

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(4, 0, 1)};
  border-color: ${({ theme }) => theme.appSidebar?.divider};
  width: 100%;
`;

const Name = styled(Typography)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.appSidebar?.contrastText};
`;

export const Profile = () => {
  const { data } = useMyself({
    refetchOnMount: false,
  });
  const { t } = useTranslation();
  const { isSharedPortfolio, isForbidden } = useSharedPortfolioContext();

  const { data: accounts, isLoading } = usePublicOrPrivateAccounts({
    isHidden: false,
  });
  const {
    data: performanceData,
    isFetched,
    isLoading: isTotalAssetsValueLoading,
  } = usePublicOrPrivatePerformance({
    accountIds: accounts?.map((account) => account.id),
    cashFlowAndPerformanceStatisticsParamsList: [
      {
        includeCash: true,
        includeDividends: true,
      },
    ],
  });
  const totalAssetsValue =
    performanceData[0]?.twrorResults[
      performanceData[0]?.twrorResults.length - 1
    ]?.currentMarketValue ?? 0;

  if (isSharedPortfolio && isForbidden) {
    return null;
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap={4.5} ml={3.5} pt={2.5}>
        <UserIcon style={{ marginLeft: "16px" }} />
        <Box>
          {isSharedPortfolio ? (
            <Box
              sx={{
                filter: `blur(5px)`,
              }}
            >
              <Name>Noname</Name>
            </Box>
          ) : data?.displayName ? (
            <Name variant="body2">{data?.displayName}</Name>
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
          <Box display="inline-flex" gap={1} width="100%">
            {isLoading || !isFetched || isTotalAssetsValueLoading ? (
              <Skeleton variant="text" width="100%" />
            ) : (
              <Box display="inline-flex" gap={1}>
                <Typography variant="caption" color="secondary.light">
                  {t("sidebar.menu.assets")}
                </Typography>
                {isSharedPortfolio ? (
                  <BlurredTypography
                    variant="caption"
                    color="secondary.light"
                    blur={5}
                  >
                    {toAmericanShort(totalAssetsValue, 0, 2)} €
                  </BlurredTypography>
                ) : (
                  <Typography variant="caption" color="secondary.light">
                    {toAmericanShort(totalAssetsValue, 0, 2)} €
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box px={4}>
        <StyledDivider />
      </Box>
    </>
  );
};
