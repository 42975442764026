import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useKey } from "react-use";
import { Box, useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RHFTextField } from "@components";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useChangeDiscordId } from "@generated/user/hooks";
import { ReactComponent as TagPlus } from "@icons/tag_plus.svg";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  title: "settingsPage.profile.modal.discordId.title",
  subtitle: "settingsPage.profile.modal.discordId.subtitle",
  confirmButton: "settingsPage.profile.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsChangeDiscordIdModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const {
    mutate: changeDiscordId,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useChangeDiscordId({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.USERS_MYSELF],
      });
    },
  });

  const schema = Yup.object().shape({
    discordId: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, setValue, watch } = useForm<{
    discordId: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      discordId: "",
    },
  });

  const isConfirmBtnDisabled = !watch("discordId");

  const handleConfirm = () => {
    const values = getValues();
    changeDiscordId({
      newDiscordId: values.discordId,
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, 500);
  };

  useKey(
    "Enter",
    () => {
      if (!isSuccess && !isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isSuccess, isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  useEffect(() => {
    setValue("discordId", "");
  }, [isOpen, setValue]);

  return (
    <SettingsModalWrapper
      {...modalData}
      successTitle={t("settingsPage.profile.modal.success.title", {
        type: t("settingsPage.profile.labels.discordId"),
      })}
      showSuccess={isSuccess}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit(handleConfirm)}
      isConfirmBtnDisabled={isConfirmBtnDisabled}
      isConfirmButtonLoading={isLoading}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
        />
      )}
      <RHFTextField
        name="discordId"
        label={
          <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
            {t("settingsPage.profile.modal.discordId.label")} <TagPlus />
          </Box>
        }
        control={control}
        fullWidth
        type="text"
      />
    </SettingsModalWrapper>
  );
};
