import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID!,
};

export const initGTM = () => {
  if (!window.location.pathname.includes("web-form")) {
    TagManager.initialize(tagManagerArgs);
  }
};
