import { UseMutationOptions } from "react-query";
import { RolesApiDeleteRoleAssignmentRequest } from "@generated/apiv1";

import { buildRolesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteRoleAssignment = async (
  token: string | undefined,
  params: RolesApiDeleteRoleAssignmentRequest
) => {
  const apiInstance = buildRolesApi(token);
  const { data } = await apiInstance.deleteRoleAssignment(params);
  return data;
};

export const useDeleteRoleAssignment = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    RolesApiDeleteRoleAssignmentRequest
  >
) => {
  return useAuthMutation(deleteRoleAssignment, options, false);
};
