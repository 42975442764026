import { UseMutationOptions } from "react-query";
import { SplitsApiCrateSplitRequest, Split } from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const crateSplit = async (
  token: string | undefined,
  params: SplitsApiCrateSplitRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.crateSplit(params);
  return data;
};

export const useCrateSplit = (
  options?: UseMutationOptions<Split, AxiosApiError, SplitsApiCrateSplitRequest>
) => {
  return useAuthMutation(crateSplit, options, false);
};
