import { UseQueryOptions } from "react-query";
import { GetTagsParams, GetTagsResponse } from "@api/v1/model";
import { getTags } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { GET_TAGS_KEY } from "../../cacheKeys";

export const useTags = (
  params?: GetTagsParams,
  options: UseQueryOptions<GetTagsResponse, AxiosApiError, GetTagsResponse> = {
    enabled: true,
  }
) =>
  useAuthQuery([GET_TAGS_KEY], ({ token }) => getTags(token, params), options);
