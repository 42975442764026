import { UseMutationOptions } from "react-query";
import {
  PlugAndPlayApiRecommendProductsRequest,
  RecommendProductsResponse,
} from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const recommendProducts = async (
  token: string | undefined,
  params?: PlugAndPlayApiRecommendProductsRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.recommendProducts(params);
  return data;
};

export const useRecommendProducts = (
  options?: UseMutationOptions<
    RecommendProductsResponse,
    AxiosApiError,
    PlugAndPlayApiRecommendProductsRequest
  >
) => {
  return useAuthMutation(recommendProducts, options, false);
};
