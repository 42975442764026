import { UseMutationOptions } from "react-query";
import { SplitsApiImportSplitByIsinRequest, SplitList } from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const importSplitByIsin = async (
  token: string | undefined,
  params: SplitsApiImportSplitByIsinRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.importSplitByIsin(params);
  return data;
};

export const useImportSplitByIsin = (
  options?: UseMutationOptions<
    SplitList,
    AxiosApiError,
    SplitsApiImportSplitByIsinRequest
  >
) => {
  return useAuthMutation(importSplitByIsin, options, false);
};
