import { UseQueryOptions } from "react-query";
import {
  PerformanceApiRealizedGainsPerYearRequest,
  RealizedGainByYearList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const realizedGainsPerYear = async (
  token: string | undefined,
  params: PerformanceApiRealizedGainsPerYearRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.realizedGainsPerYear(params);
  return data;
};

export const useRealizedGainsPerYear = (
  params: PerformanceApiRealizedGainsPerYearRequest,
  options?: UseQueryOptions<
    RealizedGainByYearList,
    AxiosApiError,
    RealizedGainByYearList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_REALIZED_GAINS_PER_YEAR, params],
    ({ token }) => realizedGainsPerYear(token, params),
    options
  );
};
