import {
  SnackbarContent,
  SnackbarProvider as NotistackProvider,
  CustomContentProps,
} from "notistack";
import { styled, Theme, Typography, useMediaQuery } from "@mui/material";
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactComponent as ErrorIcon } from "@icons/myicons/error-x.svg";

const StyledSnackbarContent = styled(SnackbarContent, {
  shouldForwardProp: (prop) => prop !== "isAppBarVisible" && prop !== "variant",
})<{
  isAppBarVisible: boolean;
  variant: "error" | "default" | "success" | "warning" | "info";
}>(({ theme, isAppBarVisible, variant }) => ({
  borderRadius: "8px",
  flexWrap: "nowrap",
  backgroundColor:
    variant === "error"
      ? theme.palette.error.main
      : theme.palette.background.tooltip,
  color:
    variant === "error"
      ? theme.palette.common.white
      : theme.palette.text.tooltipPrimary,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(4),
  zIndex: 10000,

  [theme.breakpoints.down("sm")]: {
    marginBottom: isAppBarVisible ? theme.spacing(11) : theme.spacing(-2),
    maxWidth: "250px",
    alignItems: "flex-start",
  },

  "& svg": {
    marginRight: theme.spacing(2.5),
    color: theme.palette.error.main,
  },
}));

const Wrapper = styled("div")`
  .notistack-SnackbarContainer {
    z-index: 4002;
  }
`;

interface SnackbarProps extends CustomContentProps {
  isAppBarVisible: boolean;
}

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => {
  const { id, variant, message, ...rest } = props;

  return (
    <StyledSnackbarContent
      data-testid={`snackbar-message-${variant}`}
      ref={ref}
      role="alert"
      variant={variant}
      {...rest}
    >
      <Typography
        variant="body1"
        fontSize="18px"
        lineHeight="28px"
        display="flex"
        alignItems="flex-start"
      >
        <span>
          {variant === "error" && (
            <ErrorIcon
              style={{
                width: "18px",
                height: "18px",
                minWidth: "18px",
                minHeight: "18px",
                marginBottom: "-2px",
              }}
            />
          )}
          {message}
        </span>
      </Typography>
    </StyledSnackbarContent>
  );
});

export const SnackbarProvider = ({ children }: { children?: ReactNode }) => {
  const [isAppBarVisible, setIsAppBarVisible] = useState(false);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const intersection = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) =>
          entry.isIntersecting
            ? setIsAppBarVisible(true)
            : setIsAppBarVisible(false)
        );
      },
      { root: null, threshold: 0.1 }
    );
  }, []);

  useEffect(() => {
    const appBar = document.querySelector("#app-bar");
    if (appBar instanceof Element) {
      intersection.observe(appBar);
    } else {
      setIsAppBarVisible(false);
    }
  }, [intersection, isLessSm]);

  return (
    <Wrapper>
      <NotistackProvider
        maxSnack={3}
        Components={{
          success: forwardRef((props, ref) => (
            <Snackbar ref={ref} {...props} isAppBarVisible={isAppBarVisible} />
          )),
          error: forwardRef((props, ref) => (
            <Snackbar
              ref={ref}
              {...props}
              isAppBarVisible={isAppBarVisible}
              variant="error"
            />
          )),
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isLessSm ? "left" : "center",
        }}
        // @ts-ignore
        domRoot={document.querySelector("body")}
        classes={{
          containerRoot: "snackbar-container",
        }}
      >
        {children}
      </NotistackProvider>
    </Wrapper>
  );
};
