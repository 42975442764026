import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MultibankingApiProvider } from "@api";
import { BicSelect } from "./BicSelect";
import { BankSelectView } from "./BankSelectView";
import { NotSupported } from "./notSupported";
import { GroupedBank, ShortBank } from "./utils";

const Container = styled.div`
  display: flex;
`;

const variants = {
  initial: (direction: number) => ({
    opacity: 0,
    x: direction ? "300px" : "-300px",
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  out: (direction: number) => ({
    opacity: 0,
    x: direction ? "-300px" : "300px",
  }),
};

const transition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.3,
};

interface Props {
  defaultSearchValue?: string;
  onSelect: (
    id: number,
    multibankingApiProvider: MultibankingApiProvider,
    wealthApiSlug: string | undefined
  ) => void;
  onSearchChange?: (value: string) => void;
  searchValue: string;
  className?: string;
  onChange?: (value: string) => void;
  banks: GroupedBank[];
  isLoading: boolean;
  isIdle: boolean;
}

export const BankSelect = ({
  onSelect,
  className,
  banks,
  isIdle,
  isLoading,
  searchValue,
  onChange,
}: Props) => {
  const [selectedBank, setSelectedBank] = useState<GroupedBank | undefined>();
  const [direction, setDirection] = useState(true);
  const [showNotSupported, setShowNotSupported] = useState(false);
  const [showBicSelect, setShowBicSelect] = useState(false);

  const { t } = useTranslation();

  const handleBicSelect = (bankByBic: ShortBank) => {
    if (!bankByBic.isSupported) {
      setDirection(true);
      setShowNotSupported(true);
      return;
    }
    onSelect(bankByBic.id, bankByBic.multibankingApiProvider, bankByBic.slug);
  };

  const handleSelect = (groupedBank: GroupedBank) => {
    setSelectedBank(groupedBank);
    if (groupedBank.banks.length === 1) {
      handleBicSelect(groupedBank.banks[0]);
      return;
    }
    setShowBicSelect(true);
    setDirection(true);
  };

  const handleBicSelectCancel = () => {
    setShowBicSelect(false);
    setDirection(false);
    setSelectedBank(undefined);
  };

  const handleBackFromNotSupported = () => {
    setDirection(false);
    setShowNotSupported(false);
  };

  return (
    <Container className={className}>
      <Helmet>
        <title>{t("bankSelect.pageTitle")}</title>
      </Helmet>
      <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
        {!showBicSelect && !showNotSupported && (
          <motion.div
            custom={direction}
            key="bank-select"
            initial="initial"
            animate="in"
            exit="out"
            variants={variants}
            transition={transition}
          >
            <BankSelectView
              isIdle={isIdle}
              isLoading={isLoading}
              banks={banks}
              onChange={onChange}
              searchValue={searchValue}
              onSelect={handleSelect}
            />
          </motion.div>
        )}
        {selectedBank && !showNotSupported && showBicSelect && (
          <motion.div
            custom={direction}
            key="bic-select"
            initial="initial"
            animate="in"
            exit="out"
            variants={variants}
            transition={transition}
            style={{ width: "100%" }}
          >
            <BicSelect
              className="bicSelect"
              banks={selectedBank.banks || []}
              onSelect={handleBicSelect}
              onCancel={handleBicSelectCancel}
            />
          </motion.div>
        )}
        {showNotSupported && selectedBank && (
          <motion.div
            custom={direction}
            key="not-supported"
            initial="initial"
            animate="in"
            exit="out"
            variants={variants}
            transition={transition}
          >
            <NotSupported
              className="notSupported"
              bankName={selectedBank?.name}
              iconUrl={selectedBank?.iconUrl}
              onBack={handleBackFromNotSupported}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};
