/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ApplicationProperty } from '../model';
// @ts-ignore
import { ApplicationPropertyList } from '../model';
// @ts-ignore
import { CreateOrUpdateApplicationPropertyParams } from '../model';
/**
 * ApplicationPropertiesApi - axios parameter creator
 * @export
 */
export const ApplicationPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an application property.
         * @param {CreateOrUpdateApplicationPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationProperty: async (body: CreateOrUpdateApplicationPropertyParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createApplicationProperty', 'body', body)
            const localVarPath = `/v1/applicationProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an application property.
         * @param {number} id Update the given application property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationProperty: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicationProperty', 'id', id)
            const localVarPath = `/v1/applicationProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an application property.
         * @param {number} id Retrieve the given application property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationProperty: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationProperty', 'id', id)
            const localVarPath = `/v1/applicationProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search application properties.
         * @param {Array<number>} [id] Retrieve application properties with the given IDs.
         * @param {Array<string>} [propertyName] Retrieve the application properties with the given property names.
         * @param {Array<string>} [parentPropertyName] Retrieve the application properties with the given parent property names.
         * @param {string} [isParent] Retrieve only parent categories if true, only child categories if false. Default is                              undefined (all categories).
         * @param {string} [validFrom] A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              from date that equals null or is after or equal the given date.
         * @param {string} [validTo] A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              to date that equals null or is before or equal the given date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllApplicationProperties: async (id?: Array<number>, propertyName?: Array<string>, parentPropertyName?: Array<string>, isParent?: string, validFrom?: string, validTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/applicationProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (propertyName) {
                localVarQueryParameter['propertyName'] = propertyName;
            }

            if (parentPropertyName) {
                localVarQueryParameter['parentPropertyName'] = parentPropertyName;
            }

            if (isParent !== undefined) {
                localVarQueryParameter['isParent'] = isParent;
            }

            if (validFrom !== undefined) {
                localVarQueryParameter['validFrom'] = validFrom;
            }

            if (validTo !== undefined) {
                localVarQueryParameter['validTo'] = validTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an application property.
         * @param {number} id Update the given application property.
         * @param {CreateOrUpdateApplicationPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationProperty: async (id: number, body: CreateOrUpdateApplicationPropertyParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationProperty', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateApplicationProperty', 'body', body)
            const localVarPath = `/v1/applicationProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationPropertiesApi - functional programming interface
 * @export
 */
export const ApplicationPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an application property.
         * @param {CreateOrUpdateApplicationPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationProperty(body: CreateOrUpdateApplicationPropertyParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplicationProperty(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationPropertiesApi.createApplicationProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an application property.
         * @param {number} id Update the given application property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationProperty(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationProperty(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationPropertiesApi.deleteApplicationProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an application property.
         * @param {number} id Retrieve the given application property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationProperty(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationProperty(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationPropertiesApi.getApplicationProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search application properties.
         * @param {Array<number>} [id] Retrieve application properties with the given IDs.
         * @param {Array<string>} [propertyName] Retrieve the application properties with the given property names.
         * @param {Array<string>} [parentPropertyName] Retrieve the application properties with the given parent property names.
         * @param {string} [isParent] Retrieve only parent categories if true, only child categories if false. Default is                              undefined (all categories).
         * @param {string} [validFrom] A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              from date that equals null or is after or equal the given date.
         * @param {string} [validTo] A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              to date that equals null or is before or equal the given date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllApplicationProperties(id?: Array<number>, propertyName?: Array<string>, parentPropertyName?: Array<string>, isParent?: string, validFrom?: string, validTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPropertyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllApplicationProperties(id, propertyName, parentPropertyName, isParent, validFrom, validTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationPropertiesApi.listAndSearchAllApplicationProperties']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an application property.
         * @param {number} id Update the given application property.
         * @param {CreateOrUpdateApplicationPropertyParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationProperty(id: number, body: CreateOrUpdateApplicationPropertyParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationProperty(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationPropertiesApi.updateApplicationProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApplicationPropertiesApi - factory interface
 * @export
 */
export const ApplicationPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an application property.
         * @param {ApplicationPropertiesApiCreateApplicationPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationProperty(requestParameters: ApplicationPropertiesApiCreateApplicationPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApplicationProperty> {
            return localVarFp.createApplicationProperty(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an application property.
         * @param {ApplicationPropertiesApiDeleteApplicationPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationProperty(requestParameters: ApplicationPropertiesApiDeleteApplicationPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApplicationProperty(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an application property.
         * @param {ApplicationPropertiesApiGetApplicationPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationProperty(requestParameters: ApplicationPropertiesApiGetApplicationPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApplicationProperty> {
            return localVarFp.getApplicationProperty(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search application properties.
         * @param {ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllApplicationProperties(requestParameters: ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApplicationPropertyList> {
            return localVarFp.listAndSearchAllApplicationProperties(requestParameters.id, requestParameters.propertyName, requestParameters.parentPropertyName, requestParameters.isParent, requestParameters.validFrom, requestParameters.validTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an application property.
         * @param {ApplicationPropertiesApiUpdateApplicationPropertyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationProperty(requestParameters: ApplicationPropertiesApiUpdateApplicationPropertyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApplicationProperty> {
            return localVarFp.updateApplicationProperty(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createApplicationProperty operation in ApplicationPropertiesApi.
 * @export
 * @interface ApplicationPropertiesApiCreateApplicationPropertyRequest
 */
export interface ApplicationPropertiesApiCreateApplicationPropertyRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateApplicationPropertyParams}
     * @memberof ApplicationPropertiesApiCreateApplicationProperty
     */
    readonly body: CreateOrUpdateApplicationPropertyParams
}

/**
 * Request parameters for deleteApplicationProperty operation in ApplicationPropertiesApi.
 * @export
 * @interface ApplicationPropertiesApiDeleteApplicationPropertyRequest
 */
export interface ApplicationPropertiesApiDeleteApplicationPropertyRequest {
    /**
     * Update the given application property.
     * @type {number}
     * @memberof ApplicationPropertiesApiDeleteApplicationProperty
     */
    readonly id: number
}

/**
 * Request parameters for getApplicationProperty operation in ApplicationPropertiesApi.
 * @export
 * @interface ApplicationPropertiesApiGetApplicationPropertyRequest
 */
export interface ApplicationPropertiesApiGetApplicationPropertyRequest {
    /**
     * Retrieve the given application property.
     * @type {number}
     * @memberof ApplicationPropertiesApiGetApplicationProperty
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllApplicationProperties operation in ApplicationPropertiesApi.
 * @export
 * @interface ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest
 */
export interface ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest {
    /**
     * Retrieve application properties with the given IDs.
     * @type {Array<number>}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly id?: Array<number>

    /**
     * Retrieve the application properties with the given property names.
     * @type {Array<string>}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly propertyName?: Array<string>

    /**
     * Retrieve the application properties with the given parent property names.
     * @type {Array<string>}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly parentPropertyName?: Array<string>

    /**
     * Retrieve only parent categories if true, only child categories if false. Default is                              undefined (all categories).
     * @type {string}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly isParent?: string

    /**
     * A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              from date that equals null or is after or equal the given date.
     * @type {string}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly validFrom?: string

    /**
     * A date in format yyyy-MM-dd (US Format). Only retrieve application properties with a valid                              to date that equals null or is before or equal the given date.
     * @type {string}
     * @memberof ApplicationPropertiesApiListAndSearchAllApplicationProperties
     */
    readonly validTo?: string
}

/**
 * Request parameters for updateApplicationProperty operation in ApplicationPropertiesApi.
 * @export
 * @interface ApplicationPropertiesApiUpdateApplicationPropertyRequest
 */
export interface ApplicationPropertiesApiUpdateApplicationPropertyRequest {
    /**
     * Update the given application property.
     * @type {number}
     * @memberof ApplicationPropertiesApiUpdateApplicationProperty
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateApplicationPropertyParams}
     * @memberof ApplicationPropertiesApiUpdateApplicationProperty
     */
    readonly body: CreateOrUpdateApplicationPropertyParams
}

/**
 * ApplicationPropertiesApi - object-oriented interface
 * @export
 * @class ApplicationPropertiesApi
 * @extends {BaseAPI}
 */
export class ApplicationPropertiesApi extends BaseAPI {
    /**
     * 
     * @summary Create an application property.
     * @param {ApplicationPropertiesApiCreateApplicationPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationPropertiesApi
     */
    public createApplicationProperty(requestParameters: ApplicationPropertiesApiCreateApplicationPropertyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationPropertiesApiFp(this.configuration).createApplicationProperty(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an application property.
     * @param {ApplicationPropertiesApiDeleteApplicationPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationPropertiesApi
     */
    public deleteApplicationProperty(requestParameters: ApplicationPropertiesApiDeleteApplicationPropertyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationPropertiesApiFp(this.configuration).deleteApplicationProperty(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an application property.
     * @param {ApplicationPropertiesApiGetApplicationPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationPropertiesApi
     */
    public getApplicationProperty(requestParameters: ApplicationPropertiesApiGetApplicationPropertyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationPropertiesApiFp(this.configuration).getApplicationProperty(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search application properties.
     * @param {ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationPropertiesApi
     */
    public listAndSearchAllApplicationProperties(requestParameters: ApplicationPropertiesApiListAndSearchAllApplicationPropertiesRequest = {}, options?: RawAxiosRequestConfig) {
        return ApplicationPropertiesApiFp(this.configuration).listAndSearchAllApplicationProperties(requestParameters.id, requestParameters.propertyName, requestParameters.parentPropertyName, requestParameters.isParent, requestParameters.validFrom, requestParameters.validTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an application property.
     * @param {ApplicationPropertiesApiUpdateApplicationPropertyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationPropertiesApi
     */
    public updateApplicationProperty(requestParameters: ApplicationPropertiesApiUpdateApplicationPropertyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationPropertiesApiFp(this.configuration).updateApplicationProperty(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

