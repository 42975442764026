import React from "react";
import { Box, useTheme } from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "@icons/chevron-down.svg";

export const ExpandMore = ({ disabled }: { disabled?: boolean }) => {
  const theme = useTheme();
  return (
    <Box
      color={
        disabled ? theme.palette.icon.disabled : theme.palette.icon.primary
      }
      display="flex"
      alignItems="center"
    >
      <ExpandMoreIcon fontSize="small" />
    </Box>
  );
};
