import { UseQueryOptions } from "react-query";
import {
  MandatorInvoicesApiMandatorsIdInvoicingRequest,
  MandatorInvoice,
} from "@generated/apiv1";

import { buildMandatorInvoicesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const mandatorsIdInvoicing = async (
  token: string | undefined,
  params: MandatorInvoicesApiMandatorsIdInvoicingRequest
) => {
  const apiInstance = buildMandatorInvoicesApi(token);
  const { data } = await apiInstance.mandatorsIdInvoicing(params);
  return data;
};

export const useMandatorsIdInvoicing = (
  params: MandatorInvoicesApiMandatorsIdInvoicingRequest,
  options?: UseQueryOptions<MandatorInvoice, AxiosApiError, MandatorInvoice>
) => {
  return useAuthQuery(
    [CACHE_KEYS.MANDATORINVOICES_MANDATORS_ID_INVOICING, params],
    ({ token }) => mandatorsIdInvoicing(token, params),
    options
  );
};
