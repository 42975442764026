import { UseMutationOptions } from "react-query";
import {
  SplitsApiApplySplitsForDepotRequest,
  SplitList,
} from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const applySplitsForDepot = async (
  token: string | undefined,
  params: SplitsApiApplySplitsForDepotRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.applySplitsForDepot(params);
  return data;
};

export const useApplySplitsForDepot = (
  options?: UseMutationOptions<
    SplitList,
    AxiosApiError,
    SplitsApiApplySplitsForDepotRequest
  >
) => {
  return useAuthMutation(applySplitsForDepot, options, false);
};
