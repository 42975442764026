import { UseMutationOptions } from "react-query";

import { buildQualityAssuranceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteAccountsForMockBankConnection = async (
  token: string | undefined
) => {
  const apiInstance = buildQualityAssuranceApi(token);
  const { data } = await apiInstance.deleteAccountsForMockBankConnection();
  return data;
};

export const useDeleteAccountsForMockBankConnection = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(deleteAccountsForMockBankConnection, options, false);
};
