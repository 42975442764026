import React from "react";
import { format } from "date-fns";
import { Box, Typography } from "@mui/material";
import { toGermanNumberFormat } from "@helpers";
import { InvestmentChartData } from "@components/Chart/InvestmentChart/types";
import { CustomTooltipContainer, Item } from "@components";
import { EventItem } from "./EventItem";

interface Props {
  active: boolean;
  label: string;
  ref: React.RefObject<HTMLDivElement>;
  payload: Partial<{
    dataKey:
      | "eventsValue"
      | "value"
      | "originalValue"
      | "areaValue"
      | "comparedValue"
      | "comparedOriginalValue";
    name: string;
    value: number;
    color: string;
    payload: InvestmentChartData;
  }>[];
  isComparison: boolean;
  comparedValueLabel?: string;
  mainValueLabel?: string;
}

export const CustomTooltip = React.forwardRef<HTMLDivElement, Partial<Props>>(
  (
    {
      active,
      payload = [],
      isComparison = false,
      comparedValueLabel,
      mainValueLabel,
    },
    ref
  ) => {
    if (!active || !payload) return null;
    const chartPayload = payload.find((p) => p.dataKey === "value");
    const eventsPayload = payload.find(
      (p) => p.dataKey === "eventsValue"
    )?.payload;

    if (!chartPayload || !chartPayload?.payload) return null;
    const secondaryPayload = payload.find((p) => p.dataKey === "comparedValue");

    const primaryValueKey = isComparison ? "originalValue" : "value";
    const comparedValueKey = isComparison
      ? "comparedOriginalValue"
      : "comparedValue";

    return (
      <CustomTooltipContainer
        ref={ref}
        firstRow={
          <Item
            isStrong
            color={chartPayload.color}
            title={toGermanNumberFormat(
              chartPayload.payload
                ? chartPayload.payload[primaryValueKey] ?? 0
                : 0
            )}
            subtitle={
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {format(chartPayload.payload?.date, "d.M.yyyy")}
                </Typography>
                {mainValueLabel && (
                  <Typography variant="caption" color="textSecondary">
                    {mainValueLabel}
                  </Typography>
                )}
              </Box>
            }
          />
        }
      >
        {!!secondaryPayload && (
          <Item
            isStrong
            color={secondaryPayload.color}
            title={toGermanNumberFormat(
              secondaryPayload.payload
                ? secondaryPayload.payload[comparedValueKey] ?? 0
                : 0
            )}
            subtitle={comparedValueLabel}
          />
        )}
        {eventsPayload?.events?.map((event, index) => (
          <EventItem key={`event-${index}`} event={event} />
        ))}
      </CustomTooltipContainer>
    );
  }
);
