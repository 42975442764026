import { UseQueryOptions } from "react-query";
import {
  DividendsApiGetdividendTypeDividendRequest,
  DividendResponse,
} from "@generated/apiv3/quotes";

import { buildDividendsApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getdividendTypeDividend = async (
  token: string | undefined,
  params: DividendsApiGetdividendTypeDividendRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.getdividendTypeDividend(params);
  return data;
};

export const useGetdividendTypeDividend = (
  params: DividendsApiGetdividendTypeDividendRequest,
  options?: UseQueryOptions<DividendResponse, AxiosApiError, DividendResponse>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_GETDIVIDEND_TYPE_DIVIDEND, params],
    ({ token }) => getdividendTypeDividend(token, params),
    options
  );
};
