import { useMemo } from "react";
import { useListAndSearchAllAccounts } from "@generated/apiv1/hooks";
import { GroupedBanks } from "./types";
import {
  useGetFinApiBankConnections,
  useGetWealthApiBankConnections,
} from "./useSettings";

export const useBankConnections = () => {
  const { data: accounts } = useListAndSearchAllAccounts();
  const wealthApiConnectionIds = accounts?.accounts?.reduce(
    (acc, account) =>
      account.bankConnectionType === "WEALTH_API" &&
      account.bankConnectionId &&
      !acc.includes(account.bankConnectionId)
        ? [...acc, account.bankConnectionId]
        : acc,
    [] as number[]
  );
  const finApiConnectionIds = accounts?.accounts?.reduce(
    (acc, account) =>
      account.bankConnectionType === "FIN_API" && account.bankConnectionId
        ? [...acc, account.bankConnectionId]
        : acc,
    [] as number[]
  );

  const { data: finApiBankConnections } = useGetFinApiBankConnections(
    {
      id: finApiConnectionIds,
    },
    {
      enabled: Boolean(finApiConnectionIds?.length),
    }
  );
  const { data: wealthApiBankConnections } = useGetWealthApiBankConnections(
    {
      filterId: (wealthApiConnectionIds || []).join(","),
    },
    {
      enabled: Boolean(wealthApiConnectionIds?.length),
    }
  );

  return {
    finApiBankConnections: finApiBankConnections || [],
    wealthApiBankConnections: wealthApiBankConnections || [],
  };
};

export const useBankConnectionsData = () => {
  const { data: accounts, isLoading } = useListAndSearchAllAccounts();
  const bankGroups = useMemo(
    () =>
      accounts?.accounts?.reduce<GroupedBanks>(
        (acc, account) => {
          const bankConnectionId = account.bankConnectionId;
          const bankName = account.bankName;
          const provider = account.bankConnectionType;
          if (!bankName || !bankConnectionId || provider === "NETFONDS")
            return acc;

          let existingEntry = acc.accounts.find(
            (entry) => entry.id === bankConnectionId
          );
          if (!existingEntry) {
            existingEntry = {
              bankName: bankName ?? "",
              id: bankConnectionId!,
              provider: provider ?? "FIN_API",
              accounts: [],
            };
            acc.accounts.push(existingEntry);
          }
          existingEntry?.accounts.push(account);

          if (
            provider === "FIN_API" &&
            acc.finIds.indexOf(bankConnectionId) === -1
          ) {
            acc.finIds.push(bankConnectionId);
          } else if (
            provider === "WEALTH_API" &&
            acc.wealthIds.indexOf(bankConnectionId) === -1
          ) {
            acc.wealthIds.push(bankConnectionId);
          }

          return acc;
        },
        { accounts: [], finIds: [], wealthIds: [] } as GroupedBanks
      ),
    [accounts?.accounts]
  );

  const { data: finApiBankConnections, isLoading: isLoadingFinConnections } =
    useGetFinApiBankConnections(
      {
        id: bankGroups?.finIds,
      },
      {
        enabled: Boolean(bankGroups?.finIds?.length),
      }
    );
  const {
    data: wealthApiBankConnections,
    isLoading: isLoadingWealthApiConnections,
  } = useGetWealthApiBankConnections(
    {
      filterId: (bankGroups?.wealthIds || []).join(","),
    },
    {
      enabled: Boolean(bankGroups?.wealthIds?.length),
    }
  );

  const filteredAccounts = useMemo(() => {
    const availableIds = [
      ...(finApiBankConnections?.map((x) => Number(x.id)) || []),
      ...(wealthApiBankConnections?.map((x) => Number(x.id)) || []),
    ];
    return bankGroups?.accounts
      .filter((x) => availableIds.includes(x.id))
      .map((x) => {
        const connections =
          x.provider === "FIN_API"
            ? finApiBankConnections
            : wealthApiBankConnections;
        const bankConnection = connections?.find((y) => x.id === +y.id);
        return {
          ...x,
          bankId: bankConnection?.bankId,
        };
      });
  }, [bankGroups, finApiBankConnections, wealthApiBankConnections]);

  return {
    data: {
      ...bankGroups,
      accounts: filteredAccounts || [],
    },
    isLoading:
      isLoading || isLoadingFinConnections || isLoadingWealthApiConnections,
  };
};
