import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request,
  BankSynchronizationProcess,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const webFormFlowAddOrUpdateBankConnectionV2 = async (
  token: string | undefined,
  params: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.webFormFlowAddOrUpdateBankConnectionV2(
    params
  );
  return data;
};

export const useWebFormFlowAddOrUpdateBankConnectionV2 = (
  options?: UseMutationOptions<
    BankSynchronizationProcess,
    AxiosApiError,
    BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionV2Request
  >
) => {
  return useAuthMutation(
    webFormFlowAddOrUpdateBankConnectionV2,
    options,
    false
  );
};
