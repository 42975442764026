import { UseQueryOptions } from "react-query";
import {
  SocialStatisticsApiGetTopHoldingsRequest,
  SocialStatisticsTopHoldingsResponse,
} from "@generated/apiv3";

import { buildSocialStatisticsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getTopHoldings = async (
  token: string | undefined,
  params?: SocialStatisticsApiGetTopHoldingsRequest
) => {
  const apiInstance = buildSocialStatisticsApi(token);
  const { data } = await apiInstance.getTopHoldings(params);
  return data;
};

export const useGetTopHoldings = (
  params?: SocialStatisticsApiGetTopHoldingsRequest,
  options?: UseQueryOptions<
    SocialStatisticsTopHoldingsResponse,
    AxiosApiError,
    SocialStatisticsTopHoldingsResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SOCIALSTATISTICS_GET_TOP_HOLDINGS, params],
    ({ token }) => getTopHoldings(token, params),
    options
  );
};
