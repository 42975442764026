/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateCustomFeeAgreementParams
 */
export interface CreateOrUpdateCustomFeeAgreementParams {
    /**
     * The agreement type; required for creation
     * @type {string}
     * @memberof CreateOrUpdateCustomFeeAgreementParams
     */
    'type'?: CreateOrUpdateCustomFeeAgreementParamsTypeEnum;
    /**
     * The value (in percent; 1 = 100%); required for creation; must be greater then 0.5 which                                is equal to Cashback-50
     * @type {number}
     * @memberof CreateOrUpdateCustomFeeAgreementParams
     */
    'value'?: number;
    /**
     * Does the custom fee agreement have an upper bound. The rentablo fee will not raise         above the upper bound; required for creation
     * @type {boolean}
     * @memberof CreateOrUpdateCustomFeeAgreementParams
     */
    'hasUpperBound'?: boolean;
    /**
     * The upper bound for the custom fee agreement; ignored if hasUpperBound is false.         If not given, the CASHBACK_100 fee will be considered as the upper bound
     * @type {number}
     * @memberof CreateOrUpdateCustomFeeAgreementParams
     */
    'upperBound'?: number;
    /**
     * Start of the agreement; optional
     * @type {string}
     * @memberof CreateOrUpdateCustomFeeAgreementParams
     */
    'startsAt'?: string;
}

export const CreateOrUpdateCustomFeeAgreementParamsTypeEnum = {
    CASHBACK: 'cashback'
} as const;

export type CreateOrUpdateCustomFeeAgreementParamsTypeEnum = typeof CreateOrUpdateCustomFeeAgreementParamsTypeEnum[keyof typeof CreateOrUpdateCustomFeeAgreementParamsTypeEnum];


