import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import {
  Typography,
  FormControlLabel,
  Switch,
  Alert,
  TextField,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as Calendar } from "@icons/calendar.svg";
import { isDateValid } from "@helpers";
import { useHelpers } from "../hooks/useHelpers";
import { SplitValues } from "../stateMachine/types/splitValues";
import { StepContainer } from "../StepContainer";
import { InputFieldWithRevert } from "../components/NumberInputFieldWithRevert";

const Container = styled.div`
  width: 100%;
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export interface Props {
  handleNext: (splitValues: SplitValues) => void;
  handlePrevious: () => void;
  splitValues: SplitValues;
  investmentName: string;
  validationError?: string;
}

export const RatioAndDateStep = ({
  handleNext,
  handlePrevious,
  splitValues,
  investmentName,
  validationError,
}: Props) => {
  const { t } = useTranslation();
  const { toString, parseOrUndefined } = useHelpers();

  const [values, setValues] = useState<SplitValues>(splitValues);
  const [ratio, setRatio] = useState<string>(toString(splitValues.ratio));
  const [date, setDate] = useState<Date>(splitValues.date || new Date());

  const [isReverse, setIsReverse] = useState<boolean>(
    splitValues.isReverse ? splitValues.isReverse : false
  );

  const onNextDisabled =
    !isDateValid(values.date) ||
    !values.ratio ||
    values.ratio < 1 ||
    values.isReverse == null;

  const handleRatioChange = (value: string) => {
    const ratio = parseOrUndefined(value);
    setRatio(toString(ratio, value));
    setValues((current: SplitValues) => {
      return { ...current, ratio };
    });
  };

  const handleDateChange = (date?: Date | null) => {
    if (!date) return;
    setDate(date);
    setValues((current: SplitValues) => {
      return { ...current, date };
    });
  };

  const handleIsReverseChange = () => {
    setIsReverse((current) => {
      setValues((values: SplitValues) => {
        return { ...values, isReverse: !current };
      });
      return !current;
    });
  };

  return (
    <StepContainer
      testid="ratio-and-date-step"
      title={t("transactionWizard.ratioAndDateStep.title")}
      onNext={() => {
        if (onNextDisabled) return;
        handleNext(values);
      }}
      onNextDisabled={onNextDisabled}
      onPrevious={handlePrevious}
    >
      <Container>
        <Typography variant="body1">
          <b>
            {t(`transactionWizard.ratioAndDateStep.title`)}
            {": "}
          </b>
          {investmentName}
        </Typography>

        {!!validationError && (
          <Alert severity="error" data-testid="booking-validation-error">
            {validationError}
          </Alert>
        )}

        <Grid container rowSpacing={6} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              data-testid="date-picker"
              disableFuture
              openTo="year"
              mask="__.__.____"
              inputFormat="dd.MM.yyyy"
              label={t("transactionWizard.ratioAndDateStep.date")}
              views={["year", "month", "day"]}
              value={date}
              components={{
                OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "date-picker-input",
                  }}
                />
              )}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputFieldWithRevert
              fieldLabel={t("transactionWizard.ratioAndDateStep.ratio")}
              id="ratio"
              value={ratio || ""}
              handleChange={handleRatioChange}
              startAdornment={isReverse ? undefined : "1:"}
              endAdornment={isReverse ? ":1" : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isReverse}
                  onChange={() => handleIsReverseChange()}
                  color="primary"
                  data-testid="is-reverse-input"
                />
              }
              label={t("transactionWizard.ratioAndDateStep.isReverse")}
              data-testid="is-reverse-switch"
              sx={{ marginLeft: 0 }}
            />
          </Grid>
        </Grid>
      </Container>
    </StepContainer>
  );
};
