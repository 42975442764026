import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Account } from "@generated/apiv1";
import { AccountsTable } from "@components/AccountsTable";
import { Banner } from "@components";
import { ReactComponent as InfoIcon } from "@icons/info.svg";

type Props = {
  accounts?: Account[];
  checkedPortfolios: number[];
  onSelect: (ids: number[]) => void;
  onClose?: () => void;
  onContinue: () => void;
};

export const FirstStep = ({
  accounts = [],
  checkedPortfolios = [],
  onSelect,
  onClose,
  onContinue,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" height="calc(100% - 70px)">
      <AccountsTable
        accounts={accounts}
        checkedPortfolios={checkedPortfolios}
        onSelect={onSelect}
      />
      <Banner
        type="grey-info"
        icon={<InfoIcon />}
        text={t("portfolioShare.modal.cashPositionsShared")}
        mb={8}
      />
      <Box display="flex" justifyContent="space-between" gap={3}>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("navigation.previous")}
        </Button>
        <Button
          variant="contained"
          onClick={onContinue}
          color="primary"
          disabled={!checkedPortfolios.length}
        >
          {t("portfolioShare.modal.shareButton")}
        </Button>
      </Box>
    </Box>
  );
};
