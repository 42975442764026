import { UseMutationOptions } from "react-query";
import {
  BanksApiCreateInterfaceSelectionOptionRequest,
  InterfaceSelectionOption,
} from "@generated/apiv1";

import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createInterfaceSelectionOption = async (
  token: string | undefined,
  params: BanksApiCreateInterfaceSelectionOptionRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.createInterfaceSelectionOption(params);
  return data;
};

export const useCreateInterfaceSelectionOption = (
  options?: UseMutationOptions<
    InterfaceSelectionOption,
    AxiosApiError,
    BanksApiCreateInterfaceSelectionOptionRequest
  >
) => {
  return useAuthMutation(createInterfaceSelectionOption, options, false);
};
