import React from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Modal } from "@components";
import { AddOrEditManuallyPageForm } from "@features/accounts/transactions/components/AddOrEditManuallyPageForm";
import { useUpdateCashTransaction } from "@generated/apiv1/hooks";
import { CreateOrUpdateTransactionParams, Transaction } from "@generated/apiv1";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { ACCOUNT_PERFORMANCE_BY_ACCOUNT_IDS } from "@api/cacheKeys";

type Props = {
  transaction: Transaction;
  onSaved?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const EditTransactionModal = ({
  transaction,
  onSaved,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isSaving, reset } = useUpdateCashTransaction();

  const saveTransaction = (params: CreateOrUpdateTransactionParams) => {
    mutate(
      {
        id: transaction.id,
        body: params,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              CACHE_KEYS.TRANSACTIONS_TRANSACTIONS_ID,
              { id: transaction.id },
            ],
          });
          queryClient.invalidateQueries({
            queryKey: [ACCOUNT_PERFORMANCE_BY_ACCOUNT_IDS],
          });
          onSaved && onSaved();
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("addManuallyPage.header.editTitle")}
      disablePortal={false}
    >
      {transaction && (
        <AddOrEditManuallyPageForm
          isSavingLoading={isSaving}
          onSubmit={saveTransaction}
          onReset={reset}
          defaultValues={transaction as CreateOrUpdateTransactionParams}
        />
      )}
    </Modal>
  );
};
