import React, { useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useKey } from "react-use";
import { Box, Typography, useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton, Logo, RHFTextField } from "@components";
import { useResetPassword } from "@generated/user/hooks";
import { SettingsErrorBanner } from "../components/SettingsErrorBanner";

interface FormFields {
  password1: string;
  password2: string;
}

export const ResetPasswordPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    mutate: resetPassword,
    isLoading,
    isError,
  } = useResetPassword({
    onSuccess: async () => {
      navigate("/");
    },
  });

  const params = new URLSearchParams(location.search);
  const reset_token = params.get("reset_password_token");

  useEffect(() => {
    if (!reset_token) {
      navigate("/");
    }
  }, [reset_token, navigate]);

  const schema = Yup.object().shape({
    password1: Yup.string().required(t("form.errors.required")),
    password2: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, watch } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      password1: "",
      password2: "",
    },
  });

  const handleConfirm = () => {
    const values = getValues();
    if (values.password1 !== values.password2) {
      return enqueueSnackbar(t("passwordResetPage.passwordMismatch"), {
        autoHideDuration: 3000,
        variant: "error",
      });
    }

    resetPassword({
      newPassword: values.password1,
      resetPasswordToken: reset_token!,
    });
  };

  const isConfirmBtnDisabled = !watch("password1") || !watch("password2");

  useKey(
    "Enter",
    () => {
      if (!isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  return (
    <>
      <Logo
        variant="sidebar"
        width="255px"
        height="78px"
        position="absolute"
        top="5%"
        left="calc(50% - 127.5px)"
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
        sx={{
          backgroundColor: theme.palette.background.primary,
        }}
      >
        <Box
          padding={theme.spacing(8)}
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: theme.palette.background.default,
            maxWidth: "830px",
            width: "100%",
            borderRadius: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
            marginBottom={theme.spacing(10)}
          >
            <Typography
              variant="h5"
              fontSize="26px"
              lineHeight="34px"
              textAlign="center"
            >
              {t("passwordResetPage.title")}
            </Typography>
            {isError && (
              <SettingsErrorBanner
                errorMessage={t("settingsPage.commonErrorMessage")}
                marginBottom={0}
              />
            )}
            <RHFTextField
              name="password1"
              label={t("passwordResetPage.passwordLabel")}
              control={control}
              fullWidth
              type="password"
            />
            <RHFTextField
              name="password2"
              label={t("passwordResetPage.passwordRepeatLabel")}
              control={control}
              fullWidth
              type="password"
            />
          </Box>
          <LoadingButton
            data-testid="reset-password-action-button"
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            autoFocus
            onClick={handleSubmit(handleConfirm)}
            disabled={isConfirmBtnDisabled}
            isLoading={isLoading}
          >
            {t("passwordResetPage.button")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
