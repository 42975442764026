import { UseMutationOptions } from "react-query";
import { OauthApiAccessTokenRequest, LoginToken } from "@generated/apiv1";

import { buildOauthApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const accessToken = async (
  token: string | undefined,
  params: OauthApiAccessTokenRequest
) => {
  const apiInstance = buildOauthApi(token);
  const { data } = await apiInstance.accessToken(params);
  return data;
};

export const useAccessToken = (
  options?: UseMutationOptions<
    LoginToken,
    AxiosApiError,
    OauthApiAccessTokenRequest
  >
) => {
  return useAuthMutation(accessToken, options, false);
};
