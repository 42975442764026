import { useQuery, UseQueryOptions } from "react-query";
import { AxiosResponse } from "axios";
import { BankList, BankWrapper } from "@api/v1/model";
import { getBankByIban, getBankById, searchBank } from "@api/v1/methods";

export const useGetBankByIban = (
  iban?: string,
  options?: UseQueryOptions<
    AxiosResponse<BankList, any>,
    AxiosApiError,
    AxiosResponse<BankList, any>,
    string[]
  >
) =>
  useQuery(["bank-by-iban", iban!], () => getBankByIban(iban!), {
    enabled: !!iban,
    ...options,
  });

export const useGetBankById = (
  id?: number,
  {
    onSuccess,
    enabled = true,
  }: { onSuccess?: (data: BankWrapper) => void; enabled?: boolean } = {}
) =>
  useQuery(["bank-by-id", id!], () => getBankById(id!), {
    enabled: !!id && enabled,
    onSuccess,
  });

export const useSearchBanks = (
  value: string,
  options: UseQueryOptions<BankList, AxiosApiError, BankList, string[]>
) => useQuery(["search-banks", value!], () => searchBank(value!), options);
