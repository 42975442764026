import { UseMutationOptions } from "react-query";
import {
  BillingCyclesApiCreateBillingCycleRequest,
  BillingCycle,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createBillingCycle = async (
  token: string | undefined,
  params: BillingCyclesApiCreateBillingCycleRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.createBillingCycle(params);
  return data;
};

export const useCreateBillingCycle = (
  options?: UseMutationOptions<
    BillingCycle,
    AxiosApiError,
    BillingCyclesApiCreateBillingCycleRequest
  >
) => {
  return useAuthMutation(createBillingCycle, options, false);
};
