import React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import {
  Checkbox,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  padding-top: ${({ theme }) => theme.spacing(1)};
`;

const renderOptions = (
  options: Record<string, string> | string[],
  currentValue: string[] | string,
  isMultiple?: boolean
) => {
  const optionEntries = Array.isArray(options)
    ? options.map((o) => [o, o])
    : Object.entries(options);

  return optionEntries.map(([key, value]) => {
    const id = Array.isArray(options) ? value : key;

    return (
      <MenuItem key={id} value={id} data-testid={id}>
        {isMultiple && (
          <Checkbox
            checked={(currentValue as string[])?.includes(id)}
            sx={{ marginRight: (theme) => theme.spacing(3) }}
          />
        )}
        {value}
      </MenuItem>
    );
  });
};

type SelectLocalProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

type FormSelectProps<T extends FieldValues> = SelectLocalProps &
  SelectProps &
  UseControllerProps<T> & {
    options?: Record<string, string> | string[];
    optional?: boolean;
  };

export const RHFSelect = <T extends FieldValues>(
  props: FormSelectProps<T>
): JSX.Element => {
  const {
    control,
    name,
    defaultValue,
    label = "",
    type = "text",
    options,
    children,
    optional = false,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, invalid },
  } = useController({ control, name, defaultValue });

  return (
    <div>
      {label && (
        <FormLabel htmlFor={name}>
          {typeof label === "string" ? (
            <Typography variant="body2">
              {label}
              {optional ? (
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="body2"
                >
                  {" "}
                  (optional)
                </Typography>
              ) : (
                ""
              )}
            </Typography>
          ) : (
            label
          )}
        </FormLabel>
      )}
      <Select
        variant="outlined"
        fullWidth
        {...field}
        value={field.value || ""}
        error={invalid}
        type={type}
        onChange={(e) => field.onChange(e.target.value)}
        MenuProps={{
          disablePortal: true,
          sx: {
            "& .MuiBackdrop-root": {
              opacity: `0 !important`,
              cursor: "initial",
            },
          },
        }}
        {...rest}
      >
        {options
          ? renderOptions(
              options,
              field.value as string | string[],
              rest.multiple
            )
          : children}
      </Select>
      {error?.message && (
        <Error>
          <Typography
            variant="caption"
            color="inherit"
            data-testid="rhf-select-error"
          >
            {error.message}
          </Typography>
        </Error>
      )}
    </div>
  );
};
