import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DividendsProvider } from "@features/dividendPlanner/DividendsProvider";
import { LayoutWithTabs } from "@components/common/layouts/LayoutWithTabs";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import { SelectGroupParams } from "@features/selectDepots/types";
import { DepotsSelectorDropdown } from "@features/selectDepots/components/DepotsSelectorDropdown";
import { OverviewTab } from "@features/dividendPlanner/OverViewTab/OverviewTab";
import { GraphTab } from "@features/dividendPlanner/GraphTab/GraphTab";
import { CalendarTab } from "@features/dividendPlanner/CalendarTab/CalendarTab";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { useSelectedAccounts } from "@hooks/useSelectedGroups";

const accountParams: AccountsApiListAndSearchAllAccountsRequest = {
  type: ["01_depot"],
};

const selectedGroupParams: SelectGroupParams = {
  id: "dividendPlanner",
};

export const DividendsPage = () => {
  const { t } = useTranslation();
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "overview";
  const isLess750 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(750)
  );

  const { selectedAccounts, refresh } = useSelectedAccounts({
    selectedGroupParams,
    accountParams,
  });
  const selectedAccountIds = selectedAccounts
    .filter((account) => account.selected)
    .map((account) => account.id);

  const tabs = [
    {
      key: "overview",
      title: i18next.t("dividends.tabs.overview"),
      body: (
        <DividendsProvider
          accountIds={selectedAccountIds}
          filterKey={currentTab}
        >
          <OverviewTab selectedAccounts={selectedAccountIds} />
        </DividendsProvider>
      ),
    },
    {
      key: "details",
      title: i18next.t("dividends.tabs.details"),
      body: <GraphTab selectedAccounts={selectedAccountIds} />,
    },
    {
      key: "calendar",
      title: i18next.t("dividends.tabs.calendar"),
      body: (
        <DividendsProvider
          accountIds={selectedAccountIds}
          filterKey={currentTab}
        >
          <CalendarTab />
        </DividendsProvider>
      ),
    },
  ];

  return (
    <LayoutWithTabs
      title={t("dividends.title")}
      currentTab={currentTab}
      tabs={tabs}
      onTabChange={(tab) => {
        navigate(
          isSharedPortfolio
            ? `/portfolios/${publicId}/dividend-planner?tab=${tab}`
            : `/dividend-planner?tab=${tab}`
        );
      }}
      breakpoint={750}
      actionButtons={
        <Box width={isLess750 ? "100%" : "auto"}>
          <DepotsSelectorDropdown
            selectedGroupParams={selectedGroupParams}
            accountParams={accountParams}
            onSave={refresh}
            fullWidth={isLess750}
            MenuProps={{
              sx: {
                "& .MuiMenu-paper": {
                  maxHeight: 300,
                  maxWidth: isLess750 ? "unset" : 246,
                },
              },
            }}
            sx={{
              width: isLess750 ? "100%" : 246,
            }}
          />
        </Box>
      }
    />
  );
};
