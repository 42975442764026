import React, { useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  Box,
  Button,
  TableRow,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import TableCell from "@mui/material/TableCell";
import { SortableTableHeader, TableBodyCell } from "@components/Table";
import { Dividend } from "@api";
import { toCurrency, toRoundedNumberOfLots } from "@helpers";
import { ReactComponent as EditIcon } from "@icons/edit-box.svg";
import { EditDividendModal } from "@components/Accounts/TransactionTable/EditDividendModal";
import { useTableSortWithData } from "@hooks";
import { NoDataStub } from "@components";

interface RowProps {
  row: Dividend;
  // isXs: boolean;
  // isSm: boolean;
  edit?: (url: string) => void;
  create?: (url: string) => void;
  dividendExist: boolean;
}

const Row = ({ row, edit, create }: RowProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderButton = (bookingId: number | undefined) => {
    if (!!bookingId) {
      return (
        <Button
          variant="text"
          size="small"
          startIcon={
            <EditIcon
              color={
                row.numberOfLots === 0
                  ? theme.palette.text.disabled
                  : theme.palette.icon.active
              }
            />
          }
          color="secondary"
          onClick={() => (edit ? edit(bookingId!.toString()) : null)}
          disabled={row.numberOfLots === 0}
        >
          {t("singleAccount.edit")}
        </Button>
      );
    } else {
      return (
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={() =>
            create ? create(format(new Date(row.date!), "MM-dd-yyyy")) : null
          }
          disabled={row.numberOfLots === 0}
        >
          {t("domains.dividends.dividendsTable.actions.addDividends")}
        </Button>
      );
    }
  };
  return (
    <TableRow>
      <TableBodyCell>
        <Box display="flex">
          <Typography
            color="textSecondary"
            fontSize={14}
            style={{
              fontWeight: "400",
              textTransform: "none",
              cursor: "pointer",
            }}
          >
            {row.date && format(new Date(row.date), "dd.MM.yyyy")}
          </Typography>
        </Box>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography fontSize={14}>
          {toCurrency(row.dividendPerShare, row.currency)}
        </Typography>
      </TableBodyCell>

      <TableBodyCell align="right">
        <Typography fontSize={14} fontWeight={400}>
          {row.numberOfLots && toRoundedNumberOfLots(row.numberOfLots)}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography fontSize={14}>
          {toCurrency(row.grossAmount, row.currency)}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="right">{renderButton(row.bookingId)}</TableBodyCell>
    </TableRow>
  );
};

interface DividendsTableProps {
  className?: string;
  dividends: Dividend[];
  investmentId: number;
  tickerSymbol?: string;
  edit?: (url: string) => void;
  create?: (url: string) => void;
  isFetched: boolean;
}

export const DividendTable = (props: DividendsTableProps) => {
  const { t } = useTranslation();
  const {
    className,
    dividends,
    investmentId,
    tickerSymbol,
    create,
    isFetched,
  } = props;
  const [currentDividendIdEditing, setCurrentDividendIdEditing] = useState<
    number | undefined
  >(undefined);

  const {
    data: sortedDividends,
    sort,
    sortDirection,
    sortField,
  } = useTableSortWithData(dividends);

  const handleClick = (sortByName: string) => {
    sort(sortByName);
  };
  return (
    <Box
      className={className}
      sx={{
        overflowX: "auto",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeader
              onClick={handleClick}
              text={t("domains.dividends.dividendsTable.headers.date")}
              sortByName={"date"}
              sortField={sortField}
              sortDirection={sortDirection}
              isSortable
            />
            <SortableTableHeader
              onClick={handleClick}
              text={t("domains.dividends.dividendsTable.headers.dividend")}
              sortByName={"dividendPerShare"}
              sortField={sortField}
              sortDirection={sortDirection}
              align="right"
              isSortable
            />
            <SortableTableHeader
              onClick={handleClick}
              text={t("domains.dividends.dividendsTable.headers.lots")}
              sortByName={"numberOfLots"}
              sortField={sortField}
              sortDirection={sortDirection}
              align="right"
              isSortable
            />
            <SortableTableHeader
              onClick={handleClick}
              text={t("domains.dividends.dividendsTable.headers.total")}
              sortByName={"grossAmount"}
              sortField={sortField}
              sortDirection={sortDirection}
              align="right"
              isSortable
            />
            <SortableTableHeader
              text={t("domains.dividends.dividendsTable.headers.actions")}
              sortByName={"name"}
              align="right"
              isSortable={false}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetched && !sortedDividends?.length && (
            <TableRow>
              <TableCell colSpan={5}>
                <NoDataStub />
              </TableCell>
            </TableRow>
          )}
          {sortedDividends.map((div) => {
            const dividendExist = Boolean(div.bookingId);
            return (
              <Row
                key={div.date}
                row={div}
                dividendExist={dividendExist}
                edit={() => setCurrentDividendIdEditing(div.bookingId)}
                create={create}
              />
            );
          })}
        </TableBody>
      </Table>
      {Boolean(currentDividendIdEditing) && (
        <EditDividendModal
          isOpen={Boolean(currentDividendIdEditing)}
          onClose={() => setCurrentDividendIdEditing(undefined)}
          investmentId={investmentId}
          bookingId={currentDividendIdEditing!}
          tickerSymbol={tickerSymbol}
          invalidateBookings
        />
      )}
    </Box>
  );
};
