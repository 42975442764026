import { isValid, parseISO } from "date-fns";
import { convertDateToOnlyDateString, round } from "@helpers";
import {
  Dividend,
  CreateMultipleBookingParams,
  CreateOrUpdateBookingParams,
  Booking,
} from "@api";
import { DividendValues } from "../stateMachine/types/dividendValues";
import { getAmount, getAmountAC } from "../hooks";

export const valuesValidator = ({
  securityPrice,
  exchangeRate,
  date,
}: DividendValues): boolean => {
  if (!securityPrice || !exchangeRate || !date || !isValid(date)) return false;
  if (exchangeRate < 0) return false;

  return true;
};

export const buildBatchBookings = (
  dividends: Dividend[]
): CreateOrUpdateBookingParams[] => {
  return dividends.map(({ date, exchangeRate, taxAmount, grossAmount }) => ({
    numberOfLots: 1,
    securityPrice: grossAmount,
    date,
    dateIsEstimate: false,
    exchangeRate: exchangeRate,
    type: "dividend",
    commission: 0,
    taxAmount,
    expensesInEur: false,
  }));
};

export const buildEditBookingParams = ({
  date,
  securityPrice,
  exchangeRate,
  commission,
  taxAmount,
  taxAndCommissionInEur,
  type,
}: DividendValues): CreateOrUpdateBookingParams => {
  if (!date) throw new Error(`Invalid date ${date}`);
  if (!exchangeRate || exchangeRate < 0)
    throw new Error(`Invalid exchange rate ${exchangeRate}`);
  if (!securityPrice)
    throw new Error(`Invalid security price ${securityPrice}`);

  return {
    date: convertDateToOnlyDateString(date),
    securityPrice,
    exchangeRate,
    commission,
    taxAmount,
    type,
    numberOfLots: 1,
    expensesInEur: taxAndCommissionInEur,
  };
};

export const buildCreateOrUpdateBookingParams = (
  investmentId: number,
  dividendValues: DividendValues,
  accountId: number,
  bookingId?: number
): CreateMultipleBookingParams => {
  let dividend: CreateOrUpdateBookingParams = {
    ...buildEditBookingParams(dividendValues),
  };

  if (bookingId) {
    dividend = {
      id: bookingId,
      ...dividend,
    };
  }

  return {
    accountId,
    performBackgroundTransactionSync: false,
    autoAssignQuotes: false,
    createDepotSynchronizationLog: false,
    createOrUpdateInvestmentParamsList: [
      {
        id: investmentId,
        createOrUpdateBookingParamsList: [dividend],
      },
    ],
  };
};

export const buildDividendValuesFromBooking = ({
  commission = 0,
  securityPrice,
  exchangeRate = 1,
  taxAmount = 0,
  valueDate,
}: Booking): DividendValues => {
  const convertedCommission = Math.abs(commission);
  const convertedTaxAmount = Math.abs(taxAmount);
  const convertedExchangeRate =
    exchangeRate === 1 ? exchangeRate : round(1 / exchangeRate, 6);

  return {
    date: parseISO(valueDate),
    taxAndCommissionInEur: false,
    type: "dividend",
    commission: convertedCommission,
    taxAmount: convertedTaxAmount,
    exchangeRate: convertedExchangeRate,
    securityPrice,
    amount: getAmount({
      commission: convertedCommission,
      securityPrice,
      exchangeRate: convertedExchangeRate,
      taxAmount: convertedTaxAmount,
      taxAndCommissionInEur: false,
    }),
    amountAC: getAmountAC({
      commission: convertedCommission,
      securityPrice,
      exchangeRate: convertedExchangeRate,
      taxAmount: convertedTaxAmount,
      taxAndCommissionInEur: false,
    }),
  };
};
