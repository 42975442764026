import { UseQueryOptions } from "react-query";
import { InsuranceProvidersApiDeleteInsuranceProviderRequest } from "@generated/apiv1";

import { buildInsuranceProvidersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const deleteInsuranceProvider = async (
  token: string | undefined,
  params: InsuranceProvidersApiDeleteInsuranceProviderRequest
) => {
  const apiInstance = buildInsuranceProvidersApi(token);
  const { data } = await apiInstance.deleteInsuranceProvider(params);
  return data;
};

export const useDeleteInsuranceProvider = (
  params: InsuranceProvidersApiDeleteInsuranceProviderRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INSURANCEPROVIDERS_DELETE_INSURANCE_PROVIDER, params],
    ({ token }) => deleteInsuranceProvider(token, params),
    options
  );
};
