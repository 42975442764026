import { InvokeCallback, Sender } from "xstate";

import { createSplitWithToken } from "@api/v1/methods/bookings";
import { SplitValues } from "../types/splitValues";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import { buildSplitParams } from "../utils/buildRequestParams";

export const persistSplit =
  (
    context: TransactionWizardContext
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (!context.investmentValues.id)
      throw new Error("no investment ID provided");
    if (!context.splitValues) throw new Error("no split values provided");

    persist(callback, context.investmentValues.id, context.splitValues);
  };

const persist = async (
  callback: Sender<TransactionWizardEvent>,
  investmentId: number,
  splitValues: SplitValues
) => {
  try {
    await createSplitWithToken(buildSplitParams(investmentId, splitValues));
    callback({
      type: "BOOKING_VALID",
    });
  } catch (e) {
    callback({
      type: "BOOKING_INVALID",
      error: (e as AxiosApiError).message || "Saving data failed",
    });
  }
};
