import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteStoredSecretsV2Request } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteStoredSecretsV2 = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteStoredSecretsV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteStoredSecretsV2(params);
  return data;
};

export const useDeleteStoredSecretsV2 = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteStoredSecretsV2Request
  >
) => {
  return useAuthMutation(deleteStoredSecretsV2, options, false);
};
