import React from "react";
import { format, isEqual } from "date-fns";
import styled from "@emotion/styled";
import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
} from "@mui/material";
import { Dividend } from "@api";
import { toCurrency, toRoundedNumberOfLots } from "@helpers";

interface Props {
  allDividends: Dividend[];
  selectedDividends: Dividend[];
  onSelectDividend: (dividend: Dividend) => void;
}

interface ColumnTextStyledProps {
  disabled: boolean;
}

const ColumnTextStyled = styled(Typography)<ColumnTextStyledProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.text.secondary : "inherit"}};
`;

const TableRowStyled = styled(TableRow)`
  background: ${({ theme }) => theme.palette.common.white};
`;

export const DividendsTableBody = ({
  allDividends,
  selectedDividends,
  onSelectDividend,
}: Props) => {
  const isSelected = (dividendDate?: string): boolean =>
    selectedDividends.some(({ date }) => {
      if (!date || !dividendDate) return false;

      return isEqual(new Date(date), new Date(dividendDate));
    });

  return (
    <TableBody>
      {allDividends.map((dividend) => {
        const {
          bookingId,
          date,
          dividendPerShare,
          numberOfLots = 0,
          currency = "EUR",
        } = dividend;
        const isSelectedDividend = isSelected(date);
        const hasTransaction = Boolean(bookingId);

        return (
          <TableRowStyled
            hover
            key={date}
            onClick={() => onSelectDividend(dividend)}
          >
            <TableCell width={35}>
              <Checkbox
                data-testid="select-dividend-checkbox"
                inputProps={{
                  // @ts-ignore https://github.com/mui-org/material-ui/issues/20160
                  "data-testid": "select-dividend-checkbox-input",
                }}
                disabled={hasTransaction}
                checked={hasTransaction || isSelectedDividend}
              />
            </TableCell>
            <TableCell>
              <ColumnTextStyled disabled={hasTransaction} variant="body2">
                {format(new Date(date!), "dd.MM.yyyy")}
              </ColumnTextStyled>
            </TableCell>
            <TableCell>
              <ColumnTextStyled disabled={hasTransaction} variant="body2">
                {toCurrency(dividendPerShare, currency)}
              </ColumnTextStyled>
            </TableCell>
            <TableCell align="right">
              <ColumnTextStyled disabled={hasTransaction} variant="body2">
                {toRoundedNumberOfLots(numberOfLots)}
              </ColumnTextStyled>
            </TableCell>
          </TableRowStyled>
        );
      })}
    </TableBody>
  );
};
