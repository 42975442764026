import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { Account } from "@generated/apiv1";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { LoadingPlaceHolder, RHFCheckbox } from "@components";
import { useDeleteAccount } from "@generated/apiv1/hooks";
import { GET_BANK_CONNECTIONS } from "@api/cacheKeys";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";
import {
  useDeleteBankConnection,
  useDeleteStoredSecrets,
  useGetBankConnectionByProvider,
} from "../useSettings";
import { ModalState } from "../SettingsConnectionsPage";
import { BankInterface } from "./BankInterface";

const ConnectionWrapper = styled(Box)`
  & .MuiFormControlLabel-root {
    align-items: flex-start !important;
  }
`;

const commonModalProps = {
  title: "settingsPage.connections.deleteModal.areYouSure",
  confirmButtonColor: "error" as const,
  cancelButton: "settingsPage.connections.deleteModal.close",
};

const getModalProps = (modelState: ModalState) => {
  if (modelState.state === "open")
    return {
      confirmButton: "settingsPage.connections.deleteModal.deleteLoginData",
      confirmButtonColor: "error" as const,
      secondButton: "settingsPage.connections.deleteModal.deleteConnection",
      secondButtonColor: "secondaryError" as const,
    };
  if (modelState.state === "loginData")
    return {
      ...commonModalProps,
      confirmButton: "settingsPage.connections.deleteModal.deleteLoginData",
    };
  return {
    ...commonModalProps,
    confirmButton: "settingsPage.connections.deleteModal.deleteConnection",
  };
};

type FormFields = {
  deleteAll: boolean;
};

interface Props {
  modalState: ModalState;
  setModalState: (state: ModalState) => void;
  accounts: Account[];
}

export const SettingsBankConnectionModal = ({
  modalState,
  setModalState,
  accounts,
}: Props) => {
  const { title, provider, id } = modalState;
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues } = useForm<FormFields>({
    defaultValues: {
      deleteAll: false,
    },
  });
  const { data: bankConnection, isLoading } = useGetBankConnectionByProvider(
    provider || "FIN_API",
    {
      id: Number(id),
    },
    { enabled: modalState.id !== undefined }
  );
  const {
    mutateAsync: deleteLoginData,
    isLoading: isDeletingLoginData,
    isError: isDeleteLoginDataError,
  } = useDeleteStoredSecrets(provider || "FIN_API");
  const { mutateAsync: deleteAccount } = useDeleteAccount();
  const { mutateAsync: deleteBankConnection } = useDeleteBankConnection(
    provider || "FIN_API"
  );
  const [isDeletingConnection, setIsDeletingConnection] = useState(false);
  const [showError, setShowError] = useState(false);

  const confirmButtonState =
    modalState.state !== "open"
      ? modalState.state === "loginData"
        ? isDeletingLoginData
        : isDeletingConnection
      : false;

  const onClose = () => {
    setModalState({ id: undefined, state: "open" });
  };

  const onOpenModal = (value: "loginData" | "connection") => {
    setModalState({ ...modalState, state: value });
  };

  const onBack = () => {
    setModalState({ ...modalState, state: "open" });
  };

  const invalidateData = () => {
    queryClient.invalidateQueries({
      queryKey: [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS, provider],
    });
    queryClient.invalidateQueries({
      queryKey: [GET_BANK_CONNECTIONS, "WEALTH_API"],
    });
    queryClient.invalidateQueries({
      queryKey: [GET_BANK_CONNECTIONS, "FIN_API"],
    });
  };

  const handleDeleteLoginData = () => {
    deleteLoginData(
      { id: id! },
      {
        onError: (err) => {
          enqueueSnackbar(
            err.response?.data.message ||
              t("settingsPage.connections.deleteModal.errorDeletion"),
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        },
        onSuccess: () => {
          invalidateData();
          onClose();
          enqueueSnackbar(
            t("settingsPage.connections.deleteModal.successLDDelete"),
            {
              variant: "success",
              autoHideDuration: 3000,
            }
          );
        },
      }
    );
  };

  const handleDeleteBankConnection = async () => {
    setShowError(false);
    const shouldDeleteAccounts = getValues().deleteAll;
    setIsDeletingConnection(true);

    const onSuccess = () => {
      invalidateData();
      enqueueSnackbar(
        t("settingsPage.connections.deleteModal.successConDelete"),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      setIsDeletingConnection(false);
      onClose();
    };

    try {
      await Promise.allSettled([
        deleteBankConnection({ id: id! }),
        ...(shouldDeleteAccounts && accounts
          ? accounts?.map((acc) =>
              deleteAccount({ id: acc.id, deleteContraAccount: true })
            )
          : []),
      ]);

      onSuccess();
    } catch (error: AxiosApiError | any) {
      if (error?.response?.status !== 406) {
        setShowError(true);
        setIsDeletingConnection(false);
      } else {
        onSuccess();
      }
    }
  };

  const onConfirm = () => {
    if (modalState.state === "open") return onOpenModal("loginData");
    if (modalState.state === "loginData") {
      return handleDeleteLoginData();
    }
    handleDeleteBankConnection();
  };

  useEffect(() => {
    setShowError(false);
  }, [modalState.state]);

  return (
    <SettingsModalWrapper
      title={title}
      isOpen={modalState.id !== undefined}
      onClose={onClose}
      onConfirm={onConfirm}
      onSecondButtonConfirm={() => onOpenModal("connection")}
      onBack={modalState.state !== "open" ? onBack : undefined}
      showCloseIcon={true}
      isConfirmButtonLoading={confirmButtonState}
      isConfirmBtnDisabled={isLoading || confirmButtonState}
      isSecondBtnDisabled={isLoading}
      {...getModalProps(modalState)}
    >
      {isLoading && <LoadingPlaceHolder />}
      {!isLoading && (
        <>
          {modalState.state === "open" &&
            Boolean(bankConnection?.interfaces.length) && (
              <>
                <Typography
                  variant="body1"
                  fontSize="26px"
                  lineHeight="34px"
                  fontWeight={600}
                  mb={theme.spacing(6)}
                >
                  {t("settingsPage.connections.deleteModal.interfaces")}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={theme.spacing(6)}
                >
                  {bankConnection?.interfaces.map((interfaceItem) => (
                    <BankInterface connectionInterface={interfaceItem} />
                  ))}
                </Box>
              </>
            )}
          {modalState.state === "loginData" && (
            <Box m={isMobile ? 0 : theme.spacing(8, 12)}>
              {isDeleteLoginDataError && (
                <SettingsErrorBanner
                  errorMessage={t("settingsPage.commonErrorMessage")}
                  marginBottom={2.5}
                />
              )}
              <Typography
                variant="body1"
                fontSize="18px"
                lineHeight="28px"
                fontWeight={400}
                letterSpacing={-0.4}
              >
                {t("settingsPage.connections.deleteModal.loginDataDescription")}
              </Typography>
            </Box>
          )}
          {modalState.state === "connection" && (
            <ConnectionWrapper m={isMobile ? 0 : theme.spacing(8, 12)}>
              {showError && (
                <SettingsErrorBanner
                  errorMessage={t("settingsPage.commonErrorMessage")}
                  marginBottom={8}
                />
              )}
              <Typography
                variant="body1"
                fontSize="18px"
                lineHeight="28px"
                fontWeight={400}
                mb={theme.spacing(8)}
                letterSpacing={-0.4}
              >
                {t(
                  "settingsPage.connections.deleteModal.connectionDescription"
                )}
              </Typography>
              <RHFCheckbox
                name="deleteAll"
                label={t(
                  "settingsPage.connections.deleteModal.deleteAllConnected"
                )}
                control={control}
              />
            </ConnectionWrapper>
          )}
        </>
      )}
    </SettingsModalWrapper>
  );
};
