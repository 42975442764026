import { UseMutationOptions } from "react-query";
import { TransactionsApiDeleteTransactionRequest } from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteTransaction = async (
  token: string | undefined,
  params: TransactionsApiDeleteTransactionRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.deleteTransaction(params);
  return data;
};

export const useDeleteTransaction = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TransactionsApiDeleteTransactionRequest
  >
) => {
  return useAuthMutation(deleteTransaction, options, false);
};
