import { BookingTypeEnum } from "@api";
import {
  toCurrency,
  toRoundedNumberOfLots,
  formatDateOrString,
} from "@helpers";
import { Activity } from "@generated/apiv1/pdfParser";

export const toInternalType = (type: BookingTypeEnum): BookingTypeEnum => {
  switch (type) {
    case "buy":
      return "buy";
    case "sell":
      return "sell";
    case "dividend":
      return "dividend";
    case "book_in":
      return "book_in";
    case "book_out":
      return "book_out";
  }

  throw new Error(`type ${type} is unknown`);
};

export const toString = (parsingActivity: Activity) => {
  let items: string[] = [];
  if (parsingActivity.date)
    items.push(`Datum: ${formatDateOrString(parsingActivity.date)}`);
  if (parsingActivity.name) items.push(`Name: ${parsingActivity.name}`);
  if (parsingActivity.isin) items.push(`ISIN: ${parsingActivity.isin}`);
  if (parsingActivity.wkn) items.push(`WKN: ${parsingActivity.wkn}`);
  if (parsingActivity.numberOfLots)
    items.push(`Stück: ${toRoundedNumberOfLots(parsingActivity.numberOfLots)}`);
  if (parsingActivity.securityPrice)
    items.push(`Einstandskurs: ${toCurrency(parsingActivity.securityPrice)}`);
  if (parsingActivity.netAmount)
    items.push(`Betrag: ${toCurrency(parsingActivity.netAmount)}`);
  if (parsingActivity.commission)
    items.push(`Gebühren: ${toCurrency(parsingActivity.commission)}`);
  if (parsingActivity.taxAmount)
    items.push(`Steuern: ${toCurrency(parsingActivity.taxAmount)}`);
  if (parsingActivity.currency)
    items.push(`Fremdwährung: ${parsingActivity.currency}`);
  if (parsingActivity.exchangeRate && parsingActivity.exchangeRate !== 1)
    items.push(`Wechselkurs: ${parsingActivity.exchangeRate}`);
  if (parsingActivity.purpose && parsingActivity.purpose !== "null")
    items.push(`Verwendungszweck: ${parsingActivity.purpose}`);

  return items.join("; ");
};
