import React from "react";
import { Area, Line } from "recharts";
import { useChartColors } from "@hooks";
import { CustomizedDot } from "./CustomizedDot";

interface Props {
  dataKey: string;
  dataAreaKey: string;
  // eslint-disable-next-line prettier/prettier
  chartColors: ReturnType<typeof useChartColors<"securitiesChart">>;
}

export const MainLine = ({ dataKey, dataAreaKey, chartColors }: Props) => {
  return [
    <defs key="defs">
      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
        <stop offset="40%" stopColor={chartColors.mainArea} stopOpacity={1.0} />
        <stop
          offset="100%"
          stopColor={chartColors.mainArea}
          stopOpacity={0.0}
        />
      </linearGradient>
    </defs>,
    <Area
      yAxisId="1"
      isAnimationActive={false}
      key="area"
      type="monotone"
      dataKey={dataAreaKey}
      stroke=""
      fill="url(#colorUv)"
      activeDot={false}
    />,
    <Line
      yAxisId="1"
      key="line"
      isAnimationActive={false}
      type="monotone"
      activeDot={CustomizedDot}
      strokeWidth={2}
      dataKey={dataKey}
      stroke={chartColors.mainLine}
      dot={false}
      legendType="none"
    />,
  ];
};
