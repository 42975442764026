/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Currency } from '../model';
// @ts-ignore
import { CurrencyList } from '../model';
/**
 * CurrenciesApi - axios parameter creator
 * @export
 */
export const CurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a currency by three-letter ISO code.
         * @param {string} id The three-letter ISO code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GetCurrency: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1GetCurrency', 'id', id)
            const localVarPath = `/v1/currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available currencies.
         * @param {Array<V1ListCurrenciesTypeEnum>} [type] Filter currencies by the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ListCurrencies: async (type?: Array<V1ListCurrenciesTypeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrenciesApi - functional programming interface
 * @export
 */
export const CurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a currency by three-letter ISO code.
         * @param {string} id The three-letter ISO code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GetCurrency(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Currency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetCurrency(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrenciesApi.v1GetCurrency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List available currencies.
         * @param {Array<V1ListCurrenciesTypeEnum>} [type] Filter currencies by the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ListCurrencies(type?: Array<V1ListCurrenciesTypeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ListCurrencies(type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrenciesApi.v1ListCurrencies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrenciesApi - factory interface
 * @export
 */
export const CurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a currency by three-letter ISO code.
         * @param {CurrenciesApiV1GetCurrencyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GetCurrency(requestParameters: CurrenciesApiV1GetCurrencyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Currency> {
            return localVarFp.v1GetCurrency(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List available currencies.
         * @param {CurrenciesApiV1ListCurrenciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ListCurrencies(requestParameters: CurrenciesApiV1ListCurrenciesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CurrencyList> {
            return localVarFp.v1ListCurrencies(requestParameters.type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1GetCurrency operation in CurrenciesApi.
 * @export
 * @interface CurrenciesApiV1GetCurrencyRequest
 */
export interface CurrenciesApiV1GetCurrencyRequest {
    /**
     * The three-letter ISO code.
     * @type {string}
     * @memberof CurrenciesApiV1GetCurrency
     */
    readonly id: string
}

/**
 * Request parameters for v1ListCurrencies operation in CurrenciesApi.
 * @export
 * @interface CurrenciesApiV1ListCurrenciesRequest
 */
export interface CurrenciesApiV1ListCurrenciesRequest {
    /**
     * Filter currencies by the given type.
     * @type {Array<'standard'>}
     * @memberof CurrenciesApiV1ListCurrencies
     */
    readonly type?: Array<V1ListCurrenciesTypeEnum>
}

/**
 * CurrenciesApi - object-oriented interface
 * @export
 * @class CurrenciesApi
 * @extends {BaseAPI}
 */
export class CurrenciesApi extends BaseAPI {
    /**
     * 
     * @summary Get a currency by three-letter ISO code.
     * @param {CurrenciesApiV1GetCurrencyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public v1GetCurrency(requestParameters: CurrenciesApiV1GetCurrencyRequest, options?: RawAxiosRequestConfig) {
        return CurrenciesApiFp(this.configuration).v1GetCurrency(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List available currencies.
     * @param {CurrenciesApiV1ListCurrenciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public v1ListCurrencies(requestParameters: CurrenciesApiV1ListCurrenciesRequest = {}, options?: RawAxiosRequestConfig) {
        return CurrenciesApiFp(this.configuration).v1ListCurrencies(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const V1ListCurrenciesTypeEnum = {
    STANDARD: 'standard'
} as const;
export type V1ListCurrenciesTypeEnum = typeof V1ListCurrenciesTypeEnum[keyof typeof V1ListCurrenciesTypeEnum];
