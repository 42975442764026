import { UseQueryOptions } from "react-query";
import { validatePrimaryAccountIdentifier } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth/useAuthQuery";

interface Result {
  isValid: boolean;
}

interface Attrs {
  subscriptionId?: number;
  bic?: Bic;
  accountIdentifier?: string;
}

export const useValidatePrimaryAccountIdentifier = (
  { subscriptionId, bic, accountIdentifier }: Attrs,
  options: UseQueryOptions<Result> = {}
) =>
  useAuthQuery(
    [
      "validate-primary-account-identifier",
      subscriptionId!,
      bic!,
      accountIdentifier!,
    ],
    ({ token }) => {
      if (!accountIdentifier) {
        // this is needed to fix cashback wizard reload in e2e tests for account identifier step
        return Promise.resolve({ isValid: false });
      }
      return validatePrimaryAccountIdentifier(
        token,
        subscriptionId!,
        bic!,
        accountIdentifier!
      );
    },
    { enabled: !!subscriptionId && !!bic && !!accountIdentifier, ...options }
  );
