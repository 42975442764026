import { UseQueryOptions } from "react-query";
import { useAuthQuery } from "@api/auth";
import { getAccounts } from "@api/v1/methods";
import { Account, RentabloBankConnectionTypeEnum } from "@api/v1/model";

export const useCashAccounts = (
  bankConnectionType: RentabloBankConnectionTypeEnum,
  options?: UseQueryOptions<Account[], AxiosApiError, Account[]>
) =>
  useAuthQuery(
    ["cash-accounts"],
    async ({ token }) => {
      const response = await getAccounts(token, {
        type: "02_cash",
        bankConnectionType: bankConnectionType,
      });
      return response.accounts;
    },
    options
  );
