import { InvokeCallback, Sender } from "xstate";
import { getDividends } from "@api/v1/methods/dividends";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const loadDividends =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "LOADED_INVESTMENT") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    if (!context.investment?.id) {
      throw new Error("Invalid investment");
    }

    load(context.investment.id, callback);
  };

const load = async (id: number, callback: Sender<DividendWizardEvent>) => {
  try {
    const { dividends = [] } = await getDividends({
      investmentId: [id],
      excludeImported: false,
    });

    const availableDividends = dividends.filter(
      ({ numberOfLots = 0 }) => numberOfLots > 0
    );

    callback({
      type: "LOADED_DIVIDENDS",
      dividends: availableDividends,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
