import { UseMutationOptions } from "react-query";
import {
  FileUploadControllerApiUploadFilesRequest,
  ActivityList,
} from "@generated/apiv1/pdfParser";

import { buildFileUploadControllerApi } from "@generated/apiv1/pdfParser/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const uploadFiles = async (
  token: string | undefined,
  params?: FileUploadControllerApiUploadFilesRequest
) => {
  const apiInstance = buildFileUploadControllerApi(token);
  const { data } = await apiInstance.uploadFiles(params);
  return data;
};

export const useUploadFiles = (
  options?: UseMutationOptions<
    ActivityList,
    AxiosApiError,
    FileUploadControllerApiUploadFilesRequest
  >
) => {
  return useAuthMutation(uploadFiles, options, false);
};
