import { InvestmentTypeEnum, BookingTypeEnum } from "@generated/apiv1";
import { toCurrency } from "@helpers";
import { toPercent, toGermanNumberFormat } from "./numberFormat";

export type PercentageRange = "0-1" | "0-100";

export interface GetValueByInvestmentAndBookingType {
  value: number;
  investmentType: InvestmentTypeEnum;
  bookingType?: BookingTypeEnum;
  currency?: string;
  percentageRange?: PercentageRange;
}

export const getValueByInvestmentAndBookingType = ({
  value,
  investmentType,
  bookingType,
  currency = "EUR",
  percentageRange = "0-1",
}: GetValueByInvestmentAndBookingType): string => {
  if (bookingType === "split" || bookingType === "reverse_split") {
    return "";
  }

  if (investmentType === "31_bond") {
    if (bookingType === "dividend" || bookingType === "purchase_right") {
      return toCurrency(value, currency);
    }

    if (percentageRange === "0-100") {
      const formattedValue = toGermanNumberFormat(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `${formattedValue}%`;
    }

    return toPercent(value);
  }

  return toCurrency(value, currency);
};
