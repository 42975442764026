import { UseQueryOptions } from "react-query";
import {
  FundamentalsApiFundamentalsIdFinancialsRequest,
  FundamentalList,
} from "@generated/apiv1";

import { buildFundamentalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundamentalsIdFinancials = async (
  token: string | undefined,
  params: FundamentalsApiFundamentalsIdFinancialsRequest
) => {
  const apiInstance = buildFundamentalsApi(token);
  const { data } = await apiInstance.fundamentalsIdFinancials(params);
  return data;
};

export const useFundamentalsIdFinancials = (
  params: FundamentalsApiFundamentalsIdFinancialsRequest,
  options?: UseQueryOptions<FundamentalList, AxiosApiError, FundamentalList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDAMENTALS_FUNDAMENTALS_ID_FINANCIALS, params],
    ({ token }) => fundamentalsIdFinancials(token, params),
    options
  );
};
