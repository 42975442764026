import { UseMutationOptions } from "react-query";
import { AuthApiLoginWithAppleRequest, LoginData } from "@generated/user";

import { buildAuthApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const loginWithApple = async (
  token: string | undefined,
  params: AuthApiLoginWithAppleRequest
) => {
  const apiInstance = buildAuthApi(token);
  const { data } = await apiInstance.loginWithApple(params);
  return data;
};

export const useLoginWithApple = (
  options?: UseMutationOptions<
    LoginData,
    AxiosApiError,
    AuthApiLoginWithAppleRequest
  >
) => {
  return useAuthMutation(loginWithApple, options, true);
};
