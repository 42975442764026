import i18next from "i18next";
import { BookingTypeEnum } from "@generated/apiv1";
import { toGermanNumberFormat } from "./numberFormat";

export interface GetNumberOfLotsByInvestmentAndBookingType {
  value: number;
  bookingType: BookingTypeEnum;
  noPrice?: boolean;
}

export const getNumberOfLotsByInvestmentAndBookingType = ({
  value,
  bookingType,
  noPrice = false,
}: GetNumberOfLotsByInvestmentAndBookingType): string => {
  const formattedValue = toGermanNumberFormat(value);

  if (bookingType === "split") {
    return `1:${formattedValue}`;
  }
  if (bookingType === "reverse_split") {
    return `${formattedValue}:1`;
  }

  if (bookingType === "dividend" || bookingType === "purchase_right") return "";

  return noPrice
    ? `${formattedValue} ${i18next.t(
        "components.depotsStatistic.bookingsTable.lots"
      )}`
    : `${formattedValue} x`;
};
