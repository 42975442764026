import { useCallback } from "react";

export const useGoogleTagManager = (userFlowId: string) => {
  const pushData = useCallback(
    (data?: Record<string, string | undefined | null>) => {
      window.dataLayer?.push({
        event: "track-event",
        userFlowId,
        ...data,
      });
    },
    [userFlowId]
  );

  return {
    pushData,
  };
};
