import React from "react";
import styled from "@emotion/styled";
import { LogItem, SynchronizationLogItem } from "./SynchronizationLogItem";
import { NoUpdateRequired } from "./NoUpdateRequired";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export interface SynchronizationLogItemListProps {
  logItems: LogItem[];
}

export const SynchronizationLogItemList = ({
  logItems,
}: SynchronizationLogItemListProps) => {
  if (!logItems.length) {
    return (
      <Container>
        <NoUpdateRequired />
      </Container>
    );
  }

  return (
    <>
      {logItems.map((item) => {
        return (
          <Container key={`logItem-${item.id}`}>
            <SynchronizationLogItem logItem={item} />
          </Container>
        );
      })}
    </>
  );
};
