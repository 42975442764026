import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "@helpers/currencyCodeToSymbol";
import { RangeValue } from "@components";
import { ComparisonChartRangeSelector } from "@components/Chart/ComparisonChart/components";
import { DataBlockLayout } from "@components/common/dataBlock";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { ComparisonBaseChart } from "./ComparisonBaseChart";
import { FundamentalsAutocomplete } from "./components/FundamentalsAutocomplete";

interface Props {
  isLoading?: boolean;
  defaultRange?: RangeValue;
  data: any;
  dataKey1: string;
  dataKey2?: string;
  onRangeSelect: (value: RangeValue) => void;
  onMetricSelected: (value?: string) => void;
  onHide: () => void;
  metricCurrency1?: string;
  metricCurrency2?: string;
  quoteCurrency: string;
  onlyPro?: boolean;
  noData?: boolean;
}

const FiltersContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),

  "& > *": {
    width: "50%",
  },
}));

const StyledContentBlock = styled(DataBlockLayout)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
  },
}));

const withCurrency = (label: string, currency?: string) => {
  if (!currency) {
    return label;
  }
  return `${label} (${getCurrencySymbol(currency)})`;
};

export const ComparisonChartWithFilters = ({
  isLoading = false,
  defaultRange,
  data,
  dataKey1,
  dataKey2,
  onRangeSelect,
  onMetricSelected,
  onHide,
  metricCurrency1,
  metricCurrency2,
  quoteCurrency,
  onlyPro,
  noData = false,
}: Props) => {
  const { t } = useTranslation();

  const label1 = dataKey1 && t(`fundamentals.metrics.${dataKey1}.short`);
  const shortLabel1 =
    dataKey1 &&
    withCurrency(t(`fundamentals.metrics.${dataKey1}.short`), metricCurrency1);

  const desc1 = dataKey1 && t(`fundamentals.metrics.${dataKey1}.desc`);
  const label2 = dataKey2 && t(`fundamentals.metrics.${dataKey2}.short`);
  const shortLabel2 =
    dataKey2 &&
    withCurrency(t(`fundamentals.metrics.${dataKey2}.short`), metricCurrency2);

  const desc2 = dataKey2 && t(`fundamentals.metrics.${dataKey2}.desc`);

  const title = label2
    ? `${label1} ${t("fundamentals.comparisonChart.to")} ${label2}`
    : label1;

  const quoteLabel = withCurrency(
    t("fundamentals.comparisonChart.quote"),
    quoteCurrency
  );

  return (
    <StyledContentBlock
      title={title}
      isLoading={isLoading}
      onHide={onHide}
      toolTipData={{ desc1, desc2 }}
    >
      <FiltersContainer>
        <ComparisonChartRangeSelector
          defaultValue={defaultRange}
          onSelect={onRangeSelect}
        />
        <FundamentalsAutocomplete
          defaultValue={dataKey2}
          excludeKey={dataKey1}
          onChange={onMetricSelected}
          style={{ maxWidth: "250px" }}
        />
      </FiltersContainer>

      <div
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        {noData ? (
          <NoDataStub />
        ) : (
          <ComparisonBaseChart
            quoteLabel={quoteLabel}
            data={data}
            shortLabel1={shortLabel1}
            shortLabel2={shortLabel2}
            dataKey1={dataKey1}
            dataKey2={dataKey2}
          />
        )}
      </div>
    </StyledContentBlock>
  );
};
