import { UseMutationOptions } from "react-query";
import { UsersApiConfirmRequest } from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const confirm = async (
  token: string | undefined,
  params: UsersApiConfirmRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.confirm(params);
  return data;
};

export const useConfirm = (
  options?: UseMutationOptions<void, AxiosApiError, UsersApiConfirmRequest>
) => {
  return useAuthMutation(confirm, options, true);
};
