import React from "react";
import { useDividendsPerYearForInvestment } from "../../hooks/useDividendsPerYearForInvestment";
import { DividendsBaseChart } from "./DividendsBaseChart";

interface Props {
  investmentId: number;
}

export const DividendsByInvestmentChart = ({ investmentId }: Props) => {
  const { data: dividends, isLoading } =
    useDividendsPerYearForInvestment(investmentId);

  return <DividendsBaseChart dividends={dividends} isLoading={isLoading} />;
};
