import { UseMutationOptions } from "react-query";
import { OtpApiChangeOtpLabelRequest, User } from "@generated/user";

import { buildOtpApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const changeOtpLabel = async (
  token: string | undefined,
  params: OtpApiChangeOtpLabelRequest
) => {
  const apiInstance = buildOtpApi(token);
  const { data } = await apiInstance.changeOtpLabel(params);
  return data;
};

export const useChangeOtpLabel = (
  options?: UseMutationOptions<User, AxiosApiError, OtpApiChangeOtpLabelRequest>
) => {
  return useAuthMutation(changeOtpLabel, options, true);
};
