import React from "react";
import { cloneDeep } from "lodash";
import { Box, Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dividend, DividendsParams } from "@api";
import { LoadingPlaceHolder } from "@components";
import { ReactComponent as WarningIcon } from "@icons/myicons/warning-circle-filled.svg";
import { useDividends } from "@api/v1/hooks/useDividends";
import { DividendTable } from "./DividendTable";

interface HeaderTabProps {
  missingDividendsCount: number;
}

const dataConverter = (dividends?: Dividend[]): Dividend[] => {
  if (!dividends) return [];

  // Note: WARNING - We have to use cloneDeep because of reverse method mutates the array
  // Note: We should reverse the array because dates order from new to old
  return cloneDeep(dividends)
    .reverse()
    .map(
      ({
        bookingId,
        date = "",
        dividendPerShare = 0,
        numberOfLots = 0,
        grossAmount = 0,
        investment,
      }) => {
        const { currency = "EUR" } = investment!;

        return {
          bookingId,
          date,
          currency,
          dividendPerShare,
          numberOfLots,
          grossAmount,
        };
      }
    );
};

const HeaderTab = styled("div")<HeaderTabProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ missingDividendsCount }) =>
    missingDividendsCount ? "space-between" : "flex-end"};
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

const Container = styled("div")`
  padding-top: ${({ theme }) => theme.spacing(4)};
`;

const MissingDividendHeader = styled("div")`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    gap: 0;
  }
`;

const MissingTextContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const MissingDividendsIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DividendsTableContainer = ({
  tickerSymbol,
}: {
  tickerSymbol?: string;
}) => {
  const { t } = useTranslation();
  const { investmentId } = useParams() as { investmentId: string };
  const navigate = useNavigate();
  const parsedInvestmentId = Number.parseInt(investmentId, 10);

  const dividendParams: DividendsParams = {
    investmentId: [parsedInvestmentId],
    excludeImported: false,
  };

  const {
    data: dividendsData,
    isLoading: isDividendsLoading,
    isFetched,
  } = useDividends(dividendParams);

  if (isDividendsLoading)
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );

  const convertedDividends = dataConverter(dividendsData?.dividends);
  const unreceivedDividendsCount = convertedDividends.filter(
    ({ bookingId, numberOfLots = 0 }) => !bookingId && numberOfLots > 0
  ).length;

  const editLink = (bookingId: string) => {
    navigate(`/investments/${investmentId}/dividend-wizard/edit/${bookingId}`);
  };
  const createLink = (path: string) => {
    navigate(
      `/investments/${investmentId}/dividend-wizard/create?date=${path}`
    );
  };

  return (
    <Container>
      <HeaderTab missingDividendsCount={unreceivedDividendsCount}>
        {Boolean(unreceivedDividendsCount) && (
          <MissingDividendHeader data-testid="missing-dividends-container">
            <MissingTextContainer>
              <MissingDividendsIcon />
              <Typography variant="body1">
                {t("investment.dividends.missingDividends.header", {
                  count: unreceivedDividendsCount,
                  value: unreceivedDividendsCount,
                })}
              </Typography>
            </MissingTextContainer>
            <Button
              variant="text"
              color="primary"
              size="small"
              component={Link}
              to={`/investments/${investmentId}/dividend-wizard/create/batch`}
            >
              {t("investment.dividends.missingDividends.addBatchButton")}
            </Button>
          </MissingDividendHeader>
        )}
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to={`/investments/${investmentId}/dividend-wizard/create`}
        >
          {t("investment.dividends.addDividends")}
        </Button>
      </HeaderTab>
      <Box
        sx={{
          overflow: "auto",
        }}
      >
        <DividendTable
          dividends={convertedDividends}
          edit={editLink}
          create={createLink}
          isFetched={isFetched}
          investmentId={parsedInvestmentId}
          tickerSymbol={tickerSymbol}
        />
      </Box>
    </Container>
  );
};
