import { UseQueryOptions } from "react-query";
import {
  FinancialGoalsApiListAndSearchAllFinancialGoalsRequest,
  FinancialGoal,
} from "@generated/apiv1";

import { buildFinancialGoalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllFinancialGoals = async (
  token: string | undefined,
  params?: FinancialGoalsApiListAndSearchAllFinancialGoalsRequest
) => {
  const apiInstance = buildFinancialGoalsApi(token);
  const { data } = await apiInstance.listAndSearchAllFinancialGoals(params);
  return data;
};

export const useListAndSearchAllFinancialGoals = (
  params?: FinancialGoalsApiListAndSearchAllFinancialGoalsRequest,
  options?: UseQueryOptions<FinancialGoal, AxiosApiError, FinancialGoal>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FINANCIALGOALS_LIST_AND_SEARCH_ALL_FINANCIAL_GOALS, params],
    ({ token }) => listAndSearchAllFinancialGoals(token, params),
    options
  );
};
