import React from "react";
import { Box, styled, Typography, TypographyProps } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ReactComponent as CopyIcon } from "@icons/myicons/content_copy.svg";

const CopyTypographyContainer = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  gap: theme.spacing(1),
  alignItems: "center",
  cursor: "pointer",

  "&:hover": {
    "& .MuiTypography-root, & > div": {
      color: theme.palette.primary.main,
    },
  },
}));

type Props = {
  children: string;
  iconColor?: string;
  text?: string;
  iconPosition?: "start" | "end";
} & TypographyProps;

export const CopyTypography = ({
  children,
  iconColor = "text.secondary",
  iconPosition = "start",
  text,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(children);
    enqueueSnackbar(t("copiedToClipboard"), {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  return (
    <CopyTypographyContainer onClick={handleCopy}>
      {iconPosition === "start" && (
        <Box color={iconColor} display="flex" alignItems="center">
          <CopyIcon />
        </Box>
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        fontWeight={400}
        {...rest}
      >
        {text ? text : children}
      </Typography>
      {iconPosition === "end" && (
        <Box color={iconColor} display="flex" alignItems="center">
          <CopyIcon />
        </Box>
      )}
    </CopyTypographyContainer>
  );
};
