import { getImpersonatedUserToken } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

import { impersonate } from "@features/login/impersonate/impersonateTokenHandler";

const requestImpersonateToken = async (
  token: string | undefined,
  { id }: { id: number }
) => {
  const userToken = await getImpersonatedUserToken(token, id);
  impersonate(userToken);
  return userToken;
};

export const useImpersonateUser = () =>
  useAuthMutation(requestImpersonateToken);
