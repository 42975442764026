import {
  Dividend,
  Investment,
  CreateMultipleBookingParams,
  TransactionValidationError,
  Booking,
} from "@api";
import { DividendValues } from "./dividendValues";

export interface DividendWizardContext {
  dividends: Dividend[];
  dividendValues: DividendValues;
  selectedDividends: Dividend[];
  selectedDividendsWithTaxRate: Dividend[];
  investment: Investment | null;
  error: ApiException | null;
  customTaxAmount: number;
  validationErrors: TransactionValidationError[];
  validatedMultipleBookingParams?: CreateMultipleBookingParams;
  bookingId?: number;
  booking: Booking | null;
  skipSummaryStep?: boolean;
}

export const defaultContext: DividendWizardContext = {
  dividends: [],
  selectedDividends: [],
  selectedDividendsWithTaxRate: [],
  investment: null,
  error: null,
  validationErrors: [],
  customTaxAmount: 0,
  booking: null,
  dividendValues: {
    date: new Date(),
    type: "dividend",
    exchangeRate: 1,
    taxAndCommissionInEur: true,
  },
  skipSummaryStep: false,
};
