import { UseQueryOptions } from "react-query";
import { UserAuthorization } from "@generated/apiv1";

import { buildAuthorizationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getAuthorization = async (token: string | undefined) => {
  const apiInstance = buildAuthorizationsApi(token);
  const { data } = await apiInstance.getAuthorization();
  return data;
};

export const useGetAuthorization = (
  options?: UseQueryOptions<UserAuthorization, AxiosApiError, UserAuthorization>
) => {
  return useAuthQuery(
    [CACHE_KEYS.AUTHORIZATIONS_GET_AUTHORIZATION],
    ({ token }) => getAuthorization(token),
    options
  );
};
