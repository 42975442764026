import React from "react";
import { useParams } from "react-router-dom";
import { EditDividendWizard } from "./EditDividendWizard";

export const EditDividendWizardWithRouting = () => {
  const { investmentId, bookingId } = useParams<"investmentId" | "bookingId">();

  return (
    <EditDividendWizard
      investmentId={Number.parseInt(investmentId!, 10)}
      bookingId={Number.parseInt(bookingId!, 10)}
    />
  );
};
