import { UseQueryOptions } from "react-query";
import {
  ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest,
  InvestmentTypeBreakdownList,
} from "@generated/apiv1";

import { buildClassificationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listCurrencyClassificationsForAllInvestments = async (
  token: string | undefined,
  params?: ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest
) => {
  const apiInstance = buildClassificationsApi(token);
  const { data } =
    await apiInstance.listCurrencyClassificationsForAllInvestments(params);
  return data;
};

export const useListCurrencyClassificationsForAllInvestments = (
  params?: ClassificationsApiListCurrencyClassificationsForAllInvestmentsRequest,
  options?: UseQueryOptions<
    InvestmentTypeBreakdownList,
    AxiosApiError,
    InvestmentTypeBreakdownList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CLASSIFICATIONS_LIST_CURRENCY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
      params,
    ],
    ({ token }) => listCurrencyClassificationsForAllInvestments(token, params),
    options
  );
};
