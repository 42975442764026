import { UseQueryOptions } from "react-query";
import {
  UsersApiListAndSearchAllUsersRequest,
  PageableUserList,
} from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllUsers = async (
  token: string | undefined,
  params?: UsersApiListAndSearchAllUsersRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.listAndSearchAllUsers(params);
  return data;
};

export const useListAndSearchAllUsers = (
  params?: UsersApiListAndSearchAllUsersRequest,
  options?: UseQueryOptions<PageableUserList, AxiosApiError, PageableUserList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERS_LIST_AND_SEARCH_ALL_USERS, params],
    ({ token }) => listAndSearchAllUsers(token, params),
    options
  );
};
