import { UseQueryOptions } from "react-query";
import { PerformanceApiAbsoluteReturnRequest } from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const absoluteReturn = async (
  token: string | undefined,
  params: PerformanceApiAbsoluteReturnRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.absoluteReturn(params);
  return data;
};

export const useAbsoluteReturn = (
  params: PerformanceApiAbsoluteReturnRequest,
  options?: UseQueryOptions<number, AxiosApiError, number>
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_ABSOLUTE_RETURN, params],
    ({ token }) => absoluteReturn(token, params),
    options
  );
};
