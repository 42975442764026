import { InvokeCallback, Sender } from "xstate";

import {
  createCarveOutWithToken,
  createOrUpdateBookingsWithToken,
  createOrUpdateManagedDepotBookingWithToken,
  createSwitchWithToken,
} from "@api/v1/methods/bookings";
import { BookingValues } from "../types/bookingValues";
import { InvestmentValues } from "../types/investmentValues";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";
import {
  buildBookingParams,
  buildCarveOutParams,
  buildInvestmentParams,
  buildInvestmentParamsList,
  buildManagedDepotParams,
  buildSwitchParams,
} from "../utils/buildRequestParams";
import { isCapitalMeasure } from "../utils/utils";

export const persistBooking =
  (
    context: TransactionWizardContext
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (!context.investmentValues.depotId)
      throw new Error("no depot ID provided");
    if (!context.bookingValues.type) throw new Error("No booking type defined");
    if (
      isCapitalMeasure(context.bookingValues.type) &&
      !context.sourceInvestmentValues?.id
    )
      throw new Error("No source investment ID defined");

    if (context.investmentValues.type === "91_managed") {
      persistManagedDepot(
        callback,
        context.investmentValues,
        context.bookingValues
      );
      return;
    }

    isCapitalMeasure(context.bookingValues.type)
      ? persistCapitalMeasure(
          callback,
          context.sourceInvestmentValues?.id!,
          context.investmentValues,
          context.bookingValues
        )
      : persistStandard(
          callback,
          context.investmentValues,
          context.bookingValues
        );
  };

const persistStandard = async (
  callback: Sender<TransactionWizardEvent>,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    if (!investmentValues.depotId) throw new Error(`No depot ID provided`);

    const paramsList = buildInvestmentParamsList([
      buildInvestmentParams(investmentValues, [
        buildBookingParams(bookingValues),
      ]),
    ]);

    const result = await createOrUpdateBookingsWithToken({
      accountId: investmentValues.depotId,
      performBackgroundTransactionSync: false,
      autoAssignQuotes: false,
      createDepotSynchronizationLog: false,
      createOrUpdateInvestmentParamsList:
        paramsList.createOrUpdateInvestmentParamsList,
    });

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      return;
    }

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage
          : "Validation failed",
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};

const persistCapitalMeasure = async (
  callback: Sender<TransactionWizardEvent>,
  sourceInvestmentId: number,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    const investmentParams = buildInvestmentParams(investmentValues, [
      buildBookingParams(bookingValues),
    ]);

    const result =
      bookingValues.type === "carve_out"
        ? await createCarveOutWithToken(
            buildCarveOutParams(sourceInvestmentId, investmentParams)
          )
        : await createSwitchWithToken(
            buildSwitchParams(sourceInvestmentId, investmentParams)
          );

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });

      return;
    }

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage
          : "Validation failed",
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};

const persistManagedDepot = async (
  callback: Sender<TransactionWizardEvent>,
  investmentValues: InvestmentValues,
  bookingValues: BookingValues
) => {
  try {
    const investmentParams = buildInvestmentParams(investmentValues, [
      buildBookingParams(bookingValues),
    ]);

    if (!bookingValues.entryQuote) throw new Error("no entry quote provided");

    const result = await createOrUpdateManagedDepotBookingWithToken(
      buildManagedDepotParams(
        bookingValues.entryQuote,
        bookingValues.depotValuationAfterBooking == null
          ? bookingValues.entryQuote
          : bookingValues.depotValuationAfterBooking,
        investmentParams
      )
    );

    if (!result.hasValidationErrors) {
      callback({
        type: "BOOKING_VALID",
      });
      return;
    }

    callback({
      type: "BOOKING_INVALID",
      error:
        result.transactionValidationErrors.length > 0
          ? result.transactionValidationErrors[0].errorMessage
          : "Validation failed",
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
