import { BookingTypeEnum } from "@api";
import { round, isDateValid } from "@helpers";
import { BookingValues } from "../stateMachine/types/bookingValues";
import { InvestmentValues } from "../stateMachine/types/investmentValues";

export interface Amounts {
  amount: number;
  amountAC: number;
}

export interface PriceWithAmount {
  price: number;
  amount: number;
}

const expensesSign = (type: BookingTypeEnum) =>
  ["buy", "book_in", "carve_out"].includes(type) ? -1 : 1;

export const getAmounts = (
  bookingType: BookingTypeEnum,
  numberOfLots: number,
  price: number,
  exchangeRate: number,
  commission: number,
  taxAmount: number,
  priceInPercent: boolean,
  taxAndCommissionInEur: boolean
): Amounts => {
  const percentFactor = priceInPercent ? 0.01 : 1;
  const taxAndCommission = (commission + taxAmount) * expensesSign(bookingType);
  const amount =
    numberOfLots * price * percentFactor -
    (taxAndCommissionInEur
      ? taxAndCommission * exchangeRate
      : taxAndCommission);
  const amountAC =
    (numberOfLots * price * percentFactor) / exchangeRate -
    (taxAndCommissionInEur
      ? taxAndCommission
      : taxAndCommission / exchangeRate);

  return {
    amount: round(amount),
    amountAC: round(amountAC),
  };
};

const getPriceAndTotalAmountAC = (
  bookingType: BookingTypeEnum,
  numberOfLots: number,
  totalAmount: number,
  exchangeRate: number,
  commission: number,
  taxAmount: number,
  priceInPercent: boolean,
  taxAndCommissionInEur: boolean
): PriceWithAmount => {
  const percentFactor = priceInPercent ? 100 : 1;
  const taxAndCommission = (commission + taxAmount) * expensesSign(bookingType);
  const amountAC = totalAmount / exchangeRate;

  const amountBeforeExpenses =
    totalAmount -
    (taxAndCommissionInEur
      ? taxAndCommission * exchangeRate
      : taxAndCommission);
  const securityPrice = (amountBeforeExpenses / numberOfLots) * percentFactor;

  return { price: round(securityPrice), amount: round(amountAC) };
};

const getPriceAndTotalAmount = (
  bookingType: BookingTypeEnum,
  numberOfLots: number,
  totalAmountAC: number,
  exchangeRate: number,
  commission: number,
  taxAmount: number,
  priceInPercent: boolean,
  taxAndCommissionInEur: boolean
): PriceWithAmount => {
  const percentFactor = priceInPercent ? 100 : 1;
  const taxAndCommission = (commission + taxAmount) * expensesSign(bookingType);
  const amount = totalAmountAC * exchangeRate;

  const amountACBeforeExpenses =
    totalAmountAC -
    (taxAndCommissionInEur
      ? taxAndCommission
      : taxAndCommission / exchangeRate);
  const securityPrice =
    ((amountACBeforeExpenses * exchangeRate) / numberOfLots) * percentFactor;

  return { price: round(securityPrice), amount: round(amount) };
};

const gramToOunces = (gram?: number) => {
  if (!gram) return gram;
  return gram / 31.1034768;
};

const ouncesToGram = (ounces?: number) => {
  if (!ounces) return ounces;
  return round(ounces * 31.1034768, 6);
};

const definedAndPositive = (value?: number): boolean => {
  return !!value && value > 0;
};

const validate = (
  values: BookingValues,
  investmentValues?: InvestmentValues
) => {
  const isManaged = investmentValues?.type === "91_managed";

  if (
    values.type === "book_in" &&
    !definedAndPositive(values.securityPrice) &&
    !isManaged
  )
    return false;

  if (
    isManaged &&
    investmentValues?.id &&
    values.depotValuationAfterBooking == null
  )
    return false;

  return (
    isDateValid(values.date) &&
    definedAndPositive(values.exchangeRate) &&
    definedAndPositive(values.entryQuote) &&
    (isManaged || definedAndPositive(values.numberOfLots))
  );
};

export const useGetBookingValues = () => {
  return {
    getAmounts,
    getPriceAndTotalAmountAC,
    getPriceAndTotalAmount,
    gramToOunces,
    ouncesToGram,
    validate,
  };
};
