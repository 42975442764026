/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AbsoluteReturnList } from '../model';
// @ts-ignore
import { CashFlowAndPerformanceStatisticsList } from '../model';
// @ts-ignore
import { CreateCashFlowAndPerformanceStatisticsParams } from '../model';
// @ts-ignore
import { CreateOrUpdateInvestmentParams } from '../model';
// @ts-ignore
import { CreatePerformanceIntervalsParams } from '../model';
// @ts-ignore
import { HistogramBinList } from '../model';
// @ts-ignore
import { InvestmentPerformance } from '../model';
// @ts-ignore
import { PerformanceIntervalList } from '../model';
// @ts-ignore
import { PerformanceTimePointList } from '../model';
// @ts-ignore
import { PortfolioPerformanceResultList } from '../model';
// @ts-ignore
import { RealizedGain } from '../model';
// @ts-ignore
import { RealizedGainByBooking } from '../model';
// @ts-ignore
import { RealizedGainByYearList } from '../model';
// @ts-ignore
import { RiskYieldStatistics } from '../model';
// @ts-ignore
import { TwrorResultList } from '../model';
/**
 * PerformanceApi - axios parameter creator
 * @export
 */
export const PerformanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the absolute return with or without dividends in EUR for the given investment.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {number} id Retrieve the absolute return for this investment.
         * @param {boolean} [includeDividends] Include dividends into the return calculation; default is true
         * @param {boolean} [excludeExpenses] Exclude expenses (like tax and commission) from the return calculation, default is false
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absoluteReturn: async (id: number, includeDividends?: boolean, excludeExpenses?: boolean, startDate?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absoluteReturn', 'id', id)
            const localVarPath = `/v1/performance/absoluteReturn/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeDividends !== undefined) {
                localVarQueryParameter['includeDividends'] = includeDividends;
            }

            if (excludeExpenses !== undefined) {
                localVarQueryParameter['excludeExpenses'] = excludeExpenses;
            }

            if (startDate) {
                localVarQueryParameter['startDate'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the absolute returns (with our without dividends) in EUR for the given investments.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {Array<number>} [investmentId] Retrieve statistics for the given investments
         * @param {Array<number>} [accountId] Retrieve statistics for all investments held by the given accounts
         * @param {boolean} [includeDividends] Include dividends into the return calculation; default is true
         * @param {boolean} [excludeExpenses] Exclude expenses (like tax and commission) from the return calculation, default is false
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
         * @param {string} [timeStep] The time step used for calculation; allowed values are day, week, month, year.                              Default is day. Note that the precision of the calculation will decrease with a longer                              time step, but runtime will increase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absoluteReturnByPosition: async (investmentId?: Array<number>, accountId?: Array<number>, includeDividends?: boolean, excludeExpenses?: boolean, startDate?: Array<string>, timeStep?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/performance/absoluteReturn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (includeDividends !== undefined) {
                localVarQueryParameter['includeDividends'] = includeDividends;
            }

            if (excludeExpenses !== undefined) {
                localVarQueryParameter['excludeExpenses'] = excludeExpenses;
            }

            if (startDate) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (timeStep !== undefined) {
                localVarQueryParameter['timeStep'] = timeStep;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Estimate the trading gain for a new (not yet persisted) transaction.
         * @param {number} id ID of the investment to check trading gain for
         * @param {CreateOrUpdateInvestmentParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimateTradingGainForNewTransaction: async (id: number, body: CreateOrUpdateInvestmentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('estimateTradingGainForNewTransaction', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('estimateTradingGainForNewTransaction', 'body', body)
            const localVarPath = `/v1/performance/realizedGains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the return on investment including the investments performance for the given investment.
         * @param {number} id Retrieve return on investment for this investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentPerformance: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investmentPerformance', 'id', id)
            const localVarPath = `/v1/performance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                      Calculate cash flow and performance statistics for the given accounts. By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceByDepots: async (body: CreateCashFlowAndPerformanceStatisticsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('performanceByDepots', 'body', body)
            const localVarPath = `/v1/performance/depots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a performance histogram including the performance of all ACTIVE users,                      using the given performance interval.
         * @param {string} [performanceInterval] The performance interval to use for histogram creation; supported values are                              six-month steps, so 1, 6, 12, 18 ... 60. The default value is 12.
         * @param {string} [percentile] Apply the given percentile for calculation of low, average and high performance cutoff                              ranges. Must be a value between 0 and 100. Only values within the boundary will be considered                              when defining the cutoff ranges. This way outlier may be filtered, e.g. by using a 10%                              cutoff to filter the lowest and highest 10%. Default is 5. Set to zero to use no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceHistogram: async (performanceInterval?: string, percentile?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/performance/histogram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (performanceInterval !== undefined) {
                localVarQueryParameter['performanceInterval'] = performanceInterval;
            }

            if (percentile !== undefined) {
                localVarQueryParameter['percentile'] = percentile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given accounts and intervals.                        Calculations are limited to 500 investments across all accounts; including active and                        historic positions.
         * @param {CreatePerformanceIntervalsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceIntervals: async (body: CreatePerformanceIntervalsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('performanceIntervals', 'body', body)
            const localVarPath = `/v1/performance/intervals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate performance for a set of time points defined by the given interval. Calculations                        are limited to 500 investments across all accounts; including active and historic positions.
         * @param {CreatePerformanceIntervalsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceTimePoints: async (body: CreatePerformanceIntervalsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('performanceTimePoints', 'body', body)
            const localVarPath = `/v1/performance/timePoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                     A simplified endpoint to calculate the TWROR portfolio performance for the given accounts.                      This simplified endpoint may also be used for retrieving a friend\'s portfolio performance as it                      does not include any cash-flow or balance information. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {Array<number>} accountId Include the given accounts in the performance calculation
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Start performance calculation at the given date.                              Default is 5 years ago.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolio: async (accountId: Array<number>, startDate?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('portfolio', 'accountId', accountId)
            const localVarPath = `/v1/performance/portfolio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (startDate) {
                localVarQueryParameter['startDate'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The parameters for the calculation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPerformance: async (body: CreateCashFlowAndPerformanceStatisticsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('portfolioPerformance', 'body', body)
            const localVarPath = `/v2/performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {string} publicId Public ID of the user
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPortfolioPerformance: async (publicId: string, body: CreateCashFlowAndPerformanceStatisticsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicPortfolioPerformance', 'publicId', publicId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('publicPortfolioPerformance', 'body', body)
            const localVarPath = `/v2/public/{publicId}/performance`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate the yearly realized gains in total and by category. Categories are trading gains,                      dividend gains, interest gains, other gains and tax refunds. All values (except tax refunds)                      are available as gross and net values.
         * @param {Array<number>} accountId Calculate gains per year for the given accounts. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realizedGainsPerYear: async (accountId: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('realizedGainsPerYear', 'accountId', accountId)
            const localVarPath = `/v1/performance/realizedGains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED. Use the new riskYieldMetrics endpoints.                     Get risk yield statistics for the selected accounts or investments
         * @param {Array<number>} [investmentId] Retrieve statistics for the given investments; Limited to 250 investments.
         * @param {Array<number>} [accountId] Retrieve statistics for all investments held by the given accounts; Limited to 250 investments.
         * @param {string} [interval] Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year; default is month.
         * @param {string} [numIntervals] The number of intervals to include in the calculation. Default is 36; minimum value                              is 5 and the maximum value is 252, the number of trading days per year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        riskYieldStatistics: async (investmentId?: Array<number>, accountId?: Array<number>, interval?: string, numIntervals?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/performance/riskYieldEstimates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (numIntervals !== undefined) {
                localVarQueryParameter['numIntervals'] = numIntervals;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate the detailed trading gain for an investment. Trading gain is given accumulated and                      for each of the investments transactions.
         * @param {number} id Calculate the trading gain for the given investment and all of its transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingGainForInvestment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tradingGainForInvestment', 'id', id)
            const localVarPath = `/v1/performance/realizedGains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerformanceApi - functional programming interface
 * @export
 */
export const PerformanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerformanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve the absolute return with or without dividends in EUR for the given investment.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {number} id Retrieve the absolute return for this investment.
         * @param {boolean} [includeDividends] Include dividends into the return calculation; default is true
         * @param {boolean} [excludeExpenses] Exclude expenses (like tax and commission) from the return calculation, default is false
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absoluteReturn(id: number, includeDividends?: boolean, excludeExpenses?: boolean, startDate?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absoluteReturn(id, includeDividends, excludeExpenses, startDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.absoluteReturn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the absolute returns (with our without dividends) in EUR for the given investments.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {Array<number>} [investmentId] Retrieve statistics for the given investments
         * @param {Array<number>} [accountId] Retrieve statistics for all investments held by the given accounts
         * @param {boolean} [includeDividends] Include dividends into the return calculation; default is true
         * @param {boolean} [excludeExpenses] Exclude expenses (like tax and commission) from the return calculation, default is false
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
         * @param {string} [timeStep] The time step used for calculation; allowed values are day, week, month, year.                              Default is day. Note that the precision of the calculation will decrease with a longer                              time step, but runtime will increase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absoluteReturnByPosition(investmentId?: Array<number>, accountId?: Array<number>, includeDividends?: boolean, excludeExpenses?: boolean, startDate?: Array<string>, timeStep?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsoluteReturnList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absoluteReturnByPosition(investmentId, accountId, includeDividends, excludeExpenses, startDate, timeStep, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.absoluteReturnByPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Estimate the trading gain for a new (not yet persisted) transaction.
         * @param {number} id ID of the investment to check trading gain for
         * @param {CreateOrUpdateInvestmentParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async estimateTradingGainForNewTransaction(id: number, body: CreateOrUpdateInvestmentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealizedGain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.estimateTradingGainForNewTransaction(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.estimateTradingGainForNewTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the return on investment including the investments performance for the given investment.
         * @param {number} id Retrieve return on investment for this investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investmentPerformance(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentPerformance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investmentPerformance(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.investmentPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                      Calculate cash flow and performance statistics for the given accounts. By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceByDepots(body: CreateCashFlowAndPerformanceStatisticsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashFlowAndPerformanceStatisticsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceByDepots(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.performanceByDepots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a performance histogram including the performance of all ACTIVE users,                      using the given performance interval.
         * @param {string} [performanceInterval] The performance interval to use for histogram creation; supported values are                              six-month steps, so 1, 6, 12, 18 ... 60. The default value is 12.
         * @param {string} [percentile] Apply the given percentile for calculation of low, average and high performance cutoff                              ranges. Must be a value between 0 and 100. Only values within the boundary will be considered                              when defining the cutoff ranges. This way outlier may be filtered, e.g. by using a 10%                              cutoff to filter the lowest and highest 10%. Default is 5. Set to zero to use no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceHistogram(performanceInterval?: string, percentile?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistogramBinList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceHistogram(performanceInterval, percentile, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.performanceHistogram']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given accounts and intervals.                        Calculations are limited to 500 investments across all accounts; including active and                        historic positions.
         * @param {CreatePerformanceIntervalsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceIntervals(body: CreatePerformanceIntervalsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceIntervalList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceIntervals(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.performanceIntervals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate performance for a set of time points defined by the given interval. Calculations                        are limited to 500 investments across all accounts; including active and historic positions.
         * @param {CreatePerformanceIntervalsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceTimePoints(body: CreatePerformanceIntervalsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceTimePointList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceTimePoints(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.performanceTimePoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                     A simplified endpoint to calculate the TWROR portfolio performance for the given accounts.                      This simplified endpoint may also be used for retrieving a friend\'s portfolio performance as it                      does not include any cash-flow or balance information. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {Array<number>} accountId Include the given accounts in the performance calculation
         * @param {Array<string>} [startDate] A date in format yyyy-MM-dd (US Format). Start performance calculation at the given date.                              Default is 5 years ago.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolio(accountId: Array<number>, startDate?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwrorResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolio(accountId, startDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.portfolio']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The parameters for the calculation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioPerformance(body: CreateCashFlowAndPerformanceStatisticsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPerformanceResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioPerformance(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.portfolioPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {string} publicId Public ID of the user
         * @param {CreateCashFlowAndPerformanceStatisticsParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicPortfolioPerformance(publicId: string, body: CreateCashFlowAndPerformanceStatisticsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPerformanceResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicPortfolioPerformance(publicId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.publicPortfolioPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate the yearly realized gains in total and by category. Categories are trading gains,                      dividend gains, interest gains, other gains and tax refunds. All values (except tax refunds)                      are available as gross and net values.
         * @param {Array<number>} accountId Calculate gains per year for the given accounts. Limited to 500 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async realizedGainsPerYear(accountId: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealizedGainByYearList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.realizedGainsPerYear(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.realizedGainsPerYear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED. Use the new riskYieldMetrics endpoints.                     Get risk yield statistics for the selected accounts or investments
         * @param {Array<number>} [investmentId] Retrieve statistics for the given investments; Limited to 250 investments.
         * @param {Array<number>} [accountId] Retrieve statistics for all investments held by the given accounts; Limited to 250 investments.
         * @param {string} [interval] Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year; default is month.
         * @param {string} [numIntervals] The number of intervals to include in the calculation. Default is 36; minimum value                              is 5 and the maximum value is 252, the number of trading days per year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async riskYieldStatistics(investmentId?: Array<number>, accountId?: Array<number>, interval?: string, numIntervals?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskYieldStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.riskYieldStatistics(investmentId, accountId, interval, numIntervals, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.riskYieldStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate the detailed trading gain for an investment. Trading gain is given accumulated and                      for each of the investments transactions.
         * @param {number} id Calculate the trading gain for the given investment and all of its transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingGainForInvestment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealizedGainByBooking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingGainForInvestment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PerformanceApi.tradingGainForInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PerformanceApi - factory interface
 * @export
 */
export const PerformanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerformanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve the absolute return with or without dividends in EUR for the given investment.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {PerformanceApiAbsoluteReturnRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absoluteReturn(requestParameters: PerformanceApiAbsoluteReturnRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.absoluteReturn(requestParameters.id, requestParameters.includeDividends, requestParameters.excludeExpenses, requestParameters.startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the absolute returns (with our without dividends) in EUR for the given investments.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
         * @param {PerformanceApiAbsoluteReturnByPositionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absoluteReturnByPosition(requestParameters: PerformanceApiAbsoluteReturnByPositionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AbsoluteReturnList> {
            return localVarFp.absoluteReturnByPosition(requestParameters.investmentId, requestParameters.accountId, requestParameters.includeDividends, requestParameters.excludeExpenses, requestParameters.startDate, requestParameters.timeStep, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Estimate the trading gain for a new (not yet persisted) transaction.
         * @param {PerformanceApiEstimateTradingGainForNewTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        estimateTradingGainForNewTransaction(requestParameters: PerformanceApiEstimateTradingGainForNewTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<RealizedGain> {
            return localVarFp.estimateTradingGainForNewTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the return on investment including the investments performance for the given investment.
         * @param {PerformanceApiInvestmentPerformanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentPerformance(requestParameters: PerformanceApiInvestmentPerformanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<InvestmentPerformance> {
            return localVarFp.investmentPerformance(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                      Calculate cash flow and performance statistics for the given accounts. By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {PerformanceApiPerformanceByDepotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceByDepots(requestParameters: PerformanceApiPerformanceByDepotsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CashFlowAndPerformanceStatisticsList> {
            return localVarFp.performanceByDepots(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a performance histogram including the performance of all ACTIVE users,                      using the given performance interval.
         * @param {PerformanceApiPerformanceHistogramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceHistogram(requestParameters: PerformanceApiPerformanceHistogramRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<HistogramBinList> {
            return localVarFp.performanceHistogram(requestParameters.performanceInterval, requestParameters.percentile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given accounts and intervals.                        Calculations are limited to 500 investments across all accounts; including active and                        historic positions.
         * @param {PerformanceApiPerformanceIntervalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceIntervals(requestParameters: PerformanceApiPerformanceIntervalsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PerformanceIntervalList> {
            return localVarFp.performanceIntervals(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate performance for a set of time points defined by the given interval. Calculations                        are limited to 500 investments across all accounts; including active and historic positions.
         * @param {PerformanceApiPerformanceTimePointsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceTimePoints(requestParameters: PerformanceApiPerformanceTimePointsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PerformanceTimePointList> {
            return localVarFp.performanceTimePoints(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED: use /v2/performance instead.                     A simplified endpoint to calculate the TWROR portfolio performance for the given accounts.                      This simplified endpoint may also be used for retrieving a friend\'s portfolio performance as it                      does not include any cash-flow or balance information. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {PerformanceApiPortfolioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolio(requestParameters: PerformanceApiPortfolioRequest, options?: RawAxiosRequestConfig): AxiosPromise<TwrorResultList> {
            return localVarFp.portfolio(requestParameters.accountId, requestParameters.startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {PerformanceApiPortfolioPerformanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPerformance(requestParameters: PerformanceApiPortfolioPerformanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<PortfolioPerformanceResultList> {
            return localVarFp.portfolioPerformance(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
         * @param {PerformanceApiPublicPortfolioPerformanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPortfolioPerformance(requestParameters: PerformanceApiPublicPortfolioPerformanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<PortfolioPerformanceResultList> {
            return localVarFp.publicPortfolioPerformance(requestParameters.publicId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate the yearly realized gains in total and by category. Categories are trading gains,                      dividend gains, interest gains, other gains and tax refunds. All values (except tax refunds)                      are available as gross and net values.
         * @param {PerformanceApiRealizedGainsPerYearRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        realizedGainsPerYear(requestParameters: PerformanceApiRealizedGainsPerYearRequest, options?: RawAxiosRequestConfig): AxiosPromise<RealizedGainByYearList> {
            return localVarFp.realizedGainsPerYear(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED. Use the new riskYieldMetrics endpoints.                     Get risk yield statistics for the selected accounts or investments
         * @param {PerformanceApiRiskYieldStatisticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        riskYieldStatistics(requestParameters: PerformanceApiRiskYieldStatisticsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RiskYieldStatistics> {
            return localVarFp.riskYieldStatistics(requestParameters.investmentId, requestParameters.accountId, requestParameters.interval, requestParameters.numIntervals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate the detailed trading gain for an investment. Trading gain is given accumulated and                      for each of the investments transactions.
         * @param {PerformanceApiTradingGainForInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingGainForInvestment(requestParameters: PerformanceApiTradingGainForInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<RealizedGainByBooking> {
            return localVarFp.tradingGainForInvestment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for absoluteReturn operation in PerformanceApi.
 * @export
 * @interface PerformanceApiAbsoluteReturnRequest
 */
export interface PerformanceApiAbsoluteReturnRequest {
    /**
     * Retrieve the absolute return for this investment.
     * @type {number}
     * @memberof PerformanceApiAbsoluteReturn
     */
    readonly id: number

    /**
     * Include dividends into the return calculation; default is true
     * @type {boolean}
     * @memberof PerformanceApiAbsoluteReturn
     */
    readonly includeDividends?: boolean

    /**
     * Exclude expenses (like tax and commission) from the return calculation, default is false
     * @type {boolean}
     * @memberof PerformanceApiAbsoluteReturn
     */
    readonly excludeExpenses?: boolean

    /**
     * A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
     * @type {Array<string>}
     * @memberof PerformanceApiAbsoluteReturn
     */
    readonly startDate?: Array<string>
}

/**
 * Request parameters for absoluteReturnByPosition operation in PerformanceApi.
 * @export
 * @interface PerformanceApiAbsoluteReturnByPositionRequest
 */
export interface PerformanceApiAbsoluteReturnByPositionRequest {
    /**
     * Retrieve statistics for the given investments
     * @type {Array<number>}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve statistics for all investments held by the given accounts
     * @type {Array<number>}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly accountId?: Array<number>

    /**
     * Include dividends into the return calculation; default is true
     * @type {boolean}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly includeDividends?: boolean

    /**
     * Exclude expenses (like tax and commission) from the return calculation, default is false
     * @type {boolean}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly excludeExpenses?: boolean

    /**
     * A date in format yyyy-MM-dd (US Format). Get the absolute return between now and the                              given date.
     * @type {Array<string>}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly startDate?: Array<string>

    /**
     * The time step used for calculation; allowed values are day, week, month, year.                              Default is day. Note that the precision of the calculation will decrease with a longer                              time step, but runtime will increase.
     * @type {string}
     * @memberof PerformanceApiAbsoluteReturnByPosition
     */
    readonly timeStep?: string
}

/**
 * Request parameters for estimateTradingGainForNewTransaction operation in PerformanceApi.
 * @export
 * @interface PerformanceApiEstimateTradingGainForNewTransactionRequest
 */
export interface PerformanceApiEstimateTradingGainForNewTransactionRequest {
    /**
     * ID of the investment to check trading gain for
     * @type {number}
     * @memberof PerformanceApiEstimateTradingGainForNewTransaction
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateInvestmentParams}
     * @memberof PerformanceApiEstimateTradingGainForNewTransaction
     */
    readonly body: CreateOrUpdateInvestmentParams
}

/**
 * Request parameters for investmentPerformance operation in PerformanceApi.
 * @export
 * @interface PerformanceApiInvestmentPerformanceRequest
 */
export interface PerformanceApiInvestmentPerformanceRequest {
    /**
     * Retrieve return on investment for this investment.
     * @type {number}
     * @memberof PerformanceApiInvestmentPerformance
     */
    readonly id: number
}

/**
 * Request parameters for performanceByDepots operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPerformanceByDepotsRequest
 */
export interface PerformanceApiPerformanceByDepotsRequest {
    /**
     * The comment details
     * @type {CreateCashFlowAndPerformanceStatisticsParams}
     * @memberof PerformanceApiPerformanceByDepots
     */
    readonly body: CreateCashFlowAndPerformanceStatisticsParams
}

/**
 * Request parameters for performanceHistogram operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPerformanceHistogramRequest
 */
export interface PerformanceApiPerformanceHistogramRequest {
    /**
     * The performance interval to use for histogram creation; supported values are                              six-month steps, so 1, 6, 12, 18 ... 60. The default value is 12.
     * @type {string}
     * @memberof PerformanceApiPerformanceHistogram
     */
    readonly performanceInterval?: string

    /**
     * Apply the given percentile for calculation of low, average and high performance cutoff                              ranges. Must be a value between 0 and 100. Only values within the boundary will be considered                              when defining the cutoff ranges. This way outlier may be filtered, e.g. by using a 10%                              cutoff to filter the lowest and highest 10%. Default is 5. Set to zero to use no cutoff.
     * @type {string}
     * @memberof PerformanceApiPerformanceHistogram
     */
    readonly percentile?: string
}

/**
 * Request parameters for performanceIntervals operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPerformanceIntervalsRequest
 */
export interface PerformanceApiPerformanceIntervalsRequest {
    /**
     * The comment details
     * @type {CreatePerformanceIntervalsParams}
     * @memberof PerformanceApiPerformanceIntervals
     */
    readonly body: CreatePerformanceIntervalsParams
}

/**
 * Request parameters for performanceTimePoints operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPerformanceTimePointsRequest
 */
export interface PerformanceApiPerformanceTimePointsRequest {
    /**
     * The comment details
     * @type {CreatePerformanceIntervalsParams}
     * @memberof PerformanceApiPerformanceTimePoints
     */
    readonly body: CreatePerformanceIntervalsParams
}

/**
 * Request parameters for portfolio operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPortfolioRequest
 */
export interface PerformanceApiPortfolioRequest {
    /**
     * Include the given accounts in the performance calculation
     * @type {Array<number>}
     * @memberof PerformanceApiPortfolio
     */
    readonly accountId: Array<number>

    /**
     * A date in format yyyy-MM-dd (US Format). Start performance calculation at the given date.                              Default is 5 years ago.
     * @type {Array<string>}
     * @memberof PerformanceApiPortfolio
     */
    readonly startDate?: Array<string>
}

/**
 * Request parameters for portfolioPerformance operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPortfolioPerformanceRequest
 */
export interface PerformanceApiPortfolioPerformanceRequest {
    /**
     * The parameters for the calculation.
     * @type {CreateCashFlowAndPerformanceStatisticsParams}
     * @memberof PerformanceApiPortfolioPerformance
     */
    readonly body: CreateCashFlowAndPerformanceStatisticsParams
}

/**
 * Request parameters for publicPortfolioPerformance operation in PerformanceApi.
 * @export
 * @interface PerformanceApiPublicPortfolioPerformanceRequest
 */
export interface PerformanceApiPublicPortfolioPerformanceRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof PerformanceApiPublicPortfolioPerformance
     */
    readonly publicId: string

    /**
     * The comment details
     * @type {CreateCashFlowAndPerformanceStatisticsParams}
     * @memberof PerformanceApiPublicPortfolioPerformance
     */
    readonly body: CreateCashFlowAndPerformanceStatisticsParams
}

/**
 * Request parameters for realizedGainsPerYear operation in PerformanceApi.
 * @export
 * @interface PerformanceApiRealizedGainsPerYearRequest
 */
export interface PerformanceApiRealizedGainsPerYearRequest {
    /**
     * Calculate gains per year for the given accounts. Limited to 500 investments.
     * @type {Array<number>}
     * @memberof PerformanceApiRealizedGainsPerYear
     */
    readonly accountId: Array<number>
}

/**
 * Request parameters for riskYieldStatistics operation in PerformanceApi.
 * @export
 * @interface PerformanceApiRiskYieldStatisticsRequest
 */
export interface PerformanceApiRiskYieldStatisticsRequest {
    /**
     * Retrieve statistics for the given investments; Limited to 250 investments.
     * @type {Array<number>}
     * @memberof PerformanceApiRiskYieldStatistics
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve statistics for all investments held by the given accounts; Limited to 250 investments.
     * @type {Array<number>}
     * @memberof PerformanceApiRiskYieldStatistics
     */
    readonly accountId?: Array<number>

    /**
     * Use the given time interval for statistics calculation. Supported values are                              \&#39;day | week | month | year; default is month.
     * @type {string}
     * @memberof PerformanceApiRiskYieldStatistics
     */
    readonly interval?: string

    /**
     * The number of intervals to include in the calculation. Default is 36; minimum value                              is 5 and the maximum value is 252, the number of trading days per year.
     * @type {string}
     * @memberof PerformanceApiRiskYieldStatistics
     */
    readonly numIntervals?: string
}

/**
 * Request parameters for tradingGainForInvestment operation in PerformanceApi.
 * @export
 * @interface PerformanceApiTradingGainForInvestmentRequest
 */
export interface PerformanceApiTradingGainForInvestmentRequest {
    /**
     * Calculate the trading gain for the given investment and all of its transactions
     * @type {number}
     * @memberof PerformanceApiTradingGainForInvestment
     */
    readonly id: number
}

/**
 * PerformanceApi - object-oriented interface
 * @export
 * @class PerformanceApi
 * @extends {BaseAPI}
 */
export class PerformanceApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the absolute return with or without dividends in EUR for the given investment.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
     * @param {PerformanceApiAbsoluteReturnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public absoluteReturn(requestParameters: PerformanceApiAbsoluteReturnRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).absoluteReturn(requestParameters.id, requestParameters.includeDividends, requestParameters.excludeExpenses, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the absolute returns (with our without dividends) in EUR for the given investments.                      The absolute return is the return that was achieved with a given position until now, including                      all trading or quote gains. Optionally, dividends may be included as well.
     * @param {PerformanceApiAbsoluteReturnByPositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public absoluteReturnByPosition(requestParameters: PerformanceApiAbsoluteReturnByPositionRequest = {}, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).absoluteReturnByPosition(requestParameters.investmentId, requestParameters.accountId, requestParameters.includeDividends, requestParameters.excludeExpenses, requestParameters.startDate, requestParameters.timeStep, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Estimate the trading gain for a new (not yet persisted) transaction.
     * @param {PerformanceApiEstimateTradingGainForNewTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public estimateTradingGainForNewTransaction(requestParameters: PerformanceApiEstimateTradingGainForNewTransactionRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).estimateTradingGainForNewTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the return on investment including the investments performance for the given investment.
     * @param {PerformanceApiInvestmentPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public investmentPerformance(requestParameters: PerformanceApiInvestmentPerformanceRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).investmentPerformance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED: use /v2/performance instead.                      Calculate cash flow and performance statistics for the given accounts. By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
     * @param {PerformanceApiPerformanceByDepotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public performanceByDepots(requestParameters: PerformanceApiPerformanceByDepotsRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).performanceByDepots(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a performance histogram including the performance of all ACTIVE users,                      using the given performance interval.
     * @param {PerformanceApiPerformanceHistogramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public performanceHistogram(requestParameters: PerformanceApiPerformanceHistogramRequest = {}, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).performanceHistogram(requestParameters.performanceInterval, requestParameters.percentile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate cash flow and performance statistics for the given accounts and intervals.                        Calculations are limited to 500 investments across all accounts; including active and                        historic positions.
     * @param {PerformanceApiPerformanceIntervalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public performanceIntervals(requestParameters: PerformanceApiPerformanceIntervalsRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).performanceIntervals(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate performance for a set of time points defined by the given interval. Calculations                        are limited to 500 investments across all accounts; including active and historic positions.
     * @param {PerformanceApiPerformanceTimePointsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public performanceTimePoints(requestParameters: PerformanceApiPerformanceTimePointsRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).performanceTimePoints(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED: use /v2/performance instead.                     A simplified endpoint to calculate the TWROR portfolio performance for the given accounts.                      This simplified endpoint may also be used for retrieving a friend\'s portfolio performance as it                      does not include any cash-flow or balance information. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
     * @param {PerformanceApiPortfolioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public portfolio(requestParameters: PerformanceApiPortfolioRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).portfolio(requestParameters.accountId, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
     * @param {PerformanceApiPortfolioPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public portfolioPerformance(requestParameters: PerformanceApiPortfolioPerformanceRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).portfolioPerformance(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate cash flow and performance statistics for the given portfolios.                      By default, only the                      portfolio performance (excluding the net cash position) will be included. Using the flags                      includeCash and assetClasses additional data may be requested. Calculations are limited to 500                      investments across all accounts; including active and historic positions.
     * @param {PerformanceApiPublicPortfolioPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public publicPortfolioPerformance(requestParameters: PerformanceApiPublicPortfolioPerformanceRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).publicPortfolioPerformance(requestParameters.publicId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate the yearly realized gains in total and by category. Categories are trading gains,                      dividend gains, interest gains, other gains and tax refunds. All values (except tax refunds)                      are available as gross and net values.
     * @param {PerformanceApiRealizedGainsPerYearRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public realizedGainsPerYear(requestParameters: PerformanceApiRealizedGainsPerYearRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).realizedGainsPerYear(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED. Use the new riskYieldMetrics endpoints.                     Get risk yield statistics for the selected accounts or investments
     * @param {PerformanceApiRiskYieldStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public riskYieldStatistics(requestParameters: PerformanceApiRiskYieldStatisticsRequest = {}, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).riskYieldStatistics(requestParameters.investmentId, requestParameters.accountId, requestParameters.interval, requestParameters.numIntervals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate the detailed trading gain for an investment. Trading gain is given accumulated and                      for each of the investments transactions.
     * @param {PerformanceApiTradingGainForInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public tradingGainForInvestment(requestParameters: PerformanceApiTradingGainForInvestmentRequest, options?: RawAxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).tradingGainForInvestment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

