import { Box, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { ReactComponent as ClearIcon } from "@icons/clear.svg";
import { ReactComponent as EqualIcon } from "@icons/equal.svg";

export const MultiplyIcon = () => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      color="icon.primary"
      height={isLessSm ? "24px" : "48px"}
    >
      <ClearIcon />
    </Box>
  );
};

export const EqualsIcon = () => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      color="icon.primary"
      height={isLessSm ? "24px" : "48px"}
    >
      <EqualIcon />
    </Box>
  );
};
