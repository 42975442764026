import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const clearNameAndDepotAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BACK")
    throw new Error(`Invalid event type ${event.type}`);
  const depotId = new URL(window.location.href).searchParams.get("depotId");

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId: depotId ? parseInt(depotId) : undefined,
    },
    bookingValues: {
      ...context.bookingValues,
      comment: undefined,
    },
  };
};
