import { axiosInstanceV1 } from "@api/axiosInstance";
import { DateValuePair } from "../model";

export const getAccountBalances = async (
  token: string | undefined,
  params: {
    /** ID of the account to retrieve balances for. */
    id: number[];
    /** Get balances from the given date; format is yyyy-MM-dd (US Format). Default is not to set this value. */
    from?: string;
    /** Get balances up to the given date; format is yyyy-MM-dd (US Format). Default is not to set this value. */
    to?: string;
    /** Will pad dates so that there is a balance for every day if true; default is false */
    padDates?: boolean;
  }
) => {
  const { data } = await axiosInstanceV1.get<{
    dateValuePairs: DateValuePair[];
  }>(`/accounts/balances`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
  return data;
};
