import { UseMutationOptions } from "react-query";
import {
  CategoriesApiCreateTransactionCategoryRequest,
  Category,
} from "@generated/apiv1";

import { buildCategoriesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createTransactionCategory = async (
  token: string | undefined,
  params: CategoriesApiCreateTransactionCategoryRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.createTransactionCategory(params);
  return data;
};

export const useCreateTransactionCategory = (
  options?: UseMutationOptions<
    Category,
    AxiosApiError,
    CategoriesApiCreateTransactionCategoryRequest
  >
) => {
  return useAuthMutation(createTransactionCategory, options, false);
};
