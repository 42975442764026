import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setSelectedDividendsAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "DIVIDENDS_SELECTED") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    selectedDividends: event.selectedDividends,
  };
};
