import { UseMutationOptions } from "react-query";
import {
  PerformanceApiPerformanceTimePointsRequest,
  PerformanceTimePointList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const performanceTimePoints = async (
  token: string | undefined,
  params: PerformanceApiPerformanceTimePointsRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.performanceTimePoints(params);
  return data;
};

export const usePerformanceTimePoints = (
  options?: UseMutationOptions<
    PerformanceTimePointList,
    AxiosApiError,
    PerformanceApiPerformanceTimePointsRequest
  >
) => {
  return useAuthMutation(performanceTimePoints, options, false);
};
