import React from "react";
import { Box, styled, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { SettingsBankConnectionInterface } from "@features/settingsPage/SettingsConnectionsPage/types";
import { ReactComponent as WarningIcon } from "@icons/myicons/warning-triangle-filled.svg";
import { ReactComponent as InterfaceIcon } from "@icons/myicons/interface.svg";

const ActiveBox = styled(Typography)(({ theme }) => ({
  borderRadius: "24px",
  padding: theme.spacing(0, 2),
  fontSize: "12px",
  width: "fit-content",
  marginTop: theme.spacing(2),
  color: theme.palette.success.dark,
  backgroundColor: theme.palette.background.success,
}));

const InterfaceIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2.75),
  borderRadius: "8px",
  backgroundColor: theme.palette.background.neutral,
}));

type Props = {
  connectionInterface: SettingsBankConnectionInterface;
};

export const BankInterface = ({ connectionInterface }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Box
          display="flex"
          alignItems={"flex-start"}
          gap={4}
          mb={theme.spacing(1)}
        >
          <InterfaceIconBox>
            <InterfaceIcon />
          </InterfaceIconBox>
          <Box>
            <Typography variant="h6" fontSize="22px" lineHeight="30px">
              {connectionInterface.name}
            </Typography>
            {connectionInterface.lastUpdate && (
              <Typography
                variant="body1"
                fontSize="18px"
                lineHeight="28px"
                color="textSecondary"
              >
                {t("settingsPage.connections.deleteModal.actualized")}{" "}
                {format(
                  new Date(connectionInterface.lastUpdate),
                  "dd.MM.yyyy, HH:mm"
                )}
              </Typography>
            )}
            {connectionInterface.status === "SUCCESS" && (
              <ActiveBox variant="body3" lineHeight="28px">
                {t("settings.bankConnection.interfaces.active")}
              </ActiveBox>
            )}
            {connectionInterface.status === "FAILED" && (
              <Box
                display="flex"
                flexDirection="row"
                gap={theme.spacing(1)}
                mt={theme.spacing(2)}
              >
                <WarningIcon
                  height="20px"
                  width="20px"
                  style={{
                    minHeight: "20px",
                    minWidth: "20px",
                  }}
                  color={theme.palette.icon.error}
                />
                {connectionInterface.error && (
                  <Typography
                    variant="body2"
                    color={theme.palette.text.negative}
                  >
                    {connectionInterface.error}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
