/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { SocialStatisticsTopHoldingsResponse } from '../model';
// @ts-ignore
import { SocialStatisticsTopMoversResponse } from '../model';
/**
 * SocialStatisticsApi - axios parameter creator
 * @export
 */
export const SocialStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Top Holdings
         * @param {GetTopHoldingsFilterAssetClassEnum} [filterAssetClass] Asset class to filter the top holdings query, e.g. stock to see only stocks top holdings
         * @param {GetTopHoldingsFilterDepotTypeEnum} [filterDepotType] Depot type to filter the top holdings by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
         * @param {number} [limit] Number of records to be returned in response. Max is 100. Default is 10.
         * @param {GetTopHoldingsOrderByEnum} [orderBy] Results can be ordered by field &#x60;market_value&#x60; or &#x60;investments&#x60;, always descending.Default is market_value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopHoldings: async (filterAssetClass?: GetTopHoldingsFilterAssetClassEnum, filterDepotType?: GetTopHoldingsFilterDepotTypeEnum, limit?: number, orderBy?: GetTopHoldingsOrderByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/social_statistics/top_holdings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterAssetClass !== undefined) {
                localVarQueryParameter['filter[asset_class]'] = filterAssetClass;
            }

            if (filterDepotType !== undefined) {
                localVarQueryParameter['filter[depot_type]'] = filterDepotType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns top movers
         * @param {GetTopMoversFilterAssetClassEnum} [filterAssetClass] Asset class to filter the top movers query, e.g. stock to see only stocks top movers
         * @param {GetTopMoversFilterDepotTypeEnum} [filterDepotType] Depot type to filter the top movers by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
         * @param {number} [filterDays] Top movers in the last X days. Max 30 days. Default is 7 days.
         * @param {number} [limit] Number of records to be returned in response. Max is 100.Default is 10.
         * @param {GetTopMoversOrderByEnum} [orderBy] Results can be ordered by field &#x60;total_buys&#x60; or &#x60;total_sells&#x60;, always descending.Default is total_buys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopMovers: async (filterAssetClass?: GetTopMoversFilterAssetClassEnum, filterDepotType?: GetTopMoversFilterDepotTypeEnum, filterDays?: number, limit?: number, orderBy?: GetTopMoversOrderByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/social_statistics/top_movers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterAssetClass !== undefined) {
                localVarQueryParameter['filter[asset_class]'] = filterAssetClass;
            }

            if (filterDepotType !== undefined) {
                localVarQueryParameter['filter[depot_type]'] = filterDepotType;
            }

            if (filterDays !== undefined) {
                localVarQueryParameter['filter[days]'] = filterDays;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialStatisticsApi - functional programming interface
 * @export
 */
export const SocialStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Top Holdings
         * @param {GetTopHoldingsFilterAssetClassEnum} [filterAssetClass] Asset class to filter the top holdings query, e.g. stock to see only stocks top holdings
         * @param {GetTopHoldingsFilterDepotTypeEnum} [filterDepotType] Depot type to filter the top holdings by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
         * @param {number} [limit] Number of records to be returned in response. Max is 100. Default is 10.
         * @param {GetTopHoldingsOrderByEnum} [orderBy] Results can be ordered by field &#x60;market_value&#x60; or &#x60;investments&#x60;, always descending.Default is market_value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopHoldings(filterAssetClass?: GetTopHoldingsFilterAssetClassEnum, filterDepotType?: GetTopHoldingsFilterDepotTypeEnum, limit?: number, orderBy?: GetTopHoldingsOrderByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialStatisticsTopHoldingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopHoldings(filterAssetClass, filterDepotType, limit, orderBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialStatisticsApi.getTopHoldings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns top movers
         * @param {GetTopMoversFilterAssetClassEnum} [filterAssetClass] Asset class to filter the top movers query, e.g. stock to see only stocks top movers
         * @param {GetTopMoversFilterDepotTypeEnum} [filterDepotType] Depot type to filter the top movers by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
         * @param {number} [filterDays] Top movers in the last X days. Max 30 days. Default is 7 days.
         * @param {number} [limit] Number of records to be returned in response. Max is 100.Default is 10.
         * @param {GetTopMoversOrderByEnum} [orderBy] Results can be ordered by field &#x60;total_buys&#x60; or &#x60;total_sells&#x60;, always descending.Default is total_buys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopMovers(filterAssetClass?: GetTopMoversFilterAssetClassEnum, filterDepotType?: GetTopMoversFilterDepotTypeEnum, filterDays?: number, limit?: number, orderBy?: GetTopMoversOrderByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialStatisticsTopMoversResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopMovers(filterAssetClass, filterDepotType, filterDays, limit, orderBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialStatisticsApi.getTopMovers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SocialStatisticsApi - factory interface
 * @export
 */
export const SocialStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Top Holdings
         * @param {SocialStatisticsApiGetTopHoldingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopHoldings(requestParameters: SocialStatisticsApiGetTopHoldingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SocialStatisticsTopHoldingsResponse> {
            return localVarFp.getTopHoldings(requestParameters.filterAssetClass, requestParameters.filterDepotType, requestParameters.limit, requestParameters.orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns top movers
         * @param {SocialStatisticsApiGetTopMoversRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopMovers(requestParameters: SocialStatisticsApiGetTopMoversRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SocialStatisticsTopMoversResponse> {
            return localVarFp.getTopMovers(requestParameters.filterAssetClass, requestParameters.filterDepotType, requestParameters.filterDays, requestParameters.limit, requestParameters.orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTopHoldings operation in SocialStatisticsApi.
 * @export
 * @interface SocialStatisticsApiGetTopHoldingsRequest
 */
export interface SocialStatisticsApiGetTopHoldingsRequest {
    /**
     * Asset class to filter the top holdings query, e.g. stock to see only stocks top holdings
     * @type {'stock' | 'fund' | 'etf' | 'bond' | 'cash' | 'certificate_os' | 'precious_metal' | 'material_asset' | 'foreign_currency' | 'managed'}
     * @memberof SocialStatisticsApiGetTopHoldings
     */
    readonly filterAssetClass?: GetTopHoldingsFilterAssetClassEnum

    /**
     * Depot type to filter the top holdings by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
     * @type {'real' | 'manual'}
     * @memberof SocialStatisticsApiGetTopHoldings
     */
    readonly filterDepotType?: GetTopHoldingsFilterDepotTypeEnum

    /**
     * Number of records to be returned in response. Max is 100. Default is 10.
     * @type {number}
     * @memberof SocialStatisticsApiGetTopHoldings
     */
    readonly limit?: number

    /**
     * Results can be ordered by field &#x60;market_value&#x60; or &#x60;investments&#x60;, always descending.Default is market_value.
     * @type {'market_value' | 'investments'}
     * @memberof SocialStatisticsApiGetTopHoldings
     */
    readonly orderBy?: GetTopHoldingsOrderByEnum
}

/**
 * Request parameters for getTopMovers operation in SocialStatisticsApi.
 * @export
 * @interface SocialStatisticsApiGetTopMoversRequest
 */
export interface SocialStatisticsApiGetTopMoversRequest {
    /**
     * Asset class to filter the top movers query, e.g. stock to see only stocks top movers
     * @type {'stock' | 'fund' | 'etf' | 'bond' | 'cash' | 'certificate_os' | 'precious_metal' | 'material_asset' | 'foreign_currency' | 'managed'}
     * @memberof SocialStatisticsApiGetTopMovers
     */
    readonly filterAssetClass?: GetTopMoversFilterAssetClassEnum

    /**
     * Depot type to filter the top movers by transactions that                               are imported directly from connected bank accounts,                               or by those that have been manually added.
     * @type {'real' | 'manual'}
     * @memberof SocialStatisticsApiGetTopMovers
     */
    readonly filterDepotType?: GetTopMoversFilterDepotTypeEnum

    /**
     * Top movers in the last X days. Max 30 days. Default is 7 days.
     * @type {number}
     * @memberof SocialStatisticsApiGetTopMovers
     */
    readonly filterDays?: number

    /**
     * Number of records to be returned in response. Max is 100.Default is 10.
     * @type {number}
     * @memberof SocialStatisticsApiGetTopMovers
     */
    readonly limit?: number

    /**
     * Results can be ordered by field &#x60;total_buys&#x60; or &#x60;total_sells&#x60;, always descending.Default is total_buys.
     * @type {'total_buys' | 'total_sells'}
     * @memberof SocialStatisticsApiGetTopMovers
     */
    readonly orderBy?: GetTopMoversOrderByEnum
}

/**
 * SocialStatisticsApi - object-oriented interface
 * @export
 * @class SocialStatisticsApi
 * @extends {BaseAPI}
 */
export class SocialStatisticsApi extends BaseAPI {
    /**
     * 
     * @summary Top Holdings
     * @param {SocialStatisticsApiGetTopHoldingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialStatisticsApi
     */
    public getTopHoldings(requestParameters: SocialStatisticsApiGetTopHoldingsRequest = {}, options?: RawAxiosRequestConfig) {
        return SocialStatisticsApiFp(this.configuration).getTopHoldings(requestParameters.filterAssetClass, requestParameters.filterDepotType, requestParameters.limit, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns top movers
     * @param {SocialStatisticsApiGetTopMoversRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialStatisticsApi
     */
    public getTopMovers(requestParameters: SocialStatisticsApiGetTopMoversRequest = {}, options?: RawAxiosRequestConfig) {
        return SocialStatisticsApiFp(this.configuration).getTopMovers(requestParameters.filterAssetClass, requestParameters.filterDepotType, requestParameters.filterDays, requestParameters.limit, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTopHoldingsFilterAssetClassEnum = {
    STOCK: 'stock',
    FUND: 'fund',
    ETF: 'etf',
    BOND: 'bond',
    CASH: 'cash',
    CERTIFICATE_OS: 'certificate_os',
    PRECIOUS_METAL: 'precious_metal',
    MATERIAL_ASSET: 'material_asset',
    FOREIGN_CURRENCY: 'foreign_currency',
    MANAGED: 'managed'
} as const;
export type GetTopHoldingsFilterAssetClassEnum = typeof GetTopHoldingsFilterAssetClassEnum[keyof typeof GetTopHoldingsFilterAssetClassEnum];
/**
 * @export
 */
export const GetTopHoldingsFilterDepotTypeEnum = {
    REAL: 'real',
    MANUAL: 'manual'
} as const;
export type GetTopHoldingsFilterDepotTypeEnum = typeof GetTopHoldingsFilterDepotTypeEnum[keyof typeof GetTopHoldingsFilterDepotTypeEnum];
/**
 * @export
 */
export const GetTopHoldingsOrderByEnum = {
    MARKET_VALUE: 'market_value',
    INVESTMENTS: 'investments'
} as const;
export type GetTopHoldingsOrderByEnum = typeof GetTopHoldingsOrderByEnum[keyof typeof GetTopHoldingsOrderByEnum];
/**
 * @export
 */
export const GetTopMoversFilterAssetClassEnum = {
    STOCK: 'stock',
    FUND: 'fund',
    ETF: 'etf',
    BOND: 'bond',
    CASH: 'cash',
    CERTIFICATE_OS: 'certificate_os',
    PRECIOUS_METAL: 'precious_metal',
    MATERIAL_ASSET: 'material_asset',
    FOREIGN_CURRENCY: 'foreign_currency',
    MANAGED: 'managed'
} as const;
export type GetTopMoversFilterAssetClassEnum = typeof GetTopMoversFilterAssetClassEnum[keyof typeof GetTopMoversFilterAssetClassEnum];
/**
 * @export
 */
export const GetTopMoversFilterDepotTypeEnum = {
    REAL: 'real',
    MANUAL: 'manual'
} as const;
export type GetTopMoversFilterDepotTypeEnum = typeof GetTopMoversFilterDepotTypeEnum[keyof typeof GetTopMoversFilterDepotTypeEnum];
/**
 * @export
 */
export const GetTopMoversOrderByEnum = {
    BUYS: 'total_buys',
    SELLS: 'total_sells'
} as const;
export type GetTopMoversOrderByEnum = typeof GetTopMoversOrderByEnum[keyof typeof GetTopMoversOrderByEnum];
