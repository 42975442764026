import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Typography } from "@mui/material";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";

export interface Props {
  onSelect?: (key: string) => void;
  items: { key: string; label: string }[];
  label?: string;
  selected?: string[];
}

export const AddMenu = ({ onSelect, items, label, selected }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        size="small"
        color="secondary"
        variant="contained"
        data-testid="add-menu"
        startIcon={<PlusIcon />}
      >
        {t(label || "fundamentals.addMenu.title")}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.length === 0 && (
          <MenuItem key="nothing" disabled={true}>
            {t("fundamentals.addMenu.noItems")}
          </MenuItem>
        )}
        {items.map(({ key, label }) => {
          const isSelected = selected?.includes(key);

          return (
            <MenuItem
              key={key}
              data-testid={`add-menu-item-${key}`}
              onClick={() => {
                if (onSelect) {
                  onSelect(key);
                }
              }}
              selected={selected?.includes(key)}
            >
              <Box display="flex" gap={3}>
                <Checkbox
                  checked={isSelected}
                  sx={{
                    padding: 0,
                  }}
                />
                <Typography variant="body2" fontWeight={400}>
                  {label}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
