import {
  defaultContext,
  TransactionWizardContext,
} from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";

export const setManualFlowAction = (context: TransactionWizardContext) => {
  return {
    manualFlow: true,
    investmentValues: defaultContext.investmentValues,
  };
};
