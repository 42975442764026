import { UseQueryOptions } from "react-query";
import { getHistoricQuotesIntraday } from "@api/v1/methods";
import { GetHistoricQuotesIntradayParams, QuoteBarList } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_HISTORIC_QUOTES_INTRADAY } from "../../cacheKeys";

export const useGetHistoricQuotesIntraday = (
  params: GetHistoricQuotesIntradayParams,
  options?: UseQueryOptions<QuoteBarList, AxiosApiError, QuoteBarList>
) =>
  useAuthQuery(
    [GET_HISTORIC_QUOTES_INTRADAY, params],
    ({ token }) => getHistoricQuotesIntraday(token, params),
    options
  );
