import React, { useState } from "react";
import { useGetInvestments } from "@api/v1/hooks/useInvestments";
import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";
import { InvestmentValues } from "@features/transactionWizard/stateMachine/types/investmentValues";
import { NameAndDepotStep } from "./NameAndDepotStep";

export interface Props {
  handleNext: (
    depotId: number,
    investmentValues: InvestmentValues,
    comment?: string
  ) => void;
  handlePrevious: () => void;
  context: TransactionWizardContext;
  isManualTransaction?: boolean;
  isin?: string;
}

export const NameAndDepotStepWithLookup = ({
  handleNext,
  handlePrevious,
  isManualTransaction,
  context,
}: Props) => {
  const isin =
    context.sourceInvestmentValues?.isin || context.investmentValues?.isin;
  const tickerSymbol =
    context.sourceInvestmentValues?.tickerSymbol ||
    context.investmentValues?.tickerSymbol;
  const [depotId, setDepotId] = useState<number | undefined>(
    context.sourceInvestmentValues?.depotId || context.investmentValues.depotId
  );
  const handleDepotIdChange = (id: number | null) => {
    setDepotId(id ?? undefined);
  };

  const handleNextWrapper = (
    investmentValues: InvestmentValues,
    comment?: string
  ) => {
    if (!depotId) return;
    handleNext(depotId, investmentValues, comment);
  };

  const { data: investmentList } = useGetInvestments(
    {
      accountId: depotId ? [depotId] : undefined,
      isin: isin ? [isin] : undefined,
      tickerSymbol: !isin && tickerSymbol ? [tickerSymbol] : undefined,
      includeHistoric: false,
    },
    {
      enabled: Boolean(depotId && (isin || tickerSymbol)),
      refetchOnMount: "always",
    }
  );

  const investment = investmentList?.investments?.length
    ? investmentList.investments[0]
    : undefined;

  return (
    <NameAndDepotStep
      handleNext={handleNextWrapper}
      context={context}
      handlePrevious={handlePrevious}
      handleDepotIdChange={handleDepotIdChange}
      selectedDepotId={depotId}
      isManualTransaction={isManualTransaction}
      existingLots={investment ? investment.numberOfLots : 0}
    />
  );
};
