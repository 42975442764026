import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, Typography, useTheme } from "@mui/material";
import {
  formatDateOrString,
  toRoundedNumberOfLots,
  toCurrency,
} from "@helpers";
import { ReactComponent as SuccessIcon } from "@icons/check_circle.svg";
import { TransactionValidationError } from "@api";
import { ActivityWithBooking } from "@features/fileDrop/helpers/bookingLocator";
import { ActivityWithBookingError } from "@features/fileDrop/components/ActivityWithBookingError";
import { Tooltip } from "@components";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

const Container = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "hasError",
})<{ hasError?: boolean }>(({ theme, hasError }) => ({
  width: "100%",
  padding: theme.spacing(3),
  border: `1px solid ${
    hasError ? theme.palette.border.error : theme.palette.border.secondary
  }`,
  backgroundColor: hasError
    ? theme.palette.background.error
    : theme.palette.background.paper,
}));

const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const NumberContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
  white-space: nowrap;
`;

const AmountContainer = styled.div`
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing(0.7)};
  border-radius: 8px;
`;

const DateContainer = styled.div`
  margin-left: auto;
`;

const TypeWithNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const TypeIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 4px;
  padding: 2px 6px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export interface Props {
  transactionValidationErrors: TransactionValidationError[];
  activitiesWithBooking: ActivityWithBooking[];
  testid?: string;
  fileName: string;
  onRemove: (activity: ActivityWithBooking) => void;
}

export const ActivityWithBookingItem = ({
  activitiesWithBooking,
  transactionValidationErrors,
  testid,
  fileName,
  onRemove,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const hasError = transactionValidationErrors?.length > 0;

  return (
    <Container
      data-testid={testid || "activity-with-booking-item"}
      hasError={hasError}
    >
      {activitiesWithBooking.map((activityWithBooking, index) => (
        <React.Fragment key={activityWithBooking.activity.uuid}>
          {index !== 0 && (
            <Divider sx={{ marginTop: "6px", marginBottom: "6px" }} />
          )}
          <FlexRow>
            <TypeWithNameContainer>
              {!activityWithBooking?.booking && (
                <TypeIcon>{t("transactionUpload.newTransaction")}</TypeIcon>
              )}
              {activityWithBooking?.booking && (
                <TypeIcon>{t("transactionUpload.updateTransaction")}</TypeIcon>
              )}

              <NumberContainer>
                <Typography
                  variant="body1"
                  color={
                    hasError
                      ? theme.palette.text.negative
                      : theme.palette.text.primary
                  }
                >
                  {t(
                    `transactionUpload.transactionTypes.${activityWithBooking?.activity.type}`
                  )}
                </Typography>
              </NumberContainer>
            </TypeWithNameContainer>

            {activityWithBooking?.activity.numberOfLots && (
              <NumberContainer>
                <Typography
                  variant="body1"
                  color={
                    hasError
                      ? theme.palette.text.negative
                      : theme.palette.text.primary
                  }
                >
                  {toRoundedNumberOfLots(
                    activityWithBooking?.activity.numberOfLots,
                    6
                  )}
                </Typography>
              </NumberContainer>
            )}

            {!hasError && (
              <Tooltip
                text={
                  <Typography variant="caption" fontWeight={400}>
                    {fileName}
                  </Typography>
                }
                placement="top"
              >
                <Box display="flex" alignSelf="center" ml={2}>
                  <SuccessIcon
                    width="20"
                    height="20"
                    color={theme.palette.icon.success}
                  />
                </Box>
              </Tooltip>
            )}
            {hasError &&
              transactionValidationErrors
                .filter((error) => error.hash === activityWithBooking?.hash)
                .map((error, ix) => {
                  return (
                    <Box
                      key={`activity-with-booking-error-${ix}`}
                      alignSelf="center"
                      ml={2}
                    >
                      <ActivityWithBookingError
                        data-testid={`activity-with-booking-error-${ix}`}
                        message={error.errorMessage}
                      />
                    </Box>
                  );
                })}
            {activityWithBooking?.activity.netAmount && (
              <AmountContainer>
                <Typography variant="body1" fontWeight="600">
                  {toCurrency(activityWithBooking?.activity.netAmount)}
                </Typography>
              </AmountContainer>
            )}
          </FlexRow>
          <Box mt={3}>
            <TypeWithNameContainer>
              <Box display="flex" gap={3} width="100%" minWidth={0}>
                {!activityWithBooking?.investment?.id && (
                  <TypeIcon>{t("transactionUpload.newInvestment")}</TypeIcon>
                )}
                <Typography variant="body1" noWrap>
                  <b>
                    {activityWithBooking?.activity.name ||
                      activityWithBooking?.activity.cryptoSymbol}
                  </b>
                  {activityWithBooking?.activity.isin &&
                    ` (${activityWithBooking?.activity.isin})`}
                </Typography>
              </Box>

              {!!activityWithBooking?.activity.commission && (
                <Typography variant="body2" flexShrink={0}>
                  {toCurrency(activityWithBooking?.activity.commission)}{" "}
                  {t("transactionUpload.fee")}
                </Typography>
              )}
            </TypeWithNameContainer>
          </Box>

          {!!activityWithBooking?.activity.taxAmount && (
            <Typography variant="body2">
              {toCurrency(activityWithBooking?.activity.taxAmount)}{" "}
              {t("transactionUpload.tax")}
            </Typography>
          )}

          <Box display="flex" gap={2}>
            {!!activityWithBooking?.activity.date && (
              <FlexRow>
                <DateContainer>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                  >
                    {formatDateOrString(activityWithBooking?.activity.date)}
                  </Typography>
                </DateContainer>
              </FlexRow>
            )}
            <Box
              sx={{
                color: theme.palette.icon.secondary,
                ":hover": {
                  color: theme.palette.icon.primary,
                },
              }}
            >
              <DeleteIcon
                width="20"
                height="20"
                onClick={() => {
                  onRemove(activityWithBooking);
                }}
                cursor="pointer"
              />
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Container>
  );
};
