import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SecuritiesSearch } from "@features/securities/SecuritiesSearch";
import { Modal } from "@components";
import { SymbolV2 } from "@generated/apiv1";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSymbolSelect: (symbol: SymbolV2) => void;
};

export const SearchModal = ({ isOpen, onClose, onSymbolSelect }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      disablePortal={false}
      isOpen={isOpen}
      onClose={onClose}
      height="auto"
      title={t("securitiesSearch.title")}
      zIndex={10000}
    >
      <Box>
        <SecuritiesSearch onSymbolSelect={onSymbolSelect} />
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{
            marginTop: (theme) => theme.spacing(4),
          }}
        >
          {t("navigation.previous")}
        </Button>
      </Box>
    </Modal>
  );
};
