/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BankHealth } from '../model';
/**
 * BankHealthReportsApi - axios parameter creator
 * @export
 */
export const BankHealthReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate bank health report
         * @param {BankHealthFilterBankSlugEnum} filterBankSlug Filter bank health by bank_slug
         * @param {string} [filterFrom] Show bank health report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
         * @param {string} [filterTo] Show bank health up to the given date,                               ISO date such as 2017-07-21. Default is today
         * @param {BankHealthFilterIntervalEnum} [filterInterval] Group bank health report per interval duration. Default is DAY
         * @param {boolean} [filterExcludeUserBasedErrors] Exclude user based errors from the bank health report. Default is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankHealth: async (filterBankSlug: BankHealthFilterBankSlugEnum, filterFrom?: string, filterTo?: string, filterInterval?: BankHealthFilterIntervalEnum, filterExcludeUserBasedErrors?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterBankSlug' is not null or undefined
            assertParamExists('bankHealth', 'filterBankSlug', filterBankSlug)
            const localVarPath = `/api/v3/reports/banks/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }

            if (filterBankSlug !== undefined) {
                localVarQueryParameter['filter[bank_slug]'] = filterBankSlug;
            }

            if (filterInterval !== undefined) {
                localVarQueryParameter['filter[interval]'] = filterInterval;
            }

            if (filterExcludeUserBasedErrors !== undefined) {
                localVarQueryParameter['filter[exclude_user_based_errors]'] = filterExcludeUserBasedErrors;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankHealthReportsApi - functional programming interface
 * @export
 */
export const BankHealthReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankHealthReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate bank health report
         * @param {BankHealthFilterBankSlugEnum} filterBankSlug Filter bank health by bank_slug
         * @param {string} [filterFrom] Show bank health report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
         * @param {string} [filterTo] Show bank health up to the given date,                               ISO date such as 2017-07-21. Default is today
         * @param {BankHealthFilterIntervalEnum} [filterInterval] Group bank health report per interval duration. Default is DAY
         * @param {boolean} [filterExcludeUserBasedErrors] Exclude user based errors from the bank health report. Default is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankHealth(filterBankSlug: BankHealthFilterBankSlugEnum, filterFrom?: string, filterTo?: string, filterInterval?: BankHealthFilterIntervalEnum, filterExcludeUserBasedErrors?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankHealth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankHealth(filterBankSlug, filterFrom, filterTo, filterInterval, filterExcludeUserBasedErrors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankHealthReportsApi.bankHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankHealthReportsApi - factory interface
 * @export
 */
export const BankHealthReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankHealthReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate bank health report
         * @param {BankHealthReportsApiBankHealthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankHealth(requestParameters: BankHealthReportsApiBankHealthRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankHealth> {
            return localVarFp.bankHealth(requestParameters.filterBankSlug, requestParameters.filterFrom, requestParameters.filterTo, requestParameters.filterInterval, requestParameters.filterExcludeUserBasedErrors, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankHealth operation in BankHealthReportsApi.
 * @export
 * @interface BankHealthReportsApiBankHealthRequest
 */
export interface BankHealthReportsApiBankHealthRequest {
    /**
     * Filter bank health by bank_slug
     * @type {'TradeRepublic' | 'finanzen_net' | 'bison_app' | 'binance' | 'Quirion' | 'Degiro' | 'Bitpanda' | 'Whitebox' | 'ScalableCapital' | 'MockBank' | 'SmartbrokerPlus' | 'JustTrade' | 'Parqet'}
     * @memberof BankHealthReportsApiBankHealth
     */
    readonly filterBankSlug: BankHealthFilterBankSlugEnum

    /**
     * Show bank health report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
     * @type {string}
     * @memberof BankHealthReportsApiBankHealth
     */
    readonly filterFrom?: string

    /**
     * Show bank health up to the given date,                               ISO date such as 2017-07-21. Default is today
     * @type {string}
     * @memberof BankHealthReportsApiBankHealth
     */
    readonly filterTo?: string

    /**
     * Group bank health report per interval duration. Default is DAY
     * @type {'WEEK' | 'DAY' | 'HOUR' | 'QUARTER'}
     * @memberof BankHealthReportsApiBankHealth
     */
    readonly filterInterval?: BankHealthFilterIntervalEnum

    /**
     * Exclude user based errors from the bank health report. Default is true
     * @type {boolean}
     * @memberof BankHealthReportsApiBankHealth
     */
    readonly filterExcludeUserBasedErrors?: boolean
}

/**
 * BankHealthReportsApi - object-oriented interface
 * @export
 * @class BankHealthReportsApi
 * @extends {BaseAPI}
 */
export class BankHealthReportsApi extends BaseAPI {
    /**
     * 
     * @summary Generate bank health report
     * @param {BankHealthReportsApiBankHealthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankHealthReportsApi
     */
    public bankHealth(requestParameters: BankHealthReportsApiBankHealthRequest, options?: RawAxiosRequestConfig) {
        return BankHealthReportsApiFp(this.configuration).bankHealth(requestParameters.filterBankSlug, requestParameters.filterFrom, requestParameters.filterTo, requestParameters.filterInterval, requestParameters.filterExcludeUserBasedErrors, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const BankHealthFilterBankSlugEnum = {
    TRADE_REPUBLIC: 'TradeRepublic',
    FINANZEN_NET: 'finanzen_net',
    BISON_APP: 'bison_app',
    BINANCE: 'binance',
    QUIRION: 'Quirion',
    DEGIRO: 'Degiro',
    BITPANDA: 'Bitpanda',
    WHITEBOX: 'Whitebox',
    SCALABLE_CAPITAL: 'ScalableCapital',
    MOCK_BANK: 'MockBank',
    SMARTBROKER_PLUS: 'SmartbrokerPlus',
    JUST_TRADE: 'JustTrade',
    PARQET: 'Parqet'
} as const;
export type BankHealthFilterBankSlugEnum = typeof BankHealthFilterBankSlugEnum[keyof typeof BankHealthFilterBankSlugEnum];
/**
 * @export
 */
export const BankHealthFilterIntervalEnum = {
    WEEK: 'WEEK',
    DAY: 'DAY',
    HOUR: 'HOUR',
    QUARTER: 'QUARTER'
} as const;
export type BankHealthFilterIntervalEnum = typeof BankHealthFilterIntervalEnum[keyof typeof BankHealthFilterIntervalEnum];
