/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateUserParams } from '../model';
// @ts-ignore
import { User } from '../model';
/**
 * SignupApi - axios parameter creator
 * @export
 */
export const SignupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new user; attached to the given mandator. By default, the user will receive an email                      confirmation after registration. This may be skipped using the \'skipEmailConfirmation\' parameter;                      see parameter documentation. Also, users in role \'ROLE_SUBSCRIPTION_MANAGEMENT\' may register                      users without email confirmation.                      Note that changing a users mandator afterwards requires the executing user to be in role \'ROLE_MANDATOR_ADMIN\'.
         * @param {CreateUserParams} body The user parameters
         * @param {string} [clientSecret] DEPRECATED: Use CreateUserParams.mandatorClientSecret instead.                              The client secret is required for user registration without email confirmation. If                              provided the users email address is considered confirmed. Note that we do not perform                              validation on email addresses for server-to-server use cases. This means it is                              completely save to provide a random hash instead of a valid email address.                              The client secret must match the mandator given in the create user parameters.                              Make sure to never expose your client secret on the web, i.e. do not use it in a publicly                              visible web site or script.
         * @param {boolean} [skipSessionLogin] Not relevant for server-to-server use cases. By default, the operations does perform a                                session-based login immediately. If this parameter is set true, no login will be done.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (body: CreateUserParams, clientSecret?: string, skipSessionLogin?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createUser', 'body', body)
            const localVarPath = `/v1/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientSecret !== undefined) {
                localVarQueryParameter['clientSecret'] = clientSecret;
            }

            if (skipSessionLogin !== undefined) {
                localVarQueryParameter['skipSessionLogin'] = skipSessionLogin;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignupApi - functional programming interface
 * @export
 */
export const SignupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new user; attached to the given mandator. By default, the user will receive an email                      confirmation after registration. This may be skipped using the \'skipEmailConfirmation\' parameter;                      see parameter documentation. Also, users in role \'ROLE_SUBSCRIPTION_MANAGEMENT\' may register                      users without email confirmation.                      Note that changing a users mandator afterwards requires the executing user to be in role \'ROLE_MANDATOR_ADMIN\'.
         * @param {CreateUserParams} body The user parameters
         * @param {string} [clientSecret] DEPRECATED: Use CreateUserParams.mandatorClientSecret instead.                              The client secret is required for user registration without email confirmation. If                              provided the users email address is considered confirmed. Note that we do not perform                              validation on email addresses for server-to-server use cases. This means it is                              completely save to provide a random hash instead of a valid email address.                              The client secret must match the mandator given in the create user parameters.                              Make sure to never expose your client secret on the web, i.e. do not use it in a publicly                              visible web site or script.
         * @param {boolean} [skipSessionLogin] Not relevant for server-to-server use cases. By default, the operations does perform a                                session-based login immediately. If this parameter is set true, no login will be done.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(body: CreateUserParams, clientSecret?: string, skipSessionLogin?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(body, clientSecret, skipSessionLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignupApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SignupApi - factory interface
 * @export
 */
export const SignupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignupApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new user; attached to the given mandator. By default, the user will receive an email                      confirmation after registration. This may be skipped using the \'skipEmailConfirmation\' parameter;                      see parameter documentation. Also, users in role \'ROLE_SUBSCRIPTION_MANAGEMENT\' may register                      users without email confirmation.                      Note that changing a users mandator afterwards requires the executing user to be in role \'ROLE_MANDATOR_ADMIN\'.
         * @param {SignupApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: SignupApiCreateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.createUser(requestParameters.body, requestParameters.clientSecret, requestParameters.skipSessionLogin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in SignupApi.
 * @export
 * @interface SignupApiCreateUserRequest
 */
export interface SignupApiCreateUserRequest {
    /**
     * The user parameters
     * @type {CreateUserParams}
     * @memberof SignupApiCreateUser
     */
    readonly body: CreateUserParams

    /**
     * DEPRECATED: Use CreateUserParams.mandatorClientSecret instead.                              The client secret is required for user registration without email confirmation. If                              provided the users email address is considered confirmed. Note that we do not perform                              validation on email addresses for server-to-server use cases. This means it is                              completely save to provide a random hash instead of a valid email address.                              The client secret must match the mandator given in the create user parameters.                              Make sure to never expose your client secret on the web, i.e. do not use it in a publicly                              visible web site or script.
     * @type {string}
     * @memberof SignupApiCreateUser
     */
    readonly clientSecret?: string

    /**
     * Not relevant for server-to-server use cases. By default, the operations does perform a                                session-based login immediately. If this parameter is set true, no login will be done.
     * @type {boolean}
     * @memberof SignupApiCreateUser
     */
    readonly skipSessionLogin?: boolean
}

/**
 * SignupApi - object-oriented interface
 * @export
 * @class SignupApi
 * @extends {BaseAPI}
 */
export class SignupApi extends BaseAPI {
    /**
     * 
     * @summary Create a new user; attached to the given mandator. By default, the user will receive an email                      confirmation after registration. This may be skipped using the \'skipEmailConfirmation\' parameter;                      see parameter documentation. Also, users in role \'ROLE_SUBSCRIPTION_MANAGEMENT\' may register                      users without email confirmation.                      Note that changing a users mandator afterwards requires the executing user to be in role \'ROLE_MANDATOR_ADMIN\'.
     * @param {SignupApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupApi
     */
    public createUser(requestParameters: SignupApiCreateUserRequest, options?: RawAxiosRequestConfig) {
        return SignupApiFp(this.configuration).createUser(requestParameters.body, requestParameters.clientSecret, requestParameters.skipSessionLogin, options).then((request) => request(this.axios, this.basePath));
    }
}

