import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useKey } from "react-use";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useInitiateOtpSetup, useOtpSetup } from "@generated/user/hooks";
import { OtpProvisioningData } from "@generated/user";
import {
  CopyTypography,
  LoadingButton,
  LoadingPlaceHolder,
  RHFTextField,
} from "@components";
import { SettingsErrorBlock } from "@features/settingsPage/components/SettingsErrorBlock";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { SecuritySuccessModal } from "./SecuritySuccessModal";

interface FormFields {
  otpCode: string;
  otpLabel: string;
}

const CodeBox = styled(Box)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-radius: 6px;
  height: 54px;
`;

const CreateMFA = ({
  setCustomTitle,
  isLoadingUserData,
}: {
  setCustomTitle: Dispatch<SetStateAction<string>>;
  isLoadingUserData: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [otpData, setOtpData] = useState<OtpProvisioningData>();
  const [showBankCodes, setShowBankCodes] = useState<boolean>(false);
  const [bankCodes, setBankCodes] = useState<string[]>([]);
  const bankCodeCopy = bankCodes.join(", ");

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const {
    mutate: setupOtp,
    isLoading,
    isSuccess,
  } = useOtpSetup({
    onSuccess: async (response) => {
      setShowSuccessModal(true);
      setBankCodes(response.codes);
    },
    onError: () => {
      enqueueSnackbar(t("settingsPage.commonErrorMessage"), {
        autoHideDuration: 3000,
        variant: "error",
      });
    },
  });
  const {
    mutate: otpSetup,
    isLoading: isLoadingOtpSetup,
    isError,
  } = useInitiateOtpSetup({
    onSuccess: async (data) => {
      setOtpData(data);
    },
  });

  const schema = Yup.object().shape({
    otpCode: Yup.string().required(t("form.errors.required")),
    otpLabel: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, watch } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      otpCode: "",
      otpLabel: t("settingsPage.security.mfaNamePlaceholder"),
    },
  });

  const handleConfirm = () => {
    setupOtp(getValues());
  };

  const handleCloseSuccessModal = async () => {
    await queryClient.invalidateQueries({
      queryKey: [CACHE_KEYS.USERS_MYSELF],
    });
    setCustomTitle(t("settingsPage.security.setupTitle"));
    setShowBankCodes(true);
    setShowSuccessModal(false);
  };

  useEffect(() => {
    otpSetup({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isConfirmBtnDisabled = !watch("otpCode") || !watch("otpLabel");

  useKey(
    "Enter",
    () => {
      if (!isSuccess && !isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isSuccess, isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  if (isLoadingUserData || isLoadingOtpSetup) return <LoadingPlaceHolder />;

  if (isError) {
    return <SettingsErrorBlock />;
  }

  return (
    <>
      {!showBankCodes ? (
        <>
          <Typography variant="body1" fontWeight={400} letterSpacing={-0.35}>
            {t("settingsPage.security.newTFADescription")}
          </Typography>
          {otpData?.qrCodeSvg && (
            <Box display="flex" justifyContent="center" m="48px 0">
              <img
                style={{ width: "192px", height: "192px" }}
                alt="qr-code"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  otpData.qrCodeSvg
                )}`}
              />
            </Box>
          )}
          <Typography variant="body1" fontWeight={400} letterSpacing={-0.35}>
            {t("settingsPage.security.scanInfo")}
          </Typography>
          {otpData?.qrCodeSvg && (
            <Typography
              m="16px 0"
              variant="body1"
              fontSize="22px"
              lineHeight="30px"
              fontWeight={600}
            >
              {otpData.otpSecret}
            </Typography>
          )}
          <Box display="flex" flexDirection="column" gap={theme.spacing(6)}>
            <RHFTextField
              name="otpCode"
              label={t("settingsPage.security.verificationCode")}
              control={control}
              fullWidth
            />
            <RHFTextField
              name="otpLabel"
              label={t("settingsPage.security.mfaName")}
              control={control}
              fullWidth
            />
          </Box>
          <LoadingButton
            sx={{ width: "100%", margin: theme.spacing(12, 0, 4) }}
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleConfirm)}
            disabled={isConfirmBtnDisabled}
            isLoading={isLoading}
          >
            {t("settingsPage.security.activate")}
          </LoadingButton>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            fontWeight={400}
            letterSpacing={-0.27}
            mb={theme.spacing(4)}
          >
            {t("settingsPage.security.setupDescription")}
          </Typography>
          <Grid container spacing={theme.spacing(6)} justifyContent={"center"}>
            {bankCodes.map((code) => (
              <Grid item xs={"auto"} md={6} minWidth="311px" width="100%">
                <CodeBox>
                  <Typography variant="h6" fontSize="20px" lineHeight="28px">
                    {code}
                  </Typography>
                </CodeBox>
              </Grid>
            ))}
          </Grid>

          <Typography
            variant="body1"
            fontWeight={400}
            letterSpacing={-0.35}
            m={theme.spacing(4, 0)}
          >
            {t("settingsPage.security.setupInfo")}
          </Typography>
          <CopyTypography
            variant="body1"
            lineHeight="24px"
            fontWeight={500}
            iconPosition="end"
            color={theme.palette.text.link}
            iconColor={theme.palette.icon.active}
            text={t("settingsPage.security.copyBackupCodes")}
          >
            {bankCodeCopy}
          </CopyTypography>
          <Button
            sx={{
              marginTop: theme.spacing(12),
              marginBottom: theme.spacing(4),
            }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/settings/security")}
          >
            {t("settingsPage.security.successButton")}
          </Button>
        </>
      )}
      <SecuritySuccessModal
        isOpen={showSuccessModal}
        onClose={handleCloseSuccessModal}
      />
    </>
  );
};

export default CreateMFA;
