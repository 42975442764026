import { Box, Link as MuiLink, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as ArrowUpRight } from "@icons/arrow-up-right.svg";

export const LinkWithArrow = ({
  text,
  href,
}: {
  text: string;
  href: string;
}) => {
  const theme = useTheme();
  return (
    <MuiLink href={href} target="_blank" rel="noopener noreferrer">
      <Box display="flex" alignItems="center">
        <Typography
          mr={2}
          variant="body2"
          color={theme.palette.background.primary}
          fontWeight="600"
        >
          {text}
        </Typography>
        <ArrowUpRight />
      </Box>
    </MuiLink>
  );
};
