import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ReactComponent as DeadEmojiIcon } from "@icons/myicons/dead_emoji.svg";

export const SettingsErrorBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={theme.spacing(4)}
    >
      <DeadEmojiIcon />
      <Typography
        variant="body1"
        fontSize="26px"
        lineHeight="34px"
        fontWeight={600}
        letterSpacing={-0.4}
        m={theme.spacing(6, 0)}
      >
        {t("settingsPage.errorBlock.title")}
      </Typography>
      <Typography
        variant="body1"
        fontSize="18px"
        lineHeight="28px"
        fontWeight={400}
        textAlign="center"
        letterSpacing={-0.5}
      >
        <Trans
          i18nKey="settingsPage.errorBlock.subtitle"
          components={{
            linkText: (
              <Link href={`mailto:${t("aboutCompany.support.email")}`} />
            ),
          }}
        />
      </Typography>
    </Box>
  );
};
