/* tslint:disable */
/* eslint-disable */
/**
 * Finanzfluss API
 * An API for the <a href=\"https://www.finanzfluss.de/\">Finanzfluss</a> backend. Work in progress.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@finanzfluss.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { User } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Set the duration after which the user is automatically signed out when inactive.
         * @summary Change the auto sign out duration the currently logged-in user.
         * @param {ChangeAutoSignOutDurationNewAutoSignOutDurationEnum} newAutoSignOutDuration The new auto sign out duration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAutoSignOutDuration: async (newAutoSignOutDuration: ChangeAutoSignOutDurationNewAutoSignOutDurationEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAutoSignOutDuration' is not null or undefined
            assertParamExists('changeAutoSignOutDuration', 'newAutoSignOutDuration', newAutoSignOutDuration)
            const localVarPath = `/user/api/v1/users/changeAutoSignOutDuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (newAutoSignOutDuration !== undefined) {
                localVarQueryParameter['newAutoSignOutDuration'] = newAutoSignOutDuration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the discord ID of the currently logged-in user.
         * @summary Change the discord ID of the currently logged-in user.
         * @param {string} newDiscordId The new discord ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDiscordId: async (newDiscordId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDiscordId' is not null or undefined
            assertParamExists('changeDiscordId', 'newDiscordId', newDiscordId)
            const localVarPath = `/user/api/v1/users/changeDiscordId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (newDiscordId !== undefined) {
                localVarQueryParameter['newDiscordId'] = newDiscordId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the display user name of the currently logged-in user.
         * @summary Change the display name of the currently logged-in user.
         * @param {string} newDisplayName The new display name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDisplayName: async (newDisplayName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDisplayName' is not null or undefined
            assertParamExists('changeDisplayName', 'newDisplayName', newDisplayName)
            const localVarPath = `/user/api/v1/users/changeDisplayName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (newDisplayName !== undefined) {
                localVarQueryParameter['newDisplayName'] = newDisplayName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Change the email address of the currently logged-in user.  The user will receive an email asking for a confirmation of the change.
         * @summary Change the email of the currently logged-in user.
         * @param {string} newEmail The new email address
         * @param {string} password The current password of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (newEmail: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newEmail' is not null or undefined
            assertParamExists('changeEmail', 'newEmail', newEmail)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changeEmail', 'password', password)
            const localVarPath = `/user/api/v1/users/changeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (newEmail !== undefined) {
                localVarQueryParameter['newEmail'] = newEmail;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm a user using the confirmation token sent via email.
         * @summary Confirm a user signup with a confirmation token
         * @param {string} token The user confirmation token; which was sent to the user via email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirm: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirm', 'token', token)
            const localVarPath = `/user/api/v1/users/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permanently delete the currently logged-in user.  If a subscription exists, then it will be cancelled. Upon successful deletion, a confirmation email will be sent to the user.
         * @summary Permanently delete the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Initiate the password reset process by generating a token and sending it out to the user by email.  Note that the operation will always return status \"OK\" to prevent an attacker to systematically screen for valid email addresses.
         * @summary Send a password reset email to the user
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordReset: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('initiatePasswordReset', 'email', email)
            const localVarPath = `/user/api/v1/users/initiatePasswordReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve account information about the currently signed in user
         * @summary Get information about the currently signed in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myself: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Trigger a resending of the registration confirmation email to the user  For security reasons, the endpoint will always return a 201 status code, even if the email address is not found.
         * @summary Resend the registration confirmation email
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationEmail: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resendConfirmationEmail', 'email', email)
            const localVarPath = `/user/api/v1/users/resendConfirmationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset a password for a user that requested a password reset and received an email for that
         * @summary Reset a password
         * @param {string} resetPasswordToken The password reset token; which was sent to the user via email.
         * @param {string} newPassword The new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordToken: string, newPassword: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordToken' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordToken', resetPasswordToken)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('resetPassword', 'newPassword', newPassword)
            const localVarPath = `/user/api/v1/users/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resetPasswordToken !== undefined) {
                localVarQueryParameter['resetPasswordToken'] = resetPasswordToken;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Register a new user with an email and password.  The user will receive an confirmation email (DOI) after registration.
         * @summary Signup a new user with email and password.
         * @param {string} displayName The name / nickname of the new user, minimum 3 characters and maximum 15
         * @param {string} email The email of the new user
         * @param {string} password The password of the user to login with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (displayName: string, email: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayName' is not null or undefined
            assertParamExists('signup', 'displayName', displayName)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('signup', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('signup', 'password', password)
            const localVarPath = `/user/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (displayName !== undefined) {
                localVarQueryParameter['displayName'] = displayName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Set the duration after which the user is automatically signed out when inactive.
         * @summary Change the auto sign out duration the currently logged-in user.
         * @param {ChangeAutoSignOutDurationNewAutoSignOutDurationEnum} newAutoSignOutDuration The new auto sign out duration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAutoSignOutDuration(newAutoSignOutDuration: ChangeAutoSignOutDurationNewAutoSignOutDurationEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAutoSignOutDuration(newAutoSignOutDuration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeAutoSignOutDuration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Change the discord ID of the currently logged-in user.
         * @summary Change the discord ID of the currently logged-in user.
         * @param {string} newDiscordId The new discord ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDiscordId(newDiscordId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDiscordId(newDiscordId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeDiscordId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Change the display user name of the currently logged-in user.
         * @summary Change the display name of the currently logged-in user.
         * @param {string} newDisplayName The new display name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDisplayName(newDisplayName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDisplayName(newDisplayName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeDisplayName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Change the email address of the currently logged-in user.  The user will receive an email asking for a confirmation of the change.
         * @summary Change the email of the currently logged-in user.
         * @param {string} newEmail The new email address
         * @param {string} password The current password of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(newEmail: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(newEmail, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Confirm a user using the confirmation token sent via email.
         * @summary Confirm a user signup with a confirmation token
         * @param {string} token The user confirmation token; which was sent to the user via email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirm(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirm(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.confirm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Permanently delete the currently logged-in user.  If a subscription exists, then it will be cancelled. Upon successful deletion, a confirmation email will be sent to the user.
         * @summary Permanently delete the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Initiate the password reset process by generating a token and sending it out to the user by email.  Note that the operation will always return status \"OK\" to prevent an attacker to systematically screen for valid email addresses.
         * @summary Send a password reset email to the user
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePasswordReset(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePasswordReset(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.initiatePasswordReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve account information about the currently signed in user
         * @summary Get information about the currently signed in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myself(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myself(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.myself']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Trigger a resending of the registration confirmation email to the user  For security reasons, the endpoint will always return a 201 status code, even if the email address is not found.
         * @summary Resend the registration confirmation email
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendConfirmationEmail(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendConfirmationEmail(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resendConfirmationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset a password for a user that requested a password reset and received an email for that
         * @summary Reset a password
         * @param {string} resetPasswordToken The password reset token; which was sent to the user via email.
         * @param {string} newPassword The new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordToken: string, newPassword: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordToken, newPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Register a new user with an email and password.  The user will receive an confirmation email (DOI) after registration.
         * @summary Signup a new user with email and password.
         * @param {string} displayName The name / nickname of the new user, minimum 3 characters and maximum 15
         * @param {string} email The email of the new user
         * @param {string} password The password of the user to login with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(displayName: string, email: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(displayName, email, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.signup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Set the duration after which the user is automatically signed out when inactive.
         * @summary Change the auto sign out duration the currently logged-in user.
         * @param {UsersApiChangeAutoSignOutDurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAutoSignOutDuration(requestParameters: UsersApiChangeAutoSignOutDurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.changeAutoSignOutDuration(requestParameters.newAutoSignOutDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the discord ID of the currently logged-in user.
         * @summary Change the discord ID of the currently logged-in user.
         * @param {UsersApiChangeDiscordIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDiscordId(requestParameters: UsersApiChangeDiscordIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.changeDiscordId(requestParameters.newDiscordId, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the display user name of the currently logged-in user.
         * @summary Change the display name of the currently logged-in user.
         * @param {UsersApiChangeDisplayNameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDisplayName(requestParameters: UsersApiChangeDisplayNameRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.changeDisplayName(requestParameters.newDisplayName, options).then((request) => request(axios, basePath));
        },
        /**
         *  Change the email address of the currently logged-in user.  The user will receive an email asking for a confirmation of the change.
         * @summary Change the email of the currently logged-in user.
         * @param {UsersApiChangeEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(requestParameters: UsersApiChangeEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changeEmail(requestParameters.newEmail, requestParameters.password, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm a user using the confirmation token sent via email.
         * @summary Confirm a user signup with a confirmation token
         * @param {UsersApiConfirmRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirm(requestParameters: UsersApiConfirmRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.confirm(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * Permanently delete the currently logged-in user.  If a subscription exists, then it will be cancelled. Upon successful deletion, a confirmation email will be sent to the user.
         * @summary Permanently delete the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         *  Initiate the password reset process by generating a token and sending it out to the user by email.  Note that the operation will always return status \"OK\" to prevent an attacker to systematically screen for valid email addresses.
         * @summary Send a password reset email to the user
         * @param {UsersApiInitiatePasswordResetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordReset(requestParameters: UsersApiInitiatePasswordResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.initiatePasswordReset(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve account information about the currently signed in user
         * @summary Get information about the currently signed in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myself(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.myself(options).then((request) => request(axios, basePath));
        },
        /**
         *  Trigger a resending of the registration confirmation email to the user  For security reasons, the endpoint will always return a 201 status code, even if the email address is not found.
         * @summary Resend the registration confirmation email
         * @param {UsersApiResendConfirmationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationEmail(requestParameters: UsersApiResendConfirmationEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resendConfirmationEmail(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset a password for a user that requested a password reset and received an email for that
         * @summary Reset a password
         * @param {UsersApiResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(requestParameters: UsersApiResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetPassword(requestParameters.resetPasswordToken, requestParameters.newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         *  Register a new user with an email and password.  The user will receive an confirmation email (DOI) after registration.
         * @summary Signup a new user with email and password.
         * @param {UsersApiSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(requestParameters: UsersApiSignupRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.signup(requestParameters.displayName, requestParameters.email, requestParameters.password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeAutoSignOutDuration operation in UsersApi.
 * @export
 * @interface UsersApiChangeAutoSignOutDurationRequest
 */
export interface UsersApiChangeAutoSignOutDurationRequest {
    /**
     * The new auto sign out duration
     * @type {'15mins' | '30mins' | '1h' | '3h' | '6h' | '12h' | '24h' | '1W' | '1M'}
     * @memberof UsersApiChangeAutoSignOutDuration
     */
    readonly newAutoSignOutDuration: ChangeAutoSignOutDurationNewAutoSignOutDurationEnum
}

/**
 * Request parameters for changeDiscordId operation in UsersApi.
 * @export
 * @interface UsersApiChangeDiscordIdRequest
 */
export interface UsersApiChangeDiscordIdRequest {
    /**
     * The new discord ID
     * @type {string}
     * @memberof UsersApiChangeDiscordId
     */
    readonly newDiscordId: string
}

/**
 * Request parameters for changeDisplayName operation in UsersApi.
 * @export
 * @interface UsersApiChangeDisplayNameRequest
 */
export interface UsersApiChangeDisplayNameRequest {
    /**
     * The new display name
     * @type {string}
     * @memberof UsersApiChangeDisplayName
     */
    readonly newDisplayName: string
}

/**
 * Request parameters for changeEmail operation in UsersApi.
 * @export
 * @interface UsersApiChangeEmailRequest
 */
export interface UsersApiChangeEmailRequest {
    /**
     * The new email address
     * @type {string}
     * @memberof UsersApiChangeEmail
     */
    readonly newEmail: string

    /**
     * The current password of the user.
     * @type {string}
     * @memberof UsersApiChangeEmail
     */
    readonly password: string
}

/**
 * Request parameters for confirm operation in UsersApi.
 * @export
 * @interface UsersApiConfirmRequest
 */
export interface UsersApiConfirmRequest {
    /**
     * The user confirmation token; which was sent to the user via email.
     * @type {string}
     * @memberof UsersApiConfirm
     */
    readonly token: string
}

/**
 * Request parameters for initiatePasswordReset operation in UsersApi.
 * @export
 * @interface UsersApiInitiatePasswordResetRequest
 */
export interface UsersApiInitiatePasswordResetRequest {
    /**
     * The email of the user
     * @type {string}
     * @memberof UsersApiInitiatePasswordReset
     */
    readonly email: string
}

/**
 * Request parameters for resendConfirmationEmail operation in UsersApi.
 * @export
 * @interface UsersApiResendConfirmationEmailRequest
 */
export interface UsersApiResendConfirmationEmailRequest {
    /**
     * The email of the user
     * @type {string}
     * @memberof UsersApiResendConfirmationEmail
     */
    readonly email: string
}

/**
 * Request parameters for resetPassword operation in UsersApi.
 * @export
 * @interface UsersApiResetPasswordRequest
 */
export interface UsersApiResetPasswordRequest {
    /**
     * The password reset token; which was sent to the user via email.
     * @type {string}
     * @memberof UsersApiResetPassword
     */
    readonly resetPasswordToken: string

    /**
     * The new password
     * @type {string}
     * @memberof UsersApiResetPassword
     */
    readonly newPassword: string
}

/**
 * Request parameters for signup operation in UsersApi.
 * @export
 * @interface UsersApiSignupRequest
 */
export interface UsersApiSignupRequest {
    /**
     * The name / nickname of the new user, minimum 3 characters and maximum 15
     * @type {string}
     * @memberof UsersApiSignup
     */
    readonly displayName: string

    /**
     * The email of the new user
     * @type {string}
     * @memberof UsersApiSignup
     */
    readonly email: string

    /**
     * The password of the user to login with
     * @type {string}
     * @memberof UsersApiSignup
     */
    readonly password: string
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Set the duration after which the user is automatically signed out when inactive.
     * @summary Change the auto sign out duration the currently logged-in user.
     * @param {UsersApiChangeAutoSignOutDurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeAutoSignOutDuration(requestParameters: UsersApiChangeAutoSignOutDurationRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeAutoSignOutDuration(requestParameters.newAutoSignOutDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the discord ID of the currently logged-in user.
     * @summary Change the discord ID of the currently logged-in user.
     * @param {UsersApiChangeDiscordIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeDiscordId(requestParameters: UsersApiChangeDiscordIdRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeDiscordId(requestParameters.newDiscordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the display user name of the currently logged-in user.
     * @summary Change the display name of the currently logged-in user.
     * @param {UsersApiChangeDisplayNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeDisplayName(requestParameters: UsersApiChangeDisplayNameRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeDisplayName(requestParameters.newDisplayName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Change the email address of the currently logged-in user.  The user will receive an email asking for a confirmation of the change.
     * @summary Change the email of the currently logged-in user.
     * @param {UsersApiChangeEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeEmail(requestParameters: UsersApiChangeEmailRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeEmail(requestParameters.newEmail, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm a user using the confirmation token sent via email.
     * @summary Confirm a user signup with a confirmation token
     * @param {UsersApiConfirmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public confirm(requestParameters: UsersApiConfirmRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).confirm(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permanently delete the currently logged-in user.  If a subscription exists, then it will be cancelled. Upon successful deletion, a confirmation email will be sent to the user.
     * @summary Permanently delete the currently logged-in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Initiate the password reset process by generating a token and sending it out to the user by email.  Note that the operation will always return status \"OK\" to prevent an attacker to systematically screen for valid email addresses.
     * @summary Send a password reset email to the user
     * @param {UsersApiInitiatePasswordResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public initiatePasswordReset(requestParameters: UsersApiInitiatePasswordResetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).initiatePasswordReset(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve account information about the currently signed in user
     * @summary Get information about the currently signed in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public myself(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).myself(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Trigger a resending of the registration confirmation email to the user  For security reasons, the endpoint will always return a 201 status code, even if the email address is not found.
     * @summary Resend the registration confirmation email
     * @param {UsersApiResendConfirmationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resendConfirmationEmail(requestParameters: UsersApiResendConfirmationEmailRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resendConfirmationEmail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset a password for a user that requested a password reset and received an email for that
     * @summary Reset a password
     * @param {UsersApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(requestParameters: UsersApiResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPassword(requestParameters.resetPasswordToken, requestParameters.newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Register a new user with an email and password.  The user will receive an confirmation email (DOI) after registration.
     * @summary Signup a new user with email and password.
     * @param {UsersApiSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public signup(requestParameters: UsersApiSignupRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).signup(requestParameters.displayName, requestParameters.email, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ChangeAutoSignOutDurationNewAutoSignOutDurationEnum = {
    _15MINS: '15mins',
    _30MINS: '30mins',
    _1H: '1h',
    _3H: '3h',
    _6H: '6h',
    _12H: '12h',
    _24H: '24h',
    _1_W: '1W',
    _1_M: '1M'
} as const;
export type ChangeAutoSignOutDurationNewAutoSignOutDurationEnum = typeof ChangeAutoSignOutDurationNewAutoSignOutDurationEnum[keyof typeof ChangeAutoSignOutDurationNewAutoSignOutDurationEnum];
