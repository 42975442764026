import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, TypographyProps } from "@mui/material";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";

type ValueType = "positive" | "negative" | "zero";

const ArrowContainer = styled.div<{ positive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme, positive }) =>
    positive ? theme.palette.text.positive : theme.palette.text.negative};
`;

const Container = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "showBackground",
})<{
  type: ValueType;
  showBackground?: boolean;
}>`
  color: ${({ theme, type }) => {
    if (type === "positive") {
      return theme.palette.text.positive;
    }
    if (type === "negative") {
      return theme.palette.text.negative;
    }
    return theme.palette.text.primary;
  }};
  padding: ${({ theme, showBackground }) =>
    showBackground ? theme.spacing(0.5, 1) : 0};
  border-radius: 4px;
  background: ${({
    theme: {
      palette: { background },
    },
    type,
    showBackground = false,
  }) => {
    if (!showBackground) return "transparent";

    if (type === "positive") {
      return background.success;
    }
    if (type === "negative") {
      return background.error;
    }
    return "transparent";
  }};
`;

export interface GainLossTypographyProps extends TypographyProps {
  value: number;
  showIndicator?: boolean;
  showBackground?: boolean;
  iconSize?: string;
}

export const GainLossTypography = ({
  value,
  showIndicator = false,
  showBackground = false,
  iconSize = "8px",
  ...props
}: GainLossTypographyProps) => {
  let valueType: ValueType = "zero";
  if (value > 0) {
    valueType = "positive";
  }
  if (value < 0) {
    valueType = "negative";
  }

  return (
    <Box display="flex" alignItems="center" gap={1.5} {...props}>
      <Container type={valueType} showBackground={showBackground} {...props} />
      {showIndicator && (
        <ArrowContainer positive={value > 0}>
          {value > 0 && <UpIcon width={iconSize} />}
          {value < 0 && <DownIcon width={iconSize} />}
        </ArrowContainer>
      )}
    </Box>
  );
};
