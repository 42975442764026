import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { renderToString } from "react-dom/server";
import { Theme, useTheme } from "@mui/material";
import { toCurrency, toPercent } from "@helpers";
import { ChartTooltip } from "@components";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    formatter: () => false,
  },
  subtitle: {
    align: "center",
    verticalAlign: "middle",
    useHTML: true,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      borderWidth: 2,
      borderRadius: 0,
      size: "100%",
      innerSize: "65%",
    },
  },
};

export type DonutChartItem = {
  id: number | string;
  name: string;
  value: number;
  currency: string;
  color?: string;
  childrenNumber?: number;
  y: number;
};

export type DonutChartItemWithPercentage = DonutChartItem & {
  percentage?: number;
};

const getSubtitle = (
  theme: Theme,
  itemToShow?: DonutChartItemWithPercentage | null,
  totalValue?: number,
  totalValueText?: string,
  totalValueMaximumDigits?: number,
  subtitleWidth?: number,
  subtitleFirstLineStyle: Record<string, string> = {},
  subtitleSecondLineStyle: Record<string, string> = {},
  isLoading = false,
  view: "absolute" | "percentage" = "absolute"
) => {
  if (isLoading) return null;

  return renderToString(
    <div
      style={{
        width: subtitleWidth || "130px",
        maxHeight: "120px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontFamily: "Averta,Arial",
          fontSize: "26px",
          lineHeight: "34px",
          marginBottom: "4px",
          fontWeight: 600,
          color: theme.palette.text.primary,
          ...subtitleFirstLineStyle,
        }}
      >
        {view === "absolute"
          ? toCurrency(
              itemToShow?.value || totalValue,
              itemToShow?.currency || "EUR",
              {
                maximumFractionDigits: totalValueMaximumDigits,
                minimumFractionDigits: totalValueMaximumDigits,
              }
            )
          : toPercent(itemToShow ? (itemToShow?.percentage ?? 0) / 100 : 1)}
      </div>
      <div
        style={{
          fontFamily: "Averta,Arial",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "18px",
          color: theme.palette.text.secondary,
          wordBreak: "break-all",
          width: "100%",
          whiteSpace: "pre-wrap",
          textAlign: "center",
          ...subtitleSecondLineStyle,
        }}
      >
        {itemToShow
          ? (itemToShow.name?.length || 0) > 50
            ? itemToShow.name.slice(0, 50) + "..."
            : itemToShow.name
          : totalValueText}
      </div>
    </div>
  );
};

type Props = {
  chartData?: DonutChartItem[];
  totalValue?: number;
  totalValueMaximumDigits?: number;
  chartColors?: string[];
  onSectionHover?: (item: DonutChartItem | null) => void;
  onSectionSelect?: (item: DonutChartItem | null) => void;
  width?: number;
  height?: number;
  totalValueText?: string;
  subtitleFirstLineStyle?: Record<string, string>;
  subtitleSecondLineStyle?: Record<string, string>;
  isLoading?: boolean;
  view?: "absolute" | "percentage";
};

export type DonutChartRef = {
  setSelectedItem: (item: DonutChartItemWithPercentage | null) => void;
  setHoveredItem: (item: DonutChartItemWithPercentage | null) => void;
  finishedAnimating?: boolean;
};

export const DonutChart = memo(
  forwardRef((props: Props, ref: ForwardedRef<DonutChartRef>) => {
    const {
      chartData,
      totalValue,
      totalValueMaximumDigits = 2,
      chartColors,
      onSectionHover,
      onSectionSelect,
      width = 340,
      height = 340,
      totalValueText,
      subtitleFirstLineStyle,
      subtitleSecondLineStyle,
      isLoading,
      view = "absolute",
    } = props;
    const theme = useTheme();
    const [selectedItem, setSelectedItem] =
      useState<DonutChartItemWithPercentage | null>(null);
    const [hoveredItem, setHoveredItem] =
      useState<DonutChartItemWithPercentage | null>(null);
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const subtitleWidth = (width < height ? width : height) * 0.65 - 50;

    const itemToShow = hoveredItem || selectedItem;
    const subtitle = getSubtitle(
      theme,
      itemToShow,
      totalValue,
      totalValueText,
      totalValueMaximumDigits,
      subtitleWidth,
      subtitleFirstLineStyle,
      subtitleSecondLineStyle,
      isLoading,
      view
    );
    const tooltipFormatter = useCallback(
      function () {
        // @ts-ignore
        if (this.point.id !== selectedItem?.id) return "";

        return renderToString(
          <ChartTooltip>
            {/*@ts-ignore*/}
            {toPercent(this.point.percentage / 100)}
          </ChartTooltip>
        );
      },
      [selectedItem?.id]
    );

    const selectItem = useCallback((item: DonutChartItem | null) => {
      // @ts-ignore NOTE: Don't change that to variable -> it won't work
      if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
        return;

      if (item === null) {
        setSelectedItem(null);
        return;
      }

      // @ts-ignore
      chartComponentRef?.current?.chart.series[0].data
        // @ts-ignore
        .find((chartItem) => chartItem.id === item.id)
        ?.select();
    }, []);

    const hoverOverItem = useCallback((item: DonutChartItem | null) => {
      // @ts-ignore
      if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
        return;

      const foundItem = chartComponentRef?.current?.chart.series[0].data
        // @ts-ignore
        .find((chartItem) => chartItem.id === item?.id);

      setHoveredItem(
        item ? { ...item, percentage: foundItem?.percentage ?? 0 } : null
      );
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        setSelectedItem: selectItem,
        setHoveredItem: hoverOverItem,
      }),
      [selectItem, hoverOverItem]
    );

    const onUnselect = useCallback(
      (data: any) => {
        // @ts-ignore
        if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
          return;

        setSelectedItem((selectedItem) => {
          if (selectedItem?.id === data.target.options.id) {
            onSectionSelect?.(null);
            return null;
          }
          return selectedItem;
        });
      },
      [onSectionSelect]
    );

    const handleSelect = useCallback(
      (data: any) => {
        // @ts-ignore
        if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
          return;

        setSelectedItem({
          ...data.target.options,
          percentage: data.target.percentage,
        });
        onSectionSelect?.({
          ...data.target.options,
          percentage: data.target.percentage,
        });
      },
      [onSectionSelect]
    );

    const handleMouseOver = useCallback(
      (data: any) => {
        // @ts-ignore
        if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
          return;

        setHoveredItem({
          ...data.target.options,
          percentage: data.target.percentage,
        });
        onSectionHover?.({
          ...data.target.options,
          percentage: data.target.percentage,
        });
      },
      [onSectionHover]
    );

    const handleMouseOut = useCallback(() => {
      // @ts-ignore
      if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
        return;

      setHoveredItem(null);
      onSectionHover?.(null);
    }, [onSectionHover]);

    const chartOptions = useMemo(() => {
      return {
        ...options,
        chart: {
          ...options.chart,
          width,
          height,
        },
        colors: chartColors || [],
        subtitle: {
          ...options.subtitle,
          text: subtitle,
        },
        plotOptions: {
          ...options.plotOptions,
          pie: {
            ...options.plotOptions?.pie,
            dataLabels: {
              enabled: true,
              useHTML: true,
              distance: -10,
              crop: false,
              style: {
                color: theme.palette.text.primary,
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "18px",
              },
              formatter: tooltipFormatter,
            },
          },
          series: {
            stickyTracking: false,
            point: {
              events: {
                unselect: onUnselect,
                select: handleSelect,
                mouseOver: handleMouseOver,
                mouseOut: handleMouseOut,
              },
            },
          },
        },
        series: [
          {
            colorByPoint: true,
            data: chartData,
          },
        ],
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      width,
      height,
      chartColors,
      chartData,
      theme,
      subtitle,
      tooltipFormatter,
    ]);

    useEffect(() => {
      // @ts-ignore
      if (!chartComponentRef?.current?.chart.series[0].finishedAnimating)
        return;

      chartComponentRef?.current?.chart?.reflow();
    }, [chartOptions]);

    return (
      <WithLoadingSpinner isLoading={Boolean(isLoading)}>
        <HighchartsReact
          highcharts={Highcharts}
          ref={chartComponentRef}
          options={chartOptions}
        />
      </WithLoadingSpinner>
    );
  })
);
