export const createObjectUrl = (
  data: File | Blob | string,
  type = "application/pdf"
): string => {
  const blob = new Blob([data], { type });
  return URL.createObjectURL(blob);
};

export const downloadFile = (
  objectUrl: string,
  name: string = "file"
): void => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = objectUrl;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(objectUrl);
  document.body.removeChild(a);
};
