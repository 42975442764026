import React, { useMemo } from "react";
import { endOfQuarter, format, startOfQuarter } from "date-fns";
import { Box, styled } from "@mui/material";
import { BarChartMode, Range, BarChartItem } from "../types";
import { BarSection } from "./BarSection";

const BarChartContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "mode" && prop !== "transitionDirection",
})<{ mode: BarChartMode; transitionDirection: "left" | "right" | null }>(
  ({ theme, mode, transitionDirection }) => ({
    display: "flex",
    width: "100%",
    flexShrink: 0,
    alignItems: "center",
    gap: theme.spacing(1),
    overflow: "auto",
    marginBottom: theme.spacing(1),
    transform: `translateX(${
      transitionDirection === "left"
        ? "0"
        : transitionDirection === "right"
        ? "-200%"
        : "-100%"
    })`,
    transition: transitionDirection ? "transform 0.3s ease-in-out" : "none",

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      justifyContent: mode === "custom" ? "center" : "flex-start",
    },
  })
);

const formatData = (
  data: BarChartItem[],
  mode: BarChartMode,
  mainPeriod: Range
) => {
  if (mode === "quarterly") {
    return groupByQuarter(data);
  }

  if (mode === "custom") {
    return groupByPeriod(data, mainPeriod);
  }

  return data;
};

const groupByQuarter = (data: BarChartItem[]) => {
  const groupedData = data.reduce((acc, item, i) => {
    const month = item.startDate.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    const quarterLabel = `Q${quarter}`;

    if (acc[quarterLabel]) {
      acc[quarterLabel].value += item.value;
      acc[quarterLabel].value2 += item.value2;
    } else {
      acc[quarterLabel] = {
        ...item,
        id: quarterLabel,
        label: quarterLabel,
        startDate: startOfQuarter(item.startDate),
        endDate: endOfQuarter(item.endDate),
      };
    }

    return acc;
  }, {} as Record<string, BarChartItem>);

  return Object.values(groupedData);
};

const groupByPeriod = (data: BarChartItem[], period: Required<RangeType>) => {
  const groupedData = data.reduce((acc, item, i) => {
    const label = "custom-period";

    if (acc[label]) {
      acc[label].value += item.value;
      acc[label].value2 += item.value2;
    } else {
      acc[label] = {
        ...item,
        id: label,
        label: `${format(period.startDate, "dd.MM.yy")}-${format(
          period.startDate,
          "dd.MM.yy"
        )}`,
        startDate: period.startDate,
        endDate: period.endDate,
      };
    }

    return acc;
  }, {} as Record<string, BarChartItem>);

  return Object.values(groupedData);
};

type Props = {
  data: BarChartItem[];
  mode: BarChartMode;
  mainPeriod: Range;
  onBarClick: (item: BarChartItem) => void;
  selectedBar: string | null;
  isAnimationEnabled: boolean;
  transitionDirection: "left" | "right" | null;
};

export const BarChartYear = ({
  data,
  mode,
  mainPeriod,
  onBarClick,
  selectedBar,
  isAnimationEnabled,
  transitionDirection,
}: Props) => {
  const formattedData = useMemo(
    () => formatData(data, mode, mainPeriod),
    [data, mode, mainPeriod]
  );

  const maxNumber = useMemo(
    () =>
      formattedData.reduce(
        (acc, item) => Math.max(acc, Math.max(item.value, item.value2)),
        0
      ),
    [formattedData]
  );

  return (
    <BarChartContainer mode={mode} transitionDirection={transitionDirection}>
      {formattedData.map((item, index) => {
        return (
          <BarSection
            key={item.id}
            fullWidth={mode === "monthly" || mode === "quarterly"}
            onClick={() => onBarClick(item)}
            item={item}
            isSelected={selectedBar === item.id}
            maxNumber={maxNumber}
            isAnimationEnabled={isAnimationEnabled}
          />
        );
      })}
    </BarChartContainer>
  );
};
