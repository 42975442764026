import React from "react";
import { styled, Typography, TypographyProps } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const HighlightSpan = styled("span")<{ highlight: boolean }>`
  ${({ highlight, theme }) =>
    highlight && `background-color: ${theme.palette.primary.lighter}`}
`;

const Container = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled: boolean }>`
  ${({ isDisabled }) => (isDisabled ? "opacity: 0.7;" : "")}
`;

export type SearchHighlightProps = {
  value?: string;
  searchValue?: string;
  isDisabled?: boolean;
} & TypographyProps;

export const SearchHighlight = ({
  value,
  searchValue,
  isDisabled = false,
  ...props
}: SearchHighlightProps) => {
  if (!value) return <></>;
  if (!searchValue)
    return (
      <Container isDisabled={isDisabled} {...props}>
        {value}
      </Container>
    );

  const matches = match(value, searchValue);
  const parts = parse(value, matches);

  return (
    <Container isDisabled={isDisabled} {...props}>
      {parts.map((part, index) => (
        <HighlightSpan key={index} highlight={part.highlight}>
          {part.text}
        </HighlightSpan>
      ))}
    </Container>
  );
};
