import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setManualInvestmentAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "MANUAL_SELECT")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    manualInvestmentSelected: true,
  };
};
