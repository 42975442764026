/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccessToken } from '../model';
// @ts-ignore
import { PageableUserList } from '../model';
// @ts-ignore
import { StripeSubscription } from '../model';
// @ts-ignore
import { UpdatePasswordParams } from '../model';
// @ts-ignore
import { UpdateUserParams } from '../model';
// @ts-ignore
import { User } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Finalize the change email workflow for the currently logged in user.
         * @param {string} emailChangeToken The email change token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (emailChangeToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangeToken' is not null or undefined
            assertParamExists('changeEmail', 'emailChangeToken', emailChangeToken)
            const localVarPath = `/v1/users/changeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailChangeToken !== undefined) {
                localVarQueryParameter['emailChangeToken'] = emailChangeToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the logged in users password by supplying the new as well as the current password.                      A new login (or API token) may be necessary after password change.
         * @param {UpdatePasswordParams} body The user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (body: UpdatePasswordParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changePassword', 'body', body)
            const localVarPath = `/v1/users/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm a user using the confirmation token sent via email.
         * @param {string} confirmationToken The user confirmation token; which is sent to the user via email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser: async (confirmationToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationToken' is not null or undefined
            assertParamExists('confirmUser', 'confirmationToken', confirmationToken)
            const localVarPath = `/v1/users/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (confirmationToken !== undefined) {
                localVarQueryParameter['confirmationToken'] = confirmationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanently unregister the currently logged in user instance. The user will be logged out upon                      successful unregistration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanently unregister the user identified by the given username / email. This endpoint is                        accessible at mandator level and allows authentication with with mandator credentials.
         * @param {string} username Username or email of the user to delete. Deletion with ID is not possible for security reasons.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUsername: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteUserByUsername', 'username', username)
            const localVarPath = `/v1/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate the email change process by generating a confirmation token and sending it out to                      the new email address. Note that the email address remains unchanged until the email change flow                      is complete.
         * @param {string} email The new email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateEmailChange: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('initiateEmailChange', 'email', email)
            const localVarPath = `/v1/users/initiateEmailChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate the password reset process by generating a confirmation token and sending it out to                      the user by email. Note that the operation will always return status \'OK\' to prevent an attacker                      to systematically screen for valid email addresses.
         * @param {string} email The users email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordReset: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('initiatePasswordReset', 'email', email)
            const localVarPath = `/v1/users/initiatePasswordReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a User (as Administrator) belonging to the administrators mandator
         * @param {string} [search] Search users matching the given search string (email, firstName, lastName)
         * @param {Array<number>} [id] Retrieve the users with the given ID(s). User IDs must match your mandator scope.
         * @param {boolean} [fullWildcard] Perform a full wildcard search (slow); default is false
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllUsers: async (search?: string, id?: Array<number>, fullWildcard?: boolean, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fullWildcard !== undefined) {
                localVarQueryParameter['fullWildcard'] = fullWildcard;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend the registration confirmation email.
         * @param {string} email The users email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationConfirmation: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resendRegistrationConfirmation', 'email', email)
            const localVarPath = `/v1/users/resendRegistrationConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset a users password and unlock the account (if required).
         * @param {string} confirmationToken The user confirmation token; which is sent to the user via email.
         * @param {string} password The new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (confirmationToken: string, password: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationToken' is not null or undefined
            assertParamExists('resetPassword', 'confirmationToken', confirmationToken)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('resetPassword', 'password', password)
            const localVarPath = `/v1/users/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (confirmationToken !== undefined) {
                localVarQueryParameter['confirmationToken'] = confirmationToken;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the user with the given username. This operation is powerful and thus limited to administrators.                      Note that changing a users mandator requires the executing user to be in role ROLE_MANDATOR_ADMIN.
         * @param {string} username Username or email of the user to update. An update with ID is not possible for security reasons.
         * @param {UpdateUserParams} body The user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (username: string, body: UpdateUserParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateUser', 'username', username)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUser', 'body', body)
            const localVarPath = `/v1/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a User (as Administrator) belonging to the administrators mandator.
         * @param {number} id ID of the user to retrieve; use \&#39;0\&#39; to retrieve the currently logged in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersId', 'id', id)
            const localVarPath = `/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Impersonate as a user
         * @param {number} id ID of the user to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdImpersonate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdImpersonate', 'id', id)
            const localVarPath = `/v1/users/{id}/impersonate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get myself (the currently logged in user instance)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMyself: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/myself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets current user premium (stripe) subscription
         * @param {number} id ID of user trying to access his subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPremiumSubscription: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersPremiumSubscription', 'id', id)
            const localVarPath = `/v1/users/{id}/premiumSubscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Finalize the change email workflow for the currently logged in user.
         * @param {string} emailChangeToken The email change token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(emailChangeToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(emailChangeToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changeEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the logged in users password by supplying the new as well as the current password.                      A new login (or API token) may be necessary after password change.
         * @param {UpdatePasswordParams} body The user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(body: UpdatePasswordParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.changePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Confirm a user using the confirmation token sent via email.
         * @param {string} confirmationToken The user confirmation token; which is sent to the user via email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUser(confirmationToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUser(confirmationToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.confirmUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Permanently unregister the currently logged in user instance. The user will be logged out upon                      successful unregistration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Permanently unregister the user identified by the given username / email. This endpoint is                        accessible at mandator level and allows authentication with with mandator credentials.
         * @param {string} username Username or email of the user to delete. Deletion with ID is not possible for security reasons.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserByUsername(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserByUsername(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserByUsername']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initiate the email change process by generating a confirmation token and sending it out to                      the new email address. Note that the email address remains unchanged until the email change flow                      is complete.
         * @param {string} email The new email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateEmailChange(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateEmailChange(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.initiateEmailChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initiate the password reset process by generating a confirmation token and sending it out to                      the user by email. Note that the operation will always return status \'OK\' to prevent an attacker                      to systematically screen for valid email addresses.
         * @param {string} email The users email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePasswordReset(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePasswordReset(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.initiatePasswordReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a User (as Administrator) belonging to the administrators mandator
         * @param {string} [search] Search users matching the given search string (email, firstName, lastName)
         * @param {Array<number>} [id] Retrieve the users with the given ID(s). User IDs must match your mandator scope.
         * @param {boolean} [fullWildcard] Perform a full wildcard search (slow); default is false
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllUsers(search?: string, id?: Array<number>, fullWildcard?: boolean, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllUsers(search, id, fullWildcard, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.listAndSearchAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend the registration confirmation email.
         * @param {string} email The users email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendRegistrationConfirmation(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendRegistrationConfirmation(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resendRegistrationConfirmation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset a users password and unlock the account (if required).
         * @param {string} confirmationToken The user confirmation token; which is sent to the user via email.
         * @param {string} password The new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(confirmationToken: string, password: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(confirmationToken, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the user with the given username. This operation is powerful and thus limited to administrators.                      Note that changing a users mandator requires the executing user to be in role ROLE_MANDATOR_ADMIN.
         * @param {string} username Username or email of the user to update. An update with ID is not possible for security reasons.
         * @param {UpdateUserParams} body The user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(username: string, body: UpdateUserParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(username, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a User (as Administrator) belonging to the administrators mandator.
         * @param {number} id ID of the user to retrieve; use \&#39;0\&#39; to retrieve the currently logged in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Impersonate as a user
         * @param {number} id ID of the user to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdImpersonate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdImpersonate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdImpersonate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get myself (the currently logged in user instance)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMyself(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMyself(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersMyself']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets current user premium (stripe) subscription
         * @param {number} id ID of user trying to access his subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPremiumSubscription(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPremiumSubscription(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPremiumSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Finalize the change email workflow for the currently logged in user.
         * @param {UsersApiChangeEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(requestParameters: UsersApiChangeEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changeEmail(requestParameters.emailChangeToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the logged in users password by supplying the new as well as the current password.                      A new login (or API token) may be necessary after password change.
         * @param {UsersApiChangePasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(requestParameters: UsersApiChangePasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changePassword(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm a user using the confirmation token sent via email.
         * @param {UsersApiConfirmUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser(requestParameters: UsersApiConfirmUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.confirmUser(requestParameters.confirmationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanently unregister the currently logged in user instance. The user will be logged out upon                      successful unregistration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanently unregister the user identified by the given username / email. This endpoint is                        accessible at mandator level and allows authentication with with mandator credentials.
         * @param {UsersApiDeleteUserByUsernameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByUsername(requestParameters: UsersApiDeleteUserByUsernameRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserByUsername(requestParameters.username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate the email change process by generating a confirmation token and sending it out to                      the new email address. Note that the email address remains unchanged until the email change flow                      is complete.
         * @param {UsersApiInitiateEmailChangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateEmailChange(requestParameters: UsersApiInitiateEmailChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.initiateEmailChange(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate the password reset process by generating a confirmation token and sending it out to                      the user by email. Note that the operation will always return status \'OK\' to prevent an attacker                      to systematically screen for valid email addresses.
         * @param {UsersApiInitiatePasswordResetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePasswordReset(requestParameters: UsersApiInitiatePasswordResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.initiatePasswordReset(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a User (as Administrator) belonging to the administrators mandator
         * @param {UsersApiListAndSearchAllUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllUsers(requestParameters: UsersApiListAndSearchAllUsersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableUserList> {
            return localVarFp.listAndSearchAllUsers(requestParameters.search, requestParameters.id, requestParameters.fullWildcard, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend the registration confirmation email.
         * @param {UsersApiResendRegistrationConfirmationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationConfirmation(requestParameters: UsersApiResendRegistrationConfirmationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resendRegistrationConfirmation(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset a users password and unlock the account (if required).
         * @param {UsersApiResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(requestParameters: UsersApiResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetPassword(requestParameters.confirmationToken, requestParameters.password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the user with the given username. This operation is powerful and thus limited to administrators.                      Note that changing a users mandator requires the executing user to be in role ROLE_MANDATOR_ADMIN.
         * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UsersApiUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.updateUser(requestParameters.username, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a User (as Administrator) belonging to the administrators mandator.
         * @param {UsersApiUsersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersId(requestParameters: UsersApiUsersIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Impersonate as a user
         * @param {UsersApiUsersIdImpersonateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdImpersonate(requestParameters: UsersApiUsersIdImpersonateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessToken> {
            return localVarFp.usersIdImpersonate(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get myself (the currently logged in user instance)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMyself(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersMyself(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets current user premium (stripe) subscription
         * @param {UsersApiUsersPremiumSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPremiumSubscription(requestParameters: UsersApiUsersPremiumSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<StripeSubscription> {
            return localVarFp.usersPremiumSubscription(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeEmail operation in UsersApi.
 * @export
 * @interface UsersApiChangeEmailRequest
 */
export interface UsersApiChangeEmailRequest {
    /**
     * The email change token.
     * @type {string}
     * @memberof UsersApiChangeEmail
     */
    readonly emailChangeToken: string
}

/**
 * Request parameters for changePassword operation in UsersApi.
 * @export
 * @interface UsersApiChangePasswordRequest
 */
export interface UsersApiChangePasswordRequest {
    /**
     * The user details
     * @type {UpdatePasswordParams}
     * @memberof UsersApiChangePassword
     */
    readonly body: UpdatePasswordParams
}

/**
 * Request parameters for confirmUser operation in UsersApi.
 * @export
 * @interface UsersApiConfirmUserRequest
 */
export interface UsersApiConfirmUserRequest {
    /**
     * The user confirmation token; which is sent to the user via email.
     * @type {string}
     * @memberof UsersApiConfirmUser
     */
    readonly confirmationToken: string
}

/**
 * Request parameters for deleteUserByUsername operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserByUsernameRequest
 */
export interface UsersApiDeleteUserByUsernameRequest {
    /**
     * Username or email of the user to delete. Deletion with ID is not possible for security reasons.
     * @type {string}
     * @memberof UsersApiDeleteUserByUsername
     */
    readonly username: string
}

/**
 * Request parameters for initiateEmailChange operation in UsersApi.
 * @export
 * @interface UsersApiInitiateEmailChangeRequest
 */
export interface UsersApiInitiateEmailChangeRequest {
    /**
     * The new email address.
     * @type {string}
     * @memberof UsersApiInitiateEmailChange
     */
    readonly email: string
}

/**
 * Request parameters for initiatePasswordReset operation in UsersApi.
 * @export
 * @interface UsersApiInitiatePasswordResetRequest
 */
export interface UsersApiInitiatePasswordResetRequest {
    /**
     * The users email address.
     * @type {string}
     * @memberof UsersApiInitiatePasswordReset
     */
    readonly email: string
}

/**
 * Request parameters for listAndSearchAllUsers operation in UsersApi.
 * @export
 * @interface UsersApiListAndSearchAllUsersRequest
 */
export interface UsersApiListAndSearchAllUsersRequest {
    /**
     * Search users matching the given search string (email, firstName, lastName)
     * @type {string}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly search?: string

    /**
     * Retrieve the users with the given ID(s). User IDs must match your mandator scope.
     * @type {Array<number>}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly id?: Array<number>

    /**
     * Perform a full wildcard search (slow); default is false
     * @type {boolean}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly fullWildcard?: boolean

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof UsersApiListAndSearchAllUsers
     */
    readonly order?: string
}

/**
 * Request parameters for resendRegistrationConfirmation operation in UsersApi.
 * @export
 * @interface UsersApiResendRegistrationConfirmationRequest
 */
export interface UsersApiResendRegistrationConfirmationRequest {
    /**
     * The users email address.
     * @type {string}
     * @memberof UsersApiResendRegistrationConfirmation
     */
    readonly email: string
}

/**
 * Request parameters for resetPassword operation in UsersApi.
 * @export
 * @interface UsersApiResetPasswordRequest
 */
export interface UsersApiResetPasswordRequest {
    /**
     * The user confirmation token; which is sent to the user via email.
     * @type {string}
     * @memberof UsersApiResetPassword
     */
    readonly confirmationToken: string

    /**
     * The new password.
     * @type {string}
     * @memberof UsersApiResetPassword
     */
    readonly password: string
}

/**
 * Request parameters for updateUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRequest
 */
export interface UsersApiUpdateUserRequest {
    /**
     * Username or email of the user to update. An update with ID is not possible for security reasons.
     * @type {string}
     * @memberof UsersApiUpdateUser
     */
    readonly username: string

    /**
     * The user details
     * @type {UpdateUserParams}
     * @memberof UsersApiUpdateUser
     */
    readonly body: UpdateUserParams
}

/**
 * Request parameters for usersId operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdRequest
 */
export interface UsersApiUsersIdRequest {
    /**
     * ID of the user to retrieve; use \&#39;0\&#39; to retrieve the currently logged in user.
     * @type {number}
     * @memberof UsersApiUsersId
     */
    readonly id: number
}

/**
 * Request parameters for usersIdImpersonate operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdImpersonateRequest
 */
export interface UsersApiUsersIdImpersonateRequest {
    /**
     * ID of the user to impersonate
     * @type {number}
     * @memberof UsersApiUsersIdImpersonate
     */
    readonly id: number
}

/**
 * Request parameters for usersPremiumSubscription operation in UsersApi.
 * @export
 * @interface UsersApiUsersPremiumSubscriptionRequest
 */
export interface UsersApiUsersPremiumSubscriptionRequest {
    /**
     * ID of user trying to access his subscription
     * @type {number}
     * @memberof UsersApiUsersPremiumSubscription
     */
    readonly id: number
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Finalize the change email workflow for the currently logged in user.
     * @param {UsersApiChangeEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeEmail(requestParameters: UsersApiChangeEmailRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeEmail(requestParameters.emailChangeToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the logged in users password by supplying the new as well as the current password.                      A new login (or API token) may be necessary after password change.
     * @param {UsersApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(requestParameters: UsersApiChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).changePassword(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm a user using the confirmation token sent via email.
     * @param {UsersApiConfirmUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public confirmUser(requestParameters: UsersApiConfirmUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).confirmUser(requestParameters.confirmationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanently unregister the currently logged in user instance. The user will be logged out upon                      successful unregistration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanently unregister the user identified by the given username / email. This endpoint is                        accessible at mandator level and allows authentication with with mandator credentials.
     * @param {UsersApiDeleteUserByUsernameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserByUsername(requestParameters: UsersApiDeleteUserByUsernameRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserByUsername(requestParameters.username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate the email change process by generating a confirmation token and sending it out to                      the new email address. Note that the email address remains unchanged until the email change flow                      is complete.
     * @param {UsersApiInitiateEmailChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public initiateEmailChange(requestParameters: UsersApiInitiateEmailChangeRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).initiateEmailChange(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate the password reset process by generating a confirmation token and sending it out to                      the user by email. Note that the operation will always return status \'OK\' to prevent an attacker                      to systematically screen for valid email addresses.
     * @param {UsersApiInitiatePasswordResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public initiatePasswordReset(requestParameters: UsersApiInitiatePasswordResetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).initiatePasswordReset(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a User (as Administrator) belonging to the administrators mandator
     * @param {UsersApiListAndSearchAllUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listAndSearchAllUsers(requestParameters: UsersApiListAndSearchAllUsersRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).listAndSearchAllUsers(requestParameters.search, requestParameters.id, requestParameters.fullWildcard, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend the registration confirmation email.
     * @param {UsersApiResendRegistrationConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resendRegistrationConfirmation(requestParameters: UsersApiResendRegistrationConfirmationRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resendRegistrationConfirmation(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset a users password and unlock the account (if required).
     * @param {UsersApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(requestParameters: UsersApiResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPassword(requestParameters.confirmationToken, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the user with the given username. This operation is powerful and thus limited to administrators.                      Note that changing a users mandator requires the executing user to be in role ROLE_MANDATOR_ADMIN.
     * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(requestParameters: UsersApiUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(requestParameters.username, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a User (as Administrator) belonging to the administrators mandator.
     * @param {UsersApiUsersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersId(requestParameters: UsersApiUsersIdRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Impersonate as a user
     * @param {UsersApiUsersIdImpersonateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdImpersonate(requestParameters: UsersApiUsersIdImpersonateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdImpersonate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get myself (the currently logged in user instance)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersMyself(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersMyself(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets current user premium (stripe) subscription
     * @param {UsersApiUsersPremiumSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPremiumSubscription(requestParameters: UsersApiUsersPremiumSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPremiumSubscription(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

