// const CODES: {
//   [key: string]: { code: string; name: string; symbol: string };
// } = {
//   ARS: { code: "ARS", name: "Argentinischer Peso", symbol: "ARS" },
//   EUR: { code: "EUR", name: "Euro", symbol: "€" },
//   AUD: { code: "AUD", name: "Australischer Dollar", symbol: "AUD" },
//   BRL: { code: "BRL", name: "Brasilianischer Real", symbol: "R$" },
//   CAD: { code: "CAD", name: "Kanadischer Dollar", symbol: "CAD" },
//   CLP: { code: "CLP", name: "Chilenischer Peso", symbol: "CLP" },
//   CNY: { code: "CNY", name: "Renminbi", symbol: "¥" },
//   DKK: { code: "DKK", name: "Dänische Krone", symbol: "DKK" },
//   HKD: { code: "HKD", name: "Hong Kong Dollar", symbol: "HKD" },
//   INR: { code: "INR", name: "Indische Rupie", symbol: "INR" },
//   IDR: { code: "IDR", name: "Indonesische Rupiah", symbol: "Rp" },
//   ILS: { code: "ILS", name: "Schekel", symbol: "₪" },
//   KRW: { code: "KRW", name: "Koreanischer Won", symbol: "₩" },
//   MYR: { code: "MYR", name: "Malaysischer Ringgit", symbol: "RM" },
//   MXN: { code: "MXN", name: "Mexikanischer Peso", symbol: "MXN" },
//   NZD: { code: "NZD", name: "Neuseeland-Dollar", symbol: "NZD" },
//   NOK: { code: "NOK", name: "Norwegische Krone", symbol: "NOK" },
//   PEN: { code: "PEN", name: "Nuevo Sol", symbol: "S/." },
//   SGD: { code: "SGD", name: "Singapur Dollar", symbol: "SGD" },
//   SEK: { code: "SEK", name: "Schwedische Krone", symbol: "SEK" },
//   TWD: { code: "TWD", name: "Taiwan Dollar", symbol: "NT$" },
//   THB: { code: "THB", name: "Thai Baht", symbol: "฿" },
//   GBP: { code: "GBP", name: "Britisches Pfund", symbol: "£" },
//   USD: { code: "USD", name: "US Dollar", symbol: "$" },
//   VEF: { code: "VEF", name: "Bolivar", symbol: "Bs" },
//   CHF: { code: "CHF", name: "Schweizer Franken", symbol: "CHF" },
//   TRY: { code: "TRY", name: "Türkische Lira ", symbol: "TRY" },
//   JPY: { code: "JPY", name: "Japanischer Yen ", symbol: "¥" },
//   RUB: { code: "RUB", name: "Russischer Rubel ", symbol: "₽" },
//   ZAR: { code: "ZAR", name: "Suedafrikanischer Rand", symbol: "R" },
//   DEM: { code: "DEM", name: "Deutsche Mark", symbol: "DEM" },
//   PCT: { code: "PCT", name: "Prozent", symbol: "%" },
// };

// export const getCurrencySymbol = (code?: string) =>
//   code && (CODES[code]?.symbol || code);

export const getCurrencySymbol = (isoCode?: string) => {
  if (!isoCode) return isoCode;
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: isoCode,
  });
  const currency = formatter
    .formatToParts(0)
    .find((part) => part.type === "currency")?.value;
  return currency ? currency : isoCode;
};
