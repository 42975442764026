import { InvokeCallback, Sender } from "xstate";
import { CreateMultipleBookingParams } from "@api";
import { validateBookingsWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";
import { buildBatchBookings } from "../../utils/utils";

export const validateDividends =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "TAX_RATE_LOADED")
      throw new Error(`Invalid event type ${event.type}`);

    const investmentParams: CreateMultipleBookingParams = {
      accountId: context.investment?.account.id ?? 0,
      performBackgroundTransactionSync: false,
      autoAssignQuotes: false,
      createDepotSynchronizationLog: false,
      createOrUpdateInvestmentParamsList: [
        {
          id: context.investment?.id,
          createOrUpdateBookingParamsList: buildBatchBookings(
            event.selectedDividendsWithTaxRate
          ),
        },
      ],
    };

    validate(investmentParams, callback);
  };

const validate = async (
  params: CreateMultipleBookingParams,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const { hasValidationErrors, transactionValidationErrors } =
      await validateBookingsWithToken(params);

    if (!hasValidationErrors) {
      callback({
        type: "DIVIDENDS_VALIDATED",
        validatedMultipleBookingParams: params,
      });

      return;
    }

    callback({ type: "BACK", validationErrors: transactionValidationErrors });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
