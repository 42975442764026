import { UseMutationOptions } from "react-query";
import {
  SubcategoriesApiUpdateSubcategoryRequest,
  Subcategory,
} from "@generated/apiv3";

import { buildSubcategoriesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateSubcategory = async (
  token: string | undefined,
  params: SubcategoriesApiUpdateSubcategoryRequest
) => {
  const apiInstance = buildSubcategoriesApi(token);
  const { data } = await apiInstance.updateSubcategory(params);
  return data;
};

export const useUpdateSubcategory = (
  options?: UseMutationOptions<
    Subcategory,
    AxiosApiError,
    SubcategoriesApiUpdateSubcategoryRequest
  >
) => {
  return useAuthMutation(updateSubcategory, options, false);
};
