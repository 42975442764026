import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographyWithInfoIcon } from "@components/TypographyWithInfoIcon";
import { LoadingButton } from "@components";

type Props = {
  onVisibilityChange: (visibility: "private" | "public") => void;
  onPercentageViewChange: (percentageView: boolean) => void;
  onNameChange: (name: string) => void;
  onContinue: () => void;
  onBack: () => void;
  visibility: "private" | "public";
  name: string;
  percentageView: boolean;
  isLoading?: boolean;
};

export const SecondStep = ({
  onVisibilityChange,
  onNameChange,
  onContinue,
  onBack,
  visibility,
  name,
  percentageView,
  onPercentageViewChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box>
      <RadioGroup
        row
        defaultValue={visibility}
        onChange={(e, value) => {
          onVisibilityChange(value as "private" | "public");
        }}
        sx={{
          marginBottom: (theme) => theme.spacing(4),
        }}
      >
        <FormControlLabel
          value="private"
          control={<Radio checked={visibility === "private"} />}
          label={
            <TypographyWithInfoIcon
              color="textSecondary"
              variant="body1"
              tooltipText={t("portfolioShare.modal.privateTooltip")}
            >
              {t("portfolioShare.modal.private")}
            </TypographyWithInfoIcon>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          value="public"
          control={<Radio checked={visibility === "public"} />}
          label={
            <TypographyWithInfoIcon
              color="textSecondary"
              variant="body1"
              tooltipText={t("portfolioShare.modal.publicTooltip")}
            >
              {t("portfolioShare.modal.public")}
            </TypographyWithInfoIcon>
          }
          labelPlacement="end"
        />
      </RadioGroup>
      {visibility === "public" && (
        <FormControlLabel
          value="percentageView"
          control={
            <Checkbox
              checked={percentageView}
              onChange={() => {
                onPercentageViewChange(!percentageView);
              }}
            />
          }
          label={t("portfolioShare.modal.onlyPercentage")}
          labelPlacement="end"
          sx={{
            marginBottom: (theme) => theme.spacing(6),
          }}
        />
      )}
      <TextField
        fullWidth
        placeholder={t("portfolioShare.modal.dashboardName_placeholder")}
        value={name}
        name="name"
        label={t("portfolioShare.modal.dashboardName")}
        onChange={(e) => onNameChange(e.target.value)}
        sx={{
          marginBottom: (theme) => theme.spacing(8),
        }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        flexDirection={isLessSm ? "column" : "row"}
      >
        <Button
          variant="contained"
          onClick={onBack}
          color="secondary"
          fullWidth={isLessSm}
        >
          {t("navigation.previous")}
        </Button>

        <LoadingButton
          isLoading={isLoading}
          variant="contained"
          fullWidth={isLessSm}
          onClick={onContinue}
          color="primary"
          disabled={!name || isLoading}
        >
          {visibility === "private"
            ? t("portfolioShare.modal.acceptButton")
            : t("portfolioShare.modal.publishButton")}
        </LoadingButton>
      </Box>
    </Box>
  );
};
