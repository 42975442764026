import React, { ReactNode } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Dropzone, { DropzoneProps } from "react-dropzone";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  height: 110px;
  padding-top: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: solid 1px ${({ theme }) => theme.palette.border.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
`;

type Props = {
  isParsing: boolean;
  onDrop: (files: File[]) => void;
  text: ReactNode;
} & DropzoneProps;

export const FileDropField = ({ isParsing, onDrop, text, ...rest }: Props) => {
  return (
    <>
      {isParsing && (
        <Container data-testid="transaction-parser-file-drop">
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </Container>
      )}
      {!isParsing && (
        <>
          <Dropzone disabled={isParsing} onDrop={onDrop} {...rest}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <Container
                  {...getRootProps()}
                  data-testid="transaction-parser-file-drop"
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    p={2}
                  >
                    {typeof text === "string" ? (
                      <Typography variant="body1">{text}</Typography>
                    ) : (
                      text
                    )}
                  </Box>
                </Container>
              </section>
            )}
          </Dropzone>
        </>
      )}
    </>
  );
};
