export const getAccountUrl = (
  id: number,
  type: "depot" | "account" = "account"
) =>
  `${process.env.REACT_APP_V2_URL}/${
    type === "account" ? "accounts" : "depots"
  }/${id}`;

export const goToAccount = (id: number) =>
  (window.location.href = getAccountUrl(id));
export const goToDepot = (id: number) =>
  (window.location.href = getAccountUrl(id, "depot"));

export const getDashboardUrl = () => `${process.env.REACT_APP_V2_URL}`;
export const goToDashboard = () => (window.location.href = getDashboardUrl());

export const getSessionLogoutUrl = () =>
  `${process.env.REACT_APP_ROOT_URL}/logoff`;
export const goToSessionLogout = () =>
  (window.location.href = getSessionLogoutUrl());

export const getInvestmentsUrl = () =>
  `${process.env.REACT_APP_V2_URL}/investments`;
export const goToInvestments = () =>
  (window.location.href = getInvestmentsUrl());

export const getEmailConfirmationUrl = () =>
  `${process.env.REACT_APP_V2_URL}/confirmation/email`;
export const goToEmailConfirmation = () =>
  (window.location.href = getEmailConfirmationUrl());
