import { Investment, SingleInvestmentTwrorRecord } from "@generated/apiv1";

export type PerformanceData = Record<
  string,
  { valuationGainAbs?: number; twror?: number }
>;

export const transformPerformanceData = (
  performance: SingleInvestmentTwrorRecord[] | undefined
) => {
  if (!performance) return {};

  return performance.reduce((acc: PerformanceData, performanceItem) => {
    const key = performanceItem.aggregation?.isAggregated
      ? performanceItem.aggregation.identifier || ""
      : performanceItem.investmentReference?.id || "";

    const performanceData = {
      valuationGainAbs: performanceItem.valuationGainAbs,
      twror: performanceItem.twror,
    };

    const aggregations = performanceItem.aggregation?.isAggregated
      ? performanceItem.aggregation.items?.reduce((aggAcc, aggItem) => {
          const aggregationKey = aggItem.investmentReference?.id || "";
          return {
            ...aggAcc,
            [aggregationKey]: {
              valuationGainAbs: aggItem.valuationGainAbs,
              twror: aggItem.twror,
            },
          };
        }, {} as PerformanceData) ?? {}
      : {};

    return {
      ...acc,
      ...aggregations,
      [key]: performanceData,
    };
  }, {} as PerformanceData);
};

export const addPerformanceDataToInvestments = (
  investments: Investment[],
  performanceData: PerformanceData,
  logos: Record<string, string | null | undefined> | undefined,
  totalValue: number
) => {
  if (!investments || !performanceData) return [];

  return investments.map((investment) => {
    const perfKey = investment.aggregation?.isAggregated
      ? investment.aggregation.identifier || ""
      : investment.id;
    const performance = performanceData[perfKey];

    const aggregation = investment.aggregation?.isAggregated
      ? {
          ...investment.aggregation,
          items: investment.aggregation.items?.map((item) => ({
            ...item,
            ...performanceData[item.id],
          })),
        }
      : investment.aggregation;

    return {
      ...investment,
      allocation: investment.currentMarketValueAC / totalValue ?? 0,
      valuationGainAbs: performance?.valuationGainAbs,
      twror: performance?.twror,
      image: logos?.[investment.isin] || logos?.[investment.tickerSymbol],
      aggregation,
    };
  });
};
