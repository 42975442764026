import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useKey } from "react-use";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RHFTextField } from "@components";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useChangeDisplayName } from "@generated/user/hooks";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  title: "settingsPage.profile.modal.name.title",
  confirmButton: "settingsPage.profile.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
};

interface Props {
  name: string;
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsChangeNameModal = ({ isOpen, onClose, name }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: changeName,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useChangeDisplayName({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.USERS_MYSELF],
      });
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, setValue, watch } = useForm<{
    name: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
    },
  });

  const nameValue = watch("name");
  const isConfirmBtnDisabled =
    !watch("name") || nameValue.length < 3 || nameValue.length > 31;

  const handleConfirm = () => {
    const values = getValues();
    changeName({
      newDisplayName: values.name,
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, 500);
  };

  useKey(
    "Enter",
    () => {
      if (!isSuccess && !isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isSuccess, isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  useEffect(() => {
    setValue("name", name);
  }, [isOpen, name, setValue]);

  return (
    <SettingsModalWrapper
      {...modalData}
      successTitle={t("settingsPage.profile.modal.success.title", {
        type: t("settingsPage.profile.labels.name"),
      })}
      showSuccess={isSuccess}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit(handleConfirm)}
      isConfirmBtnDisabled={isConfirmBtnDisabled}
      isConfirmButtonLoading={isLoading}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
        />
      )}
      <RHFTextField
        name="name"
        label={t("settingsPage.profile.labels.name")}
        control={control}
        fullWidth
        type="text"
      />
    </SettingsModalWrapper>
  );
};
