/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateTppWebFormParams } from '../model';
// @ts-ignore
import { CreateTppWebFormV2Params } from '../model';
// @ts-ignore
import { TppWebForm } from '../model';
// @ts-ignore
import { UpdateTppWebFormV2Params } from '../model';
/**
 * TppWebFormsApi - axios parameter creator
 * @export
 */
export const TppWebFormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {CreateOrUpdateTppWebFormParams} body A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppWebForm: async (body: CreateOrUpdateTppWebFormParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTppWebForm', 'body', body)
            const localVarPath = `/v1/tppWebForms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {string} id ID of the web form to retrieve (this is a random ID token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppWebFormsId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tppWebFormsId', 'id', id)
            const localVarPath = `/v1/tppWebForms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {CreateTppWebFormV2Params} body A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CreateTppWebForm: async (body: CreateTppWebFormV2Params, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v2CreateTppWebForm', 'body', body)
            const localVarPath = `/v2/tppWebForms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {string} id ID of the web form to retrieve (this is a random ID token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TppWebFormsId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2TppWebFormsId', 'id', id)
            const localVarPath = `/v2/tppWebForms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a TPP web-form with the import process status. This endpoint is intended for use                 by the wealthAPI import flow and will update the web-form status according to the import progress
         * @param {string} id ID of the web form to update (this is a random ID token)
         * @param {UpdateTppWebFormV2Params} body An update tpp web form params object. Currently, only updating of the bankConnectionID                         is supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateTppWebForm: async (id: string, body: UpdateTppWebFormV2Params, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2UpdateTppWebForm', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v2UpdateTppWebForm', 'body', body)
            const localVarPath = `/v2/tppWebForms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TppWebFormsApi - functional programming interface
 * @export
 */
export const TppWebFormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TppWebFormsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {CreateOrUpdateTppWebFormParams} body A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTppWebForm(body: CreateOrUpdateTppWebFormParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppWebForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTppWebForm(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppWebFormsApi.createTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {string} id ID of the web form to retrieve (this is a random ID token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tppWebFormsId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppWebForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tppWebFormsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppWebFormsApi.tppWebFormsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {CreateTppWebFormV2Params} body A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CreateTppWebForm(body: CreateTppWebFormV2Params, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppWebForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2CreateTppWebForm(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppWebFormsApi.v2CreateTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {string} id ID of the web form to retrieve (this is a random ID token)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TppWebFormsId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppWebForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TppWebFormsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppWebFormsApi.v2TppWebFormsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a TPP web-form with the import process status. This endpoint is intended for use                 by the wealthAPI import flow and will update the web-form status according to the import progress
         * @param {string} id ID of the web form to update (this is a random ID token)
         * @param {UpdateTppWebFormV2Params} body An update tpp web form params object. Currently, only updating of the bankConnectionID                         is supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UpdateTppWebForm(id: string, body: UpdateTppWebFormV2Params, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UpdateTppWebForm(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppWebFormsApi.v2UpdateTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TppWebFormsApi - factory interface
 * @export
 */
export const TppWebFormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TppWebFormsApiFp(configuration)
    return {
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {TppWebFormsApiCreateTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppWebForm(requestParameters: TppWebFormsApiCreateTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppWebForm> {
            return localVarFp.createTppWebForm(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {TppWebFormsApiTppWebFormsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppWebFormsId(requestParameters: TppWebFormsApiTppWebFormsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppWebForm> {
            return localVarFp.tppWebFormsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
         * @param {TppWebFormsApiV2CreateTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CreateTppWebForm(requestParameters: TppWebFormsApiV2CreateTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppWebForm> {
            return localVarFp.v2CreateTppWebForm(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given web-form
         * @param {TppWebFormsApiV2TppWebFormsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TppWebFormsId(requestParameters: TppWebFormsApiV2TppWebFormsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppWebForm> {
            return localVarFp.v2TppWebFormsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a TPP web-form with the import process status. This endpoint is intended for use                 by the wealthAPI import flow and will update the web-form status according to the import progress
         * @param {TppWebFormsApiV2UpdateTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateTppWebForm(requestParameters: TppWebFormsApiV2UpdateTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2UpdateTppWebForm(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTppWebForm operation in TppWebFormsApi.
 * @export
 * @interface TppWebFormsApiCreateTppWebFormRequest
 */
export interface TppWebFormsApiCreateTppWebFormRequest {
    /**
     * A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
     * @type {CreateOrUpdateTppWebFormParams}
     * @memberof TppWebFormsApiCreateTppWebForm
     */
    readonly body: CreateOrUpdateTppWebFormParams
}

/**
 * Request parameters for tppWebFormsId operation in TppWebFormsApi.
 * @export
 * @interface TppWebFormsApiTppWebFormsIdRequest
 */
export interface TppWebFormsApiTppWebFormsIdRequest {
    /**
     * ID of the web form to retrieve (this is a random ID token)
     * @type {string}
     * @memberof TppWebFormsApiTppWebFormsId
     */
    readonly id: string
}

/**
 * Request parameters for v2CreateTppWebForm operation in TppWebFormsApi.
 * @export
 * @interface TppWebFormsApiV2CreateTppWebFormRequest
 */
export interface TppWebFormsApiV2CreateTppWebFormRequest {
    /**
     * A create tpp web form params object. For creating web forms, the bankId must be set                         and the bankConnectionId property should be empty.
     * @type {CreateTppWebFormV2Params}
     * @memberof TppWebFormsApiV2CreateTppWebForm
     */
    readonly body: CreateTppWebFormV2Params
}

/**
 * Request parameters for v2TppWebFormsId operation in TppWebFormsApi.
 * @export
 * @interface TppWebFormsApiV2TppWebFormsIdRequest
 */
export interface TppWebFormsApiV2TppWebFormsIdRequest {
    /**
     * ID of the web form to retrieve (this is a random ID token)
     * @type {string}
     * @memberof TppWebFormsApiV2TppWebFormsId
     */
    readonly id: string
}

/**
 * Request parameters for v2UpdateTppWebForm operation in TppWebFormsApi.
 * @export
 * @interface TppWebFormsApiV2UpdateTppWebFormRequest
 */
export interface TppWebFormsApiV2UpdateTppWebFormRequest {
    /**
     * ID of the web form to update (this is a random ID token)
     * @type {string}
     * @memberof TppWebFormsApiV2UpdateTppWebForm
     */
    readonly id: string

    /**
     * An update tpp web form params object. Currently, only updating of the bankConnectionID                         is supported.
     * @type {UpdateTppWebFormV2Params}
     * @memberof TppWebFormsApiV2UpdateTppWebForm
     */
    readonly body: UpdateTppWebFormV2Params
}

/**
 * TppWebFormsApi - object-oriented interface
 * @export
 * @class TppWebFormsApi
 * @extends {BaseAPI}
 */
export class TppWebFormsApi extends BaseAPI {
    /**
     * 
     * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
     * @param {TppWebFormsApiCreateTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppWebFormsApi
     */
    public createTppWebForm(requestParameters: TppWebFormsApiCreateTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return TppWebFormsApiFp(this.configuration).createTppWebForm(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given web-form
     * @param {TppWebFormsApiTppWebFormsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppWebFormsApi
     */
    public tppWebFormsId(requestParameters: TppWebFormsApiTppWebFormsIdRequest, options?: RawAxiosRequestConfig) {
        return TppWebFormsApiFp(this.configuration).tppWebFormsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate the TPP web-form flow by creating a new web-form. The web-form will contain the redirect                 URL (starting with https://rentablo.de) required to execute the web-form flow. After web-form creation, you must                 redirect the user to the given redirect URL
     * @param {TppWebFormsApiV2CreateTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppWebFormsApi
     */
    public v2CreateTppWebForm(requestParameters: TppWebFormsApiV2CreateTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return TppWebFormsApiFp(this.configuration).v2CreateTppWebForm(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given web-form
     * @param {TppWebFormsApiV2TppWebFormsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppWebFormsApi
     */
    public v2TppWebFormsId(requestParameters: TppWebFormsApiV2TppWebFormsIdRequest, options?: RawAxiosRequestConfig) {
        return TppWebFormsApiFp(this.configuration).v2TppWebFormsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a TPP web-form with the import process status. This endpoint is intended for use                 by the wealthAPI import flow and will update the web-form status according to the import progress
     * @param {TppWebFormsApiV2UpdateTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppWebFormsApi
     */
    public v2UpdateTppWebForm(requestParameters: TppWebFormsApiV2UpdateTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return TppWebFormsApiFp(this.configuration).v2UpdateTppWebForm(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

