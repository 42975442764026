import { MutateOptions } from "react-query";
import { CreateOrUpdateWatchlistParams, Watchlist } from "@api/v1/model";
import { createWatchlist } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

export const useCreateWatchlist = (
  options?: MutateOptions<
    Watchlist,
    AxiosApiError,
    CreateOrUpdateWatchlistParams
  >
) => useAuthMutation(createWatchlist, options);
