import { ExtendedBookingType } from "@features/transactionWizard/modal/steps/constants";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setBookingTypeAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKING_TYPE_SELECTED")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    bookingValues: {
      ...context.bookingValues,
      type: event.bookingType,
      numberOfLots: shouldTakeoverNol(event.bookingType)
        ? context.investmentValues.numberOfLots
        : event.bookingType === "dividend"
        ? 1
        : undefined,
    },
    investmentValues: {
      ...context.investmentValues,
      id: event.investmentId || context.investmentValues.id,
    },
  };
};

const shouldTakeoverNol = (bookingType: ExtendedBookingType) =>
  ["sell", "book_out", "switch"].includes(bookingType);
