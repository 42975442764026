/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DepotSynchronizationLogItem } from './depot-synchronization-log-item';

/**
 * 
 * @export
 * @interface DepotSynchronizationLog
 */
export interface DepotSynchronizationLog {
    /**
     * Technical identifier
     * @type {number}
     * @memberof DepotSynchronizationLog
     */
    'id'?: number;
    /**
     * A batch ID in case several depots were updated in one batch
     * @type {string}
     * @memberof DepotSynchronizationLog
     */
    'batchId'?: string;
    /**
     * The type of update
     * @type {string}
     * @memberof DepotSynchronizationLog
     */
    'type'?: DepotSynchronizationLogTypeEnum;
    /**
     * ID of the depots that was updated
     * @type {number}
     * @memberof DepotSynchronizationLog
     */
    'accountId'?: number;
    /**
     * Timestamp of depot update
     * @type {string}
     * @memberof DepotSynchronizationLog
     */
    'executedAt'?: string;
    /**
     * Timestamp of rollback
     * @type {string}
     * @memberof DepotSynchronizationLog
     */
    'rolledBackAt'?: string;
    /**
     * The associated log items
     * @type {Array<DepotSynchronizationLogItem>}
     * @memberof DepotSynchronizationLog
     */
    'logItems'?: Array<DepotSynchronizationLogItem>;
}

export const DepotSynchronizationLogTypeEnum = {
    MANUAL: 'MANUAL',
    BATCH_UPDATE: 'BATCH_UPDATE'
} as const;

export type DepotSynchronizationLogTypeEnum = typeof DepotSynchronizationLogTypeEnum[keyof typeof DepotSynchronizationLogTypeEnum];


