import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { PrimaryActionWithMenu } from "@components/common/navigation/PrimaryActionWithMenu";
import { HeaderMenu } from "./HeaderMenu";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface WatchlistsSinglePageHeaderProps {
  onPrimaryActionClick: () => void;
  onWatchlistEdit: () => void;
  onWatchlistDelete: () => void;
  totalObservedItems: number;
  isWatchlistLoading: boolean;
}

export const WatchlistsSinglePageHeader = (
  props: WatchlistsSinglePageHeaderProps
) => {
  const {
    totalObservedItems = 0,
    onWatchlistDelete,
    onWatchlistEdit,
    onPrimaryActionClick,
  } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typography color="textSecondary" variant="body2" fontWeight={400}>
        {t("watchlistSinglePage.header.totalItems", {
          count: totalObservedItems,
        })}
      </Typography>

      <PrimaryActionWithMenu
        data-testid="security-add-button"
        primaryActionText={t("watchlistSinglePage.header.actions.addNewItem")}
        onClick={onPrimaryActionClick}
        size="small"
      >
        <HeaderMenu
          onWatchlistEdit={onWatchlistEdit}
          onWatchlistDelete={onWatchlistDelete}
        />
      </PrimaryActionWithMenu>
    </Wrapper>
  );
};
