import { addSeconds } from "date-fns";
import { endSessionImpersonation } from "@api/v1/methods";
import { LoginToken } from "@api/v1/model";
import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from "@api/auth";

const IMPERSONATOR = "IMPERSONATOR";

export const impersonate = (impersonatedUserToken: LoginToken) => {
  const token = {
    ...impersonatedUserToken,
    rememberMe: true,
    expires_at: addSeconds(new Date(), impersonatedUserToken.expires_in),
  };
  backupExistingTokenToLocalStorage();
  saveToLocalStorage(token);
  return token;
};

export const endImpersonation = () => {
  const backedUpToken = localStorage.getItem(
    `${LOGIN_TOKEN_LOCAL_STORAGE_KEY}_${IMPERSONATOR}`
  );
  localStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY, backedUpToken || "");
  endSessionImpersonation();
};

const saveToLocalStorage = (token: any) => {
  localStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
};

export const backupExistingTokenToLocalStorage = () => {
  const existing = localStorage.getItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY) || "";
  localStorage.setItem(
    `${LOGIN_TOKEN_LOCAL_STORAGE_KEY}_${IMPERSONATOR}`,
    existing
  );
};
