import { UseQueryOptions } from "react-query";
import {
  DividendsApiPublicDividendHistoryRequest,
  DividendHistoryTree,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicDividendHistory = async (
  token: string | undefined,
  params: DividendsApiPublicDividendHistoryRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.publicDividendHistory(params);
  return data;
};

export const usePublicDividendHistory = (
  params: DividendsApiPublicDividendHistoryRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_PUBLIC_DIVIDEND_HISTORY, params],
    ({ token }) => publicDividendHistory(token, params),
    options
  );
};
