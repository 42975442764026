import React from "react";
import {
  Box,
  darken,
  styled,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "@icons/myicons/close.svg";

const getBackground = (theme: Theme): Record<string, string> => ({
  "default-primary": theme.palette.background.selected,
  "default-secondary": theme.palette.background.neutral,
  "outlined-primary": theme.palette.common.white,
  "outlined-secondary": theme.palette.common.white,
});

const TagWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "variant" &&
    prop !== "color" &&
    prop !== "backgroundColor" &&
    prop !== "borderColor",
})<{
  variant: string;
  color: string;
  borderColor?: string;
  backgroundColor?: string;
}>(({ theme, variant, color, borderColor, backgroundColor }) => {
  let finalColor = color;
  let finalBorder = "none";
  let finalBackground = backgroundColor;

  if (color === "primary" || color === "secondary") {
    finalColor =
      variant === "default"
        ? theme.palette.text.primary
        : color === "primary"
        ? theme.palette.primary.dark
        : theme.palette.text.secondary;
  }
  if (variant === "outlined") {
    finalBorder = borderColor
      ? `1px solid ${borderColor}`
      : color === "primary"
      ? `1px solid ${theme.palette.primary.light}`
      : `1px solid ${theme.palette.border.primary}`;
  }
  if (color === "primary" || color === "secondary") {
    finalBackground = getBackground(theme)[`${variant}-${color}`];
  }

  return {
    display: "flex",
    alignItems: "center",
    borderRadius: "16px",
    backgroundColor: finalBackground,
    border: finalBorder,
    gap: theme.spacing(1),
    padding: theme.spacing(1, 3),
    color: `${finalColor} !important`,
  };
});

const TagIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color?: string }>(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "16px",
  width: "16px",
  color: color || theme.palette.icon.secondary,
  cursor: "pointer",

  "&:hover svg": {
    color: color ? darken(color, 0.2) : theme.palette.icon.primary,
  },
}));

export type TagProps = {
  id?: string;
  text: string;
  variant: "default" | "outlined";
  color?: "primary" | "secondary" | string;
  backgroundColor?: string;
  borderColor?: string;
  onTagRemove?: (id: string) => void;
} & Omit<TypographyProps, "variant" | "color">;

export const Tag = ({
  id,
  text,
  variant = "default",
  color = "primary",
  onTagRemove,
  backgroundColor,
  borderColor,
  ...props
}: TagProps) => {
  return (
    <TagWrapper
      key={id || text}
      variant={variant}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <Typography variant="body2" {...props}>
        {text}
      </Typography>
      {onTagRemove && (
        <TagIconWrapper
          onClick={() => onTagRemove(id || text)}
          color={
            color !== "primary" && color !== "secondary" ? color : undefined
          }
        >
          <CloseIcon width="10px" height="10px" />
        </TagIconWrapper>
      )}
    </TagWrapper>
  );
};
