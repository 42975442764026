import { useMutation, UseMutationOptions } from "react-query";
import { AxiosError } from "axios";
import { buildHistoricQuotesApiWithToken } from "@generated/apiv1/apiBuilder";
import { AddOrUpdateHistoricQuoteParams, ApiException } from "@generated/apiv1";

export const useAddOrUpdateHistoricQuotes = (
  options: UseMutationOptions<
    unknown,
    AxiosError<ApiException>,
    AddOrUpdateHistoricQuoteParams
  > = {}
) => {
  return useMutation(async (params: AddOrUpdateHistoricQuoteParams) => {
    const historicApi = await buildHistoricQuotesApiWithToken();
    return historicApi.addOrUpdateHistoricQuotes({ body: params });
  }, options);
};
