import { format } from "date-fns";
import {
  useBatchInvestmentPerformance,
  useBatchPublicInvestmentPerformance,
} from "@generated/apiv1/hooks";
import { usePublicOrPrivateInvestments } from "@features/sharedPortfolio/hooks/usePublicOrPrivateInvestments";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import { useSecurityImages } from "@hooks/useSecurityImages";

export const useInvestments = ({
  accountIds,
  startDate,
  endDate,
  showAggregated = false,
}: {
  accountIds: number[];
  startDate: Date;
  endDate: Date;
  showAggregated: boolean;
}) => {
  const { investments, cash, isLoading, isFetched } =
    usePublicOrPrivateInvestments(
      {
        accountId: accountIds,
        aggregate: showAggregated,
      },
      {
        enabled: Boolean(accountIds?.length > 0),
      }
    );

  const { data: investmentsPerformance } = usePublicOrPrivateData({
    // @ts-ignore TODO: fix this when API is fixed
    usePublicMethod: useBatchPublicInvestmentPerformance,
    usePrivateMethod: useBatchInvestmentPerformance,
    params: {
      depotId: accountIds,
      from: format(startDate!, "yyyy-MM-dd"),
      to: format(endDate!, "yyyy-MM-dd"),
      inAccountCurrency: false,
      includeExpenses: true,
      includeDividends: false,
      aggregate: showAggregated,
    },
    options: {
      enabled: Boolean(accountIds?.length > 0),
    },
  });

  const logos = useSecurityImages(investments);

  return {
    investments,
    investmentsPerformance,
    isLoading,
    isFetched,
    investmentLogos: logos,
    cash,
  };
};
