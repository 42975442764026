import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { PageLayout } from "@components";
import { SelectFileAndFormat } from "@features/csvDrop/SelectFileAndFormat";
import { CsvParseResult, ParseCsvBankSlugEnum } from "@generated/apiv3";
import { ParsingResults } from "@features/csvDrop/ParsingResults";
import { useAccountsId } from "@generated/apiv1/hooks";

export const CsvUploadPage = () => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<number>(0);
  const [parsingResult, setParsingResult] =
    React.useState<CsvParseResult | null>(null);
  const [formData, setFormData] = React.useState<{
    depotId?: number;
    bankSlug?: ParseCsvBankSlugEnum;
  }>({});

  const { depotId } = useParams();
  const { data: depot, isLoading: idDepotLoading } = useAccountsId(
    {
      id: Number(depotId),
    },
    {
      enabled: Boolean(depotId),
    }
  );

  const breadcrumbs: BreadcrumbItem[] = depotId
    ? [
        {
          text: t("breadcrumbs.home"),
          to: "/",
        },
        {
          text: t("breadcrumbs.depots"),
          to: "/depots-accounts",
        },
        {
          to: `/depots/${depotId}`,
          text: depot?.name || t("breadcrumbs.depot"),
          isLoading: idDepotLoading,
        },
        {
          text: t("csvImport.title"),
        },
      ]
    : [
        {
          text: t("breadcrumbs.home"),
          to: "/",
        },
        {
          text: t("breadcrumbs.investments"),
          to: "/investments",
        },
        {
          text: t("csvImport.title"),
        },
      ];

  return (
    <PageLayout title={t("csvImport.title")} breadcrumbs={breadcrumbs}>
      <Box display={step === 0 ? "block" : "none"}>
        <SelectFileAndFormat
          onCsvUpload={setParsingResult}
          changeStep={(
            depotId: number,
            selectedFormat: ParseCsvBankSlugEnum
          ) => {
            setStep(1);
            setFormData({ depotId, bankSlug: selectedFormat });
          }}
        />
      </Box>
      {parsingResult && (
        <Box display={step === 1 ? "block" : "none"}>
          <ParsingResults
            parsingResult={parsingResult as CsvParseResult}
            changeStep={setStep}
            depotId={formData.depotId}
            bankSlug={formData.bankSlug}
          />
        </Box>
      )}
    </PageLayout>
  );
};
