/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Category } from '../model';
// @ts-ignore
import { CategoryList } from '../model';
// @ts-ignore
import { CreateOrUpdateCategoryParams } from '../model';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a Transaction Category
         * @param {number} id ID of the category to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesId', 'id', id)
            const localVarPath = `/v1/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a transaction category
         * @param {CreateOrUpdateCategoryParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionCategory: async (body: CreateOrUpdateCategoryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTransactionCategory', 'body', body)
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Transaction Category
         * @param {number} id ID of the category to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/v1/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get and search all Transaction Categories
         * @param {Array<number>} [id] ID of the categories to retrieve
         * @param {string} [search] If specified, then only those categories will be contained in the result whose \&#39;name\&#39;                              contains the given search string (the matching works case-insensitive). If no categories                              contain the search string in their name, then the result will be an empty list. NOTE: If                              the given search string consists of several terms (separated by whitespace), then ALL of                              these terms must be contained in the name in order for a category to get included into the result.
         * @param {boolean} [isCustom] If specified, then the result will contain only categories that are either finAPI global                              (in case of value \&#39;false\&#39;), or only categories that have been created by the authorized user                              (in case of value \&#39;true\&#39;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllCategories: async (id?: Array<number>, search?: string, isCustom?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isCustom !== undefined) {
                localVarQueryParameter['isCustom'] = isCustom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a Transaction Category
         * @param {number} id ID of the category to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a transaction category
         * @param {CreateOrUpdateCategoryParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionCategory(body: CreateOrUpdateCategoryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionCategory(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.createTransactionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Transaction Category
         * @param {number} id ID of the category to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.deleteCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get and search all Transaction Categories
         * @param {Array<number>} [id] ID of the categories to retrieve
         * @param {string} [search] If specified, then only those categories will be contained in the result whose \&#39;name\&#39;                              contains the given search string (the matching works case-insensitive). If no categories                              contain the search string in their name, then the result will be an empty list. NOTE: If                              the given search string consists of several terms (separated by whitespace), then ALL of                              these terms must be contained in the name in order for a category to get included into the result.
         * @param {boolean} [isCustom] If specified, then the result will contain only categories that are either finAPI global                              (in case of value \&#39;false\&#39;), or only categories that have been created by the authorized user                              (in case of value \&#39;true\&#39;).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllCategories(id?: Array<number>, search?: string, isCustom?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllCategories(id, search, isCustom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.listAndSearchAllCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a Transaction Category
         * @param {CategoriesApiCategoriesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesId(requestParameters: CategoriesApiCategoriesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.categoriesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a transaction category
         * @param {CategoriesApiCreateTransactionCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionCategory(requestParameters: CategoriesApiCreateTransactionCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.createTransactionCategory(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Transaction Category
         * @param {CategoriesApiDeleteCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(requestParameters: CategoriesApiDeleteCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.deleteCategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get and search all Transaction Categories
         * @param {CategoriesApiListAndSearchAllCategoriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllCategories(requestParameters: CategoriesApiListAndSearchAllCategoriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CategoryList> {
            return localVarFp.listAndSearchAllCategories(requestParameters.id, requestParameters.search, requestParameters.isCustom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoriesId operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesIdRequest
 */
export interface CategoriesApiCategoriesIdRequest {
    /**
     * ID of the category to retrieve
     * @type {number}
     * @memberof CategoriesApiCategoriesId
     */
    readonly id: number
}

/**
 * Request parameters for createTransactionCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCreateTransactionCategoryRequest
 */
export interface CategoriesApiCreateTransactionCategoryRequest {
    /**
     * The transaction parameters
     * @type {CreateOrUpdateCategoryParams}
     * @memberof CategoriesApiCreateTransactionCategory
     */
    readonly body: CreateOrUpdateCategoryParams
}

/**
 * Request parameters for deleteCategory operation in CategoriesApi.
 * @export
 * @interface CategoriesApiDeleteCategoryRequest
 */
export interface CategoriesApiDeleteCategoryRequest {
    /**
     * ID of the category to delete
     * @type {number}
     * @memberof CategoriesApiDeleteCategory
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllCategories operation in CategoriesApi.
 * @export
 * @interface CategoriesApiListAndSearchAllCategoriesRequest
 */
export interface CategoriesApiListAndSearchAllCategoriesRequest {
    /**
     * ID of the categories to retrieve
     * @type {Array<number>}
     * @memberof CategoriesApiListAndSearchAllCategories
     */
    readonly id?: Array<number>

    /**
     * If specified, then only those categories will be contained in the result whose \&#39;name\&#39;                              contains the given search string (the matching works case-insensitive). If no categories                              contain the search string in their name, then the result will be an empty list. NOTE: If                              the given search string consists of several terms (separated by whitespace), then ALL of                              these terms must be contained in the name in order for a category to get included into the result.
     * @type {string}
     * @memberof CategoriesApiListAndSearchAllCategories
     */
    readonly search?: string

    /**
     * If specified, then the result will contain only categories that are either finAPI global                              (in case of value \&#39;false\&#39;), or only categories that have been created by the authorized user                              (in case of value \&#39;true\&#39;).
     * @type {boolean}
     * @memberof CategoriesApiListAndSearchAllCategories
     */
    readonly isCustom?: boolean
}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Get a Transaction Category
     * @param {CategoriesApiCategoriesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesId(requestParameters: CategoriesApiCategoriesIdRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a transaction category
     * @param {CategoriesApiCreateTransactionCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public createTransactionCategory(requestParameters: CategoriesApiCreateTransactionCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).createTransactionCategory(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Transaction Category
     * @param {CategoriesApiDeleteCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public deleteCategory(requestParameters: CategoriesApiDeleteCategoryRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).deleteCategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get and search all Transaction Categories
     * @param {CategoriesApiListAndSearchAllCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public listAndSearchAllCategories(requestParameters: CategoriesApiListAndSearchAllCategoriesRequest = {}, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).listAndSearchAllCategories(requestParameters.id, requestParameters.search, requestParameters.isCustom, options).then((request) => request(this.axios, this.basePath));
    }
}

