/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Account } from '../model';
// @ts-ignore
import { AccountList } from '../model';
// @ts-ignore
import { AccountSelectGroup } from '../model';
// @ts-ignore
import { AggregatedFundCommissionStatistics } from '../model';
// @ts-ignore
import { AssetCategorization } from '../model';
// @ts-ignore
import { CreateOrUpdateAccountParams } from '../model';
// @ts-ignore
import { CreateOrUpdateAccountSelectGroupParams } from '../model';
// @ts-ignore
import { DateValuePairList } from '../model';
// @ts-ignore
import { DepotTransferParams } from '../model';
// @ts-ignore
import { FinApiAccountList } from '../model';
// @ts-ignore
import { ImportRemoteAccountParams } from '../model';
// @ts-ignore
import { LinkExistingAccountToBankParams } from '../model';
// @ts-ignore
import { RemoteAccountStatus } from '../model';
// @ts-ignore
import { SyncStatusList } from '../model';
// @ts-ignore
import { UpdateRemoteAccountStatusParams } from '../model';
/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Transfer all investments from this account to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 All investments will be booked-out and booked-in into the given account.
         * @param {number} id ID of the account from which assets will be transferred to (must be of type DEPOT                         or MATERIAL_ASSETS)
         * @param {DepotTransferParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountTransfer: async (id: number, body: DepotTransferParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountTransfer', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('accountTransfer', 'body', body)
            const localVarPath = `/v1/accounts/{id}/transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Account
         * @param {number} id ID of the account to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsId', 'id', id)
            const localVarPath = `/v1/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the accumulated balances for the given account(s). Balances are calculated based on the                      available transaction history. If necessary, adjusting entries will be created to account for                      deviations  between the banks transaction history and the actually available transaction history.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {string} [from] Get balances from the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
         * @param {string} [to] Get balances up to the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
         * @param {boolean} [padDates] Will pad dates so that there is a balance for every day if true; default is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balances: async (id: Array<number>, from?: string, to?: string, padDates?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('balances', 'id', id)
            const localVarPath = `/v1/accounts/balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (padDates !== undefined) {
                localVarQueryParameter['padDates'] = padDates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the accumulated cash flows for the given account(s). Cash flows are calculated based on the                      available transaction history. If necessary, adjusting positions will be added implicitly to account for                      deviations between the banks transaction history and the actually available transaction history.
         * @param {Array<number>} id ID of the account to retrieve cash flows for.
         * @param {Array<number>} [interval] The interval for cash flows, available are day (default), week, month and year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashFlows: async (id: Array<number>, interval?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cashFlows', 'id', id)
            const localVarPath = `/v1/accounts/cashFlows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (interval) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Categorize the given accounts.
         * @param {Array<number>} [id] IDs of the accounts to categorize. Will perform categorization on all of the users                              cash accounts, depots and material asset depots if not given.
         * @param {boolean} [includeHidden] Include hidden accounts as well; default is false. Ignored if individual IDs are provided
         * @param {boolean} [inPercent] Convert all values to percent; default is false. This allows to provide data anonymized.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categorize: async (id?: Array<number>, includeHidden?: boolean, inPercent?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts/categorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (includeHidden !== undefined) {
                localVarQueryParameter['includeHidden'] = includeHidden;
            }

            if (inPercent !== undefined) {
                localVarQueryParameter['inPercent'] = inPercent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the contra account for the given depot.
         * @param {number} id ID of the account (must be of type DEPOT) to change the contra account for
         * @param {number} contraAccountId ID of the new contra account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeContraAccount: async (id: number, contraAccountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeContraAccount', 'id', id)
            // verify required parameter 'contraAccountId' is not null or undefined
            assertParamExists('changeContraAccount', 'contraAccountId', contraAccountId)
            const localVarPath = `/v1/accounts/{id}/assignContraAccount/{contraAccountId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contraAccountId"}}`, encodeURIComponent(String(contraAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an account. In case this is a depot, the contra account will be created automatically.
         * @param {CreateOrUpdateAccountParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (body: CreateOrUpdateAccountParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAccount', 'body', body)
            const localVarPath = `/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update or create an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts. Note that select groups do not                      have an ID as they are solely identified by their name.
         * @param {CreateOrUpdateAccountSelectGroupParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSelectGroup: async (body: CreateOrUpdateAccountSelectGroupParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createOrUpdateSelectGroup', 'body', body)
            const localVarPath = `/v1/accounts/selectGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the given account.
         * @param {number} id ID of the account to delete
         * @param {boolean} [deleteContraAccount] Delete the contra account together with the depot if true. This is however only possible                              if the contra account is solely used for the depot being deleted. Default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (id: number, deleteContraAccount?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAccount', 'id', id)
            const localVarPath = `/v1/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteContraAccount !== undefined) {
                localVarQueryParameter['deleteContraAccount'] = deleteContraAccount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an account select group
         * @param {string} id ID of the select group to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSelectGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSelectGroup', 'id', id)
            const localVarPath = `/v1/accounts/selectGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the estimated fund commissions for the selected accounts. Fund commissions include                      portfolio commissions and agios that would have been payed in the last 12 months.
         * @param {Array<number>} [id] ID of the accounts to retrieve fund commission estimates for. If not specified, all DEPOTS                              of the currently logged in user will be used for estimation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundCommissions: async (id?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts/fundCommissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get historic valuations (depot valuation plus optionally the cash position) for the given accounts.                      Valuations will be returned using the given interval definition; always at the first of the                      week / month / year.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {string} intervalType Use the given interval type, one of day, week, month, year
         * @param {string} startDate Start data at the given date; format is yyyy-MM-dd (US Format); default is 5 years ago.
         * @param {boolean} includeCash Return portfolio valuations including cash if true; default is false.
         * @param {boolean} [includeCurrentValue] Include the current (today\&#39;s) value; no matter if it falls into the interval or not.                              Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicValuations: async (id: Array<number>, intervalType: string, startDate: string, includeCash: boolean, includeCurrentValue?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('historicValuations', 'id', id)
            // verify required parameter 'intervalType' is not null or undefined
            assertParamExists('historicValuations', 'intervalType', intervalType)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('historicValuations', 'startDate', startDate)
            // verify required parameter 'includeCash' is not null or undefined
            assertParamExists('historicValuations', 'includeCash', includeCash)
            const localVarPath = `/v1/accounts/historicValuations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (includeCash !== undefined) {
                localVarQueryParameter['includeCash'] = includeCash;
            }

            if (includeCurrentValue !== undefined) {
                localVarQueryParameter['includeCurrentValue'] = includeCurrentValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Categorize the given account.
         * @param {number} id ID of the account to categorize.
         * @param {boolean} [inPercent] Convert all values to percent; this allows to provide data anonymized.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idCategorize: async (id: number, inPercent?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idCategorize', 'id', id)
            const localVarPath = `/v1/accounts/{id}/categorize`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inPercent !== undefined) {
                localVarQueryParameter['inPercent'] = inPercent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import the given remote account into local storage. The account must already exist on finAPI side.
         * @param {ImportRemoteAccountParams} body Defines the remote account to import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteAccount: async (body: ImportRemoteAccountParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('importRemoteAccount', 'body', body)
            const localVarPath = `/v1/accounts/importRemoteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link the given (manually maintained) account to the given remote accounts.
         * @param {number} id ID of the account (must be of type DEPOT) to update
         * @param {LinkExistingAccountToBankParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkToRemoteAccount: async (id: number, body: LinkExistingAccountToBankParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('linkToRemoteAccount', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('linkToRemoteAccount', 'body', body)
            const localVarPath = `/v1/accounts/{id}/linkToRemoteAccount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Accounts
         * @param {Array<number>} [id] Retrieve the given accounts
         * @param {Array<number>} [bankConnectionId] Retrieve all accounts belonging to the given bank connection ID(s)
         * @param {Array<ListAndSearchAllAccountsBankConnectionTypeEnum>} [bankConnectionType] Retrieve all accounts having the given bank connection type(s)
         * @param {Array<ListAndSearchAllAccountsTypeEnum>} [type] Retrieve Accounts with the given type
         * @param {Array<string>} [bic] Retrieve accounts with the given BIC
         * @param {Array<string>} [iban] Retrieve accounts with the given IBAN
         * @param {Array<number>} [subCategoryId] Retrieve accounts having the given sub-category ID
         * @param {string} [selectGroupFilter] Only return the results which are also part of the given select group.
         * @param {boolean} [bankLinkRequired] Only return accounts that are linked to a bank (i.e. their finApiId is not null).
         * @param {boolean} [isHidden] Only return accounts in status hidden / not hidden; default: no filter
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccounts: async (id?: Array<number>, bankConnectionId?: Array<number>, bankConnectionType?: Array<ListAndSearchAllAccountsBankConnectionTypeEnum>, type?: Array<ListAndSearchAllAccountsTypeEnum>, bic?: Array<string>, iban?: Array<string>, subCategoryId?: Array<number>, selectGroupFilter?: string, bankLinkRequired?: boolean, isHidden?: boolean, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (bankConnectionId) {
                localVarQueryParameter['bankConnectionId'] = bankConnectionId;
            }

            if (bankConnectionType) {
                localVarQueryParameter['bankConnectionType'] = bankConnectionType;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (bic) {
                localVarQueryParameter['bic'] = bic;
            }

            if (iban) {
                localVarQueryParameter['iban'] = iban;
            }

            if (subCategoryId) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            if (selectGroupFilter !== undefined) {
                localVarQueryParameter['selectGroupFilter'] = selectGroupFilter;
            }

            if (bankLinkRequired !== undefined) {
                localVarQueryParameter['bankLinkRequired'] = bankLinkRequired;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['isHidden'] = isHidden;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Accounts for the TPP web form flow
         * @param {string} tppWebFormToken The token according to the currently active / currently completed web-flow
         * @param {Array<ListAndSearchAllAccountsTppWebFormTypeEnum>} [type] Retrieve Accounts with the given type
         * @param {boolean} [bankLinkRequired] Only return accounts that are linked to a bank (i.e. their finApiId is not null).
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccountsTppWebForm: async (tppWebFormToken: string, type?: Array<ListAndSearchAllAccountsTppWebFormTypeEnum>, bankLinkRequired?: boolean, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tppWebFormToken' is not null or undefined
            assertParamExists('listAndSearchAllAccountsTppWebForm', 'tppWebFormToken', tppWebFormToken)
            const localVarPath = `/v1/accounts/tppWebForm/{tppWebFormToken}`
                .replace(`{${"tppWebFormToken"}}`, encodeURIComponent(String(tppWebFormToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (bankLinkRequired !== undefined) {
                localVarQueryParameter['bankLinkRequired'] = bankLinkRequired;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get and search all remote accounts (stored on finAPI side). Note that the list of remote accounts                      may differ from the list of locally persisted accounts. Users may e.g. decide to skip an account during                      import.
         * @param {Array<number>} [id] Retrieve the remote accounts with the given (finAPI) IDs.
         * @param {Array<number>} [accountId] Retrieve the remote account matching the given (local) IDs.
         * @param {Array<number>} [bankConnectionId] Retrieve the remote accounts belonging to the given bank connection(s).
         * @param {boolean} [skipImported] Only retrieve remote accounts that have not been imported.                         Default is to ignore this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllRemoteAccounts: async (id?: Array<number>, accountId?: Array<number>, bankConnectionId?: Array<number>, skipImported?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts/remoteAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (bankConnectionId) {
                localVarQueryParameter['bankConnectionId'] = bankConnectionId;
            }

            if (skipImported !== undefined) {
                localVarQueryParameter['skipImported'] = skipImported;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a sync status for given accounts
         * @param {Array<number>} accountId Retrieve the sync status for the given accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualSyncRequired: async (accountId: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('manualSyncRequired', 'accountId', accountId)
            const localVarPath = `/v1/accounts/manualSyncRequired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all investments / transactions / insurances from the given account.
         * @param {number} id ID of the account to purge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('purgeAccount', 'id', id)
            const localVarPath = `/v1/accounts/{id}/purge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the remote status (finAPI status on updates, saved PIN, etc.) for the given account.
         * @param {number} id Retrieve the remote status for the account with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remoteStatus', 'id', id)
            const localVarPath = `/v1/accounts/{id}/remoteStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts.
         * @param {string} id ID of the select group to delete.
         * @param {Array<string>} [fallbackAccountType] In case the select group was not found: return IDs of all accounts matching the given types.
         * @param {boolean} [excludeManuallyMaintainedFallbackAccounts] Exclude manually maintained accounts and accounts currently not supporting categorization                             (i.e. wealthAPI accounts) if we are falling back to a standard selection.                             This is useful for use-cases relying on banking data - here, manually maintained accounts                             do not make sense.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectGroupsId: async (id: string, fallbackAccountType?: Array<string>, excludeManuallyMaintainedFallbackAccounts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectGroupsId', 'id', id)
            const localVarPath = `/v1/accounts/selectGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fallbackAccountType) {
                localVarQueryParameter['fallbackAccountType'] = fallbackAccountType;
            }

            if (excludeManuallyMaintainedFallbackAccounts !== undefined) {
                localVarQueryParameter['excludeManuallyMaintainedFallbackAccounts'] = excludeManuallyMaintainedFallbackAccounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all selectable accounts
         * @param {string} type Type of the investment; the set of returned accounts depends on the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectableAccounts: async (type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('selectableAccounts', 'type', type)
            const localVarPath = `/v1/accounts/selectableAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink the given account from its remote remote account. The remote account will be deleted after                      un-linking.
         * @param {number} id ID of the account (must be of type DEPOT) to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkRemoteAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unlinkRemoteAccount', 'id', id)
            const localVarPath = `/v1/accounts/{id}/unlinkRemoteAccount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the account with the given ID.
         * @param {number} id ID of the account to update
         * @param {CreateOrUpdateAccountParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (id: number, body: CreateOrUpdateAccountParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccount', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAccount', 'body', body)
            const localVarPath = `/v1/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the remote status of the given account.
         * @param {number} id ID of the account to update
         * @param {UpdateRemoteAccountStatusParams} body The update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteStatus: async (id: number, body: UpdateRemoteAccountStatusParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRemoteStatus', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRemoteStatus', 'body', body)
            const localVarPath = `/v1/accounts/{id}/remoteStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current valuation (depot valuation plus optionally the cash position) for the given accounts.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {boolean} includeCash Return portfolio valuations including cash if true; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valuations: async (id: Array<number>, includeCash: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('valuations', 'id', id)
            // verify required parameter 'includeCash' is not null or undefined
            assertParamExists('valuations', 'includeCash', includeCash)
            const localVarPath = `/v1/accounts/valuation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (includeCash !== undefined) {
                localVarQueryParameter['includeCash'] = includeCash;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Transfer all investments from this account to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 All investments will be booked-out and booked-in into the given account.
         * @param {number} id ID of the account from which assets will be transferred to (must be of type DEPOT                         or MATERIAL_ASSETS)
         * @param {DepotTransferParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountTransfer(id: number, body: DepotTransferParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountTransfer(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountTransfer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an Account
         * @param {number} id ID of the account to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the accumulated balances for the given account(s). Balances are calculated based on the                      available transaction history. If necessary, adjusting entries will be created to account for                      deviations  between the banks transaction history and the actually available transaction history.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {string} [from] Get balances from the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
         * @param {string} [to] Get balances up to the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
         * @param {boolean} [padDates] Will pad dates so that there is a balance for every day if true; default is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balances(id: Array<number>, from?: string, to?: string, padDates?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateValuePairList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balances(id, from, to, padDates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.balances']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the accumulated cash flows for the given account(s). Cash flows are calculated based on the                      available transaction history. If necessary, adjusting positions will be added implicitly to account for                      deviations between the banks transaction history and the actually available transaction history.
         * @param {Array<number>} id ID of the account to retrieve cash flows for.
         * @param {Array<number>} [interval] The interval for cash flows, available are day (default), week, month and year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashFlows(id: Array<number>, interval?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateValuePairList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashFlows(id, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.cashFlows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Categorize the given accounts.
         * @param {Array<number>} [id] IDs of the accounts to categorize. Will perform categorization on all of the users                              cash accounts, depots and material asset depots if not given.
         * @param {boolean} [includeHidden] Include hidden accounts as well; default is false. Ignored if individual IDs are provided
         * @param {boolean} [inPercent] Convert all values to percent; default is false. This allows to provide data anonymized.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categorize(id?: Array<number>, includeHidden?: boolean, inPercent?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCategorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categorize(id, includeHidden, inPercent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.categorize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the contra account for the given depot.
         * @param {number} id ID of the account (must be of type DEPOT) to change the contra account for
         * @param {number} contraAccountId ID of the new contra account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeContraAccount(id: number, contraAccountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeContraAccount(id, contraAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.changeContraAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create an account. In case this is a depot, the contra account will be created automatically.
         * @param {CreateOrUpdateAccountParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(body: CreateOrUpdateAccountParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update or create an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts. Note that select groups do not                      have an ID as they are solely identified by their name.
         * @param {CreateOrUpdateAccountSelectGroupParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSelectGroup(body: CreateOrUpdateAccountSelectGroupParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSelectGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSelectGroup(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.createOrUpdateSelectGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the given account.
         * @param {number} id ID of the account to delete
         * @param {boolean} [deleteContraAccount] Delete the contra account together with the depot if true. This is however only possible                              if the contra account is solely used for the depot being deleted. Default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(id: number, deleteContraAccount?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(id, deleteContraAccount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.deleteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an account select group
         * @param {string} id ID of the select group to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSelectGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSelectGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.deleteSelectGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the estimated fund commissions for the selected accounts. Fund commissions include                      portfolio commissions and agios that would have been payed in the last 12 months.
         * @param {Array<number>} [id] ID of the accounts to retrieve fund commission estimates for. If not specified, all DEPOTS                              of the currently logged in user will be used for estimation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fundCommissions(id?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregatedFundCommissionStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fundCommissions(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.fundCommissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get historic valuations (depot valuation plus optionally the cash position) for the given accounts.                      Valuations will be returned using the given interval definition; always at the first of the                      week / month / year.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {string} intervalType Use the given interval type, one of day, week, month, year
         * @param {string} startDate Start data at the given date; format is yyyy-MM-dd (US Format); default is 5 years ago.
         * @param {boolean} includeCash Return portfolio valuations including cash if true; default is false.
         * @param {boolean} [includeCurrentValue] Include the current (today\&#39;s) value; no matter if it falls into the interval or not.                              Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicValuations(id: Array<number>, intervalType: string, startDate: string, includeCash: boolean, includeCurrentValue?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateValuePairList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicValuations(id, intervalType, startDate, includeCash, includeCurrentValue, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.historicValuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Categorize the given account.
         * @param {number} id ID of the account to categorize.
         * @param {boolean} [inPercent] Convert all values to percent; this allows to provide data anonymized.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idCategorize(id: number, inPercent?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCategorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idCategorize(id, inPercent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.idCategorize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import the given remote account into local storage. The account must already exist on finAPI side.
         * @param {ImportRemoteAccountParams} body Defines the remote account to import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importRemoteAccount(body: ImportRemoteAccountParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importRemoteAccount(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.importRemoteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link the given (manually maintained) account to the given remote accounts.
         * @param {number} id ID of the account (must be of type DEPOT) to update
         * @param {LinkExistingAccountToBankParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkToRemoteAccount(id: number, body: LinkExistingAccountToBankParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkToRemoteAccount(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.linkToRemoteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Accounts
         * @param {Array<number>} [id] Retrieve the given accounts
         * @param {Array<number>} [bankConnectionId] Retrieve all accounts belonging to the given bank connection ID(s)
         * @param {Array<ListAndSearchAllAccountsBankConnectionTypeEnum>} [bankConnectionType] Retrieve all accounts having the given bank connection type(s)
         * @param {Array<ListAndSearchAllAccountsTypeEnum>} [type] Retrieve Accounts with the given type
         * @param {Array<string>} [bic] Retrieve accounts with the given BIC
         * @param {Array<string>} [iban] Retrieve accounts with the given IBAN
         * @param {Array<number>} [subCategoryId] Retrieve accounts having the given sub-category ID
         * @param {string} [selectGroupFilter] Only return the results which are also part of the given select group.
         * @param {boolean} [bankLinkRequired] Only return accounts that are linked to a bank (i.e. their finApiId is not null).
         * @param {boolean} [isHidden] Only return accounts in status hidden / not hidden; default: no filter
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllAccounts(id?: Array<number>, bankConnectionId?: Array<number>, bankConnectionType?: Array<ListAndSearchAllAccountsBankConnectionTypeEnum>, type?: Array<ListAndSearchAllAccountsTypeEnum>, bic?: Array<string>, iban?: Array<string>, subCategoryId?: Array<number>, selectGroupFilter?: string, bankLinkRequired?: boolean, isHidden?: boolean, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllAccounts(id, bankConnectionId, bankConnectionType, type, bic, iban, subCategoryId, selectGroupFilter, bankLinkRequired, isHidden, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.listAndSearchAllAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Accounts for the TPP web form flow
         * @param {string} tppWebFormToken The token according to the currently active / currently completed web-flow
         * @param {Array<ListAndSearchAllAccountsTppWebFormTypeEnum>} [type] Retrieve Accounts with the given type
         * @param {boolean} [bankLinkRequired] Only return accounts that are linked to a bank (i.e. their finApiId is not null).
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllAccountsTppWebForm(tppWebFormToken: string, type?: Array<ListAndSearchAllAccountsTppWebFormTypeEnum>, bankLinkRequired?: boolean, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllAccountsTppWebForm(tppWebFormToken, type, bankLinkRequired, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.listAndSearchAllAccountsTppWebForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get and search all remote accounts (stored on finAPI side). Note that the list of remote accounts                      may differ from the list of locally persisted accounts. Users may e.g. decide to skip an account during                      import.
         * @param {Array<number>} [id] Retrieve the remote accounts with the given (finAPI) IDs.
         * @param {Array<number>} [accountId] Retrieve the remote account matching the given (local) IDs.
         * @param {Array<number>} [bankConnectionId] Retrieve the remote accounts belonging to the given bank connection(s).
         * @param {boolean} [skipImported] Only retrieve remote accounts that have not been imported.                         Default is to ignore this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllRemoteAccounts(id?: Array<number>, accountId?: Array<number>, bankConnectionId?: Array<number>, skipImported?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinApiAccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllRemoteAccounts(id, accountId, bankConnectionId, skipImported, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.listAndSearchAllRemoteAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a sync status for given accounts
         * @param {Array<number>} accountId Retrieve the sync status for the given accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualSyncRequired(accountId: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncStatusList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualSyncRequired(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.manualSyncRequired']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete all investments / transactions / insurances from the given account.
         * @param {number} id ID of the account to purge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purgeAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purgeAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.purgeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the remote status (finAPI status on updates, saved PIN, etc.) for the given account.
         * @param {number} id Retrieve the remote status for the account with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteAccountStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteStatus(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.remoteStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts.
         * @param {string} id ID of the select group to delete.
         * @param {Array<string>} [fallbackAccountType] In case the select group was not found: return IDs of all accounts matching the given types.
         * @param {boolean} [excludeManuallyMaintainedFallbackAccounts] Exclude manually maintained accounts and accounts currently not supporting categorization                             (i.e. wealthAPI accounts) if we are falling back to a standard selection.                             This is useful for use-cases relying on banking data - here, manually maintained accounts                             do not make sense.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectGroupsId(id: string, fallbackAccountType?: Array<string>, excludeManuallyMaintainedFallbackAccounts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSelectGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectGroupsId(id, fallbackAccountType, excludeManuallyMaintainedFallbackAccounts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.selectGroupsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all selectable accounts
         * @param {string} type Type of the investment; the set of returned accounts depends on the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectableAccounts(type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectableAccounts(type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.selectableAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink the given account from its remote remote account. The remote account will be deleted after                      un-linking.
         * @param {number} id ID of the account (must be of type DEPOT) to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkRemoteAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkRemoteAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.unlinkRemoteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the account with the given ID.
         * @param {number} id ID of the account to update
         * @param {CreateOrUpdateAccountParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(id: number, body: CreateOrUpdateAccountParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.updateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the remote status of the given account.
         * @param {number} id ID of the account to update
         * @param {UpdateRemoteAccountStatusParams} body The update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRemoteStatus(id: number, body: UpdateRemoteAccountStatusParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteAccountStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRemoteStatus(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.updateRemoteStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the current valuation (depot valuation plus optionally the cash position) for the given accounts.
         * @param {Array<number>} id ID of the account to retrieve balances for.
         * @param {boolean} includeCash Return portfolio valuations including cash if true; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valuations(id: Array<number>, includeCash: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valuations(id, includeCash, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.valuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Transfer all investments from this account to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 All investments will be booked-out and booked-in into the given account.
         * @param {AccountsApiAccountTransferRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountTransfer(requestParameters: AccountsApiAccountTransferRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.accountTransfer(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Account
         * @param {AccountsApiAccountsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsId(requestParameters: AccountsApiAccountsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.accountsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the accumulated balances for the given account(s). Balances are calculated based on the                      available transaction history. If necessary, adjusting entries will be created to account for                      deviations  between the banks transaction history and the actually available transaction history.
         * @param {AccountsApiBalancesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balances(requestParameters: AccountsApiBalancesRequest, options?: RawAxiosRequestConfig): AxiosPromise<DateValuePairList> {
            return localVarFp.balances(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.padDates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the accumulated cash flows for the given account(s). Cash flows are calculated based on the                      available transaction history. If necessary, adjusting positions will be added implicitly to account for                      deviations between the banks transaction history and the actually available transaction history.
         * @param {AccountsApiCashFlowsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashFlows(requestParameters: AccountsApiCashFlowsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DateValuePairList> {
            return localVarFp.cashFlows(requestParameters.id, requestParameters.interval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Categorize the given accounts.
         * @param {AccountsApiCategorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categorize(requestParameters: AccountsApiCategorizeRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AssetCategorization> {
            return localVarFp.categorize(requestParameters.id, requestParameters.includeHidden, requestParameters.inPercent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the contra account for the given depot.
         * @param {AccountsApiChangeContraAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeContraAccount(requestParameters: AccountsApiChangeContraAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.changeContraAccount(requestParameters.id, requestParameters.contraAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an account. In case this is a depot, the contra account will be created automatically.
         * @param {AccountsApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: AccountsApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.createAccount(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update or create an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts. Note that select groups do not                      have an ID as they are solely identified by their name.
         * @param {AccountsApiCreateOrUpdateSelectGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSelectGroup(requestParameters: AccountsApiCreateOrUpdateSelectGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountSelectGroup> {
            return localVarFp.createOrUpdateSelectGroup(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the given account.
         * @param {AccountsApiDeleteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(requestParameters: AccountsApiDeleteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.deleteAccount(requestParameters.id, requestParameters.deleteContraAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an account select group
         * @param {AccountsApiDeleteSelectGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSelectGroup(requestParameters: AccountsApiDeleteSelectGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSelectGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the estimated fund commissions for the selected accounts. Fund commissions include                      portfolio commissions and agios that would have been payed in the last 12 months.
         * @param {AccountsApiFundCommissionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundCommissions(requestParameters: AccountsApiFundCommissionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AggregatedFundCommissionStatistics> {
            return localVarFp.fundCommissions(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get historic valuations (depot valuation plus optionally the cash position) for the given accounts.                      Valuations will be returned using the given interval definition; always at the first of the                      week / month / year.
         * @param {AccountsApiHistoricValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicValuations(requestParameters: AccountsApiHistoricValuationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DateValuePairList> {
            return localVarFp.historicValuations(requestParameters.id, requestParameters.intervalType, requestParameters.startDate, requestParameters.includeCash, requestParameters.includeCurrentValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Categorize the given account.
         * @param {AccountsApiIdCategorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idCategorize(requestParameters: AccountsApiIdCategorizeRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCategorization> {
            return localVarFp.idCategorize(requestParameters.id, requestParameters.inPercent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import the given remote account into local storage. The account must already exist on finAPI side.
         * @param {AccountsApiImportRemoteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRemoteAccount(requestParameters: AccountsApiImportRemoteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.importRemoteAccount(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link the given (manually maintained) account to the given remote accounts.
         * @param {AccountsApiLinkToRemoteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkToRemoteAccount(requestParameters: AccountsApiLinkToRemoteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.linkToRemoteAccount(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Accounts
         * @param {AccountsApiListAndSearchAllAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccounts(requestParameters: AccountsApiListAndSearchAllAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountList> {
            return localVarFp.listAndSearchAllAccounts(requestParameters.id, requestParameters.bankConnectionId, requestParameters.bankConnectionType, requestParameters.type, requestParameters.bic, requestParameters.iban, requestParameters.subCategoryId, requestParameters.selectGroupFilter, requestParameters.bankLinkRequired, requestParameters.isHidden, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Accounts for the TPP web form flow
         * @param {AccountsApiListAndSearchAllAccountsTppWebFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllAccountsTppWebForm(requestParameters: AccountsApiListAndSearchAllAccountsTppWebFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountList> {
            return localVarFp.listAndSearchAllAccountsTppWebForm(requestParameters.tppWebFormToken, requestParameters.type, requestParameters.bankLinkRequired, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get and search all remote accounts (stored on finAPI side). Note that the list of remote accounts                      may differ from the list of locally persisted accounts. Users may e.g. decide to skip an account during                      import.
         * @param {AccountsApiListAndSearchAllRemoteAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllRemoteAccounts(requestParameters: AccountsApiListAndSearchAllRemoteAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<FinApiAccountList> {
            return localVarFp.listAndSearchAllRemoteAccounts(requestParameters.id, requestParameters.accountId, requestParameters.bankConnectionId, requestParameters.skipImported, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a sync status for given accounts
         * @param {AccountsApiManualSyncRequiredRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualSyncRequired(requestParameters: AccountsApiManualSyncRequiredRequest, options?: RawAxiosRequestConfig): AxiosPromise<SyncStatusList> {
            return localVarFp.manualSyncRequired(requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all investments / transactions / insurances from the given account.
         * @param {AccountsApiPurgeAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeAccount(requestParameters: AccountsApiPurgeAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.purgeAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the remote status (finAPI status on updates, saved PIN, etc.) for the given account.
         * @param {AccountsApiRemoteStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteStatus(requestParameters: AccountsApiRemoteStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<RemoteAccountStatus> {
            return localVarFp.remoteStatus(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts.
         * @param {AccountsApiSelectGroupsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectGroupsId(requestParameters: AccountsApiSelectGroupsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountSelectGroup> {
            return localVarFp.selectGroupsId(requestParameters.id, requestParameters.fallbackAccountType, requestParameters.excludeManuallyMaintainedFallbackAccounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all selectable accounts
         * @param {AccountsApiSelectableAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectableAccounts(requestParameters: AccountsApiSelectableAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountList> {
            return localVarFp.selectableAccounts(requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink the given account from its remote remote account. The remote account will be deleted after                      un-linking.
         * @param {AccountsApiUnlinkRemoteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkRemoteAccount(requestParameters: AccountsApiUnlinkRemoteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.unlinkRemoteAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the account with the given ID.
         * @param {AccountsApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: AccountsApiUpdateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.updateAccount(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the remote status of the given account.
         * @param {AccountsApiUpdateRemoteStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRemoteStatus(requestParameters: AccountsApiUpdateRemoteStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<RemoteAccountStatus> {
            return localVarFp.updateRemoteStatus(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current valuation (depot valuation plus optionally the cash position) for the given accounts.
         * @param {AccountsApiValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valuations(requestParameters: AccountsApiValuationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.valuations(requestParameters.id, requestParameters.includeCash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountTransfer operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountTransferRequest
 */
export interface AccountsApiAccountTransferRequest {
    /**
     * ID of the account from which assets will be transferred to (must be of type DEPOT                         or MATERIAL_ASSETS)
     * @type {number}
     * @memberof AccountsApiAccountTransfer
     */
    readonly id: number

    /**
     * Defines which account to link
     * @type {DepotTransferParams}
     * @memberof AccountsApiAccountTransfer
     */
    readonly body: DepotTransferParams
}

/**
 * Request parameters for accountsId operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsIdRequest
 */
export interface AccountsApiAccountsIdRequest {
    /**
     * ID of the account to retrieve
     * @type {number}
     * @memberof AccountsApiAccountsId
     */
    readonly id: number
}

/**
 * Request parameters for balances operation in AccountsApi.
 * @export
 * @interface AccountsApiBalancesRequest
 */
export interface AccountsApiBalancesRequest {
    /**
     * ID of the account to retrieve balances for.
     * @type {Array<number>}
     * @memberof AccountsApiBalances
     */
    readonly id: Array<number>

    /**
     * Get balances from the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
     * @type {string}
     * @memberof AccountsApiBalances
     */
    readonly from?: string

    /**
     * Get balances up to the given date; format is yyyy-MM-dd (US Format). Default is not to set this value.
     * @type {string}
     * @memberof AccountsApiBalances
     */
    readonly to?: string

    /**
     * Will pad dates so that there is a balance for every day if true; default is false
     * @type {boolean}
     * @memberof AccountsApiBalances
     */
    readonly padDates?: boolean
}

/**
 * Request parameters for cashFlows operation in AccountsApi.
 * @export
 * @interface AccountsApiCashFlowsRequest
 */
export interface AccountsApiCashFlowsRequest {
    /**
     * ID of the account to retrieve cash flows for.
     * @type {Array<number>}
     * @memberof AccountsApiCashFlows
     */
    readonly id: Array<number>

    /**
     * The interval for cash flows, available are day (default), week, month and year.
     * @type {Array<number>}
     * @memberof AccountsApiCashFlows
     */
    readonly interval?: Array<number>
}

/**
 * Request parameters for categorize operation in AccountsApi.
 * @export
 * @interface AccountsApiCategorizeRequest
 */
export interface AccountsApiCategorizeRequest {
    /**
     * IDs of the accounts to categorize. Will perform categorization on all of the users                              cash accounts, depots and material asset depots if not given.
     * @type {Array<number>}
     * @memberof AccountsApiCategorize
     */
    readonly id?: Array<number>

    /**
     * Include hidden accounts as well; default is false. Ignored if individual IDs are provided
     * @type {boolean}
     * @memberof AccountsApiCategorize
     */
    readonly includeHidden?: boolean

    /**
     * Convert all values to percent; default is false. This allows to provide data anonymized.
     * @type {boolean}
     * @memberof AccountsApiCategorize
     */
    readonly inPercent?: boolean
}

/**
 * Request parameters for changeContraAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeContraAccountRequest
 */
export interface AccountsApiChangeContraAccountRequest {
    /**
     * ID of the account (must be of type DEPOT) to change the contra account for
     * @type {number}
     * @memberof AccountsApiChangeContraAccount
     */
    readonly id: number

    /**
     * ID of the new contra account
     * @type {number}
     * @memberof AccountsApiChangeContraAccount
     */
    readonly contraAccountId: number
}

/**
 * Request parameters for createAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateAccountRequest
 */
export interface AccountsApiCreateAccountRequest {
    /**
     * The account details
     * @type {CreateOrUpdateAccountParams}
     * @memberof AccountsApiCreateAccount
     */
    readonly body: CreateOrUpdateAccountParams
}

/**
 * Request parameters for createOrUpdateSelectGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateOrUpdateSelectGroupRequest
 */
export interface AccountsApiCreateOrUpdateSelectGroupRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateAccountSelectGroupParams}
     * @memberof AccountsApiCreateOrUpdateSelectGroup
     */
    readonly body: CreateOrUpdateAccountSelectGroupParams
}

/**
 * Request parameters for deleteAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteAccountRequest
 */
export interface AccountsApiDeleteAccountRequest {
    /**
     * ID of the account to delete
     * @type {number}
     * @memberof AccountsApiDeleteAccount
     */
    readonly id: number

    /**
     * Delete the contra account together with the depot if true. This is however only possible                              if the contra account is solely used for the depot being deleted. Default is false.
     * @type {boolean}
     * @memberof AccountsApiDeleteAccount
     */
    readonly deleteContraAccount?: boolean
}

/**
 * Request parameters for deleteSelectGroup operation in AccountsApi.
 * @export
 * @interface AccountsApiDeleteSelectGroupRequest
 */
export interface AccountsApiDeleteSelectGroupRequest {
    /**
     * ID of the select group to delete.
     * @type {string}
     * @memberof AccountsApiDeleteSelectGroup
     */
    readonly id: string
}

/**
 * Request parameters for fundCommissions operation in AccountsApi.
 * @export
 * @interface AccountsApiFundCommissionsRequest
 */
export interface AccountsApiFundCommissionsRequest {
    /**
     * ID of the accounts to retrieve fund commission estimates for. If not specified, all DEPOTS                              of the currently logged in user will be used for estimation.
     * @type {Array<number>}
     * @memberof AccountsApiFundCommissions
     */
    readonly id?: Array<number>
}

/**
 * Request parameters for historicValuations operation in AccountsApi.
 * @export
 * @interface AccountsApiHistoricValuationsRequest
 */
export interface AccountsApiHistoricValuationsRequest {
    /**
     * ID of the account to retrieve balances for.
     * @type {Array<number>}
     * @memberof AccountsApiHistoricValuations
     */
    readonly id: Array<number>

    /**
     * Use the given interval type, one of day, week, month, year
     * @type {string}
     * @memberof AccountsApiHistoricValuations
     */
    readonly intervalType: string

    /**
     * Start data at the given date; format is yyyy-MM-dd (US Format); default is 5 years ago.
     * @type {string}
     * @memberof AccountsApiHistoricValuations
     */
    readonly startDate: string

    /**
     * Return portfolio valuations including cash if true; default is false.
     * @type {boolean}
     * @memberof AccountsApiHistoricValuations
     */
    readonly includeCash: boolean

    /**
     * Include the current (today\&#39;s) value; no matter if it falls into the interval or not.                              Default is true.
     * @type {boolean}
     * @memberof AccountsApiHistoricValuations
     */
    readonly includeCurrentValue?: boolean
}

/**
 * Request parameters for idCategorize operation in AccountsApi.
 * @export
 * @interface AccountsApiIdCategorizeRequest
 */
export interface AccountsApiIdCategorizeRequest {
    /**
     * ID of the account to categorize.
     * @type {number}
     * @memberof AccountsApiIdCategorize
     */
    readonly id: number

    /**
     * Convert all values to percent; this allows to provide data anonymized.
     * @type {boolean}
     * @memberof AccountsApiIdCategorize
     */
    readonly inPercent?: boolean
}

/**
 * Request parameters for importRemoteAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiImportRemoteAccountRequest
 */
export interface AccountsApiImportRemoteAccountRequest {
    /**
     * Defines the remote account to import
     * @type {ImportRemoteAccountParams}
     * @memberof AccountsApiImportRemoteAccount
     */
    readonly body: ImportRemoteAccountParams
}

/**
 * Request parameters for linkToRemoteAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiLinkToRemoteAccountRequest
 */
export interface AccountsApiLinkToRemoteAccountRequest {
    /**
     * ID of the account (must be of type DEPOT) to update
     * @type {number}
     * @memberof AccountsApiLinkToRemoteAccount
     */
    readonly id: number

    /**
     * Defines which account to link
     * @type {LinkExistingAccountToBankParams}
     * @memberof AccountsApiLinkToRemoteAccount
     */
    readonly body: LinkExistingAccountToBankParams
}

/**
 * Request parameters for listAndSearchAllAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiListAndSearchAllAccountsRequest
 */
export interface AccountsApiListAndSearchAllAccountsRequest {
    /**
     * Retrieve the given accounts
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly id?: Array<number>

    /**
     * Retrieve all accounts belonging to the given bank connection ID(s)
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly bankConnectionId?: Array<number>

    /**
     * Retrieve all accounts having the given bank connection type(s)
     * @type {Array<'WEALTH_API' | 'FIN_API' | 'NETFONDS'>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly bankConnectionType?: Array<ListAndSearchAllAccountsBankConnectionTypeEnum>

    /**
     * Retrieve Accounts with the given type
     * @type {Array<'01_depot' | '02_cash' | '03_user_defined' | '04_insurance' | '05_material_assets'>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly type?: Array<ListAndSearchAllAccountsTypeEnum>

    /**
     * Retrieve accounts with the given BIC
     * @type {Array<string>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly bic?: Array<string>

    /**
     * Retrieve accounts with the given IBAN
     * @type {Array<string>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly iban?: Array<string>

    /**
     * Retrieve accounts having the given sub-category ID
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly subCategoryId?: Array<number>

    /**
     * Only return the results which are also part of the given select group.
     * @type {string}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly selectGroupFilter?: string

    /**
     * Only return accounts that are linked to a bank (i.e. their finApiId is not null).
     * @type {boolean}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly bankLinkRequired?: boolean

    /**
     * Only return accounts in status hidden / not hidden; default: no filter
     * @type {boolean}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly isHidden?: boolean

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof AccountsApiListAndSearchAllAccounts
     */
    readonly order?: string
}

/**
 * Request parameters for listAndSearchAllAccountsTppWebForm operation in AccountsApi.
 * @export
 * @interface AccountsApiListAndSearchAllAccountsTppWebFormRequest
 */
export interface AccountsApiListAndSearchAllAccountsTppWebFormRequest {
    /**
     * The token according to the currently active / currently completed web-flow
     * @type {string}
     * @memberof AccountsApiListAndSearchAllAccountsTppWebForm
     */
    readonly tppWebFormToken: string

    /**
     * Retrieve Accounts with the given type
     * @type {Array<'01_depot' | '02_cash' | '03_user_defined' | '04_insurance' | '05_material_assets'>}
     * @memberof AccountsApiListAndSearchAllAccountsTppWebForm
     */
    readonly type?: Array<ListAndSearchAllAccountsTppWebFormTypeEnum>

    /**
     * Only return accounts that are linked to a bank (i.e. their finApiId is not null).
     * @type {boolean}
     * @memberof AccountsApiListAndSearchAllAccountsTppWebForm
     */
    readonly bankLinkRequired?: boolean

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof AccountsApiListAndSearchAllAccountsTppWebForm
     */
    readonly order?: string
}

/**
 * Request parameters for listAndSearchAllRemoteAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiListAndSearchAllRemoteAccountsRequest
 */
export interface AccountsApiListAndSearchAllRemoteAccountsRequest {
    /**
     * Retrieve the remote accounts with the given (finAPI) IDs.
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllRemoteAccounts
     */
    readonly id?: Array<number>

    /**
     * Retrieve the remote account matching the given (local) IDs.
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllRemoteAccounts
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the remote accounts belonging to the given bank connection(s).
     * @type {Array<number>}
     * @memberof AccountsApiListAndSearchAllRemoteAccounts
     */
    readonly bankConnectionId?: Array<number>

    /**
     * Only retrieve remote accounts that have not been imported.                         Default is to ignore this parameter.
     * @type {boolean}
     * @memberof AccountsApiListAndSearchAllRemoteAccounts
     */
    readonly skipImported?: boolean
}

/**
 * Request parameters for manualSyncRequired operation in AccountsApi.
 * @export
 * @interface AccountsApiManualSyncRequiredRequest
 */
export interface AccountsApiManualSyncRequiredRequest {
    /**
     * Retrieve the sync status for the given accounts
     * @type {Array<number>}
     * @memberof AccountsApiManualSyncRequired
     */
    readonly accountId: Array<number>
}

/**
 * Request parameters for purgeAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiPurgeAccountRequest
 */
export interface AccountsApiPurgeAccountRequest {
    /**
     * ID of the account to purge
     * @type {number}
     * @memberof AccountsApiPurgeAccount
     */
    readonly id: number
}

/**
 * Request parameters for remoteStatus operation in AccountsApi.
 * @export
 * @interface AccountsApiRemoteStatusRequest
 */
export interface AccountsApiRemoteStatusRequest {
    /**
     * Retrieve the remote status for the account with the given ID.
     * @type {number}
     * @memberof AccountsApiRemoteStatus
     */
    readonly id: number
}

/**
 * Request parameters for selectGroupsId operation in AccountsApi.
 * @export
 * @interface AccountsApiSelectGroupsIdRequest
 */
export interface AccountsApiSelectGroupsIdRequest {
    /**
     * ID of the select group to delete.
     * @type {string}
     * @memberof AccountsApiSelectGroupsId
     */
    readonly id: string

    /**
     * In case the select group was not found: return IDs of all accounts matching the given types.
     * @type {Array<string>}
     * @memberof AccountsApiSelectGroupsId
     */
    readonly fallbackAccountType?: Array<string>

    /**
     * Exclude manually maintained accounts and accounts currently not supporting categorization                             (i.e. wealthAPI accounts) if we are falling back to a standard selection.                             This is useful for use-cases relying on banking data - here, manually maintained accounts                             do not make sense.
     * @type {boolean}
     * @memberof AccountsApiSelectGroupsId
     */
    readonly excludeManuallyMaintainedFallbackAccounts?: boolean
}

/**
 * Request parameters for selectableAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiSelectableAccountsRequest
 */
export interface AccountsApiSelectableAccountsRequest {
    /**
     * Type of the investment; the set of returned accounts depends on the given type.
     * @type {string}
     * @memberof AccountsApiSelectableAccounts
     */
    readonly type: string
}

/**
 * Request parameters for unlinkRemoteAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiUnlinkRemoteAccountRequest
 */
export interface AccountsApiUnlinkRemoteAccountRequest {
    /**
     * ID of the account (must be of type DEPOT) to update
     * @type {number}
     * @memberof AccountsApiUnlinkRemoteAccount
     */
    readonly id: number
}

/**
 * Request parameters for updateAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateAccountRequest
 */
export interface AccountsApiUpdateAccountRequest {
    /**
     * ID of the account to update
     * @type {number}
     * @memberof AccountsApiUpdateAccount
     */
    readonly id: number

    /**
     * The account details
     * @type {CreateOrUpdateAccountParams}
     * @memberof AccountsApiUpdateAccount
     */
    readonly body: CreateOrUpdateAccountParams
}

/**
 * Request parameters for updateRemoteStatus operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateRemoteStatusRequest
 */
export interface AccountsApiUpdateRemoteStatusRequest {
    /**
     * ID of the account to update
     * @type {number}
     * @memberof AccountsApiUpdateRemoteStatus
     */
    readonly id: number

    /**
     * The update parameters
     * @type {UpdateRemoteAccountStatusParams}
     * @memberof AccountsApiUpdateRemoteStatus
     */
    readonly body: UpdateRemoteAccountStatusParams
}

/**
 * Request parameters for valuations operation in AccountsApi.
 * @export
 * @interface AccountsApiValuationsRequest
 */
export interface AccountsApiValuationsRequest {
    /**
     * ID of the account to retrieve balances for.
     * @type {Array<number>}
     * @memberof AccountsApiValuations
     */
    readonly id: Array<number>

    /**
     * Return portfolio valuations including cash if true; default is false.
     * @type {boolean}
     * @memberof AccountsApiValuations
     */
    readonly includeCash: boolean
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Transfer all investments from this account to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 All investments will be booked-out and booked-in into the given account.
     * @param {AccountsApiAccountTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountTransfer(requestParameters: AccountsApiAccountTransferRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountTransfer(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Account
     * @param {AccountsApiAccountsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsId(requestParameters: AccountsApiAccountsIdRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the accumulated balances for the given account(s). Balances are calculated based on the                      available transaction history. If necessary, adjusting entries will be created to account for                      deviations  between the banks transaction history and the actually available transaction history.
     * @param {AccountsApiBalancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public balances(requestParameters: AccountsApiBalancesRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).balances(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.padDates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the accumulated cash flows for the given account(s). Cash flows are calculated based on the                      available transaction history. If necessary, adjusting positions will be added implicitly to account for                      deviations between the banks transaction history and the actually available transaction history.
     * @param {AccountsApiCashFlowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public cashFlows(requestParameters: AccountsApiCashFlowsRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).cashFlows(requestParameters.id, requestParameters.interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Categorize the given accounts.
     * @param {AccountsApiCategorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public categorize(requestParameters: AccountsApiCategorizeRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).categorize(requestParameters.id, requestParameters.includeHidden, requestParameters.inPercent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the contra account for the given depot.
     * @param {AccountsApiChangeContraAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeContraAccount(requestParameters: AccountsApiChangeContraAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).changeContraAccount(requestParameters.id, requestParameters.contraAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an account. In case this is a depot, the contra account will be created automatically.
     * @param {AccountsApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createAccount(requestParameters: AccountsApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createAccount(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update or create an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts. Note that select groups do not                      have an ID as they are solely identified by their name.
     * @param {AccountsApiCreateOrUpdateSelectGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createOrUpdateSelectGroup(requestParameters: AccountsApiCreateOrUpdateSelectGroupRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createOrUpdateSelectGroup(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the given account.
     * @param {AccountsApiDeleteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteAccount(requestParameters: AccountsApiDeleteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteAccount(requestParameters.id, requestParameters.deleteContraAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an account select group
     * @param {AccountsApiDeleteSelectGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteSelectGroup(requestParameters: AccountsApiDeleteSelectGroupRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteSelectGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the estimated fund commissions for the selected accounts. Fund commissions include                      portfolio commissions and agios that would have been payed in the last 12 months.
     * @param {AccountsApiFundCommissionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public fundCommissions(requestParameters: AccountsApiFundCommissionsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).fundCommissions(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get historic valuations (depot valuation plus optionally the cash position) for the given accounts.                      Valuations will be returned using the given interval definition; always at the first of the                      week / month / year.
     * @param {AccountsApiHistoricValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public historicValuations(requestParameters: AccountsApiHistoricValuationsRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).historicValuations(requestParameters.id, requestParameters.intervalType, requestParameters.startDate, requestParameters.includeCash, requestParameters.includeCurrentValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Categorize the given account.
     * @param {AccountsApiIdCategorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public idCategorize(requestParameters: AccountsApiIdCategorizeRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).idCategorize(requestParameters.id, requestParameters.inPercent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import the given remote account into local storage. The account must already exist on finAPI side.
     * @param {AccountsApiImportRemoteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public importRemoteAccount(requestParameters: AccountsApiImportRemoteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).importRemoteAccount(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link the given (manually maintained) account to the given remote accounts.
     * @param {AccountsApiLinkToRemoteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public linkToRemoteAccount(requestParameters: AccountsApiLinkToRemoteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).linkToRemoteAccount(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Accounts
     * @param {AccountsApiListAndSearchAllAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listAndSearchAllAccounts(requestParameters: AccountsApiListAndSearchAllAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listAndSearchAllAccounts(requestParameters.id, requestParameters.bankConnectionId, requestParameters.bankConnectionType, requestParameters.type, requestParameters.bic, requestParameters.iban, requestParameters.subCategoryId, requestParameters.selectGroupFilter, requestParameters.bankLinkRequired, requestParameters.isHidden, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Accounts for the TPP web form flow
     * @param {AccountsApiListAndSearchAllAccountsTppWebFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listAndSearchAllAccountsTppWebForm(requestParameters: AccountsApiListAndSearchAllAccountsTppWebFormRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listAndSearchAllAccountsTppWebForm(requestParameters.tppWebFormToken, requestParameters.type, requestParameters.bankLinkRequired, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get and search all remote accounts (stored on finAPI side). Note that the list of remote accounts                      may differ from the list of locally persisted accounts. Users may e.g. decide to skip an account during                      import.
     * @param {AccountsApiListAndSearchAllRemoteAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listAndSearchAllRemoteAccounts(requestParameters: AccountsApiListAndSearchAllRemoteAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listAndSearchAllRemoteAccounts(requestParameters.id, requestParameters.accountId, requestParameters.bankConnectionId, requestParameters.skipImported, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a sync status for given accounts
     * @param {AccountsApiManualSyncRequiredRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public manualSyncRequired(requestParameters: AccountsApiManualSyncRequiredRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).manualSyncRequired(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all investments / transactions / insurances from the given account.
     * @param {AccountsApiPurgeAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public purgeAccount(requestParameters: AccountsApiPurgeAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).purgeAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the remote status (finAPI status on updates, saved PIN, etc.) for the given account.
     * @param {AccountsApiRemoteStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public remoteStatus(requestParameters: AccountsApiRemoteStatusRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).remoteStatus(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an account select group. Account select groups allow to define standard selections                      in case users want to always start with a custom set of accounts.
     * @param {AccountsApiSelectGroupsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public selectGroupsId(requestParameters: AccountsApiSelectGroupsIdRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).selectGroupsId(requestParameters.id, requestParameters.fallbackAccountType, requestParameters.excludeManuallyMaintainedFallbackAccounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all selectable accounts
     * @param {AccountsApiSelectableAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public selectableAccounts(requestParameters: AccountsApiSelectableAccountsRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).selectableAccounts(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink the given account from its remote remote account. The remote account will be deleted after                      un-linking.
     * @param {AccountsApiUnlinkRemoteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public unlinkRemoteAccount(requestParameters: AccountsApiUnlinkRemoteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).unlinkRemoteAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the account with the given ID.
     * @param {AccountsApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAccount(requestParameters: AccountsApiUpdateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateAccount(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the remote status of the given account.
     * @param {AccountsApiUpdateRemoteStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateRemoteStatus(requestParameters: AccountsApiUpdateRemoteStatusRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateRemoteStatus(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current valuation (depot valuation plus optionally the cash position) for the given accounts.
     * @param {AccountsApiValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public valuations(requestParameters: AccountsApiValuationsRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).valuations(requestParameters.id, requestParameters.includeCash, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllAccountsBankConnectionTypeEnum = {
    WEALTH_API: 'WEALTH_API',
    FIN_API: 'FIN_API',
    NETFONDS: 'NETFONDS'
} as const;
export type ListAndSearchAllAccountsBankConnectionTypeEnum = typeof ListAndSearchAllAccountsBankConnectionTypeEnum[keyof typeof ListAndSearchAllAccountsBankConnectionTypeEnum];
/**
 * @export
 */
export const ListAndSearchAllAccountsTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _03_USER_DEFINED: '03_user_defined',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;
export type ListAndSearchAllAccountsTypeEnum = typeof ListAndSearchAllAccountsTypeEnum[keyof typeof ListAndSearchAllAccountsTypeEnum];
/**
 * @export
 */
export const ListAndSearchAllAccountsTppWebFormTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _03_USER_DEFINED: '03_user_defined',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;
export type ListAndSearchAllAccountsTppWebFormTypeEnum = typeof ListAndSearchAllAccountsTppWebFormTypeEnum[keyof typeof ListAndSearchAllAccountsTppWebFormTypeEnum];
