import React, { useMemo } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { addMonths, format } from "date-fns";
import { last } from "lodash";
import { useTranslation } from "react-i18next";
import { DividendCardContainer } from "@features/dividendPlanner/components/DividendCardContainer";
import { usePublicOrPrivatePerformance } from "@features/sharedPortfolio/usePublicOrPrivatePerformance";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import {
  useDividendsPortfolioYield,
  useDividendsPrediction,
} from "@features/dividendPlanner/hooks/useDividends";
import {
  usePublicDividendPrediction,
  usePublicDividendYield,
} from "@generated/apiv1/hooks";
import { generateRandomNumber } from "@helpers";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";

const CAGR_YEARS_CHECK = 4;

type Props = {
  accountIds: number[];
};

export const DividendCards = ({ accountIds }: Props) => {
  const { isUserPro, isUserDataFetched } = useDividendsContext();
  const { t } = useTranslation();
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const {
    data: predictData,
    isFetchedAfterMount: isPredictDataLoaded,
    isLoading: isPredictDataLoading,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsPrediction,
    usePublicMethod: usePublicDividendPrediction,
    params: {
      predictYears: 1,
      accountId: accountIds,
      dividendForPortfolio: true,
    },
    options: {
      enabled: Boolean(accountIds.length && isUserPro),
    },
  });

  const {
    data: performanceData,
    isLoading,
    isFetched,
  } = usePublicOrPrivatePerformance(
    {
      accountIds,
      startDate: format(addMonths(new Date(), -12), "yyyy-MM-dd"),
      interval: "year",
      cashFlowAndPerformanceStatisticsParamsList: [
        { assetClasses: [], includeCash: false, includeDividends: false },
      ],
    },
    {
      enabled: Boolean(accountIds.length),
    }
  );

  const {
    data: dividendYieldActualData,
    isLoading: isDividendYieldDataLoading,
    isFetchedAfterMount,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendsPortfolioYield,
    usePublicMethod: usePublicDividendYield,
    params: {
      calculationType: "actual" as "actual" | "predicted",
      accountId: accountIds,
    },
    options: {
      enabled: Boolean(accountIds.length),
    },
  });

  const performance = performanceData?.[0];
  const portfolioValue = last(performance?.twrorResults)?.currentMarketValue;
  const currentYearValue = dividendYieldActualData?.dividendYields?.find(
    (item) => item.year === new Date().getFullYear()
  );

  const currentYearDividendValue = currentYearValue?.totalGrossDividend;
  const grossYield = currentYearValue?.grossYield;

  const cagrTimePeriod =
    dividendYieldActualData?.cagrResult?.finalYear! -
    dividendYieldActualData?.cagrResult?.initialYear!;

  const cagr =
    cagrTimePeriod >= CAGR_YEARS_CHECK
      ? dividendYieldActualData?.cagrResult?.cagr
      : undefined;
  const incomeThisYear =
    predictData?.nodesByYear?.[new Date().getFullYear()]?.grossAmount;
  const incomeForMonth = (incomeThisYear || 0) / 12;

  const cagrTimePeriodOutput =
    cagrTimePeriod >= CAGR_YEARS_CHECK
      ? ` ${cagrTimePeriod} ` + t("dividends.overviewPage.cards.yield.years")
      : undefined;

  const randomNumbers = useMemo(() => {
    return {
      incomeForMonth: generateRandomNumber(),
      incomeThisYear: generateRandomNumber(),
      grossYield: generateRandomNumber(0, 100),
      cagr: generateRandomNumber(0, 100),
    };
  }, []);

  return (
    <Box
      display="flex"
      gap={6}
      alignItems="stertch"
      flexDirection={isLessMd ? "column" : "row"}
    >
      <DividendCardContainer
        i18key="portfolio"
        value1={portfolioValue}
        isValue1Loading={isLoading || !isFetched}
        value2={currentYearDividendValue}
        isValue2Loading={isDividendYieldDataLoading || !isFetchedAfterMount}
      />
      <DividendCardContainer
        i18key="income"
        value1={isUserPro ? incomeThisYear : randomNumbers.incomeThisYear}
        value2={isUserPro ? incomeForMonth : randomNumbers.incomeForMonth}
        isValue1Loading={
          isUserPro
            ? isPredictDataLoading || !isPredictDataLoaded
            : !isUserDataFetched
        }
        isValue2Loading={
          isUserPro
            ? isPredictDataLoading || !isPredictDataLoaded
            : !isUserDataFetched
        }
        displayGainLoss
        isForPlusOnly
      />
      <DividendCardContainer
        i18key="yield"
        value1={isUserPro ? grossYield : randomNumbers.grossYield}
        value2={isUserPro ? cagr : randomNumbers.cagr}
        isValue1Loading={
          isUserPro
            ? isDividendYieldDataLoading || !isFetchedAfterMount
            : !isUserDataFetched
        }
        isValue2Loading={
          isUserPro
            ? isDividendYieldDataLoading || !isFetchedAfterMount
            : !isUserDataFetched
        }
        additionalText={cagrTimePeriodOutput}
        displayGainLoss
        isForPlusOnly
        isPercentage
      />
    </Box>
  );
};
