import { InvokeCallback, Sender } from "xstate";
import { Dividend } from "@api";
import { getDividends } from "@api/v1/methods/dividends";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const loadTaxAmount =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  async (callback) => {
    if (event.type !== "SKIP_TAX" && event.type !== "TAX_ENTERED") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    load(
      context.investment?.id ?? 0,
      context.customTaxAmount,
      context.selectedDividends,
      callback
    );
  };

const load = async (
  investmentId: number,
  taxRate: number,
  selectedDividends: Dividend[],
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const { dividends = [] } = await getDividends({
      investmentId: [investmentId],
      excludeImported: false,
      taxRate,
    });

    const selectedDividendsWithTaxRate: Dividend[] = dividends.filter(
      ({ date }) =>
        selectedDividends.some(
          ({ date: responseDate }) => date === responseDate
        )
    );

    callback({
      type: "TAX_RATE_LOADED",
      selectedDividendsWithTaxRate,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
