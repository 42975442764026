import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import { Icon, Theme } from "@mui/material";
import { useTheme } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import { ReactComponent as Arrow } from "@icons/arrow-left.svg";
import { TableBodyCell } from "@components/Table/TableBodyCell";

const getBorderBottomStyle = ({
  theme,
  isExpandable,
  isExpanded,
  isSelected,
  isDepots,
}: {
  theme: Theme;
  isExpandable?: boolean;
  isExpanded?: boolean;
  isSelected?: boolean;
  isDepots?: boolean;
}) => {
  if (!isSelected) {
    if (isExpandable && isExpanded && !isDepots) {
      return "none";
    } else {
      return `1px solid ${theme.palette.border.secondary}`;
    }
  }
  if (isSelected) return `1px solid ${theme.palette.border.secondary}`;
};

const Container = styled(motion.tr, {
  shouldForwardProp: isPropValid,
})<{
  isExpandable?: boolean;
  isExpanded?: boolean;
  isSelected?: boolean;
  height?: number;
  clickable?: boolean;
  isDepots?: boolean;
}>`
  & > td {
    vertical-align: center;
    padding: ${({ theme }) => theme.spacing(3)};
    border-bottom: ${({
      theme,
      isExpandable,
      isExpanded,
      isSelected,
      isDepots,
    }) =>
      getBorderBottomStyle({
        theme,
        isExpandable,
        isExpanded,
        isSelected,
        isDepots,
      })};
  }
  & > td.expand {
    padding: ${({ theme }) => theme.spacing(4, 0, 4)};
  }
  cursor: ${({ isExpandable = false, clickable = false }) =>
    isExpandable || clickable ? "pointer" : "inherit"};
  background: ${({ theme, isSelected }) =>
    isSelected ? `${theme.palette.background.neutral}` : "unset"};
  height: ${({ height = "auto" }) => (height ? `${height}px` : "auto")};
`;

const ExpandableContainer = styled.div<{ isDepots: boolean }>`
  width: 100%;
  background-color: ${({ theme, isDepots }) =>
    isDepots ? "transparent" : theme.palette.background.neutral};
  border-radius: 8px;
`;

const ExpandableContainerTd = styled.td<{ isDepots: boolean }>`
  padding: 0 !important;
  padding-bottom: ${({ theme, isDepots }) =>
    isDepots ? 0 : theme.spacing(3)} !important;
  border: ${({ isDepots }) => isDepots && "none!important"};
`;

type Props = {
  className?: string;
  children: ReactNode;
  expandableElement?: ReactNode;
  isDepots?: boolean;
  isWrapped?: boolean;
  clickable?: boolean;
  isSelected?: boolean;
  height?: number;
  onClick?: () => void;
} & Omit<
  React.HTMLAttributes<HTMLTableRowElement>,
  "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag"
>;

export const TableRow = ({
  className,
  children,
  expandableElement,
  isDepots = false,
  isWrapped = true,
  clickable,
  isSelected,
  height,
  ...props
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const handleClick = () => {
    if (!expandableElement) return;
    setIsExpanded((current) => !current);
  };

  return (
    <>
      <Container
        isExpanded={isExpanded}
        onClick={clickable ? handleClick : undefined}
        className={isSelected ? `calendar-table-selected-row` : className}
        isExpandable={!!expandableElement}
        isSelected={isSelected}
        height={height}
        isDepots={isDepots}
        clickable={clickable || Boolean(props.onClick)}
        {...props}
      >
        {Boolean(expandableElement) && (
          <TableBodyCell
            className="expand"
            sx={{
              paddingRight: 0,
            }}
            width="2%"
          >
            <Icon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& svg": {
                  transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
                  transition: "transform 0.2s ease-in-out",
                  "& path": {
                    stroke: theme.palette.icon.primary,
                  },
                },
              }}
            >
              <Arrow width="28px" height="28px" />
            </Icon>
          </TableBodyCell>
        )}
        {children}
      </Container>
      {isExpanded && (
        <>
          {isWrapped ? (
            <Container isExpanded={isExpanded}>
              <ExpandableContainerTd colSpan={42} isDepots={isDepots}>
                <ExpandableContainer isDepots={isDepots}>
                  {expandableElement}
                </ExpandableContainer>
              </ExpandableContainerTd>
            </Container>
          ) : (
            expandableElement
          )}
        </>
      )}
    </>
  );
};
