import React from "react";
import { useParams } from "react-router-dom";
import { InvestmentPage } from "./InvestmentPage";

export const InvestmentByParamPage = () => {
  const { investmentId, depotId } = useParams<{
    investmentId: string;
    depotId: string;
  }>();
  return (
    <InvestmentPage
      investmentId={Number.parseInt(investmentId!, 10)}
      depotId={Number.parseInt(depotId!, 10)}
    />
  );
};
