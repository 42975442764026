import React from "react";
import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generateRandomNumber, toCurrency } from "@helpers";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { Switcher } from "@components";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { BlurredTypography } from "@components/BlurredTypography";

type Props = {
  amount: number;
  hideAmount?: boolean;
};

export const SumPanel = ({ amount, hideAmount }: Props) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { filter, setFilter, isUserPro, isUserDataFetched } =
    useDividendsContext();
  const theme = useTheme();

  if (!isUserDataFetched) return null;

  return (
    <Box
      px={isLessSm ? 4 : 6}
      py={4}
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.subtle,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body1">
          {t("dividends.calendarPage.sum")}:{" "}
        </Typography>
        <BlurredTypography
          shouldBlur={!isUserPro && hideAmount}
          blur={6}
          variant="body1"
        >
          {toCurrency(
            !isUserPro && hideAmount ? generateRandomNumber(0, amount) : amount
          )}
        </BlurredTypography>
      </Box>
      <Switcher
        checked={filter.includePrediction}
        disabled={!isUserPro}
        onChange={() => {
          setFilter({
            ...filter,
            includePrediction: !filter.includePrediction,
          });
        }}
        labelPlacement="start"
        label={
          <Box display="flex" alignItems="center" gap={2} mr={2}>
            {!isUserPro && <LockIcon />}
            {t("dividends.filters.prediction")}
          </Box>
        }
        switchName="include-prediction"
        sx={{
          "& .MuiSwitch-root": {
            marginRight: 0,
          },
        }}
      />
    </Box>
  );
};
