import { UseQueryOptions } from "react-query";

import { buildSecurityCommissionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const validateSecurityCommissions = async (
  token: string | undefined
) => {
  const apiInstance = buildSecurityCommissionsApi(token);
  const { data } = await apiInstance.validateSecurityCommissions();
  return data;
};

export const useValidateSecurityCommissions = (
  options?: UseQueryOptions<unknown, AxiosApiError, unknown>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SECURITYCOMMISSIONS_VALIDATE_SECURITY_COMMISSIONS],
    ({ token }) => validateSecurityCommissions(token),
    options
  );
};
