import React, { useEffect, useState } from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import {
  Box,
  FormLabel,
  TextField,
  TextFieldProps,
  Typography,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as EyeIcon } from "@icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "@icons/eye-cross.svg";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  padding-top: ${({ theme }) => theme.spacing(1)};
`;

const StyledLinearProgress = styled(LinearProgress)<{
  passwordstrength: number;
}>(({ passwordstrength, theme }) => ({
  marginTop: theme.spacing(2.5),
  position: "relative",
  backgroundColor: theme.palette.background.neutralDark,

  "& .MuiLinearProgress-barColorPrimary": {
    background:
      passwordstrength > 80
        ? theme.palette.success.dark
        : theme.palette.warning.main,
  },
  ":before": {
    content: "''",
    display: "block",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    background: `linear-gradient(to right, transparent 0%, ${Array(3)
      .fill(0)
      .map(
        (_, i) =>
          `transparent calc(${(i + 1) * 25}% - 3px), ${
            theme.palette.common.white
          } calc(${(i + 1) * 25}% - 3px), ${theme.palette.common.white} calc(${
            (i + 1) * 25
          }% + 3px), transparent calc(${(i + 1) * 25}% + 3px)`
      )
      .join(", ")}, transparent 100%)`,
  },
}));

const Label = styled(FormLabel)(({ theme }) => ({
  display: "inline-block",
  fontSize: "14px",
  lineHeight: "22px",
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  width: "100%",
}));

interface Props {
  name: string;
  testId?: string;
  optional?: boolean;
  showPasswordStrength?: boolean;
}

type FormInputProps<T extends FieldValues> = TextFieldProps &
  Props &
  UseControllerProps<T>;

const evaluatePasswordStrength = (password: string) => {
  return import("zxcvbn").then(({ default: zxcvbn }) => zxcvbn(password).score);
};

export const RHFPassword = <T extends FieldValues>(
  props: FormInputProps<T>
): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const {
    control,
    name,
    defaultValue,
    label = "",
    variant = "outlined",
    testId = "text-input",
    optional = false,
    type,
    showPasswordStrength,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController<T>({ control, name, defaultValue });

  useEffect(() => {
    evaluatePasswordStrength(field.value).then((score) => {
      setPasswordStrength(score * 25);
    });
  }, [field.value]);

  return (
    <Box>
      {label && (
        <Label htmlFor={name}>
          {typeof label === "string" ? (
            <Typography variant="body2">{label}</Typography>
          ) : (
            label
          )}
          {optional ? (
            <Typography color="textSecondary" component="span" variant="body2">
              {" "}
              (optional)
            </Typography>
          ) : (
            ""
          )}
        </Label>
      )}
      <TextField
        {...field}
        error={!!error}
        value={field.value || ""}
        type={showPassword ? "text" : "password"}
        variant={variant}
        inputProps={{ "data-testid": testId }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="toggle-password-visibility"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                size="small"
                sx={{ marginRight: (theme) => theme.spacing(-2) }}
              >
                {showPassword ? (
                  <Box color="common.N600" display="flex">
                    <EyeIcon width="24px" height="24px" />
                  </Box>
                ) : (
                  <Box color="common.N600" display="flex">
                    <EyeOffIcon width="24px" height="24px" />
                  </Box>
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {showPasswordStrength && (
        <StyledLinearProgress
          passwordstrength={passwordStrength}
          variant="determinate"
          value={passwordStrength}
          color="primary"
        />
      )}

      {error?.message && (
        <Error>
          <Typography
            variant="caption"
            color="inherit"
            data-testid="rhf-textfield-error"
          >
            {error.message}
          </Typography>
        </Error>
      )}
    </Box>
  );
};
