import { UseQueryOptions } from "react-query";
import { CommentsApiShowCommentRequest, Comment } from "@generated/apiv1";

import { buildCommentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showComment = async (
  token: string | undefined,
  params: CommentsApiShowCommentRequest
) => {
  const apiInstance = buildCommentsApi(token);
  const { data } = await apiInstance.showComment(params);
  return data;
};

export const useShowComment = (
  params: CommentsApiShowCommentRequest,
  options?: UseQueryOptions<Comment, AxiosApiError, Comment>
) => {
  return useAuthQuery(
    [CACHE_KEYS.COMMENTS_SHOW_COMMENT, params],
    ({ token }) => showComment(token, params),
    options
  );
};
