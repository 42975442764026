/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateSplit } from '../model';
// @ts-ignore
import { Split } from '../model';
// @ts-ignore
import { SplitList } from '../model';
// @ts-ignore
import { UpdateSplit } from '../model';
/**
 * SplitsApi - axios parameter creator
 * @export
 */
export const SplitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary apply executable splits to a depot without filtering executed splits (the default)
         * @param {number} id ID of the depot
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySplitsForDepot: async (id: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applySplitsForDepot', 'id', id)
            const localVarPath = `/api/v3/splits/depots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterExcludeExecuted !== undefined) {
                localVarQueryParameter['filter[exclude_executed]'] = filterExcludeExecuted;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary apply executable splits to a set of depots
         * @param {number} filterId Apply splits to the given depot IDs
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySplitsForDepots: async (filterId: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('applySplitsForDepots', 'filterId', filterId)
            const localVarPath = `/api/v3/splits/depots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }

            if (filterExcludeExecuted !== undefined) {
                localVarQueryParameter['filter[exclude_executed]'] = filterExcludeExecuted;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary batch splits
         * @param {number} [limit] Only imports the given number of isins
         * @param {boolean} [filterOnlyNew] Only imports isins previously not imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchImportSplits: async (limit?: number, filterOnlyNew?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/splits/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filterOnlyNew !== undefined) {
                localVarQueryParameter['filter[only_new]'] = filterOnlyNew;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new split
         * @param {CreateSplit} createSplit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crateSplit: async (createSplit: CreateSplit, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSplit' is not null or undefined
            assertParamExists('crateSplit', 'createSplit', createSplit)
            const localVarPath = `/api/v3/splits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSplit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a split
         * @param {string} id The split ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSplit: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSplit', 'id', id)
            const localVarPath = `/api/v3/splits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a split
         * @param {string} id ID of the split
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplit: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSplit', 'id', id)
            const localVarPath = `/api/v3/splits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get executable splits for a depot
         * @param {number} id ID of the depot
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitsForDepot: async (id: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSplitsForDepot', 'id', id)
            const localVarPath = `/api/v3/splits/depots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterExcludeExecuted !== undefined) {
                localVarQueryParameter['filter[exclude_executed]'] = filterExcludeExecuted;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get executable splits for a set of depots
         * @param {number} filterId Get splits for the given depot IDs
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitsForDepots: async (filterId: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('getSplitsForDepots', 'filterId', filterId)
            const localVarPath = `/api/v3/splits/depots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }

            if (filterExcludeExecuted !== undefined) {
                localVarQueryParameter['filter[exclude_executed]'] = filterExcludeExecuted;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary import splits
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSplitByIsin: async (isin: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('importSplitByIsin', 'isin', isin)
            const localVarPath = `/api/v3/splits/{isin}/import`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list and find splits
         * @param {string} [filterIsin] Filter splits by ISINs
         * @param {ShowSplitsFilterStatusEnum} [filterStatus] Filter by status, e.g. to see deleted records; default is active
         * @param {string} [filterFrom] Show splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Show splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSplits: async (filterIsin?: string, filterStatus?: ShowSplitsFilterStatusEnum, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/splits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterIsin !== undefined) {
                localVarQueryParameter['filter[isin]'] = filterIsin;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a split
         * @param {string} id The split ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateSplit} updateSplit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSplit: async (id: string, updateSplit: UpdateSplit, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSplit', 'id', id)
            // verify required parameter 'updateSplit' is not null or undefined
            assertParamExists('updateSplit', 'updateSplit', updateSplit)
            const localVarPath = `/api/v3/splits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSplit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SplitsApi - functional programming interface
 * @export
 */
export const SplitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SplitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary apply executable splits to a depot without filtering executed splits (the default)
         * @param {number} id ID of the depot
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applySplitsForDepot(id: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applySplitsForDepot(id, filterExcludeExecuted, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.applySplitsForDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary apply executable splits to a set of depots
         * @param {number} filterId Apply splits to the given depot IDs
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applySplitsForDepots(filterId: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applySplitsForDepots(filterId, filterExcludeExecuted, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.applySplitsForDepots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary batch splits
         * @param {number} [limit] Only imports the given number of isins
         * @param {boolean} [filterOnlyNew] Only imports isins previously not imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchImportSplits(limit?: number, filterOnlyNew?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchImportSplits(limit, filterOnlyNew, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.batchImportSplits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create a new split
         * @param {CreateSplit} createSplit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crateSplit(createSplit: CreateSplit, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Split>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crateSplit(createSplit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.crateSplit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a split
         * @param {string} id The split ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSplit(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSplit(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.deleteSplit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a split
         * @param {string} id ID of the split
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSplit(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Split>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSplit(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.getSplit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get executable splits for a depot
         * @param {number} id ID of the depot
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSplitsForDepot(id: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSplitsForDepot(id, filterExcludeExecuted, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.getSplitsForDepot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get executable splits for a set of depots
         * @param {number} filterId Get splits for the given depot IDs
         * @param {boolean} [filterExcludeExecuted] Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
         * @param {string} [filterFrom] Include only splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Include only splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSplitsForDepots(filterId: number, filterExcludeExecuted?: boolean, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSplitsForDepots(filterId, filterExcludeExecuted, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.getSplitsForDepots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary import splits
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importSplitByIsin(isin: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importSplitByIsin(isin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.importSplitByIsin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list and find splits
         * @param {string} [filterIsin] Filter splits by ISINs
         * @param {ShowSplitsFilterStatusEnum} [filterStatus] Filter by status, e.g. to see deleted records; default is active
         * @param {string} [filterFrom] Show splits starting from the given date, ISO date such as 2017-07-21
         * @param {string} [filterTo] Show splits up to the given date, ISO date such as 2017-07-21
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showSplits(filterIsin?: string, filterStatus?: ShowSplitsFilterStatusEnum, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showSplits(filterIsin, filterStatus, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.showSplits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a split
         * @param {string} id The split ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateSplit} updateSplit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSplit(id: string, updateSplit: UpdateSplit, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Split>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSplit(id, updateSplit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SplitsApi.updateSplit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SplitsApi - factory interface
 * @export
 */
export const SplitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SplitsApiFp(configuration)
    return {
        /**
         * 
         * @summary apply executable splits to a depot without filtering executed splits (the default)
         * @param {SplitsApiApplySplitsForDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySplitsForDepot(requestParameters: SplitsApiApplySplitsForDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.applySplitsForDepot(requestParameters.id, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary apply executable splits to a set of depots
         * @param {SplitsApiApplySplitsForDepotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySplitsForDepots(requestParameters: SplitsApiApplySplitsForDepotsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.applySplitsForDepots(requestParameters.filterId, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary batch splits
         * @param {SplitsApiBatchImportSplitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchImportSplits(requestParameters: SplitsApiBatchImportSplitsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.batchImportSplits(requestParameters.limit, requestParameters.filterOnlyNew, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new split
         * @param {SplitsApiCrateSplitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crateSplit(requestParameters: SplitsApiCrateSplitRequest, options?: RawAxiosRequestConfig): AxiosPromise<Split> {
            return localVarFp.crateSplit(requestParameters.createSplit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a split
         * @param {SplitsApiDeleteSplitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSplit(requestParameters: SplitsApiDeleteSplitRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSplit(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a split
         * @param {SplitsApiGetSplitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplit(requestParameters: SplitsApiGetSplitRequest, options?: RawAxiosRequestConfig): AxiosPromise<Split> {
            return localVarFp.getSplit(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get executable splits for a depot
         * @param {SplitsApiGetSplitsForDepotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitsForDepot(requestParameters: SplitsApiGetSplitsForDepotRequest, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.getSplitsForDepot(requestParameters.id, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get executable splits for a set of depots
         * @param {SplitsApiGetSplitsForDepotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitsForDepots(requestParameters: SplitsApiGetSplitsForDepotsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.getSplitsForDepots(requestParameters.filterId, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary import splits
         * @param {SplitsApiImportSplitByIsinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSplitByIsin(requestParameters: SplitsApiImportSplitByIsinRequest, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.importSplitByIsin(requestParameters.isin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list and find splits
         * @param {SplitsApiShowSplitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSplits(requestParameters: SplitsApiShowSplitsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SplitList> {
            return localVarFp.showSplits(requestParameters.filterIsin, requestParameters.filterStatus, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a split
         * @param {SplitsApiUpdateSplitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSplit(requestParameters: SplitsApiUpdateSplitRequest, options?: RawAxiosRequestConfig): AxiosPromise<Split> {
            return localVarFp.updateSplit(requestParameters.id, requestParameters.updateSplit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applySplitsForDepot operation in SplitsApi.
 * @export
 * @interface SplitsApiApplySplitsForDepotRequest
 */
export interface SplitsApiApplySplitsForDepotRequest {
    /**
     * ID of the depot
     * @type {number}
     * @memberof SplitsApiApplySplitsForDepot
     */
    readonly id: number

    /**
     * Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
     * @type {boolean}
     * @memberof SplitsApiApplySplitsForDepot
     */
    readonly filterExcludeExecuted?: boolean

    /**
     * Include only splits starting from the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiApplySplitsForDepot
     */
    readonly filterFrom?: string

    /**
     * Include only splits up to the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiApplySplitsForDepot
     */
    readonly filterTo?: string
}

/**
 * Request parameters for applySplitsForDepots operation in SplitsApi.
 * @export
 * @interface SplitsApiApplySplitsForDepotsRequest
 */
export interface SplitsApiApplySplitsForDepotsRequest {
    /**
     * Apply splits to the given depot IDs
     * @type {number}
     * @memberof SplitsApiApplySplitsForDepots
     */
    readonly filterId: number

    /**
     * Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
     * @type {boolean}
     * @memberof SplitsApiApplySplitsForDepots
     */
    readonly filterExcludeExecuted?: boolean

    /**
     * Include only splits starting from the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiApplySplitsForDepots
     */
    readonly filterFrom?: string

    /**
     * Include only splits up to the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiApplySplitsForDepots
     */
    readonly filterTo?: string
}

/**
 * Request parameters for batchImportSplits operation in SplitsApi.
 * @export
 * @interface SplitsApiBatchImportSplitsRequest
 */
export interface SplitsApiBatchImportSplitsRequest {
    /**
     * Only imports the given number of isins
     * @type {number}
     * @memberof SplitsApiBatchImportSplits
     */
    readonly limit?: number

    /**
     * Only imports isins previously not imported
     * @type {boolean}
     * @memberof SplitsApiBatchImportSplits
     */
    readonly filterOnlyNew?: boolean
}

/**
 * Request parameters for crateSplit operation in SplitsApi.
 * @export
 * @interface SplitsApiCrateSplitRequest
 */
export interface SplitsApiCrateSplitRequest {
    /**
     * 
     * @type {CreateSplit}
     * @memberof SplitsApiCrateSplit
     */
    readonly createSplit: CreateSplit
}

/**
 * Request parameters for deleteSplit operation in SplitsApi.
 * @export
 * @interface SplitsApiDeleteSplitRequest
 */
export interface SplitsApiDeleteSplitRequest {
    /**
     * The split ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {string}
     * @memberof SplitsApiDeleteSplit
     */
    readonly id: string
}

/**
 * Request parameters for getSplit operation in SplitsApi.
 * @export
 * @interface SplitsApiGetSplitRequest
 */
export interface SplitsApiGetSplitRequest {
    /**
     * ID of the split
     * @type {string}
     * @memberof SplitsApiGetSplit
     */
    readonly id: string
}

/**
 * Request parameters for getSplitsForDepot operation in SplitsApi.
 * @export
 * @interface SplitsApiGetSplitsForDepotRequest
 */
export interface SplitsApiGetSplitsForDepotRequest {
    /**
     * ID of the depot
     * @type {number}
     * @memberof SplitsApiGetSplitsForDepot
     */
    readonly id: number

    /**
     * Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
     * @type {boolean}
     * @memberof SplitsApiGetSplitsForDepot
     */
    readonly filterExcludeExecuted?: boolean

    /**
     * Include only splits starting from the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiGetSplitsForDepot
     */
    readonly filterFrom?: string

    /**
     * Include only splits up to the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiGetSplitsForDepot
     */
    readonly filterTo?: string
}

/**
 * Request parameters for getSplitsForDepots operation in SplitsApi.
 * @export
 * @interface SplitsApiGetSplitsForDepotsRequest
 */
export interface SplitsApiGetSplitsForDepotsRequest {
    /**
     * Get splits for the given depot IDs
     * @type {number}
     * @memberof SplitsApiGetSplitsForDepots
     */
    readonly filterId: number

    /**
     * Exclude splits that have been executed before. Note that the split may have                  been executed partially; i.e. the split has not been applied to all bookings. Default is false.
     * @type {boolean}
     * @memberof SplitsApiGetSplitsForDepots
     */
    readonly filterExcludeExecuted?: boolean

    /**
     * Include only splits starting from the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiGetSplitsForDepots
     */
    readonly filterFrom?: string

    /**
     * Include only splits up to the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiGetSplitsForDepots
     */
    readonly filterTo?: string
}

/**
 * Request parameters for importSplitByIsin operation in SplitsApi.
 * @export
 * @interface SplitsApiImportSplitByIsinRequest
 */
export interface SplitsApiImportSplitByIsinRequest {
    /**
     * 
     * @type {string}
     * @memberof SplitsApiImportSplitByIsin
     */
    readonly isin: string
}

/**
 * Request parameters for showSplits operation in SplitsApi.
 * @export
 * @interface SplitsApiShowSplitsRequest
 */
export interface SplitsApiShowSplitsRequest {
    /**
     * Filter splits by ISINs
     * @type {string}
     * @memberof SplitsApiShowSplits
     */
    readonly filterIsin?: string

    /**
     * Filter by status, e.g. to see deleted records; default is active
     * @type {'active' | 'deleted'}
     * @memberof SplitsApiShowSplits
     */
    readonly filterStatus?: ShowSplitsFilterStatusEnum

    /**
     * Show splits starting from the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiShowSplits
     */
    readonly filterFrom?: string

    /**
     * Show splits up to the given date, ISO date such as 2017-07-21
     * @type {string}
     * @memberof SplitsApiShowSplits
     */
    readonly filterTo?: string
}

/**
 * Request parameters for updateSplit operation in SplitsApi.
 * @export
 * @interface SplitsApiUpdateSplitRequest
 */
export interface SplitsApiUpdateSplitRequest {
    /**
     * The split ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {string}
     * @memberof SplitsApiUpdateSplit
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSplit}
     * @memberof SplitsApiUpdateSplit
     */
    readonly updateSplit: UpdateSplit
}

/**
 * SplitsApi - object-oriented interface
 * @export
 * @class SplitsApi
 * @extends {BaseAPI}
 */
export class SplitsApi extends BaseAPI {
    /**
     * 
     * @summary apply executable splits to a depot without filtering executed splits (the default)
     * @param {SplitsApiApplySplitsForDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public applySplitsForDepot(requestParameters: SplitsApiApplySplitsForDepotRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).applySplitsForDepot(requestParameters.id, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary apply executable splits to a set of depots
     * @param {SplitsApiApplySplitsForDepotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public applySplitsForDepots(requestParameters: SplitsApiApplySplitsForDepotsRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).applySplitsForDepots(requestParameters.filterId, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary batch splits
     * @param {SplitsApiBatchImportSplitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public batchImportSplits(requestParameters: SplitsApiBatchImportSplitsRequest = {}, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).batchImportSplits(requestParameters.limit, requestParameters.filterOnlyNew, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new split
     * @param {SplitsApiCrateSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public crateSplit(requestParameters: SplitsApiCrateSplitRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).crateSplit(requestParameters.createSplit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a split
     * @param {SplitsApiDeleteSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public deleteSplit(requestParameters: SplitsApiDeleteSplitRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).deleteSplit(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a split
     * @param {SplitsApiGetSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public getSplit(requestParameters: SplitsApiGetSplitRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).getSplit(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get executable splits for a depot
     * @param {SplitsApiGetSplitsForDepotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public getSplitsForDepot(requestParameters: SplitsApiGetSplitsForDepotRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).getSplitsForDepot(requestParameters.id, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get executable splits for a set of depots
     * @param {SplitsApiGetSplitsForDepotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public getSplitsForDepots(requestParameters: SplitsApiGetSplitsForDepotsRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).getSplitsForDepots(requestParameters.filterId, requestParameters.filterExcludeExecuted, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary import splits
     * @param {SplitsApiImportSplitByIsinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public importSplitByIsin(requestParameters: SplitsApiImportSplitByIsinRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).importSplitByIsin(requestParameters.isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list and find splits
     * @param {SplitsApiShowSplitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public showSplits(requestParameters: SplitsApiShowSplitsRequest = {}, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).showSplits(requestParameters.filterIsin, requestParameters.filterStatus, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a split
     * @param {SplitsApiUpdateSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitsApi
     */
    public updateSplit(requestParameters: SplitsApiUpdateSplitRequest, options?: RawAxiosRequestConfig) {
        return SplitsApiFp(this.configuration).updateSplit(requestParameters.id, requestParameters.updateSplit, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ShowSplitsFilterStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted'
} as const;
export type ShowSplitsFilterStatusEnum = typeof ShowSplitsFilterStatusEnum[keyof typeof ShowSplitsFilterStatusEnum];
