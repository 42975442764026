import { UseMutationOptions } from "react-query";
import { StringList } from "@generated/apiv1";

import { buildMasterDataApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const autoCurateFundClassification = async (
  token: string | undefined
) => {
  const apiInstance = buildMasterDataApi(token);
  const { data } = await apiInstance.autoCurateFundClassification();
  return data;
};

export const useAutoCurateFundClassification = (
  options?: UseMutationOptions<StringList, AxiosApiError, unknown>
) => {
  return useAuthMutation(autoCurateFundClassification, options, false);
};
