/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BankMessage } from '../model';
// @ts-ignore
import { BankMessageList } from '../model';
// @ts-ignore
import { CreateBankMessage } from '../model';
// @ts-ignore
import { UpdateBankMessage } from '../model';
/**
 * BankMessagesApi - axios parameter creator
 * @export
 */
export const BankMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new bank message
         * @param {CreateBankMessage} createBankMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankMessage: async (createBankMessage: CreateBankMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankMessage' is not null or undefined
            assertParamExists('createBankMessage', 'createBankMessage', createBankMessage)
            const localVarPath = `/api/v3/bank_messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a bank message
         * @param {number} id The bank message ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankMessage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankMessage', 'id', id)
            const localVarPath = `/api/v3/bank_messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary filter bank messages
         * @param {string} [filterBics] Comma separated values of bics or bic groupsExample: \&#39;filter[bics]&#x3D;bic1,bic2\&#39;
         * @param {GetBankMessagesFilterStatusEnum} [filterStatus] Status of the bank messages to be retrieved. Defaults to ANY if omittedExample \&#39;filter[status]&#x3D;ANY\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankMessages: async (filterBics?: string, filterStatus?: GetBankMessagesFilterStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/bank_messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterBics !== undefined) {
                localVarQueryParameter['filter[bics]'] = filterBics;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter[status]'] = filterStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a bank message
         * @param {number} id The bank message ID
         * @param {UpdateBankMessage} updateBankMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankMessage: async (id: number, updateBankMessage: UpdateBankMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBankMessage', 'id', id)
            // verify required parameter 'updateBankMessage' is not null or undefined
            assertParamExists('updateBankMessage', 'updateBankMessage', updateBankMessage)
            const localVarPath = `/api/v3/bank_messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankMessagesApi - functional programming interface
 * @export
 */
export const BankMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new bank message
         * @param {CreateBankMessage} createBankMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankMessage(createBankMessage: CreateBankMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankMessage(createBankMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankMessagesApi.createBankMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a bank message
         * @param {number} id The bank message ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankMessage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankMessage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankMessagesApi.deleteBankMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary filter bank messages
         * @param {string} [filterBics] Comma separated values of bics or bic groupsExample: \&#39;filter[bics]&#x3D;bic1,bic2\&#39;
         * @param {GetBankMessagesFilterStatusEnum} [filterStatus] Status of the bank messages to be retrieved. Defaults to ANY if omittedExample \&#39;filter[status]&#x3D;ANY\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankMessages(filterBics?: string, filterStatus?: GetBankMessagesFilterStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankMessageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankMessages(filterBics, filterStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankMessagesApi.getBankMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a bank message
         * @param {number} id The bank message ID
         * @param {UpdateBankMessage} updateBankMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBankMessage(id: number, updateBankMessage: UpdateBankMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBankMessage(id, updateBankMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankMessagesApi.updateBankMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankMessagesApi - factory interface
 * @export
 */
export const BankMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new bank message
         * @param {BankMessagesApiCreateBankMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankMessage(requestParameters: BankMessagesApiCreateBankMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankMessage> {
            return localVarFp.createBankMessage(requestParameters.createBankMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a bank message
         * @param {BankMessagesApiDeleteBankMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankMessage(requestParameters: BankMessagesApiDeleteBankMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankMessage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary filter bank messages
         * @param {BankMessagesApiGetBankMessagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankMessages(requestParameters: BankMessagesApiGetBankMessagesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BankMessageList> {
            return localVarFp.getBankMessages(requestParameters.filterBics, requestParameters.filterStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a bank message
         * @param {BankMessagesApiUpdateBankMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankMessage(requestParameters: BankMessagesApiUpdateBankMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankMessage> {
            return localVarFp.updateBankMessage(requestParameters.id, requestParameters.updateBankMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createBankMessage operation in BankMessagesApi.
 * @export
 * @interface BankMessagesApiCreateBankMessageRequest
 */
export interface BankMessagesApiCreateBankMessageRequest {
    /**
     * 
     * @type {CreateBankMessage}
     * @memberof BankMessagesApiCreateBankMessage
     */
    readonly createBankMessage: CreateBankMessage
}

/**
 * Request parameters for deleteBankMessage operation in BankMessagesApi.
 * @export
 * @interface BankMessagesApiDeleteBankMessageRequest
 */
export interface BankMessagesApiDeleteBankMessageRequest {
    /**
     * The bank message ID
     * @type {number}
     * @memberof BankMessagesApiDeleteBankMessage
     */
    readonly id: number
}

/**
 * Request parameters for getBankMessages operation in BankMessagesApi.
 * @export
 * @interface BankMessagesApiGetBankMessagesRequest
 */
export interface BankMessagesApiGetBankMessagesRequest {
    /**
     * Comma separated values of bics or bic groupsExample: \&#39;filter[bics]&#x3D;bic1,bic2\&#39;
     * @type {string}
     * @memberof BankMessagesApiGetBankMessages
     */
    readonly filterBics?: string

    /**
     * Status of the bank messages to be retrieved. Defaults to ANY if omittedExample \&#39;filter[status]&#x3D;ANY\&#39;
     * @type {'ACTIVE' | 'ARCHIVED' | 'ANY'}
     * @memberof BankMessagesApiGetBankMessages
     */
    readonly filterStatus?: GetBankMessagesFilterStatusEnum
}

/**
 * Request parameters for updateBankMessage operation in BankMessagesApi.
 * @export
 * @interface BankMessagesApiUpdateBankMessageRequest
 */
export interface BankMessagesApiUpdateBankMessageRequest {
    /**
     * The bank message ID
     * @type {number}
     * @memberof BankMessagesApiUpdateBankMessage
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBankMessage}
     * @memberof BankMessagesApiUpdateBankMessage
     */
    readonly updateBankMessage: UpdateBankMessage
}

/**
 * BankMessagesApi - object-oriented interface
 * @export
 * @class BankMessagesApi
 * @extends {BaseAPI}
 */
export class BankMessagesApi extends BaseAPI {
    /**
     * 
     * @summary create a new bank message
     * @param {BankMessagesApiCreateBankMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankMessagesApi
     */
    public createBankMessage(requestParameters: BankMessagesApiCreateBankMessageRequest, options?: RawAxiosRequestConfig) {
        return BankMessagesApiFp(this.configuration).createBankMessage(requestParameters.createBankMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a bank message
     * @param {BankMessagesApiDeleteBankMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankMessagesApi
     */
    public deleteBankMessage(requestParameters: BankMessagesApiDeleteBankMessageRequest, options?: RawAxiosRequestConfig) {
        return BankMessagesApiFp(this.configuration).deleteBankMessage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary filter bank messages
     * @param {BankMessagesApiGetBankMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankMessagesApi
     */
    public getBankMessages(requestParameters: BankMessagesApiGetBankMessagesRequest = {}, options?: RawAxiosRequestConfig) {
        return BankMessagesApiFp(this.configuration).getBankMessages(requestParameters.filterBics, requestParameters.filterStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a bank message
     * @param {BankMessagesApiUpdateBankMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankMessagesApi
     */
    public updateBankMessage(requestParameters: BankMessagesApiUpdateBankMessageRequest, options?: RawAxiosRequestConfig) {
        return BankMessagesApiFp(this.configuration).updateBankMessage(requestParameters.id, requestParameters.updateBankMessage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBankMessagesFilterStatusEnum = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
    ANY: 'ANY'
} as const;
export type GetBankMessagesFilterStatusEnum = typeof GetBankMessagesFilterStatusEnum[keyof typeof GetBankMessagesFilterStatusEnum];
