import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import truncate from "lodash/truncate";
import { useGetWatchlistEntry } from "@api/v1/hooks/useGetWatchlistEntry";
import { useGetSingleWatchlist } from "@api/v1/hooks/useGetSingleWatchlist";
import { PageLayout, Tab, Tabs, GainLossPercents } from "@components";
import {
  ComparisonValue,
  SelectComparison,
} from "@components/Chart/BaseChartTools/SelectComparison";
import { FundamentalsPageByIsin } from "@features/fundamentals/FundamentalsPageByIsin";
import { intervalToTimeRange, toCurrency } from "@helpers";
import { QuoteChartContainer } from "@components/Chart/QuoteChartContainer";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { SecuritiesSinglePageHeader } from "./components/SecuritiesSinglePageHeader";

export const tabKeys = {
  fundamentals: "fundamentals",
  news: "news",
};

export const SecuritiesSinglePage = () => {
  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { watchlistId, securityId } = useParams<"watchlistId" | "securityId">();
  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );
  const [comparedTicker, setComparedTicker] = React.useState<
    ComparisonValue | undefined
  >();

  const { t } = useTranslation();

  const { data: watchlist } = useGetSingleWatchlist(watchlistId!);

  const { data: security } = useGetWatchlistEntry(
    Number.parseInt(securityId!, 10),
    { enabled: Boolean(securityId), keepPreviousData: true }
  );

  const backURL = `/watchlists/${watchlistId}`;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: "/",
      text: "Home",
    },
    {
      to: "/watchlists",
      text: "Watchlists",
    },
    {
      to: backURL,
      text: truncate(watchlist?.name),
    },
    {
      text: truncate(security?.name),
    },
  ];

  const securityForLogos = useMemo(() => {
    if (!security) return [];
    return [security];
  }, [security]);

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  const handleTabChange = (key: string) => {
    setSearchParams({ tab: key });
  };

  const logo = useSecurityImages(security ? securityForLogos : []);

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <SecuritiesSinglePageHeader
        securityId={securityId!}
        watchlistId={watchlistId!}
        securityName={security?.name ?? ""}
        securityIsin={security?.isin ?? ""}
        image={logo[security?.isin || ""] || logo[security?.tickerSymbol || ""]}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        mb={6}
        gap={isDownMd ? 6 : 3}
        flexDirection={isDownMd ? "column" : "row"}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" mb={3}>
            {t("watchlistEntry.details.gainLossStatistics.currentQuote")}
          </Typography>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography fontSize="36px">
              {toCurrency(
                security?.quote?.last ?? 0,
                security?.quote?.currency
              )}
            </Typography>
            <GainLossPercents value={security?.quote?.changePercentAbs ?? 0} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <RangeSelector
            range={selectedRange === "custom" ? range : undefined}
            onSelect={setSelectedRange}
            onCustomSelect={onCustomRangeSelect}
            intervalType={selectedRange}
          />
        </Box>
      </Box>

      <QuoteChartContainer
        mainQuoteProvider={security?.quote?.quoteProvider ?? ""}
        mainName={security?.name ?? ""}
        mainTickerSymbol={security?.tickerSymbol ?? ""}
        comparedName={comparedTicker?.label}
        comparedQuoteProvider={comparedTicker?.quoteProvider}
        comparedTickerSymbol={comparedTicker?.value}
        range={range}
        selectedInterval={selectedRange}
      />

      <Box my={6} display="flex" justifyContent="flex-end" width="100%">
        <Box width={isDownMd ? "100%" : 334}>
          <SelectComparison
            value={comparedTicker}
            onChange={setComparedTicker}
          />
        </Box>
      </Box>

      <Tabs
        onChange={handleTabChange}
        defaultKey={searchParams.get("tab")}
        variant="scrollable"
      >
        <Tab
          label={t("securitiesSinglePage.tabs.fundamentals")}
          key={tabKeys.fundamentals}
        >
          {security?.tickerSymbol &&
            security?.isin &&
            security?.quote?.quoteProvider && (
              <FundamentalsPageByIsin
                watchlistEntryId={security.id}
                quoteProvider={security?.quote?.quoteProvider}
                tickerSymbol={security?.tickerSymbol}
                isin={security.isin}
              />
            )}
        </Tab>
      </Tabs>
    </PageLayout>
  );
};
