/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InsuranceProviderSpecificContractIdPattern
 */
export interface InsuranceProviderSpecificContractIdPattern {
    /**
     * Technical identifier
     * @type {number}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'id'?: number;
    /**
     * ID of the insurance provider the pattern belongs to
     * @type {number}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'insuranceProviderId'?: number;
    /**
     * The pattern for ID detection
     * @type {string}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'pattern'?: string;
    /**
     * Should we replace parts after ID detection?
     * @type {string}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'replacementPattern'?: string;
    /**
     * The type of ID
     * @type {string}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'type'?: InsuranceProviderSpecificContractIdPatternTypeEnum;
    /**
     * Is the pattern enabled?
     * @type {boolean}
     * @memberof InsuranceProviderSpecificContractIdPattern
     */
    'enabled'?: boolean;
}

export const InsuranceProviderSpecificContractIdPatternTypeEnum = {
    CONTRACT_ID: 'contract_id',
    CUSTOMER_ID: 'customer_id',
    OTHER_ID: 'other_id'
} as const;

export type InsuranceProviderSpecificContractIdPatternTypeEnum = typeof InsuranceProviderSpecificContractIdPatternTypeEnum[keyof typeof InsuranceProviderSpecificContractIdPatternTypeEnum];


