import { UseQueryOptions } from "react-query";
import {
  UserReportsApiActiveUsersByBankRequest,
  ActiveUsersByBank,
} from "@generated/apiv3";

import { buildUserReportsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const activeUsersByBank = async (
  token: string | undefined,
  params?: UserReportsApiActiveUsersByBankRequest
) => {
  const apiInstance = buildUserReportsApi(token);
  const { data } = await apiInstance.activeUsersByBank(params);
  return data;
};

export const useActiveUsersByBank = (
  params?: UserReportsApiActiveUsersByBankRequest,
  options?: UseQueryOptions<ActiveUsersByBank, AxiosApiError, ActiveUsersByBank>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERREPORTS_ACTIVE_USERS_BY_BANK, params],
    ({ token }) => activeUsersByBank(token, params),
    options
  );
};
