import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Trans, useTranslation } from "react-i18next";
import { matchRoutes, useLocation } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import sortBy from "lodash/sortBy";
import { ReactComponent as ErrorIcon } from "@icons/exclude.svg";
import { LoadingButton } from "@components";
import { usePersistValidBookings } from "@features/fileDrop/hooks/usePersistValidBookings";
import {
  FileDrop,
  Props as FileDropProps,
} from "@features/fileDrop/components/FileDrop";
import { UniqueParsingResult } from "@features/fileDrop/components/ParsingResult";
import { ActivityWithBookingItem } from "@features/fileDrop/components/ActivityWithBookingItem";
import { useGetAndValidateBookings } from "@features/fileDrop/hooks/useValidateBookings";
import { ActivityWithBooking } from "@features/fileDrop/helpers/bookingLocator";

const SpacerContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export interface FileDropHandlerProps {
  accountId: number;
  fileDropComponent?: React.FC<FileDropProps>;
}

export const FileDropHandler = ({
  accountId,
  fileDropComponent = FileDrop,
}: FileDropHandlerProps) => {
  const [isParsingFinished, setIsParsingFinished] = useState(false);
  const [removeFailedActivitiesStarted, setRemoveFailedActivitiesStarted] =
    useState(false);
  const isDepot = matchRoutes([{ path: "/depots/*" }], useLocation());

  const { t } = useTranslation();
  const theme = useTheme();
  const [parsingResults, setParsingResults] = useState<UniqueParsingResult[]>(
    []
  );
  const successfulParsingResults = useMemo(
    () => parsingResults.filter((item) => item.successful),
    [parsingResults]
  );
  const unsuccessfulParsingResults = useMemo(
    () => parsingResults.filter((item) => !item.successful),
    [parsingResults]
  );

  const sortedSuccessfulResults = useMemo(
    () =>
      sortBy(
        successfulParsingResults,
        (result) => result.activities?.[0]?.date
      ).reverse(),
    [successfulParsingResults]
  );

  const { data: getAndValidateBookingsResult, isLoading: validatingData } =
    useGetAndValidateBookings(accountId, parsingResults, {
      keepPreviousData: true,
      enabled: isParsingFinished,
    });

  const { persistValidBookings, isLoading: persistingBookings } =
    usePersistValidBookings(isDepot ? `/depots/${accountId}` : "/investments");

  const handlePersistButtonClick = () => {
    persistValidBookings(
      accountId,
      createOrUpdateInvestmentParamsList || [],
      transactionValidationErrorList || []
    );
  };

  const activitiesWithBookings = useMemo(
    () => getAndValidateBookingsResult?.activitiesWithBookings || [],
    [getAndValidateBookingsResult]
  );
  const createOrUpdateInvestmentParamsList =
    getAndValidateBookingsResult?.createOrUpdateInvestmentParamsList;
  const transactionValidationErrorList =
    getAndValidateBookingsResult?.transactionValidationErrorList
      .transactionValidationErrors;

  const handleParsingResult = (results: UniqueParsingResult[]) => {
    setParsingResults((current) => [...current, ...results]);
  };

  const handleRemoveFailedActivities = useCallback(() => {
    setParsingResults((current) =>
      current.reduce((acc, parsingResult) => {
        const found = activitiesWithBookings.filter(
          (item) => item.activity.sourceFilename === parsingResult.id
        );

        const activitiesWithoutErrors = found.filter(
          (item) =>
            !transactionValidationErrorList?.some(
              (error) => item.hash === error.hash
            )
        );
        if (!activitiesWithoutErrors || activitiesWithoutErrors.length === 0) {
          return acc;
        }

        const newActivities = parsingResult.activities?.filter((item) =>
          activitiesWithoutErrors.some(
            (activityWithBooking) =>
              activityWithBooking.id === item.parsingResultId
          )
        );

        return [
          ...acc,
          {
            ...parsingResult,
            activities: newActivities,
          },
        ] as UniqueParsingResult[];
      }, [] as UniqueParsingResult[])
    );
  }, [activitiesWithBookings, transactionValidationErrorList]);

  const handleRemoveActivity = (activity: ActivityWithBooking) => {
    setParsingResults((current) =>
      current.reduce((acc, parsingResult) => {
        if (parsingResult.id === activity.activity.sourceFilename) {
          const newActivities = parsingResult.activities?.filter(
            (item) => item.uuid !== activity.activity.uuid
          );
          if (!newActivities || newActivities.length === 0) {
            return acc;
          }

          return [
            ...acc,
            {
              ...parsingResult,
              activities: newActivities,
            },
          ];
        }
        return [...acc, parsingResult];
      }, [] as UniqueParsingResult[])
    );
  };

  useEffect(() => {
    if (
      removeFailedActivitiesStarted &&
      transactionValidationErrorList?.length
    ) {
      handleRemoveFailedActivities();
    } else {
      setRemoveFailedActivitiesStarted(false);
    }
  }, [
    removeFailedActivitiesStarted,
    transactionValidationErrorList,
    handleRemoveFailedActivities,
  ]);

  return (
    <div>
      <SpacerContainer>
        {fileDropComponent({
          onParsingResult: handleParsingResult,
          setIsParsingFinished,
          isValidating: validatingData,
        })}
      </SpacerContainer>

      {sortedSuccessfulResults.map((parsingResult) => {
        const found = activitiesWithBookings.filter(
          (item) => item.activity.sourceFilename === parsingResult.id
        );
        if (!found.length) return null;

        return (
          <SpacerContainer
            key={`activity-with-booking-item-successful-${parsingResult.id}`}
          >
            <ActivityWithBookingItem
              activitiesWithBooking={found}
              transactionValidationErrors={
                transactionValidationErrorList?.filter((error) =>
                  found.some((activity) => activity.hash === error.hash)
                ) || []
              }
              fileName={parsingResult.file.name}
              onRemove={handleRemoveActivity}
            />
          </SpacerContainer>
        );
      })}

      {Boolean(unsuccessfulParsingResults?.length) && (
        <>
          <Box
            p={3}
            mb={3}
            borderRadius="8px"
            border={`1px solid ${theme.palette.border.error}`}
            bgcolor={theme.palette.background.error}
          >
            <MuiAccordion
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <MuiAccordionSummary
                expandIcon={
                  <ExpandMoreIcon data-testid="expand-failed-files" />
                }
                sx={{
                  padding: 0,
                  minHeight: "auto",
                  height: "32px",

                  "&.Mui-expanded": {
                    height: "32px",
                    minHeight: "auto",
                  },
                }}
              >
                <Box display="flex" alignItems="center" gap={5.5} ml={2}>
                  <Box display="flex" color="error.main">
                    <ErrorIcon width={20} height={20} />
                  </Box>
                  <Typography variant="body1">
                    <Trans
                      i18nKey="transactionUpload.failedFiles"
                      values={{ number: unsuccessfulParsingResults.length }}
                    >
                      {t("transactionUpload.failedFiles")}
                    </Trans>
                  </Typography>
                </Box>
              </MuiAccordionSummary>
              <AccordionDetails>
                <ul style={{ marginBottom: 0, paddingLeft: "12px" }}>
                  {unsuccessfulParsingResults.map((parsingResult, ix) => (
                    <li>
                      <Typography
                        key={`activity-with-booking-item-unsuccessful-${ix}`}
                        noWrap
                        fontWeight={400}
                        mt={ix !== 0 ? 1 : 0}
                      >
                        {parsingResult.file.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </MuiAccordion>
          </Box>
        </>
      )}

      {activitiesWithBookings.length > 0 && (
        <SpacerContainer>
          <Box display="flex" gap={3}>
            {Boolean(transactionValidationErrorList?.length) && (
              <LoadingButton
                variant="contained"
                color="secondary"
                disabled={removeFailedActivitiesStarted}
                isLoading={removeFailedActivitiesStarted}
                onClick={() => setRemoveFailedActivitiesStarted(true)}
              >
                {t("transactionUpload.ignoreErrors")}
              </LoadingButton>
            )}
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handlePersistButtonClick()}
              disabled={
                !isParsingFinished ||
                persistingBookings ||
                validatingData ||
                (transactionValidationErrorList &&
                  transactionValidationErrorList.length > 0)
              }
              isLoading={persistingBookings || validatingData}
            >
              {t("transactionUpload.persistData")}
            </LoadingButton>
          </Box>
        </SpacerContainer>
      )}
    </div>
  );
};
