import React, { ChangeEvent } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { Switcher } from "@components";
import { ExportButton } from "@features/investments/components/InvestmentsTable/ExportButton";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

type Props = {
  onShowHistoricChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onShowAggregationChange: (event: ChangeEvent<HTMLInputElement>) => void;
  activeTab?: string;
  accountIds: number[];
  minDate: string;
  maxDate: string;
  showHistoric: boolean;
  showAggregation: boolean;
};

export const TabActions = ({
  activeTab,
  minDate,
  maxDate,
  showHistoric,
  onShowHistoricChange,
  showAggregation,
  onShowAggregationChange,
  accountIds,
}: Props) => {
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const isMore950 = useMediaQuery((theme: Theme) => theme.breakpoints.up(950));

  return (
    <Box display="flex" alignItems="center" gap={6}>
      {isMore950 && activeTab === "investments" && (
        <>
          <Switcher
            checked={showAggregation}
            onChange={onShowAggregationChange}
            label="components.depotsStatistic.showAggregationSwitch"
            switchName="show-aggregation-switch"
          />
          <Switcher
            checked={showHistoric}
            onChange={onShowHistoricChange}
            label="components.depotsStatistic.showHistoricSwitch"
            switchName="show-historic-switch"
          />
        </>
      )}
      {isMore950 && !isSharedPortfolio && (
        <ExportButton
          activeTab={activeTab}
          params={{
            accountId: accountIds,
            includeHistoric: showHistoric,
            minDate: minDate,
            maxDate: maxDate,
          }}
        />
      )}
    </Box>
  );
};
