import React, { useState, CSSProperties } from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RangeValue } from "@components/Chart";

interface Props {
  size?: "normal" | "large";
  defaultValue?: RangeValue;
  onSelect: (value: RangeValue) => void;
  style?: CSSProperties;
}

export const ComparisonChartRangeSelector = ({
  onSelect,
  defaultValue,
  size = "normal",
  style = {},
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<RangeValue>(defaultValue || "5y");

  const handleChange = (value: RangeValue) => {
    onSelect(value);
    setSelected(value);
  };

  return (
    <div style={style}>
      <Select
        data-testid="range-selector"
        value={selected}
        onChange={(event) => handleChange(event.target.value as RangeValue)}
        inputProps={{ "data-testid": "range-selector-input" }}
        fullWidth
      >
        <MenuItem value="3y" data-testid="range-option-3y">
          {t("ranges.years.threeYears")}
        </MenuItem>
        <MenuItem value="5y" data-testid="range-option-5y">
          {t("ranges.years.fiveYears")}
        </MenuItem>
        <MenuItem value="max" data-testid="range-option-max">
          {t("ranges.max")}
        </MenuItem>
      </Select>
    </div>
  );
};
