import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { Booking } from "@generated/apiv1";
import { toRoundedNumber, toCurrency } from "@helpers";

type BookingProps = {
  bookingData: Booking;
};

export const BookingInfo = ({ bookingData }: BookingProps) => {
  const { t } = useTranslation();
  const { numberOfLots, securityPrice, exchangeRate, type, investment } =
    bookingData;
  return (
    <Box display="flex">
      <Typography variant="body2" fontWeight="400">
        <Link
          component={RouterLink}
          to={`/investments/${investment.id}`}
          fontWeight="400"
          variant="body2"
        >
          {investment.name}
        </Link>
        <Typography component="span" mx={1} fontWeight="400" variant="body2">
          /
        </Typography>
        {`${t(`bookingTypes.${type}`)} 
          ${toRoundedNumber(numberOfLots)}x 
          ${toCurrency(securityPrice * exchangeRate, "EUR")}`}
      </Typography>
    </Box>
  );
};
