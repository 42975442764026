import { UseQueryOptions } from "react-query";
import {
  SocialStatisticsApiMostWantedRequest,
  MostWantedStatisticsResult,
} from "@generated/apiv1";

import { buildSocialStatisticsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const mostWanted = async (
  token: string | undefined,
  params: SocialStatisticsApiMostWantedRequest
) => {
  const apiInstance = buildSocialStatisticsApi(token);
  const { data } = await apiInstance.mostWanted(params);
  return data;
};

export const useMostWanted = (
  params: SocialStatisticsApiMostWantedRequest,
  options?: UseQueryOptions<
    MostWantedStatisticsResult,
    AxiosApiError,
    MostWantedStatisticsResult
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SOCIALSTATISTICS_MOST_WANTED, params],
    ({ token }) => mostWanted(token, params),
    options
  );
};
