import { useInvestment } from "@api/v1/hooks/useInvestment";
import { RangeValue } from "@components/Chart/ComparisonChart/types";
import { useHistoricQuotesPerYear } from "../useHistoricQuotesForInvestment";
import { useChartDataBase } from "./useChartDataBase";
import { getDates } from "./getFromDate";
import { mergeHistoricQuotes } from "./mergeHistoricQuotes";

interface Props {
  investmentId?: number;
  range?: RangeValue;
  dataKey1?: string;
  dataKey2?: string;
}
interface Options {
  enabled?: boolean;
}

export const useChartDataByInvestment = (
  { investmentId, range, dataKey1, dataKey2 }: Props,
  { enabled }: Options = { enabled: true }
) => {
  const { fromDate } = getDates(range);

  const {
    data: investment,
    isLoading: investmentIsLoading,
    isIdle: investmentIsIdle,
  } = useInvestment(investmentId, { enabled });

  const {
    data: historicQuotes,
    isLoading: historicQuotesIsLoading,
    isIdle: historicQuotesIsIdle,
  } = useHistoricQuotesPerYear(
    investmentId,
    investment?.tickerSymbolWithCurrency,
    fromDate,
    { enabled }
  );

  const {
    data: chartData,
    securityData,
    isLoading: isChartDataLoading,
    isIdle: isChartDataIdle,
  } = useChartDataBase(
    {
      isin: investment?.isin,
      range,
      dataKey1,
      dataKey2,
    },
    { enabled }
  );

  const isLoading =
    investmentIsLoading || isChartDataLoading || historicQuotesIsLoading;

  const isIdle = investmentIsIdle || isChartDataIdle || historicQuotesIsIdle;
  const data = mergeHistoricQuotes(chartData, historicQuotes);

  return {
    data,
    securityData,
    isLoading,
    isIdle,
  };
};
