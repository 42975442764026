import { UseMutationOptions } from "react-query";
import { WatchlistsApiEditWatchlistRequest, Watchlist } from "@generated/apiv1";

import { buildWatchlistsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const editWatchlist = async (
  token: string | undefined,
  params: WatchlistsApiEditWatchlistRequest
) => {
  const apiInstance = buildWatchlistsApi(token);
  const { data } = await apiInstance.editWatchlist(params);
  return data;
};

export const useEditWatchlist = (
  options?: UseMutationOptions<
    Watchlist,
    AxiosApiError,
    WatchlistsApiEditWatchlistRequest
  >
) => {
  return useAuthMutation(editWatchlist, options, false);
};
