import { UseQueryOptions } from "react-query";
import {
  QuotesApiGetSecuritiesRequest,
  SecuritiesResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSecurities = async (
  token: string | undefined,
  params?: QuotesApiGetSecuritiesRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getSecurities(params);
  return data;
};

export const useGetSecurities = (
  params?: QuotesApiGetSecuritiesRequest,
  options?: UseQueryOptions<
    SecuritiesResponse,
    AxiosApiError,
    SecuritiesResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_SECURITIES, params],
    ({ token }) => getSecurities(token, params),
    options
  );
};
