import { UseQueryOptions } from "react-query";
import {
  QuotesApiGetHistoricQuotesInBatchRequest,
  HistoricQuotesBatchResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getHistoricQuotesInBatch = async (
  token: string | undefined,
  params: QuotesApiGetHistoricQuotesInBatchRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getHistoricQuotesInBatch(params);
  return data;
};

export const useGetHistoricQuotesInBatch = (
  params: QuotesApiGetHistoricQuotesInBatchRequest,
  options?: UseQueryOptions<
    HistoricQuotesBatchResponse,
    AxiosApiError,
    HistoricQuotesBatchResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_HISTORIC_QUOTES_IN_BATCH, params],
    ({ token }) => getHistoricQuotesInBatch(token, params),
    options
  );
};
