/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AssignRoleParams } from '../model';
// @ts-ignore
import { CreateRoleParams } from '../model';
// @ts-ignore
import { Role } from '../model';
// @ts-ignore
import { RoleList } from '../model';
/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign a role to a user.
         * @param {AssignRoleParams} body The user parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole: async (body: AssignRoleParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assignRole', 'body', body)
            const localVarPath = `/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new role; only mandator administrators may do so, as this is a very central setting.
         * @param {CreateRoleParams} body The user parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (body: CreateRoleParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createRole', 'body', body)
            const localVarPath = `/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the given role. This is only possible if the role is not in use.                      Only mandator administrators may do so, as this is a very central setting.
         * @param {number} id ID of the role to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the given role from the given user.
         * @param {string} role Name of the role to remove from the user.
         * @param {number} userId ID of the user to remove the role assignment from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleAssignment: async (role: string, userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteRoleAssignment', 'role', role)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRoleAssignment', 'userId', userId)
            const localVarPath = `/v1/roles/{role}/user/{userId}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Roles matching the given search or belonging to the given user(s).
         * @param {string} [search] Search roles matching the given search string
         * @param {Array<number>} [userId] Get all roles belonging to the given users; will ignore the search parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllRoles: async (search?: string, userId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (userId) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Role.
         * @param {number} id ID of the role to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesId', 'id', id)
            const localVarPath = `/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign a role to a user.
         * @param {AssignRoleParams} body The user parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRole(body: AssignRoleParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRole(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.assignRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new role; only mandator administrators may do so, as this is a very central setting.
         * @param {CreateRoleParams} body The user parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(body: CreateRoleParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the given role. This is only possible if the role is not in use.                      Only mandator administrators may do so, as this is a very central setting.
         * @param {number} id ID of the role to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.deleteRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove the given role from the given user.
         * @param {string} role Name of the role to remove from the user.
         * @param {number} userId ID of the user to remove the role assignment from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleAssignment(role: string, userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleAssignment(role, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.deleteRoleAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Roles matching the given search or belonging to the given user(s).
         * @param {string} [search] Search roles matching the given search string
         * @param {Array<number>} [userId] Get all roles belonging to the given users; will ignore the search parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllRoles(search?: string, userId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllRoles(search, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.listAndSearchAllRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Role.
         * @param {number} id ID of the role to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign a role to a user.
         * @param {RolesApiAssignRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole(requestParameters: RolesApiAssignRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignRole(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new role; only mandator administrators may do so, as this is a very central setting.
         * @param {RolesApiCreateRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(requestParameters: RolesApiCreateRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<Role> {
            return localVarFp.createRole(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the given role. This is only possible if the role is not in use.                      Only mandator administrators may do so, as this is a very central setting.
         * @param {RolesApiDeleteRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(requestParameters: RolesApiDeleteRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRole(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the given role from the given user.
         * @param {RolesApiDeleteRoleAssignmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleAssignment(requestParameters: RolesApiDeleteRoleAssignmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRoleAssignment(requestParameters.role, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Roles matching the given search or belonging to the given user(s).
         * @param {RolesApiListAndSearchAllRolesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllRoles(requestParameters: RolesApiListAndSearchAllRolesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RoleList> {
            return localVarFp.listAndSearchAllRoles(requestParameters.search, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Role.
         * @param {RolesApiRolesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesId(requestParameters: RolesApiRolesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Role> {
            return localVarFp.rolesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignRole operation in RolesApi.
 * @export
 * @interface RolesApiAssignRoleRequest
 */
export interface RolesApiAssignRoleRequest {
    /**
     * The user parameters
     * @type {AssignRoleParams}
     * @memberof RolesApiAssignRole
     */
    readonly body: AssignRoleParams
}

/**
 * Request parameters for createRole operation in RolesApi.
 * @export
 * @interface RolesApiCreateRoleRequest
 */
export interface RolesApiCreateRoleRequest {
    /**
     * The user parameters
     * @type {CreateRoleParams}
     * @memberof RolesApiCreateRole
     */
    readonly body: CreateRoleParams
}

/**
 * Request parameters for deleteRole operation in RolesApi.
 * @export
 * @interface RolesApiDeleteRoleRequest
 */
export interface RolesApiDeleteRoleRequest {
    /**
     * ID of the role to delete.
     * @type {number}
     * @memberof RolesApiDeleteRole
     */
    readonly id: number
}

/**
 * Request parameters for deleteRoleAssignment operation in RolesApi.
 * @export
 * @interface RolesApiDeleteRoleAssignmentRequest
 */
export interface RolesApiDeleteRoleAssignmentRequest {
    /**
     * Name of the role to remove from the user.
     * @type {string}
     * @memberof RolesApiDeleteRoleAssignment
     */
    readonly role: string

    /**
     * ID of the user to remove the role assignment from.
     * @type {number}
     * @memberof RolesApiDeleteRoleAssignment
     */
    readonly userId: number
}

/**
 * Request parameters for listAndSearchAllRoles operation in RolesApi.
 * @export
 * @interface RolesApiListAndSearchAllRolesRequest
 */
export interface RolesApiListAndSearchAllRolesRequest {
    /**
     * Search roles matching the given search string
     * @type {string}
     * @memberof RolesApiListAndSearchAllRoles
     */
    readonly search?: string

    /**
     * Get all roles belonging to the given users; will ignore the search parameters.
     * @type {Array<number>}
     * @memberof RolesApiListAndSearchAllRoles
     */
    readonly userId?: Array<number>
}

/**
 * Request parameters for rolesId operation in RolesApi.
 * @export
 * @interface RolesApiRolesIdRequest
 */
export interface RolesApiRolesIdRequest {
    /**
     * ID of the role to retrieve.
     * @type {number}
     * @memberof RolesApiRolesId
     */
    readonly id: number
}

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Assign a role to a user.
     * @param {RolesApiAssignRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public assignRole(requestParameters: RolesApiAssignRoleRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).assignRole(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new role; only mandator administrators may do so, as this is a very central setting.
     * @param {RolesApiCreateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRole(requestParameters: RolesApiCreateRoleRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRole(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the given role. This is only possible if the role is not in use.                      Only mandator administrators may do so, as this is a very central setting.
     * @param {RolesApiDeleteRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRole(requestParameters: RolesApiDeleteRoleRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRole(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the given role from the given user.
     * @param {RolesApiDeleteRoleAssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRoleAssignment(requestParameters: RolesApiDeleteRoleAssignmentRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRoleAssignment(requestParameters.role, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Roles matching the given search or belonging to the given user(s).
     * @param {RolesApiListAndSearchAllRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public listAndSearchAllRoles(requestParameters: RolesApiListAndSearchAllRolesRequest = {}, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).listAndSearchAllRoles(requestParameters.search, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Role.
     * @param {RolesApiRolesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesId(requestParameters: RolesApiRolesIdRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

