import { UseMutationOptions } from "react-query";
import { HistoricQuotesApiAddOrUpdateHistoricQuotesRequest } from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const addOrUpdateHistoricQuotes = async (
  token: string | undefined,
  params: HistoricQuotesApiAddOrUpdateHistoricQuotesRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.addOrUpdateHistoricQuotes(params);
  return data;
};

export const useAddOrUpdateHistoricQuotes = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    HistoricQuotesApiAddOrUpdateHistoricQuotesRequest
  >
) => {
  return useAuthMutation(addOrUpdateHistoricQuotes, options, false);
};
