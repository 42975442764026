import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Divider, Typography } from "@mui/material";
import { useListAndSearchAllInvestments } from "@generated/apiv1/hooks";
import { TransactionWizardContext } from "@features/transactionWizard/stateMachine/types/transactionWizardContext";
import { LoadingProgressBar } from "@components";
import { areNonListedAssetsSelected } from "@features/transactionWizard/stateMachine/utils/utils";
import { StepContainer } from "../StepContainer";
import { BookingTypeWithSplit } from "../stateMachine/types/splitValues";

const Container = styled.div`
  width: 100%;
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  border: 1;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.divider};
`;

const FieldWithInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const mostUsed: BookingTypeWithSplit[] = ["buy", "sell"];
const specials: BookingTypeWithSplit[] = [
  "book_in",
  "book_out",
  "switch",
  "retained_dividend",
  "carve_out",
  "split",
];
const disabledTypesWhenNewInvestment: BookingTypeWithSplit[] = [
  "sell",
  "book_out",
  "switch",
  "carve_out",
  "split",
];
const sellTypes: BookingTypeWithSplit[] = [
  "sell",
  "book_out",
  "switch",
  "carve_out",
  "split",
];

export interface Props {
  handleNext: (
    bookingType: BookingTypeWithSplit,
    investmentId?: number
  ) => void;
  handlePrevious?: () => void;
  context: TransactionWizardContext;
  isManualTransaction?: boolean;
}

export const BookingTypeStep = ({
  handleNext,
  handlePrevious,
  context,
  isManualTransaction,
}: Props) => {
  const defaultValue = context.bookingValues.type;
  const accountId = context.investmentValues.depotId;
  const isin =
    context.sourceInvestmentValues?.isin || context.investmentValues.isin;
  const tickerSymbol =
    context.sourceInvestmentValues?.tickerSymbol ||
    context.investmentValues.tickerSymbol;

  const { t } = useTranslation();
  const [value, setValue] = useState<BookingTypeWithSplit | undefined>(
    defaultValue
  );

  const { data, isLoading } = useListAndSearchAllInvestments(
    {
      accountId: [accountId!],
      isin: isin ? [isin] : undefined,
      tickerSymbol: !isin && tickerSymbol ? [tickerSymbol] : undefined,
      includeHistoric: false,
    },
    {
      enabled: Boolean(accountId && (isin || tickerSymbol)),
    }
  );

  const hasInvested = data?.investments?.length
    ? Boolean(data.investments[0].numberOfLots)
    : false;
  const shouldHideSell =
    isManualTransaction || areNonListedAssetsSelected(context)
      ? false
      : !hasInvested;

  const handleClick = (bookingType: BookingTypeWithSplit) => {
    if (sellTypes.includes(bookingType) && hasInvested) {
      handleNext(bookingType, data?.investments[0].id);
    }
    handleNext(bookingType);
    setValue(bookingType);
  };

  if (isLoading) {
    return <LoadingProgressBar />;
  }

  return (
    <StepContainer
      testid="booking-type-step"
      title={t("transactionWizard.bookingTypeStep.title")}
      onPrevious={handlePrevious}
    >
      <Container>
        {mostUsed.map((bookingType) => {
          return (
            <FieldWithInfoContainer key={bookingType}>
              <Button
                variant={value === bookingType ? "outlined" : "contained"}
                data-testid={`${bookingType}-button`}
                disabled={
                  shouldHideSell &&
                  disabledTypesWhenNewInvestment.includes(bookingType)
                }
                color="primary"
                onClick={() => handleClick(bookingType)}
              >
                {t(`transactionWizard.bookingTypeStep.${bookingType}`)}
              </Button>
            </FieldWithInfoContainer>
          );
        })}

        <StyledDivider />

        <Typography variant="h6" mb={6}>
          {t("transactionWizard.bookingTypeStep.specials")}
        </Typography>
        {specials.map((bookingType) => {
          return (
            <FieldWithInfoContainer key={bookingType}>
              <Button
                variant={value === bookingType ? "outlined" : "contained"}
                data-testid={`${bookingType}-button`}
                disabled={
                  shouldHideSell &&
                  disabledTypesWhenNewInvestment.includes(bookingType)
                }
                color="primary"
                onClick={() => handleClick(bookingType)}
              >
                {t(`transactionWizard.bookingTypeStep.${bookingType}`)}
              </Button>
            </FieldWithInfoContainer>
          );
        })}
      </Container>
    </StepContainer>
  );
};
