import React from "react";
import { ReactComponent as InfoIcon } from "@icons/myicons/warning-triangle-filled.svg";
import { Banner } from "@components";

export const SettingsErrorBanner = ({
  errorMessage,
  marginBottom = 4,
  marginTop = 0,
}: {
  errorMessage: string;
  marginBottom?: number;
  marginTop?: number;
}) => {
  return (
    <Banner
      type="error"
      icon={<InfoIcon style={{ height: 24, width: 24 }} />}
      text={errorMessage}
      mb={marginBottom}
      mt={marginTop}
      textStyle={{ fontSize: "18px", lineHeight: "28px" }}
    />
  );
};
