import React from "react";
import { renderToString } from "react-dom/server";
import Highcharts from "highcharts";
import { Theme } from "@mui/material";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import { formatDateDDMMYY } from "@helpers/datetime";
import { splitFormatter } from "@components/Chart/Tooltips/chartSplitFormatter";

type Props = {
  formatter: (value?: string | number | null) => React.ReactNode;
  x?: string | number;
  points?: Highcharts.TooltipFormatterContextObject[];
  theme: Theme;
};

export const SingleAccountChartTooltip = ({
  x,
  points,
  formatter,
  theme,
}: Props) => {
  return [
    renderToString(
      <ChartTooltip>
        <div
          style={{
            fontSize: "10px",
            fontWeight: 500,
            color: theme.palette.text.secondary,
          }}
        >
          {formatDateDDMMYY(x)}
        </div>
      </ChartTooltip>
    ),
    splitFormatter(formatter, points?.[0]),
    splitFormatter(formatter, points?.[1]),
    splitFormatter(formatter, points?.[2]),
  ];
};
