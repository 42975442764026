import { UseQueryOptions } from "react-query";
import {
  TppCertificatesApiTppCertificatesIdRequest,
  TppCertificate,
} from "@generated/apiv1";

import { buildTppCertificatesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const tppCertificatesId = async (
  token: string | undefined,
  params: TppCertificatesApiTppCertificatesIdRequest
) => {
  const apiInstance = buildTppCertificatesApi(token);
  const { data } = await apiInstance.tppCertificatesId(params);
  return data;
};

export const useTppCertificatesId = (
  params: TppCertificatesApiTppCertificatesIdRequest,
  options?: UseQueryOptions<TppCertificate, AxiosApiError, TppCertificate>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TPPCERTIFICATES_TPP_CERTIFICATES_ID, params],
    ({ token }) => tppCertificatesId(token, params),
    options
  );
};
