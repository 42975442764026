/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankConnectionWrapper } from './bank-connection-wrapper';
// May contain unused imports in some cases
// @ts-ignore
import { MultiStepAuthenticationChallenge } from './multi-step-authentication-challenge';

/**
 * Representation of the values and status of a TPP web form flow
 * @export
 * @interface TppWebForm
 */
export interface TppWebForm {
    /**
     * Technical identifier (a random ID token); may be used to retrieve the web-form
     * @type {string}
     * @memberof TppWebForm
     */
    'id': string;
    /**
     * The public ID (or \'slug\') of the mandator the web-form user belongs to
     * @type {string}
     * @memberof TppWebForm
     */
    'mandatorPublicId': string;
    /**
     * Deprecated: use the bank slug; which equals the ID for FIN_API banks.             This values will be statically set to \'0\' for non-finapi banks.             ID of the bank we want to import or update; will match the bank connection
     * @type {number}
     * @memberof TppWebForm
     */
    'bankId': number;
    /**
     * Slug of the bank we want to import or update; equals the bank ID for multibanking             provider FIN_API.
     * @type {string}
     * @memberof TppWebForm
     */
    'bankSlug': string;
    /**
     * Who provides the multibanking API?
     * @type {string}
     * @memberof TppWebForm
     */
    'multibankingApiProvider': TppWebFormMultibankingApiProviderEnum;
    /**
     * Redirect the users web-client to this URL to start the web-form flow
     * @type {string}
     * @memberof TppWebForm
     */
    'startWebFormFlowUrl': string;
    /**
     * The redirect URL back to the originating client (e.g. https://partner.page/something)             to use in this particular form flow
     * @type {string}
     * @memberof TppWebForm
     */
    'redirectUrl': string;
    /**
     * The display mode of the web form, allowing to switch between light and dark mode.             Default is \'LIGHT\'.
     * @type {string}
     * @memberof TppWebForm
     */
    'displayMode': TppWebFormDisplayModeEnum;
    /**
     * ID of the created or updated bank connection.             In case import failed or the form flow did not finish yet this field may be empty. Please be aware             that you must use endpoint v1/bankConnections to request FIN_API bank connections;             v3 endpoints are not yet available.
     * @type {number}
     * @memberof TppWebForm
     */
    'bankConnectionId'?: number;
    /**
     * 
     * @type {BankConnectionWrapper}
     * @memberof TppWebForm
     */
    'bankConnection'?: BankConnectionWrapper;
    /**
     * FIN_API only: The bank connection interface to import or update. If not given, the user will             be asked to update or import all available interfaces
     * @type {string}
     * @memberof TppWebForm
     */
    'bankConnectionInterfaceType'?: TppWebFormBankConnectionInterfaceTypeEnum;
    /**
     * 
     * @type {MultiStepAuthenticationChallenge}
     * @memberof TppWebForm
     */
    'multiStepAuthentication'?: MultiStepAuthenticationChallenge;
    /**
     * The current status of this particular form flow, possible values are<br />            WAITING_FOR_CREDENTIALS: no user input as of yet <br />            WAITING_FOR_MFA: waiting for a second factor or decoupled approach <br />            WAITING_FOR_REDIRECT_RESPONSE: waiting for a redirect back from the bank in case the PSD2 redirect approach is used <br />            IMPORT_SUCCESS: bank connection successfully imported or updated <br />            IMPORT_FAILED: the import failed, check the error message
     * @type {string}
     * @memberof TppWebForm
     */
    'bankConnectionInterfaceStatus': TppWebFormBankConnectionInterfaceStatusEnum;
    /**
     * The response code from the banking API, this may be HTTP_OK for a successful import or             update or an error code, see https://sandbox.finapi.io/#!/Bank_Connections/importBankConnection for             possible error codes
     * @type {number}
     * @memberof TppWebForm
     */
    'bankingApiResponseCode'?: number;
    /**
     * A response from the banking API, this will typically be an error message for failed             import or update attempts
     * @type {string}
     * @memberof TppWebForm
     */
    'bankingApiResponseMessage'?: string;
}

export const TppWebFormMultibankingApiProviderEnum = {
    FIN_API: 'FIN_API',
    WEALTH_API: 'WEALTH_API'
} as const;

export type TppWebFormMultibankingApiProviderEnum = typeof TppWebFormMultibankingApiProviderEnum[keyof typeof TppWebFormMultibankingApiProviderEnum];
export const TppWebFormDisplayModeEnum = {
    LIGHT: 'LIGHT',
    DARK: 'DARK'
} as const;

export type TppWebFormDisplayModeEnum = typeof TppWebFormDisplayModeEnum[keyof typeof TppWebFormDisplayModeEnum];
export const TppWebFormBankConnectionInterfaceTypeEnum = {
    XS2_A: 'XS2A',
    FINTS_SERVER: 'FINTS_SERVER',
    WEB_SCRAPER: 'WEB_SCRAPER'
} as const;

export type TppWebFormBankConnectionInterfaceTypeEnum = typeof TppWebFormBankConnectionInterfaceTypeEnum[keyof typeof TppWebFormBankConnectionInterfaceTypeEnum];
export const TppWebFormBankConnectionInterfaceStatusEnum = {
    WAITING_FOR_CREDENTIALS: 'WAITING_FOR_CREDENTIALS',
    WAITING_FOR_MFA: 'WAITING_FOR_MFA',
    WAITING_FOR_REDIRECT_RESPONSE: 'WAITING_FOR_REDIRECT_RESPONSE',
    IMPORT_SUCCESS: 'IMPORT_SUCCESS',
    IMPORT_FAILED: 'IMPORT_FAILED'
} as const;

export type TppWebFormBankConnectionInterfaceStatusEnum = typeof TppWebFormBankConnectionInterfaceStatusEnum[keyof typeof TppWebFormBankConnectionInterfaceStatusEnum];


