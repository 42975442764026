import React, { ReactNode } from "react";
import {
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  Theme,
} from "@mui/material";
import MuiCloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import { Logo } from "@components/Logo";

export type Variant = "light" | "dark";

const Container = styled(Box, {
  shouldForwardProp: (prop: string) =>
    isPropValid(prop) || prop === "boxShadow" || prop === "justifyContent",
})`
  ${({ backgroundColor }: { backgroundColor?: string }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => (theme.type === "finanzfluss" ? "56px" : "60px")};
`;

const Title = styled(Typography, {
  shouldForwardProp: (name) => name !== "textColor",
})`
  ${({ textColor }: { textColor?: string; component: string }) =>
    textColor && `color: ${textColor};`}
  position: absolute;
  top: ${({ theme }) => (theme.type === "finanzfluss" ? "12px" : "17px")};
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
`;

const CloseIcon = styled(MuiCloseIcon, { shouldForwardProp: isPropValid })`
  ${({ textColor }: { textColor?: string }) =>
    textColor && `color: ${textColor};`}
`;

export interface HeaderProps {
  backUrl?: string;
  onBackClick?: () => void;
  title?: ReactNode;
  variant?: Variant;
  onLogoClick?: () => void;
  isCenteredLayout?: boolean;
}

export const WizardsHeader = ({
  backUrl,
  title,
  variant = "light",
  onBackClick,
  onLogoClick,
  isCenteredLayout,
}: HeaderProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const logoVariant = isXs ? "compact" : "bankConnection";
  const backgroundColor =
    variant === "dark"
      ? theme.header?.dark?.backgroundColor
      : theme.header?.light?.backgroundColor;

  const color =
    variant === "dark" ? theme.header?.dark?.color : theme.header?.light?.color;

  return (
    <Container
      boxShadow={variant === "light" ? 2 : 0}
      backgroundColor={backgroundColor}
      justifyContent={
        isCenteredLayout
          ? "center"
          : title || backUrl || onBackClick
          ? "space-between"
          : "center"
      }
    >
      {isCenteredLayout ? (
        <>
          <Box display="flex" flexGrow={0.5} justifyContent="end">
            <Logo variant="bankConnection" onClick={onLogoClick} />
          </Box>
          <Box display="flex" flexGrow={0.5} justifyContent="end">
            <IconButton onClick={onBackClick} size="large">
              <CloseIcon textColor={color} />
            </IconButton>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Logo variant={logoVariant} onClick={onLogoClick} />
          </Box>
          {title && (
            <Title textColor={color} component="div">
              {title}
            </Title>
          )}
          {backUrl && !onBackClick && (
            <IconButton href={backUrl} size="large">
              <CloseIcon textColor={color} />
            </IconButton>
          )}
          {!backUrl && onBackClick && (
            <IconButton onClick={onBackClick} size="large">
              <CloseIcon textColor={color} />
            </IconButton>
          )}
        </>
      )}
    </Container>
  );
};
