import { axiosInstanceV1 } from "@api/axiosInstance";

export const loadProperties = async (
  token: string | undefined,
  propKey: string
) => {
  try {
    const response = await axiosInstanceV1.get<{
      key: string;
      value: { values: any[] };
    }>(`/users/properties/${propKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (e) {
    if ((e as any).response?.status === 404) return null;
    throw e;
  }
};

export const updateProperties = async <V>(
  token: string | undefined,
  {
    values,
    propKey,
  }: {
    values: V[];
    propKey: string;
  }
) => {
  try {
    const response = await axiosInstanceV1.post(
      `/users/properties`,
      { key: propKey, value: { values } },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (e) {
    if ((e as any).response?.status === 404) return null;
    throw e;
  }
};
