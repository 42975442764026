import { UseQueryOptions } from "react-query";
import {
  BankMessagesApiGetBankMessagesRequest,
  BankMessageList,
} from "@generated/apiv3";

import { buildBankMessagesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankMessages = async (
  token: string | undefined,
  params?: BankMessagesApiGetBankMessagesRequest
) => {
  const apiInstance = buildBankMessagesApi(token);
  const { data } = await apiInstance.getBankMessages(params);
  return data;
};

export const useGetBankMessages = (
  params?: BankMessagesApiGetBankMessagesRequest,
  options?: UseQueryOptions<BankMessageList, AxiosApiError, BankMessageList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKMESSAGES_GET_BANK_MESSAGES, params],
    ({ token }) => getBankMessages(token, params),
    options
  );
};
