export type ModalType =
  | "name"
  | "email"
  | "password"
  | "discordId"
  | "delete"
  | "";

export interface ProfileModalTextData {
  [key: string]: {
    title: string;
    subtitle?: string;
    confirmButton: string;
    cancelButton?: string;
    confirmButtonColor?: "primary" | "error";
  };
}

export type ProfileFieldType = "name" | "email" | "password" | "discordId";

export interface ProfileField {
  label: string;
  text: string;
  subtext?: string;
}

export type ProfileEditOptions = {
  [key in ProfileFieldType]: ProfileField;
};

export const profileEditOptions: ProfileEditOptions = {
  name: {
    label: "settingsPage.profile.labels.name",
    text: "settingsPage.profile.labels.name",
  },
  email: {
    label: "settingsPage.profile.labels.email",
    text: "settingsPage.profile.labels.email",
  },
  password: {
    label: "settingsPage.profile.labels.password",
    text: "settingsPage.profile.labels.password",
  },
  discordId: {
    label: "settingsPage.profile.labels.discordId",
    text: "settingsPage.profile.labels.discordId",
  },
};
