import { UseQueryOptions } from "react-query";
import { FundsApiMostPopularRequest, PageableFundList } from "@generated/apiv1";

import { buildFundsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const mostPopular = async (
  token: string | undefined,
  params: FundsApiMostPopularRequest
) => {
  const apiInstance = buildFundsApi(token);
  const { data } = await apiInstance.mostPopular(params);
  return data;
};

export const useMostPopular = (
  params: FundsApiMostPopularRequest,
  options?: UseQueryOptions<PageableFundList, AxiosApiError, PageableFundList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDS_MOST_POPULAR, params],
    ({ token }) => mostPopular(token, params),
    options
  );
};
