import { UseMutationOptions } from "react-query";
import { SynchronizationBatchJob } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const bankConnectionsCashback = async (token: string | undefined) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.bankConnectionsCashback();
  return data;
};

export const useBankConnectionsCashback = (
  options?: UseMutationOptions<SynchronizationBatchJob, AxiosApiError, unknown>
) => {
  return useAuthMutation(bankConnectionsCashback, options, false);
};
