import { MutateOptions } from "react-query";
import {
  CreateOrUpdateWatchlistEntryParams,
  WatchlistEntry,
} from "@api/v1/model";
import { updateWatchlistEntry } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

export const useUpdateWatchlistEntry = (
  options?: MutateOptions<
    WatchlistEntry,
    AxiosApiError,
    CreateOrUpdateWatchlistEntryParams & { watchlistEntryId: number }
  >
) => useAuthMutation(updateWatchlistEntry, options);
