import { UseMutationOptions } from "react-query";
import {
  InvestmentsApiUpdatePrivateNotesRequest,
  Comment,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updatePrivateNotes = async (
  token: string | undefined,
  params: InvestmentsApiUpdatePrivateNotesRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.updatePrivateNotes(params);
  return data;
};

export const useUpdatePrivateNotes = (
  options?: UseMutationOptions<
    Comment,
    AxiosApiError,
    InvestmentsApiUpdatePrivateNotesRequest
  >
) => {
  return useAuthMutation(updatePrivateNotes, options, false);
};
