import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import LazyLoad from "react-lazyload";
import { LoadingPlaceHolder } from "@components";
import { HistoricalQuotesChart } from "@components/Chart/HistoricalQuotesChart";
import { FundamentalsBlock, Header } from "../FundamentalsBlock";
import { Props as FundamentalsByIsinProps } from "../../FundamentalsPageByIsin";
import { GainLossStatisticsLoader } from "../../../watchlists/details/GainLossStatisticsLoader";
import { useDividendsPerYear } from "../../hooks/useDividendsPerYear";
import { DividendsChart } from "../DividendsChart/DividendsChart";

const LazyLoadWrapperMaxWidth = styled(Box)<{ maxWidth?: number }>`
  width: 100%;
  height: 100%;

  .lazyload-wrapper {
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: none;
  }
`;

export const FundFundamentalsByIsin = ({
  isin,
  watchlistEntryId,
  quoteProvider,
  tickerSymbol,
}: FundamentalsByIsinProps) => {
  const { data: dividends, isLoading: isDividendsLoading } =
    useDividendsPerYear(isin);

  const areDividendsPresent = dividends && dividends?.length > 0;

  if (isDividendsLoading) {
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <FundamentalsBlock header={<Header />}>
      {watchlistEntryId && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <GainLossStatisticsLoader watchlistEntryId={watchlistEntryId} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}

      {areDividendsPresent && (
        <LazyLoadWrapperMaxWidth>
          <LazyLoad>
            <DividendsChart isin={isin} currency={"EUR"} />
          </LazyLoad>
        </LazyLoadWrapperMaxWidth>
      )}
      <LazyLoadWrapperMaxWidth>
        <LazyLoad>
          <HistoricalQuotesChart
            quoteProvider={quoteProvider}
            tickerSymbol={tickerSymbol}
          />
        </LazyLoad>
      </LazyLoadWrapperMaxWidth>
    </FundamentalsBlock>
  );
};
