import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toGermanNumberFormat } from "@helpers";
import { Event } from "@components/Chart/InvestmentChart/types";
import { Item as ItemOrig } from "../../BaseChartTools/CustomTooltip";
import { getEventLabel } from "../EventDot/getEventLabel";

interface Props {
  event: Event;
}

export const EventItem = ({ event }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const label = getEventLabel(event, theme.palette);

  return (
    <ItemOrig
      beforeComponent={
        <Typography
          variant="h5"
          style={{
            paddingRight: "7px",
            paddingLeft: "1px",
            color: label.color,
          }}
        >
          {label.name}
        </Typography>
      }
      color={label.color}
      title={toGermanNumberFormat(event.amount)}
      subtitle={t(`investmentChartTooltip.${event.type}`)}
    />
  );
};
