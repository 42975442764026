import { UseQueryOptions } from "react-query";
import { ClientConfiguration } from "@generated/apiv1";

import { buildFinApiClientConfigurationApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getFinApiClientConfiguration = async (
  token: string | undefined
) => {
  const apiInstance = buildFinApiClientConfigurationApi(token);
  const { data } = await apiInstance.getFinApiClientConfiguration();
  return data;
};

export const useGetFinApiClientConfiguration = (
  options?: UseQueryOptions<
    ClientConfiguration,
    AxiosApiError,
    ClientConfiguration
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.FINCLIENTCONFIGURATIONAPI_GET_FIN_API_CLIENT_CONFIGURATION],
    ({ token }) => getFinApiClientConfiguration(token),
    options
  );
};
