import { UseMutationOptions } from "react-query";
import { PlugAndPlayApiAddToProductWatchlistRequest } from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const addToProductWatchlist = async (
  token: string | undefined,
  params?: PlugAndPlayApiAddToProductWatchlistRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.addToProductWatchlist(params);
  return data;
};

export const useAddToProductWatchlist = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    PlugAndPlayApiAddToProductWatchlistRequest
  >
) => {
  return useAuthMutation(addToProductWatchlist, options, false);
};
