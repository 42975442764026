/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * SubscriptionFormsApi - axios parameter creator
 * @export
 */
export const SubscriptionFormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate an empty cashback subscription form.
         * @param {boolean} isNewDepot Generate forms for new depot (if true) or and advisor change (if false).
         * @param {string} bic Generate forms for the given bank.
         * @param {string} formType Generate a form for a single, junior, joint or VL depot. Allowed values are                              Einzel, Gemeinschaft, Junior, VL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateEmptySubscriptionForm: async (isNewDepot: boolean, bic: string, formType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isNewDepot' is not null or undefined
            assertParamExists('generateEmptySubscriptionForm', 'isNewDepot', isNewDepot)
            // verify required parameter 'bic' is not null or undefined
            assertParamExists('generateEmptySubscriptionForm', 'bic', bic)
            // verify required parameter 'formType' is not null or undefined
            assertParamExists('generateEmptySubscriptionForm', 'formType', formType)
            const localVarPath = `/v1/subscriptionForms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isNewDepot !== undefined) {
                localVarQueryParameter['isNewDepot'] = isNewDepot;
            }

            if (bic !== undefined) {
                localVarQueryParameter['bic'] = bic;
            }

            if (formType !== undefined) {
                localVarQueryParameter['formType'] = formType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionFormsApi - functional programming interface
 * @export
 */
export const SubscriptionFormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionFormsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate an empty cashback subscription form.
         * @param {boolean} isNewDepot Generate forms for new depot (if true) or and advisor change (if false).
         * @param {string} bic Generate forms for the given bank.
         * @param {string} formType Generate a form for a single, junior, joint or VL depot. Allowed values are                              Einzel, Gemeinschaft, Junior, VL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateEmptySubscriptionForm(isNewDepot: boolean, bic: string, formType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateEmptySubscriptionForm(isNewDepot, bic, formType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionFormsApi.generateEmptySubscriptionForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionFormsApi - factory interface
 * @export
 */
export const SubscriptionFormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionFormsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate an empty cashback subscription form.
         * @param {SubscriptionFormsApiGenerateEmptySubscriptionFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateEmptySubscriptionForm(requestParameters: SubscriptionFormsApiGenerateEmptySubscriptionFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.generateEmptySubscriptionForm(requestParameters.isNewDepot, requestParameters.bic, requestParameters.formType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for generateEmptySubscriptionForm operation in SubscriptionFormsApi.
 * @export
 * @interface SubscriptionFormsApiGenerateEmptySubscriptionFormRequest
 */
export interface SubscriptionFormsApiGenerateEmptySubscriptionFormRequest {
    /**
     * Generate forms for new depot (if true) or and advisor change (if false).
     * @type {boolean}
     * @memberof SubscriptionFormsApiGenerateEmptySubscriptionForm
     */
    readonly isNewDepot: boolean

    /**
     * Generate forms for the given bank.
     * @type {string}
     * @memberof SubscriptionFormsApiGenerateEmptySubscriptionForm
     */
    readonly bic: string

    /**
     * Generate a form for a single, junior, joint or VL depot. Allowed values are                              Einzel, Gemeinschaft, Junior, VL
     * @type {string}
     * @memberof SubscriptionFormsApiGenerateEmptySubscriptionForm
     */
    readonly formType: string
}

/**
 * SubscriptionFormsApi - object-oriented interface
 * @export
 * @class SubscriptionFormsApi
 * @extends {BaseAPI}
 */
export class SubscriptionFormsApi extends BaseAPI {
    /**
     * 
     * @summary Generate an empty cashback subscription form.
     * @param {SubscriptionFormsApiGenerateEmptySubscriptionFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionFormsApi
     */
    public generateEmptySubscriptionForm(requestParameters: SubscriptionFormsApiGenerateEmptySubscriptionFormRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionFormsApiFp(this.configuration).generateEmptySubscriptionForm(requestParameters.isNewDepot, requestParameters.bic, requestParameters.formType, options).then((request) => request(this.axios, this.basePath));
    }
}

