import { round, isDateValid } from "@helpers";
import { ExtendedBookingType } from "@features/transactionWizard/modal/steps/constants";
import { BookingValues } from "../stateMachine/types/bookingValues";

export interface Amounts {
  amount: number;
  totalAmount: number;
}

export interface PriceWithAmount {
  price: number;
  amount: number;
}

const expensesSign = (type: ExtendedBookingType) =>
  ["buy", "book_in", "carve_out"].includes(type) ? -1 : 1;

// 100 + 20 = 120
// 100 - 20 = 80
// numberOfLots, commission, taxAmount, price, priceInPercent -> amount, totalAmount

export const getAmounts = (
  bookingType: ExtendedBookingType,
  numberOfLots: number,
  price: number,
  commission: number,
  taxAmount: number,
  priceInPercent: boolean
): Amounts => {
  const percentFactor = priceInPercent ? 0.01 : 1;
  const taxAndCommission = (commission + taxAmount) * expensesSign(bookingType);
  const totalAmount = numberOfLots * price * percentFactor - taxAndCommission;
  const amount = totalAmount + taxAndCommission;

  return {
    amount: round(amount),
    totalAmount: round(totalAmount),
  };
};

const getEntryQuoteForNumberOfLots = (
  totalAmount: number,
  numberOfLots: number,
  priceInPercent: boolean
): number => {
  const percentFactor = priceInPercent ? 100 : 1;
  const securityPrice = (totalAmount / (numberOfLots || 1)) * percentFactor;

  return round(securityPrice);
};

const getNumberOfLotsForEntryQuote = (
  totalAmount: number,
  entryQuote: number
) => {
  return totalAmount / (entryQuote || 1);
};

const gramToOunces = (gram?: number) => {
  if (!gram) return gram;
  return gram / 31.1034768;
};

const ouncesToGram = (ounces?: number) => {
  if (!ounces) return ounces;
  return round(ounces * 31.1034768, 6);
};

const definedAndPositive = (value?: number): boolean => {
  return !!value && value > 0;
};

const validate = (values: BookingValues) => {
  if (values.type === "book_in" && !definedAndPositive(values.securityPrice))
    return false;

  return (
    isDateValid(values.date) &&
    definedAndPositive(values.exchangeRate) &&
    definedAndPositive(values.entryQuote) &&
    definedAndPositive(values.numberOfLots)
  );
};

export const useGetBookingValues = () => {
  return {
    getAmounts,
    getNumberOfLotsForEntryQuote,
    getEntryQuoteForNumberOfLots,
    gramToOunces,
    ouncesToGram,
    validate,
  };
};
