/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CreateOrUpdateBookingParams } from './create-or-update-booking-params';

/**
 * 
 * @export
 * @interface CreateOrUpdateInvestmentParams
 */
export interface CreateOrUpdateInvestmentParams {
    /**
     * Required if we do a batch update and the investment exists
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'id'?: number;
    /**
     * Only required when updating. When creating, the account is globally given in the                               \'CreateMultipleBookings\' params
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'accountId'?: number;
    /**
     * the investment type; required for creation
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'type'?: CreateOrUpdateInvestmentParamsTypeEnum;
    /**
     * The investments ticker symbol; typically given by a quote provider; required for object                       creation
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'tickerSymbol'?: string;
    /**
     * The investment currency (3-letter ISO code); required for update
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'currency'?: string;
    /**
     * The quote provider; this depends on the quote sources your mandator supports;                                required for object creation
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'quoteProvider'?: string;
    /**
     * The quote currency; if different from the investments currency quotes will be converted                                into the investments currency
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'quoteCurrency'?: string;
    /**
     * The investments finAPI ID if imported via banking API
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'finApiId'?: string;
    /**
     * The investment name; required for object creation
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'name'?: string;
    /**
     * The investments ISIN; if available
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'isin'?: string;
    /**
     * The investments WKN; if available
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'wkn'?: string;
    /**
     * The current market price; this will be stored together with the investment and used                                as a fallback in case there is no quote data available
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'currentMarketPrice'?: number;
    /**
     * For bonds only: the bond interest per year
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'yield'?: number;
    /**
     * For bonds only: the due date
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'dueDate'?: string;
    /**
     * The industry sector; use the industries API to obtain valid IDs
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'industryId'?: number;
    /**
     * The region; use the regions API to obtain valid IDs
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'regionId'?: number;
    /**
     * A user-defined tag for custom grouping
     * @type {string}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'tag'?: string;
    /**
     * ID of this investment\'s sub-category (if any)
     * @type {number}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'subCategoryId'?: number;
    /**
     * A list ob bookings (transactions) to create together with this investment; at least one                                booking is required for creation
     * @type {Array<CreateOrUpdateBookingParams>}
     * @memberof CreateOrUpdateInvestmentParams
     */
    'createOrUpdateBookingParamsList'?: Array<CreateOrUpdateBookingParams>;
}

export const CreateOrUpdateInvestmentParamsTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;

export type CreateOrUpdateInvestmentParamsTypeEnum = typeof CreateOrUpdateInvestmentParamsTypeEnum[keyof typeof CreateOrUpdateInvestmentParamsTypeEnum];


