import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useChartColors } from "@hooks";
import { BarChartItem } from "../types";

const BarSectionContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isSelected", "fullWidth", "isActive"].includes(prop as string),
})<{
  isSelected?: boolean;
  fullWidth?: boolean;
  isActive?: boolean;
}>(({ theme, isSelected, fullWidth, isActive }) => ({
  borderRadius: "8px",
  maxWidth: fullWidth ? "none" : "156px",
  minWidth: "66px",
  width: fullWidth ? "100%" : "unset",
  height: "176px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: isSelected ? theme.palette.background.selected : "unset",
  cursor: isActive ? "pointer" : "default",
  padding: theme.spacing(3),

  "&:hover": isActive
    ? {
        backgroundColor: isSelected
          ? theme.palette.background.selected
          : theme.palette.background.subtle,
      }
    : {},
}));

const BarChartLine = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "barHeight" && prop !== "isExpenses" && prop !== "shouldAnimate",
})<{
  barHeight: number;
  isExpenses?: boolean;
  shouldAnimate: boolean;
  color: string;
}>(({ theme, barHeight, isExpenses, shouldAnimate, color }) => ({
  borderRadius: "2px",
  animation: shouldAnimate
    ? `barHeight${Math.floor(barHeight)} 0.5s ease-in-out forwards`
    : "none",
  height: shouldAnimate ? 0 : `${barHeight > 1 ? barHeight : 2}px`,
  width: "10px",
  backgroundColor:
    barHeight > 1 ? color : theme.charts["cashflowChart"].disabled,

  [`@keyframes barHeight${Math.floor(barHeight)}`]: {
    "0%": {
      height: 0,
    },
    "100%": {
      height: `${barHeight > 1 ? barHeight : 2}px`,
    },
  },
}));

export const BarSection = ({
  item,
  isSelected,
  onClick,
  maxNumber,
  fullWidth,
  isAnimationEnabled,
}: {
  item: BarChartItem;
  isSelected?: boolean;
  onClick: (id: string) => void;
  maxNumber: number;
  fullWidth: boolean;
  isAnimationEnabled: boolean;
}) => {
  const isActive = item.value > 0 || item.value2 > 0;
  const chartColors = useChartColors("cashflowChart");

  const handleClick = () => {
    isActive && onClick(item.id);
  };

  return (
    <BarSectionContainer
      onClick={handleClick}
      isSelected={isSelected}
      fullWidth={fullWidth}
      isActive={isActive}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="end"
        height="100%"
      >
        <Box
          display="flex"
          alignItems="end"
          justifyContent="center"
          gap={1}
          mb={3}
        >
          <BarChartLine
            barHeight={(item.value * 112) / maxNumber}
            color={chartColors.expenses}
            shouldAnimate={isAnimationEnabled}
          />
          <BarChartLine
            barHeight={(item.value2 * 112) / maxNumber}
            color={chartColors.income}
            shouldAnimate={isAnimationEnabled}
          />
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            opacity: isActive ? 1 : 0.4,
          }}
        >
          {item.label}
        </Typography>
      </Box>
    </BarSectionContainer>
  );
};
