import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfirmDeleteDialog } from "@components";
import { useDeleteWatchlistEntry } from "@api/v1/hooks/useDeleteWatchlistEntry";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

interface SecuritiesHeaderMenuDeleteActionProps {
  securityId: string;
  watchlistId: string;
}

export const SecuritiesHeaderMenuDeleteAction = (
  props: SecuritiesHeaderMenuDeleteActionProps
) => {
  const { watchlistId, securityId } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutateAsync: deleteWatchlistEntry } = useDeleteWatchlistEntry();

  const handleClickOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteWatchlist = async () => {
    await deleteWatchlistEntry(Number.parseInt(securityId, 10));
    handleCloseDialog();
    navigate(`/watchlists/${watchlistId}`);
  };

  return (
    <>
      <MenuItem
        onClick={handleClickOpenDialog}
        data-testid="delete-watchlist-menu-item"
      >
        <Box display="flex" alignItems="center" gap={3} color="error.main">
          <DeleteIcon />
          {t("securitiesSinglePage.mobileHeaderMenuActions.deleteSecurity")}
        </Box>
      </MenuItem>

      <ConfirmDeleteDialog
        isOpen={isDialogOpen}
        title={t("securitiesSinglePage.deleteDialog.title")}
        onClose={handleCloseDialog}
        onDelete={handleDeleteWatchlist}
      />
    </>
  );
};
