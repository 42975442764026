import i18next from "i18next";
import { useMemo } from "react";
import { keyBy } from "lodash";
import { Investment, RiskYieldWithInvestment } from "@generated/apiv1";
import {
  useBatchRiskYieldMetrics,
  usePublicBatchRiskYieldMetrics,
} from "@generated/apiv1/hooks";
import { usePublicOrPrivateInvestments } from "@features/sharedPortfolio/hooks/usePublicOrPrivateInvestments";
import { ChartBreakdown } from "@features/analysis/components/types";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import { useSecurityImages } from "@hooks";

const convertToChartBreakdown = (
  perInvestmentRiskClass?: RiskYieldWithInvestment[],
  investments?: Investment[],
  logos?: Record<string, string | undefined | null>
): ChartBreakdown[] => {
  if (!perInvestmentRiskClass) return [];
  if (!investments) return [];

  const investmentsById = keyBy(investments, "id");

  const uniqueRiskClasses = perInvestmentRiskClass.reduce(
    (acc, riskStatistics) => {
      const key = riskStatistics.annualizedRiskClass ?? "unclassified";

      return {
        ...acc,
        [key]: {
          amount: (acc[key]?.amount || 0) + 1,
          marketValue:
            (acc[key]?.marketValue || 0) +
            (investmentsById[riskStatistics.investmentReference?.id ?? ""]
              ?.currentMarketValueAC || 0),
          investments: [
            ...(acc[key]?.investments || []),
            investmentsById[riskStatistics.investmentReference?.id ?? ""],
          ].filter(Boolean),
        },
      };
    },
    {} as Record<
      number | string,
      {
        amount: number;
        marketValue: number;
        investments: Investment[];
      }
    >
  );

  const riskClasses = Object.keys(uniqueRiskClasses).map(
    (riskClass) =>
      ({
        id: riskClass,
        uid: riskClass,
        value: uniqueRiskClasses[riskClass].marketValue,
        name:
          riskClass !== "unclassified"
            ? i18next.t(`riskClassification.numberToWord.${riskClass}`)
            : i18next.t("riskClassification.unclassified"),
        currency: "EUR",
        hasChildren: true,
        y: uniqueRiskClasses[riskClass].marketValue,
      } as ChartBreakdown)
  );

  const investmentsChartData = Object.keys(uniqueRiskClasses).reduce(
    (acc, riskClass) => {
      const investments = uniqueRiskClasses[riskClass].investments;

      return [
        ...acc,
        ...investments.map(
          (investment) =>
            ({
              id: investment.id,
              uid: investment.id,
              parentId: riskClass,
              hasChildren: false,
              value: investment.currentMarketValueAC || 0,
              name: investment.standardisedName || investment.name,
              currency: "EUR",
              y: investment.currentMarketValueAC || 0,
              icon:
                logos?.[investment.isin] || logos?.[investment.tickerSymbol],
            } as ChartBreakdown)
        ),
      ];
    },
    [] as ChartBreakdown[]
  );

  return [...riskClasses, ...investmentsChartData].sort(
    (a, b) => b.value - a.value
  );
};

export const useRiskBreakdowns = (accountIds?: number[]) => {
  const investmentsQuery = usePublicOrPrivateInvestments(
    {
      accountId: accountIds,
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  const riskYieldStatisticsQuery = usePublicOrPrivateData({
    usePublicMethod: usePublicBatchRiskYieldMetrics,
    usePrivateMethod: useBatchRiskYieldMetrics,
    params: { depotId: accountIds },
    options: {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  });

  const logos = useSecurityImages(investmentsQuery.investments);

  const breakdowns = useMemo(
    () =>
      convertToChartBreakdown(
        riskYieldStatisticsQuery.data?.riskYieldMetrics,
        investmentsQuery.investments,
        logos
      ),

    [
      riskYieldStatisticsQuery.data?.riskYieldMetrics,
      investmentsQuery.investments,
      logos,
    ]
  );

  return {
    data: breakdowns,
    isLoading:
      investmentsQuery.isFetching || riskYieldStatisticsQuery.isFetching,
    isFetched: investmentsQuery.isFetched && riskYieldStatisticsQuery.isFetched,
  };
};
