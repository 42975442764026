/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateRandomUserParams } from '../model';
// @ts-ignore
import { MockBankConnectionConfigurationParams } from '../model';
// @ts-ignore
import { RandomUser } from '../model';
// @ts-ignore
import { TimeDependentProperty } from '../model';
/**
 * QualityAssuranceApi - axios parameter creator
 * @export
 */
export const QualityAssuranceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary \"Set the configuration for banking API mocking. This endpoint is only available on                 quality assurance environments.
         * @param {MockBankConnectionConfigurationParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureMockBankingApi: async (body: MockBankConnectionConfigurationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('configureMockBankingApi', 'body', body)
            const localVarPath = `/v1/qualityAssurance/configureMockBankingApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm the users email address.                      This endpoint is only available on quality assurance environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserQA: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/qualityAssurance/confirmUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Programmatically create a new, empty user. The users username, password and user reference                      are returned. This endpoint is only available on quality assurance environments
         * @param {CreateRandomUserParams} body Parameters for creating a random user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRandomUser: async (body: CreateRandomUserParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createRandomUser', 'body', body)
            const localVarPath = `/v1/qualityAssurance/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Programmatically create a new, random voucher that is valid from one year ago to one year                      from now. The voucher belongs to the given mandator.
         * @param {string} mandatorPublicId The voucher belongs to the given mandator.
         * @param {string} amount The vouchers amount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRandomVoucher: async (mandatorPublicId: string, amount: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorPublicId' is not null or undefined
            assertParamExists('createRandomVoucher', 'mandatorPublicId', mandatorPublicId)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('createRandomVoucher', 'amount', amount)
            const localVarPath = `/v1/qualityAssurance/vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary \"Delete all accounts for the currently configured mock bank connection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountsForMockBankConnection: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/qualityAssurance/deleteAccountsForMockBankConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the users email confirmation token.                      This endpoint is only available on quality assurance environments.
         * @param {string} email Get the email confirmation token for the user matching the given email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailChangeToken: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('emailChangeToken', 'email', email)
            const localVarPath = `/v1/qualityAssurance/emailChangeToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QualityAssuranceApi - functional programming interface
 * @export
 */
export const QualityAssuranceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QualityAssuranceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary \"Set the configuration for banking API mocking. This endpoint is only available on                 quality assurance environments.
         * @param {MockBankConnectionConfigurationParams} body Defines which account to link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configureMockBankingApi(body: MockBankConnectionConfigurationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configureMockBankingApi(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.configureMockBankingApi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Confirm the users email address.                      This endpoint is only available on quality assurance environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserQA(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserQA(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.confirmUserQA']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Programmatically create a new, empty user. The users username, password and user reference                      are returned. This endpoint is only available on quality assurance environments
         * @param {CreateRandomUserParams} body Parameters for creating a random user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRandomUser(body: CreateRandomUserParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RandomUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRandomUser(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.createRandomUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Programmatically create a new, random voucher that is valid from one year ago to one year                      from now. The voucher belongs to the given mandator.
         * @param {string} mandatorPublicId The voucher belongs to the given mandator.
         * @param {string} amount The vouchers amount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRandomVoucher(mandatorPublicId: string, amount: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeDependentProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRandomVoucher(mandatorPublicId, amount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.createRandomVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary \"Delete all accounts for the currently configured mock bank connection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountsForMockBankConnection(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccountsForMockBankConnection(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.deleteAccountsForMockBankConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the users email confirmation token.                      This endpoint is only available on quality assurance environments.
         * @param {string} email Get the email confirmation token for the user matching the given email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailChangeToken(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailChangeToken(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QualityAssuranceApi.emailChangeToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QualityAssuranceApi - factory interface
 * @export
 */
export const QualityAssuranceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QualityAssuranceApiFp(configuration)
    return {
        /**
         * 
         * @summary \"Set the configuration for banking API mocking. This endpoint is only available on                 quality assurance environments.
         * @param {QualityAssuranceApiConfigureMockBankingApiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureMockBankingApi(requestParameters: QualityAssuranceApiConfigureMockBankingApiRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.configureMockBankingApi(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm the users email address.                      This endpoint is only available on quality assurance environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserQA(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.confirmUserQA(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Programmatically create a new, empty user. The users username, password and user reference                      are returned. This endpoint is only available on quality assurance environments
         * @param {QualityAssuranceApiCreateRandomUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRandomUser(requestParameters: QualityAssuranceApiCreateRandomUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<RandomUser> {
            return localVarFp.createRandomUser(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Programmatically create a new, random voucher that is valid from one year ago to one year                      from now. The voucher belongs to the given mandator.
         * @param {QualityAssuranceApiCreateRandomVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRandomVoucher(requestParameters: QualityAssuranceApiCreateRandomVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<TimeDependentProperty> {
            return localVarFp.createRandomVoucher(requestParameters.mandatorPublicId, requestParameters.amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary \"Delete all accounts for the currently configured mock bank connection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountsForMockBankConnection(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAccountsForMockBankConnection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the users email confirmation token.                      This endpoint is only available on quality assurance environments.
         * @param {QualityAssuranceApiEmailChangeTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailChangeToken(requestParameters: QualityAssuranceApiEmailChangeTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.emailChangeToken(requestParameters.email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for configureMockBankingApi operation in QualityAssuranceApi.
 * @export
 * @interface QualityAssuranceApiConfigureMockBankingApiRequest
 */
export interface QualityAssuranceApiConfigureMockBankingApiRequest {
    /**
     * Defines which account to link
     * @type {MockBankConnectionConfigurationParams}
     * @memberof QualityAssuranceApiConfigureMockBankingApi
     */
    readonly body: MockBankConnectionConfigurationParams
}

/**
 * Request parameters for createRandomUser operation in QualityAssuranceApi.
 * @export
 * @interface QualityAssuranceApiCreateRandomUserRequest
 */
export interface QualityAssuranceApiCreateRandomUserRequest {
    /**
     * Parameters for creating a random user
     * @type {CreateRandomUserParams}
     * @memberof QualityAssuranceApiCreateRandomUser
     */
    readonly body: CreateRandomUserParams
}

/**
 * Request parameters for createRandomVoucher operation in QualityAssuranceApi.
 * @export
 * @interface QualityAssuranceApiCreateRandomVoucherRequest
 */
export interface QualityAssuranceApiCreateRandomVoucherRequest {
    /**
     * The voucher belongs to the given mandator.
     * @type {string}
     * @memberof QualityAssuranceApiCreateRandomVoucher
     */
    readonly mandatorPublicId: string

    /**
     * The vouchers amount.
     * @type {string}
     * @memberof QualityAssuranceApiCreateRandomVoucher
     */
    readonly amount: string
}

/**
 * Request parameters for emailChangeToken operation in QualityAssuranceApi.
 * @export
 * @interface QualityAssuranceApiEmailChangeTokenRequest
 */
export interface QualityAssuranceApiEmailChangeTokenRequest {
    /**
     * Get the email confirmation token for the user matching the given email address.
     * @type {string}
     * @memberof QualityAssuranceApiEmailChangeToken
     */
    readonly email: string
}

/**
 * QualityAssuranceApi - object-oriented interface
 * @export
 * @class QualityAssuranceApi
 * @extends {BaseAPI}
 */
export class QualityAssuranceApi extends BaseAPI {
    /**
     * 
     * @summary \"Set the configuration for banking API mocking. This endpoint is only available on                 quality assurance environments.
     * @param {QualityAssuranceApiConfigureMockBankingApiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public configureMockBankingApi(requestParameters: QualityAssuranceApiConfigureMockBankingApiRequest, options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).configureMockBankingApi(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm the users email address.                      This endpoint is only available on quality assurance environments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public confirmUserQA(options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).confirmUserQA(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Programmatically create a new, empty user. The users username, password and user reference                      are returned. This endpoint is only available on quality assurance environments
     * @param {QualityAssuranceApiCreateRandomUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public createRandomUser(requestParameters: QualityAssuranceApiCreateRandomUserRequest, options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).createRandomUser(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Programmatically create a new, random voucher that is valid from one year ago to one year                      from now. The voucher belongs to the given mandator.
     * @param {QualityAssuranceApiCreateRandomVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public createRandomVoucher(requestParameters: QualityAssuranceApiCreateRandomVoucherRequest, options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).createRandomVoucher(requestParameters.mandatorPublicId, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary \"Delete all accounts for the currently configured mock bank connection.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public deleteAccountsForMockBankConnection(options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).deleteAccountsForMockBankConnection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the users email confirmation token.                      This endpoint is only available on quality assurance environments.
     * @param {QualityAssuranceApiEmailChangeTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityAssuranceApi
     */
    public emailChangeToken(requestParameters: QualityAssuranceApiEmailChangeTokenRequest, options?: RawAxiosRequestConfig) {
        return QualityAssuranceApiFp(this.configuration).emailChangeToken(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }
}

