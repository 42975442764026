import {
  AssetReportsApi,
  BankConnectionsApi,
  BankErrorsReportsApi,
  BankHealthReportsApi,
  BankMessagesApi,
  BankSyncsApi,
  BanksApi,
  CategoriesApi,
  CSVApi,
  EtfTypeClassificationApi,
  IsinNamesApi,
  PlugAndPlayApi,
  RateLimitApi,
  SocialStatisticsApi,
  SplitsApi,
  SubcategoriesApi,
  TrackingEventsApi,
  UserReportsApi,
} from "@generated/apiv3";

import { withToken } from "@api/auth/withToken";
import {
  buildAnonymousConfiguration,
  buildConfiguration,
} from "@api/v3/buildConfiguration";

export const buildAssetReportsApiWithToken = withToken(
  async (token: string) => new AssetReportsApi(buildConfiguration(token))
);
export const buildAnonymousAssetReportsApi = () =>
  new AssetReportsApi(buildAnonymousConfiguration());
export const buildAssetReportsApi = (token?: string) =>
  new AssetReportsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankConnectionsApiWithToken = withToken(
  async (token: string) => new BankConnectionsApi(buildConfiguration(token))
);
export const buildAnonymousBankConnectionsApi = () =>
  new BankConnectionsApi(buildAnonymousConfiguration());
export const buildBankConnectionsApi = (token?: string) =>
  new BankConnectionsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankErrorsReportsApiWithToken = withToken(
  async (token: string) => new BankErrorsReportsApi(buildConfiguration(token))
);
export const buildAnonymousBankErrorsReportsApi = () =>
  new BankErrorsReportsApi(buildAnonymousConfiguration());
export const buildBankErrorsReportsApi = (token?: string) =>
  new BankErrorsReportsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankHealthReportsApiWithToken = withToken(
  async (token: string) => new BankHealthReportsApi(buildConfiguration(token))
);
export const buildAnonymousBankHealthReportsApi = () =>
  new BankHealthReportsApi(buildAnonymousConfiguration());
export const buildBankHealthReportsApi = (token?: string) =>
  new BankHealthReportsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankMessagesApiWithToken = withToken(
  async (token: string) => new BankMessagesApi(buildConfiguration(token))
);
export const buildAnonymousBankMessagesApi = () =>
  new BankMessagesApi(buildAnonymousConfiguration());
export const buildBankMessagesApi = (token?: string) =>
  new BankMessagesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBankSyncsApiWithToken = withToken(
  async (token: string) => new BankSyncsApi(buildConfiguration(token))
);
export const buildAnonymousBankSyncsApi = () =>
  new BankSyncsApi(buildAnonymousConfiguration());
export const buildBankSyncsApi = (token?: string) =>
  new BankSyncsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildBanksApiWithToken = withToken(
  async (token: string) => new BanksApi(buildConfiguration(token))
);
export const buildAnonymousBanksApi = () =>
  new BanksApi(buildAnonymousConfiguration());
export const buildBanksApi = (token?: string) =>
  new BanksApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCategoriesApiWithToken = withToken(
  async (token: string) => new CategoriesApi(buildConfiguration(token))
);
export const buildAnonymousCategoriesApi = () =>
  new CategoriesApi(buildAnonymousConfiguration());
export const buildCategoriesApi = (token?: string) =>
  new CategoriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildCSVApiWithToken = withToken(
  async (token: string) => new CSVApi(buildConfiguration(token))
);
export const buildAnonymousCSVApi = () =>
  new CSVApi(buildAnonymousConfiguration());
export const buildCSVApi = (token?: string) =>
  new CSVApi(token ? buildConfiguration(token) : buildAnonymousConfiguration());

export const buildEtfTypeClassificationApiWithToken = withToken(
  async (token: string) =>
    new EtfTypeClassificationApi(buildConfiguration(token))
);
export const buildAnonymousEtfTypeClassificationApi = () =>
  new EtfTypeClassificationApi(buildAnonymousConfiguration());
export const buildEtfTypeClassificationApi = (token?: string) =>
  new EtfTypeClassificationApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildIsinNamesApiWithToken = withToken(
  async (token: string) => new IsinNamesApi(buildConfiguration(token))
);
export const buildAnonymousIsinNamesApi = () =>
  new IsinNamesApi(buildAnonymousConfiguration());
export const buildIsinNamesApi = (token?: string) =>
  new IsinNamesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildPlugAndPlayApiWithToken = withToken(
  async (token: string) => new PlugAndPlayApi(buildConfiguration(token))
);
export const buildAnonymousPlugAndPlayApi = () =>
  new PlugAndPlayApi(buildAnonymousConfiguration());
export const buildPlugAndPlayApi = (token?: string) =>
  new PlugAndPlayApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildRateLimitApiWithToken = withToken(
  async (token: string) => new RateLimitApi(buildConfiguration(token))
);
export const buildAnonymousRateLimitApi = () =>
  new RateLimitApi(buildAnonymousConfiguration());
export const buildRateLimitApi = (token?: string) =>
  new RateLimitApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSocialStatisticsApiWithToken = withToken(
  async (token: string) => new SocialStatisticsApi(buildConfiguration(token))
);
export const buildAnonymousSocialStatisticsApi = () =>
  new SocialStatisticsApi(buildAnonymousConfiguration());
export const buildSocialStatisticsApi = (token?: string) =>
  new SocialStatisticsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSplitsApiWithToken = withToken(
  async (token: string) => new SplitsApi(buildConfiguration(token))
);
export const buildAnonymousSplitsApi = () =>
  new SplitsApi(buildAnonymousConfiguration());
export const buildSplitsApi = (token?: string) =>
  new SplitsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildSubcategoriesApiWithToken = withToken(
  async (token: string) => new SubcategoriesApi(buildConfiguration(token))
);
export const buildAnonymousSubcategoriesApi = () =>
  new SubcategoriesApi(buildAnonymousConfiguration());
export const buildSubcategoriesApi = (token?: string) =>
  new SubcategoriesApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildTrackingEventsApiWithToken = withToken(
  async (token: string) => new TrackingEventsApi(buildConfiguration(token))
);
export const buildAnonymousTrackingEventsApi = () =>
  new TrackingEventsApi(buildAnonymousConfiguration());
export const buildTrackingEventsApi = (token?: string) =>
  new TrackingEventsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildUserReportsApiWithToken = withToken(
  async (token: string) => new UserReportsApi(buildConfiguration(token))
);
export const buildAnonymousUserReportsApi = () =>
  new UserReportsApi(buildAnonymousConfiguration());
export const buildUserReportsApi = (token?: string) =>
  new UserReportsApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );
