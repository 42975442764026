/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankInterfaceLoginField } from './bank-interface-login-field';
// May contain unused imports in some cases
// @ts-ignore
import { TppAuthenticationGroup } from './tpp-authentication-group';

/**
 * Interface used to connect to a bank
 * @export
 * @interface BankInterface
 */
export interface BankInterface {
    /**
     * 
     * @type {TppAuthenticationGroup}
     * @memberof BankInterface
     */
    'tppAuthenticationGroup'?: TppAuthenticationGroup;
    /**
     * Login credentials fields which should be shown to the user.
     * @type {Array<BankInterfaceLoginField>}
     * @memberof BankInterface
     */
    'loginCredentials': Array<BankInterfaceLoginField>;
    /**
     * Set of interface properties/specifics. Possible values:<br><br>&bull; <code>REDIRECT_APPROACH</code> - means that the interface uses a redirect approach when authorizing the user. It requires you to pass the \'redirectUrl\' field in all services which define the field. If the user already has imported a bank connection of the same bank that he is about to import, we recommend to confront the user with the question: <blockquote>For the selected bank you have already imported successfully the following accounts: &lt;account list&gt;. Are you sure that you want to import another bank connection from &lt;bank name&gt;? </blockquote>&bull; <code>DECOUPLED_APPROACH</code> - means that the interface can trigger a decoupled approval during user authorization.<br/><br/>&bull; <code>DETAILED_CONSENT</code> - means that the interface requires a list of account references when authorizing the user. It requires you to pass the \'accountReferences\' field in all services which define the field.<br/><br/>Note that this set can be empty, if the interface does not have any specific properties.
     * @type {Array<string>}
     * @memberof BankInterface
     */
    'properties'?: Array<BankInterfacePropertiesEnum>;
    /**
     * Login hint. Contains a German message for the user that explains what kind of credentials are expected.<br/><br/>Please note that it is essential to always show the login hint to the user if there is one, as the credentials that finAPI requires for the bank might be different to the credentials that the user knows from his online banking.<br/><br/>Also note that the contents of this field should always be interpreted as HTML, as the text might contain HTML tags for highlighted words, paragraphs, etc.
     * @type {string}
     * @memberof BankInterface
     */
    'loginHint'?: string;
    /**
     * The health status of this interface. This is a value between 0 and 100, depicting the percentage of successful communication attempts with the bank via this interface during the latest couple of bank connection imports or updates (across the entire finAPI system). Note that \'successful\' means that there was no technical error trying to establish a communication with the bank. Non-technical errors (like incorrect credentials) are regarded successful communication attempts.
     * @type {number}
     * @memberof BankInterface
     */
    'health': number;
    /**
     * Time of the last communication attempt with this interface during an import, update or connect interface (across the entire finAPI system). The value is returned in the format \'YYYY-MM-DD HH:MM:SS.SSS\' (german time).
     * @type {string}
     * @memberof BankInterface
     */
    'lastCommunicationAttempt'?: string;
    /**
     * Time of the last successful communication with this interface during an import, update or connect interface (across the entire finAPI system). The value is returned in the format \'YYYY-MM-DD HH:MM:SS.SSS\' (german time).
     * @type {string}
     * @memberof BankInterface
     */
    'lastSuccessfulCommunication'?: string;
    /**
     * Whether this interface has the general capability to do money transfers. Note that it still depends on the specifics of an account whether you will actually be able to do money transfers for that account or not - see the field AccountInterface.capabilities for more. In general, you should prefer the field AccountInterface.capabilities to determine what kind of payments an account supports. This field here is meant to be used mainly for when you are planning to do standalone money transfers, i.e. when you do not plan to import an account and thus will not have the data about the account\'s exact capabilities.
     * @type {boolean}
     * @memberof BankInterface
     */
    'isMoneyTransferSupported'?: boolean;
    /**
     * Bank interface. Possible values:<br><br>&bull; <code>FINTS_SERVER</code> - means that finAPI will download data via the bank\'s FinTS server.<br>&bull; <code>WEB_SCRAPER</code> - means that finAPI will parse data from the bank\'s online banking website.<br>&bull; <code>XS2A</code> - means that finAPI will download data via the bank\'s XS2A interface.<br>
     * @type {string}
     * @memberof BankInterface
     */
    'interface': BankInterfaceInterfaceEnum;
}

export const BankInterfacePropertiesEnum = {
    REDIRECT_APPROACH: 'REDIRECT_APPROACH',
    DECOUPLED_APPROACH: 'DECOUPLED_APPROACH',
    DETAILED_CONSENT: 'DETAILED_CONSENT'
} as const;

export type BankInterfacePropertiesEnum = typeof BankInterfacePropertiesEnum[keyof typeof BankInterfacePropertiesEnum];
export const BankInterfaceInterfaceEnum = {
    WEB_SCRAPER: 'WEB_SCRAPER',
    FINTS_SERVER: 'FINTS_SERVER',
    XS2_A: 'XS2A'
} as const;

export type BankInterfaceInterfaceEnum = typeof BankInterfaceInterfaceEnum[keyof typeof BankInterfaceInterfaceEnum];


