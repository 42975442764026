// Important: polyfills must come before the other imports!
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/de";
import "./fonts/fonts.css";

import React from "react";
import { createRoot } from "react-dom/client";
import "@translations/i18n";
import { de } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import { initAxios } from "@api";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initGTM } from "./gtm";

setDefaultOptions({ locale: de });

initAxios({
  baseURL: process.env.REACT_APP_API_BASE_URL!,
  legacyURL: process.env.REACT_APP_WEB_API_URL!,
  rentabloURL: process.env.REACT_APP_API_RENTABLO_URL!,
  ffURL: process.env.REACT_APP_FF_API_BASE_URL!,
});
initGTM();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
