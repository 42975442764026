import { UseMutationOptions } from "react-query";
import {
  QualityAssuranceApiCreateRandomUserRequest,
  RandomUser,
} from "@generated/apiv1";

import { buildQualityAssuranceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createRandomUser = async (
  token: string | undefined,
  params: QualityAssuranceApiCreateRandomUserRequest
) => {
  const apiInstance = buildQualityAssuranceApi(token);
  const { data } = await apiInstance.createRandomUser(params);
  return data;
};

export const useCreateRandomUser = (
  options?: UseMutationOptions<
    RandomUser,
    AxiosApiError,
    QualityAssuranceApiCreateRandomUserRequest
  >
) => {
  return useAuthMutation(createRandomUser, options, false);
};
