/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InternalTransaction } from '../model';
// @ts-ignore
import { InternalTransactionList } from '../model';
// @ts-ignore
import { UpdateInternalTransactionParams } from '../model';
/**
 * InternalTransactionsApi - axios parameter creator
 * @export
 */
export const InternalTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the XML for a transaction batch
         * @param {number} id Billing cycle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchTransferXml: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBatchTransferXml', 'id', id)
            const localVarPath = `/v1/internalTransactions/batch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the internal transaction with the given ID
         * @param {number} id Retrieve the given internal transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTransactionsId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalTransactionsId', 'id', id)
            const localVarPath = `/v1/internalTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of internal transactions
         * @param {Array<number>} [id] Retrieve the given internal transactions
         * @param {Array<number>} [billingCycleId] Retrieve the internal transactions for the given billing cycles
         * @param {Array<string>} [status] Filter by the given status(es)
         * @param {string} [executedAfter] Only retrieve transactions with executed at date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [executedBefore] Only retrieve transactions with executed at date equal or before the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInternalTransactions: async (id?: Array<number>, billingCycleId?: Array<number>, status?: Array<string>, executedAfter?: string, executedBefore?: string, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/internalTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (billingCycleId) {
                localVarQueryParameter['billingCycleId'] = billingCycleId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (executedAfter !== undefined) {
                localVarQueryParameter['executedAfter'] = executedAfter;
            }

            if (executedBefore !== undefined) {
                localVarQueryParameter['executedBefore'] = executedBefore;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the entire transaction batch as executed
         * @param {number} id Mark the entire batch of transactions for the given billing cycle as executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markBatchTransferAsExecuted: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markBatchTransferAsExecuted', 'id', id)
            const localVarPath = `/v1/internalTransactions/batch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the internal transaction with the given ID
         * @param {number} id Update the given internal transaction
         * @param {UpdateInternalTransactionParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInternalTransaction: async (id: number, body: UpdateInternalTransactionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInternalTransaction', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateInternalTransaction', 'body', body)
            const localVarPath = `/v1/internalTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalTransactionsApi - functional programming interface
 * @export
 */
export const InternalTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the XML for a transaction batch
         * @param {number} id Billing cycle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchTransferXml(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchTransferXml(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalTransactionsApi.getBatchTransferXml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the internal transaction with the given ID
         * @param {number} id Retrieve the given internal transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalTransactionsId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalTransactionsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalTransactionsApi.internalTransactionsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of internal transactions
         * @param {Array<number>} [id] Retrieve the given internal transactions
         * @param {Array<number>} [billingCycleId] Retrieve the internal transactions for the given billing cycles
         * @param {Array<string>} [status] Filter by the given status(es)
         * @param {string} [executedAfter] Only retrieve transactions with executed at date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [executedBefore] Only retrieve transactions with executed at date equal or before the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllInternalTransactions(id?: Array<number>, billingCycleId?: Array<number>, status?: Array<string>, executedAfter?: string, executedBefore?: string, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTransactionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllInternalTransactions(id, billingCycleId, status, executedAfter, executedBefore, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalTransactionsApi.listAndSearchAllInternalTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Mark the entire transaction batch as executed
         * @param {number} id Mark the entire batch of transactions for the given billing cycle as executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markBatchTransferAsExecuted(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTransactionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markBatchTransferAsExecuted(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalTransactionsApi.markBatchTransferAsExecuted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the internal transaction with the given ID
         * @param {number} id Update the given internal transaction
         * @param {UpdateInternalTransactionParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInternalTransaction(id: number, body: UpdateInternalTransactionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInternalTransaction(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalTransactionsApi.updateInternalTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InternalTransactionsApi - factory interface
 * @export
 */
export const InternalTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the XML for a transaction batch
         * @param {InternalTransactionsApiGetBatchTransferXmlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchTransferXml(requestParameters: InternalTransactionsApiGetBatchTransferXmlRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getBatchTransferXml(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the internal transaction with the given ID
         * @param {InternalTransactionsApiInternalTransactionsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalTransactionsId(requestParameters: InternalTransactionsApiInternalTransactionsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<InternalTransaction> {
            return localVarFp.internalTransactionsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of internal transactions
         * @param {InternalTransactionsApiListAndSearchAllInternalTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInternalTransactions(requestParameters: InternalTransactionsApiListAndSearchAllInternalTransactionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InternalTransactionList> {
            return localVarFp.listAndSearchAllInternalTransactions(requestParameters.id, requestParameters.billingCycleId, requestParameters.status, requestParameters.executedAfter, requestParameters.executedBefore, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark the entire transaction batch as executed
         * @param {InternalTransactionsApiMarkBatchTransferAsExecutedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markBatchTransferAsExecuted(requestParameters: InternalTransactionsApiMarkBatchTransferAsExecutedRequest, options?: RawAxiosRequestConfig): AxiosPromise<InternalTransactionList> {
            return localVarFp.markBatchTransferAsExecuted(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the internal transaction with the given ID
         * @param {InternalTransactionsApiUpdateInternalTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInternalTransaction(requestParameters: InternalTransactionsApiUpdateInternalTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<InternalTransaction> {
            return localVarFp.updateInternalTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBatchTransferXml operation in InternalTransactionsApi.
 * @export
 * @interface InternalTransactionsApiGetBatchTransferXmlRequest
 */
export interface InternalTransactionsApiGetBatchTransferXmlRequest {
    /**
     * Billing cycle ID
     * @type {number}
     * @memberof InternalTransactionsApiGetBatchTransferXml
     */
    readonly id: number
}

/**
 * Request parameters for internalTransactionsId operation in InternalTransactionsApi.
 * @export
 * @interface InternalTransactionsApiInternalTransactionsIdRequest
 */
export interface InternalTransactionsApiInternalTransactionsIdRequest {
    /**
     * Retrieve the given internal transaction
     * @type {number}
     * @memberof InternalTransactionsApiInternalTransactionsId
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllInternalTransactions operation in InternalTransactionsApi.
 * @export
 * @interface InternalTransactionsApiListAndSearchAllInternalTransactionsRequest
 */
export interface InternalTransactionsApiListAndSearchAllInternalTransactionsRequest {
    /**
     * Retrieve the given internal transactions
     * @type {Array<number>}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly id?: Array<number>

    /**
     * Retrieve the internal transactions for the given billing cycles
     * @type {Array<number>}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly billingCycleId?: Array<number>

    /**
     * Filter by the given status(es)
     * @type {Array<string>}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly status?: Array<string>

    /**
     * Only retrieve transactions with executed at date equal or after the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly executedAfter?: string

    /**
     * Only retrieve transactions with executed at date equal or before the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly executedBefore?: string

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof InternalTransactionsApiListAndSearchAllInternalTransactions
     */
    readonly order?: string
}

/**
 * Request parameters for markBatchTransferAsExecuted operation in InternalTransactionsApi.
 * @export
 * @interface InternalTransactionsApiMarkBatchTransferAsExecutedRequest
 */
export interface InternalTransactionsApiMarkBatchTransferAsExecutedRequest {
    /**
     * Mark the entire batch of transactions for the given billing cycle as executed
     * @type {number}
     * @memberof InternalTransactionsApiMarkBatchTransferAsExecuted
     */
    readonly id: number
}

/**
 * Request parameters for updateInternalTransaction operation in InternalTransactionsApi.
 * @export
 * @interface InternalTransactionsApiUpdateInternalTransactionRequest
 */
export interface InternalTransactionsApiUpdateInternalTransactionRequest {
    /**
     * Update the given internal transaction
     * @type {number}
     * @memberof InternalTransactionsApiUpdateInternalTransaction
     */
    readonly id: number

    /**
     * The account details
     * @type {UpdateInternalTransactionParams}
     * @memberof InternalTransactionsApiUpdateInternalTransaction
     */
    readonly body: UpdateInternalTransactionParams
}

/**
 * InternalTransactionsApi - object-oriented interface
 * @export
 * @class InternalTransactionsApi
 * @extends {BaseAPI}
 */
export class InternalTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Get the XML for a transaction batch
     * @param {InternalTransactionsApiGetBatchTransferXmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalTransactionsApi
     */
    public getBatchTransferXml(requestParameters: InternalTransactionsApiGetBatchTransferXmlRequest, options?: RawAxiosRequestConfig) {
        return InternalTransactionsApiFp(this.configuration).getBatchTransferXml(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the internal transaction with the given ID
     * @param {InternalTransactionsApiInternalTransactionsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalTransactionsApi
     */
    public internalTransactionsId(requestParameters: InternalTransactionsApiInternalTransactionsIdRequest, options?: RawAxiosRequestConfig) {
        return InternalTransactionsApiFp(this.configuration).internalTransactionsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of internal transactions
     * @param {InternalTransactionsApiListAndSearchAllInternalTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalTransactionsApi
     */
    public listAndSearchAllInternalTransactions(requestParameters: InternalTransactionsApiListAndSearchAllInternalTransactionsRequest = {}, options?: RawAxiosRequestConfig) {
        return InternalTransactionsApiFp(this.configuration).listAndSearchAllInternalTransactions(requestParameters.id, requestParameters.billingCycleId, requestParameters.status, requestParameters.executedAfter, requestParameters.executedBefore, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark the entire transaction batch as executed
     * @param {InternalTransactionsApiMarkBatchTransferAsExecutedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalTransactionsApi
     */
    public markBatchTransferAsExecuted(requestParameters: InternalTransactionsApiMarkBatchTransferAsExecutedRequest, options?: RawAxiosRequestConfig) {
        return InternalTransactionsApiFp(this.configuration).markBatchTransferAsExecuted(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the internal transaction with the given ID
     * @param {InternalTransactionsApiUpdateInternalTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalTransactionsApi
     */
    public updateInternalTransaction(requestParameters: InternalTransactionsApiUpdateInternalTransactionRequest, options?: RawAxiosRequestConfig) {
        return InternalTransactionsApiFp(this.configuration).updateInternalTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

