/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateProfileParams } from '../model';
// @ts-ignore
import { NewsletterSubscriptionStatus } from '../model';
// @ts-ignore
import { Profile } from '../model';
/**
 * UserProfilesApi - axios parameter creator
 * @export
 */
export const UserProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a users profile image
         * @param {number} id ID of the profile to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileImage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserProfileImage', 'id', id)
            const localVarPath = `/v1/userProfiles/{id}/profileImage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the newsletter subscription status for the given profile
         * @param {number} id Retrieve the newsletter subscription for the given profile ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsletterSubscription: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNewsletterSubscription', 'id', id)
            const localVarPath = `/v1/userProfiles/{id}/newsletterSubscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a users profile image, if present
         * @param {number} id Retrieve the profile image for the profile with the given ID
         * @param {boolean} [thumbnail] Return the image as thumbnail (this is a version with reduced image dimensions);                              default is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImage: async (id: number, thumbnail?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileImage', 'id', id)
            const localVarPath = `/v1/userProfiles/{id}/profileImage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (thumbnail !== undefined) {
                localVarQueryParameter['thumbnail'] = thumbnail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set or update a users profile image
         * @param {number} id ID of the profile to update
         * @param {File} image The profile image to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserProfileImage: async (id: number, image: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setUserProfileImage', 'id', id)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('setUserProfileImage', 'image', image)
            const localVarPath = `/v1/userProfiles/{id}/profileImage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe the given profile to the newsletter
         * @param {number} id ID of the profile to subscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToNewsletter: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscribeToNewsletter', 'id', id)
            const localVarPath = `/v1/userProfiles/{id}/newsletterSubscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsubscribe the given profile from the newsletter
         * @param {number} id ID of the profile to unsubscribe from the newsletter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromNewsletter: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unsubscribeFromNewsletter', 'id', id)
            const localVarPath = `/v1/userProfiles/{id}/newsletterSubscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a users profile
         * @param {number} id ID of the profile to update
         * @param {CreateOrUpdateProfileParams} body The profile parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (id: number, body: CreateOrUpdateProfileParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserProfile', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserProfile', 'body', body)
            const localVarPath = `/v1/userProfiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user profile
         * @param {number} [id] ID of the profile to retrieve; will retrieve the currently logged in users profile                              if not specified. For \&#39;standard users\&#39; (ROLE_USER) only public data is included unless                              the user is viewing his own profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/userProfiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfilesApi - functional programming interface
 * @export
 */
export const UserProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a users profile image
         * @param {number} id ID of the profile to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileImage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileImage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.deleteUserProfileImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the newsletter subscription status for the given profile
         * @param {number} id Retrieve the newsletter subscription for the given profile ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsletterSubscription(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsletterSubscriptionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsletterSubscription(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.getNewsletterSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a users profile image, if present
         * @param {number} id Retrieve the profile image for the profile with the given ID
         * @param {boolean} [thumbnail] Return the image as thumbnail (this is a version with reduced image dimensions);                              default is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileImage(id: number, thumbnail?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileImage(id, thumbnail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.profileImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set or update a users profile image
         * @param {number} id ID of the profile to update
         * @param {File} image The profile image to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserProfileImage(id: number, image: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserProfileImage(id, image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.setUserProfileImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Subscribe the given profile to the newsletter
         * @param {number} id ID of the profile to subscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeToNewsletter(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsletterSubscriptionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeToNewsletter(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.subscribeToNewsletter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unsubscribe the given profile from the newsletter
         * @param {number} id ID of the profile to unsubscribe from the newsletter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeFromNewsletter(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeFromNewsletter(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.unsubscribeFromNewsletter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a users profile
         * @param {number} id ID of the profile to update
         * @param {CreateOrUpdateProfileParams} body The profile parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(id: number, body: CreateOrUpdateProfileParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.updateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a user profile
         * @param {number} [id] ID of the profile to retrieve; will retrieve the currently logged in users profile                              if not specified. For \&#39;standard users\&#39; (ROLE_USER) only public data is included unless                              the user is viewing his own profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfile(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfilesApi.userProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfilesApi - factory interface
 * @export
 */
export const UserProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a users profile image
         * @param {UserProfilesApiDeleteUserProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileImage(requestParameters: UserProfilesApiDeleteUserProfileImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserProfileImage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the newsletter subscription status for the given profile
         * @param {UserProfilesApiGetNewsletterSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsletterSubscription(requestParameters: UserProfilesApiGetNewsletterSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewsletterSubscriptionStatus> {
            return localVarFp.getNewsletterSubscription(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a users profile image, if present
         * @param {UserProfilesApiProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImage(requestParameters: UserProfilesApiProfileImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.profileImage(requestParameters.id, requestParameters.thumbnail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set or update a users profile image
         * @param {UserProfilesApiSetUserProfileImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserProfileImage(requestParameters: UserProfilesApiSetUserProfileImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setUserProfileImage(requestParameters.id, requestParameters.image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe the given profile to the newsletter
         * @param {UserProfilesApiSubscribeToNewsletterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToNewsletter(requestParameters: UserProfilesApiSubscribeToNewsletterRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewsletterSubscriptionStatus> {
            return localVarFp.subscribeToNewsletter(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsubscribe the given profile from the newsletter
         * @param {UserProfilesApiUnsubscribeFromNewsletterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromNewsletter(requestParameters: UserProfilesApiUnsubscribeFromNewsletterRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.unsubscribeFromNewsletter(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a users profile
         * @param {UserProfilesApiUpdateUserProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(requestParameters: UserProfilesApiUpdateUserProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<Profile> {
            return localVarFp.updateUserProfile(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user profile
         * @param {UserProfilesApiUserProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile(requestParameters: UserProfilesApiUserProfileRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Profile> {
            return localVarFp.userProfile(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserProfileImage operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiDeleteUserProfileImageRequest
 */
export interface UserProfilesApiDeleteUserProfileImageRequest {
    /**
     * ID of the profile to update
     * @type {number}
     * @memberof UserProfilesApiDeleteUserProfileImage
     */
    readonly id: number
}

/**
 * Request parameters for getNewsletterSubscription operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiGetNewsletterSubscriptionRequest
 */
export interface UserProfilesApiGetNewsletterSubscriptionRequest {
    /**
     * Retrieve the newsletter subscription for the given profile ID
     * @type {number}
     * @memberof UserProfilesApiGetNewsletterSubscription
     */
    readonly id: number
}

/**
 * Request parameters for profileImage operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiProfileImageRequest
 */
export interface UserProfilesApiProfileImageRequest {
    /**
     * Retrieve the profile image for the profile with the given ID
     * @type {number}
     * @memberof UserProfilesApiProfileImage
     */
    readonly id: number

    /**
     * Return the image as thumbnail (this is a version with reduced image dimensions);                              default is false
     * @type {boolean}
     * @memberof UserProfilesApiProfileImage
     */
    readonly thumbnail?: boolean
}

/**
 * Request parameters for setUserProfileImage operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiSetUserProfileImageRequest
 */
export interface UserProfilesApiSetUserProfileImageRequest {
    /**
     * ID of the profile to update
     * @type {number}
     * @memberof UserProfilesApiSetUserProfileImage
     */
    readonly id: number

    /**
     * The profile image to upload
     * @type {File}
     * @memberof UserProfilesApiSetUserProfileImage
     */
    readonly image: File
}

/**
 * Request parameters for subscribeToNewsletter operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiSubscribeToNewsletterRequest
 */
export interface UserProfilesApiSubscribeToNewsletterRequest {
    /**
     * ID of the profile to subscribe
     * @type {number}
     * @memberof UserProfilesApiSubscribeToNewsletter
     */
    readonly id: number
}

/**
 * Request parameters for unsubscribeFromNewsletter operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiUnsubscribeFromNewsletterRequest
 */
export interface UserProfilesApiUnsubscribeFromNewsletterRequest {
    /**
     * ID of the profile to unsubscribe from the newsletter
     * @type {number}
     * @memberof UserProfilesApiUnsubscribeFromNewsletter
     */
    readonly id: number
}

/**
 * Request parameters for updateUserProfile operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiUpdateUserProfileRequest
 */
export interface UserProfilesApiUpdateUserProfileRequest {
    /**
     * ID of the profile to update
     * @type {number}
     * @memberof UserProfilesApiUpdateUserProfile
     */
    readonly id: number

    /**
     * The profile parameters
     * @type {CreateOrUpdateProfileParams}
     * @memberof UserProfilesApiUpdateUserProfile
     */
    readonly body: CreateOrUpdateProfileParams
}

/**
 * Request parameters for userProfile operation in UserProfilesApi.
 * @export
 * @interface UserProfilesApiUserProfileRequest
 */
export interface UserProfilesApiUserProfileRequest {
    /**
     * ID of the profile to retrieve; will retrieve the currently logged in users profile                              if not specified. For \&#39;standard users\&#39; (ROLE_USER) only public data is included unless                              the user is viewing his own profile
     * @type {number}
     * @memberof UserProfilesApiUserProfile
     */
    readonly id?: number
}

/**
 * UserProfilesApi - object-oriented interface
 * @export
 * @class UserProfilesApi
 * @extends {BaseAPI}
 */
export class UserProfilesApi extends BaseAPI {
    /**
     * 
     * @summary Delete a users profile image
     * @param {UserProfilesApiDeleteUserProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public deleteUserProfileImage(requestParameters: UserProfilesApiDeleteUserProfileImageRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).deleteUserProfileImage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the newsletter subscription status for the given profile
     * @param {UserProfilesApiGetNewsletterSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public getNewsletterSubscription(requestParameters: UserProfilesApiGetNewsletterSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).getNewsletterSubscription(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a users profile image, if present
     * @param {UserProfilesApiProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public profileImage(requestParameters: UserProfilesApiProfileImageRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).profileImage(requestParameters.id, requestParameters.thumbnail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set or update a users profile image
     * @param {UserProfilesApiSetUserProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public setUserProfileImage(requestParameters: UserProfilesApiSetUserProfileImageRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).setUserProfileImage(requestParameters.id, requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe the given profile to the newsletter
     * @param {UserProfilesApiSubscribeToNewsletterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public subscribeToNewsletter(requestParameters: UserProfilesApiSubscribeToNewsletterRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).subscribeToNewsletter(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsubscribe the given profile from the newsletter
     * @param {UserProfilesApiUnsubscribeFromNewsletterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public unsubscribeFromNewsletter(requestParameters: UserProfilesApiUnsubscribeFromNewsletterRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).unsubscribeFromNewsletter(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a users profile
     * @param {UserProfilesApiUpdateUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public updateUserProfile(requestParameters: UserProfilesApiUpdateUserProfileRequest, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).updateUserProfile(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user profile
     * @param {UserProfilesApiUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public userProfile(requestParameters: UserProfilesApiUserProfileRequest = {}, options?: RawAxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).userProfile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

