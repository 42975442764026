import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from "@icons/myicons/eye.svg";
import { ReactComponent as DotsIcon } from "@icons/dots.svg";

export const ChartMenu = ({ onHide }: { onHide: () => void }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color="grey"
        size="small"
        data-testid="chart-menu"
      >
        <DotsIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="chart-menu-hide"
          onClick={() => {
            onHide();
          }}
        >
          <ListItemIcon>
            <EyeIcon />
          </ListItemIcon>
          <ListItemText primary={t("fundamentals.chartMenu.hide")} />
        </MenuItem>
      </Menu>
    </div>
  );
};
