import { UseQueryOptions } from "react-query";
import {
  AccountsApiHistoricValuationsRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const historicValuations = async (
  token: string | undefined,
  params: AccountsApiHistoricValuationsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.historicValuations(params);
  return data;
};

export const useHistoricValuations = (
  params: AccountsApiHistoricValuationsRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_HISTORIC_VALUATIONS, params],
    ({ token }) => historicValuations(token, params),
    options
  );
};
