import { UseMutationOptions } from "react-query";
import { SplitsApiBatchImportSplitsRequest } from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const batchImportSplits = async (
  token: string | undefined,
  params?: SplitsApiBatchImportSplitsRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.batchImportSplits(params);
  return data;
};

export const useBatchImportSplits = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    SplitsApiBatchImportSplitsRequest
  >
) => {
  return useAuthMutation(batchImportSplits, options, false);
};
