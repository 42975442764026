import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Watchlist } from "@api";
import { Watchlist as WatchlistV2 } from "@generated/apiv1";

type WatchlistV2Required = Omit<WatchlistV2, "id"> & { id: number };

interface WatchlistSelectorProps {
  watchlists: (Watchlist | WatchlistV2Required)[];
  currentWatchlist?: Watchlist | WatchlistV2Required;
  onSelect: (watchlist: Watchlist | WatchlistV2Required) => void;
}

export const WatchlistSelector = (props: WatchlistSelectorProps) => {
  const { watchlists, currentWatchlist, onSelect } = props;
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedWatchlist = watchlists.find(
      (watchlist) => watchlist.id === event.target.value
    );
    if (!selectedWatchlist) return;
    onSelect(selectedWatchlist);
  };

  if (!watchlists?.length || !currentWatchlist) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="watchlist-selector-label">
        {t("watchlists.title")}
      </InputLabel>
      <Select
        data-testid="watchlist-select"
        labelId="watchlist-selector-label"
        value={currentWatchlist?.id}
        onChange={handleChange}
        inputProps={{
          "data-testid": `watchlist-select-input-${currentWatchlist?.name}`,
        }}
        MenuProps={{
          disablePortal: true,
        }}
      >
        {watchlists.map((item) => {
          return (
            <MenuItem
              key={item.id}
              value={item.id}
              data-testid={`watchlist-select-item-${item.name}`}
              selected={item.id === currentWatchlist?.id}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
