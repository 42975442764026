import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import { Divider } from "@mui/material";
import { isBeforeOrEqual } from "@helpers";
import { SplitValues } from "../stateMachine/types/splitValues";
import { StepContainer } from "../StepContainer";
import { SplitBooking } from "./SplitBooking";

const Container = styled.div`
  width: 100%;
  & > :not(style) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export interface Props {
  splitValues: SplitValues;
  handleNext: () => void;
  handlePrevious: () => void;
}

const nolAfterSplit = (nol: number, splitRatio: number, isReverse: boolean) =>
  isReverse ? nol / splitRatio : nol * splitRatio;

export const SplitPreviewStep = ({
  splitValues,
  handleNext,
  handlePrevious,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StepContainer
      testid="split-preview-step"
      title={t(
        `transactionWizard.splitPreviewStep.${
          splitValues.isReverse ? "titleReverse" : "title"
        }`
      )}
      onNext={() => {
        handleNext();
      }}
      nextLabel={t("transactionWizard.splitPreviewStep.persist")}
      onPrevious={handlePrevious}
    >
      <Container>
        {(splitValues.bookings || []).map((booking) => {
          const isSplit = isBeforeOrEqual(
            parseISO(booking.valueDate),
            splitValues.date!
          );

          return (
            <React.Fragment key={booking.id}>
              <SplitBooking
                testId={`booking-${booking.id}`}
                bookingDate={parseISO(booking.valueDate)}
                type={booking.type}
                numberOfLots={booking.numberOfLots}
                numberOfLotsAfterSplit={
                  isSplit
                    ? nolAfterSplit(
                        booking.numberOfLots,
                        splitValues.ratio ?? 1,
                        splitValues.isReverse ?? false
                      )
                    : undefined
                }
              />
            </React.Fragment>
          );
        })}
        <Divider />
      </Container>
    </StepContainer>
  );
};
