import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteBankConnectionRequest } from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankConnection(params);
  return data;
};

export const useDeleteBankConnection = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteBankConnectionRequest
  >
) => {
  return useAuthMutation(deleteBankConnection, options, false);
};
