import React from "react";
import { Box } from "@mui/material";
import i18next from "i18next";
import { LoadingButton } from "@components";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  handleNext?: () => void;
  onNextDisabled?: boolean;
  buttonTitle?: string;
};

export const StepContainer = ({
  children,
  isLoading,
  handleNext,
  onNextDisabled,
  buttonTitle = i18next.t("navigation.next"),
}: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"space-between"}
      gap={6}
      height="100%"
    >
      <Box
        sx={{
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
      {handleNext && (
        <LoadingButton
          variant="contained"
          color="primary"
          isLoading={isLoading}
          disabled={onNextDisabled}
          fullWidth
          onClick={handleNext}
        >
          {buttonTitle}
        </LoadingButton>
      )}
    </Box>
  );
};
