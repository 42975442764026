import { UseMutationOptions } from "react-query";
import {
  AccountsApiUpdateRemoteStatusRequest,
  RemoteAccountStatus,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateRemoteStatus = async (
  token: string | undefined,
  params: AccountsApiUpdateRemoteStatusRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.updateRemoteStatus(params);
  return data;
};

export const useUpdateRemoteStatus = (
  options?: UseMutationOptions<
    RemoteAccountStatus,
    AxiosApiError,
    AccountsApiUpdateRemoteStatusRequest
  >
) => {
  return useAuthMutation(updateRemoteStatus, options, false);
};
