import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import {
  TermsTitle,
  TermsText,
  TermsMainTitle,
  TermsSecondSubheader,
} from "@components/common/layouts/TppWebForm/shared";

interface ImpressumTppWebFormProps {
  showTitle?: boolean;
}

export const ImpressumTppWebForm = (props: ImpressumTppWebFormProps) => {
  const { showTitle = true } = props;
  const { t } = useTranslation();

  return (
    <div>
      {showTitle && (
        <TermsMainTitle variant="h1" color="textPrimary">
          Impressum
        </TermsMainTitle>
      )}

      <TermsSecondSubheader variant="subtitle2" color="textPrimary">
        {t("aboutCompany.b2b.name")} - {t("aboutCompany.b2b.claim")}
      </TermsSecondSubheader>

      <TermsText variant="body1" color="textPrimary">
        Betreiber des Internetangebots:
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>{t("aboutCompany.b2b.name")}</b>
        <br />
        {t("aboutCompany.company.street")}{" "}
        {t("aboutCompany.company.streetNumber")}
        <br />
        {t("aboutCompany.company.zip")} {t("aboutCompany.company.city")}
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Email:{" "}
        <Link href={`mailto:${t("aboutCompany.b2b.email")}`} underline="hover">
          {t("aboutCompany.b2b.email")}
        </Link>
        <br />
        Web:{" "}
        <Link href={t("aboutCompany.b2b.website")} underline="hover">
          {t("aboutCompany.b2b.website")}
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Handelsregister: Berlin Charlottenburg HRB 166061 B<br />
        UStID: DE297600002
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Geschäftsführung und inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:
        Dipl. Btrw. André Rabenstein (CEO), Dr. Wolfram Stacklies (CTO) -
        Anschrift wie oben
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Zulassung als Kontoinformationsdienstleister (KID):</b>
        <br />
        {t("aboutCompany.b2b.name")} ist ein von der Bundesanstalt für
        Finanzdienstleistungsaufsicht (BaFin) regulierter Zahlungsdienst. Die
        offizielle Erlaubnis zur Erbringung von Kontoinformationsdiensten gemäß
        §1 Abs. 1 Satz 2 Nr. 8 ZAG hat die wealthAPI am 23.08.2019 von der BaFin
        erhalten und kann unter{" "}
        <Link
          href="https://portal.mvp.bafin.de/database/ZahlInstInfo/zahlinst.do?id=152048"
          target="_blank"
          underline="hover"
        >
          https://portal.mvp.bafin.de/database/ZahlInstInfo/zahlinst.do?id=152048
        </Link>{" "}
        eingesehen werden.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Erlaubnis:</b>
        <br />
        Innerhalb der Bundesrepublik Deutschland und Österreich ist eine
        Gewerbeerlaubnis für die Tätigkeit gem. § 34c,d,f Abs. 1 GewO vorhanden.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Status:</b>
        <br />
        Immobilienmakler mit Erlaubnis nach § 34 c Abs. 1 GewO
        <br />
        Versicherungsmakler mit Erlaubnis nach § 34 d Abs. 1 GewO
        (Registrierungsnummer § 34d: D-EY9B-XMREB-80)
        <br />
        Finanzanlagenvermittler gemäß § 34f Abs.1 GewO (Registrierungsnummer §
        34f: D-F-155-335Y-41)
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Umfang der Erlaubnis:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Anteile oder Aktien an inländischen offenen Investmentvermögen, offenen
        EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, die
        nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen (§ 34 f Abs. 1
        Nr. 1 GewO)
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen,
        geschlossenen EU-Investmentvermögen oder ausländischen geschlossenen
        Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben
        werden dürfen (§ 34 f Abs. 1 Nr. 2 GewO)
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagengesetzes
        (§ 34 f Abs. 1 Nr. 3 GewO)
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>
          Die Erlaubnis wurde erteilt von der zuständigen Erlaubnis- und
          Aufsichtsbehörde:
        </b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        {" "}
        Ordnungsamt Tempelhof-Schöneberg
        <br />
        Tempelhofer Damm 165
        <br />
        12099 Berlin
        <br />
        Tel: 030/ 90 277 - 34 60
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Gemeinsame Registerstelle:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Industrie - und Handelskammer Berlin, Fasanenstr. 85, 10623 Berlin
        <br />
        Tel:. 030-31510-0
        <br />
        Fax 030-31510-166
        <br />
        <Link href="http://www.berlin.ihk.de" target="_blank" underline="hover">
          www.berlin.ihk.de
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Gemeinsame Registerstelle im Sinne des § 11 Absatz 1 Nr. 4 VersVermV
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Deutscher Industrie- und Handelskammertag (DIHK) e. V.
        <br />
        Breite Straße 29
        <br />
        10178 Berlin
        <br />
        Tel: 0180/6 00 58 50 (20 Cent/Min aus dem dt. Festnetz, höchstens 60
        Cent/Min aus Mobilfunknetzen)
        <br />
        Registerabruf:{" "}
        <Link
          href="http://www.vermittlerregister.info"
          target="_blank"
          underline="hover"
        >
          www.vermittlerregister.info
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Beteiligungen:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Es besteht keine unmittelbare oder mittelbare Beteiligung über zehn
        Prozent am Stimmrecht oder Kapital eines Versicherers durch
        Maklerunternehmen/Versicherungsmakler.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Es besteht keine unmittelbare oder mittelbare Beteiligung über zehn
        Prozent an Stimmrecht oder Kapital des
        Maklerunternehmens/Versicherungsmakler durch Versicherer.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        Informationen zur Streitschlichtung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische
        Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit,
        die Sie unter{" "}
        <Link
          href="http://ec.europa.eu/consumers/odr"
          target="_blank"
          underline="hover"
        >
          ec.europa.eu/consumers/odr
        </Link>{" "}
        finden. Verbraucher haben die Möglichkeit, diese Plattform für die
        Beilegung ihrer Streitigkeiten zu nutzen. Wir sind im Einzelfall bereit,
        an einem außergerichtlichen Schlichtungsverfahren teilzunehmen.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Schlichtungsstellen:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Versicherungsombudsmann e.V.
        <br />
        Postfach 08 06 32, 10006 Berlin
        <br />
        <Link
          href="http://www.versicherungsombudsmann.de"
          target="_blank"
          underline="hover"
        >
          www.versicherungsombudsmann.de
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Ombudsmann Private Kranken- und Pflegeversicherung
        <br />
        Postfach 06 02 22, 10052 Berlin
        <br />
        <Link
          href="http://www.pkv-ombudsmann.de"
          target="_blank"
          underline="hover"
        >
          www.pkv-ombudsmann.de
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Ombudsstelle für Investmentfonds der Unternehmen des BVI
        <br />
        Unter den Linden 42, 10052 Berlin
        <br />
        <Link
          href="http://www.ombudsstelle-investmentfonds.de"
          target="_blank"
          underline="hover"
        >
          www.ombudsstelle-investmentfonds.de
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Verfahren zur Beschwerdebearbeitung:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Beschwerden sollen in schriftlicher Form an{" "}
        <Link href={`mailto:${t("aboutCompany.b2b.email")}`} underline="hover">
          {t("aboutCompany.b2b.email")}
        </Link>{" "}
        gesendet werden. In dringenden Fällen können Beschwerden auch über die
        wealthAPI Service-Hotline eingereicht werden.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Eine Bearbeitung Ihrer Beschwerde erfolgt grundsätzlich durch den
        wealthAPI Kundenservice. Je nach Art und Umfang des Mangels erfolgt ggf.
        eine betriebsinterne Weiterleitung. Wir versuchen Ihr Anliegen innerhalb
        von fünf Werktagen zu bearbeiten. Bei Vorgängen, die Partnerbanken
        betreffen haben wir auf die Bearbeitszeiten der Bank leider keinen
        Einfluss.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        {" "}
        <b>Berufsrechtliche Regelungen:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        - § 34 d Gewerbeordnung
        <br />
        - §§ 59-68 VVG
        <br />- VersVermV
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Die berufsrechtlichen Regelungen können über die vom Bundesministerium
        der Justiz und von der juris GmbH betriebenen Homepage{" "}
        <Link
          href="http://www.gesetze-im-internet.de"
          target="_blank"
          underline="hover"
        >
          www.gesetze-im-internet.de
        </Link>{" "}
        eingesehen und abgerufen werden.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Mitglied der Industrie- und Handelskammer
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Industrie- und Handelskammer zu Berlin
        <br />
        Fasanenstraße 85
        <br />
        10623 Berlin
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>Sonstige:</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Bei allen anderen Streitigkeiten, die im Zusammenhang mit unserer
        Tätigkeit gegenüber einem Verbraucher stehen, können Sie sich an
        folgende staatlich anerkannte Verbraucherschlichtungsstelle wenden:
        <br />
        Allgemeine Verbraucherschlichtungsstelle (beim Zentrum für Schlichtung
        e.V.)
        <br />
        Straßburger Straße 8<br />
        77694 Kehl
        <br />
        Internet:{" "}
        <Link
          href="http://www.verbraucher-schlichter.de"
          target="_blank"
          underline="hover"
        >
          www.verbraucher-schlichter.de
        </Link>
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        Haftungshinweis
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <b>
          Haftung für eigene Inhalte, eingeschränkte Haftung für fremde Inhalte
        </b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Alle Inhalte unseres Internetauftritts wurden mit Sorgfalt und nach
        bestem Gewissen erstellt. Eine Gewähr für die Aktualität,
        Vollständigkeit und Richtigkeit sämtlicher Seiten wird nicht übernommen.
        Wir sind als Dienstanbieter für eigene Inhalte auf den wealthAPI-Seiten
        verantwortlich, jedoch nicht verpflichtet, die übermittelten oder
        gespeicherten fremden Informationen zu überwachen. Dazu zählen auch
        Inhalte, die von wealthAPI-Mitgliedern veröffentlicht werden. Gleichwohl
        überprüfen wir alle Inhalte, zu denen uns eine konkrete Rechtsverletzung
        zur Kenntnis gebracht wird. Wir haften aber frühestens ab dem Zeitpunkt
        der Kenntniserlangung und einer angemessenen Prüffrist.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        {" "}
        <b>Eingeschränkte Haftung für externe Links</b>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI-Seiten enthalten Verknüpfungen zu Webseiten Dritter ("externe
        Links"). Auf deren Inhalt hat wealthAPI keinen Einfluss. Wir übernehmen
        aus diesem Grund keine Gewähr. Für die Inhalte und Richtigkeit der
        Informationen ist der jeweilige Informationsanbieter der verlinkten
        Webseite verantwortlich. Dazu zählen auch Links, die von
        wealthAPI-Mitgliedern veröffentlicht werden. Gleichwohl überprüfen wir
        alle Links, zu denen uns eine konkrete Rechtsverletzung zur Kenntnis
        gebracht wird. Wir haften aber frühestens ab dem Zeitpunkt der
        Kenntniserlangung und einer angemessenen Prüffrist.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        Urheberrecht
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Die auf dieser Webseite veröffentlichten Inhalte unterliegen dem
        deutschen Urheberrecht. Jede Art der Vervielfältigung, Bearbeitung,
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
        Urheberrechts bedarf der vorherigen schriftlichen Zustimmung von
        wealthAPI. Bitte wenden Sie sich für die Genehmigung an{" "}
        <Link
          href={`mailto:${t("aboutCompany.media.b2bEmail")}`}
          underline="hover"
        >
          {t("aboutCompany.media.b2bEmail")}
        </Link>
        .
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        Datenschutz
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Durch den Besuch unseres Internetauftritts können Informationen über den
        Zugriff (z.B. Datum, Uhrzeit, aufgerufene Seite) und personenbezogene
        Daten (z.B. IP-Adresse) gespeichert und verarbeitet werden. Für weitere
        Informationen zur Verarbeitung von Daten bei dem Besuch unserer Websites
        verweisen wir auf unsere{" "}
        <Link component={RouterLink} to="/privacy" underline="hover">
          Datenschutzerklärung
        </Link>
        . Zur Verarbeitung von Informationen und personenbezogenen Daten im
        Rahmen des Anmelde- und Registrierungsprozesses verweisen wir ebenfalls
        auf unsere{" "}
        <Link component={RouterLink} to="/privacy" underline="hover">
          Datenschutzerklärung
        </Link>
        ; daneben beachten Sie bitte auch unsere{" "}
        <Link component={RouterLink} to="/agb-tpp-web-form" underline="hover">
          Allgemeinen Geschäftsbedingungen
        </Link>
        .
      </TermsText>

      <p>-----</p>

      <TermsText variant="body1" color="textPrimary">
        Sollten die Inhalte oder die Aufmachung unserer Website gegen die Rechte
        Dritter oder gegen gesetzliche Bestimmungen verstoßen, dann schicken Sie
        uns bitte eine Nachricht über unser Kontaktformular ohne Kostennote. Wir
        garantieren Ihnen, dass wir zu Recht beanstandete Passagen umgehend
        entfernen werden, ohne dass von Ihrer Seite die Einschaltung eines
        Rechtsanwalts erforderlich ist. Sollten Sie trotz dieses Hinweises ohne
        vorherige Kontaktaufnahme eine Abmahnung eröffnen, werden wir diese
        vollumfänglich zurückweisen und gegebenenfalls Gegenklage wegen
        Verletzung vorgenannter Bestimmungen einreichen.
      </TermsText>
    </div>
  );
};
