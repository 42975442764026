import { InvokeCallback, Sender } from "xstate";
import { withToken } from "@api";
import { getByQuoteProviderAndTicker } from "@generated/apiv1/hooks";
import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const loadSymbol =
  (
    context: TransactionWizardContext,
    event: TransactionWizardEvent
  ): InvokeCallback<TransactionWizardEvent, TransactionWizardEvent> =>
  (callback) => {
    if (event.type !== "INIT_FROM_TICKER")
      throw new Error(`Invalid event type ${event.type}`);

    load(event.quoteProvider, event.tickerSymbol, callback);
  };

const load = async (
  quoteProvider: string,
  tickerSymbol: string,
  callback: Sender<TransactionWizardEvent>
) => {
  try {
    const symbol = await withToken(getByQuoteProviderAndTicker)({
      quoteProvider: quoteProvider as
        | "tte"
        | "ing"
        | "none"
        | "quandl"
        | "rentablo"
        | "crypto",
      id: tickerSymbol,
    });

    callback({
      type: "INIT_FROM_SYMBOL",
      symbol: symbol,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
