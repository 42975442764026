import { UseMutationOptions } from "react-query";
import { AccountsApiCreateAccountRequest, Account } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createAccount = async (
  token: string | undefined,
  params: AccountsApiCreateAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.createAccount(params);
  return data;
};

export const useCreateAccount = (
  options?: UseMutationOptions<
    Account,
    AxiosApiError,
    AccountsApiCreateAccountRequest
  >
) => {
  return useAuthMutation(createAccount, options, false);
};
