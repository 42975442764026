import { axiosInstanceV1 } from "@api/axiosInstance";
import { BankList, BankWrapper } from "../model";

export const getBankByIban = async (iban: string) => {
  return axiosInstanceV1.get<BankList>(`/banks`, {
    params: { search: iban?.replace(/\s/g, "") },
  });
};

export const getBankById = async (id: number) => {
  const response = await axiosInstanceV1.get<BankWrapper>(`/banks/${id}`);
  return response.data;
};

export const searchBank = async (search: string): Promise<BankList> => {
  const response = await axiosInstanceV1.get<BankList>(`/banks`, {
    params: { search },
  });

  return response.data;
};
