import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiGetBankConnectionViaTppWebFormRequest,
  BankConnection,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankConnectionViaTppWebForm = async (
  token: string | undefined,
  params: BankConnectionsApiGetBankConnectionViaTppWebFormRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.getBankConnectionViaTppWebForm(params);
  return data;
};

export const useGetBankConnectionViaTppWebForm = (
  params: BankConnectionsApiGetBankConnectionViaTppWebFormRequest,
  options?: UseQueryOptions<BankConnection, AxiosApiError, BankConnection>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_GET_BANK_CONNECTION_VIA_TPP_WEB_FORM, params],
    ({ token }) => getBankConnectionViaTppWebForm(token, params),
    options
  );
};
