import { UseQueryOptions } from "react-query";
import {
  BookingsApiPublicListAndSearchAllBookingsRequest,
  PageableBookingList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicListAndSearchAllBookings = async (
  token: string | undefined,
  params: BookingsApiPublicListAndSearchAllBookingsRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.publicListAndSearchAllBookings(params);
  return data;
};

export const usePublicListAndSearchAllBookings = (
  params: BookingsApiPublicListAndSearchAllBookingsRequest,
  options?: UseQueryOptions<
    PageableBookingList,
    AxiosApiError,
    PageableBookingList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BOOKINGS_PUBLIC_LIST_AND_SEARCH_ALL_BOOKINGS, params],
    ({ token }) => publicListAndSearchAllBookings(token, params),
    options
  );
};
