import { UseQueryOptions } from "react-query";
import { InvoiceList } from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getInvoices = async (token: string | undefined) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.getInvoices();
  return data;
};

export const useGetInvoices = (
  options?: UseQueryOptions<InvoiceList, AxiosApiError, InvoiceList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_GET_INVOICES],
    ({ token }) => getInvoices(token),
    options
  );
};
