import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Pie, PieChart, Cell, Tooltip, Sector } from "recharts";
import { Box, styled, Typography, useMediaQuery, Theme } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTranslation } from "react-i18next";
import { toCurrency } from "@helpers";
import { CashflowChartLegend } from "./CashflowChartLegend";
import { DonutChartTooltip } from "./DonutChartTooltip";
import { DonutChartData } from "./types";
import { useChartData } from "./useChartData";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    onHoverColor,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + 5 * cos;
  const sy = cy + 5 * sin;

  return (
    <g>
      <Sector
        cx={sx}
        cy={sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        strokeWidth={3}
        fill={onHoverColor}
        stroke="white"
        style={{ cursor: "pointer" }}
      />
      {/*this is to prevent twitching effect when moving slowly between sectors */}
      <Sector
        cx={sx}
        cy={sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        strokeWidth={6}
        fill="transparent"
        stroke="transparent"
        style={{ cursor: "pointer" }}
      />
    </g>
  );
};

export const DonutChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(10),

  [theme.breakpoints.down(950)]: {
    flexDirection: "column",
  },
}));

export type DonutChartProps = {
  data: DonutChartData[];
  displayLegend?: boolean;
  displayTotal?: boolean;
  onLegendClick?: (id: string | null) => void;
  onSectionClick?: (id: string | string[]) => void;
};

export const DonutChart = forwardRef(
  (
    {
      data,
      displayLegend = false,
      displayTotal = false,
      onLegendClick,
      onSectionClick,
    }: DonutChartProps,
    ref: ForwardedRef<{ resetActiveItem: () => void }>
  ) => {
    const { t } = useTranslation();
    const isLessSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down(750)
    );
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const onlyOneItem = data.length === 1;

    const { chartData, totalValue, totalAbsValue } = useChartData(data);

    const resetActiveItem = useCallback(() => {
      setActiveItem(null);
      onLegendClick?.(null);
    }, [onLegendClick]);

    useImperativeHandle(
      ref,
      () => ({
        resetActiveItem,
      }),
      [resetActiveItem]
    );

    const handleSectionClick = (id: string) => {
      if (onSectionClick) {
        const clickedItem = chartData.find((item) => item.id === id);
        if (!clickedItem) return;

        onSectionClick(
          clickedItem?.id === "rest" && clickedItem.children
            ? clickedItem.children.map((child) => child.id)
            : clickedItem.id
        );
        handleLegendClick(id, clickedItem?.id !== "rest");
      } else {
        handleLegendClick(id);
      }
    };

    const handleLegendClick = (
      id: string,
      shouldTriggerOnLegendClick: boolean = true
    ) => {
      if (!displayLegend) return;
      if (activeItem === id) {
        setActiveItem(null);
        onLegendClick?.(null);
      } else {
        setActiveItem(id);
        if (shouldTriggerOnLegendClick) onLegendClick?.(id);
      }
    };

    const activeItemIndex = chartData.findIndex(
      (item) =>
        item.id === activeItem ||
        item.children?.some((child) => child.id === activeItem)
    );

    return (
      <DonutChartContainer>
        <CashflowChartLegend
          data={data}
          displayLegend={displayLegend}
          onLegendClick={handleLegendClick}
          activeLegend={activeItem}
        />
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          gap={isLessSm ? 3 : 6}
          height={displayTotal ? 430 : 360}
          sx={{ position: "relative" }}
        >
          {displayTotal && (
            <Box
              textAlign="center"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography fontSize="26px" lineHeight="34px" fontWeight={400}>
                {toCurrency(
                  chartData[0]?.id === "noData" ? 0 : totalValue,
                  "EUR"
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("domains.donutChart.total")}
              </Typography>
            </Box>
          )}
          <Box height="100%" width="100%">
            <AutoSizer>
              {({ width, height }: { width: number; height: number }) => {
                const lowestNumber = width < height ? width : height;

                return (
                  <PieChart
                    width={width}
                    height={height}
                    margin={{ top: 10, bottom: 10 }}
                  >
                    <Pie
                      activeIndex={onlyOneItem ? undefined : activeItemIndex}
                      activeShape={renderActiveShape}
                      data={chartData}
                      innerRadius={lowestNumber / 4}
                      outerRadius={lowestNumber / 2 - 5}
                      dataKey="value"
                      labelLine={false}
                      isAnimationActive={false}
                      onClick={(props) => handleSectionClick(props.id)}
                      startAngle={90}
                      endAngle={-270}
                      minAngle={3}
                      onMouseOver={(props) => {
                        setHoveredItem(props.id);
                      }}
                      onMouseLeave={() => {
                        setHoveredItem(null);
                      }}
                    >
                      {chartData.map((item) => (
                        <Cell
                          key={item.id}
                          fill={
                            item.id === hoveredItem
                              ? item?.onHoverColor
                              : item.color
                          }
                          strokeWidth={3}
                          style={{
                            cursor: displayLegend ? "pointer" : "default",
                          }}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      active={true}
                      content={<DonutChartTooltip totalValue={totalAbsValue} />}
                    />
                  </PieChart>
                );
              }}
            </AutoSizer>
          </Box>
        </Box>
      </DonutChartContainer>
    );
  }
);
