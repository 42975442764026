import { UseQueryOptions } from "react-query";
import { getWatchlistEntries } from "@api/v1/methods";
import {
  ListAndSearchAllWatchlistEntries,
  WatchlistEntryList,
} from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_WATCHLISTS_ENTRIES } from "../../cacheKeys";

export const useGetWatchlistEntries = (
  params: ListAndSearchAllWatchlistEntries,
  options?: UseQueryOptions<
    WatchlistEntryList,
    AxiosApiError,
    WatchlistEntryList
  >
) =>
  useAuthQuery(
    [GET_WATCHLISTS_ENTRIES, params],
    ({ token }) => getWatchlistEntries(token, params),
    options
  );
