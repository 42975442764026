import React from "react";
import styled from "@emotion/styled";
import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import { Card as CardOrig } from "../../Card";

const Card = styled(CardOrig, {
  shouldForwardProp: (prop) => prop !== "hideShadow",
})<{ hideShadow?: boolean }>`
  display: grid;
  grid-template-columns: 48px auto 32px;
  column-gap: 30px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.subtle};
  border: none !important;
  & * {
    line-height: 1.3;
  }
  box-shadow: ${({ hideShadow, theme }) =>
    hideShadow ? "none" : theme.shadows[1]};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 24px auto 32px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  transform: none !important;
`;

const SecondColumn = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

const ThirdColumn = styled.div`
  margin-top: 7px;
`;

export const AccountRowLoading = ({ hideShadow }: { hideShadow?: boolean }) => {
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Card size="row" hideShadow={hideShadow}>
      <StyledSkeleton width={isLessSm ? 24 : 48} height={isLessSm ? 24 : 48} />
      <SecondColumn>
        <StyledSkeleton width={200} height={8} />
        <StyledSkeleton width={124} height={8} />
      </SecondColumn>
      <ThirdColumn>
        <StyledSkeleton width={32} height={8} />
      </ThirdColumn>
    </Card>
  );
};
