import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Account } from "@api";
import { useGetDepotSynchronizationLog } from "@api/v1/hooks/useDepotSynchronizationLog";
import { LoadingProgressBar } from "@components";
import { goToDepot } from "@helpers/mainAppUrls";
import { useGetAccount } from "@api/v1/hooks/useAccounts";
import { ImportSuccess } from "./components/ImportSuccess";

export const ImportSuccessWithRedirectPage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();
  const [account, setAccount] = useState<Account>();

  const accountIdNumber = accountId ? parseInt(accountId) : undefined;
  const { isLoading: isLoadingAccount } = useGetAccount(accountIdNumber, {
    onSuccess: (account) => {
      setAccount(account);
    },
  });
  const { depotSynchronizationLogId } =
    useParams<"depotSynchronizationLogId">();

  const { data: depotSynchronizationLog, isLoading } =
    useGetDepotSynchronizationLog(Number.parseInt(depotSynchronizationLogId!));

  if (isLoading || isLoadingAccount || !depotSynchronizationLog) {
    return <LoadingProgressBar />;
  }

  return (
    <ImportSuccess
      depotSynchronizationLog={depotSynchronizationLog}
      account={account}
      onGoToDepot={() => {
        accountId
          ? goToDepot(depotSynchronizationLog.accountId)
          : navigate("/investments");
      }}
    />
  );
};
