import React from "react";
import {
  XAxis as RXAxis,
  YAxis as RYAxis,
  CartesianGrid as RCartesianGrid,
} from "recharts";
import { Theme } from "@emotion/react";
import { toCustomShort } from "@helpers";
import { XAxisTick } from "./XAxisTick";

interface Props {
  xDataKey: string;
  theme: Theme;
  tickInterval: number;
  tickFormat?: string;
  hideYAxis?: boolean;
  valueTickFormatter?: (value: any, index?: number) => string;
}

export const GridAndAxis = ({
  xDataKey,
  theme,
  tickInterval,
  tickFormat,
  valueTickFormatter = (value: number) => toCustomShort(value),
  hideYAxis = false,
}: Props) => [
  <RCartesianGrid
    key="grid"
    vertical={false}
    stroke={theme.palette.border.primary}
    strokeDasharray="2 4"
    strokeOpacity={1}
  />,
  <RXAxis
    type="category"
    key="x-axis"
    dataKey={xDataKey}
    tick={(props) => <XAxisTick {...props} dateFormat={tickFormat} />}
    interval={tickInterval}
    stroke={theme.palette.border.primary}
    tickSize={10}
    dy={10}
  />,
  <RYAxis
    yAxisId="1"
    key="y-axis"
    width={90}
    axisLine={false}
    tickLine={false}
    tickSize={37}
    stroke={theme.palette.grey[500]}
    tickFormatter={valueTickFormatter}
    domain={["dataMin", "auto"]}
    type="number"
    hide={hideYAxis}
  />,
];
