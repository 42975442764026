import { UseQueryOptions } from "react-query";
import {
  BanksApiInterfaceSelectionOptionsRequest,
  InterfaceSelectionOptionList,
} from "@generated/apiv1";

import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const interfaceSelectionOptions = async (
  token: string | undefined,
  params?: BanksApiInterfaceSelectionOptionsRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.interfaceSelectionOptions(params);
  return data;
};

export const useInterfaceSelectionOptions = (
  params?: BanksApiInterfaceSelectionOptionsRequest,
  options?: UseQueryOptions<
    InterfaceSelectionOptionList,
    AxiosApiError,
    InterfaceSelectionOptionList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKS_INTERFACE_SELECTION_OPTIONS, params],
    ({ token }) => interfaceSelectionOptions(token, params),
    options
  );
};
