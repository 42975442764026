import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { Box, Button, styled, Typography } from "@mui/material";
import { ReactComponent as RightChevron } from "@icons/myicons/chevron_right.svg";
import { settingIcons } from "../constants";

const MobileContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 31px;
`;

const MobileButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(4)};
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-radius: 12px;
`;

export const SettingsMobileNavigation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <MobileContainer>
      <MobileButton
        variant="text"
        color="primary"
        onClick={() => navigate("/settings/profile")}
      >
        <Box display="flex" gap={theme.spacing(3)}>
          {settingIcons.profile}
          <Typography variant="body1" color={theme.palette.text.primary}>
            {t("settingsPage.tabs.profile")}
          </Typography>
        </Box>
        <RightChevron color={theme.palette.icon.primary} />
      </MobileButton>
      <MobileButton
        variant="text"
        color="primary"
        onClick={() => navigate("/settings/subscription")}
      >
        <Box display="flex" gap={theme.spacing(3)}>
          {settingIcons.subscription}
          <Typography variant="body1" color={theme.palette.text.primary}>
            {t("settingsPage.tabs.subscription")}
          </Typography>
        </Box>
        <RightChevron color={theme.palette.text.primary} />
      </MobileButton>
      <MobileButton
        variant="text"
        color="primary"
        onClick={() => navigate("/settings/security")}
      >
        <Box display="flex" gap={theme.spacing(3)}>
          {settingIcons.security}
          <Typography variant="body1" color={theme.palette.text.primary}>
            {t("settingsPage.tabs.security")}
          </Typography>
        </Box>
        <RightChevron color={theme.palette.text.primary} />
      </MobileButton>
      <MobileButton
        variant="text"
        color="primary"
        onClick={() => navigate("/settings/connections")}
      >
        <Box display="flex" gap={theme.spacing(3)}>
          {settingIcons.connections}
          <Typography variant="body1" color={theme.palette.text.primary}>
            {t("settingsPage.tabs.connections")}
          </Typography>
        </Box>
        <RightChevron color={theme.palette.text.primary} />
      </MobileButton>
    </MobileContainer>
  );
};
