import React from "react";
import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { Card as CardOrg } from "@components";

const Card = styled(CardOrg)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-left: 24px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.subtle};
  box-shadow: none;
  height: 125px;
  border: none;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 18px;
`;

const Circle = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  background-color: ${({ theme }) => theme.palette.background.subtle};
`;

const CircleContainer = styled.div`
  position: relative;
  width: 58px;
  height: 58px;
`;

export const AssetTypeCardLoading = () => {
  return (
    <Card size="micro">
      <CircleContainer>
        <Skeleton width={58} height={58} variant="circular" />
        <Circle />
      </CircleContainer>
      <Rows>
        <Skeleton width={48} height={8} />
        <Skeleton width={96} height={8} />
        <Skeleton width={48} height={8} />
      </Rows>
    </Card>
  );
};
