import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { useLogout } from "@hooks";
import { ReactComponent as EmailSentIcon } from "@icons/myicons/email-sent.svg";
import { useInitiatePasswordReset } from "@generated/user/hooks";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  title: "settingsPage.profile.modal.password.title",
  subtitle: "settingsPage.profile.modal.password.subtitle",
  confirmButton: "settingsPage.profile.modal.password.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
}

export const SettingsInitChangePasswordModal = ({
  isOpen,
  onClose,
  userEmail,
}: Props) => {
  const { t } = useTranslation();
  const { logout } = useLogout();
  const {
    mutate: initPasswordReset,
    isLoading,
    isSuccess,
    isError,
  } = useInitiatePasswordReset();

  const handleConfirm = () => {
    initPasswordReset({
      email: userEmail,
    });
  };

  const handleClose = () => {
    onClose();
    if (isSuccess) {
      logout();
    }
  };

  return (
    <SettingsModalWrapper
      {...modalData}
      successTitle={t("settingsPage.profile.modal.password.sentTitle")}
      successSubtitle={t("settingsPage.profile.modal.password.sentSubtitle")}
      successButton={t("settingsPage.profile.modal.password.sentButton")}
      successIcon={<EmailSentIcon />}
      showSuccess={isSuccess}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isConfirmBtnDisabled={isLoading}
      isConfirmButtonLoading={isLoading}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
          marginBottom={0}
        />
      )}
    </SettingsModalWrapper>
  );
};
