import { parseJSON, isEqual } from "date-fns";
import i18n from "i18next";
import { SecurityChartData } from "@components/Chart/SecuritiesChart/types";
import { ComparisonValue } from "@features/dashboard/types";
import { SecuritiesChartSelectedType } from "@features/investments/components/ChartToolbar";
import { getPercent, getInterval } from "@helpers";
import {
  DateValuePair,
  PortfolioPerformanceResult,
  TwrorAndCashFlowResult,
} from "@generated/apiv1";
import { useHistoricQuotes } from "@generated/apiv1/hooks";
import { usePerformanceByAssetClass } from "./usePerformanceByAssetClass";

interface CharDataConverterParams<T> {
  securityName: string;
  data?: T;
  emptyData?: TwrorAndCashFlowResult[];
}

type CharDataConverter<T extends Array<any>> = (
  params: CharDataConverterParams<T>
) => SecurityChartData;

const convertToChartData: CharDataConverter<TwrorAndCashFlowResult[]> = ({
  data,
  securityName,
}) => {
  if (!data) return { name: securityName, securityName, data: [] };
  const name =
    i18n.t(`investments.securitiesChart.${securityName}`) || securityName;
  return {
    name,
    securityName,
    data: data.map(({ date, currentMarketValue }) => [
      parseJSON(date!).getTime(),
      Math.round((currentMarketValue ?? 0) * 100) / 100,
    ]),
  };
};

const convertToPercentChartData: CharDataConverter<
  TwrorAndCashFlowResult[]
> = ({ data, securityName }) => {
  if (!data) return { name: securityName, securityName, data: [] };
  const name =
    i18n.t(`investments.securitiesChart.${securityName}`) || securityName;
  return {
    name,
    securityName,
    data: data.map(({ date, twror }) => [
      parseJSON(date!).getTime(),
      getPercent(twror ?? 0),
    ]),
  };
};

const hasData = (data?: PortfolioPerformanceResult) => {
  if (!data) return false;
  return data.twrorResults.some(
    (twrorResult) =>
      twrorResult.currentMarketValue !== 0 || twrorResult.twror !== 1
  );
};

const convertFromHistoricQuotes = ({
  data,
  securityName,
  comparisonData,
  emptyData = [],
}: {
  data: TwrorAndCashFlowResult[];
  comparisonData?: DateValuePair[];
  emptyData?: TwrorAndCashFlowResult[];
  securityName: string;
}): SecurityChartData => {
  if (!data)
    return {
      name: securityName,
      securityName,
      data: [],
    };

  const newComparisonData = data.map((item) => {
    const foundComparisonItem = comparisonData?.find((dataItem) =>
      isEqual(new Date(dataItem.date!), new Date(item.date!))
    );

    return {
      date: item.date,
      value: item.twror,
      comparedValue: foundComparisonItem?.value ?? 0,
    };
  });

  let reference = newComparisonData[0];
  let comparedValue = 1;

  const formattedComparisonData: [number, number][] = newComparisonData.map(
    (current) => {
      if (current.comparedValue && reference.comparedValue) {
        comparedValue *= current.comparedValue / reference.comparedValue;
      }

      reference = current;

      const secondaryPercents = getPercent(comparedValue);

      return [new Date(current.date!).getTime(), secondaryPercents];
    }
  );

  return {
    name: securityName,
    securityName,
    data: formattedComparisonData,
  };
};

export interface InvestmentsChartDataStatistic {
  portfolioValue: number;
  performance: number;
  changeToday: number;
  investedCapital: number;
  changeTodayAbs: number;
  valuationGain: number;
  valuationGainPercent: number;
  irr: number;
}

interface UseInvestmentsChartDataOutput {
  data: SecurityChartData[];
  comparedTickerData?: SecurityChartData;
  typesWithData: SecuritiesChartSelectedType[];
  statistic: InvestmentsChartDataStatistic;
  performanceByAssetClass: ReturnType<typeof usePerformanceByAssetClass>;
  isIdle: boolean;
  isLoading: boolean;
}

export const useInvestmentsChartData = (
  accountIds: number[],
  startDate: Date,
  endDate: Date,
  chartType: "performance" | "valuation" = "valuation",
  comparedTicker?: ComparisonValue
): UseInvestmentsChartDataOutput => {
  const isValuationChart = chartType === "valuation";
  const interval = getInterval(startDate, endDate);

  const query = usePerformanceByAssetClass({
    accountIds,
    startDate,
    endDate,
    interval,
  });
  const converterCallback = isValuationChart
    ? convertToChartData
    : convertToPercentChartData;

  const {
    data: comparisonHistoryQuotes,
    isIdle: historicQuotesIdle,
    isLoading: historicQuotesLoading,
  } = useHistoricQuotes(
    {
      // @ts-ignore
      tickerSymbol: comparedTicker?.value,
      interval,
      from: startDate?.toISOString(),
      to: endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      enabled: Boolean(comparedTicker?.value),
      keepPreviousData: true,
    }
  );

  const totalPerformance = converterCallback({
    securityName: "all",
    data: query.all?.twrorResults,
  });

  const data = [totalPerformance];

  const typesWithData: SecuritiesChartSelectedType[] = [];

  if (hasData(query.shares)) {
    typesWithData.push("shares");
    const allHoldings = converterCallback({
      securityName: "shares",
      data: query.shares?.twrorResults,
    });
    data.push(allHoldings);
  }
  if (hasData(query.funds)) {
    typesWithData.push("funds");
    const fonds = converterCallback({
      securityName: "funds",
      data: query.funds?.twrorResults,
    });
    data.push(fonds);
  }
  if (hasData(query.etfs)) {
    typesWithData.push("etfs");
    const etf = converterCallback({
      securityName: "etfs",
      data: query.etfs?.twrorResults,
    });
    data.push(etf);
  }
  if (hasData(query.bonds)) {
    typesWithData.push("bonds");
    const bonds = converterCallback({
      securityName: "bonds",
      data: query.bonds?.twrorResults,
    });
    data.push(bonds);
  }
  if (hasData(query.currency)) {
    typesWithData.push("currency");
    const derivative = converterCallback({
      securityName: "currency",
      data: query.currency?.twrorResults,
    });
    data.push(derivative);
  }
  if (hasData(query.materialAssets)) {
    typesWithData.push("materialAssets");
    const derivative = converterCallback({
      securityName: "materialAssets",
      data: query.materialAssets?.twrorResults,
    });
    data.push(derivative);
  }
  if (hasData(query.metals)) {
    typesWithData.push("metals");
    const derivative = converterCallback({
      securityName: "metals",
      data: query.metals?.twrorResults,
    });
    data.push(derivative);
  }

  const statistics = query.all;
  const twrorResult =
    statistics?.twrorResults[statistics.twrorResults.length - 1];

  const portfolioValue = twrorResult?.currentMarketValue ?? 0;
  const performance = (twrorResult?.twror ?? 1) - 1;
  const changeToday = statistics?.changeLast?.performance ?? 0;
  const investedCapital = twrorResult?.purchaseValue ?? 0;
  const changeTodayAbs = statistics?.changeLast?.changeAbsCashFlowAdjusted ?? 0;
  const valuationGain = twrorResult?.valuationGainAbs ?? 0;
  const valuationGainPercent = twrorResult?.valuationGainInPercent ?? 0;
  const irr = statistics?.irrTotal?.irr ?? 0;

  let comparedTickerData: SecurityChartData | undefined;

  if (comparedTicker) {
    comparedTickerData = convertFromHistoricQuotes({
      data: query.all?.twrorResults,
      securityName: comparedTicker?.label,
      comparisonData:
        comparisonHistoryQuotes?.historicQuotes?.[comparedTicker?.value] || [],
      emptyData: query.all?.twrorResults,
    });
  }

  return {
    data,
    comparedTickerData,
    typesWithData,
    statistic: {
      portfolioValue,
      performance,
      changeToday,
      investedCapital,
      changeTodayAbs,
      valuationGain,
      valuationGainPercent,
      irr,
    },
    performanceByAssetClass: query,
    isIdle: query.isIdle && historicQuotesIdle,
    isLoading: !query.isFetched || query.isLoading || historicQuotesLoading,
  };
};
