import React from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { SnackbarProvider } from "@providers/SnackbarProvider";
import { AppLayout } from "@components";
import { SharedPortfolioProvider } from "@providers/SharedPortfolioProvider";
import { ScrollToTop } from "@components/ScrollToTop";
import { AppLayoutProvider } from "@providers/AppLayoutProvider";
import { DashboardPage } from "@features/dashboard/DashboardPage";
import { InvestmentsPage } from "@features/investments/InvestmentsPage";
import { InvestmentEditByParamPage } from "@features/investments/investment/edit/InvestmentEditByParamPage";
import { InvestmentByParamPage } from "@features/investments/investment/InvestmentByParamPage";
import { FileDropHandlerWithRoutingPage } from "@features/fileDrop/FileDropHandlerWithRoutingPage";
import { ImportSuccessWithRedirectPage } from "@features/fileDrop/ImportSuccessWithRedirectPage";
import { CreateBatchDividendWizardWithRouting } from "@features/dividendWizard/CreateBatchDividendWizardWithRouting";
import { CreateDividendWizardWithRouting } from "@features/dividendWizard/CreateDividendWizardWithRouting";
import { EditDividendWizardWithRouting } from "@features/dividendWizard/EditDividendWizardWithRouting";
import { NewTransactionWizardWithRouting } from "@features/transactionWizard/NewTransactionWizardWithRouting";
import { ManualTransactionWizardWithRouting } from "@features/transactionWizard/ManualTransactionWizardWithRouting";
import { DepotsAccountsPage } from "@features/depotsAccounts/DepotsAccountsPage";
import { DepotEditPage } from "@features/depot/edit/DepotEditPage";
import { DepotPage } from "@features/depot/DepotPage";
import { AccountAddManuallyPage } from "@features/accounts/add-manually/AccountAddManuallyPage";
import { AccountAddChoicePage } from "@features/accounts/add/AccountAddChoicePage";
import { AccountEditPage } from "@features/accounts/edit/AccountEditPage";
import { AddManuallyPage } from "@features/accounts/transactions/AddManuallyPage";
import { SingleAccountByParamPage } from "@features/accounts/SingleAccountByParamPage";
import { WatchlistsPage } from "@features/watchlists/WatchlistsPage";
import { SecuritiesSinglePage } from "@features/securities/SecuritiesSinglePage";
import { CashflowAnalyzerPage } from "@features/cashflowAnalyzer/CashflowAnalyzerPage";
import { AnalysisPage } from "@features/analysis/AnalysisPage";
import { SecuritiesEditPage } from "@features/securities/edit/SecuritiesEditPage";
import { SingleSecurityPage } from "@features/securities/security/SingleSecurityPage";
import { FeedbackPage } from "@features/feedback/FeedbackPage";
import { AddPage } from "@features/securities/add/AddPage";
import { BankSelectWithRedirectPage } from "@features/bankSelect/BankSelectWithRedirectPage";
import { LegalNoteWithRedirectPage } from "@features/bankSelect/LegalNoteWithRedirectPage";
import { TppWebFormRedirectPage } from "@features/bankSelect/TppWebFormRedirectPage";
import {
  SynchronizationLogForBatchId,
  SynchronizationLogListWithRouting,
} from "@components/SynchronizationLog";
import { AssignContraAccountWithRedirectPage } from "@features/depot/assignContraAccount/AssignContraAccountWithRedirectPage";
import { SharedAppSidebar } from "@components/common/layouts/AppSidebar/SharedAppSidebar";
import { DepotTransferPage } from "@features/depot/depotTransfer/DepotTransferPage";
import { LogoutWithRedirect } from "@features/login/logout/LogoutWithRedirect";
import { CsvUploadPage } from "@features/csvDrop/CsvUploadPage";
import { isProduction } from "@constants";
import {
  AccountsProvider,
  DASHBOARD_PAGE_PROPS,
  INVESTMENTS_PAGE_PROPS,
} from "@providers/AccountsProvider";
import { SecurityImagesProvider } from "@providers/SecurityImageProvider";
import { JumpToSecurityPage } from "@features/securities/JumpToSecurityPage";
import { DividendsPage } from "@features/dividendPlanner/DividendsPage";
import {
  SettingsPage,
  SettingsPageWithTab,
} from "@features/settingsPage/SettingsPage";
import { ResetPasswordPage } from "@features/settingsPage/ResetPasswordPage/ResetPasswordPage";
import { TempLogin } from "@features/settingsPage/TempLogin/TempLogin";
import { SubcategoriesProvider } from "@providers/SubcategoriesProvider";
import { OnlyAuthenticatedRoute } from "./routes";

export const AppRouter = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <SnackbarProvider>
      <SecurityImagesProvider>
        <SharedPortfolioProvider>
          <SubcategoriesProvider>
            <Routes>
              <Route path="/logout" element={<LogoutWithRedirect />} />
              {!isProduction && <Route path="/login" element={<TempLogin />} />}
              <Route path="/reset-password" element={<ResetPasswordPage />} />

              {/* Shared Portfolio */}
              <Route path="/portfolios">
                <Route
                  path=":sharedPortfolioId"
                  element={
                    <AppLayoutProvider>
                      <AppLayout sidebar={SharedAppSidebar} />
                    </AppLayoutProvider>
                  }
                >
                  <Route
                    path=""
                    element={
                      <AccountsProvider {...DASHBOARD_PAGE_PROPS}>
                        <DashboardPage />
                      </AccountsProvider>
                    }
                  />
                  <Route
                    path="investments"
                    element={
                      <AccountsProvider {...INVESTMENTS_PAGE_PROPS}>
                        <InvestmentsPage />
                      </AccountsProvider>
                    }
                  />
                  <Route path="classifications" element={<AnalysisPage />} />
                  <Route path="dividend-planner" element={<DividendsPage />} />
                </Route>
              </Route>

              <Route path="" element={<OnlyAuthenticatedRoute />}>
                <Route
                  path="/depots/:fromAccountId/transfer"
                  element={<DepotTransferPage />}
                />

                <Route
                  path="/synchronization-logs/:accountId"
                  element={<SynchronizationLogListWithRouting />}
                />

                <Route
                  path="/synchronization-logs/:batchId"
                  element={<SynchronizationLogForBatchId />}
                />
                <Route
                  path="/depots/:id/assign-contra-account"
                  element={<AssignContraAccountWithRedirectPage />}
                />
              </Route>

              <Route
                path="/import-bank-connection"
                element={<OnlyAuthenticatedRoute />}
              >
                <Route path="redirect" element={<TppWebFormRedirectPage />} />
                <Route
                  path="select-bank"
                  element={
                    <AppLayoutProvider>
                      <AppLayout>
                        <BankSelectWithRedirectPage />
                      </AppLayout>
                    </AppLayoutProvider>
                  }
                />
                <Route
                  path=":bankId/legal-note"
                  element={<LegalNoteWithRedirectPage />}
                />
              </Route>

              <Route
                path="/"
                element={
                  <OnlyAuthenticatedRoute>
                    <AppLayoutProvider>
                      <AppLayout />
                    </AppLayoutProvider>
                  </OnlyAuthenticatedRoute>
                }
              >
                <Route
                  index
                  element={
                    <AccountsProvider {...DASHBOARD_PAGE_PROPS}>
                      <DashboardPage />
                    </AccountsProvider>
                  }
                />
                <Route
                  path="/jumpToSecurity"
                  element={<JumpToSecurityPage />}
                />
                <Route path="/feedback" element={<FeedbackPage />} />

                <Route
                  path="/depots-accounts"
                  element={<DepotsAccountsPage />}
                />
                <Route path="/settings">
                  <Route path="" element={<SettingsPage />} />
                  <Route path=":tab" element={<SettingsPageWithTab />} />
                </Route>
                <Route
                  path="/settings/security/create"
                  element={<SettingsPage defaultTab="create" />}
                />

                {/* Depots */}
                <Route path="/depots">
                  <Route
                    path="add-manually"
                    element={<AccountAddManuallyPage />}
                  />
                  <Route path=":depotId/edit" element={<DepotEditPage />} />
                  <Route path=":depotId" element={<DepotPage />} />
                  <Route
                    path=":depotId/investments/:investmentId"
                    element={<InvestmentByParamPage />}
                  />
                  <Route path="add" element={<AccountAddChoicePage />} />
                  <Route path=":depotId/csv-drop" element={<CsvUploadPage />} />
                  <Route
                    path=":accountId/file-drop"
                    element={<FileDropHandlerWithRoutingPage />}
                  />
                  <Route
                    path=":accountId/file-drop-success/:depotSynchronizationLogId"
                    element={<ImportSuccessWithRedirectPage />}
                  />
                </Route>

                {/* Accounts */}
                <Route path="/accounts">
                  <Route
                    path="add-manually"
                    element={<AccountAddManuallyPage />}
                  />

                  <Route path="add" element={<AccountAddChoicePage />} />
                  <Route
                    path=":accountId/transactions/add-manually"
                    element={<AddManuallyPage />}
                  />
                  <Route path=":accountId/edit" element={<AccountEditPage />} />
                  <Route
                    path=":accountId"
                    element={<SingleAccountByParamPage />}
                  />
                </Route>

                {/* Investments*/}
                <Route path="/investments">
                  <Route
                    path=""
                    element={
                      <AccountsProvider {...INVESTMENTS_PAGE_PROPS}>
                        <InvestmentsPage />
                      </AccountsProvider>
                    }
                  />

                  <Route
                    path=":investmentId/edit"
                    element={<InvestmentEditByParamPage />}
                  />
                  <Route
                    path=":investmentId"
                    element={<InvestmentByParamPage />}
                  />
                  <Route path="csv-drop" element={<CsvUploadPage />} />
                  <Route
                    path="file-drop"
                    element={<FileDropHandlerWithRoutingPage />}
                  />
                  <Route
                    path="file-drop-success/:depotSynchronizationLogId"
                    element={<ImportSuccessWithRedirectPage />}
                  />
                  <Route
                    path=":investmentId/dividend-wizard/create/batch"
                    element={<CreateBatchDividendWizardWithRouting />}
                  />

                  <Route
                    path=":investmentId/dividend-wizard/create"
                    element={<CreateDividendWizardWithRouting />}
                  />

                  <Route
                    path=":investmentId/dividend-wizard/edit/:bookingId"
                    element={<EditDividendWizardWithRouting />}
                  />
                </Route>

                {/* Wathclists */}
                <Route path="/watchlists">
                  <Route path="" element={<WatchlistsPage />}>
                    <Route path=":watchlistId" element={<WatchlistsPage />} />
                  </Route>

                  <Route
                    path=":watchlistId/securities/add"
                    element={<AddPage />}
                  />

                  <Route
                    path=":watchlistId/securities/:securityId/edit"
                    element={<SecuritiesEditPage />}
                  />

                  <Route
                    path=":watchlistId/securities/:securityId"
                    element={<SecuritiesSinglePage />}
                  />
                </Route>

                <Route path="classifications" element={<AnalysisPage />} />

                <Route
                  path="/cashflow-analyzer"
                  element={<CashflowAnalyzerPage />}
                />

                <Route path="/dividend-planner" element={<DividendsPage />} />

                <Route path="/securities" element={<SingleSecurityPage />} />

                <Route
                  path="/new-transaction-wizard"
                  element={<NewTransactionWizardWithRouting />}
                />

                <Route
                  path="/manual-transaction-wizard"
                  element={<ManualTransactionWizardWithRouting />}
                />
              </Route>
              <Route
                path="*"
                element={
                  <OnlyAuthenticatedRoute>
                    <Navigate to="" />
                  </OnlyAuthenticatedRoute>
                }
              />
            </Routes>
          </SubcategoriesProvider>
        </SharedPortfolioProvider>
      </SecurityImagesProvider>
    </SnackbarProvider>

    <ScrollToTop />
  </BrowserRouter>
);
