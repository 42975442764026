import { UseMutationOptions } from "react-query";
import { UsersApiChangeDisplayNameRequest, User } from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const changeDisplayName = async (
  token: string | undefined,
  params: UsersApiChangeDisplayNameRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.changeDisplayName(params);
  return data;
};

export const useChangeDisplayName = (
  options?: UseMutationOptions<
    User,
    AxiosApiError,
    UsersApiChangeDisplayNameRequest
  >
) => {
  return useAuthMutation(changeDisplayName, options, true);
};
