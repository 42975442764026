import { withToken } from "@api/auth/withToken";
import { CurrencyList, CurrencyTypeEnum } from "@api/v1/model";
import { axiosInstanceV1 } from "@api/axiosInstance";
export interface CurrencyParams {
  type?: CurrencyTypeEnum;
}

export const getCurrencies = async (
  token: string | undefined,
  params?: CurrencyParams
) => {
  const { data } = await axiosInstanceV1.get<CurrencyList>(`/currencies`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getCurrenciesWithToken = withToken(getCurrencies);
