import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactCountryFlag from "react-country-flag";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { Region } from "@api";
import { useRegions } from "@api/v1/hooks/useRegions";

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

interface Props extends Omit<BoxProps, "onChange"> {
  defaultRegion?: Region;
  className?: string;
  onChange?: (region: Region | null) => void;
  isError?: boolean;
  errorText?: string;
  disabled?: boolean;
  variant?: "outlined" | "filled" | "standard";
  label?: string;
  testId?: string;
  noLabel?: boolean;
}

export const RegionSelector = ({
  className,
  onChange,
  defaultRegion,
  label,
  isError = false,
  errorText,
  disabled = false,
  testId = "region-selector-input",
  noLabel = false,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, isLoading } = useRegions();
  const [region, setRegion] = useState(defaultRegion ?? null);

  const handleChange = (value: Region | null) => {
    let region = value;
    if (value === null) {
      region =
        data?.regions?.find((region) => region.germanName === "Global") || null;
    }
    onChange && onChange(region);
    setRegion(region);
  };

  const regions =
    data?.regions?.filter(
      (region) =>
        region.regionType === "country" || region.germanName === "Global"
    ) || [];

  return (
    <Box {...props}>
      {noLabel ? null : (
        <Typography
          component="label"
          variant="body2"
          sx={{ display: "inline-block", marginBottom: theme.spacing(1) }}
        >
          {label || t("regionSelector.label")}
        </Typography>
      )}
      <Autocomplete
        disabled={disabled}
        defaultValue={defaultRegion || null}
        className={className}
        value={region}
        disablePortal
        onChange={(e, value) => handleChange(value)}
        loading={isLoading}
        options={regions}
        blurOnSelect
        getOptionLabel={(option) => option.germanName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={isError}
            helperText={errorText}
            placeholder={label}
            inputProps={{
              ...params.inputProps,
              "data-testid": testId,
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option?.germanName || "", inputValue, {
            insideWords: true,
          });
          const parts = parse(option?.germanName || "", matches);

          return (
            <li {...props}>
              <OptionContainer data-testid="region-selector-option">
                {option.twoLetterCode && (
                  <ReactCountryFlag
                    countryCode={option.twoLetterCode}
                    svg
                    title={option.twoLetterCode}
                  />
                )}
                <Typography variant="body2">
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: 500,
                        color: part.highlight
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </OptionContainer>
            </li>
          );
        }}
      />
    </Box>
  );
};
