import React, { MouseEvent, useState } from "react";
import {
  Box,
  BoxProps,
  Breakpoint,
  Button,
  Link,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const SHOWED_TEXT_LENGTH = 150;

const BannerContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "breakpoint" && prop !== "alignBannerItems",
})<{
  type: string;
  breakpoint: number | Breakpoint;
  alignBannerItems: string;
}>(({ theme, type, breakpoint, alignBannerItems }) => ({
  display: "flex",
  alignItems: alignBannerItems,
  padding: theme.spacing(3, 5),
  backgroundColor: getColor(type, theme),
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  border:
    type === "white" ? `1px solid ${getBorderColor(type, theme)}` : "none",

  [theme.breakpoints.down(breakpoint)]: {
    alignItems: "flex-start",
  },
}));

const ButtonsContainer = styled(Box)<{ shouldBreak?: boolean }>(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    gap: theme.spacing(4),
  })
);

const InnerBannerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "breakpoint",
})<{ breakpoint: number | Breakpoint }>(({ theme, breakpoint }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.down(breakpoint)]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const getColor = (type: string, theme: Theme) => {
  switch (type) {
    case "grey-info":
      return theme.palette.background.subtle;
    case "warn":
      return theme.palette.background.warning;
    case "white":
      return theme.palette.background.default;
    case "error":
      return theme.palette.background.error;
    case "success":
      return theme.palette.background.success;
    default:
      return theme.palette.background.info;
  }
};

const getBorderColor = (type: string, theme: Theme) => {
  switch (type) {
    case "grey-info":
      return theme.palette.border.secondary;
    case "warn":
      return theme.palette.warning.light;
    case "white":
      return theme.palette.border.primary;
    case "error":
      return theme.palette.error.light;
    case "success":
      return theme.palette.success.light;
    default:
      return theme.palette.border.primary;
  }
};

const getIconColor = (type: string, theme: Theme) => {
  switch (type) {
    case "grey-info":
      return theme.palette.icon.primary;
    case "warn":
      return theme.palette.icon.warning;
    case "white":
      return theme.palette.icon.primary;
    case "error":
      return theme.palette.icon.error;
    case "success":
      return theme.palette.icon.success;
    default:
      return theme.palette.icon.active;
  }
};

const getSecondaryButtonColor = (type: string, theme: Theme) => {
  switch (type) {
    case "grey-info":
      return theme.palette.text.secondary;
    case "warn":
      return theme.palette.warning.main;
    case "white":
      return theme.palette.text.secondary;
    case "error":
      return theme.palette.error.main;
    case "success":
      return theme.palette.success.main;
    default:
      return theme.palette.primary.main;
  }
};

const getShowMoreButtonColor = (type: string, theme: Theme) => {
  switch (type) {
    case "grey-info":
      return theme.palette.text.primary;
    case "warn":
      return theme.palette.warning.main;
    case "white":
      return theme.palette.text.primary;
    case "error":
      return theme.palette.error.main;
    case "success":
      return theme.palette.success.main;
    default:
      return theme.palette.primary.main;
  }
};

export type BannerProps = BoxProps & {
  type: "info" | "grey-info" | "warn" | "error" | "white" | "success";
  icon?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  textStyle?: React.CSSProperties;
  primaryButton?: {
    label: string;
    onClick: () => void;
    testId?: string;
    variant?: "text" | "outlined" | "contained" | undefined;
    color?:
      | "inherit"
      | "grey"
      | "info"
      | "error"
      | "primary"
      | "secondary"
      | "success"
      | "warning"
      | "tertiary"
      | undefined;
  };
  secondaryButton?: {
    label: string;
    onClick: (event: MouseEvent<HTMLElement>) => void;
    testId?: string;
  };
  breakpoint?: number | Breakpoint;
  alignBannerItems?: string;
  endAdornment?: React.ReactNode;
};

export const Banner = ({
  type = "info",
  icon,
  title,
  text,
  primaryButton,
  secondaryButton,
  endAdornment,
  breakpoint = "sm",
  alignBannerItems = "center",
  textStyle = {},
  ...props
}: BannerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  const [isShowedMore, setIsShowedMore] = useState(false);

  const isTextShouldClamp =
    typeof text === "string" &&
    text.length > SHOWED_TEXT_LENGTH &&
    isMobile &&
    !isShowedMore;

  const showedText = isTextShouldClamp
    ? `${text.slice(0, SHOWED_TEXT_LENGTH)}..`
    : text;

  const isShowMoreBtnShown = isTextShouldClamp;

  return (
    <BannerContainer
      type={type}
      breakpoint={breakpoint}
      alignBannerItems={alignBannerItems}
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        color={getIconColor(type, theme)}
        sx={{
          "& svg": {
            color: getIconColor(type, theme),
            flexShrink: 0,
            marginRight: theme.spacing(4.5),
          },
        }}
      >
        {icon}
      </Box>
      <InnerBannerContainer breakpoint={breakpoint}>
        <Box
          sx={{
            marginRight: theme.spacing(4.5),
            [theme.breakpoints.down(breakpoint)]: {
              marginBottom: theme.spacing(
                primaryButton || secondaryButton ? 3 : 0
              ),
            },
          }}
        >
          {title && (
            <Typography
              variant="body1"
              sx={{ fontWeight: 600 }}
              whiteSpace="pre-line"
            >
              {title}
            </Typography>
          )}
          {typeof text === "string" ? (
            <>
              <Typography
                color={
                  type === "error"
                    ? theme.palette.text.negative
                    : theme.palette.text.secondary
                }
                variant="body2"
                fontWeight="500"
                sx={textStyle}
              >
                {showedText}
                {isShowMoreBtnShown && (
                  <Typography
                    variant="body2"
                    component="span"
                    color={getShowMoreButtonColor(type, theme)}
                    onClick={() => setIsShowedMore(true)}
                    ml={1}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: getSecondaryButtonColor(type, theme),
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {t("showMore")}
                  </Typography>
                )}
              </Typography>
            </>
          ) : (
            text
          )}
        </Box>
        {Boolean(primaryButton || secondaryButton) && (
          <ButtonsContainer>
            {primaryButton && (
              <Button
                variant={primaryButton.variant || "outlined"}
                color={primaryButton.color || "grey"}
                data-testid={primaryButton.testId || "banner-primary-button"}
                size="small"
                onClick={primaryButton.onClick}
                sx={{ flexShrink: 0 }}
              >
                {primaryButton.label}
              </Button>
            )}
            {secondaryButton && (
              <Link
                onClick={secondaryButton.onClick}
                underline="none"
                color={getSecondaryButtonColor(type, theme)}
                data-testid={
                  secondaryButton.testId || "banner-secondary-button"
                }
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: getSecondaryButtonColor(type, theme),
                    textDecoration: "underline",
                  },
                }}
              >
                {secondaryButton.label}
              </Link>
            )}
          </ButtonsContainer>
        )}
        {endAdornment}
      </InnerBannerContainer>
    </BannerContainer>
  );
};
