import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, IconButton, Menu, Typography } from "@mui/material";
import { ReactComponent as CogIcon } from "@icons/cog.svg";
import { SecurityImage } from "@components/SecurityImage";
import { SecuritiesSinglePageMobileMenu } from "./SecuritiesSinglePageMobileMenu";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(7.5)};
`;

export interface SecuritiesSinglePageHeaderProps {
  securityName: string;
  securityIsin: string;
  securityId: string;
  watchlistId: string;
  image?: string | null;
}

export const SecuritiesSinglePageHeader = (
  props: SecuritiesSinglePageHeaderProps
) => {
  const { securityId, watchlistId, securityName, image } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" gap={3}>
        <SecurityImage src={image} alt={securityName} />
        <Typography variant="h4" component="h1">
          {securityName}
        </Typography>
      </Box>

      <div>
        <IconButton onClick={handleOpenMenu} color="secondary">
          <CogIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <SecuritiesSinglePageMobileMenu
            securityId={securityId}
            watchlistId={watchlistId}
          />
        </Menu>
      </div>
    </Container>
  );
};
