import { SecurityAttributes } from "@generated/apiv3/quotes";
import { SymbolV2 } from "@generated/apiv1";

export const convertSecurityToSymbolV2 = (
  id: string,
  security: SecurityAttributes
): SymbolV2 => {
  return {
    id,
    type: security.security_type!,
    isin: security.isin!,
    name: security.name!,
    wkn: security.wkn!,
    // @ts-ignore TODO: backend fix
    quoteProvider: security.quote_provider!,
    regionIsoCode: security.region_iso_code!,
  };
};
