import { UseMutationOptions } from "react-query";
import { CSVApiParseCsvRequest, CsvParseResult } from "@generated/apiv3";
import { useAuthMutation, axiosInstanceV3 } from "@api";

// had to write a custom method because otherwise automatically generated method will stringify the file as json (non-fixable or time-consuming bug on the backend specification)
export const parseCsv = async (
  token: string | undefined,
  params?: CSVApiParseCsvRequest
): Promise<CsvParseResult> => {
  const { data } = await axiosInstanceV3.postForm(
    `/csv/parse`,
    {
      file: params?.file,
      bank_slug: params?.bankSlug,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const useCsvUpload = (
  options?: UseMutationOptions<
    CsvParseResult,
    AxiosApiError,
    CSVApiParseCsvRequest
  >
) => {
  return useAuthMutation(parseCsv, options);
};
