import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { LegendCircle } from "../LegendCircle";

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 110px;
  white-space: nowrap;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ItemContainer = styled.div`
  display: flex;
`;

const LegendCircleContainer = styled.div`
  height: 12px;
  width: 12px;
  margin-top: 3px;
  margin-right: 5px;
`;

interface Props {
  beforeComponent?: ReactNode;
  color?: string;
  title: string;
  subtitle?: ReactNode;
  isStrong?: boolean;
}

export const Item = ({
  color,
  title,
  subtitle,
  isStrong = false,
  beforeComponent = (
    <LegendCircleContainer>
      <LegendCircle
        color={color || "black"}
        size={isStrong ? "12px" : "10px"}
      />
    </LegendCircleContainer>
  ),
}: Props) => (
  <ItemContainer>
    {beforeComponent}
    <TitleContainer>
      <Title variant={isStrong ? "h3" : "body1"}>{title}</Title>
      {typeof subtitle === "string" ? (
        <SubTitle>{subtitle}</SubTitle>
      ) : (
        subtitle
      )}
    </TitleContainer>
  </ItemContainer>
);
