import { UseQueryOptions } from "react-query";
import { QuotesApiGetQuotesV2Request, QuoteList } from "@generated/apiv1";

import { buildQuotesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getQuotesV2 = async (
  token: string | undefined,
  params: QuotesApiGetQuotesV2Request
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getQuotesV2(params);
  return data;
};

export const useGetQuotesV2 = (
  params: QuotesApiGetQuotesV2Request,
  options?: UseQueryOptions<QuoteList, AxiosApiError, QuoteList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_QUOTES_V2, params],
    ({ token }) => getQuotesV2(token, params),
    options
  );
};
