import { UseMutationOptions, UseQueryOptions } from "react-query";
import {
  downloadInvestments,
  GetInvestmentParams,
  getInvestments,
} from "@api/v1/methods";
import { PageableInvestmentList } from "@api/v1/model";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import { GET_INVESTMENTS } from "../../cacheKeys";

export const useGetInvestments = (
  params: GetInvestmentParams = {},
  options: UseQueryOptions<
    PageableInvestmentList,
    AxiosApiError,
    PageableInvestmentList
  > = { enabled: true }
) =>
  useAuthQuery(
    [GET_INVESTMENTS, params],
    ({ token }) => getInvestments(token, params),
    options
  );

export const useDownloadInvestments = (
  params: GetInvestmentParams,
  options?: UseMutationOptions<Blob, AxiosApiError, unknown>
) => useAuthMutation((token) => downloadInvestments(token, params), options);
