export default {
  en: {
    translation: {
      domains: {
        investments: {
          investmentTable: {
            quote: "Quote",
            entries_one: "{{count}} entry",
            entries_other: "{{count}} entries",
            purchase: "Purchase Value",
            name: "Name",
            change: "Current value",
            pieces: "Lots at",
            at: "at",
            dividends: "Dividends",
            allocation: "Allocation",
            changePercentAbs: "Current price",
            totalValue: "Total value",
            dividendsValue: "Dividend",
            addQuote: {
              modalTitle: "Add quote",
              quote: "Quote",
              quoteFrom: "Quote from",
              save: "Save",
              successMessage: "Successfully saved",
            },
          },
        },
        dividends: {
          noDividends: "No dividends. Would you like to add manually?",
          noDividendsAddLink: "Add",
          addDividends: "Add custom",
          missingDividends: {
            header_one: "There is {{value}} missing dividend.",
            header_other: "There are {{value}} missing dividends.",
            addBatchButton: "Add now",
          },
          dividendsTable: {
            headers: {
              date: "Date",
              dividend: "Dividend",
              lots: "Lots",
              total: "Total",
              actions: "Actions",
            },
            actions: {
              addDividends: "Add",
              editDividends: "Edit",
            },
          },
        },
        bankSelect: {
          pageTitle: "Import depots/accounts",
          title: "Please search bank",
          searchLabel: "Bank Name, BIC or IBAN",
          selectBic: {
            title: "Please select BIC",
            back: "Back",
          },
          notFound: "Bank not found.",
          notSupported: "The bank is currently not supported.",
        },
        loginForm: {
          login: {
            label: "Your Email",
            placeholder: "Email",
            loginFailed: "Login failed. <1>Did you forget your password?</1>",
            locked:
              "Your account has been locked do to too many invalid login attempts. <1>Unlock your account now.</1>",
            somethingWrong:
              "Sorry, something went wrong(status:{{errorStatusCode}}). Try again. Please contact our support using <1>{{supportEmail}}</1> if this problem persist.",
            somethingWrongWithoutStatus:
              "Sorry, something went wrong. Try again. Please contact our support using <1>{{supportEmail}}</1> if this problem persist.",
          },
          password: {
            label: "Password",
            placeholder: "Password",
            forgot: "Forgot passwort?",
            clickHere: "Click here",
          },
          rememberMe: {
            label: "Stay logged in",
          },
          submit: {
            label: "Login",
          },
          register: {
            label: "Register now",
            question: "No account?",
          },
        },
        creditCardsMiniCard: {
          lastTransaction: "The last transaction",
          noTransactions: "No transactions",
        },
        creditCardCard: {
          title: "Credit cards",
          title_one: "{{count}} Credit card",
          title_other: "{{count}} Credit cards",
          noAccountsTitle: "No credit cards",
          noAccountsSubtitle:
            "You do not have any credit cards, do you want to add them now?",
          noAccountsButtonText: "Add now",
        },
        cashAccountsCard: {
          title: "Accounts",
          subtitle_one: "{{count}} Account",
          subtitle_other: "{{count}} Accounts",
          noAccountsTitle: "No accounts",
          noAccountsSubtitle:
            "You do not have any accounts, do you want to add them now?",
          noAccountsButtonText: "Add now",
          percentPrefix: "YTD:",
        },
        donutChart: {
          total: "Total",
        },
        depotsCard: {
          title: "Depots",
          depotsTitle_one: "{{count}} Depot",
          depotsTitle_other: "{{count}} Depots",
          noAccountsTitle: "No depots",
          noAccountsSubtitle:
            "You do not have any depots, do you want to add them now?",
          noAccountsButtonText: "Add now",
          addButton: "Add",
          addManuallyButton: "Manually",
        },
        accountPreviewCard: {
          updateRecommended: "Update recommended",
        },
        investmentsCard: {
          title: "Investments",
          subtitle_one: "{{count}} Investment",
          subtitle_other: "{{count}} Investments",
          noAccountsTitle: "No investments",
          noAccountsSubtitle:
            "You do not have any investments, do you want to add them now?",
          noAccountsButtonText: "Add now",
          percentPrefix: "YTD:",
        },
      },
      general: {
        chart: {
          assetsChart: {
            legend: {
              investments: "Investments",
              cash: "Cash",
              insurances: "Insurances",
            },
          },
          selectComparison: {
            compareWith: "Compare with",
            recommends: "Recommendations",
            markets: "Markets",
          },
          securitiesChart: {
            assetTypes: {
              all: "All",
              derivative: "Derivative",
              etfs: "ETFs",
              funds: "Funds",
              bonds: "Bonds",
              holdings: "Holdings",
            },
            securitiesChartBottomBar: {
              typeSwitcher: {
                performance: "Performance",
                valuation: "Valuation",
                disabledTooltip:
                  "Comparison is only supported in mode ‘performance’",
              },
              simulateProduct: {
                simulateBtn: "Simulate product recommendation",
                toProductBtn: "To the product",
                tooltipTitle:
                  "We found products that would optimize your portfolio",
                tooltipText:
                  "We have found at least 1 product that has a better risk/return ratio than your current portfolio. Click the button to see the direct comparison to your current portfolio.",
              },
            },
            mobileHeaderValues: {
              totalValue: "All",
              holdingsValue: "Holdings",
              fondsValue: "Funds",
              etfsValue: "ETFs",
              derivativeValue: "Derivative",
            },
          },
        },
        ranges: {
          rangeSelector: {
            intervalSelector: {
              compact: {
                oneDay: "1D",
                oneWeek: "1W",
                oneMonth: "1M",
                sixMonths: "6M",
                oneYear: "1Y",
                threeYears: "3Y",
                fiveYears: "5Y",
                YTD: "YTD",
                sinceBeginning: "Max",
              },
              oneWeek: "1 week",
              oneMonth: "1 month",
              oneYear: "1 year",
              sinceBeginning: "Since the beginning",
            },
          },
        },
        table: {
          tableMobileSortMenu: {
            sortBy: "Sort by",
          },
        },
      },
    },
  },
  de: {
    translation: {
      domains: {
        investments: {
          investmentTable: {
            quote: "Aktueller Kurs",
            entries_one: "{{count}} Entry",
            entries_other: "{{count}} Entries",
            purchase: "Kaufwert",
            name: "Name",
            change: "Aktueller Wert",
            pieces: "St. zu",
            at: "zu",
            dividends: "Dividende",
            allocation: "Allokation",
            changePercentAbs: "Kurs",
            totalValue: "Gesamtwert",
            dividendsValue: "Dividende",
            addQuote: {
              modalTitle: "Kurs hinzufügen",
              quote: "Kurs",
              quoteFrom: "Kurs vom",
              save: "Speichern",
              successMessage: "Erfolgreich gespeichert",
            },
          },
        },
        dividends: {
          noDividends:
            "Keine Dividenden. Möchten Sie einen Eintrag hinzufügen?",
          noDividendsAddLink: "Hinzufügen",
          addDividends: "Manueller Eintrag",
          missingDividends: {
            header_one: "{{value}} fehlende Dividende.",
            header_other: "{{value}} fehlende Dividenden.",
            addBatchButton: "Jetzt hinzufügen",
          },
          dividendsTable: {
            headers: {
              date: "Datum",
              dividend: "Dividende",
              lots: "Stück",
              total: "Gesamt",
              actions: "Aktion",
            },
            actions: {
              addDividends: "Hinzufügen",
              editDividends: "Bearbeiten",
            },
          },
        },
        bankSelect: {
          pageTitle: "Depots/Konten importieren",
          title: "Bitte wählen Sie Ihre Bank",
          searchLabel: "IBAN, BLZ, BIC oder Name",
          selectBic: {
            title: "Bitte wählen Sie Ihre BIC",
            back: "Zurück",
          },
          notFound: "Nicht gefunden",
          notSupported: "Diese Bank wird aktuell noch nicht unterstützt",
        },
        loginForm: {
          login: {
            label: "Ihre Email-Adresse",
            placeholder: "Email-Adresse",
            loginFailed:
              "Login fehlgeschlagen. <1>Haben Sie Ihr Passwort vergessen?</1>",
            locked:
              "Ihr Benutzerkonto wurde aufgrund von mehreren ungültigen Login-Versuchen gesperrt. <1>Konto jetzt entsperren.</1>",
            somethingWrong:
              "Es ist ein Fehler aufgetreten(status:{{errorStatusCode}}). Bitte versuchen Sie es nochmals. Kontaktieren Sie unseren Support unter <1>{{supportEmail}}</1>, sollte dieses Problem mehrfach auftreten.",
            somethingWrongWithoutStatus:
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es nochmals. Kontaktieren Sie unseren Support unter <1>{{supportEmail}}</1>, sollte dieses Problem mehrfach auftreten.",
          },
          password: {
            label: "Passwort",
            placeholder: "Passwort",
            forgot: "Passwort vergessen?",
            clickHere: "Hier klicken",
          },
          rememberMe: {
            label: "Eingeloggt bleiben",
          },
          submit: {
            label: "Login",
          },
          register: {
            label: "Jetzt Registrieren",
            question: "Noch kein Account?",
          },
        },
        creditCardsMiniCard: {
          lastTransaction: "Letzte Buchung",
          noTransactions: "Keine Buchungen",
        },
        creditCardCard: {
          title: "Kreditkarten",
          title_one: "{{count}} Kreditkarte",
          title_other: "{{count}} Kreditkarten",
          noAccountsTitle: "Keine Kreditkarten",
          noAccountsSubtitle:
            "Sie haben bisher noch keine Kreditkarten hinzugefügt, möchten Sie das jetzt tun?",
          noAccountsButtonText: "Kreditkarten hinzufügen",
        },
        cashAccountsCard: {
          title: "Konten",
          subtitle_one: "{{count}} Konto",
          subtitle_other: "{{count}} Konten",
          noAccountsTitle: "Keine Konten",
          noAccountsSubtitle:
            "Sie haben bisher noch keine Konten hinzugefügt, möchten Sie das jetzt tun?",
          noAccountsButtonText: "Konten hinzufügen",
          percentPrefix: "YTD:",
        },
        donutChart: {
          total: "Gesamtausgaben",
        },
        depotsCard: {
          title: "Depots",
          depotsTitle_one: "{{count}} Depot",
          depotsTitle_other: "{{count}} Depots",
          noAccountsTitle: "Keine Konten",
          noAccountsSubtitle:
            "Sie haben bisher noch keine Konten hinzugefügt, möchten Sie das jetzt tun?",
          noAccountsButtonText: "Konten hinzufügen",
          addButton: "Add",
          addManuallyButton: "Manually",
        },
        accountPreviewCard: {
          updateRecommended: "Aktualisierung empfohlen",
        },
        investmentsCard: {
          title: "Investments",
          subtitle_one: "{{count}} Investment",
          subtitle_other: "{{count}} Investments",
          noAccountsTitle: "Keine Investments",
          noAccountsSubtitle:
            "Sie haben bisher noch keine Investments hinzugefügt, möchten Sie das jetzt tun?",
          noAccountsButtonText: "Investments hinzufügen",
          percentPrefix: "YTD:",
        },
      },
      general: {
        chart: {
          assetsChart: {
            legend: {
              investments: "Investments",
              cash: "Cash",
              insurances: "Versicherungen",
            },
          },
          selectComparison: {
            compareWith: "Vergleichen mit",
            recommends: "VORSCHLÄGE",
            markets: "MÄRKTE",
          },
          securitiesChart: {
            assetTypes: {
              all: "Alle",
              derivative: "Derivate",
              etfs: "ETF",
              funds: "Fonds",
              bonds: "Anleihen",
              holdings: "Einzeltitel",
            },
            securitiesChartBottomBar: {
              typeSwitcher: {
                performance: "Performance",
                valuation: "Wert",
                disabledTooltip:
                  "Ein Vergleich der Werte kann nur im Modus ‘Performance’ vorgenommen werden",
              },
              simulateProduct: {
                simulateBtn: "Produktempfehlung simulieren",
                toProductBtn: "Zum Produkt",
                tooltipTitle:
                  "Wir haben Produkte gefunden, die Ihr Portfolio optimieren würden",
                tooltipText:
                  "Wir haben mindestens 1 Produkt gefunden, das ein besseres Rendite-Risiko-Verhältnis aufweist als Ihr aktuelles Portfolio. Klicken Sie den Button, um den direkten Vergleich zu Ihrem aktuellen Portfolio zu sehen.",
              },
            },
            mobileHeaderValues: {
              totalValue: "Alle",
              holdingsValue: "Einzeltitel",
              fondsValue: "Fonds",
              etfsValue: "ETF",
              derivativeValue: "Derivate",
            },
          },
        },
        ranges: {
          rangeSelector: {
            intervalSelector: {
              compact: {
                oneDay: "1D",
                oneWeek: "1W",
                oneMonth: "1M",
                sixMonths: "6M",
                oneYear: "1J",
                threeYears: "3J",
                fiveYears: "5J",
                YTD: "YTD",
                sinceBeginning: "Max",
              },
              oneWeek: "1 Woche",
              oneMonth: "1 Monat",
              oneYear: "1 Jahr",
              sinceBeginning: "Seit Beginn",
            },
          },
        },
        table: {
          tableMobileSortMenu: {
            sortBy: "Sortieren nach",
          },
        },
      },
    },
  },
};
