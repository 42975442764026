import React, { useState, ChangeEvent } from "react";
import { styled, Table, TablePagination, TableContainer } from "@mui/material";
import { Dividend } from "@api";
import { DividendsTableHeader } from "./DividendsTableHeader";
import { DividendsTableBody } from "./DividendsTableBody";

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  & .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(2, 4)};
    border-color: ${({ theme }) => theme.palette.border.secondary};
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):last-child .MuiTableCell-root {
    border-bottom: none;
  }
`;

interface Props {
  unreceivedDividendsCount: number;
  allDividends: Dividend[];
  selectedDividends: Dividend[];
  onSelectDividend: (dividend: Dividend) => void;
  onSelectAllDividends: (type: "select" | "unselect") => void;
}

export const DividendsTable = ({
  allDividends,
  selectedDividends,
  unreceivedDividendsCount,
  onSelectAllDividends,
  onSelectDividend,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllDividends = (event: ChangeEvent<HTMLInputElement>) => {
    const type = event.target.checked ? "select" : "unselect";
    onSelectAllDividends(type);
  };

  return (
    <StyledTableContainer>
      <Table>
        <DividendsTableHeader
          onSelectAllClick={handleSelectAllDividends}
          rowCount={unreceivedDividendsCount}
          rowSelected={selectedDividends.length}
        />
        <DividendsTableBody
          allDividends={allDividends.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          onSelectDividend={onSelectDividend}
          selectedDividends={selectedDividends}
        />
      </Table>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[5, 10, 25]}
        count={allDividends.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTableContainer>
  );
};
