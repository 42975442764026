import { format } from "date-fns";
import { useGetExchangeRateOnDemand } from "@api/v1/hooks/useGetExchangeRate";
import { useLookupHistoricQuotesOnDemand } from "@api/v1/hooks/useLookupHistoricQuotes";

export const useProposePrice = (
  tickerSymbol: string,
  quoteProvider: string,
  quoteCurrency: string,
  investmentCurrency: string,
  handleSecurityPriceChange: (quote: number) => void,
  handleExchangeRateChange: (rate: number) => void
) => {
  const { mutateAsync: lookupQuotes } = useLookupHistoricQuotesOnDemand();
  const { mutateAsync: getExchangeRate } = useGetExchangeRateOnDemand();

  const proposeSecurityPriceChange = async (date: Date) => {
    if (!date || (date && isNaN(date.getTime()))) return;
    lookupQuotes({
      tickerSymbol,
      quoteProvider,
      quoteCurrency: quoteCurrency,
      convertToCurrency: investmentCurrency,
      from: format(date, "yyyy-MM-dd"),
      to: format(date, "yyyy-MM-dd"),
    })
      .then((result) => {
        if (!result || !result.length) return;
        if (result[0].value == null) return;
        handleSecurityPriceChange(result[0].value);
      })
      .catch(() => {
        // ignore...
      });
  };

  const proposeExchangeRateChange = async (date: Date) => {
    if (!date || (date && isNaN(date.getTime()))) return;
    if (!investmentCurrency || investmentCurrency === "EUR") return;
    getExchangeRate({
      from: "EUR",
      to: investmentCurrency,
      date: format(date, "yyyy-MM-dd"),
    })
      .then((result) => {
        if (!result || !result.rate) return;
        handleExchangeRateChange(result.rate);
      })
      .catch(() => {
        // ignore...
      });
  };

  return {
    proposeSecurityPriceChange,
    proposeExchangeRateChange,
  };
};
