import { QueryClient } from "react-query";
import { AxiosError } from "axios";
import { goToEmailConfirmation } from "@helpers/mainAppUrls";
import { NoTokenError } from "./auth/NoTokenError";
import { isEmailConfirmationRequiredError } from "./auth/isEmailConfirmationRequiredError";

const retry = (failureCount: number, err: unknown) => {
  const error = err as NoTokenError | AxiosError;

  if ((error as AxiosError).response?.status === 401 && failureCount > 1) {
    return false;
  }

  if (error instanceof NoTokenError) {
    return false;
  }

  if (isEmailConfirmationRequiredError(error as AxiosError<ApiException>)) {
    goToEmailConfirmation();
    return false;
  }

  if ((error as AxiosError).response?.status === 403) {
    return false;
  }
  if ((error as AxiosError).response?.status === 404) {
    return false;
  }
  return failureCount <= 2;
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry } },
});
