import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiEditBankConnectionRequest,
  BankConnectionWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const editBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiEditBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.editBankConnection(params);
  return data;
};

export const useEditBankConnection = (
  options?: UseMutationOptions<
    BankConnectionWrapper,
    AxiosApiError,
    BankConnectionsApiEditBankConnectionRequest
  >
) => {
  return useAuthMutation(editBankConnection, options, false);
};
