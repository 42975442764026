import React from "react";
import { AccountTypeEnum } from "@generated/apiv1";
import { ReactComponent as ReceiveMoneyIcon } from "@icons/receive-money.svg";
import { ReactComponent as CreditCardIcon } from "@icons/credit-card.svg";
import { ReactComponent as LineChartIcon } from "@icons/line-chart.svg";
import { ReactComponent as CryptoIcon } from "@icons/crypto.svg";
import { ReactComponent as EuroIcon } from "@icons/euro.svg";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as DotsIcon } from "@icons/dots.svg";
import { ReactComponent as MetalsIcon } from "@icons/edelmetalle.svg";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";

export type AccountType = {
  value: AccountSubcategoryEnum;
  icon: React.ReactNode;
  accountType: AccountTypeEnum;
};

export const accountTypes: AccountType[] = [
  {
    value: AccountSubcategoryEnum.DEPOT,
    icon: <LineChartIcon width="20px" />,
    accountType: "01_depot",
  },
  {
    value: AccountSubcategoryEnum.CASH_ACCOUNT,
    icon: <EuroIcon width="20px" />,
    accountType: "02_cash",
  },
  {
    value: AccountSubcategoryEnum.REAL_ESTATE,
    icon: <HomeIcon width="20px" />,
    accountType: "05_material_assets",
  },
  {
    value: AccountSubcategoryEnum.LOAN,
    icon: <ReceiveMoneyIcon width="20px" />,
    accountType: "02_cash",
  },
  {
    value: AccountSubcategoryEnum.CREDIT_CARD,
    icon: <CreditCardIcon width="20px" />,
    accountType: "02_cash",
  },
  {
    value: AccountSubcategoryEnum.CRYPTO,
    icon: <CryptoIcon width="20px" />,
    accountType: "01_depot",
  },
  {
    value: AccountSubcategoryEnum.PRECIOUS_METALS,
    icon: <MetalsIcon width="20px" />,
    accountType: "05_material_assets",
  },
  {
    value: AccountSubcategoryEnum.OTHER_MATERIAL_ASSETS,
    icon: <DotsIcon width="20px" />,
    accountType: "05_material_assets",
  },
];

export const accountSubcategoryMap: Record<AccountSubcategoryEnum, string> = {
  DEPOT: "01_depot",
  CASH_ACCOUNT: "02_cash",
  REAL_ESTATE: "05_material_assets",
  LOAN: "02_cash",
  CREDIT_CARD: "02_cash",
  CRYPTO: "01_depot",
  PRECIOUS_METALS: "05_material_assets",
  OTHER_MATERIAL_ASSETS: "05_material_assets",
};
