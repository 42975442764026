import { UseQueryOptions } from "react-query";
import { AccountsApiAccountsIdRequest, Account } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const accountsId = async (
  token: string | undefined,
  params: AccountsApiAccountsIdRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.accountsId(params);
  return data;
};

export const useAccountsId = (
  params: AccountsApiAccountsIdRequest,
  options?: UseQueryOptions<Account, AxiosApiError, Account>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_ACCOUNTS_ID, params],
    ({ token }) => accountsId(token, params),
    options
  );
};
