import { TransactionWizardContext } from "../types/transactionWizardContext";

export const clearInvestmentValuesAction = (
  context: TransactionWizardContext
) => {
  const depotId = new URL(window.location.href).searchParams.get("depotId");
  const investmentId = new URL(window.location.href).pathname?.match(
    "/investments/(.*)"
  )?.[1];

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId: depotId
        ? parseInt(depotId)
        : context.sourceInvestmentValues?.depotId,
      isin: undefined,
      name: undefined,
      id: investmentId ? parseInt(investmentId) : undefined,
      numberOfLots: undefined,
      tickerSymbol: undefined,
    },
  };
};
