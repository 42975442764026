import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { uniq, uniqBy } from "lodash";
import { isWithinInterval } from "date-fns";
import { usePublicOrPrivateBookings } from "@features/sharedPortfolio/hooks/usePublicOrPrivateBookings";
import {
  Booking,
  BookingTypeEnum,
  InvestmentTypeEnum,
  ListAndSearchAllBookingsInvestmentTypeEnum,
  ListAndSearchAllBookingsTypeEnum,
} from "@generated/apiv1";
import { SelectOption } from "@components/common/forms/MultipleSelect";
import { intervalToTimeRange } from "@helpers";

type Filters = {
  bookings?: ListAndSearchAllBookingsTypeEnum[] | null;
  investment?: string | null;
  investmentTypes?: ListAndSearchAllBookingsInvestmentTypeEnum[] | null;
  range?: { startDate?: Date; endDate?: Date } | null;
};

export const filterBookings = (
  bookings: Booking[] | undefined,
  filterType: "investmentType" | "investment" | "bookingType" | "range",
  filters: Filters
) => {
  if (!bookings) return [];

  return bookings.filter((booking) => {
    const withinDate = filters.range
      ? isWithinInterval(new Date(booking.valueDate), {
          start: filters.range.startDate || new Date(0),
          end: filters.range.endDate || new Date(),
        })
      : true;

    switch (filterType) {
      case "investmentType":
        return (
          withinDate &&
          (filters.investment
            ? booking.investment.isin === filters.investment
            : true) &&
          (filters.bookings?.length
            ? filters.bookings.includes(
                booking.type as ListAndSearchAllBookingsTypeEnum
              )
            : true)
        );
      case "investment":
        return (
          withinDate &&
          (filters.investmentTypes?.length
            ? filters.investmentTypes.includes(
                booking.investment
                  .type as ListAndSearchAllBookingsInvestmentTypeEnum
              )
            : true) &&
          (filters.bookings?.length
            ? filters.bookings.includes(
                booking.type as ListAndSearchAllBookingsTypeEnum
              )
            : true)
        );
      case "bookingType":
        return (
          withinDate &&
          (filters.investment
            ? booking.investment.isin === filters.investment
            : true) &&
          (filters.investmentTypes?.length
            ? filters.investmentTypes.includes(
                booking.investment
                  .type as ListAndSearchAllBookingsInvestmentTypeEnum
              )
            : true)
        );
      case "range":
        return (
          (filters.investment
            ? booking.investment.isin === filters.investment
            : true) &&
          (filters.investmentTypes?.length
            ? filters.investmentTypes.includes(
                booking.investment
                  .type as ListAndSearchAllBookingsInvestmentTypeEnum
              )
            : true) &&
          (filters.bookings?.length
            ? filters.bookings.includes(
                booking.type as ListAndSearchAllBookingsTypeEnum
              )
            : true)
        );

      default:
        return bookings;
    }
  });
};

export const useBookingsFilters = (
  accountIds: number[],
  filters: Filters,
  defaultIntervalTypes: IntervalType[]
): {
  assetTypes: SelectOption<InvestmentTypeEnum>[];
  investments: string[];
  bookingTypes: SelectOption<BookingTypeEnum>[];
  intervalTypes: IntervalType[];
} => {
  const { t } = useTranslation();
  const { bookings: bookingsForFilters } = usePublicOrPrivateBookings(
    {
      accountId: accountIds,
      perPage: 5000,
      minDate: filters.range?.startDate?.toISOString(),
      maxDate: filters.range?.endDate?.toISOString(),
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const assetTypes = useMemo(() => {
    return uniqBy(
      filterBookings(bookingsForFilters, "investmentType", filters)?.map(
        (booking) => ({
          value: booking.investment.type as InvestmentTypeEnum,
          label: t(`investmentTypes.${booking.investment.type}`),
        })
      ),
      (item) => item.value
    );
  }, [bookingsForFilters, filters, t]);

  const investments = useMemo(() => {
    return uniq(
      filterBookings(bookingsForFilters, "investment", filters)?.map(
        (booking) => booking.investment.isin
      )
    );
  }, [bookingsForFilters, filters]);

  const bookingTypes = useMemo(() => {
    return uniqBy(
      filterBookings(bookingsForFilters, "bookingType", filters)?.map(
        (booking) => ({
          value: booking.type,
          label: t(`bookingTypes.${booking.type}`),
        })
      ),
      (item) => item.value
    );
  }, [bookingsForFilters, filters, t]);

  const intervalTypes = useMemo(() => {
    const bookings = filterBookings(bookingsForFilters, "range", filters);
    return defaultIntervalTypes.filter((interval) => {
      if (interval === "custom") return true;

      const range = intervalToTimeRange(interval);
      return bookings.some((booking) =>
        isWithinInterval(new Date(booking.valueDate), {
          start: range.startDate || new Date(0),
          end: range.endDate,
        })
      );
    });
  }, [bookingsForFilters, filters, defaultIntervalTypes]);

  return {
    assetTypes,
    investments,
    bookingTypes,
    intervalTypes,
  };
};
