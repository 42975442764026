import React from "react";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery, Theme } from "@mui/material";
import i18next from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { LayoutWithTabs } from "@components/common/layouts/LayoutWithTabs";
import { DepotsSelectorDropdown } from "@features/selectDepots/components/DepotsSelectorDropdown";
import { Classifications } from "@features/analysis/ClassificationsTab/Classifications";
import { SelectGroupParams } from "@features/selectDepots/types";
import { useSelectedAccounts } from "@hooks";

const accountParams: AccountsApiListAndSearchAllAccountsRequest = {
  type: ["01_depot", "05_material_assets"],
};

const selectedGroupParams: SelectGroupParams = {
  id: "analyseDepots",
  fallbackAccountType: ["01_depot", "05_material_assets"],
};

export const AnalysisPage = () => {
  const { t } = useTranslation();
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedMenuTab = searchParams.get("menu") || "overview";
  const isLess750 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(750)
  );

  const { selectedAccounts, refresh } = useSelectedAccounts({
    selectedGroupParams,
    accountParams,
  });
  const selectedAccountIds = selectedAccounts
    .filter((account) => account.selected)
    .map((account) => account.id);

  const tabs = [
    {
      key: "overview",
      title: i18next.t("analysis.tabs.overview"),
      body: <Classifications accountIds={selectedAccountIds ?? []} />,
    },
    /*{
      key: "yield",
      title: i18next.t("analysis.tabs.yield"),
      body: <PerformanceTab accountIds={selectedAccountIds ?? []} />,
    },*/
  ];

  return (
    <LayoutWithTabs
      title={t("analysis.title")}
      currentTab={selectedMenuTab}
      tabs={tabs}
      onTabChange={(tab) => {
        navigate(
          isSharedPortfolio
            ? `/portfolios/${publicId}/classifications?menu=${tab}`
            : `/classifications?menu=${tab}`
        );
      }}
      breakpoint={750}
      actionButtons={
        <Box width={isLess750 ? "100%" : "auto"}>
          <DepotsSelectorDropdown
            selectedGroupParams={selectedGroupParams}
            accountParams={accountParams}
            onSave={refresh}
            fullWidth={isLess750}
            MenuProps={{
              sx: {
                "& .MuiMenu-paper": {
                  maxHeight: 300,
                  maxWidth: isLess750 ? "unset" : 246,
                },
              },
            }}
            sx={{
              width: isLess750 ? "100%" : 246,
            }}
          />
        </Box>
      }
    />
  );
};
