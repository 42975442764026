import { UseQueryOptions } from "react-query";
import {
  BillingCyclesApiBillingCyclePayoutStatisticsRequest,
  CommissionStatisticList,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const billingCyclePayoutStatistics = async (
  token: string | undefined,
  params?: BillingCyclesApiBillingCyclePayoutStatisticsRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.billingCyclePayoutStatistics(params);
  return data;
};

export const useBillingCyclePayoutStatistics = (
  params?: BillingCyclesApiBillingCyclePayoutStatisticsRequest,
  options?: UseQueryOptions<
    CommissionStatisticList,
    AxiosApiError,
    CommissionStatisticList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_BILLING_CYCLE_PAYOUT_STATISTICS, params],
    ({ token }) => billingCyclePayoutStatistics(token, params),
    options
  );
};
