/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DepotSynchronizationLog } from '../model';
// @ts-ignore
import { PageableDepotSynchronizationLogList } from '../model';
/**
 * DepotSynchronizationLogsApi - axios parameter creator
 * @export
 */
export const DepotSynchronizationLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query or find a set of depot synchronization logs.
         * @param {Array<number>} id The synchronization log IDs
         * @param {Array<string>} batchId The synchronization batch IDs
         * @param {Array<string>} accountId Retrieve logs for the given account ID(s)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAndSearchDepotSynchronizationLogs: async (id: Array<number>, batchId: Array<string>, accountId: Array<string>, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAndSearchDepotSynchronizationLogs', 'id', id)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('findAndSearchDepotSynchronizationLogs', 'batchId', batchId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('findAndSearchDepotSynchronizationLogs', 'accountId', accountId)
            const localVarPath = `/v1/depotSynchronizationLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (batchId) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attempt to rollback the depot synchronization log with the given key.
         * @param {number} id The synchronization log ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackDepotSynchronizationLog: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rollbackDepotSynchronizationLog', 'id', id)
            const localVarPath = `/v1/depotSynchronizationLogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the depot synchronization log with the given key.
         * @param {number} id The synchronization log ID
         * @param {Array<ShowDepotSynchronizationLogStatusEnum>} [status] Include log items with the given status; default is SUCCESS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDepotSynchronizationLog: async (id: number, status?: Array<ShowDepotSynchronizationLogStatusEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showDepotSynchronizationLog', 'id', id)
            const localVarPath = `/v1/depotSynchronizationLogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepotSynchronizationLogsApi - functional programming interface
 * @export
 */
export const DepotSynchronizationLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepotSynchronizationLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query or find a set of depot synchronization logs.
         * @param {Array<number>} id The synchronization log IDs
         * @param {Array<string>} batchId The synchronization batch IDs
         * @param {Array<string>} accountId Retrieve logs for the given account ID(s)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAndSearchDepotSynchronizationLogs(id: Array<number>, batchId: Array<string>, accountId: Array<string>, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableDepotSynchronizationLogList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAndSearchDepotSynchronizationLogs(id, batchId, accountId, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepotSynchronizationLogsApi.findAndSearchDepotSynchronizationLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Attempt to rollback the depot synchronization log with the given key.
         * @param {number} id The synchronization log ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollbackDepotSynchronizationLog(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rollbackDepotSynchronizationLog(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepotSynchronizationLogsApi.rollbackDepotSynchronizationLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the depot synchronization log with the given key.
         * @param {number} id The synchronization log ID
         * @param {Array<ShowDepotSynchronizationLogStatusEnum>} [status] Include log items with the given status; default is SUCCESS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showDepotSynchronizationLog(id: number, status?: Array<ShowDepotSynchronizationLogStatusEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotSynchronizationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showDepotSynchronizationLog(id, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepotSynchronizationLogsApi.showDepotSynchronizationLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepotSynchronizationLogsApi - factory interface
 * @export
 */
export const DepotSynchronizationLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepotSynchronizationLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Query or find a set of depot synchronization logs.
         * @param {DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAndSearchDepotSynchronizationLogs(requestParameters: DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableDepotSynchronizationLogList> {
            return localVarFp.findAndSearchDepotSynchronizationLogs(requestParameters.id, requestParameters.batchId, requestParameters.accountId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attempt to rollback the depot synchronization log with the given key.
         * @param {DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackDepotSynchronizationLog(requestParameters: DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rollbackDepotSynchronizationLog(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the depot synchronization log with the given key.
         * @param {DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDepotSynchronizationLog(requestParameters: DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepotSynchronizationLog> {
            return localVarFp.showDepotSynchronizationLog(requestParameters.id, requestParameters.status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAndSearchDepotSynchronizationLogs operation in DepotSynchronizationLogsApi.
 * @export
 * @interface DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest
 */
export interface DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest {
    /**
     * The synchronization log IDs
     * @type {Array<number>}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly id: Array<number>

    /**
     * The synchronization batch IDs
     * @type {Array<string>}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly batchId: Array<string>

    /**
     * Retrieve logs for the given account ID(s)
     * @type {Array<string>}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly accountId: Array<string>

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogs
     */
    readonly order?: string
}

/**
 * Request parameters for rollbackDepotSynchronizationLog operation in DepotSynchronizationLogsApi.
 * @export
 * @interface DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest
 */
export interface DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest {
    /**
     * The synchronization log ID
     * @type {number}
     * @memberof DepotSynchronizationLogsApiRollbackDepotSynchronizationLog
     */
    readonly id: number
}

/**
 * Request parameters for showDepotSynchronizationLog operation in DepotSynchronizationLogsApi.
 * @export
 * @interface DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest
 */
export interface DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest {
    /**
     * The synchronization log ID
     * @type {number}
     * @memberof DepotSynchronizationLogsApiShowDepotSynchronizationLog
     */
    readonly id: number

    /**
     * Include log items with the given status; default is SUCCESS
     * @type {Array<'SUCCESS' | 'FAILED'>}
     * @memberof DepotSynchronizationLogsApiShowDepotSynchronizationLog
     */
    readonly status?: Array<ShowDepotSynchronizationLogStatusEnum>
}

/**
 * DepotSynchronizationLogsApi - object-oriented interface
 * @export
 * @class DepotSynchronizationLogsApi
 * @extends {BaseAPI}
 */
export class DepotSynchronizationLogsApi extends BaseAPI {
    /**
     * 
     * @summary Query or find a set of depot synchronization logs.
     * @param {DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotSynchronizationLogsApi
     */
    public findAndSearchDepotSynchronizationLogs(requestParameters: DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest, options?: RawAxiosRequestConfig) {
        return DepotSynchronizationLogsApiFp(this.configuration).findAndSearchDepotSynchronizationLogs(requestParameters.id, requestParameters.batchId, requestParameters.accountId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attempt to rollback the depot synchronization log with the given key.
     * @param {DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotSynchronizationLogsApi
     */
    public rollbackDepotSynchronizationLog(requestParameters: DepotSynchronizationLogsApiRollbackDepotSynchronizationLogRequest, options?: RawAxiosRequestConfig) {
        return DepotSynchronizationLogsApiFp(this.configuration).rollbackDepotSynchronizationLog(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the depot synchronization log with the given key.
     * @param {DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotSynchronizationLogsApi
     */
    public showDepotSynchronizationLog(requestParameters: DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest, options?: RawAxiosRequestConfig) {
        return DepotSynchronizationLogsApiFp(this.configuration).showDepotSynchronizationLog(requestParameters.id, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ShowDepotSynchronizationLogStatusEnum = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
} as const;
export type ShowDepotSynchronizationLogStatusEnum = typeof ShowDepotSynchronizationLogStatusEnum[keyof typeof ShowDepotSynchronizationLogStatusEnum];
