import { UseQueryOptions } from "react-query";
import {
  UserPropertiesApiShowUserPropertyRequest,
  CustomProperty,
} from "@generated/apiv1";

import { buildUserPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showUserProperty = async (
  token: string | undefined,
  params: UserPropertiesApiShowUserPropertyRequest
) => {
  const apiInstance = buildUserPropertiesApi(token);
  const { data } = await apiInstance.showUserProperty(params);
  return data;
};

export const useShowUserProperty = (
  params: UserPropertiesApiShowUserPropertyRequest,
  options?: UseQueryOptions<CustomProperty, AxiosApiError, CustomProperty>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERPROPERTIES_SHOW_USER_PROPERTY, params],
    ({ token }) => showUserProperty(token, params),
    options
  );
};
