import { UseMutationOptions } from "react-query";
import { CommentsApiCreateCommentRequest, Comment } from "@generated/apiv1";

import { buildCommentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createComment = async (
  token: string | undefined,
  params: CommentsApiCreateCommentRequest
) => {
  const apiInstance = buildCommentsApi(token);
  const { data } = await apiInstance.createComment(params);
  return data;
};

export const useCreateComment = (
  options?: UseMutationOptions<
    Comment,
    AxiosApiError,
    CommentsApiCreateCommentRequest
  >
) => {
  return useAuthMutation(createComment, options, false);
};
