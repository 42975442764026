import { UseMutationOptions } from "react-query";
import {
  CategoriesV2ApiCreateCategoryV2Request,
  CategoryV2,
} from "@generated/apiv1";

import { buildCategoriesV2Api } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createCategoryV2 = async (
  token: string | undefined,
  params: CategoriesV2ApiCreateCategoryV2Request
) => {
  const apiInstance = buildCategoriesV2Api(token);
  const { data } = await apiInstance.createCategoryV2(params);
  return data;
};

export const useCreateCategoryV2 = (
  options?: UseMutationOptions<
    CategoryV2,
    AxiosApiError,
    CategoriesV2ApiCreateCategoryV2Request
  >
) => {
  return useAuthMutation(createCategoryV2, options, false);
};
