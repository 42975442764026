import React, {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useController, useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { RHFCheckbox, SearchField } from "@components";
import { useSearchSymbolV2 } from "@generated/apiv1/hooks";
import { SymbolV2 } from "@generated/apiv1";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { SecuritiesSearchResultsMobile } from "@features/securities/SecuritiesSearchResultsMobile";
import { SecuritiesSearchResultsDesktop } from "@features/securities/SecuritiesSearchResultsDesktop";

type FormFields = {
  search: string;
  all: boolean;
} & FilterFields;

type FilterFields = {
  "11_stock": boolean;
  "21_fund": boolean;
  "31_bond": boolean;
  "41_cash": boolean;
  "51_certos": boolean;
  "61_pmetals": boolean;
  "71_massets": boolean;
  "81_fcurr": boolean;
  "22_etf": boolean;
  "91_managed": boolean;
};

export type SymbolType = keyof FilterFields;

const searchFilters: SymbolType[] = [
  "11_stock",
  "21_fund",
  "31_bond",
  "41_cash",
  "51_certos",
  "61_pmetals",
  "71_massets",
  "81_fcurr",
  "22_etf",
  "91_managed",
];

type Props = {
  onSymbolSelect: (symbol: SymbolV2) => void;
  defaultSearchFilter?: SymbolType;
  defaultSearchValue?: string;
  selectedFilters?: SymbolType[];
  prepicks?: SymbolV2[];
  id?: string;
  filters?: ReactElement;
  placeholder?: string;
  searchHeight?: number;
  shouldCloseOnOuterClick?: boolean;
  showMobileVersion?: boolean;
};

export const SecuritiesSearch = forwardRef(
  (
    {
      onSymbolSelect,
      defaultSearchValue,
      defaultSearchFilter,
      selectedFilters,
      id,
      prepicks,
      placeholder,
      searchHeight,
      shouldCloseOnOuterClick,
      showMobileVersion,
      filters,
    }: Props,
    searchRef
  ) => {
    const { t } = useTranslation();
    const ref = useRef() as React.RefObject<HTMLInputElement>;
    const searchResultsRef = useRef() as React.RefObject<HTMLDivElement>;
    const [areSearchResultsOpen, setAreSearchResultsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchResultsHeight, setSearchResultsHeight] = useState(0);
    const [searchResults, setSearchResults] = useState<SymbolV2[]>([]);

    const { control, watch, setValue } = useForm<FormFields>({
      defaultValues: {
        search: defaultSearchValue ?? "",
        all: !defaultSearchFilter,
        ...searchFilters.reduce((acc, filter) => {
          acc[filter] =
            filter === defaultSearchFilter ||
            (selectedFilters?.includes(filter) ?? false);
          return acc;
        }, {} as Record<SymbolType, boolean>),
      },
    });

    const { field } = useController({ control, name: "search" });
    const values = watch();

    const type = useMemo(() => {
      return (
        selectedFilters ?? searchFilters.filter((filter) => values[filter])
      );
    }, [values, selectedFilters]);
    const [searchValue] = useDebounce(values.search, 300);

    const { isFetching } = useSearchSymbolV2(
      {
        search: searchValue,
        type,
      },
      {
        enabled: searchValue.length >= 3,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setSearchResults(data?.symbols ?? []);
        },
      }
    );

    const logos = useSecurityImages(
      searchResults?.map((symbol) => ({
        isin: symbol.isin,
        tickerSymbol: symbol.quoteProvider === "crypto" ? symbol.id : undefined,
        type: symbol.type,
        hasValidIsin: true,
        quoteProvider: symbol.quoteProvider,
      })) || []
    );

    useImperativeHandle(
      searchRef,
      () => ({
        openSearch: () => {
          setAreSearchResultsOpen(true);
        },
      }),
      [setAreSearchResultsOpen]
    );

    useEffect(() => {
      if (!searchValue && selectedFilters?.length) {
        setSearchResults(prepicks || []);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prepicks]);

    useEffect(() => {
      if (!selectedFilters) return;

      searchFilters?.forEach((filter) => {
        setValue(filter, selectedFilters.includes(filter));
      });
    }, [selectedFilters, setValue]);

    useEffect(() => {
      if (searchValue.length >= 3) {
        setAreSearchResultsOpen(true);
      } else {
        setAreSearchResultsOpen(false);
      }
    }, [searchValue]);

    useEffect(() => {
      setAnchorEl(ref?.current);
    }, [ref]);

    useEffect(() => {
      if (!searchResultsRef?.current) return;

      const resizeObserver = new ResizeObserver(() => {
        setSearchResultsHeight(searchResultsRef?.current?.offsetHeight || 0);
      });
      resizeObserver.observe(searchResultsRef.current);

      return () => resizeObserver.disconnect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResultsRef?.current]);

    const shouldShowNonDefaultFilters =
      filters && !(showMobileVersion && areSearchResultsOpen);

    return (
      <Box
        width="100%"
        height="100%"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <SearchField
          placeholder={placeholder || t("securitiesSearch.placeholder")}
          fullWidth
          id={id}
          sx={{
            width: "100%",
            marginBottom: (theme) => theme.spacing(5),
          }}
          value={field.value}
          onChange={field.onChange}
          name="securities-search"
          ref={ref}
          autoFocus
          displayClearButton={!!field.value}
          onClear={() => {
            setAreSearchResultsOpen(false);
            field.onChange("");
          }}
          onClick={() => (searchResults ? setAreSearchResultsOpen(true) : null)}
        />
        {shouldShowNonDefaultFilters && filters}
        {!filters && (
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            rowGap={2}
            mt={searchResultsHeight / 4}
          >
            <RHFCheckbox
              name="all"
              key="all"
              label={t("securitiesSearch.all")}
              control={control}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!event.target.checked) return;

                const someChecked = searchFilters.some(
                  (filter) => values[filter]
                );
                if (someChecked) {
                  searchFilters.forEach((filter) => {
                    setValue(filter, false, { shouldValidate: true });
                  });
                  setValue("all", true, { shouldValidate: true });
                }
              }}
            />
            {searchFilters.map((filter) => (
              <RHFCheckbox
                name={filter}
                key={filter}
                label={t(`investmentTypeClassification.types.${filter}`)}
                control={control}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const someChecked = searchFilters.some(
                    (filter) => filter !== event.target.name && values[filter]
                  );
                  if (someChecked) {
                    setValue(
                      event.target.name as keyof FilterFields,
                      event.target.checked,
                      { shouldValidate: true }
                    );
                  } else {
                    setValue("all", !event.target.checked, {
                      shouldValidate: true,
                    });
                    setValue(
                      event.target.name as keyof FilterFields,
                      event.target.checked,
                      {
                        shouldValidate: true,
                      }
                    );
                  }
                }}
              />
            ))}
          </Box>
        )}

        {showMobileVersion && areSearchResultsOpen && (
          <SecuritiesSearchResultsMobile
            symbols={searchResults}
            isLoading={isFetching}
            onSymbolSelect={onSymbolSelect}
            shouldShowExpand={searchValue.length >= 3}
            logos={logos}
          />
        )}

        {anchorEl && !showMobileVersion && (
          <SecuritiesSearchResultsDesktop
            isLoading={isFetching}
            onSymbolSelect={onSymbolSelect}
            ref={searchResultsRef}
            anchorEl={anchorEl}
            isOpen={areSearchResultsOpen}
            symbols={searchResults}
            shouldShowExpand={searchValue.length >= 3}
            logos={logos}
            searchHeight={searchHeight}
            setSearchResultsHeight={setSearchResultsHeight}
            onOuterClick={
              shouldCloseOnOuterClick
                ? (event) => {
                    setAreSearchResultsOpen(
                      // @ts-ignore
                      event.target?.getAttribute("name") === "securities-search"
                    );
                  }
                : undefined
            }
          />
        )}
      </Box>
    );
  }
);
