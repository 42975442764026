/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CustomData } from './custom-data';

/**
 * 
 * @export
 * @interface CreateOrUpdateSubscriptionParams
 */
export interface CreateOrUpdateSubscriptionParams {
    /**
     * The account holder this subscription belongs to; required for cashback subscriptions.                                Once created, an account holder can only be changed by a subscription administrator.
     * @type {number}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'accountHolderId'?: number;
    /**
     * The subscription type; required for creation. The type cannot be changed afterwards.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'type'?: CreateOrUpdateSubscriptionParamsTypeEnum;
    /**
     * The subscription start date; required for creation
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'start'?: string;
    /**
     * The subscription end date; not relevant for cashback subscriptions
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'end'?: string;
    /**
     * The type of onboarding being used; default is INTERNAL.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'onboardingType'?: CreateOrUpdateSubscriptionParamsOnboardingTypeEnum;
    /**
     * Is the subscription active? This is relevant for subscriptions the get extended;                                not for cashback.
     * @type {boolean}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'isActive'?: boolean;
    /**
     * Has the subscription been cancelled?
     * @type {boolean}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'isCancelled'?: boolean;
    /**
     * Is this a trial subscription?
     * @type {boolean}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'isTrial'?: boolean;
    /**
     * The subscription status; will default to \'incomplete\' if not given at creation time.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'status'?: CreateOrUpdateSubscriptionParamsStatusEnum;
    /**
     * An optional, custom tag useful for custom subscription processing
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'tag'?: string;
    /**
     * A discount code or user recommendation code.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'discountCode'?: string;
    /**
     * Does the subscription belong to a certain bank? Relevant for cashback only.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'bic'?: string;
    /**
     * A reason, e.g. for cancellation. May be given by business or the customer;                                send blank or an empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'reason'?: string;
    /**
     * A user comment (longtext); send blank or an empty string to clear.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'comment'?: string;
    /**
     * The primary account or depot identifier. Relevant for correct billing; required for                                cashback only.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'primaryAccountIdentifier'?: string;
    /**
     * The secondary account or depot identifier. May be relevant for correct billing; required for                                a few banks within the cashback product only.
     * @type {string}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'additionalAccountIdentifier'?: string;
    /**
     * 
     * @type {CustomData}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'customData'?: CustomData;
    /**
     * Data for storing and restoring the subscription wizard page; as key value pair.
     * @type {{ [key: string]: object; }}
     * @memberof CreateOrUpdateSubscriptionParams
     */
    'wizardData'?: { [key: string]: object; };
}

export const CreateOrUpdateSubscriptionParamsTypeEnum = {
    _02_BASIC_FLATRATE: '02_basic_flatrate',
    _03_FUND_FLATRATE: '03_fund_flatrate'
} as const;

export type CreateOrUpdateSubscriptionParamsTypeEnum = typeof CreateOrUpdateSubscriptionParamsTypeEnum[keyof typeof CreateOrUpdateSubscriptionParamsTypeEnum];
export const CreateOrUpdateSubscriptionParamsOnboardingTypeEnum = {
    INTERNAL: 'internal',
    EXTERNAL: 'external'
} as const;

export type CreateOrUpdateSubscriptionParamsOnboardingTypeEnum = typeof CreateOrUpdateSubscriptionParamsOnboardingTypeEnum[keyof typeof CreateOrUpdateSubscriptionParamsOnboardingTypeEnum];
export const CreateOrUpdateSubscriptionParamsStatusEnum = {
    INCOMPLETE: 'incomplete',
    IN_PROGRESS: 'in_progress',
    INTERNAL_PROCESSING: 'internal_processing',
    PROCESSING_AT_BANK: 'processing_at_bank',
    PROCESSED: 'processed',
    CONTRACT_TERMINATED: 'contract_terminated',
    CANCELLED: 'cancelled'
} as const;

export type CreateOrUpdateSubscriptionParamsStatusEnum = typeof CreateOrUpdateSubscriptionParamsStatusEnum[keyof typeof CreateOrUpdateSubscriptionParamsStatusEnum];


