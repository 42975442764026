import { withToken } from "@api/auth/withToken";
import { axiosInstanceV1 } from "@api/axiosInstance";
import { accountsId } from "@generated/apiv1/hooks";
import {
  GetAccountParams,
  Account,
  CreateOrUpdateAccountParams,
  AccountList,
  AssetCategorization,
  GetAccountsCategorizeParams,
} from "../model";

export const getAccount = async (token: string | undefined, id: number) => {
  const { data } = await axiosInstanceV1.get<Account>(`/accounts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getAccountsCategorize = async (
  token: string | undefined,
  params?: GetAccountsCategorizeParams
) => {
  const { data } = await axiosInstanceV1.get<AssetCategorization>(
    `/accounts/categorize`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );
  return data;
};

export const getAccounts = async (
  token: string | undefined,
  params: GetAccountParams
) => {
  const { data } = await axiosInstanceV1.get<AccountList>(`/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params,
  });
  return data;
};

export const getAccountsWithTppWebForm = async (
  tppWebFormToken: string,
  params: GetAccountParams
) => {
  const { data } = await axiosInstanceV1.get<AccountList>(
    `/accounts/tppWebForm/${tppWebFormToken}`,
    {
      params: params,
    }
  );
  return data;
};

export const createAccount = async (
  token: string | undefined,
  params: CreateOrUpdateAccountParams
) => {
  const { data } = await axiosInstanceV1.post<Account>(`/accounts`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const assignContraAccount = async (
  token: string | undefined,
  {
    id,
    contraAccountId,
  }: {
    id: number;
    contraAccountId: number;
  }
) => {
  const { data } = await axiosInstanceV1.put(
    `/accounts/${id}/assignContraAccount/${contraAccountId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const transferDepot = async (
  token: string | undefined,
  {
    transferFromAccountId,
    ...attrs
  }: {
    transferFromAccountId: number;
    transferToAccountId: number;
    dateOfTransfer: string;
    transferFullLotsOnly?: boolean;
  }
) => {
  const { data } = await axiosInstanceV1.put(
    `/accounts/${transferFromAccountId}/transfer`,
    attrs,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const updateAccount = async (
  token: string | undefined,
  { id, ...attrs }: CreateOrUpdateAccountParams & { id: number }
) => {
  const { data } = await axiosInstanceV1.put<Account>(
    `/accounts/${id}`,
    { ...attrs },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const deleteAccount = async (token: string | undefined, id: number) => {
  const { data } = await axiosInstanceV1.delete<Account>(`/accounts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const purgeAccount = async (token: string | undefined, id: string) => {
  const { data } = await axiosInstanceV1.delete<Account>(
    `/accounts/${id}/purge`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getAccountsWithToken = withToken(getAccounts);

export const createAccountWithToken = withToken(createAccount);

export const updateAccountWithToken = withToken(updateAccount);

export const deleteAccountWithToken = withToken(deleteAccount);

export const accountsIdWithToken = withToken(accountsId);
