import { Theme } from "@emotion/react";
import React, { RefObject } from "react";
import { useChartColors } from "@hooks";

const lineColor = (index: number, theme: Theme, lineColors: string[]) => {
  const newIndex = (index + 1) % lineColors.length;
  return lineColors[newIndex];
};

interface Props {
  lineDrawingEnabled?: boolean;
  lines: { x1: number; y1: number; x2: number; y2: number }[];
  theme: Theme;
  lineRef: RefObject<SVGLineElement>;
  // eslint-disable-next-line prettier/prettier
  chartColors: ReturnType<typeof useChartColors<"securitiesChart">>;
}

export const DrawableLines = ({
  lineDrawingEnabled,
  lines,
  theme,
  lineRef,
  chartColors,
}: Props) => {
  if (!lineDrawingEnabled) return null;

  const allLines = lines?.map(({ x1, y1, x2, y2 }, index) => (
    <line
      key={`line-${index}`}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      strokeLinecap="round"
      strokeWidth={2}
      stroke={
        lineColor(index, theme, chartColors.drawableLines) ||
        theme.palette.graphVisual["08"].dark
      }
    />
  ));

  const activeLine = (
    <line
      key="active-line"
      ref={lineRef}
      strokeLinecap="round"
      strokeWidth={2}
      stroke={theme.palette.common.black}
    />
  );

  return [...allLines, activeLine];
};
