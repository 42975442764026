import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { ReactComponent as SuccessIcon } from "@icons/myicons/success-lock.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SecuritySuccessModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <SettingsModalWrapper
      successTitle={t("settingsPage.security.successTitle")}
      successSubtitle="settingsPage.security.successSubtitle"
      successButton="settingsPage.security.successButton"
      successIcon={<SuccessIcon />}
      showSuccess={true}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onClose}
    />
  );
};
