import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorIcon } from "@icons/error.svg";
import { ReactComponent as SuccessIcon } from "@icons/success.svg";
import { Banner } from "@components";

interface AddManuallyMessageProps {
  type?: "success" | "error";
  message?: string;
}

export const CreateAndUpdateResultMessage = ({
  message,
  type,
}: AddManuallyMessageProps) => {
  const { t } = useTranslation();

  if (type === "error") {
    return (
      <Banner
        data-testid="result-alert-error"
        type="error"
        icon={<ErrorIcon width="30px" height="30px" />}
        text={message}
        mb={2}
      />
    );
  }

  if (type === "success") {
    return (
      <Banner
        data-testid="result-alert-success"
        icon={<SuccessIcon />}
        type="success"
        mb={2}
        text={t("addManuallyPage.form.messages.success")}
      />
    );
  }

  return null;
};
