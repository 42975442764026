/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InsuranceCategory } from '../model';
// @ts-ignore
import { PageableInsuranceCategoryList } from '../model';
/**
 * InsuranceCategoriesApi - axios parameter creator
 * @export
 */
export const InsuranceCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get an Insurance Category
         * @param {number} id ID of the insurance category to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceCategoriesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insuranceCategoriesId', 'id', id)
            const localVarPath = `/v1/insuranceCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Insurance categories
         * @param {Array<number>} [id] Retrieve Insurance categories with the given IDs
         * @param {Array<string>} [type] Retrieve Insurance categories matching the given types; allowed values are                              category, subCategory.
         * @param {string} [name] Perform a wildcard search on the category name
         * @param {Array<number>} [parentId] Retrieve sub categories for the given parent IDs
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInsuranceCategories: async (id?: Array<number>, type?: Array<string>, name?: string, parentId?: Array<number>, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/insuranceCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (parentId) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceCategoriesApi - functional programming interface
 * @export
 */
export const InsuranceCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get an Insurance Category
         * @param {number} id ID of the insurance category to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceCategoriesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceCategoriesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceCategoriesApi.insuranceCategoriesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Insurance categories
         * @param {Array<number>} [id] Retrieve Insurance categories with the given IDs
         * @param {Array<string>} [type] Retrieve Insurance categories matching the given types; allowed values are                              category, subCategory.
         * @param {string} [name] Perform a wildcard search on the category name
         * @param {Array<number>} [parentId] Retrieve sub categories for the given parent IDs
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllInsuranceCategories(id?: Array<number>, type?: Array<string>, name?: string, parentId?: Array<number>, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInsuranceCategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllInsuranceCategories(id, type, name, parentId, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InsuranceCategoriesApi.listAndSearchAllInsuranceCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InsuranceCategoriesApi - factory interface
 * @export
 */
export const InsuranceCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get an Insurance Category
         * @param {InsuranceCategoriesApiInsuranceCategoriesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceCategoriesId(requestParameters: InsuranceCategoriesApiInsuranceCategoriesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<InsuranceCategory> {
            return localVarFp.insuranceCategoriesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Insurance categories
         * @param {InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInsuranceCategories(requestParameters: InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableInsuranceCategoryList> {
            return localVarFp.listAndSearchAllInsuranceCategories(requestParameters.id, requestParameters.type, requestParameters.name, requestParameters.parentId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for insuranceCategoriesId operation in InsuranceCategoriesApi.
 * @export
 * @interface InsuranceCategoriesApiInsuranceCategoriesIdRequest
 */
export interface InsuranceCategoriesApiInsuranceCategoriesIdRequest {
    /**
     * ID of the insurance category to retrieve
     * @type {number}
     * @memberof InsuranceCategoriesApiInsuranceCategoriesId
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllInsuranceCategories operation in InsuranceCategoriesApi.
 * @export
 * @interface InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest
 */
export interface InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest {
    /**
     * Retrieve Insurance categories with the given IDs
     * @type {Array<number>}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly id?: Array<number>

    /**
     * Retrieve Insurance categories matching the given types; allowed values are                              category, subCategory.
     * @type {Array<string>}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly type?: Array<string>

    /**
     * Perform a wildcard search on the category name
     * @type {string}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly name?: string

    /**
     * Retrieve sub categories for the given parent IDs
     * @type {Array<number>}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly parentId?: Array<number>

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof InsuranceCategoriesApiListAndSearchAllInsuranceCategories
     */
    readonly order?: string
}

/**
 * InsuranceCategoriesApi - object-oriented interface
 * @export
 * @class InsuranceCategoriesApi
 * @extends {BaseAPI}
 */
export class InsuranceCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Get an Insurance Category
     * @param {InsuranceCategoriesApiInsuranceCategoriesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceCategoriesApi
     */
    public insuranceCategoriesId(requestParameters: InsuranceCategoriesApiInsuranceCategoriesIdRequest, options?: RawAxiosRequestConfig) {
        return InsuranceCategoriesApiFp(this.configuration).insuranceCategoriesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Insurance categories
     * @param {InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceCategoriesApi
     */
    public listAndSearchAllInsuranceCategories(requestParameters: InsuranceCategoriesApiListAndSearchAllInsuranceCategoriesRequest = {}, options?: RawAxiosRequestConfig) {
        return InsuranceCategoriesApiFp(this.configuration).listAndSearchAllInsuranceCategories(requestParameters.id, requestParameters.type, requestParameters.name, requestParameters.parentId, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }
}

