import React from "react";
import styled from "@emotion/styled";
import { SecurityMetricValue } from "@features/fundamentals/components/SecurityMetrics";
import { AddMenu } from "./AddMenu";

const Container = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: "flex",
  justifyContent: "flex-end",
}));

interface Props {
  menuItems?: { key: string; label: string }[];
  onSelect?: (key: string) => void;
  selectedMetrics?: SecurityMetricValue[];
}

export const Header = ({
  menuItems,
  onSelect,
  selectedMetrics = [],
}: Props) => {
  return (
    <Container>
      {menuItems && menuItems.length > 0 && (
        <AddMenu
          items={menuItems}
          onSelect={onSelect}
          selected={selectedMetrics.map((metric) => metric.mainMetricKey)}
        />
      )}
    </Container>
  );
};
