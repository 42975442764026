import React, { useEffect } from "react";
import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { ReactComponent as FilterIcon } from "@icons/filter.svg";
import { ReactComponent as LockIcon } from "@icons/lock.svg";
import { Modal, Tag, TypographyWithInfoIcon } from "@components";
import { useDividendFilters } from "@features/dividendPlanner/components/Filters/useDividendFilters";
import {
  InvestmentSelect,
  isNewInvestmentOption,
} from "@components/common/forms/InvestmentSelect";
import {
  Filter,
  useDividendsContext,
} from "@features/dividendPlanner/DividendsProvider";
import { InvestmentReference, InvestmentTypeEnum } from "@generated/apiv1";
import { MultipleSelect } from "@components/common/forms/MultipleSelect";
import { PlusGradient } from "@components/Plus/PlusGradient";

const redDotStyles = (theme: Theme) => ({
  "&::after": {
    display: "block",
    content: "''",
    position: "absolute",
    right: 2,
    top: 6,
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.text.negative,
    marginRight: theme.spacing(1),
  },
});

const defaultFilter = {
  investmentIsins: [],
  investmentTypes: [],
  includePrediction: true,
  sortByPaymentDate: true,
};

export const FilterButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const {
    filter: appliedFilter,
    setFilter: applyFilter,
    investmentsForFilters,
    areFiltersApplied,
    isUserPro,
  } = useDividendsContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [filter, setFilter] = React.useState<Filter>(appliedFilter);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { investmentIsins, investmentTypes } = useDividendFilters(filter);

  const selectableInvestments = Object.values(
    investmentsForFilters || {}
  )?.filter((investment) => investmentIsins.includes(investment.id));

  useEffect(() => {
    if (!isEqual(appliedFilter, filter)) setFilter(appliedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<FilterIcon />}
        onClick={() => setIsOpen(true)}
        fullWidth={isLessSm}
        sx={(theme) => ({
          fontSize: "16px",
          position: "relative",
          padding: theme.spacing(2.5, 4),
          ...(areFiltersApplied && redDotStyles(theme)),
        })}
        {...props}
      >
        {t("dividends.filters.buttonTitle")}
      </Button>
      <Modal
        isOpen={isOpen}
        height="auto"
        position={isLessSm ? "bottom" : "centered"}
        onClose={() => setIsOpen(false)}
        title={t("dividends.filters.title")}
        titleAlign="center"
      >
        <Box display="flex" flexDirection="column" gap={6}>
          {!isUserPro && <PlusGradient variant="large" />}
          <FormControl
            sx={{
              marginBottom: (theme) => theme.spacing(4),
            }}
          >
            <Box
              mb={3}
              display="flex"
              gap={2}
              alignItems="center"
              color={isUserPro ? "text.primary" : "text.secondary"}
              sx={{
                "& svg": {
                  flexShrink: 0,
                },
              }}
            >
              {!isUserPro && <LockIcon />}
              <Typography variant="h5" fontWeight={700}>
                {t(`dividends.filters.assetClass`)}
              </Typography>
            </Box>
            <MultipleSelect<InvestmentTypeEnum>
              value={filter.investmentTypes}
              onChange={(value) => {
                setFilter((prev) => ({
                  ...prev,
                  investmentTypes: value,
                }));
              }}
              fullWidth
              disabled={!isUserPro}
              options={investmentTypes}
              selectedOptions={filter.investmentTypes}
              noOptionsText={t("bookingsTable.filters.assets")}
              MenuProps={{
                disablePortal: true,
              }}
              getOptionsText={(selected) => {
                return selected.length > 1
                  ? t("bookingsTable.filters.assetsSelected", {
                      count: selected.length,
                    })
                  : t(`investmentTypes.${selected[0]}_plural`);
              }}
            />
          </FormControl>
          <FormControl>
            <Box
              mb={3}
              display="flex"
              alignItems="center"
              gap={2}
              color={isUserPro ? "text.primary" : "text.secondary"}
              sx={{
                "& svg": {
                  flexShrink: 0,
                },
              }}
            >
              {!isUserPro && <LockIcon />}
              <Typography variant="h5" fontWeight={700}>
                {t(`dividends.filters.investments`)}
              </Typography>
            </Box>
            <InvestmentSelect<InvestmentReference>
              onChange={(investment) => {
                if (isNewInvestmentOption(investment)) return;

                setFilter((prev) => ({
                  ...prev,
                  investmentIsins: investment?.isin
                    ? [...prev.investmentIsins, investment?.isin]
                    : [],
                }));
              }}
              disabled={!isUserPro}
              selectableInvestments={selectableInvestments}
              displaySelectedInvestment={false}
              placeholder={t(`dividends.filters.investments`)}
              onlyUnique
              ListboxProps={{
                style: {
                  maxHeight: "250px",
                },
              }}
              includeHistoric
              noLabel
            />
            {Boolean(filter.investmentIsins?.length) && (
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                {filter.investmentIsins.map((isin) => {
                  const investment = Object.values(
                    investmentsForFilters || {}
                  ).find((inv) => inv.isin === isin);
                  if (!investment) return null;

                  return (
                    <Tag
                      key={investment.id}
                      text={investment.standardisedName}
                      variant="default"
                      color="secondary"
                      onTagRemove={() => {
                        setFilter((prev) => ({
                          ...prev,
                          investmentIsins: prev.investmentIsins.filter(
                            (investmentIsin) => investmentIsin !== isin
                          ),
                        }));
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </FormControl>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={filter.includePrediction}
                color="primary"
                disabled={!isUserPro}
                onChange={(event, checked) =>
                  setFilter((prev) => ({
                    ...prev,
                    includePrediction: checked,
                  }))
                }
                size="medium"
              />
            }
            label={
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                color={isUserPro ? "text.primary" : "text.secondary"}
                sx={{
                  "& svg": {
                    flexShrink: 0,
                  },
                }}
              >
                {!isUserPro && <LockIcon />}
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="textSecondary"
                  sx={{
                    marginRight: (theme) => theme.spacing(2),
                  }}
                >
                  {t("dividends.filters.displayPrediction")}
                </Typography>
              </Box>
            }
            sx={{
              marginLeft: (theme) => theme.spacing(0),
              justifyContent: "flex-end",
            }}
          />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={filter.sortByPaymentDate}
                color="primary"
                disabled={!isUserPro}
                onChange={(event, checked) =>
                  setFilter((prev) => ({
                    ...prev,
                    sortByPaymentDate: checked,
                  }))
                }
                size="medium"
              />
            }
            label={
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                color={isUserPro ? "text.primary" : "text.secondary"}
                sx={{
                  "& svg": {
                    flexShrink: 0,
                  },
                }}
              >
                {!isUserPro && <LockIcon />}
                <TypographyWithInfoIcon
                  tooltipText={t("dividends.filters.sortTooltip")}
                  iconSize={16}
                  variant="body1"
                  fontWeight={500}
                  color="textSecondary"
                  iconProps={{
                    marginRight: (theme) => theme.spacing(2),
                  }}
                >
                  {t("dividends.filters.sort")}
                </TypographyWithInfoIcon>
              </Box>
            }
            sx={{
              marginLeft: (theme) => theme.spacing(0),
              justifyContent: "flex-end",
            }}
          />
          <Box display="flex" flexDirection="column" gap={4} mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                applyFilter(filter);
                setIsOpen(false);
              }}
              disabled={!isUserPro}
            >
              {t("dividends.filters.buttonTitle")}
            </Button>
            <Button
              variant="text"
              color="primary"
              fullWidth
              disabled={isEqual(filter, defaultFilter)}
              onClick={() => {
                setFilter(defaultFilter);
              }}
            >
              {t("dividends.filters.rollback")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
