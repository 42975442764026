import React, { forwardRef } from "react";
import { Box, MenuItem, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Account } from "@api";
import { ReactComponent as UpdateIcon } from "@icons/refresh.svg";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as ManageBankConnectionIcon } from "@icons/building-columns-solid.svg";
import { ReactComponent as HideIcon } from "@icons/eye-cross.svg";
import { ReactComponent as UnhideIcon } from "@icons/eye.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

interface Props {
  account: Account;
  handleOpenDeleteModal: () => void;
  handleHideAccount: () => void;
}

export const SingleAccountMenu = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const { account, handleOpenDeleteModal, handleHideAccount } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const shouldDisplayUpdateButton =
      Boolean(account?.bankConnectionType) &&
      account?.bankConnectionType !== "NETFONDS";

    return (
      <div ref={ref}>
        {shouldDisplayUpdateButton && (
          <MenuItem
            data-testid="account-update"
            component={Link}
            to={`/import-bank-connection/redirect?bankConnectionId=${account?.bankConnectionId}&multibankingApiProvider=${account?.bankConnectionType}`}
          >
            <Box display="flex" alignItems="center" gap={3}>
              <UpdateIcon
                width="24px"
                height="24px"
                color={theme.palette.icon.primary}
              />

              {t("singleAccountPage.header.update")}
            </Box>
          </MenuItem>
        )}
        <MenuItem
          data-testid="edit"
          component={Link}
          to={`/accounts/${account?.id}/edit`}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <EditIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />

            {t("singleAccountPage.header.edit")}
          </Box>
        </MenuItem>
        {Boolean(account?.bankConnectionType) && (
          <MenuItem
            data-testid="manage-bank-connection"
            component={Link}
            to={`/settings/connections?type=${account?.bankConnectionType}&id=${account?.bankConnectionId}`}
          >
            <Box display="flex" alignItems="center" gap={3}>
              <ManageBankConnectionIcon
                width="24px"
                height="24px"
                color={theme.palette.icon.primary}
              />
              {t("singleAccountPage.header.manageBankConnection")}
            </Box>
          </MenuItem>
        )}
        <MenuItem
          onClick={handleHideAccount}
          data-testid={account?.isHidden ? "unhide-account" : "hide-account"}
        >
          <Box display="flex" alignItems="center" gap={3}>
            {account?.isHidden ? (
              <UnhideIcon
                width="24px"
                height="24px"
                color={theme.palette.icon.primary}
              />
            ) : (
              <HideIcon
                width="24px"
                height="24px"
                color={theme.palette.icon.primary}
              />
            )}

            {account?.isHidden
              ? t("singleAccountPage.header.show")
              : t("singleAccountPage.header.hide")}
          </Box>
        </MenuItem>
        <MenuItem
          data-testid="delete-account-item"
          onClick={handleOpenDeleteModal}
        >
          <Box display="flex" alignItems="center" color="error.main" gap={3}>
            <DeleteIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.error}
            />

            {t("singleAccountPage.header.delete")}
          </Box>
        </MenuItem>
      </div>
    );
  }
);
