import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from "./saveToStorage";

export const readFromLocalStorage = (): Token | undefined => {
  const tokenString = localStorage.getItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY);
  if (tokenString) {
    const token = JSON.parse(tokenString);
    return {
      ...token,
      expires_at: new Date(token.expires_at),
      auto_sign_out_at: token.auto_sign_out_at
        ? new Date(token.auto_sign_out_at)
        : undefined,
    };
  }
  return;
};

export const readFromSessionStorage = (): Token | undefined => {
  const tokenString = sessionStorage.getItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY);
  if (tokenString) {
    const token = JSON.parse(tokenString);
    return {
      ...token,
      expires_at: new Date(token.expires_at),
      auto_sign_out_at: token.auto_sign_out_at
        ? new Date(token.auto_sign_out_at)
        : undefined,
    };
  }
  return;
};
