import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, useTheme } from "@mui/material";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

interface HeaderMenuProps {
  onWatchlistEdit: () => void;
  onWatchlistDelete: () => void;
  handleCloseMenu?: () => void;
}

export const HeaderMenu = forwardRef<HTMLDivElement, HeaderMenuProps>(
  (
    { onWatchlistEdit, onWatchlistDelete, handleCloseMenu }: HeaderMenuProps,
    ref
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <div ref={ref}>
        <MenuItem
          data-testid="edit-watchlist-menu-item"
          onClick={() => {
            onWatchlistEdit();
            handleCloseMenu?.();
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <EditIcon color={theme.palette.icon.primary} />
            {t("watchlistSinglePage.header.actions.editWatchlist")}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onWatchlistDelete();
            handleCloseMenu?.();
          }}
          data-testid="delete-watchlist-menu-item"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={3}
            color={theme.palette.icon.error}
          >
            <DeleteIcon color={theme.palette.icon.error} />
            {t("watchlistSinglePage.header.actions.deleteWatchlist")}
          </Box>
        </MenuItem>
      </div>
    );
  }
);
