import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import { useDebounce } from "use-debounce";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRoutes } from "@features/bankSelect/useRoutes";
import { MultibankingApiProvider, UserAuthorization } from "@api";
import { groupBankByName, LoadingProgressBar, PageLayout } from "@components";
import { BankSelect } from "@components/BankSelect";
import { useGetMyAuthorization } from "@api/v1/hooks/useAuthorization";
import { useFindBanks } from "@api/v1/hooks/useFindBanks";

const hasFinApiAccount = (authorization: UserAuthorization) =>
  authorization.thirdPartyAccounts?.includes("FINAPI");

export const BankSelectWithRedirectPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { pushLegalNote, pushImportConnection, pushSelectBank } = useRoutes();
  const {
    data: authorization,
    isLoading: isAuthorizationLoading,
    isFetched,
  } = useGetMyAuthorization();

  const handleSearchChange = (value: string) => {
    pushSelectBank(value);
  };
  const defaultSearchValue = searchParams.get("q") as string;

  const [searchValue, setSearchValue] = useState<string>(
    defaultSearchValue || ""
  );
  const handleSearchValueChange = (val: string) => {
    setSearchValue(val);
    handleSearchChange(val);
  };

  const handleSelect = (
    id: number,
    multibankingProvider: MultibankingApiProvider,
    wealthApiSlug?: string
  ) => {
    if (!authorization) return;
    hasFinApiAccount(authorization)
      ? pushImportConnection(id, multibankingProvider, wealthApiSlug)
      : pushLegalNote(id, multibankingProvider, wealthApiSlug);
  };

  const [value] = useDebounce(searchValue, 300);

  const valueWithoutSpaces =
    value && validator.isIBAN(value) ? value?.replace(/\s/g, "") : value;

  const { data, isFetching, isIdle } = useFindBanks(
    valueWithoutSpaces || defaultSearchValue,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const banks = groupBankByName(data?.banks);
  if (isAuthorizationLoading || !isFetched) {
    return <LoadingProgressBar />;
  }
  return (
    <PageLayout
      layout="centered"
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        {
          text: t("sidebar.portfoliosAndAccounts.main"),
          to: "/depots-accounts",
        },
        {
          text: t("sidebar.automaticImport"),
        },
      ]}
    >
      <Box pb={20} width="100%">
        <BankSelect
          onSelect={handleSelect}
          onSearchChange={handleSearchChange}
          banks={banks}
          isLoading={isFetching}
          isIdle={isIdle}
          searchValue={searchValue}
          onChange={handleSearchValueChange}
        />
      </Box>
    </PageLayout>
  );
};
