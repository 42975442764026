import { UseMutationOptions } from "react-query";
import { AccountHoldersApiDeleteAccountHolderRequest } from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteAccountHolder = async (
  token: string | undefined,
  params: AccountHoldersApiDeleteAccountHolderRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.deleteAccountHolder(params);
  return data;
};

export const useDeleteAccountHolder = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    AccountHoldersApiDeleteAccountHolderRequest
  >
) => {
  return useAuthMutation(deleteAccountHolder, options, false);
};
