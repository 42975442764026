import { AssetCard } from "@features/investments/components/Assets/AssetTypeCard";
import {
  InvestmentTypeEnum,
  ListAndSearchAllBookingsInvestmentTypeEnum,
} from "@generated/apiv1";

const TypeMapEnum = {
  shares: InvestmentTypeEnum._11_STOCK,
  funds: InvestmentTypeEnum._21_FUND,
  etfs: InvestmentTypeEnum._22_ETF,
  bonds: InvestmentTypeEnum._31_BOND,
  currency: InvestmentTypeEnum._81_FCURR,
  materialAssets: InvestmentTypeEnum._71_MASSETS,
  metals: InvestmentTypeEnum._61_PMETALS,
};

export const assetCardsToInvestmentType = (
  assetCards: AssetCard[]
): ListAndSearchAllBookingsInvestmentTypeEnum[] => {
  return assetCards.map((assetCard) => TypeMapEnum[assetCard]);
};

export const investmentTypeToAssetCard = (
  investmentTypes: InvestmentTypeEnum[]
): AssetCard[] => {
  const entries = Object.entries(TypeMapEnum);

  return investmentTypes
    .map((type) => entries.find(([key, value]) => value === type)?.[0])
    .filter(Boolean) as AssetCard[];
};
