import { UseMutationOptions } from "react-query";
import {
  PerformanceApiPublicPortfolioPerformanceRequest,
  PortfolioPerformanceResultList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const publicPortfolioPerformance = async (
  token: string | undefined,
  params: PerformanceApiPublicPortfolioPerformanceRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.publicPortfolioPerformance(params);
  return data;
};

export const usePublicPortfolioPerformance = (
  options?: UseMutationOptions<
    PortfolioPerformanceResultList,
    AxiosApiError,
    PerformanceApiPublicPortfolioPerformanceRequest
  >
) => {
  return useAuthMutation(publicPortfolioPerformance, options, false);
};
