import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowCircleIcon } from "@icons/arrow-circle.svg";
import { Tooltip } from "@components";

type Props = {
  update: "manual" | "automatic";
};

export const UpdateTag = ({ update }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const color =
    update === "manual"
      ? theme.palette.primary.main
      : theme.palette.text.positive;

  return (
    <Tooltip
      enterDelay={400}
      enterTouchDelay={400}
      enterNextDelay={400}
      text={
        update === "manual"
          ? t("bankSelect.manualUpdateTooltip")
          : t("bankSelect.automaticUpdateTooltip")
      }
    >
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        py={0.5}
        pr={1.5}
        pl={2}
        sx={{
          borderRadius: "12px",
          color,
          backgroundColor:
            update === "manual" ? "background.secondary" : "background.success",
        }}
        width="fit-content"
      >
        <ArrowCircleIcon width="10px" height="10px" />
        <Typography
          fontSize="11px"
          lineHeight="16px"
          fontWeight={500}
          color={color}
          noWrap
        >
          {update === "manual"
            ? t("bankSelect.manualUpdate")
            : t("bankSelect.automaticUpdate")}
        </Typography>
      </Box>
    </Tooltip>
  );
};
