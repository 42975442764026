import React from "react";
import { Box, BoxProps, Theme, useMediaQuery } from "@mui/material";
import FinanzflussCompactLogo from "./images/finanzfluss-compact-logo.svg";
import FinanzflussLogoWhite from "./images/finanzfluss-logo-white.svg";
import FinanzflussAssetLogo from "./images/fallback-asset-logo-finanzfluss.svg";

export type LogoVariant =
  | "default"
  | "compact"
  | "bankConnection"
  | "sidebar"
  | "footer"
  | "asset";

type LogoVariantProps = {
  icon: string;
  width: number | string;
  height: number | string;
  breakpoints?: {
    [key: string]: {
      width: number | string;
      height: number | string;
    };
  };
};

const getLogoProps = (variant: LogoVariant): LogoVariantProps | undefined => {
  return {
    default: {
      icon: FinanzflussLogoWhite,
      width: 120,
      height: 37,
    },
    compact: {
      icon: FinanzflussCompactLogo,
      width: 30,
      height: 30,
    },
    asset: {
      icon: FinanzflussAssetLogo,
      width: 30,
      height: 30,
    },
  }[variant as string];
};

type Props = {
  variant?: LogoVariant;
  alt?: string;
} & BoxProps;

export const Logo = ({ variant = "default", alt, ...rest }: Props) => {
  const logoVariant = getLogoProps(variant) || getLogoProps("default");
  const logoUrl = logoVariant?.icon;
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const dimensions =
    isLessSm && logoVariant?.breakpoints?.sm
      ? logoVariant?.breakpoints?.sm
      : isLessMd && logoVariant?.breakpoints?.md
      ? logoVariant?.breakpoints?.md
      : logoVariant;
  const { width, height } = dimensions || {};

  return logoUrl ? (
    <Box width={width} height={height} {...rest} sx={{ cursor: "pointer" }}>
      <img
        width="100%"
        height="100%"
        data-testid={`logo-finanzfluss-${variant}`}
        style={{ objectFit: "contain" }}
        src={logoUrl}
        alt={alt || `Finanzfluss logo`}
      />
    </Box>
  ) : null;
};
