/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateProfileParams
 */
export interface CreateOrUpdateProfileParams {
    /**
     * The users gender (1 = male, 2 = female); ignored if not given
     * @type {number}
     * @memberof CreateOrUpdateProfileParams
     */
    'gender'?: CreateOrUpdateProfileParamsGenderEnum;
    /**
     * The users first name; ignored if not given
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'firstName'?: string;
    /**
     * The users last name; ignored if not given
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'lastName'?: string;
    /**
     * The users academic title (Dr., Prof., ...)
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'academicTitle'?: string;
    /**
     * The users phone number; send a blank or empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'phoneNumber'?: string;
    /**
     * The users fax number; send a blank or empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'fax'?: string;
    /**
     * The users investment motto; send a blank or empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'motto'?: string;
    /**
     * The users self description; send a blank or empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'profileDescription'?: string;
    /**
     * The users investment strategy; send a blank or empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'investmentStrategy'?: string;
    /**
     * The users risk appetite; ignored if not given; no size constraints apply
     * @type {number}
     * @memberof CreateOrUpdateProfileParams
     */
    'riskAppetite'?: number;
    /**
     * Allow or prevent others from contacting the user; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'mayContact'?: boolean;
    /**
     * The user wants to discuss about investments; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'wantDiscuss'?: boolean;
    /**
     * Description what discussion topics the user likes; send an empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'wantDiscussDescription'?: string;
    /**
     * The user will help noobies; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'wantToHelp'?: boolean;
    /**
     * Description on which topics a user can help; send an empty string to clear
     * @type {string}
     * @memberof CreateOrUpdateProfileParams
     */
    'wantToHelpDescription'?: string;
    /**
     * Are the users investments visible to other users; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'investmentVisibility'?: boolean;
    /**
     * DEPRECATED: Is the profile publicly visible - this flag is used to clear visibility on an                                administrative level
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'isPublic'?: boolean;
    /**
     * Receive emails on investments (buys, sells) of his friends; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'emailNotification'?: boolean;
    /**
     * Receive emails on new investment related comments; ignored if not given
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'commentNotification'?: boolean;
    /**
     * A flag allowing to enable or disable certain tracking settings (must be implemented in the frontend)
     * @type {boolean}
     * @memberof CreateOrUpdateProfileParams
     */
    'isTrackingEnabled'?: boolean;
    /**
     * The frequency of the investment report (1 = weekly, 2 = monthly, 9 = off); ignored if not given
     * @type {number}
     * @memberof CreateOrUpdateProfileParams
     */
    'investmentReportFrequency'?: CreateOrUpdateProfileParamsInvestmentReportFrequencyEnum;
}

export const CreateOrUpdateProfileParamsGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CreateOrUpdateProfileParamsGenderEnum = typeof CreateOrUpdateProfileParamsGenderEnum[keyof typeof CreateOrUpdateProfileParamsGenderEnum];
export const CreateOrUpdateProfileParamsInvestmentReportFrequencyEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_9: 9
} as const;

export type CreateOrUpdateProfileParamsInvestmentReportFrequencyEnum = typeof CreateOrUpdateProfileParamsInvestmentReportFrequencyEnum[keyof typeof CreateOrUpdateProfileParamsInvestmentReportFrequencyEnum];


