import { UseQueryOptions } from "react-query";
import {
  SharingPreferencesApiShowSharingPreferencesByPublicIdRequest,
  SharingPreference,
} from "@generated/apiv1";

import { buildSharingPreferencesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showSharingPreferencesByPublicId = async (
  token: string | undefined,
  params: SharingPreferencesApiShowSharingPreferencesByPublicIdRequest
) => {
  const apiInstance = buildSharingPreferencesApi(token);
  const { data } = await apiInstance.showSharingPreferencesByPublicId(params);
  return data;
};

export const useShowSharingPreferencesByPublicId = (
  params: SharingPreferencesApiShowSharingPreferencesByPublicIdRequest,
  options?: UseQueryOptions<SharingPreference, AxiosApiError, SharingPreference>
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES_BY_PUBLIC_ID,
      params,
    ],
    ({ token }) => showSharingPreferencesByPublicId(token, params),
    options
  );
};
