import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, BoxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { Header } from "@components/common/headers/Header";
import { BackButton } from "@components/BackButton";
import { LoadingProgressBar } from "@components/LoadingProgressBar";

const Container = styled(Box)<{ layout: "default" | "centered" }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ layout }) =>
    layout === "centered" ? "center" : "stretch"};
  margin: ${({ theme }) =>
    theme.type === "finanzfluss" ? theme.spacing(6) : theme.spacing(5, 6)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: ${({ theme }) => theme.spacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing(12)};
  }
`;

interface Props extends Omit<BoxProps, "title"> {
  breadcrumbs?: BreadcrumbItem[];
  mobileMenu?: ReactNode;
  title?: ReactNode;
  subtitle?: string;
  backUrl?: string | (() => void);
  actionButton?: ReactNode;
  isLoading?: boolean;
  children: ReactNode;
  isMainPage?: boolean;
  id?: string;
  layout?: "default" | "centered";
}

export const PageLayout = ({
  breadcrumbs,
  mobileMenu,
  isMainPage,
  actionButton,
  layout = "default",
  isLoading,
  title,
  subtitle,
  backUrl,
  children,
  id,
  ...rest
}: Props) => {
  const navigate = useNavigate();
  const newBreadcrumbs = breadcrumbs ? [...breadcrumbs] : undefined;

  return (
    <>
      {newBreadcrumbs && <Breadcrumbs items={newBreadcrumbs} />}
      <Container id={id} layout={layout} {...rest}>
        <Box
          width="100%"
          sx={{ maxWidth: layout === "centered" ? "736px" : "none" }}
        >
          {backUrl && (
            <BackButton
              onClick={
                typeof backUrl === "function"
                  ? backUrl
                  : () => navigate(backUrl)
              }
            />
          )}
          {isLoading ? (
            <LoadingProgressBar />
          ) : (
            <>
              {(title || actionButton || mobileMenu) && (
                <Header
                  isMainPage={isMainPage}
                  title={title}
                  subtitle={subtitle}
                  actionButton={actionButton}
                  mobileMenu={mobileMenu}
                />
              )}
            </>
          )}
          {children}
        </Box>
      </Container>
    </>
  );
};
