import React from "react";
import { Line } from "recharts";
import { useChartColors } from "@hooks";
import { CustomizedDot } from "./CustomizedDot";

interface Props {
  dataKey: string;
  showDot?: boolean;
  // eslint-disable-next-line prettier/prettier
  chartColors: ReturnType<typeof useChartColors<"securitiesChart">>;
}

export const SecondaryLine = ({ dataKey, showDot, chartColors }: Props) => {
  return (
    <>
      <defs key="secondary-defs">
        <linearGradient id="secondaryLine" x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="40%"
            stopColor={chartColors.secondaryArea}
            stopOpacity={1.0}
          />
          <stop
            offset="100%"
            stopColor={chartColors.secondaryArea}
            stopOpacity={0.0}
          />
        </linearGradient>
      </defs>
      <Line
        yAxisId="1"
        key="secondary-line"
        isAnimationActive={false}
        type="monotone"
        activeDot={showDot ? CustomizedDot : false}
        strokeWidth={2}
        dataKey={dataKey}
        stroke={chartColors.secondaryLine}
        dot={false}
        legendType="none"
      />
    </>
  );
};
