import { addSeconds } from "date-fns";
import { LoginToken } from "@generated/apiv1";
import { LoginData } from "@generated/user";
export const LOGIN_TOKEN_LOCAL_STORAGE_KEY = "LOGIN_TOKEN";

export const saveToLocalStorage = (token: Token) => {
  localStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
};

export const saveToSessionStorage = (token: Token) => {
  sessionStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
};

export const saveToStorage = (
  tokenWithoutDate: LoginToken,
  rememberMe?: boolean
) => {
  const token: Token = {
    ...tokenWithoutDate,
    rememberMe,
    expires_at: addSeconds(new Date(), tokenWithoutDate.expires_in!),
    refresh_token: tokenWithoutDate.refresh_token!,
    access_token: tokenWithoutDate.access_token!,
  };

  if (rememberMe) {
    saveToLocalStorage(token);
  } else {
    saveToSessionStorage(token);
  }
  return token;
};

export const saveUserTokenToStorage = (
  token: Token,
  loginToken: LoginData,
  rememberMe?: boolean
) => {
  const newToken: Token = {
    ...token,
    auto_sign_out_at: new Date(loginToken.autoSignOutAt),
    ff_access_token: loginToken.ffAccessToken,
    frill_sso_token: loginToken.frillSsoToken,
    refresh_token: loginToken.wapiRefreshToken,
    access_token: loginToken.wapiAccessToken,
    //TODO: string[] vs Role[] difference between wapi and ff apis, uncomment/change once transition is complete
    // roles: loginToken.wapiRoles,
    expires_at: new Date(loginToken.wapiTokenExpiresAt),
  };

  if (rememberMe) {
    saveToLocalStorage(newToken);
  } else {
    saveToSessionStorage(newToken);
  }
  return token;
};
