import { UseQueryOptions } from "react-query";
import { getAccountBalances } from "@api/v1/methods";
import { DateValuePair } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { ACCOUNT_BALANCES } from "../../cacheKeys";

interface Params {
  accountIds: number[];
  from?: Date;
  to?: Date;
}

export const useAccountBalances = (
  params: Params,
  options?: UseQueryOptions<
    { dateValuePairs: DateValuePair[] },
    AxiosApiError,
    { dateValuePairs: DateValuePair[] }
  >
) =>
  useAuthQuery<
    { dateValuePairs: DateValuePair[] },
    AxiosApiError,
    { dateValuePairs: DateValuePair[] }
  >(
    [ACCOUNT_BALANCES, params],
    ({ token }) =>
      getAccountBalances(token, {
        id: params.accountIds,
        from: params.from?.toISOString(),
        to: params.to?.toISOString(),
      }),
    options
  );
