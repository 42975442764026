import React from "react";
import { Box, IconButton } from "@mui/material";
import { ActionsMenu } from "@components/common";
import { ReactComponent as CogIcon } from "@icons/cog.svg";

type Props = {
  children: React.ReactElement;
  size?: "small" | "medium";
};

export const ButtonWithActionMenu = ({ children, size = "small" }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <IconButton
        className="rangeSelectorButton"
        data-testid="open-menu-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        color="secondary"
        size={size}
      >
        <CogIcon />
      </IconButton>
      <ActionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {React.cloneElement(children, {
          handleCloseMenu,
        })}
      </ActionsMenu>
    </Box>
  );
};
