/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CashbackAdvantage
 */
export interface CashbackAdvantage {
    /**
     * Indicates if action by the user is recommended
     * @type {string}
     * @memberof CashbackAdvantage
     */
    'commissionStatus'?: CashbackAdvantageCommissionStatusEnum;
    /**
     * The theoretical cashback advantage
     * @type {number}
     * @memberof CashbackAdvantage
     */
    'cashbackAdvantage'?: number;
}

export const CashbackAdvantageCommissionStatusEnum = {
    ACTION_REQUIRED: 'ACTION_REQUIRED',
    CHECK: 'CHECK',
    OK: 'OK',
    OPTIONAL: 'OPTIONAL'
} as const;

export type CashbackAdvantageCommissionStatusEnum = typeof CashbackAdvantageCommissionStatusEnum[keyof typeof CashbackAdvantageCommissionStatusEnum];


