import { axiosInstanceV1, useAuthMutation } from "@api/auth";
import { createObjectUrl, downloadFile } from "@helpers/files";

const download = async (
  token: string | undefined,
  params?: { url: string; filename?: string }
) => {
  if (!params?.url) return undefined;

  const { data } = await axiosInstanceV1.get<File>(params.url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });

  return {
    filename: params?.filename,
    data,
  };
};

export const useDownload = (format: string, defaultFilename?: string) => {
  return useAuthMutation(download, {
    onSuccess: (data) => {
      if (!data) return;
      const { data: fileData, filename } = data;

      const url = createObjectUrl(fileData, format);
      downloadFile(url, filename || defaultFilename);
    },
  });
};
