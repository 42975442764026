import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  useMediaQuery,
  Theme,
  IconButton,
  Box,
  BoxProps,
  Skeleton,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as ArrowRight } from "@icons/chevron-crumb.svg";

const BreadcrumbsContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => theme.spacing(5, 0, 6, 6)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: ${({ theme }) => theme.spacing(2, 0, 4, 4)};
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
  height: 25px;
  width: 25px;
  margin-right: ${({ theme }) => theme.spacing(3)};
  border-radius: 50%;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

export interface BreadcrumbsProps extends BoxProps {
  backUrl?: string;
  items: BreadcrumbItem[];
  fontSize?: string;
  showBackButton?: boolean;
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const theme = useTheme();
  const { items, backUrl, showBackButton = true, ...rest } = props;

  const navigate = useNavigate();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const lastIndexItems = items.length - 1;
  const breadcrumbsAmount = items.length;
  const breadrcumbsLength = items.reduce(
    (acc, item) => item.text.length + acc,
    0
  );

  return (
    <BreadcrumbsContainer aria-label="breadcrumb" {...rest}>
      {isXs && showBackButton && (
        <StyledIconButton
          color="grey"
          onClick={() =>
            navigate(backUrl || items[items.length - 2]?.to || "/")
          }
        >
          <ArrowRight width="20px" height="20px" />
        </StyledIconButton>
      )}
      <MuiBreadcrumbs separator={<ArrowRight />}>
        {items.map((item, index) => {
          const key = `${item.text}-${item.to}`;
          const shouldDisplayDots =
            isXs &&
            (breadrcumbsLength > 40 || breadcrumbsAmount > 3) &&
            index === 0 &&
            item.to === "/";

          if (item.isLoading) {
            return <Skeleton width={100} key={key} />;
          }

          if (!item.to) {
            return lastIndexItems !== index ? (
              <Typography key={key} color="textSecondary" variant="body2">
                {shouldDisplayDots ? "..." : item.text}
              </Typography>
            ) : (
              <Box
                borderRadius={0.7}
                key={key}
                sx={{
                  background: theme.palette.background.secondary,
                  padding: theme.spacing(0.5, 1.5),
                }}
              >
                <Typography color={theme.palette.primary.main} variant="body2">
                  {shouldDisplayDots ? "..." : item.text}
                </Typography>
              </Box>
            );
          }

          return (
            <MuiLink
              key={key}
              color="inherit"
              to={item.to ?? ""}
              component={StyledLink}
              variant="body2"
            >
              {shouldDisplayDots ? "..." : item.text}
            </MuiLink>
          );
        })}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  );
};
