import { useState } from "react";
import {
  getStoredSortParams,
  setStoredSortParams,
} from "@helpers/localStorage";

export interface SortProperties {
  field: string;
  direction: TableSortDirection;
  saveKey?: string;
}

export const useTableSort = (defaultSort?: SortProperties) => {
  const savedSort = defaultSort?.saveKey
    ? getStoredSortParams(defaultSort?.saveKey)
    : undefined;
  const sortProps = savedSort
    ? {
        field: savedSort.sortField,
        direction: savedSort.sortDirection,
      }
    : defaultSort;

  const [sort, setSort] = useState<SortProperties | undefined>(sortProps);

  const handleSetSort = (field: string, direction?: TableSortDirection) => {
    setSort((current) => {
      let sortParams: SortProperties | undefined;
      if (field && direction) {
        sortParams = { field, direction };
      } else if (current?.field !== field) {
        sortParams = { field, direction: "ASC" };
      } else if (current?.direction === "DESC") {
        sortParams = { field, direction: "ASC" };
      } else {
        sortParams = { field, direction: "DESC" };
      }

      if (defaultSort?.saveKey) {
        setStoredSortParams(
          sortParams.field,
          sortParams.direction,
          defaultSort?.saveKey
        );
      }
      return sortParams;
    });
  };

  return {
    sort: handleSetSort,
    sortField: sort?.field,
    sortDirection: sort?.direction,
  };
};
