import React, { useState, ChangeEvent } from "react";
import { styled, Table, TableContainer, TablePagination } from "@mui/material";
import { Dividend } from "@api";
import { SummaryTableHeader } from "./SummaryTableHeader";
import { SummaryTableBody } from "./SummaryTableBody";

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  & .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(2, 4)};
    border-color: ${({ theme }) => theme.palette.border.secondary};
  }

  & .MuiTableRow-root:not(.MuiTableRow-head):last-child .MuiTableCell-root {
    border-bottom: none;
  }
`;

interface Props {
  selectedDividends: Dividend[];
}

export const SummaryTable = ({ selectedDividends }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTableContainer>
      <Table>
        <SummaryTableHeader />
        <SummaryTableBody
          selectedDividends={selectedDividends.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
        />
      </Table>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[5, 10, 25]}
        count={selectedDividends.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTableContainer>
  );
};
