import { UseMutationOptions } from "react-query";
import { BookingsApiDeleteBookingByExternalIdRequest } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBookingByExternalId = async (
  token: string | undefined,
  params: BookingsApiDeleteBookingByExternalIdRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.deleteBookingByExternalId(params);
  return data;
};

export const useDeleteBookingByExternalId = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BookingsApiDeleteBookingByExternalIdRequest
  >
) => {
  return useAuthMutation(deleteBookingByExternalId, options, false);
};
