import { Box, Card, Typography, useTheme } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { YTDPercents } from "@features/investments/components/Assets/YTDPercents";
import { PercentChart } from "@features/investments/components/Assets/PercentChart";
import { toCurrency } from "@helpers";
import { BlurredTypography } from "@components/BlurredTypography";
import { AssetTypeCardLoading } from "./AssetTypeCardLoading";

const Marker = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const PercentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledCard = styled(Card)(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(4),
  height: 120,
  width: "auto",
  minWidth: 260,
  cursor: "pointer",

  [theme.breakpoints.down("md")]: {
    cursor: "grab",
  },
}));

export type AssetCard =
  | "shares"
  | "funds"
  | "etfs"
  | "bonds"
  | "currency"
  | "materialAssets"
  | "metals";

export interface Props {
  isLoading?: boolean;
  hasData?: boolean;
  title: string;
  amount?: string;
  percent?: number;
  increase?: number;
  changeAbs?: number;
  color?: string;
  onClick?: () => void;
  isSelected: boolean;
  interval: string;
}

export const AssetTypeCard = ({
  percent,
  onClick,
  title,
  amount,
  increase,
  changeAbs,
  color,
  isLoading = false,
  hasData = true,
  isSelected = false,
  interval,
}: Props) => {
  const theme = useTheme();

  if (isLoading) {
    return <AssetTypeCardLoading />;
  }

  if (!hasData || amount == null || percent == null || increase == null) {
    return null;
  }

  return (
    <StyledCard
      onClick={onClick}
      sx={{
        background: isSelected ? theme.palette.background.secondary : "unset",
        border: `1px solid ${
          isSelected ? theme.palette.background.primary : "transparent"
        }`,
        outline: `1px solid ${
          isSelected
            ? theme.palette.background.primary
            : theme.palette.border.secondary
        }`,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={3}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Marker color={color} />
          <Typography variant="body2" fontWeight={400}>
            {title}
          </Typography>
        </Box>
        <PercentWrapper>
          <YTDPercents interval={interval} value={increase} />
        </PercentWrapper>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" gap={3}>
          <Box ml={-1}>
            <PercentChart
              percent={percent}
              isSelected={isSelected}
              color={color || theme.palette.primary.main}
            />
          </Box>
          <Box>
            <BlurredTypography fontSize={24} fontWeight={600} noWrap mb={1}>
              {amount}
            </BlurredTypography>
            {Boolean(changeAbs) && (
              <BlurredTypography
                value={changeAbs}
                variant="body2"
                fontWeight={600}
                showIndicator
              >
                {toCurrency(changeAbs, "EUR")}
              </BlurredTypography>
            )}
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};
