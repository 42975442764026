import { UseQueryOptions } from "react-query";
import { HistoricQuote, HistoricQuoteParams } from "@api/v1/model";
import { loadHistoricQuotes } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { HISTORIC_QUOTES } from "../../cacheKeys";

export const useHistoricQuotes = (
  attrs: HistoricQuoteParams,
  options: UseQueryOptions<HistoricQuote[], AxiosApiError, HistoricQuote[]> = {}
) => {
  return useAuthQuery<HistoricQuote[], AxiosApiError, HistoricQuote[]>(
    [HISTORIC_QUOTES, attrs],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ({ token }) => loadHistoricQuotes(token, attrs!),
    options
  );
};
