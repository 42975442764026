import React from "react";
import { useTranslation } from "react-i18next";
import { DividendHistoryTreeNode } from "@generated/apiv1";
import { Badge } from "@components";
import { Dividend } from "@features/dividendPlanner/components/DividendsTable/useDividendsForTable";

const getFrequency = (dividend: DividendHistoryTreeNode | Dividend) => {
  if (dividend.numberOfPaymentsPerYear === 1) {
    return "annually";
  } else if (dividend.numberOfPaymentsPerYear === 2) {
    return "semiAnnually";
  } else if (dividend.numberOfPaymentsPerYear === 4) {
    return "quarterly";
  } else if (dividend.numberOfPaymentsPerYear === 12) {
    return "monthly";
  }
  return null;
};

type Props = {
  dividend: DividendHistoryTreeNode | Dividend;
};

export const DividendFrequency = ({ dividend }: Props) => {
  const { t } = useTranslation();
  const frequency = getFrequency(dividend);

  if (!frequency) {
    return null;
  }

  return (
    <Badge variant="default">
      {t(`dividends.calendarPage.tooltip.frequencyMap.${frequency}`)}
    </Badge>
  );
};
