import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest,
  AddOrUpdateBankConnectionResult,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const webFormFlowAddOrUpdateBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.webFormFlowAddOrUpdateBankConnection(
    params
  );
  return data;
};

export const useWebFormFlowAddOrUpdateBankConnection = (
  options?: UseMutationOptions<
    AddOrUpdateBankConnectionResult,
    AxiosApiError,
    BankConnectionsApiWebFormFlowAddOrUpdateBankConnectionRequest
  >
) => {
  return useAuthMutation(webFormFlowAddOrUpdateBankConnection, options, false);
};
