/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankGroupWrapper } from './bank-group-wrapper';
// May contain unused imports in some cases
// @ts-ignore
import { BankInterface } from './bank-interface';

/**
 * Container for a bank\'s data
 * @export
 * @interface BankWrapper
 */
export interface BankWrapper {
    /**
     * Bank identifier.<br/><br/>NOTE: Do NOT assume that the identifiers of banks are the same across                        different Rentablo environments. In fact, the identifiers may change whenever a new API version is                        released, even within the same environment. The identifiers are meant to be used for references                        within our services only, but not for hard-coding them in your application. If you need to                        hard-code the usage of a certain bank within your application, please instead refer to the BIC.
     * @type {number}
     * @memberof BankWrapper
     */
    'id': number;
    /**
     * Who provides the multibanking API?
     * @type {string}
     * @memberof BankWrapper
     */
    'multibankingApiProvider': BankWrapperMultibankingApiProviderEnum;
    /**
     * Name of bank
     * @type {string}
     * @memberof BankWrapper
     */
    'name': string;
    /**
     * BIC of bank
     * @type {string}
     * @memberof BankWrapper
     */
    'bic'?: string;
    /**
     * The \'slug\' used by multibanking provider WEALTH_API              to identify a bank. Empty if not supported by WEALTH_API
     * @type {string}
     * @memberof BankWrapper
     */
    'slug'?: string;
    /**
     * BLZ of bank
     * @type {string}
     * @memberof BankWrapper
     */
    'blz': string;
    /**
     * Bank location (two-letter country code; ISO 3166 ALPHA-2). Note that when this field is not set,                        it means that this bank depicts an international institute which is not bound to any specific country.
     * @type {string}
     * @memberof BankWrapper
     */
    'location'?: string;
    /**
     * City that this bank is located in. Note that this field may not be set for some banks.
     * @type {string}
     * @memberof BankWrapper
     */
    'city'?: string;
    /**
     * DEPRECATED. Whether this bank is supported by Rentablo / finAPI, i.e. whether you can import/update a bank                        connection of this bank.<br><br>NOTE: This field is deprecated and will be removed at some point.                        Please refer to field \'interfaces\' instead.
     * @type {boolean}
     * @memberof BankWrapper
     */
    'isSupported': boolean;
    /**
     * Whether this bank\'s XS2A interface is production ready. Even if this flat is false, a bank                        may still ship with XS2A interfaces, and these interfaces may be used. We however expect                        service interruptions due to ongoing implementation and testing on bank side.
     * @type {boolean}
     * @memberof BankWrapper
     */
    'isXs2aReady': boolean;
    /**
     * Whether this bank\'s credentials are compatible across interfaces.
     * @type {string}
     * @memberof BankWrapper
     */
    'credentialCompatibility': BankWrapperCredentialCompatibilityEnum;
    /**
     * If true, then this bank does not depict a real bank, but rather a testing endpoint provided by a\" +                        bank or by finAPI. You probably want to regard these banks only during the development of your                        application, but not in production. You can filter out these banks in production by making sure                        that the \'isTestBank\' parameter is always set to \'false\' whenever your application is calling                        the \'Get and search all banks\' service.
     * @type {boolean}
     * @memberof BankWrapper
     */
    'isTestBank': boolean;
    /**
     * Popularity of this bank with your users (mandator-wide, i.e. across all of your clients).                        The value equals the number of bank connections that are currently imported for this bank across                        all of your users (which means it is a constantly adjusting value). You can use this field for                        statistical evaluation, and also for ordering bank search results                        (see service \'Get and search all banks\').
     * @type {number}
     * @memberof BankWrapper
     */
    'popularity': number;
    /**
     * Set of interfaces that Rentablo can use to connect to the bank. Note that this set                                  will be empty for non-supported banks.
     * @type {Array<BankInterface>}
     * @memberof BankWrapper
     */
    'interfaces': Array<BankInterface>;
    /**
     * Url to display the bank icon.
     * @type {string}
     * @memberof BankWrapper
     */
    'iconUrl': string;
    /**
     * Assigns BICs to group, e.g. \'SPARKASSE\'. A group may be used to select custom icons.             If there is only one bic, the group will equal the BIC.
     * @type {string}
     * @memberof BankWrapper
     */
    'bicGroup'?: string;
    /**
     * 
     * @type {BankGroupWrapper}
     * @memberof BankWrapper
     */
    'bankGroup'?: BankGroupWrapper;
}

export const BankWrapperMultibankingApiProviderEnum = {
    FIN_API: 'FIN_API',
    WEALTH_API: 'WEALTH_API'
} as const;

export type BankWrapperMultibankingApiProviderEnum = typeof BankWrapperMultibankingApiProviderEnum[keyof typeof BankWrapperMultibankingApiProviderEnum];
export const BankWrapperCredentialCompatibilityEnum = {
    COMPATIBLE: 'COMPATIBLE',
    INCOMPATIBLE: 'INCOMPATIBLE',
    UNKNOWN: 'UNKNOWN'
} as const;

export type BankWrapperCredentialCompatibilityEnum = typeof BankWrapperCredentialCompatibilityEnum[keyof typeof BankWrapperCredentialCompatibilityEnum];


