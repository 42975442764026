import { UseQueryOptions } from "react-query";
import {
  InflationRatesApiGetInflationRatesRequest,
  Quote,
} from "@generated/apiv1";

import { buildInflationRatesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getInflationRates = async (
  token: string | undefined,
  params: InflationRatesApiGetInflationRatesRequest
) => {
  const apiInstance = buildInflationRatesApi(token);
  const { data } = await apiInstance.getInflationRates(params);
  return data;
};

export const useGetInflationRates = (
  params: InflationRatesApiGetInflationRatesRequest,
  options?: UseQueryOptions<Quote, AxiosApiError, Quote>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INFLATIONRATES_GET_INFLATION_RATES, params],
    ({ token }) => getInflationRates(token, params),
    options
  );
};
