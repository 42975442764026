import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styled from "@emotion/styled";
import { useSearchBanks } from "@api";
import { SecurityImage } from "@components/SecurityImage";
import { ReactComponent as SearchIcon } from "@icons/myicons/search.svg";
import { BankOption, banksForSearchField } from "@constants";

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const renderOption = (props: Object, option: BankOption) => (
  <li {...props}>
    <StyledListItem
      id={`bank-option-${option.bic}`}
      data-testid={`bank-option-${option.bic}`}
    >
      <Box display="flex" gap={2} alignItems="center">
        <SecurityImage src={option.iconUrl || ""} width={24} height={24} />
        <Typography variant="body1">{option.name}</Typography>
      </Box>

      {option.bic && (
        <Typography variant="body2" color="textSecondary" fontWeight={400}>
          {option.bic}
        </Typography>
      )}
    </StyledListItem>
  </li>
);

const renderSearchInput =
  (label: string, placeholder: string, bank?: BankOption | null) =>
  (params: AutocompleteRenderInputParams) =>
    (
      <Box position="relative">
        <TextField
          {...params}
          data-testid="bank-search"
          label={label}
          inputProps={{
            ...params.inputProps,
            sx: {
              "&::placeholder": {
                color: "text.secondary",
                opacity: 1,
              },
            },
            "data-testid": "bank-search-input",
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  marginLeft: (theme) => theme.spacing(3),
                }}
              >
                {bank?.iconUrl ? (
                  <SecurityImage
                    src={bank?.iconUrl || ""}
                    width={20}
                    height={20}
                  />
                ) : (
                  <Box color="icon.secondary" height="24px">
                    <SearchIcon width="24px" height="24px" />
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              paddingLeft: `2px !important`,
            },
          }}
        />
      </Box>
    );

const ListboxComponent = React.forwardRef<HTMLUListElement, any>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <>
        <Box display="flex" justifyContent="space-between" px={3.5} pt={2}>
          <Typography variant="overline" color="textSecondary" fontWeight={600}>
            {t("components.bankSearchField.bankName")}
          </Typography>
          <Typography variant="overline" color="textSecondary" fontWeight={600}>
            {t("components.bankSearchField.bic")}
          </Typography>
        </Box>
        <ul ref={ref} {...props} />
      </>
    );
  }
);

type Props = {
  defaultBank?: BankOption | null;
  onBankClick?: (bank: BankOption | null) => void;
  label?: string;
  placeholder?: string;
  listHeight?: number;
};

export const BankSearchField = ({
  defaultBank,
  onBankClick,
  label = i18next.t("components.bankSearchField.label") as string,
  placeholder = i18next.t("components.bankSearchField.placeholder") as string,
  listHeight,
}: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = React.useState<BankOption[]>([]);
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState<BankOption | null>(
    defaultBank || null
  );

  const handleBankClick = (value: BankOption | null) => {
    setSelectedBank(value);
    onBankClick?.(value);
  };

  useEffect(() => {
    defaultBank && setSelectedBank(defaultBank);
  }, [defaultBank]);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setOptions(banksForSearchField);
    }
  }, [debouncedSearchValue]);

  useSearchBanks(debouncedSearchValue, {
    enabled: Boolean(debouncedSearchValue),
    onSuccess: (data) => {
      setOptions(
        data?.banks?.map((bank) => ({
          id: bank.bic,
          name: bank.name,
          bic: bank.bic,
          iconUrl: bank.iconUrl,
        })) ?? []
      );
      setLoading(false);
    },
  });

  return (
    <Autocomplete
      id="bank-search"
      data-testid="bank-autocomplete"
      value={selectedBank}
      open={open}
      options={options}
      loading={loading}
      disablePortal
      renderOption={renderOption}
      renderInput={renderSearchInput(label, placeholder, selectedBank)}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        style: {
          maxHeight: listHeight || "auto",
        },
      }}
      inputValue={searchValue}
      getOptionLabel={(option) => (option as BankOption).name}
      filterOptions={(options) => options}
      blurOnSelect
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      noOptionsText={t("accountTypes.bankSelect.noOptions")}
      onChange={(_, value) => handleBankClick((value as BankOption) || null)}
      onInputChange={(_, value: string) => {
        setLoading(!!value);
        setSearchValue(value);
      }}
    />
  );
};
