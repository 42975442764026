import { SecurityMetricValue } from "../../SecurityMetrics";

export const useSelectedMetricsAndMenuItems = (
  savedMetrics: SecurityMetricValue[],
  allMetric: {
    key: string;
    label: string;
  }[]
) => {
  const allMetricKeys = allMetric.map(({ key }) => key);

  const selectedMetrics = savedMetrics.filter(({ mainMetricKey }) =>
    allMetricKeys.includes(mainMetricKey)
  );

  return { selectedMetrics, menuItems: allMetric };
};
