import { differenceInCalendarDays, parseISO } from "date-fns";
import { Booking, BookingTypeEnum, InvestmentReference } from "@api";
import { ParsingResultActivity } from "@features/fileDrop/components/ParsingResult";
import { Activity } from "@generated/apiv1/pdfParser";

const MAX_DATE_DIFFERENCE = 5;
const MAX_AMOUNT_DIFFERENCE_PERCENT = 0.025;
const MAX_NOL_DIFFERENCE_PERCENT = 0.05;

export interface ActivityWithBooking {
  activity: ParsingResultActivity;
  booking?: Booking;
  investment?: InvestmentReference;
  hash: string;
  id: string;
}
export const generateRandomString = () => {
  return new Date().getTime() + Math.random().toString(36).substring(7);
};

export const getTransactionAmount = (parsingActivity: Activity) => {
  return parsingActivity.type === "buy" || parsingActivity.type === "book_in"
    ? Math.abs(parsingActivity.netAmount || 0) * -1
    : Math.abs(parsingActivity.netAmount || 0);
};

const findBookingsWithMatchingTickerSymbol = (
  bookings: Booking[],
  tickerSymbol?: string
) => {
  return bookings.filter(
    (booking) => booking.investment?.tickerSymbol === tickerSymbol
  );
};

const findBookingsWithMatchingIsinsOrWkn = (
  bookings: Booking[],
  isin?: string,
  wkn?: string
) => {
  return bookings.filter(
    (booking) =>
      (isin && booking.investment?.isin === isin) ||
      (wkn && booking.investment?.wkn === wkn)
  );
};

const findBookingWithMatchingDateAndType = (
  withMatchingIsinOrWkn: Booking[],
  parsingActivity: Activity
): Booking | undefined => {
  let matchingBookings: Booking[] = [];
  withMatchingIsinOrWkn.forEach((booking) => {
    if (!parsingActivity.date) return;

    if (
      parsingActivity.type &&
      !doTypesMatch(booking.type, parsingActivity.type)
    )
      return;

    if (
      !doDatesMatch(parsingActivity.date, booking.valueDate) &&
      !doDatesMatch(parsingActivity.date, booking.bankBookingDate)
    )
      return;

    if (
      doesAmountMatch(booking, parsingActivity) ||
      doNumberOfLotsMatch(booking, parsingActivity)
    ) {
      matchingBookings.push(booking);
    }
  });

  return matchingBookingWithMinAmountDelta(matchingBookings, parsingActivity);
};

const doTypesMatch = (
  bookingType: BookingTypeEnum,
  activityType: BookingTypeEnum
) => {
  if (bookingType === activityType) return true;
  if (bookingType === "buy" && activityType === "book_in") return true;
  return bookingType === "sell" && activityType === "book_out";
};

const doDatesMatch = (isoDate1: string, isoDate2: string) => {
  return (
    Math.abs(
      differenceInCalendarDays(parseISO(isoDate1), parseISO(isoDate2))
    ) <= MAX_DATE_DIFFERENCE
  );
};

const doesAmountMatch = (booking: Booking, parsingActivity: Activity) => {
  const toBeAmount = getTransactionAmount(parsingActivity);
  return (
    Math.abs(toBeAmount - booking.amount) <=
    Math.abs(toBeAmount * MAX_AMOUNT_DIFFERENCE_PERCENT)
  );
};

const doNumberOfLotsMatch = (booking: Booking, parsingActivity: Activity) => {
  if (!parsingActivity.numberOfLots || parsingActivity.type === "dividend")
    return false;

  const toBeNol = Math.abs(parsingActivity.numberOfLots);
  return (
    Math.abs(toBeNol - booking.numberOfLots) <=
    Math.abs(toBeNol * MAX_NOL_DIFFERENCE_PERCENT)
  );
};

const matchingBookingWithMinAmountDelta = (
  bookings: Booking[],
  parsingActivity: Activity
) => {
  if (!bookings?.length) return undefined;
  let delta = 99999999;
  let result: Booking | undefined = undefined;
  bookings.forEach((booking) => {
    const currentDelta = Math.abs(
      getTransactionAmount(parsingActivity) - booking.amount
    );
    if (currentDelta < delta) {
      result = booking;
      delta = currentDelta;
    }
  });
  return result;
};

const withPositiveNolOrDefault = (bookings: Booking[]) => {
  if (!bookings?.length) return undefined;

  const withPositiveNol = bookings.find(
    (booking) => booking.investment.numberOfLots > 0
  );

  return withPositiveNol ? withPositiveNol.investment : bookings[0].investment;
};

export const locateBookings = (
  bookings: Booking[],
  parsingActivities: Array<ParsingResultActivity>
): ActivityWithBooking[] => {
  const activitiesWithBookings = parsingActivities.map((parsingActivity) => {
    const isCrypto = Boolean(parsingActivity.cryptoSymbol);
    const withMatchingTickerSymbol = isCrypto
      ? findBookingsWithMatchingTickerSymbol(
          bookings,
          `${parsingActivity.cryptoSymbol}_to_EUR`
        )
      : [];

    const withMatchingIsinOrWkn = isCrypto
      ? []
      : findBookingsWithMatchingIsinsOrWkn(
          bookings,
          parsingActivity.isin,
          parsingActivity.wkn
        );
    const withMatchingIsinOrWknOrTickerSymbol = isCrypto
      ? withMatchingTickerSymbol
      : withMatchingIsinOrWkn;

    const match = findBookingWithMatchingDateAndType(
      withMatchingIsinOrWknOrTickerSymbol,
      parsingActivity
    );

    return {
      activity: parsingActivity,
      booking: match,
      investment: match
        ? match.investment
        : withPositiveNolOrDefault(withMatchingIsinOrWknOrTickerSymbol),
      hash: generateRandomString(),
      id: parsingActivity.parsingResultId,
    };
  });

  return activitiesWithBookings.sort((a, b) => {
    return `${a.activity.name}${a.activity.date}`.localeCompare(
      `${b.activity.name}${b.activity.date}`
    );
  });
};
