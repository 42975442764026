import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, matchRoutes } from "react-router-dom";
import { Divider, styled, useTheme } from "@mui/material";
import { UserWithType } from "@api";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as LineChartIcon } from "@icons/line-chart.svg";
import { ReactComponent as AnalyticsPie } from "@icons/analytics-pie-2.svg";
import { ReactComponent as HandEuro } from "@icons/hand-euro.svg";
import { ReactComponent as Eye } from "@icons/eye.svg";
import { ReactComponent as Bars } from "@icons/bars.svg";
import { ReactComponent as BuildingColumns } from "@icons/building-columns-solid.svg";
import { MenuItem, SidebarMenu } from "@components";

export interface AppSidebarMenuProps {
  className?: string;
  collapsed?: boolean;
  user?: UserWithType;
}

export const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== "collapsed",
})`
  margin: ${({ theme }) => theme.spacing(2, 0, 3)};
  border-color: ${({ theme }) => theme.appSidebar?.divider};
  width: 100%;
`;

export const AppSidebarMenu = ({
  className,
  collapsed = false,
}: AppSidebarMenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();

  return (
    <SidebarMenu className={className} collapsed={collapsed}>
      <MenuItem
        to="/"
        name={t("sidebar.dashboard")}
        selected={pathname === "/"}
        before={<HomeIcon />}
      />
      <MenuItem
        to="/investments"
        name={t("sidebar.investments")}
        selected={Boolean(
          matchRoutes(
            [
              { path: "/investments/*" },
              { path: "/securities/*" },
              { path: "/new-transaction-wizard/*" },
              { path: "/manual-transaction-wizard/*" },
              { path: "/edit-transaction-wizard/*" },
            ],
            location
          )
        )}
        before={<LineChartIcon />}
      />
      <MenuItem
        to="/classifications"
        name={t("sidebar.analyse")}
        selected={pathname === "/classifications"}
        before={<AnalyticsPie />}
      />
      <MenuItem
        to="/dividend-planner"
        name={t("sidebar.dividends")}
        selected={pathname === "/dividend-planner"}
        before={<HandEuro />}
      />
      <MenuItem
        to="/watchlists"
        name={t("sidebar.watchlists")}
        selected={Boolean(matchRoutes([{ path: "/watchlists/*" }], location))}
        before={<Eye color={theme.palette.common.white} />}
      />

      <StyledDivider />

      <MenuItem
        to="/cashflow-analyzer"
        name={t("sidebar.budget")}
        selected={pathname === "/cashflow-analyzer"}
        before={<Bars />}
      />

      <MenuItem
        to="/depots-accounts"
        name={t("sidebar.portfoliosAndAccounts.main")}
        before={<BuildingColumns color={theme.palette.common.white} />}
        selected={Boolean(
          matchRoutes(
            [
              { path: "/depots/*" },
              { path: "/accounts/*" },
              { path: "/depots-accounts" },
              { path: "/import-bank-connection/select-bank" },
            ],
            location
          )
        )}
      />
    </SidebarMenu>
  );
};
