import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { goToInvestments } from "@helpers/mainAppUrls";

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.dark};
`;

const ButtonWithSpacer = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
  message?: string;
}

export const SomethingWrongStep = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <div data-testid="something-wrong">
      <Title variant="h4">{t("transactionWizard.somethingWrong.title")}</Title>
      {message && <Typography variant="h3">{message}</Typography>}
      <Typography variant="body1">
        {t("transactionWizard.somethingWrong.contactSupport")}
      </Typography>

      <ButtonWithSpacer
        variant="contained"
        color="primary"
        onClick={() => goToInvestments()}
      >
        {t("transactionWizard.somethingWrong.done")}
      </ButtonWithSpacer>
    </div>
  );
};
