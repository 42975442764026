import { UseQueryOptions } from "react-query";
import { BankList } from "@generated/apiv1/pdfParser";

import { buildBankControllerApi } from "@generated/apiv1/pdfParser/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/pdfParser/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const show = async (token: string | undefined) => {
  const apiInstance = buildBankControllerApi(token);
  const { data } = await apiInstance.show();
  return data;
};

export const useShow = (
  options?: UseQueryOptions<BankList, AxiosApiError, BankList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONTROLLER_SHOW],
    ({ token }) => show(token),
    options
  );
};
