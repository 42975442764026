import { UseQueryOptions } from "react-query";
import {
  DividendsApiPublicDividendYieldRequest,
  DividendYieldList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicDividendYield = async (
  token: string | undefined,
  params: DividendsApiPublicDividendYieldRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.publicDividendYield(params);
  return data;
};

export const usePublicDividendYield = (
  params: DividendsApiPublicDividendYieldRequest,
  options?: UseQueryOptions<DividendYieldList, AxiosApiError, DividendYieldList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_PUBLIC_DIVIDEND_YIELD, params],
    ({ token }) => publicDividendYield(token, params),
    options
  );
};
