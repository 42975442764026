import { TransactionWizardContext } from "../types/transactionWizardContext";

export const clearInvestmentValuesAction = (
  context: TransactionWizardContext
) => {
  const depotId = new URL(window.location.href).searchParams.get("depotId");

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId: depotId ? parseInt(depotId) : undefined,
      isin: undefined,
      name: undefined,
      id: undefined,
      numberOfLots: undefined,
      tickerSymbol: undefined,
    },
  };
};
