/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreatePerformanceIntervalsParams
 */
export interface CreatePerformanceIntervalsParams {
    /**
     * Start of the performance calculation. The start date is limited to the maximum             quote historic, which is currently 10 years. Default is 6 years ago. Date format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof CreatePerformanceIntervalsParams
     */
    'startDate'?: string;
    /**
     * End of the performance calculation; default is today.             Date format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof CreatePerformanceIntervalsParams
     */
    'endDate'?: string;
    /**
     * Calculate the performance on the given accounts or depots.
     * @type {Array<number>}
     * @memberof CreatePerformanceIntervalsParams
     */
    'accountIds'?: Array<number>;
    /**
     * Restrict data points to the given interval. Default is \'month\'.
     * @type {string}
     * @memberof CreatePerformanceIntervalsParams
     */
    'interval'?: CreatePerformanceIntervalsParamsIntervalEnum;
    /**
     * Defines which value within the specified interval is used. Default value is current.             Ignored if no interval is given or interval is ```day```.             The interval position is defined as:             **current**: current day of week / month / year              **first**: first day of the week / month / year             **last:** last day of the week / month / year. Note that the last day for an incomplete year, month or             week may be today.             Default is \'current\'.
     * @type {string}
     * @memberof CreatePerformanceIntervalsParams
     */
    'intervalPosition'?: CreatePerformanceIntervalsParamsIntervalPositionEnum;
    /**
     * List of dates for which the performance intervals should be calculated.          If this list is set, startDate, endDate, interval and intervalPosition are ignored.
     * @type {Array<string>}
     * @memberof CreatePerformanceIntervalsParams
     */
    'intervalDates'?: Array<string>;
    /**
     * Should the calculation be done including the reference accounts cash balance; if this         is set true, dividends will be included as well as they are part of the cash balance.
     * @type {boolean}
     * @memberof CreatePerformanceIntervalsParams
     */
    'includeCash'?: boolean;
    /**
     * Should dividends be included in the performance calculation.
     * @type {boolean}
     * @memberof CreatePerformanceIntervalsParams
     */
    'includeDividends'?: boolean;
    /**
     * Should we filter for individual asset classes? If filtering is activated, only quote         performance (no cash, no dividends) is supported.
     * @type {Array<string>}
     * @memberof CreatePerformanceIntervalsParams
     */
    'assetClasses'?: Array<string>;
}

export const CreatePerformanceIntervalsParamsIntervalEnum = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
} as const;

export type CreatePerformanceIntervalsParamsIntervalEnum = typeof CreatePerformanceIntervalsParamsIntervalEnum[keyof typeof CreatePerformanceIntervalsParamsIntervalEnum];
export const CreatePerformanceIntervalsParamsIntervalPositionEnum = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last'
} as const;

export type CreatePerformanceIntervalsParamsIntervalPositionEnum = typeof CreatePerformanceIntervalsParamsIntervalPositionEnum[keyof typeof CreatePerformanceIntervalsParamsIntervalPositionEnum];


