import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useQueryClient } from "react-query";
import { subDays } from "date-fns";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@components/LoadingButton";
import { sameDateInUTC } from "@helpers";
import { RHFDatePicker, RHFTextField } from "@components/common/forms";
import { useAddOrUpdateHistoricQuotes } from "@api/v1/hooks/useAddOrUpdateHistoricQuotes";
import { Investment } from "@generated/apiv1";
import { GET_INVESTMENTS } from "@api/cacheKeys";

interface FormFields {
  quote: string;
  quoteFrom: string;
}

interface Props {
  investment?: Investment;
  onClose?: () => void;
}
export const InvestmentTableAddQuoteModal = ({
  investment,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    quote: Yup.number().required(),
    quoteFrom: Yup.string().required(),
  });

  const { handleSubmit, control, getValues, watch } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      quote: "0",
      quoteFrom: new Date().toString(),
    },
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: saveQuote, isLoading } = useAddOrUpdateHistoricQuotes({
    onSuccess: async () => {
      onClose && onClose();
      enqueueSnackbar(
        `${t("domains.investments.investmentTable.addQuote.successMessage")}`,
        {
          autoHideDuration: 3000,
          variant: "success",
        }
      );
      await queryClient.invalidateQueries({ queryKey: [GET_INVESTMENTS] });
    },
  });
  const onSubmit = () => {
    const { quote, quoteFrom } = getValues();
    saveQuote({
      investmentId: investment?.id,
      dateValuePairs: [
        {
          date: sameDateInUTC(new Date(quoteFrom)).toISOString(),
          value: Number.parseFloat(quote),
        },
      ],
    });
  };

  const isSaveBtnDisabled =
    !watch("quote") || Number(watch("quote")) <= 0 || !watch("quoteFrom");

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6} md={6}>
        <RHFTextField
          name="quote"
          label={t("domains.investments.investmentTable.addQuote.quote")}
          control={control}
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} lg={6} md={6}>
        <RHFDatePicker
          name="quoteFrom"
          label={t("domains.investments.investmentTable.addQuote.quoteFrom")}
          control={control as Control<any>}
          maxDate={subDays(new Date(), 1)}
        />
      </Grid>
      <Grid item lg={12}>
        <LoadingButton
          variant="contained"
          type="submit"
          size="small"
          color="primary"
          isLoading={isLoading}
          sx={{ marginTop: 5 }}
          onClick={handleSubmit(onSubmit)}
          disabled={isSaveBtnDisabled}
        >
          {t("domains.investments.investmentTable.addQuote.save")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
