import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMachine } from "@xstate/react";
import {
  Box,
  IconButton,
  IconButtonProps,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BaseActionObject,
  ResolveTypegenMeta,
  ServiceMap,
  State,
  TypegenDisabled,
} from "xstate";
import { CloseButton, Modal } from "@components";
import { ReactComponent as ArrowLeft } from "@icons/arrow-left.svg";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";
import { newTransactionWizardModalSM } from "@features/transactionWizard/modal/stateMachine/newTransactionWizardModalSM";
import { SearchSymbolStep } from "@features/transactionWizard/modal/steps/SearchSymbolStep";
import { NameAndDepotStep } from "@features/transactionWizard/modal/steps/NameAndDepotStep";
import { InvestmentValues } from "@features/transactionWizard/modal/stateMachine/types/investmentValues";
import { BookingTypeStep } from "@features/transactionWizard/modal/steps/BookingTypeStep";
import {
  ExtendedBookingType,
  filterGroups,
} from "@features/transactionWizard/modal/steps/constants";
import { SymbolInfo } from "@features/transactionWizard/modal/components/SymbolInfo";
import { PriceAndDateStep } from "@features/transactionWizard/modal/steps/PriceAndDateStep";
import { BookingValues } from "@features/transactionWizard/modal/stateMachine/types/bookingValues";
import { SummaryStep } from "@features/transactionWizard/modal/steps/SummaryStep/SummaryStep";
import { SplitPreviewStep } from "@features/transactionWizard/modal/steps/SplitPreviewStep";
import { RatioAndDateStep } from "@features/transactionWizard/modal/steps/RatioAndDateStep";
import { SplitValues } from "@features/transactionWizard/modal/stateMachine/types/splitValues";
import { TransactionWizardState } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardState";
import {
  defaultContext,
  TransactionWizardContext,
} from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { TransactionWizardEvent } from "@features/transactionWizard/modal/stateMachine/types/TransactionWizardEvent";
import { AccountTypeEnum, QuoteV2, SymbolV2 } from "@generated/apiv1";
import { SomethingWrongStep } from "@features/transactionWizard/modal/steps/SomethingWrongStep";

const getStep = (
  currentState: State<
    TransactionWizardContext,
    TransactionWizardEvent,
    any,
    TransactionWizardState,
    ResolveTypegenMeta<
      TypegenDisabled,
      TransactionWizardEvent,
      BaseActionObject,
      ServiceMap
    >
  >
) => {
  if (typeof currentState.value === "object") {
    const entries = Object.entries(currentState.value)[0];
    return `${entries[0]}.${entries[1]}`;
  }
  return currentState.value;
};

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "50%",
  border: `1px solid ${theme.palette.border.secondary} !important`,
  width: "40px",
  height: "40px",

  [theme.breakpoints.down("sm")]: {
    border: "0px !important",
    width: "24px",
    height: "24px",
  },

  "& svg": {
    flexShrink: 0,
  },
}));

export const BackButton = (props: IconButtonProps) => {
  const theme = useTheme();

  return (
    <StyledIconButton {...props}>
      <ArrowLeft
        color={theme.palette.icon.primary}
        width="40px"
        height="40px"
      />
    </StyledIconButton>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  symbol?: SymbolV2 & { quote?: QuoteV2 };
  accountId?: number;
  investmentId?: number;
  accountType?: AccountTypeEnum;
  accountCategory?: AccountSubcategoryEnum;
};

export const NewTransactionModalContainer = ({
  isOpen,
  onClose,
  accountId,
  accountType,
  accountCategory,
  investmentId,
  symbol,
}: Props) => {
  const { t } = useTranslation();
  const [currentState, sendEvent] = useMachine(newTransactionWizardModalSM, {
    context: {
      ...defaultContext,
      defaultDepotId: accountId,
      defaultInvestmentId: investmentId,
      depotSubCategory: accountCategory,
    },
  });
  const shouldDisplayBackButton =
    currentState.value !== "idle" &&
    currentState.value !== "searchSymbol" &&
    !(symbol && currentState.value === "depots") &&
    !(
      (investmentId || accountId) &&
      currentState.value === "selectBookingType"
    );

  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isManual = Boolean(currentState.context.manualFlow);
  const isNonListed = Boolean(
    currentState.context.investmentValues?.type === "71_massets"
  );

  const handleOtherClick = (group: keyof typeof filterGroups) => {
    sendEvent({
      type: group === "other" ? "MANUAL_SELECTED" : "NON_LISTED_SELECTED",
      investmentValues:
        group === "other"
          ? { quoteProvider: "none" }
          : { type: "71_massets", quoteProvider: "none" },
    });
  };

  useEffect(() => {
    if (!currentState.matches("idle")) return;

    if (
      accountType === "05_material_assets" &&
      accountCategory !== AccountSubcategoryEnum.PRECIOUS_METALS
    ) {
      sendEvent({
        type:
          accountCategory === AccountSubcategoryEnum.REAL_ESTATE
            ? "INIT_FROM_NON_LISTED"
            : "INIT_FROM_MANUAL",
        investmentValues: { type: "71_massets", quoteProvider: "none" },
      });
    } else if (investmentId) {
      sendEvent({
        type: "INIT_FROM_INVESTMENT",
        id: investmentId,
      });
    } else if (symbol) {
      sendEvent({
        type: "INIT_FROM_SYMBOL",
        symbol,
      });
    } else {
      sendEvent({
        type: "INIT",
      });
    }
  }, [
    investmentId,
    symbol,
    sendEvent,
    currentState,
    accountCategory,
    accountType,
  ]);

  const onBackButtonClick = () => {
    sendEvent({
      type: "BACK",
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      width={830}
      maxHeight={isLessSm ? "calc(100vh - 60px)" : "100vh"}
      height={isLessSm ? "calc(100vh - 60px)" : "auto"}
      position={isLessSm ? "bottom" : "centered"}
      zIndex={4000}
      disablePortal={false}
      title={
        currentState.matches("somethingWrong") ? undefined : (
          <Box
            mb={8}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <Box width={isLessSm ? "24px" : "40px"}>
              {shouldDisplayBackButton && (
                <BackButton onClick={onBackButtonClick} />
              )}
            </Box>
            <Typography variant={isLessSm ? "h6" : "h4"} fontWeight={700}>
              {accountCategory === AccountSubcategoryEnum.REAL_ESTATE &&
              "priceAndDate" === getStep(currentState)
                ? t("transactionWizardModal.priceAndDate.realEstateTitle")
                : t(`transactionWizardModal.${getStep(currentState)}.title`, {
                    bookingType: t(
                      `transactionWizardModal.selectBookingType.${currentState.context.bookingValues?.type}.title`
                    ),
                  })}
            </Typography>
            <Box display="flex" alignItems="center" gap={4}>
              <CloseButton onClose={onClose} />
            </Box>
          </Box>
        )
      }
      onClose={onClose}
    >
      {currentState.context.symbol && (
        <SymbolInfo
          symbol={currentState.context.symbol}
          bookingType={currentState.context.bookingValues?.type}
        />
      )}
      <Box height="100%" sx={{ overflowY: "auto" }}>
        <>
          {currentState.matches("searchSymbol") && (
            <SearchSymbolStep
              context={currentState.context}
              handleNext={(symbol) =>
                sendEvent({
                  type: "INIT_FROM_SYMBOL",
                  symbol,
                })
              }
              onOtherClick={handleOtherClick}
            />
          )}
          {(currentState.matches("depots") ||
            ((isManual || isNonListed) &&
              currentState.matches("split.loadBookings"))) && (
            <NameAndDepotStep
              context={currentState.context}
              handleNext={(
                depotId: number,
                investmentValues: InvestmentValues,
                symbol?: SymbolV2 & { quote?: QuoteV2 },
                comment?: string,
                accountCategory?: AccountSubcategoryEnum
              ) =>
                sendEvent({
                  type: "NAME_AND_DEPOT_SELECTED",
                  depotId,
                  symbol,
                  investmentValues,
                  comment,
                  depotSubCategory: accountCategory,
                })
              }
              isLoading={
                // @ts-ignore
                currentState.matches("split.loadBookings")
              }
              isInvestmentRequired={Boolean(
                currentState.context.isInvestmentRequired
              )}
              isManualTransaction={isManual}
            />
          )}

          {(currentState.matches("selectBookingType") ||
            currentState.matches("loadInvestment") ||
            (!isManual &&
              !isNonListed &&
              currentState.matches("split.loadBookings"))) && (
            <BookingTypeStep
              handleNext={(bookingType: ExtendedBookingType, investmentId) =>
                sendEvent({
                  type: "BOOKING_TYPE_SELECTED",
                  bookingType,
                  investmentId,
                })
              }
              context={currentState.context}
              isManualTransaction={isManual}
              // @ts-ignore
              isLoadingSplit={currentState.matches("split.loadBookings")}
              isLoading={
                // @ts-ignore
                currentState.matches("loadInvestment")
              }
            />
          )}

          {(currentState.matches("split.preview") ||
            currentState.matches("split.persist")) && (
            <SplitPreviewStep
              handleNext={() =>
                sendEvent({
                  type: "PERSIST",
                })
              }
              investmentType={currentState.context.investmentValues?.type}
              // @ts-ignore
              isLoading={currentState.matches("split.persist")}
              splitValues={currentState.context.splitValues || {}}
            />
          )}

          {currentState.matches("split.ratioAndDate") && (
            <RatioAndDateStep
              context={currentState.context}
              handleNext={(splitValues: SplitValues) =>
                sendEvent({
                  type: "PREVIEW",
                  splitValues,
                })
              }
            />
          )}

          {currentState.matches("targetInvestment.search") && (
            <SearchSymbolStep
              context={currentState.context}
              handleNext={(symbol) =>
                sendEvent({
                  type: "INIT_FROM_SYMBOL",
                  symbol,
                })
              }
              onOtherClick={handleOtherClick}
            />
          )}

          {currentState.matches("targetInvestment.manual") && (
            <NameAndDepotStep
              handleNext={(
                depotId: number,
                investmentValues: InvestmentValues
              ) =>
                sendEvent({
                  type: "INVESTMENT_SELECTED",
                  investmentValues,
                })
              }
              context={currentState.context}
              isExchangeOrCarveOut
            />
          )}

          {(currentState.matches("priceAndDate") ||
            currentState.matches("validateBooking")) && (
            <PriceAndDateStep
              handleNext={(bookingValues: BookingValues) =>
                sendEvent({
                  type: "VALIDATE_BOOKING",
                  bookingValues,
                })
              }
              context={currentState.context}
              // @ts-ignore
              isLoading={currentState.matches("validateBooking")}
            />
          )}

          {(currentState.matches("summary") ||
            currentState.matches("persist")) && (
            <SummaryStep
              handleNext={() =>
                sendEvent({
                  type: "PERSIST",
                })
              }
              context={currentState.context}
              // @ts-ignore
              isLoading={currentState.matches("persist")}
            />
          )}

          {currentState.matches("persisted") && onClose()}

          {currentState.matches("somethingWrong") && <SomethingWrongStep />}
        </>
      </Box>
    </Modal>
  );
};

export const NewTransactionModal = (props: Props) => {
  if (!props.isOpen) return null;

  return <NewTransactionModalContainer {...props} />;
};
