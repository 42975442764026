interface Account {
  id: number;
  type?: string;
  typeFromBank?: string;
  bankConnectionType?: string;
}

interface GroupedIdsByType {
  depotIds: number[];
  cashIds: number[];
  creditCardIds: number[];
}

export const useAccountIdsByTypes = (
  accounts: Account[] = []
): GroupedIdsByType => {
  return accounts.reduce(
    (acc, val) => {
      if (val.typeFromBank === "CREDIT_CARD") {
        return { ...acc, creditCardIds: [...acc.creditCardIds, val.id] };
      }
      if (val.type === "01_depot" || val.type === "05_material_assets") {
        return { ...acc, depotIds: [...acc.depotIds, val.id] };
      }
      if (val.type === "02_cash") {
        return { ...acc, cashIds: [...acc.cashIds, val.id] };
      }

      return acc;
    },
    {
      depotIds: [],
      cashIds: [],
      creditCardIds: [],
    } as GroupedIdsByType
  );
};
