import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@components";

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const Footer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isOpenedOnWizardPage",
})<{ isOpenedOnWizardPage: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(8)};
  display: flex;
  flex-direction: row-reverse;
  gap: ${({ theme }) => theme.spacing(0)};
  justify-content: ${({ isOpenedOnWizardPage }) =>
    isOpenedOnWizardPage ? "flex-end" : "space-between"};
`;

export interface Props {
  title?: string;
  subtitle?: ReactNode;
  testid?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  onNextDisabled?: boolean;
  nextLabel?: string;
  children: ReactNode;
  maxWidth?: number;
  chart?: ReactNode;
  isLoading?: boolean;
}

export const StepContainer = ({
  title,
  subtitle,
  testid,
  onPrevious,
  onNext,
  onNextDisabled = false,
  nextLabel,
  children,
  maxWidth = 700,
  chart,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isOpenedOnWizardPage = location.pathname.includes("wizard");
  const nextBtnTitle = () => {
    if (nextLabel) return nextLabel;
    if (isOpenedOnWizardPage) return t("navigation.next");
    return t("navigation.save");
  };

  return (
    <Container
      data-testid={testid ? testid : "step-container"}
      maxWidth={maxWidth}
    >
      <Header>
        {title && (
          <Typography variant="h4" component="h1" mb={subtitle ? 2 : 0}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Header>

      {children}
      {chart}

      <Footer isOpenedOnWizardPage={isOpenedOnWizardPage}>
        {onNext && (
          <StyledLoadingButton
            disabled={isLoading || onNextDisabled}
            onClick={onNext}
            color="primary"
            variant="contained"
            disableElevation
            data-testid="next"
            isLoading={isLoading}
          >
            {nextBtnTitle()}
          </StyledLoadingButton>
        )}
        {onPrevious && (
          <StyledLoadingButton
            color="secondary"
            variant="contained"
            onClick={onPrevious}
            data-testid="actions-previous"
          >
            {t("navigation.previous")}
          </StyledLoadingButton>
        )}
      </Footer>
    </Container>
  );
};
