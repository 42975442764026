import React from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Industry, updateInvestment, useAuthMutation } from "@api";
import { IndustrySelector } from "@components";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const IndustrySelectorWithInvestmentUpdate = ({
  investmentId,
  industry,
}: {
  investmentId: number;
  industry?: Industry;
}) => {
  const { t } = useTranslation();
  const {
    mutate: update,
    isError,
    error,
    isLoading,
  } = useAuthMutation(updateInvestment);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = (industry: Industry | null) => {
    update(
      { id: investmentId, industryId: industry?.id || null },
      {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              CACHE_KEYS.CLASSIFICATIONS_LIST_REGION_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
            ],
          });
        },
        onSuccess: () => {
          enqueueSnackbar({
            message: t("form.successMessage"),
            variant: "success",
            autoHideDuration: 3000,
          });
        },
      }
    );
  };

  return (
    <IndustrySelector
      disabled={isLoading}
      defaultIndustry={industry}
      onChange={handleChange}
      isError={isError}
      errorText={(error as ApiException)?.message}
      noLabel
      sx={{
        maxWidth: isLessSm ? "none" : "250px",
        width: "100%",
        flexShrink: "0",
      }}
    />
  );
};
