import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

const FirsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  & > *:first-of-type {
    margin-right: 16px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TooltipContainer = styled(motion.div)<{ withShadow: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.background.tooltipChart};
  box-shadow: ${({ theme, withShadow }) =>
    withShadow ? theme.shadows[1] : "none"};
  padding: 14px 18px 13px 20px;
  align-self: flex-start;
  min-width: 247px;
`;

interface Props {
  firstRow: ReactNode;
  withShadow: boolean;
  children: ReactNode;
}

export const CustomTooltipContainer = React.forwardRef<
  HTMLDivElement,
  Partial<Props>
>(({ firstRow, children, withShadow = false }, ref) => (
  <TooltipContainer ref={ref} layout withShadow={withShadow}>
    {firstRow && <FirsRow>{firstRow}</FirsRow>}
    <Row>{children}</Row>
  </TooltipContainer>
));
