import React, { useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingProgressBar } from "@components";
import { useFocus } from "../../hooks/useFocus";
import { Question } from "./Question";
import { StepContainer } from "./StepContainer";

export interface AutocompleteStepProps<T> {
  onPrevious: () => void;
  stepNumber: number;
  isFirst: boolean;
  onNext: (value?: T) => void;
  defaultValue?: T;
  validationError?: ValidationError;
  focus: boolean;
}

type InternalProps<T> = AutocompleteStepProps<T> & {
  testId?: string;
  question: string;
  placeholder: string;
  id?: string;
  options: T[];
  loading?: boolean;
  getOptionLabel?: (option: T) => string;
};

export const AutocompleteStep = <T extends any>({
  stepNumber,
  testId,
  question,
  onNext,
  onPrevious,
  isFirst,
  defaultValue,
  validationError,
  focus,
  placeholder,
  options,
  loading = false,
  id = "wizard-select-input",
  getOptionLabel,
}: InternalProps<T>) => {
  const [value, setValue] = useState<T | null>(defaultValue || null);
  const inputEl = useFocus(focus);

  return (
    <StepContainer
      testId={testId}
      onNext={() => onNext(value || undefined)}
      onPrevious={onPrevious}
      isFirst={isFirst}
      dependency={value}
    >
      <Question htmlFor={id} stepNumber={stepNumber} question={question} />

      {loading ? (
        <LoadingProgressBar />
      ) : (
        <Autocomplete
          id={id}
          getOptionLabel={getOptionLabel}
          loading={loading}
          value={value}
          fullWidth
          style={{ maxWidth: "580px" }}
          options={options}
          autoHighlight
          onChange={(_: any, value: T | null) => {
            setValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              variant="outlined"
              placeholder={placeholder}
              error={!!validationError}
              helperText={validationError?.message}
              inputRef={inputEl}
              inputProps={{ ...params.inputProps, "data-testid": "input" }}
              InputProps={{
                ...params.InputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    </StepContainer>
  );
};
