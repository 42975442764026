import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  Security,
  FinancialsResponse,
  StatisticResponse,
  DividendPerYearResponse,
  MarketCapResponse,
} from "../model";

export const loadFundamental = async (
  token: string | undefined,
  isin: string
) => {
  const response = await axiosInstanceV1.get<Security>(
    `/fundamentals/${isin}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const loadMarketCap = async (
  token: string | undefined,
  isin: string,
  quoteProvider: string,
  tickerSymbol: string,
  currency: string
) => {
  const response = await axiosInstanceV1.get<MarketCapResponse>(
    `/fundamentals/${isin}/marketCapitalization`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { quoteProvider, tickerSymbol, convertToCurrency: currency },
    }
  );

  return response.data;
};

export const loadFinancials = async (
  token: string | undefined,
  isin: string,
  from?: number,
  to?: number
) => {
  const response = await axiosInstanceV1.get<FinancialsResponse>(
    `/fundamentals/${isin}/financials`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { from, to },
    }
  );
  return response.data;
};

export const loadStatistics = async (
  token: string | undefined,
  isin: string,
  from?: string,
  to?: string
) => {
  const response = await axiosInstanceV1.get<StatisticResponse>(
    `/fundamentals/${isin}/statistics`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { interval: "year", from, to },
    }
  );

  return response.data.statistics;
};

export const loadDividendsPerYear = async (
  token: string | undefined,
  isin: string,
  currency?: string
) => {
  const response = await axiosInstanceV1.get<DividendPerYearResponse>(
    `/dividends/perYearTotal`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        predictNumberOfYears: 2,
        isin,
        currency,
      },
    }
  );
  return response.data.dividendsPerYear;
};

export const loadDividendsPerYerForInvestment = async (
  token: string | undefined,
  investmentId: number
) => {
  const response = await axiosInstanceV1.get<DividendPerYearResponse>(
    `/dividends/perYearTotal`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { predictNumberOfYears: 2, investmentId },
    }
  );
  return response.data.dividendsPerYear;
};
