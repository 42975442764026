import { UseMutationOptions } from "react-query";
import {
  BookingsApiValiateCarveOutRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const valiateCarveOut = async (
  token: string | undefined,
  params: BookingsApiValiateCarveOutRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.valiateCarveOut(params);
  return data;
};

export const useValiateCarveOut = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiValiateCarveOutRequest
  >
) => {
  return useAuthMutation(valiateCarveOut, options, false);
};
