import { assign, createMachine } from "xstate";
import { DividendWizardState } from "./types/dividendWizardState";
import {
  defaultContext,
  DividendWizardContext,
} from "./types/dividendWizardContext";
import { DividendWizardEvent } from "./types/dividendWizardEvent";
import {
  loadInvestment,
  validateDividend,
  loadExchangeRate,
  saveDividends,
  loadDividends,
} from "./services";
import {
  addAxiosErrorToContextAction,
  setDividendValuesAction,
  addValidationErrorAction,
  clearValidationErrorAction,
  setExchangeRateAction,
  setValidatedParams,
  setInvestmentAction,
  setDateAction,
  findAndPrefillDividendValuesAction,
} from "./actions";

export const customDividendWizardSM = createMachine<
  DividendWizardContext,
  DividendWizardEvent,
  DividendWizardState
>(
  {
    id: "customDividendWizardSM",

    // Initial state
    initial: "idle",

    // Local context for entire machine
    context: defaultContext,

    // State definitions
    states: {
      idle: {
        on: {
          INIT_FROM_INVESTMENT: { target: "loadInvestment" },
          INIT_FROM_DIVIDEND: {
            target: "loadInvestment",
            actions: ["setDateAction"],
          },
        },
      },
      loadInvestment: {
        id: "loadInvestment",
        invoke: {
          id: "loadInvestment",
          src: loadInvestment,
        },
        on: {
          LOADED_INVESTMENT: {
            target: "loadDividends",
            actions: ["setInvestmentAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      loadDividends: {
        id: "loadDividends",
        invoke: {
          id: "loadDividends",
          src: loadDividends,
        },
        on: {
          LOADED_DIVIDENDS: {
            target: "loadExchangeRate",
            actions: ["findAndPrefillDividendValuesAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      loadExchangeRate: {
        id: "loadExchangeRate",
        invoke: {
          id: "loadExchangeRate",
          src: loadExchangeRate,
        },
        on: {
          LOADED_EXCHANGE_RATE: {
            target: "addManualDividend",
            actions: ["setExchangeRateAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      addManualDividend: {
        id: "addManualDividend",
        on: {
          VALIDATE_MANUAL_DIVIDEND: {
            target: "validateDividends",
            actions: ["clearValidationErrorAction", "setDividendValuesAction"],
          },
        },
      },
      validateDividends: {
        id: "validateDividends",
        invoke: {
          id: "validateDividend",
          src: validateDividend,
        },
        on: {
          DIVIDENDS_VALIDATED: [
            {
              target: "persist",
              actions: ["setValidatedParams"],
              cond: (context) => Boolean(context.skipSummaryStep),
            },
            {
              target: "summary",
              actions: ["setValidatedParams"],
            },
          ],
          BACK: {
            target: "addManualDividend",
            actions: ["addValidationErrorAction"],
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      summary: {
        id: "summary",
        on: {
          PERSIST: {
            target: "persist",
          },
          BACK: {
            target: "addManualDividend",
          },
        },
      },
      persist: {
        id: "persist",
        invoke: {
          id: "saveDividends",
          src: saveDividends,
        },
        on: {
          SUCCESS: {
            target: "success",
          },
          SOMETHING_WRONG: { target: "somethingWrong" },
        },
      },
      success: {
        id: "success",
        type: "final",
      },
      somethingWrong: {
        type: "final",
        id: "somethingWrong",
        entry: ["addAxiosErrorToContextAction"],
      },
    },
  },
  {
    actions: {
      setDividendValuesAction: assign(setDividendValuesAction),
      addAxiosErrorToContextAction: assign(addAxiosErrorToContextAction),
      addValidationErrorAction: assign(addValidationErrorAction),
      clearValidationErrorAction: assign(clearValidationErrorAction),
      setExchangeRateAction: assign(setExchangeRateAction),
      setValidatedParams: assign(setValidatedParams),
      setInvestmentAction: assign(setInvestmentAction),
      setDateAction: assign(setDateAction),
      findAndPrefillDividendValuesAction: assign(
        findAndPrefillDividendValuesAction
      ),
    },
  }
);
