import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { ReactComponent as SearchIcon } from "@icons/search.svg";

type SearchFieldProps = {
  onClear?: () => void;
  displayClearButton?: boolean;
} & TextFieldProps;

export const SearchField = forwardRef<HTMLDivElement, SearchFieldProps>(
  (
    {
      value,
      onClear,
      displayClearButton,
      onChange,
      placeholder,
      InputProps,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <TextField
        ref={ref}
        placeholder={
          placeholder || t("singleAccountPage.protocolTable.search.placeholder")
        }
        InputProps={{
          ...InputProps,
          endAdornment: (
            <Box display="flex" alignItems="center" gap={2}>
              {displayClearButton && onClear && !rest.disabled && (
                <IconButton
                  onClick={onClear}
                  disabled={rest.disabled}
                  size="small"
                  sx={{
                    width: "48px",
                    height: "48px",

                    "& svg": {
                      width: "22px",
                      height: "22px",
                      color: rest.disabled
                        ? theme.palette.icon.disabled
                        : theme.palette.icon.primary,
                    },
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              )}
              <Box
                color={rest.disabled ? "icon.disabled" : "icon.secondary"}
                height="20px"
              >
                <SearchIcon width="20px" height="20px" />
              </Box>
            </Box>
          ),
        }}
        id="search-field"
        autoComplete="new-password"
        value={value}
        onChange={onChange}
        {...rest}
        sx={{
          width: "300px",
          [theme.breakpoints.down(750)]: { width: "100%" },
          ...rest.sx,
        }}
      />
    );
  }
);
