import React from "react";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import styled from "@emotion/styled";
import { differenceInCalendarYears, getYear, parseISO } from "date-fns";
import { Box } from "@mui/material";
import { DataBlockLayout } from "@components/common/dataBlock";
import { DividendChartBar, LinearProgressChart } from "@components";
import { toPercent } from "@helpers";
import { LoadingPlaceHolder } from "@components/LoadingPlaceHolder";
import { DateValuePair } from "@generated/apiv1";

interface Props {
  isLoading?: boolean;
  historicQuotes?: DateValuePair[];
}

const StyledContentBlock = styled(DataBlockLayout)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
  },
}));

export const HistoricalQuotesBaseChart = ({
  historicQuotes,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const chartData = useBuildChartData(historicQuotes);
  const formatData = () => {
    const maxValue = Math.max(...chartData.map((obj) => Math.abs(obj.value)));

    return chartData.map((obj) => ({
      ...obj,
      percentage: (Math.abs(obj.value) / maxValue) * 100,
    }));
  };

  const formattedData = formatData();

  const title = t("fundamentals.metrics.historicQuotesPercent.normal");
  const desc = t("fundamentals.metrics.historicQuotesPercent.desc");

  if (isLoading) {
    return <LoadingPlaceHolder />;
  }

  return (
    <StyledContentBlock
      title={title}
      isLoading={isLoading}
      toolTipData={{ desc1: desc }}
    >
      <Box width="100%" height="100%">
        <LinearProgressChart
          chartData={formattedData}
          component={DividendChartBar}
          type="historicQuotes"
        />
      </Box>
    </StyledContentBlock>
  );
};

const useBuildChartData = (historicQuotes?: DateValuePair[]) => {
  const { t } = useTranslation();

  const sorted = sortBy(historicQuotes, ["date"]).reverse();
  return (
    sorted
      .filter(
        ({ date, value }) =>
          getYear(parseISO(date!)) !== getYear(new Date()) &&
          value !== undefined
      )
      .map((quote) => {
        const year = differenceInCalendarYears(
          new Date(),
          parseISO(quote.date!)
        );
        const percentage = Math.round(quote.value! * 10000) / 100;
        return {
          category: t("fundamentals.year", { count: year }),
          value: percentage,
          valueFormatted: toPercent(percentage / 100),
        };
      }) || []
  );
};
