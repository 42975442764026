import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { UpdateTag } from "@components/BankSelect/components/UpdateTag";
import { BankImage } from "@components/BankSelect/components/BankImage";
import { Logo } from "@components";
import { WhitelistBank } from "@constants";

export const BankOptionSmall = ({
  bank,
  onClick,
}: {
  bank: WhitelistBank;
  onClick: (bank: WhitelistBank) => void;
}) => {
  const theme = useTheme();

  return (
    <Grid item sm={6} md={4} xs={12}>
      <Box
        sx={{
          height: "64px",
          cursor: "pointer",
          border: `1px solid ${theme.palette.border.secondary}`,
          borderRadius: `${theme.shape.borderRadius}px`,

          "&:hover": {
            backgroundColor: theme.palette.background.subtle,
          },
        }}
      >
        <Box
          p={3}
          display="flex"
          alignItems="center"
          gap={2}
          onClick={() => onClick(bank)}
          data-testid={`favourite-banks-${bank.title}`}
        >
          <BankImage>
            {bank.img ? (
              <img src={bank.img} alt={bank.img} />
            ) : (
              <Logo variant="asset" width="100%" height="100%" />
            )}
          </BankImage>
          <Box>
            <Typography variant="body2" noWrap mb={1} lineHeight="16px">
              {bank.title}
            </Typography>
            <UpdateTag update={bank.update} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
