import { UseQueryOptions } from "react-query";
import {
  AccountsApiBalancesRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const balances = async (
  token: string | undefined,
  params: AccountsApiBalancesRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.balances(params);
  return data;
};

export const useBalances = (
  params: AccountsApiBalancesRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_BALANCES, params],
    ({ token }) => balances(token, params),
    options
  );
};
