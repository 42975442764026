import { Box, Button, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as EditBoxIcon } from "@icons/edit-box.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

type Props = {
  onEditItemClick?: () => void;
  onDeleteItemClick: () => void;
  editItemText: string;
  deleteItemText: string;
};
export const ActionsButton = ({
  onEditItemClick,
  onDeleteItemClick,
  editItemText,
  deleteItemText,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => setMenuEl(null);

  return (
    <>
      <Button
        variant="text"
        size="small"
        startIcon={<EditBoxIcon color={theme.palette.icon.active} />}
        color="secondary"
        onClick={handleOpenMenuClick}
      >
        {t("singleAccount.edit")}
      </Button>
      <Menu
        open={Boolean(menuEl)}
        anchorEl={menuEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { marginTop: 0 },
        }}
        disablePortal
      >
        {onEditItemClick && (
          <MenuItem onClick={onEditItemClick}>
            <Box display="flex" alignItems="center" gap={3}>
              <EditIcon
                width="24px"
                height="24px"
                color={theme.palette.icon.primary}
              />

              {editItemText}
            </Box>
          </MenuItem>
        )}
        <MenuItem onClick={onDeleteItemClick}>
          <Box
            display="flex"
            alignItems="center"
            color="error.main"
            gap={3}
            sx={{
              "& svg": {
                flexShrink: 0,
                color: theme.palette.icon.error,
              },
            }}
          >
            <DeleteIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.error}
            />

            {deleteItemText}
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};
