export interface StoredSortParams {
  sortField: string;
  sortDirection: TableSortDirection;
}

export const getStoredSortParams = (
  key: string
): StoredSortParams | undefined => {
  const item = localStorage.getItem(key);
  if (!item) return;
  return JSON.parse(item);
};

export const setStoredSortParams = (
  sortField: string,
  sortDirection: string,
  key = "sort_params"
) => {
  const object = JSON.stringify({ sortField, sortDirection });
  localStorage.setItem(key, object);
};
