import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import UndoIcon from "@mui/icons-material/Undo";
import { unionBy } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { toGermanNumberFormat } from "@helpers";

interface Investment {
  id: number;
  selected?: boolean;
  name: string;
  numberOfLots: number;
  tickerSymbol: string;
}

interface TransferInvestment {
  id: number;
  numberOfLots?: number;
}

const TableContainer = styled(MuiTableContainer)`
  background-color: ${({ theme }) => theme.palette.common.white};
  max-height: 300px;
` as typeof MuiTableContainer;

export const InvestmentsTable = ({
  investments,
  onChange,
  defaultValue = [],
}: {
  investments: Investment[];
  defaultValue?: TransferInvestment[];
  onChange?: (transferInvestments: TransferInvestment[]) => void;
}) => {
  const { t } = useTranslation();
  const firstUpdate = useRef(true);
  const rowCount = investments.length;

  const [selected, setSelected] = useState<number[]>(
    defaultValue.map(({ id }) => id)
  );

  const [transferAmounts, setTransferAmounts] = useState<TransferInvestment[]>(
    unionBy(
      defaultValue,
      investments.map(({ id, numberOfLots }) => ({ id, numberOfLots })),
      "id"
    )
  );

  const selectedTransferAmounts = transferAmounts.filter(({ id }) =>
    selected.includes(id)
  );

  const selectedTransferAmountsDep = JSON.stringify(selectedTransferAmounts);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!onChange) return;
    onChange(selectedTransferAmounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransferAmountsDep]);

  const numSelected = selected.length;
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allSelected = investments.map((investment) => investment.id);
      setSelected(allSelected);

      onChange &&
        onChange(transferAmounts.filter(({ id }) => allSelected.includes(id)));
      return;
    }
    setSelected([]);
    onChange && onChange([]);
  };

  const handleSelectInvestment = (checked: boolean, id: number) => {
    setSelected((s) => (checked ? [...s, id] : s.filter((i) => i !== id)));
  };

  const handleValueChange = (
    value: number | undefined,
    investmentId: number
  ) => {
    setTransferAmounts((current) => {
      const transferAmountsWithoutCurrent = current.filter(
        ({ id }) => id !== investmentId
      );
      return [
        ...transferAmountsWithoutCurrent,
        { id: investmentId, numberOfLots: value },
      ];
    });
  };

  const handleStringValueChange = (
    stringValue: string | undefined | null,
    investmentId: number
  ) => {
    if (!stringValue) {
      handleValueChange(undefined, investmentId);
      return;
    }
    const value = parseFloat(stringValue);
    if (isNaN(value)) {
      return;
    }
    handleValueChange(value, investmentId);
  };

  const getInvestmentNumberOfLots = (investmentId: number) =>
    transferAmounts.find(({ id }) => id === investmentId)?.numberOfLots;

  const isValueValid = (investmentId: number) => {
    const value = getInvestmentNumberOfLots(investmentId);
    if (value && value <= 0) {
      return true;
    }
    const investment = investments.find(({ id }) => id === investmentId);
    if (value && investment && value > investment?.numberOfLots) {
      return true;
    }
    return false;
  };

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(event) => handleSelectAll(event.target.checked)}
                inputProps={
                  {
                    "data-testid": "select-all-checkbox",
                  } as any
                }
              />
            </TableCell>
            <TableCell>
              <Typography>
                <strong>
                  {t("depotTransfer.investmentsTable.investments")}
                </strong>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>
                <strong>
                  {t("depotTransfer.investmentsTable.totalNumberOfLots")}
                </strong>
              </Typography>
            </TableCell>
            <TableCell align="right" style={{ maxWidth: "60px" }}>
              <Typography>
                <strong>
                  {t("depotTransfer.investmentsTable.transferNumberOfLots")}
                </strong>
              </Typography>
            </TableCell>
            <TableCell style={{ width: "50px" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => (
            <TableRow
              hover
              key={investment.id}
              data-testid={`investment-row-${investment.tickerSymbol}`}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected.includes(investment.id)}
                  onChange={(event) =>
                    handleSelectInvestment(event.target.checked, investment.id)
                  }
                  inputProps={
                    {
                      "data-testid": "select-investment-checkbox",
                    } as any
                  }
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{investment.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  {toGermanNumberFormat(investment.numberOfLots)}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ maxWidth: "100px" }}>
                <TextField
                  error={isValueValid(investment.id)}
                  helperText={
                    isValueValid(investment.id) ? "incorrect" : undefined
                  }
                  disabled={!selected.includes(investment.id)}
                  id="standard-number"
                  onChange={(event) => {
                    handleStringValueChange(event.target.value, investment.id);
                  }}
                  type="number"
                  value={getInvestmentNumberOfLots(investment.id) || ""}
                  inputProps={{
                    style: { textAlign: "right" },
                    min: 0,
                    max: investment.numberOfLots,
                    "data-testid": "number-of-lots-input",
                  }}
                  style={{ width: "100%" }}
                />
              </TableCell>
              <TableCell align="left" style={{ width: "50px" }} padding="none">
                {selected.includes(investment.id) &&
                  investment.numberOfLots !==
                    getInvestmentNumberOfLots(investment.id) && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleValueChange(
                          investment.numberOfLots,
                          investment.id
                        )
                      }
                      data-testid="revert-button"
                    >
                      <UndoIcon />
                    </IconButton>
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
