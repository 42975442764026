import { UseMutationOptions } from "react-query";
import {
  PerformanceApiPerformanceByDepotsRequest,
  CashFlowAndPerformanceStatisticsList,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const performanceByDepots = async (
  token: string | undefined,
  params: PerformanceApiPerformanceByDepotsRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.performanceByDepots(params);
  return data;
};

export const usePerformanceByDepots = (
  options?: UseMutationOptions<
    CashFlowAndPerformanceStatisticsList,
    AxiosApiError,
    PerformanceApiPerformanceByDepotsRequest
  >
) => {
  return useAuthMutation(performanceByDepots, options, false);
};
