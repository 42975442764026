import { UseMutationOptions } from "react-query";
import { UserProfilesApiSetUserProfileImageRequest } from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const setUserProfileImage = async (
  token: string | undefined,
  params: UserProfilesApiSetUserProfileImageRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.setUserProfileImage(params);
  return data;
};

export const useSetUserProfileImage = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UserProfilesApiSetUserProfileImageRequest
  >
) => {
  return useAuthMutation(setUserProfileImage, options, false);
};
