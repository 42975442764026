/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ContactUsParams } from '../model';
// @ts-ignore
import { EmailParams } from '../model';
/**
 * MailingApi - axios parameter creator
 * @export
 */
export const MailingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a contact email to Rentablo. This endpoint requires a valid captcha challenge in attribute                      challengeToken. Users will be added to the B2B mailing list if they requested so.
         * @param {ContactUsParams} body The contact us params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUs: async (body: ContactUsParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('contactUs', 'body', body)
            const localVarPath = `/v1/mailing/contactUs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send out a contact email to a friend. Requires captcha validation.
         * @param {number} id ID of the user we want to contact.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUserId: async (id: number, body: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contactUserId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('contactUserId', 'body', body)
            const localVarPath = `/v1/mailing/contactUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Cashback recommendation Email to a friend. Requires captcha validation.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendCashback: async (body: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('recommendCashback', 'body', body)
            const localVarPath = `/v1/mailing/recommendCashback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a \'recommend us\' email to the given email address. Requires captcha validation.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendUs: async (body: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('recommendUs', 'body', body)
            const localVarPath = `/v1/mailing/recommendUs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailingApi - functional programming interface
 * @export
 */
export const MailingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a contact email to Rentablo. This endpoint requires a valid captcha challenge in attribute                      challengeToken. Users will be added to the B2B mailing list if they requested so.
         * @param {ContactUsParams} body The contact us params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactUs(body: ContactUsParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactUs(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MailingApi.contactUs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send out a contact email to a friend. Requires captcha validation.
         * @param {number} id ID of the user we want to contact.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactUserId(id: number, body: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactUserId(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MailingApi.contactUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send a Cashback recommendation Email to a friend. Requires captcha validation.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recommendCashback(body: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recommendCashback(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MailingApi.recommendCashback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send a \'recommend us\' email to the given email address. Requires captcha validation.
         * @param {EmailParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recommendUs(body: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recommendUs(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MailingApi.recommendUs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MailingApi - factory interface
 * @export
 */
export const MailingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailingApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a contact email to Rentablo. This endpoint requires a valid captcha challenge in attribute                      challengeToken. Users will be added to the B2B mailing list if they requested so.
         * @param {MailingApiContactUsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUs(requestParameters: MailingApiContactUsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contactUs(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send out a contact email to a friend. Requires captcha validation.
         * @param {MailingApiContactUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUserId(requestParameters: MailingApiContactUserIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contactUserId(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Cashback recommendation Email to a friend. Requires captcha validation.
         * @param {MailingApiRecommendCashbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendCashback(requestParameters: MailingApiRecommendCashbackRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.recommendCashback(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a \'recommend us\' email to the given email address. Requires captcha validation.
         * @param {MailingApiRecommendUsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recommendUs(requestParameters: MailingApiRecommendUsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.recommendUs(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contactUs operation in MailingApi.
 * @export
 * @interface MailingApiContactUsRequest
 */
export interface MailingApiContactUsRequest {
    /**
     * The contact us params
     * @type {ContactUsParams}
     * @memberof MailingApiContactUs
     */
    readonly body: ContactUsParams
}

/**
 * Request parameters for contactUserId operation in MailingApi.
 * @export
 * @interface MailingApiContactUserIdRequest
 */
export interface MailingApiContactUserIdRequest {
    /**
     * ID of the user we want to contact.
     * @type {number}
     * @memberof MailingApiContactUserId
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {EmailParams}
     * @memberof MailingApiContactUserId
     */
    readonly body: EmailParams
}

/**
 * Request parameters for recommendCashback operation in MailingApi.
 * @export
 * @interface MailingApiRecommendCashbackRequest
 */
export interface MailingApiRecommendCashbackRequest {
    /**
     * The create or update parameters
     * @type {EmailParams}
     * @memberof MailingApiRecommendCashback
     */
    readonly body: EmailParams
}

/**
 * Request parameters for recommendUs operation in MailingApi.
 * @export
 * @interface MailingApiRecommendUsRequest
 */
export interface MailingApiRecommendUsRequest {
    /**
     * The create or update parameters
     * @type {EmailParams}
     * @memberof MailingApiRecommendUs
     */
    readonly body: EmailParams
}

/**
 * MailingApi - object-oriented interface
 * @export
 * @class MailingApi
 * @extends {BaseAPI}
 */
export class MailingApi extends BaseAPI {
    /**
     * 
     * @summary Send a contact email to Rentablo. This endpoint requires a valid captcha challenge in attribute                      challengeToken. Users will be added to the B2B mailing list if they requested so.
     * @param {MailingApiContactUsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public contactUs(requestParameters: MailingApiContactUsRequest, options?: RawAxiosRequestConfig) {
        return MailingApiFp(this.configuration).contactUs(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send out a contact email to a friend. Requires captcha validation.
     * @param {MailingApiContactUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public contactUserId(requestParameters: MailingApiContactUserIdRequest, options?: RawAxiosRequestConfig) {
        return MailingApiFp(this.configuration).contactUserId(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Cashback recommendation Email to a friend. Requires captcha validation.
     * @param {MailingApiRecommendCashbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public recommendCashback(requestParameters: MailingApiRecommendCashbackRequest, options?: RawAxiosRequestConfig) {
        return MailingApiFp(this.configuration).recommendCashback(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a \'recommend us\' email to the given email address. Requires captcha validation.
     * @param {MailingApiRecommendUsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public recommendUs(requestParameters: MailingApiRecommendUsRequest, options?: RawAxiosRequestConfig) {
        return MailingApiFp(this.configuration).recommendUs(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

