import { useRef } from "react";
import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { useToken } from "./useToken";

// @ts-ignore
export interface SecureQueryFunctionContext<
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = any
> extends QueryFunctionContext {
  queryKey: TQueryKey;
  pageParam?: TPageParam;
  token: string;
  ff_token: string;
}

export type SecureQueryFunction<T = unknown> = (
  context: SecureQueryFunctionContext<any>
) => T | Promise<T>;

export function useAuthQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  queryKey: QueryKey,
  queryFn: SecureQueryFunction<TQueryFnData> & {},
  options?: UseQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> {
  const isEnabled = options?.enabled === undefined ? true : !!options?.enabled;

  const { data: token } = useToken({
    enabled: options?.enabled,
  });

  const accessTokenRef = useRef<string>();
  accessTokenRef.current = token?.access_token;

  const ffAccessTokenRef = useRef<string>();
  ffAccessTokenRef.current = token?.ff_access_token;

  return useQuery(
    [
      ...(typeof queryKey === "string" ? [queryKey] : queryKey),
      accessTokenRef.current,
    ] as QueryKey,
    (context) =>
      queryFn({
        ...context,
        token: accessTokenRef.current!,
        ff_token: ffAccessTokenRef.current!,
      }),
    {
      ...options,
      enabled: isEnabled,
    }
  );
}
