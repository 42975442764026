import { UseMutationOptions } from "react-query";
import { OtpProvisioningData } from "@generated/user";

import { buildOtpApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const initiateOtpSetup = async (token: string | undefined) => {
  const apiInstance = buildOtpApi(token);
  const { data } = await apiInstance.initiateOtpSetup();
  return data;
};

export const useInitiateOtpSetup = (
  options?: UseMutationOptions<OtpProvisioningData, AxiosApiError, unknown>
) => {
  return useAuthMutation(initiateOtpSetup, options, true);
};
