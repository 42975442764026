import { UseQueryOptions } from "react-query";
import { TransactionsApiIdDetectIsinOrWknRequest, Tag } from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const idDetectIsinOrWkn = async (
  token: string | undefined,
  params: TransactionsApiIdDetectIsinOrWknRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.idDetectIsinOrWkn(params);
  return data;
};

export const useIdDetectIsinOrWkn = (
  params: TransactionsApiIdDetectIsinOrWknRequest,
  options?: UseQueryOptions<Tag, AxiosApiError, Tag>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TRANSACTIONS_ID_DETECT_ISIN_OR_WKN, params],
    ({ token }) => idDetectIsinOrWkn(token, params),
    options
  );
};
