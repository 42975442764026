import React from "react";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SortableTableHeader, TableBodyCell } from "@components/Table";
import { NoAccountsStub } from "@components/NoAccountsStub";
import { LoadingPlaceHolder } from "@components";
import { InvestmentsChartDataStatistic } from "@features/dashboard/useInvestmentsChartDataTotal";
import { toCurrency, toPercent } from "@helpers";
import { BlurredTypography } from "@components/BlurredTypography";
import { AllocationChart } from "@components/Chart/AllocationChart";
import { ReactComponent as CashIcon } from "@icons/coins.svg";
import { Investment } from "@generated/apiv1";

const Image = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: 40,
  height: 40,
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.tertiary.light,
  display: "flex",
  color: theme.palette.common.white,
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
}));

type Props = {
  investments?: Investment[];
  statistics?: InvestmentsChartDataStatistic;
  isLoading?: boolean;
  isFetched?: boolean;
};

export const CashTable = ({
  statistics,
  investments,
  isLoading,
  isFetched,
}: Props) => {
  const { t } = useTranslation();
  const investmentsValue = (investments ?? []).reduce(
    (acc, investment) => acc + investment.currentMarketValueAC,
    0
  );
  const cashValue = Math.abs(statistics?.cashBalance ?? 0);
  const allocationPercent = cashValue / (investmentsValue + cashValue || 1);

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeader
              sortByName="name"
              text={t("dashboardPage.dashboard.investmentsTable.columns.name")}
              width="40%"
            />

            <SortableTableHeader
              sortByName="currentMarketValueAC"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.totalValue"
              )}
              width="20%"
              align="right"
            />

            <SortableTableHeader text="" sortByName="" width="20%" />

            <SortableTableHeader
              sortByName="allocation"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.allocation"
              )}
              width="20%"
              align="right"
            />
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBody>
            <TableRow>
              <TableBodyCell colSpan={4} sx={{ borderBottom: "none" }}>
                {isFetched ? <NoAccountsStub py={5} /> : <LoadingPlaceHolder />}
              </TableBodyCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow data-testid="cash-table-row">
              <TableBodyCell width="40%">
                <Box display="flex" alignItems="center" gap={3}>
                  <Image>
                    <CashIcon />
                  </Image>
                  <Typography fontWeight={400} variant="body2">
                    {t("dashboardPage.dashboard.mainDataSection.cash")}
                  </Typography>
                </Box>
              </TableBodyCell>

              <TableBodyCell width="20%">
                <BlurredTypography
                  variant="body2"
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {toCurrency(statistics?.cashBalance || 0)}
                </BlurredTypography>
              </TableBodyCell>
              <TableBodyCell align="right" width="20%">
                <Box />
              </TableBodyCell>
              <TableBodyCell align="right" width="20%">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <AllocationChart percent={allocationPercent} />
                  <Box width={60}>
                    <Typography variant="body2">
                      {toPercent(allocationPercent)}
                    </Typography>
                  </Box>
                </Box>
              </TableBodyCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Box>
  );
};
