import { UseMutationOptions } from "react-query";
import {
  PlugAndPlayApiPostAnswersRequest,
  CreateAnswerResponse,
} from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const postAnswers = async (
  token: string | undefined,
  params: PlugAndPlayApiPostAnswersRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.postAnswers(params);
  return data;
};

export const usePostAnswers = (
  options?: UseMutationOptions<
    CreateAnswerResponse,
    AxiosApiError,
    PlugAndPlayApiPostAnswersRequest
  >
) => {
  return useAuthMutation(postAnswers, options, false);
};
