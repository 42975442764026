/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BillingCycle } from '../model';
// @ts-ignore
import { BillingCycleList } from '../model';
// @ts-ignore
import { CommissionStatisticList } from '../model';
// @ts-ignore
import { CreateBillingCycleParams } from '../model';
// @ts-ignore
import { Invoice } from '../model';
// @ts-ignore
import { InvoiceList } from '../model';
// @ts-ignore
import { TaxationEstimate } from '../model';
// @ts-ignore
import { TaxationEstimateList } from '../model';
// @ts-ignore
import { UpdateTaxationEstimateParams } from '../model';
/**
 * BillingCyclesApi - axios parameter creator
 * @export
 */
export const BillingCyclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get payout statistics for all billing cycles over time
         * @param {string} [mandatorPublicId] Only display data for the given mandator.
         * @param {boolean} [monthlyValues] Retrieve monthly instead of quarterly values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclePayoutStatistics: async (mandatorPublicId?: string, monthlyValues?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billingCycles/payoutStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }

            if (monthlyValues !== undefined) {
                localVarQueryParameter['monthlyValues'] = monthlyValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or export the billing cycle with the given ID
         * @param {number} id Retrieve the given billing cycle
         * @param {string} [format] Retrieve billing cycle data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;. A PDF export will only be allowed if there are actually any executed                              transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclesId: async (id: number, format?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingCyclesId', 'id', id)
            const localVarPath = `/v1/billingCycles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the billing invoice for all account holders enclosed by the logged in user in the given                      billing cycle.
         * @param {number} id Retrieve invoicing data for the given billing cycle
         * @param {string} [format] Retrieve invoicing data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclesIdInvoices: async (id: number, format?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingCyclesIdInvoices', 'id', id)
            const localVarPath = `/v1/billingCycles/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new billing cycle with the given start and end date
         * @param {CreateBillingCycleParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingCycle: async (body: CreateBillingCycleParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createBillingCycle', 'body', body)
            const localVarPath = `/v1/billingCycles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the billing cycle with the given ID
         * @param {number} id Delete the given billing cycle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingCycle: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBillingCycle', 'id', id)
            const localVarPath = `/v1/billingCycles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get billing invoices for all account holders enclosed by the logged in user. This includes all data                      from previous billing cycles and data that is not billed yet. Each billing cycle for each account                      holder and not yet billed data for each account holder will result in one                      AccountHolderCommission record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billingCycles/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the amount of kickback (cashback transfers) that may be relevant for a declaration of taxes.                      The amount is based on the commissions payed out to the customer, the according Rentablo                      administrative fee and the tax exempt rations defined by the German legislator                      (Teilfreistellungsbetraege).<br>                      <b>Dislaimer:</b> Rentablo does not provide any tax advising services. All data generated by this                      endpoint is thus delivered \'as is\', without any liability and without any guarantee for                      compliance or correctness of the produced data.
         * @param {number} accountHolderId Retrieve a taxation estimate for the given account holder
         * @param {string} [estimateFrom] Get the taxation estimate starting from the given date; format is yyyy-MM-dd (US Format).                              The default value is the start of last year; an usually this value will be the beginning                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
         * @param {string} [estimateTo] Get the taxation estimate ending with the given date; format is yyyy-MM-dd (US Format).                              The default value is the end of last year; an usually this value will be the end                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxationEstimate: async (accountHolderId: number, estimateFrom?: string, estimateTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountHolderId' is not null or undefined
            assertParamExists('getTaxationEstimate', 'accountHolderId', accountHolderId)
            const localVarPath = `/v1/billingCycles/taxation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountHolderId !== undefined) {
                localVarQueryParameter['accountHolderId'] = accountHolderId;
            }

            if (estimateFrom !== undefined) {
                localVarQueryParameter['estimateFrom'] = estimateFrom;
            }

            if (estimateTo !== undefined) {
                localVarQueryParameter['estimateTo'] = estimateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of billing cycles
         * @param {Array<number>} [id] Retrieve the given billing cycles
         * @param {string} [startDate] Only retrieve billing cycles with start date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [endDate] Only retrieve billing cycles with end date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBillingCycles: async (id?: Array<number>, startDate?: string, endDate?: string, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billingCycles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the taxation category for a given fund (identified by ISIN). The update is persisted, so                      that a user needs to update the category only once; future queries will automatically use the                      value chosen by the user.
         * @param {UpdateTaxationEstimateParams} body The taxation estimate update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaxationCategory: async (body: UpdateTaxationEstimateParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTaxationCategory', 'body', body)
            const localVarPath = `/v1/billingCycles/taxation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingCyclesApi - functional programming interface
 * @export
 */
export const BillingCyclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingCyclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get payout statistics for all billing cycles over time
         * @param {string} [mandatorPublicId] Only display data for the given mandator.
         * @param {boolean} [monthlyValues] Retrieve monthly instead of quarterly values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingCyclePayoutStatistics(mandatorPublicId?: string, monthlyValues?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionStatisticList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingCyclePayoutStatistics(mandatorPublicId, monthlyValues, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.billingCyclePayoutStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or export the billing cycle with the given ID
         * @param {number} id Retrieve the given billing cycle
         * @param {string} [format] Retrieve billing cycle data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;. A PDF export will only be allowed if there are actually any executed                              transactions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingCyclesId(id: number, format?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCycle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingCyclesId(id, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.billingCyclesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the billing invoice for all account holders enclosed by the logged in user in the given                      billing cycle.
         * @param {number} id Retrieve invoicing data for the given billing cycle
         * @param {string} [format] Retrieve invoicing data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingCyclesIdInvoices(id: number, format?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingCyclesIdInvoices(id, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.billingCyclesIdInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new billing cycle with the given start and end date
         * @param {CreateBillingCycleParams} body The account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBillingCycle(body: CreateBillingCycleParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCycle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBillingCycle(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.createBillingCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the billing cycle with the given ID
         * @param {number} id Delete the given billing cycle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBillingCycle(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBillingCycle(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.deleteBillingCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get billing invoices for all account holders enclosed by the logged in user. This includes all data                      from previous billing cycles and data that is not billed yet. Each billing cycle for each account                      holder and not yet billed data for each account holder will result in one                      AccountHolderCommission record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.getInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the amount of kickback (cashback transfers) that may be relevant for a declaration of taxes.                      The amount is based on the commissions payed out to the customer, the according Rentablo                      administrative fee and the tax exempt rations defined by the German legislator                      (Teilfreistellungsbetraege).<br>                      <b>Dislaimer:</b> Rentablo does not provide any tax advising services. All data generated by this                      endpoint is thus delivered \'as is\', without any liability and without any guarantee for                      compliance or correctness of the produced data.
         * @param {number} accountHolderId Retrieve a taxation estimate for the given account holder
         * @param {string} [estimateFrom] Get the taxation estimate starting from the given date; format is yyyy-MM-dd (US Format).                              The default value is the start of last year; an usually this value will be the beginning                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
         * @param {string} [estimateTo] Get the taxation estimate ending with the given date; format is yyyy-MM-dd (US Format).                              The default value is the end of last year; an usually this value will be the end                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxationEstimate(accountHolderId: number, estimateFrom?: string, estimateTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxationEstimateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxationEstimate(accountHolderId, estimateFrom, estimateTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.getTaxationEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of billing cycles
         * @param {Array<number>} [id] Retrieve the given billing cycles
         * @param {string} [startDate] Only retrieve billing cycles with start date equal or after the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [endDate] Only retrieve billing cycles with end date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllBillingCycles(id?: Array<number>, startDate?: string, endDate?: string, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCycleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllBillingCycles(id, startDate, endDate, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.listAndSearchAllBillingCycles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the taxation category for a given fund (identified by ISIN). The update is persisted, so                      that a user needs to update the category only once; future queries will automatically use the                      value chosen by the user.
         * @param {UpdateTaxationEstimateParams} body The taxation estimate update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaxationCategory(body: UpdateTaxationEstimateParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxationEstimate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaxationCategory(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingCyclesApi.updateTaxationCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BillingCyclesApi - factory interface
 * @export
 */
export const BillingCyclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingCyclesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get payout statistics for all billing cycles over time
         * @param {BillingCyclesApiBillingCyclePayoutStatisticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclePayoutStatistics(requestParameters: BillingCyclesApiBillingCyclePayoutStatisticsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CommissionStatisticList> {
            return localVarFp.billingCyclePayoutStatistics(requestParameters.mandatorPublicId, requestParameters.monthlyValues, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or export the billing cycle with the given ID
         * @param {BillingCyclesApiBillingCyclesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclesId(requestParameters: BillingCyclesApiBillingCyclesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<BillingCycle> {
            return localVarFp.billingCyclesId(requestParameters.id, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the billing invoice for all account holders enclosed by the logged in user in the given                      billing cycle.
         * @param {BillingCyclesApiBillingCyclesIdInvoicesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingCyclesIdInvoices(requestParameters: BillingCyclesApiBillingCyclesIdInvoicesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Invoice> {
            return localVarFp.billingCyclesIdInvoices(requestParameters.id, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new billing cycle with the given start and end date
         * @param {BillingCyclesApiCreateBillingCycleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingCycle(requestParameters: BillingCyclesApiCreateBillingCycleRequest, options?: RawAxiosRequestConfig): AxiosPromise<BillingCycle> {
            return localVarFp.createBillingCycle(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the billing cycle with the given ID
         * @param {BillingCyclesApiDeleteBillingCycleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingCycle(requestParameters: BillingCyclesApiDeleteBillingCycleRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBillingCycle(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get billing invoices for all account holders enclosed by the logged in user. This includes all data                      from previous billing cycles and data that is not billed yet. Each billing cycle for each account                      holder and not yet billed data for each account holder will result in one                      AccountHolderCommission record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(options?: RawAxiosRequestConfig): AxiosPromise<InvoiceList> {
            return localVarFp.getInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the amount of kickback (cashback transfers) that may be relevant for a declaration of taxes.                      The amount is based on the commissions payed out to the customer, the according Rentablo                      administrative fee and the tax exempt rations defined by the German legislator                      (Teilfreistellungsbetraege).<br>                      <b>Dislaimer:</b> Rentablo does not provide any tax advising services. All data generated by this                      endpoint is thus delivered \'as is\', without any liability and without any guarantee for                      compliance or correctness of the produced data.
         * @param {BillingCyclesApiGetTaxationEstimateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxationEstimate(requestParameters: BillingCyclesApiGetTaxationEstimateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaxationEstimateList> {
            return localVarFp.getTaxationEstimate(requestParameters.accountHolderId, requestParameters.estimateFrom, requestParameters.estimateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of billing cycles
         * @param {BillingCyclesApiListAndSearchAllBillingCyclesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllBillingCycles(requestParameters: BillingCyclesApiListAndSearchAllBillingCyclesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BillingCycleList> {
            return localVarFp.listAndSearchAllBillingCycles(requestParameters.id, requestParameters.startDate, requestParameters.endDate, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the taxation category for a given fund (identified by ISIN). The update is persisted, so                      that a user needs to update the category only once; future queries will automatically use the                      value chosen by the user.
         * @param {BillingCyclesApiUpdateTaxationCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaxationCategory(requestParameters: BillingCyclesApiUpdateTaxationCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaxationEstimate> {
            return localVarFp.updateTaxationCategory(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for billingCyclePayoutStatistics operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiBillingCyclePayoutStatisticsRequest
 */
export interface BillingCyclesApiBillingCyclePayoutStatisticsRequest {
    /**
     * Only display data for the given mandator.
     * @type {string}
     * @memberof BillingCyclesApiBillingCyclePayoutStatistics
     */
    readonly mandatorPublicId?: string

    /**
     * Retrieve monthly instead of quarterly values
     * @type {boolean}
     * @memberof BillingCyclesApiBillingCyclePayoutStatistics
     */
    readonly monthlyValues?: boolean
}

/**
 * Request parameters for billingCyclesId operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiBillingCyclesIdRequest
 */
export interface BillingCyclesApiBillingCyclesIdRequest {
    /**
     * Retrieve the given billing cycle
     * @type {number}
     * @memberof BillingCyclesApiBillingCyclesId
     */
    readonly id: number

    /**
     * Retrieve billing cycle data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;. A PDF export will only be allowed if there are actually any executed                              transactions.
     * @type {string}
     * @memberof BillingCyclesApiBillingCyclesId
     */
    readonly format?: string
}

/**
 * Request parameters for billingCyclesIdInvoices operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiBillingCyclesIdInvoicesRequest
 */
export interface BillingCyclesApiBillingCyclesIdInvoicesRequest {
    /**
     * Retrieve invoicing data for the given billing cycle
     * @type {number}
     * @memberof BillingCyclesApiBillingCyclesIdInvoices
     */
    readonly id: number

    /**
     * Retrieve invoicing data in the given format, allowable values are \&#39;json\&#39; and \&#39;pdf\&#39;.                              Default is \&#39;json\&#39;.
     * @type {string}
     * @memberof BillingCyclesApiBillingCyclesIdInvoices
     */
    readonly format?: string
}

/**
 * Request parameters for createBillingCycle operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiCreateBillingCycleRequest
 */
export interface BillingCyclesApiCreateBillingCycleRequest {
    /**
     * The account details
     * @type {CreateBillingCycleParams}
     * @memberof BillingCyclesApiCreateBillingCycle
     */
    readonly body: CreateBillingCycleParams
}

/**
 * Request parameters for deleteBillingCycle operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiDeleteBillingCycleRequest
 */
export interface BillingCyclesApiDeleteBillingCycleRequest {
    /**
     * Delete the given billing cycle
     * @type {number}
     * @memberof BillingCyclesApiDeleteBillingCycle
     */
    readonly id: number
}

/**
 * Request parameters for getTaxationEstimate operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiGetTaxationEstimateRequest
 */
export interface BillingCyclesApiGetTaxationEstimateRequest {
    /**
     * Retrieve a taxation estimate for the given account holder
     * @type {number}
     * @memberof BillingCyclesApiGetTaxationEstimate
     */
    readonly accountHolderId: number

    /**
     * Get the taxation estimate starting from the given date; format is yyyy-MM-dd (US Format).                              The default value is the start of last year; an usually this value will be the beginning                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
     * @type {string}
     * @memberof BillingCyclesApiGetTaxationEstimate
     */
    readonly estimateFrom?: string

    /**
     * Get the taxation estimate ending with the given date; format is yyyy-MM-dd (US Format).                              The default value is the end of last year; an usually this value will be the end                              of a year.                              The date filter is applied to the billing cycle creation timestamp; as we base taxation                              estimation on the payment date.
     * @type {string}
     * @memberof BillingCyclesApiGetTaxationEstimate
     */
    readonly estimateTo?: string
}

/**
 * Request parameters for listAndSearchAllBillingCycles operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiListAndSearchAllBillingCyclesRequest
 */
export interface BillingCyclesApiListAndSearchAllBillingCyclesRequest {
    /**
     * Retrieve the given billing cycles
     * @type {Array<number>}
     * @memberof BillingCyclesApiListAndSearchAllBillingCycles
     */
    readonly id?: Array<number>

    /**
     * Only retrieve billing cycles with start date equal or after the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BillingCyclesApiListAndSearchAllBillingCycles
     */
    readonly startDate?: string

    /**
     * Only retrieve billing cycles with end date equal or beforer the given date;                              format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof BillingCyclesApiListAndSearchAllBillingCycles
     */
    readonly endDate?: string

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof BillingCyclesApiListAndSearchAllBillingCycles
     */
    readonly order?: string
}

/**
 * Request parameters for updateTaxationCategory operation in BillingCyclesApi.
 * @export
 * @interface BillingCyclesApiUpdateTaxationCategoryRequest
 */
export interface BillingCyclesApiUpdateTaxationCategoryRequest {
    /**
     * The taxation estimate update parameters
     * @type {UpdateTaxationEstimateParams}
     * @memberof BillingCyclesApiUpdateTaxationCategory
     */
    readonly body: UpdateTaxationEstimateParams
}

/**
 * BillingCyclesApi - object-oriented interface
 * @export
 * @class BillingCyclesApi
 * @extends {BaseAPI}
 */
export class BillingCyclesApi extends BaseAPI {
    /**
     * 
     * @summary Get payout statistics for all billing cycles over time
     * @param {BillingCyclesApiBillingCyclePayoutStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public billingCyclePayoutStatistics(requestParameters: BillingCyclesApiBillingCyclePayoutStatisticsRequest = {}, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).billingCyclePayoutStatistics(requestParameters.mandatorPublicId, requestParameters.monthlyValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or export the billing cycle with the given ID
     * @param {BillingCyclesApiBillingCyclesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public billingCyclesId(requestParameters: BillingCyclesApiBillingCyclesIdRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).billingCyclesId(requestParameters.id, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the billing invoice for all account holders enclosed by the logged in user in the given                      billing cycle.
     * @param {BillingCyclesApiBillingCyclesIdInvoicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public billingCyclesIdInvoices(requestParameters: BillingCyclesApiBillingCyclesIdInvoicesRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).billingCyclesIdInvoices(requestParameters.id, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new billing cycle with the given start and end date
     * @param {BillingCyclesApiCreateBillingCycleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public createBillingCycle(requestParameters: BillingCyclesApiCreateBillingCycleRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).createBillingCycle(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the billing cycle with the given ID
     * @param {BillingCyclesApiDeleteBillingCycleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public deleteBillingCycle(requestParameters: BillingCyclesApiDeleteBillingCycleRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).deleteBillingCycle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get billing invoices for all account holders enclosed by the logged in user. This includes all data                      from previous billing cycles and data that is not billed yet. Each billing cycle for each account                      holder and not yet billed data for each account holder will result in one                      AccountHolderCommission record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public getInvoices(options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).getInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the amount of kickback (cashback transfers) that may be relevant for a declaration of taxes.                      The amount is based on the commissions payed out to the customer, the according Rentablo                      administrative fee and the tax exempt rations defined by the German legislator                      (Teilfreistellungsbetraege).<br>                      <b>Dislaimer:</b> Rentablo does not provide any tax advising services. All data generated by this                      endpoint is thus delivered \'as is\', without any liability and without any guarantee for                      compliance or correctness of the produced data.
     * @param {BillingCyclesApiGetTaxationEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public getTaxationEstimate(requestParameters: BillingCyclesApiGetTaxationEstimateRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).getTaxationEstimate(requestParameters.accountHolderId, requestParameters.estimateFrom, requestParameters.estimateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of billing cycles
     * @param {BillingCyclesApiListAndSearchAllBillingCyclesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public listAndSearchAllBillingCycles(requestParameters: BillingCyclesApiListAndSearchAllBillingCyclesRequest = {}, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).listAndSearchAllBillingCycles(requestParameters.id, requestParameters.startDate, requestParameters.endDate, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the taxation category for a given fund (identified by ISIN). The update is persisted, so                      that a user needs to update the category only once; future queries will automatically use the                      value chosen by the user.
     * @param {BillingCyclesApiUpdateTaxationCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public updateTaxationCategory(requestParameters: BillingCyclesApiUpdateTaxationCategoryRequest, options?: RawAxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).updateTaxationCategory(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

