import { UseQueryOptions } from "react-query";
import {
  RegionsApiListAndSearchAllRegionsRequest,
  PageableRegionList,
} from "@generated/apiv1";

import { buildRegionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllRegions = async (
  token: string | undefined,
  params: RegionsApiListAndSearchAllRegionsRequest
) => {
  const apiInstance = buildRegionsApi(token);
  const { data } = await apiInstance.listAndSearchAllRegions(params);
  return data;
};

export const useListAndSearchAllRegions = (
  params: RegionsApiListAndSearchAllRegionsRequest,
  options?: UseQueryOptions<
    PageableRegionList,
    AxiosApiError,
    PageableRegionList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.REGIONS_LIST_AND_SEARCH_ALL_REGIONS, params],
    ({ token }) => listAndSearchAllRegions(token, params),
    options
  );
};
