import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useMediaQuery, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserWithType } from "@api";
import { goToDashboard } from "@helpers/mainAppUrls";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { SymbolV2 } from "@generated/apiv1";
import { Sidebar, SidebarResponsive } from "./Sidebar";
import { FakeSearchField } from "./FakeSearchField";
import {
  AppSidebarHeader as HeaderOrig,
  AppSidebarHeaderSmall,
} from "./AppSidebarHeader";
import { AppSidebarMenu as AppSidebarMenuOrig } from "./AppSidebarMenu";

const Header = styled(HeaderOrig)<{ collapsed?: boolean }>`
  ${({ collapsed, theme }) =>
    !collapsed &&
    css`
      margin-right: ${theme.spacing(8)};
    `}
`;

const AppSidebarMenu = styled(AppSidebarMenuOrig)<{ isMobile?: boolean }>`
  margin-top: ${({ theme, isMobile }) => theme.spacing(6)};
`;

const HeaderSmallStyled = styled(AppSidebarHeaderSmall)`
  flex-grow: 1;
`;

const FakeInputWrapper = styled.div<{ isSmall: boolean; matches?: boolean }>`
  margin: ${({ theme, matches }) =>
    matches ? theme.spacing(0, 3) : theme.spacing(0, 4)};

  ${({ theme, isSmall }) =>
    isSmall &&
    css`
      display: flex;
      align-items: center;
      margin: 9px ${theme.spacing(2)} 6px;
    `}
`;

export interface AppSidebarProps {
  user?: UserWithType;
  subscriptionType?: "plus" | "pro" | "free";
  collapseOnSelect?: boolean;
  collapsed: boolean;
  onCollapseChange: (collapsed: boolean) => void;
}

export const AppSidebar = ({ user }: AppSidebarProps) => {
  const navigate = useNavigate();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { sidebarCollapsed: collapsed, toggleSidebarCollapsed } =
    useAppLayout();

  const onSymbolSelect = (symbol: SymbolV2) => {
    navigate(
      `/securities?id=${symbol.id}&quoteProvider=${symbol.quoteProvider}`
    );
  };

  if (matches) {
    return (
      <SidebarResponsive
        collapsed={collapsed}
        onMenuOpen={toggleSidebarCollapsed}
        header={<HeaderSmallStyled onLogoClick={goToDashboard} />}
        iconColor="white"
      >
        <FakeInputWrapper isSmall={collapsed && !matches} matches={matches}>
          <FakeSearchField
            collapsed={collapsed}
            onSymbolSelect={onSymbolSelect}
          />
        </FakeInputWrapper>
        <AppSidebarMenu collapsed={collapsed} user={user} isMobile />
      </SidebarResponsive>
    );
  }

  return (
    <Sidebar
      collapsed={collapsed}
      onCollapseClick={toggleSidebarCollapsed}
      header={<Header onLogoClick={goToDashboard} />}
    >
      <FakeInputWrapper isSmall={collapsed && !matches}>
        <FakeSearchField
          collapsed={collapsed}
          onSymbolSelect={onSymbolSelect}
        />
      </FakeInputWrapper>
      <AppSidebarMenu user={user} collapsed={collapsed} />
    </Sidebar>
  );
};
