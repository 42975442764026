export const CACHE_KEYS = {
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_LOGIN_WITH_APPLE: "AUTH_LOGIN_WITH_APPLE",
  AUTH_LOGIN_WITH_GOOGLE: "AUTH_LOGIN_WITH_GOOGLE",
  AUTH_REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
  OTP_CHANGE_OTP_LABEL: "OTP_CHANGE_OTP_LABEL",
  OTP_DELETE_OTP: "OTP_DELETE_OTP",
  OTP_INITIATE_OTP_SETUP: "OTP_INITIATE_OTP_SETUP",
  OTP_OTP_SETUP: "OTP_OTP_SETUP",
  SUBSCRIPTIONS_REVENUE_CAT: "SUBSCRIPTIONS_REVENUE_CAT",
  SUBSCRIPTIONS_STRIPE: "SUBSCRIPTIONS_STRIPE",
  USERS_CHANGE_AUTO_SIGN_OUT_DURATION: "USERS_CHANGE_AUTO_SIGN_OUT_DURATION",
  USERS_CHANGE_DISCORD_ID: "USERS_CHANGE_DISCORD_ID",
  USERS_CHANGE_DISPLAY_NAME: "USERS_CHANGE_DISPLAY_NAME",
  USERS_CHANGE_EMAIL: "USERS_CHANGE_EMAIL",
  USERS_CONFIRM: "USERS_CONFIRM",
  USERS_DELETE_USER: "USERS_DELETE_USER",
  USERS_INITIATE_PASSWORD_RESET: "USERS_INITIATE_PASSWORD_RESET",
  USERS_MYSELF: "USERS_MYSELF",
  USERS_RESEND_CONFIRMATION_EMAIL: "USERS_RESEND_CONFIRMATION_EMAIL",
  USERS_RESET_PASSWORD: "USERS_RESET_PASSWORD",
  USERS_SIGNUP: "USERS_SIGNUP",
};
