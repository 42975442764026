import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  TableBody,
  TableCell,
  TableHead,
  Table,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Account } from "@generated/apiv1";
import { SortableTableHeader } from "@components/Table";
import { AccountView } from "@components/Accounts/AccountView";
import { useAccountsSortAndFilter } from "@components/Accounts/useAccountsSortAndFilter";
import {
  AccountWithSyncRequired,
  useAccountsWithSyncRequired,
} from "../../hooks/useAccountsWithSyncRequired";

type Props = {
  accounts: Account[];
  subCategoryId: string;
  hasAccountsWithUpdateButton?: boolean;
};

export const DepotsAccountsTable = ({
  accounts,
  subCategoryId,
  hasAccountsWithUpdateButton,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const accountsWithSyncFlag = useAccountsWithSyncRequired(accounts);

  const {
    sortedAccounts,
    sortField,
    sortDirection,
    sort,
    setPerformanceValues,
  } = useAccountsSortAndFilter(accountsWithSyncFlag, "", {
    field: "bankName",
    direction: "ASC",
  });

  const sortedWithReferenceAccounts = useMemo(() => {
    return sortedAccounts.reduce((acc, account, index) => {
      if (account.contraAccountId) {
        const referenceAccountIndex = sortedAccounts.findIndex(
          (acc) => acc.id === account.contraAccountId
        );
        if (referenceAccountIndex !== -1) {
          const refAccount = acc.splice(referenceAccountIndex, 1)[0];
          acc.splice(index, 0, {
            ...refAccount,
            isReferenceAccount: true,
          });
        }
      }
      return acc;
    }, sortedAccounts as AccountWithSyncRequired<Account & { isReferenceAccount?: boolean }>[]);
  }, [sortedAccounts]);

  const handleClick = (account: Account) => {
    const isAccount =
      account.type === "02_cash" || account.typeFromBank === "CREDIT_CARD";
    navigate(isAccount ? `/accounts/${account.id}` : `/depots/${account.id}`);
    window.scrollTo(0, 0);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <SortableTableHeader
            isSortable
            sortField={sortField}
            sortDirection={sortDirection}
            sortByName="name"
            text={t("accounts.sort.name")}
            onClick={sort}
            width="40%"
          />
          {smAndUp && <TableCell />}
          <SortableTableHeader
            isSortable
            sortField={sortField}
            sortDirection={sortDirection}
            sortByName="performance"
            align="right"
            text={t("accounts.sort.totalValue")}
            onClick={sort}
            width="55%"
          />
          {hasAccountsWithUpdateButton && (
            <SortableTableHeader
              sortByName=""
              text={t("accounts.sort.update")}
              align="right"
              width="5%"
            />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedWithReferenceAccounts.map((account) => (
          <AccountView
            key={`depot-${account.id}`}
            account={account}
            onClick={() => {
              handleClick(account);
            }}
            hasAccountsWithUpdateButton={hasAccountsWithUpdateButton}
            onPerformanceCalculated={setPerformanceValues}
            showYtdValue={false}
            type={subCategoryId}
          />
        ))}
      </TableBody>
    </Table>
  );
};
