/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DividendAllocationList } from '../model';
// @ts-ignore
import { DividendCalendarRecordList } from '../model';
// @ts-ignore
import { DividendHistoryTree } from '../model';
// @ts-ignore
import { DividendList } from '../model';
// @ts-ignore
import { DividendPerYearList } from '../model';
// @ts-ignore
import { DividendTimelineSummary } from '../model';
// @ts-ignore
import { DividendYieldList } from '../model';
/**
 * DividendsApi - axios parameter creator
 * @export
 */
export const DividendsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {Array<number>} [accountId] Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {number} [startYear] Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {boolean} [includePredictions] Include dividend predictions for the current year if true. Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendAllocation: async (accountId?: Array<number>, investmentId?: Array<number>, startYear?: number, endYear?: number, includePredictions?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/allocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (includePredictions !== undefined) {
                localVarQueryParameter['includePredictions'] = includePredictions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {Array<number>} [accountId] Get a dividend calendar for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Get a dividend calendar for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Get a dividend calendar for Investments with the given WKNs
         * @param {Array<string>} [isin] Get a dividend calendar for Investments with the given ISINs
         * @param {Array<DividendCalendarTypeEnum>} [type] Get a dividend calendar for Investments with the given asset types
         * @param {Array<number>} [industryId] Get a dividend calendar for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Get a dividend calendar for Investments belonging to the given regions (exact match only)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendCalendar: async (accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendCalendarTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result.                      Note that the attribute \'month\' starts                      with zero for January, up to 11 for December. Use \'calendarMonth\' for a month value ranging                      from 1 to 12.
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s); limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Retrieve the dividend history for Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve the dividend history for Investments with the given ISINs
         * @param {Array<DividendHistoryTypeEnum>} [type] Retrieve the dividend history for Investments with the given asset types
         * @param {Array<number>} [industryId] Retrieve the dividend history for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve the dividend history for Investments belonging to the given regions (exact match only)
         * @param {string} [cutoffDate] Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendHistory: async (accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendHistoryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, cutoffDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (cutoffDate !== undefined) {
                localVarQueryParameter['cutoffDate'] = cutoffDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {number} predictYears Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
         * @param {Array<number>} [accountId] Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project the future dividend payments for Investments with the given WKNs
         * @param {Array<string>} [isin] Project the future dividend payments for Investments with the given ISINs
         * @param {Array<DividendPredictionTypeEnum>} [type] Project the future dividend payments for Investments with the given asset types
         * @param {Array<number>} [industryId] Project the future dividend payments for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project the future dividend payments for Investments belonging to the given regions (exact match only)
         * @param {number} [yearlyPortfolioIncrease] Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
         * @param {boolean} [dividendPerShare] Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
         * @param {boolean} [dividendForPortfolio] Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
         * @param {boolean} [inAccountCurrency] Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendPrediction: async (predictYears: number, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendPredictionTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, yearlyPortfolioIncrease?: number, dividendPerShare?: boolean, dividendForPortfolio?: boolean, inAccountCurrency?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'predictYears' is not null or undefined
            assertParamExists('dividendPrediction', 'predictYears', predictYears)
            const localVarPath = `/v1/dividends/predict`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (predictYears !== undefined) {
                localVarQueryParameter['predictYears'] = predictYears;
            }

            if (yearlyPortfolioIncrease !== undefined) {
                localVarQueryParameter['yearlyPortfolioIncrease'] = yearlyPortfolioIncrease;
            }

            if (dividendPerShare !== undefined) {
                localVarQueryParameter['dividendPerShare'] = dividendPerShare;
            }

            if (dividendForPortfolio !== undefined) {
                localVarQueryParameter['dividendForPortfolio'] = dividendForPortfolio;
            }

            if (inAccountCurrency !== undefined) {
                localVarQueryParameter['inAccountCurrency'] = inAccountCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Actuals and predictions for the current year is combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {Array<number>} [accountId] Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline for Investments with the given ISINs
         * @param {Array<DividendTimelineTypeEnum>} [type] Project dividend timeline for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {DividendTimelineGroupByEnum} [groupBy] Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendTimeline: async (accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendTimelineTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: DividendTimelineGroupByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/timeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['groupBy'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {Array<number>} [accountId] Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline summary for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline summary for Investments with the given ISINs
         * @param {Array<DividendTimelineSummaryTypeEnum>} [type] Project dividend timeline summary for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline summary for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline summary for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {DividendTimelineSummaryGroupByEnum} [groupBy] Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendTimelineSummary: async (accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendTimelineSummaryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: DividendTimelineSummaryGroupByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/timeline/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['groupBy'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {Array<number>} accountId Retrieve the dividend yield for the given account(s). Limited to 300 investments.
         * @param {DividendYieldCalculationTypeEnum} [calculationType] The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
         * @param {string} [from] Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
         * @param {number} [cagrYears] Calculate the CAGR for the given number of years. Default is 5.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendYield: async (accountId: Array<number>, calculationType?: DividendYieldCalculationTypeEnum, from?: string, cagrYears?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('dividendYield', 'accountId', accountId)
            const localVarPath = `/v1/dividends/portfolioYield`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (calculationType !== undefined) {
                localVarQueryParameter['calculationType'] = calculationType;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (cagrYears !== undefined) {
                localVarQueryParameter['cagrYears'] = cagrYears;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of historic dividends for the given investment. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {number} id Retrieve Dividends for the given investment
         * @param {number} [taxRate] Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
         * @param {string} [cutoffDate] Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
         * @param {boolean} [splitAdjust] Apply splits to dividends; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividends: async (id: number, taxRate?: number, cutoffDate?: string, splitAdjust?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDividends', 'id', id)
            const localVarPath = `/v1/dividends/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (taxRate !== undefined) {
                localVarQueryParameter['taxRate'] = taxRate;
            }

            if (cutoffDate !== undefined) {
                localVarQueryParameter['cutoffDate'] = cutoffDate;
            }

            if (splitAdjust !== undefined) {
                localVarQueryParameter['splitAdjust'] = splitAdjust;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get aggregated per-year totals of dividends for the given investment. Optionally, estimates for                      following years will be included.
         * @param {number} [investmentId] Retrieve Dividends for the given investment
         * @param {string} [isin] Retrieve Dividends for the given ISIN
         * @param {string} [currency] This parameter is only used in combination with \&#39;isin\&#39;. Convert dividends into the given                              currency; default is EUR.
         * @param {number} [predictNumberOfYears] Include a prediction for the given number of years.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendsPerYear: async (investmentId?: number, isin?: string, currency?: string, predictNumberOfYears?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends/perYearTotal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (investmentId !== undefined) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (predictNumberOfYears !== undefined) {
                localVarQueryParameter['predictNumberOfYears'] = predictNumberOfYears;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list dividends for the given investments. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {Array<number>} [accountId] Retrieve Dividends for the given account(s). Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve Dividends for the given Investment(s). Limited to 300 investments.
         * @param {Array<string>} [isin] Retrieve Dividends for the given ISINS. Limited to 300 ISINs.
         * @param {number} [taxRate] Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
         * @param {string} [cutoffDate] Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
         * @param {boolean} [activeInvestmentsOnly] Only include investments with number of lots &gt; 0; default is true
         * @param {Array<boolean>} [excludeImported] Exclude dividends for investments that have been imported via banking API and for which                              transaction data is available; default is true.
         * @param {boolean} [splitAdjust] Apply splits to dividends; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDividends: async (accountId?: Array<number>, investmentId?: Array<number>, isin?: Array<string>, taxRate?: number, cutoffDate?: string, activeInvestmentsOnly?: boolean, excludeImported?: Array<boolean>, splitAdjust?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (taxRate !== undefined) {
                localVarQueryParameter['taxRate'] = taxRate;
            }

            if (cutoffDate !== undefined) {
                localVarQueryParameter['cutoffDate'] = cutoffDate;
            }

            if (activeInvestmentsOnly !== undefined) {
                localVarQueryParameter['activeInvestmentsOnly'] = activeInvestmentsOnly;
            }

            if (excludeImported) {
                localVarQueryParameter['excludeImported'] = excludeImported;
            }

            if (splitAdjust !== undefined) {
                localVarQueryParameter['splitAdjust'] = splitAdjust;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {number} [startYear] Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {boolean} [includePredictions] Include dividend predictions for the current year if true. Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendAllocation: async (publicId: string, accountId?: Array<number>, investmentId?: Array<number>, startYear?: number, endYear?: number, includePredictions?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendAllocation', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/dividends/allocation`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (includePredictions !== undefined) {
                localVarQueryParameter['includePredictions'] = includePredictions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendCalendar: async (publicId: string, accountId?: Array<number>, investmentId?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendCalendar', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/dividends/calendar`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s); limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Retrieve the dividend history for Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve the dividend history for Investments with the given ISINs
         * @param {Array<PublicDividendHistoryTypeEnum>} [type] Retrieve the dividend history for Investments with the given asset types
         * @param {Array<number>} [industryId] Retrieve the dividend history for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve the dividend history for Investments belonging to the given regions (exact match only)
         * @param {string} [cutoffDate] Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendHistory: async (publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendHistoryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, cutoffDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendHistory', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/dividends/history`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (cutoffDate !== undefined) {
                localVarQueryParameter['cutoffDate'] = cutoffDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {number} predictYears Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
         * @param {Array<number>} [accountId] Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project the future dividend payments for Investments with the given WKNs
         * @param {Array<string>} [isin] Project the future dividend payments for Investments with the given ISINs
         * @param {Array<PublicDividendPredictionTypeEnum>} [type] Project the future dividend payments for Investments with the given asset types
         * @param {Array<number>} [industryId] Project the future dividend payments for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project the future dividend payments for Investments belonging to the given regions (exact match only)
         * @param {number} [yearlyPortfolioIncrease] Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
         * @param {boolean} [dividendPerShare] Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
         * @param {boolean} [dividendForPortfolio] Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
         * @param {boolean} [inAccountCurrency] Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendPrediction: async (publicId: string, predictYears: number, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendPredictionTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, yearlyPortfolioIncrease?: number, dividendPerShare?: boolean, dividendForPortfolio?: boolean, inAccountCurrency?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendPrediction', 'publicId', publicId)
            // verify required parameter 'predictYears' is not null or undefined
            assertParamExists('publicDividendPrediction', 'predictYears', predictYears)
            const localVarPath = `/v1/public/{publicId}/dividends/predict`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (predictYears !== undefined) {
                localVarQueryParameter['predictYears'] = predictYears;
            }

            if (yearlyPortfolioIncrease !== undefined) {
                localVarQueryParameter['yearlyPortfolioIncrease'] = yearlyPortfolioIncrease;
            }

            if (dividendPerShare !== undefined) {
                localVarQueryParameter['dividendPerShare'] = dividendPerShare;
            }

            if (dividendForPortfolio !== undefined) {
                localVarQueryParameter['dividendForPortfolio'] = dividendForPortfolio;
            }

            if (inAccountCurrency !== undefined) {
                localVarQueryParameter['inAccountCurrency'] = inAccountCurrency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Data for the current year is combined in a smart way, leading to predictions becoming more and                      more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline for Investments with the given ISINs
         * @param {Array<PublicDividendTimelineTypeEnum>} [type] Project dividend timeline for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {PublicDividendTimelineGroupByEnum} [groupBy] Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendTimeline: async (publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendTimelineTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: PublicDividendTimelineGroupByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendTimeline', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/dividends/timeline`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['groupBy'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline summary for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline summary for Investments with the given ISINs
         * @param {Array<PublicDividendTimelineSummaryTypeEnum>} [type] Project dividend timeline summary for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline summary for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline summary for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {PublicDividendTimelineSummaryGroupByEnum} [groupBy] Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendTimelineSummary: async (publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendTimelineSummaryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: PublicDividendTimelineSummaryGroupByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendTimelineSummary', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/dividends/timeline/summary`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (startYear !== undefined) {
                localVarQueryParameter['startYear'] = startYear;
            }

            if (endYear !== undefined) {
                localVarQueryParameter['endYear'] = endYear;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['groupBy'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} accountId Retrieve the dividend yield for the given account(s). Limited to 300 investments.
         * @param {PublicDividendYieldCalculationTypeEnum} [calculationType] The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
         * @param {string} [from] Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
         * @param {number} [cagrYears] Calculate the CAGR for the given number of years. Default is 5.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendYield: async (publicId: string, accountId: Array<number>, calculationType?: PublicDividendYieldCalculationTypeEnum, from?: string, cagrYears?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicDividendYield', 'publicId', publicId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('publicDividendYield', 'accountId', accountId)
            const localVarPath = `/v1/public/{publicId}/dividends/portfolioYield`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (calculationType !== undefined) {
                localVarQueryParameter['calculationType'] = calculationType;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (cagrYears !== undefined) {
                localVarQueryParameter['cagrYears'] = cagrYears;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DividendsApi - functional programming interface
 * @export
 */
export const DividendsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DividendsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {Array<number>} [accountId] Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {number} [startYear] Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {boolean} [includePredictions] Include dividend predictions for the current year if true. Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendAllocation(accountId?: Array<number>, investmentId?: Array<number>, startYear?: number, endYear?: number, includePredictions?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendAllocationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendAllocation(accountId, investmentId, startYear, endYear, includePredictions, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendAllocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {Array<number>} [accountId] Get a dividend calendar for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Get a dividend calendar for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Get a dividend calendar for Investments with the given WKNs
         * @param {Array<string>} [isin] Get a dividend calendar for Investments with the given ISINs
         * @param {Array<DividendCalendarTypeEnum>} [type] Get a dividend calendar for Investments with the given asset types
         * @param {Array<number>} [industryId] Get a dividend calendar for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Get a dividend calendar for Investments belonging to the given regions (exact match only)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendCalendar(accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendCalendarTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendCalendarRecordList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendCalendar(accountId, investmentId, wkn, isin, type, industryId, regionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result.                      Note that the attribute \'month\' starts                      with zero for January, up to 11 for December. Use \'calendarMonth\' for a month value ranging                      from 1 to 12.
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s); limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Retrieve the dividend history for Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve the dividend history for Investments with the given ISINs
         * @param {Array<DividendHistoryTypeEnum>} [type] Retrieve the dividend history for Investments with the given asset types
         * @param {Array<number>} [industryId] Retrieve the dividend history for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve the dividend history for Investments belonging to the given regions (exact match only)
         * @param {string} [cutoffDate] Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendHistory(accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendHistoryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, cutoffDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendHistory(accountId, investmentId, wkn, isin, type, industryId, regionId, cutoffDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {number} predictYears Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
         * @param {Array<number>} [accountId] Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project the future dividend payments for Investments with the given WKNs
         * @param {Array<string>} [isin] Project the future dividend payments for Investments with the given ISINs
         * @param {Array<DividendPredictionTypeEnum>} [type] Project the future dividend payments for Investments with the given asset types
         * @param {Array<number>} [industryId] Project the future dividend payments for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project the future dividend payments for Investments belonging to the given regions (exact match only)
         * @param {number} [yearlyPortfolioIncrease] Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
         * @param {boolean} [dividendPerShare] Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
         * @param {boolean} [dividendForPortfolio] Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
         * @param {boolean} [inAccountCurrency] Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendPrediction(predictYears: number, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendPredictionTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, yearlyPortfolioIncrease?: number, dividendPerShare?: boolean, dividendForPortfolio?: boolean, inAccountCurrency?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendPrediction(predictYears, accountId, investmentId, wkn, isin, type, industryId, regionId, yearlyPortfolioIncrease, dividendPerShare, dividendForPortfolio, inAccountCurrency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendPrediction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Actuals and predictions for the current year is combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {Array<number>} [accountId] Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline for Investments with the given ISINs
         * @param {Array<DividendTimelineTypeEnum>} [type] Project dividend timeline for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {DividendTimelineGroupByEnum} [groupBy] Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendTimeline(accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendTimelineTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: DividendTimelineGroupByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendTimeline(accountId, investmentId, wkn, isin, type, industryId, regionId, startYear, endYear, groupBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendTimeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {Array<number>} [accountId] Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline summary for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline summary for Investments with the given ISINs
         * @param {Array<DividendTimelineSummaryTypeEnum>} [type] Project dividend timeline summary for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline summary for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline summary for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {DividendTimelineSummaryGroupByEnum} [groupBy] Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendTimelineSummary(accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<DividendTimelineSummaryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: DividendTimelineSummaryGroupByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendTimelineSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendTimelineSummary(accountId, investmentId, wkn, isin, type, industryId, regionId, startYear, endYear, groupBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendTimelineSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {Array<number>} accountId Retrieve the dividend yield for the given account(s). Limited to 300 investments.
         * @param {DividendYieldCalculationTypeEnum} [calculationType] The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
         * @param {string} [from] Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
         * @param {number} [cagrYears] Calculate the CAGR for the given number of years. Default is 5.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dividendYield(accountId: Array<number>, calculationType?: DividendYieldCalculationTypeEnum, from?: string, cagrYears?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendYieldList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dividendYield(accountId, calculationType, from, cagrYears, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.dividendYield']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of historic dividends for the given investment. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {number} id Retrieve Dividends for the given investment
         * @param {number} [taxRate] Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
         * @param {string} [cutoffDate] Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
         * @param {boolean} [splitAdjust] Apply splits to dividends; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDividends(id: number, taxRate?: number, cutoffDate?: string, splitAdjust?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDividends(id, taxRate, cutoffDate, splitAdjust, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.getDividends']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get aggregated per-year totals of dividends for the given investment. Optionally, estimates for                      following years will be included.
         * @param {number} [investmentId] Retrieve Dividends for the given investment
         * @param {string} [isin] Retrieve Dividends for the given ISIN
         * @param {string} [currency] This parameter is only used in combination with \&#39;isin\&#39;. Convert dividends into the given                              currency; default is EUR.
         * @param {number} [predictNumberOfYears] Include a prediction for the given number of years.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDividendsPerYear(investmentId?: number, isin?: string, currency?: string, predictNumberOfYears?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendPerYearList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDividendsPerYear(investmentId, isin, currency, predictNumberOfYears, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.getDividendsPerYear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list dividends for the given investments. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {Array<number>} [accountId] Retrieve Dividends for the given account(s). Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve Dividends for the given Investment(s). Limited to 300 investments.
         * @param {Array<string>} [isin] Retrieve Dividends for the given ISINS. Limited to 300 ISINs.
         * @param {number} [taxRate] Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
         * @param {string} [cutoffDate] Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
         * @param {boolean} [activeInvestmentsOnly] Only include investments with number of lots &gt; 0; default is true
         * @param {Array<boolean>} [excludeImported] Exclude dividends for investments that have been imported via banking API and for which                              transaction data is available; default is true.
         * @param {boolean} [splitAdjust] Apply splits to dividends; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllDividends(accountId?: Array<number>, investmentId?: Array<number>, isin?: Array<string>, taxRate?: number, cutoffDate?: string, activeInvestmentsOnly?: boolean, excludeImported?: Array<boolean>, splitAdjust?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllDividends(accountId, investmentId, isin, taxRate, cutoffDate, activeInvestmentsOnly, excludeImported, splitAdjust, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.listAndSearchAllDividends']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {number} [startYear] Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {boolean} [includePredictions] Include dividend predictions for the current year if true. Default is true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendAllocation(publicId: string, accountId?: Array<number>, investmentId?: Array<number>, startYear?: number, endYear?: number, includePredictions?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendAllocationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendAllocation(publicId, accountId, investmentId, startYear, endYear, includePredictions, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendAllocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendCalendar(publicId: string, accountId?: Array<number>, investmentId?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendCalendarRecordList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendCalendar(publicId, accountId, investmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Retrieve the dividend history for the given account(s); limited to 300 investments.
         * @param {Array<number>} [investmentId] Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Retrieve the dividend history for Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve the dividend history for Investments with the given ISINs
         * @param {Array<PublicDividendHistoryTypeEnum>} [type] Retrieve the dividend history for Investments with the given asset types
         * @param {Array<number>} [industryId] Retrieve the dividend history for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve the dividend history for Investments belonging to the given regions (exact match only)
         * @param {string} [cutoffDate] Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendHistory(publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendHistoryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, cutoffDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendHistory(publicId, accountId, investmentId, wkn, isin, type, industryId, regionId, cutoffDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {string} publicId Public ID of the user
         * @param {number} predictYears Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
         * @param {Array<number>} [accountId] Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project the future dividend payments for Investments with the given WKNs
         * @param {Array<string>} [isin] Project the future dividend payments for Investments with the given ISINs
         * @param {Array<PublicDividendPredictionTypeEnum>} [type] Project the future dividend payments for Investments with the given asset types
         * @param {Array<number>} [industryId] Project the future dividend payments for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project the future dividend payments for Investments belonging to the given regions (exact match only)
         * @param {number} [yearlyPortfolioIncrease] Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
         * @param {boolean} [dividendPerShare] Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
         * @param {boolean} [dividendForPortfolio] Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
         * @param {boolean} [inAccountCurrency] Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendPrediction(publicId: string, predictYears: number, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendPredictionTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, yearlyPortfolioIncrease?: number, dividendPerShare?: boolean, dividendForPortfolio?: boolean, inAccountCurrency?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendPrediction(publicId, predictYears, accountId, investmentId, wkn, isin, type, industryId, regionId, yearlyPortfolioIncrease, dividendPerShare, dividendForPortfolio, inAccountCurrency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendPrediction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Data for the current year is combined in a smart way, leading to predictions becoming more and                      more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline for Investments with the given ISINs
         * @param {Array<PublicDividendTimelineTypeEnum>} [type] Project dividend timeline for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {PublicDividendTimelineGroupByEnum} [groupBy] Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendTimeline(publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendTimelineTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: PublicDividendTimelineGroupByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendHistoryTree>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendTimeline(publicId, accountId, investmentId, wkn, isin, type, industryId, regionId, startYear, endYear, groupBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendTimeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [accountId] Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
         * @param {Array<number>} [investmentId] Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
         * @param {Array<string>} [wkn] Project dividend timeline summary for Investments with the given WKNs
         * @param {Array<string>} [isin] Project dividend timeline summary for Investments with the given ISINs
         * @param {Array<PublicDividendTimelineSummaryTypeEnum>} [type] Project dividend timeline summary for Investments with the given asset types
         * @param {Array<number>} [industryId] Project dividend timeline summary for Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Project dividend timeline summary for Investments belonging to the given regions (exact match only)
         * @param {number} [startYear] Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
         * @param {number} [endYear] Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
         * @param {PublicDividendTimelineSummaryGroupByEnum} [groupBy] Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendTimelineSummary(publicId: string, accountId?: Array<number>, investmentId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, type?: Array<PublicDividendTimelineSummaryTypeEnum>, industryId?: Array<number>, regionId?: Array<number>, startYear?: number, endYear?: number, groupBy?: PublicDividendTimelineSummaryGroupByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendTimelineSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendTimelineSummary(publicId, accountId, investmentId, wkn, isin, type, industryId, regionId, startYear, endYear, groupBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendTimelineSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} accountId Retrieve the dividend yield for the given account(s). Limited to 300 investments.
         * @param {PublicDividendYieldCalculationTypeEnum} [calculationType] The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
         * @param {string} [from] Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
         * @param {number} [cagrYears] Calculate the CAGR for the given number of years. Default is 5.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicDividendYield(publicId: string, accountId: Array<number>, calculationType?: PublicDividendYieldCalculationTypeEnum, from?: string, cagrYears?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendYieldList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicDividendYield(publicId, accountId, calculationType, from, cagrYears, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.publicDividendYield']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DividendsApi - factory interface
 * @export
 */
export const DividendsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DividendsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {DividendsApiDividendAllocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendAllocation(requestParameters: DividendsApiDividendAllocationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendAllocationList> {
            return localVarFp.dividendAllocation(requestParameters.accountId, requestParameters.investmentId, requestParameters.startYear, requestParameters.endYear, requestParameters.includePredictions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {DividendsApiDividendCalendarRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendCalendar(requestParameters: DividendsApiDividendCalendarRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendCalendarRecordList> {
            return localVarFp.dividendCalendar(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result.                      Note that the attribute \'month\' starts                      with zero for January, up to 11 for December. Use \'calendarMonth\' for a month value ranging                      from 1 to 12.
         * @param {DividendsApiDividendHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendHistory(requestParameters: DividendsApiDividendHistoryRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.dividendHistory(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.cutoffDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {DividendsApiDividendPredictionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendPrediction(requestParameters: DividendsApiDividendPredictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.dividendPrediction(requestParameters.predictYears, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.yearlyPortfolioIncrease, requestParameters.dividendPerShare, requestParameters.dividendForPortfolio, requestParameters.inAccountCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Actuals and predictions for the current year is combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {DividendsApiDividendTimelineRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendTimeline(requestParameters: DividendsApiDividendTimelineRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.dividendTimeline(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {DividendsApiDividendTimelineSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendTimelineSummary(requestParameters: DividendsApiDividendTimelineSummaryRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendTimelineSummary> {
            return localVarFp.dividendTimelineSummary(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {DividendsApiDividendYieldRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dividendYield(requestParameters: DividendsApiDividendYieldRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendYieldList> {
            return localVarFp.dividendYield(requestParameters.accountId, requestParameters.calculationType, requestParameters.from, requestParameters.cagrYears, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of historic dividends for the given investment. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {DividendsApiGetDividendsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividends(requestParameters: DividendsApiGetDividendsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendList> {
            return localVarFp.getDividends(requestParameters.id, requestParameters.taxRate, requestParameters.cutoffDate, requestParameters.splitAdjust, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get aggregated per-year totals of dividends for the given investment. Optionally, estimates for                      following years will be included.
         * @param {DividendsApiGetDividendsPerYearRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendsPerYear(requestParameters: DividendsApiGetDividendsPerYearRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendPerYearList> {
            return localVarFp.getDividendsPerYear(requestParameters.investmentId, requestParameters.isin, requestParameters.currency, requestParameters.predictNumberOfYears, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list dividends for the given investments. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
         * @param {DividendsApiListAndSearchAllDividendsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDividends(requestParameters: DividendsApiListAndSearchAllDividendsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendList> {
            return localVarFp.listAndSearchAllDividends(requestParameters.accountId, requestParameters.investmentId, requestParameters.isin, requestParameters.taxRate, requestParameters.cutoffDate, requestParameters.activeInvestmentsOnly, requestParameters.excludeImported, requestParameters.splitAdjust, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
         * @param {DividendsApiPublicDividendAllocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendAllocation(requestParameters: DividendsApiPublicDividendAllocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendAllocationList> {
            return localVarFp.publicDividendAllocation(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.startYear, requestParameters.endYear, requestParameters.includePredictions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
         * @param {DividendsApiPublicDividendCalendarRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendCalendar(requestParameters: DividendsApiPublicDividendCalendarRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendCalendarRecordList> {
            return localVarFp.publicDividendCalendar(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {DividendsApiPublicDividendHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendHistory(requestParameters: DividendsApiPublicDividendHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.publicDividendHistory(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.cutoffDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
         * @param {DividendsApiPublicDividendPredictionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendPrediction(requestParameters: DividendsApiPublicDividendPredictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.publicDividendPrediction(requestParameters.publicId, requestParameters.predictYears, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.yearlyPortfolioIncrease, requestParameters.dividendPerShare, requestParameters.dividendForPortfolio, requestParameters.inAccountCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Data for the current year is combined in a smart way, leading to predictions becoming more and                      more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
         * @param {DividendsApiPublicDividendTimelineRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendTimeline(requestParameters: DividendsApiPublicDividendTimelineRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendHistoryTree> {
            return localVarFp.publicDividendTimeline(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
         * @param {DividendsApiPublicDividendTimelineSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendTimelineSummary(requestParameters: DividendsApiPublicDividendTimelineSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendTimelineSummary> {
            return localVarFp.publicDividendTimelineSummary(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the dividend yield for the given account(s).
         * @param {DividendsApiPublicDividendYieldRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicDividendYield(requestParameters: DividendsApiPublicDividendYieldRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendYieldList> {
            return localVarFp.publicDividendYield(requestParameters.publicId, requestParameters.accountId, requestParameters.calculationType, requestParameters.from, requestParameters.cagrYears, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dividendAllocation operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendAllocationRequest
 */
export interface DividendsApiDividendAllocationRequest {
    /**
     * Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendAllocation
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendAllocation
     */
    readonly investmentId?: Array<number>

    /**
     * Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendAllocation
     */
    readonly startYear?: number

    /**
     * Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendAllocation
     */
    readonly endYear?: number

    /**
     * Include dividend predictions for the current year if true. Default is true.
     * @type {boolean}
     * @memberof DividendsApiDividendAllocation
     */
    readonly includePredictions?: boolean
}

/**
 * Request parameters for dividendCalendar operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendCalendarRequest
 */
export interface DividendsApiDividendCalendarRequest {
    /**
     * Get a dividend calendar for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly accountId?: Array<number>

    /**
     * Get a dividend calendar for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly investmentId?: Array<number>

    /**
     * Get a dividend calendar for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly wkn?: Array<string>

    /**
     * Get a dividend calendar for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly isin?: Array<string>

    /**
     * Get a dividend calendar for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly type?: Array<DividendCalendarTypeEnum>

    /**
     * Get a dividend calendar for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly industryId?: Array<number>

    /**
     * Get a dividend calendar for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendCalendar
     */
    readonly regionId?: Array<number>
}

/**
 * Request parameters for dividendHistory operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendHistoryRequest
 */
export interface DividendsApiDividendHistoryRequest {
    /**
     * Retrieve the dividend history for the given account(s); limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendHistory
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendHistory
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve the dividend history for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiDividendHistory
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve the dividend history for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiDividendHistory
     */
    readonly isin?: Array<string>

    /**
     * Retrieve the dividend history for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiDividendHistory
     */
    readonly type?: Array<DividendHistoryTypeEnum>

    /**
     * Retrieve the dividend history for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendHistory
     */
    readonly industryId?: Array<number>

    /**
     * Retrieve the dividend history for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendHistory
     */
    readonly regionId?: Array<number>

    /**
     * Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
     * @type {string}
     * @memberof DividendsApiDividendHistory
     */
    readonly cutoffDate?: string
}

/**
 * Request parameters for dividendPrediction operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendPredictionRequest
 */
export interface DividendsApiDividendPredictionRequest {
    /**
     * Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
     * @type {number}
     * @memberof DividendsApiDividendPrediction
     */
    readonly predictYears: number

    /**
     * Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly accountId?: Array<number>

    /**
     * Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly investmentId?: Array<number>

    /**
     * Project the future dividend payments for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly wkn?: Array<string>

    /**
     * Project the future dividend payments for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly isin?: Array<string>

    /**
     * Project the future dividend payments for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly type?: Array<DividendPredictionTypeEnum>

    /**
     * Project the future dividend payments for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly industryId?: Array<number>

    /**
     * Project the future dividend payments for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendPrediction
     */
    readonly regionId?: Array<number>

    /**
     * Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
     * @type {number}
     * @memberof DividendsApiDividendPrediction
     */
    readonly yearlyPortfolioIncrease?: number

    /**
     * Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
     * @type {boolean}
     * @memberof DividendsApiDividendPrediction
     */
    readonly dividendPerShare?: boolean

    /**
     * Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
     * @type {boolean}
     * @memberof DividendsApiDividendPrediction
     */
    readonly dividendForPortfolio?: boolean

    /**
     * Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
     * @type {boolean}
     * @memberof DividendsApiDividendPrediction
     */
    readonly inAccountCurrency?: boolean
}

/**
 * Request parameters for dividendTimeline operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendTimelineRequest
 */
export interface DividendsApiDividendTimelineRequest {
    /**
     * Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly accountId?: Array<number>

    /**
     * Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly investmentId?: Array<number>

    /**
     * Project dividend timeline for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly wkn?: Array<string>

    /**
     * Project dividend timeline for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly isin?: Array<string>

    /**
     * Project dividend timeline for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly type?: Array<DividendTimelineTypeEnum>

    /**
     * Project dividend timeline for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly industryId?: Array<number>

    /**
     * Project dividend timeline for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimeline
     */
    readonly regionId?: Array<number>

    /**
     * Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendTimeline
     */
    readonly startYear?: number

    /**
     * Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendTimeline
     */
    readonly endYear?: number

    /**
     * Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
     * @type {'payoutDate' | 'exDate'}
     * @memberof DividendsApiDividendTimeline
     */
    readonly groupBy?: DividendTimelineGroupByEnum
}

/**
 * Request parameters for dividendTimelineSummary operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendTimelineSummaryRequest
 */
export interface DividendsApiDividendTimelineSummaryRequest {
    /**
     * Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly accountId?: Array<number>

    /**
     * Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly investmentId?: Array<number>

    /**
     * Project dividend timeline summary for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly wkn?: Array<string>

    /**
     * Project dividend timeline summary for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly isin?: Array<string>

    /**
     * Project dividend timeline summary for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly type?: Array<DividendTimelineSummaryTypeEnum>

    /**
     * Project dividend timeline summary for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly industryId?: Array<number>

    /**
     * Project dividend timeline summary for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly regionId?: Array<number>

    /**
     * Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly startYear?: number

    /**
     * Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly endYear?: number

    /**
     * Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
     * @type {'payoutDate' | 'exDate'}
     * @memberof DividendsApiDividendTimelineSummary
     */
    readonly groupBy?: DividendTimelineSummaryGroupByEnum
}

/**
 * Request parameters for dividendYield operation in DividendsApi.
 * @export
 * @interface DividendsApiDividendYieldRequest
 */
export interface DividendsApiDividendYieldRequest {
    /**
     * Retrieve the dividend yield for the given account(s). Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiDividendYield
     */
    readonly accountId: Array<number>

    /**
     * The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
     * @type {'actual' | 'predicted'}
     * @memberof DividendsApiDividendYield
     */
    readonly calculationType?: DividendYieldCalculationTypeEnum

    /**
     * Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof DividendsApiDividendYield
     */
    readonly from?: string

    /**
     * Calculate the CAGR for the given number of years. Default is 5.
     * @type {number}
     * @memberof DividendsApiDividendYield
     */
    readonly cagrYears?: number
}

/**
 * Request parameters for getDividends operation in DividendsApi.
 * @export
 * @interface DividendsApiGetDividendsRequest
 */
export interface DividendsApiGetDividendsRequest {
    /**
     * Retrieve Dividends for the given investment
     * @type {number}
     * @memberof DividendsApiGetDividends
     */
    readonly id: number

    /**
     * Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
     * @type {number}
     * @memberof DividendsApiGetDividends
     */
    readonly taxRate?: number

    /**
     * Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof DividendsApiGetDividends
     */
    readonly cutoffDate?: string

    /**
     * Apply splits to dividends; default is false.
     * @type {boolean}
     * @memberof DividendsApiGetDividends
     */
    readonly splitAdjust?: boolean
}

/**
 * Request parameters for getDividendsPerYear operation in DividendsApi.
 * @export
 * @interface DividendsApiGetDividendsPerYearRequest
 */
export interface DividendsApiGetDividendsPerYearRequest {
    /**
     * Retrieve Dividends for the given investment
     * @type {number}
     * @memberof DividendsApiGetDividendsPerYear
     */
    readonly investmentId?: number

    /**
     * Retrieve Dividends for the given ISIN
     * @type {string}
     * @memberof DividendsApiGetDividendsPerYear
     */
    readonly isin?: string

    /**
     * This parameter is only used in combination with \&#39;isin\&#39;. Convert dividends into the given                              currency; default is EUR.
     * @type {string}
     * @memberof DividendsApiGetDividendsPerYear
     */
    readonly currency?: string

    /**
     * Include a prediction for the given number of years.
     * @type {number}
     * @memberof DividendsApiGetDividendsPerYear
     */
    readonly predictNumberOfYears?: number
}

/**
 * Request parameters for listAndSearchAllDividends operation in DividendsApi.
 * @export
 * @interface DividendsApiListAndSearchAllDividendsRequest
 */
export interface DividendsApiListAndSearchAllDividendsRequest {
    /**
     * Retrieve Dividends for the given account(s). Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Dividends for the given Investment(s). Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve Dividends for the given ISINS. Limited to 300 ISINs.
     * @type {Array<string>}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly isin?: Array<string>

    /**
     * Apply the given tax rate when calculating the dividend amounts; by default, the users                              standard tax rate is used. Must be something between 0 and 1.
     * @type {number}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly taxRate?: number

    /**
     * Only retrieve dividends with payment date after the given cutoff; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly cutoffDate?: string

    /**
     * Only include investments with number of lots &gt; 0; default is true
     * @type {boolean}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly activeInvestmentsOnly?: boolean

    /**
     * Exclude dividends for investments that have been imported via banking API and for which                              transaction data is available; default is true.
     * @type {Array<boolean>}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly excludeImported?: Array<boolean>

    /**
     * Apply splits to dividends; default is false.
     * @type {boolean}
     * @memberof DividendsApiListAndSearchAllDividends
     */
    readonly splitAdjust?: boolean
}

/**
 * Request parameters for publicDividendAllocation operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendAllocationRequest
 */
export interface DividendsApiPublicDividendAllocationRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly publicId: string

    /**
     * Retrieve the dividend allocations for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the dividend allocations for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly investmentId?: Array<number>

    /**
     * Cutoff the allocation at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly startYear?: number

    /**
     * Cutoff the allocation at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly endYear?: number

    /**
     * Include dividend predictions for the current year if true. Default is true.
     * @type {boolean}
     * @memberof DividendsApiPublicDividendAllocation
     */
    readonly includePredictions?: boolean
}

/**
 * Request parameters for publicDividendCalendar operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendCalendarRequest
 */
export interface DividendsApiPublicDividendCalendarRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendCalendar
     */
    readonly publicId: string

    /**
     * Retrieve the dividend history for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendCalendar
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the dividend history for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendCalendar
     */
    readonly investmentId?: Array<number>
}

/**
 * Request parameters for publicDividendHistory operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendHistoryRequest
 */
export interface DividendsApiPublicDividendHistoryRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly publicId: string

    /**
     * Retrieve the dividend history for the given account(s); limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve the dividend history for the given Investment(s). This parameter is ignored if                              account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly investmentId?: Array<number>

    /**
     * Retrieve the dividend history for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve the dividend history for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly isin?: Array<string>

    /**
     * Retrieve the dividend history for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly type?: Array<PublicDividendHistoryTypeEnum>

    /**
     * Retrieve the dividend history for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly industryId?: Array<number>

    /**
     * Retrieve the dividend history for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly regionId?: Array<number>

    /**
     * Only include dividends with payment date after the given cutoff;                         format is yyyy-MM-dd (US Format). Default is no cutoff.
     * @type {string}
     * @memberof DividendsApiPublicDividendHistory
     */
    readonly cutoffDate?: string
}

/**
 * Request parameters for publicDividendPrediction operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendPredictionRequest
 */
export interface DividendsApiPublicDividendPredictionRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly publicId: string

    /**
     * Predict dividends for N years into the future, starting with the current year. So if                              the current year is 2018 and projectYears is 2, we will receive projections for 2018 and                              2019.
     * @type {number}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly predictYears: number

    /**
     * Project the future dividend payments for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly accountId?: Array<number>

    /**
     * Project the future dividend payments for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly investmentId?: Array<number>

    /**
     * Project the future dividend payments for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly wkn?: Array<string>

    /**
     * Project the future dividend payments for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly isin?: Array<string>

    /**
     * Project the future dividend payments for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly type?: Array<PublicDividendPredictionTypeEnum>

    /**
     * Project the future dividend payments for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly industryId?: Array<number>

    /**
     * Project the future dividend payments for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly regionId?: Array<number>

    /**
     * Include a yearly portfolio increase (in percent) into the projection. Projected dividends                              will then also grow with the portfolio valuation. The value is given in percent, with                              1 &#x3D; 100%. No increase is added if this parameter is not given.
     * @type {number}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly yearlyPortfolioIncrease?: number

    /**
     * Calculate the dividend per share if true. If false, the dividend amount will include the                              respective number of lots. Default is false.
     * @type {boolean}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly dividendPerShare?: boolean

    /**
     * Calculate the dividend with the current number of lots if true. If false, the dividend amount                             will include the respective number of lots. Default is false.                              Note: dividendPerShare flag will be overwritten if this is set to true.
     * @type {boolean}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly dividendForPortfolio?: boolean

    /**
     * Return the dividend estimate in account currency (currently fixed to EUR) if true; in                              investment currency if false. Note that in case we query values for more then one                              investment, this will automatically be forced to true to avoid currency conversion related errors
     * @type {boolean}
     * @memberof DividendsApiPublicDividendPrediction
     */
    readonly inAccountCurrency?: boolean
}

/**
 * Request parameters for publicDividendTimeline operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendTimelineRequest
 */
export interface DividendsApiPublicDividendTimelineRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly publicId: string

    /**
     * Project dividend timeline for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly accountId?: Array<number>

    /**
     * Project dividend timeline for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly investmentId?: Array<number>

    /**
     * Project dividend timeline for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly wkn?: Array<string>

    /**
     * Project dividend timeline for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly isin?: Array<string>

    /**
     * Project dividend timeline for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly type?: Array<PublicDividendTimelineTypeEnum>

    /**
     * Project dividend timeline for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly industryId?: Array<number>

    /**
     * Project dividend timeline for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly regionId?: Array<number>

    /**
     * Cutoff the timeline at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly startYear?: number

    /**
     * Cutoff the timeline at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly endYear?: number

    /**
     * Specifies the date used to build the tree structure in the response.                              Changing the flag can affect calculated values in the response.                              This is because the organization of data impacts how certain aggregations and computed metrics are derived.                              Default is payoutDate.
     * @type {'payoutDate' | 'exDate'}
     * @memberof DividendsApiPublicDividendTimeline
     */
    readonly groupBy?: PublicDividendTimelineGroupByEnum
}

/**
 * Request parameters for publicDividendTimelineSummary operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendTimelineSummaryRequest
 */
export interface DividendsApiPublicDividendTimelineSummaryRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly publicId: string

    /**
     * Project dividend timeline summary for the given account(s). Either accountIds or InvestmentIds                              must be provided. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly accountId?: Array<number>

    /**
     * Project dividend timeline summary for the given Investment(s). Either accountIds or InvestmentIds                              must be provided.This parameter is ignored if account IDs are given. Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly investmentId?: Array<number>

    /**
     * Project dividend timeline summary for Investments with the given WKNs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly wkn?: Array<string>

    /**
     * Project dividend timeline summary for Investments with the given ISINs
     * @type {Array<string>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly isin?: Array<string>

    /**
     * Project dividend timeline summary for Investments with the given asset types
     * @type {Array<'11_stock' | '21_fund' | '31_bond' | '41_cash' | '51_certos' | '61_pmetals' | '71_massets' | '81_fcurr' | '22_etf' | '91_managed'>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly type?: Array<PublicDividendTimelineSummaryTypeEnum>

    /**
     * Project dividend timeline summary for Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly industryId?: Array<number>

    /**
     * Project dividend timeline summary for Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly regionId?: Array<number>

    /**
     * Cutoff the timeline summary at the given year. Default is 2 years back from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly startYear?: number

    /**
     * Cutoff the timeline summary at the given year. Default is 2 years forward from today. Dividends data for this year                              will be included.
     * @type {number}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly endYear?: number

    /**
     * Specifies the date used to summarize the date per year.                              Changing the flag can affect calculated values in case payout and ex date fall into                              different years. Default is to use payoutDate for aggregation.
     * @type {'payoutDate' | 'exDate'}
     * @memberof DividendsApiPublicDividendTimelineSummary
     */
    readonly groupBy?: PublicDividendTimelineSummaryGroupByEnum
}

/**
 * Request parameters for publicDividendYield operation in DividendsApi.
 * @export
 * @interface DividendsApiPublicDividendYieldRequest
 */
export interface DividendsApiPublicDividendYieldRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof DividendsApiPublicDividendYield
     */
    readonly publicId: string

    /**
     * Retrieve the dividend yield for the given account(s). Limited to 300 investments.
     * @type {Array<number>}
     * @memberof DividendsApiPublicDividendYield
     */
    readonly accountId: Array<number>

    /**
     * The type of calculation to perform.                              If \&#39;predicted\&#39; is given, the dividend yield will be calculated including the dividend                              predictions for the current year. Default is \&#39;actual\&#39;.
     * @type {'actual' | 'predicted'}
     * @memberof DividendsApiPublicDividendYield
     */
    readonly calculationType?: PublicDividendYieldCalculationTypeEnum

    /**
     * Only include dividends with payment date after the given cutoff; Default is 5 years back from today; format is yyyy-MM-dd (US Format).
     * @type {string}
     * @memberof DividendsApiPublicDividendYield
     */
    readonly from?: string

    /**
     * Calculate the CAGR for the given number of years. Default is 5.
     * @type {number}
     * @memberof DividendsApiPublicDividendYield
     */
    readonly cagrYears?: number
}

/**
 * DividendsApi - object-oriented interface
 * @export
 * @class DividendsApi
 * @extends {BaseAPI}
 */
export class DividendsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
     * @param {DividendsApiDividendAllocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendAllocation(requestParameters: DividendsApiDividendAllocationRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendAllocation(requestParameters.accountId, requestParameters.investmentId, requestParameters.startYear, requestParameters.endYear, requestParameters.includePredictions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
     * @param {DividendsApiDividendCalendarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendCalendar(requestParameters: DividendsApiDividendCalendarRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendCalendar(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result.                      Note that the attribute \'month\' starts                      with zero for January, up to 11 for December. Use \'calendarMonth\' for a month value ranging                      from 1 to 12.
     * @param {DividendsApiDividendHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendHistory(requestParameters: DividendsApiDividendHistoryRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendHistory(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.cutoffDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
     * @param {DividendsApiDividendPredictionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendPrediction(requestParameters: DividendsApiDividendPredictionRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendPrediction(requestParameters.predictYears, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.yearlyPortfolioIncrease, requestParameters.dividendPerShare, requestParameters.dividendForPortfolio, requestParameters.inAccountCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project dividend timeline between given years including historic as well as predicted data.                      Actuals and predictions for the current year is combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
     * @param {DividendsApiDividendTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendTimeline(requestParameters: DividendsApiDividendTimelineRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendTimeline(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
     * @param {DividendsApiDividendTimelineSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendTimelineSummary(requestParameters: DividendsApiDividendTimelineSummaryRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendTimelineSummary(requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the dividend yield for the given account(s).
     * @param {DividendsApiDividendYieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public dividendYield(requestParameters: DividendsApiDividendYieldRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).dividendYield(requestParameters.accountId, requestParameters.calculationType, requestParameters.from, requestParameters.cagrYears, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of historic dividends for the given investment. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
     * @param {DividendsApiGetDividendsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public getDividends(requestParameters: DividendsApiGetDividendsRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).getDividends(requestParameters.id, requestParameters.taxRate, requestParameters.cutoffDate, requestParameters.splitAdjust, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get aggregated per-year totals of dividends for the given investment. Optionally, estimates for                      following years will be included.
     * @param {DividendsApiGetDividendsPerYearRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public getDividendsPerYear(requestParameters: DividendsApiGetDividendsPerYearRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).getDividendsPerYear(requestParameters.investmentId, requestParameters.isin, requestParameters.currency, requestParameters.predictNumberOfYears, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list dividends for the given investments. Dividends without a transaction                      record have not been assigned; dividends with a transaction record were already booked. Use the                      \'number of lots\' attribute of each dividend record to determine, if any according investments                      were held at dividend payment date.
     * @param {DividendsApiListAndSearchAllDividendsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public listAndSearchAllDividends(requestParameters: DividendsApiListAndSearchAllDividendsRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).listAndSearchAllDividends(requestParameters.accountId, requestParameters.investmentId, requestParameters.isin, requestParameters.taxRate, requestParameters.cutoffDate, requestParameters.activeInvestmentsOnly, requestParameters.excludeImported, requestParameters.splitAdjust, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the dividend allocations for each unique ISIN.                     It sums up the dividend amounts from all investments associated with the same ISIN                      and returns the aggregated results.
     * @param {DividendsApiPublicDividendAllocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendAllocation(requestParameters: DividendsApiPublicDividendAllocationRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendAllocation(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.startYear, requestParameters.endYear, requestParameters.includePredictions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dividend calendar / dividend rating for the current year. The calendar consists of a list                      of all actual and predicted dividend payments. Note that the attribute \'monthOfPayment\' starts with                      zero for January, up to 11 for December.
     * @param {DividendsApiPublicDividendCalendarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendCalendar(requestParameters: DividendsApiPublicDividendCalendarRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendCalendar(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the aggregated dividend history by month or year for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
     * @param {DividendsApiPublicDividendHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendHistory(requestParameters: DividendsApiPublicDividendHistoryRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendHistory(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.cutoffDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project future dividend payments for the given accounts or investments. The                      operation delivers aggregated dividends in a tree-like structure, with nodes representing years                      and months, respectively. Each node holds a list of the IDs of all investments that contributed                      a dividend payment. Investment IDs are assigned to investment references using the                      investmentReferenceById hash also included in the result. Note that the attribute \'month\' starts                      with zero for January, up to 11 for December.
     * @param {DividendsApiPublicDividendPredictionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendPrediction(requestParameters: DividendsApiPublicDividendPredictionRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendPrediction(requestParameters.publicId, requestParameters.predictYears, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.yearlyPortfolioIncrease, requestParameters.dividendPerShare, requestParameters.dividendForPortfolio, requestParameters.inAccountCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project dividend timeline between given years including historic as well as predicted data.                      Data for the current year is combined in a smart way, leading to predictions becoming more and                      more accurate as the years goes on.                      The operation delivers portfolio-wide aggregated dividends in a                      tree-like structure, with nodes representing years and months, respectively. Each node holds a                      list of the IDs of all investments that contributed a dividend payment. Investment IDs are                      assigned to investment references using the investmentReferenceById hash also included in the result.
     * @param {DividendsApiPublicDividendTimelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendTimeline(requestParameters: DividendsApiPublicDividendTimelineRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendTimeline(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Project dividend timeline between given years including historic as well as predicted data.                      Summarize (aggregate) the date for each investment by year; leaving only a single record per                      year and position.                      Actuals and estimates for the current year are combined in a smart way, leading to predictions                      becoming more and more accurate as the years goes on.
     * @param {DividendsApiPublicDividendTimelineSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendTimelineSummary(requestParameters: DividendsApiPublicDividendTimelineSummaryRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendTimelineSummary(requestParameters.publicId, requestParameters.accountId, requestParameters.investmentId, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.industryId, requestParameters.regionId, requestParameters.startYear, requestParameters.endYear, requestParameters.groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the dividend yield for the given account(s).
     * @param {DividendsApiPublicDividendYieldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public publicDividendYield(requestParameters: DividendsApiPublicDividendYieldRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).publicDividendYield(requestParameters.publicId, requestParameters.accountId, requestParameters.calculationType, requestParameters.from, requestParameters.cagrYears, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DividendCalendarTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type DividendCalendarTypeEnum = typeof DividendCalendarTypeEnum[keyof typeof DividendCalendarTypeEnum];
/**
 * @export
 */
export const DividendHistoryTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type DividendHistoryTypeEnum = typeof DividendHistoryTypeEnum[keyof typeof DividendHistoryTypeEnum];
/**
 * @export
 */
export const DividendPredictionTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type DividendPredictionTypeEnum = typeof DividendPredictionTypeEnum[keyof typeof DividendPredictionTypeEnum];
/**
 * @export
 */
export const DividendTimelineTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type DividendTimelineTypeEnum = typeof DividendTimelineTypeEnum[keyof typeof DividendTimelineTypeEnum];
/**
 * @export
 */
export const DividendTimelineGroupByEnum = {
    PAYOUT_DATE: 'payoutDate',
    EX_DATE: 'exDate'
} as const;
export type DividendTimelineGroupByEnum = typeof DividendTimelineGroupByEnum[keyof typeof DividendTimelineGroupByEnum];
/**
 * @export
 */
export const DividendTimelineSummaryTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type DividendTimelineSummaryTypeEnum = typeof DividendTimelineSummaryTypeEnum[keyof typeof DividendTimelineSummaryTypeEnum];
/**
 * @export
 */
export const DividendTimelineSummaryGroupByEnum = {
    PAYOUT_DATE: 'payoutDate',
    EX_DATE: 'exDate'
} as const;
export type DividendTimelineSummaryGroupByEnum = typeof DividendTimelineSummaryGroupByEnum[keyof typeof DividendTimelineSummaryGroupByEnum];
/**
 * @export
 */
export const DividendYieldCalculationTypeEnum = {
    ACTUAL: 'actual',
    PREDICTED: 'predicted'
} as const;
export type DividendYieldCalculationTypeEnum = typeof DividendYieldCalculationTypeEnum[keyof typeof DividendYieldCalculationTypeEnum];
/**
 * @export
 */
export const PublicDividendHistoryTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type PublicDividendHistoryTypeEnum = typeof PublicDividendHistoryTypeEnum[keyof typeof PublicDividendHistoryTypeEnum];
/**
 * @export
 */
export const PublicDividendPredictionTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type PublicDividendPredictionTypeEnum = typeof PublicDividendPredictionTypeEnum[keyof typeof PublicDividendPredictionTypeEnum];
/**
 * @export
 */
export const PublicDividendTimelineTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type PublicDividendTimelineTypeEnum = typeof PublicDividendTimelineTypeEnum[keyof typeof PublicDividendTimelineTypeEnum];
/**
 * @export
 */
export const PublicDividendTimelineGroupByEnum = {
    PAYOUT_DATE: 'payoutDate',
    EX_DATE: 'exDate'
} as const;
export type PublicDividendTimelineGroupByEnum = typeof PublicDividendTimelineGroupByEnum[keyof typeof PublicDividendTimelineGroupByEnum];
/**
 * @export
 */
export const PublicDividendTimelineSummaryTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;
export type PublicDividendTimelineSummaryTypeEnum = typeof PublicDividendTimelineSummaryTypeEnum[keyof typeof PublicDividendTimelineSummaryTypeEnum];
/**
 * @export
 */
export const PublicDividendTimelineSummaryGroupByEnum = {
    PAYOUT_DATE: 'payoutDate',
    EX_DATE: 'exDate'
} as const;
export type PublicDividendTimelineSummaryGroupByEnum = typeof PublicDividendTimelineSummaryGroupByEnum[keyof typeof PublicDividendTimelineSummaryGroupByEnum];
/**
 * @export
 */
export const PublicDividendYieldCalculationTypeEnum = {
    ACTUAL: 'actual',
    PREDICTED: 'predicted'
} as const;
export type PublicDividendYieldCalculationTypeEnum = typeof PublicDividendYieldCalculationTypeEnum[keyof typeof PublicDividendYieldCalculationTypeEnum];
