import { useTranslation } from "react-i18next";
import { BookingTypeEnum, InvestmentTypeEnum } from "@api";
import { toGermanNumberFormat, toCurrency, toPercent } from "@helpers";

export const useLabelHelpers = () => {
  const { t } = useTranslation();

  const nolDescription = (type?: InvestmentTypeEnum) => {
    if (type === "31_bond")
      return t("transactionWizard.priceAndDateStep.numberOfLotsPercent");
    if (type === "61_pmetals")
      return t("transactionWizard.priceAndDateStep.numberOfLotsGram");
    return t("transactionWizard.priceAndDateStep.numberOfLots");
  };

  const nolSummaryDescription = (type?: InvestmentTypeEnum) => {
    if (type === "31_bond")
      return t("transactionWizard.priceAndDateStep.numberOfLotsPercent");
    if (type === "61_pmetals")
      return t("transactionWizard.priceAndDateStep.numberOfLotsOunces");
    return t("transactionWizard.priceAndDateStep.numberOfLots");
  };

  const entryQuoteDescription = (type?: BookingTypeEnum) => {
    if (type === "book_out")
      return t("transactionWizard.priceAndDateStep.bookOutPrice");
    return t("transactionWizard.priceAndDateStep.entryQuote");
  };

  const nolLabel = (type?: InvestmentTypeEnum, currencySymbol?: string) => {
    if (type === "31_bond") return currencySymbol;
    if (type === "61_pmetals")
      return t("transactionWizard.priceAndDateStep.gram");
    return t("transactionWizard.priceAndDateStep.lots");
  };

  const nolSummaryLabel = (
    type?: InvestmentTypeEnum,
    currencySymbol?: string
  ) => {
    if (type === "31_bond") return currencySymbol;
    if (type === "61_pmetals")
      return t("transactionWizard.priceAndDateStep.ounces");
    return t("transactionWizard.priceAndDateStep.lots");
  };

  const formatPrice = (
    currencySymbol: string | "%" | undefined,
    price?: number
  ) => {
    if (currencySymbol === "%") return toPercent((price ?? 0) / 100);

    if (!currencySymbol) return toGermanNumberFormat(price ?? 0);

    return toCurrency(price ?? 0, currencySymbol, {
      minimumFractionDigits: 2,
    });
  };

  const priceLabel = (type?: InvestmentTypeEnum, currencySymbol?: string) => {
    if (type === "31_bond") return "%";
    return currencySymbol;
  };

  return {
    nolLabel,
    nolSummaryLabel,
    nolDescription,
    nolSummaryDescription,
    formatPrice,
    priceLabel,
    entryQuoteDescription,
  };
};
