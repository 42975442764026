import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "@components";
import { Dividend } from "@features/dividendPlanner/components/DividendsTable/useDividendsForTable";
import { DividendHistoryTreeNode } from "@generated/apiv1";

type Props = {
  dividend: Dividend | DividendHistoryTreeNode;
  small?: boolean;
};

export const DividendStatus = ({ dividend, small }: Props) => {
  const { t } = useTranslation();

  if (small) {
    return (
      <Badge
        variant={dividend.isPrediction ? "warning" : "success"}
        px={1}
        py={0.25}
        sx={{ borderRadius: "4px" }}
        typographyVariant="overline"
      >
        {t(
          `dividends.table.shortStatuses.${
            dividend.isPrediction
              ? "prediction"
              : dividend.isOverdue
              ? "notReceived"
              : "received"
          }`
        )}
      </Badge>
    );
  }

  return (
    <Badge variant={dividend.isPrediction ? "warning" : "success"}>
      {t(
        `dividends.table.statuses.${
          dividend.isPrediction
            ? "prediction"
            : dividend.isOverdue
            ? "notReceived"
            : "received"
        }`
      )}
    </Badge>
  );
};
