import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { WizardLayout, WizardsHeader } from "@components";

const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export interface Props {
  onSubmit: () => void;
}

export const LegalNote = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <WizardLayout
      header={
        <WizardsHeader
          title={t("bankConnections.legalNote.title")}
          backUrl={"/"}
          variant="dark"
        />
      }
    >
      <Container data-testid="legal-note-first-import">
        <Spacer>
          <Typography variant="body1">
            {t("bankConnections.legalNote.finApi")}
          </Typography>
        </Spacer>
        <Spacer>
          <Typography variant="body1">
            {t("bankConnections.legalNote.dataProtection")}
          </Typography>
        </Spacer>

        <Button
          onClick={onSubmit}
          type="submit"
          color="primary"
          variant={theme.bankConnection?.button?.variant || "contained"}
          disableElevation
          data-testid="submit-button"
          endIcon={<ArrowForwardIosIcon />}
        >
          {t("bankConnections.legalNote.next")}
        </Button>
      </Container>
    </WizardLayout>
  );
};
