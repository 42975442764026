import React from "react";
import {
  Box,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { BarChartToolbar, BarChartYear } from "./components";
import { BarChartMode, BarChartItem } from "./types";

const BarChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
}));

const Circle = styled("div")<{ color: string }>(({ color }) => ({
  width: "8px",
  height: "8px",
  backgroundColor: color,
  borderRadius: "50%",
  flexShrink: 0,
}));

export type BarChartProps = {
  data: BarChartItem[];
  previousYearData: BarChartItem[];
  nextYearData: BarChartItem[];
  onMainPeriodChange: (period: Required<RangeType>) => void;
  mainPeriod: Required<RangeType>;
  onSubPeriodChange: (period: Required<RangeType>) => void;
  isLoading: boolean;
  mode: BarChartMode;
  onModeChange: (mode: BarChartMode) => void;
};

export const BarChart = ({
  data,
  previousYearData,
  nextYearData,
  onMainPeriodChange,
  mainPeriod,
  onSubPeriodChange,
  isLoading,
  mode,
  onModeChange,
}: BarChartProps) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const theme = useTheme();
  const [selectedBar, setSelectedBar] = React.useState<string | null>(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [isAnimationEnabled, setIsAnimationEnabled] = React.useState(true);
  const [transitionDirection, setTransitionDirection] = React.useState<
    "left" | "right" | null
  >(null);

  const handleModeChange = (newMode: BarChartMode) => {
    if (mode === "custom") {
      resetCustomPeriod();
    }
    onModeChange(newMode);
  };

  const handlePeriodSelect = (from: Date, to: Date) => {
    onSubPeriodChange({ startDate: from, endDate: to });
    onMainPeriodChange({ startDate: from, endDate: to });
    // this is to avoid double rerenders when mode changes and data is not changed, not the best solution but others seem to be more complex
    setTimeout(() => {
      onModeChange("custom");
      setSelectedBar("custom-period");
    }, 300);
  };

  const resetCustomPeriod = () => {
    onMainPeriodChange({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    });
    onSubPeriodChange({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    });
    onModeChange("monthly");
    setSelectedBar(format(startOfMonth(new Date()), "yyyy-MM-dd"));
  };

  const handleMainPeriodChange = (period: Required<RangeType>) => {
    setIsAnimationEnabled(false);
    setTransitionDirection(
      period.startDate < mainPeriod.startDate ? "left" : "right"
    );

    setTimeout(() => {
      setTransitionDirection(null);
      onMainPeriodChange(period);
      if (!selectedBar) {
        onSubPeriodChange(period);
      }
    }, 300);
  };

  const onBarClick = (item: BarChartItem) => {
    if (mode === "custom") return;

    if (selectedBar === item.id) {
      setSelectedBar(null);
      onSubPeriodChange({
        startDate: mainPeriod.startDate,
        endDate: mainPeriod.endDate,
      });
    } else {
      onSubPeriodChange({
        startDate: item.startDate,
        endDate: item.endDate,
      });
      setSelectedBar(item.id);
    }
  };

  const commonProps = {
    mode,
    mainPeriod,
    onBarClick,
    selectedBar,
    isAnimationEnabled,
    transitionDirection,
    isLoading,
  };

  return (
    <Box mb={6}>
      <Box display="flex" justifyContent="space-between">
        <BarChartToolbar
          onMainPeriodChange={handleMainPeriodChange}
          onModeChange={handleModeChange}
          mode={mode}
          onCustomPeriodSelect={handlePeriodSelect}
          onCustomPeriodReset={resetCustomPeriod}
          mainPeriod={mainPeriod}
        />
      </Box>
      <BarChartContainer>
        <BarChartYear data={previousYearData} {...commonProps} />
        <BarChartYear data={data} {...commonProps} />
        <BarChartYear data={nextYearData} {...commonProps} />
      </BarChartContainer>
      {!isLessSm && (
        <Box
          display="flex"
          justifyContent={isLessSm ? "center" : "flex-end"}
          gap={6}
        >
          <Box display="flex" alignItems="center" gap={1.5}>
            <Circle color={theme.palette.primary.dark} />
            <Typography variant="body2" color="textSecondary" fontWeight={400}>
              {t("cashflowAnalyzer.expenses")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1.5}>
            <Circle color={theme.palette.success.main} />
            <Typography variant="body2" color="textSecondary" fontWeight={400}>
              {t("cashflowAnalyzer.income")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
