import { UseMutationOptions } from "react-query";
import {
  DividendMasterDataRecordsApiDeleteDividendIdRequest,
  DividendMasterDataRecord,
} from "@generated/apiv1";

import { buildDividendMasterDataRecordsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteDividendId = async (
  token: string | undefined,
  params: DividendMasterDataRecordsApiDeleteDividendIdRequest
) => {
  const apiInstance = buildDividendMasterDataRecordsApi(token);
  const { data } = await apiInstance.deleteDividendId(params);
  return data;
};

export const useDeleteDividendId = (
  options?: UseMutationOptions<
    DividendMasterDataRecord,
    AxiosApiError,
    DividendMasterDataRecordsApiDeleteDividendIdRequest
  >
) => {
  return useAuthMutation(deleteDividendId, options, false);
};
