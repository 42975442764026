import { UseMutationOptions } from "react-query";
import { TrackingEventsApiTrackingEventsRequest } from "@generated/apiv3";

import { buildTrackingEventsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const trackingEvents = async (
  token: string | undefined,
  params: TrackingEventsApiTrackingEventsRequest
) => {
  const apiInstance = buildTrackingEventsApi(token);
  const { data } = await apiInstance.trackingEvents(params);
  return data;
};

export const useTrackingEvents = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TrackingEventsApiTrackingEventsRequest
  >
) => {
  return useAuthMutation(trackingEvents, options, false);
};
