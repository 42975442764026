import { isWithinInterval, parseJSON } from "date-fns";
import { DateValuePair } from "@api";
import { useAccountBalances } from "@api/v1/hooks/useAccountBalances";

const formatData = (
  data?: DateValuePair[]
): { value: number; date: Date }[] => {
  if (!data) return [];
  return data.map(({ date, value }) => ({
    date: parseJSON(date),
    value: Math.round(value * 100) / 100,
  }));
};

interface Params {
  accountId: number;
  startDate?: Date;
  endDate?: Date;
}

export const useAccountChartData = ({
  accountId,
  startDate,
  endDate,
}: Params) => {
  const { data, ...rest } = useAccountBalances({
    accountIds: [accountId],
  });

  const formattedData = formatData(data?.dateValuePairs);
  const chartDataInRange = formattedData.filter(({ date }) => {
    if (!startDate || !endDate) return true;
    return isWithinInterval(date, { start: startDate, end: endDate });
  });

  const chartData = chartDataInRange.map((item) => [
    item.date!.getTime(),
    item.value,
  ]);

  return {
    chartData,
    accountBalance:
      data?.dateValuePairs?.[data?.dateValuePairs?.length - 1]?.value,
    ...rest,
  };
};
