const stepGroups: Record<string, string[]> = {
  "select-bank": [
    "select-bank-with-other-bank-step",
    "select-existing-depot-step-select-bank-step",
    "select-bank-step",
  ],
  "select-workflow-type": [
    "select-new-or-existing-depot-step",
    "company-depot-terms-step",
  ],
  "register-or-signup-in-wizard": [
    "login",
    "email-step",
    "assign-password-step",
    "accept-terms-and-conditions-step",
  ],
  "natural-person": [
    "personal-information-summary-step",
    "select-gender-step",
    "first-name-step",
    "last-name-step",
    "country-of-birth-step",
    "place-of-birth-step",
    "date-of-birth-step",
    "name-of-birth-step",
    "address-step",
    "zip-step",
    "city-step",
    "address-country-step",
    "select-account-holder-type-step",
  ],
  nationality: [
    "country-of-nationality-step",
    "national-identifier-step",
    "another-nationality-step",
    "country-of-nationality-step",
    "national-identifier-step",
  ],
  "mifid-information": [
    "mifid-summary-step",
    "occupation-group-step",
    "occupation-step",
    "sector-step",
    "country-of-work-step",
    "access-to-insider-info-step",
    "annual-income-step",
    "liquid-assets-step",
  ],
  "investment-experience": [
    "investment-experience-summary-step",
    "experience-domestic-stocks-step",
    "experience-foreign-stocks-step",
    "experience-domestic-bonds-step",
    "experience-foreign-bonds-step",
    "experience-funds-step",
    "experience-certificates-and-options-without-leverage-step",
    "experience-certificates-and-options-with-leverage-step",
    "experience-foreign-currency-step",
    "experience-other-step",
  ],
  "bank-specific-details": [
    "account-identifier-step",
    "comdirect-condition-model-step",
    "comdirect-customer-number-step",
    "dab-condition-model-step",
    "fondsdepot-select-depot-type-step",
    "dab-mobile-for-tan-step",
  ],
  payout: [
    "payout-summary-step",
    "payout-iban-step",
    "payout-account-holder-first-name-step",
    "payout-account-holder-last-name-step",
  ],
  "contact-promotion": ["phone-number-step", "promotion-code-step"],
  summary: ["payout-summary-step", "next-steps-step", "contact-us-step"],
  taxation: [
    "taxation-summary-step",
    "country-of-taxation-step",
    "us-tax-liability-step",
    "us-tax-number-step",
    "taxation-one-more-step",
    "tax-number-step",
  ],
};

export default stepGroups;
