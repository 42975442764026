import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiShowPublicInvestmentRequest,
  Investment,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showPublicInvestment = async (
  token: string | undefined,
  params: InvestmentsApiShowPublicInvestmentRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.showPublicInvestment(params);
  return data;
};

export const useShowPublicInvestment = (
  params: InvestmentsApiShowPublicInvestmentRequest,
  options?: UseQueryOptions<Investment, AxiosApiError, Investment>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_SHOW_PUBLIC_INVESTMENT, params],
    ({ token }) => showPublicInvestment(token, params),
    options
  );
};
