import React from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";
import truncate from "lodash/truncate";
import { PageLayout, Tabs, Tab } from "@components";
import { useInvestment } from "@api/v1/hooks/useInvestment";
import { InvestmentEditQuoteData } from "./components/InvestmentEditQuoteData";
import { InvestmentEditAdvancedTab } from "./components/InvestmentEditAdvancedTab";
import { InvestmentEditBasicTab } from "./components/InvestmentEditBasicTab";

const CircularProgressStyled = styled(CircularProgress)`
  margin: ${({ theme }) => theme.spacing(6)};
`;

export interface Props {
  investmentId: number;
}

export const InvestmentEditPage = ({ investmentId }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: investment, isLoading, isIdle } = useInvestment(investmentId);

  const handleTabChange = (key: string) => {
    setSearchParams({ tab: key });
  };

  const defaultTabKey = searchParams.get("tab");

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: "/",
      text: t("breadcrumbs.home"),
    },
    {
      to: "/investments",
      text: t("breadcrumbs.investments"),
    },
    {
      to: `/investments/${investmentId}`,
      text: investment?.hasValidIsin
        ? investment?.isin
        : truncate(investment?.name),
    },
    {
      text: t("investmentEditPage.breadcrumbs.edit"),
    },
  ];

  if (isLoading) return <CircularProgressStyled />;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title={investment?.name ?? investment?.isin}
    >
      {!!investment && !isIdle ? (
        <Tabs onChange={handleTabChange} defaultKey={defaultTabKey}>
          <Tab label={t("investmentEditPage.tabs.basic")} key="basic">
            <InvestmentEditBasicTab
              investmentId={investment.id}
              investmentName={investment.name}
              investmentType={investment.type}
              depotId={investment.account.id}
              industry={investment.industry}
              region={investment.region}
              tagValue={investment.tag?.value}
            />
          </Tab>
          <Tab label={t("investmentEditPage.tabs.quoteData")} key="quoteData">
            <InvestmentEditQuoteData
              investmentId={investmentId}
              investmentISIN={investment.isin}
              investmentName={investment.name}
            />
          </Tab>
          <Tab label={t("investmentEditPage.tabs.advanced")} key="advanced">
            <InvestmentEditAdvancedTab
              investmentId={investmentId}
              investmentCurrency={investment.currency}
              investmentISIN={investment.isin}
              investmentWKN={investment.wkn}
              investmentType={investment.type}
              investmentName={investment.name}
            />
          </Tab>
        </Tabs>
      ) : (
        <Typography>{t("investmentEditPage.noInvestment")}</Typography>
      )}
    </PageLayout>
  );
};
