import { UseMutationOptions } from "react-query";
import {
  TppWebFormsApiV2CreateTppWebFormRequest,
  TppWebForm,
} from "@generated/apiv1";

import { buildTppWebFormsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const v2CreateTppWebForm = async (
  token: string | undefined,
  params: TppWebFormsApiV2CreateTppWebFormRequest
) => {
  const apiInstance = buildTppWebFormsApi(token);
  const { data } = await apiInstance.v2CreateTppWebForm(params);
  return data;
};

export const useV2CreateTppWebForm = (
  options?: UseMutationOptions<
    TppWebForm,
    AxiosApiError,
    TppWebFormsApiV2CreateTppWebFormRequest
  >
) => {
  return useAuthMutation(v2CreateTppWebForm, options, false);
};
