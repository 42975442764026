import { UseQueryOptions } from "react-query";
import {
  AccountHoldersApiListAndSearchAllAccountHoldersRequest,
  PageableAccountHolderList,
} from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllAccountHolders = async (
  token: string | undefined,
  params?: AccountHoldersApiListAndSearchAllAccountHoldersRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.listAndSearchAllAccountHolders(params);
  return data;
};

export const useListAndSearchAllAccountHolders = (
  params?: AccountHoldersApiListAndSearchAllAccountHoldersRequest,
  options?: UseQueryOptions<
    PageableAccountHolderList,
    AxiosApiError,
    PageableAccountHolderList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTHOLDERS_LIST_AND_SEARCH_ALL_ACCOUNT_HOLDERS, params],
    ({ token }) => listAndSearchAllAccountHolders(token, params),
    options
  );
};
