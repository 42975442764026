import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Box } from "@mui/material";
import {
  CreateOrUpdateAccountParams,
  CreateOrUpdateAccountParamsTypeEnum,
} from "@generated/apiv1";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";
import { useCreateAccount } from "@generated/apiv1/hooks";
import { LoadingButton, PageLayout } from "@components";
import { useAccountTypeAddText } from "@features/accounts/hooks/useAccountTypeAddText";
import { CreateAndEditAccountForm } from "../edit/components/CreateAndEditAccountForm";
import { accountSubcategoryMap } from "../constants";

export const AccountAddManuallyPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isDepots = matchPath("/depots/add-manually", location.pathname);
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const text = useAccountTypeAddText();
  const isAddManuallyStartingPage =
    type &&
    [
      "REAL_ESTATE",
      "CRYPTO",
      "OTHER_MATERIAL_ASSETS",
      "PRECIOUS_METALS",
    ].includes(type);

  const breadcrumbs = [
    { to: "/", text: t("breadcrumbs.home") },
    {
      to: "/depots-accounts",
      text: t("depotsAccounts.title"),
    },
    {
      text,
    },
  ];

  const getInitialAccountValues = () => {
    const accountType =
      (accountSubcategoryMap[
        type as AccountSubcategoryEnum
      ] as CreateOrUpdateAccountParamsTypeEnum) || "01_depot";
    return {
      name: "",
      type: accountType,
      enableImplicitTransactions: accountType === "01_depot",
    };
  };

  const navigate = useNavigate();
  const [accountValues, setAccountValues] =
    useState<CreateOrUpdateAccountParams>(getInitialAccountValues());
  const { mutateAsync, isLoading } = useCreateAccount();

  const handleCreateAccount = () => {
    mutateAsync({ body: accountValues }).then(() =>
      navigate("/depots-accounts")
    );
  };

  const handleUpdateAccountValues = (
    params: Partial<CreateOrUpdateAccountParams>
  ) => {
    setAccountValues((values) => {
      return { ...values, ...params };
    });
  };

  return (
    <>
      <PageLayout
        breadcrumbs={breadcrumbs}
        layout="centered"
        backUrl={() => {
          if (!type) {
            window.history.back();
            return;
          }

          if (isAddManuallyStartingPage) {
            navigate("/depots-accounts");
            return;
          } else {
            navigate(
              isDepots
                ? `/depots/add${type ? `?type=${type}` : ""}`
                : `/accounts/add${type ? `?type=${type}` : ""}`
            );
          }
        }}
        title={t("accounts.fillFormDepots")}
      >
        <CreateAndEditAccountForm
          accountValues={accountValues}
          onUpdateAccountValues={handleUpdateAccountValues}
          type={type}
        />

        <Box display="flex" alignItems="center" gap={3} mt={6}>
          <LoadingButton
            data-testid="create-account"
            variant="contained"
            color="primary"
            onClick={handleCreateAccount}
            isLoading={isLoading}
            fullWidth
            disabled={!accountValues.name}
          >
            {t("accounts.addManually.create")}
          </LoadingButton>
        </Box>
      </PageLayout>
    </>
  );
};
