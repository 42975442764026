import { UseQueryOptions } from "react-query";
import {
  AccountHoldersApiAccountHoldersIdRequest,
  AccountHolder,
} from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const accountHoldersId = async (
  token: string | undefined,
  params: AccountHoldersApiAccountHoldersIdRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.accountHoldersId(params);
  return data;
};

export const useAccountHoldersId = (
  params: AccountHoldersApiAccountHoldersIdRequest,
  options?: UseQueryOptions<AccountHolder, AxiosApiError, AccountHolder>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTHOLDERS_ACCOUNT_HOLDERS_ID, params],
    ({ token }) => accountHoldersId(token, params),
    options
  );
};
