import { useLayoutEffect } from "react";

export const useCustomFavicon = (
  url: string = "https://wealthapi.eu/favicon.ico"
) => {
  useLayoutEffect(() => {
    const link = document.createElement("link");
    const oldLink = document.querySelector(
      "[href$='favicon.ico']"
    ) as HTMLLinkElement;

    link.id = "favicon";
    link.rel = "shortcut icon";
    link.href = url;

    if (oldLink) {
      document.head.removeChild(oldLink);
      document.head.appendChild(link);
    }
  }, [url]);
};
