/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CloneHistoricDividendParams
 */
export interface CloneHistoricDividendParams {
    /**
     * The ISIN of the dividends to clone
     * @type {string}
     * @memberof CloneHistoricDividendParams
     */
    'fromIsin': string;
    /**
     * The cloned dividends will use this ISIN
     * @type {string}
     * @memberof CloneHistoricDividendParams
     */
    'toIsin': string;
    /**
     * The mandator scope; will use no mandator scope as default unless specified
     * @type {string}
     * @memberof CloneHistoricDividendParams
     */
    'mandatorScope'?: CloneHistoricDividendParamsMandatorScopeEnum;
}

export const CloneHistoricDividendParamsMandatorScopeEnum = {
    ONVISTA: 'onvista'
} as const;

export type CloneHistoricDividendParamsMandatorScopeEnum = typeof CloneHistoricDividendParamsMandatorScopeEnum[keyof typeof CloneHistoricDividendParamsMandatorScopeEnum];


