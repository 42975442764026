import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "@emotion/styled";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Typography, TypographyProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import {
  SharedPortfolioContext,
  useSharedPortfolioContext,
} from "@providers/SharedPortfolioProvider";
import { DepotsSelectorModal } from "@features/selectDepots/components/DepotsSelectorModal";
import { usePublicOrPrivateAccounts } from "@features/sharedPortfolio/hooks/usePublicOrPrivateAccounts";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import {
  useCreateOrUpdateSelectGroup,
  useSelectGroupsId,
} from "@generated/apiv1/hooks";
import { SelectGroupParams } from "@features/selectDepots/types";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

const DepotSelectorIcon = styled(KeyboardArrowDownRoundedIcon)`
  color: currentColor;
`;

type Props = {
  accountParams?: AccountsApiListAndSearchAllAccountsRequest;
  selectedGroupParams: SelectGroupParams;
  color?: string;
  textKeys?: {
    modalTitle: string;
    allDepots: string;
    amountDepots: string;
    noDepots: string;
  };
  icon?: ReactNode;
  onSave?: (ids: number[]) => void;
} & TypographyProps;

export const DepotsSelector = ({
  accountParams,
  selectedGroupParams,
  color = "text.primary",
  textKeys = {
    modalTitle: "depotsSelector.modalTitle",
    allDepots: "depotsSelector.allDepots",
    amountDepots: "depotsSelector.amountDepots",
    noDepots: "depotsSelector.noDepots",
  },
  icon,
  onSave,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const [isDepotSelectorModalOpen, setIsDepotSelectorModalOpen] =
    useState(false);
  const { settings } = useContext(SharedPortfolioContext);
  const { data: accounts, isFetched } =
    usePublicOrPrivateAccounts(accountParams);
  const [checkedPortfolios, setCheckedPortfolios] = useState<number[]>([]);

  const { data: selectedGroup } = useSelectGroupsId(selectedGroupParams, {
    refetchOnWindowFocus: false,
    enabled: !isSharedPortfolio,
  });
  const { mutate: saveSelectedGroup, isLoading: isSaving } =
    useCreateOrUpdateSelectGroup();

  useEffect(() => {
    if (!isSharedPortfolio || !isFetched) return;
    const savedLocalStorageData = localStorage.getItem(
      `selectedGroup-${settings.publicId}-${selectedGroupParams.id}`
    );
    const allAccountsIds = accounts?.map((account) => account.id) || [];
    const selectedAccounts: number[] = savedLocalStorageData
      ? JSON.parse(savedLocalStorageData).filter((id: number) =>
          accounts?.find((acc) => acc.id === id)
        )
      : allAccountsIds;

    if (selectedAccounts.length === 0) {
      localStorage.removeItem(
        `selectedGroup-${settings.publicId}-${selectedGroupParams.id}`
      );
      setCheckedPortfolios(allAccountsIds);
    } else {
      setCheckedPortfolios(selectedAccounts);
    }
  }, [
    accounts,
    selectedGroupParams.id,
    settings.publicId,
    isSharedPortfolio,
    isFetched,
  ]);

  useEffect(() => {
    if (isSharedPortfolio) return;
    const filteredAccounts = selectedGroup?.accountIds?.filter((id) =>
      accounts?.some((x) => x.id === id)
    );
    setCheckedPortfolios(filteredAccounts ?? []);
  }, [accounts, selectedGroup?.accountIds, isSharedPortfolio]);

  const amountAccounts = accounts?.length ?? 0;
  const amountSelectedAccounts = checkedPortfolios.length ?? 0;

  const depotsSelectorText =
    amountSelectedAccounts === amountAccounts
      ? t(textKeys.allDepots)
      : t(textKeys.amountDepots, {
          selected: amountSelectedAccounts,
          total: amountAccounts,
        });

  const AmountComponent = useCallback(
    () => (
      <Box
        display="inline-flex"
        alignItems="center"
        color="currentColor"
        sx={{
          cursor: "pointer",
        }}
      >
        {amountAccounts === 0 ? (
          <Typography variant="h4" {...rest}>
            {t(textKeys.noDepots)}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" {...rest}>
              {depotsSelectorText}
            </Typography>
            {icon ? icon : <DepotSelectorIcon />}
          </>
        )}
      </Box>
    ),
    [amountAccounts, depotsSelectorText, icon, rest, t, textKeys]
  );

  const saveSelectedAccounts = useCallback(() => {
    if (isSharedPortfolio) {
      localStorage.setItem(
        `selectedGroup-${settings.publicId}-${selectedGroupParams.id}`,
        JSON.stringify(checkedPortfolios)
      );
      setIsDepotSelectorModalOpen(false);
    } else {
      saveSelectedGroup(
        {
          body: {
            name: selectedGroupParams.id,
            accountIds: checkedPortfolios,
          },
        },
        {
          onSuccess: () => {
            setIsDepotSelectorModalOpen(false);
            queryClient.refetchQueries({
              queryKey: [
                CACHE_KEYS.ACCOUNTS_SELECT_GROUPS_ID,
                selectedGroupParams,
              ],
            });
          },
        }
      );
    }
    onSave?.(checkedPortfolios);
  }, [
    isSharedPortfolio,
    saveSelectedGroup,
    checkedPortfolios,
    settings.publicId,
    onSave,
    queryClient,
    selectedGroupParams,
  ]);

  return (
    <Box color={color}>
      <Box
        onClick={() => setIsDepotSelectorModalOpen(true)}
        data-testid="select-depots"
      >
        <AmountComponent />
      </Box>
      <DepotsSelectorModal
        accounts={accounts}
        onConfirm={saveSelectedAccounts}
        isLoading={isSaving}
        title={t(`${textKeys.modalTitle}`)}
        isOpen={isDepotSelectorModalOpen}
        checkedPortfolios={checkedPortfolios}
        onSelect={setCheckedPortfolios}
        onClose={() => setIsDepotSelectorModalOpen(false)}
      />
    </Box>
  );
};
