/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateTppCredentialParams } from '../model';
// @ts-ignore
import { PageableTppAuthenticationGroupResources } from '../model';
// @ts-ignore
import { PageableTppCredentialResources } from '../model';
// @ts-ignore
import { TppCredentials } from '../model';
/**
 * TppCredentialsApi - axios parameter creator
 * @export
 */
export const TppCredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload TPP credentials for a TPP Authentication Group.
         * @param {CreateOrUpdateTppCredentialParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppCredential: async (body: CreateOrUpdateTppCredentialParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTppCredential', 'body', body)
            const localVarPath = `/v1/tppCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a tpp credential
         * @param {number} id ID of the tpp credential to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTppCredential: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTppCredential', 'id', id)
            const localVarPath = `/v1/tppCredentials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all TPP authentication groups
         * @param {string} [name] Only the tpp authentication groups with name matching the given one should appear in the                              result list.
         * @param {string} [bankBlz] Search by connected banks: only the banks with BLZ matching the given one should appear                              in the result list.
         * @param {string} [bankName] Search by connected banks: only the banks with name matching the given one should appear                              in the result list.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppAuthenticationGroups: async (name?: string, bankBlz?: string, bankName?: string, page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/tppCredentials/tppAuthenticationGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (bankBlz !== undefined) {
                localVarQueryParameter['bankBlz'] = bankBlz;
            }

            if (bankName !== undefined) {
                localVarQueryParameter['bankName'] = bankName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all TPP credentials
         * @param {string} [search] Returns only the TPP credentials belonging to those banks whose \&#39;name\&#39;, \&#39;blz\&#39;, or \&#39;bic\&#39;                              contains the given search string (the matching works case-insensitive).&lt;br /&gt;                             Note: If the given search string consists of several terms (separated by whitespace),                              then ALL of these terms must apply to a bank in order for it to get included into the result.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppCredentials: async (search?: string, page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/tppCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the credential set with the given ID
         * @param {number} id Get the tpp credential with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppCredentialsId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tppCredentialsId', 'id', id)
            const localVarPath = `/v1/tppCredentials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update TPP credentials for a TPP Authentication Group.
         * @param {CreateOrUpdateTppCredentialParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTppCredential: async (body: CreateOrUpdateTppCredentialParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTppCredential', 'body', body)
            const localVarPath = `/v1/tppCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TppCredentialsApi - functional programming interface
 * @export
 */
export const TppCredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TppCredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload TPP credentials for a TPP Authentication Group.
         * @param {CreateOrUpdateTppCredentialParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTppCredential(body: CreateOrUpdateTppCredentialParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTppCredential(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.createTppCredential']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a tpp credential
         * @param {number} id ID of the tpp credential to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTppCredential(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTppCredential(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.deleteTppCredential']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all TPP authentication groups
         * @param {string} [name] Only the tpp authentication groups with name matching the given one should appear in the                              result list.
         * @param {string} [bankBlz] Search by connected banks: only the banks with BLZ matching the given one should appear                              in the result list.
         * @param {string} [bankName] Search by connected banks: only the banks with name matching the given one should appear                              in the result list.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTppAuthenticationGroups(name?: string, bankBlz?: string, bankName?: string, page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableTppAuthenticationGroupResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTppAuthenticationGroups(name, bankBlz, bankName, page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.listAndSearchAllTppAuthenticationGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all TPP credentials
         * @param {string} [search] Returns only the TPP credentials belonging to those banks whose \&#39;name\&#39;, \&#39;blz\&#39;, or \&#39;bic\&#39;                              contains the given search string (the matching works case-insensitive).&lt;br /&gt;                             Note: If the given search string consists of several terms (separated by whitespace),                              then ALL of these terms must apply to a bank in order for it to get included into the result.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTppCredentials(search?: string, page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableTppCredentialResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTppCredentials(search, page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.listAndSearchAllTppCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the credential set with the given ID
         * @param {number} id Get the tpp credential with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tppCredentialsId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tppCredentialsId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.tppCredentialsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update TPP credentials for a TPP Authentication Group.
         * @param {CreateOrUpdateTppCredentialParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTppCredential(body: CreateOrUpdateTppCredentialParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTppCredential(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCredentialsApi.updateTppCredential']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TppCredentialsApi - factory interface
 * @export
 */
export const TppCredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TppCredentialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload TPP credentials for a TPP Authentication Group.
         * @param {TppCredentialsApiCreateTppCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppCredential(requestParameters: TppCredentialsApiCreateTppCredentialRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppCredentials> {
            return localVarFp.createTppCredential(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a tpp credential
         * @param {TppCredentialsApiDeleteTppCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTppCredential(requestParameters: TppCredentialsApiDeleteTppCredentialRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTppCredential(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all TPP authentication groups
         * @param {TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppAuthenticationGroups(requestParameters: TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableTppAuthenticationGroupResources> {
            return localVarFp.listAndSearchAllTppAuthenticationGroups(requestParameters.name, requestParameters.bankBlz, requestParameters.bankName, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all TPP credentials
         * @param {TppCredentialsApiListAndSearchAllTppCredentialsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppCredentials(requestParameters: TppCredentialsApiListAndSearchAllTppCredentialsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableTppCredentialResources> {
            return localVarFp.listAndSearchAllTppCredentials(requestParameters.search, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the credential set with the given ID
         * @param {TppCredentialsApiTppCredentialsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppCredentialsId(requestParameters: TppCredentialsApiTppCredentialsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppCredentials> {
            return localVarFp.tppCredentialsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update TPP credentials for a TPP Authentication Group.
         * @param {TppCredentialsApiUpdateTppCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTppCredential(requestParameters: TppCredentialsApiUpdateTppCredentialRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppCredentials> {
            return localVarFp.updateTppCredential(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTppCredential operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiCreateTppCredentialRequest
 */
export interface TppCredentialsApiCreateTppCredentialRequest {
    /**
     * The create parameters
     * @type {CreateOrUpdateTppCredentialParams}
     * @memberof TppCredentialsApiCreateTppCredential
     */
    readonly body: CreateOrUpdateTppCredentialParams
}

/**
 * Request parameters for deleteTppCredential operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiDeleteTppCredentialRequest
 */
export interface TppCredentialsApiDeleteTppCredentialRequest {
    /**
     * ID of the tpp credential to delete
     * @type {number}
     * @memberof TppCredentialsApiDeleteTppCredential
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllTppAuthenticationGroups operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest
 */
export interface TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest {
    /**
     * Only the tpp authentication groups with name matching the given one should appear in the                              result list.
     * @type {string}
     * @memberof TppCredentialsApiListAndSearchAllTppAuthenticationGroups
     */
    readonly name?: string

    /**
     * Search by connected banks: only the banks with BLZ matching the given one should appear                              in the result list.
     * @type {string}
     * @memberof TppCredentialsApiListAndSearchAllTppAuthenticationGroups
     */
    readonly bankBlz?: string

    /**
     * Search by connected banks: only the banks with name matching the given one should appear                              in the result list.
     * @type {string}
     * @memberof TppCredentialsApiListAndSearchAllTppAuthenticationGroups
     */
    readonly bankName?: string

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof TppCredentialsApiListAndSearchAllTppAuthenticationGroups
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof TppCredentialsApiListAndSearchAllTppAuthenticationGroups
     */
    readonly perPage?: number
}

/**
 * Request parameters for listAndSearchAllTppCredentials operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiListAndSearchAllTppCredentialsRequest
 */
export interface TppCredentialsApiListAndSearchAllTppCredentialsRequest {
    /**
     * Returns only the TPP credentials belonging to those banks whose \&#39;name\&#39;, \&#39;blz\&#39;, or \&#39;bic\&#39;                              contains the given search string (the matching works case-insensitive).&lt;br /&gt;                             Note: If the given search string consists of several terms (separated by whitespace),                              then ALL of these terms must apply to a bank in order for it to get included into the result.
     * @type {string}
     * @memberof TppCredentialsApiListAndSearchAllTppCredentials
     */
    readonly search?: string

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof TppCredentialsApiListAndSearchAllTppCredentials
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof TppCredentialsApiListAndSearchAllTppCredentials
     */
    readonly perPage?: number
}

/**
 * Request parameters for tppCredentialsId operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiTppCredentialsIdRequest
 */
export interface TppCredentialsApiTppCredentialsIdRequest {
    /**
     * Get the tpp credential with the given ID
     * @type {number}
     * @memberof TppCredentialsApiTppCredentialsId
     */
    readonly id: number
}

/**
 * Request parameters for updateTppCredential operation in TppCredentialsApi.
 * @export
 * @interface TppCredentialsApiUpdateTppCredentialRequest
 */
export interface TppCredentialsApiUpdateTppCredentialRequest {
    /**
     * The create parameters
     * @type {CreateOrUpdateTppCredentialParams}
     * @memberof TppCredentialsApiUpdateTppCredential
     */
    readonly body: CreateOrUpdateTppCredentialParams
}

/**
 * TppCredentialsApi - object-oriented interface
 * @export
 * @class TppCredentialsApi
 * @extends {BaseAPI}
 */
export class TppCredentialsApi extends BaseAPI {
    /**
     * 
     * @summary Upload TPP credentials for a TPP Authentication Group.
     * @param {TppCredentialsApiCreateTppCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public createTppCredential(requestParameters: TppCredentialsApiCreateTppCredentialRequest, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).createTppCredential(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a tpp credential
     * @param {TppCredentialsApiDeleteTppCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public deleteTppCredential(requestParameters: TppCredentialsApiDeleteTppCredentialRequest, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).deleteTppCredential(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all TPP authentication groups
     * @param {TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public listAndSearchAllTppAuthenticationGroups(requestParameters: TppCredentialsApiListAndSearchAllTppAuthenticationGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).listAndSearchAllTppAuthenticationGroups(requestParameters.name, requestParameters.bankBlz, requestParameters.bankName, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all TPP credentials
     * @param {TppCredentialsApiListAndSearchAllTppCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public listAndSearchAllTppCredentials(requestParameters: TppCredentialsApiListAndSearchAllTppCredentialsRequest = {}, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).listAndSearchAllTppCredentials(requestParameters.search, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the credential set with the given ID
     * @param {TppCredentialsApiTppCredentialsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public tppCredentialsId(requestParameters: TppCredentialsApiTppCredentialsIdRequest, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).tppCredentialsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update TPP credentials for a TPP Authentication Group.
     * @param {TppCredentialsApiUpdateTppCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCredentialsApi
     */
    public updateTppCredential(requestParameters: TppCredentialsApiUpdateTppCredentialRequest, options?: RawAxiosRequestConfig) {
        return TppCredentialsApiFp(this.configuration).updateTppCredential(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

