import React, { ChangeEvent, useEffect, useState } from "react";
import { isFuture, isValid } from "date-fns";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { parseOrUndefined, toString } from "@helpers";
import { ReactComponent as Calendar } from "@icons/calendar.svg";

const SecuritiesValuesFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

interface SecuritiesValuesFieldsProps {
  price: number;
  date?: Date;
  onDateChange: (date?: Date) => void;
  onPriceChange: (price: number) => void;
}

export const SecuritiesValuesFields = (props: SecuritiesValuesFieldsProps) => {
  const { date, price, onDateChange, onPriceChange } = props;
  const [customDate, setCustomDate] = useState<Date | null | undefined>(
    new Date()
  );
  const [priceInput, setPriceInput] = useState("0");
  const { t } = useTranslation();

  useEffect(() => {
    // Note: Null for using empty date field
    setCustomDate(date ?? null);
    if (price) {
      setPriceInput(toString(price, "", 2));
    }
  }, [date, price]);

  const handleDateChange = (value: Date | null) => {
    setCustomDate(value);

    if (value && isValid(value) && !isFuture(value)) {
      onDateChange(value);
      return;
    }

    onDateChange(undefined);
  };

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const parsedValue = parseOrUndefined(value);

    setPriceInput(toString(parsedValue, value, 2));
    onPriceChange(parsedValue ?? 0);
  };

  return (
    <SecuritiesValuesFieldsWrapper>
      <DatePicker
        disableFuture
        openTo="year"
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        label={t("securitiesAddPage.securitiesTextFields.date")}
        views={["year", "month", "day"]}
        value={customDate}
        components={{
          OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
        }}
        PopperProps={{
          disablePortal: true,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...params.inputProps,
              "data-testid": "security-date-input",
            }}
          />
        )}
        onChange={handleDateChange}
      />
      <TextField
        fullWidth
        label={t("securitiesAddPage.securitiesTextFields.price")}
        value={priceInput}
        inputProps={{
          "data-testid": "security-price-input",
        }}
        onChange={handlePriceChange}
      />
    </SecuritiesValuesFieldsWrapper>
  );
};
