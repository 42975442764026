import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingPlaceHolder } from "@components";
import { useBankConnectionsData } from "@features/settingsPage/SettingsConnectionsPage/useBankConnections";
import { SettingsBankConnection } from "./components/SettingsBankConnection";
import { SettingsBankConnectionModal } from "./components/SettingsBankConnectionModal";
import { SettingsNoConnections } from "./components/SettingsNoConnections";

export interface ModalState {
  id?: number;
  title?: string;
  state: "open" | "loginData" | "connection";
  provider?: "FIN_API" | "WEALTH_API";
}

export const SettingsConnectionsPage = ({
  isLoadingUserData,
}: {
  isLoadingUserData: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: groupedBanks, isLoading } = useBankConnectionsData();
  const [modalState, setModalState] = useState<ModalState>({
    id: undefined,
    state: "open",
  });

  const headingStyle = {
    variant: "body1" as const,
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: 600,
    mb: theme.spacing(6),
  };

  const accounts = [
    ...(groupedBanks?.accounts.find((x) => x.id === modalState.id)?.accounts ??
      []),
  ];

  return (
    <>
      {isLoading || isLoadingUserData ? (
        <LoadingPlaceHolder />
      ) : (
        <>
          {!groupedBanks?.accounts?.length && <SettingsNoConnections />}
          {Boolean(groupedBanks?.accounts?.length) && (
            <Box mb={theme.spacing(12)} mt={theme.spacing(2)}>
              <Typography {...headingStyle}>
                {t("settingsPage.connections.depotsAndKonten")}
              </Typography>
              <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                {groupedBanks?.accounts.map((group) => (
                  <SettingsBankConnection
                    {...group}
                    setShowModal={setModalState}
                  />
                ))}
              </Box>
            </Box>
          )}
        </>
      )}

      <SettingsBankConnectionModal
        accounts={accounts}
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
};
