/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductAttributesCategoryInner } from './product-attributes-category-inner';
// May contain unused imports in some cases
// @ts-ignore
import { ProductAttributesHoldingsInner } from './product-attributes-holdings-inner';

/**
 * 
 * @export
 * @interface ProductAttributes
 */
export interface ProductAttributes {
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'short_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'internal_type'?: ProductAttributesInternalTypeEnum;
    /**
     * 
     * @type {Array<ProductAttributesCategoryInner>}
     * @memberof ProductAttributes
     */
    'category'?: Array<ProductAttributesCategoryInner>;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'dividend_distribution_frequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'dividend_distribution_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'emitter'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'established_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAttributes
     */
    'holdings_category'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'logo_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'management_style'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'manager'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductAttributes
     */
    'sustainability_rating'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAttributes
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'volume'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAttributes
     */
    'is_valid_isin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'checkout_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'ticker_symbol'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'isin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'wkn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAttributes
     */
    'is_sustainable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'popularity_score'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'roi_quality_score'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'video_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributes
     */
    'provider_slug'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ProductAttributes
     */
    'custom_data'?: object | null;
    /**
     * 
     * @type {Array<ProductAttributesHoldingsInner>}
     * @memberof ProductAttributes
     */
    'holdings'?: Array<ProductAttributesHoldingsInner> | null;
}

export const ProductAttributesInternalTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _22_ETF: '22_etf',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _91_MANAGED: '91_managed'
} as const;

export type ProductAttributesInternalTypeEnum = typeof ProductAttributesInternalTypeEnum[keyof typeof ProductAttributesInternalTypeEnum];


