/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AccountReference } from './account-reference';
// May contain unused imports in some cases
// @ts-ignore
import { Industry } from './industry';
// May contain unused imports in some cases
// @ts-ignore
import { InvestmentAggregation } from './investment-aggregation';
// May contain unused imports in some cases
// @ts-ignore
import { InvestmentQuote } from './investment-quote';
// May contain unused imports in some cases
// @ts-ignore
import { InvestmentSocialStatistics } from './investment-social-statistics';
// May contain unused imports in some cases
// @ts-ignore
import { Region } from './region';
// May contain unused imports in some cases
// @ts-ignore
import { Tag } from './tag';

/**
 * 
 * @export
 * @interface Investment
 */
export interface Investment {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Investment
     */
    'id': number;
    /**
     * The investments standardised name
     * @type {string}
     * @memberof Investment
     */
    'standardisedName': string;
    /**
     * DEPRECATED: use externalId instead. The investment ID as assigned by the banking API provider
     * @type {string}
     * @memberof Investment
     */
    'finApiId'?: string;
    /**
     * ID assigned by the bank or the multi-banking provider
     * @type {string}
     * @memberof Investment
     */
    'externalId'?: string;
    /**
     * DEPRECATED: The class name of the investment domain; useful in some cases to distinguish between                                investments and watchlist entries
     * @type {string}
     * @memberof Investment
     */
    'className': string;
    /**
     * The investments ticker symbol. A ticker symbol is a direct reference to a quote at a                                certain stock exchange. The ticker symbols structure depends on the respective quote                                provider.
     * @type {string}
     * @memberof Investment
     */
    'tickerSymbol': string;
    /**
     * Same as the ticker symbol, but with the quote currency attached. This allows to                                distinguish investments with the same ticker symbol but a different currency. This may                                happen in cases where we do not have quote data in the investments currency and thus use                                quote conversion based on exchange rates.
     * @type {string}
     * @memberof Investment
     */
    'tickerSymbolWithCurrency': string;
    /**
     * DEPRECATED: An addition ticker symbol.
     * @type {string}
     * @memberof Investment
     */
    'additionalSymbol'?: string;
    /**
     * The investments quote provider; which always comes together with the ticker symbol.                                A quote provider is a third-party API that delivers quote data based on a given ticker                                symbol. Rentablo supports different quote providers; which quote providers are available                                depends on your license agreement.
     * @type {string}
     * @memberof Investment
     */
    'quoteProvider': string;
    /**
     * The investment type
     * @type {string}
     * @memberof Investment
     */
    'type': InvestmentTypeEnum;
    /**
     * A literal investment type; e.g. 11_stock will have literal type \'Aktien\'
     * @type {string}
     * @memberof Investment
     */
    'literalType': string;
    /**
     * The name of the investment
     * @type {string}
     * @memberof Investment
     */
    'name': string;
    /**
     * Did the user define or change the name?
     * @type {boolean}
     * @memberof Investment
     */
    'isNameUserDefined': boolean;
    /**
     * DEPRECATED: The name of the investment cleared from characters that could confuse a JS library
     * @type {string}
     * @memberof Investment
     */
    'jsCompatibleName': string;
    /**
     * The investments ISIN
     * @type {string}
     * @memberof Investment
     */
    'isin': string;
    /**
     * true if the investment has a valid ISIN, false otherwise.             Non-valid ISINs may exist if the investment does not have quote data assigned.
     * @type {boolean}
     * @memberof Investment
     */
    'hasValidIsin': boolean;
    /**
     * The investments WKN (if provided); the API internally uses the ISIN as primary identifier.
     * @type {string}
     * @memberof Investment
     */
    'wkn'?: string;
    /**
     * true if the investment has a valid ticker symbol, false otherwise. Non-valid ticker                                symbols exist if the investment does not have quote data assigned.
     * @type {boolean}
     * @memberof Investment
     */
    'hasValidTickerSymbol': boolean;
    /**
     * The investments currency. Note that the investment currency may be different from the                                (native) quote currency if quote currency conversion based on exchange rates is applied.
     * @type {string}
     * @memberof Investment
     */
    'currency': string;
    /**
     * The number of lots currently in the depot. This value is calculated based on the             booking history of an investment.
     * @type {number}
     * @memberof Investment
     */
    'numberOfLots': number;
    /**
     * The number of lots that the bank delivered during import. This should typically             match the numberOfLots as we perform delta resolution in case of mismatches. Manual user intervention \'             might lead to differences.
     * @type {number}
     * @memberof Investment
     */
    'numberOfLotsFromBank'?: number;
    /**
     * The current average exchange rate; if sales are present, the value depends on the                                gain calculation method (FiFo or average).
     * @type {number}
     * @memberof Investment
     */
    'exchangeRate': number;
    /**
     * The interest per year (yield).
     * @type {number}
     * @memberof Investment
     */
    'yield'?: number;
    /**
     * The bond due date; if a bond and entered by the user
     * @type {string}
     * @memberof Investment
     */
    'dueDate'?: string;
    /**
     * The bond yield in percent (1 = 100%) based on the current quote; if yield is not known.
     * @type {number}
     * @memberof Investment
     */
    'bondYield'?: number;
    /**
     * True if the investment has at least one dividend attached
     * @type {boolean}
     * @memberof Investment
     */
    'hasDividend': boolean;
    /**
     * The average dividend per lot; average over all dividend payments so far
     * @type {number}
     * @memberof Investment
     */
    'receivedAverageDividend': number;
    /**
     * The total received dividend (the sum of all dividend payments received so far)
     * @type {number}
     * @memberof Investment
     */
    'receivedTotalDividend': number;
    /**
     * The average dividend per lot in percent of the purchase price;                                average over all dividend payments so far. The value may depend on the gain calculation method                                (FiFo or average).
     * @type {number}
     * @memberof Investment
     */
    'receivedAverageDividendPercent': number;
    /**
     * The average dividend per lot in percent of the purchase price in ACCOUNT currency.                                This value equals receivedAverageDividend if the investment currency equals the account currency.                                The value may depend on the gain calculation method (FiFo or average).
     * @type {number}
     * @memberof Investment
     */
    'receivedAverageDividendAC': number;
    /**
     * The total received dividend (the sum of all dividend payments received so far) in                                ACCOUNT currency. This value equals receivedAverageDividend if the investment currency                                equals the account currency.
     * @type {number}
     * @memberof Investment
     */
    'receivedTotalDividendAC': number;
    /**
     * The average dividend per lot in percent of the purchase price in ACCOUNT currency.                                This value equals receivedAverageDividendPercent if the investment currency equals the                                account currency. The value may depend on the gain calculation method (FiFo or average).
     * @type {number}
     * @memberof Investment
     */
    'receivedAverageDividendPercentAC'?: number;
    /**
     * 
     * @type {InvestmentQuote}
     * @memberof Investment
     */
    'quote': InvestmentQuote;
    /**
     * The investments purchase price per lot.             This is the theoretical value calculated             based on the booking history for this investment. As multibanking data may be incomplete or estimated,             this value may differ from the value delivered by the bank.
     * @type {number}
     * @memberof Investment
     */
    'purchasePrice': number;
    /**
     * The investments purchase price per lot in ACCOUNT currency.             This is the theoretical value calculated             based on the booking history for this investment. As multibanking data may be incomplete or estimated,             this value may differ from the value delivered by the bank.
     * @type {number}
     * @memberof Investment
     */
    'purchasePriceAC': number;
    /**
     * The purchase price as delivered by the bank. Only available if imported from a bank
     * @type {number}
     * @memberof Investment
     */
    'purchasePriceFromBank'?: number;
    /**
     * The investments purchase value (the price for all lots)
     * @type {number}
     * @memberof Investment
     */
    'purchaseValue': number;
    /**
     * The investments purchase value (the price for all lots) in ACCOUNT currency
     * @type {number}
     * @memberof Investment
     */
    'purchaseValueAC': number;
    /**
     * The current market value per lot
     * @type {number}
     * @memberof Investment
     */
    'currentMarketValue': number;
    /**
     * The current market value per lot in ACCOUNT currency
     * @type {number}
     * @memberof Investment
     */
    'currentMarketValueAC': number;
    /**
     * The return on investment so far; this is purchaseValue - marketValue
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestment': number;
    /**
     * The return on investment so far; this is purchaseValue - marketValue; in ACCOUNT currency
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentAC': number;
    /**
     * The return on investment per lot so far; this is purchasePrice - quote
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentPerLot': number;
    /**
     * The return on investment per lot so far; this is purchasePrice - quote; in ACCOUNT currency
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentPerLotAC': number;
    /**
     * The current return on investment in percent
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentInPercent': number;
    /**
     * The current return on investment in percent; in ACCOUNT currency
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentInPercentAC': number;
    /**
     * The current market price per lot if we included dividends received so far. Note that                                this value is mainly illustrative, as we would have to consider re-investment of                                dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'currentMarketPriceWithDividend': number;
    /**
     * The current market price per lot if we included dividends received so far; in ACCOUNT                                currency. Note that                                this value is mainly illustrative, as we would have to consider re-investment of                                dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'currentMarketPriceWithDividendAC': number;
    /**
     * The current market value (all lots) if we included dividends received so far. Note that                                this value is mainly illustrative, as we would have to consider re-investment of                                dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'currentMarketValueWithDividend': number;
    /**
     * The current market value (all lots) if we included dividends received so far; in ACCOUNT                                currency. Note that                                this value is mainly illustrative, as we would have to consider re-investment of                                dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'currentMarketValueWithDividendAC': number;
    /**
     * Same as return on investment, but including all dividends received so far.                                Note that this value is mainly illustrative, as we would have to consider re-investment                                of dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividend': number;
    /**
     * Same as return on investment AC, but including all dividends received so far.                                Note that this value is mainly illustrative, as we would have to consider re-investment                                of dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividendAC': number;
    /**
     * The return on investment so far; if we include received dividends.                                Note that this value is mainly illustrative, as we would have to consider re-investment                                of dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividendPerLot': number;
    /**
     * The return on investment so far in ACCOUNT currency; if we include received dividends.                                Note that this value is mainly illustrative, as we would have to consider re-investment                                of dividends from a statistical point.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividendPerLotAC': number;
    /**
     * Same as returnOnInvestmentWithDividend; but in percent of the purchase value.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividendInPercent': number;
    /**
     * Same as returnOnInvestmentWithDividendAC; but in percent of the purchase value.
     * @type {number}
     * @memberof Investment
     */
    'returnOnInvestmentWithDividendInPercentAC': number;
    /**
     * 
     * @type {AccountReference}
     * @memberof Investment
     */
    'account': AccountReference;
    /**
     * 
     * @type {Tag}
     * @memberof Investment
     */
    'tag'?: Tag;
    /**
     * A user defined sub-category
     * @type {number}
     * @memberof Investment
     */
    'subCategoryId'?: number;
    /**
     * 
     * @type {Region}
     * @memberof Investment
     */
    'region'?: Region;
    /**
     * 
     * @type {Industry}
     * @memberof Investment
     */
    'industry'?: Industry;
    /**
     * 
     * @type {InvestmentSocialStatistics}
     * @memberof Investment
     */
    'socialStatistics': InvestmentSocialStatistics;
    /**
     * 
     * @type {InvestmentAggregation}
     * @memberof Investment
     */
    'aggregation'?: InvestmentAggregation;
}

export const InvestmentTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;

export type InvestmentTypeEnum = typeof InvestmentTypeEnum[keyof typeof InvestmentTypeEnum];


