import React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(8)};
`;

interface Props {
  name: string;
  label: FormControlLabelProps["label"];
}

type FormInputProps<T extends FieldValues> = CheckboxProps &
  Props &
  UseControllerProps<T>;

export const RHFCheckbox = <T extends FieldValues>(
  props: FormInputProps<T>
): JSX.Element => {
  const theme = useTheme();
  const { control, name, defaultValue, label, style, ...rest } = props;

  const {
    field,
    fieldState: { error },
  } = useController<T>({ control, name, defaultValue });

  return (
    <div>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            {...field}
            checked={field.value || false}
            value={name}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            {...rest}
            sx={{ paddingTop: theme.spacing(0.25), ...rest.sx }}
          />
        }
        sx={{ alignItems: "flex-start" }}
      />
      {error?.message && (
        <Error>
          <Typography
            variant="caption"
            color="inherit"
            data-testid="rhf-checkbox-error"
          >
            {error.message}
          </Typography>
        </Error>
      )}
    </div>
  );
};
