import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request,
  AddOrUpdateBankConnectionResult,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const webFormFlowGetImportOrUpdateBankConnectionResultV2 = async (
  token: string | undefined,
  params: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } =
    await apiInstance.webFormFlowGetImportOrUpdateBankConnectionResultV2(
      params
    );
  return data;
};

export const useWebFormFlowGetImportOrUpdateBankConnectionResultV2 = (
  params: BankConnectionsApiWebFormFlowGetImportOrUpdateBankConnectionResultV2Request,
  options?: UseQueryOptions<
    AddOrUpdateBankConnectionResult,
    AxiosApiError,
    AddOrUpdateBankConnectionResult
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.BANKCONNECTIONS_WEB_FORM_FLOW_GET_IMPORT_OR_UPDATE_BANK_CONNECTION_RESULT_V2,
      params,
    ],
    ({ token }) =>
      webFormFlowGetImportOrUpdateBankConnectionResultV2(token, params),
    options
  );
};
