/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CloneHistoricDividendParams } from '../model';
// @ts-ignore
import { CreateOrUpdateDividendMasterDataRecordParams } from '../model';
// @ts-ignore
import { DividendMasterDataRecord } from '../model';
// @ts-ignore
import { DividendMasterDataRecordList } from '../model';
/**
 * DividendMasterDataRecordsApi - axios parameter creator
 * @export
 */
export const DividendMasterDataRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone historic dividends. Cloned records will be marked as curated to prevent auto-deletion.
         * @param {CloneHistoricDividendParams} body Defines from which ISIN to which ISIN the dividends should be cloned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneDividends: async (body: CloneHistoricDividendParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cloneDividends', 'body', body)
            const localVarPath = `/v1/dividendMasterDataRecords/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new dividend record.
         * @param {CreateOrUpdateDividendMasterDataRecordParams} body The create dividend parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDividend: async (body: CreateOrUpdateDividendMasterDataRecordParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDividend', 'body', body)
            const localVarPath = `/v1/dividendMasterDataRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the dividend record with the given ID.
         * @param {string} id Delete the dividend with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDividendId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDividendId', 'id', id)
            const localVarPath = `/v1/dividendMasterDataRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the dividend with the given ID.
         * @param {number} id Get the dividend with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDividendId', 'id', id)
            const localVarPath = `/v1/dividendMasterDataRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all dividends.
         * @param {Array<number>} [id] Get dividends with the given ID(s)
         * @param {Array<string>} [isin] Get dividends with the given ISIN(s)
         * @param {ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum} [mandatorPublicId] Get dividends with the given mandator scope, default returns only non-scoped dividends
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDividendMasterDataRecords: async (id?: Array<number>, isin?: Array<string>, mandatorPublicId?: ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividendMasterDataRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the historic dividend catalogue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDividendCatalogue: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividendMasterDataRecords/catalogue/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh all dividend predictions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDividendPredictions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dividendMasterDataRecords/predictions/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the dividend record with the given ID.
         * @param {string} id Update the dividend with the given ID
         * @param {CreateOrUpdateDividendMasterDataRecordParams} body The update dividend parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDividendId: async (id: string, body: CreateOrUpdateDividendMasterDataRecordParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDividendId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDividendId', 'body', body)
            const localVarPath = `/v1/dividendMasterDataRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DividendMasterDataRecordsApi - functional programming interface
 * @export
 */
export const DividendMasterDataRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DividendMasterDataRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone historic dividends. Cloned records will be marked as curated to prevent auto-deletion.
         * @param {CloneHistoricDividendParams} body Defines from which ISIN to which ISIN the dividends should be cloned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneDividends(body: CloneHistoricDividendParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneDividends(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.cloneDividends']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new dividend record.
         * @param {CreateOrUpdateDividendMasterDataRecordParams} body The create dividend parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDividend(body: CreateOrUpdateDividendMasterDataRecordParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendMasterDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDividend(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.createDividend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the dividend record with the given ID.
         * @param {string} id Delete the dividend with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDividendId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendMasterDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDividendId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.deleteDividendId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the dividend with the given ID.
         * @param {number} id Get the dividend with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDividendId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendMasterDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDividendId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.getDividendId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all dividends.
         * @param {Array<number>} [id] Get dividends with the given ID(s)
         * @param {Array<string>} [isin] Get dividends with the given ISIN(s)
         * @param {ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum} [mandatorPublicId] Get dividends with the given mandator scope, default returns only non-scoped dividends
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllDividendMasterDataRecords(id?: Array<number>, isin?: Array<string>, mandatorPublicId?: ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendMasterDataRecordList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllDividendMasterDataRecords(id, isin, mandatorPublicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.listAndSearchAllDividendMasterDataRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the historic dividend catalogue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDividendCatalogue(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDividendCatalogue(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.refreshDividendCatalogue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh all dividend predictions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDividendPredictions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDividendPredictions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.refreshDividendPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the dividend record with the given ID.
         * @param {string} id Update the dividend with the given ID
         * @param {CreateOrUpdateDividendMasterDataRecordParams} body The update dividend parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDividendId(id: string, body: CreateOrUpdateDividendMasterDataRecordParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendMasterDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDividendId(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendMasterDataRecordsApi.updateDividendId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DividendMasterDataRecordsApi - factory interface
 * @export
 */
export const DividendMasterDataRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DividendMasterDataRecordsApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone historic dividends. Cloned records will be marked as curated to prevent auto-deletion.
         * @param {DividendMasterDataRecordsApiCloneDividendsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneDividends(requestParameters: DividendMasterDataRecordsApiCloneDividendsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.cloneDividends(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new dividend record.
         * @param {DividendMasterDataRecordsApiCreateDividendRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDividend(requestParameters: DividendMasterDataRecordsApiCreateDividendRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendMasterDataRecord> {
            return localVarFp.createDividend(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the dividend record with the given ID.
         * @param {DividendMasterDataRecordsApiDeleteDividendIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDividendId(requestParameters: DividendMasterDataRecordsApiDeleteDividendIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendMasterDataRecord> {
            return localVarFp.deleteDividendId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the dividend with the given ID.
         * @param {DividendMasterDataRecordsApiGetDividendIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendId(requestParameters: DividendMasterDataRecordsApiGetDividendIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendMasterDataRecord> {
            return localVarFp.getDividendId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all dividends.
         * @param {DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllDividendMasterDataRecords(requestParameters: DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DividendMasterDataRecordList> {
            return localVarFp.listAndSearchAllDividendMasterDataRecords(requestParameters.id, requestParameters.isin, requestParameters.mandatorPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the historic dividend catalogue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDividendCatalogue(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.refreshDividendCatalogue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh all dividend predictions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDividendPredictions(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.refreshDividendPredictions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the dividend record with the given ID.
         * @param {DividendMasterDataRecordsApiUpdateDividendIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDividendId(requestParameters: DividendMasterDataRecordsApiUpdateDividendIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendMasterDataRecord> {
            return localVarFp.updateDividendId(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cloneDividends operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiCloneDividendsRequest
 */
export interface DividendMasterDataRecordsApiCloneDividendsRequest {
    /**
     * Defines from which ISIN to which ISIN the dividends should be cloned.
     * @type {CloneHistoricDividendParams}
     * @memberof DividendMasterDataRecordsApiCloneDividends
     */
    readonly body: CloneHistoricDividendParams
}

/**
 * Request parameters for createDividend operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiCreateDividendRequest
 */
export interface DividendMasterDataRecordsApiCreateDividendRequest {
    /**
     * The create dividend parameters
     * @type {CreateOrUpdateDividendMasterDataRecordParams}
     * @memberof DividendMasterDataRecordsApiCreateDividend
     */
    readonly body: CreateOrUpdateDividendMasterDataRecordParams
}

/**
 * Request parameters for deleteDividendId operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiDeleteDividendIdRequest
 */
export interface DividendMasterDataRecordsApiDeleteDividendIdRequest {
    /**
     * Delete the dividend with the given ID
     * @type {string}
     * @memberof DividendMasterDataRecordsApiDeleteDividendId
     */
    readonly id: string
}

/**
 * Request parameters for getDividendId operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiGetDividendIdRequest
 */
export interface DividendMasterDataRecordsApiGetDividendIdRequest {
    /**
     * Get the dividend with the given ID
     * @type {number}
     * @memberof DividendMasterDataRecordsApiGetDividendId
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllDividendMasterDataRecords operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest
 */
export interface DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest {
    /**
     * Get dividends with the given ID(s)
     * @type {Array<number>}
     * @memberof DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecords
     */
    readonly id?: Array<number>

    /**
     * Get dividends with the given ISIN(s)
     * @type {Array<string>}
     * @memberof DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecords
     */
    readonly isin?: Array<string>

    /**
     * Get dividends with the given mandator scope, default returns only non-scoped dividends
     * @type {'public' | 'onvista'}
     * @memberof DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecords
     */
    readonly mandatorPublicId?: ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum
}

/**
 * Request parameters for updateDividendId operation in DividendMasterDataRecordsApi.
 * @export
 * @interface DividendMasterDataRecordsApiUpdateDividendIdRequest
 */
export interface DividendMasterDataRecordsApiUpdateDividendIdRequest {
    /**
     * Update the dividend with the given ID
     * @type {string}
     * @memberof DividendMasterDataRecordsApiUpdateDividendId
     */
    readonly id: string

    /**
     * The update dividend parameters
     * @type {CreateOrUpdateDividendMasterDataRecordParams}
     * @memberof DividendMasterDataRecordsApiUpdateDividendId
     */
    readonly body: CreateOrUpdateDividendMasterDataRecordParams
}

/**
 * DividendMasterDataRecordsApi - object-oriented interface
 * @export
 * @class DividendMasterDataRecordsApi
 * @extends {BaseAPI}
 */
export class DividendMasterDataRecordsApi extends BaseAPI {
    /**
     * 
     * @summary Clone historic dividends. Cloned records will be marked as curated to prevent auto-deletion.
     * @param {DividendMasterDataRecordsApiCloneDividendsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public cloneDividends(requestParameters: DividendMasterDataRecordsApiCloneDividendsRequest, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).cloneDividends(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new dividend record.
     * @param {DividendMasterDataRecordsApiCreateDividendRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public createDividend(requestParameters: DividendMasterDataRecordsApiCreateDividendRequest, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).createDividend(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the dividend record with the given ID.
     * @param {DividendMasterDataRecordsApiDeleteDividendIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public deleteDividendId(requestParameters: DividendMasterDataRecordsApiDeleteDividendIdRequest, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).deleteDividendId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the dividend with the given ID.
     * @param {DividendMasterDataRecordsApiGetDividendIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public getDividendId(requestParameters: DividendMasterDataRecordsApiGetDividendIdRequest, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).getDividendId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all dividends.
     * @param {DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public listAndSearchAllDividendMasterDataRecords(requestParameters: DividendMasterDataRecordsApiListAndSearchAllDividendMasterDataRecordsRequest = {}, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).listAndSearchAllDividendMasterDataRecords(requestParameters.id, requestParameters.isin, requestParameters.mandatorPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the historic dividend catalogue.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public refreshDividendCatalogue(options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).refreshDividendCatalogue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh all dividend predictions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public refreshDividendPredictions(options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).refreshDividendPredictions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the dividend record with the given ID.
     * @param {DividendMasterDataRecordsApiUpdateDividendIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendMasterDataRecordsApi
     */
    public updateDividendId(requestParameters: DividendMasterDataRecordsApiUpdateDividendIdRequest, options?: RawAxiosRequestConfig) {
        return DividendMasterDataRecordsApiFp(this.configuration).updateDividendId(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum = {
    PUBLIC: 'public',
    ONVISTA: 'onvista'
} as const;
export type ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum = typeof ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum[keyof typeof ListAndSearchAllDividendMasterDataRecordsMandatorPublicIdEnum];
