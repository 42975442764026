import React, { useEffect } from "react";
import { useToken } from "@api";
import { LoadingProgressBar } from "@components";
import { LOGOUT } from "@helpers";
import { isProduction } from "@constants";

export const LogoutWithRedirect = () => {
  const { logout } = useToken({ enabled: false });

  useEffect(() => {
    logout();

    window.location.replace(
      isProduction ? LOGOUT : process.env.REACT_APP_ROOT_URL!
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingProgressBar />;
};
