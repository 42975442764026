import { TextField, Typography } from "@mui/material";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import React, { ReactNode } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ReactComponent as Calendar } from "@icons/calendar.svg";

interface Props<T>
  extends Omit<DatePickerProps<any, any>, "renderInput" | "onChange" | "value">,
    UseControllerProps<T> {
  label?: ReactNode;
  placeholder?: string;
  optional?: boolean;
  testId?: string;
}

export const RHFDatePicker = <T extends FieldValues>({
  name,
  label,
  placeholder,
  optional = false,
  testId,
  control,
  ...props
}: Props<T>) => {
  const Label = (
    <>
      {label}
      {optional ? (
        <Typography color="textSecondary" component="span" variant="body2">
          {" "}
          (optional)
        </Typography>
      ) : (
        ""
      )}
    </>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DesktopDatePicker
          onChange={field.onChange}
          value={field.value || ""}
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          components={{
            OpenPickerIcon: () => <Calendar width="20px" height="20px" />,
          }}
          PopperProps={{
            disablePortal: true,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={Label}
              placeholder={placeholder}
              error={!!error}
              helperText={error?.message}
              inputProps={{
                ...params.inputProps,
                sx: {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                  ...params.sx,
                },
                "data-testid": testId || "calendar-input",
              }}
            />
          )}
          {...props}
        />
      )}
    />
  );
};
