/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankConnectionInterface } from './bank-connection-interface';
// May contain unused imports in some cases
// @ts-ignore
import { BankConnectionOwner } from './bank-connection-owner';
// May contain unused imports in some cases
// @ts-ignore
import { BankWrapper } from './bank-wrapper';

/**
 * A wrapper around finAPIs bank connection type
 * @export
 * @interface BankConnectionWrapper
 */
export interface BankConnectionWrapper {
    /**
     * Bank connection identifier
     * @type {number}
     * @memberof BankConnectionWrapper
     */
    'id': number;
    /**
     * Custom name for the bank connection. You can set this field with the \'Edit a bank connection\' service, as well as during the initial import of the bank connection. Maximum length is 64.
     * @type {string}
     * @memberof BankConnectionWrapper
     */
    'name'?: string;
    /**
     * Bank connection type
     * @type {string}
     * @memberof BankConnectionWrapper
     */
    'type': BankConnectionWrapperTypeEnum;
    /**
     * Current status of data download (account balances and transactions/securities). The POST /bankConnections/import and POST /bankConnections/<id>/update services will set this flag to IN_PROGRESS before they return. Once the import or update has finished, the status will be changed to READY.
     * @type {string}
     * @memberof BankConnectionWrapper
     */
    'updateStatus': BankConnectionWrapperUpdateStatusEnum;
    /**
     * Current status of transactions categorization. The asynchronous download process that is triggered              by a call of the POST /bankConnections/import and POST /bankConnections/<id>/update services (and also by finAPI\'s               auto update, if enabled) will set this flag to PENDING once the download has finished and a categorization is                scheduled for the imported transactions. A separate categorization thread will then start to categorize the                 transactions (during this process, the status is IN_PROGRESS). When categorization has finished, the status                  will be (re-)set to READY. Note that the current categorization status should only be queried after the                   download has finished, i.e. once the download status has switched from IN_PROGRESS to READY.
     * @type {string}
     * @memberof BankConnectionWrapper
     */
    'categorizationStatus': BankConnectionWrapperCategorizationStatusEnum;
    /**
     * Set of interfaces that are connected for this bank connection.
     * @type {Array<BankConnectionInterface>}
     * @memberof BankConnectionWrapper
     */
    'interfaces'?: Array<BankConnectionInterface>;
    /**
     * Identifiers of the accounts that belong to this bank connection
     * @type {Array<number>}
     * @memberof BankConnectionWrapper
     */
    'accountIds': Array<number>;
    /**
     * Information about the owner(s) of the bank connection
     * @type {Array<BankConnectionOwner>}
     * @memberof BankConnectionWrapper
     */
    'owners'?: Array<BankConnectionOwner>;
    /**
     * 
     * @type {BankWrapper}
     * @memberof BankConnectionWrapper
     */
    'bank': BankWrapper;
    /**
     * This field indicates whether the last communication with the bank failed with an error that requires the user\'s attention or multi-step authentication error. If \'furtherLoginNotRecommended\' is true, finAPI will stop auto updates of this bank connection to mitigate the risk of the user\'s bank account getting locked by the bank. Every communication with the bank (via updates, money_transfers, direct debits. etc.) can change the value of this flag. If this field is true, we recommend the user to check his credentials and try a manual update of the bank connection. If the update is successful without any multi-step authentication error, the \'furtherLoginNotRecommended\' field will be set to false and the bank connection will be reincluded in the next batch update process. A manual update of the bank connection with incorrect credentials or if multi-step authentication error happens will set this field to true and lead to the exclusion of the bank connection from the following batch updates.<br/>NOTE: This field is deprecated and will be removed at some point. Please refer to the \'userActionRequired\' field of the BankConnectionInterface resource instead.
     * @type {boolean}
     * @memberof BankConnectionWrapper
     */
    'furtherLoginNotRecommended': boolean;
}

export const BankConnectionWrapperTypeEnum = {
    ONLINE: 'ONLINE',
    DEMO: 'DEMO'
} as const;

export type BankConnectionWrapperTypeEnum = typeof BankConnectionWrapperTypeEnum[keyof typeof BankConnectionWrapperTypeEnum];
export const BankConnectionWrapperUpdateStatusEnum = {
    IN_PROGRESS: 'IN_PROGRESS',
    READY: 'READY'
} as const;

export type BankConnectionWrapperUpdateStatusEnum = typeof BankConnectionWrapperUpdateStatusEnum[keyof typeof BankConnectionWrapperUpdateStatusEnum];
export const BankConnectionWrapperCategorizationStatusEnum = {
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING: 'PENDING',
    READY: 'READY'
} as const;

export type BankConnectionWrapperCategorizationStatusEnum = typeof BankConnectionWrapperCategorizationStatusEnum[keyof typeof BankConnectionWrapperCategorizationStatusEnum];


