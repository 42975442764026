import React, { useState } from "react";
import { useMyself } from "@api";
import { useChartDataByInvestment } from "@features/fundamentals/hooks/chartData/useChartDataByInvestment";
import { useChartData } from "@features/fundamentals/hooks/chartData/useChartData";
import { RangeValue } from "@components/Chart/ComparisonChart/types";
import { ComparisonChartWithFilters } from "./ComparisonChartWithFilters";
import { getCurrency } from "./helpers/getCurrency";

interface Value {
  range?: RangeValue;
  secondMetricKey?: string;
}

type Props = {
  investmentId?: number;
  isin?: string;
  quoteProvider?: string;
  tickerSymbol?: string;
  defaultValue?: Value;
  mainMetricKey: string;
  onHide: () => void;
  onChange?: ({ range, secondMetricKey }: Value) => void;
};

export const ComparisonChart = ({
  defaultValue = { range: "5y" },
  mainMetricKey,
  onHide,
  onChange,
  investmentId,
  isin,
  quoteProvider,
  tickerSymbol,
}: Props) => {
  const { data: user, isLoading: isUserLoading } = useMyself();
  const [range, setRange] = useState<RangeValue>(defaultValue.range || "5y");
  const [secondMetricKey, setSecondMetricKey] = useState<string | undefined>(
    defaultValue.secondMetricKey
  );

  const loadByInvestment = !!investmentId;

  const {
    data: dataByInvestment,
    securityData: securityDataByInvestment,
    isLoading: isChartDataByInvestmentLoading,
  } = useChartDataByInvestment(
    {
      investmentId,
      range,
      dataKey1: mainMetricKey,
      dataKey2: secondMetricKey,
    },
    { enabled: !!user && loadByInvestment }
  );

  const {
    data: dataByIsin,
    securityData: securityDataByIsin,
    isLoading: isChartDataByIsinLoading,
  } = useChartData(
    {
      isin,
      quoteProvider,
      tickerSymbol,
      range,
      dataKey1: mainMetricKey,
      dataKey2: secondMetricKey,
    },
    { enabled: !!user && !loadByInvestment }
  );

  const isLoading =
    isChartDataByInvestmentLoading || isChartDataByIsinLoading || isUserLoading;

  const handleRangeSelect = (value: RangeValue) => {
    setRange(value);
    onChange && onChange({ range: value, secondMetricKey });
  };

  const handleMetricSelected = (value?: string) => {
    setSecondMetricKey(value);
    onChange && onChange({ range, secondMetricKey: value });
  };

  const data = loadByInvestment ? dataByInvestment : dataByIsin;
  const securityData = loadByInvestment
    ? securityDataByInvestment
    : securityDataByIsin;

  const metricCurrency1 = getCurrency(mainMetricKey, securityData?.currency);
  const metricCurrency2 = getCurrency(secondMetricKey, securityData?.currency);

  const noData = !isLoading && (!data || data.length === 0);

  return (
    <ComparisonChartWithFilters
      noData={noData}
      metricCurrency1={metricCurrency1}
      metricCurrency2={metricCurrency2}
      quoteCurrency="EUR"
      isLoading={isLoading}
      defaultRange={range}
      onHide={onHide}
      data={data}
      dataKey1={mainMetricKey}
      dataKey2={secondMetricKey}
      onRangeSelect={handleRangeSelect}
      onMetricSelected={handleMetricSelected}
    />
  );
};
