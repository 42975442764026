import { UseMutationOptions } from "react-query";
import {
  UserPropertiesApiCreateOrUpdateUserPropertyRequest,
  CustomProperty,
} from "@generated/apiv1";

import { buildUserPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createOrUpdateUserProperty = async (
  token: string | undefined,
  params: UserPropertiesApiCreateOrUpdateUserPropertyRequest
) => {
  const apiInstance = buildUserPropertiesApi(token);
  const { data } = await apiInstance.createOrUpdateUserProperty(params);
  return data;
};

export const useCreateOrUpdateUserProperty = (
  options?: UseMutationOptions<
    CustomProperty,
    AxiosApiError,
    UserPropertiesApiCreateOrUpdateUserPropertyRequest
  >
) => {
  return useAuthMutation(createOrUpdateUserProperty, options, false);
};
