import { UseQueryOptions } from "react-query";
import { BookingsApiEstimateImplicitNumberOfLotsRequest } from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const estimateImplicitNumberOfLots = async (
  token: string | undefined,
  params: BookingsApiEstimateImplicitNumberOfLotsRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.estimateImplicitNumberOfLots(params);
  return data;
};

export const useEstimateImplicitNumberOfLots = (
  params: BookingsApiEstimateImplicitNumberOfLotsRequest,
  options?: UseQueryOptions<number, AxiosApiError, number>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BOOKINGS_ESTIMATE_IMPLICIT_NUMBER_OF_LOTS, params],
    ({ token }) => estimateImplicitNumberOfLots(token, params),
    options
  );
};
