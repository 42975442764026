import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const addValidationErrorAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKING_INVALID") return {};

  return {
    validationError: event.error,
  };
};
