import React, { ReactNode } from "react";
import { styled, TableCell, TableCellProps } from "@mui/material";

const StyledMuiTableCell = styled(TableCell)<
  Pick<TableBodyCellProps, "position">
>`
  ${({ position, theme }) => {
    if (position === "first")
      return `padding-left: ${theme.spacing(0)} !important`;
    if (position === "last")
      return `padding-right: ${theme.spacing(0)} !important`;
  }}
`;

interface TableBodyCellProps extends TableCellProps {
  position?: "first" | "last" | "center";
  children: ReactNode;
}

export const TableBodyCell = (props: TableBodyCellProps) => {
  const { position = "center", children, ...rest } = props;

  const cellAlign: TableCellProps["align"] =
    position === "last" ? "right" : "left";

  return (
    <StyledMuiTableCell position={position} align={cellAlign} {...rest}>
      {children}
    </StyledMuiTableCell>
  );
};
