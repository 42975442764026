/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BankErrorsAttributes } from './bank-errors-attributes';

/**
 * 
 * @export
 * @interface BankErrors
 */
export interface BankErrors {
    /**
     * Identifies the bank being imported
     * @type {string}
     * @memberof BankErrors
     */
    'bank_slug'?: BankErrorsBankSlugEnum;
    /**
     * 
     * @type {Array<BankErrorsAttributes>}
     * @memberof BankErrors
     */
    'errors'?: Array<BankErrorsAttributes>;
}

export const BankErrorsBankSlugEnum = {
    TRADE_REPUBLIC: 'TradeRepublic',
    FINANZEN_NET: 'finanzen_net',
    BISON_APP: 'bison_app',
    BINANCE: 'binance',
    QUIRION: 'Quirion',
    DEGIRO: 'Degiro',
    BITPANDA: 'Bitpanda',
    WHITEBOX: 'Whitebox',
    SCALABLE_CAPITAL: 'ScalableCapital',
    MOCK_BANK: 'MockBank',
    SMARTBROKER_PLUS: 'SmartbrokerPlus',
    JUST_TRADE: 'JustTrade',
    PARQET: 'Parqet'
} as const;

export type BankErrorsBankSlugEnum = typeof BankErrorsBankSlugEnum[keyof typeof BankErrorsBankSlugEnum];


