import { UseQueryOptions } from "react-query";
import { getWatchlists } from "@api/v1/methods";
import { ListAndSearchAllWatchlists, WatchlistList } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_WATCHLISTS } from "../../cacheKeys";

export const useGetWatchlists = (
  params: ListAndSearchAllWatchlists = {},
  options?: UseQueryOptions<WatchlistList, AxiosApiError, WatchlistList>
) =>
  useAuthQuery(
    [GET_WATCHLISTS, params],
    ({ token }) => getWatchlists(token, params),
    options
  );
