import {
  endOfMonth,
  endOfWeek,
  format,
  startOfWeek,
  eachDayOfInterval,
  addMonths,
} from "date-fns";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { DividendHistoryTreeNode } from "@generated/apiv1";
import { generateRandomNumber } from "@helpers";

const getDividendsForMonth = (
  date: Date,
  isUserPro: boolean,
  dividends?: Record<string, DividendHistoryTreeNode>
) => {
  if (!dividends) return {};

  return groupBy(
    Object.values(
      dividends?.[String(date.getFullYear())]?.children?.[
        String(date.getMonth())
      ]?.children || {}
    ).map((dividend) => ({
      ...dividend,
      grossAmount:
        dividend.isPrediction && !isUserPro
          ? generateRandomNumber(0, dividend.grossAmount)
          : dividend.grossAmount,
    })),
    (dividend) => format(new Date(dividend.paymentDate), "dd.MM.yyyy")
  );
};

const getMonthChildren = (month: DividendHistoryTreeNode) => {
  return Object.values(month.children || {});
};

const getWeekdaysBetweenDates = (startDate: Date, endDate: Date) => {
  const allDays = eachDayOfInterval({ start: startDate, end: endDate });
  return allDays;
};

export const useDividendsForCalendar = ({
  date,
  period,
}: {
  date: {
    month: number;
    year: number;
  };
  period: "month" | "year";
}): {
  grossAmount: number | undefined;
  dividends: {
    date: Date;
    dividends: DividendHistoryTreeNode[];
  }[];
} => {
  const { dividends, isUserPro, isUserDataFetched } = useDividendsContext();
  const actualDate = useMemo(() => new Date(date.year, date.month), [date]);

  return useMemo(() => {
    if (!isUserDataFetched) return { grossAmount: undefined, dividends: [] };

    if (period === "month") {
      const currentMonth =
        dividends?.[String(actualDate.getFullYear())]?.children?.[
          String(actualDate.getMonth())
        ];
      const previousMonth = addMonths(actualDate, -1);
      const nextMonth = addMonths(actualDate, 1);

      const dividendsForPreviousMonth = getDividendsForMonth(
        previousMonth,
        isUserPro,
        dividends
      );
      const dividendsForNextMonth = getDividendsForMonth(
        nextMonth,
        isUserPro,
        dividends
      );
      const dividendsForCurrentMonth = getDividendsForMonth(
        actualDate,
        isUserPro,
        dividends
      );

      const startDay = startOfWeek(actualDate);
      const endDay = endOfWeek(endOfMonth(actualDate));
      const days = getWeekdaysBetweenDates(startDay, endDay);

      return {
        grossAmount: currentMonth?.grossAmount,
        dividends: days.map((day) => {
          if (day.getMonth() === previousMonth.getMonth())
            return {
              date: day,
              dividends:
                dividendsForPreviousMonth[format(day, "dd.MM.yyyy")] || [],
            };

          if (day.getMonth() === nextMonth.getMonth())
            return {
              date: day,
              dividends: dividendsForNextMonth[format(day, "dd.MM.yyyy")] || [],
            };

          return {
            date: day,
            dividends:
              dividendsForCurrentMonth[format(day, "dd.MM.yyyy")] || [],
          };
        }),
      };
    } else {
      const currentYear = dividends?.[String(actualDate.getFullYear())];

      return {
        grossAmount: currentYear?.grossAmount,
        dividends: Array(12)
          .fill(undefined)
          .map((_, index) => {
            const month = currentYear?.children?.[String(index)];

            return {
              date: new Date(actualDate.getFullYear(), index),
              dividends: month ? getMonthChildren(month) : [],
            };
          }),
      };
    }
  }, [dividends, actualDate, period, isUserDataFetched, isUserPro]);
};
