import React from "react";
import { Link, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginFormLinks = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Typography variant="body2">
          {t("domains.loginForm.register.question")}&nbsp;
        </Typography>
        <Link href={`${process.env.REACT_APP_V2_URL}/signup`} underline="hover">
          <Typography variant="body2" fontWeight="600">
            &raquo;&nbsp;{t("domains.loginForm.register.label")}
          </Typography>
        </Link>
      </Container>

      <Container>
        <Typography variant="body2">
          {t("domains.loginForm.password.forgot")}&nbsp;
        </Typography>
        <Link
          href={`${process.env.REACT_APP_V2_URL}/reset/password`}
          underline="hover"
        >
          <Typography variant="body2" fontWeight="600">
            &raquo;&nbsp;{t("domains.loginForm.password.clickHere")}
          </Typography>
        </Link>
      </Container>
    </>
  );
};
