import { useAuthQuery, loadHistoricQuotes } from "@api";

const loadHistoricQuotesPerYear = (
  token: string | undefined,
  {
    investmentId,
    tickerSymbol,
    from,
  }: {
    investmentId: number;
    tickerSymbol: string;
    from?: string;
  }
) =>
  loadHistoricQuotes(token, {
    tickerSymbol,
    investmentId,
    from,
    intervalPosition: "last",
    interval: "year",
  });

export const useHistoricQuotesPerYear = (
  investmentId?: number,
  tickerSymbol?: string,
  from?: string,
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) =>
  useAuthQuery(
    [
      "historic-quotes-per-year",
      { investmentId: investmentId!, tickerSymbol: tickerSymbol!, from },
    ],
    ({ token }) =>
      loadHistoricQuotesPerYear(token, {
        investmentId: investmentId!,
        tickerSymbol: tickerSymbol!,
        from,
      }),
    {
      enabled: !!investmentId && !!tickerSymbol && enabled,
      refetchOnWindowFocus: false,
    }
  );

const loadHistoricQuotesPercent = (
  token: string | undefined,
  {
    investmentId,
    tickerSymbol,
    from,
  }: {
    investmentId: number;
    tickerSymbol: string;
    from?: string;
  }
) =>
  loadHistoricQuotes(token, {
    tickerSymbol,
    investmentId,
    from,
    inPercentFrom: "currentToNow",
    intervalPosition: "current",
    interval: "year",
  });

export const useHistoricQuotesPercent = (
  investmentId?: number,
  tickerSymbol?: string,
  from?: string
) =>
  useAuthQuery(
    [
      "historic-quotes-percent",
      { investmentId: investmentId!, tickerSymbol: tickerSymbol!, from },
    ],
    ({ token }) =>
      loadHistoricQuotesPercent(token, {
        investmentId: investmentId!,
        tickerSymbol: tickerSymbol!,
        from,
      }),
    {
      enabled: !!investmentId && !!tickerSymbol,
      refetchOnWindowFocus: false,
    }
  );
