import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { CustomTooltipContainer } from "@components";
import { toShort } from "@helpers";

const Circle = styled(Box)<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

interface Props {
  active: boolean;
  label: string;
  ref: React.RefObject<HTMLDivElement>;
  payload: Partial<{
    name: string;
    value: number;
    color: string;
    payload: any;
  }>[];
}

export const CustomTooltip = React.forwardRef<HTMLDivElement, Partial<Props>>(
  ({ active, payload = [] }, ref) => {
    if (!active || !payload) return null;

    return (
      <CustomTooltipContainer ref={ref} withShadow>
        <Box>
          <Typography variant="body2" mb={2}>
            {payload[0].payload.year}
          </Typography>
          {payload.map((item: any) => (
            <Box display="flex" alignItems="center" gap={1.5}>
              <Circle color={item.color!} />
              <Typography variant="body2" fontWeight={400}>
                {item.name}:
              </Typography>
              <Typography variant="body2">{toShort(item.value)}</Typography>
            </Box>
          ))}
        </Box>
      </CustomTooltipContainer>
    );
  }
);
