import { UseQueryOptions } from "react-query";
import {
  BankHealthReportsApiBankHealthRequest,
  BankHealth,
} from "@generated/apiv3";

import { buildBankHealthReportsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const bankHealth = async (
  token: string | undefined,
  params: BankHealthReportsApiBankHealthRequest
) => {
  const apiInstance = buildBankHealthReportsApi(token);
  const { data } = await apiInstance.bankHealth(params);
  return data;
};

export const useBankHealth = (
  params: BankHealthReportsApiBankHealthRequest,
  options?: UseQueryOptions<BankHealth, AxiosApiError, BankHealth>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKHEALTHREPORTS_BANK_HEALTH, params],
    ({ token }) => bankHealth(token, params),
    options
  );
};
