import React, { ReactNode } from "react";
import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import MuiKeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { ReactComponent as LockIcon } from "@icons/lock-dot.svg";
import { ReactComponent as LockOpenIcon } from "@icons/lock-dot-open.svg";
import { useHelpers } from "../hooks/useHelpers";

const NumberInput = (props: TextFieldProps) => (
  <TextField {...props} autoComplete="off" fullWidth />
);

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

const TowFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: stretch;
  position: relative;
`;

export interface Props {
  className?: string;
  fieldLabel: ReactNode;
  id: string;
  value: string;
  takeoverValue?: number;
  takeoverDate?: Date;
  handleChange: (value: string) => void;
  handleTakeover?: (value: string) => void;
  endAdornment?: string;
  startAdornment?: string;
  maximumFractionDigits?: number;
  disabled?: boolean;
  onLockClick?: () => void;
  canClickOnLockClick?: boolean;
}

export const InputFieldWithRevert = ({
  className,
  fieldLabel,
  id,
  value,
  takeoverValue,
  takeoverDate,
  handleChange,
  handleTakeover,
  endAdornment,
  startAdornment,
  maximumFractionDigits = 6,
  disabled,
  onLockClick,
}: Props) => {
  const theme = useTheme();
  const { toString } = useHelpers();

  return (
    <Container className={className}>
      <TowFieldsContainer>
        <NumericFormat
          value={value}
          label={
            <Box
              display="flex"
              alignItems="center"
              justifyContent={
                Boolean(onLockClick) ? "space-between" : "flex-start"
              }
            >
              <InputLabel sx={{ marginBottom: 0 }}>{fieldLabel}</InputLabel>
              {Boolean(onLockClick) && (
                <Box
                  display="flex"
                  onClick={onLockClick}
                  color="icon.primary"
                  sx={{
                    opacity: disabled ? 0.5 : 1,
                    cursor: disabled ? "default" : "pointer",
                  }}
                >
                  {disabled ? <LockIcon /> : <LockOpenIcon />}
                </Box>
              )}
            </Box>
          }
          onChange={(event) => {
            handleChange(event.target.value.replace(/\./gi, ""));
          }}
          id={id}
          data-testid={id}
          inputProps={{
            style: { textAlign: "left" },
            "data-testid": `${id}-input`,
          }}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : undefined,
          }}
          disabled={disabled}
          thousandSeparator="."
          decimalSeparator=","
          customInput={NumberInput}
        />
      </TowFieldsContainer>
      {takeoverValue && handleTakeover && (
        <Box display="flex" alignItems="flex-end">
          <Button
            data-testid={`${id}-takeover`}
            variant="text"
            color="primary"
            disabled={disabled}
            onClick={() => {
              handleTakeover(
                takeoverValue
                  .toFixed(maximumFractionDigits)
                  .replace(/(\.\d*?[1-9])0+$|\.0+$/, "$1") || ""
              );
            }}
            size="small"
            startIcon={
              <MuiKeyboardArrowLeftIcon sx={{ fontSize: "24px !important" }} />
            }
            sx={{
              padding: theme.spacing(1.25, 3, 1.25, 1),
              height: "48px",
              "& .MuiButton-startIcon": {
                marginRight: theme.spacing(0),
              },
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ lineHeight: "14px" }}>
                {takeoverDate && format(takeoverDate, "dd.MM.yyyy")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                {`${toString(takeoverValue, "", 3)}${endAdornment ? " " : ""}${
                  endAdornment ?? ""
                }`}
              </Typography>
            </Box>
          </Button>
        </Box>
      )}
    </Container>
  );
};
