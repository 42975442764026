import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { WizardsHeader, Variant } from "@components/common";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
`;

const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(20)};
  width: 100%;
`;

interface Props {
  children: ReactNode;
  backUrl: string;
  title: string;
  variant?: Variant;
  testId?: string;
}

export const SimpleLayout = ({
  backUrl,
  children,
  title,
  testId,
  variant = "dark",
}: Props) => (
  <Container data-testid={testId}>
    <InnerContainer>{children}</InnerContainer>
    <HeaderContainer>
      <WizardsHeader title={title} backUrl={backUrl} variant={variant} />
    </HeaderContainer>
  </Container>
);
