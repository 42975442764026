import React, { ReactNode } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";
import MuiKeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { ReactComponent as UndoIcon } from "@icons/myicons/arrow-undo.svg";
import { useHelpers } from "../hooks/useHelpers";

const NumberInput = (props: TextFieldProps) => (
  <TextField {...props} autoComplete="off" fullWidth />
);

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 12px;
`;

const ButtonContentContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  right: 0;
  top: 0;
  z-index: 5;
`;

const StyledPopover = styled(Popover)`
  pointer-events: none;

  & > *.MuiPopover-paper {
    background-color: white;
    padding: 10px;
    max-width: 300px;
  }
`;

const TowFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: stretch;
  position: relative;
`;

export interface Props {
  className?: string;
  fieldLabel: ReactNode;
  id: string;
  value: string;
  rollbackValue?: number;
  takeoverValue?: number;
  takeoverDate?: Date;
  handleChange: (value: string) => void;
  handleTakeover?: (value: string) => void;
  endAdornment?: string;
  startAdornment?: string;
  maximumFractionDigits?: number;
}

export const InputFieldWithRevert = ({
  className,
  fieldLabel,
  id,
  value,
  rollbackValue,
  takeoverValue,
  takeoverDate,
  handleChange,
  handleTakeover,
  endAdornment,
  startAdornment,
  maximumFractionDigits = 6,
}: Props) => {
  const theme = useTheme();
  const { toString, parseOrUndefined } = useHelpers();
  const { t } = useTranslation();

  const [anchorPopoverEl, setAnchorPopoverEl] =
    React.useState<HTMLDivElement | null>(null);
  const showHint = Boolean(anchorPopoverEl);

  const handleShowHint = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorPopoverEl(event.currentTarget);
  };

  const handleCloseHint = () => {
    setAnchorPopoverEl(null);
  };

  const showRollback =
    rollbackValue != null && parseOrUndefined(value) !== rollbackValue;

  return (
    <Container className={className}>
      <TowFieldsContainer>
        {showRollback && (
          <ButtonContentContainer
            onMouseEnter={handleShowHint}
            onMouseLeave={handleCloseHint}
            onClick={() => {
              handleCloseHint();
              handleChange(toString(rollbackValue, "", maximumFractionDigits));
            }}
            sx={{ position: "absolute", cursor: "pointer" }}
          >
            <UndoIcon height="19px" />
            <Box>
              <Typography
                variant="body2"
                noWrap
                data-testid={`${id}-revert`}
                color="primary.main"
              >
                {t("transactionWizard.inputFieldWithRevert.revert")}
              </Typography>
            </Box>
            <StyledPopover
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              disableRestoreFocus
              disableEnforceFocus
              open={showHint}
              anchorEl={anchorPopoverEl}
              onClose={handleCloseHint}
            >
              {t("transactionWizard.inputFieldWithRevert.revertDescription", {
                value: `${toString(rollbackValue)} ${endAdornment}`,
              })}
            </StyledPopover>
          </ButtonContentContainer>
        )}
        <NumericFormat
          value={value}
          label={fieldLabel}
          onChange={(event) => {
            handleChange(event.target.value.replace(/\./gi, ""));
          }}
          id={id}
          data-testid={id}
          inputProps={{
            style: { textAlign: "left" },
            "data-testid": `${id}-input`,
          }}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
            endAdornment: endAdornment ? (
              <InputAdornment position="start">{endAdornment}</InputAdornment>
            ) : undefined,
          }}
          thousandSeparator="."
          decimalSeparator=","
          customInput={NumberInput}
        />
      </TowFieldsContainer>
      {takeoverValue && handleTakeover && (
        <Box display="flex" alignItems="flex-end">
          <Button
            data-testid={`${id}-takeover`}
            variant="text"
            color="primary"
            onClick={() => {
              handleTakeover(
                takeoverValue
                  .toFixed(maximumFractionDigits)
                  .replace(/(\.\d*?[1-9])0+$|\.0+$/, "$1") || ""
              );
            }}
            size="small"
            startIcon={
              <MuiKeyboardArrowLeftIcon sx={{ fontSize: "24px !important" }} />
            }
            sx={{
              padding: theme.spacing(1, 3, 1, 1),
              height: "46px",
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ lineHeight: "14px" }}>
                {takeoverDate && format(takeoverDate, "dd.MM.yyyy")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                {`${toString(takeoverValue, "", 3)}${endAdornment ? " " : ""}${
                  endAdornment ?? ""
                }`}
              </Typography>
            </Box>
          </Button>
        </Box>
      )}
    </Container>
  );
};
