import { UseQueryOptions } from "react-query";
import {
  PlugAndPlayApiListProductsRequest,
  ListProductsResponse,
} from "@generated/apiv3";

import { buildPlugAndPlayApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listProducts = async (
  token: string | undefined,
  params?: PlugAndPlayApiListProductsRequest
) => {
  const apiInstance = buildPlugAndPlayApi(token);
  const { data } = await apiInstance.listProducts(params);
  return data;
};

export const useListProducts = (
  params?: PlugAndPlayApiListProductsRequest,
  options?: UseQueryOptions<
    ListProductsResponse,
    AxiosApiError,
    ListProductsResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PLUGANDPLAY_LIST_PRODUCTS, params],
    ({ token }) => listProducts(token, params),
    options
  );
};
