import { UseQueryOptions } from "react-query";
import {
  PerformanceApiRiskYieldStatisticsRequest,
  RiskYieldStatistics,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const riskYieldStatistics = async (
  token: string | undefined,
  params?: PerformanceApiRiskYieldStatisticsRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.riskYieldStatistics(params);
  return data;
};

export const useRiskYieldStatistics = (
  params?: PerformanceApiRiskYieldStatisticsRequest,
  options?: UseQueryOptions<
    RiskYieldStatistics,
    AxiosApiError,
    RiskYieldStatistics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_RISK_YIELD_STATISTICS, params],
    ({ token }) => riskYieldStatistics(token, params),
    options
  );
};
