import { UseQueryOptions } from "react-query";
import {
  DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest,
  PageableDepotSynchronizationLogList,
} from "@generated/apiv1";

import { buildDepotSynchronizationLogsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const findAndSearchDepotSynchronizationLogs = async (
  token: string | undefined,
  params: DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest
) => {
  const apiInstance = buildDepotSynchronizationLogsApi(token);
  const { data } = await apiInstance.findAndSearchDepotSynchronizationLogs(
    params
  );
  return data;
};

export const useFindAndSearchDepotSynchronizationLogs = (
  params: DepotSynchronizationLogsApiFindAndSearchDepotSynchronizationLogsRequest,
  options?: UseQueryOptions<
    PageableDepotSynchronizationLogList,
    AxiosApiError,
    PageableDepotSynchronizationLogList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.DEPOTSYNCHRONIZATIONLOGS_FIND_AND_SEARCH_DEPOT_SYNCHRONIZATION_LOGS,
      params,
    ],
    ({ token }) => findAndSearchDepotSynchronizationLogs(token, params),
    options
  );
};
