import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiCorrelationToReferenceRequest,
  CorrelationToReference,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const correlationToReference = async (
  token: string | undefined,
  params?: RiskYieldMetricsApiCorrelationToReferenceRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.correlationToReference(params);
  return data;
};

export const useCorrelationToReference = (
  params?: RiskYieldMetricsApiCorrelationToReferenceRequest,
  options?: UseQueryOptions<
    CorrelationToReference,
    AxiosApiError,
    CorrelationToReference
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_CORRELATION_TO_REFERENCE, params],
    ({ token }) => correlationToReference(token, params),
    options
  );
};
