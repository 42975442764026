import { UseQueryOptions } from "react-query";
import { InternalTransactionsApiGetBatchTransferXmlRequest } from "@generated/apiv1";

import { buildInternalTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBatchTransferXml = async (
  token: string | undefined,
  params: InternalTransactionsApiGetBatchTransferXmlRequest
) => {
  const apiInstance = buildInternalTransactionsApi(token);
  const { data } = await apiInstance.getBatchTransferXml(params);
  return data;
};

export const useGetBatchTransferXml = (
  params: InternalTransactionsApiGetBatchTransferXmlRequest,
  options?: UseQueryOptions<object, AxiosApiError, object>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INTERNALTRANSACTIONS_GET_BATCH_TRANSFER_XML, params],
    ({ token }) => getBatchTransferXml(token, params),
    options
  );
};
