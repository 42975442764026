import { UseQueryOptions } from "react-query";
import {
  deleteCashbackConnections,
  getBankConnections,
  importCashbackConnections,
} from "@api/v1/methods";
import { GetBankConnectionParams, BankConnectionList } from "@api/v1/model";
import { useAuthQuery, useAuthMutation } from "@api/auth";
import { GET_BANK_CONNECTIONS } from "../../cacheKeys";
import { useAccounts } from "./useAccounts";

export const useGetRelatedBankConnections = (
  id?: number,
  {
    onSuccess,
    enabled = true,
  }: { onSuccess?: (data: BankConnectionList) => void; enabled?: boolean } = {}
) => {
  const { data: accountList } = useAccounts(
    { bankConnectionId: id == null ? undefined : [id] },
    { enabled: !!id && enabled }
  );
  const accountIds = accountList?.accounts?.map((it) => it.id);
  return useAuthQuery(
    [
      "related-bank-connections",
      { accountId: accountIds, includeRelatedAccounts: true },
    ],
    ({ token }) =>
      getBankConnections(token, {
        accountId: accountIds,
        includeRelatedAccounts: true,
      }),
    { enabled: !!accountIds && enabled, onSuccess }
  );
};

export const useImportCashback = (options = {}) =>
  useAuthMutation(importCashbackConnections, {
    useErrorBoundary: true,
    ...options,
  });

export const useDeleteCashback = (options = {}) =>
  useAuthMutation(deleteCashbackConnections, {
    useErrorBoundary: true,
    ...options,
  });

export const useGetBankConnections = (
  params: GetBankConnectionParams = {},
  options: UseQueryOptions<
    BankConnectionList,
    AxiosApiError,
    BankConnectionList
  > = { enabled: true, retry: false }
) =>
  useAuthQuery(
    [GET_BANK_CONNECTIONS, params],
    ({ token }) => getBankConnections(token, params),
    options
  );
