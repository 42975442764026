import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const SecuritiesMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const SecuritiesMessage = () => {
  const { t } = useTranslation();

  return (
    <SecuritiesMessageWrapper>
      <Typography variant="h4" color="textPrimary">
        {t("securitiesAddPage.title")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t("securitiesAddPage.description")}
      </Typography>
    </SecuritiesMessageWrapper>
  );
};
