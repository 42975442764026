import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { Box, BoxProps } from "@mui/material";
import { SearchHighlight } from "@components";

interface Option {
  label: string;
  value: string;
  isNew?: boolean;
}

const defaultFilterOptions = createFilterOptions<Option>({
  stringify: (option) => option.label,
  matchFrom: "start",
});

interface Props extends Omit<BoxProps, "onChange"> {
  defaultTag?: string;
  className?: string;
  onChange?: (tag: string | null) => void;
  isError?: boolean;
  errorText?: string;
  disabled?: boolean;
  tags?: string[];
  noLabel?: boolean;
}

export const TagSelector = ({
  className,
  onChange,
  defaultTag,
  isError = false,
  errorText,
  disabled = false,
  tags = [],
  noLabel,
  ...props
}: Props) => {
  const [currentTag, setCurrentTag] = useState<Option | null>(
    defaultTag ? { label: defaultTag, value: defaultTag } : null
  );
  const { t } = useTranslation();

  const handleChange = (option: Option | null | string) => {
    if (typeof option === "string" || (option && "isNew" in option)) {
      let value = typeof option === "string" ? option : option.value;

      onChange && onChange(value);
      setCurrentTag({ label: value, value });
      return;
    }

    setCurrentTag(option);
    onChange && onChange(option?.value ?? null);
  };

  const tagsOptions: Option[] = tags.map((tag) => ({ label: tag, value: tag }));

  return (
    <Box {...props}>
      <Autocomplete
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={disabled}
        disablePortal
        value={currentTag}
        className={className}
        onChange={(e, value) => handleChange(value)}
        options={tagsOptions}
        getOptionLabel={(value) => {
          if (typeof value === "string") {
            return value;
          }
          return value.label || "";
        }}
        filterOptions={(options, state) => {
          const result = defaultFilterOptions(options, state);
          if (result.length > 0) {
            return result;
          }
          if (state.inputValue.length > 0) {
            return [
              {
                label: t("tagSelector.addNewOption", {
                  name: state.inputValue,
                }),
                value: state.inputValue,
                isNew: true,
              },
            ];
          }
          return [];
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={noLabel ? null : t("tagSelector.label")}
              placeholder={t("tagSelector.label")}
              error={isError}
              helperText={errorText}
              inputProps={{
                ...params.inputProps,
                "data-testid": "tag-selector-input",
              }}
            />
          );
        }}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <div data-testid="tag-selector-option">
              <SearchHighlight value={option.label} searchValue={inputValue} />
            </div>
          </li>
        )}
        freeSolo
      />
    </Box>
  );
};
