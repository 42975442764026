import { AxiosError } from "axios";
import { RefreshTokenError } from "@api/auth/RefreshTokenError";
import { TokenExpiredError } from "@api/auth/TokenExpiredError";
import { NoTokenError } from "./NoTokenError";

export const isAuthError = (
  error:
    | AxiosError<ApiException>
    | NoTokenError
    | RefreshTokenError
    | TokenExpiredError
) => {
  if (
    error instanceof NoTokenError ||
    error instanceof RefreshTokenError ||
    error instanceof TokenExpiredError
  ) {
    return true;
  }
  if (error.response?.status === 401) {
    return true;
  }

  return false;
};
