import { useMutation, useQuery } from "react-query";
import { getExchangeRate } from "@api/v1/methods";
import { GetExchangeRateParams } from "@api/v1/model";
import { GET_EXCHANGE_RATE } from "../../cacheKeys";

export const useGetExchangeRate = (
  params: GetExchangeRateParams,
  enabled = true
) =>
  useQuery(
    [GET_EXCHANGE_RATE, JSON.stringify(params)],
    () => getExchangeRate(params),
    {
      enabled: !!params.from && !!params.to && enabled,
    }
  );

export const useGetExchangeRateOnDemand = () => {
  return useMutation(getExchangeRate, {
    useErrorBoundary: false,
  });
};
