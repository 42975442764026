import { InvokeCallback, Sender } from "xstate";

import { getBookingWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const loadBooking =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback: Sender<DividendWizardEvent>) => {
    if (event.type !== "LOADED_INVESTMENT") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    if (!context.bookingId) {
      throw new Error(`Invalid booking id: ${context.bookingId}`);
    }

    load(context.bookingId, callback);
  };

const load = async (
  bookingId: number,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const booking = await getBookingWithToken(bookingId);

    callback({
      type: "LOADED_BOOKING",
      booking,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
