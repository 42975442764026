/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateDividendMasterDataRecordParams
 */
export interface CreateOrUpdateDividendMasterDataRecordParams {
    /**
     * The dividends ISIN; required for creation
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'isin'?: string;
    /**
     * The dividend provider; default is webScraper
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'provider'?: CreateOrUpdateDividendMasterDataRecordParamsProviderEnum;
    /**
     * Payout date; required for creatin
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'paymentDate'?: string;
    /**
     * EX date (you will not receive the dividend if you purchase at             or after this date). Default is no ex date.
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'exDate'?: string;
    /**
     * The amount in dividend currency; required for creation
     * @type {number}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'amount'?: number;
    /**
     * The split adjusted amount in dividend currency; if any.
     * @type {number}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'splitAdjustedAmount'?: number;
    /**
     * The amount in account currency (typically EUR); required for creation
     * @type {number}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'amountInCurrency'?: number;
    /**
     * The split adjusted amount in account currency (typically EUR), if any
     * @type {number}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'splitAdjustedAmountInCurrency'?: number;
    /**
     * The dividend currency; 3-letter ISO code; required for creation
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'currency'?: string;
    /**
     * Is this a special (i.e. non-regular) payout?
     * @type {boolean}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'isSpecial'?: boolean;
    /**
     * Was this record marked as deleted?
     * @type {boolean}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'markedAsDeleted'?: boolean;
    /**
     * Was this record marked as curated? Curated records will not be overwritten
     * @type {boolean}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'wasCurated'?: boolean;
    /**
     * The literal type of the dividend. This is a natural language description of the dividend, such as         \'Special Dividend\' or \'Quarterly Dividend\'. The language of the type is data provider specific and may be German or English.
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'literalType'?: string;
    /**
     * The mandator scope; will use no mandator scope as default unless specified
     * @type {string}
     * @memberof CreateOrUpdateDividendMasterDataRecordParams
     */
    'mandatorScope'?: CreateOrUpdateDividendMasterDataRecordParamsMandatorScopeEnum;
}

export const CreateOrUpdateDividendMasterDataRecordParamsProviderEnum = {
    WEB_SCRAPER: 'webScraper',
    FWW: 'fww',
    EXTRA_ETF: 'extraEtf',
    ONVISTA: 'onvista',
    MANUAL: 'manual'
} as const;

export type CreateOrUpdateDividendMasterDataRecordParamsProviderEnum = typeof CreateOrUpdateDividendMasterDataRecordParamsProviderEnum[keyof typeof CreateOrUpdateDividendMasterDataRecordParamsProviderEnum];
export const CreateOrUpdateDividendMasterDataRecordParamsMandatorScopeEnum = {
    ONVISTA: 'onvista'
} as const;

export type CreateOrUpdateDividendMasterDataRecordParamsMandatorScopeEnum = typeof CreateOrUpdateDividendMasterDataRecordParamsMandatorScopeEnum[keyof typeof CreateOrUpdateDividendMasterDataRecordParamsMandatorScopeEnum];


