import { UseQueryOptions } from "react-query";
import {
  SubscriptionsApiGetSubscriptionRequest,
  Subscription,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSubscription = async (
  token: string | undefined,
  params: SubscriptionsApiGetSubscriptionRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.getSubscription(params);
  return data;
};

export const useGetSubscription = (
  params: SubscriptionsApiGetSubscriptionRequest,
  options?: UseQueryOptions<Subscription, AxiosApiError, Subscription>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONS_GET_SUBSCRIPTION, params],
    ({ token }) => getSubscription(token, params),
    options
  );
};
