import { RangeValue } from "@components";

export const FINANCIAL_METRIC_KEYS = [
  "eps",
  "totalLiabilities",
  "operatingCashflow",
  "capEx",
  "freeCashflow",
  "totalEquity",
  "netIncome",
  "netRevenue",
  "outstandingShares",
  "returnOnAssets",
  "returnOnEquity",
];

export const STATISTIC_METRIC_KEYS = [
  "priceToEarnings",
  "priceToBook",
  "priceToCashflow",
  "priceToFreeCashflow",
  "priceToSales",
];

export const METRICS = [...FINANCIAL_METRIC_KEYS, ...STATISTIC_METRIC_KEYS];

export const METRICS_WITH_CURRENCY = [
  "eps",
  "totalLiabilities",
  "operatingCashflow",
  "capEx",
  "freeCashflow",
  "totalEquity",
  "netIncome",
  "netRevenue",
  "returnOnAssets",
  "returnOnEquity",
];

export interface SecurityMetricValue {
  mainMetricKey: string;
  secondMetricKey?: string;
  range?: RangeValue;
}
