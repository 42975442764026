import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const clearValidationErrorAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKING_VALID") return {};

  return {
    validationError: undefined,
  };
};
