import { UseMutationOptions } from "react-query";
import { SignupApiCreateUserRequest, User } from "@generated/apiv1";

import { buildSignupApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createUser = async (
  token: string | undefined,
  params: SignupApiCreateUserRequest
) => {
  const apiInstance = buildSignupApi(token);
  const { data } = await apiInstance.createUser(params);
  return data;
};

export const useCreateUser = (
  options?: UseMutationOptions<User, AxiosApiError, SignupApiCreateUserRequest>
) => {
  return useAuthMutation(createUser, options, false);
};
