import React, { createContext, useContext } from "react";
import {
  Account,
  AccountsApiListAndSearchAllAccountsRequest,
} from "@generated/apiv1";
import { usePublicOrPrivateAccounts } from "@features/sharedPortfolio/hooks/usePublicOrPrivateAccounts";
import { useSelectedDepotsFromLocalStorage } from "@features/selectDepots/components/useSelectedDepotsFromLocalStorage";
import { AccountGroupName } from "@features/selectDepots/types";

export interface AccountImage {
  id: number;
  image: string;
  isReference: boolean;
}

export interface AccountsContextProps {
  accounts: Account[] | undefined;
  areAccountsFetched: boolean;
  areAccountsLoading: boolean;
  selectedAccounts: number[];
  setSelectedAccounts: (accountId: number[]) => void;
}

const AccountsContext = createContext<AccountsContextProps>({
  accounts: [],
  areAccountsFetched: false,
  areAccountsLoading: false,
  selectedAccounts: [],
  setSelectedAccounts: () => {},
});

export const useAccountsContext = (): AccountsContextProps =>
  useContext(AccountsContext);

const AccountsProvider: React.FC<{
  children: React.ReactNode;
  selectedGroupsKey: AccountGroupName;
  accountsParams?: AccountsApiListAndSearchAllAccountsRequest;
}> = (props) => {
  const { children, selectedGroupsKey, accountsParams = {} } = props;

  const {
    data: accounts,
    isFetched: areAccountsFetched,
    isLoading: areAccountsLoading,
  } = usePublicOrPrivateAccounts(accountsParams);

  const { selectedAccounts, setSelectedAccounts } =
    useSelectedDepotsFromLocalStorage(selectedGroupsKey, accounts);

  return (
    <AccountsContext.Provider
      value={{
        areAccountsFetched,
        areAccountsLoading,
        selectedAccounts,
        setSelectedAccounts,
        accounts,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export { AccountsContext, AccountsProvider };
