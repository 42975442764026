import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Expand, RotateRight } from "@mui/icons-material";
import React from "react";
import { ReactComponent as FinanzflussExpand } from "@icons/arrows-left-right-to-line-solid.svg";
import { ReactComponent as FinanzflussRotateRight } from "@icons/clock-rotate-left-solid.svg";

type IntervalProps = {
  isRegular?: boolean;
  paymentInterval?: number;
  isInterAccountTransfer?: boolean;
};
export const IntervalInfo = ({
  isRegular,
  paymentInterval,
  isInterAccountTransfer,
}: IntervalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const mapIntervalToApi: Record<number, string> = {
    1: "everyMonth",
    2: "biMonthly",
    3: "quarterly",
    6: "biAnnual",
    12: "annual",
  };
  return (
    <Box display={isDownSm ? "flex" : "column"} mt={1}>
      {isRegular && (
        <Box display="flex" alignItems="center" mr={3} width="max-content">
          {theme.type === "finanzfluss" ? (
            <FinanzflussRotateRight
              width="16px"
              height="16px"
              style={{ marginRight: theme.spacing(1) }}
              fill={theme.palette.icon.secondary}
            />
          ) : (
            <RotateRight
              sx={{
                width: "16px",
                height: "16px",
                marginRight: 1,
                color: theme.palette.icon.primary,
              }}
            />
          )}
          {paymentInterval ? (
            <Typography variant="body2" color="textSecondary">
              {t(
                `singleAccountPage.protocolTable.interval.${mapIntervalToApi[paymentInterval]}`
              )}
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              {t(`cashflowAnalyzer.transactionsTable.regular`)}
            </Typography>
          )}
        </Box>
      )}
      {isInterAccountTransfer && (
        <Box display="flex" alignItems="center">
          {theme.type === "finanzfluss" ? (
            <FinanzflussExpand
              width="16px"
              height="16px"
              style={{ marginRight: theme.spacing(1) }}
              fill={theme.palette.icon.secondary}
            />
          ) : (
            <Expand
              sx={{
                width: "16px",
                height: "16px",
                transform: "rotate(90deg)",
                marginRight: 1,
                color: theme.palette.icon.primary,
              }}
            />
          )}
          <Typography variant="body2" color="textSecondary">
            {t("singleAccountPage.protocolTable.transfer")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
