/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CsvImportParseAttributes
 */
export interface CsvImportParseAttributes {
    /**
     * CSV file
     * @type {File}
     * @memberof CsvImportParseAttributes
     */
    'file'?: File;
    /**
     * Name of the file
     * @type {string}
     * @memberof CsvImportParseAttributes
     */
    'bank_slug'?: CsvImportParseAttributesBankSlugEnum;
}

export const CsvImportParseAttributesBankSlugEnum = {
    PARQET: 'Parqet',
    SCALABLE_CAPITAL: 'ScalableCapital',
    EXTRA_ETF: 'extra_etf',
    PORTFOLIO_PERFORMANCE_SECURITIES: 'portfolio_performance_securities',
    PORTFOLIO_PERFORMANCE_CASH: 'portfolio_performance_cash',
    GENERIC: 'generic'
} as const;

export type CsvImportParseAttributesBankSlugEnum = typeof CsvImportParseAttributesBankSlugEnum[keyof typeof CsvImportParseAttributesBankSlugEnum];


