import { UseQueryOptions } from "react-query";
import { loadWatchlistEntry } from "@api/v1/methods";
import { WatchlistEntry } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { GET_WATCHLISTS_ENTRY } from "../../cacheKeys";

export const useGetWatchlistEntry = (
  watchlistEntryId: number,
  options?: UseQueryOptions<WatchlistEntry, AxiosApiError, WatchlistEntry>
) =>
  useAuthQuery(
    [GET_WATCHLISTS_ENTRY, watchlistEntryId],
    ({ token }) => loadWatchlistEntry(token, watchlistEntryId),
    options
  );
