import { UseMutationOptions } from "react-query";
import {
  ApplicationPropertiesApiUpdateApplicationPropertyRequest,
  ApplicationProperty,
} from "@generated/apiv1";

import { buildApplicationPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateApplicationProperty = async (
  token: string | undefined,
  params: ApplicationPropertiesApiUpdateApplicationPropertyRequest
) => {
  const apiInstance = buildApplicationPropertiesApi(token);
  const { data } = await apiInstance.updateApplicationProperty(params);
  return data;
};

export const useUpdateApplicationProperty = (
  options?: UseMutationOptions<
    ApplicationProperty,
    AxiosApiError,
    ApplicationPropertiesApiUpdateApplicationPropertyRequest
  >
) => {
  return useAuthMutation(updateApplicationProperty, options, false);
};
