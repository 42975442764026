import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, Theme, useMediaQuery } from "@mui/material";

const Container = styled(Box)<{ collapsed: number; matches: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0
    ${({ theme, collapsed, matches }) => {
      if (matches) return theme.spacing(3);
      return collapsed ? theme.spacing(2) : theme.spacing(4);
    }};

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export interface SidebarMenuProps {
  collapsed?: boolean;
  children: ReactNode;
  className?: string;
}

export const SidebarMenu = ({
  children,
  className,
  collapsed = false,
}: SidebarMenuProps) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { collapsed });
    }
    return child;
  });

  return (
    <Container
      className={className}
      collapsed={collapsed ? 1 : 0}
      matches={matches ? 1 : 0}
    >
      {childrenWithProps}
    </Container>
  );
};
