import { UseMutationOptions } from "react-query";
import { UsersApiDeleteUserByUsernameRequest } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteUserByUsername = async (
  token: string | undefined,
  params: UsersApiDeleteUserByUsernameRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.deleteUserByUsername(params);
  return data;
};

export const useDeleteUserByUsername = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UsersApiDeleteUserByUsernameRequest
  >
) => {
  return useAuthMutation(deleteUserByUsername, options, false);
};
