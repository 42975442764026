import { useTranslation } from "react-i18next";
import { InvestmentTypeEnum } from "@api";
import { toGermanNumberFormat, toCurrency, toPercent } from "@helpers";
import { ExtendedBookingType } from "@features/transactionWizard/modal/steps/constants";

export const useLabelHelpers = () => {
  const { t } = useTranslation();

  const nolDescription = (type?: InvestmentTypeEnum) => {
    if (type === "31_bond")
      return t("transactionWizardModal.priceAndDate.numberOfLotsPercent");
    if (type === "61_pmetals")
      return t("transactionWizardModal.priceAndDate.numberOfLotsOunces");
    return t("transactionWizardModal.priceAndDate.numberOfLots");
  };

  const nolSummaryDescription = (type?: InvestmentTypeEnum) => {
    if (type === "31_bond")
      return t("transactionWizardModal.priceAndDate.numberOfLotsPercent");
    if (type === "61_pmetals")
      return t("transactionWizardModal.priceAndDate.numberOfLotsOunces");
    return t("transactionWizardModal.priceAndDate.numberOfLots");
  };

  const entryQuoteDescription = (type?: ExtendedBookingType) => {
    if (type === "book_out")
      return t("transactionWizardModal.priceAndDate.bookOutPrice");
    if (type === "dividend" || type === "retained_dividend") {
      return t("transactionWizardModal.priceAndDate.dividend");
    }
    return t("transactionWizardModal.priceAndDate.entryQuote");
  };

  const nolLabel = (type?: InvestmentTypeEnum, currencySymbol?: string) => {
    if (type === "31_bond") return currencySymbol;
    if (type === "61_pmetals")
      return t("transactionWizardModal.priceAndDate.ounces");
    return t("transactionWizardModal.priceAndDate.lots");
  };

  const formatPrice = (
    currencySymbol: string | "%" | undefined,
    price?: number
  ) => {
    if (currencySymbol === "%") return toPercent((price ?? 0) / 100);

    if (!currencySymbol) return toGermanNumberFormat(price ?? 0);

    return toCurrency(price ?? 0, currencySymbol, {
      minimumFractionDigits: 2,
    });
  };

  const priceLabel = (type?: InvestmentTypeEnum, currencySymbol?: string) => {
    if (type === "31_bond") return "%";
    return currencySymbol;
  };

  return {
    nolLabel,
    nolDescription,
    nolSummaryDescription,
    formatPrice,
    priceLabel,
    entryQuoteDescription,
  };
};
