import { UseQueryOptions } from "react-query";
import {
  HistoricQuotesApiIntradayRequest,
  QuoteBarList,
} from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const intraday = async (
  token: string | undefined,
  params: HistoricQuotesApiIntradayRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.intraday(params);
  return data;
};

export const useIntraday = (
  params: HistoricQuotesApiIntradayRequest,
  options?: UseQueryOptions<QuoteBarList, AxiosApiError, QuoteBarList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.HISTORICQUOTES_INTRADAY, params],
    ({ token }) => intraday(token, params),
    options
  );
};
