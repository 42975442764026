import { useState, useEffect } from "react";
import { useSpring, SpringValue } from "react-spring";
import * as easing from "d3-ease";

const duration = 250;
const center = {
  opacity: 1,
  transform: "translateY(0)",
};
const bottom = {
  opacity: 0,
  transform: "translateY(60vh)",
};
const top = {
  opacity: 0,
  transform: "translateY(-60vh)",
};

interface AnimationProps {
  opacity: SpringValue<number>;
  transform: SpringValue<string>;
}

export const useAnimation = (
  animation = true,
  initialStep = 0
): [number, () => void, () => void, AnimationProps, boolean] => {
  const [stepNumber, setStepNumber] = useState(initialStep);
  const [focus, setFocus] = useState(false);

  const [animationProps, setSpring] = useSpring(() => ({
    to: animation ? bottom : center,
  }));

  const moveNext = async (next: any) => {
    if (!animation) {
      setStepNumber((current) => current + 1);
      setFocus(true);
      return;
    }

    await next({
      ...top,
      config: {
        duration,
        easing: easing.easePolyIn,
      },
    });
    setStepNumber((current) => current + 1);
    await next({
      ...bottom,
      reset: true,
    });
    await next({
      ...center,
      reset: false,
      config: {
        duration,
        easing: easing.easePolyOut,
      },
    });
    setFocus(true);
  };
  const movePrevious = async (next: any) => {
    if (!animation) {
      setStepNumber((current) => current - 1);
      setFocus(true);
      return;
    }

    await next({
      ...bottom,
      config: {
        duration,
        easing: easing.easePolyIn,
      },
    });
    setStepNumber((current) => current - 1);
    await next({
      ...top,
      reset: true,
    });
    await next({
      ...center,
      reset: false,
      config: {
        duration,
        easing: easing.easePolyOut,
      },
    });
    setFocus(true);
  };

  useEffect(() => {
    setFocus(false);
    if (!animation) return;
    setSpring.start({
      to: (async (next: any) => {
        await next(center);
        setFocus(true);
      }) as any,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  const handleNextStep = () => {
    setFocus(false);
    if (!animation) {
      return setStepNumber((current) => current + 1);
    }
    setSpring.start({
      to: moveNext as any,
    });
  };

  const handlePreviousStep = () => {
    setFocus(false);
    if (!animation) {
      return setStepNumber((current) => current - 1);
    }
    setSpring.start({ to: movePrevious as any });
  };

  return [
    stepNumber,
    handleNextStep,
    handlePreviousStep,
    animationProps,
    focus,
  ];
};
