import { UseQueryOptions } from "react-query";
import {
  ApplicationPropertiesApiGetApplicationPropertyRequest,
  ApplicationProperty,
} from "@generated/apiv1";

import { buildApplicationPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getApplicationProperty = async (
  token: string | undefined,
  params: ApplicationPropertiesApiGetApplicationPropertyRequest
) => {
  const apiInstance = buildApplicationPropertiesApi(token);
  const { data } = await apiInstance.getApplicationProperty(params);
  return data;
};

export const useGetApplicationProperty = (
  params: ApplicationPropertiesApiGetApplicationPropertyRequest,
  options?: UseQueryOptions<
    ApplicationProperty,
    AxiosApiError,
    ApplicationProperty
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.APPLICATIONPROPERTIES_GET_APPLICATION_PROPERTY, params],
    ({ token }) => getApplicationProperty(token, params),
    options
  );
};
