import { UseQueryOptions } from "react-query";
import {
  BookingsApiPublicPortfolioPositionsIdRequest,
  Booking,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicPortfolioPositionsId = async (
  token: string | undefined,
  params: BookingsApiPublicPortfolioPositionsIdRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.publicPortfolioPositionsId(params);
  return data;
};

export const usePublicPortfolioPositionsId = (
  params: BookingsApiPublicPortfolioPositionsIdRequest,
  options?: UseQueryOptions<Booking, AxiosApiError, Booking>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BOOKINGS_PUBLIC_PORTFOLIO_POSITIONS_ID, params],
    ({ token }) => publicPortfolioPositionsId(token, params),
    options
  );
};
