import React, { useMemo } from "react";
import styled from "@emotion/styled";
import {
  ChartType,
  HorizontalBarChart,
  HorizontalBarChartData,
} from "@components/Chart";

interface Props {
  name: string;
  type?: ChartType;
  data: HorizontalBarChartData[];
  blurLines?: number;
  barLabelWidth?: number;
}

const generateFakeData = (data: HorizontalBarChartData[], number: number) => {
  if (number === 0) return [];
  const values = data.map(({ value }) => value);
  const max = Math.max(...values);
  const min = Math.min(...values);
  return Array.from(Array(number)).map(() => ({
    isFake: true,
    category: `try pro ${Math.round(Math.random() * 100)}`,
    value: Math.round((Math.random() * (max - min) + min) * 10) / 10,
  }));
};

const Container = styled("div")<{ lines: number }>(({ theme, lines }) => {
  const cutPixels = 10 - lines;
  const height =
    cutPixels <= 0 ? `calc(100% - 40px)` : `calc(100% - ${cutPixels * 37}px)`;
  return {
    width: "100%",
    height,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  };
});

export const HorizontalBarChartWithFake = ({
  blurLines = 0,
  data,
  ...props
}: Props) => {
  const fakeData = useMemo(
    () => generateFakeData(data, blurLines),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length]
  );
  const allData = [...data, ...fakeData];

  return (
    <Container lines={allData.length}>
      <HorizontalBarChart {...props} data={allData} />
    </Container>
  );
};
