import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const updateSplitValuesAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "PREVIEW")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    splitValues: { ...context.splitValues, ...event.splitValues },
  };
};
