import { UseMutationOptions } from "react-query";
import {
  TppCertificatesApiCreateTppCertificateRequest,
  TppCertificate,
} from "@generated/apiv1";

import { buildTppCertificatesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createTppCertificate = async (
  token: string | undefined,
  params: TppCertificatesApiCreateTppCertificateRequest
) => {
  const apiInstance = buildTppCertificatesApi(token);
  const { data } = await apiInstance.createTppCertificate(params);
  return data;
};

export const useCreateTppCertificate = (
  options?: UseMutationOptions<
    TppCertificate,
    AxiosApiError,
    TppCertificatesApiCreateTppCertificateRequest
  >
) => {
  return useAuthMutation(createTppCertificate, options, false);
};
