import { UseQueryOptions } from "react-query";
import {
  QuotesApiGetQuotesRequest,
  QuotesResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getQuotes = async (
  token: string | undefined,
  params: QuotesApiGetQuotesRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getQuotes(params);
  return data;
};

export const useGetQuotes = (
  params: QuotesApiGetQuotesRequest,
  options?: UseQueryOptions<QuotesResponse, AxiosApiError, QuotesResponse>
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_QUOTES, params],
    ({ token }) => getQuotes(token, params),
    options
  );
};
