import { TransactionWizardContext } from "../types/transactionWizardContext";

export const clearBookingTypeAction = (context: TransactionWizardContext) => {
  return {
    bookingValues: {
      ...context.bookingValues,
      type: undefined,
    },
  };
};
