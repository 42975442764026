import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useStateHelpers } from "@features/dividendWizard/hooks/useStateHelpers";
import { LoadingPlaceHolder, Modal, SomethingWrongStep } from "@components";
import { AddManualDividendStep } from "@features/dividendWizard/steps/AddManualDividendStep";
import { DividendValues } from "@features/dividendWizard/stateMachine/types/dividendValues";
import { editDividendWizardSM } from "@features/dividendWizard/stateMachine/editDividendWizardSM";
import { intervalToTimeRange } from "@helpers";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { SingleAccountChart } from "@components/Chart/SingleAccountChart";
import { NoDataStub } from "@components/Chart/NoDataStub";
import {
  BOOKINGS,
  DIVIDENDS,
  GET_REALIZED_GAINS,
  TRANSACTIONS,
} from "@api/cacheKeys";
import { useInvestmentChartData } from "../../../hooks/chart/useInvestmentChartData";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  investmentId: number;
  bookingId: number;
  tickerSymbol?: string;
  invalidateBookings?: boolean;
};

export const EditDividendModal = ({
  isOpen,
  onClose,
  investmentId,
  bookingId,
  tickerSymbol,
  invalidateBookings,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [currentState, sendEvent] = useMachine(editDividendWizardSM, {
    context: {
      skipSummaryStep: true,
      bookingId,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { isLoadingStateForModals } = useStateHelpers(currentState);

  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );

  const { data: chartData = [], isLoading } = useInvestmentChartData({
    investmentId,
    currentInterval: selectedRange,
    startDate: range.startDate,
    endDate: range.endDate,
    tickerSymbol,
  });

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  useEffect(() => {
    sendEvent({ type: "INIT_FROM_INVESTMENT", investmentId });
  }, [investmentId, sendEvent]);

  const investmentName = currentState.context.investment?.name ?? "";
  // const handleBackStep = () => sendEvent({ type: "BACK" });
  const hasChartData = Boolean(chartData.length);

  useEffect(() => {
    if (currentState.matches("success")) {
      onClose();
      enqueueSnackbar(
        `${t("transactionWizard.persistedStep.title")}: ${investmentName}`,
        { autoHideDuration: 3000, variant: "success" }
      );
      queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS],
      });
      if (invalidateBookings) {
        queryClient.invalidateQueries({
          queryKey: [DIVIDENDS],
        });
        queryClient.invalidateQueries({
          queryKey: [BOOKINGS],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_REALIZED_GAINS],
        });
      }
    }
  }, [
    currentState,
    onClose,
    enqueueSnackbar,
    t,
    queryClient,
    investmentName,
    invalidateBookings,
  ]);

  const isSaving =
    currentState.matches("validateDividends") ||
    currentState.matches("persist");

  return (
    <Modal
      disablePortal={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t("singleAccount.editTransaction")}
    >
      {isLoadingStateForModals() && <LoadingPlaceHolder />}

      <Typography variant="body3" mb={2}>
        {investmentName}
      </Typography>

      {(currentState.matches("editManualDividend") ||
        isSaving ||
        currentState.matches("success")) && (
        <AddManualDividendStep
          onNext={(dividendValues: DividendValues) =>
            sendEvent({
              type: "DIVIDEND_FILLED",
              dividendValues,
            })
          }
          isLoading={isSaving}
          dividendValues={currentState.context.dividendValues}
          investmentName={currentState.context.investment?.name ?? ""}
          investmentCurrency={
            currentState.context.investment?.currency ?? "EUR"
          }
          validationErrors={currentState.context.validationErrors ?? []}
          chart={
            <>
              <Divider
                sx={{
                  margin: theme.spacing(8, 0),
                  borderColor: theme.palette.border.secondary,
                }}
              />
              <Box mb={5} width="100%">
                <RangeSelector
                  range={selectedRange === "custom" ? range : undefined}
                  intervalType={selectedRange}
                  onSelect={setSelectedRange}
                  onCustomSelect={onCustomRangeSelect}
                  width="100%"
                />
              </Box>

              {hasChartData ? (
                <SingleAccountChart
                  chartData={chartData}
                  isLoading={isLoading}
                  intervalType={selectedRange}
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <NoDataStub />
                </Box>
              )}
            </>
          }
          disableOpenPicker
          datePickerDesktopVariant
        />
      )}

      {/*{currentState.matches("summary") && (*/}
      {/*  <ConfirmManualDividendStep*/}
      {/*    dividendValues={currentState.context.dividendValues}*/}
      {/*    investmentCurrency={*/}
      {/*      currentState.context.investment?.currency ?? "EUR"*/}
      {/*    }*/}
      {/*    investmentName={currentState.context.investment?.name ?? ""}*/}
      {/*    onPrevious={handleBackStep}*/}
      {/*    onNext={() => sendEvent({ type: "PERSIST" })}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{currentState.matches("success") && (*/}
      {/*  <SuccessStep*/}
      {/*    investmentName={currentState.context.investment?.name || ""}*/}
      {/*    redirectUrl={`/investments/${investmentId}?tab=dividends`}*/}
      {/*  />*/}
      {/*)}*/}

      {currentState.matches("somethingWrong") && (
        <SomethingWrongStep message={currentState.context.error?.message} />
      )}
    </Modal>
  );
};
