import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useAppLayout } from "@providers/AppLayoutProvider";

const Container = styled(Box)<{
  selected: boolean;
}>`
  width: 100%;
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;

  ${({ selected, theme: { palette, appSidebar } }) =>
    selected
      ? css`
          color: ${appSidebar?.menuItem?.selectedColor};
          background-color: ${appSidebar?.menuItem?.selectedBackgroundColor};
          box-shadow: ${appSidebar?.menuItem?.selectedBoxShadow};
          outline: 1px solid
            ${appSidebar?.menuItem?.selectedBorderColor || "transparent"};

          & svg {
            color: ${appSidebar?.menuItem?.selectedColor};
          }
        `
      : css`
          color: ${appSidebar?.menuItem?.color};

          &:hover {
            color: ${appSidebar?.menuItem?.hoverColor};
            background-color: ${appSidebar?.menuItem?.hoverBackgroundColor};

            & svg {
              color: ${appSidebar?.menuItem?.hoverColor};
            }
          }
        `}
`;

export const ItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "collapsed",
})<{ collapsed: boolean }>`
  text-decoration: none;
  display: flex;
  padding: ${({ theme, collapsed }) =>
    `${theme.spacing(2, collapsed ? 2 : 3)}`};
  justify-items: end;
  align-items: center;
  cursor: pointer;
  color: inherit;
  height: 40px;
`;

export const MenuItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "subItem",
})<{
  subItem: boolean;
}>`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 3px;
  padding-left: ${({ theme, subItem }) => (subItem ? theme.spacing(4) : 0)};
  font-weight: ${({ theme }) => theme.appSidebar?.menuItem?.fontWeight || 500};
`;

interface Props {
  name: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  selected?: boolean;
  children?: ReactNode;
  to?: string;
  state?: Record<string, unknown>;
  subItem?: boolean;
}

export const MenuItem = ({
  name,
  selected = false,
  before,
  after,
  to,
  state,
  subItem = false,
}: Props) => {
  const navigate = useNavigate();
  const { sidebarCollapsed: collapsed, toggleSidebarCollapsed } =
    useAppLayout();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const handleClick = () => {
    if (!to) return;

    try {
      const url = new URL(to);
      window.location.assign(url);
      if (isXs) {
        toggleSidebarCollapsed();
      }
    } catch (err) {
      navigate(to, {
        state,
      });
      if (isXs) {
        toggleSidebarCollapsed();
      }
    }
  };

  return (
    <Box width="100%">
      <Container onClick={handleClick} selected={selected}>
        <ItemContainer width="100%" className="menuItem" collapsed={collapsed}>
          {before && (
            <Box
              display="flex"
              mr={collapsed ? 0 : 4}
              width={24}
              sx={{
                flexShrink: 0,
              }}
            >
              {before}
            </Box>
          )}
          {!collapsed && (
            <MenuItemText subItem={subItem} variant="body2">
              {name}
            </MenuItemText>
          )}
          {after && after}
        </ItemContainer>
      </Container>
    </Box>
  );
};
