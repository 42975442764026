/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AccountHolderReference } from './account-holder-reference';
// May contain unused imports in some cases
// @ts-ignore
import { UserReference } from './user-reference';

/**
 * 
 * @export
 * @interface CustomFeeAgreement
 */
export interface CustomFeeAgreement {
    /**
     * 
     * @type {UserReference}
     * @memberof CustomFeeAgreement
     */
    'creator'?: UserReference;
    /**
     * 
     * @type {AccountHolderReference}
     * @memberof CustomFeeAgreement
     */
    'accountHolderReference'?: AccountHolderReference;
    /**
     * The agreement type
     * @type {string}
     * @memberof CustomFeeAgreement
     */
    'type'?: CustomFeeAgreementTypeEnum;
    /**
     * The value (in percent); 1 = 100%
     * @type {number}
     * @memberof CustomFeeAgreement
     */
    'value'?: number;
    /**
     * Does the custom fee agreement have an upper bound. The rentablo fee will not raise         above the upper bound; required for creation
     * @type {boolean}
     * @memberof CustomFeeAgreement
     */
    'hasUpperBound': boolean;
    /**
     * The upper bound for the custom fee agreement; ignored if hasUpperBound is false.         If not given, the CASHBACK_100 fee will be considered as the upper bound
     * @type {number}
     * @memberof CustomFeeAgreement
     */
    'upperBound'?: number;
    /**
     * Start of the agreement
     * @type {string}
     * @memberof CustomFeeAgreement
     */
    'startsAt'?: string;
    /**
     * The creation or update timestamp
     * @type {string}
     * @memberof CustomFeeAgreement
     */
    'timestamp'?: string;
}

export const CustomFeeAgreementTypeEnum = {
    CASHBACK: 'cashback'
} as const;

export type CustomFeeAgreementTypeEnum = typeof CustomFeeAgreementTypeEnum[keyof typeof CustomFeeAgreementTypeEnum];


