import { UseQueryOptions } from "react-query";
import {
  TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest,
  TimeDependentPropertyList,
} from "@generated/apiv1";

import { buildTimeDependentPropertiesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllTimeDependentProperties = async (
  token: string | undefined,
  params?: TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest
) => {
  const apiInstance = buildTimeDependentPropertiesApi(token);
  const { data } = await apiInstance.listAndSearchAllTimeDependentProperties(
    params
  );
  return data;
};

export const useListAndSearchAllTimeDependentProperties = (
  params?: TimeDependentPropertiesApiListAndSearchAllTimeDependentPropertiesRequest,
  options?: UseQueryOptions<
    TimeDependentPropertyList,
    AxiosApiError,
    TimeDependentPropertyList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.TIMEDEPENDENTPROPERTIES_LIST_AND_SEARCH_ALL_TIME_DEPENDENT_PROPERTIES,
      params,
    ],
    ({ token }) => listAndSearchAllTimeDependentProperties(token, params),
    options
  );
};
