import { UseMutationOptions } from "react-query";

import { buildEtfTypeClassificationApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const triggerEtfTypeClassificationUpdate = async (
  token: string | undefined
) => {
  const apiInstance = buildEtfTypeClassificationApi(token);
  const { data } = await apiInstance.triggerEtfTypeClassificationUpdate();
  return data;
};

export const useTriggerEtfTypeClassificationUpdate = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(triggerEtfTypeClassificationUpdate, options, false);
};
