import { UseMutationOptions } from "react-query";
import { UsersApiSignupRequest } from "@generated/user";

import { buildUsersApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const signup = async (
  token: string | undefined,
  params: UsersApiSignupRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.signup(params);
  return data;
};

export const useSignup = (
  options?: UseMutationOptions<void, AxiosApiError, UsersApiSignupRequest>
) => {
  return useAuthMutation(signup, options, true);
};
