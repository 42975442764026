import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadingProgressBar } from "@components";
import { createAndRedirectToTppWebForm } from "@helpers/createTppWebForm";
import { MultibankingApiProvider } from "@api";

type Props = {};

export const TppWebFormRedirectPage = (props: Props) => {
  const [searchParams] = useSearchParams();
  const bankId = searchParams.get("bankId");
  const bankConnectionId = searchParams.get("bankConnectionId");
  const multibankingApiProvider = searchParams.get("multibankingApiProvider");

  useEffect(() => {
    createAndRedirectToTppWebForm(
      multibankingApiProvider as MultibankingApiProvider,
      bankId ?? undefined,
      bankConnectionId ? Number(bankConnectionId) : undefined
    );
  }, [bankConnectionId, bankId, multibankingApiProvider]);

  return <LoadingProgressBar />;
};
