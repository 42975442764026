import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, InputAdornment } from "@mui/material";
import { parseOrUndefined, toString } from "@helpers";
import { StepContainer } from "../../transactionWizard/StepContainer";

export interface Props {
  onNext: (taxAmount: number) => void;
  onPrevious: () => void;
}

export const TaxInputStep = (props: Props) => {
  const { onNext, onPrevious } = props;
  const { t } = useTranslation();
  const [customTaxAmount, setCustomTaxAmount] = useState<string>("");
  const [taxAmount, setTaxAmount] = useState<number>(0);

  const canContinue = taxAmount > 0 && taxAmount < 100;

  const handleTaxAmountChange = (value: string) => {
    const parsedTaxAmount = parseOrUndefined(value);
    setCustomTaxAmount(toString(parsedTaxAmount, value, 3));
    setTaxAmount(parsedTaxAmount ?? 0);
  };

  const handleNextClick = () => {
    if (!canContinue) return;
    // Note: Backend can get values between 0 and 1.
    onNext(Number.parseFloat((taxAmount / 100).toFixed(3)));
  };

  return (
    <StepContainer
      testid="tax-input-step"
      title={t("batchDividendsWizard.taxInputStep.title")}
      onNextDisabled={!canContinue}
      onNext={handleNextClick}
      onPrevious={onPrevious}
    >
      <TextField
        autoComplete="off"
        label={t("batchDividendsWizard.taxInputStep.customTaxLabel")}
        data-testid="tax-input-textfield"
        onChange={(event) => {
          handleTaxAmountChange(event.target.value);
        }}
        type="text"
        value={customTaxAmount}
        inputProps={{
          "data-testid": "custom-tax-input",
        }}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
    </StepContainer>
  );
};
