import { UseMutationOptions } from "react-query";
import {
  FinancialGoalsApiCreateFinancialGoalRequest,
  FinancialGoal,
} from "@generated/apiv1";

import { buildFinancialGoalsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createFinancialGoal = async (
  token: string | undefined,
  params: FinancialGoalsApiCreateFinancialGoalRequest
) => {
  const apiInstance = buildFinancialGoalsApi(token);
  const { data } = await apiInstance.createFinancialGoal(params);
  return data;
};

export const useCreateFinancialGoal = (
  options?: UseMutationOptions<
    FinancialGoal,
    AxiosApiError,
    FinancialGoalsApiCreateFinancialGoalRequest
  >
) => {
  return useAuthMutation(createFinancialGoal, options, false);
};
