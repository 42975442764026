import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiAuthenticateViaBankConnectionRequest,
  BankAuthenticationProcess,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const authenticateViaBankConnection = async (
  token: string | undefined,
  params: BankConnectionsApiAuthenticateViaBankConnectionRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.authenticateViaBankConnection(params);
  return data;
};

export const useAuthenticateViaBankConnection = (
  options?: UseMutationOptions<
    BankAuthenticationProcess,
    AxiosApiError,
    BankConnectionsApiAuthenticateViaBankConnectionRequest
  >
) => {
  return useAuthMutation(authenticateViaBankConnection, options, false);
};
