import { UseQueryOptions } from "react-query";
import { SplitsApiGetSplitsForDepotRequest, SplitList } from "@generated/apiv3";

import { buildSplitsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSplitsForDepot = async (
  token: string | undefined,
  params: SplitsApiGetSplitsForDepotRequest
) => {
  const apiInstance = buildSplitsApi(token);
  const { data } = await apiInstance.getSplitsForDepot(params);
  return data;
};

export const useGetSplitsForDepot = (
  params: SplitsApiGetSplitsForDepotRequest,
  options?: UseQueryOptions<SplitList, AxiosApiError, SplitList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SPLITS_GET_SPLITS_FOR_DEPOT, params],
    ({ token }) => getSplitsForDepot(token, params),
    options
  );
};
