import { UseQueryOptions } from "react-query";
import { SharingPreference } from "@generated/apiv1";

import { buildSharingPreferencesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showSharingPreferences = async (token: string | undefined) => {
  const apiInstance = buildSharingPreferencesApi(token);
  const { data } = await apiInstance.showSharingPreferences();
  return data;
};

export const useShowSharingPreferences = (
  options?: UseQueryOptions<SharingPreference, AxiosApiError, SharingPreference>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SHARINGPREFERENCES_SHOW_SHARING_PREFERENCES],
    ({ token }) => showSharingPreferences(token),
    options
  );
};
