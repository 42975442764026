/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OauthParams
 */
export interface OauthParams {
    /**
     * Determines the required type of authorization: password - authorize a user;                                  refresh_token - refresh a user\'s access_token.
     * @type {string}
     * @memberof OauthParams
     */
    'grant_type'?: OauthParamsGrantTypeEnum;
    /**
     * The mandator client id
     * @type {string}
     * @memberof OauthParams
     */
    'client_id'?: string;
    /**
     * The mandator client secret
     * @type {string}
     * @memberof OauthParams
     */
    'client_secret'?: string;
    /**
     * The refresh token to exchange for a new access token, for grant type \'refresh_token\' only
     * @type {string}
     * @memberof OauthParams
     */
    'refresh_token'?: string;
    /**
     * The username to login with; for grant type \'password\' only
     * @type {string}
     * @memberof OauthParams
     */
    'username'?: string;
    /**
     * The password to login with; for grant type \'password\' only
     * @type {string}
     * @memberof OauthParams
     */
    'password'?: string;
}

export const OauthParamsGrantTypeEnum = {
    PASSWORD: 'password',
    REFRESH_TOKEN: 'refresh_token'
} as const;

export type OauthParamsGrantTypeEnum = typeof OauthParamsGrantTypeEnum[keyof typeof OauthParamsGrantTypeEnum];


