import React from "react";
import { LinearProgress } from "@mui/material";
import styled from "@emotion/styled";

interface Props {
  position?: "relative" | "absolute" | "fixed";
  size?: "small" | "normal";
  "data-testid"?: string;
}

const LoadingSpinnerStyled = styled(LinearProgress)<{
  position?: string;
  size?: "small" | "normal";
}>`
  position: ${({ position }) => position || "fixed"};
  width: 100%;
  top: 0;
  left: 0;
  ${({ size = "normal" }) => (size === "small" ? `height: 1px;` : "")}
`;

export const LoadingProgressBar = (props: Props) => {
  return <LoadingSpinnerStyled data-testid="loading-spinner" {...props} />;
};
