import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendTimelineRequest,
  DividendHistoryTree,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendTimeline = async (
  token: string | undefined,
  params?: DividendsApiDividendTimelineRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendTimeline(params);
  return data;
};

export const useDividendTimeline = (
  params?: DividendsApiDividendTimelineRequest,
  options?: UseQueryOptions<
    DividendHistoryTree,
    AxiosApiError,
    DividendHistoryTree
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_TIMELINE, params],
    ({ token }) => dividendTimeline(token, params),
    options
  );
};
