/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A container for the new certificate data
 * @export
 * @interface CreateTppCertificateParams
 */
export interface CreateTppCertificateParams {
    /**
     * A type of certificate submitted
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'type': CreateTppCertificateParamsTypeEnum;
    /**
     * A certificate (public key)
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'publicKey': string;
    /**
     * A private key in PKCS #8 format. PKCS #8 private keys are typically                       exchanged in the PEM base64-encoded format (https://support.quovadisglobal.com/kb/a37/what-is-pem-format.aspx)                      </br></br>NOTE: The certificate should have one of the following headers:                      </br>- \'-----BEGIN PRIVATE KEY-----\'</br>- \'-----BEGIN ENCRYPTED PRIVATE KEY-----\'<br>                      Any other header denotes that the private key is NOT in PKCS #8 format!
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'privateKey': string;
    /**
     * Optional passphrase for the private key
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'passphrase'?: string;
    /**
     * Optional label to certificate to identify in the list of certificates
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'label': string;
    /**
     * Start day of the certificate\'s validity, in the format                       \'YYYY-MM-DD\'. Default is the passed certificate validFrom date
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'validFromDate'?: string;
    /**
     * Expiration day of the certificate\'s validity, in the format                       \'YYYY-MM-DD\'. Default is the passed certificate validUntil date
     * @type {string}
     * @memberof CreateTppCertificateParams
     */
    'validUntilDate'?: string;
}

export const CreateTppCertificateParamsTypeEnum = {
    QWAC: 'QWAC',
    QSEALC: 'QSEALC'
} as const;

export type CreateTppCertificateParamsTypeEnum = typeof CreateTppCertificateParamsTypeEnum[keyof typeof CreateTppCertificateParamsTypeEnum];


