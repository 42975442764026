import { UseMutationOptions } from "react-query";
import {
  BookingsApiCreateManagedDepotBookingRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createManagedDepotBooking = async (
  token: string | undefined,
  params: BookingsApiCreateManagedDepotBookingRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.createManagedDepotBooking(params);
  return data;
};

export const useCreateManagedDepotBooking = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiCreateManagedDepotBookingRequest
  >
) => {
  return useAuthMutation(createManagedDepotBooking, options, false);
};
