import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiInvestmentsIdRequest,
  Investment,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const investmentsId = async (
  token: string | undefined,
  params: InvestmentsApiInvestmentsIdRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.investmentsId(params);
  return data;
};

export const useInvestmentsId = (
  params: InvestmentsApiInvestmentsIdRequest,
  options?: UseQueryOptions<Investment, AxiosApiError, Investment>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_INVESTMENTS_ID, params],
    ({ token }) => investmentsId(token, params),
    options
  );
};
