import { DividendHistoryTreeNode, InvestmentReference } from "@generated/apiv1";

export function getDividends(
  yearItem?: DividendHistoryTreeNode,
  investmentReferences?: Record<string, InvestmentReference>
) {
  if (yearItem?.children) {
    const yearDividends = Object.values(yearItem.children).reduce<
      DividendHistoryTreeNode[]
    >((acc, childItem) => {
      const children = Object.values(childItem.children || {});
      return [...acc, ...children];
    }, []);

    const dividendsByInvestmentId = new Map<
      number,
      { name: string; amount: number }
    >();

    yearDividends.forEach((childItem) => {
      const [investmentId] = childItem.investmentIds;
      const investmentRef = dividendsByInvestmentId.get(investmentId);
      if (investmentRef) {
        dividendsByInvestmentId.set(investmentId, {
          amount: childItem.grossAmount + investmentRef?.amount,
          name: investmentRef.name,
        });
        return;
      }
      const name =
        investmentReferences?.[investmentId].standardisedName ||
        investmentReferences?.[investmentId].name ||
        "";
      dividendsByInvestmentId.set(investmentId, {
        amount: childItem.grossAmount,
        name,
      });
    });

    return Array.from(dividendsByInvestmentId.values()).map((item) => {
      const percent = (item.amount / yearItem.grossAmount) * 100;
      const formattedPercent = percent.toFixed(2);
      return {
        name: item.name,
        amount: item.amount,
        percent: formattedPercent,
      };
    });
  }
  return [];
}
