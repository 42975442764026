import React from "react";
import styled from "@emotion/styled";
import { LoginParams } from "@generated/apiv1";
import { LoginFormContainer } from "./LoginFormContainer";
import { LoginFormLinks } from "./LoginFormLinks";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export interface Props {
  onLogin?: () => void;
  login: (attrs: LoginParams & { rememberMe?: boolean }) => void;
  isLoading: boolean;
  tokenError?: Partial<ApiException> | null;
}

export const LoginFormWithLinks = ({ login, isLoading, tokenError }: Props) => {
  return (
    <LoginFormContainer
      onSubmit={login}
      error={tokenError}
      isLoading={isLoading}
    >
      <Container>
        <LoginFormLinks />
      </Container>
    </LoginFormContainer>
  );
};
