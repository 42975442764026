import { UseMutationOptions } from "react-query";
import { CategoriesApiCrateCategoryRequest, Category } from "@generated/apiv3";

import { buildCategoriesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const crateCategory = async (
  token: string | undefined,
  params: CategoriesApiCrateCategoryRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.crateCategory(params);
  return data;
};

export const useCrateCategory = (
  options?: UseMutationOptions<
    Category,
    AxiosApiError,
    CategoriesApiCrateCategoryRequest
  >
) => {
  return useAuthMutation(crateCategory, options, false);
};
