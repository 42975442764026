import { loadRealizedGains, useAuthQuery } from "@api";
import { GET_REALIZED_GAINS } from "@api/cacheKeys";

export const useRealizedGains = (
  investmentId?: number,
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) =>
  useAuthQuery(
    [GET_REALIZED_GAINS, investmentId!],
    ({ token }) => loadRealizedGains(token, investmentId!),
    {
      enabled: !!investmentId && enabled,
      refetchOnWindowFocus: false,
    }
  );
