import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setBookingAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "booking"> => {
  if (event.type !== "LOADED_BOOKING") {
    throw new Error(`Invalid event type ${event.type}`);
  }

  return {
    booking: event.booking,
  };
};
