import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  InvestmentTypeEnum,
  SymbolV2,
  Symbol,
  QuoteV2,
} from "@generated/apiv1";
import { SecuritiesSearch } from "@features/securities/SecuritiesSearch";
import { useGetQuotesV2 } from "@generated/apiv1/hooks";
import { Banner } from "@components";
import { ReactComponent as InfoIcon } from "@icons/info.svg";
import { StepContainer } from "../StepContainer";

export interface Props {
  handleNext: (symbol: Symbol | (SymbolV2 & { quote: QuoteV2 })) => void;
  handlePrevious?: () => void;
  handleManualInvestmentClick: () => void;
  type?: InvestmentTypeEnum;
  depotId?: number;
  defaultSearchValue?: string;
  isExchangeOrCarveOut?: boolean;
}

export const SearchSymbolStep = ({
  handlePrevious,
  handleNext,
  handleManualInvestmentClick,
  type,
  depotId,
  defaultSearchValue,
  isExchangeOrCarveOut,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [securityToAdd, setSecurityToAdd] = useState<SymbolV2>();

  const openManualWizard = () => {
    const params = [
      ["type", type],
      ["depotId", depotId !== undefined ? String(depotId) : undefined],
    ].filter(([_, value]) => Boolean(value)) as [string, string][];
    const string = new URLSearchParams(params).toString();

    navigate(`/manual-transaction-wizard${string.length ? `?${string}` : ""}`);
  };

  useGetQuotesV2(
    {
      securityId: [securityToAdd?.id!],
      quoteProvider: securityToAdd?.quoteProvider!,
    },
    {
      enabled: Boolean(securityToAdd),
      onSuccess: (data) => {
        const quote = data.quotes?.[0];
        if (quote) {
          handleNext({
            ...securityToAdd,
            quote: quote,
          });
        }
      },
    }
  );

  return (
    <StepContainer
      testid="search-symbol-step"
      title={t("transactionWizard.searchSymbolsStep.title")}
      onPrevious={handlePrevious}
    >
      <SecuritiesSearch
        onSymbolSelect={(symbol) => setSecurityToAdd(symbol)}
        defaultSearchFilter={type}
        defaultSearchValue={defaultSearchValue}
      />
      <Banner
        type="grey-info"
        mt={6}
        width="100%"
        icon={<InfoIcon />}
        text={
          <Box display="flex" gap={1}>
            <Typography variant="body2">
              {t("symbolSearch.manualInvestmentLabel")}
            </Typography>
            <Link
              data-testid="create-manual-investment"
              variant="body2"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={
                isExchangeOrCarveOut
                  ? handleManualInvestmentClick
                  : openManualWizard
              }
            >
              {isExchangeOrCarveOut
                ? t("symbolSearch.selectManualInvestment")
                : t("symbolSearch.manualInvestment")}
            </Link>
          </Box>
        }
      />
    </StepContainer>
  );
};
