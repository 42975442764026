/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateTppCertificateParams } from '../model';
// @ts-ignore
import { PageableTppCertificateList } from '../model';
// @ts-ignore
import { TppCertificate } from '../model';
/**
 * TppCertificatesApi - axios parameter creator
 * @export
 */
export const TppCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload a client certificate. QWAC certificate is used to verify your identity by the bank during                      the TLS handshake. QsealC certificate is used to sign the requests to the bank.
         * @param {CreateTppCertificateParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppCertificate: async (body: CreateTppCertificateParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTppCertificate', 'body', body)
            const localVarPath = `/v1/tppCertificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a tpp certificate
         * @param {number} id ID of the tpp certificate to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTppCertificate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTppCertificate', 'id', id)
            const localVarPath = `/v1/tppCertificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and search all TPP certificates
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppCertificates: async (page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/tppCertificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the certificate with the given ID
         * @param {number} id Get the tpp certificate with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppCertificatesId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tppCertificatesId', 'id', id)
            const localVarPath = `/v1/tppCertificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TppCertificatesApi - functional programming interface
 * @export
 */
export const TppCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TppCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload a client certificate. QWAC certificate is used to verify your identity by the bank during                      the TLS handshake. QsealC certificate is used to sign the requests to the bank.
         * @param {CreateTppCertificateParams} body The create parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTppCertificate(body: CreateTppCertificateParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTppCertificate(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCertificatesApi.createTppCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a tpp certificate
         * @param {number} id ID of the tpp certificate to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTppCertificate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTppCertificate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCertificatesApi.deleteTppCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and search all TPP certificates
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTppCertificates(page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableTppCertificateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTppCertificates(page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCertificatesApi.listAndSearchAllTppCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the certificate with the given ID
         * @param {number} id Get the tpp certificate with the given ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tppCertificatesId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TppCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tppCertificatesId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TppCertificatesApi.tppCertificatesId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TppCertificatesApi - factory interface
 * @export
 */
export const TppCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TppCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload a client certificate. QWAC certificate is used to verify your identity by the bank during                      the TLS handshake. QsealC certificate is used to sign the requests to the bank.
         * @param {TppCertificatesApiCreateTppCertificateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTppCertificate(requestParameters: TppCertificatesApiCreateTppCertificateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppCertificate> {
            return localVarFp.createTppCertificate(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a tpp certificate
         * @param {TppCertificatesApiDeleteTppCertificateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTppCertificate(requestParameters: TppCertificatesApiDeleteTppCertificateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTppCertificate(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and search all TPP certificates
         * @param {TppCertificatesApiListAndSearchAllTppCertificatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTppCertificates(requestParameters: TppCertificatesApiListAndSearchAllTppCertificatesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableTppCertificateList> {
            return localVarFp.listAndSearchAllTppCertificates(requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the certificate with the given ID
         * @param {TppCertificatesApiTppCertificatesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tppCertificatesId(requestParameters: TppCertificatesApiTppCertificatesIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<TppCertificate> {
            return localVarFp.tppCertificatesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTppCertificate operation in TppCertificatesApi.
 * @export
 * @interface TppCertificatesApiCreateTppCertificateRequest
 */
export interface TppCertificatesApiCreateTppCertificateRequest {
    /**
     * The create parameters
     * @type {CreateTppCertificateParams}
     * @memberof TppCertificatesApiCreateTppCertificate
     */
    readonly body: CreateTppCertificateParams
}

/**
 * Request parameters for deleteTppCertificate operation in TppCertificatesApi.
 * @export
 * @interface TppCertificatesApiDeleteTppCertificateRequest
 */
export interface TppCertificatesApiDeleteTppCertificateRequest {
    /**
     * ID of the tpp certificate to delete
     * @type {number}
     * @memberof TppCertificatesApiDeleteTppCertificate
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllTppCertificates operation in TppCertificatesApi.
 * @export
 * @interface TppCertificatesApiListAndSearchAllTppCertificatesRequest
 */
export interface TppCertificatesApiListAndSearchAllTppCertificatesRequest {
    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof TppCertificatesApiListAndSearchAllTppCertificates
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof TppCertificatesApiListAndSearchAllTppCertificates
     */
    readonly perPage?: number
}

/**
 * Request parameters for tppCertificatesId operation in TppCertificatesApi.
 * @export
 * @interface TppCertificatesApiTppCertificatesIdRequest
 */
export interface TppCertificatesApiTppCertificatesIdRequest {
    /**
     * Get the tpp certificate with the given ID
     * @type {number}
     * @memberof TppCertificatesApiTppCertificatesId
     */
    readonly id: number
}

/**
 * TppCertificatesApi - object-oriented interface
 * @export
 * @class TppCertificatesApi
 * @extends {BaseAPI}
 */
export class TppCertificatesApi extends BaseAPI {
    /**
     * 
     * @summary Upload a client certificate. QWAC certificate is used to verify your identity by the bank during                      the TLS handshake. QsealC certificate is used to sign the requests to the bank.
     * @param {TppCertificatesApiCreateTppCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCertificatesApi
     */
    public createTppCertificate(requestParameters: TppCertificatesApiCreateTppCertificateRequest, options?: RawAxiosRequestConfig) {
        return TppCertificatesApiFp(this.configuration).createTppCertificate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a tpp certificate
     * @param {TppCertificatesApiDeleteTppCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCertificatesApi
     */
    public deleteTppCertificate(requestParameters: TppCertificatesApiDeleteTppCertificateRequest, options?: RawAxiosRequestConfig) {
        return TppCertificatesApiFp(this.configuration).deleteTppCertificate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and search all TPP certificates
     * @param {TppCertificatesApiListAndSearchAllTppCertificatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCertificatesApi
     */
    public listAndSearchAllTppCertificates(requestParameters: TppCertificatesApiListAndSearchAllTppCertificatesRequest = {}, options?: RawAxiosRequestConfig) {
        return TppCertificatesApiFp(this.configuration).listAndSearchAllTppCertificates(requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the certificate with the given ID
     * @param {TppCertificatesApiTppCertificatesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TppCertificatesApi
     */
    public tppCertificatesId(requestParameters: TppCertificatesApiTppCertificatesIdRequest, options?: RawAxiosRequestConfig) {
        return TppCertificatesApiFp(this.configuration).tppCertificatesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

