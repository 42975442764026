/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DateValuePairList } from '../model';
// @ts-ignore
import { MostWantedStatisticsResult } from '../model';
/**
 * SocialStatisticsApi - axios parameter creator
 * @export
 */
export const SocialStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate montly buy/sell ratios. These ratios given the mumber of buys divided buy the number                      of sells. A high ratio means users are buying, a low ratio means users are selling.
         * @param {number} performanceFrom Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
         * @param {number} performanceTo Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
         * @param {Array<string>} [investmentType] Calculate only on the given investment types. Values must match Investment.TYPE.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buySellRatio: async (performanceFrom: number, performanceTo: number, investmentType?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'performanceFrom' is not null or undefined
            assertParamExists('buySellRatio', 'performanceFrom', performanceFrom)
            // verify required parameter 'performanceTo' is not null or undefined
            assertParamExists('buySellRatio', 'performanceTo', performanceTo)
            const localVarPath = `/v1/socialStatistics/buySellRatio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (performanceFrom !== undefined) {
                localVarQueryParameter['performanceFrom'] = performanceFrom;
            }

            if (performanceTo !== undefined) {
                localVarQueryParameter['performanceTo'] = performanceTo;
            }

            if (investmentType) {
                localVarQueryParameter['investmentType'] = investmentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate statistics on the most held and most traded security positions across all active users.
         * @param {number} performanceFrom Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
         * @param {number} performanceTo Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
         * @param {number} riskFrom Risk bin cutoff; only include users with a 12 months risk bin (0-2) above or equal the given                              threshold.
         * @param {number} riskTo Risk bin cutoff; only include users with a 12 months risk bin (0-2) below or equal the given                              threshold.
         * @param {Array<string>} [investmentType] Calculate only on the given investment types. Values must match Investment.TYPE.
         * @param {Array<string>} [cutoffDate] A date in format yyyy-MM-dd (US Format). Only consider buy / sell transactions after this                              date when calculating the buy/sell statistics. Default is 14 days ago.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mostWanted: async (performanceFrom: number, performanceTo: number, riskFrom: number, riskTo: number, investmentType?: Array<string>, cutoffDate?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'performanceFrom' is not null or undefined
            assertParamExists('mostWanted', 'performanceFrom', performanceFrom)
            // verify required parameter 'performanceTo' is not null or undefined
            assertParamExists('mostWanted', 'performanceTo', performanceTo)
            // verify required parameter 'riskFrom' is not null or undefined
            assertParamExists('mostWanted', 'riskFrom', riskFrom)
            // verify required parameter 'riskTo' is not null or undefined
            assertParamExists('mostWanted', 'riskTo', riskTo)
            const localVarPath = `/v1/socialStatistics/mostWanted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (performanceFrom !== undefined) {
                localVarQueryParameter['performanceFrom'] = performanceFrom;
            }

            if (performanceTo !== undefined) {
                localVarQueryParameter['performanceTo'] = performanceTo;
            }

            if (riskFrom !== undefined) {
                localVarQueryParameter['riskFrom'] = riskFrom;
            }

            if (riskTo !== undefined) {
                localVarQueryParameter['riskTo'] = riskTo;
            }

            if (investmentType) {
                localVarQueryParameter['investmentType'] = investmentType;
            }

            if (cutoffDate) {
                localVarQueryParameter['cutoffDate'] = cutoffDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialStatisticsApi - functional programming interface
 * @export
 */
export const SocialStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate montly buy/sell ratios. These ratios given the mumber of buys divided buy the number                      of sells. A high ratio means users are buying, a low ratio means users are selling.
         * @param {number} performanceFrom Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
         * @param {number} performanceTo Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
         * @param {Array<string>} [investmentType] Calculate only on the given investment types. Values must match Investment.TYPE.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buySellRatio(performanceFrom: number, performanceTo: number, investmentType?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateValuePairList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buySellRatio(performanceFrom, performanceTo, investmentType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialStatisticsApi.buySellRatio']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Calculate statistics on the most held and most traded security positions across all active users.
         * @param {number} performanceFrom Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
         * @param {number} performanceTo Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
         * @param {number} riskFrom Risk bin cutoff; only include users with a 12 months risk bin (0-2) above or equal the given                              threshold.
         * @param {number} riskTo Risk bin cutoff; only include users with a 12 months risk bin (0-2) below or equal the given                              threshold.
         * @param {Array<string>} [investmentType] Calculate only on the given investment types. Values must match Investment.TYPE.
         * @param {Array<string>} [cutoffDate] A date in format yyyy-MM-dd (US Format). Only consider buy / sell transactions after this                              date when calculating the buy/sell statistics. Default is 14 days ago.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mostWanted(performanceFrom: number, performanceTo: number, riskFrom: number, riskTo: number, investmentType?: Array<string>, cutoffDate?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MostWantedStatisticsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mostWanted(performanceFrom, performanceTo, riskFrom, riskTo, investmentType, cutoffDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialStatisticsApi.mostWanted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SocialStatisticsApi - factory interface
 * @export
 */
export const SocialStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate montly buy/sell ratios. These ratios given the mumber of buys divided buy the number                      of sells. A high ratio means users are buying, a low ratio means users are selling.
         * @param {SocialStatisticsApiBuySellRatioRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buySellRatio(requestParameters: SocialStatisticsApiBuySellRatioRequest, options?: RawAxiosRequestConfig): AxiosPromise<DateValuePairList> {
            return localVarFp.buySellRatio(requestParameters.performanceFrom, requestParameters.performanceTo, requestParameters.investmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate statistics on the most held and most traded security positions across all active users.
         * @param {SocialStatisticsApiMostWantedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mostWanted(requestParameters: SocialStatisticsApiMostWantedRequest, options?: RawAxiosRequestConfig): AxiosPromise<MostWantedStatisticsResult> {
            return localVarFp.mostWanted(requestParameters.performanceFrom, requestParameters.performanceTo, requestParameters.riskFrom, requestParameters.riskTo, requestParameters.investmentType, requestParameters.cutoffDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for buySellRatio operation in SocialStatisticsApi.
 * @export
 * @interface SocialStatisticsApiBuySellRatioRequest
 */
export interface SocialStatisticsApiBuySellRatioRequest {
    /**
     * Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiBuySellRatio
     */
    readonly performanceFrom: number

    /**
     * Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiBuySellRatio
     */
    readonly performanceTo: number

    /**
     * Calculate only on the given investment types. Values must match Investment.TYPE.
     * @type {Array<string>}
     * @memberof SocialStatisticsApiBuySellRatio
     */
    readonly investmentType?: Array<string>
}

/**
 * Request parameters for mostWanted operation in SocialStatisticsApi.
 * @export
 * @interface SocialStatisticsApiMostWantedRequest
 */
export interface SocialStatisticsApiMostWantedRequest {
    /**
     * Performance cutoff; only include users with a 12 months performance (TWROR) above the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly performanceFrom: number

    /**
     * Performance cutoff; only include users with a 12 months performance (TWROR) below the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly performanceTo: number

    /**
     * Risk bin cutoff; only include users with a 12 months risk bin (0-2) above or equal the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly riskFrom: number

    /**
     * Risk bin cutoff; only include users with a 12 months risk bin (0-2) below or equal the given                              threshold.
     * @type {number}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly riskTo: number

    /**
     * Calculate only on the given investment types. Values must match Investment.TYPE.
     * @type {Array<string>}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly investmentType?: Array<string>

    /**
     * A date in format yyyy-MM-dd (US Format). Only consider buy / sell transactions after this                              date when calculating the buy/sell statistics. Default is 14 days ago.
     * @type {Array<string>}
     * @memberof SocialStatisticsApiMostWanted
     */
    readonly cutoffDate?: Array<string>
}

/**
 * SocialStatisticsApi - object-oriented interface
 * @export
 * @class SocialStatisticsApi
 * @extends {BaseAPI}
 */
export class SocialStatisticsApi extends BaseAPI {
    /**
     * 
     * @summary Calculate montly buy/sell ratios. These ratios given the mumber of buys divided buy the number                      of sells. A high ratio means users are buying, a low ratio means users are selling.
     * @param {SocialStatisticsApiBuySellRatioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialStatisticsApi
     */
    public buySellRatio(requestParameters: SocialStatisticsApiBuySellRatioRequest, options?: RawAxiosRequestConfig) {
        return SocialStatisticsApiFp(this.configuration).buySellRatio(requestParameters.performanceFrom, requestParameters.performanceTo, requestParameters.investmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate statistics on the most held and most traded security positions across all active users.
     * @param {SocialStatisticsApiMostWantedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialStatisticsApi
     */
    public mostWanted(requestParameters: SocialStatisticsApiMostWantedRequest, options?: RawAxiosRequestConfig) {
        return SocialStatisticsApiFp(this.configuration).mostWanted(requestParameters.performanceFrom, requestParameters.performanceTo, requestParameters.riskFrom, requestParameters.riskTo, requestParameters.investmentType, requestParameters.cutoffDate, options).then((request) => request(this.axios, this.basePath));
    }
}

