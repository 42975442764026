import { UseQueryOptions } from "react-query";
import {
  PerformanceApiInvestmentPerformanceRequest,
  InvestmentPerformance,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const investmentPerformance = async (
  token: string | undefined,
  params: PerformanceApiInvestmentPerformanceRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.investmentPerformance(params);
  return data;
};

export const useInvestmentPerformance = (
  params: PerformanceApiInvestmentPerformanceRequest,
  options?: UseQueryOptions<
    InvestmentPerformance,
    AxiosApiError,
    InvestmentPerformance
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_INVESTMENT_PERFORMANCE, params],
    ({ token }) => investmentPerformance(token, params),
    options
  );
};
