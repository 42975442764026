import { Account } from "@api/v1/model";
import { useAuthQuery } from "@api/auth";
import { getAccount } from "@api/v1/methods";
import { ACCOUNT } from "../../cacheKeys";

export const useAccount = (accountId?: number) =>
  useAuthQuery<Account, AxiosApiError, Account>(
    [ACCOUNT, accountId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ({ token }) => getAccount(token, accountId!),
    {
      enabled: !!accountId,
    }
  );
