import { UseMutationOptions } from "react-query";
import {
  AccountHoldersApiUpdateCustomFeeAgreementRequest,
  AccountHolder,
} from "@generated/apiv1";

import { buildAccountHoldersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateCustomFeeAgreement = async (
  token: string | undefined,
  params: AccountHoldersApiUpdateCustomFeeAgreementRequest
) => {
  const apiInstance = buildAccountHoldersApi(token);
  const { data } = await apiInstance.updateCustomFeeAgreement(params);
  return data;
};

export const useUpdateCustomFeeAgreement = (
  options?: UseMutationOptions<
    AccountHolder,
    AxiosApiError,
    AccountHoldersApiUpdateCustomFeeAgreementRequest
  >
) => {
  return useAuthMutation(updateCustomFeeAgreement, options, false);
};
