import { UseQueryOptions } from "react-query";
import {
  CashFlowAnalyticsApiCashFlowAnalyticsRequest,
  IncomeAndSpendingHistory,
} from "@generated/apiv1";

import { buildCashFlowAnalyticsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const cashFlowAnalytics = async (
  token: string | undefined,
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest
) => {
  const apiInstance = buildCashFlowAnalyticsApi(token);
  const { data } = await apiInstance.cashFlowAnalytics(params);
  return data;
};

export const useCashFlowAnalytics = (
  params: CashFlowAnalyticsApiCashFlowAnalyticsRequest,
  options?: UseQueryOptions<
    IncomeAndSpendingHistory,
    AxiosApiError,
    IncomeAndSpendingHistory
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.CASHFLOWANALYTICS_CASH_FLOW_ANALYTICS, params],
    ({ token }) => cashFlowAnalytics(token, params),
    options
  );
};
