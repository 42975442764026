import { UseQueryOptions } from "react-query";
import {
  SubcategoriesApiGetSubcategoryRequest,
  Subcategory,
} from "@generated/apiv3";

import { buildSubcategoriesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSubcategory = async (
  token: string | undefined,
  params: SubcategoriesApiGetSubcategoryRequest
) => {
  const apiInstance = buildSubcategoriesApi(token);
  const { data } = await apiInstance.getSubcategory(params);
  return data;
};

export const useGetSubcategory = (
  params: SubcategoriesApiGetSubcategoryRequest,
  options?: UseQueryOptions<Subcategory, AxiosApiError, Subcategory>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBCATEGORIES_GET_SUBCATEGORY, params],
    ({ token }) => getSubcategory(token, params),
    options
  );
};
