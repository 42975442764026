import { UseMutationOptions } from "react-query";
import { UsersApiChangePasswordRequest } from "@generated/apiv1";

import { buildUsersApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const changePassword = async (
  token: string | undefined,
  params: UsersApiChangePasswordRequest
) => {
  const apiInstance = buildUsersApi(token);
  const { data } = await apiInstance.changePassword(params);
  return data;
};

export const useChangePassword = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UsersApiChangePasswordRequest
  >
) => {
  return useAuthMutation(changePassword, options, false);
};
