import { InvestmentTypeEnum } from "@api";
import {
  Investment,
  ListAndSearchAllAccountsTypeEnum,
  QuoteV2,
  SymbolV2,
} from "@generated/apiv1";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import {
  ExtendedBookingType,
  filterGroups,
} from "@features/transactionWizard/modal/steps/constants";
import { getQuotesV2, searchSymbolV2 } from "@generated/apiv1/hooks";
import { getSecurity } from "@generated/apiv3/quotes/hooks";
import { convertSecurityToSymbolV2 } from "@features/securities/security/convertSecurityToSymbolV2";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";
import { InvestmentValues } from "@features/transactionWizard/modal/stateMachine/types/investmentValues";

export const investmentTypeToAccountType = (
  type: InvestmentTypeEnum
): ListAndSearchAllAccountsTypeEnum =>
  ["61_pmetals", "71_massets"].includes(type)
    ? "05_material_assets"
    : "01_depot";

export const investmentValuesToAccountSubcategory = (
  investmentValues: InvestmentValues,
  isManualFlow: boolean
): AccountSubcategoryEnum => {
  if (investmentValues.type === "61_pmetals") {
    return AccountSubcategoryEnum.PRECIOUS_METALS;
  } else if (investmentValues.type === "71_massets" && !isManualFlow) {
    return AccountSubcategoryEnum.REAL_ESTATE;
  } else if (investmentValues.type === "71_massets") {
    return AccountSubcategoryEnum.OTHER_MATERIAL_ASSETS;
  } else if (investmentValues.quoteProvider === "crypto") {
    return AccountSubcategoryEnum.CRYPTO;
  } else {
    return AccountSubcategoryEnum.DEPOT;
  }
};

export const investmentTypeToFilterSubcategory = (
  investmentType: InvestmentTypeEnum,
  isManualFlow: boolean
): AccountSubcategoryEnum | undefined => {
  if (investmentType === "61_pmetals") {
    return AccountSubcategoryEnum.PRECIOUS_METALS;
  } else if (investmentType === "71_massets" && !isManualFlow) {
    return AccountSubcategoryEnum.REAL_ESTATE;
  } else {
    return undefined;
  }
};

export const accountSubcategoryToAccountType = (
  subcategory: AccountSubcategoryEnum
): ListAndSearchAllAccountsTypeEnum =>
  subcategory === AccountSubcategoryEnum.PRECIOUS_METALS ||
  subcategory === AccountSubcategoryEnum.REAL_ESTATE ||
  subcategory === AccountSubcategoryEnum.OTHER_MATERIAL_ASSETS
    ? "05_material_assets"
    : "01_depot";

export const isCapitalMeasure = (type: ExtendedBookingType) => {
  return ["carve_out", "switch", "fusion"].includes(type);
};

export const investmentToInvestmentValues = (
  investment?: Investment | null,
  depotId?: number
) => {
  if (!investment) return null;

  return {
    id: investment.id,
    name: investment.standardisedName,
    isin: investment.isin,
    wkn: investment.wkn,
    type: investment.type as InvestmentTypeEnum,
    tickerSymbol: investment.tickerSymbol,
    quoteProvider: investment.quoteProvider,
    quoteCurrency: investment.quote.currency,
    quoteLast: investment.quote.last,
    investmentCurrency: investment.currency,
    numberOfLots: investment.numberOfLots,
    depotId: depotId,
  };
};

export const investmentToSymbol = async (
  token: string | undefined,
  investment?: Investment | null
): Promise<(SymbolV2 & { quote?: QuoteV2 }) | null> => {
  if (!investment || investment.isin.includes("_NA_")) return null;

  let symbol;

  if (investment.quoteProvider === "crypto") {
    const { data } = await getSecurity(token, {
      id: investment.tickerSymbol,
      quoteProvider: investment.quoteProvider,
    });
    symbol = convertSecurityToSymbolV2(data?.id!, data?.attributes);
  } else {
    const data = await searchSymbolV2(token, {
      search: investment.isin,
    });
    symbol = data.symbols?.[0];
  }

  const quotes = symbol?.id
    ? await getQuotesV2(token, {
        securityId: [symbol.id],
        quoteProvider: investment.quoteProvider,
      })
    : null;

  // @ts-ignore
  return {
    ...symbol,
    quote: quotes?.quotes?.[0] ?? undefined,
  };
};

export const areNonListedAssetsSelected = (
  context: TransactionWizardContext
) => {
  return context.investmentValues.type === "71_massets";
};

export const getDisabledFilters = (context: TransactionWizardContext) => {
  if (!context.defaultDepotId) return [];

  if (
    context.depotSubCategory === AccountSubcategoryEnum.DEPOT ||
    !context.depotSubCategory
  ) {
    return ["metals", "materialAssets", "crypto"];
  }

  if (context.depotSubCategory === AccountSubcategoryEnum.REAL_ESTATE) {
    return Object.keys(filterGroups).filter(
      (group) => group !== "materialAssets"
    );
  }

  if (context.depotSubCategory === AccountSubcategoryEnum.CRYPTO) {
    return Object.keys(filterGroups).filter((group) => group !== "crypto");
  }

  if (context.depotSubCategory === AccountSubcategoryEnum.PRECIOUS_METALS) {
    return Object.keys(filterGroups).filter((group) => group !== "metals");
  }

  return [];
};
