import { UseMutationOptions } from "react-query";
import {
  BookingsApiCheckConsistencyStatusRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const checkConsistencyStatus = async (
  token: string | undefined,
  params: BookingsApiCheckConsistencyStatusRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.checkConsistencyStatus(params);
  return data;
};

export const useCheckConsistencyStatus = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiCheckConsistencyStatusRequest
  >
) => {
  return useAuthMutation(checkConsistencyStatus, options, false);
};
