/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Bank consent information
 * @export
 * @interface BankConsent
 */
export interface BankConsent {
    /**
     * Status of the consent. If <code>PRESENT</code>, it means that finAPI has a consent stored and can use it to connect to the bank. If <code>NOT_PRESENT</code>, finAPI will need to acquire a consent when connecting to the bank, which may require login credentials (either passed by the client, or stored in finAPI), and/or user involvement. Note that even when a consent is <code>PRESENT</code>, it may no longer be valid and finAPI will still have to acquire a new consent.
     * @type {string}
     * @memberof BankConsent
     */
    'status': BankConsentStatusEnum;
    /**
     * Expiration time of the consent in the format \'YYYY-MM-DD HH:MM:SS.SSS\' (german time).
     * @type {string}
     * @memberof BankConsent
     */
    'expiresAt': string;
}

export const BankConsentStatusEnum = {
    PRESENT: 'PRESENT',
    NOT_PRESENT: 'NOT_PRESENT'
} as const;

export type BankConsentStatusEnum = typeof BankConsentStatusEnum[keyof typeof BankConsentStatusEnum];


