import { useEffect, useState } from "react";
import { useThrottledCallback } from "use-debounce";

const calculateTickInterval = (
  chartContainer: HTMLElement | undefined,
  ticksLength: number,
  ticksWidth: number
) => {
  if (!chartContainer) return 12;

  const chartWidth =
    (
      chartContainer?.querySelector(".recharts-area") as HTMLElement
    )?.getBoundingClientRect().width + 40 || 0;

  const tickPadding = 10;

  const maxTicks = Math.floor(chartWidth / (ticksWidth + tickPadding));
  return Math.ceil(ticksLength / maxTicks);
};

export const useTickInterval = (
  chartContainer: HTMLElement | undefined,
  ticksAmount: number,
  ticksWidth: number
) => {
  const [tickInterval, setTickInterval] = useState(12);
  const onResize = useThrottledCallback(() => {
    setTickInterval(
      calculateTickInterval(chartContainer, ticksAmount, ticksWidth) || 12
    );
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticksAmount, ticksWidth]);

  useEffect(() => {
    if (chartContainer) {
      onResize();
    }
  }, [chartContainer, onResize]);

  return tickInterval;
};
