/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateBookingParams
 */
export interface CreateOrUpdateBookingParams {
    /**
     * Provide the ID if you perform a batch update or mix updates and creation of bookings
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'id'?: number;
    /**
     * If you define this hash, it will be listed in the validation errors response
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'hash'?: string;
    /**
     * The number of lots; may be floating point and must be greater then zero;                                required for creation
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'numberOfLots': number;
    /**
     * The UNSIGNED price per lot; for dividends and kupons this should be the total amount             (numberOfLots x dividend per lot). For retained dividends this field is ignored, as the             the security price is always zero. For book_out this field is used if given; or otherwise derived from             the existing booking history if null. In both cases (retained_dividend and book_out),             the entryQuote is used for performance calculations instead of the security price.
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'securityPrice': number;
    /**
     * For Book-Ins and retained Dividends: An UNSIGNED entry quote per lot. For other         booking types this value is ignored and the security price equals the entry quote.         As book-ins and retained dividends use the entry quote for performance calculation,         the entry quote should match the quote at the date of book-in. In addition, the security price         for book-ins should equal to the price that was originally payed for the booked-in security.         For retained dividends, the security price is always zero, as these shares were for free.
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'entryQuote'?: number;
    /**
     * Indicates that prices were estimated and that a price check should be performed by the                                user.
     * @type {boolean}
     * @memberof CreateOrUpdateBookingParams
     */
    'priceCheckRequired'?: boolean;
    /**
     * The booking\'s booking date (Buchungsdatum); required for creation
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'date': string;
    /**
     * The booking\'s value date (Wertstellungsdatum); optional. Will default to date if not given
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'valueDate'?: string;
    /**
     * Indicates that the date is an estimate and that a check should be performed by the                                user
     * @type {boolean}
     * @memberof CreateOrUpdateBookingParams
     */
    'dateIsEstimate'?: boolean;
    /**
     * The exchange rate; will be set to 1 if currency is EUR; required for creation
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'exchangeRate'?: number;
    /**
     * The booking type; required for creation
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'type': CreateOrUpdateBookingParamsTypeEnum;
    /**
     * This is a cancellation. If the cancelled transaction can be located, it will be             cancelled. Otherwise the transaction will be booked as its \'inverted\' value. E.g. a buy would become a             sell and otherwise.
     * @type {boolean}
     * @memberof CreateOrUpdateBookingParams
     */
    'isCancellation'?: boolean;
    /**
     * The payed commission; defaults to zero
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'commission'?: number;
    /**
     * The payed tax; defaults to zero
     * @type {number}
     * @memberof CreateOrUpdateBookingParams
     */
    'taxAmount'?: number;
    /**
     * For foreign currency investments. Allows to denote expenses in EUR; even if the investment                                denotes in another currency. Expenses will be automatically converted to the investment                                currency if this flag is true. Otherwise the endpoint assumes that expenses denote in                                investment currency. Defaults to false.
     * @type {boolean}
     * @memberof CreateOrUpdateBookingParams
     */
    'expensesInEur'?: boolean;
    /**
     * The finAPI ID (if present)
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'finApiId'?: string;
    /**
     * A reference ID, typically provided by the bank. The reference may be used to          reference according cash transactions, or other things as defined by the bank
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'reference'?: string;
    /**
     * The data source that created this booking. This value will be auto-assigned by             automated import routines. Default is not to assign a value.
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'dataSource'?: CreateOrUpdateBookingParamsDataSourceEnum;
    /**
     * A user comment. This is NOT the transaction purpose.
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'comment'?: string;
    /**
     * DEPRECATED - Visibility of the user comment; required for creation
     * @type {string}
     * @memberof CreateOrUpdateBookingParams
     */
    'commentVisibility'?: CreateOrUpdateBookingParamsCommentVisibilityEnum;
    /**
     * DEPRECATED - Should we notify friends via email? Default is false.
     * @type {boolean}
     * @memberof CreateOrUpdateBookingParams
     */
    'notifyFriends'?: boolean;
}

export const CreateOrUpdateBookingParamsTypeEnum = {
    BUY: 'buy',
    SELL: 'sell',
    BOOK_IN: 'book_in',
    BOOK_OUT: 'book_out',
    SWITCH: 'switch',
    DIVIDEND: 'dividend',
    RETAINED_DIVIDEND: 'retained_dividend',
    PURCHASE_RIGHT: 'purchase_right'
} as const;

export type CreateOrUpdateBookingParamsTypeEnum = typeof CreateOrUpdateBookingParamsTypeEnum[keyof typeof CreateOrUpdateBookingParamsTypeEnum];
export const CreateOrUpdateBookingParamsDataSourceEnum = {
    BANKING_API: 'BANKING_API',
    PDF: 'PDF',
    CSV: 'CSV',
    USER_INPUT: 'USER_INPUT'
} as const;

export type CreateOrUpdateBookingParamsDataSourceEnum = typeof CreateOrUpdateBookingParamsDataSourceEnum[keyof typeof CreateOrUpdateBookingParamsDataSourceEnum];
export const CreateOrUpdateBookingParamsCommentVisibilityEnum = {
    ANYBODY: 'anybody',
    FRIENDS: 'friends',
    NOBODY: 'nobody'
} as const;

export type CreateOrUpdateBookingParamsCommentVisibilityEnum = typeof CreateOrUpdateBookingParamsCommentVisibilityEnum[keyof typeof CreateOrUpdateBookingParamsCommentVisibilityEnum];


