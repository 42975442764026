import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiGetPrivateNotesRequest,
  Comment,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getPrivateNotes = async (
  token: string | undefined,
  params: InvestmentsApiGetPrivateNotesRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.getPrivateNotes(params);
  return data;
};

export const useGetPrivateNotes = (
  params: InvestmentsApiGetPrivateNotesRequest,
  options?: UseQueryOptions<Comment, AxiosApiError, Comment>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_GET_PRIVATE_NOTES, params],
    ({ token }) => getPrivateNotes(token, params),
    options
  );
};
