import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as BuildingColumns } from "@icons/building-columns-solid.svg";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { NoAccountsMiniCard, PageLayout } from "@components";
import { useAccountsByTypes } from "@features/depotsAccounts/useAccountsByTypes";
// import { ReactComponent as InfoIcon } from "@icons/myicons/info.svg";
import { DepotsAccountsTable } from "@features/depotsAccounts/DepotsAccountsTable";
import { accountTypes } from "@features/accounts/constants";
import {
  CategoryType,
  useSubcategories,
} from "@providers/SubcategoriesProvider";

const NoAccountsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-grow: 0.5;
`;

const Wrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ActionButton = () => {
  const { t } = useTranslation();
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const { subcategories, isLoading } = useSubcategories({
    categoryType: CategoryType.ACCOUNT,
  });

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => setMenuEl(null);

  if (isLoading) return null;

  const getAddPageLink = (key: string) => {
    const isAccount = key === "CASH_ACCOUNT";
    const isAddManually = [
      "REAL_ESTATE",
      "CRYPTO",
      "OTHER_MATERIAL_ASSETS",
    ].includes(key);

    if (isAddManually) {
      return `/depots/add-manually?type=${key}`;
    }

    return isAccount ? `/accounts/add?type=${key}` : `/depots/add?type=${key}`;
  };

  return (
    <>
      <Button
        data-testid="button-header-menu"
        variant="contained"
        color="secondary"
        startIcon={<PlusIcon />}
        onClick={handleOpenMenuClick}
      >
        {t("depotsAccounts.add")}
      </Button>
      <Menu
        open={Boolean(menuEl)}
        anchorEl={menuEl}
        keepMounted
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {subcategories?.map((subcategory) => (
          <MenuItem
            key={`investment-create-${subcategory.name}`}
            component={RouterLink}
            data-testid={`investment-create-${subcategory.name}`}
            to={getAddPageLink(subcategory.name)}
          >
            <Typography variant="inherit">
              {t(`depotsAccounts.subcategories.${subcategory.name}`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const DepotsAccountsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    accounts,
    groupedAccounts,
    isLoading: isAccountsLoading,
  } = useAccountsByTypes();

  const [showHidden, setSHowHidden] = useState(false);

  // Separate those to prevent manipulations with depots array in markup
  const hiddenAccounts = accounts?.filter((i) => i.isHidden);
  const hasHidden = (hiddenAccounts?.length ?? 0) > 0;
  const hasAccountsWithUpdateButton = accounts?.some(
    (account) =>
      Boolean(account.bankConnectionId) &&
      account.bankConnectionType !== "NETFONDS"
  );

  const navigateToAddPage = (key: string) => {
    const isAccount = key === "CASH_ACCOUNT";
    const isAddManually = [
      "REAL_ESTATE",
      "CRYPTO",
      "OTHER_MATERIAL_ASSETS",
    ].includes(key);

    if (isAddManually) {
      navigate(`/depots/add-manually?type=${key}`);
      return;
    }

    navigate(
      isAccount ? `/accounts/add?type=${key}` : `/depots/add?type=${key}`
    );
  };

  return (
    <PageLayout
      isMainPage
      title={t("depotsAccounts.title")}
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { text: t("depotsAccounts.title") },
      ]}
      actionButton={<ActionButton />}
    >
      {/* <Banner
        type="grey-info"
        icon={<InfoIcon width="28px" height="28px" />}
        text={
          <Typography variant="body1" fontWeight={400}>
            <Trans
              i18nKey="bankSelect.bannerText"
              components={{
                boldText: <b />,
                // TODO it will be returned after Trade Republic fix
                // linkC24: <Link onClick={() => handleBankSelect(C24Bank)} />,
                // linkFinanzen: (
                //   <Link onClick={() => handleBankSelect(FinanzenNetZero)} />
                // ),
              }}
            >
              {t("bankSelect.bannerText")}
            </Trans>
          </Typography>
        }
        mb={10}
        sx={{
          padding: `${theme.spacing(1.5, 5)} !important`,
          borderRadius: "8px !important",
        }}
      /> */}

      {hasHidden && (
        <Wrapper>
          <Switch
            value={showHidden}
            color="primary"
            data-testid="hidden-switch"
            onChange={() => setSHowHidden(!showHidden)}
            sx={{ marginRight: theme.spacing(3) }}
          />
          <span>
            {t("accounts.showHiddenItems")}: {hiddenAccounts?.length}
          </span>
        </Wrapper>
      )}
      <Box display="flex" flexDirection="column" gap={6}>
        {accountTypes.map(({ value: key }) => {
          const accounts = showHidden
            ? groupedAccounts[key]
            : groupedAccounts[key]?.filter(
                (i) =>
                  !i.isHidden &&
                  !groupedAccounts[key]?.find(
                    (acc) => acc.contraAccountId === i.id
                  )?.isHidden
              );
          const hasAccounts = accounts?.length > 0;

          if (!hasAccounts) return null;

          return (
            <Box key={key}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="h6">
                  {t(`depotsAccounts.subcategories.${key}_plural`)}
                </Typography>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => navigateToAddPage(key)}
                >
                  <PlusIcon />
                </IconButton>
              </Box>
              <Box sx={{ overflowX: "auto" }}>
                <DepotsAccountsTable
                  accounts={accounts}
                  subCategoryId={key}
                  hasAccountsWithUpdateButton={hasAccountsWithUpdateButton}
                />
              </Box>
            </Box>
          );
        })}
      </Box>

      {!isAccountsLoading && accounts && accounts?.length === 0 && (
        <NoAccountsContainer>
          <NoAccountsMiniCard
            onClick={() => {
              navigate("/import-bank-connection/select-bank");
            }}
            icon={<BuildingColumns />}
            title={t("depots.noAccountsTitle")}
            subtitle={t("depots.noAccountsSubtitle")}
            buttonText={t("depots.noAccountsButtonText")}
          />
        </NoAccountsContainer>
      )}
    </PageLayout>
  );
};
