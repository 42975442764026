import { UseMutationOptions } from "react-query";
import { SynchronizationBatchJob } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const bankConnectionsCashbackV2 = async (token: string | undefined) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.bankConnectionsCashbackV2();
  return data;
};

export const useBankConnectionsCashbackV2 = (
  options?: UseMutationOptions<SynchronizationBatchJob, AxiosApiError, unknown>
) => {
  return useAuthMutation(bankConnectionsCashbackV2, options, false);
};
