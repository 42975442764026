/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Data of notification rule
 * @export
 * @interface NotificationRule
 */
export interface NotificationRule {
    /**
     * Notification rule identifier
     * @type {number}
     * @memberof NotificationRule
     */
    'id': number;
    /**
     * Trigger event type
     * @type {string}
     * @memberof NotificationRule
     */
    'triggerEvent': NotificationRuleTriggerEventEnum;
    /**
     * Additional parameters that are specific to the trigger event type. Please refer to the documentation for details.
     * @type {{ [key: string]: string; }}
     * @memberof NotificationRule
     */
    'params'?: { [key: string]: string; };
    /**
     * The string that finAPI includes into the notifications that it sends based on this rule.
     * @type {string}
     * @memberof NotificationRule
     */
    'callbackHandle'?: string;
    /**
     * Whether the notification messages that will be sent based on this rule contain encrypted detailed data or not
     * @type {boolean}
     * @memberof NotificationRule
     */
    'includeDetails': boolean;
}

export const NotificationRuleTriggerEventEnum = {
    NEW_ACCOUNT_BALANCE: 'NEW_ACCOUNT_BALANCE',
    NEW_TRANSACTIONS: 'NEW_TRANSACTIONS',
    BANK_LOGIN_ERROR: 'BANK_LOGIN_ERROR',
    FOREIGN_MONEY_TRANSFER: 'FOREIGN_MONEY_TRANSFER',
    LOW_ACCOUNT_BALANCE: 'LOW_ACCOUNT_BALANCE',
    HIGH_TRANSACTION_AMOUNT: 'HIGH_TRANSACTION_AMOUNT',
    CATEGORY_CASH_FLOW: 'CATEGORY_CASH_FLOW',
    NEW_TERMS_AND_CONDITIONS: 'NEW_TERMS_AND_CONDITIONS'
} as const;

export type NotificationRuleTriggerEventEnum = typeof NotificationRuleTriggerEventEnum[keyof typeof NotificationRuleTriggerEventEnum];


