import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiConfirmChallengeResponseRequest,
  BankAuthenticationProcess,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const confirmChallengeResponse = async (
  token: string | undefined,
  params: BankConnectionsApiConfirmChallengeResponseRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.confirmChallengeResponse(params);
  return data;
};

export const useConfirmChallengeResponse = (
  options?: UseMutationOptions<
    BankAuthenticationProcess,
    AxiosApiError,
    BankConnectionsApiConfirmChallengeResponseRequest
  >
) => {
  return useAuthMutation(confirmChallengeResponse, options, false);
};
