import { UseQueryOptions } from "react-query";
import { CategoriesApiGetCategoryRequest, Category } from "@generated/apiv3";

import { buildCategoriesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getCategory = async (
  token: string | undefined,
  params: CategoriesApiGetCategoryRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.getCategory(params);
  return data;
};

export const useGetCategory = (
  params: CategoriesApiGetCategoryRequest,
  options?: UseQueryOptions<Category, AxiosApiError, Category>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIES_GET_CATEGORY, params],
    ({ token }) => getCategory(token, params),
    options
  );
};
