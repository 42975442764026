import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { Box, Typography, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { AccountsHeader, PageLayout, PrimaryActionWithMenu } from "@components";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { DepotsDashboard } from "@features/investments/DepotsDashboard";
import {
  useDeleteAccount,
  useUpdateAccount,
  useAccountsId,
} from "@generated/apiv1/hooks";
import { InvestmentsActionButton } from "@features/investments/components/InvestmentsActionButton";
import { DepotMenu } from "@features/depot/components/DepotMenu";
import { useGetSubcategories } from "@generated/apiv3/hooks";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";
import { BankAvatar } from "@components/Accounts/BankAvatar";

export const DepotPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { depotId } = useParams<"depotId">();
  const parsedDepotId = Number.parseInt(depotId!, 10);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const { data: account } = useAccountsId(
    { id: parsedDepotId },
    {
      enabled: Boolean(parsedDepotId) && !isNaN(parsedDepotId),
    }
  );
  const { mutateAsync, isLoading: isDeleteRequestLoading } = useDeleteAccount();
  const { mutateAsync: updateMutateAsync } = useUpdateAccount();
  const [deleteContraAccount, setDeleteContraAccount] = useState(false);

  const { data: subcategories } = useGetSubcategories(
    {
      filterCategoryType: "ACCOUNT_CATEGORIZATION",
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const contraAccountId = account?.contraAccountId;
  const { data: contraAccountData } = useAccountsId(
    { id: contraAccountId! },
    {
      enabled: Boolean(contraAccountId),
    }
  );

  const canDeleteContraAccount =
    contraAccountData?.depotIds && contraAccountData?.depotIds?.length === 1;
  const accountCategory = subcategories?.data?.find(
    // TODO: backend fix
    (subcategory) => subcategory.id === String(account?.subCategoryId)
  );

  const accountCategoryName = accountCategory
    ? accountCategory?.attributes.name
    : account?.type === "01_depot" &&
      account?.subCategoryId === undefined &&
      "DEPOT";
  useEffect(() => {
    if (canDeleteContraAccount) setDeleteContraAccount(true);
  }, [canDeleteContraAccount]);

  const handleOpenDeleteModal = () => {
    setDeleteModalShow(true);
  };
  const handleCloseDeleteModal = () => setDeleteModalShow(false);

  const handleDeleteAccount = async () => {
    await mutateAsync({ id: parsedDepotId, deleteContraAccount });
    navigate("/depots-accounts");
  };

  const handleHideAccount = async () => {
    await updateMutateAsync({
      id: parsedDepotId,
      body: {
        isHidden: !account!.isHidden,
        type: account!.type!,
        name: account!.name,
      },
    });
    navigate("/depots-accounts");
  };

  return (
    <PageLayout
      data-testid="single-depot-page"
      title={
        <AccountsHeader
          title={account?.name ?? ""}
          subtitle={account?.bankName ?? ""}
          icon={
            <BankAvatar
              bankLogoURL={account?.iconUrl}
              height={48}
              width={48}
              bankName={account?.name}
              accountType={accountCategoryName as AccountSubcategoryEnum}
            />
          }
        />
      }
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { to: "/depots-accounts", text: t("depotsAccounts.title") },
        { text: truncate(account?.name) },
      ]}
      actionButton={
        <PrimaryActionWithMenu
          primaryActionComponent={
            <InvestmentsActionButton
              accountId={account?.id}
              accountType={account?.type}
              accountCategory={accountCategoryName as AccountSubcategoryEnum}
            />
          }
        >
          <DepotMenu
            account={account!}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleHideAccount={handleHideAccount}
          />
        </PrimaryActionWithMenu>
      }
    >
      <ConfirmDeleteDialog
        isOpen={deleteModalShow}
        title={t("depotPage.deleteModal.question")}
        cancelButtonText={t("singleAccount.deleteModal.cancel")}
        confirmButtonText={t("singleAccount.deleteModal.confirm")}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteAccount}
        isDeleteRequestLoading={isDeleteRequestLoading}
      >
        <Box display="flex" alignItems="center" mt={2}>
          <Checkbox
            sx={{ paddingLeft: 0 }}
            onChange={() => setDeleteContraAccount((prevState) => !prevState)}
            checked={deleteContraAccount}
            disabled={!canDeleteContraAccount}
          />
          <Typography color={theme.palette.text.secondary}>
            {t("depotPage.deleteModal.deleteContraAccount")}
          </Typography>
        </Box>
      </ConfirmDeleteDialog>
      <DepotsDashboard accountIds={[parsedDepotId]} />
    </PageLayout>
  );
};
