import { UseQueryOptions } from "react-query";
import {
  WatchlistEntriesApiPreviewRequest,
  WatchlistEntry,
} from "@generated/apiv1";

import { buildWatchlistEntriesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const preview = async (
  token: string | undefined,
  params: WatchlistEntriesApiPreviewRequest
) => {
  const apiInstance = buildWatchlistEntriesApi(token);
  const { data } = await apiInstance.preview(params);
  return data;
};

export const usePreview = (
  params: WatchlistEntriesApiPreviewRequest,
  options?: UseQueryOptions<WatchlistEntry, AxiosApiError, WatchlistEntry>
) => {
  return useAuthQuery(
    [CACHE_KEYS.WATCHLISTENTRIES_PREVIEW, params],
    ({ token }) => preview(token, params),
    options
  );
};
