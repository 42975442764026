import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppLayout } from "@components";
import { SharedAppSidebar } from "@components/common/layouts/AppSidebar/SharedAppSidebar";
import { AppLayoutProvider } from "@providers/AppLayoutProvider";
import { ReactComponent as LockedPageIcon } from "./images/LockedScreen.svg";

export const PrivatePortfolioPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppLayoutProvider>
      <AppLayout sidebar={SharedAppSidebar}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100vh"
        >
          <Box textAlign="center">
            <LockedPageIcon />
            <Typography variant="body1" mt={8} mb={8}>
              {t("portfolioShare.privatePortfolio.noAccess")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/")}
            >
              {t("portfolioShare.privatePortfolio.close")}
            </Button>
          </Box>
        </Box>
      </AppLayout>
    </AppLayoutProvider>
  );
};
