import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useAccountIdsByTypes } from "@api/v1/hooks/useAccountIdsByTypes";
import { PageLayout } from "@components";

import { DepotsSelector } from "@features/selectDepots/components/DepotsSelector";
import { DepotsDashboard } from "@features/investments/DepotsDashboard";
import { HeaderButtons } from "@features/investments/components/HeaderButtons";
import { ReactComponent as ChevronDownIcon } from "@icons/chevron-down.svg";
import { AccountsApiListAndSearchAllAccountsRequest } from "@generated/apiv1";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { SharedBanner } from "@features/sharedPortfolio/SharedBanner";
import { useAccountsContext } from "@providers/AccountsProvider";
import { useSelectGroupsId } from "@generated/apiv1/hooks";
import { SelectGroupParams } from "@features/selectDepots/types";

const accountParams: AccountsApiListAndSearchAllAccountsRequest = {
  type: ["01_depot", "05_material_assets"],
};

const selectedGroupParams: SelectGroupParams = {
  id: "investmentList",
  fallbackAccountType: ["01_depot", "05_material_assets"],
};

export const InvestmentsPage = () => {
  const { t } = useTranslation();
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const {
    accounts,
    selectedAccounts: selectedAccountsFromLocalStorage,
    setSelectedAccounts,
  } = useAccountsContext();

  const { data: selectedGroup } = useSelectGroupsId(selectedGroupParams, {
    enabled: !isSharedPortfolio,
  });

  const selectedAccounts = accounts?.filter((account) =>
    selectedGroup?.accountIds?.includes(account.id)
  );

  const groupedAccountIds = useAccountIdsByTypes(selectedAccounts);
  const accountIds = isSharedPortfolio
    ? selectedAccountsFromLocalStorage
    : groupedAccountIds.depotIds;

  return (
    <PageLayout
      isMainPage
      title={
        <Box>
          <Typography variant="h4">{t("investments.title")}</Typography>
          <DepotsSelector
            accountParams={accountParams}
            selectedGroupParams={selectedGroupParams}
            variant="body2"
            color="text.secondary"
            textKeys={{
              modalTitle: "investments.depotsSelectorTitle",
              allDepots: "depotsSelector.allDepots",
              amountDepots: "depotsSelector.amountDepots",
              noDepots: "depotsSelector.noDepots",
            }}
            onSave={setSelectedAccounts}
            icon={
              <Box display="flex" ml={1}>
                <ChevronDownIcon width="16px" height="16px" />
              </Box>
            }
          />
        </Box>
      }
      actionButton={isSharedPortfolio ? undefined : <HeaderButtons />}
      breadcrumbs={[
        {
          to: isSharedPortfolio ? `/portfolios/${publicId}` : "/",
          text: t("breadcrumbs.home"),
        },
        { text: t("breadcrumbs.investments") },
      ]}
    >
      <SharedBanner mb={11} />
      <DepotsDashboard accountIds={accountIds ?? []} />
    </PageLayout>
  );
};
