/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateOrUpdateWatchlistEntryParams } from '../model';
// @ts-ignore
import { WatchlistEntry } from '../model';
// @ts-ignore
import { WatchlistEntryList } from '../model';
/**
 * WatchlistEntriesApi - axios parameter creator
 * @export
 */
export const WatchlistEntriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a watchlist entry.
         * @param {CreateOrUpdateWatchlistEntryParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWatchlistEntry: async (body: CreateOrUpdateWatchlistEntryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createWatchlistEntry', 'body', body)
            const localVarPath = `/v1/watchlistEntries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a watchlist entry.
         * @param {number} id ID of the watchlist entry.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchlistEntry: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWatchlistEntry', 'id', id)
            const localVarPath = `/v1/watchlistEntries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the changes to a watchlist entry.
         * @param {number} id ID of the watchlist entry to update
         * @param {CreateOrUpdateWatchlistEntryParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWatchlistEntry: async (id: number, body: CreateOrUpdateWatchlistEntryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editWatchlistEntry', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editWatchlistEntry', 'body', body)
            const localVarPath = `/v1/watchlistEntries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a watchlist entry.
         * @param {number} id Retrieve the given watchlist entry.
         * @param {GetWatchlistEntryQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchlistEntry: async (id: number, quoteQuality?: GetWatchlistEntryQuoteQualityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWatchlistEntry', 'id', id)
            const localVarPath = `/v1/watchlistEntries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the selected watchlist entries.
         * @param {Array<number>} [id] Retrieve the given watchlist entries.
         * @param {Array<number>} [watchlistId] Retrieve watchlist entries belonging to the given watchlists. This filter will be combined with all                              other given parameters.
         * @param {Array<string>} [isin] Retrieve watchlist entries matching the given ISINs.
         * @param {Array<string>} [tickerSymbol] Retrieve watchlist entries with the given ticker symbols.
         * @param {string} [name] Wildcard search - Retrieve watchlist entries matching the given name.
         * @param {ListAndSearchAllWatchlistEntriesQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllWatchlistEntries: async (id?: Array<number>, watchlistId?: Array<number>, isin?: Array<string>, tickerSymbol?: Array<string>, name?: string, quoteQuality?: ListAndSearchAllWatchlistEntriesQuoteQualityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/watchlistEntries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (watchlistId) {
                localVarQueryParameter['watchlistId'] = watchlistId;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Build a watchlist entry record for previewing.
         * @param {string} name The name of the security.
         * @param {string} isin The ISIN of the security.
         * @param {string} tickerSymbol The ticker symbol of the security; required for quote loockup.
         * @param {string} quoteProvider The quote provider of the security; required for quote lookup.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview: async (name: string, isin: string, tickerSymbol: string, quoteProvider: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('preview', 'name', name)
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('preview', 'isin', isin)
            // verify required parameter 'tickerSymbol' is not null or undefined
            assertParamExists('preview', 'tickerSymbol', tickerSymbol)
            // verify required parameter 'quoteProvider' is not null or undefined
            assertParamExists('preview', 'quoteProvider', quoteProvider)
            const localVarPath = `/v1/watchlistEntries/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }

            if (tickerSymbol !== undefined) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (quoteProvider !== undefined) {
                localVarQueryParameter['quoteProvider'] = quoteProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WatchlistEntriesApi - functional programming interface
 * @export
 */
export const WatchlistEntriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WatchlistEntriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a watchlist entry.
         * @param {CreateOrUpdateWatchlistEntryParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWatchlistEntry(body: CreateOrUpdateWatchlistEntryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWatchlistEntry(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.createWatchlistEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a watchlist entry.
         * @param {number} id ID of the watchlist entry.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWatchlistEntry(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWatchlistEntry(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.deleteWatchlistEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save the changes to a watchlist entry.
         * @param {number} id ID of the watchlist entry to update
         * @param {CreateOrUpdateWatchlistEntryParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editWatchlistEntry(id: number, body: CreateOrUpdateWatchlistEntryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editWatchlistEntry(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.editWatchlistEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a watchlist entry.
         * @param {number} id Retrieve the given watchlist entry.
         * @param {GetWatchlistEntryQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWatchlistEntry(id: number, quoteQuality?: GetWatchlistEntryQuoteQualityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWatchlistEntry(id, quoteQuality, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.getWatchlistEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the selected watchlist entries.
         * @param {Array<number>} [id] Retrieve the given watchlist entries.
         * @param {Array<number>} [watchlistId] Retrieve watchlist entries belonging to the given watchlists. This filter will be combined with all                              other given parameters.
         * @param {Array<string>} [isin] Retrieve watchlist entries matching the given ISINs.
         * @param {Array<string>} [tickerSymbol] Retrieve watchlist entries with the given ticker symbols.
         * @param {string} [name] Wildcard search - Retrieve watchlist entries matching the given name.
         * @param {ListAndSearchAllWatchlistEntriesQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllWatchlistEntries(id?: Array<number>, watchlistId?: Array<number>, isin?: Array<string>, tickerSymbol?: Array<string>, name?: string, quoteQuality?: ListAndSearchAllWatchlistEntriesQuoteQualityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistEntryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllWatchlistEntries(id, watchlistId, isin, tickerSymbol, name, quoteQuality, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.listAndSearchAllWatchlistEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Build a watchlist entry record for previewing.
         * @param {string} name The name of the security.
         * @param {string} isin The ISIN of the security.
         * @param {string} tickerSymbol The ticker symbol of the security; required for quote loockup.
         * @param {string} quoteProvider The quote provider of the security; required for quote lookup.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preview(name: string, isin: string, tickerSymbol: string, quoteProvider: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WatchlistEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preview(name, isin, tickerSymbol, quoteProvider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WatchlistEntriesApi.preview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WatchlistEntriesApi - factory interface
 * @export
 */
export const WatchlistEntriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WatchlistEntriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a watchlist entry.
         * @param {WatchlistEntriesApiCreateWatchlistEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWatchlistEntry(requestParameters: WatchlistEntriesApiCreateWatchlistEntryRequest, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistEntry> {
            return localVarFp.createWatchlistEntry(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a watchlist entry.
         * @param {WatchlistEntriesApiDeleteWatchlistEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchlistEntry(requestParameters: WatchlistEntriesApiDeleteWatchlistEntryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWatchlistEntry(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the changes to a watchlist entry.
         * @param {WatchlistEntriesApiEditWatchlistEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWatchlistEntry(requestParameters: WatchlistEntriesApiEditWatchlistEntryRequest, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistEntry> {
            return localVarFp.editWatchlistEntry(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a watchlist entry.
         * @param {WatchlistEntriesApiGetWatchlistEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchlistEntry(requestParameters: WatchlistEntriesApiGetWatchlistEntryRequest, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistEntry> {
            return localVarFp.getWatchlistEntry(requestParameters.id, requestParameters.quoteQuality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the selected watchlist entries.
         * @param {WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllWatchlistEntries(requestParameters: WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistEntryList> {
            return localVarFp.listAndSearchAllWatchlistEntries(requestParameters.id, requestParameters.watchlistId, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.name, requestParameters.quoteQuality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Build a watchlist entry record for previewing.
         * @param {WatchlistEntriesApiPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview(requestParameters: WatchlistEntriesApiPreviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<WatchlistEntry> {
            return localVarFp.preview(requestParameters.name, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.quoteProvider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWatchlistEntry operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiCreateWatchlistEntryRequest
 */
export interface WatchlistEntriesApiCreateWatchlistEntryRequest {
    /**
     * The create or update parameters
     * @type {CreateOrUpdateWatchlistEntryParams}
     * @memberof WatchlistEntriesApiCreateWatchlistEntry
     */
    readonly body: CreateOrUpdateWatchlistEntryParams
}

/**
 * Request parameters for deleteWatchlistEntry operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiDeleteWatchlistEntryRequest
 */
export interface WatchlistEntriesApiDeleteWatchlistEntryRequest {
    /**
     * ID of the watchlist entry.
     * @type {number}
     * @memberof WatchlistEntriesApiDeleteWatchlistEntry
     */
    readonly id: number
}

/**
 * Request parameters for editWatchlistEntry operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiEditWatchlistEntryRequest
 */
export interface WatchlistEntriesApiEditWatchlistEntryRequest {
    /**
     * ID of the watchlist entry to update
     * @type {number}
     * @memberof WatchlistEntriesApiEditWatchlistEntry
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateWatchlistEntryParams}
     * @memberof WatchlistEntriesApiEditWatchlistEntry
     */
    readonly body: CreateOrUpdateWatchlistEntryParams
}

/**
 * Request parameters for getWatchlistEntry operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiGetWatchlistEntryRequest
 */
export interface WatchlistEntriesApiGetWatchlistEntryRequest {
    /**
     * Retrieve the given watchlist entry.
     * @type {number}
     * @memberof WatchlistEntriesApiGetWatchlistEntry
     */
    readonly id: number

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof WatchlistEntriesApiGetWatchlistEntry
     */
    readonly quoteQuality?: GetWatchlistEntryQuoteQualityEnum
}

/**
 * Request parameters for listAndSearchAllWatchlistEntries operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest
 */
export interface WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest {
    /**
     * Retrieve the given watchlist entries.
     * @type {Array<number>}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly id?: Array<number>

    /**
     * Retrieve watchlist entries belonging to the given watchlists. This filter will be combined with all                              other given parameters.
     * @type {Array<number>}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly watchlistId?: Array<number>

    /**
     * Retrieve watchlist entries matching the given ISINs.
     * @type {Array<string>}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly isin?: Array<string>

    /**
     * Retrieve watchlist entries with the given ticker symbols.
     * @type {Array<string>}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Wildcard search - Retrieve watchlist entries matching the given name.
     * @type {string}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly name?: string

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof WatchlistEntriesApiListAndSearchAllWatchlistEntries
     */
    readonly quoteQuality?: ListAndSearchAllWatchlistEntriesQuoteQualityEnum
}

/**
 * Request parameters for preview operation in WatchlistEntriesApi.
 * @export
 * @interface WatchlistEntriesApiPreviewRequest
 */
export interface WatchlistEntriesApiPreviewRequest {
    /**
     * The name of the security.
     * @type {string}
     * @memberof WatchlistEntriesApiPreview
     */
    readonly name: string

    /**
     * The ISIN of the security.
     * @type {string}
     * @memberof WatchlistEntriesApiPreview
     */
    readonly isin: string

    /**
     * The ticker symbol of the security; required for quote loockup.
     * @type {string}
     * @memberof WatchlistEntriesApiPreview
     */
    readonly tickerSymbol: string

    /**
     * The quote provider of the security; required for quote lookup.
     * @type {string}
     * @memberof WatchlistEntriesApiPreview
     */
    readonly quoteProvider: string
}

/**
 * WatchlistEntriesApi - object-oriented interface
 * @export
 * @class WatchlistEntriesApi
 * @extends {BaseAPI}
 */
export class WatchlistEntriesApi extends BaseAPI {
    /**
     * 
     * @summary Create a watchlist entry.
     * @param {WatchlistEntriesApiCreateWatchlistEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public createWatchlistEntry(requestParameters: WatchlistEntriesApiCreateWatchlistEntryRequest, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).createWatchlistEntry(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a watchlist entry.
     * @param {WatchlistEntriesApiDeleteWatchlistEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public deleteWatchlistEntry(requestParameters: WatchlistEntriesApiDeleteWatchlistEntryRequest, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).deleteWatchlistEntry(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the changes to a watchlist entry.
     * @param {WatchlistEntriesApiEditWatchlistEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public editWatchlistEntry(requestParameters: WatchlistEntriesApiEditWatchlistEntryRequest, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).editWatchlistEntry(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a watchlist entry.
     * @param {WatchlistEntriesApiGetWatchlistEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public getWatchlistEntry(requestParameters: WatchlistEntriesApiGetWatchlistEntryRequest, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).getWatchlistEntry(requestParameters.id, requestParameters.quoteQuality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the selected watchlist entries.
     * @param {WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public listAndSearchAllWatchlistEntries(requestParameters: WatchlistEntriesApiListAndSearchAllWatchlistEntriesRequest = {}, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).listAndSearchAllWatchlistEntries(requestParameters.id, requestParameters.watchlistId, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.name, requestParameters.quoteQuality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Build a watchlist entry record for previewing.
     * @param {WatchlistEntriesApiPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchlistEntriesApi
     */
    public preview(requestParameters: WatchlistEntriesApiPreviewRequest, options?: RawAxiosRequestConfig) {
        return WatchlistEntriesApiFp(this.configuration).preview(requestParameters.name, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.quoteProvider, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetWatchlistEntryQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type GetWatchlistEntryQuoteQualityEnum = typeof GetWatchlistEntryQuoteQualityEnum[keyof typeof GetWatchlistEntryQuoteQualityEnum];
/**
 * @export
 */
export const ListAndSearchAllWatchlistEntriesQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type ListAndSearchAllWatchlistEntriesQuoteQualityEnum = typeof ListAndSearchAllWatchlistEntriesQuoteQualityEnum[keyof typeof ListAndSearchAllWatchlistEntriesQuoteQualityEnum];
