import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiGetBankConnectionsInBatchRequest,
  BankConnectionList,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankConnectionsInBatch = async (
  token: string | undefined,
  params: BankConnectionsApiGetBankConnectionsInBatchRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.getBankConnectionsInBatch(params);
  return data;
};

export const useGetBankConnectionsInBatch = (
  params: BankConnectionsApiGetBankConnectionsInBatchRequest,
  options?: UseQueryOptions<
    BankConnectionList,
    AxiosApiError,
    BankConnectionList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_GET_BANK_CONNECTIONS_IN_BATCH, params],
    ({ token }) => getBankConnectionsInBatch(token, params),
    options
  );
};
