import React, { useMemo } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useDividendsForCalendar } from "@features/dividendPlanner/CalendarTab/useDividendsForCalendar";
import { DividendsCalendarCell } from "@features/dividendPlanner/CalendarTab/DividendsCalendarCell";
import { SumPanel } from "@features/dividendPlanner/CalendarTab/SumPanel";
import { NoDataStub } from "@components";

const getEmptyCells = (
  daysAmount: number,
  mediaQueries: {
    isLessLg: boolean;
    isLessMd: boolean;
    isLessSm: boolean;
  },
  period: "month" | "year"
) => {
  if (!mediaQueries.isLessLg || mediaQueries.isLessSm || period === "year")
    return 0;
  if (mediaQueries.isLessMd) {
    return daysAmount % 2;
  }
  return daysAmount % 3 === 0 ? 0 : 3 - (daysAmount % 3);
};

type Props = {
  date: {
    month: number;
    year: number;
  };
  period: "month" | "year";
};

export const DividendsCalendar = ({ date, period }: Props) => {
  const theme = useTheme();
  const isLessLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isLessMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLessSm = useMediaQuery(theme.breakpoints.down("sm"));
  const data = useDividendsForCalendar({ date, period });
  const noData =
    isLessSm &&
    (!data.dividends ||
      data.dividends
        .filter((day) =>
          period === "month" ? day.date.getMonth() === date.month : true
        )
        .every((day) => !day.dividends.length));

  const actualDividends = useMemo(
    () =>
      period === "month" && isLessLg
        ? data.dividends.filter((day) => day.date.getMonth() === date.month)
        : data.dividends,
    [data, date, period, isLessLg]
  );

  return (
    <Box>
      <SumPanel
        amount={data.grossAmount || 0}
        hideAmount={data.dividends.some((dividend) =>
          dividend.dividends.some((div) => div.isPrediction)
        )}
      />
      <Grid container>
        {period === "month" &&
          !isLessLg &&
          data.dividends.slice(0, 7).map((day, index) => (
            <Grid
              item
              md={12 / 7}
              textAlign="center"
              p={4}
              key={`weekday-${index}`}
              sx={{
                borderTop: `1px solid ${theme.palette.border.secondary}`,
                backgroundColor: theme.palette.background.subtle,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {format(day.date, "EE").replace(".", "").toUpperCase()}
              </Typography>
            </Grid>
          ))}
        {noData ? (
          <Box display="flex" justifyContent="center" width="100%">
            <NoDataStub minHeight="350px" />
          </Box>
        ) : (
          <>
            {actualDividends?.map((day) => {
              return (
                <DividendsCalendarCell
                  key={format(day.date, "dd.MM.yyyy")}
                  dividends={day.dividends}
                  date={day.date}
                  period={period}
                />
              );
            })}
            {Array(
              getEmptyCells(
                actualDividends.length,
                {
                  isLessLg,
                  isLessMd,
                  isLessSm,
                },
                period
              )
            )
              .fill(undefined)
              .map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12 / 2}
                  md={12 / 3}
                  lg={12 / (period === "month" ? 7 : 4)}
                  key={`empty-cell-${index}`}
                  sx={{
                    height: isLessLg ? "auto" : "350px",
                    minHeight: "398px",
                    borderTop: `1px solid ${theme.palette.border.secondary}`,
                    borderLeft: `1px solid ${theme.palette.border.secondary}`,
                  }}
                />
              ))}
          </>
        )}
      </Grid>
    </Box>
  );
};
