import { useAuthQuery, loadStatistics } from "@api";

interface Props {
  isin?: string;
  from?: string;
  to?: string;
}

export const useStatistics = (
  { isin, from, to }: Props,
  { enabled = true }: { enabled?: boolean } = {}
) =>
  useAuthQuery(
    ["fundamental-statistics", isin!, from, to],
    ({ token }) => loadStatistics(token, isin!, from, to),
    {
      enabled: !!isin && enabled,
      refetchOnWindowFocus: false,
    }
  );
