/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BatchSingleInvestmentTwror } from '../model';
// @ts-ignore
import { Comment } from '../model';
// @ts-ignore
import { CreateOrUpdateCommentParams } from '../model';
// @ts-ignore
import { CreateOrUpdateInvestmentParams } from '../model';
// @ts-ignore
import { Investment } from '../model';
// @ts-ignore
import { InvestmentBatchTransferParamsList } from '../model';
// @ts-ignore
import { InvestmentTransferParams } from '../model';
// @ts-ignore
import { PageableInvestmentList } from '../model';
// @ts-ignore
import { RegionAndIndustryClassification } from '../model';
/**
 * InvestmentsApi - axios parameter creator
 * @export
 */
export const InvestmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the time-weighted performance for a set of investments. Please not that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {Array<number>} [id] Retrieve performance for investments with the given IDs
         * @param {Array<number>} [depotId] Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
         * @param {string} [from] A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
         * @param {string} [to] A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
         * @param {boolean} [inAccountCurrency] Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
         * @param {boolean} [includeExpenses] Should we include tax and commission in the calculation; default is true
         * @param {boolean} [includeDividends] Should we include dividends in the calculation; default is false
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchInvestmentPerformance: async (id?: Array<number>, depotId?: Array<number>, from?: string, to?: string, inAccountCurrency?: boolean, includeExpenses?: boolean, includeDividends?: boolean, aggregate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investments/performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (inAccountCurrency !== undefined) {
                localVarQueryParameter['inAccountCurrency'] = inAccountCurrency;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (includeDividends !== undefined) {
                localVarQueryParameter['includeDividends'] = includeDividends;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the time-weighted performance for a set of public investments. Please note that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [id] Retrieve performance for investments with the given IDs
         * @param {Array<number>} [depotId] Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
         * @param {Array<string>} [from] A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
         * @param {Array<string>} [to] A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
         * @param {boolean} [inAccountCurrency] Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
         * @param {boolean} [includeExpenses] Should we include tax and commission in the calculation; default is true
         * @param {boolean} [includeDividends] Should we include dividends in the calculation; default is false
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPublicInvestmentPerformance: async (publicId: string, id?: Array<number>, depotId?: Array<number>, from?: Array<string>, to?: Array<string>, inAccountCurrency?: boolean, includeExpenses?: boolean, includeDividends?: boolean, aggregate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('batchPublicInvestmentPerformance', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/investments/performance`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (from) {
                localVarQueryParameter['from'] = from;
            }

            if (to) {
                localVarQueryParameter['to'] = to;
            }

            if (inAccountCurrency !== undefined) {
                localVarQueryParameter['inAccountCurrency'] = inAccountCurrency;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (includeDividends !== undefined) {
                localVarQueryParameter['includeDividends'] = includeDividends;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an Investment
         * @param {number} id ID of the investment to delete. This will also delete all transaction assigned to this investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvestment', 'id', id)
            const localVarPath = `/v1/investments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the changes to an investment.
         * @param {number} id ID of the investment to update
         * @param {CreateOrUpdateInvestmentParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInvestment: async (id: number, body: CreateOrUpdateInvestmentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editInvestment', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editInvestment', 'body', body)
            const localVarPath = `/v1/investments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {GainersAndLosersModeEnum} mode The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts; use all investments if not provided
         * @param {number} [max] Limit the result to max occurrences, default is 3
         * @param {GainersAndLosersQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gainersAndLosers: async (mode: GainersAndLosersModeEnum, accountId?: Array<number>, max?: number, quoteQuality?: GainersAndLosersQuoteQualityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('gainersAndLosers', 'mode', mode)
            const localVarPath = `/v1/investments/gainersAndLosers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get private notes for the given an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateNotes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrivateNotes', 'id', id)
            const localVarPath = `/v1/investments/{id}/privateNotes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer the given investment from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investment will be booked-out and booked-in into the given account.
         * @param {number} id ID of the investment to transfer
         * @param {InvestmentTransferParams} body The transfer params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentIdTransfer: async (id: number, body: InvestmentTransferParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investmentIdTransfer', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('investmentIdTransfer', 'body', body)
            const localVarPath = `/v1/investments/{id}/transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer a set of investments from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investments will be booked-out and booked-in into the given account.
         * @param {InvestmentBatchTransferParamsList} body The batch transfer params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentTransfer: async (body: InvestmentBatchTransferParamsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('investmentTransfer', 'body', body)
            const localVarPath = `/v1/investments/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {InvestmentsIdQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {InvestmentsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentsId: async (id: number, quoteQuality?: InvestmentsIdQuoteQualityEnum, format?: InvestmentsIdFormatEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investmentsId', 'id', id)
            const localVarPath = `/v1/investments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {Array<number>} [id] Retrieve Investments with the given IDs
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts
         * @param {Array<string>} [wkn] Retrieve Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Investments with the given ISINs or WKNs.
         * @param {Array<string>} [tickerSymbol] Retrieve Investments with the given ticker symbols
         * @param {string} [name] Retrieve Investments matching the given name (like search)
         * @param {Array<string>} [currency] Retrieve Investments with the given currencies (3-letter ISO code)
         * @param {Array<number>} [industryId] Retrieve Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve Investments belonging to the given regions (exact match only)
         * @param {Array<number>} [subCategoryId] Retrieve investments having the given sub-category ID
         * @param {boolean} [includeHistoric] Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
         * @param {string} [positiveAmountOrLastTransactionAfter] Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
         * @param {ListAndSearchAllInvestmentsQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {ListAndSearchAllInvestmentsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please note that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInvestments: async (id?: Array<number>, accountId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, name?: string, currency?: Array<string>, industryId?: Array<number>, regionId?: Array<number>, subCategoryId?: Array<number>, includeHistoric?: boolean, positiveAmountOrLastTransactionAfter?: string, quoteQuality?: ListAndSearchAllInvestmentsQuoteQualityEnum, format?: ListAndSearchAllInvestmentsFormatEnum, page?: number, perPage?: number, order?: string, aggregate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (isinOrWkn) {
                localVarQueryParameter['isinOrWkn'] = isinOrWkn;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (subCategoryId) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            if (includeHistoric !== undefined) {
                localVarQueryParameter['includeHistoric'] = includeHistoric;
            }

            if (positiveAmountOrLastTransactionAfter !== undefined) {
                localVarQueryParameter['positiveAmountOrLastTransactionAfter'] = positiveAmountOrLastTransactionAfter;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} accountId Retrieve Investments for the given Accounts
         * @param {Array<string>} [wkn] Retrieve Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Investments with the given ISINs or WKNs.
         * @param {Array<string>} [tickerSymbol] Retrieve Investments with the given ticker symbols
         * @param {string} [name] Retrieve Investments matching the given name (like search)
         * @param {Array<string>} [currency] Retrieve Investments with the given currencies (3-letter ISO code)
         * @param {Array<number>} [industryId] Retrieve Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve Investments belonging to the given regions (exact match only)
         * @param {Array<number>} [subCategoryId] Retrieve investments having the given sub-category ID
         * @param {boolean} [includeHistoric] Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
         * @param {string} [positiveAmountOrLastTransactionAfter] Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
         * @param {ListAndSearchAllPublicInvestmentsQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {ListAndSearchAllPublicInvestmentsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please not that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllPublicInvestments: async (publicId: string, accountId: Array<number>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, name?: string, currency?: Array<string>, industryId?: Array<number>, regionId?: Array<number>, subCategoryId?: Array<number>, includeHistoric?: boolean, positiveAmountOrLastTransactionAfter?: string, quoteQuality?: ListAndSearchAllPublicInvestmentsQuoteQualityEnum, format?: ListAndSearchAllPublicInvestmentsFormatEnum, page?: number, perPage?: number, order?: string, aggregate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('listAndSearchAllPublicInvestments', 'publicId', publicId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('listAndSearchAllPublicInvestments', 'accountId', accountId)
            const localVarPath = `/v1/public/{publicId}/investments`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (isinOrWkn) {
                localVarQueryParameter['isinOrWkn'] = isinOrWkn;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (industryId) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (regionId) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (subCategoryId) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }

            if (includeHistoric !== undefined) {
                localVarQueryParameter['includeHistoric'] = includeHistoric;
            }

            if (positiveAmountOrLastTransactionAfter !== undefined) {
                localVarQueryParameter['positiveAmountOrLastTransactionAfter'] = positiveAmountOrLastTransactionAfter;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (aggregate !== undefined) {
                localVarQueryParameter['aggregate'] = aggregate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {string} publicId Public ID of the user
         * @param {PublicGainersAndLosersModeEnum} mode The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts; use all investments if not provided
         * @param {number} [max] Limit the result to max occurrences, default is 3
         * @param {PublicGainersAndLosersQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGainersAndLosers: async (publicId: string, mode: PublicGainersAndLosersModeEnum, accountId?: Array<number>, max?: number, quoteQuality?: PublicGainersAndLosersQuoteQualityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('publicGainersAndLosers', 'publicId', publicId)
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('publicGainersAndLosers', 'mode', mode)
            const localVarPath = `/v1/public/{publicId}/investments/gainersAndLosers`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the proposed region and industry classification (if any).
         * @param {string} isin Region and industry classification is based on the given ISIN.
         * @param {string} type Region and industry classification may depend on the investment type. The parameter must match                              the values defined in Investment.TYPE.
         * @param {string} [id] Provide the ID if the investments exists and a user may have changed the default                              classification. In this case the result will include the already taken classification if                              present, or the recommended classification if not assiged previously.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionAndIndustryClassification: async (isin: string, type: string, id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('regionAndIndustryClassification', 'isin', isin)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('regionAndIndustryClassification', 'type', type)
            const localVarPath = `/v1/investments/regionAndIndustryClassification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {string} publicId Public ID of the user
         * @param {ShowPublicInvestmentFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {ShowPublicInvestmentQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPublicInvestment: async (id: number, publicId: string, format?: ShowPublicInvestmentFormatEnum, quoteQuality?: ShowPublicInvestmentQuoteQualityEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showPublicInvestment', 'id', id)
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('showPublicInvestment', 'publicId', publicId)
            const localVarPath = `/v1/public/{publicId}/investments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (quoteQuality !== undefined) {
                localVarQueryParameter['quoteQuality'] = quoteQuality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update private notes (a user-defined private comment) for the given Investment
         * @param {number} id ID of the investment attach the private comment to
         * @param {CreateOrUpdateCommentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivateNotes: async (id: number, body: CreateOrUpdateCommentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrivateNotes', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePrivateNotes', 'body', body)
            const localVarPath = `/v1/investments/{id}/privateNotes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsApi - functional programming interface
 * @export
 */
export const InvestmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the time-weighted performance for a set of investments. Please not that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {Array<number>} [id] Retrieve performance for investments with the given IDs
         * @param {Array<number>} [depotId] Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
         * @param {string} [from] A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
         * @param {string} [to] A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
         * @param {boolean} [inAccountCurrency] Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
         * @param {boolean} [includeExpenses] Should we include tax and commission in the calculation; default is true
         * @param {boolean} [includeDividends] Should we include dividends in the calculation; default is false
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchInvestmentPerformance(id?: Array<number>, depotId?: Array<number>, from?: string, to?: string, inAccountCurrency?: boolean, includeExpenses?: boolean, includeDividends?: boolean, aggregate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchSingleInvestmentTwror>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchInvestmentPerformance(id, depotId, from, to, inAccountCurrency, includeExpenses, includeDividends, aggregate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.batchInvestmentPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the time-weighted performance for a set of public investments. Please note that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} [id] Retrieve performance for investments with the given IDs
         * @param {Array<number>} [depotId] Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
         * @param {Array<string>} [from] A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
         * @param {Array<string>} [to] A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
         * @param {boolean} [inAccountCurrency] Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
         * @param {boolean} [includeExpenses] Should we include tax and commission in the calculation; default is true
         * @param {boolean} [includeDividends] Should we include dividends in the calculation; default is false
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPublicInvestmentPerformance(publicId: string, id?: Array<number>, depotId?: Array<number>, from?: Array<string>, to?: Array<string>, inAccountCurrency?: boolean, includeExpenses?: boolean, includeDividends?: boolean, aggregate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchSingleInvestmentTwror>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPublicInvestmentPerformance(publicId, id, depotId, from, to, inAccountCurrency, includeExpenses, includeDividends, aggregate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.batchPublicInvestmentPerformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an Investment
         * @param {number} id ID of the investment to delete. This will also delete all transaction assigned to this investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.deleteInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save the changes to an investment.
         * @param {number} id ID of the investment to update
         * @param {CreateOrUpdateInvestmentParams} body The create or update parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editInvestment(id: number, body: CreateOrUpdateInvestmentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Investment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editInvestment(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.editInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {GainersAndLosersModeEnum} mode The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts; use all investments if not provided
         * @param {number} [max] Limit the result to max occurrences, default is 3
         * @param {GainersAndLosersQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gainersAndLosers(mode: GainersAndLosersModeEnum, accountId?: Array<number>, max?: number, quoteQuality?: GainersAndLosersQuoteQualityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInvestmentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gainersAndLosers(mode, accountId, max, quoteQuality, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.gainersAndLosers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get private notes for the given an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivateNotes(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivateNotes(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.getPrivateNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Transfer the given investment from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investment will be booked-out and booked-in into the given account.
         * @param {number} id ID of the investment to transfer
         * @param {InvestmentTransferParams} body The transfer params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investmentIdTransfer(id: number, body: InvestmentTransferParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Investment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investmentIdTransfer(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.investmentIdTransfer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Transfer a set of investments from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investments will be booked-out and booked-in into the given account.
         * @param {InvestmentBatchTransferParamsList} body The batch transfer params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investmentTransfer(body: InvestmentBatchTransferParamsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investmentTransfer(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.investmentTransfer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {InvestmentsIdQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {InvestmentsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investmentsId(id: number, quoteQuality?: InvestmentsIdQuoteQualityEnum, format?: InvestmentsIdFormatEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Investment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investmentsId(id, quoteQuality, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.investmentsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {Array<number>} [id] Retrieve Investments with the given IDs
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts
         * @param {Array<string>} [wkn] Retrieve Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Investments with the given ISINs or WKNs.
         * @param {Array<string>} [tickerSymbol] Retrieve Investments with the given ticker symbols
         * @param {string} [name] Retrieve Investments matching the given name (like search)
         * @param {Array<string>} [currency] Retrieve Investments with the given currencies (3-letter ISO code)
         * @param {Array<number>} [industryId] Retrieve Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve Investments belonging to the given regions (exact match only)
         * @param {Array<number>} [subCategoryId] Retrieve investments having the given sub-category ID
         * @param {boolean} [includeHistoric] Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
         * @param {string} [positiveAmountOrLastTransactionAfter] Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
         * @param {ListAndSearchAllInvestmentsQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {ListAndSearchAllInvestmentsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please note that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllInvestments(id?: Array<number>, accountId?: Array<number>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, name?: string, currency?: Array<string>, industryId?: Array<number>, regionId?: Array<number>, subCategoryId?: Array<number>, includeHistoric?: boolean, positiveAmountOrLastTransactionAfter?: string, quoteQuality?: ListAndSearchAllInvestmentsQuoteQualityEnum, format?: ListAndSearchAllInvestmentsFormatEnum, page?: number, perPage?: number, order?: string, aggregate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInvestmentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllInvestments(id, accountId, wkn, isin, isinOrWkn, tickerSymbol, name, currency, industryId, regionId, subCategoryId, includeHistoric, positiveAmountOrLastTransactionAfter, quoteQuality, format, page, perPage, order, aggregate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.listAndSearchAllInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {string} publicId Public ID of the user
         * @param {Array<number>} accountId Retrieve Investments for the given Accounts
         * @param {Array<string>} [wkn] Retrieve Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Investments with the given ISINs
         * @param {Array<string>} [isinOrWkn] Retrieve Investments with the given ISINs or WKNs.
         * @param {Array<string>} [tickerSymbol] Retrieve Investments with the given ticker symbols
         * @param {string} [name] Retrieve Investments matching the given name (like search)
         * @param {Array<string>} [currency] Retrieve Investments with the given currencies (3-letter ISO code)
         * @param {Array<number>} [industryId] Retrieve Investments belonging to the given industries (exact match only)
         * @param {Array<number>} [regionId] Retrieve Investments belonging to the given regions (exact match only)
         * @param {Array<number>} [subCategoryId] Retrieve investments having the given sub-category ID
         * @param {boolean} [includeHistoric] Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
         * @param {string} [positiveAmountOrLastTransactionAfter] Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
         * @param {ListAndSearchAllPublicInvestmentsQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {ListAndSearchAllPublicInvestmentsFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {boolean} [aggregate] Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please not that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllPublicInvestments(publicId: string, accountId: Array<number>, wkn?: Array<string>, isin?: Array<string>, isinOrWkn?: Array<string>, tickerSymbol?: Array<string>, name?: string, currency?: Array<string>, industryId?: Array<number>, regionId?: Array<number>, subCategoryId?: Array<number>, includeHistoric?: boolean, positiveAmountOrLastTransactionAfter?: string, quoteQuality?: ListAndSearchAllPublicInvestmentsQuoteQualityEnum, format?: ListAndSearchAllPublicInvestmentsFormatEnum, page?: number, perPage?: number, order?: string, aggregate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInvestmentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllPublicInvestments(publicId, accountId, wkn, isin, isinOrWkn, tickerSymbol, name, currency, industryId, regionId, subCategoryId, includeHistoric, positiveAmountOrLastTransactionAfter, quoteQuality, format, page, perPage, order, aggregate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.listAndSearchAllPublicInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {string} publicId Public ID of the user
         * @param {PublicGainersAndLosersModeEnum} mode The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
         * @param {Array<number>} [accountId] Retrieve Investments for the given Accounts; use all investments if not provided
         * @param {number} [max] Limit the result to max occurrences, default is 3
         * @param {PublicGainersAndLosersQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGainersAndLosers(publicId: string, mode: PublicGainersAndLosersModeEnum, accountId?: Array<number>, max?: number, quoteQuality?: PublicGainersAndLosersQuoteQualityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableInvestmentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGainersAndLosers(publicId, mode, accountId, max, quoteQuality, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.publicGainersAndLosers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve the proposed region and industry classification (if any).
         * @param {string} isin Region and industry classification is based on the given ISIN.
         * @param {string} type Region and industry classification may depend on the investment type. The parameter must match                              the values defined in Investment.TYPE.
         * @param {string} [id] Provide the ID if the investments exists and a user may have changed the default                              classification. In this case the result will include the already taken classification if                              present, or the recommended classification if not assiged previously.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionAndIndustryClassification(isin: string, type: string, id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionAndIndustryClassification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionAndIndustryClassification(isin, type, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.regionAndIndustryClassification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an Investment
         * @param {number} id ID of the investment to retrieve
         * @param {string} publicId Public ID of the user
         * @param {ShowPublicInvestmentFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {ShowPublicInvestmentQuoteQualityEnum} [quoteQuality] A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showPublicInvestment(id: number, publicId: string, format?: ShowPublicInvestmentFormatEnum, quoteQuality?: ShowPublicInvestmentQuoteQualityEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Investment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showPublicInvestment(id, publicId, format, quoteQuality, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.showPublicInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update private notes (a user-defined private comment) for the given Investment
         * @param {number} id ID of the investment attach the private comment to
         * @param {CreateOrUpdateCommentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrivateNotes(id: number, body: CreateOrUpdateCommentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrivateNotes(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentsApi.updatePrivateNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvestmentsApi - factory interface
 * @export
 */
export const InvestmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the time-weighted performance for a set of investments. Please not that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {InvestmentsApiBatchInvestmentPerformanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchInvestmentPerformance(requestParameters: InvestmentsApiBatchInvestmentPerformanceRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BatchSingleInvestmentTwror> {
            return localVarFp.batchInvestmentPerformance(requestParameters.id, requestParameters.depotId, requestParameters.from, requestParameters.to, requestParameters.inAccountCurrency, requestParameters.includeExpenses, requestParameters.includeDividends, requestParameters.aggregate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the time-weighted performance for a set of public investments. Please note that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
         * @param {InvestmentsApiBatchPublicInvestmentPerformanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPublicInvestmentPerformance(requestParameters: InvestmentsApiBatchPublicInvestmentPerformanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<BatchSingleInvestmentTwror> {
            return localVarFp.batchPublicInvestmentPerformance(requestParameters.publicId, requestParameters.id, requestParameters.depotId, requestParameters.from, requestParameters.to, requestParameters.inAccountCurrency, requestParameters.includeExpenses, requestParameters.includeDividends, requestParameters.aggregate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an Investment
         * @param {InvestmentsApiDeleteInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment(requestParameters: InvestmentsApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteInvestment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the changes to an investment.
         * @param {InvestmentsApiEditInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInvestment(requestParameters: InvestmentsApiEditInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Investment> {
            return localVarFp.editInvestment(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {InvestmentsApiGainersAndLosersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gainersAndLosers(requestParameters: InvestmentsApiGainersAndLosersRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableInvestmentList> {
            return localVarFp.gainersAndLosers(requestParameters.mode, requestParameters.accountId, requestParameters.max, requestParameters.quoteQuality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get private notes for the given an Investment
         * @param {InvestmentsApiGetPrivateNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateNotes(requestParameters: InvestmentsApiGetPrivateNotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.getPrivateNotes(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer the given investment from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investment will be booked-out and booked-in into the given account.
         * @param {InvestmentsApiInvestmentIdTransferRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentIdTransfer(requestParameters: InvestmentsApiInvestmentIdTransferRequest, options?: RawAxiosRequestConfig): AxiosPromise<Investment> {
            return localVarFp.investmentIdTransfer(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer a set of investments from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investments will be booked-out and booked-in into the given account.
         * @param {InvestmentsApiInvestmentTransferRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentTransfer(requestParameters: InvestmentsApiInvestmentTransferRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.investmentTransfer(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Investment
         * @param {InvestmentsApiInvestmentsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investmentsId(requestParameters: InvestmentsApiInvestmentsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Investment> {
            return localVarFp.investmentsId(requestParameters.id, requestParameters.quoteQuality, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {InvestmentsApiListAndSearchAllInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllInvestments(requestParameters: InvestmentsApiListAndSearchAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableInvestmentList> {
            return localVarFp.listAndSearchAllInvestments(requestParameters.id, requestParameters.accountId, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.name, requestParameters.currency, requestParameters.industryId, requestParameters.regionId, requestParameters.subCategoryId, requestParameters.includeHistoric, requestParameters.positiveAmountOrLastTransactionAfter, requestParameters.quoteQuality, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, requestParameters.aggregate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Investments
         * @param {InvestmentsApiListAndSearchAllPublicInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllPublicInvestments(requestParameters: InvestmentsApiListAndSearchAllPublicInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableInvestmentList> {
            return localVarFp.listAndSearchAllPublicInvestments(requestParameters.publicId, requestParameters.accountId, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.name, requestParameters.currency, requestParameters.industryId, requestParameters.regionId, requestParameters.subCategoryId, requestParameters.includeHistoric, requestParameters.positiveAmountOrLastTransactionAfter, requestParameters.quoteQuality, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, requestParameters.aggregate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get gainers or losers (by changePercentToday)
         * @param {InvestmentsApiPublicGainersAndLosersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGainersAndLosers(requestParameters: InvestmentsApiPublicGainersAndLosersRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableInvestmentList> {
            return localVarFp.publicGainersAndLosers(requestParameters.publicId, requestParameters.mode, requestParameters.accountId, requestParameters.max, requestParameters.quoteQuality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the proposed region and industry classification (if any).
         * @param {InvestmentsApiRegionAndIndustryClassificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionAndIndustryClassification(requestParameters: InvestmentsApiRegionAndIndustryClassificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<RegionAndIndustryClassification> {
            return localVarFp.regionAndIndustryClassification(requestParameters.isin, requestParameters.type, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Investment
         * @param {InvestmentsApiShowPublicInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPublicInvestment(requestParameters: InvestmentsApiShowPublicInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Investment> {
            return localVarFp.showPublicInvestment(requestParameters.id, requestParameters.publicId, requestParameters.format, requestParameters.quoteQuality, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update private notes (a user-defined private comment) for the given Investment
         * @param {InvestmentsApiUpdatePrivateNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivateNotes(requestParameters: InvestmentsApiUpdatePrivateNotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.updatePrivateNotes(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for batchInvestmentPerformance operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiBatchInvestmentPerformanceRequest
 */
export interface InvestmentsApiBatchInvestmentPerformanceRequest {
    /**
     * Retrieve performance for investments with the given IDs
     * @type {Array<number>}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly id?: Array<number>

    /**
     * Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
     * @type {Array<number>}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly depotId?: Array<number>

    /**
     * A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
     * @type {string}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly from?: string

    /**
     * A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
     * @type {string}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly to?: string

    /**
     * Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
     * @type {boolean}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly inAccountCurrency?: boolean

    /**
     * Should we include tax and commission in the calculation; default is true
     * @type {boolean}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly includeExpenses?: boolean

    /**
     * Should we include dividends in the calculation; default is false
     * @type {boolean}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly includeDividends?: boolean

    /**
     * Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
     * @type {boolean}
     * @memberof InvestmentsApiBatchInvestmentPerformance
     */
    readonly aggregate?: boolean
}

/**
 * Request parameters for batchPublicInvestmentPerformance operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiBatchPublicInvestmentPerformanceRequest
 */
export interface InvestmentsApiBatchPublicInvestmentPerformanceRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly publicId: string

    /**
     * Retrieve performance for investments with the given IDs
     * @type {Array<number>}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly id?: Array<number>

    /**
     * Retrieve performance of investments for the given depots; only active investments with a                              number of lots greater than zero will be considered. This parameter takes precedence                              over the id parameter if given.
     * @type {Array<number>}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly depotId?: Array<number>

    /**
     * A date in format yyyy-MM-dd. Start performance calculation at the given date.                              Default is 6 years ago.
     * @type {Array<string>}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly from?: Array<string>

    /**
     * A date in format yyyy-MM-dd. End performance calculation at the given date.                              Default is today.
     * @type {Array<string>}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly to?: Array<string>

    /**
     * Should we keep the investment currency or convert to account currency; default is                              true what will convert all responses to EUR
     * @type {boolean}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly inAccountCurrency?: boolean

    /**
     * Should we include tax and commission in the calculation; default is true
     * @type {boolean}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly includeExpenses?: boolean

    /**
     * Should we include dividends in the calculation; default is false
     * @type {boolean}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly includeDividends?: boolean

    /**
     * Should we aggregate \&#39;duplicate\&#39; securities to one security; default is false.
     * @type {boolean}
     * @memberof InvestmentsApiBatchPublicInvestmentPerformance
     */
    readonly aggregate?: boolean
}

/**
 * Request parameters for deleteInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiDeleteInvestmentRequest
 */
export interface InvestmentsApiDeleteInvestmentRequest {
    /**
     * ID of the investment to delete. This will also delete all transaction assigned to this investment.
     * @type {number}
     * @memberof InvestmentsApiDeleteInvestment
     */
    readonly id: number
}

/**
 * Request parameters for editInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiEditInvestmentRequest
 */
export interface InvestmentsApiEditInvestmentRequest {
    /**
     * ID of the investment to update
     * @type {number}
     * @memberof InvestmentsApiEditInvestment
     */
    readonly id: number

    /**
     * The create or update parameters
     * @type {CreateOrUpdateInvestmentParams}
     * @memberof InvestmentsApiEditInvestment
     */
    readonly body: CreateOrUpdateInvestmentParams
}

/**
 * Request parameters for gainersAndLosers operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiGainersAndLosersRequest
 */
export interface InvestmentsApiGainersAndLosersRequest {
    /**
     * The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
     * @type {'gainers' | 'losers'}
     * @memberof InvestmentsApiGainersAndLosers
     */
    readonly mode: GainersAndLosersModeEnum

    /**
     * Retrieve Investments for the given Accounts; use all investments if not provided
     * @type {Array<number>}
     * @memberof InvestmentsApiGainersAndLosers
     */
    readonly accountId?: Array<number>

    /**
     * Limit the result to max occurrences, default is 3
     * @type {number}
     * @memberof InvestmentsApiGainersAndLosers
     */
    readonly max?: number

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiGainersAndLosers
     */
    readonly quoteQuality?: GainersAndLosersQuoteQualityEnum
}

/**
 * Request parameters for getPrivateNotes operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiGetPrivateNotesRequest
 */
export interface InvestmentsApiGetPrivateNotesRequest {
    /**
     * ID of the investment to retrieve
     * @type {number}
     * @memberof InvestmentsApiGetPrivateNotes
     */
    readonly id: number
}

/**
 * Request parameters for investmentIdTransfer operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInvestmentIdTransferRequest
 */
export interface InvestmentsApiInvestmentIdTransferRequest {
    /**
     * ID of the investment to transfer
     * @type {number}
     * @memberof InvestmentsApiInvestmentIdTransfer
     */
    readonly id: number

    /**
     * The transfer params
     * @type {InvestmentTransferParams}
     * @memberof InvestmentsApiInvestmentIdTransfer
     */
    readonly body: InvestmentTransferParams
}

/**
 * Request parameters for investmentTransfer operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInvestmentTransferRequest
 */
export interface InvestmentsApiInvestmentTransferRequest {
    /**
     * The batch transfer params
     * @type {InvestmentBatchTransferParamsList}
     * @memberof InvestmentsApiInvestmentTransfer
     */
    readonly body: InvestmentBatchTransferParamsList
}

/**
 * Request parameters for investmentsId operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiInvestmentsIdRequest
 */
export interface InvestmentsApiInvestmentsIdRequest {
    /**
     * ID of the investment to retrieve
     * @type {number}
     * @memberof InvestmentsApiInvestmentsId
     */
    readonly id: number

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiInvestmentsId
     */
    readonly quoteQuality?: InvestmentsIdQuoteQualityEnum

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof InvestmentsApiInvestmentsId
     */
    readonly format?: InvestmentsIdFormatEnum
}

/**
 * Request parameters for listAndSearchAllInvestments operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiListAndSearchAllInvestmentsRequest
 */
export interface InvestmentsApiListAndSearchAllInvestmentsRequest {
    /**
     * Retrieve Investments with the given IDs
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly id?: Array<number>

    /**
     * Retrieve Investments for the given Accounts
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Investments with the given WKNs
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Investments with the given ISINs
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly isin?: Array<string>

    /**
     * Retrieve Investments with the given ISINs or WKNs.
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly isinOrWkn?: Array<string>

    /**
     * Retrieve Investments with the given ticker symbols
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Retrieve Investments matching the given name (like search)
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly name?: string

    /**
     * Retrieve Investments with the given currencies (3-letter ISO code)
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly currency?: Array<string>

    /**
     * Retrieve Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly industryId?: Array<number>

    /**
     * Retrieve Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly regionId?: Array<number>

    /**
     * Retrieve investments having the given sub-category ID
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly subCategoryId?: Array<number>

    /**
     * Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
     * @type {boolean}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly includeHistoric?: boolean

    /**
     * Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly positiveAmountOrLastTransactionAfter?: string

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly quoteQuality?: ListAndSearchAllInvestmentsQuoteQualityEnum

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly format?: ListAndSearchAllInvestmentsFormatEnum

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly order?: string

    /**
     * Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please note that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
     * @type {boolean}
     * @memberof InvestmentsApiListAndSearchAllInvestments
     */
    readonly aggregate?: boolean
}

/**
 * Request parameters for listAndSearchAllPublicInvestments operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiListAndSearchAllPublicInvestmentsRequest
 */
export interface InvestmentsApiListAndSearchAllPublicInvestmentsRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly publicId: string

    /**
     * Retrieve Investments for the given Accounts
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly accountId: Array<number>

    /**
     * Retrieve Investments with the given WKNs
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Investments with the given ISINs
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly isin?: Array<string>

    /**
     * Retrieve Investments with the given ISINs or WKNs.
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly isinOrWkn?: Array<string>

    /**
     * Retrieve Investments with the given ticker symbols
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Retrieve Investments matching the given name (like search)
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly name?: string

    /**
     * Retrieve Investments with the given currencies (3-letter ISO code)
     * @type {Array<string>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly currency?: Array<string>

    /**
     * Retrieve Investments belonging to the given industries (exact match only)
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly industryId?: Array<number>

    /**
     * Retrieve Investments belonging to the given regions (exact match only)
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly regionId?: Array<number>

    /**
     * Retrieve investments having the given sub-category ID
     * @type {Array<number>}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly subCategoryId?: Array<number>

    /**
     * Include historic Investments (those which were sold and are now not part of the depot)                              if true. Default values if false.
     * @type {boolean}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly includeHistoric?: boolean

    /**
     * Retrieve Investments having a positive number of lots (they are still in our depot) OR                              investments that were entirely sold after the given date; date format is yyyy-MM-dd (US Format)                              This is useful to cover use cases like \&#39;I would like to add a dividend to an investment                              I sold one month ago.
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly positiveAmountOrLastTransactionAfter?: string

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly quoteQuality?: ListAndSearchAllPublicInvestmentsQuoteQualityEnum

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly format?: ListAndSearchAllPublicInvestmentsFormatEnum

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly order?: string

    /**
     * Should we aggregate \&#39;duplicate\&#39; investments to one investment; default is false.                                A duplicate investment is an investment with the same ISIN, WKN or ticker.                                 Please not that investments with different currency are not considered identical.                                 E.g. Microsoft in EUR and Microsoft in USD would not be aggregated
     * @type {boolean}
     * @memberof InvestmentsApiListAndSearchAllPublicInvestments
     */
    readonly aggregate?: boolean
}

/**
 * Request parameters for publicGainersAndLosers operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiPublicGainersAndLosersRequest
 */
export interface InvestmentsApiPublicGainersAndLosersRequest {
    /**
     * Public ID of the user
     * @type {string}
     * @memberof InvestmentsApiPublicGainersAndLosers
     */
    readonly publicId: string

    /**
     * The mode to use, either \&#39;gainers\&#39; or \&#39;losers\&#39;
     * @type {'gainers' | 'losers'}
     * @memberof InvestmentsApiPublicGainersAndLosers
     */
    readonly mode: PublicGainersAndLosersModeEnum

    /**
     * Retrieve Investments for the given Accounts; use all investments if not provided
     * @type {Array<number>}
     * @memberof InvestmentsApiPublicGainersAndLosers
     */
    readonly accountId?: Array<number>

    /**
     * Limit the result to max occurrences, default is 3
     * @type {number}
     * @memberof InvestmentsApiPublicGainersAndLosers
     */
    readonly max?: number

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiPublicGainersAndLosers
     */
    readonly quoteQuality?: PublicGainersAndLosersQuoteQualityEnum
}

/**
 * Request parameters for regionAndIndustryClassification operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiRegionAndIndustryClassificationRequest
 */
export interface InvestmentsApiRegionAndIndustryClassificationRequest {
    /**
     * Region and industry classification is based on the given ISIN.
     * @type {string}
     * @memberof InvestmentsApiRegionAndIndustryClassification
     */
    readonly isin: string

    /**
     * Region and industry classification may depend on the investment type. The parameter must match                              the values defined in Investment.TYPE.
     * @type {string}
     * @memberof InvestmentsApiRegionAndIndustryClassification
     */
    readonly type: string

    /**
     * Provide the ID if the investments exists and a user may have changed the default                              classification. In this case the result will include the already taken classification if                              present, or the recommended classification if not assiged previously.
     * @type {string}
     * @memberof InvestmentsApiRegionAndIndustryClassification
     */
    readonly id?: string
}

/**
 * Request parameters for showPublicInvestment operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiShowPublicInvestmentRequest
 */
export interface InvestmentsApiShowPublicInvestmentRequest {
    /**
     * ID of the investment to retrieve
     * @type {number}
     * @memberof InvestmentsApiShowPublicInvestment
     */
    readonly id: number

    /**
     * Public ID of the user
     * @type {string}
     * @memberof InvestmentsApiShowPublicInvestment
     */
    readonly publicId: string

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof InvestmentsApiShowPublicInvestment
     */
    readonly format?: ShowPublicInvestmentFormatEnum

    /**
     * A quality setting for quote data. Better quality may lead to slower response times.                         Default is PERFORMANCE. The available option are:                         PERFORMANCE: use a short-time caching layer for optimal response times                         OPTIMAL: request real-time quotes across available stock exchanges and choose the most recent                            quote. This requires a lookup against the quote data provider for each request and might                            does lead to slower response times; depending on your quote provider performance. This                            setting is currently only available for quote provider ovfs.
     * @type {'PERFORMANCE' | 'OPTIMAL'}
     * @memberof InvestmentsApiShowPublicInvestment
     */
    readonly quoteQuality?: ShowPublicInvestmentQuoteQualityEnum
}

/**
 * Request parameters for updatePrivateNotes operation in InvestmentsApi.
 * @export
 * @interface InvestmentsApiUpdatePrivateNotesRequest
 */
export interface InvestmentsApiUpdatePrivateNotesRequest {
    /**
     * ID of the investment attach the private comment to
     * @type {number}
     * @memberof InvestmentsApiUpdatePrivateNotes
     */
    readonly id: number

    /**
     * The comment details
     * @type {CreateOrUpdateCommentParams}
     * @memberof InvestmentsApiUpdatePrivateNotes
     */
    readonly body: CreateOrUpdateCommentParams
}

/**
 * InvestmentsApi - object-oriented interface
 * @export
 * @class InvestmentsApi
 * @extends {BaseAPI}
 */
export class InvestmentsApi extends BaseAPI {
    /**
     * 
     * @summary Get the time-weighted performance for a set of investments. Please not that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
     * @param {InvestmentsApiBatchInvestmentPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public batchInvestmentPerformance(requestParameters: InvestmentsApiBatchInvestmentPerformanceRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).batchInvestmentPerformance(requestParameters.id, requestParameters.depotId, requestParameters.from, requestParameters.to, requestParameters.inAccountCurrency, requestParameters.includeExpenses, requestParameters.includeDividends, requestParameters.aggregate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the time-weighted performance for a set of public investments. Please note that this method is                      optimized for fast batch use cases and thus does approximate the performance calculation by                      simply using \'currentValue / previousValue + cashFlow\'. Due to cash-flow adjustment, it may                      produce slightly different results than a performance calculation that considers every single day.
     * @param {InvestmentsApiBatchPublicInvestmentPerformanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public batchPublicInvestmentPerformance(requestParameters: InvestmentsApiBatchPublicInvestmentPerformanceRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).batchPublicInvestmentPerformance(requestParameters.publicId, requestParameters.id, requestParameters.depotId, requestParameters.from, requestParameters.to, requestParameters.inAccountCurrency, requestParameters.includeExpenses, requestParameters.includeDividends, requestParameters.aggregate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an Investment
     * @param {InvestmentsApiDeleteInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public deleteInvestment(requestParameters: InvestmentsApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).deleteInvestment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the changes to an investment.
     * @param {InvestmentsApiEditInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public editInvestment(requestParameters: InvestmentsApiEditInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).editInvestment(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get gainers or losers (by changePercentToday)
     * @param {InvestmentsApiGainersAndLosersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public gainersAndLosers(requestParameters: InvestmentsApiGainersAndLosersRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).gainersAndLosers(requestParameters.mode, requestParameters.accountId, requestParameters.max, requestParameters.quoteQuality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get private notes for the given an Investment
     * @param {InvestmentsApiGetPrivateNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public getPrivateNotes(requestParameters: InvestmentsApiGetPrivateNotesRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).getPrivateNotes(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer the given investment from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investment will be booked-out and booked-in into the given account.
     * @param {InvestmentsApiInvestmentIdTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public investmentIdTransfer(requestParameters: InvestmentsApiInvestmentIdTransferRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).investmentIdTransfer(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer a set of investments from its original depot to the given one. This typically mirrors the business                 case \'depot transfer\' (Depotwechsel) in which the customer moves his assets from one bank to another.                 The investments will be booked-out and booked-in into the given account.
     * @param {InvestmentsApiInvestmentTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public investmentTransfer(requestParameters: InvestmentsApiInvestmentTransferRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).investmentTransfer(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Investment
     * @param {InvestmentsApiInvestmentsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public investmentsId(requestParameters: InvestmentsApiInvestmentsIdRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).investmentsId(requestParameters.id, requestParameters.quoteQuality, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Investments
     * @param {InvestmentsApiListAndSearchAllInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public listAndSearchAllInvestments(requestParameters: InvestmentsApiListAndSearchAllInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).listAndSearchAllInvestments(requestParameters.id, requestParameters.accountId, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.name, requestParameters.currency, requestParameters.industryId, requestParameters.regionId, requestParameters.subCategoryId, requestParameters.includeHistoric, requestParameters.positiveAmountOrLastTransactionAfter, requestParameters.quoteQuality, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, requestParameters.aggregate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Investments
     * @param {InvestmentsApiListAndSearchAllPublicInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public listAndSearchAllPublicInvestments(requestParameters: InvestmentsApiListAndSearchAllPublicInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).listAndSearchAllPublicInvestments(requestParameters.publicId, requestParameters.accountId, requestParameters.wkn, requestParameters.isin, requestParameters.isinOrWkn, requestParameters.tickerSymbol, requestParameters.name, requestParameters.currency, requestParameters.industryId, requestParameters.regionId, requestParameters.subCategoryId, requestParameters.includeHistoric, requestParameters.positiveAmountOrLastTransactionAfter, requestParameters.quoteQuality, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, requestParameters.aggregate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get gainers or losers (by changePercentToday)
     * @param {InvestmentsApiPublicGainersAndLosersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public publicGainersAndLosers(requestParameters: InvestmentsApiPublicGainersAndLosersRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).publicGainersAndLosers(requestParameters.publicId, requestParameters.mode, requestParameters.accountId, requestParameters.max, requestParameters.quoteQuality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the proposed region and industry classification (if any).
     * @param {InvestmentsApiRegionAndIndustryClassificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public regionAndIndustryClassification(requestParameters: InvestmentsApiRegionAndIndustryClassificationRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).regionAndIndustryClassification(requestParameters.isin, requestParameters.type, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Investment
     * @param {InvestmentsApiShowPublicInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public showPublicInvestment(requestParameters: InvestmentsApiShowPublicInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).showPublicInvestment(requestParameters.id, requestParameters.publicId, requestParameters.format, requestParameters.quoteQuality, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update private notes (a user-defined private comment) for the given Investment
     * @param {InvestmentsApiUpdatePrivateNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsApi
     */
    public updatePrivateNotes(requestParameters: InvestmentsApiUpdatePrivateNotesRequest, options?: RawAxiosRequestConfig) {
        return InvestmentsApiFp(this.configuration).updatePrivateNotes(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GainersAndLosersModeEnum = {
    GAINERS: 'gainers',
    LOSERS: 'losers'
} as const;
export type GainersAndLosersModeEnum = typeof GainersAndLosersModeEnum[keyof typeof GainersAndLosersModeEnum];
/**
 * @export
 */
export const GainersAndLosersQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type GainersAndLosersQuoteQualityEnum = typeof GainersAndLosersQuoteQualityEnum[keyof typeof GainersAndLosersQuoteQualityEnum];
/**
 * @export
 */
export const InvestmentsIdQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type InvestmentsIdQuoteQualityEnum = typeof InvestmentsIdQuoteQualityEnum[keyof typeof InvestmentsIdQuoteQualityEnum];
/**
 * @export
 */
export const InvestmentsIdFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type InvestmentsIdFormatEnum = typeof InvestmentsIdFormatEnum[keyof typeof InvestmentsIdFormatEnum];
/**
 * @export
 */
export const ListAndSearchAllInvestmentsQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type ListAndSearchAllInvestmentsQuoteQualityEnum = typeof ListAndSearchAllInvestmentsQuoteQualityEnum[keyof typeof ListAndSearchAllInvestmentsQuoteQualityEnum];
/**
 * @export
 */
export const ListAndSearchAllInvestmentsFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type ListAndSearchAllInvestmentsFormatEnum = typeof ListAndSearchAllInvestmentsFormatEnum[keyof typeof ListAndSearchAllInvestmentsFormatEnum];
/**
 * @export
 */
export const ListAndSearchAllPublicInvestmentsQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type ListAndSearchAllPublicInvestmentsQuoteQualityEnum = typeof ListAndSearchAllPublicInvestmentsQuoteQualityEnum[keyof typeof ListAndSearchAllPublicInvestmentsQuoteQualityEnum];
/**
 * @export
 */
export const ListAndSearchAllPublicInvestmentsFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type ListAndSearchAllPublicInvestmentsFormatEnum = typeof ListAndSearchAllPublicInvestmentsFormatEnum[keyof typeof ListAndSearchAllPublicInvestmentsFormatEnum];
/**
 * @export
 */
export const PublicGainersAndLosersModeEnum = {
    GAINERS: 'gainers',
    LOSERS: 'losers'
} as const;
export type PublicGainersAndLosersModeEnum = typeof PublicGainersAndLosersModeEnum[keyof typeof PublicGainersAndLosersModeEnum];
/**
 * @export
 */
export const PublicGainersAndLosersQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type PublicGainersAndLosersQuoteQualityEnum = typeof PublicGainersAndLosersQuoteQualityEnum[keyof typeof PublicGainersAndLosersQuoteQualityEnum];
/**
 * @export
 */
export const ShowPublicInvestmentFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type ShowPublicInvestmentFormatEnum = typeof ShowPublicInvestmentFormatEnum[keyof typeof ShowPublicInvestmentFormatEnum];
/**
 * @export
 */
export const ShowPublicInvestmentQuoteQualityEnum = {
    PERFORMANCE: 'PERFORMANCE',
    OPTIMAL: 'OPTIMAL'
} as const;
export type ShowPublicInvestmentQuoteQualityEnum = typeof ShowPublicInvestmentQuoteQualityEnum[keyof typeof ShowPublicInvestmentQuoteQualityEnum];
