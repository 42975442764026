import {
  subMonths,
  startOfMonth,
  startOfDay,
  endOfDay,
  endOfMonth,
} from "date-fns";
import { maxBy, map, each, keyBy } from "lodash";
import { Transaction, Category } from "@generated/apiv1";
import { isAfterOrEqual, isBeforeOrEqual } from "@helpers";

export type TransactionCategoryTreeNode = {
  total: number;
  categoryName: string | null;
  categoryId: number | null;
  categoryLevel: number;
  totalInterAccount: number;
  totalIncome: number;
  totalSpending: number;
  regularIncome: number;
  regularSpending: number;
  interAccountIncome: number;
  interAccountSpending: number;
  children: Record<string, TransactionCategoryTreeNode>;
  transactions: Transaction[];
  maxAmount?: number;
  barScale?: number;
};

export class TransactionTreeBuilder {
  buildTransactionsCategoryTree(
    transactions: Transaction[],
    categories: Category[],
    fromMonthsAgo?: number,
    toMonthsAgo?: number
  ) {
    const incomeCategoryId = categories.find((c) => c.name === "Einnahmen")?.id;
    const start = startOfDay(
      startOfMonth(subMonths(new Date(), fromMonthsAgo || 0))
    );
    let stop = null;
    if (toMonthsAgo) {
      stop = endOfDay(endOfMonth(subMonths(new Date(), toMonthsAgo)));
    }

    const transactionsWithCategories = this.assignCategories(
      transactions,
      categories
    );
    const rootNode = this.createTransactionCategoryTreeNode();

    for (let i = 0; i < transactionsWithCategories.length; i++) {
      const transaction = transactionsWithCategories[i];
      if (isAfterOrEqual(new Date(transaction.bankBookingDate!), start)) {
        if (
          !stop ||
          isBeforeOrEqual(new Date(transaction.bankBookingDate!), stop)
        ) {
          this.addTransactionToCategoryTree(
            rootNode,
            transaction,
            incomeCategoryId
          );
        }
      }
    }

    rootNode.maxAmount = 0;
    if (Object.keys(rootNode.children).length > 0) {
      const maxValue = maxBy(map(rootNode.children, "total"), (val) => {
        return Math.abs(val);
      });
      each(rootNode.children, (node) => {
        node.barScale = Math.abs(node.total / (maxValue || 1));
        if (node.barScale < 0.1) {
          node.barScale = 0.1;
        }
      });
    }

    return rootNode;
  }

  addTransactionToCategoryTree(
    rootNode: TransactionCategoryTreeNode,
    transaction: Transaction,
    incomeCategoryId: number = 320
  ) {
    const shouldBeMovedToIncomeCategory =
      ((transaction.category?.id !== incomeCategoryId &&
        transaction.category?.parentId !== incomeCategoryId) ||
        !transaction.category) &&
      (transaction.amount || 0) > 0;

    const category = shouldBeMovedToIncomeCategory
      ? {
          id: transaction.category?.id || -1,
          name: transaction.category?.name || "Nicht klassifiziert",
          parentId: incomeCategoryId,
          parentName: "Einnahmen",
          children: [],
          isCustom: false,
        }
      : transaction.category;
    let node = null;
    let childNode = null;

    if (!category) {
      const name = "Nicht klassifiziert";
      node = this.createOrUpdateNode(rootNode, -1, name, transaction, true);
      childNode = this.createOrUpdateNode(node, -1, name, transaction, false);
    } else {
      if (category.parentId) {
        // regular add: first the parent-category, then the category
        node = this.createOrUpdateNode(
          rootNode,
          category.parentId,
          category.parentName!,
          transaction,
          true
        );
        childNode = this.createOrUpdateNode(
          node,
          category.id!,
          category.name!,
          transaction,
          false
        );
      } else {
        // add same category twice: in case we assigned a parent category directly
        node = this.createOrUpdateNode(
          rootNode,
          category.id!,
          category.name!,
          transaction,
          true
        );
        childNode = this.createOrUpdateNode(
          node,
          category.id!,
          category.name!,
          transaction,
          false
        );
      }
    }

    node.categoryLevel = 1;
    childNode.categoryLevel = 2;
  }

  createOrUpdateNode(
    rootNode: TransactionCategoryTreeNode,
    categoryId: number,
    categoryName: string,
    transaction: Transaction,
    updateRootNode: boolean
  ) {
    var node = rootNode.children[categoryId];
    if (!node) {
      node = this.createTransactionCategoryTreeNode();
      node.categoryId = categoryId;
      node.categoryName = categoryName;
      rootNode.children[categoryId] = node;
    }
    const amount = transaction.amount || 0;

    if (amount > 0) {
      if (!transaction.isInterAccountTransfer) {
        node.totalIncome += amount;
        if (updateRootNode) {
          rootNode.totalIncome += amount;
        }
        if (transaction.isRegular) {
          node.regularIncome += amount;
          if (updateRootNode) {
            rootNode.regularIncome += amount;
          }
        }
      } else {
        node.interAccountIncome += amount;
        if (updateRootNode) {
          rootNode.interAccountIncome += amount;
        }
      }
    } else {
      if (!transaction.isInterAccountTransfer) {
        node.totalSpending += amount;
        if (updateRootNode) {
          rootNode.totalSpending += amount;
        }
        if (transaction.isRegular) {
          node.regularSpending += amount;
          if (updateRootNode) {
            rootNode.regularSpending += amount;
          }
        }
      } else {
        node.interAccountSpending += amount;
        if (updateRootNode) {
          rootNode.interAccountSpending += amount;
        }
      }
    }

    if (!transaction.isInterAccountTransfer) {
      node.total += amount;
      if (updateRootNode) {
        rootNode.total += amount;
      }
    } else {
      node.totalInterAccount += amount;
      if (updateRootNode) {
        rootNode.totalInterAccount += amount;
      }
    }

    node.transactions.push(transaction);
    if (updateRootNode) {
      rootNode.transactions.push(transaction);
    }

    return node;
  }

  private assignCategories(
    transactions: Transaction[],
    categories: Category[]
  ) {
    const categoriesById = keyBy(categories, "id");

    return Object.values(transactions).map((transaction) => ({
      ...transaction,
      ...(transaction.categoryId && categoriesById[transaction.categoryId]
        ? { category: categoriesById[transaction.categoryId] }
        : {}),
    }));
  }

  private createTransactionCategoryTreeNode(): TransactionCategoryTreeNode {
    return {
      categoryName: null,
      categoryId: null,
      categoryLevel: 0,
      total: 0,
      totalInterAccount: 0,
      totalIncome: 0,
      totalSpending: 0,
      regularIncome: 0,
      regularSpending: 0,
      interAccountIncome: 0,
      interAccountSpending: 0,
      children: {},
      transactions: [],
    };
  }
}
