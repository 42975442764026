import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { useTheme } from "@emotion/react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Typography } from "@mui/material";
import { toCustomShort, toShort } from "@helpers";
import { useChartColors } from "@hooks";
import { CustomTooltip } from "@components/Chart/ComparisonChart/CustomTooltip";
import { calculateDomains } from "./helpers/calculateDomains";

const CustomLegend = function (props: any) {
  const { payload } = props;

  return (
    <Box
      display="flex"
      gap={6}
      alignItems="center"
      justifyContent="flex-end"
      mt={6}
    >
      {payload.map((entry: any, i: number) => {
        const { color, value } = entry;

        return (
          <Box display="flex" alignItems="center" gap={1.5} key={i}>
            <Box
              sx={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: color,
              }}
            />
            <Typography variant="body2" fontWeight={400} color="textSecondary">
              {value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export interface ComparisonChartData {
  quote?: number;
  year: number;
  eps?: number;
  totalLiabilities?: number;
  operatingCashflow?: number;
  capEx?: number;
  freeCashflow?: number;
  totalEquity?: number;
  netIncome?: number;
  netRevenue?: number;
  outstandingShares?: number;
  returnOnAssets?: number;
  returnOnEquity?: number;
  fScore?: number;
  priceToBook?: number;
  priceToCashflow?: number;
  priceToFreeCashflow?: number;
  priceToSales?: number;
  priceToEarnings?: number;
}

export interface ComparisonBaseChartProps {
  shortLabel1: string;
  shortLabel2?: string;
  dataKey1: string;
  dataKey2?: string;
  quoteLabel: string;
  data: ComparisonChartData[];
}

export const ComparisonBaseChart = ({
  data,
  shortLabel1,
  shortLabel2,
  quoteLabel,
  dataKey1,
  dataKey2,
}: ComparisonBaseChartProps) => {
  const theme = useTheme();
  const domains = calculateDomains(data, dataKey1, dataKey2);
  const chartColors = useChartColors("comparisonChart");
  const isLinePositive =
    (data[0]?.quote || 0) <= (data[data.length - 1]?.quote || 0);

  return (
    <AutoSizer data-testid="comparison-base-chart">
      {({ width, height }: { width: number; height: number }) => (
        <ComposedChart
          width={width}
          height={height}
          data={data}
          barGap="1"
          stackOffset="sign"
          margin={{
            left: 0,
            top: 10,
          }}
        >
          <CartesianGrid
            stroke={theme.palette.border.secondary}
            vertical={false}
          />
          <ReferenceLine
            y={0}
            yAxisId="left"
            stroke={theme.palette.border.secondary}
          />
          <XAxis
            dataKey="year"
            stroke={theme.palette.border.secondary}
            tick={{
              fontSize: "12px",
              fill: theme.palette.text.secondary,
              fontWeight: 400,
            }}
            tickLine={false}
            tickMargin={20}
          />
          <YAxis
            domain={domains[dataKey1] as any}
            type="number"
            yAxisId="left"
            axisLine={false}
            tickCount={6}
            tickMargin={12}
            orientation="left"
            stroke={theme.palette.border.secondary}
            tickFormatter={(value) => toCustomShort(value)}
            tick={{
              fontSize: "12px",
              fill: theme.palette.text.secondary,
              fontWeight: 400,
            }}
          />
          {dataKey2 && (
            <YAxis
              type="number"
              domain={domains[dataKey2] as any}
              orientation="right"
              tickCount={6}
              yAxisId="right"
              tickMargin={12}
              tickFormatter={(value) => toCustomShort(value)}
              stroke={theme.palette.border.secondary}
              tick={{
                fontSize: "12px",
                fill: theme.palette.text.secondary,
                fontWeight: 400,
              }}
              hide={true}
            />
          )}
          <YAxis orientation="right" yAxisId="quote" hide={true} />
          <Tooltip
            formatter={(value: string | number) => toShort(value as number)}
            content={<CustomTooltip />}
            contentStyle={{
              boxShadow: theme.shadows[1],
              borderColor: "transparent",
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <Legend iconType="circle" content={<CustomLegend />} />
          <Bar
            name={shortLabel1}
            yAxisId="left"
            dataKey={dataKey1}
            barSize={10}
            fill={chartColors.value1}
            radius={[2, 2, 2, 2]}
          />
          {shortLabel2 && dataKey2 && (
            <Bar
              name={shortLabel2}
              yAxisId="right"
              dataKey={dataKey2}
              barSize={10}
              fill={chartColors.value2}
              radius={[2, 2, 0, 0]}
            />
          )}
          <Line
            name={quoteLabel}
            yAxisId="quote"
            type="linear"
            dataKey="quote"
            stroke={
              isLinePositive
                ? theme.palette.success.main
                : theme.palette.error.main
            }
            strokeWidth={2}
            data={data}
            dot={false}
          />
        </ComposedChart>
      )}
    </AutoSizer>
  );
};
