import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Button as MuiButton, Typography } from "@mui/material";
import { Card } from "../Card";
import { CardLabel } from "../CardLabel";

const MiniCard = styled(Card)<{ height?: string }>`
  height: ${({ height }) => height || "192px"};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(3.25, 4, 4)};
  box-sizing: border-box;
`;

const Button = styled(MuiButton)`
  margin-top: auto;
`;

export interface NoAccountsMiniCardProps {
  icon: ReactNode;
  title: string;
  subtitle: string;
  buttonText: string;
  onClick?: () => void;
  height?: string;
}

export const NoAccountsMiniCard = ({
  onClick,
  icon,
  title,
  subtitle,
  buttonText,
  height,
}: NoAccountsMiniCardProps) => {
  return (
    <MiniCard onClick={onClick} height={height}>
      <CardLabel icon={icon} title={title} />
      <Typography variant="body2">{subtitle}</Typography>
      <Button color="primary" variant="contained" size="small">
        {buttonText}
      </Button>
    </MiniCard>
  );
};
