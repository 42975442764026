import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setInvestmentIdAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKING_TYPE_SELECTED")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    investmentValues: {
      ...context.investmentValues,
      id: event.investmentId,
    },
  };
};
