import React, { ReactNode, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { ReactComponent as ChevronIcon } from "../../../../../icons/chevron-down.svg";

const Container = styled(Box)<{
  selected: boolean;
}>`
  text-decoration: none;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  color: ${({ theme, selected }) =>
    selected
      ? theme.appSidebar?.menuItem?.selectedColor
      : theme.appSidebar?.menuItem?.color};
`;

const ItemContainer = styled(Box)<{ selected: boolean; collapsed: number }>`
  text-decoration: none;
  display: flex;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background-color: ${({ theme, selected }) =>
    selected ? "#4560C7" : "transparent"};
  padding: ${({ theme, collapsed }) =>
    `${theme.spacing(2)} ${theme.spacing(collapsed ? 2 : 3)} ${theme.spacing(
      2
    )} ${theme.spacing(collapsed ? 2 : 3)}`};
  justify-items: end;
  align-items: center;
  cursor: pointer;
  color: inherit;

  & svg {
    color: ${({ theme, selected }) =>
      selected
        ? theme.appSidebar?.menuItem?.selectedColor
        : theme.appSidebar?.menuItem?.color};
  }

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.appSidebar?.menuItem?.selectedBackgroundColor
        : theme.appSidebar?.menuItem?.hoverBackgroundColor};
    color: ${({ theme }) => theme.appSidebar?.menuItem?.hoverColor};
    & svg {
      color: ${({ theme }) => theme.appSidebar?.menuItem?.hoverColor};
    }
  }
`;

const ExpandIconContainer = styled(Box)<{ expand: number }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.appSidebar?.menuItem?.color};

  & svg {
    transform: ${({ expand }) => (expand ? "rotate(180deg)" : "none")};
    transition: transform 0.1s;
  }
`;

const ChildrenContainer = styled(Box)`
  & > div {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  & .menuItem {
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
`;

const MenuItemText = styled(Box)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  font-weight: ${({ theme }) => theme.appSidebar?.menuItem?.fontWeight || 500};
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding-top: 3px;
`;

interface Props {
  name: string | ReactNode;
  before?: ReactNode;
  onClick?: () => void;
  expandIcon?: React.ReactNode;
  selected: boolean;
  children: ReactNode;
  to?: string;
}

export const MenuItemExpandable = ({
  name,
  selected,
  before,
  expandIcon = <ChevronIcon />,
  children,
  to,
}: Props) => {
  const [expand, setExpand] = useState(selected);
  useEffect(() => {
    setExpand(selected);
  }, [selected]);

  const navigate = useNavigate();
  const { sidebarCollapsed: collapsed } = useAppLayout();

  const handleClick = () => {
    if (!collapsed) {
      setExpand(!expand);
    }
    if (to && !selected) return navigate(to);
  };

  return (
    <Container selected={selected} width="100%">
      <ItemContainer
        width="100%"
        onClick={handleClick}
        selected={selected}
        collapsed={collapsed ? 1 : 0}
        display="flex"
        justifyContent="space-between"
        className="menuItemExpandable-container"
      >
        <Box alignItems="center" display="flex" minWidth="0" width="100%">
          <Box display="flex" mr={collapsed ? 0 : 4}>
            {before}
          </Box>
          {!collapsed && <MenuItemText>{name}</MenuItemText>}
        </Box>
        {!collapsed && (
          <ExpandIconContainer expand={expand ? 1 : 0}>
            {expandIcon}
          </ExpandIconContainer>
        )}
      </ItemContainer>
      {!collapsed && (
        <Collapse component={Box} in={expand}>
          <ChildrenContainer>{children}</ChildrenContainer>
        </Collapse>
      )}
    </Container>
  );
};
