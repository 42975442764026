import { UseQueryOptions } from "react-query";
import {
  SubscriptionsApiValidateDiscountCodeRequest,
  DiscountCodeValidationResult,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const validateDiscountCode = async (
  token: string | undefined,
  params: SubscriptionsApiValidateDiscountCodeRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.validateDiscountCode(params);
  return data;
};

export const useValidateDiscountCode = (
  params: SubscriptionsApiValidateDiscountCodeRequest,
  options?: UseQueryOptions<
    DiscountCodeValidationResult,
    AxiosApiError,
    DiscountCodeValidationResult
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONS_VALIDATE_DISCOUNT_CODE, params],
    ({ token }) => validateDiscountCode(token, params),
    options
  );
};
