/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A container for the bank certificate\'s data
 * @export
 * @interface TppCertificate
 */
export interface TppCertificate {
    /**
     * A certificate identifier.
     * @type {number}
     * @memberof TppCertificate
     */
    'id': number;
    /**
     * Type of certificate.
     * @type {string}
     * @memberof TppCertificate
     */
    'certificateType': TppCertificateCertificateTypeEnum;
    /**
     * Optional label of certificate.
     * @type {string}
     * @memberof TppCertificate
     */
    'label'?: string;
    /**
     * Valid from date in the format \'YYYY-MM-DD\'.
     * @type {string}
     * @memberof TppCertificate
     */
    'validFrom'?: string;
    /**
     * Valid until date in the format \'YYYY-MM-DD\'.
     * @type {string}
     * @memberof TppCertificate
     */
    'validUntil'?: string;
}

export const TppCertificateCertificateTypeEnum = {
    QWAC: 'QWAC',
    QSEALC: 'QSEALC'
} as const;

export type TppCertificateCertificateTypeEnum = typeof TppCertificateCertificateTypeEnum[keyof typeof TppCertificateCertificateTypeEnum];


