import { getYear, parseISO } from "date-fns";
import { HistoricQuote } from "@api";
import { ComparisonChartData } from "@components";

export const mergeHistoricQuotes = (
  chartData?: ComparisonChartData[],
  historicQuotes?: HistoricQuote[]
) =>
  chartData?.map((data) => {
    const quote = historicQuotes?.find(
      ({ date }) => getYear(parseISO(date)) === data.year
    );
    return { ...data, quote: quote?.value };
  });
