import {
  Box,
  IconButton,
  Modal as MuiModal,
  Slide,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { LoadingPlaceHolder } from "@components/LoadingPlaceHolder";
import { ReactComponent as CloseIcon } from "@icons/close.svg";

type ModalPosition = "centered" | "right" | "bottom";

const StyledMuiModal = styled(MuiModal, {
  shouldForwardProp: (prop) => prop !== "backdropStyle",
})<{ backdropStyle?: "dark" | "blur" }>(({ theme, backdropStyle }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor:
      backdropStyle === "dark" ? "rgba(0, 0, 0, 0.5)" : "transparent",
    backdropFilter: "blur(6px)",
  },
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isSmall",
})<{ isSmall: boolean }>(({ theme, isSmall = false }) => ({
  borderRadius: "50%",
  border: `1px solid ${theme.palette.border.secondary} !important`,
  width: isSmall ? "24px" : "40px",
  height: isSmall ? "24px" : "40px",

  [theme.breakpoints.down("sm")]: {
    border: "0px !important",
  },

  "& svg": {
    flexShrink: 0,
  },
}));

export const CloseButton = ({
  onClose,
  isSmall = false,
}: {
  onClose: () => void;
  isSmall?: boolean;
}) => {
  const theme = useTheme();
  const isLessSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledIconButton onClick={onClose} isSmall={isSmall || isLessSm}>
      <CloseIcon
        color={theme.palette.icon.primary}
        width="24px"
        height="24px"
      />
    </StyledIconButton>
  );
};

const OuterModalWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "modalPosition",
})<{
  modalPosition: ModalPosition;
}>(({ modalPosition }) => ({
  display: "flex",
  alignItems: modalPosition === "bottom" ? "flex-end" : "center",
  justifyContent: modalPosition === "centered" ? "center" : "flex-end",
  height: "100%",
  width: "100%",
  position: "absolute",
  outline: "none !important",
  top: 0,
  right: modalPosition === "right" ? "12px" : 0,
}));

const InnerModalWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "modalPosition",
})<{
  width: number;
  height: number | string;
  maxHeight?: number | string;
  modalPosition: ModalPosition;
}>(({ theme, width, height, maxHeight, modalPosition }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: `${width}px`,
  maxHeight:
    typeof maxHeight === "number"
      ? `${maxHeight}px`
      : maxHeight ?? typeof height === "number"
      ? `${height}px`
      : height,
  height: height === "auto" ? "auto" : "100%",
  borderRadius: modalPosition === "bottom" ? "16px 16px 0 0" : "16px",
  padding: theme.spacing(8),
  outline: "none !important",
  overflowY: "auto",
  border: `1px solid ${theme.palette.border.secondary}`,
  boxShadow: theme.shadows[1],

  [theme.breakpoints.down("sm")]: {
    right: "unset",
    padding: theme.spacing(6),
  },
}));

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  title?: string | ReactElement;
  subtitle?: string;
  children: ReactNode;
  width?: number;
  height?: number | string;
  maxHeight?: number | string;
  testId?: string;
  position?: ModalPosition;
  isLoading?: boolean;
  actionButton?: ReactNode;
  disablePortal?: boolean;
  disableScrollLock?: boolean;
  zIndex?: number;
  backdropStyle?: "dark" | "blur";
  titleAlign?: "left" | "center";
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  testId,
  width = 700,
  height = 600,
  maxHeight,
  position = "centered",
  backdropStyle = "dark",
  actionButton,
  titleAlign = "left",
  isLoading = false,
  disablePortal = true,
  disableScrollLock = false,
  zIndex,
}: ModalProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [isFocusing, setIsFocusing] = React.useState(false);
  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target as HTMLElement).closest("#modal-wrapper") && !isFocusing) {
      onClose?.();
    }
  };

  const handleOuterWrapperMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target as HTMLElement).closest("#modal-wrapper")) {
      setIsFocusing(false);
    }
  };

  const innerContent = (
    <OuterModalWrapper
      modalPosition={position}
      onMouseDown={handleOuterWrapperMouseDown}
      onMouseUp={handleClose}
    >
      <InnerModalWrapper
        id="modal-wrapper"
        width={width}
        height={height}
        maxHeight={maxHeight}
        data-testid={testId}
        modalPosition={position}
        onMouseDown={() => setIsFocusing(true)}
        onMouseUp={() => setIsFocusing(false)}
      >
        {typeof title === "string" ? (
          <Box>
            <Box
              mb={subtitle ? 1 : 6}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {titleAlign === "center" && !isMobile && <Box width={40} />}
              <Typography variant="h4" fontWeight={700}>
                {title}
              </Typography>
              <Box display="flex" alignItems="center" gap={4}>
                {actionButton}
                {onClose && <CloseButton onClose={onClose} />}
              </Box>
            </Box>
            {subtitle && (
              <Typography variant="body2" color="textSecondary" mb={6}>
                {subtitle}
              </Typography>
            )}
          </Box>
        ) : (
          title
        )}
        {isLoading ? <LoadingPlaceHolder height="100%" /> : children}
      </InnerModalWrapper>
    </OuterModalWrapper>
  );

  return (
    <StyledMuiModal
      disablePortal={disablePortal}
      disableScrollLock={disableScrollLock}
      open={isOpen}
      onClose={onClose}
      sx={zIndex ? { zIndex } : {}}
      backdropStyle={backdropStyle}
    >
      {position === "right" || position === "bottom" || height === "100%" ? (
        <Slide in={isOpen} direction={position === "right" ? "left" : "up"}>
          {innerContent}
        </Slide>
      ) : (
        innerContent
      )}
    </StyledMuiModal>
  );
};
