import { UseMutationOptions } from "react-query";
import {
  TransactionsApiCreateCashTransactionRequest,
  Transaction,
} from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createCashTransaction = async (
  token: string | undefined,
  params: TransactionsApiCreateCashTransactionRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.createCashTransaction(params);
  return data;
};

export const useCreateCashTransaction = (
  options?: UseMutationOptions<
    Transaction,
    AxiosApiError,
    TransactionsApiCreateCashTransactionRequest
  >
) => {
  return useAuthMutation(createCashTransaction, options, false);
};
