import { UseMutationOptions } from "react-query";
import { RolesApiAssignRoleRequest } from "@generated/apiv1";

import { buildRolesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const assignRole = async (
  token: string | undefined,
  params: RolesApiAssignRoleRequest
) => {
  const apiInstance = buildRolesApi(token);
  const { data } = await apiInstance.assignRole(params);
  return data;
};

export const useAssignRole = (
  options?: UseMutationOptions<void, AxiosApiError, RolesApiAssignRoleRequest>
) => {
  return useAuthMutation(assignRole, options, false);
};
