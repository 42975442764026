import React from "react";
import {
  Alert,
  AlertTitle,
  Typography,
  styled,
  Button,
  Link,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import CachedIcon from "@mui/icons-material/Cached";
import { FEEDBACK_EMAIL } from "@helpers";

interface Props {
  error?: any;
  eventId?: string;
  onClose?: () => void;
}

const Container = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(20),
  marginLeft: theme.spacing(10),
  marginRight: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(10),
  textAlign: "center",
}));

export const SomethingWrong = ({ error, eventId, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Alert severity="error" onClose={onClose}>
        <AlertTitle>
          <Trans
            i18nKey="somethingWrong.title"
            components={{
              link1: <Link href={`mailto:${FEEDBACK_EMAIL}`} />,
            }}
          />
        </AlertTitle>
        <Typography variant="subtitle1" data-hj-whitelist>
          {t("somethingWrong.message", { message: error?.message })}
        </Typography>
        {error?.errors?.map((errorDetails: ApiErrorDetails) => {
          return (
            errorDetails.message && (
              <div data-hj-whitelist>{errorDetails.message}</div>
            )
          );
        })}
        <Typography variant="subtitle2" data-hj-whitelist>
          {t("somethingWrong.eventIdMessage", { eventId })}
        </Typography>
      </Alert>
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CachedIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("somethingWrong.reload")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
