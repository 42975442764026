/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { LoginParams } from '../model';
// @ts-ignore
import { LoginToken } from '../model';
// @ts-ignore
import { OauthParams } from '../model';
/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary wealthAPI implements the OAuth 2.0 Standard for authorizing mandators and users within                        mandators. OAuth uses the terminology of clients and users. A client represents a mandator                        that calls certain services. A service call might be in the context of a user of the mandator                        (e.g.: getting a user\'s investments), or outside any user context                        (e.g.: editing your mandators configuration). In any case, every service call must be authorized                        by an access_token. This service can be used to get such an access_token, for either one of                        the client\'s users, or for the client itself. Also, this service can be used to refresh the                        access_token of a user that has previously requested an access_token.
         * @param {OauthParams} body The username and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessToken: async (body: OauthParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('accessToken', 'body', body)
            const localVarPath = `/oauth/access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DEPRECATED, use /oauth/access_token instead. Request a login token for the given user
         * @param {LoginParams} body The username and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('login', 'body', body)
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke (invalidate) all refresh tokens for the currently logged in user. Note that access tokens                        are not invalidated, but they will become invalid once they expire.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/revoke_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary wealthAPI implements the OAuth 2.0 Standard for authorizing mandators and users within                        mandators. OAuth uses the terminology of clients and users. A client represents a mandator                        that calls certain services. A service call might be in the context of a user of the mandator                        (e.g.: getting a user\'s investments), or outside any user context                        (e.g.: editing your mandators configuration). In any case, every service call must be authorized                        by an access_token. This service can be used to get such an access_token, for either one of                        the client\'s users, or for the client itself. Also, this service can be used to refresh the                        access_token of a user that has previously requested an access_token.
         * @param {OauthParams} body The username and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessToken(body: OauthParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessToken(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.accessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DEPRECATED, use /oauth/access_token instead. Request a login token for the given user
         * @param {LoginParams} body The username and password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Revoke (invalidate) all refresh tokens for the currently logged in user. Note that access tokens                        are not invalidated, but they will become invalid once they expire.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OauthApi.revokeToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * 
         * @summary wealthAPI implements the OAuth 2.0 Standard for authorizing mandators and users within                        mandators. OAuth uses the terminology of clients and users. A client represents a mandator                        that calls certain services. A service call might be in the context of a user of the mandator                        (e.g.: getting a user\'s investments), or outside any user context                        (e.g.: editing your mandators configuration). In any case, every service call must be authorized                        by an access_token. This service can be used to get such an access_token, for either one of                        the client\'s users, or for the client itself. Also, this service can be used to refresh the                        access_token of a user that has previously requested an access_token.
         * @param {OauthApiAccessTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessToken(requestParameters: OauthApiAccessTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginToken> {
            return localVarFp.accessToken(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DEPRECATED, use /oauth/access_token instead. Request a login token for the given user
         * @param {OauthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: OauthApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginToken> {
            return localVarFp.login(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke (invalidate) all refresh tokens for the currently logged in user. Note that access tokens                        are not invalidated, but they will become invalid once they expire.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.revokeToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accessToken operation in OauthApi.
 * @export
 * @interface OauthApiAccessTokenRequest
 */
export interface OauthApiAccessTokenRequest {
    /**
     * The username and password
     * @type {OauthParams}
     * @memberof OauthApiAccessToken
     */
    readonly body: OauthParams
}

/**
 * Request parameters for login operation in OauthApi.
 * @export
 * @interface OauthApiLoginRequest
 */
export interface OauthApiLoginRequest {
    /**
     * The username and password
     * @type {LoginParams}
     * @memberof OauthApiLogin
     */
    readonly body: LoginParams
}

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * 
     * @summary wealthAPI implements the OAuth 2.0 Standard for authorizing mandators and users within                        mandators. OAuth uses the terminology of clients and users. A client represents a mandator                        that calls certain services. A service call might be in the context of a user of the mandator                        (e.g.: getting a user\'s investments), or outside any user context                        (e.g.: editing your mandators configuration). In any case, every service call must be authorized                        by an access_token. This service can be used to get such an access_token, for either one of                        the client\'s users, or for the client itself. Also, this service can be used to refresh the                        access_token of a user that has previously requested an access_token.
     * @param {OauthApiAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public accessToken(requestParameters: OauthApiAccessTokenRequest, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).accessToken(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DEPRECATED, use /oauth/access_token instead. Request a login token for the given user
     * @param {OauthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public login(requestParameters: OauthApiLoginRequest, options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).login(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke (invalidate) all refresh tokens for the currently logged in user. Note that access tokens                        are not invalidated, but they will become invalid once they expire.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public revokeToken(options?: RawAxiosRequestConfig) {
        return OauthApiFp(this.configuration).revokeToken(options).then((request) => request(this.axios, this.basePath));
    }
}

