import React from "react";
import { Box, Button, TextField, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Banner } from "@components";
import { ReactComponent as SuccessIcon } from "@icons/success.svg";
import { ReactComponent as RectangleIcon } from "@icons/rectangle.svg";

type Props = {
  publicId?: string;
  onContinue: () => void;
  onBack: () => void;
};

export const ThirdStep = ({ publicId, onContinue, onBack }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [copied, setCopied] = React.useState(false);
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const link = `${process.env.REACT_APP_V2_URL}/portfolios/${publicId}`;

  return (
    <Box>
      {copied && (
        <Banner
          type="success"
          text={t("portfolioShare.modal.linkWasCopied")}
          icon={<SuccessIcon />}
          mb={6}
        />
      )}
      <Box display="flex" alignItems="center" gap={6}>
        <TextField
          value={link}
          fullWidth
          label={t("portfolioShare.modal.linkToDashboard")}
          sx={{
            marginBottom: (theme) => theme.spacing(8),
          }}
        />
        <Box display="flex" alignItems="center" flexShrink={0}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<RectangleIcon />}
            onClick={() => {
              navigator.clipboard.writeText(link);
              setCopied(true);
            }}
          >
            {t("portfolioShare.modal.copyLink")}
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        flexDirection={isLessSm ? "column" : "row"}
      >
        <Button
          variant="contained"
          onClick={onBack}
          color="secondary"
          fullWidth={isLessSm}
        >
          {t("navigation.previous")}
        </Button>
        <Box
          display="flex"
          alignItems="center"
          gap={3}
          width={isLessSm ? "100%" : "auto"}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/portfolios/${publicId}`)}
            fullWidth={isLessSm}
          >
            {t("portfolioShare.modal.goToPortfolio")}
          </Button>
          <Button
            variant="contained"
            onClick={onContinue}
            color="primary"
            fullWidth={isLessSm}
          >
            {t("portfolioShare.modal.closeButton")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
