import React, { ReactNode } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import { useTranslation } from "react-i18next";
import { AuthProvider } from "@providers/AuthContext";
import { LoginOrConfirmation } from "@features/login/LoginOrConfirmation";
import { AppRouter } from "./AppRouter";
import { AppWrapper } from "./AppWrapper";
import "./style.css";

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const dateLocale = i18n.language === "de" ? deLocale : enLocale;

  return (
    <AppWrapper>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocale}
      >
        {children}
      </LocalizationProvider>
    </AppWrapper>
  );
};

const App = () => {
  return (
    <AppProvider>
      <AuthProvider loginComponent={LoginOrConfirmation}>
        <AppRouter />
      </AuthProvider>
    </AppProvider>
  );
};

export default App;
