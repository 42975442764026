import { axiosInstanceV1 } from "@api/axiosInstance";
import { withToken } from "@api/auth/withToken";
import {
  CreateOrUpdateBookingParams,
  CreateOrUpdateInvestmentParamsList,
  TransactionValidationErrorList,
  CreateMultipleBookingParams,
  PageableBookingList,
  Booking,
  BookingTypeEnum,
  CarveOutParams,
  SwitchParams,
  CreateOrUpdateManagedDepotParams,
  SplitParams,
} from "../model";

export interface GetBookingParams {
  id?: number[];
  format?: "csv" | "json";
  accountId?: number[];
  investmentId?: number[];
  finApiId?: number[];
  type?: BookingTypeEnum[];
  wkn?: string[];
  isin?: string[];
  isinOrWkn?: string[];
  tickerSymbol?: string[];
  currency?: string[];
  minAmount?: number;
  maxAmount?: number;
  minDate?: string;
  maxDate?: string;
  page?: number;
  perPage?: number;
  order?: string;
}

export const getBooking = async (token: string | undefined, id: number) => {
  const { data } = await axiosInstanceV1.get<Booking>(`/bookings/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const updateBooking = async (
  token: string | undefined,
  bookingId: string,
  createOrUpdateBookingParams: CreateOrUpdateBookingParams
) => {
  const { data } = await axiosInstanceV1.put<CreateOrUpdateBookingParams>(
    `/bookings/${bookingId}`,
    createOrUpdateBookingParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const deleteBooking = async (
  token: string | undefined,
  bookingId: number
) => {
  const { data } = await axiosInstanceV1.delete(`/bookings/${bookingId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getBookings = async (
  token: string | undefined,
  params: GetBookingParams
) => {
  const { data } = await axiosInstanceV1.get<PageableBookingList>(`/bookings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params,
  });
  return data;
};

export const downloadBookings = async (
  token: string | undefined,
  params: GetBookingParams
) => {
  const { data } = await axiosInstanceV1.get<File>(`/bookings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
    params,
  });
  return data;
};

export const createOrUpdateBookings = async (
  token: string | undefined,
  createMultipleBookingParams: CreateMultipleBookingParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings`,
    createMultipleBookingParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const validateBookings = async (
  token: string | undefined,
  createOrUpdateInvestmentParamsList: CreateOrUpdateInvestmentParamsList
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/checkConsistencyStatus`,
    createOrUpdateInvestmentParamsList,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const createCarveOut = async (
  token: string | undefined,
  carveOutParams: CarveOutParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/carveOut`,
    carveOutParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const validateCarveOut = async (
  token: string | undefined,
  carveOutParams: CarveOutParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/carveOut/checkConsistencyStatus`,
    carveOutParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const createSwitch = async (
  token: string | undefined,
  switchParams: SwitchParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/switch`,
    switchParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const validateSwitch = async (
  token: string | undefined,
  switchParams: SwitchParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/switch/checkConsistencyStatus`,
    switchParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const createOrUpdateManagedDepotBooking = async (
  token: string | undefined,
  managedDepotParams: CreateOrUpdateManagedDepotParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/managedDepot`,
    managedDepotParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const validateManagedDepotBooking = async (
  token: string | undefined,
  managedDepotParams: CreateOrUpdateManagedDepotParams
) => {
  const { data } = await axiosInstanceV1.post<TransactionValidationErrorList>(
    `/bookings/managedDepot/checkConsistencyStatus`,
    managedDepotParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const createSplit = async (
  token: string | undefined,
  splitParams: SplitParams
) => {
  const { data } = await axiosInstanceV1.put(`/bookings/split`, splitParams, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const createOrUpdateBookingsWithToken = withToken(
  createOrUpdateBookings
);

export const createOrUpdateManagedDepotBookingWithToken = withToken(
  createOrUpdateManagedDepotBooking
);

export const createCarveOutWithToken = withToken(createCarveOut);

export const createSwitchWithToken = withToken(createSwitch);

export const createSplitWithToken = withToken(createSplit);

export const getBookingWithToken = withToken(getBooking);

export const updateBookingWithToken = withToken(updateBooking);

export const getBookingsWithToken = withToken(getBookings);

export const validateBookingsWithToken = withToken(validateBookings);

export const validateManagedDepotBookingWithToken = withToken(
  validateManagedDepotBooking
);

export const validateCarveOutWithToken = withToken(validateCarveOut);

export const validateSwitchWithToken = withToken(validateSwitch);
