import React, { ReactElement } from "react";
import { ReactComponent as MetalsIcon } from "@icons/edelmetalle.svg";
import { ReactComponent as LineChartIcon } from "@icons/line-chart.svg";
import { ReactComponent as CryptoIcon } from "@icons/crypto.svg";
import { ReactComponent as CoinsIcon } from "@icons/coins-single.svg";
import { ReactComponent as HomeIcon } from "@icons/home.svg";
import { ReactComponent as DiamondIcon } from "@icons/diamond.svg";
import {
  BookingTypeEnum,
  SearchSymbolV2TypeEnum,
  SymbolV2,
} from "@generated/apiv1";
import { ReactComponent as AusbuchungIcon } from "@icons/bookingTypes/ausbuchung.svg";
import { ReactComponent as EinbuchungIcon } from "@icons/bookingTypes/einbuchung.svg";
import { ReactComponent as FusionIcon } from "@icons/bookingTypes/fusion.svg";
import { ReactComponent as SplitIcon } from "@icons/bookingTypes/split.svg";
import { ReactComponent as TauschIcon } from "@icons/bookingTypes/tausch.svg";
import { ReactComponent as DividendeIcon } from "@icons/bookingTypes/dividende.svg";
import { ReactComponent as GratisAktieIcon } from "@icons/bookingTypes/gratis_aktie.svg";
import { ReactComponent as KaufIcon } from "@icons/bookingTypes/kauf.svg";
import { ReactComponent as VerkaufIcon } from "@icons/bookingTypes/verkauf.svg";
import { ReactComponent as CarveOutIcon } from "@icons/bookingTypes/carve_out.svg";

const prepicksList: Record<string, SymbolV2[]> = {
  crypto: [
    {
      id: "BTC_to_EUR",
      name: "Bitcoin",
      type: "81_fcurr",
      literalType: "Crypto currency",
      quoteProvider: "crypto",
    },
    {
      id: "ETH_to_EUR",
      name: "Ethereum",
      type: "81_fcurr",
      literalType: "Crypto currency",
      quoteProvider: "crypto",
    },
    {
      id: "USDT_to_EUR",
      name: "Tether",
      type: "81_fcurr",
      literalType: "Crypto currency",
      quoteProvider: "crypto",
    },
    {
      id: "BNB_to_EUR",
      name: "BNB",
      type: "81_fcurr",
      literalType: "Crypto currency",
      quoteProvider: "crypto",
    },
    {
      id: "SOL_to_EUR",
      name: "Solana",
      type: "81_fcurr",
      literalType: "Crypto currency",
      quoteProvider: "crypto",
    },
  ],
  foreignCurrency: [
    {
      id: "USD_to_EUR",
      name: "US Dollar",
      type: "81_fcurr",
      literalType: "Currency",
      quoteProvider: "currency",
    },
    {
      id: "CHF_to_EUR",
      name: "Schweizer Franken",
      type: "81_fcurr",
      literalType: "Currency",
      quoteProvider: "currency",
    },
    {
      id: "GBP_to_EUR",
      name: "Britisches Pfund",
      type: "81_fcurr",
      literalType: "Currency",
      quoteProvider: "currency",
    },
    {
      id: "JPY_to_EUR",
      name: "Japanischer Yen",
      type: "81_fcurr",
      literalType: "Currency",
      quoteProvider: "currency",
    },
    {
      id: "AUD_to_EUR",
      name: "Australischer Dollar",
      type: "81_fcurr",
      literalType: "Currency",
      quoteProvider: "currency",
    },
  ],
  metals: [
    {
      id: "GOLD_EUR",
      isin: "XC0009655157",
      name: "Gold",
      type: "61_pmetals",
      quoteProvider: "quandl",
    },
    {
      id: "SILVER_EUR",
      isin: "XC0009653103",
      name: "Silber",
      type: "61_pmetals",
      quoteProvider: "quandl",
    },
    {
      id: "PLATINUM_EUR",
      isin: "XC0009665546",
      name: "Platin",
      type: "61_pmetals",
      quoteProvider: "quandl",
    },
    {
      id: "PALLADIUM_EUR",
      isin: "XC0009665520",
      name: "Palladium",
      type: "61_pmetals",
      quoteProvider: "quandl",
    },
  ],
};

export const filterGroups: Record<
  string,
  {
    types: SearchSymbolV2TypeEnum[];
    icon: React.ReactNode;
    prepicks?: SymbolV2[];
  }
> = {
  securities: {
    types: ["11_stock", "21_fund", "31_bond", "41_cash", "22_etf", "51_certos"],
    icon: <LineChartIcon width="20px" />,
  },
  metals: {
    types: ["61_pmetals"],
    icon: <MetalsIcon width="20px" />,
    prepicks: prepicksList.metals,
  },
  foreignCurrency: {
    types: ["81_fcurr"],
    icon: <CoinsIcon width="20px" />,
    prepicks: prepicksList.foreignCurrency,
  },
  crypto: {
    types: ["81_fcurr"],
    icon: <CryptoIcon width="20px" />,
    prepicks: prepicksList.crypto,
  },
  materialAssets: {
    icon: <HomeIcon width="20px" />,
    types: ["71_massets"],
  },
  other: {
    types: ["71_massets"],
    icon: <DiamondIcon width="20px" />,
  },
};

export enum AdditionalBookingType {
  FUSION = "fusion",
}

export type ExtendedBookingType = AdditionalBookingType | BookingTypeEnum;

export const normalBookings: ExtendedBookingType[] = [
  BookingTypeEnum.BUY,
  BookingTypeEnum.SELL,
];

export const extendedBookings: ExtendedBookingType[] = [
  BookingTypeEnum.DIVIDEND,
  BookingTypeEnum.BOOK_IN,
  BookingTypeEnum.BOOK_OUT,
  BookingTypeEnum.SWITCH,
  AdditionalBookingType.FUSION,
  BookingTypeEnum.CARVE_OUT,
  BookingTypeEnum.SPLIT,
  BookingTypeEnum.RETAINED_DIVIDEND,
];

export const sellTypes: ExtendedBookingType[] = [
  BookingTypeEnum.SELL,
  BookingTypeEnum.BOOK_OUT,
  BookingTypeEnum.SWITCH,
  AdditionalBookingType.FUSION,
  BookingTypeEnum.CARVE_OUT,
  BookingTypeEnum.SPLIT,
];

export const bookingImagesMap: Record<ExtendedBookingType, ReactElement> = {
  [BookingTypeEnum.BUY]: <KaufIcon />,
  [BookingTypeEnum.SELL]: <VerkaufIcon />,
  [BookingTypeEnum.BOOK_OUT]: <AusbuchungIcon />,
  [BookingTypeEnum.BOOK_IN]: <EinbuchungIcon />,
  [BookingTypeEnum.SWITCH]: <TauschIcon />,
  [AdditionalBookingType.FUSION]: <FusionIcon />,
  [BookingTypeEnum.CARVE_OUT]: <CarveOutIcon />,
  [BookingTypeEnum.SPLIT]: <SplitIcon />,
  [BookingTypeEnum.DIVIDEND]: <DividendeIcon />,
  [BookingTypeEnum.REVERSE_SPLIT]: <SplitIcon />,
  [BookingTypeEnum.PURCHASE_RIGHT]: <KaufIcon />,
  [BookingTypeEnum.RETAINED_DIVIDEND]: <GratisAktieIcon />,
};
