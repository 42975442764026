import { UseQueryOptions } from "react-query";
import { UserProfilesApiUserProfileRequest, Profile } from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const userProfile = async (
  token: string | undefined,
  params?: UserProfilesApiUserProfileRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.userProfile(params);
  return data;
};

export const useUserProfile = (
  params?: UserProfilesApiUserProfileRequest,
  options?: UseQueryOptions<Profile, AxiosApiError, Profile>
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERPROFILES_USER_PROFILE, params],
    ({ token }) => userProfile(token, params),
    options
  );
};
