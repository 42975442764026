import { axiosInstanceV1 } from "@api/axiosInstance";
import {
  ListAndSearchAllWatchlistEntries,
  WatchlistEntryList,
  WatchlistEntry,
  CreateOrUpdateWatchlistEntryParams,
} from "../model";

export const loadWatchlistEntry = async (
  token: string | undefined,
  watchlistEntryId: number
) => {
  const response = await axiosInstanceV1.get<WatchlistEntry>(
    `/watchlistEntries/${watchlistEntryId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const getWatchlistEntries = async (
  token: string | undefined,
  params: ListAndSearchAllWatchlistEntries
) => {
  const response = await axiosInstanceV1.get<WatchlistEntryList>(
    `/watchlistEntries`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  );

  return response.data;
};

export const createWatchlistEntry = async (
  token: string | undefined,
  params: CreateOrUpdateWatchlistEntryParams
) => {
  const response = await axiosInstanceV1.post<WatchlistEntry>(
    `/watchlistEntries`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const updateWatchlistEntry = async (
  token: string | undefined,
  params: CreateOrUpdateWatchlistEntryParams & { watchlistEntryId: number }
) => {
  const response = await axiosInstanceV1.put<WatchlistEntry>(
    `/watchlistEntries/${params.watchlistEntryId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const deleteWatchlistEntry = async (
  token: string | undefined,
  watchlistEntryId: number
) => {
  const response = await axiosInstanceV1.delete<never>(
    `/watchlistEntries/${watchlistEntryId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
