import React from "react";
import { Grid } from "@mui/material";

export const HideableField = ({
  children,
  hide,
}: {
  children: React.ReactNode;
  hide: boolean;
}) => {
  return (
    <Grid item xs={12} sx={{ display: hide ? "none" : "block" }}>
      {children}
    </Grid>
  );
};
