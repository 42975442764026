import React from "react";
import Highcharts from "highcharts";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import { formatDate } from "@components/Chart/Tooltips/PerformanceValuationTooltip";
import { toCurrency, toPercent } from "@helpers";

type Props = {
  isLimitReached: boolean;
  chartType: "valuation" | "performance";
  series: Highcharts.Series[];
  x?: string | number;
};

export const WatchlistsChartTooltip = ({
  isLimitReached,
  chartType,
  x,
  series,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ChartTooltip>
      <div
        style={{
          color: theme.palette.text.secondary,
          fontWeight: 500,
          marginBottom: "9px",
        }}
      >
        {formatDate(x)}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          maxHeight: isLimitReached ? "215px" : "auto",
          overflowY: "auto",
          paddingRight: "4px",
        }}
      >
        {series?.map((serie) => {
          // @ts-ignore
          if (serie.initialType === "flags") return null;
          const value = serie.points.find((point) => point.x === x)?.y;
          return (
            <div
              key={serie.name}
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    width: 8,
                    height: 8,
                    backgroundColor: serie.color as string,
                  }}
                />
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "160px",
                  }}
                >
                  {serie.name}
                </div>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: 600,
                }}
              >
                {chartType === "valuation"
                  ? toCurrency(value ?? 0)
                  : toPercent((value ?? 0) / 100)}
              </div>
            </div>
          );
        })}
      </div>
      {isLimitReached && (
        <div
          style={{
            color: theme.palette.text.secondary,
            fontWeight: 500,
            marginTop: "9px",
          }}
        >
          <div
            style={{
              backgroundColor: "#F9F9FA",
              borderRadius: "8px",
              padding: "8px",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 500,
              border: "1px solid #F0F0F2",
              whiteSpace: "normal",
              width: "230px",
              color: theme.palette.text.primary,
            }}
          >
            {t("watchlists.maximum")}
          </div>
        </div>
      )}
    </ChartTooltip>
  );
};
