import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/browser";
import { NoTokenError } from "@api/auth/NoTokenError";
import { useMyself } from "@api";
import { isProduction } from "@constants";

export const AuthContext = createContext<{
  setLoginRequired: (error: AxiosError<ApiException> | NoTokenError) => void;
}>({
  setLoginRequired: () => {
    // eslint-disable-next-line no-console
    console.warn("No <AuthProvider/> Found. Login form will not be shown.");
  },
});

interface Props {
  children: ReactNode;
  loginComponent: FC<{
    error?: AxiosError<ApiException> | NoTokenError;
    onLogin?: () => void;
  }>;
}

export const AuthProvider = ({ children, loginComponent }: Props) => {
  const [loginRequired, setLoginRequired] = useState(false);
  const [error, setError] = useState<AxiosError<ApiException> | NoTokenError>();
  const { data: myself } = useMyself();

  useEffect(() => {
    if (myself) {
      Sentry.setUser({ id: String(myself.id), email: myself.email });
    }
  }, [myself]);

  const handleLoginRequired = (
    err: AxiosError<ApiException> | NoTokenError
  ) => {
    setLoginRequired(true);
    setError(err);
  };

  const handleLogin = () => {
    setLoginRequired(false);
    setError(undefined);
  };

  useEffect(() => {
    if (isProduction && loginRequired) {
      window.location.replace(`${process.env.REACT_APP_V2_URL}/sign_in`);
    }
  }, [loginRequired]);

  if (loginRequired && isProduction) return null;

  return (
    <AuthContext.Provider value={{ setLoginRequired: handleLoginRequired }}>
      {loginRequired
        ? React.createElement(loginComponent, {
            error: error,
            onLogin: handleLogin,
          })
        : children}
    </AuthContext.Provider>
  );
};
