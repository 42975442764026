import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Investment, SingleInvestmentTwrorRecord } from "@generated/apiv1";
import { LoadingPlaceHolder, NoAccountsStub } from "@components";
import { SortableTableHeader, TableBodyCell } from "@components/Table";
import { InvestmentChildRows } from "@features/investments/components/InvestmentsTable";
import { DashboardInvestmentRow } from "@features/dashboard/components/DashboardInvestmentRow";
import { useTableSortWithData } from "@hooks";
import {
  getStoredSortParams,
  setStoredSortParams,
} from "@helpers/localStorage";
import {
  addPerformanceDataToInvestments,
  transformPerformanceData,
} from "./utils";

type Props = {
  investments?: Investment[];
  isLoading?: boolean;
  isFetched?: boolean;
  performance?: SingleInvestmentTwrorRecord[];
  cashValue: number;
  logos?: Record<string, string | undefined | null>;
};

export const DashboardInvestmentsTable = ({
  investments = [],
  isLoading,
  isFetched,
  performance,
  cashValue,
  logos,
}: Props) => {
  const { t } = useTranslation();
  const hasAnyAggregatedRows = investments.some(
    (investment) => investment.aggregation?.isAggregated
  );

  const totalValue = useMemo(
    () =>
      investments.reduce(
        (acc, investment) => acc + investment.currentMarketValueAC,
        0
      ) + Math.abs(cashValue),
    [cashValue, investments]
  );

  const performancesByInvestmentId = useMemo(
    () => transformPerformanceData(performance),
    [performance]
  );

  const investmentsWithAdditionalProps = useMemo(
    () =>
      addPerformanceDataToInvestments(
        investments,
        performancesByInvestmentId,
        logos,
        totalValue
      ),
    [investments, performancesByInvestmentId, logos, totalValue]
  );

  const { data, sortField, sortDirection, sort } = useTableSortWithData(
    investmentsWithAdditionalProps,
    {
      field: "allocation",
      direction: "DESC",
    }
  );

  useEffect(() => {
    const item = getStoredSortParams("holdingList");
    if (!item) {
      sort("allocation", "DESC");
      return;
    }
    const { sortDirection, sortField } = item;
    sort(sortField, sortDirection);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!sortDirection || !sortField) return;
    setStoredSortParams(sortField, sortDirection, "holdingList");
  }, [sortDirection, sortField]);

  const handleClickOnHeader = (sortByName: string) => {
    sort(sortByName);
  };

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            {hasAnyAggregatedRows && <TableCell style={{ minWidth: "16px" }} />}
            <SortableTableHeader
              isSortable
              sortField={sortField}
              sortDirection={sortDirection}
              sortByName="standardisedName"
              text={t("dashboardPage.dashboard.investmentsTable.columns.name")}
              onClick={handleClickOnHeader}
            />

            <SortableTableHeader
              isSortable
              sortField={sortField}
              sortDirection={sortDirection}
              sortByName="numberOfLots"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.numberOfShares"
              )}
              onClick={handleClickOnHeader}
              align="right"
            />

            <SortableTableHeader
              isSortable
              sortField={sortField}
              sortDirection={sortDirection}
              sortByName="currentMarketValueAC"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.totalValue"
              )}
              onClick={handleClickOnHeader}
              align="right"
            />

            <SortableTableHeader
              isSortable
              sortField={sortField}
              sortDirection={sortDirection}
              sortByName="twror"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.change"
              )}
              onClick={handleClickOnHeader}
              align="right"
            />

            <SortableTableHeader
              isSortable
              sortField={sortField}
              sortDirection={sortDirection}
              sortByName="allocation"
              text={t(
                "dashboardPage.dashboard.investmentsTable.columns.allocation"
              )}
              onClick={handleClickOnHeader}
              align="right"
            />
          </TableRow>
        </TableHead>
        {isLoading || (isFetched && !data?.length) ? (
          <TableBody>
            <TableRow>
              <TableBodyCell colSpan={6} sx={{ borderBottom: "none" }}>
                {isFetched ? <NoAccountsStub py={5} /> : <LoadingPlaceHolder />}
              </TableBodyCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {data?.map((investment) => (
              <>
                <DashboardInvestmentRow
                  key={
                    investment.aggregation?.isAggregated
                      ? investment.aggregation?.identifier
                      : investment.id
                  }
                  image={investment.image}
                  row={investment}
                  showIndent={
                    hasAnyAggregatedRows &&
                    !investment.aggregation?.isAggregated
                  }
                  expandableElement={
                    investment.aggregation?.isAggregated ? (
                      <InvestmentChildRows
                        investments={investment.aggregation?.items ?? []}
                        totalValue={investment.currentMarketValueAC ?? 0}
                        renderComponent={DashboardInvestmentRow}
                        sortField={sortField}
                        sortDirection={sortDirection}
                      />
                    ) : undefined
                  }
                />
              </>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  );
};
