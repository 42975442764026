import React, { ReactNode } from "react";
import { Menu } from "@mui/material";
import styled from "@emotion/styled";

const StyledMenu = styled(Menu)`
  width: 100%;
`;

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  children: ReactNode;
}

export const ActionsMenu = ({ anchorEl, setAnchorEl, children }: Props) => {
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      className="rangeSelectorMenu"
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </StyledMenu>
  );
};
