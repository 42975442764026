import { withToken } from "@api/auth/withToken";
import { axiosInstanceV1 } from "@api/axiosInstance";
import { Symbol, SymbolList } from "@api/v1/model";

export const searchSymbols = async (
  token: string | undefined,
  search: string,
  perAssetType: number,
  forceExactMatch = false
) => {
  const { data } = await axiosInstanceV1.get<SymbolList>(`/symbols`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      search,
      perAssetType,
      forceExactMatch,
    },
  });
  return data;
};

export const getSymbol = async (
  token: string | undefined,
  quoteProvider: string,
  tickerSymbol: string
) => {
  const { data } = await axiosInstanceV1.get<Symbol>(
    `/symbols/${quoteProvider}/${tickerSymbol}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getSymbolWithToken = withToken(getSymbol);
