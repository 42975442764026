import { getCurrencies, CurrencyParams } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { GET_CURRENCIES_KEY } from "../../cacheKeys";

export const useGetCurrencies = (params: CurrencyParams) =>
  useAuthQuery(
    [GET_CURRENCIES_KEY, params],
    ({ token }) => getCurrencies(token, params),
    {
      staleTime: Infinity,
    }
  );
