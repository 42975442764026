import { useQuery, UseQueryOptions } from "react-query";
import { FeedItemList, RentabloBlogFeed } from "@api/v1/model";
import { getNewsFeed, getNewsFeedRentabloBlog } from "@api/v1/methods";
import { GET_NEWS_FEED, GET_NEWS_FEED_RENTABLO_BLOG } from "../../cacheKeys";

interface Params {
  isin: string;
}

export const useGetNewsFeed = ({ isin }: Params) =>
  useQuery([GET_NEWS_FEED, isin], () => getNewsFeed({ isin }));

export const useGetNewsFeedRentabloBlog = (
  params: RentabloBlogFeed,
  options?: UseQueryOptions<
    FeedItemList,
    AxiosApiError,
    FeedItemList,
    (string | RentabloBlogFeed)[]
  >
) =>
  useQuery(
    [GET_NEWS_FEED_RENTABLO_BLOG, params],
    () => getNewsFeedRentabloBlog(params),
    options
  );
