import React, { useCallback } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { StyledCard } from "@features/dividendPlanner/components/StyledCard";

import { Filters } from "@features/dividendPlanner/OverViewTab/MainSection/Filters";
import { DividendsChart } from "@features/dividendPlanner/OverViewTab/DividendsChart/DividendsChart";
import { Modal } from "@components";
import { DividendsTable } from "@features/dividendPlanner/components/DividendsTable/DividendsTable";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";
import { PlusGradient } from "@components/Plus/PlusGradient";

export const MainSection = () => {
  const theme = useTheme();
  const isLessSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(
    undefined
  );
  const [detailedViewDate, setDetailedViewDate] = React.useState<Date | null>(
    null
  );

  const { isUserDataFetched, isUserPro } = useDividendsContext();

  const handleChange = (year?: number) => {
    setSelectedYear(year);
  };

  const onTooltipClick = useCallback((date: Date | null) => {
    setDetailedViewDate(date);
  }, []);

  return (
    <StyledCard>
      <Filters onChange={handleChange} />
      <DividendsChart
        onTooltipClick={onTooltipClick}
        selectedYear={selectedYear}
      />
      {isUserDataFetched && !isUserPro && (
        <PlusGradient variant="large" mt={6} />
      )}
      <DividendsTable
        date={selectedYear ? new Date(selectedYear, 0) : new Date()}
        period={selectedYear ? "year" : undefined}
        width="calc(100% + 48px)"
        view="table"
        margin={(theme) => theme.spacing(6, -6, 0)}
        sx={{
          "& .MuiTableCell-root:first-of-type": {
            padding: theme.spacing(3, 3, 3, 6),
          },
          "& .MuiTableCell-root:last-of-type": {
            padding: theme.spacing(3, 6, 3, 3),
          },
        }}
        scrollableTarget="layoutWithTabsBody"
      />

      {detailedViewDate && (
        <Modal
          width={1000}
          height={isLessSm ? "calc(100% - 86px)" : 650}
          position={isLessSm ? "bottom" : "centered"}
          isOpen
          onClose={() => setDetailedViewDate(null)}
          title={format(detailedViewDate, selectedYear ? "MMMM yyyy" : "yyyy")}
          titleAlign="center"
        >
          <DividendsTable
            height="100%"
            scrollContainerHeight="100%"
            view="modal"
            date={detailedViewDate}
            period={selectedYear ? "month" : "year"}
            displayProBanner
            sx={{
              "& .infinite-scroll-component__outerdiv": {
                height: "100%",
              },
              "& .infinite-scroll-component": {
                height: "100% !important",
              },
            }}
          />
        </Modal>
      )}
    </StyledCard>
  );
};
