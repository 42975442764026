export const toCurrency = (
  num = 0,
  isoCode = "EUR",
  options: Intl.NumberFormatOptions = {}
) => {
  const shouldShowMoreNumbers = Math.abs(num) < 0.01;

  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: shouldShowMoreNumbers ? 8 : 2,
    style: "currency",
    currency: isoCode,
    ...options,
  })
    .format(num)
    .replace(/\s/, "");
};

export const toCurrencyWholeNumber = (
  num: number = 0,
  isoCode: string = "EUR"
) => {
  return toCurrency(num, isoCode, {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const toRoundedNumber = (num: number, fractionDigits: number = 2) => {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits,
  }).format(num);
};

type SignDisplayType = "auto" | "never" | "always" | "exceptZero";

export const toPercent = (
  num = 0,
  signDisplay: SignDisplayType = "auto",
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) => {
  const result = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits,
    maximumFractionDigits,
    style: "percent",
    // @ts-ignore
    signDisplay,
  })
    .format(num)
    .replace(/\s/, "");

  if (result === "100,00%") {
    return "100%";
  }

  return result;
};

export const toRoundedNumberOfLots = (
  num: number,
  maximumFractionDigits = 2
) => {
  return `${new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
  }).format(num)} St.`;
};

export const toShort = (num: number) =>
  new Intl.NumberFormat("de-DE", {
    notation: "compact",
    compactDisplay: "short",
  })
    .format(num)
    .replace(" ", "");

export const toAmericanShort = (
  num: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(num)
    .replace(".", ",");
};

export const toCustomShort = (num: number) => {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
  })
    .format(num)
    .replace(/[.,KMBT]/g, (x) => {
      switch (x) {
        case ",":
          return ".";
        case ".":
          return ",";
        case "K":
          return "T";
        case "M":
          return "Mio";
        case "B":
          return "Mrd";
        case "T":
          return "Bio";
        default:
          return x;
      }
    });
};

export const toCurrencyCustomShort = (num: number, currency: string) => {
  return toCurrency(num, currency, {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const toGermanNumberFormat = (
  num: number,
  options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
) => new Intl.NumberFormat("de-DE", options).format(num);

export const toString = (
  val?: number,
  input = "",
  maximumFractionDigits = 6
) => {
  if (val == null || isNaN(val)) return input;
  const numberFormatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: false,
  });

  const len = numberFormatter.format(val).length;
  const substring = input.substring(len);
  return substring.match(/,?0+/)
    ? numberFormatter.format(val) + substring
    : numberFormatter.format(val);
};

export const parseOrUndefined = (value: string) => {
  try {
    if (value === "") return undefined;
    if (value.endsWith(",") || value.endsWith(".")) return undefined;
    // do not use replaceAll because node does not implement it and it will fail in jest
    const usFormat = value.replace(/,/g, ".");
    const parsedValue = parseFloat(usFormat);
    if (Number.isNaN(parsedValue)) return undefined;

    return parsedValue;
  } catch (err) {
    return undefined;
  }
};

export const kFormatter = (val: number | string) => {
  const num = Number(val);
  if (Math.abs(num) > 999) {
    return Math.sign(num) * Number((Math.abs(num) / 1000).toFixed()) + "K";
  }
  return Math.sign(num) * Math.abs(num);
};
