import { UseMutationOptions } from "react-query";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankConnectionsCashback = async (
  token: string | undefined
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankConnectionsCashback();
  return data;
};

export const useDeleteBankConnectionsCashback = (
  options?: UseMutationOptions<void, AxiosApiError, unknown>
) => {
  return useAuthMutation(deleteBankConnectionsCashback, options, false);
};
