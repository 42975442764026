import { isEqual, parseISO } from "date-fns";
import { sameDateInUTC } from "@helpers";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const findAndPrefillDividendValuesAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
): Pick<DividendWizardContext, "dividendValues"> | object => {
  if (event.type !== "LOADED_DIVIDENDS") {
    throw new Error(`Invalid event type ${event.type}`);
  }
  const dividend = event.dividends.find(({ date }) => {
    return isEqual(
      parseISO(date!),
      sameDateInUTC(context.dividendValues.date!)
    );
  });

  if (!dividend) return {};

  return {
    dividendValues: {
      ...context.dividendValues,
      securityPrice: dividend.grossAmount,
    },
  };
};
