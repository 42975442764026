import React from "react";
import { Card, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toCurrency } from "@helpers";
import { GainLossTypography, LoadingPlaceHolder } from "@components";

const CardWrapper = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 4),
}));

type Props = {
  type: "income" | "expenses" | "balance";
  actual: number;
  average?: number;
  isLoading: boolean;
};

export const IncomeExpensesCard = ({
  type,
  isLoading,
  actual,
  average,
}: Props) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingPlaceHolder height="116px" />;
  }

  return (
    <CardWrapper>
      <Typography variant="body1" fontWeight={500} color="textSecondary" mb={2}>
        {t(`cashflowAnalyzer.${type}`)}
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <GainLossTypography
            variant="h6"
            value={
              actual === 0 || type === "balance"
                ? 0
                : type === "income"
                ? 1
                : -1
            }
          >
            {toCurrency(type === "balance" ? actual : Math.abs(actual), "EUR")}
          </GainLossTypography>
          <Typography variant="caption" color="textSecondary" fontWeight={400}>
            {t("cashflowAnalyzer.actualMonth")}
          </Typography>
        </Grid>
        {average !== undefined && (
          <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary">
              {toCurrency(Math.abs(average), "EUR")}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              fontWeight={400}
            >
              {t("cashflowAnalyzer.mean")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardWrapper>
  );
};
