import { UseMutationOptions, UseQueryOptions } from "react-query";
import { downloadInvoice, getInvoices } from "@api/v1/methods";
import { InvoiceList } from "@api/v1/model";
import { useAuthQuery, useAuthMutation } from "@api/auth";

import { MY_INVOICES } from "../../cacheKeys";

export const useMyInvoices = (
  options?: UseQueryOptions<InvoiceList, AxiosApiError, InvoiceList>
) => useAuthQuery([MY_INVOICES], ({ token }) => getInvoices(token), options);

export const useDownloadInvoice = (
  options?: UseMutationOptions<
    { data: Blob; name: string },
    AxiosApiError,
    number
  >
) => useAuthMutation(downloadInvoice, options);
