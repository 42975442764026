import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMachine } from "@xstate/react";
import truncate from "lodash/truncate";
import { PageLayout } from "@components";
import { SomethingWrong } from "../transactionWizard/steps/SomethingWrong";
import { PersistedStep as SuccessStep } from "./steps/PersistedStep";
import { customDividendWizardSM } from "./stateMachine/customDividendWizardSM";
import { useStateHelpers } from "./hooks";
import { ConfirmManualDividendStep } from "./steps/ConfirmManualDividendStep";
import { AddManualDividendStep } from "./steps/AddManualDividendStep";
import { DividendValues } from "./stateMachine/types/dividendValues";

export interface Props {
  investmentId: number;
  date: string | null;
}

export const CreateDividendWizard = ({ investmentId, date }: Props) => {
  const [currentState, sendEvent] = useMachine(customDividendWizardSM);
  const { t } = useTranslation();
  const { isLoadingState } = useStateHelpers(currentState);

  useEffect(() => {
    if (date) {
      sendEvent({
        type: "INIT_FROM_DIVIDEND",
        investmentId,
        date: new Date(date),
      });
    } else {
      sendEvent({ type: "INIT_FROM_INVESTMENT", investmentId });
    }
  }, [investmentId, date, sendEvent]);

  const investmentName = currentState.context.investment?.name ?? "";
  const handleBackStep = () => sendEvent({ type: "BACK" });

  return (
    <PageLayout
      isLoading={isLoadingState()}
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        { to: "/investments", text: t("breadcrumbs.investments") },
        { to: `/investments/${investmentId}`, text: truncate(investmentName) },
        { text: t("addDividendWizard.breadcrumbs.create") },
      ]}
    >
      {currentState.matches("addManualDividend") && (
        <AddManualDividendStep
          onNext={(dividendValues: DividendValues) =>
            sendEvent({
              type: "VALIDATE_MANUAL_DIVIDEND",
              dividendValues,
            })
          }
          dividendValues={currentState.context.dividendValues}
          investmentName={currentState.context.investment?.name ?? ""}
          investmentCurrency={
            currentState.context.investment?.currency ?? "EUR"
          }
          validationErrors={currentState.context.validationErrors ?? []}
        />
      )}

      {currentState.matches("summary") && (
        <ConfirmManualDividendStep
          dividendValues={currentState.context.dividendValues}
          investmentCurrency={
            currentState.context.investment?.currency ?? "EUR"
          }
          investmentName={currentState.context.investment?.name ?? ""}
          onPrevious={handleBackStep}
          onNext={() => sendEvent({ type: "PERSIST" })}
        />
      )}

      {currentState.matches("success") && (
        <SuccessStep
          investmentName={currentState.context.investment?.name || ""}
          redirectUrl={`/investments/${investmentId}?tab=dividends`}
        />
      )}

      {currentState.matches("somethingWrong") && (
        <SomethingWrong message={currentState.context.error?.message} />
      )}
    </PageLayout>
  );
};
