import React from "react";
import styled from "@emotion/styled";
import { Chip } from "@mui/material";

export type SecuritiesChartSelectedType =
  | "all"
  | "shares"
  | "funds"
  | "etfs"
  | "bonds"
  | "currency"
  | "materialAssets"
  | "metals";

export interface ChartToolbarItem {
  clickable?: boolean;
  text: string;
  value: SecuritiesChartSelectedType;
  selected?: boolean;
}

export interface ChartToolbarProps {
  allowedTypes: ChartToolbarItem[];
  onChange: (type: SecuritiesChartSelectedType) => void;
  selected: string[];
}

const ChartToolbarWrapper = styled.div`
  margin-top: 24px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 12px;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ChartToolbar = ({
  allowedTypes,
  onChange,
  selected,
}: ChartToolbarProps) => {
  const isSelected = (value: string) => selected.includes(value);
  return (
    <ChartToolbarWrapper>
      {allowedTypes.map((type) => (
        <Chip
          size="small"
          variant="outlined"
          key={`key-${type.value}`}
          label={type.text}
          color={
            !type.clickable || isSelected(type.value) ? "primary" : "secondary"
          }
          onClick={type.clickable ? () => onChange(type.value) : () => {}}
        />
      ))}
    </ChartToolbarWrapper>
  );
};
