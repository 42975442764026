/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DividendBatchResponse } from '../model';
// @ts-ignore
import { DividendResponse } from '../model';
/**
 * DividendsApi - axios parameter creator
 * @export
 */
export const DividendsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns dividend
         * @param {string} isin 
         * @param {GetdividendTypeDividendDividendTypeEnum} dividendType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getdividendTypeDividend: async (isin: string, dividendType: GetdividendTypeDividendDividendTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('getdividendTypeDividend', 'isin', isin)
            // verify required parameter 'dividendType' is not null or undefined
            assertParamExists('getdividendTypeDividend', 'dividendType', dividendType)
            const localVarPath = `/api/v3/dividends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }

            if (dividendType !== undefined) {
                localVarQueryParameter['dividend_type'] = dividendType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns dividend batch
         * @param {string} isins Comma separated ISINs. isins&#x3D;US2338252073,DE0007100000
         * @param {GetdividendTypeDividendInBatchDividendTypeEnum} dividendType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getdividendTypeDividendInBatch: async (isins: string, dividendType: GetdividendTypeDividendInBatchDividendTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isins' is not null or undefined
            assertParamExists('getdividendTypeDividendInBatch', 'isins', isins)
            // verify required parameter 'dividendType' is not null or undefined
            assertParamExists('getdividendTypeDividendInBatch', 'dividendType', dividendType)
            const localVarPath = `/api/v3/dividends/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isins !== undefined) {
                localVarQueryParameter['isins'] = isins;
            }

            if (dividendType !== undefined) {
                localVarQueryParameter['dividend_type'] = dividendType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DividendsApi - functional programming interface
 * @export
 */
export const DividendsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DividendsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns dividend
         * @param {string} isin 
         * @param {GetdividendTypeDividendDividendTypeEnum} dividendType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getdividendTypeDividend(isin: string, dividendType: GetdividendTypeDividendDividendTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getdividendTypeDividend(isin, dividendType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.getdividendTypeDividend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns dividend batch
         * @param {string} isins Comma separated ISINs. isins&#x3D;US2338252073,DE0007100000
         * @param {GetdividendTypeDividendInBatchDividendTypeEnum} dividendType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getdividendTypeDividendInBatch(isins: string, dividendType: GetdividendTypeDividendInBatchDividendTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DividendBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getdividendTypeDividendInBatch(isins, dividendType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DividendsApi.getdividendTypeDividendInBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DividendsApi - factory interface
 * @export
 */
export const DividendsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DividendsApiFp(configuration)
    return {
        /**
         * 
         * @summary returns dividend
         * @param {DividendsApiGetdividendTypeDividendRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getdividendTypeDividend(requestParameters: DividendsApiGetdividendTypeDividendRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendResponse> {
            return localVarFp.getdividendTypeDividend(requestParameters.isin, requestParameters.dividendType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns dividend batch
         * @param {DividendsApiGetdividendTypeDividendInBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getdividendTypeDividendInBatch(requestParameters: DividendsApiGetdividendTypeDividendInBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<DividendBatchResponse> {
            return localVarFp.getdividendTypeDividendInBatch(requestParameters.isins, requestParameters.dividendType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getdividendTypeDividend operation in DividendsApi.
 * @export
 * @interface DividendsApiGetdividendTypeDividendRequest
 */
export interface DividendsApiGetdividendTypeDividendRequest {
    /**
     * 
     * @type {string}
     * @memberof DividendsApiGetdividendTypeDividend
     */
    readonly isin: string

    /**
     * 
     * @type {'reported' | 'estimated'}
     * @memberof DividendsApiGetdividendTypeDividend
     */
    readonly dividendType: GetdividendTypeDividendDividendTypeEnum
}

/**
 * Request parameters for getdividendTypeDividendInBatch operation in DividendsApi.
 * @export
 * @interface DividendsApiGetdividendTypeDividendInBatchRequest
 */
export interface DividendsApiGetdividendTypeDividendInBatchRequest {
    /**
     * Comma separated ISINs. isins&#x3D;US2338252073,DE0007100000
     * @type {string}
     * @memberof DividendsApiGetdividendTypeDividendInBatch
     */
    readonly isins: string

    /**
     * 
     * @type {'reported' | 'estimated'}
     * @memberof DividendsApiGetdividendTypeDividendInBatch
     */
    readonly dividendType: GetdividendTypeDividendInBatchDividendTypeEnum
}

/**
 * DividendsApi - object-oriented interface
 * @export
 * @class DividendsApi
 * @extends {BaseAPI}
 */
export class DividendsApi extends BaseAPI {
    /**
     * 
     * @summary returns dividend
     * @param {DividendsApiGetdividendTypeDividendRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public getdividendTypeDividend(requestParameters: DividendsApiGetdividendTypeDividendRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).getdividendTypeDividend(requestParameters.isin, requestParameters.dividendType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns dividend batch
     * @param {DividendsApiGetdividendTypeDividendInBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DividendsApi
     */
    public getdividendTypeDividendInBatch(requestParameters: DividendsApiGetdividendTypeDividendInBatchRequest, options?: RawAxiosRequestConfig) {
        return DividendsApiFp(this.configuration).getdividendTypeDividendInBatch(requestParameters.isins, requestParameters.dividendType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetdividendTypeDividendDividendTypeEnum = {
    REPORTED: 'reported',
    ESTIMATED: 'estimated'
} as const;
export type GetdividendTypeDividendDividendTypeEnum = typeof GetdividendTypeDividendDividendTypeEnum[keyof typeof GetdividendTypeDividendDividendTypeEnum];
/**
 * @export
 */
export const GetdividendTypeDividendInBatchDividendTypeEnum = {
    REPORTED: 'reported',
    ESTIMATED: 'estimated'
} as const;
export type GetdividendTypeDividendInBatchDividendTypeEnum = typeof GetdividendTypeDividendInBatchDividendTypeEnum[keyof typeof GetdividendTypeDividendInBatchDividendTypeEnum];
