import React, { cloneElement, isValidElement, ReactNode } from "react";
import { styled } from "@mui/material";
import { motion } from "framer-motion";
import { ReactComponent as ChevronIcon } from "../../../../../icons/chevron.svg";
import { Footer } from "../Footer";

const Container = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.appSidebar?.background};
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

const InnerContainer = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: clip;
`;

const ArrowContainer = styled(motion.div)`
  position: absolute;
  bottom: 105px;
  right: -11px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  color: ${({ theme }) => theme.appSidebar?.color};
  cursor: pointer;
  z-index: 1;

  & svg {
    margin-left: -2px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export interface SidebarProps {
  header: ReactNode;
  children: ReactNode;
  collapsed?: boolean;
  onCollapseClick?: () => void;
}

export const Sidebar = ({
  header,
  children,
  collapsed,
  onCollapseClick,
}: SidebarProps) => {
  const headerWithProps = isValidElement(header)
    ? cloneElement(header, { collapsed })
    : header;

  return (
    <Container
      initial={{ width: collapsed ? 55 : 300 }}
      animate={{ width: collapsed ? 55 : 300 }}
    >
      <InnerContainer>
        {headerWithProps}
        {children}
      </InnerContainer>
      <ArrowContainer
        onClick={onCollapseClick}
        animate={{ rotate: collapsed ? 180 : 0, transition: { delay: 0.3 } }}
      >
        <ChevronIcon />
      </ArrowContainer>
      <Footer />
    </Container>
  );
};
