import { useRef, useState } from "react";

export const useDrawLines = (enabled = true) => {
  const [lines, setLines] =
    useState<{ x1: number; y1: number; x2: number; y2: number }[]>();
  const [startXY, setStartXY] = useState<{ x: number; y: number }>();
  const [isDrawing, setIsDrawing] = useState(false);

  const lineRef = useRef<SVGLineElement>(null);

  const handleMouseDown = (event: any) => {
    if (!enabled) return;
    lineRef.current?.setAttribute("x2", "0");
    lineRef.current?.setAttribute("y2", "0");
    lineRef.current?.setAttribute("x1", "0");
    lineRef.current?.setAttribute("y1", "0");
    const newX = event?.chartX;
    const newY = event?.chartY;
    setStartXY({ x: newX, y: newY });
    setIsDrawing(true);
  };

  const handleMouseMove = (event: any) => {
    if (!enabled) return;
    if (!isDrawing) return;

    const newX = event?.chartX;
    const newY = event?.chartY;
    lineRef.current?.setAttribute("x1", startXY?.x?.toString() ?? "");
    lineRef.current?.setAttribute("y1", startXY?.y?.toString() ?? "");
    if (newX) {
      lineRef.current?.setAttribute("x2", newX);
    }
    if (newY) {
      lineRef.current?.setAttribute("y2", newY);
    }
  };

  const handleMouseUp = (event: any) => {
    if (!enabled) return;
    if (!startXY) return;
    const newX = event?.chartX;
    const newY = event?.chartY;

    const newLine = {
      x1: startXY.x,
      y1: startXY.y,
      x2: newX as number,
      y2: newY as number,
    };
    setLines((current) => [...(current ?? []), newLine]);
    setStartXY(undefined);
    setIsDrawing(false);
  };

  const handleClear = () => {
    setLines(undefined);
  };

  return {
    lines,
    mouseUp: handleMouseUp,
    mouseMove: handleMouseMove,
    mouseDown: handleMouseDown,
    isDrawing,
    lineRef,
    clear: handleClear,
  };
};
