import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { updateInvestment, useAuthMutation } from "@api";
import { GET_INVESTMENTS } from "@api/cacheKeys";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const useUpdateTag = (investmentId: number) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { mutate: update, ...rest } = useAuthMutation(updateInvestment, {
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_INVESTMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.INVESTMENTS_LIST_AND_SEARCH_ALL_INVESTMENTS],
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: t("form.successMessage"),
        variant: "success",
        autoHideDuration: 3000,
      });
    },
  });

  const handleUpdate = (tag: string | null) => {
    update({ id: investmentId, tag: tag?.trim() ?? "" });
  };

  return { update: handleUpdate, ...rest };
};
