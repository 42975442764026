import { MutateOptions } from "react-query";
import {
  CreateOrUpdateWatchlistEntryParams,
  WatchlistEntry,
} from "@api/v1/model";
import { createWatchlistEntry } from "@api/v1/methods";
import { useAuthMutation } from "@api/auth";

export const useCreateWatchlistEntry = (
  options?: MutateOptions<
    WatchlistEntry,
    AxiosApiError,
    CreateOrUpdateWatchlistEntryParams
  >
) => useAuthMutation(createWatchlistEntry, options);
