import React from "react";
import styled from "@emotion/styled";
import { Typography, TypographyVariant } from "@mui/material";
import { toPercent } from "@helpers";
import { ReactComponent as UpIcon } from "@icons/positive.svg";
import { ReactComponent as DownIcon } from "@icons/negative.svg";

const Container = styled.div`
  display: flex;
  height: min-content;
  align-items: stretch;
`;

const ArrowContainer = styled.div<{ positive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ positive }) => (positive ? "flex-start" : "flex-end")};
  margin-top: ${({ positive }) => (positive ? "6px" : "auto")};
  margin-bottom: ${({ positive }) => (positive ? "auto" : "6px")};
  margin-left: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme, positive }) =>
    positive ? theme.palette.text.positive : theme.palette.text.negative};
`;

export interface PercentIndicatorProps {
  percent: number;
  variant?: TypographyVariant;
  iconSize?: string;
  className?: string;
}

const CLASS_NAME = "PercentIndicator";

export const PercentIndicator = ({
  percent,
  variant = "h2",
  iconSize = "14px",
  className,
}: PercentIndicatorProps) => {
  return (
    <Container className={[`${CLASS_NAME}-root`, className].join("")}>
      <Typography className={`${CLASS_NAME}-title`} variant={variant}>
        {toPercent(percent)}
      </Typography>
      <ArrowContainer
        className={`${CLASS_NAME}-ArrowContainer`}
        positive={percent > 0}
      >
        {percent > 0 && (
          <UpIcon width={iconSize} className={`${CLASS_NAME}-UpIcon`} />
        )}
        {percent < 0 && (
          <DownIcon width={iconSize} className={`${CLASS_NAME}-DownIcon`} />
        )}
      </ArrowContainer>
    </Container>
  );
};
