import { UseMutationOptions } from "react-query";
import { SubcategoriesApiDeleteSubcategoryRequest } from "@generated/apiv3";

import { buildSubcategoriesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteSubcategory = async (
  token: string | undefined,
  params: SubcategoriesApiDeleteSubcategoryRequest
) => {
  const apiInstance = buildSubcategoriesApi(token);
  const { data } = await apiInstance.deleteSubcategory(params);
  return data;
};

export const useDeleteSubcategory = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    SubcategoriesApiDeleteSubcategoryRequest
  >
) => {
  return useAuthMutation(deleteSubcategory, options, false);
};
