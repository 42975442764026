import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const setInvestmentAction = (
  context: DividendWizardContext,
  event: DividendWizardEvent
) => {
  if (event.type !== "LOADED_INVESTMENT")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    investment: event.investment,
  };
};
