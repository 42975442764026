import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { StepContainer } from "../../transactionWizard/StepContainer";

export interface Props {
  onNext: (agree: boolean) => void;
  onPrevious: () => void;
}

const ControlsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const TaxRequestStep = (props: Props) => {
  const { onNext, onPrevious } = props;
  const { t } = useTranslation();
  return (
    <StepContainer
      testid="tax-request-step"
      title={t("batchDividendsWizard.taxRequestStep.taxQuestion")}
      onPrevious={onPrevious}
    >
      <ControlsContainer>
        <Box mb={2}>
          <Button
            data-testid="enter-tax-button"
            variant="contained"
            color="primary"
            onClick={() => onNext(true)}
          >
            {t("batchDividendsWizard.taxRequestStep.acceptTax")}
          </Button>
        </Box>
        <Button
          data-testid="skip-tax-button"
          variant="contained"
          color="primary"
          onClick={() => onNext(false)}
        >
          {t("batchDividendsWizard.taxRequestStep.declineTax")}
        </Button>
      </ControlsContainer>
    </StepContainer>
  );
};
