import { Box, BoxProps, styled } from "@mui/material";
import React from "react";
import { Logo } from "@components/Logo";

const Image = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasImage",
})<{
  hasImage?: boolean;
  isFinanzfluss?: boolean;
  width?: number;
  height?: number;
}>(({ theme, hasImage, width, height }) => ({
  borderRadius: theme.shape.borderRadius,
  width: width,
  height: height,
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: hasImage
    ? theme.palette.background.default
    : theme.palette.tertiary.light,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  overflow: "hidden",

  "& img": {
    borderRadius: theme.shape.borderRadius,
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
}));

const Circle = styled(Box)<{ color?: string }>(({ theme, color }) => ({
  width: 11,
  height: 11,
  borderRadius: "50%",
  backgroundColor: color,
  position: "absolute",
  bottom: 0,
  left: 0,
  marginLeft: theme.spacing(7),
  marginBottom: theme.spacing(0.75),
  border: `2px solid ${theme.palette.background.default}`,
}));

type Props = {
  src?: string | null;
  alt?: string;
  width?: number;
  height?: number;
  circleColor?: string;
} & BoxProps;

export const SecurityImage = ({
  src,
  alt,
  width = 40,
  height = 40,
  circleColor,
  ...rest
}: Props) => {
  return (
    <Image hasImage={Boolean(src)} width={width} height={height} {...rest}>
      {src ? <img src={src || ""} alt={alt} /> : <Logo variant="asset" />}
      {circleColor && <Circle color={circleColor} />}
    </Image>
  );
};
