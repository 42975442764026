import React, { useState } from "react";
import { Box, Typography, TableBody } from "@mui/material";
import { format, parseJSON } from "date-fns";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { TableBodyCell, TableRow } from "@components/Table";
import { useDeleteBooking } from "@api";
import { Booking, BookingTypeEnum, InvestmentTypeEnum } from "@generated/apiv1";
import {
  getNumberOfLotsByInvestmentAndBookingType,
  getValueByInvestmentAndBookingType,
  toCurrency,
} from "@helpers";
import { ActionsButton } from "@components/ActionsButton";
import { EditBookingModal } from "@components/Accounts/TransactionTable/EditBookingModal";
import { EditDividendModal } from "@components/Accounts/TransactionTable/EditDividendModal";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { BlurredTypography } from "@components/BlurredTypography";
import { BookingType } from "@components/BookingType";
import { InvestmentInfo } from "@components/InvestmentInfo";
import { useSecurityImages } from "@hooks/useSecurityImages";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { VirtualBookingTooltip } from "@features/investments/components/VirtualBookingTooltip";
import {
  BOOKING_WITH_NOL_ESTIMATED,
  DELTA_RESOLUTION,
  NEGATIVE_NOL_DELTA_RESOLUTION,
} from "@constants";

interface BookingsTableBodyProps {
  data: Booking[];
}

export const BookingsTableBody = (props: BookingsTableBodyProps) => {
  const { data = [] } = props;
  const { t } = useTranslation();
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [currentBookingIdEditing, setCurrentBookingIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentInvestmentIdEditing, setCurrentInvestmentIdEditing] = useState<
    number | undefined
  >(undefined);
  const [currentBookingIdDeleting, setCurrentBookingIdDeleting] = useState<
    number | undefined
  >(undefined);
  const [tickerSymbol, setTickerSymbol] = useState<string | undefined>(
    undefined
  );
  const [isEditTransactionModalOpened, setIsEditTransactionModalOpened] =
    useState(false);
  const [isEditDividendModalOpened, setIsEditDividendModalOpened] =
    useState(false);
  const [isDeleteBookingModalOpened, setIsDeleteBookingModalOpened] =
    useState(false);

  const handleBookingTypeEditing = (
    bookingType?: BookingTypeEnum,
    bookingId?: number,
    investmentId?: number,
    tickerSymbol?: string
  ) => {
    setCurrentInvestmentIdEditing(investmentId);
    setCurrentBookingIdEditing(bookingId);
    setTickerSymbol(tickerSymbol);
    if (bookingType === "dividend") {
      setIsEditDividendModalOpened(true);
      return;
    }
    setIsEditTransactionModalOpened(true);
  };

  const handleDeleteBooking = (bookingId?: number) => {
    setIsDeleteBookingModalOpened(true);
    setCurrentBookingIdDeleting(bookingId);
  };

  const logos = useSecurityImages(
    data.map((booking) => ({
      ...booking.investment,
    }))
  );

  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isDeleteRequestLoading,

    reset: mutationReset,
  } = useDeleteBooking({
    onSuccess: () => {
      setIsDeleteBookingModalOpened(false);
      queryClient.refetchQueries({
        queryKey: [CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS],
      });
    },
    onError: (error) => {
      setErrorMessage(error.response?.data?.message);
    },
    retry: false,
  });

  return (
    <>
      <TableBody>
        {data.map((item: Booking) => (
          <TableRow key={item.id}>
            <TableBodyCell>
              <Box display="flex" alignItems="center" gap={1}>
                <BookingType type={item.type} />
                {(item.dataConsistencyStatus === BOOKING_WITH_NOL_ESTIMATED ||
                  item.dataConsistencyStatus === DELTA_RESOLUTION ||
                  item.dataConsistencyStatus ===
                    NEGATIVE_NOL_DELTA_RESOLUTION) && (
                  <VirtualBookingTooltip
                    dataConsistencyStatus={item.dataConsistencyStatus}
                  />
                )}
              </Box>
              <Typography
                variant="body2"
                fontWeight={400}
                color="textSecondary"
              >
                {format(parseJSON(item.valueDate), "dd.MM.yyyy")}
              </Typography>
            </TableBodyCell>

            <TableBodyCell>
              <InvestmentInfo
                item={item.investment}
                image={
                  logos[item.investment.isin] ||
                  logos[item.investment.tickerSymbol]
                }
              />
            </TableBodyCell>

            <TableBodyCell align="right">
              <BlurredTypography variant="body2" color="textPrimary">
                {getValueByInvestmentAndBookingType({
                  value: Math.abs(item.securityPrice),
                  currency: item.investment.currency,
                  investmentType: item.investment.type as InvestmentTypeEnum,
                  bookingType: item.type,
                  percentageRange: "0-100",
                })}
              </BlurredTypography>
            </TableBodyCell>

            <TableBodyCell align="right">
              <BlurredTypography
                variant="body2"
                color="textSecondary"
                fontWeight={400}
              >
                {getNumberOfLotsByInvestmentAndBookingType({
                  value: item.numberOfLots,
                  bookingType: item.type,
                  noPrice: true,
                })}
              </BlurredTypography>
            </TableBodyCell>

            <TableBodyCell align="right">
              <BlurredTypography
                display="flex"
                justifyContent="end"
                variant="body2"
              >
                {item.amount
                  ? toCurrency(item.amount, item.investment.currency)
                  : "N/A"}
              </BlurredTypography>
            </TableBodyCell>

            {!isSharedPortfolio && (
              <TableBodyCell align="right">
                <ActionsButton
                  onEditItemClick={
                    item.type === "split" || item.type === "reverse_split"
                      ? undefined
                      : () =>
                          handleBookingTypeEditing(
                            item.type,
                            item.id,
                            item.investment?.id,
                            item.investment?.tickerSymbol
                          )
                  }
                  onDeleteItemClick={() => handleDeleteBooking(item?.id)}
                  editItemText={t("singleAccount.editTransaction")}
                  deleteItemText={t("singleAccount.deleteTransaction")}
                />
              </TableBodyCell>
            )}
          </TableRow>
        ))}
      </TableBody>
      {isEditTransactionModalOpened && currentBookingIdEditing && (
        <EditBookingModal
          isOpen={isEditTransactionModalOpened}
          onClose={() => setIsEditTransactionModalOpened(false)}
          investmentId={currentInvestmentIdEditing}
          bookingId={currentBookingIdEditing}
          tickerSymbol={tickerSymbol}
          invalidateBookings
        />
      )}

      {isEditDividendModalOpened &&
        currentBookingIdEditing &&
        currentInvestmentIdEditing && (
          <EditDividendModal
            isOpen={isEditDividendModalOpened}
            onClose={() => setIsEditDividendModalOpened(false)}
            investmentId={currentInvestmentIdEditing}
            bookingId={currentBookingIdEditing}
            tickerSymbol={tickerSymbol}
            invalidateBookings
          />
        )}

      <ConfirmDeleteDialog
        isOpen={isDeleteBookingModalOpened}
        title={t("singleAccount.deleteModal.title")}
        text={t("singleAccount.deleteModal.text")}
        cancelButtonText={t("singleAccount.deleteModal.cancel")}
        confirmButtonText={t("singleAccount.deleteModal.confirm")}
        onClose={() => {
          setIsDeleteBookingModalOpened(false);
          setErrorMessage(undefined);
          mutationReset();
        }}
        onDelete={() => mutate(currentBookingIdDeleting!)}
        errorMessage={errorMessage}
        isDeleteRequestLoading={isDeleteRequestLoading}
      />
    </>
  );
};
