import React, { ReactNode, useRef } from "react";
import {
  Box,
  styled,
  Tab,
  Typography,
  Tabs,
  useMediaQuery,
  Theme,
  Breakpoint,
} from "@mui/material";
import { Card } from "@components";

const MainContainer = styled(Box)<{ breakpoint: Breakpoint | number }>(
  ({ theme, breakpoint }) => ({
    background: theme.palette.background.subtle,
    height: "100dvh",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down(breakpoint)]: {
      height: "auto",
      minHeight: "100dvh",
    },
  })
);

const PanelContainer = styled(Box)<{ breakpoint: Breakpoint | number }>(
  ({ theme, breakpoint }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    background: theme.palette.background.paper,
    position: "relative",
    padding: theme.spacing(0, 6),
    boxShadow: "0px 0px 2px 0px #00000029, 0px 4px 24px 0px #00000014",
    zIndex: "3",

    [theme.breakpoints.down(breakpoint)]: {
      padding: theme.spacing(4, 6),
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  })
);

const StyledTab = styled(Tab)(() => ({
  height: "72px",
}));

const StyledTabs = styled(Tabs)<{ breakpoint: Breakpoint | number }>(
  ({ theme, breakpoint }) => ({
    "& .MuiTabs-flexContainer": {
      padding: 0,
    },
    "& .MuiTabs-scroller": {
      height: "72px",
    },
    "& .MuiTabs-indicator": {
      height: "3px",
    },
    "& .MuiTab-root": {
      justifyContent: "center",
    },

    [theme.breakpoints.down(breakpoint)]: {
      "& .MuiTabs-scroller": {
        height: "50px",
      },
    },
  })
);

//TODO can be also used in dividents
export const ContainerStyledCard = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      p={6}
      gap={6}
      maxWidth={1400}
      width="100%"
      flex={1}
    >
      {children}
    </Box>
  );
};
//TODO can be also used in dividents
export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2.5,
  padding: theme.spacing(6),
  border: "none",
  boxShadow: "0px 0px 2px 0px #00000029, 0px 4px 24px 0px #00000014",
  width: "100%",
  height: "auto",
  flex: 1,
}));

type Props = {
  title: string;
  testId?: string;
  actionButtons?: React.ReactNode;
  currentTab: string;
  onTabChange: (tab: string) => void;
  tabs: {
    title: string;
    key: string;
    body: React.ReactNode;
  }[];
  breakpoint?: Breakpoint | number;
};

export const LayoutWithTabs = ({
  title,
  tabs,
  actionButtons,
  currentTab,
  onTabChange,
  breakpoint = "md",
}: Props) => {
  const ref = useRef<HTMLElement>();
  const activeTabObject = tabs.find((tab) => tab.key === currentTab);

  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLessBreakpoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint)
  );

  return (
    <MainContainer breakpoint={breakpoint}>
      <PanelContainer
        breakpoint={breakpoint}
        flexDirection={isLessBreakpoint ? "column" : "row"}
      >
        <Box
          display="flex"
          alignItems={isLessBreakpoint ? "flex-start" : "center"}
          flexDirection={isLessBreakpoint ? "column" : "row"}
          columnGap={isLessMd ? 6 : 12}
        >
          <Typography variant="h6" component="h1" fontWeight={700}>
            {title}
          </Typography>
          <StyledTabs
            breakpoint={breakpoint}
            value={currentTab}
            onChange={(event: React.SyntheticEvent, value: string) => {
              ref.current?.scrollTo(0, 0);
              onTabChange(value);
            }}
          >
            {tabs.map((tab) => (
              <StyledTab
                disableRipple
                label={tab.title}
                key={tab.key}
                value={tab.key}
              />
            ))}
          </StyledTabs>
        </Box>
        {actionButtons}
      </PanelContainer>
      <Box
        id="layoutWithTabsBody"
        ref={ref}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{
          overflowY: "auto",
          height: "100%",
          minHeight: "0",
        }}
      >
        {activeTabObject?.body}
      </Box>
    </MainContainer>
  );
};
