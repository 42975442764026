import { UseQueryOptions, UseQueryResult } from "react-query";
import {
  useListAndSearchAllAccounts,
  useListPublicAccounts,
} from "@generated/apiv1/hooks";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import {
  Account,
  AccountsApiListAndSearchAllAccountsRequest,
} from "@generated/apiv1";

export const usePublicOrPrivateAccounts = (
  params: AccountsApiListAndSearchAllAccountsRequest = {},
  options: UseQueryOptions<Account[], AxiosApiError, Account[]> = {}
): UseQueryResult<Account[]> => {
  const enabled = options.enabled ?? true;
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();

  const { data: accounts, ...rest } = useListAndSearchAllAccounts(params, {
    enabled: enabled && !isSharedPortfolio,
  });
  const { data: publicAccounts, ...publicRest } = useListPublicAccounts(
    {
      publicId: publicId!,
      ...params,
    },
    {
      enabled: enabled && isSharedPortfolio,
    }
  );

  return {
    ...(isSharedPortfolio ? publicRest : rest),
    data: isSharedPortfolio
      ? publicAccounts?.publicAccounts
      : accounts?.accounts,
  } as UseQueryResult<Account[]>;
};
