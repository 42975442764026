import { withToken } from "@api/auth/withToken";
import {
  AddOrUpdateBankConnectionParams,
  AddOrUpdateBankConnectionResult,
  BankConnection,
  BankConnectionList,
  BankSynchronizationProcess,
  EditBankConnectionParams,
  GetBankConnectionParams,
} from "@api/v1/model";
import { axiosInstanceV1, axiosInstanceV2 } from "@api/axiosInstance";
import { SynchronizationBatchJob } from "@api/v1/methods/synchronizationBatchJob";

export const addBankConnection = async (
  token: string | undefined,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV1.post<AddOrUpdateBankConnectionResult>(
    `/bankConnections`,
    addOrUpdateBankConnectionParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const importCashbackConnections = async (token: string | undefined) => {
  return axiosInstanceV1.post<SynchronizationBatchJob>(
    `/bankConnections/cashback`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteCashbackConnections = async (token: string | undefined) => {
  return axiosInstanceV1.delete(`/bankConnections/cashback`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateBankConnection = async (
  token: string | undefined,
  id: number,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV1.put<AddOrUpdateBankConnectionResult>(
    `/bankConnections/${id}/update`,
    addOrUpdateBankConnectionParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const editBankConnection = async (
  token: string | undefined,
  id: number,
  editBankConnectionParams: EditBankConnectionParams
) => {
  return axiosInstanceV1.put<BankConnection>(
    `/bankConnections/${id}`,
    editBankConnectionParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getBankConnections = async (
  token: string | undefined,
  params: GetBankConnectionParams
) => {
  const { data } = await axiosInstanceV1.get<BankConnectionList>(
    `/bankConnections`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    }
  );
  return data;
};

export const addOrUpdateBankConnectionViaTppWebForm = async (
  id: string,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV1.post<AddOrUpdateBankConnectionResult>(
    `/bankConnections/webFormFlow/${id}`,
    addOrUpdateBankConnectionParams
  );
};

export const editBankConnectionViaTppWebForm = async (
  id: string,
  editBankConnectionParams: EditBankConnectionParams
) => {
  return axiosInstanceV1.put<BankConnection>(
    `/bankConnections/webFormFlow/${id}`,
    editBankConnectionParams
  );
};

export const addBankConnectionV2 = async (
  token: string | undefined,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV2.post<BankSynchronizationProcess>(
    `/bankConnections`,
    addOrUpdateBankConnectionParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateBankConnectionV2 = async (
  token: string | undefined,
  id: number,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV2.put<BankSynchronizationProcess>(
    `/bankConnections/${id}/update`,
    addOrUpdateBankConnectionParams,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getUpdateProcessV2 = async (
  token: string | undefined,
  processId: string
) => {
  return axiosInstanceV2.get<AddOrUpdateBankConnectionResult>(
    `/bankConnections/updateProcess/${processId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addOrUpdateBankConnectionViaTppWebFormV2 = async (
  id: string,
  addOrUpdateBankConnectionParams: AddOrUpdateBankConnectionParams
) => {
  return axiosInstanceV2.post<BankSynchronizationProcess>(
    `/bankConnections/webFormFlow/${id}`,
    addOrUpdateBankConnectionParams
  );
};

export const getUpdateProcessViaTppWebFormV2 = async (
  id: string,
  processId: string
) => {
  return axiosInstanceV2.get<AddOrUpdateBankConnectionResult>(
    `/bankConnections/webFormFlow/${id}/updateProcess/${processId}`
  );
};

export const addBankConnectionWithToken = withToken(addBankConnection);
export const updateBankConnectionWithToken = withToken(updateBankConnection);
export const addBankConnectionV2WithToken = withToken(addBankConnectionV2);
export const updateBankConnectionV2WithToken = withToken(
  updateBankConnectionV2
);
export const importCashbackConnectionWithToken = withToken(
  importCashbackConnections
);
export const editBankConnectionWithToken = withToken(editBankConnection);
export const getBankConnectionsWithToken = withToken(getBankConnections);
export const getUpdateProcessV2WithToken = withToken(getUpdateProcessV2);
