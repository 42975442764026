import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { BookingTypeEnum } from "@generated/apiv1";

export const BookingType = ({ type }: { type: BookingTypeEnum }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const map: Record<
    BookingTypeEnum,
    { backgroundColor?: string; color: string }
  > = {
    buy: {
      backgroundColor: theme.palette.background.success,
      color: theme.palette.text.positive,
    },
    book_in: {
      backgroundColor: theme.palette.background.success,
      color: theme.palette.text.positive,
    },
    sell: {
      backgroundColor: theme.palette.info.lighter,
      color: theme.palette.info.main,
    },
    book_out: {
      backgroundColor: theme.palette.info.lighter,
      color: theme.palette.info.main,
    },
    dividend: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    split: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    reverse_split: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    switch: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    retained_dividend: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    purchase_right: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
    carve_out: {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: map[type]?.backgroundColor || "transparent",
        borderRadius: "4px",
        flexShrink: 0,
      }}
      py={1.5}
      px={1}
    >
      <Typography
        variant="body2"
        color={map[type]?.color || "textPrimary"}
        noWrap
      >
        {t(`bookingTypes.${type}`)}
      </Typography>
    </Box>
  );
};
