import { UseMutationOptions } from "react-query";
import { StripeCheckoutSession } from "@generated/apiv1";

import { buildStripeApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const stripeCreateCustomerPortalSession = async (
  token: string | undefined
) => {
  const apiInstance = buildStripeApi(token);
  const { data } = await apiInstance.stripeCreateCustomerPortalSession();
  return data;
};

export const useStripeCreateCustomerPortalSession = (
  options?: UseMutationOptions<StripeCheckoutSession, AxiosApiError, unknown>
) => {
  return useAuthMutation(stripeCreateCustomerPortalSession, options, false);
};
