import { UseQueryOptions } from "react-query";
import {
  RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest,
  BatchRiskYieldMetrics,
} from "@generated/apiv1";

import { buildRiskYieldMetricsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicBatchRiskYieldMetrics = async (
  token: string | undefined,
  params: RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest
) => {
  const apiInstance = buildRiskYieldMetricsApi(token);
  const { data } = await apiInstance.publicBatchRiskYieldMetrics(params);
  return data;
};

export const usePublicBatchRiskYieldMetrics = (
  params: RiskYieldMetricsApiPublicBatchRiskYieldMetricsRequest,
  options?: UseQueryOptions<
    BatchRiskYieldMetrics,
    AxiosApiError,
    BatchRiskYieldMetrics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.RISKYIELDMETRICS_PUBLIC_BATCH_RISK_YIELD_METRICS, params],
    ({ token }) => publicBatchRiskYieldMetrics(token, params),
    options
  );
};
