export interface ChartData {
  label: string;
  value: number;
}

export const addAreaValues = <TIncomingData>(
  data: TIncomingData[],
  fieldName: keyof TIncomingData,
  multiplier = 0.1
): Array<TIncomingData & { areaValue: [number, number] }> => {
  const values = data.map((val: any) => val[fieldName] as number);

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const delta = (maxValue - minValue) * multiplier;

  return data.map((item: any) => ({
    ...item,
    areaValue: [minValue - delta, item[fieldName]],
  }));
};
