import React, { forwardRef } from "react";
import { Box, MenuItem, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Account } from "@generated/apiv1";
import { ReactComponent as UpdateIcon } from "@icons/clock-rotate-left-solid.svg";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as UpdateHistoryIcon } from "@icons/clock_time.svg";
import { ReactComponent as ManageBankConnectionIcon } from "@icons/building-columns-solid.svg";
import { ReactComponent as HideIcon } from "@icons/eye-cross.svg";
import { ReactComponent as UnhideIcon } from "@icons/eye.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { ReactComponent as TransferIcon } from "@icons/wallet.svg";

interface Props {
  account?: Account;
  handleOpenDeleteModal: () => void;
  handleHideAccount: () => void;
}

export const DepotMenu = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { handleOpenDeleteModal, handleHideAccount, account } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const shouldDisplayUpdateButton =
    Boolean(account?.bankConnectionType) &&
    account?.bankConnectionType !== "NETFONDS";

  return (
    <div ref={ref}>
      {shouldDisplayUpdateButton && (
        <MenuItem
          data-testid="depot-update"
          component={Link}
          to={`/import-bank-connection/redirect?bankConnectionId=${account?.bankConnectionId}&multibankingApiProvider=${account?.bankConnectionType}`}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <UpdateIcon
              width="22px"
              height="22px"
              color={theme.palette.icon.primary}
            />
            {t("depotPage.headerMenu.update")}
          </Box>
        </MenuItem>
      )}
      <MenuItem
        data-testid="edit"
        component={Link}
        to={`/depots/${account?.id}/edit`}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <EditIcon
            width="24px"
            height="24px"
            color={theme.palette.icon.primary}
          />
          {t("depotPage.headerMenu.edit")}
        </Box>
      </MenuItem>
      {shouldDisplayUpdateButton && (
        <MenuItem
          data-testid="synchronization-log"
          component={Link}
          to={`/synchronization-logs/${account?.id}`}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <UpdateHistoryIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />
            {t("depotPage.headerMenu.showUpdateHistory")}
          </Box>
        </MenuItem>
      )}
      {Boolean(account?.bankConnectionType) && (
        <MenuItem
          data-testid="manage-bank-connection"
          component={Link}
          to={`/settings/connections?type=${account?.bankConnectionType}&id=${account?.bankConnectionId}`}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <ManageBankConnectionIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />

            {t("depotPage.headerMenu.manageBankConnection")}
          </Box>
        </MenuItem>
      )}
      <MenuItem
        data-testid="depot-transfer"
        component={Link}
        to={`/depots/${account?.id}/transfer?redirectUrl=${process.env.REACT_APP_V2_URL}/depots/${account?.id}`}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <TransferIcon
            width="24px"
            height="24px"
            color={theme.palette.icon.primary}
          />
          {t("depotPage.headerMenu.transfer")}
        </Box>
      </MenuItem>
      {Boolean(account?.bankConnectionType) && (
        <MenuItem
          data-testid="change-account"
          component={Link}
          to={`/depots/${account?.id}/assign-contra-account`}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <EditIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />

            {t("depotPage.headerMenu.changeAccount")}
          </Box>
        </MenuItem>
      )}
      <MenuItem
        onClick={handleHideAccount}
        data-testid={account?.isHidden ? "unhide-depot" : "hide-depot"}
      >
        <Box display="flex" alignItems="center" gap={3}>
          {account?.isHidden ? (
            <UnhideIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />
          ) : (
            <HideIcon
              width="24px"
              height="24px"
              color={theme.palette.icon.primary}
            />
          )}
          {account?.isHidden
            ? t("depotPage.headerMenu.show")
            : t("depotPage.headerMenu.hide")}
        </Box>
      </MenuItem>
      <MenuItem data-testid="delete">
        <Box
          display="flex"
          alignItems="center"
          onClick={handleOpenDeleteModal}
          color="error.main"
          gap={3}
        >
          <DeleteIcon
            width="24px"
            height="24px"
            color={theme.palette.icon.error}
          />
          {t("depotPage.headerMenu.delete")}
        </Box>
      </MenuItem>
    </div>
  );
});
