import { UseQueryOptions } from "react-query";
import {
  BanksApiInterfaceSelectionOptionRequest,
  InterfaceSelectionOption,
} from "@generated/apiv1";

import { buildBanksApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const interfaceSelectionOption = async (
  token: string | undefined,
  params: BanksApiInterfaceSelectionOptionRequest
) => {
  const apiInstance = buildBanksApi(token);
  const { data } = await apiInstance.interfaceSelectionOption(params);
  return data;
};

export const useInterfaceSelectionOption = (
  params: BanksApiInterfaceSelectionOptionRequest,
  options?: UseQueryOptions<
    InterfaceSelectionOption,
    AxiosApiError,
    InterfaceSelectionOption
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKS_INTERFACE_SELECTION_OPTION, params],
    ({ token }) => interfaceSelectionOption(token, params),
    options
  );
};
