import { UseQueryOptions } from "react-query";
import {
  InsuranceProvidersApiCreateInsuranceProviderRequest,
  InsuranceProvider,
} from "@generated/apiv1";

import { buildInsuranceProvidersApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const createInsuranceProvider = async (
  token: string | undefined,
  params: InsuranceProvidersApiCreateInsuranceProviderRequest
) => {
  const apiInstance = buildInsuranceProvidersApi(token);
  const { data } = await apiInstance.createInsuranceProvider(params);
  return data;
};

export const useCreateInsuranceProvider = (
  params: InsuranceProvidersApiCreateInsuranceProviderRequest,
  options?: UseQueryOptions<InsuranceProvider, AxiosApiError, InsuranceProvider>
) => {
  return useAuthQuery(
    [CACHE_KEYS.INSURANCEPROVIDERS_CREATE_INSURANCE_PROVIDER, params],
    ({ token }) => createInsuranceProvider(token, params),
    options
  );
};
