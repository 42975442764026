import { useMutation, useQuery, UseQueryOptions } from "react-query";
import {
  HistoricQuoteListWithRiskYieldStatistics,
  HistoricQuoteLookupParams,
  Lookup,
} from "@api/v1/model";
import {
  loadHistoricQuotesLookup,
  getHistoricQuotesLookup,
} from "@api/v1/methods";
import {
  GET_HISTORIC_QUOTES,
  GET_HISTORIC_QUOTES_LOOKUP,
} from "../../cacheKeys";

export const useLookupHistoricQuotes = (
  params: HistoricQuoteLookupParams,
  enabled = true
) =>
  useQuery(
    [GET_HISTORIC_QUOTES, JSON.stringify(params)],
    () => loadHistoricQuotesLookup(params),
    {
      enabled: !!params.tickerSymbol && !!params.quoteProvider && enabled,
    }
  );

export const useLookupHistoricQuotesOnDemand = () => {
  return useMutation(loadHistoricQuotesLookup, {
    useErrorBoundary: false,
  });
};

export const useGetHistoricQuotesLookup = (
  params: Lookup,
  options?: UseQueryOptions<
    HistoricQuoteListWithRiskYieldStatistics,
    AxiosApiError,
    HistoricQuoteListWithRiskYieldStatistics,
    (string | Lookup)[]
  >
) =>
  useQuery(
    [GET_HISTORIC_QUOTES_LOOKUP, params],
    () => getHistoricQuotesLookup(params),
    options
  );
