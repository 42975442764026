import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setSelectedCurrencyAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "VALIDATE_BOOKING")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    investmentValues: {
      ...context.investmentValues,
      investmentCurrency: event.currency,
    },
  };
};
