import React from "react";
import { useParams } from "react-router-dom";
import { DepotTransferWizard } from "./DepotTransferWizard";

export const DepotTransferPage = () => {
  const { fromAccountId } = useParams<"fromAccountId">();
  return (
    <DepotTransferWizard fromAccountId={Number.parseInt(fromAccountId!, 10)} />
  );
};
