import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiGetBankConnectionV2Request,
  BankConnectionWrapper,
} from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getBankConnectionV2 = async (
  token: string | undefined,
  params: BankConnectionsApiGetBankConnectionV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.getBankConnectionV2(params);
  return data;
};

export const useGetBankConnectionV2 = (
  params: BankConnectionsApiGetBankConnectionV2Request,
  options?: UseQueryOptions<
    BankConnectionWrapper,
    AxiosApiError,
    BankConnectionWrapper
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_GET_BANK_CONNECTION_V2, params],
    ({ token }) => getBankConnectionV2(token, params),
    options
  );
};
