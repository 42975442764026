import React, {
  ReactNode,
  useState,
  FunctionComponent,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useKey } from "react-use";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { ReactComponent as ArrowUp } from "@icons/arrow-up.svg";
import { CenterScreen, ChangeDataContext, DataContext } from "@components";
import { useGoogleTagManagerVirtualPage } from "@hooks";
import { getStepGroup } from "./helpers/getStepGroup";

export const WizardStepContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 60%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 800px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 90%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 96%;
  }
`;

interface StepContainerProps {
  onNext?: () => void;
  onPrevious?: () => void;
  isFirst?: boolean;
  dependency?: unknown;
  children: ReactNode;
  testId?: string;
  isOptional?: boolean;
  NotificationComponent?: ReactNode;
  HelpComponent?: FunctionComponent<{ onClose: () => void }>;
  helpText?: string;
}

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(9)};
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const ActionsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const HelpLink = styled.a`
  margin-top: ${({ theme }) => theme.spacing(4)};
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.disabled};
  text-decoration: underline;
  cursor: pointer;
`;

const isChildReactElement = (child: ReactNode): child is ReactElement => {
  if (
    !child ||
    typeof child === "string" ||
    typeof child === "boolean" ||
    typeof child === "number"
  ) {
    return false;
  }
  return "props" in child;
};

export const StepContainer = ({
  testId,
  onNext,
  onPrevious,
  dependency,
  isFirst = false,
  children,
  isOptional = false,
  NotificationComponent,
  helpText,
  HelpComponent,
}: StepContainerProps) => {
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useContext(ChangeDataContext);
  const { data } = useContext(DataContext);

  useKey(
    "Enter",
    (event) => {
      if (
        event.target instanceof Element &&
        // don't react on clicking action-previous and next
        !event.target.closest("[data-testid='actions-previous']") &&
        !event.target.closest("[data-testid='next']")
      ) {
        onNext && onNext();
      }
    },
    {},
    [JSON.stringify(dependency)]
  );

  const showSkip = isOptional && !dependency;
  const nextButtonText = showSkip
    ? t("signupWizard.nextButton.skip")
    : t("signupWizard.nextButton.next");

  useEffect(() => {
    if (!dispatch || !testId) return;

    // this is to let Wizard know which step it is currently in
    dispatch({
      type: "step",
      payload: {
        step: testId,
      },
    });
  }, [testId, dispatch]);

  useGoogleTagManagerVirtualPage({
    contextId: testId,
    userFlowId: "cashback-wizard",
    pageTitle: Array.isArray(children)
      ? children.find(
          (child) => isChildReactElement(child) && child.props.question
          // @ts-ignore
        )?.props.question
      : null,
    data: {
      contextGroup: getStepGroup(testId),
      workflow: data?.workflowType || null,
    },
  });

  return (
    <CenterScreen>
      <WizardStepContainer
        data-testid={testId}
        className="wizard-step-container"
      >
        {!showHelp && (
          <>
            {!isFirst && onPrevious && (
              <Header>
                <Button
                  onClick={onPrevious}
                  data-testid="actions-previous"
                  size="small"
                  startIcon={<ArrowUp width="20px" height="20px" />}
                >
                  {t("navigation.previous")}
                </Button>
                {NotificationComponent && NotificationComponent}
              </Header>
            )}
            {children}
            {helpText && HelpComponent && (
              <HelpLink
                data-testid="help-link"
                onClick={() => {
                  setShowHelp(true);
                }}
              >
                {helpText}
              </HelpLink>
            )}
            {onNext && (
              <ActionsContainer>
                <StyledButton
                  id="wizard-submit"
                  onClick={() => onNext()}
                  color={showSkip ? "grey" : "primary"}
                  variant="contained"
                  disableElevation
                  data-testid="next"
                >
                  {nextButtonText}
                </StyledButton>
                <ActionsText variant="body2">
                  {t("signupWizard.orEnter")}
                  <SubdirectoryArrowLeftIcon style={{ fontSize: "1rem" }} />
                </ActionsText>
              </ActionsContainer>
            )}
          </>
        )}
        {showHelp &&
          HelpComponent &&
          HelpComponent({
            onClose: () => {
              setShowHelp(false);
            },
          })}
      </WizardStepContainer>
    </CenterScreen>
  );
};
