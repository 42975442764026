import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendTimelineSummaryRequest,
  DividendTimelineSummary,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendTimelineSummary = async (
  token: string | undefined,
  params?: DividendsApiDividendTimelineSummaryRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendTimelineSummary(params);
  return data;
};

export const useDividendTimelineSummary = (
  params?: DividendsApiDividendTimelineSummaryRequest,
  options?: UseQueryOptions<
    DividendTimelineSummary,
    AxiosApiError,
    DividendTimelineSummary
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_TIMELINE_SUMMARY, params],
    ({ token }) => dividendTimelineSummary(token, params),
    options
  );
};
