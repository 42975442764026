/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { InvestmentQuote } from './investment-quote';
// May contain unused imports in some cases
// @ts-ignore
import { InvestmentSocialStatistics } from './investment-social-statistics';
// May contain unused imports in some cases
// @ts-ignore
import { WatchlistReference } from './watchlist-reference';

/**
 * 
 * @export
 * @interface WatchlistEntry
 */
export interface WatchlistEntry {
    /**
     * Technical identifier
     * @type {number}
     * @memberof WatchlistEntry
     */
    'id'?: number;
    /**
     * The class name of the watchlist entry domain; useful in some cases to distinguish between                                investments and watchlist entries
     * @type {string}
     * @memberof WatchlistEntry
     */
    'className'?: string;
    /**
     * The investments name
     * @type {string}
     * @memberof WatchlistEntry
     */
    'name'?: string;
    /**
     * The investments name in a standardised form
     * @type {string}
     * @memberof WatchlistEntry
     */
    'standardisedName'?: string;
    /**
     * DEPRECATED - this attribute will be removed soon
     * @type {string}
     * @memberof WatchlistEntry
     */
    'jsCompatibleName'?: string;
    /**
     * The investments ISIN
     * @type {string}
     * @memberof WatchlistEntry
     */
    'isin'?: string;
    /**
     * true if the investment has a valid ISIN, false otherwise.             Non-valid ISINs may exist if the investment does not have quote data assigned.
     * @type {boolean}
     * @memberof WatchlistEntry
     */
    'hasValidIsin'?: boolean;
    /**
     * The investments ticker symbol. A ticker symbol is a direct reference to a quote at a                                certain stock exchange. The ticker symbols structure depends on the respective quote                                provider.
     * @type {string}
     * @memberof WatchlistEntry
     */
    'tickerSymbol'?: string;
    /**
     * Same as the ticker symbol, but with the quote currency attached. This allows to                                distinguish investments with the same ticker symbol but a different currency. This may                                happen in cases where we do not have quote data in the investments currency and thus use                                quote conversion based on exchange rates.
     * @type {string}
     * @memberof WatchlistEntry
     */
    'tickerSymbolWithCurrency'?: string;
    /**
     * The investments currency. Note that the investment currency may be different from the                                (native) quote currency if quote currency conversion based on exchange rates is applied.
     * @type {string}
     * @memberof WatchlistEntry
     */
    'currency'?: string;
    /**
     * The investment type
     * @type {string}
     * @memberof WatchlistEntry
     */
    'type'?: WatchlistEntryTypeEnum;
    /**
     * A literal investment type; e.g. 11_stock will have literal type \'Aktien\'
     * @type {string}
     * @memberof WatchlistEntry
     */
    'literalType'?: string;
    /**
     * The date of \'virtual purchase\'
     * @type {string}
     * @memberof WatchlistEntry
     */
    'purchaseDate'?: string;
    /**
     * The watch price / price of the \'virtual purchase\'
     * @type {number}
     * @memberof WatchlistEntry
     */
    'purchasePrice'?: number;
    /**
     * A user defined comment
     * @type {string}
     * @memberof WatchlistEntry
     */
    'comment'?: string;
    /**
     * The return on investment so far; this is purchasePrice - marketValue
     * @type {number}
     * @memberof WatchlistEntry
     */
    'returnOnInvestment'?: number;
    /**
     * The current return on investment in percent
     * @type {number}
     * @memberof WatchlistEntry
     */
    'returnOnInvestmentInPercent'?: number;
    /**
     * The quote one week ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneWeekAgo'?: number;
    /**
     * The percent change now to one week ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneWeekAgoInPercent'?: number;
    /**
     * The quote one month ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneMonthAgo'?: number;
    /**
     * The percent change now to one month ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneMonthAgoInPercent'?: number;
    /**
     * The quote three months ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'threeMonthAgo'?: number;
    /**
     * The percent change now to three months ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'threeMonthAgoInPercent'?: number;
    /**
     * The quote six months ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'sixMonthAgo'?: number;
    /**
     * The percent change now to six months ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'sixMonthAgoInPercent'?: number;
    /**
     * The quote one year ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneYearAgo'?: number;
    /**
     * The percent change now to one year ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'oneYearAgoInPercent'?: number;
    /**
     * The quote two years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'twoYearsAgo'?: number;
    /**
     * The percent change now to two years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'twoYearsAgoInPercent'?: number;
    /**
     * The quote three years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'threeYearsAgo'?: number;
    /**
     * The percent change now to three years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'threeYearsAgoInPercent'?: number;
    /**
     * The quote four years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'fourYearsAgo'?: number;
    /**
     * The percent change now to four years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'fourYearsAgoInPercent'?: number;
    /**
     * The quote five years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'fiveYearsAgo'?: number;
    /**
     * The percent change now to five years ago
     * @type {number}
     * @memberof WatchlistEntry
     */
    'fiveYearsAgoInPercent'?: number;
    /**
     * 
     * @type {InvestmentQuote}
     * @memberof WatchlistEntry
     */
    'quote'?: InvestmentQuote;
    /**
     * 
     * @type {InvestmentSocialStatistics}
     * @memberof WatchlistEntry
     */
    'socialStatistics'?: InvestmentSocialStatistics;
    /**
     * 
     * @type {WatchlistReference}
     * @memberof WatchlistEntry
     */
    'watchlistReference'?: WatchlistReference;
}

export const WatchlistEntryTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond',
    _41_CASH: '41_cash',
    _51_CERTOS: '51_certos',
    _61_PMETALS: '61_pmetals',
    _71_MASSETS: '71_massets',
    _81_FCURR: '81_fcurr',
    _22_ETF: '22_etf',
    _91_MANAGED: '91_managed'
} as const;

export type WatchlistEntryTypeEnum = typeof WatchlistEntryTypeEnum[keyof typeof WatchlistEntryTypeEnum];


