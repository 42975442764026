import {
  addMonths,
  addYears,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  startOfMonth,
  subYears,
} from "date-fns";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useCashflowAnalytics } from "@features/cashflowAnalyzer/hooks/useCashflow";
import { BarChartItem } from "@components";
import { IncomeAndSpendingHistory } from "@generated/apiv1";

const generateZeroValuesChartData = (startDate: Date, endDate: Date) => {
  return Array(Math.abs(differenceInCalendarMonths(startDate, endDate)) + 1)
    .fill(undefined)
    .map((_, index) => {
      const date = addMonths(startDate, index);
      return {
        id: format(date, "yyyy-MM-dd"),
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
        label: format(date, "MMM"),
        value: 0,
        value2: 0,
      };
    });
};

const transformToChartData = (
  history: IncomeAndSpendingHistory,
  startDate: Date,
  endDate: Date
) => {
  const {
    averageRegularIncome,
    averageNonRegularIncome,
    averageRegularExpenses,
    averageNonRegularExpenses,
  } = history;

  const data = averageRegularIncome?.extendedDateValuePairs?.reduce(
    (acc, item) => {
      if (!item.date) return acc;

      return [
        ...acc,
        {
          id: format(new Date(item.date!), "yyyy-MM-dd"),
          startDate: startOfMonth(new Date(item.date!)),
          endDate: endOfMonth(new Date(item.date!)),
          label: format(new Date(item.date!), "MMM")
            .replace(".", "")
            .toUpperCase(),
          value:
            Math.abs(
              averageRegularExpenses?.extendedDateValuePairs?.find(
                (subItem) => subItem.date === item.date
              )?.value || 0
            ) +
            Math.abs(
              averageNonRegularExpenses?.extendedDateValuePairs?.find(
                (subItem) => subItem.date === item.date
              )?.value || 0
            ),
          value2:
            (item.value || 0) +
            (averageNonRegularIncome?.extendedDateValuePairs?.find(
              (subItem) => subItem.date === item.date
            )?.value || 0),
        },
      ];
    },
    [] as BarChartItem[]
  );

  if (!data) return generateZeroValuesChartData(startDate, endDate);

  return orderBy(data, "startDate", "asc");
};

export const useCashflowChartData = (
  accountIds: number[],
  startDate: Date,
  endDate: Date
) => {
  const { data: cashflowAnalytics = {}, isLoading } = useCashflowAnalytics(
    {
      id: accountIds,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
    },
    {
      enabled: !!accountIds && accountIds.length > 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const { data: cashflowAnalyticsPreviousYear = {} } = useCashflowAnalytics(
    {
      id: accountIds,
      from: format(subYears(startDate, 1), "yyyy-MM-dd"),
      to: format(subYears(endDate, 1), "yyyy-MM-dd"),
    },
    {
      enabled: !!accountIds && accountIds.length > 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: cashflowAnalyticsNextYear = {} } = useCashflowAnalytics(
    {
      id: accountIds,
      from: format(addYears(startDate, 1), "yyyy-MM-dd"),
      to: format(addYears(endDate, 1), "yyyy-MM-dd"),
    },
    {
      enabled: !!accountIds && accountIds.length > 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const chartData = useMemo(
    () => transformToChartData(cashflowAnalytics, startDate, endDate),
    [cashflowAnalytics, startDate, endDate]
  );
  const previousYearChartData = useMemo(
    () =>
      transformToChartData(cashflowAnalyticsPreviousYear, startDate, endDate),
    [cashflowAnalyticsPreviousYear, startDate, endDate]
  );
  const nextYearChartData = useMemo(
    () => transformToChartData(cashflowAnalyticsNextYear, startDate, endDate),
    [cashflowAnalyticsNextYear, startDate, endDate]
  );

  return {
    chartData,
    previousYearChartData,
    nextYearChartData,
    isLoading,
  };
};
