import { UseMutationOptions } from "react-query";
import { ReservationsApiCreateReservationRequest } from "@generated/apiv1";

import { buildReservationsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createReservation = async (
  token: string | undefined,
  params: ReservationsApiCreateReservationRequest
) => {
  const apiInstance = buildReservationsApi(token);
  const { data } = await apiInstance.createReservation(params);
  return data;
};

export const useCreateReservation = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    ReservationsApiCreateReservationRequest
  >
) => {
  return useAuthMutation(createReservation, options, false);
};
