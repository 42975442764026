import { UseQueryOptions } from "react-query";
import {
  TppCredentialsApiListAndSearchAllTppCredentialsRequest,
  PageableTppCredentialResources,
} from "@generated/apiv1";

import { buildTppCredentialsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllTppCredentials = async (
  token: string | undefined,
  params?: TppCredentialsApiListAndSearchAllTppCredentialsRequest
) => {
  const apiInstance = buildTppCredentialsApi(token);
  const { data } = await apiInstance.listAndSearchAllTppCredentials(params);
  return data;
};

export const useListAndSearchAllTppCredentials = (
  params?: TppCredentialsApiListAndSearchAllTppCredentialsRequest,
  options?: UseQueryOptions<
    PageableTppCredentialResources,
    AxiosApiError,
    PageableTppCredentialResources
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.TPPCREDENTIALS_LIST_AND_SEARCH_ALL_TPP_CREDENTIALS, params],
    ({ token }) => listAndSearchAllTppCredentials(token, params),
    options
  );
};
