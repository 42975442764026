import React from "react";
import { useInvestment } from "@api/v1/hooks/useInvestment";
import { useInvestmentPerformance } from "../hooks/useInvestmentPerformance";
import { useRealizedGains } from "../hooks/useRealizedGains";
import { GainLossStatistics } from "./GainLossStatistics";

interface Props {
  investmentId: number;
}

export const GainLossStatisticsLoader = ({ investmentId }: Props) => {
  const { data: investment, isLoading: isInvestmentLoading } =
    useInvestment(investmentId);

  const {
    data: realizedGainByBooking,
    isLoading: isRealizedGainByBookingLoading,
  } = useRealizedGains(investmentId);

  const {
    data: investmentPerformance,
    isLoading: isInvestmentPerformanceLoading,
  } = useInvestmentPerformance(investmentId);

  const isLoading =
    isInvestmentLoading ||
    isInvestmentPerformanceLoading ||
    isRealizedGainByBookingLoading;

  return (
    <GainLossStatistics
      isLoading={isLoading}
      investment={investment}
      realizedGainByBooking={realizedGainByBooking}
      investmentPerformance={investmentPerformance}
    />
  );
};
