import { UseQueryOptions } from "react-query";
import {
  TppWebFormsApiTppWebFormsIdRequest,
  TppWebForm,
} from "@generated/apiv1";

import { buildTppWebFormsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const tppWebFormsId = async (
  token: string | undefined,
  params: TppWebFormsApiTppWebFormsIdRequest
) => {
  const apiInstance = buildTppWebFormsApi(token);
  const { data } = await apiInstance.tppWebFormsId(params);
  return data;
};

export const useTppWebFormsId = (
  params: TppWebFormsApiTppWebFormsIdRequest,
  options?: UseQueryOptions<TppWebForm, AxiosApiError, TppWebForm>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TPPWEBFORMS_TPP_WEB_FORMS_ID, params],
    ({ token }) => tppWebFormsId(token, params),
    options
  );
};
