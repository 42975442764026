import { UseQueryOptions } from "react-query";
import {
  SubscriptionAnalyticsApiSubscriptionsByMonthRequest,
  DateValuePairList,
} from "@generated/apiv1";

import { buildSubscriptionAnalyticsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const subscriptionsByMonth = async (
  token: string | undefined,
  params?: SubscriptionAnalyticsApiSubscriptionsByMonthRequest
) => {
  const apiInstance = buildSubscriptionAnalyticsApi(token);
  const { data } = await apiInstance.subscriptionsByMonth(params);
  return data;
};

export const useSubscriptionsByMonth = (
  params?: SubscriptionAnalyticsApiSubscriptionsByMonthRequest,
  options?: UseQueryOptions<DateValuePairList, AxiosApiError, DateValuePairList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONANALYTICS_SUBSCRIPTIONS_BY_MONTH, params],
    ({ token }) => subscriptionsByMonth(token, params),
    options
  );
};
