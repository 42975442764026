import { UseQueryOptions } from "react-query";
import {
  ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest,
  IndustryBreakdownList,
} from "@generated/apiv1";

import { buildClassificationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listIndustryClassificationsForAllInvestments = async (
  token: string | undefined,
  params?: ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest
) => {
  const apiInstance = buildClassificationsApi(token);
  const { data } =
    await apiInstance.listIndustryClassificationsForAllInvestments(params);
  return data;
};

export const useListIndustryClassificationsForAllInvestments = (
  params?: ClassificationsApiListIndustryClassificationsForAllInvestmentsRequest,
  options?: UseQueryOptions<
    IndustryBreakdownList,
    AxiosApiError,
    IndustryBreakdownList
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.CLASSIFICATIONS_LIST_INDUSTRY_CLASSIFICATIONS_FOR_ALL_INVESTMENTS,
      params,
    ],
    ({ token }) => listIndustryClassificationsForAllInvestments(token, params),
    options
  );
};
