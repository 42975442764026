import { uniq } from "lodash";
import { ParsingResult } from "../components/ParsingResult";

export const extractIsins = (parsingResults: ParsingResult[]) => {
  return extractIsinsOrWkns(parsingResults, "isin");
};

export const extractWkns = (parsingResults: ParsingResult[]) => {
  return extractIsinsOrWkns(parsingResults, "wkn");
};

export const extractIsinsAndWkns = (parsingResults: ParsingResult[]) => {
  return [
    ...extractIsinsOrWkns(parsingResults, "isin"),
    ...extractIsinsOrWkns(parsingResults, "wkn"),
  ];
};

export const extractTickerSymbols = (parsingResults: ParsingResult[]) => {
  if (!parsingResults?.length) return [];
  let values: string[] = [];
  parsingResults.forEach((parsingResult) => {
    if (!parsingResult.activities?.length) return null;
    parsingResult.activities.forEach((activity) => {
      if (!activity.cryptoSymbol) return;
      values.push(`${activity.cryptoSymbol}_to_EUR`);
    });
  });
  return uniq(values);
};

const extractIsinsOrWkns = (
  parsingResults: ParsingResult[],
  attribute: "isin" | "wkn"
) => {
  if (!parsingResults?.length) return [];
  let values: string[] = [];
  parsingResults.forEach((parsingResult) => {
    if (!parsingResult.activities?.length) return null;
    parsingResult.activities.forEach((activity) => {
      if (!activity[attribute]) return;
      values.push(activity[attribute]!);
    });
  });
  return uniq(values);
};
