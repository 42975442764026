import {
  BankControllerApi,
  FileUploadControllerApi,
} from "@generated/apiv1/pdfParser";

import { withToken } from "@api/auth/withToken";
import {
  buildAnonymousConfiguration,
  buildConfiguration,
} from "@api/v1/pdfParser/buildConfiguration";

export const buildBankControllerApiWithToken = withToken(
  async (token: string) => new BankControllerApi(buildConfiguration(token))
);
export const buildAnonymousBankControllerApi = () =>
  new BankControllerApi(buildAnonymousConfiguration());
export const buildBankControllerApi = (token?: string) =>
  new BankControllerApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );

export const buildFileUploadControllerApiWithToken = withToken(
  async (token: string) =>
    new FileUploadControllerApi(buildConfiguration(token))
);
export const buildAnonymousFileUploadControllerApi = () =>
  new FileUploadControllerApi(buildAnonymousConfiguration());
export const buildFileUploadControllerApi = (token?: string) =>
  new FileUploadControllerApi(
    token ? buildConfiguration(token) : buildAnonymousConfiguration()
  );
