import { UseMutationOptions } from "react-query";
import { UserProfilesApiUnsubscribeFromNewsletterRequest } from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const unsubscribeFromNewsletter = async (
  token: string | undefined,
  params: UserProfilesApiUnsubscribeFromNewsletterRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.unsubscribeFromNewsletter(params);
  return data;
};

export const useUnsubscribeFromNewsletter = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UserProfilesApiUnsubscribeFromNewsletterRequest
  >
) => {
  return useAuthMutation(unsubscribeFromNewsletter, options, false);
};
