import { useTheme } from "@emotion/react";
import React from "react";
import { InvestmentChartData } from "@components/Chart/InvestmentChart/types";
import { getEventLabelForEvents } from "./getEventLabel";

interface Props {
  cx?: number;
  cy?: number;
  stroke?: string;
  fill?: string;
  payload?: InvestmentChartData;
}

export const EventDot = (props: Props) => {
  const { cx = 0, cy = 0, stroke, fill, payload } = props;
  const theme = useTheme();

  if (payload?.events == null || payload?.events.length === 0) {
    return null;
  }

  const label = getEventLabelForEvents(payload.events, theme.palette);

  return (
    <svg x={cx - 15} y={cy - 31} width={30} height={30} viewBox="0 0 100 100">
      <g>
        <path
          strokeWidth="2px"
          d="M47.423 1.06398C43.298 1.65898 40.4 2.48398 37.618 3.88498C29.501 7.95298 23.611 15.667 21.807 24.57C20.694 29.981 21.059 37.196 22.786 43.988C25.146 53.352 29.118 61.89 40.9 82.882C42.704 86.106 45.467 91.037 47.04 93.858C48.633 96.679 49.957 99 50.014 99C50.071 99 50.666 97.983 51.338 96.736C52.029 95.508 54.965 90.174 57.881 84.897C65.019 72.003 66.516 69.259 68.953 64.442C74.882 52.757 77.549 44.985 78.604 36.351C78.969 33.453 78.873 28.234 78.431 25.702C77.337 19.427 74.459 13.863 69.969 9.39198C65.824 5.22798 61.027 2.63798 55.405 1.50598C53.64 1.14098 48.709 0.871985 47.423 1.06398ZM54.158 4.49898C64.136 6.24498 72.329 14.055 74.843 24.186C75.726 27.774 75.918 30.825 75.534 35.008C74.632 44.698 71.044 54.139 61.911 70.986C58.591 77.106 50.225 92.15 50.052 92.303C49.918 92.437 43.566 81.251 38.27 71.599C35.257 66.092 31.266 58.071 29.655 54.291C28.178 50.78 26.201 44.889 25.568 42.011C24.666 37.886 24.417 35.64 24.417 31.554C24.398 26.527 24.916 23.821 26.604 19.926C30.077 11.925 37.407 6.01498 45.715 4.51798C47.788 4.15298 52.047 4.13398 54.158 4.49898Z"
          stroke={stroke}
          fill={fill}
        />
        <text
          x={50}
          y={50}
          fill={label?.color}
          textAnchor="middle"
          style={{
            fontSize: label?.name.length > 1 ? "32px" : "40px",
            fontWeight: 800,
          }}
        >
          {label?.name}
        </text>
      </g>
    </svg>
  );
};
