import { UseQueryOptions } from "react-query";
import {
  DividendsApiPublicDividendCalendarRequest,
  DividendCalendarRecordList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const publicDividendCalendar = async (
  token: string | undefined,
  params: DividendsApiPublicDividendCalendarRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.publicDividendCalendar(params);
  return data;
};

export const usePublicDividendCalendar = (
  params: DividendsApiPublicDividendCalendarRequest,
  options?: UseQueryOptions<
    DividendCalendarRecordList,
    AxiosApiError,
    DividendCalendarRecordList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_PUBLIC_DIVIDEND_CALENDAR, params],
    ({ token }) => publicDividendCalendar(token, params),
    options
  );
};
