import { useMediaQuery, Box } from "@mui/material";
import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { css, useTheme } from "@emotion/react";
import { Helmet } from "react-helmet";
import { useMyself } from "@api/auth";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { UserWithType } from "@api";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { AppSidebar } from "../AppSidebar";

const Backdrop = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Content = styled("div")<{
  collapsed?: boolean;
  isMobile: boolean;
}>`
  ${({ collapsed, isMobile }) => {
    if (isMobile)
      return css`
        padding-left: 0;
      `;

    return collapsed
      ? css`
          padding-left: 55px;
        `
      : css`
          padding-left: 300px;
        `;
  }}
`;

export interface AppLayoutProps {
  path?: string;
  mainMenuKey?: string;
  subMenuKey?: string;
  children?: ReactNode;
  sidebar?: React.ComponentType<{
    collapsed: boolean;
    onCollapseChange: () => void;
    user?: UserWithType;
    collapseOnSelect: boolean;
  }>;
}

export const AppLayout = ({
  children,
  sidebar = AppSidebar,
}: AppLayoutProps) => {
  const { data: myself } = useMyself();
  const { isSharedPortfolio } = useSharedPortfolioContext();
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const { sidebarCollapsed, toggleSidebarCollapsed } = useAppLayout();

  const Sidebar = sidebar;

  return (
    <Box position="relative">
      {isSharedPortfolio && (
        <Helmet>
          <meta
            property="og:image"
            content="https://content.finanzfluss.de/wp-content/uploads/2024/03/website-og-image-copilot-shared-portfolio.jpg"
          />
        </Helmet>
      )}
      <Sidebar
        collapsed={sidebarCollapsed}
        onCollapseChange={toggleSidebarCollapsed}
        user={myself}
        collapseOnSelect={isSm && !isXs}
      />
      <Backdrop
        animate={{ opacity: isSm && !isXs && !sidebarCollapsed ? 0.2 : 1 }}
      >
        <Content collapsed={sidebarCollapsed} isMobile={isXs}>
          {children ? children : <Outlet />}
        </Content>
      </Backdrop>
    </Box>
  );
};
