/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * Technical identifier
     * @type {number}
     * @memberof Account
     */
    'id': number;
    /**
     * The high-level account type; as assigned by the Rentablo SaaS platform. This type             defines the behaviour of and the actions available on this account. I.e. only a depot may have investments and             only a cash account has a balance.
     * @type {string}
     * @memberof Account
     */
    'type': AccountTypeEnum;
    /**
     * true if this account is marked hidden
     * @type {boolean}
     * @memberof Account
     */
    'isHidden'?: boolean;
    /**
     * The detailed type of the account as defined by the account holding bank. This will be             null if not provided or the account is manually maintained.
     * @type {string}
     * @memberof Account
     */
    'typeFromBank'?: AccountTypeFromBankEnum;
    /**
     * The ID of the contra account; only available if this is a depot. Each booking on a                                depot will result in a transaction on the contra account.
     * @type {number}
     * @memberof Account
     */
    'contraAccountId'?: number;
    /**
     * If this is a contra account: the IDs of all attached depots.
     * @type {Array<number>}
     * @memberof Account
     */
    'depotIds'?: Array<number>;
    /**
     * The literal account name
     * @type {string}
     * @memberof Account
     */
    'name': string;
    /**
     * The account balance
     * @type {number}
     * @memberof Account
     */
    'balance'?: number;
    /**
     * The account currency (3 letter ISO code)
     * @type {string}
     * @memberof Account
     */
    'currency': string;
    /**
     * A default setting for the accounts tax rate; this may be used when deducting taxes.
     * @type {number}
     * @memberof Account
     */
    'taxRate'?: number;
    /**
     * A default setting fro the accounts booking commission; this may be used when applying commissions.
     * @type {number}
     * @memberof Account
     */
    'defaultCommission'?: number;
    /**
     * Does this account have implicit cash management enabled; in this case bookings resulting                                in a negative balance will automatically create implicit transactions.
     * @type {boolean}
     * @memberof Account
     */
    'implicitTransactions'?: boolean;
    /**
     * The accounts external ID (finAPI, Netfonds, others); if any.
     * @type {string}
     * @memberof Account
     */
    'externalId'?: string;
    /**
     * DEPRECATED: use externalId instead! The accounts finAPI ID (if linked to a bank).
     * @type {number}
     * @memberof Account
     */
    'finApiId'?: number;
    /**
     * The accounts bank connection ID (if linked to a bank via finAPI).
     * @type {number}
     * @memberof Account
     */
    'bankConnectionId'?: number;
    /**
     * The accounts bank connection type (if linked to a bank).
     * @type {string}
     * @memberof Account
     */
    'bankConnectionType'?: AccountBankConnectionTypeEnum;
    /**
     * The accounts preferred bank connection interface (if any).
     * @type {string}
     * @memberof Account
     */
    'preferredInterface'?: AccountPreferredInterfaceEnum;
    /**
     * The accounts literal bank name; if linked to a bank or defined by the user.
     * @type {string}
     * @memberof Account
     */
    'bankName'?: string;
    /**
     * The accounts BIC; if linked to a bank or defined by the user.
     * @type {string}
     * @memberof Account
     */
    'bankCode'?: string;
    /**
     * DEPRECATED: this field name is misleading; use bicGroup instead.             A grouping of banks to a common slug like \'SPARKASSE\'. Will contain the BIC itself             if the given bank has only a single BIC.
     * @type {string}
     * @memberof Account
     */
    'bankGroup'?: string;
    /**
     * A grouping of banks to a common slug like \'SPARKASSE\'. Will contain the BIC itself             if the given bank has only a single BIC.
     * @type {string}
     * @memberof Account
     */
    'bicGroup'?: string;
    /**
     * Url to display the bank icon.
     * @type {string}
     * @memberof Account
     */
    'iconUrl': string;
    /**
     * The accounts IBAN
     * @type {string}
     * @memberof Account
     */
    'iban'?: string;
    /**
     * The account holder name
     * @type {string}
     * @memberof Account
     */
    'accountHolderName'?: string;
    /**
     * Bank\'s internal identification of the account holder.
     * @type {string}
     * @memberof Account
     */
    'accountHolderIdentification'?: string;
    /**
     * (National) account number
     * @type {string}
     * @memberof Account
     */
    'accountNumber'?: string;
    /**
     * Account\'s sub-account-number
     * @type {string}
     * @memberof Account
     */
    'subAccountNumber'?: string;
    /**
     * The earliest balance that we got at the start of the very first bank synchronization run
     * @type {number}
     * @memberof Account
     */
    'initialBalance'?: number;
    /**
     * Date of this very first balance
     * @type {string}
     * @memberof Account
     */
    'initialBalanceDate'?: string;
    /**
     * The number of active assets; this may be investments, material assets or insurances
     * @type {number}
     * @memberof Account
     */
    'activeAssets'?: number;
    /**
     * Date of the last manual synchronization
     * @type {string}
     * @memberof Account
     */
    'lastManualSyncDate'?: string;
    /**
     * Date of the last automatic (via background job) synchronization
     * @type {string}
     * @memberof Account
     */
    'lastAutoSyncDate'?: string;
    /**
     * Date of the last manual or automatic synchronization
     * @type {string}
     * @memberof Account
     */
    'lastSyncDate'?: string;
    /**
     * Does the account have synchronization issues? This is typically a deviation between                                the security positions maintained locally and those delivered by the bank. Issues                                will typically resolved using a manual synchronization step
     * @type {boolean}
     * @memberof Account
     */
    'hasSynchronizationIssues'?: boolean;
    /**
     * DEPRECATED: use typeFromBank instead!                                Detailed type of the bank account; only available if linked to a bank. Type mappings                                are 1:checking, 2:savings, 3:creditCard, 4:security, 5:loan, 6:pocket, 7:membership,                                8:bausparen.
     * @type {number}
     * @memberof Account
     */
    'bankAccountTypeId'?: AccountBankAccountTypeIdEnum;
    /**
     * A user defined sub-category
     * @type {number}
     * @memberof Account
     */
    'subCategoryId'?: number;
    /**
     * The visibility status; default is private
     * @type {string}
     * @memberof Account
     */
    'visibility'?: AccountVisibilityEnum;
    /**
     * The id for public portfolio sharing
     * @type {string}
     * @memberof Account
     */
    'publicId'?: string;
}

export const AccountTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;

export type AccountTypeEnum = typeof AccountTypeEnum[keyof typeof AccountTypeEnum];
export const AccountTypeFromBankEnum = {
    CHECKING: 'CHECKING',
    SAVINGS: 'SAVINGS',
    CREDIT_CARD: 'CREDIT_CARD',
    DEPOT: 'DEPOT',
    LOAN: 'LOAN',
    MEMBERSHIP: 'MEMBERSHIP',
    HOME_PURCHASE_SAVINGS: 'HOME_PURCHASE_SAVINGS',
    REFERENCE_ACCOUNT: 'REFERENCE_ACCOUNT',
    FOREIGN_CURRENCY_ACCOUNT: 'FOREIGN_CURRENCY_ACCOUNT'
} as const;

export type AccountTypeFromBankEnum = typeof AccountTypeFromBankEnum[keyof typeof AccountTypeFromBankEnum];
export const AccountBankConnectionTypeEnum = {
    WEALTH_API: 'WEALTH_API',
    FIN_API: 'FIN_API',
    NETFONDS: 'NETFONDS'
} as const;

export type AccountBankConnectionTypeEnum = typeof AccountBankConnectionTypeEnum[keyof typeof AccountBankConnectionTypeEnum];
export const AccountPreferredInterfaceEnum = {
    XS2_A: 'XS2A',
    FINTS_SERVER: 'FINTS_SERVER',
    WEB_SCRAPER: 'WEB_SCRAPER'
} as const;

export type AccountPreferredInterfaceEnum = typeof AccountPreferredInterfaceEnum[keyof typeof AccountPreferredInterfaceEnum];
export const AccountBankAccountTypeIdEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_21: 21,
    NUMBER_22: 22
} as const;

export type AccountBankAccountTypeIdEnum = typeof AccountBankAccountTypeIdEnum[keyof typeof AccountBankAccountTypeIdEnum];
export const AccountVisibilityEnum = {
    PRIVATE: 'private',
    PUBLIC: 'public'
} as const;

export type AccountVisibilityEnum = typeof AccountVisibilityEnum[keyof typeof AccountVisibilityEnum];


