import { UseMutationOptions } from "react-query";
import { TransactionsApiDeleteTransactionByExternalIdRequest } from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteTransactionByExternalId = async (
  token: string | undefined,
  params: TransactionsApiDeleteTransactionByExternalIdRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.deleteTransactionByExternalId(params);
  return data;
};

export const useDeleteTransactionByExternalId = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    TransactionsApiDeleteTransactionByExternalIdRequest
  >
) => {
  return useAuthMutation(deleteTransactionByExternalId, options, false);
};
