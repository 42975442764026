/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateBatchTransactionParams } from '../model';
// @ts-ignore
import { CreateOrUpdateTransactionParams } from '../model';
// @ts-ignore
import { IdentifierList } from '../model';
// @ts-ignore
import { PageableTransactionList } from '../model';
// @ts-ignore
import { Tag } from '../model';
// @ts-ignore
import { Transaction } from '../model';
/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign the given transaction category to the given transaction
         * @param {Array<number>} id ID(s) of the transactions to assign a category to
         * @param {number} categoryId ID of the category to assign
         * @param {number} [trainCategorization] Controls whether finAPI\&#39;s categorization system should learn from the given categorization(s).                              If set to \&#39;true\&#39;, then the user\&#39;s categorization rules will be updated so that similar                              transactions will get categorized accordingly in future. If set to \&#39;false\&#39;, then the service                              will simply change the category of the given transaction(s), without updating the user\&#39;s                              categorization rules. The field defaults to \&#39;true\&#39; if not specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCategory: async (id: Array<number>, categoryId: number, trainCategorization?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignCategory', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('assignCategory', 'categoryId', categoryId)
            const localVarPath = `/v1/transactions/assignCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (trainCategorization !== undefined) {
                localVarQueryParameter['trainCategorization'] = trainCategorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a set of cash transactions for the given account.
         * @param {CreateBatchTransactionParams} body The batch transaction creation parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateCashTransactions: async (body: CreateBatchTransactionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('batchCreateCashTransactions', 'body', body)
            const localVarPath = `/v1/transactions/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a cash transaction.
         * @param {CreateOrUpdateTransactionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashTransaction: async (body: CreateOrUpdateTransactionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCashTransaction', 'body', body)
            const localVarPath = `/v1/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a cash Transaction
         * @param {number} id ID of the transaction to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: async (id: number, keepData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransaction', 'id', id)
            const localVarPath = `/v1/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keepData !== undefined) {
                localVarQueryParameter['keepData'] = keepData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a cash Transaction by external ID. This is a convenience routine typically used by                        SaaS processes working on booking data.
         * @param {string} externalId External ID of the transaction to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionByExternalId: async (externalId: string, keepData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('deleteTransactionByExternalId', 'externalId', externalId)
            const localVarPath = `/v1/transactions/{externalId}/byExternalId`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keepData !== undefined) {
                localVarQueryParameter['keepData'] = keepData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detect the ISIN / WKN contained within a transaction purpose.
         * @param {number} id ID of the transaction to detect ISIN or WKN from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idDetectIsinOrWkn: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idDetectIsinOrWkn', 'id', id)
            const localVarPath = `/v1/transactions/{id}/detectIsinOrWkn`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search a list of Transactions
         * @param {Array<number>} [id] Retrieve Transactions with the given IDs
         * @param {Array<number>} [accountId] Retrieve Transactions for the given Accounts
         * @param {Array<number>} [depotId] Retrieve Transactions related to investments residing inside the given depots.                              Note that this parameter will be ignored if you also provide investmentIds.
         * @param {Array<number>} [insuranceId] Retrieve Transactions for the given insurance; setting this parameter will ignore the                              \&#39;accountId\&#39; or \&#39;depotId\&#39; parameter.
         * @param {Array<number>} [investmentId] Retrieve Transactions for the given investments
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Transactions with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Transactions with the given external IDs
         * @param {Array<string>} [type] Retrieve transactions having the given type, allowed values are withdrawal, deposit,                              interest, tax_refund, other_gains.
         * @param {Array<string>} [wkn] Retrieve Transactions belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Transactions belonging to Investments with the given ISINs
         * @param {Array<string>} [tickerSymbol] Retrieve Transactions belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Transactions in the given currencies (3-letter ISO code)
         * @param {number} [minAmount] Retrieve Transactions with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Transactions with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {boolean} [skipManuallyCreatedTransactions] Skip transactions that were manually created (i.e. not imported from a bank).
         * @param {boolean} [includeAdjustingEntries] Include adjusting entries. Adjusting entries are cash transactions created whenever the                              account balance will fall below zero. This is a convenience feature for manually                              maintained depots.
         * @param {ListAndSearchAllTransactionFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTransaction: async (id?: Array<number>, accountId?: Array<number>, depotId?: Array<number>, insuranceId?: Array<number>, investmentId?: Array<number>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<string>, wkn?: Array<string>, isin?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, skipManuallyCreatedTransactions?: boolean, includeAdjustingEntries?: boolean, format?: ListAndSearchAllTransactionFormatEnum, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (accountId) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (depotId) {
                localVarQueryParameter['depotId'] = depotId;
            }

            if (insuranceId) {
                localVarQueryParameter['insuranceId'] = insuranceId;
            }

            if (investmentId) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (finApiId) {
                localVarQueryParameter['finApiId'] = finApiId;
            }

            if (externalId) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (tickerSymbol) {
                localVarQueryParameter['tickerSymbol'] = tickerSymbol;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (minAmount !== undefined) {
                localVarQueryParameter['minAmount'] = minAmount;
            }

            if (maxAmount !== undefined) {
                localVarQueryParameter['maxAmount'] = maxAmount;
            }

            if (minDate !== undefined) {
                localVarQueryParameter['minDate'] = minDate;
            }

            if (maxDate !== undefined) {
                localVarQueryParameter['maxDate'] = maxDate;
            }

            if (skipManuallyCreatedTransactions !== undefined) {
                localVarQueryParameter['skipManuallyCreatedTransactions'] = skipManuallyCreatedTransactions;
            }

            if (includeAdjustingEntries !== undefined) {
                localVarQueryParameter['includeAdjustingEntries'] = includeAdjustingEntries;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get transactions for the given cash account in MT940 format; a widely used SWIFT standard. See                      https://www.sepaforcorporates.com/swift-for-corporates/account-statement-mt940-file-format-overview                      for details on this format.<br>                      Data my be returned in plain format (no brackets) or in the SWIFT message format (with header in                      brackets).
         * @param {number} accountId Retrieve transactions for the given cash Account
         * @param {string} [minDate] Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mt940: async (accountId: number, minDate?: string, maxDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('mt940', 'accountId', accountId)
            const localVarPath = `/v1/transactions/mt940`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (minDate !== undefined) {
                localVarQueryParameter['minDate'] = minDate;
            }

            if (maxDate !== undefined) {
                localVarQueryParameter['maxDate'] = maxDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Transaction
         * @param {number} id ID of the transaction to retrieve
         * @param {TransactionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {boolean} [includeRemoteSepaFields] Include SEPA transaction data stored on the finApi cloud. Note that this will slow                         down the request somewhat, as remote data must be polled in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsId: async (id: number, format?: TransactionsIdFormatEnum, includeRemoteSepaFields?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('transactionsId', 'id', id)
            const localVarPath = `/v1/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (includeRemoteSepaFields !== undefined) {
                localVarQueryParameter['includeRemoteSepaFields'] = includeRemoteSepaFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a cash transaction.
         * @param {number} id ID of the transaction to update
         * @param {CreateOrUpdateTransactionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCashTransaction: async (id: number, body: CreateOrUpdateTransactionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCashTransaction', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCashTransaction', 'body', body)
            const localVarPath = `/v1/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign the given transaction category to the given transaction
         * @param {Array<number>} id ID(s) of the transactions to assign a category to
         * @param {number} categoryId ID of the category to assign
         * @param {number} [trainCategorization] Controls whether finAPI\&#39;s categorization system should learn from the given categorization(s).                              If set to \&#39;true\&#39;, then the user\&#39;s categorization rules will be updated so that similar                              transactions will get categorized accordingly in future. If set to \&#39;false\&#39;, then the service                              will simply change the category of the given transaction(s), without updating the user\&#39;s                              categorization rules. The field defaults to \&#39;true\&#39; if not specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCategory(id: Array<number>, categoryId: number, trainCategorization?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCategory(id, categoryId, trainCategorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.assignCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a set of cash transactions for the given account.
         * @param {CreateBatchTransactionParams} body The batch transaction creation parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchCreateCashTransactions(body: CreateBatchTransactionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchCreateCashTransactions(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.batchCreateCashTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a cash transaction.
         * @param {CreateOrUpdateTransactionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCashTransaction(body: CreateOrUpdateTransactionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCashTransaction(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.createCashTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a cash Transaction
         * @param {number} id ID of the transaction to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransaction(id: number, keepData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransaction(id, keepData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.deleteTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a cash Transaction by external ID. This is a convenience routine typically used by                        SaaS processes working on booking data.
         * @param {string} externalId External ID of the transaction to delete
         * @param {boolean} [keepData] Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransactionByExternalId(externalId: string, keepData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransactionByExternalId(externalId, keepData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.deleteTransactionByExternalId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Detect the ISIN / WKN contained within a transaction purpose.
         * @param {number} id ID of the transaction to detect ISIN or WKN from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idDetectIsinOrWkn(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idDetectIsinOrWkn(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.idDetectIsinOrWkn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search a list of Transactions
         * @param {Array<number>} [id] Retrieve Transactions with the given IDs
         * @param {Array<number>} [accountId] Retrieve Transactions for the given Accounts
         * @param {Array<number>} [depotId] Retrieve Transactions related to investments residing inside the given depots.                              Note that this parameter will be ignored if you also provide investmentIds.
         * @param {Array<number>} [insuranceId] Retrieve Transactions for the given insurance; setting this parameter will ignore the                              \&#39;accountId\&#39; or \&#39;depotId\&#39; parameter.
         * @param {Array<number>} [investmentId] Retrieve Transactions for the given investments
         * @param {Array<number>} [finApiId] (Deprecated - use externalId) Retrieve Transactions with the given finAPI IDs
         * @param {Array<string>} [externalId] Retrieve Transactions with the given external IDs
         * @param {Array<string>} [type] Retrieve transactions having the given type, allowed values are withdrawal, deposit,                              interest, tax_refund, other_gains.
         * @param {Array<string>} [wkn] Retrieve Transactions belonging to Investments with the given WKNs
         * @param {Array<string>} [isin] Retrieve Transactions belonging to Investments with the given ISINs
         * @param {Array<string>} [tickerSymbol] Retrieve Transactions belonging to Investments with the given ticker symbols
         * @param {Array<string>} [currency] Retrieve Transactions in the given currencies (3-letter ISO code)
         * @param {number} [minAmount] Retrieve Transactions with amount greater or equal than the given minAmount
         * @param {number} [maxAmount] Retrieve Transactions with amount less or equal than the given maxAmount
         * @param {string} [minDate] Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {boolean} [skipManuallyCreatedTransactions] Skip transactions that were manually created (i.e. not imported from a bank).
         * @param {boolean} [includeAdjustingEntries] Include adjusting entries. Adjusting entries are cash transactions created whenever the                              account balance will fall below zero. This is a convenience feature for manually                              maintained depots.
         * @param {ListAndSearchAllTransactionFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllTransaction(id?: Array<number>, accountId?: Array<number>, depotId?: Array<number>, insuranceId?: Array<number>, investmentId?: Array<number>, finApiId?: Array<number>, externalId?: Array<string>, type?: Array<string>, wkn?: Array<string>, isin?: Array<string>, tickerSymbol?: Array<string>, currency?: Array<string>, minAmount?: number, maxAmount?: number, minDate?: string, maxDate?: string, skipManuallyCreatedTransactions?: boolean, includeAdjustingEntries?: boolean, format?: ListAndSearchAllTransactionFormatEnum, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableTransactionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllTransaction(id, accountId, depotId, insuranceId, investmentId, finApiId, externalId, type, wkn, isin, tickerSymbol, currency, minAmount, maxAmount, minDate, maxDate, skipManuallyCreatedTransactions, includeAdjustingEntries, format, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.listAndSearchAllTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get transactions for the given cash account in MT940 format; a widely used SWIFT standard. See                      https://www.sepaforcorporates.com/swift-for-corporates/account-statement-mt940-file-format-overview                      for details on this format.<br>                      Data my be returned in plain format (no brackets) or in the SWIFT message format (with header in                      brackets).
         * @param {number} accountId Retrieve transactions for the given cash Account
         * @param {string} [minDate] Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {string} [maxDate] Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mt940(accountId: number, minDate?: string, maxDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mt940(accountId, minDate, maxDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.mt940']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Transaction
         * @param {number} id ID of the transaction to retrieve
         * @param {TransactionsIdFormatEnum} [format] Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
         * @param {boolean} [includeRemoteSepaFields] Include SEPA transaction data stored on the finApi cloud. Note that this will slow                         down the request somewhat, as remote data must be polled in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsId(id: number, format?: TransactionsIdFormatEnum, includeRemoteSepaFields?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsId(id, format, includeRemoteSepaFields, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.transactionsId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a cash transaction.
         * @param {number} id ID of the transaction to update
         * @param {CreateOrUpdateTransactionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCashTransaction(id: number, body: CreateOrUpdateTransactionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCashTransaction(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.updateCashTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign the given transaction category to the given transaction
         * @param {TransactionsApiAssignCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCategory(requestParameters: TransactionsApiAssignCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<IdentifierList> {
            return localVarFp.assignCategory(requestParameters.id, requestParameters.categoryId, requestParameters.trainCategorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a set of cash transactions for the given account.
         * @param {TransactionsApiBatchCreateCashTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateCashTransactions(requestParameters: TransactionsApiBatchCreateCashTransactionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.batchCreateCashTransactions(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a cash transaction.
         * @param {TransactionsApiCreateCashTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashTransaction(requestParameters: TransactionsApiCreateCashTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Transaction> {
            return localVarFp.createCashTransaction(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a cash Transaction
         * @param {TransactionsApiDeleteTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction(requestParameters: TransactionsApiDeleteTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTransaction(requestParameters.id, requestParameters.keepData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a cash Transaction by external ID. This is a convenience routine typically used by                        SaaS processes working on booking data.
         * @param {TransactionsApiDeleteTransactionByExternalIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionByExternalId(requestParameters: TransactionsApiDeleteTransactionByExternalIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTransactionByExternalId(requestParameters.externalId, requestParameters.keepData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detect the ISIN / WKN contained within a transaction purpose.
         * @param {TransactionsApiIdDetectIsinOrWknRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idDetectIsinOrWkn(requestParameters: TransactionsApiIdDetectIsinOrWknRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.idDetectIsinOrWkn(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search a list of Transactions
         * @param {TransactionsApiListAndSearchAllTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllTransaction(requestParameters: TransactionsApiListAndSearchAllTransactionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableTransactionList> {
            return localVarFp.listAndSearchAllTransaction(requestParameters.id, requestParameters.accountId, requestParameters.depotId, requestParameters.insuranceId, requestParameters.investmentId, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.skipManuallyCreatedTransactions, requestParameters.includeAdjustingEntries, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get transactions for the given cash account in MT940 format; a widely used SWIFT standard. See                      https://www.sepaforcorporates.com/swift-for-corporates/account-statement-mt940-file-format-overview                      for details on this format.<br>                      Data my be returned in plain format (no brackets) or in the SWIFT message format (with header in                      brackets).
         * @param {TransactionsApiMt940Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mt940(requestParameters: TransactionsApiMt940Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.mt940(requestParameters.accountId, requestParameters.minDate, requestParameters.maxDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Transaction
         * @param {TransactionsApiTransactionsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsId(requestParameters: TransactionsApiTransactionsIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Transaction> {
            return localVarFp.transactionsId(requestParameters.id, requestParameters.format, requestParameters.includeRemoteSepaFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a cash transaction.
         * @param {TransactionsApiUpdateCashTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCashTransaction(requestParameters: TransactionsApiUpdateCashTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Transaction> {
            return localVarFp.updateCashTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignCategory operation in TransactionsApi.
 * @export
 * @interface TransactionsApiAssignCategoryRequest
 */
export interface TransactionsApiAssignCategoryRequest {
    /**
     * ID(s) of the transactions to assign a category to
     * @type {Array<number>}
     * @memberof TransactionsApiAssignCategory
     */
    readonly id: Array<number>

    /**
     * ID of the category to assign
     * @type {number}
     * @memberof TransactionsApiAssignCategory
     */
    readonly categoryId: number

    /**
     * Controls whether finAPI\&#39;s categorization system should learn from the given categorization(s).                              If set to \&#39;true\&#39;, then the user\&#39;s categorization rules will be updated so that similar                              transactions will get categorized accordingly in future. If set to \&#39;false\&#39;, then the service                              will simply change the category of the given transaction(s), without updating the user\&#39;s                              categorization rules. The field defaults to \&#39;true\&#39; if not specified.
     * @type {number}
     * @memberof TransactionsApiAssignCategory
     */
    readonly trainCategorization?: number
}

/**
 * Request parameters for batchCreateCashTransactions operation in TransactionsApi.
 * @export
 * @interface TransactionsApiBatchCreateCashTransactionsRequest
 */
export interface TransactionsApiBatchCreateCashTransactionsRequest {
    /**
     * The batch transaction creation parameters
     * @type {CreateBatchTransactionParams}
     * @memberof TransactionsApiBatchCreateCashTransactions
     */
    readonly body: CreateBatchTransactionParams
}

/**
 * Request parameters for createCashTransaction operation in TransactionsApi.
 * @export
 * @interface TransactionsApiCreateCashTransactionRequest
 */
export interface TransactionsApiCreateCashTransactionRequest {
    /**
     * The transaction parameters
     * @type {CreateOrUpdateTransactionParams}
     * @memberof TransactionsApiCreateCashTransaction
     */
    readonly body: CreateOrUpdateTransactionParams
}

/**
 * Request parameters for deleteTransaction operation in TransactionsApi.
 * @export
 * @interface TransactionsApiDeleteTransactionRequest
 */
export interface TransactionsApiDeleteTransactionRequest {
    /**
     * ID of the transaction to delete
     * @type {number}
     * @memberof TransactionsApiDeleteTransaction
     */
    readonly id: number

    /**
     * Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
     * @type {boolean}
     * @memberof TransactionsApiDeleteTransaction
     */
    readonly keepData?: boolean
}

/**
 * Request parameters for deleteTransactionByExternalId operation in TransactionsApi.
 * @export
 * @interface TransactionsApiDeleteTransactionByExternalIdRequest
 */
export interface TransactionsApiDeleteTransactionByExternalIdRequest {
    /**
     * External ID of the transaction to delete
     * @type {string}
     * @memberof TransactionsApiDeleteTransactionByExternalId
     */
    readonly externalId: string

    /**
     * Keep the data by just marking the record as deleted, instead of physically deleting it.                                Default is false (i.e. to physically delete).
     * @type {boolean}
     * @memberof TransactionsApiDeleteTransactionByExternalId
     */
    readonly keepData?: boolean
}

/**
 * Request parameters for idDetectIsinOrWkn operation in TransactionsApi.
 * @export
 * @interface TransactionsApiIdDetectIsinOrWknRequest
 */
export interface TransactionsApiIdDetectIsinOrWknRequest {
    /**
     * ID of the transaction to detect ISIN or WKN from
     * @type {number}
     * @memberof TransactionsApiIdDetectIsinOrWkn
     */
    readonly id: number
}

/**
 * Request parameters for listAndSearchAllTransaction operation in TransactionsApi.
 * @export
 * @interface TransactionsApiListAndSearchAllTransactionRequest
 */
export interface TransactionsApiListAndSearchAllTransactionRequest {
    /**
     * Retrieve Transactions with the given IDs
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly id?: Array<number>

    /**
     * Retrieve Transactions for the given Accounts
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly accountId?: Array<number>

    /**
     * Retrieve Transactions related to investments residing inside the given depots.                              Note that this parameter will be ignored if you also provide investmentIds.
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly depotId?: Array<number>

    /**
     * Retrieve Transactions for the given insurance; setting this parameter will ignore the                              \&#39;accountId\&#39; or \&#39;depotId\&#39; parameter.
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly insuranceId?: Array<number>

    /**
     * Retrieve Transactions for the given investments
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly investmentId?: Array<number>

    /**
     * (Deprecated - use externalId) Retrieve Transactions with the given finAPI IDs
     * @type {Array<number>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly finApiId?: Array<number>

    /**
     * Retrieve Transactions with the given external IDs
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly externalId?: Array<string>

    /**
     * Retrieve transactions having the given type, allowed values are withdrawal, deposit,                              interest, tax_refund, other_gains.
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly type?: Array<string>

    /**
     * Retrieve Transactions belonging to Investments with the given WKNs
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Transactions belonging to Investments with the given ISINs
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly isin?: Array<string>

    /**
     * Retrieve Transactions belonging to Investments with the given ticker symbols
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly tickerSymbol?: Array<string>

    /**
     * Retrieve Transactions in the given currencies (3-letter ISO code)
     * @type {Array<string>}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly currency?: Array<string>

    /**
     * Retrieve Transactions with amount greater or equal than the given minAmount
     * @type {number}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly minAmount?: number

    /**
     * Retrieve Transactions with amount less or equal than the given maxAmount
     * @type {number}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly maxAmount?: number

    /**
     * Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly minDate?: string

    /**
     * Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly maxDate?: string

    /**
     * Skip transactions that were manually created (i.e. not imported from a bank).
     * @type {boolean}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly skipManuallyCreatedTransactions?: boolean

    /**
     * Include adjusting entries. Adjusting entries are cash transactions created whenever the                              account balance will fall below zero. This is a convenience feature for manually                              maintained depots.
     * @type {boolean}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly includeAdjustingEntries?: boolean

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly format?: ListAndSearchAllTransactionFormatEnum

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof TransactionsApiListAndSearchAllTransaction
     */
    readonly order?: string
}

/**
 * Request parameters for mt940 operation in TransactionsApi.
 * @export
 * @interface TransactionsApiMt940Request
 */
export interface TransactionsApiMt940Request {
    /**
     * Retrieve transactions for the given cash Account
     * @type {number}
     * @memberof TransactionsApiMt940
     */
    readonly accountId: number

    /**
     * Retrieve Transactions with date after or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof TransactionsApiMt940
     */
    readonly minDate?: string

    /**
     * Retrieve Transactions with date before or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof TransactionsApiMt940
     */
    readonly maxDate?: string
}

/**
 * Request parameters for transactionsId operation in TransactionsApi.
 * @export
 * @interface TransactionsApiTransactionsIdRequest
 */
export interface TransactionsApiTransactionsIdRequest {
    /**
     * ID of the transaction to retrieve
     * @type {number}
     * @memberof TransactionsApiTransactionsId
     */
    readonly id: number

    /**
     * Deliver a CSV file if set to \&#39;csv\&#39;; default is JSON.
     * @type {'csv' | 'json'}
     * @memberof TransactionsApiTransactionsId
     */
    readonly format?: TransactionsIdFormatEnum

    /**
     * Include SEPA transaction data stored on the finApi cloud. Note that this will slow                         down the request somewhat, as remote data must be polled in.
     * @type {boolean}
     * @memberof TransactionsApiTransactionsId
     */
    readonly includeRemoteSepaFields?: boolean
}

/**
 * Request parameters for updateCashTransaction operation in TransactionsApi.
 * @export
 * @interface TransactionsApiUpdateCashTransactionRequest
 */
export interface TransactionsApiUpdateCashTransactionRequest {
    /**
     * ID of the transaction to update
     * @type {number}
     * @memberof TransactionsApiUpdateCashTransaction
     */
    readonly id: number

    /**
     * The transaction parameters
     * @type {CreateOrUpdateTransactionParams}
     * @memberof TransactionsApiUpdateCashTransaction
     */
    readonly body: CreateOrUpdateTransactionParams
}

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Assign the given transaction category to the given transaction
     * @param {TransactionsApiAssignCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public assignCategory(requestParameters: TransactionsApiAssignCategoryRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).assignCategory(requestParameters.id, requestParameters.categoryId, requestParameters.trainCategorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a set of cash transactions for the given account.
     * @param {TransactionsApiBatchCreateCashTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public batchCreateCashTransactions(requestParameters: TransactionsApiBatchCreateCashTransactionsRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).batchCreateCashTransactions(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a cash transaction.
     * @param {TransactionsApiCreateCashTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public createCashTransaction(requestParameters: TransactionsApiCreateCashTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).createCashTransaction(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a cash Transaction
     * @param {TransactionsApiDeleteTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public deleteTransaction(requestParameters: TransactionsApiDeleteTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).deleteTransaction(requestParameters.id, requestParameters.keepData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a cash Transaction by external ID. This is a convenience routine typically used by                        SaaS processes working on booking data.
     * @param {TransactionsApiDeleteTransactionByExternalIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public deleteTransactionByExternalId(requestParameters: TransactionsApiDeleteTransactionByExternalIdRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).deleteTransactionByExternalId(requestParameters.externalId, requestParameters.keepData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detect the ISIN / WKN contained within a transaction purpose.
     * @param {TransactionsApiIdDetectIsinOrWknRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public idDetectIsinOrWkn(requestParameters: TransactionsApiIdDetectIsinOrWknRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).idDetectIsinOrWkn(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search a list of Transactions
     * @param {TransactionsApiListAndSearchAllTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public listAndSearchAllTransaction(requestParameters: TransactionsApiListAndSearchAllTransactionRequest = {}, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).listAndSearchAllTransaction(requestParameters.id, requestParameters.accountId, requestParameters.depotId, requestParameters.insuranceId, requestParameters.investmentId, requestParameters.finApiId, requestParameters.externalId, requestParameters.type, requestParameters.wkn, requestParameters.isin, requestParameters.tickerSymbol, requestParameters.currency, requestParameters.minAmount, requestParameters.maxAmount, requestParameters.minDate, requestParameters.maxDate, requestParameters.skipManuallyCreatedTransactions, requestParameters.includeAdjustingEntries, requestParameters.format, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get transactions for the given cash account in MT940 format; a widely used SWIFT standard. See                      https://www.sepaforcorporates.com/swift-for-corporates/account-statement-mt940-file-format-overview                      for details on this format.<br>                      Data my be returned in plain format (no brackets) or in the SWIFT message format (with header in                      brackets).
     * @param {TransactionsApiMt940Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public mt940(requestParameters: TransactionsApiMt940Request, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).mt940(requestParameters.accountId, requestParameters.minDate, requestParameters.maxDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Transaction
     * @param {TransactionsApiTransactionsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionsId(requestParameters: TransactionsApiTransactionsIdRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionsId(requestParameters.id, requestParameters.format, requestParameters.includeRemoteSepaFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a cash transaction.
     * @param {TransactionsApiUpdateCashTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public updateCashTransaction(requestParameters: TransactionsApiUpdateCashTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).updateCashTransaction(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAndSearchAllTransactionFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type ListAndSearchAllTransactionFormatEnum = typeof ListAndSearchAllTransactionFormatEnum[keyof typeof ListAndSearchAllTransactionFormatEnum];
/**
 * @export
 */
export const TransactionsIdFormatEnum = {
    CSV: 'csv',
    JSON: 'json'
} as const;
export type TransactionsIdFormatEnum = typeof TransactionsIdFormatEnum[keyof typeof TransactionsIdFormatEnum];
