import { axiosInstanceV1 } from "@api/axiosInstance";
import { GetTagsParams, GetTagsResponse } from "../model";

export const getTags = async (
  token: string | undefined,
  params?: GetTagsParams
) => {
  const { data } = await axiosInstanceV1.get<GetTagsResponse>(`/tags`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  return data;
};
