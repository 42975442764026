import { UseMutationOptions, UseQueryOptions } from "react-query";
import { useAuthMutation, useAuthQuery } from "@api/auth";
import {
  buildBankConnectionsApiWithToken as buildFinApiBankConnectionsApiWithToken,
  buildAccountsApiWithToken,
} from "@generated/apiv1/apiBuilder";
import { buildBankConnectionsApiWithToken as buildWealthApiBankConnectionsApiWithToken } from "@generated/apiv3/apiBuilder";
import {
  BankConnectionsApiGetBankConnectionRequest,
  AccountsApiListAndSearchAllRemoteAccountsRequest,
  AccountsApiListAndSearchAllAccountsRequest,
  BankConnectionsApiDeleteStoredSecretsRequest,
  BankConnectionsApiListAndSearchAllBankConnectionsV2Request,
  BankConnectionsApiDeleteBankConnectionV2Request,
} from "@generated/apiv1";

import {
  GET_BANK_CONNECTION,
  GET_BANK_CONNECTIONS,
  GET_REMOTE_ACCOUNTS,
} from "@api/cacheKeys";
import {
  transformBankConnection,
  transformBankConnectionAccount,
} from "@features/settingsPage/SettingsConnectionsPage/helpers";
import {
  SettingsBankConnection,
  SettingsBankConnectionAccount,
} from "@features/settingsPage/SettingsConnectionsPage/types";
import {
  BankConnectionData,
  BankConnectionsApiGetBankConnectionsInBatchRequest,
} from "@generated/apiv3";

export const getBankConnection = async (
  provider: "FIN_API" | "WEALTH_API",
  params: BankConnectionsApiGetBankConnectionRequest
) => {
  const bankConnectionsApi =
    provider === "FIN_API"
      ? await buildFinApiBankConnectionsApiWithToken()
      : await buildWealthApiBankConnectionsApiWithToken();
  const { data } = await bankConnectionsApi.getBankConnection(params);
  return transformBankConnection(
    // @ts-ignore
    provider === "FIN_API" ? data : (data?.data as BankConnectionData)
  );
};

export const getAccountsByProvider = async (
  provider: "FIN_API" | "WEALTH_API",
  params:
    | AccountsApiListAndSearchAllRemoteAccountsRequest
    | AccountsApiListAndSearchAllAccountsRequest
) => {
  const accountsApi = await buildAccountsApiWithToken();
  const { data } = await (provider === "FIN_API"
    ? accountsApi.listAndSearchAllRemoteAccounts(params)
    : accountsApi.listAndSearchAllAccounts(params));
  return data?.accounts?.map(transformBankConnectionAccount);
};

export const getWealthApiBankConnections = async (
  params: BankConnectionsApiGetBankConnectionsInBatchRequest
) => {
  const bankConnectionsApi = await buildWealthApiBankConnectionsApiWithToken();
  const { data } = await bankConnectionsApi.getBankConnectionsInBatch(params);
  return data?.data.map(transformBankConnection);
};

export const getFinApiBankConnections = async (
  params: BankConnectionsApiListAndSearchAllBankConnectionsV2Request
) => {
  const bankConnectionsApi = await buildFinApiBankConnectionsApiWithToken();
  const { data } = await bankConnectionsApi.listAndSearchAllBankConnectionsV2(
    params
  );
  return data?.bankConnections?.map(transformBankConnection);
};

export const deleteStoredSecrets =
  (provider: "FIN_API" | "WEALTH_API") =>
  async (
    token: string | undefined,
    params: BankConnectionsApiDeleteStoredSecretsRequest
  ) => {
    if (provider === "FIN_API") {
      const bankConnectionsApi = await buildFinApiBankConnectionsApiWithToken();
      return bankConnectionsApi.deleteStoredSecrets(params);
    } else {
      const bankConnectionsApi =
        await buildWealthApiBankConnectionsApiWithToken();
      return bankConnectionsApi.clearCredentials(params);
    }
  };

export const deleteBankConnection =
  (provider: "FIN_API" | "WEALTH_API") =>
  async (
    token: string | undefined,
    params: BankConnectionsApiDeleteBankConnectionV2Request
  ) => {
    if (provider === "FIN_API") {
      const bankConnectionsApi = await buildFinApiBankConnectionsApiWithToken();
      return bankConnectionsApi.deleteBankConnectionV2(params);
    } else {
      const bankConnectionsApi =
        await buildWealthApiBankConnectionsApiWithToken();
      return bankConnectionsApi.deleteBankConnection(params);
    }
  };

export const useGetBankConnectionByProvider = (
  provider: "FIN_API" | "WEALTH_API",
  params: { id: number },
  options?: UseQueryOptions<unknown, AxiosApiError, SettingsBankConnection>
) => {
  return useAuthQuery(
    [GET_BANK_CONNECTION, provider, params],
    () => getBankConnection(provider, params),
    options
  );
};

export const useGetAccountsByProvider = (
  provider: "FIN_API" | "WEALTH_API",
  params:
    | AccountsApiListAndSearchAllRemoteAccountsRequest
    | AccountsApiListAndSearchAllAccountsRequest,
  options?: UseQueryOptions<
    unknown,
    AxiosApiError,
    SettingsBankConnectionAccount[]
  >
) => {
  return useAuthQuery(
    [GET_REMOTE_ACCOUNTS, provider, params],
    () => getAccountsByProvider(provider, params),
    options
  );
};

export const useDeleteStoredSecrets = (
  provider: "FIN_API" | "WEALTH_API",
  options?: UseMutationOptions<
    unknown,
    AxiosApiError,
    BankConnectionsApiDeleteStoredSecretsRequest
  >
) => {
  return useAuthMutation(deleteStoredSecrets(provider), options);
};

export const useDeleteBankConnection = (
  provider: "FIN_API" | "WEALTH_API",
  options?: UseMutationOptions<
    unknown,
    AxiosApiError,
    BankConnectionsApiDeleteBankConnectionV2Request
  >
) => {
  return useAuthMutation(deleteBankConnection(provider), options);
};

export const useGetWealthApiBankConnections = (
  params: BankConnectionsApiGetBankConnectionsInBatchRequest,
  options?: UseQueryOptions<unknown, AxiosApiError, SettingsBankConnection[]>
) => {
  return useAuthQuery(
    [GET_BANK_CONNECTIONS, "WEALTH_API"],
    () => getWealthApiBankConnections(params),
    options
  );
};

export const useGetFinApiBankConnections = (
  params: BankConnectionsApiListAndSearchAllBankConnectionsV2Request,
  options?: UseQueryOptions<unknown, AxiosApiError, SettingsBankConnection[]>
) => {
  return useAuthQuery(
    [GET_BANK_CONNECTIONS, "FIN_API"],
    () => getFinApiBankConnections(params),
    options
  );
};
