import { UseMutationOptions } from "react-query";
import {
  NaturalPersonsApiUpdateMultipleNaturalPersonsRequest,
  NaturalPerson,
} from "@generated/apiv1";

import { buildNaturalPersonsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateMultipleNaturalPersons = async (
  token: string | undefined,
  params: NaturalPersonsApiUpdateMultipleNaturalPersonsRequest
) => {
  const apiInstance = buildNaturalPersonsApi(token);
  const { data } = await apiInstance.updateMultipleNaturalPersons(params);
  return data;
};

export const useUpdateMultipleNaturalPersons = (
  options?: UseMutationOptions<
    NaturalPerson,
    AxiosApiError,
    NaturalPersonsApiUpdateMultipleNaturalPersonsRequest
  >
) => {
  return useAuthMutation(updateMultipleNaturalPersons, options, false);
};
