import { axiosInstanceV1 } from "@api/axiosInstance";
import { GetDepotSynchronizationLogParams } from "@api/v1/model/get-syncronization-log-params";
import {
  DepotSynchronizationLog,
  PageableDepotSynchronizationLogList,
} from "../model";

export const getDepotSynchronizationLog = async (
  token: string | undefined,
  id: number
) => {
  const { data } = await axiosInstanceV1.get<DepotSynchronizationLog>(
    `/depotSynchronizationLogs/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getDepotSynchronizationLogs = async (
  token: string | undefined,
  params: GetDepotSynchronizationLogParams
) => {
  const { data } =
    await axiosInstanceV1.get<PageableDepotSynchronizationLogList>(
      `/depotSynchronizationLogs`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      }
    );
  return data;
};

export const rollbackDepotSynchronizationLog = async (
  token: string | undefined,
  { id }: { id: number }
) => {
  const { data } = await axiosInstanceV1.delete(
    `/depotSynchronizationLogs/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
