import { UseMutationOptions } from "react-query";
import { MailingApiRecommendUsRequest } from "@generated/apiv1";

import { buildMailingApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const recommendUs = async (
  token: string | undefined,
  params: MailingApiRecommendUsRequest
) => {
  const apiInstance = buildMailingApi(token);
  const { data } = await apiInstance.recommendUs(params);
  return data;
};

export const useRecommendUs = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    MailingApiRecommendUsRequest
  >
) => {
  return useAuthMutation(recommendUs, options, false);
};
