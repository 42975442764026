import { UseQueryOptions } from "react-query";
import { CashFlowAndPerformanceStatisticsList } from "@api/v1/model";
import { getPerformanceByDepots } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";
import { ACCOUNT_PERFORMANCE_BY_ACCOUNT_IDS } from "@api/cacheKeys";

export interface Attrs {
  accountIds?: number[];
  startDate?: Date;
  endDate?: Date;
  interval?: "day" | "week" | "month" | "year";
}

export const usePerformanceByAccounts = (
  { accountIds, startDate, endDate, interval = "day" }: Attrs,
  options: UseQueryOptions<
    CashFlowAndPerformanceStatisticsList,
    AxiosApiError,
    CashFlowAndPerformanceStatisticsList
  > = {}
) => {
  const query = useAuthQuery<
    CashFlowAndPerformanceStatisticsList,
    AxiosApiError,
    CashFlowAndPerformanceStatisticsList
  >(
    [
      ACCOUNT_PERFORMANCE_BY_ACCOUNT_IDS,
      accountIds?.sort(),
      startDate?.getTime(),
      endDate?.getTime(),
      interval,
    ],

    ({ token }) =>
      getPerformanceByDepots(token, {
        accountIds,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        interval,
        cashFlowAndPerformanceStatisticsParamsList: [
          { assetClasses: [], includeCash: false, includeDividends: true },
          { assetClasses: [], includeCash: true, includeDividends: true },
        ],
      }),
    {
      enabled: !!accountIds && accountIds.length > 0,
      keepPreviousData: true,
      ...options,
    }
  );

  const [withoutCash, withCash] =
    query.data?.cashFlowAndPerformanceStatisticsList || [];
  const data = {
    withoutCash,
    withCash,
  };
  return { ...query, data };
};
