import { UseMutationOptions } from "react-query";
import {
  InternalTransactionsApiUpdateInternalTransactionRequest,
  InternalTransaction,
} from "@generated/apiv1";

import { buildInternalTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateInternalTransaction = async (
  token: string | undefined,
  params: InternalTransactionsApiUpdateInternalTransactionRequest
) => {
  const apiInstance = buildInternalTransactionsApi(token);
  const { data } = await apiInstance.updateInternalTransaction(params);
  return data;
};

export const useUpdateInternalTransaction = (
  options?: UseMutationOptions<
    InternalTransaction,
    AxiosApiError,
    InternalTransactionsApiUpdateInternalTransactionRequest
  >
) => {
  return useAuthMutation(updateInternalTransaction, options, false);
};
