import { UseMutationOptions } from "react-query";
import { BankConnectionsApiClearCredentialsRequest } from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const clearCredentials = async (
  token: string | undefined,
  params: BankConnectionsApiClearCredentialsRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.clearCredentials(params);
  return data;
};

export const useClearCredentials = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiClearCredentialsRequest
  >
) => {
  return useAuthMutation(clearCredentials, options, false);
};
