/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateSubcategory } from '../model';
// @ts-ignore
import { Subcategory } from '../model';
// @ts-ignore
import { SubcategoryList } from '../model';
// @ts-ignore
import { UpdateSubcategory } from '../model';
/**
 * SubcategoriesApi - axios parameter creator
 * @export
 */
export const SubcategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new subcategory
         * @param {CreateSubcategory} createSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubcategory: async (createSubcategory: CreateSubcategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubcategory' is not null or undefined
            assertParamExists('createSubcategory', 'createSubcategory', createSubcategory)
            const localVarPath = `/api/v3/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubcategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubcategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSubcategory', 'id', id)
            const localVarPath = `/api/v3/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary filter subcategories
         * @param {string} [filterName] Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
         * @param {string} [filterCategoryId] Comma separated values.Example: \&#39;filter[category_id]&#x3D;1,2\&#39;
         * @param {string} [filterCategoryName] Comma separated values.Example: \&#39;filter[category_name]&#x3D;Investments,Accounts\&#39;
         * @param {string} [filterCategoryType] Comma separated values.Example: \&#39;filter[category_type]&#x3D;INVESTMENT,ACCOUNT\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubcategories: async (filterName?: string, filterCategoryId?: string, filterCategoryName?: string, filterCategoryType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterCategoryId !== undefined) {
                localVarQueryParameter['filter[category_id]'] = filterCategoryId;
            }

            if (filterCategoryName !== undefined) {
                localVarQueryParameter['filter[category_name]'] = filterCategoryName;
            }

            if (filterCategoryType !== undefined) {
                localVarQueryParameter['filter[category_type]'] = filterCategoryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubcategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubcategory', 'id', id)
            const localVarPath = `/api/v3/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateSubcategory} updateSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubcategory: async (id: number, updateSubcategory: UpdateSubcategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubcategory', 'id', id)
            // verify required parameter 'updateSubcategory' is not null or undefined
            assertParamExists('updateSubcategory', 'updateSubcategory', updateSubcategory)
            const localVarPath = `/api/v3/subcategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubcategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubcategoriesApi - functional programming interface
 * @export
 */
export const SubcategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubcategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new subcategory
         * @param {CreateSubcategory} createSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubcategory(createSubcategory: CreateSubcategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubcategory(createSubcategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubcategoriesApi.createSubcategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubcategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubcategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubcategoriesApi.deleteSubcategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary filter subcategories
         * @param {string} [filterName] Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
         * @param {string} [filterCategoryId] Comma separated values.Example: \&#39;filter[category_id]&#x3D;1,2\&#39;
         * @param {string} [filterCategoryName] Comma separated values.Example: \&#39;filter[category_name]&#x3D;Investments,Accounts\&#39;
         * @param {string} [filterCategoryType] Comma separated values.Example: \&#39;filter[category_type]&#x3D;INVESTMENT,ACCOUNT\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubcategories(filterName?: string, filterCategoryId?: string, filterCategoryName?: string, filterCategoryType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubcategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubcategories(filterName, filterCategoryId, filterCategoryName, filterCategoryType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubcategoriesApi.getSubcategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubcategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubcategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubcategoriesApi.getSubcategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update a subcategory
         * @param {number} id The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
         * @param {UpdateSubcategory} updateSubcategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubcategory(id: number, updateSubcategory: UpdateSubcategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubcategory(id, updateSubcategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubcategoriesApi.updateSubcategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubcategoriesApi - factory interface
 * @export
 */
export const SubcategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubcategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary create new subcategory
         * @param {SubcategoriesApiCreateSubcategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubcategory(requestParameters: SubcategoriesApiCreateSubcategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subcategory> {
            return localVarFp.createSubcategory(requestParameters.createSubcategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a subcategory
         * @param {SubcategoriesApiDeleteSubcategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubcategory(requestParameters: SubcategoriesApiDeleteSubcategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSubcategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary filter subcategories
         * @param {SubcategoriesApiGetSubcategoriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubcategories(requestParameters: SubcategoriesApiGetSubcategoriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<SubcategoryList> {
            return localVarFp.getSubcategories(requestParameters.filterName, requestParameters.filterCategoryId, requestParameters.filterCategoryName, requestParameters.filterCategoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a subcategory
         * @param {SubcategoriesApiGetSubcategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubcategory(requestParameters: SubcategoriesApiGetSubcategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subcategory> {
            return localVarFp.getSubcategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a subcategory
         * @param {SubcategoriesApiUpdateSubcategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubcategory(requestParameters: SubcategoriesApiUpdateSubcategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subcategory> {
            return localVarFp.updateSubcategory(requestParameters.id, requestParameters.updateSubcategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSubcategory operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiCreateSubcategoryRequest
 */
export interface SubcategoriesApiCreateSubcategoryRequest {
    /**
     * 
     * @type {CreateSubcategory}
     * @memberof SubcategoriesApiCreateSubcategory
     */
    readonly createSubcategory: CreateSubcategory
}

/**
 * Request parameters for deleteSubcategory operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiDeleteSubcategoryRequest
 */
export interface SubcategoriesApiDeleteSubcategoryRequest {
    /**
     * The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof SubcategoriesApiDeleteSubcategory
     */
    readonly id: number
}

/**
 * Request parameters for getSubcategories operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiGetSubcategoriesRequest
 */
export interface SubcategoriesApiGetSubcategoriesRequest {
    /**
     * Comma separated values.Example: \&#39;filter[name]&#x3D;name1,name2\&#39;
     * @type {string}
     * @memberof SubcategoriesApiGetSubcategories
     */
    readonly filterName?: string

    /**
     * Comma separated values.Example: \&#39;filter[category_id]&#x3D;1,2\&#39;
     * @type {string}
     * @memberof SubcategoriesApiGetSubcategories
     */
    readonly filterCategoryId?: string

    /**
     * Comma separated values.Example: \&#39;filter[category_name]&#x3D;Investments,Accounts\&#39;
     * @type {string}
     * @memberof SubcategoriesApiGetSubcategories
     */
    readonly filterCategoryName?: string

    /**
     * Comma separated values.Example: \&#39;filter[category_type]&#x3D;INVESTMENT,ACCOUNT\&#39;
     * @type {string}
     * @memberof SubcategoriesApiGetSubcategories
     */
    readonly filterCategoryType?: string
}

/**
 * Request parameters for getSubcategory operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiGetSubcategoryRequest
 */
export interface SubcategoriesApiGetSubcategoryRequest {
    /**
     * The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof SubcategoriesApiGetSubcategory
     */
    readonly id: number
}

/**
 * Request parameters for updateSubcategory operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiUpdateSubcategoryRequest
 */
export interface SubcategoriesApiUpdateSubcategoryRequest {
    /**
     * The subcategory ID. Example: \&#39;id&#x3D;123\&#39;
     * @type {number}
     * @memberof SubcategoriesApiUpdateSubcategory
     */
    readonly id: number

    /**
     * 
     * @type {UpdateSubcategory}
     * @memberof SubcategoriesApiUpdateSubcategory
     */
    readonly updateSubcategory: UpdateSubcategory
}

/**
 * SubcategoriesApi - object-oriented interface
 * @export
 * @class SubcategoriesApi
 * @extends {BaseAPI}
 */
export class SubcategoriesApi extends BaseAPI {
    /**
     * 
     * @summary create new subcategory
     * @param {SubcategoriesApiCreateSubcategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public createSubcategory(requestParameters: SubcategoriesApiCreateSubcategoryRequest, options?: RawAxiosRequestConfig) {
        return SubcategoriesApiFp(this.configuration).createSubcategory(requestParameters.createSubcategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a subcategory
     * @param {SubcategoriesApiDeleteSubcategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public deleteSubcategory(requestParameters: SubcategoriesApiDeleteSubcategoryRequest, options?: RawAxiosRequestConfig) {
        return SubcategoriesApiFp(this.configuration).deleteSubcategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary filter subcategories
     * @param {SubcategoriesApiGetSubcategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public getSubcategories(requestParameters: SubcategoriesApiGetSubcategoriesRequest = {}, options?: RawAxiosRequestConfig) {
        return SubcategoriesApiFp(this.configuration).getSubcategories(requestParameters.filterName, requestParameters.filterCategoryId, requestParameters.filterCategoryName, requestParameters.filterCategoryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a subcategory
     * @param {SubcategoriesApiGetSubcategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public getSubcategory(requestParameters: SubcategoriesApiGetSubcategoryRequest, options?: RawAxiosRequestConfig) {
        return SubcategoriesApiFp(this.configuration).getSubcategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a subcategory
     * @param {SubcategoriesApiUpdateSubcategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public updateSubcategory(requestParameters: SubcategoriesApiUpdateSubcategoryRequest, options?: RawAxiosRequestConfig) {
        return SubcategoriesApiFp(this.configuration).updateSubcategory(requestParameters.id, requestParameters.updateSubcategory, options).then((request) => request(this.axios, this.basePath));
    }
}

