import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setNameAndDepotAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "NAME_AND_DEPOT_SELECTED")
    throw new Error(`Invalid event type ${event.type}`);

  const depotId =
    event.type === "NAME_AND_DEPOT_SELECTED"
      ? event.depotId
      : context.investmentValues.depotId;

  return {
    investmentValues: {
      ...context.investmentValues,
      depotId,
      ...event.investmentValues,
    },
    bookingValues: {
      ...context.bookingValues,
      comment: event.comment,
    },
  };
};
