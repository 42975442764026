import { useManualSyncRequired } from "@generated/apiv1/hooks";

export type AccountWithSyncRequired<T> = T & { syncRequired: boolean };

export const useAccountsWithSyncRequired = <
  T extends { bankConnectionId?: number; id: number }
>(
  accounts: T[]
): AccountWithSyncRequired<T>[] => {
  const acccountsWithBankConnection = accounts.filter((account) =>
    Boolean(account.bankConnectionId)
  );

  const { data: syncRequiredStatuses } = useManualSyncRequired(
    {
      accountId: acccountsWithBankConnection.map((account) => account.id),
    },
    {
      enabled: acccountsWithBankConnection.length > 0,
    }
  );

  return accounts.map((account) => ({
    ...account,
    syncRequired:
      syncRequiredStatuses?.syncStatuses?.find(
        (status) => status.accountId === account.id
      )?.manualSyncRequired || false,
  }));
};
