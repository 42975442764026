import React from "react";
import { Box, Button, ButtonProps, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "@icons/arrow-left.svg";

export const BackButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const isLessMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Button
      className="bicSelectBackButton"
      disableElevation={true}
      color="primary"
      size="small"
      variant="text"
      data-testid="cancel-button"
      sx={{
        marginBottom: (theme) => theme.spacing(isLessMd ? 5 : 11),
        marginLeft: (theme) => theme.spacing(-3),
        "& svg": {
          marginLeft: (theme) => theme.spacing(-1.5),
        },
      }}
      {...props}
    >
      <Box display="flex" alignItems="center">
        <BackIcon />
        {t("navigation.previous")}
      </Box>
    </Button>
  );
};
