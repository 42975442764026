import { UseMutationOptions } from "react-query";
import { CategoriesApiUpdateCategoryRequest, Category } from "@generated/apiv3";

import { buildCategoriesApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateCategory = async (
  token: string | undefined,
  params: CategoriesApiUpdateCategoryRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.updateCategory(params);
  return data;
};

export const useUpdateCategory = (
  options?: UseMutationOptions<
    Category,
    AxiosApiError,
    CategoriesApiUpdateCategoryRequest
  >
) => {
  return useAuthMutation(updateCategory, options, false);
};
