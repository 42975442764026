import React from "react";

type Props = {
  children: React.ReactNode;
};

export const ChartTooltip = ({ children }: Props) => {
  return (
    <div
      style={{
        fontFamily: "Averta,Arial",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
        border: `1px solid #E8EAED`,
        padding: "8px",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      {children}
    </div>
  );
};
