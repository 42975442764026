import React from "react";
import { Line, LineChart } from "recharts";
import { useTheme } from "@emotion/react";
import AutoSizer from "react-virtualized-auto-sizer";

export interface AccountPreviewChartData {
  value: number;
  date: Date;
}

export interface AccountPreviewChartProps {
  chartData: AccountPreviewChartData[];
  height?: number;
  strokeColor?: string;
}

export const AccountPreviewChart = ({
  chartData,
  height = 64,
  strokeColor,
}: AccountPreviewChartProps) => {
  const theme = useTheme();

  return (
    <AutoSizer>
      {({ width }: { width: number }) => (
        <LineChart data={chartData} width={width} height={height}>
          <Line
            type="monotone"
            dataKey="value"
            stroke={strokeColor || theme.palette.primary.dark}
            strokeWidth={2}
            dot={false}
            animationDuration={1000}
          />
        </LineChart>
      )}
    </AutoSizer>
  );
};
