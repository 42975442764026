import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  styled,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useQueryClient } from "react-query";
import { TransactionWithCategory } from "@features/cashflowAnalyzer/types";
import { TypographyWithInfoIcon } from "@components/TypographyWithInfoIcon";
import { toCurrency } from "@helpers";
import { TransactionCategory } from "@features/cashflowAnalyzer/components/TransactionCategory";
import { ReactComponent as ChevronDown } from "@icons/chevron-down.svg";
import { ReactComponent as InfoIcon } from "@icons/info.svg";
import { useUpdateCashTransaction } from "@generated/apiv1/hooks";
import { TRANSACTIONS } from "@api/cacheKeys";
import { ReactComponent as AccountTransferIcon } from "@icons/arrows-left-right-to-line-solid.svg";
import { ReactComponent as RegularIcon } from "@icons/clock-rotate-left-solid.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet.svg";

const ExpandIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
  display: "flex",
  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  transition: "transform 0.2s ease-in-out",
  transformOrigin: "center",
}));

export const TransactionRow = ({
  transaction,
  onCategoryClick,
  isOpen,
  accountName,
}: {
  transaction: TransactionWithCategory;
  onCategoryClick: (transaction: TransactionWithCategory) => void;
  isOpen: boolean;
  accountName?: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isPositive = transaction.amount! > 0;
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { mutate: updateTransaction } = useUpdateCashTransaction({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS],
      });
    },
  });

  const handleCategoryClick = () => {
    onCategoryClick(transaction);
  };

  const handleAccountTransferClick = () => {
    updateTransaction({
      id: transaction.id!,
      body: {
        isInterAccountTransfer: !transaction.isInterAccountTransfer,
      },
    });
  };

  const handleRegularClick = () => {
    updateTransaction({
      id: transaction.id!,
      body: {
        isRegular: !transaction.isRegular,
      },
    });
  };

  const amountColor = transaction.isInterAccountTransfer
    ? theme.palette.text.tertiary
    : isPositive
    ? theme.palette.text.positive
    : theme.palette.text.primary;

  return (
    <>
      <Grid container key={transaction.id} mb={2} py={1} columnSpacing={10}>
        <Grid
          item
          xs={8}
          sm={6}
          md={7}
          display="flex"
          alignItems="center"
          gap={2}
        >
          {!isLessSm && (
            <ExpandIcon color="icon.secondary" isOpen={isOpen}>
              <ChevronDown width="16px" height="16px" />
            </ExpandIcon>
          )}
          <Box>
            <Box>
              {transaction.counterpartName && (
                <Typography
                  variant="body2"
                  fontWeight={600}
                  mb={isLessSm ? 1 : 0}
                >
                  {transaction.counterpartName}
                </Typography>
              )}
              <Box display="flex" alignItems="center" gap={3}>
                {transaction.isInterAccountTransfer && (
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={isLessSm ? 1 : 0}
                    gap={1}
                  >
                    <AccountTransferIcon width="14px" height="14px" />
                    <Typography variant="body2" fontWeight={400}>
                      {t("singleAccountPage.protocolTable.transfer")}
                    </Typography>
                  </Box>
                )}
                {transaction.isRegular && (
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={isLessSm ? 1 : 0}
                    gap={1}
                  >
                    <RegularIcon width="12px" height="12px" />
                    <Typography variant="body2" fontWeight={400}>
                      {t("cashflowAnalyzer.transactionsTable.regular")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {transaction.purpose && transaction.purpose.length > 50 ? (
              <TypographyWithInfoIcon
                variant="caption"
                fontWeight={400}
                color="textSecondary"
                tooltipText={transaction.purpose}
                icon={<InfoIcon width="16px" height="16px" />}
              >
                {`${transaction.purpose?.slice(0, 50)}...`}
              </TypographyWithInfoIcon>
            ) : (
              <Typography
                variant="caption"
                fontWeight={400}
                color="textSecondary"
              >
                {transaction.purpose}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={4} sm={6} md={5}>
          <Box
            display="flex"
            alignItems={isLessSm ? "flex-start" : "center"}
            justifyContent={isLessSm ? "flex-end" : "space-between"}
            gap={3}
            height="100%"
          >
            {!isLessSm && (
              <TransactionCategory
                color={transaction.color}
                backgroundColor={transaction.backgroundColor}
                onClick={handleCategoryClick}
                clickable
              >
                <Typography variant={isLessSm ? "caption" : "body2"} noWrap>
                  {transaction.categoryName || "Nicht Klassifiziert"}
                </Typography>
              </TransactionCategory>
            )}

            <Typography
              variant="body1"
              fontWeight={600}
              color={amountColor}
              p={1}
              sx={{
                backgroundColor: isPositive
                  ? "background.success"
                  : "transparent",
                borderRadius: "4px",
              }}
            >
              {isPositive && "+"}
              {toCurrency(transaction.amount, "EUR", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Collapse orientation="vertical" in={isOpen}>
        <Box
          px={3}
          py={2.5}
          mb={3}
          className="transaction-expanded"
          sx={{
            borderRadius: "12px",
            border: (theme) => `1px solid ${theme.palette.border.secondary}`,
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Typography
            variant="caption"
            color="textSecondary"
            fontWeight={400}
            mb={3}
          >
            {transaction.purpose}
          </Typography>
          <Box display="flex" gap={11} mb={3}>
            <Box
              display="flex"
              flexDirection="column"
              minWidth={isLessSm ? 0 : 257}
            >
              <Typography variant="caption" color="textSecondary" mb={1.5}>
                {t("cashflowAnalyzer.transactionsTable.thisTransactionIs")}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={transaction.isRegular}
                    color="primary"
                    onChange={handleRegularClick}
                    sx={{ marginLeft: theme.spacing(3) }}
                  />
                }
                label={t("cashflowAnalyzer.transactionsTable.regular")}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px !important",
                    lineHeight: "18px !important",
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                  },
                  marginBottom: theme.spacing(2),
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={transaction.isInterAccountTransfer}
                    color="primary"
                    onChange={handleAccountTransferClick}
                    sx={{ marginLeft: theme.spacing(3) }}
                  />
                }
                label={t("cashflowAnalyzer.transactionsTable.accountTransfer")}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px !important",
                    lineHeight: "18px !important",
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Box>
            <Box>
              <Typography variant="caption" color="textSecondary" mb={1.5}>
                {t("cashflowAnalyzer.transactionsTable.category")}
              </Typography>
              <Link
                onClick={handleCategoryClick}
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: (theme) => theme.spacing(1),
                }}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: isLessSm ? "100px" : "auto",
                  }}
                >
                  {transaction.categoryName || "Nicht Klassifiziert"}
                </Box>
                <ChevronDown width="16px" height="16px" />
              </Link>
            </Box>
          </Box>
          <Divider sx={{ marginBottom: (theme) => theme.spacing(3) }} />
          <Box display="flex" alignItems="flex-start" gap={3}>
            <Box>
              {accountName && (
                <Typography variant="caption" color="textSecondary" mb={3}>
                  {t("cashflowAnalyzer.transactionsTable.account")}
                </Typography>
              )}
              <Typography variant="caption" color="textSecondary" mb={1.5}>
                {t("cashflowAnalyzer.transactionsTable.counterpart")}
              </Typography>
              <Typography variant="caption" fontWeight={400}>
                {transaction.counterpartName}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                fontWeight={400}
              >
                {t("domains.cashback.paymentDataForm.bic")}:{" "}
                {transaction.counterpartBic}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                fontWeight={400}
              >
                {t("signupWizard.payoutSummaryStep.iban")}:{" "}
                {transaction.counterpartIban}
              </Typography>
            </Box>
            {accountName && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                color="text.secondary"
              >
                <WalletIcon width="16px" height="16px" />
                <Typography color="textSecondary" variant="caption">
                  {accountName}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Collapse>
    </>
  );
};
