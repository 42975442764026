import i18next from "i18next";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { getCurrencySymbol } from "@helpers";

const nolLabel = (currency: string = "EUR", type?: InvestmentTypeEnum) => {
  const currencySymbol = getCurrencySymbol(currency);

  if (type === "31_bond") return currencySymbol;
  if (type === "61_pmetals")
    return i18next.t("transactionWizard.priceAndDateStep.gram");
  return i18next.t("transactionWizard.priceAndDateStep.lots");
};

const priceLabel = (currency: string = "EUR", type?: InvestmentTypeEnum) => {
  const currencySymbol = getCurrencySymbol(currency);

  if (type === "31_bond") return "%";
  return currencySymbol;
};

export const getInputAdornment = (
  name: string,
  currency: string,
  taxAndCommissionInEur: boolean,
  type?: InvestmentTypeEnum
) => {
  switch (name) {
    case "security_price":
      return priceLabel(currency, type);
    case "tax_amount":
    case "commission":
      return getCurrencySymbol(taxAndCommissionInEur ? "EUR" : currency);
    case "number_of_lots":
      return nolLabel(currency, type);
    default:
      return "";
  }
};
