import { UseMutationOptions } from "react-query";
import { BankConnectionsApiDeleteBankConnectionV2Request } from "@generated/apiv1";

import { buildBankConnectionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteBankConnectionV2 = async (
  token: string | undefined,
  params: BankConnectionsApiDeleteBankConnectionV2Request
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.deleteBankConnectionV2(params);
  return data;
};

export const useDeleteBankConnectionV2 = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    BankConnectionsApiDeleteBankConnectionV2Request
  >
) => {
  return useAuthMutation(deleteBankConnectionV2, options, false);
};
