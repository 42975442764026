import { loadFund, FundParams, useAuthQuery } from "@api";
import { LOAD_FUND_BY_ISIN } from "@api/cacheKeys";

export const useFund = (isin: string, params?: FundParams) =>
  useAuthQuery(
    [LOAD_FUND_BY_ISIN, isin, params],
    async ({ token }) => {
      try {
        return await loadFund(token, isin, params);
      } catch (e) {
        if ((e as AxiosApiError).response?.status === 404) {
          return;
        }
        throw e;
      }
    },
    {
      enabled: !!isin,
      refetchOnWindowFocus: false,
    }
  );
