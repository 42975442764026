import React, { ReactElement, ReactNode, useRef } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { LayoutWithSidebarHeader } from "@components/common/layouts/LayoutWithSidebar/LayoutWithSidebarHeader";
import { LoadingProgressBar, LogoVariant } from "@components";

type Props = {
  breadcrumbs?: BreadcrumbItem[];
  children?: ReactNode;
  isLoading?: boolean;
  progressBar?: ReactElement;
  sidebar: ReactElement;
  actionButton?: ReactElement;
  context?: LogoVariant;
  mobileHeader?: ReactElement;
  maxWidth?: number;
};

export const LayoutWithSidebar = ({
  breadcrumbs,
  children,
  isLoading,
  progressBar,
  sidebar,
  actionButton,
  mobileHeader,
  context,
  maxWidth = 736,
}: Props) => {
  const isMoreMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box height={isMoreMd ? "100vh" : "auto"}>
      {isLoading ? <LoadingProgressBar position="relative" /> : progressBar}
      <Box display="flex" height="calc(100% - 5px)">
        {isMoreMd && sidebar}
        <Box width="100%" sx={{ overflowY: "auto" }} ref={ref}>
          <LayoutWithSidebarHeader
            sidebar={sidebar}
            breadcrumbs={breadcrumbs}
            actionButton={actionButton}
            context={context}
            mobileHeader={mobileHeader}
            maxWidth={maxWidth}
          />
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
