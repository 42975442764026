import { UseQueryOptions } from "react-query";
import {
  IsinNamesApiGetStandardIsinNamesRequest,
  StandardIsinNamesResponse,
} from "@generated/apiv3";

import { buildIsinNamesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getStandardIsinNames = async (
  token: string | undefined,
  params: IsinNamesApiGetStandardIsinNamesRequest
) => {
  const apiInstance = buildIsinNamesApi(token);
  const { data } = await apiInstance.getStandardIsinNames(params);
  return data;
};

export const useGetStandardIsinNames = (
  params: IsinNamesApiGetStandardIsinNamesRequest,
  options?: UseQueryOptions<
    StandardIsinNamesResponse,
    AxiosApiError,
    StandardIsinNamesResponse
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ISINNAMES_GET_STANDARD_ISIN_NAMES, params],
    ({ token }) => getStandardIsinNames(token, params),
    options
  );
};
