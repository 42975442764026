import React from "react";
import { renderToString } from "react-dom/server";
import { format } from "date-fns";
import Highcharts from "highcharts";
import { Theme } from "@mui/material";
import { ChartTooltip } from "@components/Chart/Tooltips/ChartTooltip";
import { splitFormatter } from "@components/Chart/Tooltips/chartSplitFormatter";

export const formatDate = (value?: string | number) => {
  if (!value) return "";

  return `${format(new Date(value), "dd. MMM ")}'${format(
    new Date(value),
    "yy"
  )}`;
};

type Props = {
  formatter: (value?: string | number | null) => React.ReactNode;
  x?: string | number;
  points?: Highcharts.TooltipFormatterContextObject[];
  theme: Theme;
};

export const PerformanceValuationTooltip = ({
  x,
  points,
  formatter,
  theme,
}: Props) => {
  const arr = points?.map((item) => splitFormatter(formatter, item)) || [];

  return [
    renderToString(
      <ChartTooltip>
        <div
          style={{
            fontSize: "10px",
            fontWeight: 500,
            color: theme.palette.text.tooltipSecondary,
          }}
        >
          {formatDate(x)}
        </div>
      </ChartTooltip>
    ),
    ...arr,
  ];
};
