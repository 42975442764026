import { UseQueryOptions } from "react-query";
import {
  DiscountsApiListAndSearchAllDiscountsRequest,
  DiscountPositionList,
} from "@generated/apiv1";

import { buildDiscountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllDiscounts = async (
  token: string | undefined,
  params?: DiscountsApiListAndSearchAllDiscountsRequest
) => {
  const apiInstance = buildDiscountsApi(token);
  const { data } = await apiInstance.listAndSearchAllDiscounts(params);
  return data;
};

export const useListAndSearchAllDiscounts = (
  params?: DiscountsApiListAndSearchAllDiscountsRequest,
  options?: UseQueryOptions<
    DiscountPositionList,
    AxiosApiError,
    DiscountPositionList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DISCOUNTS_LIST_AND_SEARCH_ALL_DISCOUNTS, params],
    ({ token }) => listAndSearchAllDiscounts(token, params),
    options
  );
};
