import { TransactionWizardContext } from "../types/transactionWizardContext";
import { TransactionWizardEvent } from "../types/TransactionWizardEvent";

export const setBookingsAction = (
  context: TransactionWizardContext,
  event: TransactionWizardEvent
) => {
  if (event.type !== "BOOKINGS_LOADED")
    throw new Error(`Invalid event type ${event.type}`);

  return {
    splitValues: { ...context.splitValues, bookings: event.bookings },
  };
};
