import { UseQueryOptions } from "react-query";
import {
  MandatorConfigurationsApiGetMandatorConfigurationRequest,
  MandatorConfiguration,
} from "@generated/apiv1";

import { buildMandatorConfigurationsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getMandatorConfiguration = async (
  token: string | undefined,
  params: MandatorConfigurationsApiGetMandatorConfigurationRequest
) => {
  const apiInstance = buildMandatorConfigurationsApi(token);
  const { data } = await apiInstance.getMandatorConfiguration(params);
  return data;
};

export const useGetMandatorConfiguration = (
  params: MandatorConfigurationsApiGetMandatorConfigurationRequest,
  options?: UseQueryOptions<
    MandatorConfiguration,
    AxiosApiError,
    MandatorConfiguration
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.MANDATORCONFIGURATIONS_GET_MANDATOR_CONFIGURATION, params],
    ({ token }) => getMandatorConfiguration(token, params),
    options
  );
};
