import React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import {
  RadioProps,
  FormControlLabelProps,
  Typography,
  RadioGroup,
  Box,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Option } from "@helpers/types";

const Error = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledCard = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isLabelCustom" &&
    prop !== "size" &&
    prop !== "layout" &&
    prop !== "hasError",
})<{
  size?: "small" | "default" | "large";
  checked?: boolean;
  isLabelCustom?: boolean;
  layout?: string;
  hasError?: boolean;
}>(({ theme, size, checked, isLabelCustom, layout, hasError }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: layout === "vertical" ? theme.spacing(3, 4) : theme.spacing(3.5),
  cursor: "pointer",
  gap: theme.spacing(isLabelCustom ? 0 : 3),
  alignItems: size === "small" || layout === "grid" ? "center" : "flex-start",
  border: hasError
    ? `1px solid ${theme.palette.border.error}`
    : checked
    ? `1px solid ${theme.palette.border.active}`
    : `1px solid ${theme.palette.border.primary}`,
  borderRadius: theme.type === "onvista" ? "4px" : "8px",
  boxShadow: hasError
    ? `0 0 0 1px${theme.palette.border.error} inset, ${theme.shadows[1]}`
    : checked
    ? `0 0 0 1px${theme.palette.primary.main} inset, ${theme.shadows[1]}`
    : theme.shadows[1],

  "&:hover": {
    ...((layout === "vertical" || layout === "two-columns") && {
      backgroundColor: theme.palette.background.subtle,
    }),
    ...((layout === "horizontal" || layout === "grid") && {
      boxShadow: `0 0 0 1px${theme.palette.primary.light} inset, ${theme.shadows[1]}`,
      borderColor: theme.palette.primary.light,
    }),
  },

  ...(isLabelCustom && {
    padding: theme.spacing(3, 4),
    maxWidth:
      layout === "vertical" || layout === "two-columns"
        ? "100%"
        : "calc(50% - 8px)",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  }),
  ...(size === "small" && {
    padding: theme.spacing(2, 3),
    width: "auto",
    minWidth: "200px",
  }),
  ...(size === "large" && {
    minHeight: "72px",
    alignItems: "center",
    padding: theme.spacing(3, 5),
  }),
}));

export const CheckedCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "withRadio" && prop !== "multi",
})<{
  checked?: boolean;
  withRadio?: boolean;
  multi?: boolean;
}>(({ theme, checked, multi, withRadio = true }) => ({
  display: withRadio ? "flex" : "none",
  flexShrink: 0,
  alignItems: "center",
  position: "relative",
  justifyContent: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: `2px solid ${theme.palette.border.primary}`,
  marginTop: multi
    ? theme.spacing(-6)
    : theme.type === "onvista"
    ? theme.spacing(0.5)
    : 0,
  marginRight: multi ? theme.spacing(-1.5) : 0,

  ...(checked &&
    !multi && {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.primary,

      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
      },
    }),
  ...(checked &&
    multi && {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.primary,

      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        width: "16px",
        height: "16px",
        background: "no-repeat center center url(/images/checkbox-checked.svg)",
        backgroundSize: "cover",
      },
    }),
}));

export const RHFCardGroupCard = ({
  option,
  onChange,
  checked = false,
  size = "default",
  withRadio = true,
  layout = "",
  radioPosition = "right",
  hasError = false,
  startIcon,
  endIcon,
  translationNamespace,
  multi,
}: {
  option: Option;
  onChange: (value: Option["value"]) => void;
  checked?: boolean;
  size?: "small" | "default" | "large";
  withRadio?: boolean;
  layout?: string;
  radioPosition?: "left" | "right";
  hasError?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  translationNamespace?: string;
  multi?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmall = size === "small";
  const cardLabel =
    option.label ||
    (i18n.exists(`${translationNamespace}.${option.value}`)
      ? t(`${translationNamespace}.${option.value}`)
      : undefined);

  const cardSubtitle =
    option.subtitle ||
    (i18n.exists(`${translationNamespace}.${option.value}_subtitle`)
      ? t(`${translationNamespace}.${option.value}_subtitle`)
      : undefined);

  return (
    <StyledCard
      onClick={() => onChange(option.value)}
      data-testid={option.testId}
      role="radio"
      data-checked={checked}
      checked={checked}
      size={size}
      isLabelCustom={typeof option.label !== "string" && !isSmall}
      height={layout === "grid" ? "100%" : "auto"}
      layout={layout}
      hasError={hasError}
    >
      {radioPosition === "left" && (
        <CheckedCircle checked={checked} withRadio={withRadio} multi={multi} />
      )}
      <Box width="100%">
        {typeof cardLabel === "string" ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={3}
          >
            <Box display="flex" alignItems="center" gap={3}>
              {startIcon && (
                <Box
                  display="flex"
                  alignItems="center"
                  color={theme.palette.icon.primary}
                >
                  {startIcon}
                </Box>
              )}
              <Box>
                <Typography variant={layout === "vertical" ? "body1" : "body2"}>
                  {cardLabel}
                </Typography>
                {typeof cardSubtitle === "string" ? (
                  <Typography variant="caption" color="textSecondary">
                    {cardSubtitle}
                  </Typography>
                ) : (
                  cardSubtitle
                )}
              </Box>
            </Box>
            {endIcon}
          </Box>
        ) : (
          cardLabel
        )}
      </Box>
      {radioPosition === "right" && (
        <CheckedCircle checked={checked} withRadio={withRadio} multi={multi} />
      )}
    </StyledCard>
  );
};

const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: (prop) =>
    !["isCustomLabel", "layout"].includes(prop as string),
})<{ isCustomLabel?: boolean; layout?: string }>(
  ({ theme, isCustomLabel, layout }) => ({
    display: layout === "grid" || layout === "two-columns" ? "grid" : "flex",
    gap: theme.spacing(isCustomLabel ? 4 : layout === "vertical" ? 3 : 2),
    flexWrap: isCustomLabel ? "wrap" : "nowrap",
    flexDirection: layout === "vertical" ? "column" : "row",

    ...(layout
      ? {
          alignItems: "center",
          gridAutoColumns: layout === "two-columns" ? "unset" : "1fr",
          gridTemplateColumns: layout === "two-columns" ? "1fr 1fr" : "unset",
          gridAutoFlow: layout === "two-columns" ? "unset" : "column",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: layout === "two-columns" ? "1fr" : "1fr 1fr",
            gridAutoColumns: "unset",
            gridAutoFlow: "unset",
          },
        }
      : {
          [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
          },
        }),
  })
);

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "withRadio",
})<{ size?: "small" | "default" | "large"; withRadio?: boolean }>(
  ({ theme, size, withRadio }) => {
    const isSmall = size === "small" || withRadio;

    return {
      fontSize: isSmall ? "14px" : "18px",
      fontWeight: isSmall ? 500 : 600,
      marginBottom: theme.spacing(isSmall ? 1 : 4),

      ...(theme.type === "onvista" && {
        fontSize: isSmall ? "16px" : "18px",
        fontWeight: "600",
      }),
    };
  }
);

export type CustomCardComponent = React.FC<{
  onChange: (value: string) => void;
  option: Option;
  checked: boolean;
  size?: "small" | "default" | "large";
}>;

interface Props {
  name: string;
  label?: FormControlLabelProps["label"];
  options: Option[];
  testId?: string;
  component?: CustomCardComponent;
  withRadio?: boolean;
  layout?: "vertical" | "horizontal" | "grid" | "two-columns";
  radioPosition?: "left" | "right";
  size?: "small" | "default" | "large";
  multi?: boolean;
  translationNamespace?: string;
}

type FormInputProps<T extends FieldValues> = Omit<RadioProps, "size"> &
  Props &
  UseControllerProps<T>;

export const RHFCardGroup = <T extends FieldValues>(
  props: FormInputProps<T>
): JSX.Element => {
  const {
    control,
    name,
    defaultValue,
    label,
    options,
    testId,
    withRadio,
    layout,
    translationNamespace,
    radioPosition = "right",
    size = "default",
    multi = false,
    component: Component = RHFCardGroupCard,
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController<T>({ control, name, defaultValue });

  const formatValue = (val: boolean | string) => {
    if (val === true) return "true";
    if (val === false) return "false";
    return val || null;
  };

  const onMultipleValueChange = (value: string) => {
    if (!field.value) {
      return field.onChange([value]);
    }
    if (field.value.includes(value)) {
      field.onChange(field.value.filter((v: string) => v !== value));
    } else {
      field.onChange([...field.value, value]);
    }
  };

  const renderedOptions = options.map((option) => {
    const checked =
      multi && field.value
        ? field.value.includes(option.value)
        : field.value === option.value;
    const isInvalidOptionSelected =
      checked && error?.type === "is-invalid-option";

    return (
      <Component
        startIcon={option.startIcon}
        endIcon={option.endIcon}
        onChange={multi ? onMultipleValueChange : field.onChange}
        option={option}
        checked={checked}
        multi={multi}
        size={size}
        withRadio={withRadio}
        radioPosition={radioPosition}
        layout={layout}
        key={`${name}-${option.value}`}
        hasError={isInvalidOptionSelected}
        translationNamespace={translationNamespace}
      />
    );
  });

  return (
    <>
      {label && (
        <Label size={size} withRadio={withRadio}>
          {label}
        </Label>
      )}
      {multi ? (
        <StyledRadioGroup layout={layout}>{renderedOptions}</StyledRadioGroup>
      ) : (
        <StyledRadioGroup
          isCustomLabel={options.some(
            (option) => option.label && typeof option.label !== "string"
          )}
          data-testid={testId}
          value={formatValue(field.value)}
          onChange={(_, value) => {
            let preparedValue: string | boolean = value;
            if (value === "true") {
              preparedValue = true;
            }
            if (value === "false") {
              preparedValue = false;
            }
            field.onChange(preparedValue);
          }}
          row
          layout={layout}
        >
          {renderedOptions}
        </StyledRadioGroup>
      )}
      {error?.type !== "is-invalid-option" && error?.message && (
        <Error>
          <Typography
            variant="caption"
            color="inherit"
            data-testid="rhf-radio-group-error"
          >
            {error.message}
          </Typography>
        </Error>
      )}
    </>
  );
};
