/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ActiveUsersByBank } from '../model';
/**
 * UserReportsApi - axios parameter creator
 * @export
 */
export const UserReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Active users by bank report
         * @param {string} [filterFrom] Show active users by bank report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeUsersByBank: async (filterFrom?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/user_reports/active_users_by_bank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserReportsApi - functional programming interface
 * @export
 */
export const UserReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Active users by bank report
         * @param {string} [filterFrom] Show active users by bank report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activeUsersByBank(filterFrom?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveUsersByBank>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activeUsersByBank(filterFrom, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserReportsApi.activeUsersByBank']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserReportsApi - factory interface
 * @export
 */
export const UserReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Active users by bank report
         * @param {UserReportsApiActiveUsersByBankRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeUsersByBank(requestParameters: UserReportsApiActiveUsersByBankRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ActiveUsersByBank> {
            return localVarFp.activeUsersByBank(requestParameters.filterFrom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activeUsersByBank operation in UserReportsApi.
 * @export
 * @interface UserReportsApiActiveUsersByBankRequest
 */
export interface UserReportsApiActiveUsersByBankRequest {
    /**
     * Show active users by bank report starting from the given date,                               ISO date such as 2017-07-21. Default is 30 days ago
     * @type {string}
     * @memberof UserReportsApiActiveUsersByBank
     */
    readonly filterFrom?: string
}

/**
 * UserReportsApi - object-oriented interface
 * @export
 * @class UserReportsApi
 * @extends {BaseAPI}
 */
export class UserReportsApi extends BaseAPI {
    /**
     * 
     * @summary Active users by bank report
     * @param {UserReportsApiActiveUsersByBankRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserReportsApi
     */
    public activeUsersByBank(requestParameters: UserReportsApiActiveUsersByBankRequest = {}, options?: RawAxiosRequestConfig) {
        return UserReportsApiFp(this.configuration).activeUsersByBank(requestParameters.filterFrom, options).then((request) => request(this.axios, this.basePath));
    }
}

