import { UseQueryOptions } from "react-query";
import {
  AccountsApiSelectableAccountsRequest,
  AccountList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const selectableAccounts = async (
  token: string | undefined,
  params: AccountsApiSelectableAccountsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.selectableAccounts(params);
  return data;
};

export const useSelectableAccounts = (
  params: AccountsApiSelectableAccountsRequest,
  options?: UseQueryOptions<AccountList, AxiosApiError, AccountList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_SELECTABLE_ACCOUNTS, params],
    ({ token }) => selectableAccounts(token, params),
    options
  );
};
