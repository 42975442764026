import { BankWrapper as ApiBank, MultibankingApiProvider } from "@api";

type Bank = Pick<
  ApiBank,
  | "id"
  | "multibankingApiProvider"
  | "bic"
  | "slug"
  | "name"
  | "isSupported"
  | "iconUrl"
  | "bicGroup"
>;

export interface ShortBank {
  bic?: string;
  slug?: string;
  multibankingApiProvider: MultibankingApiProvider;
  id: number;
  isSupported: boolean;
  bicGroup?: string;
}

export interface GroupedBank {
  ids: number[];
  name: string;
  banks: ShortBank[];
  iconUrl?: string;
  bicGroup?: string;
}

const mapToGroupedBank = (value: Bank, acc: GroupedBank[]) => {
  const banks = [
    {
      id: value.id,
      multibankingApiProvider: value.multibankingApiProvider,
      slug: value.slug,
      bic: value.bic,
      isSupported: !!value.isSupported,
    },
  ];

  return [
    ...acc,
    {
      ids: [value.id],
      name: value.name,
      banks,
      iconUrl: value.iconUrl,
      bicGroup: value.bicGroup,
    },
  ];
};

export const groupBankByName = (banks?: Bank[]) => {
  if (!banks) return [];
  return banks.reduce((acc, value) => {
    const existing = acc.find(({ name }) => name === value.name);
    if (!existing) {
      return mapToGroupedBank(value, acc);
    }

    existing.ids?.push(value.id);

    existing.banks?.push({
      bic: value.bic,
      slug: value.slug,
      multibankingApiProvider: value.multibankingApiProvider,
      id: value.id,
      isSupported: !!value.isSupported,
      bicGroup: value.bicGroup,
    });

    return acc;
  }, [] as GroupedBank[]);
};
