import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WizardLayout, WizardsHeader } from "@components";
import { getAccountUrl } from "@helpers/mainAppUrls";
import { AssignContraAccount } from "./components/AssignContraAccount";

export const AssignContraAccountWithRedirectPage = () => {
  const { id } = useParams<"id">();
  const { t } = useTranslation();
  const accountId = Number.parseInt(id!, 10);

  const goBack = () => {
    window.history.back();
  };

  return (
    <WizardLayout
      header={
        <WizardsHeader
          title={t("assignContraAccount.title")}
          onBackClick={goBack}
          backUrl={getAccountUrl(accountId)}
          variant="dark"
        />
      }
    >
      <AssignContraAccount depotId={accountId} />
    </WizardLayout>
  );
};
