/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BankSync } from '../model';
// @ts-ignore
import { BankSyncListInner } from '../model';
/**
 * BankSyncsApi - axios parameter creator
 * @export
 */
export const BankSyncsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get bank synchronization data
         * @param {number} id The ID of the bank synchronization run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankSync: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankSync', 'id', id)
            const localVarPath = `/api/v3/wealth_api/bank_syncs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list bank syncs with the given filters
         * @param {string} filterSlug Filter results by bank slug, example filter[slug]&#x3D;Degiro
         * @param {string} [filterUserId] Filter results by user id, example filter[user_id]&#x3D;6775
         * @param {string} [filterFrom] Filter results by creation date in ISO format,                               example filter[from]&#x3D;2024-04-01. Default is 1 month ago.
         * @param {string} [filterTo] Filter results by creation date in ISO format,                               example filter[to]&#x3D;2024-05-01. Default is now.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBankSyncs: async (filterSlug: string, filterUserId?: string, filterFrom?: string, filterTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterSlug' is not null or undefined
            assertParamExists('showBankSyncs', 'filterSlug', filterSlug)
            const localVarPath = `/api/v3/wealth_api/bank_syncs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filterSlug !== undefined) {
                localVarQueryParameter['filter[slug]'] = filterSlug;
            }

            if (filterUserId !== undefined) {
                localVarQueryParameter['filter[user_id]'] = filterUserId;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter[from]'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter[to]'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankSyncsApi - functional programming interface
 * @export
 */
export const BankSyncsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankSyncsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get bank synchronization data
         * @param {number} id The ID of the bank synchronization run
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankSync(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankSync>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankSync(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankSyncsApi.getBankSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list bank syncs with the given filters
         * @param {string} filterSlug Filter results by bank slug, example filter[slug]&#x3D;Degiro
         * @param {string} [filterUserId] Filter results by user id, example filter[user_id]&#x3D;6775
         * @param {string} [filterFrom] Filter results by creation date in ISO format,                               example filter[from]&#x3D;2024-04-01. Default is 1 month ago.
         * @param {string} [filterTo] Filter results by creation date in ISO format,                               example filter[to]&#x3D;2024-05-01. Default is now.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBankSyncs(filterSlug: string, filterUserId?: string, filterFrom?: string, filterTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankSyncListInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBankSyncs(filterSlug, filterUserId, filterFrom, filterTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BankSyncsApi.showBankSyncs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BankSyncsApi - factory interface
 * @export
 */
export const BankSyncsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankSyncsApiFp(configuration)
    return {
        /**
         * 
         * @summary get bank synchronization data
         * @param {BankSyncsApiGetBankSyncRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankSync(requestParameters: BankSyncsApiGetBankSyncRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankSync> {
            return localVarFp.getBankSync(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list bank syncs with the given filters
         * @param {BankSyncsApiShowBankSyncsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBankSyncs(requestParameters: BankSyncsApiShowBankSyncsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BankSyncListInner>> {
            return localVarFp.showBankSyncs(requestParameters.filterSlug, requestParameters.filterUserId, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBankSync operation in BankSyncsApi.
 * @export
 * @interface BankSyncsApiGetBankSyncRequest
 */
export interface BankSyncsApiGetBankSyncRequest {
    /**
     * The ID of the bank synchronization run
     * @type {number}
     * @memberof BankSyncsApiGetBankSync
     */
    readonly id: number
}

/**
 * Request parameters for showBankSyncs operation in BankSyncsApi.
 * @export
 * @interface BankSyncsApiShowBankSyncsRequest
 */
export interface BankSyncsApiShowBankSyncsRequest {
    /**
     * Filter results by bank slug, example filter[slug]&#x3D;Degiro
     * @type {string}
     * @memberof BankSyncsApiShowBankSyncs
     */
    readonly filterSlug: string

    /**
     * Filter results by user id, example filter[user_id]&#x3D;6775
     * @type {string}
     * @memberof BankSyncsApiShowBankSyncs
     */
    readonly filterUserId?: string

    /**
     * Filter results by creation date in ISO format,                               example filter[from]&#x3D;2024-04-01. Default is 1 month ago.
     * @type {string}
     * @memberof BankSyncsApiShowBankSyncs
     */
    readonly filterFrom?: string

    /**
     * Filter results by creation date in ISO format,                               example filter[to]&#x3D;2024-05-01. Default is now.
     * @type {string}
     * @memberof BankSyncsApiShowBankSyncs
     */
    readonly filterTo?: string
}

/**
 * BankSyncsApi - object-oriented interface
 * @export
 * @class BankSyncsApi
 * @extends {BaseAPI}
 */
export class BankSyncsApi extends BaseAPI {
    /**
     * 
     * @summary get bank synchronization data
     * @param {BankSyncsApiGetBankSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankSyncsApi
     */
    public getBankSync(requestParameters: BankSyncsApiGetBankSyncRequest, options?: RawAxiosRequestConfig) {
        return BankSyncsApiFp(this.configuration).getBankSync(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list bank syncs with the given filters
     * @param {BankSyncsApiShowBankSyncsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankSyncsApi
     */
    public showBankSyncs(requestParameters: BankSyncsApiShowBankSyncsRequest, options?: RawAxiosRequestConfig) {
        return BankSyncsApiFp(this.configuration).showBankSyncs(requestParameters.filterSlug, requestParameters.filterUserId, requestParameters.filterFrom, requestParameters.filterTo, options).then((request) => request(this.axios, this.basePath));
    }
}

