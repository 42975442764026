import React from "react";
import { format } from "date-fns";
import { toGermanNumberFormat } from "@helpers";
import { CustomTooltipContainer } from "@components/Chart";
import { ItemSettings } from "./ItemSettings";
import { Item } from "./Item";

export interface CustomTooltipProps {
  fieldName?: string;
  itemSettings: ItemSettings[];
  active: boolean;
  label: string;
  ref: React.RefObject<HTMLDivElement>;
  payload: Partial<{
    name: string;
    value: number;
    color: string;
    payload: any;
    dataKey: string;
  }>[];
}

export const CustomTooltip = React.forwardRef<
  HTMLDivElement,
  Partial<CustomTooltipProps>
>(({ itemSettings, active, payload = [], fieldName = "totalValue" }, ref) => {
  if (!active || !payload) return null;
  const overviewPayload = payload[1];
  if (!overviewPayload) return null;
  if (!overviewPayload.payload) return null;
  return (
    <CustomTooltipContainer
      ref={ref}
      firstRow={
        <Item
          isStrong
          color={overviewPayload.color}
          title={toGermanNumberFormat(
            overviewPayload.payload
              ? overviewPayload.payload[fieldName] ?? 0
              : 0
          )}
          subtitle={format(overviewPayload.payload?.date, "d.M.yyyy")}
        />
      }
    >
      {itemSettings?.map((setting, index) => (
        <Item
          key={`item-${index}`}
          color={setting.color}
          title={toGermanNumberFormat(
            overviewPayload.payload[setting.dataKey] ?? 0
          )}
          subtitle={setting.title}
        />
      ))}
    </CustomTooltipContainer>
  );
});
