import { UseQueryOptions } from "react-query";
import { NewsFeedsApiNewsByIsinRequest, FeedItemList } from "@generated/apiv1";

import { buildNewsFeedsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const newsByIsin = async (
  token: string | undefined,
  params: NewsFeedsApiNewsByIsinRequest
) => {
  const apiInstance = buildNewsFeedsApi(token);
  const { data } = await apiInstance.newsByIsin(params);
  return data;
};

export const useNewsByIsin = (
  params: NewsFeedsApiNewsByIsinRequest,
  options?: UseQueryOptions<FeedItemList, AxiosApiError, FeedItemList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.NEWSFEEDS_NEWS_BY_ISIN, params],
    ({ token }) => newsByIsin(token, params),
    options
  );
};
