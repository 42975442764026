/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateContractIdParams
 */
export interface CreateOrUpdateContractIdParams {
    /**
     * The (DB-level) ID; in case this is an update
     * @type {number}
     * @memberof CreateOrUpdateContractIdParams
     */
    'id'?: number;
    /**
     * The contract ID, required for creation
     * @type {string}
     * @memberof CreateOrUpdateContractIdParams
     */
    'contractId'?: string;
    /**
     * Is the contract ID curated (i.e. modified or confirmed by the user)?                                This flag will be forced true for manual creation; and also if the contract ID is manually                                changed.
     * @type {boolean}
     * @memberof CreateOrUpdateContractIdParams
     */
    'isContractIdCurated'?: boolean;
    /**
     * The type of ID; this may be a contract ID, a customer ID or something else; required for creation
     * @type {string}
     * @memberof CreateOrUpdateContractIdParams
     */
    'type'?: CreateOrUpdateContractIdParamsTypeEnum;
    /**
     * Is the type curated (i.e. modified or confirmed by the user)?                                This flag will be forced true for manual creation; and also if the type is manually                                changed.
     * @type {boolean}
     * @memberof CreateOrUpdateContractIdParams
     */
    'isTypeCurated'?: boolean;
    /**
     * Mark the contract ID as deleted
     * @type {boolean}
     * @memberof CreateOrUpdateContractIdParams
     */
    'isDeleted'?: boolean;
}

export const CreateOrUpdateContractIdParamsTypeEnum = {
    CONTRACT_ID: 'contract_id',
    CUSTOMER_ID: 'customer_id',
    OTHER_ID: 'other_id'
} as const;

export type CreateOrUpdateContractIdParamsTypeEnum = typeof CreateOrUpdateContractIdParamsTypeEnum[keyof typeof CreateOrUpdateContractIdParamsTypeEnum];


