import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from "react-query";
import {
  PageableTransactionList,
  Transaction,
  TransactionsApiCreateCashTransactionRequest,
  TransactionsApiListAndSearchAllTransactionRequest,
} from "@generated/apiv1";
import {
  ListAndSearchAllTransactionParams,
  downloadTransactions,
} from "@api/v1/methods";
import {
  listAndSearchAllTransaction,
  createCashTransaction,
} from "@generated/apiv1/hooks";
import { useAuthQuery, useAuthMutation, useAuthInfiniteQuery } from "@api/auth";
import { TRANSACTIONS } from "../../cacheKeys";

export const useTransactions = (
  params: ListAndSearchAllTransactionParams,
  options?: UseQueryOptions<
    PageableTransactionList,
    AxiosApiError,
    PageableTransactionList
  >
) =>
  useAuthQuery<PageableTransactionList, AxiosApiError, PageableTransactionList>(
    [TRANSACTIONS, params],
    ({ token }) => listAndSearchAllTransaction(token, params),
    options
  );

export const useInfiniteTransactions = (
  params: TransactionsApiListAndSearchAllTransactionRequest,
  options?: UseInfiniteQueryOptions<
    PageableTransactionList,
    AxiosApiError,
    PageableTransactionList
  >
) =>
  useAuthInfiniteQuery<
    PageableTransactionList,
    AxiosApiError,
    PageableTransactionList
  >(
    [TRANSACTIONS, params],
    ({ token, pageParam }) =>
      listAndSearchAllTransaction(token, { ...params, page: pageParam || 1 }),
    options
  );

export const useCreateTransaction = (
  options: UseMutationOptions<
    Transaction,
    AxiosApiError,
    TransactionsApiCreateCashTransactionRequest
  > = {}
) => useAuthMutation(createCashTransaction, options);

export const useDownloadTransactions = (
  params: ListAndSearchAllTransactionParams,
  options?: UseMutationOptions<Blob, AxiosApiError, unknown>
) => useAuthMutation((token) => downloadTransactions(token, params), options);
