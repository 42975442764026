import { isEqual } from "date-fns";
import { getPercent } from "@helpers";
import { DateValuePair } from "@generated/apiv1";

export const convertToChartData = (
  mainData: DateValuePair[],
  comparisonData?: DateValuePair[],
  chartType: "valuation" | "performance" = "valuation"
): { mainValue: [number, number]; comparedValue: [number, number] }[] => {
  if (!mainData) return [];

  const newComparisonData = mainData.map((item) => {
    const foundComparisonItem = comparisonData?.find((dataItem) =>
      isEqual(new Date(dataItem.date!), new Date(item.date!))
    );

    return {
      date: item.date,
      value: item.value,
      comparedValue: foundComparisonItem?.value ?? 0,
    };
  });

  let reference = newComparisonData[0];
  let comparedValue = 1;
  let currentValue = 1;

  return newComparisonData.map((current) => {
    currentValue *= (current.value ?? 0) / (reference.value || 1);
    if (current.comparedValue && reference.comparedValue) {
      comparedValue *= current.comparedValue / reference.comparedValue;
    }

    reference = current;

    const percents = getPercent(currentValue);
    const secondaryPercents = getPercent(comparedValue);

    return {
      mainValue: [
        new Date(current.date!).getTime(),
        (chartType === "performance" ? percents : current.value) || 0,
      ],
      comparedValue: [new Date(current.date!).getTime(), secondaryPercents],
    };
  });
};
