import React from "react";
import { useGetWatchlistEntry } from "@api";
import { GainLossStatistics } from "./GainLossStatistics";

interface Props {
  watchlistEntryId: number;
}

export const GainLossStatisticsLoader = ({ watchlistEntryId }: Props) => {
  const { data: watchlistEntry, isLoading: isWatchlistEntryLoading } =
    useGetWatchlistEntry(watchlistEntryId);

  return (
    <GainLossStatistics
      isLoading={isWatchlistEntryLoading}
      watchlistEntry={watchlistEntry}
    />
  );
};
