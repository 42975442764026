import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SearchModal } from "@components/common/layouts/AppSidebar/SearchModal";
import { SymbolV2 } from "@generated/apiv1";
import { ReactComponent as SearchIcon } from "../../../../icons/search.svg";

const Container = styled(Box)<{ collapsed: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.appSidebar?.color};
  background-color: ${({ theme }) => theme.appSidebar?.search?.backgroundColor};
  border: ${({ theme }) =>
    `1px solid ${theme.appSidebar?.search?.borderColor}`};
`;

const SmallSearchButton = styled(Box)`
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  cursor: pointer;
  color: ${({ theme }) => theme.appSidebar?.menuItem?.color};

  &:hover {
    background-color: ${({ theme }) =>
      theme.appSidebar?.menuItem?.hoverBackgroundColor};

    & svg {
      color: ${({ theme }) => theme.appSidebar?.menuItem?.hoverColor};
    }
  }
`;

interface FakeSearchFieldProps {
  value?: string;
  collapsed: boolean;
  onSymbolSelect: (symbol: SymbolV2) => void;
}

export const FakeSearchField = (props: FakeSearchFieldProps) => {
  const { collapsed, value, onSymbolSelect } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const { t } = useTranslation();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!(event.target as HTMLElement).closest(".clear")) {
      setOpen(true);
    }
  };

  const handleSymbolSelect = (symbol: SymbolV2) => {
    setOpen(false);
    onSymbolSelect?.(symbol);
  };

  if (collapsed && !matches) {
    return (
      <>
        <SmallSearchButton onClick={handleClick}>
          <SearchIcon color={theme.appSidebar?.menuItem?.selectedColor} />
        </SmallSearchButton>
        <SearchModal
          isOpen={open}
          onClose={() => setOpen(false)}
          onSymbolSelect={handleSymbolSelect}
        />
      </>
    );
  }

  return (
    <>
      <Container onClick={handleClick} collapsed={collapsed ? 1 : 0} gap={3.5}>
        <Box minWidth={0} flexShrink={1}>
          <Typography
            variant="body1"
            color={
              value
                ? theme.palette.text.primary
                : theme.appSidebar?.search?.color || theme.appSidebar?.color
            }
            fontWeight="400"
            noWrap
          >
            {value || t("sidebar.searchField")}
          </Typography>
        </Box>
        <SearchIcon color={theme.appSidebar?.search?.iconColor} />
      </Container>
      {open && (
        <SearchModal
          isOpen={open}
          onClose={() => setOpen(false)}
          onSymbolSelect={handleSymbolSelect}
        />
      )}
    </>
  );
};
