import { UseQueryOptions } from "react-query";
import {
  AccountsApiListAndSearchAllAccountsTppWebFormRequest,
  AccountList,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllAccountsTppWebForm = async (
  token: string | undefined,
  params: AccountsApiListAndSearchAllAccountsTppWebFormRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.listAndSearchAllAccountsTppWebForm(params);
  return data;
};

export const useListAndSearchAllAccountsTppWebForm = (
  params: AccountsApiListAndSearchAllAccountsTppWebFormRequest,
  options?: UseQueryOptions<AccountList, AxiosApiError, AccountList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_LIST_AND_SEARCH_ALL_ACCOUNTS_TPP_WEB_FORM, params],
    ({ token }) => listAndSearchAllAccountsTppWebForm(token, params),
    options
  );
};
