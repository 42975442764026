import { InvokeCallback, Sender } from "xstate";

import { getInvestmentWithToken } from "@api/v1/methods/investments";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";

export const loadInvestment =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (
      event.type !== "INIT_FROM_INVESTMENT" &&
      event.type !== "INIT_FROM_DIVIDEND"
    )
      throw new Error(`Invalid event type ${event.type}`);

    load(event.investmentId, callback);
  };

const load = async (
  investmentId: number,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const investment = await getInvestmentWithToken(investmentId);

    callback({
      type: "LOADED_INVESTMENT",
      investment,
    });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
