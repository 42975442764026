import { useEffect, useRef } from "react";

export const useFocus = (focus: boolean) => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!focus) {
      return;
    }
    inputEl?.current?.focus();
  }, [focus]);

  return inputEl;
};
