/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DividendRecordAttributes } from './dividend-record-attributes';

/**
 * 
 * @export
 * @interface DividendAttributes
 */
export interface DividendAttributes {
    /**
     * 
     * @type {string}
     * @memberof DividendAttributes
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DividendAttributes
     */
    'security_type'?: DividendAttributesSecurityTypeEnum | null;
    /**
     * 
     * @type {Array<DividendRecordAttributes>}
     * @memberof DividendAttributes
     */
    'records'?: Array<DividendRecordAttributes>;
}

export const DividendAttributesSecurityTypeEnum = {
    _11_STOCK: '11_stock',
    _21_FUND: '21_fund',
    _31_BOND: '31_bond'
} as const;

export type DividendAttributesSecurityTypeEnum = typeof DividendAttributesSecurityTypeEnum[keyof typeof DividendAttributesSecurityTypeEnum];


