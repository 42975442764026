import { UseQueryOptions } from "react-query";
import {
  DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest,
  DepotSynchronizationLog,
} from "@generated/apiv1";

import { buildDepotSynchronizationLogsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const showDepotSynchronizationLog = async (
  token: string | undefined,
  params: DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest
) => {
  const apiInstance = buildDepotSynchronizationLogsApi(token);
  const { data } = await apiInstance.showDepotSynchronizationLog(params);
  return data;
};

export const useShowDepotSynchronizationLog = (
  params: DepotSynchronizationLogsApiShowDepotSynchronizationLogRequest,
  options?: UseQueryOptions<
    DepotSynchronizationLog,
    AxiosApiError,
    DepotSynchronizationLog
  >
) => {
  return useAuthQuery(
    [
      CACHE_KEYS.DEPOTSYNCHRONIZATIONLOGS_SHOW_DEPOT_SYNCHRONIZATION_LOG,
      params,
    ],
    ({ token }) => showDepotSynchronizationLog(token, params),
    options
  );
};
