import React from "react";
import styled from "@emotion/styled";
import { Symbol } from "@api";
import { SymbolSearch } from "@features/searchSymbol/components/SymbolSearch";
import { SecuritiesMessage } from "./SecuritiesMessage";

const SecuritiesSearchStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  max-width: 700px;
`;

interface SecuritiesSearchStepProps {
  onSymbolSelect: (symbol: Symbol) => void;
  noManual?: boolean;
}

export const SecuritiesSearchStep = (props: SecuritiesSearchStepProps) => (
  <SecuritiesSearchStepWrapper>
    <SecuritiesMessage />
    <SymbolSearch
      onSelectSymbol={props.onSymbolSelect}
      noManual={props.noManual}
    />
  </SecuritiesSearchStepWrapper>
);
