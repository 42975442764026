import { useNavigate } from "react-router-dom";
import qs from "query-string";
import { MultibankingApiProvider } from "@api";
import { createAndRedirectToTppWebForm } from "@helpers/createTppWebForm";

export const useRoutes = () => {
  const navigate = useNavigate();
  const pushSelectBank = (q?: string) => {
    // @ts-ignore
    const params = new URL(window.location).searchParams;
    const type = params.get("type");

    navigate({
      pathname: `/import-bank-connection/select-bank`,
      search: q
        ? `q=${qs.stringify(qs.parse(q))}${type ? `&type=${type}` : ""}`
        : type
        ? `?type=${type}`
        : "",
    });
  };

  const pushImportConnection = async (
    id: number | string,
    multibankingProvider: MultibankingApiProvider,
    wealthApiSlug?: string
  ) => {
    return await createAndRedirectToTppWebForm(
      multibankingProvider,
      id || wealthApiSlug,
      undefined
    );
  };

  const pushUpdateConnection = async (
    bankConnectionId: number,
    multibankingProvider: MultibankingApiProvider
  ) => {
    return await createAndRedirectToTppWebForm(
      multibankingProvider,
      undefined,
      bankConnectionId
    );
  };

  const pushLegalNote = (
    id: number | string,
    multibankingProvider: MultibankingApiProvider,
    wealthApiSlug?: string
  ) => {
    if (multibankingProvider === "WEALTH_API") {
      createAndRedirectToTppWebForm(
        multibankingProvider,
        wealthApiSlug,
        undefined
      );
      return;
    }
    navigate(`/import-bank-connection/${id}/legal-note`);
  };

  return {
    pushSelectBank,
    pushImportConnection,
    pushUpdateConnection,
    pushLegalNote,
  };
};
