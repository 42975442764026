import { useEffect } from "react";
import { findLast } from "lodash";

type Props = {
  contextId?: string;
  userFlowId: string;
  pageTitle?: string | null;
  pageRoute?: string | null;
  data?: Record<string, unknown>;
  enabled?: boolean;
};

export const useGoogleTagManagerVirtualPage = (
  {
    contextId,
    userFlowId,
    pageTitle,
    pageRoute,
    data = {},
    enabled = true,
  }: Props,
  deps?: unknown[]
) => {
  useEffect(() => {
    const dataLayer = window.dataLayer;
    if (!contextId || !dataLayer || !enabled) return;

    // ensure that we don't track repeated renders
    if (
      findLast(dataLayer, (layer) => {
        return layer.event === "track-vp";
      })?.contextId === contextId
    ) {
      return;
    }

    dataLayer?.push({
      event: "track-vp",
      userFlowId,
      contextId,
      pageRoute: pageRoute || `/virtual/${contextId}`,
      pageTitle,
      ...data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || [contextId, userFlowId, pageTitle, data, enabled]);
};
