/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { UserReference } from './user-reference';

/**
 * A cashback incident, i.e. something that is not in order
 * @export
 * @interface CashbackIncident
 */
export interface CashbackIncident {
    /**
     * Technical identifier
     * @type {number}
     * @memberof CashbackIncident
     */
    'id': number;
    /**
     * The subscription the incident belongs to
     * @type {number}
     * @memberof CashbackIncident
     */
    'subscriptionId': number;
    /**
     * The incident type. This is a freetext field for user reference only
     * @type {string}
     * @memberof CashbackIncident
     */
    'type': string;
    /**
     * The incident status
     * @type {string}
     * @memberof CashbackIncident
     */
    'status': CashbackIncidentStatusEnum;
    /**
     * The incident comment (a longtext)
     * @type {string}
     * @memberof CashbackIncident
     */
    'comment'?: string;
    /**
     * Any user-defined external reference; e.g. a ticket link
     * @type {string}
     * @memberof CashbackIncident
     */
    'externalReference'?: string;
    /**
     * 
     * @type {UserReference}
     * @memberof CashbackIncident
     */
    'createdBy': UserReference;
    /**
     * The creation date
     * @type {string}
     * @memberof CashbackIncident
     */
    'createdAt'?: string;
    /**
     * The last update date
     * @type {string}
     * @memberof CashbackIncident
     */
    'updatedAt'?: string;
}

export const CashbackIncidentStatusEnum = {
    ACTIVE: 'active',
    RESOLVED: 'resolved'
} as const;

export type CashbackIncidentStatusEnum = typeof CashbackIncidentStatusEnum[keyof typeof CashbackIncidentStatusEnum];


