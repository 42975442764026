import { searchSymbols } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";

export const useSearchSymbols = (search?: string, perPage = 10) =>
  useAuthQuery(
    ["search-symbols", search!],
    ({ token }) => searchSymbols(token, search!, perPage),
    {
      enabled: !!search && search?.trim()?.length >= 3,
    }
  );
