import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetQuotesV2,
  useListAndSearchAllWatchlists,
  useSearchSymbolV2,
} from "@generated/apiv1/hooks";
import { LoadingProgressBar } from "@components";

export const JumpToSecurityPage = () => {
  const [searchParams] = useSearchParams();
  const isin = searchParams.get("isin");
  const type = searchParams.get("type");

  const { data, isFetched } = useSearchSymbolV2(
    {
      isinOrWkn: isin as string,
    },
    {
      enabled: Boolean(isin),
    }
  );
  const { data: quotes, isFetched: isQuoteFetched } = useGetQuotesV2(
    {
      // @ts-ignore
      securityId: [data?.symbols?.[0]?.id],
      quoteProvider: data?.symbols?.[0]?.quoteProvider || "tte",
    },
    {
      enabled: Boolean(data?.symbols?.[0]),
    }
  );

  const { data: watchlistsData, isFetched: isWatchlistFetched } =
    useListAndSearchAllWatchlists(
      {},
      {
        enabled: type === "watchlist",
      }
    );

  const security = data?.symbols?.[0];
  const quote = quotes?.quotes?.[0];
  const watchlist = watchlistsData?.watchlists?.[0];

  useEffect(() => {
    let url;
    switch (type) {
      case "watchlist":
        if (!isFetched || !isQuoteFetched || !isWatchlistFetched) return;

        if (!watchlist) {
          url = `${process.env.REACT_APP_V2_URL}/watchlists`;
        } else if (security && quote && watchlist) {
          url = `${process.env.REACT_APP_V2_URL}/watchlists/${watchlist.id}/securities/add?quoteProvider=${security.quoteProvider}&tickerSymbol=${quote.tickerSymbol}`;
        }
        break;
      case "portfolio":
        if (!isFetched || !isQuoteFetched) return;
        if (security && quote) {
          url = `${process.env.REACT_APP_V2_URL}/new-transaction-wizard?quoteProvider=${security.quoteProvider}&tickerSymbol=${quote.tickerSymbol}`;
        }
        break;
      default:
        if (!isFetched) return;
        if (security) {
          url = `${process.env.REACT_APP_V2_URL}/securities?id=${security.id}&quoteProvider=${security.quoteProvider}`;
        }
    }

    window.location.replace(url ?? process.env.REACT_APP_V2_URL!);
  }, [
    security,
    quote,
    isFetched,
    isQuoteFetched,
    type,
    watchlist,
    isWatchlistFetched,
  ]);

  return <LoadingProgressBar />;
};
