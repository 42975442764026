import { UseMutationOptions } from "react-query";
import {
  BookingsApiSwitchInvestmentRequest,
  TransactionValidationErrorList,
} from "@generated/apiv1";

import { buildBookingsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const switchInvestment = async (
  token: string | undefined,
  params: BookingsApiSwitchInvestmentRequest
) => {
  const apiInstance = buildBookingsApi(token);
  const { data } = await apiInstance.switchInvestment(params);
  return data;
};

export const useSwitchInvestment = (
  options?: UseMutationOptions<
    TransactionValidationErrorList,
    AxiosApiError,
    BookingsApiSwitchInvestmentRequest
  >
) => {
  return useAuthMutation(switchInvestment, options, false);
};
