import { useAuthQuery, loadFundamental } from "@api";

export const useFundamental = (isin?: string) =>
  useAuthQuery(
    ["fundamental", isin!],
    ({ token }) => loadFundamental(token, isin!),
    {
      enabled: !!isin,
      refetchOnWindowFocus: false,
    }
  );
