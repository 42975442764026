/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SubcategoryMeta
 */
export interface SubcategoryMeta {
    /**
     * Status of the subcategory if it is active or deleted
     * @type {string}
     * @memberof SubcategoryMeta
     */
    'status': SubcategoryMetaStatusEnum;
}

export const SubcategoryMetaStatusEnum = {
    ACTIVE: 'active',
    DELETED: 'deleted'
} as const;

export type SubcategoryMetaStatusEnum = typeof SubcategoryMetaStatusEnum[keyof typeof SubcategoryMetaStatusEnum];


