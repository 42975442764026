/* tslint:disable */
/* eslint-disable */
/**
 * Finanzfluss API
 * An API for the <a href=\"https://www.finanzfluss.de/\">Finanzfluss</a> backend. Work in progress.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@finanzfluss.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * User model
 * @export
 * @interface User
 */
export interface User {
    /**
     * A unique identifier for the user, used for Stripe, e.g. \"usr_123abc\"
     * @type {string}
     * @memberof User
     */
    'uuid': string;
    /**
     * The user name / nick name that the user has picked, e.g. \"Thomas\"
     * @type {string}
     * @memberof User
     */
    'displayName': string;
    /**
     * The email address of the user
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Whether the user has the Copilot Plus role
     * @type {boolean}
     * @memberof User
     */
    'hasActiveCopilotPlusSubscription'?: boolean;
    /**
     * The discord ID of the user, e.g. \"123456\"
     * @type {string}
     * @memberof User
     */
    'discordId'?: string;
    /**
     * Whether the user has enabled OTP MFA for login
     * @type {boolean}
     * @memberof User
     */
    'otpRequiredForLogin'?: boolean;
    /**
     * The label for the OTP MFA device, e.g. \"My Phone\"
     * @type {string}
     * @memberof User
     */
    'otpLabel'?: string;
    /**
     * The duration after which the user will be automatically signed out
     * @type {string}
     * @memberof User
     */
    'autoSignOutDuration'?: UserAutoSignOutDurationEnum;
}

export const UserAutoSignOutDurationEnum = {
    _15MINS: '15mins',
    _30MINS: '30mins',
    _1H: '1h',
    _3H: '3h',
    _6H: '6h',
    _12H: '12h',
    _24H: '24h',
    _1_W: '1W',
    _1_M: '1M'
} as const;

export type UserAutoSignOutDurationEnum = typeof UserAutoSignOutDurationEnum[keyof typeof UserAutoSignOutDurationEnum];


