import React, { createContext, useContext, useState } from "react";

export type Image = {
  isLoading?: boolean;
  value: string | null | undefined;
};

interface SecurityImagesContextProps {
  images: Record<string, Image>;
  setImages: React.Dispatch<React.SetStateAction<Record<string, Image>>>;
}

const SecurityImagesContext = createContext<
  SecurityImagesContextProps | undefined
>(undefined);

export const useSecurityImagesContext = (): SecurityImagesContextProps => {
  const context = useContext(SecurityImagesContext);
  if (!context) {
    throw new Error(
      "useSecurityImages must be used within a SecurityImagesProvider"
    );
  }
  return context;
};

export const SecurityImagesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [images, setImages] = useState<Record<string, Image>>({});

  return (
    <SecurityImagesContext.Provider value={{ images, setImages }}>
      {children}
    </SecurityImagesContext.Provider>
  );
};
