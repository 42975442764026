import { UseMutationOptions } from "react-query";
import { UserProfilesApiDeleteUserProfileImageRequest } from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteUserProfileImage = async (
  token: string | undefined,
  params: UserProfilesApiDeleteUserProfileImageRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.deleteUserProfileImage(params);
  return data;
};

export const useDeleteUserProfileImage = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    UserProfilesApiDeleteUserProfileImageRequest
  >
) => {
  return useAuthMutation(deleteUserProfileImage, options, false);
};
