import { UseQueryOptions } from "react-query";
import {
  TppCertificatesApiListAndSearchAllTppCertificatesRequest,
  PageableTppCertificateList,
} from "@generated/apiv1";

import { buildTppCertificatesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listAndSearchAllTppCertificates = async (
  token: string | undefined,
  params?: TppCertificatesApiListAndSearchAllTppCertificatesRequest
) => {
  const apiInstance = buildTppCertificatesApi(token);
  const { data } = await apiInstance.listAndSearchAllTppCertificates(params);
  return data;
};

export const useListAndSearchAllTppCertificates = (
  params?: TppCertificatesApiListAndSearchAllTppCertificatesRequest,
  options?: UseQueryOptions<
    PageableTppCertificateList,
    AxiosApiError,
    PageableTppCertificateList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.TPPCERTIFICATES_LIST_AND_SEARCH_ALL_TPP_CERTIFICATES, params],
    ({ token }) => listAndSearchAllTppCertificates(token, params),
    options
  );
};
