import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { ReactComponent as CoinIcon } from "@icons/myicons/coin.svg";

export const SettingsNoConnections = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={theme.spacing(10)}
    >
      <CoinIcon />
      <Typography
        variant="body1"
        fontSize="26px"
        lineHeight="34px"
        fontWeight={600}
        letterSpacing={-0.4}
        m={theme.spacing(6, 0, 11.5)}
      >
        {t("settingsPage.connections.noAccounts.title")}
      </Typography>
      <Button
        sx={{ width: "100%", border: "none!important" }}
        variant="contained"
        color="primary"
        onClick={() => {
          navigate("/import-bank-connection/select-bank");
        }}
      >
        {t("settingsPage.connections.noAccounts.buttonText")}
      </Button>
    </Box>
  );
};
