import { addYears, startOfDay, endOfDay, parseJSON } from "date-fns";
import { usePerformanceByAccounts } from "@api/v1/hooks/performance/usePerformanceByAccounts";

export const useAccountPerformance = (
  accountId: number,
  withCash: boolean = false
) => {
  const { data, ...rest } = usePerformanceByAccounts(
    {
      accountIds: [accountId],
      startDate: startOfDay(addYears(new Date(), -1)),
      endDate: endOfDay(new Date()),
    },
    { staleTime: Infinity }
  );
  const statistic = withCash ? data?.withCash : data?.withoutCash;
  const chartData = statistic?.twrorResults.map(({ date, portfolioValue }) => ({
    date: parseJSON(date),
    value: portfolioValue,
  }));

  const portfolioValue = chartData
    ? chartData[chartData?.length - 1]?.value
    : 0;

  const sinceYear = chartData ? chartData[0]?.date.getFullYear() : undefined;

  return {
    data,
    chartData,
    portfolioValue,
    changePercent: statistic?.changeYTD?.changePercent,
    changeAbsCashFlowAdjusted:
      statistic?.changeTotal?.changeAbsCashFlowAdjusted,
    sinceYear,
    ...rest,
  };
};
