import { UseQueryOptions } from "react-query";
import {
  BankConnectionsApiUpdateAuthenticationProcessRequest,
  BankAuthenticationProcess,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const updateAuthenticationProcess = async (
  token: string | undefined,
  params: BankConnectionsApiUpdateAuthenticationProcessRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.updateAuthenticationProcess(params);
  return data;
};

export const useUpdateAuthenticationProcess = (
  params: BankConnectionsApiUpdateAuthenticationProcessRequest,
  options?: UseQueryOptions<
    BankAuthenticationProcess,
    AxiosApiError,
    BankAuthenticationProcess
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.BANKCONNECTIONS_UPDATE_AUTHENTICATION_PROCESS, params],
    ({ token }) => updateAuthenticationProcess(token, params),
    options
  );
};
