import React, { useContext } from "react";
import { Box, BoxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Banner, LinkWithArrow } from "@components";
import { useMyself } from "@api";
import { SharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { useAppLayout } from "@providers/AppLayoutProvider";
import { SIGNUP } from "@helpers/urlConstants";

type Props = BoxProps & {};

export const SharedBanner = (props: Props) => {
  const { t } = useTranslation();
  const { settings, isSharedPortfolio } = useContext(SharedPortfolioContext);
  const { data: myself, isFetched: isMyselfInfoFetched } = useMyself();
  const isLess1200 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1200)
  );
  const isLess1000 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1000)
  );
  const isLess750 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(750)
  );
  const isLess950 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(950)
  );
  const { sidebarCollapsed } = useAppLayout();

  if (!isMyselfInfoFetched || !isSharedPortfolio || !settings) return null;

  return (
    <Banner
      breakpoint={myself?.id ? 1000 : 750}
      type="info"
      text={
        <Box
          display="inline-flex"
          gap={1}
          flexDirection={
            isLess950 || (isLess1200 && !sidebarCollapsed) ? "column" : "row"
          }
        >
          <Typography variant="body1">
            {t("portfolioShare.banner.portfolio", {
              portfolio: settings.name,
            })}
          </Typography>
          {!myself?.id && (
            <Typography variant="body1" fontWeight={400}>
              {t("portfolioShare.banner.createYourOwn")}
            </Typography>
          )}
        </Box>
      }
      endAdornment={
        <Box mt={(myself?.id && isLess1000) || isLess750 ? 1.5 : 0}>
          {myself?.id ? (
            <LinkWithArrow
              text={t("portfolioShare.banner.toMyDashboard")}
              href={process.env.REACT_APP_V2_URL!}
            />
          ) : (
            <LinkWithArrow
              text={t("portfolioShare.banner.register")}
              href={SIGNUP}
            />
          )}
        </Box>
      }
      {...props}
    />
  );
};
