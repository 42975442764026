import React, { createContext, useContext, useMemo } from "react";
import { isEqual } from "lodash";
import { usePublicOrPrivateData } from "@features/sharedPortfolio/hooks/usePublicOrPrivateData";
import {
  useDividendTimeline,
  usePublicDividendTimeline,
} from "@generated/apiv1/hooks";
import { useSecurityImages } from "@hooks";
import {
  DividendHistoryTreeNode,
  DividendTimelineGroupByEnum,
  DividendTimelineTypeEnum,
  InvestmentReference,
} from "@generated/apiv1";
import { useMyself } from "@api";
import { useSecurityImagesContext } from "@providers/SecurityImageProvider";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";

const currentYear = new Date().getFullYear();
const defaultFilter = {
  investmentIsins: [],
  investmentTypes: [],
  includePrediction: true,
  sortByPaymentDate: true,
};

export interface DividendsContextProps {
  dividends?: Record<string, DividendHistoryTreeNode>;
  investments?: Record<string, InvestmentReference>;
  dividendsForFilters?: Record<string, DividendHistoryTreeNode>;
  investmentsForFilters: Record<string, InvestmentReference>;
  images: Record<string, string | null | undefined>;
  isLoading: boolean;
  setFilter: (filter: Filter) => void;
  filter: Filter;
  areFiltersApplied: boolean;
  isUserPro: boolean;
  isUserDataFetched: boolean;
  areImagesFetched: boolean;
}

const DividendsContext = createContext<DividendsContextProps>({
  dividends: {},
  investments: {},
  investmentsForFilters: {},
  dividendsForFilters: {},
  images: {},
  isLoading: true,
  setFilter: () => {},
  filter: defaultFilter,
  areFiltersApplied: false,
  isUserPro: false,
  isUserDataFetched: false,
  areImagesFetched: false,
});

export const useDividendsContext = (): DividendsContextProps =>
  useContext(DividendsContext);

export const useDividendsForPeriod = ({
  month,
  year,
  day,
}: {
  month?: number;
  year?: number;
  day?: number;
} = {}): Record<string, DividendHistoryTreeNode> | null => {
  const { dividends } = useDividendsContext();

  return useMemo(() => {
    if (day && month !== undefined && year) {
      const currentMonth = dividends?.[String(year)]?.children?.[String(month)];
      const currentDayDividends = Object.values(currentMonth?.children || {})
        .filter((dividend) => new Date(dividend.paymentDate).getDate() === day)
        .reduce(
          (acc, dividend) => ({
            ...acc,
            [dividend.investmentIds[0]]: dividend,
          }),
          {} as Record<number, DividendHistoryTreeNode>
        );

      return currentMonth
        ? {
            [String(month)]: {
              ...currentMonth,
              children: currentDayDividends,
            },
          }
        : null;
    } else if (month !== undefined && year) {
      const currentMonth = dividends?.[String(year)]?.children?.[String(month)];
      return currentMonth
        ? {
            [String(month)]: currentMonth,
          }
        : null;
    } else if (year) {
      const currentYear = dividends?.[String(year)];
      return currentYear
        ? {
            [String(year)]: dividends?.[String(year)],
          }
        : null;
    }

    return dividends || null;
  }, [dividends, month, year, day]);
};

const getFilterFromLocalStorage = (filterKey: string): Filter => {
  try {
    const filter = localStorage.getItem(`dividendsFilter-${filterKey}`);
    return filter ? JSON.parse(filter) : defaultFilter;
  } catch (err) {
    return defaultFilter;
  }
};

export type Filter = {
  investmentIsins: string[];
  investmentTypes: DividendTimelineTypeEnum[];
  includePrediction: boolean;
  sortByPaymentDate: boolean;
};

const DividendsProvider: React.FC<{
  filterKey: string;
  children: React.ReactNode;
  accountIds: number[];
  predictYears?: number;
}> = (props) => {
  const { children, accountIds, predictYears = 1 } = props;
  const { isOpenByAnon } = useSharedPortfolioContext();
  const { data, isFetchedAfterMount: isUserDataFetched } = useMyself();
  const { images: fullImagesData } = useSecurityImagesContext();
  const isUserPro = Boolean(data?.isUserPro);
  const [filter, setFilter] = React.useState<Filter>(
    getFilterFromLocalStorage(props.filterKey)
  );

  const { data: timelineDataForFilters } = usePublicOrPrivateData({
    usePrivateMethod: useDividendTimeline,
    usePublicMethod: usePublicDividendTimeline,
    params: {
      accountId: accountIds,
      startYear: currentYear - 100,
      endYear: currentYear + 1,
    },
    options: {
      enabled: Boolean(accountIds.length),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const investmentIds = useMemo(() => {
    return Object.values(timelineDataForFilters?.investmentReferenceById || {})
      .filter((inv) => filter.investmentIsins.includes(inv.isin))
      .map((inv) => inv.id);
  }, [timelineDataForFilters, filter]);

  const {
    data: timeline,
    isLoading,
    isFetched,
  } = usePublicOrPrivateData({
    usePrivateMethod: useDividendTimeline,
    usePublicMethod: usePublicDividendTimeline,
    params: {
      accountId: filter.investmentIsins.length ? undefined : accountIds,
      type: filter.investmentTypes,
      investmentId: investmentIds,
      startYear: currentYear - 100,
      endYear: currentYear + predictYears,
      groupBy: filter.sortByPaymentDate
        ? DividendTimelineGroupByEnum.PAYOUT_DATE
        : DividendTimelineGroupByEnum.EX_DATE,
    },
    options: {
      enabled: Boolean(accountIds.length),
    },
  });

  const images = useSecurityImages(
    Object.values(timeline?.investmentReferenceById || {}).map((inv) => ({
      isin: inv.isin,
      ticker_symbol: inv.tickerSymbol,
      type: inv.type,
      quoteProvider: inv.quoteProvider,
      hasValidIsin: inv.hasValidIsin,
    }))
  );

  const dividends = !filter.includePrediction
    ? Object.entries(timeline?.nodesByYear || {}).reduce(
        (acc, [year, nodes]) => {
          const monthNodes = Object.entries(nodes.children || {}).reduce(
            (acc, [month, monthData]) => {
              return monthData.isPrediction
                ? acc
                : { ...acc, [month]: monthData };
            },
            {} as Record<string, DividendHistoryTreeNode>
          );

          if (Object.keys(monthNodes).length) {
            return {
              ...acc,
              [year]: {
                ...nodes,
                children: monthNodes,
              },
            };
          }

          return acc;
        },
        {} as Record<string, DividendHistoryTreeNode>
      )
    : timeline?.nodesByYear;

  const investmentsForFilters = useMemo(() => {
    return Object.values(
      timelineDataForFilters?.investmentReferenceById || {}
    ).reduce((acc, investment) => {
      const isExisting = Object.values(
        timelineDataForFilters?.nodesByYear || {}
      ).some((node) => node.investmentIds.includes(investment.id));
      return isExisting ? { ...acc, [investment.id]: investment } : acc;
    }, {} as Record<string, InvestmentReference>);
  }, [timelineDataForFilters]);

  const changeFilter = (filter: Filter) => {
    setFilter(filter);
    localStorage.setItem(
      `dividendsFilter-${props.filterKey}`,
      JSON.stringify(filter)
    );
  };

  const areImagesFetched = useMemo(() => {
    return (
      isFetched &&
      Object.values(fullImagesData).every((img) => img.isLoading === false)
    );
  }, [isFetched, fullImagesData]);

  return (
    <DividendsContext.Provider
      value={{
        dividends,
        investments: timeline?.investmentReferenceById,
        investmentsForFilters,
        dividendsForFilters: timelineDataForFilters?.nodesByYear,
        images,
        isLoading: !isFetched || isLoading,
        setFilter: changeFilter,
        filter,
        isUserPro,
        isUserDataFetched: isOpenByAnon ? true : isUserDataFetched,
        areFiltersApplied: !isEqual(filter, defaultFilter),
        areImagesFetched,
      }}
    >
      {children}
    </DividendsContext.Provider>
  );
};

export { DividendsContext, DividendsProvider };
