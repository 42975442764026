import { UseMutationOptions } from "react-query";
import {
  WatchlistsApiCreateWatchlistRequest,
  Watchlist,
} from "@generated/apiv1";

import { buildWatchlistsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createWatchlist = async (
  token: string | undefined,
  params: WatchlistsApiCreateWatchlistRequest
) => {
  const apiInstance = buildWatchlistsApi(token);
  const { data } = await apiInstance.createWatchlist(params);
  return data;
};

export const useCreateWatchlist = (
  options?: UseMutationOptions<
    Watchlist,
    AxiosApiError,
    WatchlistsApiCreateWatchlistRequest
  >
) => {
  return useAuthMutation(createWatchlist, options, false);
};
