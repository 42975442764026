import { UseMutationOptions } from "react-query";
import { OtpApiOtpSetupRequest, OtpRecoveryCodes } from "@generated/user";

import { buildOtpApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const otpSetup = async (
  token: string | undefined,
  params: OtpApiOtpSetupRequest
) => {
  const apiInstance = buildOtpApi(token);
  const { data } = await apiInstance.otpSetup(params);
  return data;
};

export const useOtpSetup = (
  options?: UseMutationOptions<
    OtpRecoveryCodes,
    AxiosApiError,
    OtpApiOtpSetupRequest
  >
) => {
  return useAuthMutation(otpSetup, options, true);
};
