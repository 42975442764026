import { Palette } from "@mui/material/styles";
import { Event } from "@components/Chart/InvestmentChart/types";

export const getEventLabel = (event: Event, colors: Palette) => {
  if (event.type === "expected_dividend") {
    return { name: "D", color: colors?.success.dark };
  }

  if (event.type === "buy") {
    return { name: "K", color: colors?.primary.dark };
  }

  if (event.type === "sell") {
    return { name: "V", color: colors?.error.dark };
  }

  if (event.type === "carve_out") {
    return { name: "C", color: colors?.error.dark };
  }

  if (event.type === "dividend") {
    return { name: "D", color: colors?.success.dark };
  }

  if (event.type === "book_in") {
    return { name: "K", color: colors?.primary.dark };
  }

  if (event.type === "book_out") {
    return { name: "V", color: colors?.error.dark };
  }

  if (event.type === "retained_dividend") {
    return { name: "D", color: colors?.success.dark };
  }

  if (event.type === "purchase_right") {
    return { name: "B", color: colors?.error.dark };
  }

  if (event.type === "split") {
    return { name: "SP", color: colors?.primary.dark };
  }

  return { name: "unknown", color: colors?.common.black };
};

export const getEventLabelForEvents = (events: Event[], colors: Palette) => {
  if (events.length > 1) {
    return { name: events.length.toString(), color: colors?.grey[400] };
  }
  const event = events[0];

  return getEventLabel(event, colors);
};
