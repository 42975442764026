import { axiosInstanceV1 } from "@api/axiosInstance";
import { UserAuthorization } from "../model";

export const getMyAuthorization = async (token: string | undefined) => {
  const { data } = await axiosInstanceV1.get<UserAuthorization>(
    `/authorizations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
