/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateOrUpdateTransactionParams
 */
export interface CreateOrUpdateTransactionParams {
    /**
     * The account this transaction belongs to; required for object creation
     * @type {number}
     * @memberof CreateOrUpdateTransactionParams
     */
    'accountId'?: number;
    /**
     * Attach the transaction to the given insurance; default is no insurance
     * @type {number}
     * @memberof CreateOrUpdateTransactionParams
     */
    'insuranceId'?: number;
    /**
     * The transaction type; required for object creation
     * @type {string}
     * @memberof CreateOrUpdateTransactionParams
     */
    'type'?: CreateOrUpdateTransactionParamsTypeEnum;
    /**
     * The transaction amount; required for object creation
     * @type {number}
     * @memberof CreateOrUpdateTransactionParams
     */
    'amount'?: number;
    /**
     * The tax amount; default is zero. Only interests and other_gains may have a tax amount
     * @type {number}
     * @memberof CreateOrUpdateTransactionParams
     */
    'taxAmount'?: number;
    /**
     * A user comment
     * @type {string}
     * @memberof CreateOrUpdateTransactionParams
     */
    'comment'?: string;
    /**
     * The transactions value date; required for object creation
     * @type {string}
     * @memberof CreateOrUpdateTransactionParams
     */
    'valueDate'?: string;
    /**
     * The SEPA purpose
     * @type {string}
     * @memberof CreateOrUpdateTransactionParams
     */
    'purpose'?: string;
    /**
     * Usually, the \'isRegular\' flag is set automatically. If you set this explicitly                                the flag will be marked as \'user defined\' and not be changed by our artificial                                intelligence in the future
     * @type {boolean}
     * @memberof CreateOrUpdateTransactionParams
     */
    'isRegular'?: boolean;
    /**
     * Usually, the \'isInterAccountTransfer\' flag is set automatically. If you set this explicitly                                the flag will be marked as \'user defined\' and not be changed by our artificial                                 intelligence in the future
     * @type {boolean}
     * @memberof CreateOrUpdateTransactionParams
     */
    'isInterAccountTransfer'?: boolean;
}

export const CreateOrUpdateTransactionParamsTypeEnum = {
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
    INTEREST: 'interest',
    TAX_REFUND: 'tax_refund',
    OTHER_GAINS: 'other_gains'
} as const;

export type CreateOrUpdateTransactionParamsTypeEnum = typeof CreateOrUpdateTransactionParamsTypeEnum[keyof typeof CreateOrUpdateTransactionParamsTypeEnum];


