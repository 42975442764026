import { useAuthQuery, loadInvestmentPerformance } from "@api";

export const useInvestmentPerformance = (
  investmentId?: number,
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) =>
  useAuthQuery(
    ["investmentPerformance", investmentId!],
    ({ token }) => loadInvestmentPerformance(token, investmentId!),
    {
      enabled: !!investmentId && enabled,
    }
  );
