import React, { useEffect, useMemo } from "react";
import {
  Box,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterButton } from "@features/dividendPlanner/components/Filters/FilterButton";
import { useDividendsContext } from "@features/dividendPlanner/DividendsProvider";

type Props = {
  onChange: (year: number | undefined) => void;
};

export const Filters = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const isLess750 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(
    undefined
  );
  const { dividendsForFilters } = useDividendsContext();
  const years = useMemo(() => {
    if (!dividendsForFilters) {
      return [];
    }
    return Object.keys(dividendsForFilters || {})
      .map((key) => parseInt(key))
      .reverse()
      .slice(0, 4);
  }, [dividendsForFilters]);

  useEffect(() => {
    onChange(selectedYear);
  }, [selectedYear, onChange]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={isLess750 ? "flex-start" : "center"}
      width="100%"
      mb={6}
      gap={3}
      flexDirection={isLess750 ? "column" : "row"}
    >
      <Typography variant="h5" fontWeight={700} letterSpacing={-0.5}>
        {t(`dividends.overviewPage.mainSection.title`)}
      </Typography>
      <Box
        display="flex"
        gap={4}
        alignItems={isLessSm ? "flex-start" : "center"}
        flexDirection={isLessSm ? "column-reverse" : "row"}
        width="100%"
        justifyContent={isLess750 ? "flex-start" : "flex-end"}
      >
        <ToggleButtonGroup
          color="secondary"
          value={selectedYear}
          sx={{
            height: 48,
            width: isLessSm ? "100%" : "auto",
          }}
        >
          <ToggleButton
            key="all"
            value="all"
            onClick={() => setSelectedYear(undefined)}
            selected={selectedYear === undefined}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {t(`dividends.overviewPage.mainSection.yearSelector.all`)}
          </ToggleButton>
          {years.map((year) => {
            return (
              <ToggleButton
                key={year}
                value={year}
                onClick={() => setSelectedYear(year)}
                selected={year === selectedYear}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {year}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <FilterButton />
      </Box>
    </Box>
  );
};
