import { Configuration } from "@generated/apiv1";

export const buildConfiguration = (token: string) => {
  return new Configuration({
    basePath: `${process.env.REACT_APP_API_BASE_URL}/api`,
    apiKey: `Bearer ${token}`,
  });
};

export const buildAnonymousConfiguration = () =>
  new Configuration({
    basePath: `${process.env.REACT_APP_API_BASE_URL}/api`,
  });
