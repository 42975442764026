import styled from "@emotion/styled";

export const Row = styled.div`
  display: flex;
  align-content: stretch;

  & > :not(style) {
    margin: 0;
    flex-grow: 1;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
