import { UseMutationOptions } from "react-query";
import {
  InsurancesApiBatchUpdateInsurancesRequest,
  PageableInsuranceList,
} from "@generated/apiv1";

import { buildInsurancesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const batchUpdateInsurances = async (
  token: string | undefined,
  params: InsurancesApiBatchUpdateInsurancesRequest
) => {
  const apiInstance = buildInsurancesApi(token);
  const { data } = await apiInstance.batchUpdateInsurances(params);
  return data;
};

export const useBatchUpdateInsurances = (
  options?: UseMutationOptions<
    PageableInsuranceList,
    AxiosApiError,
    InsurancesApiBatchUpdateInsurancesRequest
  >
) => {
  return useAuthMutation(batchUpdateInsurances, options, false);
};
