import React, { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

export interface LinkWithRoutingProps {
  children: ReactNode;
  to: string;
  testId: string;
}

export const LinkWithRouting = ({
  to,
  testId,
  children,
}: LinkWithRoutingProps) => {
  return (
    <Link component={RouterLink} to={to} data-testid={testId} underline="hover">
      {children}
    </Link>
  );
};
