import React from "react";
import { useHistoricQuotesLookupPercent } from "@features/fundamentals/hooks/useHistoricQuotesLookup";
import { HistoricalQuotesBaseChart } from "@components/Chart/HistoricalQuotesChart";

interface Props {
  quoteProvider: string;
  tickerSymbol: string;
}

export const HistoricalQuotesChart = ({
  quoteProvider,
  tickerSymbol,
}: Props) => {
  const { data: historicQuotes, isLoading: isHistoricQuotesLoading } =
    useHistoricQuotesLookupPercent(quoteProvider, tickerSymbol);
  return (
    <HistoricalQuotesBaseChart
      historicQuotes={historicQuotes}
      isLoading={isHistoricQuotesLoading}
    />
  );
};
