import { useListAndSearchAllAccounts } from "@generated/apiv1/hooks";
import { Account, AccountTypeEnum } from "@generated/apiv1";
import {
  CategoryType,
  useSubcategories,
} from "@providers/SubcategoriesProvider";

const typeMap: Record<AccountTypeEnum, string> = {
  "01_depot": "DEPOT",
  "02_cash": "CASH_ACCOUNT",
  "05_material_assets": "OTHER_MATERIAL_ASSETS",
  "04_insurance": "INSURANCE",
};

export const useAccountsByTypes = () => {
  const { data, ...rest } = useListAndSearchAllAccounts({
    // @ts-ignore
    order: ["bankName asc", "name asc"],
  });
  const { subcategories, isLoading } = useSubcategories({
    categoryType: CategoryType.ACCOUNT,
  });

  const groupedAccounts = data?.accounts?.reduce((acc, val) => {
    let subCategoryId = val.subCategoryId || undefined;

    if (!subCategoryId) {
      const subcategory = subcategories?.find(
        (subcategory) => subcategory.name === typeMap[val.type]
      );
      subCategoryId = subcategory?.id;
    }

    let subCategoryName;

    const connectedParentAccount = data?.accounts?.find(
      (account) => account.contraAccountId === val.id
    );

    if (connectedParentAccount) {
      subCategoryName =
        subcategories?.find(
          (subcategory) =>
            Number(subcategory.id) === connectedParentAccount.subCategoryId
        )?.name ||
        typeMap[connectedParentAccount.type] ||
        "DEPOT";
    } else {
      subCategoryName = subcategories?.find(
        (subcategory) => subcategory.id === subCategoryId
      )?.name;
    }

    if (!subCategoryName) return acc;

    return {
      ...acc,
      [subCategoryName]: [...(acc[subCategoryName] || []), val],
    };
  }, {} as Record<string, Account[]>);

  return {
    ...rest,
    isLoading: isLoading || rest.isLoading,
    accounts: data?.accounts,
    groupedAccounts: groupedAccounts || {},
  };
};
