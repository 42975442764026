import { uniq } from "lodash";
import {
  CreateOrUpdateBookingParams,
  CreateOrUpdateInvestmentParams,
} from "@api";
import { ActivityWithBooking } from "../helpers/bookingLocator";
import { toInternalType, toString } from "../components/ParsingActivity";

const buildCreateOrUpdateInvestmentParams = (
  accountId: number,
  activityWithBooking: ActivityWithBooking
): CreateOrUpdateInvestmentParams => {
  return {
    id: activityWithBooking?.investment?.id,
    accountId: accountId,
    type:
      activityWithBooking.investment?.type ||
      (activityWithBooking.activity.cryptoSymbol ? "81_fcurr" : undefined),
    currency:
      activityWithBooking.investment?.currency ||
      activityWithBooking.activity.currency ||
      "EUR",
    name:
      activityWithBooking.activity.name ||
      activityWithBooking.activity.cryptoSymbol ||
      "Not provided",
    isin: activityWithBooking.activity.isin || undefined,
    wkn: activityWithBooking.activity.wkn || undefined,
    tickerSymbol: activityWithBooking.activity.cryptoSymbol
      ? activityWithBooking.investment?.tickerSymbol ||
        `${activityWithBooking.activity.cryptoSymbol}_to_EUR`
      : undefined,
    quoteProvider: activityWithBooking.activity.cryptoSymbol
      ? "crypto"
      : undefined,
    createOrUpdateBookingParamsList: [],
  };
};

const buildCreateOrUpdateBookingParams = (
  activityWithBooking: ActivityWithBooking,
  investmentCurrency: string
): CreateOrUpdateBookingParams => {
  if (!activityWithBooking.activity.type)
    throw new Error("activity.type must be given");

  return {
    id: activityWithBooking.booking?.id,
    hash: activityWithBooking.hash,
    numberOfLots:
      activityWithBooking.activity.type === "dividend"
        ? 1
        : activityWithBooking.activity.numberOfLots,
    securityPrice: getSecurityPrice(activityWithBooking, investmentCurrency),
    entryQuote: activityWithBooking.activity.securityPrice,
    priceCheckRequired: false,
    date: getDate(activityWithBooking),
    dateIsEstimate: false,
    exchangeRate: getExchangeRate(activityWithBooking, investmentCurrency),
    type: toInternalType(activityWithBooking.activity.type),
    commission: activityWithBooking.activity.commission || 0,
    taxAmount: activityWithBooking.activity.taxAmount || 0,
    expensesInEur: true,
    comment: toString(activityWithBooking.activity),
    commentVisibility: "nobody",
    notifyFriends: false,
  };
};

const getSecurityPrice = (
  activityWithBooking: ActivityWithBooking,
  investmentCurrency: string
) => {
  if (!activityWithBooking.activity.securityPrice) return undefined;

  const price =
    activityWithBooking.activity.type === "dividend"
      ? activityWithBooking.activity.netAmount || 0
      : activityWithBooking.activity.securityPrice;

  return investmentCurrency !== "EUR" &&
    activityWithBooking.activity.exchangeRate
    ? price * activityWithBooking.activity.exchangeRate
    : price;
};

const getExchangeRate = (
  activityWithBooking: ActivityWithBooking,
  investmentCurrency: string
) => {
  if (investmentCurrency === "EUR") return 1;
  return activityWithBooking.activity.exchangeRate || 1;
};

const getDate = (activityWithBooking: ActivityWithBooking) => {
  return activityWithBooking.booking?.finApiId
    ? activityWithBooking.booking.valueDate
    : activityWithBooking.activity.date;
};

export const buildCreateOrUpdateInvestmentParamsList = (
  accountId: number,
  activitiesWithBookings: ActivityWithBooking[]
): CreateOrUpdateInvestmentParams[] => {
  const isinsOrWknsOrTickerSymbols = uniq(
    activitiesWithBookings.map((awb) => {
      if (awb.activity.cryptoSymbol) {
        return (
          awb.investment?.tickerSymbol || `${awb.activity.cryptoSymbol}_to_EUR`
        );
      }
      if (awb.investment) {
        return awb.investment.isin ? awb.investment.isin : awb.investment.wkn;
      }
      return awb.activity.isin ? awb.activity.isin : awb.activity.wkn;
    })
  );

  return isinsOrWknsOrTickerSymbols.map((isinOrWknOrTickerSymbol) => {
    const activities = activitiesWithBookings.filter(
      (awb) =>
        isinOrWknOrTickerSymbol &&
        (awb.activity.isin === isinOrWknOrTickerSymbol ||
          awb.activity.wkn === isinOrWknOrTickerSymbol ||
          `${awb.activity.cryptoSymbol}_to_EUR` === isinOrWknOrTickerSymbol ||
          awb.investment?.tickerSymbol === isinOrWknOrTickerSymbol ||
          awb.investment?.isin === isinOrWknOrTickerSymbol ||
          awb.investment?.wkn === isinOrWknOrTickerSymbol)
    );

    let createOrUpdateInvestmentParams = buildCreateOrUpdateInvestmentParams(
      accountId,
      activities[0]
    );

    createOrUpdateInvestmentParams.createOrUpdateBookingParamsList =
      activities.map((activity) => {
        return buildCreateOrUpdateBookingParams(
          activity,
          createOrUpdateInvestmentParams.currency || "EUR"
        );
      });

    return createOrUpdateInvestmentParams;
  });
};
