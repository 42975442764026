import React, { useCallback, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AxiosError } from "axios";
import { isAuthError, NoTokenError, useMyself, useToken } from "@api";

import { AuthContext } from "@providers/AuthContext";

export const ProtectedRoute = ({
  children,
  roles,
}: {
  children?: JSX.Element;
  roles: string[];
}) => {
  const { data: myself, isLoading } = useMyself();

  if (isLoading) {
    return null;
  }

  if (myself && !myself.roles?.some((role) => roles.includes(role))) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />;
};

export const OnlyAuthenticatedRoute = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  const { setLoginRequired } = useContext(AuthContext);

  const handleTokenError = useCallback(
    (e: unknown) => {
      if (!isAuthError(e as NoTokenError | AxiosError<ApiException>)) {
        return;
      }

      setLoginRequired(e as NoTokenError | AxiosError<ApiException>);
    },
    [setLoginRequired]
  );

  const { isLoading } = useToken({
    onError: handleTokenError,
    key: "onlyAuthenticatedRoute",
  });

  if (isLoading) {
    return null;
  }

  return children ? children : <Outlet />;
};
