import { useMemo } from "react";
import { differenceInCalendarMonths, format } from "date-fns";
import { keyBy } from "lodash";
import { Transaction, Category } from "@generated/apiv1";
import { useTransactions } from "@api/v1/hooks/useTransactions";
import { TransactionTreeBuilder } from "@features/cashflowAnalyzer/services/TransactionTreeBuilder";
import { useV1Categories } from "@features/cashflowAnalyzer/hooks/useCashflow";
import {
  CategoryWithChildren,
  TransactionWithCategory,
} from "@features/cashflowAnalyzer/types";
import { useChartColors } from "@hooks";
import { ComponentsTheme } from "@theming/types";

const transactionTreeBuilder = new TransactionTreeBuilder();

const formatTransactionsForTable = (
  transactions: Transaction[],
  categories: Category[],
  colors?: ComponentsTheme["charts"]["cashflowChart"]["categories"]
) => {
  return transactions.reduce((acc, transaction) => {
    if (!transaction.bankBookingDate) {
      return acc;
    }
    const category = categories.find(
      (category) => category.id === transaction.categoryId
    );
    const name =
      category?.parentName ?? category?.name ?? "Nicht klassifiziert";

    const date = new Date(transaction.bankBookingDate);
    const key = format(date, "yyyy-MM-dd");
    if (!acc[key]) {
      acc[key] = [];
    }

    const formattedName = name.replace("&", "und");
    acc[key].push({
      ...transaction,
      color:
        colors?.[formattedName]?.text ||
        colors?.[formattedName]?.dark ||
        colors?.["Nicht klassifiziert"]?.text,
      backgroundColor:
        colors?.[formattedName]?.light ||
        colors?.["Nicht klassifiziert"]?.light,
      categoryName: category?.name,
    });
    return acc;
  }, {} as Record<string, TransactionWithCategory[]>);
};

const buildCategoryTree = (
  categories: Category[],
  colors?: ComponentsTheme["charts"]["cashflowChart"]["categories"]
) => {
  const categoryById = keyBy(categories, "id");
  return categories.reduce((acc, category) => {
    if (category.parentId) return acc;
    const name = category.name.replace("&", "und");

    return {
      ...acc,
      [category.id]: {
        ...category,
        color: colors?.[name]?.dark,
        backgroundColor: colors?.[name]?.light,
        children: category.children?.map((child) => categoryById[child]),
      },
    };
  }, {} as Record<number, CategoryWithChildren>);
};

export const useCategorizedTransactions = (
  accountIds: number[],
  startDate: Date,
  endDate: Date
) => {
  const colors = useChartColors("cashflowChart")?.categories;

  const { data: transactions, isLoading: areTransactionsLoading } =
    useTransactions(
      {
        accountId: accountIds,
        includeAdjustingEntries: false,
        skipManuallyCreatedTransactions: true,
        minDate: format(startDate, "yyyy-MM-dd"),
        maxDate: format(endDate, "yyyy-MM-dd"),
        order: "date desc",
        perPage: 5000,
      },
      {
        enabled: !!accountIds && accountIds.length > 0,
        refetchOnWindowFocus: false,
        cacheTime: 0,
      }
    );
  const { data: categories, isLoading: areCategoriesLoading } = useV1Categories(
    undefined,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const transactionsByAccount = useMemo(
    () =>
      transactionTreeBuilder.buildTransactionsCategoryTree(
        transactions?.transactions || [],
        categories?.categories || [],
        differenceInCalendarMonths(new Date(), startDate),
        differenceInCalendarMonths(new Date(), endDate)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactions?.transactions, categories?.categories]
  );

  const groupedTransactions = useMemo(
    () =>
      formatTransactionsForTable(
        transactions?.transactions ?? [],
        categories?.categories ?? [],
        colors
      ),
    [transactions?.transactions, categories?.categories, colors]
  );

  const categoryTree = useMemo(
    () => buildCategoryTree(categories?.categories ?? [], colors),
    [categories?.categories, colors]
  );

  return {
    categorizedTransactions: transactionsByAccount,
    transactions: groupedTransactions,
    categories: categoryTree,
    isLoading: areTransactionsLoading || areCategoriesLoading,
  };
};
