import React from "react";
import { Box, BoxProps, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const NoAccountsStub = (props: BoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      {...props}
    >
      <Typography variant="body2" mb={1}>
        {t("dashboardPage.dashboard.noDataFirstLine")}
      </Typography>
      <Typography variant="body2">
        <Trans
          i18nKey="dashboardPage.dashboard.noDataSecondLine"
          components={{
            linkText: (
              <Link
                onClick={() => navigate("/depots/add")}
                color="textPrimary"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
};
