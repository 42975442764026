import { useState } from "react";

interface Attrs {
  onCustomIntervalSelect: (start: Date, end: Date) => void;
}

export const useDateReferenceSelect = ({ onCustomIntervalSelect }: Attrs) => {
  const [refAreaLeft, setRefAreaLeft] = useState<Date>();
  const [refAreaRight, setRefAreaRight] = useState<Date>();

  const handleMouseDown = (e: any) => {
    if (!e?.activeLabel) return;
    setRefAreaLeft(e.activeLabel);
  };

  const handleMouseUp = () => {
    if (!refAreaLeft || !refAreaRight) return;
    if (refAreaRight < refAreaLeft) {
      onCustomIntervalSelect(refAreaRight, refAreaLeft);
    } else {
      onCustomIntervalSelect(refAreaLeft, refAreaRight);
    }

    setRefAreaRight(undefined);
    setRefAreaLeft(undefined);
  };

  const handleMouseMove = (e: any) => {
    if (!e?.activeLabel) return;
    refAreaLeft && setRefAreaRight(e.activeLabel);
  };

  return {
    mouseMove: handleMouseMove,
    mouseUp: handleMouseUp,
    mouseDown: handleMouseDown,
    refAreaLeft,
    refAreaRight,
  };
};
