import {
  alpha,
  Box,
  createTheme,
  darken,
  IconButton,
  ThemeOptions,
  TypeBackground,
  TypeText,
} from "@mui/material";
import { deDE, enUS } from "@mui/material/locale";
import { PaletteOptions } from "@mui/material/styles";
import {
  ColorPartial,
  CommonColors,
  SimplePaletteColorOptions,
} from "@mui/material/styles/createPalette";
import React from "react";
import { Shadows } from "@mui/material/styles/shadows";
import { ExpandMore } from "@theming/ExpandMore";
import { ReactComponent as CheckedIcon } from "@icons/checkbox-checked.svg";
import { ReactComponent as RadioCheckedIcon } from "@icons/radio-checked.svg";
import { baseLightColors, baseLightShadows } from "./baseTokens";
import { generateStandardChartColors } from "./utils";

export const colors: PaletteOptions & {
  text: Partial<TypeText>;
  background: Partial<TypeBackground>;
  primary: SimplePaletteColorOptions;
  common: Partial<CommonColors>;
  grey: ColorPartial;
  secondary: SimplePaletteColorOptions;
  secondaryWarning: SimplePaletteColorOptions;
  secondaryError: SimplePaletteColorOptions;
} = {
  common: {
    white: baseLightColors.N000,
    black: baseLightColors.N1000,
  },
  secondaryWarning: {
    main: "#7d4c20",
  },
  secondaryError: {
    main: "#e56043",
  },
  text: {
    primary: "#15284B",
    secondary: "#737E93",
    tertiary: "#8A93A5",
    warning: "#E68C3A",
    disabled: alpha("#737E93", 0.4),
    positive: "#13CC89",
    negative: "#e56043",
    tooltipPrimary: "#15284B",
    tooltipSecondary: "#737E93",
    link: "#4D6BDD",
  },
  background: {
    default: baseLightColors.N000,
    paper: baseLightColors.N000,
    sidebar: "#4D6BDD",
    footer: baseLightColors.N000,
    subtle: "#F9F9FA",
    neutral: "#F3F5F5",
    neutralLight: "#F8F8FD",
    neutralDark: darken("#F3F5F5", 0.1),
    tooltip: baseLightColors.N000,
    tooltipChart: baseLightColors.N000,
    primary: "#4D6BDD",
    secondary: "#EDF0FC",
    selected: "#EDF0FC",
    tertiary: "#F3F5F5",
    info: "#EDF0FC",
    upgrade: "#EDF8FF",
    error: "#FFF1ED",
    warning: "#FFF5EC",
    success: "#E7FAF3",
  },
  primary: {
    lighter: "#A6B5EE",
    light: "#7189E4",
    main: "#4D6BDD",
    dark: "#3E56B1",
    contrastText: baseLightColors.N000,
  },
  secondary: {
    lighter: "#EDF0FC",
    light: "#DBDFF0",
    main: "#4D6BDD",
    dark: "#3E56B1",
    contrastText: baseLightColors.N000,
  },
  tertiary: {
    lighter: "#F3F5F5",
    light: "#DCDFE4",
    main: "#B9BFC9",
    dark: "#8A93A5",
    contrastText: baseLightColors.N000,
  },
  info: {
    lighter: "#EDF8FF",
    light: "#A9DEFC",
    main: "#42B6F8",
    dark: "#3592C6",
  },
  icon: {
    primary: "#15284B",
    secondary: "#737E93",
    tertiary: "#8A93A5",
    disabled: alpha("#737E93", 0.4),
    onColor: baseLightColors.N000,
    active: "#4D6BDD",
    success: "#13CC89",
    warning: "#FF9B40",
    error: "#e56043",
  },
  error: {
    lighter: "#FFF1ED",
    light: "#FFBBAC",
    main: "#e56043",
    dark: "#B34B34",
    contrastText: baseLightColors.N000,
  },
  warning: {
    lighter: "#FFF5EC",
    light: "#FFD1A7",
    main: "#FF9B40",
    dark: "#CE7D34",
    contrastText: baseLightColors.N000,
  },
  success: {
    lighter: "#E7FAF3",
    light: "#A1EBD0",
    main: "#13CC89",
    dark: "#0EA871",
    contrastText: baseLightColors.N000,
  },
  border: {
    primary: "#D0D4DB",
    secondary: "#E8EAED",
    active: "#4D6BDD",
    error: "#e56043",
  },
  grey: {
    main: baseLightColors.N300,
    dark: baseLightColors.N400,
    50: "#FCFCFC",
    100: "#F9F9F9",
    200: "#F4F4F4",
    300: "#EEEEEE",
    400: "#DDDDDD",
    500: "#CCCCCC",
    600: "#BFC3C5",
    700: baseLightColors.N700,
    800: baseLightColors.N800,
    900: baseLightColors.N900,
  },
  graphVisual: {
    "01": {
      lighter: "#EDF0FC",
      light: "#B8C4F1",
      mid: "#4D6BDD",
      dark: "#384D9F",
      darker: darken("#384D9F", 0.2),
    },
    "02": {
      lighter: "#E7FAF3",
      light: "#A1EBD0",
      mid: "#13CC89",
      dark: "#0D8F60",
      darker: darken("#0D8F60", 0.2),
    },
    "03": {
      lighter: "#FFEBD9",
      light: "#FFD1A7",
      mid: "#FF9B40",
      dark: "#CE7D34",
      darker: darken("#CE7D34", 0.2),
    },
    "04": {
      lighter: "#FFE1DB",
      light: "#FFBBAC",
      mid: "#e56043",
      dark: "#B34B34",
      darker: darken("#B34B34", 0.2),
    },
    "05": {
      lighter: "#FFF7DB",
      light: "#FFEFB6",
      mid: "#FFD749",
      dark: "#D4B442",
      darker: darken("#D4B442", 0.2),
    },
    "06": {
      lighter: "#F4EBFF",
      light: "#B692F6",
      mid: "#7F56D9",
      dark: "#694AAE",
      darker: darken("#694AAE", 0.2),
    },
    "07": {
      lighter: "#EDF8FF",
      light: "#A9DEFC",
      mid: "#42B6F8",
      dark: "#3592C6",
      darker: darken("#3592C6", 0.2),
    },
    "08": {
      lighter: "#F8F8F8",
      light: "#F0F0F2",
      mid: "#DBDBDD",
      dark: "#6E6E6F",
      darker: darken("#6E6E6F", 0.2),
    },
    "09": {
      lighter: "#E6F8FA",
      light: "#9BE4EB",
      mid: darken("#9BE4EB", 0.2),
      dark: "#06BCCD",
      darker: "#049EAC",
    },
    "10": {
      lighter: "#E6F8FA",
      light: "#9BE4EB",
      mid: darken("#9BE4EB", 0.2),
      dark: "#06BCCD",
      darker: "#049EAC",
    },
    "11": {
      lighter: "#E0F2AF",
      light: "#D1EB87",
      mid: "#B2DD37",
      dark: "#8EB12C",
      darker: darken("#8EB12C", 0.2),
    },
    "12": {
      lighter: "#AEE9BD",
      light: "#85DD9B",
      mid: "#34C759",
      dark: "#2A9F47",
      darker: darken("#2A9F47", 0.2),
    },
    "13": {
      lighter: "#CCBCF0",
      light: "#B29AE8",
      mid: "#7F56D9",
      dark: "#6645AE",
      darker: darken("#6645AE", 0.2),
    },
    "14": {
      light: "#70A6E6",
      lighter: "#9FC4EE",
      mid: "#106BD6",
      dark: "#0D56AB",
      darker: darken("#0D56AB", 0.2),
    },
  },
};

const standardChartColors = generateStandardChartColors(colors);

export const finanzflussTheme: ThemeOptions = {
  spacing: 4,
  type: "finanzfluss",
  typography: {
    fontSize: 16,
    fontFamily: "Averta,Arial,Helvetica,sans-serif",
    body1: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0",
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0",
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    body3: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "0",
      "@media (max-width:601px)": {
        fontSize: "16px",
      },
    },
    h1: {
      fontSize: "44px",
      fontWeight: 600,
      lineHeight: "54px",
      letterSpacing: "-0.02em",
      "@media (max-width:601px)": {
        fontSize: "34px",
        lineHeight: "42px",
      },
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    h2: {
      fontSize: "38px",
      fontWeight: 600,
      lineHeight: "48px",
      "@media (max-width:601px)": {
        fontSize: "28px",
        lineHeight: "34px",
      },
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    h3: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "40px",
      "@media (max-width:601px)": {
        fontSize: "26px",
        lineHeight: "34px",
      },
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    h4: {
      fontSize: "26px",
      fontWeight: 600,
      lineHeight: "34px",
      "@media (max-width:601px)": {
        fontSize: "22px",
        lineHeight: "30px",
      },
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    h5: {
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "30px",
      "@media (max-width:601px)": {
        fontSize: "20px",
        lineHeight: "28px",
      },
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    subtitle1: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    subtitle2: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    button: {
      fontWeight: 700,
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
      fontSize: "1rem",
      lineHeight: 1.75,
      textTransform: "uppercase",
    },
    overline: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: 500,
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 500,
      fontFamily: "Averta,Arial,Helvetica,sans-serif",
    },
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  shadows: [
    "none",
    `0px 1px 2px ${alpha("#15284B", 0.16)}`,
    `0px 2px 4px -1px ${alpha("#15284B", 0.16)}`,
    `0px 4px 6px -1px ${alpha("#15284B", 0.16)}`,
    `0px 4px 12px -1px ${alpha("#15284B", 0.12)}, 0px 4px 16px -2px ${alpha(
      "#15284B",
      0.16
    )}`,
    `0px 8px 16px -2px ${alpha("#15284B", 0.12)}, 0px 6px 24px -2px ${alpha(
      "#15284B",
      0.16
    )}`,
    ...Array(19).fill("none"),
  ] as Shadows,
  palette: colors,
  shape: {
    borderRadius: 6,
  },
  showcaseLayout: {
    image: "ftd-showcase.png",
  },
  appSidebar: {
    background: colors.background.sidebar,
    contrastText: baseLightColors.N000,
    color: baseLightColors.N000,
    divider: "#4560C7",
    search: {
      backgroundColor: baseLightColors.N000,
      boxShadow: "none",
      iconColor: colors.icon.secondary,
      borderColor: colors.border.primary,
      color: colors.text.secondary,
    },
    menuItem: {
      color: baseLightColors.N000,
      hoverColor: baseLightColors.N000,
      hoverBackgroundColor: colors.primary.dark,
      selectedBackgroundColor: colors.primary.dark,
      selectedBorderColor: "transparent",
      selectedColor: baseLightColors.N000,
      fontWeight: 600,
    },
  },
  footer: {
    textColor: "#888",
    dividerColor: "#bbb",
  },
  bankConnection: {
    importProgress: {
      backgroundColor: "#EDF0FC",
    },
  },
  header: {
    dark: {
      backgroundColor: "#4D6BDD",
      color: "#FFFFFF",
    },
    light: {
      backgroundColor: "#FFFFFF",
      color: "#15284B",
    },
  },
  components: {
    MuiSkeleton: {
      variants: [
        {
          props: { variant: "rectangular" },
          style: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius,
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        color: "grey",
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5, 4),
          textTransform: "none",
          fontWeight: 500,
          borderRadius: theme.shape.borderRadius,
          lineHeight: "24px",
        }),
        startIcon: ({ theme }) => ({
          marginRight: theme.spacing(2),
        }),
        endIcon: ({ theme }) => ({
          marginLeft: theme.spacing(2),
        }),
      },
      variants: [
        {
          props: {
            size: "small",
          },
          style: {
            fontSize: "14px",
            lineHeight: "22px",
          },
        },
        {
          props: {
            color: "grey",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.neutral,
            color: theme.palette.text.primary,

            "&:hover": {
              backgroundColor: theme.palette.grey.dark,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: {
            variant: "contained",
          },
          style: ({ theme }) => ({
            padding: theme.spacing(2.5, 4),

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.neutral,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: {
            variant: "contained",
            size: "small",
          },
          style: ({ theme }) => ({
            padding: theme.spacing(1.75, 3),
          }),
        },
        {
          props: {
            variant: "contained",
            color: "grey",
          },
          style: ({ theme }) => ({
            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: {
            variant: "outlined",
          },
          style: ({ theme }) => ({
            padding: theme.spacing(2.25, 3.75),
            backgroundColor: theme.palette.background.default,
            boxShadow: "none !important",

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.text.secondary,
              },
            "&.Mui-disabled": {
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },
          }),
        },
        {
          props: {
            variant: "outlined",
            color: "primary",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.selected,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.primary.lighter,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.primary.main,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              borderColor: theme.palette.primary.lighter,
            },
          }),
        },
        {
          props: {
            variant: "outlined",
            color: "secondary",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.upgrade,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.secondary.lighter,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.secondary.main,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.secondary?.lighter,
              borderColor: theme.palette.secondary?.lighter,
            },
          }),
        },
        {
          props: {
            variant: "outlined",
            color: "tertiary",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.tertiary,
            padding: theme.spacing(2.25, 4),
            borderColor: theme.palette.tertiary.lighter,
            color: theme.palette.text.primary,

            "&:not(.Mui-disabled) .MuiButton-startIcon, &:not(.Mui-disabled) .MuiButton-endIcon":
              {
                color: theme.palette.text.primary,
              },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
              borderColor: theme.palette.border.secondary,
            },

            "&:hover": {
              backgroundColor: theme.palette.tertiary?.lighter,
              borderColor: theme.palette.tertiary?.lighter,
            },
          }),
        },
        {
          props: {
            variant: "contained",
            color: "secondaryWarning",
          },
          style: ({ theme }) => ({
            color: theme.palette.secondaryWarning.main,
            backgroundColor: theme.palette.graphVisual["05"].light,
            border: "none",

            "& svg": {
              color: theme.palette.secondaryWarning.main,
            },

            "&:hover": {
              border: "none",
            },
          }),
        },
        {
          props: {
            variant: "contained",
            color: "secondaryError",
          },
          style: ({ theme }) => ({
            color: theme.palette.secondaryError.main,
            backgroundColor: theme.palette.error.lighter,
            border: "none",

            "& svg": {
              color: theme.palette.secondaryError.main,
            },

            "&:hover": {
              border: "none",
            },
          }),
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            borderColor: theme.palette.border.secondary,

            "&:hover": {
              backgroundColor: theme.palette.background.subtle,
              borderColor: theme.palette.border.secondary,
            },
          }),
        },
        {
          props: { variant: "outlined", size: "small" },
          style: ({ theme }) => ({
            padding: theme.spacing(1.5, 2.75),
          }),
        },
        {
          props: { variant: "text" },
          style: ({ theme }) => ({
            padding: theme.spacing(2.5, 4),
          }),
        },
        {
          props: { variant: "text", color: "grey" },
          style: ({ theme }) => ({
            color: theme.palette.text.primary,

            "&:hover": {
              backgroundColor: theme.palette.background.subtle,
            },
          }),
        },
        {
          props: { variant: "text", size: "small" },
          style: ({ theme }) => ({
            padding: theme.spacing(1.75, 3),
          }),
        },
        {
          props: {
            size: "small",
          },
          style: ({ theme }) => ({
            fontSize: "16px",
            lineHeight: "24px",
            padding: theme.spacing(1.5, 3),
          }),
        },
        {
          props: {
            size: "medium",
          },
          style: ({ theme }) => ({
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "28px",
            padding: theme.spacing(2.5, 4),
          }),
        },
        {
          props: {
            variant: "contained",
            color: "primary",
          },
          style: ({ theme }) => ({
            "&.Mui-disabled": {
              backgroundColor: alpha(theme.palette.primary.main, 0.4),
              color: theme.palette.common.white,
            },
            "&:hover": {
              backgroundColor: "#4560C7",
            },
            // "& .MuiTouchRipple-rippleVisible .MuiTouchRipple-child": {
            //   backgroundColor: theme.palette.primary.dark,
            // },
          }),
        },
        {
          props: {
            variant: "contained",
            color: "grey",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.neutral,
            color: theme.palette.text.primary,

            "&:hover": {
              backgroundColor: "#E8EAED",
            },
          }),
        },
        {
          props: {
            variant: "contained",
            color: "secondary",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.secondary,
            color: theme.palette.primary.main,

            "&:hover": {
              backgroundColor: "#E4E8F7",
            },
          }),
        },
        {
          props: {
            variant: "contained",
            color: "tertiary",
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.common.white,

            "&:hover": {
              backgroundColor: theme.palette.text.primary,
            },
          }),
        },
        {
          props: {
            variant: "outlined",
          },
          style: ({ theme }) => ({
            padding: theme.spacing(2.25, 4),
          }),
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          height: "48px",
          width: "48px",
          color: theme.palette.text.secondary,

          "& .MuiTouchRipple-ripple": {
            borderRadius: "inherit",
          },
          "& .MuiTouchRipple-child": {
            borderRadius: "inherit !important",
          },
          "&.Mui-disabled": {
            color: theme.palette.grey.dark,
          },
        }),
      },
      variants: [
        {
          props: {
            size: "small",
          },
          style: {
            width: "36px",
            height: "36px",
          },
        },
        {
          props: { color: "grey" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.neutral,
            color: theme.palette.text.primary,

            "&:hover": {
              backgroundColor: theme.palette.grey.dark,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              color: theme.palette.grey.dark,
            },
          }),
        },
        {
          props: { color: "primary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.primary,
            color: theme.palette.primary.contrastText,

            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.neutral,
            },
          }),
        },
        {
          props: { color: "outlined" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.border.secondary}`,

            "&:hover": {
              backgroundColor: theme.palette.background.neutralLight,
              borderColor: theme.palette.border.secondary,
            },

            "&.Mui-disabled": {
              borderColor: theme.palette.border.secondary,
              color: theme.palette.grey.dark,
              boxShadow: "none",
            },
          }),
        },
        {
          props: { color: "secondary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.secondary,
            color: theme.palette.primary.main,

            "&:hover": {
              backgroundColor: "#E4E8F7",
              borderColor: theme.palette.primary.lighter,
            },

            "&.Mui-disabled": {
              backgroundColor: theme.palette.background.subtle,
              borderColor: theme.palette.border.secondary,
              color: theme.palette.grey.dark,
            },
          }),
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-markLabel[data-index="0"]': {
            transform: "translateX(0px)",
          },
          '& .MuiSlider-markLabel[style="left: 100%;"]': {
            transform: "translateX(-100%)",
          },
          marginBottom: 0,
          marginTop: "12px",
        },
        mark: {
          display: "none",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: "0 4px",
        },
        root: {
          position: "relative",
          overflow: "hidden",
          "&::after": {
            content: '""',
            display: "block",
            position: "absolute",
            right: 0,
            top: 0,
            width: "30px",
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",
          },
        },
        ol: {
          flexWrap: "nowrap",
          overflowX: "auto",
          paddingRight: "30px",
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        li: {
          flexShrink: 0,
          whiteSpace: "nowrap",
        },
      },
      defaultProps: {
        expandText: "Pfad anzeigen",
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.border.secondary}`,
          "& .PrivatePickersYear-yearButton, & .PrivatePickersMonth-root": {
            fontSize: "16px",
            margin: "4px 0",
          },
          "& .MuiPickersDay-root": {
            fontSize: "14px",
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
        },
        scroller: {
          height: "26px",
          overflowY: "hidden",
        },
        flexContainer: ({ theme }) => ({
          gap: theme.spacing(6),
          padding: 0,
          height: "100%",
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `0 !important`,
          justifyContent: "flex-start",

          "&.Mui-selected": {
            color: theme.palette.primary.main,
            fontWeight: 500,
            "&:hover": {
              color: theme.palette.primary.main,
            },
          },
          textTransform: "none",
          fontSize: "16px",
          lineHeight: "24px",
          height: "100% !important",
          fontWeight: 400,
          color: theme.palette.text.tertiary,
          minWidth: "auto",
          "&:hover": {
            textDecoration: "none",
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "p",
          subtitle2: "p",
          caption: "p",
          overline: "p",
          body1: "p",
          body2: "p",
          body3: "p",
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          marginLeft: 0,

          "& .Mui-checked": {
            backgroundColor: theme.palette.background.primary,
            ".MuiSwitch-track": {
              backgroundColor: theme.palette.background.primary,
            },
            ".MuiSwitch-thumb": {
              backgroundColor: theme.palette.background.default,
            },
          },
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.background.neutralDark,
          opacity: "1 !important",
        }),
        sizeSmall: ({ theme }) => ({
          height: "20px",
          width: "36px",
          borderRadius: "16px",

          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(16px)",
            },
            padding: theme.spacing(0.5),
          },

          "& .MuiSwitch-thumb": {
            height: "16px",
            width: "16px",
          },
        }),
        sizeMedium: ({ theme }) => ({
          height: "24px",
          width: "44px",
          borderRadius: "24px",

          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(20px)",
            },
            padding: theme.spacing(0.5),
          },

          "& .MuiSwitch-thumb": {
            height: "20px",
            width: "20px",
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          border: `1px solid ${theme.palette.border.secondary}`,
          boxShadow: theme.shadows[2],
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "inline-block",
          fontSize: "16px",
          lineHeight: "24px",
          marginBottom: theme.spacing(1),
          color: theme.palette.text.secondary,

          "&.Mui-disabled": {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "none",
          border: `1px solid ${theme.palette.border.secondary}`,
          borderRadius: "8px",
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "16px",
          lineHeight: "28px",
          fontWeight: 400,
        },
        root: {
          "& .MuiCheckbox-root": {
            paddingLeft: "11px",
            paddingRight: "12px",
            paddingTop: "0",
            paddingBottom: "0",
          },
          "& .MuiSwitch-root": {
            marginRight: "12px",
          },
          alignItems: "center !important",
          "& .MuiSwitch-sizeSmall  + .MuiFormControlLabel-label": {
            fontSize: "14px",
            lineHeight: "20px",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        color: "primary",
        icon: <Box className="radioIcon" />,
        checkedIcon: (
          <Box
            className="radioIcon"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <RadioCheckedIcon width="18px" height="18px" />
          </Box>
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .radioIcon": {
            width: "20px",
            height: "20px",
            boxSizing: "border-box",
            borderRadius: "50%",
            padding: "0",
            border: `2px solid ${theme.palette.border.primary}`,
            backgroundColor: theme.palette.background.default,
          },

          "&:hover .radioIcon": {
            borderColor: theme.palette.border.primary,
          },

          "&.Mui-checked .radioIcon": {
            backgroundColor: theme.palette.background.primary,
            borderColor: theme.palette.primary.main,
          },

          "&:hover.Mui-checked .radioIcon": {
            borderColor: theme.palette.primary.light,
          },

          "&.Mui-disabled .radioIcon": {
            borderColor: theme.palette.border.secondary,
          },

          "&.Mui-disabled.Mui-checked .radioIcon": {
            backgroundColor: theme.palette.primary.lighter,
            borderColor: theme.palette.primary.lighter,

            "& svg": {
              color: `${theme.palette.common.white} !important`,
            },
            "& svg path:first-of-type": {
              fill: `${theme.palette.common.white} !important`,
            },
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
        icon: <Box className="checkIcon" />,
        checkedIcon: (
          <Box className="checkIcon" display="flex" alignItems="center">
            <CheckedIcon width="18px" height="18px" />
          </Box>
        ),
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .checkIcon": {
            width: "20px",
            height: "20px",
            boxSizing: "border-box",
            borderRadius: theme.shape.borderRadius,
            padding: "0",
            border: `2px solid ${theme.palette.border.secondary}`,
            backgroundColor: theme.palette.background.default,
          },

          "&:hover .checkIcon": {
            borderColor: theme.palette.border.primary,
          },

          "&.Mui-checked .checkIcon": {
            backgroundColor: theme.palette.background.primary,
            borderColor: theme.palette.primary.main,
          },

          "&:hover.Mui-checked .checkIcon": {
            borderColor: theme.palette.primary.light,
          },

          "&.Mui-disabled .checkIcon": {
            borderColor: theme.palette.border.secondary,
          },

          "&.Mui-disabled.Mui-checked .checkIcon": {
            backgroundColor: theme.palette.primary.lighter,
            borderColor: theme.palette.primary.lighter,

            "& svg": {
              color: `${theme.palette.common.white} !important`,
            },
            "& svg path:first-of-type": {
              fill: `${theme.palette.common.white} !important`,
            },
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.border.secondary,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: "22px",
          minHeight: "auto",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          color: theme.palette.text.primary,
          fontWeight: 400,
          padding: theme.spacing(2.5, 3),
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: "unset",
          border: "unset",
          color: theme.palette.text.primary,
        }),
        outlinedPrimary: ({ theme }) => ({
          backgroundColor: "#44536F",
          color: theme.palette.common.white,
          borderColor: "unset",
          border: "unset",

          "&.MuiChip-clickable:hover": {
            backgroundColor: "#44536F",
            borderColor: "unset",
          },

          "& .MuiChip-icon": {
            color: theme.palette.common.white,
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.background.neutral,
          borderColor: "unset",
          border: "unset",
          "&.MuiChip-clickable:hover": {
            backgroundColor: theme.palette.background.neutral,
          },
        }),
        icon: {
          width: "16px",
          height: "16px",
        },
        sizeSmall: {
          height: "30px",
          borderRadius: "16px",

          "& .MuiChip-icon": {
            marginLeft: "8px",
          },
        },
        sizeMedium: {
          height: "36px",
          borderRadius: "30px",

          "& .MuiChip-icon": {
            marginLeft: "12px",
          },
        },
        label: {
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: (theme) => ({
              boxShadow: theme.shadows[2],
              backgroundColor: theme.palette.background.default,
            }),
          },
        },
        IconComponent: (props) => {
          return (
            <IconButton
              sx={{
                width: "48px",
                height: "48px",
                right: "4px !important",
                top: "0 !important",

                "& svg": {
                  width: "24px",
                  height: "24px",
                },
              }}
              {...props}
            >
              <ExpandMore disabled={props.className.includes("disabled")} />
            </IconButton>
          );
        },
      },
      styleOverrides: {
        select: ({ theme }) => ({
          fontSize: "16px",
          lineHeight: "28px",
        }),
        // @ts-ignore actually works
        root: {
          "& .MuiBackdrop-root": {
            opacity: `0 !important`,
            cursor: "initial",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMore />,
        sx: {
          "label[data-shrink=false]+.MuiInputBase-formControl input::-webkit-input-placeholder":
            {
              opacity: "1 !important",
            },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .Mui-disabled svg": {
            color: `${theme.palette.icon.disabled} !important`,
          },
        }),
        inputRoot: {
          padding: `0 !important`,
        },
        clearIndicator: ({ theme }) => ({
          color: theme.palette.icon.primary,
          "& svg": {
            width: "22px",
            height: "22px",
          },
        }),
        groupLabel: ({ theme }) => ({
          lineHeight: "30px",
          color: theme.palette.text.primary,
          fontSize: "14px",
        }),
        option: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: "14px",
          lineHeight: "22px",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: theme.palette.background.subtle,
          },
          minHeight: "auto",
          padding: `${theme.spacing(2.5, 3)} !important`,

          "&:not(:last-child)": {
            borderBottom: `1px solid ${theme.palette.border.secondary}`,
          },
        }),
        popupIndicator: ({ theme }) => ({
          color: theme.palette.icon.primary,
        }),
        endAdornment: {
          height: "28px",
          display: "flex",
          alignItems: "center",
          right: "6px !important",

          "& > button": {
            height: "48px",
            width: "48px",
          },

          "& svg": {
            width: "24px",
            height: "24px",
          },
        },
        input: ({ theme }) => ({
          padding: `${theme.spacing(3, 16, 3, 3)} !important`,

          "&::placeholder": {
            opacity: 1,
            fontSize: "16px",
            color: theme.palette.text.tertiary,
          },
        }),
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          marginTop: theme.spacing(4.5),
          marginBottom: "5px",
          boxShadow: theme.shadows[2],
          border: `1px solid ${theme.palette.border.secondary}`,
        }),
        listbox: ({ theme }) => ({
          padding: theme.spacing(0),
        }),
        loading: {
          fontSize: "14px",
        },
        noOptions: {
          fontSize: "14px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          marginBottom: theme.spacing(1),
          color:
            ownerState.color === "secondary"
              ? theme.palette.text.secondary
              : theme.palette.text.primary,
          letterSpacing: "-0.01em",
          transform: "none",
          position: "relative",

          "&.Mui-focused": {
            color: theme.palette.text.primary,
          },

          "&.Mui-error": {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& fieldset": {
            top: 0,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.border.secondary,
          },
          "& legend": {
            display: "none",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.border.secondary,
            },
          },
          "&:focus-within": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.background.subtle,

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.border.secondary,
            },
          },
        }),
        input: ({ theme }) => ({
          padding: theme.spacing(2, 3),
          fontSize: "14px",
          lineHeight: "20px",
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme, ownerState: { color } }) => {
          return {
            border:
              color === "secondary"
                ? `1px solid ${theme.palette.border.secondary}`
                : "none",
            backgroundColor:
              color === "secondary"
                ? theme.palette.background.subtle
                : "transparent",
            padding:
              color === "secondary" ? theme.spacing(1, 1.5) : theme.spacing(0),
            borderRadius: theme.shape.borderRadius,
            gap: theme.spacing(2),

            ".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius,

              "&.Mui-selected": {
                borderLeft:
                  color === "secondary"
                    ? `1px solid ${theme.palette.border.secondary}`
                    : "none",
              },
            },

            "& .MuiToggleButton-root": {
              textTransform: "none",
              border: "1px solid transparent",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
              flex: 1,
              color: theme.palette.text.secondary,
              padding: theme.spacing(1, 1.5),
              borderRadius: "4px !important",

              "&.Mui-selected": {
                color: theme.palette.primary.main,
                fontWeight: color === "secondary" ? 600 : 500,
                textDecoration: color === "secondary" ? "none" : "underline",
                boxShadow: "none",
                backgroundColor: theme.palette.common.white,
                border:
                  color === "secondary"
                    ? `1px solid ${theme.palette.border.secondary}`
                    : "none",
              },
              "&.Mui-selected:hover": {
                textDecoration: color === "secondary" ? "none" : "underline",
              },
              "&.Mui-disabled": {
                borderColor: "transparent",
              },
            },
          };
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        popper: {
          zIndex: 7000,
        },
        tooltipPlacementTop: {
          "&.MuiTooltip-tooltipArrow": {
            marginBottom: "10px !important",
          },
          marginBottom: "5px !important",
        },
        tooltipPlacementRight: {
          "&.MuiTooltip-tooltipArrow": {
            marginLeft: "12px !important",
          },
          marginLeft: "5px !important",
        },
        tooltipPlacementBottom: {
          "&.MuiTooltip-tooltipArrow": {
            marginTop: "12px !important",
          },
          marginTop: "4px !important",
        },
        tooltipPlacementLeft: {
          "&.MuiTooltip-tooltipArrow": {
            marginRight: "12px !important",
          },
          marginRight: "4px !important",
        },
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.tooltip,
          [`.MuiTooltip-popper[data-popper-placement*="bottom"] &`]: {
            margin: "5px 0",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="bottom"] &`]: {
            margin: "10px 0",
          },
          [`.MuiTooltip-popper[data-popper-placement*="top"] &`]: {
            margin: "5px 0",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="top"] &`]: {
            margin: "10px 0",
          },
          [`.MuiTooltip-popper[data-popper-placement*="right"] &`]: {
            margin: "0 5px",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="right"] &`]: {
            margin: "0 10px",
          },
          [`.MuiTooltip-popper[data-popper-placement*="left"] &`]: {
            margin: "0 5px",
          },
          [`.MuiTooltip-popperArrow[data-popper-placement*="left"] &`]: {
            margin: "0 10px",
          },
          color: colors.text.primary,
          boxShadow: `${baseLightShadows[3]} !important`,
          padding: theme.spacing(2),
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.background.tooltip,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          cursor: "pointer",
          textDecoration: "underline",

          "&:hover": {
            textDecoration: "underline",
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          ".MuiIconButton-root": {
            height: "36px",
            width: "36px",
            marginRight: "-9px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: "auto",
          "&.MuiInputBase-adornedEnd": {
            paddingRight: "14px",
          },
          "&.Mui-disabled .MuiInputAdornment-root .MuiTypography-root": {
            color: theme.palette.text.disabled,
          },
        }),
        input: ({ theme }) => ({
          fontSize: "16px !important",
          lineHeight: "24px !important",
          fontWeight: 400,
          padding: `${theme.spacing(3)} !important`,

          "& .MuiBackdrop-root": {
            opacity: `0 !important`,
            cursor: "initial",
          },

          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.tertiary,
          },
        }),
        multiline: {
          padding: "0 !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "14px",
          lineHeight: 1.43,
          letterSpacing: "0.01em",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: { shrink: true },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2, 3),
          borderColor: theme.palette.border.secondary,
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          marginTop: theme.spacing(4.5),
        }),
        list: ({ theme }) => ({
          padding: theme.spacing(0),
        }),
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelRowsPerPage: "Zeilen pro Seite:",
      },
    },
    MuiRating: {
      defaultProps: {
        emptyLabelText: "Keine Wertung",
      },
    },
    MuiAlert: {
      defaultProps: {
        closeText: "Schließen",
      },
    },
    MuiPagination: {
      defaultProps: {
        "aria-label": "Navigation via Seitennummerierung",
      },
    },
  },
  charts: {
    ...standardChartColors,
    assetsChart: {
      investments: colors.graphVisual["01"].mid,
      cash: colors.graphVisual["02"].mid,
      insurances: colors.graphVisual["05"].mid,
    },
    dashboardDonutChart: [
      colors.graphVisual["01"].dark,
      "#364471",
      colors.graphVisual["02"].light,
      colors.graphVisual["02"].mid,
      colors.graphVisual["02"].dark,
      colors.graphVisual["01"].light,
    ],
    securitiesChart: {
      ...standardChartColors.securitiesChart,
      mainLine: colors.success?.main!,
      secondaryLine: colors.error?.main!,
      all: colors.graphVisual["08"].dark,
      shares: colors.graphVisual["01"].dark,
      funds: colors.graphVisual["01"].mid,
      etfs: colors.graphVisual["01"].light,
      bonds: colors.graphVisual["05"].mid,
      currency: colors.graphVisual["05"].light,
      materialAssets: colors.graphVisual["03"].light,
      metals: "#FF7A5B",
      searched: colors.graphVisual["09"].dark,
    },
    speedometerChart: [
      colors.graphVisual["02"].dark,
      colors.graphVisual["02"].mid,
      colors.graphVisual["02"].light,
      colors.graphVisual["05"].mid,
      colors.graphVisual["04"].light,
      colors.graphVisual["04"].mid,
      colors.graphVisual["04"].dark,
    ],
    predictionChart: [
      colors.graphVisual["07"].dark,
      colors.graphVisual["07"].mid,
      colors.graphVisual["05"].lighter,
      colors.graphVisual["05"].mid,
      colors.graphVisual["02"].dark,
      colors.graphVisual["03"].mid,
      colors.graphVisual["06"].dark,
      colors.graphVisual["06"].light,
      colors.graphVisual["01"].mid,
      colors.graphVisual["01"].dark,
    ],
    horizontalBarChart: {
      positive: colors.graphVisual["02"].lighter,
      negative: colors.graphVisual["04"].light,
      estimated: colors.graphVisual["08"].lighter,
      current: colors.graphVisual["08"].dark,
      neutral: colors.graphVisual["08"].mid,
    },
    donutChart: [
      "#32458F",
      "#4D6BDD",
      "#94A6EB",
      "#FED749",
      "#FFEFB6",
      "#FFB5A5",
      "#FF7A5B",
      "#CC563A",
    ],
    cashflowChart: {
      ...standardChartColors.cashflowChart,
      income: colors.graphVisual["02"].mid,
      expenses: colors.graphVisual["01"].mid,
      disabled: alpha("#737E93", 0.2),
      others: colors.graphVisual["05"].mid,
    },
    classificationChart: [
      "#283874",
      "#32458F",
      "#4D6BDD",
      "#7189E4",
      "#94A6EB",
      "#0A6644",
      "#0FA36E",
      "#11B87B",
      "#42D6A1",
      "#71E0B8",
      "#B8F0DC",
    ],
    dividendPlannerChart: {
      currentMonthBarBackground: "#4D6BDD1F",
      chartBarRegular: "#4D6BDD",
    },
    dividendsChart: [
      "#3957CD",
      "#49A369",
      "#F5C142",
      "#F58B5D",
      "#A347FF",
      "#52BBF0",
      "#E27AB2",
      "#1335B5",
      "#5EDDA8",
      "#E0D81B",
      "#FF6347",
      "#ED61E8",
      "#C81686",
      "#3385FF",
      "#00EBEB",
      "#99E426",
      "#F0A800",
      "#EB5149",
      "#842BD7",
      "#8286EA",
    ],
  },
};

export const finanzflussThemeLightEn = createTheme(finanzflussTheme, enUS);
export const finanzflussThemeLightDe = createTheme(finanzflussTheme, deDE);
