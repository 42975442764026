import { StateType } from "../stateMachine/types/StateType";

export const useStateHelpers = (currentState: StateType) => {
  const isLoadingState = () =>
    currentState.matches("idle") ||
    currentState.matches("loadBooking") ||
    currentState.matches("split.loadBookings") ||
    currentState.matches("loadInvestment") ||
    currentState.matches("convertEntryQuoteToCurrency") ||
    currentState.matches("loadExchangeRate") ||
    currentState.matches("validateBooking") ||
    currentState.matches("persist");

  const isLoadingStateForModals = () =>
    currentState.matches("loadBooking") ||
    currentState.matches("split.loadBookings") ||
    currentState.matches("loadInvestment") ||
    currentState.matches("convertEntryQuoteToCurrency") ||
    currentState.matches("loadExchangeRate");

  return {
    isLoadingState,
    isLoadingStateForModals,
  };
};
