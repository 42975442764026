import { getDiscountCode, validateDiscountCode } from "@api/v1/methods";
import { useAuthQuery } from "@api/auth";

export const useValidateDiscountCode = (discountCode?: string) =>
  useAuthQuery(
    ["load-discount-code", discountCode!],
    ({ token }) => validateDiscountCode(token, discountCode!),
    {
      enabled: !!discountCode,
    }
  );

export const useGetDiscountCode = () =>
  useAuthQuery(["get-discount-code"], ({ token }) => getDiscountCode(token));
