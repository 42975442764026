import React, { useRef, useState } from "react";
import { IconButton, IconButtonProps, Popover, useTheme } from "@mui/material";
import { ReactComponent as Calendar } from "@icons/calendar-schedule.svg";
import { DateRangePicker } from "@components";
import { DateRangePickerProps } from "@components/DateRangePicker/DateRangePicker";

export interface DatePickerProps
  extends Omit<IconButtonProps, "onChange">,
    Omit<DateRangePickerProps, "onChange"> {
  defaultFrom?: Date;
  onChange: (from: Date, to?: Date) => void;
  onOpen?: () => void;
  from?: Date;
  to?: Date;
  disablePortal?: boolean;
}

export const DatePicker = ({
  onOpen,
  onChange,
  defaultFrom,
  defaultTo,
  from,
  to,
  disablePortal,
  ...rest
}: DatePickerProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handleDateChange = (from?: Date, to?: Date) => {
    if (!from) return;
    onChange(from, to);
    if (to) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disablePortal
      >
        <DateRangePicker
          onChange={handleDateChange}
          defaultFrom={defaultFrom}
          defaultTo={defaultTo}
          from={from}
          to={to}
          disabledDays={[{ after: new Date() }]}
        />
      </Popover>
      <IconButton
        size="small"
        onClick={handleOpen}
        ref={ref}
        sx={{ height: "32px", color: theme.palette.icon.tertiary, ...rest.sx }}
        {...rest}
      >
        <Calendar width="20px" height="20px" />
      </IconButton>
    </>
  );
};
