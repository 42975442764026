/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Comment } from '../model';
// @ts-ignore
import { CreateOrUpdateCommentParams } from '../model';
// @ts-ignore
import { PageableCommentList } from '../model';
/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a comment
         * @param {number} id Delete the comment with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/v1/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a comment attached to an investment or watchlist entry. A challenge token will be required                      for spam protection unless the mandator disabled it (mandator configuration property                      \'commentChallengeTokenEnabled\').
         * @param {CreateOrUpdateCommentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (body: CreateOrUpdateCommentParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createComment', 'body', body)
            const localVarPath = `/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get or search Comments
         * @param {number} [investmentId] Retrieve comments for the given investment; requires an authenticated user.                              Note that the current user might not have                              privileges to see comments for another users investment.
         * @param {number} [watchlistEntryId] Retrieve comments for the given watchlist entry; requires an authenticated user.                              This parameter will be ignored if an investmentId is provided.                              Note that the current user might not have                              privileges to see comments for another users watchlist entries.
         * @param {Array<string>} [isin] Retrieve public comments for the given ISIN
         * @param {string} [mandatorPublicId] Filter public comments by the given mandator
         * @param {string} [minDate] Retrieve only comments with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for this endpoint is \&#39;createdAt asc\&#39; (which is a deviation from the                              standard sort by ID normally used).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllComments: async (investmentId?: number, watchlistEntryId?: number, isin?: Array<string>, mandatorPublicId?: string, minDate?: string, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (investmentId !== undefined) {
                localVarQueryParameter['investmentId'] = investmentId;
            }

            if (watchlistEntryId !== undefined) {
                localVarQueryParameter['watchlistEntryId'] = watchlistEntryId;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }

            if (minDate !== undefined) {
                localVarQueryParameter['minDate'] = minDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a comment
         * @param {number} id Retrieve the comment with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showComment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showComment', 'id', id)
            const localVarPath = `/v1/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a comment
         * @param {number} id Delete the comment with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a comment attached to an investment or watchlist entry. A challenge token will be required                      for spam protection unless the mandator disabled it (mandator configuration property                      \'commentChallengeTokenEnabled\').
         * @param {CreateOrUpdateCommentParams} body The comment details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(body: CreateOrUpdateCommentParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.createComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get or search Comments
         * @param {number} [investmentId] Retrieve comments for the given investment; requires an authenticated user.                              Note that the current user might not have                              privileges to see comments for another users investment.
         * @param {number} [watchlistEntryId] Retrieve comments for the given watchlist entry; requires an authenticated user.                              This parameter will be ignored if an investmentId is provided.                              Note that the current user might not have                              privileges to see comments for another users watchlist entries.
         * @param {Array<string>} [isin] Retrieve public comments for the given ISIN
         * @param {string} [mandatorPublicId] Filter public comments by the given mandator
         * @param {string} [minDate] Retrieve only comments with date after or equal than the given date; format is yyyy-MM-dd (US Format)
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for this endpoint is \&#39;createdAt asc\&#39; (which is a deviation from the                              standard sort by ID normally used).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllComments(investmentId?: number, watchlistEntryId?: number, isin?: Array<string>, mandatorPublicId?: string, minDate?: string, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableCommentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllComments(investmentId, watchlistEntryId, isin, mandatorPublicId, minDate, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.listAndSearchAllComments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a comment
         * @param {number} id Retrieve the comment with the given ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showComment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showComment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.showComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a comment
         * @param {CommentsApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: CommentsApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a comment attached to an investment or watchlist entry. A challenge token will be required                      for spam protection unless the mandator disabled it (mandator configuration property                      \'commentChallengeTokenEnabled\').
         * @param {CommentsApiCreateCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(requestParameters: CommentsApiCreateCommentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.createComment(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get or search Comments
         * @param {CommentsApiListAndSearchAllCommentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllComments(requestParameters: CommentsApiListAndSearchAllCommentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableCommentList> {
            return localVarFp.listAndSearchAllComments(requestParameters.investmentId, requestParameters.watchlistEntryId, requestParameters.isin, requestParameters.mandatorPublicId, requestParameters.minDate, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a comment
         * @param {CommentsApiShowCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showComment(requestParameters: CommentsApiShowCommentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.showComment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in CommentsApi.
 * @export
 * @interface CommentsApiDeleteRequest
 */
export interface CommentsApiDeleteRequest {
    /**
     * Delete the comment with the given ID.
     * @type {number}
     * @memberof CommentsApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for createComment operation in CommentsApi.
 * @export
 * @interface CommentsApiCreateCommentRequest
 */
export interface CommentsApiCreateCommentRequest {
    /**
     * The comment details
     * @type {CreateOrUpdateCommentParams}
     * @memberof CommentsApiCreateComment
     */
    readonly body: CreateOrUpdateCommentParams
}

/**
 * Request parameters for listAndSearchAllComments operation in CommentsApi.
 * @export
 * @interface CommentsApiListAndSearchAllCommentsRequest
 */
export interface CommentsApiListAndSearchAllCommentsRequest {
    /**
     * Retrieve comments for the given investment; requires an authenticated user.                              Note that the current user might not have                              privileges to see comments for another users investment.
     * @type {number}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly investmentId?: number

    /**
     * Retrieve comments for the given watchlist entry; requires an authenticated user.                              This parameter will be ignored if an investmentId is provided.                              Note that the current user might not have                              privileges to see comments for another users watchlist entries.
     * @type {number}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly watchlistEntryId?: number

    /**
     * Retrieve public comments for the given ISIN
     * @type {Array<string>}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly isin?: Array<string>

    /**
     * Filter public comments by the given mandator
     * @type {string}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly mandatorPublicId?: string

    /**
     * Retrieve only comments with date after or equal than the given date; format is yyyy-MM-dd (US Format)
     * @type {string}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly minDate?: string

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for this endpoint is \&#39;createdAt asc\&#39; (which is a deviation from the                              standard sort by ID normally used).
     * @type {string}
     * @memberof CommentsApiListAndSearchAllComments
     */
    readonly order?: string
}

/**
 * Request parameters for showComment operation in CommentsApi.
 * @export
 * @interface CommentsApiShowCommentRequest
 */
export interface CommentsApiShowCommentRequest {
    /**
     * Retrieve the comment with the given ID.
     * @type {number}
     * @memberof CommentsApiShowComment
     */
    readonly id: number
}

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a comment
     * @param {CommentsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public _delete(requestParameters: CommentsApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a comment attached to an investment or watchlist entry. A challenge token will be required                      for spam protection unless the mandator disabled it (mandator configuration property                      \'commentChallengeTokenEnabled\').
     * @param {CommentsApiCreateCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public createComment(requestParameters: CommentsApiCreateCommentRequest, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).createComment(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get or search Comments
     * @param {CommentsApiListAndSearchAllCommentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public listAndSearchAllComments(requestParameters: CommentsApiListAndSearchAllCommentsRequest = {}, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).listAndSearchAllComments(requestParameters.investmentId, requestParameters.watchlistEntryId, requestParameters.isin, requestParameters.mandatorPublicId, requestParameters.minDate, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a comment
     * @param {CommentsApiShowCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public showComment(requestParameters: CommentsApiShowCommentRequest, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).showComment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

