/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PublicAccount
 */
export interface PublicAccount {
    /**
     * Technical identifier
     * @type {number}
     * @memberof PublicAccount
     */
    'id': number;
    /**
     * The high-level account type; as assigned by the Rentablo SaaS platform. This type             defines the behaviour of and the actions available on this account. I.e. only a depot may have investments and             only a cash account has a balance.
     * @type {string}
     * @memberof PublicAccount
     */
    'type': PublicAccountTypeEnum;
    /**
     * true if this account is marked hidden
     * @type {boolean}
     * @memberof PublicAccount
     */
    'isHidden'?: boolean;
    /**
     * The detailed type of the account as defined by the account holding bank. This will be             null if not provided or the account is manually maintained.
     * @type {string}
     * @memberof PublicAccount
     */
    'typeFromBank'?: PublicAccountTypeFromBankEnum;
    /**
     * The ID of the contra account; only available if this is a depot. Each booking on a                                depot will result in a transaction on the contra account.
     * @type {number}
     * @memberof PublicAccount
     */
    'contraAccountId'?: number;
    /**
     * If this is a contra account: the IDs of all attached depots.
     * @type {Array<number>}
     * @memberof PublicAccount
     */
    'depotIds'?: Array<number>;
    /**
     * The literal account name
     * @type {string}
     * @memberof PublicAccount
     */
    'name': string;
    /**
     * The account balance
     * @type {number}
     * @memberof PublicAccount
     */
    'balance'?: number;
    /**
     * The account currency (3 letter ISO code)
     * @type {string}
     * @memberof PublicAccount
     */
    'currency': string;
    /**
     * The accounts bank connection type (if linked to a bank).
     * @type {string}
     * @memberof PublicAccount
     */
    'bankConnectionType'?: PublicAccountBankConnectionTypeEnum;
    /**
     * The accounts literal bank name; if linked to a bank or defined by the user.
     * @type {string}
     * @memberof PublicAccount
     */
    'bankName'?: string;
    /**
     * DEPRECATED: this field name is misleading; use bicGroup instead.             A grouping of banks to a common slug like \'SPARKASSE\'. Will contain the BIC itself             if the given bank has only a single BIC.
     * @type {string}
     * @memberof PublicAccount
     */
    'bankGroup'?: string;
    /**
     * A grouping of banks to a common slug like \'SPARKASSE\'. Will contain the BIC itself             if the given bank has only a single BIC.
     * @type {string}
     * @memberof PublicAccount
     */
    'bicGroup'?: string;
    /**
     * Url to display the bank icon.
     * @type {string}
     * @memberof PublicAccount
     */
    'iconUrl': string;
    /**
     * The earliest balance that we got at the start of the very first bank synchronization run
     * @type {number}
     * @memberof PublicAccount
     */
    'initialBalance'?: number;
    /**
     * Date of this very first balance
     * @type {string}
     * @memberof PublicAccount
     */
    'initialBalanceDate'?: string;
    /**
     * The number of active assets; this may be investments, material assets or insurances
     * @type {number}
     * @memberof PublicAccount
     */
    'activeAssets'?: number;
    /**
     * Date of the last manual synchronization
     * @type {string}
     * @memberof PublicAccount
     */
    'lastManualSyncDate'?: string;
    /**
     * Date of the last automatic (via background job) synchronization
     * @type {string}
     * @memberof PublicAccount
     */
    'lastAutoSyncDate'?: string;
    /**
     * Date of the last manual or automatic synchronization
     * @type {string}
     * @memberof PublicAccount
     */
    'lastSyncDate'?: string;
    /**
     * A user defined sub-category
     * @type {number}
     * @memberof PublicAccount
     */
    'subCategoryId'?: number;
}

export const PublicAccountTypeEnum = {
    _01_DEPOT: '01_depot',
    _02_CASH: '02_cash',
    _04_INSURANCE: '04_insurance',
    _05_MATERIAL_ASSETS: '05_material_assets'
} as const;

export type PublicAccountTypeEnum = typeof PublicAccountTypeEnum[keyof typeof PublicAccountTypeEnum];
export const PublicAccountTypeFromBankEnum = {
    CHECKING: 'CHECKING',
    SAVINGS: 'SAVINGS',
    CREDIT_CARD: 'CREDIT_CARD',
    DEPOT: 'DEPOT',
    LOAN: 'LOAN',
    MEMBERSHIP: 'MEMBERSHIP',
    HOME_PURCHASE_SAVINGS: 'HOME_PURCHASE_SAVINGS',
    REFERENCE_ACCOUNT: 'REFERENCE_ACCOUNT',
    FOREIGN_CURRENCY_ACCOUNT: 'FOREIGN_CURRENCY_ACCOUNT'
} as const;

export type PublicAccountTypeFromBankEnum = typeof PublicAccountTypeFromBankEnum[keyof typeof PublicAccountTypeFromBankEnum];
export const PublicAccountBankConnectionTypeEnum = {
    WEALTH_API: 'WEALTH_API',
    FIN_API: 'FIN_API',
    NETFONDS: 'NETFONDS'
} as const;

export type PublicAccountBankConnectionTypeEnum = typeof PublicAccountBankConnectionTypeEnum[keyof typeof PublicAccountBankConnectionTypeEnum];


