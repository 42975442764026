import React, { ReactNode } from "react";
import { Theme, Card as MuiCard, styled, css } from "@mui/material";
const onClickCss = (theme: Theme) => css`
  cursor: pointer;
  &:hover {
    box-shadow: ${theme.shadows[4]};
  }
`;

const getSharedStyle = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.palette.background.paper};
`;

const MiniContainer = styled(MuiCard)`
  ${({ theme }) => getSharedStyle(theme)}
  height: 160px;
  padding: 18px 24px;
  ${({ onClick, theme }) => (onClick ? onClickCss(theme) : "")}
`;

const MaxiContainer = styled(MuiCard)`
  ${({ theme }) => getSharedStyle(theme)}
  ${({ onClick, theme }) => (onClick ? onClickCss(theme) : "")};
  overflow: hidden;
`;

const RowContainer = styled(MuiCard)`
  ${({ theme }) => getSharedStyle(theme)}
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) =>
    theme.spacing(6)};

  ${({ onClick, theme }) => (onClick ? onClickCss(theme) : "")}
`;

const MicroContainer = styled(MuiCard)`
  ${({ theme }) => getSharedStyle(theme)}
  height: 120px;
  width: 250px;
  ${({ onClick, theme }) => (onClick ? onClickCss(theme) : "")}
`;

export interface CardProps {
  size?: "mini" | "maxi" | "row" | "micro";
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Card = ({ size = "mini", ...rest }: CardProps) => {
  if (size === "maxi") {
    return <MaxiContainer {...rest} />;
  }

  if (size === "row") {
    return <RowContainer {...rest} />;
  }

  if (size === "micro") {
    return <MicroContainer {...rest} />;
  }
  return <MiniContainer {...rest} />;
};
