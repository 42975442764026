import { UseQueryOptions } from "react-query";
import {
  ExchangeRatesApiExchangeRatesRequest,
  ExchangeRate,
} from "@generated/apiv1";

import { buildExchangeRatesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const exchangeRates = async (
  token: string | undefined,
  params: ExchangeRatesApiExchangeRatesRequest
) => {
  const apiInstance = buildExchangeRatesApi(token);
  const { data } = await apiInstance.exchangeRates(params);
  return data;
};

export const useExchangeRates = (
  params: ExchangeRatesApiExchangeRatesRequest,
  options?: UseQueryOptions<ExchangeRate, AxiosApiError, ExchangeRate>
) => {
  return useAuthQuery(
    [CACHE_KEYS.EXCHANGERATES_EXCHANGE_RATES, params],
    ({ token }) => exchangeRates(token, params),
    options
  );
};
