import { UseQueryOptions } from "react-query";
import {
  SubscriptionsApiValidatePrimaryAccountIdentifierRequest,
  ValidationResult,
} from "@generated/apiv1";

import { buildSubscriptionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const validatePrimaryAccountIdentifier = async (
  token: string | undefined,
  params: SubscriptionsApiValidatePrimaryAccountIdentifierRequest
) => {
  const apiInstance = buildSubscriptionsApi(token);
  const { data } = await apiInstance.validatePrimaryAccountIdentifier(params);
  return data;
};

export const useValidatePrimaryAccountIdentifier = (
  params: SubscriptionsApiValidatePrimaryAccountIdentifierRequest,
  options?: UseQueryOptions<ValidationResult, AxiosApiError, ValidationResult>
) => {
  return useAuthQuery(
    [CACHE_KEYS.SUBSCRIPTIONS_VALIDATE_PRIMARY_ACCOUNT_IDENTIFIER, params],
    ({ token }) => validatePrimaryAccountIdentifier(token, params),
    options
  );
};
