import { UseQueryOptions } from "react-query";
import {
  InvestmentsApiBatchPublicInvestmentPerformanceRequest,
  BatchSingleInvestmentTwror,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const batchPublicInvestmentPerformance = async (
  token: string | undefined,
  params: InvestmentsApiBatchPublicInvestmentPerformanceRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.batchPublicInvestmentPerformance(params);
  return data;
};

export const useBatchPublicInvestmentPerformance = (
  params: InvestmentsApiBatchPublicInvestmentPerformanceRequest,
  options?: UseQueryOptions<
    BatchSingleInvestmentTwror,
    AxiosApiError,
    BatchSingleInvestmentTwror
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INVESTMENTS_BATCH_PUBLIC_INVESTMENT_PERFORMANCE, params],
    ({ token }) => batchPublicInvestmentPerformance(token, params),
    options
  );
};
