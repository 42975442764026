import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import truncate from "lodash/truncate";
import { useNavigate } from "react-router-dom";
import { CreateOrUpdateAccountParams } from "@generated/apiv1";
import {
  listAndSearchAllAccounts,
  useUpdateAccount,
} from "@generated/apiv1/hooks";
import { useAccounts } from "@api/v1/hooks/useAccounts";
import { GET_ACCOUNTS } from "@api/cacheKeys";
import { useSearchBanks } from "@api/v1/hooks/useBank";
import { LoadingProgressBar, PageLayout } from "@components";
import { useGetBankConnection } from "@generated/apiv3/hooks";
import { useToken } from "@api";
import { CreateAndEditAccountForm } from "./CreateAndEditAccountForm";
import { CreateAndUpdateResultMessage } from "./CreateAndUpdateResultMessage";

const SaveButton = styled(Button)`
  align-self: flex-start;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  max-width: 700px;
`;

interface CreateAndEditAccountProps {
  accountId: string;
  type: "account" | "depot";
}

export const CreateAndEditAccount = (props: CreateAndEditAccountProps) => {
  const { accountId, type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const parsedAccountId = Number.parseInt(accountId, 10);

  const [accountValues, setAccountValues] =
    useState<CreateOrUpdateAccountParams>({
      name: "",
      type: "01_depot",
    });

  const { data: token } = useToken();
  const { mutateAsync, error, status } = useUpdateAccount({
    onSuccess: () => {
      queryClient.refetchQueries([GET_ACCOUNTS]);
    },
  });
  const { data: accountsResponse, isLoading: isAccountsLoading } = useAccounts(
    {
      id: [parsedAccountId],
    },
    {
      onSuccess: (data) => {
        const [account] = data?.accounts ?? [];
        setAccountValues({
          name: account?.name ?? "",
          type: account?.type ?? "01_depot",
        });
      },
    }
  );

  const [account] = accountsResponse?.accounts ?? [];

  const { data: bankConnection, isLoading: isConnectionLoading } =
    useGetBankConnection(
      {
        id: account?.bankConnectionId!,
      },
      {
        enabled: Boolean(
          account?.bankConnectionId &&
            account?.bankConnectionType === "WEALTH_API"
        ),
      }
    );

  const { data: searchBankResponse } = useSearchBanks(
    account?.bankCode as string,
    {
      enabled: Boolean(account?.bankCode),
    }
  );
  const isParqet = bankConnection?.data?.attributes.bic === "PARQDEB1XXX";
  const [bank] = searchBankResponse?.banks ?? [];

  const handleUpdateAccount = () => {
    mutateAsync({ body: accountValues, id: parsedAccountId }).then(() => {
      setTimeout(() => {
        navigate("/depots-accounts");
      }, 1000);
    });
    // updating referenceAccountForParqet
    if (isParqet && account.contraAccountId) {
      listAndSearchAllAccounts(token?.access_token, {
        id: [account.contraAccountId],
      }).then((data) => {
        if (!data.accounts?.[0]) return;
        mutateAsync({
          body: {
            type: data.accounts[0].type,
            name: data.accounts[0].name,
            bankName: accountValues.bankName ?? undefined,
            bic: accountValues.bic ?? undefined,
          },
          id: account.contraAccountId!,
        });
      });
    }
  };

  const handleUpdateAccountValues = (
    params: Partial<CreateOrUpdateAccountParams>
  ) => {
    setAccountValues((values) => {
      return { ...values, ...params };
    });
  };

  const requestStatus =
    status === "success" || status === "error" ? status : undefined;

  const url = type === "depot" ? "/depots" : "/accounts";
  const accountName = account?.name ?? "";

  if (isConnectionLoading || isAccountsLoading) {
    return <LoadingProgressBar />;
  }

  return (
    <PageLayout
      title={t("createAndEditAccount.header.title", { value: accountName })}
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        {
          to: "/depots-accounts",
          text:
            type === "depot"
              ? t("createAndEditAccount.breadcrumbs.depots")
              : t("createAndEditAccount.breadcrumbs.accounts"),
        },
        {
          to: `${url}/${accountId}`,
          text: truncate(accountName),
        },
        {
          text: t("createAndEditAccount.breadcrumbs.edit"),
        },
      ]}
    >
      <Container data-testid="account-edit-form">
        <CreateAndUpdateResultMessage
          message={error?.response?.data?.message}
          type={requestStatus}
        />

        <CreateAndEditAccountForm
          accountValues={accountValues}
          hideFields={{
            bank: Boolean(account?.bankConnectionType && !isParqet),
            name: false,
            type: true,
          }}
          defaultBank={bank}
          onUpdateAccountValues={handleUpdateAccountValues}
        />

        <SaveButton
          data-testid="save-button"
          variant="contained"
          color="primary"
          disabled={isAccountsLoading}
          onClick={handleUpdateAccount}
        >
          {t("createAndEditAccount.actions.save")}
        </SaveButton>
      </Container>
    </PageLayout>
  );
};
