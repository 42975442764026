import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { Box, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useAccountChartData } from "@features/accounts/hooks/useAccountChartData";
import { toCurrency, intervalToTimeRange } from "@helpers";
import { PageLayout, AccountsHeader, PrimaryActionWithMenu } from "@components";
import { ReactComponent as PlusIcon } from "@icons/plus-icon.svg";
import { useAccount } from "@api/v1/hooks/useAccount";
import { useDeleteAccount, useUpdateAccount } from "@api";
import { SingleAccountChart } from "@components/Chart/SingleAccountChart";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import { AccountProtocolTable } from "@components/Accounts/TransactionTable/AccountProtocolTable";
import { NoDataStub } from "@components/Chart/NoDataStub";
import { ConfirmDeleteDialog } from "@components/Modal/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { SingleAccountMenu } from "@features/accounts/components/SingleAccountMenu";

interface Props {
  accountId: number;
}

const TableWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(12),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(8),
  },
}));

const Title = ({ title }: { title: number | string }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        {t("accountChart.subtitle")}
      </Typography>
      <Typography variant="h4">{title}</Typography>
    </Box>
  );
};

export const SingleAccountPage = ({ accountId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] =
    React.useState<IntervalType>("oneYear");
  const [range, setRange] = React.useState<RangeType>(
    intervalToTimeRange("oneYear")
  );
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    mutateAsync,
    reset: resetDeleteAccountResult,
    isLoading: isDeleteAccountLoading,
    isError: isDeleteAccountError,
    error: deleteAccountError,
  } = useDeleteAccount();

  const { mutateAsync: updateMutateAsync } = useUpdateAccount();

  const { data: account } = useAccount(accountId);

  const { chartData, accountBalance, isFetching, isFetched } =
    useAccountChartData({
      accountId,
      startDate: range.startDate,
      endDate: range.endDate,
    });

  const onCustomRangeSelect = (from: Date, to: Date) => {
    setSelectedRange("custom");
    setRange({ startDate: from, endDate: to });
  };

  useEffect(() => {
    if (selectedRange === "custom") return;
    setRange(intervalToTimeRange(selectedRange));
  }, [selectedRange]);

  const handleDeleteAccount = async () => {
    await mutateAsync(accountId);
    navigate("/depots-accounts");
  };
  const handleHideAccount = async () => {
    await updateMutateAsync({
      id: accountId,
      isHidden: !account!.isHidden,
      type: account!.type!,
      name: account!.name,
    });
    navigate("/depots-accounts");
  };
  const handleOpenDeleteModal = () => {
    setDeleteModalShow(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalShow(false);

    if (isDeleteAccountError) {
      resetDeleteAccountResult();
    }
  };

  const hasNoData = isFetched && !chartData?.length;

  return (
    <PageLayout
      isMainPage
      title={
        <AccountsHeader
          title={account?.name ?? ""}
          subtitle={account?.bankName ?? ""}
        />
      }
      breadcrumbs={[
        { to: "/", text: t("breadcrumbs.home") },
        {
          to: "/depots-accounts",
          text: t("depotsAccounts.title"),
        },
        { text: truncate(account?.name) },
      ]}
      actionButton={
        <PrimaryActionWithMenu
          primaryActionMobileComponent={
            <IconButton
              color="secondary"
              onClick={() =>
                navigate(`/accounts/${account?.id}/transactions/add-manually`)
              }
            >
              <PlusIcon />
            </IconButton>
          }
          primaryAction={`/accounts/${account?.id}/transactions/add-manually`}
          primaryActionText={t("singleAccount.addTransaction")}
        >
          <SingleAccountMenu
            account={account!}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleHideAccount={handleHideAccount}
          />
        </PrimaryActionWithMenu>
      }
    >
      <Title
        title={
          toCurrency(
            accountBalance ?? account?.balance ?? 0,
            account?.currency ?? "EUR"
          ) || 0
        }
      />

      <Box my={4} display="flex" justifyContent="end">
        <RangeSelector
          range={selectedRange === "custom" ? range : undefined}
          intervalType={selectedRange}
          onSelect={setSelectedRange}
          onCustomSelect={onCustomRangeSelect}
        />
      </Box>

      {hasNoData ? (
        <NoDataStub />
      ) : (
        <SingleAccountChart
          chartData={chartData}
          isLoading={isFetching}
          intervalType={selectedRange}
        />
      )}

      <TableWrapper>
        <AccountProtocolTable accountId={accountId} range={range} />
      </TableWrapper>

      <ConfirmDeleteDialog
        isOpen={deleteModalShow}
        title={t("depots.deleteModal.question")}
        cancelButtonText={t("singleAccount.deleteModal.cancel")}
        confirmButtonText={t("singleAccount.deleteModal.confirm")}
        isDeleteRequestLoading={isDeleteAccountLoading}
        errorMessage={deleteAccountError?.response?.data?.message}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteAccount}
      />
    </PageLayout>
  );
};
