import { getPercent } from "@helpers";
import { InvestmentChartData } from "@components/Chart/InvestmentChart/types";
// TODO: more readable
export const calculatePercents = (
  quotes: InvestmentChartData[]
): InvestmentChartData[] => {
  let reference = quotes[0];
  let value = 1;
  let comparedValue = 1;
  let areaValue = 1;
  let areaValue2 = 1;

  return quotes.map((current) => {
    value *= current.value / reference.value;
    if (current.comparedValue && reference.comparedValue) {
      comparedValue *= current.comparedValue / reference.comparedValue;
    }
    areaValue *= current.areaValue[0] / reference.areaValue[0];
    areaValue2 *= current.areaValue[1] / reference.areaValue[1];

    reference = current;

    const percents = getPercent(value);
    const secondaryPercents = getPercent(comparedValue);
    const areaValues: [number, number] = [
      getPercent(areaValue),
      getPercent(areaValue2),
    ];

    return {
      ...current,
      value: percents,
      originalValue: current.value,
      eventsValue: percents,
      areaValue: areaValues,
      comparedValue: secondaryPercents,
      comparedOriginalValue: current.comparedValue,
    };
  });
};
