import { UseQueryOptions } from "react-query";
import { EtfTypeClassificationList } from "@generated/apiv3";

import { buildEtfTypeClassificationApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const listEtfTypeClassifications = async (token: string | undefined) => {
  const apiInstance = buildEtfTypeClassificationApi(token);
  const { data } = await apiInstance.listEtfTypeClassifications();
  return data;
};

export const useListEtfTypeClassifications = (
  options?: UseQueryOptions<
    EtfTypeClassificationList,
    AxiosApiError,
    EtfTypeClassificationList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ETFTYPECLASSIFICATION_LIST_ETF_TYPE_CLASSIFICATIONS],
    ({ token }) => listEtfTypeClassifications(token),
    options
  );
};
