import { UseMutationOptions } from "react-query";
import {
  BillingCyclesApiUpdateTaxationCategoryRequest,
  TaxationEstimate,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const updateTaxationCategory = async (
  token: string | undefined,
  params: BillingCyclesApiUpdateTaxationCategoryRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.updateTaxationCategory(params);
  return data;
};

export const useUpdateTaxationCategory = (
  options?: UseMutationOptions<
    TaxationEstimate,
    AxiosApiError,
    BillingCyclesApiUpdateTaxationCategoryRequest
  >
) => {
  return useAuthMutation(updateTaxationCategory, options, false);
};
