import { UseQueryOptions } from "react-query";
import {
  UserProfilesApiGetNewsletterSubscriptionRequest,
  NewsletterSubscriptionStatus,
} from "@generated/apiv1";

import { buildUserProfilesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getNewsletterSubscription = async (
  token: string | undefined,
  params: UserProfilesApiGetNewsletterSubscriptionRequest
) => {
  const apiInstance = buildUserProfilesApi(token);
  const { data } = await apiInstance.getNewsletterSubscription(params);
  return data;
};

export const useGetNewsletterSubscription = (
  params: UserProfilesApiGetNewsletterSubscriptionRequest,
  options?: UseQueryOptions<
    NewsletterSubscriptionStatus,
    AxiosApiError,
    NewsletterSubscriptionStatus
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.USERPROFILES_GET_NEWSLETTER_SUBSCRIPTION, params],
    ({ token }) => getNewsletterSubscription(token, params),
    options
  );
};
