import React, { cloneElement, ReactElement, ReactNode, useMemo } from "react";
import {
  Box,
  ClickAwayListener,
  styled,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  Typography,
} from "@mui/material";

const StyledTooltip = styled(
  ({ className, ...props }: MuiTooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "tooltipWidth",
  }
)<{
  variant: "default" | "white";
  placement: string;
  maxWidth?: string;
  tooltipWidth?: string;
}>(({ theme, variant, maxWidth, tooltipWidth }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor:
      variant === "white"
        ? theme.palette.background.default
        : theme.palette.background.tooltip,
    boxShadow: variant === "white" ? theme.shadows[3] : "none",
    maxWidth: tooltipWidth || maxWidth || "300px",
  },
  [`& .MuiTooltip-arrow`]: {
    color:
      variant === "white"
        ? theme.palette.background.default
        : theme.palette.background.tooltip,
  },
}));

export type TooltipProps = {
  title?: string;
  text: ReactNode;
  children: ReactElement;
  openOnClick?: boolean;
  isSmall?: boolean;
  variant?: "default" | "white";
  maxWidth?: string;
  tooltipWidth?: string;
  noWrap?: boolean;
} & Omit<MuiTooltipProps, "title">;

export const Tooltip = ({
  title,
  text,
  openOnClick,
  isSmall,
  children,
  variant = "default",
  noWrap,
  ...props
}: TooltipProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const Child = useMemo(
    () =>
      openOnClick
        ? cloneElement(children, {
            onClick: () => {
              setIsTooltipOpen((prev) => !prev);
            },
          })
        : children,
    [children, openOnClick]
  );

  return (
    <StyledTooltip
      variant={variant}
      enterDelay={200}
      enterNextDelay={200}
      title={
        <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
          <Box py={isSmall ? 0 : 2} px={isSmall ? 0 : 1}>
            {title && (
              <Typography
                variant="body2"
                fontWeight={600}
                mb={2}
                color={
                  variant === "white" ? "text.primary" : "text.tooltipPrimary"
                }
              >
                {title}
              </Typography>
            )}
            {typeof text === "string" ? (
              <Typography
                variant="body2"
                fontWeight={400}
                color={
                  variant === "white"
                    ? "text.secondary"
                    : "text.tooltipSecondary"
                }
                noWrap={noWrap}
              >
                {text}
              </Typography>
            ) : (
              text
            )}
          </Box>
        </ClickAwayListener>
      }
      placement="top"
      open={openOnClick ? isTooltipOpen : undefined}
      disableInteractive={false}
      {...props}
    >
      {Child}
    </StyledTooltip>
  );
};
