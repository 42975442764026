import { UseQueryOptions } from "react-query";
import {
  DividendsApiGetDividendsRequest,
  DividendList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getDividends = async (
  token: string | undefined,
  params: DividendsApiGetDividendsRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.getDividends(params);
  return data;
};

export const useGetDividends = (
  params: DividendsApiGetDividendsRequest,
  options?: UseQueryOptions<DividendList, AxiosApiError, DividendList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_GET_DIVIDENDS, params],
    ({ token }) => getDividends(token, params),
    options
  );
};
