import { Configuration } from "@generated/apiv3";

export const buildConfiguration = (token: string) =>
  new Configuration({
    basePath: `${process.env.REACT_APP_API_BASE_URL}/pdf-parser-v2`,
    apiKey: `Bearer ${token}`,
  });

export const buildAnonymousConfiguration = () =>
  new Configuration({
    basePath: `${process.env.REACT_APP_API_BASE_URL}/pdf-parser-v2`,
  });
