import { UseMutationOptions } from "react-query";
import { HistoricQuotesApiRefreshHistoricQuoteRequest } from "@generated/apiv1";

import { buildHistoricQuotesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const refreshHistoricQuote = async (
  token: string | undefined,
  params: HistoricQuotesApiRefreshHistoricQuoteRequest
) => {
  const apiInstance = buildHistoricQuotesApi(token);
  const { data } = await apiInstance.refreshHistoricQuote(params);
  return data;
};

export const useRefreshHistoricQuote = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    HistoricQuotesApiRefreshHistoricQuoteRequest
  >
) => {
  return useAuthMutation(refreshHistoricQuote, options, false);
};
