import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { isWithinInterval, parseJSON, compareDesc } from "date-fns";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Booking, Investment, InvestmentTypeEnum } from "@generated/apiv1";
import { midday } from "@helpers";
import { useRangeSelectControl } from "@hooks";
import { LoadingPlaceHolder } from "@components";
import {
  TableBookingData,
  BookingSingleInvestmentTable,
} from "./BookingsSingleInvestmentTable";

interface InvestmentProtocolContainerProps {
  bookingData: Booking[];
  investmentQuote: number;
  currentMarketValue: number;
  currencySymbol?: string;
  isLoading?: boolean;
  investment: Investment;
}

interface BookingDataConverterProps {
  bookingData: Booking[];
  investmentQuote: number;
}

export const BookingSingleInvestmentTableStyled = styled(
  BookingSingleInvestmentTable
)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const bookingDataConverter = ({
  bookingData,
  investmentQuote,
}: BookingDataConverterProps): TableBookingData[] => {
  return bookingData.map((booking): TableBookingData => {
    const entryQuote = booking?.entryQuote
      ? Math.abs(booking.entryQuote)
      : null;
    const priceChange =
      booking.type === "dividend" || !entryQuote || entryQuote === 0
        ? null
        : investmentQuote / entryQuote - 1;
    const expenses = booking.commission! + booking.taxAmount!;
    const grossGain =
      booking.type === "sell" ? booking.realizedGain!.grossGain! : null;

    return {
      id: booking.id,
      valueDate: booking.valueDate,
      bookingType: booking.type,
      numberOfLots: booking.numberOfLots,
      securityPrice: Math.abs(booking.securityPrice),
      priceChange,
      expenses,
      amount: Math.abs(booking.amount),
      grossGain,
      isExternalID: Boolean(booking.externalId),
      dataConsistencyStatus: booking.dataConsistencyStatus,
      comment: booking.comment,
      currency: booking.investment.currency,
      investmentId: booking.investment.id,
      investmentType: booking.investment.type as InvestmentTypeEnum, //TODO should be changed in the backend
      investmentTickerSymbol: booking.investment.tickerSymbol!,
    };
  });
};

const sortByDate = (origin: TableBookingData[]): TableBookingData[] => {
  return cloneDeep(origin).sort(
    ({ valueDate: datePrev }, { valueDate: dateNext }) =>
      compareDesc(parseJSON(datePrev), parseJSON(dateNext))
  );
};

const filterBookingsByRange = (
  bookings: TableBookingData[],
  start: Date | undefined,
  end: Date
): TableBookingData[] => {
  if (!start) return bookings;

  return bookings.filter(({ valueDate }) =>
    isWithinInterval(parseJSON(valueDate), { start, end })
  );
};

export const InvestmentProtocolContainer = ({
  bookingData,
  investmentQuote,
  currencySymbol,
  currentMarketValue,
  isLoading,
  investment,
}: InvestmentProtocolContainerProps) => {
  const [startDate] = useState(new Date());
  const [bookingsConverted, setBookingsConverted] = useState<
    TableBookingData[]
  >([]);
  const [bookingsWithPeriods, setBookingsWithPeriods] = useState<
    TableBookingData[]
  >([]);

  useEffect(() => {
    const convertedBookings = bookingDataConverter({
      bookingData,
      investmentQuote,
    });

    // Note: One-time sort for all array types
    const sortedByDate = sortByDate(convertedBookings);

    // Note: First render always uses all period
    const filteredBookingsByPeriods = filterBookingsByRange(
      sortedByDate,
      undefined,
      midday(new Date())
    );

    setBookingsConverted(sortedByDate);
    setBookingsWithPeriods(filteredBookingsByPeriods);
  }, [bookingData, investmentQuote]);

  const rangeData = useRangeSelectControl({
    firstDate: startDate,
  });

  useEffect(() => {
    setBookingsWithPeriods(
      sortByDate(
        filterBookingsByRange(
          bookingsConverted,
          rangeData.range.startDate,
          rangeData.range.endDate
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsConverted, rangeData.range.startDate, rangeData.range.endDate]);

  if (isLoading) {
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <Box sx={{ overflowX: "auto" }}>
      <BookingSingleInvestmentTableStyled
        currentMarketValue={currentMarketValue}
        bookings={bookingsWithPeriods}
        currencySymbol={currencySymbol}
        rangeData={rangeData}
        investment={investment}
      />
    </Box>
  );
};
