import { Account } from "@api";
import { IdType } from "./SelectContraAccount";
import { Option } from "./SelectContraAccountContainer";

export const buildOptions = (accounts: Account[], idType: IdType) =>
  accounts
    .filter(({ externalId }) => !!externalId)
    .reduce<Option[]>((acc, account) => {
      return [
        ...acc,
        {
          id: idType === "REMOTE" ? parseInt(account.externalId!) : account.id,
          name: account.name,
          iban: account.iban,
          bankName: account.bankName,
        },
      ];
    }, [])
    .sort((a, b) =>
      `${a.bankName}${a.name}`.localeCompare(`${b.bankName}${b.name}`)
    );
