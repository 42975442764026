import React from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useKey } from "react-use";
import { Box, Typography, useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton, Logo, RHFTextField } from "@components";
import { useLogin } from "@generated/user/hooks";
import { useToken } from "@api";
import { saveUserTokenToStorage } from "@api/auth/saveToStorage";

interface FormFields {
  email: string;
  password: string;
}

export const TempLogin = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data: token } = useToken();
  const { mutate: resetPassword, isLoading } = useLogin({
    onSuccess: async (response) => {
      if (token) {
        saveUserTokenToStorage(token, response, token.rememberMe);
      }
      navigate("/");
    },
    onError: () => {
      enqueueSnackbar(t("settingsPage.commonErrorMessage"), {
        autoHideDuration: 3000,
        variant: "error",
      });
    },
  });

  const schema = Yup.object().shape({
    email: Yup.string().required(t("form.errors.required")),
    password: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, watch } = useForm<FormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleConfirm = () => {
    const values = getValues();
    resetPassword({
      email: values.email,
      password: values.password,
    });
  };

  const isConfirmBtnDisabled = !watch("email") || !watch("password");

  useKey(
    "Enter",
    () => {
      if (!isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  return (
    <>
      <Logo
        variant="sidebar"
        width="255px"
        height="78px"
        position="absolute"
        top="5%"
        left="calc(50% - 127.5px)"
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
        sx={{
          backgroundColor: theme.palette.background.primary,
        }}
      >
        <Box
          padding={theme.spacing(8)}
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: theme.palette.background.default,
            maxWidth: "830px",
            width: "100%",
            borderRadius: "16px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
            marginBottom={theme.spacing(10)}
          >
            <Typography
              variant="h5"
              fontSize="26px"
              lineHeight="34px"
              textAlign="center"
            >
              Temporary Login
            </Typography>
            <RHFTextField
              name="email"
              label={"Email"}
              control={control}
              fullWidth
            />
            <RHFTextField
              name="password"
              label={"Password"}
              control={control}
              fullWidth
              type="password"
            />
          </Box>
          <LoadingButton
            data-testid="reset-password-action-button"
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            autoFocus
            onClick={handleSubmit(handleConfirm)}
            disabled={isConfirmBtnDisabled}
            isLoading={isLoading}
          >
            {"Sign in"}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
