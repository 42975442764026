import React from "react";
import { format } from "date-fns";
import styled from "@emotion/styled";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { Dividend } from "@api";
import { toCurrency, toRoundedNumberOfLots } from "@helpers";

interface Props {
  selectedDividends: Dividend[];
}

const TableRowStyled = styled(TableRow)`
  background: ${({ theme }) => theme.palette.common.white};
`;

const TableCellStyled = styled(TableCell)`
  vertical-align: top;
`;

export const SummaryTableBody = ({ selectedDividends }: Props) => {
  return (
    <TableBody>
      {selectedDividends.map(
        ({
          date,
          dividendPerShare,
          numberOfLots = 0,
          netAmount,
          grossAmount,
          taxAmount,
          currency = "EUR",
        }) => (
          <TableRowStyled hover key={date}>
            <TableCellStyled>
              <Typography variant="body2">
                {format(new Date(date!), "dd.MM.yyyy")}
              </Typography>
            </TableCellStyled>
            <TableCellStyled>
              <Typography variant="body2">
                {toCurrency(netAmount, currency)}
              </Typography>
            </TableCellStyled>
            <TableCellStyled>
              <Typography variant="body2">
                {toCurrency(taxAmount, currency)}
              </Typography>
            </TableCellStyled>
            <TableCellStyled align="right">
              <Typography variant="body2">
                {toCurrency(grossAmount, currency)}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {toCurrency(dividendPerShare, currency)} x{" "}
                {toRoundedNumberOfLots(numberOfLots)}
              </Typography>
            </TableCellStyled>
          </TableRowStyled>
        )
      )}
    </TableBody>
  );
};
