import { useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { useMemo } from "react";
import {
  listAndSearchAllBookings,
  publicListAndSearchAllBookings,
} from "@generated/apiv1/hooks";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import {
  Booking,
  BookingsApiListAndSearchAllBookingsRequest,
  BookingsApiPublicListAndSearchAllBookingsRequest,
  PageableBookingList,
} from "@generated/apiv1";
import { useAuthInfiniteQuery } from "@api";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";

export const useInfinitePublicBookings = (
  params: BookingsApiPublicListAndSearchAllBookingsRequest,
  options?: UseInfiniteQueryOptions<
    PageableBookingList,
    AxiosApiError,
    PageableBookingList
  >
) =>
  useInfiniteQuery<PageableBookingList, AxiosApiError, PageableBookingList>(
    [CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS, params],
    ({ pageParam }) =>
      publicListAndSearchAllBookings(undefined, {
        ...params,
        page: pageParam || 1,
      }),
    options
  );

export const useInfinitePrivateBookings = (
  params: BookingsApiListAndSearchAllBookingsRequest,
  options?: UseInfiniteQueryOptions<
    PageableBookingList,
    AxiosApiError,
    PageableBookingList
  >
) =>
  useAuthInfiniteQuery<PageableBookingList, AxiosApiError, PageableBookingList>(
    [CACHE_KEYS.BOOKINGS_LIST_AND_SEARCH_ALL_BOOKINGS, params],
    ({ token, pageParam }) =>
      listAndSearchAllBookings(token, { ...params, page: pageParam || 1 }),
    options
  );

export const usePublicOrPrivateBookings = (
  params: BookingsApiListAndSearchAllBookingsRequest = {},
  options: UseInfiniteQueryOptions<
    PageableBookingList,
    AxiosApiError,
    PageableBookingList
  > = {}
) => {
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();

  const { data: bookings, ...rest } = useInfinitePrivateBookings(params, {
    ...options,
    enabled: !isSharedPortfolio && (options.enabled ?? true),
    getNextPageParam: (lastPage) => (lastPage.paging?.page || 0) + 1,
  });
  const { data: publicBookings, ...publicRest } = useInfinitePublicBookings(
    {
      publicId: publicId!,
      ...params,
    },
    {
      ...options,
      enabled: isSharedPortfolio && (options.enabled ?? true),
      getNextPageParam: (lastPage) => (lastPage.paging?.page || 0) + 1,
    }
  );

  const aggregatedBookings = useMemo(() => {
    return (isSharedPortfolio ? publicBookings : bookings)?.pages.reduce(
      (acc, page) => {
        return [...acc, ...(page.bookings || [])];
      },
      [] as Booking[]
    );
  }, [bookings, publicBookings, isSharedPortfolio]);

  return {
    bookings: aggregatedBookings,
    ...(isSharedPortfolio ? publicRest : rest),
  };
};
