import { UseQueryOptions } from "react-query";
import {
  TppCredentialsApiTppCredentialsIdRequest,
  TppCredentials,
} from "@generated/apiv1";

import { buildTppCredentialsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const tppCredentialsId = async (
  token: string | undefined,
  params: TppCredentialsApiTppCredentialsIdRequest
) => {
  const apiInstance = buildTppCredentialsApi(token);
  const { data } = await apiInstance.tppCredentialsId(params);
  return data;
};

export const useTppCredentialsId = (
  params: TppCredentialsApiTppCredentialsIdRequest,
  options?: UseQueryOptions<TppCredentials, AxiosApiError, TppCredentials>
) => {
  return useAuthQuery(
    [CACHE_KEYS.TPPCREDENTIALS_TPP_CREDENTIALS_ID, params],
    ({ token }) => tppCredentialsId(token, params),
    options
  );
};
