import { UseQueryOptions } from "react-query";
import {
  CashFlowAnalyticsApiPredictCashFlowsRequest,
  IncomeAndSpendingStatistics,
} from "@generated/apiv1";

import { buildCashFlowAnalyticsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const predictCashFlows = async (
  token: string | undefined,
  params?: CashFlowAnalyticsApiPredictCashFlowsRequest
) => {
  const apiInstance = buildCashFlowAnalyticsApi(token);
  const { data } = await apiInstance.predictCashFlows(params);
  return data;
};

export const usePredictCashFlows = (
  params?: CashFlowAnalyticsApiPredictCashFlowsRequest,
  options?: UseQueryOptions<
    IncomeAndSpendingStatistics,
    AxiosApiError,
    IncomeAndSpendingStatistics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.CASHFLOWANALYTICS_PREDICT_CASH_FLOWS, params],
    ({ token }) => predictCashFlows(token, params),
    options
  );
};
