import React, { useState, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useChartColors, useRangeSelectControl } from "@hooks";
import {
  ComparisonValue,
  InvestmentTableAddQuoteModal,
  Tab,
  Tabs,
  GainLossPercents,
  Switcher,
} from "@components";
import { SecuritiesChartType } from "@components/Chart/SecuritiesChart/types";
import { RangeSelector } from "@components/common/ranges/RangeSelector";
import {
  ChartToolbar,
  ChartToolbarItem,
  SecuritiesChartSelectedType,
} from "@features/investments/components/ChartToolbar";
import { SecuritiesChart } from "@components/Chart/SecuritiesChart/SecuritiesChart";
import { useInvestmentsChartData } from "@features/investments/hooks/useInvestmentsChartData";
import { useMyself } from "@api";
import { AssetTypeBreakdown } from "@features/investments/components/Assets/AssetTypeBreakdown";
import { AssetCard } from "@features/investments/components/Assets/AssetTypeCard";
import { TabActions } from "@features/investments/components/TabActions";
import { ExportButton } from "@features/investments/components/InvestmentsTable/ExportButton";
import { InvestmentsTable } from "@features/investments/components/InvestmentsTable/InvestmentsTable";
import { BookingsTableContainer } from "@features/investments/components/BookingsTable/BookingsTableContainer";
import { Title } from "@features/investments/components/Title";
import { ChartTypeToggler } from "@features/investments/components/ChartTypeToggler";
import { SelectComparison } from "@components/Chart/BaseChartTools/SelectComparison";

import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { usePublicOrPrivateInvestments } from "@features/sharedPortfolio/hooks/usePublicOrPrivateInvestments";
import { investmentTypeToAssetCard } from "@features/investments/components/helpers/assetCards";
import { InvestmentTypeEnum } from "@generated/apiv1";

const GainLossPercentsStyled = styled(GainLossPercents)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

interface DepotsStatisticProps {
  accountIds: number[];
}

export const DepotsDashboard = (props: DepotsStatisticProps) => {
  const { accountIds } = props;
  const { isSharedPortfolio, isAnonymized } = useSharedPortfolioContext();

  const theme = useTheme();
  const { t } = useTranslation("");
  const chartColors = useChartColors("securitiesChart");

  const isDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isDown950 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(950)
  );

  const { data: myself } = useMyself();
  const [searchParams, setSearchParams] = useSearchParams();

  const [chartType, setChartType] = useState<SecuritiesChartType>(
    isAnonymized ? "performance" : "valuation"
  );
  const [showHistoric, setShowHistoric] = useState(false);
  const [showAggregation, setShowAggregation] = useState(true);
  const [comparedTicker, setComparedTicker] = useState<
    ComparisonValue | undefined
  >();
  const [selectedTypes, setSelectedTypes] = useState<
    SecuritiesChartSelectedType[]
  >(["all"]);
  const [selectedAssetCards, setSelectedAssetCards] = useState<AssetCard[]>([]);

  const rangeData = useRangeSelectControl({
    firstDate: new Date(),
    id: "depots",
  });

  const { investments, isFetching: isInvestmentsLoading } =
    usePublicOrPrivateInvestments(
      {
        accountId: accountIds,
        includeHistoric: showHistoric,
        aggregate: showAggregation,
      },
      {
        enabled: accountIds?.length > 0,
        refetchOnWindowFocus: false,
      }
    );

  const {
    data: chartData,
    comparedTickerData,
    statistic,
    typesWithData,
    performanceByAssetClass,
    isLoading: isSecuritiesChartDataLoading,
  } = useInvestmentsChartData(
    accountIds,
    rangeData.range.startDate,
    rangeData.range.endDate,
    chartType,
    comparedTicker
  );

  const onTabChange = (key: string) => setSearchParams({ tab: key });
  const onShowHistoricChange = (event: ChangeEvent<HTMLInputElement>) =>
    setShowHistoric(event.target.checked);
  const onShowAggregationChange = (event: ChangeEvent<HTMLInputElement>) =>
    setShowAggregation(event.target.checked);

  const defaultTabKey = searchParams.get("tab");

  const chartControl = (["all"] as SecuritiesChartSelectedType[])
    .concat(typesWithData)
    .map<ChartToolbarItem>((i) => ({
      text: t(`investments.securitiesChart.${i}`),
      value: i,
      clickable: selectedTypes.includes(i) ? selectedTypes.length >= 2 : true,
    }));

  const onChartControlSelect = (value: SecuritiesChartSelectedType) => {
    const index = selectedTypes.indexOf(value);
    if (index < 0) return setSelectedTypes([...selectedTypes, value]);
    return setSelectedTypes([...selectedTypes.filter((i) => i !== value)]);
  };

  const hasSelectedComparedValue = Boolean(comparedTicker);

  const handleChartType = (value: SecuritiesChartType) => {
    if (!value || value === chartType) return;
    setChartType(value);
  };

  const onAssetCardClick = (value: AssetCard) => {
    const index = selectedAssetCards.indexOf(value);
    if (index < 0) return setSelectedAssetCards([...selectedAssetCards, value]);
    return setSelectedAssetCards([
      ...selectedAssetCards.filter((i) => i !== value),
    ]);
  };

  const selectedChartData = chartData.filter((securityChart) =>
    selectedTypes.includes(
      securityChart.securityName as SecuritiesChartSelectedType
    )
  );
  const fullChartData = comparedTickerData
    ? [...selectedChartData, comparedTickerData]
    : selectedChartData;
  const preparedChartData = fullChartData.map((securityItem) => {
    return {
      ...securityItem,
      color: chartColors[securityItem.securityName] || chartColors.searched,
    };
  });

  const handleComparisonChange = (value?: ComparisonValue) => {
    setComparedTicker(value);
    if (value) {
      setChartType("performance");
    }
  };

  const isComparisonDisabled = !myself?.isUserPro;

  return (
    <>
      <Box
        my={4}
        display="flex"
        flexDirection={isDownMd ? "column" : "row"}
        justifyContent="space-between"
        alignContent={isDownMd ? "unset" : "center"}
      >
        <Title
          changeToday={
            (chartType === "valuation" || isAnonymized) && (
              <GainLossPercentsStyled value={statistic.performance ?? 0} />
            )
          }
          chartType={isAnonymized ? "valuation" : chartType}
          statistic={statistic}
        />

        <Box marginTop={isDownMd ? theme.spacing(6) : 0}>
          <RangeSelector
            range={rangeData.range}
            intervalType={rangeData.intervalType}
            intervalTypes={[
              "oneWeek",
              "oneMonth",
              "YTD",
              "oneYear",
              "threeYears",
              "fiveYears",
              "sinceBeginning",
            ]}
            onSelect={rangeData.selectInterval}
          />
        </Box>
      </Box>

      <ChartToolbar
        allowedTypes={chartControl}
        selected={selectedTypes}
        onChange={(type) => onChartControlSelect(type)}
      />

      <ChartTypeToggler
        chartType={chartType}
        handleChartType={handleChartType}
        hasSelectedComparedValue={hasSelectedComparedValue}
      />

      <SecuritiesChart
        chartData={preparedChartData}
        chartType={chartType}
        intervalType={rangeData.intervalType}
        isLoading={accountIds?.length ? isSecuritiesChartDataLoading : false}
        isComparison={Boolean(comparedTicker)}
        displayArea
      />

      <Box my={6} width={isDownMd ? "100%" : 334}>
        <SelectComparison
          value={comparedTicker}
          onChange={handleComparisonChange}
          disabled={isComparisonDisabled}
        />
      </Box>

      <Box mb={isDownMd ? 0 : 6} mt={isDownMd ? 4 : 12}>
        <AssetTypeBreakdown
          performanceByAssetClass={performanceByAssetClass}
          onCardClick={(type) => onAssetCardClick(type)}
          selectedCards={selectedAssetCards}
          interval={rangeData.intervalType}
          assetsWithData={typesWithData}
        />
      </Box>

      <Typography variant="h5" mt={isDownMd ? 8 : 12} mb={6}>
        {t("investments.overview")}
      </Typography>

      <Tabs
        style={{
          marginBottom: theme.spacing(0.5),
        }}
        onChange={onTabChange}
        defaultKey={defaultTabKey}
        buttonElement={
          <TabActions
            onShowHistoricChange={onShowHistoricChange}
            onShowAggregationChange={onShowAggregationChange}
            accountIds={accountIds}
            minDate={rangeData?.range?.startDate?.toISOString()}
            maxDate={rangeData?.range?.endDate.toISOString()}
            showHistoric={showHistoric}
            showAggregation={showAggregation}
          />
        }
      >
        <Tab
          label={t("components.depotsStatistic.tabs.investments")}
          key="investments"
        >
          {isDown950 && (
            <Box
              display="flex"
              alignContent="center"
              gap={6}
              my={4}
              justifyContent="space-between"
            >
              <Box alignSelf="center" ml={3}>
                <Switcher
                  checked={showAggregation}
                  onChange={onShowAggregationChange}
                  label="components.depotsStatistic.showAggregationSwitch"
                  switchName="show-aggregation-switch"
                />
                <Switcher
                  checked={showHistoric}
                  onChange={onShowHistoricChange}
                  label="components.depotsStatistic.showHistoricSwitch"
                  switchName="show-historic-switch"
                />
              </Box>
              {!isSharedPortfolio && (
                <ExportButton
                  params={{
                    accountId: accountIds,
                    includeHistoric: showHistoric,
                    minDate: rangeData?.range?.startDate?.toISOString(),
                    maxDate: rangeData?.range?.endDate.toISOString(),
                  }}
                />
              )}
            </Box>
          )}

          <InvestmentsTable
            selectedAssetCards={selectedAssetCards}
            investments={investments}
            isLoading={isInvestmentsLoading}
            AddQuoteModalContent={<InvestmentTableAddQuoteModal />}
          />
        </Tab>
        <Tab
          label={t("components.depotsStatistic.tabs.bookings")}
          key="bookings"
        >
          {isDown950 && !isSharedPortfolio && (
            <Box
              display="flex"
              flexDirection="column"
              alignContent="center"
              gap={6}
              my={4}
              justifyContent="space-between"
            >
              <ExportButton
                params={{
                  accountId: accountIds,
                  includeHistoric: showHistoric,
                  minDate: rangeData?.range?.startDate?.toISOString(),
                  maxDate: rangeData?.range?.endDate.toISOString(),
                }}
              />
            </Box>
          )}
          <Box mt={6}>
            <BookingsTableContainer
              accountIds={accountIds ?? []}
              selectedCards={selectedAssetCards}
              onInvestmentTypeChange={(types: InvestmentTypeEnum[]) => {
                setSelectedAssetCards(investmentTypeToAssetCard(types));
              }}
            />
          </Box>
        </Tab>
      </Tabs>
    </>
  );
};
