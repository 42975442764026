import React, { useCallback, useEffect } from "react";
import { styled, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMyself } from "@api";
import { Tabs, Tab, Tooltip } from "@components";
import { BlurForNonPro } from "@components/BlurForNonPro";
import {
  ContainerStyledCard,
  StyledCard,
} from "@components/common/layouts/LayoutWithTabs";
import { useSharedPortfolioContext } from "@providers/SharedPortfolioProvider";
import { ReactComponent as InfoIcon } from "@icons/info.svg";
import { IndustryClassification } from "./IndustryClassification/IndustryClassification";
import { InvestmentTypeClassification } from "./InvestmentTypeClassification/InvestmentTypeClassification";
import { RegionClassification } from "./RegionClassification/RegionClassification";
import { RiskClassification } from "./RiskClassification/RiskClassification";
import { TagClassification } from "./TagClassification/TagClassification";
import { YieldClassification } from "./YieldClassification/YieldClassification";
import { CurrencyClassification } from "./CurrencyClassification";

// @ts-ignore
const StyledTabs = styled(Tabs)(() => ({
  " & .Mui-disabled": {
    pointerEvents: "initial !important",
    padding: 0,
  },
  " & .MuiTabs-indicator": {
    display: "none",
  },
}));

const Label = ({ title }: { title: string }) => {
  return <BlurForNonPro isLockIconShown>{title}</BlurForNonPro>;
};

export const Classifications = ({ accountIds }: { accountIds: number[] }) => {
  const { t } = useTranslation();
  const { data: myself, isFetched } = useMyself();
  const { isSharedPortfolio, publicId } = useSharedPortfolioContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") ?? "investmentType";
  const handleTabChange = useCallback(
    (tab: string) => {
      if (!myself?.isUserPro && (tab === "yield" || tab === "tag")) return;
      navigate({
        pathname: isSharedPortfolio
          ? `/portfolios/${publicId}/classifications`
          : `/classifications`,
        search: `?tab=${tab}`,
      });
    },
    [navigate, myself?.isUserPro, isSharedPortfolio, publicId]
  );

  useEffect(() => {
    if ((tab === "yield" || tab === "tag") && isFetched && !myself?.isUserPro) {
      navigate({
        pathname: isSharedPortfolio
          ? `/portfolios/${publicId}/classifications`
          : `/classifications`,
        search: `?tab=investmentType`,
      });
    }
  }, [tab, myself, navigate, isFetched, isSharedPortfolio, publicId]);
  return (
    <ContainerStyledCard>
      <StyledCard>
        <StyledTabs
          onChange={handleTabChange}
          defaultKey={tab}
          variant="scrollable"
          fakeDisabled={!myself?.isUserPro ? ["yield", "tag"] : []}
        >
          <Tab
            label={t("analysis.classifications.overviewTabs.investmentType")}
            key="investmentType"
          >
            <InvestmentTypeClassification accountIds={accountIds} />
          </Tab>
          <Tab
            label={t("analysis.classifications.overviewTabs.region")}
            key="region"
          >
            <RegionClassification accountIds={accountIds} />
          </Tab>
          <Tab
            label={t("analysis.classifications.overviewTabs.industry")}
            key="industry"
          >
            <IndustryClassification accountIds={accountIds} />
          </Tab>

          <Tab
            label={
              <Box display="flex" alignItems="center">
                {t("analysis.classifications.overviewTabs.risk")}
                <Tooltip
                  enterTouchDelay={0}
                  text={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      whiteSpace="pre-line"
                      fontWeight={400}
                    >
                      {t("analysis.classifications.riskTooltipText")}
                    </Typography>
                  }
                >
                  <InfoIcon style={{ marginLeft: 8 }} />
                </Tooltip>
              </Box>
            }
            key="risk"
          >
            <RiskClassification accountIds={accountIds} />
          </Tab>
          <Tab
            label={t("analysis.classifications.overviewTabs.currency")}
            key="currency"
          >
            <CurrencyClassification accountIds={accountIds} />
          </Tab>
          <Tab
            label={
              <Box display="flex" alignItems="center">
                <Label
                  title={t("analysis.classifications.overviewTabs.yield")}
                />
                <Tooltip
                  enterTouchDelay={0}
                  text={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      whiteSpace="pre-line"
                      fontWeight={400}
                    >
                      {t("analysis.classifications.yieldTooltipText")}
                    </Typography>
                  }
                >
                  <InfoIcon style={{ marginLeft: 8 }} />
                </Tooltip>
              </Box>
            }
            key="yield"
          >
            <YieldClassification accountIds={accountIds} />
          </Tab>
          <Tab
            label={
              <Label title={t("analysis.classifications.overviewTabs.tag")} />
            }
            key="tag"
          >
            <TagClassification accountIds={accountIds} />
          </Tab>
        </StyledTabs>
      </StyledCard>
    </ContainerStyledCard>
  );
};
