/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The list of banks supported by the PDF import
 * @export
 * @interface Bank
 */
export interface Bank {
    /**
     * The bank name
     * @type {string}
     * @memberof Bank
     */
    'name'?: string;
    /**
     * The bank group. This will equal the BIC if the bank has a single BIC; or a group definitionif the bank has more than one BIC
     * @type {string}
     * @memberof Bank
     */
    'group'?: string;
    /**
     * The bank capabilities. Possible values are: ORDER_STATEMENT: The import supports order statements such as buys, sells, book ins, book outs EARNINGS_STATEMENT: The import supports earnings statements which are typically from dividends ORDER_AND_EARNINGS_SUMMARY: The import supports summary statements for orders and earnings BANK_STATEMENT: The import supports bank statements such as monthly cash account transaction summaries TAX_STATEMENT: The import supports tax statements such as e.g. \"Vorabpauschale\" in Germany 
     * @type {Array<string>}
     * @memberof Bank
     */
    'capabilities'?: BankCapabilitiesEnum;
}

export const BankCapabilitiesEnum = {
    ORDER_STATEMENT: 'ORDER_STATEMENT',
    EARNINGS_STATEMENT: 'EARNINGS_STATEMENT',
    ORDER_AND_EARNINGS_SUMMARY: 'ORDER_AND_EARNINGS_SUMMARY',
    BANK_STATEMENT: 'BANK_STATEMENT',
    TAX_STATEMENT: 'TAX_STATEMENT'
} as const;

export type BankCapabilitiesEnum = typeof BankCapabilitiesEnum[keyof typeof BankCapabilitiesEnum];


