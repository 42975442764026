import { UseQueryOptions } from "react-query";
import { useAuthQuery } from "@api/auth";

import { GET_BY_QUOTE_PROVIDER_AND_TICKER } from "@api/cacheKeys";

import { buildSymbolsApiWithToken } from "@generated/apiv1/apiBuilder";
import {
  Symbol,
  SymbolsApiGetByQuoteProviderAndTickerRequest,
} from "@generated/apiv1";

export const useGetByQuoteProviderAndTicker = (
  params: SymbolsApiGetByQuoteProviderAndTickerRequest,
  options: UseQueryOptions<Symbol, AxiosApiError> = {
    enabled: true,
  }
) => {
  return useAuthQuery(
    [GET_BY_QUOTE_PROVIDER_AND_TICKER],
    async () => {
      const api = await buildSymbolsApiWithToken();
      const response = await api.getByQuoteProviderAndTicker(params);
      return response.data;
    },
    options
  );
};
