import React from "react";
import { useRevenueCat, useStripe } from "@generated/user/hooks";
import { LoadingPlaceHolder } from "@components";
import { SettingsErrorBlock } from "../components/SettingsErrorBlock";
import { SubscriptionType } from "../types";
import { SubscriptionComparison } from "./components/SubscriptionComparison";
import SubscriptionView from "./components/SubscriptionView";

interface Props {
  showInOneColumn: boolean;
  isLoadingUserData: boolean;
  isPlusUser: boolean;
}

function SettingsSubscriptionsPage({
  showInOneColumn,
  isLoadingUserData,
  isPlusUser,
}: Props) {
  const {
    data: revenueCatData,
    isLoading: isLoadingRevenueCatData,
    isError: isRevenueCatError,
  } = useRevenueCat({
    refetchOnWindowFocus: false,
    enabled: !isLoadingUserData && isPlusUser,
  });

  const {
    data: stripeData,
    isLoading: isLoadingStripeData,
    isError: isStripeError,
  } = useStripe({
    refetchOnWindowFocus: false,
    enabled:
      !isLoadingUserData &&
      (!isPlusUser ||
        (!isLoadingRevenueCatData && !revenueCatData?.subscriptions?.length)),
  });

  const userType: SubscriptionType = revenueCatData?.subscriptions?.length
    ? "revenueCat"
    : stripeData?.subscriptions?.length
    ? "stripe"
    : "free";

  if (isLoadingUserData || isLoadingStripeData || isLoadingRevenueCatData) {
    return <LoadingPlaceHolder />;
  }

  if (isStripeError || isRevenueCatError) {
    return <SettingsErrorBlock />;
  }

  return isPlusUser ? (
    <SubscriptionView
      stripeData={stripeData}
      revenueCatData={revenueCatData}
      subscriptionType={userType}
    />
  ) : (
    <SubscriptionComparison
      stripeData={stripeData}
      showInOneColumn={showInOneColumn}
    />
  );
}

export default SettingsSubscriptionsPage;
