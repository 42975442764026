import React from "react";
import { useTranslation } from "react-i18next";
import { LegendLayout } from "@features/analysis/components/Legend";
import { DonutChart } from "@components/Chart/DonutChart";
import { useChartColors } from "@hooks";
import { CardLayout } from "../../components/CardLayout";
import { useRiskBreakdowns } from "./useRiskBreakdowns";

export interface Props {
  accountIds?: number[];
}

export const RiskClassification = ({ accountIds }: Props) => {
  const { t } = useTranslation();
  const {
    data: chartData,
    isLoading,
    isFetched,
  } = useRiskBreakdowns(accountIds);
  const colors = useChartColors("classificationChart");

  return (
    <CardLayout
      isLoading={Boolean(accountIds?.length && isLoading)}
      noData={!accountIds?.length || (isFetched && !chartData.length)}
      chartData={chartData}
      chartTitle={t("analysis.classifications.overviewTabs.risk")}
      chart={<DonutChart chartColors={colors} />}
      legend={<LegendLayout showInvestmentAmount />}
    />
  );
};
