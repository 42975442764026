import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { WatchlistEntry } from "@generated/apiv1";
import { LoadingPlaceHolder, Table, TableContainer } from "@components";
import {
  getStoredSortParams,
  setStoredSortParams,
} from "@helpers/localStorage";
import { useTableSortWithData } from "@hooks";
import { WatchlistItemsTableHead } from "./WatchlistItemsTableHead";
import { WatchlistItemsTableBody } from "./WatchlistItemsTableBody";

const WATCHLIST_SINGLE_ITEMS_TABLE_KEY = "watchlistSingleItems";

interface WatchlistItemsTableProps {
  watchlistId: string;
  entries: (WatchlistEntry & { color?: string })[];
  isTableDataLoading: boolean;
}

export const WatchlistItemsTable = (props: WatchlistItemsTableProps) => {
  const { entries, watchlistId, isTableDataLoading } = props;

  const {
    data: sortedItems,
    sortField,
    sortDirection,
    sort,
  } = useTableSortWithData(entries);

  const handleSort = (name: string) => {
    sort(name);
  };

  useEffect(() => {
    if (!sortDirection || !sortField) return;
    setStoredSortParams(
      sortField,
      sortDirection,
      WATCHLIST_SINGLE_ITEMS_TABLE_KEY
    );
  }, [sortDirection, sortField]);

  useEffect(() => {
    const item = getStoredSortParams(WATCHLIST_SINGLE_ITEMS_TABLE_KEY);
    if (!item) return;
    const { sortDirection, sortField } = item;
    sort(sortField, sortDirection);
    // eslint-disable-next-line
  }, []);

  if (isTableDataLoading) {
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <WatchlistItemsTableHead
            onSort={handleSort}
            sortField={sortField || ""}
            sortDirection={sortDirection || "NONE"}
          />
          <WatchlistItemsTableBody
            watchlistId={watchlistId}
            data={sortedItems}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
