import { InvokeCallback, Sender } from "xstate";
import { CreateMultipleBookingParams } from "@api";
import { validateBookingsWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";
import { buildCreateOrUpdateBookingParams } from "../../utils/utils";

export const validateSingleBooking =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "DIVIDEND_FILLED") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    if (!context.bookingId) {
      throw new Error(`Invalid booking id: ${context.bookingId}`);
    }

    if (!context.investment?.id) {
      throw new Error(`Invalid investment id: ${context.investment?.id}`);
    }

    const params = buildCreateOrUpdateBookingParams(
      context.investment.id,
      context.dividendValues,
      context.investment.account.id,
      context.bookingId
    );

    validate(params, callback);
  };

const validate = async (
  params: CreateMultipleBookingParams,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const { hasValidationErrors, transactionValidationErrors } =
      await validateBookingsWithToken(params);

    if (!hasValidationErrors) {
      callback({
        type: "DIVIDENDS_VALIDATED",
        validatedMultipleBookingParams: params,
      });

      return;
    }

    callback({ type: "BACK", validationErrors: transactionValidationErrors });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
