import React, { useEffect } from "react";
import * as Yup from "yup";
import { Box, FormLabel, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useKey } from "react-use";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useQueryClient } from "react-query";
import { RHFTextField } from "@components";
import { ReactComponent as EmailSentIcon } from "@icons/myicons/email-sent.svg";
import { SettingsModalWrapper } from "@features/settingsPage/components/SettingsModalWrapper";
import { CACHE_KEYS } from "@generated/user/cacheKeys";
import { useChangeEmail } from "@generated/user/hooks";
import { SettingsErrorBanner } from "@features/settingsPage/components/SettingsErrorBanner";

const modalData = {
  title: "settingsPage.profile.modal.email.title",
  confirmButton: "settingsPage.profile.confirm",
  cancelButton: "settingsPage.profile.cancelButton",
};

interface FormFields {
  email: string;
  password: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
}

export const SettingsChangeEmailModal = ({
  isOpen,
  onClose,
  userEmail,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const {
    mutate: changeEmail,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useChangeEmail({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.USERS_MYSELF],
      });
    },
  });

  const schema = Yup.object().shape({
    email: Yup.string().required(t("form.errors.required")),
    password: Yup.string().required(t("form.errors.required")),
  });

  const { handleSubmit, control, getValues, setValue, watch } =
    useForm<FormFields>({
      resolver: yupResolver(schema),
      defaultValues: {
        email: "",
        password: "",
      },
    });

  const handleConfirm = () => {
    const values = getValues();
    changeEmail({
      newEmail: values.email,
      password: values.password,
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, 500);
  };

  const isConfirmBtnDisabled = !watch("email") || !watch("password");

  useKey(
    "Enter",
    () => {
      if (!isSuccess && !isConfirmBtnDisabled) {
        handleSubmit(handleConfirm)();
      }
    },
    {},
    [isSuccess, isConfirmBtnDisabled, handleSubmit, handleConfirm]
  );

  useEffect(() => {
    setValue("email", "");
    setValue("password", "");
  }, [isOpen, setValue]);

  return (
    <SettingsModalWrapper
      {...modalData}
      successTitle={t("settingsPage.profile.modal.email.sentTitle")}
      successSubtitle={t("settingsPage.profile.modal.email.sentSubtitle")}
      successButton={t("settingsPage.profile.modal.email.sentButton")}
      successIcon={<EmailSentIcon />}
      showSuccess={isSuccess}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleSubmit(handleConfirm)}
      isConfirmBtnDisabled={isConfirmBtnDisabled}
      isConfirmButtonLoading={isLoading}
    >
      {isError && (
        <SettingsErrorBanner
          errorMessage={t("settingsPage.commonErrorMessage")}
        />
      )}
      <Box display="flex" flexDirection="column" gap={theme.spacing(6)}>
        <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
          <FormLabel>
            <Typography variant="body2" component="span">
              {t("settingsPage.profile.modal.email.currentEmailLabel")}
            </Typography>
          </FormLabel>
          <Typography
            variant="body2"
            fontSize="20px"
            fontWeight={700}
            color="textPrimary"
            component="span"
          >
            {userEmail}
          </Typography>
        </Box>
        <RHFTextField
          name="email"
          label={t("settingsPage.profile.modal.email.newEmailLabel")}
          control={control}
          fullWidth
          type="text"
          autoComplete="one-time-code"
        />
        <RHFTextField
          name="password"
          label={t("settingsPage.profile.modal.email.passwordLabel")}
          control={control}
          fullWidth
          type="password"
          autoComplete="one-time-code"
        />
      </Box>
    </SettingsModalWrapper>
  );
};
