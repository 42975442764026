import React from "react";
import { Line as RLine } from "recharts";

interface Props {
  strokeColor: string;
  dataKey: string;
}

export const Line = ({ strokeColor, dataKey }: Props) => (
  <RLine
    key={`line-${dataKey}`}
    isAnimationActive={false}
    type="monotone"
    activeDot={{ strokeWidth: 2, r: 5 }}
    strokeWidth={1}
    dataKey={dataKey}
    stroke={strokeColor}
    dot={false}
    legendType="none"
    yAxisId="1"
  />
);
