export const GET_ACCOUNTS = "get-accounts";
export const GET_ACCOUNTS_CATEGORIZE = "get-accounts-categorize";
export const GET_BANK_CONNECTIONS = "get-bank-connections";
export const GET_BANK_CONNECTION = "get-bank-connection";
export const GET_DISCOUNTS_KEY = "get-discounts";
export const GET_REMOTE_ACCOUNTS = "get-remote-accounts";
export const MY_DEPOTS = "my-depots";
export const MY_INVOICES = "my-invoices";
export const GET_SELECTED_GROUP = "get-selected-group";
export const GET_SECURITY_COMISSIONS = "GET_SECURITY_COMISSIONS";
export const GET_REGIONS_KEY = "get-regions";
export const GET_INDUSTRIES_KEY = "get-industries";
export const GET_INVESTMENT_KEY = "get-investment";
export const GET_INVESTMENTS = "get-investments";
export const GET_INVESTMENTS_GAINERS_AND_LOSERS =
  "get-investments-gainers-and-losers";
export const GET_TAGS_KEY = "get-tags";
export const GET_PERFORMANCE_BY_DEPOTS = "get-performance-by-depots";
export const GET_RISK_YIELD_ESTIMATES_KEY = "get-risk-yield-estimates";
export const LOAD_FUND_BY_ISIN = "load_fund_by_isin";
export const VALIDATE_BOOKINGS_KEY = "validate-bookings";
export const GET_AND_VALIDATE_BOOKINGS_KEY = "get-and-validate-bookings";
export const GET_CURRENCIES_KEY = "get-currencies";
export const GET_HISTORIC_QUOTES = "get-historic-quotes";
export const GET_HISTORIC_QUOTES_LOOKUP = "get-historic-quotes-lookup";
export const GET_HISTORIC_QUOTES_INTRADAY = "get-historic-quotes-intraday";
export const GET_EXCHANGE_RATE = "get-exchange-rate";
export const GET_SUBSCRIPTIONS = "get-subscriptions";
export const GET_ACCOUNT_HOLDERS = "get-account-holders";
export const GET_REALIZED_GAINS = "get-realized-gains";
export const GET_WATCHLISTS = "get-watchlists";
export const GET_WATCHLISTS_ENTRIES = "get-watchlists-entries";
export const GET_WATCHLISTS_ENTRY = "get-watchlists-entry";
export const GET_SINGLE_WATCHLIST = "get-single-watchlist";
export const GET_SYMBOL = "get-symbol";
export const GET_APPLICATION_PROPERTIES = "get-application-properties";
export const GET_SPLIT_KEY = "get-split";
export const GET_SPLITS_KEY = "get-splits";
export const GET_DIVIDENDS_MASTER_KEY = "get-dividends-master";
export const GET_DIVIDEND_MASTER_KEY = "get-dividend-master";
export const GET_FUNDS_KEY = "get-funds";
export const GET_QUESTIONS_KEY = "get-questions";
export const GET_PRODUCTS_KEY = "get-products";
export const GET_RECOMMEND_PRODUCTS_KEY = "get-recommend-products";
export const GET_PRODUCT_KEY = "get-product";
export const GET_PRODUCTS_IN_WATCHLIST_KEY = "get-products-in-watchlist";
export const GET_PORTFOLIO_KEY = "get-portfolio";
export const GET_PORTFOLIO_METRICS_KEY = "get-portfolio-metrics";
export const GET_USER_PROFILE_KEY = "get-user-profile";
export const GET_USER_PROFILE_SUBSCRIPTION_KEY =
  "get-user-profile-subscription";
export const GET_PORTFOLIO_BACKTRACKING_KEY = "get-portfolio-backtracking";
export const GET_RISK_YIELD_METRICS_KEY = "get-risk-yield-metrics";
export const GET_FUND_COMMISITIONS_KEY = "get-fund-commissions";

export const ACCOUNT = "account";

export const ACCOUNT_PERFORMANCE_BY_ASSET_TYPES =
  "account-performance-by-asset-types";
export const ACCOUNT_PERFORMANCE_BY_ACCOUNT_IDS =
  "account-performance-by-account-ids";

export const ACCOUNT_BALANCES = "account-balances";
export const TRANSACTIONS = "transactions";
export const CATEGORIES_V1 = "categories-v1";
export const CASHFLOW_ANALYTICS = "cashflow-analytics";
export const CASHFLOW_STATISTICS = "cashflow-statistics";
export const HISTORIC_QUOTES = "historic-quotes";
export const REALIZED_GAINS = "realized-gains";
export const INVESTMENT_PERFORMANCE = "investment-performance";
export const DIVIDENDS_PER_YEAR = "dividends-per-year";
export const DIVIDENDS = "dividends";
export const HISTORIC_QUOTES_PERCENT = "historic-quotes-percent";
export const BOOKINGS = "get-bookings";
export const GET_NEWS_FEED = "get-news-feed";
export const GET_NEWS_FEED_RENTABLO_BLOG = "get-news-feed-rentablo-blog";
export const CLASSIFICATION_BY_CURRENCY = "classification-by-currency";

export const COMPANY_LOGO = "company-logo";
export const GET_BY_QUOTE_PROVIDER_AND_TICKER =
  "get-by-quote-provider-and-ticker";

export const REQUEST_EMAIL_CHANGE = "request-email-change";
export const GET_USER_PROFILE_IMAGE = "get-user-profile-image";
export const GET_DIVIDENDS_CALENDAR = "get-dividends-calendar";
export const GET_TAX_ESTIMATE_KEY = "get-tax-estimate";
export const GET_DIVIDENDS_PORTFOLIO_YIELD = "get-dividends-portfolio-yield";
export const GET_DIVIDENDS_HISTORY = "get-dividends-history";
export const GET_DIVIDENDS_PREDICTION = "get-dividends-prediction";
export const GET_PERFORMANCE = "get-performance";
export const GET_AND_VALIDATE_FINANZFLUSS_BOOKINGS_KEY =
  "get-and-validate-finanzfluss-bookings";
