import { InvokeCallback, Sender } from "xstate";

import { validateBookingsWithToken } from "@api/v1/methods/bookings";
import { DividendWizardContext } from "../types/dividendWizardContext";
import { DividendWizardEvent } from "../types/dividendWizardEvent";
import { DividendValues } from "../types/dividendValues";
import { buildCreateOrUpdateBookingParams } from "../../utils/utils";

export const validateDividend =
  (
    context: DividendWizardContext,
    event: DividendWizardEvent
  ): InvokeCallback<DividendWizardEvent, DividendWizardEvent> =>
  (callback) => {
    if (event.type !== "VALIDATE_MANUAL_DIVIDEND") {
      throw new Error(`Invalid event type ${event.type}`);
    }

    if (!context.investment?.id) {
      throw new Error("Invalid investment");
    }

    validate(
      event.dividendValues,
      context.investment.id,
      context.investment.account.id,
      callback
    );
  };

const validate = async (
  dividendValues: DividendValues,
  investmentId: number,
  accountId: number,
  callback: Sender<DividendWizardEvent>
) => {
  try {
    const params = buildCreateOrUpdateBookingParams(
      investmentId,
      dividendValues,
      accountId
    );
    const { hasValidationErrors, transactionValidationErrors } =
      await validateBookingsWithToken(params);

    if (!hasValidationErrors) {
      callback({
        type: "DIVIDENDS_VALIDATED",
        validatedMultipleBookingParams: params,
      });

      return;
    }

    callback({ type: "BACK", validationErrors: transactionValidationErrors });
  } catch (e) {
    callback({
      type: "SOMETHING_WRONG",
      error: e as AxiosApiError,
    });
  }
};
