import { UseQueryOptions } from "react-query";
import {
  PerformanceApiTradingGainForInvestmentRequest,
  RealizedGainByBooking,
} from "@generated/apiv1";

import { buildPerformanceApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const tradingGainForInvestment = async (
  token: string | undefined,
  params: PerformanceApiTradingGainForInvestmentRequest
) => {
  const apiInstance = buildPerformanceApi(token);
  const { data } = await apiInstance.tradingGainForInvestment(params);
  return data;
};

export const useTradingGainForInvestment = (
  params: PerformanceApiTradingGainForInvestmentRequest,
  options?: UseQueryOptions<
    RealizedGainByBooking,
    AxiosApiError,
    RealizedGainByBooking
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.PERFORMANCE_TRADING_GAIN_FOR_INVESTMENT, params],
    ({ token }) => tradingGainForInvestment(token, params),
    options
  );
};
