import { UseQueryOptions } from "react-query";
import {
  CategoriesApiGetCategoriesRequest,
  CategoryList,
} from "@generated/apiv3";

import { buildCategoriesApi } from "@generated/apiv3/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getCategories = async (
  token: string | undefined,
  params?: CategoriesApiGetCategoriesRequest
) => {
  const apiInstance = buildCategoriesApi(token);
  const { data } = await apiInstance.getCategories(params);
  return data;
};

export const useGetCategories = (
  params?: CategoriesApiGetCategoriesRequest,
  options?: UseQueryOptions<CategoryList, AxiosApiError, CategoryList>
) => {
  return useAuthQuery(
    [CACHE_KEYS.CATEGORIES_GET_CATEGORIES, params],
    ({ token }) => getCategories(token, params),
    options
  );
};
