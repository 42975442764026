import { UseMutationOptions } from "react-query";
import {
  TransactionsApiAssignCategoryRequest,
  IdentifierList,
} from "@generated/apiv1";

import { buildTransactionsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const assignCategory = async (
  token: string | undefined,
  params: TransactionsApiAssignCategoryRequest
) => {
  const apiInstance = buildTransactionsApi(token);
  const { data } = await apiInstance.assignCategory(params);
  return data;
};

export const useAssignCategory = (
  options?: UseMutationOptions<
    IdentifierList,
    AxiosApiError,
    TransactionsApiAssignCategoryRequest
  >
) => {
  return useAuthMutation(assignCategory, options, false);
};
