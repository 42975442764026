import React from "react";
import { Box, TableCell, Typography } from "@mui/material";
import { format, isValid, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { BookingType, InvestmentInfo, TableRow, Tooltip } from "@components";
import { toCurrency, toGermanNumberFormat } from "@helpers";
import { ButtonWithActionMenu } from "@components/ButtonWithActionMenu";
import { ActionMenu } from "@features/csvDrop/ActionMenu";
import { CsvRow } from "@generated/apiv3";
import { ReactComponent as WarningIcon } from "@icons/warning-circle.svg";

const getDate = (date: string | undefined) =>
  date && isValid(parseISO(date))
    ? format(new Date(date), "dd.MM.yyyy")
    : undefined;

type Props = {
  parsingResult: CsvRow;
  image?: string | null;
  onEditClick: (parsingResult: CsvRow) => void;
  onDeleteClick: (parsingResult: CsvRow) => void;
};

export const ParsingResultRow = ({
  parsingResult,
  image,
  onEditClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation();

  const errors: string[] = Object.values(parsingResult).reduce(
    (acc, field) =>
      field?.error
        ? [
            ...acc,
            t("csvImport.parsingResults.error", {
              name: t(`csvImport.parsingResults.${field.name}`),
            }),
          ]
        : acc,
    []
  );
  const currency = parsingResult.currency?.value || "EUR";

  return (
    <TableRow key={parsingResult.id}>
      <TableCell>
        <Box display="flex" alignItems="center" gap={2.5} mb={0.5}>
          {parsingResult.transaction_type?.value && (
            <BookingType type={parsingResult.transaction_type?.value} />
          )}
          {Boolean(errors.length) && (
            <Tooltip
              text={
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontWeight={400}
                >
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Typography>
              }
            >
              <Box display="flex" alignItems="center">
                <WarningIcon />️
              </Box>
            </Tooltip>
          )}
        </Box>
        <Typography variant="body2" color="textSecondary" fontWeight={400}>
          {getDate(parsingResult.date?.value)}
        </Typography>
      </TableCell>
      <TableCell>
        <InvestmentInfo
          item={{
            hasValidIsin: Boolean(parsingResult.isin?.value),
            isin: parsingResult.isin?.value,
            name: parsingResult.name?.value,
            type: parsingResult.asset_type?.value,
          }}
          image={image}
          link={null}
        />
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {toGermanNumberFormat(parsingResult.number_of_lots?.value || 0, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 6,
          })}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {toCurrency(parsingResult.security_price?.value, currency)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="right">
          {toCurrency(parsingResult.commission?.value, currency)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" align="right">
          {toCurrency(parsingResult.tax_amount?.value, currency)}
        </Typography>
      </TableCell>
      <TableCell>
        <ButtonWithActionMenu>
          <ActionMenu
            onEditClick={() => onEditClick(parsingResult)}
            onDeleteClick={() => onDeleteClick(parsingResult)}
          />
        </ButtonWithActionMenu>
      </TableCell>
    </TableRow>
  );
};
