import { UseQueryOptions } from "react-query";
import {
  InternalTransactionsApiInternalTransactionsIdRequest,
  InternalTransaction,
} from "@generated/apiv1";

import { buildInternalTransactionsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const internalTransactionsId = async (
  token: string | undefined,
  params: InternalTransactionsApiInternalTransactionsIdRequest
) => {
  const apiInstance = buildInternalTransactionsApi(token);
  const { data } = await apiInstance.internalTransactionsId(params);
  return data;
};

export const useInternalTransactionsId = (
  params: InternalTransactionsApiInternalTransactionsIdRequest,
  options?: UseQueryOptions<
    InternalTransaction,
    AxiosApiError,
    InternalTransaction
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.INTERNALTRANSACTIONS_INTERNAL_TRANSACTIONS_ID, params],
    ({ token }) => internalTransactionsId(token, params),
    options
  );
};
