import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Theme,
  Table,
  TableBody,
  Button,
  useMediaQuery,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CsvParseResult, CsvRow, ParseCsvBankSlugEnum } from "@generated/apiv3";
import { LoadingButton, SortableTableHeader } from "@components";
import { useSecurityImages, useTableSortWithData } from "@hooks";
import { ParsingResultEditModal } from "@features/csvDrop/ParsingResultEditModal";
import { InvestmentTypeEnum } from "@generated/apiv1";
import { useImportCsv } from "@generated/apiv3/hooks";
import { ParsingResultRow } from "@features/csvDrop/ParsingResultRow";

type Props = {
  depotId?: number;
  bankSlug?: ParseCsvBankSlugEnum;
  parsingResult: CsvParseResult | null;
  changeStep: (step: number) => void;
};

export const ParsingResults = ({
  parsingResult,
  changeStep,
  depotId,
  bankSlug,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [parsingResults, setParsingResults] = useState<CsvRow[] | undefined>(
    parsingResult?.result
  );
  const [parsingResultToEdit, setParsingResultToEdit] = useState<CsvRow | null>(
    null
  );
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { mutate: saveParsingResults, isLoading: isSaving } = useImportCsv();

  const {
    sort,
    sortDirection,
    sortField,
    data: sortedResults,
  } = useTableSortWithData(parsingResults ?? [], {
    field: "date.value",
    direction: "ASC",
  });

  const isNextDisabled =
    !parsingResults?.length ||
    parsingResults?.some((result) =>
      Object.values(result).some(
        (field) => field.name !== "asset_type" && field?.error
      )
    );

  const images = useSecurityImages(
    parsingResults?.map((result) => ({
      ...result,
      quoteProvider: "tte",
      isin: result.isin?.value,
      // ticker_symbol: result.ticker_symbol?.value,
      hasValidIsin: Boolean(result.isin?.value),
      type: result.asset_type?.value ?? InvestmentTypeEnum._11_STOCK,
    }))
  );

  useEffect(() => {
    setParsingResults(parsingResult?.result);
  }, [parsingResult?.result]);

  const handleParsingResultSave = (result: CsvRow) => {
    const updatedParsingResults = parsingResults?.map((parsingResult) =>
      parsingResult.id === result.id ? result : parsingResult
    );

    setParsingResults(updatedParsingResults);
    setParsingResultToEdit(null);
  };

  const handleParsingResultDelete = (result: CsvRow) => {
    const updatedParsingResults = parsingResults?.filter(
      (parsingResult) => parsingResult.id !== result.id
    );

    setParsingResults(updatedParsingResults);
  };

  const handleParsingResultsSubmit = () => {
    if (!parsingResults || !depotId || !bankSlug) {
      changeStep(0);
      return;
    }
    saveParsingResults(
      {
        csvImportPerformAttributes: {
          records: parsingResults,
          // @ts-ignore
          depot_id: depotId,
          bank_slug: bankSlug,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("csvImport.parsingResults.saveSuccess"), {
            variant: "success",
            autoHideDuration: 3000,
          });
          navigate(`/depots/${depotId}`);
        },
        onError: () => {
          enqueueSnackbar(t("csvImport.parsingResults.saveError"), {
            variant: "error",
            autoHideDuration: 3000,
          });
        },
      }
    );
  };

  return (
    <Box maxWidth={920}>
      <Box
        sx={(theme: Theme) => ({
          borderRadius: `8px`,
          border: `1px solid ${theme.palette.border.primary}`,
        })}
      >
        <Box
          px={4}
          sx={{
            overflowY: "auto",
            maxHeight: "628px",
            "& tr:last-of-type .MuiTableCell-root": {
              borderBottom: "none !important",
            },
          }}
          height="100%"
        >
          <Table stickyHeader>
            <TableHead>
              <SortableTableHeader
                text={t("csvImport.parsingResults.date")}
                sortByName="date.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
              />
              <SortableTableHeader
                text={t("csvImport.parsingResults.name")}
                sortByName="name.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
              />
              <SortableTableHeader
                text={t("csvImport.parsingResults.number_of_lots")}
                sortByName="number_of_lots.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
                align="right"
              />
              <SortableTableHeader
                text={t("csvImport.parsingResults.security_price")}
                sortByName="security_price.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
                align="right"
              />
              <SortableTableHeader
                text={t("csvImport.parsingResults.commission")}
                sortByName="commission.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
                align="right"
              />
              <SortableTableHeader
                text={t("csvImport.parsingResults.tax_amount")}
                sortByName="tax_amount.value"
                sortField={sortField}
                sortDirection={sortDirection}
                isSortable
                onClick={sort}
                align="right"
              />
              <SortableTableHeader text="" sortByName="" />
            </TableHead>
            <TableBody>
              {sortedResults.map((result) => (
                <ParsingResultRow
                  image={images[result.isin?.value ?? ""]}
                  parsingResult={result}
                  key={result.id}
                  onEditClick={setParsingResultToEdit}
                  onDeleteClick={handleParsingResultDelete}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={isLessSm ? "column" : "row"}
        justifyContent="space-between"
        gap={3}
        mt={6}
      >
        <Button
          variant="contained"
          color="grey"
          onClick={() => changeStep(0)}
          fullWidth={isLessSm}
        >
          {t("navigation.previous")}
        </Button>
        <LoadingButton
          fullWidth={isLessSm}
          isLoading={isSaving}
          disabled={isNextDisabled}
          variant="contained"
          color="primary"
          onClick={handleParsingResultsSubmit}
        >
          {t("navigation.next")}
        </LoadingButton>
      </Box>
      {parsingResultToEdit && (
        <ParsingResultEditModal
          isOpen={Boolean(parsingResultToEdit)}
          onClose={() => setParsingResultToEdit(null)}
          parsingResult={parsingResultToEdit}
          onSave={handleParsingResultSave}
        />
      )}
    </Box>
  );
};
