import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Theme, useMediaQuery } from "@mui/material";
import {
  TermsMainTitle,
  TermsSecondSubheader,
  TermsText,
  TermsTitle,
} from "./shared";

interface TppWebFormTermsOfUseProps {
  showTitle?: boolean;
}

export const TppWebFormTermsOfUse = (props: TppWebFormTermsOfUseProps) => {
  const { showTitle = true } = props;
  const { t } = useTranslation();
  const isMoreSM = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <div>
      {showTitle && (
        <TermsMainTitle variant={isMoreSM ? "h1" : "h3"} color="textPrimary">
          Allgemeine Geschäftsbedingungen (AGB)
        </TermsMainTitle>
      )}

      <TermsSecondSubheader variant="subtitle2" color="textPrimary">
        {t("aboutCompany.b2b.name")} - {t("aboutCompany.b2b.claim")}
      </TermsSecondSubheader>

      <TermsTitle variant="h3" color="textPrimary">
        Nutzungsvereinbarung und Hinweise für Endnutzer von wealthAPI
        Kontoinformations- und Zahlungsauslösediensten
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Stand: 06.12.2021
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        1 Inhalt und Zustandekommen des Vertrages
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        {" "}
        1.1 Vertragsparteien und Gegenstand
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Die {t("aboutCompany.b2b.name")}, {t("aboutCompany.company.street")}{" "}
        {t("aboutCompany.company.streetNumber")},{" "}
        {t("aboutCompany.company.zip")} {t("aboutCompany.company.city")} (AG
        Berlin Charlottenburg HRB 166061 B), E-Mail:{" "}
        {t("aboutCompany.b2b.email")} ("wealthAPI") ist ein von der
        Bundesanstalt für Finanzdienstleistungsaufsicht ("BaFin"),
        Graurheindorfer Str. 108, D-53117 Bonn, Fax: + 49 228 4108-1550, E-Mail:
        poststelle@bafin.de, beaufsichtigtes Zahlungsinstitut.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Der von wealthAPI als Online-Dienst zur Verfügung gestellte
        Kontoinformationsdienst ("KID") erlaubt es Endnutzern, ihre Kontostände
        und Kontobewegungen (nachfolgend "Kontoinformationen") von
        Zahlungskonten und sonstigen Konten von Kredit- und
        Finanzdienstleistungsinstituten, Kreditkartenunternehmen und anderen
        Finanzdatenanbietern (nachfolgend "Kontoanbieter") auszulesen, soweit
        diese Konten jeweils in den Leistungsumfang zwischen dem Endnutzer und
        dem Dienste-Anbieter (wie im Folgenden definiert) einbezogen sind.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Die ausgelesenen Kontoinformationen werden bei wealthAPI gespeichert und
        über ein API (Application Programming Interface) dem Endnutzer über den
        Dienst des Dienst-Anbieters zugänglich gemacht.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        1.2 Verhältnis zum Dienst-Anbieter
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI und der Dienst-Anbieter sind rechtlich und wirtschaftlich
        selbstständige Unternehmen und erbringen für den Endnutzer
        unterschiedliche Leistungen (zum Zusammenspiel von wealthAPI und Dienst
        siehe Ziffer 2.1.1).
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        1.3 Weg zum Vertragsschluss
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Voraussetzung für die Erbringung von Kontoinformationsdiensten durch
        wealthAPI ist der Abschluss dieser Nutzungsvereinbarung. Hierzu wird der
        Endnutzer aus dem Dienst auf eine Webseite order ein System von
        wealthAPI weitergeleitet. Auf dieser Webseite/diesen System können vom
        Endnutzer folgende Aktivitäten durchgeführt werden:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        1.3.1 Der Endnutzer kann die Datenschutzhinweise und die
        vorvertraglichen Informationen für Kontoinformationsdienste und die
        Nutzungsbedingungen zur Kenntnis nehmen. Mit dem Anklicken des "Weiter"
        Buttons stimmt der Endnutzer den Nutzungsbedingungen zu. Mit der
        Zustimmung kommt der Vertrag zwischen Endnutzer und wealthAPI zustande
        ("Vertragsschluss"). Die Nutzungsbedingungen stehen dem Endnutzer als
        Link zum Download und zum Ausdruck zur Verfügung.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        1.3.2 Anschließend kann der Endnutzer seine Zugangsdaten für den Zugang
        zum Kontoanbieter (Benutzerkennung o.ä. und PIN/Passwort) eingeben.
        Teilweise hat der Endnutzer zusätzlich die Option, die Speicherung der
        PIN durch wealthAPI zu erlauben oder abzulehnen. Per Klick auf den
        entsprechenden Button (z.B. "Bankdaten abrufen") werden die Zugangsdaten
        an wealthAPI übermittelt und die Verbindung zum Kontoanbieter durch
        wealthAPI hergestellt.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        1.3.3 Im Falle eines wealthAPI KID kann der Kontoanbieter eine starke
        Kundenauthentifizierung verlangen. In diesem Fall kann der Endnutzer
        seine personalisierten Sicherheitsmerkmale, bspw. eine TAN, in der
        Eingabemaske angeben und über wealthAPI an den Kontoanbieter
        übermitteln, sofern dies erforderlich und technisch möglich ist.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        1.3.4 wealthAPI nutzt für die Erbringung von Kontoinformationsdiensten
        Dienste der finAPI GmbH (Adams-Lehmann-Straße 44, D-80797 München) im
        Rahmen einer enstprechenden Vereinbarung zur Aufgragsdatenvereinbarung.
        Kontoinformationen des Benutzers werden auf Seiten der finAPI GmbH
        gespeichert, soweit dies zur Erbringung des KID notwendig ist.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Die Einbindung weiterer Kontoanbieter und/oder Konten nach
        Vertragsschluss ist, soweit diese von Dienste-Anbieter unterstützt
        werden, grundsätzlich möglich und erfolgt ebenfalls durch Weiterleitung
        des Endnutzers auf eine Webseite/ ein System von wealthAPI.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        1.4 Keine abweichenden Regelungen/Änderungen
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Für die Erbringung der Kontoinformation- oder Zahlungsauslösedienste
        durch wealthAPI gelten im Verhältnis zwischen wealthAPI und dem
        Endnutzer ausschließlich diese Nutzungsbedingungen. Änderungen dieser
        Nutzungsbedingungen werden vor ihrem Inkrafttreten rechtzeitig
        schriftlich, in elektronischer Form oder in Textform mitgeteilt; sie
        gelten als genehmigt, wenn der wealthAPI nicht binnen eines Monats nach
        Bekanntgabe der Änderungen ein Widerspruch des Endnutzers zugeht. Die
        Mitteilung kann über wealthAPI durch Anzeige innerhalb der Eingabemaske
        oder über den Dienst-Anbieter erfolgen. Ein Widerspruch berechtigt den
        Endnutzer zur fristlosen Kündigung dieser Nutzungsvereinbarung.
        wealthAPI wird den Endnutzer auf die Frist und die Möglichkeit zum
        Widerspruch hinweisen.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        2 Leistungsbeschreibung
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        2.1 Leistungsbeschreibung
      </TermsTitle>

      <TermsTitle variant="h6" color="textPrimary">
        2.1.1 Zusammenspiel von Dienst und wealthAPI
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Der Endnutzer kann wealthAPI KID aus dem Dienst des Dienst-Anbieters
        heraus nutzen. Dabei wird die Eingabe und Verwaltung der persönlichen
        Zugangsdaten des Endnutzers beim jeweiligen Kontoanbieter sowie die
        Ausführung der KID direkt von wealthAPI für den Endnutzer durchgeführt.
        Nach Ausführung der KID Funktionen stellt wealthAPI die entsprechenden
        Daten dem Endnutzer zur Anzeige und Verarbeitung in dem Dienst des
        Dienst-Anbieters zur Verfügung. Der Dienst-Anbieter erhält dabei zu
        keinem Zeitpunkt sensible Zahlungsdaten, die ihm einen Zugriff auf
        Kontoinformationen bei Kontoanbietern des Endnutzers ermöglichen. Die
        sensiblen Zahlungsdaten werden direkt an wealthAPI-Systeme übergeben,
        von wealthAPI verarbeitet und im Falle des KID von wealthAPI bzw. der
        finAPI GmbH im Auftrag von wealthAPI gespeichert. Die vom Endnutzer
        eingegebenen, sensiblen Zahlungsdaten werden durch die wealthAPI vor
        unbefugtem Zugriff geschützt.
      </TermsText>

      <TermsTitle variant="h6" color="textPrimary">
        2.1.2 wealthAPI KID Funktionen
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Allgemein umfassen die KID von wealthAPI für den Endnutzer folgende
        Funktionen mit der Maßgabe, dass sich die für den Endnutzer im
        Einzelfall zur Verfügung stehenden Funktionen auch nach dem zwischen dem
        Endnutzer und dem Dienst-Anbieter vereinbarten Leistungsumfang richten,
        so dass gegebenenfalls einzelne der im Folgenden aufgeführten Funktionen
        für den Endnutzer nicht nutzbar sind:
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.1.2.1 Allgemein
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Entgegennahme der persönlichen Zugangsdaten des Endnutzers beim
        jeweiligen Kontoanbieter auf einer gesonderten und gesicherten
        Webseite/Systems von wealthAPI. Entgegennahme einer TAN oder eines
        sonstigen weiteren Sicherheitsmerkmals auf einer gesonderten
        Webseite/Systems von wealthAPI und Übermittlung an den Kontoanbieter des
        Endnutzers.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        2.1.2.2 Darüber hinaus umfasst der KID folgende Funktionen
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>Einzelner oder wiederholter Abruf von Kontoinformationen.</li>
          <li>
            Speicherung und Aggregation der abgerufenen Kontoinformationen für
            den späteren Abruf durch einen vom Endnutzer bevollmächtigten
            Dienst.
          </li>
          <li>
            Abruf der Kontoinformationen durch den vom Endnutzer genutzten
            Dienst.
          </li>
          <li>
            Optionale verschlüsselte Speicherung der Zugangsdaten zu einem
            Kontoanbieter.
          </li>
          <li>Änderung und Löschung der zuvor gespeicherten Zugangsdaten.</li>
        </ul>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.1.2.4 Weitere aufbauende optionale Dienstleistungen
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Die Kategorisierung von Kontoinformationen. Einzelne Funktionen der
        wealthAPI KID hängen von Produkten und Leistungen Dritter ab, auf die
        wealthAPI keinen Einfluss hat. Dies kann dazu führen, dass einzelne
        Funktionen der wealthAPI KID vorübergehend oder dauerhaft nicht
        verfügbar sein können.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.2 Garantien nur in Schriftform mit Unterschrift
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Garantien und Zusicherungen von Eigenschaften durch wealthAPI sind im
        Zweifel nur dann als solche auszulegen, wenn sie in Schriftform (mit
        Unterschrift) erfolgen und als "Garantie" bezeichnet sind.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.3 Bereitstellung und Nutzung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Dem Endnutzer stehen die Funktionalitäten von wealthAPI im Umfang des
        zwischen dem Endnutzer und dem Dienst-Anbieter vereinbarten
        Nutzungsumfangs zur Verfügung und die Nutzung gemäß dieser
        Nutzungsvereinbarung zu.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        3 Pflichten und Obliegenheiten des Endnutzers
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        3.1 Einhalten vorgegebener Nutzungsszenarien
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Endnutzern ist es nicht gestattet, die wealthAPI Funktionen in einer
        Weise zu nutzen, die in diesen Nutzungsbedingungen nicht ausdrücklich
        gestattet ist.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Endnutzer dürfen auf die wealthAPI KID nicht zugreifen oder diese
        nutzen, um
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>
            die Funktionalität des wealthAPI KID zu stören, zu belasten oder zu
            unterbrechen;
          </li>
          <li>
            den Betrieb oder die Funktionalität des wealthAPI KID zu umgehen, zu
            behindern oder zu manipulieren;
          </li>
          <li>technische Beschränkungen zu umgehen oder zu unterlaufen;</li>
          <li>
            den wealthAPI KID auf einer anderen Webseite oder in einer anderen
            Anwendung einzubetten oder anderweitig zu verlinken;
          </li>
          <li>
            die Sicherheit der wealthAPI Software oder eines Netzwerks oder
            eines Servers, das bzw. den wealthAPI nutzt, zu verletzen oder
            Sicherheitsschwachstellen derselben zu auszunutzen;
          </li>
          <li>
            den wealthAPI KID für im Zusammenhang mit Aktivitäten, die eine
            Straftat darstellen oder gegen nationale und ausländische
            gesetzliche Bestimmungen verstoßen, zu nutzen.
          </li>
        </ul>
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        3.2 Sperrung bei Missbrauch
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI ist berechtigt, den Zugang des Endnutzers zu sperren, wenn
        Anhaltspunkte für eine missbräuchliche Nutzung oder eine Gefährdung der
        Datensicherheit vorliegen.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        4 Haftungsbeschränkung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI haftet im Rahmen der gesetzlichen Bestimmungen für Schäden,
        soweit diese
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>
            1. vorsätzlich oder grob fahrlässig von wealthAPI verursacht wurden
            oder
          </li>
          <li>
            2. leicht fahrlässig von wealthAPI verursacht wurden und auf
            wesentliche Pflichtverletzungen zurückzuführen sind, die die
            Erreichung des Zwecks dieses Vertrages gefährden oder auf die
            Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße
            Durchführung dieses Vertrages erst ermöglicht und auf deren
            Einhaltung der Endnutzer vertrauen darf
            (Kardinalspflichtverletzung). In diesem Fall haftet wealthAPI nur
            begrenzt auf den für einen Vertrag dieser Art typischerweise
            vorhersehbaren Schaden.
          </li>
        </ul>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Die Haftungsbeschränkungen gelten auch bei Ansprüchen gegen Mitarbeiter
        und Beauftragte von wealthAPI.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Die Haftungsbeschränkungen gelten auch bei Ansprüchen gegen Mitarbeiter
        und Beauftragte von wealthAPI.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        5 Kosten
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Die Nutzung des wealthAPI KID ist für den Endnutzer kostenlos.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        6 Laufzeit und Vertragsbeendigung
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        6.1 Laufzeit und Vertragsbeendigung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Der Vertrag beginnt mit Vertragsschluss und ist auf unbestimmte Zeit
        geschlossen. Der Vertrag kann von beiden Parteien mit einer Frist von
        zwei Wochen gekündigt werden. Das Recht zur außerordentlichen Kündigung
        bleibt unberührt. Kündigungen bedürfen der Textform (z.B. E-Mail).
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Der Vertrag endet, ohne dass es einer Erklärung des Endnutzers oder von
        wealthAPI bedarf, wenn
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>
            das Vertragsverhältnis zwischen wealthAPI und dem Dienst-Anbieter
            endet.
          </li>
          <li>
            das den Endnutzer betreffende Vertragsverhältnis zwischen dem
            Endnutzer und den Dienst-Anbieter endet.
          </li>
        </ul>
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        6.2 Folgen der Vertragsbeendigung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Mit Vertragsende wird wealthAPI die personenbezogenen Daten des
        Endnutzers löschen, sofern wealthAPI nicht gesetzlich zur Aufbewahrung
        berechtigt oder verpflichtet ist.
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        7 Sonstiges
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        7.1 Anwendbares Recht
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Auf diesen Vertrag und sämtliche damit im Zusammenhang stehende
        Streitigkeiten findet ausschließlich deutsches Recht Anwendung.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        7.2 Gerichtsstand
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Ist der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts
        oder ein öffentlich-rechtliches Sondervermögen, so ist ausschließlicher
        Gerichtsstand Berlin. wealthAPI bleibt in diesem Fall berechtigt, am
        Sitz des Kunden zu klagen.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        7.3 Ausschließliche Geltung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Diese Nutzungsvereinbarung gilt ausschließlich. Entgegenstehende oder
        abweichende Allgemeine Geschäftsbedingungen des Endnutzers werden von
        wealthAPI nicht anerkannt und entfalten keine rechtliche Wirkung, es sei
        denn, wealthAPI stimmt diesen zuvor ausdrücklich schriftlich zu.
      </TermsText>

      <TermsTitle variant="h2" color="textPrimary">
        Vorvertragliche Informationen für Kontoinformationsdienste und
        Zahlungsauslösedienste (§ 675d BGB i.V.m: Art. 248 EGBGB)
      </TermsTitle>

      <TermsTitle variant="h4" color="textPrimary">
        1 Informationen zum Zahlungsdienstleiter
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        1.1 Name und Anschrift Zahlungsdienstleisters
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        {t("aboutCompany.b2b.name")}
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        {t("aboutCompany.company.street")}{" "}
        {t("aboutCompany.company.streetNumber")}
        <br />
        {t("aboutCompany.company.zip")} {t("aboutCompany.company.city")}
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Email:{" "}
        <Link href={`mailto:${t("aboutCompany.b2b.email")}`} underline="hover">
          {t("aboutCompany.b2b.email")}
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Web:{" "}
        <Link href={t("aboutCompany.b2b.website")} underline="hover">
          {t("aboutCompany.b2b.website")}
        </Link>
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        1.2 Zuständige Aufsichtsbehörde/Register
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        E-Mail:{" "}
        <Link href="mailto:poststelle@bafin.de" underline="hover">
          poststelle@bafin.de
        </Link>
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Web:{" "}
        <Link href="https://www.bafin.de" underline="hover">
          www.bafin.de
        </Link>
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Dienstsitz Bonn:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Graurheindorfer Str. 108
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        53117 Bonn
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Dienstsitz Frankfurt:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Marie-Curie-Str. 24-28
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        60439 Frankfurt
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Zahlungsinstitute-Register: BaFin ID 152048
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        und
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Deutsche Bundesbank
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Hauptverwaltung Brandenburg
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Leibnizstr. 10
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        10965 Berlin
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        E-Mail:{" "}
        <Link href="mailto:info@bundesbank.de" underline="hover">
          info@bundesbank.de
        </Link>
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Web:{" "}
        <Link href="https://www.bundesbank.de" underline="hover">
          www.bundesbank.de
        </Link>
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        2 Informationen zur Nutzung der Zahlungsdienste
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        2.1 Kontoinformationsdienste
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Der von der wealthAPI als Online-Dienst zur Verfügung gestellte
        Kontoinformationsdienst ist ein Online-Dienst zur Mitteilung
        konsolidierter Informationen über Zahlungskonten und sonstigen Konten
        von Kredit- und Finanzdienstleistungsinstituten, Kreditkartenunternehmen
        und anderen Finanzdatenanbietern. Der Vertragsschluss zwischen dem
        Endnutzer und wealthAPI kommt für einen Kontoinformationsdienst
        zustande, wenn der Endnutzer die personalisierten Sicherheitsmerkmale
        (Benutzerkennung o.ä., ggfs. Kontonummer oder IBAN und PIN/Passwort) auf
        der Webseite der wealthAPI und durch das Bestätigen des entsprechenden
        Dialogfelds seine ausdrückliche Zustimmung zum Kontoinformationsdienst
        erteilt.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.2 Entgelte/Wechselkurs/Referenzwechselkurs
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Die Nutzung der Zahlungsdienste von wealthAPI ist für sie unentgeltlich.
        Auf Entgelte, Zinsen und Wechselkurse aus Ihrem Vertragsverhältnis mit
        dem Kontoinhaber hat die wealthAPI keinen Einfluss.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.3 Kommunikation
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Die vorvertraglichen Informationspflichten werden dem Endnutzer vor
        Abschluss der jeweiligen Verträge durch wealthAPI zur Verfügung
        gestellt. Die Kommunikation zwischen dem Endnutzer und wealthAPI erfolgt
        in deutscher Sprache. Dies gilt auch für die Vertragssprache.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.4 Schutz- und Abhilfemaßnahmen
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Im Falle eines vermuteten oder tatsächlichen Betrugs oder bei
        Sicherheitsrisiken wird wealthAPI oder der Dienst-Anbieter den Endnutzer
        hierüber informieren.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.5 Informationen zur Haftung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        2.5.1 Beruhen nicht autorisierte Zahlungsvorgänge auf der Nutzung eines
        verloren gegangenen, gestohlenen oder sonst abhandengekommenen
        Zahlungsinstruments oder auf der sonstigen missbräuchlichen Verwendung
        eines Zahlungsinstruments, so kann der Kontoanbieter vom Endnutzer den
        Ersatz des hierdurch entstandenen Schadens bis zu einem Betrag von 50
        Euro verlangen.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.5.2 Der Endnutzer haftet nicht nach Absatz 2.5.1, wenn
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>
            1. es ihm nicht möglich gewesen ist, den Verlust, den Diebstahl, das
            Abhandenkommen oder eine sonstige missbräuchliche Verwendung des
            Zahlungsinstruments vor dem nicht autorisierten Zahlungsvorgang zu
            bemerken oder
          </li>
          <li>
            2. der Verlust des Zahlungsinstruments durch einen Angestellten,
            einen Agenten, eine Zweigniederlassung des Kontoanbieters des
            Endnutzers oder eine sonstige Stelle, an die Tätigkeiten
            Kontoanbieters des Endnutzers ausgelagert wurden, verursacht worden
            ist.
          </li>
        </ul>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.5.3 Abweichend von den 2.5.1 und 2.5.2 ist der Endnutzer seinem
        Kontoinhaber zum Ersatz des gesamten Schadens verpflichtet, der infolge
        eines nicht autorisierten Zahlungsvorgangs entstanden ist, wenn der
        Endnutzer
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>1. in betrügerischer Absicht gehandelt hat oder</li>
          <li>
            2. den Schaden herbeigeführt hat durch vorsätzliche oder grob
            fahrlässige Verletzung
            <ul>
              <li>
                a) einer oder mehrerer Pflichten gemäß § 675l Absatz 1 oder
              </li>
              <li>
                b) einer oder mehrerer vereinbarter Bedingungen für die Ausgabe
                und Nutzung des Zahlungsinstruments.
              </li>
            </ul>
          </li>
        </ul>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.5.4 Abweichend von den 2.5.1 und 2.5.3 ist der Endnutzer seinem
        Kontoinhaber nicht zum Schadensersatz verpflichtet, wenn
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>
            1. der Kontoanbieter des Endnutzers eine starke
            Kundenauthentifizierung im Sinne des § 1 Absatz 24 des
            Zahlungsdiensteaufsichtsgesetzes nicht verlangt oder
          </li>
          <li>
            2. der Zahlungsempfänger oder sein Zahlungsdienstleister eine starke
            Kundenauthentifizierung im Sinne des § 1 Absatz 24 des
            Zahlungsdiensteaufsichtsgesetzes nicht akzeptiert.
          </li>
        </ul>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Satz 1 gilt nicht, wenn der Endnutzer in betrügerischer Absicht
        gehandelt hat. Im Fall von Satz 1 Nummer 2 ist derjenige, der eine
        starke Kundenauthentifizierung nicht akzeptiert, verpflichtet, dem
        Kontoanbieter des Endnutzers den daraus entstehenden Schaden zu
        ersetzen.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.5.5 Abweichend von den 2.5.1 und 2.5.3 ist der Endnutzer nicht zum
        Ersatz von Schäden verpflichtet, die aus der Nutzung eines nach der
        Anzeige gemäß § 675l Absatz 1 Satz 2 verwendeten Zahlungsinstruments
        entstanden sind. Der Zahler ist auch nicht zum Ersatz von Schäden im
        Sinne des Absatzes 1 verpflichtet, wenn der Kontoanbieter seiner Pflicht
        gemäß § 675m Abs. 1 Nr. 3 nicht nachgekommen ist. Die Sätze 1 und 2 sind
        nicht anzuwenden, wenn der Endnutzer in betrügerischer Absicht gehandelt
        hat.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.6 Informationen über die Frist innerhalb der, der Endnutzer dem
        Kontoanbieter nicht autorisierte oder fehlerhaft ausgelöste oder
        ausgeführte Zahlungsvorgänge anzeigen muss
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Der Endnutzer ist verpflichtet, seinen Kontoanbieter unverzüglich nach
        Feststellung eines nicht autorisierten oder fehlerhaft ausgeführten
        Zahlungsvorgangs zu unterrichten. Ansprüche und Einwendungen des
        Endnutzers gegen seinen Kontoanbieter sind ausgeschlossen, wenn nicht
        der Endnutzer seinen Kontoanbieter nicht spätestens 13 Monate nach dem
        Tag der nicht autorisierten oder fehlerhaften unterrichtet hat.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.7 Haftung für nicht autorisierte Zahlungsvorgänge
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Im Fall eines nicht autorisierten Zahlungsvorgangs hat der Kontoanbieter
        des Endnutzers gegen diesen keinen Anspruch auf Erstattung seiner
        Aufwendungen. Der Kontoanbieter des Endnutzers ist verpflichtet, dem
        Endnutzer den Zahlungsbetrag unverzüglich zu erstatten und, sofern der
        Betrag einem Zahlungskonto belastet worden ist, dieses Zahlungskonto
        wieder auf den Stand zu bringen, auf dem es sich ohne die Belastung
        durch den nicht autorisierten Zahlungsvorgang befunden hätte. Diese
        Verpflichtung ist unverzüglich, spätestens jedoch bis zum Ende des
        Geschäftstags zu erfüllen, der auf den Tag folgt, an welchem dem
        Kontoanbieter angezeigt wurde, dass der Zahlungsvorgang nicht
        autorisiert ist, oder er auf andere Weise davon Kenntnis erhalten hat.
        Ansprüche und Einwendungen des Endnutzers gegen den Kontoanbieter sind
        ausgeschlossen, wenn dieser seinen Kontoanbieter nicht spätestens 13
        Monate nach dem Tag der Belastung mit einem nicht autorisierten oder
        fehlerhaft ausgeführten Zahlungsvorgang hiervon unterrichtet hat.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        2.8 Haftung bei nicht erfolgter, fehlerhafter oder verspäteter
        Ausführung eines Zahlungsauftrags
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        2.8.1 Wird ein Zahlungsvorgang vom Endnutzer ausgelöst, kann dieser von
        seinem Kontoanbieter im Fall einer nicht erfolgten oder fehlerhaften
        Ausführung des Zahlungsauftrags die unverzügliche und ungekürzte
        Erstattung des Zahlungsbetrags verlangen. Wurde der Betrag einem
        Zahlungskonto des Endnutzers belastet, ist dieses Zahlungskonto wieder
        auf den Stand zu bringen, auf dem es sich ohne den fehlerhaft
        ausgeführten Zahlungsvorgang befunden hätte. Soweit vom Zahlungsbetrag
        entgegen § 675q Abs. 1 Entgelte abgezogen wurden, hat der Kontoanbieter
        des Endnutzers den abgezogenen Betrag dem Zahlungsempfänger unverzüglich
        zu übermitteln. Weist der Kontoanbieter des Endnutzers nach, dass der
        Zahlungsbetrag ungekürzt beim Zahlungsdienstleister des
        Zahlungsempfängers eingegangen ist, entfällt die Haftung nach diesem
        Absatz.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.8.2 Wird ein Zahlungsvorgang vom Endnutzer ausgelöst, kann dieser im
        Fall einer verspäteten Ausführung des Zahlungsauftrags verlangen, dass
        sein Kontoanbieter gegen den Zahlungsdienstleister des
        Zahlungsempfängers den Anspruch nach Satz 2 geltend macht. Der
        Kontoanbieter des Endnutzers kann vom Zahlungsdienstleister des
        Zahlungsempfängers verlangen, die Gutschrift des Zahlungsbetrags auf dem
        Zahlungskonto des Zahlungsempfängers so vorzunehmen, als sei der
        Zahlungsvorgang ordnungsgemäß ausgeführt worden. Weist der Kontoanbieter
        des Endnutzers nach, dass der Zahlungsbetrag rechtzeitig beim
        Zahlungsdienstleister des Zahlungsempfängers eingegangen ist, entfällt
        die Haftung nach diesem Absatz.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.8.3 Ansprüche des Endnutzes gegen seinen Kontoanbieter nach Absatz
        2.8.1 Satz 1 und 2 bestehen nicht, soweit der Zahlungsauftrag in
        Übereinstimmung mit der vom Zahlungsdienstnutzer angegebenen
        fehlerhaften Kundenkennung ausgeführt wurde. In diesem Fall kann der
        Endnutzer von seinem Kontoanbieter jedoch verlangen, dass dieser sich im
        Rahmen seiner Möglichkeiten darum bemüht, den Zahlungsbetrag
        wiederzuerlangen.
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        2.8.4 Ein Endnutzer kann von seinem Kontoanbieter über die Ansprüche
        nach 2.8.1 hinaus die Erstattung der Entgelte und Zinsen verlangen, die
        der Kontoanbieter ihm im Zusammenhang mit der nicht erfolgten oder
        fehlerhaften Ausführung des Zahlungsvorgangs in Rechnung gestellt oder
        mit denen er dessen Zahlungskonto belastet hat.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        2.8.5 Wenn bei Beteiligung mehrerer Zahlungsdienstleister an dem
        Zahlungsvorgang von diesen Zahlungsdienstleistern mindestens einer
        innerhalb und mindestens einer außerhalb des Europäischen
        Wirtschaftsraums belegen ist, ist Absatz 2.8.1 bis 2.8.2 auf die
        innerhalb des Europäischen Wirtschaftsraums getätigten Bestandteile des
        Zahlungsvorgangs nicht anzuwenden.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        2.8.6 Beschwerde- und Rechtsbehelfsverfahren/Streitbeilegungsverfahren
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Bei Beschwerden können sich Endnutzer gemäß § 60 ZAG an die
        Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) wenden. Die
        Beschwerden sind schriftlich oder zur Niederschrift bei der BaFin
        einzulegen und sollen den Sachverhalt sowie den Beschwerdegrund angeben.
        Die Beschwerde ist zu richten an:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Bundesanstalt für Finanzdienstleistungsaufsicht
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Graurheindorfer Straße 108, 53117 Bonn
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        wealthAPI ist grundsätzlich bereit, an Streitbeilegungsverfahren bei der
        Schlichtungsstelle der Deutschen Bundesbank teilzunehmen. Die
        Schlichtungsstelle bei der Deutschen Bundesbank ist eine behördliche
        Verbraucherschlichtungsstelle. Ihr sachlicher Zuständigkeitsbereich ist
        gesetzlich festgelegt.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Schlichtungsstelle bei der Deutschen Bundesbank
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Postfach 11 12 32
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        60047 Frankfurt
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Telefon: 069 / 2388 1907
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        E-Mail: schlichtung@bundesbank.de
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Internet:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        http://www.bundesbank.de/Navigation/DE/Service/Schlichtungsstelle/schlichtungsstelle.html
      </TermsText>

      <TermsTitle variant="h3" color="textPrimary">
        Datenschutzerklärung der wealthAPI GmbH für die Nutzung von
        wealthAPI-Diensten
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Version 1.0 - 27.12.2019
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        1. Angaben zum Verantwortlichen und Datenschutzbeauftragten
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Verantwortliche Stelle im Sinne des Datenschutzrechts ist:
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI GmbH (nachfolgend "wealthAPI")
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Geschäftsführer: André Rabenstein, Dr. Wolfram Stacklies
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        {t("aboutCompany.company.street")}{" "}
        {t("aboutCompany.company.streetNumber")}
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        12107 Berlin
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Telefon:{" "}
        <Link href={`tel:${t("aboutCompany.company.phone")}`} underline="hover">
          {t("aboutCompany.company.phone")}
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Email:{" "}
        <Link href={`mailto:${t("aboutCompany.b2b.email")}`} underline="hover">
          {t("aboutCompany.b2b.email")}
        </Link>
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        eingetragen beim Amtsgericht Charlottenburg unter HRB 166061 B
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Kontakt zum Datenschutzbeauftragten
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        Bei Fragen zum Datenschutz schreiben Sie uns gerne eine E-Mail oder
        wenden Sie sich direkt an unseren Datenschutzbeauftragten:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Telefon:{" "}
        <Link href={`tel:${t("aboutCompany.company.phone")}`} underline="hover">
          {t("aboutCompany.company.phone")}
        </Link>
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Email:{" "}
        <Link
          href={`mailto:${t("aboutCompany.b2b.privacyEmail")}`}
          underline="hover"
        >
          {t("aboutCompany.b2b.privacyEmail")}
        </Link>
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        2. Allgemeines zur Datenverarbeitung
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        wealthAPI verarbeitet personenbezogene Daten im Einklang mit geltendem
        Recht, insbesondere der Verordnung (EU) 2016/679 des europäischen
        Parlaments und des Rates vom 27.04.2016 ("Datenschutz-Grundverordnung",
        DSGVO) und des Bundesdatenschutzgesetzes (BDSG). wealthAPI verwendet
        alle Kunden-, Endnutzer- bzw. Interessentendaten (nachfolgend
        "Kundendaten") grundsätzlich nur
      </TermsText>

      <TermsText variant="body1" color="textPrimary">
        <ul>
          <li>zur Durchführung vorvertraglicher Maßnahmen,</li>
          <li>
            zur Erfüllung ihrer vertraglichen Pflichten gegenüber dem
            betroffenen Kunden, Endnutzer bzw. Interessenten (nachfolgend
            "Kunden") wie in der Nutzungsvereinbarung für Endnutzer beschrieben,
            sowie
          </li>
          <li>
            zur Erfüllung sonstiger rechtlicher, insbesondere
            aufsichtsrechtlicher Pflichten (Art. 6 Abs. 1 lit. b), c) DSGVO).
          </li>
          <li>
            Darüber hinaus kann auch eine Verarbeitung zur Wahrung berechtigter
            Interessen von wealthAPI stattfinden, beispielsweise die Erstellung
            anonymisierter Auswertungen zur Verbesserung oder Erweiterung der
            Services für Kunden, zu Zwecken des Forderungsmanagements, der
            Rechtsverteidigung oder der Direktwerbung für eigene
            Dienstleistungen (z.B. Newsletter), soweit nicht überwiegende
            Interessen, Grundrechte oder Grundfreiheiten des Kunden
            entgegenstehen (Art. 6 Abs. 1 lit. f) DSGVO); insoweit steht dem
            Kunden ein Widerspruchsrecht zu.
          </li>
          <li>
            Grundsätzlich gibt Rentablo keine Kundendaten an Dritte weiter. Zu
            den oben genannten Zwecken gibt wealthAPI ausnahmsweise Kundendaten
            an vertraglich zu Datenschutz und Datensicherheit verpflichtete
            Dritte (z.B. wealthAPI Mitarbeiter, externe Dienstleister wie z.B.
            von wealthAPI beauftragte Kontoinformationsdienste,
            IT-Dienstleister, Betreiber von Endnutzer-Anwendungen auf Basis von
            wealthAPI Diensten), sowie an Konto- und Depotführende Institute
            weiter. Die Weitergabe von Kundendaten ist jeweils auf das
            erforderliche Maß zur Zweckerfüllung beschränkt.
          </li>
          <li>
            Eine Verarbeitung von Kundendaten und/oder deren Weitergabe an
            Dritte zu anderen als den oben genannten Zwecken erfolgt nur auf
            Grundlage ordnungsgemäßer Einwilligung des Kunden (Art. 6 Abs. 1
            lit. a) DSGVO).
          </li>
          <li>
            wealthAPI speichert und verarbeitet personenbezogene Kundendaten,
            solange es für die Erfüllung ihrer vertraglichen und gesetzlichen
            Pflichten erforderlich ist. Dabei ist zu beachten, dass die
            Geschäftsbeziehung zu den Kunden auf Dauer angelegt ist. Sobald der
            Kunde wealthAPI personenbezogene Daten mitgeteilt und wealthAPI auf
            dieser Grundlage ein Vertragsverhältnis (z.B. Vertrag über die
            Erbringung von wealthAPI KontoInformationsDiensten) mit ihm
            begründet und solche Dienstleistungen erbracht hat, bestehen
            handels-, steuerrechtliche und aufsichtsrechtliche Archivierungs-,
            Dokumentations- und Auskunftspflichten, an die wealthAPI gebunden
            ist und die auch Kundendaten umfassen. Solche Fristen betragen zwei
            bis zehn Jahre.
          </li>
          <li>
            Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher
            Pflichten nicht mehr erforderlich, werden diese regelmäßig gelöscht,
            es sei denn, ihre (befristete) Weiterverarbeitung ist für die
            Erhaltung von Beweismitteln im Rahmen der Verjährungsfristen (bis zu
            30 Jahre) erforderlich, soweit seitens wealthAPI für diese
            Speicherung über die gesetzlichen Aufbewahrungsfristen hinaus ein
            überwiegendes berechtigtes Interesse gemäß Art. 6 Abs. 1 lit. f)
            DSGVO besteht.
          </li>
        </ul>
      </TermsText>

      <TermsTitle variant="h4" color="textPrimary">
        3. Rechte des Betroffenen
      </TermsTitle>

      <TermsTitle variant="h5" color="textPrimary">
        Allgemeine Datenschutzrechte
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Kunden haben das Recht auf Auskunft nach Art. 15 DSGVO, das Recht auf
        Berichtigung nach Art. 16 DSGVO, das Recht auf Löschung nach Art. 17
        DSGVO, das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO,
        das Recht auf Widerspruch nach Art. 21 DSGVO sowie das Recht auf
        Datenübertragbarkeit aus Art. 20 DSGVO.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        Widerrufsrechte
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Einige Datenverarbeitungsvorgänge sind nur mit ausdrücklicher
        Einwilligung des Kunden möglich. Kunden können eine bereits erteilte
        Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        Ausübung der Datenschutzrechte, Mitwirkungspflichten und Einschränkungen
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Für die Ausübung bestimmter Widerspruchs- und Widerrufsrechte besteht
        vereinzelt die Möglichkeit der Betätigung von Links oder Buttons;
        hierauf werden Kunden im jeweiligen Zusammenhang gesondert aufmerksam
        gemacht. Daneben können Kunden sich zur Geltendmachung ihrer
        Datenschutzrechte jederzeit postalisch oder per E-Mail an den
        Datenschutzbeauftragten wenden (Kontaktdaten oben).
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Hinweis:
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Eingaben von Kunden können gegebenenfalls nur bearbeiten werden, wenn
        Kunden wealthAPI ihren vollständigen Vor- und Nachnamen, ihre aktuelle
        und ggf. auch alte Anschrift, ihr Geburtsdatum und ihre E-Mail-Adresse
        angeben. Diese Angaben dienen ausschließlich zum Abgleich und stellen
        somit für Kunden einen Schutz dar, damit nicht unberechtigte Dritte
        persönlichen Daten von Kunden erhalten oder deren Datenschutzrechte
        ausüben können. Wünschenswert und hilfreich, aber nicht notwendig, wären
        darüber hinaus auch etwaige Angebots-, Vorgangs- und/oder
        Vertragsnummern, die wealthAPI Kunden mitgeteilt hat, damit wealthAPI
        schneller die betreffenden Daten identifizieren kann.
      </TermsText>
      <TermsText variant="body1" color="textPrimary">
        Bei Ausübung des Auskunftsrechts und des Löschungsrechts gelten die
        Einschränkungen nach §§ 34 und 35 BDSG. Im Übrigen können sich
        Einschränkungen daraus ergeben, dass einer vollständigen oder teilweisen
        Erfüllung der Datenschutzrechte im Einzelfall andere gesetzliche
        Bestimmungen oder ein berechtigtes Interesse von wealthAPI
        entgegenstehen.
      </TermsText>

      <TermsTitle variant="h5" color="textPrimary">
        Beschwerderecht
      </TermsTitle>

      <TermsText variant="body1" color="textPrimary">
        Kunden haben ein Beschwerderecht bei der für wealthAPI zuständigen
        Aufsichtsbehörde. Eine Verpflichtung des Kunden zur Bereitstellung
        personenbezogener Daten besteht bis zum Abschluss des
        Vertragsverhältnisses (z.B. Vertrag über die Erbringung von wealthAPI
        KontoInformationsdiensten) grundsätzlich nicht. Allerdings ist ohne
        diese Daten der Abschluss des Vertragsverhältnisses und dessen Erfüllung
        durch die Erbringung der vertraglich vereinbarten Leistungen gegenüber
        dem Kunden für wealthAPI nicht möglich.
      </TermsText>
    </div>
  );
};
