import React, { Component, ReactNode } from "react";
import * as Sentry from "@sentry/browser";
import { SomethingWrong } from "./SomethingWrong";

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean; eventId?: string; error?: Error }
> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = {
      eventId: undefined,
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId,
        error: error?.response?.data ? error.response.data : error,
      });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <SomethingWrong
          error={this.state.error}
          eventId={this.state.eventId}
          onClose={() => this.setState({ hasError: false, eventId: undefined })}
        />
      );
    }
    return this.props.children;
  }
}
