import { UseMutationOptions } from "react-query";
import {
  InvestmentsApiEditInvestmentRequest,
  Investment,
} from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const editInvestment = async (
  token: string | undefined,
  params: InvestmentsApiEditInvestmentRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.editInvestment(params);
  return data;
};

export const useEditInvestment = (
  options?: UseMutationOptions<
    Investment,
    AxiosApiError,
    InvestmentsApiEditInvestmentRequest
  >
) => {
  return useAuthMutation(editInvestment, options, false);
};
