import { UseMutationOptions } from "react-query";
import { OtpApiDeleteOtpRequest } from "@generated/user";

import { buildOtpApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteOtp = async (
  token: string | undefined,
  params: OtpApiDeleteOtpRequest
) => {
  const apiInstance = buildOtpApi(token);
  const { data } = await apiInstance.deleteOtp(params);
  return data;
};

export const useDeleteOtp = (
  options?: UseMutationOptions<void, AxiosApiError, OtpApiDeleteOtpRequest>
) => {
  return useAuthMutation(deleteOtp, options, true);
};
