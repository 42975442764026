import React, { useState } from "react";
import { AxiosError } from "axios";
import { axiosErrorToApiException, DepotSynchronizationLog } from "@api";
import { useGetAccount } from "@api/v1/hooks/useAccounts";
import { useRollbackDepotSynchronizationLog } from "@api/v1/hooks/useDepotSynchronizationLog";
import { LoadingProgressBar } from "@components";
import { useListAndSearchAllBookings } from "@generated/apiv1/hooks";
import { toDisplayFormat } from "./convertLotItemsToDisplayFormat";
import { AccountHeaderWithRollback } from "./AccountHeaderWithRollback";
import { SynchronizationLogItemList } from "./SynchronizationLogItemList";
import { LogItemLine } from "./LogItemLine";

interface Props {
  depotSynchronizationLog: DepotSynchronizationLog;
}

export const SynchronizationLog = ({ depotSynchronizationLog }: Props) => {
  const [rollbackError, setRollbackError] = useState<
    ApiException | undefined
  >();

  const bookingIds =
    depotSynchronizationLog.logItems?.reduce<number[]>(
      (acc, it) => (it.bookingId ? [...acc, it.bookingId] : acc),
      []
    ) || [];

  const { data: depot, isLoading: isDepotLoading } = useGetAccount(
    depotSynchronizationLog.accountId
  );
  const { data: bookingList, isLoading: areBookingsLoading } =
    useListAndSearchAllBookings(
      {
        id: bookingIds,
      },
      { enabled: bookingIds.length > 0 }
    );
  const {
    mutateAsync: rollbackDepotSynchronizationLog,
    isLoading: rollbackInProgress,
  } = useRollbackDepotSynchronizationLog();

  const handleRollback = () => {
    if (!depotSynchronizationLog?.id) return;
    if (rollbackInProgress || !!depotSynchronizationLog.rolledBackAt) return;
    rollbackDepotSynchronizationLog({
      id: depotSynchronizationLog.id,
    }).catch((ex) => {
      const err = axiosErrorToApiException(ex as AxiosError);
      setRollbackError(err);
    });
  };

  const hasUpdates =
    depotSynchronizationLog.logItems &&
    depotSynchronizationLog.logItems.length > 0;

  if (isDepotLoading || (bookingIds.length > 0 && areBookingsLoading)) {
    return <LoadingProgressBar />;
  }

  const logItems = toDisplayFormat(
    depotSynchronizationLog?.logItems,
    bookingList?.bookings
  );

  return (
    <div>
      <AccountHeaderWithRollback
        onRollback={handleRollback}
        name={depot?.name || "N/A"}
        iban={depot?.iban}
        showRollbackLink={hasUpdates}
        rollbackErrorMessage={rollbackError?.message}
      />

      <SynchronizationLogItemList logItems={logItems} />

      <LogItemLine />
    </div>
  );
};
