import { UseQueryOptions } from "react-query";
import {
  DividendsApiDividendAllocationRequest,
  DividendAllocationList,
} from "@generated/apiv1";

import { buildDividendsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const dividendAllocation = async (
  token: string | undefined,
  params?: DividendsApiDividendAllocationRequest
) => {
  const apiInstance = buildDividendsApi(token);
  const { data } = await apiInstance.dividendAllocation(params);
  return data;
};

export const useDividendAllocation = (
  params?: DividendsApiDividendAllocationRequest,
  options?: UseQueryOptions<
    DividendAllocationList,
    AxiosApiError,
    DividendAllocationList
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.DIVIDENDS_DIVIDEND_ALLOCATION, params],
    ({ token }) => dividendAllocation(token, params),
    options
  );
};
