import { UseMutationOptions } from "react-query";
import { InvestmentsApiInvestmentTransferRequest } from "@generated/apiv1";

import { buildInvestmentsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const investmentTransfer = async (
  token: string | undefined,
  params: InvestmentsApiInvestmentTransferRequest
) => {
  const apiInstance = buildInvestmentsApi(token);
  const { data } = await apiInstance.investmentTransfer(params);
  return data;
};

export const useInvestmentTransfer = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    InvestmentsApiInvestmentTransferRequest
  >
) => {
  return useAuthMutation(investmentTransfer, options, false);
};
