import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as ArrowUp } from "@icons/arrow-up-bold.svg";
import { useListAndSearchAllInvestments } from "@generated/apiv1/hooks";
import { TransactionWizardContext } from "@features/transactionWizard/modal/stateMachine/types/transactionWizardContext";
import { areNonListedAssetsSelected } from "@features/transactionWizard/modal/stateMachine/utils/utils";
import {
  extendedBookings,
  ExtendedBookingType,
  normalBookings,
  sellTypes,
  bookingImagesMap,
} from "@features/transactionWizard/modal/steps/constants";
import { WithLoadingSpinner } from "@components/LoadingSpinner/WithLoadingSpinner";
import { LoadingSpinner } from "@components";

const BookingTypeCard = ({
  bookingType,
  disabled,
  onClick,
  selected,
  numberOfLots,
  isLoading,
}: {
  bookingType: ExtendedBookingType;
  disabled: boolean;
  onClick: () => void;
  selected: boolean;
  numberOfLots?: number;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Grid item sm={6} xs={12}>
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        height={isLessSm ? 84 : 96}
        onClick={disabled ? undefined : onClick}
        sx={(theme) => ({
          borderRadius: "12px",
          opacity: disabled ? 0.5 : 1,
          padding: isLessSm ? theme.spacing(5.5, 4) : theme.spacing(6.25, 4),
          pointerEvent: disabled ? "none" : "auto",
          outline: selected
            ? `1px solid ${theme.palette.primary.main}`
            : "none",
          cursor: disabled ? "initial" : "pointer",
          border: `1px solid ${
            selected
              ? theme.palette.primary.main
              : theme.palette.border.secondary
          }`,

          ...(!disabled && {
            "&:hover": {
              border: selected
                ? `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.primary.lighter}`,
              outline: selected
                ? `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.primary.lighter}`,
            },
          }),
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={40}
          height={40}
          flexShrink={0}
          sx={(theme) => ({
            borderRadius: "12px",
            backgroundColor: selected
              ? theme.palette.background.primary
              : theme.palette.background.info,
            color: selected
              ? theme.palette.background.info
              : theme.palette.icon.active,
          })}
        >
          {isLoading ? (
            <LoadingSpinner width={20} />
          ) : (
            bookingImagesMap[bookingType]
          )}
        </Box>
        <Box>
          <Typography variant="subtitle1">
            {t(`transactionWizardModal.selectBookingType.${bookingType}.title`)}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            fontWeight={400}
            lineHeight="18px"
            mt={0.5}
          >
            {bookingType === "sell" && numberOfLots !== undefined
              ? t(
                  `transactionWizardModal.selectBookingType.sell.${
                    Boolean(numberOfLots)
                      ? "subtitle_amount"
                      : "subtitle_notAllowed"
                  }`,
                  {
                    amount: numberOfLots,
                  }
                )
              : t(
                  `transactionWizardModal.selectBookingType.${bookingType}.subtitle`
                )}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export interface Props {
  handleNext: (bookingType: ExtendedBookingType, investmentId?: number) => void;
  context: TransactionWizardContext;
  isManualTransaction?: boolean;
  isLoading: boolean;
  isLoadingSplit: boolean;
}

export const BookingTypeStep = ({
  handleNext,
  context,
  isLoading,
  isLoadingSplit,
  isManualTransaction,
}: Props) => {
  const isSpecialSelected =
    context.bookingValues.type &&
    extendedBookings.includes(context.bookingValues.type);
  const shouldShowAmount =
    !isManualTransaction && context.investmentValues.type !== "71_massets";
  const [displaySpecials, setDisplaySpecials] = useState(isSpecialSelected);
  const defaultValue = context.bookingValues.type;
  const accountId = context.investmentValues.depotId;
  const tickerSymbol =
    context.sourceInvestmentValues?.tickerSymbol ||
    context.investmentValues.tickerSymbol;
  const isin =
    context.sourceInvestmentValues?.isin || context.investmentValues.isin;
  const quoteProvider =
    context.sourceInvestmentValues?.quoteProvider ||
    context.investmentValues.quoteProvider;

  const { t } = useTranslation();
  const isLessSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [value, setValue] = useState<ExtendedBookingType | undefined>(
    defaultValue
  );
  //const isEnabled = accountId && isin && !context.defaultInvestmentId;
  const isEnabled =
    accountId &&
    (quoteProvider === "crypto" ? tickerSymbol : isin) &&
    !context.defaultInvestmentId;

  const { data, isLoading: isLoadingInvestments } =
    useListAndSearchAllInvestments(
      {
        accountId: [accountId!],
        isin: isin ? [isin] : undefined,
        tickerSymbol:
          quoteProvider === "crypto" && tickerSymbol
            ? [tickerSymbol]
            : undefined,
        includeHistoric: false,
      },
      {
        enabled: Boolean(isEnabled),
      }
    );

  const numberOfLots =
    context.investmentValues.numberOfLots ||
    (isEnabled ? data?.investments?.[0]?.numberOfLots : 0) ||
    0;
  const hasInvested = Boolean(numberOfLots);

  const shouldHideSell =
    isManualTransaction || areNonListedAssetsSelected(context)
      ? false
      : !hasInvested;

  const handleClick = (bookingType: ExtendedBookingType) => {
    if (sellTypes.includes(bookingType) && hasInvested) {
      handleNext(
        bookingType,
        data?.investments[0].id || context.defaultInvestmentId
      );
    }
    handleNext(bookingType);
    setValue(bookingType);
  };

  return (
    <WithLoadingSpinner isLoading={isLoading || isLoadingInvestments}>
      <Box margin={0.25}>
        <Grid container spacing={isLessSm ? 4 : 5.75} mb={5.75}>
          {normalBookings.map((bookingType) => {
            return (
              <BookingTypeCard
                key={bookingType}
                bookingType={bookingType}
                disabled={shouldHideSell && sellTypes.includes(bookingType)}
                onClick={() => handleClick(bookingType)}
                selected={value === bookingType}
                numberOfLots={shouldShowAmount ? numberOfLots : undefined}
              />
            );
          })}
        </Grid>

        <Button
          variant="outlined"
          onClick={() => setDisplaySpecials(!displaySpecials)}
          endIcon={<ArrowUp />}
          fullWidth
          sx={{
            height: 60,
            boxShadow: "none !important",
            borderRadius: "12px",

            "& svg": {
              color: (theme) => theme.palette.icon.primary,
              transform: displaySpecials ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s",
              transformOrigin: "center",
            },
          }}
        >
          {t("transactionWizardModal.selectBookingType.specials")}
        </Button>

        <Collapse in={displaySpecials}>
          <Grid container spacing={5.75} mt={0}>
            {extendedBookings.map((bookingType) => {
              return (
                <BookingTypeCard
                  key={bookingType}
                  bookingType={bookingType}
                  disabled={shouldHideSell && sellTypes.includes(bookingType)}
                  selected={value === bookingType}
                  onClick={() => handleClick(bookingType)}
                  numberOfLots={numberOfLots}
                  isLoading={bookingType === "split" && isLoadingSplit}
                />
              );
            })}
          </Grid>
        </Collapse>
      </Box>
    </WithLoadingSpinner>
  );
};
