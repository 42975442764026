import { useTranslation } from "react-i18next";

export const useMetrics = () => {
  const { t } = useTranslation();

  const financialMetrics = [
    { key: "eps", label: t(`fundamentals.metrics.eps.normal`) },
    {
      key: "totalLiabilities",
      label: t(`fundamentals.metrics.totalLiabilities.normal`),
    },
    {
      key: "operatingCashflow",
      label: t(`fundamentals.metrics.operatingCashflow.normal`),
    },
    { key: "capEx", label: t(`fundamentals.metrics.capEx.normal`) },
    {
      key: "freeCashflow",
      label: t(`fundamentals.metrics.freeCashflow.normal`),
    },
    { key: "totalEquity", label: t(`fundamentals.metrics.totalEquity.normal`) },
    { key: "netIncome", label: t(`fundamentals.metrics.netIncome.normal`) },
    { key: "netRevenue", label: t(`fundamentals.metrics.netRevenue.normal`) },
    {
      key: "outstandingShares",
      label: t(`fundamentals.metrics.outstandingShares.normal`),
    },
    {
      key: "returnOnAssets",
      label: t(`fundamentals.metrics.returnOnAssets.normal`),
    },
    {
      key: "returnOnEquity",
      label: t(`fundamentals.metrics.returnOnEquity.normal`),
    },
  ];

  const statisticMetrics = [
    {
      key: "priceToEarnings",
      label: t(`fundamentals.metrics.priceToEarnings.normal`),
    },
    { key: "priceToBook", label: t(`fundamentals.metrics.priceToBook.normal`) },
    {
      key: "priceToCashflow",
      label: t(`fundamentals.metrics.priceToCashflow.normal`),
    },
    {
      key: "priceToFreeCashflow",
      label: t(`fundamentals.metrics.priceToFreeCashflow.normal`),
    },
    {
      key: "priceToSales",
      label: t(`fundamentals.metrics.priceToSales.normal`),
    },
  ];
  const allMetric = [...financialMetrics, ...statisticMetrics];

  return {
    financialMetrics,
    statisticMetrics,
    allMetric,
  };
};
