import { Currency, Transaction } from "@api";
import { QuoteV2, SymbolV2 } from "@generated/apiv1";
import { AccountSubcategoryEnum } from "@providers/SubcategoriesProvider";
import { BookingValues } from "./bookingValues";
import { SplitValues } from "./splitValues";
import { InvestmentValues } from "./investmentValues";

export interface TransactionWizardContext {
  currencies: Currency[];
  symbol?: SymbolV2 & { quote?: QuoteV2 };
  sepaTransaction?: Transaction;
  error?: ApiException;
  depotSubCategory?: AccountSubcategoryEnum;
  validationError?: string;
  bookingValues: BookingValues;
  bookingValuesToRestore?: BookingValues;
  investmentValues: InvestmentValues;
  splitValues?: SplitValues;
  sourceInvestmentValues?: InvestmentValues;
  manualInvestmentSelected?: boolean;
  isInvestmentRequired?: boolean;
  manualFlow?: boolean;
  defaultDepotId?: number;
  defaultInvestmentId?: number;
}

export const defaultContext: TransactionWizardContext = {
  currencies: [],
  symbol: undefined,
  sepaTransaction: undefined,
  error: undefined,
  bookingValues: {
    date: new Date(),
    commission: 0,
    taxAmount: 0,
    exchangeRate: 1,
  },
  bookingValuesToRestore: undefined,
  investmentValues: {
    investmentCurrency: "EUR",
  },
  splitValues: {
    date: new Date(),
    isReverse: false,
  },
};
