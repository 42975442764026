import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { CreateDividendWizard } from "./CreateDividendWizard";

export const CreateDividendWizardWithRouting = () => {
  const { investmentId } = useParams<"investmentId">();
  const query = new URLSearchParams(useLocation().search);

  return (
    <CreateDividendWizard
      investmentId={Number.parseInt(investmentId!, 10)}
      date={query.get("date")}
    />
  );
};
