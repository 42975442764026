import { UseMutationOptions } from "react-query";
import {
  ApplicationPropertiesApiCreateApplicationPropertyRequest,
  ApplicationProperty,
} from "@generated/apiv1";

import { buildApplicationPropertiesApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const createApplicationProperty = async (
  token: string | undefined,
  params: ApplicationPropertiesApiCreateApplicationPropertyRequest
) => {
  const apiInstance = buildApplicationPropertiesApi(token);
  const { data } = await apiInstance.createApplicationProperty(params);
  return data;
};

export const useCreateApplicationProperty = (
  options?: UseMutationOptions<
    ApplicationProperty,
    AxiosApiError,
    ApplicationPropertiesApiCreateApplicationPropertyRequest
  >
) => {
  return useAuthMutation(createApplicationProperty, options, false);
};
