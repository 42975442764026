import React from "react";
import styled from "@emotion/styled";
import { Typography, TypographyVariant } from "@mui/material";
import { toPercent } from "@helpers";

type PercentType = "positive" | "negative" | "zero";

const Container = styled(Typography)<{ type: PercentType }>`
  color: ${({ theme, type }) => {
    if (type === "positive") {
      return theme.palette.text.positive;
    }
    if (type === "negative") {
      return theme.palette.text.negative;
    }
    return theme.palette.text.primary;
  }};
`;

export interface PercentProps {
  className?: string;
  percent: number;
  variant?: TypographyVariant;
}

export const Percent = ({
  percent,
  variant = "h5",
  className,
}: PercentProps) => {
  let percentType: PercentType = "zero";
  let sign = "";
  if (percent > 0) {
    percentType = "positive";
    sign = "+";
  }
  if (percent < 0) {
    percentType = "negative";
    sign = "-";
  }

  const formattedPercent = `${sign} ${toPercent(Math.abs(percent))}`;
  return (
    <Container
      variant={variant}
      type={percentType}
      noWrap
      className={className}
    >
      {formattedPercent}
    </Container>
  );
};
