import { toString } from "@helpers";
import { ExtendedBookingType } from "@features/transactionWizard/modal/steps/constants";

const hasTax = (type?: ExtendedBookingType) =>
  type
    ? ["sell", "dividend", "retained_dividend", "purchase_right"].includes(type)
    : false;

const isCapitalMeasure = (type?: ExtendedBookingType) =>
  type ? ["carve_out", "switch", "fusion"].includes(type) : false;

const parseOrUndefined = (value: string) => {
  try {
    if (!value || value === "") return undefined;
    if (value.endsWith(",") || value.endsWith(".")) return undefined;
    // do not use replaceAll because node does not implement it and it will fail in jest
    const usFormat = value.replace(/,/g, ".");
    return parseFloat(usFormat);
  } catch (err) {
    return undefined;
  }
};

const hasSecurityPrice = (type?: ExtendedBookingType) => {
  if (!type) return false;
  return type === "book_in";
};

export const useHelpers = () => {
  return {
    toString,
    isCapitalMeasure,
    parseOrUndefined,
    hasTax,
    hasSecurityPrice,
  };
};
