import { UseMutationOptions } from "react-query";
import { AuthApiRefreshTokenRequest, LoginData } from "@generated/user";

import { buildAuthApi } from "@generated/user/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const refreshToken = async (
  token: string | undefined,
  params: AuthApiRefreshTokenRequest
) => {
  const apiInstance = buildAuthApi(token);
  const { data } = await apiInstance.refreshToken(params);
  return data;
};

export const useRefreshToken = (
  options?: UseMutationOptions<
    LoginData,
    AxiosApiError,
    AuthApiRefreshTokenRequest
  >
) => {
  return useAuthMutation(refreshToken, options, true);
};
