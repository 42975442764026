import { UseMutationOptions } from "react-query";
import { AccountsApiDeleteSelectGroupRequest } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteSelectGroup = async (
  token: string | undefined,
  params: AccountsApiDeleteSelectGroupRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.deleteSelectGroup(params);
  return data;
};

export const useDeleteSelectGroup = (
  options?: UseMutationOptions<
    void,
    AxiosApiError,
    AccountsApiDeleteSelectGroupRequest
  >
) => {
  return useAuthMutation(deleteSelectGroup, options, false);
};
