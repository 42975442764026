import React from "react";
import { useParams } from "react-router-dom";
import { InvestmentEditPage } from "./InvestmentEditPage";

export const InvestmentEditByParamPage = () => {
  const { investmentId } = useParams<"investmentId">();
  return (
    <InvestmentEditPage investmentId={Number.parseInt(investmentId!, 10)} />
  );
};
