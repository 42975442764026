import { useTheme } from "@emotion/react";
import React from "react";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { darken } from "@mui/material";

export interface PercentChartProps {
  percent: number;
  className?: string;
  isSelected?: boolean;
  color?: string;
}

export const PercentChart = ({
  percent,
  className,
  isSelected,
  color,
}: PercentChartProps) => {
  const theme = useTheme();
  const data = [{ name: "L1", value: percent }];

  return (
    <RadialBarChart
      width={54}
      height={54}
      cx="50%"
      cy="50%"
      innerRadius={"80%"}
      outerRadius={"100%"}
      barSize={5}
      data={data}
      margin={{ bottom: 0, left: 0, top: 0, right: 0 }}
      startAngle={90}
      endAngle={-270}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        className={className}
        animationDuration={300}
        background={{
          fill: isSelected
            ? theme.palette.graphVisual["08"].mid
            : darken(theme.palette.common.white, 0.05),
        }}
        fill={color}
        dataKey="value"
        angleAxisId={0}
        cornerRadius={16}
      />
      <text
        x={28}
        y={28}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12px"
        fontFamily="Averta"
        fontWeight={400}
        fill={theme.palette.text.primary}
      >
        {percent}%
      </text>
    </RadialBarChart>
  );
};
