/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Fund } from '../model';
// @ts-ignore
import { PageableFundList } from '../model';
/**
 * FundsApi - axios parameter creator
 * @export
 */
export const FundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search funds by name, ISIN or WKN.
         * @param {string} [search] Retrieve funds whose name, ISIN or WKN matches the given search string
         * @param {boolean} [strict] Strict mode: require an exact match; only applicable when using the custom search
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {Array<string>} [id] Retrieve Funds with the given IDs
         * @param {Array<string>} [wkn] Retrieve Funds with the given WKNs
         * @param {Array<string>} [isin] Retrieve Funds with the given ISINs
         * @param {Array<string>} [type] Limit the search to the given fund type, supported values are MANAGED and ETF
         * @param {boolean} [withoutCourtage] Also include funds not having a courtage record in the result set; default is false
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllFunds: async (search?: string, strict?: boolean, includeCommissions?: boolean, id?: Array<string>, wkn?: Array<string>, isin?: Array<string>, type?: Array<string>, withoutCourtage?: boolean, page?: number, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (strict !== undefined) {
                localVarQueryParameter['strict'] = strict;
            }

            if (includeCommissions !== undefined) {
                localVarQueryParameter['includeCommissions'] = includeCommissions;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (wkn) {
                localVarQueryParameter['wkn'] = wkn;
            }

            if (isin) {
                localVarQueryParameter['isin'] = isin;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (withoutCourtage !== undefined) {
                localVarQueryParameter['withoutCourtage'] = withoutCourtage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get funds held by the mandators users, sorted by popularity (most popular first).
         * @param {string} mandatorPublicId Get statistics for the given mandator
         * @param {Array<string>} [type] Filter for the given types, allowed values are \&#39;21_fund\&#39; and \&#39;22_etf\&#39;
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mostPopular: async (mandatorPublicId: string, type?: Array<string>, page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mandatorPublicId' is not null or undefined
            assertParamExists('mostPopular', 'mandatorPublicId', mandatorPublicId)
            const localVarPath = `/v1/funds/mostPopular`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mandatorPublicId !== undefined) {
                localVarQueryParameter['mandatorPublicId'] = mandatorPublicId;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runFwwImport1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/funds/runFwwImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given fund.
         * @param {string} id Get the fund with the given ID
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {boolean} [includeFundamentals] Include fundamental data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showFund: async (id: string, includeCommissions?: boolean, includeFundamentals?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showFund', 'id', id)
            const localVarPath = `/v1/funds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCommissions !== undefined) {
                localVarQueryParameter['includeCommissions'] = includeCommissions;
            }

            if (includeFundamentals !== undefined) {
                localVarQueryParameter['includeFundamentals'] = includeFundamentals;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the given fund by ISIN
         * @param {string} isin Get the fund with the given ISIN
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {boolean} [includeFundamentals] Include fundamental data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showFundByIsin: async (isin: string, includeCommissions?: boolean, includeFundamentals?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('showFundByIsin', 'isin', isin)
            const localVarPath = `/v1/funds/byIsin/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCommissions !== undefined) {
                localVarQueryParameter['includeCommissions'] = includeCommissions;
            }

            if (includeFundamentals !== undefined) {
                localVarQueryParameter['includeFundamentals'] = includeFundamentals;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundsApi - functional programming interface
 * @export
 */
export const FundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search funds by name, ISIN or WKN.
         * @param {string} [search] Retrieve funds whose name, ISIN or WKN matches the given search string
         * @param {boolean} [strict] Strict mode: require an exact match; only applicable when using the custom search
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {Array<string>} [id] Retrieve Funds with the given IDs
         * @param {Array<string>} [wkn] Retrieve Funds with the given WKNs
         * @param {Array<string>} [isin] Retrieve Funds with the given ISINs
         * @param {Array<string>} [type] Limit the search to the given fund type, supported values are MANAGED and ETF
         * @param {boolean} [withoutCourtage] Also include funds not having a courtage record in the result set; default is false
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAndSearchAllFunds(search?: string, strict?: boolean, includeCommissions?: boolean, id?: Array<string>, wkn?: Array<string>, isin?: Array<string>, type?: Array<string>, withoutCourtage?: boolean, page?: number, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableFundList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAndSearchAllFunds(search, strict, includeCommissions, id, wkn, isin, type, withoutCourtage, page, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundsApi.listAndSearchAllFunds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get funds held by the mandators users, sorted by popularity (most popular first).
         * @param {string} mandatorPublicId Get statistics for the given mandator
         * @param {Array<string>} [type] Filter for the given types, allowed values are \&#39;21_fund\&#39; and \&#39;22_etf\&#39;
         * @param {number} [page] Result page that you want to retrieve.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mostPopular(mandatorPublicId: string, type?: Array<string>, page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageableFundList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mostPopular(mandatorPublicId, type, page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundsApi.mostPopular']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runFwwImport1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runFwwImport1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundsApi.runFwwImport1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given fund.
         * @param {string} id Get the fund with the given ID
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {boolean} [includeFundamentals] Include fundamental data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showFund(id: string, includeCommissions?: boolean, includeFundamentals?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fund>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showFund(id, includeCommissions, includeFundamentals, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundsApi.showFund']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the given fund by ISIN
         * @param {string} isin Get the fund with the given ISIN
         * @param {boolean} [includeCommissions] Include commissions (courtages) if true; default is false.
         * @param {boolean} [includeFundamentals] Include fundamental data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showFundByIsin(isin: string, includeCommissions?: boolean, includeFundamentals?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fund>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showFundByIsin(isin, includeCommissions, includeFundamentals, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundsApi.showFundByIsin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FundsApi - factory interface
 * @export
 */
export const FundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundsApiFp(configuration)
    return {
        /**
         * 
         * @summary Search funds by name, ISIN or WKN.
         * @param {FundsApiListAndSearchAllFundsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAndSearchAllFunds(requestParameters: FundsApiListAndSearchAllFundsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageableFundList> {
            return localVarFp.listAndSearchAllFunds(requestParameters.search, requestParameters.strict, requestParameters.includeCommissions, requestParameters.id, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.withoutCourtage, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get funds held by the mandators users, sorted by popularity (most popular first).
         * @param {FundsApiMostPopularRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mostPopular(requestParameters: FundsApiMostPopularRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageableFundList> {
            return localVarFp.mostPopular(requestParameters.mandatorPublicId, requestParameters.type, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runFwwImport1(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runFwwImport1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given fund.
         * @param {FundsApiShowFundRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showFund(requestParameters: FundsApiShowFundRequest, options?: RawAxiosRequestConfig): AxiosPromise<Fund> {
            return localVarFp.showFund(requestParameters.id, requestParameters.includeCommissions, requestParameters.includeFundamentals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the given fund by ISIN
         * @param {FundsApiShowFundByIsinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showFundByIsin(requestParameters: FundsApiShowFundByIsinRequest, options?: RawAxiosRequestConfig): AxiosPromise<Fund> {
            return localVarFp.showFundByIsin(requestParameters.isin, requestParameters.includeCommissions, requestParameters.includeFundamentals, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listAndSearchAllFunds operation in FundsApi.
 * @export
 * @interface FundsApiListAndSearchAllFundsRequest
 */
export interface FundsApiListAndSearchAllFundsRequest {
    /**
     * Retrieve funds whose name, ISIN or WKN matches the given search string
     * @type {string}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly search?: string

    /**
     * Strict mode: require an exact match; only applicable when using the custom search
     * @type {boolean}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly strict?: boolean

    /**
     * Include commissions (courtages) if true; default is false.
     * @type {boolean}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly includeCommissions?: boolean

    /**
     * Retrieve Funds with the given IDs
     * @type {Array<string>}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly id?: Array<string>

    /**
     * Retrieve Funds with the given WKNs
     * @type {Array<string>}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly wkn?: Array<string>

    /**
     * Retrieve Funds with the given ISINs
     * @type {Array<string>}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly isin?: Array<string>

    /**
     * Limit the search to the given fund type, supported values are MANAGED and ETF
     * @type {Array<string>}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly type?: Array<string>

    /**
     * Also include funds not having a courtage record in the result set; default is false
     * @type {boolean}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly withoutCourtage?: boolean

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof FundsApiListAndSearchAllFunds
     */
    readonly order?: string
}

/**
 * Request parameters for mostPopular operation in FundsApi.
 * @export
 * @interface FundsApiMostPopularRequest
 */
export interface FundsApiMostPopularRequest {
    /**
     * Get statistics for the given mandator
     * @type {string}
     * @memberof FundsApiMostPopular
     */
    readonly mandatorPublicId: string

    /**
     * Filter for the given types, allowed values are \&#39;21_fund\&#39; and \&#39;22_etf\&#39;
     * @type {Array<string>}
     * @memberof FundsApiMostPopular
     */
    readonly type?: Array<string>

    /**
     * Result page that you want to retrieve.
     * @type {number}
     * @memberof FundsApiMostPopular
     */
    readonly page?: number

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof FundsApiMostPopular
     */
    readonly perPage?: number
}

/**
 * Request parameters for showFund operation in FundsApi.
 * @export
 * @interface FundsApiShowFundRequest
 */
export interface FundsApiShowFundRequest {
    /**
     * Get the fund with the given ID
     * @type {string}
     * @memberof FundsApiShowFund
     */
    readonly id: string

    /**
     * Include commissions (courtages) if true; default is false.
     * @type {boolean}
     * @memberof FundsApiShowFund
     */
    readonly includeCommissions?: boolean

    /**
     * Include fundamental data
     * @type {boolean}
     * @memberof FundsApiShowFund
     */
    readonly includeFundamentals?: boolean
}

/**
 * Request parameters for showFundByIsin operation in FundsApi.
 * @export
 * @interface FundsApiShowFundByIsinRequest
 */
export interface FundsApiShowFundByIsinRequest {
    /**
     * Get the fund with the given ISIN
     * @type {string}
     * @memberof FundsApiShowFundByIsin
     */
    readonly isin: string

    /**
     * Include commissions (courtages) if true; default is false.
     * @type {boolean}
     * @memberof FundsApiShowFundByIsin
     */
    readonly includeCommissions?: boolean

    /**
     * Include fundamental data
     * @type {boolean}
     * @memberof FundsApiShowFundByIsin
     */
    readonly includeFundamentals?: boolean
}

/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
export class FundsApi extends BaseAPI {
    /**
     * 
     * @summary Search funds by name, ISIN or WKN.
     * @param {FundsApiListAndSearchAllFundsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public listAndSearchAllFunds(requestParameters: FundsApiListAndSearchAllFundsRequest = {}, options?: RawAxiosRequestConfig) {
        return FundsApiFp(this.configuration).listAndSearchAllFunds(requestParameters.search, requestParameters.strict, requestParameters.includeCommissions, requestParameters.id, requestParameters.wkn, requestParameters.isin, requestParameters.type, requestParameters.withoutCourtage, requestParameters.page, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get funds held by the mandators users, sorted by popularity (most popular first).
     * @param {FundsApiMostPopularRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public mostPopular(requestParameters: FundsApiMostPopularRequest, options?: RawAxiosRequestConfig) {
        return FundsApiFp(this.configuration).mostPopular(requestParameters.mandatorPublicId, requestParameters.type, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public runFwwImport1(options?: RawAxiosRequestConfig) {
        return FundsApiFp(this.configuration).runFwwImport1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given fund.
     * @param {FundsApiShowFundRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public showFund(requestParameters: FundsApiShowFundRequest, options?: RawAxiosRequestConfig) {
        return FundsApiFp(this.configuration).showFund(requestParameters.id, requestParameters.includeCommissions, requestParameters.includeFundamentals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the given fund by ISIN
     * @param {FundsApiShowFundByIsinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public showFundByIsin(requestParameters: FundsApiShowFundByIsinRequest, options?: RawAxiosRequestConfig) {
        return FundsApiFp(this.configuration).showFundByIsin(requestParameters.isin, requestParameters.includeCommissions, requestParameters.includeFundamentals, options).then((request) => request(this.axios, this.basePath));
    }
}

