import { UseMutationOptions } from "react-query";
import {
  BankConnectionsApiAuthenticateAtBankRequest,
  BankAuthenticationProcess,
} from "@generated/apiv3";

import { buildBankConnectionsApi } from "@generated/apiv3/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const authenticateAtBank = async (
  token: string | undefined,
  params: BankConnectionsApiAuthenticateAtBankRequest
) => {
  const apiInstance = buildBankConnectionsApi(token);
  const { data } = await apiInstance.authenticateAtBank(params);
  return data;
};

export const useAuthenticateAtBank = (
  options?: UseMutationOptions<
    BankAuthenticationProcess,
    AxiosApiError,
    BankConnectionsApiAuthenticateAtBankRequest
  >
) => {
  return useAuthMutation(authenticateAtBank, options, false);
};
