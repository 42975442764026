import React from "react";
import { useDividendsPerYear } from "../../hooks/useDividendsPerYear";
import { DividendsBaseChart } from "./DividendsBaseChart";

interface Props {
  isin?: string;
  currency?: string;
}

export const DividendsChart = ({ isin, currency }: Props) => {
  const { data: dividends, isLoading } = useDividendsPerYear(isin, currency);

  return <DividendsBaseChart dividends={dividends} isLoading={isLoading} />;
};
