import { UseQueryOptions } from "react-query";
import {
  AccountsApiFundCommissionsRequest,
  AggregatedFundCommissionStatistics,
} from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundCommissions = async (
  token: string | undefined,
  params?: AccountsApiFundCommissionsRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.fundCommissions(params);
  return data;
};

export const useFundCommissions = (
  params?: AccountsApiFundCommissionsRequest,
  options?: UseQueryOptions<
    AggregatedFundCommissionStatistics,
    AxiosApiError,
    AggregatedFundCommissionStatistics
  >
) => {
  return useAuthQuery(
    [CACHE_KEYS.ACCOUNTS_FUND_COMMISSIONS, params],
    ({ token }) => fundCommissions(token, params),
    options
  );
};
