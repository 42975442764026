import { UseQueryOptions } from "react-query";
import {
  QuotesApiGetSecurityRequest,
  SecurityResponse,
} from "@generated/apiv3/quotes";

import { buildQuotesApi } from "@generated/apiv3/quotes/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv3/quotes/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const getSecurity = async (
  token: string | undefined,
  params: QuotesApiGetSecurityRequest
) => {
  const apiInstance = buildQuotesApi(token);
  const { data } = await apiInstance.getSecurity(params);
  return data;
};

export const useGetSecurity = (
  params: QuotesApiGetSecurityRequest,
  options?: UseQueryOptions<SecurityResponse, AxiosApiError, SecurityResponse>
) => {
  return useAuthQuery(
    [CACHE_KEYS.QUOTES_GET_SECURITY, params],
    ({ token }) => getSecurity(token, params),
    options
  );
};
