import React from "react";
import { Box } from "@mui/material";
import { LoadingPlaceHolder } from "@components";
import { useFund } from "./hooks/useFund";
import { SecurityFundamentalsByIsin } from "./components/SecurityFundamentals/SecurityFundamentalsByIsin";
import { FundFundamentalsByIsin } from "./components/FundFundamentals/FundFundamentalsByIsin";

export interface Props {
  watchlistEntryId?: number;
  quoteProvider: string;
  tickerSymbol: string;
  isin: string;
}

export const FundamentalsPageByIsin = (props: Props) => {
  const { data: fundData, isLoading: isFundLoading } = useFund(props.isin);

  const isInvestmentFund = !!fundData;

  if (isFundLoading) {
    return (
      <Box mt={6}>
        <LoadingPlaceHolder />
      </Box>
    );
  }

  return isInvestmentFund ? (
    <FundFundamentalsByIsin {...props} />
  ) : (
    <SecurityFundamentalsByIsin {...props} />
  );
};
