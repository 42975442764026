import { AxiosError } from "axios";

export const axiosErrorToApiExceptionMessage = (
  axiosError: AxiosError
): string | undefined => {
  return (axiosError as any).response?.data?.message;
};

export const axiosErrorToApiException = (
  axiosError: AxiosError
): ApiException => {
  return axiosError?.response?.data as ApiException;
};
