import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BankImage = styled(Box)(({ theme }) => ({
  borderRadius: "6px",
  overflow: "hidden",
  width: "40px",
  height: "40px",
  flexShrink: 0,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.border.secondary}`,

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));
