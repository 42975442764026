/* tslint:disable */
/* eslint-disable */
/**
 * wealthAPI SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BankCatalogue } from '../model';
// @ts-ignore
import { BankWrapper } from '../model';
// @ts-ignore
import { BankWrapperList } from '../model';
// @ts-ignore
import { CreateInterfaceSelectionOptionParams } from '../model';
// @ts-ignore
import { InterfaceSelectionOption } from '../model';
// @ts-ignore
import { InterfaceSelectionOptionList } from '../model';
// @ts-ignore
import { UpdateInterfaceSelectionOptionParams } from '../model';
/**
 * BanksApi - axios parameter creator
 * @export
 */
export const BanksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the bank\'s icon (if any) by BIC
         * @param {string} id BIC to retrieve icon for
         * @param {number} [accountTypeId] A account type ID as defined by finAPI as fallback. In case no logo was found we use a                              standard logo matching the given type ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIcon: async (id: string, accountTypeId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankIcon', 'id', id)
            const localVarPath = `/v1/banks/icon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountTypeId !== undefined) {
                localVarQueryParameter['accountTypeId'] = accountTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a bank by the given search string
         * @param {string} search Name / BIC / BLZ of the bank to search (wildcard searches are supported)
         * @param {Array<BanksMultibankingProviderEnum>} [multibankingProvider] Filter results by the given multi-banking providers; default: no filter
         * @param {string} [includeDuplicates] Include banks with duplicated BICs (but most likely different BLZ) if true; default is false.
         * @param {Array<string>} [filterBic] Only include banks having the given BICs. May be combined with filterBicGroup and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {Array<string>} [filterBicGroup] Only include banks having the given BIC groups. May be combined with filterBic and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {Array<string>} [filterName] Only include banks matching the given name (wildcard). May be combined with filterBic and filterBicGroup.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banks: async (search: string, multibankingProvider?: Array<BanksMultibankingProviderEnum>, includeDuplicates?: string, filterBic?: Array<string>, filterBicGroup?: Array<string>, filterName?: Array<string>, perPage?: number, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('banks', 'search', search)
            const localVarPath = `/v1/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (multibankingProvider) {
                localVarQueryParameter['multibankingProvider'] = multibankingProvider;
            }

            if (includeDuplicates !== undefined) {
                localVarQueryParameter['includeDuplicates'] = includeDuplicates;
            }

            if (filterBic) {
                localVarQueryParameter['filterBic'] = filterBic;
            }

            if (filterBicGroup) {
                localVarQueryParameter['filterBicGroup'] = filterBicGroup;
            }

            if (filterName) {
                localVarQueryParameter['filterName'] = filterName;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a bank by the given ID.
         * @param {number} id ID of the bank.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banksId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('banksId', 'id', id)
            const localVarPath = `/v1/banks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the bank catalogue
         * @param {string} [bankName] Filter banks by name which start with given string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogue: async (bankName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/banks/catalogue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bankName !== undefined) {
                localVarQueryParameter['bankName'] = bankName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a bank interface selection option
         * @param {CreateInterfaceSelectionOptionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterfaceSelectionOption: async (body: CreateInterfaceSelectionOptionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createInterfaceSelectionOption', 'body', body)
            const localVarPath = `/v1/banks/interfaceSelectionOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the given bank interface selection option
         * @param {number} id ID of the option to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankInterfaceSelectionOption: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBankInterfaceSelectionOption', 'id', id)
            const localVarPath = `/v1/banks/interfaceSelectionOptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a bank interface selection option by the given ID.
         * @param {number} id ID of the bank interface selection option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfaceSelectionOption: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('interfaceSelectionOption', 'id', id)
            const localVarPath = `/v1/banks/interfaceSelectionOptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List and filter bank interface selection options
         * @param {Array<string>} [bicGroup] Get interface selection options for the given BIC groups; default: all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfaceSelectionOptions: async (bicGroup?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/banks/interfaceSelectionOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bicGroup) {
                localVarQueryParameter['bicGroup'] = bicGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the given bank interface selection option
         * @param {number} id ID of the bank interface selection option to update.
         * @param {UpdateInterfaceSelectionOptionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterfaceSelectionOption: async (id: number, body: UpdateInterfaceSelectionOptionParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInterfaceSelectionOption', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateInterfaceSelectionOption', 'body', body)
            const localVarPath = `/v1/banks/interfaceSelectionOptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BanksApi - functional programming interface
 * @export
 */
export const BanksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BanksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the bank\'s icon (if any) by BIC
         * @param {string} id BIC to retrieve icon for
         * @param {number} [accountTypeId] A account type ID as defined by finAPI as fallback. In case no logo was found we use a                              standard logo matching the given type ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIcon(id: string, accountTypeId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankIcon(id, accountTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.bankIcon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a bank by the given search string
         * @param {string} search Name / BIC / BLZ of the bank to search (wildcard searches are supported)
         * @param {Array<BanksMultibankingProviderEnum>} [multibankingProvider] Filter results by the given multi-banking providers; default: no filter
         * @param {string} [includeDuplicates] Include banks with duplicated BICs (but most likely different BLZ) if true; default is false.
         * @param {Array<string>} [filterBic] Only include banks having the given BICs. May be combined with filterBicGroup and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {Array<string>} [filterBicGroup] Only include banks having the given BIC groups. May be combined with filterBic and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {Array<string>} [filterName] Only include banks matching the given name (wildcard). May be combined with filterBic and filterBicGroup.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
         * @param {number} [perPage] Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
         * @param {string} [order] Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banks(search: string, multibankingProvider?: Array<BanksMultibankingProviderEnum>, includeDuplicates?: string, filterBic?: Array<string>, filterBicGroup?: Array<string>, filterName?: Array<string>, perPage?: number, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankWrapperList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banks(search, multibankingProvider, includeDuplicates, filterBic, filterBicGroup, filterName, perPage, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.banks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a bank by the given ID.
         * @param {number} id ID of the bank.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banksId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banksId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.banksId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the bank catalogue
         * @param {string} [bankName] Filter banks by name which start with given string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogue(bankName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankCatalogue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogue(bankName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.catalogue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a bank interface selection option
         * @param {CreateInterfaceSelectionOptionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInterfaceSelectionOption(body: CreateInterfaceSelectionOptionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceSelectionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInterfaceSelectionOption(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.createInterfaceSelectionOption']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the given bank interface selection option
         * @param {number} id ID of the option to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankInterfaceSelectionOption(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankInterfaceSelectionOption(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.deleteBankInterfaceSelectionOption']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a bank interface selection option by the given ID.
         * @param {number} id ID of the bank interface selection option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfaceSelectionOption(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceSelectionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfaceSelectionOption(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.interfaceSelectionOption']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List and filter bank interface selection options
         * @param {Array<string>} [bicGroup] Get interface selection options for the given BIC groups; default: all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interfaceSelectionOptions(bicGroup?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceSelectionOptionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interfaceSelectionOptions(bicGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.interfaceSelectionOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the given bank interface selection option
         * @param {number} id ID of the bank interface selection option to update.
         * @param {UpdateInterfaceSelectionOptionParams} body The transaction parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterfaceSelectionOption(id: number, body: UpdateInterfaceSelectionOptionParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceSelectionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterfaceSelectionOption(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanksApi.updateInterfaceSelectionOption']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BanksApi - factory interface
 * @export
 */
export const BanksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BanksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the bank\'s icon (if any) by BIC
         * @param {BanksApiBankIconRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIcon(requestParameters: BanksApiBankIconRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.bankIcon(requestParameters.id, requestParameters.accountTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a bank by the given search string
         * @param {BanksApiBanksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banks(requestParameters: BanksApiBanksRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankWrapperList> {
            return localVarFp.banks(requestParameters.search, requestParameters.multibankingProvider, requestParameters.includeDuplicates, requestParameters.filterBic, requestParameters.filterBicGroup, requestParameters.filterName, requestParameters.perPage, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a bank by the given ID.
         * @param {BanksApiBanksIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banksId(requestParameters: BanksApiBanksIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankWrapper> {
            return localVarFp.banksId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the bank catalogue
         * @param {BanksApiCatalogueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogue(requestParameters: BanksApiCatalogueRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BankCatalogue> {
            return localVarFp.catalogue(requestParameters.bankName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a bank interface selection option
         * @param {BanksApiCreateInterfaceSelectionOptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterfaceSelectionOption(requestParameters: BanksApiCreateInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<InterfaceSelectionOption> {
            return localVarFp.createInterfaceSelectionOption(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the given bank interface selection option
         * @param {BanksApiDeleteBankInterfaceSelectionOptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankInterfaceSelectionOption(requestParameters: BanksApiDeleteBankInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBankInterfaceSelectionOption(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a bank interface selection option by the given ID.
         * @param {BanksApiInterfaceSelectionOptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfaceSelectionOption(requestParameters: BanksApiInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<InterfaceSelectionOption> {
            return localVarFp.interfaceSelectionOption(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List and filter bank interface selection options
         * @param {BanksApiInterfaceSelectionOptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interfaceSelectionOptions(requestParameters: BanksApiInterfaceSelectionOptionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InterfaceSelectionOptionList> {
            return localVarFp.interfaceSelectionOptions(requestParameters.bicGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the given bank interface selection option
         * @param {BanksApiUpdateInterfaceSelectionOptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterfaceSelectionOption(requestParameters: BanksApiUpdateInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<InterfaceSelectionOption> {
            return localVarFp.updateInterfaceSelectionOption(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankIcon operation in BanksApi.
 * @export
 * @interface BanksApiBankIconRequest
 */
export interface BanksApiBankIconRequest {
    /**
     * BIC to retrieve icon for
     * @type {string}
     * @memberof BanksApiBankIcon
     */
    readonly id: string

    /**
     * A account type ID as defined by finAPI as fallback. In case no logo was found we use a                              standard logo matching the given type ID.
     * @type {number}
     * @memberof BanksApiBankIcon
     */
    readonly accountTypeId?: number
}

/**
 * Request parameters for banks operation in BanksApi.
 * @export
 * @interface BanksApiBanksRequest
 */
export interface BanksApiBanksRequest {
    /**
     * Name / BIC / BLZ of the bank to search (wildcard searches are supported)
     * @type {string}
     * @memberof BanksApiBanks
     */
    readonly search: string

    /**
     * Filter results by the given multi-banking providers; default: no filter
     * @type {Array<'WEALTH_API' | 'FIN_API'>}
     * @memberof BanksApiBanks
     */
    readonly multibankingProvider?: Array<BanksMultibankingProviderEnum>

    /**
     * Include banks with duplicated BICs (but most likely different BLZ) if true; default is false.
     * @type {string}
     * @memberof BanksApiBanks
     */
    readonly includeDuplicates?: string

    /**
     * Only include banks having the given BICs. May be combined with filterBicGroup and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
     * @type {Array<string>}
     * @memberof BanksApiBanks
     */
    readonly filterBic?: Array<string>

    /**
     * Only include banks having the given BIC groups. May be combined with filterBic and filterName.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
     * @type {Array<string>}
     * @memberof BanksApiBanks
     */
    readonly filterBicGroup?: Array<string>

    /**
     * Only include banks matching the given name (wildcard). May be combined with filterBic and filterBicGroup.                             Adding other filters will increase the result set by the matches from other filters.                             Default is no filter.
     * @type {Array<string>}
     * @memberof BanksApiBanks
     */
    readonly filterName?: Array<string>

    /**
     * Maximum number of records per page. Can be at most 500. NOTE: Due to its validation                              and visualization, the swagger frontend might show very low performance, or even crashes,                              when a service responds with a lot of data. It is recommended to use a HTTP client like                              Postman or DHC instead of our swagger frontend for service calls with large page sizes.
     * @type {number}
     * @memberof BanksApiBanks
     */
    readonly perPage?: number

    /**
     * Determines the order of the results. You can use any of the given query parameters for ordering.                              The default order for all services is \&#39;id asc\&#39;.
     * @type {string}
     * @memberof BanksApiBanks
     */
    readonly order?: string
}

/**
 * Request parameters for banksId operation in BanksApi.
 * @export
 * @interface BanksApiBanksIdRequest
 */
export interface BanksApiBanksIdRequest {
    /**
     * ID of the bank.
     * @type {number}
     * @memberof BanksApiBanksId
     */
    readonly id: number
}

/**
 * Request parameters for catalogue operation in BanksApi.
 * @export
 * @interface BanksApiCatalogueRequest
 */
export interface BanksApiCatalogueRequest {
    /**
     * Filter banks by name which start with given string.
     * @type {string}
     * @memberof BanksApiCatalogue
     */
    readonly bankName?: string
}

/**
 * Request parameters for createInterfaceSelectionOption operation in BanksApi.
 * @export
 * @interface BanksApiCreateInterfaceSelectionOptionRequest
 */
export interface BanksApiCreateInterfaceSelectionOptionRequest {
    /**
     * The transaction parameters
     * @type {CreateInterfaceSelectionOptionParams}
     * @memberof BanksApiCreateInterfaceSelectionOption
     */
    readonly body: CreateInterfaceSelectionOptionParams
}

/**
 * Request parameters for deleteBankInterfaceSelectionOption operation in BanksApi.
 * @export
 * @interface BanksApiDeleteBankInterfaceSelectionOptionRequest
 */
export interface BanksApiDeleteBankInterfaceSelectionOptionRequest {
    /**
     * ID of the option to delete
     * @type {number}
     * @memberof BanksApiDeleteBankInterfaceSelectionOption
     */
    readonly id: number
}

/**
 * Request parameters for interfaceSelectionOption operation in BanksApi.
 * @export
 * @interface BanksApiInterfaceSelectionOptionRequest
 */
export interface BanksApiInterfaceSelectionOptionRequest {
    /**
     * ID of the bank interface selection option.
     * @type {number}
     * @memberof BanksApiInterfaceSelectionOption
     */
    readonly id: number
}

/**
 * Request parameters for interfaceSelectionOptions operation in BanksApi.
 * @export
 * @interface BanksApiInterfaceSelectionOptionsRequest
 */
export interface BanksApiInterfaceSelectionOptionsRequest {
    /**
     * Get interface selection options for the given BIC groups; default: all
     * @type {Array<string>}
     * @memberof BanksApiInterfaceSelectionOptions
     */
    readonly bicGroup?: Array<string>
}

/**
 * Request parameters for updateInterfaceSelectionOption operation in BanksApi.
 * @export
 * @interface BanksApiUpdateInterfaceSelectionOptionRequest
 */
export interface BanksApiUpdateInterfaceSelectionOptionRequest {
    /**
     * ID of the bank interface selection option to update.
     * @type {number}
     * @memberof BanksApiUpdateInterfaceSelectionOption
     */
    readonly id: number

    /**
     * The transaction parameters
     * @type {UpdateInterfaceSelectionOptionParams}
     * @memberof BanksApiUpdateInterfaceSelectionOption
     */
    readonly body: UpdateInterfaceSelectionOptionParams
}

/**
 * BanksApi - object-oriented interface
 * @export
 * @class BanksApi
 * @extends {BaseAPI}
 */
export class BanksApi extends BaseAPI {
    /**
     * 
     * @summary Get the bank\'s icon (if any) by BIC
     * @param {BanksApiBankIconRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public bankIcon(requestParameters: BanksApiBankIconRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).bankIcon(requestParameters.id, requestParameters.accountTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a bank by the given search string
     * @param {BanksApiBanksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public banks(requestParameters: BanksApiBanksRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).banks(requestParameters.search, requestParameters.multibankingProvider, requestParameters.includeDuplicates, requestParameters.filterBic, requestParameters.filterBicGroup, requestParameters.filterName, requestParameters.perPage, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a bank by the given ID.
     * @param {BanksApiBanksIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public banksId(requestParameters: BanksApiBanksIdRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).banksId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the bank catalogue
     * @param {BanksApiCatalogueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public catalogue(requestParameters: BanksApiCatalogueRequest = {}, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).catalogue(requestParameters.bankName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a bank interface selection option
     * @param {BanksApiCreateInterfaceSelectionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public createInterfaceSelectionOption(requestParameters: BanksApiCreateInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).createInterfaceSelectionOption(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the given bank interface selection option
     * @param {BanksApiDeleteBankInterfaceSelectionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public deleteBankInterfaceSelectionOption(requestParameters: BanksApiDeleteBankInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).deleteBankInterfaceSelectionOption(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a bank interface selection option by the given ID.
     * @param {BanksApiInterfaceSelectionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public interfaceSelectionOption(requestParameters: BanksApiInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).interfaceSelectionOption(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List and filter bank interface selection options
     * @param {BanksApiInterfaceSelectionOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public interfaceSelectionOptions(requestParameters: BanksApiInterfaceSelectionOptionsRequest = {}, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).interfaceSelectionOptions(requestParameters.bicGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the given bank interface selection option
     * @param {BanksApiUpdateInterfaceSelectionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanksApi
     */
    public updateInterfaceSelectionOption(requestParameters: BanksApiUpdateInterfaceSelectionOptionRequest, options?: RawAxiosRequestConfig) {
        return BanksApiFp(this.configuration).updateInterfaceSelectionOption(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const BanksMultibankingProviderEnum = {
    WEALTH_API: 'WEALTH_API',
    FIN_API: 'FIN_API'
} as const;
export type BanksMultibankingProviderEnum = typeof BanksMultibankingProviderEnum[keyof typeof BanksMultibankingProviderEnum];
