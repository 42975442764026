import React from "react";
import { Box, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";

type Props = {
  onEditClick: () => void;
  onDeleteClick: () => void;
  handleCloseMenu?: () => void;
};

export const ActionMenu = ({
  onEditClick,
  onDeleteClick,
  handleCloseMenu,
}: Props) => {
  const { t } = useTranslation();
  const handleEditClick = () => {
    onEditClick();
    handleCloseMenu?.();
  };

  const handleDeleteClick = () => {
    onDeleteClick();
    handleCloseMenu?.();
  };

  return (
    <>
      <MenuItem onClick={handleEditClick}>
        <Box display="flex" alignItems="center" gap={3}>
          <EditIcon width={20} height={20} />

          {t("csvImport.parsingResults.edit")}
        </Box>
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <Box display="flex" alignItems="center" gap={3} color="error.main">
          <DeleteIcon width={20} height={20} />
          {t("csvImport.parsingResults.delete")}
        </Box>
      </MenuItem>
    </>
  );
};
