import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { ReactComponent as CheckIcon } from "@icons/myicons/green-check.svg";
import { ReactComponent as BankIcon1 } from "@icons/bankicons/bank1.svg";
import { ReactComponent as BankIcon2 } from "@icons/bankicons/bank2.svg";
import { ReactComponent as BankIcon3 } from "@icons/bankicons/bank3.svg";
import { ReactComponent as BankIcon4 } from "@icons/bankicons/bank4.svg";
import { ReactComponent as BankIcon5 } from "@icons/bankicons/bank5.svg";
import { ReactComponent as BankIcon6 } from "@icons/bankicons/bank6.svg";
import { ReactComponent as BankIcon7 } from "@icons/bankicons/bank7.svg";
import { ReactComponent as BankIcon8 } from "@icons/bankicons/bank8.svg";

const textStyle = {
  small: {},
  medium: {
    fontSize: "18px",
    lineHeight: "28px",
  },
};

const iconStyle = {
  small: {
    width: 20,
    height: 20,
    minWidth: 20,
  },
  medium: {
    width: 24,
    height: 24,
    minWidth: 24,
  },
};

type Props = {
  text: string;
  showBankIcons?: boolean;
  variant?: "small" | "medium";
};

export const SubscriptionBenefit = ({
  text,
  showBankIcons,
  variant = "small",
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems={showBankIcons ? "flex-start" : "center"}
        gap={theme.spacing(3)}
      >
        <CheckIcon
          style={{
            ...iconStyle[variant],
          }}
        />
        <Box>
          <Typography
            variant="body1"
            fontWeight={400}
            letterSpacing={-0.2}
            {...textStyle[variant]}
          >
            {text}
          </Typography>
          {showBankIcons && (
            <Box display="flex" gap={theme.spacing(2)} flexWrap="wrap">
              <BankIcon1 />
              <BankIcon2 />
              <BankIcon3 />
              <BankIcon4 />
              <BankIcon5 />
              <BankIcon6 />
              <BankIcon7 />
              <BankIcon8 />
              <Typography variant="body1" fontWeight={400}>
                {t("settingsPage.subscription.muchMore")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
