import React, { useState } from "react";
import { isEqual, format } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { Dividend, TransactionValidationError } from "@api";
import { StepContainer } from "../../transactionWizard/StepContainer";
import { DividendsTable } from "../components/DividendsTable";

export interface Props {
  dividends: Dividend[];
  previouslySelectedDividends: Dividend[];
  validationErrors: TransactionValidationError[];
  onNext: (selectedDividends: Dividend[]) => void;
}

const ValidationErrorsContainer = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ListDividendsStep = (props: Props) => {
  const {
    onNext,
    dividends,
    previouslySelectedDividends = [],
    validationErrors,
  } = props;
  const { t } = useTranslation();
  const unreceivedDividends = dividends.filter(({ bookingId }) => !bookingId);
  const [selectedDividends, setSelectedDividends] = useState<Dividend[]>(
    previouslySelectedDividends.length
      ? previouslySelectedDividends
      : unreceivedDividends
  );

  const handleNext = () => {
    if (!selectedDividends.length) return null;

    return onNext(selectedDividends);
  };

  const handleSelectDividend = (selectedDividend: Dividend) => {
    const selectedIndex = selectedDividends.findIndex(({ date }) => {
      if (!date || !selectedDividend.date) return false;

      return isEqual(new Date(date), new Date(selectedDividend.date));
    });
    let newSelected: Dividend[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDividends, selectedDividend);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDividends.slice(1));
    } else if (selectedIndex === selectedDividends.length - 1) {
      newSelected = newSelected.concat(selectedDividends.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDividends.slice(0, selectedIndex),
        selectedDividends.slice(selectedIndex + 1)
      );
    }

    setSelectedDividends(newSelected);
  };

  const handleSelectAllDividends = (type: "select" | "unselect") => {
    if (type === "select") {
      setSelectedDividends(unreceivedDividends);
      return;
    }
    setSelectedDividends([]);
  };

  return (
    <StepContainer
      testid="select-dividend-step"
      title={t("batchDividendsWizard.listDividendsStep.title")}
      subtitle={t("batchDividendsWizard.listDividendsStep.subtitle")}
      onNext={handleNext}
      onNextDisabled={!selectedDividends.length}
    >
      {Boolean(validationErrors?.length) &&
        validationErrors.map(({ errorMessage, date }) => (
          <ValidationErrorsContainer
            severity="error"
            data-testid="dividend-validation-error"
            key={errorMessage}
          >
            {format(new Date(date!), "dd.MM.yyyy")} - {errorMessage}
          </ValidationErrorsContainer>
        ))}
      <DividendsTable
        allDividends={dividends}
        selectedDividends={selectedDividends}
        unreceivedDividendsCount={unreceivedDividends.length}
        onSelectAllDividends={handleSelectAllDividends}
        onSelectDividend={handleSelectDividend}
      />
    </StepContainer>
  );
};
