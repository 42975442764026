import { UseQueryOptions } from "react-query";
import {
  BillingCyclesApiBillingCyclesIdInvoicesRequest,
  Invoice,
} from "@generated/apiv1";

import { buildBillingCyclesApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const billingCyclesIdInvoices = async (
  token: string | undefined,
  params: BillingCyclesApiBillingCyclesIdInvoicesRequest
) => {
  const apiInstance = buildBillingCyclesApi(token);
  const { data } = await apiInstance.billingCyclesIdInvoices(params);
  return data;
};

export const useBillingCyclesIdInvoices = (
  params: BillingCyclesApiBillingCyclesIdInvoicesRequest,
  options?: UseQueryOptions<Invoice, AxiosApiError, Invoice>
) => {
  return useAuthQuery(
    [CACHE_KEYS.BILLINGCYCLES_BILLING_CYCLES_ID_INVOICES, params],
    ({ token }) => billingCyclesIdInvoices(token, params),
    options
  );
};
