import { UseQueryOptions } from "react-query";
import { FundamentalsApiFundamentalsIdRefreshRequest } from "@generated/apiv1";

import { buildFundamentalsApi } from "@generated/apiv1/apiBuilder";
import { CACHE_KEYS } from "@generated/apiv1/cacheKeys";
import { useAuthQuery } from "@api/auth";

export const fundamentalsIdRefresh = async (
  token: string | undefined,
  params: FundamentalsApiFundamentalsIdRefreshRequest
) => {
  const apiInstance = buildFundamentalsApi(token);
  const { data } = await apiInstance.fundamentalsIdRefresh(params);
  return data;
};

export const useFundamentalsIdRefresh = (
  params: FundamentalsApiFundamentalsIdRefreshRequest,
  options?: UseQueryOptions<void, AxiosApiError, void>
) => {
  return useAuthQuery(
    [CACHE_KEYS.FUNDAMENTALS_FUNDAMENTALS_ID_REFRESH, params],
    ({ token }) => fundamentalsIdRefresh(token, params),
    options
  );
};
