import Highcharts from "highcharts";
import imageSrc from "./finanzfluss-logo.webp";

const options: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  tooltip: {
    borderRadius: 4,
    borderColor: "transparent",
    backgroundColor: "transparent",
    shadow: false,
    style: {
      boxShadow: "none",
      fontFamily: "var(--ff-font-family-base)",
      fontWeight: "400",
      fontSize: "12px",
    },
    useHTML: true,
    // @ts-ignore
    shape: "square",
    stickOnContact: true,
  },
  chart: {
    events: {
      load() {
        const image = new Image();
        image.src = imageSrc;

        let widthImage = 240;
        let heightImage = widthImage / 4;
        const plotX = this.plotLeft + this.plotWidth * 0.5;
        const plotY = this.plotTop + this.plotHeight * 0.57;
        if (plotX * 0.5 < widthImage) {
          widthImage = plotX * 0.5;
          heightImage = widthImage / 4;
        }

        const positionX = plotX - widthImage / 2;
        const positionY = plotY - heightImage / 2;
        // @ts-ignore
        this.logo = this.renderer
          .image(image.src, positionX, positionY - 22, widthImage, heightImage)
          .css({
            position: "relative",
            opacity: 0.15,
          })
          .attr({
            zIndex: 1,
          })
          .add();

        // @ts-ignore
        this.logo.element.setAttribute("preserveAspectRatio", "xMidYMid meet");
      },
      redraw() {
        let widthImage = 240;
        let heightImage = widthImage / 4;
        const plotX = this.plotLeft + this.plotWidth * 0.5;
        const plotY = this.plotTop + this.plotHeight * 0.57;
        if (plotX * 0.5 < widthImage) {
          widthImage = plotX * 0.5;
          heightImage = widthImage / 4;
        }
        const positionX = plotX - widthImage / 2;
        const positionY = plotY - heightImage / 2;
        // @ts-ignore
        this.logo.attr({
          x: positionX,
          y: positionY - 22,
          width: widthImage,
          height: heightImage,
        });
      },
    },
    zooming: { mouseWheel: false },
  },
};

export default options;
