import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { ReactComponent as OuterRing } from "./outer-ring.svg";
import { ReactComponent as InnerRing } from "./inner-ring.svg";

const SpinnerContainer = styled(Box)(({ theme }) => ({
  position: "relative",

  ".outer-ring, .inner-ring": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transformOrigin: "center",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },

  ".inner-ring": {
    animationName: "spin-back",
    width: "85%",
    height: "85%",
  },

  ".outer-ring": {
    animationName: "spin-forth",
    width: "100%",
    height: "100%",
  },

  "@keyframes spin-back": {
    from: {
      transform: "translate(-50%, -50%) rotate(45deg)",
    },
    to: {
      transform: "translate(-50%, -50%) rotate(-315deg)",
    },
  },
  "@keyframes spin-forth": {
    from: {
      transform: "translate(-50%, -50%) rotate(45deg)",
    },
    to: {
      transform: "translate(-50%, -50%) rotate(405deg)",
    },
  },
}));

interface Props {
  width?: number;
}

export const LoadingSpinner = ({ width = 500 }: Props) => {
  return (
    <SpinnerContainer width={width} height={width}>
      <OuterRing className="outer-ring" />
      <InnerRing className="inner-ring" />
    </SpinnerContainer>
  );
};
