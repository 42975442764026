/* tslint:disable */
/* eslint-disable */
/**
 * API V3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BankingCredentials
 */
export interface BankingCredentials {
    /**
     * The banking login or username
     * @type {string}
     * @memberof BankingCredentials
     */
    'login'?: string;
    /**
     * The banking password / PIN
     * @type {string}
     * @memberof BankingCredentials
     */
    'password'?: string;
    /**
     * The phone area code when login is a phone number
     * @type {string}
     * @memberof BankingCredentials
     */
    'area_code'?: string;
    /**
     * identifies the bank, see allowed values
     * @type {string}
     * @memberof BankingCredentials
     */
    'bank_slug': BankingCredentialsBankSlugEnum;
    /**
     * Should banking login and password be stored?
     * @type {boolean}
     * @memberof BankingCredentials
     */
    'store_secrets': boolean;
}

export const BankingCredentialsBankSlugEnum = {
    TRADE_REPUBLIC: 'TradeRepublic',
    FINANZEN_NET: 'finanzen_net',
    BISON_APP: 'bison_app',
    BINANCE: 'binance',
    QUIRION: 'Quirion',
    DEGIRO: 'Degiro',
    BITPANDA: 'Bitpanda',
    WHITEBOX: 'Whitebox',
    SCALABLE_CAPITAL: 'ScalableCapital',
    MOCK_BANK: 'MockBank',
    SMARTBROKER_PLUS: 'SmartbrokerPlus',
    JUST_TRADE: 'JustTrade',
    PARQET: 'Parqet'
} as const;

export type BankingCredentialsBankSlugEnum = typeof BankingCredentialsBankSlugEnum[keyof typeof BankingCredentialsBankSlugEnum];


