import { UseMutationOptions } from "react-query";
import { AccountsApiDeleteAccountRequest } from "@generated/apiv1";

import { buildAccountsApi } from "@generated/apiv1/apiBuilder";
import { useAuthMutation } from "@api/auth";

export const deleteAccount = async (
  token: string | undefined,
  params: AccountsApiDeleteAccountRequest
) => {
  const apiInstance = buildAccountsApi(token);
  const { data } = await apiInstance.deleteAccount(params);
  return data;
};

export const useDeleteAccount = (
  options?: UseMutationOptions<
    object,
    AxiosApiError,
    AccountsApiDeleteAccountRequest
  >
) => {
  return useAuthMutation(deleteAccount, options, false);
};
