import { getYear, parseISO } from "date-fns";
import { sortBy, uniq, omit } from "lodash";
import { Financial, Statistic, HistoricQuote } from "@api";
import { ComparisonChartData, RangeValue } from "@components";
import { useFinancials } from "../useFinancials";
import { useStatistics } from "../useStatistics";
import {
  FINANCIAL_METRIC_KEYS,
  STATISTIC_METRIC_KEYS,
} from "../../components/SecurityMetrics";
import { getDates } from "./getFromDate";

const merge = (financialData?: Financial[], statisticData?: Statistic[]) => {
  const fYears = financialData?.map(({ year }) => year);
  const sYears = statisticData?.map(({ date }) => getYear(parseISO(date)));
  const years = uniq([...(fYears || []), ...(sYears || [])]);

  return years.map((year) => {
    const financial = financialData?.find((v) => v.year === year);
    const statistic = statisticData?.find(
      (v) => getYear(parseISO(v.date)) === year
    );
    return {
      ...omit(financial, ["year"]),
      ...omit(statistic, ["date"]),
      year,
    };
  });
};

interface Props {
  isin?: string;
  range?: RangeValue;
  dataKey1?: string;
  dataKey2?: string;
  historicQuotes?: HistoricQuote[];
}

interface Options {
  enabled?: boolean;
}

export const useChartDataBase = (
  { isin, range, dataKey1, dataKey2 }: Props,
  { enabled }: Options = { enabled: true }
) => {
  const { fromDate, toDate, fromYear, toYear } = getDates(range);

  const isFinancialKeyPresent =
    FINANCIAL_METRIC_KEYS.includes(dataKey1 as any) ||
    FINANCIAL_METRIC_KEYS.includes(dataKey2 as any);
  const isStatisticKeyPresent =
    STATISTIC_METRIC_KEYS.includes(dataKey1 as any) ||
    STATISTIC_METRIC_KEYS.includes(dataKey2 as any);

  const financialResult = useFinancials(
    {
      isin: isin,
      from: fromYear,
      to: toYear,
    },
    { enabled: isFinancialKeyPresent && enabled }
  );

  const statisticResult = useStatistics(
    {
      isin: isin,
      from: fromDate,
      to: toDate,
    },
    { enabled: isStatisticKeyPresent && enabled }
  );

  const data: ComparisonChartData[] = sortBy(
    merge(financialResult.data?.financials, statisticResult.data),
    ["year"]
  );

  const isLoading = financialResult.isLoading || statisticResult.isLoading;

  return {
    ...financialResult,
    data,
    securityData: financialResult.data?.security,
    isLoading,
  };
};
